import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: 'info.component.html'
})
export class InfoComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
     }
}
