<div class="tab-content">
    <form #f="ngForm" name="form" [formGroup]="linksForm">
        <div class="form-group" *ngIf="linksForm['controls']?.['Title']">
            <label for="lblTitle" class="col-form-label">
                <span translate>TITLE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input pInputText type="text" formControlName="Title" />
            </div>
            <control-messages [control]="linksForm.controls['Title']"></control-messages>
        </div>
        <div class="form-group" *ngIf="linksForm['controls']?.['Url']">
            <label for="lblUrl" class="col-form-label">
                <span translate>URL</span>
                <span class="required">*</span>
            </label>
            <div>
                <input pInputText type="text" formControlName="Url" />
            </div>
            <control-messages [control]="linksForm.controls['Url']"></control-messages>
        </div>
        <div class="action-btns-wrapper">
            <a class="btn btn-outline-secondary" (click)="goBackLocation()" translate>BACK</a>
            <button type="button" [disabled]="!linksForm.valid" (click)="saveLinks(linksForm.value)"
                class="btn btn-primary" translate>SAVE</button>
        </div>
        <div class="table-view">
            <!-- Table starts -->
            <p-table [value]="linkList" #dt [rows]="10" [paginator]="true" [pageLinks]="3"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}" 
            [rowsPerPageOptions]="[5,10,20]">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'TITLE' | translate}}</th>
                        <th>{{'URL'|translate}}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-links>
                    <tr>
                        <td>{{links.Title}}</td>
                        <td><a href="{{links.Url}}" target="_blank">{{links.Url}}</a></td>
                        <td>
                            <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                (click)="editLinks(links.Id)">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </button>
                            <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                (click)="deleteLink(links.Id)">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table ends -->
        </div>
    </form>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>