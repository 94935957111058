
import { EmployeeContactUserResolver } from './../users/services/user.resolver.service';
import { ResponsibleForExecutionResolver, AllDepartmentResolver } from './../follow-ups/services/deviation.resolver.service';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { TaskManagerComponent } from './task-manager.component';
import { NewTaskComponent } from './components/task-new.component';
import { TaskListComponent } from './components/task-list.component';
import {
    TaskManagerEditResolver,
    TaskManagerListResolver,
    FollowUpPersonList,
    RepeatitionList,
    ExecutionPersonList,
    TaskManagerAuditLog
} from './services/task.resolver.service';

export const taskmanagerRoutes: Route[] = [
    {
        path: 'taskmanager',
        component: TaskManagerComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: TaskListComponent,
                resolve: {
                    list: TaskManagerListResolver,
                    followup: EmployeeContactUserResolver
                }
            },
            {
                path: 'new',
                component: NewTaskComponent,
                resolve: {
                    repetition: RepeatitionList,
                    user: EmployeeContactUserResolver
                }
            },
            {
                path: 'edit/:tid',
                component: NewTaskComponent,
                resolve: {
                    user: EmployeeContactUserResolver,
                    edit: TaskManagerEditResolver,
                    repetition: RepeatitionList,
                    exePerson: ExecutionPersonList,
                    followup: FollowUpPersonList,
                    departments: AllDepartmentResolver
                }
            }
        ]
    }
];
