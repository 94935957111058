import { FaqServices } from './../kuba/faqs/services/faqs.service';
import { AppSharedModule } from './../kuba/shared/appShared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './faq.component';

import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AppSharedModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DropdownModule,
        FormsModule
    ],
    exports: [FaqComponent],
    declarations: [FaqComponent],
    providers: [
        FaqServices
    ],
})
export class FaqModule { }
