import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { element } from 'protractor';

import { EmployeeServices } from './../../../employees/services/employee.services';
import { BaseServices } from './../../../kuba.services';
import { Timetable } from './../../../time-table/models/timetable';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'timetablelist',
    templateUrl: 'mypage-timetable-list.component.html'
})
export class MyPageTimetableListComponent implements OnInit {
    ProjectWorkTypeName: any;
    employees: any[];
    StartDate: any;
    EmployeeName: any;
    @ViewChild('timetableTable',{static:false}) timetableTable: Table;
    months: any[];
    years: any[];
    showTypeofWork = true; //type of work
    Months: any;
    timetables: Timetable[];
    TimetableForm: FormGroup;
    bTimetable: any = {};
    bodyString: any;
    isHidden = false;
    val: any;
    projects: SelectItem[];
    types: SelectItem[];
    statuses: SelectItem[];
    showEditTimeTable = false;
    Year: any;
    articles: Array<any>;
    empId: number;
    private subscriptions: Subscription[] = [];

    /**
     * constructor
     * @param employeeServices {{EmployeeServices}}
     * @param fb {{FormBuilder}}
     * @param router {{Router}}
     * @param route {{ActivatedRoute}}
     */
    constructor(
        private employeeServices: EmployeeServices,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) { }

    /**
     * getting employee id,binding the datatable based on id,binding yesr and month function call
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let eid = this.route.snapshot.params['tid'];
        this.empId = +eid;
        if (this.empId) {
            this.timetables = [];
            let list = <Timetable[]>this.route.snapshot.data['timetable'];
            if (list) {
                list.forEach(element => {
                    element.Month = `${new Date(element.StartDate).getMonth() +
                        1}`;
                    element.Year = `${new Date(
                        element.StartDate
                    ).getFullYear()}`;
                    this.timetables.push(element);
                });
            }
        } else {
            this.timetables = [];
            let list = <Timetable[]>this.route.snapshot.data['list'];
            if (list) {
                list.forEach(element => {
                    element.Month = `${new Date(element.StartDate).getMonth() +
                        1}`;
                    element.Year = `${new Date(
                        element.StartDate
                    ).getFullYear()}`;
                    this.timetables.push(element);
                });
            }
        }

        this.bindYears();
        this.bindMonths();
    }

    /**
     * delete employee timtable
     * @param timetables
     */
    deleteTimetable(timetables:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(
                    this.employeeServices
                    .deleteTimetable(timetables)
                    .subscribe(result => {
                        if (this.empId) {
                            this.subscriptions.push(
                                this.employeeServices
                                .getTimeTableByEmployeeId(this.empId)
                                .subscribe(res => {
                                    this.timetables = res;
                                }));
                        } else {
                            this.subscriptions.push(
                                this.employeeServices
                                .getAllTimetableByBusinessId(
                                    BaseServices.BusinessId
                                )
                                .subscribe(res => {
                                    this.timetables = res;
                                }));
                        }
                    }));
            }
        });
    }

    /**
     * month change dropdown event for filter
     * @param e
     * @param monthDropdown
     */
    onMonthChanged(e:any, monthDropdown:any) {
        if (e.value) {
            // custom filter datatable
            this.timetableTable.filter(
                monthDropdown.selectedOption.label,
                'Month',
                'equals'
            );
        } else {
            this.timetableTable.reset();
        }
    }

    /**
     * year change dropdown event for filter
     * @param e
     * @param yearDropdown
     */
    onYearChanged(e:any, yearDropdown:any) {
        if (e.value) {
            // custom filter datatable
            this.timetableTable.filter(
                yearDropdown.selectedOption.label,
                'Year',
                'equals'
            );
        } else {
            this.timetableTable.reset();
        }
    }
    /**
     * binding year dropdown
     */
    bindYears() {
        this.years = [];
        let currentYear = new Date().getFullYear();
        this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.years = [];
            this.years.push(
                { label: val.SELECT, value: null }
            );
        }));
        for (let n = currentYear - 10; n <= currentYear + 10; n++) {
            this.years.push({ label: n, value: n });
        }
    }

    /**
     * binding montha dropdown
     */
    bindMonths() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.months = [];
            this.months.push(
                { label: val.SELECT, value: null }
            );
        }));
        for (let n = 1; n <= 12; n++) {
            this.months.push({ label: n, value: n < 10 ? '0' + n : n });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
