<div class="chart-container">
  <!-- Advanced Pie-Chart: Type 1 Total deviations-->
  <h4 translate *ngIf="chartView === 'chartType1'">TOTALVIEW</h4>
  <div
    *ngIf="chartView === 'chartType1'"
    class="chart-wrapper pie-chart-wrapper chart-min-height"
  >
    <ngx-charts-advanced-pie-chart
      [view]="view"
      [scheme]="colorScheme"
      [results]="single"
      [gradient]="gradient"
    >
    </ngx-charts-advanced-pie-chart>
  </div>

  <!-- Bar-Chart: Type 2 Deviations per department-->
  <!-- Only visible in Business level, this is replaced in portal level -->
  <h4 translate *ngIf="chartView === 'chartType2' && !isPortal">
    DEVIATIONS_PER_DEPARTMENT
  </h4>

  <div *ngIf="chartView === 'chartType2' && !isPortal" class="chart-min-height">
    <ngx-charts-bar-vertical-stacked
      [scheme]="colorScheme"
      [results]="paginatedDepartmentData"
      [gradient]="gradient"
      [legendTitle]="legendTitle"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="showLegend"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      xAxisLabel="Department"
      yAxisLabel="Count"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div
    *ngIf="chartView === 'chartType2' && !isPortal"
    class="d-flex justify-content-end pb-1"
  >
    <button
      class="btn btn-outline-secondary"
      type="button"
      translate
      (click)="prevPage('department')"
    >
      BACK
    </button>
    <button
      class="btn btn-outline-primary"
      type="button"
      translate
      (click)="nextPage('department')"
    >
      NEXT
    </button>
  </div>
  <!-- Bar-Chart: Type 2 Deviations per business-->
  <!-- Only visible in Portal level, this is replaced in business level -->
  <h4 translate *ngIf="chartView === 'chartType2' && isPortal">
    DEVIATIONS_PER_BUSINESS
  </h4>
  <div *ngIf="chartView === 'chartType2' && isPortal" class="chart-min-height">
    <ngx-charts-bar-vertical-stacked
      [scheme]="colorScheme"
      [results]="paginatedBusinessData"
      [gradient]="gradient"
      [legendTitle]="legendTitle"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="showLegend"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      xAxisLabel="Business"
      yAxisLabel="Count"
    >
    </ngx-charts-bar-vertical-stacked>
  </div>
  <div
    *ngIf="chartView === 'chartType2' && isPortal"
    class="d-flex justify-content-end"
  >
    <button
      class="btn btn-outline-primary"
      type="button"
      translate
      (click)="prevPage('business')"
    >
      BACK
    </button>
    <button
      class="btn btn-outline-primary"
      type="button"
      translate
      (click)="nextPage('business')"
    >
      NEXT
    </button>
  </div>
  <!-- Bar-Chart: Type 3 Deviations per importance-->
  <div *ngIf="chartView === 'chartType3'" class="chart-min-height">
    <h4 translate>DEVIATIONS_PER_IMPORTANCE</h4>
    <div class="chart-wrapper pie-chart-wrapper">
      <ngx-charts-pie-grid
        [view]="view"
        [scheme]="colorSchemeImportance"
        [results]="importanceData"
      >
      </ngx-charts-pie-grid>
    </div>
  </div>
</div>
