import 'rxjs/Rx';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FoodSafetyServices } from './../../services/foodsafety.services';

@Component({
    templateUrl: 'new-device.component.html',
})

export class CreateFoodSafetyDeviceComponent implements OnInit {
    deviceForm: FormGroup;
    constructor(private foodsafetySvc: FoodSafetyServices, private fb: FormBuilder) {
        this.deviceForm = this.fb.group({
            DeviceID: ['', Validators.required],
            DeviceName: ['', Validators.required],
            SensorID1: ['', Validators.required],
            SensorName1: ['', Validators.required],
            Status1: [''],
            SensorID2: ['', Validators.required],
            SensorName2: ['', Validators.required],
            Status2: [''],
            SensorID3: ['', Validators.required],
            SensorName3: ['', Validators.required],
            Status3: [''],
            SensorID4: ['', Validators.required],
            SensorName4: ['', Validators.required],
            Status4: [''],
        });
    }

    ngOnInit() {

    }

    // TODO: Function nameconvention need to be checked with Yella
    SaveRiskanalysis(value) {

    }

    // TODO: Functionality need to  be implemented
    saveProceed(value) {

    }
}
