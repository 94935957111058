import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcplOnlyofficeViewerComponent } from './ecpl-onlyoffice-viewer.component';
import { EcplOnlyOfficeViwerService } from './ecpl-onlyoffice-viewer.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [EcplOnlyofficeViewerComponent],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    TranslateModule,
    ProgressSpinnerModule,
  ],
  exports: [EcplOnlyofficeViewerComponent],
  providers: [EcplOnlyOfficeViwerService],
})
export class EcplOnlyofficeViewerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplOnlyofficeViewerModule,
    };
  }
}
