import { Component, OnInit } from '@angular/core';

@Component({     
    templateUrl: 'consumptions.component.html'
})

export class ConsumptionsComponent implements OnInit {
    constructor() { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
     }
}