<p-accordion>
    <p-accordionTab *ngFor="let feature of features; let i = index">
        <p-header>
            {{feature.Title}}
        </p-header>
        <p-checkbox [(ngModel)]="feature.IsChecked" binary="true" label="Select All"
            (click)="onTabOpen(i, feature.IsChecked)"></p-checkbox>
        <div *ngIf="feature.Widgets || feature.AdditionFeatures">
            <ul *ngIf="feature.Widgets">
                <li *ngFor="let widget of feature.Widgets">
                    <input type="checkbox" [(ngModel)]="widget.IsChecked"> {{widget.Title}}
                </li>
            </ul>
            <ul *ngIf="feature.AdditionFeatures">
                <li *ngFor="let afeature of feature.AdditionFeatures">
                    <input type="checkbox" [(ngModel)]="afeature.IsChecked"> {{afeature.Title}}
                </li>
            </ul>
        </div>
    </p-accordionTab>
    <button (click)="saveFeatures(features)">Save</button>
</p-accordion>


<!--testing Purpose-->
<!--<ng-template #tpl let-item="item" let-toggleCollapseExpand="toggleCollapseExpand" let-onCheckedChange="onCheckedChange">
    <div class="form-check">
        <i *ngIf="feature.children" (click)="toggleCollapseExpand()" aria-hidden="true" class="fa" [class.pi-caret-right]="feature.collapsed"
            [class.pi-caret-down]="!feature.collapsed"></i>
        <label class="form-check-label">
            <input type="checkbox" class="form-check-input"
                [(ngModel)]="feature.checked" (ngModelChange)="onCheckedChange()" [disabled]="feature.disabled" />
            {{item.text}}
        </label>
        <label class="form-check-label">
            <i class="pi pi-trash" aria-hidden="true" title="Remove" (click)="removeItem(item)"></i>
        </label>
    </div>
</ng-template>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <div class="d-inline-block">
                <leo-treeview [features]="features" [template]="tpl" (selectedChange)="onSelectedChange($event)">
                </leo-treeview>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="alert alert-success" role="alert">
            Selected products:
            <div *ngFor="let row of rows">{{row}}</div>
        </div>
    </div>
</div>-->
<!--<p-tree [value]="features" selectionMode="checkbox" [(selection)]="selectedFiles2"></p-tree>
<div>Selected Nodes: <span *ngFor="let file of selectedFiles2">{file.label} </span></div>-->