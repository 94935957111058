import { UserRole } from "./../../follow-ups/models/deviation";
import { Rights } from "./../../../_models/feature";
import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
 import { CommonChecklistService } from "../../common/services/common-checklist.services";
import { UploaderConfig } from "src/app/shared/ecpl-document-explorer/uploader-config";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";
import { BaseServices } from "src/app/kuba/kuba.services";
import { ManualService } from "src/app/kuba/manuals/services/manual.services";
import {
    ManualChecklistItemUpdate,
    ParentItem,
    ChildItem
} from "../models/manual-checklist.model";
import { isArray } from "util";
import { ToasterComponent } from "src/app/_directives/toaster.component";
import { TranslateService } from '@ngx-translate/core';
import * as _ from "lodash";
import { data } from "jquery";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
@Component({
    selector: 'manual-filemanager',
    templateUrl: './manual-view.component.html'
})
export class ManualViewComponent implements OnInit {
    applicationId: number;
    coverImg: boolean = false;
    coverImghide: boolean = false;
    manualImg: string;
    manualName: string;
    manualId: number;
    triggerUploader = false;
    apiResponse = 'none';
    isEditManuals = true;
    treeNode: any;
    busMode: string;
    editMode = false;
    treeActiveNode: any = null;
    showSavingLoader = false;

    // Document Explorer Configs
    additionalData: any;
    upConfig: UploaderConfig;
    uploadedFiles: any;
    UploadableFile: any;
    mPortalId: number;
    mBusinessId: number;
    coverImage = "";
    isLar = false;
    filename: string;
    defaultManualImg: string;
    explorerNodeId: number;
    userRole: string;
    allFiles: any[];
    isShowAllFile = true;
    isHideShowAllButton = false;
    loading = false;
    adminUsers:any = [];
    isPortalManager = true;
    public selectedFiles: any[];
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private manualService: ManualService,
        private cdr: ChangeDetectorRef,
        private commonChecklistService: CommonChecklistService,
        private http: HttpClient,
        private translator: TranslateService,
    ) {
        this.manualId = this.route.snapshot.params['mid'];
        this.manualName = this.route.snapshot.params['manualname'];
        this.mPortalId = this.route.snapshot.params['mPortalId'];
        this.mBusinessId = this.route.snapshot.params['mBusinessId'];

        this.manualImg = '';
        this.applicationId = BaseServices.ApplicationId;
        this.upConfig = {
            title: 'All Files',
            viewSwitch: false,
            editMode: this.editMode,
            windowedHeight: true,
            showUserMeta: true,
            uploaderUri:
                environment.BASE_URL + '/file/upload/application/manual',
            showSearchFilter: true,
            addtionalData: null,
            showDownloadAll: true,
            showDeleteAllButton: true,
            showApproveDocumentButton: false
        };
        this.userRole = BaseServices.UserRole;

    }


    // Events form Tree
    catchEvent(event:any): void {

        this.triggerUploader = false;
        this.treeActiveNode = BaseServices.getActiveTree;
        switch (event.eventName) {
            case 'CheckBoxStatus':
                this.loading = true;
                this.subscriptions.push(this.manualService
                    .updateManualCheckboxstatus(
                        this.manualId,
                        event.node.data,
                        this.applicationId,
                        this.mBusinessId,
                        this.mPortalId
                    ).pipe(
                        debounceTime(500),
                        distinctUntilChanged()
                    )
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();

                            }
                        },
                        error =>
                            console.log(
                                'Manual checklist delete Error : ',
                                error
                            ),
                        () => console.log('Manual checklist delete Complete')
                    ));

                break;
            case "AddNewRootFolder":


                this.subscriptions.push(this.manualService
                    .createManualFolder(
                        this.manualId,
                        event,
                        true,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error =>
                            console.log("Manual Create folder Error : ", error),
                        () =>
                            console.log(
                                "Manual Create folder Complete",
                                // this.treeActiveNode.data
                            )
                    ));
                break;
            case "AddNewFolder":

                this.subscriptions.push(this.manualService
                    .createManualFolder(
                        this.manualId,
                        event,
                        false,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error =>
                            console.log("Manual Create folder Error : ", error),
                        () =>
                            console.log(
                                "Manual Create folder Complete",
                                // this.treeActiveNode.data
                            )
                    ));
                break;
            case "RenameFolder":

                this.subscriptions.push(this.manualService
                    .renameManualFolder(this.manualId, event)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error =>
                            console.log("Manual Create folder Error : ", error),
                        () => console.log("Manual Create folder Complete")
                    ));
                break;
            case "DeleteFolder":

                this.subscriptions.push(this.manualService
                    .deleteManualFolder(this.manualId, event.item.data.nodeId)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                                this.toasterComponent.callToastDlt();
                            }
                        },
                        error =>
                            console.log("Manual delete folder Error : ", error),
                        () => console.log("Manual delete folder Complete")
                    ));
                break;
            case "AddFiles":
                this.triggerUploader = true;
                break;
            case "EditFile":
                // TODO:
                break;
            case "RenameFile":
                this.subscriptions.push(this.manualService
                    .renameDocument(this.manualId, event)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error => console.log("Rename article error : ", error),
                        () => console.log("Rename article Complete")
                    ));
                break;
            case "DeleteFile":

                this.subscriptions.push(this.manualService
                    .deleteDocument(this.manualId, event.item.data.nodeId)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                                this.toasterComponent.callToastDlt();
                            }
                        },
                        error =>
                            console.log(
                                "Manual checklist delete Error : ",
                                error
                            ),
                        () => console.log("Manual checklist delete Complete")
                    ));
                break;
            case "NewArticle":

                this.subscriptions.push(this.manualService
                    .newArticle(
                        this.manualId,
                        event,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        (response: any) => {
                            if (response) {
                                BaseServices.setArticleId(response.Id);
                                this.subscriptions.push(this.manualService
                                    .newArticleText(
                                        this.manualId,
                                        response.Id,
                                        event,
                                        true,
                                        +this.mPortalId,
                                        +this.mBusinessId,
                                        +event.item.articleContent.article.version
                                    )
                                    .subscribe((result:any) => {
                                        if (result) {
                                            this.refreshTreeNode();
                                            this.toasterComponent.callToast();
                                            BaseServices.setArticleId(0);
                                        }
                                    }));
                            }
                        },
                        (error:any) => {
                            this.apiResponse = "failed";
                            console.log("New article error : ", error);
                        },
                        () => console.log("new article Complete")
                    ));
                break;

            case "NewLar":
                this.subscriptions.push(this.manualService
                    .newLar(
                        this.manualId,
                        event,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        (response:any) => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        (error:any) => {
                            this.apiResponse = "failed";
                            console.log("new lar error : ", error);
                        },
                        () => console.log("new article Complete")
                    ));
                break;
            case "SaveLar":
                this.subscriptions.push(this.manualService
                    .saveLar(
                        this.manualId,
                        event,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        (response:any) => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        (error:any) => {
                            this.apiResponse = "failed";
                            console.log("Save article error : ", error);
                        },
                        () => console.log("save article Complete")
                    ));
                break;
            case "DeleteLar":
                this.subscriptions.push(this.manualService
                    .deleteLar(this.manualId, event.item.larId)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                                this.toasterComponent.callToastDlt();
                            }
                        },
                        error => console.log("Lar  delete Error : ", error),
                        () => console.log("Lar  delete Complete")
                    ));
                break;
            case 'SaveArticle':
                let version = event.item.articleContent.article.version;
                this.subscriptions.push(this.manualService
                    .saveArticle(
                        this.manualId,
                        event,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        (response: any) => {
                            if (response) {
                                BaseServices.setArticleId(response.Id);
                                this.subscriptions.push(this.manualService
                                    .newArticleText(
                                        this.manualId,
                                        response.Id,
                                        event,
                                        false,
                                        +this.mPortalId,
                                        +this.mBusinessId,
                                        +version
                                    )
                                    .subscribe((result:any) => {
                                        if (result) {
                                            BaseServices.setArticleId(0);
                                            this.refreshTreeNode();
                                            this.toasterComponent.callToast();
                                        }
                                    }));
                            }
                        },
                        (error:any) => {
                            this.apiResponse = "failed";
                            console.log("Save article error : ", error);
                        },
                        () => console.log("save article Complete")
                    ));
                break;
            case "RenameArticle":
                this.subscriptions.push(this.manualService
                    .renameArticle(this.manualId, event)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error => console.log("Rename article error : ", error),
                        () => console.log("Rename article Complete")
                    ));
                break;
            case "DeleteArticle":
                this.subscriptions.push(this.manualService
                    .deleteArticle(this.manualId, event.item.data.nodeId)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                            this.toasterComponent.callToastDlt();
                        },
                        error =>
                            console.log("Manual delete delete Error : ", error),
                        () => console.log("Manual delete delete Complete")
                    ));
                break;

            case "NewChecklist":
                this.subscriptions.push(this.manualService
                    .newChecklist(
                        this.manualId,
                        event,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        (response: any) => {
                            let checklistId = response.Id;
                            let listModel = this.checklistSaveObj(event);

                            this.subscriptions.push(this.manualService
                                .saveChecklist(
                                    this.manualId,
                                    checklistId,
                                    listModel,
                                    +this.mPortalId,
                                    +this.mBusinessId,
                                    0
                                )
                                .subscribe(
                                    response => {
                                        if (response) {
                                            this.refreshTreeNode();
                                        }
                                    },
                                    error =>
                                        console.log(
                                            "Save checklistitem error : ",
                                            error
                                        ),
                                    () =>
                                        console.log(
                                            "save checklistitem Complete"
                                        )
                                ));
                        },
                        error => console.log("Save checklist error : ", error),
                        () => console.log("save checklist Complete")
                    ));
                break;
            case "CopyChecklist":
                event.item.AppManualFolderId = event.activeNodeId;
                this.subscriptions.push(this.commonChecklistService.copyCheckList(event.item).subscribe(
                    result => {
                        if (result) {
                            this.refreshTreeNode();
                        }
                    },
                    (error:any) => console.log("Save checklist error : ", error),
                    () => console.log("save checklist Complete")
                ));

                break;
            case "SaveChecklist":
                let checklistId = event.item.checkList.id;
                let listModel = this.checklistSaveObj(event);
                this.subscriptions.push(this.manualService
                    .saveChecklist(
                        this.manualId,
                        checklistId,
                        listModel,
                        +this.mPortalId,
                        +this.mBusinessId,
                        BaseServices.UserId
                    )
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error => console.log("Save checklist error : ", error),
                        () => console.log("save checklist Complete")
                    ));
                break;
            case "RenameChecklist":
                this.subscriptions.push(this.manualService
                    .renameChecklist(this.manualId, event)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                            }
                        },
                        error =>
                            console.log("Rename checklist error : ", error),
                        () => console.log("Rename checklist Complete")
                    ));
                break;
            case "DeleteChecklist":
                this.subscriptions.push(this.manualService
                    .deleteChecklist(this.manualId, event.item.data.nodeId)
                    .subscribe(
                        response => {
                            if (response) {
                                this.refreshTreeNode();
                                this.toasterComponent.callToastDlt();
                            }
                        },
                        error =>
                            console.log(
                                "Manual checklist delete Error : ",
                                error
                            ),
                        () => console.log("Manual checklist delete Complete")
                    ));
                break;
            case 'DeleteSelectedChecklist':
                this.subscriptions.push(this.manualService.deleteSelectedChecklist(event.item.data).subscribe(result => {
                    if (result) {
                        this.refreshTreeNode();
                        this.toasterComponent.callToastDlt();
                    }
                }));
                break;
            case 'DeleteSelectedArticle':
                this.subscriptions.push(this.manualService.deleteSelectedArticle(event.item.data).subscribe(result => {
                    if (result) {
                        this.refreshTreeNode();
                        this.toasterComponent.callToastDlt();
                    }
                }));
                break;
            case 'approveArticle':
                this.subscriptions.push(this.manualService.approveBusinessArticle(event.item.data).subscribe(result => {
                    if (result) {
                        this.refreshTreeNode();
                    }
                }));
                break;
            case 'DeleteSelectedDocuments':
                this.subscriptions.push(this.manualService.deleteMultipleDocument(event.item.data).subscribe(result => {
                    if (result) {
                        this.refreshTreeNode();
                        this.toasterComponent.callToastDlt();
                    }
                }));
                break;
            case 'moveNode':
                this.subscriptions.push(this.manualService.moveNode(this.manualId, event).subscribe(
                    response => {
                        if (response) {
                            this.refreshTreeNode();
                        }
                    },
                    error => console.log('Manual movenoxde  Error : ', error),
                    () => console.log('Manual movenode  Complete')
                ));
                break;
            case 'CopyEditVersionDocument':
                // Service that copy existing file to respective place
                this.subscriptions.push(this.manualService.createEditCopyofDocument(event).subscribe(
                    (response: any) => {
                        let docPathDto = {
                            Id: event.item.id,
                            Path: response.path,
                            ModifiedBy: BaseServices.UserId
                        };
                        this.subscriptions.push(this.manualService
                            .CreateEditDocumentVersion(docPathDto)
                            .subscribe(
                                response =>
                                    console.log('Document version created'),
                                error =>
                                    console.error(
                                        'Document version  Error : ',
                                        error
                                    ),
                                () => console.log('Document version  Complete')
                            ));
                    },
                    error => console.error('Document version  Error : ', error),
                    () => console.log('Document version  Complete')
                ));
                break;
            case 'CopyFile':
                if (
                    confirm(
                        'ou are about to creating a new copy of this file, Do you really want to proceed?'
                    )
                ) {
                    // Service that copy existing file to respective place
                    this.subscriptions.push(this.manualService.createCopyofDocument(event).subscribe(
                        (response: any) => {
                            let docPathDto = {
                                Id: event.item.data.nodeId,
                                Path: response.path,
                                ModifiedBy: BaseServices.UserId
                            };

                            this.subscriptions.push(this.manualService
                                .copyManualDocument(
                                    docPathDto,
                                    +this.mPortalId,
                                    +this.mBusinessId
                                )
                                .subscribe(
                                    (result: any) => {
                                        this.refreshTreeNode();
                                        this.toasterComponent.callToast();
                                    },
                                    (error: any) => {
                                        console.error(
                                            "Manual document service error:",
                                            error
                                        );
                                    },
                                    () => {
                                        console.log(
                                            "Adding manual document service complete."
                                        );
                                    }
                                ));
                        },
                        error =>
                            console.error("Document version  Error : ", error),
                        () => console.log("Document version  Complete")
                    ));
                }

                // service that update existing record in document based on Module
                break;

            case "CopyOfficeDocument":
                // Add Version to Document version Table
                this.subscriptions.push(this.manualService
                    .createDocumentVersion(event)
                    .subscribe(
                        response => console.log("Document version created"),
                        error =>
                            console.error("Document version  Error : ", error),
                        () => console.log("Document version  Complete")
                    ));
                // Service that copy existing file to respective place
                this.subscriptions.push(this.manualService.createCopyofDocument(event).subscribe(
                    (response: any) => {
                        let docPathDto = {
                            Id: event.item.id,
                            Path: response.path,
                            ModifiedBy: BaseServices.UserId
                        };

                        this.subscriptions.push(this.manualService
                            .updateDocument(docPathDto, this.manualId)
                            .subscribe(
                                response => {

                                    this.refreshTreeNode();
                                    sessionStorage.setItem("EditAsCopy", "1");
                                },
                                error =>
                                    console.error(
                                        "Document version  Error : ",
                                        error
                                    ),
                                () => console.log("Document version  Complete")
                            ));
                    },
                    error => console.error("Document version  Error : ", error),
                    () => console.log("Document version  Complete")
                ));
                // service that update existing record in document based on Module
                break;

            case "ShowOfficeFileSaveloader":
                // This trigger when Office document was edit,
                // Office server would take 10 Second to response with edited file till then show Saving loader
                if (
                    parseInt(sessionStorage.getItem("isDocumentChanged")!, null!)
                ) {
                    this.subscriptions.push(this.manualService.updateManualDocument(this.manualId, event.item.id, BaseServices.UserId).
                        subscribe(
                            result => {
                                if (result) {

                                }
                            }
                        ));

                    this.showSavingLoader = true;
                    setTimeout(() => {
                        this.showSavingLoader = false;
                        this.refreshTreeNode();
                    }, 10000);
                    sessionStorage.setItem("isDocumentChanged", "0");
                }
                break;

            case "activate":
                this.loading = true;
                if (event.node.hasChildren) {
                    this.isShowAllFile = true;
                    this.coverImg = false;
                    this.coverImghide = false;
                    if (
                        event.node.data.documentType === 'laws' ||
                        event.node.data.documentType === 'regulation' ||
                        event.node.data.documentType === 'videos' ||
                        event.node.data.documentType === 'Videos'
                    ) {
                        this.isHideShowAllButton = true;
                        this.isLar = true;
                        this.uploadedFiles = event.node.data.content;
                        this.filename = event.node.data.documentType;
                        this.treeActiveNode = event.node;
                        this.upConfig.title = event.node.data.filename;
                        BaseServices.setActiveTreeNode(this.treeActiveNode);
                    } else {
                        this.isHideShowAllButton = false;
                        this.isLar = false;
                        this.uploadedFiles = event.node.data.nodes;
                        this.upConfig.title = event.node.data.filename;
                        this.upConfig.documentType =
                            event.node.data.documentType;
                        this.upConfig.addtionalData = {
                            ApplicationId: BaseServices.ApplicationId,
                            FolderPath:
                                "mid-" +
                                this.manualId +
                                "-" +
                                this.manualName.replace(" ", "-").toLowerCase(),
                            FolderTypeName: event.node.data.documentType,
                            ManualId: +this.manualId,
                            UserId: BaseServices.UserId,
                            ManualFolderId: event.node.data.nodeId
                        };

                        this.treeActiveNode = event.node;
                        BaseServices.setActiveTreeNode(this.treeActiveNode);
                    }
                }
                this.treeActiveNode = BaseServices.getActiveTree;
                this.cdr.detectChanges();
                this.loading = false;
                break;

            case 'initialized':
                break;
            case 'updateData':
                break;
            case 'loadNodeChildren':
                break;
            default:
                break;
        }
    }

    // emitter for save from Document Explorer
    saveUploaded(event: any) {
        this.treeActiveNode = BaseServices.getActiveTree;
        switch (event.eventName) {
            case "AddNewFolder":
                if (!event.parent) {
                    event.parent = this.treeActiveNode.data;
                }
                this.catchEvent(event);
                break;
            case "DeleteFolder":
            case "DeleteArticle":
            case "DeleteChecklist":
            case "DeleteFile":
            case 'DeleteSelectedArticle':
            case 'DeleteSelectedChecklist':
            case 'DeleteSelectedDocuments':
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case 'approveArticle':
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "NewLar":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "DeleteLar":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "SaveLar":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;

            case "SaveArticle":
                this.catchEvent(event);
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                break;
            case "NewArticle":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "SaveChecklist":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "CopyEditVersionDocument":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "NewChecklist":
            case "CopyChecklist":
                if (this.treeActiveNode.data !== undefined) {
                    event.activeNodeId = this.treeActiveNode.data.nodeId;
                }
                this.catchEvent(event);
                break;
            case "ShowOfficeFileSaveloader":
                if (
                    parseInt(sessionStorage.getItem("isDocumentChanged")!, null!)
                ) {
                    this.subscriptions.push(this.manualService.updateManualDocument(this.manualId, event.item.id, BaseServices.UserId).
                        subscribe(
                            result => {
                                if (result) {

                                }
                            }
                        ));
                    this.showSavingLoader = true;
                    setTimeout(() => {
                        this.showSavingLoader = false;
                        this.refreshTreeNode();
                    }, 15000);
                    sessionStorage.setItem("isDocumentChanged", "0");
                }
                break;
            default:
                let uploadedFile = Object.assign(
                    {},
                    event,
                    this.upConfig.addtionalData
                );
                this.subscriptions.push(this.manualService
                    .addManualDocument(
                        uploadedFile,
                        +this.mPortalId,
                        +this.mBusinessId
                    )
                    .subscribe(
                        (result: any) => {
                            this.refreshTreeNode();
                        },
                        (error: any) => {
                            console.error(
                                "Manual document service error:",
                                error
                            );
                        },
                        () => {
                            console.log(
                                "Adding manual document service complete."
                            );
                        }
                    ));
                break;
        }
    }

    // Emitter for selected folder from Document Explorer
    setActiveNodeIntree(exploreNode: any) {
        let event = {
            eventName: "activate",
            node: {
                data: exploreNode[0].nodes
            }
        };
        this.catchEvent(event);
    }

    pushToBack() {
        this.location.back();
    }

    // Toggle Edit Mode
    toggleEditMode() {
        this.editMode = !this.editMode;
        this.upConfig.editMode = !this.upConfig.editMode;
        this.treeActiveNode = BaseServices.getActiveTree;
        // this.refreshTreeNode();
        if (this.treeActiveNode) {
            let flattenned = this.flattenArrObj(this.treeNode);
            const parent = _.filter(flattenned, [
                'nodeId',
                this.treeActiveNode.data.nodeId
            ]);
            var i = parent.length-1;
            if (this.treeActiveNode.data.documentType === 'laws'){
                this.uploadedFiles = parent[i].content;
            }else if(this.treeActiveNode.data.documentType === 'regulation'
                || this.treeActiveNode.data.documentType === 'videos') {
                this.uploadedFiles = parent[i].content;
            } else {
                this.uploadedFiles = parent[0].nodes.filter((x:any) => x.status != 2);
            }
            this.treeActiveNode = parent[0];
        }
    }

    refreshTreeNode() {
        let businessId = +this.mBusinessId ? this.mBusinessId : 0;
        let portalId = +this.mPortalId ? this.mPortalId : 0;
        let appId = BaseServices.ApplicationId;
        let userRole = BaseServices.UserRole;

        if (userRole === "Admin") {
            this.subscriptions.push(this.manualService
                .getManualTree(
                    this.applicationId,
                    this.manualId,
                    portalId,
                    businessId
                )
                .subscribe(
                    (result: any) => {
                        this.loading = false;
                        this.treeActiveNode = BaseServices.getActiveTree;
                        this.treeNode = result;
                        let flattenned = this.flattenArrObj(this.treeNode);
                        if (this.treeActiveNode) {
                            const parent = _.filter(flattenned, [
                                'nodeId',
                                this.treeActiveNode.data.nodeId
                            ]);
                            var i = parent.length-1;
                            if (this.treeActiveNode.data.documentType === 'laws'){
                                this.uploadedFiles = parent[i].content;
                            }else if(this.treeActiveNode.data.documentType === 'regulation'
                                || this.treeActiveNode.data.documentType === 'videos') {
                                this.uploadedFiles = parent[i].content;
                            } else {
                                this.uploadedFiles = parent[0].nodes.filter((x:any) => x.status != 2);
                            }
                            this.treeActiveNode = parent[0];
                        }
                        else {
                            if (this.treeNode) {
                                let flattenned = this.flattenArrObj(this.treeNode);
                                this.uploadedFiles = flattenned.filter((x:any) => x.isFolder !== true && x.status === 1);
                            }
                        }
                    },
                    (error: any) => {
                        this.loading = false;
                        console.error(
                            "Api Error while refreshing tree:",
                            error
                        );
                    },
                    () => {
                        console.log("Refreshing tree service complete.");
                        this.apiResponse = "reloaded";
                    }
                ));
        } else if (userRole === "Portal") {
            this.subscriptions.push(this.manualService
                .getManualTreePortalLevel(
                    this.manualId,
                    appId,
                    portalId,
                    businessId
                )
                .subscribe(
                    (result: any) => {
                        this.loading = false;
                        this.treeActiveNode = BaseServices.getActiveTree;
                        this.treeNode = result;
                        let flattenned = this.flattenArrObj(this.treeNode);

                        if (this.treeActiveNode) {
                            const parent = _.filter(flattenned, [
                                "nodeId",
                                this.treeActiveNode.data.nodeId
                            ]);
                            var i = parent.length-1;
                            if (this.treeActiveNode.data.documentType === 'laws'){
                                this.uploadedFiles = parent[i].content;
                            }else if(this.treeActiveNode.data.documentType === 'regulation'
                                || this.treeActiveNode.data.documentType === 'videos') {
                                this.uploadedFiles = parent[i].content;
                            } else {
                                this.uploadedFiles = parent[0].nodes.filter((x:any) => x.status != 2);
                            }
                            this.treeActiveNode = parent[0];
                        }
                        else {
                            if (this.treeNode) {
                                let flattenned = this.flattenArrObj(this.treeNode);
                                this.uploadedFiles = flattenned.filter((x:any) => x.isFolder !== true && x.status === 1);
                            }
                        }
                    },
                    (error: any) => {
                        this.loading = false;
                        console.error(
                            "Api Error while refreshing tree:",
                            error
                        );
                    },
                    () => {
                        console.log("Refreshing tree service complete.");
                        this.apiResponse = "reloaded";
                    }
                ));
        } else if (userRole === "Editor" || userRole === "User") {
            this.subscriptions.push(this.manualService
                .getManualTreeBusinessLevel(
                    this.manualId,
                    appId,
                    portalId,
                    businessId,
                    false
                )
                .subscribe(
                    (result: any) => {
                        this.loading = false;
                        this.treeActiveNode = BaseServices.getActiveTree;
                        this.treeNode = result;
                        let flattenned = this.flattenArrObj(this.treeNode);
                        if (this.treeActiveNode) {
                            const parent = _.filter(flattenned, [
                                "nodeId",
                                this.treeActiveNode.data.nodeId
                            ]);
                            var i = parent.length-1;
                            if (this.treeActiveNode.data.documentType === 'laws'){
                                this.uploadedFiles = parent[i].content;
                            }else if(this.treeActiveNode.data.documentType === 'regulation'
                                || this.treeActiveNode.data.documentType === 'videos') {
                                this.uploadedFiles = parent[i].content;
                            } else {
                                this.uploadedFiles = parent[0].nodes.filter((x:any) => x.status != 2);
                            }
                            this.treeActiveNode = parent[0];
                        }
                        else {
                            if (this.treeNode) {
                                let flattenned = this.flattenArrObj(this.treeNode);
                                this.uploadedFiles = flattenned.filter((x:any) => x.isFolder !== true && x.status === 1);
                            }
                        }
                    },
                    (error: any) => {
                        this.loading = false;
                        console.error(
                            "Api Error while refreshing tree:",
                            error
                        );
                    },
                    () => {
                        console.log("Refreshing tree service complete.");
                        this.apiResponse = "reloaded";
                    }
                ));
        } else {
        }
    }

    ngOnInit() {
        this.coverImg = true;
        this.coverImghide = false;
        document.querySelector("body").classList.remove("opened");
        BaseServices.setActiveTreeNode(null);
        this.treeActiveNode = null;
        this.treeNode = this.route.snapshot.data['treeData'];
        if (this.treeNode) {
            let flattenned = this.flattenArrObj(this.treeNode);
            this.uploadedFiles = flattenned.filter((x:any) => x.isFolder !== true && x.status === 1);
        }
        this.treeNode = this.route.snapshot.data['treeData'];
        this.busMode = this.route.snapshot.data['businessMode'];
        let aUsers = this.route.snapshot.data['adminUsers'];
        this.subscriptions.push(this.translator.stream('SELECT_DROPDOWN').subscribe(val => {
            this.adminUsers = [];
            if (+BaseServices.roleId === 1) {
                this.adminUsers.push(
                    { Name: val.SELECT, Id: null! }
                );
            }

            if (aUsers != null && aUsers.length > 0) {
                aUsers.forEach((x:any) => {
                    this.adminUsers.push(
                        { Name: x.Name, Id: x.Id }
                    );
                });
            }
        }));

        let businessId = +this.mBusinessId ? this.mBusinessId : 0;
        let portalId = +this.mPortalId ? this.mPortalId : 0;
        let appId = BaseServices.ApplicationId;
        this.subscriptions.push(this.manualService
            .getManualCoverImage(this.manualId, appId, portalId, businessId)
            .subscribe(
                (result: any) => {
                    if (result) {
                        if (result.CoverImg) {
                            this.coverImage = result.CoverImg;
                        } else {
                            this.defaultManualImg = this.applicationId == 5 ? "../../../../assets/images/Medi3-handbok.jpg" : "../../../../assets/images/KubaManual.jpg";
                        }
                    }

                },
                error => console.log("Cover image error : ", error),
                () => console.log("Cover image1234")
            ));
        this.onEditManuals();
    }

    private flattenArrObj(arr:any) {
        let newArr:any = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    private checklistSaveObj(model:any) {
        let listModel = new ManualChecklistItemUpdate();
        listModel.title = model.item.checkList.title;
        listModel.score = model.item.checkList.scoreIndex;
        listModel.userId = BaseServices.UserId;
        listModel.parentItems = [];
        if (model.item.checkList.sections.length > 0) {
            model.item.checkList.sections.forEach((ParentItem:any) => {
                let section = this.checklistSecObj(ParentItem);
                listModel.parentItems.push(section);
            });
        }

        return listModel;
    }

    private checklistSecObj(obj:any) {
        let section = new ParentItem();
        section.parentItemId = obj.id; // Id
        section.parentId = 0;
        section.parentItemPoint = obj.position;
        section.parentItemTitle = obj.title;
        section.parentItemStatus = obj.status;
        section.childItems = this.checklistItemObj(obj.items);
        return section;
    }

    private checklistItemObj(objItem:any) {
        let items: ChildItem[] = [];
        if (isArray(objItem)) {
            objItem.forEach((item:any) => {
                items.push({
                    childItemId: item.id,
                    childItemTitle: item.title,
                    childItemPoint: item.position,
                    isView: item.status
                });
            });
        }
        return items;
    }
    onEditManuals() {
        let currentUserRole = BaseServices.UserRole;
        if (currentUserRole === 'User'
            || (BaseServices.PortalManager !== true && currentUserRole === 'Portal')) {
            let userRightsId = Rights.EDIT_MANUALS;
            this.isEditManuals = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );

        }
        if (this.userRole === 'Portal' && BaseServices.PortalManager === false) {
            this.isPortalManager = this.isEditManuals;
        }
    }

    /**
     * upload for manual cover image
     * @param selectedFiles
     */
    myUploader(selectedFiles:any) {
        let businessId = +this.mBusinessId ? this.mBusinessId : 0;
        let portalId = +this.mPortalId ? this.mPortalId : 0;
        let appId = BaseServices.ApplicationId;
        let userRole = BaseServices.UserRole;
        this.loading = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };
        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/manual/coverImage/' +
                BaseServices.ApplicationId +
                '/' +
                this.manualId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                let res = response;
                this.coverImage = res.path;
                if (userRole === 'Admin') {
                    this.loading = false;
                    this.subscriptions.push(this.manualService
                        .updateCommonManualCoverImg(
                            this.manualId,
                            res.path
                        )
                        .subscribe(
                            (result: any) => {
                                if (result) {
                                }
                            },
                            error => console.log('Cover image error : ', error),
                            () => console.log('Cover image1234')
                        ));
                } else if (userRole === 'Portal') {
                    this.loading = false;
                    this.subscriptions.push(this.manualService
                        .updatePortalManualCoverImg(
                            this.manualId,
                            res.path,
                            portalId
                        )
                        .subscribe(
                            (result: any) => {
                                if (result) {
                                }
                            },
                            error => console.log('Cover image error : ', error),
                            () => console.log('Cover image1234')
                        ));
                } else if (userRole === 'Editor' || userRole === 'User') {
                    this.loading = false;
                    this.subscriptions.push(this.manualService
                        .updateBusinessManualCoverImg(
                            this.manualId,
                            res.path,
                            businessId
                        )
                        .subscribe(
                            (result: any) => {
                                if (result) {
                                }
                            },
                            error => console.log('Cover image error : ', error),
                            () => console.log('Cover image1234')
                        ));
                }
            })
            .subscribe(
                result => { },
                error =>
                    console.log('Upload manual cover image Error : ', error),
                () => console.log('Upload manual cover image Complete')
            ));
    }

    showAllFiles() {
        this.coverImg = false;
        this.coverImghide = true;
        this.isShowAllFile = false;
        if (this.treeActiveNode == undefined) {
            this.allFiles = this.flattenArrObj(this.treeNode[0].nodes);
        }
        else {
            this.allFiles = this.flattenArrObj(this.treeActiveNode.data.nodes);
        }
        this.uploadedFiles = this.allFiles.filter((x:any) => x.isFolder === false);
    }

    hideAllFiles() {
        this.coverImg = false;
        this.coverImghide = true;
        this.isShowAllFile = true;
        this.uploadedFiles = '';
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
