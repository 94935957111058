import { ModuleOverviewListResolver } from './services/module-overview.resolver.service';
import { Route } from '@angular/router';

import { ModuleOverviewComponent } from './module-overview.component';
import { ModuleOverviewListComponent } from './components/module-overviewList.component';
import { CreateModuleOverviewComponent } from './components/Createmodule-overview.component';
export const moduleoverviewRoutes: Route[] = [
    {
        path: 'module-overview',
        component: ModuleOverviewComponent,
        children: [
            {
                path: 'list',
                component: ModuleOverviewListComponent,
                resolve: {
                    list: ModuleOverviewListResolver,
                },
            },
            {
                path: 'create/:id',
                component: CreateModuleOverviewComponent
            }

        ]
    },
];
