
import { FoodSafetyListComponent } from './../foodsafety/components/foodsafety-list/foodsafety-list.component';
import { FoodSafetyComponent } from './../foodsafety/foodsafety.component';
import { ManualControlListResolver, TypeOfObjectResolver, BrandResolver, ModelResolver, ManualControlEditResolver, TemperatureResolver, ManualTempControlResolver } from './services/manual-control-resolver';
import { ManualControlFormComponent } from './components/manual-control-form.component';
import { ManualControlListComponent } from './components/manual-control-list.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { ManualControlComponent } from './manual-control.component';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

export const ManualControlRoutes: Route[] = [{
    path: 'manual-control',
    component: ManualControlComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: ManualControlListComponent,
            canActivate: [AuthGuard],
            resolve: {
                list: ManualControlListResolver,
                manageTemp: ManualTempControlResolver
            }
        },
        {
            path: 'add',
            component: ManualControlFormComponent,
            canActivate: [AuthGuard],
            resolve: {
                typeofObject: TypeOfObjectResolver,
                brand: BrandResolver,
                model: ModelResolver,
                temperature: TemperatureResolver
            }
        },
        {
            path: 'edit/:mId',
            component: ManualControlFormComponent,
            canActivate: [AuthGuard],
            resolve: {
                edit: ManualControlEditResolver,
                typeofObject: TypeOfObjectResolver,
                brand: BrandResolver,
                model: ModelResolver,
                temperature: TemperatureResolver
            }
        },


    ]

}];




