<div class="page-title">
    <div class="page-actions text-right">
        <p-dropdown [options]="reportDropdown" [(ngModel)]="selectedReport" (onChange)="onTemplateChanged($event)">
        </p-dropdown>
        <button [disabled]="!isTemplate" class="btn btn-outline-success" translate
            (click)="copyCheckList()">COPY_TEMPLATE</button>
        <button class="btn btn-outline-success" (click)="createCheckList()" translate>NEW_CHECKLIST</button>
    </div>

</div>
<div class="col-sm-6">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="checkList" #checklistTable [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'TITLE'| translate}}</th>
                    <th>{{'OPTION'| translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-Ir>
                <tr>
                    <td>
                        <span class="thumb-sm">
                            <ecpl-checklist-viewer [checklistName]="Ir.Filename" [(checklistSrc)]="Ir.content"
                                (saveChanges)="saveUploaded($event)" [id]="Ir.content.checkList.Id" [editMode]="false">
                            </ecpl-checklist-viewer>
                        </span>
                        <span>{{Ir.filename}}</span>
                    </td>
                    <td class="col-button">
                        <button *ngIf="Ir.isEditable" class="btn btn-icon" title="{{'Edit' | translate}}"
                            (click)="editCheckList(Ir)">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <a *ngIf="Ir.isEditable" (click)="deleteChecklist(Ir['id'])" class="btn btn-icon">
                            <i class="icon ic-sm icon-trash" pTooltip="{{'DELETE' | translate}}"
                                tooltipPosition="top"></i>
                        </a>
                        <button *ngIf="!isAdmin && featureKey" class="btn btn-icon" title="{{'Print' | translate}}"
                            (click)="printChecklist(Ir)">
                            <i class="icon ic-sm icon-pdf"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</div>
<div #hiddenAction style="width:1px; height:1px; overflow:hidden" *ngIf="file != null">
    <ecpl-checklist-viewer [checklistName]="file.filename" [(checklistSrc)]="file" (saveChanges)="saveUploaded($event)"
        [editMode]="true">
    </ecpl-checklist-viewer>
</div>
<toaster-component></toaster-component>