export class ManualEdit {
    Id: number;
    Title: string;
    Description: string;
    CoverImg: string;
    Status: number;
    LevelId: number;
    LevelKey: number;
    EditorOnly: boolean;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    PublishedOn: Date;
    AppManualId: number;
    ApplicationId?: number;
    BusinessId?: number;
    BusinessPortalId?: number;
    constructor() {
        this.ApplicationId = 0;
        this.BusinessId = 0;
        this.BusinessPortalId = 0;
    }
}

export class ManualCoverImageDto {
    ApplicationId?: number;
    PortalId?: number;
    BusinessId?: number;
    CoverImage?: string;
    ManualId?: number;
}


