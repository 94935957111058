import { NgModule, ModuleWithProviders } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { KubaCompleteChecklistComponent } from './kuba-complete-checklist.component';
import { VehiclesCompleteChecklistComponent } from './kuba-vehicles-complete-checklist.component';
import { FoodSafetyCompleteChecklistComponent } from './kuba-foodsafety-complete-checklist.component';
import { KubaCompleteSafteyJobChecklistComponent } from './kuba-safety-job-analysis-complete-checklist.component';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { CompleteCheckListServices } from '../services/complete-checklist.service';
import { TableModule } from 'primeng/table';
import { AppSharedModule } from '../appShared.module';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ContextMenuModule } from 'primeng/contextmenu';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { SpinnerModule } from 'primeng/spinner';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { StepsModule } from 'primeng/steps';
import { MenuModule } from 'primeng/menu';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AppSharedModule,
    EditorModule, CalendarModule, ContextMenuModule,
    MenuModule, DropdownModule, InputTextareaModule,
    InputTextModule, ButtonModule, TableModule, SharedModule, DialogModule, CheckboxModule
    , StepsModule, EcplDocumentTreeModule, EcplDocumentExplorerModule,SpinnerModule,
    TabsModule.forRoot()
  ],
  declarations: [KubaCompleteChecklistComponent, FoodSafetyCompleteChecklistComponent,VehiclesCompleteChecklistComponent, KubaCompleteSafteyJobChecklistComponent],
  providers: [CompleteCheckListServices]
})
export class KubaCompleteChecklistModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: KubaCompleteChecklistModule
    };
  }
}
