import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { SjaListComponent } from './components/sja-list.component';
import { SjaUserformComponent } from './components/sja-userform.component';
import { SjaFormComponent } from './sja-form.component';
import { SJAFormListResolver, SJAFormResolver, SJATitleListResolver } from './sjaform.resolver.service';

export const SjaFormRoutingModule: Route[] = [{
    path: 'sjaform',
    component: SjaFormComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: SjaListComponent,
            canActivate: [AuthGuard],
            resolve: {
                list: SJAFormListResolver
            }
        },
        {
            path: 'new',
            component: SjaUserformComponent,
            canActivate: [AuthGuard],
            resolve: {
                sjaTitleList: SJATitleListResolver
            }
        },
        {
            path: 'edit/:Id',
            component: SjaUserformComponent,
            canActivate: [AuthGuard],
            resolve: {
                sjaform: SJAFormResolver,
                sjaTitleList: SJATitleListResolver
            }
        }]
}];
