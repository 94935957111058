<div class="login-wrapper" [ngClass]="{ 'is-medi3': isMedi3 == true }">
  <div class="login-panel">
    <div class="login-panel-body">
      <div class="kb-progress-bar" *ngIf="loading && !isPhp">
        <div class="kb-progress"></div>
      </div>
      <div class="logo">
        <img *ngIf="!isMedi3" src="assets/images/KubaLogo.png" alt="" />
        <img *ngIf="isMedi3" src="assets/images/Medi3Logo.svg" alt="" />
      </div>
      <div class="login-form" *ngIf="!isPhp">
        <form
          name="form"
          (ngSubmit)="f.form.valid && verifyGDPRandLogin()"
          #f="ngForm"
          novalidate
        >
          <div *ngIf="isInvalid && isOnline" class="alert alert-danger mbtm-10">
            <span class="icon ic-xs icon-alert mright-10"></span>Wrong Username
            or Password. Try again or click Forgot Password to reset it.
          </div>
          <div *ngIf="!isOnline" class="alert alert-danger mbtm-10">
            <span class="icon ic-xs icon-alert mright-10"></span>No internet
            connection.
          </div>
          <div
            class="form-group"
            [ngClass]="{ 'has-error': f.submitted && !username.valid }"
          >
            <label for="username" class="col-form-label">Brukernavn</label>
            <input
              type="text"
              class="form-control"
              name="username"
              [(ngModel)]="model.username"
              #username="ngModel"
              required
              autofocus
              autocomplete="username"
            />
            <div *ngIf="f.submitted && !username.valid" class="help-block">
              Username is required
            </div>
          </div>
          <div
            class="form-group"
            [ngClass]="{ 'has-error': f.submitted && !password.valid }"
          >
            <label for="password" class="col-form-label">Passord</label>
            <input
              type="password"
              class="form-control"
              name="password"
              [(ngModel)]="model.password"
              #password="ngModel"
              required
              autocomplete="current-password"
            />
            <div *ngIf="f.submitted && !password.valid" class="help-block">
              Password is required
            </div>
          </div>
          <div class="form-group">
            <p-checkbox
              label="Husk meg"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="model.rememberMe"
              #rememberMe="ngModel"
              [binary]="true"
              (onChange)="RememberMeFunction()"
            ></p-checkbox>
            <a
              href="javascript:void(0):"
              class="btn-link pull-right"
              (click)="goToForgetPassword()"
            >
              <span>Glemt passord?</span>
            </a>
          </div>
          <button
            [disabled]="loading"
            class="btn btn-primary btn-block"
            [ngClass]="{ 'is-medi3': isMedi3 == true }"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ termsAndCondition }}"
  class="policy-modal"
  [style]="{ width: '560px' }"
  [(visible)]="displayDialog"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
  [modal]="true"
  [closable]="false"
>
  <div *ngIf="!isMedi3">
    <h4>PERSONVERNPOLICY</h4>
    <h5>Slik behandler KUBA NORGE AS personopplysninger</h5>
    <p>
      Vi er avhengige av tillit fra våre kunder. Derfor er vi også opptatt av å
      ivareta ditt personvern. Alle dine personopplysninger skal være trygge hos
      oss. Det inkluderer alt som kan knyttes direkte til deg som person, blant
      annet adresse, kontaktinformasjon, GPS-posisjoner og øvrige
      personopplysninger.
    </p>
    <h4>Innsamling av personopplysninger</h4>
    <p>
      KUBA NORGE AS behandler personopplysninger først og fremst i forbindelse
      med kundeservice, kundeoppfølging, kundeadministrasjon, markedsføring,
      fakturering og gjennom avtalene vi har inngått med våre kunder.<br />
      KUBA NORGE AS innhenter som regel opplysningene direkte fra deg. Noen
      ganger henter vi opplysninger fra andre kilder, som kan være både
      offentlige og private institusjoner. I tillegg innhentes GPS posisjoner
      automatisk gjennom noen av våre produkter. Disse GPS posisjonene er også å
      anse som personopplysninger.<br />
      Vi informerer deg når vi innhenter opplysninger om deg og KUBA NORGE AS er
      behandlingsansvarlig for disse opplysningene, med mindre innsamlingen er
      lovbestemt, hvis varsling er umulig eller uforholdsmessig vanskelig, eller
      hvis vi vet at du allerede er kjent med at vi innhenter disse
      opplysningene. Dersom KUBA NORGE AS mottar dine opplysninger i listeform i
      forbindelse med markedsføringsaktiviteter, vil du ved første kommunikasjon
      fra KUBA NORGE AS relatert til markedsføring basert på slik liste, ha
      mulighet til å reservere deg fra fremtidig kommunikasjon basert på slik
      liste. I tilfeller hvor KUBA NORGE AS samler inn personopplysninger på
      oppdrag fra våre kunder, og slik sett opptrer som en databehandler på vår
      kundes vegne, vil det være vår kunde som har ansvaret for informasjonen
      til deg. Vi vil i slike tilfeller også kunne gi deg informasjon direkte,
      men da på oppdrag fra vår kunde.<br />
      Tidvis kan vi gjøre opptak av telefonsamtaler hvis du samtykker til det.
    </p>

    <h4>Kundeundersøkelser</h4>
    <p>
      Når du har vært i kontakt med KUBA NORGE AS, kan vi spørre deg om hvordan
      opplevelsen din var. Da lærer vi mer om hvordan vi kan gi våre kunder enda
      bedre produkter og tjenester. Ved hjelp av disse tilbakemeldingene kan vi
      også måle effekten av forbedringstiltak og se på koblingen mellom
      kundetilfredshet og kundeadferd over tid.<br />
      Hvis du ikke vil dele denne typen opplysninger med oss, kan du la være å
      svare på undersøkelsen vi sender deg.
    </p>

    <h4>Nyhetsbrev og markedsføring</h4>
    <p>
      Du vil kunne motta markedsføring, informasjon og varslinger fra KUBA NORGE
      AS. Dette kan komme på e-post, gjennom våre hjemmesider eller i andre
      hensiktsmessige kanaler. Dette vil kunne komme i tre forskjellige
      kategorier:
    </p>
    <ul>
      <li>Informasjon og nyheter, som regel i form av nyhetsbrev på e-post.</li>
      <li>Systemvarslinger om dine tjenester fra KUBA NORGE AS.</li>
    </ul>
    <p>
      Du kan på ethvert tidspunkt registrere eller avregistrere deg i forhold
      til å motta denne kommunikasjonen.
    </p>

    <h4>Utlevering av personopplysninger</h4>
    <p>
      Internt i KUBA NORGE AS har vi et kunderegister som er tilgjengelig for
      KUBA NORGE AS, datterselskaper og samarbeidspartnere. Formålet med dette
      kunderegisteret er å gi kundene våre best mulig service, samt informasjon
      og tilbud om produkter og tjenester vi tilbyr. Kunderegister kan inneholde
      disse opplysningene om deg:
    </p>
    <ul>
      <li>navn</li>
      <li>kontaktopplysninger</li>
      <li>hvilket selskap du er ansatt i</li>
      <li>opplysninger om hvilket konsernselskap du er kunde i</li>
      <li>hvilke tjenester og produkter du har avtale om</li>
      <li>historikk over din kontakt med KUBA NORGE AS</li>
    </ul>

    <h4>Behandlingsansvarlig</h4>
    <p>
      Behandlingsansvarlig er den som bestemmer formålet med behandlingen av
      personopplysninger. For personopplysninger som samles inn basert på
      oppfyllelse av avtaler med våre kunder vil det som regel være kunden selv
      som er behandlingsansvarlig.
    </p>

    <h4>Databehandler</h4>
    <p>
      Når det er KUBA NORGE AS sin kunde som er Behandlingsansvarlig har KUBA
      NORGE AS rollen som Databehandler. For dette formål er det inngått en
      Databehandleravtale mellom kunden og KUBA NORGE AS.<br />
      KUBA NORGE AS har også inngått avtaler med underleverandører om
      databehandling. Egne databehandleravtaler regulerer alle
      personopplysninger som deles med våre underleverandører. Våre
      underleverandører kan ikke bruke opplysningene til andre formål enn det
      som er bestemt av Behandlingsansvarlig.
    </p>

    <h4>Dine rettigheter</h4>
    <h4>Innsyn</h4>
    <p>
      Du har rett til å få informasjon om hvilke personopplysninger vi behandler
      og hvordan vi behandler dem. En god del av den informasjonen vi har om deg
      som kunde, får du oversikt over på dine personlige sider. Forespørsler om
      ytterligere innsyn gjøres til Behandlingsansvarlig for opplysningene. For
      ansatte i bedrifter som er kunder av KUBA NORGE AS er noen datafelter kun
      synlige for den ansatte selv, og ikke for arbeidsgiver (bl.a. private
      turer i KUBA NORGE AS Kjørebok). Dersom det via arbeidsgiver
      (Behandlingsansvarlig) bes om kopi av alle data registrert på den ansatte,
      gjøres det oppmerksom på at disse data da også kan være synlige for
      arbeidsgiver i denne prosessen.
    </p>

    <h4>Retting</h4>
    <p>
      Det er viktig at opplysningene vi har om deg er riktige og at de er
      nødvendige for å iverksette avtalene vi har inngått med deg og gi deg god
      service. Du kan kreve at vi retter og sletter opplysninger om deg hvis de
      er mangelfulle eller unødvendige. Krav om retting gjøres til
      Behandlingsansvarlig for opplysningene.
    </p>

    <h4>Sletting</h4>
    <p>
      KUBA NORGE AS sletter personopplysninger når de ikke lenger er nødvendige
      for å oppfylle det formålet de ble innhentet for. Det betyr at så lenge du
      har en avtale med oss, lagrer vi nødvendige opplysninger om deg. Når
      avtaleforholdet avsluttes, vil vi slette data med mindre avtale om videre
      lagring er inngått. Mer informasjon om sletting finnes i vilkår for våre
      tjenester. Krav om sletting av opplysninger rettes til
      Behandlingsansvarlig. Om det er lovbestemte krav til minimum lagringstid,
      for eksempel for regnskapsmateriale, vil ikke sletting kunne gjøres før
      denne minimumstiden er passert. Informasjonskapsler (cookies)
    </p>

    <h4>Formål</h4>
    <p>
      Informasjonskapsler (cookies) er små filer som lagrer informasjon om
      hvordan du bruker en internettside. Informasjonen lagres i den nettleseren
      du bruker, altså hos deg. KUBA NORGE AS bruker informasjonskapsler på våre
      hjemmesider for å:
    </p>
    <ul>
      <li>
        lære av din adferd, slik at vi kan forbedre nettstedets funksjonalitet,
        brukeropplevelse og innhold
      </li>
      <li>tilpasse innholdet, slik at det blir mest mulig relevant for deg</li>
      <li>
        gi deg relevant og tilpasset markedsføring på andre nettsteder du
        besøker
      </li>
    </ul>
    <p>
      Du kan oppleve å se annonser fra oss på andre nettsteder, basert på
      innhold og sider du har besøkt på nettsidene våre. Informasjonskapslene
      som brukes for å få til det inneholder kun informasjon om hvilke sider du
      har besøkt på nettstedet vårt, og ikke opplysninger som kan identifisere
      deg som kunde.<br />
      Opplysningene vi innhenter i forbindelse med bruk av våre hjemmesider kan
      i visse tilfeller bli kombinert med informasjon fra ditt øvrige
      kundeforhold.<br />
      Benytter du nettsidene våre uten å skru av funksjonen for
      informasjonskapsler i nettleseren din, aksepterer du KUBA NORGE AS sin
      bruk av informasjonskapsler. Det er dessverre ikke teknisk mulig å logge
      inn på sidene våre hvis du ikke aksepterer at vi legger
      informasjonskapsler i nettleseren din.
    </p>

    <h4>Slik unngår du informasjonskapsler</h4>
    <p>
      Om du ikke vil tillate lagring av informasjonskapsler på din datamaskin,
      må du slå av funksjonen i nettleseren. Vær oppmerksom på at dette vil føre
      til at du mister funksjonalitet på www.kuba.no og andre nettsteder.<br />
      Du kan slette informasjonskapsler ved å følge instruksjonene for sletting
      av informasjonskapsler i din nettleser.<br />
      Hvis du ønsker å tillate informasjonskapsler, men samtidig følge med på
      hva vi egentlig lagrer, så finnes det tillegg til nettleseren din som lar
      deg gjøre det på en forholdsvis enkel måte. Et mye brukt alternativ finner
      du på www.ghostery.com.<br />
    </p>

    <h4>Personvern på www.kuba.no</h4>
    <h4>Bruk av analyseverktøy</h4>
    <p>På www.kuba.no registrerer vi følgende informasjon om deg:</p>
    <ul>
      <li>
        din posisjon, ved bruk av IP-adresse, posisjonsdata eller lignende
      </li>
      <li>
        dine elektroniske spor, for eksempel hvilke sider du besøker og hvilke
        produkter du bestiller
      </li>
      <li>teknisk informasjon om din nettleser og ditt operativsystem</li>
    </ul>
    <p>
      Informasjonen om din adferd på våre nettsider bruker vi til disse
      formålene:
    </p>
    <ul>
      <li>analyse</li>
      <li>personlig tilpassing av nettstedet</li>
      <li>kundeservice</li>
      <li>markedsføring</li>
    </ul>
    <p>
      Vi benytter Google Analytics til trafikkanalyse på www.Kuba Norge AS.no og
      tilhørende nettsteder. Du kan reservere deg mot Google Analytics fra denne
      siden: Google Analytics
    </p>

    <h4>Du kan bli kontaktet</h4>
    <p>
      Dersom du benytter veileder for produkt på våre hjemmesider, kan det hende
      at vi kontakter deg i salgsøyemed basert på din bruk av veilederen.
    </p>

    <h4>Personprofiler</h4>
    <p>
      I KUBA NORGE AS bruker vi ved noen anledninger personprofiler for å
      tilpasse våre tilbud til deg. Personprofiler er en sammenstilling av
      opplysninger vi har fått fra deg, som for eksempel navn, adresse, andre
      opplysninger du har oppgitt, hvilke tjenester du bruker og
      trafikkopplysninger. Når vi benytter oss av profilering i vår
      markedsføring, opplyser vi deg om det.<br />
      Personvernombud<br />
      KUBA NORGE AS sitt personvernombud, Lars Arne Dahl, skal blant annet være
      en hjelp for deg som kunde. Har du spørsmål om hvordan vi behandler dine
      personopplysninger, kan du kontakte personvernombudet på post&#64;kuba.no.
    </p>
    <p>
      På Datatilsynets hjemmesider kan du lese mer om hvilke krav som stilles
      for behandling av personopplysninger.
    </p>
  </div>
  <div *ngIf="isMedi3">
    <h4>PERSONVERNPOLICY</h4>
    <h5>Slik behandler MEDI3 AS personopplysninger</h5>
    <p>
      Vi er avhengige av tillit fra våre kunder. Derfor er vi også opptatt av å
      ivareta ditt personvern. Alle dine personopplysninger skal være trygge hos
      oss. Det inkluderer alt som kan knyttes direkte til deg som person, blant
      annet adresse, kontaktinformasjon, GPS-posisjoner og øvrige
      personopplysninger.
    </p>
    <h4>Innsamling av personopplysninger</h4>
    <p>
      MEDI3 AS behandler personopplysninger først og fremst i forbindelse med
      kundeservice, kundeoppfølging, kundeadministrasjon, markedsføring,
      fakturering og gjennom avtalene vi har inngått med våre kunder.<br />
      MEDI3 AS innhenter som regel opplysningene direkte fra deg. Noen ganger
      henter vi opplysninger fra andre kilder, som kan være både offentlige og
      private institusjoner. I tillegg innhentes GPS posisjoner automatisk
      gjennom noen av våre produkter. Disse GPS posisjonene er også å anse som
      personopplysninger.<br />
      Vi informerer deg når vi innhenter opplysninger om deg og MEDI3 AS er
      behandlingsansvarlig for disse opplysningene, med mindre innsamlingen er
      lovbestemt, hvis varsling er umulig eller uforholdsmessig vanskelig, eller
      hvis vi vet at du allerede er kjent med at vi innhenter disse
      opplysningene. Dersom MEDI3 AS mottar dine opplysninger i listeform i
      forbindelse med markedsføringsaktiviteter, vil du ved første kommunikasjon
      fra MEDI3 AS relatert til markedsføring basert på slik liste, ha mulighet
      til å reservere deg fra fremtidig kommunikasjon basert på slik liste. I
      tilfeller hvor MEDI3 AS samler inn personopplysninger på oppdrag fra våre
      kunder, og slik sett opptrer som en databehandler på vår kundes vegne, vil
      det være vår kunde som har ansvaret for informasjonen til deg. Vi vil i
      slike tilfeller også kunne gi deg informasjon direkte, men da på oppdrag
      fra vår kunde.<br />
      Tidvis kan vi gjøre opptak av telefonsamtaler hvis du samtykker til det.
    </p>

    <h4>Kundeundersøkelser</h4>
    <p>
      Når du har vært i kontakt med MEDI3 AS, kan vi spørre deg om hvordan
      opplevelsen din var. Da lærer vi mer om hvordan vi kan gi våre kunder enda
      bedre produkter og tjenester. Ved hjelp av disse tilbakemeldingene kan vi
      også måle effekten av forbedringstiltak og se på koblingen mellom
      kundetilfredshet og kundeadferd over tid.<br />
      Hvis du ikke vil dele denne typen opplysninger med oss, kan du la være å
      svare på undersøkelsen vi sender deg.
    </p>

    <h4>Nyhetsbrev og markedsføring</h4>
    <p>
      Du vil kunne motta markedsføring, informasjon og varslinger fra MEDI3 AS.
      Dette kan komme på e-post, gjennom våre hjemmesider eller i andre
      hensiktsmessige kanaler. Dette vil kunne komme i tre forskjellige
      kategorier:
    </p>
    <ul>
      <li>Informasjon og nyheter, som regel i form av nyhetsbrev på e-post.</li>
      <li>Systemvarslinger om dine tjenester fra MEDI3 AS.</li>
    </ul>
    <p>
      Du kan på ethvert tidspunkt registrere eller avregistrere deg i forhold
      til å motta denne kommunikasjonen.
    </p>

    <h4>Utlevering av personopplysninger</h4>
    <p>
      Internt i MEDI3 AS har vi et kunderegister som er tilgjengelig for MEDI3
      AS, datterselskaper og samarbeidspartnere. Formålet med dette
      kunderegisteret er å gi kundene våre best mulig service, samt informasjon
      og tilbud om produkter og tjenester vi tilbyr. Kunderegister kan inneholde
      disse opplysningene om deg:
    </p>
    <ul>
      <li>navn</li>
      <li>kontaktopplysninger</li>
      <li>hvilket selskap du er ansatt i</li>
      <li>opplysninger om hvilket konsernselskap du er kunde i</li>
      <li>hvilke tjenester og produkter du har avtale om</li>
      <li>historikk over din kontakt med MEDI3 AS</li>
    </ul>

    <h4>Behandlingsansvarlig</h4>
    <p>
      Behandlingsansvarlig er den som bestemmer formålet med behandlingen av
      personopplysninger. For personopplysninger som samles inn basert på
      oppfyllelse av avtaler med våre kunder vil det som regel være kunden selv
      som er behandlingsansvarlig.
    </p>

    <h4>Databehandler</h4>
    <p>
      Når det er MEDI3 AS sin kunde som er Behandlingsansvarlig har MEDI3 AS
      rollen som Databehandler. For dette formål er det inngått en
      Databehandleravtale mellom kunden og MEDI3 AS.<br />
      MEDI3 AS har også inngått avtaler med underleverandører om databehandling.
      Egne databehandleravtaler regulerer alle personopplysninger som deles med
      våre underleverandører. Våre underleverandører kan ikke bruke
      opplysningene til andre formål enn det som er bestemt av
      Behandlingsansvarlig.
    </p>

    <h4>Dine rettigheter</h4>
    <h4>Innsyn</h4>
    <p>
      Du har rett til å få informasjon om hvilke personopplysninger vi behandler
      og hvordan vi behandler dem. En god del av den informasjonen vi har om deg
      som kunde, får du oversikt over på dine personlige sider. Forespørsler om
      ytterligere innsyn gjøres til Behandlingsansvarlig for opplysningene. For
      ansatte i bedrifter som er kunder av MEDI3 AS er noen datafelter kun
      synlige for den ansatte selv, og ikke for arbeidsgiver (bl.a. private
      turer i MEDI3 AS Kjørebok). Dersom det via arbeidsgiver
      (Behandlingsansvarlig) bes om kopi av alle data registrert på den ansatte,
      gjøres det oppmerksom på at disse data da også kan være synlige for
      arbeidsgiver i denne prosessen.
    </p>

    <h4>Retting</h4>
    <p>
      Det er viktig at opplysningene vi har om deg er riktige og at de er
      nødvendige for å iverksette avtalene vi har inngått med deg og gi deg god
      service. Du kan kreve at vi retter og sletter opplysninger om deg hvis de
      er mangelfulle eller unødvendige. Krav om retting gjøres til
      Behandlingsansvarlig for opplysningene.
    </p>

    <h4>Sletting</h4>
    <p>
      MEDI3 AS sletter personopplysninger når de ikke lenger er nødvendige for å
      oppfylle det formålet de ble innhentet for. Det betyr at så lenge du har
      en avtale med oss, lagrer vi nødvendige opplysninger om deg. Når
      avtaleforholdet avsluttes, vil vi slette data med mindre avtale om videre
      lagring er inngått. Mer informasjon om sletting finnes i vilkår for våre
      tjenester. Krav om sletting av opplysninger rettes til
      Behandlingsansvarlig. Om det er lovbestemte krav til minimum lagringstid,
      for eksempel for regnskapsmateriale, vil ikke sletting kunne gjøres før
      denne minimumstiden er passert. Informasjonskapsler (cookies)
    </p>

    <h4>Formål</h4>
    <p>
      Informasjonskapsler (cookies) er små filer som lagrer informasjon om
      hvordan du bruker en internettside. Informasjonen lagres i den nettleseren
      du bruker, altså hos deg. MEDI3 AS bruker informasjonskapsler på våre
      hjemmesider for å:
    </p>
    <ul>
      <li>
        lære av din adferd, slik at vi kan forbedre nettstedets funksjonalitet,
        brukeropplevelse og innhold
      </li>
      <li>tilpasse innholdet, slik at det blir mest mulig relevant for deg</li>
      <li>
        gi deg relevant og tilpasset markedsføring på andre nettsteder du
        besøker
      </li>
    </ul>
    <p>
      Du kan oppleve å se annonser fra oss på andre nettsteder, basert på
      innhold og sider du har besøkt på nettsidene våre. Informasjonskapslene
      som brukes for å få til det inneholder kun informasjon om hvilke sider du
      har besøkt på nettstedet vårt, og ikke opplysninger som kan identifisere
      deg som kunde.<br />
      Opplysningene vi innhenter i forbindelse med bruk av våre hjemmesider kan
      i visse tilfeller bli kombinert med informasjon fra ditt øvrige
      kundeforhold.<br />
      Benytter du nettsidene våre uten å skru av funksjonen for
      informasjonskapsler i nettleseren din, aksepterer du MEDI3 AS sin bruk av
      informasjonskapsler. Det er dessverre ikke teknisk mulig å logge inn på
      sidene våre hvis du ikke aksepterer at vi legger informasjonskapsler i
      nettleseren din.
    </p>

    <h4>Slik unngår du informasjonskapsler</h4>
    <p>
      Om du ikke vil tillate lagring av informasjonskapsler på din datamaskin,
      må du slå av funksjonen i nettleseren. Vær oppmerksom på at dette vil føre
      til at du mister funksjonalitet på www.medi3.no og andre nettsteder.<br />
      Du kan slette informasjonskapsler ved å følge instruksjonene for sletting
      av informasjonskapsler i din nettleser.<br />
      Hvis du ønsker å tillate informasjonskapsler, men samtidig følge med på
      hva vi egentlig lagrer, så finnes det tillegg til nettleseren din som lar
      deg gjøre det på en forholdsvis enkel måte. Et mye brukt alternativ finner
      du på www.ghostery.com.<br />
    </p>

    <h4>Personvern på www.medi3.no</h4>
    <h4>Bruk av analyseverktøy</h4>
    <p>På www.medi3.no registrerer vi følgende informasjon om deg:</p>
    <ul>
      <li>
        din posisjon, ved bruk av IP-adresse, posisjonsdata eller lignende
      </li>
      <li>
        dine elektroniske spor, for eksempel hvilke sider du besøker og hvilke
        produkter du bestiller
      </li>
      <li>teknisk informasjon om din nettleser og ditt operativsystem</li>
    </ul>
    <p>
      Informasjonen om din adferd på våre nettsider bruker vi til disse
      formålene:
    </p>
    <ul>
      <li>analyse</li>
      <li>personlig tilpassing av nettstedet</li>
      <li>kundeservice</li>
      <li>markedsføring</li>
    </ul>
    <p>
      Vi benytter Google Analytics til trafikkanalyse på www.medi3.no og
      tilhørende nettsteder. Du kan reservere deg mot Google Analytics fra denne
      siden: Google Analytics
    </p>

    <h4>Du kan bli kontaktet</h4>
    <p>
      Dersom du benytter veileder for produkt på våre hjemmesider, kan det hende
      at vi kontakter deg i salgsøyemed basert på din bruk av veilederen.
    </p>

    <h4>Personprofiler</h4>
    <p>
      I MEDI3 AS bruker vi ved noen anledninger personprofiler for å tilpasse
      våre tilbud til deg. Personprofiler er en sammenstilling av opplysninger
      vi har fått fra deg, som for eksempel navn, adresse, andre opplysninger du
      har oppgitt, hvilke tjenester du bruker og trafikkopplysninger. Når vi
      benytter oss av profilering i vår markedsføring, opplyser vi deg om
      det.<br />
      Personvernombud<br />
    </p>
    <h4>Personvernombud</h4>
    <p>
      Spørsmål vedrørende personvernet <br />Har du spørsmål vedrørende hvordan
      vi behandler dine personopplysninger kan du kontakte oss på medi3.no<br />På
      Datatilsynets hjemmesider kan du lese mer om hvilke krav som stilles for
      behandling av personopplysninger.
    </p>
  </div>
  <div style="text-align: right">
    <button type="button" class="btn" (click)="decline()">
      <span translate>Avslå</span>
    </button>
    <button type="button" class="btn btn-primary" (click)="accept()">
      <span translate>Aksepterer</span>
    </button>
  </div>
</p-dialog>
<div class="progress-overlay" *ngIf="isPhp">
  <div class="kuba-loader">
    <span>
      <span></span>
      <span></span>
    </span>
  </div>
</div>
