import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QualitySystemComponent } from './quality-system.component';
import { QualitySystemEditComponent } from './components/quality-system-edit.component';
import { QualitySystemListComponent } from './components/quality-system-list.component';
import { QualitySystemViewComponent } from './components/quality-system-view.component';
import { QualitysystemListComponent } from './components/qualitysystem-list.component';
import { FormsModule } from '@angular/forms';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';
import { RouterModule } from '@angular/router';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { QualitySystemService } from './services/quality-system.service';
import { InternalControlByUserResolver, InternalControlEditResolver, InternalControlFeaturesResolver, InternalControlResolver } from './services/quality-system.resolver.service';
import { HttpClientModule } from '@angular/common/http';
import { AppSharedModule } from '../shared/appShared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';


@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      HttpClientModule,
      AppSharedModule,
      RouterModule,
      ConfirmDialogModule,
      EcplTabNavigationModule,
      KubaExportModule

  ],
  exports: [
      QualitySystemComponent,
      QualitySystemEditComponent,
      QualitySystemListComponent,
      QualitySystemViewComponent,
      QualitysystemListComponent
  ],
  declarations: [
      QualitySystemComponent,
      QualitySystemEditComponent,
      QualitySystemListComponent,
      QualitySystemViewComponent,
      QualitysystemListComponent
  ],
  providers: [
      QualitySystemService,
      InternalControlResolver,
      ConfirmationService,
      InternalControlFeaturesResolver,
      InternalControlEditResolver,
      InternalControlByUserResolver
  ],
})
export class QualitySystemModule { }
