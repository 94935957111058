<div class="card-body">
  <form>
    <div class="page-title">
      <div class="page-action text-right">
        <button type="button" class="btn btn-success" (click)="save()" title="{{'SAVE'|translate}}" translate>
          {{'SAVE' | translate}}
        </button>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <label class="font-weight-bold">{{'STANDARD_TEXT_DEVIATION'| translate}}</label>
          </div>
          <textarea class="form-control" rows="7" [(ngModel)]='saveData.DeviationComment'
            name="insertStandard"></textarea>
          <button style="margin-top: 15px;" type="button" class="btn btn-outline-primary" (click)="insertStandards()"
            title="{{'INSERT_STANDARD'|translate}}" translate>
            {{'INSERT_STANDARD' | translate}}
          </button>
        </div>
      </div>
    </div>

  </form>
</div>
<toaster-component></toaster-component>