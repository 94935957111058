import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { HazardSections, HazardSubSections } from '../../models/hazardanalysis';
import { FoodSafetyServices } from '../../services/foodsafety.services';
import { ActivatedRoute } from '@angular/router';
import { FeatureKey, Status } from 'src/app/_models';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'hazard-analysis',
    templateUrl: 'hazard-analysis.component.html'
})

export class HazardAnalysisComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    hazardAnalysisdata: HazardSections[];
    riskMatrix: SelectItem[];
    featureId: number;
    parentId:any;
    editMode = false;
    businessId: number;
    isLoadForm = false;
    appId: number;
    hazardDefaultIdNorway: number = -2;
    hazardDefaultIdEng: number = -1;
    loading: boolean = false;
    yesOrNo: SelectItem[];
    isClearForm = false;
    private subscriptions: Subscription[] = [];
    constructor(
        private translate: TranslateService,
        private foodSafetyServices: FoodSafetyServices,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
    ) { }

    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.editMode = false;
        this.bindDropDown();
        this.featureId = +this.route.snapshot.params['Id'];
        this.parentId= JSON.parse(sessionStorage.getItem('workingModule'));
        this.parentId= this.parentId.id;
        this.hazardAnalysisdata = <any[]>this.route.snapshot.data['list'];
        if (this.hazardAnalysisdata.length > 0) {
            this.hazardAnalysisdata;
            this.isClearForm = true;
        }
        else {
            this.emptyTemplate();
            this.isLoadForm = true;
        }
    }

    emptyTemplate() {
        this.hazardAnalysisdata = [];
        let hz = new HazardSections();
        hz.Position = 1;
        hz.FoodSafetyId = this.featureId;
        hz.FeatureId = this.parentId;
        hz.Status = Status.Active;
        hz.No = '';
        hz.Name = '';
        hz.ReferenceAndDocument = '';
        hz.ControlFrequency = '';
        hz.HazardSubSections = [];
        hz.HazardSubSections.push(new HazardSubSections());
        hz.HazardSubSections[0].BCPA = '';
        hz.HazardSubSections[0].Risk = '';
        hz.HazardSubSections[0].Description = '';
        hz.HazardSubSections[0].RiskReduceMeasure = '';
        hz.HazardSubSections[0].CCPCBR5 = '';
        hz.HazardSubSections[0].Position = 1;
        hz.HazardSubSections[0].Status = Status.Active;
        hz.HazardSubSections[0].RiskMatrix1 = 1;
        hz.HazardSubSections[0].RiskMatrix2 = 1;
        hz.HazardSubSections[0].RiskMatrix3 = 1;
        hz.HazardSubSections[0].YesOrNo = 0;
        this.hazardAnalysisdata.push(hz);
    }

    bindDropDown() {
        this.subscriptions.push(this.translate.stream('RISK_MATRIX_LIST').subscribe(val => {
            this.riskMatrix = [];
            this.riskMatrix.push(
                { label: val.L, value: 1 },
                { label: val.M, value: 2 },
                { label: val.H, value: 3 }
            )
        }));

        this.subscriptions.push(this.translate.stream('YES_OR_NO_LIST').subscribe(val => {
            this.yesOrNo = [];
            this.yesOrNo.push(
                { label: val.No, value: 0 },
                { label: val.Yes, value: 1 }
            )
        }));
    }

    CalculateRiskMatrix3(secIndex, subSecIndex) {
        let calculateRisk;
        let riskM1 = this.hazardAnalysisdata[secIndex].HazardSubSections[subSecIndex].RiskMatrix1;
        let riskM2 = this.hazardAnalysisdata[secIndex].HazardSubSections[subSecIndex].RiskMatrix2;
        if (riskM1 && riskM2) {
            calculateRisk = this.calculation(
                riskM1, riskM2
            );
            this.hazardAnalysisdata[secIndex].HazardSubSections[subSecIndex].RiskMatrix3 = calculateRisk;
        }
    }

    calculation(rMatrix1, rMatrix2) {
        if (rMatrix1 == rMatrix2) {
            return rMatrix1;
        }
        else if (rMatrix1 == 1 && rMatrix2 == 2) {
            return 1;
        }
        else if (rMatrix1 == 1 && rMatrix2 == 3) {
            return 2;
        }
        else if (rMatrix1 == 2 && rMatrix2 == 3) {
            return 3;
        }
        else if (rMatrix1 == 2 && rMatrix2 == 1) {
            return 1;
        }
        else if (rMatrix1 == 3 && rMatrix2 == 1) {
            return 2;
        }
        else if (rMatrix1 == 3 && rMatrix2 == 2) {
            return 3;
        }
    }
    
    addAboveSection(secIndex) {        
        let itemsToShift = this.hazardAnalysisdata.splice(secIndex, this.hazardAnalysisdata.length);
        let hz = new HazardSections();
        hz.FoodSafetyId = this.featureId;
        hz.FeatureId = this.parentId,
        hz.Status = Status.Active;       
        hz.Position = secIndex + 1;
        hz.HazardSubSections = [];
        hz.HazardSubSections.push(new HazardSubSections());
        hz.HazardSubSections[0].Position = 1;
        hz.HazardSubSections[0].Status = Status.Active;
        hz.HazardSubSections[0].RiskMatrix1 = 1;
        hz.HazardSubSections[0].RiskMatrix2 = 1;
        hz.HazardSubSections[0].RiskMatrix3 = 1;
        this.hazardAnalysisdata.push(hz);
        itemsToShift.forEach(i => {
            ++i.Position;
            this.hazardAnalysisdata.push(i);
        });        
    }

    addNewSection(secIndex) {
        let itemsToShift = this.hazardAnalysisdata.splice(secIndex + 1, this.hazardAnalysisdata.length);
        let hz = new HazardSections();
        hz.FoodSafetyId = this.featureId;
        hz.FeatureId = this.parentId,
        hz.Status = Status.Active;
        hz.Position = secIndex + 2;
        hz.HazardSubSections = [];
        hz.HazardSubSections.push(new HazardSubSections());
        hz.HazardSubSections[0].Position = 1;
        hz.HazardSubSections[0].Status = Status.Active;
        hz.HazardSubSections[0].RiskMatrix1 = 1;
        hz.HazardSubSections[0].RiskMatrix2 = 1;
        hz.HazardSubSections[0].RiskMatrix3 = 1;
        this.hazardAnalysisdata.push(hz);
        itemsToShift.forEach(i => {
            ++i.Position;
            this.hazardAnalysisdata.push(i);
        });
    }

    addSubSection(secIndex, subSecIndex) {
        let itemsToShift = this.hazardAnalysisdata[secIndex].HazardSubSections.splice(subSecIndex + 1, this.hazardAnalysisdata[secIndex].HazardSubSections.length);
        let newItem = new HazardSubSections();
        newItem.Position = subSecIndex + 2;
        newItem.Status = Status.Active;
        newItem.RiskMatrix1 = 1;
        newItem.RiskMatrix2 = 1;
        newItem.RiskMatrix3 = 1;
        this.hazardAnalysisdata[secIndex].HazardSubSections.push(newItem);
        itemsToShift.forEach(i => {
            ++i.Position;
            this.hazardAnalysisdata[secIndex].HazardSubSections.push(i);
        });
    }

    removeSection(secIndex, Id) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                if (Id == 0 || Id == null) {
                    this.hazardAnalysisdata.splice(secIndex, 1);
                    for (let index = secIndex; index < this.hazardAnalysisdata.length; index++) {
                        --this.hazardAnalysisdata[secIndex].Position;
                    }
                }
                else {
                    this.subscriptions.push(this.foodSafetyServices.deleteHazardSection(Id)
                        .subscribe(result => {
                            if (result) {
                                this.hazardAnalysisdata.splice(secIndex, 1);
                                for (let index = secIndex; index < this.hazardAnalysisdata.length; index++) {
                                    --this.hazardAnalysisdata[secIndex].Position;
                                }
                            }
                        }));
                }
            }
        });
    }

    removeSubSection(secIndex, subSecIndex, Id) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                if (Id == 0 || Id == null) {
                    this.hazardAnalysisdata[secIndex].HazardSubSections.splice(subSecIndex, 1);
                    for (let index = subSecIndex; index < this.hazardAnalysisdata[secIndex].HazardSubSections.length; index++) {
                        --this.hazardAnalysisdata[secIndex].HazardSubSections[index].Position;
                    }
                }
                else {
                    this.subscriptions.push(this.foodSafetyServices.deleteHazardSubSection(Id).
                        subscribe(result => {
                            if (result) {
                                this.hazardAnalysisdata[secIndex].HazardSubSections.splice(subSecIndex, 1);
                                for (let index = subSecIndex; index < this.hazardAnalysisdata[secIndex].HazardSubSections.length; index++) {
                                    --this.hazardAnalysisdata[secIndex].HazardSubSections[index].Position;
                                }
                            }
                        }));
                }
            }
        });
    }

    SaveHazard() {
        this.hazardAnalysisdata;
        this.loading = true;
        this.hazardAnalysisdata.forEach(element => {
            if (element.FoodSafetyId != this.featureId) {
                element.FoodSafetyId = this.featureId;
                element.Id = 0;
                element.HazardSubSections.forEach(subElement => {
                    subElement.Id = 0
                });
            }
        });
        this.subscriptions.push(this.foodSafetyServices.saveHazardSectionsAndSubSections(this.hazardAnalysisdata)
            .subscribe((result: any) => {
                if (result) {
                    this.hazardAnalysisdata = result;
                    this.toasterComponent.callToast();
                    this.loading = false;
                }
            }));
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    exportHazardAnalysis() {
        let cultureInfo = sessionStorage.getItem('languageMode');
        let type = 'pdf';
        this.subscriptions.push(this.foodSafetyServices.exportFSHazardAnalysis(BaseServices.ApplicationId,
            BaseServices.BusinessId, this.featureId, cultureInfo,this.parentId)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    let FetureId= JSON.parse(sessionStorage.getItem('workingModule'));
                    FetureId= FetureId.id;
                    if(FetureId==20){
                        link.download = `${this.translate.instant("Hazard Analysis-Food Safety")}.${type.toLowerCase()}`;
                    }else{
                        link.download = `${this.translate.instant("Hazard Analysis-Internal Control")}.${type.toLowerCase()}`;
                    }
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }));
    }

    AddDefault() {
        this.confirmationService.confirm({
            message: this.translate.instant('CONFIRMATION_TO_LOAD_TEMPLATE'),
            accept: () => {
                this.hazardAnalysisdata = [];
                let cultureInfo = sessionStorage.getItem('languageMode')
                if (cultureInfo == "no-NO") {
                    this.GetDefaultHazard(this.hazardDefaultIdNorway);
                }
                else {
                    this.GetDefaultHazard(this.hazardDefaultIdEng);
                }
            }
        });
    }

    GetDefaultHazard(id) {
        this.loading = true;
        this.subscriptions.push(this.foodSafetyServices.getHazardAnalysis(id,this.parentId)
            .subscribe(result => {
                if (result) {
                    this.hazardAnalysisdata = result;
                    this.loading = false;
                }
            }));
    }

    ClearForm(featureKey) {
        if (featureKey > 0) {
            this.confirmationService.confirm({
                message: this.translate.instant('CONFIRMATION_TO_CLEAR_FORM'),
                accept: () => {
                    this.loading = true;
                    this.subscriptions.push(this.foodSafetyServices.deleteHazardForm(featureKey,this.parentId)
                        .subscribe(isHazardFormDeleted => {
                            if (isHazardFormDeleted == true) {
                                this.subscriptions.push(this.foodSafetyServices.getHazardAnalysis(this.featureId, this.parentId)
                                    .subscribe(hazardData => {
                                        this.emptyTemplate();
                                        this.loading = false;
                                        this.toasterComponent.callToastDlt();
                                    }));
                            }
                        }));
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}