export class Faq {
    Id: number;
    FeatureId: number;
    AdditionalFeatureId: number;
    Title: string;
    Description: string;
    VideoPath: string;
    ContentData: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;;
    ModifiedBy: number;
    ModifiedOn: Date;
    constructor() {
        this.Id = 0;
        this.ContentData = '';
    }
}