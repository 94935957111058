import { Component, OnInit } from '@angular/core';

@Component({
     template: `<router-outlet></router-outlet>`
})

export class ActivityLogComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}