<div class="tab-content">
    <div class="page-title">
      <span class="page-actions">
        <a routerLink="new" [routerLink]="['./../new',0]" class="btn btn-success" routerLinkActive="active">
          <span class="icon ic-sm icon-add"></span>
          <span translate>NEW</span>
        </a>
      </span>
    </div>
    <div class="table-view">
      <!-- Table starts -->
      <p-table #dt [value]="suggestionbox" [rows]="10" exportFilename="Sugeestion list" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20]" [responsive]="true"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Caption -->
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix">
            <div class="table-options">
              <div class="pull-left">
              </div>
              <div class="pull-right">
                <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData">
                </kuba-export>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th>{{'TITLE'| translate}}</th>
            <th>{{'DESCRIPTION' | translate}}</th>
            <th>{{'SUGGESTED_BY' | translate}}</th>
            <th *ngIf="showEditIcon"></th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-suggestion>
          <tr>
            <td>
              <span>{{suggestion.Title | capitalize}}</span>
            </td>
            <td>
              <span>{{suggestion.Status==1?'New Suggestion':''}}</span>
            </td>
            <td>
              <span>{{suggestion.Description}}</span>
            </td>
            <td>
              <span>{{suggestion.CreatorName}}</span>
            </td>
            <td class="col-button" *ngIf="showEditIcon">
              <a routerLink="new" [routerLink]="['./../new',suggestion.Id]" class="btn btn-icon"
                title="{{'EDIT' | translate}}" routerLinkActive="active"
                *ngIf="(isVisible!==false)">
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
              <button *ngIf="isVisible" type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                (click)="confirm(suggestion)">
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="showEditIcon" let-columns>
          <tr>
            <td colspan="5">
              {{'NO_RECORDS_FOUND'|translate}}
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="4">
              {{'NO_RECORDS_FOUND'|translate}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table Ends-->
    </div>
  </div>
  <toaster-component></toaster-component>
  <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
  