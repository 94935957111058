import { Component } from '@angular/core';
import { TimetableServices } from "./services/timetable.service";
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
@Component({

    template: `<router-outlet></router-outlet>`
})
export class TimetableComponent { 
     constructor(private http: HttpClient,private TimetableSvc: TimetableServices,translate:TranslateService) {
    }
}
