import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
@Component({

    template: `<router-outlet></router-outlet>`
})
export class NewsComponent { 
     constructor(private http: HttpClient) {
    }
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}
