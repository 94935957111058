<div class="tab-content">
    <form [formGroup]="ruhForm">
        <div class="row">
            <div class="col-sm-5">
                <div class="page-title">
                    <span translate>NEW_INCIDENT </span>
                    <button class="btn btn-link p-0 pull-right">
                        <i class="icon ic-sm icon-info"></i>
                    </button>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>DEPARTMENT</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [options]="departments" [style]="{'width':'100%'}" formControlName="DepartmentId"
                          placeholder="{{'SELECT'|translate}}" (onChange)="onDepartmentChanged(depControl)"
                          #depControl></p-dropdown>
                    </div>
                    <span style="color:red" *ngIf="!isdepartmentFollowupExist">{{noFollowupExistAlert}}</span>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>TOPICS</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [options]="Topics" [style]="{'width':'150px'}" formControlName="TopicID"
                          placeholder="{{'SELECT'|translate}}" #topicDropdown></p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>TYPE_OF_CASE</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [options]="Case" [style]="{'width':'150px'}" formControlName="CaseId"
                          placeholder="{{'SELECT'|translate}}" #caseDropdown
                          (onChange)="bindTopicDropdown(caseDropdown)"></p-dropdown>
                        <span style="color:red" *ngIf="!isCaseTopicExist">{{noTopicExistAlert}}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblWhathappend" class="col-form-label">
                        <span translate>TITLE</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <input type="text" id="txtRelatedIncident" formControlName="Title" pInputText>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblLocation" class="col-form-label">
                        <span translate>LOCATION</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <input type="text" id="txtLocation" pInputText name="Location" style="width: 340px"
                          formControlName="Location">
                    </div>
                </div>
                <div class="form-group">
                    <label for="endDate" class="col-form-label">
                        <span translate>EVENT_DATE</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <my-date-picker name="enddate" [options]="startDateOptions" [style]="{'width':'100%'}"
                          placeholder="{{'SELECT_END_DATE'|translate}}" formControlName="EventDate"></my-date-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblStartTime" class="col-form-label">
                        <span translate>EVENT_TIME</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-calendar [timeOnly]="true" readonlyInput="true" [style]="{'width':'300px'}"
                          styleClass="dateTimeField" formControlName="EventTime">
                        </p-calendar>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblDescription" class="col-form-label">
                        <span translate>DESCRIPTION</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea style="width: 350px;height: 50px;" pInputTextarea
                          formControlName="Description">  </textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblDescription" class="col-form-label">
                        <span translate>CORRECTIVE_ACTIONS</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea style="width: 350px" pInputTextarea formControlName="CorrectiveAction"> </textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblDescription" class="col-form-label">
                        <span translate>SUGGESTIONS_ACTIONS</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea style="width: 350px" pInputTextarea formControlName="SuggestionAction"> </textarea>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                        <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedRuhFiles"
                          (saveChanges)="saveUploaded($event)">
                        </ecpl-document-explorer>
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <button type="button" class="btn btn-outline-secondary" (click)="backToList()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button type="submit" class="btn btn-primary" [ngClass]="{'loading' : loading}"
                      [disabled]="loading || !(ruhForm.valid && isdepartmentFollowupExist)" (click)="saveRuh()">
                        <span *ngIf="!loading" translate>SAVE</span>
                        <span *ngIf="loading" translate>SAVE_PROCESS</span>
                        <span *ngIf="loading" class="loader-icon"></span>
                    </button>
                </div>
            </div>
        </div>
        <toaster-component></toaster-component>
    </form>
</div>