import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FeatureKey } from 'src/app/_models/feature';

@Component({
    selector: 'project-view',
    templateUrl: 'internal-control-view.component.html'
})

export class InternalControlViewComponent implements OnInit {
    pageTitle: string;
    additionalFeatures: any;
    enabledAdditionalFeatures: any;
    icId: number;
    initialPage: any;
    icObject: any;
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.icId = this.activatedRoute.snapshot.params['id'];
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        allAdditionalFeatures.forEach(element => {
            if (element.id === FeatureKey.SAFETY_JOB_ANALYSIS) {
                element.translationKey = 'IC_SJA';
            }
        });
        this.icObject = this.activatedRoute.snapshot.data['edit'];
        this.pageTitle = this.icObject.Title;
        sessionStorage.setItem('InternalCntrlName',this.pageTitle);
        this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
            .filter(x => x.id !== 142); // Hide details tab #KW-276
        if (this.enabledAdditionalFeatures.length > 0) {
            this.route.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.icId }],
                { relativeTo: this.activatedRoute });
        }
    }

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }
}
