<div>
    <h3 translate>SEARCH_RESULTS</h3>
</div>
<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="resultList" #resultsTable [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="searchMode!='Art'" pSortableColumn="Title">{{'TITLE'|translate}}</th>
                    <th *ngIf="searchMode=='Art'" pSortableColumn="filename">{{'TITLE'|translate}}</th>
                    <th *ngIf="searchMode=='Dev'" pSortableColumn="Module">{{'MODULES'|translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-searchData>
                <tr>
                    <td *ngIf="searchMode!='Art'">
                        <span *ngIf="searchMode=='Act'">
                            <a
                                [routerLink]="['../../../../business/'+searchData.BusinessId+'/follow-up/activities/edit',searchData.Id]">{{searchData.Title}}</a>
                        </span>
                        <span *ngIf="(searchMode=='Dev' && searchData.Modules=='Project')">
                            <a
                                [routerLink]="['../../../../business/'+searchData.BusinessId+'/project/details/'+searchData.TopicId+'/deviation/edit',searchData.Id]">{{searchData.Title}}</a>
                        </span>
                        <span *ngIf="(searchMode=='Dev' && searchData.Modules=='Fire Deviations')">
                            <a
                                [routerLink]="['../../../../business/'+searchData.BusinessId+'/fire-deviation/details/'+searchData.TopicId+'/deviation/edit',searchData.Id]">{{searchData.Title}}</a>
                        </span>
                        <span *ngIf="(searchMode=='Dev' && searchData.Modules=='Internal control')">
                            <a
                                [routerLink]="['../../../../business/'+searchData.BusinessId+'/internal-control/details/'+searchData.TopicId+'/deviation/edit',searchData.Id]">{{searchData.Title}}</a>
                        </span>
                        <span *ngIf="(searchMode=='Dev' && searchData.Modules=='')">
                            <a
                                [routerLink]="['../../../../business/'+searchData.BusinessId+'/follow-up/deviations/edit',searchData.Id]">{{searchData.Title}}</a>
                        </span>
                    </td>
                    <td *ngIf="searchMode=='Art'">
                        <span *ngIf="searchMode=='Art'">
                            <span>
                                <ecpl-article-viewer
                                    *ngIf="searchData.type == 'file' && searchData.documentType == 'article'"
                                    [articleName]="searchData.filename" [(articleSrc)]="searchData.content"
                                    [id]="searchData.nodeId" (click)="openArticleViewer(searchData)">
                                </ecpl-article-viewer>
                            </span>
                            {{searchData.filename}}
                        </span>
                    </td>
                    <td *ngIf="searchMode=='Dev'">
                        <span>{{searchData.Module}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="3">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</div>