import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';


@Component({
    selector: 'framework-edit',
    templateUrl: 'framework-agreement-edit.component.html'
})

export class FrameworkEditComponent implements OnInit {

    //#region variables
    contractorName: any;
    //#endregion

    //#region constructors
    /**
     * constructor
     * @param activatedRoute {{ActivatedRoute}}
     * @param location {{Location}}
     */
    constructor(private location: Location) { }
    //#endregion

    //#region page-events
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
    //#endregion

    //#region control-events

    //#endregion

    //#region methods
    gotoBack() {
        this.location.back();
    }
    //#endregion

}
