import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtherSystemComponent } from './other-system.component';
import { OtherSystemEditComponent } from './components/other-system-edit.component';
import { OthersystemListComponent } from './components/othersystem-list.component';
import { OtherSystemListComponent } from './components/other-system-list.component';
import { OtherSystemViewComponent } from './components/other-system-view.component';
import { FormsModule } from '@angular/forms';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';
import { RouterModule } from '@angular/router';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { OtherSystemService } from './services/other-system.service';
import { InternalControlByUserResolver, InternalControlEditResolver, InternalControlFeaturesResolver, InternalControlResolver } from './services/other-system.resolver.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppSharedModule } from '../shared/appShared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppSharedModule,
    RouterModule,
    ConfirmDialogModule,
    EcplTabNavigationModule,
    KubaExportModule
  ],
  exports: [
    OtherSystemComponent,
    OtherSystemEditComponent,
    OtherSystemListComponent,
    OtherSystemViewComponent,
    OtherSystemComponent
  ],
  declarations: [
    OtherSystemComponent, 
    OtherSystemEditComponent, 
    OthersystemListComponent, 
    OtherSystemListComponent, 
    OtherSystemViewComponent
  ],
  providers: [
      OtherSystemService,
      InternalControlResolver,
      ConfirmationService,
      InternalControlFeaturesResolver,
      InternalControlEditResolver,
      InternalControlByUserResolver
  ]
})
export class OtherSystemModule { }
