import { Component, OnInit } from '@angular/core';

import { BaseServices } from './../../kuba.services';
import { BusinessFeatureService } from './../../businesses/services/businessFeatures.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'adminuser-rights',
    templateUrl: 'adminuser-rights.component.html'
})

export class AdminUserRightsComponent implements OnInit {
    //#region variables
    businessFeature: any;
    private subscription: Subscription;
    //#endregion

    //#region constructor
    constructor(private businessSvc: BusinessFeatureService) {
        this.subscription = this.businessSvc.getBusinessFeature(1).subscribe((res) => {
            this.businessFeature = res;
        })
    }
    //#endregion

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}