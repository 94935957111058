import { ToasterComponent } from './../../../_directives/toaster.component';
import { SupplierServices } from './../Services/supplier.services';
import { SelectItem, ConfirmationService} from 'primeng/api';
import { SupplierList } from './../Models/supplierList';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from '../../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'supplier-list',
    templateUrl: 'supplierList.component.html'
})
export class SupplierListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static: false}) dt: Table;
    suppliers: SupplierList[];
    statuses: SelectItem[];
    supplierTypes: SelectItem[];
    selectedSupplier: any;
    isDeleteSupplier: boolean;
    additionalData: any;
    isUser: boolean = false;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private supplierService: SupplierServices,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 2 },
                { label: val.ACTIVE, value: 1 }
            );
        }));

        // filter the type of contractor
        this.subscriptions.push(this.translate.stream('SUB_CONTRACTOR_TYPES').subscribe(val => {
            this.supplierTypes = [];
            this.supplierTypes.push({ label: val.SUB_CONTRACTOR_SELECT, value: null });
            this.supplierTypes.push({ label: val.SUB_CONTRACTOR_PERSON, value: 1 });
            this.supplierTypes.push({ label: val.SUB_CONTRACTOR_COMPANY, value: 2 });
        }));

        this.additionalData = {
            fileName: 'Supplier list',
            header: 'Supplier list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Type of supplier', dataKey: 'SupplierTypeName' },
                { title: 'Mobile', dataKey: 'Mobile' },
                { title: 'Telephone', dataKey: 'Telephone' },
                { title: 'Email', dataKey: 'Email' },
                { title: 'Status', dataKey: 'Status' }
            ]
        };
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.suppliers = this.route.snapshot.data['list'];
        this.isUser = (BaseServices.roleId === '5');
    }
    Binddata() {
        this.subscriptions.push(this.supplierService.getAll().subscribe(res => {
            this.suppliers = res;
        }));
    }
    deleteSupplier(selectedSupplier:any) {
        let supplierIds:any = [];
        selectedSupplier.forEach((element:any) => {
            supplierIds.push(element.Id);
        });
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.supplierService
                    .deleteMultipleSuppliers(supplierIds)
                    .subscribe(result => {
                        this.Binddata();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
        this.isDeleteSupplier = false;
    }
    /**
     *row select event
     */
    onRowSelect(selectedSupplier:any) {
        if (this.selectedSupplier.length > 0) {
            this.isDeleteSupplier = true;
        } else {
            this.isDeleteSupplier = false;
        }
    }
    /**
     *row unselect event
     */
    onRowUnSelect(selectedSupplier:any) {
        if (this.selectedSupplier.length > 0) {
            this.isDeleteSupplier = true;
        } else {
            this.isDeleteSupplier = false;
        }
    }

    /**
     * status dropdown change events
     */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            this.dt.filter(
                statusDropdown.selectedOption.label,
                'StatusText',
                'contains'
            );
        } else {
            this.dt.reset();
        }
    }
    /**
     * status dropdown change events
     */
    ontypeChanged(e: any, typesDropdown: any) {
        if (e.value) {
            this.dt.filter(
                typesDropdown.selectedOption.label,
                'SupplierTypeName',
                'contains'
            );
        } else {
            this.dt.reset();
        }
    }

    editStatus(id:any, status:any) {
        if (status === 'Active') {
            status = '1';
          
        } else if (status === 'InActive') {
            status = '2';
        }
        if(status == '1'){
            this.confirmationService.confirm({
                message: this.translate.instant('EDIT_INACTIVE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.supplierService.statusUpdate(id, status).subscribe(res => {
                        this.Binddata();
                    }));
                }
            });
        }
        else if(status == '2'){
            this.confirmationService.confirm({
                message: this.translate.instant('EDIT_ACTIVE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.supplierService.statusUpdate(id, status).subscribe(res => {
                        this.Binddata();
                    }));
                }
            });
        }
      
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
