import { BusinessListResolver } from './../businesses/services/business.resolver.service';
import { Route } from '@angular/router';
import { AuthGuard } from './../../_guards/index';
import { ContactComponent } from './contact.component';
import {
    ContactEditResolver,
    ContactTypeResolver,
    FunctionListResolver,
    CountryCodeListResolver,
    CustomerContactsWithLoginResolver,
    CustomerContactsWithResolver,
    AdminContactsResolver
} from './services/contact.resolver.service';
import {
    CustomerContactLoginComponent,
    CustomerContactComponent,
    ContactListComponent,
    OurContactComponent,
    ContactEditComponent,
    ContactNewComponent
} from './components/index';

export const contactRoutes: Route[] = [
    {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: ContactListComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'our',
                        component: OurContactComponent,
                        resolve: {
                            list: AdminContactsResolver,
                            contactType: ContactTypeResolver,
                            function: FunctionListResolver,
                        }
                    },
                    {
                        path: 'login',
                        component: CustomerContactLoginComponent,
                        resolve: {
                            list: CustomerContactsWithLoginResolver,
                            function: FunctionListResolver,
                        }
                    },

                    {
                        path: 'contact',
                        component: CustomerContactComponent,
                        resolve: {
                            list: CustomerContactsWithResolver,
                            function: FunctionListResolver,
                            business: BusinessListResolver
                        }
                    }
                ]
            },
            {
                path: 'edit/:cid',
                component: ContactEditComponent,
                canActivate: [AuthGuard],
                resolve: {
                    edit: ContactEditResolver,
                    contactType: ContactTypeResolver,
                    function: FunctionListResolver,
                    countryCode: CountryCodeListResolver
                }
            },
            {
                path: 'new',
                component: ContactNewComponent,
                canActivate: [AuthGuard],
                resolve: {
                    contactType: ContactTypeResolver,
                    function: FunctionListResolver,
                    countryCode: CountryCodeListResolver
                }
            }
        ]
    }
];
