import { FeatureKey } from 'src/app/_models';
import { RiskanalysisService } from './../services/risk-analysis.service';
import { UserService } from './../../users/services/user.service';
import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { IMyDpOptions } from 'mydatepicker';
import * as _ from 'lodash';

import { Feature, Rights } from './../../../_models/feature';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { DeviationList } from '../../deviation/models/deviationList';
import { TypeOfCase, Topic } from './../models/deviation';
import { DeviationServices } from '../services/deviation.services';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { FeaturesBaseServices } from '../../features/services/features-base.services';
import { DeviationListChartsComponent } from './deviation-list-charts/deviation-list-charts.component';
@Component({
  selector: 'deviationList',
  templateUrl: 'deviation-list.component.html',
  styleUrls: ['deviation-list.component.scss'],
})
export class DeviationListComponent implements OnInit, OnDestroy {
  //#region variables
  vDepartment: SelectItem[];
  vModules: SelectItem[];
  showDeviationDrop: boolean = false;
  showDeviationDisabled: boolean = false;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('deviationTable', { static: false }) deviationTable: Table;
  public deviationList: DeviationList[];
  public deviationAllList: DeviationList[];
  statuses: SelectItem[];
  Case: SelectItem[];
  Client: SelectItem[];
  ShowDeviation: SelectItem[];
  status: SelectItem[];
  followup: any[];
  Topics: SelectItem[];
  topicForm: FormGroup;
  deviationSearch: FormGroup;
  caseForm: FormGroup;
  imps: any;
  businesses: any[];
  editTopics: any = [];
  editTypeOfCases: any = [];
  startDate: string;
  endDate: string;
  isStatusDone = false;
  isEditor: boolean;
  isAdmin: boolean;
  isPortal: boolean;
  itemsPerPage = 10;
  pageSum = 0;
  topicId: number;
  caseId: number;
  viewTopic = false;
  addtypeOfCase = false;
  viewTypeOfCase = false;
  addTopic = false;
  isShowDeptDeviation = false;
  isShowSaveButton = true;
  newButtonDisplay = true;
  isDisplayOwnDeviation: boolean;
  userId: number;
  hasDeviationDeleteAccess = true;
  userRole: string;
  additionalData: any;
  isNameExists: boolean;
  existsAlerttext: string;
  departments: any;
  isTopicNameExists: boolean;
  existsTopicAlerttext: string;
  existsTypeCaseAlerttext: string;
  isTypeCaseNameExists: boolean;
  importances: any;
  totalRecords: number;
  isShowPlusbutton = true;
  vresperson: SelectItem[];
  createdBy: SelectItem[];
  showLoader = true;
  currentDeviation: any;
  loading = false;
  isBusinessId = false;
  isShowNewButton = false;
  rowsPerPageOptions: any = [];
  Showloader: boolean;
  departmentList: any[];
  displayDeviation = false;

  deptFilter: any = null;
  clientFilter: any = null;
  createdToFilter: any = null;
  craetedFromFilter: any = null;
  iscreatedToFilter = true;
  descFilter: any = null;
  responsibleFollowUpFilter: any = null;
  responsibleExecutionFilter: any = null;
  createdByFilter: any = null;
  showFilter = false;
  businessFeature: Feature[];
  chartViews: string[] = ['chartType1', 'chartType2', 'chartType3']; // Array of chart types
  currentIndex: number = 0; // Index to keep track of current chart
  isPaused: boolean = false; // Flag to check if cycling is paused
  @ViewChild(DeviationListChartsComponent)
  chartsComponent: DeviationListChartsComponent;
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    selectionTxtFontSize: '14px',
    height: '32px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  public endDateOptions: IMyDpOptions = this.startDateOptions;
  private subscriptions: Subscription[] = [];
  isIKdeviation: boolean = false;
  isAHdeviation: boolean = false;
  isInternalControl: boolean = false;
  isproject: boolean = false;
  isothersystem: boolean = false;
  isqualitysystem: boolean = false;
  isfdv: boolean = false;
  isVehicles: boolean = false;
  isFoodSafety: boolean = false;
  isKubaControl: boolean = false;
  isFramework: boolean = false;
  isService: boolean = false;
  isKunde: boolean = false;
  featuresBaseDetails: any;
  //#endregion

  //#region constructor

  /**
   * constructor
   * @param deviationService {DeviationService}
   * @param route {ActivatedRoute}
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private deviationService: DeviationServices,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private _fb: FormBuilder,
    private userService: UserService,
    private riskanalysisService: RiskanalysisService,
    private translate: TranslateService
  ) {
    this.topicForm = this._fb.group({
      Topic: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.caseForm = this._fb.group({
      Case: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.deviationSearch = this._fb.group({
      topic: [''],
      typeOfCase: [],
    });
    this.userId = BaseServices.UserId;
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.DEVIATION_LIST,
          header: this.translate.instant('DEVIATION_LIST'),
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Deviation Number', dataKey: 'No' },
            { title: 'Title', dataKey: 'Title' },
            { title: 'Checklist Name', datakey: 'ChecklistName' },
            { title: 'Status', dataKey: 'Status' },
            { title: 'Department', dataKey: 'Department' },
            { title: 'Topics', datakey: 'TopicName' },
            { title: 'Type of Case', datakey: 'CaseName' },
            { title: 'Deadline', dataKey: 'DeadlineForPdf' },
            {
              title: 'Responsible for Execution',
              dataKey: 'ResponsiblePerson',
            },
            { title: 'Importance', dataKey: 'Importance.value' },
            { title: 'Description', dataKey: 'Description' },
            { title: 'Modules', dataKey: 'Modules' },
            { title: 'DisplayCost', dataKey: 'Cost' },
            { title: 'DisplayCost', dataKey: 'DisplayCost' },
            { title: 'Event Location', dataKey: 'EventLocation' },
            { title: 'Business', dataKey: 'BusinessName' },
            { title: 'Done On', dataKey: 'CompletedDateForPdf' },
            { title: 'Created On', dataKey: 'CreatedOn' },
            { title: 'Created By', dataKey: 'CreatorName' },
          ],
        };
      })
    );
  }

  //#endregion

  //#region page-events
  /**
   * bind list with status icon
   */
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.currentDeviation = 1;
    this.userRole = BaseServices.UserRole;
    this.newButtonDisplay =
      BaseServices.roleId === '1'
        ? false
        : BaseServices.roleId === '2'
        ? false
        : true;
    this.isShowNewButton =
      BaseServices.roleId === '1'
        ? false
        : BaseServices.roleId === '2'
        ? false
        : true;
    this.isAdmin = BaseServices.roleId === '1' ? true : false;
    this.isPortal = BaseServices.roleId === '2' ? true : false;
    this.isEditor = BaseServices.roleId === '3' ? true : false;
    if (BaseServices.UserRole === 'User' || BaseServices.UserRole === 'Guest') {
      this.onAdministrateDeviation();
    } else {
      this.bindDeviationList();
    }

    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.deviationService.getRModeFeature().subscribe((feature: any) => {
          if (feature) {
            this.businessFeature = <Feature[]>JSON.parse(feature.Features);
            var internal = this.businessFeature.filter(
              (x) => x.name === 'Internalcontrol' && x.checked === true
            );
            var project = this.businessFeature.filter(
              (x) => x.name === 'Project' && x.checked === true
            );
            var othersystem = this.businessFeature.filter(
              (x) => x.name === 'OtherSystems' && x.checked === true
            );
            var qualitysystem = this.businessFeature.filter(
              (x) => x.name === 'QualitySystem' && x.checked === true
            );
            var fdv = this.businessFeature.filter(
              (x) => x.name === 'FDV' && x.checked === true
            );
            var Vehicles = this.businessFeature.filter(
              (x) => x.name === 'Vehicles' && x.checked === true
            );
            var FoodSafety = this.businessFeature.filter(
              (x) => x.name === 'FoodSafety' && x.checked === true
            );
            var KubaControl = this.businessFeature.filter(
              (x) => x.name === 'KubaControl' && x.checked === true
            );
            var Framework = this.businessFeature.filter(
              (x) => x.name === 'Framework Agreement' && x.checked === true
            );
            var Service = this.businessFeature.filter(
              (x) => x.name === 'Service Agreement' && x.checked === true
            );
            var Kunde = this.businessFeature.filter(
              (x) => x.name === 'Kunde EL' && x.checked === true
            );
            if (internal.length > 0) {
              this.isInternalControl = true;
            }
            if (project.length > 0) {
              this.isproject = true;
            }
            if (othersystem.length > 0) {
              this.isothersystem = true;
            }
            if (qualitysystem.length > 0) {
              this.isqualitysystem = true;
            }
            if (fdv.length > 0) {
              this.isfdv = true;
            }
            if (Vehicles.length > 0) {
              this.isVehicles = true;
            }
            if (FoodSafety.length > 0) {
              this.isFoodSafety = true;
            }
            if (KubaControl.length > 0) {
              this.isKubaControl = true;
            }
            if (Framework.length > 0) {
              this.isFramework = true;
            }
            if (Service.length > 0) {
              this.isService = true;
            }
            if (Kunde.length > 0) {
              this.isKunde = true;
            }
          }
        })
      );
    } else {
      let featuresBaseDetails = FeaturesBaseServices.getFeatureDetails(
        BaseServices.getUserFeatures()
      );
      var internal = featuresBaseDetails.filter(
        (x) => x.name === 'Internalcontrol' && x.checked === true
      );
      var project = featuresBaseDetails.filter(
        (x) => x.name === 'Project' && x.checked === true
      );
      var othersystem = featuresBaseDetails.filter(
        (x) => x.name === 'OtherSystems' && x.checked === true
      );
      var qualitysystem = featuresBaseDetails.filter(
        (x) => x.name === 'QualitySystem' && x.checked === true
      );
      var fdv = featuresBaseDetails.filter(
        (x) => x.name === 'FDV' && x.checked === true
      );
      var Vehicles = featuresBaseDetails.filter(
        (x) => x.name === 'Vehicles' && x.checked === true
      );
      var FoodSafety = featuresBaseDetails.filter(
        (x) => x.name === 'FoodSafety' && x.checked === true
      );
      var KubaControl = featuresBaseDetails.filter(
        (x) => x.name === 'KubaControl' && x.checked === true
      );
      var Framework = featuresBaseDetails.filter(
        (x) => x.name === 'Framework Agreement' && x.checked === true
      );
      var Service = featuresBaseDetails.filter(
        (x) => x.name === 'Service Agreement' && x.checked === true
      );
      var Kunde = featuresBaseDetails.filter(
        (x) => x.name === 'Kunde EL' && x.checked === true
      );
      if (internal.length > 0) {
        this.isInternalControl = true;
      }
      if (project.length > 0) {
        this.isproject = true;
      }
      if (othersystem.length > 0) {
        this.isothersystem = true;
      }
      if (qualitysystem.length > 0) {
        this.isqualitysystem = true;
      }
      if (fdv.length > 0) {
        this.isfdv = true;
      }
      if (Vehicles.length > 0) {
        this.isVehicles = true;
      }
      if (FoodSafety.length > 0) {
        this.isFoodSafety = true;
      }
      if (KubaControl.length > 0) {
        this.isKubaControl = true;
      }
      if (Framework.length > 0) {
        this.isFramework = true;
      }
      if (Service.length > 0) {
        this.isService = true;
      }
      if (Kunde.length > 0) {
        this.isKunde = true;
      }
    }

    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    this.bindDepartmentDropdown();
    this.subscriptions.push(
      this.translate.stream('RISKFIGURE_DROPDOWN').subscribe((val) => {
        this.importances = [];
        this.importances.push(
          { label: val.SELECT, value: null },
          { label: val.LOW, value: 'Low' },
          { label: val.MEDIUM, value: 'Medium' },
          { label: val.HIGH, value: 'High' }
        );
      })
    );
    if (BaseServices.UserRole === 'Admin') {
      this.isShowPlusbutton = false;
    }
    if (+BaseServices.roleId === 2) {
      this.isShowPlusbutton = false;
    }
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (+BaseServices.roleId === 5) {
      this.isShowPlusbutton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }
    this.vresperson = [];
    let responsiblePerson: any[];
    responsiblePerson = this.route.snapshot.data['user'];

    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.vresperson = [];
        this.vresperson.push({ label: val.SELECT, value: null });
        if (responsiblePerson) {
          responsiblePerson.forEach((responblePerson: any) => {
            this.vresperson.push({
              label: responblePerson.Name,
              value: responblePerson.Id,
            });
          });
        }
      })
    );
    if (BaseServices.roleId === '3') {
      this.isBusinessId = true;
      this.bindCreatedByDropDown(BaseServices.BusinessId);
    }

    this.vDepartment = [];
    let departments: any[];
    departments = this.route.snapshot.data['departments'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.vDepartment = [];
        this.vDepartment.push({ label: val.SELECT, value: null });
      })
    );
    if (departments) {
      departments.forEach((department: any) => {
        this.vDepartment.push({
          label: department.Name,
          value: department.Id,
        });
      });
    }
    this.subscriptions.push(
      this.translate.stream('RISKFIGURE_DROPDOWN').subscribe((val) => {
        this.importances = [];
        this.importances.push(
          { label: val.SELECT, value: null },
          { label: val.LOW, value: 'Low' },
          { label: val.MEDIUM, value: 'Medium' },
          { label: val.HIGH, value: 'High' }
        );
      })
    );
    if (this.isPortal && BaseServices.ApplicationId != 4) {
      this.translate.stream('VMODULES').subscribe((val) => {
        this.vModules = [];
        this.vModules.push(
          { label: val.SELECT, value: null },
          { label: val.KUBA_Control, value: 'KUBA_Control' },
          { label: val.FDV, value: 'FDV' },
          { label: val.FOLLOW_UP, value: 'Follow up' },
          { label: val.FOOD_SAFETY, value: 'FOOD_SAFETY' },
          { label: val.INTERNAL_CONTROL, value: 'INTERNAL_CONTROL' },
          { label: val.OTHER_SYSTEMS, value: 'OTHER_SYSTEMS' },
          { label: val.PROJECT, value: 'PROJECT' },
          { label: val.QUALITY_SYSTEMS, value: 'QUALITY_SYSTEMS' },
          { label: val.Vehicle, value: 'Vehicle' }
        );
      });
    } else {
      if (BaseServices.ApplicationId != 4) {
        this.isIKdeviation = true;
        this.translate.stream('VMODULES').subscribe((val) => {
          this.vModules = [];
          this.vModules.push(
            { label: val.SELECT, value: null },
            { label: 'FDV', value: 'FDV' },
            { label: 'Follow up', value: 'Follow up' },
            { label: 'SF', value: 'SF (' },
            { label: 'IK', value: 'IK (' },
            { label: 'KK', value: 'KK (' },
            { label: 'SS', value: 'SS (' },
            { label: 'PR', value: 'PR (' },
            { label: 'AS', value: 'AS (' },
            { label: 'RC', value: 'RC (' },
            { label: 'KJ', value: 'KJ (' }
          );
        });
      } else if (BaseServices.ApplicationId == 4) {
        this.isAHdeviation = true;
        this.translate.stream('VMODULES').subscribe((val) => {
          this.vModules = [];
          this.vModules.push(
            { label: val.SELECT, value: null },
            { label: 'EL', value: 'EL (' },
            { label: 'FA', value: 'FA (' },
            { label: 'FDV', value: 'FDV' },
            { label: 'Follow up', value: 'Follow up' },
            { label: 'IK', value: 'IK (' },
            { label: 'PR', value: 'PR (' },
            { label: 'SA', value: 'SA (' },
            { label: 'KJ', value: 'KJ (' }
          );
        });
      } else {
        this.translate.stream('VMODULES').subscribe((val) => {
          this.vModules = [];
          this.vModules.push(
            { label: val.SELECT, value: null },
            { label: val.KUNDE_EL, value: 'KUNDE_EL' },
            { label: val.FRAMEWORK_AGREEMENT, value: 'FRAMEWORK_AGREEMENT' },
            { label: val.FDV, value: 'FDV' },
            { label: val.FOLLOW_UP, value: 'Follow up' },
            { label: val.INTERNAL_CONTROL, value: 'INTERNAL_CONTROL' },
            { label: val.PROJECT, value: 'PROJECT' },
            { label: val.SERVICE_AGREEMENT, value: 'SERVICE_AGREEMENT' },
            { label: val.Vehicle, value: 'Vehicle' }
          );
        });
      }
    }

    // Fetch and log deviationList
    this.subscriptions.push(
      this.deviationService
        .getDeviationByBusiness(BaseServices.BusinessId)
        .subscribe((deviationlist) => {
          if (deviationlist) {
            this.deviationList = deviationlist;
            this.totalRecords = deviationlist.length;
            this.bindStatusIcon(deviationlist);
            this.bindDropDowns(deviationlist);
            this.showLoader = false;
          }
        })
    );
    this.startChartCycling();
  }

  bindCreatedByDropDown(businessId: number) {
    this.createdBy = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.createdBy = [];
        this.createdBy.push({ label: val.SELECT, value: null });
        this.subscriptions.push(
          this.deviationService.getUser(businessId).subscribe((list: any) => {
            if (list) {
              list.forEach((element: any) => {
                this.createdBy.push({
                  label: element.Name,
                  value: element.ContactPersonId,
                });
              });
            }
          })
        );
      })
    );
  }
  /**
   * bind the department dropdown list
   */
  bindDepartmentDropdown() {
    this.departments = [];
    let roleId = BaseServices.roleId;
    if (roleId === '1' || roleId === '2') {
      this.departmentList = this.route.snapshot.data['departmentPortal'];
    } else {
      this.departmentList = this.route.snapshot.data['department'];
    }
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.departments = [];
        this.departments.push({ label: val.SELECT, value: null });
        if (this.departmentList) {
          this.departmentList.forEach((department: any) => {
            this.departments.push({
              label: department.Name,
              value: department.Id,
            });
          });
        }
      })
    );
  }
  //#endregion

  //#region page-destroy-event

  ngOnDestroy() {
    document.querySelector('body').classList.add('opened');
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }

  //#endregion

  //#region control-events
  /**
   * filter list based on followup person
   * @param e {any}
   * @param followUpDropdown {any}
   */
  onFollowupChanged(followUpDropdown: any) {
    sessionStorage.setItem(
      'search_FollowupDev_ResponsibleForFollowup',
      followUpDropdown.value
    );
    // custom filter datatable
    this.deviationTable.filter(
      followUpDropdown.value,
      'FollowUpUserId',
      'equals'
    );
  }
  /**
   * filter list based on execution person
   * @param e {any}
   * @param executionDropdown {any}
   */
  onExecutionChanged(executionDropdown: any) {
    sessionStorage.setItem(
      'search_FollowupDev_ResponsibleForExecution',
      executionDropdown.selectedOption.value
    );
    // custom filter datatable
    if (executionDropdown.selectedOption.value) {
      this.deviationTable.filter(
        executionDropdown.selectedOption.value,
        'ExecutionUserId',
        'equals'
      );
    } else {
      this.deviationTable.filter(null, 'ExecutionUserId', 'equals');
    }
  }
  onBusinessChanged(e: any, businessDropDown: any) {
    if (e.value) {
      this.isBusinessId = true;
      this.bindCreatedByDropDown(e.value);
      this.deviationTable.filter(
        businessDropDown.selectedOption.value,
        'BusinessId',
        'equals'
      );
    } else {
      this.isBusinessId = false;
      this.deviationTable.reset();
    }
  }
  /**
   * filter list based on followup person
   * @param e {any}
   * @param departmentDropdown {any}
   */
  onDepartmentChanged(depControl: any) {
    // custom filter datatable
    this.deviationTable.filter(
      depControl.value,
      'BusinessDepartmentId',
      'equals'
    );
    sessionStorage.setItem('search_FollowupDev_Dept', depControl.value);
  }
  /**
   * filter list based on topic
   * @param e {any}
   * @param topicDropdown {any}
   */
  onTopicChanged(topicDropdown: any) {
    sessionStorage.setItem(
      'search_FollowupDev_Topics',
      topicDropdown.selectedOption.label
    );
    this.deviationTable.filter(
      topicDropdown.selectedOption.label,
      'TopicName',
      'equals'
    );
  }
  /**
   * custom filter datatable of status
   */
  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      this.deviationTable.filter(statusDropdown.value, 'StatusId', 'equals');
    } else {
      this.deviationTable.reset();
    }
  }

  onDepartmentChange(e: any, departmentDropdown: any) {
    if (e.value) {
      this.deviationTable.filter(
        departmentDropdown.selectedOption.label,
        'Department',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }
  onModuleChange(e: any, moduleDropdown: any) {
    if (e.value) {
      this.deviationTable.filter(
        moduleDropdown.selectedOption.value,
        'Modules',
        'contains'
      );
    } else {
      this.deviationTable.reset();
    }
  }

  onImportanceChange(e: any, importanceControl: any) {
    if (e.value) {
      this.deviationTable.filter(
        importanceControl.value,
        'Importance.value',
        'equals'
      );
    } else {
      this.deviationTable.reset();
    }
  }

  /**
   *  filter list based on Type of case
   * @param e  {any}
   * @param caseDropdown  {any}
   */
  onCaseChanged(caseDropdown: any) {
    sessionStorage.setItem(
      'search_FollowupDev_TypeOfCase',
      caseDropdown.selectedOption.label
    );
    this.deviationTable.filter(
      caseDropdown.selectedOption.label,
      'CaseName',
      'equals'
    );
  }
  /**
   *  filter list based on Client
   * @param e {any}
   * @param clientDropdown {any}
   */
  onClientChanged(clientDropdown: any) {
    this.deviationTable.filter(clientDropdown.value, 'CreatedBy', 'equals');
    sessionStorage.setItem('search_FollowupDev_Client', clientDropdown.value);
  }
  /**
   * filter deviation list based on show devaition value
   * @param e {any}
   * @param showDeviationDropdown {any}
   */
  onShowDeviationChanged(e: any) {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.SEE_ONLY_DEVIATION_IN_OWN_DEPT;
    this.isShowDeptDeviation = BaseServices.checkUserRights(
      userRightsId,
      currentUserRole
    );
    if (e.value === '1') {
      this.currentDeviation = 1;
      this.isShowDeptDeviation == true
        ? this.onSeeOnlyDeviationInOwnDepartment()
        : this.bindDeviationList();
    } else if (e.value === '2') {
      this.currentDeviation = 2;
      this.isShowDeptDeviation == true
        ? this.deviationTable.filter(true, 'IsShowDeptDev', 'equals')
        : this.bindDeviationByUser();
    }
  }
  /**
   * save and updating topic
   */
  saveTopic() {
    this.loading = true;
    let reskTopic = new Topic();
    reskTopic.Id = this.topicId ? this.topicId : 0;
    reskTopic.Name = this.topicForm.value.Topic;
    reskTopic.Status = '1';
    reskTopic.BusinessId = BaseServices.BusinessId;
    if (reskTopic.Id > 0) {
      this.subscriptions.push(
        this.deviationService
          .updateTopics(reskTopic)
          .subscribe((isTopicUpdated) => {
            if (isTopicUpdated) {
              this.toasterComponent.callToast();
              this.loading = false;
              this.bindTopicDropdown();
              this.deviationSearch.get('topic')!.patchValue(isTopicUpdated.Id);
            } else {
              this.existsTopicAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isTopicNameExists = true;
              this.loading = false;
              setTimeout(() => {
                this.isTopicNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.deviationService.addTopics(reskTopic).subscribe((isTopicAdded) => {
          if (isTopicAdded) {
            this.toasterComponent.callToast();
            this.loading = false;
            this.bindTopicDropdown();
            this.riskanalysisService.clearCachedTopic();
            this.deviationSearch.get('topic')!.patchValue(isTopicAdded.Id);
          } else {
            this.existsTopicAlerttext = this.translate.instant(`TOPIC_EXIST`);
            this.isTopicNameExists = true;
            this.loading = false;
            setTimeout(() => {
              this.isTopicNameExists = false;
            }, 3000);
          }
        })
      );
    }
    this.clear();
  }
  clear() {
    this.topicId = 0;
    this.topicForm = this._fb.group({
      Topic: '',
    });
  }
  /**
   * save type of case
   */
  saveTypeOfCase() {
    this.loading = true;
    let typeOfcase = new TypeOfCase();
    typeOfcase.Id = this.caseId ? this.caseId : 0;
    typeOfcase.Name = this.caseForm.value.Case;
    typeOfcase.Status = '1';
    typeOfcase.BusinessId = BaseServices.BusinessId;
    typeOfcase.ApplicationId = BaseServices.ApplicationId;
    if (typeOfcase.Id > 0) {
      typeOfcase.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .updateCaseType(typeOfcase.Id, typeOfcase)
          .subscribe((isCaseUpdated) => {
            if (isCaseUpdated) {
              this.addtypeOfCase = false;
              this.bindTypeofCaseDropdown();
              this.deviationSearch
                .get('typeOfCase')!
                .patchValue(isCaseUpdated.Id);
              this.toasterComponent.callToast();
              this.loading = false;
            } else {
              this.existsTypeCaseAlerttext =
                this.translate.instant(`TOPIC_EXIST`);
              this.isTypeCaseNameExists = true;
              this.loading = false;
              setTimeout(() => {
                this.isTypeCaseNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      typeOfcase.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.deviationService
          .addTypeCase(typeOfcase)
          .subscribe((isCaseAdded) => {
            if (isCaseAdded) {
              this.addtypeOfCase = false;
              this.deviationService.clearCachedCaseType();
              this.deviationSearch
                .get('typeOfCase')!
                .patchValue(isCaseAdded.Id);
              this.bindTypeofCaseDropdown();
              this.toasterComponent.callToast();
              this.loading = false;
            } else {
              this.existsTypeCaseAlerttext =
                this.translate.instant(`TOPIC_EXIST`);
              this.isTypeCaseNameExists = true;
              this.loading = false;
              setTimeout(() => {
                this.isTypeCaseNameExists = false;
              }, 3000);
            }
          })
      );
    }
    this.clearCaseForm();
  }
  clearCaseForm() {
    this.caseId = 0;
    this.caseForm = this._fb.group({
      Case: '',
    });
  }
  /**
   * startdate calendar onselect event
   * @param event {any}
   */
  onStartDateChanged(event: any) {
    this.iscreatedToFilter = true;
    sessionStorage.setItem('search_FollowupDev_CreatedFrom', event.jsdate);
    // custom filter datatable
    this.startDate = event.jsdate;
    if (this.startDate) {
      this.dateRangeFilter(this.startDate, this.endDate);
    } else {
      this.bindBothDeviation();
    }
  }

  /**
   * enddate calendar onselect event
   * @param event {any}
   */
  onEndDateChanged(event: any) {
    this.iscreatedToFilter = true;
    // custom filter datatable
    sessionStorage.setItem('search_FollowupDev_CreatedTo', event.jsdate);
    this.endDate = event.jsdate;
    if (this.endDate) {
      this.dateRangeFilter(this.startDate, this.endDate);
    } else {
      this.bindBothDeviation();
    }
  }
  /**
   * edit topic
   * @param Topictype {any}
   */
  editTopicType(topic: Topic) {
    this.topicId = topic.Id!;
    let result = {
      Topic: topic.Name,
    };
    (<FormGroup>this.topicForm).setValue(result, { onlySelf: true });
  }
  /**
   * delete topic
   * @param id {any}
   */
  deleteTopic(id: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.deviationService.deleteTopics(id).subscribe((isTopicDeleted) => {
            if (isTopicDeleted) {
              this.riskanalysisService.clearCachedTopic();
              this.bindTopicDropdown();
            }
          })
        );
      },
    });
  }
  /**
   * edit type of case
   * @param TypeOfCase {any}
   */
  editTypeOfCase(typeOfCase: TypeOfCase) {
    this.caseId = typeOfCase.Id!;
    let result = {
      Case: typeOfCase.Name,
    };
    (<FormGroup>this.caseForm).setValue(result, { onlySelf: true });
  }
  /**
   * delete type of case
   * @param deleteType {any}
   */
  deleteTypeOfCase(deleteType: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.deviationService
            .deleteTypeCase(deleteType)
            .subscribe((isCaseDeleted) => {
              if (isCaseDeleted) {
                this.deviationService.clearCachedCaseType();
                this.bindTypeofCaseDropdown();
              }
            })
        );
      },
    });
  }
  /**
   * fetches data and refreshes grid
   */
  clearFilters() {
    if (BaseServices.UserRole === 'User' || BaseServices.UserRole === 'Guest') {
      this.bindDeviationByUser();
    }
    // else {
    //     this.bindDeviationList();
    // }
    sessionStorage.setItem('search_FollowupDev_Dept', null);
    sessionStorage.setItem('search_FollowupDev_Client', null);
    sessionStorage.setItem('search_FollowupDev_CreatedTo', null);
    sessionStorage.setItem('search_FollowupDev_CreatedFrom', null);
    sessionStorage.setItem('search_FollowupDev_Topics', null);
    sessionStorage.setItem('search_FollowupDev_Desc', null);
    sessionStorage.setItem('search_FollowupDev_TypeOfCase', null);
    sessionStorage.setItem('search_FollowupDev_ResponsibleForFollowup', null);
    sessionStorage.setItem('search_FollowupDev_ResponsibleForExecution', null);
    sessionStorage.setItem('search_FollowupDev_CreatedBy', null);
    this.deptFilter = null;
    this.clientFilter = null;
    this.createdToFilter = null;
    this.craetedFromFilter = null;
    this.deviationSearch.controls['topic'].setValue('');
    this.deviationSearch.controls['typeOfCase'].setValue('');
    this.descFilter = null;
    this.responsibleFollowUpFilter = null;
    this.responsibleExecutionFilter = null;
    this.deviationTable.reset();
  }
  /**
   * page change event
   * @param event {any}
   */
  onPageChange(event: any) {
    let ends: number = parseInt(event.first, 10) + parseInt(event.rows, 10);
    this.getSum(this.deviationAllList, event.first, ends);
  }
  /**
   * topic popup reset
   */
  onBeforeDeviationTopicDialogHide() {
    this.topicForm.reset();
  }

  /**
   * type of case popup reset
   */
  onBeforeDeviationTypeofCaseDialogHide() {
    this.caseForm.reset();
  }
  bindBothDeviation() {
    if (this.currentDeviation === 1) {
      this.bindDeviationList();
    } else if (this.currentDeviation === 2) {
      this.bindDeviationByUser();
    }
  }
  //#endregion

  //#region methods
  /**
   * bind deviation list by User Id
   */
  bindDeviationByUser() {
    if (BaseServices.UserRole == 'User' || BaseServices.UserRole == 'Guest') {
      this.deviationList = [];
      this.subscriptions.push(
        this.deviationService
          .getDeviationsByUser(BaseServices.UserId)
          .subscribe((result) => {
            if (result) {
              let deviations = <any>result;
              this.bindDropDowns(deviations);
              if (deviations) {
                this.bindStatusIcon(deviations);
              }
            }
          })
      );
    }
  }
  /**
   *
   * bind status icon by status
   * @param deviations {any}
   */
  bindStatusIcon(deviations: any) {
    this.deviationList = [];
    if (deviations) {
      deviations.forEach((deviationInfo: any) => {
        let daysDiff = 0;
        let statusIcon = '';
        let currentdate = new Date();
        let deadLine = new Date(deviationInfo.Deadline);
        deviationInfo.Importance = this.assignRiskData(
          deviationInfo.Importance
        );
        if (deviationInfo.Deadline != null) {
          daysDiff = HelperService.getDaysDiff(currentdate, deadLine);
        }
        if (deviationInfo.StatusId === 9) {
          statusIcon = 'icon ic-sm icon-clear-deadline';
        } else if (deviationInfo.StatusId === 10) {
          statusIcon = 'icon ic-sm icon-cancel';
        } else if (deviationInfo.StatusId === 7) {
          statusIcon = 'icon ic-sm icon-new-deadline';
        } else if (deviationInfo.Deadline) {
          if (daysDiff > 3) {
            statusIcon = 'icon ic-sm icon-far-deadline';
          } else if (daysDiff > -1 && daysDiff < 4) {
            statusIcon = 'icon ic-sm icon-near-deadline';
          } else {
            statusIcon = 'icon ic-sm icon-over-deadline';
          }
        } else {
          statusIcon = 'icon ic-sm icon-over-deadline';
        }
        if (
          +deviationInfo.ClientId === FeatureKey.FOOD_SAFETY &&
          sessionStorage.getItem('languageId') === '2'
        ) {
          deviationInfo.Modules = deviationInfo.Modules.replace('FS', 'IK-Mat');
        }
        deviationInfo.StatusIcon = statusIcon;
        this.deviationList.push(deviationInfo);
        this.rowsPerPageOptions = [10, 20, 50, 100];
        if (this.deviationList.length > 100) {
          this.rowsPerPageOptions.push(this.deviationList.length);
        }
      });
      this.globalDeviationFilter();
    }
  }

  globalDeviationFilter() {
    if (this.deviationList.length > 0) {
      let sessiondeptFilter = sessionStorage.getItem('search_FollowupDev_Dept');
      let sessionClientFilter = sessionStorage.getItem(
        'search_FollowupDev_Client'
      );
      let createdToFilter = sessionStorage.getItem(
        'search_FollowupDev_CreatedTo'
      );
      let createdFromFilter = sessionStorage.getItem(
        'search_FollowupDev_CreatedFrom'
      );
      let topicsFilter = sessionStorage.getItem('search_FollowupDev_Topics');
      let descFilter = sessionStorage.getItem('search_FollowupDev_Desc');
      let typeOfCaseFilter = sessionStorage.getItem(
        'search_FollowupDev_TypeOfCase'
      );
      let responsibleForFollowup = sessionStorage.getItem(
        'search_FollowupDev_ResponsibleForFollowup'
      );
      let responsibleForExecution = sessionStorage.getItem(
        'search_FollowupDev_ResponsibleForExecution'
      );
      let createdByFilter = sessionStorage.getItem(
        'search_FollowupDev_CreatedBy'
      );
      if (sessiondeptFilter && sessiondeptFilter !== 'null') {
        this.deptFilter = sessiondeptFilter;
        this.deviationTable.filter(
          sessiondeptFilter,
          'BusinessDepartmentId',
          'equals'
        );
      }
      if (sessionClientFilter && sessionClientFilter !== 'null') {
        this.clientFilter = sessionClientFilter;
        this.deviationTable.filter(sessionClientFilter, 'CreatedBy', 'equals');
      }
      if (topicsFilter && topicsFilter !== 'null') {
        this.deviationSearch.controls['topic'].setValue(topicsFilter);
        this.deviationTable.filter(topicsFilter, 'TopicId', 'equals');
      }

      if (descFilter && descFilter !== 'null') {
        this.descFilter = descFilter;
        this.deviationTable.filter(descFilter, 'Description', 'contains');
      }
      if (typeOfCaseFilter && typeOfCaseFilter !== 'null') {
        this.deviationSearch.controls['typeOfCase'].setValue(typeOfCaseFilter);
        this.deviationTable.filter(typeOfCaseFilter, 'CaseId', 'equals');
      }

      if (responsibleForFollowup && responsibleForFollowup !== 'null') {
        this.responsibleFollowUpFilter = responsibleForFollowup;
        this.deviationTable.filter(
          responsibleForFollowup,
          'FollowUpUserId',
          'equals'
        );
      }

      if (responsibleForExecution && responsibleForExecution !== 'null') {
        this.responsibleExecutionFilter = responsibleForExecution;
        this.deviationTable.filter(
          responsibleForExecution,
          'ExecutionUserId',
          'equals'
        );
      }

      if (createdByFilter && createdByFilter !== 'null') {
        this.createdByFilter = createdByFilter;
        this.deviationTable.filter(createdByFilter, 'CreatorName', 'contains');
      }

      this.createdToFilter =
        createdToFilter && createdToFilter !== 'null'
          ? HelperService.formatInputDate(new Date(createdToFilter))
          : null;
      this.craetedFromFilter =
        createdFromFilter && createdFromFilter !== 'null'
          ? HelperService.formatInputDate(new Date(createdFromFilter))
          : null;
      if (this.createdToFilter || this.craetedFromFilter) {
        if (this.iscreatedToFilter) {
          this.dateRangeFilter(
            createdFromFilter && createdFromFilter !== 'null'
              ? new Date(createdFromFilter)
              : null,
            createdToFilter && createdToFilter !== 'null'
              ? new Date(createdToFilter)
              : null
          );
          this.iscreatedToFilter = false;
        }
      }
    }
  }

  /**
   * get deviation list
   */
  bindDeviationList() {
    this.deviationList = [];
    this.subscriptions.push(
      this.deviationService
        .getDeviationByBusiness(BaseServices.BusinessId)
        .subscribe((deviationlist) => {
          if (deviationlist) {
            this.totalRecords = deviationlist.length;
            if (deviationlist.Status === 'DONE') {
              this.isStatusDone = false;
            }

            this.bindStatusIcon(deviationlist);
            this.bindDropDowns(deviationlist);
            this.showLoader = false;
          }
        })
    );
  }
  dateRangeFilter(StartDate: any, EndDate: any) {
    let startDate = StartDate ? StartDate : new Date('1800-01-01');
    let endDate = EndDate ? EndDate : new Date();
    this.deviationList = [];
    let list: any;
    if (this.currentDeviation === 1) {
      this.subscriptions.push(
        this.deviationService
          .getDeviationByBusiness(BaseServices.BusinessId)
          .subscribe((deviationlist) => {
            if (deviationlist) {
              list = deviationlist.filter((x: any) => {
                return (
                  new Date(x.CreatedOn) >= startDate &&
                  new Date(x.CreatedOn) <= endDate
                );
              });
            }
            this.totalRecords = list.length;
            if (list.Status === 'Done') {
              this.isStatusDone = false;
            }
            this.bindStatusIcon(list);
            this.bindDropDowns(list);
            this.showLoader = false;
          })
      );
    } else if (this.currentDeviation === 2) {
      let deviations = <any>this.route.snapshot.data['deviationByUser'];
      if (deviations) {
        list = deviations.filter((x: any) => {
          return (
            new Date(x.Deadline) >= startDate && new Date(x.Deadline) <= endDate
          );
        });
      }
      this.totalRecords = list.length;
      if (list.Status === 'Done') {
        this.isStatusDone = false;
      }
      this.bindStatusIcon(list);
      this.bindDropDowns(list);
      this.showLoader = false;
    }
  }

  /**
   * color risk figure
   * @param riskFigure {any}
   */
  assignRiskData(riskFigure: any) {
    let riskData: any = [];
    if (riskFigure!.value) {
      return riskFigure;
    } else {
      if (riskFigure === 'Low') {
        riskData.id = 1;
        riskData.value = 'LOW';
        riskData.backColor = '#4ec55c';
      } else if (riskFigure === 'Medium') {
        riskData.id = 2;
        riskData.value = 'MEDIUM';
        riskData.backColor = '#f0ac19';
      } else if (riskFigure === 'High') {
        riskData.id = 3;
        riskData.value = 'HIGH';
        riskData.backColor = '#e7644f';
      } else {
        riskData.backColor = '';
      }
      return riskData;
    }
  }
  /**
   * delete deviation
   * @param id {any}
   */
  confirm(id: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.deviationService.deleteDeviation(id);
        this.bindDeviationList();
      },
    });
  }
  /**
   * bind dropdowns
   */
  bindDropDowns(deviations: any) {
    this.followup = [];
    let followUpPerson: any[];
    followUpPerson = this.route.snapshot.data['user'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.followup = [];
        this.followup.push({ label: val.SELECT, value: null });
        if (followUpPerson) {
          followUpPerson.forEach((followupPerson: any) => {
            this.followup.push({
              label: followupPerson.Name,
              value: followupPerson.Id,
            });
          });
        }
      })
    );

    this.Case = [];
    this.editTypeOfCases = [];
    let caseType: any[];
    caseType = this.route.snapshot.data['caseType'];
    const uniquecaseTypeSet = new Map<string, any>();

    caseType.forEach((item) => {
      // Assuming 'name' is the property you want to filter on
      const name = item.Name;

      // If the name is not already in the Map, add the item to the Map
      if (!uniquecaseTypeSet.has(name)) {
        uniquecaseTypeSet.set(name, item);
      }
    });
    // Convert Map values back to an array if needed
    const uniquecaseType = Array.from(uniquecaseTypeSet.values());

    if (BaseServices.roleId != '2') {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Case = [];
          this.Case.push({ label: val.SELECT, value: null });
          if (caseType) {
            caseType.forEach((caseInfo: any) => {
              this.Case.push({ label: caseInfo.Name, value: caseInfo.Id });
              this.editTypeOfCases.push({
                Name: caseInfo.Name,
                Id: caseInfo.Id,
                IsDefault: caseInfo.IsDefault,
              });
            });
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Case = [];
          this.Case.push({ label: val.SELECT, value: null });
          if (uniquecaseType) {
            uniquecaseType.forEach((caseInfo: any) => {
              this.Case.push({ label: caseInfo.Name, value: caseInfo.Id });
              this.editTypeOfCases.push({
                Name: caseInfo.Name,
                Id: caseInfo.Id,
                IsDefault: caseInfo.IsDefault,
              });
            });
          }
        })
      );
    }

    this.Client = [];
    let clients = this.route.snapshot.data['client'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.Client = [];
        this.Client.push({ label: val.SELECT, value: null });
        if (clients) {
          clients.forEach((clientInfo: any) => {
            this.Client.push({
              label: clientInfo.Name,
              value: clientInfo.Id,
            });
          });
        }
      })
    );
    this.businesses = [];
    let business = this.route.snapshot.data['business'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.businesses.push({ label: val.SELECT, value: null });
      })
    );
    if (business) {
      business.forEach((businessInfo: any) => {
        this.businesses.push({
          label: businessInfo.Name,
          value: businessInfo.Id,
        });
      });
    }

    this.Topics = [];
    this.editTopics = [];
    let topics: any[];
    topics = this.route.snapshot.data['topic'];
    const uniquecaseTopicSet = new Map<string, any>();

    topics.forEach((item) => {
      // Assuming 'name' is the property you want to filter on
      const name = item.Name;

      // If the name is not already in the Map, add the item to the Map
      if (!uniquecaseTopicSet.has(name)) {
        uniquecaseTopicSet.set(name, item);
      }
    });
    // Convert Map values back to an array if needed
    const uniquecaseTopic = Array.from(uniquecaseTopicSet.values());
    if (BaseServices.roleId != '2') {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Topics = [];
          this.Topics.push({ label: val.SELECT, value: null });
          if (topics) {
            topics.forEach((topicInfo: any) => {
              this.Topics.push({
                label: topicInfo.Name,
                value: topicInfo.Id,
              });
              this.editTopics.push({
                Name: topicInfo.Name,
                Id: topicInfo.Id,
              });
            });
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Topics = [];
          this.Topics.push({ label: val.SELECT, value: null });
          if (uniquecaseTopic) {
            uniquecaseTopic.forEach((topicInfo: any) => {
              this.Topics.push({
                label: topicInfo.Name,
                value: topicInfo.Id,
              });
              this.editTopics.push({
                Name: topicInfo.Name,
                Id: topicInfo.Id,
              });
            });
          }
        })
      );
    }

    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.SELECT, value: null },
          { label: val.NEW, value: 7 },
          { label: val.OPEN, value: 8 },
          { label: val.DONE, value: 9 },
          { label: val.REJECTED, value: 10 }
        );
      })
    );

    this.subscriptions.push(
      this.translate.stream('SELECT_DEVIATIONDROP').subscribe((val) => {
        this.ShowDeviation = [];

        let userRightsId = Rights.ADMINISTRATE_DEVIATION;
        let role = BaseServices.UserRole;
        if (role === 'Editor') {
          this.ShowDeviation.push(
            { label: val.ALL_DEVIATION, value: '1' },
            { label: val.MY_DEVIATION, value: '2' }
          );
        } else if (role === 'User') {
          this.showDeviationDrop = BaseServices.checkUserRights(
            userRightsId,
            role
          );
          if (this.showDeviationDrop) {
            this.ShowDeviation.push(
              { label: val.ALL_DEVIATION, value: '1' },
              { label: val.MY_DEVIATION, value: '2' }
            );
          } else {
            let currentUserRole = BaseServices.UserRole;
            let userRightsId = Rights.VIEW_DEVIATION;
            let IsViewDeviation = BaseServices.checkUserRights(
              userRightsId,
              currentUserRole
            );
            let currentUserRole2 = BaseServices.UserRole;
            let userRightsId2 = Rights.SEE_ONLY_DEVIATION_IN_OWN_DEPT;
            let IsSeeOnlyDepartViewDev = BaseServices.checkUserRights(
              userRightsId2,
              currentUserRole2
            );
            if (IsViewDeviation) {
              this.ShowDeviation.push(
                { label: val.ALL_DEVIATION, value: '1' },
                { label: val.MY_DEVIATION, value: '2' }
              );
            } else if (IsSeeOnlyDepartViewDev) {
              this.ShowDeviation.push(
                { label: val.ALL_DEVIATION, value: '1' },
                { label: val.MY_DEVIATION, value: '2' }
              );
            } else {
              this.showDeviationDisabled = true;
              this.ShowDeviation.push({ label: val.MY_DEVIATION, value: '2' });
            }
          }
        } else {
          this.showDeviationDisabled = true;
          this.ShowDeviation.push({ label: val.MY_DEVIATION, value: '2' });
          this.bindDeviationByUser();
        }
      })
    );
    this.setDoneStatus(deviations);
  }

  setDoneStatus(deviations: any) {
    if (deviations) {
      deviations.forEach((e: any) => {
        if (e.StatusId === 9) {
          this.isStatusDone = false;
        } else {
          this.isStatusDone = true;
        }
      });
      this.deviationAllList = deviations;
      this.totalRecords = deviations.length;
      this.bindStatusIcon(deviations);
      this.getSum(deviations, 0, this.itemsPerPage);
    }
  }
  /**
   * bind type of case dropdown
   */
  bindTypeofCaseDropdown() {
    this.subscriptions.push(
      this.deviationService.getAllCaseType().subscribe((typeCaseList) => {
        if (typeCaseList) {
          this.Case = [];
          typeCaseList.forEach((typeCase: any) => {
            this.Case.push({ label: typeCase.Name, value: typeCase.Id });
          });
        }
        this.editTypeOfCases = typeCaseList;
      })
    );
  }
  /**
   * bind topic dropdown
   */
  bindTopicDropdown() {
    this.subscriptions.push(
      this.deviationService
        .getAllTopics(BaseServices.BusinessId)
        .subscribe((topicList) => {
          if (topicList) {
            this.Topics = [];
            this.subscriptions.push(
              this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                this.Topics = [];
                this.Topics.push({ label: val.SELECT, value: null });
              })
            );
            topicList.forEach((topic: any) => {
              this.Topics.push({
                label: topic.Name,
                value: topic.Id,
              });
            });
            this.editTopics = topicList;
          }
        })
    );
  }
  /**
   * get days difference
   * @param StartDate {date}
   * @param EndDate {date}
   */
  getDaysDiff(StartDate: Date, EndDate: Date) {
    if (StartDate && EndDate) {
      let _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let date1 = Date.UTC(
        StartDate.getFullYear(),
        StartDate.getMonth(),
        StartDate.getDate()
      );
      let date2 = Date.UTC(
        EndDate.getFullYear(),
        EndDate.getMonth(),
        EndDate.getDate()
      );
      let res: any = Math.floor((date2 - date1) / _MS_PER_DAY);
      return res;
    }
  }

  /**
   * calculate total Cost
   */
  calculateGroupTotal() {
    let total = 0;
    if (this.deviationList) {
      this.deviationList.forEach((pageCost) => {
        if (pageCost.Cost) {
          total += pageCost.Cost;
        }
      });
    }
    return total;
  }
  deviationFilterChange(event: any) {
    let list = event.filteredValue;
    if (list.length > 0) {
      this.getSum(list, 0, this.itemsPerPage);
    }
  }
  deviationPageChange(event: any) {
    let list = this.deviationList.slice(event.first, event.first + event.rows);
    if (list.length > 0) {
      this.getSum(list, 0, this.itemsPerPage);
    }
  }
  /**
   * page wise cost calculation
   * @param deviations {DeviationList}
   * @param start {number}
   * @param end {number}
   */
  getSum(deviations: DeviationList[], start: number, end: number): void {
    deviations = _.slice(deviations, start, end);
    let sum = _.sumBy(deviations, (o) => {
      return o.Cost || 0;
    });
    this.pageSum = this.convertDecimal(sum);
    //  this.bindStatusIcon(deviations);
  }
  convertDecimal(data: any) {
    if (data !== null) {
      if (data === 0) {
        return data.toFixed(2);
      } else {
        return data.toFixed(2);
      }
    }
  }
  /**
   * get dicimal number
   * @param data {any}
   */
  decimalCalculation(data: any, i: any) {
    if (data) {
      let number = data[i].Price;
      return number ? number.toFixed(2) : 0;
    }
    return 0;
  }
  // TODO: to be implemented
  showAll() {}

  /**
   * see only their department deviation based on their rights
   */
  onSeeOnlyDeviationInOwnDepartment() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.SEE_ONLY_DEVIATION_IN_OWN_DEPT;
    this.isShowDeptDeviation = BaseServices.checkUserRights(
      userRightsId,
      currentUserRole
    );
    if (this.isShowDeptDeviation) {
      this.showLoader = false;
      this.subscriptions.push(
        this.deviationService
          .getAllDeviationByDepartment(BaseServices.UserId)
          .subscribe((deviations: any) => {
            if (deviations != null) {
              this.deviationList = deviations;
            }
            this.bindDropDowns(deviations);
          })
      );
    }
  }
  /**
   * Edit the page based upon administrate deviation rights
   */
  onAdministrateDeviation() {
    let currentUserRole1 = BaseServices.UserRole;
    let userRightsIdAdmin = Rights.ADMINISTRATE_DEVIATION;
    let userRightsView = Rights.VIEW_DEVIATION;
    let userRightsViewDepart = Rights.SEE_ONLY_DEVIATION_IN_OWN_DEPT;

    if (currentUserRole1 === 'User') {
      let CheckAdmin = BaseServices.checkUserRights(
        userRightsIdAdmin,
        currentUserRole1
      );
      let CheckView = BaseServices.checkUserRights(
        userRightsView,
        currentUserRole1
      );
      let CheckViewDepart = BaseServices.checkUserRights(
        userRightsViewDepart,
        currentUserRole1
      );

      if (!CheckAdmin && !CheckView && !CheckViewDepart) {
        this.bindDeviationByUser();
      }
    }

    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.hasDeviationDeleteAccess = false;
      this.isStatusDone = false;
      this.isShowSaveButton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.isShowSaveButton === false) {
        this.displayDeviation = true;
        if (currentUserRole === 'Guest') {
          this.onDeviationRights();
        } else {
          this.newButtonDisplay = false;
        }
        this.onViewDeviation();
        // The below comment for showing wrong deviations list from other business.
        //this.onSeeOnlyDeviationInOwnDepartment();
      } else if (this.isShowSaveButton) {
        this.hasDeviationDeleteAccess = true;
        this.bindDeviationList();
      }
    }
  }

  /**
   * Display the deviation list based upon displayown deviation rights
   */

  onDisplayOwnDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.DISPLAY_OWN_DEVIATION_ONLY;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.isDisplayOwnDeviation = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.isDisplayOwnDeviation === true) {
        this.bindDeviationByUser();
      } else {
        this.onSeeOnlyDeviationInOwnDepartment();
      }
    }
  }

  /**
   * update deviation based on viewdeviation rights
   */
  onViewDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.VIEW_DEVIATION;
    if (currentUserRole === 'User' || currentUserRole === 'Guest') {
      this.isShowSaveButton = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.isShowSaveButton) {
        this.showDeviationDisabled = false;
        this.bindDeviationList();
      }
    }
  }

  /**
   * add deviation based on the deviation rights
   */
  onDeviationRights() {
    let userRightsId = Rights.DEVIATION_RIGHTS;
    let role = BaseServices.UserRole;
    if (role === 'Guest') {
      this.newButtonDisplay = BaseServices.checkUserRights(userRightsId, role);
      if (this.newButtonDisplay === true) {
        this.isShowNewButton = true;
      } else {
        this.isShowNewButton = false;
      }
    }
  }

  onSearchChange(searchValue: string) {
    sessionStorage.setItem('search_FollowupDev_Desc', searchValue);
    if (searchValue && searchValue !== '') {
      this.deviationTable.filter(searchValue, 'Description', 'contains');
    } else {
      this.deviationTable.filter(null, 'Description', 'contains');
    }
  }

  onCreatedByChange(e: any, searchValue: any) {
    sessionStorage.setItem('search_FollowupDev_CreatedBy', e.value);
    if (e.value) {
      this.deviationTable.filter(searchValue.label, 'CreatorName', 'contains');
    } else {
      this.deviationTable.filter(null, 'CreatorName', 'contains');
    }
  }
  /**
   * Show loading bar when export the data.
   */
  loader(value: any) {
    this.Showloader = value;
  }

  private startChartCycling() {
    setInterval(() => {
      if (!this.isPaused) {
        this.currentIndex = (this.currentIndex + 1) % this.chartViews.length;
        this.chartView = this.chartViews[this.currentIndex];
      }
    }, 8000); // 8 seconds
  }

  togglePause() {
    this.isPaused = !this.isPaused;
  }

  // Method to change the chart view based on direction
  changeChart(direction: 'next' | 'previous') {
    this.isPaused = true; // Pause the cycling when manually changing the chart

    if (direction === 'next') {
      this.currentIndex = (this.currentIndex + 1) % this.chartViews.length;
    } else if (direction === 'previous') {
      this.currentIndex =
        (this.currentIndex - 1 + this.chartViews.length) %
        this.chartViews.length;
    }
    this.chartView = this.chartViews[this.currentIndex];
  }

  onChildButtonClicked() {
    this.isPaused = true; // Pause the cycling when a button in the child component is clicked
  }

  get chartView(): string {
    return this.chartViews[this.currentIndex];
  }

  set chartView(value: string) {
    this.currentIndex = this.chartViews.indexOf(value);
  }
}

//#endregion
