import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { Suggestion } from '../models/suggestion';
import { BaseServices } from './../../kuba.services';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SuggestionsServices {
    constructor(private http: HttpClient) {}

    // List all business
    list() {
        return this.http
            .get(
                environment.BASE_URL +
                    `/suggestionsbybusiness/${BaseServices.BusinessId}`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    // retrive a suggestion for editing and View
    getById(id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/suggestion/' + id,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    // create a new suggestion
    create(newSuggestion: Suggestion) {
        return this.http
            .post(
                environment.BASE_URL + '/suggestion/',
                newSuggestion,
                BaseServices.headerOption
            )
            .map((response) => {
                return response;
            });
    }

    // update the suggestion
    update(data: Suggestion) {
        return this.http
            .put(environment.BASE_URL + '/suggestion/', data, BaseServices.headerOption)
            .map((response) => {
                return response;
            });
    }

    deleteById(id: number, newSuggestion: Suggestion) {
        return this.http
            .delete(
                environment.BASE_URL + '/suggestion/' + id,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteSuggetionDocument(id:any) {
        return this.http
            .delete(
                environment.BASE_URL + '/suggestiondocument/' + id,
                BaseServices.headerOption
            )
            .map(result => result);
    }
}
