import { NotificationSubscription } from "../../../shared/employee-contact";

export class EL {
    Id: number;
    ContractName: string;
    ContractNumber: any;
    Status: string;
    BusinessPropertyId: number;
    ClientId: number;
    ContractImage: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: string;
    BusinessId: number;
    Comments: string;
    KundeELTemplateId: number;
    ElTemplateId: number;
    CopySJA: string;
    BusinessPropertyDto: BusinessProperty;
    KundeELUsers: KundeELUser[];
    KundeELClientUsers: KundeELClientUser[];
    KundeElDocument: KundeElDocumentDto[];
    ShowDeviation?: boolean;
    EmailSubscribers: NotificationSubscription[];
    SMSSubscribers:NotificationSubscription[];
    selectedUsers: KundeELUser[];
    kundeElImage: string;
}

export class KundeElDocumentDto {
    Id: number;
    KundeELId: number;
    Filename: string;
    OriginalFileName: string;
    MimeType: string;
    Size: string;
    Path: string;
    FileType: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}
export class KundeELClientUser {
    ELId: number;
    ClientId: number;
    ClientUserId: number;
    Name: string;
    Id: number;
}

export class BusinessProperty {
    Id: number;
    BusinessId: number;
    BusinessPropertyTypeId: number;
    Name: string;
    Number: string;
    FloorCount: number;
    ApartmentCount: number;
    Address: string;
    Status: string;
    City: string;
    Zip: string;
    Location: string;
    Latitude: string;
    Longitude: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: string;
}

export class ELUsers {
    ELId: number;
    UserId: number;
    Name: string;
}

export class KundeELUser {
    KundeELId: number;
    UserId: number;
    Name: string;    
    Id: number;
    FeatureKey: number;
    FeatureId: number;    
    IsEmail : boolean;
}


export class ElDetail {
    ContractNumber: string;
    ContractName: string;
    StatusText: string;
    ContractImage: string;
    Comments: string;
    ELClientUsers: ELClientUsersDetail[];
    constructor() {
        this.ContractNumber = '';
        this.ContractName = '';
        this.StatusText = '';
        this.ContractImage = '';
        this.Comments = '';
        this.ELClientUsers = [];
    }
}
export class ELClientUsersDetail {
    Name: string;
    constructor() {
        this.Name = '';
    }
}
