import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { BaseServices } from './../../kuba.services';
import {
    Department,
    VehicleBrand,
    VehicleList,
    VehicleTypeOfvehicle
} from './../models/vehicle';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VehicleServices {
    constructor(private http: HttpClient) {}

    getAllVehicles() {
        return this.http
            .get(
                environment.BASE_URL +
                    '/vehicle/vehicles/' +
                    BaseServices.BusinessId, BaseServices.headerOption
            )
            .map((result:any) => result);
    }
    getVehiclesById(id: number) {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehicle/' + id, BaseServices.headerOption)
            .map((result:any) => result);
    }

    createVehicle(vehicle: VehicleList) {
        return this.http
            .post(
                environment.BASE_URL + '/vehicle/vehicle',
                vehicle,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateVehicle(vehicle: VehicleList) {
        return this.http
            .put(
                environment.BASE_URL + '/vehicle/vehicle',
                vehicle,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteVehicle(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/vehicle/vehicle/' + id, BaseServices.headerOption)
            .map((response: any) => {
                return response;
            });
    }
    getAllDepartments() {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehicles', BaseServices.headerOption)
            .map(result => result);
    }
    getAllBrands() {
        return this.http
            .get(environment.BASE_URL + '/vehiclebrand/vehiclebrands', BaseServices.headerOption)
            .map((result: any) => result);
    }
    getBrandById(Id: number) {
        return this.http
            .get(environment.BASE_URL + '/vehiclebrand/vehiclebrands/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    createBrand(vehicle: VehicleBrand) {
        return this.http
            .post(
                environment.BASE_URL + '/vehiclebrand/vehiclebrand',
                vehicle,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateBrand(vehicle: VehicleBrand) {
        return this.http
            .put(
                environment.BASE_URL + '/vehiclebrand/vehiclebrand',
                vehicle,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteBrandById(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/vehiclebrand/vehiclebrand/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    getAllModels() {
        return this.http
            .get(environment.BASE_URL + '/vehiclemodel/vehiclemodels', BaseServices.headerOption)
            .map((result: any) => result);
    }

    getAllVehicleType() {
        return this.http
            .get(environment.BASE_URL + '/vehicletype/vehicletypes', BaseServices.headerOption)
            .map((result: any) => result);
    }
    getAllVehicleSizes() {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehiclesizes', BaseServices.headerOption)
            .map((result: any) => result);
    }
    getVehicleTypeById(Id: number) {
        return this.http
            .get(environment.BASE_URL + '/vehicletype/vehicletypes/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    createVehicleType(vehicle: VehicleTypeOfvehicle) {
        return this.http
            .post(
                environment.BASE_URL + '/vehicletype/vehicletype',
                vehicle,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateVehicleType(vehicle: VehicleTypeOfvehicle) {
        return this.http
            .put(
                environment.BASE_URL + '/vehicletype/vehicletype',
                vehicle,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteVehicleType(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/vehicletype/vehicletype/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    // update department in view
    updateDept(existingCategory: Department) {
        return this.http
            .put(
                environment.BASE_URL + '/department/' + existingCategory.Id,
                existingCategory, BaseServices.headerOption
            )
            .map(result => result);
    }
    // adding new department in view
    createDept(newCategory: Department) {
        return this.http
            .post(
                environment.BASE_URL + '/department/',
                JSON.stringify(newCategory),
                BaseServices.headerOption
            )
            .map(result => result);
    }
    deleteDepartment(id: number) {
        return this.http
            .delete(
                environment.BASE_URL + '/departmentdelete/' + id,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getVehicleFeatures(businessId: number) {
        let featureId: number;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        featureId = workingModule.id;
        return this.http
            .get(
                environment.BASE_URL + '/business/' + businessId,
                BaseServices.headerOption
            )
            .map((result:any) => {
                let feature = result.Features;
                let vehicleAdditionalFeature = _.find(JSON.parse(feature), {
                    id: featureId,
                    access: true
                });
                return vehicleAdditionalFeature;
            });
    }
    checkAutowizuser(data: any) {
        return this.http
            .post(
                environment.BASE_URL + '/vehicle/AuthenticateAutowizUser/',
                data, BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }
    getAutowizbyUserId(userId: string) {
        return this.http
            .get(
                environment.BASE_URL + '/vehicle/AutowizUserByUserId/' + userId, BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }
    getFleetControlUrl(userId: string) {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehicleFleetUrl/' + userId, BaseServices.headerOption)
            .map((response: any) => {
                return response;
            });
    }
    getAutowizVehicleList(userdata: any) {
        return this.http
            .post(environment.BASE_URL + '/vehicle/vehicleSyncupList', userdata, BaseServices.headerOption)
            .map((response: any) => {
                return response;
            });
    }
    checkExistingDepartment(departmentId: number) {
        return this.http
            .get(
                environment.BASE_URL + '/checkdepartment/' + departmentId,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    checkExistingVehicleType(vehicleTypeId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                    '/vehicletype/checkType/' +
                    vehicleTypeId,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    checkExistingBrand(brandId: number) {
        return this.http
            .get(
                environment.BASE_URL + '/vehiclebrand/checkbrand/' + brandId,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getUserVehicles(Id: number) {
        return this.http.get(environment.BASE_URL + '/vehicle/getuserVehicles/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }
}
