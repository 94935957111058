<div class="tab-content">
    <div class="page-title">
        <span translate>COURSE_DETAILS</span>
    </div>
    <div [formGroup]="courseDetailForm">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="CName">
                        <span translate>COURSE_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="BusinessCourseId" id="CName" class="ex-full-width"
                            [options]="coursenames" [style]="{'width':'100%'}"></p-dropdown>
                        <button class="btn btn-primary" type="button" (click)="showDialog()" pButton
                            icon="pi pi-question-circle"></button>
                    </div>
                    <control-messages [control]="courseDetailForm.controls['BusinessCourseId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Institute">
                        <span translate>INSTITUTE</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="Institute" id="Institute" />
                    </div>
                    <control-messages [control]="courseDetailForm.controls['Institute']"></control-messages>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-col-form-label" for="startDate">
                                    <span translate>START_DATE</span>
                                </label>
                                <span *ngIf="hasChildren">
                                    <i class="pi pi-info-circle" aria-hidden="true"
                                        tooltip="This is driven by sub-activities"></i>
                                </span>
                                <div>
                                    <my-date-picker [style]="{'width':'160px'}" name="startdate"
                                        options="startDateOptions" formControlName="StartDate"
                                        (dateChanged)="onStartDateChanged($any($event))" required selDate="selDate">
                                    </my-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="form-col-form-label" for="endDate">
                                    <span translate>END_DATE</span>
                                </label>
                                <div>
                                    <my-date-picker name="enddate" [style]="{'width':'160px'}"
                                        (dateChanged)="onEndDateChanged($any($event))" options]="endDateOptions"
                                        formControlName="EndDate"
                                        required></my-date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="Renewaldate">
                        <span translate>RENEWAL_DATE</span>
                    </label>
                    <div>
                        <my-date-picker name="enddate" [style]="{'width':'160px'}" options="renewalDateOptions"
                            formControlName="Renewaldate" >
                        </my-date-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="ResponsibleId">
                        <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                    </label>
                    <div>
                        <p-dropdown [filter]="true" formControlName="ResponsibleId" id="ResponsibleId"
                            [options]="rexcecution" [style]="{'width':'100%'}"></p-dropdown>
                    </div>
                    <control-messages [control]="courseDetailForm.controls['ResponsibleId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="DeadlineNotification">
                        <span translate>NOTIFICATION_BEFORE_DEADLINE_COURSE</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="DeadlineNotification" id="Deadline" />
                    </div>
                    <control-messages [control]="courseDetailForm.controls['DeadlineNotification']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Description">
                        <span translate>DESCRIPTION</span>
                    </label>
                    <div>
                        <textarea pInputTextarea formControlName="Description" id="Description"
                            [(ngModel)]="description"></textarea>
                    </div>
                    <control-messages [control]="courseDetailForm.controls['Description']"></control-messages>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="form-group">
                        <div>
                            <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                                (saveChanges)="saveUploaded($any($event))">
                            </ecpl-document-explorer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="action-btns-wrapper">
    <div class="row">
        <div class="col-sm-6">
            <a class="btn btn-outline-secondary" (click)="Clear()" role="button">
                <span translate>CLEAR</span>
            </a>
            <div>
                <button class="btn btn-primary" type="submit" (click)=saveChanges()
                    [disabled]="!courseDetailForm.valid">
                    <span translate>SAVE_CHANGES</span>
                </button>
            </div>
        </div>
        <div class="col-sm-6 text-right">
            <button class="btn btn-outline-primary" type="submit" (click)="printList()">
                <span class="icon ic-sm icon-print"></span>
                <span translate>PRINT_THIS_LIST</span>
            </button>
        </div>
    </div>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table #dt [value]="courseobject" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
    [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>{{'COURSE_NAME'|translate}}</th>
                <th>{{'INSTITUTE'|translate}}</th>
                <th>{{'START_DATE'|translate}}</th>
                <th>{{'END_DATE'|translate}}</th>
                <th>{{'RENEWAL_DATE'|translate}}</th>
                <th>{{'RESPONSIBLE_FOR_EXECUTION' | translate}}</th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-course>
            <tr>
                <td>
                    <span>{{course.Course}}</span>
                </td>
                <td>
                    <span>{{course.Institute}}</span>
                </td>
                <td>
                    <span>{{course.StartDate |date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span>{{course.EndDate |date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span>{{course.Renewaldate |date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span>{{course.ResponsiblepersonName}}</span>
                </td>
                <td>
                    <button type="button" class="btn btn-icon" title="{{ 'EDIT' | translate}}"
                        (click)="edit(course.Id)">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button type="button" class="btn btn-icon" title="{{ 'DELETE' | translate}}"
                        (click)="delete(course['Id'])">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>

        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
</div>
<p-dialog header="{{'Kuba Says:|translate'}}" [(visible)]="display" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <p translate>COURSE_INFO</p>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-primary" pButton icon="pi-check" (click)="display=false"
                label="Ok"></button>
        </div>
    </p-footer>
</p-dialog>

<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>