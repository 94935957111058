import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DeclarationOfConformity } from '../models/declaration-conformity';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeclarationConformityServices {
    item: Object;
    constructor(private http: HttpClient,
    public translate: TranslateService) {
    }

    /**
         * add declaration of conformity to list
         * @param data {any}
         */
    add(data): Promise<any> {
        let opt = BaseServices.headerOption
        let body = JSON.stringify(data);
        let lang = BaseServices.userCultureInfo(this.translate.currentLang);
        opt.headers.append('CultureInfo', lang);
        return this.http
            .post(
                environment.BASE_URL + '/project/projectdeclaration',
                body,
                opt
            )
            .toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    // edit declaration
    getDeclaration(id: number) {
        return this.http
            .get(environment.BASE_URL + '/project/projectdeclaration/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    private extractData(res) {
        let body = res; // return body || {};

        return <DeclarationOfConformity[]>res;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    reportGenerate(businessId: number, cultureInfo: string, ReportJson) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: ReportJson });
        let url = `${environment.BASE_URL}/export-DOC/${businessId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }
}
