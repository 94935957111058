<script type="text/javascript">
  var dp = new DayPilot.Scheduler("scheduler");

  // config ...

  dp.init();

  document.addEventListener("DOMContentLoaded", function () {
    alert("script calling");
    var exportButton = document.getElementById("exportbutton");
    var areaInput = document.getElementById("area");
    var exportContainer = document.getElementById("export");

    exportButton.addEventListener("click", function (ev) {
      ev.preventDefault();
      var area = areaInput.value;
      var element = dp.exportAs("svg", { area: area }).toElement();
      exportContainer.innerHTML = "";
      exportContainer.appendChild(element);
    });
  });
</script>
<div class="dropdown-content" id="menu">
  <div (click)="OpenRequest()"><span translate>OPEN</span></div>
  <div (click)="ApproveRequest()"><span translate>APPROVED</span></div>
  <div (click)="RejectRequest()"><span translate>REJECTED</span></div>
</div>
<div class="tab-content">
  <div class="page-title">
    <span translate>EMPLOYEE_LEAVE</span>
    <span class="page-actions pull-right">
      <a [routerLink]="['./../allocate']" class="btn btn-success">
        <span class="icon ic-sm icon-add"></span>
        <span translate>ALLOCATE_LEAVE</span>
      </a>
    </span>
  </div>
  <form [formGroup]="DayPilotForm">
    <div class="row">
      <div class="col-sm-3">
        <div class="form-group">
          <label for="department">
            <span translate>FROM_DATE</span>
          </label>
          <div>
            <my-date-picker
              name="startDate"
              [options]="startDateOptions"
              (dateChanged)="
                onDayPilotStartDateOnChanged($event); onStartDateChanged($event)
              "
              placeholder="{{ 'SELECT_START_DATE' | translate }}"
              formControlName="DayPilotStartDate"
            >
            </my-date-picker>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label for="department">
            <span translate>END_DATE</span>
          </label>
          <div>
            <my-date-picker
              name="endDate"
              [options]="startDateOptions"
              (dateChanged)="
                onDayPilotEndDateOnChanged($event); onEndDateChanged($event)
              "
              placeholder="{{ 'SELECT_END_DATE' | translate }}"
              formControlName="DayPilotEndDate"
            >
            </my-date-picker>
          </div>
        </div>
      </div>
      <div class="col-sm-1">
        <div class="form-group" style="margin-top: 22px">
          <div>
            <button class="btn btn-primary" type="submit" (click)="Search()">
              <span translate>SEARCH</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="mtop-20 mbtm-20">
    <div class="ui-helper-clearfix">
      <div class="kubaCalendar"></div>
    </div>
  </div>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #dt
      [value]="leaves"
      #leavesTable
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="Employee Leave Details"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <form [formGroup]="leaveListForm">
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department">
                      <span translate>FROM_DATE</span>
                    </label>
                    <div>
                      <my-date-picker
                        name="startDate"
                        [options]="startDateOptions"
                        formControlName="startDate"
                        (dateChanged)="onStartDateChanged($event)"
                        [(ngModel)]="startDateFilter"
                        placeholder="{{ 'SELECT_START_DATE' | translate }}"
                      ></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="department">
                      <span translate>END_DATE</span>
                    </label>
                    <div>
                      <my-date-picker
                        name="endDate"
                        [options]="startDateOptions"
                        formControlName="endDate"
                        (dateChanged)="onEndDateChanged($event)"
                        placeholder="{{ 'SELECT_END_DATE' | translate }}"
                      ></my-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <button
                    class="btn btn-outline-primary"
                    type="submit"
                    (click)="refreshTable()"
                  >
                    <span class="icon ic-sm icon-reset"></span>
                    <span translate>REFRESH</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="table-options">
            <div class="pull-left"></div>
            <div class="pull-right">
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="leavesTable.filteredValue || leavesTable.value"
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="EmployeeName">
            {{ "EMPLOYEE_NAME" | translate }}
            <p-sortIcon field="EmployeeName"></p-sortIcon>
          </th>
          <th pSortableColumn="StartDate">
            {{ "START_DATE" | translate }}
            <p-sortIcon field="StartDate"></p-sortIcon>
          </th>
          <th pSortableColumn="EndDate">
            {{ "END_DATE" | translate }}
            <p-sortIcon field="EndDate"></p-sortIcon>
          </th>
          <th pSortableColumn="NoOfDays">
            {{ "NO_OF_DAYS" | translate }}
            <p-sortIcon field="NoOfDays"></p-sortIcon>
          </th>
          <th>{{ "STATUS" | translate }}</th>
          <th>{{ "APPROVAL_PERSON" | translate }}</th>
          <th pSortableColumn="AprDate">
            {{ "DATE_APPROVAL" | translate }}
            <p-sortIcon field="AprDate"></p-sortIcon>
          </th>
          <th width="95px"><span translate>DESCRIPTION</span></th>
          <th width="84px">{{ "OPTIONS" | translate }}</th>
        </tr>
        <tr>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter(
                  $any($event.target)?.value,
                  'EmployeeName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <p-dropdown
              [options]="statusList"
              (onChange)="onStatusChanged($event, ed)"
              #ed
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              width="118px"
              [options]="approvalPersonList"
              (onChange)="
                dt.filter($event.value, 'ApprovalPersonNameList', 'equals')
              "
              styleClass="p-column-filter"
              placeholder=""
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-employee pTemplate="item">
                {{ employee.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-employee>
        <tr>
          <td>
            <span class="text-overflow">{{ employee.EmployeeName }}</span>
          </td>
          <td>
            <span>{{ employee.StartDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ employee.EndDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ employee.NoOfDays }}</span>
          </td>
          <td>
            <span translate>{{ employee.StatusText }}</span>
          </td>
          <td>
            <span class="text-overflow">{{
              employee.ApprovalPersonNameList
            }}</span>
          </td>
          <td>
            <span>{{ employee.ApprovedDate | date : "dd/MM/yyyy" }}</span>
          </td>
          <td class="col-button">
            <span
              *ngIf="employee.Description != ''"
              pTooltip="{{ employee.Description }}"
              tooltipPosition="top"
              class="btn btn-icon"
            >
              <i class="pi pi-comment" aria-hidden="true"></i>
            </span>
          </td>
          <td class="col-button">
            <button
              type="button"
              [routerLink]="['./../editLeave', employee.Id]"
              class="btn btn-icon"
              title="{{ 'EDIT' | translate }}"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="9">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>

<div id="dpFullScreenModal" class="leave-modal" style="display: none">
  <div class="overlay-content">
    <div class="page-title">
      <h4>
        <span translate>EMPLOYEE_LEAVE</span>
      </h4>
      <button (click)="hideModal()" class="btn btn-icon close">
        <span class="icon ic-md icon-multiply"></span>
      </button>
    </div>
  </div>
  <div class="tab-content">
    <form [formGroup]="DayPilotForm">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="department">
              <span translate>FROM_DATE</span>
            </label>
            <div>
              <my-date-picker
                name="startDate"
                [(ngModel)]="startDateFs"
                [options]="startDateOptions"
                (dateChanged)="
                  onDayPilotStartDateOnChanged($event);
                  onStartDateChanged($event)
                "
                placeholder="{{ 'SELECT_START_DATE' | translate }}"
                formControlName="DayPilotStartDate"
              >
              </my-date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="department">
              <span translate>END_DATE</span>
            </label>
            <div>
              <my-date-picker
                name="endDate"
                [(ngModel)]="endDateFs"
                [options]="startDateOptions"
                (dateChanged)="
                  onDayPilotEndDateOnChanged($event); onEndDateChanged($event)
                "
                placeholder="{{ 'SELECT_END_DATE' | translate }}"
                formControlName="DayPilotEndDate"
              >
              </my-date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-1">
          <div class="form-group" style="margin-top: 22px">
            <div>
              <button
                class="btn btn-primary"
                type="submit"
                (click)="filterDaypilotEmpListFs()"
              >
                <span translate>SEARCH</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="mtop-20 mbtm-20">
      <div class="kubaCalendar"></div>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<div
  class="panel-loader"
  *ngIf="loading && fullscreen"
  style="
    z-index: 999999;
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0px;
  "
>
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
<div
  class="panel-loader"
  *ngIf="loading && !fullscreen"
  style="z-index: 999999"
>
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
