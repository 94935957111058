<div class="main-content">

    <div class="main-heading">
        <span>
            <a [routerLink]="['../../../archive/list']" *ngIf="featureKey==null" class="btn btn-icon">
                <span class="icon ic-xs icon-back"></span>
            </a>
            <a *ngIf="featureKey!=null" (click)="goBackPreviousPage();" class="btn btn-icon">
                <span class="icon ic-xs icon-back"></span>
            </a>
            <span translate>COMPLETE_MANUAL_CONTROL</span><span> - {{zoneTitle}}</span>
            <span *ngIf="FoodSafetyManualCompleteChecklist"> {{FoodSafetyManualCompleteChecklist.Title}}</span>
            <ng-template *ngIf="FoodSafetyManualCompleteChecklist">
                <span *ngIf="featureKey!=null">
                    Manual Control
                </span>
                <span *ngIf="featureKey==null">
                    All Zone checklist
                </span>
            </ng-template>
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <table class="table border-0">
                <thead>
                    <tr>
                        <th><span translate>NAME_OF_THE_OBJECT</span></th>
                        <th><span translate>TYPE_OF_OBJECT</span></th>
                        <th><span translate>MIN_TEMP</span></th>
                        <th><span translate>MAX_TEMP</span></th>
                        <th style="width:80px;"><span translate>FOOD_SAFETY_TEMPRATURE</span></th>
                        <th style="width:120px"><span translate>FOOD_SAFETY_DEVIATION</span></th>
                        <th style="width:200px;"><span translate>FOOD_SAFETY_COMMENTS</span></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-List let-headPoint="index" [ngForOf]="FoodSafetyManualCompleteChecklistData">
                        <tr>
                            <td>
                                <strong> {{List.ObjectName}} </strong>
                            </td>
                            <td>
                                <strong>{{List.TypeOfObject}}</strong>
                            </td>
                            <td>
                                <strong>{{List.MinTemp}}</strong>
                            </td>
                            <td>
                                <strong>{{List.MaxTemp}}</strong>
                            </td>
                            <td>
                                <p-spinner size="30" [(ngModel)]="List.Temperature" placeholder="-80°C to 300°C"
                                  [step]="0.1" [min]="-80" [max]="300"></p-spinner>
                            </td>
                            <td>
                                <ng-template ngFor let-status [ngForOf]="List.Status">
                                    <div class="radio">
                                        <label>
                                            <input id="statusOptions" required name="options[{{headPoint}}]"
                                              type="radio" [value]="status.Value"
                                              (change)="setStatus(status, List.Status)"
                                              [checked]="status.Value">{{status.Type | translate}}
                                        </label>
                                    </div>
                                </ng-template>
                            </td>
                            <td>
                                <textarea [(ngModel)]="List.Comments" id="statusComments"
                                  class="form-control"></textarea>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div class="action-btns-wrapper">
                <button [routerLink]="['../../../archive/list']" *ngIf="featureKey==null && featureId == 20"
                  class="btn btn-outline-secondary">
                    <span class="icon ic-xs icon-back"></span>
                    <span>Back To Archive</span>
                </button>
                <button (click)="goBackPreviousPage();" *ngIf="featureKey==null && featureId != 20"
                  class="btn btn-outline-secondary">
                    <span class="icon ic-xs icon-back"></span>
                    <span translate>BACK_CONTROL</span>
                </button>
                <button *ngIf="featureKey!=null" (click)="goBackPreviousPage();" class="btn btn-outline-secondary">
                    <span class="icon ic-xs icon-back"></span>
                    <span *ngIf="featureId != 20" translate>BACK_CONTROL</span>
                    <span *ngIf="featureId == 20" translate>BACK_FOODSAFETY</span>
                </button>
                <button [disabled]="checkValid()" (click)="Saveforlater();" class="btn btn-primary">
                    <span translate>SAVE_FOR_LATER</span>
                </button>
                <button [disabled]="checkValid()" class="btn btn-primary" (click)="SaveCompleteCheckList()"><span
                      translate>SAVE_AND_COMPLETE</span></button>
            </div>
            <!--Panel Loader-->
            <div *ngIf="showLoader" class="panel-loader">
                <span>
                    <span class="loader-icon"></span>
                    <span translate>PROCESSING</span>
                </span>
            </div>
            <!--Panel Loader-->
        </div>
    </div>
</div>
<p-confirmDialog width="425" acceptLabel="{{'SWITCH_YES'|translate}}"
  rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>