import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApplicationService {
    constructor(private http: HttpClient) {}

    getById(id: number) {
        return this.http
            .get(`${environment.BASE_URL}/application/${id}`)
            .map(result => result);
    }
    
    delete(id) {
        return this.http
            .delete(`${environment.BASE_URL}/application/${id}/features`)
            .map(result => result);
    }
}
