import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { AppSharedModule } from './../shared/appShared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeatureDocumentComponent } from './feature-document.component';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { CopyManualFilesModule } from 'src/app/kuba/copy-manual-files/copyManualFiles.module'
import { FeatureManualService } from './feature-document.services';
import { DocumentListResolver } from 'src/app/kuba/feature-documents/feature-document.service.resolver';
import { EcplLarViewerModule } from './../../shared/ecpl-lar-viewer/ecpl-lar-viewer.module';

@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        EcplDocumentTreeModule,
        EcplDocumentExplorerModule,
        CopyManualFilesModule,
        EcplLarViewerModule,
        ModalModule.forRoot()
    ],
    exports: [
        FeatureDocumentComponent
    ],
    declarations:
    [
        FeatureDocumentComponent,
    ],
    providers: [
        FeatureManualService,
        DocumentListResolver
    ],

    schemas: [],
})
export class FeatureDocumentModule { }
