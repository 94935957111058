import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EcplTabNavigationComponent } from './ecpl-tab-navigation.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EcplTabNavigationComponent],
  imports: [BrowserModule, CommonModule, RouterModule, TranslateModule],
  exports: [EcplTabNavigationComponent, CommonModule]
})
export class EcplTabNavigationModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplTabNavigationModule
    };
  }
}
