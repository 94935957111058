import { Component, OnInit } from '@angular/core';

@Component({
  selector:'contact-new',
  templateUrl: 'contactNew.component.html'
})

export class ContactNewComponent implements OnInit {
 
  constructor() { }

  

  ngOnInit() {
    document.querySelector("body").classList.remove("opened");
  }
} 