
import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'meeting-calendar',
    template: 'meetingCalendar.component.html'
})

export class MeetingCalendarComponent implements OnInit {
    constructor() { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
     }
}