<div class="main-content">
    <div class="main-heading">
        <span translate>ADD_ZONES</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="foodSafetyForm" class="form">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="ZoneName" class="col-form-label">
                                <span translate>ZONE_NAME </span>
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <p-dropdown [options]="Zone" formControlName="ZoneName" [style]="{'width':'100%'}"
                                  type="text">
                                </p-dropdown>
                                <button class="btn btn-outline-primary" type="button" (click)="addZone = !addZone">
                                    <i class="pi pi-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                            <control-messages [control]="foodSafetyForm.controls['ZoneName']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label for="Description" class="col-form-label">
                                <span translate>DESCRIPTION </span>
                            </label>
                            <div>
                                <textarea pInputTextarea formControlName="Description"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="ChooseProject" translate>COPY_EXISTING_ZONE</label>
                            <div>
                                <p-dropdown #chooseZone [options]="fsZoneNames" [filter]="true"
                                  [disabled]="copyDropDown" formControlName="ChooseZone"
                                  (onChange)="CopyFiles($event)"></p-dropdown>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="showCopyFiles">
                            <p-checkbox *ngIf="!filesTemplateId" label="{{'COPY_FILES'|translate}}"
                              formControlName="CopyFiles" binary="true"></p-checkbox>
                            <p-checkbox *ngIf="filesTemplateId" [disabled]="true" pTooltip="{{filesTemplateName}}"
                              tooltipPosition="top" label="{{'COPY_FILES'|translate}}" formControlName="CopyFiles"
                              binary="true"></p-checkbox>
                            <p-checkbox *ngIf="!hazardTemplateId" label="{{'COPY_HAZARD'|translate}}"
                              formControlName="CopyHazard" binary="true"></p-checkbox>
                            <p-checkbox *ngIf="hazardTemplateId" [disabled]="true" pTooltip="{{hazardTemplateName}}"
                              tooltipPosition="top" label="{{'COPY_HAZARD'|translate}}" formControlName="CopyHazard"
                              binary="true"></p-checkbox>
                            <p-checkbox *ngIf="!sJATemplateId" label="{{'COPY_SJA'|translate}}"
                              formControlName="CopySJA" binary="true"></p-checkbox>
                            <p-checkbox *ngIf="sJATemplateId" [disabled]="true" pTooltip="{{sJATemplateName}}"
                              tooltipPosition="top" label="{{'COPY_SJA'|translate}}" formControlName="CopySJA"
                              binary="true">
                            </p-checkbox>
                        </div>
                        <div class="form-group">
                            <p-checkbox label="{{'DO_NOT_DISPLAY_DEVIATION'|translate}}"
                              formControlName="IsShowDeviationChecked" binary="true"></p-checkbox>
                        </div>
                        <div class="action-btns-wrapper">
                            <a (click)="gotoBack()" class="btn btn-outline-secondary">
                                <span class="icon ic-xs icon-back"></span>
                                <span translate>BACK_LIST</span>
                            </a>
                            <button class="btn btn-primary" type="submit" (click)="saveFoodSafety()"
                              [disabled]="!foodSafetyForm.valid">
                                <span translate>SAVE</span>
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="saveAndCreateNew()"
                              [disabled]="!foodSafetyForm.valid">
                                <span translate>SAVE_CREATE_NEW</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="sub-panel-title">
                                <span translate>ASSIGN_ACCESS</span>
                            </div>
                            <!-- Table starts -->
                            <p-table #dt [value]="foodUsers" dataKey="Id" scrollable="true" scrollHeight="200px">
                                <!-- Header -->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th width="40px">
                                            <p-checkbox [ngModel]="checkedAll" (ngModelChange)="checkAll($event)"
                                              [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                                        </th>
                                        <th>{{'NAME' | translate}}</th>
                                    </tr>
                                </ng-template>
                                <!-- Body Content -->
                                <ng-template pTemplate="body" let-fs>
                                    <tr>
                                        <td width="40px">
                                            <p-checkbox [(ngModel)]="fs.Id === userId || fs.status"
                                              [disabled]="fs.Id === userId" (ngModelChange)="checked(fs)"
                                              [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                                        </td>
                                        <td>
                                            <span>{{fs.Name}}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!-- Empty message -->
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                        <td colspan="2">
                                            {{'NO_RECORDS_FOUND'|translate}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <!-- Table Ends-->
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>EMAIL_NEWDEVIATION_LABEL</span>
                            </label>
                            <div *ngIf="bindSelectedUsers">
                                <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [options]="userSubscribers"
                                  formControlName="selectedEmailSubscriber" [style]="{'width':'100%'}">
                                </p-multiSelect>
                            </div>
                            <div *ngIf="bindAllUsers">
                                <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [dataKey]="'Id'"
                                  [options]="selectedSubscribers" formControlName="selectAllEmailSubscriber"
                                  [style]="{'width':'100%'}">
                                </p-multiSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<p-dialog header="{{'ADD_ZONES'|translate}}" [(visible)]="addZone" (onHide)="onZonePopupHide()" [modal]="true"
[style]="{width: '400px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="zonePopup">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>ZONE_NAME</span>
            </label>
            <div>
                <input type="text" formControlName="Name" class="label-input form-control" required>
            </div>
            <label for="title " color class="col-form-label " *ngIf="isZoneNameAllocated" [ngStyle]="{ 'color': 'red'} ">
                <span translate>CANNOT_DELETE</span>
            </label>
            <label for="title " color class="col-form-label " *ngIf="isZoneNameExists" [ngStyle]="{ 'color': 'red'} ">
                <span translate>ZONE_EXIST</span>
            </label>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="listZone = !listZone" (click)="viewZone()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="zonePopup.invalid" (click)="saveZone()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="listZone">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #et [value]="zoneList" [rows]="5" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'ZONE_NAME'|translate}}</th>
                            <th width="100px">{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="et.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-zone>
                        <tr>
                            <td>
                                <span pTooltip="{{zone.Name}}" tooltipPosition="top">{{zone.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                  (click)="editZone(zone)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                  (click)="deleteZone(zone['Id'])">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="{{'ADD_DEPARTMENT'|translate}}" (onHide)="onDepartmentPopupHide()" [(visible)]="addDepartment"
  [modal]="true" [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="departmentPopup">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>NAME</span>
            </label>
            <div>
                <input type="text" formControlName="Name" class="label-input form-control" required>
            </div>
        </div>
        <label for="title " color class="col-form-label " *ngIf="isDepartmentNameAllocated"
          [ngStyle]="{ 'color': 'red'} ">
            <span translate>CANNOT_DELETE</span>
        </label>
        <label for="title " color class="col-form-label " *ngIf="isDepartmentNameExists" [ngStyle]="{ 'color': 'red'} ">
            <span translate>DEPARTMENT_EXIST</span>
        </label>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="listDepartment = !listDepartment"
              (click)="viewDepartment()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="departmentPopup.invalid"
              (click)="saveDepartment()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="listDepartment">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #ct [value]="departmentList" [rows]="5" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'DEPARTMENT_NAME'|translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="ct.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-department>
                        <tr>
                            <td>
                                <span>{{department.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                  (click)="editDepartment(department)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                  (click)="deleteDepartment(department['Id'])">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>