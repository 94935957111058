<div class="card-body">
    <div class="page-title">
        <span translate>FIRE_ALARM_SYSTEM</span>
    </div>
    <div class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="6"><span translate>FIRE_ALARM_SYSTEM</span></th>
                </tr>
                <tr>
                    <th colspan="6"><span translate>TYPE_OF_SYSTEM</span></th>
                </tr>
            </thead>
            <tbody *ngIf="!editMode">
                <tr>
                    <td style="width: 7%;"><span translate>TYPE</span></td>
                    <td style="width: 13%;"><span>{{firealarmSystem.Type}}</span></td>
                    <td style="width: 7%;"><span translate>INSTALLED_YEAR</span></td>
                    <td style="width: 13%;"><span>{{firealarmSystem.InstalledYear}}</span></td>
                </tr>
                <tr>
                    <td style="width: 7%;"><span translate>SUPPLIER</span></td>
                    <td style="width: 13%;"><span>{{firealarmSystem.Supplier}}</span></td>
                    <td style="width: 7%;"><span translate>LOOP</span></td>
                    <td style="width: 13%;"><span>{{firealarmSystem.LoopAddress}}</span></td>
                </tr>
            </tbody>
            <tbody *ngIf="editMode">
                <tr>
                    <td style="width:70px"><span translate>TYPE</span></td>
                    <td style="width:130px">
                        <input type="text" class="form-control" [(ngModel)]="firealarmSystem.Type" />
                    </td>
                    <td style="width:70px"><span translate>INSTALLED_YEAR</span></td>
                    <td style="width:130px">
                        <input type="text" class="form-control" [(ngModel)]="firealarmSystem.InstalledYear" />
                    </td>
                </tr>
                <tr>
                    <td style="width:70px"><span translate>SUPPLIER</span></td>
                    <td style="width:130px">
                        <input type="text" class="form-control" [(ngModel)]="firealarmSystem.Supplier" />
                    </td>
                    <td style="width:70px"><span translate>LOOP</span></td>
                    <td style="width:130px">
                        <input type="text" class="form-control" [(ngModel)]="firealarmSystem.LoopAddress" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!editMode" class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width: 50%;"><span translate>COMPONENTS</span></th>
                    <th style="width: 50%"><span translate>COMMENTS</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of systemContent">
                    <td><span>{{item.components}}</span></td>
                    <td><span>{{item.comments}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="editMode" class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th></th>
                    <th><span translate>COMPONENTS</span></th>
                    <th><span translate>COMMENTS</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of systemContent">
                    <td class="col-button" style="width:8%">
                        <button type="button" class="btn btn-icon" (click)="addItem(item)" title="Add Row">
                            <span class="icon ic-sm icon-add"></span>
                        </button>
                        <button *ngIf="systemContent.length > 1" type="button" class="btn btn-icon" (click)="deleteItem(item)" title="Delete Row">
                            <span class="icon ic-sm icon-trash"></span>
                        </button>
                    </td>
                    <td style="width:46%;padding: 0px;">
                        <input style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text" class="form-control" [(ngModel)]="item.components" />
                    </td>
                    <td style="width:46%;padding: 0px;">
                        <input style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text" class="form-control" [(ngModel)]="item.comments" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th><span translate>FIRE_ALARM_SYSTEM_CONTROLS</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="editMode">
                    <td><input type="text" class="form-control full-max-width" [(ngModel)]="firealarmSystem.Controls" /></td>
                </tr>
                <tr *ngIf="!editMode">
                    <td><span>{{firealarmSystem.Controls}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th><span translate>FIRE_ALARM_SYSTEM_CONNECTION</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="editMode">
                    <td><input type="text" class="form-control full-max-width" [(ngModel)]="firealarmSystem.Connection" /></td>
                </tr>
                <tr *ngIf="!editMode">
                    <td><span>{{firealarmSystem.Connection}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="editMode" class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="6"><span translate>SYSTEM_COMPONENTS</span></th>
                </tr>
                <tr>
                    <th></th>
                    <th style="text-align:center"><span translate>AMOUNT</span></th>
                    <th style="text-align:center"><span translate>TYPE</span></th>
                    <th style="text-align:center"><span translate>DESCRIPTION</span></th>
                    <th style="text-align:center"><span translate>LOCATION</span></th>
                    <th style="text-align:center"><span translate>LABELLING</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of otherEquipment">
                    <td class="col-button" style="width:8%">
                        <button type="button" class="btn btn-icon" (click)="addEquipment(item)" title="Add Row">
                            <span class="icon ic-sm icon-add"></span>
                        </button>
                        <button *ngIf="otherEquipment.length > 1" type="button" class="btn btn-icon" [disabled]="disableItemDelete(item)" (click)="deleteEquipment(item)" title="Delete Row">
                            <span *ngIf="disableItemDelete(item)" pTooltip="{{'DELETE_EQUIPMENT_NOT_POSSIBLE_DEVIATION' | translate}}" class="icon ic-sm icon-trash"></span>
                            <span *ngIf="!disableItemDelete(item)" class="icon ic-sm icon-trash"></span>
                        </button>
                    </td>
                    <td style="width:9%">
                        <input type="text" class="form-control" [(ngModel)]="item.amount" />
                    </td>
                    <td style="width:9%">
                        <input type="text" class="form-control" [(ngModel)]="item.type" />
                    </td>
                    <td style="width:12%">
                        <input type="text" class="form-control" [(ngModel)]="item.description" />
                    </td>
                    <td style="width:10%">
                        <input type="text" class="form-control" [(ngModel)]="item.location" />
                    </td>
                    <td style="width:10%">
                        <input type="text" class="form-control" [(ngModel)]="item.merking" /><!-- labelling in English -->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!editMode" class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="5"><span translate>OTHER_EQUIPMENT</span></th>
                </tr>
                <tr>
                    <th style="text-align:center" colspan="1"><span translate>AMOUNT</span></th>
                    <th style="text-align:center" colspan="1"><span translate>TYPE</span></th>
                    <th style="text-align:center" colspan="1"><span translate>DESCRIPTION</span></th>
                    <th style="text-align:center" colspan="1"><span translate>LOCATION</span></th>
                    <th style="text-align:center" colspan="1"><span translate>LABELLING</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of otherEquipment">
                    <td><span>{{item.amount}}</span></td>
                    <td><span>{{item.type}}</span></td>
                    <td><span>{{item.description}}</span></td>
                    <td><span>{{item.location}}</span></td>
                    <td><span>{{item.merking}}</span></td><!-- labelling in English -->
                </tr>
            </tbody>
        </table>
    </div>
</div>
<toaster-component></toaster-component>