import { ContactView } from '../../contact/models/contactView';
import { ContactServices } from '../../contact/services/contact.service';
import { ContactEdit } from '../../contact/models/contactEdit';
import { Component, OnInit, ViewChild, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService
 } from 'primeng/api';
import { AppSharedModule } from './../../shared/appShared.module';
import { BaseServices } from '../../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
let jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
    selector: 'kuba-norge-as',
    templateUrl: 'kuba-norge-as.component.html'
})
export class KubaNorgeAsComponent implements OnInit {
    @ViewChild('contactsTable',{static: false}) contactsTable: Table;
    pdf: any;
    cTypeOfConatct: any;
    typeOfContacts: SelectItem[];
    contacts: any;
    contactsMedium: any;
    contactView: ContactView[];
    function: SelectItem[];
    additionalData: any;
    selectedContacts: any;
    private subscriptions: Subscription[] = [];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public contactServices: ContactServices,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.additionalData = {
            fileName: 'Kuba-Norge',
            header: 'Kuba-Norge',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Telephone', dataKey: 'Telephone' },
                { title: 'RoleName', dataKey: 'RoleName' },
                { title: 'ContactFunctionalAreaId', dataKey: 'ContactFunctionalAreaId' },
                { title: 'IsEmail', dataKey: 'IsEmail' }
            ]
        };

    }

    ngOnInit(): void {
        let list = this.route.snapshot.data['list'];
        if (list) {
            this.contactView = list;
        }
        this.subscriptions.push(this.translate.stream("TYPE_OF_CONTACT_LIST").subscribe(val=>{
            this.typeOfContacts = [];
            this.typeOfContacts.push({ label: val.ALL, value: null });
            this.typeOfContacts.push({ label: val.PERSON, value: 1 });
            this.typeOfContacts.push({ label: val.COMPANY, value: 2 });
        }));

        this.function = [];
        this.function.push({ label: 'Select', value: null });
        this.cTypeOfConatct = this.route.snapshot.data['function'];
        if (this.cTypeOfConatct) {
            this.cTypeOfConatct.forEach((element:any) => {
                this.function.push({ label: element.Name, value: element.Id });
            });
        }
    }

    getContact() {
        this.subscriptions.push(this.contactServices.getAll().subscribe(result => {
            this.contactView = result;
        }));
    }

    confirm(value:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.contactServices.deleteContact(value).then(result => {
                    this.getContact();
                });
            }
        });
    }

    deleteContacts(selectedContacts:any) {
        let contactIds:any = ([] = []);
        selectedContacts.forEach((element: any) => {
            contactIds.push({ Id: element.Id });
        });
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.contactServices.deleteAll(contactIds).subscribe(result => {
                    this.subscriptions.push(this.contactServices
                        .getAll()
                        .subscribe(res => (this.contactView = res)));
                }));
            }
        });
    }

    exportPdf() {
        let doc = new jsPDF('p', 'pt');
        let columns = [
            { title: 'Name', dataKey: 'Name' },
            { title: 'Cellular Phone', dataKey: 'Telephone' },
            { title: 'Role', dataKey: 'RoleName' }
        ];
        let data = this.contactView;
        doc.autoTable(columns, data);
        doc.save('Contact List.pdf');
    }
    onTypeOfContactChanged(e:any, typeOfContactsDropdown:any) {
        this.contactsTable.filter(
            typeOfContactsDropdown.selectedOption.value,
            'ContactTypeId',
            'equals'
        );
        if (e.value == null) {
            this.contactsTable.reset();
        }
    }

    onfunctionChanged(e:any, functionDropdown:any) {
        this.contactsTable.filter(
            functionDropdown.selectedOption.value,
            'ContactFunctionalAreaId',
            'equals'
        );
        if (e.value == null) {
            this.contactsTable.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
