import { Component } from '@angular/core';

@Component({
    template: `<router-outlet></router-outlet>`
})
export class FrameworkAgreementComponent {
    constructor() {
    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");        
    }
}
