import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class NotesService {
    constructor(private http: HttpClient) {}

    getAll() {
        return this.http
            .get(environment.BASE_URL + '/notes', BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }

    getById(businessId: number) {
        return this.http
            .get<any>(
                environment.BASE_URL + '/notebybusiness/' + businessId,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getNoteByUser(userId: number) {
        return this.http
            .get<any>(
                environment.BASE_URL + '/notes/' + userId,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    add(data) {
        return this.http
            .post(
                environment.BASE_URL + '/note/',
                data,
                BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    update(data) {
        return this.http
            .put(
                environment.BASE_URL + '/notesupdate/',
                data,
                BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
}
