import { ActivatedRoute, Params } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  moduleId: module.id,
  selector: 'project-edit',
  templateUrl: 'project-edit.component.html',
})
export class ProjectEditComponent implements OnInit {
  projectName: any;
  /**
   * constructor
   * @param activatedRoute {{ActivatedRoute}}
   * @param location {{Location}}
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
  }
  /**
   * go to list
   */
  gotoBack() {
    this.location.back();
  }
}
