import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { SelectItem, ConfirmationService } from 'primeng/api';

import { ConsumptionType, Consumption, UnitOfConsumption } from './../models/consumptions';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { ConsumptionsService } from './../services/consumptions.services';
import { IMyDpOptions } from 'mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'new-consumption',
    templateUrl: 'consumption-new.component.html'
})

export class NewConsumptionComponent implements OnInit {
    // pre defined property
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    consumptiontypedata: any;
    consumptionList: any;
    editUnits = [];
    editConsumptions = [];
    previousNumber: number;
    todaysNumber: number;
    addUnit: boolean;
    consumptionId: number;
    consumptionTypeId: number;
    consumptionUnitId: number;
    isConsumptionTypeExsist = false;
    isConsumptionUnitExsist = false;
    consumptionForm: FormGroup;
    ConsumptionTypeForm: FormGroup;
    ConsumptionUnitForm: FormGroup;
    consumptions: any;
    units: SelectItem[];
    consumptionData = new Consumption();
    consumptionType = new ConsumptionType();
    selectedUnit = new UnitOfConsumption();
    selectedConsumption = new UnitOfConsumption();
    consumptionTypes: SelectItem[];
    private subscriptions: Subscription[] = [];

    viewUnit = false;
    addType = false;
    viewType = false;
    /**
        * Date picker configuration option
        */
    public defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        disableSince: this.getCurrentDate()
    };
    /**
     * constructor
     * @param route {{ActivatedRoute}}
     * @param formBuilder {{FormBuilder}}
     * @param consumptionsService {{ConsumptionsService}}
     */
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private consumptionsService: ConsumptionsService,
        protected location: Location,
        private confirmationService: ConfirmationService,
        private translate: TranslateService) {
        this.consumptionList = this.route.snapshot.data['list'];
    }
    /**
     * editing consumption,binding dropdowns
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.ConsumptionTypeForm = this.formBuilder.group({
            Name: ['', Validators.required]
        })
        this.ConsumptionUnitForm = this.formBuilder.group(
            {
                Name: ['', Validators.required]
            })
            this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            let cid = params['cid'];
            this.consumptionId = +cid;
        }));

        this.consumptionForm = this.formBuilder.group({
            ConsumptionTypes: ['', Validators.required],
            date: ['', Validators.required],
            PreviousNumber: [''],
            TodaysNumber: ['', Validators.required],
            EstimatedNumber: [''],
            ConsumptionUnits: [''],
            Price: ['', Validators.required],
            Comments: ['']

        });
        if (this.consumptionId > 0) {
            let result = this.route.snapshot.data['detail'];
            if (result) {
                this.consumptionData = result;
                this.consumptions = {
                    ConsumptionTypes: result.ConsumptionTypeId,
                    date: result.Date ? HelperService.formatInputDate(result.Date) : null,
                    PreviousNumber: result.PreviousNumber,
                    TodaysNumber: result.TodaysNumber,
                    EstimatedNumber: result.EstimatedNumber,
                    ConsumptionUnits: result.ConsumptionUnitId,
                    Price: result.Price,
                    Comments: result.Comments
                };
                this.consumptionForm.setValue(this.consumptions, { onlySelf: true });
            }

        } else {
            this.getLatestPreviousNumber();
        }
        this.units = [];
        this.editUnits = [];
        let unitsOfConsumption = this.route.snapshot.data['unitOfConsumption'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.units = [];
            this.units.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (unitsOfConsumption) {
            unitsOfConsumption.forEach((element:any) => {
                this.units.push({ label: element.Name, value: element.Id });
                this.editUnits.push({ Name: element.Name, Id: element.Id });
            });
        }
        this.consumptionTypes = [];
        this.editConsumptions = [];
        let consumptionTypes = this.route.snapshot.data['consumptionType'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.consumptionTypes = [];
            this.consumptionTypes.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (consumptionTypes) {
            consumptionTypes.forEach((element:any) => {
                this.consumptionTypes.push({ label: element.Name, value: element.Id });
                this.editConsumptions.push({ Name: element.Name, Id: element.Id });
            });
        }

        this.bindUnitOfconsumptionDropDown();
    }

    /**
       * get current date
       */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() + 1
        };
    }
    /**
     * Save consumption
     * @param consumptions {{any}}
     */
    onSubmitTemplateBased() {
        this.consumptionData.Date = this.consumptionForm.value.date ?
            HelperService.formatDateFilter(this.consumptionForm.value.date.formatted) : null!
        this.consumptionData.PreviousNumber = this.consumptionForm.value.PreviousNumber;
        this.consumptionData.TodaysNumber = this.consumptionForm.value.TodaysNumber;
        this.consumptionData.EstimatedNumber = this.consumptionForm.value.EstimatedNumber;
        if (this.consumptionForm.value.ConsumptionTypes) {
            this.consumptionData.ConsumptionTypeId = this.consumptionForm.value.ConsumptionTypes;
        }
        if (this.consumptionForm.value.ConsumptionUnits) {
            this.consumptionData.ConsumptionUnitId = this.consumptionForm.value.ConsumptionUnits;
        }
        this.consumptionData.Comments = this.consumptionForm.value.Comments;
        this.consumptionData.Price = this.consumptionForm.value.Price;
        if (this.consumptionId > 0) {
            this.consumptionData.Id = this.consumptionId;
            this.subscriptions.push(this.consumptionsService.update(this.consumptionId, this.consumptionData).subscribe((isConsumptionUpdate) => {
                if (isConsumptionUpdate) {
                    this.toasterComponent.callToast();
                    this.gotoList();
                }
            }));
        } else {
            this.consumptionData.Id = 0;
            this.consumptionData.Status = '1';
            this.consumptionData.CreatedOn = new Date();
            this.consumptionData.BusinessId = BaseServices.BusinessId;
            this.subscriptions.push(this.consumptionsService.add(this.consumptionData).subscribe((isConsumptionAdded) => {
                if (isConsumptionAdded) {
                    this.toasterComponent.callToast();
                    this.gotoList();
                }
            }));
        }

    }

    /**
     * bind consumption type dropdown
     */
    bindConsumptionTypeDropdown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.units = [];
            this.units.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.consumptionsService.getAllConsumptionsType().subscribe((result: any) => {
            if (result) {
                this.consumptionTypes = [];
                result.forEach((element:any) => {
                    this.consumptionTypes.push({ label: element.Name, value: element.Id });
                });
                this.editConsumptions = result;
            }
        }));
    }
    /**
     * bind consumtion type dropdown
     */
    bindUnitOfconsumptionDropDown() {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.units = [];
            this.units.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.consumptionsService.getConsumptionsUnitByBusinessId(BaseServices.BusinessId).subscribe(result => {
            if (result) {
                this.units = [];
                result.forEach((element:any) => {
                    this.units.push({ label: element.Name, value: element.Id });
                });
                this.editUnits = result;
            }
        }));

    }
    /**
    * edit Unit of consumption
    * @param consumptionUnit {{any}}
    */
    editUnit(consumptionUnit: any) {
        this.ConsumptionUnitForm = this.formBuilder.group({
            Name: consumptionUnit.Name
        })
        this.consumptionUnitId = consumptionUnit.Id;
    }
    /**
     * save Unit of consumption
     */
    saveUnit() {
        let consumptionUnit = new UnitOfConsumption();
        consumptionUnit.Id = (this.consumptionUnitId) ? this.consumptionUnitId : 0;
        consumptionUnit.Name = this.ConsumptionUnitForm.value.Name;
        consumptionUnit.BusinessId = BaseServices.BusinessId;
        consumptionUnit.Status = '1';
        if (this.consumptionUnitId > 0) {
            this.subscriptions.push(this.consumptionsService.updateConsumptionsUnit(consumptionUnit.Id, consumptionUnit).subscribe(result => {
                if (result) {
                    this.addUnit = false;
                    this.bindUnitOfconsumptionDropDown();
                    this.toasterComponent.callToast();
                    this.consumptionForm
                        .get('ConsumptionUnits')!
                        .patchValue(result.Id);
                    this.consumptionUnitId = 0;
                    this.clearConsumptionUnit();
                } else {
                    this.isConsumptionUnitExsist = true;
                }
            }));
        } else {
            this.subscriptions.push(this.consumptionsService.addConsumptionsUnit(consumptionUnit)
                .subscribe(result => {
                    if (result) {
                        this.addUnit = false;
                        this.bindUnitOfconsumptionDropDown();
                        this.toasterComponent.callToast();
                        this.isConsumptionUnitExsist = false;
                        this.consumptionForm
                            .get('ConsumptionUnits')!
                            .patchValue(result.Id);
                        this.clearConsumptionUnit();
                    } else {
                        this.isConsumptionUnitExsist = true;
                    }
                }));
        }
        this.viewUnit = false;
    }
    /**
   * delete Unit of consumption based on Id
   * @param consumptionType {{any}}
   */
    deleteUnit(consumptionType:any) {
        let data = this.consumptionList ? this.consumptionList.filter((x:any) => x.ConsumptionUnitId === consumptionType.Id) : []
        if (data.length > 0) {
            alert('You cannot delete this Consumption unit as it is being used');
        } else {
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.consumptionsService.deleteConsumptionsUnit(consumptionType.Id).subscribe(result => {
                        if (result) {
                            this.addUnit = false;
                            this.bindUnitOfconsumptionDropDown();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
                }
            });
        }
    }
    /**
   * edit consumption type
   * @param consumptionType {{any}}
   */
    editType(consumptionType: any) {
        this.ConsumptionTypeForm = this.formBuilder.group({
            Name: consumptionType.Name
        });
        this.consumptionTypeId = consumptionType.Id;
    }
    /**
     * save consumption Type
     */
    saveType() {
        let consumptionType = new UnitOfConsumption();
        consumptionType.Id = this.consumptionTypeId ? this.consumptionTypeId : 0;
        consumptionType.Name = this.ConsumptionTypeForm.value.Name;
        consumptionType.BusinessId = BaseServices.BusinessId;
        consumptionType.Status = '1';
        if (this.consumptionTypeId > 0) {
            this.subscriptions.push(this.consumptionsService.updateConsumptionsType(consumptionType.Id, consumptionType).subscribe(result => {
                if (result) {
                    this.addType = false;
                    this.bindConsumptionTypeDropdown();
                    this.toasterComponent.callToast();
                    this.consumptionForm
                        .get('ConsumptionTypes')!
                        .patchValue(result.Id);
                    this.consumptionTypeId = 0;
                    this.clearConsumptionType();
                } else {
                    this.isConsumptionTypeExsist = true;
                }
            }));
        } else {
            this.subscriptions.push(this.consumptionsService.addConsumptionsType(consumptionType)
                .subscribe(result => {
                    if (result) {
                        this.addType = false;
                        this.bindConsumptionTypeDropdown();
                        this.toasterComponent.callToast();
                        this.isConsumptionTypeExsist = false;
                        this.consumptionForm
                            .get('ConsumptionTypes')!
                            .patchValue(result.Id);
                        this.clearConsumptionType();
                    } else {
                        this.isConsumptionTypeExsist = true;
                    }
                }));
        }
        this.viewType = false;
    }
    /**
   * delete consumption type
   * @param consumptionType
   */
    deleteType(consumptionType:any) {
        let deleteTypeId = consumptionType.Id;
        let data = this.consumptionList ? this.consumptionList.filter((x:any) => x.ConsumptionTypeId === consumptionType.Id) : []
        if (data.length > 0) {
            alert('You cannot delete this Consumption type as it is being used');
        } else {
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.consumptionsService.deleteConsumptionsType(deleteTypeId).subscribe(result => {
                        if (result) {
                            this.addType = false;
                            this.bindConsumptionTypeDropdown();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
                }
            });
        }


    }
    gotoList() {
        this.location.back();
    }
    onNumber(e: any, limitNumber: any) {
        HelperService.numberFieldValidation(e, limitNumber);
    }
    clearConsumptionType() {
        this.ConsumptionTypeForm = this.formBuilder.group({
            Name: ['']
        })
    }
    clearConsumptionUnit() {
        this.ConsumptionUnitForm = this.formBuilder.group({
            Name: ['']
        })
    }
    onEstimatedNumberCalculation() {
        let estimatedNumber = (this.todaysNumber ? this.todaysNumber : 0) - (this.previousNumber ? this.previousNumber : 0);
        this.consumptionForm
            .get('EstimatedNumber')!
            .patchValue(estimatedNumber);
    }
    onPreviousNumberChanged(e: any) {
        this.previousNumber = e.value;
        this.onEstimatedNumberCalculation();
    }
    onTodaysNumberChanged(e: any) {
        this.todaysNumber = e.value;
        this.onEstimatedNumberCalculation();
    }
    getLatestPreviousNumber() {
        this.subscriptions.push(this.consumptionsService.getLatestPreviousNo().subscribe((res: any) => {
            this.consumptionForm
                .get('PreviousNumber')!
                .patchValue(res.PreviousNumber);
            this.consumptionForm
                .get('ConsumptionTypes')!
                .patchValue(res.ConsumptionTypeId);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
