<div class="tab-content">
  <form [formGroup]="commonDetailsForm">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            <span translate>TYPE_OF_EMPLOYEE</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              formControlName="TypeOfEmployeeId"
              id="Type"
              [options]="types"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addType = !addType"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['TypeOfEmployeeId']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="commonDetailsForm.controls['Number']">
          <label>
            <span translate>EMPLOYEE_NUMBER</span>
          </label>
          <div>
            <input
              type="number"
              pInputText
              formControlName="Number"
              (keypress)="onMobileNumberChange($event, 20)"
              id="Number"
              class="ex-full-width"
              maxlength="20"
            />
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['Number']"
          ></control-messages>
        </div>
        <div class="form-group" *ngIf="commonDetailsForm.controls['Name']">
          <label>
            <span translate>NAME</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Name"
              class="ex-full-width"
              maxlength="20"
            />
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['Name']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label>
            <span translate>DATE_BIRTH</span>
          </label>
          <div>
            <my-date-picker
              options="startDateOptions"
              formControlName="Dob"
              required
            ></my-date-picker>
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['Dob']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label>
            <span translate>POSITION</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              formControlName="EmployeePositionId"
              id="Position"
              [options]="positions"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addPosition = !addPosition"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['EmployeePositionId']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label>
            <span translate="">PERCENTAGE</span>
          </label>
          <div>
            <p-dropdown
              formControlName="Percentage"
              id="Percentage"
              [options]="percentage"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['Percentage']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label>
            <span translate>DEPARTMENT</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown
              [filter]="true"
              formControlName="BusinessDepartmentId"
              #departmentDropdown
              (onChange)="
                onDepartmentDropDownChanged($event, departmentDropdown)
              "
              id="Department"
              [options]="departments"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addDept = !addDept"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['BusinessDepartmentId']"
          ></control-messages>
        </div>
        <div class="form-group" [hidden]="IsHidden">
          <label>
            <span translate>Sub Department</span>
          </label>
          <div>
            <p-dropdown
              [filter]="true"
              [options]="subDepartments"
              formControlName="SubDepartmentId"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="addSubDept = !addSubDept"
            >
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <p-checkbox
              label="Department Leader"
              [binary]="false"
              [disabled]="hideSaveUserButton"
              formControlName="DepartmentLead"
            ></p-checkbox>
          </div>
        </div>
        <div class="form-group" [hidden]="isUser">
          <label>
            <span translate>FUNCTION</span>
          </label>
          <div>
            <p-dropdown
              class="ex-full-width"
              formControlName="ContactFunctionalAreaId"
              [options]="funtions"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group">
          <label>
            <span translate>ADDRESS</span>
          </label>
          <div>
            <textarea
              id="Address"
              class="ex-full-width"
              name="Address"
              pInputTextarea
              formControlName="Address"
              [autoResize]="true"
            ></textarea>
          </div>
        </div>
        <div class="form-group-25_75">
          <div class="form-group">
            <label>
              <span translate>ZIP_CODE</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="ZipOrCity"
                id="Zip"
                (keypress)="onMobileNumberChange($event, 20)"
                class="ex-full-width"
              />
            </div>
          </div>
          <div class="form-group">
            <label>
              <span translate>LOCATION</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Location"
                id="Location"
                class="ex-full-width"
              />
            </div>
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['ZipOrCity']"
          ></control-messages>
          <control-messages
            [control]="commonDetailsForm.controls['Location']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="Mobile">
            <span translate>MOBILE</span>
          </label>
          <div [hidden]="IsHidding" class="phone-field">
            <p-dropdown
              [options]="CountryCode"
              formControlName="CountryCode"
              [filter]="true"
              [style]="{ width: '110px' }"
            >
            </p-dropdown>
            <input type="number" pInputText formControlName="Mobile" />
          </div>
          <div [hidden]="IsHide" class="phone-field">
            <input type="number" pInputText formControlName="OtherCode" />
            <input type="number" pInputText formControlName="OtherMobile" />
          </div>
          <div [hidden]="IsHidding">
            <a
              routerLinkActive="active"
              class="btn btn-link"
              href="javascript:void(0);"
              (click)="changeCountryCode()"
              >OtherCodes</a
            >
          </div>
          <div [hidden]="IsHide">
            <a
              routerLinkActive="active"
              class="btn btn-link"
              href="javascript:void(0);"
              (click)="changeToCountryCodeDropDown()"
              >Pre defined Code</a
            >
          </div>
        </div>
        <div class="form-group-50_50">
          <div class="form-group">
            <label>
              <span translate>TELEPHONE</span>
            </label>
            <div>
              <input
                type="number"
                pInputText
                formControlName="Telephone"
                id="Telephone"
                (keypress)="onMobileNumberChange($event, 20)"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="commonDetailsForm.controls['Telephone']"
            ></control-messages>
          </div>
        </div>
        <div class="form-group">
          <label>
            <span translate>EMAIL</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              pInputText
              formControlName="Email"
              class="ex-full-width"
              maxlength="100"
            />
          </div>
          <control-messages
            [control]="commonDetailsForm.controls['Email']"
          ></control-messages>
        </div>
        <div class="form-group-50_50">
          <div class="form-group">
            <label class="form-col-form-label" for="startDate">
              <span translate>START_DATE</span>
            </label>
            <span *ngIf="hasChildren">
              <i
                class="pi pi-info-circle"
                aria-hidden="true"
                tooltip="This is driven by sub-activities"
              ></i>
            </span>
            <div>
              <my-date-picker
                [style]="{ width: '160px' }"
                [disabled]="hideSaveUserButton"
                name="startdate"
                options="startDateOptions"
                formControlName="StartDate"
                (dateChanged)="onStartDateChanged($any($event))"
                required
              ></my-date-picker>
            </div>
          </div>
          <div class="form-group">
            <label class="form-col-form-label" for="endDate">
              <span translate>END_DATE</span>
            </label>
            <div>
              <my-date-picker
                name="enddate"
                [disabled]="hideSaveUserButton"
                [style]="{ width: '160px' }"
                (dateChanged)="onEndDateChanged($any($event))"
                formControlName="EndDate"
                options="endDateOptions"
                required
              >
              </my-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group checkbox">
          <div [hidden]="isUser">
            <p-checkbox
              label="{{ 'Receive email for new deviations' | translate }}"
              formControlName="IsEmailNewDeviation"
              [binary]="false"
            ></p-checkbox>
          </div>
          <div [hidden]="isUser">
            <p-checkbox
              label="{{ 'SHOW_DASHBOARD ' | translate }}"
              formControlName="ShowOnDashBoard"
              [binary]="false"
            ></p-checkbox>
          </div>
          <div [hidden]="isUser">
            <p-checkbox
              label="{{ 'NOTIFICATION_SMS' | translate }}"
              formControlName="NotificationBySms"
              [binary]="false"
            ></p-checkbox>
          </div>
          <div [hidden]="isUser">
            <p-checkbox
              label="{{ 'NOTIFICATION_EMAIL' | translate }}"
              formControlName="NotificationByEmail"
              [binary]="false"
            ></p-checkbox>
          </div>
          <div [hidden]="isUser">
            <p-checkbox
              label="{{ 'SHOW_GUEST' | translate }}"
              formControlName="IsShowToGuest"
              [binary]="true"
            >
            </p-checkbox>
          </div>
          <div>
            <p-checkbox
              label="{{ 'SUGGESTION_NOTIFICATION' | translate }}"
              formControlName="NotifySuggestion"
              [binary]="false"
            ></p-checkbox>
          </div>
          <div [hidden]="isUser">
            <p-checkbox
              label="{{ 'MAIN_CONTACT' | translate }}"
              formControlName="MainContact"
              [binary]="false"
            >
            </p-checkbox>
          </div>
          <div>
            <p-checkbox
              label="{{ 'APPROVAL_NOTICE' | translate }}"
              formControlName="IsApprovalNotice"
              [disabled]="hideSaveUserButton"
              [binary]="true"
              (onChange)="onApprovalNoticeChanged($event)"
            >
            </p-checkbox>
            <div>
              <label>
                <span translate>READ_APPLICATION</span>
                {{ approvalTime }}</label
              >
            </div>
          </div>
        </div>
        <div class="sub-panel-title">
          <span translate>INFO_EMPLOYEE</span>
        </div>
        <div class="upload-file-container">
          <label>
            <span translate>EMPLOYEE_IMAGE</span>
          </label>
          <div>
            <!-- TODO: Employee profile image -->
          </div>
          <div class="form-group">
            <div class="upload-actions">
              <label class="btn btn-outline-secondary upload-button">
                <input
                  type="file"
                  ngFileSelect
                  (uploadOutput)="onUploadOutput($event)"
                  [uploadInput]="uploadInput"
                />
                Browse
              </label>
              <button
                type="button"
                class="btn btn-outline-primary start-upload-btn"
                (click)="startUpload()"
                [disabled]="!isFileChoosed"
              >
                Start Upload
              </button>
            </div>
            <div class="upload-items">
              <div class="upload-item" *ngFor="let f of files; let i = index">
                <div class="filename">
                  <span
                    >{{ f.name }} ({{ f.id }}) -
                    {{ f.progress?.data?.percentage }}%</span
                  >
                  <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
                  <span>ETA {{ f.progress?.data?.etaHuman }}</span>
                </div>
                <div class="progress">
                  <span
                    class="bar"
                    [style.width]="f?.progress?.data?.percentage + '%'"
                  ></span>
                </div>
              </div>
            </div>
            <div *ngIf="employeeImage != null">
              <img
                src="{{ employeeImage }}"
                alt=""
                width="100px"
                height="100px"
              />
            </div>
            <div class="tab-content">
              <ecpl-document-explorer
                [uploaderConfig]="upConfig"
                (saveChanges)="saveUploaded($event)"
                [uploadedFiles]="uploadedFiles"
              >
              </ecpl-document-explorer>
            </div>
          </div>
        </div>
        <div class="sub-panel-title">
          <span translate>NOTES</span>
        </div>
        <div class="form-group">
          <div>
            <textarea
              id="Notes"
              class="ex-full-width"
              pInputTextarea
              formControlName="Note"
              [autoResize]="true"
            ></textarea>
          </div>
        </div>
        <button
          class="btn btn-outline-primary"
          type="submit"
          (click)="addSignature()"
        >
          <span translate>SIGNATURE</span>
        </button>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <div class="row">
        <div class="col-sm-6">
          <a
            [routerLink]="['../../employee/list']"
            class="btn btn-outline-secondary"
          >
            <span class="icon ic-sm icon-back"></span>
            <span translate>BACK_LIST</span>
          </a>
          <button
            class="btn btn-primary"
            type="submit"
            (click)="saveChanges()"
            *ngIf="showMyPageSaveButton"
          >
            <span translate>SAVE</span>
          </button>
          <button
            class="btn btn-primary"
            (click)="saveAndNewUser()"
            [disabled]="hideSaveUserButton"
          >
            <span translate>SAVE_NEW_USER</span>
          </button>
        </div>
        <div class="col-sm-6 text-right">
          <button class="btn btn-outline-primary" (click)="exportPdf()">
            <span class="icon ic-sm icon-print"></span>
            <span translate>PRINT_PAGE</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<p-dialog
  header="{{ 'Add/Edit Employee Type' | translate }}"
  [(visible)]="addType"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeEmployeeTypeDialogHide()"
>
  <form [formGroup]="EmployeeTypeForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>TYPE</span>
      </label>
      <div>
        <input
          type="text"
          #title
          class="label-input form-control"
          formControlName="employeeType"
        />
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewType = !viewType"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!EmployeeTypeForm.valid"
        (click)="saveType()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #et
          [value]="editTypes"
          #employeeTypeTable
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    et.filter($any($event.target)?.value, 'Type', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryType>
            <tr>
              <td>
                <span>{{ categoryType.Type }}</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editType(categoryType)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteType(categoryType)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'Add/Edit Position' | translate }}"
  [(visible)]="addPosition"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforePositionDialogHide()"
>
  <form [formGroup]="PositionForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          #title
          class="label-input form-control"
          formControlName="position"
        />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
        }}</span>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewPosition = !viewPosition"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!PositionForm.valid"
        (click)="savePosition()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewPosition">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #ct
          [value]="editPositions"
          #positionTable
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "Position Name" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    ct.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryPosition>
            <tr>
              <td>
                <span>{{ categoryPosition.Name }}</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editPosition(categoryPosition)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deletePosition(categoryPosition)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  #departmenttable
  [(visible)]="addDept"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeDepartmentDialogHide()"
>
  <form [formGroup]="DepartmentForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          #title
          class="label-input form-control"
          formControlName="department"
        />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
        }}</span>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="viewDept = !viewDept"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!DepartmentForm.valid"
        (click)="saveDept()"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewDept">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #ft
          [value]="editDepartments"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "TYPE_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    ft.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryDept>
            <tr>
              <td>
                <span>{{ categoryDept.Name }}</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDept(categoryDept)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'Delete' | translate }}"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_SUBDEPARTMENT' | translate }}"
  #subDeptTable
  [(visible)]="addSubDept"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeSubDepartmentDialogHide()"
>
  <form [formGroup]="SubDeptForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          #title
          class="label-input form-control"
          formControlName="SubDepartment"
        />
        <span style="color: red" *ngIf="isNameExists">{{
          existsAlerttext
        }}</span>
      </div>
    </div>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="viewSubDept = !viewSubDept"
        >
          <span translate>VIEW</span>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!SubDeptForm.valid"
          (click)="saveSubDept()"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="viewSubDept">
        <div class="table-view">
          <!-- Table starts -->
          <p-table
            #gt
            [value]="editSubDepartments"
            [rows]="10"
            [paginator]="true"
            [pageLinks]="3"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "SUB_DEPARTMENT_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      gt.filter($any($event.target)?.value, 'Name', 'contains')
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-categorySubDept>
              <tr>
                <td>
                  <span>{{ categorySubDept.Name }}</span>
                </td>
                <td>
                  <button
                    type="button"
                    title="{{ 'EDIT' | translate }}"
                    class="btn btn-icon"
                    (click)="editSubDept(categorySubDept)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteSubDept(categorySubDept)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'ADD_EDIT_USER' | translate }}"
  [(visible)]="addCategory"
  [style]="{ width: '900px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="userForm">
    <div class="row">
      <div class="col-sm-5">
        <div class="form">
          <div class="form-group" *ngIf="userForm.controls['Name']">
            <label for="Name">
              <span translate>NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input type="text" pInputText formControlName="Name" required />
            </div>
            <control-messages
              [control]="userForm.controls['Name']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="userForm.controls['Username']">
            <label for="Username">
              <span translate>USERNAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Username"
                id="Username"
                required
              />
            </div>
            <control-messages
              [control]="userForm.controls['Username']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="RoleId">
              <span translate>ADDITIONAL_ROLE</span>
            </label>
            <div>
              <p-dropdown
                formControlName="RoleId"
                [options]="role"
                [style]="{ width: '100%' }"
                (onChange)="onRoleDropDownChanged($event)"
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group">
            <label for="Name">
              <span translate>OWNER_ACCOUNT</span>
            </label>
            <div>
              <input type="Name" pInputText formControlName="Name" readonly />
            </div>
          </div>
          <div class="form-group">
            <label for="Mobile">
              <span translate>MOBILE</span>
            </label>
            <div class="phone-field">
              <p-dropdown
                [options]="CountryCode"
                formControlName="CountryCode"
                [filter]="true"
                [style]="{ width: '110px' }"
              >
              </p-dropdown>
              <input
                type="number"
                pInputText
                formControlName="Mobile"
                id="Mobile"
              />
            </div>
          </div>
          <div class="form-group" *ngIf="userForm.controls['Email']">
            <label for="Email">
              <span translate>EMAIL</span>
              <span class="required">*</span>
            </label>
            <div>
              <input type="text" pInputText formControlName="Email" required />
            </div>
            <control-messages
              [control]="userForm.controls['Email']"
            ></control-messages>
          </div>

          <div class="form-group" *ngIf="userForm.controls['Password']">
            <label for="Password">
              <span translate>PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="Password"
                id="Password"
                required
              />
            </div>
            <control-messages
              [control]="userForm.controls['Password']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="RepeatPassword">
              <span translate>REPEAT_PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                id="RepeatPassword"
                formControlName="Password"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-7">
        <div
          formArrayName="userCategories"
          class="form-group"
          *ngIf="editedUser"
        >
          <div
            class="checkbox"
            *ngFor="let def of userFormGroups.controls; let i = index"
          >
            <div class="col-sm-6">
              <label>
                <span formGroupName="{{ i }}">
                  <input
                    type="checkbox"
                    formControlName="{{ userRightsData[i].id }}"
                    (change)="
                      onRightsCheckboxChange($event, userRightsData[i].id)
                    "
                  />
                  <span translate>
                    {{ userRightsData[i].name | translate }}</span
                  >
                </span>
              </label>
            </div>
          </div>
        </div>
        <div
          formArrayName="guestCategories"
          class="form-group"
          *ngIf="editedGuest"
        >
          <div
            class="checkbox"
            *ngFor="let def of guestFormGroups.controls; let j = index"
          >
            <div class="col-sm-6">
              <label>
                <span formGroupName="{{ j }}">
                  <input
                    type="checkbox"
                    formControlName="{{ guestRightsData[j].id }}"
                    (change)="
                      onRightsCheckboxChange($event, guestRightsData[j].id)
                    "
                  />
                  <span translate>
                    {{ guestRightsData[j].name | translate }}</span
                  >
                </span>
              </label>
            </div>
          </div>
        </div>
        <div
          formArrayName="editorCategories"
          class="form-group"
          *ngIf="editedEditor"
        >
          <div
            class="checkbox"
            *ngFor="let def of editCategoriesFormGroups.controls; let k = index"
          >
            <div class="col-sm-6">
              <label>
                <span formGroupName="{{ k }}">
                  <input
                    type="checkbox"
                    formControlName="{{ editorRightsData[k].id }}"
                    (change)="
                      onRightsCheckboxChange($event, editorRightsData[k].id)
                    "
                  />
                  <span translate>{{
                    editorRightsData[k].name | translate
                  }}</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="generateRandomPassword()"
      >
        <span translate>RANDOM_PASSWORD</span>
      </button>
      <button
        class="btn btn-primary"
        type="button"
        (click)="saveUser()"
        [disabled]="!userForm.valid"
      >
        <span translate>SAVE</span>
      </button>
      <button
        class="btn btn-primary"
        type="button"
        (click)="saveAndClose()"
        [disabled]="!userForm.valid"
      >
        <span translate>SAVE_CLOSE</span>
      </button>
    </div>
  </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
