import { ActivatedRoute, Params } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: 'taskmanagernorecords.component.html'
})

export class TaskManagerNoRecordsComponent implements OnInit {

    //#region variable

    message: string;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param route {route}
     */
    constructor(private route: ActivatedRoute) { }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit(): void {
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            let text = params['message'];
            if (text === '') {
                this.message = 'Task deleted';
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
