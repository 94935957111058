import { Component, OnInit } from '@angular/core';

@Component({
  template: '<router-outlet></router-outlet>'
})
export class QualitySystemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}