import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Auditlogdata } from '../../follow-ups/models/activities';
import { ActivityList, FollowUpPerson, Tasklist } from '../models';
import { TaskService } from '../services/task.services';
import { BaseServices } from './../../kuba.services';

@Injectable()
export class TaskManagerListResolver implements Resolve<Tasklist[]> {
    constructor(private backend: TaskService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<Tasklist[]> {
        return this.backend.getAllByEach(
            +route.params['Id'],
            route.parent.parent.data['parent']
        );
    }
}

@Injectable()
export class TaskManagerEditResolver implements Resolve<ActivityList> {
    constructor(private backend: TaskService) {}
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getById(+route.params['tid']);
    }
}

@Injectable()
export class FollowUpPersonList implements Resolve<FollowUpPerson[]> {
    constructor(private backend: TaskService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<FollowUpPerson[]> {
        return this.backend.getAllFollow(BaseServices.BusinessId);
    }
}

@Injectable()
export class RepeatitionList implements Resolve<FollowUpPerson[]> {
    constructor(private backend: TaskService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<FollowUpPerson[]> {
        return this.backend.getAllRepeat();
    }
}

@Injectable()
export class ExecutionPersonList implements Resolve<FollowUpPerson[]> {
    constructor(private backend: TaskService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<FollowUpPerson[]> {
        return this.backend.getAllExce(BaseServices.BusinessId);
    }
}

@Injectable()
export class TaskManagerAuditLog implements Resolve<Auditlogdata[]> {
    constructor(private backend: TaskService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Auditlogdata[]> {
        return this.backend.getTaskManagerAuditLog(
            10,
            +route.params['tid'],
            route.parent.parent.data['parent']
        );
    }
}
