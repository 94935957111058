
export function getBaseLocation() {

    let domains = ['ik', 'ba', 'din-hms', 'ahlsell', 'ur', 'medi3','unipro'];
    let paths: string[] = location.pathname.split('/').splice(1, 1);
    let basePath: string = (paths && paths[0]);


    if (!location.hostname.match(/[^.\s]+\.kuba\.dev/m) != null) {
        if (domains.indexOf(basePath) > -1) {
            return '/' + basePath;
        }else {
            return '/';
        }
    }else {
        return '/' ;
    }

}
