export class EmployeeLeave {
  Id: number;
  StartDate: string;
  EndDate: string;
  StartDateForFilter: string;
  EndDateForFilter: string;
  NoOfDays: number;
  ApprovalPerson: string;
  ApprovalPersonList: string[]=[];
  DateOfApproval: Date;
  Status: string;
  Description: string;
  IsShowWeekend: string;
  EmployeeId: number;
  BusinessId: number;
  BusinessIdRef: number;
  CreatedBy: string;
  CreatedOn: number;
  ModifiedOn: number;
  ModifiedBy: string;
  LeaveTaken: number;
  LeaveAvailable: number;
  EmployeeName: string;
  Year: number;
  LeaveAvailableId: number;
  LanguageId: number;
  UserId: number;
}

export interface UserRights {
  id: string;
  name: string;
  isChecked: boolean;
  rightsType: number;
}
