import { Route } from '@angular/router';
import { AuthGuard, AdminPortalTabGuard } from '../../_guards/index';
import { ManualComponent } from './manual.component';
import { AssignManualComponent } from './components/assignmanual.component'
import {
    ManualViewComponent,
} from './components/index';
import {
    ManualListResolver,
    BusinessManualListResolver,
    PortalManualListResolver,
    ManualTreeDataResolver,
    ChoosePortalResolver,
    ChooseBusinessResolver,
    AllManualListResolver,
    BusinessModeReslover,
    AdminUserResolver
} from './services/manual.resolver.service';


export const manualsRoutes: Route[] = [{
    path: 'manual',
    canActivate: [AuthGuard],
    children: [{
        path: 'list',
        component: ManualComponent,
        canActivate: [AuthGuard],
        resolve: {
            manualList: ManualListResolver,
            portalManualList: PortalManualListResolver,
            businessManualList: BusinessManualListResolver,
            allManualList: AllManualListResolver,
            businessMode: BusinessModeReslover,
            adminUsers: AdminUserResolver
        }
    },
    {
        path: 'view/:mid/:manualname/:mPortalId/:mBusinessId',
        component: ManualViewComponent,
        canActivate: [AuthGuard],
        resolve: {
            treeData: ManualTreeDataResolver,
            businessMode: BusinessModeReslover,
            adminUsers: AdminUserResolver
        }
    }, {
        path: 'assign',
        component: AssignManualComponent,
        canActivate: [AdminPortalTabGuard],
        resolve: {
            manualList: ManualListResolver,
            adminUsers: AdminUserResolver
        }

    }
    ]
}
];
