import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { FollowUpMessageService } from './../services/message.services';

@Component({
    selector: 'message-view',
    templateUrl: 'message-view.component.html'
})
export class MessageViewComponent implements OnInit {
    messageViewForm: FormGroup;
    fromUserName: string;
    subject: string;
    message: string;
    attachmentUrl: string;
    FileName: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private followUpMessageService: FollowUpMessageService,
        private _location: Location
    ) { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let message = this.route.snapshot.data['message'];
        if (message) {
            this.fromUserName = message.FromuserName;
            this.subject = message.Subject;
            this.message = message.Message;
            this.attachmentUrl = message.AttachmentUrl;
            this.FileName = message.FileName;
        }
    }

    /**
     * get back location
     */
    gotoBack() {
        this._location.back();
    }
}
