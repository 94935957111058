import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ActivityLog } from './../models/activitylog';
import { ActivityLogServices } from './activitylog.services';

@Injectable()
export class ActivityLogListResolver implements Resolve<ActivityLog[]> {

    constructor(private backend: ActivityLogServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ActivityLog[]> {
        return this.backend.getAllActivityLog();
    }

}