<div class="tab-content">
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./inbox']">
                        <span translate>INBOX</span>
                    </a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./outbox']">
                        <span translate>OUTBOX</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <router-outlet></router-outlet>
</div>