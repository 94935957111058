import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { ProjectEdit } from '../models/projectEdit';
import { ProjectList } from '../models/projectlist';
import { BaseServices } from './../../kuba.services';
import { Observable } from 'rxjs/Observable';
import { SjaForm } from 'src/app/kuba/sja-form/components/sja-form';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProjectServices {
  constructor(private http: HttpClient) {}

  // List all projects
  list() {
    let result: ProjectList;
    return this.http
      .get(environment.BASE_URL + '/project/list', BaseServices.headerOption)
      .map((res) => res);
  }
  getlistByBusiness(id: number) {
    let result: any;
    return this.http
      .get(
        environment.BASE_URL + '/project/getall/' + id,
        BaseServices.headerOption
      )
      .map((res: any) => res);
  }
  getProjectById(id: number) {
    let result: any;
    return this.http
      .get(environment.BASE_URL + '/Project/' + id, BaseServices.headerOption)
      .map((res: any) => res);
  }
  create(newProject: ProjectEdit) {
    return this.http
      .post(
        environment.BASE_URL + '/project/save',
        newProject,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getProjectList(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/name/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getProjectByUser(Id: number, businessId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/project/getuserprojects/' +
          Id +
          '/' +
          businessId +
          '/' +
          BaseServices.FeatureId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getProjectByGuest(uID: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/getGuestprojects/' + uID,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getFDVByGuest(uID: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/getguestFDV/' + uID,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getSubContractor(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/ContractorLookup/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  // contactperson dropdown
  getClientUser(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/ContactLookup/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getUser(id: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/ContactLookup/' + id,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  update(ExistingProject: ProjectEdit) {
    return this.http
      .put(
        environment.BASE_URL + '/project/update',
        ExistingProject,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  deleteproject(id: number) {
    return this.http
      .delete(
        environment.BASE_URL + '/project/delete/' + id,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getResponsibility() {
    return this.http
      .get(
        environment.BASE_URL + '/project/Responsibility',
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }
  getContractorContactuser(contractorId: any[]) {
    return this.http
      .put(
        environment.BASE_URL + '/project/contractorcontacts/',
        contractorId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
  getClientContactUser(clientId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/clientcontact/clientcontactsuser/' + clientId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }

  getProjectFeatures(businessId: number) {
    let featureId: number;
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    featureId = workingModule.id;
    return this.http
      .get(
        environment.BASE_URL + '/business/' + businessId,
        BaseServices.headerOption
      )
      .map((result: any) => {
        let feature = result.Features;
        let projectAdditionalFeature = _.find(JSON.parse(feature), {
          id: featureId,
          access: true,
        });
        return projectAdditionalFeature;
      });
  }
  getProjectFDVStatus(pId: number) {
    return this.http
      .get(
        environment.BASE_URL + '/project/fdvStatus/' + pId,
        BaseServices.headerOption
      )
      .map((result: any) => result);
  }

  getActiveClientContactUser(clientId: number) {
    return this.http
      .get(
        environment.BASE_URL +
          '/clientcontact/activeclientcontactsuser/' +
          clientId,
        BaseServices.headerOption
      )
      .map((result) => result);
  }
}
