export class ManualDocument {
    Id: number;
    AppManualId: number;
    ApplicationId: number;
    PortalId: number;
    BusinessId: number;
    AppManualFolderId: number;
    Title: string;
    OriginalFileName: string;
    DocumentTypeId: number;
    Path: string;
    MimeType: string;
    Size: number;
    Status: number;
    CreatedOn: string;
    CreatedBy: number;
    ModifiedBy: number;
    ModifiedOn: string;
}
export class ArchiveDocument {
    Id: number;
    BusinessId: number;
    ContractId: number;
    FeatureId: number;
    FeatureKey: number;
    ArchiveChecklistId: number;
    ArchiveFolderId: number;
    FileName: string;
    OriginalFileName: string;
    Size: number;
    MimeType: string;
    Path: string;
    FileType: number;
    Status: number;
    CreatedOn: string;
    CreatedBy: number;
    ModifiedBy: number;
    ModifiedOn: string;
    IsParentBuilding:boolean;
}
