import {
    NewsListResolver,
    NewsEditResolver,
    AdminNewsListResolver
} from './services/news.resolver.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewsListComponent } from './components/NewsList.component';
import { CreateNewsComponent } from './components/CreateNews.component';
import { NewsComponent } from './news.component';
import { 
    ConfirmationService,
     SharedModule } from 'primeng/api';
import { NewsServices } from './services/news.service';
import { AppSharedModule } from './../shared/appShared.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MyDatePickerModule } from 'mydatepicker';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ReactiveFormsModule,
        ContextMenuModule,
        MenuModule,
        ConfirmDialogModule,
        TranslateModule,
        MyDatePickerModule,
        AppSharedModule
    ],
    declarations: [
        NewsComponent, 
        NewsListComponent, 
        CreateNewsComponent
    ],
    providers: [
        NewsServices,
        ConfirmationService,
        NewsListResolver,
        NewsEditResolver,
        AdminNewsListResolver
    ],
    bootstrap: [NewsListComponent, CreateNewsComponent, NewsComponent]
})
export class NewsModule {}
