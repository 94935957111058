import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'inspection',
    template: `<router-outlet></router-outlet>`
})

export class InspectionComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}