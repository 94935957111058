import { Component, OnInit } from '@angular/core';

// TODO: to be implemented
@Component({
  selector: 'features-rights',
  templateUrl: './featuresAndRights.component.html',
})
export class FeaturesAndRightsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
