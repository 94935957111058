<div class="tab-content">
    <div class="page-title">
        <span translate>POWER</span>
    </div>
    <form [formGroup]="measureProtocolPowerLeakage">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group" *ngIf="measureProtocolPowerLeakage.controls['PowerReading']">
                    <label for="Title">
                        <span translate>MA</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="number" formControlName="PowerReading" id="mA" class="form-control" />
                    </div>
                    <control-messages [control]="measureProtocolPowerLeakage.controls['PowerReading']">
                    </control-messages>
                </div>
                <div class="form-group" *ngIf="measureProtocolPowerLeakage.controls['Comments']">
                    <label>
                        <span translate>COMMENTS</span>
                    </label>
                    <div>
                        <textarea formControlName="Comments" id="Comments" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper text-left">
            <button class="btn btn-primary" [disabled]="measureProtocolPowerLeakage.invalid || saveprogress"
                (click)="saveChanges()">
                <span translate>SAVE</span>
            </button>
            <button type="button" class="btn btn-outline-secondary" (click)="onClear()">
                <span translate>CLEAR</span>
            </button>
        </div>
    </form>

    <div class="mtop-20">
        <div class="page-title" translate>POWER</div>
        <!-- Table starts -->
        <p-table #dt [value]="powerLeakage" scrollable="true" scrollHeight="200px" [rows]="10" [paginator]="true"
            [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="PowerReading">{{'MA' | translate}} <p-sortIcon field="PowerReading"></p-sortIcon></th>
                    <th pSortableColumn="Comments">{{'COMMENTS' | translate}} <p-sortIcon field="Comments"></p-sortIcon></th>
                    <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
                    <th pSortableColumn="CreatedOn">{{'CREATED_ON' | translate}} <p-sortIcon field="CreatedOn"></p-sortIcon></th>
                    <th pSortableColumn="ModifiedName">{{'EDITED_BY' | translate}} <p-sortIcon field="ModifiedName"></p-sortIcon></th>
                    <th>{{'OPTIONS'| translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-pl>
                <tr>
                    <td>
                        <span>{{pl.PowerReading}}</span>
                    </td>
                    <td>
                        <span>{{pl.Comments}}</span>
                    </td>
                    <td>
                        <span>{{pl.CreatedName}}</span>
                    </td>
                    <td>
                        <span>{{pl.CreatedOn | date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{pl.ModifiedName}}</span>
                    </td>
                    <td class="col-button">
                        <a class="btn btn-icon" title="{{'EDIT' | translate}}" (click)="editPowerLeakage(pl['Id'])"
                            routerLinkActive="active">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </a>
                        <button class="btn btn-icon" title="{{'DELETE' | translate}}"
                            (click)="deletePowerLeakage(pl['Id'])" routerLinkActive="active">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="6">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends-->
    </div>
    <div *ngIf="!isElectro" class="action-btns-wrapper text-right">
        <button type="button" class="btn btn-outline-primary" [routerLink]="['./../temperature']">
            <span translate>NEXT</span>
            <span class="icon ic-xs icon-forward"></span>
        </button>
    </div>
</div>
<toaster-component></toaster-component>
<p-dialog header="{{('CONFIRMATION') | translate}}" *ngIf="ShowConfirm" [(visible)]="ShowConfirm" [modal]="true"
[style]="{width: '500px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'visible'}">
    <span translate>DELETE_THIS_RECORD</span>
    <div class="action-btns-wrapper">
        <button type="submit" class="btn btn-primary" (click)="Confirmation(true)">
            <span class="pi pi-check"></span><span translate>SWITCH_YES</span>
        </button>
        <button type="submit" class="btn btn-primary" (click)="Confirmation(false)">
            <span class="pi pi-times"></span><span translate>SWITCH_NO</span>
        </button>
    </div>
</p-dialog>