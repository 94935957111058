<!-- Table starts -->
<p-table [value]="suggestionbox" [rows]="10" [paginator]="true"
    [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
    <!-- Footer -->
    <ng-template pTemplate="summary">Todo:Users Info and Export buttons</ng-template>

    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th>{{'NO'|translate}}</th>
            <th pSortableColumn="Status">{{'STATUS'|translate}}</th>
            <th pSortableColumn="Title">{{'TITLE'|translate}}</th>
            <th pSortableColumn="Description">{{'DESCRIPTION'|translate}}</th>
            <th pSortableColumn="CreatedOn">{{'CREATED_ON'|translate}}</th>
            <th pSortableColumn="ModifiedBy">{{'MODIFIED_BY'|translate}}</th>
            <th></th>
        </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-commonManual>
        <tr>
            <td>
                <span>{{commonManual.Id}}</span>
            </td>
            <td>
                <span>{{commonManual.Status}}</span>
            </td>
            <td>
                <span>{{commonManual.Title}}</span>
            </td>
            <td>
                <span>{{commonManual.Description}}</span>
            </td>
            <td>
                <span>{{commonManual.CreatedOn}}</span>
            </td>
            <td>
                <span>{{commonManual.ModifiedBy}}</span>
            </td>
            <td class="col-button">
                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                    (click)="editsuggestion(commonManual)">
                    <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                    (click)="deletesuggestion(commonManual)">
                    <i class="icon ic-sm icon-trash"></i>
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>
<!-- Table Ends -->
<button type="button" class="btn btn-icon" (click)="newSuggestion()">
    <i class="icon ic-sm icon-add"></i>
</button>
<p-dialog header="Edit Suggestion" [(visible)]="showEditSuggestion" [modal]="true" 
[style]="{width: '600px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="suggestionForm" novalidate>
        <div class="form-group">
            <label for="title" translate>TITLE</label>
            <span class="required">*</span>
            <input type="text" class="form-control" formControlName="Title" [value]="Title" name="Title" />\
        </div>
        <div class="form-group">
            <label for="description" translate>DESCRIPTION</label>
            <span class="required">*</span>
            <textarea class="form-control" formControlName="Description" [value]="Description" name="Description"
                class="form-control"></textarea>
        </div>
        <div class="form-group">
            <label for="description" translate>STATUS</label>
            <span class="required">*</span>
            <textarea class="form-control" formControlName="Status" [value]="Status" name="Status"
                class="form-control"></textarea>
        </div>
    </form>
    <!-- Footer -->
    <ng-template pTemplate="summary">
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-secondary" (click)="showEditSuggestion=false"
                translate>CANCEL</button>
            <button type="button" class="btn btn-primary" (click)="updateClose(suggestionForm.value)"
                translate>UPDATE</button>
        </div>
    </ng-template>
</p-dialog>