import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { HelpSupport } from "../models/helpsupport";
import { HelpSupportServices } from '../services/help-support.service'

@Injectable()
export class HelpSupportListResolver implements Resolve<HelpSupport[]> {
    constructor(private backend: HelpSupportServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<HelpSupport[]> {
        if (BaseServices.roleId === '1') {
            return this.backend.adminHelpSupportlist();
        } else {
            return this.backend.list();
        }
    }
}

@Injectable()
export class HelpsupportEditResolver implements Resolve<HelpSupport> {
    constructor(private backend: HelpSupportServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.get(+route.params['aid']);
    }
}
@Injectable()
export class HelpSupportUserListResolver implements Resolve<HelpSupport[]> {
    constructor(private backend: HelpSupportServices) { }
    resolve(): Observable<HelpSupport[]> {
        return this.backend.getAllHelpSupportDetails();
    }
}
