import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class FaqServices {

    constructor(private http: HttpClient) { }

    getCategory() {
        return this.http
            .get(environment.BASE_URL + '/faq/faqs',BaseServices.headerOption)
            .map(result => result);
    }
    
    add(data) {
        return this.http
            .post(environment.BASE_URL + '/faq/add', data,BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    update(id, data) {
        return this.http
            .put(
            environment.BASE_URL + '/faq/updatefaq/' + id,
            data,
            BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
    getFaqByFeatureId(fid) {
        return this.http
            .get(
            environment.BASE_URL + '/faq/faqByFeatureId/' + fid,
            BaseServices.headerOption
            )
            .map(result => result);
    }

    getFaqById(id: number) {
        return this.http
            .get(
            environment.BASE_URL + '/faq/faq/' + id,
            BaseServices.headerOption
            )
            .map(result => result);
    }
    getFaqByAdditionalFeature(aid) {
        // tslint:disable-next-line:max-line-length
        return this.http
            .get(
            environment.BASE_URL + '/faq/faqByAdditionalFeatureId/' + aid,
            BaseServices.headerOption
            )
            .map(result => result);
    }

    getAllFeatures() {
        return this.http
            .get(environment.BASE_URL + '/faq/allfeatures',BaseServices.headerOption)
            .map(result => result);
    }
}
