<div class="page-title">
    <a [routerLink]="['./../../../../list/own']" class="btn btn-icon" title="{{'BACK_PROJECTS'|translate}}">
        <span class="icon ic-xs icon-back"></span>
    </a>
    <span translate>FDV_DOCUMENTS</span>
</div>
<div>
    <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
</div>
<div *ngIf="type ==='common'" class="table-view">
    <div class="spinner-overlay" *ngIf='showProgress'>
        <div class="spinner-wrapper-loader">
            <span class="fdv-progress" style="width:50%">
                <span class="progress" style="height:60%">
                    <span class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100" [ngStyle]="{ 'width': progressPercentage + '%'}">
                    </span>
                </span>
                <span class="progressPercentage">
                    &nbsp;&nbsp; {{progressPercentage}} %
                </span>
            </span>
        </div>
    </div>
    <!-- Table starts -->
    <p-table [rows]="defaultRowsPerPage" [value]="commonDocumentList" [(selection)]="selectedCommonDocumentList"
        (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event,'common')" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 55px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{'PRODUCT_NUMBER' | translate}}</th>
                <th>{{'PRODUCT_NAME' | translate}}</th>
                <th>{{'EDITED_DATE' | translate}}</th>
                <th>{{'EDIT' | translate}}</th>
                <th>{{'PDF' | translate}}</th>
                <th *ngIf="isHideGuestButton">{{'FDV_DELETE' | translate}}</th>
                <th *ngIf="isHideGuestButton">{{'REPLACE' | translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-x>
            <tr>
                <td style="width: 55px;">
                    <p-tableCheckbox id="selectedOrders" [value]="x">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span [style.color]="(x.type === 6 && x.itemId === 0) ? 'red' : 'black' ">{{x.itemNumber}}</span>
                </td>
                <td>
                    <span
                        [style.color]="(x.type === 1 || x.type === 6 && x.itemId !== 0 )  ? 'green' : x.type === 2 ? 'blue': (x.type === 3 || x.type === 8  || x.type ===6 && x.itemId ===0 )  ? 'red' : 'block' ">{{x.itemName}}</span>
                </td>
                <td>
                    <span>{{x.articleModifiedOn}}</span>
                </td>
                <td>
                    <span *ngIf="x.type === 5">
                        <ecpl-article-viewer [style]="{'width':'50%'}" [articleName]="x.article.filename"
                            [(articleSrc)]="x.article.content" [id]="x.article.nodeId" [editMode]="true"
                            (saveChanges)="saveUploaded($event)">
                        </ecpl-article-viewer>
                    </span>
                </td>
                <td>
                    <button (click)="downloadFDF(x)" type="button"
                        *ngIf="x.filePath !== null && (x.filePath !== 'Unsorted')" class="btn btn-icon" title="PDF">
                        <i class="icon ic-sm icon-pdf"></i>
                    </button>
                    <button (click)="downloadNoEFOPage(x)" type="button"
                        *ngIf="x.filePath === null && (x.type === 1 || x.type === 6 ) && (x.itemNumber !== 'Unsorted')"
                        class="btn btn-icon" title="PDF">
                        <i class="icon ic-sm icon-030"></i>
                    </button>
                </td>
                <td *ngIf="isHideGuestButton">
                    <button (click)="deleteCommonList(x)" type="button" *ngIf="x.type !==7 " class="btn btn-icon"
                        title="{{'DELETE' | translate}}">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
                <td *ngIf="isHideGuestButton">
                    <button type="button" *ngIf="x.filePath === null && (x.type === 1 ||x.type === 2 || x.type ===6)"
                        (click)="ReplaceMissingEFODocument(x.itemId)" class="btn btn-icon" title="Replace">
                        <i class="icon ic-sm icon-032"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isHideGuestButton" let-columns>
            <tr>
                <td colspan="8">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="6">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
    <button (click)="fdvBulkDownLoad()" *ngIf="isHideGuestButton" [disabled]="validateSelectedCommon()" type="button "
        class="btn btn-primary ">
        <span translate>FDV_BULK_DOWNLOAD</span>
    </button>
    <button (click)="deleteBulkCommonList()" *ngIf="isHideGuestButton" [disabled]="validateSelectedCommon()"
        type="button " class="btn btn-primary ">
        <span translate>DELETE_SELECTED_ITEMS</span>
    </button>
</div>

<div *ngIf="type ==='efo' && fdvDocumentList" class="table-view">
    <!-- Table starts -->
    <p-table [rows]="20" [value]="fdvDocumentList" [paginator]="true" [(selection)]="selectedEFODocumentList"
        (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event,'efo')" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20,1000]" [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{'PRODUCT_NUMBER' | translate}}</th>
                <th>{{'PRODUCT_NAME' | translate}}</th>
                <th>{{'BUILDING_SECTION' | translate}}</th>
                <th *ngIf="isHideGuestButton">{{ 'CHOOSE' | translate}}</th>
                <th>{{'FDV_DELETE' | translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-fdvEfo>
            <tr>
                <td style="width: 38px;">
                    <p-tableCheckbox id="selectedOrders" [value]="fdvEfo">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span>{{fdvEfo.itemNumber}}</span>
                </td>
                <td>
                    <span>{{fdvEfo.itemName}}</span>
                </td>
                <td>
                    <p-multiSelect [options]="fdvEfo.projectArticle" [(ngModel)]="fdvEfo.projectSelectedArticle"
                        [style]="{ 'width': '100%'}" optionLabel="name" defaultLabel="{{ 'CHOOSE' | translate}}">
                    </p-multiSelect>
                </td>
                <td class="col-button" *ngIf="isHideGuestButton">
                    <span>
                        <a href="javascript:void(0); " (click)="DeleteSingleFDVEfo(fdvEfo)" class="btn btn-icon">
                            <span class="icon ic-sm icon-trash" aria-hidden="true"></span>
                        </a>
                    </span>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isHideGuestButton" let-columns>
            <tr>
                <td colspan="5">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="4">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="text-align: right"
        *ngIf="isHideGuestButton">
        <button (click)="saveSelectedEFODocumentList()" type="button" class="btn btn-primary"
            [disabled]="validateSelectedFDVEFODocument()">
            <span translate>SAVE</span>
        </button>
        <button (click)="DeleteMultipleEfoDoc()" [disabled]="validateSelectedFDVEFODocument()" type="button "
            class="btn btn-primary ">
            <span translate>DELETE_SELECTED_ITEMS</span>
        </button>
    </div>
</div>

<div *ngIf=" type==='owndocument' && fdvDocumentList" class="table-view">
    <!-- Table starts -->
    <p-table [rows]="5" [value]="fdvDocumentList" [paginator]="true" [(selection)]="selectedOwnDocumentList"
        (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event,'own')" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20,1000]" [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{ 'PRODUCT_NUMBER' | translate}}</th>
                <th>{{ 'PRODUCT_NAME' | translate}}</th>
                <th>{{ 'BUILDING_SECTION' | translate}}</th>
                <th>{{ 'PDF' | translate}}</th>
                <th *ngIf="isHideGuestButton">{{'FDV_DELETE' | translate}}</th>
                <th *ngIf="isHideGuestButton">{{'REPLACE' | translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-x>
            <tr>
                <td style="width: 55px;">
                    <p-tableCheckbox id="selectedOrders" [value]="x">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span>{{x.itemNumber}}</span>
                </td>
                <td>
                    <span>{{x.itemName}}</span>
                </td>
                <td>
                    <p-multiSelect [options]="x.projectArticle" [(ngModel)]="x.projectSelectedArticle"
                        [style]="{ 'width': '100%'}" optionLabel="name" defaultLabel="{{ 'CHOOSE' | translate}}">
                    </p-multiSelect>
                </td>
                <td>
                    <button (click)="downloadFDF(x)" *ngIf="x.path != null" type="button" class="btn btn-icon"
                        title="PDF">
                        <i class="icon ic-sm icon-pdf"></i>
                    </button>
                </td>
                <td *ngIf="isHideGuestButton">
                    <button (click)="DeleteFdvLookUp(x)" type="button" class="btn btn-icon"
                        title="{{'DELETE' | translate}}">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
                <td *ngIf="isHideGuestButton">
                    <button type="button" (click)="ReplaceOwnDocument(x)" class="btn btn-icon"
                        title="{{'REPLACE'|translate}}">
                        <i class="icon ic-sm icon-032"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isHideGuestButton" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="5">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="text-align: right "
        *ngIf="isHideGuestButton">
        <button (click)="saveSelectedOwnDocumentList()" [disabled]="validateSelectedFDVOwnDocument()" type="button"
            class="btn btn-primary">
            <span translate>SAVE</span>
        </button>
        <!-- [disabled]="validateSelectedFDVOwnDocument() " -->
    </div>
</div>

<div *ngIf="type==='projectphotos' && fdvProjectPhoto " class="table-view ">
    <!-- Table starts -->
    <p-table [rows]="5 " [value]="fdvProjectPhoto " [paginator]="true " [(selection)]="selectedProjectPhotoList"
        (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event,'projectphotos')" [pageLinks]="3 "
        [rowsPerPageOptions]="[5,10,20,1000] " [responsive]="true ">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{'TITLE'|translate}}</th>
                <th>{{ 'BUILDING_SECTION' | translate}}</th>
                <th>{{ 'IMG_PREVIEW' | translate}}</th>
                <th *ngIf="isHideGuestButton">{{'FDV_DELETE'|translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-fdvPhoto>
            <tr>
                <td style="width: 38px;">
                    <p-tableCheckbox id="selectedOrders" [value]="fdvPhoto">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span>{{fdvPhoto.ImageTitle}}</span>
                </td>
                <td>
                    <p-multiSelect [options]="fdvPhoto.ProjectArticle " [(ngModel)]="fdvPhoto.ProjectSelectedArticle
                " optionLabel="Name" defaultLabel="{{ 'CHOOSE' | translate}}" [style]="{ 'width': '100%'}">
                    </p-multiSelect>
                </td>
                <td>
                    <span>
                        <img (mouseenter)="PreviewProjectPhoto(fdvPhoto) " src="{{fdvPhoto.FDVManualDocPath}}" alt=" "
                            width="90px ">
                    </span>
                </td>
                <td class="col-button" *ngIf="isHideGuestButton">
                    <span>
                        <a href="javascript:void(0);" (click)="DeleteFdvPhotoProject(fdvPhoto.Id)"
                            class="btn btn-icon ">
                            <span class="icon ic-sm icon-trash " aria-hidden="true "></span>
                        </a>
                    </span>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isHideGuestButton" let-columns>
            <tr>
                <td colspan="5">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="4">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="text-align: right "
        *ngIf="isHideGuestButton">
        <button (click)="saveSelectedProjectPhotoList()" [disabled]="validateSelectedPhotoProject()" type="button "
            class="btn btn-primary ">
            <span translate>SAVE</span>
        </button>
        <!-- [disabled]="ValidateSelectedPhotoProject() " -->
    </div>
</div>

<div *ngIf="type==='ahlselldatabase' && forvaltningDocumentList" class="table-view">
    <!-- Table starts -->
    <p-table [rows]="5" [value]="forvaltningDocumentList " [paginator]="true"
        [(selection)]="selectedforvaltningDocumentList"
        (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event,'ahlselldatabase')" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20,1000] " [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{ 'CHAPTER' | translate}}</th>
                <th>{{'ARTICLE'|translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-x>
            <tr>
                <td style="width: 38px;">
                    <p-tableCheckbox id="selectedOrders" [value]="x">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span translate>Forvaltning EL</span>
                </td>
                <td>
                    <span>{{x.Title}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="text-align: right "
        *ngIf="isHideGuestButton">
        <button (click)="SaveSelectedForvaltningEL()" type="button" class="btn btn-primary "
            [disabled]="validateSelectedForvaltning()">
            <span translate>SAVE</span>
        </button>
    </div>
</div>

<!--Panel Loader-->
<div *ngIf="showLoader" class="panel-loader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->

<div class="action-btns-wrapper " *ngIf="isHideGuestButton">
    <button type="button " *ngIf="type==='efo'" (click)="showDlgBase() " class="btn btn-outline-primary"
        translate>UPLOAD_FROM_NRF_BASE</button>
    <button type="button " *ngIf="type==='owndocument' " (click)="showDlgdoc() " class="btn btn-outline-primary"
        translate>CHOOSE_FROM_YOUR_OWN_DOCUMENT</button>
    <button type="button " *ngIf="type==='projectphotos' " (click)="showDlgPhoto() " class="btn btn-outline-primary"
        translate>PROJECT_PHOTOS
    </button>
</div>
<!-- Upload from EFO Base -->
<p-dialog header="{{'UPLOAD_FROM_NRF_BASE'|translate}}" [(visible)]="displayEFOBase " [style]="{width: '600px'}"
    [resizable]="false" [draggable]="false">
    <div class="form-group">
        <label class="col-form-label">
            <span translate>IMPORT_PRODUCTS_EXCEL</span>
        </label>
        <div class="upload-actions">
            <label class="btn btn-outline-secondary upload-button">
                <input type="file" (change)="incomingfile($event)"
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                <span translate>{{filename | translate}}</span>
            </label>
            <button (click)="Upload()" [disabled]="isDisableUploadButton" type="button"
                class="btn btn-outline-primary start-upload-btn" translate>
                START_UPLOAD
            </button>
            <a class="btn btn-outline-secondary" href="./../assets/template_files/NRFtemplate.xls" download>
                <i class="pi pi-question " aria-hidden="true"></i>
            </a>
        </div>
    </div>
</p-dialog>
<!-- Choose from your own document  -->
<p-dialog header="{{'CHOOSE_FROM_YOUR_OWN_DOCUMENT'|translate}} " [(visible)]="displaydoc " [style]="{width: '900px'}"
    [resizable]="false" [draggable]="false">

    <div class="form-group ">
        <label for="Browse " class="col-form-label ">
            <span translate>MORE_ATTACHMENTS</span>
        </label>
        <div>
            <!-- TODO: Upload Multiple -->
        </div>
    </div>
    <div class="form-group ">
        <ecpl-document-explorer [style]="{ 'width': '160px', 'height': '100px'} " [uploaderConfig]="upConfig
            " [uploadedFiles]="fdvAttachments " (saveChanges)="uploaderEvents($event) ">
        </ecpl-document-explorer>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix ">
            <button (click)="saveUploadedOwnDocument() " type="button " class="btn btn-primary ">
                <span translate>SAVE</span>
            </button>
        </div>
    </p-footer>
</p-dialog>
<!-- Project Photos  -->
<p-dialog header="Project photos" [(visible)]="displayphoto " [modal]="true" [style]="{width: '600px'}"
    [resizable]="false" [draggable]="false">
    <div class="form-group ">
        <label for="Browse " class="col-form-label ">
            <span translate>UPLOAD_PHOTO</span>
        </label>
    </div>
    <div class="form-group">
        <ecpl-document-explorer [style]="{ 'width': '160px', 'height': '100px'} " [uploaderConfig]="upConfig
            " [uploadedFiles]="fdvAttachments " (saveChanges)="uploaderEvents($event) ">
        </ecpl-document-explorer>
    </div>

    <div class="action-btns-wrapper">
        <button (click)="UploadPhotoFiles()" type="button" class="btn btn-outline-primary">
            <span translate>SAVE_IMAGEDOC</span>
        </button>
    </div>

</p-dialog>
<p-dialog (mouseleave)="ExitPreviewProjectPhoto()" header="{{displayPhotoTitle}}" [(visible)]="displayPreviewPhoto"
    [style]="{width: '530px'}" [resizable]="false" [draggable]="false">
    <img src="{{displayPhotoURL}} " alt=" " width="500px ">
</p-dialog>

<p-dialog header="{{replaceHeader}}" [(visible)]="displayMissingEFOReplace" [modal]="true" [style]="{width: '530px'}"
    [resizable]="false" [draggable]="false" [contentStyle]="{ 'overflow': 'visible'}">
    <div class="form-group">
        <div class="upload-actions">
            <label>
                <span translate> REPLACE_PDF_FROM_PC </span>
            </label>
            <div>
                <input type="file" id="files" class="hidden" (change)="replaceEFOwithFileUpload($event)" />
                <label for="files" class="btn btn-outline-primary" translate>UPLOAD</label>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label>
            <span translate>CHOOSE_EXISTING_PDF</span>
        </label>
        <div>
            <p-dropdown [style]="{ 'width': '200px'}" [options]="ownDocumentList" [(ngModel)]="selectOwnDocument"
                [filter]="true"></p-dropdown>
        </div>
    </div>
    <p-footer class="text-right">
        <button (click)="replaceEfoWithOwnDocument()" type="button" class="btn btn-primary">
            <span translate>SAVE</span>
        </button>
    </p-footer>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<p-dialog [(visible)]="nrfdocumentdailog" [style]="{width: '600px'}" [resizable]="false" [draggable]="false">
    <label>
        <span translate> EFO_NRF_DAILOG_MESSAGE1 </span>
    </label>
    <label>
        <span translate> EFO_NRF_DAILOG_MESSAGE2 </span>
    </label>
    <p-footer>
        <button (click)="showNotificationTab()" type="button" class="btn btn-primary">
            <span translate>OK</span>
        </button>
    </p-footer>
</p-dialog>
<p-dialog [(visible)]="efobulkreportdailog" [style]="{width: '600px'}" [resizable]="false" [draggable]="false">
    <label>
        <span translate> EFO_NRF_DIALOG_MESSAGE3 </span>
    </label>
    <label>
        <span translate> EFO_NRF_DAILOG_MESSAGE2 </span>
    </label>
    <p-footer>
        <button (click)="showNotificationTab()" type="button" class="btn btn-primary">
            <span translate>OK</span>
        </button>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>