import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { ProjectAdmin } from './../../project-admin/models/project-admin';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AssignProjectService {
    constructor(private http: HttpClient) {}

    getPortalList() {
        return this.http
            .get(environment.BASE_URL + '/portalnamelist', BaseServices.headerOption)
            .map(result => result);
    }
    getBusinessList() {
        return this.http
            .get(environment.BASE_URL + '/businessnamelist', BaseServices.headerOption)
            .map(result => result);
    }
    getProjectById(id: number) {
        return this.http
            .get(environment.BASE_URL + '/projecttemplate/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    getProjectList(applicationId: number) {
        return this.http
            .get(
                environment.BASE_URL + '/admin-projecttemplate/' + applicationId, BaseServices.headerOption
            )
            .map((result: any) => result);
    }
    getProjectListByBusiness(applicationId: number, businessId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                    '/projectlistbybusiness/' +
                    applicationId +
                    '/' +
                    businessId,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    getProjectListByPortal(applicationId: number, portalId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                    '/projectlistbyportal/' +
                    applicationId +
                    '/' +
                    portalId,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    updateStatus(projectId: number, status: number) {
        return this.http
            .put(
                environment.BASE_URL + '/projecttemplatestatus/' + projectId,
                status,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    assignProject(project: ProjectAdmin[]) {
        return this.http
            .post(
                environment.BASE_URL + '/assignprojecttemplate',
                project,
                BaseServices.headerOption
            )
            .map(result => result);
    }
}
