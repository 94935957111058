import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { Vehicle } from './../models/vehicle';
import { VehicleServices } from './vehicle.service';

@Injectable()
export class VehicleListResolver implements Resolve<Vehicle[]> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Vehicle[]> {
        return this.backend.getAllVehicles();
    }
}

@Injectable()
export class VehicleEditResolver implements Resolve<Vehicle> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getVehiclesById(+route.params['id']);
    }
}
@Injectable()
export class VehicleSizeResolver implements Resolve<Vehicle[]> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Vehicle[]> {
        return this.backend.getAllVehicleSizes();
    }
}
@Injectable()
export class VehicleBrandResolver implements Resolve<Vehicle[]> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Vehicle[]> {
        return this.backend.getAllBrands();
    }
}
@Injectable()
export class TypeOfVehicleVehicleResolver implements Resolve<Vehicle[]> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Vehicle[]> {
        return this.backend.getAllVehicleType();
    }
}
@Injectable()
export class VehicleModelResolver implements Resolve<Vehicle[]> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Vehicle[]> {
        return this.backend.getAllModels();
    }
}

@Injectable()
export class VehicleFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: VehicleServices) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getVehicleFeatures(BaseServices.BusinessId);
    }
}

@Injectable()
export class VehicleListResolverByUserResolver implements Resolve<Vehicle[]> {
    constructor(private backend: VehicleServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Vehicle[]> {
        return this.backend.getUserVehicles(BaseServices.UserId);
    }
}

