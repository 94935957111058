import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
    templateUrl: 'employees.component.html'
})
export class EmployeesComponent implements OnInit {

    //#region variable

    name: string;
    parentRoute = 'List';

    //#endregion

    //#region constructor
    constructor(
        private route: ActivatedRoute, ) {
        this.parentRoute = route.snapshot.parent!.data['parent'];
    }

    //#endregion

    //#region page-event

    ngOnInit() {
        sessionStorage.setItem('Pageindex',JSON.stringify(1));
        sessionStorage.setItem('PageRow','10');
        document.querySelector("body").classList.remove("opened");
    }

    //#endregion
}
