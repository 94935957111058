<div class="main-content">
    <div class="card">
        <div class="card-body">
            <div class="page-title" translate>
                TRASH
            </div>
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="trash" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="CompanyName">{{'NAME'|translate}} <p-sortIcon
                                  field="CompanyName"></p-sortIcon></th>
                            <th pSortableColumn="Telephone">{{'TELEPHONE'|translate}} <p-sortIcon
                                  field="Telephone"></p-sortIcon></th>
                            <th pSortableColumn="Connectedto">{{'CONNECTED_TO'|translate}} <p-sortIcon
                                  field="Connectedto"></p-sortIcon></th>
                            <th pSortableColumn="HSEManager">{{'HSE_MANAGER'|translate}} <p-sortIcon
                                  field="HSEManager"></p-sortIcon></th>
                            <th pSortableColumn="EmployeeCount">{{'EMPLOYEES'|translate}} <p-sortIcon
                                  field="EmployeeCount"></p-sortIcon></th>
                            <th pSortableColumn="ModifiedOn">{{'DELETED_ON'|translate}} <p-sortIcon
                                  field="ModifiedOn"></p-sortIcon></th>
                            <th>{{'RESTORE'|translate}}</th>
                            <th>{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'CompanyName', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-trash>
                        <tr>
                            <td>
                                <span>{{trash.CompanyName}}</span>
                            </td>
                            <td>
                                <span>{{trash.Telephone}}</span>
                            </td>
                            <td>
                                <span>{{trash.Connectedto}}</span>
                            </td>
                            <td>
                                <span>{{trash.HSEManager}}</span>
                            </td>
                            <td>
                                <span>{{trash.EmployeeCount}}</span>
                            </td>
                            <td>
                                <span>{{trash.ModifiedOn | date:'dd-MM-yyyy'}}</span>
                            </td>
                            <td>
                                <button type="button" class="btn btn-icon" (click)="restoreTrash(trash.Id)"
                                  title="{{'RESTORE'|translate}}">
                                    <i class="pi pi-undo" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td class="col-button">
                                <button type="button" (click)="confirm(trash.Id)" class="btn btn-icon"
                                  title="{{'DELETE'|translate}}">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="8">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
              acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
        </div>
    </div>
</div>