export class Accesslog {
    Id ?: number;
    UserId ?: number;
    BusinessId ?: any;
    Ip ?: string;
    Browser ?: string;
    Os ?: string;
    ApplicationId ?: any;
    CreatedOn ?: string;
}


