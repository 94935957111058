<div class="main-content">
  <div class="main-heading">
    <span translate>MODULEOVERVIEW_LIST</span>
    <span class="page-actions text-right" *ngIf="isAdministrateHelp">
      <a *ngIf="!isAdmin" outerLink="create" [routerLink]="['./../create',0]" class="btn btn-success"
        routerLinkActive="active">
        <span class="icon ic-sm icon-add"></span>
        <span translate>NEW</span>
      </a>
    </span>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-view">
        <!-- Table starts -->
        <p-table #dt [value]="moduleoverview" [rows]="10" [paginator]="true" [pageLinks]="3"
          [rowsPerPageOptions]="[5,10,20]"
          [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'FEATURE_NAME'|translate}}</th>
              <th pSortableColumn="CreatedOn">{{'CREATED_ON'|translate}}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <p-dropdown width='118px' [options]="featureName" #featurename
                  (onChange)="onFeatureChange($event,featurename)" styleClass="p-column-filter" placeholder=""
                  [filterBy]="null" [showClear]="true">
                  <ng-template let-module pTemplate="item"> {{ module.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-commonmodule>
            <tr>
              <td>
                <span>{{commonmodule.TranslationKey | translate}}</span>
              </td>
              <td>
                <span>{{commonmodule.CreatedOn |date : 'dd/MM/yyyy'}}</span>
              </td>
              <td class="col-button">
                <a routerLink="create" [routerLink]="['./../create',commonmodule.Id]" class="btn btn-icon"
                  title="{{'EDIT'|translate}}" routerLinkActive="active">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </a>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                  (click)="confirm(commonmodule.Id)" *ngIf="isHideDeleteIcon">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="3">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
      <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
    </div>
  </div>
</div>
<toaster-component></toaster-component>