import { AppSharedModule } from './../shared/appShared.module';
import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FaqsViewComponent, FaqsComponent, CustomHelpComponent } from './index';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { FaqServices } from './services/faqs.service';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        EditorModule,
        SharedModule,
        CalendarModule,
        ContextMenuModule,
        DropdownModule,
        InputTextModule,
        InputTextareaModule,
        ButtonModule,
        VgControlsModule,
        VgCoreModule,
        VgBufferingModule,
        VgOverlayPlayModule,
        TranslateModule,
        AppSharedModule,
        ReactiveFormsModule,
        EcplDocumentExplorerModule
    ],
    exports: [FaqsViewComponent, FaqsComponent, CustomHelpComponent],
    declarations: [FaqsViewComponent, FaqsComponent, CustomHelpComponent],
    providers: [FaqServices]
})
export class FaqsModule {}
