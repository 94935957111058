<div class="d-flex align-items-center app-bar">
  <div class="kuba-logo">
    <img
      class="large-screen"
      *ngIf="
        currentUserData.ApplicationLogo == null ||
        currentUserData.ApplicationLogo == ''
      "
      src="{{ assetPath }}/images/KubaLogo.png"
      alt="Application logo"
    />
    <img
      class="small-screen"
      *ngIf="
        currentUserData.ApplicationLogo == null ||
        currentUserData.ApplicationLogo == ''
      "
      src="{{ assetPath }}/images/kuba-b.png"
      alt="Application logo"
    />

    <img
      *ngIf="currentUserData.ApplicationLogo != null"
      [src]="currentUserData.ApplicationLogo"
      alt="Application logo"
    />
  </div>
  <div class="d-flex align-items-center">
    <div class="divider"></div>
    <a
      [routerLink]="['dashboard']"
      *ngIf="businessBranding; then business; else application"
    >
    </a>
    <h6 class="client-name" *ngIf="currentUserData.BusinessName">
      {{ currentUserData.BusinessName }}
    </h6>

    <ng-template #business>
      <div class="logo-wrapper">
        <!-- Business has a logo -->
        <img
          class="client-logo"
          *ngIf="currentUserData.BusinessLogo != null"
          [src]="currentUserData.BusinessLogo"
          alt="Business Logo"
        />
      </div>
    </ng-template>
    <ng-template #application>
      <!-- Admin level -->
      <!-- <img
        *ngIf="currentUserData.ApplicationLogo != null && roleId == 1"
        [src]="currentUserData.ApplicationLogo"
        alt="Application logo"
      /> -->
      <!-- <div class="logo-wrapper">
        <img
          class="client-logo"
          *ngIf="currentUserData.BusinessLogo != null && roleId !== 2"
          [src]="currentUserData.BusinessLogo"
          alt="Application logo"
        />
      </div> -->
      <h6
        class="client-name"
        *ngIf="currentUserData.BusinessLogo == null && roleId !== 2"
      >
        {{ currentUserData.BusinessName }}
      </h6>
      <div class="logo-wrapper">
        <img
          class="client-logo app-logo"
          *ngIf="currentUserData.PortalLogo !== '' && roleId === 2"
          [src]="currentUserData.PortalLogo"
          alt="Application logo"
        />
      </div>
      <h6
        class="client-name"
        *ngIf="roleId === 2 && currentUserData.PortalName"
      >
        {{ currentUserData.PortalName }}
      </h6>
    </ng-template>
  </div>
</div>
