<style>
  .checklist-modal .panel-heading {
    background-color: #fff;
    padding: 0;
    font-size: 12px;
  }

  .checklist-modal .panel-body {
    background-color: #f5f5f5;
    padding: 0;
    border: none;
  }

  .checklist-modal .panel-heading .table td {
    border: none;
  }

  .checklist-modal .panel-heading td.col-button {
    background: transparent;
  }

  .checklist-modal .panel {
    border-left: 3px solid #ccc;
  }

  .checklist-modal .table > tbody > tr > td {
    vertical-align: middle;
  }

  .checklist-modal .table tr:hover {
    background: #eee;
  }
</style>
<div class="page-title">
  {{ Title }}
</div>
<form [formGroup]="activityForm" class="form">
  <input type="hidden" value="{{ ActivityScheduleId }}" />
  <div class="row">
    <div class="col-sm-4 section">
      <h4 translate>ACTIVITY_INFORMATION</h4>

      <div class="form-group">
        <label translate>STATUS</label>
        <div>
          <label
            class="form-check form-check-inline"
            *ngFor="let option of options"
          >
            <input
              type="radio"
              [readonly]="isReadOnly"
              formControlName="Status"
              value="{{ option.id }}"
              (change)="statusChange()"
              [checked]="option.id === activityForm.controls['Status'].value"
              class="form-check-input"
            />
            {{ option.name | translate }}
          </label>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label" translate>DEPARTMENT</label>
        <div class="input-btn-group">
          <p-multiSelect
            [options]="vDepartment"
            formControlName="Department"
            [style]="{ width: '100%' }"
            [defaultLabel]="'SELECT' | translate"
          ></p-multiSelect>
          <button
            *ngIf="isGuestHideButton"
            class="btn btn-outline-primary"
            type="button"
            (click)="showDepartment()"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
        <control-messages
          [control]="activityForm.controls['Department']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label class="col-form-label" translate
          >TITLE
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" pInputText formControlName="Title" />
        </div>
        <control-messages
          [control]="activityForm.controls['Title']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="Description" class="col-form-label" translate
          >DESCRIPTION</label
        >
        <div>
          <textarea
            name="Description"
            class="form-control"
            formControlName="Description"
          ></textarea>
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label">
          <span translate>DEADLINE</span>
          <span class="required">*</span>
        </label>
        <div>
          <my-date-picker
            name="deadline"
            [options]="dateOptions"
            (dateChanged)="onDeadLineChanged($event)"
            formControlName="Deadline"
            placeholder="{{ 'SELECT_DEADLINE' | translate }}"
            required
          >
          </my-date-picker>
        </div>
        <control-messages
          [control]="activityForm.controls['Deadline']"
        ></control-messages>
      </div>
      <!-- Checklist dropdown -->
      <div
        *ngIf="userRole === 'Editor' || userRole === 'User'"
        class="form-group"
      >
        <label class="col-form-label" translate>CHECKLIST</label>
        <div class="input-btn-group">
          <p-dropdown
            [filter]="true"
            [options]="checkList"
            formControlName="cmbCheckList"
            [disabled]="disableChecklistDropDown"
            (onChange)="onShowSeeChecklist($event)"
          ></p-dropdown>
          <button
            class="btn btn-link btn-icon"
            type="button"
            (click)="displayOverlay.toggle($event)"
          >
            <span class="icon ic-md icon-info"></span>
          </button>
        </div>
        <div
          class="mtop-10"
          *ngIf="
            activityForm['controls']['cmbCheckList'].value &&
            showCompleteCheckListButton &&
            showChecklist
          "
        >
          <button
            class="btn btn-primary"
            (click)="completeChecklist()"
            translate
          >
            COMPLETE_CHECKLIST
          </button>
        </div>
      </div>

      <!-- Container for repetition -->
      <div class="repetition-container form-group">
        <!-- Repetition dropdown -->
        <div class="repetition">
          <label class="col-form-label">
            <span translate>REPETITION</span>
            <span class="required">*</span>
          </label>
          <div>
            <p-dropdown
              [filter]="true"
              [disabled]="isRepetetionExists"
              formControlName="RepetitionId"
              [options]="repetitions"
              (onChange)="onRepetetionChange($event)"
            >
            </p-dropdown>
          </div>
          <control-messages
            [control]="activityForm.controls['RepetitionId']"
          ></control-messages>
        </div>
        <!-- Repetition checkbox -->

        <div class="repetition" *ngIf="isRepetetion && isChecklistSelected">
          <label for="lblRepeat" class="col-form-label" translate
            >REPEAT_CHECKLIST_RECURRING</label
          >
          <div>
            <p-checkbox formControlName="chkRepeat" binary="true"></p-checkbox>
          </div>
        </div>
      </div>

      <div class="form-group">
        <ecpl-document-explorer
          [uploaderConfig]="upConfig"
          [uploadedFiles]="uploadedFiles"
          (saveChanges)="saveUploaded($event)"
        >
        </ecpl-document-explorer>
      </div>
    </div>
    <div class="col-sm-4 section">
      <h4 translate>RESPONSIBILITY_AND_NOTIFICATIONS</h4>

      <div class="form-group">
        <label for="responsiblePerson" class="col-form-label" translate
          >RESPONSIBLE_FOR_EXECUTION
          <span class="required">*</span>
        </label>
        <div>
          <p-multiSelect
            [options]="vresperson"
            formControlName="ExecutionUser"
            [defaultLabel]="'CHOOSE' | translate"
          ></p-multiSelect>
        </div>
        <control-messages
          [control]="activityForm.controls['ExecutionUser']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="notificationDeadline" class="col-form-label" translate
          >NOTIFICATION_BEFORE_DEADLINE_ACTIVITIES</label
        >
        <!-- <div>
          <input
            type="number"
            min="0"
            pInputTextarea
            (keypress)="onNumber($event, 2)"
            formControlName="NotificationBeforeDeadline"
          />
        </div> -->
        <p-dropdown
          [options]="FollowUpDays"
          formControlName="NotificationBeforeDeadline"
        ></p-dropdown>
        <control-messages
          [control]="activityForm.controls['NotificationBeforeDeadline']"
        ></control-messages>
      </div>
      <div class="form-group">
        <label for="followUp" class="col-form-label" translate
          >RESPONSIBLE_FOR_FOLLOW_UP
          <span class="required">*</span>
        </label>
        <div>
          <p-multiSelect
            [options]="follows"
            formControlName="FollowUp"
            [defaultLabel]="'CHOOSE' | translate"
          ></p-multiSelect>
        </div>
        <control-messages
          [control]="activityForm.controls['FollowUp']"
        ></control-messages>
      </div>

      <div class="form-group">
        <label for="notificationDeadline" class="col-form-label" translate
          >NOTIFICATION_BEFORE_DEADLINE_ACTIVITIES1</label
        >
        <div>
          <p-dropdown
            [options]="FollowUpDays"
            formControlName="NotificationBeforeFollowUp"
          ></p-dropdown>
        </div>
      </div>
    </div>

    <!-- Activity log - Right side -->
    <div class="col-sm-4 section">
      <h4 translate>ACTIVITY_LOG</h4>

      <div class="wb-content" style="height: auto">
        <div class="default-list">
          <div class="list-item">
            <span translate>CREATED_BY</span> {{ CreatorName }}
          </div>
          <div class="list-item">
            <span translate>CREATED_ON</span> {{ createdOn }}
          </div>
        </div>
      </div>
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          [value]="activityLog"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "MODIFIED_FIELD" | translate }}</th>
              <th>{{ "PREVIOUS_VALUE" | translate }}</th>
              <th>{{ "MODIFIED_BY" | translate }}</th>
              <th>{{ "MODIFIED_ON" | translate }}</th>
            </tr>
          </ng-template>

          <!-- Body Content -->
          <ng-template pTemplate="body" let-activityLog>
            <tr>
              <td>
                <span translate>{{ activityLog.FieldName }}</span>
              </td>
              <td>
                <span
                  translate
                  pTooltip="{{ activityLog.OldValue }}"
                  tooltipPosition="right"
                  >{{ activityLog.OldValue }}</span
                >
              </td>
              <td>
                <span translate>{{ activityLog.Modifier }}</span>
              </td>
              <td>
                <span translate>{{ activityLog.CreatedOn }}</span>
              </td>
            </tr></ng-template
          >

          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="4">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <a class="btn btn-outline-secondary" [routerLink]="['../../']">
      <span class="icon ic-xs icon-back"></span>
      <span translate>BACK_LIST</span>
    </a>
    <button
      class="btn btn-primary"
      *ngIf="isChecklistSelected"
      (click)="showCheckList()"
      translate
    >
      SEE_CHECKLIST
    </button>
    <button
      class="btn btn-primary"
      [disabled]="!activityForm.valid"
      (click)="onSubmitTemplateBased(activityForm.value)"
      translate
    >
      SAVE
    </button>
    <div class="pull-right">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="exportPdf(activityForm.value)"
      >
        <span class="icon ic-sm icon-print"></span>
        <span translate>PRINT_PAGE</span>
        <div class="panel-loader" *ngIf="loadingReport">
          <span>
            <span class="loader-icon"></span>
            <span translate>PROCESSING</span>
          </span>
        </div>
      </button>
    </div>
  </div>
</form>

<p-overlayPanel #displayOverlay [dismissable]="true" appendTo="body">
  <h5>{{ "INFO" | translate }}</h5>
  <p>
    <span translate
      >IF_THE_CHECKLIST_IS_IN_UNDER_COMPLETION_WE_DO_NOT_HAVE_THE_OPTION_TO_CHANGE_THE_CHECKLIST_IN_DROPDOWN</span
    >
  </p>
</p-overlayPanel>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>

<p-dialog
  header="{{ checklistTile }}"
  [style]="{ width: '90%' }"
  [(visible)]="displayDialog"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
>
  <div class="panel-loader" *ngIf="!checklistDetails">
    <span>
      <span class="loader-icon"></span>
      <span translate>PROCESSING</span>
    </span>
  </div>
  <div *ngIf="checklistDetails">
    <ng-template
      ngFor
      let-section
      [ngForOf]="checklistDetails.Items"
      let-parent="index"
    >
      <div class="main-content">
        <div class="main-heading">
          <table class="table m-0">
            <tr>
              <th style="width: 55px">{{ parent + 1 }}</th>
              <th>{{ section.Title }}</th>
            </tr>
          </table>
        </div>
        <ng-template
          ngFor
          let-item
          [ngForOf]="section.SubItems"
          let-child="index"
        >
          <div class="card-body">
            <table class="table m-0">
              <tr>
                <td *ngIf="item.Status === 1" style="width: 55px">
                  {{ parent + 1 }} . {{ child + 1 }}
                </td>
                <td *ngIf="item.Status === 1">{{ item.Title }}</td>
              </tr>
            </table>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</p-dialog>
<p-dialog
  header="{{ 'ADD_DEPARTMENT' | translate }}"
  [(visible)]="department"
  modal="modal"
  (onHide)="onBeforeCaseDialogHide()"
  width="300"
  responsive="true"
>
  <form [formGroup]="departmentForm">
    <div class="form-group">
      <label for="title" class="control-label">
        <span translate>NAME</span>
      </label>
      <div>
        <input
          type="text"
          [(ngModel)]="selectedDepartment1.Name"
          class="label-input form-control"
          formControlName="Name"
        />
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-primary-outline"
        (click)="hidesElement = !hidesElement"
        (click)="viewDepartment()"
      >
        <span translate>VIEW</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="saveDepartment()"
        [disabled]="!departmentForm.valid"
      >
        <span translate>SAVE</span>
      </button>
    </div>
    <label
      for="title"
      color
      class="control-label"
      *ngIf="isDepartmentAllocated"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>CANNOT_DELETE</span>
    </label>
    <label
      for="title "
      color
      class="control-label"
      *ngIf="isDepartmentErrorDisabled"
      [ngStyle]="{ color: 'red' }"
    >
      <span translate>DEPARTMENT_EXIST</span>
    </label>

    <div *ngIf="hidesElement">
      <div class="table-view">
        <p-table
          #bt
          [value]="departmentdetails"
          [rows]="5"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "DEPARTMENT_NAME" | translate }}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="bt.filter($event.target.value, 'Name', 'contains')"
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-Type>
            <tr>
              <td>
                <span>{{ Type.Name }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'EDIT' | translate }}"
                  (click)="editDepartment(Type)"
                >
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteDepartment(Type)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-footer>
</p-dialog>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
