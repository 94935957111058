export class Topic {
  Id: number;
  Name: string;
  BusinessId: number;
  Status: string;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date;
  CaseId:number;
  ApplicationId: number
}
