<div class="tab-content">
    <div class="page-title">
        <span translate>TIME_TABLE</span>
        <span class="page-actions">
            <a [routerLink]="['./../newtimetable']" class="btn btn-success">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table #dt [value]="timetables" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
            [responsive]="true">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="filter-controls-wrapper" style="display:none">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="Month" class="col-form-label" translate>MONTH</label>
                                    <div>
                                        <p-dropdown [options]="months" [style]="{'width':'100%'}" ngModel id="Month"
                                            name="Month">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="Year" class="col-form-label" translate>YEAR</label>
                                    <div>
                                        <p-dropdown type="text" [options]="years" id="Year" ngModel name="Year"
                                            [style]="{'width':'100%'}">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                            <div class="action-btns-group">
                                <button class="btn p-3 filter-toggle">
                                    <span class="icon ic-sm icon-filter"></span>
                                    <span translate>FILTER</span>
                                </button>
                            </div>
                            <div class="action-btns-group">
                                <a disabled href="javascript:void(0);" class="btn">
                                    <!-- toggle-action-btn -->
                                    <i class="icon ic-sm icon-send-file-o"></i>
                                    <span translate>EXPORT</span>
                                </a>
                                <div class="export-btns">
                                    <button type="button" class="dropdown-item">
                                        <i class="icon ic-sm icon-xls mr-2"></i> Excel</button>
                                    <button type="button" class="dropdown-item">
                                        <i class="icon ic-sm icon-word mr-2"></i> Word</button>
                                    <button type="button" class="dropdown-item">
                                        <i class="icon ic-sm icon-pdf mr-2"></i> PDF</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="ProjectWorkTypeName">{{'TYPE_OF_WORK' | translate }}</th>
                    <th pSortableColumn="ProjectName">{{'PROJECT_NAME'|translate}}</th>
                    <th pSortableColumn="EmployeeName">{{'NAME'|translate}}</th>
                    <th pSortableColumn="Comments">{{'COMMENTS'|translate}}</th>
                    <th pSortableColumn="StartDate">{{'START_DATE'|translate}}</th>
                    <th pSortableColumn="StartTime">{{'START_TIME'|translate}}</th>
                    <th pSortableColumn="EndDate">{{'END_DATE'|translate}}</th>
                    <th pSortableColumn="EndTime">{{'END_TIME'|translate}}</th>
                    <th pSortableColumn="HoursSpent">{{'HOURS_SPENT'|translate}}</th>
                    <th pSortableColumn="ApproverName">{{'APPROVAL_PERSON'|translate}}</th>
                    <th pSortableColumn="ApprovalDate">{{'DATE_APPROVAL'|translate}}</th>
                    <th></th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'ProjectWorkTypeName', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'ProjectName', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'EmployeeName', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'Comments', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'StartDate', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'StartTime', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'EndDate', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'EndTime', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'HoursSpent', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'ApproverName', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="dt.filter($any($event.target)?.value, 'ApprovalDate', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-timetable>
                <tr>
                    <td>
                        <span>
                            <a
                                [routerLink]="['./../../edittimetable',timetable['Id']]">{{timetable.ProjectWorkTypeName}}</a>
                        </span>
                    </td>
                    <td>
                        <span>{{timetable.ProjectName}}</span>
                    </td>
                    <td>
                        <span>{{timetable.EmployeeName}}</span>
                    </td>
                    <td>
                        <span>{{timetable.Comments}}</span>
                    </td>
                    <td>
                        <span>{{timetable.StartDate |date : 'MM/dd/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{timetable.StartTime}}</span>
                    </td>
                    <td>
                        <span>{{timetable.EndDate |date : 'MM/dd/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{timetable.EndTime}}</span>
                    </td>
                    <td>
                        <span>{{timetable.HoursSpent}}</span>
                    </td>
                    <td>
                        <span>{{timetable.ApproverName}}</span>
                    </td>
                    <td>
                        <span>{{timetable.ApprovalDate |date : 'MM/dd/yyyy'}}</span>
                    </td>
                    <td>
                        <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                            (click)="deleteTimetable(timetable.Id)">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="12">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>