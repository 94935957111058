import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../../shared/custom-validator';
import { ElectroContractContent } from 'src/app/kuba/electro/models/electro-contract-content.models';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ElectroService } from 'src/app/kuba/electro/services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contract-content',
  templateUrl: './contract-content.component.html',
})
export class ContractContentComponent implements OnInit {
  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  addServiceForm: FormGroup;
  showDescDialog: boolean = false;
  contractContentList: ElectroContractContent[];
  contractContent: ElectroContractContent;
  additionalData: any;
  private subscriptions: Subscription[] = [];
  constructor(private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private electroServices: ElectroService,
    private _fb: FormBuilder, ) {
  }

  ngOnInit() {
    this.contractContentList = [];
    this.contractContentList = this.route.snapshot.data['content']
    if(this.contractContentList){
      this.contractContentList = this.contractContentList.filter(x => !(x.InspectionId));
    }
  }

  /**
   * initialize content form
   * @param electroContractContent 
   */
  initForm(electroContractContent?: ElectroContractContent): void {
    this.showDescDialog = true;
    let description = '';

    if (electroContractContent && electroContractContent.Id > 0) {
      description = electroContractContent.Name;
    }

    this.contractContent = new ElectroContractContent();

    if (electroContractContent) {
      this.contractContent = electroContractContent;
    }

    this.addServiceForm = this._fb.group({
      'description': [description, [Validators.required, CustomValidator.noWhitespaceValidator]],
    });
  }

  /**
   * delete content.
   * @param contractContentId 
   */
  deleteContent(contractContentId) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(this.electroServices.deleteContractContent(contractContentId).subscribe(result => {
          if (result) {
            //rebind content after content deletion.
            this.subscriptions.push(this.electroServices.getContractContent().subscribe(result => {
              this.contractContentList = result.filter(x => !(x.InspectionId));;
              this.addServiceForm.reset();
            }));
          }
          this.toasterComponent.callToastDlt();
        }));
      }
    });
  }

  /**
   * close description dialog
   */
  closeModal() {
    this.showDescDialog = false;    
  }

  /**
   * reset description from control
   */
  clearDescription() {
    this.addServiceForm.reset()
  }

  /**
   * save content description
   */
  saveDescription(addServiceForm:any) {
    let electroSaveContractContent = this.addServiceForm.value;
    this.contractContent.Name = electroSaveContractContent.description;
    this.contractContent.BusinessId = BaseServices.BusinessId;

    if (this.contractContent.Id > 0) {
      this.contractContent.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(this.electroServices.saveContractContent(this.contractContent).subscribe(result => {
        if (result) {
          this.showDescDialog = false; 
          this.subscriptions.push(this.electroServices.getContractContent().subscribe(result => {
            this.contractContentList = result.filter(x => !(x.InspectionId));
            this.addServiceForm.reset();
          }));
        }
        this.toasterComponent.callToast();
      }));
    }
    else {
      this.contractContent.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(this.electroServices.saveContractContent(this.contractContent).subscribe(result => {

        if (result) {
          this.showDescDialog = false; 
          this.subscriptions.push(this.electroServices.getContractContent().subscribe(result => {
            this.contractContentList = result.filter(x => !(x.InspectionId));
            this.addServiceForm.reset();
          }));
        }
        this.toasterComponent.callToast();
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
