export class FeatureDocumentModel {
    Id: number;
    Title: string;
    OriginalFileName: string;
    Path: string;
    MimeType: string;
    Size: number;
    DocumentTypeId: number;
    FeatureFolderId: number;
    FeatureId: number;
    FeatureKey: number;
    Status: string;
    CreatedBy: number;
    ModifiedBy: number;
    BusinessId: number;
    UserId:number;
}

export class FeatureChecklistModel {
    Id: number;
    FeatureId: number;
    FeatureKey: number;
    BusinessId: number;
    Title: string;
    Description : string;
    FeatureFolderId : any;
    IsScoreIndex: boolean;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    manualTitle: string;
    ApplicationId: number;
    PortalId: number;
}

export class ParentItem {
    parentItemId: number;
    parentId: number;
    parentItemPoint: number;
    parentItemTitle: string;
    parentItemStatus: number
    childItems: ChildItem[];
}

export class ChildItem {
    childItemId: number;
    parentId?: number;
    childItemPoint: number;
    isView: number;
    childItemTitle: string;
}

export class FeatureChecklistItemUpdate {
    title: string;
    score: boolean;
    userId: number;
    parentItems: ParentItem[];
    ModifiedBy ?: number;
}
export class ElectroChecklistModel {
    Id: number;
    FeatureId: number;
    FeatureKey: number;
    BusinessId: number;
    Title: string;   
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;  
    ApplicationId: number;
    PortalId: number;
}

export class ElectroChecklistItemUpdate {
    title: string;   
    userId: number;
    parentItems: ParentItem[];
    ModifiedBy ?: number;
}