import { BusinessPortalListResolver } from './../portals/services/business-portal.resolver.service';
import { BusinessListResolver } from 'src/app/kuba/businesses/services/business.resolver.service';
import { FeatureDocumentRoutes } from './../feature-documents/feature-document.routing';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { CommonViewComponent } from './../common/components/commonView.component';
import {
    ProjectAdminListResolver,
    PortalNameListResolver,
    BusinessNameListResolver,
    AdminProjectListResolver
} from './service/project-admin.resolver';
import { ProjectAdminComponent } from './components/project-admin.component';
import { ProjectAdminListComponent } from './components/project-admin-list.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { ProjectAdminMainComponent } from './project-admin-main.component';
import { Route } from '@angular/router';

export const projectAdminRoutes: Route[] = [
    {
        path: 'projectadmin',
        component: ProjectAdminMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: ProjectAdminListComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: ProjectAdminListResolver
                }
            },
            {
                path: 'newproject',
                component: ProjectAdminComponent,
                canActivate: [AuthGuard],
                resolve: {
                    portal: BusinessPortalListResolver,
                    business: BusinessListResolver
                }
            },
            {
                path: 'details/:id',
                component: CommonViewComponent,
                canActivate: [AuthGuard],
                children: [...FeatureDocumentRoutes]
            }
        ]
    }
];
