<div class="dropdown">
  <a href="javascript:void(0);" class="btn p-3 toggle-action-btn">
    <!-- toggle-action-btn -->
    <i class="icon ic-sm icon-send-file-o"></i>
    <span translate>EXPORT</span>
  </a>
  <div class="export-btns">
    <button *ngIf="!isPdfonly" type="button" class="dropdown-item" (click)="exportReport('XLSX')" translate>
      <i class="icon ic-sm icon-xls mr-2"></i>
      <span>EXCEL</span>
    </button>
    <button *ngIf="!isPdfonly && showFormat" type="button" class="dropdown-item" (click)="exportReport('DOCX')"
      translate>
      <i class="icon ic-sm icon-word mr-2"></i>
      <span>WORD</span>
    </button>
    <button *ngIf="showFormat" type="button" class="dropdown-item" (click)="exportReport('PDF')" translate>
      <i class="icon ic-sm icon-pdf mr-2"></i>
      <span>PDF</span>
    </button>
  </div>
</div>