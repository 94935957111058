import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ElectroService } from '../../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights, FeatureKey } from 'src/app/_models';
import { KubaExportService } from 'src/app/kuba/shared/kuba-exports/kuba-export.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'inspection-overview',
    templateUrl: 'inspection-overview.component.html'
})

export class InspectionOverviewComponent implements OnInit {

    @ViewChild('inspectionTable',{static: false}) inspectionTable: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    inspectionList: any;
    statuses: any;
    rowsPerPageOptions: number[];
    isGuestHideButton = true;
    contractId: number;
    showLoader: boolean;
    contractNumber: string;
    resperson: SelectItem[];
    openInspRows = [];
    additionalData = {
        fileName: this.translate.instant('INSPECTION_LIST'),
        header: this.translate.instant('INSPECTION_LIST'),
        businessId: BaseServices.BusinessId,
        cultureInfo: BaseServices.userCultureInfo(),
        columnNames: [
            { title: 'Inspection no ', dataKey: 'InspectionNumber' },
            { title: 'Title', dataKey: 'Title' },
            { title: 'Deviation', dataKey: 'Deviations' },
            { title: 'Status', datakey: 'InspectionStatustext' },
            { title: 'Execution UserName', datakey: 'ResponsibleUserName' },
            { title: 'Followup UserName', dataKey: 'FollowupUserName' },
            { title: 'Deadline', dataKey: 'Deadline' },
            { title: 'Done On', dataKey: 'DoneOn' }
        ]
    };
    isInstallerTab: boolean;
    isClientUserRestrict = false;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute,
        private translate: TranslateService,
        private confirmationService: ConfirmationService,
        private electroService: ElectroService,
        private kubaExportService: KubaExportService,
        public router: Router
    ) {
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }
        this.isInstallerTab = this.router.url.indexOf('installer-insp') > -1;
        this.isClientUserRestrict = this.isInstallerTab ? (BaseServices.FeatureId == FeatureKey.CLIENT || 
            BaseServices.FeatureId == FeatureKey.CLIENT_CONTACT) : false;
        this.contractId = this.route.snapshot.parent.parent.params['Id'];
    }

    ngOnInit() {
        this.inspectionList = this.route.snapshot.data['list'];
        this.subscriptions.push(this.translate.stream('SELECT_INSPECTION_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.BOOK_INSPECTION, value: 13 },
                { label: val.INSPECTION_PLANNED, value: 14 },
                { label: val.INSPECTION_STARTED, value: 15 },
                { label: val.COMPLETED, value: 16 },
            );
        }));
        let users = this.route.snapshot.data['user'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.resperson = [];
            this.resperson.push(
                { label: val.SELECT, value: null }
            );
            if (users) {
                users.forEach((followupPerson: any) => {
                    this.resperson.push({
                        label: followupPerson.Name,
                        value: followupPerson.Id
                    });
                });
            }
        }));
        this.bindStatusIcon(this.inspectionList);
        this.contractNumber = localStorage.getItem('contractNumber');
    }

    /**
     *
     * bind status icon by status
     * @param inspection {any}
     */
    bindStatusIcon(inspection: any[]) {
        this.inspectionList = [];
        if (!(this.isClientUserRestrict || BaseServices.roleId == '3' || HelperService.ApprovalPersonRightsCheck(
            JSON.parse(BaseServices.getUserRights()), Rights.SERVICE_LEADER_ELECTRO))) {
            inspection = inspection.filter(x => (x.CreatedBy === BaseServices.UserId ? true : x.InspectionExecutionUsers ?
                (x.InspectionExecutionUsers.indexOf(BaseServices.UserId) > -1) : false) || (x.InspectionFollowupUsers ?
                    (x.InspectionFollowupUsers.indexOf(BaseServices.UserId) > -1) : false));
        }
        if (inspection) {
            inspection.forEach((inspectionInfo: any) => {
                let daysDiff = 0;
                let statusIcon = '';
                let currentdate = new Date();
                let deadLine = new Date(inspectionInfo.Deadline);
                if (inspectionInfo.Deadline != null) {
                    daysDiff = HelperService.getDaysDiff(currentdate, deadLine);
                }
                if (inspectionInfo.Status === 16) {
                    statusIcon = 'icon ic-sm icon-clear-deadline';
                } else if (inspectionInfo.Deadline) {
                    if (daysDiff > 3) {
                        statusIcon = 'icon ic-sm icon-far-deadline';
                    } else if (daysDiff > 0 && daysDiff <= 3) {
                        statusIcon = 'icon ic-sm icon-near-deadline';
                    } else {
                        statusIcon = 'icon ic-sm icon-over-deadline';
                    }
                } else {
                    statusIcon = 'icon ic-sm icon-over-deadline';
                }
                inspectionInfo.StatusIcon = statusIcon;
                this.inspectionList.push(inspectionInfo);
                this.rowsPerPageOptions = [10, 20, 50, 100];
                if (this.inspectionList.length > 100) {
                    this.rowsPerPageOptions.push(this.inspectionList.length);
                }
            });
        }
    }

    /**
     * status filter change event.
     * @param e
     * @param statusDropdown
     */
    onStatusChange(e: any, statusDropdown: any) {
        if (e.value) {
            this.inspectionTable.filter(
                +statusDropdown.value,
                'Status',
                'equals'
            );
        } else {
            this.inspectionTable.reset();
        }
    }

    onResponsiblePersonChange(e: any, user: any) {
        if (user.selectedOption.value !== null) {
            this.inspectionTable.filter(
                user.selectedOption.label,
                'ResponsibleUserName',
                'contains'
            );
        } else {
            this.inspectionTable.reset();
        }
    }

    onFollowupPersonChange(e: any, user: any) {
        if (user.selectedOption.value !== null) {
            this.inspectionTable.filter(
                user.selectedOption.label,
                'FollowupUserName',
                'contains'
            );
        } else {
            this.inspectionTable.reset();
        }
    }
    /**
     * inspection delete confirmation event.
     * @param id
     */
    confirm(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.electroService.deleteInspection(id).subscribe(result => {
                    if (this.router.url.indexOf('self-insp') > -1) {
                        this.subscriptions.push(this.electroService.getClientInspectionsbyContract(this.contractId).subscribe(result => {
                            this.inspectionList = result;
                            this.bindStatusIcon(this.inspectionList);
                        }));
                        this.toasterComponent.callToastDlt();
                    } else {
                        this.subscriptions.push(this.electroService.getInstallerInspectionsbyContract(this.contractId).subscribe(result => {
                            this.inspectionList = result;
                            this.bindStatusIcon(this.inspectionList);
                        }));
                        this.toasterComponent.callToastDlt();
                    }
                }));
            }
        });
    }
    downloadPDF() {
        this.showLoader = true;
        let CultureInfo = sessionStorage.getItem('languageMode')
        let filename = this.contractNumber + "-" + this.translate.instant(this.additionalData.fileName);
        this.subscriptions.push(this.kubaExportService
            .generateExportFile(this.inspectionTable.value, this.additionalData.columnNames,
                'PDF', this.translate.instant(this.additionalData.header), 0, BaseServices.BusinessId, CultureInfo)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${filename}.${('PDF').toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert(this.translate.instant('EXPORT_NOT_DOWNLOAD'));
                }));
    }

    public setCollapse(i: number): boolean {
        if (this.openInspRows.indexOf(i) > -1)
            return false;
        return true;
    }

    collapseRow(col, InsId) {
        if (col) {
            this.openInspRows.splice(this.openInspRows.indexOf(InsId), 1);
        } else {
            this.openInspRows.push(InsId);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}