<div class="main-content ">
    <div class="main-heading">
        <span><strong translate>INTERNAL_CONTROL_OF</strong> - {{ pageTitle | capitalize}}</span>
    </div>
    <div class="card">
        <div class="card-body">
            <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="icId"></ecpl-tab-navigation>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>