import { TrashListResolver } from './services/trash.resolver.service';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import { TrashComponent } from './trash.component';
import { TrashListComponent } from './components/TrashList.component';

export const trashRoutes: Route[] = [
    {
        path: 'trash',
        component: TrashListComponent,
        resolve: {
            list: TrashListResolver
        },
    },
];