import { FdvDocComponent } from './components/fdv-doc.component';
import { FdvDocumentComponent } from './fdv-doc.component';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
export const fdvDocumentRoutes: Route[] = [
    {
        path: 'fdv-document',
        component: FdvDocumentComponent,
        children: [
            {
                path: 'list',
                component: FdvDocComponent,
            }
        ]
    }];
