<features translate *ngIf="featureBase" [isPortalExists]="isPortalExists" [featureBase]="featureBase"
    [supplierChecked]="supplierChecked"></features>

<div class="action-btns-wrapper">
    <button *ngIf="!(roleId === 1 && isPortalExists)" type="button" class="btn btn-primary"
        (click)="saveFeature()">{{'SAVE' | translate}}</button>
    <button *ngIf="(isAdmin || isPortal)" type="submit" class="btn btn-outline-primary" (click)="rModeCheckFeature();">
        <span translate>R</span>
    </button>
</div>
<toaster-component [toast]="toast"></toaster-component>