import { ProjectServices } from './../../projects/services/project.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'assign-project-view',
    templateUrl: 'assign-project-view.component.html'
})
export class AssignProjectViewComponent implements OnInit {
    pageTitle: string;
    additionalFeatures: any;
    fdvId: number;
    initialPage: any;
    projectObj: any;
    businessId: number;
    private subscription: Subscription;
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private projectServices: ProjectServices
    ) {
        this.fdvId = this.activatedRoute.snapshot.params['id'];
        this.projectObj = this.activatedRoute.snapshot.data['project'];
        this.businessId = this.projectObj.BusinessId;
        this.subscription = this.projectServices
            .getProjectFeatures(this.businessId)
            .subscribe((result: any) => {
                this.additionalFeatures = result;
            });

        this.pageTitle = this.projectObj.Title;
        this.route.navigate(
            [this.additionalFeatures.children[0].linkText, { Id: this.fdvId }],
            { relativeTo: this.activatedRoute }
        );
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
