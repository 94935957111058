import { UserService } from './../../users/services/user.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import * as _ from 'lodash';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { UserCredential } from 'src/app/kuba/users/models/user';
import { BaseServices } from './../../kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { SubContractor, ContractorContacts, User, ModuleList } from './../models/contractor';
import { SubContractorServices } from '../services/contractor.service';
import { Module, UserRole } from './../../follow-ups/models/deviation';
import { EmployeeServices } from './../../employees/services/employee.services';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from './../../../_services/helper.service';
import { FeatureKey } from './../../../_models/feature';
import { FeatureBase, Feature } from './../../features/models/features-base.model';
import { environment } from 'src/environments/environment';
import { FeaturesBaseServices } from 'src/app/kuba/features/services/features-base.services';
import { ProjectServices } from 'src/app/kuba/projects/services/project.service';
import { data } from 'jquery';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
enum Year { min = 1000, max = 2000 }
@Component({
    templateUrl: 'sub-contractor-new.component.html'
})
export class SubContractorNewComponent implements OnInit {
    //#region variables
    checked: any = null;
    approvedDate: any;
    avatarName: string;
    uploadStart: boolean;
    public selectedFiles: any;
    businessId: number;
    contractorImage: string;
    checkedContact: any = null;
    avatarNameContact: string;
    CountryCode: SelectItem[];
    uploadStartContact: boolean;
    contractorImageContact: string;
    approvedDateContact: any;
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    isShowHSE = true;
    contractorForm: FormGroup;
    contractorContactsForm: FormGroup;
    updatePasswordForm: FormGroup;
    userForm: FormGroup;
    subContract: ContractorContacts[];
    clientId: any;
    addCategory = false;
    userCreation = true;
    contact: SelectItem[];
    Mobile: SelectItem[];
    role: SelectItem[];
    contractorcontacts: any;
    contractorContact = new ContractorContacts();
    subContractorId: any;
    subContractorIds: any;
    userDataId: any;
    contractorContactId: any;
    contractorContactIds: any;
    subContractorContactId: number;
    subContractorData: any;
    contractorData: any;
    userData: any;
    userRightsData: any;
    contractorContactUserId: number;
    subContractorUserId: number;
    hidden = false;
    PasswordField: any;
    loading = false;
    addcontractorContact = false;
    allCategories = new FormArray([]);
    IsHiding = false;
    IsHide = false;
    IsShow = false;
    isContractorUser = true;
    isEdit = true;
    isNewUser = true;
    userCredentials = new UserCredential();
    showPasswordResetDialog = false;
    passwordValidation = false;
    rightsData: any;
    cachedUserName: string;
    hideSaveUserButton = true;
    isContractorContactUser = false;
    featureBase: FeatureBase;
    canViewUser: boolean;
    ContractorActive = false;
    ContractorContactActive = true;
    objBusineesDetails: any = {};
    moduleList: any;
    projectSubContractor: SubContractor[];
    private subscriptions: Subscription[] = [];

    public defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '32px',
        selectionTxtFontSize: '14px',
        minYear: <number>Year.min,
        maxYear: <number>Year.max,
        disableSince: this.getCurrentDate(),
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
    };
    // TODO: ? is user Id should be Dynamic?
    userId = 0;
    contractValue: any;
    isMobile: boolean;
    dialogDisplay: boolean=false;

    //#endregion
    // TODO: ! need to be dynamic
    //#region constructor
    /**
     * constructor
     * @param fb {fb}
     * @param confirmationService {confirmationService}
     * @param subContractorService {subContractorService}
     * @param route {route}
     * @param translate {TranslateService}
     */
    public dobCalendarDateOptions: IMyDpOptions = this.defaultDateOptions;
    constructor(
        private fb: FormBuilder,
        private confirmationService: ConfirmationService,
        private subContractorService: SubContractorServices,
        private businessServices: BusinessServices,
        private route: ActivatedRoute,
        private userService: UserService,
        private employeeService: EmployeeServices,
        private translate: TranslateService,
        private http: HttpClient,
        private router: Router,
        private projectService: ProjectServices

    ) {
        let myPage = this.route.snapshot.queryParams['myPage']
        if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
            this.businessId = this.route.snapshot.parent!.params['bid'];
        } else {
            this.businessId = BaseServices.BusinessId;
        }
        if (myPage) {
            this.isEdit = false;
        }
        this.contractorForm = this.fb.group({
            ContractorType: ['', [Validators.required]],
            Name: ['', [Validators.required, Validators.nullValidator]],
            Address: [''],
            Location: [''],
            Phonecode: [2],
            BusinessName: [''],
            OrganizationNumber: [''],
            Mobile: [''],
            Telephone: [''],
            // Fax: [],
            Email: ['', [Validators.required, ValidationService.emailValidator, Validators.nullValidator]],
            Comment: [''],
            Zip: [''],
            IsApprovalNotice: ['']
        });
        this.contractorContactsForm = this.fb.group({
            DateofBirth: [''],
            HSECardNumber: [''],
            ContactName: ['', [Validators.required, Validators.nullValidator]],
            ContactMobile: [''],
            Phonecode: [2],
            ContactEmailId: ['', [Validators.required, ValidationService.emailValidator, Validators.nullValidator]],
            IsSms: [''],
            IsEmail: [''],
            AssignProjectUser: [''],
            IsApprovalNotice: ['']
        });
        this.updatePasswordForm = this.fb.group({
            'passwordValidation': this.fb.group({
                'Password': ['', [Validators.required]],
                'ConfirmPassword': ['', [Validators.required]]
            }, { validator: ValidationService.matchPassword })
        })
    }
    //#endregion
    //#region page-event
    /**
     * page event
     */
    ngOnInit() {

        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.ContractorActive = true;
            if (BaseServices.FeatureId === FeatureKey.CONTRACTOR_CONTACT) {
                this.ContractorContactActive = false;
            }
        }

        let BusinessDetails = JSON.parse(localStorage.getItem("BusinessDetails")!);
        this.objBusineesDetails.Name = BusinessDetails.CompanyName
        this.objBusineesDetails.Address = BusinessDetails.Address
        this.objBusineesDetails.Zip = BusinessDetails.Zipcode
        this.objBusineesDetails.Phone = BusinessDetails.Telephone
        this.objBusineesDetails.Email = BusinessDetails.Email
        this.subscriptions.push(this.subContractorService.getProjectByUser(BaseServices.UserId, BaseServices.BusinessId)
            .subscribe(result => {
                if (result) {
                    let list = result;
                    this.moduleList = [];
                    list.forEach((element: any) => {
                        this.moduleList.push({
                            Number: element.Number,
                            Title: element.Title,
                            Status: (element.Status == "NEW" ? "OPEN" : element.Status),
                            Id: element.Id,
                            Modules: "Project"
                        });
                    });
                }
            }));
        this.userCreation = false;
        let cid = this.route.snapshot.params['cid'];
        this.subContractorId = cid ? +cid : 0;
        this.contractorUserExitence();

        if (this.subContractorId) {
            this.addcontractorContact = true;
            this.fetchData();
            let result = this.route.snapshot.data['contractor']
            if (result) {
                this.contractorImage = result.Avatar;
                this.subContractorData = {
                    ContractorType: result.ContractorTypeId,
                    Name: result.Name,
                    Address: result.Address,
                    BusinessName: result.BusinessName,
                    OrganizationNumber: result.OrganizationNumber,
                    Location: result.Location,
                    Phonecode: result.CountryCode ? result.CountryCode : 2,
                    Mobile: result.Mobile,
                    Telephone: result.Telephone,
                    Email: result.Email,
                    Comment: result.Comment,
                    Zip: result.Zip,
                    IsApprovalNotice: result.IsApprovalNotice,
                };
                
                (<FormGroup>this.contractorForm).setValue(this.subContractorData, { onlySelf: true });
                if (result.IsApprovalNotice) {
                    this.checked = true;
                    this.approvedDate = result.ApprovalDateTime;
                }
                this.contractorImage = this.avatarName = result.Avatar ? result.Avatar : null;
            }
        } else{
            this.subContractorData = {
                ContractorType: 0,
                Name: '',
                Address: '',
                BusinessName: '',
                OrganizationNumber: '',
                Location: '',
                Phonecode: 2,
                Mobile: '',
                Telephone: '',
                Email: '',
                Comment: '',
                Zip: '',
                IsApprovalNotice: '',
            };
            
            (<FormGroup>this.contractorForm).setValue(this.subContractorData, { onlySelf: true });
            this.contractorImage = this.avatarName = null;
        }

        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.role = [];
            this.role.push(
                { label: val.SELECT, value: null },
                { label: val.GUEST, value: 4 }
            );
        }));
        // this.role = [];
        // this.role.push({ label: 'Select', value: null });
        // this.role.push({ label: 'Guest', value: 4 });

        this.subscriptions.push(this.translate.stream('SUB_CONTRACTOR_TYPES').subscribe(val => {
            this.contact = [];
            this.contact.push({ label: val.SUB_CONTRACTOR_SELECT, value: null });
            this.contact.push({ label: val.SUB_CONTRACTOR_PERSON, value: 1 });
            this.contact.push({ label: val.SUB_CONTRACTOR_COMPANY, value: 2 });
        }));

        this.Mobile = [];
        let data = this.route.snapshot.data['countrycode'];
        if (data) {
            data.forEach((element: any) => {
                this.Mobile.push({ label: '+' + element.Phonecode, value: element.Id });
            });
        }
        this.initForm();

        if (+BaseServices.UserId === UserRole.USER || +BaseServices.UserId === UserRole.GUEST) {
            this.canViewUser = false;
        }
        else if (+BaseServices.UserId === UserRole.EDITOR) {
            this.canViewUser = true;
        }
    }
    //#endregion
    //#region methods
    //To go to Project Details Page 
    setCurrentModule(moduleId) {
        this.subscriptions.push(this.businessServices.getById(BaseServices.BusinessId).subscribe((result: any) => {
            if (result) {
                let featureBase = BaseServices.getUserFeatures();
                let template = <any>JSON.parse(featureBase.FeaturesTemplate);
                featureBase.FeaturesTemplate = JSON.stringify(template.filter((x: any) =>
                    (x.navigationRoles.indexOf('B') > -1 && x.IsFeature.indexOf('N') > -1)
                    || (x.navigationRoles.indexOf('B') > -1 && x.IsDefault === 1)));
                featureBase.Features = result.Features;
                featureBase.Type = 'business';
                featureBase.Details = result;
                let baseFeatures = FeaturesBaseServices.getFeatureDetails(featureBase).filter(x => x.id == 16);
                sessionStorage.setItem('workingModule', JSON.stringify(baseFeatures[0]));
                this.router.navigate(
                    [
                        './../../../project/details', moduleId
                    ],
                    { relativeTo: this.route }
                );
            }

        }));
    }

    getFeatureDetail(projectId: number) {
        this.subscriptions.push(this.businessServices.getById(BaseServices.BusinessId).subscribe((result) => {
            let feature = result.Features;
            let projectAdditionalFeature = _.find(JSON.parse(feature), { 'id': Module.PROJECT, 'access': true });
            this.router.navigate([`./../../../project/details/${projectId}/document/list`, { Id: projectId }],
                { relativeTo: this.route });
        }))
    }
    /** 
     * clearing the data in the contractor contact form
    */
    ContactClear() {
        this.contractorContactId = 0;
        this.contractorImage = null!;
        let contractorContactData = {
            ContactName: '',
            ContactMobile: '',
            Phonecode: 2,
            ContactEmailId: '',
            AssignProjectUser: '',
            IsSms: '',
            IsEmail: '',
            DateofBirth: '',
            HSECardNumber: '',
            IsApprovalNotice: ''
        };
        
        (<FormGroup>this.contractorContactsForm)
            .setValue(contractorContactData, { onlySelf: true });
    }

    /**
     * Edit the contractor contact
     * @param data
     */
    editContractorContact(data: any) {
        this.contractorContact = data;
        this.subscriptions.push(this.subContractorService.getContractorContactUser(data.Id).subscribe(result => {
            if(result == null){
                this.contractorContactUserId=null;
            }
            else {
                this.contractorContactUserId = result.Id;
                this.contractValue = result;
            }
            this.contractorContactId = data.Id;
            this.contractorContactUserExitence(data.IsUser);
            if (data) {
                let contractorContactData = {
                    ContactName: data.Name,
                    ContactMobile: data.Mobile,
                    Phonecode: data.CountryCode ? data.CountryCode : 2,
                    ContactEmailId: data.Email,
                    IsSms: data.IsSms,
                    IsEmail: data.IsEmail,
                    AssignProjectUser: !!data.AssignProjectUser,
                    DateofBirth: data.DateofBirth
                        ? HelperService.formatInputDate(
                            data.DateofBirth
                        )
                        : null,
                    HSECardNumber: data.HSECardNumber,
                    IsApprovalNotice: data.IsApprovalNotice,
                };
                
                (<FormGroup>this.contractorContactsForm).setValue(contractorContactData, { onlySelf: true });
                if (data.IsApprovalNotice) {
                    this.checkedContact = true;
                    this.approvedDateContact = data.ApprovalDateTime;
                }
                this.contractorImageContact = this.avatarNameContact = data.Avatar ? data.Avatar : null;
            }
        }));

    }
    /**
     * Add/Update the Contractor
     */
    saveContractor() {
        this.loading = true;
        this.addcontractorContact = true;
        let subContractor = new SubContractor();
        subContractor.Id = this.subContractorId ? this.subContractorId : 0;
        subContractor.ContractorTypeId = this.contractorForm.value.ContractorType;
        subContractor.Name = this.contractorForm.value.Name;
        subContractor.Address = this.contractorForm.value.Address;
        subContractor.Zip = this.contractorForm.value.Zip;
        subContractor.Location = this.contractorForm.value.Location;
        subContractor.Mobile = this.contractorForm.value.Mobile;
        subContractor.Telephone = this.contractorForm.value.Telephone;
        subContractor.CountryCode = this.contractorForm.value.Phonecode;
        subContractor.Email = this.contractorForm.value.Email;
        subContractor.Comment = this.contractorForm.value.Comment;
        subContractor.Status = '1';
        subContractor.BusinessId = BaseServices.BusinessId;
        subContractor.AppId = BaseServices.ApplicationId;
        subContractor.Avatar = this.contractorImage;
        subContractor.IsApprovalNotice = this.contractorForm.value.IsApprovalNotice;
        subContractor.ApprovalDateTime = this.approvedDate;
        if (this.subContractorId > 0) {
            subContractor.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.subContractorService.update(this.subContractorId, subContractor).subscribe(() => {
                this.toasterComponent.callToast();
                this.loading = false;
            }));
        } else {
            subContractor.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.subContractorService.add(subContractor).subscribe((result: any) => {
                this.subContractorId = result ? result.Id : 0;
                this.toasterComponent.callToast();
                this.loading = false;
            }));
        }
    }
    /**
     * Add/Update the contractor contact
     */
    saveContractorContact() {
        this.contractorContact.Id = this.contractorContactId ? this.contractorContactId : 0;
        this.contractorContact.ContractorId = this.subContractorId;
        this.contractorContact.Email = this.contractorContactsForm.value.ContactEmailId;
        this.contractorContact.Name = this.contractorContactsForm.value.ContactName;
        if (this.contractorContactsForm.value.DateofBirth) {
            if (this.contractorContactsForm.value.DateofBirth.formatted !== undefined) {
                this.contractorContact.DateofBirth = HelperService.formatDateFilter(
                    this.contractorContactsForm.value.DateofBirth
                        ? this.contractorContactsForm.value.DateofBirth.formatted
                        : null);
            }
        } else {
            this.contractorContact.DateofBirth = null!;
        }
        this.contractorContact.HSECardNumber = this.contractorContactsForm.value.HSECardNumber;
        this.contractorContact.CountryCode = this.contractorContactsForm.value.Phonecode;
        this.contractorContact.Mobile = this.contractorContactsForm.value.ContactMobile;
        this.contractorContact.IsEmail = this.contractorContactsForm.value.IsEmail;
        this.contractorContact.IsSms = this.contractorContactsForm.value.IsSms;
        this.contractorContact.AssignProjectUser = this.contractorContactsForm.value.AssignProjectUser;
        this.contractorContact.Status = '1';
        this.contractorContact.Avatar = this.contractorImageContact;
        this.contractorContact.IsApprovalNotice = this.contractorContactsForm.value.IsApprovalNotice;
        this.contractorContact.ApprovalDateTime = this.approvedDateContact;
        if (this.contractorContactId > 0) {
            this.contractorContact.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.subContractorService.updateContractorContact(this.contractorContact).subscribe(() => {
                this.fetchData();
                this.ContactClear();
            }));
            this.toasterComponent.callToast();
        } else {
            this.contractorContact.IsUser = false;
            this.contractorContact.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.subContractorService.addContractorContact(this.contractorContact).subscribe(() => {
                this.fetchData();
                this.ContactClear();
            }));
            this.toasterComponent.callToast();
        }
        this.clear();
        this.clearuser();
    }
    /**
     * delete the client contact
     * @param subContract {any}
     */
    // delete the contractor
    confirm(subContract: any) {
        this.confirmationService.confirm({
            message: this.translate.instant("ARE_SURE_TO_PERFORM_ACTION"),
            accept: () => {
                this.subscriptions.push(this.subContractorService.deleteContractorContact(subContract).subscribe(() => {
                    this.fetchData();
                }));
                this.toasterComponent.callToastDlt();
                this.clear();
            }
        });
    }
    /**
        * Fetch the Data
        */
    fetchData() {
        this.subscriptions.push(this.subContractorService
            .listContractorContact()
            .subscribe(
                (result: any) => (this.contractorcontacts = result.filter((x: any) => x.ContractorId === this.subContractorId))
            ));
    }
    /**
     * mobilenumber keypress event restrict number greater than 20 and restrict text
     */
    onMobileNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        if (e.target.value.length === limit) {
            e.preventDefault();
        }
        if (e.which < 48 || e.which > 57) {
            e.preventDefault();
        }
    }
    userClear() {
        this.showPasswordResetDialog = false;
        this.updatePasswordForm.reset();
    }
    showResetPassword() {
        this.showPasswordResetDialog = true;
    }
    // Edit the user details
    saveProceed() {
        this.saveContractor();
        this.isContractorUser = true;
        this.subscriptions.push(this.subContractorService.getContractorUser(this.subContractorId).subscribe(result => {
            this.subContractorUserId = result ? result.Id : 0;
            if (this.subContractorUserId > 0) {
                this.subscriptions.push(this.subContractorService.getUserById(this.subContractorUserId).subscribe(data => {
                    this.initForm(data);
                    this.addCategory = true;
                    this.isNewUser = false;
                }));
            } else {
                let userData = {
                    Id: this.subContractorUserId ? this.subContractorUserId : 0,
                    Name: this.contractorForm.value.Name,
                    Email: this.contractorForm.value.Email,
                    Username: this.contractorForm.value.Email,
                    Phonecode: this.contractorForm.value.Phonecode,
                    Mobile: this.contractorForm.value.Mobile
                };
                this.initForm(userData);
                this.addCategory = true;
            }
        }));
    }

    refreshUser() {
        if (this.cachedUserName) {
            this.userForm.get('Username')!.patchValue(this.cachedUserName);
        } else {
            this.userForm
                .get('Username')!
                .patchValue(this.contractorForm.value.Email);
        }
    }

    /**
     * Initialize the form
     * @param data {any}
     */
    initForm(data?: any) {
        let Id: number;
        let Name = '';
        let Email = '';
        let Username = '';
        let Password = '';
        let RepeatPassword = '';
        // let UserInfoSms = '';
        let RoleId = '';
        let Status = '';
        let Phonecode = '';
        let Mobile = '';
        if (data) {
            this.cachedUserName = data.Username;
            Id = data.Id ? data.Id : '0';
            Name = data.Name ? data.Name : '';
            Email = data.Email ? data.Email : '';
            Username = data.Username ? data.Username : '';
            Password = data.Password ? data.Password : '';
            RepeatPassword = data.Password ? data.Password : '';
            // UserInfoSms = data.UserInfoSms ? data.UserInfoSms : '';
            RoleId = data.RoleId ? data.RoleId : '';
            Status = data.Status ? data.Status : '1';
            Phonecode = data.Phonecode ? data.Phonecode : '';
            Mobile = data.Mobile ? data.Mobile : '';
            this.rightsData = data.Rights
                ? JSON.parse(data.Rights)
                : this.userRightsData;
        }
        let allCategories: FormArray | any = new FormArray([]);

        this.userForm = new FormGroup({
            Name: new FormControl(Name, [
                Validators.required,
                ValidationService.noWhitespaceValidator
            ]),
            Email: new FormControl(Email, [
                Validators.required,
                Validators.email
            ]),
            Username: new FormControl(Username, [
                Validators.required,
                ValidationService.noWhitespaceValidator
            ], this.isUserNameUnique.bind(this)),
            Password: new FormControl(Password, [
                Validators.required,
                ValidationService.noWhitespaceValidator
            ]),
            RepeatPassword: new FormControl(RepeatPassword, [
                Validators.required,
                ValidationService.noWhitespaceValidator
            ]),
            Id: new FormControl(Id!),
            // UserInfoSms: new FormControl(UserInfoSms),
            RoleId: new FormControl(RoleId, [Validators.required]),
            Status: new FormControl(Status),
            Phonecode: new FormControl(Phonecode),
            Mobile: new FormControl(Mobile),
            categories: allCategories
        });
        if(data){
            this.bindRightsCheckbox(data);
        }

    }

    bindRightsCheckbox(data?: any) {
        this.userRightsData = [
            {
                'id': '1',
                'name': 'ACTIVE_ACCOUNT',
                'isChecked': true,
                'rightsType': 1
            },
            {
                'id': '20',
                'name': 'DEVIATION_RIGHTS',
                'isChecked': false,
                'rightsType': 1
            },
            {
                'id': '25',
                'name': 'READING_SJA_RIGHTS',
                'isChecked': false,
                'rightsType': 1
            },
        ];
        const userControl = <FormArray>this.userForm.controls['categories'];
        for (let i = 0; i < this.userRightsData.length; i++) {
            let fg = new FormGroup({});
            fg.addControl(
                `${this.userRightsData[i].id}`,
                new FormControl(
                    data
                        ? this.arrayCompare(data.Rights, this.userRightsData[i].id)
                        : this.userRightsData[i].isChecked
                )
            );
            userControl.push(fg);
        }
    }

    /**
     * To array compare
     * @param list {any}
     * @param key {number}
     */
    arrayCompare(list: any, key: number) {
        let isMatched = false;
        let dataList = JSON.parse(list || null);
        if (dataList && dataList.length > 0) {
            for (let index = 0; index < dataList.length; index++) {
                let element = dataList[index];
                if (element.id === key) {

                    return element.isChecked;
                }
            }
        }
        return isMatched;
    }
    /**
     * Generate the random password
     */
    generateRandomPassword() {
        let randomstring = Math.random()
            .toString(36)
            .slice(-8);
        this.userForm.get('Password')!.patchValue(randomstring);
        this.userForm.get('RepeatPassword')!.patchValue(randomstring);
        this.PasswordField = randomstring;
        this.hidden = true;
    }

    // save the user Contractor contact
    saveUserContact() {
        this.IsHiding = false;
        this.isContractorUser = false;
        if (this.contractorContactUserId > 0 && this.contractorContactUserId != null) {
            this.subscriptions.push(this.subContractorService.getUserById(this.contractorContactUserId).subscribe(data => {
                this.initForm(data);
                this.isNewUser = false;
            }));
        } else {
            let userData = {
                Id: this.contractorContactUserId ? this.contractorContactUserId : 0,
                Name: this.contractorContactsForm.value.ContactName,
                Email: this.contractorContactsForm.value.ContactEmailId,
                Username: this.contractorContactsForm.value.ContactEmailId,
                Phonecode: this.contractorContactsForm.value.Phonecode,
                Mobile: this.contractorContactsForm.value.ContactMobile
            };
            this.initForm(userData);
            this.isNewUser = true;  
        }
        if (this.contractorContactsForm.value.ContactMobile == "" || this.contractorContactsForm.value.ContactMobile == null) {
            this.isMobile = true;
        }
        else {
            this.isMobile = false;
        }
        this.addCategory = true;
    }

    hideUserName(data?: any) {
        let Id: number;
        let Name = '';
        let Email = '';
        let Username = '';
        let Password = '';
        let RepeatPassword = '';
        // let UserInfoSms = '';
        let RoleId = '';
        let Status = '';
        let Phonecode = '';
        let Mobile = '';
        if (data) {
            Id = data.Id ? data.Id : '0';
            Name = data.Name ? data.Name : '';
            Email = data.Email ? data.Email : '';
            Username = '';
            Password = data.Password ? data.Password : '';
            RepeatPassword = data.Password ? data.Password : '';
            // UserInfoSms = data.UserInfoSms ? data.UserInfoSms : '';
            RoleId = data.RoleId ? data.RoleId : '';
            Status = data.Status ? data.Status : '1';
            Phonecode = data.Phonecode ? data.Phonecode : '';
            Mobile = data.Mobile ? data.Mobile : '';
        }
        let allCategories: FormArray | any = new FormArray([]);
        for (let i = 0; i < this.rightsData.length; i++) {
            let fg = new FormGroup({});
            fg.addControl(this.rightsData[i].name, new FormControl(data ? data.Rights : this.rightsData[i].isChecked));
            allCategories.push(fg);
        }

        this.userForm = new FormGroup({
            Id: new FormControl(Id!),
            Name: new FormControl(Name),
            Email: new FormControl(Email),
            Username: new FormControl(''),
            Password: new FormControl(Password),
            RepeatPassword: new FormControl(RepeatPassword),
            RoleId: new FormControl(RoleId),
            Status: new FormControl(Status),
            Phonecode: new FormControl(Phonecode),
            Mobile: new FormControl(Mobile),
            categories: allCategories
        });
    }

    changeName() {
        this.userForm.get('Username')!.patchValue('');
    }

    onRightsCheckboxChange(e: any, rightsId: any) {
        if (this.rightsData) {
            _.each(this.rightsData, (x: any) => {
                if (x.id === rightsId) {
                    x.isChecked = e.target.checked;
                }
            });
        }
    }
    /**
     * save the user details
     */
    saveUser() {
        let userdetails = new User();
        userdetails.Id = this.userForm.value.Id;
        userdetails.FeatureId = this.isContractorUser ? Module.CONTRACTOR : Module.CONTRACTOR_CONTACT;
        userdetails.FeatureKey = this.isContractorUser ? this.subContractorId : this.contractorContactId;
        userdetails.Email = this.userForm.value.Email;
        userdetails.Name = this.userForm.value.Name;
        userdetails.Mobile = this.userForm.value.Mobile;
        userdetails.Password = this.userForm.value.Password;
        userdetails.RoleId = (this.userForm.value.RoleId === '') ? 4 : this.userForm.value.RoleId;
        userdetails.ApplicationId = BaseServices.ApplicationId;
        userdetails.BusinessId = BaseServices.BusinessId;
        userdetails.Status = '1';
        userdetails.Username = this.userForm.value.Username;
        // userdetails.UserInfoSms = this.userForm.value.UserInfoSms;
        if (window.name === 'Remote' && this.userForm.value.Id === '0') {
            userdetails.LanguageId = 2; // default language Norsk
        } else {
            userdetails.LanguageId = BaseServices.userLanguageId;
        }
        userdetails.CreatedBy = BaseServices.UserId;
        userdetails.Rights = `${JSON.stringify(this.rightsData)}`;

        if (this.userForm.value.Id > 0) {
            this.subscriptions.push(this.subContractorService.updateUser(userdetails).subscribe(isUpdated => {
                this.fetchData();
                if (isUpdated) {
                    this.toasterComponent.callToast();
                }
            }))
        } else {
            if(this.contractorContact.Id > 0){
                this.subscriptions.push(this.subContractorService.addUser(userdetails).subscribe((userData:any) => {
                    this.userDataId=userData.Id
                    if (this.userDataId!= null || this.userDataId!= 0) {
                        this.contractorContact.IsUser = true;
                        this.subscriptions.push(this.subContractorService.updateContractorContact(this.contractorContact).subscribe(res2=>{
                            this.fetchData();
                            this.ContactClear();
                        }));   
                    }
                }));
                
            }
            else if (this.contractorContactsForm.valid) {
                this.contractorContact.Id = this.contractorContactId ? this.contractorContactId : 0;
                this.contractorContact.ContractorId = this.subContractorId;
                this.contractorContact.Email = this.contractorContactsForm.value.ContactEmailId;
                this.contractorContact.Name = this.contractorContactsForm.value.ContactName;
                if (this.contractorContactsForm.value.DateofBirth) {
                    if (this.contractorContactsForm.value.DateofBirth.formatted !== undefined) {
                        this.contractorContact.DateofBirth = HelperService.formatDateFilter(
                            this.contractorContactsForm.value.DateofBirth
                                ? this.contractorContactsForm.value.DateofBirth.formatted
                                : null);
                    }
                } else {
                    this.contractorContact.DateofBirth = null;
                }
                this.contractorContact.HSECardNumber = this.contractorContactsForm.value.HSECardNumber;
                this.contractorContact.CountryCode = this.contractorContactsForm.value.Phonecode;
                this.contractorContact.Mobile = this.contractorContactsForm.value.ContactMobile;
                this.contractorContact.IsEmail = this.contractorContactsForm.value.IsEmail;
                this.contractorContact.IsSms = this.contractorContactsForm.value.IsSms;
                this.contractorContact.AssignProjectUser = this.contractorContactsForm.value.AssignProjectUser;
                this.contractorContact.Status = '1';
                this.contractorContact.Avatar = this.contractorImageContact;
                this.contractorContact.IsApprovalNotice = this.contractorContactsForm.value.IsApprovalNotice;
                this.contractorContact.ApprovalDateTime = this.approvedDateContact;
                if (this.contractorContactId > 0) {
                    this.contractorContact.ModifiedBy = BaseServices.UserId;
                    this.subscriptions.push(this.subContractorService.updateContractorContact(this.contractorContact).subscribe(() => {
                        this.fetchData();
                        this.ContactClear();
                    }));
                    this.toasterComponent.callToast();
                } else {
                    this.contractorContact.CreatedBy = BaseServices.UserId;
                    this.subscriptions.push(this.subContractorService.addContractorContact(this.contractorContact).subscribe((contractData:any) => {
                        userdetails.FeatureKey = contractData.Id;
                        this.contractorContactIds = contractData.Id; 
                        this.subscriptions.push(this.subContractorService.addUser(userdetails).subscribe((userData:any) => {
                            this.userDataId=userData.Id
                            if (this.userDataId!= null || this.userDataId!= 0) {
                                this.contractorContact.IsUser = true;
                                this.contractorContact.Id =  this.contractorContactIds;
                                this.subscriptions.push(this.subContractorService.updateContractorContact(this.contractorContact).subscribe(res2=>{
                                    this.fetchData();
                                }));    
                            }   
                        }));
                        
                        this.ContactClear();
                    }));

                    this.toasterComponent.callToast();
                }
            };
        }
        this.addCategory = false;
        if (this.userForm.value.Id > 0) {
            this.toasterComponent.callToast();
        }
        this.clear();
        this.ngOnInit();
    }

    showDialog() {
        this.dialogDisplay = true;
    }

    resetPassWord() {
        this.userCredentials.Id = this.userForm.value.Id;
        this.userCredentials.Password = this.updatePasswordForm.value.passwordValidation.Password;
        this.userCredentials.Password = this.updatePasswordForm.value.passwordValidation.ConfirmPassword;
        this.subscriptions.push(this.businessServices.updatePassword(this.userCredentials).subscribe(result => {
            if (result === false) {
                this.passwordValidation = true;
            } else {
                this.toasterComponent.callToast();
            }
        }))
        this.showPasswordResetDialog = false;
    }
    /**
   * user name validation
   */
    isUserNameUnique(control: FormControl) {
        const q = new Promise(resolve => {
            setTimeout(() => {
                if (control.value === this.cachedUserName) {
                    resolve(null);
                } else {
                    this.subscriptions.push(this.employeeService.getUserName(control.value).subscribe(
                        x => {
                            if (!x) {
                                resolve(null);
                            } else {
                                resolve({ invalidUserNameWithId: true });
                            }
                        },
                        () => {
                            resolve({ invalidUserNameWithId: true });
                        }
                    ));
                }
            }, 1000);
        });
        return q;
    }

    contractorUserExitence() {
        this.subscriptions.push(this.userService.getUserExits(Module.CONTRACTOR, this.subContractorId).subscribe((res: any) => {
            if (res) {
                this.hideSaveUserButton = false;
            }
        }));
    }
    contractorContactUserExitence(isuser) {
        this.subscriptions.push(this.userService.getUserExits(Module.CONTRACTOR_CONTACT, this.contractorContactId).subscribe((res: any) => {
            if (isuser == true) {
                this.userCreation = true;
            } else {
                this.userCreation = false;
            }
        }));
    }
    saveAndSendSMS() {
        let userdetails = new User();
        userdetails.Id = this.userForm.value.Id;
        userdetails.FeatureId = this.isContractorUser ? Module.CONTRACTOR : Module.CONTRACTOR_CONTACT;
        userdetails.FeatureKey = this.isContractorUser ? this.subContractorId : this.contractorContactId;
        userdetails.Email = this.userForm.value.Email;
        userdetails.Name = this.userForm.value.Name;
        userdetails.Password = this.userForm.value.Password;
        userdetails.RoleId = (this.userForm.value.RoleId === '') ? 4 : this.userForm.value.RoleId;
        userdetails.ApplicationId = BaseServices.ApplicationId;
        userdetails.BusinessId = BaseServices.BusinessId;
        userdetails.Status = '1';
        userdetails.Username = this.userForm.value.Username;
        userdetails.CountryCode = this.userForm.value.Phonecode;

        userdetails.Mobile = this.userForm.value.Mobile
        // userdetails.UserInfoSms = this.userForm.value.UserInfoSms;
        userdetails.Rights = `${JSON.stringify(this.rightsData)}`;
        if (this.userForm.value.Id > 0) {
            this.subscriptions.push(this.subContractorService.updateUser(userdetails).subscribe(isUpdated => {
                this.fetchData();
                if (isUpdated) {
                    this.toasterComponent.callToast();
                }
            }))
        } else {
            // userdetails.CreatedBy = BaseServices.UserId;
            if (this.contractorContact.Id > 0) {
                this.subscriptions.push(this.businessServices.addUsersAndSendSMS(userdetails).subscribe((userData: any) => {
                    this.userDataId = userData.Id
                    if (this.userDataId != null || this.userDataId != 0) {
                        this.contractorContact.IsUser = true;
                        this.subContractorService.updateContractorContact(this.contractorContact).subscribe(res2 => {
                            this.fetchData();
                            this.ContactClear();
                        });
                    }
                }));

            }
            else if (this.contractorContactsForm.valid) {
                this.contractorContact.Id = this.contractorContactId ? this.contractorContactId : 0;
                this.contractorContact.ContractorId = this.subContractorId;
                this.contractorContact.Email = this.contractorContactsForm.value.ContactEmailId;
                this.contractorContact.Name = this.contractorContactsForm.value.ContactName;
                if (this.contractorContactsForm.value.DateofBirth) {
                    if (this.contractorContactsForm.value.DateofBirth.formatted !== undefined) {
                        this.contractorContact.DateofBirth = HelperService.formatDateFilter(
                            this.contractorContactsForm.value.DateofBirth
                                ? this.contractorContactsForm.value.DateofBirth.formatted
                                : null);
                    }
                } else {
                    this.contractorContact.DateofBirth = null;
                }
                this.contractorContact.HSECardNumber = this.contractorContactsForm.value.HSECardNumber;
                this.contractorContact.CountryCode = this.contractorContactsForm.value.Phonecode;
                this.contractorContact.Mobile = this.contractorContactsForm.value.ContactMobile;
                this.contractorContact.IsEmail = this.contractorContactsForm.value.IsEmail;
                this.contractorContact.IsSms = this.contractorContactsForm.value.IsSms;
                this.contractorContact.AssignProjectUser = this.contractorContactsForm.value.AssignProjectUser;
                this.contractorContact.Status = '1';
                this.contractorContact.Avatar = this.contractorImageContact;
                this.contractorContact.IsApprovalNotice = this.contractorContactsForm.value.IsApprovalNotice;
                this.contractorContact.ApprovalDateTime = this.approvedDateContact;
                if (this.contractorContactId > 0) {
                    this.contractorContact.ModifiedBy = BaseServices.UserId;
                    this.subContractorService.updateContractorContact(this.contractorContact).subscribe(() => {
                        this.fetchData();
                        this.ContactClear();
                    });
                    this.toasterComponent.callToast();
                } else {
                    this.contractorContact.CreatedBy = BaseServices.UserId;
                    this.subContractorService.addContractorContact(this.contractorContact).subscribe((contractData: any) => {
                        userdetails.FeatureKey = contractData.Id;
                        this.contractorContactIds = contractData.Id;
                        this.businessServices.addUsersAndSendSMS(userdetails).subscribe((userData: any) => {
                            this.userDataId = userData.Id
                            if (this.userDataId != null || this.userDataId != 0) {
                                this.contractorContact.IsUser = true;
                                this.contractorContact.Id = this.contractorContactIds;
                                this.subContractorService.updateContractorContact(this.contractorContact).subscribe(res2 => {
                                    this.fetchData();
                                });
                            }
                        });

                        this.ContactClear();
                    });
                    this.toasterComponent.callToast();

                }
            }
        }
        // userdetails.CreatedBy = BaseServices.UserId;
        // this.businessServices.addUsersAndSendSMS(userdetails).subscribe(subresult => {
        //     if (!this.isContractorUser) {
        //         this.contractorContact.IsUser = true;
        //         this.subContractorService.updateContractorContact(this.contractorContact).subscribe();
        //         this.toasterComponent.callToast();
        //     }
        // });
        // }
        this.addCategory = false;
        if (this.userForm.value.Id > 0) {
            this.toasterComponent.callToast();
        }
        this.clear();
        this.ngOnInit();
        this.addCategory = false;

    }
    //#endregion

    bindCountryCode() {
        this.CountryCode = [];
        let countryCodes = this.route.snapshot.data['countrycodedropdown'];
        if (countryCodes) {
            countryCodes.forEach((countryCode: any) => {
                this.CountryCode.push({ label: '+' + countryCode.Phonecode, value: countryCode.Id });
            });
        }
    }
    /**

   /**
   * get current date
   */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() + 1
        };
    }
    /**
    * mobile number validation
    * @param e {any}
    * @param limitNumber {any}
    */
    onNumberChange(e: any, limitNumber: any) {
        HelperService.numberFieldValidation(e, limitNumber);
    }

    MobileEvent(event) {
        var mobileNum = document.getElementById('mobilenum')as HTMLButtonElement;
        if(mobileNum.value.length >= 7){
                
            this.isMobile = false;
        }else{
                
            this.isMobile = true;    
        }
    }
    getCrewlistFeatureDetails() {
        this.featureBase = BaseServices.getUserFeatures();
        let objCreawlist = JSON.parse(this.featureBase.FeaturesTemplate).filter((e: any) => e.id = FeatureKey.PROJECT)[0]
            .children.filter((x: any) => x.id = FeatureKey.CREW_LIST);
        if (objCreawlist !== null) {
            this.isShowHSE = objCreawlist.checked === false ? false : true;
        }
    }

    filesSelect(selectedFiles: any): void {
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/contractor/file/upload/employee-image/' +
                BaseServices.ApplicationId +
                '/' +
                this.businessId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response;
                this.contractorImage = this.avatarName = res.Result.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }
    filesSelectContact(selectedFiles: any): void {
        this.uploadStartContact = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/contractorContact/file/upload/employee-image/' +
                BaseServices.ApplicationId +
                '/' +
                this.businessId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStartContact = false;
                let res = response;
                this.contractorImageContact = this.avatarNameContact = res.Result.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    clear() {
        this.contractorContactId = '0';
        this.contractorContactsForm.reset();
    }

    clearuser() {
        this.contractorContactUserId = 0;
        this.initForm();
        this.userData = '';
    }

    generateRandomPasswordReset() {
        let randomstring = Math.random()
            .toString(36)
            .slice(-8);
        this.updatePasswordForm.patchValue({ passwordValidation: { Password: randomstring, ConfirmPassword: randomstring } });
        this.PasswordField = randomstring;
        this.hidden = true;

    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }

}
