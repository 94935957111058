import { Component, OnInit, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessEquipment, BusinessEquipmentType } from '../models/index';
import { BusinessEquipmentService } from './../services/businessEquipment.service';
import { BaseServices } from '../../kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'business-equipments.component.html'
})
export class BusinessEquipmentsComponent implements OnInit {
    //#region variable
    // access the primeng datatable
    @ViewChild('equipmentTable',{static:false}) equipmentTable: Table;
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    @ViewChild('typeofEquipType',{static:false}) typeofEquipType: Table;
    @ViewChild('typeofEquip',{static:false}) typeofEquip: Table;
    public bequipobj: BusinessEquipment[];
    isDisable = true;
    public equipmentForm: FormGroup;
    addType = false;
    addEquip = false;
    types: SelectItem[];
    equipments: SelectItem[];
    editTypes: any = [];
    editEquipments: any = [];
    selectedEquipType = new BusinessEquipmentType();
    selectedEquipment = new BusinessEquipmentType();
    equipmentParentId: number;
    childId: number;
    businessId: number;
    addEquipmentForm: FormGroup;
    equipmentTypeForm: FormGroup;
    editEquipmentTypeId: number;
    equipmentId: number;
    existsAlerttext: string;
    isNameExists: boolean;
    viewType = false;
    viewPosition = false;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * constructor
     * @param _fb {FormBuilder}
     * @param businessEquipmentService {BusinessEquipmentService}
     * @param confirmationService {ConfirmationService}
     * @param activatedRoute {ActivatedRoute}
     * @param router {Router}
     */
    constructor(
        private _fb: FormBuilder,
        public businessEquipmentService: BusinessEquipmentService,
        private confirmationService: ConfirmationService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService

    ) {
        this.equipmentForm = this._fb.group({
            'EquipmentParent': ['', Validators.required],
            'EquipmentType': ['', Validators.required]
        });
        this.addEquipmentForm = this._fb.group({
            'Name': ['', [Validators.required, ValidationService.noWhitespaceValidator]],
        });
        this.equipmentTypeForm = this._fb.group({
            'Name': ['', [Validators.required, ValidationService.noWhitespaceValidator]],
        });
    }
    //#endregion

    //#region page-event
    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.activatedRoute.parent.params.subscribe((params: Params) => {
            let bid = params['bid'];
            this.businessId = +bid;
            this.businessId = bid ? +bid : BaseServices.BusinessId;
        }));
        this.bequipobj = <BusinessEquipment[]>this.activatedRoute.snapshot.data['equipment'];

        this.bindEquipmentType();
    }
    //#endregion

    //#region methods
    /**
     * bind equipment list
     */
    bindEquipmentList() {
        this.bequipobj = [];
        this.subscriptions.push(this.businessEquipmentService.list(this.businessId).subscribe((res:any) => {
            this.bequipobj = res;
        }))
    }
    /**
     * bing equipment type dropdown and table
     * @param businessId {number}
     */
    bindEquipmentType() {
        this.subscriptions.push(this.businessEquipmentService.getAllEquipType(this.businessId).subscribe((equipmentTypes:any) => {
                this.types = [];
                this.types.push(
                    { label: 'Select', value: null }
                );

            if (equipmentTypes) {
                equipmentTypes.forEach((equipmentType: any) => {
                    this.types.push({ label: equipmentType.Name, value: equipmentType.Id });
                });
                this.editTypes = equipmentTypes;
            }
        }));
    }

    /**
     * bind equipmenttype child dropdown
     * @param EquipmentParentId {number}
     */
    bindEquipment(EquipmentParentId: number) {
        this.subscriptions.push(this.businessEquipmentService.getAllByEquip(EquipmentParentId).subscribe((equipmentResult:any) => {
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.equipments = [];
                this.equipments.push(
                    { label: val.SELECT, value: null }
                );
            }));
            if (equipmentResult) {
                equipmentResult.forEach((equipment: any) => {
                    this.equipments.push({ label: equipment.Name, value: equipment.Id });
                });
                this.editEquipments = equipmentResult;
            }
        }));
    }

    onEquipmentTypeClose() {
        this.equipmentTypeForm.reset();
        this.editEquipmentTypeId = 0;
    }
    onEquipmentClose() {
        this.addEquipmentForm.reset();
        this.equipmentId = 0;
    }
    /**
     * edit equipmentType
     * @param equipmentType {any}
     */
    editType(equipmentType: BusinessEquipmentType) {
        this.editEquipmentTypeId = equipmentType.Id;
        let result = {
            Name: equipmentType.Name
        };
        (<FormGroup>this.equipmentTypeForm)
            .setValue(result, { onlySelf: true });
    }

    /**
     * edit equipment
     * @param equipment {any}
     */
    editEquipment(equipment: BusinessEquipmentType) {
        this.equipmentId = equipment.Id;
        let result = {
            Name: equipment.Name
        };
        (<FormGroup>this.addEquipmentForm)
            .setValue(result, { onlySelf: true });
    }


    /**
     * add and update equipment type
     */
    saveEquipType() {
        let equipmentType = new BusinessEquipmentType();
        equipmentType.Id = (this.editEquipmentTypeId) ? this.editEquipmentTypeId : 0;
        equipmentType.Name = this.equipmentTypeForm.value.Name.trim();
        equipmentType.Status = '1';
        equipmentType.BusinessId = this.businessId;
        if (equipmentType.Id > 0) {
            this.subscriptions.push(this.businessEquipmentService.updateEquipType(equipmentType).subscribe(equipmentTypeEdited => {
                this.bindEquipmentTypeDropDown(equipmentTypeEdited);
            }));
        } else {
            this.subscriptions.push(this.businessEquipmentService.createEquipType(equipmentType)
                .subscribe(equipmentTypeAdded => {
                    this.bindEquipmentTypeDropDown(equipmentTypeAdded);
                }));
        }
    }
    /**
     * equipment dropdown
     * @param euipmentInfo {any}
     */
    bindEquipmentTypeDropDown(euipmentInfo: any) {
        if (euipmentInfo) {
            this.bindEquipmentType()
            this.toasterComponent.callToast();
            this.equipmentParentId = euipmentInfo.Id;
            this.editEquipmentTypeId = 0;
            this.addType = false;
            this.isDisable = false;
            this.equipmentForm.get('EquipmentParent')!.patchValue(euipmentInfo.Id);
        } else {
            this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
            this.isNameExists = true;
            setTimeout(function () {
                this.isNameExists = false;
            }.bind(this), 3000);
        }
    }

    /**
     * add and edit equipment
     */
    saveEquipment() {
        let equipment = new BusinessEquipmentType();
        equipment.Id = (this.equipmentId) ? this.equipmentId : 0;
        equipment.Name = this.addEquipmentForm.value.Name.trim();
        equipment.Status = '1';
        equipment.ParentId = (this.equipmentParentId > 0) ? this.equipmentParentId : '';
        equipment.BusinessId = this.businessId;
        if (equipment.Id > 0) {
            this.subscriptions.push(this.businessEquipmentService.updateEquipType(equipment).subscribe((isEquipmentUpdated: any) => {
                this.bindEquipmentDropDown(isEquipmentUpdated);
            }));
        } else {
            this.subscriptions.push(this.businessEquipmentService.createEquipType(equipment)
                .subscribe((isEquipmentAdded: any) => {
                    this.bindEquipmentDropDown(isEquipmentAdded);
                }));
        }
    }

    bindEquipmentDropDown(equipmentInfo: any) {
        if (equipmentInfo) {
            this.equipmentId = 0;
            this.childId = equipmentInfo.Id;
            this.bindEquipment(this.equipmentParentId);
            this.toasterComponent.callToast();
            this.addEquip = false;
            this.equipmentForm.get('EquipmentType')!.patchValue(equipmentInfo.Id);
        } else {
            this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
            this.isNameExists = true;
            setTimeout(function () {
                this.isNameExists = false;
            }.bind(this), 3000);
        }
    }

    /**
     * mapping equipment and equipment ype
     */
    addEquipment() {
        let addEquip = new BusinessEquipment();
        addEquip.EquipmentTypeId = this.childId;
        addEquip.Status = '1';
        addEquip.EquipmentParentId = (this.equipmentParentId > 0) ? this.equipmentParentId : '';
        addEquip.BusinessId = this.businessId;
        this.subscriptions.push(this.businessEquipmentService.add(addEquip)
            .subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.bindEquipmentList();
                }
            }));
        this.equipmentTable.reset();
        this.equipmentForm.reset();
        this.equipmentTypeForm.reset();
    }

    /**
     * delete equipment type
     * @param value {anyF}
     */
    deleteType(value: any) {
        let data = [] = this.bequipobj.filter(element => element.EquipmentParentId === value);
        if (data.length > 0) {
            alert(this.translate.instant('CANNOT_DELETE_TOE_USED'));
        } else {
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.businessEquipmentService.deleteType(value, this.businessId)
                        .subscribe(deleteResponse => {
                            if (deleteResponse) {
                                this.toasterComponent.callToastDlt();
                                this.bindEquipmentType();
                                this.addType = false;
                            }
                        }));
                }
            });

        }
    }

    /**
     * delete equipment
     * @param value {any}
     */
    deleteEquipment(value: any) {

        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.businessEquipmentService.deleteEquipment(value)
                    .subscribe(result => {
                        if (result) {
                            this.toasterComponent.callToastDlt();
                            this.bindEquipmentList();
                        }
                    }));
            }
        });

    }
    /**
     * delete equipment dropdown item
     * @param value {any}
     */
    deleteEquipmentInModal(value: any) {
        let data = [] = this.bequipobj.filter(element => element.EquipmentTypeId === value);
        if (data.length > 0) {
            alert(this.translate.instant('CANNOT_DELETE_EQUIP_USED'));
        } else {
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.businessEquipmentService.deleteType(value, this.businessId)
                        .subscribe(deleteResponse => {
                            if (deleteResponse) {
                                this.bindEquipment(this.equipmentParentId);
                            }

                        }));
                }
            });
        }
    }

    /**
     * navigate location
     */
    goToNext() {
        this.router.navigate(['./../social-aspects'], { relativeTo: this.activatedRoute });
    }
    //#endregion

    //#region control-events
    /**
    * equipment type change event
    * @param e {event}
    * @param equipmentDropdown {any}
    */
    onEquipmentChanged(e: any, equipmentDropdown: any) {
        this.editEquipments = [];
        this.equipmentParentId = e.value;
        if (this.equipmentForm.value.EquipmentParent) {
            this.isDisable = false;
        } else {
            this.isDisable = true;
        }
        this.equipmentParentId = e.value;
        this.bindEquipment(this.equipmentParentId);
        if (e.value && this.bequipobj) {
            // custom filter datatable
            this.equipmentTable.filter(equipmentDropdown.selectedOption.label, 'EquipmentParent', '');
        } else {
            this.equipmentTable.reset();
            this.equipments = [];
        }
    }

    /**
     * equipment change event
     * @param e {event}
     */
    onTypeChanged(e: any) {
        this.childId = e.value;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}

