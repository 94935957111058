import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { result } from 'lodash';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SJAFormServices } from '../sjaform.routing';
import { TaskPlanListDto, ApprovalNotices } from './../../follow-ups/models/riskanalysis';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sja-list',
  templateUrl: './sja-list.component.html',
  styles: []
})
export class SjaListComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
  sjaDataList = [];
  roleId: number;
  featureKey: number;
  loading = false;
  selectedSJA: any = [];
  parentId: any;
  approvalNotices: any = [];
  parentKey: any;
  rowID: number = null;
  isCollapsed = false;
  isApproved = false;
  isShowToUser = true;
  checkRole: boolean;
  isRole: boolean;
  Business: SelectItem[];
  showApprovalTable = true;
  hasRights: boolean;
  guestEditAccess: boolean;
  loginUserName: string;
  userRole: string;
  isUser: boolean;
  showInfo: boolean;
  private subscriptions: Subscription[] = [];


  constructor(private route: ActivatedRoute,
    private SJAFormServices: SJAFormServices,
    private confirmationService: ConfirmationService,
    private translate: TranslateService) {

    this.parentKey = route.snapshot.parent.parent.data['parent'];
    let workingModule = <any>JSON.parse(sessionStorage.getItem('workingModule'));
    this.parentId = workingModule.id;
  }

  ngOnInit() {
    // To hide side menu bar
    document.querySelector("body").classList.remove("opened");
    this.isRole = BaseServices.roleId == '2' || BaseServices.roleId == '1' ? false : true;
    if (this.isRole) {
      this.sjaDataList = this.route.snapshot.data['list'];
    }
    this.roleId = +BaseServices.roleId;
    if (this.isRole == false) {
      this.isShowToUser = false;
      this.isApproved = true;
      this.showApprovalTable = false;
    }
    this.featureKey = this.route.snapshot.parent.parent.params['id'];
    this.featureKey = this.parentId == 15 ? this.featureKey = 0 : this.featureKey;
    this.bindApprovalNoticeList();
    this.bindBusinessDropDown();
    let businessList = this.route.snapshot.data['business'];
    let userRights = JSON.parse(BaseServices.getUserRights());
    let checkSJAFormRight
    if (userRights != null) {
      checkSJAFormRight = userRights.filter(x => x.id == 47 && x.isChecked == true);
    }
    this.userRole = BaseServices.UserRole;
    this.hasRights = this.userRole == 'Editor' ? true : this.userRole == 'User' && checkSJAFormRight.length == 1 ? true : this.userRole == 'Guest' ? false : false;
    this.loginUserName = BaseServices.Name;

  }

  DeleteSJAForm(Id) {
    if (Id > 0) {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.loading = true;
          this.featureKey = this.parentId == 15 ? 0 : this.featureKey
          this.subscriptions.push(this.SJAFormServices.deleteSJAForm(Id)
            .subscribe((data: any) => {
              if (data) {
                this.subscriptions.push(this.SJAFormServices.getSJAFormList(this.parentId, this.featureKey, BaseServices.BusinessId)
                  .subscribe((result: any) => {
                    this.sjaDataList = result;
                  }));
                this.loading = false;
              }
            }));
        }
      });
    }
  }

  exportPdf(sjaFormId) {
    this.loading = true;
    let fileName = this.translate.instant('SJA_FORM')
    let filetype = 'pdf';
    let cultureInfo = sessionStorage.getItem('languageMode');
    this.subscriptions.push(this.SJAFormServices.exportSJAForm(sjaFormId, cultureInfo, BaseServices.ApplicationId)
      .subscribe(blob => {
        this.loading = false;
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.${filetype.toLowerCase()}`;
        link.click();
      },
        error => {
          alert('Export not downloaded');
        }))
  }



  saveApprovalNotice() {
    let approvalNotices = [];
    if (this.selectedSJA.length > 0) {
      this.selectedSJA.forEach((e: any) => {
        let approvalNotice = new ApprovalNotices();
        approvalNotice.Id = 0;
        approvalNotice.BusinessId = BaseServices.BusinessId;
        approvalNotice.SjaFormId = e.Id;
        approvalNotice.ApprovedBy = BaseServices.UserId;
        approvalNotice.ApprovedOn = new Date();
        approvalNotices.push(approvalNotice);
      });
      this.subscriptions.push(this.SJAFormServices.addApprovalNotices(approvalNotices).subscribe(addResult => {
        if (addResult) {
          this.toasterComponent.callToast();
        }
        this.bindApprovalNoticeList();
        this.featureKey = this.parentId == 15 ? this.featureKey = 0 : this.featureKey;
        this.subscriptions.push(this.SJAFormServices.getSJAFormList(this.parentId, this.featureKey, BaseServices.BusinessId).subscribe((result: any) => {
          if (result) {
            this.sjaDataList = result;
          }
        }));
      }));
    }
    else if (this.selectedSJA.length == 0) {
      this.showInfo = true;
    }
    this.selectedSJA = [];
  }


  bindApprovalNoticeList() {
    this.approvalNotices = [];
    if (this.parentId == 43) {
      this.subscriptions.push(this.SJAFormServices.getAllApprovedForKubaControl(this.featureKey).subscribe(result => {
        if (result) {
          this.approvalNotices = [];
          this.approvalNotices = result;
        }
      }))
    } else {
      this.subscriptions.push(this.SJAFormServices.getAllApproved(this.featureKey).subscribe(result => {
        if (result) {
          this.approvalNotices = [];
          this.approvalNotices = result;
        }
      }))
    }
  }

  public setCollapse(i: number): boolean {
    let isCollapsed = (i === this.rowID);
    return !isCollapsed;
  }

  toggleClick(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
  }

  toggleClickHide(i: number): void {
    this.rowID = null;
  }

  bindBusinessDropDown() {
    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
      this.Business = [];
      this.Business.push(
        { label: val.SELECT, value: null }
      );
    }));

    let businessList = this.route.snapshot.data['business'];
    if (businessList) {
      businessList.forEach((repitition: any) => {
        this.Business.push({
          label: repitition.Name,
          value: repitition.Id
        });
      });
    }
  }

  onBusinessChanged(event) {
    this.loading = false;
    if (event.value) {
      this.featureKey = this.parentId == 15 ? this.featureKey = 0 : this.featureKey;
      this.subscriptions.push(this.SJAFormServices.getSJAFormList(this.parentId, this.featureKey, event.value).subscribe((res: any) => {
        this.sjaDataList = res;
        this.bindApprovalNoticeList();
      }))
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
