<div class="panel-tabs">
    <nav>
        <ul class="nav nav-pills">
            <li [routerLinkActive]="['active']">
                <a [routerLink]="['./our']">
                    <span translate>OUR_CONTACTS</span>
                </a>
            </li>
            <li [routerLinkActive]="['active']">
                <a [routerLink]="['./contact']">
                    <span translate>CUSTOMER_CONTACTS</span>
                </a>
            </li>
            <li [routerLinkActive]="['active']">
                <a [routerLink]="['./login']">
                    <span translate>CUSTOMER_CONTACTS_WITHLOGIN</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<div class="card">
    <div class="card-body">
        <router-outlet></router-outlet>
    </div>
</div>