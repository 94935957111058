import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { NotificationEdit } from '../models/notificationEdit';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class NotificationServices {

    constructor(private http: HttpClient) { }


    getAll() {
        return this.http.get<any>(environment.BASE_URL + '/notifications', BaseServices.headerOption)
            .map(result => result);

    }

    getById(notificationId: number) {
        return this.http.get(environment.BASE_URL + '/notification/' + notificationId, BaseServices.headerOption)
            .map(result => result);
    }

    add(notification: NotificationEdit) {
        return this.http.post(environment.BASE_URL + '/notification', notification, BaseServices.headerOption)
            .map(result => result);
    }

    update(notification: NotificationEdit) {
        return this.http.put(environment.BASE_URL + '/notification/' + notification.Id, notification, BaseServices.headerOption)
            .map(result => result);
    }

    deleteNotification(id) {
        let result: boolean;
        return this.http.delete(environment.BASE_URL + '/notificationDelete/' + id, BaseServices.headerOption).toPromise()
            .then(result => result);
    }

    getLoginUserNotifications() {
        return this.http.get<any>(environment.BASE_URL + '/notifications/list', BaseServices.headerOption)
            .map(result => result);
    }

    getAllUserNotifications() {
        return this.http.get(environment.BASE_URL + '/notifications/users', BaseServices.headerOption)
            .map(result => result);
    }

    private extractData(res: any) {
        let body = res;       // return body || {};

        return <NotificationEdit[]>res;

    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
