import { Component, OnInit } from '@angular/core';
@Component({
   
    template: `<router-outlet></router-outlet>`
})
export class SupplierComponent implements OnInit {
    constructor() { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
     }
}