import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Tasklist, ActivityDelete } from 'src/app/kuba/task-manager/models';
import { TaskService } from 'src/app/kuba/task-manager/services/task.services';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { FeatureKey, Rights } from 'src/app/_models';
import { FeatureManualService } from 'src/app/kuba/feature-documents/feature-document.services';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { PaginationInstance } from 'ngx-pagination';
import * as _ from 'lodash';
import { FoodSafetyServices } from '../../services/foodsafety.services';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'daily-task',
    templateUrl: 'daily-task.component.html'
})

export class DailyTaskComponent implements OnInit {
    tasks: Tasklist[];
    @ViewChild('taskTable',{static: false}) taskTable: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    pdf: any;
    selectedTasklist: Tasklist;
    tasklist: Tasklist[];
    parentId: number;
    parentKey: any;
    statuses: SelectItem[];
    loading = false;
    displayDialog = false;
    recurringActivityId = 0;
    isAdministrateActivity: boolean;
    workingModule: any;
    private subscriptions: Subscription[] = [];
    additionalData = {
        fileName: this.translate.instant('FS_TASK_MANAGER_LIST'),
        header: this.translate.instant('FS_TASK_MANAGER_LIST'),
        businessId: BaseServices.BusinessId,
        cultureInfo: BaseServices.userCultureInfo(),
        columnNames: [
            { title: 'No', dataKey: 'No' },
            { title: 'Title', dataKey: 'Title' },
            { title: 'Description', dataKey: 'Description' },
            { title: 'Deadline', dataKey: 'EndDate' },
            { title: 'Created On', dataKey: 'CreatedOn' },
            { title: 'Created By', dataKey: 'CreatorName' }
        ]
    };
    treeNode: any;
    uploadedFiles: any;
    upConfig: UploaderConfig;
    nodeId: any;
    isEditMode = false;
    pgConfig: PaginationInstance = {
        itemsPerPage: 10,
        currentPage: 1
    };
    pageSizeList: SelectItem[] = [
        { label: 'All', value: 0 },
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '50', value: 50 },
    ];
    public selectedPageSize = this.pgConfig.itemsPerPage;

    constructor(public taskService: TaskService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private foodsafetyService: FoodSafetyServices) {
        this.parentKey = this.route.snapshot.params['Id']
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.parentId = this.workingModule.id;
        this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.NEW, value: 7 },
                { label: val.OPEN, value: 8 },
                { label: val.DONE, value: 9 },
                { label: val.REJECTED, value: 10 }
            );
        }));
        this.BindData();
    }

    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        let tasks = <Tasklist[]>this.route.snapshot.data['list'];
        this.tasklist = [];
        if (tasks ? tasks.length > 0 : false) {
            this.bindTasks(tasks);
        }
        this.onAdministrateActivity();
        sessionStorage.setItem('childrenModule',null);
    }

    bindTasks(taskList?: any): any {
        this.tasklist = [];
        taskList.forEach((element: any) => {
            element.Responsible = false;
            if (element.FollowupUserIdList) {
                for (let i = 0; i < element.FollowupUserIdList.length; i++) {
                    if (BaseServices.UserId === element.FollowupUserIdList[i]) {
                        element.Responsible = true;
                    }
                }
            }
            if (!element.Responsible) {
                if (element.ExecutionUserIdList) {
                    for (let i = 0; i < element.ExecutionUserIdList.length; i++) {
                        if (BaseServices.UserId === element.ExecutionUserIdList[i]) {
                            element.Responsible = true;
                        }
                    }
                }
            }
            let daysDiff = 0;
            let statusIcon = '';
            let currentdate = new Date();
            let deadLine = new Date(element.EndDate);
            if (element.EndDate != null) {
                daysDiff = this.getDaysDiff(currentdate, deadLine);
            }
            if (element.Status === 'NEW') {
                statusIcon = 'icon ic-sm icon-star-circle text-primary';
            } else if (
                element.Status === 'DONE' ||
                element.Status === 'Rejected'
            ) {
                statusIcon = 'icon ic-sm icon-clear-deadline';
            } else {
                statusIcon = 'icon ic-sm icon-star-circle text-secondary';
            }
            element.StatusIcon = statusIcon;
            this.tasklist.push(element);
        });
    }
    /**
     * get Task manager list
     */
    getTaskList() {
        this.subscriptions.push(this.foodsafetyService.getFSDailyTaskList(this.parentKey).subscribe(res => {
            if (res) {
                this.bindTasks(res)
            }
        }));
    }

    /**
     * filter task mananger list based on status
     * @param e {{any}}
     * @param statusDropdown {{any}}
     */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            this.taskTable.filter(statusDropdown.selectedOption.value, 'StatusId', 'equals');
        } else {
            this.taskTable.reset();
        }
    }

    /**
     * get days between start date and end date
     * @param StartDate {{date}}
     * @param EndDate {{date}}
     */
    getDaysDiff(StartDate: Date, EndDate: Date) {
        if (StartDate && EndDate) {
            let _MS_PER_DAY = 1000 * 60 * 60 * 24;
            let date1 = Date.UTC(
                StartDate.getFullYear(),
                StartDate.getMonth(),
                StartDate.getDate()
            );
            let date2 = Date.UTC(
                EndDate.getFullYear(),
                EndDate.getMonth(),
                EndDate.getDate()
            );
            let res:any = Math.floor((date2 - date1) / _MS_PER_DAY);
            return res;
        }
    }

    onAdministrateActivity() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
        if (currentUserRole === 'User') {
            this.isAdministrateActivity = BaseServices.checkUserRights(userRightsId, currentUserRole);
        } else if (currentUserRole === 'Guest') {
            this.isAdministrateActivity = false;
        } else {
            this.isAdministrateActivity = true;
        }
    }

    onCancel() {
        this.displayDialog = false;
    }

    recurTranslate(recur) {
        let concatRecur = "";
        if (recur) {
            for (var i = 0; i < recur.length; i++) {
                if (recur.length == i + 1) {
                    concatRecur += this.translate.instant(recur[i]);
                } else {
                    concatRecur += this.translate.instant(recur[i]) + ", ";
                }
            }
        }
        return concatRecur;
    }

    //#region checklist
    BindData() {
        this.subscriptions.push(this.taskService
            .getCheckList(this.parentId, this.parentKey)
            .subscribe(
                (result: any) => {
                    this.uploadedFiles = result;
                },
                (error: any) => {
                    console.error('Api Error while refreshing tree:', error);
                },
                () => {
                    console.log('Refreshing tree service complete.');
                }
            ));
    }
    private flattenArrObj(arr) {
        let newArr = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    onPageChanged(e: any) {
        if (this.selectedPageSize === 0) {
            this.pgConfig.itemsPerPage = this.uploadedFiles.length;
            this.pgConfig.currentPage = 1;
        } else {
            this.pgConfig.itemsPerPage = this.selectedPageSize;
        }
    }
    //#endregion
    checkTaskStatus(task) {
        if (task.ChecklistId) {
            this.subscriptions.push(this.taskService.getActivityCompleteCheckList(task.Id).subscribe(
                (result: any) => {
                    if (result) {
                        this.router.navigate(
                            ['../../../../complete-checklist/incomplete/' + result.Id,
                                'task-manager', this.parentId, this.parentKey],
                            { relativeTo: this.route }
                        );
                    } else {
                        this.router.navigate(
                            ['../../../../complete-checklist/new/' + task.ChecklistId,
                                'task-manager', this.parentId, this.parentKey, task.Id],
                            { relativeTo: this.route }
                        );
                    }
                }
            ));
        }
        else {
            this.confirmationService.confirm({
                message: this.translate.instant('COMPLETE_TASK?'),
                accept: () => {
                    this.loading = true;
                    this.subscriptions.push(this.taskService.CompleteFSTask(task.Id, BaseServices.UserId).subscribe(completed => {
                        if (completed) {
                            this.toasterComponent.callToast();
                            setTimeout(() => {
                                this.getTaskList();
                            },100);
                            this.loading = false;
                        }
                    }));
                }
            });
        }
    }
    saveEmitter(eventData) {
        switch (eventData.eventName) {
    
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}