import { KubaExportService } from './kuba-export.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { KubaExportComponent } from './kuba-export.component';

import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from '../appShared.module';

@NgModule({
    imports: [TranslateModule, AppSharedModule],
    exports: [KubaExportComponent],
    declarations: [KubaExportComponent],
    providers: [KubaExportService],
})
export class KubaExportModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: KubaExportModule
        };
    }
}
