<div class="main-content ">
    <div class="main-heading">
        <strong translate>PROJ_DETAILS
            <span> - {{ pageTitle | capitalize}}</span>
        </strong>
    </div>
    <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="projectId"></ecpl-tab-navigation>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>