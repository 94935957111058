import { ActivityLogServices } from './../services/activitylog.services';
import { SelectItem } from 'primeng/api';
import { ToasterConfig, ToasterService, Toast } from 'angular2-toaster';
import { ActivatedRoute, Params } from '@angular/router';
import {
    FormGroup,
    FormBuilder,
    ReactiveFormsModule,
    FormControl
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
    templateUrl: 'new-activitylog.component.html'
})
export class CreateActivityLogComponent implements OnInit {
    activitylogForm: FormGroup;
    Id: number;
    accessLog: any;
    cBusiness: any;
    cUsers: any;
    business: SelectItem[];
    users: SelectItem[];

    public config1: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-right',
        showCloseButton: false,
        tapToDismiss: false,
        timeout: 2000
    });

    constructor(
        private fb: FormBuilder,
        private activityLogServices: ActivityLogServices,
        private activatedRoute: ActivatedRoute,
        private toasterService: ToasterService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.activitylogForm = this.fb.group({
            username: [''],
            business: [''],
            ip: [''],
            browser: [''],
            os: ['']
        });
    }
    popToast() {
        let toast: Toast = {
            type: 'success',
            title: 'Add/Update',
            body: 'Data saved successfully'
        };

        this.toasterService.pop(toast);
    }

    ngOnInit() {document.querySelector("body").classList.remove("opened");}

    gotoList() {
        this.location.back();
    }

    // TODO: to be worked on save funs
    saveChanges() {}
}
