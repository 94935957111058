import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationList } from './../models/notificationList';
import { NotificationServices } from './notifications.service';



@Injectable()
export class NotificationListResolver implements Resolve<NotificationList[]> {
    constructor(private backend: NotificationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<NotificationList[]> {
        return this.backend.getAll();
    }

}

@Injectable()
export class NotificationUserListtResolver implements Resolve<NotificationList[]> {
    constructor(private backend: NotificationServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<NotificationList[]> {
        return this.backend.getLoginUserNotifications();
    }

}

