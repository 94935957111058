import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { FaqsViewComponent, FaqsComponent, CustomHelpComponent } from './index';

export const faqsRoutes: Route[] = [
    {
        path: 'faq',
        component: FaqsComponent,
        children: [
            {
                path: 'views',
                component: FaqsViewComponent
            },
            {
                path: 'helps',
                component: CustomHelpComponent
            }
        ]
    }
    // {
    //     path: 'views',
    //     component: FaqsViewComponent
    // },
    // {
    //     path: 'helps',
    //     component: CustomHelpComponent,
    //     // resolve: {
    //     //     edit: FaqsEditResolver
    //     // }
    // }
];
