import { Route, Resolve } from '@angular/router';
import { AuthGuard } from '../_guards/index';
import { kubaRoutes } from 'src/app/kuba';
import { KubaRuhNewComponent } from './kuba-ruh.component';
import { RuhCaseTypeResolver } from 'src/app/kuba/follow-ups/services/ruh.resolver.service';
import { AllDepartmentCachedResolver } from 'src/app/kuba/follow-ups/services/deviation.resolver.service';
import { EmployeeContactUserResolver } from 'src/app/kuba/time-table/services/timetable.resolver.service';
import { UserRoleResolver } from 'src/app/kuba/users/services/user.resolver.service';

export const RuhRoutes: Route[] = [
    {
        path: 'ruhguestlogin', // commen login for all app      
        component: KubaRuhNewComponent,
        resolve:
        {
            caseType: RuhCaseTypeResolver,
            department: AllDepartmentCachedResolver,
            user: EmployeeContactUserResolver,
            userrole: UserRoleResolver
        }
    },
];
