import { Component, OnInit } from '@angular/core';
import { SuggestionsServices } from '../services/suggestion.service';
import { Suggestion } from '../models/suggestions';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'box',
    templateUrl: 'box.component.html'
})

export class BoxComponent implements OnInit {
    nSuggestion: any = {};
    suggestionbox: Suggestion[];
    selectedSuggestion: Suggestion;
    showNewSuggestion = false;
    showEditSuggestion = false;
    displayDialog: boolean;
    suggestionForm: FormGroup;
    public test = {};
    Id: number;
    public gId;
    value: any;
    public Title;
    public Description;
    public Status;
    public testId: any;
    private subscriptions: Subscription[] = [];
    constructor(private sugbox: SuggestionsServices,
        private formBuilder: FormBuilder) {
            this.subscriptions.push(this.sugbox.list().subscribe((res: any) => this.suggestionbox = res));
        this.suggestionForm = this.formBuilder.group({
            Title: ['', Validators.required],
            Description: ['', Validators.required],
            Status: ['', Validators.required]
        });
    }
    /** New suggestions */
    newSuggestion() {

        this.showEditSuggestion = true;
    }
    saveClose(value: Suggestion) {
        this.showNewSuggestion = false;
        this.sugbox.create(value);
        this.subscriptions.push(this.sugbox.list().subscribe((res: any) => this.suggestionbox = res));
    }
    editsuggestion(data) {
        this.showEditSuggestion = true;
        this.Id = data.Id;
        this.gId = data.Id;
        this.Title = data.Title;
        this.Description = data.Description;
        this.Status = data.Status;
        this.testId = data.Id;
    }
    /* delete the suggestion*/
    deletesuggestion(data) {
        let deleteid = data.Id;
        this.sugbox.deleteById(deleteid, data);
    }
    updateClose(data: Suggestion) {
        let x = this.testId;
        this.sugbox.update(x, data);
    }
    save(newactivity) {

        //this.activitysrv.add(activityobj);
    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.sugbox.list().subscribe(
            (result: any) => this.suggestionbox = result
        ))
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
