import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'followups-deviation',
    templateUrl: 'deviation.component.html'
})
export class FollowUpDeviationComponent implements OnInit {
    
    constructor() { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
   
}