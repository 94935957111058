<div class="main-content">
  <div class="card">
    <div class="card-body">
      <div class="page-title">
        <span translate>NEW_FEATURES</span>
        <span class="page-actions">
          <button type="button" class="btn btn-success" (click)="notification(null)">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
          </button>
        </span>
      </div>
      <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="notificationlist" #dt [rows]="10" [paginator]="true" [pageLinks]="3"
          [rowsPerPageOptions]="[5,10,20]"
          [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="Title">{{'TITLE' | translate}}</th>
              <th>{{'STATUS' | translate}}</th>
              <th pSortableColumn="CreatedOn">{{'CREATED_ON' | translate}}</th>
              <th pSortableColumn="PublishedOn">{{'PUBLISHED_ON' | translate}}</th>
              <th>{{'OPTIONS'| translate}}</th>
            </tr>
            <tr>
              <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'Title', 'contains')"
                  placeholder="" class="p-column-filter">
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-notification>
            <tr>
              <td>
                <span>{{notification.Title}}</span>
              </td>
              <td>
                <span>{{notification.StatusText.toUpperCase() | translate}}</span>
              </td>
              <td>
                <span>{{notification.CreatedOn |date : 'dd/MM/yyyy'}}</span>
              </td>
              <td>
                <span>{{notification.PublishedOn |date : 'dd/MM/yyyy'}}</span>
              </td>
              <td class="col-button">
                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                  (click)="notificationEdit(notification.Id)">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                  (click)="deleteNotification(notification.Id)">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="5">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->

        <!--New Notification Modal Popup-->
        <p-dialog header="{{'ADD_EDIT_NOTIFICATION'|translate}}" [(visible)]="showNewNotification" [modal]="true"
        [style]="{width: '600px'}" [resizable]="false" [draggable]="false">
          <form [formGroup]="notificationForm" novalidate>
            <div class="form-group">
              <label for="title">
                <span translate>TITLE</span>
                <span class="required">*</span>
              </label>
              <input type="text" class="form-control" formControlName="Title" pInputText />
              <control-messages [control]="notificationForm.controls['Title']"></control-messages>
            </div>
            <div class="mbtm-10">
              <label for="description">
                <span translate>CONTENT</span>
              </label>
              <p-editor formControlName="description" [(ngModel)]='sign' [style]="{'height':'200px'}">
              </p-editor>
            </div>
            <button type="button" class="btn btn-outline-primary" (click)="getUser()">
              <span translate>SIGN</span>
            </button>
          </form>
          <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
              <button type="button" class="btn btn-outline-secondary" (click)="showNewNotification=false">
                <span translate>CANCEL</span>
              </button>
              <button type="button" class="btn btn-primary" [disabled]="!notificationForm.valid"
                (click)="saveNotification(1)">
                <span translate>SAVE_CLOSE</span>
              </button>
              <button *ngIf="!isPublished" type="button" class="btn btn-primary" [disabled]="!notificationForm.valid"
                (click)="saveNotification(2)">
                <span translate>SAVE_PUBLISH</span>
              </button>
            </div>
          </p-footer>
        </p-dialog>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog width="425" acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}">
</p-confirmDialog>
<toaster-component></toaster-component>