<div class="tab-content">
    <div class="main-content">
        <div class="main-heading">
            <span translate>PROJECTS</span>
        </div>
        <div class="card">
            <div class="card-body">
                <form class="form" [formGroup]="projectForm">
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>PROJ_NAME</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="ProjectName" class="ex-full-width" />
                        </div>
                        <control-messages [control]="projectForm.controls['ProjectName']"></control-messages>
                    </div>
                    <div class="form-group" [hidden]="IsHidden">
                        <label class="col-form-label">
                            <span translate>CHOOSE_BUSINESS</span>
                        </label>
                        <div>
                            <p-dropdown [options]="ChooseBusiness" [style]="{'width':'524px'}" (onChange)="onChange()"
                              formControlName="Business" [filter]="true"
                              placeholder="{{'SELECT_BUSINESS' | translate}}">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="form-group" [hidden]="IsHide">
                        <label class="col-form-label">
                            <span translate>CHOOSE_PORTAL</span>
                        </label>
                        <div>
                            <p-dropdown [options]="ChoosePortal" (onChange)="onChangeevent()" formControlName="Portal"
                              [filter]="true" [style]="{'width':'524px'}" placeholder="{{'SELECT_PORTAL' | translate}}">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="action-btns-wrapper">
                        <a class="btn btn-outline-secondary" (click)="gotoBack()">
                            <span class="icon ic-xs icon-back"></span>

                            <span translate>BACK_LIST</span>
                        </a>
                        <button class="btn btn-primary" (click)="saveproject()" [disabled]="!projectForm.valid">
                            <span translate>CREATE</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>