import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { ManualDocument } from 'src/app/kuba/manuals/models/manual-document.model';
import { ManualVersion } from 'src/app/kuba/manuals/models/manual-version';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { HelperService } from 'src/app/_services/helper.service';
import {
    ArticleEntityModel,
    ArticleDataEntityModel,
    LarDataEntityModel,
    ArticleDetails
} from 'src/app/shared/ecpl-article-viewer/interfaces';
import { ManualChecklistModel } from '../models/manual-checklist.model';
import { TreeData } from '../../common/models';
import { BaseServices } from './../../kuba.services';
import { ManualEdit, ManualView } from './../models';
import { ManualCoverImageDto } from '../models/manual-edit.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ManualService {
    featureName: string;
    workingModule: any;
    applicationId: number;
    businessId: number;
    portalId: number;
    commonTreeData = [
        {
            id: 1,
            title: 'Project Data',
            fileType: 1,
            documentType: 1,
            folderId: 1,
            isCommon: true,
            checked: true,
            isEdit: false,
            children: [
                {
                    id: 2,
                    title: '1.1 Manual Folder',
                    fileType: 1,
                    documentType: 1,
                    folderId: 2,
                    isCommon: true,
                    children: [
                        {
                            id: 3,
                            title: '1.1.1 Articles 1',
                            fileType: 2,
                            documentType: 1,
                            folderId: 3,
                            isCommon: true
                        },
                        {
                            id: 4,
                            title: '1.1.2 Articles 2',
                            fileType: 2,
                            documentType: 1,
                            folderId: 4,
                            isCommon: true
                        }
                    ]
                },
                {
                    id: 5,
                    title: '1.2 Sample Folder',
                    fileType: 1,
                    documentType: 1,
                    folderId: 5,
                    isCommon: true
                }
            ]
        },
        {
            id: 6,
            title: 'Forms/Checklist',
            fileType: 1,
            documentType: 0,
            folderId: 6,
            isCommon: true,
            children: [
                {
                    id: 7,
                    title: 'Forms',
                    fileType: 1,
                    documentType: 2,
                    folderId: 7,
                    isCommon: true
                },
                {
                    id: 8,
                    title: 'Checklist',
                    fileType: 1,
                    documentType: 3,
                    folderId: 8,
                    isCommon: true
                }
            ]
        },
        {
            id: 10,
            title: 'Instructions',
            fileType: 1,
            documentType: 4,
            folderId: 9,
            isCommon: true
        },
        {
            id: 11,
            title: 'Laws and Regulation',
            fileType: 1,
            documentType: 5,
            folderId: 10,
            isCommon: true
        },
        {
            id: 12,
            title: 'Archive',
            fileType: 1,
            documentType: 6,
            folderId: 11,
            isCommon: true
        },
        {
            id: 13,
            title: 'Routines',
            fileType: 1,
            documentType: 7,
            folderId: 12,
            isCommon: true
        },
        {
            id: 14,
            title: 'Deviation',
            fileType: 1,
            documentType: 8,
            folderId: 13,
            isCommon: true
        }
    ];


    static createAuthorizationHeader(headers: Headers) {
        let session = JSON.parse(sessionStorage.getItem('session')!);
        if (session) {
            let type = 'Bearer';
            let token = session.AccessToken;
            // Split the JWT token by dots
            const tokenParts = token.split('.');

            // Check if there are at least two parts (header and payload)
            if (tokenParts.length >= 2) {
                // Add the desired letter after the first dot (in the payload part)
                tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);

                // Join the token parts back together with a dot separator
                // token = tokenParts.join('.'); commented for token issue fix

                headers.append('content-type', 'application/json');
                headers.append('Authorization', type + ' ' + token);
            } else {
                // Handle the case where the token doesn't have the expected format
                console.error('Invalid JWT token format');
            }
        }
    }

    // Observable string sources
    private isEditModeSource = new Subject<boolean>();

    // Observable string streams
    isEditMode$ = this.isEditModeSource.asObservable();

    constructor(private http: HttpClient, private route: ActivatedRoute) {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')!
        );
        this.featureName = this.workingModule.name;
        this.applicationId = BaseServices.ApplicationId;
        this.portalId = +this.route.snapshot.params['mPortalId'];
        this.businessId = +this.route.snapshot.params['mBusinessId'];
    }

    // Service message commands
    setMode(modeType: boolean) {
        this.isEditModeSource.next(modeType);
    }

    getManuallist(levelId: number, levelKey: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/${levelId}/${levelKey}/${
                BaseServices.ApplicationId
                }`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getPortalManuallist(id: number) {
        return this.http
            .get<any>(
                `${environment.BASE_URL}/manuals/portal/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getBusinessManuallist(id: number) {
        return this.http
            .get<any>(
                `${environment.BASE_URL}/manuals/business/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getAllManuallist(id: number) {
        return this.http
            .get<any>(
                `${environment.BASE_URL}/manuals/getAll/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getCommonManuallist(id: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/common/${id}`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    getCommonManuallistByBusinessLogin(id: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/businessLogin/${id}`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    
    getManualsDocumentTraining(id: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/getManualsDocumentTraining/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getCommonManuallistByPortalLogin(id: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/portalLogin/${id}`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    getManualTree(
        appId: number,
        mid: number,
        portalId: number,
        businessId: number
    ): Observable<TreeData[]> {
        return this.http
            .get<any>(
                `${
                environment.BASE_URL
                }/manual/${mid}/tree/manualtreedata/applicationlevel/${appId}/${portalId}/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getManualTreeBusinessLevel(
        mid: number,
        appId: number,
        portalId: number,
        businessId: number,
        isCopyManual: boolean
    ): Observable<TreeData[]> {
        return this.http
            .get<any>(
                `${
                environment.BASE_URL
                }/manual/${mid}/tree/manualtreedata/businesslevel/${appId}/${portalId}/${businessId}/${isCopyManual}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getManualTreePortalLevel(
        mid: number,
        appId: number,
        portalId: number,
        businessId: number
    ): Observable<TreeData[]> {
        return this.http
            .get<any>(
                `${
                environment.BASE_URL
                }/manual/${mid}/tree/manualtreedata/portallevel/${appId}/${portalId}/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    add(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .post(
                environment.BASE_URL + '/manual',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    addCommonManual(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .post(
                environment.BASE_URL + '/application/manual/common',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    addPortalManual(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .post(
                environment.BASE_URL + '/application/manual/portal',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    addBusinessManual(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .post(
                environment.BASE_URL + '/application/manual/business',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    update(Id: number, manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .put(
                environment.BASE_URL + '/manual/' + Id,
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    updateCommonManual(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .put(
                environment.BASE_URL + '/application/manual/common/',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    updateBusinessManual(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .put(
                environment.BASE_URL + '/application/manual/business/',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    updatePortalManual(manualEdit: ManualEdit) {
        let result: ManualView;
        return this.http
            .put(
                environment.BASE_URL + '/application/manual/portal/',
                manualEdit,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    delete(Id: number) {
        return this.http
            .delete(
                environment.BASE_URL + '/newsdelete/' + Id,
                BaseServices.headerOption
            )
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }

    getProjectTree() {
        return this.commonTreeData;
    }

    getAssignManualList(
        levelId: number,
        levelKey: number,
        appId: number,
        mode: number
    ) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/manuals/${levelId}/${levelKey}/${appId}/${mode}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    UpdateAssignManuals(manualList: ManualView[], mode: number) {
        return this.http
            .put(
                `${environment.BASE_URL}/application/manuals/${mode}`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    InsertAssignManuals(manualList: ManualEdit[]) {
        return this.http
            .post(
                `${environment.BASE_URL}/application/manuals`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    DeleteAppManualLevel(id: number) {
        return this.http
            .delete(
                `${environment.BASE_URL}/application/manual/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    DeletePortalManualLevel(id: number) {
        return this.http
            .delete(
                `${environment.BASE_URL}/application/manual/portal/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    DeleteBusinessManualLevel(id: number) {
        return this.http
            .delete(
                `${environment.BASE_URL}/application/manual/business/${id}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    GetAppManualVersions(appId: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/application/manual/version/${appId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    addUpdateManualVersion(manualVersion: ManualVersion) {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/version/${
                BaseServices.ApplicationId
                }`,
                manualVersion,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getBusinessUserListForManuals(businessId: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/ManualUsers/${businessId}`,
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    deleteManualVersion(id: number) {
        return this.http.delete(
            `${environment.BASE_URL}/manual/version/${id}`,
            BaseServices.headerOption
        );
    }

    getManualById(mid:any) {
        return this.http
            .get<any>(
                `${environment.BASE_URL}/application/manual/${mid}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Document related services
    // AppManual Document
    addManualDocument(uploadedFile:any, mPortalId: number, mBusinessId: number) {
        let appManualDocument: ManualDocument = new ManualDocument();
        appManualDocument.ApplicationId = +BaseServices.ApplicationId
            ? +BaseServices.ApplicationId
            : 0;
        (appManualDocument.PortalId = +mPortalId ? +mPortalId : 0),
            (appManualDocument.BusinessId = +mBusinessId ? +mBusinessId : 0);
        appManualDocument.AppManualId = uploadedFile.ManualId;
        appManualDocument.AppManualFolderId = uploadedFile.ManualFolderId;
        appManualDocument.Title = uploadedFile.filename;
        appManualDocument.OriginalFileName = uploadedFile.filename;
        appManualDocument.DocumentTypeId = uploadedFile.fileTypeId;
        appManualDocument.Path = uploadedFile.path;
        appManualDocument.MimeType = uploadedFile.mimetype;
        appManualDocument.Size = uploadedFile.size;
        appManualDocument.Status = 1;
        appManualDocument.CreatedBy = BaseServices.UserId;

        return this.http
            .post(
                `${environment.BASE_URL}/manual/${
                appManualDocument.AppManualId
                }/document/new`,
                appManualDocument,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // Document related services
    // AppManual copy Document
    copyManualDocument(docDetails: any, mPortalId: number, mBusinessId: number) {
        let appManualDocument: ManualDocument = new ManualDocument();
        appManualDocument.ApplicationId = +BaseServices.ApplicationId
            ? +BaseServices.ApplicationId
            : 0;
        (appManualDocument.PortalId = +mPortalId ? +mPortalId : 0),
            (appManualDocument.BusinessId = +mBusinessId ? +mBusinessId : 0);
        appManualDocument.Status = 1;
        appManualDocument.CreatedBy = BaseServices.UserId;
        appManualDocument.ModifiedBy = BaseServices.UserId;
        appManualDocument.Id = docDetails.Id;
        appManualDocument.Path = docDetails.Path;
        return this.http
            .post(
                `${environment.BASE_URL}/manual/${
                appManualDocument.AppManualId
                }/document/copy`,
                appManualDocument,
                BaseServices.headerOption
            )
            .map(result => result);
    }



    // Add manual folder
    createManualFolder(mid:any, model:any, isRootFolder:any, mPortalId:any, mBusinessId:any) {
        let sData = {
            AppManualId: mid,
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            PortalId: mPortalId ? +mPortalId : 0,
            BusinessId: mBusinessId ? mBusinessId : 0,
            FolderName: model.newNode.filename,
            ParentFolderId: isRootFolder ? 0 : model.parent.nodeId,
            DocumentTypeId: model.parent.documentTypeId,
            Status: 1,
            IsDefault: model.newNode.IsDefault,
            IsCommon: false,
            Position: 0,
            CreatedBy: +BaseServices.UserId
        };

        return this.http
            .post(
                `${environment.BASE_URL}/manual/${mid}/folder/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Rename Manaul Folder
    renameManualFolder(mid:any, model:any) {
        let sData = {
            Id: +model.item.data.nodeId,
            FolderName: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/folder/rename/${
                sData.Id
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Manual folder
    deleteManualFolder(mid:any, did:any) {
        return this.http
            .post(
                `${environment.BASE_URL}/manual/${mid}/folder/delete/${did}`,
                '',
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add Article
    newArticle(mid:any, model:any, mPortalId:any, mBusinessId:any) {
        let sData: ArticleEntityModel = {
            AppManualId: mid,
            AppManualFolderId: model.activeNodeId,
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            PortalId: +mPortalId ? +mPortalId : 0,
            BusinessId: +mBusinessId ? +mBusinessId : 0,
            Title: model.item.articleContent.article.articleName,
            DocumentNo: model.item.articleContent.article.documentNumber,
            Chapter: model.item.articleContent.article.chapter,
            Version: model.item.articleContent.article.version,
            ArticleDate: model.item.articleContent.article.articleDate.jsdate,
            SignaturePath: model.item.articleContent.article.signaturePath,
            Status: 1,
            CreatedBy: + BaseServices.UserId,
            ModifiedBy: null!
        };
        return this.http
            .post(
                `${environment.BASE_URL}/manual/${mid}/article/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add lar
    newLar(mid:any, model:any, mPortalId:any, mBusinessId:any) {
        let sData: LarDataEntityModel = {
            AppManualId: mid,
            AppManualFolderId: model.activeNodeId,
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            PortalId: +mPortalId ? +mPortalId : 0,
            BusinessId: +mBusinessId ? +mBusinessId : 0,
            Title: model.item.larContent.title,
            Url: model.item.larContent.url,
            IsLaws: model.item.larContent.isLaws,
            IsCommon: model.item.larContent.isCommon === '0' ? false : true,
            Status: 1,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .post(
                `${environment.BASE_URL}/manual/${mid}/law-regulations/add`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }
    // update lar
    saveLar(mid:any, model:any, mPortalId:any, mBusinessId:any) {
        let sData: LarDataEntityModel = {
            Id: +model.item.larId,
            Title: model.item.larContent.title,
            Url: model.item.larContent.url,
            IsCommon: model.item.larContent.isCommon,
            Status: 1,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/law-regulations/update`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update Article
    saveArticle(mid:any, model:any, mPortalId:any, mBusinessId:any) {
        let sData: ArticleEntityModel = {
            Id: model.item.articleId,
            AppManualId: mid,
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            PortalId: +mPortalId ? +mPortalId : 0,
            BusinessId: +mBusinessId ? +mBusinessId : 0,
            Title: model.item.articleContent.article.articleName,
            DocumentNo: model.item.articleContent.article.documentNumber,
            Chapter: model.item.articleContent.article.chapter,
            Version: model.item.articleContent.article.version,
            SignaturePath: model.item.articleContent.article.signaturePath,
            Status: 1,
            EditedBy: model.item.articleContent.article.editedBy,
            ModifiedBy: BaseServices.UserId,
            ArticleFormattedDate: model.item.articleContent.article.articleDate.formatted
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/article/update/${
                sData.Id
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    updateCommonManualCoverImg(mid:any, coverImage:any) {
        let sData: ManualCoverImageDto = {
            ManualId: mid,
            CoverImage: coverImage
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manuals/coverImage/common`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }
    updatePortalManualCoverImg(mid:any, coverImage:any, portalId:any) {
        let sData: ManualCoverImageDto = {
            ManualId: mid,
            CoverImage: coverImage,
            PortalId: portalId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manuals/coverImage/portal`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }
    updateBusinessManualCoverImg(mid:any, coverImage:any, businessId:any) {
        let sData: ManualCoverImageDto = {
            ManualId: mid,
            CoverImage: coverImage,
            BusinessId: businessId,
            ApplicationId: BaseServices.ApplicationId,
            PortalId: BaseServices.PortalId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manuals/coverImage/business`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    setPortalVersion() {

    }

    articleModifedBy(textType: string, model: any, mPortalId: number, mBusinessId: number) {
        if (this.applicationId !== 0 && mPortalId === 0 && mBusinessId === 0) {
            if (model.item.articleContent.article.id > 0 && model.item.articleContent.article.editedBy === null) {
                return BaseServices.UserId;
            }
            return model.item.articleContent.article.editedBy;
        } else if (this.applicationId !== 0 && mPortalId !== 0 && mBusinessId === 0) {
            if (model.item.articleContent.article.roleId === 1 && model.item.articleContent.article.portalId === 0) {
                if (textType === 'Own Text' || textType === 'Portal Text') {
                    return BaseServices.UserId;
                } else if (textType === 'Standard Text' || textType === 'Legislation') {
                    return model.item.articleContent.subArticles.filter((x:any) => x.name === 'Standard Text')[0].modifiedBy;
                }
            } else {
                return BaseServices.UserId;
            }
        } else if (this.applicationId !== 0 && mPortalId === 0 && mBusinessId > 0) {
            if (model.item.articleContent.article.roleId === 1 && model.item.articleContent.article.businessId === 0) {
                if (model.item.articleContent.article.roleId === 1 && model.item.articleContent.article.portalId === 0) {
                    if (textType === 'Own Text') {
                        return BaseServices.UserId;
                    } else if (textType === 'Portal Text') {
                        return model.item.articleContent.subArticles.filter((x:any) => x.name === 'Portal Text')[0].modifiedBy;
                    } else if (textType === 'Standard Text' || textType === 'Legislation') {
                        return model.item.articleContent.subArticles.filter((x:any) => x.name === 'Standard Text')[0].modifiedBy;
                    }
                } else {

                }
            } else if (model.item.articleContent.article.roleId === 2 && model.item.articleContent.article.businessId === 0) {
                if (textType === 'Own Text') {
                    return BaseServices.UserId;
                } else if (textType === 'Portal Text') {
                    return model.item.articleContent.subArticles.filter((x:any) => x.name === 'Portal Text')[0].modifiedBy;
                }
            } else {
                return model.item.articleContent.article.editedBy;
            }
        }
        return null;
    }

    /**
   * get current date
   */
    getCurrentDate(date = null) {
        let dtObj, dt;
        if (date == null) {
            dtObj = new Date();
            dt = {
                date: {
                    year: dtObj.getFullYear(),
                    month: dtObj.getMonth() + 1,
                    day: dtObj.getDate()
                },
                formatted: `${dtObj.getDate()}/${dtObj.getMonth() + 1}/${dtObj.getFullYear()}`,
                jsDate: dtObj,
                jsdate: dtObj
            }
        } else {
            dtObj = new Date(date);
            dt = {
                date: {
                    year: dtObj.getFullYear(),
                    month: dtObj.getMonth() + 1,
                    day: dtObj.getDate()
                }
            }
        }
        return dt;
    }

    /**
     *
     * @param textType
     * @param model
     * @param mPortalId
     * @param mBusinessId
     */
    articleEditDate(textType: string, model: any, mPortalId: number, mBusinessId: number) {
        if (this.applicationId !== 0 && mPortalId === 0 && mBusinessId === 0) {
            if (model.item.articleContent.article.articleDate.formatted) {
                return HelperService.formatDateFilter(model.item.articleContent.article.articleDate.formatted);
            } else {
                return HelperService.formatDateFilter(this.getCurrentDate().formatted);
            }
        } else if (this.applicationId !== 0 && mPortalId !== 0 && mBusinessId === 0) {
            if (model.item.articleContent.article.roleId === 1 && model.item.articleContent.article.portalId === 0) {
                if (textType === 'Own Text' || textType === 'Portal Text') {
                    if (model.item.articleContent.article.articleDate.formatted) {
                        return HelperService.formatDateFilter(model.item.articleContent.article.articleDate.formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }
                } else if (textType === 'Standard Text' || textType === 'Legislation') {
                    if (model.item.articleContent.subArticles.filter((x:any) => x.name === 'Standard Text')[0].articleDate) {
                        return HelperService.formatDateFilter(HelperService
                            .formatInputDate(model.item.articleContent.subArticles.filter((x:any) => x.name === 'Standard Text')[0].articleDate)
                            .formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }
                }
            } else {
                if (model.item.articleContent.article.articleDate.formatted) {
                    return HelperService.formatDateFilter(model.item.articleContent.article.articleDate.formatted);
                } else {
                    return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                }
            }
        } else if (this.applicationId !== 0 && mPortalId === 0 && mBusinessId > 0) {
            if (model.item.articleContent.article.roleId === 1 && model.item.articleContent.article.businessId === 0) {
                if (textType === 'Own Text') {
                    if (model.item.articleContent.article.articleDate.formatted) {
                        return HelperService.formatDateFilter(model.item.articleContent.article.articleDate.formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }
                } else if (textType === 'Portal Text') {
                    if (model.item.articleContent.subArticles.filter((x:any) => x.name === 'Portal Text')[0].articleDate) {
                        return HelperService.formatDateFilter(HelperService
                            .formatInputDate(model.item.articleContent.subArticles.filter((x:any) => x.name === 'Portal Text')[0].articleDate)
                            .formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }

                } else if (textType === 'Standard Text' || textType === 'Legislation') {
                    if (model.item.articleContent.subArticles.filter((x:any) => x.name === 'Standard Text')[0].articleDate) {
                        return HelperService.formatDateFilter(HelperService
                            .formatInputDate(model.item.articleContent.subArticles.filter((x:any) => x.name === 'Standard Text')[0].articleDate)
                            .formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }

                }
            } else if (model.item.articleContent.article.roleId === 2 && model.item.articleContent.article.businessId === 0) {
                if (textType === 'Own Text') {
                    if (model.item.articleContent.article.articleDate.formatted) {
                        return HelperService.formatDateFilter(model.item.articleContent.article.articleDate.formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }

                } else if (textType === 'Portal Text') {
                    if (model.item.articleContent.subArticles.filter((x:any) => x.name === 'Portal Text')[0].articleDate) {
                        return HelperService.formatDateFilter(HelperService
                            .formatInputDate(model.item.articleContent.subArticles.filter((x:any) => x.name === 'Portal Text')[0].articleDate)
                            .formatted);
                    } else {
                        return HelperService.formatDateFilter(this.getCurrentDate().formatted);
                    }
                }
            } else {
                return HelperService.formatDateFilter(model.item.articleContent.article.articleDate.formatted);
            }
        }
        return null;
    }

    // update Article own texts
    newArticleText(mid:any, aid:any, model:any, isNew:any, mPortalId:any, mBusinessId:any, version:any) {
        let sData: ArticleDataEntityModel[] = [];
        model.item.articleContent.subArticles.forEach((element:any) => {
                sData.push({
                Id: element.id ? element.id : 0,
                ApplicationId: +BaseServices.ApplicationId
                    ? +BaseServices.ApplicationId
                    : 0,
                PortalId: mPortalId ? +mPortalId : 0,
                BusinessId: mBusinessId ? +mBusinessId : 0,
                AppManualArticleId: aid,
                TextType: element.name,
                TextContent: element.text,
                SignaturePath: element.name === 'Own Text' ?
                    model.item.articleContent.article.signaturePath : null,
                Version: version,
                PortalVersion: element.portalVersion,
                PortalEditedBy: this.articleModifedBy(element.name, model, mPortalId, mBusinessId),
                BusinessVersion: (element.name === 'Own Text' && +BaseServices.roleId > 2) ? element.businessVersion : null,
                BusinessEditedBy: (element.name === 'Own Text' && +BaseServices.roleId > 2) ? element.businessEditedBy : null,
                Status: element.status === 'Active' ? '1' : '0',
                CreatedBy: BaseServices.UserId,
                ModifiedBy: this.articleModifedBy(element.name, model, mPortalId, mBusinessId),
                ArticleFormattedDate: this.articleEditDate(element.name, model, mPortalId, mBusinessId)!,
                ArticleEditDate: model.item.articleContent.article.articleEditDate,
                ArticleStatus: JSON.stringify(model.item.articleContent.article.roleStatus)
            });            
        });

        if (isNew) {
            return this.http
                .post(
                    `${
                    environment.BASE_URL
                    }/manual/${mid}/article/ArticleAllText`,
                    sData,
                    BaseServices.headerOption
                )
                .map(result => result)
                .catch(error => BaseServices.handleError(error));
        } else {
            return this.http
                .put(
                    `${
                    environment.BASE_URL
                    }/manual/${mid}/article/ArticleAllText/${aid}/${version}/${mBusinessId}/${BaseServices.ApplicationId}/${mPortalId}`,
                    sData,
                    BaseServices.headerOption
                )
                .map(result => result)
                .catch(error => BaseServices.handleError(error));
        }
    }

    // Rename Article
    renameArticle(mid:any, model:any) {
        let sData = {
            Id: +model.item.data.nodeId,
            Title: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/article/rename/${
                sData.Id
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Article
    deleteArticle(mid:any, did:any) {
        return this.http
            .delete(
                `${environment.BASE_URL}/manual/${mid}/article/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteSelectedArticle(data:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/0/article/delete`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add Checklist
    newChecklist(mid:any, model:any, mPortalId:any, mBusinessId:any) {
        let sData = new ManualChecklistModel();
        sData.ApplicationId = +BaseServices.ApplicationId
            ? +BaseServices.ApplicationId
            : 0;
        sData.PortalId = mPortalId ? +mPortalId : 0;
        sData.BusinessId = mBusinessId ? mBusinessId : 0;
        sData.AppManualId = mid;
        sData.AppManualFolderId = model.activeNodeId;
        sData.Title = model.item.checkList.title;
        sData.IsScoreIndex = model.item.checkList.scoreIndex;
        sData.Status = model.item.checkList.status; // === 'active' ? 1 : 2;
        sData.CreatedBy = BaseServices.UserId;

        return this.http
            .post(
                `${environment.BASE_URL}/manual/${mid}/checklist/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // update checklist
    saveChecklist(mid:any, checklistId:any, model:any, mPortalId:any, mBusinessId:any, modifiedBy:any) {
        let sData = `[${JSON.stringify(model)}]`;
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/manual/${mid}/checklist/item/updateAll/${checklistId}/${modifiedBy}`,
                JSON.stringify(sData),
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Rename checklist
    renameChecklist(mid:any, model:any) {
        let sData = {
            Id: +model.item.data.nodeId,
            Title: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/checklist/rename/${
                sData.Id
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete checklist
    deleteChecklist(mid:any, did:any) {
        return this.http
            .delete(
                `${environment.BASE_URL}/manual/${mid}/checklist/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }


    deleteSelectedChecklist(data:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/0/checklist/DeleteSelectedChecklist`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add Document
    createDocument(mid:any, model:any) { }

    // update checklist
    UpdateDocument(mid:any, model:any) { }

    // Rename checklist
    renameDocument(mid:any, model:any) {
        let sData = {
            Id: +model.item.data.nodeId,
            Title: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/document/rename/${
                sData.Id
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete document
    deleteDocument(mid:any, did:any) {
        return this.http
            .delete(
                `${environment.BASE_URL}/manual/${mid}/document/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleDocument(data:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/0/document/deleteSelectedDoc`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete lar
    deleteLar(mid:any, did:any) {
        return this.http
            .delete(
                `${environment.BASE_URL}/manual/${mid}/law-regulations/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    getCommonManualListApplication(applicationId: number) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetCommonAssignManualByApplicationLevel/${applicationId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    GetCommonAssignManualByPortalLevel(portalId: number) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetCommonAssignManualByPortalLevel/${portalId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    GetUnassignedManualByPortalLevel(applicationId: number, portalId: number) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetUnassignedManualByPortalLevel/${applicationId}/${portalId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    GetUnassignedManualByBusinessLevel(
        applicationId: number,
        businessId: number
    ) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetUnassignedManualByBusinessLevel/${applicationId}/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    GetUnassignedManualByBusinessLevelByPortalLogin(
        portalId: number,
        businessId: number
    ) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetUnassignedManualByBusinessLevelByPortalLogin/${portalId}/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    GetAssignedManualforPortal(portalId: number) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetAssignedManualforPortal/${portalId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    GetAssignedManualforBusiness(businessId: number) {
        return this.http
            .get(
                `${
                environment.BASE_URL
                }/assignmanual/GetAssignedManualforBusiness/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    AddAssignManualsToPortal(manualList: ManualView[]) {
        return this.http
            .post(
                `${environment.BASE_URL}/assignmanual/AddAssignManualToPortal/`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    AddAssignManualToBusiness(manualList: ManualView[]) {
        return this.http
            .post(
                `${
                environment.BASE_URL
                }/assignmanual/AddAssignManualToBusiness/`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    UpdatePortalAssignedManuals(manualList: ManualView[]) {
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/assignmanual/UpdatePortalAssignedManuals/`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    UpdateBusinessAssignedManuals(manualList: ManualView[]) {
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/assignmanual/UpdateBusinessAssignedManuals/`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    UpdatePortalCommonManuals(manualList: ManualView[]) {
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/assignmanual/UpdatePortalCommonManuals/`,
                manualList,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deletePortalAssignedManual(manualDetails: ManualView) {
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/assignmanual/deletePortalManuals/`,
                manualDetails,
                BaseServices.headerOption
            )
            .map(result => result);
    }


    deleteBusinessAssignedManual(manualDetails: ManualView) {
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/assignmanual/deleteBusinessManuals/`,
                manualDetails,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    moveNode(mid:any, model:any) {
        let sData:any;
        switch (model.to.parent.documentType) {
            case 'article':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                let result: any =this.http
                .put(
                    `${
                    environment.BASE_URL
                    }/manual/${mid}/article/DragAndDrop/applicationlevel`,
                    sData,
                    BaseServices.headerOption
                )
                .map(result => result)
                .catch(error => BaseServices.handleError(error));
                return result;
                    break;
            case 'checklist':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                return this.http
                    .put(
                        `${
                        environment.BASE_URL
                        }/manual/${mid}/checklist/DragAndDrop/applicationlevel`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));
                    break;
            case 'document':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                return this.http
                    .put(
                        `${
                        environment.BASE_URL
                        }/manual/${mid}/document/DragAndDrop/applicationlevel`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map(result => result)
                    .catch(error => BaseServices.handleError(error));
                    break;
            default:
                break;
        }
    }

    getChecklistTemplates(mid:any) {
        return this.http
            .get<any[]>(
                environment.BASE_URL +
                '/manual/' +
                mid +
                '/checklist/checkListForTemplate/' +
                this.getUser('roleKey') +
                '/' +
                this.getUser('appBiz'),
                BaseServices.headerOption
            )
    }

    getMergeFieldData(
        appId: number,
        mid: number,
        portalId: number,
        businessId: number,
        projectId: number
    ): Observable<TreeData[]> {
        return this.http
            .get<any>(
                `${
                environment.BASE_URL
                }/manual/${mid}/article/mergeFields/${appId}/${portalId}/${businessId}/${projectId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateBusinessTreeObject(mid: number, manualTreeObject:any) {
        let sData = JSON.stringify(manualTreeObject);

        sData = JSON.stringify(sData);

        let businessId: number = +BaseServices.BusinessId;
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/manual/${mid}/tree/updateBusinessTreeObject/${businessId}/${+BaseServices.UserId}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Update manual checkbox status
    updateManualCheckboxstatus(mid:any, data:any, appId:any, businessId:any, portalId:any) {
        let sData = {
            Id: +data.nodeId,
            Status: +data.status,
            DocumentType: data.documentType.toLowerCase(),
            FileType: data.type.toLowerCase(),
            IsDefault: data.isDefault,
            ManualId: mid,
            AppId: appId,
            BusinessId: businessId,
            PortalId: portalId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/tree/manualPermission/${
                BaseServices.UserId
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // get articletext status
    getArticleTextStatus(aData: ArticleDetails, mid: number) {
    // getArticleTextStatus(aData: any, mid: number) {
        return this.http
            .put(
                `${environment.BASE_URL}/manual/${mid}/article/articleTextStatus`,
                aData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Update modified by and modified on for manual document 
    updateManualDocument(mid:any, fileId:any, userId:any) {
        let data = {};
        return this.http.
            put(
                `${environment.BASE_URL}/manual/${mid}/document/updateDocument/${fileId}/${userId}`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    CreateEditDocumentVersion(document:any) {
        let varsionDto = {
            Id: 0,
            AppManualDocumentId: document.Id,
            Path: document.Path,
            RevisionNo: 1,
            RevisionComment: 'Saveing As',
            Status: '1',
            CreatedBy: BaseServices.UserId,
            ModifiedBy: BaseServices.UserId
        };
        return this.http
            .post(
                `${environment.BASE_URL}/manual/document/SaveCopyversion`,
                varsionDto,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    createDocumentVersion(document:any) {
        let varsionDto = {
            Id: 0,
            AppManualDocumentId: document.item.id,
            Path: document.item.path,
            RevisionNo: 1,
            RevisionComment: 'Saveing As',
            Status: '1',
            CreatedBy: BaseServices.UserId,
            ModifiedBy: BaseServices.UserId
        };
        return this.http
            .post(
                `${environment.BASE_URL}/manual/document/version`,
                varsionDto,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getManualCoverImage(
        mid: number,
        appId: number,
        portalId: number,
        businessId: number
    ): Observable<TreeData[]> {
        return this.http
            .get<any>(
                `${
                environment.BASE_URL
                }/manuals/coverImage/${mid}/${appId}/${portalId}/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }


    createEditCopyofDocument(document:any) {
        this.setWorkingModule();
        let documentDto = {
            FeatureId: 0,
            ExisitingFilePath: document.item.path,
            IsManual: this.featureName === 'Manual' ? true : false
        };
        return this.http
            .post(
                `${environment.BASE_URL}/file/doc-save-as`,
                documentDto,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    createCopyofDocument(document:any) {
        this.setWorkingModule();
        let documentDto = {
            ApplicationId: +BaseServices.ApplicationId
                ? +BaseServices.ApplicationId
                : 0,
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            FeatureId: 0,
            ExisitingFilePath: document.item.data.path,
            IsManual: this.featureName === 'Manual' ? true : false
        };
        return this.http
            .post(
                `${environment.BASE_URL}/file/doc-save-as`,
                documentDto,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateDocument(document:any, mid:any) {
        return this.http
            .put(
                `${
                environment.BASE_URL
                }/manual/${mid}/document/update/filepath`,
                document,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // Private
    getUser(need:any) {
        let result = {
            roleKey: 0,
            appBiz: 0
        };

        switch (BaseServices.UserRole) {
            case 'Admin':
                result.roleKey = 1;
                result.appBiz = +BaseServices.ApplicationId;
                break;
            case 'Portal':
                result.roleKey = 2;
                result.appBiz = +BaseServices.PortalId;
                break;
            case 'Editor':
                result.roleKey = 3;
                result.appBiz = +BaseServices.BusinessId;
                break;

            default:
                result.roleKey = 3;
                result.appBiz = +BaseServices.BusinessId;
                break;
        }

        if (need === 'roleKey') {
            return result.roleKey;
        } else if (need === 'appBiz') {
            let val:any;
            val = result.appBiz;
            return val;
        }
    }

    setWorkingModule() {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')!
        );
        this.featureName = this.workingModule.name;
    }

    getBusinessFeatureManual(businessId: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/manualbusinessfeature/status/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getPortalFeatureManual(portalId: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/manualportalfeature/status/${portalId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getBusinessMode(businessId: number) {
        return this.http
            .get<any>(
                `${environment.BASE_URL}/manuals/businessMode/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getAdminUser() {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/Adminusers/`,
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    /**
     *
     * @param businessId
     * @param manualId
     */
    getApprovalNoticeForManuals(businessId: number, manualId: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/manuals/approvalNoticeForManuals/${businessId}/${manualId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    /**
     *
     * @param approveDetails
     */
    approveBusinessArticle(approveDetails: any) {
        let result: ManualView;
        return this.http
            .post(
                `${environment.BASE_URL}/manual/${approveDetails.ManualId}/article/approvedArticleStatus`,
                approveDetails,
                BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }
    getManualReviewStatement(uId: number, mId: number, bId: Number, lId: any) {
        return this.http.post<any>(environment.BASE_URL + '/manuals/ReviewStatement/' + uId + '/'
            + mId + '/' + bId + '/' + lId, BaseServices.headerOption)
    }
}
