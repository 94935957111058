<div class="main-content">
  <div class="main-heading">
    <span translate>EMPLOYEE</span>
  </div>
  <div class="panel-tabs">
    <nav>
      <ul class="nav nav-pills">
        <li [routerLinkActive]="['active']">
          <a translate [routerLink]="['./customer-employee-login']"
            >CUSTOMER_LOGIN</a
          >
        </li>
        <li [routerLinkActive]="['active']">
          <a translate [routerLink]="['./customer-employee']">CUSTOMER_LIST</a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="card">
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
