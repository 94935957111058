<div class="row mb-2" *ngFor="let data of viewData">
    <div *ngIf="typeofproperty(data.value) === 'object'; then arrayList else default"></div>
    <ng-template #default>
        <div class="col-3 text-right font-weight-bold">{{data.label | translate}}:</div>
        <div class="col-9">
            <span *ngIf="checkBool(data.value)">
                {{data.value === true ? 'Yes': 'No'}}
            </span>
            <img *ngIf="matchPath(data.value) != null" src="{{data.value}}" width="120" height="140" alt="">
            <span *ngIf="matchPath(data.value) == null">
                {{data.value | translate}}
            </span>
        </div>

    </ng-template>
    <ng-template #arrayList>
        <div class="col-3 text-right font-weight-bold" *ngIf="staticArray.isArray(data.value)">
            {{data.label | translate}}:
        </div>
        <div class="col-9">
            <div *ngIf="staticArray.isArray(data.value)">
                <p>
                    <span *ngFor="let item of data.value;let i = ikndex">
                        <span *ngIf="i > 0 && i < data?.value?.length">
                        </span>{{item.Name | capitalize}},
                    </span>
                </p>
            </div>
        </div>
        <div *ngIf="typeofproperty(data.value) === 'object' && !staticArray.isArray(data.value)">
            <div class="row" *ngFor="let item of data">
                <div class="col-3 text-right font-weight-bold">{{item.key.toUpperCase() | translate}}:</div>
                <div class="col-9">
                    <span *ngIf="checkBool(item.value); else dataField">
                        {{item.value? 'Yes':'No'}}
                    </span>
                    <ng-template #dataField>
                        {{item.value}}
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-template>
</div>