import { BaseServices } from 'src/app/kuba/kuba.services';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: 'editorcontacts.component.html'
})
export class EditorContactsComponent implements OnInit {
    isGuest = true;
    constructor() {}

    ngOnInit() {
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
        }
    }
}
