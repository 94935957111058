import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChecklistCompleteEdit } from './../models/index';
import { HelperService } from 'src/app/_services/helper.service';
import { SelectItem } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';

@Component({
    templateUrl: 'checklist-complete-general.component.html',
    selector: 'complete-checklist-general'
})


export class ChecklistCompleteGeneral {
    completeGeneralForm: FormGroup;
    @Input() checklistCompleteDetails = new ChecklistCompleteEdit();
    @Input() responsiblePersonList: SelectItem[];
    @Output() onCompleteGeneralCheckList = new EventEmitter<number>();
    responsible: SelectItem[];

    responsiblePerson: number;
    currentDate: string;
    constructor(
        private formBuilder: FormBuilder
    ) {
        this.currentDate = HelperService.formatDate(Date());
        this.completeGeneralForm = this.formBuilder.group({
            title: ['', Validators.required],
            participants: [''],
            responsiblePerson: [null, Validators.required]
        });
    }


    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.responsible = this.responsiblePersonList;
        let checkGeneralEdit = {
            title: this.checklistCompleteDetails.Title,
            participants: this.checklistCompleteDetails.Participants,
            responsiblePerson: this.checklistCompleteDetails.ResponsiblePerson == null ?
                BaseServices.UserId : this.checklistCompleteDetails.ResponsiblePerson
        };
        (<FormGroup>this.completeGeneralForm).setValue(checkGeneralEdit, {
            onlySelf: true
        });
    }
   

    saveCompleteGeneral() {
        this.onCompleteGeneralCheckList.emit(1)
    }
}