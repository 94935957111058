<div class="tab-content">
    <form [formGroup]="measureProtocolComments">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group" *ngIf="measureProtocolComments.controls['Comments']">
                    <label for="Commetns">
                        <span translate>COMMENTS</span>
                    </label>
                    <div>
                        <textarea formControlName="Comments" id="Comments" [(ngModel)]='sign' class="form-control"></textarea>
                    </div>
                </div>
                <button class="btn btn-outline-secondary" (click)="signature()">
                    <span translate>SIGN_TS</span>
                </button>
            </div>
        </div>
        <div class="action-btns-wrapper text-left">
            <button type="button" class="btn btn-primary" (click)="saveChanges()">
                <span translate>SAVE</span>
            </button>
        </div>
    </form>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425" acceptLabel = "{{'SWITCH_YES'|translate}}" rejectLabel = "{{'SWITCH_NO'|translate}}"></p-confirmDialog>