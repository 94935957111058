<div class="tab-content">
  <div class="page-title">
    <a [routerLink]="['./../../../../../vehicle/list']" class="btn btn-icon">
      <span class="icon ic-xs icon-back"></span>
    </a>
    <span translate>VEHICLE_CONTROL</span>
    <span class="page-actions">
      <a [routerLink]="['./../edit', 0]" class="btn btn-success">
        <span class="icon ic-sm icon-add"></span>
        <span translate>CHECKPOINT</span>
      </a>
      <button
        type="button"
        class="btn btn-primary"
        (click)="completeChecklist()"
        translate
      >
        COMPLETE_VEHICLE_CONTROL
      </button>
    </span>
  </div>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      [value]="controlList"
      #dt
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="table-options">
            <div class="pull-right">
              <kuba-export
                [reportData]="dt.filteredValue || dt.value"
                [additionalData]="additionalData"
              >
              </kuba-export>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "CHECKPOINT_NAME" | translate }}</th>
          <th>{{ "DESCRIPTION" | translate }}</th>
          <th>{{ "CREATED_ON" | translate }}</th>
          <th>{{ "CREATED_BY" | translate }}</th>
          <th>{{ "EDITED_ON" | translate }}</th>
          <th>{{ "EDITED_BY" | translate }}</th>
          <th>{{ "OPTIONS" | translate }}</th>
        </tr>
        <tr>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($any($event.target)?.value, 'Title', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-vehiclecontrol>
        <tr>
          <td>
            <a [routerLink]="['./../edit', vehiclecontrol.Id]">{{
              vehiclecontrol.Title
            }}</a>
          </td>
          <td>
            <span>{{ vehiclecontrol.Description }}</span>
          </td>
          <td>
            <span>{{ vehiclecontrol.CreatedOn | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ vehiclecontrol.CreatedName }}</span>
          </td>
          <td>
            <span>{{ vehiclecontrol.ModifiedOn | date : "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <span>{{ vehiclecontrol.ModifiedName }}</span>
          </td>
          <td class="col-button">
            <button
              type="button"
              class="btn btn-icon"
              title="{{ 'DELETE' | translate }}"
              (click)="deleteControl(vehiclecontrol.Id)"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="7">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
