import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { InternalControl } from '../models/internal-control';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InternalControlServices {
    constructor(private http: HttpClient) {}

    // List all InternalControl
    getAll(Id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/internalcontrol/getall/' + Id,
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    list(Id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/internalcontrol/getall/' + Id,
                BaseServices.headerOption
            )
            .map(result => result);
    }



    getInternalControlById(Id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/internalcontrol/' + Id,
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    add(data) {
        return this.http
            .post(
                environment.BASE_URL + '/internalcontrol/save',
                data,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    update(data: InternalControl) {
        return this.http
            .put(
                environment.BASE_URL + '/internalcontrol/update',
                data,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    deleteControl(Id) {
        return this.http
            .delete(
                environment.BASE_URL + '/internalcontrol/delete/' + Id,
                BaseServices.headerOption
            )
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }

    getInternalControlFeatures(businessId: number) {
        let featureId: number;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        featureId = workingModule.id;
        return this.http
            .get(
                environment.BASE_URL + '/business/' + businessId,
                BaseServices.headerOption
            )
            .map((result: any) => {
                let feature = result.Features;
                let internalcontrolAdditionalFeature = _.find(
                    JSON.parse(feature),
                    { id: featureId, access: true }
                );
                let res:any = internalcontrolAdditionalFeature;
                return res;
            });
    }

    getInternalByUser(Id: number) {
        return this.http.get(environment.BASE_URL + '/internalcontrol/getuserIC/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInternalName(Id: number){
        return this.http.get(environment.BASE_URL + '/internalcontrol/name/' + Id, BaseServices.headerOption).map((result: any) => result);
    }

}
