export class ManualChecklistModel {
    Id: number;
    AppManualId: number;
    AppManualFolderId: number;
    Title: string;
    IsScoreIndex: boolean;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    manualTitle?: string;
    ApplicationId: number;
    PortalId: number;
    BusinessId: number;
    Items?: ManualChecklistSection[];
}

export class ManualChecklistSection {
    Id: number;
    AppManualChecklistId: number;
    ParentItemId: number;
    Title: string;
    Position: number;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    SubItems: ManualChecklistSection[];
}

export class ManualChecklistItemUpdate {
    title: string;
    score: boolean;
    userId: number;
    parentItems: ParentItem[];
}

export class ParentItem {
    parentItemId: number;
    parentId: number;
    parentItemPoint: number;
    parentItemTitle: string;
    parentItemStatus: number
    childItems: ChildItem[];
}

export class ChildItem {
    childItemId: number;
    parentId?: number;
    childItemPoint: number;
    isView: number;
    childItemTitle: string;
}
