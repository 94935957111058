import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FeatureKey } from 'src/app/_models';
import { Feature } from 'src/app/kuba/features/models/features-base.model';
import { FoodSafetyServices } from '../../services/foodsafety.services';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';


@Component({
    selector: 'foodsafety-view',
    templateUrl: 'foodsafety-view.component.html'
})

export class FoodsafetyViewComponent implements OnInit {
    pageTitle: string;
    zoneId:any;
    enabledAdditionalFeatures: any;
    additionalFeatures: any;
    fsId: number;
    initialPage: any;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private foodSafetyservices: FoodSafetyServices) {
        this.fsId = this.activatedRoute.snapshot.params['id'];
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;

        this.subscriptions.push(this.foodSafetyservices.getFSZoneName(+this.fsId).subscribe(res =>{
            this.pageTitle = res[0].ZoneName;
            this.zoneId = res[0].Id;
            sessionStorage.setItem('ZoneName',this.pageTitle);
            sessionStorage.setItem('ZoneId',this.zoneId);
        }));
        if(allAdditionalFeatures != null){
            this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
        }
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x => x.id !== 164);

        this.enabledAdditionalFeatures = allAdditionalFeatures
            .filter(element => (element.checked || element.IsDefault));
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
            .filter(element =>
                (element.id !== FeatureKey.SJA_CHECKLIST)); // removing SJA checklist frature which are not required for navigation
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
            .filter(element =>
                (element.id !== FeatureKey.TIME_LOG));
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x => x.id !== 142); // Hide details tab #KW-276
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x => x.id !== FeatureKey.CLEANING) // removing checklist
        let index = _.findIndex(this.enabledAdditionalFeatures, ['translationKey', 'DEVIATION']);
        if(index >= 0) {
            this.enabledAdditionalFeatures[index].translationKey = 'DEVIATIONS';
        }
        let index1 = _.findIndex(this.enabledAdditionalFeatures, ['translationKey', 'DAILY_TASK'])
        if(index1 >= 0 ){
            this.enabledAdditionalFeatures[index1].translationKey = 'DAILY_TASKS';
        }
        if (this.enabledAdditionalFeatures.length > 0) {
            this.route.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.fsId }],
                { relativeTo: this.activatedRoute });
        }
    }

    ngOnInit() { }

    BackToZone(){
        let foodSafety = this.activatedRoute.snapshot.data['list'];
        if(foodSafety.length == 1){
           sessionStorage.setItem('checkParent',this.activatedRoute.snapshot.data['parent']);
           this.route.navigate(['../../list'],  { relativeTo: this.activatedRoute });
        }
        this.route.navigate(['../../list'],  { relativeTo: this.activatedRoute });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
