<div class="main-content">
  <div class="main-heading">
    <span>
      <span translate>ZONE</span>
    </span>
    <span *ngIf="isFSRights" class="page-actions text-right">
      <a [routerLink]="['./../edit/0']" class="btn btn-success">
        <span class="icon ic-sm icon-add"></span>
        <span>
          <span translate>NEW</span>
        </span>
      </a>
    </span>
  </div>
  <div class="panel-tabs">
    <nav>
      <ul class="nav nav-pills">
        <li [routerLinkActive]="['active']" *ngIf="isFSRights">
          <a [routerLink]="['./all']" translate>ALL</a>
        </li>
        <li [routerLinkActive]="['active']" *ngIf="hideFSRights">
          <a [routerLink]="['./own']" translate>FOR_YOU</a>
        </li>
      </ul>
    </nav>
  </div>

  <router-outlet></router-outlet>
  <
</div>
