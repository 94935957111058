
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KubaServices } from './../../kuba.services';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'manual-tab',
    template: `<p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>`
})

export class ManualTabsComponent implements OnInit {
    @Output() onTabClick = new EventEmitter<string>();
    items: MenuItem[];
    activeItem: MenuItem;
    private subscriptions: Subscription[] = [];
    private language: string = this.translator.currentLang ? this.translator.currentLang : 'en';
    constructor(private translator: TranslateService, private kubaServices: KubaServices) {
        this.subscriptions.push(this.kubaServices.getLanguage$.subscribe(
            lang => {
                this.language = lang;
            }));

            this.subscriptions.push(this.translator.stream('MANUAL_TAB').subscribe(val => {
                this.items = [
                    { label: val.COMMON, icon: 'pi pi-book', command: (event) => this.menuChange('common', event.item) },
                    { label: val.PORTAL, icon: 'pi pi-book', command: (event) => this.menuChange('portal', event.item) },
                    { label: val.BUSINESS, icon: 'pi pi-book', command: (event) => this.menuChange('business', event.item) }
                ];
            }));
    }


    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let languageId = parseInt(sessionStorage.getItem('languageId')!);
        let CultureInfo = 'en-GB';
        switch (languageId) {
            case 1:
                CultureInfo = 'en-GB';
                break;
            case 2:
                CultureInfo = 'no-NO';
                break;
            case 3:
                CultureInfo = 'sv-SE';
                break;
            case 4:
                CultureInfo = 'pl-PL';
                break;
        }
        this.activeItem = this.items[0];
    }

    menuChange(type: string, item: any) {
        this.activeItem = item;
        this.onTabClick.emit(type);
        
    }

    BindList(CultureInfo: string) {

        this.items = [];
        if (CultureInfo === 'no-NO') {
            this.items = [
                { label: 'Felles', icon: 'pi pi-book', command: (event) => this.menuChange('common', event.item) },
                { label: 'Portal', icon: 'pi pi-book', command: (event) => this.menuChange('portal', event.item) },
                { label: 'Arbeid', icon: 'pi pi-book', command: (event) => this.menuChange('business', event.item) }
            ];
        } else {
            this.items = [
                { label: 'Common', icon: 'pi pi-book', command: (event) => this.menuChange('common', event.item) },
                { label: 'Portal', icon: 'pi pi-book', command: (event) => this.menuChange('portal', event.item) },
                { label: 'Business', icon: 'pi pi-book', command: (event) => this.menuChange('business', event.item) }
            ];
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
