<div *ngIf="treeData.id">
    <div class="tree-node-title">
        <span>{{treeData.name}}</span>
        <span class="search-tree-nodes">
            <a class="btn btn-secondary btn-icon" href="javascript:void(0);" (click)="filterSearch();"><i
                    class="pi pi-search" aria-hidden="true"></i></a>
        </span>
    </div>
    <div class="tree-search-block" *ngIf="treeSearch">
        <input type="text" class="form-control" (keyup)="onKey(filterTree.value)" #filterTree
            placeholder="Search by title" />
    </div>
    <tree-root *ngIf="treeData.data" [nodes]="treeData.data" [focused]="false" (moveNode)="onNodeDrop($event)"
        [options]="optionsTreeNode" #tree class="tree-view">
        <ng-template #treeNodeTemplate let-node="node" let-index="index">
            <p-triStateCheckbox (onChange)="check( node, $event)" *ngIf="node.data.isAccess"
                [hidden]="!treeData.isEditMode" [(ngModel)]="node.data.checked"></p-triStateCheckbox>
            <span class="folder-expanded" *ngIf="!node.data.isFileType"><i class="icon ic-sm icon-open"></i></span>
            <span class="folder-collapsed" *ngIf="!node.data.isFileType"><i class="icon ic-sm icon-folder"></i></span>
            <span class="file-type" *ngIf="node.data.isFileType && node.data.documentTypeId == 1"><i
                    class="pi pi-file-o"></i></span>
            <span class="treeNode" (click)="nodeSelect(node);">{{ node.data.title }}</span>
            <span class="pull-right">{{ childrenCount(node) }}</span>
        </ng-template>
    </tree-root>
    <p-contextMenu #displayContext></p-contextMenu>
    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
</div>
<toaster-component [toast]="toast"></toaster-component>
<p-dialog header={{headerActiontext}} *ngIf="showDialog" [(visible)]="showDialog" [modal]="true" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="renameForm" (ngSubmit)="saveContextAction()">
        <div class="form-group">
            <label for="title">Title<span class="required">*</span>
                <control-messages [control]="renameForm.controls['title']"></control-messages>
            </label>
            <input type="text" formControlName="title" id="title" autofocus pInputText class="form-control" />
        </div>
        <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="submit" pButton [disabled]="!isValidTitle || !renameForm.valid || !renameForm.dirty"
                [label]="actionName"></button>
            <button type="button" pButton (click)="showDialog = false" label="Cancel"
                class="ui-button-warning"></button>
        </div>
    </form>
</p-dialog>