export class MeasureProtocolList {
    Number: string;
    Name: string;
    CreatedOn: Date;
    ModifiedOn: number;
    Status: string;
    CreatedName: string;
    ModifiedName: string;
}

export class CompleteMeasureProtocolList {
    Number: string;
    Name: string;
    KundeELId: number;
    MeasureProtocolId: number;
    Status: number;
    IsComplete: string;
    Path: string;
    DoneOn: Date;
    CreatedBy: string;
    ModifiedBy: string;
    CreatedOn: Date;
    ModifiedOn: Date;
    CreatedName: string;
    ModifiedName: string;
}

export class MPClientUsers {
    MPId: number;
    ClientId: number;
    ClientUserId: number;
    Name: string;
    Id: number;
}

export class MP {
    Id: number;
    Name: string;
    Number: number;
    ClientId: number;
    KundeELId: number;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    FeatureId: number;
}

export class InstrumentList {
    Id: number;
    InstrumentTypeId: number;
    InstrumentModelId: number;
    MeasureProtocolId: number;
    MeasureProtocolCompleteId: number;
    Status: number;
    CreatedBy: string;
    ModifiedBy: string;
    CreatedOn: number;
    ModifiedOn: number;
    CreatedName: string;
    ModifiedName: string;
}

export class CMP {
    Id: number;
    Name: string;
    Number: number;
    MeasureProtocolId: number;
    KundeELId: number;
    Status: number;
    DoneOn: Date;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    FeatureId: number;
    BuildingId: number;
    InspectionId: number;
    DistributionId: number;
    Path: string;
}
export class CMPInstrument {
    MeasureProtocolCompleteId: number;
    InstrumentId: number;
}

export class MPCompletePreview {
    Id: number;
    Number: string;
    Name: string;
    InstrumentList: any;
    VisualControl: any;
    EarthLeakageList: any;
    PowerLeakageList: any;
    TempratureList: any;
}

