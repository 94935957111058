import { ApplicationService } from './../services/application.service';
import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'application',
    templateUrl: 'applicationList.component.html'
})

// TODO: to be implemented
export class ApplicationListComponent implements OnInit {
    consumptiondata: any;
    constructor(private applicationService: ApplicationService) { }

    ngOnInit() {
    }

    confirm() {}
}
