import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { BaseServices } from './../../kuba.services';
import { ActivatedRoute } from '@angular/router';
import { ContactServices } from './../services/contact.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'customer-contact-login',
  templateUrl: 'customerContactLogin.component.html'
})

export class CustomerContactLoginComponent implements OnInit {
  custContacts: any;
  routerLink: string;
  businessId: number;
  userId: number;
  additionalData: any;
  functions: SelectItem[];
  roles: SelectItem[];
  private subscriptions: Subscription[] = [];
  @ViewChild('contactsTable',{static:false}) contactsTable: Table;

  constructor(
    private contactsSvc: ContactServices,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
    this.additionalData = {
      fileName: val.CUSTOMER_CONTACT_LOGIN,
      header: 'Customer Contact Login',
      businessId: BaseServices.BusinessId,
      cultureInfo: BaseServices.userCultureInfo(),
      columnNames: [
        { title: 'Name', dataKey: 'Name' },
        { title: 'Cellular Phone', dataKey: 'Telephone' },
        { title: 'Email Id ', dataKey: 'Emailid' },
        { title: 'Function ', dataKey: 'ContactFunctionalArea' },
        { title: 'Status ', dataKey: 'Status' },
        { title: 'Company Name ', dataKey: 'ContactWorkLocation.Company' }
      ]
    };
  }));

  }



  ngOnInit() {
    document.querySelector("body").classList.remove("opened");
    this.subscriptions.push(this.translate.stream('USER_ROLE_DROPDOWN').subscribe(val => {
      this.roles = [];
      this.roles.push(
          { label: val.ALL, value: null },
          { label: val.ADMIN, value: 'Admin' },
          { label: val.PORTAL, value: 'Portal' },
          { label: val.EDITOR, value: 'Editor' },
          { label: val.USER, value: 'User' },
          { label: val.GUEST, value: 'Guest' }
      );
  }));
  this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
      this.functions = [];
      this.functions.push(
        { label: val.SELECT, value: null }
      );
    }));
    let functions = this.activatedRoute.snapshot.data['function'];
    if (functions) {
      functions.forEach((element: any) => {
        this.functions.push({ label: element.Name, value: element.Id });
      });
    }
    this.custContacts = this.activatedRoute.snapshot.data['list'];
    if (this.custContacts) {
      this.custContacts.forEach((element:any) => {
        this.businessId = element.BusinessId;
        this.userId = element.Id;
      });
    }
  }

  onRoleChanged(e: any, roleDropdown: any) {
    this.contactsTable.filter(
      roleDropdown.selectedOption.label,
      'RoleName',
      'contains'
    );
    if (e.value == null) {
      this.contactsTable.reset();
    }
  }
  onFunctionChanged(e: any, functionDropdown: any) {
    this.contactsTable.filter(
      functionDropdown.selectedOption.value,
      'ContactFunctionalAreaId',
      'equals'
    );
    if (e.value == null) {
      this.contactsTable.reset();
    }
  }
  exportPdf() { }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
