import {
    HelpSupportListResolver,
    HelpsupportEditResolver,
    HelpSupportUserListResolver
} from './services/help-support.resolver.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpSupportListComponent } from './components/help-supportList.component';
import { CreateHelpSupportComponent } from './components/Createhelp-support.component';
import { HelpSupportUserListComponent } from './components/help-supportUserList.component';
import { HelpSupportTabsComponent } from './components/help-support-tabs.component';
import { HelpSupportComponent } from './help-support.component';
import { HelpSupportServices } from './services/help-support.service';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MyDatePickerModule } from 'mydatepicker';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ContextMenuModule} from 'primeng/contextmenu';
import {TabMenuModule} from 'primeng/tabmenu';
import {MenuModule} from 'primeng/menu';
import { AppSharedModule } from '../shared/appShared.module';
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ReactiveFormsModule,
        ContextMenuModule,
        MenuModule,
        ConfirmDialogModule,
        DropdownModule,
        TranslateModule,
        MyDatePickerModule,
        DialogModule,
        TabMenuModule,
        AppSharedModule
    ],
    declarations: [HelpSupportComponent, HelpSupportListComponent, CreateHelpSupportComponent, HelpSupportUserListComponent, HelpSupportTabsComponent],

    providers: [
        HelpSupportServices,
        ConfirmationService,
        HelpSupportListResolver,
        HelpsupportEditResolver,
        HelpSupportUserListResolver
    ],
})
export class HelpSupportModule {}
