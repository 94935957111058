import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RegistrationList } from 'src/app/kuba/Registration/models/registration-list';
import { Observable } from 'rxjs';

import { RegistrationServices } from '../services/registration.service';


@Injectable()
export class RegistrationListResolver implements Resolve<RegistrationList[]> {
  constructor(private backend: RegistrationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<RegistrationList[]> {
    return this.backend.getAllByEach(+route.params['Id'], route.parent.parent.data['parent']);
  }
}

@Injectable()
export class RegistrationEditResolver implements Resolve<RegistrationList> {
  constructor(private backend: RegistrationServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getRegisterById(+route.params['rid']);
  }
}

@Injectable()
export class TypeOfRegistrationListResolver implements Resolve<any[]> {
  constructor(private backend: RegistrationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getAllByRegType(+route.parent.parent.params['id'], route.parent.parent.data['parent']);
  }
}
