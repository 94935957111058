import { RiskAnalysisTopicCachedResolver } from './../follow-ups/services/risk-analysis.resolver.service';
import { EmployeeContactUserResolver } from './../users/services/user.resolver.service';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import {
    SafteyJobEditResolver, SafteyJobListResolver,
    TypeOfIssueResolver, TopicResolver, SJAApprovalNoticeListResolver
} from './services/jobanalysis.resolver.services';
import { SafetyjobComponent } from './jobanalysis.component';
import { EditComponent } from './components/safety-job-analysis-edit.component';
import { NewComponent } from './components/new-safety-job-analysis.component';
import { ListComponent } from './components/safety-job-list.component';
export const safetyjobRoutes: Route[] = [{
    path: 'safetyjobs',
    component: SafetyjobComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: ListComponent,
            canActivate: [AuthGuard],
            resolve: {
                list: SafteyJobListResolver,
                issue: TypeOfIssueResolver,
                topic: RiskAnalysisTopicCachedResolver
            }
        },
        {
            path: 'new',
            component: NewComponent,
            canActivate: [AuthGuard],
            resolve: {
                issue: TypeOfIssueResolver,
                topic: RiskAnalysisTopicCachedResolver
            }
        },
        {
            path: 'edit/:jobId',
            component: EditComponent,
            canActivate: [AuthGuard],
            resolve: {
                edit: SafteyJobEditResolver,
                issue: TypeOfIssueResolver,
                topic: RiskAnalysisTopicCachedResolver,
                user: EmployeeContactUserResolver
            }
        },


    ]
}];


