import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BaseServices } from './../../kuba.services';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { EmployeeContact } from './../models/employeecontact';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { CacheHandlerService } from '../../cached.services';

@Component({
  selector: 'customer-employee-login',
  templateUrl: 'customer-employee-login.component.html',
})
export class CustomerEmployeeLoginComponent implements OnInit {
  @ViewChild('contactsTable', { static: false }) contactsTable: Table;
  employeeWithlogin: EmployeeContact[];
  selectedEmployees: any = [];
  businesses: SelectItem[];
  roles: SelectItem[];
  additionalData: any;
  category: SelectItem[];
  remoteWindow: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private cacheHandlerService: CacheHandlerService
  ) {
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.EMPLOYEE_LOGIN_LIST,
          header: 'Employee Login List',
          businessId: 0,
          cultureInfo: 'en-GB',
          columnNames: [
            { title: 'Employee Name', dataKey: 'Name' },
            { title: 'Category', dataKey: 'Category' },
            { title: 'Business Name', dataKey: 'BusinessName' },
            { title: 'User Name', dataKey: 'UserName' },
            { title: 'Mobile', dataKey: 'Mobile' },
            { title: 'Role Name', datakey: 'RoleName' },
          ],
        };
      })
    );
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.employeeWithlogin = <EmployeeContact[]>(
      this.route.snapshot.data['employee']
    );
    let businesses = this.route.snapshot.data['business'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.businesses = [];
        this.businesses.push({ label: val.SELECT, value: null });
      })
    );
    if (businesses) {
      businesses.forEach((result) => {
        this.businesses.push({ label: result.CompanyName, value: result.Id });
      });
    }
    let list = this.route.snapshot.data['category'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.category = [];
        this.category.push({ label: val.SELECT, value: null });
      })
    );
    if (list) {
      list.forEach((result) => {
        this.category.push({ label: result.Name, value: result.Id });
      });
    }

    this.subscriptions.push(
      this.translate.stream('ADD_ROLE').subscribe((val) => {
        this.roles = [];
        this.roles.push(
          { label: val.ALL, value: null },
          { label: val.USER, value: 5 },
          { label: val.GUEST, value: 4 },
          { label: val.EDITOR, value: 3 }
        );
      })
    );
    this.additionalData.businessId = BaseServices.BusinessId;
    this.additionalData.cultureInfo = BaseServices.userCultureInfo();
  }
  onBusinessChanged(e: any, businessDropdown: any) {
    if (e.value) {
      this.contactsTable.filter(
        businessDropdown.selectedOption.value,
        'BusinessId',
        'equals'
      );
    } else {
      this.contactsTable.reset();
    }
  }
  onRoleChanged(e: any, roleDropdown: any) {
    if (e.value) {
      this.contactsTable.filter(
        roleDropdown.selectedOption.value,
        'RoleId',
        'equals'
      );
    } else {
      this.contactsTable.reset();
    }
  }
  onCategoryChanged(e: any, categoryDropdown: any) {
    if (e.value) {
      this.contactsTable.filter(
        categoryDropdown.selectedOption.label,
        'Category',
        'contains'
      );
    } else {
      this.contactsTable.reset();
    }
  }
  exportPdf() {
    let employeeIds: any = [];
    if (this.selectedEmployees.length > 0) {
      this.selectedEmployees.forEach((employee: any) => {
        employeeIds.push(employee.Id);
      });

      let pdfSetting = new PdfSetting();
      pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
      pdfSetting.businessName = '';
      pdfSetting.pageHeader = 'Customer employee details';
      let columns = [
        { title: 'Name', dataKey: 'Name' },
        { title: 'Category', dataKey: 'Category' },
        { title: 'Business Name', dataKey: 'BusinessName' },
        { title: 'UserName', dataKey: 'UserName' },
        { title: 'Mobile', dataKey: 'Mobile' },
        { title: 'RoleName', dataKey: 'RoleName' },
      ];
      HelperService.generatePdf(
        this.selectedEmployees,
        columns,
        'Customer employee list with login',
        pdfSetting,
        'l'
      );
    } else {
      alert('Please select atleast one employee.');
    }
  }

  //   Clicking the Employee name opens the employee in a new window
  openEmployeeInNewWindow(employee: any) {
    if (this.remoteWindow) {
      this.remoteWindow.close();
    }
    this.cacheHandlerService.clearCachedData();
    let businessSetting = {
      BusinessName: employee.BusinessName,
      BusinessLogo: employee.BusinessLogo,
    };
    sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
    sessionStorage.setItem('RModeBusinessId', employee.BusinessId);
    let windowOrigin = window.location.origin;
    let windowControl =
      'location=yes,width=' +
      screen.availWidth +
      ',height=' +
      screen.availHeight +
      ',scrollbars=yes,status=yes';

    let baseURL = BaseServices.userBase.BaseUrl;
    this.remoteWindow = window.open(
      windowOrigin +
        '/' +
        baseURL +
        '/app/business/' +
        employee.businessId +
        '/employee/edit/' +
        employee.Id,
      'Remote',
      windowControl
    )!;
  }

  //   Clicking the Business name opens the business in a new window
  openBusinessInNewWindow(employee: any) {
    if (this.remoteWindow) {
      this.remoteWindow.close();
    }
    this.cacheHandlerService.clearCachedData();
    let businessSetting = {
      BusinessName: employee.BusinessName,
      BusinessLogo: employee.BusinessLogo,
    };
    sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
    sessionStorage.setItem('RModeBusinessId', employee.BusinessId);
    let windowOrigin = window.location.origin;
    let windowControl =
      'location=yes,width=' +
      screen.availWidth +
      ',height=' +
      screen.availHeight +
      ',scrollbars=yes,status=yes';

    let baseURL = BaseServices.userBase.BaseUrl;
    this.remoteWindow = window.open(
      windowOrigin +
        '/' +
        baseURL +
        '/app/business/' +
        employee.businessId +
        '/dashboard',
      'Remote',
      windowControl
    )!;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
