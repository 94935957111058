import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KubaControlComponent } from './kuba-control.component';
import { KubaControlListComponent } from './components/kuba-control-list/kuba-control-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { DailyTaskComponent } from './components/daily-task/daily-task.component';
import { 
  FoodSafetyEditResolver, 
  FoodsafetyFeaturesResolver, 
  FoodSafetyListByUserResolver, 
  FoodSafetyListResolver, 
  FoodSafetyZonesResolver, 
  FSAuditDateListResolver, 
  FSAuditListResolver, 
  FSDailyTaskListResolver, 
  KubaControlListResolver, 
  ZoneListResolver,ObjectTypeListResolver, 
  ToDoListResolver,
  ToDoDocumentResolver
 } from './services/kubacontrol.resolver';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { NgxPaginationModule } from 'ngx-pagination';
import { EcplChecklistViewerModule } from 'src/app/shared/ecpl-checklist-viewer';
import { EcplImageViewerModule } from 'src/app/shared/ecpl-image-viewer';
import { EcplOnlyofficeViewerModule } from 'src/app/shared/ecpl-onlyoffice-viewer';
import { EcplPdfViewerModule } from 'src/app/shared/ecpl-pdf-viewer';
import { KubaControlService } from './services/kuba-control.service';
import { CreateKubaControlComponent } from './components/create-kuba-control/create-kuba-control.component';
import { KubaControlViewComponent } from './components/kuba-control-view/kuba-control-view.component';
import { KCTaskManagerListComponent } from './components/task-manager/kctask-manager-list.component';
import { KCTaskManagerFormComponent } from './components/task-manager/kc-task-manager-form.component';
import { KCAuditComponentComponent } from './components/audit/kc-audit-component.component';
import { DepartmentCachedListResolver } from '../follow-ups/services/activities.resolver.service';
import { 
  EmployeeContactUserResolver, 
  EmployeeContactUserSubscriptionResolver 
} from '../users/services/user.resolver.service';
import { KubaControlHeaderComponent } from './components/kuba-control-list/kuba-control-header.component';
import { ExecutionPersonList, FollowUpPersonList, RepeatitionList, TaskManagerEditResolver } from '../task-manager/services/task.resolver.service';
import { AllDepartmentResolver } from '../follow-ups/services/deviation.resolver.service';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ToDoListComponent } from './components/to-do-list/to-do-list.component';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { AppSharedModule } from '../shared/appShared.module';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    SharedModule,
    DialogModule,
    KubaExportModule,
    RouterModule,    
    BrowserModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    CalendarModule,
    EditorModule,
    ContextMenuModule,
    MenuModule,
    DialogModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputTextModule,
    TranslateModule,
    AppSharedModule,
    RouterModule,
    ButtonModule,
    EcplTabNavigationModule,
    EcplDocumentExplorerModule,
    InputSwitchModule,
    NgxPaginationModule,
    EcplChecklistViewerModule,
    EcplImageViewerModule,
    EcplOnlyofficeViewerModule,
    EcplPdfViewerModule,
    EcplDocumentTreeModule,
    TooltipModule,
    MyDatePickerModule
  ],
  declarations: [
    KubaControlComponent, 
    KubaControlListComponent, 
    DailyTaskComponent, 
    CreateKubaControlComponent, 
    KubaControlViewComponent, 
    KCTaskManagerListComponent, 
    KCTaskManagerFormComponent, 
    KCAuditComponentComponent, 
    KubaControlHeaderComponent,
    ToDoListComponent
  ],
  providers:[
    KubaControlService,
    KubaControlListResolver,
    FoodSafetyEditResolver,
    ZoneListResolver,
    ObjectTypeListResolver,
    DepartmentCachedListResolver,
    EmployeeContactUserSubscriptionResolver,
    EmployeeContactUserResolver,
    FoodSafetyZonesResolver,
    FoodSafetyListResolver,
    FoodSafetyListByUserResolver,
    FoodsafetyFeaturesResolver,
    FSDailyTaskListResolver,
    TaskManagerEditResolver,
    RepeatitionList,
    ExecutionPersonList,
    FollowUpPersonList,
    AllDepartmentResolver,
    FSAuditListResolver,
    FSAuditDateListResolver,
    ToDoListResolver,
    ToDoDocumentResolver
  ]
})
export class KubaControlModule { }
