<div class="card-body">
    <div class="page-title">
        <span translate>RISK_ASSESSMENT_INSTALLATION</span>
    </div>
    <div class="row">
        <div *ngIf="!editMode" class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="12"><span translate>RISK_ASSESSMENT_INSTALLATION</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>VOLTAGE_SYSTEM</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;width: 25%;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.VoltageSystemIT === true">
                            </p-checkbox>
                            <label translate>VOLTAGE_IT</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.VoltageSystemTNCS === true">
                            </p-checkbox>
                            <label translate>VOLTAGE_TNCS</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.VoltageSystemTNS === true">
                            </p-checkbox>
                            <label translate>VOLTAGE_TNS</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.VoltageSystemTT === true">
                            </p-checkbox>
                            <label translate>VOLTAGE_TT</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>DATA_DISTRIBUTION_NETWORK</label></td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.DistributionDataAvail === 1">
                            </p-checkbox>
                            <label translate>Foreligger fra E-verket</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.DistributionDataAvail === 2">
                            </p-checkbox>
                            <label translate>Foreligger ikke E-verket</label>
                        </td>
                        <td>
                            <label translate>IK-maks</label>
                        </td>
                        <td>
                            <span>{{riskAssessment.IK_Max}}</span>
                        </td>
                        <td>
                            <label translate>[kA]</label>
                        </td>
                        <td>
                            <label translate>IK-min</label>
                        </td>
                        <td>
                            <span>{{riskAssessment.IK_Min}}</span>
                        </td>
                        <td>
                            <label translate>[kA]</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>EARTH_ELECTRODE_TYPE</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.EarthType === 1">
                            </p-checkbox>
                            <label translate>Spyd</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.EarthType === 2">
                            </p-checkbox>
                            <label translate>Wire</label>
                        </td>
                        <td colspan="1" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.EarthType === 3">
                            </p-checkbox>
                            <label translate>Annet</label>
                        </td>
                        <td colspan="5">
                            <span>{{riskAssessment.OtherEarthType}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>EARTH_FAULT_MONITORING</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.EarthMonitorType1 === true">
                            </p-checkbox>
                            <label translate>Jordfeilvarsler</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.EarthMonitorType2 === true">
                            </p-checkbox>
                            <label translate>Jordfeilbryter</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.EarthMonitorType3 === true">
                            </p-checkbox>
                            <label translate>Isolasjonsovervaking</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>OVERVOLTAGE_PROTECTION</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.OverVoltageProtection === 1">
                            </p-checkbox>
                            <label translate>Ja</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.OverVoltageProtection === 2">
                            </p-checkbox>
                            <label translate>Nei</label>
                        </td>
                        <td colspan="1" style="border: none;">
                            <label translate>Kommentar</label>
                        </td>
                        <td colspan="5">
                            <span>{{riskAssessment.OverVoltageComment}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>BOARDS_FUSE_CABINETS</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.BoardFuseType === 1">
                            </p-checkbox>
                            <label translate>For sakkyndig betjening</label>
                        </td>
                        <td colspan="9" style="border: none;">
                            <p-checkbox binary="true" disabled [ngModel]="riskAssessment.BoardFuseType === 2">
                            </p-checkbox>
                            <label translate>For usakkyndig betjening</label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="12"><label translate>OTHER_MATTERS_CONSIDERED</label></th>
                    </tr>
                    <tr>
                        <th colspan="6"><label translate>ELEMENT_CONSIDERED</label></th>
                        <th colspan="6"><label translate>COMMENTS</label></th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of elementsConsidered">
                        <td colspan="6" style="width:46%">
                            <span>{{item.elements}}</span>
                        </td>
                        <td colspan="6" style="width:46%">
                            <span>{{item.comments}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="editMode" class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="12"><span translate>RISK_ASSESSMENT_INSTALLATION</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>VOLTAGE_SYSTEM</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;width: 25%;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.VoltageSystemIT === true"
                              (onChange)="currentTypemultiChecked($event,1)">
                            </p-checkbox>
                            <label translate>VOLTAGE_IT</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.VoltageSystemTNCS === true"
                              (onChange)="currentTypemultiChecked($event,2)">
                            </p-checkbox>
                            <label translate>VOLTAGE_TNCS</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.VoltageSystemTNS === true"
                              (onChange)="currentTypemultiChecked($event,3)">
                            </p-checkbox>
                            <label translate>VOLTAGE_TNS</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.VoltageSystemTT === true"
                              (onChange)="currentTypemultiChecked($event,4)">
                            </p-checkbox>
                            <label translate>VOLTAGE_TT</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>DATA_DISTRIBUTION_NETWORK</label></td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.DistributionDataAvail === 1"
                              (onChange)="currentTypeChecked(1, 1)">
                            </p-checkbox>
                            <label translate>Foreligger fra E-verket</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.DistributionDataAvail === 2"
                              (onChange)="currentTypeChecked(1, 2)">
                            </p-checkbox>
                            <label translate>Foreligger ikke E-verket</label>
                        </td>
                        <td>
                            <label translate>IK-maks</label>
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="riskAssessment.IK_Max" />
                        </td>
                        <td>
                            <label translate>[kA]</label>
                        </td>
                        <td>
                            <label translate>IK-min</label>
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="riskAssessment.IK_Min" />
                        </td>
                        <td>
                            <label translate>[kA]</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>EARTH_ELECTRODE_TYPE</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.EarthType === 1"
                              (onChange)="currentTypeChecked(2, 1)">
                            </p-checkbox>
                            <label translate>Spyd</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.EarthType === 2"
                              (onChange)="currentTypeChecked(2, 2)">
                            </p-checkbox>
                            <label translate>Wire</label>
                        </td>
                        <td colspan="1" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.EarthType === 3"
                              (onChange)="currentTypeChecked(2, 3)">
                            </p-checkbox>
                            <label translate>Annet</label>
                        </td>
                        <td colspan="5">
                            <input style="max-width: 600px;width: 100%;" type="text" class="form-control"
                              [(ngModel)]="riskAssessment.OtherEarthType" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>EARTH_FAULT_MONITORING</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.EarthMonitorType1 === true"
                              (onChange)="currentTypemultiChecked($event,5)">
                            </p-checkbox>
                            <label translate>Jordfeilvarsler</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.EarthMonitorType2 === true"
                              (onChange)="currentTypemultiChecked($event,6)">
                            </p-checkbox>
                            <label translate>Jordfeilbryter</label>
                        </td>
                        <td colspan="6" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.EarthMonitorType3 === true"
                              (onChange)="currentTypemultiChecked($event,7)">
                            </p-checkbox>
                            <label translate>Isolasjonsovervaking</label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>OVERVOLTAGE_PROTECTION</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.OverVoltageProtection === 1"
                              (onChange)="currentTypeChecked(3, 1)">
                            </p-checkbox>
                            <label translate>Ja</label>
                        </td>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.OverVoltageProtection === 2"
                              (onChange)="currentTypeChecked(3, 2)">
                            </p-checkbox>
                            <label translate>Nei</label>
                        </td>
                        <td colspan="1" style="border: none;">
                            <label translate>Kommentar</label>
                        </td>
                        <td colspan="5">
                            <input style="max-width: 600px;width: 100%;" type="text" class="form-control"
                              [(ngModel)]="riskAssessment.OverVoltageComment" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="bg-light-grey"><label translate>BOARDS_FUSE_CABINETS</label></td>
                    </tr>
                    <tr>
                        <td colspan="3" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.BoardFuseType === 1"
                              (onChange)="currentTypeChecked(4, 1)">
                            </p-checkbox>
                            <label translate>For sakkyndig betjening</label>
                        </td>
                        <td colspan="9" style="border: none;">
                            <p-checkbox binary="true" [ngModel]="riskAssessment.BoardFuseType === 2"
                              (onChange)="currentTypeChecked(4, 2)">
                            </p-checkbox>
                            <label translate>For usakkyndig betjening</label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="12"><label translate>OTHER_MATTERS_CONSIDERED</label></th>
                    </tr>
                    <tr>
                        <th colspan="2"></th>
                        <th colspan="5"><label translate>ELEMENT_CONSIDERED</label></th>
                        <th colspan="5"><label translate>COMMENTS</label></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of elementsConsidered">
                        <td colspan="2" class="col-button" style="width:8%">
                            <button type="button" class="btn btn-icon" (click)="addItem(item)" title="Add Row">
                                <span class="icon ic-sm icon-add"></span>
                            </button>
                            <button *ngIf="elementsConsidered.length > 1" type="button" class="btn btn-icon"
                              (click)="deleteItem(item)" title="Delete Row">
                                <span class="icon ic-sm icon-trash"></span>
                            </button>
                        </td>
                        <td colspan="5" style="width:46%;padding: 0px;">
                            <input style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text"
                              class="form-control" autoResize="autoResize" [(ngModel)]="item.elements" />
                        </td>
                        <td colspan="5" style="width:46%;padding: 0px;">
                            <input style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text"
                              class="form-control" autoResize="autoResize" [(ngModel)]="item.comments" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th><span translate>SYSTEM_WITH_PROPOSED_METHODS_TO_SAFEGUARD_SECURITY</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="editMode">
                        <td style="padding: 0px;"><textarea type="text" class="form-control" autoResize="autoResize"
                              [(ngModel)]="riskAssessment.Summary"></textarea></td>
                    </tr>
                    <tr *ngIf="!editMode">
                        <td><span>{{riskAssessment.Summary}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<toaster-component></toaster-component>