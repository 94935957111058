import { NotificationListResolver } from './services/notification.resolver.service';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import { NotificationComponent } from './notifications.component';

import { NotificationListComponent } from './components/notification-list/notificationList.component';
export const notificationsRoutes: Route[] = [
    {
        path: 'notification/list',
        component: NotificationListComponent,
        resolve: {
            list: NotificationListResolver,
        }
    },
];