import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { SuggestionComponent } from './suggestions.component';
import { BoxComponent } from './components/box.component';
export const suggestionRoutes: Route[] =
    [
        {
            path: 'suggestion',
            component: BoxComponent
        },
        {
            path: 'box',
            component: SuggestionComponent
        }

    ];