import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseServices } from 'src/app/kuba/kuba.services';
import { ValidationService } from './../../shared/services/validation.service';
import { HelperService } from './../../../_services/helper.service';
import { ConsumptionsService } from '../services/consumptions.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ConsumptionType, UnitOfConsumption } from './../models/consumptions';
import { UserRole } from '../models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'consumption-list',
    templateUrl: 'consumption-list.component.html'
})
export class ConsumptionListComponent implements OnInit {
    //#region variable

    @ViewChild('consumptionTable',{static: false}) consumptionTable: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    showConsumptionBarChart = true;
    data: any;
    consumptionPopUp: FormGroup;
    consumptionSearch: FormGroup;
    consumptiondata: any;
    isVisible = true;
    isReport = true;
    selectedConsumption = new UnitOfConsumption();
    isConsumptionTypeExsist = false;
    consumptionTypes: SelectItem[];
    businessName: SelectItem[];
    isBusiness = true;
    consumptionType = new ConsumptionType();
    editConsumptions: any = [];
    addType = false;
    viewType = false;
    consumptionId: number;
    totalRecords = 0;
    additionalData: any;
    isEditor = true;
    showFilter = false;
    consumptionTypeFilter: any = null;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param consumptionService {ConsumptionsService}
     * @param route {ActivatedRoute}
     * @param router {Router}
     * @param confirmationService {ConfirmationService}
     * @param _fb {FormBuilder}
     */
    constructor(
        private consumptionService: ConsumptionsService,
        private route: ActivatedRoute,
        public router: Router,
        private confirmationService: ConfirmationService,
        private _fb: FormBuilder,
        private translate: TranslateService
    ) {
        if (BaseServices.BusinessId) {
            this.consumptiondata = this.route.snapshot.data['list'];
        }
        this.data = {
            labels: [],
            datasets: [
                {
                    label: '',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: []
                }
            ]
        };
        this.consumptionPopUp = this._fb.group({
            Name: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ]
        });
        this.consumptionSearch = this._fb.group({
            type: ['']
        });
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => { 
        this.additionalData = {
            fileName: val.CONSUMPTION_LIST,
            header: 'Consumption List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Reading', dataKey: 'TodaysNumber' },
                { title: 'Number Used', dataKey: 'EstimatedNumber' },
                { title: 'Price', dataKey: 'Price' },
            ]
        };
    }));
    }

    //#endregion

    //#region page-event

    /**
     * bind consumption type dropdown
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let business = this.route.snapshot.data['business'];
        this.businessName = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.businessName = [];
            this.businessName.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (business) {
            business.forEach((list: any) => {
                this.businessName.push({
                    label: list.Name,
                    value: list.Id
                });
            });
        }

        if (+BaseServices.roleId !== UserRole.ADMIN || +BaseServices.roleId !== UserRole.PORTAL) {
            this.consumptiondata = this.route.snapshot.data['list'];
            this.consumptionTypes = [];
            this.editConsumptions = [];
            let consumptionTypes = this.route.snapshot.data['consumptionType'];
            this.subscriptions.push(this.translate.stream("CONSUMP_ALL").subscribe(val => {
                this.consumptionTypes = [];
                this.consumptionTypes.push({ label: val.ALL, value: null });
            }));
            if (consumptionTypes) {
                consumptionTypes.forEach((consumpType: any) => {
                    this.consumptionTypes.push({
                        label: consumpType.Name,
                        value: consumpType.Id
                    });
                    this.editConsumptions.push({
                        Name: consumpType.Name,
                        Id: consumpType.Id
                    });
                });
            }
        }
        switch (BaseServices.UserRole) {
            case 'Admin':
                this.isVisible = false;
                this.isBusiness = false;
                this.isReport = true;
                break;
            case 'Editor':
                this.isVisible = true;
                this.isBusiness = true;
                this.isReport = true;
                break;
            case 'User':
                this.isVisible = false;
                this.isBusiness = true;
                this.isReport = true;
                break;
            case 'Guest':
                this.isVisible = false;
                this.isBusiness = true;
                this.isReport = false;
                break;
            case 'Portal':
                this.isVisible = false;
                this.isBusiness = false;
                this.isReport = true;
                break;
        }
        this.isEditor = +BaseServices.roleId === UserRole.EDITOR ? true : false;
        this.globalConsumptionType();
    }

    reloadCosumption() {
        this.subscriptions.push(this.consumptionService.list().subscribe((list) => {
            if (list) {
                this.consumptiondata = list;
            }
        }))
    }
    //#endregion

    //#region control-event

    /**
     * delete consumption based on Id
     * @param id {number}
     */
    confirm(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.consumptionService.delete(id).subscribe((result) => {
                    this.toasterComponent.callToastDlt();
                    this.reloadCosumption();
                }))
            }
        });
    }
    /**
     * filtering list based on consumption type
     * @param event {any}
     * @param consumptionType {any}
     */
    onConsumptionTypeChanged(consumptionType: any) {
        sessionStorage.setItem('search_FollowUpCon_ConsumptioType', consumptionType.value);
        if (consumptionType.value) {
            this.consumptionTable.filter(
                `${consumptionType.value}`,
                'ConsumptionTypeId',
                'equals'
            );
        } else {
            this.consumptionTable.reset();
        }
    }
    /**
     *
     * @param business
     */
    onBusinessChanged(event: any) {
        if (event.value) {
            let data = this.route.snapshot.data['list'];
            if (data && data.length > 0) {
                this.consumptiondata = data.filter((x:any) => x.BusinessId === +event.value)
            }
            this.subscriptions.push(this.consumptionService
                .getConsumptionsTypesByBusiness(event.value)
                .subscribe((consumptionTypes: any) => {
                    this.consumptionTypes = [];
                    this.editConsumptions = [];
                    this.consumptionTypes.push({ label: 'All', value: null });
                    if (consumptionTypes) {
                        consumptionTypes.forEach((consumpType: any) => {
                            this.consumptionTypes.push({
                                label: consumpType.Name,
                                value: consumpType.Id
                            });
                            this.editConsumptions.push({
                                Name: consumpType.Name,
                                Id: consumpType.Id
                            });
                        });
                    }
                }));
            this.consumptionTable.filter(
                `${event.value}`,
                'BusinessId',
                'equals'
            );
        } else {
            this.consumptionTable.reset();
        }
    }
    /**
     * editing consumption type
     * @param consumptionType {any}
     */
    editType(consumptionType: UnitOfConsumption) {
        this.consumptionId = consumptionType.Id!;
        let result = {
            Name: consumptionType.Name
        };
        (<FormGroup>this.consumptionPopUp).setValue(result, { onlySelf: true });
    }
    /**
     * save consumption Type
     */
    saveType() {
        let consumptionType = new UnitOfConsumption();
        consumptionType.Id = this.consumptionId ? this.consumptionId : 0;
        consumptionType.Name = this.consumptionPopUp.value.Name;
        consumptionType.BusinessId = BaseServices.BusinessId;
        consumptionType.Status = '1';
        if (this.consumptionId > 0) {
            this.subscriptions.push(this.consumptionService
                .updateConsumptionsType(consumptionType.Id, consumptionType)
                .subscribe(isTypeUpdated => {
                    if (isTypeUpdated) {
                        this.addType = false;
                        this.bindConsumptionTypeDropdown();
                        this.toasterComponent.callToast();
                        this.consumptionSearch
                            .get('type')!
                            .patchValue(isTypeUpdated.Id);
                        this.consumptionId = 0;
                        this.clearConsumptionType();
                    } else {
                        this.isConsumptionTypeExsist = true;
                    }
                }));
        } else {
            this.subscriptions.push(this.consumptionService
                .addConsumptionsType(consumptionType)
                .subscribe(isTypeAdded => {
                    if (isTypeAdded) {
                        this.addType = false;
                        this.bindConsumptionTypeDropdown();
                        this.toasterComponent.callToast();
                        this.consumptionSearch
                            .get('type')!
                            .patchValue(isTypeAdded.Id);
                        this.clearConsumptionType();
                    } else {
                        this.isConsumptionTypeExsist = true;
                    }
                }));
        }
        this.viewType = false;
    }
    /**
  * delete consumption type
  * @param consumptionType
  */
    deleteType(consumptionType:any) {
        let deleteTypeId = consumptionType.Id;
        let data = this.consumptiondata ? this.consumptiondata.filter((x:any) => x.ConsumptionTypeId === consumptionType.Id) : []
        if (data.length > 0) {
            alert('You cannot delete this Consumption type as it is being used');
        } else {
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.consumptionService.deleteConsumptionsType(deleteTypeId).subscribe(result => {
                        if (result) {
                            this.addType = false;
                            this.bindConsumptionTypeDropdown();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
                }
            });
        }


    }
    /**
     *consumption type popup reset
     */
    onBeforeConsumptionTypeDialogHide() {
        this.consumptionPopUp.reset();
    }
    // TODO : to be implemented
    showAll() { }

    // TODO : to be implemented
    exportPdf() { }

    //#endregion

    //#region method

    /**
     * create bar chart for consumption list
     */
    barChart() {
        this.showConsumptionBarChart = false;
        if (this.consumptiondata) {
            let dates: any = [];
            let data: any = [];
            this.consumptiondata.forEach((date: any) => {
                dates.push(HelperService.formatDate(date.Date));
                data.push(date.EstimatedNumber);
            });
            this.setChartData(dates, data);
        }
    }
    /**
     * chart creation
     * @param dates {any}
     * @param numbers {number}
     */
    setChartData(dates: any, numbers: number) {
        this.data.labels = dates;
        this.data.datasets[0].data = numbers;
    }
    /**
     * get no of days
     * @param data {any}
     * @param i {number}
     */
    setNoOfDays(data: any, i: number) {
        let daysCount = 0;
        if (i > 0) {
            let startDate = data[i - 1].Date;
            let endDate = data[i].Date;
            if (data[i].Date && data[i - 1].Date) {
                let date1 = new Date(startDate).toDateString();
                let date2 = new Date(endDate).toDateString();
                let timeDiff = Math.abs(
                    new Date(date2).getTime() - new Date(date1).getTime()
                );
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                daysCount = diffDays > 0 ? diffDays : 0;
            }
        }
        return daysCount;
    }
    /**
     * Calculation for price per day
     * @param data {any}
     * @param i {any}
     */
    pricePerDayCalculation(data: any, i: any) {
        let price = 0;
        let numberUsed = parseInt(data[i].EstimatedNumber, 10);
        let amount = parseInt(data[i].Price, 10);
        let days = this.setNoOfDays(data, i);
        if (days === 0) {
            price = amount * numberUsed;
        } else {
            let pricePerDay = numberUsed / days;
            price = pricePerDay * amount;
        }
        return price ? price.toFixed(2) : 0;
    }
    /**
     * get decimal number
     * @param data {any}
     */
    decimalCalculation(data: any, i: any) {
        if (data) {
            let number = data[i].Price;
            return number ? number.toFixed(2) : 0;
        }
        return 0;
    }
    /**
     * bind consumption type dropdown
     */
    bindConsumptionTypeDropdown() {
        this.subscriptions.push(this.consumptionService
            .getAllConsumptionsType()
            .subscribe((consumptionTypes: any) => {
                if (consumptionTypes) {
                    this.consumptionTypes = [];
                    this.consumptionTypes.push({
                        label: 'SELECT',
                        value: null
                    });
                    let typeOfConsumption = consumptionTypes;
                    typeOfConsumption.forEach((types: any) => {
                        this.consumptionTypes.push({
                            label: types.Name,
                            value: types.Id
                        });
                    });
                    this.editConsumptions = consumptionTypes;
                }
            }));
    }
    clearConsumptionType() {
        this.consumptionPopUp = this._fb.group({
            Name: ['']
        })
    }


    globalConsumptionType() {
        let consumptionType = sessionStorage.getItem('search_FollowUpCon_ConsumptioType');

        if (consumptionType && consumptionType !== 'null') {
            this.consumptionTypeFilter = consumptionType;
            this.consumptionTable.filter(consumptionType,
                'ConsumptionTypeId',
                'equals'
            )
        }
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
