import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { LoggedUser } from '../../../_models/';
import { BusinessServices } from './../../businesses/services/business.services';
import { BaseServices } from './../../kuba.services';
import { AdminUser } from './../models/adminuser';
import { AdminUserService } from './adminuser.service';

@Injectable()
export class AdminUserEditResolver implements Resolve<AdminUser> {
    constructor(private backend: AdminUserService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getAdminUserById(+route.params['uid']);
    }
}
@Injectable()
export class UserListByBusinessResolver implements Resolve<any> {
    constructor(private backend: BusinessServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getUsersByBusiness(BaseServices.BusinessId);
    }
}
@Injectable()
export class AdminUsersListResolver implements Resolve<any> {
    constructor(private backend: AdminUserService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getAdminCreatedUsers();
    }
}