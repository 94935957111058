<div class="tab-content">
    <form class="form" #f="ngForm" novalidate name="form">
        <div class="form-group">
            <label for="lblCreatedBy" class="col-form-label">
                <span translate></span>
            </label>
        </div>
        <div class="form-group">
            <label for="lblCreatedOn" class="col-form-label">
                <span translate></span>
            </label>
        </div>
        <div class="form-group">
            <label for="lblTime" class="col-form-label">
                <span translate></span>
            </label>
        </div>
    </form>
</div>