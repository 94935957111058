import { Execution } from './../../follow-ups/models/activities';

import { TaskPlanListDto } from './../../follow-ups/models/riskanalysis';
import { Business } from './../../accesslog/models/accesslog';
export class TaskPlan {
    RiskAnalysisId: number;
    ActivityId: number;
    ActivityEditDto: Activity;
    ActivitySchedule: ActivityScheduleDto;
}
export class Activity {
    Id: number;
    Title: string;
    BusinessDepartmentId: number;
    Status: string;
    ActivityScheduleId: number;
    ExecutionUserId: number;
    RiskFigure: number;
    FollowupUserId: number;
    Description: string;
    CompletedDate: Date;
    CreatedOn: string;
    BusinessId: number;
    IsMappedActivity: boolean;
    FeatureKey: number;
    ActivityAttachmentDto: ActivityAttachment[];
    ActivityScheduleDto: ActivityScheduleDto;


}
export class ActivityAttachment {
    Id: number;
    ActivityId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    Mapping: string;
    FileType: string;
    Status: string;
    CreatedBy: {
        Id: number;
        Name: string;
    };
    CreatedOn: string;
    ModifiedBy: {
        Id: number;
        Name: string;
    };
    ModifiedOn: string;
}
export class ActivityScheduleDto {
    Id: number;
    StartDate: Date;
    EndDate: string;  // modified by : Thereshan
    ScheduleRule: string;
    NotifyBeforeDeadLine: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}
export class TaskPlanList {
    Id: number;
    Title: string;
    Deadline: string;
    Date: string;
    Description: string;
    ResponsiblePerson: string;
    FollowupPerson: string;
    DocumentType: string;
    Goal: string;
    EstimatedCost: string;
    Status: string;
    ExecutionUserId: number;
    FollowupUserId: number;
    ExecutionUsers: Execution[];
    FollowupUsers: Execution[];
    ExecutionUserName: string;
    RepetitionId: number;
    ActivityScheduleId: number;
    NotifyBeforeDeadLine: number;
    NotifyBeforeFollowup: number;
    IsMappedActivity: boolean;
    BusinessId: number;
    ActivityAttachmentDto: ActivityAttachment[];
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    StatusText: string;
    ActivityScheduleDto: ActivityScheduleDto;
    ActivityNumber: number;
    Activity_Number_Prefix: string;
    FollowupUsersList: any;
    ExecutionUsersList: any;
    constructor() {
        this.ActivityScheduleDto = new ActivityScheduleDto();
    }
}
export class FeatureMapping {
    RiskAnalysisId: number;
    ActivityId: number;
    IsMappedActivity: boolean;
}
