<div class="main-content">
    <div class="main-heading">
        <strong><span translate>{{title}}</span><span>{{contractName}}</span></strong>
    </div>
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./info']" translate>CONTRACT_INFO</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./editcontent']" translate>EDIT_CONTENT</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>