import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { SupplierContact } from './../Models/supplierContact';
import { SupplierList } from './../Models/supplierList';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SupplierServices {

    constructor(private http: HttpClient) {

    }

    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', 'application/json');
    }

    getAll() {
        return this.http.get<any>(`${environment.BASE_URL}/supplierlist/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(result => result);
    }
    getActiveSuppliers() {
        return this.http.get<any>(`${environment.BASE_URL}/supplierdropdown/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(result => result);
    }
    add(supplier: SupplierList) {
        return this.http.post(environment.BASE_URL + '/supplier', supplier, BaseServices.headerOption)
            .map(result => result);
    }

    getById(id: number) {
        return this.http.get<any>(environment.BASE_URL + '/suppliers/' + id, BaseServices.headerOption)
            .map(result => (result) ? result : null)
    }

    update(supplier: SupplierList) {
        return this.http.put(environment.BASE_URL + '/supplier', supplier, BaseServices.headerOption)
            .map(result => result);
    }

    addSupplierContact(suppliercontact: SupplierContact) {
        return this.http.post(environment.BASE_URL + '/suppliercontact/suppliercontact', suppliercontact, BaseServices.headerOption)
            .map(result => result);
    }

    deleteSuppliers(ids: number[]) {
        return this.http.put(environment.BASE_URL + '/supplier/', ids, BaseServices.headerOption)
            .map(result => result)
            .catch(this.handleError);
    }

    getSupplierContact() {
        return this.http.get(environment.BASE_URL + '/suppliercontact/suppliercontacts', BaseServices.headerOption)
            .map(result => result);
    }

    getSupplierContactById(id: number) {
        return this.http.get(environment.BASE_URL + '/suppliercontact/suppliercontacts/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    getSupplierContactListById(supplierId: number) {
        return this.http.get<any>(environment.BASE_URL + '/suppliercontact/supplierscontact-list/' + supplierId,BaseServices.headerOption)
            .map(result => result);
    }
    getCountryCode() {
        return this.http.get(environment.BASE_URL + '/countrycode/countrycodes', BaseServices.headerOption)
            .map((result: any) => result);
    }
    updateSupplierContact(suppliercontact: SupplierContact) {
        return this.http.put(environment.BASE_URL + '/suppliercontact/suppliercontact', suppliercontact, BaseServices.headerOption)
            .map(result => result);
    }

    getContactType() {
        return this.http.get<any>(environment.BASE_URL + '/contacttypes/contactTypes', BaseServices.headerOption)
            .map(result => result);
    }

    deleteSupplierContact(id: number) {
        return this.http.delete(environment.BASE_URL + '/suppliercontact/suppliercontact/' + id, BaseServices.headerOption)
            .map(result => result)
            .catch(this.handleError);
    }

    statusUpdate(id:any, status:any) {
        return this.http.put(environment.BASE_URL + '/supplierstatus/' + id, status, BaseServices.headerOption)
            .map(result => result);
    }

    deleteMultipleSuppliers(ids:any) {
        return this.http.put(environment.BASE_URL + '/suppliers', ids, BaseServices.headerOption)
            .map(result => result);
    }
    checkSupplierName(name: any) {
        return this.http.get(environment.BASE_URL + '/checkSupplierName/' + name, BaseServices.headerOption)
            .map(result => result);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
