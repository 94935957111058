import { BaseServices } from './../../kuba.services';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SelectItem} from 'primeng/api';
import { ManualLARService } from './../services/manual-laws-regualtion.services';
import { ManualLAR } from './../models/index';
import { TreeView, TreeData } from '../../common/models/index';
import { Toast } from 'angular2-toaster';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'manual-laws-regulation',
    templateUrl: 'manual-laws-regulation.component.html'

})
export class ManualLARComponent implements OnInit {
    @Input('treeData') treeData: TreeView;
    @Input('nodeData') nodeData: TreeData;
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    manualLARList: ManualLAR[];
    manualLARS: ManualLAR[];
    manualLAR: ManualLAR;
    createLARForm: FormGroup;
    types: SelectItem[];
    isLaws: boolean = true;
    showDialog: boolean = false;
    selectedType: string;
    toast: Toast;
    private subscriptions: Subscription[] = [];
    constructor(
        private manualLARServices: ManualLARService,
        private _fb: FormBuilder,
    ) {
        this.types = [];
        this.types.push({ label: 'Laws', value: true });
        this.types.push({ label: 'Regualtion', value: false });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.selectedType = this.isLaws ? 'Laws' : 'Regulation';
         this.manualLARS = [];
         this.manualLARList = [];
         this.subscriptions.push(this.manualLARServices.getManualLAR(this.treeData.id, this.nodeData.levelId, this.nodeData.levelKey).subscribe(result => {
            if (result) {
                this.manualLARList = result;
                this.manualLARS = this.manualLARList.filter(x => x.IsLaws === this.isLaws);
            }
        }));
    }


    initLARForm(manualLARS?: ManualLAR): void {
        this.showDialog = true;
        let title = '';
        let url = '';
        let selectedType = 'Laws';

        if (manualLARS && manualLARS.Id > 0) {
            title = manualLARS.Title;
            url = manualLARS.Url;
            selectedType = manualLARS.IsLaws ? 'Laws' : 'Regulation';
        }
        this.manualLAR = new ManualLAR();
        if (manualLARS) {
            this.manualLAR = manualLARS;
        }

        this.createLARForm = this._fb.group({
            'title': new FormControl(title, Validators.required),
            'url': new FormControl(url, Validators.required),
            'selectedType': new FormControl(selectedType),
        });
    }

    manualLARSTypeFilter() {
        this.manualLARS = this.manualLARList.filter(x => x.IsLaws === this.isLaws);
    }

    saveLAR() {
        let manualLARSave = this.createLARForm.value;
        this.manualLAR.Title = manualLARSave.title;
        this.manualLAR.Url = manualLARSave.url;
        this.manualLAR.IsLaws = (manualLARSave.selectedType == 'Laws') ? true : false;
        if (this.manualLAR.Id > 0) {
            this.manualLAR.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.manualLARServices.update(this.manualLAR.Id, this.manualLAR).subscribe(result => {
                if (result) {
                    this.toast = this.popToastMessage('updated');
                    this.closeModal();
                }
            }));
        } else {
            this.manualLAR.AppManualId = this.nodeData.treeParentId;
            this.manualLAR.AppManualFolderId = this.nodeData.nodeId;
            this.manualLAR.LevelId = this.nodeData.levelId;
            this.manualLAR.LevelKey = this.nodeData.levelKey;
            this.manualLAR.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.manualLARServices.add(this.manualLAR).subscribe(result => {
                if (result) {
                    this.toast = this.popToastMessage('created');
                    this.closeModal();
                }
            }));
        }

    }

    deleteLAR(manualLARS: ManualLAR) {
        manualLARS.Status = 0;
        manualLARS.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(this.manualLARServices.update(manualLARS.Id, manualLARS).subscribe(result => {
            if (result) {
                this.toast = this.popToastMessage('deleted');
                this.toasterComponent.callToast();
                this.ngOnInit();
            }
        }));
    }

    closeModal() {
        this.showDialog = false;
        this.ngOnInit();
        this.toasterComponent.callToast();
    }

    popToastMessage(type: string): Toast {
        let body = `Data ${type} successfully`;
        var toast: Toast = {
            type: 'success',
            title: 'Laws and Regulations',
            body: body
        };
        return toast;
    }

    changeSwitch(manualLARS: ManualLAR) {
        manualLARS.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(this.manualLARServices.update(manualLARS.Id, manualLARS).subscribe(result => {
            if (result) {
                this.toast = this.popToastMessage('changed');
                this.toasterComponent.callToast();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}