import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { first } from 'rxjs-compat/operator/first';

@Injectable()
export class InitialServices {
    appSettings: any = [];
    stylePath: any;
    assetPath: string = environment.BASE_ASSET_PATH;
    private subscription: Subscription;

    constructor(
        private http: HttpClient,
        private router: Router,
        public translate: TranslateService,
        @Inject(APP_BASE_HREF) private baseHref: string
    ) { }

    createAuthorizationHeader(headers: HttpHeaders) {
        headers = headers.append('content-type', 'application/json');
    }

    appInitializing() {
        if (sessionStorage.getItem('appSettings') == null) {
            this.subscription = this.getAllApplication().pipe().subscribe(
                response => {
                    this.appSettings = response;
                    let hostName = window.location.hostname;
                    let PathName = this.baseHref.replace('/', '');
                    let settings: any = _.filter(this.appSettings, {
                        DomainUrl: hostName
                    });
                    sessionStorage.setItem(
                        'appSettings',
                        JSON.stringify(this.appSettings[0])
                    );

                },
            );
        }
        this.setApplicationLanguage();
    }

    setApplicationLanguage() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        if (sessionStorage.getItem('appSettings')) {
            let lang = JSON.parse(sessionStorage.getItem('appSettings'));
            this.translate.use(lang.Language.IsoCode);
        } else {
            let language = JSON.parse(localStorage.getItem('languageId'));
            if (language == 1) {
                this.translate.use('en');
            }
            else {
                this.translate.use('no');
            }
        }
    }

    // Retrives all the applications and set local storage with selected application
    getAllApplication() {
        let headers = new HttpHeaders();
        this.createAuthorizationHeader(headers);
        headers = headers.append('content-type', 'application/json');
        return this.http.get<any>(`${environment.BASE_URL}/applications`)
            .pipe(map(response => {
                return response;
            }));
    }

    getApplicationByHost(hostName:any) {
        let headers = new HttpHeaders();
        this.createAuthorizationHeader(headers);
        return this.http
            .get<HttpHeaderResponse>(environment.BASE_URL + '/applications', { headers: headers }).pipe(
                map((response: HttpHeaderResponse) => {
                    let res = response;
                    this.appSettings = _.filter(res, { DomainUrl: hostName });
                    if (this.appSettings) {
                        sessionStorage.setItem(
                            'appSettings',
                            JSON.stringify(this.appSettings)
                        );
                    }
                    return this.appSettings;
                })
            );
    }

    getApplicationByPath(pathName : any) {
        let headers = new HttpHeaders();
        this.createAuthorizationHeader(headers);
        return this.http
            .get<HttpHeaderResponse>(environment.BASE_URL + '/applications', { headers: headers }).pipe(
                map((response: HttpHeaderResponse) => {
                    let res = response;
                    this.appSettings = _.filter(res, { BaseUrl: pathName });
                    if (this.appSettings) {
                        sessionStorage.setItem(
                            'appSettings',
                            JSON.stringify(this.appSettings)
                        );
                    }
                    return this.appSettings;
                })
            )
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
