<div class="main-content">
  <div class="main-heading">
    <strong> {{checklistTitle}}<span> - {{projectTitle}}</span></strong>
  </div>
  <div class="card">
    <div class="card-body">
      <tabset #staticTabs>
        <tab heading="{{'STEP1_GENERAL'| translate}}">

          <!--Panel Loader-->
          <div *ngIf="showLoader" class="panel-loader">
            <span>
              <span class="loader-icon"></span>
              <span translate>PROCESSING</span>
            </span>
          </div>
          <!--Panel Loader-->

          <div class="tab-content">
            <div class="form-group">
              <label for="title">
                <span translate>TITLE</span>
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" [(ngModel)]="CompleteCheckList.Title" id="title" name="title" #title="ngModel"
                  required class="form-control" />
              </div>
              <div *ngIf="title.invalid && (title.dirty || title.touched)" class="alert alert-danger">
                <div *ngIf="title.errors['required']" translate>
                  REQUIRED
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>DATE</span>
              </label>
              <div>
                {{CompleteCheckList.InitDate | date:'dd-MM-yyyy'}}
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>RESPONSIBLE</span>
                <span class="required">*</span>
              </label>
              <div *ngIf="responsibleperson">
                <p-dropdown id="responsible" #responsible="ngModel" [options]="responsibleperson" required
                  [(ngModel)]="CompleteCheckList.ResponsibleId" [style]="{'width':'130px'}"></p-dropdown>

              </div>
            </div>
            <div class="form-group">
              <label for="location">
                <span translate>LOCATION</span>
              </label>
              <div>
                <input type="text" [(ngModel)]="CompleteCheckList.Location" id="location" name="location"
                  #location="ngModel" required class="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label>
                <span translate>PARTICIPANTS</span>
              </label>
              <div>
                <textarea [(ngModel)]="CompleteCheckList.Participants" id="participants"
                  class="form-control"></textarea>
              </div>
            </div>
            <div class="action-btns-wrapper">
              <button (click)="Back()" class="btn btn-outline-secondary">
                <span translate>BACK</span>
              </button>
              <button [disabled]="!title.valid || !CompleteCheckList.ResponsibleId"
                (click)="SaveFirstStepCompleteCheckList()" class="btn btn-primary">
                <span translate>SAVE_AND_NEXT </span>
              </button>
            </div>
          </div>
        </tab>
        <tab heading="{{'STEP2_CHECKLIST' | translate}}">
          <!--Panel Loader-->
          <div *ngIf="showLoader" class="panel-loader">
            <span>
              <span class="loader-icon"></span>
              <span translate>PROCESSING</span>
            </span>
          </div>
          <!--Panel Loader-->
          <div class="tab-content">
            <div *ngIf="CompleteChecklistEdit.Section">
              <div class="pull-right">
                <button class="btn text-primary" (click)="Saveforlater(true)"
                  pTooltip="{{'AUTOSAVE_ON_PRINT' | translate }}">
                  <span class="icon ic-sm icon-print"></span>
                  <span translate>PRINT_AS_PDF</span>
                </button>
              </div>
              <ng-template ngFor let-section let-headPoint="index" [ngForOf]="CompleteChecklistEdit.Section">
                <div class="sub-panel">
                  <div class="sub-panel-title">
                    <strong> {{headPoint+1}} {{section.Title}}</strong>
                  </div>
                  <div class="sub-panel-content">
                    <ng-template ngFor let-sectionitem let-i="index" [ngForOf]="section.Items">
                      <div class="sub-panel">
                        <div class="sub-panel-title"> {{headPoint+1}}. {{i+1}} {{sectionitem.Title}}</div>
                        <div class="sub-panel-content">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group-50_50">
                                <div *ngIf="isScoreIndex" class="form-group">
                                  <label>
                                    <span translate>SCORE</span>
                                  </label>
                                  <div *ngIf="scoreList">
                                    <p-dropdown [(ngModel)]="sectionitem.Score" [options]="scoreList"
                                      [style]="{'width':'80px'}"></p-dropdown>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>Status</label>
                                  <span class="required">*</span>
                                  <ng-template ngFor let-status [ngForOf]="sectionitem.Status">
                                    <div class="radio">
                                      <label>
                                        <input id="statusOptions[{{headPoint}}{{i}}]" name="options[{{headPoint}}{{i}}]"
                                          type="radio" [value]="status.value"
                                          (change)="setStatus(status, sectionitem.Status,sectionitem)"
                                          [checked]="status.value">
                                        <span *ngIf="status.Label=='Deviation'" translate>
                                          CREATE_DEVIATION
                                        </span>
                                        <span *ngIf="status.Label!='Deviation'" translate>
                                          {{status.Label}}
                                        </span>

                                      </label>
                                      <div *ngIf="status.Label === 'Deviation'  && status.value === true 
                                     && featureId == elFeatureKey && status.DeviationNumber !== null">
                                        <span translate>DEVIATIONNO </span> <span> : {{status.DeviationNumber}}</span>
                                      </div>
                                    </div>
                                  </ng-template>
                                  <ng-template ngFor let-refstatus [ngForOf]="sectionitem.Status">
                                    <div
                                      *ngIf="refstatus.Label === 'Deviation'  && refstatus.value === true  && featureId == elFeatureKey">
                                      <div class="form-group">
                                        <p-dropdown [(ngModel)]="sectionitem.ReferenceChecklist" [filter]="true"
                                          [options]="checkListReference" [style]="{'width':'160px'}">
                                        </p-dropdown>
                                        <br><br>
                                        <div class="form-group">
                                          <textarea readonly [(ngModel)]="sectionitem.ReferenceChecklist"
                                            class="form-control"></textarea>
                                        </div>

                                      </div>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                              <div class="form-group">
                                <textarea [(ngModel)]="sectionitem.Comments" id="statusComments"
                                  class="form-control"></textarea>
                              </div>
                              <div class="form-group">
                                <strong>{{sectionitem.Signature}}</strong>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <ecpl-document-explorer [style]="{'width':'160px','height':'100px'}"
                                  [uploaderConfig]="upConfig" [uploadedFiles]="sectionitem.Attachments"
                                  (saveChanges)="uploaderEvents($event, sectionitem)">
                                </ecpl-document-explorer>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="action-btns-wrapper">
              <button (click)="EnableFirstTab()" class="btn btn-outline-secondary">
                <span translate>PREVIOUS_STEP</span>
              </button>
              <button (click)="Saveforlater();" class="btn btn-primary">
                <span translate>SAVE_FOR_LATER</span>
              </button>
              <button (click)="SaveSecondStepCompleteCheckList()" class="btn btn-primary" [disabled]="checkValid()">
                <span translate> Save & Next</span>
              </button>
            </div>
          </div>
        </tab>
        <tab heading="{{'STEP3_FINISH' | translate}}">
          <!--Panel Loader-->
          <div *ngIf="showLoader" class="panel-loader">
            <span>
              <span class="loader-icon"></span>
              <span translate>PROCESSING</span>
            </span>
          </div>
          <!--Panel Loader-->
          <div class="tab-content">
            <div class="row">
              <div class="col-sm-6">
                <label>
                  <span translate>FOOD_SAFETY_APPROVAL</span>
                </label>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" [(ngModel)]="aproval">
                    <span translate>FOOD_SAFETY_CHECKLIST_COMPLETED</span>
                  </label>
                </div>
              </div>
              <div *ngIf="type != 'feature' && type != 'task-manager'" class="col-sm-6">
                <div class="form-group">
                  <label>
                    <span translate>SELECT_FOLDER_ARCHIVE_CHECKLIST</span>
                    <span>{{selectFolderName}}</span>
                  </label>
                </div>
                <div class="mtop-20">
                  <app-ecpl-document-tree *ngIf="treeNode" treeId="ArchiveDocumentsTree" [(ecplTreeData)]="treeNode"
                    (ecplNodeEvent)="catchEvent($event)" [treeType]="treeType">
                  </app-ecpl-document-tree>
                </div>
              </div>
              <div *ngIf="type === 'feature' || type === 'task-manager' " class="col-sm-6">
                <div class="form-group">
                  <label>
                    <span translate>FOOD_SAFETY_SELECT_FOLDER</span>
                    - <span>{{selectFeatureFolderName}}</span>
                  </label>
                </div>
                <div class="mtop-20">
                  <app-ecpl-document-tree *ngIf="featureTreeNode" treeId="ArchiveDocumentsTree"
                    [(ecplTreeData)]="featureTreeNode" (ecplNodeEvent)="onFeatureCatchEvent($event)"
                    [treeType]="treeType">
                  </app-ecpl-document-tree>
                </div>
              </div>

            </div>
            <div class="action-btns-wrapper">
              <button (click)="SaveAndCompleteChecklist()" [disabled]="isCheckBoxTicked()" class="btn btn-primary">
                <span translate>SAVE_AND_COMPLETE</span>
              </button>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog width="425" acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}">
</p-confirmDialog>