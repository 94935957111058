import { CountryCodeResolver } from './../employees/services/employeeWorkExperience.resolver.service';
import { BusinessUsersEditComponent } from './../businesses/components/business-user-edit.component';
import { UserRoleResolver } from './../users/services/user.resolver.service';
import { BusinessUserEditResolver } from './../businesses/services/business.resolver.service';
import { AdminUserListComponent } from './components/adminuser-list.component';
import { AdminUserEditComponent } from './components/adminuser-edit.component';
import { AdminUserAddComponent } from './components/new-adminuser.component';
import { AdminUserRightsComponent } from './components/adminuser-rights.component';
import { AdminUserEditResolver, UserListByBusinessResolver, AdminUsersListResolver } from './services/adminuser.resolver.service';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard, AdminTabGuard } from '../../_guards/index';
import { AdminUserComponent } from './adminuser.component';

export const AdminUserRoutes: Route[] = [{
    path: 'admin-user',
    component: AdminUserComponent,
    canActivate: [AdminTabGuard],
    children: [{
        path: 'list',
        component: AdminUserListComponent,
        resolve: {
            list: AdminUsersListResolver
        },
    },
    {
        path: 'new',
        component: AdminUserAddComponent,
        resolve: {
            countryCode: CountryCodeResolver
        }
    },
    {
        path: 'edit/:uid',
        component: AdminUserEditComponent,
        resolve: {
            userData: AdminUserEditResolver,
            countryCode: CountryCodeResolver
        },
    }, {
        path: 'rights',
        component: AdminUserRightsComponent
    },

    ]

}];

