import {
    Component,
    OnInit,
    AfterViewChecked,
    ChangeDetectorRef,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatePipe, Location } from '@angular/common';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { ArchiveServices } from 'src/app/kuba/archive/archive.services';
import { BaseServices, KubaServices } from 'src/app/kuba/kuba.services';
import { ElectroService } from '../services/electro.services';
import { ElectroInstallatioForms } from '../models/electrofeatures';
import { ElectroDocumentService } from '../services/electro-document.services';
import { ElectroCompleteChecklistEdit } from '../models/electrochecklistcompletion';
import { CourseOverviewComponent } from './installation/course-overview.component';
import { DistributionOverviewComponent } from './installation/distribution-overview.component';
import { DocumentAssessmentComponent } from './installation/document-assessment.component';
import { EmergencyLightingComponent } from './installation/emergency-lighting.component';
import { FilreAlarmComponent } from './installation/fire-alarm-system.component';
import { RiskAssessmentComponent } from './installation/risk-assessment.component';
import { ElectroInstallationService } from '../services/electro-installation.services';
import { ElectroCourseOverview } from '../models/electroinstallation';
import { CompleteChecklistStatus, CompleteChecklistItemSection } from '../../shared/models/complete-checklist'
import { CompleteCheckListServices } from '../../shared/services/complete-checklist.service'
import { ElectroDeviation } from '../models/electrodeviation';
import { Subscription } from 'rxjs';

class ElectroArchiveDocumentModel {
    Id: number;
    Title: string;
    OriginalFileName: string;
    Path: string;
    MimeType: string;
    Size: number;
    DocumentTypeId: number;
    FeatureFolderId: number;
    FeatureId: number;
    FeatureKey: number;
    Status: string;
    CreatedBy: number;
    ModifiedBy: number;
}

@Component({
    templateUrl: 'electro-archive.component.html'
})
export class ElectroArchiveComponent implements OnInit {

    @ViewChild('course',{static: false}) course: CourseOverviewComponent;
    @ViewChild('distribution',{static: false}) distribution: DistributionOverviewComponent;
    @ViewChild('documentAssessment',{static: false}) documentAssessment: DocumentAssessmentComponent;
    @ViewChild('emergencyLighting',{static: false}) emergencyLighting: EmergencyLightingComponent;
    @ViewChild('filreAlarm',{static: false}) filreAlarm: FilreAlarmComponent;
    @ViewChild('riskAssessment',{static: false}) riskAssessment: RiskAssessmentComponent;
    modalRef: BsModalRef;
    bsConfig = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };
    applicationId: number;
    featureId: number;
    contractId: number;
    featureName = '';
    featureKeyName: string;
    featureKey: number;
    triggerUploader = false;

    deviation = new ElectroDeviation();
    addFileCheckpoint: number;
    addFileHeadpoint: number;

    treeNode: any;
    editMode = false;
    treeActiveNode: any;

    // Document Explorer Configs
    additionalData: any;
    upConfig: UploaderConfig;
    uploadedFiles: any;
    UploadableFile: any;

    featuredocumentId: number;
    additionalFeatures: any;
    pageTitle: string;
    locale: string;
    downloadAll = false;
    allFiles: any[];
    isShowAllFile = true;
    isHideShowAllButton = false;
    enableForm: string;
    previewChecklist: boolean;
    previewHeader = "PREVIEW";
    roleId : any;
    CompleteChecklistEdit: ElectroCompleteChecklistEdit;
    formData: any;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private modalService: BsModalService,
        private archiveServices: ArchiveServices,
        private electroServices: ElectroService,
        private translationService: TranslateService,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private CompleteCheckListServices: CompleteCheckListServices,
        private electroDocumentServices: ElectroDocumentService,
        private electroInstallationService: ElectroInstallationService,
        private kubaServices: KubaServices
    ) {
        this.contractId = route.snapshot.parent.params['cid'];
        this.featureKey = route.snapshot.parent.params['id'];
        this.featureKeyName = this.route.snapshot.params['manualname'];
        this.applicationId = BaseServices.ApplicationId;
        this.upConfig = {
            title: 'ALL_FILES',
            viewSwitch: false,
            editMode: this.editMode,
            windowedHeight: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
            showDownloadAll: this.downloadAll,
            addtionalData: null,
            showDeleteAllButton: true,
        };
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureName = workingModule.Title;
        this.featureId = workingModule.id;
    }

    // Emitter for selected folder from Document Explorer
    setActiveNodeIntree(event: any) {
        this.treeActiveNode = event;
    }

    // Toggle Edit Mode
    toggleEditMode() {
        this.editMode = !this.editMode;
        this.upConfig.editMode = !this.upConfig.editMode;
    }

    // Events form Tree
    catchTreeEvent(event): void {
        this.triggerUploader = false;
        switch (event.eventName) {
            case 'CheckBoxStatus':
                if (event.node.data.isBuilding) {
                    alert(this.translationService.instant('CANT_HIDE_BUILDING'));
                } else {
                    this.subscriptions.push(this.electroServices
                        .updateManualCheckboxstatus(event.node.data)
                        .subscribe(
                            response => {
                                this.refreshTreeNode();
                            },
                            error =>
                                console.log(
                                    'Checkbox status not update : ',
                                    error
                                ),
                            () => console.log('Checkbox status updated')
                        ));
                }
                // console.log('CheckBoxStatus :', event);
                break;

            case 'AddNewRootFolder':
                // console.log('add Folder @ Manual :', event);
                this.subscriptions.push(this.electroServices.createRootFolder(event, true, this.contractId).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error =>
                        console.log('Manual Create folder Error : ', error),
                    () =>
                        console.log(
                            'Manual Create folder Complete',
                            // this.treeActiveNode.data
                        )
                ));
                break;

            case 'AddNewFolder':
                // console.log('add Folder @ Manual :', event);
                this.subscriptions.push(this.electroServices.createManualFolder(event, false, this.contractId).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error =>
                        console.log('Manual Create folder Error : ', error),
                    () =>
                        console.log(
                            'Manual Create folder Complete',
                            // this.treeActiveNode.data
                        )
                ));
                break;

            case 'RenameFolder':
                // console.log('RenameFolder @ Manual :', event);
                this.subscriptions.push(this.electroServices.renameManualFolder(event).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error =>
                        console.log('Manual Create folder Error : ', error),
                    () => console.log('Manual Create folder Complete')
                ));
                break;

            case 'DeleteSelectedDocuments':
                this.subscriptions.push(this.electroServices.deleteMultipleDocument(event.item.data)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log(
                                'Manual delete folder Error : ',
                                error
                            ),
                        () => console.log('Manual delete folder Complete')
                    ))
                break;

            case 'DeleteFolder':
                // console.log('DeleteFolder @ Manual :', event);

                this.subscriptions.push(this.electroServices
                    .deleteManualFolder(event.item.data.nodeId)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log('Manual delete folder Error : ', error),
                        () => console.log('Manual delete folder Complete')
                    ));

                break;

            case 'AddFiles':
                this.triggerUploader = true;
                // console.log(
                //     'AddFiles @ Manual :',
                //     event,
                //     'triggerUploader:',
                //     this.triggerUploader
                // );
                break;
            case 'EditFile':
                // TODO:
                // console.log('RenameFile @ Manual :', event);
                break;
            case 'RenameFile':
                // console.log('RenameFile @ Manual :', event);
                this.subscriptions.push(this.electroServices.renameDocument(event).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error => console.log('Rename article error : ', error),
                    () => console.log('Rename article Complete')
                ));
                break;
            case 'DeleteFile':
                // console.log('DeleteFile @ Manual :', event);
                if(event.item.data.documentType == "checklist" ||
                event.item.data.documentType == "archiveundercompletion" ||
                event.item.data.documentType == "installationform" ||
                event.item.data.documentType == "undercompletioninstallationform"){
                    this.deleteCklFormMP(event.item.data);
                }else{
                    this.subscriptions.push(this.electroServices.deleteDocument(event.item.data.nodeId)
                    .subscribe(response => {
                            this.refreshTreeNode();
                        },
                        error => console.log('Manual checklist delete Error : ',error),
                        () => console.log('Manual checklist delete Complete')
                    ));
                }
                break;
            case 'NewArticle':
                // TODO:
                // console.log('AddArticle @ Manual :', event);
                break;
            case 'SaveArticle':
                // TODO:
                // console.log('EditArticle @ Manual :', event);
                break;
            case 'RenameArticle':
                // console.log('RenameArticle @ Manual :', event);
                break;
            case 'DeleteArticle':
                // console.log('DeleteArticle @ Manual :', event);
                break;

            case 'AddChecklist':
                // TODO:
                // console.log('AddChecklist @ Manual :', event);
                break;
            case 'EditChecklist':
                // TODO:
                // console.log('EditChecklist @ Manual :', event);
                break;
            case 'RenameChecklist':
                // console.log('RenameChecklist @ Manual :', event);
                break;
            case 'DeleteChecklist':
                // console.log('DeleteChecklist @ Manual :', event);
                break;
            case 'moveNode':
                this.subscriptions.push(this.electroServices.moveNode(event).subscribe(
                    response => {
                        if (response) {
                            this.refreshTreeNode();
                        }
                    },
                    error => console.log("Manual movenoxde  Error : ", error),
                    () => console.log("Manual movenode  Complete")
                ));
                break;
            case 'activate':
                if (event.eventName !== 'toggleExpanded' && event.node) {
                    this.checklistOrFormNavigator(event.node.data);
                }
                if (event.node.hasChildren) {
                    this.isShowAllFile = true;
                    this.uploadedFiles = event.node.data.nodes;
                    this.upConfig.title = event.node.data.filename;
                    if (event.node.data.translateKey === 'CHECKLIST_UNDER_COMPLETION') {
                        this.downloadAll = false;
                        this.upConfig.showDownloadAll = false;
                    } else {
                        this.upConfig.showDownloadAll = true;
                        this.downloadAll = true;
                    }
                    this.upConfig.documentType = event.node.data.documentType;
                    this.upConfig.addtionalData = {
                        ApplicationId: BaseServices.ApplicationId,
                        BusinessId: BaseServices.BusinessId,
                        FolderPath:
                            'fid-' +
                            this.featureKey +
                            '-' +
                            '', //this.featureName.replace(' ', '-').toLowerCase(),
                        FolderTypeName: event.node.data.documentType,
                        FeatureId: this.featureId,
                        FeatureKey: +this.featureKey,
                        FeatureName: this.featureName,
                        ArchiveFolderId: event.node.data.nodeId,
                        userId: BaseServices.UserId,
                        IsParentBuilding: event.node.data.isBuilding ? true : false
                    };

                    this.treeActiveNode = event.node;
                }

                this.treeActiveNode = event.node;
                this.cdr.detectChanges();
                break;
            case 'initialized':
                console.log('initialized ');
                break;
            case 'updateData':
                // console.log('updateData', event);

                break;
            case 'loadNodeChildren':
                // console.log('loadNodeChildren', event);
                break;
            case 'deactivate':
                this.previewChecklist = false;
                break;
            default:
                //this.previewChecklist = false;
                break;
        }
    }

    // emitter for save from Document Explorer
    catchExplorerEvent(event: any) {
        switch (event.eventName) {
            case 'AddNewFolder':
                // console.log(
                //     'Create folder in Manual : ',
                //     event,
                //     this.treeActiveNode
                // );
                if (!event.parent) {
                    event.parent = this.treeActiveNode.data;
                }
                this.catchTreeEvent(event);
                break;
            case 'DeleteFolder':
            case 'DeleteArticle':
            case 'DeleteChecklist':
                this.subscriptions.push(this.electroDocumentServices.deleteCompletionChecklist(event.item.data.nodeId, 0)
                    .subscribe(res => {
                        if (res) {
                            this.refreshTreeNode();
                        }
                    }));
                break;
            case 'DeleteFile':
            case 'DeleteDefaultFile':
                this.deleteCklFormMP(event.item.data);
                break;
            case 'DeleteSelectedDocuments':
            case 'DeleteArchiveSelectedDocuments':
                this.catchTreeEvent(event);
                break;
            case 'SaveArticle':
                event.activeNodeId = this.treeActiveNode.data.nodeId;
                this.catchTreeEvent(event);
                break;
            case 'NewArticle':
                event.activeNodeId = this.treeActiveNode.data.nodeId;
                this.catchTreeEvent(event);
                break;
            case 'CopyEditVersionDocument':
                event.activeNodeId = this.treeActiveNode.data.nodeId;
                this.catchTreeEvent(event);
                break;
            case 'ShowOfficeFileSaveloader':
                if (
                    parseInt(sessionStorage.getItem('isDocumentChanged'), null)
                ) {
                    this.electroServices.UpdateDocument(event.item.id, BaseServices.UserId).
                        subscribe(
                            response => {
                                if (response) {
                                }
                            }
                        );
                    setTimeout(() => {
                        this.refreshTreeNode();
                    }, 15000);
                    sessionStorage.setItem('isDocumentChanged', '0');
                }
                break;
            default:
                let uploadedFile = Object.assign(
                    {},
                    event,
                    this.upConfig.addtionalData
                );
                this.subscriptions.push(this.electroServices.addArchiveDocument(uploadedFile, this.contractId).subscribe(
                    (result: any) => {
                        this.refreshTreeNode();
                    },
                    (error: any) => {
                        console.error('Archive document service error:', error);
                    },
                    () => {
                        console.log(
                            'Adding archive document service complete.'
                        );
                    }
                ));
                break;
        }
    }

    /**
     * refresh node event.
     */
    refreshTreeNode() {
        this.subscriptions.push(this.electroServices.getElectroArchiveTree(BaseServices.BusinessId, this.contractId).subscribe(
            (result: any) => {
                this.treeNode = result; // .nodes[0];
                let flattenned = this.flattenArrObj(this.treeNode);
                const parent = _.filter(flattenned, [
                    'id',
                    this.treeActiveNode.data.id
                ]);
                this.uploadedFiles = parent[0].nodes;
                this.upConfig.title = parent[0].filename;
            },
            (error: any) => {
                console.error('Api Error while refreshing tree:', error);
            },
            () => {
                console.log('Refreshing tree service complete.');
            }
        ));
    }

    /**
     * open model event
     * @param template 
     */
    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.bsConfig);
    }

    ngOnInit() {
        this.roleId = BaseServices.roleId;
        this.treeNode = this.route.snapshot.data['treeData'];
    }

    // private
    private flattenArrObj(arr) {
        let newArr = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    showAllFiles() {
        this.isShowAllFile = false;
        if(this.treeActiveNode == undefined){
            this.allFiles = this.flattenArrObj(this.treeNode[0].nodes);
        }
        else{
            this.allFiles = this.flattenArrObj(this.treeActiveNode.data.nodes);
        }
        this.uploadedFiles = this.allFiles.filter(x => x.isFolder === false);
    }

    setStatus(
        event: CompleteChecklistStatus,
        status: CompleteChecklistStatus[],
        CompleteChecklistItemSection: CompleteChecklistItemSection
    ) {
        for (let statusitem of status) {
            if (statusitem.Label === event.Label) {
                statusitem.value = true;
            } else {
                statusitem.value = false;
            }
        }

        this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
            if (result) {
                CompleteChecklistItemSection.Signature =
                    this.translate.instant('SIGNED') +
                    BaseServices.userName +
                    ' ' +
                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
            }
        });
    }

    addEditDeviation(devId, headPoint, subSection) {
        this.deviation = new ElectroDeviation();
        this.addFileHeadpoint = headPoint;
        this.addFileCheckpoint = subSection;
        if (devId) {
            this.electroServices.getDeviationById(devId).subscribe(result => {
                if (result) {
                    this.deviation = result;
                }
            });
        }
        else {
        }

    }

    hideAllFiles() {
        this.isShowAllFile = true;
        this.uploadedFiles = '';
    }

    openChecklistOrForm(event) {
        this.checklistOrFormNavigator(event);
    }

    checklistOrFormNavigator(data) {
        let routeNav = `./../../../${this.contractId}/${0}/complete-cklist/`;
        if (this.editMode) {
            switch (data.documentType) {
                case "checklist":
                    this.router.navigate([routeNav + `${0}/${data.nodeId}`], { relativeTo: this.route });
                    break;
                case "archiveundercompletion":
                    this.router.navigate([routeNav + `${0}/${data.nodeId}`], { relativeTo: this.route });
                    break;
                case "installationform":
                    this.router.navigate([routeNav + `${data.installationFormType}/${data.nodeId}`], { relativeTo: this.route });
                    break;
                case "undercompletioninstallationform":
                    this.router.navigate([routeNav + `${data.installationFormType}/${data.nodeId}`], { relativeTo: this.route });
                    break;
            }
        } else {
            switch (data.documentType) {
                case "archiveundercompletion":
                    this.enableForm = 'checklist';
                    this.subscriptions.push(this.electroDocumentServices.getCompletionChecklistdata(data.nodeId).subscribe(res => {
                        if (res) {
                            this.previewHeader = res.Title;
                            this.previewChecklist = true;
                            this.CompleteChecklistEdit = JSON.parse(res.CheckListData);
                        }
                    }));
                    break;
                case "checklist":
                    this.enableForm = 'checklist';
                    this.subscriptions.push(this.electroDocumentServices.getCompletionChecklistdata(data.nodeId).subscribe(res => {
                        if (res) {
                            this.previewHeader = res.Title;
                            this.previewChecklist = true;
                            this.CompleteChecklistEdit = JSON.parse(res.CheckListData);
                        }
                    }));
                    break;
            }
            switch (data.installationFormType) {
                case ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION:
                    this.enableForm = 'document-assessment';
                    this.subscriptions.push(this.electroInstallationService.getDocumentAssessmsentById(data.nodeId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.previewHeader = "PREVIEW";
                            this.previewChecklist = true;
                            this.documentAssessment.initForm(res);
                            this.documentAssessment.editMode = false;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION:
                    this.enableForm = 'risk-assessment';
                    this.subscriptions.push(this.electroInstallationService.getRiskAssessmentById(data.nodeId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.previewHeader = "PREVIEW";
                            this.previewChecklist = true;
                            this.riskAssessment.initForm(res);
                            this.riskAssessment.editMode = false;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW:
                    this.enableForm = 'distribution-overview';
                    this.subscriptions.push(this.electroInstallationService.getDistributionsById(data.nodeId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.previewHeader = "PREVIEW";
                            this.previewChecklist = true;
                            this.distribution.initForm(res);
                            this.distribution.editMode = false;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW:
                    this.enableForm = 'course-overview';
                    this.subscriptions.push(this.electroInstallationService.getCourseById(data.nodeId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.previewHeader = "PREVIEW";
                            this.previewChecklist = true;
                            this.course.initForm(res);
                            this.course.editMode = false;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.FIRE_ALARM_SYSTEM:
                    this.enableForm = 'fire-alarm';
                    this.subscriptions.push(this.electroInstallationService.getFirealarmSystemById(data.nodeId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.previewHeader = "PREVIEW";
                            this.previewChecklist = true;
                            this.filreAlarm.initForm(res);
                            this.filreAlarm.editMode = false;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM:
                    this.enableForm = 'emergency-lighting';
                    this.subscriptions.push(this.electroInstallationService.getEmergencyLightingById(data.nodeId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.previewHeader = "PREVIEW";
                            this.previewChecklist = true;
                            this.emergencyLighting.initForm(res);
                            this.emergencyLighting.editMode = false;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.MEASURE_PROTOCOL:
                    this.enableForm = 'measure-protocol';
                    this.previewChecklist = true;
                    this.previewHeader = "PREVIEW";
                    this.formData = { Id: data.nodeId };
                    break;
            }
        }
    }

    onBuidingSelect(distId) {
        if (distId) {
            let inspectionId = this.course.courseOverview.InspectionId;
            this.subscriptions.push(this.electroInstallationService.getCourseByInspectionDistribution(this.course.courseOverview.BuildingId,
                inspectionId, distId).subscribe(course => {
                    if (course) {
                        this.formData = course;
                    } else {
                        this.formData = new ElectroCourseOverview();
                    }
                    this.formData.DistributionId = distId;
                    this.formData.BuildingId = this.course.courseOverview.BuildingId;
                    this.course.showLoader = false;
                    this.course.initForm(this.formData);
                }));
        }
    }

    deleteCklFormMP(event) {
        let cklOrFormType = 0;
        switch (event.documentType) {
            case "checklist":
                cklOrFormType = 0;
                break;
            case "archiveundercompletion":
                cklOrFormType = 0;
                break;
            case "installationform":
                cklOrFormType = event.installationFormType;
                break;
            case "undercompletioninstallationform":
                cklOrFormType = event.installationFormType;
                break;
        }
        this.subscriptions.push(this.electroDocumentServices.deleteCompletionChecklist(event.nodeId, cklOrFormType)
            .subscribe(res => {
                if (res) {
                    this.refreshTreeNode();
                }
            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
