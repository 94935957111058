import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { Meeting, MeetingType } from './../models/meeting';
import { MeetingService } from './meeting.services';

@Injectable()
export class MeetingListResolver implements Resolve<Meeting[]> {
    constructor(private backend: MeetingService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Meeting[]> {
        return this.backend.getMeetingByBusiness(BaseServices.BusinessId);
    }
}

@Injectable()
export class MeetingEditResolver implements Resolve<Meeting> {
    constructor(private backend: MeetingService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Meeting> {
        return this.backend.getById(+route.params['mid']);
    }
}

@Injectable()
export class MeetingTypeResolver implements Resolve<MeetingType[]> {
    constructor(private backend: MeetingService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<MeetingType[]> {
        return this.backend.getAllMeetingType();
    }
}

@Injectable()
export class MeetingUserResolver implements Resolve<any> {
    constructor(private backend: MeetingService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getUser(BaseServices.BusinessId);
    }
}
