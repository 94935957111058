import { NotesEditResolver } from './services/notes.resolver.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NotesComponent } from './notes.component';
import { DetailsComponent } from './components/user-signin/details.component';
import { NotesService } from './services/notes.service';
import { AppSharedModule } from './../shared/appShared.module';
import { BusinessListResolver } from 'src/app/kuba/businesses/services/business.resolver.service';
@NgModule({
    imports: [FormsModule, AppSharedModule],
    providers: [NotesService, BusinessListResolver]
})
export class NotesModule {}
