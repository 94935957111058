import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FoodSafetyList } from 'src/app/kuba/foodsafety/models';
import { FoodSafetyServices } from 'src/app/kuba/foodsafety/services/foodsafety.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FoodSafetyManualCompleteChecklist } from 'src/app/kuba/shared/models/complete-checklist';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Rights } from 'src/app/_models/feature';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { KubaControlService } from '../../services/kuba-control.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-kuba-control-list',
    templateUrl: './kuba-control-list.component.html'
})
export class KubaControlListComponent implements OnInit {
    //isKcRights = true;    
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    FoodSafety: FoodSafetyList[];
    FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist;
    additionalData: any;
    statuses: SelectItem[];
    filterdata = [];
    isShowUser = true;
    isDisabled : boolean;
    private subscriptions: Subscription[] = [];
    constructor(
        public kubaControlService: KubaControlService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private router: Router,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            this.additionalData = {
                fileName: val.CONTROL_OBJECT_LIST,
                header: 'Control Object List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Object name', dataKey: 'ZoneName' },
                    { title: 'Type Of Object', dataKey: 'ObjectType'},
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Created By', dataKey: 'CreatorName' },
                    { title: 'Status', dataKey:'Status'},
                    { title: 'Deviations In Followup', dataKey: 'ShowDeviation' }        
                ]
            };
        }));
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
           { label: val.SELECT, value: null },
           { label: val.ACTIVE, value: 1 },
           { label: val.INACTIVE, value: 2 }
           
            );
           }));
   
    }

    ngOnInit(): void {
        this.isDisabled = true;
        let foodSafety = <FoodSafetyList[]>this.route.snapshot.data['list'];
        if (foodSafety.length > 0) {
            this.FoodSafety = foodSafety;
        }
        var fdParam = null;
        fdParam = this.route.snapshot.params['fs'];
        let fs = sessionStorage.getItem('checkParent');
        if (foodSafety.length == 1 && fs == 'KUBA_CONTROL') {
            this.router.navigate(['../../list'], { relativeTo: this.route });
        }
        else if (foodSafety.length == 1) {

            this.router.navigate(['../../details/' + foodSafety[0].Id], { relativeTo: this.route })
        }
        sessionStorage.setItem('checkParent', null);
        if (+BaseServices.roleId === 5) {
            this.isShowUser = false;
        }
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.filterdata.push(this.FoodSafety);

        var datafil = this.filterdata.filter(x=> (x.Status == 1));

    }


    deleteFoodSafety(FoodSafety: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.kubaControlService
                    .deleteFoodSafety(FoodSafety)
                    .subscribe(result => {
                        this.subscriptions.push(this.kubaControlService
                            .getAllByBusinessId(BaseServices.BusinessId)
                            .subscribe(res => {
                                this.FoodSafety = res;
                                this.toasterComponent.callToastDlt();
                            }));
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion

}
