import { Component, OnInit } from '@angular/core';

@Component({
    template: `<router-outlet></router-outlet>`
})
export class AccessLogComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
