import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ToasterComponent } from '../../../_directives/toaster.component';
import { PortalRisk } from './../models/riskanalysis';
import { RiskanalysisService } from 'src/app/kuba/follow-ups/services/risk-analysis.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'risk-new-portal.component.html'
})
export class NewRiskPortalComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    riskForm: FormGroup;
    businesses: SelectItem[];
    selectedBusinesses: string[];
    private subscriptions: Subscription[] = [];
    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router : Router,
        private riskanalysisService: RiskanalysisService) { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.riskForm = this.formBuilder.group({
            Business: ['', Validators.required],
            Title: ['', Validators.required],
            Location: [''],
            WhatCanHappen: [''],
            ActionTaken: ['']
        });

        this.businesses = [];
        let business = this.route.snapshot.data['business'];
        if (business) {
            business.forEach((element:any) => {
                this.businesses.push({ label: element.CompanyName, value: element.Id });
            });
        }
    }
    saveRisk(Mode: string) {
        let risk:any = new PortalRisk();
        risk.Id = 0;
        risk.Location = this.riskForm.value.Location;
        risk.ExpectedBehaviour = this.riskForm.value.WhatCanHappen;
        risk.Alreadyactiontaken = this.riskForm.value.ActionTaken;
        risk.Title = this.riskForm.value.Title;
        risk.ApplicationId = BaseServices.ApplicationId;
        risk.Status = '1';
        risk.BusinessIds = [];
        this.riskForm.value.Business.forEach((selectedBusiness: any) => {
            risk.BusinessIds.push(selectedBusiness)
        });
        this.subscriptions.push(this.riskanalysisService.addPortalRisk(risk).subscribe(isAdded => {
            if (isAdded) {
                this.toasterComponent.callToast();
                Mode === ('NewRisk') ? this.clear() : this.router.navigate([`../risklist`], { relativeTo: this.route });
            }
        }))

    }
    clear() {
        this.riskForm = this.formBuilder.group({
            Business: [''],
            Title: [''],
            Location: [''],
            WhatCanHappen: [''],
            ActionTaken: ['']
        });
    }

    // TODO : to be implemented
    saveAndNewRisk() { }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
