
import { Injectable } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FeatureDetailService {
    featureId: number;
    workingModule: any;

    constructor(private http: HttpClient) {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.featureId = this.workingModule.id;
    }

    getDetails(serviceUrl) {
        this.setWorkingModule();
        return this.http
            .get(environment.BASE_URL + serviceUrl, BaseServices.headerOption)
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // private
    setWorkingModule() {
        this.workingModule = JSON.parse(
            sessionStorage.getItem('workingModule')
        );
        this.featureId = this.workingModule.id;
    }
}
