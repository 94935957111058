<div class="main-content">
    <div class="main-heading">
        <span translate>NEW_USER</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="adminUserAddForm" (submit)="saveChanges()" class="form">
                <adminuser-form [adminUserForm]="adminUserAddForm"></adminuser-form>
                <div class="action-btns-wrapper text-left">
                    <a class="btn btn-outline-secondary" [routerLink]="['./../list']" role="button">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </a>
                    <button class="btn btn-primary" type="submit" [disabled]="!adminUserAddForm.valid">
                        <span translate>SAVE_CHANGES</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<toaster-component></toaster-component>