import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToasterComponent } from './../../../../_directives/toaster.component';
import { BaseServices } from './../../../kuba.services';
import { Notes } from './../../models/notes';
import { SelectItem } from 'primeng/api';
import { NotesService } from '../../services/notes.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'notes',
    templateUrl: 'details.component.html'
})
export class DetailsComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    notes: any;
    isVisible = false;
    note: Notes;
    business: SelectItem[];
    noteForm: FormGroup;
    sign: any;
    noteDetail: any;
    noteId: number;
    isHidden: boolean;
    empId: number;
    private subscriptions: Subscription[] = [];

    /**
     * constructor
     * @param formBuilder {{FormBuilder}}
     * @param notesService {{NotesService}}
     * @param route {{ActivatedRoute}}
     */
    constructor(
        private formBuilder: FormBuilder,
        private notesService: NotesService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.empId = this.route.snapshot.data['eid'];

        this.noteForm = this.formBuilder.group({
            Note1: [],
            Business: ['']
        });
        this.business = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.business = [];
            this.business.push(
                { label: val.SELECT, value: null }
            );
        }));
        let business = this.route.snapshot.data['business'];
        if (business) {
            business.forEach((element: any) => {
                this.business.push({ label: element.Name, value: element.Id });
            });
        }
        // only visible when role is Admin/Portal
        this.isVisible = (BaseServices.UserRole.toUpperCase() === 'ADMIN'
            || BaseServices.UserRole.toUpperCase() === 'PORTAL');
    }

    /**
     * get data for edit
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (this.empId) {
            this.subscriptions.push(this.notesService.getNoteByUser(this.empId).subscribe(result => {
                this.noteId = result.Id;
                if (result) {
                    (<FormGroup>this.noteForm).get('Note1').patchValue(result.Note1);
                } else {
                    this.noteForm = this.formBuilder.group({
                        Note1: [],
                        Business: ['']
                    });
                }
            }))
        } else {
            if (BaseServices.UserRole.toUpperCase() === 'ADMIN'
                || BaseServices.UserRole.toUpperCase() === 'PORTAL') {
                (<FormGroup>this.noteForm).get('Note1').patchValue('');
                this.isHidden = true;
            } else {
                this.subscriptions.push(this.notesService
                    .getById(BaseServices.BusinessId)
                    .subscribe(result => {
                        if (result) {
                            this.noteId = result.Id;
                            this.noteDetail = result;
                            (<FormGroup>this.noteForm).get('Note1').patchValue(result.Note1);
                        }
                    }));
                this.isHidden = false;
            }
        }

    }


    /**
     * get signature
     */
    signature() {
        let today = new Date();
        let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let dateTime = date + ' ' + time;
        let note = this.noteForm.value.Note1 ? this.noteForm.value.Note1 : '';
        this.sign = ` ${note} ${BaseServices.Name} ${dateTime}`;
        if (this.noteDetail) {
            this.sign = `  ${note}   ${BaseServices.Name} ${dateTime}`;
        }
    }

    /**
     * save and update note
     */
    saveNote() {
        let note = new Notes();
        note.Id = this.noteId ? this.noteId : 0;
        if (this.empId) {
            note.CreatedBy = this.empId;
        } else {
            note.CreatedBy = BaseServices.UserId;
        }
        note.BusinessId = this.noteForm.value.Business ? this.noteForm.value.Business : BaseServices.BusinessId;
        note.Note1 = this.noteForm.value.Note1 ? this.noteForm.value.Note1 : '';
        if (this.noteId > 0) {
            this.subscriptions.push(this.notesService.update(note).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        } else {
            this.subscriptions.push(this.notesService.add(note).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        }
    }
    /**
     * get note when business change
     * @param business {{any}}
     */
    onBusinessChanged(business) {
        if (business.selectedOption.value) {
            this.isHidden = false;
            this.subscriptions.push(this.notesService.getById(business.selectedOption.value).subscribe(result => {
                if (result) {
                    this.noteId = result.Id;
                    (<FormGroup>this.noteForm).get('Note1').patchValue(result.Note1);
                }
            }));
        } else {
            this.isHidden = true;
            (<FormGroup>this.noteForm).get('Note1').patchValue('');
        }

    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
