import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastContainerModule } from 'ngx-toastr';
import { ValidationService } from '../kuba/shared/services/validation.service';
import { ToasterComponent } from './../_directives/toaster.component';
import { AuthenticationService } from './../_services/authentication.service';

import { ResetCredential } from './../kuba/users/models/user';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'reset-password',
    templateUrl: 'reset-password.component.html',
    styleUrls: ['reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    updatePasswordForm: FormGroup;

    userCredentials = new ResetCredential();
    returnValue = 0;
    token: string;
    decodedToken: any;
    userId: number;
    createdOn: any;
    regenaratePassword = false;
    currentTime: Date;
    private subscriptions: Subscription[] = [];
    constructor(
        public formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private renderer: Renderer2
    ) { }
    ngOnInit() {
        this.updatePasswordForm = this.formBuilder.group({
            passwordValidation: this.formBuilder.group(
                {
                    Password: ['', [Validators.required, Validators.nullValidator]],
                    ConfirmPassword: ['', [Validators.required, Validators.nullValidator]]
                },

                { validator: ValidationService.matchPassword }
            )
        });

        this.renderer.addClass(document.body.parentElement, 'loginView');
        this.renderer.addClass(document.body, 'loginView');
  
        this.route.queryParams.subscribe((params:any) => {
            this.token = params.TokenKey;
          });
        let resetCredentials = new ResetCredential();
        resetCredentials.Token = this.token;
        
        this.authenticationService
            .getUserIdByToken(resetCredentials)
            .subscribe((result: any) => {
                this.userId = result.UserId;
                this.createdOn = result.CreatedOn;
                this.currentTime = result.CurrentTime
                let date2 = new Date(
                    this.currentTime.getFullYear(),
                    this.currentTime.getMonth(),
                    this.currentTime.getDate(),
                    this.currentTime.getHours(),
                    this.currentTime.getMinutes(),
                    this.currentTime.getSeconds(),
                    this.currentTime.getMilliseconds()
                );
                let timeStart = new Date(this.createdOn).getTime();
                let timeEnd = new Date(date2).getTime();
                let hourDiff = Math.abs(timeEnd - timeStart); // in ms
                let minDiff = Math.round(
                    ((hourDiff % 86400000) % 3600000) / 60000
                ); // in minutes
                let hDiff = hourDiff / 3600 / 1000; // in hours
                if (hDiff >= 1 || result.Status === false) {
                    this.regenaratePassword = true;
                }
            });
    }
    changePassword() {

        this.userCredentials.Id = this.userId;
        this.userCredentials.Password = this.updatePasswordForm.value.passwordValidation.Password;
        this.userCredentials.Password = this.updatePasswordForm.value.passwordValidation.ConfirmPassword;
        this.userCredentials.Token = this.token;
        this.subscriptions.push(this.authenticationService
            .resetPassword(this.userCredentials)
            .subscribe((result: any) => {
                this.returnValue = result;
                this.clear();
            }));
    }

    clear() {

        this.userCredentials.Id = 0;
        setTimeout(x => {
            this.returnValue = 0;
        }, 3000);
        this.updatePasswordForm = this.formBuilder.group({
            passwordValidation: this.formBuilder.group({
                Password: '',
                ConfirmPassword: ''
            })
        });
    }
    ngOnDestroy() {
        this.renderer.removeClass(document.body.parentElement, 'loginView');
        this.renderer.removeClass(document.body, 'loginView');

        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
