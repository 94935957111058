export class CompleteCheckList {
    Id?: number;
    ArchiveFolderId?: number;
    BusinessId?: number;
    FeatureId?: number;
    FeatureKey?: number;
    Title?: string;
    InitDate?: Date;
    ResponsibleId?: number;
    Participants?: string;
    ChecklistId?: number;
    CheckListData?: string;
    MovedFolderId?: number;
    Status?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    ModifiedBy?: number;
    ModifiedOn?: Date;
    FeatureFolderId?: number;
    ParentId?: number;
    ParentKey?: number;
    Created?: string;
    ActivityId?: number;
    ApplicationId?: number;
    IsTaskManagerChecklist?: boolean;
    IsUnderCompletion?: boolean;
    Location?: string;
    DepartmentId?:number;
    CompletedBy?: number;
    SendonlytoDepartment?: boolean;
    DeviationIds?: number[];
    constructor() {
        this.Id = 0;
        this.ResponsibleId = null;
        this.InitDate = new Date();
        this.CreatedOn = new Date();
        this.ModifiedOn = new Date();
        this.IsTaskManagerChecklist = false;
    }
    CultureInfo?:string;
}
export class DeviationIds {
   DeviationIds?: number[];
   FeatureId ?: number;
   FeatureKey? : string;
   CultureInfo?: string;
}
export class CompleteChecklistEdit {
    Section: CompleteChecklistSection[];
}

export class CompleteChecklistSection {
    Title: string;
    Point: number;
    Items: CompleteChecklistItemSection[];
}

export class CompleteChecklistItemSection {
    Title: string;
    Point: number;
    Comments: string;
    Status: CompleteChecklistStatus[];
    Attachments: CompleteAttachment[];
    Score?: number;
    Signature: string;
    ReferenceChecklist?: string;
}

export class CompleteChecklistStatus {
    Label: string;
    value: boolean;
    DeviationId: number;
    DeviationNumber?: string;
}

export class CompleteAttachment {
    id?: number;
    DeviationId?: number;
    filename?: string;
    OriginalFileName?: string;
    mimetype?: string;
    size?: number;
    path?: string;
    FileType?: number;
    status?: number;
    createdBy?: number;
    createdOn?: Date;
    ModifiedBy?: number;
    ModifiedOn?: Date;
    originalname?: string;
    constructor() {
        this.status = 1;
    }
}

export class FoodSafetyManualCompleteChecklist {
    Id?: number;
    ManualFolderId?: number;
    BusinessId?: number;
    FeatureId?: number;
    FeatureKey?: number;
    CheckListData?: string;
    Status?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    ModifiedBy?: number;
    ModifiedOn?: Date;
    Title?: string;
}

export class FoodSafetyManualCompleteChecklistData {
    Id?: number;
    ObjectName?: string;
    TypeOfObject?: string;
    AverageTemperature?: number;
    Temperature?: number;
    Comments?: string;
    DeviationId?: number;
    FoodSafetyId?: number;
    Status: FoodSafteyManualCompleteStatus[];
}

export class FoodSafteyManualCompleteStatus {
    Type?: string;
    Value?: boolean;
}
export class VehiclesCompleteChecklist {
    Id?: number;
    FeatureFolderId?: number;
    BusinessId?: number;
    FeatureId?: number;
    FeatureKey?: number;
    Title?: string;
    CheckListData?: string;
    Status?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    ModifiedBy?: number;
    ModifiedOn?: Date;
}

export class VehiclesCompleteChecklisttData {
    Id?: number;
    CheckpointName?: string;
    Comments?: string;
    DeviationId?: number;
    Status: VehiclesCompleteStatus[];
}
export class VehiclesCompleteStatus {
    Type?: string;
    Value?: boolean;
}