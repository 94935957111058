import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { Observable, Subscription } from 'rxjs';
import { SJAFormServices } from '../sjaform.routing';
import { SjaCheckListData, SjaForm, SjaTitleForm } from './sja-form';
import { DatePipe, Location } from '@angular/common';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { element } from 'protractor';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators';
@Component({
  selector: 'app-sja-userform',
  templateUrl: './sja-userform.component.html',
  styles: [],
})
export class SjaUserformComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  SjaCheckList: {
    Id: number;
    Title: string;
    TranslationKey: string;
    Yes: boolean;
    No: boolean;
    NotApplicable: boolean;
    Comments: string;
    Respo: string;
    IsParent: boolean;
    ParentId: number;
  }[];
  newSJAForm: SjaForm;
  featureKey: number;
  Id: number;
  firstInit = true;
  firstInit1 = true;
  firstInit2 = true;
  firstInit3 = true;
  currentDate: Date;
  addTitle: boolean;
  sjaTitleId: number;
  sjaTitle: string;
  SJATitleForm: FormGroup;
  SJATitleList: any;
  SJATitles: SelectItem[];
  editSJATitle: any = [];
  isLabelDisabled: boolean;
  isTitleAllocated: boolean;
  parentId: any;
  viewSJATitle: boolean;
  isReponsibleSign: boolean;
  isAreaManagerSign: boolean;
  isResponsibleForExecutionSign: boolean;
  isOthersSign: boolean;
  hasRights: boolean;
  private subscriptions: Subscription[] = [];
  public DateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };

  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private SJAFormServices: SJAFormServices,
    private route: ActivatedRoute,
    private location: Location,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) {
    this.featureKey = +route.snapshot.parent.parent.params['id'];
    let workingModule = <any>(
      JSON.parse(sessionStorage.getItem('workingModule'))
    );
    this.parentId = workingModule.id;

    this.SJATitleForm = this.formBuilder.group({
      SjaTitle: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    let path = this.route.snapshot.url[0].path;
    this.bindDetails();
    this.BindSJATitle();
    if (path == 'edit') {
      this.newSJAForm = new SjaForm();
      this.newSJAForm = <SjaForm>this.route.snapshot.data['sjaform'];
      this.newSJAForm.Date =
        this.newSJAForm.Date != null
          ? HelperService.formatInputDate(this.newSJAForm.Date)
          : null;
      this.newSJAForm.SjaResponsibleSignedDate =
        this.newSJAForm.SjaResponsibleSignedDate != null
          ? HelperService.formatInputDate(
              this.newSJAForm.SjaResponsibleSignedDate
            )
          : null;
      this.newSJAForm.AreaManagerSignedDate =
        this.newSJAForm.AreaManagerSignedDate != null
          ? HelperService.formatInputDate(this.newSJAForm.AreaManagerSignedDate)
          : null;
      this.newSJAForm.ResponsibleExecutionSignedDate =
        this.newSJAForm.ResponsibleExecutionSignedDate != null
          ? HelperService.formatInputDate(
              this.newSJAForm.ResponsibleExecutionSignedDate
            )
          : null;
      this.newSJAForm.OthersSignedDate =
        this.newSJAForm.OthersSignedDate != null
          ? HelperService.formatInputDate(this.newSJAForm.OthersSignedDate)
          : null;
      this.BindChecklist(<any>JSON.parse(this.newSJAForm.CheckListData));
    } else {
      this.newSJAForm = new SjaForm();
      this.newSJAForm.Id = 0;
      this.newSJAForm.SjaTitleId = 0;
      this.newSJAForm.Location = '';
      this.newSJAForm.IsCheckCompletedChecklist = false;
      this.newSJAForm.Date = '';
      (this.newSJAForm.Participants = ''),
        (this.newSJAForm.SjaResponsible = ''),
        (this.newSJAForm.JobDescription = ''),
        (this.newSJAForm.RiskAssociatedWithWork = '');
      this.newSJAForm.ProtectionOrSecurity = '';
      this.newSJAForm.ConclusionOrComments = '';
      this.newSJAForm.CheckListData = '';
      this.newSJAForm.SjaResponsibleSignedDate = '';
      this.newSJAForm.AreaManagerSignedDate = '';
      this.newSJAForm.ResponsibleExecutionSignedDate = '';
      this.newSJAForm.OthersSignedDate = '';
      this.newSJAForm.DigitalResponsibleSign = '';
      this.newSJAForm.DigitalAreaManagerSign = '';
      this.newSJAForm.DigitalExecutionSign = '';
      this.newSJAForm.DigitalOtherSign = '';
      this.BindDefaultChecklist();
    }
    let userRole = BaseServices.UserRole;
    this.hasRights = userRole == 'Guest' || userRole == 'Portal' ? false : true;
    this.currentDate = new Date();
  }

  BindDefaultChecklist() {
    this.SjaCheckList = [];
    this.SjaCheckList.push(
      {
        Id: 1,
        Title: this.translate.instant('DOC_EXPERIENCE_COMPETENCE'),
        TranslationKey: 'DOC_EXPERIENCE_COMPETENCE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 2,
        Title: this.translate.instant('COMMON_WORK'),
        TranslationKey: 'COMMON_WORK',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 1,
      },
      {
        Id: 3,
        Title: this.translate.instant('KNOWLEDGE_OF_UNWANTED'),
        TranslationKey: 'KNOWLEDGE_OF_UNWANTED',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 1,
      },
      {
        Id: 4,
        Title: this.translate.instant('REQUIRED_PANNEL'),
        TranslationKey: 'REQUIRED_PANNEL',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 1,
      },
      {
        Id: 5,
        Title: this.translate.instant('COMMUNICATION'),
        TranslationKey: 'COMMUNICATION',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 6,
        Title: this.translate.instant('POSSIBLE_CONFLICT'),
        TranslationKey: 'POSSIBLE_CONFLICT',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 5,
      },
      {
        Id: 7,
        Title: this.translate.instant('HANDLING'),
        TranslationKey: 'HANDLING',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 5,
      },
      {
        Id: 8,
        Title: this.translate.instant('REQUIREMENT'),
        TranslationKey: 'REQUIREMENT',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 5,
      },
      {
        Id: 9,
        Title: this.translate.instant('SJA_WORK_PLACE'),
        TranslationKey: 'SJA_WORK_PLACE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 10,
        Title: this.translate.instant('UNFAMILAR_WORKING_POSITIONS'),
        TranslationKey: 'UNFAMILAR_WORKING_POSITIONS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 11,
        Title: this.translate.instant('WORKS_IN_TANK'),
        TranslationKey: 'WORKS_IN_TANK',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 12,
        Title: this.translate.instant('WORK_IN_DITCH'),
        TranslationKey: 'WORK_IN_DITCH',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 13,
        Title: this.translate.instant('TIDY_AND_CLEAN'),
        TranslationKey: 'TIDY_AND_CLEAN',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 14,
        Title: this.translate.instant('MORE_ADVANCED_SAFETY_GEAR'),
        TranslationKey: 'MORE_ADVANCED_SAFETY_GEAR',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 15,
        Title: this.translate.instant('WEATHER_WIND_VISION'),
        TranslationKey: 'WEATHER_WIND_VISION',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 16,
        Title: this.translate.instant('USAGE_OF_SCAFFOLDS'),
        TranslationKey: 'USAGE_OF_SCAFFOLDS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 17,
        Title: this.translate.instant('WORK_AT_HIGHS'),
        TranslationKey: 'WORK_AT_HIGHS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 18,
        Title: this.translate.instant('IONIZING_RADIATION'),
        TranslationKey: 'IONIZING_RADIATION',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 19,
        Title: this.translate.instant('EMERGENCY_ESCAPE'),
        TranslationKey: 'EMERGENCY_ESCAPE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 9,
      },
      {
        Id: 20,
        Title: this.translate.instant('CHEMICAL_DANGERS'),
        TranslationKey: 'CHEMICAL_DANGERS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 21,
        Title: this.translate.instant('USER_OF_HAZARDOUS'),
        TranslationKey: 'USER_OF_HAZARDOUS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 20,
      },
      {
        Id: 22,
        Title: this.translate.instant('USER_OF_FLAMMABLE'),
        TranslationKey: 'USER_OF_FLAMMABLE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 20,
      },
      {
        Id: 23,
        Title: this.translate.instant('CHEMICALS_REQUIRE_APPROVE'),
        TranslationKey: 'CHEMICALS_REQUIRE_APPROVE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 20,
      },
      {
        Id: 24,
        Title: this.translate.instant('BIOLOGICAL_MATERIAL'),
        TranslationKey: 'BIOLOGICAL_MATERIAL',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 20,
      },
      {
        Id: 25,
        Title: this.translate.instant('DUST_ASBESTOSE'),
        TranslationKey: 'DUST_ASBESTOSE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 20,
      },
      {
        Id: 26,
        Title: this.translate.instant('MECHANICAL_DANGERS'),
        TranslationKey: 'MECHANICAL_DANGERS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 27,
        Title: this.translate.instant('STABILITY_STRENGTH_SUSPENSE'),
        TranslationKey: 'STABILITY_STRENGTH_SUSPENSE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 26,
      },
      {
        Id: 28,
        Title: this.translate.instant('SQUEEZE_CUT_BLOW'),
        TranslationKey: 'SQUEEZE_CUT_BLOW',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 26,
      },
      {
        Id: 29,
        Title: this.translate.instant('NOISE_PRESSURE_TEMPERATURE'),
        TranslationKey: 'NOISE_PRESSURE_TEMPERATURE',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 26,
      },
      {
        Id: 30,
        Title: this.translate.instant('WASTE_TREATMENT'),
        TranslationKey: 'WASTE_TREATMENT',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 26,
      },
      {
        Id: 31,
        Title: this.translate.instant('SPECIAL_EUIPMENT_REQUIRED'),
        TranslationKey: 'SPECIAL_EUIPMENT_REQUIRED',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 26,
      },
      {
        Id: 32,
        Title: this.translate.instant('ELECTRICAL_DANGER'),
        TranslationKey: 'ELECTRICAL_DANGER',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 33,
        Title: this.translate.instant('CURRENT'),
        TranslationKey: 'CURRENT',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 32,
      },
      {
        Id: 34,
        Title: this.translate.instant('SHOCK_CREEP_CURRENT'),
        TranslationKey: 'SHOCK_CREEP_CURRENT',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 32,
      },
      {
        Id: 35,
        Title: this.translate.instant('LOSS_POWER_SUPPLY'),
        TranslationKey: 'LOSS_POWER_SUPPLY',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 32,
      },
      {
        Id: 36,
        Title: this.translate.instant('WORK_AREA'),
        TranslationKey: 'WORK_AREA',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 37,
        Title: this.translate.instant('NEED_INSPECTION'),
        TranslationKey: 'NEED_INSPECTION',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 36,
      },
      {
        Id: 38,
        Title: this.translate.instant('MARKING_SIGNAGE_BARRIER'),
        TranslationKey: 'MARKING_SIGNAGE_BARRIER',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 36,
      },
      {
        Id: 39,
        Title: this.translate.instant('ENVIRONMENTAL_CONSEQUENCES'),
        TranslationKey: 'ENVIRONMENTAL_CONSEQUENCES',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 36,
      },
      {
        Id: 40,
        Title: this.translate.instant('PHYSICAL_SECURITY_SYSTEMS'),
        TranslationKey: 'PHYSICAL_SECURITY_SYSTEMS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: true,
        ParentId: 0,
      },
      {
        Id: 41,
        Title: this.translate.instant('WORK_ON_SECURITY_SYSTEMS'),
        TranslationKey: 'WORK_ON_SECURITY_SYSTEMS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 40,
      },
      {
        Id: 42,
        Title: this.translate.instant('DISCONNECTING_SECURITY_SYSTEMS'),
        TranslationKey: 'DISCONNECTING_SECURITY_SYSTEMS',
        Yes: false,
        No: false,
        NotApplicable: false,
        Comments: '',
        Respo: '',
        IsParent: false,
        ParentId: 40,
      }
    );
  }

  BindChecklist(CheckListData) {
    this.SjaCheckList = [];
    CheckListData.forEach((x) => {
      this.SjaCheckList.push({
        Id: x.Id,
        Title:
          x.TranslationKey != null && x.TranslationKey != ''
            ? this.translate.instant(x.TranslationKey)
            : x.Title,
        TranslationKey: x.TranslationKey != null ? x.TranslationKey : x.Title,
        Yes: x.Yes,
        No: x.No,
        NotApplicable: x.NotApplicable,
        Comments: x.Comments,
        Respo: x.Respo,
        IsParent: x.IsParent,
        ParentId: x.ParentId,
      });
    });
  }

  //#region signature
  @ViewChild('canvas', { static: false }) public canvas: ElementRef;
  @ViewChild('canvas1', { static: false }) public canvas1: ElementRef;
  @ViewChild('canvas2', { static: false }) public canvas2: ElementRef;
  @ViewChild('canvas3', { static: false }) public canvas3: ElementRef;

  private cx: CanvasRenderingContext2D;
  i = new Image();

  private cx1: CanvasRenderingContext2D;
  i1 = new Image();

  private cx2: CanvasRenderingContext2D;
  i2 = new Image();

  private cx3: CanvasRenderingContext2D;
  i3 = new Image();

  public ngAfterViewInit() {
    //canvas property adjustment after view child init.

    this.cx = null;
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    canvasEl.width = 380;
    canvasEl.height = 150;

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    this.captureEvents(canvasEl);

    this.cx1 = null;
    const canvasE2: HTMLCanvasElement = this.canvas1.nativeElement;
    this.cx1 = canvasE2.getContext('2d');

    canvasE2.width = 380;
    canvasE2.height = 150;

    this.cx1.lineWidth = 3;
    this.cx1.lineCap = 'round';
    this.cx1.strokeStyle = '#000';

    this.captureEvents1(canvasE2);

    this.cx2 = null;
    const canvasE3: HTMLCanvasElement = this.canvas2.nativeElement;
    this.cx2 = canvasE3.getContext('2d');

    canvasE3.width = 380;
    canvasE3.height = 150;

    this.cx2.lineWidth = 3;
    this.cx2.lineCap = 'round';
    this.cx2.strokeStyle = '#000';

    this.captureEvents2(canvasE3);

    this.cx3 = null;
    const canvasE4: HTMLCanvasElement = this.canvas3.nativeElement;
    this.cx3 = canvasE4.getContext('2d');

    canvasE4.width = 380;
    canvasE4.height = 150;

    this.cx3.lineWidth = 3;
    this.cx3.lineCap = 'round';
    this.cx3.strokeStyle = '#000';

    this.captureEvents3(canvasE4);
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      if (this.firstInit) {
        this.bindSign();
      }
      if (this.firstInit1) {
        this.bindSign1();
      }
      if (this.firstInit2) {
        this.bindSign2();
      }
      if (this.firstInit3) {
        this.bindSign3();
      }
    }, 300); //binding saved sign after CanvasRenderingContext2D(cx) view init completed.
  }

  /**
   * canvas mouse event capturer.
   * @param canvasEl
   */
  private captureEvents(canvasEl: HTMLCanvasElement) {
    this.subscriptions.push(
      fromEvent(canvasEl, 'mousedown')
        .pipe(
          switchMap(() =>
            fromEvent(canvasEl, 'mousemove').pipe(
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              pairwise()
            )
          )
        )
        .subscribe((res: [MouseEvent, MouseEvent]) => {
          const rect = canvasEl.getBoundingClientRect();

          const prevPos = {
            x: res[0].clientX - rect.left,
            y: res[0].clientY - rect.top,
          };

          const currentPos = {
            x: res[1].clientX - rect.left,
            y: res[1].clientY - rect.top,
          };

          this.drawOnCanvas(prevPos, currentPos);
        })
    );
  }

  /**
   * canvas mouse event capturer.
   * @param canvasE2
   */
  private captureEvents1(canvasE2: HTMLCanvasElement) {
    this.subscriptions.push(
      fromEvent(canvasE2, 'mousedown')
        .pipe(
          switchMap(() =>
            fromEvent(canvasE2, 'mousemove').pipe(
              takeUntil(fromEvent(canvasE2, 'mouseup')),
              pairwise()
            )
          )
        )
        .subscribe((res: [MouseEvent, MouseEvent]) => {
          const rect = canvasE2.getBoundingClientRect();

          const prevPos = {
            x: res[0].clientX - rect.left,
            y: res[0].clientY - rect.top,
          };

          const currentPos = {
            x: res[1].clientX - rect.left,
            y: res[1].clientY - rect.top,
          };

          this.drawOnCanvas1(prevPos, currentPos);
        })
    );
  }

  /**
   * canvas mouse event capturer.
   * @param canvasE3
   */
  private captureEvents2(canvasE3: HTMLCanvasElement) {
    this.subscriptions.push(
      fromEvent(canvasE3, 'mousedown')
        .pipe(
          switchMap(() =>
            fromEvent(canvasE3, 'mousemove').pipe(
              takeUntil(fromEvent(canvasE3, 'mouseup')),
              pairwise()
            )
          )
        )
        .subscribe((res: [MouseEvent, MouseEvent]) => {
          const rect = canvasE3.getBoundingClientRect();

          const prevPos = {
            x: res[0].clientX - rect.left,
            y: res[0].clientY - rect.top,
          };

          const currentPos = {
            x: res[1].clientX - rect.left,
            y: res[1].clientY - rect.top,
          };

          this.drawOnCanvas2(prevPos, currentPos);
        })
    );
  }

  /**
   * canvas mouse event capturer.
   * @param canvasE4
   */
  private captureEvents3(canvasE4: HTMLCanvasElement) {
    this.subscriptions.push(
      fromEvent(canvasE4, 'mousedown')
        .pipe(
          switchMap(() =>
            fromEvent(canvasE4, 'mousemove').pipe(
              takeUntil(fromEvent(canvasE4, 'mouseup')),
              pairwise()
            )
          )
        )
        .subscribe((res: [MouseEvent, MouseEvent]) => {
          const rect = canvasE4.getBoundingClientRect();

          const prevPos = {
            x: res[0].clientX - rect.left,
            y: res[0].clientY - rect.top,
          };

          const currentPos = {
            x: res[1].clientX - rect.left,
            y: res[1].clientY - rect.top,
          };

          this.drawOnCanvas3(prevPos, currentPos);
        })
    );
  }

  /**
   * draw on canvas function.
   * @param prevPos
   * @param currentPos
   */
  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx) {
      return;
    }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
      this.isReponsibleSign = true;
      if (this.isReponsibleSign) {
        this.newSJAForm.SjaResponsibleSignedDate =
          HelperService.formatInputDate(new Date());
        this.newSJAForm.DigitalResponsibleSign =
          BaseServices.Name +
          ' ' +
          this.datePipe.transform(this.currentDate, 'dd/MM/yyyy HH:mm');
      }
    }
  }

  private drawOnCanvas1(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx1) {
      return;
    }

    this.cx1.beginPath();

    if (prevPos) {
      this.cx1.moveTo(prevPos.x, prevPos.y); // from
      this.cx1.lineTo(currentPos.x, currentPos.y);
      this.cx1.stroke();
      this.isAreaManagerSign = true;
      if (this.isAreaManagerSign) {
        this.newSJAForm.AreaManagerSignedDate = HelperService.formatInputDate(
          new Date()
        );
        this.newSJAForm.DigitalAreaManagerSign =
          BaseServices.Name +
          ' ' +
          this.datePipe.transform(this.currentDate, 'dd/MM/yyyy HH:mm');
      }
    }
  }

  private drawOnCanvas2(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx2) {
      return;
    }

    this.cx2.beginPath();

    if (prevPos) {
      this.cx2.moveTo(prevPos.x, prevPos.y); // from
      this.cx2.lineTo(currentPos.x, currentPos.y);
      this.cx2.stroke();
      this.isResponsibleForExecutionSign = true;
      if (this.isResponsibleForExecutionSign) {
        this.newSJAForm.ResponsibleExecutionSignedDate =
          HelperService.formatInputDate(new Date());
        this.newSJAForm.DigitalExecutionSign =
          BaseServices.Name +
          ' ' +
          this.datePipe.transform(this.currentDate, 'dd/MM/yyyy HH:mm');
      }
    }
  }

  private drawOnCanvas3(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx3) {
      return;
    }

    this.cx3.beginPath();

    if (prevPos) {
      this.cx3.moveTo(prevPos.x, prevPos.y); // from
      this.cx3.lineTo(currentPos.x, currentPos.y);
      this.cx3.stroke();
      this.isOthersSign = true;
      if (this.isOthersSign) {
        this.newSJAForm.OthersSignedDate = HelperService.formatInputDate(
          new Date()
        );
        this.newSJAForm.DigitalOtherSign =
          BaseServices.Name +
          ' ' +
          this.datePipe.transform(this.currentDate, 'dd/MM/yyyy HH:mm');
      }
    }
  }

  /**
   * bind signature to canvas.
   */
  bindSign() {
    if (this.newSJAForm.ResponsibleSign) {
      this.i.src = this.newSJAForm.ResponsibleSign;
      this.cx.drawImage(this.i, 0, 0, 400, 150);
    }
  }

  bindSign1() {
    if (this.newSJAForm.AreaManagerSign) {
      this.i1.src = this.newSJAForm.AreaManagerSign;
      this.cx1.drawImage(this.i1, 0, 0, 400, 150);
    }
  }

  bindSign2() {
    if (this.newSJAForm.ResponsibleForExecutionSign) {
      this.i2.src = this.newSJAForm.ResponsibleForExecutionSign;
      this.cx2.drawImage(this.i2, 0, 0, 400, 150);
    }
  }

  bindSign3() {
    if (this.newSJAForm.OthersSign) {
      this.i3.src = this.newSJAForm.OthersSign;
      this.cx3.drawImage(this.i3, 0, 0, 400, 150);
    }
  }

  /**
   * clear signature from canvas.
   */
  clearSign() {
    this.firstInit = false;
    this.cx.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    this.isReponsibleSign = false;
    if (!this.isReponsibleSign) {
      this.newSJAForm.SjaResponsibleSignedDate = '';
      this.newSJAForm.DigitalResponsibleSign = '';
    }
  }

  clearSign1() {
    this.firstInit1 = false;
    this.cx1.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    this.isAreaManagerSign = false;
    if (!this.isAreaManagerSign) {
      this.newSJAForm.AreaManagerSignedDate = '';
    }
  }

  clearSign2() {
    this.firstInit2 = false;
    this.cx2.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    this.isResponsibleForExecutionSign = false;
    if (!this.isResponsibleForExecutionSign) {
      this.newSJAForm.ResponsibleExecutionSignedDate = '';
      this.newSJAForm.DigitalExecutionSign = '';
    }
  }

  clearSign3() {
    this.firstInit3 = false;
    this.cx3.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    this.isOthersSign = false;
    if (!this.isOthersSign) {
      this.newSJAForm.OthersSignedDate = '';
      this.newSJAForm.DigitalOtherSign = '';
    }
  }
  //#endregion

  saveForm() {
    this.loading = true;
    let workingModule = <any>(
      JSON.parse(sessionStorage.getItem('workingModule'))
    );
    this.newSJAForm.FeatureId = workingModule.id;
    this.newSJAForm.FeatureKey = workingModule.id == 15 ? 0 : this.featureKey;
    this.newSJAForm.CreatedBy = BaseServices.Name;
    this.newSJAForm.Date =
      this.newSJAForm.Date != null
        ? HelperService.formatDateFilter(this.newSJAForm.Date.formatted)
        : null;
    this.newSJAForm.ResponsibleSign = this.canvas.nativeElement.toDataURL();
    this.newSJAForm.AreaManagerSign = this.canvas1.nativeElement.toDataURL();
    this.newSJAForm.ResponsibleForExecutionSign =
      this.canvas2.nativeElement.toDataURL();
    this.newSJAForm.OthersSign = this.canvas3.nativeElement.toDataURL();
    this.newSJAForm.CheckListData = JSON.stringify(this.SjaCheckList);
    this.newSJAForm.Status = true;
    if (workingModule.id == 43) {
      this.newSJAForm.ZoneName = sessionStorage.getItem('ZoneName');
    } else if (workingModule.id == 16) {
      this.newSJAForm.ZoneName = sessionStorage.getItem('ProjectName');
    }
    this.newSJAForm.LanguageId = sessionStorage.getItem('languageId');
    this.newSJAForm.ApplicationId = BaseServices.ApplicationId;
    this.newSJAForm.BusinessId = BaseServices.BusinessId;
    this.newSJAForm.SjaResponsibleSignedDate =
      this.newSJAForm.SjaResponsibleSignedDate != null
        ? HelperService.formatDateFilter(
            this.newSJAForm.SjaResponsibleSignedDate.formatted
          )
        : null;
    this.newSJAForm.AreaManagerSignedDate =
      this.newSJAForm.AreaManagerSignedDate != null
        ? HelperService.formatDateFilter(
            this.newSJAForm.AreaManagerSignedDate.formatted
          )
        : null;
    this.newSJAForm.ResponsibleExecutionSignedDate =
      this.newSJAForm.ResponsibleExecutionSignedDate != null
        ? HelperService.formatDateFilter(
            this.newSJAForm.ResponsibleExecutionSignedDate.formatted
          )
        : null;
    this.newSJAForm.OthersSignedDate =
      this.newSJAForm.OthersSignedDate != null
        ? HelperService.formatDateFilter(
            this.newSJAForm.OthersSignedDate.formatted
          )
        : null;
    this.newSJAForm.DigitalResponsibleSign =
      this.newSJAForm.DigitalResponsibleSign;
    this.newSJAForm.DigitalAreaManagerSign =
      this.newSJAForm.DigitalAreaManagerSign;
    this.newSJAForm.DigitalExecutionSign = this.newSJAForm.DigitalExecutionSign;
    this.newSJAForm.DigitalOtherSign = this.newSJAForm.DigitalOtherSign;
    this.subscriptions.push(
      this.SJAFormServices.createSjaForm(this.newSJAForm).subscribe((data) => {
        if (data) {
          this.loading = false;
          this.toasterComponent.callToast();
          this.location.back();
        }
      })
    );
  }

  /**
   * add new checklist row
   * @param item
   */
  addItem(item) {
    let index;
    for (
      let i = this.SjaCheckList.indexOf(item) + 1;
      i <= this.SjaCheckList.length;
      i++
    ) {
      let element = this.SjaCheckList[i];
      let IsParentHasChild = this.SjaCheckList.filter(
        (x) => x.ParentId == item.Id
      );
      if (i != this.SjaCheckList.length && IsParentHasChild.length) {
        if (element.IsParent != true) {
          index = i;
        } else {
          break;
        }
      } else if (
        i != this.SjaCheckList.length &&
        IsParentHasChild.length == 0
      ) {
        index = this.SjaCheckList.indexOf(item);
        break;
      } else {
        index = this.SjaCheckList.length - 1;
      }
    }
    let itemToShift = this.SjaCheckList.splice(
      this.SjaCheckList.indexOf(this.SjaCheckList[index]) + 1,
      this.SjaCheckList.length
    );
    let newItem = {
      Id: item.Id + 1,
      Title: '',
      TranslationKey: '',
      Yes: false,
      No: false,
      NotApplicable: false,
      Comments: '',
      Respo: '',
      IsParent: false,
      ParentId: item.Id,
    };
    this.SjaCheckList.push(newItem);
    itemToShift.forEach((i) => {
      ++i.Id;
      this.SjaCheckList.push(i);
    });
  }

  /**
   * delete checklist row
   * @param item
   * @param index
   */
  deleteItem(item, i) {
    if (item.IsParent == false) {
      this.SjaCheckList.splice(this.SjaCheckList.indexOf(item), 1);
    } else if (item.IsParent == true) {
      this.confirmationService.confirm({
        message: this.translate.instant('SJA_DELETE_THIS_RECORD'),
        accept: () => {
          let deleteList = this.SjaCheckList.filter(
            (x) => x.ParentId == item.Id || x.Id == item.Id
          );
          deleteList.forEach((element: any) => {
            this.SjaCheckList.splice(this.SjaCheckList.indexOf(element), 1);
          });
        },
      });
    }
  }

  /**
   * Back to list
   */
  gotoList() {
    this.location.back();
  }

  uncheckExceptYes(item: any) {
    this.SjaCheckList.forEach((element) => {
      if (element.Id == item.Id) {
        element.No = false;
        element.NotApplicable = false;
      }
    });
  }
  uncheckExceptNo(item: any) {
    this.SjaCheckList.forEach((element) => {
      if (element.Id == item.Id) {
        element.Yes = false;
        element.NotApplicable = false;
      }
    });
  }

  uncheckExceptNotApplicable(item: any) {
    this.SjaCheckList.forEach((element) => {
      if (element.Id == item.Id) {
        element.No = false;
        element.Yes = false;
      }
    });
  }

  addNewSection() {
    let addParentItem = {
      Id: this.SjaCheckList.length + 1,
      Title: '',
      TranslationKey: '',
      Yes: false,
      No: false,
      NotApplicable: false,
      Comments: '',
      Respo: '',
      IsParent: true,
      ParentId: 0,
    };
    this.SjaCheckList.push(addParentItem);
  }

  AddSJATitle() {
    this.addTitle = true;
  }

  SaveTitle() {
    let sjaTitleForm = new SjaTitleForm();
    sjaTitleForm.Id = this.sjaTitleId ? this.sjaTitleId : 0;
    sjaTitleForm.SjaTitle = this.SJATitleForm.value.SjaTitle;
    sjaTitleForm.ApplicationId = BaseServices.ApplicationId;
    sjaTitleForm.BusinessId = BaseServices.BusinessId;
    sjaTitleForm.CreatedBy = BaseServices.UserId;
    sjaTitleForm.CreatedOn = new Date();
    sjaTitleForm.Status = true;
    if (sjaTitleForm.Id == 0) {
      this.subscriptions.push(
        this.SJAFormServices.SaveSjaTitle(sjaTitleForm).subscribe(
          (data: any) => {
            if (data != null) {
              this.BindSJATitle();
              this.toasterComponent.callToast();
              this.newSJAForm.SjaTitleId = data.Id;
              this.addTitle = false;
            } else {
              this.isLabelDisabled = true;
              setTimeout(
                function () {
                  this.isLabelDisabled = false;
                }.bind(this),
                3000
              );
            }
          }
        )
      );
    } else {
      this.BindSJATitle();
      this.toasterComponent.callToast();
      this.newSJAForm.SjaTitleId = this.sjaTitleId;
      this.addTitle = false;
    }
    this.clear();
  }

  clear() {
    this.sjaTitleId = 0;
    this.SJATitleForm = this.formBuilder.group({
      SjaTitle: [''],
    });
  }

  BindSJATitle() {
    this.SJATitles = [];
    this.subscriptions.push(
      this.SJAFormServices.GetSJATitleList().subscribe((result) => {
        this.SJATitleList = result;
        if (this.SJATitleList) {
          this.subscriptions.push(
            this.translate.stream('SELECT_SJA_TITLE').subscribe((val) => {
              this.SJATitles = [];
              this.SJATitles.push(
                { label: val.Construction_failure, value: 1 },
                { label: val.Fire_Explosion, value: 2 },
                { label: val.Movable_objects_danger_of_crushing, value: 3 },
                { label: val.Sharp_object, value: 4 },
                { label: val.Falling_object, value: 5 },
                { label: val.Fall, value: 6 },
                { label: val.Heavy_lifting_heavy_materials, value: 7 },
                { label: val.High_low_temperature_top_flakes, value: 8 },
                { label: val.Danger_of_electric_shock, value: 9 },
                { label: val.High_pressure_risk_of_splashing, value: 10 },
                { label: val.Noise_vibration, value: 11 },
                { label: val.Laser_light, value: 12 },
                { label: val.Dust_smoke_gases_toxic_substances, value: 13 },
                { label: val.Lack_lights, value: 14 },
                { label: val.Weather_conditions, value: 15 },
                { label: val.Natural_events, value: 16 },
                { label: val.Work_in_tanks, value: 17 },
                { label: val.Danger_drowning, value: 18 },
                { label: val.Collision, value: 19 }
              );
            })
          );
          this.SJATitleList.forEach((element) => {
            this.SJATitles.push({ label: element.SjaTitle, value: element.Id });
          });
          this.editSJATitle = this.SJATitles;
        }
      })
    );
  }

  EditSJATitle(sjaTitleData: SjaTitleForm) {
    this.sjaTitleId = sjaTitleData.Id;
    this.sjaTitle = sjaTitleData.SjaTitle;
    let result = {
      SjaTitle: sjaTitleData.SjaTitle,
    };
    (<FormGroup>this.SJATitleForm).setValue(result, { onlySelf: true });
  }

  DeleteSJATitle(sjaTitleData: SjaTitleForm) {
    this.subscriptions.push(
      this.SJAFormServices.getSJAFormList(
        this.parentId,
        BaseServices.FeatureKey,
        BaseServices.BusinessId
      ).subscribe((result: any) => {
        if (result != null) {
          let checkTitleUsed = result.filter(
            (x) => x.SjaTitleId === sjaTitleData.value
          );
          if (checkTitleUsed.length > 0) {
            this.isTitleAllocated = true;
            setTimeout(() => {
              this.isTitleAllocated = false;
            }, 3000);
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.SJAFormServices.DeleteSJATitle(
                    sjaTitleData.value
                  ).subscribe((result) => {
                    this.BindSJATitle();
                    this.toasterComponent.callToastDlt();
                  })
                );
              },
            });
          }
        }
      })
    );
  }

  bindDetails() {
    this.SJATitles = [];
    this.editSJATitle = [];
    this.subscriptions.push(
      this.translate.stream('SELECT_SJA_TITLE').subscribe((val) => {
        this.SJATitles = [];
        this.SJATitles.push(
          { label: val.Construction_failure, value: 1 },
          { label: val.Fire_Explosion, value: 2 },
          { label: val.Movable_objects_danger_of_crushing, value: 3 },
          { label: val.Sharp_object, value: 4 },
          { label: val.Falling_object, value: 5 },
          { label: val.Fall, value: 6 },
          { label: val.Heavy_lifting_heavy_materials, value: 7 },
          { label: val.High_low_temperature_top_flakes, value: 8 },
          { label: val.Danger_of_electric_shock, value: 9 },
          { label: val.High_pressure_risk_of_splashing, value: 10 },
          { label: val.Noise_vibration, value: 11 },
          { label: val.Laser_light, value: 12 },
          { label: val.Dust_smoke_gases_toxic_substances, value: 13 },
          { label: val.Lack_lights, value: 14 },
          { label: val.Weather_conditions, value: 15 },
          { label: val.Natural_events, value: 16 },
          { label: val.Work_in_tanks, value: 17 },
          { label: val.Collision, value: 19 },
          { label: val.Danger_drowning, value: 18 }
        );
      })
    );
    let result = this.route.snapshot.data['sjaTitleList'];
    if (result) {
      result.forEach((element: any) => {
        this.SJATitles.push({
          label: element.SjaTitle,
          value: element.Id,
        });
      });
      this.editSJATitle = this.SJATitles;
    }
  }
  dateKeyUp() {
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
