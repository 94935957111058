import { LinksComponent } from './links.component';
import { Route } from '@angular/router';
import { BusinessLinksComponent } from 'src/app/kuba/businesses/components';
import { BusinessLinksListResolver } from 'src/app/kuba/businesses/services/businessLinks.resolver.service';
export const PotalLinkRoutes: Route[] = [
    {
        path: '',
        component: LinksComponent,
        children: [
            {
                path: 'portal-link',
                component: BusinessLinksComponent,
                resolve: {
                    list: BusinessLinksListResolver,
                },
            }
        ]
    }
]
