
export class ElectroInspection {
    Id: number;
    ContractId: number;
    BusinessId: number;
    ClientId: number;
    InspectionNumber: number;
    Title: string;
    Status: number;
    Deadline: string;
    RepetitionId: number;
    NotifyBeforeDeadline: number;
    NotifyBeforeFollowup: number;
    Comments: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    DoneOn: string;
    InspectionExecution: InspectionExecution[];
    InspectionFollowup: InspectionFollowup[];
    ElectroInspectionTasks: ElectroInspectionTasks[];
    ElectroInspectionAttachment : ElectroInspectionAttachment[];
    StartDate: string;
    IsSelfInspection: boolean;
    IsChangeNotifyExecution : boolean;
    IsChangeNotifyFollowup : boolean;
    InspectionNoPrefix:string;
    InspectionNoSuffix:string;
    NeedToRecurInspection: boolean;
    ParentId:number;
    RecurGenerated:boolean;
}
export class InspectionExecution {
    Id: number;
    ExecutionId: number;
    InspectionId: number;
}
export class InspectionFollowup {
    Id: number;
    FollowupId: number;
    InspectionId: number;
}

export class ElectroInspectionTasks {
    Id: number;
    ElectroContractId: number;
    InspectionId: number;
    ContentId: number;
    Status: number;
    ChecklistCompletionId: number;
    IsInspectionTask: boolean;
}
export class InspectionList {
    Id: number;
    InspectionNumber: number;
    InspectionId: number;
    Title: string;
    ContractName: string;
    ClientName: string;
    Deadline: string;
    DoneOn: string;
    status: number;
    StatusText: string;
    ResponsibleUserName: string;
}

export class CheckList {
    Id: number;
    Title: string;
    status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
}

export class InspectionChartModel {
    MonthYear: string;
    CompletedCount: number;
    TimeremainingCount: number;
    NoticePeriodCount: number;
    OverDeadlineCount: number;
}

export class ElectroInspectionAttachment {
    Id: number;
    DeviationId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: string;
    Status: string;
    CreatedBy:number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
}

