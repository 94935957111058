import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import 'rxjs/Rx';

import { TrashServices } from '../services/trash.service';
import { Trash } from './../models/trash';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'trashlist',
    templateUrl: './TrashList.component.html'
})
export class TrashListComponent implements OnInit {
    statuses: SelectItem[];
    public trash: Trash[];
    private subscriptions: Subscription[] = [];
    constructor(
        private http: HttpClient,
        public trashServices: TrashServices,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
              { label: val.SELECT, value: null },
              { label: val.INACTIVE, value: 0 },
              { label: val.ACTIVE, value: 1 }
            );
          }));
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.trash = this.route.snapshot.data['list'];
    }

    confirm(trash) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_PERMANTELY'),
            accept: () => {
                this.subscriptions.push(this.trashServices.delete(trash).subscribe(res => {
                    this.fetchNewsData();
                }));
            }
        });
    }

    fetchNewsData() {
        this.subscriptions.push(this.trashServices.list().subscribe((res:any) => {
            this.trash = res;
        }));
    }
    /**
     * to restore business into list
     * @param businessId {any}
     */
    restoreTrash(businessId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
            accept: () => {
                this.subscriptions.push(this.trashServices.restoreBusiness(businessId)
                    .subscribe(isBusinessRestored => {
                        if (isBusinessRestored) {
                            this.fetchNewsData();
                        }
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
