import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { ProjectAdminList } from './../models/project-admin-list';
import { ProjectAdminServices } from './project-admin.service';

@Injectable()
export class ProjectAdminListResolver implements Resolve<ProjectAdminList[]> {
    constructor(private backend: ProjectAdminServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ProjectAdminList[]> {
        return this.backend.getProjectList(BaseServices.ApplicationId);
    }
}
@Injectable()
export class BusinessNameListResolver implements Resolve<any[]> {
    constructor(private backend: ProjectAdminServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getBusinessList();
    }
}
@Injectable()
export class PortalNameListResolver implements Resolve<any[]> {
    constructor(private backend: ProjectAdminServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getPortalList();
    }
}
@Injectable()
export class AdminProjectListResolver implements Resolve<any[]> {
    constructor(private backend: ProjectAdminServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAdminProject(BaseServices.ApplicationId, BaseServices.UserId);
    }
}
