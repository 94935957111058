export class LarModel {
    id?: number;
    appManualId?: number;
    apManualFolderId?: number;
    applicationId?: number;
    portalId?: number;
    businessId?: number;
    title: string;
    url: string;
    isLaws: boolean;
    isCommon: boolean;
    IsAccess: boolean;
    status: number;
    createdBy: string;
    modifiedBy: string;
    modifiedOn: any;
    createdOn: any;
}

export interface LarEvent {
    eventName: string;
    item: {
        larId?: number;
        larContent?: LarModel;
    };
}
