<div class="main-content">
    <div class="main-heading">
        <span translate>COMPLETE_PROTOCOL</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="completemeasureProtocolForm">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label translate>STATUS</label>
                            <div>
                                <div class="radio-inline" *ngFor="let option of options">
                                    <label>
                                        <input type="radio" formControlName="Status" value="{{option.id}}"
                                          [checked]="option.id === completemeasureProtocolForm.controls['Status'].value">{{option.name.toUpperCase()
                                        | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" hidden>
                            <label for="Status">
                                <span translate>STATUS</span>
                            </label>
                            <div>
                                <input type="text" value="New Issue" pInputText formControlName="Status"
                                  class="ex-full-width" readonly />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span translate>MP_NUMBER</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="number" formControlName="Number" id="Number" class="ex-full-width" />
                            </div>
                            <control-messages
                              [control]="completemeasureProtocolForm.controls['Number']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>MP_NAME</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="text" formControlName="Name" id="Name" class="ex-full-width" />
                            </div>
                            <control-messages
                              [control]="completemeasureProtocolForm.controls['Name']"></control-messages>
                        </div>
                    </div>
                </div>
                <div class="action-btns-wrapper text-left">
                    <button class="btn btn-outline-secondary" (click)="gotoBack()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button type="button" class="btn btn-primary" [disabled]="completemeasureProtocolForm.invalid"
                      (click)="saveChanges()">
                        <span translate>SAVE</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<toaster-component></toaster-component>