<div class="card-body">
    <div class="page-title">
        <span translate>ELECTRICAL_COURSE_OVERVIEW</span>
    </div>
    <div class="row" *ngIf="editMode">
        <div class="col-sm-6">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="6">
                            <span translate>DISTRIBUTION_SHORT_CIRCUIT</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width: 21%;"><label translate>DISTRIBUTION</label></td>
                        <td colspan="5">
                            <p-dropdown #dist [style]="{'width':'100%'}" [(ngModel)]="courseOverview.DistributionId"
                                (onChange)="onCourseDistSelect(dist)" [options]="distributions"
                                placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>PROVIDED_FROM</label></td>
                        <td colspan="2">
                            <input type="text" class="form-control" [(ngModel)]="courseOverview.ProvidedFrom" />
                        </td>
                        <td class="bg-light-grey"><label translate>MEASURED</label></td>
                        <td class="bg-light-grey"><label translate>STATED</label></td>
                        <td class="bg-light-grey"><label translate>CALCULATED</label></td>
                    </tr>
                    <tr>
                        <td><label translate>Ik Max</label></td>
                        <td>
                            <input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.IK_Max" />
                        </td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Max_Type === 1"
                                (onChange)="currentTypeChecked(1, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Max_Type === 2"
                                (onChange)="currentTypeChecked(1, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Max_Type === 3"
                                (onChange)="currentTypeChecked(1, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>Ik Min</label></td>
                        <td>
                            <input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.IK_Min" />
                        </td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Min_Type === 1"
                                (onChange)="currentTypeChecked(2, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Min_Type === 2"
                                (onChange)="currentTypeChecked(2, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Min_Type === 3"
                                (onChange)="currentTypeChecked(2, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>Ij Max</label></td>
                        <td>
                            <input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.IJ_Max" />
                        </td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Max_Type === 1"
                                (onChange)="currentTypeChecked(3, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Max_Type === 2"
                                (onChange)="currentTypeChecked(3, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Max_Type === 3"
                                (onChange)="currentTypeChecked(3, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>Ij Min</label></td>
                        <td>
                            <input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.IJ_Min" />
                        </td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Min_Type === 1"
                                (onChange)="currentTypeChecked(4, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Min_Type === 2"
                                (onChange)="currentTypeChecked(4, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Min_Type === 3"
                                (onChange)="currentTypeChecked(4, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-6">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="5"><span translate>INSTALLATION_DATA</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width: 35%;"><label translate>SYSTEM_VOLTAGE_FREQUENCY</label></td>
                        <td><input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.SystemVoltage" />
                        </td>
                        <td><label>[V]</label></td>
                        <td><input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.SystemFrequency" />
                        </td>
                        <td><label>[Hz]</label></td>
                    </tr>
                    <tr>
                        <td><label translate>POWER_CABLE</label></td>
                        <td><input type="text" class="form-control" (keypress)="onNumber($event,10)" [(ngModel)]="courseOverview.PowerCableSurface" />
                        </td>
                        <td><label>[mm2]</label></td>
                        <td colspan="2">
                            <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="courseOverview.PowerCableMaterial"
                                [options]="cableMaterials" placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>DISTRIBUTION_SYSTEM</label></td>
                        <td colspan="4">
                            <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="courseOverview.DistributionSystem"
                                [options]="distributionSystems" placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>PROTECTION_FORANKOBLET</label></td>
                        <td colspan="4">
                            <input type="text" class="form-control" [(ngModel)]="courseOverview.Protection" />
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>EARTH_ELECTRODE_TYPE</label></td>
                        <td colspan="4">
                            <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="courseOverview.EarthType"
                                [options]="earthTypes" placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>EARTH_CONNECTION_SPOT</label></td>
                        <td colspan="4">
                            <input type="text" class="form-control" [(ngModel)]="courseOverview.EarthConnectionSpot" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="11"><label translate>ELECTRICAL_COURSE_OVERVIEW</label></th>
                    </tr>
                    <tr>
                        <th style="text-align:center" colspan="2"><span translate>COURSE</span></th>
                        <th style="text-align:center;width: 30%;"><span translate>LOAD_DESCRIPTION_EQUIPMENT</span>
                        </th>
                        <th style="text-align:center" colspan="3"><span translate>PROTECTION</span></th>
                        <th style="text-align:center" colspan="3"><span translate>CABLE</span></th>
                        <th style="text-align:center"><span translate>ORDER</span></th>
                        <th style="text-align:center"><span translate>JFB</span></th>
                    </tr>
                    <tr>
                        <th style="text-align:center" colspan="2"></th>
                        <th style="text-align:center"></th>
                        <th style="text-align:center"><span translate>TYPE</span></th>
                        <th style="text-align:center"><span translate>in [a]</span></th>
                        <th style="text-align:center"><span translate>Kar</span></th>
                        <th style="text-align:center"><span translate>S[mm2]</span></th>
                        <th style="text-align:center"><span translate>L[m]</span></th>
                        <th style="text-align:center;width: 8%;"><span translate>R inst.met</span></th>
                        <th style="text-align:center"><span translate>Nr</span></th>
                        <th style="text-align:center"><span translate>[mA]</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of courseDetails">
                        <td class="col-button" style="width:20px">
                            <button type="button" (click)="addItem(item)" class="btn btn-icon">
                                <span class="icon ic-sm icon-add"></span>
                            </button>
                            <button *ngIf="courseDetails.length > 1" type="button" (click)="deleteItem(item)" class="btn btn-icon">
                                <span class="icon ic-sm icon-trash"></span>
                            </button>
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.course" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.loadDiscription" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.protectionType" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.protectionTin_a" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.protectionTkar" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.cableS" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.cableL" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.cableR" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.rekkekl" />
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="item.JFB" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="!editMode">
        <div class="col-sm-6">
            <table class="table table-bordered fixRowHeight45">
                <thead>
                    <tr>
                        <th colspan="6">
                            <span translate>DISTRIBUTION_SHORT_CIRCUIT</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><label translate>DISTRIBUTION</label></td>
                        <td colspan="5">
                            <p-dropdown #dist [style]="{'width':'100%'}" [(ngModel)]="courseOverview.DistributionId"
                            (onChange)="onCourseDistSelect(dist)" placeholder="{{'SELECT'|translate}}" [options]="distributions">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>PROVIDED_FROM</label></td>
                        <td colspan="2"><span>{{courseOverview.ProvidedFrom}}</span></td>
                        <td class="bg-light-grey"><label translate>MEASURED</label></td>
                        <td class="bg-light-grey"><label translate>STATED</label></td>
                        <td class="bg-light-grey"><label translate>CALCULATED</label></td>
                    </tr>
                    <tr>
                        <td><label translate>Ik Max</label></td>
                        <td><span>{{courseOverview.IK_Max}}</span></td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Max_Type === 1" disabled
                                (onChange)="currentTypeChecked(1, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Max_Type === 2" disabled
                                (onChange)="currentTypeChecked(1, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Max_Type === 3" disabled
                                (onChange)="currentTypeChecked(1, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>Ik Min</label></td>
                        <td><span>{{courseOverview.IK_Min}}</span></td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Min_Type === 1" disabled
                                (onChange)="currentTypeChecked(2, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Min_Type === 2" disabled
                                (onChange)="currentTypeChecked(2, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IK_Min_Type === 3" disabled
                                (onChange)="currentTypeChecked(2, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>Ij Max</label></td>
                        <td><span>{{courseOverview.IJ_Max}}</span></td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Max_Type === 1" disabled
                                (onChange)="currentTypeChecked(3, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Max_Type === 2" disabled
                                (onChange)="currentTypeChecked(3, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Max_Type === 3" disabled
                                (onChange)="currentTypeChecked(3, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>Ij Min</label></td>
                        <td><span>{{courseOverview.IJ_Min}}</span></td>
                        <td class="bg-light-grey"><label>[kA]</label></td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Min_Type === 1" disabled
                                (onChange)="currentTypeChecked(4, 1)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Min_Type === 2" disabled
                                (onChange)="currentTypeChecked(4, 2)">
                            </p-checkbox>
                        </td>
                        <td>
                            <p-checkbox binary="true" [ngModel]="courseOverview.IJ_Min_Type === 3" disabled
                                (onChange)="currentTypeChecked(4, 3)">
                            </p-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-6">
            <table class="table table-bordered fixRowHeight45">
                <thead>
                    <tr>
                        <th colspan="5"><span translate>INSTALLATION_DATA</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><label translate>SYSTEM_VOLTAGE_FREQUENCY</label></td>
                        <td><span>{{courseOverview.SystemVoltage}}</span></td>
                        <td><label>[V]</label></td>
                        <td><span>{{courseOverview.SystemFrequency}}</span></td>
                        <td><label>[Hz]</label></td>
                    </tr>
                    <tr>
                        <td><label translate>POWER_CABLE</label></td>
                        <td><span>{{courseOverview.PowerCableSurface}}</span></td>
                        <td><label>[mm2]</label></td>
                        <td colspan="2">
                            <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="courseOverview.PowerCableMaterial"
                                disabled [options]="cableMaterials" placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>DISTRIBUTION_SYSTEM</label></td>
                        <td colspan="4">
                            <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="courseOverview.DistributionSystem"
                                disabled [options]="distributionSystems" placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>PROTECTION_FORANKOBLET</label></td>
                        <td colspan="4"><span>{{courseOverview.Protection}}</span></td>
                    </tr>
                    <tr>
                        <td><label translate>EARTH_ELECTRODE_TYPE</label></td>
                        <td colspan="4">
                            <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="courseOverview.EarthType" disabled
                                [options]="earthTypes" placeholder="{{'SELECT'|translate}}">
                            </p-dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td><label translate>EARTH_CONNECTION_SPOT</label></td>
                        <td colspan="4"><span>{{courseOverview.EarthConnectionSpot}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="12"><label translate>ELECTRICAL_COURSE_OVERVIEW</label></th>
                    </tr>
                    <tr>
                        <th style="text-align:center"><span translate>COURSE</span></th>
                        <th style="text-align:center" colspan="3"><span translate>LOAD_DESCRIPTION_EQUIPMENT</span></th>
                        <th style="text-align:center" colspan="3"><span translate>PROTECTION</span></th>
                        <th style="text-align:center" colspan="3"><span translate>CABLE</span></th>
                        <th style="text-align:center"><span translate>ORDER</span></th>
                        <th style="text-align:center"><span translate>JFB</span></th>
                    </tr>
                    <tr>
                        <th style="text-align:center"></th>
                        <th style="text-align:center" colspan="3"></th>
                        <th style="text-align:center"><span translate>TYPE</span></th>
                        <th style="text-align:center"><span translate>in [a]</span></th>
                        <th style="text-align:center"><span translate>Kar</span></th>
                        <th style="text-align:center"><span translate>S[mm2]</span></th>
                        <th style="text-align:center"><span translate>L[m]</span></th>
                        <th style="text-align:center"><span translate>R inst.met</span></th>
                        <th style="text-align:center"><span translate>Nr</span></th>
                        <th style="text-align:center"><span translate>[mA]</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of courseDetails">
                        <td><span>{{item.course}}</span></td>
                        <td colspan="3"><span>{{item.loadDiscription}}</span></td>
                        <td><span>{{item.protectionType}}</span></td>
                        <td><span>{{item.protectionTin_a}}</span></td>
                        <td><span>{{item.protectionTkar}}</span></td>
                        <td><span>{{item.cableS}}</span></td>
                        <td><span>{{item.cableL}}</span></td>
                        <td><span>{{item.cableR}}</span></td>
                        <td><span>{{item.rekkekl}}</span></td><!-- order -->
                        <td><span>{{item.JFB}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->