import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Observable } from 'rxjs';

import { EmployeePrivateDocumentServices } from './employeeprivatedocument.service';

@Injectable()
export class EmployeePrivateDocResolver implements Resolve<EmployeePrivateDocumentServices[]> {

    constructor(private backend: EmployeePrivateDocumentServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<EmployeePrivateDocumentServices[]> {
        return this.backend.list(+route.params['eid'],1) ;
    }

}

@Injectable()
export class ApprovalDocumentResolver implements Resolve<EmployeePrivateDocumentServices[]> {
    constructor(private backend: EmployeePrivateDocumentServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<EmployeePrivateDocumentServices[]> {
        return this.backend.getApprovalDocuments(BaseServices.UserFeatureKey,BaseServices.BusinessId,BaseServices.PortalId,1);
    }
}

@Injectable()
export class DocumentedTrainingResolver implements Resolve<EmployeePrivateDocumentServices[]> {

    constructor(private backend: EmployeePrivateDocumentServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<EmployeePrivateDocumentServices[]> {
        return this.backend.list(+route.params['eid'],2) ;
    }

}

@Injectable()
export class MultiDocumentedTrainingResolver implements Resolve<EmployeePrivateDocumentServices[]> {
    constructor(private backend: EmployeePrivateDocumentServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<EmployeePrivateDocumentServices[]> {
        return this.backend.getApprovalDocuments(BaseServices.UserFeatureKey,BaseServices.BusinessId,BaseServices.PortalId,2);
    }
}
