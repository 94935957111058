<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table #contactsTable [value]="contactView" dataKey="Id" [(selection)]="selectedContacts" [rows]="10"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}"
            [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" exportFilename="Contact List" styleClass="p-datatable-striped p-datatable-gridlines">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="filter-controls-wrapper" style="display:none">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>
                                        <span translate>TYPE_OF_CONTACT</span>
                                    </label>
                                    <div>
                                        <p-dropdown [options]="typeOfContacts" [style]="{'width':'150px'}"
                                            #typeOfContactsDropdown
                                            (onChange)="onTypeOfContactChanged($event, typeOfContactsDropdown)">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>
                                        <span translate>FUNCTION</span>
                                    </label>
                                    <div>
                                        <p-dropdown [options]="function | dropdownSelectTranslate"
                                            [style]="{'width':'100%'}" #functionDropdown
                                            (onChange)="onfunctionChanged($event, functionDropdown)"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                            <div class="action-btns-group">
                                <button class="btn p-3 filter-toggle">
                                    <span class="icon ic-sm icon-filter"></span>
                                    <span translate>FILTER</span>
                                </button>
                            </div>
                            <div class="action-btns-group">
                                <kuba-export [reportData]="contactsTable.filteredValue || contactsTable.value"
                                    [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'NAME' | translate}}</th>
                    <th>{{'TELEPHONE' | translate}}</th>
                    <th>{{'ROLE' | translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="contactsTable.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                            class="p-column-filter" >
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-contact>
                <tr>
                    <td><span>{{contact.Name}}</span></td>
                    <td><span>{{contact.Telephone}}</span></td>
                    <td><span>{{contact.RoleName}}</span></td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="3">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>