import { VehicleServices } from './services/vehicle.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({

    template: `<router-outlet></router-outlet>`
})
export class VehicleComponent {
    constructor() {
    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
}
