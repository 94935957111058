import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { HelpSupportCategory } from '../../../_models/feature';
import { HelpSupportServices } from '../services/help-support.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { HelpSupport, HelpSupportDetails } from 'src/app/kuba/help-support/models/helpsupport';
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs';


@Component({
    templateUrl: 'help-supportUserList.component.html'
})

export class HelpSupportUserListComponent implements OnInit {

    //#region variable
    moduleName: any;
    about: string;
    isVideos = false;
    videoLink: any;
    notificationUser: any;
    businessId: number;
    notificationTitle: string;
    showNewNotification: boolean;
    notificationDesc: string;
    supportType = 'articles';
    helpsupportList = new HelpSupportDetails();
    helpsupport: any;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param route {ActivatedRoute}
     * @param notificationServices {NotificationServices}
     * @param _fb {FormBuilder}
     */
    constructor(
        private helpsupportServices: HelpSupportServices,
        private _sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private _fb: FormBuilder,
        private translate: TranslateService
    ) {

    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let nid = params['id'];
            switch (+nid) {
                case 2:
                    this.supportType = 'articles';
                    break;
                case 3:
                    this.supportType = 'videos';
                    break;
                case 4:
                    this.supportType = 'questions';
                    break;

                default:
                    this.supportType = 'articles';
                    break;
            }
        }));
        this.businessId = BaseServices.BusinessId;
        this.helpsupportList = JSON.parse(sessionStorage.getItem('helpsupportdetails')!);
        this.BindManualList(this.supportType);
    }

    onTabClick(supportType: string) {
        let helpsupportList: HelpSupport[] = [];
        switch (supportType) {
            case 'articles':
                helpsupportList = this.helpsupportList.Articles;
                break;
            case 'videos':
                helpsupportList = this.helpsupportList.Videos;
                break;
            case 'questions':
                helpsupportList = this.helpsupportList.QuestionsAnswers;
                break;
        }
        this.helpsupport = helpsupportList;
        this.subscriptions.push(this.translate.stream('MODULE_NAMES').subscribe(val => {
            this.moduleName = [];
            this.moduleName.push(
                { label: val.SELECT, value: null },
                { label: val.ABSENCE, value: 1 },
                { label: val.LEAVE, value: 2 },
                { label: val.CLIENT, value: 3 },
                { label: val.CONTACT, value: 4 },
                { label: val.SUBCONTRACTOR, value: 5 },
                { label: val.FOODSAFETY, value: 6 },
                { label: val.FDV, value: 7 },
                { label: val.PROJECT, value: 8 },
                { label: val.FOLLOWUP, value: 9 },
                { label: val.IKCLIENT, value: 10 },
                { label: val.FRAMEWORKAGREEMENT, value: 11 },
                { label: val.SERVICEAGREEMENT, value: 12 },
                { label: val.KUNDEEL, value: 13 }
            );
            for (let i = 0; i < this.helpsupport.length; i++) {
                if (this.helpsupport[i].ModuleName != null) {
                    let name = this.moduleName.filter((x:any) => x.value == this.helpsupport[i].ModuleName)[0].label;
                    this.helpsupport[i].ModuleNameOriginal = name;
                } else {
                    let name = "";
                    this.helpsupport[i].ModuleNameOriginal = name;
                }
            }
        }));
    }

    BindManualList(type: any) {
        let helpsupportList: HelpSupport[] = [];

        switch (type) {
            case 'articles':
                helpsupportList = this.helpsupportList.Articles;
                break;
            case 'videos':
                helpsupportList = this.helpsupportList.Videos;
                break;
            case 'questions':
                helpsupportList = this.helpsupportList.QuestionsAnswers;
                break;
        }
        this.helpsupport = helpsupportList;
        this.subscriptions.push(this.translate.stream('MODULE_NAMES').subscribe(val => {
            this.moduleName = [];
            this.moduleName.push(
                { label: val.SELECT, value: null },
                { label: val.ABSENCE, value: 1 },
                { label: val.LEAVE, value: 2 },
                { label: val.CLIENT, value: 3 },
                { label: val.CONTACT, value: 4 },
                { label: val.SUBCONTRACTOR, value: 5 },
                { label: val.FOODSAFETY, value: 6 },
                { label: val.FDV, value: 7 },
                { label: val.PROJECT, value: 8 },
                { label: val.FOLLOWUP, value: 9 },
                { label: val.IKCLIENT, value: 10 },
                { label: val.FRAMEWORKAGREEMENT, value: 11 },
                { label: val.SERVICEAGREEMENT, value: 12 },
                { label: val.KUNDEEL, value: 13 }
            );
            for (let i = 0; i < this.helpsupport.length; i++) {
                if (this.helpsupport[i].ModuleName != null) {
                    let name = this.moduleName.filter((x:any) => x.value == this.helpsupport[i].ModuleName)[0].label;
                    this.helpsupport[i].ModuleNameOriginal = name;
                } else {
                    let name = "";
                    this.helpsupport[i].ModuleNameOriginal = name;
                }
            }
        }));
    }
    /**
     * show popup
     * @param notification {any}
     */
    showTitlePopup(notificationId: any) {
        this.showNewNotification = true;
        let notification = this.helpsupport.filter(x => x.Id == notificationId)
        if (notification[0].CategoryId === HelpSupportCategory.VIDEOS) {
            this.isVideos = true;
            this.videoLink = this._sanitizer.bypassSecurityTrustResourceUrl(
                notification[0].ContentLink
            );
        }
        else {
            this.isVideos = false;
        }
        this.notificationTitle = notification[0].Title;
        this.notificationDesc = notification[0].Content;
    }
    /**
     * reset popup
     */
    closePopUp() {
        this.showNewNotification = false;
    }
    cancel() {
        this.notificationTitle = '';
        this.notificationDesc = '';
        this.isVideos = false;
    }
    //#endregion

    //#region methods

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion

}
