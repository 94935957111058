import 'rxjs/add/operator/toPromise';

import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { UploadFile, UploadInput, UploadOutput, UploadStatus } from 'ngx-uploader';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { BusinessDepartmentService } from './../../../business-department.services';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { Rights } from './../../../../_models/feature';
import { HelperService } from './../../../../_services/helper.service';
import {
    Employee,
    EmployeeDocument,
    EmployeePosition,
    EmployeeRights,
    EmployeeType,
} from './../../../employees/models/employee';
import { EmployeeServices } from './../../../employees/services/employee.services';
import { BaseServices } from './../../../kuba.services';
import { SaveUsers } from './../../../users/models/user';
import { UserService } from './../../../users/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

declare var pdfMake: any;
declare var dataBulider: any;

@Component({
    selector: 'employee',
    templateUrl: 'mypage-common-details.component.html'
})
export class MypageCommonDetailsComponent implements OnInit {

    additionalData: any;
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    @ViewChild('employeeTypeTable',{static:false}) employeeTypeTable: Table;
    @ViewChild('positionTable',{static:false}) positionTable: Table;
    @ViewChild('departmenttable',{static:false}) departmenttable: Table;
    @ViewChild('subDeptTable',{static:false}) subDeptTable: Table;
    commonDetailsForm: FormGroup;
    userForm: FormGroup;
    approvalTime: any;
    categories: any;
    PositionForm: FormGroup;
    funtions: SelectItem[];
    SubDeptForm: FormGroup;
    EmployeeTypeForm: FormGroup;
    DepartmentForm: FormGroup;
    newDocumentAdded: any = [];
    employeeImage: string;
    IsHidden: Boolean = true;
    employeeTypeId: number;
    positionId: number;
    departmentId: number;
    subDeptId: number;
    userRightsData: any = [];
    rightsData: any = [];
    guestRightsData: any = [];
    isUser = false;
    editorRightsData: any = [];
    editedUser = false;
    editedGuest = false;
    editedEditor = false;
    isFileChoosed = false;
    viewType = false;
    viewPosition = false;
    viewDept = false;
    viewSubDept = false;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    userId: number;
    role: SelectItem[];
    IsHide = true;
    IsHidding: Boolean = false;
    pdf: any;
    paramsSub: any;
    bSubDepartment: any;
    UserId: any;
    users: any;
    userData: any;
    Mobile: SelectItem[];
    subDepartments: SelectItem[];
    percentage: SelectItem[];
    CountryCode: SelectItem[];
    id: any;
    departmentValue: string;
    addCategory = false;
    newDeviations: string[] = [];
    notifySMS: string[] = [];
    notifyEmails: string[] = [];
    showGuests: string[] = [];
    suggestion: string[] = [];
    approvals: string[] = [];
    employeeId: number;
    employees: Employee;
    uploadRequest: any;
    addType = false;
    addPosition = false;
    addDept = false;
    viewCategory = false;
    avatarName: string;
    employee: any;
    departments: SelectItem[];
    editSubDepartments: any = [];
    positions: SelectItem[];
    types: SelectItem[];
    btype: any = [];
    documents: EmployeeDocument[];
    bdepartment: any = [];
    bposition: any = [];
    selectedType = new EmployeeType();
    selectedPosition = new EmployeePosition();
    selectedDept = new Employee();
    selectedSubDept = new Employee();
    uploadedFiles: any = [];
    isEditMyPage: boolean;
    editTypes: any = [];
    editPositions: any = [];
    editDepartments: any = [];
    description: any;
    notes: any;
    sign: any;
    Name: any;
    upConfig: UploaderConfig;
    addSubDept = false;
    viewSubDepartment = false;
    locale = 'en';
    hasChildren = false;
    showType = false;
    showPosition = false;
    showDept = false;
    showMyPageSaveButton: boolean;
    hideSaveUserButton: boolean;
    existsAlerttext: string;
    isNameExists: boolean;
    private subscriptions: Subscription[] = [];

    /**
     * Date picker configuration option
     */
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        selectionTxtFontSize: '14px',
        height: '26px'
    };

    public endDateOptions: IMyDpOptions = this.startDateOptions;
    constructor(
        private fb: FormBuilder,
        public employeeServices: EmployeeServices,
        private route: ActivatedRoute,
        protected location: Location,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private businessDepartmentService: BusinessDepartmentService,
        private translate: TranslateService
    ) {
        this.uploadInput = new EventEmitter<UploadInput>();
        this.UserinitForm();
    }

    /**
     * user signature
     */
    addSignature() {
        this.sign = '';
        let today = new Date();
        let date = today.getDate() + '/' + (today.getMonth()) + '/' + today.getFullYear();
        this.notes = BaseServices.userName;
        this.sign = ` ${this.sign ? this.sign : ''} ${this.notes} ${date}`;
        this.commonDetailsForm.patchValue({ Note: ` ${this.sign}` });
        if (this.description) {
            this.notes = BaseServices.userName;
            this.commonDetailsForm.patchValue({
                Note: ` ${this.description}  ${this.notes} ${date}`
            });
        }
    }
    /**
     * setting formgroup,binding dropdowns,fetching the data for edit
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.additionalData = {
            'ApplicationId': BaseServices.ApplicationId,
            'BusinessId': BaseServices.BusinessId,
            'EmployeeId': BaseServices.FeatureKey,
            'IsPrivateDocument': false,
            'Status': '1',
            'CreatedOn': new Date(),
            'ModifiedBy': BaseServices.UserId,
            'ModifiedOn': new Date(),
        };
        this.upConfig = {
            title: 'EMPLOYEE_DOCUMENT',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/employee/private-docs',
            addtionalData: this.additionalData
        };
        if (BaseServices.UserRole.toLocaleUpperCase() === 'USER') {
            this.isUser = true;
        } else {
            this.isUser = false;
        }

        this.commonDetailsForm = this.fb.group({
            TypeOfEmployeeId: ['', Validators.required],
            Number: [''],
            Name: ['', Validators.required],
            Dob: [''],
            EmployeePositionId: ['', Validators.required],
            Percentage: [''],
            BusinessDepartmentId: [''],
            SubDepartmentId: [''],
            DepartmentLead: [''],
            Address: [''],
            ZipOrCity: [''],
            Location: [''],
            CountryCode: [''],
            Mobile: [''],
            Telephone: [''],
            Fax: [''],
            Email: ['', [ValidationService.emailValidator]],
            StartDate: [''],
            EndDate: [''],
            IsEmailNewDeviation: [''],
            ShowOnDashBoard: [''],
            NotificationBySms: [''],
            NotificationByEmail: [''],
            IsShowToGuest: [''],
            NotifySuggestion: [''],
            MainContact: [''],
            IsApprovalNotice: [''],
            Note: [''],
            ContactFunctionalAreaId: [''],
            OtherCode: [''],
            OtherMobile: ['']
        });

        this.PositionForm = this.fb.group({
            position: ['', Validators.required]
        });
        this.SubDeptForm = this.fb.group({
            SubDepartment: ['', Validators.required]
        });
        this.DepartmentForm = this.fb.group({
            department: ['', Validators.required]
        });
        this.EmployeeTypeForm = this.fb.group({
            employeeType: ['', Validators.required]
        });
        this.subscriptions.push(this.userService.getUsersById(BaseServices.UserId).subscribe((result: any) => {
            if (result) {
                this.employeeId = result.FeatureKey;
                this.subscriptions.push(this.employeeServices.getById(this.employeeId).subscribe(result => {
                    if (result) {
                        this.initForm(result);
                    } else {
                        this.initForm();
                    }
                }));
                if (this.employeeId > 0) {
                    this.subscriptions.push(this.employeeServices.listDoc(this.employeeId).subscribe(result => {
                        this.uploadedFiles = result;
                    }));
                }
            }
        }));

        // let commonDetails = this.route.snapshot.data['edit']
        this.bindDetails();
        this.OnShowSaveButton();
        this.onEditMyPage();
    }
    /**
       * file upload events
       * @param event {any}
       */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.type;
            switch (eventType) {
                case 'remove':
                    this.uploadedFiles = this.uploadedFiles.filter((x: any) => x.id !== +event.id);
                    break;
                default:
                    this.newDocumentAdded.push(event);
                    break;
            }
        }
    }
    initForm(data?: any) {
        let result = data;
        let startDate = null;
        let dob: any;
        let endDate: any;
        if (result) {
            this.employeeImage = (result.Avatar) ? result.Avatar : null;
            this.userId = result.UserId;
            dob = result.Dob ? HelperService.formatInputDate(result.Dob) : null;
            // Start Date Input and Date Range set
            startDate = result.EmploymentFromDate ? HelperService.formatInputDate(result.EmploymentFromDate) : null;
            let startdate: Date = HelperService.formatDate(result.EmploymentFromDate)
                ? new Date(HelperService.formatDate(result.EmploymentFromDate))
                : null!;
            this.endDateOptions = HelperService.setDateRange(this.endDateOptions, startdate, 'startDate');
            // End Date Input and Date Range set
            endDate = result.EmploymentEndDate ? HelperService.formatInputDate(result.EmploymentEndDate) : null;
            let enddate: Date = HelperService.formatDate(result.EmploymentEndDate)
                ? new Date(HelperService.formatDate(result.EmploymentEndDate))
                : null!;
            this.startDateOptions = HelperService.setDateRange(this.startDateOptions, enddate, 'endDate');
            if (this.employeeId) {
                this.description = result.Note;
                this.Name = result.Name;
                // this.bindSubDepartmentDropdown(result.BusinessDepartmentId);
            } else {
                this.Name = 'New Employee';
            }
        } else {
            this.startDateOptions = this.startDateOptions;
            this.endDateOptions = this.startDateOptions;
        }
        let commondetails;
        commondetails = {
            TypeOfEmployeeId: result.TypeOfEmployeeId ? result.TypeOfEmployeeId : '',
            Name: result.Name ? result.Name : '',
            Number: result.Number ? result.Number : '',
            Dob: dob,
            BusinessDepartmentId: result.BusinessDepartmentId ? result.BusinessDepartmentId : '',
            SubDepartmentId: result.SubDepartmentId ? result.SubDepartmentId : '',
            CountryCode: result.CountryCode ? result.CountryCode : '',
            Mobile: result.Mobile ? result.Mobile : '',
            Email: result.Email ? result.Email : '',
            EmployeePositionId: result.EmployeePositionId,
            Percentage: result.Percentage ? result.Percentage : '',
            DepartmentLead: result.DepartmentLead ? result.DepartmentLead : false,
            Address: result.Address ? result.Address : '',
            ZipOrCity: result.ZipOrCity ? result.ZipOrCity : '',
            Location: result.Location ? result.Location : '',
            Telephone: result.Telephone ? result.Telephone : '',
            Fax: result.Fax ? result.Fax : '',
            EndDate: startDate,
            StartDate: endDate,
            IsEmailNewDeviation: result.IsEmailNewDeviation ? result.IsEmailNewDeviation : false,
            ShowOnDashBoard: result.ShowInDashboard ? result.ShowInDashboard : false,
            NotificationBySms: result.IsSms ? result.IsSms : false,
            NotificationByEmail: result.IsEmail ? result.IsEmail : false,
            IsShowToGuest: result.IsShowToGuest ? result.IsShowToGuest : false,
            NotifySuggestion: result.NotifySuggestion ? result.NotifySuggestion : false,
            MainContact: result.IsMainContact ? result.IsMainContact : false,
            IsApprovalNotice: result.IsApprovalNotice ? result.IsApprovalNotice : false,
            Note: result.Note ? result.Note : '',
            OtherMobile: result.Mobile ? result.Mobile : '',
            OtherCode: result.CountryCode ? result.CountryCode : '',
            ContactFunctionalAreaId: result.ContactFunctionalAreaId ? result.ContactFunctionalAreaId : ''
        };
        (<FormGroup>this.commonDetailsForm).setValue(commondetails, {
            onlySelf: true
        });
        let today = result.ApprovalDateTime ? new Date(result.ApprovalDateTime) : null;
        if (today) {
            let date = today.getMonth() + '/' + today.getDate() + '/' + today.getFullYear();
            let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
            let dateTime = date + ' ' + time;
            this.approvalTime = dateTime;
        }
    }

    /**
     * Event handler for Start date
     * @param event
     */

    onStartDateChanged(event: IMyDateModel) {
        this.endDateOptions = this.setDateInput(this.endDateOptions, event, 'startDate');
    }

    /**
     * Event handler for End date
     * @param event
     */
    onEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.startDateOptions = this.setDateInput(this.startDateOptions, event, 'endDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.startDateOptions);
            copyConfig.disableSince = this.getCurrentDate();
            this.startDateOptions = copyConfig;
        }
    }
    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate()
        };
    }

    /**
     * Method for setting date range
     * @param dateConfig
     * @param event
     * @param type
     */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }

    /**
     * create and update form values
     */
    saveChanges() {
        let employee = new Employee();
        employee = this.commonDetailsForm.value;
        employee.BusinessId = BaseServices.BusinessId;
        employee.Mobile = this.commonDetailsForm.value.Mobile
            ? this.commonDetailsForm.value.Mobile
            : this.commonDetailsForm.value.OtherMobile;
        employee.CountryCode = this.commonDetailsForm.value.CountryCode
            ? this.commonDetailsForm.value.CountryCode
            : this.commonDetailsForm.value.OtherCode;
        employee.ContactFunctionalAreaId = this.commonDetailsForm.value.ContactFunctionalAreaId
            ? this.commonDetailsForm.value.ContactFunctionalAreaId
            : '';
        employee.Dob = HelperService.formatDateForFilter(
            this.commonDetailsForm.value.Dob ? this.commonDetailsForm.value.Dob.formatted : null);
        employee.ApprovalDateTime = this.commonDetailsForm.value.IsApprovalNotice ? this.approvalTime : '';
        employee.ShowInDashboard = this.commonDetailsForm.value.ShowOnDashBoard
            ? this.commonDetailsForm.value.ShowOnDashBoard
            : false;
        employee.IsMainContact = this.commonDetailsForm.value.MainContact
            ? this.commonDetailsForm.value.MainContact
            : false;
        employee.TypeOfEmployeeId = this.commonDetailsForm.value.TypeOfEmployeeId
            ? this.commonDetailsForm.value.TypeOfEmployeeId
            : '';
        employee.BusinessDepartmentId = this.commonDetailsForm.value.BusinessDepartmentId
            ? this.commonDetailsForm.value.BusinessDepartmentId
            : '';
        employee.EmployeePositionId = this.commonDetailsForm.value.EmployeePositionId
            ? this.commonDetailsForm.value.EmployeePositionId
            : '';
        employee.Status = '1';
        employee.Avatar = this.employeeImage;
        employee.EmploymentFromDate = this.commonDetailsForm.value.StartDate
            ? this.commonDetailsForm.value.StartDate.formatted
            : null;
        employee.EmploymentEndDate = this.commonDetailsForm.value.EndDate
            ? this.commonDetailsForm.value.EndDate.formatted
            : null;
        employee.Note = this.commonDetailsForm.value.Note ? this.commonDetailsForm.value.Note : '';
        if (this.employeeId) {
            employee.Id = this.employeeId;
            employee.BusinessId = BaseServices.BusinessId;
            this.subscriptions.push(this.employeeServices.update(this.employeeId, employee).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.bindDocuments(this.employeeId);
                }
            }));
        } else {
            employee.Id = (this.employeeId) ? this.employeeId : 0;
            employee.BusinessId = BaseServices.BusinessId;
            this.subscriptions.push(this.employeeServices.add(employee).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.bindDocuments(this.employeeId);
                }
            }));
        }
    }

    /**
     * binding emp doc
     * @param id {number}
     */
    bindDocuments(id: number) {
        this.subscriptions.push(this.employeeServices.getallDocuments(id).subscribe((res: any) => {
            this.documents = res;
        }));
    }
    /**
     * delete emp doc
     * @param id {any}
     */
    deleteDocument(id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeServices.deleteDocument(id).subscribe(result => {
                    if (result) {
                        this.bindDocuments(this.employeeId);
                    }
                }));
            }
        });
    }
    /**
         * binding department dropdown
         */
    bindDepartmentDropDown() {
        this.departments = [];
        this.editDepartments = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(result => {
            if (result) {
                result.forEach((element:any) => {
                    this.departments.push({
                        label: element.Name,
                        value: element.Id
                    });
                    this.editDepartments.push({ Name: element.Name, Id: element.Id });
                });
            }
        }));
    }

    /**
     * changing country code dropdown to textbox
     */
    changeCountryCode() {
        this.IsHide = false;
        this.IsHidding = true;
    }

    /**
     * changing country code textbox to dropdown
     */
    changeToCountryCodeDropDown() {
        this.IsHide = true;
        this.IsHidding = false;
    }
    /**
     * upload employee image
     * @param event
     */
    onUpload(event:any) {
        for (let file of event.files) {
            this.avatarName = file.name;
        }
    }
    /**
     * upload doc
     */
    onUploadDoc(event:any) {
        let employeedocument: EmployeeDocument[] = [];
        for (let file of event.files) {
            let doc = new EmployeeDocument();
            doc.Id = 0;
            doc.FileName = file.name;
            doc.EmployeeId = this.employeeId;
            this.uploadedFiles.push(doc);
        }
    }
    /**
     * To edit Type
     * @param employeeType {any}
     */
    editType(employeeType: any) {
        this.employeeTypeId = employeeType.Id;
        this.EmployeeTypeForm = this.fb.group({
            employeeType: employeeType.Type
        });
    }
    /**
     * To edit Position
     * @param employeePosition {any}
     */
    editPosition(employeePosition: any) {
        this.positionId = employeePosition.Id;
        this.PositionForm = this.fb.group({
            position: employeePosition.Name
        });
    }
    /**
     * To edit Department
     * @param employeeDept {any}
     */
    editDept(employeeDept: any) {
        this.departmentId = employeeDept.Id;
        this.DepartmentForm = this.fb.group({
            department: employeeDept.Name
        });
    }
    /**
     * To edit sub Department
     * @param empSubDept {any}
     */
    editSubDept(empSubDept: any) {
        this.subDeptId = empSubDept.Id;
        this.SubDeptForm = this.fb.group({
            SubDepartment: empSubDept.Name
        });
    }
    /**
     * department dropdown change event
     * @param e
     * @param departmentDropdown
     */
    onDepartmentDropDownChanged(e:any, departmentDropdown:any) {
        this.IsHidden = false;
        this.departmentValue = departmentDropdown.selectedOption.value;
        this.bindSubDepartmentDropdown(this.departmentValue);
    }
    /**
     * binding sub department dropdown
     * @param value
     */
    bindSubDepartmentDropdown(value:any) {
        this.subDepartments = [];
        this.editSubDepartments = [];
        this.subDepartments.push({
            label: 'SELECT',
            value: null
        });
        this.subscriptions.push(this.employeeServices.getAllSubDepartments(value).subscribe(result => {
            this.bSubDepartment = result;
            this.bSubDepartment.forEach((element:any) => {
                this.subDepartments.push({
                    label: element.Name,
                    value: element.Id
                });
            });
        }));
    }

    /**
     * add and update type of employee
     */
    saveType() {
        let employeeType = new EmployeeType();
        employeeType.Id = this.employeeTypeId ? this.employeeTypeId : 0;
        employeeType.Type = this.EmployeeTypeForm.value.employeeType;
        employeeType.Status = '1';
        employeeType.BusinessId = BaseServices.BusinessId;
        if (employeeType.Id > 0) {
            this.subscriptions.push(this.employeeServices.updateType(employeeType).subscribe(isTypeUpdtaed => {
                this.bindEmployeeTypeDropdown(isTypeUpdtaed);
            }));
        } else {
            this.subscriptions.push(this.employeeServices.createType(employeeType)
                .subscribe(isTypeAdded => {
                    this.bindEmployeeTypeDropdown(isTypeAdded);
                }));
        }
    }

    get editCategoriesFormGroups() {
        return this.userForm.get('editorCategories') as FormArray
    }
    get guestFormGroups() {
        return this.userForm.get('guestCategories') as FormArray
    }
    get userFormGroups() {
        return this.userForm.get('userCategories') as FormArray
    }
    /**
    * employee type after event trigger
    * @param employeeType {any}
    */
    bindEmployeeTypeDropdown(employeeType: any) {
        if (employeeType) {
            this.toasterComponent.callToast();
            this.bindEmployeeType(BaseServices.BusinessId);
            this.commonDetailsForm.get('TypeOfEmployee')!.patchValue(employeeType.Id);
            this.addType = false;
        } else {
            this.showAlert();
        }
    }
    /**
     * to show alert for duplicate
     */
    showAlert() {
        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
        this.isNameExists = true;
        setTimeout(() => {
            this.isNameExists = false;
        }, 3000);
    }
    /**
     * add and update employee position
     */
    savePosition() {
        let employeePosition = new EmployeePosition();
        employeePosition.Id = (this.positionId) ? this.positionId : 0;
        employeePosition.Name = this.PositionForm.value.position;
        employeePosition.Status = '1';
        employeePosition.BusinessId = BaseServices.BusinessId;
        if (employeePosition.Id > 0) {
            this.subscriptions.push(this.employeeServices.updatePosition(employeePosition).subscribe(updatePosition => {
                this.bindPositionDropdown(updatePosition);
            }));
        } else {
            this.subscriptions.push(this.employeeServices.createPosition(employeePosition)
                .subscribe(newPosition => {
                    this.bindPositionDropdown(newPosition);
                }));
        }
    }

    /**
     * position bind after event trigger
     * @param position {any}
     */
    bindPositionDropdown(position: any) {
        if (position) {
            this.addPosition = false;
            this.toasterComponent.callToast();
            this.bindPosition(BaseServices.BusinessId);
            this.commonDetailsForm.get('EmployeePosition')!.patchValue(position.Id);
        } else {
            this.showAlert();
        }
    }
    /**
    * binding department dropdown
    */
    bindDepartmentDropdown() {
        this.departments = [];
        this.editDepartments = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
        }));
        let businessDepartment = this.route.snapshot.data['businessdropdown']
        if (businessDepartment) {
            businessDepartment.forEach((element: any) => {
                this.departments.push({ label: element.Name, value: element.Id });
                this.editDepartments.push({ Name: element.Name, Id: element.Id });
            });
        }

    }
    /**
     * add and update department
     */
    saveDept() {
        let employeeDept = new Employee();
        employeeDept.Id = (this.departmentId) ? this.departmentId : 0;
        employeeDept.Name = this.DepartmentForm.value.department;
        employeeDept.Status = '1';
        employeeDept.BusinessId = BaseServices.BusinessId;
        employeeDept.ApplicationId = BaseServices.ApplicationId;
        if (employeeDept.Id > 0) {
            this.subscriptions.push(this.employeeServices.updateDept(employeeDept).subscribe(isDepartmentUpdated => {
                this.bindDeptDropdown(isDepartmentUpdated);
            }));
        } else {
            this.subscriptions.push(this.employeeServices.createDept(employeeDept)
                .subscribe(isDepartmentAdded => {
                    this.bindDeptDropdown(isDepartmentAdded);
                }));
        }
    }

    /**
     * department after event trigger
     * @param department {any}
     */
    bindDeptDropdown(department: any) {
        if (department) {
            this.departmentValue = department.Id;
            this.toasterComponent.callToast();
            this.bindDepartment();
            this.commonDetailsForm.get('BusinessDepartmentId')!.patchValue(department.Id);
            this.addDept = false;
            this.bindSubDepartmentDropdown(department.Id);
        } else {
            this.showAlert();
        }
    }
    // refresh the department dropdown
    bindDepartment() {
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(department => {
            if (department) {
                this.departments = [];
                department.forEach((element: any) => {
                    this.departments.push({ label: element.Name, value: element.Id });
                });
                this.editDepartments = department;
            }
        }
        ))
    }
    /**
     * add and update sub department
     */
    saveSubDept() {
        let employeeSubDept = new Employee();
        employeeSubDept.Id = (this.subDeptId) ? this.subDeptId : 0;
        employeeSubDept.Name = this.SubDeptForm.value.SubDepartment;
        employeeSubDept.Status = '1';
        employeeSubDept.BusinessId = BaseServices.BusinessId;
        employeeSubDept.ApplicationId = BaseServices.ApplicationId;
        employeeSubDept.ParentId = this.departmentValue;
        if (employeeSubDept.Id > 0) {
            this.subscriptions.push(this.employeeServices.updateDept(employeeSubDept).subscribe(updateSubDept => {
                this.bindSubDeptDropdown(updateSubDept);
            }));
        } else {
            this.subscriptions.push(this.employeeServices.createDept(employeeSubDept)
                .subscribe(addSubDept => {
                    this.bindSubDeptDropdown(addSubDept);
                }));
        }
    }
    /**
    * bind sub dept after event trigger
    * @param subDeptInfo {any}
    */
    bindSubDeptDropdown(subDeptInfo: any) {
        if (subDeptInfo) {
            this.addSubDept = false;
            this.toasterComponent.callToast();
            this.bindSubDepartment(this.departmentValue);
            this.commonDetailsForm.get('SubDepartmentId')!.patchValue(subDeptInfo.Id);
        } else {
            this.showAlert();
        }
    }

    /**
        * Refresh the sub department after update
        * @param value {any}
        */
    bindSubDepartment(value: any) {
        this.subscriptions.push(this.employeeServices.getAllSubDepartments(value).subscribe((res: any) => {
            res.forEach((element: any) => {
                this.subDepartments = [];
                if (res) {
                    this.subDepartments.push({ label: element.Name, value: element.Id });
                }
                this.editSubDepartments = res;
            });
        }))
    }
    /**
     * Binding position dropdown
     */
    bindPositiondropdown() {
        this.positions = [];
        this.editPositions = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.positions = [];
            this.positions.push(
                { label: val.SELECT, value: null }
            );
        }));
        let positions = this.route.snapshot.data['positiondropdown']
        if (positions) {
            positions.forEach((element: any) => {
                this.positions.push({ label: element.Name, value: element.Id });
                this.editPositions.push({ Name: element.Name, Id: element.Id });
            });
        }

    }
    /**
     * Refreshing Position Dropdown after update
     * @param busId {any}
     */
    bindPosition(busId: any) {
        this.subscriptions.push(this.employeeServices.getAllByPosition(busId).subscribe(res => {
            this.positions = [];
            res.forEach((position: any) => {
                this.positions.push({ label: position.Name, value: position.Id });
            });
            this.editPositions = res;
        }))
    }
    /**
     * Binding employee dropdown
     */
    bindTypeofEmployeeDropdown() {
        this.types = [];
        this.editTypes = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.types = [];
            this.types.push(
                { label: val.SELECT, value: null }
            );
        }));
        let employeeType = this.route.snapshot.data['employee'];

        if (employeeType) {
            const uniqueTypeSet = new Set<string>(); // Use a Set to store unique types

            employeeType.forEach((typeOfEmp: any) => {
                const typeLabel = typeOfEmp.Type;
                const typeId = typeOfEmp.Id;

                // Check if the typeLabel is unique before pushing
                if (!uniqueTypeSet.has(typeLabel)) {
                    uniqueTypeSet.add(typeLabel);

                    // Push into types
                    this.types.push({ label: typeLabel, value: typeId });

                    // Push into editTypes
                    this.editTypes.push({ Type: typeLabel, Id: typeId });
                }
            });
        }
    }
    /**
     * Binding employee type
     * @param busId {any}
     */
    bindEmployeeType(busId: any) {
        this.subscriptions.push(this.employeeServices.getAllByType(busId).subscribe(
            res => {
                this.types = [];
                res.forEach(empType => {
                    this.types.push({ label: empType.Type, value: empType.Id });
                });
                this.editTypes = res;
            }));
    }
    /**
   * employee type popup control clear
    */
    onBeforeEmployeeTypeDialogHide() {
        this.EmployeeTypeForm.reset();
        this.employeeTypeTable.reset();
        this.employeeTypeId = 0;
    }
    /**
    * position popup control clear
     */
    onBeforePositionDialogHide() {
        this.PositionForm.reset();
        this.positionTable.reset();
        this.positionId = 0;
    }
    /**
    * department popup clear
    */
    onBeforeDepartmentDialogHide() {
        this.DepartmentForm.reset();
        this.departmenttable.reset();
        this.departmentId = 0;
    }
    /**
    * sub department popup clear
    */
    onBeforeSubDepartmentDialogHide() {
        this.SubDeptForm.reset();
        this.subDeptTable.reset();
        this.subDeptId = 0;
    }
    /**
     * deleting subdepartment
     * @param empSubdept
     */
    deleteSubDept(empSubdept:any) {
        let deleteSubDeptId = empSubdept.Id;
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeServices.deleteSubDepartment(deleteSubDeptId).subscribe(result => {
                    this.bindSubDepartment(this.departmentValue);
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }
    deleteDepartment(department:any) {
        let deleteDeptId = department.Id;
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeServices.deleteDepartment(deleteDeptId).subscribe(result => {
                    this.bindDepartment();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    deletePosition(position:any) {
        let deletePosition = position.Id;
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeServices.deletePosition(deletePosition).subscribe(result => {
                    this.bindPosition(BaseServices.BusinessId);
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }


    /**
     * delete employee type
     * @param employeeType
     */
    deleteType(employeeType:any) {
        let deleteTypeid = employeeType.Id;
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeServices.deleteType(deleteTypeid).subscribe(result => {
                    if (result) {
                        this.bindEmployeeType(BaseServices.BusinessId);
                        this.toasterComponent.callToastDlt();
                    }
                }));
            }
        });
    }
    gotoList() {
        this.location.back();
    }
    /**
     * Initialize the user form
     * @param data {any}
     */
    UserinitForm(data?: any) {
        let Id: number;
        let Name = '';
        let Email = '';
        let Username = '';
        let Password = '';
        let RoleId: number;
        let Status = '';
        let CountryCode = '';
        let Mobile = '';
        let userCategories: string;
        let guestCategories: string;
        let editorCategories: string;
        if (data) {
            Id = data.Id ? +data.Id : 0;
            Name = data.Name ? data.Name : '';
            Email = data.Email ? data.Email : '';
            Username = data.Username ? data.Username : '';
            Password = data.Password ? data.Password : '';
            RoleId = data.RoleId ? +data.RoleId : 0;
            Status = data.Status ? data.Status : '1';
            CountryCode = data.CountryCode ? data.CountryCode : '';
            Mobile = data.Mobile ? data.Mobile : '';
            // this.rightsData = data.Rights ? JSON.parse(data.Rights) : '';
            if (RoleId === 3) {
                // editor
                this.rightsData = data.Rights ? JSON.parse(data.Rights) : this.editorRightsData;
            } else if (RoleId === 4) {
                // guest
                this.rightsData = data.Rights ? JSON.parse(data.Rights) : this.guestRightsData;
            } else if (RoleId === 5) {
                // user
                this.rightsData = data.Rights ? JSON.parse(data.Rights) : this.userRightsData;
            }
        }

        let allCategories: FormArray | any= new FormArray([]);
        let gCategories: FormArray | any= new FormArray([]);
        let eCategories: FormArray | any= new FormArray([]);
        this.userForm = new FormGroup({
            Id: new FormControl(Id!),
            Name: new FormControl(Name),
            Email: new FormControl(Email),
            Username: new FormControl(Username),
            Password: new FormControl(Password),
            RoleId: new FormControl(RoleId!),
            Status: new FormControl(Status),
            CountryCode: new FormControl(CountryCode),
            Mobile: new FormControl(Mobile),
            userCategories: allCategories,
            guestCategories: gCategories,
            editorCategories: eCategories
        });
        if (RoleId! > 0) {
            this.setRoleVisiblity(RoleId!);
        }
        this.bindRightsCheckbox(data);
    }

    onRoleDropDownChanged(e:any) {
        this.setRoleVisiblity(e.value);
        // this.bindRightsCheckbox();
    }

    onRightsCheckboxChange(e:any, rightsId:any) {
        // this.rightsData = rights;
        if (this.rightsData) {
            _.each(this.rightsData, (x: EmployeeRights) => {
                if (x.id === rightsId) {
                    x.isChecked = e.target.checked;
                }
            });
        }
    }
    arrayCompare(list: any, key: number) {
        let isMatched = false;
        let dataList = JSON.parse(list || null);
        if (dataList && dataList.length > 0) {
            for (let index = 0; index < dataList.length; index++) {
                let element = dataList[index];
                if (element.id === key) {
                    return element.isChecked;
                }
            }
        }
        return isMatched;
    }

    setRoleVisiblity(val: number) {
        if (val === 3) { // editor
            this.rightsData = this.editorRightsData;
            this.editedEditor = true;
            this.editedGuest = false;
            this.editedUser = false;
        }
        if (val === 4) { // guest
            this.editedGuest = true;
            this.editedEditor = false;
            this.editedUser = false;
            this.rightsData = this.guestRightsData;
        }
        if (val === 5) { // user
            this.editedUser = true;
            this.editedEditor = false;
            this.editedGuest = false;
            this.rightsData = this.userRightsData;
        }
    }
    bindRightsCheckbox(data?: any) {
        this.userRightsData = [
            {
                id: '1',
                name: 'ACTIVE_ACCOUNT',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '2',
                name: 'EDIT_MY_PAGE',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '3',
                name: 'EDIT_MANUALS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '4',
                name: 'ADMINISTRATE_ACTIVITIES',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '5',
                name: 'ADMINISTRATE_RISK_ANALYSIS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '6',
                name: 'ADMINISTRATE_PROJECTS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '7',
                name: 'ADMINISTRATE_FDV',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '8',
                name: 'ADMINISTRATE_INTERNAL_CONTROL',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '9',
                name: 'ADMINISTRATE_INTERNAL_NEWS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '10',
                name: 'APPROVE_ABSENCE',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '11',
                name: 'APPROVE_LEAVE',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '12',
                name: 'APPROVE_TIMETABLE',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '13',
                name: 'ADD_EMPLOYEE',
                isChecked: false,
                rightsType: 2
            },
            {
                id: '14',
                name: 'DEPARTMENT_EMPLOYEE_RIGHTS',
                isChecked: false,
                rightsType: 2
            },
            {
                id: '15',
                name: 'HIDE_EMPLOYEE_LIST',
                isChecked: false,
                rightsType: 2
            },
            {
                id: '16',
                name: 'ADMINISTRATE_DEVIATION',
                isChecked: false,
                rightsType: 3
            },
            {
                id: '17',
                name: 'VIEW_DEVIATION',
                isChecked: false,
                rightsType: 3
            },
            {
                id: '18',
                name: 'DISPLAY_OWN_DEVIATIONS',
                isChecked: false,
                rightsType: 3
            },
            {
                id: '19',
                name: 'DEVIATIONS_OWN_DEPARTMENT',
                isChecked: false,
                rightsType: 3
            }, 
            {
                id: '48',
                name: 'ADMINISTRATE_OTHER_SYSTEMS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '49',
                name: 'ADMINISTRATE_QUALITY_SYSTEM',
                isChecked: false,
                rightsType: 1
            },
            {
                'id': '51',
                'name': 'ADMINISTRATE_KUBA_CONTROL',
                'isChecked': false,
                'rightsType': 1
            },
            {
                'id': '38',
                'name': 'ADMINISTRATE_FOOD_SAFETY',
                'isChecked': false,
                'rightsType': 1
            },
            {
                'id': '52',
                'name': 'ADMINISTRATE_FLEXI',
                'isChecked': false,
                'rightsType': 1
            }
        ];

        let allCategories: FormArray | any= new FormArray([]);
        const userControl = <FormArray>this.userForm.get('userCategories');
        for (let i = 0; i < this.userRightsData.length; i++) {
            let fg = new FormGroup({});
            fg.addControl(
                `${this.userRightsData[i].id}`,
                new FormControl(
                    (data)
                        ? this.arrayCompare(data.Rights, this.userRightsData[i].id)
                        : this.userRightsData[i].isChecked
                )
            );
            userControl.push(fg);
        }
        this.guestRightsData = [
            {
                id: '1',
                name: 'ACTIVE_ACCOUNT',
                isChecked: false,
                rightsType: [1, 2, 3]
            },
            {
                id: '10',
                name: 'APPROVE_ABSENCE',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '11',
                name: 'APPROVE_LEAVE',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '12',
                name: 'APPROVE_TIMETABLE',
                isChecked: false,
                rightsType: 1
            }
        ];

        const guestcontrol = <FormArray>this.userForm.get('guestCategories');
        for (let j = 0; j < this.guestRightsData.length; j++) {
            let ft = new FormGroup({});
            ft.addControl(
                `${this.guestRightsData[j].id}`,
                new FormControl(
                    (data)
                        ? this.arrayCompare(data.Rights, this.guestRightsData[j].id)
                        : this.guestRightsData[j].isChecked
                )
            );
            guestcontrol.push(ft);
        }

        this.editorRightsData = [
            {
                id: '1',
                name: 'ACTIVE_ACCOUNT',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '20',
                name: 'DEVIATION_RIGHTS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '21',
                name: 'VIEW_MANUALS',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '22',
                name: 'CHOOSE_PROJECT_FDV',
                isChecked: false,
                rightsType: 1
            },
            {
                id: '15',
                name: 'HIDE_EMPLOYEE_LIST',
                isChecked: false,
                rightsType: 2
            },
            {
                id: '17',
                name: 'VIEW_DEVIATION',
                isChecked: false,
                rightsType: 3
            },
            {
                id: '18',
                name: 'DISPLAY_OWN_DEVIATIONS',
                isChecked: false,
                rightsType: 3
            },
            {
                id: '19',
                name: 'DEVIATIONS_OWN_DEPARTMENT',
                isChecked: false,
                rightsType: 3
            }
        ];

        const editorControl = <FormArray>this.userForm.get('editorCategories');
        for (let k = 0; k < this.editorRightsData.length; k++) {
            let fk = new FormGroup({});
            fk.addControl(
                `${this.editorRightsData[k].id}`,
                new FormControl(
                    (data)
                        ? this.arrayCompare(data.Rights, this.editorRightsData[k].id)
                        : this.editorRightsData[k].isChecked
                )
            );
            editorControl.push(fk);
        }
    }

    saveAndNewUser() {
        this.saveChanges();
        this.addCategory = true;
        let roleId: number;
        if (this.userId > 0) {
            this.subscriptions.push(this.userService.getUsersById(this.userId).subscribe(data => {
                this.initForm(data);
                this.addCategory = true;
            }));
        } else {
            let userData = {
                Id: this.userId ? this.userId : 0,
                Name: this.commonDetailsForm.value.Name,
                Email: this.commonDetailsForm.value.Email,
                Username: this.commonDetailsForm.value.Email,
                CountryCode: this.commonDetailsForm.value.CountryCode,
                Mobile: this.commonDetailsForm.value.Mobile,
                RoleId: ''
            };
            this.UserinitForm(userData);
            this.addCategory = true;
        }
    }
    /**
     * generating random password
     */
    generateRandomPassword() {
        let randomstring = Math.random()
            .toString(36)
            .slice(-8);
        this.userForm.get('Password')!.patchValue(randomstring);
    }
    bindDropdown(value:any) {
        if (value === '3') {
            this.rightsData = [
                {
                    id: '1',
                    name: 'ACTIVE_ACCOUNT',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '10',
                    name: 'APPROVE_ABSENCE',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '11',
                    name: 'APPROVE_LEAVE',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '12',
                    name: 'APPROVE_TIMETABLE',
                    isChecked: false,
                    rightsType: 1
                }
            ];
        }
        if (value === '5') {
            this.rightsData = [
                {
                    id: '1',
                    name: 'ACTIVE_ACCOUNT',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '2',
                    name: 'EDIT_MY_PAGE',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '3',
                    name: 'EDIT_MANUALS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '4',
                    name: 'ADMINISTRATE_ACTIVITIES',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '5',
                    name: 'ADMINISTRATE_RISK_ANALYSIS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '6',
                    name: 'ADMINISTRATE_PROJECTS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '7',
                    name: 'ADMINISTRATE_FDV',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '8',
                    name: 'ADMINISTRATE_INTERNAL_CONTROL',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '9',
                    name: 'ADMINISTRATE_INTERNAL_NEWS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '10',
                    name: 'APPROVE_ABSENCE',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '11',
                    name: 'APPROVE_LEAVE',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '12',
                    name: 'APPROVE_TIMETABLE',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '13',
                    name: 'ADD_EMPLOYEE',
                    isChecked: false,
                    rightsType: 2
                },
                {
                    id: '14',
                    name: 'DEPARTMENT_EMPLOYEE_RIGHTS',
                    isChecked: false,
                    rightsType: 2
                },
                {
                    id: '15',
                    name: 'HIDE_EMPLOYEE_LIST',
                    isChecked: false,
                    rightsType: 2
                },
                {
                    id: '16',
                    name: 'ADMINISTRATE_DEVIATION',
                    isChecked: false,
                    rightsType: 3
                },
                {
                    id: '17',
                    name: 'VIEW_DEVIATION',
                    isChecked: false,
                    rightsType: 3
                },
                {
                    id: '18',
                    name: 'DISPLAY_OWN_DEVIATIONS',
                    isChecked: false,
                    rightsType: 3
                },
                {
                    id: '19',
                    name: 'DEVIATIONS_OWN_DEPARTMENT',
                    isChecked: false,
                    rightsType: 3
                },
                {
                    id: '48',
                    name: 'ADMINISTRATE_OTHER_SYSTEMS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '49',
                    name: 'ADMINISTRATE_QUALITY_SYSTEM',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    'id': '51',
                    'name': 'ADMINISTRATE_KUBA_CONTROL',
                    'isChecked': false,
                    'rightsType': 1
                },
                {
                    'id': '52',
                    'name': 'ADMINISTRATE_FLEXI',
                    'isChecked': false,
                    'rightsType': 1
                }
            ];
        }
        if (value === '4') {
            this.rightsData = [
                {
                    id: '1',
                    name: 'ACTIVE_ACCOUNT',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '20',
                    name: 'DEVIATION_RIGHTS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '21',
                    name: 'VIEW_MANUALS',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '22',
                    name: 'CHOOSE_PROJECT_FDV',
                    isChecked: false,
                    rightsType: 1
                },
                {
                    id: '15',
                    name: 'HIDE_EMPLOYEE_LIST',
                    isChecked: false,
                    rightsType: 2
                },
                {
                    id: '17',
                    name: 'VIEW_DEVIATION',
                    isChecked: false,
                    rightsType: 3
                },
                {
                    id: '18',
                    name: 'DISPLAY_OWN_DEVIATIONS',
                    isChecked: false,
                    rightsType: 3
                },
                {
                    id: '19',
                    name: 'DEVIATIONS_OWN_DEPARTMENT',
                    isChecked: false,
                    rightsType: 3
                }
            ];
        }
        let data:any;
        let allCategories: FormArray | any= new FormArray([]);
        const control = <FormArray>this.userForm.controls['categories'];
        let count = (<FormArray>this.userForm.controls['categories']).value.length;

        for (let i = 0; i < this.rightsData.length; i++) {
            let fg = new FormGroup({});
            fg.addControl(
                `${this.rightsData[i].name}`,
                new FormControl(data ? data.Rights : this.rightsData[i].isChecked)
            );
            (<FormArray>this.userForm.get('categories')).push(fg);
        }
    }

    /**
     * create user
     */
    saveUser() {
        let Users = new SaveUsers();
        Users.Id = this.userId ? this.userId : 0;
        Users.Name = this.userForm.value.Name;
        Users.Email = this.userForm.value.Email;
        Users.Username = this.userForm.value.Username;
        Users.RoleId = this.userForm.value.RoleId;
        Users.Mobile = this.userForm.value.Mobile;
        Users.CountryCode = this.userForm.value.CountryCode;
        Users.Status = '1';
        Users.FeatureId = 14;
        Users.FeatureKey = this.employeeId ? this.employeeId : 0;
        Users.ApplicationId = BaseServices.ApplicationId;
        Users.Password = this.userForm.value.Password;
        Users.BusinessId = BaseServices.BusinessId;
        if (window.name === 'Remote' && this.userId === 0) {
            Users.LanguageId = 2; // default language Norsk
        } else {
            Users.LanguageId = BaseServices.userLanguageId;
        }
        Users.Rights = `${JSON.stringify(this.userForm.value.userCategories)}`;
        if (Users.Id > 0) {
            this.subscriptions.push(this.userService.updateUser(Users.Id, Users).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        } else {
            this.subscriptions.push(this.userService.addusers(Users).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        }
    }
    /**
     * closing the popup
     */
    saveAndClose() {
        this.saveUser();
        this.addCategory = false;
    }
    clear() {
        this.rightsData = [''];
        let allCategories: FormArray | any= new FormArray([]);
        this.userForm.value.categories = [''];
        (<FormGroup>this.userForm).setValue(this.categories, {
            onlySelf: true
        });
    }
    exportPdf() {
        let contdata = this.commonDetailsForm.value;
        this.pdf = pdfMake;
        dataBulider('Common Details', contdata);
    }
    onApprovalNoticeChanged(event:any) {
        let today = new Date();
        let date = today.getMonth() + '/' + today.getDate() + '/' + today.getFullYear();
        let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let dateTime = date + ' ' + time;
        if (event === true) {
            this.approvalTime = dateTime;
        } else {
            this.approvalTime = '';
        }
    }

    OnShowSaveButton() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId;
        let userRights;
        let data;
        if (currentUserRole === 'User') {
            userRightsId = Rights.EDIT_MY_PAGE;
            userRights = BaseServices.getUserRights();
            data = JSON.parse(userRights);
            this.isEditMyPage = HelperService.checkRightsData(data, userRightsId);
        } else {
            this.isEditMyPage = false;
        }

    }
    /**
   * Binding country code dropdown
   */
    bindCountryCode() {
        this.CountryCode = [];
        let countryCodes = this.route.snapshot.data['countrycodedropdown'];
        if (countryCodes) {
            countryCodes.forEach((countryCode: any) => {
                this.CountryCode.push({ label: '+' + countryCode.Phonecode, value: countryCode.Id });
            });
        }
    }
    /**
    * To bind Details
    * @param busId {any}
    */
    bindDetails() {
        this.bindDepartmentDropdown();
        this.bindTypeofEmployeeDropdown();
        this.bindPositiondropdown();
        this.bindCountryCode();
        this.bindFunctionDropDown();
        this.percentage = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.percentage = [];
            this.percentage.push(
                { label: val.SELECT, value: null }
            );
        }));
        for (let n = 1; n <= 100; n++) {
            this.percentage.push({ label: n + '%', value: n });
        }
        this.role = [];
        this.role.push({ label: 'Select', value: null });
        this.role.push({ label: 'Guest', value: '4' });
        this.role.push({ label: 'User', value: '5' });
        this.role.push({ label: 'Editor', value: '3' });
    }
    /**
 * Binding function dropdown
 */
    bindFunctionDropDown() {
        this.funtions = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.funtions = [];
            this.funtions.push(
                { label: val.SELECT, value: null }
            );
        }));
        let functions = this.route.snapshot.data['function'];
        if (functions) {
            functions.forEach((element:any) => {
                this.funtions.push({ label: element.Name, value: element.Id });
            });
        }
    }
    /**
   * on upload event
   * @param output
   */
    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.isFileChoosed = true;
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'done') {
            this.employeeImage = output.file!.response.Result.path;
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
        }

        this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
    }
    /**
   * upload event
   */
    startUpload(): void {
        //  this.businessId = BaseServices.BusinessId;
        const event: UploadInput = {
            type: 'uploadAll',
            url: 'http://localhost:53145/api/kuba/v1/file/upload/business/logo/'
                + BaseServices.ApplicationId + '/' + BaseServices.BusinessId,
            method: 'POST',
            data: { foo: 'bar' }
        };
        this.uploadInput.emit(event);
    }
    /**
     * hide save button based on edit mypage rights
     */
    onEditMyPage() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.EDIT_MY_PAGE;
        if (currentUserRole === 'User') {
            this.hideSaveUserButton = true;
            this.showMyPageSaveButton = BaseServices.checkUserRights(userRightsId, currentUserRole);
        } else {
            this.showMyPageSaveButton = true;
            this.hideSaveUserButton = false;
        }
    }

    /**
 * mobilenumber keypress event restrict number greater than 20 and restrict text
 */
    onMobileNumberChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
