<form [formGroup]="myForm" novalidate (ngSubmit)="save()">
  <!--Property-->
  <div class="mbtm-20" formArrayName="pageProperties">
    <div *ngFor="let Properties of myForm.controls['pageProperties']['controls']; let i=index" class="sub-panel">
      <div class="sub-panel-title">
        <span> {{title | translate}} {{ i + 1}}</span>
        <span class="sub-panel-actions pull-right" *ngIf="myForm.controls['pageProperties']['controls'].length >1">
          <a href="javascript:void(0);" (click)="removeProperties(i)">
            <span class="icon icon-sm icon-delete"></span>
          </a>
        </span>
      </div>
      <div class="sub-panel-content" [formGroupName]="i">
        <div class="form-inline">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <div [hidden]="true">
                  <input type="text" formControlName="Id">
                  <input type="text" formControlName="Status">
                  <input type="text" formControlName="BusinessId">
                </div>
                <label>
                  <span translate>TYPE</span>
                </label>
                <p-dropdown [options]="departments" formControlName="SocialAspectsTypeId" [style]="{'width':'150px'}">
                </p-dropdown>
                <button type="button" class="btn btn-outline-primary" (click)="typeOfSocialAspects(i)">
                  <i class="pi pi-plus"></i>
                </button>
              </div>
              <control-messages
                [control]="myForm.controls['pageProperties']['controls'][i]['controls'].SocialAspectsTypeId">
              </control-messages>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label>
                  <span translate>DESCRIPTION</span>
                </label>
                <input type="text" class="form-control" formControlName="Description" maxlength="100">
                <control-messages [control]="myForm.controls['pageProperties']['controls'][i]['controls'].Description">
                </control-messages>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label>
                  <input type="checkbox" formControlName="IsApplicable">
                  <span translate>APPLICABLE</span>
                </label>
              </div>
              <control-messages [control]="myForm.controls['pageProperties']['controls'][i]['controls'].IsApplicable">
              </control-messages>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mbtm-20">
    <a (click)="addSocialAspects()" href="javascript:void(0);" class="btn btn-outline-primary">
      <i class="icon ic-sm icon-add"></i>
      <span translate>ADD_ASPECTS</span>
    </a>
  </div>
  <div class="action-btns-wrapper text-right">
    <button type="submit" class="btn btn-primary" [disabled]="!myForm.valid">
      <span translate>SUBMIT</span>
    </button>
  </div>
  <div class="clearfix"></div>
</form>
<p-dialog header="{{'ADD_SOCIAL_ASPECT'|translate}}" [(visible)]="showSocialType" [style]="{width: '300px'}"
  [resizable]="false" [draggable]="false">
  <form [formGroup]="socialAspectsTypeForm" (onHide)="onBeforeAspectDialogHide();">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>TYPE</span>
        <span class="required">*</span>
      </label>
      <div>
        <input pInputText type="text" class="label-input form-control" formControlName="Name" maxlength="100"
          [required]="true" />
        <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix mbtm_10">
      <button type="button" class="btn btn-outline-primary" (click)="listSocialAspect = !listSocialAspect">
        <span translate>VIEW</span>
      </button>
      <button type="button" class="btn btn-primary" (click)="saveSocialAspectsType();"
        [disabled]="!socialAspectsTypeForm.valid">
        <span translate>SAVE</span>
      </button>
    </div>
    <div class="table-view" *ngIf="listSocialAspect">
      <!-- Table starts -->
      <p-table #socialAspects [value]="socialTypeData" [rows]="10" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20]">
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th>{{'NAME'|translate}}</th>
            <th></th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-socialaspects>
          <tr>
            <td>
              <span>{{socialaspects.Name}}</span>
            </td>
            <td>
              <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}" (click)="editType(socialaspects)">
                <i class="icon ic-sm icon-pencil-tip"></i>
              </button>
              <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                (click)="deleteType(socialaspects.Id)">
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="2">
              {{'NO_RECORDS_FOUND'|translate}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table ends -->
    </div>
  </p-footer>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>