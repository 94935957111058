import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { ProjectAdmin } from './../models/project-admin';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProjectAdminServices {
    constructor(private http: HttpClient) {
    }

    getPortalList() {
        return this.http
            .get(
            environment.BASE_URL + '/portalnamelist',
            BaseServices.headerOption
            )
            .map((result: any) => result);
    }
    getBusinessList() {
        return this.http
            .get(
            environment.BASE_URL + '/businessnamelist',
            BaseServices.headerOption
            )
            .map((result: any) => result);
    }
    getProjectList(applicationId: number) {
        return this.http
            .get(
            environment.BASE_URL + '/admin-projecttemplate/' + applicationId, BaseServices.headerOption
            )
            .map((result: any) => result);
    }
    create(project: ProjectAdmin) {
        return this.http
            .post(
            environment.BASE_URL + '/projecttemplate',
            project,
            BaseServices.headerOption
            )
            .map(result => result);
    }
    getProjectById(id: number) {
        return this.http
            .get(environment.BASE_URL + '/projecttemplate/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    update(project: ProjectAdmin) {
        return this.http
            .put(
            environment.BASE_URL + '/projecttemplate',
            project,
            BaseServices.headerOption
            )
            .map(result => result);
    }
    delete(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/projecttemplate/' + id)
            .map(result => result)
            .catch(this.handleError);
    }
    getAdminProject(applicationId: number, userId: number) {
        return this.http
            .get(environment.BASE_URL + '/adminprojectlist/' + applicationId + '/' + userId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    checkProjectName(projectName: string) {
        return this.http
            .get(environment.BASE_URL + '/check-projectname/' + projectName, BaseServices.headerOption)
            .map(result => result);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
