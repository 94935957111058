import { BaseServices } from './../../kuba.services';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActivityServices } from '../services/activities.services';
import { Activity, Execution } from '../models/activities';
import { SelectItem,ConfirmationService } from 'primeng/api';

@Component({
    selector: 'followups-activities',
    templateUrl: 'activities.component.html',
})
export class FollowUpActivitiesComponent implements OnInit {

    constructor( ) {}
  
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
}