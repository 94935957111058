export class Consumption {
    Id ?: number;
    ConsumptionTypeId ?: string;
    Date ?: string;
    CreatedOn ?: Date;
    ModifiedOn ?: string;
    PreviousNumber ?: string;
    TodaysNumber ?: string;
    EstimatedNumber ?: string;
    ConsumptionUnitId ?: string;
    Price ?: string;
    Comments ?: string;
    Status ?: string;
    BusinessId ?: number;
    CreatedBy ?: string;
    ModifiedBy ?: string;
}
export class ConsumptionType {
    Id ?: number;
    Name ?: string;
    CreatedOn ?: string;
    ModifiedOn ?: string;
    Status ?: string;
    BusinessId ?: number;
    CreatedBy ?: string;
    ModifiedBy ?: string;
}
export class UnitOfConsumption {
    Id ?: number;
    Name ?: string;
    CreatedOn ?: Date;
    ModifiedOn ?: Date;
    Status ?: string;
    BusinessId ?: number;
    CreatedBy ?: number;
    ModifiedBy ?: number;
}