import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { AccessLog } from '../models/accesslog';
import { AccessLogServices } from './accesslog.services';

@Injectable()
export class AccessLogListResolver implements Resolve<AccessLog[]> {
    constructor(private backend: AccessLogServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<AccessLog[]> {
        return this.backend.getAllAccessLog();
    }
}
@Injectable()
export class AccessLogEditResolver implements Resolve<AccessLog[]> {
    constructor(private backend: AccessLogServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<AccessLog[]> {
        return this.backend.getAccessLogById(+route.params['aid']);
    }
}
