<div class="dropdown pull-right">
    <a class="dd-icon-btn dropdown-toggle" id="notificationBtn" [ngClass]="{'active': showDropDown}"
        pTooltip="{{'NOTIFICATION' | translate }}" tooltipPosition="bottom" (click)="dropdownToggle()">
        <span class="icon ic-sm icon-notification"></span>
        <span class="label label-danger" *ngIf="notificationCount > 0">{{notificationCount}}</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-right notification-list" style="display:block;" *ngIf="showDropDown">
        <li class="list-heading">
            <span translate>NOTIFICATION</span>
            <a (click)="markAsRead()" href="javascript:void(0);" translate class="btn btn-link p-0"><span
                    class="icon ic-sm icon-checkmark"></span> <span translate>MARK_READ</span></a>
        </li>
        <li class="mini-tabs">
            <div class="tabs-list">
                <a (click)="toggleNotificationTab()" 
                 [ngClass]="(notifications)? 'tab-item active': 'tab-item'" 
                    href="javascript:void(0)" translate>STORY</a>
                <a (click)="toggleNotificationTab()" *ngIf="fdvNotifications"
                    [ngClass]="(!notifications)? 'tab-item active' : 'tab-item'" href="javascript:void(0)" translate>DOWNLOADS</a>
            </div>
        </li>

        <ng-template [ngIf]="notifications">
            <div style="max-height:200px;overflow-y:scroll">
            <li class="list-item" *ngFor="let item of notifyList">
                <a href="javascript:void(0);" (click)="showTitlePopup(item)">
                    <span class="list-item-title">{{item.Title}}
                        <span *ngIf="(item.ProgressPercentage !=100 && item.FileType && item.FileType == 3)" class="fdv-progress">
                            <span class="progress">
                                <span class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                    aria-valuemax="100" 
                                    [ngStyle]="{ 'width': item.ProgressPercentage + '%' }">
                                </span>
                            </span>
                            <span class="progress-count" *ngIf="item.ProgressPercentage != 100">
                                {{item.ProgressPercentage}}%
                            </span>
                        </span>
                    </span>
                    <span *ngIf="(item.ProgressPercentage ==100 && item.FileType && item.FileType == 3)" class="text-success">
                        <span class="icon ic-sm icon-checkmark"></span>
                    </span>
                    <span class="metadata" *ngIf = "!item.FileType">{{item.CreatedOn | date:'MM-dd-yyyy' }}</span>
                </a>
            </li>
            </div>
        </ng-template>
        <ng-template [ngIf]="!notifications">
            <div style="max-height:200px;overflow-y:scroll">
            <li class="list-item"  *ngFor="let item of fdvNotificationList">
                <a *ngIf="item.FileType == 1" href="javascript:void(0);" (click)="showTitlePopup(item)">
                    <span class="list-item-title">{{item.Title}}
                        <span *ngIf="item.ProgressPercentage !=100" class="fdv-progress">
                            <span class="progress">
                                <span class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                    aria-valuemax="100" 
                                    [ngStyle]="{ 'width': item.ProgressPercentage + '%' }">
                                </span>
                            </span>
                            <span class="progress-count" *ngIf="item.ProgressPercentage != 100">
                                {{item.ProgressPercentage}}%
                            </span>
                        </span>
                    </span>
                    <span *ngIf="item.ProgressPercentage ==100" class="text-success">
                        <span class="icon ic-sm icon-checkmark"></span>
                    </span>
                </a>
                <a *ngIf="item.FileType == 2" href="javascript:void(0);" (click)="showfdvTitlePopup(item)">
                    <span class="list-item-title">{{item.Title}}
                        <span *ngIf="item.ProgressPercentage <=100 && item.Description == null" class="fdv-progress">
                            <span class="progress">
                                <span class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                    aria-valuemax="100" 
                                    [ngStyle]="{ 'width': item.ProgressPercentage + '%' }">
                                </span>
                            </span>
                            <span class="progress-count" *ngIf="item.ProgressPercentage != 100">
                                {{item.ProgressPercentage}}%
                            </span>
                        </span>
                    </span>
                    <span *ngIf="item.Description != null" class="text-success">
                        <span class="icon ic-sm icon-checkmark"></span>
                    </span>
                </a>
            
            </li>
            </div>
        </ng-template>
        <li *ngIf="allCaught">
            <div class="no-data">
                <span class="muted" translate>ALL_CAUGHT</span>
            </div>
        </li>
        <li>
            <div>
                <a class="btn btn-link" href="javascript:void(0);"
                    [routerLink]="['./../app/' + userRole +'/' + businessId +'/notificationuser']">
                    <span translate>VIEW_ALL</span><span class="icon ic-xs icon-forward" aria-hidden="true"></span>
                </a>
            </div>
        </li>
    </ul>
</div>
<p-dialog header="{{'NOTIFICATION'| translate}}" [(visible)]="showNewNotification" [modal]="true" [style]="{width: '500px'}" [resizable]="false" [draggable]="false">
    <div class="form-group">
        <h5 class="notifi-title">
            <span translate>{{notificationTitle}}</span>
        </h5>
        <span *ngIf="percentage !=100 && isBulkReport" class="fdv-progress">
                <span class="progress">
                    <span class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100"  [ngStyle]="{ 'width': percentage + '%' }">
                    </span>
                </span>
                <span class="progress-count">
                    {{percentage}}%
                </span>
        </span>
        <div *ngIf="!isfdvBulkDownload">
            <div class="notif-content-wrapper" style="padding:10px;word-break:break-all;"
                [innerHTML]="notificationDesc" *ngIf = !isBulkReport>
            </div>
            <div class="notif-content-wrapper" style="padding:10px;word-break:break-all;">
                <p class="text-danger" *ngIf="percentage == 100 && isBulkReport">
                    <button class="btn btn-primary-outline" *ngIf="notificationDesc;" (click)="fdvBulkDownload(fdvReportId,notificationDesc)"><span translate>CLICK_DOWNLOAD</span></button>
                </p>
            </div>
        </div>
        <div *ngIf="isfdvBulkDownload">
        <div class="mtop-10" style="max-height: 284px;overflow-y: auto;">
                <table class="table">
                    <thead>
                        <tr>
                            <th><span translate>PRODUCT_NUMBER</span></th>
                            <th><span translate>PRODUCT_NAME</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of fdvBulkSelectedData">
                            <td>{{item.ItemNumber}}</td>
                            <td>{{item.ItemName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                <p class="text-danger" *ngIf="percentage == 100">
                    <button class="btn btn-primary-outline" *ngIf="notificationDesc;" (click)="fdvBulkDownload(fdvReportId,notificationDesc)"><span translate>CLICK_DOWNLOAD</span></button>
                    <ng-template *ngIf="downloadExpired == 0"><span translate>DOWNLOAD_EXPIRED</span></ng-template>
                </p>
        </div>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline" (click)="closePopUp()">
                <span translate>CLOSE</span>
            </button>
        </div>
    </p-footer>
</p-dialog>
