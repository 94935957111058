import { BusinessPortalListResolver, BusinessPortalEditResolver, PortalManagerUserlistResolver, PortalBusListResolver } from './services/business-portal.resolver.service';
import { CountryCodeResolver } from './../client/services/client.resolver.service';
import { Route } from '@angular/router';
import { PortalComponent } from './portals.component';
import {
    PortalDetailsComponent,
    PortalListComponent,
    PortalFeaturesComponent,
    PortalWizardComponent,
    PortalUserComponent
} from './components/';
import { AdminPortalTabGuard, AdminTabGuard } from 'src/app/_guards';

export const portalRoutes: Route[] = [{
    path: 'portal',
    component: PortalComponent,
    canActivate: [AdminPortalTabGuard],
    children: [{
        path: 'list',
        component: PortalListComponent,
        resolve: {
            list: BusinessPortalListResolver
        },
    },
    {
        path: 'new',
        component: PortalWizardComponent,
        children: [{
            path: 'details',
            component: PortalDetailsComponent,

        }]
    },
    {
        path: 'edit/:pid',
        component: PortalWizardComponent,
        resolve: {
            portalDetails: BusinessPortalEditResolver
        },
        children: [{
            path: 'details',
            component: PortalDetailsComponent
        },
        {
            path: 'user',
            component: PortalUserComponent,
            resolve: {
                countrycodedropdown: CountryCodeResolver,
                portalManagerUserList: PortalManagerUserlistResolver,
                PortalBusinessList: PortalBusListResolver
            }
        },
        {
            path: 'features',
            component: PortalFeaturesComponent
        }]
    }]
}];
