import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VehicleCheckListServices {
    constructor(private http: HttpClient) {}

    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', "application/json");
    }
    getAllVehicles() {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehicles', BaseServices.headerOption)
            .map(result => result);
    }
    getVehiclesById(id: number) {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehicles/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    deleteVehicle(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/vehicle/vehicle/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }
    getAllDepartments() {
        return this.http
            .get(environment.BASE_URL + '/vehicle/vehicles', BaseServices.headerOption)
            .map(result => result);
    }
    getAllBrands() {
        return this.http
            .get(environment.BASE_URL + '/vehiclebrand/vehiclebrands', BaseServices.headerOption)
            .map(result => result);
    }
    getBrandById(Id: number) {
        return this.http
            .get(environment.BASE_URL + '/vehiclebrand/vehiclebrands/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

  
    deleteBrandById(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/vehiclebrand/vehiclebrand/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }
    getAllModels() {
        return this.http
            .get(environment.BASE_URL + '/vehiclemodel/vehiclemodels', BaseServices.headerOption)
            .map(result => result);
    }

    getAllVehicleType() {
        return this.http
            .get(environment.BASE_URL + '/vehicletype/vehicletypes', BaseServices.headerOption)
            .map(result => result);
    }
    getVehicleTypeById(Id: number) {
        return this.http
            .get(environment.BASE_URL + '/vehicletype/vehicletypes/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

 
    deleteVehicleType(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/vehicletype/vehicletype/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => {
                return response;
            });
    }
}
