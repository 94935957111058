import { Route } from '@angular/router';

import { employeeRoutes } from './../employees/employees.routing';
import { MyPageUserDetailResolver } from './services/common-details.resolver.service';
import { MyPageComponent } from './my-page.component';
import { MyPageGuard } from 'src/app/_guards';
import { contactRoutes } from '../contact/contact.routing';
import { SubContractorRoutes } from '../contractor/contractor.routing';
import { clientRoutes } from '../client/client.routing';


export const MyPageRoutes: Route[] = [
    {
        path: 'my-page',
        component: MyPageComponent,
        resolve: {
            user: MyPageUserDetailResolver
        },
        data: { parent: 'MYPAGE' },
        canActivate: [MyPageGuard],
    },
    ...employeeRoutes,
    ...contactRoutes,
    ...SubContractorRoutes,
    ...clientRoutes
];
