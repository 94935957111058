<div class="panel-tabs">
    <nav>
        <ul class="nav nav-pills">
            <li *ngIf="tabsEnabled.indexOf('installer-insp')>-1" [routerLinkActive]="['active']">
                <a [routerLink]="['./installer-insp']" translate>INSTALLER_INSPECTION</a>
            </li>
            <li *ngIf="tabsEnabled.indexOf('self-insp')>-1" [routerLinkActive]="['active']">
                <a [routerLink]="['./self-insp']" translate>SELF_INSPECTION</a>
            </li>
            <li *ngIf="tabsEnabled.indexOf('dev-overview')>-1" [routerLinkActive]="['active']">
                <a [routerLink]="['./dev-overview']" translate>DEVIATION_OVERVIEW</a>
            </li>
            <li *ngIf="tabsEnabled.indexOf('report-temp')>-1" [routerLinkActive]="['active']">
                <a [routerLink]="['./report-temp']" translate>REPORT_TEMPLATES</a>
            </li>
            <li *ngIf="tabsEnabled.indexOf('forms')>-1" [routerLinkActive]="['active']">
                <a [routerLink]="['./forms']" translate>INSTALLATION_FORMS</a>
            </li>
        </ul>
    </nav>
</div>
<router-outlet></router-outlet>