import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CKEditorLoaderService {
  private scriptLoadedSubject: BehaviorSubject<boolean>;

  constructor() {
    this.scriptLoadedSubject = new BehaviorSubject<boolean>(false);
    this.loadScript();
  }

  private loadScript() {
    const script = document.createElement('script');
    script.src = 'https://cdn.ckeditor.com/4.6.1/full/ckeditor.js';
    script.onload = () => this.scriptLoadedSubject.next(true);
    document.head.appendChild(script);
  }

  isScriptLoaded() {
    return this.scriptLoadedSubject.asObservable();
  }
}
