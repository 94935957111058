import { BusinessProperty } from './../../businesses/models/businessProperty';
import { User } from './../../projects/models/user';
export class AdminUser {
    Id: number;
    LanguageId: number;
    RoleId: number;
    ApplicationId: number;
    BusinessId: number;
    Rights: string;
    FeatureId: number;
    FeatureKey: String;
    Username: string;
    Password: string;
    Email: string;
    Name: string;
    Avatar: string;
    Sso: string;
    SsoUsername: string;
    SsoDomain: string;
    SsoLoggedIn: string;
    NotificationUpdateOn: string;
    Status: string;
}
export class AdminUserLanguage {
    Id: number;
    Title: string;
}
export class AdminUserRole {
    Id: number;
    Name: string;
}
export class AdminSaveUsers {
    Id: number;
    LanguageId: number;
    RoleId: number;
    ApplicationId: number;
    BusinessId: number;
    Rights: string;
    FeatureId: number;
    FeatureKey: number;
    Username: string;
    Password: string;
    Email: string;
    Name: string;
    SignutureFile: AdminUserSignatureFile;
    SignatureImg: string;
    AvatarFile: AdminUserAvatarFile;
    Avatar: string;
    Sso: string;
    SsoUsername: string;
    SsoDomain: string;
    SsoLoggedIn: true;
    IsNewsLetter: boolean;
    NotificationUpdateOn: Date;
    Status: string;
    Mobile: number;
    CountryCode: number;
    PostalAddress: string;
    CreatedBy: number;
    IsTOCApproved: boolean;
}
export class AdminUserSignatureFile {
    ContentType: string;
    ContentDisposition: string;
    Headers: {};
    Length: number;
    Name: string;
    FileName: string
}
export class AdminUserAvatarFile {
    ContentType: string;
    ContentDisposition: string;
    Headers: {};
    Length: number;
    Name: string;
    FileName: string;
}

export class CountryCodes {
    Id: number;
    Iso: string;
    Name: string;
    Nicename: string;
    Iso3: string;
    Numcode: string;
    Phonecode: string;
    Status: string;
    BusinessId: number;
}

export class UserAddressPropertyDto {
    UserId: number;
    AddressId: number;
    User: AdminSaveUsers;
    AddressProperty: AddressProperty;
    constructor() {
        this.User = new AdminSaveUsers();
        this.AddressProperty = new AddressProperty();
    }
}
export class AddressProperty {
    Id: number;
    BusinessId: number;
    BusinessPropertyTypeId: number;
    Name: string;
    Number: string;
    Description: string;
    FloorCount: number;
    ApartmentCount: number;
    Address: string;
    Status: string;
    City: string;
    Zip: string;
    ContactId: number;
    logopath: string;
    Location: string;
    Latitude: string;
    Longitude: string;
    CreatedBy: number;
    ModifiedBy: number
}