export class Department {
    Id: number;
    Name: string;
    ParentId: number;
    Status: string;
    BusinessId: number;
    ApplicationId: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
}
