import { HelperService } from './../../../_services/helper.service';
import { UploaderConfig } from './../../../shared/ecpl-document-explorer/uploader-config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem } from 'primeng/api';
import { Options } from './../../../_models/options';
import { KundeELUser, KundeELClientUser, EL, BusinessProperty } from './../models/el-edit';
import { User } from './../models/user'
import { BaseServices } from './../../kuba.services';
import { ELService } from './../services/el.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';



@Component({
    selector: 'el-form',
    templateUrl: 'el-form.component.html'
})

export class ELFormComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    contractorName: string;
    uploadStart: boolean;
    KundeElId: number;
    elForm: FormGroup;
    loading: boolean;
    IsHidden = false;
    contracterId: number;
    elUsers: any[];
    clients: SelectItem[];
    kundeElClientUsers: KundeELClientUser[];
    selectedUser: any[];
    selectedClient: any[];
    selectedContractUsers: any[];
    elList: SelectItem[];
    checkedAll:boolean;
    businessPropertyId: number;
    options = [
        new Options(7, 'NEW'),
        new Options(8, 'OPEN'),
        new Options(9, 'DONE'),
        new Options(10, 'REJECTED')
    ];
    isEditMode = false;
    upConfig: UploaderConfig;
    uploadedFiles: any[] = [];
    additionalData: any;
    newDocumentAdded: any = [];
    showAssignClient = false;
    isGuestButtonHide = true;
    userId: number;
    elTemplateId = 0;
    bindSelectedUsers = true;
    bindAllUsers = false;
    userSubscribers: SelectItem[];
    selectedSubscribers: SelectItem[];
    selectedEmailSubscriber = [];
    selectedEmailSMSSubscriber = [];
    elSelectedUsers: any[];
    emailSubscriber = [];
    kundeElImage: string;
    public selectedFiles
    private subscriptions: Subscription[] = [];
    //#endregion

    constructor(
        private route: ActivatedRoute,
        private elServices: ELService,
        private fb: FormBuilder,
        private location: Location,
        private translate: TranslateService,
        private http: HttpClient
    ) {
        this.elForm = this.fb.group({
            'ContractNumber': ['', Validators.required],
            'ContractName': ['', Validators.required],
            'Address': [''],
            'Zip': [''],
            'City': [''],
            'Status': [7],
            'ClientId': [],
            'Comments': [''],
            'ContractImage': [],
            'Client': [],
            'ChooseContracts': [],
            'CopySJA': [false],
            'ContactPerson': [[]],
            'elUser': [[]],
            'elClientUsers': [[]],
            'IsShowDeviationChecked': [false],
            'selectedEmailSubscriber': [[]],
            'selectedEmailSMSSubscriber': [[]]
        });
    }
    //#endregion

    //#region page-events
    /**
     * page load event
    */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.userId = +BaseServices.UserId;
        this.contracterId = this.route.snapshot.params['id'];
        this.bindFormDropdowns();
        let res = this.route.snapshot.data['edit'];
        let userSubscription = <any[]>this.route.snapshot.data[
            'users_subscription'
        ];
        this.selectedSubscribers = [];
        this.elUsers = userSubscription;
        this.elSelectedUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: { Id: element.Id }
                    });
                    if (element.Id == BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        }));
        if (res) {
            if (res.KundeELTemplateId > 0) {
                this.IsHidden = true;
            }
            this.isEditMode = true;
            this.initForm(res);
        } else {
            this.initForm();
        }
        if (+BaseServices.roleId === 4) {
            this.isGuestButtonHide = false;
        }
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ParentId: +this.contracterId ? +this.contracterId : 0,
            Status: 1,
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: 'KundeEL Document',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/kundeEL/file/upload/business/kundeEL',
            addtionalData: this.additionalData
        };
    }

    onClientChanged(event, clientDropdown) {
        this.showAssignClient = true;
        this.getContactByClientId(event.value);
    }

    getContactByClientId(clientId: number) {
        if (!clientId) {
            clientId = 0;
        }
        this.subscriptions.push(this.elServices.getActiveClientContactUser(clientId)
            .subscribe((result: any) => {

                if (result) {
                    this.kundeElClientUsers = [];
                    result.forEach(element => {
                        let user = new KundeELClientUser();

                        user.Name = element.Name;
                        user.ClientUserId = element.ClientUserId;
                        user.ClientId = element.ClientId;
                        user.ELId = this.contracterId ? this.contracterId : 0;
                        user.Id = element.ClientUserId;

                        this.kundeElClientUsers.push(user);
                    });
                }
            }))
    }

    /**
     * save and create new button click event
     */
    saveAndCreateNew() {
        this.saveChanges();
        this.clear();
    }
    /**
        * file upload events
        * @param event {any}
        */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            let keyBindings = {
                Originalname: 'OriginalFileName',
                FileTypeId: 'FileType'
            };
            let deviationDoc = Object.assign({}, event, this.additionalData);
            switch (eventType) {
                case 'DeleteDefaultFile':
                    this.uploadedFiles = this.uploadedFiles.filter(
                        (x: any) => x.path !== event.item.data.path
                    );
                    if (event.item.data.id === 0) {
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x: any) => x.Path !== event.item.data.path
                        );
                    } else {
                        //  remove existing attachement
                        this.subscriptions.push(this.elServices
                            .deleteElAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        x => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let serviceDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    serviceDoc.id = 0;
                    serviceDoc.createdBy = BaseServices.UserId;
                    serviceDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(serviceDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    saveChanges() {
        let userId = BaseServices.UserId;
        let name = BaseServices.userName;
        let checkUserId = this.selectedUser.filter(x => x.Id == userId);
        if (checkUserId.length === 0) {
            this.selectedUser.push({ Id: userId, Name: name });
        }
        if (this.elForm.valid) {
            let el = new EL();
            el.Id = (this.contracterId) ? this.contracterId : 0;
            el.ContractNumber = this.elForm.value.ContractNumber;
            el.ContractName = this.elForm.value.ContractName;
            el.ClientId = this.elForm.value.ClientId;
            el.CopySJA = this.elForm.value.CopySJA;
            if (this.IsHidden === false) {
                el.ElTemplateId = this.elForm.value.ChooseContracts;
            }
            //el.kundeElImage = this.kundeElImage;
            el.ShowDeviation = this.elForm.value.IsShowDeviationChecked;
            el.KundeELTemplateId = this.elForm.value.ChooseContracts;
            el.ContractImage = this.kundeElImage;
            // tslint:disable-next-line:radix
            if (this.elForm.value.Status === '') {
                el.Status = '7';
            } else {
                el.Status = this.elForm.value.Status
            }
            el.CreatedOn = new Date();
            el.CreatedBy = BaseServices.UserId;
            el.BusinessId = BaseServices.BusinessId;
            el.Comments = this.elForm.value.Comments;
            let businessProperty = new BusinessProperty();
            businessProperty.Id = (this.businessPropertyId) ? this.businessPropertyId : 0;
            businessProperty.Address = this.elForm.value.Address ? this.elForm.value.Address : '';
            businessProperty.City = this.elForm.value.City;
            businessProperty.Zip = this.elForm.value.Zip.toString();
            businessProperty.CreatedOn = new Date();
            businessProperty.CreatedBy = BaseServices.UserId;
            businessProperty.BusinessId = BaseServices.BusinessId;
            el.BusinessPropertyDto = businessProperty;
            el.KundeElDocument = this.newDocumentAdded;
            el.KundeELUsers = [];
            if ((this.bindSelectedUsers) && this.elForm.value.selectedEmailSubscriber) {
                this.emailSubscriber = this.elForm.value
                    .selectedEmailSubscriber;
            }
            if ((this.bindAllUsers) && this.elForm.value.selectedEmailSMSSubscriber) {
                this.emailSubscriber = this.elForm.value.selectedEmailSMSSubscriber;
            }
            if (this.selectedContractUsers) {
                // tslint:disable-next-line:no-shadowed-variable
                this.selectedContractUsers.forEach(element => {
                    let user = new KundeELUser();
                    user.KundeELId = this.contracterId ? this.contracterId : 0;
                    user.Name = element.Name ? element.Name : '';
                    user.UserId = element.Id;
                    user.IsEmail = this.emailSubscriber.find(x => x.Id == element.Id) ? true : false;
                    el.KundeELUsers.push(user);
                });
            }

            el.KundeELClientUsers = [];
            if (this.selectedClient) {
                // tslint:disable-next-line:no-shadowed-variable
                this.selectedClient.forEach(element => {
                    let user = new KundeELClientUser();
                    user.ELId = this.contracterId ? this.contracterId : 0;
                    user.Name = element.Name;
                    user.ClientId = element.ClientId;
                    user.ClientUserId = element.Id;
                    user.ClientId = element.ClientId
                    el.KundeELClientUsers.push(user)
                });
            }
            else if (el.ClientId) {
                let client = new KundeELClientUser();
                client.ELId = this.contracterId ? this.contracterId : 0;
                client.ClientId = el.ClientId;
                el.KundeELClientUsers.push(client);
            }

            if (el.Id > 0) {
                el.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.elServices
                    .update(el)
                    .subscribe(result => {
                        this.toasterComponent.callToast();
                        this.gotoBack();
                    }));
            } else {
                this.subscriptions.push(this.elServices
                    .create(el)
                    .subscribe((result) => {
                        if (result) {
                            this.toasterComponent.callToast();
                        }
                        this.gotoBack();
                    }));

            }

        }
    }

    /**
     * clear form data
    */
    clear() {
        this.initForm();
        this.contracterId = 0;
        this.selectedContractUsers = [];
    }

    initForm(data?: any) {
        let contractNumber = '';
        let ContractName = '';
        let address = '';
        let city = '';
        let zip = '';
        let Comments = '';
        let ChooseContracts = '';
        let client = '';
        let selectedClient = [];
        let selectedUser = [];
        let status = 7;
        let copySJA = '';
        let IsShowDeviationChecked = false;
        this.selectedContractUsers = [];
        let ContractImage = '';
        let selectedEmailSubscriber = this.selectedEmailSubscriber;
        let selectAllEmailSubscriber = this.selectedEmailSMSSubscriber;
        if (data) {
            this.kundeElImage = data.ContractImage ? data.ContractImage : null;
            this.elTemplateId = data.KundeELTemplateId ? data.KundeELTemplateId : 0
            ContractName = data.ContractName;
            contractNumber = data.ContractNumber;
            ChooseContracts = data.KundeELTemplateId;
            address = data.BusinessPropertyDto ? data.BusinessPropertyDto.Address : '';
            city = data.BusinessPropertyDto ? data.BusinessPropertyDto.City : '';
            zip = data.BusinessPropertyDto ? data.BusinessPropertyDto.Zip.toString() : '';
            this.businessPropertyId = data.BusinessPropertyId;
            Comments = data.Comments;
            IsShowDeviationChecked = data.ShowDeviation;
            client = data.ClientId;
            ContractImage = data.kundeElImage;
            this.getContactByClientId(+client);
            status = data.Status ? data.Status : 7;
            copySJA = data.CopySJA;
            this.uploadedFiles = [];
            data.KundeElDocument.forEach(element => {
                this.uploadedFiles.push(
                    HelperService.switchKeysCase(element, null, 'L')
                );
            });
            this.selectedContractUsers = [];
            if (data.KundeELUsers) {
                data.KundeELUsers.forEach((element: any) => {
                    let selectedUsers = this.elUsers.find(
                        x => x.Id === element.UserId
                    );
                    if (selectedUsers) {
                        selectedUsers.status = true;
                        selectedUsers.email = false;
                        this.checked(selectedUsers);
                        if (element.IsEmail) {
                            this.selectedEmailSubscriber.push({ Id: element.UserId });
                            this.selectedEmailSMSSubscriber.push({ Id: element.UserId });
                        }
                    }
                });
                this.elForm.patchValue({
                    selectedEmailSubscriber: this.selectedEmailSubscriber
                });
            }

            this.selectedClient = [];
            if (data.KundeELClientUsers.length > 0) {
                // tslint:disable-next-line:no-shadowed-variable
                data.KundeELClientUsers.forEach(element => {
                    this.selectedClient.push({ Id: element.ClientUserId, Name: element.Name, CCId: element.Id, ClientId: element.ClientId })
                });
                this.showAssignClient = true;
            }
            this.elForm = this.fb.group({
                ContractNumber: new FormControl(contractNumber),
                ContractName: new FormControl(ContractName),
                Address: new FormControl(address),
                City: new FormControl(city),
                Zip: new FormControl(zip),
                Comments: new FormControl(Comments),
                ChooseContracts: new FormControl(ChooseContracts),
                ClientId: new FormControl(client),
                Status: new FormControl(status),
                CopySJA: new FormControl(copySJA),
                ContractImage:new FormControl(ContractImage),
                IsShowDeviationChecked: new FormControl(IsShowDeviationChecked),
                selectedEmailSubscriber: new FormControl(selectedEmailSubscriber),
                selectedEmailSMSSubscriber: new FormControl(selectAllEmailSubscriber)
            });
        } else {
            if (this.elUsers) {
                let selectedUsers = this.elUsers.find(
                    x => x.Id === BaseServices.UserId
                );
                if (selectedUsers) {
                    selectedUsers.status = true;
                    this.checked(selectedUsers);
                }
            }
        }
    }
    bindFormDropdowns() {
        let users = this.route.snapshot.data['users'];
        this.elUsers = users;
        this.selectedUser = [];
        this.selectedClient = [];
        this.elList = [];
        let elList = this.route.snapshot.data['elList']
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.elList = [];
            this.elList.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (elList) {
            // tslint:disable-next-line:no-shadowed-variable
            elList.forEach(element => {
                this.elList.push({ label: element.ContractName, value: element.Id })
            });
        }

        this.clients = [];
        let Clients = this.route.snapshot.data['clients'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.clients = [];
            this.clients.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (Clients) {
            // tslint:disable-next-line:no-shadowed-variable
            Clients.forEach(element => {
                this.clients.push({ label: element.Name, value: element.Id })
            });
        }

    }

    gotoBack() {
        this.location.back();
    }

    checked(value) {
        if (value.status) {
            this.selectedContractUsers.push(value);
            if (this.elUsers) {
                this.elUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.email)) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedContractUsers, val => {
                if (this.userSubscribers) {
                    _.forEach(this.userSubscribers, (item: any) => {
                        if (item.value.Id == value.Id) {
                            this.userSubscribers.pop();
                        }
                    });
                }
                return val === value;
            });
        }
    }

    checkAll(event) {
        _.forEach(this.elUsers, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status =
                    // to remain checked state for current user
                    this.userId === +item.Id ? true : false;
            }
        });

        this.selectedContractUsers = this.elUsers;
        if (!event) {
            let userId = BaseServices.UserId;
            let name = BaseServices.Name;
            let checkUserId = this.selectedUser.filter(x => x.Id == userId);
            if (checkUserId.length > 0) {
                this.userSubscribers = [];
                this.userSubscribers.push({ label: name, value: { Id: userId } });
            }
            this.selectedContractUsers = [];
            this.bindAllUsers = false;
            this.bindSelectedUsers = true;
        }
        else {
            this.bindAllUsers = true;
            this.bindSelectedUsers = false;
        }
    }

    onContractNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45)
        ) {
            e.preventDefault();
        }
    }

    filesSelect(selectedFiles: any): void {
        this.uploadStart = true;
        this.KundeElId = this.route.snapshot.params['id'];

        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };
        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/kundeEl/file/upload/kundeEL-image/' +
                BaseServices.ApplicationId +
                '/' +
                this.KundeElId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response;
                this.kundeElImage = res.path;
                this.loading = false;
            })
            .subscribe(
                result => {
                 },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
