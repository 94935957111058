import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';


@Pipe({ name: 'SpecifiChar' })
export class SpecifiCharPipe implements PipeTransform {
    constructor() { }
    transform(value: string): string {
        var n = value.indexOf('.');
        value = value.substring(0, n != -1 ? n : value.length);
        return value;
    }
}

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
    constructor() { }
    transform(value: number, status:any): string {
        return status[value];
    }
}

@Pipe({ name: 'rowStatus' })
export class RowStatusPipe implements PipeTransform {
    constructor() { }
    transform(value: number): string {
        let status: any = {
            1: 'ACTIVE',
            2: 'INACTIVE'
        };
        return status[value];
    }
}

@Pipe({ name: 'categoryName' })
export class categoryNamePipe implements PipeTransform {
    constructor() { }
    transform(value: number): string {
        let categoryName: any = {
            1: 'STORY',
            2: 'ARTICLES',
            3: 'VIDEOS',
            4: 'QUESTIONSANDANSWERS',
            5: 'EMAILID'
        };
        return categoryName[value];
    }
}

@Pipe({ name: 'moduleName' })
export class moduleNamePipe implements PipeTransform {
    constructor() { }
    transform(value: number): string {
        let moduleName: any = {
            1: "Absence",
            2: "Leave",
            3: "Client",
            4: "Contact",
            5: "Sub contractor",
            6: "Food safety",
            7: "FDV",
            8: "Project",
            9: "Follow up",
            10: "Ik Client",
            11: "Framework agreement",
            12: "Service agreement",
            13: "Kunde EL"
        };
        return moduleName[value];
    }
}

@Pipe({ name: 'month' })
export class MonthPipe implements PipeTransform {
    constructor() { }
    transform(value: string): string {
        let name: string;
        let months: any = {
            '01': 'January',
            '02': 'February',
            '03': 'March',
            '04': 'April',
            '05': 'May',
            '06': 'June',
            '07': 'July',
            '08': 'August',
            '09': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        };
        return months[value];
    }
}

@Pipe({ name: 'licenseCategory' })
export class LicenseCategoryPipe implements PipeTransform {
    constructor() { }
    transform(value: number): string {
        let name = null;
        let category:any = {
            1: 'DEMO_INTERNAL_USE',
            2: 'MANUAL_WITHOUT_FOLLOWUP',
            3: 'KUBA_BASIC',
            4: 'KUBA_STANDARD',
            5: 'FOOD_SAFETY_LICENSE',
            6: 'KUBA_TOTAL',
            7: 'ENTERPRISE',
            8: 'OCCUPATIONAL_HEALTH_SERVICE'
        };
        name = category[value];
        return name;
    }
}

@Pipe({ name: 'assignValue' })
export class AssignValuePipe implements PipeTransform {
    constructor() { }
    transform(value: any, data: any, columnName: any): string {
        data[columnName] = value;
        return value;
    }
}
@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
    transform(value: any) {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }
}

@Pipe({ name: 'formatHours' })
export class FormatHoursPipe implements PipeTransform {
    transform(value: any) {
        if (value) {
            return value.slice(0, -3);
        }
        return value;
    }
}

@Pipe({ name: 'role' })
export class RolesPipe implements PipeTransform {
    constructor() { }
    transform(value: string): string {
        let roles: any = {
            '1': 'ADMIN',
            '2': 'PORTAL',
            '3': 'EDITOR',
            '4': 'GUEST',
            '5': 'USER',
            '6': 'DEVELOPER',
            '9': 'SUPER'
        };
        return roles[value];
    }
}

@Pipe({ name: 'module' })
export class ModulesPipe implements PipeTransform {
    constructor() { }
    transform(value: string): string {
        let modules: any = {
            '11': 'CLIENT',
            '12': 'SUBCONTRACTOR',
            '10': 'CONTACT',
            '8': 'EMPLOYEE',
            '165': 'CLIENT_CONTACT',
            '166': 'SUBCONTRACTOR_CONTACT'
        };
        return modules[value];
    }
}

@Pipe({ name: 'fomatTime' })
export class FormatTimePipe implements PipeTransform {
    constructor() { }
    transform(value: string): string {
        if (value && value.indexOf(':') > -1) {
            let formatted = value.split(':');
            if (formatted.length > 2) {
                return `${formatted[0]}:${formatted[1]}`;
            }
        }
        return '';
    }
}

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    public transform(value: any, keys: string, term: string) {
        if (!term) {
            return value;
        }
        return (value || []).filter((item: any) =>
            keys
                .split(',')
                .some(
                    key =>
                        item.hasOwnProperty(key) &&
                        new RegExp(term, 'gi').test(item[key])
                )
        );
    }
}

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
    transform(value:any, args: string[]): any {
        let keys = [];

        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                keys.push({ key: key, value: value[key] });
            }
        }
        return keys;
    }
}


@Pipe({
    name: 'activeFileCount'
})
export class ActiveFileCount implements PipeTransform {
    public transform(value: any) {
        let list = value;
        let count = 0;
        if (list) {
            list = list.filter((x: any) => x.type === 'file');
            return list.length;
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'activeFolderCount'
})
export class ActiveFolderCount implements PipeTransform {
    public transform(value: any) {
        let list = value;
        let count = 0;
        if (list) {
            list = list.filter(x => x.type === 'folder');
            return list.length;
        } else {
            return '';
        }
    }
}

@Pipe({
    name: 'defaultFolderTranslate'
})
export class DefaultFolderTranslate implements PipeTransform {
    private subscriptions: Subscription[] = [];
    constructor(private translate: TranslateService) { }
    public transform(value: any) {
        let currentNode = value;
        if (currentNode != null && currentNode.data.translateKey !== null && currentNode.data.isManualArticle !== true
            && (currentNode.data.isDefault === true || currentNode.data.translateKey === 'IMAGES')) {
                this.subscriptions.push(this.translate.stream('DEFAULT_FOLDER').subscribe(val => {
                currentNode.data.filename = val[currentNode.data.translateKey];
            }));
            return currentNode;
        } else {
            return currentNode;
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

@Pipe({
    name: 'dropdownSelectTranslate'
})
export class DropdownSelectTranslate implements PipeTransform {
    private subscriptions: Subscription[] = [];
    constructor(private translate: TranslateService) { }
    public transform(value: any) {
        let selectedValue = value;
        if (selectedValue.length > 0) {
            if (selectedValue[0].value == null) {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    selectedValue[0].label = val.SELECT;
                    var index = selectedValue.findIndex((i: any) => i.label === "Default" || i.label === "Misligholde" || i.label === "Standard");
                    if (index >= 0) {
                        selectedValue[index].label = val.DEFAULT;
                    }
                }));
            }
        }
        return selectedValue;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

@Pipe({
    name: 'userdropdownSelectTranslate'
})
export class UserDropdownSelectTranslate implements PipeTransform {
    private subscriptions: Subscription[] = [];
    constructor(private translate: TranslateService) { }
    public transform(value: any) {
        let selectedValue = value;
        if (selectedValue[0].value == null) {
            this.subscriptions.push(this.translate.stream('SELECT_PERSON').subscribe(val => {
                selectedValue[0].label = val.SELECT_A_PERSON;
            }));
        }
        return selectedValue;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

@Pipe({
    name: 'sicknessReason',
    pure: false
})
export class SicknessReasonTranslate implements PipeTransform {
    private subscriptions: Subscription[] = [];
    constructor(private translate: TranslateService) { }
    public transform(value: any) {
        let translatedReason = value;
        if (translatedReason) {
            this.subscriptions.push(this.translate.stream(value.split(',')).subscribe(data => {
                const values = Object.keys(data).map(key => data[key]);
                translatedReason = values.join(',');
                return translatedReason;
            }));
        }
        return translatedReason;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

