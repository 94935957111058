import { Rights } from './../../../_models/feature';
import { BaseServices } from './../../kuba.services';
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
    selector: 'framework-list',
    templateUrl: 'frameworkAgreementList.component.html'
})

export class FrameworkListComponent implements OnInit {
    isFrameWorkAgreementRights = true;
    isHideFrameWorkAgreementRights= true;

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        /*
         * get framework list
         */
        this.router.navigate(['all'], { relativeTo: this.route })
        if (+BaseServices.roleId === 4) {
            this.isFrameWorkAgreementRights = false;
            this.router.navigate(['own'], { relativeTo: this.route })
        }else if (+BaseServices.roleId === 5) {
            this.onFrameWorkAgreementRights();
        }else {
            this.OnAdmin();
         }
    }
    onFrameWorkAgreementRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.FRAMEWORK_AGREEMENT_RIGHTS;
        if (currentUserRole === 'User') {
            this.isFrameWorkAgreementRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
            this.router.navigate(['own'], { relativeTo: this.route });
        } else {
            this.isFrameWorkAgreementRights = true;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
    }

    OnAdmin(){
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
        if(curSession > 0)
        {
            this.isFrameWorkAgreementRights=true;
            this.isHideFrameWorkAgreementRights = false;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
        else{
            this.isFrameWorkAgreementRights = true;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
    }
}
