<div class="row" *ngIf="treeData.isEditMode">
    <div class="col-sm-12">
        <div class="upload-file-container">
            <label>
                <span translate>Upload Manual Image</span>
            </label>
            <div class="upload-actions">
                <label class="btn btn-outline-secondary upload-button">
                    <input type="file" ngFileSelect> Browse
                </label>
            </div>
            <div class="upload-items">
                <!-- /* Kuba 14 migration initial check */  -->
                <!-- <div class="upload-item" *ngFor="let f of files; let i = index;">
                    <div class="filename">
                        <span>{{ f.name }} ({{ f.id }}) - {{ f.progress?.data?.percentage }}%</span>
                        <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
                        <span>ETA {{ f.progress?.data?.etaHuman }}</span>
                    </div>
                    <div class="progress">
                        <span class="bar" [style.width]="f?.progress?.data?.percentage + '%'"></span>
                    </div>
                </div> -->
            </div>
            <!-- /* Kuba 14 migration initial check */  -->
            <!-- <div *ngIf="businessLogo != null">
                <img src="{{businessLogo}}" alt="" width="100" height="100" class="thumbnail">
            </div> -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="company-image">
            <img class="img-fluid" src="assets/images/KubaManual.JPG" alt="Manual Image">
        </div>
    </div>
</div>