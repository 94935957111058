import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { FollowUpMessageService } from './message.services';

@Injectable()
export class MessageListResolver implements Resolve<any[]> {
    constructor(private backend: FollowUpMessageService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getMessages(BaseServices.UserId);
    }
}
@Injectable()
export class OutBoxMessageResolver implements Resolve<any[]> {
    constructor(private backend: FollowUpMessageService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getOutBoxMessages(BaseServices.UserId);
    }
}
@Injectable()
export class MessageViewResolver implements Resolve<any[]> {
    constructor(private backend: FollowUpMessageService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getMessageById(+route.params['Id']);
    }
}



@Injectable()
export class MessageUserListResolver implements Resolve<any[]> {
    constructor(private backend: FollowUpMessageService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        if ((BaseServices.roleId === '3' || BaseServices.roleId === '4' || BaseServices.roleId === '5') &&
            BaseServices.PortalId > 0) {
            return this.backend.getToListForBusinessUnderPortal();
        } else {
            return this.backend.getToListForBusinessUnderAdmin();
        }
    }
}

@Injectable()
export class MessageInboxUserListResolver implements Resolve<any[]> {
    constructor(private backend: FollowUpMessageService) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.roleId === '1') {
            return this.backend.getAdminAndPortal();
        } else if (BaseServices.roleId === '2') {
            return this.backend.getToListForPortal();
        } else if (
            (BaseServices.roleId === '3' || '4' || '5') &&
            BaseServices.PortalId > 0
        ) {
            return this.backend.getToListForBusinessUnderPortal();
        } else if (
            (BaseServices.roleId === '3' || '4' || '5') &&
            BaseServices.BusinessId > 0
        ) {
            return this.backend.getToListForBusinessUnderAdmin();
        }
    }
}




