import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { timeout } from 'rxjs-compat/operator/timeout';

@Component({
  selector: 'app-deviation-widget',
  templateUrl: './deviation-widget.component.html',
  styleUrls: ['./deviation-widget.component.css'],
})
export class DashboardDeviationChartComponent implements OnInit {
  single: any[];
  view: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Status';
  showYAxisLabel = false;
  yAxisLabel = 'Amount';
  shouldShow = new BehaviorSubject(false);

  colorScheme = {
    domain: ['#F7941E', 'var(--color-primary-500)', '#329934', '#AAAAAA'],
  };

  @Input() deviationCount: any[];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.shouldShow.next(true);
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['deviationCount']) {
      this.updateChartData(this.deviationCount);
    }
  }

  /**
   * Update chart data based on the input data.
   * @param data - The input deviation data array.
   */
  updateChartData(data: any[]) {
    const translationRequests = data.map((item) =>
      this.translate.get(item.StatusText)
    );

    forkJoin(translationRequests).subscribe((translations) => {
      this.single = data.map((item, index) => ({
        name: translations[index],
        value: item.Count,
      }));
    });
  }

  /**
   * Navigate to the deviation follow-up page depending on if you are admin, portal or business user.
   * @param event - The event object.
   */
  onSelect(event: any) {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    segments[segments.length - 1] = 'follow-up';
    segments.push('deviations');
    const deviationPath = segments.join('/');

    this.router.navigate([deviationPath]);
  }
}
