<div class="page-title">
    <span translate>NEW_ACTIVITY</span>
</div>
<form [formGroup]="activityForm" class="form">
    <div>
        <input type="hidden" value="{{ActivityScheduleId}}" />
    </div>
    <div class="row">
        <div class="col-sm-6" *ngIf="isBusiness">
            <div class="form-group">
                <label for="responsiblePerson" class="col-form-label" translate>BUSINESS_LIST
                    <span class="required">*</span>
                </label>
                <div>
                    <p-multiSelect [options]="businessList" formControlName="Business" [style]="{'width':'160px'}">
                    </p-multiSelect>
                </div>
                <control-messages [control]="activityForm.controls['Business']"></control-messages>
            </div>
            <div class="form-group">
                <label for="title" class="col-form-label" translate>TITLE
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="Title" />
                </div>
                <control-messages [control]="activityForm.controls['Title']"></control-messages>
            </div>
            <div class="form-group">
                <label for="EndDate" class="col-form-label">
                    <span translate>DEADLINE</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <my-date-picker name="deadline" [options]="dateOptions" formControlName="Deadline"
                        placeholder="Select a date" required></my-date-picker>
                </div>
                <control-messages [control]="activityForm.controls['Deadline']"></control-messages>
            </div>
            <div class="form-group">
                <label for="repetition" class="col-form-label" translate>REPETITION
                    <span class="required">*</span>
                </label>
                <div>
                    <p-dropdown [filter]="true" formControlName="RepetitionId"
                        [options]="vRepetition" [style]="{'width':'160px'}">
                        <ng-template let-repetiton pTemplate="item"> {{ repetiton.label | translate }}
                        </ng-template>
                    </p-dropdown>
                </div>
                <control-messages [control]="activityForm.controls['RepetitionId']"></control-messages>
            </div>
            <div class="form-group">
                <label for="Description" class="col-form-label" translate>DESCRIPTION</label>
                <div>
                    <textarea name="Description" class="form-control" formControlName="Description"></textarea>
                </div>
            </div>
            <div class="form-group">
                <label for="repetition" class="col-form-label" translate>CHECKLIST
                </label>
                <div>
                    <p-dropdown [filter]="true" formControlName="CheckList" [options]="CheckListData"
                        [style]="{'width':'160px'}"></p-dropdown>
                </div>
                <control-messages [control]="activityForm.controls['CheckList']"></control-messages>
            </div>
            <div class="form-group">
                <div class="checkbox">
                    <p-checkbox label="{{'Repeat checklist for recurring' | translate}}"
                        formControlName="RepeatCheckList" binary="true"></p-checkbox>
                </div>
            </div>
            <div class="form-group">
                <label for="notificationDeadline" class="col-form-label"
                    translate>NOTIFICATION_BEFORE_DEADLINE_ACTIVITIES</label>
                <div>
                    <input type="number" min="0" (keypress)="onNumber($event,2)" pInputTextarea
                        (keypress)="onNumber($event,2)" formControlName="NotificationBeforeDeadline" />
                </div>
            </div>
            <div class="form-group">
                <label for="lblNotificationFollowUp" class="col-form-label"
                    translate>NOTIFICATION_BEFORE_THE_ACTIVITY_DAYS</label>
                <div>
                    <div>
                        <p-dropdown [options]="FollowUpDays" [style]="{'width':'100%'}"
                            formControlName="NotificationBeforeFollowUp"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                        (saveChanges)="saveUploaded($event)">
                    </ecpl-document-explorer>
                </div>
            </div>
            <div class="form-group">
                <label for="lblURL" class="col-form-label" translate>URL</label>
                <div>
                    <input type="text" pInputText formControlName="LinkUrl" />
                </div>
            </div>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button class="btn btn-outline-secondary" (click)="gotoList()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
        <button class="btn btn-primary" [disabled]="!activityForm.valid" (click)="savePortalActivity()">Save</button>
    </div>
</form>
<toaster-component></toaster-component>