import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Electro, ContractDetail, ElectroInternalControl, ElectroDeviationClosing, ElectroContractContentData, ContractDetailEdit } from '../../models/electrocontract';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroService } from '../../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { Rights } from 'src/app/_models';
import { ElectroDocumentService } from '../../services/electro-document.services';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CompleteCheckListServices } from 'src/app/kuba/shared/services/complete-checklist.service';

@Component({
    selector: 'contract-detail',
    templateUrl: 'contract-detail.component.html'
})

export class ContractDetailComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    electro: Electro;
    contractId: number;
    editMode = false;
    tasks: any;
    loading = false;
    details: ElectroInternalControl;
    editorName: string;
    page: string;
    units: any;
    isGuestHideButton = true;
    createdOn: string;
    firstInit = true;
    isClient: boolean;
    isClientSigned: boolean;
    isElectricianSigned: boolean;
    electricianSign:string;
    clientSign:string;
    electricianSignInfo: string;
    clientSignInfo:string;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private electroServices: ElectroService,
        private electroDocumentServices: ElectroDocumentService,
        private CompleteCheckListServices: CompleteCheckListServices,
        private datePipe: DatePipe
    ) {
        this.isClient = BaseServices.FeatureId !== 8;
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }

        this.contractId = this.route.snapshot.parent.parent.params['cid']
        this.subscriptions.push(this.translate.stream('SELECT_UNIT').subscribe(val => {
            this.units = [];
            this.units.push(
                { label: val.SELECT, value: '' },
                { label: val.FIXED_PRICE, value: 1 },
                { label: val.PER_INSPECTION, value: 2 },
                { label: val.PER_YEAR, value: 3 },
                { label: val.HOURLY_RATE, value: 4 }
            );
        }));
        this.electro = this.route.snapshot.data['contractInfo'];
        let contractDetail = this.route.snapshot.data['contractDetail'];
        let currentTab = this.route.snapshot.url[0].path;//get path of current tab
        // type-1 on selected tab is internal control and type-2 on deviation closing
        if (!contractDetail) {
            contractDetail = new ContractDetail();
        }
        this.details = contractDetail.ElectroInternalControl ? contractDetail.ElectroInternalControl : [];
        this.details.DocumentNumber = "IKK0201";
        this.details.Version = this.details.Version ? this.details.Version : 1;
        this.editorName = contractDetail.InternalControlModifiedByName;
        this.page = "1_OF_1";
        this.electricianSign = contractDetail.ElectricianSign;
        this.clientSign = contractDetail.ClientSign;
        this.electricianSignInfo = contractDetail.ElectricianSignInfo;
        this.clientSignInfo = contractDetail.ClientSignInfo;
        
        let modified = null;
        if (this.details.CreatedOn) {
            this.createdOn = this.details.CreatedOn;
            modified = this.details.ModifiedOn;
            let ccDateObj = new Date(this.electro.CreatedOn);
            let cDateObj = new Date(this.details.CreatedOn);
            this.details.CreatedOn = ccDateObj.toLocaleDateString('en-GB');
            this.details.ModifiedOn = cDateObj.toLocaleDateString('en-GB');
        }
        if (modified) {
            let mDateObj = new Date(modified);
            this.details.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
        }

        let Content = this.route.snapshot.data['contractcontent'];
        if (Content) {
            this.tasks = [];
            Content.forEach(task => {
                this.tasks.push(task);
            });
        }
        let internalTasks = [];
        if (this.electro && this.electro.ContractContentsData) {
            this.electro.ContractContentsData.forEach(task => {
                let internalTask = this.tasks.find(x => x.Id == task.ContentId)
                if (internalTask) {
                    internalTask.Id = task.Id;
                    internalTask.Interval = task.Interval;
                    internalTask.ContentId = task.ContentId;
                    internalTask.ElectroContractId = task.ElectroContractId;
                    internalTask.Unit = task.Unit;
                    internalTask.UnitText = task.Unit == 1 ? "FIXED_PRICE" : task.Unit == 2 ? "PER_INSPECTION" :
                        task.Unit == 3 ? "PER_YEAR" : task.Unit == 4 ? "HOURLY_RATE" : '';
                    internalTask.Cost = task.Cost;
                    internalTask.PerformedForm = task.PerformedForm;
                    internalTasks.push(internalTask);
                }
            });
        }

        this.tasks = internalTasks;
    }

    ngOnInit() { }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    /**
     * Save contract details.
     */
    saveChanges() {
        let contractDetail = new ContractDetailEdit();
        contractDetail.Id = this.details.Id;
        contractDetail.DocumentNumber = /* this.controlTabType == 1 ? */ "IKK0201" /* : "IKK0202" */;
        contractDetail.ContractId = this.contractId;
        contractDetail.ModifiedBy = BaseServices.UserId;
        contractDetail.Version = this.details.Version;
        contractDetail.Description = this.details.Description;
        contractDetail.TermsDuration = this.details.TermsDuration;
        if (this.details.Id) {
            contractDetail.CreatedBy = this.details.CreatedBy;
            contractDetail.CreatedOn = this.createdOn;
        }
        else {
            contractDetail.CreatedBy = BaseServices.UserId;
        }
        contractDetail.ContractContentsData = [];
        this.tasks.forEach(task => {
            let data = new ElectroContractContentData();
            data.Id = task.Id;
            data.ElectroContractId = task.ElectroContractId
            data.ContentId = task.ContentId;
            data.Interval = task.Interval;
            data.Unit = task.Unit;
            data.Cost = task.Cost;
            data.PerformedForm = task.PerformedForm;
            contractDetail.ContractContentsData.push(data);
        });
        this.tasks;
        if (this.isElectricianSigned || this.isClientSigned) {
            if(this.isClient){
                contractDetail.ClientSign = this.canvas.nativeElement.toDataURL();
                contractDetail.ClientSignInfo = this.clientSignInfo;
            }else{
                contractDetail.ElectricianSign = this.canvas.nativeElement.toDataURL();
                contractDetail.ElectricianSignInfo = this.electricianSignInfo;
            }
        }
        this.subscriptions.push(this.electroServices.saveUpdateInternalControl(contractDetail).subscribe(result => {
            if (result ? result.ElectroInternalControl : false) {
                this.details.Id = result.ElectroInternalControl.Id;
                if (result.ElectroInternalControl.CreatedOn) {
                    this.createdOn = result.ElectroInternalControl.CreatedOn;
                    let cDateObj = new Date(this.details.CreatedOn);
                    this.details.ModifiedOn = cDateObj.toLocaleDateString('en-GB');
                }
                if (result.ElectroInternalControl.ModifiedOn) {
                    let mDateObj = new Date(result.ElectroInternalControl.ModifiedOn);
                    this.details.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
                }
                if (this.tasks) {
                    this.tasks.forEach(task => {
                        task.UnitText = task.Unit == 1 ? "FIXED_PRICE" : task.Unit == 2 ? "PER_INSPECTION" :
                            task.Unit == 3 ? "PER_YEAR" : task.Unit == 4 ? "HOURLY_RATE" : '';
                    });
                }
                this.toasterComponent.callToast();
                this.isClientSigned = false;
                this.isElectricianSigned = false;
                this.electricianSign = result.ElectricianSign;
                this.clientSign = result.ClientSign;
                if(this.isClient && this.clientSign){
                    this.bindSign();
                }
                else if(this.electricianSign){
                    this.bindSign();
                }
                else{
                    this.clearSign();
                }
            }
        }));
    }

    /**
     * print contract details as pdf.
     */
    printPdf() {
        let ContractNumber = localStorage.getItem('contractNumber');
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = sessionStorage.getItem('languageMode');
        this.subscriptions.push(this.electroDocumentServices
            .generateContractControlReport(this.contractId, CultureInfo)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${ContractNumber + '-' + this.translate.instant('CONTRACT_INTERNAL_CONTROL')}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }));
    }

    //#region signature
    @ViewChild('canvas',{static: false}) public canvas: ElementRef;


    private cx: CanvasRenderingContext2D;
    i = new Image;

    public ngAfterViewInit() {//canvas property adjustment after view child init.

        this.cx = null;
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        canvasEl.width = 380;
        canvasEl.height = 150;

        this.cx.lineWidth = 3;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#000';

        this.captureEvents(canvasEl);
    }
    ngAfterViewChecked() {
        if (this.firstInit) {
            setTimeout(() => {
                this.bindSign();
            }, 300);//binding saved sign after CanvasRenderingContext2D(cx) view init completed.
        }
    }

    /**
     * canvas mouse event capturer.
     * @param canvasEl 
     */
    private captureEvents(canvasEl: HTMLCanvasElement) {
        this.subscriptions.push(Observable
            .fromEvent(canvasEl, 'mousedown')
            .switchMap((e) => {
                return Observable
                    .fromEvent(canvasEl, 'mousemove')
                    .takeUntil(Observable.fromEvent(canvasEl, 'mouseup'))
                    .pairwise()
            })
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);
            }));
    }

    /**
     * draw on canvas function.
     * @param prevPos 
     * @param currentPos 
     */
    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
        if (!this.cx) { return; }
        this.subscriptions.push(this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
            if (result) {
                let Signature =
                    BaseServices.Name +
                    ' ' +
                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                    if(this.isClient){
                        this.clientSignInfo=Signature;
                    }else{
                        this.electricianSignInfo=Signature;
                    }
            }
        }));
        if (this.isClient) {
            this.isClientSigned = true;
            this.isElectricianSigned = false;
        } else {
            this.isClientSigned = false;
            this.isElectricianSigned = true;
        }
        this.cx.beginPath();

        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }
    }

    /**
     * clear signature from canvas.
     */
    clearSign() {
        this.firstInit = false;
        this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.clientSignInfo='';
        this.electricianSignInfo='';
    }

    /**
     * bind signature to canvas.
     */
    bindSign() {
        if((this.isClient ? this.clientSign : (this.editMode && this.electricianSign))){
            this.i.src = this.isClient ? this.clientSign : this.electricianSign;
            this.cx.drawImage(this.i, 0, 0);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}