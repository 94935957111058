import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AboutKuba } from './../dashboard/models/dashboard';

@Component({
    moduleId: module.id,
    templateUrl: 'about.component.html'
})
export class AboutComponent implements OnInit {
    //#region variable

    about: string;

    //#endregion
    //#region constructor

    constructor(private route: ActivatedRoute) { }

    //#endregion

    //#region page-event

    ngOnInit() {
        this.bindAbout();
    }

    //#endregion

    //#region methods

    bindAbout() {
        let about = <AboutKuba[]>this.route.snapshot.data['aboutUs'];
        if (about != null) {
            let aboutKuba = about.filter(x => x.CmsId === 53);
            if (aboutKuba.length > 0) {
                this.about = aboutKuba[0].ContentData;
            }
        }
    }

    //#endregion
}
