import { Rights } from 'src/app/_models';

import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ServiceAgreementServices } from './../services/service-agreement.service'
import { ServiceAgreement } from '../models/service-agreement';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'service-agreement-list',
    templateUrl: 'service-agreement-list.component.html'
})
// tslint:disable-next-line:component-class-suffix
export class ServiceAgreementList implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    contracts: ServiceAgreement[];
    isServiceAgreementRights = true;
    pdf: any;
    additionalData: any;
    isGuest = true;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute
        , private confirmationService: ConfirmationService
        , private serviceAgreementServices: ServiceAgreementServices,
        private translate: TranslateService) {
        this.additionalData = {
            fileName: 'Service Agreement List',
            header: 'Service Agreement List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Contract Number', dataKey: 'ContractNumber' },
                { title: 'Contract Name', dataKey: 'ContractName' },
                { title: 'Client Name', dataKey: 'ClientName' },
                { title: 'Created By', dataKey: 'CreatedName' },
                { title: 'Status', dataKey: 'Status' }
            ]
        };
    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
        }
        this.contracts = [];
        let list;
        if (+BaseServices.roleId === 4) {
            this.isServiceAgreementRights = false;
            list = <ServiceAgreement[]>this.route.snapshot.data['mylist'];
        } else {
            list = <ServiceAgreement[]>this.route.snapshot.data['list'];
        }
        if (list != null) {
            for (let t of list) {
                if (t.Status === '2') {
                    t.Status = 'New';
                } else if (t.Status === '3') {
                    t.Status = 'Open';
                } else if (t.Status === '4') {
                    t.Status = 'Done';
                } else if (t.Status === '5') {
                    t.Status = 'Rejected';
                } else { t.Status = 'New'; }
            }
            this.contracts.push(...list);
        }
        this.onServiceAgreementRights();
    }

    /**
     * get frameworklist
     */
    getProjectList() {
        this.subscriptions.push(this.serviceAgreementServices
            .getServiceAgreementlistByBusiness()
            .subscribe(result => {
                let list = result;
                list.forEach(t => {
                    if (t.Status === '2') {
                        t.Status = 'New';
                    } else if (t.Status === '3') {
                        t.Status = 'Open';
                    } else if (t.Status === '4') {
                        t.Status = 'Done';
                    } else if (t.Status === '5') {
                        t.Status = 'Rejected';
                    } else { t.Status = 'New'; }
                });
                this.contracts = [];
                this.contracts.push(...list);
            }));
    }

    exportCSV() { }
    /**
   * pdf report for framework and agreement list
   */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Serviceagreement list';
        let columns = [
            { title: 'Contract Number', dataKey: 'ContractNumber' },
            { title: 'Contract Name', dataKey: 'ContractName' },
            { title: 'Client Name', dataKey: 'Client' },
            { title: 'Status', dataKey: 'Status' },
            { title: 'Created By', dataKey: 'CreatedName' },
            { title: 'Edited By', dataKey: 'ModifierName' },
        ];
        HelperService.generatePdf(this.contracts, columns, 'Service agreement list', pdfSetting, 'l');
    }

    /**
  * delete service based on id
  * @param  service
  */
    deleteService(service) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.serviceAgreementServices
                    .delete(service)
                    .subscribe(result => {
                        this.getProjectList();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }
    /**
       * add/update based on service agreement rights
       */
    onServiceAgreementRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_AGREEMENT_RIGHTS;
        if (currentUserRole === 'User') {
            this.isServiceAgreementRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
