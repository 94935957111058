 import { UserService } from './../users/services/user.service';
 import { BusinessServices } from './../businesses/services/business.services';
import {
    AccessLogEditResolver,
    AccessLogListResolver
} from './services/accesslog.services.resolver';
import { CreateAccessLogComponent } from './components/new-accesslog.component';
import { AccessLogServices } from './services/accesslog.services';
import { AccessLogListComponent } from './components/accesslog-list.component';
import { AccessLogComponent } from './accesslog.component';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { AppSharedModule } from '../shared/appShared.module';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TableModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ContextMenuModule,
        MenuModule,
        DialogModule,
        ReactiveFormsModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        ButtonModule,
        CheckboxModule
    ],
    declarations: [
        AccessLogComponent,
        AccessLogListComponent,
        CreateAccessLogComponent
    ],
    providers: [
        AccessLogServices,
        AccessLogEditResolver,
        AccessLogListResolver,
        UserService,
        BusinessServices 
    ],
    exports: [
        AccessLogComponent,
        AccessLogListComponent,
        CreateAccessLogComponent
    ],
    bootstrap: []
})
export class AccessLogModule {}
