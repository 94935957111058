export abstract class Common {
    Id: number;
    BusinessId: number;
    Status: string;
}

export class BusinessSocialAspect extends Common {
    SocialAspectsTypeId: number;
    IsApplicable: number;
    Description: string;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
}

export class BusinessSocialAspectType extends Common {
    Name: string;
}

