<div class="tab-content">
    <div class="page-title">
      <span translate>MEETING</span>
      <span class="page-actions">
        <a routerLink="new" [routerLink]="['./new',0]" class="btn btn-success" routerLinkActive="active">
          <span class="icon ic-sm icon-add"></span>
          <span translate>NEW</span>
        </a>
      </span>
    </div>
    <div class="table-view">
      <!-- Table starts -->
      <p-table #dt [value]="meetingData" [rows]="10" [paginator]="true" exportFilename="Meeting list" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20]"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Caption -->
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix">
            <div class="filter-controls-wrapper" *ngIf="edited">
              <div class="form-group">
                <label>
                  <span translate>CHOOSE_BUSINESS</span>
                </label>
                <div>
                  <p-dropdown [options]="meetings" placeholder="{{'Select a Business' | translate}}"
                    [(ngModel)]="selectedCity" (onChange)="dropdownChange($event.value)" [style]="{'width':'100%'}">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="table-options">
              <div class="pull-left">
              </div>
              <div class="pull-right">
                <div class="action-btns-group" *ngIf="edited">
                  <button class="btn p-3 filter-toggle">
                    <span class="icon ic-sm icon-filter"></span>
                    <span translate>FILTER</span>
                  </button>
                </div>
                <div class="action-btns-group">
                  <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData">
                  </kuba-export>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="MeetingTypeText">{{'TYPE_OF_MEETING'|translate}} <p-sortIcon field="MeetingTypeText"></p-sortIcon></th>
            <th pSortableColumn="Title">{{'MEETING_CONVERNER'|translate}} <p-sortIcon field="Title"></p-sortIcon></th>
            <th pSortableColumn="DateForPdf">{{'MEETING_DATE'|translate}} <p-sortIcon field="DateForPdf"></p-sortIcon></th>
            <th>{{'START_TIME'|translate}}</th>
            <th>{{'END_TIME'|translate}}</th>
            <th pSortableColumn="Duration">{{'HOURS_SPENT'|translate}} <p-sortIcon field="Duration"></p-sortIcon></th>
            <th>{{'ATTACHMENT'| translate}}</th>
            <th>{{'OPTIONS'| translate}}</th>
          </tr>
        </ng-template>
  
        <!-- Body Content -->
        <ng-template pTemplate="body" let-meeting>
          <tr>
            <td>
              <span>{{meeting.MeetingTypeText}}</span>
            </td>
            <td>
              <span>{{meeting.Title}}</span>
            </td>
            <td>
              <span>{{meeting.DateForPdf}}</span>
            </td>
            <td>
              <span>{{meeting.StartTime | formatHours}}</span>
            </td>
            <td>
              <span>{{meeting.EndTime | formatHours}}</span>
            </td>
            <td>
              <span>{{meeting.Duration | formatHours}}</span>
            </td>
            <td class="col-button">
              <button *ngIf="meeting.MeetingDocument.length>0" (click)="downloadAttachment(meeting.Id)"
                tooltipPosition="top" class="btn btn-icon" title="{{'Testing'|translate}}">
                <i class="icon ic-sm icon-attach" aria-hidden="true"></i>
              </button>
            </td>
            <td class="col-button">
              <a routerLink="new" [routerLink]="['./new',meeting.Id]" class="btn btn-icon" title="{{'EDIT'|translate}}"
                routerLinkActive="active">
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
              <button type="button" class="btn btn-icon" (click)="confirm(meeting.Id)" title="{{'DELETE'|translate}}">
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="8">
              {{'NO_RECORDS_FOUND'|translate}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table ends -->
      <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
    </div>
    <toaster-component></toaster-component>
  </div>
  
