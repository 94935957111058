import { SupplierServices } from './../Services/supplier.services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({

    templateUrl: 'supplierEditForm.component.html'
})
export class SupplierEditComponent implements OnInit {
    newSupplier: FormGroup;
    supplier: any;
    supplierid: number;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute,
        private supplierservice: SupplierServices) { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            let sid = params['supplierId'];
            this.supplierid = +sid
            if (this.supplierid) {
                let result = this.route.snapshot.data['detail'];

                if (result) {
                    this.supplier = {
                        Name: result.Name,
                        Mobile: result.Mobile,
                        Telephone: result.Telephone,
                        Email: result.Email
                    };
                    (<FormGroup>this.newSupplier)
                        .setValue(this.supplier, { onlySelf: true });

                }

            }

        }));


    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
