import {
    InternalControlResolver,
    InternalControlFeaturesResolver,
    InternalControlEditResolver,
    InternalControlByUserResolver,
    InternalControlNameResolver
} from './services/internal-control.resolver.service';
import { InternalControlServices } from './services/internal-control.service';
import { AppSharedModule } from './../shared/appShared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { InternalControlComponent } from './internal-control.component';
import { InternalControlEditComponent } from './components/internal-control-edit.component';
import { InternalControlListComponent } from './components/internal-control-list.component';
import { InternalControlViewComponent } from './components/internal-control-view.component';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { InternalListComponent } from './components/internalcontrol-list.component';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        AppSharedModule,
        RouterModule,
        ConfirmDialogModule,
        EcplTabNavigationModule,
        KubaExportModule

    ],
    exports: [
        InternalControlComponent,
        InternalControlEditComponent,
        InternalControlListComponent,
        InternalControlViewComponent,
        InternalListComponent
    ],
    declarations: [
        InternalControlComponent,
        InternalControlEditComponent,
        InternalControlListComponent,
        InternalControlViewComponent,
        InternalListComponent
    ],
    providers: [
        InternalControlServices,
        InternalControlResolver,
        ConfirmationService,
        InternalControlFeaturesResolver,
        InternalControlEditResolver,
        InternalControlByUserResolver,
        InternalControlNameResolver
    ],
})
export class InternalControlModule { }
