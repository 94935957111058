<div>
  <div class="row">
    <div class="col-md-6">
      <table
        width="100%"
        border="0"
        cellspacing="0"
        cellpadding="0"
        style="
          border-collapse: collapse;
          text-align: center;
          margin-bottom: 20px;
        "
      >
        <tbody>
          <tr>
            <td>
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                style="
                  text-align: center;
                  border: 1px solid rgba(0, 0, 0, 0.2);
                  border-collapse: collapse;
                "
              >
                <tbody>
                  <tr>
                    <td width="25%">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        style="text-align: center; border-collapse: collapse"
                      >
                        <thead>
                          <tr>
                            <th
                              width="25%"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 97px;
                                text-align: center;
                              "
                            >
                              <strong translate>PROBABILITY</strong>
                              <br /><strong translate
                                >GIVES_HIGHEST_PROBABLITY</strong
                              >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              width="25%"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                              "
                            >
                              5
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                              "
                            >
                              4
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                              "
                            >
                              3
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                              "
                            >
                              2
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                              "
                            >
                              1
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="75%">
                      <table
                        width="100%"
                        border="0"
                        cellspacing="0"
                        cellpadding="0"
                        style="text-align: center; border-collapse: collapse"
                      >
                        <thead>
                          <tr>
                            <th
                              style="
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                text-align: center;
                              "
                              colspan="5"
                            >
                              <strong translate>CONSEQUENCE</strong>
                              <br /><strong translate
                                >GIVES_BIGGEST_DAMAGE</strong
                              >
                            </th>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <table
                                width="100%"
                                border="0"
                                cellspacing="0"
                                cellpadding="0"
                                style="text-align: center; height: 60px"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style="
                                        border-right: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        border-bottom: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        height: 60px;
                                        width: 20%;
                                      "
                                    >
                                      1
                                    </td>
                                    <td
                                      style="
                                        border-right: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        border-bottom: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        height: 60px;
                                        width: 20%;
                                      "
                                    >
                                      2
                                    </td>
                                    <td
                                      style="
                                        border-right: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        border-bottom: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        height: 60px;
                                        width: 20%;
                                      "
                                    >
                                      3
                                    </td>
                                    <td
                                      style="
                                        border-right: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        border-bottom: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        height: 60px;
                                        width: 20%;
                                      "
                                    >
                                      4
                                    </td>
                                    <td
                                      style="
                                        border-bottom: 1px solid
                                          rgba(0, 0, 0, 0.2);
                                        height: 60px;
                                        width: 20%;
                                      "
                                    >
                                      5
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />
                              (5)
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(10)
                              <br />
                            </td>
                            <td
                              bgcolor="#ff8d7a"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              HIGH <br />(15)
                              <br />
                            </td>
                            <td
                              bgcolor="#ff8d7a"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              HIGH <br />(20)
                            </td>
                            <td
                              bgcolor="#ff8d7a"
                              style="
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                            >
                              HIGH
                              <br />(25)
                            </td>
                          </tr>
                          <tr>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(4)
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(8)
                              <br />
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(12)
                            </td>
                            <td
                              bgcolor="#ff8d7a"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              HIGH <br />(16)
                            </td>
                            <td
                              bgcolor="#ff8d7a"
                              style="
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              HIGH <br />(20)
                            </td>
                          </tr>
                          <tr>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(3)
                            </td>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(6)
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(9)
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(12)
                            </td>
                            <td
                              bgcolor="#ff8d7a"
                              style="
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              HIGH <br />(15)
                            </td>
                          </tr>
                          <tr>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(2)
                            </td>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(4)
                            </td>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(6)
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(8)
                            </td>
                            <td
                              bgcolor="#ffe971"
                              style="
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              MEDIUM <br />(10)
                            </td>
                          </tr>
                          <tr>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(1)
                            </td>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(2)
                            </td>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(3)
                            </td>
                            <td
                              bgcolor="#98ffa4"
                              style="
                                border-right: 1px solid rgba(0, 0, 0, 0.2);
                                height: 60px;
                                width: 15%;
                              "
                              translate
                            >
                              LOW <br />(4)
                            </td>
                            <td bgcolor="#98ffa4" translate>LOW <br />(5)</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                style="
                  text-align: left;
                  margin: 15px 0;
                  border-collapse: collapse;
                "
              >
                <tbody>
                  <tr>
                    <td
                      style="
                        width: 30px;
                        height: 30px;
                        background-color: #98ffa4;
                      "
                    ></td>
                    <td style="padding-left: 10px" translate>LOW_RISK</td>
                    <td>&nbsp;</td>
                    <td
                      style="
                        width: 30px;
                        height: 30px;
                        background-color: #ffe971;
                      "
                    ></td>
                    <td style="padding-left: 10px" translate>MEDIUM_RISK</td>
                    <td>&nbsp;</td>
                    <td
                      style="
                        width: 30px;
                        height: 30px;
                        background-color: #ff8d7a;
                      "
                    ></td>
                    <td style="padding-left: 10px" translate>HIGH_RISK</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        style="border: 1px solid rgba(0, 0, 0, 0.2); margin-bottom: 20px"
      >
        <tbody>
          <tr>
            <td style="padding: 10px">
              <p>
                <strong translate>ACCEPTANCE_CRITERIA</strong>
                <br />
                <span translate>CRITERIA_BASIS_DECISION</span>
              </p>
              <p>
                <strong translate>CRITERIA_USED</strong>
              </p>
              <ol style="list-style: none; padding-left: 20px">
                <li style="list-style: none">
                  <strong translate>HIGH_RISK_RED</strong>
                  <ul style="padding-left: 20px">
                    <li style="list-style: none">
                      <p translate>EVALUATE_RISKMEASURE</p>
                    </li>
                  </ul>
                </li>
                <li style="list-style: none">
                  <strong translate>MEDIUM_RISK_YELLOW</strong>
                  <ul style="padding-left: 20px">
                    <li style="list-style: none">
                      <p translate>RISK_INTERFERE</p>
                    </li>
                  </ul>
                </li>
                <li style="list-style: none">
                  <strong translate>LOW_RISK_GREEN</strong>
                  <ul style="padding-left: 20px">
                    <li style="list-style: none">
                      <p translate>ACCEPT_RISKS</p>
                    </li>
                  </ul>
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        style="border: 1px solid rgba(0, 0, 0, 0.2); margin-bottom: 20px"
      >
        <tbody>
          <tr>
            <td
              style="
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                padding: 10px;
                text-align: center;
              "
            >
              <strong translate style="margin-right: 10px"
                >PROBABILITY_RISK</strong
              >
              <span translate>UNWANTED_INCIDENTS</span>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <strong translate>NUMERICAL_VALUE</strong>
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <strong translate>DESCRIPTION_STATIC</strong>
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <strong translate>EXPLANATION</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      UNLIKELY
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>NOT_SIMILAR</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      2
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      LESS_LIKELY
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>NOT_UNLIKELY</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      3
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      LIKELY
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>RECURRING_EVENT</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      4
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      VERY_LIKELY
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>OCCUR_REGULARLY</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      5
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      MUCH_LIKELY
                    </td>
                    <td style="padding: 10px">
                      <p translate>RATIO_CONTINUOUSLY_PRESENT</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-6">
      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        style="border: 1px solid rgba(0, 0, 0, 0.2); margin-bottom: 20px"
      >
        <tbody>
          <tr>
            <td
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); padding: 10px"
            >
              <strong translate style="margin-right: 10px"
                >CONSEQUENCE_STATIC</strong
              >
              <span translate>DEGREE_DAMAGE</span>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <strong translate>NUMERICAL_VALUE</strong>
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <strong translate>DESCRIPTION_STATIC</strong>
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <strong translate>EXPLANATION</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      HARMLESS
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>NEGLIGIBLE_DAMAGE</p>
                      <p translate>NEGLIGIBLE_ENVIRONMENT</p>
                      <p translate>NEGLIGIBLE_MATERIAL</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      2
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      DANGEROUS
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>MINOR_DAMAGE</p>
                      <p translate>MINOR_ENVIRONMENT</p>
                      <p translate>MINOR_MATERIAL</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      3
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      CRITICAL
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>CONSIDERABLE_DAMAGE</p>
                      <p translate>CONSIDERABLE_ENVIRONMENT</p>
                      <p translate>CONSIDERABLE_MATERIAL</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      4
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      VERY_CRITICAL
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      <p translate>RESULT_DEATH</p>
                      <p translate>SERIOUS_ENVIRONMENT</p>
                      <p translate>SERIOUS_MATERIAL</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                    >
                      5
                    </td>
                    <td
                      style="
                        padding: 10px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      translate
                    >
                      CATASTROPHIC
                    </td>
                    <td style="padding: 10px">
                      <p translate>MANY_DEATH</p>
                      <p translate>SERIOUS_DAMAGE_ENVIRONMENT</p>
                      <p translate>MATERIAL_DESTRUCTION</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
