import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AboutComponent } from './about.component';
import { DashboardAboutKubaResolver } from '../dashboard/Services/dashboard.resolver.service';
export const aboutRoutes: Route[] = [
    {
        path: '',
        resolve: {
            aboutUs: DashboardAboutKubaResolver
        },
        children: [
            {
                path: 'about',
                component: AboutComponent
            }
        ]
    }
];
