import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';


import { BusinessLinksService } from '../services/businessLinks.service';
import { ConfirmationService } from 'primeng/api'
import { BaseServices } from './../../kuba.services';
import { Links } from './../models/businessLink';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'business-links.component.html',
})
export class BusinessLinksComponent implements OnInit {

    //#region variable

    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    linksForm: FormGroup;
    linkList: any = [];
    linksFormdata = new Links;
    Link: any;
    businessId: number;
    portalId: number;
    public Id;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor

    /**
     * constructor
     * @param businessLinksService {BusinessLinksService}
     * @param _fb {FormBuilder}
     * @param activatedRoute {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param _location {Location}
     */
    constructor(
        private businessLinksService: BusinessLinksService,
        private _fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private _location: Location,
        private translate: TranslateService
    ) {
        this.linksForm = this._fb.group({
            Title: ['', [Validators.required, ValidationService.noWhitespaceValidator]],
            Url: ['', [Validators.required, ValidationService.websiteValidator]],
        });
    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '2') {
            this.businessId = 0;
            this.portalId = +this.activatedRoute.parent?.snapshot.params['id'];
        } else {
            this.businessId = +this.activatedRoute.parent?.snapshot.params['bid'];
            this.portalId = 0;
        }
        this.bindGrid();
    }

    //#endregion

    //#region control-event

    /**
     * link list
     */
    bindGrid() {
        let list = this.activatedRoute.snapshot.data['list'];
        if (list) {
            this.linkList = list;
        }
    }
    /**
     * save link
     * @param data {any}
     */
    saveLinks(data: any) {
        this.linksFormdata.Id = this.linksFormdata.Id ? this.linksFormdata.Id : 0;
        this.linksFormdata.Title = data.Title;
        this.linksFormdata.BusinessId = this.businessId;
        this.linksFormdata.PortalId = BaseServices.PortalId;
        this.linksFormdata.ApplicationId = BaseServices.ApplicationId;
        this.linksFormdata.Status = '1';
        this.linksFormdata.CreatedBy = BaseServices.UserId;
        if (data.Url.includes('http')) {
            this.linksFormdata.Url = data.Url;
        } else {
            this.linksFormdata.Url = 'http://' + data.Url;
        }
        if (this.linksFormdata.Id > 0) {
            this.subscriptions.push(this.businessLinksService.updateLink(this.linksFormdata.Id, this.linksFormdata)
                .subscribe((isLinkUpdated) => {
                    if (isLinkUpdated) {
                        this.refreshList();
                        this.toasterComponent.callToast();
                    }
                }));
        } else {
            this.subscriptions.push(this.businessLinksService.createLink(this.linksFormdata)
                .subscribe((isLinkAdded) => {
                    if (isLinkAdded) {
                        this.refreshList();
                        this.toasterComponent.callToast();
                    }
                }));
        }
        this.clear();
    }
    /**
     * form reset
     */
    clear() {
        this.linksFormdata.Id = 0;
        this.linksForm.reset();
    }
    /**
     * table refresh
     */
    refreshList() {
        this.subscriptions.push(this.businessLinksService.getLinksByBusinessId(this.businessId).subscribe((linkData: any) => {
            if (linkData) {
                this.linkList = linkData;
            }
        }))
    }
    /**
     * delete link
     * @param Id {any}
     */
    deleteLink(Id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.businessLinksService.deleteById(Id).subscribe((deleteList: any) => {
                    if (deleteList) {
                        this.refreshList();
                        this.toasterComponent.callToastDlt();
                    }
                }))
            }
        });
    }
    /**
     * edit table link
     * @param id {any}
     */
    editLinks(id: any) {
        this.subscriptions.push(this.businessLinksService
            .getLinksById(id)
            .subscribe((result:any) => {
                this.linksFormdata = result;
                this.linksFormdata.Id = result.Id;
                if (result) {
                    this.Link = {
                        Title: result.Title,
                        Url: result.Url,
                    };
                    (<FormGroup>this.linksForm).setValue(this.Link, { onlySelf: true });
                }
            }));
    }
    /**
     * back to list
     */
    goBackLocation() {
        this._location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
