import { DeviationShared } from '../../deviation/models/deviationShared';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs/Observable';

import { BaseServices } from './../../kuba.services';
import { Pagination, PaginatedResult } from 'src/app/_models/options';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class DeviationServices {

    _caseType: Observable<any> = null!;
    constructor(private http: HttpClient) {
    }

    clearCachedCaseType() {
        this._caseType = null!;
    }
    getDepartments() {
        return this.http.get(environment.BASE_URL + '/alldepartments', BaseServices.headerOption)
            .map((result: any) => result);

    }
    // bind the type of case
    getAllCaseType() {
        return this.http.get(environment.BASE_URL + '/casetypes/' + BaseServices.BusinessId
            + '/' + BaseServices.roleId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getClients(id: number) {
        return this.http.get(environment.BASE_URL + '/client/getall/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getDeviationsByUser(id: number) {
        return this.http.get(environment.BASE_URL + '/deviationbyuser/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getDeviations(page?: number, itemsPerPage?: number): Observable<PaginatedResult<any[]>> {
        let paginatedResult: PaginatedResult<any[]> = new PaginatedResult<any[]>();

        let headers = new Headers();
        if (page != null && itemsPerPage != null) {
            headers.append('Pagination', page + ',' + itemsPerPage);
        }
        return this.http.get(environment.BASE_URL + '/deviation-paging/' + BaseServices.UserId, BaseServices.headerOption).map((res: any) => {
            paginatedResult.result = res;

            if (res.headers.get('Pagination') !== null) {
                let paginationHeader: Pagination = BaseServices.getSerialized<Pagination>(JSON.parse(res.headers.get("Pagination")));
                paginatedResult.pagination = paginationHeader;
            }
            return paginatedResult;
        });
    }

    addDeviation(data:any) {
        return this.http.post(environment.BASE_URL + '/DeviationHeader', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    getAllTopics(Id: number) {
        return this.http.get(environment.BASE_URL + '/DeviationTopic/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getTopicsForAdmin(riskId: number) {
        return this.http.get(environment.BASE_URL + '/topicsforadmin/' + riskId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getDepartmentsForAdmin(riskId: number) {
        return this.http.get(environment.BASE_URL + '/departmentforadmin/' + riskId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // add the type case
    addTypeCase(data:any) {
        return this.http.post(environment.BASE_URL + '/CaseType', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // add  the department
    addDepartment(data:any) {
        return this.http.post(environment.BASE_URL + '/department', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // update  the department
    updateDepartment(Id: number, data:any) {
        return this.http.put(environment.BASE_URL + '/department/' + Id, data, BaseServices.headerOption)
            .map((response: any) => { return response });

    }
    // delete  the department
    deleteDepartment(Id: number) {
        return this.http.delete(environment.BASE_URL + '/departmentdelete/' + Id, BaseServices.headerOption)
            .map((response: any) => { return response; });
    }
    // update the typecase
    updateCaseType(Id: number, data:any) {
        return this.http.put(environment.BASE_URL + '/CaseType/' + Id, data, BaseServices.headerOption)
            .map((response: any) => { return response });

    }
    // delete the typecase
    deleteTypeCase(Id: number) {
        return this.http.delete(environment.BASE_URL + '/CaseType/' + Id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // delete the topics
    deleteTopics(Id: number) {
        return this.http.delete(environment.BASE_URL + '/Delete/' + Id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // add the topics
    addTopics(data:any) {
        return this.http.post(environment.BASE_URL + '/Save', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // update the topics
    updateTopics(data:any) {
        return this.http.put(environment.BASE_URL + '/Update', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // list the deviations
    list() {
        return this.http.get(environment.BASE_URL + '/deviations', BaseServices.headerOption)
            .map(result => result);
    }
    deleteDeviation(id:any) {
        return this.http.delete(environment.BASE_URL + '/deviation/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }
    getUser(id: number) {
        return this.http.get(environment.BASE_URL + '/project/ContactLookup/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getDeviationByBusiness(id: number) {
        return this.http.get(environment.BASE_URL + '/deviationslist/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // bind business dropdown
    getBusiness() {
        return this.http.get(environment.BASE_URL + '/businesses', BaseServices.headerOption)
            .map((result: any) => result);
    }
    // bind deviation list
    getDeviationListWithDateFilter(startDate: string, endDate: string) {
        return this.http.get(`${environment.BASE_URL}/deviations/${startDate}/${endDate}`, BaseServices.headerOption)
            .map(result => result);
    }
    getAllDeviationByDepartment(userId:any) {
        return this.http.get(`${environment.BASE_URL}/deviationbydepartment/${userId}`, BaseServices.headerOption)
            .map(result => result);
    }
    saveDocument(document:any) {
        return this.http.post(environment.BASE_URL + '/file/upload/business/followup/deviation-docs', document, BaseServices.headerOption)
            .map(result => result);
    }

    // bind portal department
    getPortalDepartment() {
        return this.http.get(environment.BASE_URL + '/getdepartmentforportal', BaseServices.headerOption)
            .map((result:any) => result);
    }
    getById(id: number) {
        return this.http
            .get(
            environment.BASE_URL + '/deviation/' + id,
            BaseServices.headerOption
            )
            .map(result => result);
    }
    getResponsibleAndAssignedUsers(businessId: number, featureId: number, featureKey: number) {
        return this.http
            .get(`${environment.BASE_URL}/assignedresponsibleusers/${businessId}/${featureId}/${featureKey}`, BaseServices.headerOption)
            .map(result => result);
    }

    getRModeFeature() {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/rmode-feature/' + BaseServices.BusinessId, BaseServices.headerOption)
        .pipe(map((response: HttpHeaderResponse) => {
            return response
        }));
}

}
