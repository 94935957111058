import { HelpSupportListResolver,HelpSupportUserListResolver } from './services/help-support.resolver.service';
import { Route } from '@angular/router';

import { HelpSupportComponent } from './help-support.component';
import { HelpSupportListComponent } from './components/help-supportList.component';
import {HelpSupportUserListComponent} from './components/help-supportUserList.component';
import { CreateHelpSupportComponent } from './components/Createhelp-support.component';
export const helpsupportRoutes: Route[] = [
    {
        path: 'help-support',
        component: HelpSupportComponent,
        children: [
            {
                path: 'list',
                component: HelpSupportListComponent,
                resolve: {
                    list: HelpSupportListResolver,
                },
            },
            {
                path: 'userlist/:id',
                component: HelpSupportUserListComponent,
            },
            {
                path: 'create/:id',
                component: CreateHelpSupportComponent
            }

        ]
    },
];
