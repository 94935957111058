<div class="page-title">
    <span>Checklists</span>
</div>
<div class="list">
    <table class="table table-striped list">
        <thead>
            <tr>
                <th>
                    <input type="checkbox" [(ngModel)]="selectedAll" (change)="SelectAllDocuments();" />
                </th>
                <th></th>
                <th>File Name</th>
                <th>Created By</th>
                <th>Created On</th>
                <th>Edited By</th>
                <th>Edited On</th>
            </tr>
        </thead>
        <tbody class="uploads">
            <tr class="file-item" *ngFor="let f of uploadedFiles | paginate: pgConfig ; index as i ;">
                <td>
                    <input type="checkbox" [(ngModel)]="f.selected" (change)="checkIfAllSelected();" />
                </td>
                <td>
                    <span class="thumb-sm">
                        <ecpl-checklist-viewer *ngIf="f.type == 'file' && f.documentType == 'checklist'"
                          [checklistName]="f.filename" [(checklistSrc)]="uploadedFiles[i]" [id]="f.nodeId"
                          (saveChanges)="saveEmitter($event)" [editMode]="isEditMode"></ecpl-checklist-viewer>
                    </span>
                </td>
                <td>{{f.filename}}</td>
                <td>{{f.createdBy}}</td>
                <td>{{f.createdOn | date:'dd-MM-yyyy'}}</td>
                <td>{{f.modifiedBy}}</td>
                <td>{{f.modifiedOn | date:'dd-MM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>
    <pagination-controls *ngIf="(uploadedFiles)?.length > 0 && selectedPageSize !=0"
      (pageChange)="pgConfig.currentPage = $event" previousLabel="Previous" nextLabel="Next">
    </pagination-controls>
    <div class="action-btns-wrapper" style="text-align: center">
        <button class="btn btn-link" (click)="DocumentsDownloadAll()" translate>
            <span class="icon ic-sm icon-download"></span>
            DOWNLOAD_ALL
        </button>
        <p-dropdown *ngIf="(uploadedFiles)?.length > 0" [options]="pageSizeList" [(ngModel)]="selectedPageSize"
          (onChange)="onPageChanged($event)" [style]="{'width':'50px','float':'right'}"></p-dropdown>
    </div>
</div>
<router-outlet></router-outlet>