import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { Config } from 'protractor';
import { Subscription } from 'rxjs';

import { BaseServices, KubaServices } from '../kuba/kuba.services';
import { Language } from './../kuba/users/models/user';
import { UserService } from './../kuba/users/services/user.service';

@Component({
  selector: 'languages',
  templateUrl: 'languages.component.html',
  styleUrls: ['languages.component.css'],
  providers: [UserService, KubaServices, TranslateService, MessageService],
})
export class LanguagesComponent implements OnInit {
  appLanguages: any;
  userLanguage: any;
  private subscription: Subscription;
  @Output() languageChange = new EventEmitter<Language>();

  constructor(
    private messageService: MessageService,
    private userServices: UserService,
    private kubaServices: KubaServices,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (BaseServices.appLanguages) {
      this.appLanguages = BaseServices.appLanguages;
    } else {
      // this changes is for forgot password page translation
      this.appLanguages = [
        {
          Id: 1,
          Title: 'English',
          IsoCode: 'en',
          CultureInfo: 'en-GB',
        },
        {
          Id: 2,
          Title: 'Norsk',
          IsoCode: 'no',
          CultureInfo: 'no-NO',
        },
      ];
    }
    this.userLanguage = BaseServices.userLanguage;
    if (this.userLanguage === undefined) {
      (this.userLanguage = {
        Id: 2,
        Title: 'Norsk',
        IsoCode: 'no',
        CultureInfo: 'no-NO',
      }),
        this.translate.use(this.userLanguage.IsoCode);
    } else {
      sessionStorage.setItem('languageId', this.userLanguage.Id);
      sessionStorage.setItem('languageMode', this.userLanguage.CultureInfo);
      localStorage.setItem('languageId', this.userLanguage.Id);
      localStorage.setItem('languageMode', this.userLanguage.CultureInfo);
      this.translate.use(this.userLanguage.IsoCode);
    }
  }

  showInfo() {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: 'Message Content',
    });
  }

  changeLang(lang: any) {
    this.translate.use(lang.IsoCode);
    if (BaseServices.appLanguages) {
      this.subscription = this.userServices.updateLanguage(lang.Id).subscribe(
        (result: any) => {
          if (result === 'Language was updated.') {
            this.messageService.add({
              severity: 'info',
              summary: this.translate.instant('DEFAULT_LANGUAGE') + lang.Title,
              detail: '',
            });
            this.userLanguage = lang;
            this.languageChange.emit(this.userLanguage);
          } else if (result === 'Language was updated.') {
          }
        },
        (error) => console.error('Language update error:', error)
      );
    } else {
      this.userLanguage = lang;
    }
    sessionStorage.setItem('languageId', lang.Id);
    sessionStorage.setItem('languageMode', lang.CultureInfo);
    localStorage.setItem('languageId', lang.Id);
    localStorage.setItem('languageMode', lang.CultureInfo);
    this.kubaServices.setLanguage(this.translate.currentLang);
    document.documentElement.lang = lang.IsoCode;
  }
}
