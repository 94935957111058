import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../kuba/kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotificationServices {
    constructor(private http: HttpClient) {}

    getAllUserNotifications() {
        return this.http
            .get(
                environment.BASE_URL + '/notifications/users',
                BaseServices.headerOption
            )
            .map(result => result);
    }

    markNotificationsRead() {
        return this.http
            .get(
                environment.BASE_URL + '/user/notification-read', 
                BaseServices.headerOption
            )
            .map((result:any) => result.text());
    }

    updateFdvReportDownloadDate(Id: number) {
            return this.http.put(environment.BASE_URL + '/fdv-notification/' + Id,  BaseServices.headerOption)
                .map((response) => { return response });
    }
}
