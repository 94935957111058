
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FeatureManualService } from 'src/app/kuba/feature-documents/feature-document.services';
@Injectable()
export class DocumentListResolver implements Resolve<any[]> {
    constructor(private backend: FeatureManualService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        let workingModule = JSON.parse(sessionStorage.getItem("workingModule"));
        let featureKey = workingModule.id == 60 ? 0 : route.params['Id']
        return this.backend.getFeatureManualTree(featureKey);
    }
}
