<div class="main-content">
    <div class="main-heading">
        <div class="panel-title"><span translate>User Rights</span></div>
    </div>
    <table class="table table-striped">
        <thead>
            <tr>
                <th>Feature</th>
                <th>Create</th>
                <th>View Own</th>
                <th>View Any</th>
                <th>Modify Own</th>
                <th>Modify Any</th>
                <th>Delete own</th>
                <th>Delete Any</th>
                <th>Export</th>
                <th>Mails</th>
                <th>SMS</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let feature of businessFeature">
                <td scope="row">
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox> {{feature.Title}}
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
                <td>
                    <p-checkbox [(ngModel)]="value" [binary]="true"></p-checkbox>
                </td>
            </tr>

        </tbody>
    </table>
    <div class="action-btns-wrapper">
        <a class="btn btn-outline-secondary" [routerLink]="['./../list']" (click)="cancel()" role="button"><span
                translate>Cancel</span></a>
        <button class="btn btn-primary" type="submit"><span translate>Apply</span></button>
        <button class="btn btn-outline-primary" type="submit"><span translate>Save As Template</span></button>
    </div>
</div>