<div class="page-title" *ngIf="isEditPrintManual">
    <span class="pull-right page-actions">
        <button class="btn btn-primary" (click)="downloadPDF()">
            <span class="icon ic-sm icon-print"></span>
            <span translate>PRINT_AS_PDF</span>
        </button>
    </span>
</div>
<div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table #manualTable [value]="manualsList" groupField="ParentDocumentId" expandableRowGroups="true"
        rowGroupMode="subheader" [(selection)]="selectedManual"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="rowgroupheader" let-rowData style="width: 100%">
            <td *ngIf="isLeader || isEditPrintManual" style="width:28px;padding-right:20px">
                <p-checkbox binary="true" #chapterStatus
                    [ngModel]="selectedChapter.indexOf(rowData['ParentDocumentId']) !== -1"
                    (onChange)="onChapterSelected(rowData['ParentDocumentId'],chapterStatus)"></p-checkbox>
            </td>
            <td style="padding:0px 25px"><span>{{'CHAPTER' | translate }}
                    {{rowData['ParentDocumentId']}}</span></td>
            <td style="padding:0px 50px"></td>
            <td style="padding:0px 70px">{{rowData['ParentTranslationKey'] | translate}}</td>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="isLeader || isEditPrintManual" style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="DocumentNumber">{{'DOCUMENT_NR' | translate}} <p-sortIcon field="DocumentNumber"></p-sortIcon></th>
                <th pSortableColumn="DocumentType">{{'DOCUMENT_TYPE' | translate}} <p-sortIcon field="DocumentType"></p-sortIcon></th>
                <th pSortableColumn="TranslationKey">{{'TITLE'| translate}} <p-sortIcon field="TranslationKey"></p-sortIcon></th>
                <th>{{'PAGE' | translate }}</th>
                <th *ngIf="isLeader && isEditPrintManual">{{'OPTIONS'| translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-manual let-i="rowIndex">
            <tr>
                <td *ngIf="isLeader || isEditPrintManual" style="width: 38px;">
                    <p-tableCheckbox id="selectedOrders" [value]="manual">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span>{{manual.DocumentNumber}}</span>
                </td>
                <td>
                    <span translate>{{manual.DocumentType}}</span>
                </td>
                <td>
                    <a *ngIf="!isLeader; else elseBlock" href="javascript:void(0)" (click)="openDocument(manual,false)"
                        id="article-file-{{manual.Id}}">
                        <span translate>{{manual.TranslationKey}}</span>
                    </a>
                    <ng-template #elseBlock><span translate>{{manual.TranslationKey}}</span></ng-template>
                </td>
                <td>
                    <span>{{i+1}}</span>
                </td>
                <td class="col-button" *ngIf="isLeader && isEditPrintManual">
                    <button *ngIf="manual.IsNavigation" type="button" class="btn btn-icon"
                        title="{{'EDIT' | translate}}" (click)="openDocument(manual, true)">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isLeader && isEditPrintManual" let-columns>
            <tr>
                <td colspan="5">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isLeader || isEditPrintManual" let-columns>
            <tr>
                <td colspan="6">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="(!isLeader && !isEditPrintManual)" let-columns>
            <tr>
                <td colspan="4">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
</div>
<div class="action-btns-wrapper" *ngIf="isLeader && (!isEditPrintManual)">
    <button class="btn btn-primary" type="button" (click)="saveChanges()">
        <span translate>SAVE_NEXT</span>
    </button>
</div>
<toaster-component></toaster-component>
<div #hiddenAction style="width:1px; height:1px; overflow:hidden" *ngIf="editDoc != null">
    <ecpl-article-viewer *ngIf="manualType === 'article'" [articleName]="editDoc['articleName']" [(articleSrc)]="editDoc"
        (saveChanges)="saveUploaded($event)" [id]="editDoc.article.id" [editMode]="editMode">
    </ecpl-article-viewer>
    <ecpl-checklist-viewer *ngIf="manualType === 'checklist'" [checklistName]="editDoc['checkList'].title"
        [(checklistSrc)]="editDoc" (saveChanges)="saveUploaded($event)" [editMode]="editMode">
    </ecpl-checklist-viewer>
    <electro-facts-abt-company *ngIf="manualType === 'factform'" [editMode]="editMode"
        [facts]="editDoc.article.factAboutCompanyData"></electro-facts-abt-company>
</div>
<div [ngClass]="isiFrameEnabled ? 'e-org-modal' : 'hidden'">
    <div class="modal-dialog">
        <div class="modal-header">
            <button (click)="hideModal()" class="btn btn-icon close">
                <span class="icon ic-md icon-multiply"></span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="isiFrameEnabled" class="col-sm-12">
                <div class="ptop-10 pbtm-10" *ngIf="!editMode">
                    <div class="row">
                        <div class="col-md-3">
                            <label translate>DOCUMENT_NUMBER</label>:
                            <label class="text-primary">{{orgchartData.DocumentNumber}}</label>
                        </div>
                        <div class="col-md-3">
                            <label translate>EDITED_DATE</label>:
                            <label class="text-primary">{{EditedOn | date : 'dd.MM.yyyy'}}</label>
                        </div>
                        <div class="col-md-3">
                            <label translate>EDITED_BY</label>:
                            <label class="text-primary">{{orgchartData.EditorName}}</label>
                        </div>
                        <div class="col-md-3">
                            <label translate>VERSION</label>:
                            <label class="text-primary">{{orgchartData.Version}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <iframe *ngIf="editMode" #iframe frameborder="0" width="100%" height="100%"></iframe>
            <img style="margin-top: 25px" *ngIf="!editMode && isSvgchart" [src]="svgchart">
        </div>
    </div>
</div>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->