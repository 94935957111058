import { Component, OnInit, Input, DoCheck, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';

import { HelperService } from './../../../_services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { AdminUserService } from './../../adminuser/services/adminuser.service';
import { User } from './../models/user';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights } from 'src/app/_models/feature';
import { Subscription } from 'rxjs';

@Component({
    selector: 'user-form',
    templateUrl: 'userForm.component.html',
})
export class UserFormComponent implements OnInit, OnChanges, DoCheck {
    @Input() userForm: FormGroup;
    @Input() portalUSerId: number;
    @Input() portalUserRightsData: any;
    @Output() setPortalUserRights = new EventEmitter<any>();
    user: User;
    mobileCodes: any;
    uploadRequest: any;
    cLanguage: any;
    CRole: any;
    Roles: SelectItem[];
    Status: any;
    users: any;
    userData: any;
    Language: SelectItem[];
    roleFilter = [3, 4, 5];
    isNew = true;
    isExistingUser = false;
    portalInitialRightsData: any = [];
    disabledIsPortalManager = false;
    private subscriptions: Subscription[] = [];
    constructor(private userService: UserService,
        private adminUserService: AdminUserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService

    ) {
        this.portalInitialRightsData = [
            {
                'id': '1',
                'name': 'ACTIVE_ACCOUNT',
                'isChecked': true
            },
            {
                'id': '37',
                'name': 'PORTAL_MANAGER',
                'isChecked': false
            },
            {
                'id': '10',
                'name': 'APPROVE_ABSENCE',
                'isChecked': false
            },
            {
                'id': '11',
                'name': 'APPROVE_LEAVE',
                'isChecked': false
            },
            {
                'id': '12',
                'name': 'APPROVE_TIMETABLE',
                'isChecked': false
            }, {
                'id': '3',
                'name': 'EDIT_MANUALS',
                'isChecked': false
            },
            {
                'id': '45',
                'name': 'DOCUMENTED_TRAINING_RIGHTS',
                'isChecked': false,
                'rightsType': 3
            },
            {
                'id': '46',
                'name': 'APPROVAL_DOCUMENT_RIGHTS',
                'isChecked': false,
                'rightsType': 1
            },
        ];
    }

    /**
     * page init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.bindCountryCode();
        if (this.portalUSerId > 0) {
            this.isExistingUser = true;
        }

        if (+BaseServices.roleId === 2 && BaseServices.PortalManager === false) {
            this.isExistingUser = true;
        }

        this.subscriptions.push(this.adminUserService.checkNewUser$.subscribe(condition => {
            this.isExistingUser = condition;
        }));
        this.Language = [];
        this.Language.push({ label: 'select a Language', value: null });
        this.subscriptions.push(this.userService.getLanguages().subscribe(result => {
            this.cLanguage = result;
            this.cLanguage.forEach((element: any) => {
                this.Language.push({ label: element.Title, value: element.Id });
            });
        }));

        this.Roles = [];
        this.Roles.push({ label: 'select a Role', value: '' });
        this.subscriptions.push(this.userService.getRoles().subscribe((result:any) => {
            let RoleId = 2; // +this.userForm.get('RoleId').value;
            this.roleFilter.push(RoleId);
            this.CRole = result.filter((x: any) => (this.roleFilter.indexOf(x.Id) > -1));
            this.CRole.forEach((Role: any) => {
                this.Roles.push({ label: Role.Name, value: Role.Id });

            });
        }));
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.Status = [];
            this.Status.push(
                { label: val.SELECT, value: '' },
                { label: val.INACTIVE, value: '0' },
                { label: val.ACTIVE, value: '01' }
            );
        }));
    }

    /** */
    ngOnChanges(changes: SimpleChanges): void {
        let isPortalManager = <FormArray>this.userForm.controls['IsPortalManager'];
        this.disabledIsPortalManager = isPortalManager.value === true ? true : false;
        if (+BaseServices.roleId > 1) {
            this.disabledIsPortalManager = true;
        } else {
            if (+<FormArray>this.userForm.controls['length'].value === 0) {
                this.userForm.get('IsPortalManager')!.patchValue(true);
                this.disabledIsPortalManager = true;
            }
        }
        if (this.portalInitialRightsData !== null) {
            const userEditorControl = <FormArray>this.userForm.controls['portalUserRights'];
            userEditorControl.reset([]);
            for (let k = 0; k < this.portalInitialRightsData.length; k++) {
                let fk = new FormGroup({});
                fk.addControl(`${this.portalInitialRightsData[k].id}`,
                    new FormControl({
                        value: (this.portalUserRightsData.length > 0) ?
                            this.arrayCompare(this.portalUserRightsData, this.portalInitialRightsData[k].id,
                                this.portalInitialRightsData[k]) :
                            this.portalInitialRightsData[k].isChecked
                    })
                )
                userEditorControl.push(fk);
            }
            if (this.userForm.value.UserId != null && BaseServices.UserId === this.userForm.value.UserId) {
                if (+BaseServices.roleId === 2) {
                    userEditorControl['controls'].forEach(rightControl => {
                        if (+Object.keys(rightControl.value)[0] === Rights.APPROVE_ABSENSE
                            || +Object.keys(rightControl.value)[0] === Rights.APPROVE_LEAVE
                            || +Object.keys(rightControl.value)[0] === Rights.APPROVE_TIMETABLE) {
                            rightControl.enable();
                        } else {
                            rightControl.disable();
                        }
                    });

                } else {
                    userEditorControl['controls'].forEach(rightControl => {
                        rightControl.disable();
                    });
                }

            } 
            else {
                let isPMChecked = false;
                userEditorControl['controls'].forEach(rightControl => {
                    if (+<FormArray>this.userForm.controls['length'].value === 0 &&
                        (+Object.keys(rightControl.value)[0] === Rights.PORTAL_MANAGER
                            || +Object.keys(rightControl.value)[0] === Rights.EDIT_MANUALS)) {
                        rightControl.disable();
                    } else {
                        if (+Object.keys(rightControl.value)[0] === Rights.PORTAL_MANAGER) {
                            if (rightControl.value[Rights.PORTAL_MANAGER].value === true) {
                                isPMChecked = true;
                                rightControl.disable();
                            }
                            if (+BaseServices.roleId > 1) {
                                rightControl.disable();
                            }
                        } else if (+Object.keys(rightControl.value)[0] === Rights.EDIT_MANUALS) {
                            if (isPMChecked === true) {
                                rightControl.disable();
                            }
                        } else {
                            rightControl.enable();
                        }
                    }
                });
            }

        }
    }
    get userFormGroups () {
        return this.userForm.get('IsPortalManager') as FormArray
      }
    get portaluserFormGroups () {
        return this.userForm.get('portalUserRights') as FormArray
      }


    ngDoCheck() {
        if (this.portalUSerId > 0) {
            this.isExistingUser = true;
        } else { this.isExistingUser = false; }

        if (+BaseServices.roleId === 2 && BaseServices.PortalManager === false) {
            this.isExistingUser = true;
        }
    }


    /**
     * To array compare
     * @param list {any}
     * @param key {number}
     */
    arrayCompare(list: any, key: number, data:any) {
        let isMatched = false;
        if (list && list.length > 0) {
            for (let index = 0; index < list.length; index++) {
                let element = list[index];
                if (element.id === key || element.id.toString() === key) {
                    if ((element.name === 'PORTAL_MANAGER' || element.name === 'EDIT_MANUALS')
                        && +<FormArray>this.userForm.controls['length'].value === 0) {
                        data.isChecked = true;
                        return element.isChecked = true;
                    } else if (element.name === 'EDIT_MANUALS') {
                        let isChecked = list.filter((x:any) => +x.id === Rights.PORTAL_MANAGER)[0].isChecked;
                        if (isChecked === true) {
                            data.isChecked = true;
                            return element.isChecked = true;
                        }
                    }
                    data.isChecked = element.isChecked;
                    return element.isChecked;
                }
            }
        }
        return isMatched;
    }

    /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
    onNumberChange(e: any, limitNumber: any) {
        HelperService.numberFieldValidation(e, limitNumber);
    }

    /**
     * Enable/Disable the rights on checkbox
     * @param e {event}
     * @param rightsId {number}
     */
    onRightsCheckboxChange(e: any, rightsId: any) {
        let obj = {
            id: rightsId,
            status: e.target.checked
        }
        if (+rightsId === 1) {
            const userControl = <FormArray>this.userForm.controls['portalUserRights'];
            userControl['controls'].forEach(rightControl => {
                rightControl.enable();
                if (+Object.keys(rightControl.value)[0] !== 1) {
                    if (!e.target.checked) {
                        rightControl.disable();
                    } else {
                        if (+<FormArray>this.userForm.controls['length'].value === 0
                            && (+Object.keys(rightControl.value)[0] === Rights.PORTAL_MANAGER
                                || +Object.keys(rightControl.value)[0] === Rights.EDIT_MANUALS)) {
                            rightControl.disable();
                        } else if (+Object.keys(rightControl.value)[0] === Rights.EDIT_MANUALS) {
                            if (userControl.value[+Object.keys(Rights.PORTAL_MANAGER)][1] === true) {
                                rightControl.disable();
                            }
                        } else if (+Object.keys(rightControl.value)[0] === Rights.PORTAL_MANAGER) {
                            if (this.portalUserRightsData.filter((x:any) => +x.id === Rights.PORTAL_MANAGER)[0].isChecked) {
                                rightControl.disable();
                            } else {
                                rightControl.enable();
                            }

                        } else {
                            rightControl.enable();
                        }

                    }
                }
            });
        } else if (+rightsId === Rights.PORTAL_MANAGER) {
            const userControl = <FormArray>this.userForm.controls['portalUserRights'];
            userControl['controls'].forEach(rightControl => {
                if (+Object.keys(rightControl.value)[0] === Rights.EDIT_MANUALS) {
                    if (e.target.checked === true) {
                        rightControl.value[3] = true
                        rightControl.disable();
                    } else {
                        rightControl.enable();
                    }
                }
            });
        }

        this.setPortalUserRights.emit(obj);
    }

    /**
   * Binding country code dropdown
   */
    bindCountryCode() {
        this.mobileCodes = [];
        let code = this.activatedRoute.snapshot.data['countrycodedropdown'];
        if (code) {
            code.forEach((countryCode: any) => {
                this.mobileCodes.push({
                    label: '+' + countryCode.Phonecode,
                    value: '+' + countryCode.Phonecode
                });
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
