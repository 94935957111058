<div class="main-content">

    <div class="panel-tabs" *ngIf="removeTab === false ;else other">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./details']" [class.disabled]="isNewPortal">
                        <span translate>PORTAL_DETAILS</span>
                    </a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./user']" [class.disabled]="isNewPortal">
                        <span translate>USERS</span>
                    </a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./features']" [class.disabled]="isNewPortal">
                        <span translate>FEATURES</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <ng-template #other>
        <div class="panel-tabs">
            <nav>
                <ul class="nav nav-pills">
                    <li [routerLinkActive]="['active']">
                        <a [routerLink]="['./user']" [class.disabled]="isNewPortal">
                            <span translate>MY_USER</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </ng-template>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>