<div class="tab-content">
  <div class="page-title">
    <h4 translate>ACTIVITIES</h4>

    <p-dialog
      *ngIf="!isPortal"
      header="{{ 'NEW_ACTIVITY' | translate }}"
      [(visible)]="displayDialog"
      [style]="{ width: '350px' }"
      [resizable]="false"
      [draggable]="false"
      showEffect="fade"
      [modal]="true"
      (onHide)="onCloseEvent()"
    >
      <form [formGroup]="newActivityForm">
        <div class="form">
          <!-- <div class="form-group">
            <label for="status">
              <span translate>STATUS</span>
            </label>
            <div>
              <input pInputText readonly formControlName="Status" />
            </div>
          </div> -->
          <div class="form-group" *ngIf="newActivityForm.controls['Title']">
            <label for="title">
              <span translate>TITLE</span>
              <span class="required">*</span>
            </label>
            <div>
              <input pInputText formControlName="Title" />
            </div>
            <control-messages
              [control]="newActivityForm.controls['Title']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="description">
              <span translate>DESCRIPTION</span>
            </label>
            <div>
              <textarea pInputText formControlName="Description"></textarea>
            </div>
          </div>
        </div>
      </form>
      <div class="action-btns-wrapper">
        <button
          type="submit"
          class="btn btn-primary"
          icon="pi-check"
          label="Save"
          (click)="onSubmitTemplateBased(newActivityForm.value)"
          [disabled]="!newActivityForm.valid || saveButtonClicked"
        >
          <span translate>SAVE</span>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          icon="pi-times"
          (click)="cancel()"
        >
          <span translate>CANCEL</span>
        </button>
      </div>
    </p-dialog>

    <p-dialog
      *ngIf="isPortal"
      header="{{ 'NEW_ACTIVITY' | translate }}"
      [(visible)]="displayDialog"
      [responsive]="true"
      showEffect="fade"
      [modal]="true"
      [draggable]="false"
      (onHide)="onCloseEvent()"
      [style]="{ width: '550px' }"
    >
      <form [formGroup]="newActivityForm">
        <div class="form">
          <div class="col-sm-12">
            <div>
              <div class="form-group">
                <label for="status">
                  <span translate>STATUS</span>
                </label>
                <div>
                  <input pInputText readonly formControlName="Status" />
                </div>
              </div>
              <div class="form-group" *ngIf="newActivityForm.controls['Title']">
                <label for="title">
                  <span translate>TITLE</span>
                  <span class="required">*</span>
                </label>
                <div>
                  <input pInputText formControlName="Title" />
                </div>
                <control-messages
                  [control]="newActivityForm.controls['Title']"
                ></control-messages>
              </div>
              <div class="form-group">
                <label for="description">
                  <span translate>DESCRIPTION</span>
                </label>
                <div>
                  <textarea
                    pInputText
                    formControlName="Description"
                    style="height: 170px; width: 100%"
                  ></textarea>
                </div>
              </div>
            </div>
            <div *ngIf="isPortalAccess">
              <div>
                <span translate>SELECT_BUSINESS</span>
                <!-- <span class="required">*</span> -->
              </div>
              <div class="table-view">
                <p-table
                  #dt
                  [value]="businesses"
                  [responsive]="true"
                  [(selection)]="selectedBusiness"
                  dataKey="value"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 55px">
                        <p-tableHeaderCheckbox
                          id="selectedBusiness"
                          (click)="BusinessCount()"
                        ></p-tableHeaderCheckbox>
                      </th>
                      <th pSortableColumn="label" style="width: 250px">
                        {{ "NAME" | translate
                        }}<p-sortIcon field="label"></p-sortIcon>
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>
                        <input
                          pInputText
                          type="text"
                          (input)="
                            dt.filter($event.target.value, 'label', 'contains')
                          "
                          placeholder=""
                          class="p-column-filter"
                        />
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-business>
                    <tr>
                      <td style="width: 55px">
                        <p-tableCheckbox
                          [value]="business"
                          (click)="BusinessCount()"
                        >
                        </p-tableCheckbox>
                      </td>
                      <td>
                        <span>{{ business.label }}</span>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </form>
      <br />
      <div class="" style="margin-left: 29px">
        <button
          type="submit"
          class="btn btn-primary"
          icon="pi-check"
          label="Save"
          *ngIf="isPortalAccess"
          (click)="savePortalActivity()"
          [disabled]="!newActivityForm.valid || saveButtonClicked || !showSave"
        >
          <span translate>SAVE</span>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          icon="fa-close"
          (click)="cancel()"
        >
          <span translate>CANCEL</span>
        </button>
      </div>
    </p-dialog>
  </div>
  <app-activity-list-chart
    [activities]="activitylist"
  ></app-activity-list-chart>
  <span
    class="page-actions d-flex justify-content-end"
    *ngIf="isNewButtonEnable"
  >
    <button type="submit" class="btn btn-success" (click)="showDialogToAdd()">
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </button>
  </span>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #activityTable
      [value]="activitylist"
      [rows]="10"
      [paginator]="enablePaginator"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="ActivityList"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <div class="row">
              <div class="col-sm-3" *ngIf="isShowActivity">
                <div class="form-group">
                  <label for="Year" class="col-form-label">
                    <span translate>SHOW_ACTIVITY</span>
                  </label>
                  <div>
                    <p-dropdown
                      type="text"
                      [options]="showActivities"
                      [disabled]="isDisableShowActivity"
                      (onChange)="onShowActivityChanged($event, sa)"
                      #sa
                      [style]="{ width: '160px' }"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="Month" class="col-form-label">
                    <span translate>DONE_ON</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="doneOn"
                      (onChange)="onDoneOnChanged($event, d)"
                      #d
                      [style]="{ width: '160px' }"
                      [(ngModel)]="doneOnFilter"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="Year" class="col-form-label">
                    <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                  </label>
                  <div>
                    <p-dropdown
                      type="text"
                      [options]="follows"
                      [(ngModel)]="responsibleFollowUpFilter"
                      (onChange)="onFollowupChanged($event, followUpDropdown)"
                      #followUpDropdown
                      [style]="{ width: '160px' }"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="Year" class="col-form-label">
                    <span translate>REPETITION</span>
                  </label>
                  <div>
                    <p-dropdown
                      type="text"
                      [options]="repetitions"
                      [filter]="true"
                      [(ngModel)]="repetitionFilter"
                      (onChange)="onRepeatChanged($event, r)"
                      #r
                      [style]="{ width: '160px' }"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3" *ngIf="isBusiness">
                <div class="form-group">
                  <label for="Year" class="col-form-label">
                    <span translate>CHOOSE_BUSINESS</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="businesses"
                      type="text"
                      (onChange)="onBusinessChanged($event, cb)"
                      #cb
                      [filter]="true"
                      [style]="{ width: '160px' }"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="Fdate">
                    <span translate>DEADLINE_FROM_DATE</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="startdate"
                      [options]="startDateOptions"
                      placeholder="{{ 'SELECT_START_DATE' | translate }}"
                      [(ngModel)]="fromDateFilter"
                      (dateChanged)="onStartDateChanged($event)"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="Edate">
                    <span translate readonlyInput="true"
                      >DEADLINE_END_DATE</span
                    >
                  </label>
                  <div>
                    <my-date-picker
                      name="enddate"
                      [options]="endDateOptions"
                      placeholder="{{ 'SELECT_END_DATE' | translate }}"
                      [(ngModel)]="endDateFilter"
                      (dateChanged)="onEndDateChanged($event)"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>
                    <span translate>DESCRIPTION</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      pInputText
                      #cf
                      (keyup)="onDescriptionChanged(cf)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-options">
            <div class="pull-right">
              <button class="btn p-3 filter-toggle">
                <span class="icon ic-sm icon-filter"></span>
                <span translate>FILTER</span>
              </button>
              <div class="action-btns-group">
                <kuba-export
                  [printMode]="1"
                  [reportData]="
                    activityTable.filteredValue || activityTable.value
                  "
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th width="35px"></th>
          <th pSortableColumn="No" width="110px">
            {{ "NUMBER_NO" | translate }}
          </th>
          <th pSortableColumn="BusinessName" width="180" *ngIf="!isAdmin">
            {{ "BUSINESS" | translate }}
          </th>
          <th pSortableColumn="Title">
            {{ "TITLE" | translate }}
          </th>

          <th width="85px">{{ "STATUS" | translate }}</th>
          <th>{{ "DEPARTMENT" | translate }}</th>
          <th>{{ "RESPONSIBLE_PERSON" | translate }}</th>
          <th pSortableColumn="FollowupUserList" *ngIf="isNotAdmin">
            {{ "FOLLOWUP_PERSON" | translate }}
          </th>
          <th pSortableColumn="EndDateForPdf" width="105">
            {{ "DEADLINE" | translate }}
          </th>

          <th pSortableColumn="CreatedOnForPdf" width="105">
            {{ "CREATED_ON" | translate }}
          </th>
          <th pSortableColumn="CompletedDate" width="105" *ngIf="isNotAdmin">
            {{ "DONE_ON" | translate }}
          </th>
          <th width="40px" *ngIf="isDisableDeleteIcon">
            <!-- {{ "ACTIONS" | translate }} -->
          </th>
          <!-- <th></th> -->
        </tr>
        <tr>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                activityTable.filter(
                  $any($event.target)?.value,
                  'No',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th *ngIf="!isAdmin">
            <input
              pInputText
              type="text"
              (input)="
                activityTable.filter(
                  $event.target.value,
                  'BusinessName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                activityTable.filter(
                  $any($event.target)?.value,
                  'Title',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>

          <th>
            <p-dropdown
              width="118"
              [options]="statuses"
              #status
              (onChange)="onStatusChanged($event, status)"
              styleClass="p-column-filter"
              placeholder=""
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              width="118"
              [options]="vDepartment"
              #department
              (onChange)="onDepartmentChange($event, department)"
              styleClass="p-column-filter"
              placeholder=""
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-department pTemplate="item">
                {{ department.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              width="118"
              [options]="vresperson"
              #responsible
              (onChange)="onResponsiblePersonChange($event, responsible)"
              styleClass="p-column-filter"
              placeholder=""
              [showClear]="true"
              appendTo="body"
            >
              <ng-template let-responsible pTemplate="item">
                {{ responsible.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th *ngIf="isNotAdmin"></th>
          <th></th>

          <th></th>
          <th *ngIf="isNotAdmin"></th>
          <th *ngIf="isDisableDeleteIcon"></th>
          <!-- <th></th> -->
        </tr>
      </ng-template>

      <!-- Body Content -->
      <ng-template pTemplate="body" let-activity>
        <tr>
          <td class="status-icon">
            <i
              *ngIf="activity.StatusId == 10"
              class="{{ activity.StatusData.StatusIcon }}"
              style="color: red"
            ></i>
            <i
              *ngIf="activity.StatusId != 10"
              class="{{ activity.StatusData.StatusIcon }}"
            ></i>
          </td>
          <td>
            <span
              pTooltip="{{ activity.No }}"
              tooltipPosition="top"
              translate
              >{{ activity.No }}</span
            >
          </td>
          <td *ngIf="!isAdmin">
            <span
              translate
              pTooltip="{{ activity.BusinessName }}"
              tooltipPosition="right"
              class="text-overflow"
              >{{ activity.BusinessName }}</span
            >
          </td>
          <td *ngIf="isEditor">
            <a
              [routerLink]="['./edit', activity.Id]"
              [class.disabled]="activity.RoleId === '1'"
              pTooltip="{{ activity.Title }}"
              tooltipPosition="right"
              >{{ activity.Title }}</a
            >
          </td>
          <td *ngIf="!isEditor">
            <a
              *ngIf="activity.Responsible === true"
              [routerLink]="['./edit', activity.Id]"
              pTooltip="{{ activity.Title }}"
              tooltipPosition="right"
            >
              {{ activity.Title }}
            </a>
            <span
              *ngIf="activity.Responsible !== true"
              pTooltip="{{ activity.Title }}"
              tooltipPosition="right"
            >
              {{ activity.Title }}
            </span>
          </td>

          <td>
            <span translate>{{ activity.Status }}</span>
          </td>
          <td>
            <span [innerHTML]="activity.Department"></span>
          </td>
          <td>
            <span translate>{{ activity.ExecutionUserList }}</span>
          </td>
          <td *ngIf="isNotAdmin">
            <span translate>{{ activity.FollowupUserList }}</span>
          </td>
          <td>
            <span translate *ngIf="activity.Status !== 'NEW'">{{
              activity.EndDateForPdf
            }}</span>
          </td>

          <td>
            <span translate>{{ activity.CreatedOnForPdf }}</span>
          </td>
          <td *ngIf="isNotAdmin">
            <span translate>{{
              activity.CompletedDate | date : "dd/MM/yyyy"
            }}</span>
          </td>
          <td class="col-button" *ngIf="isDisableDeleteIcon">
            <button
              type="button"
              *ngIf="activity.StatusId !== 9 && activity.RepetitionId <= 1"
              class="btn btn-icon"
              (click)="confirm(activity.Id)"
              title="{{ 'DELETE' | translate }}"
              [disabled]="
                activity.RoleId === 'Portal' && activity.StatusId === 9
              "
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
            <button
              type="button"
              *ngIf="
                activity.StatusId !== 9 &&
                activity.RepetitionId > 1 &&
                roleId === '3'
              "
              class="btn btn-icon"
              (click)="showDialogForStopFutureRecurring(activity)"
            >
              <i class="icon ic-sm icon-032"></i>
            </button>
          </td>
        </tr>
      </ng-template>

      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix">
          <div class="footer-data">
            <div class="symbol-descriptions">
              <div class="symbol-desc-title">
                <span translate>DESCRIPTION_SYMBOLS</span>
              </div>
              <div class="symbol-desc-items">
                <span class="new-deadline">
                  <i class="icon ic-md icon-new-deadline"></i>
                  <span translate>NEW_ACTIVITY</span>
                </span>
                <span class="far-deadline">
                  <i class="icon ic-md icon-far-deadline"></i>
                  <span translate>TIME_REMAINING</span>
                </span>
                <span class="over-deadline">
                  <i class="icon ic-md icon-over-deadline"></i>
                  <span translate>OVERDEADLINE</span>
                </span>
                <span class="near-deadline">
                  <i class="icon ic-md icon-near-deadline"></i>
                  <span translate>PERIOD</span>
                </span>
                <span class="clear-deadline">
                  <i class="icon ic-md icon-clear-deadline"></i>
                  <span translate>DONE_STATUS</span>
                </span>
                <span class="clear-deadline" style="color: red">
                  <i class="icon ic-md icon-cancel"></i>
                  <span translate>REJECTED</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-exclamation-triangle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <p-dialog
    header="{{ 'CONFIRMATION' | translate }}"
    [(visible)]="displayDialogForRecurring"
    [style]="{ width: '350px' }"
    [resizable]="false"
    [draggable]="false"
    showEffect="fade"
    [modal]="true"
    [closable]="true"
  >
    <div class="confirmation-content">
      <div class="pi pi-exclamation-triangle">
        <span style="font-family: 'Arial', sans-serif">
          {{ "STOP_DELETE_FUTURE_RECURRING_ACTIVITIES" | translate }}</span
        >
      </div>
    </div>
    <div class="action-btns-wrapper">
      <button
        type="submit"
        class="btn btn-outline-primary"
        [disabled]="IsFutureRecurring"
        label="Save"
        (click)="onClickStopFutureRecurring()"
      >
        <span translate>STOP_FUTURE_RECURRING</span>
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="onClickDeleteRecurringActivity()"
      >
        <span translate>DELETE</span>
      </button>
    </div>
  </p-dialog>
</div>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
