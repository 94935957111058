<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <!-- Table starts -->
      <p-table [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" #DescriptionTable
        [value]="contractContentList">
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="Name">{{'ADDED_SERVICES' | translate}} <p-sortIcon field="Name"></p-sortIcon></th>
            <th>{{'OPTIONS'| translate}}</th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-task>
          <tr>
            <td>
              <div pTooltip="{{task.Name}}" tooltipPosition="right">{{task.Name}}</div>
            </td>
            <td class="col-button">
              <a (click)="initForm(task)" class="btn btn-icon">
                <i class="icon ic-sm icon-pencil-tip" pTooltip="{{'EDIT' | translate}}" tooltipPosition="top"></i>
              </a>
              <a (click)="deleteContent(task['Id'])" class="btn btn-icon">
                <i class="icon ic-sm icon-trash" pTooltip="{{'DELETE' | translate}}" tooltipPosition="top"></i>
              </a>
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="2">
              {{'NO_RECORDS_FOUND'|translate}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table Ends -->
      <div class="action-btns-wrapper">
        <button type="button" (click)="initForm()" class="btn btn-success pull-right">
          <span translate>ADD_NEW</span>
        </button>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<p-dialog header="{{('ADD_NEW') | translate}}" *ngIf="showDescDialog" [(visible)]="showDescDialog" [modal]="true"
[style]="{width: '500px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'visible'}">
  <form [formGroup]="addServiceForm">
    <div class="form-group">
      <label for="description" translate>DESCRIPTION
      </label>
      <input type="text" formControlName="description" pInputText />
      <span class="required">*</span>
      <control-messages [control]="addServiceForm.controls['description']"></control-messages>
    </div>
    <div class="action-btns-wrapper">
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button type="button" class="btn btn-outline-secondary" (click)="clearDescription()" translate>CLEAR</button>
        <button type="button" class="btn btn-outline-secondary" (click)="showDescDialog = false" translate>CLOSE</button>
        <button type="Submit" class="btn btn-primary" [disabled]="!addServiceForm.valid"
          (click)="saveDescription(addServiceForm.value)" translate>SAVE</button>
      </div>
    </div>
  </form>
</p-dialog>
<toaster-component></toaster-component>