export class ElectroControlScope {
    Id: number;
    ElectroContractId: number;
    DocumentNumber: string;
    Version: number;
    ControlItems: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    ElectroInstallationBuliding: ElectroInstallationBuliding[];
}
export class ElectroDocumentAssessment {
    Id: number;
    ElectroContractId: number;
    InspectionId: number;
    DocumentNumber: string;
    BuildingId: number;
    Version: number;
    DocumentReview: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    DocumentAssessmentId: number;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    SubTaskId: number;
}

export class ElectroInstallationBuliding {
    Id: number;
    ElectroContractId: number;
    ControlScopeId: number;
    BusinessId: number;
    Status: number;
    BuildingNumber: string;
    InstallationTypeId: string;
    GridSystemId: string;
    InstallationBuildAfterId: string;
    RiskGroup: number;
    UsedFor: string;
    YearBuilt: number;
    YearUpgraded: number;
    Floors: string;
    Rooms: number;
}

export class ElectroDistributionOverview {
    Id: number;
    ElectroContractId: number;
    InspectionId: number;
    BuildingId: number;
    DocumentNumber: string;
    Version: number;
    ControlItems: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    ElectroDistributionFloor: ElectroDistributionFloor[];
    SubTaskId: number;
}
export class ElectroFireAlarmSystem {
    Id: number;
    DocumentNumber: string;
    Version: number;
    ElectroContractId: number;
    InspectionId: number;
    BuildingId: number;
    SystemContent: string;
    OtherEquipment: string;
    OtherEquipmentStatus: string;
    IsEquipmentUpdate:boolean;
    Controls: string;
    Connection: string;
    Type: string;
    InstalledYear: string;
    Supplier: string;
    LoopAddress: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    SubTaskId: number;
}
export class ElectroDistributionFloor {
    Id: number;
    OrderId: number;
    DistributionId: number;
    RoomNo: number;
    Floor: string;
    MeasureNumber: number;
    DistributionType: number;
    ElectroContractId: number;
    Status: number;
}
export class ElectroCourseOverview {
    Id: number;
    ElectroContractId: number;
    InspectionId: number;
    BuildingId: number;
    DocumentNumber: string;
    Version: number;
    DistributionId: number;
    ProvidedFrom: string
    IK_Max: number;
    IK_Max_Type: number;
    IK_Min: number;
    IK_Min_Type: number;
    IJ_Max: number;
    IJ_Max_Type: number;
    IJ_Min: number;
    IJ_Min_Type: number;
    SystemVoltage: number;
    SystemFrequency: number;
    PowerCableSurface: number;
    PowerCableMaterial: number;
    DistributionSystem: number;
    Protection: string;
    EarthType: number;
    EarthConnectionSpot: string;
    CourseOverviewDetails: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    SubTaskId: number;
}

export class ElectroEmergencyLightSystem {
    Id: number;
    ElectroContractId: number;
    InspectionId: number;
    BuildingId: number;
    DocumentNumber: string;
    Version: number;
    Supplier: string;
    InstalledYear: string;
    OtherEquipment: string;
    OtherEquipmentStatus: string;
    IsEquipmentUpdate:boolean;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    SubTaskId: number;
}

export class ElectroFireSprinklerSystem {
    Id: number;
    ElectroContractId: number;
    InspectionId: number;
    BuildingId: number;
    DocumentNumber: string;
    Version: number;
    Type: string;
    InstalledYear: string;
    Supplier: string;
    FGApproverId: string;
    Area: number;
    RiskGroup: string;
    SystemContent: string;
    Connection: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    SubTaskId: number;
}

export class ElectroRiskAssessment {
    Id: number;
    BusinessId: number;
    ElectroContractId: number;
    InspectionId: number;
    BuildingId: number;
    DocumentNumber: string;
    Version: number;
    VoltageSystemIT: boolean;
    VoltageSystemTNCS: boolean;
    VoltageSystemTNS: boolean;
    VoltageSystemTT: boolean;
    DistributionDataAvail: number;
    IK_Max: number;
    IK_Min: number;
    EarthType: number;
    OtherEarthType: string;
    EarthMonitorType1: boolean;
    EarthMonitorType2: boolean;
    EarthMonitorType3: boolean;
    OverVoltageProtection: number;
    OverVoltageComment: string;
    BoardFuseType: number;
    OtherMatters: string;
    Summary: string;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    CompletedDate: string;
    SignatureImageData: string;
    CompletionTitle:string;
    SubTaskId: number;
}

export class ElectroOrgChartData
{
    Id: number;
    BusinessId: number;
    ApplicationId: number;
    ElectroContractId: number;
    DocumentNumber: string;
    Version: number;
    ChartXmlData : string;
    ChartSvgData : string;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    EditorName: string;
    Chapter: number;
    ArticleEditedDate: any;
}

export class ElectroFormTemplates{
    Id: number;
    ContractId: number;
    FormType: number;
    FormTemplate: string;
    Status: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
}