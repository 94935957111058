<div class="tab-content">
    <div class="main-content">
        <div class="main-heading">
            <strong>
                <span translate>PROJECTS</span>
            </strong>
            <div class="page-actions text-right">
                <a [routerLink]="['./../newproject']" class="btn btn-success">
                    <span class="icon ic-sm icon-add"></span>
                    <span translate>NEW</span>
                </a>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #dt [value]="projectList" [rows]="10" [paginator]="true" [pageLinks]="3"
                      [rowsPerPageOptions]="[5,10,20]"
                      [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="Name">{{'PROJ_NAME' | translate}}</th>
                                <th>{{'CREATED_ON' | translate}}</th>
                                <th>{{'LAST_EDITED' | translate}}</th>
                                <th>{{'EDITED_BY' | translate}}</th>
                                <th>{{'RENAME' | translate}}</th>
                                <th>{{'OPTIONS'| translate}}</th>
                            </tr>
                            <tr>
                                <th>
                                    <input pInputText type="text"
                                      (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                      class="p-column-filter">
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-projects>
                            <tr>
                                <td>
                                    <span>{{projects.Name}}</span>
                                </td>
                                <td>
                                    <span>{{projects.CreatedOn | date : 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span>{{projects.ModifiedOn | date : 'dd/MM/yyyy'}}</span>
                                </td>
                                <td>
                                    <span>{{projects.ModifierName}}</span>
                                </td>
                                <td>
                                    <a [routerLinkActive]="['active']" href="javascript:void(0);"
                                      (click)="onRenameClicked(projects)">
                                        <span translate>RENAME</span>
                                    </a>
                                </td>
                                <td class="col-button">
                                    <button type="button" [routerLink]="['../details',projects.Id]" class="btn btn-icon"
                                      title="{{'EDIT' | translate}}">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" (click)="deleteProject(projects.Id)" class="btn btn-icon"
                                      title="{{'DELETE' | translate}}">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table ends -->
                </div>
            </div>
        </div>
    </div>

    <p-dialog header="{{'Rename Project' | translate}}" [(visible)]="isRename" [modal]="true" 
    [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
        <form [formGroup]="renameForm">
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>ENTER_PROJ_NAME</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="ProjectName" [style]="{'width':'100%'}" />
                </div>
                <control-messages [control]="renameForm.controls['ProjectName']"></control-messages>
            </div>
            <p-footer>
                <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                    <button class="btn btn-primary" [disabled]="!renameForm.valid" (click)="saveProjectName()">
                        <span translate>SAVE</span>
                    </button>
                </div>
            </p-footer>
        </form>
    </p-dialog>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>