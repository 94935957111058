//#region import
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService, PdfSetting } from './../../../../_services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Options } from './../../../../_models/options';
import { BaseServices } from './../../../kuba.services';
import {
    VisualControlData, VisualControl,
    Resistance, Continuity,
    Insulation, Voltage,
    Power, Effect,
    Reactive, Short
} from '../../models/visual-control.model';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-visual-contol',
    templateUrl: 'measure-protocol-visual-control.component.html'
})

export class MeasureProtocolVisualControlComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('res',{static: false}) res : ElementRef;
    kundeELId: number;
    parentKey: any;
    cmpId: number;
    visualControlData = new VisualControlData();
    VisualControl: VisualControl;
    measureProtocolResistance: FormGroup;
    measureProtocolContinuity: FormGroup;
    measureProtocolInsulation: FormGroup;
    measureProtocolVoltage: FormGroup;
    measureProtocolPower: FormGroup;
    measureProtocolEffectPower: FormGroup;
    measureProtocolEffectReactive: FormGroup;
    measureProtocolEffectShortCircuit: FormGroup;

    res_Id: number;
    featureId: number;
    createdOn: string;
    createdBy: number;
    isElectro = false;

    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        public measureprotocolservice: MeasureProtocolService) {

        this.measureProtocolResistance = this.fb.group({
            'MeasureValue': [''],
            'Method': [''],
        });

        this.measureProtocolContinuity = this.fb.group({
            'HighestValue': [''],
            'Description': [''],
        });

        this.measureProtocolInsulation = this.fb.group({
            'L1_PE': [''],
            'L2_PE': [''],
            'L3_PE': [''],
            'N_PE': [''],
        });

        this.measureProtocolVoltage = this.fb.group({
            'L1_L2': [''],
            'L1_L3': [''],
            'L2_L3': [''],
            'L1_PE': [''],
            'L2_PE': [''],
            'L3_PE': [''],
            'N_L1': [''],
            'N_L2': [''],
            'N_L3': [''],

        });

        this.measureProtocolPower = this.fb.group({
            'L1': [''],
            'L2': [''],
            'L3': [''],
            'N': [''],
            'PE': [''],
        });

        this.measureProtocolEffectPower = this.fb.group({
            'L1': [''],
            'L2': [''],
            'L3': [''],
            'P_tot': [''],
        });

        this.measureProtocolEffectReactive = this.fb.group({
            'S': [''],
            'Cosphi': [''],
        });

        this.measureProtocolEffectShortCircuit = this.fb.group({
            'Polmin': [''],
            'Description1': [''],
            'Polmax': [''],
            'Description2': [''],
        });
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.kundeELId = this.route.snapshot.parent.parent.parent.params['id'];
        this.parentKey = this.route.snapshot.parent.parent.parent.data['parent'];
        this.cmpId = this.route.snapshot.parent.params['mpcid'];
        let res = this.route.snapshot.data['vsData'];
        if (res) {
            this.res_Id = res.Id;
            let visualControlStringifyData = res.Data;
            let visualControlJsonData = JSON.parse(visualControlStringifyData);
            this.initForm(visualControlJsonData);
        } else {
            this.initForm();
        }
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule ? workingModule.id : 0;
        if(this.featureId == FeatureKey.ELECTRO){
            this.isElectro = true;
            this.kundeELId = 0;
            this.parentKey = 'KUNDE_EL';
            this.cmpId = +this.route.snapshot.params['cClId'];
            if(this.cmpId){
                this.subscriptions.push(this.measureprotocolservice.getVisualControl(this.cmpId).subscribe(vsData=>{
                    if(vsData){
                        this.res_Id = vsData.Id;
                        this.createdOn = vsData.CreatedOn;
                        this.createdBy = vsData.CreatedBy;
                        let visualControlStringifyData = vsData.Data;
                        let visualControlJsonData = JSON.parse(visualControlStringifyData);
                        this.initForm(visualControlJsonData);
                    }
                }))
            }
        }
    }

    initForm(visualControlJsonData?: any) {
        let MeasureValue = '';
        let Method = '';
        let HighestValue = '';
        let Description = '';
        let L1_PE = '';
        let L2_PE = '';
        let L3_PE = '';
        let N_PE = '';

        let L1_L2 = '';
        let L1_L3 = '';
        let L2_L3 = '';

        let N_L1 = '';
        let N_L2 = '';
        let N_L3 = '';

        let L1 = '';
        let L2 = '';
        let L3 = '';
        let N = '';
        let PE = '';

        let P_tot = '';
        let S = '';
        let Cosphi = '';

        let Polmin = '';
        let Description1 = '';
        let Polmax = '';
        let Description2 = '';



        if (visualControlJsonData) {
            //Resistance
            MeasureValue = visualControlJsonData.Resistance.MeasureValue;
            Method = visualControlJsonData.Resistance.Method;
            this.measureProtocolResistance = this.fb.group({
                MeasureValue: new FormControl(MeasureValue),
                Method: new FormControl(Method),
            });

            //Continuity
            HighestValue = visualControlJsonData.Continuity.HighestValue;
            Description = visualControlJsonData.Continuity.Description;
            this.measureProtocolContinuity = this.fb.group({
                HighestValue: new FormControl(HighestValue),
                Description: new FormControl(Description),
            });

            //Insulation
            L1_PE = visualControlJsonData.Insulation.L1_PE;
            L2_PE = visualControlJsonData.Insulation.L2_PE;
            L3_PE = visualControlJsonData.Insulation.L3_PE;
            N_PE = visualControlJsonData.Insulation.N_PE;

            this.measureProtocolInsulation = this.fb.group({
                L1_PE: new FormControl(L1_PE),
                L2_PE: new FormControl(L2_PE),
                L3_PE: new FormControl(L3_PE),
                N_PE: new FormControl(N_PE),
            });

            //Voltage
            L1_L2 = visualControlJsonData.Voltage.L1_L2;
            L1_L3 = visualControlJsonData.Voltage.L1_L3;
            L2_L3 = visualControlJsonData.Voltage.L2_L3;
            L1_PE = visualControlJsonData.Voltage.L1_PE;
            L2_PE = visualControlJsonData.Voltage.L2_PE;
            L3_PE = visualControlJsonData.Voltage.L3_PE;
            N_L1 = visualControlJsonData.Voltage.N_L1;
            N_L2 = visualControlJsonData.Voltage.N_L2;
            N_L3 = visualControlJsonData.Voltage.N_L3;

            this.measureProtocolVoltage = this.fb.group({
                L1_L2: new FormControl(L1_L2),
                L1_L3: new FormControl(L1_L3),
                L2_L3: new FormControl(L2_L3),
                L1_PE: new FormControl(L1_PE),
                L2_PE: new FormControl(L2_PE),
                L3_PE: new FormControl(L3_PE),
                N_L1: new FormControl(N_L1),
                N_L2: new FormControl(N_L2),
                N_L3: new FormControl(N_L3),
            });

            //Power
            L1 = visualControlJsonData.Power.L1;
            L2 = visualControlJsonData.Power.L2;
            L3 = visualControlJsonData.Power.L3;
            N = visualControlJsonData.Power.N;
            PE = visualControlJsonData.Power.PE;

            this.measureProtocolPower = this.fb.group({
                L1: new FormControl(L1),
                L2: new FormControl(L2),
                L3: new FormControl(L3),
                N: new FormControl(N),
                PE: new FormControl(PE),
            });

            //Effect
            L1 = visualControlJsonData.Effect.L1;
            L2 = visualControlJsonData.Effect.L2;
            L3 = visualControlJsonData.Effect.L3;
            P_tot = visualControlJsonData.Effect.P_tot;

            this.measureProtocolEffectPower = this.fb.group({
                L1: new FormControl(L1),
                L2: new FormControl(L2),
                L3: new FormControl(L3),
                P_tot: new FormControl(P_tot),
            });

            //Reactive
            S = visualControlJsonData.Reactive.S;
            Cosphi = visualControlJsonData.Reactive.Cosphi;

            this.measureProtocolEffectReactive = this.fb.group({
                S: new FormControl(S),
                Cosphi: new FormControl(Cosphi),
            });

            //Short
            Polmin = visualControlJsonData.Short.Polmin;
            Description1 = visualControlJsonData.Short.Description1;
            Polmax = visualControlJsonData.Short.Polmax;
            Description2 = visualControlJsonData.Short.Description2;

            this.measureProtocolEffectShortCircuit = this.fb.group({
                Polmin: new FormControl(Polmin),
                Description1: new FormControl(Description1),
                Polmax: new FormControl(Polmin),
                Description2: new FormControl(Description2),
            });

        }

    }

    saveVC() {
        let resistance = new Resistance();
        resistance.MeasureValue = this.measureProtocolResistance.value.MeasureValue;
        resistance.Method = this.measureProtocolResistance.value.Method;
        let continuity = new Continuity();
        continuity.HighestValue = this.measureProtocolContinuity.value.HighestValue;
        continuity.Description = this.measureProtocolContinuity.value.Description;
        let insulation = new Insulation();
        insulation.L1_PE = this.measureProtocolInsulation.value.L1_PE;
        insulation.L2_PE = this.measureProtocolInsulation.value.L2_PE;
        insulation.L3_PE = this.measureProtocolInsulation.value.L3_PE;
        insulation.N_PE = this.measureProtocolInsulation.value.N_PE;
        let voltage = new Voltage();
        voltage.L1_L2 = this.measureProtocolVoltage.value.L1_L2;
        voltage.L1_L3 = this.measureProtocolVoltage.value.L1_L3;
        voltage.L2_L3 = this.measureProtocolVoltage.value.L2_L3;
        voltage.L1_PE = this.measureProtocolVoltage.value.L1_PE;
        voltage.L2_PE = this.measureProtocolVoltage.value.L2_PE;
        voltage.L3_PE = this.measureProtocolVoltage.value.L3_PE;
        voltage.N_L1 = this.measureProtocolVoltage.value.N_L1;
        voltage.N_L2 = this.measureProtocolVoltage.value.N_L2;
        voltage.N_L3 = this.measureProtocolVoltage.value.N_L3;
        let power = new Power();
        power.L1 = this.measureProtocolPower.value.L1;
        power.L2 = this.measureProtocolPower.value.L2;
        power.L3 = this.measureProtocolPower.value.L3;
        power.N = this.measureProtocolPower.value.N;
        power.PE = this.measureProtocolPower.value.PE;
        let effect = new Effect();
        effect.L1 = this.measureProtocolEffectPower.value.L1;
        effect.L2 = this.measureProtocolEffectPower.value.L2;
        effect.L3 = this.measureProtocolEffectPower.value.L3;
        effect.P_tot = this.measureProtocolEffectPower.value.P_tot;
        let reactive = new Reactive();
        reactive.S = this.measureProtocolEffectReactive.value.S;
        reactive.Cosphi = this.measureProtocolEffectReactive.value.Cosphi;
        let short = new Short();
        short.Polmin = this.measureProtocolEffectShortCircuit.value.Polmin;
        short.Description1 = this.measureProtocolEffectShortCircuit.value.Description1;
        short.Polmax = this.measureProtocolEffectShortCircuit.value.Polmax;
        short.Description2 = this.measureProtocolEffectShortCircuit.value.Description2;

        let visualControlData = new VisualControlData();
        visualControlData.Resistance = resistance;
        visualControlData.Continuity = continuity;
        visualControlData.Insulation = insulation;
        visualControlData.Voltage = voltage;
        visualControlData.Power = power;
        visualControlData.Effect = effect;
        visualControlData.Reactive = reactive;
        visualControlData.Short = short;

        let data = `${JSON.stringify(visualControlData)}`;

        let visualControl = new VisualControl();
        visualControl.Id = (this.res_Id) ? this.res_Id : 0;
        visualControl.Data = data;
        visualControl.MeasureProtocolCompleteId = this.cmpId;
        visualControl.Status = 1;
        visualControl.CreatedBy = BaseServices.UserId;
        visualControl.CreatedOn = new Date();
        visualControl.ModifiedBy = BaseServices.UserId;
        visualControl.ModifiedOn = new Date();

        if (visualControl.Id > 0) {
            visualControl.CreatedBy = this.createdBy;
            visualControl.CreatedOn = new Date(this.createdOn);
            visualControl.ModifiedBy = BaseServices.UserId;
            visualControl.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .updateVisualControl(visualControl)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                }));
        } else {
            this.subscriptions.push(this.measureprotocolservice
                .createVisualControl(visualControl)
                .subscribe((result: any) => {
                    if (result) {
                        this.res_Id = result.Id;
                        this.createdOn = result.CreatedOn;
                        this.createdBy = result.CreatedBy;
                        this.toasterComponent.callToast();
                    }
                }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

