import { ContactTypeResolver } from './../contact/services/contact.resolver.service';
import {
    ProjectEditResolver, UserListResolver, ClientUserListResolver, ResponsibiltyListResolver, SubContractorListResolver
} from './../projects/services/project.resolver';
import { ProjectEditComponent } from './../projects/components/project-edit.component';
import {
    ClientListResolver, ClientEditResolver, ClientContactListResolver,
    ModuleListResolver,
     ClientListByBusinessResolver,
    CountryCodeResolver
} from './services/client.resolver.service';
import { ClientComponent } from './client.component';
import { ClientListComponent } from './components/client-list/client-list.component';
import { CreateClientComponent } from './components/new-client/new-client.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

export const clientRoutes: Route[] = [
    {
        path: 'client',
        component: ClientComponent,
        children: [
            {
                path: 'list',
                component: ClientListComponent,
                resolve: {
                    list: ClientListByBusinessResolver
                }
            },
            {
                path: 'new',
                component: CreateClientComponent,
                resolve: {
                    contactlist: ClientContactListResolver,
                    contactType: ContactTypeResolver,
                    countryCode: CountryCodeResolver

                }
            },
            {
                path: 'edit/:cid',
                component: CreateClientComponent,
                resolve: {
                    edit: ClientEditResolver,
                    contactlist: ClientContactListResolver,
                    module: ModuleListResolver,
                    contactType: ContactTypeResolver,
                    countryCode: CountryCodeResolver
                }
            },
        ]
    }

];