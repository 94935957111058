export class NotificationList {
    Id: number;
    Title: String;
    Description: string;
    Priority: number;
    CreatedBy: string;
    ModifiedBy: string;
    CreatedOn: string;
    ModifiedOn: string;
    StatusText: string;
    NotificationUpdateOn: string;
    Status: number;
    FileType: number;
    ProgressPercentage: number;
    JsonFdvBulkReport = [];
    DownloadExpired : number;
    UserId : number;
    Name: string;
    PublishedOn: Date;

}
