<div class="main-content">
    <div class="main-heading">
        <span translate>NEW_QUALITY_SYSTEM</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="InternalControlForm" (ngSubmit)="onSubmitTemplateBased(InternalControlForm.value)">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>
                                <span translate>TITLE </span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="text" formControlName="Title" name="Title" pInputText />
                            </div>
                            <control-messages [control]="InternalControlForm.controls['Title']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>DESCRIPTION</span>
                            </label>
                            <div>
                                <textarea formControlName="Description" pInputTextarea></textarea>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                        <p-checkbox label="{{'INTERNAL_CONTROL_EDITOR' | translate}}" formControlName="EditorOnly"
                          binary="true"></p-checkbox>
                    </div> -->
                        <div class="form-group">
                            <p-checkbox label="{{'DO_NOT_DISPLAY_DEVIATION'|translate}}"
                              formControlName="IsShowDeviationChecked" binary="true"></p-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="sub-panel-title">
                                <span translate>ASSIGN_ACCESS</span>
                            </div>
                            <!-- Table starts -->
                            <p-table #dt [value]="interanlUsers" dataKey="Id" [paginator]="" [pageLinks]="3"
                              [scrollable]="true" scrollHeight="200px">
                                <!-- Header -->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>
                                            <p-checkbox [ngModel]="checkedAll" (ngModelChange)="checkAll($event)"
                                              [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                                        </th>
                                        <th>{{'NAME' | translate}}</th>
                                    </tr>
                                </ng-template>
                                <!-- Body Content -->
                                <ng-template pTemplate="body" let-internal>
                                    <tr>
                                        <td>
                                            <p-checkbox [(ngModel)]="internal.Id === userId || internal.status"
                                              [disabled]="internal.Id === userId" (ngModelChange)="checked(internal)"
                                              [ngModelOptions]="{standalone: true}" [binary]="true"></p-checkbox>
                                        </td>
                                        <td>
                                            <span>{{internal.Name}}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!-- Empty message -->
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                        <td colspan="2">
                                            {{'NO_RECORDS_FOUND'|translate}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <!-- Table Ends -->
                        </div>
                        <div class="form-group">
                            <label for="EmailDevaition">
                                <span translate>EMAIL_NEWDEVIATION_LABEL</span>
                            </label>
                            <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [dataKey]="'Id'"
                              [options]="userSubscribers" formControlName="selectedEmailSubscriber"
                              [style]="{'width':'100%'}">
                                <ng-template let-emailDeviation let-i="index" pTemplate="item">
                                    {{i}}
                                    <span>{{emailDeviation.label}}</span>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                    </div>
                </div>

                <div class="action-btns-wrapper">
                    <a [routerLink]="['../../list']" class="btn btn-outline-secondary">
                        <span class="icon icon-sm icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </a>
                    <button *ngIf="isAdministrateIC" class="btn btn-primary" [disabled]="!InternalControlForm.valid">
                        <span translate>SAVE</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<toaster-component></toaster-component>