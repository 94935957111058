import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { BaseServices } from './../kuba.services';
import { CustomizeHomePageService } from './customize-homepage.service';

@Injectable()
export class CustomizeHomePageResolver implements Resolve<any[]> {
    constructor(private backend: CustomizeHomePageService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getData(BaseServices.ApplicationId, +route.params['cmsId'], BaseServices.UserId);
    }
}

@Injectable()
export class ApplicationDefaultVideoResolver implements Resolve<any[]> {
    constructor(private backend: CustomizeHomePageService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getDefaultVideo(0, 59, BaseServices.UserId);
    }
}
