import { Module } from 'src/app/kuba/follow-ups/models/deviation';

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureBase } from './features/models/features-base.model';

@Injectable({
  providedIn: 'root',
})
export class KubaServices {
  // Observable string sources
  private setLangSource = new Subject<string>();
  userSettings: any;

  // Observable string streams
  getLanguage$ = this.setLangSource.asObservable();

  constructor(private http: HttpClient) {}

  // Service message commands
  setLanguage(lang: string) {
    this.setLangSource.next(lang);
  }

  getUserBase() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/user/base',
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { userBase: response };
        })
      );
  }

  getAllLanguages() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/master/languages',
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { languages: response };
        })
      );
  }

  getUserCustomSetting() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/user/customsetting',
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { userLanguageTheme: response };
        })
      );
  }

  getUserFeatures() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/user/features',
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { userFeature: response };
        })
      );
  }

  getUserNotifications() {
    return this.http
      .get<HttpHeaderResponse>(
        environment.BASE_URL + '/notifications/users',
        BaseServices.headerOption
      )
      .pipe(
        map((response: HttpHeaderResponse) => {
          response = response;
          return { response: response };
        })
      );
  }

  public handleError(error: HttpHeaderResponse) {
    console.error(error);
    // return Observable.throw(error || 'Server error');
    return throwError(error || 'Server error');
  }
}

@Injectable({
  providedIn: 'root',
})
export class BaseServices {
  public static userSettings: any = null;
  public static activeTree: any;
  public static articleId = 0;
  public static ses: any;
  static setUserSettings(userSettings: any) {
    this.userSettings = userSettings;
  }
  static getUserSettings() {
    this.userSettings = sessionStorage.getItem('userSettings');
    return JSON.parse(this.userSettings);
  }
  static getLanguageId() {
    this.userSettings = sessionStorage.getItem('languageId');
    return this.userSettings;
  }

  static get userBase(): any {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase;
  }

  static get PortalManager(): boolean {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.IsPortalManager;
  }

  static get PortalBusinessListName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.PortalBusinessListName;
  }

  static get BusinessId(): number {
    if (window.name === 'Remote') {
      let businessId = +sessionStorage.getItem('RModeBusinessId')!;
      if (businessId > 0) {
        return +sessionStorage.getItem('RModeBusinessId')!;
      }
    }
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.BusinessId;
  }

  static get BusinessName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.BusinessName;
  }
  static get OrganizationNumber(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.OrganizationNumber;
  }

  static get ApplicationId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.ApplicationId;
  }

  static get ApplicationName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.ApplicationName;
  }

  static get UserId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserId;
  }

  static get UserFeatureKey(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserFeatureKey;
  }

  static get PortalId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.PortalId;
  }

  static get UserRole(): string {
    if (window.name === 'Remote') {
      return 'Editor';
    }
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserRole;
  }

  static get userTheme(): any {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userTheme;
  }

  static get userName(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.UserName;
  }
  static get Name(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.Name;
  }
  static get FeatureKey(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userProfile.FeatureKey;
  }
  static get FeatureId(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userProfile.FeatureId;
  }

  static get roleId(): string {
    if (window.name === 'Remote') {
      let businessId = +sessionStorage.getItem('RModeBusinessId')!;
      if (businessId > 0) {
        return '3';
      }
    }
    this.userSettings = this.getUserSettings();
    return `${this.userSettings.userProfile.RoleId}`;
  }

  static get appLanguages(): string | any {
    this.userSettings = this.getUserSettings();
    if (this.userSettings) {
      return this.userSettings.languages;
    }
  }

  static get userLanguage(): string | any {
    this.userSettings = this.getUserSettings();
    if (this.userSettings) {
      return this.userSettings.userLanguage;
    }
  }
  static get userLanguageId(): number {
    this.userSettings = this.getLanguageId();
    return this.userSettings;
  }

  static get userFeatures(): any {
    this.userSettings = this.getUserSettings();
    return JSON.parse(this.userSettings.userFeature.Features);
  }

  static get userProfile(): string {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userProfile;
  }

  static get getActiveTree(): any {
    return this.activeTree;
  }

  static get getArticleId(): any {
    return this.articleId;
  }
  static getUserFeatures(): FeatureBase {
    this.userSettings = this.getUserSettings();
    if (this.userSettings.userFeature) {
      return this.userSettings.userFeature;
    }
    return null!;
  }

  static getUserRights() {
    this.userSettings = this.getUserSettings();
    if (this.userSettings.userFeature.UserRights) {
      return this.userSettings.userFeature.UserRights;
    }
    return null;
  }

  static get headerOption() {
    this.ses = sessionStorage.getItem('session');
    let headers = new HttpHeaders();
    let session = JSON.parse(this.ses);
    if (session) {
      let type = 'Bearer';
      let token = session.AccessToken;
      // Split the JWT token by dots
      const tokenParts = token.split('.');

      // Check if there are at least two parts (header and payload)
      if (tokenParts.length >= 2) {
        // Add the desired letter after the first dot (in the payload part)
        tokenParts[1] =
          tokenParts[1].substring(0, 5) + 'W' + tokenParts[1].substring(5);

        // Join the token parts back together with a dot separator
        // // token = tokenParts.join('.'); commented for token issue fix

        headers = headers.append('content-type', 'application/json');
        headers = headers.append('Authorization', type + ' ' + token);
      } else {
        // Handle the case where the token doesn't have the expected format
        console.error('Invalid JWT token format');
      }
    }
    let options = { headers: headers };
    return options;
  }
  static createAuthorizationHeader(headers: HttpHeaders) {
    this.ses = sessionStorage.getItem('session');
    let session = JSON.parse(this.ses);
    if (session) {
      let type = 'Bearer';
      let token = session.AccessToken;
      // Split the JWT token by dots
      const tokenParts = token.split('.');

      // Check if there are at least two parts (header and payload)
      if (tokenParts.length >= 2) {
        // Add the desired letter after the first dot (in the payload part)
        tokenParts[1] =
          tokenParts[1].substring(0, 5) + 'W' + tokenParts[1].substring(5);

        // Join the token parts back together with a dot separator
        // token = tokenParts.join('.'); commented for token issue fix

        headers = headers.append('content-type', 'application/json');
        headers = headers.append('Authorization', type + ' ' + token);
      } else {
        // Handle the case where the token doesn't have the expected format
        console.error('Invalid JWT token format');
      }
    }
  }

  static setSessionData(sessionName: string, data: any) {
    let session = sessionStorage.getItem(sessionName);
    if (session) {
      sessionStorage.removeItem(sessionName);
    }
    sessionStorage.setItem(sessionName, data);
  }

  static setActiveTreeNode(data: any) {
    this.activeTree = data;
  }

  static setArticleId(id: number) {
    this.articleId = id;
  }

  static appendSessionData(sessionName: string, data: any) {
    let session = sessionStorage.getItem(sessionName);
    if (session) {
      sessionStorage.removeItem(sessionName);
    }
    sessionStorage.setItem(sessionName, data);
  }

  static apiRoute(key: any): string {
    let apiRoute = '';
    switch (key) {
      case 'PROJECT':
        apiRoute = 'project';
        break;
      case 'FDV':
        apiRoute = 'firedeviation';
        break;
      case 'IC':
        apiRoute = 'internalcontrol';
        break;
      case 'OS':
        apiRoute = 'othersystems';
        break;
      case 'QS':
        apiRoute = 'qualitysystem';
        break;
      case 'FS':
        apiRoute = 'foodsafety';
        break;
      case 'VEHICLE':
        apiRoute = 'vehicle';
        break;
      case 'SA':
        apiRoute = 'serviceAgreement';
        break;
      case 'FRAMEWORK':
        apiRoute = 'framework';
        break;
      case 'KUNDE_EL':
        apiRoute = 'kundeEL';
        break;
      case 'KUBA_CONTROL':
        apiRoute = 'kubacontrol';
        break;
    }
    return apiRoute;
  }

  static getFeatureId(key: any): number {
    let featureId = 1; // follow up
    switch (key) {
      case 'PROJECT':
        featureId = Module.PROJECT;
        break;
      case 'FDV':
        featureId = Module.FIREDEVIATION;
        break;
      case 'IC':
        featureId = Module.INTERNALCONTROL;
        break;
      case 'OS':
        featureId = Module.OTHER_SYSTEMS;
        break;
      case 'QS':
        featureId = Module.QUALITY_SYSTEMS;
        break;
      case 'FS':
        featureId = Module.FOODSAFETY;
        break;
      case 'VEHICLE':
        featureId = Module.VEHICLE;
        break;
      case 'SA':
        featureId = Module.SERVICEAGREEMENT;
        break;
      case 'FRAMEWORK':
        featureId = Module.FRAMEWORKAGREEMENT;
        break;
      case 'KUNDE_EL':
        featureId = Module.Kunde_EL;
        break;
      case 'KUBA_CONTROL':
        featureId = Module.KUBA_CONTROL;
        break;
    }
    return featureId;
  }

  static checkUserRights(userRightId: number, roleFilter: string) {
    let currentUserRole = BaseServices.UserRole;
    if (currentUserRole === roleFilter) {
      let data = this.getUserRights();
      let userRights = JSON.parse(data);
      return this.checkRightsForAccess(userRights, userRightId);
    }
    return true;
  }

  static checkRightsForAccess(rightsData: any, rightsId: number): boolean {
    let userRight = false;
    if (rightsData && rightsData.length > 0) {
      for (let x = 0; x < rightsData.length; x++) {
        let element = rightsData[x];
        let id = +element.id;
        if (id === rightsId) {
          return element.isChecked;
        }
      }
    }
    return userRight;
  }
  static handleError(error: HttpHeaderResponse) {
    console.error('ApiService::handleError', error);
    if (error.status === 401) {
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
      if (appSettings) {
        window.location.href = appSettings.BaseUrl + '/login';
      }
      window.location.href = '/login';
    }
    // return Observable.throw(error);
    return throwError(error);
  }

  /**
   * generate file
   * @param reportData {any}
   * @param columnNames {any}
   * @param type {any}
   */
  generateExportFile(
    reportData: any,
    columnNames: any,
    type: any,
    headerName: any,
    printMode: any
  ) {
    let dynamicType: any;
    if (type === 'PDF') {
      dynamicType = 'application/pdf';
    } else if (type === 'EXCEL') {
      dynamicType =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (type === 'WORD') {
      dynamicType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    let myData = JSON.stringify({ data: reportData, columnNames: columnNames });
    let url = `${environment.BASE_URL}/export/${type}/${headerName}/${printMode}`;
    return Observable.create((observer: any) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      let session = JSON.parse(sessionStorage.getItem('session'));
      let type = 'Bearer';
      let token = session.AccessToken;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Authorization', type + ' ' + token);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let contentType = dynamicType;
            let blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(myData);
    });
  }

  static userCultureInfo(languageName?: string): string {
    this.userSettings = this.getUserSettings();
    let cultureInfo = '';
    let langKey = languageName
      ? languageName
      : this.userSettings.userLanguage.Id;
    switch (langKey) {
      case 1:
      case 'en':
        cultureInfo = 'en-GB';
        break;
      case 2:
      case 'no':
        cultureInfo = 'no-NO';
        break;
      case 3:
      case 'sv':
        cultureInfo = 'sv-SE';
        break;
      case 4:
      case 'pl':
        cultureInfo = 'pl-PL';
        break;
    }
    return cultureInfo;
  }

  /*
    Util method to serialize a string to a specific Type
    */
  static getSerialized<T>(arg: any): T {
    return <T>JSON.parse(JSON.stringify(arg));
  }

  static get IsTOCApproved(): boolean {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.IsTOCApproved;
  }

  static get BusDepartment(): number {
    this.userSettings = this.getUserSettings();
    return this.userSettings.userBase.BusinessDepartmentId;
  }
}
