<div class="tab-content">
  <form [formGroup]="inspectionForm">
    <div class="row">
      <div class="col-sm-5">
        <div class="form-group">
          <label class="col-form-label">
            <span translate>INSPECTION_NO</span>
          </label>
          <div class="phone-field">
            <input
              type="text"
              pInputText
              disabled
              value="{{ dispInspectionNo }}"
            />
            <input
              type="text"
              [readonly]="isDisableAllFields"
              id="txtRelatedIncident"
              pInputText
              formControlName="InspectionNo"
              (keypress)="checkNumber($event)"
            />
          </div>
          <control-messages
            [control]="inspectionForm.controls['InspectionNo']"
          ></control-messages>
          <div></div>
        </div>
        <div class="form-group">
          <label class="col-form-label">
            <span translate>INSPECTION_TITLE</span>
            <span class="required">*</span>
          </label>
          <div>
            <input
              type="text"
              [readonly]="isDisableAllFields"
              id="txtRelatedIncident"
              pInputText
              formControlName="InspectionTitle"
            />
          </div>
          <control-messages
            [control]="inspectionForm.controls['InspectionTitle']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label class="col-form-label">
            <span translate>STATUS</span>
            <span class="required">*</span>
          </label>
          <p-dropdown
            [disabled]="
              (isDisableAllFields || statusDisabled) && !newInspection
            "
            formControlName="InspectionStatus"
            [options]="status"
            (onChange)="statusChange()"
            placeholder="{{ 'SELECT' | translate }}"
            [style]="{ width: '100%' }"
          >
          </p-dropdown>
          <control-messages
            [control]="inspectionForm.controls['InspectionStatus']"
          ></control-messages>
        </div>
        <div class="form-group" [hidden]="hideStartDate">
          <label class="col-form-label">
            <span translate>START_DATE</span>
            <span class="required">*</span>
          </label>
          <my-date-picker
            [disabled]="isDisableAllFields"
            [options]="dateOptions"
            placeholder="{{ 'SELECT_START_DATE' | translate }}"
            [style]="{ width: '100%' }"
            formControlName="StartDate"
            (dateChanged)="onDateChanged($event)"
          >
          </my-date-picker>
          <control-messages
            [control]="inspectionForm.controls['StartDate']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label class="col-form-label">
            <span translate>DEADLINE</span>
            <span class="required">*</span>
          </label>
          <my-date-picker
            [disabled]="isDisableAllFields"
            [options]="dateOptionsforDeadLine"
            placeholder="{{ 'SELECT_DEADLINE_DATE' | translate }}"
            [style]="{ width: '100%' }"
            formControlName="Deadline"
          ></my-date-picker>
          <div
            class="error-msg"
            *ngIf="
              deadLinePastStartMsg && inspectionForm.controls['Deadline'].errors
            "
          >
            <span translate>DEADLINE_PAST_STARTDATE</span>
          </div>
          <control-messages
            [control]="inspectionForm.controls['Deadline']"
          ></control-messages>
        </div>
        <div class="form-group" [ngClass]="{ hidden: !isSelfInspection }">
          <label class="col-form-label">
            <span translate>INTERVAL</span>
            <span class="required">*</span>
          </label>
          <p-dropdown
            [disabled]="isDisableAllFields"
            formControlName="Repetition"
            [options]="repetition"
            placeholder="{{ 'SELECT' | translate }}"
            [style]="{ width: '100%' }"
          ></p-dropdown>
          <control-messages
            [control]="inspectionForm.controls['Repetition']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label class="col-form-label">
            <span translate>RESPONSIBLE_EXECUTION</span>
            <span class="required">*</span>
          </label>
          <p-multiSelect
            [disabled]="isDisableAllFields"
            formControlName="Execution"
            [options]="execution"
            [style]="{ width: '100%' }"
            defaultLabel="{{ 'CHOOSE' | translate }}"
          >
          </p-multiSelect>
          <control-messages
            [control]="inspectionForm.controls['Execution']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label class="col-form-label">
            <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
            <span class="required">*</span>
          </label>
          <p-multiSelect
            [disabled]="isDisableAllFields"
            formControlName="Followup"
            [options]="followup"
            [style]="{ width: '100%' }"
            defaultLabel="{{ 'CHOOSE' | translate }}"
          >
          </p-multiSelect>
          <control-messages
            [control]="inspectionForm.controls['Followup']"
          ></control-messages>
        </div>
        <div class="form-group">
          <label for="comment" class="col-form-label">
            <span translate>COMMENT</span>
          </label>
          <div>
            <textarea
              [readonly]="isDisableAllFields"
              pInputTextarea
              autoResize="autoResize"
              formControlName="Comment"
            ></textarea>
          </div>
        </div>
        <div class="form-group" *ngIf="!isSelfInspection">
          <label class="col-form-label" translate>TASKS</label>
          <div *ngIf="!isClientUserRestrict" class="input-btn-group pull-right">
            <button
              type="button"
              *ngIf="!inspectionId"
              disabled
              class="btn btn-outline-primary"
              (click)="addTask()"
            >
              <span
                *ngIf="!allSelectedTasks.length"
                pTooltip="{{ 'SAVE_BEFORE_ADD_TASK' | translate }}"
                translate
                >ADD_TASK</span
              >
              <span *ngIf="allSelectedTasks.length" translate>EDIT_TASK</span>
            </button>
            <button
              type="button"
              *ngIf="inspectionId"
              class="btn btn-outline-primary"
              (click)="addTask()"
            >
              <span *ngIf="!allSelectedTasks.length" translate>ADD_TASK</span>
              <span *ngIf="allSelectedTasks.length" translate>EDIT_TASK</span>
            </button>
          </div>
        </div>
        <div class="form-group" *ngIf="allSelectedTasks.length">
          <!-- Table starts -->
          <p-table [value]="allSelectedTasks" dataKey="Id">
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "SUB_TASKS" | translate }}</th>
                <th>{{ "STATUS" | translate }}"</th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-task>
              <tr>
                <td>
                  <h6 pTooltip="{{ task.Name }}">{{ task.Name }}</h6>
                  <div>
                    <span>{{ "INTERVAL" | translate }}</span
                    >:
                    <span>{{ task.IntervalText | translate }}</span>
                  </div>
                  <div>
                    <span>{{ "LAST_PERFORMED" | translate }}</span> :
                    <span>{{ task.LastPerformed | date : "dd.MM.yyyy" }}</span>
                  </div>
                  <div>
                    <span>{{ "NEXT_INSPECTION" | translate }}</span> :
                    <span>{{ task.NextInspection | date : "dd.MM.yyyy" }}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <p-dropdown
                      [options]="taskStatus"
                      [style]="{ width: '100%', overflow: 'visible' }"
                      [disabled]="isClientUserRestrict"
                      [(ngModel)]="task.Status"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </p-dropdown>
                  </div>
                  <div *ngIf="!isClientUserRestrict" style="margin-top: 10px">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="complete(task.Id)"
                      [disabled]="task.Status === 3"
                    >
                      <span translate>COMPLETE_FYLL</span
                      ><span class="icon ic-sm icon-046"></span>
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table Ends -->
        </div>

        <div class="form-group" *ngIf="isSelfInspection">
          <label class="col-form-label" translate>FILL_OUT_DOCUMENTATION</label>
          <div class="input-btn-group pull-right">
            <ng-template pTemplate="body" let-task>
              <button
                type="button"
                class="btn btn-primary"
                (click)="complete(task)"
                [disabled]="!inspectionId"
              >
                <span translate>COMPLETE_FYLL</span
                ><span class="icon ic-sm icon-046"></span>
              </button>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="tab-content">
            <ecpl-document-explorer
              [uploaderConfig]="upConfig"
              [uploadedFiles]="uploadedFiles"
              (saveChanges)="saveUploaded($event)"
            >
            </ecpl-document-explorer>
          </div>
        </div>
      </div>
      <div class="col-sm-7">
        <div *ngIf="clientContactInfo && !isSelfInspection">
          <div class="page-title">
            <span translate>CLIENT_INFORMATION</span>
          </div>
          <table class="table no-border">
            <tr>
              <th style="width: 140px">
                <span translate>FIRM_NAME</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientFirm }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>CLIENT_NUMBER</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientNumber }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>ADDRESS</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientAddress }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>ZIP_CITY</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientZipcodeCity }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>PHONE_NO</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientPhoneNumber }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>EMAIL</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientEmail }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>CONTACT_PERSON</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ClientContactPerson }}</span>
              </td>
            </tr>
          </table>
          <div class="page-title">
            <span translate>INSTALLATION_CONTACT</span>
          </div>
          <table class="table no-border">
            <tr>
              <th style="width: 140px">
                <span translate>NAME</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ContactName }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>PHONE_NO</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ContactPhoneNumber }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>EMAIL</span>
              </th>
              <td>
                <span>{{ clientContactInfo.ContactEmail }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span translate>FUNCTION</span>
              </th>
              <td>
                <span translate>{{
                  clientContactInfo.InstallationContactFunctionText
                }}</span>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="treeData && !isContractFollowupTab">
          <app-ecpl-document-tree
            *ngIf="treeData"
            treeId="0"
            [(ecplTreeData)]="treeData"
            (ecplNodeEvent)="catchTreeEvent($event)"
            [(ecplTreeCheckbox)]="editMode"
            (ecplNodeOptionsEvent)="treeOptionsEvent($event)"
          >
          </app-ecpl-document-tree>
        </div>
      </div>
    </div>
    <div *ngIf="isClientUserRestrict" class="action-btns-wrapper">
      <button type="button" class="btn btn-outline-secondary" (click)="back()">
        <span translate>CANCEL</span>
      </button>
      <div class="pull-right" *ngIf="inspectionId">
        <button
          *ngIf="checkCompletion() && !isSelfInspection"
          type="submit"
          class="btn btn-primary"
          (click)="InspectionReportClick()"
          [disabled]="!(inspectionForm.valid && inspectionId)"
        >
          <span translate>COMBINED_INSPECTION_REPORT</span>
        </button>
      </div>
    </div>
    <div *ngIf="!isClientUserRestrict" class="action-btns-wrapper">
      <button type="button" class="btn btn-outline-secondary" (click)="back()">
        <span translate>CANCEL</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="SaveInspectionClick('back')"
        [disabled]="!inspectionForm.valid"
      >
        <span translate>SAVE_AND_BACK</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="SaveInspectionClick('stay')"
        [disabled]="!inspectionForm.valid"
      >
        <span translate>SAVE</span>
      </button>
      <button
        *ngIf="isContractFollowupTab"
        type="submit"
        class="btn btn-primary"
        (click)="SaveInspectionClick('next')"
        [disabled]="!inspectionForm.valid"
      >
        <span translate>SAVE_AND_NEXT</span>
      </button>
      <div class="pull-right" *ngIf="inspectionId">
        <button
          *ngIf="checkCompletion() && isEndControl && isEditorOrLeader"
          type="submit"
          class="btn btn-success"
          (click)="endControlClick()"
          [disabled]="
            !(inspectionForm.valid && isTasksCompleted && inspectionId)
          "
        >
          <span translate>END_CONTROL</span>
        </button>
        <button
          *ngIf="checkCompletion() && !isSelfInspection"
          type="submit"
          class="btn btn-primary"
          (click)="InspectionReportClick()"
          [disabled]="!(inspectionForm.valid && inspectionId)"
        >
          <span translate>COMBINED_CONTROL_REPORT</span>
        </button>
      </div>
    </div>
  </form>
</div>

<p-dialog
  header="{{ 'ADD_TASK' | translate }}"
  *ngIf="showAddTaskDialog"
  [modal]="true"
  [(visible)]="showAddTaskDialog"
  [style]="{ width: '800px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'scroll' }"
>
  <div class="table-view">
    <!-- Table starts -->
    <p-table #dt1 [value]="contractTasks" dataKey="Id">
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th>{{ "CONTRACT_TASKS" | translate }}</th>
          <th>{{ "LAST_PERFORMED" | translate }}</th>
          <th>{{ "NEXT_INSPECTION" | translate }}</th>
          <th>{{ "STATUS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-inspection>
        <tr>
          <td>
            <p-checkbox
              *ngIf="inspection.IsTaskServiceAvailable"
              binary="true"
              [ngModel]="selectedContractTasks.indexOf(inspection) !== -1"
              (onChange)="onTaskSelected(inspection, $event)"
            >
            </p-checkbox>
          </td>
          <td>
            <span>{{ inspection.Name }}</span>
          </td>
          <td>
            <span>{{ inspection.LastPerformed | date : "dd.MM.yyyy" }}</span>
          </td>
          <td>
            <span>{{ inspection.NextInspection | date : "dd.MM.yyyy" }}</span>
          </td>
          <td>
            <p-dropdown
              [options]="taskStatus"
              [style]="{ width: '100%', overflow: 'visible' }"
              [(ngModel)]="inspection.Status"
              [ngModelOptions]="{ standalone: true }"
              disabled
            >
            </p-dropdown>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
  <div
    class="table-view"
    *ngIf="inspectionTasks ? inspectionTasks.length : false"
  >
    <!-- Table starts -->
    <p-table
      [(selection)]="selectedInspectionTasks"
      #dt2
      [value]="inspectionTasks"
      dataKey="Id"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 38px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>{{ "SINGLE_INSPECTION_TASKS" | translate }}</th>
          <th>{{ "OPTIONS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-inspection>
        <tr>
          <td style="width: 38px">
            <p-tableCheckbox id="selectedOrders" [value]="inspection">
            </p-tableCheckbox>
          </td>
          <td>
            <span>{{ inspection.Name }}</span>
          </td>
          <td class="col-button">
            <button
              type="button"
              class="btn btn-icon"
              (click)="editSingleTask(inspection)"
              title="{{ 'EDIT' | translate }}"
            >
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
            <button
              type="button"
              class="btn btn-icon"
              (click)="deleteSingleTask(inspection['Id'])"
              title="{{ 'DELETE' | translate }}"
            >
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table Ends -->
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <span pTooltip="{{ 'SAVE_BEFORE_ADD_TASK' | translate }}">
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="addSingleTask()"
          *ngIf="!inspectionId"
          disabled
          translate
        >
          ADD_SINGLE_TASK
        </button>
      </span>
      <span pTooltip="{{ 'SAVE_BEFORE_ADD_TASK' | translate }}">
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveEnabledTasks()"
          *ngIf="!inspectionId"
          disabled
          translate
        >
          SAVE
        </button>
      </span>
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="addSingleTask()"
        *ngIf="inspectionId"
        translate
      >
        ADD_SINGLE_TASK
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveEnabledTasks()"
        *ngIf="inspectionId"
        translate
      >
        SAVE
      </button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog
  header="{{ 'ADD_SINGLE_TASK' | translate }}"
  *ngIf="showAddSingleTaskDialog"
  [(visible)]="showAddSingleTaskDialog"
  [modal]="true"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <form [formGroup]="addServiceForm">
    <div class="form-group">
      <label for="description" translate>DESCRIPTION </label>
      <input type="text" formControlName="description" pInputText />
      <span class="required">*</span>
      <control-messages
        [control]="addServiceForm.controls['description']"
      ></control-messages>
    </div>
    <div class="action-btns-wrapper">
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="clearDescription()"
          translate
        >
          CLEAR
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="showAddSingleTaskDialog = false"
          translate
        >
          CLOSE
        </button>
        <button
          type="Submit"
          class="btn btn-primary"
          [disabled]="!addServiceForm.valid"
          (click)="saveService(addServiceForm.value)"
          translate
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
  <span>
    <span class="loader-icon"></span>
    <span>Processing...</span>
  </span>
</div>
<!--Panel Loader-->
<p-dialog
  header="{{ 'SEND_CHANGE_NOTIFICATION' | translate }}"
  *ngIf="isShowNotifyPopup"
  [(visible)]="isShowNotifyPopup"
  [modal]="true"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <div class="form-group">
    <div class="checkbox">
      <p-checkbox
        label="{{ 'SEND_CHANGE_NOTIFICATION' | translate }}"
        binary="true"
        [(ngModel)]="IsChangeNotify"
      >
      </p-checkbox>
    </div>
  </div>
  <div class="form-group" *ngIf="IsChangeNotify">
    <div class="checkbox">
      <p-checkbox
        label="{{ 'RESPONSIBLE_EXECUTION' | translate }}"
        binary="true"
        [(ngModel)]="IsExecutionChangeNotify"
      ></p-checkbox>
      <p-checkbox
        label="{{ 'RESPONSIBLE_FOR_FOLLOW_UP' | translate }}"
        binary="true"
        [(ngModel)]="IsFollowupChangeNotify"
      ></p-checkbox>
    </div>
  </div>
  <div class="action-btns-wrapper">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="SaveInspection(navOnSaveClick)"
    >
      <span translate>SAVE</span>
    </button>
  </div>
</p-dialog>
<p-dialog
  header="{{ previewHeader | translate }}"
  *ngIf="previewChecklist"
  [(visible)]="previewChecklist"
  [modal]="true"
  [style]="{ width: '1000px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'scroll' }"
>
  <course-overview
    #course
    *ngIf="enableForm == 'course-overview'"
    [courseOverview]="formData"
    (distributionChange)="onBuidingSelect($event)"
  ></course-overview>
  <distribution-overview
    #distribution
    *ngIf="enableForm == 'distribution-overview'"
    [distributionOverview]="formData"
  >
  </distribution-overview>
  <document-assessment
    #documentAssessment
    *ngIf="enableForm == 'document-assessment'"
    [DocumentAssessment]="formData"
  >
  </document-assessment>
  <emergency-lighting
    #emergencyLighting
    *ngIf="enableForm == 'emergency-lighting'"
    [lighting]="formData"
  >
  </emergency-lighting>
  <fire-alarm
    #filreAlarm
    *ngIf="enableForm == 'fire-alarm'"
    [firealarmSystem]="formData"
  ></fire-alarm>
  <risk-assessment
    #riskAssessment
    *ngIf="enableForm == 'risk-assessment'"
    [riskAssessment]="formData"
  >
  </risk-assessment>
  <measure-protocol-preview
    *ngIf="enableForm == 'measure-protocol'"
    [mpCompleteId]="formData.Id"
  >
  </measure-protocol-preview>
  <div *ngIf="enableForm == 'checklist' && CompleteChecklistEdit.Section">
    <ng-template
      ngFor
      let-section
      let-headPoint="index"
      [ngForOf]="CompleteChecklistEdit.Section"
    >
      <table class="table">
        <thead>
          <tr>
            <th>{{ headPoint + 1 }} {{ section.Title }}</th>
            <th style="width: 70px">
              <span *ngIf="headPoint === 0" translate>OK</span>
            </th>
            <th style="width: 70px">
              <span *ngIf="headPoint === 0" translate>N/A</span>
            </th>
            <th style="width: 70px">
              <span *ngIf="headPoint === 0" translate>DEVIATION</span>
            </th>
            <th style="width: 250px">
              <span *ngIf="headPoint === 0" translate>COMMENTS</span>
            </th>
          </tr>
        </thead>
        <ng-template
          ngFor
          let-sectionitem
          let-i="index"
          [ngForOf]="section.Items"
        >
          <tbody>
            <tr>
              <td>
                <label
                  >{{ headPoint + 1 }}. {{ i + 1 }}
                  {{ sectionitem.Title }}</label
                >
              </td>
              <ng-template ngFor let-status [ngForOf]="sectionitem.Status">
                <td *ngIf="status.Label != 'Deviation'">
                  <input
                    id="statusOptions[{{ headPoint }}{{ i }}]"
                    disabled
                    name="options[{{ headPoint }}{{ i }}]"
                    type="checkbox"
                    [value]="status.value"
                    (change)="
                      setStatus(status, sectionitem.Status, sectionitem)
                    "
                    style="width: 28px; height: 28px; margin: 0px"
                    [checked]="status.value"
                  />
                </td>
              </ng-template>
              <td>
                <a
                  class="btn btn-danger"
                  disabled
                  (click)="addEditDeviation(0, headPoint, i)"
                >
                  <span class="icon ic-sm icon-add"></span
                ></a>
              </td>
              <td>
                <textarea
                  disabled
                  [(ngModel)]="sectionitem.Comments"
                  id="statusComments"
                  class="form-control single-line"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="text-right" style="border: 0">
                <strong>{{ sectionitem.Signature }}</strong>
              </td>
            </tr>
            <tr *ngIf="sectionitem.DeviationList.length">
              <td colspan="6" style="border: 0">
                <table class="table table-hover">
                  <tr>
                    <th style="width: 80px">
                      <span translate>DEVIATION_NUMBER</span>
                    </th>
                    <th style="width: 100px">
                      <span translate>LOCATION</span>
                    </th>
                    <th><span translate>DESCRIPTION</span></th>
                    <th style="width: 80px"><span translate>STATUS</span></th>
                    <th style="width: 100px">
                      <span translate>DEADLINE</span>
                    </th>
                    <th style="width: 160px">
                      <span translate>RESPONSIBLE_EXECUTION</span>
                    </th>
                  </tr>
                  <tr *ngFor="let dev of sectionitem.DeviationList">
                    <td>{{ dev.Id }}</td>
                    <td>
                      <span translate>{{ dev.Location }}</span>
                    </td>
                    <td>
                      <span translate>{{ dev.Description }}</span>
                    </td>
                    <td>
                      <span translate>{{ dev.StatusText }}</span>
                    </td>
                    <td>
                      <span translate>{{
                        dev.Deadline | date : "dd.MM.yyyy"
                      }}</span>
                    </td>
                    <td>
                      <span translate>{{ dev.ResponsiblePersonName }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </ng-template>
  </div>
</p-dialog>
<p-dialog
  header="{{ 'END_CONTROL' | translate }}"
  *ngIf="ShowEndControlConfirm"
  [(visible)]="ShowEndControlConfirm"
  [modal]="true"
  [style]="{ width: '500px' }"
  [resizable]="false"
  [draggable]="false"
  [contentStyle]="{ overflow: 'visible' }"
>
  <span translate>NEED_REPETITION_ADD_NEW_INSPECTION</span>
  <div class="action-btns-wrapper">
    <button
      type="submit"
      class="btn btn-primary"
      (click)="endControlCofirmation(true)"
    >
      <span class="pi pi-check"></span><span translate>SWITCH_YES</span>
    </button>
    <button
      type="submit"
      class="btn btn-primary"
      (click)="endControlCofirmation(false)"
    >
      <span class="pi pi-times"></span><span translate>SWITCH_NO</span>
    </button>
  </div>
</p-dialog>
