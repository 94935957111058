import { HelperService } from './../../../_services/helper.service';
import { SelectItem } from 'primeng/api';
import { IMyDpOptions } from 'mydatepicker';
import { CrewListServices } from 'src/app/kuba/crew-list/services/crewlist.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
const StringBuilder = require('string-builder');

@Component({
    selector: 'crew-list',
    templateUrl: 'crew-list.component.html'
})
export class CrewListComponent implements OnInit {
    @ViewChild('crewListTable',{static: false}) crewListTable: Table;
    crewList: any;
    filterData: any;
    parentId: any;
    parentKey: any;
    statuses: SelectItem[];
    projectInfo: any;
    projectName: string;
    ProjectAddress: string;
    ProjectBuilder: string;
    additionalData: any;
    featureKeyName: string;
    hideFeatureInfo = true;
    buildername: string;
    private subscriptions: Subscription[] = [];
    foodsafety:boolean=true;
    /**
* Date picker configuration option
*/
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px'
    };
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    constructor(
        private crewListServices: CrewListServices,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
        if(this.parentKey === 'FS'){
            this.additionalData = {
                fileName: val.CREW_LIST,
                header: 'Crew List',
                businessId: BaseServices.BusinessId,
                cultureInfo: 'en-GB',
                columnNames: [
                    { title: 'Name', dataKey: 'Name' },
                    { title: 'Time', dataKey: 'TimeZone' },
                    { title: 'Status', dataKey: 'ModeName' },
                    { title: 'Address', dataKey: 'Address' },
                    { title: 'FeatureId', dataKey: 'FeatureId' },
                    { title: 'FeatureKey', dataKey: 'FeatureKey' }
                ]
            };
        }
        else{
            this.additionalData = {
                fileName: val.CREW_LIST,
                header: 'Crew List',
                businessId: BaseServices.BusinessId,
                cultureInfo: 'en-GB',
                columnNames: [
                    { title: 'Organization Number', dataKey: 'OrganizationNumber' },
                    { title: 'Company Name', dataKey: 'CompanyName' },
                    { title: 'Name', dataKey: 'Name' },
                    { title: 'Date Of Birth', dataKey: 'Dob' },
                    { title: 'HSE-Card Number', dataKey: 'HSECardNumber' },
                    { title: 'Date', dataKey: 'Date' },
                    { title: 'Time', dataKey: 'TimeZone' },
                    { title: 'Status', dataKey: 'ModeName' },
                    { title: 'Address', dataKey: 'Address' },
                    { title: 'FeatureId', dataKey: 'FeatureId' },
                    { title: 'FeatureKey', dataKey: 'FeatureKey' }
                ]
            };
        }
    }));
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let sb = new StringBuilder();
        this.crewList = this.route.snapshot.data['list'];
        this.projectInfo = this.route.snapshot.data['proInfo'];
        this.parentKey = this.route.snapshot.parent.parent.data['parent'];

        if (this.projectInfo) {
            this.projectName = this.projectInfo.ProjectName;
            this.ProjectAddress = this.projectInfo.Address;
            this.ProjectBuilder = this.projectInfo.Builder;
        }
        if (this.parentKey === 'FS') {
            this.buildername = this.translate.instant('DEPARTMENT_NAME');
            this.featureKeyName = 'ZONE';
        } else {
            this.featureKeyName = 'PROJ_NAME';
            this.buildername = this.translate.instant('BUILDER');
        }
        if (this.crewList) {
            this.filterData = this.crewList;
            this.getTime(this.crewList);
        }
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_CREWLIST').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.IN_, value: 'IN' },
                { label: val.ON_BREAK, value: 'ON_BREAK' },
                { label: val.TEMPORARY_OUT, value: 'TEMPORARY_OUT' },
                { label: val.NOT_PRESENT, value: 'NOT_PRESENT' },
            );
        }));
        if(this.parentKey === 'FS'){
            this.foodsafety=false;
        }
    }
    /**
    * to filter status in list
    * @param e
    * @param statusDropdown
    */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.crewListTable.filter(
                statusDropdown.selectedOption.value,
                'ModeName',
                'contains'
            );
        } else {
            this.getCrewList();
        }
    }
    getTime(data) {
        data.forEach((element, index) => {
            if (element.Date) {
                let Time = HelperService.formatTime(new Date(element.Date));
                this.crewList[index].Time = Time;
            }
        });
    }
    getCrewList() {
        this.subscriptions.push(this.crewListServices.getCrewList(this.parentId, this.parentKey).subscribe(result => {
            if (result) {
                this.crewList = result;
            }
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
