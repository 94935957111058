<div class="main-content">
    <div class="main-heading">
        <strong>
            <span translate>AVAILABLE_MODULES</span>
        </strong>
    </div>
    <div class="">
        <div class="grid-view">
            <div class="page-title">
                <span translate>HSE_MODULES</span>
            </div>
            <div class="grid-item" *ngFor="let item of moduleoverview.HSE_MODULES">
                <div class="grid-body">
                    <div class="grid-item-header">
                        <div class="grid-item-icon">
                            <span class="icon ic-xl icon-{{item.Key | lowercase}}"></span>
                        </div>
                        <div class="grid-item-title">{{item.TranslationKey | translate }}</div>
                        <div class="grid-item-status">
                            <span *ngIf="item.checked" class="icon ic-md icon-checkmark"></span>
                            <span *ngIf="!item.checked" class="icon ic-md icon-multiply"></span>
                        </div>
                    </div>
                    <div class="grid-item-body">
                        <p
                            [innerHTML]="item.Description.length <= 210 ? item.Description : (item.Description | slice:0:210) + '...' ">
                        </p>
                    </div>
                    <div class="grid-item-meta">
                        <a href="javascript:void(0);" (click)="showTitlePopup(item)" translate>VIEW_MORE
                            <span class="icon ic-xs icon-forward"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="page-title">
                <span translate>ADDITIONAL_MODULES</span>
            </div>
            <div class="grid-item" *ngFor="let item of moduleoverview.ADDITIONAL_MODULES">
                <div class="grid-body">
                    <div class="grid-item-header">
                        <div class="grid-item-icon">
                            <span class="icon ic-xl icon-{{item.Key | lowercase}}"></span>
                        </div>
                        <div class="grid-item-title">{{item.TranslationKey | translate }}</div>
                        <div class="grid-item-status">
                            <span *ngIf="item.checked" class="icon ic-md icon-checkmark"></span>
                            <span *ngIf="!item.checked" class="icon ic-md icon-multiply"></span>
                        </div>
                    </div>
                    <div class="grid-item-body">
                        <p
                            [innerHTML]="item.Description.length <= 210 ? item.Description : (item.Description | slice:0:210) + '...' ">
                        </p>
                    </div>
                    <div class="grid-item-meta">
                        <a href="javascript:void(0);" (click)="showTitlePopup(item)" translate>VIEW_MORE
                            <span class="icon ic-xs icon-forward"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="page-title">
                <span translate>HR_MODULES</span>
            </div>
            <div class="grid-item" *ngFor="let item of moduleoverview.HR_MODULES">
                <div class="grid-body">
                    <div class="grid-item-header">
                        <div class="grid-item-icon">
                            <span class="icon ic-xl icon-{{item.Key | lowercase}}"></span>
                        </div>
                        <div class="grid-item-title">{{item.TranslationKey | translate }}</div>
                        <div class="grid-item-status">
                            <span *ngIf="item.checked" class="icon ic-md icon-checkmark"></span>
                            <span *ngIf="!item.checked" class="icon ic-md icon-multiply"></span>
                        </div>
                    </div>
                    <div class="grid-item-body">
                        <p
                            [innerHTML]="item.Description.length <= 210 ? item.Description : (item.Description | slice:0:210) + '...' ">
                        </p>
                    </div>
                    <div class="grid-item-meta">
                        <a href="javascript:void(0);" (click)="showTitlePopup(item)" translate>VIEW_MORE
                            <span class="icon ic-xs icon-forward"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="page-title">
                <span translate>OTHER</span>
            </div>
            <div class="grid-item" *ngFor="let item of moduleoverview.OTHER">
                <div class="grid-body">
                    <div class="grid-item-header">
                        <div class="grid-item-icon">
                            <span class="icon ic-xl icon-{{item.Key | lowercase}}"></span>
                        </div>
                        <div class="grid-item-title">{{item.TranslationKey | translate }}</div>
                        <div class="grid-item-status">
                            <span *ngIf="item.checked" class="icon ic-md icon-checkmark"></span>
                            <span *ngIf="!item.checked" class="icon ic-md icon-multiply"></span>
                        </div>
                    </div>
                    <div class="grid-item-body">
                        <p
                            [innerHTML]="item.Description.length <= 210 ? item.Description : (item.Description | slice:0:210) + '...' ">
                        </p>
                    </div>
                    <div class="grid-item-meta">
                        <a href="javascript:void(0);" (click)="showTitlePopup(item)" translate>VIEW_MORE
                            <span class="icon ic-xs icon-forward"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="page-title">
                <span translate>ALTERNATIVE_SETTINGS</span>
            </div>
            <div class="grid-item" *ngFor="let item of moduleoverview.ALTERNATIVE_SETTINGS">
                <div class="grid-body">
                    <div class="grid-item-header">
                        <div class="grid-item-icon">
                            <span class="icon ic-xl icon-{{item.Key | lowercase}}"></span>
                        </div>
                        <div class="grid-item-title">{{item.TranslationKey | translate }}</div>
                        <div class="grid-item-status">
                            <span *ngIf="item.checked" class="icon ic-md icon-checkmark"></span>
                            <span *ngIf="!item.checked" class="icon ic-md icon-multiply"></span>
                        </div>
                    </div>
                    <div class="grid-item-body">
                        <p
                            [innerHTML]="item.Description.length <= 210 ? item.Description : (item.Description | slice:0:210) + '...' ">
                        </p>
                    </div>
                    <div class="grid-item-meta">
                        <a href="javascript:void(0);" (click)="showTitlePopup(item)" translate>VIEW_MORE
                            <span class="icon ic-xs icon-forward"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <p-dialog header="{{'MODULEOVERVIEW'|translate}}" [(visible)]="showFeature" [modal]="true" 
        [style]="{width: '600px'}" [resizable]="false" [draggable]="false" (onHide)="cancel()" [contentStyle]="{'min-height':'400px'}">
            <div class="form-group">
                <label for="title">
                    <span translate>{{featureName}}</span>
                </label>
                <div class="wb-content">
                    <div [innerHTML]="featureDesc"></div>
                </div>
            </div>
            <p-footer>
                <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                    <button type="button" class="btn btn-outline-secondary" (click)="closePopUp()">
                        <span translate>CLOSE</span>
                    </button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
</div>