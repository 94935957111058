<div class="panel panle-default">
    <div class="main-heading">
        <strong translate>NEW_RISK_ANALYSIS</strong>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="riskForm">
                <div class="form-group" *ngIf="riskForm.controls['Business']">
                    <label>
                        <span translate>BUSINESS_LIST</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-multiSelect [options]="businesses" [(ngModel)]="selectedBusinesses"
                          formControlName="Business" [style]="{'width':'380px'}"></p-multiSelect>
                    </div>
                    <control-messages [control]="riskForm.controls['Business']"></control-messages>
                </div>
                <div class="form-group" *ngIf="riskForm.controls['Title']">
                    <label>
                        <span translate>TITLE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input pInputText type="text" formControlName="Title" />
                    </div>
                    <control-messages [control]="riskForm.controls['Title']"></control-messages>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>LOCATION</span>
                    </label>
                    <div>
                        <input pInputText type="text" formControlName="Location" />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>WHAT_CAN_HAPPEN</span>
                    </label>
                    <div>
                        <textarea name="WhatCanHappen" class="form-control" formControlName="WhatCanHappen"></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>ACTION_TAKEN</span>
                    </label>
                    <div>
                        <textarea name="ActionTaken" class="form-control" formControlName="ActionTaken"></textarea>
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <button type="button" class="btn btn-outline-secondary" [routerLink]="['./..']">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button id="btnSave" type="button" class="btn btn-primary" (click)="saveRisk('Save')"
                      [disabled]="!riskForm.valid" translate>SAVE</button>
                    <button id="btnSaveAndNewrisk" class="btn btn-primary" type="button" (click)="saveRisk('NewRisk')"
                      [disabled]="!riskForm.valid" translate>SAVE_NEW_RISK</button>
                </div>
            </form>
        </div>
    </div>
</div>
<toaster-component></toaster-component>