<div class="page-title">
    <span class="page-actions">
        <button class="btn btn-primary" (click)="downloadPDF()">
            <span class="icon ic-sm icon-print"></span>
            <span translate>PRINT_AS_PDF</span>
        </button>
        <a *ngIf="!isClientUserRestrict" [routerLink]="['../edit',contractId,0]" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span>
                <span translate>NEW</span>
            </span>
        </a>
    </span>
</div>
<div class="table-view mbtm-30">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="inspectionList" #inspectionTable [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]" expandableRows="true" 
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th></th>
                    <th pSortableColumn="Title">{{'INSPECTION_NO' | translate}} <p-sortIcon field="Title"></p-sortIcon></th>
                    <th pSortableColumn="Deviations">{{'INSPECTION_TITLE'| translate}} <p-sortIcon field="Deviations"></p-sortIcon></th>
                    <th pSortableColumn="InspectionStatustext">{{'DEVIATIONS' | translate }} <p-sortIcon field="InspectionStatustext"></p-sortIcon></th>
                    <th>{{'STATUS'| translate}}</th>
                    <th>{{'RESPONSIBLE_PERSON'| translate}}</th>
                    <th>{{'FOLLOWUP_PERSON'| translate}}</th>
                    <th pSortableColumn="Deadline">{{'DEADLINE' | translate}} <p-sortIcon field="Deadline"></p-sortIcon></th>
                    <th pSortableColumn="DoneOn">{{'DONE_ON' | translate}} <p-sortIcon field="DoneOn"></p-sortIcon></th>
                    <th *ngIf="isGuestHideButton">{{'OPTIONS'| translate}}</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                        <input pInputText type="text"
                            (input)="inspectionTable.filter($event.target.value, 'Title', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th></th>
                    <th>
                        <p-dropdown [options]="statuses" (onChange)="onStatusChange($event, ed)" #ed
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th>
                        <p-dropdown [options]="resperson" #responsible
                            (onChange)="onResponsiblePersonChange($event,responsible)" styleClass="p-column-filter"
                            placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-resperson pTemplate="item"> {{ resperson.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th>
                        <p-dropdown [options]="resperson" #followup (onChange)="onFollowupPersonChange($event,followup)"
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-followup pTemplate="item"> {{ followup.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                    <th *ngIf="isGuestHideButton"></th>
                </tr>
            </ng-template>

            <!-- Body Content -->
            <ng-template pTemplate="body" let-inspection let-expanded="expanded" let-i="rowIndex">
                <tr>
                    <td>
                        <a (click)="inspectionTable.toggleRow(inspection)"
                            *ngIf="inspection.InspectionSubTaskList.length>0">
                            <i class="pi pi-chevron-circle-right" aria-hidden="true" *ngIf="setCollapse(inspection.Id)"
                                (click)="collapseRow(false,inspection.Id)"></i>
                            <i class="pi pi-chevron-circle-down" aria-hidden="true" *ngIf="!setCollapse(inspection.Id)"
                                (click)="collapseRow(true,inspection.Id)"></i>
                        </a>
                    </td>
                    <td>
                        <span><i class="{{inspection.StatusIcon}}"></i></span>
                    </td>
                    <td>
                        <span>{{inspection.InspectionNumber}}</span>
                    </td>
                    <td>
                        <a pTooltip="{{inspection.Title}}" [routerLink]="['../edit',inspection.ContractId,inspection.Id]">{{inspection.Title}}</a>
                    </td>
                    <td>
                        <span>{{inspection.Deviations}}</span>
                    </td>
                    <td>
                        <span>{{inspection.InspectionStatustext | translate}}</span>
                    </td>
                    <td>
                        <span>{{inspection.ResponsibleUserName}}</span>
                    </td>
                    <td>
                        <span>{{inspection.FollowupUserName}}</span>
                    </td>
                    <td>
                        <span>{{inspection.Deadline | date : 'dd.MM.yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{inspection.DoneOn | date : 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="col-button" *ngIf="isGuestHideButton">
                        <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                            (click)="confirm(inspection.Id)">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-task>
                <tr>
                    <td colspan="10">
                        <div>
                            <!-- Table starts -->
                            <p-table [value]="task.InspectionSubTaskList" [rows]="5">
                                <!-- Header -->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>{{'SUB_TASKS'|translate}}</th>
                                        <th>{{'STATUS'|translate}}</th>
                                        <th>{{'INTERVAL'|translate}}</th>
                                    </tr>
                                </ng-template>
                                <!-- Body Content -->
                                <ng-template pTemplate="body" let-row>
                                    <tr>
                                        <td>
                                            <span>{{row.Name}}</span>
                                        </td>
                                        <td>
                                            <span>{{row.StatusText | translate}}</span>
                                        </td>
                                        <td>
                                            <span>{{row.IntervalText | translate}}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                                <!-- Empty message -->
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                        <td colspan="3">
                                            {{'NO_RECORDS_FOUND'|translate}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <!-- Table ends -->
                        </div>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" *ngIf="isGuestHideButton" let-columns>
                <tr>
                    <td colspan="11">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="10">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            <!-- Footer -->
            <ng-template pTemplate="summary">
                <div class="footer-data">
                    <div class="symbol-descriptions">
                        <div class="symbol-desc-title">
                            <span translate>DESCRIPTION_SYMBOLS</span>
                        </div>
                        <div class="symbol-desc-items">
                            <span class="far-deadline">
                                <i class="icon ic-md icon-far-deadline"></i>
                                <span translate>MORE_DEADLINE</span>
                            </span>
                            <span class="over-deadline">
                                <i class="icon ic-md icon-over-deadline"></i>
                                <span translate>OVERDEADLINE</span>
                            </span>
                            <span class="near-deadline">
                                <i class="icon ic-md icon-near-deadline"></i>
                                <span translate>LESS_DEADLINE</span>
                            </span>
                            <span class="clear-deadline">
                                <i class="icon ic-md icon-clear-deadline"></i>
                                <span translate>DONE_STATUS</span>
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-table>
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>