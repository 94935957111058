<div class="tab-content">
    <div class="panel-tab-inner">
        <div class="table-r">
            <!-- Table starts -->
            <p-table #dt [value]="users" [rows]="10" [paginator]="true" [pageLinks]="3"
                [rowsPerPageOptions]="[5,10,20]"
                [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="Name">{{'NAME'|translate}}</th>
                        <th pSortableColumn="Username">{{'USERNAME'|translate}}</th>
                        <th>{{'ADDITIONAL_ROLE'|translate}}</th>
                        <th pSortableColumn="MobileWithCode">{{'MOBILE'|translate}}</th>
                        <th pSortableColumn="Email">{{'EMAIL'|translate}}</th>
                        <th pSortableColumn="Name">{{'ACCOUNT_OWNER'|translate}}</th>
                        <th>{{'MODULES'|translate}}</th>
                        <th width="90px">{{'OPTIONS'| translate}}</th>
                    </tr>
                    <tr>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Username', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <p-dropdown width='118px' [options]="userRole" #responsible
                                (onChange)="onResponsiblePersonChange($event,responsible)" styleClass="p-column-filter"
                                placeholder="" [showClear]="true">
                                <ng-template let-user pTemplate="item"> {{ user.label | translate }}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'MobileWithCode', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Email', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text"
                                (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')"
                                class="p-column-filter">
                        </th>
                        <th>
                            <p-dropdown width='118px' [options]="modules" #department
                                (onChange)="onDepartmentChange($event,department)" styleClass="p-column-filter"
                                placeholder="" [showClear]="true">
                                <ng-template let-feature pTemplate="item"> {{ feature.label | translate }}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-users>
                    <tr>
                        <td>
                            <span pTooltip="{{users.Name}}" tooltipPosition="top">{{users.Name}}</span>
                        </td>
                        <td>
                            <span>{{users.Username}}</span>
                        </td>
                        <td>
                            <span>{{users.RoleId}}</span>
                        </td>
                        <td>
                            <span>{{users.MobileWithCode}}</span>
                        </td>
                        <td>
                            <span>{{users.Email}}</span>
                        </td>
                        <td>
                            <span>{{users.Name}}</span>
                        </td>
                        <td>
                            <span>{{users.FeatureId | module}}</span>
                        </td>
                        <td>
                            <a [routerLink]="['../../user/edit',users.Id]" class="btn btn-icon"
                                title="{{'EDIT'|translate}}" routerLinkActive="active">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </a>
                            <button type="button" class="btn btn-icon" (click)="deleteUser(users.Id)"
                                title="{{'DELETE'|translate}}">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table ends -->
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>