import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { UserRole } from './../../follow-ups/models/deviation';
import { BusinessPortalService } from './../services/business-portal.services';
import { FeaturesComponent } from './../../features/components/features.component';
import { FeatureBase, Feature } from './../../features/models/features-base.model';
import { BusinessPortalEdit } from './../models/business-portal-edit.model';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FeatureKey } from 'src/app/_models/feature';
import { Subscription } from 'rxjs';

@Component({
    selector: 'portal-features',
    templateUrl: 'portal-features.component.html'
})

export class PortalFeaturesComponent implements OnInit {
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    @ViewChild(FeaturesComponent, {static: false}) featuresComponent: FeaturesComponent;
    featureBase: FeatureBase;
    businessPortal: BusinessPortalEdit;
    isPortal: boolean;
    hideNewButton = true;
    private subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private businessPortalService: BusinessPortalService,
        protected location: Location) {

    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
            this.hideNewButton = false;
        }
        this.featureBase = BaseServices.getUserFeatures();
        let featureBaseClone = BaseServices.getUserFeatures();
        let template = <any>JSON.parse(featureBaseClone.FeaturesTemplate!);
        this.featureBase.FeaturesTemplate = JSON.stringify(
            template.filter((x: any) => (x.navigationRoles.indexOf('P') > -1 && x.IsFeature.indexOf('F') > -1))
        );
        this.businessPortal = new BusinessPortalEdit();
        this.isPortal = (+BaseServices.roleId === UserRole.PORTAL) ? true : false;
        let portalDetails = this.activatedRoute.snapshot.parent!.data['portalDetails'];
        this.businessPortal = portalDetails;
        this.featureBase.Features = portalDetails.Features;
        this.featureBase.Type = 'portal';
        this.featureBase.Details = this.businessPortal;
    }

    saveFeature() {
        let features = <Feature[]>this.featuresComponent.features;
        let myPageFeatures = features.filter(x => { return x.id == FeatureKey.MY_PAGE; })[0];
        let employeeFeatures: any = null;
        let defaultFeatures = null;
        let conFalse: any = false;
        if(this.featuresComponent.filterEmployeeKey){
            employeeFeatures = this.featuresComponent.filterEmployeeKey[0];                 
            defaultFeatures = this.featuresComponent.filterEmployeeKey[1];
        }
        if (myPageFeatures && employeeFeatures) {
            if (myPageFeatures.id == FeatureKey.MY_PAGE && employeeFeatures.id == FeatureKey.EMPLOYEES) {
                employeeFeatures.checked = myPageFeatures.checked;
                employeeFeatures.access = myPageFeatures.access;
            }
            myPageFeatures.children.forEach(mypage => {
                employeeFeatures.children.forEach((emp:any) => {
                    if (emp.id === FeatureKey.EMPLOYEE_COURSE_DETAILS && mypage.id === FeatureKey.COURSE_DETAILS) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conFalse;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_SICKNESS_ABSENCE && mypage.id === FeatureKey.SICKNESS_ABSENCE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conFalse;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_LEAVE && mypage.id === FeatureKey.LEAVE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conFalse;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_TIMETABLE && mypage.id === FeatureKey.TIME_TABLE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conFalse;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_FIRST_OF_KIN && mypage.id === FeatureKey.FIRST_OF_KIN) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conFalse;
                    }
                    else if (emp.id === FeatureKey.EMPLOYEE_WORK_EXPERIENCE && mypage.id === FeatureKey.WORK_EXPERIENCE) {
                        emp.checked = mypage.checked;
                        emp.access = mypage.access;
                        return conFalse;
                    }
                })
            })
            if(defaultFeatures){
                defaultFeatures.access = true;
                defaultFeatures.checked = true;
            }
            features.push(employeeFeatures, defaultFeatures);
        }      
        features = this.setCheckedValue(features);
        this.businessPortal.Features = `${JSON.stringify(features)}`;
        this.businessPortal.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(this.businessPortalService.updatePortalFeature(this.businessPortal.Id, this.businessPortal)
            .subscribe((isUpdate) => {
                if (isUpdate) { this.toasterComponent.callToast(); }
            }));
    }

    setCheckedValue(featuresTemplate: Feature[]) {
        _.each(featuresTemplate, (data) => {
            data.checked = (data.checked == null) ? true : data.checked;
            if (BaseServices.roleId === '1') {
                data.access = (data.checked == null) ? true : data.checked;
            }
            if (data.children && data.children.length > 0) {
                this.setCheckedValue(data.children);
            }
            if (BaseServices.roleId === '1' || BaseServices.roleId === '2') {
                data.checked = (data.IsDefault) ? true : data.checked;
            }
        });
        return featuresTemplate;
    }
    gotoList() {
        this.router.navigate(['./../../../list'], { relativeTo: this.activatedRoute });
    }
    /**
     * get back the location
     */
    goBackLocation() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
