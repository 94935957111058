import { HelperService } from './../../../_services/helper.service';
import { environment } from 'src/environments/environment';
import { UploaderConfig } from './../../../shared/ecpl-document-explorer/uploader-config';
//#region import
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem } from 'primeng/api';
import { Options } from './../../../_models/options';
import {
    ServiceAgreement,
    ServiceClientUsers,
    ServiceUsers,
    User,
    BusinessProperty
} from './../models/service-agreement';
import { BaseServices } from './../../kuba.services';
import { ServiceAgreementServices } from './../services/service-agreement.service';
import { TranslateService } from '@ngx-translate/core';
import {
    UploadOutput,
    UploadInput,
    UploadFile,
    UploadStatus
} from 'ngx-uploader';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'service-agreement-form',
    templateUrl: 'service-agreement-form.component.html'
})
export class ServiceAgreementFormComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    contractorName: string;
    serviceForm: FormGroup;
    contracterId: number;
    serviceUsers: User[];
    clients: SelectItem[];
    serviceClientUsers: ServiceClientUsers[];
    selectedUser: any[];
    selectedClient: any[];
    serviceList: SelectItem[];
    businessPropertyId: number;
    options = [
        new Options(7, 'New'),
        new Options(8, 'Open'),
        new Options(9, 'Done'),
        new Options(10, 'Rejected')
    ];
    upConfig: UploaderConfig;
    uploadedFiles = [];
    additionalData: any;
    newDocumentAdded: any = [];
    isFileChoosed = false;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    dragOver: boolean;
    ContractorImg: string;
    public selectedFiles;
    uploadStart: boolean;
    bindAllUsers = false;
    bindSelectedUsers = true;
    userSubscribers: SelectItem[];
    selectedSubscribers: SelectItem[];
    emailUsers: any[];
    selectedEmailSubscriber = [];
    selectAllEmailSubscriber = [];
    userId: number;
    checkedAll:boolean;
    emailSubscriber = [];
    SAUsers: any[];
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private serviceAgreementServices: ServiceAgreementServices,
        private fb: FormBuilder,
        private location: Location,
        private http: HttpClient,
        private translate: TranslateService
    ) {
        this.uploadInput = new EventEmitter<UploadInput>();
        this.serviceForm = this.fb.group({
            ContractNumber: ['', Validators.required],
            ContractName: ['', Validators.required],
            Address: [''],
            City: [''],
            Zip: [''],
            Status: ['2'],
            ClientId: [],
            Comments: [''],
            ContractImage: [''],
            Client: [],
            ChooseProject: [],
            CopySJA: [''],
            ContactPerson: [[]],
            ServiceUser: [[]],
            ServiceClientUsers: [[]],
            IsShowDeviationChecked: [false],
            selectedEmailSubscriber: [''],
            selectAllEmailSubscriber: ['']
        });
        this.userId = BaseServices.UserId;
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.contracterId = this.route.snapshot.params['sId'];
        let serviceId = +this.contracterId;
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ParentId: serviceId ? serviceId : 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: this.translate.instant('SERVICE_AGREEMENT_DOCUMENT'),
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/serviceAgreement/file-upload',
            addtionalData: this.additionalData
        };
        this.bindFormDropdowns();
        this.selectedSubscribers = [];
        this.userSubscribers = [];
        let userSubscription = this.route.snapshot.data[
            'users_subscription'
        ];
        this.emailUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: { Id: element.Id }
                    });
                    if (element.Id == BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        }));
        let res = this.route.snapshot.data['edit'];
        if (res) {
            this.businessPropertyId = res.BusinessPropertyDto.Id;
            this.initForm(res);
        } else {
            this.initForm();
        }
    }

    /**
     * file upload events
     * @param event {any}
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.type;
            switch (eventType) {
                case 'remove':
                    this.uploadedFiles = this.uploadedFiles.filter(
                        x => x.id !== +event.id
                    );
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let serviceDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    serviceDoc.id = 0;
                    serviceDoc.createdBy = BaseServices.UserId;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(serviceDoc, keyBindings)
                    );
                    break;
            }
        }
    }

    onClientChanged(event, clientDropdown) {
        this.getContactByClientId(event.value);
    }

    getContactByClientId(clientId: number) {
        if (!clientId) {
            clientId = 0;
        }
        this.subscriptions.push(this.serviceAgreementServices
            .getActiveClientContactUser(clientId)
            .subscribe((result: any) => {

                if (result) {
                    this.serviceClientUsers = [];
                    result.forEach(element => {
                        let user = new ServiceClientUsers();

                        user.Name = element.Name;
                        user.ClientUserId = element.ClientUserId;
                        user.ClientId = element.ClientId;
                        user.ServiceId = this.contracterId ? this.contracterId : 0;
                        user.Id = element.ClientUserId;

                        this.serviceClientUsers.push(user);
                    });
                }
            }));
    }
    /**
     * save and create new button click event
     */
    saveAndCreateNew() {
        this.saveChanges();
        this.clear();
    }

    saveChanges() {
        let userId = BaseServices.UserId;
        let name = BaseServices.userName;
        let checkUserId = this.selectedUser.filter(x => x.Id == userId);
        if (checkUserId.length === 0) {
            this.selectedUser.push({ Id: userId, Name: name });
        }
        if (this.serviceForm.valid) {
            let service = new ServiceAgreement();
            service.Id = this.contracterId ? this.contracterId : 0;
            service.ContractNumber = this.serviceForm.value.ContractNumber;
            service.ContractName = this.serviceForm.value.ContractName;
            service.ClientId = this.serviceForm.value.ClientId;
            service.ContractImage = this.ContractorImg;
            service.ShowDeviation = !this.serviceForm.value.IsShowDeviationChecked;
            // tslint:disable-next-line:radix
            if (this.serviceForm.value.Status === '') {
                service.Status = '2';
            } else {
                service.Status = this.serviceForm.value.Status;
            }
            service.CreatedOn = new Date();
            service.CreatedBy = BaseServices.UserId;
            service.BusinessId = BaseServices.BusinessId;
            service.Comments = this.serviceForm.value.Comments;
            let businessProperty = new BusinessProperty();
            businessProperty.Id = this.businessPropertyId
                ? this.businessPropertyId
                : 0;
            businessProperty.Address = this.serviceForm.value.Address
                ? this.serviceForm.value.Address
                : '';
            businessProperty.City = this.serviceForm.value.City;
            businessProperty.Zip = this.serviceForm.value.Zip;
            businessProperty.CreatedOn = new Date();
            businessProperty.CreatedBy = BaseServices.UserId;
            businessProperty.BusinessId = BaseServices.BusinessId;
            businessProperty.Status = '1';
            service.BusinessPropertyDto = businessProperty;
            service.BusinessPropertyId = businessProperty.Id;
            service.ServiceAgreementDocument = this.newDocumentAdded;
            if ((this.bindSelectedUsers) && this.serviceForm.value.selectedEmailSubscriber) {
                service.SAEmailSubscribers = [];
                this.emailSubscriber = this.serviceForm.value
                    .selectedEmailSubscriber;
            }
            if ((this.bindAllUsers) && this.serviceForm.value.selectAllEmailSubscriber) {
                service.SAEmailSubscribers = [];
                this.emailSubscriber = this.serviceForm.value.selectAllEmailSubscriber;
            }
            service.ServiceUsers = [];
            if (this.selectedUser) {
                // tslint:disable-next-line:no-shadowed-variable
                this.selectedUser.forEach(element => {
                    let user = new ServiceUsers();
                    user.ServiceId = this.contracterId ? this.contracterId : 0;
                    user.Name = element.Name ? element.Name : '';
                    user.UserId = element.Id;
                    user.IsEmail = this.emailSubscriber.find(x => x.Id == element.Id) ? true : false
                    service.ServiceUsers.push(user);
                });
            }
            service.ServiceClientUsers = [];
            if (this.selectedClient) {
                // tslint:disable-next-line:no-shadowed-variable
                this.selectedClient.forEach(element => {
                    let user = new ServiceClientUsers();
                    user.ServiceId = this.contracterId ? this.contracterId : 0;
                    user.Name = element.Name;
                    user.ClientId = element.ClientId;
                    user.ClientUserId = element.Id;
                    user.ClientId = element.ClientId;
                    service.ServiceClientUsers.push(user);
                });
            }
            else if (service.ClientId) {
                let client = new ServiceClientUsers();
                client.ServiceId = this.contracterId ? this.contracterId : 0;
                client.ClientId = service.ClientId;
                service.ServiceClientUsers.push(client);
            }

            if (service.Id > 0) {
                service.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.serviceAgreementServices
                    .update(service.Id, service)
                    .subscribe(result => {
                        this.toasterComponent.callToast();
                    }));
            } else {
                this.subscriptions.push(this.serviceAgreementServices.add(service).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                    this.gotoBack();
                }));
            }
        }
    }
    filesSelect(selectedFiles: any): void {
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/serviceAgreement/file/upload/contractor-image/' +
                BaseServices.ApplicationId +
                '/' +
                0,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response;
                this.ContractorImg = res.Result.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }
    /**
     * clear form data
     */
    clear() {
        this.initForm();
        this.contracterId = 0;
        this.uploadedFiles = [];
    }

    initForm(data?: any) {
        let contractNumber = '';
        let ContractName = '';
        let address = '';
        let city = '';
        let zip = '';
        let Comments = '';
        let chooseProject = '';
        let client = '';
        let selectedClient = [];
        let selectedUser = [];
        let status = '';
        let copySJA = '';
        let IsShowDeviationChecked = false;
        let selectedEmailSubscriber = this.selectedEmailSubscriber;
        let selectAllEmailSubscriber = this.selectAllEmailSubscriber;
        if (data) {
            if (data.ServiceAgreementDocument) {
                data.ServiceAgreementDocument.forEach(element => {
                    this.uploadedFiles.push(
                        HelperService.switchKeysCase(element, null, 'L')
                    );
                });
            }
            ContractName = data.ContractName;
            contractNumber = data.ContractNumber;
            address = data.BusinessPropertyDto
                ? data.BusinessPropertyDto.Address
                : '';
            city = data.BusinessPropertyDto
                ? data.BusinessPropertyDto.City
                : '';
            zip = data.BusinessPropertyDto ? data.BusinessPropertyDto.Zip : '';
            this.businessPropertyId = data.BusinessPropertyId;
            Comments = data.Comments;
            IsShowDeviationChecked = !data.ShowDeviation;
            client = data.ClientId;
            this.getContactByClientId(+client);
            status = data.Status;
            copySJA = data.CopySJA;
            this.selectedUser = [];
            this.ContractorImg = data.ContractImage;
            if (data.ServiceUsers) {
                // tslint:disable-next-line:no-shadowed-variable
                data.ServiceUsers.forEach(element => {
                    let selectedUsers = this.SAUsers.find(x => x.Id === element.UserId);
                    if (selectedUsers) {
                        selectedUsers.status = true;
                        selectedUsers.isEmail = false;
                        this.checked(selectedUsers)
                        if (element.IsEmail) {
                            this.selectedEmailSubscriber.push({
                                Id: element.UserId
                            });
                            this.selectAllEmailSubscriber.push({
                                Id: element.Id

                            });
                        }
                    }
                });
            }
            this.selectedClient = [];
            if (data.ServiceClientUsers) {
                // tslint:disable-next-line:no-shadowed-variable
                data.ServiceClientUsers.forEach(element => {
                    this.selectedClient.push({
                        Id: element.ClientUserId,
                        Name: element.Name,
                        CCId: element.Id,
                        ClientId: element.ClientId
                    });
                });
            }

            this.serviceForm = this.fb.group({
                ContractNumber: new FormControl(contractNumber),
                ContractName: new FormControl(ContractName),
                Address: new FormControl(address),
                City: new FormControl(city),
                Zip: new FormControl(zip),
                Comments: new FormControl(Comments),
                IsShowDeviationChecked: new FormControl(IsShowDeviationChecked),
                ClientId: new FormControl(client),
                Status: new FormControl(status),
                selectedEmailSubscriber: new FormControl(selectedEmailSubscriber),
                selectAllEmailSubscriber: new FormControl(selectAllEmailSubscriber)
            });
        } else {
            this.serviceForm = this.fb.group({
                ContractNumber: new FormControl(contractNumber),
                ContractName: new FormControl(ContractName),
                Address: new FormControl(address),
                City: new FormControl(city),
                Zip: new FormControl(zip),
                Comments: new FormControl(Comments),
                IsShowDeviationChecked: new FormControl(IsShowDeviationChecked),
                ClientId: new FormControl(client),
                Status: new FormControl('7'),
                selectedEmailSubscriber: new FormControl(selectedEmailSubscriber),
                selectAllEmailSubscriber: new FormControl(selectAllEmailSubscriber)
            });
        }
    }
    bindFormDropdowns() {
        let users = this.route.snapshot.data['users'];
        this.serviceUsers = users;
        this.SAUsers = users;
        this.selectedUser = [];
        this.selectedClient = [];

        this.serviceList = [];
        let serviceList = this.route.snapshot.data['serviceList'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.serviceList = [];
            this.serviceList.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (serviceList.Count > 0) {
            // tslint:disable-next-line:no-shadowed-variable
            serviceList.forEach(element => {
                this.serviceList.push({
                    label: element.ContractName,
                    value: element.Id
                });
            });
        }

        this.clients = [];
        let Clients = this.route.snapshot.data['clients'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.clients = [];
            this.clients.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (Clients) {
            // tslint:disable-next-line:no-shadowed-variable
            Clients.forEach(element => {
                this.clients.push({ label: element.Name, value: element.Id });
            });
        }
    }

    /**
     * upload event
     */
    startUpload(): void {
        const event: UploadInput = {
            type: 'uploadAll',
            url:
                'http://localhost:53145/api/kuba/v1/serviceAgreement/file/upload/contractor-image/' +
                BaseServices.ApplicationId +
                '/' +
                0,
            method: 'POST',
            data: { foo: 'bar' }
        };
        this.uploadInput.emit(event);
    }

    /**
     * on upload event
     * @param output
     */
    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (
            output.type === 'addedToQueue' &&
            typeof output.file !== 'undefined'
        ) {
            this.isFileChoosed = true;
            this.files.push(output.file);
        } else if (
            output.type === 'uploading' &&
            typeof output.file !== 'undefined'
        ) {
            const index = this.files.findIndex(
                file =>
                    typeof output.file !== 'undefined' &&
                    file.id === output.file.id
            );
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter(
                (file: UploadFile) => file !== output.file
            );
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'done') {
            this.ContractorImg = output.file.response.Result.path;
        } else if (
            output.type === 'rejected' &&
            typeof output.file !== 'undefined'
        )

        this.files = this.files.filter(
            file => file.progress.status !== UploadStatus.Done
        );
    }

    /**
     * mobile number validation
     * @param e {any}
     * @param limitNumber {any}
     */
    onContractNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 && (charCode < 48 || charCode > 57))
        ) {
            e.preventDefault();
        }
    }

    gotoBack() {
        this.location.back();
    }

    checked(value) {
        if (value.status) {
            this.selectedUser.push(value);
            if (this.emailUsers) {
                let selectedUsers = this.emailUsers.find(x => x.Id === value.Id);
                this.emailUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.isEmail)) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedUser, (val) => {
                if (this.userSubscribers) {
                    _.forEach(this.userSubscribers, (item: any) => {
                        if (item.value.Id == value.Id) {
                            this.userSubscribers.pop();
                        }
                    });
                }
                return val === value;
            })

        }
    }

    checkAll(event) {
        _.forEach(this.serviceUsers, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status =
                    // to remain checked state for current user
                    (this.userId === +item.Id) ? true : false;
            }
        });

        this.selectedUser = this.serviceUsers;
        if (!event) {
            this.selectedUser = [];
            this.bindAllUsers = false;
            this.bindSelectedUsers = true;
        }
        else {
            this.bindAllUsers = true;
            this.bindSelectedUsers = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
