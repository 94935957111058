import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ElectroFeature } from '../models/electrofeatures';

@Component({
    selector: 'electro-view',
    templateUrl: 'electro-view.component.html'
})

export class ElectroViewComponent implements OnInit {

    pageTitle: string;
    enabledAdditionalFeatures: any;
    pId: number;
    enabledContractFeatures: any;
    contractName: string;
    clientName:string;
    clientLogo:string;
    private subscriptions: Subscription[] = [];
    @ViewChild('clilogo',{static: false}) clilogo :ElementRef;
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.subscriptions.push(route.params.subscribe(val => {}));
        
    }

    ngOnInit() {
        this.pId = this.route.snapshot.params['cid'];
        this.contractName = " - " + localStorage.getItem('contractName');
        this.clientName = localStorage.getItem('clientName')+"  ";
        this.clientLogo = localStorage.getItem('clientLogo');
        //  Live issue fixes 
        // this.clilogo.nativeElement;
        this.enabledContractFeatures = this.route.snapshot.data['enabledFeatures'];
        let enabledFeatures: number[];
        enabledFeatures = [];
        enabledFeatures.push(ElectroFeature.EDIT_PRINT_MANUAL);
        if (sessionStorage.getItem('workingModule') && (JSON.parse(sessionStorage.getItem('workingModule')).name === 'Electro')) {

            let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
            this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => (element.checked || element.IsDefault)
                && element.navigationRoles.indexOf('B') > -1);

                //filter the features enabled for a contract.
            if (this.enabledContractFeatures ? this.enabledContractFeatures.EnabledManuals : false) {
                (JSON.parse(this.enabledContractFeatures.EnabledManuals)).forEach(m => {
                    if (!(enabledFeatures.indexOf(m.featureId) > -1)) {
                        enabledFeatures.push(+m.featureId);
                    }
                });
            }
            this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(element => enabledFeatures.indexOf(element.id) > -1);
            if (this.enabledAdditionalFeatures.length > 0) {
                this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.pId }],
                    { relativeTo: this.route });
            }
        }
     }

     ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}