import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    public transform(value: any[], keys: string, term: string, startDateValue: string, endDateValue: string) {
        if (term) {
            let searchKey = keys.split(',');
            value = (value || []).filter((item) => keys.split(',')
                .some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
        }

        if (startDateValue && endDateValue) {
            let startDateValueSplit = startDateValue.split('/');
            let endDateValueSplit = endDateValue.split('/');
            startDateValue = startDateValueSplit[1] + '/' + startDateValueSplit[0]
                + '/' + startDateValueSplit[2];
            endDateValue = endDateValueSplit[1] + '/' + endDateValueSplit[0]
                + '/' + endDateValueSplit[2];
            let startDate = new Date(startDateValue).getTime();
            let endDateTemp = new Date(endDateValue);
            endDateTemp.setDate(endDateTemp.getDate() + 1);
            let endDate = endDateTemp.getTime();
            value = _.filter(value, function (o) {
                let oDate = new Date(o.createdOn.replace('T', ' ')).getTime();
                return oDate >= startDate && oDate <= endDate;
            });
        }

        return value;
    }
}

@Pipe({
    name: 'searchlist'
})
export class SearchListPipe implements PipeTransform {
    public transform(value: any[], keys: string, term: string) {
        if (!term) {
            return value
        };
        return (value || []).filter((item) => keys.split(',')
            .some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    }
}

@Pipe({
    name: 'searchByUser'
})
export class SearchByUserPipe implements PipeTransform {
    public transform(value: any[], key: any) {
        if (key) {
            let result = value.filter(x => x.createdBy === key);
            return result;
        }
        return value;
    }
}
