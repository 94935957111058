import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
@Component({ 
    template: `<notifications></notifications>`
})
export class NotificationComponent implements OnInit {
    constructor(private http: HttpClient) { }

    ngOnInit(): void {
        
    }
}