import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { CountryCode } from './../Models/countryCode';
import { SupplierContact } from './../Models/supplierContact';
import { ConfirmationService } from 'primeng/api';
import { SupplierServices } from './../Services/supplier.services';
import { SupplierList } from './../Models/supplierList';
import * as _ from 'lodash';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators
} from '@angular/forms';
import { AppSharedModule } from './../../shared/appShared.module';
import { SelectItem } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'supplier-form',
    templateUrl: 'supplierForm.component.html'
})
export class SupplierFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    newSupplier: FormGroup;
    newsupplierContact: FormGroup;
    supplierdata: any;
    supplierContId: any;
    isHidden = false;
    foodSafetyId: number;
    suppContact: any;
    supplierContactList: SupplierContact[];
    supplierId: any;
    supplier: any;
    mobile: SelectItem[];
    receptionId: number;
    mobilecode: any;
    contacttype: SelectItem[];
    isSupplierAdded = false;
    isSupplierExists: boolean;
    private subscriptions: Subscription[] = [];
    constructor(
        private supplierservice: SupplierServices,
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        private route: ActivatedRoute,
        private location: Location,
        private confirmationService: ConfirmationService,
        private router: Router,
        private translate: TranslateService
    ) {
        this.newSupplier = this.formBuilder.group({
            Name: ['', [Validators.required], this.onSupplierExits.bind(this)],
            Number: [''],
            SupplierType: ['', Validators.required],
            OrgNumber: [''],
            Address: [''],
            Zip: [''],
            Location: [''],
            Phonecode: [47],
            Mobile: [''],
            Telephone: [''],
            Email: [
                '',
                [Validators.required, ValidationService.emailValidator]
            ],
            Comment: ['']
        });
        this.newsupplierContact = this.formBuilder.group({
            Name: ['', Validators.required],
            Mobile: [''],
            Email: [
                '',
                [Validators.required, ValidationService.emailValidator]
            ],
            IsSms: [''],
            IsEmail: [''],
            Phonecode: [47]
        });
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.receptionId = +this.route.snapshot.parent!.queryParams['reception'];
        if (this.receptionId != null) {
            this.isHidden = false;
        } else {
            this.isHidden = true;
        }
        this.foodSafetyId = +this.route.snapshot.parent!.queryParams[
            'foodsafety'
        ];
            let sid = this.route.snapshot.params['supplierId'];
            this.supplierId = (sid) ? +sid : 0;
            if (this.supplierId > 0) {
                this.isSupplierAdded = true;
                this.supplierContactList = <SupplierContact[]>this.route
                    .snapshot.data['contactlist'];
                let result = this.route.snapshot.data['detail'];
                if (result) {
                    this.supplier = {
                        Name: result.Name,
                        SupplierType: result.SupplierTypeId,
                        Mobile: result.Mobile,
                        Telephone: result.Telephone,
                        Email: result.Email,
                        Number: result.Number,
                        OrgNumber: result.OrgNumber,
                        Address: result.Address,
                        Zip: result.Zip,
                        Location: result.Location,
                        Comment: result.Comment,
                        Phonecode: +result.CountryCode
                    };
                    (<FormGroup>this.newSupplier).setValue(this.supplier, {
                        onlySelf: true
                    });
                }
            } else {
                this.supplierContactList = [];
            }

        this.mobile = [];
        let coutryCodes = this.route.snapshot.data['countryCode'];
        if (coutryCodes) {
            coutryCodes.forEach((element: any) => {
                this.mobile.push({
                    label: '+' + element.Phonecode,
                    value: '+' + element.Phonecode
                });
            });
        }

        this.contacttype = [];
        this.subscriptions.push(this.translate.stream('CLIENT_TYPES').subscribe(val =>{
        this.contacttype = [];
            this.contacttype.push(
                { label: val.PERSON, value: 1},
                { label: val.COMPANY, value: 2}
            );
        }));
    }
    saveSupplier(): any {
        this.isSupplierAdded = true;
        if (this.newSupplier.valid) {
            let supplier = new SupplierList();
            supplier.Id = this.supplierId ? this.supplierId : 0;
            supplier.SupplierTypeId = this.newSupplier.value.SupplierType;
            supplier.Name = this.newSupplier.value.Name;
            supplier.Number = this.newSupplier.value.Number;
            supplier.OrgNumber = this.newSupplier.value.OrgNumber;
            supplier.Address = this.newSupplier.value.Address;
            supplier.Zip = this.newSupplier.value.Zip;
            supplier.Location = this.newSupplier.value.Location;
            supplier.Mobile = this.newSupplier.value.Mobile.toString();
            supplier.CountryCode = this.newSupplier.value.Phonecode.toString();
            supplier.Telephone = this.newSupplier.value.Telephone;
            supplier.Email = this.newSupplier.value.Email;
            supplier.Comment = this.newSupplier.value.Comment;
            supplier.Status = '1';
            supplier.BusinessId = BaseServices.BusinessId;
            if (this.supplierId > 0) {
                this.subscriptions.push(this.supplierservice.update(supplier).subscribe(result => {
                    this.toasterComponent.callToast();
                }));
            } else {
                this.subscriptions.push(this.supplierservice.add(supplier).subscribe((result:any) => {
                    if(result){
                        this.supplierId = (result) ? result.Id : 0;
                        this.toasterComponent.callToast();
                    }
                }));
            }
        }
    }

    /**
     * number text box event
     * @param e
     * @param limitNumber
     */
    onNumberChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 && (charCode < 48 || charCode > 57))
        ) {
            e.preventDefault();
        }
    }
    /**
     * email change event
     */
    onEmailChange(e: any) {
        let charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && charCode < 48 && charCode !== 46) {
            e.preventDefault();
        }
    }
    getSupplierContact() {
        this.subscriptions.push(this.supplierservice
            .getSupplierContactListById(this.supplierId)
            .subscribe(result => (this.supplierContactList = result)));
    }

    /**
     * form reset
     */
    clear() {
        this.supplierContId = 0;
        this.newsupplierContact.reset();
    }

    editSupplierContact(supplierContactId: number) {
        this.subscriptions.push(this.supplierservice
            .getSupplierContactById(supplierContactId)
            .subscribe((result:any) => {
                this.supplierContId = supplierContactId;
                if (result) {
                    this.supplierdata = {
                        Name: result.Name,
                        Mobile: result.Mobile,
                        Email: result.Email,
                        IsSms: result.IsSms,
                        IsEmail: result.IsEmail,
                        Phonecode: result.CountryCode
                    };
                    (<FormGroup>this.newsupplierContact).setValue(
                        this.supplierdata,
                        { onlySelf: true }
                    );
                }
            }));
    }

    saveSupplierContact() {
        if (this.newsupplierContact.valid) {
            let suppliercontact = new SupplierContact();
            suppliercontact.Id = this.supplierContId ? this.supplierContId : 0;
            suppliercontact.SupplierId = this.supplierId;
            suppliercontact.Name = this.newsupplierContact.value.Name;
            suppliercontact.Mobile = this.newsupplierContact.value.Mobile;
            suppliercontact.Email = this.newsupplierContact.value.Email;
            suppliercontact.IsSms = this.newsupplierContact.value.IsSms;
            suppliercontact.IsEmail = this.newsupplierContact.value.IsEmail;
            suppliercontact.Status = '1';
            suppliercontact.CountryCode = this.newsupplierContact.value.Phonecode;
            if (this.supplierId > 0) {
                if (suppliercontact.Id > 0) {
                    this.subscriptions.push(this.supplierservice
                        .updateSupplierContact(suppliercontact)
                        .subscribe(result => {
                            this.toasterComponent.callToast();
                            this.getSupplierContact();
                        }));
                } else {
                    this.saveContact(suppliercontact);
                }
            } else {
                this.supplierContactList.push(suppliercontact);
            }
        }
        this.clear();
    }

    saveContact(suppliercontact) {
        this.subscriptions.push(this.supplierservice
            .addSupplierContact(suppliercontact)
            .subscribe(result => {
                this.toasterComponent.callToast();
                this.getSupplierContact();
            }));
    }
    saveAndBackToReception() {
        if (this.saveSupplier()) {
            setTimeout(() => {
                this.router.navigate(
                    [
                        '../../foodsafety/details/' +
                        this.foodSafetyId +
                        '/reception-control/edit/',
                        this.receptionId
                    ],
                    { relativeTo: this.route }
                );
            }, 1000);
        }
    }
    gotoList() {
        this.location.back();
    }

    deleteConfirm(supplierContactList:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.supplierservice
                    .deleteSupplierContact(supplierContactList)
                    .subscribe(result => {
                        this.toasterComponent.callToastDlt();
                        this.getSupplierContact();
                    }));
            }
        });
    }
    onSupplierExits(control: FormControl) {
        const q = new Promise((resolve) => {
            if(this.supplierId == 0) {
                if (control.value) {
                    this.subscriptions.push(this.supplierservice.checkSupplierName(control.value).subscribe((x) => {
                        if (!x) {
                            resolve(null);
                        } else {
                            resolve({ 'isSupplierNameUnique': true });
                        }
                    }, () => { resolve({ 'isSupplierNameUnique': true }); }));
                }
            }
            else {
                resolve(null);
            }
        });
        return q;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
