import { ActivitySchedule } from './../../follow-ups/models/activities';
import { ActivityAttachment } from './taskplan';
export class Safetyjob {
  Id: number;
  ProjectId: number;
  RiskFigure: any;
  Topics: number;
  Description: string;
  TypeofIssue: string;
  Status: number;
  Location: string;
  CreatedOn: string;
  EditedBy: string;
  GPSLocation: string;
  Title: string;
  Consequence: number;
  RelatedIncident: string;
}


