<div class="container">
  <div class="header-top-container row justify-content-between">
    <div class="d-flex align-items-center">
      <i class="btn-toggle-menu hamburger-header pi pi-bars"> </i>
      <branding></branding>
    </div>
    <div class="col-auto d-flex align-items-center"></div>
    <div class="col-auto d-flex align-items-center">
      <!--Languages directive-->
      <div class="language-wrapper">
        <languages></languages>
      </div>
      <div class="top-links d-flex align-items-center h-100">
        <div class="top-link-item hse-course">
          <button
            class="btn btn-primary px-2 py-1"
            (click)="isMedi3 ? openUrlInNewTabMed() : openUrlInNewTab()"
          >
            <span>{{ "HSE_COURSE" | translate }}</span>
          </button>
        </div>

        <div class="top-link-item kb-quick-help" *ngIf="!userrole">
          <!--Quick Help-->
          <quick-help></quick-help>
        </div>
        <div class="top-link-item kb-notification">
          <!--Notification-->
          <notification></notification>
        </div>
        <div class="top-link-item profile">
          <!--User Profile-->
          <user-profile></user-profile>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ 'INFORMATION' | translate }}"
  [(visible)]="isSearchEmpty"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px' }"
>
  {{ "KEYWORD_SEARCH" | translate }}
  <p-footer>
    <button
      type="button"
      class="btn btn-link"
      title="{{ 'OK' | translate }}"
      (click)="informationHide()"
      translate
    >
      {{ "OK" | translate }}
    </button>
  </p-footer>
</p-dialog>
