<div class="main-content ">
    <div class="main-heading">
        <strong> Project of - {{ pageTitle | capitalize}}</strong>
    </div>
    <ecpl-tab-navigation [linkItems]="additionalFeatures.children" [parentId]="fdvId"></ecpl-tab-navigation>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>