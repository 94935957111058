<div class="main-content">
  <div class="main-heading">
    <span translate>ADD_EDIT_SUPPLIER</span>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="newSupplier">
        <div class="row">
          <div class="col-sm-6">

            <div class="form-group">
              <label for="Type of Supplier" translate>TYPE_SUPPLIER
                <span class="required">*</span>
              </label>
              <div>
                <p-dropdown placeholder="{{'SELECT'|translate}}" [options]="contacttype"
                  formControlName="SupplierType"></p-dropdown>
              </div>
              <control-messages [control]="newSupplier.controls['SupplierType']"></control-messages>
            </div>
            <div class="form-group">
              <label for="Name" translate>NAME
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText formControlName="Name" maxLength="100" class="ex-full-width" />
              </div>
              <div>
                <span style="color:green" *ngIf="isSupplierExists" translate>Name is already
                  Available</span>
              </div>
              <control-messages [control]="newSupplier.controls['Name']"></control-messages>
            </div>
            <div class="form-group">
              <label for="Number" translate>SUPPLIER_NUMBER</label>
              <div>
                <input type="text" pInputText class="ex-full-width" onpaste="return false;"
                  (keypress)="onNumberChange($event,20)" formControlName="Number">
              </div>
            </div>
            <div class="form-group">
              <label for="OrgNumber" translate>ORG_NUMBER</label>
              <div>
                <input type="text" pInputText class="ex-full-width" onpaste="return false;"
                  (keypress)="onNumberChange($event,20)" formControlName="OrgNumber">
              </div>
            </div>
            <div class="form-group">
              <label for="Address" translate>ADDRESS</label>
              <div>
                <textarea pInputTextarea class="ex-description" maxLength="250" formControlName="Address"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label for="Zip" translate>ZIP_CODE</label>
              <div>
                <input type="text" pInputText onpaste="return false;" class="ex-full-width"
                  (keypress)="onNumberChange($event,20)" formControlName="Zip">
              </div>
            </div>
            <div class="form-group">
              <label for="Location" translate>LOCATION</label>
              <div>
                <input type="text" pInputText class="ex-full-width" maxLength="100" formControlName="Location" />
              </div>
            </div>
            <div class="form-group">
              <label for="Mobile" class="col-form-label">
                <span translate>MOBILE</span>
              </label>
              <div class="phone-field">
                <p-dropdown [options]="mobile" formControlName="Phonecode" [filter]="true" [style]="{'width':'110px'}">
                </p-dropdown>
                <input type="number" pInputText onpaste="return false;" formControlName="Mobile"
                  (keypress)="onNumberChange($event,20)">
              </div>
            </div>
            <div class="form-group">
              <label for="Telephone" translate>TELEPHONE</label>
              <div>

                <input type="text" pInputText class="ex-full-width" onpaste="return false;"
                  (keypress)="onNumberChange($event,20)" formControlName="Telephone">
              </div>
            </div>
            <div class="form-group">
              <label for="Email" translate>EMAIL
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText class="ex-full-width" formControlName="Email" maxLength="100">
              </div>
              <control-messages [control]="newSupplier.controls['Email']"></control-messages>
            </div>
            <div class="form-group">
              <label for="Comment" translate>COMMENT</label>
              <div>
                <textarea pInputTextarea class="ex-description" maxLength="250" formControlName="Comment"></textarea>
              </div>
            </div>

          </div>
             <div class="col-sm-6">
          <div *ngIf="isSupplierAdded">
            <form [formGroup]="newsupplierContact">

           
                <div>
                  <h4 translate>ADD_EDIT_SUPCONTACT</h4>
                </div>
                <div class="form-group">
                  <label for="Name" translate>NAME
                    <span class="required">*</span>
                  </label>
                  <div>
                    <input type="text" pInputText maxLength="100" formControlName="Name" class="ex-full-width" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="Mobile" class="col-form-label">
                    <span translate>MOBILE</span>
                  </label>
                  <div class="phone-field">
                    <p-dropdown [options]="mobile" formControlName="Phonecode" [filter]="true"
                      [style]="{'width':'110px'}">
                    </p-dropdown>
                    <input type="number" pInputText formControlName="Mobile" (keypress)="onNumberChange($event,20)">
                  </div>
                </div>
                <div class="form-group">
                  <label for="Email" translate>EMAIL
                    <span class="required">*</span>
                  </label>
                  <div>
                    <input type="text" pInputText class="ex-full-width" maxLength="100" formControlName="Email" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="Notification" class="col-form-label">
                    <span translate>NOTIFICATION</span>
                  </label>
                  <div>
                    <div class="checkbox">
                      <p-checkbox label="{{'BY_SMS'|translate}}" formControlName="IsSms" [binary]="true"></p-checkbox>
                    </div>
                    <div class="checkbox">
                      <p-checkbox label="{{'BY_EMAIL'|translate}}" formControlName="IsEmail"
                        [binary]="true"></p-checkbox>
                    </div>
                  </div>
                </div>
                <div class="action-btns-wrapper">
                  <button type="button" class="btn btn-primary" [disabled]="!newsupplierContact.valid"
                    (click)="saveSupplierContact()" translate>ADD</button>
                </div>
                <div class="table-view">
                  <!-- Table starts -->
                  <p-table #dt [value]="supplierContactList" [rows]="10" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]"
                    [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="Name">{{'NAME'|translate}}</th>
                        <th pSortableColumn="Mobile">{{'MOBILE'|translate}}</th>
                        <th pSortableColumn="Email">{{'EMAIL'|translate}}</th>
                        <th>{{'DELETE'|translate}}</th>
                      </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-supplier>
                      <tr>
                        <td>
                          <a href="return:void();" (click)="editSupplierContact(supplier['Id'])"
                            routerLinkActive="active">{{supplier.Name}}</a>
                        </td>
                        <td>
                          <span>{{supplier.Mobile}}</span>
                        </td>
                        <td>
                          <span>{{supplier.Email}}</span>
                        </td>
                        <td class="col-button">
                          <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                            (click)="deleteConfirm(supplier['Id'])">
                            <i class="icon ic-sm icon-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                        <td colspan="4">
                          {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <!-- Table Ends -->
                </div>
              
            </form>
          </div>
          </div>
        </div>
        <div class="action-btns-wrapper text-left">
          <div class="col-sm-3">
            <button type="button" class="btn btn-primary" (click)="saveSupplier()" [disabled]="!newSupplier.valid"
              translate>SAVE</button>
            <button type="button" class="btn btn-outline-secondary" (click)="gotoList()">
              <span class="icon ic-xs icon-back"></span>
              <span translate>BACK_LIST</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>