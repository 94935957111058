import { HelperService } from './../_services/helper.service';
import { IMyDpOptions } from 'mydatepicker';

export class DateSetting {
    defaultDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '26px',
        selectionTxtFontSize: '14px',
        disableSince: HelperService.formatInputDate((new Date()).setDate((new Date()).getDate() + 1)).date
    };

    constructor() { }
}
