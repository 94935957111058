export class FeatureBase {
    Type: string;
    Details: any;
    Features: string;
    FeaturesTemplate: string;
    UserRights: string;
    DefaultFeatures : string;
}

export class Feature {
    id: number;
    name: string;
    checked: boolean;
    access: boolean;
    isFeature: any;
    IsDefault: number;
    translationKey:string;
    children: Feature[];
}
