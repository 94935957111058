import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ContactView } from './../../contact/models/contactView';
import { EmployeeContactServices } from './employee-admin.service';

@Injectable()
export class EmployeeListResolver implements Resolve<ContactView[]> {
    constructor(private backend: EmployeeContactServices) {}
    resolve(route: ActivatedRouteSnapshot): Observable<ContactView[]> {
        return this.backend.getCustomerEmployee();
    }
}
@Injectable()
export class EmployeeWithLoginListResolver implements Resolve<ContactView[]> {
    constructor(private backend: EmployeeContactServices) {}
    resolve(route: ActivatedRouteSnapshot): Observable<ContactView[]> {
        return this.backend.getCustomerEmployeeWithLogin();
    }
}
