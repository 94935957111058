<div class="main-content">
    <div class="main-heading">
        <strong>
            <span translate>ELECTRO</span><span>{{contractName}}</span>
        </strong>
        <span class="pull-right">
            <span>{{clientName}}</span>
            <img #clilogo src="{{clientLogo}}" alt="" width="100" height="40">
        </span>
    </div>
    <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="pId"></ecpl-tab-navigation>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>