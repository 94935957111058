import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'project-new',
  templateUrl: 'project-new.component.html'
})

export class ProjectNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.querySelector("body").classList.remove("opened");
  }
} 