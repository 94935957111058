<form [formGroup]="editSafetyJobAnalysis">
    <div class="row">
        <div class="col-sm-6">
            <div class="sub-panel">
                <div class="sub-panel-title">
                    <span *ngIf="isICSJA" translate>IC_SJA</span>
                    <span *ngIf="!isICSJA" translate>SAFETY_JOB_ANALYSIS</span>
                </div>
                <div class="sub-panel-content">
                    <div class="form-group">
                        <label for="topic">
                            <span translate>TOPICS</span>
                            <span class="required">*</span>
                        </label>
                        <div class="input-btn-group">
                            <p-dropdown [options]="Topic" formControlName="TopicId" [style]="{'width':'500px'}">
                            </p-dropdown>
                            <button *ngIf="isHideGuestButton" class="btn btn-outline-primary" type="button"
                              (click)="addTopic = !addTopic">
                                <i class="pi pi-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                        <control-messages [control]="editSafetyJobAnalysis.controls['TopicId']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label for="Issue">
                            <span translate>TYPE_OF_ISSUE</span>
                            <span class="required">*</span>
                        </label>
                        <div class="input-btn-group">
                            <p-dropdown [options]="Issue" id="Issue" formControlName="IssueTypeId"
                              [style]="{'width':'500px'}">
                            </p-dropdown>
                            <button *ngIf="isHideGuestButton" class="btn btn-outline-primary" type="button"
                              (click)="addType = !addType">
                                <i class="pi pi-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                        <control-messages [control]="editSafetyJobAnalysis.controls['IssueTypeId']"></control-messages>
                    </div>
                    <div class="form-group" [hidden]="isWhatCanHappen">
                        <label for="lblWhathappend" class="col-form-label">
                            <span translate>WHAT_CAN_HAPPEN</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <textarea type="text" id="txtariaWhatHappend" pInputTextarea
                              formControlName="WhatCanHappen"> </textarea>
                        </div>
                        <control-messages [control]="editSafetyJobAnalysis.controls['WhatCanHappen']">
                        </control-messages>
                    </div>
                    <div class="form-group">
                        <label for="Location">
                            <span translate>LOCATION</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="Location" id="Location"
                              class="ex-full-width" />
                        </div>
                        <control-messages [control]="editSafetyJobAnalysis.controls['Location']"></control-messages>
                    </div>
                    <div class="form-group" [hidden]="isDescription">
                        <label for="lblDescription">
                            <span translate>DESCRIPTION</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <textarea pInputTextarea id="Description" formControlName="WhatCanHappen"
                              class="ex-description"></textarea>
                        </div>
                        <control-messages [control]="editSafetyJobAnalysis.controls['WhatCanHappen']">
                        </control-messages>
                    </div>
                    <div class="form-group">
                        <label for="figure">
                            <span translate>PROBALILITY_FIGURE</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-dropdown [options]="probability" id="figure" formControlName="Probability"
                              (onChange)="probabilityChanged($event)" placeholder="Select" [style]="{'width':'528px'}">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Consequence">
                            <span translate>CONSEQUENCE_FIGURE</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-dropdown [options]="consequence" id="Consequence" (onChange)="consequenceChanged($event)"
                              formControlName="Consequence" placeholder="Select" [style]="{'width':'528px'}">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="riskData">
                        <label for="Risk">
                            <span translate>RISK_FIGURE</span>
                        </label>
                        <div>
                            <input type="text" [style.background-color]="riskData.backColor" pInputText
                              formControlName="RiskFigure" class="ex-full-width" [value]="riskData.value | translate" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-panel">
                <div class="sub-panel-title">
                    <span translate>TASK_PLAN</span>
                </div>
                <div class="sub-panel-content">
                    <form [formGroup]="addTaskPlan">
                        <div class="form-group">
                            <label for="Title">
                                <span translate>TITLE</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="text" id="Id" formControlName="Id" hidden="true" />
                                <input type="text" pInputText id="Title" formControlName="Title"
                                  class="ex-full-width" />
                            </div>
                            <control-messages [control]="addTaskPlan.controls['Title']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label for="EndDate">
                                <span translate>DEADLINE</span>
                                <span class="required">*</span>
                            </label>
                            <my-date-picker name="startdate" [options]="startDateOptions" formControlName="EndDate"
                              placeholder="{{'SELECT_START_DATE'|translate}}" required></my-date-picker>
                        </div>
                        <div class="form-group">
                            <label for="Execution">
                                <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-multiSelect [options]="Execution" formControlName="ExecutionUser"
                                  [style]="{'width':'100%'}" [defaultLabel]="'CHOOSE' | translate"></p-multiSelect>
                            </div>
                            <control-messages [control]="addTaskPlan.controls['ExecutionUser']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label for="Followup">
                                <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-multiSelect [options]="Followup" formControlName="FollowupUser"
                                  [style]="{'width':'100%'}" [defaultLabel]="'CHOOSE' | translate"></p-multiSelect>
                            </div>
                            <control-messages [control]="addTaskPlan.controls['FollowupUser']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label for="description">
                                <span translate>DESCRIPTION</span>
                            </label>
                            <div>
                                <textarea pInputTextarea id="description" formControlName="Description"
                                  class="ex-description"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Notification">
                                <span translate>NOTIFICATION_BEFORE_DEADLINE</span>
                            </label>
                            <div>
                                <input type="text" pInputText formControlName="NotifyBeforeDeadLine"
                                  class="ex-full-width" maxlength="2" />
                            </div>
                        </div>
                        <div class="upload-file-container">
                            <div class="tab-content">
                                <!-- <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedAcitivityFiles" (saveChanges)="saveActivityUploaded($event)">
                        </ecpl-document-explorer> -->
                                <div class="form-group">
                                    <div class="upload-actions">
                                        <label class="btn btn-outline-secondary upload-button" *ngIf="isHideGuestButton">
                                            <input type="file" ngFileSelect [options]="option"
                                              (uploadOutput)="onUploadOutput($event)"
                                              [uploadInput]="uploadInput">{{'BROWSE' | translate}}</label>
                                    </div>
                                    <div class="upload-items">
                                        <div class="upload-item" *ngFor="let f of files; let i = index;">
                                            <div class="filename">
                                                <span>{{ f.name }} ({{ f.id }}) -
                                                    {{ f.progress?.data?.percentage }}%</span>
                                                <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
                                                <span>ETA {{ f.progress?.data?.etaHuman }}</span>
                                            </div>
                                            <div class="progress">
                                                <span class="bar"
                                                  [style.width]="f?.progress?.data?.percentage + '%'"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="documentName !=null">
                                        <span>{{documentName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="isHideGuestButton" class="btn btn-outline-primary"
                              [disabled]="!addTaskPlan.valid" (click)="saveTaskDetails()" type="submit">
                                <span translate>ADD_TASK</span>
                            </button>
                            <button *ngIf="isHideGuestButton" class="btn btn-outline-primary" (click)="clearTask()">
                                <span translate>CLEAR</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="sub-panel">
                <div class="sub-panel-title">
                    <span translate>SAFETY_LOG</span>
                </div>
                <div class="sub-panel-content">
                    <dl class="dl-horizontal">
                        <dt translate>ASSIGNED_BY</dt>
                        <dd>{{createdby}}</dd>
                    </dl>
                    <dl class="dl-horizontal">
                        <dt translate>CREATED_ON</dt>
                        <dd>{{createdon}}</dd>
                    </dl>
                    <dl class="dl-horizontal">
                        <dt translate>TIME</dt>
                        <dd>{{time}}</dd>
                    </dl>
                    <div class="table-view">
                        <!-- Table starts -->
                        <p-table [value]="safetyLogData" (onRowSelect)="onRowSelect($event)" [rows]="5"
                          [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>{{'MODIFIED_FIELD' | translate}}</th>
                                    <th>{{'PREVIOUS_VALUE' | translate}}</th>
                                    <th>{{'MODIFIED_BY' | translate}}</th>
                                    <th>{{'MODIFIED_ON' | translate}}</th>
                                    <th>{{'TIME' | translate}}</th>
                                </tr>
                            </ng-template>
                            <!-- Body Content -->
                            <ng-template pTemplate="body" let-risk>
                                <tr>
                                    <td>
                                        <span>{{risk.FieldName.toUpperCase() | translate}}</span>
                                    </td>
                                    <td>
                                        <span>{{risk.OldValue}}</span>
                                    </td>
                                    <td>
                                        <span>{{risk.Modifier}}</span>
                                    </td>
                                    <td>
                                        <span>{{risk.CreatedOn | date : 'dd/MM/yyyy'}}</span>
                                    </td>
                                    <td>
                                        <span>{{risk.Time}}</span>
                                    </td>
                                </tr>
                            </ng-template>
                            <!-- Empty message -->
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td colspan="5">
                                        {{'NO_RECORDS_FOUND'|translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- Table ends -->
                    </div>
                </div>
            </div>
            <div class="sub-panel">
                <div class="sub-panel-title">
                    <span translate>SAFETY_DOCUMENT</span>
                </div>
                <div class="sub-panel-content">
                    <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                      (saveChanges)="saveUploaded($event)">
                    </ecpl-document-explorer>
                </div>
            </div>
            <div [hidden]="isCheckList || hideSJAChecklist" class="sub-panel">
                <div class="sub-panel-title">
                    <span *ngIf="parentKey !== 'FS'" translate>FEATURE_SJA_CHECKLIST</span>
                    <span *ngIf="parentKey === 'FS'" translate>HAZARD_ANALYSIS_CHECKLIST</span>
                </div>
                <div class="sub-panel-content">
                    <div *ngIf="ChecklistData">
                        <div class="form-group-50_50">
                            <div class="form-group">
                                <p-dropdown [options]="ChecklistData" formControlName="checklistId"
                                  [style]="{'width':'100%'}">
                                </p-dropdown>
                            </div>
                            <div class="form-group">
                                <button *ngIf="editSafetyJobAnalysis.controls['checklistId'].value"
                                  class="btn btn-primary" (click)="completeCheckList()">
                                    <span translate>COMPLETE_CHECKLIST</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-view">
                        <!-- Table starts -->
                        <p-table [value]="CompleteCheckList" #dt [rows]="5" [paginator]="true" [pageLinks]="3"
                          [rowsPerPageOptions]="[5,10,20]">
                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="Title">{{'DOCUMENT_NAME'|translate}}</th>
                                    <th>{{'CREATED_BY' | translate}}</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <th>
                                        <input pInputText type="text"
                                          (input)="dt.filter($any($event.target)?.value, 'Title', 'contains')" placeholder=""
                                          class="p-column-filter" >
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <!-- Body Content -->
                            <ng-template pTemplate="body" let-checklist>
                                <tr>
                                    <td>
                                        <a *ngIf="!checklist['Path']"
                                          [routerLink]="['../../../../../../safety-job/incomplete', checklist['Id'],'project',sjaFeatureId,jobId,FeatureId,parentId]">
                                            <span class="icon ic-lg icon-046"></span>
                                            {{checklist.Title}}({{'undercompletion' | translate }})
                                        </a>
                                        <div class="thumb-sm">
                                            <!-- <ecpl-pdf-viewer *ngIf="checklist['Path']" [id]="checklist['Id']" [pdfSrc]="checklist['Path']" [pdfName]="checklist['Title']"></ecpl-pdf-viewer> -->
                                            <a *ngIf="checklist['Path']" [href]="checklist['Path']" download>
                                                <img src="/assets/file-icons/kb-file-pdf.png" alt="Pdf File">
                                            </a>
                                        </div>
                                        <span *ngIf="checklist['Path']"> {{checklist.Title}} </span>
                                    </td>
                                    <td>
                                        <span>{{checklist.Created}}</span>
                                    </td>
                                    <td class="col-button">
                                        <button *ngIf="isHideGuestButton" type="button" class="btn btn-icon"
                                          title="{{'DELETE'|translate}}"
                                          (click)="confirmCompleteChecklistDelete(checklist['Id'],checklist['Path'])">
                                            <i class="icon ic-sm icon-trash"></i>
                                        </button>
                                        <a *ngIf="checklist['Path']" href="javascript:void(0);" class="btn btn-icon"
                                          (click)="copyDocLink(checklist)" title="{{'COPY_URL'|translate}}">
                                            <span class="pi pi-copy fs-md"></span>
                                        </a>
                                    </td>
                                </tr>
                            </ng-template>
                            <!-- Empty message -->
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td colspan="3">
                                        {{'NO_RECORDS_FOUND'|translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- Table ends -->
                    </div>
                </div>
            </div>
            <div class="sub-panel">
                <div class="sub-panel-title">
                    <span translate>TASK_SAVED</span>
                </div>
                <div class="sub-panel-content">
                    <div class="table-view">
                        <!-- Table starts -->
                        <p-table #dt [value]="taskPlanList" selectionMode="single" [rows]="5" [paginator]="true"
                          [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>{{'TASK_NAME' | translate}}</th>
                                    <th>{{'DEADLINE' | translate}}</th>
                                    <th>{{'DESCRIPTION' | translate}}</th>
                                    <th>{{'RESPONSIBLE_PERSON' | translate}}</th>
                                    <th>{{'FOLLOWUP_PERSON' | translate}}</th>
                                    <th>{{'DOCUMENTS' | translate}}</th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <!-- Body Content -->
                            <ng-template pTemplate="body" let-safetyjoblist let-i="rowIndex">
                                <tr>
                                    <td>
                                        <a (click)="editTask(safetyjoblist,i)" routerLinkActive="active"
                                          href="javascript:void(0);">{{safetyjoblist.Title}}</a>
                                    </td>
                                    <td>
                                        <span>{{safetyjoblist.Deadline |date : 'dd/MM/yyyy'}}</span>
                                    </td>
                                    <td>
                                        <span>{{safetyjoblist.Description}}</span>
                                    </td>
                                    <td>
                                        <span>{{safetyjoblist.ExecutionUserName}}</span>
                                    </td>
                                    <td>
                                        <span>{{safetyjoblist.FollowupPerson}}</span>
                                    </td>
                                    <td>
                                        <a href="{{safetyjoblist.DocumentType}}">
                                            <span *ngIf="safetyjoblist.DocumentType" class="icon ic-xs icon-xls"></span>
                                        </a>
                                    </td>
                                    <td class="col-button">
                                        <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                          (click)="confirm(safetyjoblist,i)">
                                            <i class="icon ic-sm icon-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </ng-template>
                            <!-- Empty message -->
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td colspan="7">
                                        {{'NO_RECORDS_FOUND'|translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- Table ends -->
                    </div>
                </div>
                <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
                  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
            </div>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button class="btn btn-outline-secondary" (click)="goback()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
        <button *ngIf="isHideGuestButton" class="btn btn-primary" (click)="saveChanges('')"
          [disabled]="!editSafetyJobAnalysis.valid">
            <span translate>SAVE</span>
        </button>
        <button *ngIf="isHideGuestButton" class="btn btn-primary" (click)="saveChanges('clear')"
          [disabled]="!editSafetyJobAnalysis.valid">
            <span translate>SAVE_ADD</span>
        </button>
        <button *ngIf="isHideGuestButton" class="btn btn-primary" (click)="saveAddTasks()"
          [disabled]="!editSafetyJobAnalysis.valid">
            <span translate>SAVE_TASK</span>
        </button>
    </div>
</form>
<div class="mtop-20">
    <staticdata></staticdata>
</div>
<p-dialog header="{{'NEW_TOPIC' | translate}}" [(visible)]="addTopic" (onHide)="onTopicFormHide()" 
  [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="topicForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>TOPIC_NAME</span>
            </label>
            <div>
                <input type="text" id="title" class="label-input form-control" formControlName="Name">
            </div>
            <label for="title" color class="col-form-label" *ngIf="isTopicExists" [ngStyle]="{'color': 'red'}">
                <span translate>TOPIC_EXIST</span>
            </label>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-primary" (click)="hidingElement = !hidingElement"
                  (click)="viewTopic()">
                    <span translate>VIEW</span>
                </button>
                <button type="button" class="btn btn-primary" [disabled]="!topicForm.valid" (click)="saveTopic()">
                    <span translate>SAVE</span>
                </button>
            </div>
            <div *ngIf="hidingElement">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table [value]="editTopics" [rows]="5" [paginator]="true" (onRowSelect)="onRowSelect($event)"
                      [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'TOPIC_NAME'|translate}}</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-categoryType>
                            <tr>
                                <td>
                                    <span>{{categoryType.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                      (click)="editTopic(categoryType)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                      (click)="deleteTopic(categoryType['Id'])">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table ends -->
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>
<p-dialog header="{{'TYPE_OF_ISSUE'|translate}}" [(visible)]="addType" (onHide)="onTypeFormHide()" 
  [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="typeForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>SAFETY_ISSUE</span>
            </label>
            <div>
                <input type="text" id="title" class="label-input form-control" formControlName="Name">
            </div>
            <label for="title" color class="col-form-label" *ngIf="isCaseExists" [ngStyle]="{'color': 'red'}">
                <span translate>TOPIC_EXIST</span>
            </label>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-primary" (click)="hidingElement = !hidingElement"
                  (click)="viewIssue()">
                    <span translate>VIEW</span>
                </button>
                <button type="button" class="btn btn-primary" [disabled]="!typeForm.valid" (click)="saveIssue()">
                    <span translate>SAVE</span>
                </button>
            </div>
            <div *ngIf="hidingElement">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table [value]="editTypes" [rows]="5" [paginator]="true" (onRowSelect)="onRowSelect($event)"
                      [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'SAFETY_ISSUE'|translate}}</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-categoryType>
                            <tr>
                                <td>
                                    <span>{{categoryType.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                      (click)="editIssue(categoryType)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                      (click)="deleteIssue(categoryType['Id'])">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table ends -->
                </div>
            </div>

        </p-footer>
    </form>
</p-dialog>

<p-dialog header="{{'COPY_DOCUMENT_URL' | translate}}" [(visible)]="showCopyLinkDailog"  
[style]="{width: '600px', height:'200px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="copyDocForm">

        <input type="text" #Name class="label-input form-control" style="width:100%;" formControlName="filePath">
        <div class="action-btns-wrapper">
            <button class="btn btn-primary" type="button" (cbOnSuccess)="copyDocumnetUrl()" [ngxClipboard]="Name">
                <span class="icon ic-sm icon-copy-to-clipboard"></span>
                <span translate>COPY_CLOSE</span>
            </button>
        </div>

    </form>
</p-dialog>
<toaster-component></toaster-component>
<p-dialog header="{{'NOTIFICATION' | translate}}" [(visible)]="showNotification"  
[style]="{width: '600px'}" [resizable]="false" [draggable]="false">
    <span translate> TASK_TO_SAVE </span>
</p-dialog>
<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>
<div class="panel-loader" *ngIf="loading">
    <span>
        <span class="loader-icon"></span>
        <span translate>PROCESSING</span>
    </span>
</div>