import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { Brand, Model, Temperature, TypeOfObject } from './../models/food-control';
import { FoodControlList } from './../models/food-control-list';
import { ManualControlServices } from './manual-control-services';

@Injectable()
export class ManualControlListResolver implements Resolve<FoodControlList[]> {
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodControlList[]> {
        return this.backend.getManualControlByParent(+route.params['Id']);
    }
}
@Injectable()
export class ManualControlEditResolver implements Resolve<FoodControlList[]> {
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodControlList[]> {
        return this.backend.getManualControlById(+route.params['mId']);
    }
}
@Injectable()
export class TypeOfObjectResolver implements Resolve<TypeOfObject[]> {
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<TypeOfObject[]> {
        return this.backend.getTypeofObject(BaseServices.BusinessId);
    }
}
@Injectable()
export class TemperatureResolver implements Resolve<Temperature[]> {
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Temperature[]> {
        return this.backend.getTemperature(BaseServices.BusinessId);
    }
}
@Injectable()
export class BrandResolver implements Resolve<Brand[]> {
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Brand[]> {
        return this.backend.getBrand(BaseServices.BusinessId);
    }
}

@Injectable()
export class ModelResolver implements Resolve<Model[]>{
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Model[]> {
        return this.backend.getModel(BaseServices.BusinessId);
    }
}

@Injectable()
export class ManualTempControlResolver implements Resolve<any[]> {
    constructor(private backend: ManualControlServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodControlList[]> {
        return this.backend.ManageTemperatureControl(+route.params['Id']);
    }
}
