<div class="main-content">
    <div class="main-heading">
        <span translate>ASSIGN_PROJECT</span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="form-group">
                <p-radioButton label="{{'BUSINESS' | translate}}" id="first" name="content" (click)="changeToBusiness()"
                  [(ngModel)]="radioValue" (ngModelChange)="reset()" value="1">
                </p-radioButton>
                <ng-template [ngIf]="!isPortal">
                    <p-radioButton label="{{'PORTAL' | translate}}" (click)="changeToPortal()" id="second"
                      name="content" [(ngModel)]="radioValue" (ngModelChange)="reset()" value="2"></p-radioButton>
                </ng-template>
            </div>

            <div class="form-group" *ngIf="radioValue === '1'">
                <label for="ChooseBusiness">
                    <span translate>CHOOSE_BUSINESS</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <p-dropdown [options]="chooseBusiness" [style]="{'width':'500px'}" [filter]="true"
                      [(ngModel)]="Business" (onChange)="onBusinessChanged($event)"
                      placeholder="{{'CHOOSE_BUSINESS' | translate}}" required></p-dropdown>
                </div>
            </div>
            <div class="form-group" *ngIf="radioValue === '2'">
                <label for="ChoosePortal">
                    <span translate>CHOOSE_PORTAL</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <p-dropdown [options]="choosePortal" [style]="{'width':'500px'}" [filter]="true"
                      [(ngModel)]="Portal" (onChange)="onPortalChanged($event)"
                      placeholder="{{'CHOOSE_PORTAL' | translate}}" required>
                    </p-dropdown>
                </div>
            </div>
            <div class="form-group">
                <label>
                    <span translate>PROJ_LIST</span>
                </label>
                <!-- Table starts -->
                <p-table #dt [value]="projects" [rows]="10" [style]="{'width':'500px'}" [(selection)]="selectedData"
                  [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 55px;">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>{{'PROJ_NAME' | translate}}</th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-assignproj>
                        <tr>
                            <td style="width: 55px;">
                                <p-tableCheckbox id="selectedOrders" [value]="assignproj">
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <span>{{assignproj.Name}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="action-btns-wrapper">
                <button type="button" class="btn btn-primary" *ngIf="isValueSelected " (click)="assignProject()"
                  [disabled]="!((Business || Portal) && selectedData.length>0)">
                    <span translate>ASSIGN</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" *ngIf="isValueSelected" (click)="clear()">
                    <span translate>CLEAR</span>
                </button>
            </div>
        </div>
    </div>

</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="icon icon-030" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>