import { List } from "lodash";

export class Meeting {
    Id ?: number;
    Title ?: string;
    MeetingType ?: number;
    Date ?: string;
    StartTime ?: string;
    EndTime ?: string;
    Duration ?: string;
    Description ?: string;
    CreatedOn ?: Date;
    CreatedBy ?: number;
    ModifiedOn ?: Date;
    ModifiedBy ?: number;
    CreatorName ?: string;
    ModifierName ?: string;
    Status ?: string;
    MeetingTypeText ?: string;
    MeetingUser ?: MeetingUserDto;
    MeetingUserBackup ?: MeetingUserBackupDto;
    MeetingConverner ?: MeetingConvernerDto;
    MeetingUserList ?: List<MeetingUserDto>;
    MeetingUserBackupList ?: List<MeetingUserBackupDto>;
    BusinessId ?: number;
    MeetingDocument ?: MeetingDocumentNode[];
    MeetingParticipations ?: MeetingParticipationsDto;
    MeetingParticipationsList ?: List<MeetingParticipationsDto>;
}
export class MeetingType {
    Id ?: number;
    Title ?: string;
    Status ?: number;
    BusinessId ?: number;
}
export class MeetingUserDto {
    UserId ?: number;
    IsOrganizar ?: boolean;
    UserName ?: string;
    UserNameList ?: any[]=[];
}
export class MeetingUserBackupDto {
    UserId ?: number;
    IsOrganizar ?: boolean;
    UserName ?: string;
    UserNameList ?: any[]=[];
}
export class MeetingParticipationsDto {
    UserId ?: number;
    IsOrganizar ?: boolean;
    UserName ?: string;
    UserNameList ?: any[]=[];
}
export class MeetingConvernerDto {
    UserId ?: number;
    IsOrganizar ?: boolean;
    ConvernerName ?: string
}

export class MeetingDocumentNode {
    Id ?: number;
    MeetingId ?: number;
    FileName ?: string;
    OriginalFileName ?: string;
    MimeType ?: string;
    Size ?: number;
    Path ?: string;
    FileType ?: number;
    Status ?: string;
  }
