export class Deviation {
    Id ?: number;
    Title ?: string;
    FeatureId ?: number;
    Description ?: string;
    BusinessId ?: number;
    ApplicationId ?: number;
    BusinessDepartmentId ?: number;
    BusinessDepartmentIds ?: number[];
    TopicId ?: number;
    CaseId ?: number;
    Importance ?: string;
    Cost ?: string;
    Deadline ?: string;
    ExecutionUserId ?: number;
    DeviationAttachment ?: DeviationAttachment[];
    FollowupUserId ?: number;
    Status ?: string;
    ImmediateAction ?: string;
    CauseAnalysis ?: string;
    CorrectiveAction ?: string;
    EffectOfAction ?: string;
    NotifyBeforeDeadLine ?: string;
    NotificationBeforeFollowup?: string;
    IsShowtoUsers ?: string;
    IsDeviationViewRights ?: string;
    IsSendOnlyDepartment ?: string;
    CompletedDate ?: string;
    CompletedBy ?: string;
    Comment?: string;
    ManualId ?: number;
    ManualChkCommentsId ?: number;
    DeviationNumber ?: number;
    DeviationNumberPrefix ?: string;
    CreatedBy ?: number;
    CreatedOn ?: string
    ModifiedBy ?: string;
    ModifiedOn ?: string;
    DeviationUrl ?: string;
    CultureInfo ?: string;
    SendonlytoDepartment  ?: boolean;
    SendonlytoEditors ?: boolean;
    EventDate ?: string;
    EventTime ?: string;
    EventLocation ?: string;
}
export class Department {

    Id ?: number;
    Name ?: string;
    ParentId ?: string;
    Status ?: number;
    BusinessId ?: number;
    ApplicationId ?: number;
    CreatedBy ?: number;
    ModifiedBy ?: number;
    CreatedOn ?: string;
    ModifiedOn ?: string;

}
export class DeviationAttachment {
    Id ?: number;
    DeviationId ?: number;
    FileName ?: string;
    OriginalFileName ?: string;
    MimeType ?: string;
    Size ?: number;
    Path ?: string;
    FileType ?: string;
    Status ?: string;
    CreatedBy ?: {
        Id ?: number;
        Name ?: string;
    };
    CreatedOn ?: string;
    ModifiedBy ?: {
        Id ?: number;
        Name ?: string;
    };
    ModifiedOn ?: string;
}

export class TypeOfCase {

    Id ?: number;
    Name ?: string;
    Status ?: string;
    BusinessId ?: number;
    ApplicationId ?: number;
    CreatedBy ?: number;
    CreatedOn ?: Date;
    ModifiedBy ?: number;
    ModifiedOn ?: Date;
    IsDefault ?: boolean;


}
export class Topic {

    Id ?: number;
    Name ?: string;
    BusinessId ?: number;
    Status ?: string;
    CreatedBy ?: number;
    CreatedOn ?: Date;
    ModifiedBy ?: number;
    ModifiedOn ?: Date;

}
export class ResponsibleForExe {
    Name ?: string;
    ContactPersonId ?: number
}
export class Business {
    Id ?: number;
    CompanyName ?: string;
}
export class Client {
    Id ?: number;
    Name ?: string;
}

export class FollowupPerson {
    ProjectId ?: number;
    ContactPersonId ?: number;
    Name ?: string;
}
export class ResponsiblePerson {
    Id ?: number;
    Name ?: string;
}

export enum Module {
    EMPLOYEEPRIVATEDOCUMENT = 132,
    FRAMEWORKAGREEMENT = 22,
    SERVICEAGREEMENT = 23,
    FDV = 18,
    RECEPTIONCONTROL = 154,
    ACTIVITIES = 145,
    OURCOMPANY = 6,
    BUSINESS = 2,
    CONTACT = 10,
    EMPLOYEES = 8,
    FOLLOWUPS = 15,
    PROJECT = 16,
    FIREDEVIATION = 45, //
    INTERNALCONTROL = 19,
    FOODSAFETY = 20,
    KUBA_CONTROL = 43,
    CLIENT = 11,
    CLIENT_CONTACT = 165, //
    SUPPLIER = 25,
    CONTRACTOR = 12,
    CONTRACTOR_CONTACT = 166, //
    VEHICLE = 21,
    ELECTRO = 36,
    Kunde_EL = 24,
    OTHER_SYSTEMS = 42,
    QUALITY_SYSTEMS = 41,
    FLEXI = 60
}
export enum UserRole {
    ADMIN = 1,
    PORTAL = 2,
    EDITOR = 3,
    GUEST = 4,
    USER = 5,
    DEVELOPER = 6,
    SUPER = 9,
}

