import 'rxjs/Rx';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ConfirmationService } from 'primeng/api';

import { FDV } from '../models/fdvlist';
import { FDVServices } from '../services/fdv.service';
import { BaseServices } from './../../kuba.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fdv',
  templateUrl: 'fdvMyList.component.html'
})
export class FdvMyListComponent implements OnInit {
  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  fireDeviation: FDV[];
  isHidden = false;
  isMyFdvHidden = false;
  private subscriptions: Subscription[] = [];
  /**
   * constructor
   * @param route {{ActivatedRoute}}
   * @param fdvService {{FDVServices}}
   * @param confirmationService {{ConfirmationService}}
   */
  constructor(
    private route: ActivatedRoute,
    private fdvService: FDVServices,
    private confirmationService: ConfirmationService) { }
  /**
   * get FDV list
   */
  ngOnInit(): void {
    document.querySelector("body").classList.remove("opened");
    this.fireDeviation = this.route.snapshot.data['myList'];
    switch (BaseServices.UserRole.toUpperCase()) {
      case 'ADMIN': this.isHidden = false;
        this.isMyFdvHidden = false;
        break;
      case 'EDITOR': this.isHidden = false;
        this.isMyFdvHidden = false;
        break;
      case 'USER':
        this.isMyFdvHidden = false;
        break;
      case 'GUEST': this.isHidden = true;
        this.isMyFdvHidden = true;
        break;
      case 'PORTAL': this.isHidden = true;
        this.isMyFdvHidden = true;
        break;
    }

  }
  /**
   * delete FDV and refresh FDV list
   * @param fireDeviation {{any}}
   */
  deleteFdv(fireDeviation) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this fire deviation?',
      accept: () => {
        this.subscriptions.push(this.fdvService
          .delete(fireDeviation)
          .subscribe(result => {
            this.subscriptions.push(this.fdvService
              .myList(BaseServices.BusinessId, BaseServices.UserId)
              .subscribe(result => { this.fireDeviation = result }));
            this.toasterComponent.callToastDlt();
          }));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
