<div class="tab-content">
    <div class="page-title">
        <strong translate>MESSAGE_DETAILS</strong>
    </div>
    <div class="form-data">
        <label>
            <span translate>FROM</span>
        </label>
        <label>{{fromuserName}}</label>
    </div>
    <div class="form-data">
        <label>
            <span translate>SUBJECT</span>
        </label>
        <label>{{subject}}</label>
    </div>
    <div class="form-data">
        <label>
            <span translate>MESSAGE</span>
        </label>
        <label>{{message}}</label>
    </div>
    <div class="form-data" *ngIf="attachmentUrl">
        <label>
            <span translate>ATTACHMENTS</span>
        </label>
        <a target="_self" href="{{attachmentUrl}}">{{FileName}}</a>
    </div>
    <div class="action-btns-wrapper">
        <button class="btn btn-outline-secondary" (click)="gotoBack()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
    </div>
</div>