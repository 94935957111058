<div class="tab-content">
    <div class="page-title" translate>ADDITIONAL_FEATURES</div>
    <div class="tree-grid">
        <tree-root *ngIf="features" [nodes]="features" [focused]="false" [options]="optionsTreeNode" #tree
            class="tree-view">
            <ng-template #treeNodeTemplate let-node="node" let-index="index">
                <span>
                    <p-triStateCheckbox (onChange)="check( node, $event)"
                        [disabled]="(node.data.id === 180 && !node.data.access)||(roleId === 2 && portalFeatures === null) || (roleId !== 1 && !node.data.access) || (roleId === 1 && isPortalExists) || filterFeaturesKey.indexOf(node.id) > -1"
                        [(ngModel)]="node.data.checked"></p-triStateCheckbox>
                </span>
                <span>
                    <span class="treeNode" title="{{ node.data.translationKey | translate }}" translate>{{
                        node.data.translationKey }}</span>
                </span>
            </ng-template>
        </tree-root>
    </div>
</div>