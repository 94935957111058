import { DepartmentCachedListResolver } from './../follow-ups/services/activities.resolver.service';
import { kubaFeatureDetailsRoutes } from 'src/app/kuba/shared/kuba-feature-details/Kuba-feature-details.routes';
import { DepartmentResolver } from './../deviation/services/deviation.resolver';
import { ReceptionControlRoutes } from './../reception-control/reception-control.routing';
import { ManualControlRoutes } from './../manual-control/manual-control.routing';
import { productionRoutes } from './../production/production.routing';
import {
    FoodSafetyListResolver,
    FoodSafetyEditResolver,
    ZoneListResolver,
    FoodsafetyFeaturesResolver,
    FoodSafetyListByUserResolver,
    FoodSafetyZonesResolver,
    FSDailyTaskListResolver,
    FSAuditListResolver,
    HazardAnalysisListResolver,
    FSAuditDateListResolver,
    CoolingListResolver,
    CoolingProductsListResolver
} from './services/foodsafety.resolver';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { CommonViewComponent } from './../common/components/commonView.component';
import { CreateFoodSafetyDeviceComponent } from './components/new-device/new-device.component';
import { FoodSafetyDeviceListComponent } from './components/device-list/device-list.component';
import { FoodSafetyDocumentComponent } from './components/foodsafety-documents/foodsafety-documents.component';
import { FoodSafetyListComponent } from './components/foodsafety-list/foodsafety-list.component';
import { FoodsafetyViewComponent } from './components/foodsafety-view/foodsafety-view.component';
import { FoodSafetyComponent } from './foodsafety.component';
import { CreateFoodSafetyComponent } from './components/new-foodsafety/new-foodsafety.component';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';
import { crewroutes } from 'src/app/kuba/crew-list/crew-list.routing';
import { foodsafetycleaningRoutes } from '../foodsafety-cleaning/foodsafety-cleaning.routing';
import { EmployeeContactUserSubscriptionResolver, EmployeeContactUserResolver } from '../users/services/user.resolver.service';
import { FoodSafetyUser } from './models';
import { FoodHeaderListComponent } from './components/foodsafety-list/foodsafety-header.component';
import { DailyTaskComponent } from './components/daily-task/daily-task.component';
import { HazardAnalysisComponent } from './components/hazard-analysis/hazard-analysis.component';
import { FSAuditComponent } from './components/audit/fs-audit.component';
import { FSTaskManagerListComponent } from './components/task-manager/fs-taskmanager-list.component';
import { FSTaskManagerFormComponent } from './components/task-manager/fs-taskmanager-form.component';
import { TaskManagerComponent } from '../task-manager/task-manager.component';
import { TaskManagerListResolver, TaskManagerEditResolver, RepeatitionList, ExecutionPersonList, FollowUpPersonList } from '../task-manager/services/task.resolver.service';
import { AllDepartmentResolver } from '../follow-ups/services/deviation.resolver.service';
import { CoolingListComponent } from './components/cooling/cooling-list/cooling-list.component';
export const foodsafetyRoutes: Route[] = [
    {
        path: 'foodsafety',
        component: FoodSafetyComponent,
        children: [
            {
                path: 'list',
                component: FoodHeaderListComponent,
                children: [
                    { path: '', redirectTo: 'all', pathMatch: 'full' },
                    {
                        path: '',
                        component: FoodSafetyListComponent,
                        resolve: {
                            list: FoodSafetyListByUserResolver
                        }
                    },
                    {
                        path: 'own',
                        component: FoodSafetyListComponent,
                        resolve: {
                            list: FoodSafetyListByUserResolver
                        }
                    },
                    {
                        path: 'all',
                        component: FoodSafetyListComponent,
                        resolve: {
                            list: FoodSafetyListResolver
                        }
                    }
                ]
            },
            {
                path: 'edit/:id',
                component: CreateFoodSafetyComponent,
                resolve: {
                    edit: FoodSafetyEditResolver,
                    zonedetails: ZoneListResolver,
                    deptdetails: DepartmentCachedListResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver,
                    contact: EmployeeContactUserResolver,
                    zones: FoodSafetyZonesResolver
                }
            },
            {
                path: 'documents',
                component: FoodSafetyDocumentComponent
            },
            {
                path: 'devices',
                component: FoodSafetyDeviceListComponent
            },
            {
                path: 'newdevice',
                component: CreateFoodSafetyDeviceComponent
            },
            {
                path: 'editdeivce/:deid',
                component: CreateFoodSafetyDeviceComponent
            },
            {
                path: 'details/:id',
                component: FoodsafetyViewComponent,
                resolve: {
                    additionalFeatures: FoodsafetyFeaturesResolver,
                    list: FoodSafetyListResolver
                },
                data: { parent: 'FS' },
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'dailytask',
                        component: DailyTaskComponent,
                        resolve: {
                            list: FSDailyTaskListResolver
                        }
                    },
                    { 
                        path: 'hazardanalysis', 
                        component: HazardAnalysisComponent ,
                        resolve:{
                            list: HazardAnalysisListResolver
                        }
                    },
                    {
                        path: 'cooling',
                        component: CoolingListComponent,
                        resolve:{
                            coolingList: CoolingListResolver,
                            coolingProduct: CoolingProductsListResolver
                        }
                    },
                    {
                        path: 'audit',
                        component: FSAuditComponent,
                        resolve:{
                            list: FSAuditListResolver,
                            fslist: FoodSafetyListResolver,
                            dateList: FSAuditDateListResolver
                        }
                    },
                    {
                        path: 'taskmanager',
                        component: TaskManagerComponent,
                        canActivate: [AuthGuard],
                        children: [
                            {
                                path: 'list',
                                component: FSTaskManagerListComponent,
                                resolve: {
                                    list: TaskManagerListResolver,
                                }
                            },
                            {
                                path: 'edit/:tid',
                                component: FSTaskManagerFormComponent,
                                resolve: {
                                    user: EmployeeContactUserResolver,
                                    edit: TaskManagerEditResolver,
                                    repetition: RepeatitionList,
                                    exePerson: ExecutionPersonList,
                                    followup: FollowUpPersonList,
                                    departments: AllDepartmentResolver
                                }
                            }
                        ]
                    },
                    ...kubaFeatureDetailsRoutes,
                    ...FeatureDocumentRoutes,
                    ...deviationRoutes,
                    ...taskmanagerRoutes,
                    ...timetableRoutes,
                    ...safetyjobRoutes,
                    ...registrationRoutes,
                    ...productionRoutes,
                    ...ManualControlRoutes,
                    ...ReceptionControlRoutes,
                    ...crewroutes,
                    ...foodsafetycleaningRoutes
                ]
            }
        ]
    }
];
