import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KubaCompleteChecklistComponent } from './kuba-complete-checklist.component';
import { FoodSafetyCompleteChecklistComponent } from './kuba-foodsafety-complete-checklist.component';
import { VehiclesCompleteChecklistComponent } from './kuba-vehicles-complete-checklist.component';
import { ResponsibleForExecutionResolver } from './../../follow-ups/services/deviation.resolver.service';
import { KubaCompleteSafteyJobChecklistComponent } from './kuba-safety-job-analysis-complete-checklist.component';
export const completeChecklistRoutes: Routes = [
    {        
        path: 'complete-checklist/new/:clId/:Type/:FeatureId/:FeatureKey/:Department',
        component: KubaCompleteChecklistComponent
    },
    {
        path: 'complete-checklist/new/:clId/:Type/:ActivityId/:FeatureId/:FeatureKey',
        component: KubaCompleteChecklistComponent
    },
    {
        path: 'complete-checklist/incomplete/:clId/:Type/:FeatureId/:FeatureKey',
        component: KubaCompleteChecklistComponent
    },
    {
        path: 'fs-complete-checklist/incomplete/:clId',
        component: FoodSafetyCompleteChecklistComponent
    },
    {
        path: 'fs-complete-checklist/new/:FeatureKey',
        component: FoodSafetyCompleteChecklistComponent
    },
    {
        path: 'vehicle-complete-checklist/new/:clId',
        component: VehiclesCompleteChecklistComponent
    },
    {
        path: 'vehicle-complete-checklist/incomplete/:clId',
        component: VehiclesCompleteChecklistComponent
    },
    {
        path: 'safety-job/new/:clId/:Type/:FeatureId/:FeatureKey/:ParentId/:ParentKey',
        component: KubaCompleteSafteyJobChecklistComponent
    },
    {
        path: 'safety-job/incomplete/:clId/:Type/:FeatureId/:FeatureKey/:ParentId/:ParentKey',
        component: KubaCompleteSafteyJobChecklistComponent
    },
];
