import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EL } from '../models/el-edit';
import * as _ from 'lodash';
import { FeatureKey } from 'src/app/_models/feature';

@Component({
    selector: 'elt-view',
    templateUrl: 'el-view.component.html'
})

export class ELViewComponent implements OnInit {
    pageTitle: string;
    additionalFeatures: any;
    enabledAdditionalFeatures: any;
    faId: number;
    initialPage: any;
    elObj: EL;
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute) {
        this.faId = this.activatedRoute.snapshot.params['id'];

        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        let isUnderCompletion = sessionStorage.getItem('isUnderCompletion');
        this.elObj = this.activatedRoute.snapshot.data['el'];
        this.pageTitle = this.elObj.ContractName;
        sessionStorage.setItem('KundeELName',this.pageTitle);
        this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x => x.id !== 164);
        let index = _.findIndex(this.enabledAdditionalFeatures, { translationKey: 'DETAILS' });
            if (index >= 0) {
                this.enabledAdditionalFeatures[index].translationKey = 'KUNDE_EL_DETAILS';
            }
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
            .filter(element =>
                (element.id !== FeatureKey.SJA_CHECKLIST));
        if (this.enabledAdditionalFeatures.length > 0) {
            if (isUnderCompletion === 'true') {
                sessionStorage.setItem('isUnderCompletion', 'false');
                if (this.enabledAdditionalFeatures[0].translationKey === 'DOCUMENTS') {
                    this.route.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.faId }],
                        { relativeTo: this.activatedRoute });
                } else {
                    this.route.navigate([this.enabledAdditionalFeatures[1].linkText, { Id: this.faId }],
                        { relativeTo: this.activatedRoute });
                }
            } else {
                this.route.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.faId }],
                    { relativeTo: this.activatedRoute });
            }

        }
    }

    ngOnInit() { document.querySelector("body").classList.remove("opened");}
}
