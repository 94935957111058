<div class="tab-content">
    <div class="row">
        <div class="col-sm-3">
            <div class="panel-tabs tabs-left tabs-vertical">
                <ul class="nav nav-pills">
                    <li class="active">
                        <a href="#res" data-toggle="tab">{{'EARTH' | translate}}</a>
                    </li>
                    <li>
                        <a href="#con" data-toggle="tab">{{'CONTINUITY_POINT' | translate}}</a>
                    </li>
                    <li>
                        <a href="#ins" data-toggle="tab">{{'INSULATION_RESISTANCE_WITH_ALL_CIRCUITS_ACTIVATED_OVER_1MQ_MQ' | translate}}</a>
                    </li>
                    <li>
                        <a href="#vol" data-toggle="tab">{{'VOLTAGE_MEASUEREMENT' | translate}}</a>
                    </li>
                    <li>
                        <a href="#pow" data-toggle="tab">{{'POWER_MEASUREMENT' | translate}}</a>
                    </li>
                    <li>
                        <a href="#eff" data-toggle="tab">{{'EFFECT_OF_POWER' | translate}}</a>
                    </li>
                    <li>
                        <a href="#rea" data-toggle="tab">{{'REACTIVE_MEASUREMENT' | translate}}</a>
                    </li>
                    <li>
                        <a href="#sho" data-toggle="tab">{{'SHORT_CIRCUIT_VALUE' | translate}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-sm-9">
            <!-- Tab panes -->
            <div class="tab-content">
                <!-- Resistance to Earth -->
                <div class="tab-pane active" id="res">
                    <form [formGroup]="measureProtocolResistance">
                        <div class="form-group">
                            <label for="Title">
                                <span translate>INΩ</span>
                            </label>
                            <div>
                                <input type="number" formControlName="MeasureValue" id="MeasureValue" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>METHOD</span>
                            </label>
                            <div>
                                <input type="text" formControlName="Method" id="Method" class="form-control" />
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Continuity -->
                <div class="tab-pane" id="con">
                    <form [formGroup]="measureProtocolContinuity">
                        <div class="form-group">
                            <label for="Title">
                                <span translate>HIGH_VALUE</span>
                            </label>
                            <div>
                                <input type="number" formControlName="HighestValue" id="HighestValue" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>DESCRIPTION</span>
                            </label>
                            <div>
                                <textarea type="text" formControlName="Description" id="Description" class="form-control"></textarea>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Insulation Resistance -->
                <div class="tab-pane" id="ins">
                    <form [formGroup]="measureProtocolInsulation">
                        <div class="form-group">
                            <label for="Title">
                                <span>L1-PE</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L1_PE" id="L1" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>L2-PE</span>
                            </label>
                            <div>
                                <input type="text" formControlName="L2_PE" id="L2" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>L3-PE</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L3_PE" id="L3" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>N-PE</span>
                            </label>
                            <div>
                                <input type="text" formControlName="N_PE" id="NPE" class="form-control" />
                            </div>
                        </div>
                    </form>
                </div>

                <!--Voltage Measurement -->
                <div class="tab-pane" id="vol">
                    <form [formGroup]="measureProtocolVoltage">
                        <div class="form-group">
                            <label for="Title">
                                <span>L1-L2</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L1_L2" id="L1L2" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>L1-L3</span>
                            </label>
                            <div>
                                <input type="text" formControlName="L1_L3" id="L1L3" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>L2-L3</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L2_L3" id="L2L3" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>L1-PE</span>
                            </label>
                            <div>
                                <input type="text" formControlName="L1_PE" id="L1PE" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>L2-PE</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L2_PE" id="L2PE" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>L3-PE</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L3_PE" id="L3PE" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>N-L1</span>
                            </label>
                            <div>
                                <input type="number" formControlName="N_L1" id="NL1" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>N-L2</span>
                            </label>
                            <div>
                                <input type="number" formControlName="N_L2" id="NL2" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>N-L3</span>
                            </label>
                            <div>
                                <input type="number" formControlName="N_L3" id="NL3" class="form-control" />
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Power Measurement -->
                <div class="tab-pane" id="pow">
                    <form [formGroup]="measureProtocolPower">
                        <div class="form-group">
                            <label for="Title">
                                <span>L1</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L1" id="L1" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>L2</span>
                            </label>
                            <div>
                                <input type="text" formControlName="L2" id="L2" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>L3</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L3" id="L3" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>N</span>
                            </label>
                            <div>
                                <input type="text" formControlName="N" id="N" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>PE</span>
                            </label>
                            <div>
                                <input type="text" formControlName="PE" id="PE" class="form-control" />
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Effect -->
                <div class="tab-pane" id="eff">
                    <form [formGroup]="measureProtocolEffectPower">
                        <div class="form-group">
                            <label for="Title">
                                <span>L1</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L1" id="L1" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>L2</span>
                            </label>
                            <div>
                                <input type="text" formControlName="L2" id="L2" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>L3</span>
                            </label>
                            <div>
                                <input type="number" formControlName="L3" id="L3" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>P tot</span>
                            </label>
                            <div>
                                <input type="text" formControlName="P_tot" id="ptot" class="form-control" />
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Reactive -->
                <div class="tab-pane" id="rea">
                    <form [formGroup]="measureProtocolEffectReactive">
                        <div class="form-group">
                            <label for="Title">
                                <span>S</span>
                            </label>
                            <div>
                                <input type="number" formControlName="S" id="S" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span>Cos phi</span>
                            </label>
                            <div>
                                <input type="text" formControlName="Cosphi" id="Cosphi" class="form-control" />
                            </div>
                        </div>
                    </form>
                </div>

                <!--Short Circuit Values -->
                <div class="tab-pane" id="sho">
                    <form [formGroup]="measureProtocolEffectShortCircuit">
                        <div class="form-group">
                            <label for="Title">
                                <span>2-pol min</span>
                            </label>
                            <div>
                                <input type="number" formControlName="Polmin" id="pol" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>DESCRIPTION</span>
                            </label>
                            <div>
                                <textarea type="text" formControlName="Description1" id="Description" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Title">
                                <span>3-pol max</span>
                            </label>
                            <div>
                                <input type="number" formControlName="Polmax" id="3pol" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>DESCRIPTION</span>
                            </label>
                            <div>
                                <textarea type="text" formControlName="Description2" id="Description" class="form-control"></textarea>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div *ngIf="!isElectro" class="action-btns-wrapper">
                <button class="btn btn-primary" (click)="saveVC()">
                    <span translate>SAVE</span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="!isElectro" class="action-btns-wrapper text-right">
        <button type="button" class="btn btn-outline-primary" [routerLink]="['./../earthleakage']">
            <span translate>NEXT</span>
            <span class="icon ic-xs icon-forward"></span>
        </button>
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425" acceptLabel = "{{'SWITCH_YES'|translate}}" rejectLabel = "{{'SWITCH_NO'|translate}}"></p-confirmDialog>