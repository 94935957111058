import { Component, OnInit, Input } from '@angular/core';

import { BaseServices } from '../kuba/kuba.services';
import { NotificationServices } from './../_services/notification.services';
import { TranslateService } from '@ngx-translate/core';
import * as signalR from '@aspnet/signalr';
import { NotificationList } from 'src/app/kuba/notifications/models';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';


@Component({
    selector: 'notification',
    templateUrl: 'notification.component.html',
    providers: [NotificationServices],
})
export class NotificationComponent implements OnInit {
    //#region variable
    notifyList: any = [];
    fdvNotificationList: any = [];
    notifications = true;
    userRole: string;
    businessId: number;
    showNewNotification: boolean;
    notificationTitle: string;
    notificationDesc: string;
    percentage = 0;
    fdvNotifications = false;
    allCaught = false;
    showDropDown = false;
    notificationCount = 0;
    isfdvBulkDownload = false;
    fdvBulkSelectedData: any = [];
    fdvReportId =0;
    progressPercentage = 0;
    downloadExpired = 1;
    isBulkReport = false;
    isDownloads:Boolean;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param notificationService {NotificationServices}
     */
    constructor(
        private notificationService: NotificationServices,
        private translate: TranslateService) {}

    //#endregion

    //#region page-event

    /**
     * intit
     */
    ngOnInit(): void {
        let languageId = Number(sessionStorage.getItem('languageId'));
        let CultureInfo = 'en-GB';
        switch (languageId) {
            case 1:
                CultureInfo = 'en-GB';
                break;
            case 2:
                CultureInfo = 'no-NO';
                break;
            case 3:
                CultureInfo = 'sv-SE';
                break;
            case 4:
                CultureInfo = 'pl-PL';
                break;
        }
        if(CultureInfo == 'en-GB'){
            this.isDownloads = true;
        }
        this.fdvNotifications = (BaseServices.ApplicationId == 5) ? false : true;
        let roleId = BaseServices.roleId;
        if (roleId === '1') {
            this.userRole = 'admin';
        } else if (roleId === '2') {
            this.userRole = 'portal';
        } else {
            this.userRole = 'business';
        }
        this.businessId = BaseServices.BusinessId;
        this.subscriptions.push(this.notificationService.getAllUserNotifications().subscribe((result:any) => {
            result.forEach(item => {
               if(BaseServices.ApplicationId == 4 && (item["FileType"] == 1|| item["FileType"] == 2)) {   
                this.fdvNotificationList.unshift(item);
                if(item["FileType"]==2)
                {
                    item["ProgressPercentage"] = 100;
                } 
                item["ProgressPercentage"] = item["ProgressPercentage"] == null ? 0 : item["ProgressPercentage"]
                   if(item["FileType"]==2 || (item["FileType"] == 1 && item["ProgressPercentage"] == 100))
                    this.notificationCount = this.notificationCount + 1;
                    
               } else {
                   this.notifyList.unshift(item);
                   this.notificationCount = this.notificationCount + 1;
               }
            });
            this.notificationCount =  this.notifyList.length + this.fdvNotificationList.length;
            this.allCaught = this.notifications ? (this.notifyList.length <= 0) : (this.fdvNotificationList.length <= 0);
        }));
        const connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(environment.NOTIFICATION_URL + "/NotificationHub")
        .build();
        // Commented for 404 issue
        // connection.start().then(function () {
        //    }).catch(function (err) {
        //     return console.error(err)
        // });
        connection.onclose(() => {
            setTimeout(function(){
                connection.start().then(function () {
                    }).catch(function (err) {
                      console.error(err)
                 });
                   },3000); 
        });
        connection.on("PushNotification", (msg: any) => { 
            let item  = new NotificationList();
            item.Id = msg["id"];
            item.Title = msg["title"];
            item.Description = msg["description"];
            item.Priority = msg["priority"];
            item.CreatedBy = msg["createdBy"];
            item.CreatedOn = msg["createdOn"];
            item.ModifiedBy = msg["modifiedBy"];
            item.ModifiedOn = msg["modifiedOn"];
            item.Status = msg["status"];
            item.FileType = msg["fileType"];
            item.UserId = msg["userId"];
            item.ProgressPercentage = msg["progressPercentage"] == null? 0 : msg["progressPercentage"]; 
            if(!(this.fdvNotificationList.find((x: any)=> x.Id == msg['id'] && x.FileType == 1) || this.notifyList.find((x: any) => x.Id == msg['id'])))
            {
                    switch(BaseServices.UserRole)
                    {
                        case 'Admin': 
                            {
                                    if(msg["applicationId"] == BaseServices.ApplicationId)
                                    {
                                        if(BaseServices.ApplicationId == 4 && (item.FileType == 1)) {
                                           if(BaseServices.UserId == item.UserId)
                                           {
                                                this.fdvNotificationList.push[0](item);
                                                if(item.ProgressPercentage == 100 )
                                                    this.notificationCount = this.notificationCount + 1;
                                                this.showDropDown = true;
                                                this.notifications = false; 
                                           } 
                                        } else {
                                            this.notifyList.unshift(item);    
                                            this.notificationCount = this.notificationCount + 1;   
                                        }
                                    }
                                    break;
                            }
                        case 'Portal':
                            {
                                    if(msg["applicationId"] == BaseServices.ApplicationId || msg["portalId"] == BaseServices.PortalId)
                                    {
                                        if(BaseServices.ApplicationId == 4 && (item.FileType == 1)) {
                                            if(BaseServices.UserId == item.UserId)
                                            {
                                                 this.fdvNotificationList.unshift(item);
                                                 if(item.ProgressPercentage == 100 )
                                                     this.notificationCount = this.notificationCount + 1;
                                                this.showDropDown = true;
                                                this.notifications = false; 
                                            } 
                                        } else {
                                            this.notifyList.unshift(item);    
                                            this.notificationCount = this.notificationCount + 1;   
                                        }
                                    }
                                    break;
                            }
                        case 'Editor':
                            {
                                    if(msg["applicationId"] == BaseServices.ApplicationId || msg["portalId"] == BaseServices.PortalId || msg["businessId"] == BaseServices.BusinessId)
                                    {
                                        if(BaseServices.ApplicationId == 4 && (item.FileType == 1)) {
                                            if(BaseServices.UserId == item.UserId)
                                           {
                                                this.fdvNotificationList.unshift(item);
                                                if(item.ProgressPercentage == 100 )
                                                    this.notificationCount = this.notificationCount + 1;
                                                this.showDropDown = true;
                                                this.notifications = false; 
                                           }   
                                        } else {
                                            this.notifyList.unshift(item);    
                                            this.notificationCount = this.notificationCount + 1;   
                                        }
                                    }
                                    break;
                            }
                        case 'Guest':
                            {
                                    if(msg["applicationId"] == BaseServices.ApplicationId || msg["portalId"] == BaseServices.PortalId || msg["businessId"] == BaseServices.BusinessId)
                                    {
                                        if(BaseServices.ApplicationId == 4 && (item.FileType == 1)) {
                                            if(BaseServices.UserId == item.UserId)
                                            {
                                                 this.fdvNotificationList.unshift(item);
                                                 if(item.ProgressPercentage == 100 )
                                                     this.notificationCount = this.notificationCount + 1;
                                                this.showDropDown = true;
                                                this.notifications = false; 
                                            } 
                                        } else {
                                            this.notifyList.unshift(item);    
                                            this.notificationCount = this.notificationCount + 1;   
                                        }
                                    }
                                    break;
                            }
                        case 'User':
                            {
                                    if(msg["applicationId"] == BaseServices.ApplicationId || msg["portalId"] == BaseServices.PortalId || msg["businessId"] == BaseServices.BusinessId)
                                    {
                                        if(BaseServices.ApplicationId == 4 && (item.FileType == 1 )) {
                                            if(BaseServices.UserId == item.UserId)
                                            {
                                                 this.fdvNotificationList.unshift(item);
                                                 if(item.ProgressPercentage == 100 )
                                                     this.notificationCount = this.notificationCount + 1;
                                                this.showDropDown = true;
                                                this.notifications = false; 
                                            } 
                                        } else {
                                            this.notifyList.unshift(item);    
                                            this.notificationCount = this.notificationCount + 1;   
                                        }
                                    }
                                    break;
                            }
                    }
            }   
            this.allCaught = this.notifications ? (this.notifyList.length <= 0) : (this.fdvNotificationList.length <= 0);
        });

        connection.on("UpdateFDVProgress", (msg: any) => {
            let item  = new NotificationList();
            item.Id = msg["id"];
            item.Title = msg["title"];
            item.Description = msg["description"];
            item.Priority = msg["priority"];
            item.CreatedBy = msg["createdBy"];
            item.CreatedOn = msg["createdOn"];
            item.ModifiedBy = msg["modifiedBy"];
            item.ModifiedOn = msg["modifiedOn"];
            item.Status = msg["status"];
            item.FileType = msg["fileType"];
            item.UserId = msg["userId"];
            item.ProgressPercentage = msg["progressPercentage"] == null? 0 : msg["progressPercentage"]; 
            if(BaseServices.ApplicationId == 4 && (item.FileType == 1 )) {
                if(item.UserId == msg["userId"])
                {
                    var index = this.fdvNotificationList.findIndex((x: any) => x.Id == item.Id && x.FileType ==1);
                    if(index >=0 && this.fdvNotificationList[index].ProgressPercentage != 100 && item.ProgressPercentage == 100 )
                    {
                         this.notificationCount = this.notificationCount + 1;
                    }
                    this.fdvNotificationList[index] = item;
                    this.fdvNotificationList[index].ProgressPercentage = item.ProgressPercentage == null ? 0 : item.ProgressPercentage; 
                }
             } 
        });

        connection.on('PushNotificationFDVDownload', (msg:any) => {
            let item = new NotificationList();
            item.Id = msg["id"];
            item.Title = msg["fileName"];
            item.ProgressPercentage = msg["progressPercentage"];
            item.JsonFdvBulkReport = msg["jsonFdvBulkReport"];
            item.FileType = msg["fileType"];
            item.Description = msg["filePath"];
            item.DownloadExpired = msg["downloadExpired"];
            item.UserId = msg["userId"];
            if(!(this.fdvNotificationList.find((x: any)=> x.Id == msg['id'] && x.FileType == 2)) && msg["applicationId"] == BaseServices.ApplicationId && msg["businessId"] == BaseServices.BusinessId && msg["userId"] == BaseServices.UserId)
            {
                this.fdvNotificationList.unshift(item);
                this.showDropDown =  true;
                this.notifications = false;        
            } else {
                var index = this.fdvNotificationList.findIndex((x: any) => x.Id == item.Id && x.FileType == 2);
                this.fdvNotificationList[index].Description = item.Description;
                this.fdvNotificationList[index].DownloadExpired = item.DownloadExpired;
                if(index >= 0 && this.fdvNotificationList[index].ProgressPercentage != 100  && item.UserId == BaseServices.UserId)
                {
                    this.fdvNotificationList[index].ProgressPercentage = item.ProgressPercentage;
                    if(item.ProgressPercentage == 100)
                        this.notificationCount = this.notificationCount + 1;
                } 
            }
            this.allCaught = this.notifications ? (this.notifyList.length <= 0) : (this.fdvNotificationList.length <= 0);
        });

        connection.on('PushNotificationBulkDownload', (msg:any) => {
            let item = new NotificationList();
            item.Id = msg["id"];
            item.Title = msg["fileName"];
            item.ProgressPercentage = msg["progressPercentage"] ? msg["progressPercentage"] : 0;
            item.FileType = 3;
            item.Description = msg["filePath"];
            item.Name = msg["loginName"];
            if(!(this.notifyList.find((x: any)=> x.Id == msg['id'] && x.FileType == 3)) && msg["businessId"] == BaseServices.BusinessId && msg["loginName"] == BaseServices.Name)
            {
                this.notifyList.unshift(item);
                this.showDropDown =  true;
                this.notifications = true;     
            } else {
                var index = this.notifyList.findIndex((x: any) => x.Id == item.Id && x.FileType == 3);
                this.notifyList[index].Description = item.Description;
                if(index >= 0 && this.notifyList[index].ProgressPercentage != 100  && item.Name == BaseServices.Name)
                {
                    this.notifyList = this.notifyList.filter((x: any) => x.Id != item.Id);
                    this.notifyList.unshift(item);
                    if(item.ProgressPercentage == 100)
                        this.notificationCount = this.notificationCount + 1;
                } 
            }
            this.allCaught = this.notifications ? (this.notifyList.length <= 0) : (this.fdvNotificationList.length <= 0);
        });
    }

    //#endregion

    //#region control-event

    /**
     * make notification read
     */
    markAsRead() {
        this.subscriptions.push(this.notificationService.markNotificationsRead().subscribe(result => {
            this.fdvNotificationList = this.fdvNotificationList.filter(x=> x.ProgressPercentage != 100)
            this.notifyList = [];
            this.notificationCount = 0;
            this.allCaught =  this.allCaught = this.notifications ? (this.notifyList.length <= 0) : (this.fdvNotificationList.length <= 0);
        }));
    }

    /**
     * show popup
     * @param notification {any}
     */
    showTitlePopup(notification: any) {
        this.showNewNotification = true;
        this.isfdvBulkDownload = false;
        this.isBulkReport = notification.FileType && notification.FileType == 3?  true :  false;
        this.notificationTitle = notification.Title;
        this.notificationDesc = notification.Description;
        this.percentage = notification.ProgressPercentage ? notification.ProgressPercentage : 0;
        let replaceKey = ['EFO_DETAILS','EFO_ID','EFO_FILENAME','PROJECT_NAME','EFO_TOTAL','EFO_SUCESS','EFO_FAILED','EFO_DUPLICATE'];
        replaceKey.forEach(element => {
            this.notificationDesc = this.notificationDesc.replace(element, this.translate.instant(element));
        });
    }
    showfdvTitlePopup(file: any) {
        this.showNewNotification = true;
        this.isfdvBulkDownload = true;
        this.fdvReportId = file.Id;
        this.notificationTitle = file.Title;
        this.notificationDesc = (file.DownloadExpired && file.DownloadExpired == 1) ?  file.Description : null;
        this.percentage = file.ProgressPercentage;
        this.fdvBulkSelectedData = <any>JSON.parse(file.JsonFdvBulkReport);
        
        this.downloadExpired = file.DownloadExpired;
    }

    fdvBulkDownload(ReportId: any,url: any){
        const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.href = url;
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            this.subscriptions.push(this.notificationService.updateFdvReportDownloadDate(ReportId).subscribe(result => {
        }));
    }
    /**
     * reset popup
     */
    closePopUp() {
        this.notificationTitle = '';
        this.notificationDesc = '';
        this.percentage = null!;
        this.isfdvBulkDownload = false;
        this.showNewNotification = false;
    }
    toggleNotificationTab() {
      this.notifications = !this.notifications;
      this.allCaught = (this.notifications && this.notifyList.length <= 0) || (!this.notifications && this.fdvNotificationList.length <= 0);
    }
    dropdownToggle() {
        this.showDropDown = this.showDropDown ? false : true;
        this.fdvNotifications = (BaseServices.ApplicationId == 5) ? false : true;
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
