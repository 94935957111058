import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Client } from './../../client/models/client';
import { ClientServices } from './../../client/services/client.services';
import { BaseServices } from './../../kuba.services';
import { UserService } from './../../users/services/user.service';
import { ClientUser, ContactPerson, Contractor } from './../models/projectEdit';
import { ProjectServices } from './project.service';
import { ProjectFDVStatus } from './../models/projectFdvStatus';
import { ProjectList } from '../models/projectlist';

@Injectable()
export class ProjectListResolver implements Resolve<ProjectList[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ProjectList[]> {
    return this.backend.getlistByBusiness(BaseServices.BusinessId);
  }
}
@Injectable()
export class ProjectListByUserResolver implements Resolve<ProjectList[]> {
  constructor(private backend: ProjectServices) {
    let userRole = BaseServices.UserRole;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectList[]> {
    if (window.name !== 'Remote') {
      return this.backend.getProjectByUser(
        BaseServices.UserId,
        BaseServices.BusinessId
      );
    }
    return null!;
  }
}
@Injectable()
export class MyProjectListResolver implements Resolve<ProjectList[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ProjectList[]> {
    return this.backend.getlistByBusiness(BaseServices.BusinessId); // have to pass userid
  }
}
@Injectable()
export class ProjectEditResolver implements Resolve<ProjectList[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ProjectList[]> {
    return this.backend.getProjectById(+route.params['id']);
  }
}
@Injectable()
export class ClientListResolver implements Resolve<Client[]> {
  constructor(private backend: ClientServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Client[]> {
    return this.backend.getAllClients();
  }
}
@Injectable()
export class ProjectFdvStatusResolver implements Resolve<ProjectFDVStatus[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ProjectFDVStatus[]> {
    return this.backend.getProjectFDVStatus(+route.params['id']);
  }
}

@Injectable()
export class UserListResolver implements Resolve<ContactPerson[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ContactPerson[]> {
    return this.backend.getUser(BaseServices.BusinessId);
  }
}
@Injectable()
export class ProjectNameListResolver implements Resolve<any[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getProjectList(BaseServices.BusinessId);
  }
}
@Injectable()
export class SubContractorListResolver implements Resolve<Contractor[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Contractor[]> {
    return this.backend.getSubContractor(BaseServices.BusinessId);
  }
}
@Injectable()
export class ClientUserListResolver implements Resolve<ClientUser[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ClientUser[]> {
    return this.backend.getClientUser(BaseServices.BusinessId);
  }
}
@Injectable()
export class ResponsibiltyListResolver implements Resolve<any[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getResponsibility();
  }
}

@Injectable()
export class ProjectFeaturesResolver implements Resolve<any[]> {
  constructor(private backend: ProjectServices) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.backend.getProjectFeatures(BaseServices.BusinessId);
  }
}
