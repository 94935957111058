import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: 'ruh.component.html'
})

export class RuhComponent implements OnInit {
    constructor() { }

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }
}