import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    templateUrl: 'foodsafety-documents.component.html'
})
export class FoodSafetyDocumentComponent implements OnInit {
    showEditPortal = false;

    constructor( private fb: FormBuilder) {

    }

    ngOnInit(): void {

    }

}
