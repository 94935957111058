//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import {
    HelperService,
    PdfSetting
} from './../../../../_services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Options } from './../../../../_models/options';
import { BaseServices } from './../../../kuba.services';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';
import { InstrumentType, ModelType, Instrument } from '../../models/instrument';
import { InstrumentList } from '../../models/measure-protocol';
import { DateSetting } from './../../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-used-instruments',
    templateUrl: 'measure-protocol-used-instruments.component.html'
})
export class MeasureProtocolUsedInstrumentComponent extends DateSetting implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolUsedInstrument: FormGroup;
    instrumentTypeForm: FormGroup;
    instrumentModelForm: FormGroup;

    types: SelectItem[];
    editTypes: any = [];
    models: SelectItem[];
    editModels: any = [];
    viewType = false;

    kundeELId: number;
    parentKey: any;
    cmpId: number;
    instrumentTypeId: number;
    instrumentModelId: number;

    CMPinstruments: InstrumentList[];
    usedInstrument: any;
    usedInstrumentId: any;
    createdOn: any;
    createdBy: any;
    addModel = false;
    addType = false;
    featureId: number;
    saveprogress: boolean = false;
    private subscriptions: Subscription[] = [];
    /**
     * Date picker configuration option
     */
    //public CalibratedDateOptions: IMyDpOptions = this.defaultDateOptions;

    public CalibratedDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        private confirmationService: ConfirmationService,
        public measureprotocolservice: MeasureProtocolService,
        private translate: TranslateService
    ) {
        super();
        this.measureProtocolUsedInstrument = this.fb.group({
            InstrumentTypeId: ['', Validators.required],
            InstrumentModelId: ['', Validators.required],
            SerialNumber: [''],
            CalibratedDate: ['', Validators.required]
        });

        this.instrumentTypeForm = this.fb.group({
            Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        });

        this.instrumentModelForm = this.fb.group({
            Name: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.kundeELId = this.route.snapshot.parent.parent.parent.params['id'];
        this.parentKey = this.route.snapshot.parent.parent.parent.data[
            'parent'
        ];
        this.cmpId = this.route.snapshot.parent.params['mpcid'];
        this.bindDetails();
        this.CMPinstruments = [];
        this.CMPinstruments = this.route.snapshot.data['cmpListInt'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule ? workingModule.id : 0;
        if (this.featureId == FeatureKey.ELECTRO) {
            this.kundeELId = 0;
            this.parentKey = 'KUNDE_EL'
        }
    }

    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate()
        };
    }

    /**
     * get all instrument list
     */
    getAllCMPInstruments() {
        this.subscriptions.push(this.measureprotocolservice
            .getAllCMPInstruments(this.cmpId)
            .subscribe((result: any) => (this.CMPinstruments = result)));
    }

    bindDetails() {
        this.types = [];
        this.editTypes = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.types = [];
            this.types.push({ label: val.SELECT, value: null });
            let result = this.route.snapshot.data['instrumentType'];
            if (result) {
                result.forEach((instType: any) => {
                    this.types.push({ label: instType.Name, value: instType.Id });
                    this.editTypes.push({ Name: instType.Name, Id: instType.Id });
                });
            }
        }));


        this.models = [];
        this.editModels = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.models = [];
            this.models.push({ label: val.SELECT, value: null });
            let Modelresult = this.route.snapshot.data['modelType'];
            if (Modelresult) {
                Modelresult.forEach((modelType: any) => {
                    this.models.push({ label: modelType.Name, value: modelType.Id });
                    this.editModels.push({ Name: modelType.Name, Id: modelType.Id });
                });
            }
        }));

    }

    /**
     * view type list
     */
    viewInstrumentType() {
        if (this.featureId == FeatureKey.ELECTRO) {
            this.subscriptions.push(this.measureprotocolservice.getElectroBusinessInstrumentTypes().subscribe((instType: any) => {
                if (instType) {
                    this.types = [];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.types = [];
                        this.types.push({ label: val.SELECT, value: null });
                    }));
                    instType.forEach((instType: any) => {
                        this.types.push({ label: instType.Name, value: instType.Id });
                    });
                }
                this.editTypes = instType;
            }))
        } else {
            this.subscriptions.push(this.measureprotocolservice.getAllByType(this.kundeELId, this.parentKey).subscribe(instType => {
                if (instType) {
                    this.types = [];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.types = [];
                        this.types.push({ label: val.SELECT, value: null });
                    }));
                    instType.forEach((instType: any) => {
                        this.types.push({ label: instType.Name, value: instType.Id });
                    });
                }
                this.editTypes = instType;
            }));
        }

    }

    /**
     * view model list
     */
    viewModelType() {
        if (this.featureId == FeatureKey.ELECTRO) {
            this.subscriptions.push(this.measureprotocolservice.getElectroBusinessInstrumentModels().subscribe((modelType: any) => {
                if (modelType) {
                    this.models = [];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.models = [];
                        this.models.push({ label: val.SELECT, value: null });
                    }));
                    modelType.forEach((modelType: any) => {
                        this.models.push({ label: modelType.Name, value: modelType.Id });
                    });
                }
                this.editModels = modelType;
            }));
        } else {
            this.subscriptions.push(this.measureprotocolservice.getAllByModel(this.kundeELId, this.parentKey).subscribe(modelType => {
                if (modelType) {
                    this.models = [];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.models = [];
                        this.models.push({ label: val.SELECT, value: null });
                    }));
                    modelType.forEach((modelType: any) => {
                        this.models.push({ label: modelType.Name, value: modelType.Id });
                    });
                }
                this.editModels = modelType;
            }));
        }

    }

    clearType() {
        this.instrumentTypeId = 0;
        this.instrumentTypeForm = this.fb.group({
            Name: ['']
        });
    }

    clearModel() {
        this.instrumentModelId = 0;
        this.instrumentModelForm = this.fb.group({
            Name: ['']
        });
    }

    oninstrumentTypeFormHide() {
        this.instrumentTypeForm.reset();
    }
    oninstrumentModelFormHide() {
        this.instrumentModelForm.reset();
    }

    /**
     * save type of instrument
     */
    saveType() {
        let instrumentType = new InstrumentType();
        instrumentType.Id = this.instrumentTypeId ? this.instrumentTypeId : 0;
        instrumentType.Name = this.instrumentTypeForm.value.Name;
        instrumentType.KundeELId = this.kundeELId;
        instrumentType.Status = '1';
        instrumentType.CreatedOn = new Date();
        instrumentType.CreatedBy = BaseServices.UserId;
        instrumentType.BusinessId = BaseServices.BusinessId;
        instrumentType.FeatureId = this.featureId;
        if (instrumentType.Id > 0) {
            instrumentType.ModifiedOn = new Date();
            instrumentType.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice
                .updateInsType(instrumentType, this.parentKey, this.kundeELId)
                .subscribe((instrumentEdited: any) => {
                    if (instrumentEdited) {
                        this.viewInstrumentType();
                        this.toasterComponent.callToast();
                        this.measureProtocolUsedInstrument
                            .get('InstrumentTypeId')
                            .patchValue(instrumentEdited.Id);
                        this.addType = false;
                    }
                }));
        } else {
            this.subscriptions.push(this.measureprotocolservice
                .createInsType(instrumentType, this.parentKey, this.kundeELId)
                .subscribe((instrumentAdded: any) => {
                    if (instrumentAdded) {
                        this.viewInstrumentType();
                        this.toasterComponent.callToast();
                        this.measureProtocolUsedInstrument
                            .get('InstrumentTypeId')
                            .patchValue(instrumentAdded.Id);
                        this.addType = false;
                    }
                }));
        }
        this.clearType();
    }

    saveModel() {
        let modelType = new ModelType();
        modelType.Id = this.instrumentModelId ? this.instrumentModelId : 0;
        modelType.Name = this.instrumentModelForm.value.Name;
        modelType.KundeELId = this.kundeELId;
        modelType.Status = '1';
        modelType.CreatedBy = BaseServices.UserId;
        modelType.CreatedOn = new Date();
        modelType.BusinessId = BaseServices.BusinessId
        modelType.FeatureId = this.featureId;

        if (modelType.Id > 0) {
            modelType.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice.updateModel(modelType, this.parentKey, this.kundeELId)
                .subscribe((modelEdited: any) => {
                    if (modelEdited) {
                        this.viewModelType();
                        this.toasterComponent.callToast();
                        this.measureProtocolUsedInstrument
                            .get('InstrumentModelId')
                            .patchValue(modelEdited.Id);
                        this.addModel = false;
                    }
                }));
        } else {
            this.subscriptions.push(this.measureprotocolservice.createModel(modelType, this.parentKey, this.kundeELId)
                .subscribe((modelAdded: any) => {
                    if (modelAdded) {
                        this.viewModelType();
                        this.toasterComponent.callToast();
                        this.measureProtocolUsedInstrument
                            .get('InstrumentModelId')
                            .patchValue(modelAdded.Id);
                        this.addModel = false;
                    }
                }));
        }
        this.clearModel();
    }

    /**
     * edit type of registration
     */
    editType(instrumentType: InstrumentType) {
        this.instrumentTypeId = instrumentType.Id;
        let result = {
            Name: instrumentType.Name
        };
        (<FormGroup>this.instrumentTypeForm).setValue(result, {
            onlySelf: true
        });
    }

    /**
     * edit model of registration
     */
    editModel(modelType: ModelType) {
        this.instrumentModelId = modelType.Id;
        let result = {
            Name: modelType.Name
        };
        (<FormGroup>this.instrumentModelForm).setValue(result, {
            onlySelf: true
        });
    }

    /**
     * delete instrument
     * @param instrumentType
     */
    deleteType(instrumentType: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                let deleteTypeid = instrumentType.Id;
                this.subscriptions.push(this.measureprotocolservice
                    .deleteInsType(deleteTypeid, this.kundeELId, this.parentKey)
                    .subscribe(() => {
                        this.viewInstrumentType();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }

    /**
     * delete instrument
     * @param instrumentModel
     */
    deleteModel(instrumentModel: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                let deleteModelid = instrumentModel.Id;
                this.subscriptions.push(this.measureprotocolservice
                    .deleteModelType(
                        deleteModelid,
                        this.kundeELId,
                        this.parentKey
                    )
                    .subscribe(() => {
                        this.viewModelType();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }

    /**
     * clearing the data in the form
     */
    onClear() {
        this.saveprogress = false;
        this.usedInstrumentId = 0;
        this.measureProtocolUsedInstrument = this.fb.group({
            InstrumentTypeId: ['', Validators.required],
            InstrumentModelId: ['', Validators.required],
            SerialNumber: [''],
            CalibratedDate: ['', Validators.required]
        });
    }

    saveChanges() {
        this.saveprogress = true;
        let instrument = new Instrument();
        instrument.Id = this.usedInstrumentId ? this.usedInstrumentId : 0;
        instrument.InstrumentTypeId = this.measureProtocolUsedInstrument.value.InstrumentTypeId;
        instrument.InstrumentModelId = this.measureProtocolUsedInstrument.value.InstrumentModelId;
        instrument.SerialNumber = this.measureProtocolUsedInstrument.value.SerialNumber;
        instrument.CalibratedDate = HelperService.formatDateFilter(this.measureProtocolUsedInstrument.value.CalibratedDate.formatted);
        instrument.MeasureProtocolId = 0;
        instrument.MeasureProtocolCompleteId = this.cmpId;
        instrument.Status = '1';
        instrument.BusinessId = BaseServices.BusinessId
        instrument.FeatureId = this.featureId;
        if (instrument.Id > 0) {
            instrument.CreatedBy = this.createdBy;
            instrument.CreatedOn = this.createdOn;
            instrument.ModifiedBy = BaseServices.UserId;
            instrument.ModifiedOn = new Date();
            if (this.featureId == FeatureKey.ELECTRO) {
                this.subscriptions.push(this.measureprotocolservice.updateElectroInstrument(instrument).subscribe(result => {
                    this.toasterComponent.callToast();
                    this.subscriptions.push(this.measureprotocolservice.getElectroBusinessInstruments().subscribe((ins: any) => {
                        this.CMPinstruments = ins;
                    }));
                    this.onClear();
                }));
            } else {
                this.subscriptions.push(this.measureprotocolservice.updateInstrument(instrument).subscribe(result => {
                    this.toasterComponent.callToast();
                    this.getAllCMPInstruments();
                    this.onClear();
                }));
            }

        } else {
            instrument.CreatedBy = BaseServices.UserId;
            instrument.CreatedOn = new Date();
            if (this.featureId == FeatureKey.ELECTRO) {
                this.subscriptions.push(this.measureprotocolservice.createElectroInstrument(instrument).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.subscriptions.push(this.measureprotocolservice.getElectroBusinessInstruments().subscribe((ins: any) => {
                            this.CMPinstruments = ins;
                        }));
                        this.onClear();
                    }
                }));
            } else {
                this.subscriptions.push(this.measureprotocolservice.createCMPInstrument(instrument).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.getAllCMPInstruments();
                        this.onClear();
                    }
                }));
            }
        }
    }

    /**
     * editing employees first of kin
     * @param Id {number}
     */

    editInstrument(id: number) {
        this.subscriptions.push(this.measureprotocolservice
            .getInstrumentById(this.cmpId, id)
            .subscribe((usedInstrument: any) => {
                if (usedInstrument) {
                    this.createdOn = usedInstrument.CreatedOn;
                    this.createdBy = usedInstrument.CreatedBy;
                    this.usedInstrumentId = id;
                    this.usedInstrument = {
                        InstrumentTypeId: usedInstrument.InstrumentTypeId,
                        InstrumentModelId: usedInstrument.InstrumentModelId,
                        SerialNumber: usedInstrument.SerialNumber,
                        CalibratedDate: HelperService.formatInputDate(
                            usedInstrument.CalibratedDate
                        )
                    };
                    (<FormGroup>this.measureProtocolUsedInstrument).setValue(
                        this.usedInstrument,
                        { onlySelf: true }
                    );
                }
            }));
    }

    /**
     * delete instrument based on id
     * @param  inst
     */
    deleteInstrument(instrumentId) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.measureprotocolservice.deleteInstrument(instrumentId).subscribe(result => {
                    if (this.featureId == FeatureKey.ELECTRO) {
                        this.subscriptions.push(this.measureprotocolservice.getElectroBusinessInstruments().subscribe((ins: any) => {
                            this.CMPinstruments = ins;
                        }));
                    } else {
                        this.getAllCMPInstruments();
                    }
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
