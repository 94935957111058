import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ElectroList, Electro,LarList, ContractDetail, ElectroEnabledManuals, ElectroContractContentData } from '../models/electrocontract';
import { ElectroService } from './electro.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { InspectionList, ElectroInspection, CheckList, InspectionChartModel } from '../models/electroinspection';
import { ElectroDeviation, DeviationList } from '../models/electrodeviation';
import { ElectroInstallationService } from './electro-installation.services';
import { TreeData } from 'src/app/kuba/common/models';
import { ElectroDocumentService } from './electro-document.services';
import { ElectroInstallationBuliding } from '../models/electroinstallation';
import { ElectroChecklistCompletion } from '../models/electrochecklistcompletion';
import { ElectroFeature } from '../models/electrofeatures';
import { CompleteCheckListServices } from 'src/app/kuba/shared/services/complete-checklist.service';
import { Client } from 'src/app/kuba/follow-ups/models/deviation';
import { HelperService } from 'src/app/_services/helper.service';
import { Rights, FeatureKey } from 'src/app/_models';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';

@Injectable()
export class ElectroListByBusinessResolver implements Resolve<ElectroList[]> {
    constructor(private backend: ElectroService) { }
    resolve() {
        if(BaseServices.roleId == '3' || HelperService.ApprovalPersonRightsCheck(
            JSON.parse(BaseServices.getUserRights()), Rights.SERVICE_LEADER_ELECTRO)){
                return this.backend.getContractsbybusiness();
        }
        return this.backend.getContractByUserAssociated();
    }
}
@Injectable()
export class ContractWorkersResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getContractWorkers();
    }
}
@Injectable()
export class ContractContentResolver implements Resolve<ElectroContractContentData[]>{
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getContractContent();
    }

}
@Injectable()
export class ElectroEditResolver implements Resolve<Electro[]> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return +route.parent.params['cid'] > 0 ?
            this.backend.getContractById(+route.parent.params['cid']) : null;
    }
}
@Injectable()
export class ControlScopeBuildingsResolver implements Resolve<ElectroInstallationBuliding[]> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return +route.parent.params['cid'] > 0 ?
            this.backend.getInstallationBuilding(+route.parent.params['cid']) : null;
    }
}

@Injectable()
export class InspectionScopeResolver implements Resolve<ElectroInstallationBuliding[]> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return +route.parent.params['cid'] > 0 ?
            this.backend.getInspectionScope(+route.parent.params['cid']) : null;
    }
}

@Injectable()
export class InspecitonChartResolver implements Resolve<InspectionChartModel[]>{
    constructor(private backend: ElectroService) { }
    resolve() {
        return this.backend.getInspectionChartData();
    }
}
@Injectable()
export class InspectionListByBusinessResolver implements Resolve<InspectionList[]> {
    constructor(private backend: ElectroService) { }
    resolve() {
        return this.backend.getInspectionsbybusiness();
    }
}

@Injectable()
export class AssignedWorkersResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getAssignedWorkers(+route.parent.params['cid']);
    }
}

@Injectable()
export class AssignedWorkersByInspectionResolver implements Resolve<any[]>{
    
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.params['Iid'] > 0 ?
        this.backend.getAssignedWorkersByInspection(+route.params['Iid']): null;
    }
}
@Injectable()
export class BuildingsByInspectionResolver implements Resolve<any[]>{
    
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.params['Iid'] > 0 ?
        this.backend.getBuildingsByInspection(+route.params['Iid']): null;
    }
}
@Injectable()
export class AssignedTasksResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        let insId = +route.parent.params['Iid'] > 0 ? +route.parent.params['Iid'] : 0;
        return this.backend.getAssignedTasks(+route.parent.params['cid'], insId);
    }
}
@Injectable()
export class ContractClientContactInfoResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getClientContactInfo(+route.parent.params['cid']);
    }
}

@Injectable()
export class InspectionEditResolver implements Resolve<ElectroInspection[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.parent.params['Iid'] > 0 ?
            this.backend.getInspectionById(+route.parent.params['Iid']) : null;
    }
}

@Injectable()
export class DeviationListByBusinessResolver implements Resolve<DeviationList[]>{
    constructor(private backend: ElectroService){}
    resolve(): Observable<DeviationList[]>{
        return this.backend.getDeviationByBusiness();
    }
}

@Injectable()
export class DevInspectionResolver implements Resolve<ElectroInspection[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.parent.parent.params['Iid'] > 0 ?
            this.backend.getInspectionById(+route.parent.parent.params['Iid']) : null;
    }
}

@Injectable()
export class DeviationEditResolver implements Resolve<ElectroDeviation[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.params['Did'] > 0 ?
        this.backend.getDeviationById(+route.params['Did']) : null;
    }
}
//Organization and Responsibilities
@Injectable()
export class OrganizationListByBusinessResolver implements Resolve<InspectionList[]> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot): any {
        return this.backend.getArticlesByBusinessFeature(ElectroFeature.ORGANIZATION_AND_RESPONSIBILTIES,
            BaseServices.UserRole !== 'Admin'? +route.params['Id'] : 0);
    }
}

@Injectable()
export class InternalControlListByBusinessResolver implements Resolve<InspectionList[]> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot): any {
        return this.backend.getArticlesByBusinessFeature(ElectroFeature.INTERNAL_CONTROL_SYSTEM,
            BaseServices.UserRole !== 'Admin'? +route.params['Id'] : 0);
    }
}

@Injectable()
export class LawsListByBusinessResolver implements Resolve<LarList[]> {
    constructor(private backend: ElectroService) { }
    resolve(): any {
        return this.backend.getLawsList();
    }
}

@Injectable()
export class RegulationsListByBusinessResolver implements Resolve<LarList[]> {
    constructor(private backend: ElectroService) { }
    resolve(): any {
        return this.backend.getRegulationsList();
    }
}

@Injectable()
export class InspectionReportListByBusinessResolver implements Resolve<InspectionList[]> {
    constructor(private backend: ElectroService) { }
    resolve(): any {
        return this.backend.getInspectionReportbybusiness();
    }
}

@Injectable()
export class ReportCheckListResolver implements Resolve<CheckList[]> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getReportChecklist(+route.parent.params['Id']);
    }
}
@Injectable()
export class ElectroInfoResolver implements Resolve<Electro> {
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return +route.parent.parent.params['cid'] > 0 ?
            this.backend.getContractInfoById(+route.parent.parent.params['cid']) : null;
    }
}
@Injectable()
export class ElectroContractDetailById implements Resolve<ContractDetail>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getContractDetailById(+route.parent.parent.params['cid']);
    }
}

@Injectable()
export class ElectroManualListResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){  
        return this.backend.getElectroManualByContractId(+route.parent.params['cid'],route.url[0].path === "editcontent" ?1 :2);
    }
}

@Injectable()
export class ElectroEnabledManualsResolver implements Resolve<ElectroEnabledManuals>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getEnabledElectroManualByContractId(+route.parent.params['cid']);
    }
}

// installation
@Injectable()
export class EnabledInstallationFormsResolver implements Resolve<any[]>{
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getEnabledForms(+route.parent.parent.params['cid']);
    }
}

@Injectable()
export class InstallationFormsBuildingsTreeResolver implements Resolve<any[]>{
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getInstallationFormsBuildingsTree(+route.parent.parent.params['cid']);
    }
}
@Injectable()
export class ElectroArchiveTreeDataResolver implements Resolve<any[]> {
    constructor(private backend: ElectroService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<TreeData[]> {
        return this.backend.getElectroArchiveTree(BaseServices.BusinessId,+route.parent.params['cid']);
    }
}

@Injectable()
export class EnabledChecklistResolver implements Resolve<any[]>{
    constructor(private backend: ElectroDocumentService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getContractEnabledChecklists(+route.parent.params['cid']);
    }
}

//settings
@Injectable()
export class ElectroSettingsResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getSettingsById();
    }
}
@Injectable()
export class ElectroChecklistTemplateResolver implements Resolve<ElectroChecklistCompletion>{
    constructor(private backend: ElectroDocumentService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.params['clId'] ? 
        this.backend.getChecklistTemplateById(+route.params['clId']) : null;
    }
}

@Injectable()
export class ElectroCompletionChecklistDataResolver implements Resolve<ElectroChecklistCompletion>{
    constructor(private backend: ElectroDocumentService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.params['cClId'] ? 
        this.backend.getCompletionChecklistdata(+route.params['cClId']) : null;
    }
}

@Injectable()
export class ContractBuildingsResolver implements Resolve<ElectroInstallationBuliding[]>{
    constructor(private backend: ElectroInstallationService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroInstallationBuliding[]> {
        return this.backend.getContractBuildings(+route.params['cid']);
    }
}

@Injectable()
export class EnabledReportsResolver implements Resolve<any[]>{
    constructor(private backend: ElectroDocumentService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getContractEnabledChecklists(+route.params['cid']);
    }
}

@Injectable()
export class ChecklistBuildingtreeResolver implements Resolve<any[]>{
    constructor(private backend: ElectroDocumentService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getInspectionChecklistBuildingtree(+route.parent.params['cid'],+route.parent.params['Iid']);
    }
}

@Injectable()
export class InspectionByContractResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getInspectionsbyContract(+route.parent.params['cid']);
    }
}

@Injectable()
export class InstallerInspectionByContractResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getInstallerInspectionsbyContract(+route.parent.parent.params['Id']);
    }
}

@Injectable()
export class ClientInspectionByContractResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getClientInspectionsbyContract(+route.parent.parent.params['Id']);
    }
}
@Injectable()
export class DeviationsbyInspectionResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getDeviationsbyInspection(+route.parent.parent.params['Iid']);
    }
}

@Injectable()
export class DeviationListByContractResolver implements Resolve<DeviationList[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot): Observable<DeviationList[]>{
        return this.backend.getDeviationsbyContract(+route.parent.parent.params['Id']);
    }
}

@Injectable()
export class ElectroEnabledFeaturesResolver implements Resolve<ElectroEnabledManuals>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getEnabledElectroManualByContractId(+route.params['cid']);
    }
}

@Injectable()
export class InspectionEnabledTasksResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getInspectionTasks(+route.parent.params['Iid']);
    }
}

@Injectable()
export class EnabledFormsResolver implements Resolve<any[]>{
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getEnabledForms(+route.params['cid']);
    }
}
@Injectable()
export class ViolatedParaResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.GetViolatedParaReference();
    }
}
@Injectable()
export class ClientNumListResolver implements Resolve<any[]> {
    constructor(private backend: ElectroService) { }
    resolve() {
        return this.backend.getClientWithClientNum();
    }
}

@Injectable()
export class ClientUsersBySelfInspectionResolver implements Resolve<any[]>{   
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return +route.parent.params['cid'] > 0 ?
         this.backend.GetClientUsersBySelfInspection(+route.parent.params['cid']): null;
    }
}
@Injectable()
export class ElectroMeasureProtocolResolver implements Resolve<any[]>{   
    constructor(private backend: MeasureProtocolService){}
    resolve(route: ActivatedRouteSnapshot){
        if(+route.params['cid']){
            return this.backend.getAllMeasureProtocol(FeatureKey.ELECTRO,+route.params['cid'], 'KUNDE_EL');
        }else{
            return this.backend.getAllMeasureProtocol(FeatureKey.ELECTRO,
                +route.parent.parent.params['cid'], 'KUNDE_EL');
        }
    }
}
@Injectable()
export class DeviationClientInfoResolver implements Resolve<any[]>{
    constructor(private backend: ElectroService){}
    resolve(route: ActivatedRouteSnapshot){
        return this.backend.getClientContactInfoByInspection(+route.params['Iid']);
    }
}