import { ConfirmationService } from 'primeng/api';
import { PropertyObject } from './../../projects/models/projectEdit';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';

import { BaseServices } from './../../kuba.services';
import { BusinessProperty, BusinessPropertyAndObject, BusinessPropertyType } from './../models/businessProperty';
import { BusinessPropertyService } from './../services/businessProperty.service';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from './../../../_services/helper.service';
import { PropertyObjects } from '../../FDV/models/fdv';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'property-nested-form',
    templateUrl: 'property-nested-form.component.html'
})

export class PropertyNestedFormComponent implements OnInit {

    //#region variable

    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    @ViewChild('propertyObject',{static:false}) propertyObject: Table;
    _businessProperty: BusinessProperty[];
    loading = false;
    objectArray: any[];
    showBusinessProperty: Boolean = false;
    showPropertyObjectTable: Boolean = false;
    propertyObjectData: any;
    selectedPropertyObjectId: number;
    propertyObjectForm: FormGroup;
    checkboxProperties = new FormArray([]);
    @Input() title: string;
    @Input() propertyType: any;
    @Input('data')
    set data(businessProperty: BusinessProperty[]) {
        if (businessProperty) {
            this._businessProperty = businessProperty;
            this.initForm();
        }
    }
    @Output() change: EventEmitter<number> = new EventEmitter<number>();
    public myForm: FormGroup;
    existsAlerttext: string;
    isNameExists: boolean;
    businessId: number;
    currentAddButtonIndex = 0;
    showSocialType: Boolean = false;
    isNameAllocated = false;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param _fb {FormBuilder}
     * @param route {ActivatedRoute}
     * @param busPropertyService {BusinessPropertyService}
     */
    constructor(
        private _fb: FormBuilder,
        private route: ActivatedRoute,
        private busPropertyService: BusinessPropertyService,
        private confirmationService: ConfirmationService,
        private translate: TranslateService

    ) {

        this.businessId = this.route.snapshot.parent?.params['bid'];
        this.propertyObjectForm = this._fb.group({
            Name: ['', [ValidationService.noWhitespaceValidator, Validators.required]]
        });
        this.myForm = this._fb.group({
            type: ['', Validators.required],
            Description: ['', Validators.required],
            Name: [''],
            ApartmentCount: [''],
            FloorCount: [''],
            checkboxProperties: this.checkboxProperties
        });
        // default prop object list for checkbox
        this.objectArray = route.snapshot.data['propObjects'] ? route.snapshot.data['propObjects'] : null;
        // propertyObject list in popup
        this.propertyObjectData = route.snapshot.data['ddlist'] ? route.snapshot.data['ddlist'] : null;
    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.initForm();
        this.propertyType = [];
            this.propertyType.push(
                { label: 'Select', value: null }
            );

        if (this.propertyObjectData) {
            this.propertyObjectData.forEach((propertyTypeData: any) => {
                this.propertyType.push({ label: propertyTypeData.Name, value: propertyTypeData.Id });
            });
        }
    }

    //#endregion

    get userFormGroups () {
        return this.myForm.get('pageProperties') as FormArray
      }
    //#region control-event

    bindListType() {
        this.propertyType = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.propertyType = [];
            this.propertyType.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.busPropertyService.listPropertyType(this.businessId).subscribe((type:any) => {
            if (type) {
                this.propertyObjectData = type;
                type.forEach((element:any) => {
                    this.propertyType.push({ label: element.Name, value: element.Id });
                });
            }
        }));
    }
    setCheckedState(id:any, index:any): boolean {
        let isCheck = false;
        if (this._businessProperty) {
            if (this._businessProperty[index].PropertyObjects) {
                this._businessProperty[index].PropertyObjects.forEach(props => {
                    isCheck = (isCheck) ? true : (props.BusinessPropertyObjectId === id);
                });
            }
        }
        return isCheck;
    }

    initForm() {
        let businessProperties: FormArray = new FormArray([]);
        this.myForm = this._fb.group({
            pageProperties: businessProperties
        });
        if (this._businessProperty) {
            // edit the businessPropertyObject
            this._businessProperty.forEach((element, index) => {
                this.addbusinessProperties(element);
            });
        } else {
            this.addbusinessProperties();
        }
    }
    /**
     * Adds a properties FormGroup to the businessProperty
     * @method addbusinessProperties
     * @param void
     * @return void
     */
    addbusinessProperties(businessProperty?: any): void {
        let type = '';
        let description = '';
        let name = '';
        let apartmentCount = '';
        let floorCount = '';
        let id = '';
        let status = 1;
        // Construct and populate the checkboxProperties FormArray
        // outside of the FormBuilder so we can populate it dynamically
        this.checkboxProperties = new FormArray([]);
        if (this.objectArray) {
            this.objectArray.forEach((element, index) => {
                let fg : any = new FormGroup({});
                // Quantity field for Common Recovery(S) Qty - [id = 7]
                if (element.Id === 7) {
                    fg.addControl('Quantity', new FormControl(0));
                }
                fg.addControl(`${index}`, new FormControl(
                    this.arrayCompare(businessProperty, this.objectArray[index].Id)
                ));
                this.checkboxProperties.push(fg);
            });
        }
        if (businessProperty) {
            type = businessProperty.BusinessPropertyTypeId ? businessProperty.BusinessPropertyTypeId : '';
            description = businessProperty.Description ? businessProperty.Description : '';
            name = businessProperty.Name ? businessProperty.Name : '';
            apartmentCount = businessProperty.ApartmentCount ? businessProperty.ApartmentCount : '';
            floorCount = businessProperty.FloorCount ? businessProperty.FloorCount : '';
            id = businessProperty.Id ? businessProperty.Id : '';
            status = businessProperty.Status ? businessProperty.Status : '1';
        }
        (<FormArray>this.myForm.controls['pageProperties']).push(
            new FormGroup({
                type: new FormControl(type, Validators.required),
                Description: new FormControl(description, Validators.required),
                Name: new FormControl(name),
                ApartmentCount: new FormControl(apartmentCount),
                FloorCount: new FormControl(floorCount),
                Id: new FormControl(id), // hidden value
                Status: new FormControl(status),
                BusinessId: new FormControl(BaseServices.BusinessId),
                checkboxProperties: this.checkboxProperties
            })
        )
    }

    removeProperties(i: number) {
        const control = <FormArray>this.myForm.controls['pageProperties'];
        if (control.value[i].Id) {
            let propId = control.value[i].Id;
            this.subscriptions.push(this.busPropertyService.deleteProperty(propId).subscribe(res => {
                control.removeAt(i);
            }))
        } else { // remove uncommited property
            control.removeAt(i);
        }
    }

    updateBusinessPropertyModel(e:any, index:any, businessPropertyObjectId:any) {
        if (this._businessProperty && index < this._businessProperty.length) {
            if (e.target.checked) {
                let propObject = new BusinessPropertyAndObject();
                propObject.BusinessPropertyId = this._businessProperty[index].Id;
                propObject.BusinessPropertyObjectId = businessPropertyObjectId;
                if (!this._businessProperty[index].PropertyObjects) {
                    this._businessProperty[index].PropertyObjects = [];
                }
                this._businessProperty[index].PropertyObjects.push(propObject);
            } else {
                let filteredData = this._businessProperty[index].PropertyObjects
                    .filter(item => item.BusinessPropertyObjectId !== businessPropertyObjectId);
                this._businessProperty[index].PropertyObjects = filteredData;
            }
        } else {
            if (!this._businessProperty) { this._businessProperty = [] }
            let newBusinessProperty = new BusinessProperty();
            newBusinessProperty.BusinessId = this.businessId;
            this._businessProperty.push(newBusinessProperty);
            let newPropObject = new BusinessPropertyAndObject();
            newPropObject.BusinessPropertyId = 0;
            newPropObject.BusinessPropertyObjectId = businessPropertyObjectId;
            this._businessProperty[index].PropertyObjects = [];
            this._businessProperty[index].PropertyObjects.push(newPropObject);
        }
    }

    save() {
        let formData;
        this.loading = true;
        formData = this.myForm.value.pageProperties;
        if (this.myForm.value) {
            formData.forEach((formProp:any, mainIndex:any) => {
                if (this._businessProperty && mainIndex < this._businessProperty.length) {
                    // update change in business props
                    this._businessProperty[mainIndex].BusinessPropertyTypeId = this.myForm.value.pageProperties[mainIndex].type
                    this._businessProperty[mainIndex].Description = this.myForm.value.pageProperties[mainIndex].Description
                    this._businessProperty[mainIndex].Name = this.myForm.value.pageProperties[mainIndex].Name
                    this._businessProperty[mainIndex].ApartmentCount = this.myForm.value.pageProperties[mainIndex].ApartmentCount
                    this._businessProperty[mainIndex].FloorCount = this.myForm.value.pageProperties[mainIndex].FloorCount
                    if (this.checkboxProperties) {
                        let properties;
                        properties = formProp.checkboxProperties;
                        this._businessProperty[mainIndex].PropertyObjects = [];
                        properties.forEach((x:any) => {
                            let jsonKey = Object.keys(x)[0];
                            let id = +jsonKey;
                            if (x[jsonKey]) {
                                let propertyObj = new PropertyObjects();
                                this.loading= false;
                                propertyObj.BusinessPropertyId = 0; // assign proper businessProperty id
                                propertyObj.BusinessPropertyObjectId = id;
                                propertyObj.Quantity =
                                    id === 7 && x.Quantity ? x.Quantity : 0;
                                this._businessProperty[mainIndex].PropertyObjects.push(
                                    propertyObj
                                   
                                );
                               
                            }
                        });
                    }

                } else {
                    // add new business property
                    this._businessProperty = []
                    let newBusinessProperty = new BusinessProperty();
                    newBusinessProperty.BusinessId = this.businessId;
                    newBusinessProperty.BusinessPropertyTypeId = this.myForm.value.pageProperties[mainIndex].type
                    newBusinessProperty.Description = this.myForm.value.pageProperties[mainIndex].Description
                    newBusinessProperty.Name = this.myForm.value.pageProperties[mainIndex].Name
                    newBusinessProperty.ApartmentCount = this.myForm.value.pageProperties[mainIndex].ApartmentCount
                    newBusinessProperty.FloorCount = this.myForm.value.pageProperties[mainIndex].FloorCount
                    this._businessProperty.push(newBusinessProperty);
                }


            });
        }
        this.subscriptions.push(this.busPropertyService
            .add(this._businessProperty)
            .subscribe(res => {
            }));
        this.toasterComponent.callToast();
    }
    typeOfBusinessProperty() {
        this.showBusinessProperty = true;
    }
    typeOfPropertyObjectTable() {
        this.showPropertyObjectTable = true;
    }
    editPropertyObject(propertyObject:any, Id:any) {
        this.propertyObjectForm = this._fb.group({
            Name: propertyObject
        })
        this.selectedPropertyObjectId = Id;
    }
    deleteTypeOfProperty(id) {
        this.subscriptions.push(this.busPropertyService
            .checkAllocatedBusinessProperty(id)
            .subscribe((result: any) => {
                if (result) {
                    this.isNameAllocated = true;
                    setTimeout(
                        function () {
                            this.isNameAllocated = false;
                        }.bind(this),
                        3000);
                } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.busPropertyService
                                .deletePropertyType(id)
                                .subscribe(isDeleted => {
                                    if (isDeleted) {
                                        this.bindListType();
                                        this.toasterComponent.callToastDlt();
                                    }
                                }));
                        }
                    })
                }
            }));
    }

    onBeforePropertyDialogHide() {
        this.propertyObjectForm.reset();
        this.propertyObject.reset();
        this.selectedPropertyObjectId = 0;
    }

    savePropertyType() {
        let propertyType = new BusinessPropertyType();
        propertyType.Id = (this.selectedPropertyObjectId) ? this.selectedPropertyObjectId : 0;
        propertyType.Name = this.propertyObjectForm.value.Name;
        propertyType.BusinessId = this.businessId;
        if (propertyType.Id > 0) {
            propertyType.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.busPropertyService.updatePropertyType(propertyType).subscribe(isPropertyUpdted => {
                this.propertyBind(isPropertyUpdted);
            }));
        } else {
            propertyType.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.busPropertyService.addPropertyType(propertyType).subscribe(isPropertyAdded => {
                this.propertyBind(isPropertyAdded);
            }));
        }
    }

    propertyBind(isSuccess: any) {
        if (isSuccess) {
            this.propertyType = [];
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.propertyType = [];
                this.propertyType.push(
                    { label: val.SELECT, value: null }
                );
            }));
            this.subscriptions.push(this.busPropertyService.listPropertyType(this.businessId).subscribe((type:any) => {
                if (type) {
                    this.propertyObjectData = type;
                    type.forEach((element: any) => {
                        this.propertyType.push({ label: element.Name, value: +element.Id });
                    });

                }
            }));
            this.toasterComponent.callToast();
            this.showBusinessProperty = false;
            this.selectedPropertyObjectId = 0;
        } else {
            this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
            this.isNameExists = true;
            setTimeout(() => {
                this.isNameExists = false;
            }, 3000);
        }
    }

    arrayCompare(business: any, key: number) {
        let isMatched = false;
        if (business && business.PropertyObjects) {
            let list = business.PropertyObjects;
            for (let index = 0; index < list.length; index++) {
                let element = list[index].BusinessPropertyObjectId;
                if (element === key) {
                    return true;
                }
            }
        }
        return isMatched;
    }

    /**
    * mobilenumber keypress event restrict number greater than 20 and restrict text
    */
    onMobileNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }
    /**
 * mobilenumber keypress event restrict number greater than 20 and restrict text
 */
    onQuantityChange(e: any, limitNumber: any) {
        HelperService.numberFieldValidation(e, limitNumber);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}

