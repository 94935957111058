import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Notes } from './../models/notes';
import { NotesService } from './notes.service';

@Injectable()
export class NotesEditResolver implements Resolve<Notes> {
  constructor(private backend: NotesService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['nid']);
  }
}
@Injectable()
export class NotesByUserResolver implements Resolve<Notes> {
  constructor(private backend: NotesService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getNoteByUser(+route.params['eid']);
  }
}
