import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights } from 'src/app/_models';

@Component({
  selector: 'app-qualitysystem-list',
  templateUrl: './qualitysystem-list.component.html'
})
export class QualitysystemListComponent implements OnInit {

  isQualitySystemRights = true; 
  hideQualitySystemRights= true;

  constructor(private router: Router, private route: ActivatedRoute) { 
  }

  ngOnInit(): void {
      document.querySelector("body").classList.remove("opened");
      /*
       * get InternalControl list
       */
      
      if (+BaseServices.roleId === 4) {
          this.isQualitySystemRights = false;
          this.router.navigate(['own'], { relativeTo: this.route })
      } else if (+BaseServices.roleId === 5) {
          this.onOtherSystemRights();
      } else {          
        this.isQualitySystemRights = true;
        this.router.navigate(['all'], { relativeTo: this.route })
        this.OnAdmin()

      }
  }

  onOtherSystemRights() {
      let currentUserRole = BaseServices.UserRole;
      let userRightsId = Rights.ADMINISTRATE_QUALITY_SYSTEM;

      if (currentUserRole === 'User') {
          this.isQualitySystemRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
          this.router.navigate(['own'], { relativeTo: this.route });
      } else {
          this.isQualitySystemRights = true;
          this.router.navigate(['all'], { relativeTo: this.route });
      }
  }
  OnAdmin(){
    let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
    if(curSession > 0)
    {
        this.isQualitySystemRights=true;
        this.hideQualitySystemRights = false;
        this.router.navigate(['all'], { relativeTo: this.route  });
    }
    else{
        this.isQualitySystemRights = true;
        this.router.navigate(['all'], { relativeTo: this.route  });
    }
}

}
