<div class="main-content">
  <div class="main-heading">
    <span translate>EDIT_USER</span>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="adminUserEditForm" class="form">
        <adminuser-form [adminUserForm]="adminUserEditForm"></adminuser-form>
        <div class="action-btns-wrapper">
          <a
            class="btn btn-outline-secondary"
            role="button"
            [routerLink]="['../../list']"
          >
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </a>
          <button
            class="btn btn-primary"
            type="submit"
            (click)="saveChanges()"
            [disabled]="!adminUserEditForm.valid"
          >
            <span translate>SAVE_CHANGES</span>
          </button>
          <button class="btn btn-danger" (click)="showResetPassword()">
            <span translate>RESET_PASSWORD</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="clear()"
  [(visible)]="showPasswordResetDialog"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="updatePasswordForm">
    <div formGroupName="passwordValidationGroup">
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>NEW_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="NewPassword"
            formControlName="Password"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>CONFIRM_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="ConfirmPassword"
            formControlName="ConfirmPassword"
            required
          />
        </div>
      </div>
      <control-messages
        [control]="updatePasswordForm.controls['passwordValidationGroup']"
      ></control-messages>
    </div>
    <button
      class="btn btn-outline-primary"
      (click)="resetPassWord()"
      [disabled]="!updatePasswordForm.valid"
    >
      <span translate>SAVE</span>
    </button>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="clear()"
  [(visible)]="passwordValidation"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate style="color: red">YOUR_PASSWORD_DONOT_MATCH</span>
</p-dialog>
<toaster-component></toaster-component>
