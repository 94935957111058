import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DeveloperComponent} from './developer.component';
@NgModule({
    imports: [
        CommonModule, 
        RouterModule
    ],
    exports: [DeveloperComponent],
    declarations: [DeveloperComponent],
    providers: [],
})
export class DeveloperModule { }
