<div class="tab-content">
    <div class="row">
        <div class="col-md-6">
            <form [formGroup]="licenseagrmtForm">
                <div class="form-group" *ngIf="!isDinHMSLogin">
                    <label>
                        <span translate>AGREEMENTSNAME</span>
                        <span class="required">*</span>
                        <control-messages [control]="licenseagrmtForm.controls['AgreementName']"></control-messages>
                    </label>
                    <input type="text" formControlName="AgreementName" pInputText [readonly]="isHide" maxlength="100" />
                </div>
                <div *ngIf="!isDinHMSLogin" class="form-group">
                    <label>
                        <span translate>PERSON</span>
                    </label>
                    <input type="text" formControlName="SalesPerson" pInputText [readonly]="isHide">
                </div>
                <div *ngIf="isDinHMSLogin" class="form-group">
                    <label>
                        <span translate>LICENSE_CATEGORY</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="LicenseCategory" [options]="licenseCategoryList"
                        [style]="{'width':'100%'}" [disabled]="isHide"></p-dropdown>
                    </div>
                    <control-messages [control]="licenseagrmtForm.controls['LicenseCategory']"></control-messages>
                </div>
                <div class="form-group-50_50">
                    <div class="form-group" *ngIf="licenseagrmtForm['controls']?.['PurchaseDate']">
                        <label>
                            <span translate>PURCHASE_DATE</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <my-date-picker name="startdate" [options]="PurchaseDateOptions"
                              formControlName="PurchaseDate" placeholder="{{'SELECT_DATE'|translate}}"
                              (dateChanged)="onPurchaseDateChanged($event)" required></my-date-picker>
                        </div>
                        <control-messages [control]="licenseagrmtForm.controls['PurchaseDate']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label>
                            <span translate>EXPIRY_DATE</span>
                        </label>
                        <div>
                            <my-date-picker name="enddate" [options]="ExpiryDateOptions" formControlName="ExpiryDate"
                              placeholder="{{'SELECT_START_DATE'|translate}}"
                              (dateChanged)="onExpiryDateChanged($event)" required [selDate]="selDate">
                            </my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group-50_50">
                    <div class="form-group">
                        <label>
                            <span translate>LICENSE_MONTH</span>
                        </label>
                        <div>
                            <p-dropdown formControlName="LicenseMonth" [options]="licenseMonths"
                              [style]="{'width':'100px'}" [disabled]="isHide"></p-dropdown>
                        </div>
                    </div>
                    <div *ngIf="licenseagrmtForm.controls['LicenseCategory'].value != 1 && isDinHMSLogin"
                      class="form-group">
                        <label>
                            <span translate>PRICE_PER_MONTH</span>
                            <span class="required">*</span>
                        </label>
                        <input type="text" (keypress)="onPriceChange($event,10)" formControlName="PricePerMonth"
                          pInputText>
                    </div>
                </div>
                <div class="form-group-50_50">
                    <div class="form-group">
                        <label for="Zip">
                            <span translate>NUM_EMPLOYEES</span>
                        </label>
                        <div>
                            <input type="number" pInputText id="NoOfEmployees" formControlName="NoOfEmployees"
                              class="ex-full-width" readonly />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Zip">
                            <span translate>NUM_USERS</span>
                        </label>
                        <div>
                            <input type="number" pInputText id="NoOfUsers" formControlName="NoOfUsers"
                              class="ex-full-width" readonly />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>DESCRIPTION</span>
                    </label>
                    <textarea pInputTextarea formControlName="Description" [readonly]="isHide"></textarea>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>AGREEMENT</span>
                    </label>
                    <div class="upload-file-container">
                        <div class="tab-content">
                            <div class="form-group">
                                <div class="upload-actions">
                                    <label class="btn btn-outline-secondary upload-button">
                                        <input type="file" [disabled]="isHide" ngFileSelect
                                          accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                          (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">{{'BROWSE'
                                        | translate}}
                                    </label>
                                    <span style="color:green">{{fileName}}</span>
                                </div>
                                <div class="upload-items">
                                    <div class="upload-item" *ngFor="let f of files; let i = index;">
                                        <div class="filename">
                                            <span>{{ f.name }} ({{ f.id }}) - {{ f.progress?.data?.percentage }}%</span>
                                            <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
                                            <span>ETA {{ f.progress?.data?.etaHuman }}</span>
                                        </div>
                                        <div class="progress">
                                            <span class="bar"
                                              [style.width]="f?.progress?.data?.percentage + '%'"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a *ngIf="agreement" href="{{agreement.DocumentPath}}">
                    <span *ngIf="agreement.DocumentPath" translate>CLICK_DOWNLOAD</span>
                </a>
            </form>
        </div>
        <div class="col-md-6">
            <span translate>
                <h4 translate>LICENSE_AGREE</h4>
                <ul>
                    <li *ngIf="!isMedi3" translate>UPDATE_CONTINUOUS</li>
                    <li *ngIf="isMedi3" translate>MEDI3_UPDATE_CONTINUOUS</li>
                    <li translate>SMS_SERVICE</li>
                    <li translate>FREE_TELEPHONE_SUPPORT</li>
                </ul>
                <h4 translate>USER_RIGHT</h4>
                <p translate *ngIf="!isMedi3">CONTENT_COPY_ILLEGAL</p>
                <p translate *ngIf="isMedi3">MEDI3_CONTENT_COPY_ILLEGAL</p>
                <h4 translate>GUARANTEE_RESPONSIBILITY</h4>
                <p translate *ngIf="!isMedi3">ERROR_FIXING</p>
                <p translate *ngIf="isMedi3">MEDI3_ERROR_FIXING</p>
                <p translate *ngIf="!isMedi3">BACKUP_SOLUTION</p>
                <p translate *ngIf="isMedi3">MEDI3_BACKUP_SOLUTION</p>
                <h4 translate>LICENSE_PROVISIONS</h4>
                <p translate *ngIf="!isMedi3">VIOLATION_OF_LICENSE</p>
                <p translate *ngIf="isMedi3">MEDI3_VIOLATION_OF_LICENSE</p>
                <p translate>LICENSE_EXPIRY</p>

                <div>
                    <button *ngIf="roleId < 3" type="submit" class="btn btn-outline-primary"
                      (click)="rModeLicenceAgreement();">
                        <span translate>R</span>
                    </button>
                </div>
            </span>
        </div>
    </div>
    <div *ngIf="isAdmin" class="action-btns-wrapper">
        <button type="submit" class="btn btn-outline-secondary" (click)="clear()">
            <span translate>CLEAR</span>
        </button>
        <button type="submit" class="btn btn-primary" (click)="onSaveBusinessDetails(false)"
          [disabled]="onCheckValidation()">
            <span translate>SAVE</span>
        </button>
        <button *ngIf="isDinHMS" type="submit" class="btn btn-primary" (click)="onSaveBusinessDetails(true)"
          [disabled]="onCheckValidation()">
            <span translate>SAVE_AND_NEXT</span>
        </button>
    </div>

</div>
<toaster-component></toaster-component>