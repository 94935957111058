export class FoodSafety {
    Id: number;
    Title: string;
    FoodSafetyZoneId: number;
    BusinessDepartmentId: number;
    Description: string;
    NoOfDays: number;
    Status: string;
    BusinessId: number;
    CreatedOn: Date;
    CreatedBy: number;
    ModifiedOn: Date;
    ModifiedBy: number;
    ShowDeviation?: boolean;
    FoodSafetyUserDto: FoodSafetyUser[];
    FoodEmailSubscribers: number[];
    CopyZone: number;
    IsCopyFiles?:boolean;
    IsCopyHazard?:boolean;
    IsCopySJA?:boolean;
    IsCopyTask?:boolean;
    IsCopySJAForm?:boolean;
    FilesTemplateId:number;
    HazardTemplateId:number;
    copyTaskId:number;
    SJATemplateId:number;
    ControlZoneId: number;
    ControlUserDto: FoodSafetyUser[];
    TypeOfObjectId: number;
}

export class FoodSafetyDetail {
    ZoneName: string;
    Department: string;
    Description: string;
    constructor() {
        this.ZoneName = '';
        this.Description = '';
        this.Department = '';
    }
}

export class FoodSafetyUser {
    FoodSafetyId: number;
    UserId: number;
    Name: string;
}
