<div class="main-content">
  <div class="main-heading">
    <div class="page-title">
      <span translate>VEHICLE_CONSUMPTION</span>
      <span class="page-actions pull-right">
        <a [routerLink]="['./../edit', 0]" class="btn btn-success">
          <span class="icon ic-sm icon-add"></span>
          <span translate>NEW</span>
        </a>
        <a
          [routerLink]="['./../../../../../vehicle/list']"
          class="btn btn-primary"
        >
          <span translate>BACK_VEHICLE</span>
        </a>
      </span>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="consumptionsList"
          (onRowSelect)="onRowSelect($event)"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
          (onRowUnselect)="onRowUnSelect($event)"
          [(selection)]="selectedConsumption"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Caption -->
          <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
              <div class="table-options">
                <div class="pull-right">
                  <kuba-export
                    [reportData]="dt.filteredValue || dt.value"
                    [additionalData]="additionalData"
                  ></kuba-export>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 38px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>{{ "REGISTRATION_NUMBER" | translate }}</th>
              <th>{{ "MEASURE_DATE" | translate }}</th>
              <th>{{ "BRAND" | translate }}</th>
              <th>{{ "MODEL" | translate }}</th>
              <th>{{ "TYPE_OF_VEHICLE" | translate }}</th>
              <th>{{ "PREVIOUS_KM" | translate }}</th>
              <th>{{ "ESTIMATED_KM" | translate }}</th>
              <th>{{ "TODAY_KM" | translate }}</th>
              <th>{{ "TODAY_KM" | translate }}</th>
              <th>{{ "LITER_USED_TENKM" | translate }}</th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-vehicle>
            <tr>
              <td style="width: 38px">
                <p-tableCheckbox id="selectedOrders" [value]="vehicle">
                </p-tableCheckbox>
              </td>
              <td>
                <a [routerLink]="['./../edit', vehicle.Id]">{{
                  vehicle.RegistrationNo
                }}</a>
              </td>
              <td>
                <span>{{ vehicle.Date | date : "dd/MM/yyyy" }}</span>
              </td>
              <td>
                <span>{{ vehicle.BrandName }}</span>
              </td>
              <td>
                <span>{{ vehicle.ModelName }}</span>
              </td>
              <td>
                <span>{{ vehicle.TypeName }}</span>
              </td>
              <td>
                <span>{{ vehicle.PreviousKm }}</span>
              </td>
              <td>
                <span>{{ vehicle.Estimatedkm }}</span>
              </td>
              <td>
                <span>{{ vehicle.TodaysKm }}</span>
              </td>
              <td>
                <span>{{ vehicle.LitersUsed }}</span>
              </td>
              <td>
                <span>{{ vehicle.NoOfLiters }}</span>
              </td>
            </tr>
          </ng-template>
          <!-- Footer -->
          <ng-template pTemplate="summary">
            <div class="ui-helper-clearfix">
              <div class="footer-data">
                <div class="text-left">
                  <span
                    pTooltip="{{ 'RECORD_DELETE_CONSUMPTION' | translate }}"
                  >
                    <button
                      class="btn btn-danger"
                      (click)="deleteConsumption(selectedConsumption)"
                      [disabled]="!isDeleteConsumption"
                    >
                      <i class="icon ic-sm icon-trash"></i>
                      <span translate>DELETE_CHECKED</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
