import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Suggestion } from '../models/suggestions';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SuggestionsServices {
    
    constructor(private http: HttpClient) {
        
    }
    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', 'application/json');
    }
    // List all business
    list() {
        return this.http.get(environment.BASE_URL + '/suggestions', BaseServices.headerOption)
            .map(result => result);
    }

    // retrive a business for editing and View
    getById(id: number) {
        return this.http.get(environment.BASE_URL + '/suggestion/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    create(newSuggestion: Suggestion) {
        return this.http.post(environment.BASE_URL + '/suggestion', newSuggestion, BaseServices.headerOption)
            .map(result => result);
    }

    update(id, data: Suggestion) {
        return this.http.put(environment.BASE_URL + '/suggestion/' + id, data, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }

    deleteById(id: number, newSuggestion: Suggestion) {
        return this.http.delete(environment.BASE_URL + '/suggestion/' + id, BaseServices.headerOption)
            .map(result => result);
    }
}
