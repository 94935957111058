import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-riskanalysis-list-chart',
  templateUrl: './riskanalysis-list-chart.component.html',
  styleUrls: ['./riskanalysis-list-chart.component.css'],
})
export class RiskanalysisListChartComponent implements OnInit {
  @Input() riskDetails: any[];
  single: any[] = [];
  multi: any[];
  view: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Risk Figure';
  showYAxisLabel = false;
  yAxisLabel = 'Count';

  colorScheme = {
    domain: ['#4ec55c', '#F0AC19', '#E5634F'], // Green for Low, Orange for Medium, Red for High
  };

  constructor(private translate: TranslateService) {}

  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    console.log(this.riskDetails, 'FROM CHART');
    this.transformData();
  }

  transformData() {
    if (this.riskDetails && this.riskDetails.length > 0) {
      // Initialize counters for each risk figure
      const riskFigureCounts = {
        Low: 0,
        Medium: 0,
        High: 0,
      };

      // Count the occurrences of each risk figure based on Id
      this.riskDetails.forEach((risk) => {
        const riskFigureId = risk.RiskFigureData?.Id;
        if (riskFigureId === 1) {
          riskFigureCounts.Low++;
        } else if (riskFigureId === 2) {
          riskFigureCounts.Medium++;
        } else if (riskFigureId === 3) {
          riskFigureCounts.High++;
        }
      });

      // Prepare data for the chart
      this.single = [
        {
          name: this.translate.instant('LOW'),
          value: riskFigureCounts.Low,
        },
        {
          name: this.translate.instant('MEDIUM'),
          value: riskFigureCounts.Medium,
        },
        { name: this.translate.instant('HIGH'), value: riskFigureCounts.High },
      ];
    }
  }
}
