import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { CountryCode } from './../../Suppliers/Models/countryCode';
import { SupplierServices } from './../../Suppliers/Services/supplier.services';
import { EmployeeWorkExperienceList } from './../models/employeeWorkExperienceList';
import { EmployeeWorkExperienceServices } from './employeeworkexperience.service';


@Injectable()
export class EmployeeWorkExperienceResolver implements Resolve<EmployeeWorkExperienceList[]> {
    constructor(private backend: EmployeeWorkExperienceServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<EmployeeWorkExperienceList[]> {
        return this.backend.getEmployeeWorkExperienceByEmployeeId(+route.params['eid']);
    }
}
@Injectable()
export class CountryCodeResolver implements Resolve<CountryCode[]> {
    constructor(private backend: SupplierServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<CountryCode[]> {
        return this.backend.getCountryCode();
    }
}

