<div class="main-content">
  <div class="main-heading">
    <strong> {{checklistTitle}} </strong>
  </div>
  <div class="card">
    <div class="card-body">
      <tabset #staticTabs>
        <tab heading="{{'STEP2_CHECKLIST' | translate}}">
          <div class="tab-content">

            <!--Panel Loader-->
            <div *ngIf="showLoader" class="panel-loader">
              <span>
                <span class="loader-icon"></span>
                <span translate>PROCESSING</span>
              </span>
            </div>
            <!--Panel Loader-->

            <div *ngIf="CompleteChecklistEdit.Section">
              <ng-template ngFor let-section let-headPoint="index" [ngForOf]="CompleteChecklistEdit.Section">
                <div class="sub-panel">
                  <div class="sub-panel-title">
                    <strong> {{headPoint+1}} {{section.Title}}</strong>
                  </div>
                  <div class="sub-panel-content">
                    <ng-template ngFor let-sectionitem let-i="index" [ngForOf]="section.Items">
                      <div>
                        <div class="page-title">
                          <strong> {{headPoint+1}}. {{i+1}} {{sectionitem.Title}}</strong>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group-50_50">
                              <div *ngIf="isScoreIndex" class="form-group">
                                <label>
                                  <span translate>Score</span>
                                </label>
                                <div *ngIf="scoreList">
                                  <p-dropdown [(ngModel)]="sectionitem.Score" [options]="scoreList"
                                    [style]="{'width':'80px'}"></p-dropdown>
                                </div>
                              </div>
                              <div class="form-group">
                                <label>Status</label>
                                <ng-template ngFor let-status [ngForOf]="sectionitem.Status">
                                  <div class="radio">
                                    <label>
                                      <input id="statusOptions[{{headPoint}}{{i}}]" name="options[{{headPoint}}{{i}}]"
                                        type="radio" [value]="status.value"
                                        (change)="setStatus(status, sectionitem.Status,sectionitem)"
                                        [checked]="status.value">
                                      <span *ngIf="status.Label=='Deviation'" translate>
                                        CREATE_DEVIATION
                                      </span>
                                      <span *ngIf="status.Label!='Deviation'" translate>
                                        {{status.Label}}
                                      </span>
                                    </label>
                                  </div>
                                </ng-template>

                              </div>
                            </div>
                            <div class="form-group">
                              <textarea [(ngModel)]="sectionitem.Comments" id="statusComments"
                                class="form-control"></textarea>
                            </div>
                            <div class="form-group">
                              <strong>{{sectionitem.Signature}}</strong>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div>
                                <ecpl-document-explorer [style]="{'width':'160px','height':'100px'}"
                                  [uploaderConfig]="upConfig" [uploadedFiles]="sectionitem.Attachments"
                                  (saveChanges)="uploaderEvents($event, sectionitem)">
                                </ecpl-document-explorer>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="action-btns-wrapper">
              <button (click)="SaveForLater()" class="btn btn-primary">
                <span translate>SAVE_FOR_LATER</span>
              </button>
              <button [disabled]="checkValid()" (click)="SaveSecondStepCompleteCheckList()" class="btn btn-primary">
                <span translate> Save & Next</span>
              </button>
            </div>
          </div>
        </tab>
        <tab heading="{{'STEP3_FINISH' | translate}}">
          <div class="tab-content">
            <!--Panel Loader-->
            <div *ngIf="showLoader" class="panel-loader">
              <span>
                <span class="loader-icon"></span>
                <span translate>PROCESSING</span>
              </span>
            </div>
            <!--Panel Loader-->

            <label>
              <span translate>FOOD_SAFETY_APPROVAL</span>
            </label>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="aproval">
                <span translate> FOOD_SAFETY_CHECKLIST_COMPLETED</span>
              </label>
            </div>
            <div class="action-btns-wrapper">
              <button (click)="EnableFirstTab()" class="btn btn-outline-secondary" translate>PREVIOUS_STEP</button>
              <button (click)="SaveAndCompleteChecklist()" [disabled]="!aproval" class="btn btn-primary" translate>
                SAVE_AND_COMPLETE </button>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>