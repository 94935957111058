<div class="main-content">
    <!--Panel Loader-->
    <div *ngIf="showLoader" class="panel-loader">
        <span>
            <span class="loader-icon"></span>
            <span translate>PROCESSING</span>
        </span>
    </div>
    <!--Panel Loader-->
    <div class="main-heading">
        <span *ngIf="featureKey">
            <a *ngIf="featureKey!=null" class="btn btn-icon" href="javascript:void(0);" (click)="goBack()">
                <span class="icon ic-xs icon-back"></span>
            </a>
            <span>{{VehiclesCompleteChecklist.Title}}</span>
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <table class="table border-0">
                <thead>
                    <tr>
                        <th><span translate>CHECKPOINT_NAME</span>
                        </th>
                        <th style="width:150px;"><span translate>STATUS</span></th>
                        <th style="width:200px;"><span translate>COMMENTS</span></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-List let-headPoint="index" [ngForOf]="VehiclesCompleteChecklisttData">
                        <tr>
                            <td>
                                <strong>{{List.CheckpointName}}</strong>
                                <span class="required">*</span>
                            </td>
                            <td>
                                <ng-template ngFor let-status [ngForOf]="List.Status">
                                    <div class="radio">
                                        <label>
                                            <input id="statusOptions" required name="options[{{headPoint}}]"
                                              type="radio" [value]="status.Value"
                                              (change)="setStatus(status, List.Status)"
                                              [checked]="status.Value">{{status.Type | translate}}
                                        </label>
                                    </div>
                                </ng-template>
                            </td>
                            <td>
                                <textarea [(ngModel)]="List.Comments" id="statusComments"
                                  class="form-control"></textarea>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
            <div class="action-btns-wrapper">
                <button *ngIf="featureKey!=null" class="btn btn-outline-secondary" (click)="goBack()">
                    <span class="icon ic-xs icon-back"></span>
                    <span translate>BACK_LIST</span>
                </button>
                <button [disabled]="checkValid()" class="btn btn-primary" (click)="SaveCompleteCheckList()">
                    <span translate>SAVE</span></button>
            </div>
        </div>
    </div>
</div>