import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HelperService } from 'src/app/_services/helper.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { RiskanalysisService } from '../services/risk-analysis.service';
import { Ruh, TypeOfCase } from '../models/ruh';
import { Topic } from '../../safety-job-analysis/models';
import { RuhService } from '../services/ruh.services';
import { IMyDpOptions } from 'mydatepicker';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'ruh-new.component.html'
})

export class RuhNewComponent implements OnInit {
    featureId: number;
    selectedTypeOfCase = new TypeOfCase();
    selectedTopic = new Topic();
    loading = false;
    riskanalysisId: number;
    departments: any;
    Case: SelectItem[];
    Topics: SelectItem[];
    topicId: number;
    caseId: number;
    topicForm: FormGroup;
    caseForm: FormGroup;
    upConfig: UploaderConfig;
    upRuhConfig: UploaderConfig;
    uploadedRuhFiles = [];
    additionalData: any;
    newDocumentAdded: any = [];
    isdepartmentFollowupExist = true;
    isCaseTopicExist = true;
    noFollowupExistAlert: string;
    noTopicExistAlert: string;
    followupId: number;
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px'
    };

    @Input() ruhForm: FormGroup;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private _fb: FormBuilder,
        private location: Location,
        private riskanalysisService: RiskanalysisService,
        private ruhServices: RuhService
    ) {
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ActivityId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: 'Attachments',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/ruh/file/upload/business/followup/ruh-docs',
            addtionalData: this.additionalData
        };
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.ruhForm = this._fb.group({
            DepartmentId: ['', Validators.required],
            TopicID: ['', Validators.required],
            CaseId: ['', Validators.required],
            Title: ['', Validators.required],
            Location: ['', Validators.required],
            EventDate: [''],
            EventTime: ['', Validators.required],
            Description: ['', Validators.required],
            CorrectiveAction: [''],
            SuggestionAction: ['']

        });
        this.bindDropdown();

    }

    /**
     * bind dropdown 
     */
    bindDropdown() {
        //bind dropdown for department
        let department = this.route.snapshot.data['department'];
        this.departments = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
            if (department) {
                department.forEach((department: any) => {
                    this.departments.push({ label: department.Name, value: department.Id });
                });
            }
        }));

        //bind dropdown for Type of Case
        this.Case = [];
        let caseType: any[];
        caseType = this.route.snapshot.data['caseType'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Case = [];
            this.Case.push(
                { label: val.SELECT, value: null }
            );
            if (caseType) {
                caseType.forEach((caseInfo: any) => {
                    this.Case.push({ label: caseInfo.Name, value: caseInfo.Id });
                });
            }
        }));

    }
    //bind dropdown for Topics  
    bindTopicDropdown(caseId: any) {
        let topics: any[];
        this.subscriptions.push(this.ruhServices.getAllTopics(caseId.value).subscribe(result => {
            if (result) {
                topics = result;
                this.isCaseTopicExist = true;
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.Topics = [];
                    this.Topics.push(
                        { label: val.SELECT, value: null }
                    );
                    if (topics) {
                        topics.forEach((topicInfo: any) => {
                            this.Topics.push({
                                label: topicInfo.Name,
                                value: topicInfo.Id
                            });

                        });
                    }
                }));
            }
            else {
                this.isCaseTopicExist = false;
                this.noTopicExistAlert = 'No Topic under this Case go to list page to add topic';
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.Topics = [];
                    this.Topics.push(
                        { label: val.SELECT, value: null }
                    );
                }));
            }
        }));
    }
    /**
   * filter list based on followup person
   * @param e {any}
   * @param departmentDropdown {any}
   */
    onDepartmentChanged(depControl: any) {
        if (depControl) {
            this.subscriptions.push(this.ruhServices.getFollowupofDepartment(depControl.value)
                .subscribe((result:any) => {
                    if (result) {
                        this.followupId = result.Id;
                        this.isdepartmentFollowupExist = true;
                    }
                    else {
                        this.followupId = 0;
                        this.isdepartmentFollowupExist = false;
                        this.noFollowupExistAlert = this.translate.instant(`NO_FOLLOWUP`);
                    }
                }));
        }
    }

    /**
     * save uploaded file
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x:any) => x.Path !== event.item.data.path
                        );
                        this.uploadedRuhFiles = this.uploadedRuhFiles.filter(
                            (x:any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.riskanalysisService
                            .deleteRiskAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedRuhFiles = this.uploadedRuhFiles.filter(
                                        (x:any) => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    /**
     * save deviation
     * @param data {{any}}
     */
    saveRuh() {
        this.loading = true;
        let windowOrigin = window.location.origin;
        let applicationBase = '';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
        if (appSettings) {
            applicationBase = appSettings.BaseUrl;
        }
        let url = windowOrigin + '/' + applicationBase + '/login?returnUrl='
            + '/app/business/' + BaseServices.BusinessId + '/follow-up/ruh/edit/';
        let ruhdetails = new Ruh();
        ruhdetails.BusinessId = BaseServices.BusinessId;
        ruhdetails.ApplicationId = BaseServices.ApplicationId;
        ruhdetails.Id = (this.caseId > 0) ? this.caseId : 0;
        ruhdetails.Title = this.ruhForm.value.Title;
        ruhdetails.BusinessDepartmentId = this.ruhForm.value.DepartmentId;
        ruhdetails.CaseId = this.ruhForm.value.CaseId;
        ruhdetails.TopicId = this.ruhForm.value.TopicID;
        ruhdetails.Location = this.ruhForm.value.Location;
        ruhdetails.EventDate = HelperService.formatDateFilter(this.ruhForm.value.EventDate.formatted);
        ruhdetails.EventTime = HelperService.formatTime(this.ruhForm.value.EventTime);
        ruhdetails.Description = this.ruhForm.value.Description;
        ruhdetails.CorrectiveAction = this.ruhForm.value.CorrectiveAction;
        ruhdetails.SuggestionAction = this.ruhForm.value.SuggestionAction;
        ruhdetails.Status = '7';
        ruhdetails.CreatedBy = BaseServices.UserId;
        ruhdetails.ActivityId = 1;
        ruhdetails.RuhAttachment = this.newDocumentAdded;
        ruhdetails.CultureInfo = BaseServices.userCultureInfo();
        ruhdetails.FollowupUserId = this.followupId;
        this.subscriptions.push(this.ruhServices.addRuh(ruhdetails).subscribe(isRuhAdded => {
            if (isRuhAdded) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.backToList();
            }
        }));
    }
    /**
     * to back to risk list
     * @param mode {string}
     */
    backToList() {
        setTimeout(() => {
            this.location.back();
        }, 2000);

    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

}