<div class="tab-content">
    <div class="page-title">
        <span translate>NEW_CONSUMPTION_VEHICLE</span>
    </div>
    <div *ngIf=vehicle>
        <div class="form-group">
            <label for="Brand" class="col-form-label">
                <span translate>BRAND</span>:
                <span translate>{{vehicle.VehicleBrandName}} </span>
            </label>
        </div>
        <div class="form-group">
            <label class="col-form-label">
                <span translate>MODEL</span>:
                <span translate>{{vehicle.VehicleModelName}} </span>
            </label>
        </div>
        <div class="form-group">
            <label for="Type" class="col-form-label">
                <span translate>TYPE_OF_VEHICLE </span>:
                <span translate>{{vehicle.VehicleTypeName}} </span>
            </label>
        </div>
        <div class="form-group">
            <label for="RegistrationNumber" class="col-form-label">
                <span translate>REGISTRATION_NUMBER</span>:
                <span translate>{{vehicle.RegistrationNo}} </span>
            </label>
        </div>
    </div>
    <form [formGroup]="consumptionForm" (ngSubmit)="saveVehicleConsumption()" #f="ngForm" novalidate name="form" class="form">
        <div class="form-group">
            <label class="col-form-label">
                <span translate>CHOOSE_DATE</span>
                <span class="required">*</span>
            </label>
            <div>
                <my-date-picker name="deadline" [options]="defaultDateOptions" formControlName="ChooseDate" placeholder="{{'SELECT_DATE'|translate}}" required></my-date-picker>
            </div>
        </div>
        <div class="form-group" formGroupName="Kilometers">
            <label for="PreviousKM" class="col-form-label">
                <span translate>PREVIOUS_KM </span>
            </label>
            <div>
                <input type="text" pInputText formControlName="PreviousKM">
                <span translate>LAST_CONSUMPTION</span>
            </div>
            <label for="TodaysKM" class="col-form-label">
                <span translate>TODAY_KM</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" pInputText formControlName="TodaysKM" (keyup)="calculateEstimatedKm()">
                <span translate>ACTUAL_NUMBER</span>
            </div>
            <control-messages [control]="consumptionForm.controls['Kilometers']"></control-messages>
        </div>
        <div class="form-group">
            <label for="EstimatedKm" class="col-form-label">
                <span translate>ESTIMATED_KM</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" pInputText formControlName="EstimatedKm">
                <span translate>NUMBER_USING </span>
            </div>
            <control-messages [control]="consumptionForm.controls['EstimatedKm']"></control-messages>
        </div>
        <div class="form-group">
            <label for="NoofLiterUsed" class="col-form-label">
                <span translate>LITER</span>
            </label>
            <div>
                <input type="text" pInputText formControlName="NoofLiterUsed" (keyup)="calculateLiterused()">
            </div>
        </div>
        <div class="form-group">
            <label for="NoOfliterusedper10km" class="col-form-label">
                <span translate>LITER_NUMBER</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" pInputText formControlName="NoOfliterusedper10km">
            </div>
            <control-messages [control]="consumptionForm.controls['NoOfliDterusedper10km']"></control-messages>
        </div>
        <div class="form-group">
            <label for="Comments" class="col-form-label">
                <span translate>COMMENTS</span>
            </label>
            <div>
                <textarea pInputTextarea formControlName="Comments"></textarea>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <a [routerLink]="['./../../list']" class="btn btn-outline-secondary">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </a>
            <button class="btn btn-primary" type="submit" [disabled]="!consumptionForm.valid">
                <span translate>SAVE</span>
            </button>
        </div>
    </form>
</div>
<toaster-component></toaster-component>