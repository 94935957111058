export class NotificationEdit {
    Id: number;
    Applicationid: number;
    Businessid: number;
    PortalId: number;
    UserId: number;
    Title: String;
    Description: string;
    status: number;
    Priority: string;
    CreatedBy: string;
    ModifiedBy: number;
    PublishedOn: Date;
    CreatedOn: Date;
}
