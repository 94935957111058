<div class="table-view mbtm-30">
    <svg width="900" height="350" responsive="true"></svg>
</div>
<div class="action-btns-wrapper">
    <button (click)="shiftLeft()" class="btn btn-outline-primary" [disabled]="!enableLeftArrow">
        <span class="icon ic-sm icon-back"></span>
    </button>
    <button (click)="shiftRight()" class="btn btn-outline-primary" [disabled]="!enableRightArrow">
        <span class="icon ic-sm icon-forward"></span>
    </button>
</div>

<div class="table-view mbtm-30">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="inspectionList" dataKey="InspectionNumber" #inspectionTable [rows]="10" [paginator]="true"
          [pageLinks]="3" [rowsPerPageOptions]="rowsPerPageOptions" expandableRows="true"
          [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th></th>
                    <th pSortableColumn="InspectionNumber">{{'INSPECTION_NO' | translate}} <p-sortIcon
                          field="InspectionNumber"></p-sortIcon></th>
                    <th pSortableColumn="Title">{{'INSPECTION_TITLE'| translate}} <p-sortIcon
                          field="Title"></p-sortIcon></th>
                    <th pSortableColumn="ContractName">{{'CONTRACT_NAME'| translate}} <p-sortIcon
                          field="ContractName"></p-sortIcon></th>
                    <th pSortableColumn="ClientName">{{'CLIENT' | translate }} <p-sortIcon
                          field="ClientName"></p-sortIcon></th>
                    <th>{{'STATUS'| translate}}</th>
                    <th>{{'RESPONSIBLE_PERSON'| translate}}</th>
                    <th pSortableColumn="Deadline">{{'DEADLINE' | translate}} <p-sortIcon field="Deadline"></p-sortIcon>
                    </th>
                    <th *ngIf="isGuestHideButton"></th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th>
                        <input pInputText type="text"
                          (input)="inspectionTable.filter($event.target.value, 'InspectionNumber', 'contains')"
                          placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                          (input)="inspectionTable.filter($event.target.value, 'Title', 'contains')" placeholder=""
                          class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                          (input)="inspectionTable.filter($event.target.value, 'ContractName', 'contains')"
                          placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                          (input)="inspectionTable.filter($event.target.value, 'ClientName', 'contains')" placeholder=""
                          class="p-column-filter">
                    </th>
                    <th>
                        <p-dropdown [options]="statuses" (onChange)="onStatusChange($event, ed)" #ed
                          styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th>
                        <p-dropdown [options]="resperson" #responsible
                          (onChange)="onResponsiblePersonChange($event,responsible)" styleClass="p-column-filter"
                          placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-resperson pTemplate="item"> {{ resperson.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th *ngIf="isGuestHideButton"></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-inspection let-expanded="expanded" let-i="rowIndex">
                <tr>
                    <td>
                        <a (click)="inspectionTable.toggleRow(inspection)"
                          *ngIf="inspection.InspectionSubTaskList.length>0">
                            <i class="pi pi-chevron-circle-right" aria-hidden="true" *ngIf="setCollapse(inspection.Id)"
                              (click)="collapseRow(false,inspection.Id)"></i>
                            <i class="pi pi-chevron-circle-down" aria-hidden="true" *ngIf="!setCollapse(inspection.Id)"
                              (click)="collapseRow(true,inspection.Id)"></i>
                        </a>
                    </td>
                    <td>
                        <span><i class="{{inspection.StatusIcon}}"></i></span>
                    </td>
                    <td>
                        <span>{{inspection.InspectionNumber}}</span>
                    </td>
                    <td>
                        <a pTooltip="{{inspection.Title}}"
                          [routerLink]="['../',inspection.ContractId,inspection.Id,'edit']">{{inspection.Title}}</a>
                    </td>
                    <td>
                        <span>{{inspection.ContractName}}</span>
                    </td>
                    <td>
                        <span>{{inspection.ClientName}}</span>
                    </td>
                    <td>
                        <span>{{inspection.InspectionStatustext | translate}}</span>
                    </td>
                    <td>
                        <span>{{inspection.ResponsibleUserName}}</span>
                    </td>
                    <td>
                        <span>{{inspection.Deadline | date : 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="col-button" *ngIf="isGuestHideButton">
                        <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                          (click)="confirm(inspection.Id)">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Footer -->
            <ng-template pTemplate="summary">
                <div class="footer-data">
                    <div class="symbol-descriptions">
                        <div class="symbol-desc-title">
                            <span translate>DESCRIPTION_SYMBOLS</span>
                        </div>
                        <div class="symbol-desc-items">
                            <span class="far-deadline">
                                <i class="icon ic-md icon-far-deadline"></i>
                                <span translate>MORE_DEADLINE</span>
                            </span>
                            <span class="over-deadline">
                                <i class="icon ic-md icon-over-deadline"></i>
                                <span translate>OVERDEADLINE</span>
                            </span>
                            <span class="near-deadline">
                                <i class="icon ic-md icon-near-deadline"></i>
                                <span translate>LESS_DEADLINE</span>
                            </span>
                            <span class="clear-deadline">
                                <i class="icon ic-md icon-clear-deadline"></i>
                                <span translate>DONE_STATUS</span>
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Row expansion -->
            <ng-template pTemplate="rowexpansion" let-inspection>
                <tr>
                    <td colspan="9">
                        <div>
                            <!-- Table starts -->
                            <p-table [value]="inspection.InspectionSubTaskList" [rows]="5">
                                <!-- Header -->
                                <ng-template pTemplate="header">
                <tr>
                    <th>{{'SUB_TASKS'|translate}}</th>
                    <th>{{'STATUS'|translate}}</th>
                    <th>{{'INTERVAL'|translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-task>
                <tr>
                    <td>
                        <span>{{task.Name}}</span>
                    </td>
                    <td>
                        <span>{{task.StatusText | translate}}</span>
                    </td>
                    <td>
                        <span>{{task.IntervalText | translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    <!-- Row expansion -->
    <ng-template pTemplate="rowexpansion" *ngIf="isGuestHideButton" let-inspection>
        <tr>
            <td colspan="10">
                <div>
                    <!-- Table starts -->
                    <p-table [value]="inspection.InspectionSubTaskList" [rows]="5">
                        <!-- Header -->
                        <ng-template pTemplate="header">
        <tr>
            <th>{{'SUB_TASKS'|translate}}</th>
            <th>{{'STATUS'|translate}}</th>
            <th>{{'INTERVAL'|translate}}</th>
        </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-task>
        <tr>
            <td>
                <span>{{task.Name}}</span>
            </td>
            <td>
                <span>{{task.StatusText | translate}}</span>
            </td>
            <td>
                <span>{{task.IntervalText | translate}}</span>
            </td>
        </tr>
    </ng-template>
    </p-table>
</div>
</td>
</tr>
</ng-template>
<!-- Empty message -->
<ng-template pTemplate="emptymessage" let-columns>
    <tr>
        <td colspan="9">
            {{'NO_RECORDS_FOUND'|translate}}
        </td>
    </tr>
</ng-template>
<!-- Empty message -->
<ng-template pTemplate="emptymessage" *ngIf="isGuestHideButton" let-columns>
    <tr>
        <td colspan="10">
            {{'NO_RECORDS_FOUND'|translate}}
        </td>
    </tr>
</ng-template>
</p-table>
<!-- Table Ends -->
</div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>