import { ProjectAdminServices } from './../../project-admin/service/project-admin.service';
import { BusinessPortalService } from './../../portals/services/business-portal.services';
import { BusinessServices } from './../../businesses/services/business.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ViewChild } from '@angular/core';
import { FeatureKey } from 'src/app/_models';
import { HelperService } from './../../../_services/helper.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { SelectItem, ConfirmationService } from 'primeng/api';

import { KubaGeneralService } from './../../kuba-general.services';
import { BaseServices } from './../../kuba.services';
import { ProjectAdminList } from './../../project-admin/models/project-admin-list';
import { AssignProjectService } from './../services/assign-projects.service';
import { ProjectAdmin } from './../../project-admin/models/project-admin';
import { UserRole } from '../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'assign-project',
    templateUrl: 'assign-project.component.html'
})
export class AssignProjectComponent implements OnInit {
    //#region variable
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    Business = null;
    Portal = null;
    projects: ProjectAdminList[];
    checkedData: any = [];
    selectedData: any = [];
    radioValue = '1';
    chooseBusiness: SelectItem[];
    sortBusiness: SelectItem[];
    choosePortal: SelectItem[];
    sortPortal: SelectItem[];
    selectedProject: any;
    selectedValue: number;
    project = new ProjectAdmin();
    isValueSelected = false;
    isPortal: boolean;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param assignProjectService {AssignProjectService}
     * @param kubaGeneralService {KubaGeneralService}
     * @param toasterService {ToasterService}
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private assignProjectService: AssignProjectService,
        private kubaGeneralService: KubaGeneralService,
        private toasterService: ToasterService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private businessService: BusinessServices,
        private businessPortalService: BusinessPortalService,
        private projectAdminServices: ProjectAdminServices,
        private translate: TranslateService
    ) { }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.isPortal = (+BaseServices.roleId === UserRole.PORTAL) ? true : false;
        this.projects = <ProjectAdminList[]>this.route.snapshot.data['list'];
        this.subscriptions.push(this.businessService.list().subscribe(projectList => {
            this.subscriptions.push(this.translate.stream('SELECT_BUSINESS_DROP').subscribe(val => {
                this.chooseBusiness = [];
                this.chooseBusiness.push(
                    { label: val.SELECT_BUSINESS, value: null }
                );
            }));
            if (projectList) {
                projectList.forEach((list: any) => {
                    let rights = (list.Features) ? JSON.parse(list.Features) : null;
                    let hasAccess = HelperService.featureBasedCheck(rights, FeatureKey.PROJECT);
                    if (hasAccess) {
                        this.chooseBusiness.push({ label: list.CompanyName, value: list.Id });
                    }
                });
            }



        }));
        this.subscriptions.push(this.businessPortalService.getAll().subscribe(portalList => {
            this.subscriptions.push(this.translate.stream('SELECT_PORTAL_DROP').subscribe(val => {
                this.choosePortal = [];
                this.choosePortal.push(
                    { label: val.SELECT_PORTAL, value: null }
                );
            }));
            if (portalList) {
                portalList.forEach((list: any) => {
                    let rights = (list.Features) ? JSON.parse(list.Features) : null;
                    let hasAccess = HelperService.featureBasedCheck(rights, FeatureKey.PROJECT);
                    if (hasAccess) {
                        this.choosePortal.push({ label: list.Title, value: list.Id });
                    }
                });
            }
        }));
    }

    //#endregion

    //#region control-event

    /**
     * status esit
     * @param id {any}
     * @param status {any}
     */
    editStatus(id: any, status: any) {
        if (status > 0) {
            this.confirmationService.confirm({
                message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
                accept: () => {
                    this.subscriptions.push(this.assignProjectService
                        .updateStatus(id, status)
                        .subscribe(isStatusUpdated => {
                            if (isStatusUpdated) {
                                this.refreshTable();
                            }
                        }));
                }
            });
        }
    }
    /**
     * reset data
     */
    clear() {
        this.selectedData = [];
    }
    /**
     * resetting dropdown values
     */
    reset() {
        this.Business = null;
        this.Portal = null;
        this.selectedData = [];
    }

    /**
     * refreshing the table with unassigned project
     */
    defaultToUnAssignedProjects() {
        this.subscriptions.push(this.projectAdminServices
            .getProjectList(BaseServices.ApplicationId)
            .subscribe(res => { this.projects = res }))
    }

    /**
     * refreshing the table while changing to business
     */
    changeToBusiness() {
        this.defaultToUnAssignedProjects();
    }
    /**
     * refreshing the table while changing to portal
     */
    changeToPortal() {
        this.defaultToUnAssignedProjects();
    }
    /**
     * assign project
     */
    assignProject() {
        let selectedProject: any = [];
        this.selectedData.forEach((projectElement: any) => {
            let project = new ProjectAdmin();
            if (this.radioValue === '1') {
                project.BusinessId = this.Business;
            } else {
                project.BusinessId = 0;
            }
            if (this.radioValue === '2') {
                project.PortalId = this.Portal;
            } else {
                project.PortalId = 0;
            }
            selectedProject.push({
                Id: projectElement.Id, Name: projectElement.Name, ApplicationId: BaseServices.ApplicationId, BusinessId: project.BusinessId,
                PortalId: project.PortalId, CreatedBy: BaseServices.UserId
            });
        });
        if ((this.Business || this.Portal) && this.selectedData) {
            this.subscriptions.push(this.assignProjectService
                .assignProject(selectedProject)
                .subscribe(isProjectAssigned => {
                    if (isProjectAssigned) {
                        this.refreshTable();
                        this.toasterComponent.callToast();
                    }
                }))
        }
    }
    /**
     * business change event
     * @param e {any}
     */
    onBusinessChanged(e: any) {
        this.selectedValue = e.value;
        this.selectedData = [];
        if (e.value) {
            this.isValueSelected = true;
            this.refreshTable();
        } else {
            this.isValueSelected = false;
            this.defaultToUnAssignedProjects();
        }
    }
    /**
     * portal change event
     * @param e {any}
     */
    onPortalChanged(e: any) {
        this.selectedValue = e.value;
        this.selectedData = [];
        if (e.value) {
            this.isValueSelected = true;
            this.refreshTable();
        } else {
            this.isValueSelected = false;
            this.defaultToUnAssignedProjects();
        }
    }
    /**
     * table refresh
     */
    refreshTable() {
        if (this.radioValue === '1') {
            this.subscriptions.push(this.assignProjectService
                .getProjectListByBusiness(BaseServices.ApplicationId, this.selectedValue)
                .subscribe((businessProject: any) => {
                    this.projects = businessProject;
                    businessProject.forEach((project: any) => {
                        if (project.IsMapped === true) {
                            this.selectedData.push(project);
                        }
                    });
                }))
        } else {
            this.subscriptions.push(this.assignProjectService
                .getProjectListByPortal(BaseServices.ApplicationId, this.selectedValue)
                .subscribe((portalProject: any) => {
                    this.projects = portalProject;
                    portalProject.forEach((project: any) => {
                        if (project.IsMapped === true) {
                            this.selectedData.push(project);
                        }
                    });
                }))
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion

}
