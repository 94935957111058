import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { TaskManagerNoRecordsComponent } from 'src/app/kuba/shared/taskmanagernorecords/taskmanagernorecords.component';

export const taskManagerNorecordRoutes: Route[] = [
    {
        path: '',
        children: [
            {
                path: 'task-manager/:message',
                component: TaskManagerNoRecordsComponent
            }
        ]
    }
];
