import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ControlDto } from './../models/vehicle-control';
import { VehicleControlService } from './vehicle-control.services';

@Injectable()
export class VehicleControlListResolver implements Resolve<ControlDto[]> {
    constructor(private backend: VehicleControlService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<ControlDto[]> {
        return this.backend.getAllVehicleControl();
    }
}
@Injectable()
export class VehicleControlListByVehicleResolver
    implements Resolve<ControlDto[]> {
    constructor(private backend: VehicleControlService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<ControlDto[]> {
        return this.backend.getVehicleControlByVehicleId(+route.params['Id']);
    }
}
@Injectable()
export class VehicleControlEditResolver implements Resolve<ControlDto> {
    constructor(private backend: VehicleControlService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<ControlDto> {
        if (+route.params['id'] > 0) {
            return this.backend.getVehicleControlById(+route.params['id']);
        } else {
            return null;
        }
    }
}
