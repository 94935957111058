<div class="page-title">
    <a *ngIf="!isFs" [routerLink]="['./../../../../list/own']" class="btn btn-icon"
        title="{{'BACK_PROJECTS'|translate}}">
        <span translate class="icon ic-xs icon-back" ></span>
    </a>
    <span translate>REGISTRATION</span>
    <span class="page-actions">
        <a [routerLink]="['./../edit',0]" class="btn btn-success" *ngIf="!isGuest">
            <span class="icon ic-sm icon-add"></span>
            <span>
                <span translate>NEW</span>
            </span>
        </a>
    </span>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table #registration [value]="registrations" dataKey="Id" [(selection)]="selectedEmployee" [rows]="10"
    [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}"
        [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" exportFilename="Registration Details">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="table-options">
                    <div *ngIf="registrations.length>0" class="pull-right">
                        <kuba-export [reportData]="registration.filteredValue || registration.value"
                            [additionalData]="additionalData"></kuba-export>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>{{'TYPE_OF_REGISTRATIONS' | translate}}</th>
                <th pSortableColumn="Description">{{'DESCRIPTION' | translate}} <p-sortIcon field="Description"></p-sortIcon></th>
                <th pSortableColumn="RegisteredBY">{{'REGISTEREDBY' | translate}} <p-sortIcon field="RegisteredBY"></p-sortIcon></th>
                <th pSortableColumn="Modifier">{{'EDITED_BY' | translate}} <p-sortIcon field="Modifier"></p-sortIcon></th>
                <th pSortableColumn="CreatedOn">{{'DATE_REGISTERED' | translate}} <p-sortIcon field="CreatedOn"></p-sortIcon></th>
                <th>{{'OPTIONS'| translate}}</th>
            </tr>
            <tr>
                <th>
                    <p-dropdown [options]="types"
                        (onChange)="registration.filter($event.value, 'RegistrationTypeName', 'equals')"
                        styleClass="p-column-filter" placeholder="Select"  [showClear]="true" appendTo="body">
                        <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                        </ng-template>
                    </p-dropdown>
                </th>
                <th>
                    <input pInputText type="text"
                        (input)="registration.filter($any($event.target)?.value, 'Description', 'contains')" placeholder=""
                        class="p-column-filter" >
                </th>
                <th>
                    <input pInputText type="text"
                        (input)="registration.filter($any($event.target)?.value, 'RegisteredBY', 'contains')" placeholder=""
                        class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text"
                        (input)="registration.filter($any($event.target)?.value, 'Modifier', 'contains')" placeholder=""
                        class="p-column-filter" >
                </th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-register>
            <tr>
                <td>
                    <a [routerLink]="['./../edit', register.Id]">{{register.RegistrationTypeName}}</a>
                </td>
                <td>
                    <span>{{register.Description}}</span>
                </td>
                <td>
                    <span>{{register.RegisteredBY}}</span>
                </td>
                <td>
                    <span>{{register.Modifier}}</span>
                </td>
                <td>
                    <span>{{register.CreatedOn |date : 'dd/MM/yyyy'}}</span>
                </td>
                <td class="col-button">
                    <a (click)="confirm(register.Id)" class="btn btn-icon" title="{{'DELETE'|translate}}"
                        routerLinkActive="active">
                        <i class="icon ic-sm icon-trash"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="6">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<router-outlet></router-outlet>
<toaster-component></toaster-component>