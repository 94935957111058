import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HelperService } from 'src/app/_services/helper.service';
import { Meeting } from './../models/meeting';
import { MeetingService } from '../services/meeting.services';
import { PdfSetting } from 'src/app/_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

let jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
    selector: 'meeting-list',
    templateUrl: 'meeting-list.component.html'
})

export class MeetingListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    meetings: SelectItem[];
    meetingData: Meeting[];
    selectedCity: string;
    public edited = false;
    additionalData: any;
    meetingDocument: any =[];
    meetingAttachment: any =[];
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor
    /**
     * constructor
     * @param MeetingService {MeetingService}
     * @param route {ActivatedRoute}
     * @param router {Router}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private meetingService: MeetingService,
        private route: ActivatedRoute,
        public router: Router,
        private confirmationService: ConfirmationService,
        private translate: TranslateService) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => { 
        this.additionalData = {
            fileName: val.MEETING_LIST,
            header: 'Meeting List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Type of Meeting', dataKey: 'MeetingTypeText' },
                { title: 'Meeting Convener', dataKey: 'Title' },
                { title: 'Meeting date', dataKey: 'DateForPdf' },
                { title: 'Start Time', dataKey: 'StartTime' },
                { title: 'End Time', dataKey: 'EndTime' },
                { title: 'Hours spent', dataKey: 'Duration' }]
        };
    }));
    }
    //#endregion

    //#region page-events
    /**
     * bind meeting list
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let userRole = BaseServices.UserRole;
        if (userRole === 'Admin') {
            this.edited = true;
        }
        this.meetingData = [];
        this.meetingData = this.route.snapshot.data['list'];
        for(let i=0;i<this.meetingData.length;i++){
            this.meetingDocument.push(this.meetingData[i].MeetingDocument);
        }
        this.meetings = [];
        let meetings = this.route.snapshot.data['business'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.meetings = [];
            this.meetings.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (meetings) {
            meetings.forEach((element:any) => {
                this.meetings.push({ label: element.Name, value: element.Id });
            });
        }
    }
    //#endregion

    //#region control-events
    /**
         * get meeting list by business
         * @param value {any}
         */
    dropdownChange(value:any) {
        this.meetingData = [];
        this.subscriptions.push(this.meetingService.getMeetingByBusiness(value).subscribe(
            res => {
                this.meetingData = res;
            }
        ))
    }

    //#region methods
    /**
     *  bind meeting list
     */
    fetchData() {
        this.meetingData = [];
        this.subscriptions.push(this.meetingService.getMeetingByBusiness(BaseServices.BusinessId).subscribe(res => {
            if (res) { this.meetingData = res; }
        }));
    }

    /**
     * delet meeting by id
     * @param id {number}
     */
    confirm(id:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'), accept: () => {
                this.subscriptions.push(this.meetingService.delete(id).subscribe(res => {
                    if (res) {
                        this.toasterComponent.callToastDlt();
                        this.fetchData();
                    }
                }));
            }
        });
    }
    //#endregion

    downloadAttachment(id){
        this.subscriptions.push(this.meetingService.attachment(id).subscribe((res : any) => {
           var attachment = res.MeetingDocument;
            if (attachment !=0 ) {
                attachment.forEach((x:any) => {               
                this.meetingAttachment.push(x.path);
                });   
            }

            var attLength=this.meetingAttachment.length
            for(let i=0; i<=attLength-1; i++){
                window.open(this.meetingAttachment[i]);
                window.close();
            }
            this.meetingAttachment=[];
        }));
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
