import { VehicleListResolver } from './../vehicle-consumption/services/vehicle-consumption-resolver.service';
import { VehicleListComponent } from './../vehicle/components/vehicleList.component';
import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { VehicleTrackListComponent } from './components/vehicletrackList.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { VehicleTrackingComponent } from './vehicleTracking.component';
import { CommonViewComponent } from './../common/components/commonView.component';
export const vehicleTrackingRoutes: Route[] = [
    {
        path: 'vehicle-tracking',
        component: VehicleTrackingComponent,
        children: [
            {
                path: 'list',
                component: VehicleTrackListComponent
            },
            {
                path: 'details/:id',
                component: CommonViewComponent,
                data: { parent: 'VEHICLE' },
                canActivate: [AuthGuard],
                children: [...deviationRoutes, ...timetableRoutes]
            }
        ]
    }
];
