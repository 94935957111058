import { TranslateModule } from '@ngx-translate/core';
import { VehicleServices } from 'src/app/kuba/vehicle/services/vehicle.service';
import { BrowserModule } from '@angular/platform-browser';
import { AutoWizLoginComponent } from './components/autowiz-login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from '../shared/appShared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MyDatePickerModule } from 'mydatepicker';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        RouterModule,
        BrowserModule,
        AppSharedModule,
        RouterModule,
        MyDatePickerModule,
        TranslateModule,
        ConfirmDialogModule,
    ],
    exports: [AutoWizLoginComponent],
    declarations: [AutoWizLoginComponent],
    providers: [VehicleServices],
})
export class AutoWizLoginModule { }
