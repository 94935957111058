<div class="card-body">
    <div class="page-title">
        <span translate>EMERGENCY_LIGHTING_SYSTEM</span>
    </div>
    <div class="row" *ngIf="editMode">
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="4"><label translate>EMERGENCY_LIGHTING_SYSTEM</label></th>
                    </tr>
                    <tr>
                        <th colspan="4"><label translate>TYPE_OF_SYSTEM</label></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width:60px"><label translate>SUPPLIER</label></td>
                        <td style="width:140px">
                            <input type="text" class="form-control" [(ngModel)]="lighting.Supplier" />
                        </td>
                        <td style="width:60px"><label translate>INSTALLED_YEAR</label></td>
                        <td style="width:140px">
                            <input type="text" class="form-control" [(ngModel)]="lighting.InstalledYear" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="7"><label translate>OTHER_EQUIPMENT</label></th>
                    </tr>
                    <tr>
                        <th style="text-align:center" colspan="2"><span translate>NUMBER_NO</span></th>
                        <th style="text-align:center"><span translate>FLOOR</span></th>
                        <th style="text-align:center"><span translate>LOCATION</span></th>
                        <th style="text-align:center"><span translate>TYPE_WATT</span></th>
                        <th style="text-align:center"><span translate>ATTACHED_DISTRIBUTION_COURSE</span></th>
                        <th style="text-align:center"><span translate>SOURCE_LYSKILDE</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of otherEquipments">
                        <td class="col-button" style="width:20px">
                            <button type="button" (click)="addItem(item)" class="btn btn-icon">
                                <span class="icon ic-sm icon-add"></span>
                            </button>
                            <button *ngIf="otherEquipments.length > 1" type="button" (click)="deleteItem(item)" [disabled]="disableItemDelete(item)"
                                class="btn btn-icon">
                                <span *ngIf="disableItemDelete(item)"
                                    pTooltip="{{'DELETE_EQUIPMENT_NOT_POSSIBLE_DEVIATION' | translate}}"
                                    class="icon ic-sm icon-trash"></span>
                                <span *ngIf="!disableItemDelete(item)" class="icon ic-sm icon-trash"></span>
                            </button>
                        </td>
                        <td style="width:85px">
                            <input type="text" class="form-control" [(ngModel)]="item.no" />
                        </td>
                        <td style="width:85px">
                            <input type="text" class="form-control" [(ngModel)]="item.floor" />
                        </td>
                        <td style="width:130px">
                            <input type="text" class="form-control" [(ngModel)]="item.location" />
                        </td>
                        <td style="width:100px">
                            <input type="text" class="form-control" [(ngModel)]="item.type" />
                        </td>
                        <td style="width:100px">
                            <input type="text" class="form-control" [(ngModel)]="item.distributionCourse" />
                        </td>
                        <td style="width:100px">
                            <input type="text" class="form-control" [(ngModel)]="item.source" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div translate>EMERGENCY_TABLE_DESCRIP_TEXT</div>
        </div>
    </div>
    <div class="row" *ngIf="!editMode">
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="4"><label translate>EMERGENCY_LIGHTING_SYSTEM</label></th>
                    </tr>
                    <tr>
                        <th colspan="4"><label translate>TYPE_OF_SYSTEM</label></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="width:70px"><label translate>SUPPLIER</label></td>
                        <td style="width:130px"><span>{{lighting.Supplier}}</span></td>
                        <td style="width:70px"><label translate>INSTALLED_YEAR</label></td>
                        <td style="width:130px"><span>{{lighting.InstalledYear}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="6"><label translate>OTHER_EQUIPMENT</label></th>
                    </tr>
                    <tr>
                        <th style="text-align:center"><span translate>NUMBER_NO</span></th>
                        <th style="text-align:center"><span translate>FLOOR</span></th>
                        <th style="text-align:center"><span translate>LOCATION</span></th>
                        <th style="text-align:center"><span translate>TYPE_WATT</span></th>
                        <th style="text-align:center"><span translate>ATTACHED_DISTRIBUTION_COURSE</span></th>
                        <th style="text-align:center"><span translate>SOURCE_LYSKILDE</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of otherEquipments">
                        <td style="width:85px"><span>{{item.no}}</span></td>
                        <td style="width:85px"><span>{{item.floor}}</span></td>
                        <td style="width:100px"><span>{{item.location}}</span></td>
                        <td style="width:85px"><span>{{item.type}}</span></td>
                        <td style="width:130px"><span>{{item.distributionCourse}}</span></td>
                        <td style="width:100px"><span>{{item.source}}</span></td>
                    </tr>
                </tbody>
            </table>
            <div translate>EMERGENCY_TABLE_DESCRIP_TEXT</div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>