<div class="main-content">
    <div class="main-heading">
        <strong>
            <span translate>ELECTRO</span>
        </strong>
    </div>
    <ecpl-tab-navigation *ngIf="isAdmin" [linkItems]="enabledAdditionalFeatures" [parentId]="fId"></ecpl-tab-navigation>
    <div class="panel-tabs" *ngIf="!isAdmin">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./list']" translate>CONTRACTS_OVERVIEW</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./inspection']" translate>INSPECTION_OVERVIEW</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./dev']" translate>DEVIATION_OVERVIEW</a>
                </li>
                <li *ngIf="isGuestHideButton" [routerLinkActive]="['active']">
                    <a [routerLink]="['./settings']" translate>SETTINGS</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>