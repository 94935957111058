<div class="page-title">
  <span translate>PRODUCTION</span>
  <span class="page-actions pull-right">
    <a [routerLink]="['./../edit', 0]" class="btn btn-success">
      <span class="icon ic-sm icon-add"></span>
      <span translate>NEW</span>
    </a>
  </span>
</div>
<div class="table-view">
  <!-- Table starts -->
  <p-table
    #dt
    [value]="productions"
    dataKey="Id"
    [(selection)]="selectedProduction"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    exportFilename="Registration Details"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="filter-controls-wrapper" style="display: none">
          <form [formGroup]="ProductionControlForm" class="form">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="cmpname" class="col-form-label">
                    <span translate>FROM_DATE</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="startdate"
                      [options]="startDateOptions"
                      formControlName="StartDate"
                      placeholder="{{ 'SELECT_START_DATE' | translate }}"
                      (dateChanged)="onStartDateChanged($event)"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="cmpno" class="col-form-label">
                    <span translate>END_DATE</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="enddate"
                      (dateChanged)="onEndDateChanged($event)"
                      [options]="endDateOptions"
                      formControlName="EndDate"
                      placeholder="{{ 'SELECT_END_DATE' | translate }}"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="table-options">
          <div class="pull-right">
            <div class="action-btns-group">
              <button class="btn p-3 filter-toggle">
                <span class="icon ic-sm icon-filter"></span>
                <span translate>FILTER</span>
              </button>
            </div>
            <div class="action-btns-group">
              <kuba-export
                [reportData]="dt.filteredValue || dt.value"
                [additionalData]="additionalData"
              ></kuba-export>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 55px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="LotNumber">
          {{ "LOT_NUMBER" | translate }}
          <p-sortIcon field="LotNumber"></p-sortIcon>
        </th>
        <th pSortableColumn="DeliveryOn">
          {{ "DELIVERY_DATE" | translate }}
          <p-sortIcon field="DeliveryOn"></p-sortIcon>
        </th>
        <th pSortableColumn="ClientNumber">
          {{ "CLIENT_NUMBER" | translate }}
        </th>
        <th pSortableColumn="ClientName">
          {{ "PRODUCTION_CLIENT_NAME" | translate }}
        </th>
        <th pSortableColumn="Quantity">
          {{ "QUANTITY" | translate }}
          <p-sortIcon field="Quantity"></p-sortIcon>
        </th>
        <th pSortableColumn="ProductionType">
          {{ "PRODUCTION_TYPES" | translate }}
        </th>
      </tr>
      <tr>
        <th></th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              dt.filter($any($event.target)?.value, 'LotNumber', 'contains')
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th>
          <p-dropdown
            [options]="ClientNumberList"
            (onChange)="onSubChanged($event, cd)"
            #cd
            styleClass="p-column-filter"
            placeholder=""
            [filterBy]="ClientNumber"
            [showClear]="true"
          >
            <ng-template let-clientnumber pTemplate="item">
              {{ clientnumber.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th>
          <p-dropdown
            [options]="ClientNameList"
            (onChange)="onnameChanged($event, cnl)"
            #cnl
            styleClass="p-column-filter"
            placeholder=""
            [filterBy]="null"
            [showClear]="true"
          >
            <ng-template let-namelist pTemplate="item">
              {{ namelist.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th></th>
        <th>
          <p-dropdown
            [options]="ProductionTypeList"
            (onChange)="ontypeChanged($event, cpl)"
            #cpl
            styleClass="p-column-filter"
            placeholder=""
            [filterBy]="null"
            [showClear]="true"
          >
            <ng-template let-types pTemplate="item">
              {{ types.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-reception>
      <tr>
        <td style="width: 55px">
          <p-tableCheckbox id="selectedOrders" [value]="reception">
          </p-tableCheckbox>
        </td>
        <td>
          <a [routerLink]="['./../edit', reception.Id]">{{
            reception.LotNumber
          }}</a>
        </td>
        <td>
          <span translate>{{
            reception.DeliveryOn | date : "MM/dd/yyyy"
          }}</span>
        </td>
        <td>
          <span>{{ reception.ClientNumber }}</span>
        </td>
        <td>
          <span>{{ reception.ClientName }}</span>
        </td>
        <td>
          <span>{{ reception.Quantity }}</span>
        </td>
        <td>
          <span>{{ reception.ProductionType }}</span>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="7">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <div class="ui-helper-clearfix">
        <div class="table-options">
          <div class="pull-left">
            <span>
              <button
                class="btn btn-danger"
                type="submit"
                (click)="deleteProduction()"
              >
                <i class="icon ic-sm icon-trash"></i>
                <span translate>DELETE_PRODUCTION</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
  <!-- Table Ends -->
</div>
<router-outlet></router-outlet>
<p-confirmDialog
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
