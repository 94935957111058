<div class="tab-content">
    <div class="page-title">
        <span>VEHICLE_CONTROL</span>
    </div>
    <form [formGroup]="controlForm" #f="ngForm" novalidate name="form">
        <div class="form-group" *ngIf="controlForm.controls['CheckpointName']">
            <label for="CheckpointName" class="col-form-label">
                <span translate>CHECKPOINT_NAME </span>
                <span class="required">*</span>
            </label>

            <div>
                <input type="text" pInputText formControlName="CheckpointName">
            </div>
            <control-messages [control]="controlForm.controls['CheckpointName']"></control-messages>
        </div>
        <div class="form-group">
            <label for="Comments" class="col-form-label">
                <span translate>COMMENTS</span>
            </label>
            <div>
                <textarea pInputTextarea formControlName="Comments"></textarea>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <button type="button" (click)="back()" class="btn btn-outline-secondary">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button class="btn btn-primary" type="submit" [disabled]="!controlForm.valid" (click)="saveVehicleControl()">
                <span translate>SAVE</span>
            </button>
            <button class="btn btn-primary" type="submit" (click)="SaveAndNew()" [disabled]="!controlForm.valid">
                <span translate>SAVE_CREATE_NEW</span>
            </button>
        </div>
    </form>
</div>
<toaster-component></toaster-component>