import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'inspection-followup-tab',
    templateUrl: 'inspection-followup-tab.component.html'
})

export class InspectionFollowupTabComponent implements OnInit {

    a:number;
    isEditMode: boolean;
    constructor(private route: ActivatedRoute) {
        this.a =1;
        let electroId = this.route.snapshot.params['Iid'];
       if(electroId > 0){
           this.isEditMode = true;
       }
     }

    ngOnInit() { }
}