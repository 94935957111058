import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import {
  Feature as FeatureAccess,
  FeatureBase,
} from './../kuba/features/models/features-base.model';
import { FeaturesBaseServices } from './../kuba/features/services/features-base.services';
import { BaseServices } from './../kuba/kuba.services';
import { Rights } from '../_models/feature';
import { FeatureKey } from '../_models/feature';
import { BusinessServices } from '../kuba/businesses/services/business.services';
import { BusinessPortalService } from './../kuba/portals/services/business-portal.services';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  features: any = [];
  navSource: any = [];
  businessId: number = BaseServices.BusinessId;
  currentUserRole: string;
  appId: number;
  featureBase: FeatureBase;
  featuresAccess: FeatureAccess[];
  placeholder = true;
  @Input() urlBase: string;
  private subscriptions: Subscription[] = [];

  constructor(
    public businessServices: BusinessServices,
    public featuresBaseServices: FeaturesBaseServices,
    public businessPortalService: BusinessPortalService,
    public route: ActivatedRoute
  ) {}
  ngOnInit() {
    this.currentUserRole = BaseServices.UserRole;
    this.appId = BaseServices.ApplicationId;
    if (BaseServices.roleId === '1') {
      this.features = BaseServices.userFeatures;
      if (this.currentUserRole !== 'Editor') {
        _.remove(this.features, (n: any) => {
          return n.translationKey === 'USER_LIST';
        });
        _.remove(this.features, (n: any) => {
          return n.translationKey === 'OUR_COMPANY';
        });
      }
      if (this.currentUserRole === 'Editor') {
        let index = _.findIndex(this.features, [
          'translationKey',
          'OUR_COMPANY',
        ]);
        if (index >= 0) {
          this.features[index].linkText = _.replace(
            this.features[index].linkText,
            '%s',
            this.businessId.toString()
          );
        }
      }
      this.placeholder = false;
    } else if (BaseServices.roleId === '2') {
      this.subscriptions.push(
        this.businessPortalService
          .getPortalFeatures(BaseServices.PortalId)
          .subscribe((res: any) => {
            if (res) {
              let featureBase = BaseServices.getUserFeatures();
              let template = <any>JSON.parse(featureBase.FeaturesTemplate!);
              featureBase.FeaturesTemplate = JSON.stringify(
                template.filter(
                  (x: any) =>
                    (x.navigationRoles.indexOf('P') > -1 &&
                      x.IsFeature.indexOf('N') > -1) ||
                    (x.navigationRoles.indexOf('P') > -1 && x.IsDefault === 1)
                )
              );
              featureBase.Features = res.Features;
              featureBase.Details = res;
              let baseFeatures =
                FeaturesBaseServices.getFeatureDetails(featureBase);
              this.features = baseFeatures.filter((x: any) => {
                if (res.Features === null) {
                  return x.IsDefault === 1;
                } else {
                  return x.checked === true;
                }
              });
              this.placeholder = false;
              let assignBusiness = {
                IsDefault: 0,
                IsFeature: 'FN',
                access: true,
                checked: true,
                id: 2000,
                key: '037',
                linkText: 'portal/edit/' + BaseServices.PortalId + '/user',
                name: 'assign-business',
                navigationRoles: 'P',
                translationKey: 'Assign Business',
              };
              if (BaseServices.roleId === '2') {
                //KW-666 - To reflect the change of Business List name for Portal users as well
                this.features.forEach((element: any) => {
                  if (element.id === 2) {
                    element.translationKey =
                      BaseServices.PortalBusinessListName !== ''
                        ? BaseServices.PortalBusinessListName
                        : element.translationKey;
                  }
                });
              }
              this.placeholder = false;
            }
            if (this.currentUserRole !== 'Editor') {
              _.remove(this.features, (n: any) => {
                return n.translationKey === 'USER_LIST';
              });
              _.remove(this.features, (n: any) => {
                return n.translationKey === 'OUR_COMPANY';
              });
            }
            if (this.currentUserRole === 'Editor') {
              let index = _.findIndex(this.features, [
                'translationKey',
                'OUR_COMPANY',
              ]);
              if (index >= 0) {
                this.features[index].linkText = _.replace(
                  this.features[index].linkText,
                  '%s',
                  this.businessId.toString()
                );
              }
            }
            BaseServices.setSessionData(
              'leftNav',
              JSON.stringify(this.features)
            );
          })
      );
    } else {
      this.subscriptions.push(
        this.businessServices
          .getById(BaseServices.BusinessId)
          .subscribe((result: any) => {
            if (result) {
              localStorage.setItem('BusinessDetails', JSON.stringify(result));
              let featureBase = BaseServices.getUserFeatures();
              let template = <any>JSON.parse(featureBase.FeaturesTemplate!);
              featureBase.FeaturesTemplate = JSON.stringify(
                template.filter(
                  (x: any) =>
                    (x.navigationRoles.indexOf('B') > -1 &&
                      x.IsFeature.indexOf('N') > -1) ||
                    (x.navigationRoles.indexOf('B') > -1 && x.IsDefault === 1)
                )
              );
              featureBase.Features = result.Features;
              featureBase.Type = 'business';
              featureBase.Details = result;
              let baseFeatures =
                FeaturesBaseServices.getFeatureDetails(featureBase);
              this.features = baseFeatures.filter((x: any) => {
                if (result.Features === null) {
                  return x.IsDefault === 1;
                } else if (x.id === FeatureKey.MY_PAGE) {
                  return x.id === FeatureKey.MY_PAGE;
                } else {
                  return x.checked === true || x.IsDefault === 1;
                }
              });
              this.placeholder = false;
            }
            if (this.currentUserRole !== 'Editor') {
              _.remove(this.features, (n: any) => {
                return n.translationKey === 'USER_LIST';
              });
              _.remove(this.features, (n: any) => {
                return n.translationKey === 'OUR_COMPANY';
              });
            }
            if (this.currentUserRole === 'Editor') {
              let index = _.findIndex(this.features, [
                'translationKey',
                'OUR_COMPANY',
              ]);
              if (index >= 0) {
                this.features[index].linkText = _.replace(
                  this.features[index].linkText,
                  '%s',
                  this.businessId.toString()
                );
              }
              let mypageindex = _.findIndex(this.features, [
                'id',
                FeatureKey.MY_PAGE,
              ]);
              if (index >= 0) {
                this.features[mypageindex].translationKey = 'MY_PAGE';
              }
            }
            if (this.currentUserRole === 'Guest') {
              if (
                BaseServices.FeatureId === FeatureKey.CONTACTS ||
                BaseServices.FeatureId === FeatureKey.EMPLOYEES
              ) {
                let userRightsId = Rights.CHOOSE_PROJECTFDV; // check rights for project/fdv tab
                let showProjectFdv = BaseServices.checkUserRights(
                  userRightsId,
                  this.currentUserRole
                );
                if (showProjectFdv === true) {
                  let filterFeaturesKey = [
                    FeatureKey.DASHBOARD,
                    FeatureKey.EMPLOYEES,
                    FeatureKey.FOLLOW_UP,
                    FeatureKey.CONTACTS,
                    FeatureKey.MANUALS,
                  ];
                  this.subscriptions.push(
                    this.businessServices
                      .LeftNavigationAssignAccess(
                        this.businessId,
                        this.appId,
                        this.currentUserRole
                      )
                      .subscribe((res: any) => {
                        if (res.projectCount > 0) {
                          filterFeaturesKey.push(FeatureKey.PROJECT);
                        }
                        if (res.fdvCount > 0) {
                          filterFeaturesKey.push(FeatureKey.FDV);
                        }
                        if (res.vehicleCount > 0) {
                          filterFeaturesKey.push(FeatureKey.VEHICLES);
                        }
                        if (res.frameworkAgreementCount > 0) {
                          filterFeaturesKey.push(
                            FeatureKey.FRAMEWORK_AGREEMENT
                          );
                        }
                        if (res.internalControlCount > 0) {
                          filterFeaturesKey.push(FeatureKey.INTERNAL_CONTROL);
                        }

                        if (res.kundeELCount > 0) {
                          filterFeaturesKey.push(FeatureKey.KUNDE_EL);
                        }
                        if (res.serviceAgreementCount > 0) {
                          filterFeaturesKey.push(FeatureKey.SERVICE_AGREEMENT);
                        }

                        this.features = _.filter(this.features, (x: any) => {
                          return (
                            filterFeaturesKey.indexOf(x.id) > -1 ||
                            (x.navigationRoles.indexOf('B') > -1 &&
                              x.IsDefault === 1)
                          );
                        });
                      })
                  );
                } else {
                  let filterFeaturesKey = [
                    FeatureKey.DASHBOARD,
                    FeatureKey.EMPLOYEES,
                    FeatureKey.FOLLOW_UP,
                    FeatureKey.CONTACTS,
                    FeatureKey.MANUALS,
                  ];
                  this.subscriptions.push(
                    this.businessServices
                      .LeftNavigationAssignAccess(
                        this.businessId,
                        this.appId,
                        this.currentUserRole
                      )
                      .subscribe((res: any) => {
                        if (res.projectCount > 0) {
                          filterFeaturesKey.push(FeatureKey.PROJECT);
                        }
                        if (res.fdvCount > 0) {
                          filterFeaturesKey.push(FeatureKey.FDV);
                        }
                        if (res.foodSafetyCount > 0) {
                          filterFeaturesKey.push(FeatureKey.FOOD_SAFETY);
                        }
                        if (res.controlCount > 0) {
                          filterFeaturesKey.push(FeatureKey.KUBA_CONTROL);
                        }
                        if (res.frameworkAgreementCount > 0) {
                          filterFeaturesKey.push(
                            FeatureKey.FRAMEWORK_AGREEMENT
                          );
                        }
                        if (res.internalControlCount > 0) {
                          filterFeaturesKey.push(FeatureKey.INTERNAL_CONTROL);
                        }
                        if (res.vehicleCount > 0) {
                          filterFeaturesKey.push(FeatureKey.VEHICLES);
                        }
                        if (res.kundeELCount > 0) {
                          filterFeaturesKey.push(FeatureKey.KUNDE_EL);
                        }
                        if (res.serviceAgreementCount > 0) {
                          filterFeaturesKey.push(FeatureKey.SERVICE_AGREEMENT);
                        }
                        this.features = _.filter(this.features, (x: any) => {
                          return (
                            filterFeaturesKey.indexOf(x.id) > -1 ||
                            (x.navigationRoles.indexOf('B') > -1 &&
                              x.IsDefault === 1)
                          );
                        });
                      })
                  );
                }
              } else {
                if (+BaseServices.FeatureId === FeatureKey.CLIENT) {
                  let filterFeaturesKey = [FeatureKey.MY_PAGE];
                  this.features = _.filter(this.features, (x: any) => {
                    return (
                      filterFeaturesKey.indexOf(x.id) > -1 ||
                      (x.navigationRoles.indexOf('B') > -1 && x.IsDefault === 1)
                    );
                  });
                } else {
                  let filterFeaturesKey = [FeatureKey.MY_PAGE];
                  //KW-809- Project access removed for Subcontractor guest login

                  this.features = _.filter(this.features, (x: any) => {
                    return (
                      filterFeaturesKey.indexOf(x.id) > -1 ||
                      (x.navigationRoles.indexOf('B') > -1 && x.IsDefault === 1)
                    );
                  });
                }
                let dashboardIndex = _.findIndex(this.features, [
                  'translationKey',
                  'DASHBOARD',
                ]);
                if (dashboardIndex >= 0) {
                  this.features[dashboardIndex].linkText = 'my-page';
                }
                let filterFeatures = [
                  FeatureKey.EDIT_BUSINESS,
                  FeatureKey.DASHBOARD,
                  FeatureKey.CONTACTS,
                ];
                _.remove(this.features, (n: any) => {
                  return filterFeatures.indexOf(n.id) > -1;
                });
                let index = _.findIndex(this.features, [
                  'id',
                  FeatureKey.MY_PAGE,
                ]);
                if (index >= 0) {
                  this.features[index].translationKey = 'MY_PROFILE';
                }
                this.features.forEach((element) => {
                  this.features = this.features.filter(
                    (x) => x.name != 'Flexi'
                  );
                });
              }
            }
            let mypageindex = _.findIndex(this.features, [
              'id',
              FeatureKey.MY_PAGE,
            ]);
            if (mypageindex >= 0) {
              this.features[mypageindex].translationKey = 'MY_PAGE';
            }
            if (BaseServices.roleId === '4') {
              this.features[mypageindex].translationKey = 'OUR_PAGE';
            }
            BaseServices.setSessionData(
              'leftNav',
              JSON.stringify(this.features)
            );
            if (window.name === 'Remote') {
              _.remove(this.features, (n: any) => {
                return n.translationKey === 'MY_PAGE';
              });
            } else {
              _.remove(this.features, (n: any) => {
                return n.checked === false && n.IsDefault === 0;
              });
            }
            if (BaseServices.roleId === '5') {
              let userRightsId = Rights.HIDE_EMPLOYEE_LIST;
              let showProjectFdv = BaseServices.checkUserRights(
                userRightsId,
                this.currentUserRole
              );
              if (showProjectFdv == true) {
                this.features.forEach((element: any) => {
                  this.features = this.features.filter(
                    (x: any) => x.name != 'Employees'
                  );
                });
              }
              let featureId = Rights.ADMINISTRATE_FLEXI;
              this.features.forEach((element) => {
                this.features = this.features.filter((x) => x.name != 'Flexi');
              });
              // let showFlexi = BaseServices.checkUserRights(
              //     featureId,
              //     this.currentUserRole
              // );
              // if (showFlexi == false) {
              //     this.features.forEach(element => {
              //         this.features = this.features.filter(x => x.name != "Flexi");
              //     });
              // }
            }
          })
      );
    }
    if (BaseServices.userFeatures != null) {
      var dashboard = BaseServices.userFeatures.filter(
        (x: any) => x.name == 'Dashboard'
      );
    }
    if (dashboard != null) this.setCurrentModule(dashboard);
  }

  checkFeatureStatus(feature: any): boolean {
    let featureAccess = false;
    let featuresAccess: any = this.featuresAccess;

    if (this.currentUserRole === 'Admin') {
      featureAccess = true;
    } else if (featuresAccess) {
      let checkFeature = new FeatureAccess();
      checkFeature = <FeatureAccess>_(featuresAccess)
        .thru((parent) => {
          return _.union(parent, _.map(parent, 'children'));
        })
        .flatten()
        .find({ id: feature.id });

      if (checkFeature) {
        featureAccess =
          checkFeature.access && checkFeature.checked ? true : false;
      }
    }
    return featureAccess;
  }

  setFeatureBase() {
    this.featuresBaseServices.getFeatureAccess();
  }

  setFeatureValue(feature: any): boolean {
    let resultAccess = false;
    let featuresAccess: any = this.featuresAccess;
    if (!feature.IsFeature) {
      resultAccess = true;
    } else if (featuresAccess) {
      let checkFeature = new FeatureAccess();
      checkFeature = <FeatureAccess>_(featuresAccess)
        .thru((parent) => {
          return _.union(parent, _.map(parent, 'children'));
        })
        .flatten()
        .find({ id: feature.id });

      if (checkFeature) {
        resultAccess =
          checkFeature.access && checkFeature.checked ? true : false;
        if (
          checkFeature.name === 'Manuals' &&
          BaseServices.UserRole === 'Guest'
        ) {
          let userRightsId = Rights.VIEW_MANUALS;
          checkFeature.access = BaseServices.checkUserRights(
            userRightsId,
            BaseServices.UserRole
          );
        }
      }
    }
    return resultAccess;
  }

  /**
   * Set working Module
   * @param feature {any}
   */
  setCurrentModule(feature: any) {
    sessionStorage.setItem('workingModule', JSON.stringify(feature));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}

export class FeatureDto {
  id: number;
  Title: string;
  LinkText: string;
  Key: string;
  IsFeature: boolean;
  AdminNavigation: boolean;
  PortalNavigation: boolean;
  BusinessNavigation: boolean;
}
