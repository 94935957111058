<div class="main-content" *ngIf="!ClientActive">
  <div class="main-heading">
    <span translate>CLIENT_KUNDE</span>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6">
          <form [formGroup]="clientForm">
            <div class="form-group">
              <label for="TypeofClient">
                <span translate>TYPE_OF_CLIENT</span>
                <span class="required">*</span>
              </label>
              <div>
                <p-dropdown
                  [options]="contact"
                  [style]="{ width: '390px' }"
                  type="text"
                  formControlName="ClientType"
                >
                </p-dropdown>
              </div>
              <control-messages
                [control]="clientForm.controls['ClientType']"
              ></control-messages>
            </div>
            <div class="form-group" *ngIf="clientForm.controls['Name']">
              <label for="Name">
                <span translate>NAME</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="Name"
                  class="ex-full-width"
                />
              </div>
              <control-messages
                [control]="clientForm.controls['Name']"
              ></control-messages>
            </div>
            <div class="form-group" *ngIf="isElectroExists">
              <label for="Number">
                <span translate>ORGANISATION_NUMBER</span>
              </label>
              <div>
                <input
                  type="number"
                  pInputText
                  formControlName="OrganizationNumber"
                  (keypress)="onMobileNumber($event, 20)"
                  onpaste="return false;"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="Number">
                <span translate>CLIENT_NUMBER</span>
                <span class="required">*</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="Number"
                  class="ex-full-width"
                  (keypress)="checkNumber($event)"
                />
              </div>
              <control-messages
                [control]="clientForm.controls['Number']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Address">
                <span translate>ADDRESS</span>
              </label>
              <div>
                <textarea pInputTextarea formControlName="Address"></textarea>
              </div>
            </div>
            <div class="form-group-25_75">
              <div class="form-group">
                <label for="ZipCode">
                  <span translate>ZIP_CODE</span>
                </label>
                <div>
                  <input
                    type="number"
                    type="number"
                    pInputText
                    formControlName="Zip"
                    (keypress)="onMobileNumber($event, 20)"
                    onpaste="return false;"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="Location">
                  <span translate>LOCATION</span>
                </label>
                <div>
                  <input type="text" pInputText formControlName="Location" />
                </div>
              </div>
              <control-messages
                [control]="clientForm.controls['Zip']"
              ></control-messages>
            </div>
            <div class="form-group">
              <label for="Mobile">
                <span translate>MOBILE</span>
              </label>
              <div class="phone-field">
                <p-dropdown
                  [options]="mobile"
                  formControlName="Phonecode"
                  [filter]="true"
                  [style]="{ width: '110px' }"
                  placeholder="Select"
                  optionLabel="label"
                  optionValue="value"
                >
                </p-dropdown>

                <input
                  type="number"
                  pInputText
                  formControlName="Mobile"
                  (keypress)="onMobileNumber($event, 20)"
                  onpaste="return false;"
                />
              </div>
            </div>
            <div class="form-group-50_50">
              <div class="form-group">
                <label for="Telephone">
                  <span translate>TELEPHONE</span>
                </label>
                <div>
                  <input
                    type="number"
                    pInputText
                    formControlName="Telephone"
                    (keypress)="onMobileNumber($event, 20)"
                    onpaste="return false;"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="EmailId">
                <span translate>EMAIL</span>
                <span class="required">*</span>
              </label>
              <div>
                <input type="text" pInputText formControlName="Email" />
                <div>
                  <span style="color: red" translate>{{
                    isEmailExist ? "" : "EMAIL_EXIST_OTHER_CONTACTS"
                  }}</span>
                </div>
                <control-messages
                  [control]="clientForm.controls['Email']"
                ></control-messages>
              </div>
            </div>
            <div class="form-group" *ngIf="isElectroExists">
              <label for="Name">
                <span translate>OWNER</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="Owner"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group" *ngIf="isElectroExists">
              <label for="Name">
                <span translate>CEO</span>
              </label>
              <div>
                <input
                  type="text"
                  pInputText
                  formControlName="CEO"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group" *ngIf="isElectroExists">
              <label for="Number">
                <span translate>NUM_EMPLOYEES</span>
              </label>
              <div>
                <input
                  type="number"
                  pInputText
                  formControlName="NoOfEmployees"
                  (keypress)="onMobileNumber($event, 20)"
                  onpaste="return false;"
                  class="ex-full-width"
                />
              </div>
            </div>
            <div class="form-group" *ngIf="isElectroExists">
              <label for="Address">
                <span translate>HOMEPAGE_ADDRESS</span>
              </label>
              <div>
                <textarea
                  pInputTextarea
                  formControlName="HomepageAddress"
                ></textarea>
              </div>
            </div>
            <div class="form-group">
              <label for="Comment">
                <span translate>COMMENTS</span>
              </label>
              <div>
                <textarea pInputTextarea formControlName="Comment"></textarea>
              </div>
            </div>
            <div class="mb-2">
              <p-checkbox
                [(ngModel)]="checked"
                label="{{ 'APPROVAL_NOTICE' | translate }}"
                [disabled]="true"
                formControlName="IsApprovalNotice"
                [binary]="true"
              >
              </p-checkbox>
            </div>
            <label *ngIf="approvedDate">
              <span translate>READ_APPLICATION</span>
              {{ approvedDate | date : "dd/MM/yyyy, h:mm a" }}</label
            >
            <div class="upload-file-container">
              <div class="form-group">
                <div class="upload-file-container">
                  <div class="upload-actions">
                    <input
                      type="file"
                      id="files"
                      class="hidden"
                      (change)="filesSelectImage($event)"
                    />
                    <label for="files" class="btn btn-outline-primary" translate
                      >UPLOAD_IMAGE</label
                    >
                  </div>
                  <div *ngIf="uploadStart">
                    <span translate>IMAGE_IS_UPLOADING</span>
                  </div>
                  <div *ngIf="clientLogo != null">
                    <img src="{{ clientLogo }}" alt="" width="200px" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!--client ends-->
        <!--client contacts-->
        <div class="col-sm-6">
          <div *ngIf="addclientContact">
            <div class="page-title">
              <span translate>CLIENT_CONTACTS</span>
            </div>
            <form [formGroup]="clientContactsForm">
              <div
                class="form-group"
                *ngIf="clientContactsForm.controls['ContactName']"
              >
                <label for="Name">
                  <span translate>NAME</span>
                  <span class="required">*</span>
                </label>
                <div>
                  <input type="text" pInputText formControlName="ContactName" />
                </div>
                <control-messages
                  [control]="clientContactsForm.controls['ContactName']"
                ></control-messages>
              </div>
              <div class="form-group">
                <label for="Mobile">
                  <span translate>MOBILE</span>
                </label>
                <div class="phone-field">
                  <p-dropdown
                    [options]="mobile"
                    [formControl]="clientContactsForm.controls['Phonecode']"
                    [filter]="true"
                    [style]="{ width: '110px' }"
                  >
                  </p-dropdown>
                  <input
                    type="number"
                    pInputText
                    formControlName="ContactMobile"
                    (keypress)="onMobileNumber($event, 20)"
                    onpaste="return false;"
                  />
                </div>
              </div>
              <div
                class="form-group"
                *ngIf="clientContactsForm.controls['ContactEmailId']"
              >
                <label for="ContactEmailId">
                  <span translate>EMAIL</span>
                  <span class="required">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    pInputText
                    formControlName="ContactEmailId"
                  />
                </div>
                <control-messages
                  [control]="clientContactsForm.controls['ContactEmailId']"
                >
                </control-messages>
              </div>
              <div class="form-group">
                <label for="Notification">
                  <span translate>NOTIFICATION</span>
                </label>
                <div>
                  <div class="mb-2">
                    <p-checkbox
                      label="SMS"
                      formControlName="IsSms"
                      [binary]="true"
                    ></p-checkbox>
                  </div>
                  <div class="mb-2">
                    <p-checkbox
                      label="Email"
                      formControlName="IsEmail"
                      [binary]="true"
                    ></p-checkbox>
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <p-checkbox
                  [(ngModel)]="checkedContact"
                  label="{{ 'APPROVAL_NOTICE' | translate }}"
                  [disabled]="true"
                  formControlName="IsApprovalNotice"
                  [binary]="true"
                >
                </p-checkbox>
              </div>
              <label *ngIf="approvedDateContact">
                <span translate>READ_APPLICATION</span>
                {{ approvedDateContact | date : "dd/MM/yyyy, h:mm a" }}</label
              >
              <div class="upload-file-container">
                <div class="form-group">
                  <div class="upload-file-container">
                    <div class="upload-actions">
                      <input
                        type="file"
                        id="files"
                        class="hidden"
                        (change)="filesSelectContact($event)"
                      />
                      <label
                        for="files"
                        class="btn btn-outline-primary"
                        translate
                        >UPLOAD_IMAGE</label
                      >
                    </div>
                    <div *ngIf="uploadStartContact">
                      <span translate>IMAGE_IS_UPLOADING</span>
                    </div>
                    <div *ngIf="clientImageContact != null">
                      <img
                        src="{{ clientImageContact }}"
                        alt=""
                        width="200px"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="action-btns-wrapper" *ngIf="isEdit">
                <button
                  class="btn btn-primary"
                  type="submit"
                  (click)="saveClientContact()"
                  [disabled]="!clientContactsForm.valid"
                >
                  <span translate>SAVE</span>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  *ngIf="!userCreation && canViewUser"
                  (click)="saveUserClient()"
                  [disabled]="!clientContactsForm.valid"
                >
                  <span translate>SAVE_ADD_USER</span>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  *ngIf="userCreation && canViewUser"
                  (click)="saveUserClient()"
                >
                  <span translate>EDIT_USER</span>
                </button>
              </div>
              <div class="table-view">
                <!-- Table starts -->
                <p-table
                  [value]="clientContact"
                  [rows]="10"
                  [paginator]="true"
                  [pageLinks]="3"
                  [rowsPerPageOptions]="[5, 10, 20]"
                >
                  <!-- Header -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="Name">{{ "NAME" | translate }}</th>
                      <th pSortableColumn="Mobile">
                        {{ "MOBILE" | translate }}
                      </th>
                      <th pSortableColumn="Email">{{ "EMAIL" | translate }}</th>
                      <th pSortableColumn="IsUser">{{ "USER" | translate }}</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <!-- Body Content -->
                  <ng-template pTemplate="body" let-clientcontact>
                    <tr>
                      <td>
                        <span>
                          <a
                            (click)="editClientContact(clientcontact)"
                            href="javascript:void(0);"
                            routerLinkActive="active"
                            >{{ clientcontact.Name }}</a
                          >
                        </span>
                      </td>
                      <td>
                        <span>{{ clientcontact.Mobile }}</span>
                      </td>
                      <td>
                        <span>{{ clientcontact.Email }}</span>
                      </td>
                      <td>
                        <span>{{
                          (clientcontact.IsUser ? "Yes" : "No") | translate
                        }}</span>
                      </td>
                      <td class="col-button">
                        <button
                          type="button"
                          class="btn btn-icon"
                          title="{{ 'DELETE' | translate }}"
                          (click)="deleteConfirm(clientcontact.Id)"
                        >
                          <i class="icon ic-sm icon-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <!-- Table ends -->
              </div>
              <div class="page-title" *ngIf="moduleList ? true : false">
                <span translate>MODULE_LIST</span>
              </div>
              <div class="table-view" *ngIf="moduleList ? true : false">
                <!-- Table starts -->
                <p-table
                  [value]="moduleList"
                  [rows]="10"
                  [paginator]="true"
                  [pageLinks]="3"
                  [rowsPerPageOptions]="[5, 10, 20]"
                >
                  <!-- Header -->
                  <ng-template pTemplate="header">
                    <tr>
                      <th>{{ "NUMBER" | translate }}</th>
                      <th>{{ "NAME" | translate }}</th>
                      <th>{{ "MODULES" | translate }}</th>
                      <th>{{ "STATUS" | translate }}</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <!-- Body Content -->
                  <ng-template pTemplate="body" let-clientcontact>
                    <tr>
                      <td>
                        <span>{{ clientcontact.Number }}</span>
                      </td>
                      <td>
                        <span>
                          <a
                            (click)="
                              setCurrentModule(
                                clientcontact.Id,
                                clientcontact.Modules,
                                clientcontact
                              )
                            "
                            href="javascript:void(0);"
                            pTooltip="{{ clientcontact.Name }}"
                            tooltipPosition="right"
                            >{{ clientcontact.Name }}</a
                          >
                        </span>
                      </td>
                      <td>
                        <span>{{ clientcontact.Modules | translate }}</span>
                      </td>
                      <td>
                        <span>{{ clientcontact.Status | translate }}</span>
                      </td>
                      <td class="col-button" *ngIf="IsUserRole">
                        <div [ngSwitch]="clientcontact.Modules">
                          <div *ngSwitchCase="'KundeEL'">
                            <a
                              [routerLink]="[
                                './../../../kunde_EL/edit',
                                clientcontact['Id']
                              ]"
                              class="btn btn-icon"
                            >
                              <i class="icon ic-sm icon-pencil-tip"></i>
                            </a>
                          </div>
                          <div *ngSwitchCase="'Electro'">
                            <a
                              [routerLink]="[
                                './../../../electro/edit/' +
                                  clientcontact['Id'] +
                                  '/info'
                              ]"
                              class="btn btn-icon"
                            >
                              <i class="icon ic-sm icon-pencil-tip"></i>
                            </a>
                          </div>
                          <div *ngSwitchDefault>
                            <a
                              [routerLink]="[
                                './../../../project/edit',
                                clientcontact['Id']
                              ]"
                              class="btn btn-icon"
                            >
                              <i class="icon ic-sm icon-pencil-tip"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <!-- Table ends -->
              </div>
            </form>
          </div>
          <!--client contacts ends-->
        </div>
      </div>
      <div class="action-btns-wrapper" *ngIf="isEdit">
        <a class="btn btn-outline-secondary" (click)="gotoList()">
          <span class="icon ic-xs icon-back"></span>
          <span translate>BACK_LIST</span>
        </a>
        <button
          class="btn btn-primary"
          type="button"
          (click)="saveClient()"
          [disabled]="!clientForm.valid"
        >
          <span translate>SAVE</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="ClientActive">
  <div class="row" style="padding: 1%; border-bottom: 1px solid">
    <div class="col-sm-8">
      <h3>
        <span translate>OUR_CLIENT_PAGE</span><span> &nbsp; </span>
        <span>{{ objBusineesDetails.Name }}</span>
      </h3>
    </div>
    <div class="col-sm-4">
      <div *ngIf="objClient.ClientimageURL != null">
        <img src="{{ objClient.ClientimageURL }}" alt="" width="200px" />
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 3%">
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>BUSINESS_NAME</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objBusineesDetails.Name }}
      </div>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>PRODUCTION_CLIENT_NAME</span
          ><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objClient.Name }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>BUSINESS_ADDRESS</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objBusineesDetails.Address }}
      </div>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>CLIENT_ADDRESS</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objClient.Address }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>CITY_ZIP</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objBusineesDetails.Zip }}
      </div>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>CITY_ZIP</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objClient.Zip }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>PHONE</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objBusineesDetails.Phone }}
      </div>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>PHONE</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objClient.Telephone }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>E-MAIL</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objBusineesDetails.Email }}
      </div>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-6">
        <label for="Username">
          <span translate>E-MAIL</span><span>&nbsp;&nbsp;:</span>
        </label>
      </div>
      <div class="col-sm-6">
        {{ objClient.Email }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <h4 translate style="margin-top: 5%">ASSIGNED_ACCESS</h4>
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          [value]="moduleList"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "NUMBER" | translate }}</th>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "MODULES" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
              <th *ngIf="IsUserRole"></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-client>
            <tr>
              <td>
                <span>{{ client.Number }}</span>
              </td>
              <td>
                <a
                  (click)="setCurrentModule(client.Id, client.Modules, client)"
                  href="javascript:void(0);"
                  pTooltip="{{ client.Name }}"
                  tooltipPosition="right"
                  >{{ client.Name }}</a
                >
              </td>
              <td>
                <span>{{ client.Modules | translate }}</span>
              </td>
              <td>
                <span>{{ client.Status | translate }}</span>
              </td>
              <td class="col-button" *ngIf="IsUserRole">
                <div [ngSwitch]="client.Modules">
                  <div *ngSwitchCase="'KundeEL'">
                    <a
                      [routerLink]="['./../../../kunde_EL/edit', client['Id']]"
                      class="btn btn-icon"
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </a>
                  </div>
                  <div *ngSwitchCase="'Electro'">
                    <a
                      [routerLink]="[
                        './../../../electro/edit/' + client['Id'] + '/info'
                      ]"
                      class="btn btn-icon"
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </a>
                  </div>
                  <div *ngSwitchDefault>
                    <a
                      [routerLink]="['./../../../project/edit', client['Id']]"
                      class="btn btn-icon"
                    >
                      <i class="icon ic-sm icon-pencil-tip"></i>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" *ngIf="IsUserRole" let-columns>
            <tr>
              <td colspan="5">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="4">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
    <div class="col-sm-6">
      <h4 translate style="margin-top: 5%">OUR_USERS</h4>
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          [value]="clientContact"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [responsive]="true"
        >
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="Name">{{ "NAME" | translate }}</th>
              <th pSortableColumn="Mobile">{{ "MOBILE" | translate }}</th>
              <th pSortableColumn="Email">{{ "EMAIL" | translate }}</th>
              <th pSortableColumn="IsUser">{{ "USER" | translate }}</th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-clientcontact>
            <tr>
              <td>
                <div
                  pTooltip="{{ clientcontact.Name }}"
                  tooltipPosition="bottom"
                >
                  {{ clientcontact.Name }}
                </div>
              </td>
              <td>
                <div
                  pTooltip="{{ clientcontact.Mobile }}"
                  tooltipPosition="bottom"
                >
                  {{ clientcontact.Mobile }}
                </div>
              </td>
              <td>
                <div
                  pTooltip="{{ clientcontact.Email }}"
                  tooltipPosition="bottom"
                >
                  {{ clientcontact.Email }}
                </div>
              </td>
              <td>
                <span>{{
                  (clientcontact.IsUser ? "Yes" : "No") | translate
                }}</span>
              </td>
              <td class="col-button">
                <button
                  type="button"
                  class="btn btn-icon"
                  title="{{ 'DELETE' | translate }}"
                  (click)="deleteConfirm(clientcontact.Id)"
                >
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="5">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table ends -->
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ 'ADD_EDIT_USER' | translate }}"
  [(visible)]="addCategory"
  [style]="{ width: '800px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="userForm" *ngIf="userForm">
    <div class="row">
      <div class="col-sm-8">
        <div class="form">
          <div class="form-group">
            <input type="text" formControlName="Id" hidden="true" />
            <label for="Name">
              <span translate>NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Name"
                id="Name"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="userForm.controls['Name']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="userForm.controls['Username']">
            <label for="Username">
              <span translate>USERNAME</span>
              <span class="required">*</span>
            </label>
            <div class="input-btn-group">
              <input
                type="text"
                pInputText
                style="width: 340px"
                maxlength="100"
                formControlName="Username"
                id="Username"
                required
              />
              <button class="btn btn-primary-outline" type="button">
                <i
                  class="fa fa-refresh"
                  (click)="refreshUsername()"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            <control-messages
              [control]="userForm.controls['Username']"
            ></control-messages>
            <div>
              <a
                routerLinkActive="active"
                class="btn btn-link"
                (click)="clearUserName()"
                >Other names</a
              >
            </div>
          </div>
          <div class="form-group">
            <label for="RoleId">
              <span translate>ADDITIONAL_ROLE</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-dropdown
                formControlName="RoleId"
                id="RoleId"
                [options]="role"
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>
            <control-messages
              [control]="userForm.controls['RoleId']"
            ></control-messages>
          </div>
          <div class="form-group">
            <label for="Mobile">
              <span translate>MOBILE</span>
            </label>
            <div class="phone-field">
              <p-dropdown
                [options]="mobile"
                [formControl]="userForm.controls['Phonecode']"
                [style]="{ width: '120px' }"
              >
              </p-dropdown>
              <input
                style="width: 240px"
                type="number"
                pInputText
                formControlName="Mobile"
                id="mobilenum"
                (keypress)="onNumberChange($event, 20)"
                (input)="MobileEvent($event)"
              />
              <button class="btn btn-link" type="button" (click)="showDialog()">
                <span class="icon ic-sm icon-043"></span>
              </button>
            </div>
          </div>
          <div class="form-group">
            <label for="Email">
              <span translate>EMAIL</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                pInputText
                formControlName="Email"
                id="Email"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="userForm.controls['Email']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="isNewUser">
            <label for="Password">
              <span translate>PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="Password"
                id="Password"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="userForm.controls['Password']"
            ></control-messages>
          </div>
          <div class="form-group" *ngIf="isNewUser">
            <label for="Password">
              <span translate>REPEAT_PASSWORD</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="password"
                pInputText
                formControlName="RepeatPassword"
                id="RepeatPassword"
                class="ex-full-width"
              />
            </div>
            <control-messages
              [control]="userForm.controls['RepeatPassword']"
            ></control-messages>
          </div>
          <div *ngIf="hidden">
            <label translate
              >YOUR_RANDOM_PASSWORD
              <span style="color: green"> {{ showPassword }}</span>
            </label>
          </div>

          <div class="action-btns-wrapper">
            <div class="row">
              <div class="col-sm-6">
                <button
                  class="btn btn-outline-primary"
                  *ngIf="isNewUser"
                  type="button"
                  (click)="generateRandomPassword()"
                >
                  <span translate>RANDOM_PASSWORD</span>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="saveUser()"
                  [disabled]="!userForm.valid"
                >
                  <span translate>SAVE</span>
                </button>
              </div>
              <div class="col-sm-6">
                <button
                  class="btn btn-primary"
                  type="button"
                  *ngIf="isNewUser"
                  (click)="saveAndSendSMS()"
                  [disabled]="!userForm.valid || isMobile"
                >
                  <span translate>SAVE_AND_SENDSMS</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="categories">
        <div>
          <span class="page-title title-equal-space" translate
            >USER_RIGHTS</span
          >
          <div
            class="mb-2"
            *ngFor="
              let def of userForm.controls['categories']['controls'];
              let i = index
            "
          >
            <label *ngIf="userRightsData[i].rightsType === 1">
              <span formGroupName="{{ i }}">
                <input
                  type="checkbox"
                  formControlName="{{ userRightsData[i].id }}"
                  (change)="
                    onRightsCheckboxChange($event, userRightsData[i].id)
                  "
                  pTooltip="{{ userRightsData[i].name | translate }}"
                  tooltipPosition="right"
                />
                {{ userRightsData[i].name | translate }}
              </span>
            </label>
          </div>
        </div>
        <div *ngIf="isElectroExists">
          <span class="page-title title-equal-space" translate>{{
            electroRightsHead
          }}</span>
          <div
            class="mb-2"
            *ngFor="
              let def of userForm.controls['categories']['controls'];
              let i = index
            "
          >
            <label *ngIf="userRightsData[i].rightsType === 7">
              <span formGroupName="{{ i }}">
                <input
                  type="checkbox"
                  formControlName="{{ userRightsData[i].id }}"
                  (change)="
                    onRightsCheckboxChange($event, userRightsData[i].id)
                  "
                  pTooltip="{{ userRightsData[i].name | translate }}"
                  tooltipPosition="right"
                />
                {{ userRightsData[i].name | translate }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-dialog
  header="{{ 'RESET_PASSWORD' | translate }}"
  (onHide)="userClear()"
  [(visible)]="showPasswordResetDialog"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <form [formGroup]="updatePasswordForm">
    <div formGroupName="passwordValidation">
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>NEW_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="NewPassword"
            formControlName="Password"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="RepeatPassword">
          <span translate>CONFIRM_PASSWORD</span>
          <span class="required">*</span>
        </label>
        <div>
          <input
            type="password"
            pInputText
            id="ConfirmPassword"
            formControlName="ConfirmPassword"
            required
          />
        </div>
      </div>
      <control-messages
        [control]="updatePasswordForm.controls['passwordValidation']"
      ></control-messages>
    </div>
    <button
      class="btn btn-outline-primary"
      (click)="resetPassWord()"
      [disabled]="!updatePasswordForm.valid"
    >
      <span translate>SAVE</span>
    </button>
    <span style="color: red" *ngIf="passwordValidation"
      >YOUR_PASSWORD_DONOT_MATCH</span
    >
  </form>
</p-dialog>
<p-dialog
  header="{{ 'INFO' | translate }}"
  [(visible)]="dialogDisplay"
  [modal]="true"
  [style]="{ width: '300px' }"
  [resizable]="false"
  [draggable]="false"
>
  <span translate>
    PLEASE_ADD_YOUR_MOBILE_NUMBER_TO_RECEIVE_USERNAME_AND_PASSWORD
  </span>
</p-dialog>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
<toaster-component></toaster-component>
