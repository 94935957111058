<div>
    <div class="main-heading">
        <h1 style="color: #93318E;">FAQ</h1>
    </div>
    <div class="table-view">
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-3">
                    <label for="category">SELECT_CATEGORY</label>
                </div>
                <div class="ui-g-9">
                    <p-dropdown [options]="Faq" [(ngModel)]="selectedCategory"
                      (onChange)="onFaqChanged($event)"></p-dropdown>
                </div>
            </div>
            <div class="ui-g-12">
                <h3 style="color: #93318E;">{{selectedCategory ? selectedCategory.name : 'none'}}</h3>
                <p>{{selectedCategory ? selectedCategory.details : 'none'}}</p>
            </div>
            <div class="ui-g-12">
                <vg-player>
                    <vg-overlay-play></vg-overlay-play>
                    <vg-buffering></vg-buffering>

                    <vg-scrub-bar>
                        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                    </vg-scrub-bar>

                    <vg-controls>
                        <vg-play-pause></vg-play-pause>
                        <vg-playback-button></vg-playback-button>

                        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

                        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

                        <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
                        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

                        <vg-track-selector></vg-track-selector>
                        <vg-mute></vg-mute>
                        <vg-volume></vg-volume>

                        <vg-fullscreen></vg-fullscreen>
                    </vg-controls>
                    <video #myMedia [vgMedia]="myMedia" id="my-video" [src]="selectedUrl" type="video/webm">
                    </video>
                </vg-player>
            </div>
        </div>
    </div>
</div>