import { SelectItem } from 'primeng/api';
import { IMyDpOptions } from 'mydatepicker';
import { HelperService } from 'src/app/_services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TimeLogService } from 'src/app/kuba/log/services/log.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'log-list.component.html'
})
export class LogListComponent implements OnInit {
    @ViewChild('timeLogTable',{static: false}) timeLogTable: Table;
    timeLog: any;
    statuses: SelectItem[];
    filterData: any;
    private subscriptions: Subscription[] = [];

    /**
* Date picker configuration option
*/
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px'
    };
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    constructor(
        public timeLogService: TimeLogService,
        private route: ActivatedRoute,
    ) {
       
    }

    /**
     * page load event
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.statuses = [];
        this.statuses.push({ label: 'Select', value: null });
        this.statuses.push({ label: 'IN', value: 'IN' });
        this.statuses.push({ label: 'On Break', value: 'OB' });
        this.statuses.push({ label: 'Temporary out', value: 'OT' });
        this.timeLog = this.route.snapshot.data['list'];
        if (this.timeLog) {
            this.filterData = this.timeLog;
            this.getTime(this.timeLog);
        }
    }
    /**
    * to filter status in list
    * @param e
    * @param statusDropdown
    */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            let data = this.filterData.filter(x => x.Mode === e.value);
            this.timeLog = data;
        } else {
            this.getCrewList();
        }
    }
    getTime(data) {
        data.forEach((element, index) => {
            if (element.Date) {
                let Time = HelperService.formatTime(new Date(element.Date));
                this.timeLog[index].Time = Time;
            }
        });
    }
    getCrewList() {
        this.subscriptions.push(this.timeLogService.getAll().subscribe(result => {
            if (result) {
                this.timeLog = result;
            }
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}



