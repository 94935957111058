import { UserRole } from './../../follow-ups/models/deviation';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { IMyDpOptions, IMyDateModel, IMyDate, IMyOptions } from 'mydatepicker';
import * as _ from 'lodash';

import { EmployeeRights } from './../models/employee';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Options } from './../../../_models/options';
import { Rights } from './../../../_models/feature';
import { HelperService } from './../../../_services/helper.service';
import { EmployeeLeaveService } from '../services/employeeleave.service';
import { EmployeeLeave, UserRights } from '../models/index';
import { ValidationService } from '../../shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { DropdownSelectTranslate } from './../../../_helpers/pipes';
import { element } from 'protractor';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { Subscription } from 'rxjs';
@Component({
    moduleId: module.id,
    selector: 'leave-edit',
    templateUrl: 'employee-leave-edit.component.html'
})
export class LeaveEditComponent implements OnInit {
    isUser: boolean = false;
    //#region  variables

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    empId: number;
    isHiddenEndDate = true;
    isGridHidden = false;
    Year: any = [];
    approvalPersons: SelectItem[];
    leaveForm: FormGroup;
    isMypage = true;
    isVisible = false;
    isApprovalPerson = false;
    parentKey: string;
    leaveId: number;
    isHidden = false;
    employeeLeave: EmployeeLeave[];
    leave = new EmployeeLeave();
    IsShowWeekend = false;
    hasChildren = false;
    locale = 'en';
    isApproved = false;
    leaveAlerttext: string;
    isLeaveAvailable: boolean;
    leaveApprovalPerson: number;
    statusChangeByUser = false;
    leaveSaveByUser = false;
    loading = false;
    leaveAvailableId: number;
    isLeaveExist = false;
    additionalData: any;
    isNotUser: boolean;
    totalDates: any = [];
    formattedTotalDates = [];
    isNoAvailableLeave: boolean;
    editOwnStatus: boolean;
    isLeaveGreaterThanNoofDays: boolean;
    isFromDateGreaterThanEndDate: boolean;
    editStatus: boolean;
    selectedYear: any;
    startDate: any;
    endDate: any;
    totalDays: number;
    map = new Map<any, any>();
    workingModule: any;
    isApprovalPerson1 = true;
    LeaveList: any = [];
    ApprovalPerson: any;
    leaveStatus: boolean;
    options = [new Options(8, 'OPEN'), new Options(6, 'APPROVED'), new Options(10, 'REJECTED')];
    private subscriptions: Subscription[] = [];
    /**
    * Date picker configuration option
    */
    public startDateOptions: IMyOptions = {
        dateFormat: 'dd/mm/yyyy',
        disableWeekends: !this.IsShowWeekend,
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '26px',
        selectionTxtFontSize: '14px',
        disableSince: this.getStartDate(),
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableUntil: this.getEndDate(),
        disableDays: this.getHolidayList()
    };
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    userRights: any;
    Approver: any;
    EditLeaveValue: any;
    ApproverRights: boolean;
    isEditor: boolean = true;
    isGuest: boolean;

    //#endregion

    //#region constructor
    /**
     * constructor
     * @param _fb {FormBuilder}
     * @param emplevsrvc {EmployeeLeaveService}
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param location {Location}
     */
    constructor(
        private _fb: FormBuilder,
        public employeeLeaveService: EmployeeLeaveService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private location: Location,
        private translate: TranslateService,
        private businessservice: BusinessServices
    ) {
    }
    //#endregion

    //#region page-events

    /**
  * getting the employee id,setting form group,getting employee data by passing employee id,
  * binding approval person dropdown
  */
    // TODO: to be implemented leave approval function based on thet form to be disabled
    ngOnInit() {
        this.workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
        document.querySelector("body").classList.remove("opened");
        this.isNotUser = +BaseServices.roleId === UserRole.USER ? false : true;
        this.isUser = +BaseServices.roleId === UserRole.USER ? true : false;
        this.isEditor = +BaseServices.roleId === UserRole.EDITOR ? true : false;
        this.isGuest = +BaseServices.roleId === UserRole.GUEST ? true : false;
        if (this.isUser == true || this.isEditor == true) {
          let userRights = BaseServices.getUserRights();
          userRights = JSON.parse(userRights);
          this.Approver = userRights.filter((x) => x.name == 'APPROVE_LEAVE');
          if (this.Approver[0].isChecked === true) {
            this.ApproverRights = true;
          } else {
            this.ApproverRights = false;
          }
        }
        if(this.isGuest == true){
            this.ApproverRights = false;
        }
        this.Year = [];
        let year = new Date();
        // binding the year dropdown
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Year = [];
            this.Year.push(
                { label: val.SELECT, value: null }
            );
            this.Year.push({ label: year.getFullYear() - 1, value: year.getFullYear() - 1 });
            this.Year.push({ label: year.getFullYear(), value: year.getFullYear() });
            this.Year.push({ label: year.getFullYear() + 1, value: year.getFullYear() + 1 });
        }));
        let eid = this.route.snapshot.params['eid'];
        if (eid) {
            this.empId = +eid;
        }
        this.leaveForm = this._fb.group({
            IsShowWeekend: [''],
            NoOfDays: [''],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            ApprovalPerson: [],
            ApprovalPersonList: ['', Validators.required],
            Description: [''],
            LeaveTaken: ['0'],
            LeaveToBeAvailed: [''],
            Year: [''],
            AvailableLeave: ['', [Validators.required, ValidationService.nonZero]],
            Status: ['8']
        });
        let userRights = BaseServices.getUserRights();
        userRights = JSON.parse(userRights);
        if (BaseServices.UserRole == 'Editor') {
            this.ApproverRights = true;
        }
        if (userRights) {
            _.find(userRights, (x: EmployeeRights) => {
                if (x.id === 11 && x.isChecked === true) {
                    this.isApprovalPerson = false;
                } else {
                    this.isApprovalPerson = false;
                }
                return userRights;
            });
        }
        let leave = this.route.snapshot.data['edit'];
        if (leave) {
            this.employeeLeave = leave;
        }
        let key = this.route.snapshot.data['parent'];
        if (key) {
            this.parentKey = key
        }
        this.binddetails();
        if (this.parentKey === 'LEAVE') {
            this.isHidden = true;
            this.isGridHidden = true;
            this.isMypage = true;
            this.isVisible = false;
            this.isLeaveExist = false;
            let data = this.route.snapshot.data['leaveEdit'];
            if (data) {
                let currentUserRole = BaseServices.UserRole;
                let userRightsId = Rights.APPROVE_LEAVE;
                let rights = BaseServices.checkUserRights(
                    userRightsId,
                    currentUserRole
                );
                if (rights === true) {
                    this.isApprovalPerson1 = false
                }
                this.leaveAvailableId = data.LeaveAvailableId;
                if (data.Status === 2) {
                    this.isApproved = true;
                }
                this.initForm(data, null);
            }
        } else if (this.parentKey === 'EMPLOYEE') {
            this.isHidden = false;
            this.isVisible = true;
            this.isGridHidden = false;
            this.isMypage = true;
            //  this.leaveAvailabilityCheck();no need to calculate leave availability at the page load
        } else if (this.parentKey === 'MYPAGE') { // my page routing not coming from anywhere, non usable code
            this.isHidden = true;
            this.isVisible = true;
            this.isMypage = false;
            this.isGridHidden = false;
        }
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            this.additionalData = {
                fileName: val.LEAVE_LIST,
                header: 'Leave List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Employee Name', dataKey: 'EmployeeName' },
                    { title: 'Start Date', dataKey: 'StartDate' },
                    { title: 'End Date', dataKey: 'EndDate' },
                    { title: 'No Of Days', dataKey: 'NoOfDays' },
                    { title: 'Status', dataKey: 'StatusText' },
                    { title: 'Approver', dataKey: 'ApprovalPersonName' },
                    { title: 'Description', dataKey: 'Description' }
                ]
            };
        }));

    }
    /**
    * init form
    * @param data {any}
    */
    initForm(data: any, approvalList: any) {
        let id = this.route.snapshot.params['lid'];
        if (id) {
            this.leaveId = id;
        }
        let startDate;
        let endDate;
        let leaveTaken: 0;
        if (data) {
            if (!data.ApprovalPersonList && approvalList) {
                data.ApprovalPersonList = approvalList['ApprovalPersonList'];
            }
            this.isLeaveExist = true;
            this.empId = data.EmployeeId;
            this.leaveApprovalPerson = (data.ApprovalPerson) ? data.ApprovalPerson : 0;
            this.bindControls(data);
            this.isHiddenEndDate = false;
            startDate = HelperService.formatInputDate(data.StartDate ? data.StartDate : null);
            if (data.EmployeeId === BaseServices.FeatureKey) {
                this.startDateOptions.disableSince = this.getStartDate();
                this.startDateOptions.disableUntil = this.getEndDate();
            }
            // End Date Input and Date Range set
            endDate = HelperService.formatInputDate(data.EndDate ? data.EndDate : null);
            this.endDateOptions.disableUntil = <IMyDate>{ year: startDate.date.year, month: startDate.date.month, day: startDate.date.day };
            this.endDateOptions.disableSince = <IMyDate>{ year: startDate.date.year + 1, month: 1, day: 1 };
            leaveTaken = (data.LeaveTaken) ? data.LeaveTaken : 0
            let leaveDetails;
            this.IsShowWeekend = data.IsShowWeekend === true ? true : false;
            this.changeSettings();
            leaveDetails = {
                startDate: startDate ? startDate : null,
                endDate: endDate ? endDate : null,
                LeaveToBeAvailed: data.LeaveAvailable,
                LeaveTaken: leaveTaken,
                ApprovalPerson: data.ApprovalPerson,
                ApprovalPersonList: data.ApprovalPersonList,
                Description: data.Description,
                NoOfDays: data.NoOfDays,
                Year: data.Year,
                IsShowWeekend: data.IsShowWeekend,
                AvailableLeave: '',
                Status: data.Status
            };
            (<FormGroup>this.leaveForm)
                .setValue(leaveDetails, { onlySelf: true });
            if (this.empId > 0) {
                let availableLeave = data.LeaveAvailable - data.LeaveTaken;
                this.leaveForm.get('LeaveToBeAvailed')!.patchValue(data.LeaveAvailable);
                this.leaveForm.get('AvailableLeave')!.patchValue(availableLeave);
                this.leaveForm.get('LeaveTaken')!.patchValue(leaveTaken);
                this.leaveForm.get('LeaveTaken')!.patchValue(leaveTaken);
                this.leaveForm.get('Year')!.patchValue(endDate.date.year);
            }
        };
    }

    /**
    * give rights to save
    * @param data {any}
    */
    bindControls(data: any) {
        if (data.ApprovalPerson === BaseServices.UserId || parseInt(BaseServices.roleId) == 3) {
            this.editOwnStatus = true;
            this.isApprovalPerson = false;
            this.leaveSaveByUser = true;
            if (data.EmployeeId !== data.ApprovalPerson) {
                if (this.parentKey === 'EMPLOYEE') {
                    this.editOwnStatus = true;
                    this.isApprovalPerson = false;
                    this.leaveSaveByUser = true;
                } else {
                    this.isApprovalPerson = true;
                    this.statusChangeByUser = true;
                    this.leaveSaveByUser = true;
                    this.editOwnStatus = false;
                    this.editStatus = true;
                }
            }
        } else if (data.EmployeeId === BaseServices.FeatureKey) {
            this.isApprovalPerson = false;
            this.editOwnStatus = false;
            this.statusChangeByUser = false;
            this.leaveSaveByUser = true;
        } else {
            this.isApprovalPerson = true;
            this.statusChangeByUser = true;
            this.leaveSaveByUser = false;
        }

        let userRightsId = Rights.APPROVE_LEAVE;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            let checkUserRights = BaseServices.checkUserRights(
                userRightsId,
                role
            );
            if (checkUserRights) {
                this.isApprovalPerson = false;
                this.leaveSaveByUser = true;//KW-1058:Enable save button for User with approval rights
            }
            if (this.workingModule.id == 8) {
                if (data.ApprovalPersonList.some((e: any) => e === BaseServices.UserId)) {
                    this.leaveSaveByUser = true;
                }
                else {
                    this.leaveSaveByUser = false;
                }
            }
        }
        if (role === 'Editor') {
            let checkUserRights = BaseServices.checkUserRights(
                userRightsId,
                role
            );
            if (checkUserRights) {
                this.isApprovalPerson = false;
                this.leaveSaveByUser = true;//KW-1058:Enable save button for Editor with approval rights
            }
        }
    }

    //#endregion

    //#region control-events

    /**
    * Save and update employee leave
    */
    onSubmit(mode: string) {
        this.loading = true;
        this.leave.Id = (this.leaveId) ? this.leaveId : 0;
        this.leaveId = 0;
        this.leave.EmployeeId = this.empId;
        this.leave.StartDate = this.leaveForm.value.startDate ?
            HelperService.formatDateFilter(this.leaveForm.value.startDate.formatted) : null!;
        this.leave.EndDate = this.leaveForm.value.endDate ?
            HelperService.formatDateFilter(this.leaveForm.value.endDate.formatted) : null!;
        this.leave.LeaveAvailable = this.leaveForm.value.LeaveToBeAvailed;
        this.leave.LeaveTaken = this.leaveForm.value.LeaveTaken;
        this.setNoOfDays(new Date(this.leave.StartDate), new Date(this.leave.EndDate));
        this.leave.NoOfDays = this.totalDays;
        this.leave.Year = this.leaveForm.value.Year;
        this.leave.ApprovalPerson = (this.isVisible == false) ? BaseServices.UserId : this.leaveForm.value.ApprovalPerson;
        this.leave.ApprovalPersonList = this.leaveForm.value.ApprovalPersonList;
        this.leave.Description = this.leaveForm.value.Description;
        this.leave.IsShowWeekend = this.IsShowWeekend === true ? 'true' : 'false'; // this.leaveForm.value.IsShowWeekend;
        this.IsShowWeekend = false;
        this.leave.LanguageId = BaseServices.userLanguageId;
        this.leave.BusinessId = BaseServices.BusinessId;
        this.leave.LeaveAvailableId = this.leaveAvailableId;
        this.leave.UserId=BaseServices.UserId;
        if (this.leave.Id > 0) {
            this.leave.Status = this.leaveForm.value.Status;
            this.subscriptions.push(this.employeeLeaveService.update(this.leave).subscribe((result) => {
                if (result) {
                    this.getEmployee(this.empId);
                    this.toasterComponent.callToast();
                    this.loading = false;
                    if (mode === 'SAVE') {
                        this.onClear()
                    } else {
                        this.gotoList()
                    }
                }
            }));
        } else {
            this.leave.Status = '8';
            this.subscriptions.push(this.employeeLeaveService.add(this.leave).subscribe((result) => {
                if (result) {
                    this.getEmployee(this.empId);
                    this.toasterComponent.callToast();
                    if (mode === 'SAVE') {
                        this.onClear()
                    } else {
                        this.gotoList()
                    }
                }
            }));
        }
    }
    /**
     * edit employee leave
     * @param Id {number}
     */
    editLeave(data: any) {
        let Id = data['Id'];
        this.isApprovalPerson = true;
        this.subscriptions.push(this.employeeLeaveService.getEmployeeLeaveByLeaveId(Id).subscribe((leave: any) => {
            if (leave) {
                this.EditLeaveValue = leave;
                this.leaveForm.reset();
                this.initForm(leave, data);

            }
            this.leaveId = leave.Id;
        }));
    }
    /**
     * Method used to show weekend in calender and changes in calculation
     */
    changeSettings() {
        // Disable/enable weekends for Start Date
        this.startDateOptions.disableSince = this.getStartDate();
        this.startDateOptions.disableUntil = this.getEndDate();
        let startCopy = HelperService.getCopyOfDatePickerOptions(this.startDateOptions);
        startCopy.disableWeekends = !this.IsShowWeekend;
        this.startDateOptions = startCopy;
        // Disable/enable weekends for End Date
        let endCopy = HelperService.getCopyOfDatePickerOptions(this.endDateOptions);
        endCopy.disableWeekends = !this.IsShowWeekend;
        this.endDateOptions = endCopy;

        this.calculationDays();
    }
    /**
     * delete employee leave
     * @param id {number}
     */
    delete(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeLeaveService.deleteLeave(id).subscribe(deleteResponse => {
                    if (deleteResponse) {
                        this.getEmployee(this.empId);
                        this.toasterComponent.callToastDlt();
                    }
                }));
            }
        });
    }
    /**
     * Clearing the data in form
     */
    onClear() {
        this.isApprovalPerson = false;
        this.leaveForm.reset();
        this.isHiddenEndDate = true;
        this.IsShowWeekend = false;
        this.isFromDateGreaterThanEndDate = false;
        this.isLeaveGreaterThanNoofDays = false;
        this.leaveForm.get('LeaveToBeAvailed')!.patchValue('');
        this.leaveForm.get('AvailableLeave')!.patchValue('');
    }
    /**
     * Bact to list event
     */
    gotoList() {
        this.location.back();
    }
    onYearChange(e: any) {
        this.selectedYear = e.value;
        this.subscriptions.push(this.employeeLeaveService
            .getAvailableLeaves(this.empId, BaseServices.BusinessId, e.value).subscribe((availableLeaves: any) => {
                if (availableLeaves) {
                    let availableLeave = availableLeaves.LeaveAvailable - availableLeaves.TotalLeaveTaken;
                    this.leaveForm.get('LeaveToBeAvailed')!.patchValue(availableLeaves.LeaveAvailable);
                    this.leaveForm.get('AvailableLeave')!.patchValue(availableLeave);
                    this.leaveForm.get('LeaveTaken')!.patchValue(availableLeaves.TotalLeaveTaken);
                } else {
                    this.leaveForm.get('LeaveToBeAvailed')!.patchValue(0);
                    this.leaveForm.get('AvailableLeave')!.patchValue(0);
                    this.leaveForm.get('LeaveTaken')!.patchValue(0);

                }
            }));
        this.leaveForm.get('NoOfDays').patchValue(this.map.get(this.selectedYear));
    }
    /**
  * event handler for End date
  * @param event {any}
  */
    onEndDateChanged(event: IMyDateModel) {
        let endDate: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        let toDate: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.endDate = new Date(endDate);
        if (this.leaveForm.value.startDate) {
            let fromDateValue = this.leaveForm.value.startDate;
            let fromDate: Date = (fromDateValue && fromDateValue.jsdate) ? new Date(fromDateValue.jsdate.getTime()) : null!;
            let startDate: Date = (fromDateValue && fromDateValue.jsdate) ? new Date(fromDateValue.jsdate.getTime()) : null!;
            // formatting the datearray
            if (fromDate && toDate) {
                this.totalDates = this.getDates(fromDate, toDate)
                this.totalDates.forEach((element: any) => {
                    this.formattedTotalDates.push(HelperService.formatDateForFilter(element))
                })
                this.isFromDateGreaterThanEndDate = startDate > toDate ? true : false;
            }
            if (this.leaveId > 0) {
                let startDate = new Date(this.formatDate(fromDateValue.formatted));
                this.totalDates = this.getDates(startDate, toDate)
                this.totalDates.forEach((element: string) => {
                    this.formattedTotalDates.push(HelperService.formatDateForFilter(element))
                })
                // re-asigning again because startdate is changing after getting total dates
                let firstDate = new Date(this.formatDate(fromDateValue.formatted));
                this.isFromDateGreaterThanEndDate = firstDate > endDate ? true : false;
                this.setNoOfDays(firstDate, endDate);
            } else {
                this.setNoOfDays(startDate, endDate);
            }
            if (this.endDate) {
                this.leaveForm.get('Year')!.patchValue(this.endDate.getFullYear());
            }
            let year = endDate ? endDate.getFullYear() : 0
            if (this.empId > 0 && year > 0) {
                this.subscriptions.push(this.employeeLeaveService
                    .getAvailableLeaves(this.empId, BaseServices.BusinessId, year).subscribe((availableLeaves: any) => {
                        if (availableLeaves) {
                            let availableLeave = availableLeaves.LeaveAvailable - availableLeaves.TotalLeaveTaken;
                            this.isNoAvailableLeave = availableLeave <= 0 ? true : false;
                            this.leaveForm.get('LeaveToBeAvailed')!.patchValue(availableLeaves.LeaveAvailable);
                            this.leaveForm.get('AvailableLeave')!.patchValue(availableLeave);
                            this.leaveForm.get('LeaveTaken')!.patchValue(availableLeaves.TotalLeaveTaken);
                        } else {
                            this.isNoAvailableLeave = true;
                            this.leaveForm.get('LeaveToBeAvailed')!.patchValue(0);
                            this.leaveForm.get('AvailableLeave')!.patchValue(0);
                            this.leaveForm.get('LeaveTaken')!.patchValue(0);
                        }
                    }));
            }
            if (this.startDate.getFullYear() != this.endDate.getFullYear()) {
                var tempStart = new Date(this.startDate);
                var tempEnd: any;
                //var DateList: any[] = [];
                this.Year.forEach((element: any) => {
                    if (element.value != null) {
                        this.map.set(element.value, 0);
                    }
                });
                while (tempStart.getFullYear() <= this.endDate.getFullYear()) {

                    tempEnd = new Date(tempStart.getFullYear(), 11, 31);
                    if (tempStart.getFullYear() == this.endDate.getFullYear()) {
                        tempEnd = new Date(this.endDate);
                    }
                    var ts = new Date(tempStart);
                    var te = new Date(tempEnd);
                    this.setNoOfDays(ts, te);
                    this.map.set(tempStart.getFullYear(), this.totalDays);
                    tempStart = new Date(tempStart.getFullYear() + 1, 0, 1);
                }
                this.selectedYear = fromDate.getFullYear();
                this.leaveForm.get('Year')!.patchValue(this.endDate.getFullYear());
                this.leaveForm.get('NoOfDays')!.patchValue(this.map.get(this.selectedYear));
                this.isLeaveGreaterThanNoofDays = false;
                this.Year.forEach((e: any) => {
                    if (e.value == null)
                        return;
                        this.subscriptions.push(this.employeeLeaveService
                        .getAvailableLeaves(this.empId, BaseServices.BusinessId, e.value).subscribe((availableLeaves: any) => {
                            if (availableLeaves) {
                                let availableLeave = availableLeaves.LeaveAvailable - availableLeaves.TotalLeaveTaken;
                                this.isNoAvailableLeave = this.isNoAvailableLeave || (availableLeave <= 0 ? true : false);
                                if (this.map.get(e.value) > availableLeave) {
                                    this.isLeaveGreaterThanNoofDays = (this.isLeaveGreaterThanNoofDays || true);
                                } else {
                                    this.isLeaveGreaterThanNoofDays = (this.isLeaveGreaterThanNoofDays || false);

                                }
                            } else {
                                this.isNoAvailableLeave = (this.isNoAvailableLeave || true);
                            }
                        }));
                });
            }
        }
    }

    formatDate(stringDate: any) {
        let replaceDate = stringDate.replace('/', '-').replace('/', '-');
        let splitDate = replaceDate.split('-');
        let formattedDate: any;
        let date = splitDate[0];
        let month = splitDate[1];
        let year = splitDate[2];
        return formattedDate = month + '/' + date + '/' + year;
    }
    /**
* Event handler for Start date
* @param event {IMyDateModel}
*/
    onStartDateChanged(event: IMyDateModel) {
        this.endDateOptions = this.setDateInput(this.endDateOptions, event, 'startDate');
        let startDate: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        let fromDate: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        this.startDate = new Date(startDate);
        let endDateValue = this.leaveForm.value.endDate;
        let endDate: Date = (endDateValue && endDateValue.jsdate) ? new Date(endDateValue.jsdate.getTime()) : null!;
        let toDate: Date = (endDateValue && endDateValue.jsdate) ? new Date(endDateValue.jsdate.getTime()) : null!;
        // formatting the date array
        if (fromDate && toDate) {
            this.totalDates = this.getDates(fromDate, toDate)
            this.totalDates.forEach((element: any) => {
                this.formattedTotalDates.push(HelperService.formatDateForFilter(element))
            })
            this.isFromDateGreaterThanEndDate = startDate > toDate ? true : false;
        }
        if (this.leaveId > 0) {
            let endDate = new Date(this.formatDate(endDateValue.formatted))
            this.totalDates = this.getDates(fromDate, endDate)
            this.totalDates.forEach((element: any) => {
                this.formattedTotalDates.push(HelperService.formatDateForFilter(element))
            })
            let secondDate = new Date(this.formatDate(endDateValue.formatted))
            this.isFromDateGreaterThanEndDate = startDate > secondDate ? true : false;
            this.setNoOfDays(startDate, endDate);
        } else {
            this.setNoOfDays(startDate, endDate);
        }
        this.leaveForm.patchValue({ EndDate: null }); // clear end date

        if (startDate) {
            this.isHiddenEndDate = false;
        }
        this.bindLeaveDetails(endDate);
        if (this.endDate != null) {
            if (this.startDate.getFullYear() != this.endDate.getFullYear()) {
                var tempStart = new Date(this.startDate);
                var tempEnd: any;
                this.Year.forEach((element: any) => {
                    if (element.value != null) {
                        this.map.set(element.value, 0);
                    }
                });
                while (tempStart.getFullYear() <= this.endDate.getFullYear()) {

                    tempEnd = new Date(tempStart.getFullYear(), 11, 31);
                    if (tempStart.getFullYear() == this.endDate.getFullYear()) {
                        tempEnd = new Date(this.endDate);
                    }
                    var ts = new Date(tempStart);
                    var te = new Date(tempEnd);
                    this.setNoOfDays(ts, te);
                    this.map.set(tempStart.getFullYear(), this.totalDays);
                    tempStart = new Date(tempStart.getFullYear() + 1, 0, 1);
                }
                this.selectedYear = fromDate.getFullYear();
                if (this.endDate) {
                    this.leaveForm.get('Year')!.patchValue(this.endDate.getFullYear());
                }
                this.leaveForm.get('NoOfDays')!.patchValue(this.map.get(this.selectedYear));
                this.isLeaveGreaterThanNoofDays = false;
                this.Year.forEach(e => {
                    this.subscriptions.push(this.employeeLeaveService
                        .getAvailableLeaves(this.empId, BaseServices.BusinessId, e.value).subscribe((availableLeaves: any) => {
                            if (availableLeaves) {
                                let availableLeave = availableLeaves.LeaveAvailable - availableLeaves.TotalLeaveTaken;
                                this.isNoAvailableLeave = this.isNoAvailableLeave || (availableLeave <= 0 ? true : false);
                                if (this.map.get(e.value) > availableLeave) {
                                    this.isLeaveGreaterThanNoofDays = (this.isLeaveGreaterThanNoofDays || true);
                                } else {
                                    this.isLeaveGreaterThanNoofDays = (this.isLeaveGreaterThanNoofDays || false);

                                }
                            } else {
                                this.isNoAvailableLeave = this.isNoAvailableLeave || true;
                            }
                        }));
                });
            }
        }
    }

    //#endregion

    //#region methods

    /**
    * get current date
    */
    getStartDate() {
        let startDate = new Date();
        return {
            year: startDate.getFullYear() + 2,
            month: 1,
            day: 1
        }
    }
    getEndDate() {
        let endDate = new Date();
        return {
            year: endDate.getFullYear() - 1,
            month: 1,
            day: 1
        }
    }
    /**
     * getting employee data by passing employee id
     * @param empid {number}
     */
    getEmployee(empId: number) {
        this.subscriptions.push(this.employeeLeaveService.getEmployeeLeavesByEmployeeId(empId).subscribe((employeeLeaves: any) => {
            this.employeeLeave = employeeLeaves;
            // this.LeaveList = this.employeeLeave;
            this.LeaveList.push(this.employeeLeave);
            this.loading = false;
        }));
    }
    /**
     * binding approval person dropdown
     */
    binddetails() {
        this.approvalPersons = [];
        let approvalPerson = this.route.snapshot.data['approvalperson'];
        if (approvalPerson) {
            approvalPerson.forEach((approvalPersons: any) => {
                if (approvalPerson) {
                    let userRights: UserRights[] = (approvalPersons.Rights) ? JSON.parse(approvalPersons.Rights) : null;
                    let hasAccess = HelperService.ApprovalPersonRightsCheck(userRights, Rights.APPROVE_LEAVE);
                    if (hasAccess) {
                        this.approvalPersons.push({ label: approvalPersons.Name, value: approvalPersons.Id });
                    }
                }
            });
        }
    }
    /**
   * Method for setting date range
   * @param dateConfig {IMyDpOptions}
   * @param event{IMyDateModel}
   * @param type  {string}
   */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }
    /**
     * days calculation
     */
    calculationDays() {
        let startDateValue = this.leaveForm.value.startDate;
        let endDateValue = this.leaveForm.value.endDate;
        let startDate: Date = (startDateValue && startDateValue.jsdate) ? new Date(startDateValue.jsdate.getTime()) : null!;
        let endDate: Date = (endDateValue && endDateValue.jsdate) ? new Date(endDateValue.jsdate.getTime()) : null!;
        if (startDate && endDate) {
            this.totalDates = this.getDates(startDate, endDate)
            this.totalDates.forEach((element: any) => {
                this.formattedTotalDates.push(HelperService.formatDateForFilter(element))
            })
        }
        if (this.leaveId > 0 && startDateValue && endDateValue) {
            let startingDate = new Date(this.formatDate(startDateValue.formatted))
            let endingDate = new Date(this.formatDate(endDateValue.formatted))
            if (startingDate && endingDate) {
                this.totalDates = this.getDates(startingDate, endingDate)
                this.totalDates.forEach((element: any) => {
                    this.formattedTotalDates.push(HelperService.formatDateForFilter(element))
                })
            }
            // re-assigning again because dates are changing after formatting dates
            let date1 = new Date(this.formatDate(startDateValue.formatted));
            let date2 = new Date(this.formatDate(endDateValue.formatted))
            this.setNoOfDays(date1, date2);
        } else {
            let firstDate: Date = (startDateValue && startDateValue.jsdate) ? new Date(startDateValue.jsdate.getTime()) : null!;
            let secondDate: Date = (endDateValue && endDateValue.jsdate) ? new Date(endDateValue.jsdate.getTime()) : null!;
            this.setNoOfDays(firstDate, secondDate);
        }
    }

    /**
     * getting date array
     */
    getDates(fromDate: any, toDate: any) {
        let arr = [];
        let dt: any;
        for (arr = [], dt = fromDate; dt <= toDate; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    }

    /**
     * comparing two arrays to get count
     */
    getCount(holidays: any, totalDays: any) {
        let count: any;
        let filteredHolidays = [];
        holidays.forEach((element: any) => {
            count = totalDays.filter((element1: any) => element1 === element);
            if (count.length > 0) {
                if (!this.IsShowWeekend && (new Date(count).getDay() === 0
                    || new Date(count).getDay() === 6)) {
                    return;
                } else {
                    filteredHolidays.push(count);
                }
            }
        });
        return filteredHolidays.length;
    }

    /**
 * calculating no of days
 * @param startDate {any}
 * @param endDate {any}
 */
    setNoOfDays(startDate: any, endDate: any) {
        let fromDate = startDate;
        let toDate = endDate;
        let formattedHolidays: any = [];
        let holidays: any;
        let holidaysCount: any;
        let year = endDate ? endDate.getFullYear() : 0
        let availableLeave: any;
        this.leaveForm.get('NoOfDays')!.patchValue(0); // Default value
        // getting business holidays
        holidays = this.route.snapshot.data['holiday']
        if (holidays) {
            holidays.forEach((element: any) => {
                formattedHolidays.push(HelperService.formatDateForFilter(element.HolidayDate));
            });
        }
        if (startDate && endDate) {
            var ts = new Date(startDate);
            var te = new Date(endDate);

            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            // The number of milliseconds in one day
            let ONE_DAY = 1000 * 60 * 60 * 24;

            // Convert both dates to milliseconds
            let startDate_ms = startDate.getTime();
            let endDate_ms = endDate.getTime();

            // Calculate the difference in milliseconds
            let difference_ms = Math.abs(startDate_ms - endDate_ms);
            let totalDays = Math.round(difference_ms / ONE_DAY) + 1;
            let checkStartDate = startDate;
            let countReduceLeave = 0;
            if (!this.IsShowWeekend) {
                for (let i = 0; i < totalDays; i++) {
                    if (checkStartDate <= endDate) {
                        if (checkStartDate.getDay() === 6 || checkStartDate.getDay() === 0) {
                            countReduceLeave = countReduceLeave + 1;
                        }
                        checkStartDate.setDate(checkStartDate.getDate() + 1);
                    }
                }
            }
            this.formattedTotalDates = [];
            while (ts <= te) {
                this.formattedTotalDates.push(HelperService.formatDateForFilter(ts));
                ts = new Date(new Date(ts).setDate(ts.getDate() + 1));
            }

            if (formattedHolidays && this.formattedTotalDates) {
                holidaysCount = this.getCount(formattedHolidays, this.formattedTotalDates)
            }
            if (holidaysCount > 0) {
                totalDays = totalDays - countReduceLeave;
                totalDays = totalDays - holidaysCount;
            } else {
                totalDays = totalDays - countReduceLeave;
            }
            //}
            if (this.empId > 0 && year > 0) {
                this.subscriptions.push(this.employeeLeaveService.getAvailableLeaves(this.empId, BaseServices.BusinessId, year)
                    .subscribe((availableLeaves: any) => {
                        if (availableLeaves) {
                            availableLeave = availableLeaves.LeaveAvailable - availableLeaves.TotalLeaveTaken;
                        }
                        if (totalDays > availableLeave) {
                            this.isLeaveGreaterThanNoofDays = true;
                        } else {
                            this.isLeaveGreaterThanNoofDays = false;

                        }
                    }));
            }
            this.leaveForm.get('NoOfDays')!.patchValue(totalDays);
            this.totalDays = totalDays;
        }
        // refreshing formattedTotalDates
        this.formattedTotalDates = [];
    }
    /**
    * bind leave details when choosing end date
    * @param endDate {any}
    */
    bindLeaveDetails(endDate: any) {
        let year = endDate ? endDate.getFullYear() : 0
        if (this.empId > 0 && year > 0) {
            this.subscriptions.push(this.employeeLeaveService.getAvailableLeaves(this.empId, BaseServices.BusinessId, year).subscribe((availableLeaves: any) => {
                if (availableLeaves) {
                    let availableLeave = availableLeaves.LeaveAvailable - availableLeaves.TotalLeaveTaken;
                    this.leaveForm.get('LeaveToBeAvailed')!.patchValue(availableLeaves.LeaveAvailable);
                    this.leaveForm.get('AvailableLeave')!.patchValue(availableLeave);
                    this.leaveForm.get('LeaveTaken')!.patchValue(availableLeaves.TotalLeaveTaken);

                } else {
                    this.leaveForm.get('LeaveToBeAvailed')!.patchValue(0);
                    this.leaveForm.get('AvailableLeave')!.patchValue(0);
                    this.leaveForm.get('LeaveTaken')!.patchValue(0);
                }
            }));
        }
    }
    /**
     * leave availability check
     */
    leaveAvailabilityCheck() {
        let year = new Date().getFullYear();
        this.subscriptions.push(this.employeeLeaveService.getAvailableLeaves(this.empId, BaseServices.BusinessId, year).subscribe((availableLeaves: any) => {
            if (availableLeaves) {
                if (this.empId > 0) {
                    let availableLeave = availableLeaves.LeaveAvailable - availableLeaves.LeaveTaken;
                    this.leaveForm.get('LeaveToBeAvailed')!.patchValue(availableLeaves.LeaveAvailable);
                    this.leaveForm.get('AvailableLeave')!.patchValue(availableLeave);
                    this.leaveForm.get('LeaveTaken')!.patchValue(availableLeaves.LeaveTaken);
                    this.leaveForm.get('Year')!.patchValue(availableLeaves.year);
                }
            } else {
                this.isLeaveAvailable = true;
                this.leaveAlerttext = 'LEAVE_AVAILABILITY'
            }
        }));
    }
    //#endregion
    getHolidayList() {
        let result: IMyDate[] = [];
        this.subscriptions.push(this.employeeLeaveService.getAllHolidays(BaseServices.BusinessId).subscribe(hList => {
            hList.forEach(element => {
                var hDate = new Date(element["HolidayDate"]);
                var hDateFormatted = ({ day: hDate.getDate(), month: hDate.getMonth() + 1, year: hDate.getFullYear() });
                result.push(hDateFormatted);
            });

        }));
        return result;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

}
