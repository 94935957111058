import { UserRole } from './../../follow-ups/models/deviation';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { BaseServices } from './../../kuba.services';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { EmployeeFirstOfKin } from '../models/index';
import { EmployeeFirstOfKinServices } from '../services/employeefirstofkin.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  moduleId: module.id,
  selector: 'first-kin-edit',
  templateUrl: 'employee-firstofkin-edit.component.html',
})
export class FirstKinEditComponent implements OnInit {
  //#region variables

  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  employee: any;
  empName: string;
  firstOfKinkForm: FormGroup;
  employeeId: number;
  pdf: any;
  firstOfKinId: any;
  data: any;
  mobileCodes: SelectItem[];
  employeeFirstOfKin: EmployeeFirstOfKin[];
  employeefok: EmployeeFirstOfKin[];
  isShow = true;
  additionalData: any;
  isNotUser: boolean;
  loading = false;
  public autoResize: boolean = true;
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructors
  /**
   * constructor
   * @param _fb {FormBuilder}
   * @param employeeFirstOfKinServices {EmployeeFirstOfKinServices}
   * @param confirmationService {ConfirmationService}
   * @param route {ActivatedRoute}
   */
  constructor(
    public _fb: FormBuilder,
    public employeeFirstOfKinServices: EmployeeFirstOfKinServices,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.firstOfKinkForm = this._fb.group({
      ContactName: ['', [Validators.required]],
      Mobile: ['', Validators.required],
      Email: [''],
      Relation: [''],
      Address: [''],
      ZipCode: [''],
      MobileCodes: ['+47'],
    });
  }
  //#endregion

  //#region page-events
  /**
   * page init
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.isNotUser = +BaseServices.roleId === UserRole.USER ? false : true;

    let eid = this.route.snapshot.params['eid'];
    this.employeeId = +eid;
    if (this.employeeId > 0) {
      this.getEmployee(this.employeeId);
    }
    this.bindCountryCode();
    if (+BaseServices.roleId === 5) {
      this.isShow = false;
      if (this.employeeId === +BaseServices.FeatureKey) {
        this.isShow = true;
      }
    }
    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.FIRST_OF_KIN_LIST,
          header: 'First of kin List',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Name', dataKey: 'ContactName' },
            { title: 'Mobile', dataKey: 'Mobile' },
            { title: 'Email', dataKey: 'Email' },
            { title: 'Relation', dataKey: 'Relation' },
            { title: 'Address', dataKey: 'Address' },
            { title: 'City', dataKey: 'ZipCode' },
          ],
        };
      })
    );
  }

  //#endregion

  //#region control-events

  /**
   * add and update first of kin
   */
  saveChanges() {
    this.loading = true;
    if (this.firstOfKinkForm.valid) {
      let employee = new EmployeeFirstOfKin();
      employee.Id = this.firstOfKinId ? this.firstOfKinId : 0;
      employee.EmployeeId = this.employeeId;
      employee.ContactName = this.firstOfKinkForm.value.ContactName;
      employee.CountryCode = this.firstOfKinkForm.value.MobileCodes
        ? this.firstOfKinkForm.value.MobileCodes
        : 47;
      employee.Mobile = this.firstOfKinkForm.value.Mobile;
      employee.Email = this.firstOfKinkForm.value.Email;
      employee.Relation = this.firstOfKinkForm.value.Relation;
      employee.Address = this.firstOfKinkForm.value.Address;
      employee.ZipCode = this.firstOfKinkForm.value.ZipCode;
      employee.BusinessId = BaseServices.BusinessId;
      employee.Status = '1';
      if (employee.Id > 0) {
        this.subscriptions.push(
          this.employeeFirstOfKinServices
            .update(employee)
            .subscribe((addResponse) => {
              if (addResponse) {
                this.getEmployee(this.employeeId);
                this.toasterComponent.callToast();
                this.loading = false;
                this.onClear();
                this.firstOfKinId = 0;
              }
            })
        );
      } else {
        this.subscriptions.push(
          this.employeeFirstOfKinServices
            .add(employee)
            .subscribe((updateResponse) => {
              if (updateResponse) {
                this.getEmployee(this.employeeId);
                this.toasterComponent.callToast();
                this.loading = false;
                this.onClear();
              }
            })
        );
      }
    }
  }
  /**
   * editing employees first of kin
   * @param Id {number}
   */
  editEmployee(id: number) {
    this.subscriptions.push(
      this.employeeFirstOfKinServices.getById(id).subscribe((employee: any) => {
        if (employee) {
          this.firstOfKinId = id;
          this.employee = {
            ContactName: employee.ContactName,
            Mobile: employee.Mobile,
            MobileCodes: employee.CountryCode,
            Relation: employee.Relation,
            Address: employee.Address,
            ZipCode: employee.ZipCode,
            Email: employee.Email,
          };
          (<FormGroup>this.firstOfKinkForm).setValue(this.employee, {
            onlySelf: true,
          });
        }
      })
    );
  }
  /**
   * delete first of kin
   * @param firstOfKinId {number}
   */
  delete(firstOfKinId: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeFirstOfKinServices
            .deleteById(firstOfKinId)
            .subscribe((deleteResponse) => {
              if (deleteResponse) {
                this.getEmployee(this.employeeId);
                this.toasterComponent.callToastDlt();
                if (firstOfKinId === this.firstOfKinId) {
                  this.onClear();
                }
              }
            })
        );
      },
    });
  }
  /**
   * clearing the data in the form
   */
  onClear() {
    this.firstOfKinkForm.reset();
    this.firstOfKinkForm.patchValue({ MobileCodes: '+47' });
    this.firstOfKinId = 0;
  }
  /**
   * mobile number validation
   * @param e {any}
   * @param limitNumber {any}
   */
  onMobileNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }
  //#endregion

  //#region methods

  bindCountryCode() {
    this.mobileCodes = [];
    this.data = this.route.snapshot.data['countrycodedropdown'];
    if (this.data) {
      this.data.forEach((mobileCode: any) => {
        this.mobileCodes.push({
          label: '+' + mobileCode.Phonecode,
          value: '+' + mobileCode.Phonecode,
        });
      });
      this.mobileCodes.push({ label: '+37', value: '+37' }); // first of kin will be having a unique mobile code.
    }
  }
  /**
   * getting the list of first of kin for the employee
   * @param employeeId {number}
   */
  getEmployee(employeeId: number) {
    this.subscriptions.push(
      this.employeeFirstOfKinServices
        .getEmployeeFirstOfKinByEmployeeId(employeeId)
        .subscribe((firstOfKins: any) => {
          if (firstOfKins) {
            this.mobileNumberWithCode(firstOfKins);
            this.employeefok = firstOfKins;
          }
        })
    );
  }
  /**
   * patch mobile number with country code
   * @param mobileDetails {any}
   */
  mobileNumberWithCode(mobileDetails: any) {
    this.employeefok = [];
    if (mobileDetails) {
      mobileDetails.forEach((mobileData: any) => {
        mobileData.Mobile = `${mobileData.CountryCode} ${mobileData.Mobile}`;
        this.employeefok.push(mobileData);
        this.empName = mobileData.EmployeeName;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
