
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { KubaFeatureDetailsComponent } from './kuba-feature-details.component';
import { FeatureDetailService } from './kuba-feature-details.services';
import { KeysPipe } from 'src/app/_helpers/pipes';
import { AppSharedModule } from '../appShared.module';

@NgModule({
    imports: [CommonModule, RouterModule, AppSharedModule],
    exports: [KubaFeatureDetailsComponent],
    declarations: [KubaFeatureDetailsComponent, KeysPipe],
    providers: [FeatureDetailService]
})
export class KubaFeatureDetailsModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: KubaFeatureDetailsModule
        };
    }
}
