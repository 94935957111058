<div class="page-title"><span translate>INSTRUMENTS</span></div>
<span *ngIf="previewMPData.InstrumentList ? previewMPData.InstrumentList.length == 0 : true" translate>
    NO_RECORDS_FOUND
</span>
<table class="table no-border" *ngIf="previewMPData.InstrumentList ? previewMPData.InstrumentList.length : false">
    <tr>
        <th><span translate>TYPE</span></th>
        <th><span translate>MODEL</span></th>
        <th><span translate>CALIBRATED_DATE</span></th>
    </tr>
    <tr *ngFor="let ins of previewMPData.InstrumentList">
        <td>{{ins.InstrumentTypeName}}</td>
        <td>{{ins.InstrumentModelName}}</td>
        <td><span translate>{{ins.CalibratedDate | date : 'dd.MM.yyyy'}}</span>
        </td>
    </tr>
</table>
<div class="page-title"><span translate>VS_STATUS</span></div>
<div class="col-md-7">
    <h5><span translate>EARTH</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>INΩ</span></td>
            <td><span>{{visualControl.Resistance.MeasureValue}}</span></td>
        </tr>
        <tr>
            <td><span translate>METHOD</span></td>
            <td><span>{{visualControl.Resistance.Method}}</span></td>
        </tr>
    </table>
    <h5><span translate>CONTINUITY_POINT</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>HIGH_VALUE</span></td>
            <td><span>{{visualControl.Continuity.HighestValue}}</span></td>
        </tr>
        <tr>
            <td><span translate>DESCRIPTION</span></td>
            <td><span>{{visualControl.Continuity.Description}}</span></td>
        </tr>
    </table>
    <h5><span translate>VOLTAGE_MEASUEREMENT</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>L1-L2</span></td>
            <td><span>{{visualControl.Voltage.L1_L2}}</span></td>
        </tr>
        <tr>
            <td><span translate>L1-L3</span></td>
            <td><span>{{visualControl.Voltage.L1_L3}}</span></td>
        </tr>
        <tr>
            <td><span translate>L2-L3</span></td>
            <td><span>{{visualControl.Voltage.L2_L3}}</span></td>
        </tr>
        <tr>
            <td><span translate>L1-PE</span></td>
            <td><span>{{visualControl.Voltage.L1_PE}}</span></td>
        </tr>
        <tr>
            <td><span translate>L2-PE</span></td>
            <td><span>{{visualControl.Voltage.L2_PE}}</span></td>
        </tr>
        <tr>
            <td><span translate>L3-PE</span></td>
            <td><span>{{visualControl.Voltage.L3_PE}}</span></td>
        </tr>
        <tr>
            <td><span translate>N-L1</span></td>
            <td><span>{{visualControl.Voltage.N_L1}}</span></td>
        </tr>
        <tr>
            <td><span translate>N-L2</span></td>
            <td><span>{{visualControl.Voltage.N_L2}}</span></td>
        </tr>
        <tr>
            <td><span translate>N-L3</span></td>
            <td><span>{{visualControl.Voltage.N_L3}}</span></td>
        </tr>
    </table>
    <h5><span translate>SHORT_CIRCUIT_VALUE</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>2-pol min</span></td>
            <td><span>{{visualControl.Short.Polmin}}</span></td>
        </tr>
        <tr>
            <td><span translate>DESCRIPTION</span></td>
            <td><span>{{visualControl.Short.Description1}}</span></td>
        </tr>
        <tr>
            <td><span translate>3-pol max</span></td>
            <td><span>{{visualControl.Short.Polmax}}</span></td>
        </tr>
        <tr>
            <td><span translate>DESCRIPTION</span></td>
            <td><span>{{visualControl.Short.Description2}}</span></td>
        </tr>
    </table>
</div>
<div class="col-md-5">
    <h5><span translate>INSULATION_RESISTANCE_WITH_ALL_CIRCUITS_ACTIVATED_OVER_1MQ_MQ</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>L1-PE</span></td>
            <td><span>{{visualControl.Insulation.L1_PE}}</span></td>
        </tr>
        <tr>
            <td><span translate>L2-PE</span></td>
            <td><span>{{visualControl.Insulation.L2_PE}}</span></td>
        </tr>
        <tr>
            <td><span translate>L3-PE</span></td>
            <td><span>{{visualControl.Insulation.L3_PE}}</span></td>
        </tr>
        <tr>
            <td><span translate>N-PE</span></td>
            <td><span>{{visualControl.Insulation.N_PE}}</span></td>
        </tr>
    </table>
    <h5><span translate>POWER_MEASUREMENT</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>L1</span></td>
            <td><span>{{visualControl.Power.L1}}</span></td>
        </tr>
        <tr>
            <td><span translate>L2</span></td>
            <td><span>{{visualControl.Power.L2}}</span></td>
        </tr>
        <tr>
            <td><span translate>L3</span></td>
            <td><span>{{visualControl.Power.L3}}</span></td>
        </tr>
        <tr>
            <td><span translate>N</span></td>
            <td><span>{{visualControl.Power.N}}</span></td>
        </tr>
        <tr>
            <td><span translate>PE</span></td>
            <td><span>{{visualControl.Power.PE}}</span></td>
        </tr>
    </table>
    <h5><span translate>EFFECT_OF_POWER</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>L1</span></td>
            <td><span>{{visualControl.Effect.L1}}</span></td>
        </tr>
        <tr>
            <td><span translate>L2</span></td>
            <td><span>{{visualControl.Effect.L2}}</span></td>
        </tr>
        <tr>
            <td><span translate>L3</span></td>
            <td><span>{{visualControl.Effect.L3}}</span></td>
        </tr>
        <tr>
            <td><span translate>P tot</span></td>
            <td><span>{{visualControl.Effect.P_tot}}</span></td>
        </tr>
    </table>
    <h5><span translate>REACTIVE_MEASUREMENT</span></h5>
    <table class="table no-border">
        <tr>
            <td style="width: 140px;"><span translate>S</span></td>
            <td><span>{{visualControl.Reactive.S}}</span></td>
        </tr>
        <tr>
            <td><span translate>Cos phi</span></td>
            <td><span>{{visualControl.Reactive.Cosphi}}</span></td>
        </tr>
    </table>
</div>
<div class="page-title"><span translate>EARTH_LEAKAGE_BREAKER</span></div>
<table class="table no-border" *ngIf="previewMPData.EarthLeakageList ? previewMPData.EarthLeakageList.length : false">
    <tr>
        <th><span translate>CIRCUITNUMBER</span></th>
        <th><span translate>EL_BREAKER_MA</span></th>
        <th><span translate>RELEASE_MA</span></th>
        <th><span translate>DISCONNECTING_MS</span></th>
    </tr>
    <tr *ngFor="let eLeak of previewMPData.EarthLeakageList">
        <td>{{eLeak.CircuitNumber}}</td>
        <td>{{eLeak.ELBreaker}}</td>
        <td>{{eLeak.ReleasePower}}</td>
        <td>{{eLeak.DisconnectTime}}</td>
    </tr>
</table>
<div class="page-title"><span translate>POWER</span></div>
<table class="table no-border" *ngIf="previewMPData.PowerLeakageList ? previewMPData.PowerLeakageList.length : false">
    <tr>
        <th><span translate>MA</span></th>
        <th><span translate>COMMENTS</span></th>
    </tr>
    <tr *ngFor="let pwr of previewMPData.PowerLeakageList">
        <td>{{pwr.PowerReading}}</td>
        <td>{{pwr.Comments}}</td>
    </tr>
</table>
<div class="page-title"><span translate>TEMPERATURE</span></div>
<table class="table no-border" *ngIf="previewMPData.TempratureList ? previewMPData.TempratureList.length : false">
    <tr>
        <th><span translate>TYPE</span></th>
        <th><span translate>MODEL</span></th>
        <th><span translate>CELSIUS</span></th>
        <th><span translate>DATE</span></th>
    </tr>
    <tr *ngFor="let temp of previewMPData.TempratureList">
        <td>{{temp.MPCTemperatureModelName}}</td>
        <td>{{temp.MPCTemperatureTypeName}}</td>
        <td>{{temp.Celsius}}</td>
        <td>{{temp.TempDate | date : 'dd.MM.yyyy'}}</td>
    </tr>
</table>