<div class="tab-content">
  <div class="page-title" *ngIf="newButtonEnable">
    <span translate>OUR_CONTACTS</span>
    <span class="page-actions">
      <a [routerLink]="['./../../new']" class="btn btn-success">
        <span class="icon ic-sm icon-add"></span>
        <span translate>NEW_CONTACT</span>
      </a>
    </span>
  </div>
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #contactsTable
      [value]="contacts"
      dataKey="Id"
      [(selection)]="selectedContacts"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      (onRowUnselect)="onRowUnselect($event)"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
      (onRowSelect)="onRowSelect($event)"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="Contact List"
      styleClass="p-datatable-striped p-datatable-gridlines"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" style="display: none">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>
                    <span translate>TYPE_OF_CONTACT</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="typeOfContacts"
                      id="contact"
                      (onChange)="
                        onTypeOfContactChanged($event, contactDropdown)
                      "
                      #contactDropdown
                      [(ngModel)]="contactTypeFilter"
                    ></p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-options">
            <div class="pull-right">
              <div class="action-btns-group">
                <button class="btn p-3 filter-toggle">
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="
                    contactsTable.filteredValue || contactsTable.value
                  "
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>{{ "NAME" | translate }}</th>
          <th>{{ "CELLULAR_PHONE" | translate }}</th>
          <th>{{ "TELEPHONE" | translate }}</th>
          <th>{{ "EMAIL" | translate }}</th>
          <th>{{ "FUNCTION" | translate }}</th>
          <th>{{ "STATUS" | translate }}</th>
          <th>{{ "COMPANY" | translate }}</th>
        </tr>
        <tr>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter(
                  $any($event.target)?.value,
                  'Name',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter(
                  $any($event.target)?.value,
                  'ContactFunctionalAreaName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              [options]="statuses"
              (onChange)="onStatusChanged($event, statusDropdown)"
              #statusDropdown
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter(
                  $any($event.target)?.value,
                  'BusinessName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-contact>
        <tr>
          <td style="width: 55px">
            <p-tableCheckbox id="selectedOrders" [value]="contact">
            </p-tableCheckbox>
          </td>
          <td>
            <span
              ><a [routerLink]="['./../../edit', contact.Id]">{{
                contact.Name
              }}</a></span
            >
          </td>
          <td>
            <span>{{ contact.MobileCode }}</span>
          </td>
          <td>
            <span>{{ contact.Telephone }}</span>
          </td>
          <td>
            <span>{{ contact.Emailid }}</span>
          </td>
          <td>
            <span>{{ contact.ContactFunctionalAreaName }}</span>
          </td>
          <td>
            <span>
              <a
                href="javascript:void(0);"
                (click)="editStatus(contact['Id'], contact['StatusText'])"
                routerLinkActive="active"
                translate
                >{{ contact.StatusText.toUpperCase() }}</a
              >
            </span>
          </td>
          <td>
            <span>{{ contact.BusinessName }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="8">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix">
          <div class="footer-data">
            <div class="text-left" *ngIf="newButtonEnable">
              <span
                pTooltip="{{
                  'SELECT_ATLEAST_ONE_RECORD_DELETE_CONTACT' | translate
                }}"
              >
                <button
                  type="button"
                  class="btn btn-danger"
                  [disabled]="!isSelectedValue"
                  (click)="deleteContacts()"
                >
                  <span class="icon ic-sm icon-trash"></span>
                  <span translate>DELETE_CHECKED</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix">
          <div class="footer-data">
            <div class="text-left" *ngIf="newButtonEnable">
              <span
                pTooltip="{{
                  'SELECT_ATLEAST_ONE_RECORD_DELETE_CONTACT' | translate
                }}"
              >
                <button
                  type="button"
                  class="btn btn-danger"
                  [disabled]="!isSelectedValue"
                  (click)="deleteContacts()"
                >
                  <span class="icon ic-sm icon-trash"></span>
                  <span translate>DELETE_CHECKED</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<toaster-component></toaster-component>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
