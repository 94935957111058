export class Suggestion {
    Id ?: number;
    BusinessId ?: number;
    Title ?: string;
    Description ?: string;
    Status ?: string;
    CreatedOn ?: string;
    CreatedBy ?: number;
    ModifiedOn ?: string;
    ModifiedBy ?: number;
    CreatorName ?: string;
    SuggestionDocument ?: SuggestionDocumentDto[];

}
export class SuggestionDocumentDto {
    Id ?: number;
    SuggestionId ?: number;
    CourseId ?: number;
    FileName ?: string;
    OriginalFileName ?: string;
    MimeType ?: string;
    Size ?: number;
    Path ?: string;
    FileType ?: number;
    IsPrivateDocument ?: boolean;
    Status ?: string;
}
