import { element } from 'protractor';
import { Component, OnInit, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import * as _ from 'lodash';
import { BaseServices } from '../kuba.services';
import { Subscription } from 'rxjs';
import { FeaturesBaseServices } from '../features/services/features-base.services';
import { BusinessServices } from '../businesses/services/business.services';

@Component({
    templateUrl: 'followups.component.html'
})
export class FollowUpsComponent implements OnInit {
    pageTitle: string;
    additionalFeatures: any;
    enabledAdditionalFeatures: any;
    fId: number;
    initialPage: any;
    vehicleTitle: string;
    firstRoute = true;
    routeSub:Subscription;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private businessServices: BusinessServices,
    ) {
        this.fId = Module.FOLLOWUPS;
        if (sessionStorage.getItem('workingModule') && ((JSON.parse(sessionStorage.getItem('workingModule')!).name === 'Follow up')) || (JSON.parse(sessionStorage.getItem('workingModule')!).name === 'Followup')) {
            let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')!).children;

            if (window.name === 'Remote') {
                _.remove(allAdditionalFeatures, (n: any) => {
                    return n.translationKey === 'MESSAGING';
                });
            }
            this.enabledAdditionalFeatures = allAdditionalFeatures.filter((element:any) => (element.checked || element.IsDefault)
                && element.IsFeature.indexOf('N') > -1);

            if (this.enabledAdditionalFeatures.length > 0) {
                if (+BaseServices.roleId === 1) {
                    // JIRA ID= kw-285
                    this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter((x:any) => x.id !== 134);
                    this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter((x:any) => x.id !== 141);
                }
                this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.fId }],
                    { relativeTo: this.route });
                this.firstRoute  = true;
                this.routeSub = this.router.events.subscribe(val=>{
                    if(val instanceof NavigationEnd){
                        if(this.router.url.indexOf('follow-up') > -1){
                            if(this.firstRoute){
                                this.firstRoute = false;
                            }
                            else{
                                var url = this.router.url;
                                if(url.substr(url.length - 9) == 'follow-up'){
                                    this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.fId }],
                                        { relativeTo: this.route });
                                }
                            }
                        }
                    }
                });
            }
        } else {
            this.businessServices.getById(BaseServices.BusinessId).subscribe(result => {
                let featureBase = BaseServices.getUserFeatures();
                let template = <any>JSON.parse(featureBase.FeaturesTemplate);
                featureBase.FeaturesTemplate = JSON.stringify(template.filter((x:any) =>
                    (x.navigationRoles.indexOf('B') > -1 && x.IsFeature.indexOf('N') > -1)
                    || (x.navigationRoles.indexOf('B') > -1 && x.IsDefault === 1)));
                featureBase.Features = result.Features;
                featureBase.Type = 'business';
                featureBase.Details = result;
                let baseFeatures = FeaturesBaseServices.getFeatureDetails(featureBase).filter((x:any) => x.id == 15);
                if(baseFeatures ? baseFeatures.length : false){
                    sessionStorage.setItem('workingModule', JSON.stringify(baseFeatures[0]));
                    let allAdditionalFeatures =  JSON.parse(sessionStorage.getItem('workingModule')!).children;
                    if (window.name === 'Remote') {
                        _.remove(allAdditionalFeatures, (n: any) => {
                            return n.translationKey === 'MESSAGING';
                        });
                    }
                    this.enabledAdditionalFeatures = allAdditionalFeatures.filter((element:any) => (element.checked || element.IsDefault)
                        && element.IsFeature.indexOf('N') > -1);
        
                    if (this.enabledAdditionalFeatures.length > 0) {
                        if (+BaseServices.roleId === 1) {
                            // JIRA ID= kw-285
                            this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter((x:any) => x.id !== 134);
                            this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter((x:any) => x.id !== 141);
                        }
                        var url = this.router.url;
                        if(url.substr(url.length - 9) == 'follow-up'){
                            this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.fId }],
                            { relativeTo: this.route });
                        }
                        this.firstRoute  = false;
                        this.routeSub = this.router.events.subscribe(val=>{
                            if(val instanceof NavigationEnd){
                                if(this.router.url.indexOf('follow-up') > -1){
                                    if(this.firstRoute){
                                        this.firstRoute = false;
                                    }
                                    else{
                                        var url = this.router.url;
                                        if(url.substr(url.length - 9) == 'follow-up'){
                                            this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.fId }],
                                                { relativeTo: this.route });
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                // need to do something to add feature id on URL
            });
            
            
        }
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
    ngOnDestroy(){
        this.firstRoute=true;
        if(this.routeSub){
            this.routeSub.unsubscribe();
        }
    }
}
