<div class="tab-content">
    <div class="page-title">
      <span translate>ADD_CODES</span>
    </div>
    <form [formGroup]="businessAbsence">
      <div class="form-group">
        <label for="sickness">
          <span translate>SICKNESS</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" pInputText required formControlName="OwnSickness" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['OwnSickness']"></control-messages>
  
      </div>
      <div class="form-group">
        <label for="medicicalcertificate">
          <span translate>SICKNESS_MED </span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" pInputText required formControlName="OwnSickMedicalCertificate" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['OwnSickMedicalCertificate']"></control-messages>
      </div>
      <div class="form-group">
        <label for="workIssues">
          <span translate>WORK_ISSUES</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" required pInputText formControlName="WorkRelatedIssues" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['WorkRelatedIssues']"></control-messages>
      </div>
      <div class="form-group">
        <label for="ChildrenSickness">
          <span translate>CHILDREN_SICKNESS</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" required pInputText formControlName="ChildrenSickness" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['ChildrenSickness']"></control-messages>
      </div>
      <div class="form-group">
        <label for="CaretakerSickness">
          <span translate>CHILDREN_CARE_SICKNESS</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" required pInputText formControlName="ChildrenCaretakerSick" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['ChildrenCaretakerSick']"></control-messages>
      </div>
      <div class="form-group">
        <label for="PaidLeave">
          <span translate>PAID_LEAVE</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" required pInputText formControlName="PaidLeave" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['PaidLeave']"></control-messages>
      </div>
      <div class="form-group">
        <label for="UnpaidLeave">
          <span translate>UNPAID_LEAVE</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" required pInputText formControlName="UnpaidLeave" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['UnpaidLeave']"></control-messages>
      </div>
      <div class="form-group">
        <label for="Others">
          <span translate>OTHERS</span>
          <span class="required">*</span>
        </label>
        <div>
          <input type="text" required pInputText formControlName="Others" maxlength="10">
        </div>
        <control-messages [control]="businessAbsence.controls['Others']"></control-messages>
      </div>
      <div class="action-btns-wrapper">
        <button class="btn btn-primary" [ngClass]="{'loading' : loading}" (click)="saveChanges()"
          [disabled]="!businessAbsence.valid || loading">
          <span>
            <span *ngIf="!loading" translate>SAVE</span>
            <span *ngIf="loading" translate>SAVE_PROCESS</span>
            <span *ngIf="loading" class="loader-icon"></span>
          </span>
        </button>
      </div>
    </form>
  </div>
  <toaster-component></toaster-component>