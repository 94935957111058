import { EmployeeContactUserResolver } from './../users/services/user.resolver.service';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SafetyjobComponent } from './jobanalysis.component';
import { JobAnalysisService } from './services/jobanalysis.services';
import { FormComponent } from './components/safety-job-form.component';
import { NewComponent } from './components/new-safety-job-analysis.component';
import { ListComponent } from './components/safety-job-list.component';
import {
  SafteyJobEditResolver,
  SafteyJobListResolver,
  TypeOfIssueResolver,
  TopicResolver,
  SJAApprovalNoticeListResolver,
} from './services/jobanalysis.resolver.services';
import { Safetyjob } from './models/index';
import { AppSharedModule } from './../shared/appShared.module';
import { TranslateModule } from '@ngx-translate/core';
import { EcplPdfViewerModule } from 'src/app/shared/ecpl-pdf-viewer';
import { ConfirmationService } from 'primeng/api';
import { ClipboardModule } from 'ngx-clipboard';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MyDatePickerModule } from 'mydatepicker';
import { EditComponent } from './components/safety-job-analysis-edit.component';
@NgModule({
  imports: [
    HttpClientModule,
    FormsModule,
    CommonModule,
    AppSharedModule,
    EcplPdfViewerModule,
    RouterModule,
    TranslateModule,
    ConfirmDialogModule,
    EcplDocumentExplorerModule,
    ClipboardModule,
    MyDatePickerModule,
  ],
  exports: [
    SafetyjobComponent,
    NewComponent,
    ListComponent,
    FormComponent,
    EditComponent,
  ],
  declarations: [
    SafetyjobComponent,
    NewComponent,
    ListComponent,
    FormComponent,
    EditComponent,
  ],
  providers: [
    JobAnalysisService,
    ConfirmationService,
    SafteyJobEditResolver,
    SafteyJobListResolver,
    TypeOfIssueResolver,
    TopicResolver,
    SJAApprovalNoticeListResolver,
    EmployeeContactUserResolver,
  ],
})
export class JobAnalysisModule {}
