import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
    selector: 'suggestion',
    templateUrl: 'suggestion.component.html'
})

export class SuggestionComponent implements OnInit {
    constructor() { }

    ngOnInit() { document.querySelector("body").classList.remove("opened");}
}