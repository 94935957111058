import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationService,SelectItem } from 'primeng/api';
import { Rights } from 'src/app/_models';
import { ToasterComponent } from '../../../_directives/toaster.component';
import { ModuleOverview } from '../models/moduleoverview';
import { ModuleOverviewServices } from '../services/module-overview.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'moduleoverviewlist',
    templateUrl: './module-overviewList.component.html'
})
export class ModuleOverviewListComponent implements OnInit {

    //#region variable
    @ViewChild('dt',{static: false}) dt: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    public moduleoverview: ModuleOverview[];
    featureName: SelectItem[];
    isAdministrateHelp = true;
    isHideDeleteIcon = true;
    isUser = true;
    isAdmin: boolean;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor

    constructor(
        public helpsupportServices: ModuleOverviewServices,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) { 
        
     }

    //#endregion

    //#region page-event

    /**
     * page load event
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        let moduleoverview = [];
        moduleoverview = this.route.snapshot.data['list'];
        if (moduleoverview) {
            sessionStorage.setItem('FeatureOverviewDropdown', JSON.stringify(moduleoverview));
            this.featureName = [];
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.featureName = [];
                this.featureName.push(
                    { label: val.SELECT, value: null }
                );
                moduleoverview.forEach((listData: any) => {
                    this.featureName.push({ label: this.translate.instant(listData.TranslationKey), value: listData.FeatureId });
                });
            }));
            
        }
        if (this.router.url.indexOf('admin') > 0) {
            if (+BaseServices.roleId === 3) {
                this.isHideDeleteIcon = false;
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
                this.isHideDeleteIcon = true;
            }
        }
        if (moduleoverview != null) {
            this.moduleoverview = moduleoverview.filter((x: any) => x.Status === 1);
        }
        if (BaseServices.roleId === '5') {
            this.isUser = false;
        }
        this.onAdministrateInternalNews();
    }

    //#endregion

    //#region control-event
    /**
     * delete news
     */
    confirm(news: any) {

        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_HELPSUPPORT'),
            accept: () => {
                this.helpsupportServices.delete(news).then(isNewsDeleted => {
                    if (isNewsDeleted) {
                        this.toasterComponent.callToastDlt();
                    }
                });
            }
        });
    }

    /**
     * Edit & update News based on administrate news rights
     */

    onAdministrateInternalNews() {
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_NEWS;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.isAdministrateHelp = BaseServices.checkUserRights(
                userRightsId,
                role
            );
            this.isHideDeleteIcon = false;
        }
    }

    onFeatureChange(e: any, featureDropdown: any) {
        if (e.value) {
            this.dt.filter(
                featureDropdown.selectedOption.label,
                'FeatureName',
                'contains'
            );
        } else {
            this.dt.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
