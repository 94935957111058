import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { ServiceAgreement } from '../models/service-agreement';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceAgreementServices {
    constructor(private http: HttpClient) { }
    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', 'application/json');
    }
    // List all News
    list() {
        return this.http.get(environment.BASE_URL + '/serviceAgreement/getall/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getByUser(Id: number) {
        return this.http.get(environment.BASE_URL + '/serviceAgreement/getuserSA/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    get(Id: number) {
        return this.http.get(environment.BASE_URL + '/serviceAgreement/get/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    add(data) {
        return this.http.post(environment.BASE_URL + '/serviceAgreement/save', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    update(Id: number, data: ServiceAgreement) {
        return this.http.put(environment.BASE_URL + '/serviceAgreement/update/' + Id, data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    delete(Id: number) {
        return this.http.delete(environment.BASE_URL + '/serviceAgreement/delete/' + Id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    getClientContactUser(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/clientcontactsuser/' + clientId, BaseServices.headerOption)
            .map(result => result);
    }
    getServiceAgreementlistByBusiness() {
        return this.http.get(environment.BASE_URL + '/serviceAgreement/getall/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getServiceMylistByBusiness() {
        return this.http.get(environment.BASE_URL + '/serviceAgreement/getmylist', BaseServices.headerOption)
            .map((result: any) => result);
    }

    getServiceAgreementFeatures(businessId: number) {
        let featureId: number;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        featureId = workingModule.id;
        return this.http.get(environment.BASE_URL + '/business/' + businessId, BaseServices.headerOption)
            .map((result: any) => {
                let feature = result.Features;
                let serviceAgreementAdditionalFeature = _.find(JSON.parse(feature), { 'id': featureId, 'access': true });
                return serviceAgreementAdditionalFeature;
            });
    }

    getServiceDocuments(serviceId: number) {
        return this.http.get(environment.BASE_URL + '/serviceAgreement/getservicedocumentlist' + serviceId, BaseServices.headerOption)
            .map(result => result);
    }

    getActiveClientContactUser(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/activeclientcontactsuser/' + clientId, BaseServices.headerOption)
            .map(result => result);
    }

}
