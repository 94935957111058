import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FaqServices } from './../../services/faqs.service';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    FormControl,
    Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'view-faq.component.html'
})
export class FaqsViewComponent implements OnInit {
    category: SelectItem[];
    FaqForm: FormGroup;
    // TODO: Need to check wether functionality implemented
    selectedCategory: any;
    selectedUrl: string;
    cFaq: any;
    Faqs: any;
    Faq: SelectItem[];
    videoList = [];
    private subscriptions: Subscription[] = [];
    constructor(private faqsvc: FaqServices, private fb: FormBuilder) {}

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.selectedUrl = this.videoList[1].title;
        this.Faq = [];
        this.Faq.push({ label: 'select a Category', value: null });
        this.subscriptions.push(this.faqsvc.getAllFeatures().subscribe(result => {
            this.cFaq = result;
            this.cFaq.forEach(element => {
                this.Faq.push({ label: element.Title, value: element.Id });
            });
        }));
    }

    onFaqChanged(e) {
        this.subscriptions.push(this.faqsvc.getFaqById(e.value).subscribe((result:any) => {
            if (result) {
                this.selectedUrl = result.VideoPath;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
