export class ControlDto {
    Id: number;
    Title: string;
    Description: string;
    VehicleId: number;
    Status: string;
    CreatedOn: string;
    ModifiedOn: string;
    CreatedBy: number;
    ModifiedBy: number;
}
export class ControlListDto {
    Id: number;
    Title: string;
    Description: string;
    VehicleId: number;
    Status: string;
    CreatedOn: string;
    ModifiedOn: string;
    CreatedName: number;
    ModifiedName: number;
}
