import { Component, OnInit } from '@angular/core';
import { Module } from '../../follow-ups/models/deviation';
import { FeaturesBaseServices } from '../../features/services/features-base.services';
import { BaseServices } from '../../kuba.services';
import { BusinessServices } from '../../businesses/services/business.services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './flexi.component.html'
})

export class FlexiComponent implements OnInit {
    fId: number;
    enabledAdditionalFeatures: any;
    isMedi3: boolean=false;

    constructor(
        private businessServices: BusinessServices,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.fId = Module.FLEXI;
        if (sessionStorage.getItem('workingModule') && ((JSON.parse(sessionStorage.getItem('workingModule')).name === 'Flexi'))) {
            let allAdditionalFeatures = [{
                "id": 61,
                "name": "HSE-advisor",
                "checked": true,
                "linkText": "HSE-advisor",
                "access": true,
                "navigationRoles": "P",
                "translationKey": "HSE-ADVISOR",
                "key": "",
                "IsFeature": "FN",
                "IsDefault": 1
            },
            {
                "id": 62,
                "name": "OHS-plan",
                "checked": true,
                "linkText": "OHS-plan",
                "access": true,
                "navigationRoles": "P",
                "translationKey": "OHS-PLAN",
                "key": "",
                "IsFeature": "FN",
                "IsDefault": 1
            },
            {
                "id": 143,
                "name": "Documents",
                "checked": true,
                "linkText": "document/list",
                "access": true,
                "navigationRoles": "P",
                "translationKey": "DOCUMENTS",
                "key": "",
                "IsFeature": "FN",
                "IsDefault": 0
            },
            {
                "id": 138,
                "name": "Risk Analysis",
                "checked": true,
                "linkText": "risk-analysis",
                "access": true,
                "navigationRoles": "APB",
                "translationKey": "RISK_ANALYSIS",
                "key": "",
                "IsFeature": "FN",
                "IsDefault": 0
            }];

            this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => (element.checked || element.IsDefault)
                && element.IsFeature.indexOf('N') > -1);
        }

    }

    ngOnInit() {
        this.businessServices.getById(BaseServices.BusinessId).subscribe(result => {
            let featureBase = BaseServices.getUserFeatures();
            featureBase.Features = result.Features;
            featureBase.Type = 'business';
            featureBase.Details = result;
            let baseFeatures = FeaturesBaseServices.getFeatureDetails(featureBase).filter(x => x.id == 60);
            if (baseFeatures ? baseFeatures.length : false) {
                sessionStorage.setItem('workingModule', JSON.stringify(baseFeatures[0]));
            }
        });
        if(BaseServices.ApplicationId ==5){
            this.isMedi3 = true;
        }
        this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: BaseServices.BusinessId }],
            { relativeTo: this.activatedRoute });
    }
}