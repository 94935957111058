export class ElectroDeviation {
    Id: number;
    InspectionId: number;
    InspectionNumber: number;
    DeviationNumber: number;
    BusinessId: number;
    ApplicationId: number;
    ExecutionUserId: number;
    FollowupUserId: number;
    Status: number;
    OfferPrice: string;
    CreatedBy: number;
    NotifyBeforeDeadLine: number;
    NotifyBeforeFollowup: number;
    ModifiedBy: number;
    CompletedBy: number;
    Building: number;
    Description: string;
    Importance: number;
    ReferReport: number;
    ReferReportCheckpoint: number;
    ReferRegulationParaViolated: number;
    CorrectiveAction: string;
    OfferInclude: string;
    DeviationNumberPrefix: string;
    Deadline: string;
    CreatedOn: string;
    ModifiedOn: string;
    CompletedDate: string;
    Location: string;
    Title: string;
    EventDate: string;
    EventTime: string;
    ParaViolated: string;
    IsChangeNotifyExecution: boolean;
    IsChangeNotifyFollowup: boolean;
    ElectroDeviationAttachment: ElectroDeviationAttachment[];
}

export class DeviationList {
    Id: number;
    InspectionId: number;
    ExecutionUserId: number;
    FollowupUserId: number;
    Status: number;
    DeviationNumber: number;
    StatusText: string;
    Importance: number;
    ImportanceText: string;
    Deadline: string;
    ResponsiblePersonName: string;
    CompletedDate: string;
    ClientName: string;
    BuildingNum: string;
}
export class ElectroDeviationAttachment {
    Id: number;
    DeviationId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
}