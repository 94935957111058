import { Route } from '@angular/router';

import { UserComponent } from './user.component';
import { UserEditComponent } from './components/userEdit.component';
import { UserListComponent } from './components/userList.component';
import { UserRightsComponent } from './components/userRights.component';
import {
    UserListResolver,
    UserEditResolver,
    UserRoleResolver
} from './services/user.resolver.service';
import { BusinessUserEditResolver } from 'src/app/kuba/businesses/services/business.resolver.service';
import { ProjectListResolver } from 'src/app/kuba/projects/services/project.resolver';
import { ELListResolver } from './../kunde-EL/services/el.resolver';
import { FdvListResolver } from './../FDV/services/fdv.resolver.service'; 

export const userRoutes: Route[] = [{
    path: 'user',
    component: UserComponent,
    children: [{
        path: 'list',
        component: UserListComponent,
        resolve: {
            list: UserListResolver
        },
    },
    {
        path: 'new',
        component: UserEditComponent
    },
    {
        path: 'edit/:uid',
        component: UserEditComponent,
        resolve: {
            userData: BusinessUserEditResolver,
            userrole: UserRoleResolver,
            projectList: ProjectListResolver,
             list: ELListResolver,
             fdvList: FdvListResolver,
            details: UserEditResolver,
            userRoles: UserRoleResolver
        },
    },
    {
        path: 'rights',
        component: UserRightsComponent
    }],
},

];
