<div class="main-content">
  <div class="main-heading">
    <div class="page-title">
      <span translate>Vehicle Task</span>
      <span class="page-actions pull-right">
        <a [routerLink]="['./../new']" class="btn btn-success">
          <span class="icon ic-sm icon-add"></span>
          <span>New Task</span>
        </a>
      </span>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-view">
        <!-- Table starts -->
        <p-table #dt [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 38px;">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="VehicleBrandId">{{'Date' | translate}}</th>
              <th pSortableColumn="VehicleModelId">{{'Activity Type'|translate}}</th>
              <th pSortableColumn="RegistrationNo">{{'Responsible for Execution'|translate}}</th>
              <th pSortableColumn="RegistrationYear">{{'Notification days'|translate}}</th>
              <th></th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input pInputText type="text"
                  (input)="dt.filter($any($event.target)?.value, 'VehicleBrandId', 'contains')" placeholder="Brand"
                  class="p-column-filter">
              </th>
              <th>
                <input pInputText type="text"
                  (input)="dt.filter($any($event.target)?.value, 'VehicleModelId', 'contains')" placeholder="Model"
                  class="p-column-filter">
              </th>
              <th>
                <input pInputText type="text"
                  (input)="dt.filter($any($event.target)?.value, 'RegistrationNo', 'contains')"
                  placeholder="Registration Number" class="p-column-filter">
              </th>
              <th>
                <input pInputText type="text"
                  (input)="dt.filter($any($event.target)?.value, 'RegistrationYear', 'contains')"
                  placeholder="Registration Year" class="p-column-filter">
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-vehicle>
            <tr>
              <td style="width: 38px;">
                <p-tableCheckbox id="selectedOrders" [value]="vehicle">
                </p-tableCheckbox>
              </td>
              <td>
                <span>{{vehicle.VehicleBrandId}}</span>
              </td>
              <td>
                <span>{{vehicle.VehicleModelId}}</span>
              </td>
              <td>
                <span>{{vehicle.RegistrationNo}}</span>
              </td>
              <td>
                <span>{{vehicle.RegistrationYear}}</span>
              </td>
              <td class="col-button">
                <a [routerLink]="['./../edittask',vehicle.Id]" class="btn btn-icon" title="{{'EDIT'|translate}}">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </a>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="13">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
      <div class="action-btns-wrapper text-right">
        <span class="action-label">Export to</span>
        <div class="btn-group">
          <button type="button" class="btn btn-link">
            <i class="pi pi-file-excel"></i> Excel</button>
          <button type="button" class="btn btn-link">
            <i class="pi pi-file-pdf"></i> PDF</button>
        </div>
      </div>
    </div>
  </div>
</div>