export class DeviationAttachment {
    Id: number;
    DeviationId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: string;
    Status: string;
    CreatedBy: {
        Id: number;
        Name: string;
    };
    CreatedOn: string;
    ModifiedBy: {
        Id: number;
        Name: string;
    };
    ModifiedOn: string;
}
