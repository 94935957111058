
import { NotificationSubscription } from "src/app/shared/employee-contact";

export class QualitySystem {
    Id: number;
    Title: string;
    Description: string;
    IsEditorOnly: string;
    Status: string;
    BusinessId: number;
    ApplicationId: number;
    CreatedBy: number;
    CreatorName: string;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    ShowDeviation?: boolean;
    QSEmailSubscribers: NotificationSubscription[];
    QSUserDto: QualitySystemUser[];
    QualitySystemUsers : QualitySystemUsers;
    UserCount: number;
}

export class QualitySystemDetail {
    Title: string;
    Description: string;
    constructor() {
        this.Title = '';
        this.Description = '';
    }
}
export class QualitySystemUser {
    QualitySystemId: number;
    UserId: number;
    Name: string;
}

export class QualitySystemUsers {
    QualitySystemId: number;
    UserId: number;
    Count:String;
    NameList: String;
}

export class KeyPairValue {
    Key?: number;
    Value: string;
}
