import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from '../kuba.services';
import { TreeData } from './../common/models';
import { ArchiveServices } from './archive.services';

@Injectable()
export class ArchiveTreeDataResolver implements Resolve<any[]> {
    constructor(private backend: ArchiveServices) {}

    resolve(route: ActivatedRouteSnapshot): Observable<TreeData[]> {
        return this.backend.getArchiveTree(BaseServices.BusinessId);
    }
}
