import { Rights } from 'src/app/_models';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ServiceAgreementServices } from './../services/service-agreement.service'
import { ServiceAgreement } from '../models/service-agreement';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'service-agreement-mylist',
    templateUrl: 'service-agreement-mylist.component.html'
})

export class ServiceAgreementMyListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    contracts: ServiceAgreement[];
    additionalData: any;
    isHideContractor = true;
    pdf: any;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute
        , private confirmationService: ConfirmationService
        , private serviceAgreementServices: ServiceAgreementServices,
        private translate: TranslateService) {
        this.additionalData = {
            fileName: 'Service-Aggreement',
            header: 'Service-Aggreement',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'ContractNumber', dataKey: 'ContractNumber' },
                { title: 'ContractName', dataKey: 'ContractName' },
                { title: 'ClientName', dataKey: 'ClientName' },
                { title: 'CreatedName', dataKey: 'CreatedName' },
                { title: 'Status', dataKey: 'Status' }
            ]
        };
    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.contracts = [];
        if (+BaseServices.roleId === 4) {
            this.isHideContractor = false;
        }
        let list = <ServiceAgreement[]>this.route.snapshot.data['mylist'];
        if (list != null) {
            for (let t of list) {
                if (t.Status === '2') {
                    t.Status = 'New';
                } else if (t.Status === '3') {
                    t.Status = 'Open';
                } else if (t.Status === '4') {
                    t.Status = 'Done';
                } else if (t.Status === '5') {
                    t.Status = 'Rejected';
                } else { t.Status = 'New'; }
            }
            this.contracts.push(...list);
        }

    }

    /**
     * get servicelist
     */
    getProjectList() {
        this.subscriptions.push(this.serviceAgreementServices
            .getServiceMylistByBusiness()
            .subscribe(result => {
                let list = result;
                list.forEach(t => {
                    if (t.Status === '2') {
                        t.Status = 'New';
                    } else if (t.Status === '3') {
                        t.Status = 'Open';
                    } else if (t.Status === '4') {
                        t.Status = 'Done';
                    } else if (t.Status === '5') {
                        t.Status = 'Rejected';
                    } else { t.Status = 'New'; }
                });
                this.contracts = [];
                this.contracts.push(...list);
            }));
    }

    exportCSV() { }
    /**
   * pdf report for service and agreement list
   */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Serviceagreement list';
        let columns = [
            { title: 'Contract Number', dataKey: 'ContractNumber' },
            { title: 'Contract Name', dataKey: 'ContractName' },
            { title: 'Client Name', dataKey: 'Client' },
            { title: 'Status', dataKey: 'Status' },
            { title: 'Created By', dataKey: 'CreatedName' },
            { title: 'Edited By', dataKey: 'ModifierName' },
        ];
        HelperService.generatePdf(this.contracts, columns, 'Service agreement list', pdfSetting, 'l');
    }

    /**
  * delete service based on id
  * @param  service
  */
    deleteService(service) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.serviceAgreementServices
                    .delete(service)
                    .subscribe(result => {
                        this.getProjectList();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
