import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
@Component({

    template: `<router-outlet></router-outlet>`
})
export class ModuleOverviewComponent { 
     constructor(private http: HttpClient) {
    }
}
