export class SupplierList {
    Id: number;
    SupplierTypeId: number;
    SupplierTypeName: string;
    Name: string;
    Number: string;
    OrgNumber: string;
    Address: string;
    Zip: string;
    Mobile: string;
    Telephone: string;
    Email: string;
    Location: string;
    CountryCode: string;
    Comment: string;
    BusinessId: number;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}
