import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from 'src/app/kuba/shared';
import { KubaRuhNewComponent } from './kuba-ruh.component';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { FileUploadService } from 'src/app/_services/fileUpload.service';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        AppSharedModule
    
    ],
    exports: [],
    declarations: [],
    providers: [FileUploadService],
})
export class RuhModule { 
    

}
