export class TreeData {
    id: number;
    nodeId: number;
    title: string;
    parentFolderId: number;
    levelId: number;
    levelKey: number;
    documentTypeId: number;
    documentTypeName: number;
    status: number;
    isDefault: boolean;
    isFileType: boolean;
    position: number;
    createdBy: number;
    createdOn: Date;
    modifiedBy: number;
    modifiedOn: Date;
    hasChildren: boolean;
    childCount: number;
    children: TreeData[];
    checked:boolean;
    treeParentId: number;
    treeParentType: string;
    createdUserType: number;
    isCommon: boolean;
}