import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { ProjectAdminList } from './../../project-admin/models/project-admin-list';
import { AssignProjectService } from './assign-projects.service';

@Injectable()

export class AssignprojectListResolver implements Resolve<ProjectAdminList[]> {
    constructor(private backend: AssignProjectService) {
    }
    resolve(route: ActivatedRouteSnapshot): Observable<ProjectAdminList[]> {
        return this.backend.getProjectList(BaseServices.ApplicationId);
    }

}
