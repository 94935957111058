import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Observable } from 'rxjs';

import { Safetyjob, SafetyJobListedit } from '../models';
import { JobAnalysisService } from '../services/jobanalysis.services';

@Injectable()
export class SafteyJobListResolver implements Resolve<Safetyjob[]> {
  constructor(
    private backend: JobAnalysisService,
    private route: ActivatedRoute) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Safetyjob[]> {
    return this.backend.getAllByEach(+route.params['Id'], route.parent!.parent!.data['parent']);
  }
}

@Injectable()
export class SafteyJobEditResolver implements Resolve<SafetyJobListedit> {
  constructor(private backend: JobAnalysisService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getRiskById(+route.params['jobId']);
  }
}
@Injectable()
export class TypeOfIssueResolver implements Resolve<any> {
  constructor(private backend: JobAnalysisService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllIssues();
  }
}
@Injectable()
export class TopicResolver implements Resolve<any> {
  constructor(private backend: JobAnalysisService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getTopicList(BaseServices.BusinessId);
  }
}
@Injectable()
export class SJAApprovalNoticeListResolver implements Resolve<any> {
  constructor(private backend: JobAnalysisService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllApproved(+route.params['Id'], route.parent!.parent!.data['parent'])
  }
}
