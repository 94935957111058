<div class="page-title">
  <span translate>ALLOCATE_LEAVE</span>
</div>
<div [formGroup]="leaveAllocateForm">
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #leavesAllocateTable
      [value]="leaves"
      [rows]="10"
      dataKey="EmployeeId"
      [(selection)]="selectedLeaves"
      [scrollable]="true"
      scrollHeight="600px"
      exportFilename="Employee Leave Details"
      (selectionChange)="onSelectionChange($event)"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper">
            <div class="form-group" *ngIf="leaveAllocateForm.controls['Year']">
              <label for="Year">
                <span translate>CHOOSE_YEAR</span>
                <span class="required">*</span>
              </label>
              <div>
                <p-dropdown
                  [filter]="true"
                  formControlName="Year"
                  id="Year"
                  [options]="Year | dropdownSelectTranslate"
                  [style]="{ width: '100%' }"
                  (onChange)="bindLeavesAvaliable(leaveAllocateForm.value.Year)"
                ></p-dropdown>
              </div>
              <control-messages
                [control]="leaveAllocateForm.controls['Year']"
              ></control-messages>
            </div>
          </div>
          <div class="table-options">
            <div class="pull-right">
              <div class="action-btns-group revealed-filters">
                <button class="btn p-3 filter-toggle">
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="
                    leavesAllocateTable.filteredValue ||
                    leavesAllocateTable.value
                  "
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="Name">
            {{ "EMPLOYEE_NAME" | translate }}
            <input
              pInputText
              type="text"
              (input)="
                leavesAllocateTable.filter(
                  $event.target.value,
                  'Name',
                  'contains'
                )
              "
              (click)="$event.stopPropagation()"
            />
          </th>
          <th pSortableColumn="DaysUsed">
            {{ "DAYS_ALLOCATED" | translate }}
            <input
              pInputText
              type="text"
              (input)="
                leavesAllocateTable.filter(
                  $event.target.value,
                  'DaysUsed',
                  'contains'
                )
              "
              (click)="$event.stopPropagation()"
            />
          </th>
          <th pSortableColumn="LeaveAvailable">
            {{ "AVAILED_DAYS" | translate }}
            <input
              pInputText
              type="text"
              (input)="
                leavesAllocateTable.filter(
                  $event.target.value,
                  'LeaveAvailable',
                  'contains'
                )
              "
              (click)="$event.stopPropagation()"
            />
          </th>
          <th pSortableColumn="DaysLeft">
            {{ "DAYS_LEFT" | translate }}
            <input
              pInputText
              type="text"
              (input)="
                leavesAllocateTable.filter(
                  $event.target.value,
                  'DaysLeft',
                  'contains'
                )
              "
              (click)="$event.stopPropagation()"
            />
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-leave>
        <tr>
          <td style="width: 55px">
            <p-tableCheckbox
              id="selectedOrders"
              [value]="leave"
            ></p-tableCheckbox>
          </td>
          <td>
            <span>{{ leave.Name }}</span>
          </td>
          <td>
            <span
              [innerHTML]="leave.LeaveAvailableDisplay || leave.LeaveAvailable"
            ></span>
          </td>
          <td>
            <span>{{ leave.DaysUsed }}</span>
          </td>
          <td>
            <span>{{ leave.DaysLeft }}</span>
          </td>
        </tr>
      </ng-template>

      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="5">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <div class="form-group">
    <label for="Availed">
      <span translate>ALLOCATED_LEAVE_DAYS</span>
    </label>
    <div>
      <input
        type="number"
        pInputText
        formControlName="LeaveAvailable"
        id="LeaveAvailable"
        onpaste="return false;"
        (input)="onLeaveAvailableChange($event)"
      />
    </div>
    <control-messages
      [control]="leaveAllocateForm.controls['LeaveAvailable']"
    ></control-messages>
  </div>
</div>
<div class="action-btns-wrapper">
  <a [routerLink]="['./../list']" class="btn btn-outline-secondary">
    <span class="icon ic-xs icon-back"></span>
    <span translate>BACK_LIST</span>
  </a>
  <button
    class="btn btn-outline-primary"
    type="button"
    (click)="isAddType = !isAddType"
  >
    <span translate>SET_HOLIDAYS</span>
  </button>
  <button
    class="btn btn-primary"
    type="submit"
    (click)="saveMultiple()"
    [disabled]="!leaveAllocateForm.valid || allocateApply"
  >
    <span translate>APPLY</span>
  </button>
</div>
<p-dialog
  header="{{ 'PUBIC_HOLIDAY' | translate }}"
  [(visible)]="isAddType"
  [style]="{ width: '500px', height: '530px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="onBeforeHolidayDialogHide()"
>
  <form [formGroup]="holidayForm">
    <p-header>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="title">
              <span translate>HOLIDAY_DATE</span>
              <span class="required">*</span>
            </label>
            <div>
              <my-date-picker
                [options]="startDateOptions"
                formControlName="HolidayDate"
                placeholder="{{ 'SELECT_HOLIDAY_DATE' | translate }}"
              ></my-date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="title">
              <span translate>HOLIDAY_NAME</span>
              <span class="required">*</span>
            </label>
            <div>
              <input
                type="text"
                #title
                pInputText
                formControlName="HolidayName"
              />
            </div>
          </div>
        </div>
      </div>
    </p-header>
    <p-footer>
      <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveHoliday()"
          [disabled]="!holidayForm.valid"
        >
          <span translate>SAVE</span>
        </button>
      </div>
      <div *ngIf="true">
        <div class="table-view" style="max-height: 310px">
          <!-- Table starts -->
          <p-table
            [value]="holidays"
            #hoildayTable
            [rows]="5"
            [paginator]="true"
            [pageLinks]="5"
            [rowsPerPageOptions]="[5, 10, 20]"
            [responsive]="true"
          >
            <!-- (onRowSelect)="onRowSelect($event)" -->
            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th>{{ "DATE" | translate }}</th>
                <th>{{ "HOLIDAY_NAME" | translate }}</th>
                <th></th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <input
                    pInputText
                    type="text"
                    (input)="
                      hoildayTable.filter(
                        $any($event.target)?.value,
                        'HolidayName',
                        'contains'
                      )
                    "
                    placeholder=""
                    class="p-column-filter"
                  />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-categoryType>
              <tr>
                <td>
                  <span>{{
                    categoryType.HolidayDate | date : "dd/MM/yyyy"
                  }}</span>
                </td>
                <td>
                  <span>{{ categoryType.HolidayName }}</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'EDIT' | translate }}"
                    (click)="editHoliday(categoryType)"
                  >
                    <i class="icon ic-sm icon-pencil-tip"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-icon"
                    title="{{ 'DELETE' | translate }}"
                    (click)="deleteHoliday(categoryType.Id)"
                  >
                    <i class="icon ic-sm icon-trash"></i>
                  </button>
                </td>
              </tr>
            </ng-template>

            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td colspan="2">
                  {{ "NO_RECORDS_FOUND" | translate }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- Table ends -->
        </div>
      </div>
    </p-footer>
  </form>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
