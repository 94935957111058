import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Electro } from '../../models/electrocontract';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { ElectroInstallationBuliding, ElectroCourseOverview } from '../../models/electroinstallation';
import { SelectItem } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Location } from '@angular/common';
import { Status } from 'src/app/_models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'course-overview',
    templateUrl: 'course-overview.component.html'
})

export class CourseOverviewComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Input() courseOverview: ElectroCourseOverview;
    @Output() distributionChange: EventEmitter<any> = new EventEmitter<any>();
    contractId: number;
    electro: Electro;
    buildings: ElectroInstallationBuliding[];
    editMode = false;
    buildingDropdown: SelectItem[];
    editorName: string;
    page: string;
    createdOn: string;
    modifiedOn: string;
    courseDetails: any[];
    distributions: SelectItem[];
    cableMaterials: SelectItem[];
    distributionSystems: SelectItem[];
    earthTypes: SelectItem[];
    isGuestHideButton = true;
    inspectionId: number;
    formData: any;
    lastDist: number;
    showLoader = false;
    isTemplate = false;
    previousDist: number;
    onDistChangeSave: boolean;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private location: Location,
        private electroInstallationService: ElectroInstallationService) {
        this.formData = JSON.parse(sessionStorage.getItem('formData'));
        sessionStorage.removeItem('formData');
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
    }
    ngOnInit() {
        this.electro = this.route.snapshot.data['contractInfo'];
        this.inspectionId = +this.route.snapshot.parent.params['Iid'] ?
            +this.route.snapshot.parent.params['Iid'] : +this.route.snapshot.params['Iid'];
        this.courseDetails = [
            {
                'id': 0,
                'course': "",
                'loadDiscription': "",
                'protectionType': "",
                'protectionTin_a': "",
                'protectionTkar': "",
                'cableS': "",
                'cableL': "",
                'cableR': "",
                'rekkekl': "",/* order in English*/
                'JFB': ""
            }];//Default course details object structure.
        this.bindDropdowns();
        this.initForm(this.courseOverview);
    }

    /**
     * bind course form dropdown data.
     */
    bindDropdowns() {
        //Add buildings registered in contract to buildingDropdown.
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.buildingDropdown = [];
            this.buildingDropdown.push({ label: val.SELECT, value: null });
            if (this.buildings) {
                this.buildings.forEach((building: any) => {
                    this.buildingDropdown.push({ label: building.BuildingNumber, value: building.Id });
                });
            }
        }));
        //Add Cu,Al to power cableMaterials dropdown.
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.cableMaterials = [];
            this.cableMaterials.push({ label: val.SELECT, value: null });
            this.cableMaterials.push({ label: "Cu", value: 1 });
            this.cableMaterials.push({ label: "Al", value: 2 });
        }));
        //Add Distribution types to distributionSystems dropdown. 
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.distributionSystems = [];
            this.distributionSystems.push({ label: val.SELECT, value: null });
            this.distributionSystems.push({ label: "IT", value: 1 });
            this.distributionSystems.push({ label: "TN-C-S", value: 2 });
            this.distributionSystems.push({ label: "TN-S", value: 3 });
            this.distributionSystems.push({ label: "TT", value: 4 });
            this.distributionSystems.push({ label: "DC", value: 5 });
        }));
        //Add electrode types to earthTypes dropdown.
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.earthTypes = [];
            this.earthTypes.push({ label: val.SELECT, value: null });
            this.earthTypes.push({ label: "Spyd", value: 1 });
            this.earthTypes.push({ label: "Bånd/Tråd", value: 2 });
            this.earthTypes.push({ label: "Plate", value: 3 });
            this.earthTypes.push({ label: "Fundament", value: 4 });
            this.earthTypes.push({ label: "Armering i betong", value: 5 });
            this.earthTypes.push({ label: "Bånd/tråd + Fundament", value: 6 });
            this.earthTypes.push({ label: "Bånd/tråd + Spyd", value: 7 });
            this.earthTypes.push({ label: "Bånd/tråd + Armering", value: 8 });
            this.earthTypes.push({ label: "Bånd/tråd + Plate", value: 9 });
        }));
    }

    /**
     * course overview form initialization.
     * @param courseOverview 
     */
    initForm(courseOverview?: ElectroCourseOverview) {
        if (courseOverview) {
            this.previousDist = courseOverview.DistributionId;
            if (this.isTemplate) {
                this.electroInstallationService.getDistributionFloorByContract(courseOverview.ElectroContractId).subscribe(result => {
                    if (result) {
                        this.subscriptions.push(this.translate.stream('SELECT_DISTRIBUTION').subscribe(val => {
                            this.distributions = [];
                            this.distributions.push({ label: val.SELECT, value: null });
                            result.forEach((d: any) => {
                                this.distributions.push({
                                    label: d.Key + '(' + (d.DistTypeKey == 1 ? val.MAIN_DISTRIBUTION : val.SUB_DISTRIBUTION) + ')'
                                    , value: d.Value
                                });
                            });
                        }));
                        this.courseOverview.DistributionId = courseOverview.DistributionId;
                    }
                });
            }
            else {
                this.subscriptions.push(this.electroInstallationService.getDistributionFloorByBuilding(courseOverview.BuildingId, courseOverview.InspectionId)
                    .subscribe(res => {
                        if (res) {
                            this.subscriptions.push(this.translate.stream('SELECT_DISTRIBUTION').subscribe(val => {
                                this.distributions = [];
                                this.distributions.push({ label: val.SELECT, value: null });
                                res.forEach((d: any) => {
                                    this.distributions.push({
                                        label: d.Key + '(' + (d.DistTypeKey == 1 ? val.MAIN_DISTRIBUTION : val.SUB_DISTRIBUTION) + ')'
                                        , value: d.Value
                                    });
                                });
                            }));
                            this.courseOverview.DistributionId = courseOverview.DistributionId;
                        }
                    }))
            }
            this.courseOverview = courseOverview;
            this.courseOverview.Version = courseOverview.Version ? courseOverview.Version : 1;
            this.editorName = courseOverview.EditorName ? courseOverview.EditorName : '';
            if (courseOverview.CourseOverviewDetails) {
                this.courseDetails = <any>JSON.parse(courseOverview.CourseOverviewDetails);
            } else {
                this.courseDetails = [
                    {
                        'id': 0,
                        'course': "",
                        'loadDiscription': "",
                        'protectionType': "",
                        'protectionTin_a': "",
                        'protectionTkar': "",
                        'cableS': "",
                        'cableL': "",
                        'cableR': "",
                        'rekkekl': "",/* order in English*/
                        'JFB': ""
                    }];
            }
            //Converting a CreatedOn time to a string by using specified locale.
            if (this.courseOverview.CreatedOn) {
                this.createdOn = this.courseOverview.CreatedOn;
                let cDateObj = new Date(this.courseOverview.CreatedOn);
                this.courseOverview.CreatedOn = cDateObj.toLocaleDateString('en-GB');
            }
            //Converting a ModifiedOn time to a string by using specified locale.
            if (this.courseOverview.ModifiedOn) {
                this.modifiedOn = this.courseOverview.ModifiedOn;
                let mDateObj = new Date(this.courseOverview.ModifiedOn);
                this.courseOverview.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
            }
        }
        else {
            this.courseOverview = new ElectroCourseOverview();
            if (this.inspectionId && this.formData) {
                this.courseOverview.Version = this.formData.Version;
            }
            else {
                this.courseOverview.Version = 1;
            }
            this.courseDetails = [
                {
                    'id': 0,
                    'course': "",
                    'loadDiscription': "",
                    'protectionType': "",
                    'protectionTin_a': "",
                    'protectionTkar': "",
                    'cableS': "",
                    'cableL': "",
                    'cableR': "",
                    'rekkekl': "",/* order in English*/
                    'JFB': ""
                }];
        }
        this.courseOverview.DocumentNumber = "IKK-040504";
        this.page = "page 4 of 7";
    }

    /**
     * set type for the specified current.
     * @param current 
     * @param type 
     */
    currentTypeChecked(current, type) {
        switch (current) {
            case 1: // current is 1 represent IK_Max_Type
                this.courseOverview.IK_Max_Type = type;
                break;
            case 2: // current is 1 represent IK_Min_Type
                this.courseOverview.IK_Min_Type = type;
                break;
            case 3: // current is 1 represent IJ_Max_Type
                this.courseOverview.IJ_Max_Type = type;
                break;
            case 4: // current is 1 represent IJ_Min_Type
                this.courseOverview.IJ_Min_Type = type;
                break;
        }
    }

    /** add new course item to the course overview table.
     * @param item 
     */
    addItem(item) {
        let itemsToShift = this.courseDetails.splice(this.courseDetails.indexOf(item) + 1, this.courseDetails.length);
        let newItem = {
            'id': 0,
            'course': "",
            'loadDiscription': "",
            'protectionType': "",
            'protectionTin_a': "",
            'protectionTkar': "",
            'cableS': "",
            'cableL': "",
            'cableR': "",
            'rekkekl': "",/* order in English*/
            'JFB': ""
        };
        //push new item to course overview table.
        this.courseDetails.push(newItem);
        //push the spliced items after new item.
        itemsToShift.forEach(i => {
            this.courseDetails.push(i);
        });
    }

    /** delete the item in course overview table.
     * @param item 
     */
    deleteItem(item) {
        this.courseDetails.splice(this.courseDetails.indexOf(item), 1);
    }

    /* save course details.
     */
    saveCourse() {
        let curDist = this.courseOverview.DistributionId;
        if (this.previousDist && this.onDistChangeSave) {
            this.courseOverview.DistributionId = this.previousDist;
        }
        if (this.onDistChangeSave ? this.previousDist : true) {
            let onDistChangeSave = this.onDistChangeSave;
            this.courseOverview.CourseOverviewDetails = JSON.stringify(this.courseDetails);
            this.courseOverview.BusinessId = BaseServices.BusinessId;
            if (this.courseOverview.Id > 0) {
                this.courseOverview.CreatedOn = this.createdOn;
                this.courseOverview.ModifiedOn = this.modifiedOn;
                this.courseOverview.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.electroInstallationService.updateCourse(this.courseOverview).subscribe(result => {
                    if (result) {
                        if (result.CourseOverviewDetails)
                            this.courseDetails = JSON.parse(result.CourseOverviewDetails);
                        if (!onDistChangeSave)
                            this.toasterComponent.callToast();
                    }
                }));
            }
            else {
                this.courseOverview.InspectionId = this.inspectionId;
                this.courseOverview.CreatedBy = BaseServices.UserId;
                this.courseOverview.Status = Status.Open;
                this.subscriptions.push(this.electroInstallationService.saveCourse(this.courseOverview).subscribe(result => {
                    if (result) {
                        this.courseOverview.Id = result.Id;
                        this.createdOn = result.CreatedOn;
                        if (result.CourseOverviewDetails)
                            this.courseDetails = JSON.parse(result.CourseOverviewDetails);
                        if (!onDistChangeSave)
                            this.toasterComponent.callToast();
                    }
                }));
            }
        }
        this.onDistChangeSave = false;
        this.courseOverview.DistributionId = curDist;
    }

    /**
     * save course and back to installation forms tab
     */
    saveBackToList() {
        this.saveCourse()
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    onCourseDistSelect(event) {
        this.onDistChangeSave = true;
        if (event.value != this.lastDist) {
            this.distributionChange.emit(event.value);
        }
        this.lastDist = event.value;
    }

    onNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        const dots = e.target.value.indexOf('.');
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            dots > 1 ||
            (charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46)
        ) {
            e.preventDefault();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}