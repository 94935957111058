export class Client {
    Id: number;
    ContactTypeId: number;
    Name: string;
    Number: string;
    Address: string;
    Zip: string;
    Mobile: string;
    Fax: string;
    Telephone: string;
    Email: string;
    Location: string;
    CountryCode: string;
    Comment: string;
    BusinessId: number;
    Status: string;
    IsUser: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    OrganizationNumber: number;
    ClientNumber: number;
    Owner: string;
    CEO: string;
    NoOfEmployees: number;
    HomepageAddress: string;
    ClientLogo: string;
    IsElectro: boolean;
    Avatar: string;
    IsApprovalNotice: boolean;
    ApprovalDateTime: any;
}
export class User {
    Id: number;
    LanguageId: number;
    RoleId: number;
    ApplicationId: number;
    Mobile: string;
    BusinessId: number;
    Rights: string;
    FeatureId: number;
    FeatureKey: number;
    Username: string;
    Password: string;
    Email: string;
    Name: string;
    Avatar: string;
    Sso: string;
    SsoUsername: string;
    SsoDomain: string;
    SsoLoggedIn: string;
    NotificationUpdateOn: string;
    Status: string;
    CreatedBy: number;
    CountryCode:string;
}
export class ContactType {
    Id: number;
    Name: string;
}