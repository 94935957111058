import { ElCheckListServices } from './services/elChecklist.services';
import { ElCheckListComponent } from './components/elchecklistList.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { EcplChecklistViewerModule } from '../../shared/ecpl-checklist-viewer';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TableModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ContextMenuModule,
        MenuModule,
        DialogModule,
        ReactiveFormsModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        ButtonModule,
        NgxPaginationModule,
        EcplChecklistViewerModule,
    ],
    exports: [ElCheckListComponent],

    declarations: [ElCheckListComponent],
    providers: [ElCheckListServices]
})
export class ElChecklistModule {}
