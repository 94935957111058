<div class="main-content ">
  <div class="main-heading">
    <strong translate>OTHER_SYSTEMS_OF
      <span> - {{ pageTitle | capitalize}}</span>
    </strong>
    <span class="page-actions text-right">
      <button type="button" class="btn btn-outline-secondary" (click)="BackToZone()">
        <span translate>BACK_LIST</span>
      </button>
    </span>
  </div>
  <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="icId"></ecpl-tab-navigation>
  <div class="card">
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>