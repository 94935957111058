import { AboutComponent } from './about.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from '../shared/appShared.module';

@NgModule({
    imports: [CommonModule, RouterModule, 
        AppSharedModule
    ],
    exports: [AboutComponent],
    declarations: [AboutComponent],
    providers: []
})
export class AboutModule {}
