import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/nb'; // Import Norwegian locale

@Component({
  selector: 'app-activity-list-chart',
  templateUrl: './activity-list-chart.component.html',
  styleUrls: ['./activity-list-chart.component.css'],
})
export class ActivityListChartComponent implements OnInit {
  @Input() activities: any[];

  multi: any[] = [];
  view: any[];

  // Chart options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = 'Month';
  showYAxisLabel = false;
  yAxisLabel: string;
  legendTitle: string;
  animations = true;

  colorScheme = {
    domain: ['#0AB7E2', '#4DC55C', '#F0AC19', '#AAAAAA', '#E5634F'],
  };

  constructor(private translate: TranslateService) {
    moment.locale('nb'); // Set locale to Norwegian Bokmål
    this.yAxisLabel = this.translate.instant('ACTIVITIES');
    this.legendTitle = this.translate.instant('DESCRIPTION');
    this.customColors = this.customColors.bind(this);
  }

  ngOnInit(): void {
    this.updateChartData();
    this.translate.onLangChange.subscribe(() => {
      this.updateChartData(); // Update chart data when language changes
    });
  }

  onSelect(event) {
    console.log(event);
  }

  updateChartData(): void {
    const currentMonth = moment().startOf('month');
    const statusCategories = ['REJECTED', 'DONE', 'OPEN', 'NEW'];
    const translatedStatusCategories = statusCategories.map((status) =>
      this.translate.instant(status.toUpperCase())
    );

    const monthCounts = Array.from({ length: 12 }, () => ({
      [translatedStatusCategories[0]]: 0,
      [translatedStatusCategories[1]]: 0,
      [translatedStatusCategories[2]]: 0,
      [translatedStatusCategories[3]]: 0,
    }));

    const expiredCounts = {
      [translatedStatusCategories[2]]: 0, // Assuming 'OPEN' is the third item
    };

    this.activities.forEach((activity) => {
      const endDate = activity.EndDate ? moment(activity.EndDate) : null;
      const createdOn = moment(activity.CreatedOn);
      const dateToUse = endDate || createdOn;
      const monthDiff = dateToUse.diff(currentMonth, 'months');
      const status = this.translate.instant(activity.Status.toUpperCase());

      if (translatedStatusCategories.includes(status)) {
        if (monthDiff >= 0 && monthDiff < 12) {
          monthCounts[monthDiff][status]++;
        } else if (monthDiff < 0 && status === translatedStatusCategories[2]) {
          expiredCounts[status]++;
        }
      }
    });

    const chartData = [
      {
        name: this.translate.instant('OverDeadlineCount'),
        series: [
          {
            name: this.translate.instant('OverDeadlineCount'),
            value: expiredCounts[translatedStatusCategories[2]],
            extra: { isExpired: true },
          },
        ],
      },
      ...monthCounts.map((counts, index) => ({
        name: currentMonth.clone().add(index, 'months').format('MMM YYYY'),
        series: translatedStatusCategories.map((status, i) => ({
          name: status,
          value: counts[status],
          extra: { isExpired: false },
        })),
      })),
    ];

    this.multi = chartData;
  }

  customColors(name: string, extra: any): string {
    if (extra?.isExpired) {
      return '#E5634F';
    }
    switch (name) {
      case this.translate.instant('NEW'):
        return '#0AB7E2';
      case this.translate.instant('DONE'):
        return '#4DC55C';
      case this.translate.instant('OPEN'):
        return '#F0AC19';
      case this.translate.instant('REJECTED'):
        return '#AAAAAA';
      case this.translate.instant('OverDeadlineCount'):
        return '#E5634F';
      default:
        return '#AAAAAA'; // default color
    }
  }

  yAxisTickFormatting(value: number): string {
    return Number.isInteger(value) ? value.toString() : '';
  }
}
