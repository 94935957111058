import { kubaFeatureDetailsRoutes } from 'src/app/kuba/shared/kuba-feature-details/Kuba-feature-details.routes';
import { AuthGuard } from './../../_guards/auth.guard';
import { Route } from '@angular/router';
import {
    ServiceListResolver,
    ServiceEditResolver,
    ServiceAgreementListResolver,
    ServiceAgreementMyListResolver,
    ServiceAgreementFeaturesResolver,
    ServiceListByUserResolver
} from './services/service-agreement.resolver.service';
import { ServiceAgreementMyListComponent } from './components/service-agreement-mylist.component';
import { ServiceAgreementList } from './components/service-agreement-list.component';
import { ServiceAgreementFormComponent } from './components/service-agreement-form.component';
import { ServiceAgreementComponent } from './service-agreement.component';
import { ClientListResolver } from './../client/services/client.resolver.service';
import { AssignUserResolver, EmployeeContactUserSubscriptionResolver,
    EmployeeContactUserResolver
 } from './../users/services/user.resolver.service';

import { CommonViewComponent } from './../common/components/commonView.component';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';
import { ServiceAgreementViewComponent } from './components/service-agreement-view.component';
export const serviceagreementRoutes: Route[] = [
    {
        path: 'service-agreement',
        component: ServiceAgreementComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'new',
                component: ServiceAgreementFormComponent,
                resolve: {
                    clients: ClientListResolver,
                    users: EmployeeContactUserResolver,//AssignUserResolver,
                    serviceList: ServiceListResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver
                }
            },
            {
                path: 'edit/:sId',
                component: ServiceAgreementFormComponent,
                resolve: {
                    edit: ServiceEditResolver,
                    clients: ClientListResolver,
                    users: EmployeeContactUserResolver,//AssignUserResolver,
                    serviceList: ServiceListResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver
                }
            },
            {
                path: 'list',
                component: ServiceAgreementList,
                resolve: {
                    list: ServiceAgreementListResolver,
                    mylist: ServiceListByUserResolver
                }
            },
            {
                path: 'my-list',
                component: ServiceAgreementMyListComponent,
                resolve: {
                    mylist: ServiceListByUserResolver
                }
            },
            {
                path: 'details/:id',
                data: { parent: 'SA' },
                component: ServiceAgreementViewComponent, // CommonViewComponent,
                resolve: {
                    additionalFeatures: ServiceAgreementFeaturesResolver
                },
                canActivate: [AuthGuard],
                children: [
                    ...kubaFeatureDetailsRoutes,
                    ...FeatureDocumentRoutes,
                    ...deviationRoutes,
                    ...taskmanagerRoutes,
                    ...timetableRoutes,
                    ...safetyjobRoutes,
                    ...registrationRoutes
                ]
            }
        ]
    }
];
