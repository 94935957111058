export class ClientContact {
    Id: number;
    ClientId: number;
    Name: string;
    Mobile: string;
    CountryCode: string;
    Email: string;
    IsSms: string;
    IsEmail: string;
    IsUser: boolean;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    IsUserRole: boolean;
    Avatar: string;
    IsApprovalNotice: boolean;
    ApprovalDateTime: any;
}
