<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table  [value]="frameworklist" #frameworkTable [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
             exportFilename="AllFramewoekList">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="frameworkTable.filteredValue || frameworkTable.value" [additionalData]="additionalData"></kuba-export>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="ContractNumber">{{'CONTRACT_NUMBER' | translate}} <p-sortIcon field="ContractNumber"></p-sortIcon></th>
                    <th pSortableColumn="ContractName">{{'CONTRACT_NAME'| translate}} <p-sortIcon field="ContractName"></p-sortIcon></th>
                    <th pSortableColumn="Client">{{'CLIENT' | translate }} <p-sortIcon field="Client"></p-sortIcon></th>
                    <th pSortableColumn="Status">{{'STATUS'| translate}} <p-sortIcon field="Status"></p-sortIcon></th>
                    <th>{{'IMAGE'| translate}}</th>
                    <th pSortableColumn="CreatedName">{{'CREATED_BY'| translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
                    <th pSortableColumn="ModifierName">{{'EDITED_BY'| translate}} <p-sortIcon field="ModifierName"></p-sortIcon></th>
                    <th *ngIf="isGuest">{{'OPTIONS'| translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="frameworkTable.filter($event.target.value, 'ContractNumber', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="frameworkTable.filter($event.target.value, 'ContractName', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="frameworkTable.filter($event.target.value, 'Client', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="frameworkTable.filter($event.target.value, 'Status', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-framework>
                <tr>
                    <td>
                        <span>{{framework.ContractNumber}}</span>
                    </td>
                    <td>
                        <a [routerLink]="['./../../details',framework.Id]">{{framework.ContractName}}</a>
                    </td>
                    <td>
                        <span>{{framework.Client}}</span>
                    </td>
                    <td>
                        <span>{{framework.Status | translate}}</span>
                    </td>
                    <td>
                        <span>{{framework.ContractImage}}</span>
                    </td>
                    <td>
                        <span>{{framework.CreatedName}}</span>
                    </td>
                    <td>
                        <span>{{framework.ModifierName}}</span>
                    </td>
                    <td class="col-button" *ngIf="isGuest">
                        <button type="button" class="btn btn-icon" [routerLink]="['./../../edit',framework.Id]" title="{{'EDIT' | translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" class="btn btn-icon" (click)="deleteFramework(framework['Id'])" title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" *ngIf="isGuest" let-columns>
                <tr>
                    <td colspan="8">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425" acceptLabel = "{{'SWITCH_YES'|translate}}" rejectLabel = "{{'SWITCH_NO'|translate}}"></p-confirmDialog>