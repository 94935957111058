<header>
  <div
    *ngIf="showWarningMsg"
    class="w-100 bg-primary text-center"
    style="padding: 8px 0"
  >
    <p style="margin: 0">
      <b style="font-size: 16px">{{ warningMsg }}</b>
    </p>
  </div>
  <!--header (language & User Panel)-->
  <div class="top-header">
    <header-top></header-top>
  </div>
  <!--Logo Header (Header)-->
  <!-- <div class="logos-block"> -->
  <!-- </div> -->
</header>
<div class="main-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!--Application navigation-->
        <navigation [urlBase]="baseUrl"></navigation>
        <div class="app-content">
          <div class="content-wrapper">
            <div class="shimmer-overlay" *ngIf="placeholder">
              <div class="smr-content">
                <div class="smr-container">
                  <div class="smr-content-main">
                    <div class="smr-content-grid smr-b-mask">
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-tl"
                            >
                              <path
                                d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                              ></path></svg
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-tr"
                            >
                              <path
                                d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                              ></path></svg
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-br"
                            >
                              <path
                                d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                              ></path></svg
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-bl"
                            >
                              <path d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"></path>
                            </svg>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-tl"
                            >
                              <path
                                d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                              ></path></svg
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-tr"
                            >
                              <path
                                d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                              ></path></svg
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-br"
                            >
                              <path
                                d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                              ></path></svg
                            ><svg
                              width="3"
                              height="3"
                              class="smr-mask smr-mask-bl"
                            >
                              <path
                                d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                              ></path></svg
                          ></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                      <div class="smr-cg-item smr-b-mask">
                        <span class="smr-b-mask">
                          <span class="smr-b-mask">
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                            <span class="smr-b-mask"
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tl"
                              >
                                <path
                                  d="M0 3 A 3 3, 0, 0, 1, 3 0 L 0 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-tr"
                              >
                                <path
                                  d="M0 0 A 3 3, 0, 0, 1, 3 3 L 3 0 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-br"
                              >
                                <path
                                  d="M3 0 A 3 3, 0, 0, 1, 0 3 L 3 3 Z"
                                ></path></svg
                              ><svg
                                width="3"
                                height="3"
                                class="smr-mask smr-mask-bl"
                              >
                                <path
                                  d="M3 3 A 3 3, 0, 0, 1, 0 0 L 0 3 Z"
                                ></path></svg
                            ></span>
                          </span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask"></span>
                          <span class="smr-b-mask smr-mask"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
