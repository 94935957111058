import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  template: `<router-outlet></router-outlet>`
})
export class ServiceAgreementComponent {
  constructor(private http: HttpClient, private translate: TranslateService) { }
  ngOnInit(): void {
    document.querySelector("body").classList.remove("opened");
  }
}
