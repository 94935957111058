//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem, ConfirmationService, } from 'primeng/api';
import { Options } from './../../../_models/options';
import { BaseServices } from './../../kuba.services';
import { MeasureProtocolService } from './../services/measure-protocol.services';
import { MPClientUsers } from './../models/measure-protocol';
import { MP } from './../models/measure-protocol';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-form',
    templateUrl: 'measure-protocol-form.component.html'
})

export class MeasureProtocolFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolForm: FormGroup;
    clients: SelectItem[];
    mpClientUsers: MPClientUsers[];

    contracterId: number;
    kundeELId: number;
    isEditMode = false;
    createdOn: Date;
    createdBy: number;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private MeasureProtocolService: MeasureProtocolService,
        private confirmationService: ConfirmationService,
        private location: Location,
        private translate: TranslateService) {
        this.measureProtocolForm = this.fb.group({
            'Number': ['', Validators.required],
            'Name': ['', Validators.required],
            'ClientId': ['']
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.contracterId = this.route.snapshot.params['id'];
        this.kundeELId = this.route.snapshot.parent.parent.params['id'];
        this.bindFormDropdowns();
        let res = this.route.snapshot.data['edit'];
        if (res) {
            this.isEditMode = true;
            this.initForm(res);
        } else {
            this.initForm();
        }

        this.subscriptions.push(this.MeasureProtocolService.getMeasureProtocolById(this.contracterId, this.kundeELId).subscribe((mpInfo: any) => {
            if (mpInfo) {
                this.createdOn = mpInfo.CreatedOn;
                this.createdBy = mpInfo.CreatedBy;
            }
        }));
    }

    onClientChanged(event, clientDropdown) {
        this.getContactByClientId(event.value);
    }

    getContactByClientId(clientId: number) {
        if (!clientId) {
            clientId = 0;
        }
        this.subscriptions.push(this.MeasureProtocolService.getClientContactUser(clientId)
            .subscribe((result: any) => {
                this.mpClientUsers = result;
            }))
    }
    /** 
    *Binding Dropdowns
    **/
    bindFormDropdowns() {
        this.clients = [];
        let Clients = this.route.snapshot.data['clients'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.clients = [];
            this.clients.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (Clients) {
            Clients.forEach(element => {
                this.clients.push({ label: element.Name, value: element.Id })
            });
        }
    }

    /**
     * save and create new button click event
     */
    saveAndCreateNew() {
        this.saveChanges();
        this.clear();
    }

    /**
     * clear form data
     */
    clear() {
        this.initForm();
        this.contracterId = 0;
    }

    /**Save form**/
    saveChanges() {
        let mp = new MP();
        mp.Id = (this.contracterId) ? this.contracterId : 0;
        mp.Number = this.measureProtocolForm.value.Number;
        mp.Name = this.measureProtocolForm.value.Name;
        mp.ClientId = this.measureProtocolForm.value.ClientId;
        mp.KundeELId = this.kundeELId;
        mp.Status = 1;
        mp.FeatureId = FeatureKey.KUNDE_EL;
        if (mp.Id > 0) {
            mp.CreatedOn = this.createdOn;
            mp.CreatedBy = this.createdBy;
            mp.ModifiedOn = new Date();
            mp.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.MeasureProtocolService
                .update(this.kundeELId, mp)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }));
        } else {
            mp.CreatedOn = new Date();
            mp.CreatedBy = BaseServices.UserId;
            mp.ModifiedOn = null;
            mp.ModifiedBy = null;
            this.subscriptions.push(this.MeasureProtocolService
                .create(this.kundeELId, mp)
                .subscribe((result) => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                    this.gotoBack();
                }));

        }
    }

    initForm(data?: any) {
        let Number = '';
        let Name = '';
        let client = '';

        if (data) {
            Name = data.Name;
            Number = data.Number;
            client = data.ClientId;
            this.getContactByClientId(+client);

            this.measureProtocolForm = this.fb.group({
                Number: new FormControl(Number, Validators.required),
                Name: new FormControl(Name, Validators.required),
                ClientId: new FormControl(client),
            });
        }
    }

    gotoBack() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

