import { Rights } from 'src/app/_models';
import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ConfirmationService, SelectItem } from 'primeng/api';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ActivatedRoute } from '@angular/router';
import { LarModel, LarEvent } from './intefaces';
import { Options } from './../../_models/options';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

export class Link {
    title: string;
    url: string;
    constructor() { }
}
@Component({
    selector: 'ecpl-lar-viewer',
    templateUrl: './ecpl-lar-viewer.component.html'
})
export class EcplLarViewerComponent implements OnInit, OnChanges {
    @Input() larSrc: any;
    @Input() name: string;
    @Input() mode: string;
    @Input() formTitle: string;
    @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();

    larList: any = [];
    newLink: Link = new Link();
    larDailog = false;
    createLarForm: FormGroup;
    larDetails: LarModel;
    larEvent: LarEvent;
    larId = 0;
    options = [
        new Options(0, 'PUBLIC'),
        new Options(1, 'PRIVATE')
    ];
    title: string;
    constructor(private formBuilder: FormBuilder
        , private sanitizer: DomSanitizer
        , private confirmationService: ConfirmationService,
        private translate: TranslateService) {
        this.createLarForm = this.formBuilder.group({
            title: ['', Validators.required],
            url: ['', Validators.compose([Validators.required])],
            status: ['0']
        });
    }

    ngOnInit() {
        if (this.name === 'laws') {
            this.title = 'Add Law';
        } else if (this.name === 'regulation') {
            this.title = 'Add Regulation';
        } else if (this.name === 'videos'){
            this.title = 'Add Videos';
        }
        this.larList = [];
        if (this.larSrc) {
            this.larList.push(...this.larSrc.laws || []);
        }
    }

    ngOnChanges() {
        if (this.name === 'laws') {
            this.title = 'Add Law';
        } else if (this.name === 'regulation') {
            this.title = 'Add Regulation';
        } else if (this.name === 'videos'){
            this.title = 'Add Videos'
        }
        this.larList = [];
        if (this.larSrc) {
            this.larList.push(...this.larSrc.laws || []);
        }
    }

    saveLar() {
        let yourRegularExpression = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
        let result = this.createLarForm.value.url.match(yourRegularExpression);
        if (result) {
            let larModel = new LarModel();
            let larSave = this.createLarForm.value
            if (this.larId === 0) {
                larModel.title = larSave.title;
                larModel.url = larSave.url;
                if (larModel.url.toLowerCase().lastIndexOf('https://') < 0
                    && larModel.url.toLowerCase().lastIndexOf('http://') < 0) {
                    larModel.url = 'https://' + larModel.url;
                }
                larModel.isCommon = larSave.status === '0' ? true : false
                larModel.isLaws = this.name === 'laws' ? true : false
                this.larEvent = {
                    eventName: 'NewLar',
                    item: {
                        larContent: larModel
                    }
                };
            } else {
                larModel.title = larSave.title;
                larModel.url = larSave.url;
                if (larModel.url.toLowerCase().lastIndexOf('https://') < 0
                    && larModel.url.toLowerCase().lastIndexOf('http://') < 0) {
                    larModel.url = 'https://' + larModel.url;
                }
                larModel.isCommon = larSave.status === '0' ? true : false
                larModel.isLaws = this.name === 'laws' ? true : false
                this.larEvent = {
                    eventName: 'SaveLar',
                    item: {
                        larId: this.larId,
                        larContent: larModel
                    }
                };
            }
            this.larDailog = false;
            this.saveChanges.emit(this.larEvent);
        } else {
            alert('Enter valid url.');
        }
    }

    /**
    * save and update
    editLar(id) {
    * @param  data
    */
    editLar(data: any) {
        if (this.name === 'laws') {
            this.title = this.translate.instant("UPDATE_LAW");
        } else if (this.name === 'videos') {
            this.title = 'Update Videos';
        } else {
            this.title = 'Update Regulation';
        }
        this.larDailog = true;
        let title = data.title;
        let url = data.url;
        let status = data.isCommon === true ? '0' : '1';
        this.larId = +data.id;
        let larSave = this.createLarForm.value
        this.createLarForm = this.formBuilder.group({
            title: new FormControl(title),
            url: new FormControl(url),
            status: new FormControl(status),
        });
    }

    /**
     * Show dialog for save laws and regulatioh
     */
    showLarDailog() {
        this.larDailog = true;
    }

    newForm() {
        if (this.name === 'laws') {
            this.title = this.translate.instant("ADD_LAW");
        } else if (this.name === 'videos') {
            this.title = this.translate.instant("ADD_VIDEOS");
        } else {
            this.title = this.translate.instant("ADD_REGULATION");
        }
        this.larId = 0;
        this.createLarForm = this.formBuilder.group({
            title: ['', Validators.required],
            url: ['', Validators.required],
            status: ['0']
        });
        this.larDailog = true;
    }

    /**
    * Cancel Show dialog
    */
    cancelClose() {
        this.larDailog = false;
    }

    /**
    * delete service based on id
    * @param  id
    */
    deleteLar(id:any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.larEvent = {
                    eventName: 'DeleteLar',
                    item: {
                        larId: id
                    }
                };
                this.larDailog = false;
                this.saveChanges.emit(this.larEvent);
            }
        });
    }

    openURL(url: any) {
        window.open(url, '_blank');
    }

    ValidateWhiteSapce(title:any) {
        let str = title;
        if (str.trim() === '') {
            return true;
        } else {
            return false;
        }
    }
}
