import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { SelectItem } from 'primeng/api';
import { BusinessPropertyService } from './../services/businessProperty.service';
import { BusinessPropertyType } from '../models/businessProperty';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'business-property-objects.component.html'
})
export class BusinessPropertyObjectsComponent implements OnInit {
    businessId: number;
    businessPropertyList: any;
    propertyType: SelectItem[];
    listOfProperty: BusinessPropertyType[];
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private busPropertyService: BusinessPropertyService
    ) {
        this.businessId = this.route.snapshot.parent?.params['bid'];
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.propertyType = [];
        this.subscriptions.push(this.busPropertyService
            .list(this.businessId)
            .subscribe(propertyList => {
                if (propertyList) {
                    this.businessPropertyList = propertyList;
                }
            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
