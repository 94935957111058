<form [formGroup]="AutoWizLoginform">
    <div class="login-wrapper">
        <div class="login-panel">
            <div class="login-panel-body">
                <div class="logo">
                    <img src="assets/images/KubaLogo.png" alt="">
                </div>
                <div>
                    <div class="form-group">
                        <label class="col-form-label">Username</label>
                        <input type="text" pInputText formControlName="UserName" id="UserName" />
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input type="password" pInputText formControlName="Password" id="Password" />
                    </div>
                    <button class="btn btn-primary btn-block" (click)="checkAutoWizUser()">Save</button>
                </div>
            </div>
        </div>
    </div>
</form>