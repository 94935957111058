import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import { ProductionComponent } from './production.component';
import { ProductionFormComponent } from './components/production-form.component';
import { ProductionListComponent } from './components/production-list.component';
import {
    ProductionListResolver,
    ProductionEditResolver,
    ProductionTypeListResolver, ReceptionDropdownResolver
} from './services/production.resolver.service';
import { ClientListByBusinessResolver } from './../client/services/client.resolver.service';
export const productionRoutes: Route[] = [{
    path: 'production',
    component: ProductionComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: ProductionListComponent,
            canActivate: [AuthGuard],
            resolve: {
                list: ProductionListResolver,
            }
        },
        {
            path: 'edit/:prodId',
            component: ProductionFormComponent,
            canActivate: [AuthGuard],
            resolve: {
                typeDetails: ProductionTypeListResolver,
                clients: ClientListByBusinessResolver,
                receptions: ReceptionDropdownResolver,
                details: ProductionEditResolver
            }
        }]
}];


