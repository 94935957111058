<div class="tab-content">
    <div class="page-title">
        <span translate>CONSUMPTION</span>
        <span class="page-actions">
            <a routerLink="new" [routerLink]="['./new',0]" *ngIf="isVisible" class="btn btn-success"
                routerLinkActive="active">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table #consumptionTable [value]="consumptiondata" [rows]="10" [paginator]="true" [pageLinks]="3"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}"
        [rowsPerPageOptions]="[5,10,20]">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="filter-controls-wrapper" style="display:none">
                        <form class="form">
                            <div class="row">
                                <div class="col-md-3" [hidden]="isBusiness">
                                    <label for="nofemp" class="col-form-label">
                                        <span translate>CHOOSE_BUSINESS</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <p-dropdown [options]="businessName" (onChange)="onBusinessChanged($event)"
                                            #businessDropdown [filter]="true" [style]="{'width':'100%'}"></p-dropdown>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <form [formGroup]="consumptionSearch">
                                            <label for="nofemp" class="col-form-label">
                                                <span translate>CONSUMPTION_TYPE</span>
                                            </label>
                                            <div class="input-btn-group">
                                                <p-dropdown [options]="consumptionTypes" formControlName="type"
                                                    (onChange)="onConsumptionTypeChanged(ct)" #ct
                                                    [style]="{'width':'100%'}" [(ngModel)]="consumptionTypeFilter">
                                                    <ng-template let-type pTemplate="item"> {{ type.label}}
                                                    </ng-template>
                                                </p-dropdown>
                                                <button type="button" class="btn btn-outline-primary"
                                                    (click)="addType=!addType" *ngIf="isEditor">
                                                    <i class="pi pi-plus"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                                <button class="btn p-3 filter-toggle" >
                                    <span class="icon ic-sm icon-filter"></span>
                                    <span translate>FILTER</span>
                                </button>
                            <div class="action-btns-group">
                                <kuba-export [reportData]="consumptionTable.filteredValue || consumptionTable.value"
                                    [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="Date">{{'DATE'|translate}} <p-sortIcon field="Date"></p-sortIcon></th>
                    <th pSortableColumn="TodaysNumber">{{'READING'|translate}} <p-sortIcon field="TodaysNumber"></p-sortIcon></th>
                    <th>{{'DAYS'|translate}}</th>
                    <th pSortableColumn="EstimatedNumber">{{'NUMBER_USED'|translate}} <p-sortIcon field="EstimatedNumber"></p-sortIcon></th>
                    <th>{{'PRICE'|translate}}</th>
                    <th>{{'PRICE_DAY'|translate}}</th>
                    <th *ngIf="isVisible">{{'OPTIONS'| translate}}</th>
                </tr>

            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-consumption let-i="rowIndex">
                <tr>
                    <td><span>{{consumption.Date | date : 'dd/MM/yyyy'}}</span></td>
                    <td><span>{{consumption.TodaysNumber }}</span></td>
                    <td> <span>{{ setNoOfDays(consumptiondata,i) }}</span></td>
                    <td><span>{{consumption.EstimatedNumber}}</span></td>
                    <td><span>{{ decimalCalculation(consumptiondata, i) }}</span></td>
                    <td> <span>{{ pricePerDayCalculation(consumptiondata,i) }}</span></td>
                    <td class="col-button" *ngIf="isVisible">
                        <a routerLink="new" [routerLink]="['./new',consumption.Id]" class="btn btn-icon"
                            routerLinkActive="active" title="{{'EDIT' | translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </a>
                        <button type="button" class="btn btn-icon" (click)="confirm(consumption.Id)"
                            title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns *ngIf="isVisible">
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!isVisible">
                    <td colspan="6">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
        <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
            acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
        <div class="action-btns-wrapper">
            <div class="row">
                <button type="button" class="btn btn-outline-primary" *ngIf="isReport" style="margin-left: 14px;"
                    (click)="barChart()" translate>VIEW_REPORT</button>
            </div>
        </div>
    </div>
    <p-dialog header="{{'NEW_TYPE' | translate}}" [(visible)]="addType" [style]="{width: '300px'}" [resizable]="false" [draggable]="false"
        (onHide)="onBeforeConsumptionTypeDialogHide()">
        <form [formGroup]="consumptionPopUp">
            <div class="form-group">
                <label for="title" class="col-form-label">
                    <span translate>TYPE_NAME</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" #title class="label-input form-control" formControlName="Name">
                </div>
                <control-messages [control]="consumptionPopUp.controls['Name']"></control-messages>
            </div>
            <label for="consumptionUnitAlert" color class="col-form-label" *ngIf="isConsumptionTypeExsist"
                [ngStyle]="{'color': 'red'}">
                <span translate>CONSUMPTION_EXIST</span>
            </label>
        </form>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType">
                    <span translate>VIEW</span>
                </button>
                <button type="button" class="btn btn-primary" [disabled]=!consumptionPopUp.valid (click)="saveType()">
                    <span translate>SAVE</span>
                </button>
            </div>

            <div *ngIf="viewType">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #client [value]="editConsumptions" [rows]="10" [paginator]="true" [pageLinks]="3"
                        [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'TYPE_NAME' | translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>
                                    <input pInputText type="text"
                                        (input)="client.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                        class="p-column-filter">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-categoryType>
                            <tr>
                                <td>
                                    <span>{{categoryType.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                        (click)="editType(categoryType)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                        (click)="deleteType(categoryType)">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table Ends -->
                </div>
            </div>
        </p-footer>
    </p-dialog>
    <div class="row">
        <div class="col-sm-6">
            <p-chart type="bar" [hidden]="showConsumptionBarChart" [data]="data"></p-chart>
        </div>
    </div>
</div>
<toaster-component></toaster-component>