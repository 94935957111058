import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroRiskAssessment } from '../../models/electroinstallation';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'risk-assessment',
    templateUrl: 'risk-assessment.component.html'
})

export class RiskAssessmentComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Input() riskAssessment: ElectroRiskAssessment;
    editMode = false;
    editorName: string;
    page: string;
    createdOn: string;
    modifiedOn: string;
    isGuestHideButton = true;
    contractId: number;
    elementsConsidered: { 'id': number; 'elements': string; 'comments': string; }[];
    inspectionId: number;
    formData: any;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private translate: TranslateService,
        private location: Location,
        private electroInstallationService: ElectroInstallationService) {
        this.formData = JSON.parse(sessionStorage.getItem('formData'));
        sessionStorage.removeItem('formData');
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
    }
    //electro: Electro;
    ngOnInit() {
        this.inspectionId = +this.route.snapshot.parent.params['Iid'] ?
            +this.route.snapshot.parent.params['Iid'] : +this.route.snapshot.params['Iid'];
        this.contractId = this.route.snapshot.parent.parent.params['cid'];
        let riskAssessment = this.route.snapshot.data['riskAssessment'];
        this.elementsConsidered = [];
        this.elementsConsidered.push(
            { id: 1, elements: this.translate.instant('COMPETENCE_INSTRUCTED_PERSONNEL'), comments: "" },
            { id: 2, elements: this.translate.instant('LOCKING_DISTRIBUTION_BOARDS'), comments: "" },
            { id: 3, elements: this.translate.instant('STATE_LIGHTING_EQUIPMENT'), comments: "" },
            { id: 4, elements: this.translate.instant('STATE_MOVING_WIRES'), comments: "" },
            { id: 5, elements: this.translate.instant('PCB_CONTAINING_CAPACITORS'), comments: "" },
            { id: 6, elements: this.translate.instant('DANGER_SHORT_CIRCUIT'), comments: "" },
            { id: 7, elements: this.translate.instant('DANGER_INJURY_ACCIDENT'), comments: "" },
        );
        this.initForm(this.riskAssessment);

    }

    /**
     * initialize form group and supply values to control on edit.
     * @param riskAssessment 
     */
    initForm(riskAssessment?: ElectroRiskAssessment) {
        if (riskAssessment) {
            this.riskAssessment = riskAssessment;
            this.riskAssessment.Version = riskAssessment.Version ? riskAssessment.Version : 1;
            this.editorName = riskAssessment.EditorName ? riskAssessment.EditorName : '';
            if (this.riskAssessment.CreatedOn) {
                this.createdOn = this.riskAssessment.CreatedOn;
                let cDateObj = new Date(this.riskAssessment.CreatedOn);
                this.riskAssessment.CreatedOn = cDateObj.toLocaleDateString('en-GB');
            }
            if (this.riskAssessment.ModifiedOn) {
                this.modifiedOn = this.riskAssessment.ModifiedOn;
                let mDateObj = new Date(this.riskAssessment.ModifiedOn);
                this.riskAssessment.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
            }
            this.elementsConsidered = <any>JSON.parse(this.riskAssessment.OtherMatters);

        }
        else {
            this.riskAssessment = new ElectroRiskAssessment();
            if (this.inspectionId && this.formData) {
                this.riskAssessment.Version = this.formData.Version;
            }
            else {
                this.riskAssessment.Version = 1;
            }
        }
        this.riskAssessment.DocumentNumber = "IKK-040502";
        this.page = "page 2 of 7";
    }

    /**
     * add new element
     * @param item 
     */
    addItem(item) {
        let itemsToShift = this.elementsConsidered.splice(this.elementsConsidered.indexOf(item) + 1, this.elementsConsidered.length);
        let newItem = { 'id': item.id + 1, 'elements': "", 'comments': "" };
        this.elementsConsidered.push(newItem);
        itemsToShift.forEach(i => {
            ++i.id;
            this.elementsConsidered.push(i);
        });
    }

    /**
     * delete specific element
     * @param item 
     */
    deleteItem(item) {
        this.elementsConsidered.splice(this.elementsConsidered.indexOf(item), 1);
    }
    /**
     * current type checkbox event.
     * @param current 
     * @param type 
     */
    currentTypeChecked(current, type) {
        switch (current) {
            case 1: // current is 1 represent DistributionDataAvail
                this.riskAssessment.DistributionDataAvail = type;
                break;
            case 2: // current is 1 represent EarthType
                this.riskAssessment.EarthType = type;
                break;
            case 3: // current is 1 represent OverVoltageProtection
                this.riskAssessment.OverVoltageProtection = type;
                break;
            case 4: // current is 1 represent BoardFuseType
                this.riskAssessment.BoardFuseType = type;
                break;
        }
    }

    /**
     * cuurent multi check event.
     * @param value 
     * @param type 
     */
    currentTypemultiChecked(value, type) {
        switch (type) {
            case 1:
                this.riskAssessment.VoltageSystemIT = value ? true : false;
                break;
            case 2:
                this.riskAssessment.VoltageSystemTNCS = value ? true : false;
                break;
            case 3:
                this.riskAssessment.VoltageSystemTNS = value ? true : false;
                break;
            case 4:
                this.riskAssessment.VoltageSystemTT = value ? true : false;
                break;
            case 5:
                this.riskAssessment.EarthMonitorType1 = value ? true : false;
                break;
            case 6:
                this.riskAssessment.EarthMonitorType2 = value ? true : false;
                break;
            case 7:
                this.riskAssessment.EarthMonitorType3 = value ? true : false;
                break;
        }
    }
    saveRiskAssessment() {
        this.riskAssessment.OtherMatters = JSON.stringify(this.elementsConsidered);
        this.riskAssessment.BusinessId = BaseServices.BusinessId;
        if (this.riskAssessment.Id > 0) {
            this.riskAssessment.CreatedOn = this.createdOn;
            this.riskAssessment.ModifiedOn = this.modifiedOn;
            this.riskAssessment.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.updateRiskAssessment(this.riskAssessment).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            this.riskAssessment.InspectionId = this.inspectionId;
            this.riskAssessment.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.saveRiskAssessment(this.riskAssessment).subscribe(result => {
                if (result) {
                    this.riskAssessment.Id = result.Id;
                    this.createdOn = result.CreatedOn;
                    this.toasterComponent.callToast();
                }
            }));
        }
    }

    saveBackToList() {
        this.saveRiskAssessment()
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}