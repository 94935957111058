import { EmployeeDocumentNode } from './employeeDocument';

export class EmployeeCourse {
  isEmployeecourse:boolean;
  Id: number;
  BusinessId: number;
  BusinessCourseId: number;
  EmployeeId: number;
  Institute: string;
  Employee: string;
  Department: string;
  RenewalDateForFilter: string;
  StartDate: string;
  EndDate: string;
  Renewaldate: string;
  Course: string;
  Description: string;
  EmployeeDocument: EmployeeCourseDocumentNode[];
  EmployeDocuments: number;
  ExecutionResponsible: string;
  ResponsiblepersonName: string;
  ResponsibleId: number;
  DeadlineNotification: number;
  Status: string;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedOn: string;
  DepartmentName: EmployeeList[];
  EmployeeList: EmployeeList[];
  BusinessCourse: BusinessCourse;
  LanguageId: number;
  Renewal_Date: string;
}
export class EmployeeCourseDocumentNode {
  Id: number;
  EmployeeId: number;
  CourseId: number;
  FileName: string;
  OriginalFileName: string;
  MimeType: string;
  Size: number;
  Path: string;
  FileType: number;
  IsPrivateDocument: boolean;
  Status: string;
}

export class EmployeeList {
  Id: number;
  Number: number;
  Name: string;
  DepartmentName: string;
  Mobile: number;
  Email: string;
  PositionName: string;
  Status: string;
  Avatar: string;
  BusinessId: string;
}
export class BusinessCourse {
  Id: number;
  Title: string;
  Description: string;
  BusinessId: number;
  Status: string;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date;
  portalId: number;
  AppId: number;
  IsEmployeeCourse: boolean;
  isEmployeecourse:boolean;
}

export class ResponsibleExecution {
  Id: number;
  Name: string;
}
