import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { MeasureProtocolComponent } from './measure-protocol.component';
import { MeasureProtocolListComponent } from './components/measure-protocol-list.component';
import { MeasureProtocolFormComponent } from './components/measure-protocol-form.component';
import { MeasureProtocolInstrumentListComponent } from './components/Instrument/measure-protocol-instrument-list.component';
import { MeasureProtocolInstrumentFormComponent } from './components/Instrument/measure-protocol-instrument-form.component';
import { MeasureProtocolUsedInstrumentComponent } from './components/MPComplete/measure-protocol-used-instruments.component';
import { MeasureProtocolVisualControlComponent } from './components/MPComplete/measure-protocol-visual-control.component';
import { MeasureProtocolEarthLeakageComponent } from './components/MPComplete/measure-protocol-earth-leakage.component';
import { MeasureProtocolPowerLeakageComponent } from './components/MPComplete/measure-protocol-power-leakage.component';
import { MeasureProtocolTemperatureComponent } from './components/MPComplete/measure-protocol-temperature.component';
import { MeasureProtocolCommentsComponent } from './components/MPComplete/measure-protocol-comments.component';
import { MeasureProtocolCompleteFormComponent } from './components/measure-protocol-complete-form.component';
import {
          MeasureProtocolListResolver,
          CompleteMeasureProtocolListResolver,
          MeasureProtocolEditResolver,
          InstrumentListResolver,
          InstrumentEditResolver,
          MeasureProtocolCompleteEditResolver,
          TypeListResolver,
          ModelListResolver,
          CMPInstrumentListResolver,
          PowerLeakageListResolver,
          EarthLeakageListResolver,
          TemperatureTypeListResolver,
          TemperatureModelListResolver,
          CMPTemperatureListResolver,
          VisualControlResolver
} from './services/measure-protocol.resolver.service';
import { ClientListResolver } from 'src/app/kuba/projects/services/project.resolver';
import { MPCDetailsComponent } from 'src/app/kuba/measure-protocol/components/MPComplete/mpc-details.component';
export const measureprotocolRoutes: Route[] = [{
  path: 'measureprotocol',
  component: MeasureProtocolComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: 'list',
      component: MeasureProtocolListComponent,
      resolve: {
        listMP: MeasureProtocolListResolver,
        listMPComplete: CompleteMeasureProtocolListResolver

      }
    },
    {
      path: 'new',
      component: MeasureProtocolFormComponent,
      resolve: {
        clients: ClientListResolver
      }
    },
    {
      path: 'edit/:id',
      component: MeasureProtocolFormComponent,
      canActivate: [AuthGuard],
      resolve: {
        edit: MeasureProtocolEditResolver,
        clients: ClientListResolver,
      }
    },
    {
      path: 'completeprotocol/edit/:id',
      component: MeasureProtocolCompleteFormComponent,
      canActivate: [AuthGuard],
      resolve: {
        edit: MeasureProtocolCompleteEditResolver,
      }

    },
    {
      path: 'instrument/:mid',
      component: MeasureProtocolComponent,
      canActivate: [AuthGuard],
      children: [

        {
          path: 'list',
          component: MeasureProtocolInstrumentListComponent,
          resolve: {
            listInst: InstrumentListResolver,
          }
        },
        {
          path: 'new',
          component: MeasureProtocolInstrumentFormComponent,
          resolve: {
            instrumentType: TypeListResolver,
            modelType: ModelListResolver,
          }
        },
        {
          path: 'edit/:id',
          canActivate: [AuthGuard],
          component: MeasureProtocolInstrumentFormComponent,
          resolve: {
            edit: InstrumentEditResolver,
            instrumentType: TypeListResolver,
            modelType: ModelListResolver,
          }
        }
      ]
    },
    {
      path: 'completeprotocol/:mpcid/details',
      component: MPCDetailsComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'usedinstruments',
          component: MeasureProtocolUsedInstrumentComponent,
          resolve: {
            instrumentType: TypeListResolver,
            modelType:ModelListResolver,
            cmpListInt:CMPInstrumentListResolver
          }
        },
        {
          path: 'visualcontrol',
          component: MeasureProtocolVisualControlComponent,
          resolve: {
            vsData: VisualControlResolver
          }
        },
        {
          path: 'earthleakage',
          component: MeasureProtocolEarthLeakageComponent,
          resolve: {
            earthLeakage: EarthLeakageListResolver
          }
        },
        {
          path: 'powerleakage',
          component: MeasureProtocolPowerLeakageComponent,
          resolve: {
            powerLeakage: PowerLeakageListResolver
          }
        },
        {
          path: 'temperature',
          component: MeasureProtocolTemperatureComponent,
          resolve: {
              CMPTemperature: CMPTemperatureListResolver,
              temperatureType: TemperatureTypeListResolver,
              temperatureModel: TemperatureModelListResolver,
          }
        },
        {
          path: 'comments',
          component: MeasureProtocolCommentsComponent,
          resolve: {
          }
        }
      ]
    },
  ]
}];