import { Component, OnInit, ViewChild, OnChanges, DoCheck, AfterContentChecked } from "@angular/core";
import { SearchResultServices } from './searchresults.service';
import { EcplArticleViewerComponent } from './../../shared/ecpl-article-viewer/ecpl-article-viewer.component';
import {
  Router,
  Routes,
  ActivatedRoute,
  Params
} from '@angular/router';
import { Table } from "primeng/table";
import { Subscription } from "rxjs";

@Component({
  selector: 'searchresults',
  templateUrl: 'searchresults.component.html',
  providers: [SearchResultServices]
})

export class SearchResultComponent implements OnInit,DoCheck {

  
  businessId: number;
  searchKey: string;
  searchMode:string;
  resultList: any[];
  oldKey:string;
  oldMode:string;
  private subscriptions: Subscription[] = [];
  @ViewChild('resultsTable',{static: false}) resultsTable: Table;
  constructor(private searchservice: SearchResultServices,
    private activatedRoute: ActivatedRoute) {
    this.resultList = [];
    
  }

  ngOnInit() {
    document.querySelector("body").classList.remove("opened");
    this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
      this.businessId = params['bId'];
      this.searchKey = params['searchKey'];
      this.searchMode = params['searchMode'];
    }));
    this.selectSearchService();
  }

  ngDoCheck()	{
    if(this.oldKey!==this.searchKey || this.oldMode!==this.searchMode)
    this.selectSearchService();
  }

  selectSearchService():void
  {
    switch(this.searchMode)
    {
      case "Dev":
        this.searchDeviation(this.businessId, this.searchKey);
        break;
      case "Act":
        this.searchActivities(this.businessId, this.searchKey);
        break;
      case "Art":
        this.searchArticles(this.businessId, this.searchKey);
        break;
    }
  }

  searchDeviation(bId: number, searchKey: string): void {
    this.oldKey=searchKey;
    this.oldMode =this.searchMode;
    this.subscriptions.push(this.searchservice.getDeviationList(bId, searchKey)
      .subscribe(response => {
        this.resultList = response;
      }));
  }

  searchActivities(bId: number, searchKey: string): void {
    this.oldKey=searchKey;
    this.oldMode =this.searchMode;
    this.subscriptions.push(this.searchservice.getActivitiesList(bId, searchKey)
      .subscribe(response => {
        this.resultList = response;
      }));
  }
  openArticleViewer(data:any){
  }
  searchArticles(bId: number, searchKey: string): void {
    sessionStorage.setItem('workingModule', JSON.stringify("{'Title':'Search','Id':'0'}"));
    this.oldKey=searchKey;
    this.oldMode =this.searchMode;
    this.subscriptions.push(this.searchservice.getArticlesList(bId, searchKey)
      .subscribe(response => {
        this.resultList = response;      
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}