import { Execution } from './activities';
// import { TaskPlan } from './riskanalysis';
export class Riskanalysis {
  Id: number;
  RiskAnalysisId: number;
  BusinessId: number;
  RiskAnalysisDepartment: any;
  CategoryId: number;
  CategoryName: string;
  Title: string;
  Location: string;
  TopicId: number;
  FeatureId: number;
  FeatureKey: number;
  Topics: string;
  BusinessDepartmentId: number;
  BusinessDepartmentList: any = [];
  DepartmentId: string;
  ExpectedBehaviour: string;
  RelatedIncident: string;
  Alreadyactiontaken: string;
  Probability: number;
  Consequence: number;
  RiskFigure: number;
  Measures: string;
  Partcipants: string;
  IssueTypeId: string;
  Status: string;
  IsActive: boolean;
  RiskAnalysisAttachment: RiskAnalysisAttachment[];
  Activity: RiskActivity[];
  DocumentPath: string;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date;
  ProjectId: number;
  Sorting: string;
  ApprovalDate: string;
  ApprovalStatus: string;
  ApplicationId: number;
  SaveType: String;
  IsMappedActivity: boolean;
  CultureInfo: string;
  Businesses: string;
  RoleId: number;
}
export class PortalRisk {
  Id?: number;
  Title?: string;
  Location?: string;
  Status?: string;
  ExpectedBehaviour?: string;
  Alreadyactiontaken?: string;
  BusinessIds?: number[];
  ApplicationId?: number;
}
export class RiskAnalysisAttachment {
  Id?: number;
  RiskAnalysisId?: number;
  DocumentPath?: string;
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
}
export class Department {
  Id?: number;
  Name?: string;
  BusinessId?: number;
  Status?: string;
  ApplicationId?: number;
  CreatedBy?: number;
}
export class Topic {
  Id?: number;
  Name?: string;
  BusinessId?: number;
  Status?: string;
}

export class ActivityEditDto {
  Id?: number;
  BusinessDepartmentId?: number;
  Title?: string;
  Description?: string;
  Status?: number;
  ExecutionUserId?: number;
  FollowupUserId?: number;
  RepetitionId?: number;
  ActivityScheduleId?: number;
  ChecklistId?: number;
  IsRepeatChklistRecurr?: boolean;
  LinkUrl?: string;
  CompletedDate?: Date;
  BusinessId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
}
export class RiskanalysisActionPlan {
  RiskAnalysisId?: number;
  ActivityId?: number;
  ActivityEditDto?: ActivityEditDto;
  ActivityAttachment?: ActivityAttachment;
  ActivitySchedule?: ActivitySchedule;
}
export class TaskPlan {
  RiskAnalysisId?: number;
  ActivityId?: number;
  IsMappedActivity?: boolean;
  ActivityEditDto?: Activity[];
}
export class Activity {
  Id?: number;
  RiskanalysisId?: number;
  Title?: string;
  Deadline?: string;
  ExecutionUserId?: number;
  FollowupUserId?: number;
  Description?: string;
  EstimatedCost?: number;
  Status?: string;
  ActivityAttachmentDto?: ActivityAttachment[];
  ActivitySchedule?: ActivitySchedule;
}

export class ActivityAttachment {
  Id?: number;
  ActivityId?: number;
  Path?: string;
  CreatedBy?: number;
  CreatedOn?: Date;
  FileTypeId?: string;
  Status?: string;
  OriginalFileName?: string;
  MimeType?: string;
  Size?: string;
  Filename?: string;
}
export class ActivitySchedule {
  Id?: number;
  StartDate?: Date;
  EndDate?: string;
  ScheduleRule?: string;
  NotifyBeforeDeadLine?: number;
  NotifyBeforeFollowup?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
  ParentId?: number;
  ActivityId?: number;
}
export class RiskActivity {
  Id?: number;
  Title?: string;
  Description?: string;
  Status?: string;
  Goal?: string;
  EstimatedCost?: number;
  ExecutionUserId?: number;
  FollowupUserId?: number;
  RepetitionId?: number;
  ActivityScheduleId?: number;
  Deadline?: string;
  NotifyBeforeDeadLine?: number;
  NotifyBeforeFollowup?: number;
  IsMappedActivity?: boolean;
  BusinessId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
  ActivityNumber?: number;
  Activity_Number_Prefix?: string;
  IsCreatedFrom?: number;
}
export class TaskPlanListDto {
  Id?: number;
  TaskName?: string;
  Deadline?: Date;
  Description?: string;
  ResponsiblePerson?: string;
  FollowupPerson?: string;
  ExecutionUserId?: string;
  DocumentType?: string;
  Goal?: string;
  Status?: number;
  ActivitySchedule?: ActivitySchedule;
  ActivityEditDto?: Activity;
  TaskPlan?: TaskPlan;
}
export class ApprovalNotices {
  Id?: number;
  Name?: string;
  BusinessId?: number;
  RiskAnalysisId?: number;
  SjaFormId?: number;
  ApprovedOn?: Date;
  ApprovedBy?: number;
}
export class ActivityList {
  Title?: string;
  ExecutionUserName?: string;
  Description?: string;
  Status?: string;
  EndDate?: Date;
  DocumentType?: string;
}
export class Business {
  Id?: number;
  CompanyName?: string;
}
export class TaskPlanList {
  Id?: number;
  Title?: string;
  Deadline?: string;
  Date?: string;
  Description?: string;
  ResponsiblePerson?: string;
  FollowupPerson?: string;
  DocumentType?: string;
  Goal?: string;
  Status?: string;
  ExecutionUserId?: number;
  FollowupUserId?: number;
  ExecutionUsers?: Execution[];
  FollowupUsers?: Execution[];
  ExecutionUserName?: string;
  RepetitionId?: number;
  ActivityScheduleId?: number;
  NotifyBeforeDeadLine?: number;
  NotifyBeforeFollowup?: number;
  IsMappedActivity?: boolean;
  BusinessId?: number;
  ActivityAttachmentDto?: ActivityAttachment[];
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
  ActivityScheduleDto?: ActivitySchedule;
  ModuleFeatureId?: number;
  AdditionalFeautreId?: number;
  CultureInfo?: string;
  Activity_Number_Prefix?: string;
  ActivityNumber?: number;
  FollowupUsersList?: any;
  ExecutionUsersList?: any;
}

export class MailInformation {
  BusinessId?: number;
  CultureInfo?: string;
  ToMail?: any;
  ToMailName?: any;
}

export class RiskAnalysisCategory {
  Id?: number;
  CategoryName?: string;
  BusinessId?: number;
  Status?: boolean;
  ApplicationId?: number;
  CreatedBy?: number;
}
