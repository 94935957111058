<div class="tab-content">
    <form [formGroup]="ruhEditForm">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="page-title">
                        <span translate>{{title}}</span>
                        <button class="btn btn-link p-0 pull-right">
                            <i class="icon ic-sm icon-info"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblLocation" class="col-form-label">
                        <span translate>DEPARTMENT</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown type="text" formControlName="DepartmentId" [options]="departments"
                          [style]="{'width':'100%'}" (onChange)="onDepartmentChanged(depControl)" #depControl>
                        </p-dropdown>
                    </div>
                    <span style="color:red" *ngIf="!isdepartmentFollowupExist">{{noFollowupExistAlert}}</span>
                </div>
                <div class="form-group">
                    <label for="lblSorting" class="col-form-label">
                        <span translate>SORTING</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown type="text" formControlName="Sorting" [options]="sorting"
                          placeholder="{{'SELECT'|translate}}" [style]="{'width':'100%'}"></p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>TOPICS</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [options]="Topics" formControlName="TopicId" [style]="{'width':'150px'}"
                          placeholder="{{'SELECT'|translate}}" #topicDropdown></p-dropdown>
                        <span style="color:red" *ngIf="!isCaseTopicExist">{{noTopicExistAlert}}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblDepartment" class="col-form-label">
                        <span translate>TYPE_OF_CASE</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [options]="Case" formControlName="CaseId" placeholder="{{'SELECT'|translate}}"
                          #caseDropdown (onChange)="bindTopicDropdown(caseDropdown)"></p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblWhathappend" class="col-form-label">
                        <span translate>TITLE</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <input type="text" id="txtRelatedIncident" formControlName="Title" pInputText>
                    </div>
                </div>
                <div class="form-group">
                    <label for="startdate" class="col-form-label">
                        <span translate>DATE_FOR_EVENT</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <my-date-picker name="startdate" [options]="dateOptions"
                          placeholder="{{'SELECT_DATE' | translate}}" formControlName="EventDate"
                          [style]="{'width':'100%'}"></my-date-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>EVENT_TIME</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-calendar [timeOnly]="true" hourFormat="24" [style]="{'width':'70%'}"
                          formControlName="EventTime" readonlyInput="true"></p-calendar>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblDescription" class="col-form-label">
                        <span translate>DESCRIPTION</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea id="txtDescription" style="width: 350px;height: 50px;" formControlName="Description"
                          pInputTextarea> </textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblRelatedIncident" class="col-form-label">
                        <span translate>CORRECTIVE_ACTIONS</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea id="txtCorrectiveActions" style="width: 350px;height: 50px;"
                          formControlName="CorrectiveAction" pInputTextarea></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblRelatedIncident" class="col-form-label">
                        <span translate>SUGGESTIONS_ACTIONS</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea id="txtSuggestionAction" style="width: 350px;height: 50px;"
                          formControlName="SuggestionAction" pInputTextarea></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <div class="tab-content">
                        <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedRuhFiles"
                          (saveChanges)="saveUploaded($event)">
                        </ecpl-document-explorer>
                    </div>
                </div>
            </div>
            <!--Action plan-New Actions-->
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="page-title">
                        <span translate>PROCESSING_RUH</span>
                        <button class="btn btn-link p-0 pull-right">
                            <i class="icon ic-sm icon-info"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblStatus" class="col-form-label">
                        <span translate>STATUS</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown type="text" formControlName="Status" [options]="RuhStatus"
                          placeholder="{{'SELECT' | translate}}" [style]="{'width':'100%'}"></p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>CAUSE_ANALYSIS_TASKPLAN</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea id="txtCauseAnalysis" type="text" formControlName="CauseAnalysis"
                          pInputTextarea></textarea>

                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>IMMEDIATE_ACTION</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea id="txtImmediateAction" type="text" formControlName="ImmediateAction"
                          pInputTextarea></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>CORRECTIVE_ACTION</span>
                    </label>
                    <div class="input-btn-group">
                        <textarea id="txtGoal" formControlName="ProcessingCorrectiveAction" pInputTextarea></textarea>
                    </div>
                </div>
                <div>
                    <label class="col-form-label">
                        <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown type="text" [disabled]="true" placeholder="{{followupName}}" #followUpDropdown>
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>DEADLINE</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <my-date-picker [options]="dateOptions" placeholder="{{'SELECT_DEADLINE_DATE' | translate}}"
                          formControlName="Deadline" (dateChanged)="setActionDeadlineOptions($event)">
                        </my-date-picker>
                    </div>
                </div>
                <div class="form-group">
                    <p-checkbox label="{{'SICKNESS_CERTIFICATE' | translate}}" [value]="IsActive" binary="false"
                      formControlName="H1"></p-checkbox>
                </div>
                <div class="form-group">
                    <p-checkbox label="{{'INCIDENT_CAUSED_INJURY' | translate}}" [value]="IsActive" binary="false"
                      formControlName="H2"></p-checkbox>
                </div>
                <div class="form-group">
                    <p-checkbox label="{{'ACCIDENT_WITHOUT_INJURY' | translate}}" [value]="IsActive" binary="false"
                      formControlName="N"></p-checkbox>
                </div>
                <div class="form-group">
                    <p-checkbox label="{{'DANGEROUS_CONDITION' | translate}}" [value]="IsActive" binary="false"
                      formControlName="FF"></p-checkbox>
                </div>
                <p-dialog header="{{'ADD_NEW_ACTION'|translate}}" [(visible)]="addActionPlan" [modal]="true" 
                [style]="{width: '500px'}" [resizable]="false" [draggable]="false">
                    <form [formGroup]="ruhAddNewActionPopup">
                        <div class="form-group">
                            <label for="lblStatus" class="col-form-label">
                                <span translate>STATUS</span>
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <p-dropdown type="text" formControlName="actionStatus" [options]="RuhStatus"
                                  placeholder="{{'SELECT' | translate}}" [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">
                                <span translate>TITLE</span>
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <input type="text" id="txtActiontitle" class="label-input form-control"
                                  formControlName="ActionTitle" pInputText>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lblDescription" class="col-form-label">
                                <span translate>DESCRIPTION</span>
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <textarea id="txtDescription" style="width: 350px;height: 50px;"
                                  formControlName="actionDescription" pInputTextarea> </textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">
                                <span translate>DEADLINE</span>
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <my-date-picker [options]="actiondateOptions"
                                  placeholder="{{'SELECT_DEADLINE_DATE' | translate}}"
                                  formControlName="actionDeadline"></my-date-picker>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">
                                <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                                <span class="required">*</span>
                            </label>
                            <div class="input-btn-group">
                                <p-dropdown type="text" [options]="vresperson" formControlName="actionExecution"
                                  filter="filter" placeholder="{{'SELECT'|translate}}" #executionDropdown>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="tab-content">
                                <ecpl-document-explorer [uploaderConfig]="upConfigActionPlan"
                                  [uploadedFiles]="uploadedActionPlanFiles"
                                  (saveChanges)="saveUploadedActionPlan($event)">
                                </ecpl-document-explorer>
                            </div>
                        </div>

                        <div> <button type="button" *ngIf="followupId===userId || isVisibleSave 
                            || ruhAddNewActionPopup.controls[actionExecution].value===userId" class="btn btn-primary"
                              [ngClass]="{'actionloading' : actionloading}"
                              [disabled]="!ruhAddNewActionPopup.valid || actionloading" (click)="saveNewAction()">
                                <span *ngIf="!actionloading" translate>SAVE</span>
                                <span *ngIf="actionloading" translate>SAVE_PROCESS</span>
                                <span *ngIf="actionloading" class="loader-icon"></span>
                            </button></div>
                    </form>
                </p-dialog>
                <br>
                <div class="form-group">
                    <div class="page-title">
                        <span translate>ACTION_PLANS</span>
                        <button class="btn btn-primary float-right pull-right" (click)="showActionPlan()"
                          [disabled]="!(ruhEditForm.get('Deadline').valid)" translate>ADD_NEW_ACTION</button>
                    </div>
                    <div class="list" *ngIf="actionPlanList">
                        <table class="table table-striped list">
                            <tbody class="uploads">
                                <tr *ngFor="let action of actionPlanList">
                                    <td>
                                        <h6>{{action.Title}}</h6>
                                        <div><span>{{'RESPONSIBLE_FOR_EXECUTION' | translate}}</span>:
                                            <span>{{action.Execution_Name}}</span>
                                        </div>
                                        <div><span>{{'DEADLINE' | translate}}</span> :
                                            <span>{{action.Deadline | date : 'dd.MM.yyyy'}}</span>
                                        </div>
                                        <div><span>{{'STATUS' | translate}}</span> :
                                            <span>{{action.StatusName | translate}}</span>
                                        </div>
                                    </td>
                                    <td style="vertical-align: middle">
                                        <i style="font-size: xx-large" class="pi pi-chevron-circle-right"
                                          aria-hidden="true" (click)="getActionById(action.Id)"></i>
                                    </td>
                                    <td style="vertical-align: middle" *ngIf="followupId===userId || isVisibleSave">
                                        <i class="icon ic-sm icon-trash" (click)="Actionconfirm(action.Id)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br><br><br>
                <div class="form-group">
                    <div class="page-title">
                        <span translate>MEASURING_EFFECT</span>
                        <button class="btn btn-link p-0 pull-right">
                            <i class="icon ic-sm icon-info"></i>
                        </button>
                    </div>
                    <label class="col-form-label">
                        <span translate>DEADLINE</span>
                    </label>
                    <div class="input-btn-group">
                        <my-date-picker name="deadline" [options]="dateOptions" formControlName="MeasuringDeadline"
                          placeholder="{{'SELECT_DEADLINE_DATE'|translate}}"></my-date-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lblGoal" class="col-form-label" translate>EFFECT_OF_ACTION_TAKEN</label>
                    <div class="input-btn-group">
                        <textarea id="txtGoal" formControlName="MeasuringEffectofActions"
                          style="width: 350px;height: 50px;" pInputTextarea></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-outline-secondary" (click)="gotoList()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button type="button" class="btn btn-primary" [ngClass]="{'loading' : loading}"
                      (click)="UpdateRuh()" *ngIf="followupId===userId || isVisibleSave"
                      [disabled]="!(ruhEditForm.valid && isdepartmentFollowupExist) || loading">
                        <span *ngIf="!loading" translate>SAVE</span>
                        <span *ngIf="loading" translate>SAVE_PROCESS</span>
                        <span *ngIf="loading" class="loader-icon"></span>
                    </button>
                </div>
            </div>
        </div>
        <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
          acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
        <toaster-component></toaster-component>
    </form>
</div>