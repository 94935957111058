import { FeatureComponent } from './components/feature.component';
import { ApplicationService } from './services/application.service';
import { AppSharedModule } from './../shared/appShared.module';
import { FeatureService } from './services/feature.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ApplicationComponent } from './application.component';
import { ApplicationListComponent } from './components/applicationList.component';
 import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AppSharedModule,
        AccordionModule,
        TranslateModule,
        TableModule,
        FormsModule,
        CheckboxModule
    ],
    exports: [
        ApplicationComponent,
        ApplicationListComponent,
        FeatureComponent],
    declarations: [
        ApplicationComponent,
        ApplicationListComponent,
        FeatureComponent],
    providers: [FeatureService, ApplicationService],
})
export class ApplicationModule { }
