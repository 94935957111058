import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    templateUrl: 'developer.component.html'
})

export class DeveloperComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}