import { Timetable } from './../models/employee';
import { EmployeeServices } from './employee.services';
import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';

@Injectable()
export class TimeTableListResolver implements Resolve<Timetable[]> {
  constructor(private backend: EmployeeServices) { }
  resolve() {
    return this.backend.getAllTimetableByBusinessId(BaseServices.BusinessId);
  }
}
