export class BusinessEdit {
  Id: number;
  OrganizationNumber: string;
  CompanyName: string;
  CompanyNumber: string;
  AboutBusiness: string;
  Address: string;
  Zipcode: string;
  Telephone: string;
  Email: string;
  BusinessCategoryId: number;
  ApplicationId: number;
  Features: string;
  Status: number;
  BusinessPortalId: number;
  CreatedBy: number;
  EmployeeCount: number;
  ModifiedBy: number;
  CreatedOn: Date;
  ModifiedOn: string;
  constructor() {
    this.Id = 0;
  }
}

export class InternalTv {
  Id: number;
  BusinessId: number;
  FileName: string;
  OriginalFileName: string;
  MimeType: string;
  Size: number;
  Path: string;
  FileType: number;
  CreatedBy: number;
  Status: string;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
}





