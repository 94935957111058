import { NgModule } from '@angular/core';
import { SearchResultComponent } from './searchresults.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EcplArticleViewerModule } from './../../shared/ecpl-article-viewer/ecpl-article-viewer.module';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
@NgModule({
    imports: [
        TableModule,
        SharedModule,
        RouterModule,
        CommonModule,
        EcplArticleViewerModule,
        TranslateModule
    ],
    exports: [SearchResultComponent],
    declarations: [SearchResultComponent]
})
export class SearchResultModule {}
