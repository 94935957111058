import { UserService } from './../../users/services/user.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { SelectItem, ConfirmationService } from 'primeng/api';
import { BusinessServices } from '../services/business.services';
import { User } from './../../users/models/user';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'business-users',
    templateUrl: 'business-users.component.html'
})

export class BusinessUsersComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    users: User[];
    articles: Array<any>
    statuses: SelectItem[];
    userRole: SelectItem[];
    modules: SelectItem[];
    editorGuestUser = [3, 4, 5];
    businessId: number;
    private subscriptions: Subscription[] = [];

    /**
     *constructor
     * @param activatedRoute {ActivatedRoute}
     * @param router {Router}
     * @param confirmationService {ConfirmationService}
     * @param businessServices {BusinessServices}
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private confirmationService: ConfirmationService,
        private businessServices: BusinessServices,
        private userService: UserService,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 0 },
                { label: val.ACTIVE, value: 1 }
            );
        }));

        this.subscriptions.push(this.translate.stream('USER_ROLE_DROPDOWN').subscribe(val => {
            this.userRole = [];
            this.userRole.push(
                { label: val.SELECT, value: null },
                { label: val.EDITOR, value: 3 },
                { label: val.GUEST, value: 4 },
                { label: val.USER, value: 5 }
            );
        }));

        this.subscriptions.push(this.translate.stream('MODULES_DROPDOWN').subscribe(val => {
            this.modules = [];
            this.modules.push(
                { label: val.SELECT, value: null },
                { label: val.EMPLOYEE, value: Module.EMPLOYEES },
                { label: val.CONTACT, value: Module.CONTACT },
                // { label: val.CLIENT, value: Module.CLIENT },
                { label: val.CLIENT_CONTACT, value: Module.CLIENT_CONTACT },
                // { label: val.SUBCONTRACTOR, value: Module.CONTRACTOR },
                { label: val.SUBCONTRACTOR_CONTACT, value: Module.CONTRACTOR_CONTACT }
            );
        }));
    }

    /**
     * Initialize the data
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.activatedRoute.parent.params.subscribe((params: Params) => {
            this.businessId = params['bid'];
            if (this.businessId) {
                let list = this.activatedRoute.snapshot.data['userList'];
                if (list) {
                    this.users = list.filter((x: any) => (this.editorGuestUser.indexOf(x.RoleId) > -1));
                }
            } else {
                this.router.navigate(['./../../../list'], { relativeTo: this.activatedRoute });
            }
        }));
    }
    refreshList() {
        this.subscriptions.push(this.businessServices.getUsersByBusiness(this.businessId).subscribe((list: any) => {
            if (list) {
                this.users = list;
            }
        }))
    }
    /**
     * delete the user
     * @param id{id}
     */
    deleteUser(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_USER'),
            accept: () => {
                this.subscriptions.push(this.businessServices.deleteUser(id).subscribe(isDeleted => {
                    if (isDeleted) {
                        this.refreshList();
                        this.userService.clearCachedUserList();
                        this.toasterComponent.callToastDlt();
                    }
                }))
            }
        });
    }
    /**
 * mobilenumber keypress event restrict number greater than 20 and restrict text
 */
    onMobileNumberChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
