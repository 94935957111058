<div class="main-content">
    <div class="main-heading">
        <span translate>{{isEditMode ? 'Edit' : 'NEW' | translate}} {{'MEASURE_PROTOCOL' | translate }}</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="measureProtocolForm">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="Title">
                                <span translate>MP_NUMBER</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="number" formControlName="Number" id="Number" class="ex-full-width" />
                            </div>
                            <control-messages [control]="measureProtocolForm.controls['Number']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>MP_NAME</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="text" formControlName="Name" id="Name" class="ex-full-width" />
                            </div>
                            <control-messages [control]="measureProtocolForm.controls['Name']"></control-messages>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>CLIENT</span>
                            </label>
                            <div>
                                <p-dropdown [options]="clients" formControlName="ClientId" #clientDropdown
                                  [style]="{'width':'100%'}">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-btns-wrapper text-left">
                    <button class="btn btn-outline-secondary" (click)="gotoBack()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button type="button" class="btn btn-primary" [disabled]="measureProtocolForm.invalid"
                      (click)="saveAndCreateNew()">
                        <span translate>SAVE_CREATE_NEW</span>
                    </button>
                    <button type="button" class="btn btn-primary" [disabled]="measureProtocolForm.invalid"
                      (click)="saveChanges()">
                        <span translate>SAVE_AND_BACK</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<toaster-component></toaster-component>