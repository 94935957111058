import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { OtherSystem } from '../models/other-system';
import { BaseServices } from './../../kuba.services';
import { OtherSystemService } from './other-system.service';



@Injectable()
export class InternalControlResolver implements Resolve<OtherSystem[]> {
    constructor(private backend: OtherSystemService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<OtherSystem[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getAll(BaseServices.BusinessId);
    }
}

@Injectable()
export class InternalControlFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: OtherSystemService) { }
    resolve(route: ActivatedRouteSnapshot): any {
        if (BaseServices.roleId === '3') {
            return this.backend.getInternalControlFeatures(BaseServices.BusinessId);
        }
    }
}

@Injectable()
export class InternalControlEditResolver implements Resolve<OtherSystem[]> {
    constructor(private backend: OtherSystemService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<OtherSystem[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getInternalControlById(+route.params['id']);
    }
}

@Injectable()
export class InternalControlByUserResolver implements Resolve<OtherSystem[]> {
    constructor(private backend: OtherSystemService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<OtherSystem[]> {
        let businessId = BaseServices.BusinessId;
        return this.backend.getInternalByUser(BaseServices.UserId);
    }
}


