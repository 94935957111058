import { UserRole } from './../../follow-ups/models/deviation';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { EmployeeServices } from './../services/employee.services';
import { HelperService } from './../../../_services/helper.service';

import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { EmployeePrivateDocumentServices } from './../services/employeeprivatedocument.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FileUploadService } from 'src/app/_services/fileUpload.service';
import {
  EmployeeDocumentNode,
  EmployeeDocumentUploadable,
} from 'src/app/kuba/employees/models/employeeDocument';
import { FeatureBase } from 'src/app/kuba/features/models/features-base.model';
import { FeatureKey, Rights } from 'src/app/_models';
import { elementAt } from 'rxjs/operator/elementAt';
import { SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { ManualService } from 'src/app/kuba/manuals/services/manual.services';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { QualitySystemService } from '../../quality-system/services/quality-system.service';
import { OtherSystemService } from '../../other-system/services/other-system.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FeatureManualService } from '../../feature-documents/feature-document.services';

@Component({
  selector: 'documented-training',
  templateUrl: 'employee-documented-training.component.html',
})
export class DocumentedTrainingComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  //#region variables
  additionalData: any;
  loading = false;
  upConfig: UploaderConfig;
  upConfigApp: UploaderConfig;
  UploadableFile: EmployeeDocumentUploadable;
  newDocumentAdded: any = [];
  uploadedFiles: any = [];
  docId: number;
  employeeId: number;
  isShowDelete: boolean;
  editMode = false;
  addManualsEdit = true;
  addFromManuals = true;
  showSavingLoader = false;
  isEditor = false;
  isUser = false;
  featureId: any;
  selectedFiles: any;
  isMultiDocument = false;
  employees: any = [];
  emp: any = [];
  rowID: number = null!;
  isCollapsed = false;
  employeeForm: FormGroup;
  selectedEmployees: SelectItem[];
  selectedEmployeesForApproval: any = [];
  files: any = [];
  selectedDocumentsForApprovalNotice: any = [];
  type: any;
  all = false;
  title: any = 'DOCUMENTED_TRAINING';
  typeList: any = [];
  originalEmp: any = [];
  showArchiveDocumentDailog: boolean = false;
  ShowPopUp: boolean = false;
  otherdocumentPopUp: boolean = false;
  QualitydocumentPopUp: boolean = false;
  treeNode: any;
  selectFolderId?: number;
  selectFolderName?: string;
  treeActiveNode: any = null;
  appManualTree: any;
  appManualTreeClone: any;
  flattenedClone: any;
  selectedArticles: any = [];
  ecplIsPrivateDocument = true;
  changeCheckBox = false;
  isDocTrainButton = true;
  hasRights = false;
  dropDownAlinement = false;
  manualList: SelectItem[];
  qualityList: SelectItem[];
  otherList: SelectItem[];
  selectedManual: any = 543;
  apiResponse: boolean = true;
  private subscriptions: Subscription[] = [];
  safeURL: any;
  isVideo = false;
  //#endregion

  //#region constructor
  /**
   *  constructor
   * @param route {ActivatedRoute}
   * @param fileUploadService {FileUploadService}
   * @param location {Location}
   * @param privateDocSvc {EmployeePrivateDocumentServices}
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private location: Location,
    private employeePrivateDocumentServices: EmployeePrivateDocumentServices,
    private employeeServices: EmployeeServices,
    private featureManualService: FeatureManualService,
    private translate: TranslateService,
    private manualservices: ManualService,
    private qualityservice: QualitySystemService,
    private otherservice: OtherSystemService,
    private _sanitizer: DomSanitizer
  ) {
    //#endregion
    this.employeeId = route.snapshot.params['eid'];
    this.type = route.snapshot.params['mId'];
    if (this.type == 1) {
      this.title = 'DOCUMENTED_TRAINING';
      this.isMultiDocument = true;
      this.subscriptions.push(
        this.employeeServices
          .list(BaseServices.BusinessId)
          .subscribe((result) => {
            if (result) {
              result.forEach((r: any) => {
                this.employees.push({
                  label: r.Name,
                  value: r.Id,
                });
              });
            }
          })
      );
      this.emp = this.route.snapshot.data['applist'];
      this.originalEmp = this.route.snapshot.data['applist'];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.typeList = [];
          this.typeList.push({ label: val.SELECT, value: null });
          var types = this.emp
            .map((item: any) => item.TypeOfEmployee)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );
          types.forEach((element: any) => {
            this.typeList.push({ label: element, value: element });
          });
        })
      );
    }

    if (BaseServices.roleId == '3') {
      if (BaseServices.userBase.UserFeatureKey == this.employeeId)
        this.isUser = true;
      else this.isEditor = true;
    }
    if (
      (BaseServices.roleId == '4' || BaseServices.roleId == '5') &&
      BaseServices.userBase.UserFeatureKey == this.employeeId
    ) {
      this.isUser = true;
    }

    if (
      BaseServices.roleId == '3' ||
      (BaseServices.roleId == '5' &&
        BaseServices.checkUserRights(Rights.DOCUMENTED_TRAINING_RIGHTS, 'User'))
    ) {
      this.hasRights = true;
    }
    // TODO: need to send dynamic values
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      EmployeeId: this.employeeId,
      IsPrivateDocument: true,
      Status: '1',
      CreatedBy: BaseServices.UserId,
      CreatedOn: '',
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: '',
      IsDocumentedTraining: true,
    };

    this.upConfig = {
      title: 'DOCUMENTED_TRAINING',
      viewSwitch: true,
      IsDocumentedUploadButton: true,
      showDeleteButton: false,
      editMode: this.editMode,
      windowedHeight: false,
      showUserMeta: false,
      showSearchFilter: false,
      showDocumentedTrainingButton: !this.isDocTrainButton && this.hasRights,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/employee/private-docs',
      addtionalData: this.additionalData,
      isEditablePrivateDocument: true,
      pdEmpId: this.employeeId,
    };
    this.upConfigApp = {
      title: '',
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      IsDocumentedUploadButton: true,
      showDeleteButton: true,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/employee/private-docs',
      addtionalData: this.additionalData,
      pdEmpId: this.employeeId,
    };
  }
  /**
   * file upload events
   * @param event {any}
   */
  isFileSeclected() {
    var selected = this.uploadedFiles.filter((x: any) => x.selected == true);
    if (selected.length > 0) return false;
    else return true;
  }

  setCollapse(i: number): boolean {
    let isCollapsed = i === this.rowID;
    return !isCollapsed;
  }

  toggleClick(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
  }

  toggleClickHide(i: number): void {
    this.rowID = null!;
  }
  // This will emit all file save/update action
  saveEmitter(eventData) {
    switch (eventData.eventName) {
      case 'SaveArticle':
        this.catchEvent(eventData);
        break;
      default:
        break;
    }
  }

  saveUploaded(event: any) {
    if (event == 'showDocumentDialog') {
      this.showDocumentDailog();
    } else if (event == 'showDocumentDialog1') {
      this.showDocumentDailog1();
    } else if (event == 'showDocumentDialog2') {
      this.showDocumentDailog2();
    } else {
      if (event) {
        let eventType = event.eventName;
        switch (eventType) {
          case 'DeleteArticle':
            {
              this.uploadedFiles = this.uploadedFiles.filter(
                (x: any) =>
                  x.id !== +event.item.data.id ||
                  (x.id == +event.item.data.id && x.documentType != 'article')
              );
              this.selectedArticles = this.selectedArticles.filter(
                (x: any) => x.id !== +event.item.data.id
              );
              if (event.item.data.id != 0) {
                this.subscriptions.push(
                  this.employeeServices
                    .deleteEmployeeDocument(event.item.data.id)
                    .subscribe((result: any) => {
                      if (result) {
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                          (x: any) => x.Path !== event.item.data.path
                        );
                        // remove from display list
                        this.getDoc();
                        this.toasterComponent.callToastDlt();
                      }
                    })
                );
              }
            }
            break;
          case 'DeleteChecklist':
            {
              this.uploadedFiles = this.uploadedFiles.filter(
                (x: any) =>
                  x.id !== +event.item.data.id ||
                  (x.id == +event.item.data.id && x.documentType != 'checklist')
              );
              this.selectedArticles = this.selectedArticles.filter(
                (x: any) => x.id !== +event.item.data.id
              );
              if (event.item.data.id != 0) {
                this.subscriptions.push(
                  this.employeeServices
                    .deleteEmployeeDocument(event.item.data.id)
                    .subscribe((result: any) => {
                      if (result) {
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                          (x: any) => x.Path !== event.item.data.path
                        );
                        // remove from display list
                        this.getDoc();
                        this.toasterComponent.callToastDlt();
                      }
                    })
                );
              }
            }
            break;
          case 'DeleteDefaultFile':
            if (event.item.data.id === 0) {
              // remove unsaved attachment
              this.newDocumentAdded = this.newDocumentAdded.filter(
                (x: any) => x.Path !== event.item.data.path
              );
              this.uploadedFiles = this.uploadedFiles.filter(
                (x: any) => x.path !== event.item.data.path
              );
              this.toasterComponent.callToastDlt();
            } else {
              // remove existing attachement
              this.subscriptions.push(
                this.employeeServices
                  .deleteEmployeeDocument(event.item.data.id)
                  .subscribe((result: any) => {
                    if (result) {
                      this.subscriptions.push(
                        (this.newDocumentAdded = this.newDocumentAdded.filter(
                          (x: any) => x.Path !== event.item.data.path
                        ))
                      );
                      // remove from display list
                      this.getDoc();
                      this.toasterComponent.callToastDlt();
                    }
                  })
              );
            }
            break;
          case 'remove':
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.id !== +event.id
            );
            break;
          case 'ShowOfficeFileSaveloader':
            this.subscriptions.push(
              this.employeePrivateDocumentServices
                .updateDoc(event.item.id, this.employeeId, false)
                .subscribe((res) => {
                  this.docId = event.item.id;
                  if (res) {
                    this.subscriptions.push(
                      this.employeePrivateDocumentServices
                        .list(this.employeeId, 2)
                        .subscribe((result) => {
                          if (result.length > 0) {
                            this.uploadedFiles = result;
                            this.Approve(1);
                          }
                        })
                    );
                  }
                })
            );
            if (parseInt(sessionStorage.getItem('isDocumentChanged')!, null!)) {
              this.showSavingLoader = true;
              setTimeout(() => {
                this.showSavingLoader = false;
                this.newDocumentAdded = [];
              }, 15000);
              sessionStorage.setItem('isDocumentChanged', '0');
            }
            break;
          default:
            let keyBindings = {
              Originalname: 'OriginalFileName',
              FileTypeId: 'FileType',
            };
            let privateDoc = Object.assign({}, event, this.additionalData);
            privateDoc.id = 0;
            privateDoc.status = '1';
            privateDoc.createdBy = BaseServices.UserId;
            this.newDocumentAdded.push(
              HelperService.switchKeysCase(privateDoc, keyBindings)
            );

            break;
        }
      }
    }
  }

  saveDoc() {
    if (this.isMultiDocument == true) {
      if (
        (this.newDocumentAdded.length > 0 ||
          this.selectedArticles.length > 0) &&
        this.selectedEmployeesForApproval.length > 0
      ) {
        this.loading = true;
        this.newDocumentAdded.forEach((element: any) => {
          this.selectedEmployeesForApproval.forEach((x: any) => {
            var tempDoc: any = {};
            tempDoc.BusinessId = element.BusinessId;
            tempDoc.CreatedBy = element.CreatedBy;
            tempDoc.CreatedOn = element.CreatedOn;
            tempDoc.EmployeeId = x.EmployeeId;
            tempDoc.Extension = element.Extension;
            tempDoc.FileType = element.FileType;
            tempDoc.Filename = element.Filename;
            tempDoc.Id = element.Id;
            tempDoc.IsPrivateDocument = element.IsPrivateDocument;
            tempDoc.Mimetype = element.Mimetype;
            tempDoc.ModifiedBy = element.ModifiedBy;
            tempDoc.ModifiedOn = element.ModifiedOn;
            tempDoc.OriginalFileName = element.OriginalFileName;
            tempDoc.Path =
              element.documentType == 'videos' ? element.url : element.Path;
            tempDoc.Size = element.Size;
            tempDoc.Status = element.Status;
            tempDoc.IsDocumentedTraining = true;
            this.files.push(tempDoc);
          });
        });

        this.selectedArticles.forEach((element: any) => {
          this.selectedEmployeesForApproval.forEach((x: any) => {
            var tempDoc: any = {};
            tempDoc.BusinessId = BaseServices.BusinessId;
            tempDoc.CreatedBy = BaseServices.UserId;
            tempDoc.CreatedOn = element.createdOn;
            tempDoc.EmployeeId = x.EmployeeId;
            tempDoc.Extension = null;
            tempDoc.FileType = null;
            tempDoc.Filename = element.filename;
            tempDoc.Id = element.Id;
            tempDoc.IsPrivateDocument = true;
            tempDoc.Mimetype = null;
            tempDoc.ModifiedBy = BaseServices.UserId;
            tempDoc.ModifiedOn = null;
            tempDoc.OriginalFileName = element.originalFileName;
            tempDoc.Path = null;
            tempDoc.Size = null;
            tempDoc.Status = 1;
            tempDoc.DocumentTypeId = element.documentTypeId;
            tempDoc.DocumentType = element.documentType;
            tempDoc.Path =
              element.documentType == 'videos' ? element.url : element.path;
            tempDoc.DocumentId = element.nodeId;
            tempDoc.documentType = element.documentType;
            tempDoc.IsDocumentedTraining = true;
            this.files.push(tempDoc);
          });
        });
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .saveDocs(this.files, this.employeeId)
            .subscribe((result) => {
              if (result) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.newDocumentAdded = [];
                this.selectedArticles = [];
                this.getApprovalDoc();
                this.selectedEmployeesForApproval = [];
                this.uploadedFiles = [];
                this.files = [];
              }
            })
        );
      }
    } else {
      if (this.selectedArticles.length > 0) {
        var temp: any = [];
        this.selectedArticles.forEach((element: any) => {
          this.loading = true;
          var tempDoc: any = {};
          tempDoc.CreatedOn = element.createdOn;
          tempDoc.EmployeeId = Number(this.employeeId);
          tempDoc.Extension = null;
          tempDoc.FileType = null;
          tempDoc.Filename = element.filename;
          tempDoc.Id = element.Id;
          tempDoc.IsPrivateDocument = true;
          tempDoc.Mimetype = null;
          tempDoc.ModifiedBy = null;
          tempDoc.ModifiedOn = null;
          tempDoc.OriginalFileName = element.originalFileName;
          tempDoc.Path = null;
          tempDoc.Size = null;
          tempDoc.Status = 1;
          tempDoc.DocumentTypeId = element.documentTypeId;
          tempDoc.Path =
            element.documentType == 'videos' ? element.url : element.path;
          tempDoc.DocumentId = element.nodeId;
          tempDoc.DocumentType = element.documentType;
          tempDoc.CreatedBy = Number(BaseServices.UserId);
          tempDoc.IsDocumentedTraining = true;
          temp.push(tempDoc);
        });
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .save(temp, this.employeeId)
            .subscribe((result) => {
              if (result) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.selectedArticles = [];
                this.getDoc();
              }
            })
        );
      }

      if (this.newDocumentAdded.length > 0) {
        this.loading = true;
        this.newDocumentAdded.forEach((element: any) => {
          element.IsDocumentedTraining = true;
        });
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .save(this.newDocumentAdded, this.employeeId)
            .subscribe((result) => {
              if (result) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.newDocumentAdded = [];
                this.getDoc();
              }
            })
        );
      }
    }
  }

  getApprovalDoc() {
    this.employeePrivateDocumentServices
      .getApprovalDocuments(
        this.employeeId,
        BaseServices.BusinessId,
        BaseServices.PortalId,
        2
      )
      .subscribe((result) => {
        this.emp = result;
        this.originalEmp = result;
        this.subscriptions.push(
          this.translate.stream('SELECT').subscribe((val) => {
            this.typeList = [];
            this.typeList.push({ label: val.SELECT, value: null });
            var types = this.emp
              .map((item: any) => item.TypeOfEmployee)
              .filter(
                (value: any, index: any, self: any) =>
                  self.indexOf(value) === index
              );
            types.forEach((element: any) => {
              this.typeList.push({ label: element, value: element });
            });
          })
        );
      });
  }
  //#endregion

  //#region page-events
  /**
   * page initialization
   */
  ngOnInit() {
    BaseServices.setActiveTreeNode(null);
    this.treeActiveNode = null;
    let uploadfiles = this.route.snapshot.data['list'];
    if (uploadfiles && uploadfiles.length > 0) {
      this.uploadedFiles = uploadfiles;
    }
  }

  ngOnChanges() {
    this.selectedFiles = this.uploadedFiles.filter(
      (x: any) => x.selected == true
    );
  }

  getDoc() {
    this.subscriptions.push(
      this.employeePrivateDocumentServices
        .list(this.employeeId, 2)
        .subscribe((result) => {
          this.uploadedFiles = result;
        })
    );
  }
  getApprovalDocuments() {
    this.loading = true;
    this.subscriptions.push(
      this.employeePrivateDocumentServices
        .getApprovalDocuments(
          this.employeeId,
          BaseServices.BusinessId,
          BaseServices.PortalId,
          2
        )
        .subscribe((result) => {
          this.emp = result;
          this.originalEmp = result;
          this.subscriptions.push(
            this.translate.stream('SELECT').subscribe((val) => {
              this.typeList = [];
              this.typeList.push({ label: val.SELECT, value: null });
              var types = this.emp
                .map((item: any) => item.TypeOfEmployee)
                .filter(
                  (value: any, index: any, self: any) =>
                    self.indexOf(value) === index
                );
              types.forEach((element: any) => {
                this.typeList.push({ label: element, value: element });
              });
            })
          );
          this.loading = false;
        })
    );
  }

  toggleEditMode(mode: any) {
    this.editMode = mode;
    this.upConfig.editMode = mode;
    this.upConfig.showDeleteButton = mode;
  }

  onTableHeaderCheckboxToggle() {
    this.selectedEmployeesForApproval = this.emp;
  }

  Approve(isSendApproval: number) {
    this.saveDoc();
    if (this.type == 1) {
      this.loading = true;
      this.selectedDocumentsForApprovalNotice =
        this.selectedEmployeesForApproval
          .filter((x) => x.TotalDocumentsForApproval > 0)
          .map((ele) => ele.EmployeeDocuments);
      for (let i = 0; i < this.selectedEmployeesForApproval.length; i++) {
        var employeeId = this.selectedEmployeesForApproval[i].EmployeeId;
      }
      var merged = [].concat.apply([], this.selectedDocumentsForApprovalNotice);
      if (this.selectedDocumentsForApprovalNotice.length > 0) {
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .update(merged, 1, employeeId, FeatureKey.DOCUMENTED_TRAINING, 2)
            .subscribe((result) => {
              if (result) {
                if (isSendApproval == 1) {
                  this.toasterComponent.callToastApprovalNotice();
                } else {
                  this.toasterComponent.callToastApprovedNotice();
                }
                this.getApprovalDoc();
                this.loading = false;
                this.selectedEmployeesForApproval = [];
              }
            })
        );
      } else {
        this.loading = false;
        this.getApprovalDoc();
        this.selectedEmployeesForApproval = [];
      }
    } else {
      this.loading = true;
      if (this.docId != null) {
        this.selectedFiles = this.uploadedFiles.filter(
          (x: any) => x.id == this.docId
        );
      } else {
        this.selectedFiles = this.uploadedFiles.filter(
          (x: any) => x.selected == true
        );
      }
      if (this.selectedFiles.length > 0) {
        this.selectedFiles.forEach((element: any) => {
          element.content.article = null;
        });
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .update(
              this.selectedFiles,
              isSendApproval,
              BaseServices.UserFeatureKey,
              FeatureKey.DOCUMENTED_TRAINING,
              2
            )
            .subscribe((result) => {
              if (result) {
                if (isSendApproval == 1) {
                  this.toasterComponent.callToastApprovalNotice();
                } else {
                  this.toasterComponent.callToastApprovedNotice();
                }
                this.loading = false;
                this.getDoc();
              }
            })
        );
      } else {
        this.loading = false;
      }
    }
  }

  choose(event: any) {}

  getFileExtension(filename: any) {
    let fileExtension = null;
    if (filename != null) {
      fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
    }
    return fileExtension.toLowerCase();
  }

  humanizeType(mimetype: any) {
    let type = '';

    switch (mimetype) {
      case 'xlsx':
      case 'xls':
      case 'csv':
      case 'docx':
      case 'doc':
      case 'rtf':
      case 'ppt':
      case 'pptx':
      case 'csv':
        type = 'Document';
        break;
      case 'png':
      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'bmp':
      case 'gif':
      case 'tiff':
        type = 'Image';
        break;
      case 'mp3':
      case 'wav':
        type = 'Audio';
        break;
      case 'avi':
      case 'mp4':
      case '3gpp':
      case 'webm':
      case 'flv':
        type = 'Video';
        break;
      case 'txt':
        type = 'Text';
        break;
      case 'PDF':
      case 'pdf':
        type = 'Pdf';
        break;
      case 'zip':
      case 'rar':
      case '7z':
        type = 'Archive file';
        break;
      default:
        type = 'Article';
        break;
    }

    return type;
  }

  checkIfAllSelected() {
    this.all = !this.all;
  }
  filterResults(data: any) {
    if (data == null || data == '') {
      this.emp = this.originalEmp;
    } else {
      var arr: any = [];
      this.originalEmp.forEach((x: any) => {
        var filter = [];
        if (x.EmployeeDocuments != null && x.EmployeeDocuments.length > 0) {
          x.EmployeeDocuments.forEach((ele: any) => {
            if (
              ele.Filename != null &&
              ele.Filename.toLowerCase().includes(data.toLowerCase())
            )
              filter.push(ele);
          });
          if (filter.length > 0) arr.push(x);
        }
      });
      this.emp = arr;
    }
  }
  showDocumentDailog() {
    this.binddetails();
    (this.featureId = FeatureKey.MANUALS), (this.ShowPopUp = true);
    this.dropDownAlinement = true;
    this.showArchiveDocumentDailog = false;
  }
  showDocumentDailog1() {
    this.binddetails1();
    (this.featureId = FeatureKey.QUALITY_SYSTEMS),
      (this.QualitydocumentPopUp = true);
    this.dropDownAlinement = true;
    this.showArchiveDocumentDailog = false;
  }
  showDocumentDailog2() {
    this.binddetails2();
    (this.featureId = FeatureKey.OTHER_SYSTEMS),
      (this.otherdocumentPopUp = true);
    this.dropDownAlinement = true;
    this.showArchiveDocumentDailog = false;
  }

  catchEvent(event: any): void {
    if (event.eventName == 'CheckBoxStatus') {
      if (event.node.data.nodes != null && event.node.data.nodes.length > 0) {
        this.updateStatus(event.node.data.nodes, event.node.data.status);
      } else {
        this.updateStatus(event.node.data, event.node.data.status);
      }
    }
  }

  getAllArticles(allData: any) {
    allData.forEach((x: any) => {
      if (x.isFolder == 1) {
        this.getAllArticles(x.nodes);
      } else {
        if (x.isFolder == 0) {
          if (
            this.selectedArticles.find((result: any) => result.id == x.id) ==
            null
          ) {
            this.selectedArticles.push(x);
          }
        }
      }
    });
    return allData;
  }

  removeAllArticles(allData: any) {
    allData.forEach((x: any) => {
      if (x.isFolder == 1) {
        this.removeAllArticles(x.nodes);
      } else {
        if (x.isFolder == 0) {
          if (
            this.selectedArticles.filter((result: any) => result.id == x.id) !=
            null
          ) {
            var index = this.selectedArticles.findIndex(
              (res: any) => res.id == x.id
            );
            this.selectedArticles.splice(index, 1);
          }
        }
      }
    });
    return allData;
  }

  // addArticlesToPrivateDocs() {
  //   this.showArchiveDocumentDailog = false;
  //   this.ShowPopUp = false;
  //   this.add(this.treeNode);
  // }
  addArticlesToPrivateDocs() {
    this.showArchiveDocumentDailog = false;
    this.ShowPopUp = false;
    this.otherdocumentPopUp = false;
    this.QualitydocumentPopUp = false;
    this.add(this.treeNode);
  }

  add(data: any) {
    data.forEach((element: any) => {
      if (element.type == 'folder') {
        this.add(element.nodes);
      } else {
        if (element.type == 'file' && element.status == 1) {
          this.selectedArticles.push(element);
          this.uploadedFiles.push(element);
        }
      }
    });
  }
  private flattenArrObj(arr: any) {
    let newArr: any = [];
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach((element) => {
        newArr.push(element);
        if (Array.isArray(element.nodes) && element.nodes.length > 0) {
          newArr = newArr.concat(this.flattenArrObj(element.nodes));
        }
      });
    }
    return newArr;
  }

  updateStatus(data: any, status: any) {
    data.forEach((element: any) => {
      element.status = Number(status);
      if (element.nodes != null && element.nodes.length > 0) {
        this.updateStatus(element.nodes, status);
      }
    });
  }
  nodeStatusChangeFunction(treedatas: any) {
    treedatas.forEach((element: any) => {
      element.status = 2;
      if (element.nodes != null && element.nodes.length > 0) {
        this.nodeStatusChangeFunction(element.nodes);
      }
    });
  }
  deleteDoc(Id: any) {
    this.loading = true;
    this.subscriptions.push(
      this.employeePrivateDocumentServices
        .delete(this.employeeId, Number.parseInt(Id))
        .subscribe((res) => {
          this.loading = false;
          this.toasterComponent.callToastDlt();
          this.selectedArticles = [];
          this.getApprovalDocuments();
        })
    );
  }
  binddetails() {
    this.manualList = [];
    let manuals = this.manualservices
      .getManualsDocumentTraining(BaseServices.BusinessId)
      .subscribe((result: any) => {
        if (result) {
          result.forEach((approvalPersons: any) => {
            if (manuals) {
              this.manualList.push({
                label: approvalPersons.Title,
                value: approvalPersons.Id,
              });
            }
          });
        }
      });
    this.subscriptions.push(manuals);
  }
  binddetails1() {
    this.qualityList = [];
    let quality = this.qualityservice
      .getQualitySystemDocumentTraining(BaseServices.BusinessId)
      .subscribe((result: any) => {
        if (result) {
          result.forEach((approvalPersons: any) => {
            if (quality) {
              this.qualityList.push({
                label: approvalPersons.Title,
                value: approvalPersons.Id,
              });
            }
          });
        }
      });
    this.subscriptions.push(quality);
  }
  binddetails2() {
    this.otherList = [];
    let other = this.otherservice
      .getOtherSystemDocumentTraining(BaseServices.BusinessId)
      .subscribe((result: any) => {
        if (result) {
          result.forEach((approvalPersons: any) => {
            if (other) {
              this.otherList.push({
                label: approvalPersons.Title,
                value: approvalPersons.Id,
              });
            }
          });
        }
      });
    this.subscriptions.push(other);
  }
  SelectManual(e: any) {
    this.treeNode = [];
    if (this.featureId == 13) {
      this.subscriptions.push(
        this.manualservices
          .getManualTreeBusinessLevel(
            e,
            BaseServices.ApplicationId,
            0,
            BaseServices.BusinessId,
            false
          )
          .subscribe(
            (result: any) => {
              this.treeNode = result.filter(
                (x) => x.status == 1 && x.id !== 10 && x.id !== 18
              );
              this.treeNode = this.treeNode.filter(
                (x) => x.documentType != 'checklist'
              );
              if (this.treeNode.length > 0) {
                this.nodeStatusChangeFunction(this.treeNode);
              }
            },
            (error: any) => {
              console.error('Api Error while refreshing tree:', error);
            },
            () => {
              this.showArchiveDocumentDailog = true;
              this.dropDownAlinement = false;
            }
          )
      );
    } else {
      this.featureManualService
        .getFeatureManualTreeBytree(this.featureId, e)
        .subscribe((result: any) => {
          this.treeNode = result.filter(
            (x) => x.status == 1 && x.id !== 10 && x.id !== 18
          );
          this.treeNode = this.treeNode.filter(
            (x) => x.documentType != 'checklist'
          );
          if (this.treeNode.length > 0) {
            this.nodeStatusChangeFunction(this.treeNode);
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
