import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    templateUrl: 'application.component.html'
})

export class ApplicationComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}