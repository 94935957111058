<div class="main-content">
    <div class="main-heading">
        <span translate>{{CompanyName | capitalize}}</span>
    </div>
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li *ngFor="let link of wizard" routerLinkActive="active">
                    <a routerLink="{{link.LinkText}}" [class.disabled]="newBusiness ? true : null">{{link.translationKey
                        | translate}}</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>