<div class="main-content">
    <div class="main-heading">
        <span translate>LIST</span>
        <span class="page-actions text-right" *ngIf="isAdministrateNews">
            <a *ngIf="!isAdmin && isUser" outerLink="create" [routerLink]="['./../create',0,0]" class="btn btn-success"
              routerLinkActive="active">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="news" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="Title">Title <p-sortIcon field="Title"></p-sortIcon></th>
                            <th pSortableColumn="Status">Status <p-sortIcon field="Status"></p-sortIcon></th>
                            <th pSortableColumn="CreatedOn">CreatedOn <p-sortIcon field="CreatedOn"></p-sortIcon></th>
                            <th pSortableColumn="PublishedOn">PublishedOn <p-sortIcon field="PublishedOn"></p-sortIcon>
                            </th>
                            <th pSortableColumn="ExpiredOn">ExpiredOn <p-sortIcon field="ExpiredOn"></p-sortIcon></th>
                            <th *ngIf="isAdministrateNews">{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($any($event.target)?.value, 'Title', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th *ngIf="isAdministrateNews"></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-news>
                        <tr>
                            <td>
                                <span>
                                    <a [routerLink]="[ './../create', news.Id, 1] ">{{news.Title}}</a>
                                </span>
                            </td>
                            <td><span translate>{{(news.PublishedOn !== null ? 1 : 0) | status:['NOT
                                    PUBLISHED','PUBLISHED']}}</span></td>
                            <td><span>{{news.CreatedOn |date : 'dd/MM/yyyy'}}</span></td>
                            <td><span>{{news.PublishedOn |date : 'dd/MM/yyyy'}}</span></td>
                            <td><span>{{news.ExpiredOn |date : 'dd/MM/yyyy'}}</span></td>
                            <td class="col-button" *ngIf="isAdministrateNews">
                                <!-- <a routerLink="create" [routerLink]="['./../create',news.Id]" class="btn btn-icon" -->
                                <a [routerLink]="['./../create',news.Id, 0]" class="btn btn-icon"
                                  title="{{'EDIT'|translate}}" *ngIf="isEditor && isHideEditIcon">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </a>
                                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                  (click)="confirm(news.Id)" *ngIf="isHideDeleteIcon">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="6">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
            <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
              acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
        </div>
    </div>
</div>
<toaster-component></toaster-component>