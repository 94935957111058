import { Route } from '@angular/router';
import { CheckListCompleteComponent } from './components/index';
import {ResponsibleForExecutionResolver} from './../follow-ups/services/deviation.resolver.service'
export const CompleteCheckListRoutes: Route[] = [
    {
        path: 'completechecklist',
        children: [
            {
                path: 'process/:chkId/:cmptChkId/:cmptType',
                component: CheckListCompleteComponent,
                resolve: {
                    responsibleForExecutionResolver:ResponsibleForExecutionResolver
                }
            }
        ]
    }    
]