import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AuthGuard } from 'src/app/_guards';
import { deviationRoutes } from '../deviation/deviation.routing';
import { FeatureDocumentRoutes } from '../feature-documents/feature-document.routing';
import { registrationRoutes } from '../Registration/registration.routing';
import { safetyjobRoutes } from '../safety-job-analysis/jobanalysis.routing';
import { kubaFeatureDetailsRoutes } from '../shared/kuba-feature-details/Kuba-feature-details.routes';
import { taskmanagerRoutes } from '../task-manager/task-manager.routing';
import { timetableRoutes } from '../time-table/timetable.routing';
import { 
    EmployeeContactUserResolver, 
    EmployeeContactUserSubscriptionResolver 
} from '../users/services/user.resolver.service';
import { OtherSystemEditComponent } from './components/other-system-edit.component';
import { OtherSystemListComponent } from './components/other-system-list.component';
import { OtherSystemViewComponent } from './components/other-system-view.component';
import { OthersystemListComponent } from './components/othersystem-list.component';
import { OtherSystemComponent } from './other-system.component';
import { InternalControlByUserResolver, InternalControlEditResolver, InternalControlFeaturesResolver, InternalControlResolver } from './services/other-system.resolver.service';

export const OtherSystemRoutes: Route[] = [
    {
        path: 'other-system',
        component: OtherSystemComponent,
        children: [
            {
                path: 'list',
                component: OthersystemListComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'all', pathMatch: 'full' },
                    {
                        path: 'own',
                        component: OtherSystemListComponent,
                        resolve: {
                            list: InternalControlByUserResolver
                        }
                    },
                    {
                        path: 'all',
                        component: OtherSystemListComponent,
                        resolve: {
                            list: InternalControlResolver,
                        }
                    }
                ]
            },
            {
                path: 'create/:id',
                component: OtherSystemEditComponent,
                resolve: {
                    contact: EmployeeContactUserResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver,
                }
            },
            {
                path: 'details/:id',
                data: { parent: 'OS' },
                component: OtherSystemViewComponent,
                resolve: {
                    list: InternalControlByUserResolver,
                    additionalFeatures: InternalControlFeaturesResolver,
                    edit: InternalControlEditResolver
                },
                canActivate: [AuthGuard],
                children: [
                    ...kubaFeatureDetailsRoutes,
                    ...FeatureDocumentRoutes,
                    ...deviationRoutes,
                    ...taskmanagerRoutes,
                    ...timetableRoutes,
                    ...safetyjobRoutes,
                    ...registrationRoutes
                ]
            }
        ]
    }
];

