<div class="tab-content">
    <div class="page-title">
        <span translate>EARTH_LEAKAGE_BREAKER</span>
    </div>

    <form [formGroup]="measureProtocolEarthLeakage">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group" *ngIf="measureProtocolEarthLeakage.controls['CircuitNumber']">
                    <label for="Title">
                        <span translate>CIRCUITNUMBER</span>
                    </label>
                    <div>
                        <input *ngIf="!isElectro" type="number" formControlName="CircuitNumber" id="CircuitNo"
                            class="form-control" />
                        <p-dropdown *ngIf="isElectro" required [style]="{'width':'100%'}" [options]="courses"
                            formControlName="CircuitNumber"></p-dropdown>
                    </div>
                </div>
                <div class="form-group" *ngIf="measureProtocolEarthLeakage.controls['ELBreaker']">
                    <label>
                        <span translate>EL_BREAKER_MA</span>
                    </label>
                    <div>
                        <input type="text" formControlName="ELBreaker" id="EarthLeakage" class="form-control" />
                    </div>
                </div>
                <div class="form-group" *ngIf="measureProtocolEarthLeakage.controls['IsTested']">
                    <div>
                        <p-checkbox label="{{'DC' | translate}}" binary="true" formControlName="IsTested"
                            id="TestedType"></p-checkbox>
                    </div>
                </div>
                <div class="form-group" *ngIf="measureProtocolEarthLeakage.controls['ReleasePower']">
                    <label>
                        <span translate>MA</span>
                    </label>
                    <div>
                        <input type="text" formControlName="ReleasePower" id="ReleasePower" class="form-control" />
                    </div>
                </div>
                <div class="form-group" *ngIf="measureProtocolEarthLeakage.controls['DisconnectTime']">
                    <label>
                        <span translate>DISCONNECTING_MS</span>
                    </label>
                    <div>
                        <input type="text" formControlName="DisconnectTime" id="DisconnectingTime"
                            class="form-control" />
                    </div>
                </div>
                <div class="form-group" *ngIf="measureProtocolEarthLeakage.controls['Comments']">
                    <label>
                        <span translate>COMMENTS</span>
                    </label>
                    <div>
                        <textarea type="text" formControlName="Comments" id="Comments" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <button class="btn btn-primary" [disabled]="saveprogress" (click)="saveChanges()">
                <span translate>SAVE</span>
            </button>
            <button type="button" class="btn btn-outline-secondary" (click)="onClear()">
                <span translate>CLEAR</span>
            </button>
        </div>
    </form>
    <div class="mtop-20">
        <div class="page-title" translate>EL</div>
        <!-- Table starts -->
        <p-table #dt [value]="earthLeakage" scrollable="true" [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]" scrollHeight="200px">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="CircuitNumber" *ngIf="!isElectro">{{'CIRCUITNUMBER' | translate}} <p-sortIcon
                            field="CircuitNumber"></p-sortIcon></th>
                    <th pSortableColumn="CircuitNumberCourse" *ngIf="isElectro">{{'CIRCUITNUMBER' | translate}}
                        <p-sortIcon field="CircuitNumberCourse"></p-sortIcon></th>
                    <th pSortableColumn="ELBreaker">{{'EL_BREAKER_MA' | translate}} <p-sortIcon
                            field="ELBreaker"></p-sortIcon></th>
                    <th pSortableColumn="ReleasePower">{{'RELEASE_MA' | translate}} <p-sortIcon
                            field="ReleasePower"></p-sortIcon></th>
                    <th pSortableColumn="DisconnectTime">{{'DISCONNECTING_MS' | translate}} <p-sortIcon
                            field="DisconnectTime"></p-sortIcon></th>
                    <th pSortableColumn="CreatedOn">{{'CREATED_ON' | translate}} <p-sortIcon
                            field="CreatedOn"></p-sortIcon></th>
                    <th pSortableColumn="EditedBy">{{'EDITED_BY' | translate}} <p-sortIcon
                            field="EditedBy"></p-sortIcon></th>
                    <th>{{'OPTIONS'| translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-el>
                <tr>
                    <td *ngIf="!isElectro">
                        <span>{{el.CircuitNumber}}</span>
                    </td>
                    <td *ngIf="isElectro">
                        <span>{{el.CircuitNumberCourse}}</span>
                    </td>
                    <td>
                        <span>{{el.ELBreaker}}</span>
                    </td>
                    <td>
                        <span>{{el.ReleasePower}}</span>
                    </td>
                    <td>
                        <span>{{el.DisconnectTime}}</span>
                    </td>
                    <td>
                        <span>{{el.CreatedOn | date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{el.EditedBy}}</span>
                    </td>
                    <td class="col-button">
                        <a class="btn btn-icon" title="{{'EDIT' | translate }}" (click)="editEarthLeakage(el['Id'])"
                            routerLinkActive="active">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </a>
                        <button class="btn btn-icon" title="{{'DELETE' | translate }}"
                            (click)="deleteEarthLeakage(el['Id'])" routerLinkActive="active">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends-->
        <div *ngIf="!isElectro" class="action-btns-wrapper text-right">
            <button type="button" class="btn btn-outline-primary" [routerLink]="['./../powerleakage']">
                <span translate>NEXT</span> <span class="icon ic-xs icon-forward"></span>
            </button>
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<p-dialog header="{{('CONFIRMATION') | translate}}" *ngIf="ShowConfirm" [(visible)]="ShowConfirm" [modal]="true"
    [style]="{width: '500px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'visible'}">
    <span translate>DELETE_THIS_RECORD</span>
    <div class="action-btns-wrapper">
        <button type="submit" class="btn btn-primary" (click)="Confirmation(true)">
            <span class="pi pi-check"></span><span translate>SWITCH_YES</span>
        </button>
        <button type="submit" class="btn btn-primary" (click)="Confirmation(false)">
            <span class="pi pi-times"></span><span translate>SWITCH_NO</span>
        </button>
    </div>
</p-dialog>