
import { Route } from '@angular/router';

import { MoveBusinessComponent } from './components/move-business.component';
import { AdminAndPortalListResolver } from './services/move-business.resolver';
import { AdminTabGuard } from 'src/app/_guards';

export const MoveBusinessRoutes: Route[] = [
    {
        path: 'move-business',
        component: MoveBusinessComponent,
        canActivate: [AdminTabGuard],
        resolve: {
            list: AdminAndPortalListResolver
        },
    },
];
