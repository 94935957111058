export class ElectroContractContent {
    Id: number;
    BusinessId: number;
    Name: string;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    InspectionId: number;
    constructor() {
        this.Id = 0;
        this.Status = 1;       
    }
}