<div class="table-view">
    <!-- Table starts -->
    <p-table #dt [value]="consumptiondata" [rows]="10" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>Title</th>
                <th>LanguageId</th>
                <th>Description</th>
                <th>BaseUrl</th>
                <th>DocsPath</th>
                <th>LogoPath</th>
                <th>DomainUrl</th>
                <th>DocsPath</th>
                <th>LogoPath</th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-appList>
            <tr>
                <td>
                    <span>{{appList.Title}}</span>
                </td>
                <td>
                    <span>{{appList.LanguageId}}</span>
                </td>
                <td>
                    <span>{{appList.Description}}</span>
                </td>
                <td>
                    <span>{{appList.BaseUrl}}</span>
                </td>
                <td>
                    <span>{{appList.DocsPath}}</span>
                </td>
                <td>
                    <span>{{appList.LogoPath}}</span>
                </td>
                <td>
                    <span>{{appList.DomainUrl}}</span>
                </td>
                <td>
                    <span>{{appList.Theme}}</span>
                </td>
                <td>
                    <span>{{appList.Language}}</span>
                </td>
                <td class="col-button">
                    <a routerLink="new" [routerLink]="['./../new',appList.Id]" class="btn btn-icon"
                        routerLinkActive="active" title="{{'EDIT' | translate}}"><i
                            class="icon ic-sm icon-pencil-tip"></i></a>
                    <button type="button" class="btn btn-icon" (click)="confirm()"
                        title="{{'DELETE' | translate}}"><i class="icon ic-sm icon-trash"></i></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
</div>