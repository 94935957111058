export enum ElectroFeature {
    EDIT_PRINT_MANUAL = 181,
    ORGANIZATION_AND_RESPONSIBILTIES = 182,
    CONTRACTS = 183,
    INTERNAL_CONTROL_SYSTEM = 184,
    INSTALLATION = 185,
    INSPECTION = 186,
    INSPECTION_REPORT_TEMPLATES = 187,
    LAWS_REGULATION = 188,
    ELECTROARCHIVE = 189
}

export enum ElectroInstallatioForms {
    ASSESSMENT_OF_AVAILABLE_DOCUMENTATION = 33,
    RISK_ASSESSMENT_OF_THE_INSTALLATION = 34,
    ELECTRICAL_DISTRIBUTION_OVERVIEW = 35,
    ELECTRICAL_COURSE_OVERVIEW = 36,
    FIRE_ALARM_SYSTEM = 37,
    EMERGENCY_LIGHTING_SYSTEM = 38,
    FIRE_SPRINKLER_SYSTEM = 39,
    MEASURE_PROTOCOL = 45,
}

export enum InspectionTaskStatus {
    NOTSTARTED = 1,
    STARTED = 2,
    COMPLETED = 3
}

export enum InspectionStatus {
    BookInspection = 13,
    InspectionPlanned = 14,
    InspectionStarted = 15,
    Completed = 16
}