import { Injectable } from '@angular/core';
import { DeviationServices } from './deviation.services';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TypeOfCase } from '../models/deviation';
import { Topic } from '../models/riskanalysis';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { RuhService } from './ruh.services';


@Injectable()
export class RuhCaseTypeResolver implements Resolve<TypeOfCase[]> {
  constructor(private backend: RuhService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<TypeOfCase[]> {
    return this.backend.getAllCaseType();
  }
}
@Injectable()
export class RuhTopicResolver implements Resolve<Topic[]> {
  constructor(private backend: RuhService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Topic[]> {
    return this.backend.getAllTopics(BaseServices.BusinessId);
  }
}


@Injectable()
export class ActionPlanResolver implements Resolve<any[]> {
    constructor(private backend: RuhService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllActionPlan(+route.params['ruhid']);
    }
}
 