import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { KubaExportService } from './kuba-export.service';
import { FeatureKey } from 'src/app/_models';
import { FeaturesBaseServices } from 'src/app/kuba/features/services/features-base.services';
import { FeatureBase } from 'src/app/kuba/features/models/features-base.model';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { BaseServices } from './../../kuba.services';
import { Subscription } from 'rxjs';
@Component({
    selector: 'kuba-export',
    templateUrl: 'kuba-export.component.html'
})

export class KubaExportComponent implements OnInit {
    @Input() reportData: any;
    @Input() additionalData: any;
    @Input() printMode?: number;
    @Input() isPDF?: boolean;
    @Output() loading = new EventEmitter();
    exportFilename: string;
    showFormat = true;
    isPdfonly = false;
    featureBase: FeatureBase;
    private subscriptions: Subscription[] = [];

    constructor(private kubaExportService: KubaExportService,
        private translate: TranslateService, public featuresBaseServices: FeaturesBaseServices,
        private businessServices: BusinessServices, ) { }

    ngOnInit() {
        // if (this.additionalData.showFormat !== undefined && this.additionalData !== undefined ) {
        //     this.showFormat = this.additionalData.showFormat;
        // }
        this.isPdfonly = this.isPDF != null ? true : false;
    }

    exportReport(type: string) {
        this.loading.emit(true);
        
        let businessId = 0;
        let CultureInfo = "en-GB";
        if (this.additionalData.businessId != undefined) {
            businessId = this.additionalData.businessId;
        }
        if (this.additionalData.cultureInfo != undefined) {
            CultureInfo = this.additionalData.cultureInfo;
        }

        let languageMode = sessionStorage.getItem('languageMode')
        if (languageMode != null) {
            CultureInfo = languageMode;
            this.exportFilename = this.translate.instant(this.additionalData.fileName);
        }
        this.subscriptions.push(this.kubaExportService
                .generateExportFile(this.reportData, this.additionalData.columnNames, type, this.additionalData.header, this.printMode, businessId, CultureInfo)
                .subscribe(
                    blob => {
                        this.loading.emit(false);
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${this.exportFilename}.${type.toLowerCase()}`;
                        link.click();
                    },
                    error => {
                        alert(this.translate.instant('EXPORT_NOT_DOWNLOAD'));
                    }));
      
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
