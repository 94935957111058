<div class="page-title">
    <span class="page-actions">
        <p-dropdown *ngIf="isGuestHideButton" [(ngModel)]="selectedForm" [options]="enabledForms"
          styleClass="text-left"></p-dropdown>
        <button *ngIf="isGuestHideButton" type="button" class="btn btn-outline-primary" (click)="onFillOutClick()">
            <span translate>FILL_OUT</span>
        </button>
    </span>
</div>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-3">
                <app-ecpl-document-tree *ngIf="treeData" treeId=0 [(ecplTreeData)]="treeData"
                  (ecplNodeEvent)="catchTreeEvent($event)" [(ecplTreeCheckbox)]="editMode">
                </app-ecpl-document-tree>
            </div>
            <div class="col-sm-9">
                <div>
                    <ecpl-document-explorer [(triggerUploader)]="triggerUploader" [uploaderConfig]="upConfig"
                      [uploadedFiles]="uploadedFiles">
                    </ecpl-document-explorer>
                </div>
            </div>
        </div>
    </div>
</div>