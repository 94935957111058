import {
  Component,
  OnInit,
  AfterViewChecked,
  ViewChild,
  Input,
  Renderer2,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Feature, FeatureBase } from './../features/models/features-base.model';
import { DashboardService } from './Services/dashboard.services';
import { News } from './../news/models/news';
import { BaseServices } from './../kuba.services';
import {
  Activity,
  ActivityCalendar,
  Riskanalysis,
  BusinessInfo,
  LoggedUser,
  AboutKuba,
  Message,
  CMSPage,
} from './models/dashboard';
import { Meeting } from './../meeting/models/meeting';
import { NewsServices } from './../news/services/news.service';
import { BusinessPortalService } from './../portals/services/business-portal.services';
import { BusinessServices } from '../businesses/services/business.services';
import * as _ from 'lodash';
import { FeatureKey, Rights } from 'src/app/_models/feature';
import * as Masonry from 'masonry-layout';
import { UIChart } from 'primeng/chart';
import { FeaturesBaseServices } from '../features/services/features-base.services';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/theme.service';
import { CalendarOptions } from '@fullcalendar/core';
import { RiskanalysisService } from '../follow-ups/services/risk-analysis.service';
@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewChecked {
  //#region variable
  /* Commented for KUBA 3.0 initial version check */
  // calendarOptions: Options;
  // @ViewChild(CalendarComponent, {static: false}) ucCalendar: CalendarComponent;
  @Input() featureBase: FeatureBase;
  @ViewChild('appLogin', { static: false }) chart: UIChart;
  public news: any;
  viewLogged = true;
  dateDisabled: boolean;
  meeting: FormGroup;
  riskAnalysis: any = [];
  internalNews: any = [];
  adminNews: any = [];
  lang: string;
  meetingId: number;
  activities: any = [];
  isAdmin: boolean;
  isPortal: boolean;
  isUser: boolean;
  isGuest: boolean;
  isEditor: boolean;
  userSettings: any;
  returnUrl: string;
  measures: any = [];
  businessFunction: any = [];
  BusinessName: string;
  BusinessImage: string;
  AboutBusiness: string;
  HseGoals: string;
  zipCode: string;
  Telephone: string;
  loggedUser: any = [];
  appLoggedUser: any[];
  today = new Date();
  yesterday = new Date();
  dayBeforeYesterday = new Date();
  isAppLog: boolean;
  aboutKuba: string;
  internalTv: string;
  hmsTv: string;
  portalTv: string;
  portalNews: string;
  deviationCount: any[];
  links: any[];
  messages: any = [];
  dashboardAdmin: string;
  hasFollowupRights = false;
  hasNewsRights = false;
  hasTVRights = false;
  hasMeasureRights = false;
  hasCalendarRights = false;
  hasActivitiesRights = false;
  hasRiskRights: boolean;
  hasDeviationRights: boolean;
  hasMessageRights: boolean;
  hasMeetingRights: boolean;
  adminPostalAddress: string;
  adminAddress: string;
  businessId: number;
  defaultVideo: string;
  applicationName: string;
  hasHMSTVRights = false;
  hasLinksRights = false;
  hasDLinksRights = false;
  hasInternalNewsRights = false;
  hasPortalNewsRights = false;
  hasAboutKubaRights = false;
  hasInternalTVRights = false;
  hasPortalTVRights = false;
  hseManager: string;
  Envlight: string;
  assetPath: string = environment.BASE_ASSET_PATH;
  checked = false;
  calenderloaded = false;
  activityLoaded = false;
  applicationId: number;
  isActivity = true;
  isRiskAnalysis = true;
  isDeviation = true;
  description: any;
  hasLoggedHistory = false;
  hasAppHistory = false;
  isPortalBusiness = false;
  Portallinks: any[];
  hasPortalLinksRights = false;
  userRoleCheck: string;
  meetingDate: any;
  options: any;
  isMedi3: boolean = false;
  calendarOptions: CalendarOptions;
  assetloc: string = environment.BASE_STYLES_PATH;
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructor

  /**
   * constructor
   * @param route {ActivatedRoute}
   * @param newsServices {NewsServices}
   * @param _fb {FormBuilder}
   * @param featuresBaseServices {FeaturesBaseServices}
   */
  constructor(
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    public newsServices: NewsServices,
    public featuresBaseServices: FeaturesBaseServices,
    private portalSvc: BusinessPortalService,
    public businessServices: BusinessServices,
    private router: Router,
    public dashboardService: DashboardService,
    private riskanalysisService: RiskanalysisService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private themeService: ThemeService
  ) {
    this.meeting = _fb.group({
      TypeOfMeet: [''],
      Name: [''],
      Date: [''],
      StartTime: [''],
      EndTime: [''],
    });
    this.isPortalBusiness = this.route.snapshot.data['PortalBusiness'];
  }

  //#endregion

  //#region page-event

  /**
   * initialize
   */
  ngOnInit(): void {
    document.querySelector('body').classList.add('opened');
    this.userRoleCheck = BaseServices.roleId;
    // alert(this.userRoleCheck);

    this.isAdmin =
      BaseServices.ApplicationId == 5 && BaseServices.roleId === '1'
        ? false
        : BaseServices.roleId === '1'
        ? true
        : false;
    this.isPortal = BaseServices.roleId === '2' ? true : false;
    this.isEditor = BaseServices.roleId === '3' ? true : false;
    this.isUser = BaseServices.roleId === '5' ? true : false;
    this.isGuest = BaseServices.roleId === '4' ? true : false;
    this.BindPortalLinks();
    if (+BaseServices.roleId === 5) {
      this.onAdministrateActivity();
      this.onAdministrateRiskAnalysis();
    }
    if (BaseServices.ApplicationId == 5) {
      this.isMedi3 = true;
    }
    this.applicationId = +BaseServices.ApplicationId;
    this.bindFeatureBasedPanel();
    if (window.name === 'Remote') {
      // var themePath = this.assetloc+ BaseServices.userTheme.Stylepath;
      // this.themeService.setTheme(themePath);
      // this.themeService.applyTheme();
      const themePath = this.assetloc + BaseServices.userTheme.Stylepath;
      const themeId = BaseServices.userTheme.Id;
      this.themeService.setTheme(themeId);
      this.themeService.applyTheme();
      this.featuresBaseServices.setRModeFeatureEditor(true);
    } else {
      // if r mode need to fetch feature and the calendar events
      this.bindCalendarEvents();
    }
    this.bindActivity();
    if (
      window.name === 'Remote' &&
      this.calenderloaded === true &&
      this.activityLoaded === true
    ) {
      this.loadComponents();
    } else {
      this.loadComponents();
    }
  }

  loadComponents() {
    this.businessId = BaseServices.BusinessId;
    this.bindNews();
    this.bindMeasures();
    this.bindBusiness();
    this.bindUserHits();
    this.bindAboutKuba();
    this.bindInternalTV();
    this.bindLinks();
    this.bindMessages();
    this.bindMeeting();
    this.bindDeviationCount();
    this.bindRiskAnalysis();
    this.onDisplayOwnDeviation();
  }

  ngAfterViewChecked(): void {
    if (this.calenderloaded === true) {
      setTimeout((x: any) => {
        if (!this.checked) {
          this.setWidgetHeight();
          this.masonryGrid();
        }
      }, 1500);
    }
  }
  //#endregion

  //#region method

  /**
   * bind panels based on feature
   */
  bindFeatureBasedPanel() {
    if (window.name === 'Remote') {
      this.subscriptions.push(
        this.dashboardService.getRModeFeature().subscribe((feature: any) => {
          if (feature) {
            let RModefeaturesDetails =
              FeaturesBaseServices.getFeatureDetails(feature);
            this.getRModeBusiness(RModefeaturesDetails);
            this.bindCalendarEvents();
          }
        })
      );
    } else {
      if (this.isAdmin || this.isPortal) {
        this.getRModeBusiness(BaseServices.getUserFeatures());
      } else {
        let featuresBaseDetails = FeaturesBaseServices.getFeatureDetails(
          BaseServices.getUserFeatures()
        );
        this.getRModeBusiness(featuresBaseDetails);
      }
    }
  }
  /**
   * bind panel
   * @param businessFeature {FeatureBase}
   */
  getRModeBusiness(features: any) {
    let featureData =
      this.isAdmin || this.isPortal ? JSON.parse(features.Features) : features;
    if (featureData) {
      if (featureData.length > 0) {
        this.hasFollowupRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.FOLLOW_UP); // followup
        this.hasActivitiesRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.ACTIVITIES); // activities
        if (window.name === 'Remote') {
          this.hasMessageRights = false; // message based on user not for business
        } else {
          this.hasMessageRights =
            this.isAdmin || this.flattenFind(featureData, FeatureKey.MESSAGES); // message
        }
        this.hasDeviationRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.DEVIATIONS); // deviation
        this.hasRiskRights =
          this.isAdmin ||
          this.flattenFind(featureData, FeatureKey.RISK_ANALYSIS); // riskanalysis
        this.hasMeetingRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.MEETING); // meeting
        this.hasMeasureRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.CONSUMPTION); // consumption
        this.hasNewsRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.ADMIN_NEWS); // admin news
        this.hasNewsRights =
          this.isAdmin ||
          this.flattenFind(featureData, FeatureKey.ADMIN_NEWS_MEDI3); // admin news
        this.hasInternalNewsRights =
          this.isAdmin ||
          this.flattenFind(featureData, FeatureKey.INTERNAL_NEWS); // internal news
        this.hasPortalNewsRights =
          this.isAdmin ||
          this.flattenFind(featureData, FeatureKey.NEWS_FROM_PORTAL); // portal news
        this.hasHMSTVRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.HMS_TV); // hms-tv
        this.hasLinksRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.LINKS); // ourcompany links
        this.hasDLinksRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.DLINKS); // dashboard links
        this.hasAboutKubaRights =
          this.isAdmin ||
          this.flattenFind(featureData, FeatureKey.ABOUT_KUBA_IK); // about kuba
        this.hasInternalTVRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.INTERNAL_TV); // internal tv
        this.hasPortalTVRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.PORTAL_TV); // portal tv
        this.hasCalendarRights =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.CALENDAR); // calendar
        this.hasLoggedHistory =
          this.isAdmin ||
          this.flattenFind(featureData, FeatureKey.LOGGED_HISTORY); // logged history
        this.hasAppHistory =
          this.isAdmin || this.flattenFind(featureData, FeatureKey.APP_HISTORY);
        this.hasPortalLinksRights = this.flattenFind(
          featureData,
          FeatureKey.PORTALLINKS
        ); // App logged history
      }
    }
  }

  /**
   * recursive search in a nested array
   * @param data {array}
   * @param featureId {number}
   */
  flattenFind(data: any, key: any) {
    let checkFeature = <Feature>_(data)
      .thru((parent) => {
        return _.union(parent, _.map(parent, 'children'));
      })
      .flatten()
      .find({ id: +key });
    if (checkFeature !== undefined) {
      return checkFeature.checked;
    } else {
      return false;
    }
  }

  /**
   * binding meeting panel
   */
  bindMeeting() {
    let meeting = <Meeting>this.route.snapshot.data['meeting'];
    if (meeting != null) {
      this.meetingId = meeting.Id;
      this.meetingDate = meeting.Date;
      let meetingInfo = {
        TypeOfMeet: meeting.MeetingTypeText,
        Name: meeting.MeetingConverner,
        Date:
          `${new Date(meeting.Date).getDate()}` +
          '/' +
          `${new Date(meeting.Date).getMonth() + 1}` +
          '/' +
          `${new Date(meeting.Date).getFullYear()}`,
        StartTime: meeting.StartTime.substring(0, 5),
        EndTime: meeting.EndTime.substring(0, 5),
      };
      (<FormGroup>this.meeting).setValue(meetingInfo, { onlySelf: true });
    }
  }
  bindActivity() {
    this.activities = <Activity[]>this.route.snapshot.data['activity'];
    if (this.activities === null) {
      this.activities = [];
      let activity = new Activity();
      activity.Title = '';
      activity.EndDate = '';
      activity.ExecutionUsers = '';
      activity.BusinessName = '';
      activity.Id = 0;
      this.activities.push(activity);
      this.activityLoaded = true;
    }
    if (this.activities) {
      this.activities.forEach((activity: any) => {
        activity.Responsible = false;
        if (activity.FollowupUserIdList) {
          for (let i = 0; i < activity.FollowupUserIdList.length; i++) {
            if (BaseServices.UserId === activity.FollowupUserIdList[i]) {
              activity.Responsible = true;
            }
          }
        }
        if (!activity.Responsible) {
          if (activity.ExecutionUserIdList) {
            for (let i = 0; i < activity.ExecutionUserIdList.length; i++) {
              if (BaseServices.UserId === activity.ExecutionUserIdList[i]) {
                activity.Responsible = true;
              }
            }
          }
        }
        if (!activity.Responsible) {
          if (BaseServices.UserId == activity.CreatedBy) {
            activity.Responsible = true;
          }
        }
      });
    }
  }
  /**
   * deviation count bindings
   */
  bindDeviationCount() {
    this.deviationCount = [];
    let deviationCount = this.route.snapshot.data['deviation'];
    if (deviationCount != null) {
      this.deviationCount = deviationCount;
    }
  }

  onDisplayOwnDeviation() {
    let isDisplayOwnDeviation: boolean;
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.DISPLAY_OWN_DEVIATION_ONLY;
    if (currentUserRole === 'Guest') {
      let userRightsId = Rights.ADMINISTRATE_DEVIATION;
      this.isDeviation = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (this.isDeviation) {
        this.subscriptions.push(
          this.dashboardService
            .getDeviationByUserBasedOnAdministrateRights(
              BaseServices.BusinessId
            )
            .subscribe((count: any) => {
              if (count) {
                this.deviationCount = count;
              }
            })
        );
      }
      isDisplayOwnDeviation = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      if (isDisplayOwnDeviation === true) {
        this.subscriptions.push(
          this.dashboardService
            .getDeviationByUser(BaseServices.roleId)
            .subscribe((result: any) => {
              if (result) {
                this.deviationCount = result;
              }
            })
        );
      } else {
        this.onSeeOnlyDeviationInOwnDepartment();
      }
    }
  }

  onSeeOnlyDeviationInOwnDepartment() {
    let isShowDeptDeviation: boolean;
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.SEE_ONLY_DEVIATION_IN_OWN_DEPT;
    isShowDeptDeviation = BaseServices.checkUserRights(
      userRightsId,
      currentUserRole
    );
    if (isShowDeptDeviation) {
      this.subscriptions.push(
        this.dashboardService
          .getDeviationByUserBasedOnDept(BaseServices.roleId)
          .subscribe((deviations: any) => {
            if (deviations != null) {
              this.deviationCount = deviations;
            }
          })
      );
    }
  }
  /**
   * Fetch risk analysis from service
   */
  bindRiskAnalysis() {
    // Call Portal service if you are portal user
    if (this.isPortal) {
      this.riskanalysisService
        .getPortalRiskAnalysis(BaseServices.UserId)
        .subscribe(
          (data: Riskanalysis[]) => {
            this.riskAnalysis = data.length ? data : [new Riskanalysis()];
          },
          (error) => {
            console.error('Error fetching risk analysis:', error);
            this.riskAnalysis = [new Riskanalysis()];
          }
        );
    }

    // Call the service if you are in business level
    if (!this.isAdmin && !this.isPortal) {
      this.riskanalysisService.list().subscribe(
        (data: Riskanalysis[]) => {
          this.riskAnalysis = data.length ? data : [new Riskanalysis()];
        },
        (error) => {
          console.error('Error fetching risk analysis:', error);
          this.riskAnalysis = [new Riskanalysis()];
        }
      );
    }
  }

  /**
   * news and internal news analysis binding
   */
  bindNews() {
    this.internalNews = <News[]>this.route.snapshot.data['internalNews'];
    this.adminNews = <News[]>this.route.snapshot.data['adminNews'];
  }
  /**
   * binding messages
   */
  bindMessages() {
    this.messages = [];
    let message = <Message[]>this.route.snapshot.data['messages'];
    if (message !== null) {
      this.messages = message;
    }
  }
  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() + 1,
    };
  }
  /**
   * measures count bindings
   */
  bindMeasures() {
    let measure = <any>this.route.snapshot.data['measure'];
    if (measure != null) {
      this.measures = measure.slice(0, 5);
    }
  }
  /**
   * Remove Duplicate Id
   */
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject: any = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  /**
   * business info
   */
  bindBusiness() {
    let business = <BusinessInfo>this.route.snapshot.data['business'];
    let businesFunctionList = <BusinessInfo[]>(
      this.route.snapshot.data['businessFunction']
    );
    let businesFunction = this.removeDuplicates(businesFunctionList, 'Id');
    if (business != null) {
      this.BusinessName = business.BusinessName ? business.BusinessName : '';
      this.adminAddress = business.AdminAddress ? business.AdminAddress : '';
      this.adminPostalAddress = business.AdminPostalAddress
        ? business.AdminPostalAddress
        : '';
      this.BusinessImage = business.BusinessImg ? business.BusinessImg : '';
      this.AboutBusiness = business.AboutBusiness ? business.AboutBusiness : '';
      this.HseGoals = business.HseGoals ? business.HseGoals : '';
      this.zipCode = business.ZipCode ? business.ZipCode : '';
      this.Telephone = business.Telephone ? business.Telephone : '';
      this.hseManager = business.HSEManager ? business.HSEManager : '';
      this.Envlight = business.Environmentallighthouse
        ? business.Environmentallighthouse
        : '';
    }
    if (BaseServices.roleId !== '1') {
      if (BaseServices.roleId !== '2') {
        this.businessFunction = businesFunction;
      }
    }

    if (BaseServices.roleId === '2' && business !== null) {
      this.subscriptions.push(
        this.portalSvc.getById(business.Id).subscribe((result) => {
          this.description = result.Description;
        })
      );
    }
  }
  /**
   * logged hits
   */
  bindUserHits() {
    let user = <LoggedUser[]>this.route.snapshot.data['loggedUser'];
    let appUser = <LoggedUser[]>this.route.snapshot.data['appLoggedUser'];
    let date = new Date();
    this.today = date;
    this.yesterday = new Date(this.yesterday.setDate(date.getDate() - 1));
    this.dayBeforeYesterday = new Date(
      this.dayBeforeYesterday.setDate(date.getDate() - 2)
    );
    if (user != null) {
      this.loggedUser = user;
      let appLog = appUser.slice(0, 10);
      if (appLog.length > 0) {
        this.isAppLog = true;
        this.appLoggedUser = appLog;
      } else {
        this.isAppLog = false;
      }
    }
  }
  /**
   * about kuba
   */
  bindAboutKuba() {
    let tvInfo = <AboutKuba[]>this.route.snapshot.data['aboutUs'];
    if (tvInfo.length > 0) {
      this.applicationName = tvInfo[0].ApplicationName;
      // default video
      let defaultVideo = tvInfo.filter((x) => x.CmsId === CMSPage.DefaultVideo);
      this.defaultVideo = defaultVideo[0]
        ? defaultVideo[0].ContentData
          ? defaultVideo[0].ContentData
          : ''
        : '';
      let hms = tvInfo.filter(
        (x) => x.CmsId === CMSPage.HmsTv && x.ContentData != null
      );
      if (hms.length > 0) {
        this.hmsTv = hms[0].ContentData;
      } else {
        this.hmsTv = this.defaultVideo;
      }
      let portal = tvInfo.filter(
        (x) => x.CmsId === CMSPage.PortalTV && x.ContentData != null
      );
      if (portal.length > 0) {
        this.portalTv = portal[0].ContentData;
      } else {
        this.portalTv = this.defaultVideo;
      }
      let news = tvInfo.filter((x) => x.CmsId === CMSPage.PortalNews);
      if (news.length > 0) {
        this.portalNews = news[0].ContentData;
      }
      let aboutKuba = tvInfo.filter((x) => x.CmsId === CMSPage.AboutKubaIk);
      if (aboutKuba.length > 0) {
        this.aboutKuba = aboutKuba[0].ContentData;
      }
      if (+BaseServices.roleId === 1) {
        let dashboardText = tvInfo.filter(
          (x) => x.CmsId === CMSPage.DashboardText
        );
        if (dashboardText.length > 0) {
          this.dashboardAdmin = dashboardText[0].ContentData;
        }
      }
    }
  }
  /**
   * calendar events
   */
  bindCalendarEvents() {
    let user = <ActivityCalendar[]>this.route.snapshot.data['calendarActivity'];
    if (!this.hasFollowupRights) {
      user = [];
    }
    /* Commented for KUBA 3.0 initial version check */
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin],
      locale: this.translateService.currentLang,
      dayMaxEvents: 1,
      height: 264,
      aspectRatio: 2,
      buttonText: {
        today: this.translateService.instant('TODAY'),
      },
      firstDay: 1,
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'today prev,next',
      },
      titleFormat: { month: 'short', year: 'numeric' },
      events: [],
      moreLinkText: this.translateService.instant('MORE'),
    };
  }
  /**
   * link panel
   */
  bindLinks() {
    let linkList = this.route.snapshot.data['links'];
    if (linkList) {
      this.links = linkList;
    }
  }
  /**
   * calendar click event
   * @param model {any}
   */
  eventClick(model: any) {
    if (model.event.type === 'ACTIVITY') {
      this.router.navigate(
        [`./../follow-up/activities/edit/${model.event.id}`],
        { relativeTo: this.route }
      );
    } else if (model.event.type === 'MEETING') {
      this.router.navigate([`./../follow-up/meetings/new/${model.event.id}`], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(
        [`./../follow-up/deviations/edit/${model.event.id}`],
        { relativeTo: this.route }
      );
    }
  }
  /**
   * news redirect
   * @param news {any}
   */
  newsEdit(news: any) {
    this.router.navigate(
      [`/app/admin/${BaseServices.ApplicationId}/news/create/${news.Id}`],
      { relativeTo: this.route }
    );
  }
  /**
   * bind internal TV
   */
  bindInternalTV() {
    let video = this.route.snapshot.data['internaltv'];
    if (video) {
      this.internalTv = video.Path ? video.Path : this.defaultVideo;
    } else {
      this.internalTv = this.defaultVideo;
    }
  }

  setWidgetHeight() {
    this.checked = true;
    var ele = document.querySelector('.inner-wrapper');

    if (ele instanceof HTMLElement) {
      // Rest of your code here
      if (ele.scrollHeight > ele.offsetHeight) {
        if (ele.scrollHeight < 544) {
          ele.style.height = '544px';
        } else if (ele.scrollHeight < 824) {
          ele.style.height = '824px';
        } else {
          ele.style.height = '824px';
        }
      } else {
        if (ele.offsetHeight < 544) {
          ele.style.height = '544px';
        } else if (ele.offsetHeight < 824) {
          ele.style.height = '824px';
        } else {
          ele.style.height = '824px';
        }
      }
    }
  }

  simpleMasonryGrid() {
    let totalColHeight;
    let columnsCount = 3;
    let widgetHeight = 280;
    const col1 = document.querySelector(
      '.masonry-view .masonry-col:first-child'
    ) as HTMLElement;
    const col2 = document.querySelector(
      '.masonry-view .masonry-col:nth-child(2)'
    ) as HTMLElement;
    const col3 = document.querySelector(
      '.masonry-view .masonry-col:last-child'
    ) as HTMLElement;

    const col1Height = col1?.offsetHeight || 0;
    const col2Height = col2?.offsetHeight || 0;
    const col3Height = col3?.offsetHeight || 0;

    if (col1Height >= col2Height && col1Height >= col3Height) {
      totalColHeight = col1Height;
    } else if (col2Height >= col1Height && col2Height >= col3Height) {
      totalColHeight = col2Height;
    } else {
      totalColHeight = col3Height;
    }

    const widgetCount = totalColHeight / widgetHeight;
    const roundOffCount = Math.ceil(widgetCount);
    const setHeight = roundOffCount * widgetHeight;

    const masonryView = document.querySelector('.masonry-view');
    if (masonryView) {
      this.renderer.setStyle(masonryView, 'height', setHeight + 'px');
    }
  }

  masonryGrid() {
    const msnry = new Masonry('.masonry-view', {
      itemSelector: '.masonry-grid-item',
      percentPosition: true,
      stamp: '.stamp',
      resize: true,
    });
    return msnry;
  }
  /**
   * activities based rights
   */
  onAdministrateActivity() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
    if (currentUserRole === 'User') {
      this.isActivity = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    } else if (currentUserRole === 'Guest') {
      this.isActivity = false;
    } else {
      this.isActivity = true;
    }
  }
  /**
   * risk-analysis based rights
   */
  onAdministrateRiskAnalysis() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_RISK_ANALYSIS;
    if (currentUserRole === 'User') {
      this.isRiskAnalysis = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }
  }
  /**
   * deviation based rights
   */
  onAdministrateDeviation() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_DEVIATION;
    if (currentUserRole === 'User') {
      this.isDeviation = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
    }
  }
  //#endregion
  BindPortalLinks() {
    let linkList = this.route.snapshot.data['PortalLinks'];
    if (linkList) {
      this.Portallinks = linkList;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
