
import { CountryCodeResolver } from './../employees/services/employeeWorkExperience.resolver.service';
import { UserRoleResolver } from './../users/services/user.resolver.service';
import { UserByBusinessResolver, BusinessUserEditResolver } from './../businesses/services/business.resolver.service';
import { AdminUserEditResolver, UserListByBusinessResolver, AdminUsersListResolver } from './services/adminuser.resolver.service';
import { AdminUserService } from './services/adminuser.service';
import { AdminUserFormComponent } from './components/adminuser-form.component';
import { BusinessFeatureService } from './../businesses/services/businessFeatures.service';
import { AdminUserListComponent } from './components/adminuser-list.component';
import { AdminUserEditComponent } from './components/adminuser-edit.component';
import { AdminUserAddComponent } from './components/new-adminuser.component';
import { AdminUserRightsComponent } from './components/adminuser-rights.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { AdminUserComponent } from './adminuser.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';


@NgModule({
    imports: [
        RouterModule,
        AppSharedModule,
        CommonModule,
        TranslateModule,
        HttpClientModule,
        CalendarModule,
        FormsModule,
        ReactiveFormsModule,
        EditorModule,
        ContextMenuModule,
        MenuModule,
        DropdownModule,
        TableModule,
        DialogModule,
        ConfirmDialogModule,
        InputTextareaModule,
        InputTextModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        PasswordModule
    ],
    exports: [
        AdminUserComponent,
        AdminUserListComponent,
        AdminUserAddComponent,
        AdminUserEditComponent,
        AdminUserFormComponent,
        AdminUserRightsComponent

    ],
    declarations: [
        AdminUserComponent,
        AdminUserListComponent,
        AdminUserAddComponent,
        AdminUserEditComponent,
        AdminUserFormComponent,
        AdminUserRightsComponent
    ],
    providers: [
        AdminUserService,
        BusinessFeatureService,
        AdminUserEditResolver,
        UserByBusinessResolver,
        BusinessUserEditResolver,
        UserRoleResolver,
        UserListByBusinessResolver,
        CountryCodeResolver,
        AdminUsersListResolver
    ],
})
export class AdminUserModule { }
