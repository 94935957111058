<div class="main-content">
    <div class="main-heading" translate>
        CONTACTS
    </div>
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./list']">
                        <span translate>CONTACTS</span>
                    </a>
                </li>
                <li [routerLinkActive]="['active']" *ngIf="isGuest">
                    <a [routerLink]="['./our']">
                        <span translate>KUBA_NORGE</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>