import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroDeviation } from '../../models/electrodeviation';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroService } from '../../services/electro.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { ElectroInspection } from '../../models/electroinspection';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { Location, DatePipe } from '@angular/common';
import { CustomValidator } from '../shared/custom-validator';
import { SelectItem } from 'primeng/api';
import { CompleteCheckListServices } from 'src/app/kuba/shared/services/complete-checklist.service';
import { Module } from 'src/app/kuba/follow-ups/models/ruh';
import { Rights } from 'src/app/_models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'deviation-form',
    templateUrl: 'deviation-form.component.html'
})

export class DeviationFormComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    inspectionId: number;
    inspection: ElectroInspection;
    deviation: ElectroDeviation;
    deviationId: number;
    deviationForm: FormGroup;
    isOffer: boolean;
    status: any;
    importance: any;
    followup: any;
    execution: any;
    followUpDays: any;
    additionalData: any;
    newDocumentAdded = [];
    uploadedFiles = [];
    upConfig: UploaderConfig;
    isGuestHideButton = true;
    IsChangeNotify = false;
    buildingList: any = [];
    violatedParagraphs: SelectItem[];
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        disableUntil: this.getCurrentDate(),
        inline: false,
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    IsClear: boolean;
    isShowNotifyPopup = false;
    IsExecutionChangeNotify = false;
    IsFollowupChangeNotify = false;
    navOnSaveClick: string;
    clientContactInfo: any;
    private subscriptions: Subscription[] = [];
    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private fb: FormBuilder,
        private location: Location,
        private electroService: ElectroService,
        private datePipe: DatePipe,
        private CompleteCheckListServices: CompleteCheckListServices
    ) {
        this.bindDropdowns();
    }

    ngOnInit() {
        this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.dateOptions= {
                dateFormat: 'dd/mm/yyyy',
                editableDateField: false,
                openSelectorOnInputClick: true,
                firstDayOfWeek: 'su',
                satHighlight: true,
                height: '32px',
                selectionTxtFontSize: '14px',
                disableSince: this.getCurrentDate(),
                inline: false,
                todayBtnTxt: this.translate.instant('TODAY'),
                dayLabels: {
                    su: this.translate.instant('SUN'),
                    mo: this.translate.instant('MON'),
                    tu: this.translate.instant('TUE'),
                    we: this.translate.instant('WED'),
                    th: this.translate.instant('THU'),
                    fr: this.translate.instant('FRI'),
                    sa: this.translate.instant('SAT')
                },
                monthLabels: {
                    1: this.translate.instant('JANUARY'),
                    2: this.translate.instant('FEBRUARY'),
                    3: this.translate.instant('MARCH'),
                    4: this.translate.instant('APRIL'),
                    5: this.translate.instant('MAY'),
                    6: this.translate.instant('JUNE'),
                    7: this.translate.instant('JULY'),
                    8: this.translate.instant('AUGUST'),
                    9: this.translate.instant('SEPTEMBER'),
                    10: this.translate.instant('OCTOBER'),
                    11: this.translate.instant('NOVEMBER'),
                    12: this.translate.instant('DECEMBER')
                }
            }
        });
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ActivityId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: 'ATTACHMENTS',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            // showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/electro/file/upload/deviation',
            addtionalData: this.additionalData
        };
        this.inspectionId = this.route.snapshot.params['Iid'];
        this.deviationId = +this.route.snapshot.params['Did'];
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
            if(BaseServices.FeatureId === Module.CLIENT_CONTACT){
                this.isGuestHideButton = BaseServices.checkUserRights(Rights.EDIT_DEVIATIONS,BaseServices.UserRole);
                if(!this.isGuestHideButton && !this.deviationId){
                    this.isGuestHideButton = BaseServices.checkUserRights(Rights.ADD_DEVIATIONS,BaseServices.UserRole);
                }
            }
        }
        this.clientContactInfo = this.route.snapshot.data['clientContactInfo'];
        this.deviation = this.route.snapshot.data['deviation'];
        if (this.deviation) {
            this.isGuestHideButton = this.deviation.CreatedBy == BaseServices.UserId ? true : this.isGuestHideButton;
            this.initForm(this.deviation);
        }
        else {
            this.initForm();
        }
    }

    /**
     * bind form dropdown data.
     */
    bindDropdowns() {
        this.subscriptions.push(this.translate.stream('SELECT_FOR_STATUS').subscribe(val => {
            this.status = [];
            this.status.push(
                { label: val.SELECT, value: null },
                { label: val.OPEN, value: 8 },
                { label: val.CLOSED, value: 9 },
            );
        }));
        this.subscriptions.push(this.translate.stream('RISKFIGURE_DROPDOWN').subscribe(val => {
            this.importance = [];
            this.importance.push(
                { label: val.SELECT, value: '' },
                { label: val.LOW, value: 1 },
                { label: val.MEDIUM, value: 2 },
                { label: val.HIGH, value: 3 },
            );
        }));
        this.followUpDays = [];
       
        for (let n = 0; n <= 14; n++) {
            if (n === 0) {
              this.followUpDays.push({
                label: this.translate.instant('ON_DEADLINE_DATE'),
                value: n,
              });
            } else {
              this.followUpDays.push({ label: n.toString(), value: n });
            }
          }
        this.followUpDays.push({ label: this.translate.instant('TREE_WEEKS'), value: 21 }, { label: this.translate.instant('ONE_MONTH'), value: 30 });
       
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.followup = [];
            this.execution = [];
            this.buildingList = [];
            this.followup.push(
                { label: val.SELECT, value: null });
            this.execution.push(
                { label: val.SELECT, value: null });
            this.buildingList.push(
                { label: val.SELECT, value: null });
            let followup = this.route.snapshot.data['followuppersons'];
            if (followup) {
                followup.forEach(person => {
                    this.followup.push({ label: person.Name, value: person.Id });
                });
            }
            let execution = this.route.snapshot.data['executionpersons'];
            if (execution) {
                execution.forEach(person => {
                    this.execution.push({ label: person.Name, value: person.Id });
                });
            }
            let building = this.route.snapshot.data['building'];
            if (building) {
                building.forEach(b => {
                    this.buildingList.push({ label: b.BuildingNumber, value: b.Id });
                });
            }
        }));

        let violatedPara = this.route.snapshot.data['violatedPara'];
        this.violatedParagraphs = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.violatedParagraphs = [];
            this.violatedParagraphs.push({ label: val.SELECT, value: null });
            if (violatedPara) {
                violatedPara.forEach((checklist: any) => {
                    this.violatedParagraphs.push({ label: checklist.Value, value: checklist.Id });
                });
            }
        }));
    }

    /**
     * initialize form group and supply values to control on edit.
     * @param data 
     */
    initForm(data?: any) {
        let inspectionNumber = this.inspectionId < 10 ? 'INS-00' + this.inspectionId :
            this.inspectionId < 100 ? 'INS-0' + this.inspectionId : 'INS-' + this.inspectionId;
        let title = '';
        let building = '';
        let location = '';
        let description = '';
        let status = '';
        let importance = '';
        let eventDate = '';
        let eventTime = '';
        let deadline = '';
        let followupUserId = '';
        let executionUserId = '';
        let correctiveAction = '';
        let offerInclude = '';
        let offerPrice = '';
        let paraViolated = [];
        let ischangeNotify = false;
        let isexcecutionChangeNotify = false;
        let isfollowupChangeNotify = false;
        if (data) {
            if(data.FollowupUserId == BaseServices.UserId || data.ExecutionUserId == BaseServices.UserId){
                this.isGuestHideButton = true;
            }
            title = data.Title;
            building = data.Building;
            location = data.Location;
            description = data.Description;
            status = data.Status;
            importance = data.Importance;
            deadline = data.Deadline;
            eventDate = data.EventDate;
            eventTime = data.EventTime;
            followupUserId = data.FollowupUserId;
            executionUserId = data.ExecutionUserId;
            correctiveAction = data.CorrectiveAction;
            offerInclude = data.OfferInclude;
            this.isOffer = data.OfferInclude ? true : false;
            offerPrice = data.OfferPrice;
            if (this.deviation.ParaViolated ? JSON.parse(this.deviation.ParaViolated) : false) {
                JSON.parse(this.deviation.ParaViolated).forEach(p => {
                    paraViolated.push(p);
                });
            }
            ischangeNotify = (data.IsChangeNotifyExecution || data.IsChangeNotifyFollowup) ? true : false;
            isexcecutionChangeNotify = data.IsChangeNotifyExecution;
            isfollowupChangeNotify = data.IsChangeNotifyFollowup;
            this.uploadedFiles = [];
            if (data.ElectroDeviationAttachment) {
                data.ElectroDeviationAttachment.forEach(element => {
                    if (element) {
                        this.uploadedFiles.push(
                            HelperService.switchKeysCase(element, null, 'L')
                        );
                    }
                });
            }
        }
        this.deviationForm = this.fb.group({
            'InspectionNumber': [inspectionNumber],
            'Title': [title, [Validators.required, CustomValidator.noWhitespaceValidator]],
            'Building': [building],
            'Location': [location, [Validators.required, CustomValidator.noWhitespaceValidator]],
            'Description': [description],
            'Status': [status, Validators.required],
            'Importance': [importance, Validators.required],
            'Deadline': [deadline ? HelperService.formatInputDate(deadline) : '', Validators.required],
            'EventDate': [eventDate ? HelperService.formatInputDate(eventDate) : '', Validators.required],
            'EventTime': [eventTime ? HelperService.formatTime(eventTime) : '', Validators.required],
            'FollowupUserId': [followupUserId, Validators.required],
            'ExecutionUserId': [executionUserId, Validators.required],
            'CorrectiveAction': [correctiveAction],
            'ParaViolated': [paraViolated],
            'OfferInclude': [offerInclude],
            'OfferPrice': [offerPrice]
        });
        if (!data) {
            this.bindEventDate();
        }
        if(+this.inspectionId){
            this.subscriptions.push(this.electroService.getInspectionNumberById(this.inspectionId).subscribe(res=>{
                if(res){
                    if(this.deviationId){
                        this.deviationForm.patchValue({'InspectionNumber': res + '-' + this.deviationId});
                    }else{
                        this.subscriptions.push(this.electroService.GetHighestDeviationNumber().subscribe(devN=>{
                            if(devN){
                                this.deviationForm.patchValue({'InspectionNumber': res + '-' + devN});
                            }
                        }))
                    }
                }
            }))
        }
    }

    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() + 1,
        };
    }

    /**
     * number field input control event.
     * @param e 
     * @param limitNumber 
     */
    onNumber(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46)
        ) {
            e.preventDefault();
        }
    }

    /**
     * Trigger event for addinf offer.
     */
    addOffer() {
        this.isOffer = !this.isOffer;
    }

    /**
    * save uploaded file
    */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            x => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            x => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.electroService
                            .deleteDeviationAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        x => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }

    /**
     * Save deviation and return back to list.
     */
    saveBackToList() {
        this.saveDeviation();
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    /**
     * save inspection and go back to inspection list.
     */
    SaveDeviationClick(nav?: string) {
        this.navOnSaveClick = nav;
        this.isShowNotifyPopup = true;
    }
    /**
     * save deviation.
     */
    saveDeviation(nav?: string) {
        let deviation = new ElectroDeviation();
        let CultureInfo = sessionStorage.getItem('languageMode');
        deviation.Title = this.deviationForm.value.Title;
        deviation.EventDate = HelperService.formatDateFilter(this.deviationForm.value.EventDate.formatted);
        deviation.EventTime = HelperService.formatTime(this.deviationForm.value.EventTime);
        deviation.Building = this.deviationForm.value.Building;
        deviation.Location = this.deviationForm.value.Location;
        deviation.Description = this.deviationForm.value.Description;
        deviation.Status = this.deviationForm.value.Status;
        deviation.Importance = this.deviationForm.value.Importance;
        deviation.Deadline = HelperService.formatDateFilter(this.deviationForm.value.Deadline.formatted);
        deviation.ExecutionUserId = this.deviationForm.value.ExecutionUserId;
        deviation.FollowupUserId = this.deviationForm.value.FollowupUserId;
        deviation.CorrectiveAction = this.deviationForm.value.CorrectiveAction;
        deviation.OfferInclude = this.isOffer ? this.deviationForm.value.OfferInclude : null;
        deviation.OfferPrice = this.isOffer ? this.deviationForm.value.OfferPrice : null;
        deviation.ApplicationId = BaseServices.ApplicationId;
        deviation.BusinessId = BaseServices.BusinessId;
        deviation.InspectionId = this.inspectionId;
        deviation.ParaViolated = JSON.stringify(this.deviationForm.value.ParaViolated);
        deviation.ElectroDeviationAttachment = this.newDocumentAdded;
        deviation.IsChangeNotifyExecution = this.IsChangeNotify ? this.IsExecutionChangeNotify : false;
        deviation.IsChangeNotifyFollowup = this.IsChangeNotify ? this.IsFollowupChangeNotify : false;
        if (this.deviationId > 0) {
            deviation.CreatedBy = this.deviation.CreatedBy;
            deviation.CreatedOn = this.deviation.CreatedOn;
            deviation.ModifiedBy = BaseServices.UserId;
            deviation.Id = this.deviationId;
            this.subscriptions.push(this.electroService.updateDeviation(deviation, CultureInfo).subscribe(result => {
                if (result) {
                    this.isShowNotifyPopup = false;
                    this.newDocumentAdded = [];
                    this.toasterComponent.callToast();
                    if (nav === 'back') {
                        this.location.back();
                    }
                }
            }));
        }
        else {
            deviation.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroService.saveDeviation(deviation, CultureInfo).subscribe(result => {
                if (result) {
                    this.deviationId = result.Id;
                    this.deviation = result;
                    this.isShowNotifyPopup = false;
                    this.newDocumentAdded = [];
                    this.toasterComponent.callToast();
                    if (nav === 'back') {
                        this.location.back();
                    }
                }
            }));
        }
    }

    /**
     * bind evnet date and time
     */
    bindEventDate() {
        let dateFormat = new Date();
        this.deviationForm.controls['EventDate'].setValue(HelperService.formatInputDate(new Date()));
        this.deviationForm.controls['EventTime'].setValue(this.datePipe.transform(dateFormat, 'HH:mm'));
        this.IsClear = true;
    }

    /**
     * clear evnet date and time
     */
    clearEventDate() {
        this.deviationForm.controls['EventDate'].setValue(null);
        this.deviationForm.controls['EventTime'].setValue(null);
        this.IsClear = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}