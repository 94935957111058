<div class="container">
    <div class="row">
        <div class="col-sm-8">
            <languages></languages>
        </div>
    </div>
</div>
<div class="login-wrapper">
    <div class="login-panel">
        <div class="login-panel-body">
            <div class="logo">
                <img src="assets/images/KubaLogo.png" alt="">
            </div>
            <a *ngIf="!regenaratePassword" [routerLink]="['/login']" class="btn btn-icon">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LOGIN</span>
            </a>
            <div class="login-form" *ngIf="!regenaratePassword">
                <form [formGroup]="updatePasswordForm">
                    <div formGroupName="passwordValidation">
                        <div class="form-group">
                            <label for="username" class="col-form-label" translate>NEW_PASSWORD
                                <span class="required">*</span>
                            </label>
                            <input type="password" name="username" class="form-control" formControlName="Password" required autofocus/>
                        </div>
                        <div class="form-group">
                            <label for="Email" class="col-form-label" translate>CONFIRM_PASSWORD
                                <span class="required">*</span>
                            </label>
                            <input type="password" name="emailId" class="form-control" formControlName="ConfirmPassword" required />
                        </div>
                        <control-messages [control]="updatePasswordForm.controls['passwordValidation']"></control-messages>
                        <div *ngIf="returnValue === 2">
                            <span style="color:red" translate>PASSWORD_EXIST</span>
                        </div>
                        <div *ngIf="returnValue === 1">
                            <span style="color:green" translate>PASSWORD_SUCCESS</span>
                        </div>
                        <div *ngIf="returnValue === 3">
                            <span style="color:red" translate>PASSWORD_TRY_AGAIN</span>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block" (click)="changePassword()" [disabled]="!updatePasswordForm.valid">
                        <span translate>SUBMIT</span>
                    </button>
                </form>
            </div>
            <div *ngIf="regenaratePassword">
                <span style="color:red" translate>PASSWORD_REQUEST</span>
                <a [routerLink]="['/login']">click here to regenerate again</a>
            </div>
        </div>
    </div>