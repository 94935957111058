
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseServices } from './kuba.services';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {shareReplay } from 'rxjs/operators'
@Injectable()
export class BusinessDepartmentService {
    _allDepartment: Observable<any> | any;
    constructor(private http: HttpClient) {

    }
    clearCachedAllDepartment() {
        this._allDepartment = null;
    }
    getCachedBusinessDepartment() {
        
            this._allDepartment = this.http.get(environment.BASE_URL + '/departments/'
                + BaseServices.BusinessId + '/'
                + BaseServices.roleId,
                BaseServices.headerOption)
                .pipe(map((response) => response)),
                shareReplay(1)
                // refCount();
        
        return this._allDepartment;
    }
}
