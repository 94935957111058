import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Riskanalysis } from 'src/app/kuba/follow-ups/models/riskanalysis';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-risk-analysis-business-widget',
  templateUrl: './risk-analysis-business-widget.component.html',
  styleUrls: ['./risk-analysis-business-widget.component.css'],
  providers: [DatePipe], // Add DatePipe to the providers array
})
export class RiskAnalysisWidgetComponent implements OnInit {
  @Input() riskAnalysis: Riskanalysis[] = [];

  showTable: 'unapproved' | 'approved' = 'unapproved';
  activeButton: 'unapproved' | 'approved' = 'unapproved';
  unapprovedRiskAnalysis: Riskanalysis[] = [];
  approvedRiskAnalysis: Riskanalysis[] = [];

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  /**
   * Filters the risk analysis data into approved and unapproved categories.
   */
  ngOnInit(): void {
    this.filterData();
  }

  /**
   * Filters the risk analysis data if there are changes in riskAnalysis input.
   *
   * @param changes - The new and old values of the data-bound properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['riskAnalysis']) {
      this.filterData();
    }
  }

  /**
   * Removes duplicate entries from the risk analysis array based on unique Id and title.
   *
   * @param arr - The array of risk analysis objects to remove duplicates from.
   * @returns An array of unique risk analysis objects.
   */
  removeDuplicates(arr: Riskanalysis[]): Riskanalysis[] {
    const uniqueIds = new Set<number>();
    return arr.filter((risk) => {
      const isDuplicate = uniqueIds.has(risk.Id);
      const hasEmptyTitle = !risk.Title || risk.Title.trim() === '';

      if (isDuplicate || hasEmptyTitle) {
        return false; // Duplicate or empty title found, don't include in filtered array
      } else {
        uniqueIds.add(risk.Id); // Add to the set of unique IDs
        return true; // First occurrence with non-empty title, include in filtered array
      }
    });
  }

  /**
   * Filters risk analysis data into approved and unapproved categories and sorts them by modification date.
   */
  filterData(): void {
    const uniqueRiskAnalysis = this.removeDuplicates(this.riskAnalysis);

    this.unapprovedRiskAnalysis = uniqueRiskAnalysis
      .filter((risk) => risk.ApprovalStatus !== 'Approved')
      .sort(
        (a, b) =>
          new Date(b.ModifiedOn).getTime() - new Date(a.ModifiedOn).getTime()
      );

    this.approvedRiskAnalysis = uniqueRiskAnalysis
      .filter((risk) => risk.ApprovalStatus === 'Approved')
      .sort(
        (a, b) =>
          new Date(b.ModifiedOn).getTime() - new Date(a.ModifiedOn).getTime()
      );
  }

  /**
   * Displays the unapproved risk analysis table and sets the active button to 'unapproved'.
   */
  showUnapprovedTable(): void {
    this.showTable = 'unapproved';
    this.activeButton = 'unapproved';
  }

  /**
   * Displays the approved risk analysis table and sets the active button to 'approved'.
   */
  showApprovedTable(): void {
    this.showTable = 'approved';
    this.activeButton = 'approved';
  }

  /**
   * Returns the CSS class based on the risk figure value.
   *
   * @param value - The value of the risk figure.
   * @returns A string representing the CSS class for the risk figure.
   */
  getRiskFigureClass(value: string | undefined): string {
    switch (value) {
      case 'LOW':
        return 'chip chip-low';
      case 'MEDIUM':
        return 'chip chip-medium';
      case 'HIGH':
        return 'chip chip-high';
      default:
        return 'chip';
    }
  }

  /**
   * Formats the risk level value for display by capitalizing and translating it.
   *
   * @param value - The value to be formatted.
   * @returns A string representing the formatted and translated risk level.
   */
  formatRiskLevel(value: string | undefined): string {
    if (!value) return '';
    const formattedValue = value.charAt(0) + value.slice(1).toLowerCase();
    return this.translateService.instant(formattedValue.toUpperCase());
  }
}
