 
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { FDVServices } from './../FDV/services/fdv.service'; 
import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { UserListResolver } from './../projects/services/project.resolver';
import {
    TypeCaseResolver,
    DepartmentResolver,
    CreatedByResolver,
    RespForFollowupResolver,
    DepartmentForAdminResolver,
    DeviationManualResolver,
    DeviationResponsibleAssignedUserResolver
} from './services/deviation.resolver';
import { TranslateModule } from '@ngx-translate/core';
import {
    DeviationEditResolver,
    DeviationListResolver
} from './services/deviation.resolver';
import { ListDeviationComponent } from './components/deviation-list/deviation-list.component';
import { DeviationFormComponent } from './components/deviation.component';
import { EditDeviationComponent } from './components/deviation-edit/deviation-edit.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from './../shared/appShared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddDeviationComponent } from './components/deviation-new/deviation-new.component';
import { DeviationServices } from './services/deviation.service';
import {
    FollowupCaseTypeResolver,
    FollowupTopicResolver,
    AllDepartmentCachedResolver,
    ResponsibleForExecutionResolver
} from 'src/app/kuba/follow-ups/services/deviation.resolver.service';
import { EmployeeContactUserResolver } from '../users/services/user.resolver.service';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MyDatePickerModule } from 'mydatepicker';
@NgModule({
    imports:
    [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        TableModule,
        SharedModule,
        DialogModule,
        FileUploadModule,
        AppSharedModule,
        RouterModule,
        TranslateModule,
        EcplDocumentExplorerModule,
        MyDatePickerModule,
        KubaExportModule

    ],

    exports:
    [
        ListDeviationComponent,
        AddDeviationComponent,
        EditDeviationComponent,
        DeviationFormComponent,

    ],
    declarations: [
        ListDeviationComponent,
        AddDeviationComponent,
        EditDeviationComponent,
        DeviationFormComponent
    ],
    providers: [
        DeviationServices,
        ConfirmationService,
        DeviationEditResolver,
        DeviationListResolver,
        DeviationEditResolver,
        FollowupTopicResolver,
        FollowupCaseTypeResolver,
        AllDepartmentCachedResolver,
        ResponsibleForExecutionResolver,
        TypeCaseResolver,
        DepartmentResolver,
        CreatedByResolver,
        RespForFollowupResolver,
        UserListResolver,
        EmployeeContactUserResolver,
        FDVServices,
        DepartmentForAdminResolver,
        DeviationManualResolver,
        DeviationResponsibleAssignedUserResolver
    ]
})
export class DeviationModule { }
