import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from './../../_guards/auth.guard';
import { SupplierListResolver, ActiveSupplierResolver } from './../Suppliers/Services/supplier.resolver.service';
import {
    ReceptionControlListResolver,
    ReceptionControlGoodsTypeResolver,
    ReceptionControlEditResolver,
    GoodsTypeByBusinessResolver,
    ReceptionControlDocumentResolver
} from './services/reception-control.resolver.service';
import { ReceptionControlFormComponent } from './components/reception-control-form.component';
import { ReceptionControlListComponent } from './components/reception-control-list.component';
import { ReceptionControlComponent } from './reception-control.component';
import { EditDeviationComponent } from 'src/app/kuba/deviation/components/deviation-edit/deviation-edit.component';
import { DeviationEditResolver, DeviationResponsibleAssignedUserResolver, DepartmentForAdminResolver, DeviationManualResolver } from '../deviation/services/deviation.resolver';
import { RiskAnalysisTopicCachedResolver } from '../follow-ups/services/risk-analysis.resolver.service';
import { FollowupCaseTypeResolver, AllDepartmentCachedResolver } from '../follow-ups/services/deviation.resolver.service';

export const ReceptionControlRoutes: Route[] = [{
    path: 'reception-control',
    component: ReceptionControlComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: ReceptionControlListComponent,
            canActivate: [AuthGuard],
            resolve: {
                list: ReceptionControlListResolver,
                suppliers: ActiveSupplierResolver,
                goodsType: GoodsTypeByBusinessResolver
            }
        },
        {
            path: 'edit/:rid',
            component: ReceptionControlFormComponent,
            canActivate: [AuthGuard],
            resolve: {
                reception: ReceptionControlEditResolver,
                suppliers: ActiveSupplierResolver,
                goodsType: GoodsTypeByBusinessResolver,
                docList: ReceptionControlDocumentResolver
            }

        }
    ]

}];




