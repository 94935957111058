<div class="tab-content">
    <div class="page-title">
        <span>
            <a [routerLink]="['./../../../../../vehicle/list']" class="btn btn-icon">
                <span class="icon ic-xs icon-back"></span>
            </a>
            <span translate>VEHICLE_TRACKING</span>
        </span>
    </div>
    <form [formGroup]="trackingForm">
        <form [formGroup]="trackingForm">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="Fdate">
                            <span translate>START_DATE</span>
                        </label>
                        <div>
                            <my-date-picker name="startdate" [options]="StartDateOptions" formControlName="StartDate"
                                placeholder="{{'SELECT_START_DATE'|translate}}"
                                (dateChanged)="onStartDateChanged($event)" required></my-date-picker>
                        </div>
                        <control-messages [control]="trackingForm.controls['StartDate']"></control-messages>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="Fdate">
                            <span translate>END_DATE</span>
                        </label>
                        <div>
                            <my-date-picker name="enddate" [options]="EndDateOptions" formControlName="EndDate"
                                placeholder="{{'SELECT_END_DATE'|translate}}" (dateChanged)="onEndDateChanged($event)"
                                required></my-date-picker>
                        </div>
                        <control-messages [control]="trackingForm.controls['EndDate']"></control-messages>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group" [hidden]="isHide">
                        <label>
                            <span translate>TRIP_CATEGORY</span>
                        </label>
                        <div>
                            <p-dropdown [options]="tripCategories" #tripCategory formControlName="TripCategory"
                                [style]="{'width':'160px'}" (onChange)="onTripCategoryChanged($event,tripCategory)">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mbtm-20">
                    <button type="button" class="btn btn-primary" [disabled]="!trackingForm.valid"
                        (click)="getTripDetails()" translate>TRIP_DETAILS</button>
                </div>
            </div>
        </form>
        <div class="table-view" *ngIf="isTripDetail">
            <!-- Table starts -->
            <p-table #tripDetailsTable [value]="tripDetails" [rows]="10" [paginator]="true" [pageLinks]="3"
                [rowsPerPageOptions]="[5,10,20]" [responsive]="true"
                [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                <!-- Caption -->
                <ng-template pTemplate="caption">
                    <div class="ui-helper-clearfix">
                        <div class="table-options">
                            <div class="pull-right">
                                <kuba-export [reportData]="tripDetailsTable.filteredValue || tripDetailsTable.value"
                                    [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="id">{{'TRIP_ID' | translate}}</th>
                        <th pSortableColumn="StartDate">{{'START_DATE' | translate}}</th>
                        <th pSortableColumn="StartTime">{{'START_TIME' | translate}}</th>
                        <th pSortableColumn="EndDate">{{'END_DATE'|translate}}</th>
                        <th pSortableColumn="EndTime">{{'END_TIME'|translate}}</th>
                        <th pSortableColumn="endLocationAddress">{{'END_PLACE'|translate}}</th>
                        <th pSortableColumn="tripDistance">{{'TOTAL_KM'|translate}}</th>
                        <th>{{'TRIP_CATEGORY'|translate}}</th>
                        <th pSortableColumn="totalTollPay">{{'TOLL_COST'|translate}}</th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-vehicle>
                    <tr>
                        <td>
                            <span>{{vehicle.id}}</span>
                        </td>
                        <td>
                            <span>{{vehicle.StartDate}}</span>
                        </td>
                        <td>
                            <span>{{vehicle.StartTime}}</span>
                        </td>
                        <td>
                            <span>{{vehicle.EndDate}}</span>
                        </td>
                        <td>
                            <span>{{vehicle.EndTime}}</span>
                        </td>
                        <td>
                            <span>{{vehicle.endLocationAddress}}</span>
                        </td>
                        <td>
                            <span>{{vehicle.tripDistance}}</span>
                        </td>
                        <td>
                            <p-inputSwitch onLabel="P" offLabel="B" class="ui-inputswitch-on"
                                [(ngModel)]="vehicle.Category">
                            </p-inputSwitch>
                        </td>
                        <td>
                            <span>{{vehicle.totalTollPay}}</span>
                        </td>
                    </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="9">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </form>
</div>