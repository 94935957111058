import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleteChecklistStatus } from 'src/app/kuba/shared/models/complete-checklist';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroChecklistCompletion, ElectroCompleteChecklistEdit, ElectroCompleteChecklistItemSection } from 'src/app/kuba/electro/models/electrochecklistcompletion';
import { FeatureKey, Status } from 'src/app/_models';
import { ElectroDocumentService } from 'src/app/kuba/electro/services/electro-document.services';
import { CompleteCheckListServices } from 'src/app/kuba/shared/services/complete-checklist.service';
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import { HelperService } from 'src/app/_services/helper.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ElectroService } from 'src/app/kuba/electro/services/electro.services';
import { CustomValidator } from '../custom-validator';
import { ElectroDeviation } from 'src/app/kuba/electro/models/electrodeviation';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ElectroInstallatioForms } from 'src/app/kuba/electro/models/electrofeatures';
import { CourseOverviewComponent } from '../../installation/course-overview.component';
import { ElectroInstallationService } from 'src/app/kuba/electro/services/electro-installation.services';
import { DistributionOverviewComponent } from '../../installation/distribution-overview.component';
import { DocumentAssessmentComponent } from '../../installation/document-assessment.component';
import { EmergencyLightingComponent } from '../../installation/emergency-lighting.component';
import { FilreAlarmComponent } from '../../installation/fire-alarm-system.component';
import { RiskAssessmentComponent } from '../../installation/risk-assessment.component';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';
import { CMP, CMPInstrument } from 'src/app/kuba/measure-protocol/models/measure-protocol';
import { MeasureProtocolVisualControlComponent } from 'src/app/kuba/measure-protocol/components/MPComplete/measure-protocol-visual-control.component';
import { MeasureProtocolEarthLeakageComponent } from 'src/app/kuba/measure-protocol/components/MPComplete/measure-protocol-earth-leakage.component';
import { MeasureProtocolPowerLeakageComponent } from 'src/app/kuba/measure-protocol/components/MPComplete/measure-protocol-power-leakage.component';
import { MeasureProtocolTemperatureComponent } from 'src/app/kuba/measure-protocol/components/MPComplete/measure-protocol-temperature.component';
import { ElectroCourseOverview } from 'src/app/kuba/electro/models/electroinstallation';
import { Dropdown } from 'primeng/dropdown';
@Component({
    selector: 'electro-checklist-completion',
    templateUrl: 'electro-checklist-completion.component.html',
    styleUrls: ['electro-checklist-completion.component.css']
})

export class ElectroChecklistCompletionComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;
    @ViewChild('pd', { static: false }) predefineData: Dropdown;
    @ViewChild('course', { static: false }) course: CourseOverviewComponent;
    @ViewChild('distribution', { static: false }) distribution: DistributionOverviewComponent;
    @ViewChild('documentAssessment', { static: false }) documentAssessment: DocumentAssessmentComponent;
    @ViewChild('emergencyLighting', { static: false }) emergencyLighting: EmergencyLightingComponent;
    @ViewChild('filreAlarm', { static: false }) filreAlarm: FilreAlarmComponent;
    @ViewChild('riskAssessment', { static: false }) riskAssessment: RiskAssessmentComponent;
    @ViewChild('vsControl', { static: false }) vsControl: MeasureProtocolVisualControlComponent;
    @ViewChild('earthLeak', { static: false }) earthLeak: MeasureProtocolEarthLeakageComponent;
    @ViewChild('pwrLeak', { static: false }) pwrLeak: MeasureProtocolPowerLeakageComponent;
    @ViewChild('temperature', { static: false }) temperature: MeasureProtocolTemperatureComponent;
    checklistData: ElectroChecklistCompletion;
    CompleteChecklistEdit: ElectroCompleteChecklistEdit;
    additionalData: any;
    buildingDropdown: any;
    upConfig: UploaderConfig;
    contractId: number;
    inspectionId: number;
    enabledChecklists: any[];
    followup: any[] = [];
    execution: any[] = [];
    status: any;
    importance: any[] = [];
    deadline: any;
    addFileCheckpoint: number;
    addFileHeadpoint: number;
    isAddFileClicked: boolean;
    isAddDeviationClicked: boolean;
    isGuestHideButton = true;
    newDocumentAdded = [];
    uploadedFiles = [];
    deleteEventObserve: boolean;
    img: any;
    formData: any;
    enableForm: string;
    public dateOptions: IMyDpOptions;
    public dateOptions1: IMyDpOptions;
    yesOrNo: any[];
    taskId: number;
    predefine: number;
    deviationForm: FormGroup;
    isOffer: boolean;
    deviation = new ElectroDeviation();
    IsClear: boolean;
    firstInit = true;
    fillType: string;
    isNew: boolean;
    formVersion: number;
    previewImages: any = [];
    showLoader: boolean;
    violatedParagraphs: SelectItem[];
    type: number;
    formOrCklId: number;
    emergencyLightEquipStatus: any;
    fireAlarmEquipStatus: any;
    isAddFileEmerClicked: boolean;
    isAddFileFireAlarmClicked: boolean;
    emerEqipIndex: number;
    fireAlarmEqipIndex: number;
    singleBuilding: number;
    mpInstruments: any;
    selectedmpInstruments: any;
    completeMP: any;
    distributionId: number;
    distributions: SelectItem[];
    isMp: boolean;
    private subscriptions: Subscription[] = [];
    addDistFromTemplate = false;
    distTemplateEmptyMsg = false;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private translate: TranslateService,
        private CompleteCheckListServices: CompleteCheckListServices,
        private datePipe: DatePipe,
        private electroDocumentService: ElectroDocumentService,
        private fb: FormBuilder,
        private confirmationService: ConfirmationService,
        private electroInstallationService: ElectroInstallationService,
        private electroService: ElectroService,
        public measureprotocolservice: MeasureProtocolService) {

            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.dateOptions = {
                dateFormat: 'dd/mm/yyyy',
                editableDateField: false,
                openSelectorOnInputClick: true,
                firstDayOfWeek: 'su',
                satHighlight: true,
                height: '32px',
                selectionTxtFontSize: '14px',
                disableSince: {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    day: new Date().getDate() + 1,
                },
                todayBtnTxt: this.translate.instant('TODAY'),
                dayLabels: {
                    su: this.translate.instant('SUN'),
                    mo: this.translate.instant('MON'),
                    tu: this.translate.instant('TUE'),
                    we: this.translate.instant('WED'),
                    th: this.translate.instant('THU'),
                    fr: this.translate.instant('FRI'),
                    sa: this.translate.instant('SAT')
                },
                monthLabels: {
                    1: this.translate.instant('JANUARY'),
                    2: this.translate.instant('FEBRUARY'),
                    3: this.translate.instant('MARCH'),
                    4: this.translate.instant('APRIL'),
                    5: this.translate.instant('MAY'),
                    6: this.translate.instant('JUNE'),
                    7: this.translate.instant('JULY'),
                    8: this.translate.instant('AUGUST'),
                    9: this.translate.instant('SEPTEMBER'),
                    10: this.translate.instant('OCTOBER'),
                    11: this.translate.instant('NOVEMBER'),
                    12: this.translate.instant('DECEMBER')
                },
                inline: false
            };
            this.dateOptions1 = {
                dateFormat: 'dd/mm/yyyy',
                editableDateField: false,
                openSelectorOnInputClick: true,
                firstDayOfWeek: 'su',
                satHighlight: true,
                height: '32px',
                selectionTxtFontSize: '14px',
                disableUntil: {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    day: new Date().getDate() - 1,
                },
                width: '380px',
                inline: false,
                todayBtnTxt: this.translate.instant('TODAY'),
                dayLabels: {
                    su: this.translate.instant('SUN'),
                    mo: this.translate.instant('MON'),
                    tu: this.translate.instant('TUE'),
                    we: this.translate.instant('WED'),
                    th: this.translate.instant('THU'),
                    fr: this.translate.instant('FRI'),
                    sa: this.translate.instant('SAT')
                },
                monthLabels: {
                    1: this.translate.instant('JANUARY'),
                    2: this.translate.instant('FEBRUARY'),
                    3: this.translate.instant('MARCH'),
                    4: this.translate.instant('APRIL'),
                    5: this.translate.instant('MAY'),
                    6: this.translate.instant('JUNE'),
                    7: this.translate.instant('JULY'),
                    8: this.translate.instant('AUGUST'),
                    9: this.translate.instant('SEPTEMBER'),
                    10: this.translate.instant('OCTOBER'),
                    11: this.translate.instant('NOVEMBER'),
                    12: this.translate.instant('DECEMBER')
                }
            };
        }));
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        this.type = +this.route.snapshot.params['typ'];
        this.formOrCklId = +this.route.snapshot.params['cClId'];
        this.fillType = 'CHECKLIST';
        let enabledForms = this.route.snapshot.data['forms'];
        if (this.type && this.formOrCklId) {
            switch (this.type) {
                case ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION:
                    this.enableForm = 'document-assessment';
                    this.subscriptions.push(this.electroInstallationService.getDocumentAssessmsentById(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.initForm();
                            this.documentAssessment.initForm(this.formData);
                            this.documentAssessment.editMode = true;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION:
                    this.enableForm = 'risk-assessment';
                    this.subscriptions.push(this.electroInstallationService.getRiskAssessmentById(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.initForm();
                            this.riskAssessment.initForm(this.formData);
                            this.riskAssessment.editMode = true;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW:
                    this.enableForm = 'distribution-overview';
                    this.subscriptions.push(this.electroInstallationService.getDistributionsById(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.initForm();
                            this.distribution.initForm(this.formData);
                            this.distribution.editMode = true;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW:
                    this.enableForm = 'course-overview';
                    this.subscriptions.push(this.electroInstallationService.getCourseById(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.formData = res;
                            this.initForm();
                            this.course.initForm(this.formData);
                            this.course.editMode = true;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.FIRE_ALARM_SYSTEM:
                    this.enableForm = 'fire-alarm';
                    this.subscriptions.push(this.electroInstallationService.getFirealarmSystemById(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.fireAlarmEquipStatus = res.OtherEquipmentStatus ? JSON.parse(res.OtherEquipmentStatus) : null;
                            this.formData = res;
                            this.initForm();
                            this.filreAlarm.initForm(this.formData);
                            this.filreAlarm.editMode = true;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM:
                    this.enableForm = 'emergency-lighting';
                    this.subscriptions.push(this.electroInstallationService.getEmergencyLightingById(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.emergencyLightEquipStatus = res.OtherEquipmentStatus ? JSON.parse(res.OtherEquipmentStatus) : null;
                            this.formData = res;
                            this.initForm();
                            this.emergencyLighting.initForm(this.formData);
                            this.emergencyLighting.editMode = true;
                        }
                    }));
                    break;
                case ElectroInstallatioForms.MEASURE_PROTOCOL:
                    this.enableForm = 'measure';
                    this.isMp = true;
                    this.onReportFormChange('FORM')
                    this.subscriptions.push(this.measureprotocolservice.getElectroCompleteMP(this.formOrCklId).subscribe(res => {
                        if (res) {
                            this.initMeasureProtocol(res);
                        }
                    }))
            }
        }
    }

    ngOnInit() {
        this.checklistData = this.route.snapshot.data['checklistData'];
        this.contractId = this.route.snapshot.params['cid'];
        this.inspectionId = this.route.snapshot.params['Iid'];
        this.taskId = +this.route.snapshot.params['tId'];
        this.isNew = this.router.url.indexOf('new-complete-cklist') > -1
        this.predefine = 0;

        if (this.checklistData && this.type == 0) {
            this.CompleteChecklistEdit = JSON.parse(this.checklistData.CheckListData);
            if (this.checklistData.Deadline) {
                this.deadline = HelperService.formatInputDate(this.checklistData.Deadline);
                this.predefine = 1;
            }
            else {
                this.predefine = 0;
            }
            if (this.checklistData.Status == Status.Done) {
                if (this.staticTabs != undefined) {
                this.staticTabs.tabs[2].disabled = false;
                this.staticTabs.tabs[1].active = true;
                }
            } else {
                if (this.staticTabs != undefined) {
                this.staticTabs.tabs[2].disabled = true;
                this.checklistData.Id ? this.staticTabs.tabs[1].active = true : this.staticTabs.tabs[1].disabled = true;
                }
            }
        }
        else {
            if (this.staticTabs != undefined) {
                this.staticTabs.tabs[0].active = true;
                this.staticTabs.tabs[1].disabled = true;
                this.staticTabs.tabs[2].disabled = true;
                if (this.staticTabs.tabs[3]) {
                    this.staticTabs.tabs[3].disabled = true;
                }
            }
            this.checklistData = new ElectroChecklistCompletion();
            if (this.isNew) {
                this.checklistData.BuildingId = this.singleBuilding;
            }
            this.checklistData.Status = Status.Open;
            this.CompleteChecklistEdit = new ElectroCompleteChecklistEdit();
        }


        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            ModuleName: 'completechecklist'
        };
        this.upConfig = {
            title: this.translate.instant('DOCUMENTS'),
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/file/upload/business/completechecklist',
            addtionalData: this.additionalData,
            showDeleteButton: true
        };
        this.bindDropdowns();
        if (!this.type) {
            this.onReportFormChange('CHECKLIST');
        } else {
            this.onReportFormChange('FORM');
        }
    }

    initForm() {
        let enabledForms = this.route.snapshot.data['forms'];
        this.subscriptions.push(this.translate.stream('SELECT_INSTALLATION_FORM').subscribe(val => {
            this.enabledChecklists = [];
            this.enabledChecklists.push(
                { label: val.SELECT, value: null });
            if (enabledForms) {
                enabledForms.forEach(f => {
                    switch (f.TranslationKey) {
                        case "ASSESSMENT_OF_AVAILABLE_DOCUMENTATION":
                            this.enabledChecklists.push({ label: val.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION, value: f.Id, type: 'form' });
                            break;
                        case "RISK_ASSESSMENT_OF_THE_INSTALLATION":
                            this.enabledChecklists.push({ label: val.RISK_ASSESSMENT_OF_THE_INSTALLATION, value: f.Id, type: 'form' });
                            break;
                        case "ELECTRICAL_DISTRIBUTION_OVERVIEW":
                            this.enabledChecklists.push({ label: val.ELECTRICAL_DISTRIBUTION_OVERVIEW, value: f.Id, type: 'form' });
                            break;
                        case "ELECTRICAL_COURSE_OVERVIEW":
                            this.enabledChecklists.push({ label: val.ELECTRICAL_COURSE_OVERVIEW, value: f.Id, type: 'form' });
                            break;
                        case "FIRE_ALARM_SYSTEM":
                            this.enabledChecklists.push({ label: val.FIRE_ALARM_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "EMERGENCY_LIGHTING_SYSTEM":
                            this.enabledChecklists.push({ label: val.EMERGENCY_LIGHTING_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "FIRE_SPRINKLER_SYSTEM":
                            this.enabledChecklists.push({ label: val.FIRE_SPRINKLER_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "MEASURE_PROTOCOL":
                            this.enabledChecklists.push({ label: val.MEASURE_PROTOCOL, value: f.Id, type: 'MP' });
                            break;
                    }
                });
            }
        }));
        this.fillType = 'FORM';
        this.checklistData.ChecklistId = this.type;
        this.staticTabs.tabs[1].disabled = false;
        if (this.enableForm == 'emergency-lighting' || this.enableForm == 'fire-alarm') {
            if (this.formData.Status == Status.Done) {
                this.staticTabs.tabs[2].disabled = false;
                this.staticTabs.tabs[3].disabled = false;
                this.staticTabs.tabs[2].active = true;
            }
            else if (this.formData.OtherEquipmentStatus && this.formData.OtherEquipment) {
                if (JSON.parse(this.formData.OtherEquipment).length ? (this.enableForm == 'emergency-lighting' ?
                    JSON.parse(this.formData.OtherEquipment)[0].no : JSON.parse(this.formData.OtherEquipment)[0].amount) : false) {
                    this.staticTabs.tabs[2].disabled = false;
                    this.staticTabs.tabs[3].disabled = true;
                    this.staticTabs.tabs[2].active = true;
                }
            }
            else {
                this.staticTabs.tabs[2].disabled = true;
                this.staticTabs.tabs[3].disabled = true;
                this.staticTabs.tabs[1].active = true;
            }
        } else {
            if (this.formData.Status == Status.Done) {
                this.staticTabs.tabs[2].disabled = false;
                this.staticTabs.tabs[1].active = true;
            } else {
                this.staticTabs.tabs[1].active = true;
                this.staticTabs.tabs[2].disabled = true;
            }
        }
        this.checklistData = new ElectroChecklistCompletion();
        this.checklistData.BuildingId = this.singleBuilding;
        this.checklistData.ChecklistId = this.type;
        this.checklistData.BuildingId = this.formData.BuildingId;
        this.checklistData.Title = this.formData.CompletionTitle ? this.formData.CompletionTitle :
            this.enabledChecklists.find(x => x.value == this.type).label;
        this.formVersion = this.formData.Version;
        this.checklistData.SignatureImageData = this.formData.SignatureImageData;
        this.checklistData.Status = this.formData.Status ? this.formData.Status : Status.Open;
    }

    initMeasureProtocol(CMP) {
        this.completeMP = CMP;
        this.checklistData = new ElectroChecklistCompletion();
        this.checklistData.ChecklistId = ElectroInstallatioForms.MEASURE_PROTOCOL;
        this.checklistData.BuildingId = CMP.BuildingId;
        this.checklistData.SignatureImageData = CMP.Path;
        this.checklistData.Title = CMP.Name;
        this.bindDistribution(CMP.DistributionId);
        if (CMP.DistributionId && this.earthLeak) {
            this.distributionId = CMP.DistributionId;
            this.subscriptions.push(this.electroInstallationService.getCoursesbyInspectionDistribution(this.checklistData.BuildingId,
                this.inspectionId, CMP.DistributionId).subscribe(course => {
                    if (course) {
                        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                            this.earthLeak.courses = []
                            this.earthLeak.courses.push({ label: val.SELECT, value: null });
                            course.forEach(c => {
                                this.earthLeak.courses.push({ label: c.course, value: c.id });
                            });
                        }));
                    }
                }));
        }
        if (CMP.Instruments) {
            this.subscriptions.push(this.measureprotocolservice.getElectroBusinessInstruments().subscribe((res:any) => {
                if (res) {
                    this.mpInstruments = res;
                    let selectedmpInstruments = [];
                    res.forEach(ins => {
                        let sIns = CMP.Instruments.find(x => x.InstrumentId == ins.Id);
                        if (sIns) {
                            selectedmpInstruments.push(ins);
                        }
                    });
                    this.selectedmpInstruments = selectedmpInstruments;
                }
            }))
        }
        this.staticTabs.tabs[1].disabled = false;
        this.staticTabs.tabs[1].active = true;
        this.staticTabs.tabs[2].disabled = false;
        this.staticTabs.tabs[3].disabled = false;
        this.staticTabs.tabs[4].disabled = false;
        this.staticTabs.tabs[5].disabled = false;
        this.staticTabs.tabs[6].disabled = false;
    }
    uploaderEvents(event: any, CompleteChecklistItemSection: ElectroCompleteChecklistItemSection) {
        switch (event.eventName) {
            case 'DeleteDefaultFile':
                CompleteChecklistItemSection.Attachments = CompleteChecklistItemSection.Attachments.filter(
                    x => x.path !== event.item.data.path
                );
                break;
            default:
                break;
        }
    }

    bindDropdowns() {
        let buildings = this.route.snapshot.data['buildings'];
        //Add buildings registered in contract to buildingDropdown.
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.buildingDropdown = [];
            this.buildingDropdown.push({ label: val.SELECT, value: null });
            if (buildings) {
                if (this.isNew) {
                    this.singleBuilding = (buildings.length == 1) ? buildings[0].Id : null;
                    this.checklistData.BuildingId = this.singleBuilding;
                }
                buildings.forEach((building: any) => {
                    this.buildingDropdown.push({ label: building.BuildingNumber, value: building.Id });
                });
            }
        }));
        this.subscriptions.push(this.translate.stream('SELECT_YES_NO').subscribe(val => {
            this.yesOrNo = [];
            this.yesOrNo.push(
                { label: val.NO, value: 0 },
                { label: val.YES, value: 1 });
        }));

        let followup = this.route.snapshot.data['responsiblepersons'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.followup = [];
            this.execution = [];
            this.followup.push(
                { label: val.SELECT, value: null });
            this.execution.push(
                { label: val.SELECT, value: null });
            if (followup) {
                followup.forEach(person => {
                    this.followup.push({ label: person.Name, value: person.Id });
                });
                followup.forEach(person => {
                    this.execution.push({ label: person.Name, value: person.Id });
                });
            }
        }));
        this.subscriptions.push(this.translate.stream('RISKFIGURE_DROPDOWN').subscribe(val => {
            this.importance = [];
            this.importance.push(
                { label: val.SELECT, value: '' },
                { label: val.LOW, value: 1 },
                { label: val.MEDIUM, value: 2 },
                { label: val.HIGH, value: 3 },
            );
        }));

        this.subscriptions.push(this.translate.stream('SELECT_FOR_STATUS').subscribe(val => {
            this.status = [];
            this.status.push(
                { label: val.SELECT, value: null },
                { label: val.OPEN, value: 8 },
                { label: val.CLOSED, value: 9 },
            );
        }));

        this.violatedParagraphs = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.violatedParagraphs = [];
            this.violatedParagraphs.push(
                { label: val.SELECT, value: null }
            );
            this.subscriptions.push(this.electroService.GetViolatedParaReference()
                .subscribe(result => {
                    result.forEach((checklist: any) => {
                        this.violatedParagraphs.push({ label: checklist.Value, value: checklist.Id });
                    });
                }));
        }));
    }

    SaveFirstStepCompleteCheckList() {
        if (this.fillType == 'CHECKLIST') {
            this.enableForm = '';
            let selectedChecklist = this.enabledChecklists.find(x => x.value == this.checklistData.ChecklistId);
            if (!this.checklistData.Id) {
                this.checklistData.Title = selectedChecklist ? selectedChecklist.label : "";
            }
            this.checklistData.ApplicationId = BaseServices.ApplicationId;
            this.checklistData.Deadline = this.deadline ? HelperService.formatDateFilter(this.deadline.formatted) : '';
            this.checklistData.BusinessId = BaseServices.BusinessId;
            this.checklistData.FeatureKey = this.contractId;
            this.checklistData.FeatureId = FeatureKey.ELECTRO;
            this.checklistData.InspectionId = this.inspectionId;
            if (this.taskId) {
                this.checklistData.TaskId = this.taskId;
            }
            this.checklistData.CheckListData = JSON.stringify(this.CompleteChecklistEdit);
            this.checklistData.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroDocumentService.saveCompletionChecklistData(this.checklistData).subscribe(result => {
                if (result) {
                    this.isNew = false;
                    this.toasterComponent.callToast();
                    this.staticTabs.tabs[1].disabled = false;
                    this.staticTabs.tabs[1].active = true;
                    this.checklistData = result;
                    this.CompleteChecklistEdit = JSON.parse(result.CheckListData);
                }
            }));
        }
        else if (this.fillType == 'FORM' && !this.isMp) {
            //routing to corresponding form based on current component route location.
            this.checklistData.Title = this.checklistData.Title ? this.checklistData.Title :
                this.enabledChecklists.find(x => x.value == this.checklistData.ChecklistId).label;
            let formData = { BuildingId: this.checklistData.BuildingId, Version: this.formVersion };
            sessionStorage.setItem('formData', JSON.stringify(formData));
            switch (this.checklistData.ChecklistId) {
                case ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION:
                    this.enableForm = 'document-assessment';
                    break;
                case ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION:
                    this.enableForm = 'risk-assessment';
                    break;
                case ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW:
                    this.enableForm = 'distribution-overview';
                    break;
                case ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW:
                    this.enableForm = 'course-overview';
                    break;
                case ElectroInstallatioForms.FIRE_ALARM_SYSTEM:
                    this.enableForm = 'fire-alarm';
                    break;
                case ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM:
                    this.enableForm = 'emergency-lighting';
                    break;
            }
            this.staticTabs.tabs[1].disabled = false;
            this.staticTabs.tabs[1].active = true;
            setTimeout(() => {
                this.ngAfterViewInit();
                this.ngAfterViewChecked();
                switch (this.enableForm) {
                    case 'document-assessment':
                        this.documentAssessment.editMode = true;
                        if (this.taskId) {
                            this.documentAssessment.DocumentAssessment.SubTaskId = this.taskId;
                        }
                        this.documentAssessment.DocumentAssessment.ElectroContractId = this.contractId;
                        this.documentAssessment.DocumentAssessment.BuildingId = this.checklistData.BuildingId;
                        this.documentAssessment.DocumentAssessment.Version = this.formVersion;
                        this.documentAssessment.DocumentAssessment.CompletionTitle = this.checklistData.Title;
                        this.documentAssessment.DocumentAssessment.Status = this.checklistData.Status;
                        this.documentAssessment.DocumentAssessment.SignatureImageData = this.canvas.nativeElement.toDataURL();
                        if (this.isNew) {
                            this.subscriptions.push(this.electroInstallationService.getContractFormTemplate(
                                ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION, this.contractId).subscribe(res => {
                                    if (res ? res.FormTemplate : false) {
                                        this.documentAssessment.DocumentReview = JSON.parse(res.FormTemplate);
                                    }
                                }));
                        }
                        break;
                    case 'risk-assessment':
                        this.riskAssessment.editMode = true;
                        if (this.taskId) {
                            this.riskAssessment.riskAssessment.SubTaskId = this.taskId;
                        }
                        if (this.isNew) {
                            this.subscriptions.push(this.electroInstallationService.getContractFormTemplate(
                                ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION, this.contractId).subscribe(res => {
                                    if (res ? res.FormTemplate : false) {
                                        this.riskAssessment.riskAssessment = JSON.parse(res.FormTemplate);
                                        this.riskAssessment.elementsConsidered = JSON.parse(this.riskAssessment.riskAssessment.OtherMatters);
                                    }
                                }));
                        }
                        this.riskAssessment.riskAssessment.ElectroContractId = this.contractId;
                        this.riskAssessment.riskAssessment.BuildingId = this.checklistData.BuildingId;
                        this.riskAssessment.riskAssessment.Version = this.formVersion;
                        this.riskAssessment.riskAssessment.CompletionTitle = this.checklistData.Title;
                        this.riskAssessment.riskAssessment.Status = this.checklistData.Status;
                        this.riskAssessment.riskAssessment.SignatureImageData = this.canvas.nativeElement.toDataURL();
                        break;
                    case 'distribution-overview':
                        this.distribution.editMode = true;
                        if (this.taskId) {
                            this.distribution.distributionOverview.SubTaskId = this.taskId;
                        }
                        this.distribution.distributionOverview.ElectroContractId = this.contractId;
                        this.distribution.distributionOverview.BuildingId = this.checklistData.BuildingId;
                        this.distribution.distributionOverview.Version = this.formVersion;
                        this.distribution.distributionOverview.CompletionTitle = this.checklistData.Title;
                        this.distribution.distributionOverview.Status = this.checklistData.Status;
                        this.distribution.distributionOverview.SignatureImageData = this.canvas.nativeElement.toDataURL();
                        if (this.isNew) {
                            this.subscriptions.push(this.electroInstallationService.getDistributionOverviewByBuilding(this.checklistData.BuildingId,
                                this.inspectionId).subscribe(res => {
                                    if (res) {
                                        this.type = ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW;
                                        this.formData = res;
                                        this.initForm();
                                        this.distribution.initForm(this.formData);
                                        this.distribution.editMode = true;
                                    } else {
                                        this.subscriptions.push(this.electroInstallationService.getContractFormTemplate(
                                            ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW, this.contractId).subscribe(res => {
                                                if (res ? res.FormTemplate : false) {
                                                    this.distribution.distributionItems = JSON.parse(res.FormTemplate);
                                                }
                                            }));
                                    }
                                }))
                        }
                        break;
                    case 'course-overview':
                        this.course.editMode = true;
                        if (this.taskId) {
                            this.course.courseOverview.SubTaskId = this.taskId;
                        }
                        this.course.courseOverview.ElectroContractId = this.contractId;
                        this.course.courseOverview.InspectionId = this.inspectionId;
                        this.course.courseOverview.BuildingId = this.checklistData.BuildingId;
                        this.course.courseOverview.Version = this.formVersion;
                        this.course.courseOverview.CompletionTitle = this.checklistData.Title;
                        this.course.courseOverview.Status = this.checklistData.Status;
                        this.course.courseOverview.SignatureImageData = this.canvas.nativeElement.toDataURL();
                        this.course.initForm(this.course.courseOverview);
                        break;
                    case 'fire-alarm':
                        this.filreAlarm.editMode = true;
                        if (this.taskId) {
                            this.filreAlarm.firealarmSystem.SubTaskId = this.taskId;
                        }
                        this.staticTabs.tabs[3].disabled = this.formData ? this.formData.Status != Status.Done : true;
                        if (this.isNew) {
                            this.subscriptions.push(this.electroInstallationService.getContractFormTemplate(
                                ElectroInstallatioForms.FIRE_ALARM_SYSTEM, this.contractId).subscribe(res => {
                                    if (res ? res.FormTemplate : false) {
                                        this.filreAlarm.firealarmSystem = JSON.parse(res.FormTemplate);
                                        this.filreAlarm.systemContent = JSON.parse(this.filreAlarm.firealarmSystem.SystemContent);
                                        this.filreAlarm.otherEquipment = JSON.parse(this.filreAlarm.firealarmSystem.OtherEquipment);
                                    }
                                }));
                        }
                        this.filreAlarm.firealarmSystem.ElectroContractId = this.contractId;
                        this.filreAlarm.firealarmSystem.BuildingId = this.checklistData.BuildingId;
                        this.filreAlarm.firealarmSystem.Version = this.formVersion;
                        this.filreAlarm.firealarmSystem.CompletionTitle = this.checklistData.Title;
                        this.filreAlarm.firealarmSystem.Status = this.checklistData.Status;
                        this.filreAlarm.firealarmSystem.SignatureImageData = this.canvas.nativeElement.toDataURL();
                        break;
                    case 'emergency-lighting':
                        this.emergencyLighting.editMode = true;
                        if (this.taskId) {
                            this.emergencyLighting.lighting.SubTaskId = this.taskId;
                        }
                        this.staticTabs.tabs[3].disabled = this.formData ? this.formData.Status != Status.Done : true;
                        if (this.isNew) {
                            this.subscriptions.push(this.electroInstallationService.getContractFormTemplate(
                                ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM, this.contractId).subscribe(res => {
                                    if (res ? res.FormTemplate : false) {
                                        this.emergencyLighting.lighting = JSON.parse(res.FormTemplate);
                                        this.emergencyLighting.otherEquipments = JSON.parse(this.emergencyLighting.lighting.OtherEquipment);
                                    }
                                }));
                        }
                        this.emergencyLighting.lighting.ElectroContractId = this.contractId;
                        this.emergencyLighting.lighting.BuildingId = this.checklistData.BuildingId;
                        this.emergencyLighting.lighting.Version = this.formVersion;
                        this.emergencyLighting.lighting.CompletionTitle = this.checklistData.Title;
                        this.emergencyLighting.lighting.Status = this.checklistData.Status;
                        this.emergencyLighting.lighting.SignatureImageData = this.canvas.nativeElement.toDataURL();
                        break;
                }
            }, 500);
        } else if (this.isMp) {
            setTimeout(() => {
                this.ngAfterViewInit();
                this.ngAfterViewChecked();
            }, 500);
            this.enableForm = 'measure';
            if (this.completeMP ? this.completeMP.Id : false) {
                this.staticTabs.tabs[1].disabled = false;
                this.staticTabs.tabs[1].active = true;
                this.saveMpCompleteStep2('next2');
                if (this.distributionId && this.earthLeak) {
                    this.subscriptions.push(this.electroInstallationService.getCoursesbyInspectionDistribution(this.checklistData.BuildingId,
                        this.inspectionId, this.distributionId).subscribe(course => {
                            if (course) {
                                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                                    this.earthLeak.courses = []
                                    this.earthLeak.courses.push({ label: val.SELECT, value: null });
                                    course.forEach(c => {
                                        this.earthLeak.courses.push({ label: c.course, value: c.id });
                                    });
                                }));
                            }
                        }));
                }
            }
            else {
                this.measureprotocolservice.getElectroBusinessInstruments().subscribe(res => {
                    if (res) {
                        this.mpInstruments = res;
                        this.staticTabs.tabs[2].disabled = true;
                        this.staticTabs.tabs[3].disabled = true;
                        this.staticTabs.tabs[4].disabled = true;
                        this.staticTabs.tabs[5].disabled = true;
                        this.staticTabs.tabs[6].disabled = true;
                        this.staticTabs.tabs[1].disabled = false;
                        this.staticTabs.tabs[1].active = true;
                        if (this.distributionId && this.earthLeak) {
                            this.subscriptions.push(this.electroInstallationService.getCoursesbyInspectionDistribution(this.checklistData.BuildingId,
                                this.inspectionId, this.distributionId).subscribe(course => {
                                    if (course) {
                                        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                                            this.earthLeak.courses = []
                                            this.earthLeak.courses.push({ label: val.SELECT, value: null });
                                            course.forEach(c => {
                                                this.earthLeak.courses.push({ label: c.course, value: c.id });
                                            });
                                        }));
                                    }
                                }));
                        }
                    }
                });
            }
        }
    }

    setStatus(event: CompleteChecklistStatus, status: CompleteChecklistStatus[],
        CompleteChecklistItemSection: ElectroCompleteChecklistItemSection) {
        let emerStatus = ["BatteryChangeOk", "BatteryChangeDef", "BatteryTestOk", "BatteryTestDef", "ConditionOk"]
        for (let statusitem of status) {
            if (statusitem.Label === event.Label) {
                statusitem.value = !statusitem.value;
            } else if (emerStatus.indexOf(event.Label) > -1) {
                if ((event.Label === "BatteryChangeOk" && statusitem.Label === "BatteryChangeDef")
                    || (event.Label === "BatteryChangeDef" && statusitem.Label === "BatteryChangeOk")) {
                    statusitem.value = false;
                }
                else if ((event.Label === "BatteryTestOk" && statusitem.Label === "BatteryTestDef")
                    || (event.Label === "BatteryTestDef" && statusitem.Label === "BatteryTestOk")) {
                    statusitem.value = false;
                }
            } else {
                statusitem.value = false;
            }
        }

        this.subscriptions.push(this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
            if (result) {
                CompleteChecklistItemSection.Signature =
                    this.translate.instant('SIGNED') + " : " +
                    BaseServices.Name +
                    ' ' +
                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
            }
        }));
    }

    EnableFirstTab() {
        this.staticTabs.tabs[0].active = true;
    }
    EnableSecondTab() {
        if (this.staticTabs.tabs[2].active) {
            this.staticTabs.tabs[1].active = true;
        } else {
            this.staticTabs.tabs[2].active = true;
        }
    }
    Saveforlater() {
        if (this.fillType == 'CHECKLIST') {
            this.checklistData.CheckListData = JSON.stringify(this.CompleteChecklistEdit);
            this.subscriptions.push(this.electroDocumentService.updateCompletionChecklistData(this.checklistData).subscribe(result => {
                if (result) {
                    this.location.back();
                    this.toasterComponent.callToast();
                }
            }));
        }
        else if (this.fillType == 'FORM') {
            if (this.isMp) {
                this.saveMpCompleteStep2('back');
            } else {
                this.saveForm('back');
            }
        }
    }

    SaveSecondStepCheckList() {
        if (this.fillType == 'CHECKLIST') {
            this.checklistData.CheckListData = JSON.stringify(this.CompleteChecklistEdit);
            this.subscriptions.push(this.electroDocumentService.updateCompletionChecklistData(this.checklistData).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.staticTabs.tabs[2].disabled = false;
                    this.staticTabs.tabs[2].active = true;
                }
            }));
        }
        else if (this.fillType == 'FORM' && this.enableForm != 'measure') {
            this.saveForm('next');
        }
        else {
            this.saveMpCompleteStep2('next3');
        }

    }
    CompleteCheckList() {
        this.checklistData.Status = Status.Done;
        if (this.fillType == 'CHECKLIST') {
            this.checklistData.CheckListData = JSON.stringify(this.CompleteChecklistEdit);
            this.checklistData.SignatureImageData = this.canvas.nativeElement.toDataURL();

            this.subscriptions.push(this.electroDocumentService.updateCompletionChecklistData(this.checklistData).subscribe(result => {
                if (result) {
                    this.location.back();
                    this.toasterComponent.callToast();
                }
            }));
        }
        else if (this.fillType == 'FORM' && this.enableForm != 'measure') {
            this.saveForm('back');
        }

    }
    checkValid() {
        let val = false;
        if (this.CompleteChecklistEdit.Section) {
            let sections = this.CompleteChecklistEdit.Section;
            for (let section of sections) {
                let items = section.Items;
                for (let item of items) {
                    let status = item.Status;
                    if (item.DeviationList ? item.DeviationList.length > 0 : false) {
                        for (let statusitem of item.Status) {
                            statusitem.value = false;
                        }
                    }
                    else if (
                        status[0].value === false &&
                            status[1].value === false &&
                            item.DeviationList ? item.DeviationList.length === 0 : false
                    ) {
                        return val = true;
                    }
                }
            }
        }
        let formValid = true;
        if (this.enableForm == 'course-overview') {
            formValid = this.course ? this.course.courseOverview.DistributionId ? !this.addDistFromTemplate : false : false;
        }
        else if (this.enableForm == 'measure') {
            formValid = this.distributionId ? !this.addDistFromTemplate : false;
        }
        return (val || !formValid);
    }
    /**
     * predefine dropdown change event.
     * @param event 
     * @param predefenition 
     */
    onPredefineChange(event, predefenition) {
        predefenition
    }

    /**
     * add file explorer trigger event.
     * @param headPoint 
     */
    addFile(headPoint, subSection) {
        if (this.enableForm == 'emergency-lighting') {
            this.isAddFileEmerClicked = true;
            this.emerEqipIndex = subSection;
        } else if (this.enableForm == 'fire-alarm') {
            this.isAddFileFireAlarmClicked = true;
            this.fireAlarmEqipIndex = subSection;
        } else {
            this.isAddFileClicked = true;
            this.addFileHeadpoint = headPoint;
            this.addFileCheckpoint = subSection;
            this.CompleteChecklistEdit.Section[headPoint].Items[subSection];
        }
        this.upConfig.uploaderUri = environment.BASE_URL + '/file/upload/business/completechecklist';
    }

    //#region  Deviation
    initDeviationForm() {
        let inspectionNumber = this.inspectionId < 10 ? 'INS-00' + this.inspectionId :
            this.inspectionId < 100 ? 'INS-0' + this.inspectionId : 'INS-' + this.inspectionId;

        let title = '';
        let building = this.checklistData.BuildingId ? this.checklistData.BuildingId : 0;
        let location = '';
        let description = '';
        let status = Status.Open;
        let importance = 1;
        let deadline = this.deadline ? HelperService.formatDateFilter(this.deadline.formatted) : '';
        let eventDate = '';
        let eventTime = '';
        let followupUserId = this.checklistData.FollowupUserId ? this.checklistData.FollowupUserId : '';
        let executionUserId = this.checklistData.ExecutionUserId ? this.checklistData.ExecutionUserId : '';
        let notifyBeforeDeadLine = 0;
        let notifyBeforeFollowup = 0;
        let correctiveAction = '';
        let offerInclude = '';
        let offerPrice = '';
        let paraViolated = [];
        this.isOffer = false;
        if (this.deviation.Id) {
            title = this.deviation.Title;
            building = this.deviation.Building;
            location = this.deviation.Location;
            description = this.deviation.Description;
            status = this.deviation.Status;
            importance = this.deviation.Importance;
            deadline = this.deviation.Deadline ? this.deviation.Deadline : '';
            eventDate = this.deviation.EventDate;
            eventTime = this.deviation.EventTime;
            followupUserId = this.deviation.FollowupUserId ? this.deviation.FollowupUserId : '';
            executionUserId = this.deviation.ExecutionUserId ? this.deviation.ExecutionUserId : '';
            notifyBeforeDeadLine = this.deviation.NotifyBeforeDeadLine;
            notifyBeforeFollowup = this.deviation.NotifyBeforeFollowup;
            correctiveAction = this.deviation.CorrectiveAction;
            this.isOffer = (this.deviation.OfferInclude || this.deviation.OfferInclude) ? true : false;
            offerInclude = this.deviation.OfferInclude;
            offerPrice = this.deviation.OfferPrice;
            if (this.deviation.ParaViolated ? JSON.parse(this.deviation.ParaViolated) : false) {
                JSON.parse(this.deviation.ParaViolated).forEach(p => {
                    paraViolated.push(p);
                });
            }
        }
        this.deviationForm = this.fb.group({
            'InspectionNumber': [inspectionNumber],
            'Title': [title],
            'Building': [building],
            'Location': [location, [Validators.required, CustomValidator.noWhitespaceValidator]],
            'Description': [description],
            'Status': [status, Validators.required],
            'Importance': [importance, Validators.required],
            'Deadline': [deadline ? HelperService.formatInputDate(deadline) : '', Validators.required],
            'EventDate': [eventDate ? HelperService.formatInputDate(eventDate) : '', Validators.required],
            'EventTime': [eventTime ? HelperService.formatTime(eventTime) : '', Validators.required],
            'FollowupUserId': [followupUserId, Validators.required],
            'ExecutionUserId': [executionUserId, Validators.required],
            'NotifyBeforeDeadline': [notifyBeforeDeadLine],
            'NotifyBeforeFollowup': [notifyBeforeFollowup],
            'CorrectiveAction': [correctiveAction],
            'ParaViolated': [paraViolated],
            'OfferInclude': [offerInclude],
            'OfferPrice': [offerPrice],
        });
        if (+this.inspectionId) {
            this.subscriptions.push(this.electroService.getInspectionNumberById(this.inspectionId).subscribe(res => {
                if (res) {
                    if (this.deviation.Id) {
                        this.deviationForm.patchValue({ 'InspectionNumber': res + '-' + this.deviation.Id });
                    } else {
                        this.subscriptions.push(this.electroService.GetHighestDeviationNumber().subscribe(devN => {
                            if (devN) {
                                this.deviationForm.patchValue({ 'InspectionNumber': res + '-' + devN });
                            }
                        }))
                    }
                }
            }))
        }
        if (!this.deviation.Id) {
            this.bindEventDate();
        }
        this.upConfig.uploaderUri = environment.BASE_URL + '/electro/file/upload/deviation';
        this.uploadedFiles = [];
        if (this.deviation.ElectroDeviationAttachment) {
            this.deviation.ElectroDeviationAttachment.forEach(element => {
                if (element) {
                    this.uploadedFiles.push(
                        HelperService.switchKeysCase(element, null, 'L')
                    );
                }
            });
        }
        this.isAddDeviationClicked = true;
    }

    addEditDeviation(devId, headPoint, subSection) {
        this.deviation = new ElectroDeviation();
        this.addFileHeadpoint = headPoint;
        this.addFileCheckpoint = subSection;
        if (devId) {
            this.subscriptions.push(this.electroService.getDeviationById(devId).subscribe(result => {
                if (result) {
                    this.deviation = result;
                    this.initDeviationForm();
                }
            }));
        }
        else {
            this.initDeviationForm();
        }

    }
    /**
         * enable clear button
         */
    enableClear() {

        this.IsClear = true;
    }
    addOffer() {
        this.isOffer = !this.isOffer;
    }

    /**
     * bind evnet date and time
     */
    bindEventDate() {
        let dateFormat = new Date();
        this.deviationForm.controls['EventDate'].setValue(HelperService.formatInputDate(new Date()));
        this.deviationForm.controls['EventTime'].setValue(this.datePipe.transform(dateFormat, 'HH:mm'));
        this.IsClear = true;
    }

    /**
     * clear evnet date and time
     */
    clearEventDate() {
        this.deviationForm.controls['EventDate'].setValue(null);
        this.deviationForm.controls['EventTime'].setValue(null);
        this.IsClear = false;
    }

    /**
    * save uploaded file
    */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            x => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            x => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.electroService
                            .deleteDeviationAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        x => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    /**
     * save deviation
     */
    saveDeviation(isback?: boolean) {
        this.showLoader = true;
        let CultureInfo = sessionStorage.getItem('languageMode');
        let deviation = new ElectroDeviation();
        deviation.Building = this.deviationForm.value.Building;
        deviation.Location = this.deviationForm.value.Location;
        deviation.Description = this.deviationForm.value.Description;
        deviation.Status = this.deviationForm.value.Status;
        deviation.Importance = this.deviationForm.value.Importance;
        deviation.Deadline = HelperService.formatDateFilter(this.deviationForm.value.Deadline.formatted);
        deviation.Title = this.deviationForm.value.Title;
        deviation.EventDate = HelperService.formatDateFilter(this.deviationForm.value.EventDate.formatted);
        deviation.EventTime = HelperService.formatTime(this.deviationForm.value.EventTime);
        deviation.ExecutionUserId = this.deviationForm.value.ExecutionUserId;
        deviation.FollowupUserId = this.deviationForm.value.FollowupUserId;
        deviation.NotifyBeforeDeadLine = this.deviationForm.value.NotifyBeforeDeadline;
        deviation.NotifyBeforeFollowup = this.deviationForm.value.NotifyBeforeFollowup;
        deviation.CorrectiveAction = this.deviationForm.value.CorrectiveAction;
        deviation.OfferInclude = this.deviationForm.value.OfferInclude;
        deviation.OfferPrice = this.deviationForm.value.OfferPrice;
        deviation.ApplicationId = BaseServices.ApplicationId;
        deviation.BusinessId = BaseServices.BusinessId;
        deviation.InspectionId = this.inspectionId;
        deviation.ParaViolated = JSON.stringify(this.deviationForm.value.ParaViolated);
        deviation.ElectroDeviationAttachment = this.newDocumentAdded;
        if (this.deviation.Id > 0) {
            deviation.CreatedBy = this.deviation.CreatedBy;
            deviation.CreatedOn = this.deviation.CreatedOn;
            deviation.ModifiedBy = BaseServices.UserId;
            deviation.Id = this.deviation.Id;
            this.subscriptions.push(this.electroService.updateDeviation(deviation, CultureInfo).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.newDocumentAdded = [];
                    this.showLoader = false;
                    if (isback) {
                        this.isAddDeviationClicked = false;
                    }
                    this.subscriptions.push(this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
                        if (result) {
                            if (this.enableForm == 'emergency-lighting') {
                                this.emergencyLightEquipStatus[this.emerEqipIndex].Signature =
                                    this.translate.instant('SIGNED') + " : " +
                                    BaseServices.Name +
                                    ' ' +
                                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                            }
                            else if (this.enableForm == 'fire-alarm') {
                                this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].Signature =
                                    this.translate.instant('SIGNED') + " : " +
                                    BaseServices.Name +
                                    ' ' +
                                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                            }
                            else {
                                this.CompleteChecklistEdit.Section[this.addFileHeadpoint]
                                    .Items[this.addFileCheckpoint].Signature =
                                    this.translate.instant('SIGNED') + " : " +
                                    BaseServices.Name +
                                    ' ' +
                                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                            }
                        }
                        if (this.enableForm == 'emergency-lighting' || this.enableForm == 'fire-alarm') {
                            this.saveForm('stay');
                        }
                        else {
                            this.checklistData.CheckListData = JSON.stringify(this.CompleteChecklistEdit);
                            this.subscriptions.push(this.electroDocumentService.updateCompletionChecklistData(this.checklistData).subscribe(data => {
                                if (data) {
                                    this.checklistData = data;
                                    this.CompleteChecklistEdit = JSON.parse(data.CheckListData);
                                }
                            }));
                        }
                    }));
                }
            }));
        }
        else {
            deviation.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroService.saveDeviation(deviation, CultureInfo).subscribe(result => {
                if (result) {
                    this.deviation.Id = result.Id;
                    if (this.enableForm == 'emergency-lighting') {
                        if (this.emergencyLightEquipStatus[this.emerEqipIndex].DeviationIdList) {
                            this.emergencyLightEquipStatus[this.emerEqipIndex].DeviationIdList.push(result.Id);
                        }
                        else {
                            this.emergencyLightEquipStatus[this.emerEqipIndex].DeviationIdList = [];
                            this.emergencyLightEquipStatus[this.emerEqipIndex].DeviationIdList.push(result.Id);
                        }
                        this.emergencyLightEquipStatus[this.emerEqipIndex].Status[0].value = false;
                        this.emergencyLightEquipStatus[this.emerEqipIndex].Status[1].value = false;
                    } else if (this.enableForm == 'fire-alarm') {
                        if (this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].DeviationIdList) {
                            this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].DeviationIdList.push(result.Id);
                        }
                        else {
                            this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].DeviationIdList = [];
                            this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].DeviationIdList.push(result.Id);
                        }
                        this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].Status[0].value = false;
                        this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].Status[1].value = false;
                    } else {
                        if (this.CompleteChecklistEdit.Section[this.addFileHeadpoint].Items[this.addFileCheckpoint].DeviationIdList) {
                            this.CompleteChecklistEdit.Section[this.addFileHeadpoint]
                                .Items[this.addFileCheckpoint].DeviationIdList.push(result.Id);
                        }
                        else {
                            this.CompleteChecklistEdit.Section[this.addFileHeadpoint]
                                .Items[this.addFileCheckpoint].DeviationIdList = [];
                            this.CompleteChecklistEdit.Section[this.addFileHeadpoint]
                                .Items[this.addFileCheckpoint].DeviationIdList.push(result.Id);
                        }
                    }
                    this.toasterComponent.callToast();
                    this.newDocumentAdded = [];
                    this.showLoader = false;
                    if (isback) {
                        this.isAddDeviationClicked = false;
                    }
                    this.subscriptions.push(this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
                        if (result) {
                            if (this.enableForm == 'emergency-lighting') {
                                this.emergencyLightEquipStatus[this.emerEqipIndex].Signature =
                                    this.translate.instant('SIGNED') + " : " +
                                    BaseServices.Name +
                                    ' ' +
                                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                            } else if (this.enableForm == 'fire-alarm') {
                                this.fireAlarmEquipStatus[this.fireAlarmEqipIndex].Signature =
                                    this.translate.instant('SIGNED') + " : " +
                                    BaseServices.Name +
                                    ' ' +
                                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                            }
                            else {
                                this.CompleteChecklistEdit.Section[this.addFileHeadpoint]
                                    .Items[this.addFileCheckpoint].Signature =
                                    this.translate.instant('SIGNED') + " : " +
                                    BaseServices.Name +
                                    ' ' +
                                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
                            }
                        }
                        if (this.enableForm == 'emergency-lighting' || this.enableForm == 'fire-alarm') {
                            this.saveForm('stay');
                        }
                        else {
                            this.checklistData.CheckListData = JSON.stringify(this.CompleteChecklistEdit);
                            this.subscriptions.push(this.electroDocumentService.updateCompletionChecklistData(this.checklistData).subscribe(data => {
                                if (data) {
                                    this.checklistData = data;
                                    this.CompleteChecklistEdit = JSON.parse(data.CheckListData);
                                }
                            }));
                        }
                    }));
                }
            }));
        }
    }

    /**
     * delete deviation event.
     * @param id 
     * @param headPoint 
     * @param subSection 
     */
    deleteDeviation(id, headPoint, subSection) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.deleteEventObserve = true;
                this.subscriptions.push(this.electroService.deleteDeviation(id).subscribe(result => {
                    this.toasterComponent.callToastDlt();
                    let idIndex = this.CompleteChecklistEdit.Section[headPoint].Items[subSection].DeviationIdList.findIndex(x => x == id);
                    this.CompleteChecklistEdit.Section[headPoint].Items[subSection].DeviationIdList.splice(idIndex, 1);
                    let devIndex = this.CompleteChecklistEdit.Section[headPoint].Items[subSection].DeviationList.findIndex(x => x.Id == id);
                    this.CompleteChecklistEdit.Section[headPoint].Items[subSection].DeviationList.splice(devIndex, 1);
                }));

            }
        });
    }
    //#endregion

    onReportFormChange(fillType) {
        this.addDistFromTemplate = false;
        this.isMp = false;
        this.fillType = fillType;
        this.predefine = 0;
        if (!this.checklistData) {
            this.checklistData.ChecklistId = null;
        }
        let enabledChecklists = this.route.snapshot.data['enabledChecklists'];
        let enabledForms = this.route.snapshot.data['forms'];
        this.subscriptions.push(this.translate.stream('SELECT_INSTALLATION_FORM').subscribe(val => {
            this.enabledChecklists = [];
            this.enabledChecklists.push(
                { label: val.SELECT, value: null });
            if (enabledChecklists && this.fillType == 'CHECKLIST') {
                if (this.isNew && !this.checklistData.ChecklistId) {
                    this.checklistData.ChecklistId = (enabledChecklists.length == 1) ? enabledChecklists[0].id : null;
                }
                enabledChecklists.forEach(cl => {
                    this.enabledChecklists.push({ label: cl.name, value: cl.id, type: 'checklist' });
                });
            }
            else if (enabledForms && this.fillType == 'FORM') {
                if (this.isNew && !this.checklistData.ChecklistId) {
                    this.checklistData.ChecklistId = (enabledChecklists.length == 1) ? enabledChecklists[0].id : null;
                }
                enabledForms.forEach(f => {
                    switch (f.TranslationKey) {
                        case "ASSESSMENT_OF_AVAILABLE_DOCUMENTATION":
                            this.enabledChecklists.push({ label: val.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION, value: f.Id, type: 'form' });
                            break;
                        case "RISK_ASSESSMENT_OF_THE_INSTALLATION":
                            this.enabledChecklists.push({ label: val.RISK_ASSESSMENT_OF_THE_INSTALLATION, value: f.Id, type: 'form' });
                            break;
                        case "ELECTRICAL_DISTRIBUTION_OVERVIEW":
                            this.enabledChecklists.push({ label: val.ELECTRICAL_DISTRIBUTION_OVERVIEW, value: f.Id, type: 'form' });
                            break;
                        case "ELECTRICAL_COURSE_OVERVIEW":
                            this.enabledChecklists.push({ label: val.ELECTRICAL_COURSE_OVERVIEW, value: f.Id, type: 'form' });
                            break;
                        case "FIRE_ALARM_SYSTEM":
                            this.enabledChecklists.push({ label: val.FIRE_ALARM_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "EMERGENCY_LIGHTING_SYSTEM":
                            this.enabledChecklists.push({ label: val.EMERGENCY_LIGHTING_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "FIRE_SPRINKLER_SYSTEM":
                            this.enabledChecklists.push({ label: val.FIRE_SPRINKLER_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "FIRE_SPRINKLER_SYSTEM":
                            this.enabledChecklists.push({ label: val.FIRE_SPRINKLER_SYSTEM, value: f.Id, type: 'form' });
                            break;
                        case "MEASURE_PROTOCOL":
                            this.enabledChecklists.push({ label: val.MEASURE_PROTOCOL, value: f.Id, type: 'MP' });
                            break;
                    }
                });
            }
        }));

    }
    previewAttachment(headPoint, subSection) {
        let imgFormats = ['png',
            'jpg',
            'JPG',
            'jpeg',
            'bmp',
            'gif',
            'tiff']
        let attachments = this.enableForm == 'emergency-lighting' ? this.emergencyLightEquipStatus[subSection].Attachments
            : this.enableForm == 'fire-alarm' ? this.fireAlarmEquipStatus[subSection].Attachments
                : this.CompleteChecklistEdit.Section[headPoint].Items[subSection].Attachments;
        let images = attachments.filter(x => imgFormats.find(f => f == x.path.substr(x.path.lastIndexOf('.') + 1)));
        this.previewImages = images ? images : [];
    }

    hideModal() {
        this.previewImages = [];
    }

    saveForm(nav: string) {
        switch (this.enableForm) {
            case 'document-assessment':
                this.documentAssessment.editMode = true;
                if (this.taskId) {
                    this.documentAssessment.DocumentAssessment.SubTaskId = this.taskId;
                }
                this.documentAssessment.DocumentAssessment.ElectroContractId = this.contractId;
                this.documentAssessment.DocumentAssessment.BuildingId = this.checklistData.BuildingId;
                this.documentAssessment.DocumentAssessment.Version = this.formVersion;
                this.documentAssessment.DocumentAssessment.CompletionTitle = this.checklistData.Title;
                this.documentAssessment.DocumentAssessment.Status = this.checklistData.Status;
                this.documentAssessment.DocumentAssessment.SignatureImageData = this.canvas.nativeElement.toDataURL();
                nav == 'back' ? this.documentAssessment.saveBackToList() : this.documentAssessment.saveDocumentAssessment();
                break;
            case 'risk-assessment':
                this.riskAssessment.editMode = true;
                if (this.taskId) {
                    this.riskAssessment.riskAssessment.SubTaskId = this.taskId;
                }
                this.riskAssessment.riskAssessment.ElectroContractId = this.contractId;
                this.riskAssessment.riskAssessment.BuildingId = this.checklistData.BuildingId;
                this.riskAssessment.riskAssessment.Version = this.formVersion;
                this.riskAssessment.riskAssessment.CompletionTitle = this.checklistData.Title;
                this.riskAssessment.riskAssessment.Status = this.checklistData.Status;
                this.riskAssessment.riskAssessment.SignatureImageData = this.canvas.nativeElement.toDataURL();
                nav == 'back' ? this.riskAssessment.saveBackToList() : this.riskAssessment.saveRiskAssessment();
                break;
            case 'distribution-overview':
                this.distribution.editMode = true;
                if (this.taskId) {
                    this.distribution.distributionOverview.SubTaskId = this.taskId;
                }
                this.distribution.distributionOverview.ElectroContractId = this.contractId;
                this.distribution.distributionOverview.BuildingId = this.checklistData.BuildingId;
                this.distribution.distributionOverview.Version = this.formVersion;
                this.distribution.distributionOverview.CompletionTitle = this.checklistData.Title;
                this.distribution.distributionOverview.Status = this.checklistData.Status;
                this.distribution.distributionOverview.SignatureImageData = this.canvas.nativeElement.toDataURL();
                nav == 'back' ? this.distribution.saveBackToList() : this.distribution.saveDistributions();
                break;
            case 'course-overview':
                let duplicateExists = false;
                this.course.courseDetails.forEach(c => {
                    let crs = this.course.courseDetails.filter(x => x.course == c.course);
                    if (crs.length > 1) {
                        duplicateExists = true;
                    }
                });
                if (duplicateExists) {
                    nav = '';
                    alert(this.translate.instant('COURSES_SHOULD_UNIQUE'));
                }
                else {
                    this.course.editMode = true;
                    if (this.taskId) {
                        this.course.courseOverview.SubTaskId = this.taskId;
                    }
                    this.course.courseOverview.ElectroContractId = this.contractId;
                    this.course.courseOverview.BuildingId = this.checklistData.BuildingId;
                    this.course.courseOverview.Version = this.formVersion;
                    this.course.courseOverview.CompletionTitle = this.checklistData.Title;
                    this.course.courseOverview.Status = this.checklistData.Status;
                    this.course.courseOverview.SignatureImageData = this.canvas.nativeElement.toDataURL();
                    nav == 'back' ? this.course.saveBackToList() : this.course.saveCourse();
                }
                break;
            case 'fire-alarm':
                this.filreAlarm.editMode = true;
                if (this.taskId) {
                    this.filreAlarm.firealarmSystem.SubTaskId = this.taskId;
                }
                this.filreAlarm.firealarmSystem.ElectroContractId = this.contractId;
                this.filreAlarm.firealarmSystem.BuildingId = this.checklistData.BuildingId;
                this.filreAlarm.firealarmSystem.Version = this.formVersion;
                this.filreAlarm.firealarmSystem.CompletionTitle = this.checklistData.Title;
                this.filreAlarm.firealarmSystem.Status = this.checklistData.Status;
                this.filreAlarm.firealarmSystem.SignatureImageData = this.canvas.nativeElement.toDataURL();
                this.filreAlarm.firealarmSystem.OtherEquipmentStatus = this.fireAlarmEquipStatus ?
                    JSON.stringify(this.fireAlarmEquipStatus) : null;
                this.filreAlarm.firealarmSystem.IsEquipmentUpdate = this.staticTabs.tabs[1].active;
                nav == 'back' ? this.filreAlarm.saveBackToList() : this.filreAlarm.saveFireSystem();
                break;
            case 'emergency-lighting':
                this.emergencyLighting.editMode = true;
                if (this.taskId) {
                    this.emergencyLighting.lighting.SubTaskId = this.taskId;
                }
                this.emergencyLighting.lighting.ElectroContractId = this.contractId;
                this.emergencyLighting.lighting.BuildingId = this.checklistData.BuildingId;
                this.emergencyLighting.lighting.Version = this.formVersion;
                this.emergencyLighting.lighting.CompletionTitle = this.checklistData.Title;
                this.emergencyLighting.lighting.Status = this.checklistData.Status;
                this.emergencyLighting.lighting.SignatureImageData = this.canvas.nativeElement.toDataURL();
                this.emergencyLighting.lighting.OtherEquipmentStatus = this.emergencyLightEquipStatus ?
                    JSON.stringify(this.emergencyLightEquipStatus) : null;
                this.emergencyLighting.lighting.IsEquipmentUpdate = this.staticTabs.tabs[1].active;
                nav == 'back' ? this.emergencyLighting.saveBackToList() : this.emergencyLighting.saveEmergencyLighting();
                break;
        }
        if (nav == 'next') {
            if ((this.enableForm == 'emergency-lighting' || this.enableForm == 'fire-alarm') && this.staticTabs.tabs[2].active) {
                this.staticTabs.tabs[3].disabled = false;
                this.staticTabs.tabs[3].active = true;
            } else {
                this.staticTabs.tabs[2].disabled = false;
                this.staticTabs.tabs[2].active = true;
            }
        }
        this.isNew = false;
    }
    //#region signature
    @ViewChild('canvas', { static: false }) public canvas: ElementRef;


    private cx: CanvasRenderingContext2D;
    i = new Image;

    public ngAfterViewInit() {//canvas property adjustment after view child init.

        this.cx = null;
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        canvasEl.width = 380;
        canvasEl.height = 150;

        this.cx.lineWidth = 3;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#000';

        this.captureEvents(canvasEl);
    }
    ngAfterViewChecked() {
        if (this.firstInit) {
            setTimeout(() => {
                this.bindSign();
            }, 300);//binding saved sign after CanvasRenderingContext2D(cx) view init completed.
        }
    }

    /**
     * canvas mouse event capturer.
     * @param canvasEl 
     */
    private captureEvents(canvasEl: HTMLCanvasElement) {
        this.subscriptions.push(Observable
            .fromEvent(canvasEl, 'mousedown')
            .switchMap((e) => {
                return Observable
                    .fromEvent(canvasEl, 'mousemove')
                    .takeUntil(Observable.fromEvent(canvasEl, 'mouseup'))
                    .pairwise()
            })
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);
            }));
    }

    /**
     * draw on canvas function.
     * @param prevPos 
     * @param currentPos 
     */
    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
        if (!this.cx) { return; }

        this.cx.beginPath();

        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }
    }

    /**
     * clear signature from canvas.
     */
    clearSign() {
        this.firstInit = false;
        this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
    }

    /**
     * bind signature to canvas.
     */
    bindSign() {
        if (this.checklistData.SignatureImageData) {
            this.i.src = this.checklistData.SignatureImageData;
            this.cx.drawImage(this.i, 0, 0);
        }
    }
    //#endregion

    getstep2Header() {
        if (this.fillType == 'CHECKLIST')
            return this.translate.instant('STEP2_CHECKLIST')
        else if (this.fillType == 'FORM' && (this.enableForm == 'emergency-lighting' || this.enableForm == 'fire-alarm'))
            return this.translate.instant('STEP2_EDIT_CONTENT')
        else if (this.fillType == 'FORM' && (this.enableForm == 'measure'))
            return this.translate.instant('STEP2_INSTRUMENT');
        else return this.translate.instant('STEP2_FORM');
    }

    Back() {
        this.location.back();
    }

    onReportFormtypeChange(eve) {
        this.addDistFromTemplate = false;
        this.isMp = false;
        this.checklistData.Title = '';
        if (this.fillType == 'FORM') {
            this.checklistData.Title = this.enabledChecklists.find(x => x.value == this.checklistData.ChecklistId).label;
        } if (eve.selectedOption.type == "MP") {
            this.isMp = true;
            this.bindDistribution();
        } else if (this.checklistData.ChecklistId == ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW) {
            this.bindDistribution();
        }
    }

    getstep3Header() {
        return (this.enableForm == 'emergency-lighting' || this.enableForm == 'fire-alarm') ?
            this.translate.instant('STEP3_CHECKLIST') : this.enableForm == 'measure' ?
                this.translate.instant('STEP3_VS_STATUS') : this.translate.instant('STEP3_FINISH')
    }

    getstep4Header() {
        return this.enableForm == 'measure' ? this.translate.instant('STEP4_EARTH_LEAKAGE_BREAKER')
            : this.translate.instant('STEP4_FINISH')
    }

    EnableTab(tab) {
        this.staticTabs.tabs[tab].disabled = false;
        this.staticTabs.tabs[tab].active = true;
    }

    onCourseDistSelect(distId) {
        if (distId) {
            this.saveForm('stay');
            if (!(+this.inspectionId)) {
                this.inspectionId = this.course.courseOverview.InspectionId;
            }
            setTimeout(() => {
                this.subscriptions.push(this.electroInstallationService.getCourseByInspectionDistribution(this.checklistData.BuildingId,
                    this.inspectionId, distId).subscribe(course => {
                        if (course) {
                            this.formData = course;
                            if (this.course.modifiedOn ? this.course.modifiedOn < course.ModifiedOn : true) {
                                this.checklistData.Status = course.Status;
                                this.checklistData.SignatureImageData = course.SignatureImageData;
                                this.checklistData.Title = course.CompletionTitle;
                                if (course.Status == Status.Done) {
                                    this.staticTabs.tabs[2].disabled = false;
                                }
                            }
                        } else {
                            this.formData = new ElectroCourseOverview();
                        }
                        this.formData.DistributionId = distId;
                        this.formData.BuildingId = this.checklistData.BuildingId;
                        this.formData.ElectroContractId = this.contractId;
                        this.course.showLoader = false;
                        this.course.initForm(this.formData);
                        this.course.editMode = true;
                    }));
            }, 250);
        }
    }

    //#region Emergency Lighting checklist functions
    addEditEmerDeviation(devId, emerEqipIndex) {
        this.deviation = new ElectroDeviation();
        this.emerEqipIndex = emerEqipIndex;
        if (devId) {
            this.subscriptions.push(this.electroService.getDeviationById(devId).subscribe(result => {
                if (result) {
                    this.deviation = result;
                    this.initDeviationForm();
                }
            }));
        }
        else {
            this.initDeviationForm();
        }
    }
    deleteEmerDeviation(id, emerEqipIndex) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.deleteEventObserve = true;
                this.subscriptions.push(this.electroService.deleteDeviation(id).subscribe(result => {
                    this.toasterComponent.callToastDlt();
                    let idIndex = this.emergencyLightEquipStatus[emerEqipIndex].DeviationIdList.findIndex(x => x == id);
                    this.emergencyLightEquipStatus[emerEqipIndex].DeviationIdList.splice(idIndex, 1);
                    let devIndex = this.emergencyLightEquipStatus[emerEqipIndex].DeviationList.findIndex(x => x.Id == id);
                    this.emergencyLightEquipStatus[emerEqipIndex].DeviationList.splice(devIndex, 1);
                    this.emergencyLighting.lighting.OtherEquipmentStatus = JSON.stringify(this.emergencyLightEquipStatus);
                }));

            }
        });
    }

    emerUploaderEvents(event: any, emergencyLightEquipStatusItem: any) {
        switch (event.eventName) {
            case 'DeleteDefaultFile':
                emergencyLightEquipStatusItem.Attachments = emergencyLightEquipStatusItem.Attachments.filter(
                    x => x.path !== event.item.data.path
                );
                break;
            default:
                let uploadedFile = event;
                // TODO: add  uploaded files to attachment objects
                break;
        }
    }

    saveEmerForm(otherEquipmentStatus) {
        this.emergencyLightEquipStatus = otherEquipmentStatus ? JSON.parse(otherEquipmentStatus) : this.emergencyLightEquipStatus;
    }

    checkEmerCklValid() {
        let val = false;
        if (this.emergencyLightEquipStatus) {
            let sections = this.emergencyLightEquipStatus;
            for (let item of sections) {
                let status = item.Status;
                if (item.DeviationList ? item.DeviationList.length > 0 : false) {
                    for (let statusitem of item.Status) {
                        if (statusitem.Label == "ConditionOk")
                            statusitem.value = false;
                    }
                }
                else if (
                    (status[0].value === false && status[1].value === false) ||
                    (status[4].value === false &&
                        (item.DeviationList ? item.DeviationList.length === 0 : true))
                ) {
                    return val = true;
                }
            }
        }

        return (val || !(this.checklistData.BuildingId && this.checklistData.Title &&
            this.checklistData.Title.trim() != ""));
    }

    deleteEquipStatus(itemRef) {
        if (this.emergencyLightEquipStatus) {
            var a = this.emergencyLightEquipStatus.find(x => x.itemRef == itemRef);
            this.emergencyLightEquipStatus.splice(this.emergencyLightEquipStatus.indexOf(a), 1);
        }

    }
    //#endregion

    //#region Fire Alarm checklist functions
    addEditFireDeviation(devId, fireAlarmEqipIndex) {
        this.deviation = new ElectroDeviation();
        this.fireAlarmEqipIndex = fireAlarmEqipIndex;
        if (devId) {
            this.subscriptions.push(this.electroService.getDeviationById(devId).subscribe(result => {
                if (result) {
                    this.deviation = result;
                    this.initDeviationForm();
                }
            }));
        }
        else {
            this.initDeviationForm();
        }
    }
    deleteFireDeviation(id, fireAlarmEqipIndex) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.deleteEventObserve = true;
                this.subscriptions.push(this.electroService.deleteDeviation(id).subscribe(result => {
                    this.toasterComponent.callToastDlt();
                    let idIndex = this.fireAlarmEquipStatus[fireAlarmEqipIndex].DeviationIdList.findIndex(x => x == id);
                    this.fireAlarmEquipStatus[fireAlarmEqipIndex].DeviationIdList.splice(idIndex, 1);
                    let devIndex = this.fireAlarmEquipStatus[fireAlarmEqipIndex].DeviationList.findIndex(x => x.Id == id);
                    this.fireAlarmEquipStatus[fireAlarmEqipIndex].DeviationList.splice(devIndex, 1);
                    this.filreAlarm.firealarmSystem.OtherEquipmentStatus = JSON.stringify(this.fireAlarmEquipStatus);
                }));

            }
        });
    }

    fireUploaderEvents(event: any, fireAlarmEquipStatusItem: any) {
        switch (event.eventName) {
            case 'DeleteDefaultFile':
                fireAlarmEquipStatusItem.Attachments = fireAlarmEquipStatusItem.Attachments.filter(
                    x => x.path !== event.item.data.path
                );
                break;
            default:
                break;
        }
    }

    saveFireForm(otherEquipmentStatus) {
        this.fireAlarmEquipStatus = otherEquipmentStatus ? JSON.parse(otherEquipmentStatus) : this.fireAlarmEquipStatus;
    }

    checkFireCklValid() {
        let val = false;
        if (this.fireAlarmEquipStatus) {
            let sections = this.fireAlarmEquipStatus;
            for (let item of sections) {
                let status = item.Status;
                if (item.DeviationList ? item.DeviationList.length > 0 : false) {
                    for (let statusitem of item.Status) {
                        statusitem.value = false;
                    }
                }
                else if (
                    status[0].value === false &&
                    status[1].value === false &&
                    (item.DeviationList ? item.DeviationList.length === 0 : true)
                ) {
                    return val = true;
                }
            }
        }
        return (val || !(this.checklistData.BuildingId && this.checklistData.Title &&
            this.checklistData.Title.trim() != ""));
    }

    deleteFireEquipStatus(itemRef) {
        if (this.fireAlarmEquipStatus) {
            var a = this.fireAlarmEquipStatus.find(x => x.itemRef == itemRef);
            this.fireAlarmEquipStatus.splice(this.fireAlarmEquipStatus.indexOf(a), 1);
        }
    }
    //#endregion

    //#region Measure protocol functions
    saveMpCompleteStep2(nav: string) {
        let cmp = new CMP();
        cmp.Id = this.completeMP ? this.completeMP.Id : 0;
        cmp.Name = this.completeMP ? this.completeMP.Name : this.checklistData.Title;
        cmp.KundeELId = this.contractId;
        cmp.Status = 1;
        cmp.CreatedOn = this.completeMP ? this.completeMP.CreatedOn : new Date();
        cmp.CreatedBy = this.completeMP ? this.completeMP.CreatedBy : BaseServices.UserId;
        cmp.FeatureId = FeatureKey.ELECTRO;
        cmp.BuildingId = this.checklistData.BuildingId;
        cmp.InspectionId = this.inspectionId;
        cmp.DistributionId = this.distributionId;
        if (!cmp.Id) {
            this.subscriptions.push(this.measureprotocolservice.createCMP(0, cmp).subscribe((res: any) => {
                if (res) {
                    this.completeMP = res;
                    this.isNew = false;
                    if (nav == 'next3') {
                        this.staticTabs.tabs[2].disabled = false;
                        this.staticTabs.tabs[2].active = true;
                        this.staticTabs.tabs[3].disabled = false;
                        this.staticTabs.tabs[4].disabled = false;
                        this.staticTabs.tabs[5].disabled = false;
                        this.staticTabs.tabs[6].disabled = false;
                    }
                    else if (nav == 'next2') {
                        this.staticTabs.tabs[1].active = true;
                    } else {
                        this.location.back();
                    }
                    if (this.selectedmpInstruments ? this.selectedmpInstruments.length : false) {
                        let instruments = [];
                        this.selectedmpInstruments.forEach(sIns => {
                            let ins = new CMPInstrument();
                            ins.InstrumentId = sIns.Id;
                            ins.MeasureProtocolCompleteId = res.Id;
                            instruments.push(ins);
                        });
                        this.subscriptions.push(this.measureprotocolservice.saveInstrumentsforMPCompleteElectro(instruments)
                            .subscribe((res: any) => {
                            }));
                    }
                }
            }));
        }
        else {
            this.subscriptions.push(this.measureprotocolservice.updateCMP(0, cmp).subscribe((res: any) => {
                if (res) {
                    this.completeMP.Id = res.Id;
                    if (nav == 'next3') {
                        this.staticTabs.tabs[2].disabled = false;
                        this.staticTabs.tabs[2].active = true;
                    } else if (nav == 'next2') {
                        this.staticTabs.tabs[1].active = true;
                    } else {
                        this.location.back();
                    }
                    this.toasterComponent.callToast();
                }
            }));
            if (this.selectedmpInstruments ? this.selectedmpInstruments.length : false) {
                let instruments = [];
                this.selectedmpInstruments.forEach(sIns => {
                    let ins = new CMPInstrument();
                    ins.InstrumentId = sIns.Id;
                    ins.MeasureProtocolCompleteId = this.completeMP.Id;
                    instruments.push(ins);
                });
                this.subscriptions.push(this.measureprotocolservice.saveInstrumentsforMPCompleteElectro(instruments)
                    .subscribe(res => {
                    }));
            }
        }
    }

    SaveVS(nav: string) {
        this.vsControl.cmpId = this.completeMP.Id;
        this.earthLeak.cmpId = this.completeMP.Id;
        this.pwrLeak.cmpId = this.completeMP.Id;
        this.temperature.cmpId = this.completeMP.Id;
        this.vsControl.saveVC();
        if (nav == 'back') {
            this.location.back();
        } else {
            this.EnableTab(3);
        }
    }

    CompleteSignMP() {
        let cmp = new CMP();
        cmp.Id = this.completeMP.Id;
        cmp.Name = this.completeMP.Name;
        cmp.Number = this.completeMP.Number;
        cmp.MeasureProtocolId = this.completeMP.MeasureProtocolId;
        cmp.KundeELId = this.contractId;
        cmp.Status = 2;
        cmp.CreatedOn = this.completeMP ? this.completeMP.CreatedOn : new Date();
        cmp.CreatedBy = this.completeMP ? this.completeMP.CreatedBy : BaseServices.UserId;
        cmp.FeatureId = FeatureKey.ELECTRO;
        cmp.BuildingId = this.checklistData.BuildingId;
        cmp.InspectionId = this.inspectionId;
        cmp.Path = this.canvas.nativeElement.toDataURL();
        this.subscriptions.push(this.measureprotocolservice.updateCMP(cmp.MeasureProtocolId, cmp).subscribe(res => {
            if (res) {
                this.location.back();
                this.toasterComponent.callToast();
            }
        }));
    }

    saveDisable() {
        if (this.enableForm == 'course-overview') {
            return this.course ? (this.checklistData.BuildingId && this.checklistData.ChecklistId
                && this.course.courseOverview.DistributionId) ? this.addDistFromTemplate : true : true;
        }
        else if (this.enableForm == 'measure') {
            return !(this.checklistData.BuildingId && this.checklistData.ChecklistId && this.distributionId);
        }
        return !(this.checklistData.BuildingId && this.checklistData.ChecklistId);
    }

    bindDistribution(dId?: number) {
        if (this.fillType == 'FORM' &&
            (this.checklistData.ChecklistId == ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW ||
                this.checklistData.ChecklistId == ElectroInstallatioForms.MEASURE_PROTOCOL)) {
            this.electroInstallationService.getDistributionFloorByBuilding(this.checklistData.BuildingId, this.inspectionId).subscribe(res => {
                if (res ? res.length : false) {
                    this.addDistFromTemplate = false;
                    this.distributionId = dId;
                    this.subscriptions.push(this.translate.stream('SELECT_DISTRIBUTION').subscribe(val => {
                        this.distributions = [];
                        this.distributions.push({ label: val.SELECT, value: null });
                        res.forEach((d: any) => {
                            this.distributions.push({
                                label: d.Key + '(' + (d.DistTypeKey == 1 ? val.MAIN_DISTRIBUTION : val.SUB_DISTRIBUTION) + ')'
                                , value: d.Value
                            });
                        });
                    }));
                } else {
                    this.addDistFromTemplate = true;
                }
            });
        }
    }

    mpDistributionChange(dist) {
        this.checklistData.Title = this.translate.instant('MEASURE_PROTOCOL') + '-' +
            this.distributions.find(x => x.value == dist.value).label;
    }
    //#endregion

    addTemplateDistribution() {
        this.subscriptions.push(this.electroInstallationService.createDistributionFromTemplate(
            this.checklistData.BuildingId, this.inspectionId).subscribe(res => {
                if (res && res.length) {
                    this.toasterComponent.callDistributionToast();
                    this.addDistFromTemplate = false;
                    this.subscriptions.push(this.translate.stream('SELECT_DISTRIBUTION').subscribe(val => {
                        this.distributions = [];
                        this.distributions.push({ label: val.SELECT, value: null });
                        res.forEach((d: any) => {
                            this.distributions.push({
                                label: d.Key + '(' + (d.DistTypeKey == 1 ? val.MAIN_DISTRIBUTION : val.SUB_DISTRIBUTION) + ')'
                                , value: d.Value
                            });
                        });
                    }));
                }else{
                    this.distTemplateEmptyMsg = true;
                }
            }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}