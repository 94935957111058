export class EmployeeWorkExperienceList {
    Id: number;
    ExperienceId: string;
    EmployeeId: number;
    CompanyName: string;
    JoiningDate: string;
    RelievingDate: string;
    Position: string;
    ContactPerson: string;
    EmailId: string;
    Mobile: string;
    CountryCode: string;
    OtherCode: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date
}