import { CreateActivityLogComponent } from './components/new-activitylog.component';
import { ActivityListResolver } from './../follow-ups/services/activities.resolver.service';
import { ActivityLogListComponent } from './components/activitylog-list.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { Route } from '@angular/router';
export const activityLogRoutes: Route[] = [
    {
        path: 'activititylog',
        component: ActivityLogListComponent,
        resolve: {
            list: ActivityListResolver
        }
    },
    {
        path: 'activitylog/new',
        component: CreateActivityLogComponent,

    }
]