<div class="main-content">
    <div class="main-heading">
        <span class="page-actions text-right">
            <!-- <button [hidden]="true" type="button" class="btn btn-outline-primary" (click)="completeChecklist()"
                translate>COMPLETE_ZONE_CHECKLIST</button> -->
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view mbtm-30">
                <!-- Table starts -->
                <p-table #dt [value]="FoodSafety" [rows]="10" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]"
                    [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Caption -->
                    <ng-template pTemplate="caption">
                        <div class="ui-helper-clearfix">
                            <div class="table-options">
                                <div class="pull-right">
                                    <kuba-export [reportData]="dt.filteredValue || dt.value"
                                        [additionalData]="additionalData"></kuba-export>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="ZoneName">{{'ZONE_NAME'|translate}} <p-sortIcon
                                    field="ZoneName"></p-sortIcon></th>
                            <th pSortableColumn="CreatorName">{{'CREATED_BY'|translate}} <p-sortIcon
                                    field="CreatorName"></p-sortIcon></th>
                            <th pSortableColumn="CreatedOn">{{'CREATED_ON'|translate}} <p-sortIcon
                                    field="CreatedOn"></p-sortIcon></th>
                            <th *ngIf="isShowUser">{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="dt.filter($any($event.target)?.value, 'ZoneName', 'contains')"
                                    placeholder="" class="p-column-filter">
                            </th>
                            <th></th>
                            <th></th>
                            <th *ngIf="isShowUser"></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-fs>
                        <tr>
                            <td>
                                <a [routerLink]="['./../../details',fs.Id]"
                                    [queryParams]="{ Title : fs.ZoneName }">{{fs.ZoneName}}</a>
                            </td>
                            <td>
                                <span>{{fs.CreatorName}}</span>
                            </td>
                            <td>
                                <span>{{fs.CreatedOn |date : 'MM/dd/yyyy'}}</span>
                            </td>
                            <td class="col-button" *ngIf="isShowUser">
                                <a [routerLink]="['./../../edit',fs.Id]" class="btn btn-icon"
                                    title="{{'EDIT' | translate}}">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </a>
                                <button type="button" class="btn btn-icon" (click)="deleteZoneDialog(fs['Id'])"
                                    title="{{'DELETE' | translate}}">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="4">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
    <p-dialog header="{{'DELETE_CONFORMATION' | translate}}" [(visible)]="deleteDialog" [modal]="true"
        [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
        <div>
            <div class="form-group">
                <label for="RepeatPassword">
                    <span translate>Please_type_DELETE</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" pInputText [(ngModel)]="delete" (keyup)="onDelete(delete)" />
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-primary" (click)="closeZoneDialog()">
            <span translate>No</span>
        </button>
        <button type="button" class="btn btn-primary" [disabled]="!deleteButton"
            (click)="deleteFoodSafety(deleteZoneID)">
            <span translate>DELETE</span>
            <!-- <i class="icon ic-sm icon-trash"></i> -->
        </button>
    </p-dialog>

    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
    <toaster-component></toaster-component>
    <div class="panel-loader" *ngIf="showLoader">
        <span>
            <span class="loader-icon"></span>
            <span>Processing...</span>
        </span>
    </div>
</div>