import { FeatureKey } from './../../../_models/feature';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators
} from '@angular/forms';


import { PdfSetting, HelperService } from './../../../_services/helper.service';
import { FollowUpPerson, ActivityList } from 'src/app/kuba/task-manager/models/activity-list';
import { element } from 'protractor';
import { BaseServices } from './../../kuba.services';
import { ConfirmationService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { TaskService } from '../services/task.services';
import { Tasklist, ActivityDelete } from '../models/index';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { Rights } from 'src/app/_models';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

declare var pdfMake: any;
declare var buildPdf: any;
declare var dataBulider: any;
@Component({
    selector: 'task-list',
    templateUrl: 'task-list.component.html'
})
export class TaskListComponent implements OnInit {
    tasks: Tasklist[];
    @ViewChild('taskTable', { static: false }) taskTable: Table;
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    pdf: any;
    selectedTasklist: Tasklist;
    newTask: boolean;
    tasklist: Tasklist[];
    followups: SelectItem[];
    taskForm: FormGroup;
    newTaskForm: FormGroup;
    parentId: number;
    parentKey: any;
    statuses: SelectItem[];
    isGuest = true;
    isUser = false;
    additionalData: any;
    loading = false;
    displayDialog = false;
    taskData = new ActivityList();
    roleId: string;
    displayDialogForRecurring = false;
    recurringActivityId = 0;
    IsFutureRecurring: boolean;
    isAdministrateActivity: boolean;
    isDisableDeleteIcon = true;
    isNewButtonEnable: boolean;
    saveButtonClicked: boolean;
    userrights: any = [];
    projectcheck: boolean = false;
    private subscriptions: Subscription[] = [];
    /**
     * 
     *
     * @param taskService {{TaskService}}
     * @param confirmationService {{ConfirmationService}}
     * @param route {{ActivatedRoute}}
     * @param fb {{FormBuilder}}
     */
    constructor(
        public taskService: TaskService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.NEW, value: 7 },
                { label: val.OPEN, value: 8 },
                { label: val.DONE, value: 9 },
                { label: val.REJECTED, value: 10 }
            );
        }));
        this.taskForm = this.fb.group({
            FollowupUserId: ['']
        });
        this.exportPdf();
        this.newTaskForm = this.fb.group({
            Title: ['', Validators.required],
            Description: ['']
        });
        this.saveButtonClicked = false;
    }

    /**
     * edit task manager and bind icon based on status
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.roleId = BaseServices.roleId;
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
            this.isUser = true;
        }
        if (BaseServices.roleId === '5') {
            this.isUser = true;
            this.isDisableDeleteIcon = false;
        }
        this.isNewButtonEnable = BaseServices.roleId === '3' ? true : false;
        this.parentId = this.route.snapshot.params['Id']
        this.parentKey = this.route.parent.parent.snapshot.data['parent'];
        let tasks = <Tasklist[]>this.route.snapshot.data['list'];

        this.tasklist = [];
        this.initForm(tasks);
        this.bindDetails();
        this.onAdministrateActivity();
        this.userrights = JSON.parse(BaseServices.getUserRights());
        if (this.userrights != null) {
            for (let i = 0; i < this.userrights.length; i++) {
                if (this.userrights[i].name == 'ADMINISTRATE_PROJECTS') {
                    this.projectcheck = this.userrights[i].isChecked;
                }
            }
        }
    }
    initForm(taskList?: any): any {
        this.tasklist = [];
        taskList.forEach((element: any) => {
            element.Responsible = false;
            if (element.FollowupUserIdList) {
                for (let i = 0; i < element.FollowupUserIdList.length; i++) {
                    if (BaseServices.UserId === element.FollowupUserIdList[i]) {
                        element.Responsible = true;
                    }
                }
            }
            if (!element.Responsible) {
                if (element.ExecutionUserIdList) {
                    for (let i = 0; i < element.ExecutionUserIdList.length; i++) {
                        if (BaseServices.UserId === element.ExecutionUserIdList[i]) {
                            element.Responsible = true;
                        }
                    }
                }
            }
            let daysDiff = 0;
            let statusIcon = '';
            let currentdate = new Date();
            let deadLine = new Date(element.EndDate);
            if (element.EndDate != null) {
                daysDiff = this.getDaysDiff(currentdate, deadLine);
            }
            if (element.Status === 'NEW') {
                statusIcon = 'icon ic-sm icon-new-deadline';
            } else if (
                element.Status === 'DONE'
            ) {
                statusIcon = 'icon ic-sm icon-clear-deadline';
            }
            else if (element.Status === 'REJECTED') {
                statusIcon = 'icon ic-sm icon-cancel';
            }
            else if (element.EndDate) {
                if (daysDiff > 3) {
                    statusIcon = 'icon ic-sm icon-far-deadline';
                } else if (daysDiff > 0 && daysDiff < 3) {
                    statusIcon = 'icon ic-sm icon-near-deadline';
                } else {
                    statusIcon = 'icon ic-sm icon-over-deadline';
                }
            } else {
                statusIcon = 'icon ic-sm icon-over-deadline';
            }
            element.StatusIcon = statusIcon;
            this.tasklist.push(element);
        });
    }
    /**
     * get Task manager list
     */
    getTaskList() {
        this.subscriptions.push(this.taskService
            .getAllByEach(this.parentId, this.parentKey)
            .subscribe(res => {
                if (res) {
                    this.initForm(res)
                }
            }));
    }

    /**
     * bind dropdown
     * @param businessId {{number}}
     */
    bindDetails() {
        this.followups = [];
        this.followups.push({ label: 'Select a Person', value: null });
        let followup = <FollowUpPerson[]>this.route.snapshot.data['followup'];
        if (followup) {
            followup.forEach(element => {
                this.followups.push({ label: element.Name, value: element.Id });
            });
        }
    }
    /**
     * filter task manager based on followup person
     * @param e {{any}}
     * @param followupDropdown {{any}}
     */
    onFollowupChanged(e: any, followupDropdown: any) {
        // custom filter datatable
        if (e.value) {
            this.taskTable.filter(
                followupDropdown.selectedOption.value,
                'FollowupUserId',
                'equals'
            );
        } else {
            this.taskTable.reset();
        }
    }
    /**
    * filter task manager based on Execution person
    * @param e {{any}}
    * @param executionDropdown {{any}}
    */
    onExecutionChanged(e: any, executionDropdown: any) {
        // custom filter datatable
        if (e.value) {
            this.taskTable.filter(
                executionDropdown.selectedOption.value,
                'ExecutionUserId',
                'equals'
            );
        } else {
            this.taskTable.reset();
        }
    }
    /**
     * filter task mananger list based on status
     * @param e {{any}}
     * @param statusDropdown {{any}}
     */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.taskTable.filter(
                statusDropdown.selectedOption.value,
                'StatusId',
                'equals'
            );
        } else {
            this.taskTable.reset();
        }
    }

    /**
     * delete task manager based on Id
     * @param value {{any}}
     */
    confirm(value: any) {
        let activity = new ActivityDelete();
        activity.AdditionalFeatureId = FeatureKey.TASK_MANAGER;
        activity.AdditionalFeatureKey = value;
        activity.FeatureKey = this.parentId;
        activity.FeatureId = BaseServices.getFeatureId(this.parentKey);

        this.subscriptions.push(this.taskService.checkParentActivity(value)
            .subscribe((count: any) => {
                // check for child and normal actvitiy
                if (count === 0 || count === 2) {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.loading = true;
                            this.subscriptions.push(this.taskService.delete(value).subscribe(isRecurringActivityDeleted => {
                                if (isRecurringActivityDeleted) {
                                    this.toasterComponent.callToastDlt();
                                    this.getTaskList();
                                    this.loading = false;
                                }
                            }));
                        }
                    });
                } else {
                    // parent activity delete
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_PARENT_ACTIVITY'),
                        accept: () => {
                            this.loading = true;
                            this.subscriptions.push(this.taskService.deleteParentwithRecurring(value).subscribe(isActivityDeleted => {
                                if (isActivityDeleted) {
                                    this.toasterComponent.callToastDlt();
                                    this.getTaskList();
                                    this.loading = false;
                                }
                            }));
                        }
                    });

                }
            }));

    }
    /**
       * generate pdf
       */
    exportPdf() {
        this.parentKey = this.route.parent.parent.snapshot.data['parent'];
        if (this.parentKey === 'PROJECT') {
            this.additionalData = {
                fileName: 'Project Task Manager List',
                header: 'Project Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'FDV') {
            this.additionalData = {
                fileName: 'FDV Task Manager List',
                header: 'FDV Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'FS') {
            this.additionalData = {
                fileName: 'FS Task Manager List',
                header: 'FS Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'IC') {
            this.additionalData = {
                fileName: 'Internal control Task Manager List',
                header: 'Internal control Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'OS') {
            this.additionalData = {
                fileName: 'Other Systems Task Manager List',
                header: 'Other Systems  Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'QS') {
            this.additionalData = {
                fileName: 'Quality System Task Manager List',
                header: 'Quality System Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'VEHICLE') {
            this.additionalData = {
                fileName: 'Vehicle task manager list',
                header: 'Vehicle task manager list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'FRAMEWORK') {
            this.additionalData = {
                fileName: 'Framework agreement task manager list',
                header: 'Framework task manager list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'SERVICE') {
            this.additionalData = {
                fileName: 'Service agreement task manager list',
                header: 'Service agreement task manager list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        } else if (this.parentKey === 'KUNDE_EL') {
            this.additionalData = {
                fileName: 'IK-Kunde EL task manager list',
                header: 'IK-Kunde EL task manager list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        }
        else {
            this.additionalData = {
                fileName: 'Task Manager List',
                header: 'Task Manager List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Title', dataKey: 'Title' },
                    { title: 'Status', dataKey: 'Status' },
                    { title: 'Deadline', dataKey: 'EndDate' },
                    { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Done On', dataKey: 'CompletedDate' },
                    { title: 'Edited By', dataKey: 'ModifierName' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            }
        }
    }

    // TODO: to be implemented
    exportCSV() { }

    /**
     * get days between start date and end date
     * @param StartDate {{date}}
     * @param EndDate {{date}}
     */
    getDaysDiff(StartDate: Date, EndDate: Date) {
        if (StartDate && EndDate) {
            let _MS_PER_DAY = 1000 * 60 * 60 * 24;
            let date1 = Date.UTC(
                StartDate.getFullYear(),
                StartDate.getMonth(),
                StartDate.getDate()
            );
            let date2 = Date.UTC(
                EndDate.getFullYear(),
                EndDate.getMonth(),
                EndDate.getDate()
            );
            let res: any = Math.floor((date2 - date1) / _MS_PER_DAY);
            return res;
        }
    }
    showDialogToAdd() {
        if (this.newTaskForm) {
            this.newTaskForm = this.fb.group({
                Title: ['', Validators.required],
                Description: ['']
            });
        }
        this.displayDialog = true;
    }

    onSubmitTemplateBased() {
        this.saveButtonClicked = true;
        if (this.newTaskForm.valid) {
            this.taskData.Id = 0;
            this.taskData.BusinessId = BaseServices.BusinessId;
            this.taskData.Status = '7';
            this.taskData.FeatureKey = this.parentId;
            this.taskData.FeatureId = this.parentKey;
            this.taskData.ApplicationId = BaseServices.ApplicationId;
            this.taskData.CreatedBy = BaseServices.UserId;
            this.taskData.Title = this.newTaskForm.value.Title;
            this.taskData.Description = this.newTaskForm.value.Description;
            this.taskData.Status = '7';
            // add new task details
            this.subscriptions.push(this.taskService
                .addActivity(this.taskData, this.parentKey)
                .subscribe(isActivityAdded => {
                    if (isActivityAdded) {
                        this.toasterComponent.callToast();
                        this.getTaskList();
                        this.displayDialog = false;
                        this.saveButtonClicked = false;
                    }
                },
                    error => {
                        console.log('Error : ', error);
                        this.saveButtonClicked = false;
                    },
                    () => {
                        this.saveButtonClicked = false;
                    }
                ));
            this.newTaskForm.reset();
        }
    }

    /**
     * show dialog for stop future recurring activity
     */
    showDialogForStopFutureRecurring(data: any) {
        this.displayDialogForRecurring = true;
        this.recurringActivityId = data.Id;
        this.IsFutureRecurring = data.IsFutureRecurring ? data.IsFutureRecurring : false;
    }

    onClickStopFutureRecurring() {
        if (this.recurringActivityId > 0) {
            // this.confirmationService.confirm({
            //     message: this.translate.instant('STOP_FUTURE_RECURRING_ACTIVTY'),
            //     accept: () => {
                    this.displayDialogForRecurring = false;
                    this.loading = true;
                    this.subscriptions.push(this.taskService.stopFutureRecurring(this.recurringActivityId).subscribe(result => {
                        if (result) {
                            this.toasterComponent.callToaststopRecurring();
                            this.getTaskList();
                            this.loading = false;

                        }
                    }));
                // },
            //     reject: () => {
            //         this.loading = false;
            //     }
            // });
        }
    }

    onClickDeleteRecurringActivity() {
        if (this.recurringActivityId > 0) {
            this.subscriptions.push(this.taskService.checkParentActivity(this.recurringActivityId)
                .subscribe((count: any) => {
                    // check for normal activity or child activity
                    if (count === 0 || count === 2) {
                        // this.confirmationService.confirm({
                        //     message: this.translate.instant('DELETE_THIS_RECORD'),
                        //     accept: () => {
                                this.loading = true;
                                this.subscriptions.push(this.taskService.delete(this.recurringActivityId).subscribe(isRecurringActivityDeleted => {
                                    if (isRecurringActivityDeleted) {
                                        this.toasterComponent.callToastDlt();
                                        this.getTaskList();
                                        this.loading = false;
                                    }
                                }));
                        //     }
                        // });
                    } 
                    else {
                        // parent activity delete
                        // this.confirmationService.confirm({
                        //     message: this.translate.instant('DELETE_PARENT_ACTIVITY'),
                        //     accept: () => {
                                this.loading = true;
                                this.subscriptions.push(this.taskService.deleteParentwithRecurring(this.recurringActivityId).subscribe(isActivityDeleted => {
                                    if (isActivityDeleted) {
                                        this.toasterComponent.callToastDlt();
                                        this.getTaskList();
                                        this.loading = false;
                                    }
                                }));
                        //     }
                        // });
                    }
                }));
            this.displayDialogForRecurring = false;
        }
    }

    /**
    * edit the activity page based upon the rights
    */
    onAdministrateActivity() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
        if (currentUserRole === 'User') {
            this.isAdministrateActivity = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        } else if (currentUserRole === 'Guest') {
            this.isAdministrateActivity = false;
        } else {
            this.isAdministrateActivity = true;
        }
    }

    onCancel() {
        this.displayDialog = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
