<div class="tab-content">
    <div class="page-title">
        <span translate>WORK_EXPERIENCE</span>
    </div>
    <form [formGroup]="workexpForm">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group" *ngIf="workexpForm.controls['CompanyName']">
                    <label for="CompanyName">
                        <span translate>COMPANY_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="CompanyName" class="" maxlength="100" />
                    </div>
                    <control-messages [control]="workexpForm.controls['CompanyName']"></control-messages>
                </div>
                <div class="form-group-50_50">
                    <div class="form-group">
                        <label class="col-form-label" for="startDate">
                            <span translate>JOINING_DATE</span>
                            <span class="required">*</span>

                        </label>
                        <div>
                            <my-date-picker name="Startdate" [options]="startDateOptions" formControlName="StartDate"
                            placeholder="{{'ENTER_THE_START_DATE'|translate}}"
                            (dateChanged)="onStartDateChanged($event)" required></my-date-picker>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label" for="endDate">
                            <span translate>RELIEVING_DATE</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <my-date-picker name="Enddate" (dateChanged)="onEndDateChanged($event)"
                                [options]="endDateOptions" formControlName="EndDate"
                                placeholder="{{'ENTER_STOP_DATE'|translate}}"
                                required></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Position">
                        <span translate>POSITION</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="Position" id="Position" class=""
                            maxlength="100" />
                    </div>
                    <control-messages [control]="workexpForm.controls['Position']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="ContactPerson">
                        <span translate>CONTACT_PERSON</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="ContactPerson" id="ContactPerson" class=""
                            maxlength="100" />
                    </div>
                    <control-messages [control]="workexpForm.controls['ContactPerson']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Email">
                        <span translate>EMAIL</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="EmailId" class="" maxlength="100" />
                    </div>
                    <control-messages [control]="workexpForm.controls['EmailId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Mobile">
                        <span translate>MOBILE</span>
                        <span class="required">*</span>
                    </label>
                    <div *ngIf="!IsHidding" class="phone-field">
                        <p-dropdown [options]="CountryCode" formControlName="MobileCodes" [filter]="true"
                            [style]="{'width':'110px'}"> </p-dropdown>
                        <input  type="text" pInputText formControlName="Mobile" class=""
                            (keypress)="onMobileNumberChange($event,20)" />
                    </div>
                    <div *ngIf="!IsHide" class="phone-field">
                        <input type="text" pInputText formControlName="OtherCode" (keypress)="onMobileCode($event)"
                            [style]="{'width':'60px'}" />
                        <input type="text" pInputText formControlName="Mobile" class=""
                            (keypress)="onMobileNumber($event)" />
                    </div>
                    <div [hidden]="IsHidding">
                        <a routerLinkActive="active" href="javascript:void(0);" (click)="changeCountryCode()"
                            class="btn btn-link">
                            <span translate>OTHER_CODES</span>
                        </a>
                    </div>
                    <div [hidden]="IsHide">
                        <a routerLinkActive="active" href="javascript:void(0);" (click)="changeToCountryCodeDropDown()"
                            class="btn btn-link">
                            <span translate>PREDEFINED_CODES</span>
                        </a>
                    </div>
                    <control-messages [control]="workexpForm.controls['Mobile']"></control-messages>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper text-left">
            <div class="row">
                <div class="col-sm-6">
                    <a class="btn btn-outline-secondary" href="javascript:void(0);" (click)="Clear()" role="button">
                        <span translate>CLEAR</span>
                    </a>
                    <button class="btn btn-primary" type="submit" [ngClass]="{'loading' : loading}"
                        [disabled]="!workexpForm.valid || loading" (click)="saveChanges()">
                        <span *ngIf="!loading" translate>SAVE</span>
                        <span *ngIf="loading" translate>SAVE_PROCESS</span>
                        <span *ngIf="loading" class="loader-icon"></span>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="workexp" #dt [rows]="10" [paginator]="true" [style]="{'width':'100%'}" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="table-options">
                        <div class="pull-right">
                            <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData"></kuba-export>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="CompanyName">{{'COMPANY_NAME'|translate}} <p-sortIcon field="CompanyName"></p-sortIcon></th>
                    <th pSortableColumn="JoiningDate">{{'JOINING_DATE' | translate}} <p-sortIcon field="JoiningDate"></p-sortIcon></th>
                    <th pSortableColumn="RelievingDate">{{'RELIEVING_DATE' | translate}} <p-sortIcon field="RelievingDate"></p-sortIcon></th>
                    <th pSortableColumn="Position">{{'POSITION' | translate}} <p-sortIcon field="Position"></p-sortIcon></th>
                    <th pSortableColumn="ContactPerson">{{'CONTACT_PERSON' | translate}} <p-sortIcon field="ContactPerson"></p-sortIcon></th>
                    <th pSortableColumn="EmailId">{{'EMAIL' | translate}} <p-sortIcon field="EmailId"></p-sortIcon></th>
                    <th pSortableColumn="Mobile">{{'MOBILE' | translate}} <p-sortIcon field="Mobile"></p-sortIcon></th>
                    <th>{{'OPTIONS'| translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-work>
                <tr>
                    <td>
                        <span>{{work.CompanyName}}</span>
                    </td>
                    <td>
                        <span>{{work.JoiningDate |date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{work.RelievingDate |date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{work.Position}}</span>
                    </td>
                    <td>
                        <span>{{work.ContactPerson}}</span>
                    </td>
                    <td>
                        <span>{{work.EmailId}}</span>
                    </td>
                    <td>
                        <span>{{work.Mobile}}</span>
                    </td>
                    <td>
                        <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                            (click)="editEmployee(work['Id'])">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" class="btn btn-icon" *ngIf="isNotUser" title="{{'DELETE' | translate}}"
                            (click)="delete(work['Id'])">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="8">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
</div>
<p-dialog header="{{'NOTIFICATION' | translate}}" [(visible)]="showNotification" 
[style]="{width: '600px'}" [resizable]="false" [draggable]="false" translate>
    NO_RECORDS_FOUND
</p-dialog>

<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>