<div class="language-list">
  <div
    class="lang-item"
    *ngFor="let lang of appLanguages"
    [ngClass]="{ active: userLanguage.IsoCode == lang.IsoCode }"
  >
    <button
      (click)="changeLang(lang)"
      class="{{ lang.Title | lowercase }}"
      value="{{ lang.IsoCode }}"
    ></button>
  </div>
</div>
<p-toast position="top-right"></p-toast>
