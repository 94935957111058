

<form [formGroup]="completeGeneralForm">
    <div class="form-group">
        <label for="title">
            <span translate>TITLE</span>
            <span class="required">*</span>
        </label>
        <div>
            <input type="text" formControlName="title" id="title" pInputText class="form-control" />
        </div>
    </div>
    <div class="form-group">
        <label for="title">
            <span translate>DATE</span>
            <span class="required"></span>
        </label>
        <div>
            {{currentDate}}
        </div>
    </div>
    <div class="form-group">
            <label for="title">
                    <span translate>RESPONSIBLE</span>
                    <span class="required"></span>
                </label>
            <div  *ngIf="responsible" >
                    <p-dropdown 
                    [options]="responsible" formControlName="responsiblePerson" [style]="{'width':'130px'}"></p-dropdown>
            </div>
    </div>

   

    <div class="form-group">
            <label for="title">
                <span translate>Participants</span>
                <span class="required"></span>
            </label>
            <div>
                    <textarea pInputTextarea formControlName="participants" id="participants" class="form-control"></textarea>
            </div>
        </div>
        <div class="form-group">
                <button type="button" pButton (click)="saveCompleteGeneral()" [disabled]="!completeGeneralForm.valid" label=" Save & Next"></button>
        </div>
        
</form>