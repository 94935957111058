import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { VehicleTaskFormComponent } from './components/vechicletaskForm.component';
import { VehicleTaskListComponent } from './components/vehicletaskList.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { VehicleTaskComponent } from './vehicleTask.component';
import { CommonViewComponent } from './../common/components/commonView.component';
export const vehicleTaskRoutes: Route[] = [
    {
        path: 'vehicle-task',
        component: VehicleTaskComponent,
        children: [
            {
                path: 'list',
                component: VehicleTaskListComponent
            },
            {
                path: 'new',
                component: VehicleTaskFormComponent
            },
            {
                path: 'edit/:vid',
                component: VehicleTaskFormComponent
            },
            {
                path: 'details/:id',
                component: CommonViewComponent,
                data: { parent: 'VEHICLE' },
                canActivate: [AuthGuard],
                children: [...deviationRoutes, ...timetableRoutes]
            }
        ]
    }
];
