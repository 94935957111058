import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ConfirmationService } from 'primeng/api';
import { VehicleControlService } from './../services/vehicle-control.services';
import { ControlDto } from './../models/vehicle-control';
import { VehiclesCompleteChecklist } from '../../shared/models/complete-checklist';
import { VehicleControlList } from './../models/vehicle-control-list';
import { BaseServices } from '../../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicle-control-list.component.html',
})

export class VehicleControlListComponent implements OnInit {
    controlList: any;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    manualobjects: ControlDto[];
    parentId: number;
    parentKey: number;
    VehiclesCompleteChecklist: VehiclesCompleteChecklist;
    additionalData: any;
    private subscriptions: Subscription[] = [];
    /**
     *
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param vehicleControlService {VehicleControlService}
     */
    constructor(private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private router: Router,
        private vehicleControlService: VehicleControlService,
        private translate: TranslateService) {

        this.additionalData = {
            fileName: 'Vehicle Control List',
            header: 'Vehicle Control List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Checkpoint Name', dataKey: 'Title' },
                { title: 'Description', dataKey: 'Description' },
                { title: 'Created On', dataKey: 'CreatedOn' },
                { title: 'Created By', dataKey: 'CreatedName' },
                { title: 'Edited On', dataKey: 'ModifiedOn' },
                { title: 'Edited By', dataKey: 'ModifiedName' }
            ]
        };
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.controlList = <ControlDto[]>this.route.snapshot.data['list'];
        this.parentId = this.route.snapshot.parent.parent.params['id'];
        this.parentKey = this.route.snapshot.parent.parent.data['parent'];
    }
    /**
     * Delete vehicle control
     * @param controlId {number}
     */
    deleteControl(controlId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleControlService
                    .deleteVehicleControl(controlId)
                    .subscribe(result => {
                        // re-bind the control table
                        this.toasterComponent.callToastDlt();
                        if (result) {
                            this.subscriptions.push(this.vehicleControlService
                                .getVehicleControlByVehicleId(this.parentId)
                                .subscribe(res => {
                                    this.controlList = res;
                                }))
                        }

                    }));
            }
        });
    }

    completeChecklist() {
        this.VehiclesCompleteChecklist = new VehiclesCompleteChecklist();
        this.VehiclesCompleteChecklist.FeatureId = 28;
        this.VehiclesCompleteChecklist.FeatureKey = this.parentId;
        this.VehiclesCompleteChecklist.BusinessId = BaseServices.BusinessId;
        this.VehiclesCompleteChecklist.Id = this.route.snapshot.params['Id'];
        this.VehiclesCompleteChecklist.CreatedBy = BaseServices.UserId;
        this.vehicleControlService
            .createCompleteChecklist(this.VehiclesCompleteChecklist)
            .subscribe(result => {

                if (result) {
                    this.router.navigate(['../../../../../vehicle-complete-checklist/incomplete/' + result,
                    { fkey: this.parentId }], { relativeTo: this.route });
                }

            });
    } 

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
