import 'rxjs/Rx';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';

import { NotificationEdit, NotificationList } from '../../models/';
import { NotificationServices } from '../../services/notifications.service';
import { LoggedUser } from './../../../../_models/loggedUser';
import { BaseServices } from './../../../kuba.services';
import {TranslateService} from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'notifications',
    templateUrl: 'notificationList.component.html'
})
export class NotificationListComponent implements OnInit {
    //#region variable
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    currentUser: LoggedUser;
    text: string;
    nNotification: any = {};
    showNewNotification = false;
    public data;
    hidden = false;
    value: any;
    edited = false;
    notificationData: any;
    disabled = true;
    loggedUser: LoggedUser;
    notificationForm: FormGroup;
    notificationId: number;
    date: Date;
    sign: any;
    Desc;
    name;
    notificationlist: NotificationList[];
    isportal = false;
    isBusiness = false;
    Employee: any;
    Businesses: any;
    isPublished: boolean;
    selectedFeature: any;
    private subscriptions: Subscription[] = [];
    editPublished: Date;

    //#endregion

    //#region constructor

    /**
     * constructor
     * @param router {Router}
     * @param activatedRoute {ActivatedRoute}
     * @param formBuilder {FormBuilder}
     * @param notificationservice {NotificationServices}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private notificationservice: NotificationServices,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.notificationForm = this.formBuilder.group({
            Title: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ],
            description: ['']
        });
    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        let sFeatures = localStorage.getItem('features');
        this.currentUser = JSON.parse(localStorage.getItem('loggedUser'));
        this.notificationlist = <NotificationList[]>this.activatedRoute.snapshot
            .data['list'];
    }

    //#endregion

    //#region control-event

    /**
     * reset popup
     */
    clear() {
        this.Desc = '';
        this.notificationData = {
            Title: '',
            description: ''
        };
        (<FormGroup>this.notificationForm).setValue(this.notificationData, {
            onlySelf: true
        });
        this.isPublished = false;
    }
    /**
     * get notification
     * @param data {any}
     */
    notificationEdit(data: any) {
        this.showNewNotification = true;
        if (data) {
            this.hidden = true;
            let selectedRow = this.notificationlist.find(x => x.Id === data);
            this.notificationId = selectedRow.Id;
            this.selectedFeature = selectedRow;
            this.editPublished = selectedRow.PublishedOn;
            if (selectedRow.Status === 2) {
                this.isPublished = true;
            } else {
                this.isPublished = false;
            }
            if (selectedRow) {
                this.Desc = selectedRow.Description;
                this.notificationData = {
                    Title: selectedRow.Title,
                    description: selectedRow.Description
                };
                (<FormGroup>this.notificationForm).setValue(
                    this.notificationData,
                    { onlySelf: true }
                );
            }
        }
    }
    notification(data: any) {
        if (data == null) {
            this.clear();
            this.notificationId = 0;
            this.selectedFeature = null;
        }
        this.showNewNotification = true;
    }
    /**
     * delete notification
     * @param notificationId {number}
     */
    deleteNotification(notificationId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.notificationservice
                    .deleteNotification(notificationId)
                    .then((isDeleted: any) => {
                        if (isDeleted) {
                            this.refreshNotificationTable();
                            this.toasterComponent.callToastDlt();
                        }
                    });
            }
        });
    }
    /**
     * get user
     */
    getUser() {
        let editorText = this.notificationForm.value.description;
        this.name = BaseServices.Name;
        this.date = new Date();
        let date =
            `${new Date().getDate()}` +
            '/' +
            `${new Date().getMonth() + 1}` +
            '/' +
            `${new Date().getFullYear()}`;
        let time = HelperService.formatTime(new Date());
        this.sign = `${this.name}  ${date} ${time}`;
        this.sign = `${editorText}  ${this.name}  ${date} ${time}`;
    }
    /**
     * save notification
     * @param statusId {number}
     */
    saveNotification(statusId: number) {
        if (this.notificationForm.valid) {
            let notification = new NotificationEdit();
            notification.Title = this.notificationForm.value.Title;
            notification.Description = this.notificationForm.value.description;
            if (this.isPublished) {
                notification.status = 2;
            } else {
                notification.status = statusId;
            }
            if (!this.isPublished) {
                if (statusId === 2) {
                    notification.PublishedOn = new Date();
                } else {
                    notification.PublishedOn = null;
                }
            }
            if(this.editPublished != null){
                notification.PublishedOn = this.editPublished;
            }
            notification.Applicationid = BaseServices.ApplicationId;
            if (this.notificationId) {
                notification.Id = this.notificationId;
                notification.ModifiedBy = BaseServices.UserId;
                notification.CreatedOn = (this.selectedFeature) ? this.selectedFeature.CreatedOn : '';
                if (this.selectedFeature === 2) {
                    notification.PublishedOn = (this.selectedFeature) ? this.selectedFeature.PublishedOn : '';
                }
                this.subscriptions.push(this.notificationservice
                    .update(notification)
                    .subscribe((isNotificationUpdated: any) => {
                        if (isNotificationUpdated) {
                            this.toasterComponent.callToast();
                            this.showNewNotification = false;
                            this.refreshNotificationTable();
                        }
                    }));
            } else {
                this.subscriptions.push(this.notificationservice
                    .add(notification)
                    .subscribe((isNotificationAdded: any) => {
                        if (isNotificationAdded) {
                            this.toasterComponent.callToast();
                            this.showNewNotification = false;
                            this.refreshNotificationTable();
                        }
                    }));
            }
        }

        this.showNewNotification = false;
    }
    /**
     * table refresh
     */
    refreshNotificationTable() {
        this.subscriptions.push(this.notificationservice.getAll().subscribe((notificationList: any) => {
           this.notificationlist = notificationList;
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
