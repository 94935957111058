<a href="javascript:void(0)" (click)="showModal()" id="pdf-file-{{id}}">
    <img src="/assets/file-icons/kb-file-pdf.png" alt="Pdf File">
</a>

<div class="pdf-modal" *ngIf="showModalWindow" id="pdf-file-{{id}}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4>
                    <span class="ic-sm">
                        <img src="/assets/file-icons/kb-file-pdf.png" alt="Pdf File">
                    </span>
                    <span>
                        {{pdfName}}
                    </span>
                </h4>
                <button (click)="hideModal()" class="close">
                    <span class="icon ic-md icon-multiply"></span>
                </button>
            </div>
            <div class="modal-body" id="pdf-pop-{{id}}">
                <div class="file-preview-wrapper">
                    <div class="file-preview-box">
                        <pdf-viewer [src]="pdfSrc" [render-text]="true" style="display: block;position: absolute;left: 0;right: 0;top: 0;bottom: 0;"></pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>