import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Observable } from 'rxjs';
 import { EmployeeContactConvener, EmployeeContactUser } from './../../../shared/employee-contact';
import { User } from '../../contractor/models/contractor';
import { UserService } from './user.service';

@Injectable()
export class UserListResolver implements Resolve<User[]> {
    constructor(private backend: UserService) { }
    resolve(): Observable<User[]> {
        return this.backend.getUsersByBusiness(BaseServices.BusinessId);
    }
}
@Injectable()
export class DeviationUserListResolver implements Resolve<User[]> {
    constructor(private backend: UserService) { }
    resolve(): Observable<User[]> {
        return this.backend.getDeviationCreatedByUsers();
    }
}

@Injectable()
export class AssignUserResolver implements Resolve<User[]> {
    constructor(private backend: UserService) { }
    resolve(): Observable<User[]> {
        return this.backend.getAssignedUsers(
            BaseServices.ApplicationId,
            BaseServices.BusinessId
        );
    }
}

@Injectable()
export class UserEditResolver implements Resolve<User[]> {
    constructor(private backend: UserService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<User[]> {
        return this.backend.getUsersById(+route.params['uid']);
    }
}

@Injectable()
export class UserRoleResolver implements Resolve<any> {
    constructor(private backend: UserService) { }
    resolve(): Observable<any> {
        return this.backend.getRoles();
    }
}

/**
 * common resolver for employees/contacts with businessid
 */
@Injectable()
export class EmployeeContactUserResolver 
    implements Resolve<EmployeeContactUser[]> {
    constructor(private backend: UserService) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.BusinessId != 0) {
            let res:any = this.backend.getEmployeeContactUsers(BaseServices.BusinessId);
            return res;
        }
    }
}
/**
 * common resolver for employees/contacts with businessid
 */
@Injectable()
export class EmployeeContactConvenerResolver 
    implements Resolve<EmployeeContactConvener[]> {
    constructor(private backend: UserService) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.BusinessId != 0) {
            let res:any = this.backend.getEmployeeContactConveners(BaseServices.BusinessId);
            return res;
        }
    }
}

/**
 * common resolver for employees/contacts subscription with businessid
 */
@Injectable()
export class EmployeeContactUserSubscriptionResolver
    implements Resolve<any[]> {
    constructor(private backend: UserService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getEmployeeContactSubscriptionUsers(BaseServices.BusinessId);
    }
}
