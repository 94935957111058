<div class="tab-content">
    <div class="page-title">
        <span translate>CONSUMPTION</span>
    </div>
    <form [formGroup]="consumptionForm">
        <div class="form-group" *ngIf="consumptionForm.controls['ConsumptionTypes']">
            <label class="col-form-label">
                <span translate>CONSUMPTION_TYPE</span>
                <span class="required">*</span>
            </label>
            <div class="input-btn-group">
                <p-dropdown formControlName="ConsumptionTypes" [options]="consumptionTypes">
                </p-dropdown>
                <button type="button" class="btn btn-outline-primary" (click)="addType=!addType">
                    <i class="pi pi-plus"></i>
                </button>
            </div>
            <control-messages [control]="consumptionForm.controls['ConsumptionTypes']"></control-messages>
        </div>
        <div class="form-group" *ngIf="consumptionForm.controls['date']">
            <label for="name" class="col-form-label">
                <span translate>CHOOSE_DATE</span>
                <span class="required">*</span>
            </label>
            <div>
                <my-date-picker name="deadline" [options]="defaultDateOptions" formControlName="date"
                    placeholder="{{'CHOOSE_DATE'|translate}}" required></my-date-picker>
            </div>
            <control-messages [control]="consumptionForm.controls['date']"></control-messages>
        </div>
        <div class="form-group">
            <label class="col-form-label" translate>PREVIOUS_NUMBER</label>
            <div>
                <input pInputText type="number" formControlName="PreviousNumber" onpaste="return false;"
                    (keypress)="onNumber($event,20)" #cf (keyup)="onPreviousNumberChanged(cf)" /><span
                    translate>LAST_CONSUMPTION</span>
            </div>
        </div>
        <div class="form-group" *ngIf="consumptionForm.controls['TodaysNumber']">
            <label for="starttime" class="col-form-label">
                <span translate>TODAY_NUMBER</span>
                <span class="required">*</span>
            </label>
            <div>
                <input pInputText type="number" formControlName="TodaysNumber" onpaste="return false;"
                    (keypress)="onNumber($event,20)" #cf (keyup)="onTodaysNumberChanged(cf)" /><span
                    translate>ACTUAL_NUMBER</span>
            </div>
            <control-messages [control]="consumptionForm.controls['TodaysNumber']"></control-messages>
        </div>
        <div class="form-group">
            <label for="endtime" class="col-form-label" translate>ESTIMATED_NUMBER</label>
            <div>
                <input pInputText type="number" formControlName="EstimatedNumber" onpaste="return false;"
                    (keypress)="onNumber($event,20)" readonly="true" />
                <span translate>NUMBER_USING</span>
            </div>
        </div>
        <div class="form-group">
            <div class="form-group">
                <label for="hours" class="col-form-label" translate>UNIT_MEASUREMENT</label>
                <div class=" input-btn-group">
                    <p-dropdown formControlName="ConsumptionUnits" [options]="units" [style]="{'width':'100%'}">
                    </p-dropdown>
                    <button type="button" class="btn btn-outline-primary" (click)="addUnit=!addUnit">
                        <i class="pi pi-plus"></i>
                    </button>
                </div>
            </div>
            <div class="form-group" *ngIf="consumptionForm.controls['Price']">
                <label for="endtime" class="col-form-label">
                    <span translate>PRICE_PRIMARY_SUGGESTED</span>
                    <span class="required">* </span>
                </label>
                <div>
                    <input pInputText type="number" formControlName="Price" onpaste="return false;"
                        (keypress)="onNumber($event,20)" />
                </div>
                <control-messages [control]="consumptionForm.controls['Price']"></control-messages>
            </div>
            <div class="form-group">
                <label for="comment" class="col-form-label" translate>COMMENTS</label>
                <div>
                    <textarea pInputTextarea formControlName="Comments"></textarea>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <button class="btn btn-outline-secondary" (click)="gotoList()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="!consumptionForm.valid"
                (click)="onSubmitTemplateBased()">Save</button>
        </div>
    </form>
    <p-dialog header="{{'New Type' | translate}}" [(visible)]="addType" [style]="{width: '350px'}" [resizable]="false"
        [draggable]="false">
        <form [formGroup]="ConsumptionTypeForm">
            <div class="form-group">
                <label for="title" class="col-form-label">
                    <span translate>TYPE_NAME</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" #title class="label-input form-control" formControlName="Name">
                </div>
                <control-messages [control]="ConsumptionTypeForm.controls['Name']"></control-messages>
            </div>
        </form>
        <label for="consumptionTypeAlert" color class="col-form-label" *ngIf="isConsumptionTypeExsist"
            [ngStyle]="{'color': 'red'}">
            <span translate>CONSUMPTION_EXIST</span>
        </label>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix action-btns-wrapper">
                <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType">
                    <span translate>VIEW</span>
                </button>
                <button type="button" class="btn btn-primary" [disabled]="!ConsumptionTypeForm.valid"
                    (click)="saveType()">
                    <span translate>SAVE</span>
                </button>
            </div>
            <div *ngIf="viewType">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #category [value]="editConsumptions" [rows]="10" [paginator]="true" [pageLinks]="3"
                        [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'TYPE_NAME' | translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>
                                    <input pInputText type="text"
                                        (input)="category.filter($any($event.target)?.value, 'Name', 'contains')"
                                        placeholder="" class="p-column-filter">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-categoryType>
                            <tr>
                                <td>
                                    <span>{{categoryType.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                        (click)="editType(categoryType)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                        (click)="deleteType(categoryType)">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table Ends -->
                </div>
            </div>
        </p-footer>
    </p-dialog>
    <p-dialog header="{{'UNIT_NAME' | translate}}" [(visible)]="addUnit" [style]="{width: '350px'}" [resizable]="false"
        [draggable]="false">
        <form [formGroup]="ConsumptionUnitForm">
            <div class="form-group">
                <label for="title" class="col-form-label">
                    <span translate>UNIT_NAME</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" #title class="label-input form-control" formControlName="Name">
                </div>
                <control-messages [control]="ConsumptionUnitForm.controls['Name']"></control-messages>
            </div>
            <label for="consumptionUnitAlert" color class="col-form-label" *ngIf="isConsumptionUnitExsist"
                [ngStyle]="{'color': 'red'}">
                <span translate>CONSUMPTION_EXIST</span>
            </label>
        </form>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-primary" (click)="viewUnit = !viewUnit">
                    <span translate>VIEW</span>
                </button>
                <button type="button" class="btn btn-primary" [disabled]="!ConsumptionUnitForm.valid"
                    (click)="saveUnit()">
                    <span translate>SAVE</span>
                </button>
            </div>
            <div *ngIf="viewUnit">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #Unit [value]="editUnits" [rows]="10" [paginator]="true" [pageLinks]="3"
                        [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'TYPE_NAME' | translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>
                                    <input pInputText type="text"
                                        (input)="Unit.filter($any($event.target)?.value, 'Name', 'contains')"
                                        placeholder="" class="p-column-filter">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-Unit>
                            <tr>
                                <td>
                                    <span>{{Unit.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                        (click)="editUnit(Unit)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                        (click)="deleteUnit(Unit)">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table Ends -->
                </div>
            </div>
        </p-footer>
    </p-dialog>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>