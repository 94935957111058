<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <languages></languages>
        </div>
    </div>
</div>
<div class="login-wrapper">
    <div class="login-panel">
        <div class="login-panel-body">
            <div class="kb-progress-bar" *ngIf="loading">
                <div class="kb-progress"></div>
            </div>
            <div class="logo">
                <img src="assets/images/KubaLogo.png" alt="">
            </div>
            <a [routerLink]="['/login']" type="button" class="btn btn-icon">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LOGIN</span>
            </a>
            <div class="login-form mtop-20">
                <form [formGroup]="ForgotPasswordForm">
                    <div formGroupName="checkUser">
                        <p>
                            <span translate>FORGOT_PASSWORD</span>
                        </p>
                        <div class="form-group">
                            <label for="username" class="col-form-label" translate>USERNAME
                                <span class="required">*</span>
                            </label>
                            <input type="text" name="username" class="form-control" formControlName="username" required autofocus/>
                        </div>
                        <div *ngIf="isNotValid" class="alert alert-danger mbtm-10">
                            <span class="icon ic-xs icon-alert mright-10"></span><span translate>NOT_AUTHORIZED</span>
                        </div>
                        <div *ngIf="mailSent" class="alert alert-success mbtm-10">
                                <span class="icon ic-xs icon-checkmark mright-10"></span><span translate>MAIL_SUCCESS</span>
                        </div>
                    </div>
                </form>
                <button class="btn btn-primary btn-block" (click)="sendMail()" [disabled]="!ForgotPasswordForm.valid">
                    <span translate>SUBMIT</span>
                </button>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>