import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BusinessEdit } from './../../businesses/models/businessEdit';
import { BaseServices } from './../../kuba.services';
import { Trash } from './../models/trash';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TrashServices {
    constructor(private http: HttpClient) {}

    createAuthorizationHeader(headers: HttpHeaders) {
        headers.append('content-type', 'application/json');
    }
    // List all Trash
    list() {
        return this.http
            .get<any>(environment.BASE_URL + '/trash', BaseServices.headerOption)
            .map(result => result);
    }
    get(Id: number) {
        return this.http
            .get<any>(
                environment.BASE_URL + '/Trash/' + Id,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    add(data) {
        return this.http
            .post<any>(
                environment.BASE_URL + '/Trash',
                data,
                BaseServices.headerOption
            )
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }
    update(Id: number, data: Trash[]) {
        return this.http
            .put(
                environment.BASE_URL + '/Trash/' + Id,
                data,
                BaseServices.headerOption
            )
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }

    delete(Id: number) {
        return this.http
            .delete(
                environment.BASE_URL + '/trashDelete/' + Id,
                BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    restoreBusiness(id: number) {
        return this.http.put(environment.BASE_URL + '/restorebusiness/' + id,id, BaseServices.headerOption)
            .map(result => result);
    }
}
