import { BusinessSignedListResolver } from './services/declaration-of-conformity.resolver.service';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from './../../_guards/index';
import { DeclarationOfConformityComponent } from './declaration-of-conformity.component';
import { DeclarationConformityComponent } from './components/declaration-conformity.component';

export const declarationOfConformityRoutes: Route[] = [
    {
        path: 'declaration-of-conformity',
        component: DeclarationOfConformityComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'page',
                component: DeclarationConformityComponent,
                resolve: {
                    sign: BusinessSignedListResolver
                }
            }
        ]
    }
];
