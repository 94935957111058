import { ActivityLog } from './../models/activitylog';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: 'activitylog-list.component.html'
})

export class ActivityLogListComponent implements OnInit {
    constructor(private route: ActivatedRoute) { }
    activityLogs: any;
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.activityLogs = <ActivityLog[]>this.route.snapshot.data['list'];
    }
}