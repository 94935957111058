<div class="main-content">
    <div class="main-heading">
        <strong>New ActivityLog</strong>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="activitylogForm">
                <div class="form-group row">
                    <label class="col-sm-4" translate>USER_NAME</label>
                    <div class="col-sm-6">
                        <p-dropdown formControlName="username" [filter]="true" [options]="users"
                          [style]="{'width':'160px'}"></p-dropdown>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" translate>BUSINESS</label>
                    <div class="col-sm-6">
                        <p-dropdown formControlName="business" [options]="business"
                          [style]="{'width':'160px'}"></p-dropdown>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4">IP</label>
                    <div class="col-sm-6">
                        <input type="text" pInputText formControlName="ip" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" translate>BROWSER</label>
                    <div class="col-sm-6">
                        <input type="text" pInputText formControlName="browser" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4">OS</label>
                    <div class="col-sm-6">
                        <input type="text" pInputText formControlName="os" />
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <button class="btn btn-outline-secondary" type="button" (click)="gotoList()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button class="btn btn-primary" type="button" (click)="saveChanges()">
                        <span translate>SAVE</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<toaster-container [toasterconfig]="config1"></toaster-container>