import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { NgForm } from '@angular/forms';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/Rx';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { BusinessDepartmentService } from './../../../business-department.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { BaseServices } from './../../../kuba.services';
import {
  FoodSafety,
  FoodSafetyUser,
} from './../../../foodsafety/models/foodsafety';
import { Zone } from './../../../foodsafety/models/zone';
import { Department } from './../../../deviation/models/deviationDepartment';
import { FoodSafetyServices } from './../../../foodsafety/services/foodsafety.services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { KubaControlService } from '../../services/kuba-control.service';
import { ObjectType } from './../../../foodsafety/models/TypeObject';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'app-create-kuba-control',
  templateUrl: './create-kuba-control.component.html',
})
export class CreateKubaControlComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  options = [
    {
      Id: '1',
      Status: 'ACTIVE',
    },
    {
      Id: '2',
      Status: 'INACTIVE',
    },
  ];
  selectedOption: any;
  selectedValue: any = '1';
  ControlId: number;
  TypeOfObjectId: number;
  foodsafety: any;
  foodSafetyForm: FormGroup;
  departmentPopup: FormGroup;
  zonePopup: FormGroup;
  objectTypePopup: FormGroup;
  Zone: SelectItem[];
  zoneList: Zone[];
  ObjectType: SelectItem[];
  objectTypeList: ObjectType[];
  Department: SelectItem[];
  selectedDepartment = new Department();
  selectedZone = new Zone();
  departmentList: Department[];
  zoneData: any;
  objectTypeData: any;
  deptData: any;
  departmentId: number;
  zoneId: number;
  objectTypeId: number;
  isDepartmentNameExists = false;
  isDepartmentNameAllocated = false;
  addZone = false;
  addObject = false;
  listZone = false;
  listObjectType = false;
  addDepartment = false;
  listDepartment = false;
  isZoneNameAllocated = false;
  isZoneNameExists = false;
  IsObjectNameExists = false;
  copyTaskId: number;
  isHideFile = true;
  createdBy: number;
  createdOn: any;
  userId: number;
  selectedUsers: any = [];
  userSubscribers: SelectItem[];
  selectedSubscribers: SelectItem[];
  selectedEmailSubscriber = [];
  selectAllEmailSubscriber = [];
  emailUsers: any[];
  bindAllUsers = false;
  bindSelectedUsers = true;
  foodUsers: any[];
  emailSubscriber = [];
  emailAllSubscriber = [];
  selectedEmailUsers: any = [];
  fsZoneNames: SelectItem[];
  typeOfObject: SelectItem[];
  filesTemplateId: number;
  hazardTemplateId: number;
  sJATemplateId: number;
  filesTemplateName: string;
  hazardTemplateName: string;
  sJATemplateName: string;
  checkedAll: boolean;
  isObjectTypeAllocated = false;
  isObjectTypeExists = false;
  disableCopiedTask = false;
  disableCopiedFiles = false;
  disableCopiedForm = false;
  isKubaControl = false;
  disableZoneDropdown = false;
  isSJAForm: boolean = true;
  isTaskManager: boolean = true;
  isDocuments: boolean = true;
  isKubaControlData: any;
  private subscriptions: Subscription[] = [];
  constructor(
    private kubaControlService: KubaControlService,
    private fb: FormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private router: Router,
    private businessDepartmentService: BusinessDepartmentService,
    private translate: TranslateService
  ) {
    this.ControlId = +this.route.snapshot.params['id'];
    this.foodSafetyForm = this.fb.group({
      Status: [],
      ObjectType: [],
      // ZoneName: ['', Validators.required],
      Title: ['', Validators.required],
      Description: [''],
      ChooseZone: [],
      CopyFiles: [false],
      CopyTask: [false],
      CopySJAForm: [false],
      CopyHazard: [false],
      CopySJA: [false],
      IsShowDeviationChecked: [false], // Default to false instead of true
      selectedEmailSubscriber: [''],
      selectAllEmailSubscriber: [''],
    });
    this.departmentPopup = this.fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.zonePopup = this.fb.group({
      Name: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.objectTypePopup = this.fb.group({
      ObjectType: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.userId = +BaseServices.UserId;
  }

  ngOnInit() {
    this.bindDropDowns();
    this.selectedSubscribers = [];
    this.userSubscribers = [];
    let userSubscription = this.route.snapshot.data['users_subscription'];
    this.selectedOption = 1;
    this.emailUsers = userSubscription;
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.userSubscribers = [];
        if (userSubscription) {
          userSubscription.forEach((element: any) => {
            this.selectedSubscribers.push({
              label: element.Name,
              value: { Id: element.Id },
            });
            if (element.Id == BaseServices.UserId) {
              this.userSubscribers.push({
                label: element.Name,
                value: { Id: element.Id },
              });
            }
          });
        }
      })
    );
    /**
     * get user list by business id
     */
    let users = this.route.snapshot.data['contact'];
    if (users) {
      this.foodUsers = users;
    }
    if (this.ControlId > 0) {
      let result = this.route.snapshot.data['edit'];
      this.createdBy = result.CreatedBy;
      this.createdOn = result.CreatedOn;
      this.filesTemplateId = result.FilesTemplateId;
      this.hazardTemplateId = result.HazardTemplateId;
      this.copyTaskId = result.copyTaskId;
      this.sJATemplateId = result.SJATemplateId;
      this.filesTemplateName = result.FilesTemplateName;
      this.hazardTemplateName = result.HazardTemplateName;
      this.sJATemplateName = result.SJATemplateName;
      if (result) {
        this.foodsafety = {
          ObjectType: result.TypeOfObjectId,
          // ZoneName: result.ControlZoneId,
          Title: result.Title,
          Status: result.Status,
          Description: result.Description,
          ChooseZone: result.CopyTaskId ? result.CopyTaskId : null,
          CopyFiles: result.IsCopyFiles ? result.IsCopyFiles : false,
          CopyTask: result.IsCopyTask ? result.IsCopyTask : false,
          CopyHazard: result.IsCopyHazard ? result.IsCopyHazard : false,
          CopySJA: result.IsCopySJA ? result.IsCopySJA : false,
          CopySJAForm: result.IsCopySJAForm ? result.IsCopySJAForm : false,
          IsShowDeviationChecked: !result.ShowDeviation, // Reverse the logic here
          selectedEmailSubscriber: result.ControlUserDto,
          selectAllEmailSubscriber: result.ControlUserDto,
        };
        (<FormGroup>this.foodSafetyForm).setValue(this.foodsafety, {
          onlySelf: true,
        });
        if (result.ControlUserDto) {
          this.selectedEmailSubscriber = [];
          result.ControlUserDto.forEach((element: any) => {
            let selectedUsers = this.foodUsers.find(
              (x) => x.Id === element.UserId
            );
            if (selectedUsers) {
              selectedUsers.status = true;
              selectedUsers.isEmail = false;
              this.checked(selectedUsers);
              if (element.IsEmail) {
                this.selectedEmailSubscriber.push({ Id: element.UserId });
              }
            }
          });
        }
        if (result.ControlUserDto) {
          this.foodSafetyForm.patchValue({
            selectedEmailSubscriber: this.selectedEmailSubscriber,
          });
        }
        this.selectedOption = this.foodSafetyForm.controls['Status'].value;
      }
      this.hideCopyFile(result.CopyTaskId);

      this.disableCopyTask(result.CopyTaskId);

      this.disableCopyfiles(result.CopyTaskId);

      this.disableCopyForm(result.CopyTaskId);

      this.disableSelectExistingObject(result.CopyTaskId);
    }

    let leftNav = JSON.parse(sessionStorage.getItem('leftNav'));
    let filteredTemplate = HelperService.filterTree(
      'KubaControl',
      leftNav.filter((x) => {
        if (x.name === 'KubaControl' && x.checked == true) {
          this.isKubaControl = true;
          this.isKubaControlData = x.children;
          this.isKubaControlData.forEach((y) => {
            if (y.name == 'SJAForm' && y.checked == true) {
              this.isSJAForm = false;
            }
            if (y.name == 'TaskManager' && y.checked == true) {
              this.isTaskManager = false;
            }
            if (y.name == 'Documents' && y.checked == true) {
              this.isDocuments = false;
            }
          });
        }
      }),
      'isKubaControl'
    );
  }

  disableCopyTask(IsCopyTask) {
    if (IsCopyTask > 0) {
      this.disableCopiedTask = true;
    } else {
      this.disableCopiedTask = false;
    }
  }

  disableCopyfiles(IsCopyTask) {
    if (IsCopyTask > 0) {
      this.disableCopiedFiles = true;
    } else {
      this.disableCopiedFiles = false;
    }
  }

  disableCopyForm(IsCopyForm) {
    if (IsCopyForm > 0) {
      this.disableCopiedForm = true;
    } else {
      this.disableCopiedForm = false;
    }
  }

  disableSelectExistingObject(selectZone) {
    if (selectZone > 0) {
      this.disableZoneDropdown = true;
    } else {
      this.disableZoneDropdown = false;
    }
  }

  checked(value) {
    if (value.status) {
      this.selectedUsers.push(value);
      if (this.emailUsers) {
        let selectedUsers = this.emailUsers.find((x) => x.Id === value.Id);
        this.emailUsers.forEach((element: any) => {
          if (
            element.Id === value.Id &&
            value.Id !== BaseServices.UserId &&
            !value.isEmail
          ) {
            this.userSubscribers.push({
              label: element.Name,
              value: { Id: element.Id },
            });
          }
        });
      }
    } else {
      _.remove(this.selectedUsers, (val) => {
        if (this.userSubscribers) {
          _.forEach(this.userSubscribers, (item: any) => {
            if (item.value.Id == value.Id) {
              this.userSubscribers.pop();
            }
          });
        }
        return val === value;
      });
    }
  }

  checkAll(event) {
    _.forEach(this.foodUsers, (item: any) => {
      if (event) {
        item.status = true;
      } else {
        item.status =
          // to remain checked state for current user
          this.userId === +item.Id ? true : false;
      }
    });

    this.selectedUsers = this.foodUsers;
    if (!event) {
      let userId = BaseServices.UserId;
      let name = BaseServices.Name;
      let checkUserId = this.selectedUsers.filter((x) => x.Id == userId);
      if (checkUserId.length > 0) {
        this.userSubscribers = [];
        this.userSubscribers.push({ label: name, value: { Id: userId } });
      }
      this.selectedUsers = [];
      this.bindAllUsers = false;
      this.bindSelectedUsers = true;
    } else {
      this.bindAllUsers = true;
      this.bindSelectedUsers = false;
    }
  }

  bindDropDowns() {
    this.Zone = [];
    this.zoneData = this.route.snapshot.data['zonedetails'];
    if (this.zoneData) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Zone = [];
          this.Zone.push({ label: val.SELECT, value: null });
          this.zoneData.forEach((element) => {
            this.Zone.push({ label: element.Name, value: element.Id });
          });
        })
      );
    }

    this.ObjectType = [];
    this.objectTypeData = this.route.snapshot.data['objects'];
    if (this.objectTypeData) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.typeOfObject = [];
          this.typeOfObject.push({ label: val.SELECT, value: null });
          this.objectTypeData.forEach((element) => {
            this.typeOfObject.push({
              label: element.ObjectType,
              value: element.Id,
            });
          });
        })
      );
    }

    this.fsZoneNames = [];
    let zones = this.route.snapshot.data['zones'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.fsZoneNames = [];
        this.fsZoneNames.push({ label: val.SELECT, value: null });
        if (zones) {
          zones.forEach((element) => {
            if (this.ControlId != element.Id)
              this.fsZoneNames.push({
                label: element.ZoneName,
                value: element.Id,
              });
          });
        }
      })
    );
    this.Department = [];
    this.deptData = this.route.snapshot.data['deptdetails'];
    if (this.deptData) {
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.Department = [];
          this.Department.push({ label: val.SELECT, value: null });
          this.deptData.forEach((element) => {
            this.Department.push({ label: element.Name, value: element.Id });
          });
        })
      );
    }
  }
  viewDepartment() {
    this.subscriptions.push(
      this.businessDepartmentService
        .getCachedBusinessDepartment()
        .subscribe((department) => {
          this.departmentList = department;
          if (department) {
            this.Department = [];
            this.departmentList.forEach((departmentList) => {
              this.Department.push({
                label: departmentList.Name,
                value: departmentList.Id,
              });
            });
          }
        })
    );
  }

  editDepartment(deptdetails: Department) {
    this.departmentId = deptdetails.Id;
    let result = {
      Name: deptdetails.Name,
    };
    (<FormGroup>this.departmentPopup).setValue(result, { onlySelf: true });
  }

  saveDepartment() {
    let deptdetails = new Department();
    deptdetails.Id = this.departmentId ? this.departmentId : 0;
    deptdetails.Name = this.departmentPopup.value.Name;
    deptdetails.BusinessId = BaseServices.BusinessId;
    deptdetails.ApplicationId = BaseServices.ApplicationId;
    deptdetails.Status = '1';
    if (deptdetails.Id > 0) {
      deptdetails.ModifiedBy = BaseServices.UserId;
      this.clearCachedDepartment();
      this.subscriptions.push(
        this.kubaControlService
          .updateDepartment(deptdetails)
          .subscribe((departmentEdited: any) => {
            this.departmentId = null;
            if (departmentEdited) {
              this.viewDepartment();
              this.toasterComponent.callToast();
              this.foodSafetyForm
                .get('Department')
                .patchValue(departmentEdited.Id);
            } else {
              this.isDepartmentNameExists = true;
              setTimeout(
                function () {
                  this.isDepartmentNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    } else {
      deptdetails.CreatedBy = BaseServices.UserId;
      this.clearCachedDepartment();
      deptdetails.Id = 0;
      this.subscriptions.push(
        this.kubaControlService
          .addDepartment(deptdetails)
          .subscribe((departmentAdded: any) => {
            if (departmentAdded) {
              this.viewDepartment();
              this.toasterComponent.callToast();
              this.foodSafetyForm
                .get('Department')
                .patchValue(departmentAdded.Id);
            } else {
              this.isDepartmentNameExists = true;
              setTimeout(
                function () {
                  this.isDepartmentNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    }
    this.clearDepartment();
  }
  onDepartmentPopupHide() {
    this.departmentPopup.reset();
  }
  onZonePopupHide() {
    this.zonePopup.reset();
  }
  onObjectTypePopupHide() {
    this.objectTypePopup.reset();
  }
  /**
   * clear department popup
   */
  clearDepartment() {
    this.departmentPopup = this.fb.group({
      Name: [''],
    });
  }

  deleteDepartment(selectedDepartment) {
    this.subscriptions.push(
      this.kubaControlService
        .checkAllocatedDepartment(selectedDepartment)
        .subscribe((result: any) => {
          if (result) {
            this.isDepartmentNameAllocated = true;
            setTimeout(
              function () {
                this.isDepartmentNameAllocated = false;
              }.bind(this),
              3000
            );
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.kubaControlService
                    .deleteDepartment(selectedDepartment)
                    .subscribe((result) => {
                      this.viewDepartment();
                      this.bindDropDowns();
                      this.clearCachedDepartment();
                      this.toasterComponent.callToastDlt();
                    })
                );
              },
            });
          }
        })
    );
  }

  clearCachedDepartment() {
    this.businessDepartmentService.clearCachedAllDepartment();
  }
  saveObjectType() {
    let objectType = new ObjectType();
    objectType.Id = this.objectTypeId ? this.objectTypeId : 0;
    objectType.ObjectType = this.objectTypePopup.value.ObjectType;
    objectType.ApplicationId = BaseServices.ApplicationId;
    objectType.BusinessId = BaseServices.BusinessId;
    objectType.Status = '1';
    if (objectType.Id > 0) {
      this.subscriptions.push(
        this.kubaControlService
          .updateObject(objectType)
          .subscribe((ObjectEdited: any) => {
            if (ObjectEdited) {
              this.objectTypeId = null;
              this.viewObject();
              this.toasterComponent.callToast();
              // this.foodSafetyForm.get('ObjectType').patchValue(ObjectEdited.Id)
            } else {
              this.IsObjectNameExists = true;
              setTimeout(
                function () {
                  this.IsObjectNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    } else {
      objectType.Id = 0;
      this.subscriptions.push(
        this.kubaControlService
          .addObject(objectType)
          .subscribe((objectAdded: any) => {
            if (objectAdded) {
              this.viewObject();
              this.toasterComponent.callToast();
              // this.foodSafetyForm.get('ObjectType').patchValue(objectAdded.Id)
            } else {
              this.IsObjectNameExists = true;
              setTimeout(
                function () {
                  this.IsObjectNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    }
    this.clearObject();
  }

  clearObject() {
    this.objectTypePopup = this.fb.group({
      ObjectType: [''],
    });
  }
  deleteObject(selectedObject) {
    this.subscriptions.push(
      this.kubaControlService
        .checkAllocatedObjectType(selectedObject)
        .subscribe((result: any) => {
          if (result) {
            this.isObjectTypeAllocated = true;
            setTimeout(
              function () {
                this.isObjectTypeAllocated = false;
              }.bind(this),
              3000
            );
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.kubaControlService
                    .deleteObject(selectedObject)
                    .subscribe((result) => {
                      this.viewZone();
                      this.bindDropDowns();
                      this.toasterComponent.callToastDlt();
                    })
                );
              },
            });
          }
        })
    );
  }

  viewZone() {
    this.subscriptions.push(
      this.kubaControlService
        .getZone(BaseServices.BusinessId)
        .subscribe((zone: any) => {
          this.zoneList = zone;
          this.Zone = [];
          if (zone) {
            zone.forEach((zoneList: any) => {
              this.Zone.push({ label: zoneList.Name, value: zoneList.Id });
            });
          }
        })
    );
  }

  viewObject() {
    this.subscriptions.push(
      this.kubaControlService
        .getObject(BaseServices.BusinessId)
        .subscribe((objectType: any) => {
          this.objectTypeList = objectType;
          this.typeOfObject = [];
          if (objectType) {
            objectType.forEach((objectTypeList: any) => {
              this.typeOfObject.push({
                label: objectTypeList.ObjectType,
                value: objectTypeList.Id,
              });
            });
          }
        })
    );
  }

  editZone(zone: Zone) {
    this.zoneId = zone.Id;
    let result = {
      Name: zone.Name,
    };
    (<FormGroup>this.zonePopup).setValue(result, { onlySelf: true });
  }

  editObject(objectType: any) {
    this.objectTypeId = objectType.value;
    let result = {
      ObjectType: objectType.label,
    };
    (<FormGroup>this.objectTypePopup).setValue(result, { onlySelf: true });
  }

  saveZone() {
    let zone = new Zone();
    zone.Id = this.zoneId ? this.zoneId : 0;
    zone.Name = this.zonePopup.value.Name;
    zone.ApplicationId = BaseServices.ApplicationId;
    zone.BusinessId = BaseServices.BusinessId;
    zone.Status = '1';
    if (zone.Id > 0) {
      this.subscriptions.push(
        this.kubaControlService
          .updateZone(zone)
          .subscribe((zoneEdited: any) => {
            if (zoneEdited) {
              this.zoneId = null;
              this.viewZone();
              this.toasterComponent.callToast();
              this.foodSafetyForm.get('ZoneName').patchValue(zoneEdited.Id);
            } else {
              this.isZoneNameExists = true;
              setTimeout(
                function () {
                  this.isZoneNameExists = false;
                }.bind(this),
                3000
              );
            }
          })
      );
    } else {
      zone.Id = 0;
      this.subscriptions.push(
        this.kubaControlService.addZone(zone).subscribe((zoneAdded: any) => {
          if (zoneAdded) {
            this.viewZone();
            this.toasterComponent.callToast();
            this.foodSafetyForm.get('ZoneName').patchValue(zoneAdded.Id);
          } else {
            this.isZoneNameExists = true;
            setTimeout(
              function () {
                this.isZoneNameExists = false;
              }.bind(this),
              3000
            );
          }
        })
      );
    }
    this.clearZone();
  }
  clearZone() {
    this.zonePopup = this.fb.group({
      Name: [''],
    });
  }
  deleteZone(selectedZone) {
    this.subscriptions.push(
      this.kubaControlService
        .checkAllocatedZone(selectedZone)
        .subscribe((result: any) => {
          if (result) {
            this.isZoneNameAllocated = true;
            setTimeout(
              function () {
                this.isZoneNameAllocated = false;
              }.bind(this),
              3000
            );
          } else {
            this.confirmationService.confirm({
              message: this.translate.instant('DELETE_THIS_RECORD'),
              accept: () => {
                this.subscriptions.push(
                  this.kubaControlService
                    .deleteZone(selectedZone)
                    .subscribe((result) => {
                      this.viewZone();
                      this.bindDropDowns();
                      this.toasterComponent.callToastDlt();
                    })
                );
              },
            });
          }
        })
    );
  }
  saveFoodSafety() {
    if (this.foodSafetyForm.valid) {
      let control = new FoodSafety();
      let userId = BaseServices.UserId;
      let name = BaseServices.userName;
      let checkUserId = this.selectedUsers.filter((x) => x.Id == userId);
      if (checkUserId.length === 0) {
        this.selectedUsers.push({ Id: userId, Name: name });
      }
      control.Id = this.ControlId ? this.ControlId : 0;
      control.TypeOfObjectId = this.foodSafetyForm.value.ObjectType;
      // control.ControlZoneId = this.foodSafetyForm.value.ZoneName;
      control.Title = this.foodSafetyForm.value.Title;
      control.BusinessId = BaseServices.BusinessId;
      control.Description = this.foodSafetyForm.value.Description;
      control.Status = '1';
      control.Status = this.selectedValue;
      control.ShowDeviation = !this.foodSafetyForm.value.IsShowDeviationChecked; // Reverse the value before saving
      control.CopyZone = this.foodSafetyForm.value.ChooseZone;
      control.IsCopyFiles = this.foodSafetyForm.value.CopyFiles;
      control.IsCopyTask = this.foodSafetyForm.value.CopyTask;
      control.IsCopySJAForm = this.foodSafetyForm.value.CopySJAForm;
      control.IsCopyHazard = this.foodSafetyForm.value.CopyHazard;
      control.IsCopySJA = this.foodSafetyForm.value.CopySJA;
      control.FilesTemplateId = this.filesTemplateId;
      control.HazardTemplateId = this.hazardTemplateId;
      control.copyTaskId = this.copyTaskId;
      control.SJATemplateId = this.sJATemplateId;
      if (
        this.bindSelectedUsers &&
        this.foodSafetyForm.value.selectedEmailSubscriber
      ) {
        control.FoodEmailSubscribers = [];
        this.emailSubscriber =
          this.foodSafetyForm.value.selectedEmailSubscriber;
      }
      if (
        this.bindAllUsers &&
        this.foodSafetyForm.value.selectAllEmailSubscriber
      ) {
        control.FoodEmailSubscribers = [];
        this.emailSubscriber =
          this.foodSafetyForm.value.selectAllEmailSubscriber;
      }
      let FoodUsers: FoodSafetyUser = new FoodSafetyUser();
      let Ids: any = ([] = []);

      this.selectedUsers.forEach((element: any) => {
        Ids.push({
          UserId: element.Id,
          ControlId: control.Id,
          Name: element.Name,
          IsEmail: this.emailSubscriber.find((x) => x.Id == element.Id)
            ? true
            : false,
        });
      });
      control.ControlUserDto = Ids;
      if (control.Id > 0) {
        control.CreatedOn = this.createdOn;
        control.CreatedBy = this.createdBy;
        control.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.kubaControlService
            .updateFoodsafety(control)
            .subscribe((result: any) => {
              this.toasterComponent.callToast();
              this.filesTemplateId = result.FilesTemplateId;
              this.hazardTemplateId = result.HazardTemplateId;
              this.copyTaskId = result.copyTaskId;
              this.sJATemplateId = result.SJATemplateId;
              this.gotoBack();
            })
        );
      } else {
        control.CreatedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.kubaControlService
            .createFoodsafety(control)
            .subscribe((result: any) => {
              this.toasterComponent.callToast();
              this.filesTemplateId = result.FilesTemplateId;
              this.hazardTemplateId = result.HazardTemplateId;
              this.copyTaskId = result.copyTaskId;
              this.sJATemplateId = result.SJATemplateId;
              this.createdBy = result.CreatedBy;
              this.createdOn = result.CreatedOn;
              this.gotoBack();
            })
        );
      }
    }
  }

  saveAndCreateNew() {
    if (this.foodSafetyForm.valid) {
      let control = new FoodSafety();
      control.Id = this.ControlId ? this.ControlId : 0;
      control.TypeOfObjectId = this.foodSafetyForm.value.ObjectType;
      // control.ControlZoneId = this.foodSafetyForm.value.ZoneName;
      control.Title = this.foodSafetyForm.value.Title;
      control.BusinessId = BaseServices.BusinessId;
      control.Description = this.foodSafetyForm.value.Description;
      control.Status = this.foodSafetyForm.value.Status;
      control.CopyZone = this.foodSafetyForm.value.ChooseZone;
      control.IsCopyFiles = this.foodSafetyForm.value.CopyFiles;
      control.IsCopyTask = this.foodSafetyForm.value.CopyTask;
      control.IsCopySJAForm = this.foodSafetyForm.value.CopySJAForm;
      control.IsCopyHazard = this.foodSafetyForm.value.CopyHazard;
      control.IsCopySJA = this.foodSafetyForm.value.CopySJA;
      control.FilesTemplateId = this.filesTemplateId;
      control.HazardTemplateId = this.hazardTemplateId;
      control.copyTaskId = this.copyTaskId;
      control.SJATemplateId = this.sJATemplateId;
      control.Status = '1';
      control.Status = this.selectedValue;
      control.CreatedOn = new Date();
      control.ShowDeviation = !this.foodSafetyForm.value.IsShowDeviationChecked; // Reverse the value before saving
      if (
        this.bindSelectedUsers &&
        this.foodSafetyForm.value.selectedEmailSubscriber
      ) {
        control.FoodEmailSubscribers = [];
        this.emailSubscriber =
          this.foodSafetyForm.value.selectedEmailSubscriber;
      }
      if (
        this.bindAllUsers &&
        this.foodSafetyForm.value.selectAllEmailSubscriber
      ) {
        control.FoodEmailSubscribers = [];
        this.emailSubscriber =
          this.foodSafetyForm.value.selectAllEmailSubscriber;
      }
      let FoodUsers: FoodSafetyUser = new FoodSafetyUser();
      let Ids: any = ([] = []);

      this.selectedUsers.forEach((element: any) => {
        Ids.push({
          UserId: element.Id,
          ControlId: control.Id,
          Name: element.Name,
          IsEmail: this.emailSubscriber.find((x) => x.Id == element.Id)
            ? true
            : false,
        });
      });
      control.ControlUserDto = Ids;
      if (control.Id > 0) {
        control.CreatedBy = this.createdBy;
        control.CreatedOn = this.createdOn;
        control.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.kubaControlService
            .updateFoodsafety(control)
            .subscribe((result: any) => {
              this.toasterComponent.callToast();
              this.filesTemplateId = result.FilesTemplateId;
              this.hazardTemplateId = result.HazardTemplateId;
              this.copyTaskId = result.copyTaskId;
              this.sJATemplateId = result.SJATemplateId;
            })
        );
      } else {
        control.CreatedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.kubaControlService
            .createFoodsafety(control)
            .subscribe((result: any) => {
              this.toasterComponent.callToast();
              this.filesTemplateId = result.FilesTemplateId;
              this.hazardTemplateId = result.HazardTemplateId;
              this.copyTaskId = result.copyTaskId;
              this.sJATemplateId = result.SJATemplateId;
              this.createdBy = result.CreatedBy;
              this.createdOn = result.CreatedOn;
            })
        );
      }
    }
    this.ControlId = 0;
    this.foodSafetyForm.reset();
  }

  statusChange(event, id, status) {
    this.selectedValue = id == '2' ? '2' : '1';
  }

  gotoBack() {
    this.location.back();
  }
  hideCopyFile(fsZoneNames) {
    if (fsZoneNames > 0) {
      this.isHideFile = false;
    } else {
      this.isHideFile = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
