<div class="tab-content">
    <form [formGroup]="meetingForm" (ngSubmit)="validateTimeAndSave()">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="meetingType" class="col-form-label">
                        <span translate>TYPE_OF_MEETING</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown formControlName="meetingType" [options]="meetingTypes">
                        </p-dropdown>
                        <button class="btn btn-outline-primary" type="button" (click)="addTopicType = !addTopicType">
                            <i class="pi pi-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                    <control-messages [control]="meetingForm.controls['meetingType']"></control-messages>
                </div>
                <div class="form-group" *ngIf="edited">
                    <label for="business" class="col-form-label">
                        <span translate>CHOOSE_BUSINESS</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown [options]="businessType" formControlName="selectedMeeting"
                            (onChange)="onBusinessChanges(businessDropdown)" #businessDropdown
                            [style]="{'width':'100%'}"></p-dropdown>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="col-form-label">
                        <span translate>MEETING_CONVERNER</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="convernerName" [options]="convernerNames"
                            [style]="{'width':'100%'}"></p-dropdown>
                    </div>
                    <control-messages [control]="meetingForm.controls['convernerName']"></control-messages>
                </div>

                <div class="form-group">
                    <label for="name" class="col-form-label">
                        <span translate>PARTICIPANTS</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [options]="userNames" id="userNames"
                            formControlName="userNameList" [style]="{'width':'100%'}">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="form-group">
                    <label for="date" class="col-form-label">
                        <span translate>DATE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <my-date-picker name="date" [options]="DateOptions" formControlName="date"
                            placeholder="{{'SELECT_DATE' | translate}}" (dateChanged)="startDateChanged($event)"
                            required></my-date-picker>
                    </div>
                    <control-messages [control]="meetingForm.controls['date']"></control-messages>
                </div>
                <div class="time-fields">
                    <div class="form-group">
                        <label for="startTime" class="col-form-label">
                            <span translate>START_TIME</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-calendar formControlName="startTime" [timeOnly]="true" hourFormat="24"
                                [style]="{'width':'100%'}" (onSelect)="startTimeChanged($event)" readonlyInput="true">
                            </p-calendar>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="endTime" class="col-form-label">
                            <span translate>END_TIME</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-calendar formControlName="endTime" [timeOnly]="true" hourFormat="24"
                                [style]="{'width':'100%'}" (onSelect)="endTimeChanged($event)" readonlyInput="true">
                            </p-calendar>
                        </div>
                    </div>
                    <control-messages [control]="meetingForm.controls['endTime']"></control-messages>
                    <control-messages [control]="meetingForm.controls['startTime']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="spendtime" class="col-form-label">
                        <span translate>HOURS_SPENT</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="spendTime" readonly />
                    </div>
                </div>
                <div class="form-group">
                    <label for="comment" class="col-form-label">
                        <span translate>COMMENTS</span>
                    </label>
                    <div>
                        <textarea pInputTextarea formControlName="comment" [rows]="3" [cols]="30"></textarea>
                    </div>
                </div>

                <div class="action-btns-wrapper">
                    <button class="btn btn-outline-secondary" type="button" (click)="goToBack()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button type="submit" class="btn btn-primary" [disabled]="!meetingForm.valid">
                        <span translate>SAVE</span>
                    </button>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary-outline" (click)="exportPdf();">
                            <span class="icon ic-sm icon-print"></span>
                            <span translate>PRINT_PAGE</span>
                            <div class="panel-loader" *ngIf="loadingReport">
                                <span>
                                    <span class="loader-icon"></span>
                                    <span translate>PROCESSING</span>
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <div>
                        <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                            (saveChanges)="saveUploaded($event)">
                        </ecpl-document-explorer>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<p-dialog header="{{'ADD_MEETING'| translate}}" [(visible)]="addTopicType" (onHide)="onBeforeMeetingTypeDialogHide()"
    [modal]="true" [style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="MeetingTypeForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>TYPE</span><span class="required">*</span>
            </label>
            <div>
                <input type="text" class="label-input form-control" formControlName="MeetingTitle">
            </div>
            <control-messages [control]="MeetingTypeForm.controls['MeetingTitle']"></control-messages>
        </div>
        <label for="meetingTypeAlert" color class="col-form-label" *ngIf="isMeetingTypeExsist"
            [ngStyle]="{'color': 'red'}">
            <span translate>MEETINGTYPE_EXIST</span>
        </label>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" [formGroup]="MeetingTypeForm">
            <button type="button" class="btn btn-outline-primary" (click)="viewTopicType = !viewTopicType">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="!MeetingTypeForm.valid"
                (click)="saveMeetingType()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="viewTopicType">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #Types [value]="typesData" [rows]="10" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TYPE_NAME' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="Types.filter($any($event.target)?.value, 'Name', 'contains')"
                                    placeholder="" class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-meetingType>
                        <tr>
                            <td>
                                <span>{{meetingType.Title}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                    (click)="editMeetingType(meetingType)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                    (click)="deleteMeetingType(meetingType.Id)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="{{'Information'| translate}}" [(visible)]="isUsedMeetingtype" [modal]="true"
    [style]="{width: '300px'}" [resizable]="false" [draggable]="false" translate>
    MEETING_EXIST
    <p-footer>
        <button type="button" class="btn btn-link" title="{{'OK'|translate}}" (click)="informationHide();" translate>
            OK
        </button>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>