
export * from './activities.component';
export * from './deviation.component';
export * from './deviation-list.component';
export * from './meeting.component';
export * from './note.component';
export * from './suggestion-list.component';
export * from './meeting-list.component';
export * from './meetingCalender.component';
export * from './department.component';
export * from './topics.component';
export * from './upload-attachment.component';
export * from './activity-edit.component';
export * from './riskanalysis.component';
export * from './riskanalysis-list.component';
export * from './riskanalysis-new.component';
export * from './activity-list.component';
export * from './meeting-new.component';
export * from './viewlog.component';
export * from './staticdata.component';
export * from './suggestion.component';
export * from './suggestion-new.component';
export * from './deviation-new.component';
export * from './consumption-new.component';
export * from './consumptions.component';
export * from './consumption-list.component';
export * from './risk-new-portal.component';
export * from './ruh-new.component';
export * from './ruh-list.component';
export * from './ruh.component';
export * from './ruh-edit.component';
