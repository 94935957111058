import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { FrameworkAgreementServices } from './services/framework.service';
import { FrameworkListComponent } from './components/frameworkAgreementList.component';
import { FrameworkFormComponent } from './components/framework-form.component';
import { NgModule } from '@angular/core';
import { AppSharedModule } from './../shared/appShared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBaseModule } from './../common/common.module';

import { RouterModule } from '@angular/router';
import { FrameworkNewComponent } from './components/framework-new.component';
import { ClientListResolver } from './../client/services/client.resolver.service';
import { AssignUserResolver } from './../users/services/user.resolver.service';
import {
    FrameworkListResolver,
    FrameworkAgreementListResolver,
    FrameworkEditResolver,
    FrameworkFeaturesResolver,
    FrameworkListByUserResolver
} from 'src/app/kuba/framework-agreement/services/framework.resolver';
import { FrameworkListAllComponent } from './components/framework-list-all.component';
import { FrameworkEditComponent } from './components/framework-agreement-edit.component';
import { FrameworkAgreementViewComponent } from './components/framework-agreement-view.component';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        CommonBaseModule,
        HttpClientModule,
        EcplTabNavigationModule,
        EcplDocumentExplorerModule,
        KubaExportModule
    ],
    exports: [
        FrameworkListComponent,
        FrameworkFormComponent,
        FrameworkNewComponent,
        FrameworkListAllComponent,
        FrameworkEditComponent,
        FrameworkAgreementViewComponent
    ],
    declarations: [
        FrameworkListComponent,
        FrameworkFormComponent,
        FrameworkNewComponent,
        FrameworkListAllComponent,
        FrameworkEditComponent,
        FrameworkAgreementViewComponent
    ],
    providers: [
        FrameworkAgreementServices,
        ClientListResolver,
        AssignUserResolver,
        FrameworkAgreementListResolver,
        FrameworkListResolver,
        FrameworkEditResolver,
        FrameworkFeaturesResolver,
        FrameworkListByUserResolver
    ]
})
export class FrameworkAgreementModule { }
