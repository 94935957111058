export class InstrumentType {
    Id: number;
    Name: string;
    KundeELId: number;
    Status: string;  
    CreatedOn: Date;
    ModifiedOn: Date;
    CreatedBy: number;
    ModifiedBy: number;
    BusinessId: number;
    FeatureId: number;
}

export class ModelType {
    Id: number;
    Name: string;
    KundeELId: number;
    Status: string;  
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    BusinessId: number;
    FeatureId: number;
}

export class Instrument{
    Id: number;
    InstrumentTypeId: number;
    InstrumentModelId: number;
    MeasureProtocolId: number;
    MeasureProtocolCompleteId: number;
    CalibratedDate: string;
    SerialNumber: any;
    Status: string;  
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    CreatedName: number;
    ModifiedName: number;
    BusinessId: number;
    FeatureId: number
}



