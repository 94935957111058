import { Route } from '@angular/router';
import { ElectroComponent } from './electro.component';
import {
    ElectroOrganizationComponent,
    ElectroLARComponent,
    ElectroListComponent,
    ElectroViewComponent,
    EditPrintManualComponent,
    InspectionChartOverview,
    ElectroFormComponent,
    ElectroContractInfoComponent,
    InspectionComponent,
    InspectionFormComponent,
    InspectionFollowupViewComponent,
    InspectionOverviewComponent,
    InspectionFollowupTabComponent,
    DeviationOverviewComponent,
    DeviationFormComponent,
    ContractTabComponent,
    ContractDetailComponent,
    InstallationFormOverviewComponent,
    SettingsFormComponent,
    DeviationSettingsComponent,
    InspectionReportSettingsComponent,
    RiskAssessmentComponent,
    DocumentAssessmentComponent,
    DistributionOverviewComponent,
    CourseOverviewComponent,
    ElectroChecklistCompletionComponent,
    DeviationOverviewListComponent
} from './components';
import { AuthGuard } from 'src/app/_guards';
import {
    ElectroListByBusinessResolver,
    ElectroEditResolver,
    InspectionListByBusinessResolver,
    OrganizationListByBusinessResolver,
    ContractWorkersResolver,
    ContractContentResolver,
    InternalControlListByBusinessResolver,
    LawsListByBusinessResolver,
    AssignedWorkersResolver,
    AssignedTasksResolver,
    InspectionEditResolver,
    RegulationsListByBusinessResolver,
    DeviationListByBusinessResolver,
    DeviationEditResolver,
    ReportCheckListResolver,
    ElectroInfoResolver,
    ElectroContractDetailById,
    ElectroManualListResolver,
    ElectroEnabledManualsResolver,
    InspecitonChartResolver,
    EnabledInstallationFormsResolver,
    InstallationFormsBuildingsTreeResolver,
    EnabledChecklistResolver,
    ElectroSettingsResolver,
    ChecklistBuildingtreeResolver,
    DeviationsbyInspectionResolver,
    ElectroArchiveTreeDataResolver,
    ContractBuildingsResolver,
    ElectroCompletionChecklistDataResolver,
    AssignedWorkersByInspectionResolver,
    DeviationListByContractResolver,
    ElectroEnabledFeaturesResolver,
    InspectionEnabledTasksResolver,
    ControlScopeBuildingsResolver,
    InspectionScopeResolver,
    EnabledReportsResolver,
    InstallerInspectionByContractResolver,
    ClientInspectionByContractResolver,
    BuildingsByInspectionResolver,
    EnabledFormsResolver,
    ContractClientContactInfoResolver,
    ViolatedParaResolver,
    ClientNumListResolver,
    ClientUsersBySelfInspectionResolver,
    ElectroMeasureProtocolResolver,
    DeviationClientInfoResolver
} from './services/electro.resolver';
import { ClientListResolver } from '../client/services/client.resolver.service';
import { InspectionReportTemplateComponent } from './components/inspection-report-template.component';
import { InstallationFormsComponent } from './components/installation/installation-forms.component';
import { InstallationBuildingsResolver, CourseOverviewResolver, EmergencyLightingResolver, DistributionsOverviewResolver, FireSprinklerResolver, FireAlarmSystemResolver, RiskAssessmentResolver, DocumentAssessmentResolver, FormsWithTemplateResolver, ElectroBusinessInstTypeResolver, ElectroBusinessInstModelResolver, ElectroBusinessInstrumentsResolver } from './services/electro-installation.resolver';
import { EmergencyLightingComponent } from './components/installation/emergency-lighting.component';
import { FilreAlarmComponent } from './components/installation/fire-alarm-system.component';
import { ElectroArchiveComponent } from './components/electro-archive.component';
import { ContractContentComponent } from './components/Settings/contract-content/contract-content.component';
import { MeasureProtocolUsedInstrumentComponent } from '../measure-protocol/components/MPComplete/measure-protocol-used-instruments.component';

export const electroRoutes: Route[] = [
    {
        path: 'electro',
        component: ElectroComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: ElectroListComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: ElectroListByBusinessResolver,
                    contractworkers: ContractWorkersResolver
                }
            },
            {
                path: 'inspection',
                component: InspectionComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        component: InspectionChartOverview,
                        canActivate: [AuthGuard],
                        resolve: {
                            list: InspectionListByBusinessResolver,
                            chart: InspecitonChartResolver,
                            user: ContractWorkersResolver
                        }
                    },
                    {
                        path: ':cid/:Iid',
                        children: [{
                            path: 'edit',
                            component: InspectionFormComponent,
                            canActivate: [AuthGuard],
                            resolve: {
                                inspection: InspectionEditResolver,
                                followuppersons: ContractWorkersResolver,
                                executionpersons: AssignedWorkersResolver,
                                contracttasks: AssignedTasksResolver,
                                enabledChecklists: EnabledChecklistResolver,
                                treeData: ChecklistBuildingtreeResolver,
                                enabledTasks: InspectionEnabledTasksResolver,
                                clientContactInfo: ContractClientContactInfoResolver
                            }
                        }]
                    }]
            },
            {
                path: 'dev',
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    {
                        path: 'list',
                        component: DeviationOverviewListComponent,
                        resolve: {
                            deviations: DeviationListByBusinessResolver,
                            executionpersons: ContractWorkersResolver,
                        }
                    },
                    {
                        path: ':Iid/:Did',
                        component: DeviationFormComponent,
                        resolve: {
                            deviation: DeviationEditResolver,
                            followuppersons: AssignedWorkersByInspectionResolver,
                            executionpersons: AssignedWorkersByInspectionResolver,
                            building: BuildingsByInspectionResolver,
                            violatedPara: ViolatedParaResolver,
                            clientContactInfo: DeviationClientInfoResolver
                        }
                    }]
            },
            {
                path: 'settings',
                component: SettingsFormComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'contract_content', pathMatch: 'full' },
                    {
                        path: 'contract_content',
                        component: ContractContentComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            content: ContractContentResolver
                        }
                    },
                    {
                        path: 'deviation_settings',
                        component: DeviationSettingsComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            setting: ElectroSettingsResolver
                        }
                    },
                    {
                        path: 'inspect_settings',
                        component: InspectionReportSettingsComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            setting: ElectroSettingsResolver
                        }
                    },
                    {
                        path: 'report_templates',
                        component: InspectionReportTemplateComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            checklist: ReportCheckListResolver
                        }
                    },
                    {
                        path: 'instruments',
                        component: MeasureProtocolUsedInstrumentComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            instrumentType: ElectroBusinessInstTypeResolver,
                            modelType: ElectroBusinessInstModelResolver,
                            cmpListInt: ElectroBusinessInstrumentsResolver
                        }
                    }
                ]
            },
            {
                path: 'org_resp',
                component: ElectroOrganizationComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: OrganizationListByBusinessResolver
                }
            },
            {
                path: 'internal_control',
                component: ElectroOrganizationComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: InternalControlListByBusinessResolver
                }
            },
            {
                path: 'inspection_report',
                component: InspectionReportTemplateComponent,
                canActivate: [AuthGuard],
                resolve: {
                    checklist: ReportCheckListResolver
                }
            },
            {
                path: 'lar',
                component: ElectroLARComponent,
                canActivate: [AuthGuard],
                resolve: {
                    larlist: LawsListByBusinessResolver,
                    reglist: RegulationsListByBusinessResolver
                }
            }]
    },
    {
        path: 'electro/edit/:cid',
        component: ElectroFormComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'info', pathMatch: 'full' },
            {
                path: 'info',
                component: ElectroContractInfoComponent,
                canActivate: [AuthGuard],
                resolve: {
                    electro: ElectroEditResolver,
                    buildings: ControlScopeBuildingsResolver,
                    inspection: InspectionScopeResolver,
                    clients: ClientListResolver,
                    clientswithClientNum: ClientNumListResolver,
                    contractworkers: ContractWorkersResolver,
                    contractcontent: ContractContentResolver
                }
            },
            {
                path: 'editcontent',
                component: EditPrintManualComponent,
                canActivate: [AuthGuard],
                resolve: {
                    manuals: ElectroManualListResolver,
                    enabledManuals: ElectroEnabledManualsResolver
                }
            },
            {
                path: 'edit/:cid/:Iid',
                children: [
                    { path: '', redirectTo: 'detail', pathMatch: 'full' },
                    {
                        path: 'detail',
                        component: InspectionFormComponent,
                        resolve: {
                            inspection: InspectionEditResolver,
                            followuppersons: ContractWorkersResolver,
                            executionpersons: AssignedWorkersResolver,
                            contracttasks: AssignedTasksResolver,
                            enabledChecklists: EnabledChecklistResolver,
                            enabledTasks: InspectionEnabledTasksResolver,
                            clientContactInfo: ContractClientContactInfoResolver
                        }
                    }
                ]
            }]
    },
    {
        path: 'electro/details/:cid',
        component: ElectroViewComponent,
        resolve: {
            enabledFeatures: ElectroEnabledFeaturesResolver
        },
        children: [
            {
                path: 'manual',
                component: EditPrintManualComponent,
                canActivate: [AuthGuard],
                resolve: {
                    manuals: ElectroManualListResolver,
                    enabledManuals: ElectroEnabledManualsResolver
                }
            },
            {
                path: 'org_resp',
                component: ElectroOrganizationComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: OrganizationListByBusinessResolver
                }
            },
            {
                path: 'contracts',
                component: ContractTabComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'control', pathMatch: 'full' },
                    {
                        path: 'control',
                        component: ContractDetailComponent,
                        resolve: {
                            contractInfo: ElectroInfoResolver,
                            contractcontent: ContractContentResolver,
                            contractDetail: ElectroContractDetailById
                        }
                    },
                    {
                        path: 'deviation',
                        component: ContractDetailComponent,
                        resolve: {
                            contractInfo: ElectroInfoResolver,
                            contractcontent: ContractContentResolver,
                            contractDetail: ElectroContractDetailById
                        }
                    }
                ]
            },
            {
                path: 'internal_control',
                component: ElectroOrganizationComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: InternalControlListByBusinessResolver
                }
            },
            {
                path: 'inspection',
                component: InspectionFollowupViewComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'installer-insp',
                        children: [
                            { path: '', redirectTo: 'list', pathMatch: 'full' },
                            {
                                path: 'list',
                                component: InspectionOverviewComponent,
                                resolve: {
                                    list: InstallerInspectionByContractResolver,
                                    user: ContractWorkersResolver
                                },
                            },
                            {
                                path: 'edit/:cid/:Iid',
                                component: InspectionFollowupTabComponent,
                                children: [
                                    { path: '', redirectTo: 'info', pathMatch: 'full' },
                                    {
                                        path: 'info',
                                        component: InspectionFormComponent,
                                        resolve: {
                                            inspection: InspectionEditResolver,
                                            followuppersons: ContractWorkersResolver,
                                            executionpersons: AssignedWorkersResolver,
                                            contracttasks: AssignedTasksResolver,
                                            enabledChecklists: EnabledChecklistResolver,
                                            treeData: ChecklistBuildingtreeResolver,
                                            enabledTasks: InspectionEnabledTasksResolver,
                                            clientContactInfo: ContractClientContactInfoResolver
                                        }
                                    },
                                    {
                                        path: 'dev',
                                        children: [
                                            { path: '', redirectTo: 'list', pathMatch: 'full' },
                                            {
                                                path: 'list',
                                                component: DeviationOverviewComponent,
                                                resolve: {
                                                    deviations: DeviationsbyInspectionResolver,
                                                    executionpersons: ContractWorkersResolver,
                                                }
                                            },
                                            {
                                                path: ':Iid/:Did',
                                                component: DeviationFormComponent,
                                                resolve: {
                                                    deviation: DeviationEditResolver,
                                                    followuppersons: AssignedWorkersByInspectionResolver,
                                                    executionpersons: AssignedWorkersByInspectionResolver,
                                                    building: BuildingsByInspectionResolver,
                                                    violatedPara: ViolatedParaResolver,
                                                    clientContactInfo: DeviationClientInfoResolver
                                                }
                                            }]
                                    }]
                            }]
                    },
                    {
                        path: 'self-insp',
                        children: [
                            { path: '', redirectTo: 'list', pathMatch: 'full' },
                            {
                                path: 'list',
                                component: InspectionOverviewComponent,
                                resolve: {
                                    list: ClientInspectionByContractResolver,
                                    user: ContractWorkersResolver
                                },
                            },
                            {
                                path: 'edit/:cid/:Iid',
                                component: InspectionFollowupTabComponent,
                                children: [
                                    { path: '', redirectTo: 'info', pathMatch: 'full' },
                                    {
                                        path: 'info',
                                        component: InspectionFormComponent,
                                        resolve: {
                                            inspection: InspectionEditResolver,
                                            followuppersons: ContractWorkersResolver,
                                            executionpersons: AssignedWorkersResolver,
                                            contracttasks: AssignedTasksResolver,
                                            enabledChecklists: EnabledChecklistResolver,
                                            treeData: ChecklistBuildingtreeResolver,
                                            enabledTasks: InspectionEnabledTasksResolver,
                                            clientContactInfo: ContractClientContactInfoResolver,
                                            followuppersonsforselfInspection: ClientUsersBySelfInspectionResolver,
                                            executionpersonsforselfInspection: ClientUsersBySelfInspectionResolver,
                                        }
                                    },
                                    {
                                        path: 'dev',
                                        children: [
                                            { path: '', redirectTo: 'list', pathMatch: 'full' },
                                            {
                                                path: 'list',
                                                component: DeviationOverviewComponent,
                                                resolve: {
                                                    deviations: DeviationsbyInspectionResolver,
                                                    executionpersons: ContractWorkersResolver,
                                                }
                                            },
                                            {
                                                path: ':Iid/:Did',
                                                component: DeviationFormComponent,
                                                resolve: {
                                                    deviation: DeviationEditResolver,
                                                    followuppersons: AssignedWorkersByInspectionResolver,
                                                    executionpersons: AssignedWorkersByInspectionResolver,
                                                    building: BuildingsByInspectionResolver,
                                                    violatedPara: ViolatedParaResolver,
                                                    clientContactInfo: DeviationClientInfoResolver
                                                }
                                            }]
                                    }]
                            }]
                    },
                    {
                        path: 'dev-overview',
                        children: [
                            { path: '', redirectTo: 'list', pathMatch: 'full' },
                            {
                                path: 'list',
                                component: DeviationOverviewComponent,
                                resolve: {
                                    deviations: DeviationListByContractResolver,
                                    executionpersons: ContractWorkersResolver,
                                }
                            },
                            {
                                path: ':Iid/:Did',
                                component: DeviationFormComponent,
                                resolve: {
                                    deviation: DeviationEditResolver,
                                    followuppersons: AssignedWorkersByInspectionResolver,
                                    executionpersons: AssignedWorkersByInspectionResolver,
                                    building: BuildingsByInspectionResolver,
                                    violatedPara: ViolatedParaResolver,
                                    clientContactInfo: DeviationClientInfoResolver
                                }
                            }]
                    },
                    {
                        path: 'report-temp',
                        component: InspectionReportTemplateComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            checklist: ReportCheckListResolver
                        }
                    },
                    {
                        path: 'form-overview',
                        component: InstallationFormOverviewComponent,
                        resolve: {
                            enabledForms: EnabledInstallationFormsResolver,
                            treeData: InstallationFormsBuildingsTreeResolver
                        }
                    },
                    {
                        path: 'forms',
                        component: InstallationFormsComponent,
                        resolve: {
                            enabledForms: FormsWithTemplateResolver,
                        }
                    },
                    {
                        path: 'ckl/:cid/:Iid/new-complete-cklist/:tId',
                        component: ElectroChecklistCompletionComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            buildings: ContractBuildingsResolver,
                            enabledChecklists: EnabledReportsResolver,
                            responsiblepersons: AssignedWorkersByInspectionResolver,
                            forms: EnabledFormsResolver,
                            measureProtocols: ElectroMeasureProtocolResolver
                        }
                    },
                    {
                        path: 'ckl/:cid/:Iid/complete-cklist/:typ/:cClId',
                        component: ElectroChecklistCompletionComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            buildings: ContractBuildingsResolver,
                            checklistData: ElectroCompletionChecklistDataResolver,
                            enabledChecklists: EnabledReportsResolver,
                            responsiblepersons: AssignedWorkersByInspectionResolver,
                            forms: EnabledFormsResolver,
                            measureProtocols: ElectroMeasureProtocolResolver
                        }
                    }]
            },
            {
                path: 'lar',
                component: ElectroLARComponent,
                canActivate: [AuthGuard],
                resolve: {
                    larlist: LawsListByBusinessResolver,
                    reglist: RegulationsListByBusinessResolver,
                    electro: ElectroEditResolver
                }
            },
            {
                path: 'archive',
                component: ElectroArchiveComponent,
                resolve: {
                    treeData: ElectroArchiveTreeDataResolver
                }
            }]
    },
    {
        path: 'electro/:cid/:Iid/new-complete-cklist/:tId',
        component: ElectroChecklistCompletionComponent,
        canActivate: [AuthGuard],
        resolve: {
            buildings: ContractBuildingsResolver,
            enabledChecklists: EnabledReportsResolver,
            responsiblepersons: AssignedWorkersByInspectionResolver,
            forms: EnabledFormsResolver,
            measureProtocols: ElectroMeasureProtocolResolver
        }
    },
    {
        path: 'electro/:cid/:Iid/complete-cklist/:typ/:cClId',
        component: ElectroChecklistCompletionComponent,
        canActivate: [AuthGuard],
        resolve: {
            buildings: ContractBuildingsResolver,
            checklistData: ElectroCompletionChecklistDataResolver,
            enabledChecklists: EnabledReportsResolver,
            responsiblepersons: AssignedWorkersByInspectionResolver,
            forms: EnabledFormsResolver,
            measureProtocols: ElectroMeasureProtocolResolver
        }
    },
    {
        path: 'electro/:cid',
        children: [{
            path: 'ins/:Iid',
            children: [{
                path: 'document-assessment/:id',
                component: DocumentAssessmentComponent,
                resolve: {
                    contractInfo: ElectroInfoResolver,
                    buildings: InstallationBuildingsResolver,
                    DocumentAssessment: DocumentAssessmentResolver
                }
            },
            {
                path: 'risk-assesment/:id',
                component: RiskAssessmentComponent,
                resolve: {
                    contractInfo: ElectroInfoResolver,
                    buildings: InstallationBuildingsResolver,
                    riskAssessment: RiskAssessmentResolver
                }
            },
            {
                path: 'distribution-overview/:id',
                component: DistributionOverviewComponent,
                resolve: {
                    contractInfo: ElectroInfoResolver,
                    buildings: InstallationBuildingsResolver,
                    distributionOverview: DistributionsOverviewResolver
                }
            },
            {
                path: 'course-overview/:id',
                component: CourseOverviewComponent,
                resolve: {
                    contractInfo: ElectroInfoResolver,
                    buildings: InstallationBuildingsResolver,
                    course: CourseOverviewResolver
                }
            },
            {
                path: 'fire-alarm-system/:id',
                component: FilreAlarmComponent,
                resolve: {
                    contractInfo: ElectroInfoResolver,
                    buildings: InstallationBuildingsResolver,
                    firealarmSystem: FireAlarmSystemResolver
                }
            },
            {
                path: 'lighting/:id',
                component: EmergencyLightingComponent,
                resolve: {
                    contractInfo: ElectroInfoResolver,
                    buildings: InstallationBuildingsResolver,
                    lighting: EmergencyLightingResolver
                }
            }]
        }]
    }];
