<section class="kb-gallery-manager">
    <div class="kb-gm-previewer">
        <div *ngIf="this.selectedImageName !== ''" class="kb-gm-image">
            <img [src]="SelectImage.Path" [alt]="SelectImage.FileName">
        </div>
        <div *ngIf="this.selectedImageName === ''" class="kb-gm-image">
            Select an image to preview
        </div>
    </div>
    <div class="kb-gm-controls">
        <div class="form-group directory-controls-group">
            <label>
                <span translate>Select a Folder</span>
            </label>
            <div class="directory-controls">
                <p-dropdown [options]="folderList" [(ngModel)]="folderId" (onChange)="BindImageList()"
                    #imgFolderDropdown [style]="{'width':'100%'}"></p-dropdown>
            </div>
            <button class="btn btn-icon" title="Add" type="submit" (click)="saveAndCreateNew()">
                <i class="icon ic-sm icon-add"></i>
            </button>
            <button class="btn btn-icon text-danger" title="Delete" [disabled]="DeleteFolderValidation()"
                (click)="DeleteImageFolder()">
                <i class="icon ic-sm icon-trash"></i>
            </button>
        </div>
        <div class="form-group" *ngIf="imageList">
            <label>
                <span translate>Files</span>
            </label>
            <input class="form-control manuals-search" [(ngModel)]="filterText" placeholder="Search file">
            <div class="files-list">
                <label>
                    <span translate>Select a File</span>
                </label>
                <div class="list-item " *ngFor="let item of imageList | searchlist:'FileName':filterText">
                    <a href="javascript:void(0);" class="file-name" (click)="PreviewImage(item)">{{item.FileName}}</a>
                    <span class="file-actions">
                        <button class="btn btn-icon" title="Rename" (click)="ShowRename(item)">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button class="btn btn-icon text-danger" title="Delete" type="submit"
                            (click)="DeleteImageFile()">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="form-group">
                <div class="upload-file-container">
                    <label translate>Image</label>
                    <div class="upload-actions">
                        <input type="file" id="files" class="hidden" (change)="filesSelectForCoverImage($event)" />
                        <label for="files" class="btn btn-outline-primary" translate>Upload New Image</label>
                    </div>
                    <div *ngIf="uploadStartForCoverImage">
                        <span translate>Image Uploading...</span>
                    </div>
                </div>

                <button [disabled]="SelectedImageValidation()" class="btn btn-primary" type="submit" (click)="upload()">
                    <span translate>Select Image</span>
                </button>
                <button class="btn btn-outline-secondary" type="submit" (click)="Cancle()">
                    <span translate>Cancel</span>
                </button>
            </div>
        </div>
        <p-dialog header="Add folder" [(visible)]="display"  [style]="{width: '350px'}" [resizable]="false" [draggable]="false"
            [baseZIndex]="10000">
            <input class="form-control" [(ngModel)]="newFolderName">
            <p-footer>
                <button type="button" class="btn btn-primary" [disabled]="ValidateAddFolderText()"
                    (click)="NewFolder()">Add
                    Folder
                </button>
                <button type="button" class="btn btn-outline-secondary" (click)="CloseFolderDialogBox()">Cancel</button>
            </p-footer>
        </p-dialog>
        <p-dialog header="Rename Image" [(visible)]="displayImage"  [style]="{width: '350px'}" [resizable]="false" [draggable]="false"
            [baseZIndex]="10000">
            <input class="form-control" [(ngModel)]="selectedImageName">
            <p-footer>
                <button type="button" [disabled]="ValidateRenameText()" (click)="RenameImage()"
                    class="btn btn-primary">Rename</button>
                <button type="button" (click)="CloseRenameDialogBox()" (click)="Test()"
                    class="btn btn-outline-secondary">Cancel</button>
            </p-footer>
        </p-dialog>
    </div>
</section>