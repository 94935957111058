<div class="tab-content">
    <div class="page-title">
        <span translate>NOTES</span>
    </div>
    <form [formGroup]="noteForm">
        <div class="form-group" *ngIf="isVisible">
            <div>
                <label>
                    <span translate>CHOOSE_BUSINESS</span>
                    <span class="required">*</span>
                </label>
            </div>
            <div>
                <p-dropdown formControlName="Business" [options]="business" #businessId
                    (onChange)="onBusinessChanged(businessId)">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group" [hidden]="isHidden">
            <div>
                <textarea class="form-control" rows="10" formControlName='Note1' [(ngModel)]='sign'
                    placeholder="{{'PLEASE_ENTER_NOTE_HERE' | translate}}"></textarea>
            </div>
        </div>
    </form>
    <div class="action-btns-wrapper" [hidden]="isHidden">
        <button type="button" class="btn btn-outline-primary" (click)="signature()">
            <span translate>SIGNATURE</span>
        </button>
        <button type="submit" class="btn btn-primary" (click)="saveNote()">
            <span translate>SAVE</span>
        </button>
    </div>
</div>
<toaster-component></toaster-component>