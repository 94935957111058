export class Notes {
    Id: number;
    BusinessId: number;
    Note1: string;
    CreatedOn: string;
    CreatedBy: number;
    ModifiedOn: string;
    ModifiedBy: string;
}
export class BusinessList {
    Id: number;
    CompanyName: string;
}