export class EmployeeFirstOfKin {
  Id: number;
  ContactName: string;
  Mobile: number;
  Address: string;
  ZipCode: string;
  CountryCode: number;
  Relation: string;
  Status: string;
  EmployeeId: number;
  BusinessId: number;
  CreatedBy: string;
  CreatedOn: number;
  ModifiedOn: number;
  ModifiedBy: string;
  EmployeeName: string;
  Email:string;
}