<ngx-charts-bar-vertical-stacked
  [view]="view"
  [scheme]="colorScheme"
  [results]="multi"
  [legendTitle]="legendTitle"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [animations]="animations"
  [yAxisTickFormatting]="yAxisTickFormatting"
  [customColors]="customColors"
  [roundDomains]="true"
  (select)="onSelect($event)"
>
</ngx-charts-bar-vertical-stacked>
