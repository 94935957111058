import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { BaseServices } from './../../kuba.services';
import { FoodSafetyServices } from './foodsafety.services';
import { FoodSafetyList } from './../models/foodsafety-list';
import { BusinessDepartmentService } from './../../business-department.services';
import * as moment from 'moment';


@Injectable()
export class FoodSafetyListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getAllByBusinessId(BaseServices.BusinessId);
    }
}
@Injectable()
export class FoodSafetyEditResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        if (+route.params['id'] > 0) {
            return this.backend.getFoodsafetyById(+route.params['id']);
        } else { return null; }
    }

}
@Injectable()
export class ZoneListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getZoneName(BaseServices.BusinessId);
    }

}
@Injectable()
export class DepartmentResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: BusinessDepartmentService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getCachedBusinessDepartment();
    }

}

@Injectable()
export class FoodsafetyFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getFoodsafetyFeatures(BaseServices.BusinessId);
    }
}

@Injectable()
export class FoodSafetyListByUserResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getFoodByUser(BaseServices.UserId);
    }
}
@Injectable()
export class FoodSafetyZonesResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(): Observable<FoodSafetyList[]> {
        return this.backend.getZonesbyBusiness();
    }
}
@Injectable()
export class FSDailyTaskListResolver implements Resolve<FoodSafetyList[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.getFSDailyTaskList(+route.params['Id']);
    }
}
@Injectable()
export class FSAuditListResolver implements Resolve<any[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        let MinDate = null;
        let MaxDate = null;
        return this.backend.foodSafetyAuditList(+route.params['Id'], MinDate, MaxDate, false);
    }
}

@Injectable()
export class HazardAnalysisListResolver implements Resolve<any[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        let parentId = JSON.parse(sessionStorage.getItem('workingModule'));
        parentId = parentId.id;
        return this.backend.getHazardAnalysis(+route.params['Id'],parentId);
    }
}

@Injectable()
export class FSAuditDateListResolver implements Resolve<any[]> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FoodSafetyList[]> {
        return this.backend.foodSafetyAuditDateList(+route.params['Id']);
    }
}

@Injectable()
export class CoolingListResolver implements Resolve<any> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getCoolingBatches(+route.params['Id']);
    }
}

@Injectable()
export class CoolingProductsListResolver implements Resolve<any> {
    constructor(private backend: FoodSafetyServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getCoolingProducts();
    }
}
