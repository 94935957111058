export class BusinessPortalEdit {
    Id: number;
    BusinessPortalId: number;
    UserId: number;
    Title: string;
    Description: string;
    ApplicationId: number;
    Features: string;
    LogoPath: string;
    Status: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    IsCommonPortalCourseNames?: boolean;
    BusinessListName: string;
    IsPortalManager: boolean;
    PortalLogo: string;
    constructor() {
        this.Id = 0;
        this.Status = '1';
    }

}

