import { User } from './../../businesses/models/businessList';
import { SelectItem } from 'primeng/api';
import { PdfSetting, HelperService } from './../../../_services/helper.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FollowUpMessageService } from './../services/message.services';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'message-outbox',
    templateUrl: 'message-outbox.component.html'
})
export class MessageOutboxComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static: false}) dt: Table;
    roleId: string;
    ToUser: SelectItem[];
    outBoxData: any[];
    selectedMessages = [];
    isSelectedValue = false;
    additionalData: any;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private followUpMessageService: FollowUpMessageService,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
        this.additionalData = {
            fileName: val.MESSAGE_OUTBOX,
            header: 'Message-Outbox',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'To', dataKey: 'ToUserName' },
                { title: 'Subject', dataKey: 'Subject' },
                { title: 'Message', dataKey: 'Message' },
                { title: 'FileName', dataKey: 'FileName' },
                { title: 'Sent', dataKey: 'SendDate' }
            ]
        };
    }));


    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.roleId = BaseServices.userName;
        this.outBoxData = this.route.snapshot.data['message'];
    }
    deleteMessages() {
        let contactIds: any = [];
        if (this.selectedMessages.length > 0) {
            this.selectedMessages.forEach((e: any) => {
                contactIds.push(e.Id);
            });
            this.confirmationService.confirm({
                message:
                'Are you sure that you want to delete the selected records?',
                accept: () => {
                    this.subscriptions.push(this.followUpMessageService
                        .deleteMessages(contactIds)
                        .subscribe(() => {
                            this.subscriptions.push(this.followUpMessageService
                                .getOutBoxMessages(BaseServices.UserId)
                                .subscribe(res => (this.outBoxData = res)));
                            this.toasterComponent.callToastDlt();
                        }));
                }
            });
            this.selectedMessages = [];
        } else {
            alert('Please select one record');
        }
    }
    /**
     * row select event
     */
    onRowSelect(selectedValue:any) {
        if (this.selectedMessages) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }
    /**
     * row unselect event
     * @param selectedValue
     */
    onRowUnselect(selectedValue:any) {
        if (this.selectedMessages.length > 0) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }

    /**
     * to user dropdown change event
     * @param event
     * @param toUserDropdown
     */
    onToUserChange(event: any, toUserDropdown: any) {
        if (event.value) {
            this.dt.filter(
                toUserDropdown.selectedOption.label,
                'ToUserName',
                'equals'
            );
        } else {
            this.dt.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
