import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'crew',
    template:
    `
        <crew-tab></crew-tab>
       
    `
})
export class CrewComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}