import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { ToasterService, Toast, ToasterConfig } from 'angular2-toaster';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProjectAdminServices } from './../service/project-admin.service';
import { ConfirmationService } from 'primeng/api';
import { ProjectAdminList } from './../models/project-admin-list';
import { ProjectAdmin } from './../models/project-admin';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'project-admin-list',
    templateUrl: 'project-admin-list.component.html'
})
export class ProjectAdminListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    projectList: ProjectAdminList[];
    renameForm: FormGroup;
    renamedata: any;
    projectId: any;
    isRename: Boolean = false;
    createdBy: number;
    cachedProjectName: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private projectAdminServices: ProjectAdminServices,
        private formBuilder: FormBuilder,
        private toasterService: ToasterService,
        private translate: TranslateService
    ) {
        this.renameForm = this.formBuilder.group({
            ProjectName: ['', [Validators.required, Validators.nullValidator,
            ValidationService.noWhitespaceValidator], this.isProjectNameUnique.bind(this)]
        });
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        // must show only not assigned project
        this.projectList = <ProjectAdminList[]>this.route.snapshot.data['list'];
    }

    deleteProject(projectList) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.projectAdminServices
                    .delete(projectList)
                    .subscribe(result => {
                        this.toasterComponent.callToastDlt();
                        this.fetchAllProjects();
                    }));
            }
        });
    }

    onRenameClicked(projects) {
        this.isRename = true;
        this.projectId = projects.Id;
        this.createdBy = projects.CreatedBy;
        this.cachedProjectName = projects.Name;
        this.renamedata = {
            ProjectName: projects.Name
        };
        (<FormGroup>this.renameForm).setValue(this.renamedata, {
            onlySelf: true
        });
    }

    saveProjectName() {
        let project = new ProjectAdmin();
        project.Id = this.projectId;
        project.Name = this.renameForm.value.ProjectName;
        project.ApplicationId = BaseServices.ApplicationId;
        project.CreatedOn = new Date();
        project.ModifiedOn = new Date();
        project.CreatedBy = this.createdBy;
        project.ModifiedBy = BaseServices.UserId;
        project.IsCommon = 1;
        this.subscriptions.push(this.projectAdminServices.update(project).subscribe(result => {
            this.fetchAllProjects();
            this.toasterComponent.callToast();
        }));
        this.clear();
    }
    clear() {
        this.isRename = false;
        this.renamedata = {
            ProjectName: ''
        };
        (<FormGroup>this.renameForm).setValue(this.renamedata, {
            onlySelf: true
        });
    }

    fetchAllProjects() {
        this.subscriptions.push(this.projectAdminServices
            .getProjectList(BaseServices.ApplicationId)
            .subscribe((result: any) => (this.projectList = result)));
    }

    /**
        * project name validation
        */
    isProjectNameUnique(control: FormControl) {
        const q = new Promise(resolve => {
            setTimeout(() => {
                if (control.value === this.cachedProjectName) {
                    resolve(null);
                } else {
                    this.subscriptions.push(this.projectAdminServices.checkProjectName(control.value).subscribe(
                        x => {
                            if (!x) {
                                resolve(null);
                            } else {
                                resolve({ invalidProjectName: true });
                            }
                        },
                        () => {
                            resolve({ invalidProjectName: true });
                        }
                    ));
                }
            }, 1000);
        });
        return q;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
