import { Component, OnInit } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ActivatedRoute, Router, Params } from '@angular/router';
@Component({
    selector: 'newsafety-job',
    templateUrl: 'new-safety-job-analysis.component.html'

})

export class NewComponent implements OnInit {
    /**
     * constructor
     * @param activatedRoute {{ActivatedRoute}}
     * @param router {{Router}}
     */
    constructor( private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() { document.querySelector("body").classList.remove("opened");}
}