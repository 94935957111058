import { kubaFeatureDetailsRoutes } from './../shared/kuba-feature-details/Kuba-feature-details.routes';
import { Route } from '@angular/router';
import { AuthGuard } from 'src/app/_guards';
import { ClientListResolver } from './../client/services/client.resolver.service';
import { FrameworkAgreementComponent } from 'src/app/kuba/framework-agreement/framework.component';
import { FrameworkListComponent } from 'src/app/kuba/framework-agreement/components/frameworkAgreementList.component';
import { FrameworkNewComponent } from 'src/app/kuba/framework-agreement/components/framework-new.component';
import { AssignUserResolver, EmployeeContactUserSubscriptionResolver,
     EmployeeContactUserResolver 
    } from './../users/services/user.resolver.service';
import {
    FrameworkListResolver,
    FrameworkAgreementListResolver,
    FrameworkEditResolver,
    FrameworkFeaturesResolver,
    FrameworkListByUserResolver
} from './services/framework.resolver';
import { FrameworkListAllComponent } from 'src/app/kuba/framework-agreement/components/framework-list-all.component';
import { FrameworkAgreementViewComponent } from 'src/app/kuba/framework-agreement/components/framework-agreement-view.component';
import { FrameworkEditComponent } from 'src/app/kuba/framework-agreement/components/framework-agreement-edit.component';
import { CommonViewComponent } from './../common/components/index';
import { deviationRoutes } from './../deviation/deviation.routing';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';

export const frameworkRoutes: Route[] = [
    {
        path: 'framework-agreement',
        component: FrameworkAgreementComponent,
        children: [
            {
                path: 'list',
                component: FrameworkListComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'own',
                        component: FrameworkListAllComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            List: FrameworkListByUserResolver
                        }
                    },
                    {
                        path: 'all',
                        component: FrameworkListAllComponent,
                        canActivate: [AuthGuard],
                        resolve: {
                            List: FrameworkAgreementListResolver
                        }
                    }
                ]
            },
            {
                path: 'edit/:fId',
                component: FrameworkEditComponent,
                canActivate: [AuthGuard],
                resolve: {
                    edit: FrameworkEditResolver,
                    clients: ClientListResolver,
                    users: EmployeeContactUserResolver,//AssignUserResolver,
                    frameworkList: FrameworkListResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver
                }
            },
            {
                path: 'new',
                component: FrameworkNewComponent,
                resolve: {
                    clients: ClientListResolver,
                    users: EmployeeContactUserResolver,//AssignUserResolver,
                    frameworkList: FrameworkListResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver, 
                }
            },
            {
                path: 'details/:id',
                component: FrameworkAgreementViewComponent,
                data: { parent: 'FRAMEWORK' },
                resolve: {
                    additionalFeatures: FrameworkFeaturesResolver
                },
                canActivate: [AuthGuard],
                children: [
                    ...kubaFeatureDetailsRoutes,
                    ...FeatureDocumentRoutes,
                    ...deviationRoutes,
                    ...taskmanagerRoutes,
                    ...timetableRoutes,
                    ...safetyjobRoutes,
                    ...registrationRoutes
                ]
            }
        ]
    }
];
