<!-- Table starts -->
<p-table (onRowUnselect)="onRowUnselect($event.data)" (onRowSelect)="onRowSelect($event.data)" [value]="CommonManuals"
    [rows]="10" [paginator]="true" [(selection)]="selectedManuals" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" #dt>
    <!-- Header -->
    <ng-template pTemplate="header">
        <tr>
            <th *ngIf="isAllCommonManual" style="width: 55px;">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngIf="!checkCommon" pSortableColumn="Title">{{'SAFETY_MANUAL_NAME'|translate}}</th>
            <th *ngIf="checkCommon" pSortableColumn="Title">{{'SAFETY_MANUAL_NAME'|translate}}</th>
            <th style="width:130px">{{'STATUS'|translate}}</th>
            <th *ngIf="!checkCommon && userRole ===1">{{'EDITOR_ONLY'|translate}}</th>
            <th *ngIf="!checkCommon && userRole ===1">{{'VERSION_NUMBER' | translate}}</th>
            <th *ngIf="!checkCommon && userRole ===1">{{'EDITED_BY' | translate}}</th>
            <th *ngIf="!checkCommon && userRole ===1">{{'EDITED_DATE' | translate}}</th>
            <th *ngIf="!checkCommon && userRole ===1">{{'TEXT' | translate}}</th>
            <th *ngIf="!checkCommon" style="width:80px">{{'ACTION' | translate}}</th>
        </tr>
        <tr>
            <th *ngIf="isAllCommonManual">
            </th>
            <th *ngIf="!checkCommon">
                <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'Title', 'contains')"
                    placeholder="" class="p-column-filter" >
            </th>
            <th *ngIf="checkCommon">
                <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'Title', 'contains')"
                    placeholder="" class="p-column-filter" >
            </th>
            <th>
                <p-dropdown [options]="statusList" (onChange)="dt.filter($event.value, 'Status', 'equals')"
                    styleClass="p-column-filter" placeholder="Select" [showClear]="true">
                    <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                    </ng-template>
                </p-dropdown>
            </th>
            <th *ngIf="!checkCommon && userRole ===1"></th>
            <th *ngIf="!checkCommon && userRole ===1"></th>
            <th *ngIf="!checkCommon && userRole ===1"></th>
            <th *ngIf="!checkCommon && userRole ===1"></th>
            <th *ngIf="!checkCommon && userRole ===1">

            </th>
            <th *ngIf="!checkCommon"></th>
        </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-manual>
        <tr>
            <td *ngIf="isAllCommonManual" style="width: 55px;">
                <p-tableCheckbox id="selectedOrders" [value]="manual">
                </p-tableCheckbox>
            </td>
            <td *ngIf="!checkCommon">
                <a
                    [routerLink]="['../view', manual['AppManualId'], manual['Title'],manual['PortalId'],manual['BusinessId']]">{{manual.Title}}</a>
            </td>
            <td *ngIf="checkCommon">
                <a [class.disabled]="manual.Status===2 && userRole===2"
                    [routerLink]="['../view', manual['AppManualId'], manual['Title'], manual['PortalId'], manual['BusinessId']  ]">{{manual.Title}}</a>
            </td>
            <td>
                <!-- <span translate>{{manual.Status | status : ["DELETED","ACTIVE","INACTIVE"]}}</span> -->
                <span>{{ getStatusTranslation(manual.Status) }}</span>
            </td>
            <td *ngIf="!checkCommon && userRole ===1">
                <p-checkbox (onChange)="onIsEditorChange(manual)" [(ngModel)]="manual.isEditorOnly" [binary]="true">
                </p-checkbox>
            </td>
            <td *ngIf="!checkCommon && userRole ===1">
                <p-dropdown [options]="assignManualVersion" [(ngModel)]="manual.RefVersionId"
                    (onChange)="onValueChange(manual)" [style]="{'width':'130px'}" appendTo="body">
                </p-dropdown>
            </td>
            <td *ngIf="!checkCommon && userRole ===1">
                <p-dropdown [options]="bindUserList" [(ngModel)]="manual.RefEditedBy" (onChange)="onValueChange(manual)"
                    [style]="{'width':'130px'}" appendTo="body">
                </p-dropdown>
            </td>
            <td *ngIf="!checkCommon && userRole ===1">
                <p-calendar [ngModel]="manual.RefEditedDate" (ngModelChange)="manual.RefEditedDate=$event"
                    dateFormat="dd/mm/yy" (onSelect)="onValueChange(manual)" appendTo="body">
                </p-calendar>
            </td>
            <td *ngIf="!checkCommon && userRole ===1">
                <div>
                    <span class="col-sm-9" style="padding:0" translate>STANDARD_TEXT</span>
                    <p-checkbox style="padding-left:5px" (onChange)="onValueChange(manual)" [(ngModel)]="manual.StandardText" [binary]="true">
                    </p-checkbox>
                </div>
                <div>
                    <span class="col-sm-9" style="padding:0" translate>LEGISLATION</span>
                    <p-checkbox style="padding-left:5px" (onChange)="onValueChange(manual)" [(ngModel)]="manual.Legislation" [binary]="true">
                    </p-checkbox>
                </div>
            </td>
            <td class="col-button" *ngIf="!checkCommon">
                <a href="javascript:void(0);" (click)="onDeleteAssignManual(manual)" class="btn btn-icon">
                    <span title="{{'DELETE'|translate}}" class="icon ic-sm icon-trash" aria-hidden="true"></span>
                </a>
            </td>
        </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template *ngIf="!isAllCommonManual" pTemplate="emptymessage" let-columns>
        <tr>
            <td colspan="2">
                {{'NO_RECORDS_FOUND'|translate}}
            </td>
        </tr>
    </ng-template>
    <ng-template *ngIf="isAllCommonManual" pTemplate="emptymessage" let-columns>
        <tr>
            <td colspan="9">
                {{'NO_RECORDS_FOUND'|translate}}
            </td>
        </tr>
    </ng-template>
</p-table>
<!-- Table ends -->

<div class="action-btns-wrapper" *ngIf="checkCommon && isAllCommonManual">
    <button (click)="assignManual()" type="button" class="btn btn-primary">
        <span translate>ASSIGN</span>
    </button>
</div>

<div class="action-btns-wrapper" *ngIf="!checkCommon && isAllCommonManual">
    <button (click)="updateManual()" type="button" class="btn btn-primary">
        <span translate>UPDATE</span>
    </button>
</div>

<div class="action-btns-wrapper" *ngIf="checkCommon && !isAllCommonManual && userRole==2">
    <button (click)="updatePortalCommonManual()" type="button" class="btn btn-primary">
        <span translate>UPDATE</span>
    </button>
</div>