import { TypeOfRegistrationListResolver } from './services/registration.resolver.service';
import { Route } from '@angular/router';
import { AuthGuard } from './../../_guards/index';
import { RegistrationComponent } from './registration.component';
import {
  RegistrationListComponent,
  RegistrationNewComponent
} from './components/index';
import { RegistrationListResolver, RegistrationEditResolver } from 'src/app/kuba/Registration/services/registration.resolver.service';

export const registrationRoutes: Route[] = [{
  path: 'registration',
  component: RegistrationComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: 'list',
      component: RegistrationListComponent,
      resolve: {
        list: RegistrationListResolver
      }
    },
    {
      path: 'edit/:rid',
      component: RegistrationNewComponent,
      data: { parent: 'REGISTER-EDIT' },
      resolve: {
        edit: RegistrationEditResolver,
        registrationType: TypeOfRegistrationListResolver
      }
    }
  ]
}];
