import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { EmployeeHoliday, EmployeeLeave, EmployeeLeaveAvailability } from '../models';
import { BaseServices } from './../../kuba.services';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class EmployeeLeaveService {
    ses: string;

    constructor(private http: HttpClient) {
    }
    getAllByBusinessId(businessId: number, getYear:any) { // remesh
        return this.http.get(environment.BASE_URL + '/employeeleaves/' + businessId + '/' + getYear, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getById(id: number) {
        return this.http.get(environment.BASE_URL + '/employeeleavesbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getEmployeeLeavesByEmployeeId(id: number) {
        return this.http.get(environment.BASE_URL + '/employeeleavesbyemployeeid/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    getEmployeeAppliedLeavesByEmployeeId(id: number) {
        return this.http.get(environment.BASE_URL + '/employeeappliedleaves/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    getEmployeeLeaveByEmployeeId(id: number) {
        return this.http.get(environment.BASE_URL + '/employeeleavebyemployeeid/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    getEmployeeLeaveEventsByBusinessId() {
        return this.http.get(environment.BASE_URL + '/employeedaypilotevents/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map(result => result);
    }
    getAvailableLeaves(empId: number, bid: number, year: number) {
        return this.http
            .get(environment.BASE_URL + '/employeeavailableleaves?EmployeeId=' + empId + '&BusinessId=' + bid + '&Year=' + year
            , BaseServices.headerOption)
            .map(result => result);
    }

    getEmployeeLeaveByLeaveId(id: number) {
        return this.http.get(environment.BASE_URL + '/employeeleavebyleaveid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    
    exportEmployeeLeaveToExcel(langId: number,id:number,startDate:string,endDate:string) {   
        this.ses = sessionStorage.getItem('session')
        let headers = new HttpHeaders();
        let session = JSON.parse(this.ses);
        if (session) {
            let type = 'Bearer';
            let token = session.AccessToken;
        
                headers = headers.append('content-type', 'application/json');
                headers = headers.append('Authorization', type + ' ' + token);
            }
        
        return this.http.post(environment.BASE_URL + '/employeeleave/exportToExcel/langId/' +langId+ '/businessId/'+id+'/startDate/' + startDate + '/endDate/' + endDate,
        null, 
        { responseType: 'blob', headers: headers}) 
       .map(
        (res: any) => {
            //   var blob = new Blob([res.blob()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'} )
              return res;            
        });
    }
    // bind emp dropdown
    getEmpByName(id: number) {
        return this.http.get(environment.BASE_URL + '/employees/' + id, BaseServices.headerOption)
            .map(employees => employees);
    }

    add(employee: EmployeeLeave) {
        return this.http.post(environment.BASE_URL + '/employeeleave', JSON.stringify(employee), BaseServices.headerOption)
            .map(result => result);
    }

    update(employee: EmployeeLeave) {
        return this.http.put(environment.BASE_URL + '/employeeleave/' + employee.Id, employee,BaseServices.headerOption)
            .map(result => result);
    }

    deleteLeave(id: number) {
        return this.http.delete(environment.BASE_URL + '/employeeleave/' + id,BaseServices.headerOption)
            .map(leave => leave);
    }

    deleteHoliday(id: number) {
        return this.http.delete(environment.BASE_URL + '/employeeHoliday/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // get approval person list
    getAllApprovedPerson() {
        return this.http.get(environment.BASE_URL + '/approval-persons-business/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // bing grid holidaty type
    getAllLeaveAllocation(bid: number, year: number) {
        return this.http.get(environment.BASE_URL + '/leaveavailability/' + bid + '/' + year, BaseServices.headerOption)
            .map(result => result);
    }
    getAllHolidays(bid: number) {
        return this.http.get(environment.BASE_URL + '/employeeholidays/' + bid, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveLeaveAllocation(leave: EmployeeLeaveAvailability[]) {
        return this.http.post(environment.BASE_URL + '/employeeallocatedleave', leave, BaseServices.headerOption)
            .map(result => result);
    }
    // update employee type
    updateHoliday(existingCategory: EmployeeHoliday) {
        return this.http.put(environment.BASE_URL + '/employeeholiday/' + existingCategory.Id, existingCategory, BaseServices.headerOption)
            .map(result => result);
    }
    // adding employee Type
    createHoliday(newCategory: EmployeeHoliday) {
        return this.http.post(environment.BASE_URL + '/employeeholiday', JSON.stringify(newCategory), BaseServices.headerOption)
            .map(result => result);
    }
    saveDayPilotLeaves(saveDayPilotLeaves: any, editedBy: number)
    {
        return this.http.post(environment.BASE_URL + '/employeeleave/savedaypilotleaves/' + editedBy + '/' + BaseServices.userLanguageId, JSON.stringify(saveDayPilotLeaves) , BaseServices.headerOption)
            .map(result => result);
    }
}       
