export class CommonArticle {
    Id: number;
    AppManualId: number;
    LevelId: number;
    LevelKey: number;
    AppManualFolderId: number;
    Title: string;
    DocumentNo: string;
    Chapter: string;
    Version: number;
    OwnContent: string;
    StandardContent: string;
    LegislationContent: string;
    PortalContent: string;
    OwnStatus: number;
    StandardStatus: number;
    LegislationStatus: number;
    PortalStatus: number;
    SignaturePath: string;
    ArticleDate: string
    IsCommon: boolean;
    IsAccess: boolean;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;

    constructor(){
        this.Id = 0;
        this.IsAccess = true;
        this.Status = 1;
        this.IsCommon = false;
        this.OwnStatus = 1;
        this.StandardStatus = 1;
        this.LegislationStatus = 1;
        this.PortalStatus = 1;
        this.Version = 1;
    }
}