import { Component, OnInit } from "@angular/core";
import { ActivityServices } from "../../follow-ups/services/activities.services";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { CKEditorLoaderService } from "src/app/shared/ckeditor-loader.service";

@Component({
    selector: 'advisor-detail',
    templateUrl: 'my-hse-advisor.component.html'
})
export class MyHSEAdvisor implements OnInit {
    userData: any;


    constructor(
        private activityService: ActivityServices,
        private route: ActivatedRoute,
        public router: Router,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private ckEditorLoader: CKEditorLoaderService
    ) {}
    
    ngOnInit(){
        $('body').removeClass('opened');

        this.ckEditorLoader.isScriptLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
              // CKEditor script has been loaded
              // Perform any additional initialization or configuration here
            }
          });
        this.userData  = this.route.snapshot.data['list'];
        console.log('userData:', this.userData);
        if( this.userData && this.userData.Image == null){
            this.userData.Image = './assets/icons/default-avatar.png';
        }
    }
}