<style>
    .table>tbody>tr>td {
        padding: 0px;
    }
</style>
<div class="page-title">
    <button *ngIf="!editMode" class="btn text-primary" (click)="printPdf()">
        <span class="icon ic-sm icon-print"></span>
        <span translate>PRINT_AS_PDF</span>
    </button>
    <span class="page-actions text-right">
        <button *ngIf="isGuestHideButton" type="button" class="btn btn-link" (click)="toggleEditMode()">
            <span [ngClass]="editMode ? 'text-danger':'text-success'">
                <span *ngIf="editMode" translate>GO_DISPLAY</span>
                <span *ngIf="!editMode" translate>GO_EDIT</span>
                <span class="icon ic-md" [ngClass]="editMode ? 'icon-view-content':'icon-edit-content'"></span>
            </span>
        </button>
    </span>
</div>
<div class="card">
    <div class="card-body bg-light">
        <div class="row">
            <div class="col-md-6">
                <label translate>DOCUMENT_NAME</label>:
                <label class="text-primary" translate>CONTRACT_INTERNAL_CONTROL</label>
            </div>
            <div class="col-md-3">
                <label translate>DOCUMENT_NUMBER</label>:
                <label class="text-primary">{{details.DocumentNumber}}</label>
            </div>
            <div class="col-md-3">
                <label translate>VERSION</label>:
                <input *ngIf="editMode;else lbl" [(ngModel)]="details.Version" pInputText class="text-primary" />
                <ng-template #lbl><label class="text-primary">{{details.Version}}</label></ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <label translate>CHAPTER</label>:
                <label class="text-primary">2</label>
            </div>
            <div class="col-md-3">
                <label translate>EDITED_BY</label>:
                <label class="text-primary">{{editorName}}</label>
            </div>
            <div class="col-md-3">
                <label translate>PUBLISHED_ON</label>:
                <label class="text-primary" translate>{{details.CreatedOn}}</label>
            </div>
            <div class="col-md-3">
                <label translate>EDITED_DATE</label>:
                <label class="text-primary">{{details.ModifiedOn}}</label>
            </div>
        </div>
        <electro-info-viewer [ElectroInfo]="electro"></electro-info-viewer>
        <!-- Table starts -->
        <p-table #dt [value]="tasks" dataKey="Id">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'INSPECTION_TASKS' | translate}}</th>
                    <th>{{'UNIT' | translate}}</th>
                    <th>{{'PRICE' | translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-contract>
                <tr>
                    <td>
                        <span>{{contract.Name}}</span>
                    </td>
                    <td>
                        <p-dropdown [options]="units" [style]="{'width':'100%','overflow':'visible'}"
                          [(ngModel)]="contract.Unit" *ngIf="editMode">
                        </p-dropdown>
                        <span *ngIf="!editMode" translate>{{contract.UnitText}}</span>
                    </td>
                    <td>
                        <input [style]="{'width':'100%'}" pInputText [(ngModel)]="contract.Cost" *ngIf="editMode" />
                        <span *ngIf="!editMode">{{contract.Cost}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="3">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <br>
        <div *ngIf="editMode">
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th colspan="4">
                                    <span translate>DESCRIPTION_OF_ELECTRICAL_SYSTEM</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <textarea [(ngModel)]="details.Description" pInputTextarea
                                      autoResize="autoResize"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th colspan="4">
                                    <span translate>TERMS_DURATION</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <textarea pInputTextarea [(ngModel)]="details.TermsDuration"
                                      autoResize="autoResize"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="!editMode">
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th colspan="4">
                                    <span translate>DESCRIPTION_OF_ELECTRICAL_SYSTEM</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <textarea [(ngModel)]="details.Description" pInputTextarea readonly
                                      autoResize="autoResize"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th colspan="4">
                                    <span translate>TERMS_DURATION</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <textarea pInputTextarea readonly [(ngModel)]="details.TermsDuration"
                                      autoResize="autoResize"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>SIGN_ELECTRICAL_COMPANY</span>
                    </label>
                    <canvas *ngIf="!isClient" [ngClass]="{hidden: !editMode}" #canvas></canvas>
                    <img *ngIf="electricianSign &&(isClient || !editMode)" style="width: 380px;height:150px"
                      [src]="electricianSign" alt="article File">
                    <label class="col-form-label"><span translate>SIGNED</span>: {{electricianSignInfo}}</label>
                </div>
                <button *ngIf="!isClient && editMode" (click)="clearSign()" class="btn btn-outline-secondary">
                    <span translate>CLEAR_SIGN</span>
                </button>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>SIGN_CLIENT</span>
                    </label>
                    <canvas *ngIf="isClient" #canvas></canvas>
                    <img *ngIf="clientSign && !isClient" style="width: 380px;height:150px" [src]="clientSign"
                      alt="article File">
                    <label class="col-form-label"><span translate>SIGNED</span>: {{clientSignInfo}}</label>
                </div>
                <button *ngIf="isClient" (click)="clearSign()" class="btn btn-outline-secondary">
                    <span translate>CLEAR_SIGN</span>
                </button>
            </div>
        </div>
        <div *ngIf="editMode || isClient" class="action-btns-wrapper">
            <button class="btn btn-primary" [ngClass]="{'loading' : loading}" (click)="saveChanges()"
              [disabled]="loading">
                <span>
                    <span *ngIf="!loading" translate>SAVE</span>
                    <span *ngIf="loading" translate>SAVE_PROCESS</span>
                    <span *ngIf="loading" class="loader-icon"></span>
                </span>
            </button>
        </div>
    </div>
</div>
<toaster-component></toaster-component>