export class ManualView {
  Id: number;
  Title: string;
  Description: string;
  CoverImg: string;
  Status: number;
  PortalId: number;
  BusinessId: number;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedOn: string;
  ModifiedOn: string;
  PublishedOn: string;
  created: {
    Id: number;
    Name: string;
  };
  Modified: {
    Id: number;
    Name: string;
  };
  LevelId: number;
  LevelKeyId: number;
  LevelKeyName: string;
  Level: Level;
  EditorOnly: boolean;
  isEditorOnly: boolean;
  isUpdate: boolean;
  isCommon: boolean;
  AppManualId: number;
  RefVersionId: number;
  RefEditedBy: number;
  RefEditedDate: Date;
  OwnText: boolean;
  StandardText: boolean;
  Legislation: boolean;
  ApprovedDate?: any;
  ApplicationId: number;
  BusinessPortalId: number;
  ShowChecklist: boolean;
  ApprovedArticle: any[];
  constructor() {
    this.Id = 0;
    this.isUpdate = false;
  }
}

export class Level {
  Id: number;
  Type: string;
}

