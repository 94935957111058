import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NotificationServices } from './../../_services/notification.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { NotificationList } from '../notifications/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: 'notificationuser.component.html'
})

export class NotificationUserComponent implements OnInit {

    //#region variable

    about: string;
    notificationList: NotificationList;
    notificationUser: any;
    businessId: number;
    notificationTitle: string;
    showNewNotification: boolean;
    notificationDesc: string;

    //#endregion

    //#region constructor

    /**
     * constructor
     * @param route {ActivatedRoute}
     * @param notificationServices {NotificationServices}
     * @param _fb {FormBuilder}
     */
    constructor(
        private route: ActivatedRoute,
        private notificationServices: NotificationServices,
        private _fb: FormBuilder,
        private translate: TranslateService
    ) {

    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.businessId = BaseServices.BusinessId;
        this.notificationUser = this.route.snapshot.data['list'];
    }
    /**
     * show popup
     * @param notification {any}
     */
    showTitlePopup(notification: any) {
        this.showNewNotification = true;
        this.notificationTitle = notification.Title;
        this.notificationDesc = notification.Description;
        let replaceKey = ['EFO_DETAILS','EFO_ID','EFO_FILENAME','EFO_TOTAL','PROJECT_NAME','EFO_SUCESS','EFO_FAILED','EFO_DUPLICATE'];
        replaceKey.forEach(element => {
            this.notificationDesc = this.notificationDesc.replace(element, this.translate.instant(element));
        });
    }
    /**
     * reset popup
     */
    closePopUp() {
        this.notificationTitle = '';
        this.notificationDesc = '';
        this.showNewNotification = false;
    }

    //#endregion

    //#region methods


    //#endregion

}
