import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { FeatureKey } from 'src/app/_models/feature';
import { FrameworkAgreementServices } from '../services/framework.service';
@Component({
    selector: 'framework-agreement-view',
    templateUrl: 'framework-agreement-view.component.html'
})

export class FrameworkAgreementViewComponent implements OnInit {
    pageTitle: string;
    enabledAdditionalFeatures: any;
    additionalFeatures: any;
    faId: number;
    private subscriptions: Subscription[] = [];
    initialPage: any;
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private frameworkAgreementServices: FrameworkAgreementServices
    ) {
        this.faId = this.activatedRoute.snapshot.params['id'];
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        this.subscriptions.push(this.frameworkAgreementServices.getFrameworkById(this.faId).subscribe(result => {
            if (result) {
                this.pageTitle  = result.ContractName;
                sessionStorage.setItem('FrameWorkAgreementName',this.pageTitle);
            }
        }));
        this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x => x.id !== 164);
        let index = _.findIndex(this.enabledAdditionalFeatures, { translationKey: 'DETAILS' });
        if (index >= 0) {
            this.enabledAdditionalFeatures[index].translationKey = 'FA_DETAILS';
        }
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
            .filter(x => x.id !== FeatureKey.SJA_CHECKLIST)

        if (this.enabledAdditionalFeatures.length > 0) {
            this.route.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.faId }],
                { relativeTo: this.activatedRoute });
        }
    }

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
