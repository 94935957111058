import { ManualService } from './services/manual.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManualView } from './models/index';
import { BaseServices } from './../kuba.services';
import { Toast } from 'angular2-toaster';
import { ToasterComponent } from './../../_directives/toaster.component';
import { debug } from 'util';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'manual.component.html'
})
export class ManualComponent implements OnInit {
    @ViewChild(ToasterComponent,{static:true}) toasterComponent: ToasterComponent;
    isAdminMode: boolean;
    showDialog = false;
    manual: ManualView;
    newManualText = 'NEW_COMMON_MANUAL';
    manualType = 'common';
    manuals: ManualView[];

    manualList: ManualView[];
    portalManualList: ManualView[];
    businessManualList: ManualView[];
    allManualList: ManualView[];
    showToast = false;
    toast: Toast;
    private subscriptions: Subscription[] = [];
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private manualService: ManualService
    ) {
        this.isAdminMode = BaseServices.UserRole === 'Admin';
        this.allManualList = this.route.snapshot.data['allManualList'];
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.BindManualList(this.manualType);
    }

    newManual() {
        this.manual = new ManualView();
        this.showDialog = true;
        if (this.manual.Id === 0) {
            if (this.manualType === 'common') {
                this.newManualText = 'NEW_COMMON_MANUAL';
            } else if (this.manualType === 'portal') {
                this.newManualText = 'NEW_PORTAL_MANUAL';
            } else if (this.manualType === 'business') {
                this.newManualText = 'NEW_BUSINESS_MANUAL';
            } else {
                this.newManualText = 'NEW_COMMON_MANUAL';
            }
        }
    }

    onClose(returnObj: any) {
        if (returnObj.isSaved) {
            this.toast = returnObj.toast;
            this.showToast = true;
            if (returnObj.isProceed) {
                this.router.navigate(
                    ['./view', returnObj.manual.Id, returnObj.manual.Title],
                    { relativeTo: this.route.parent }
                );
            } else {
                this.toasterComponent.callToast();
                this.BindUpdatedList();
            }
        }
        this.showDialog = false;
    }

    onTabClick(manualType: string) {
        this.manualType = manualType;
        switch (manualType) {
            case 'common':
                this.newManualText = 'EDIT_COMMON_MANUAL';
                break;
            case 'portal':
                this.newManualText = 'EDIT_PORTAL_MANUAL';
                break;
            case 'business':
                this.newManualText = 'EDIT_BUSINESS_MANUAL';
                break;
        }
        this.changeManualList(this.manualType);
    }

    changeManualList(type:any) {
        let manualList:any = [];
        switch (type) {
            case 'common':
                manualList = this.manualList;
                break;
            case 'portal':
                manualList = this.portalManualList;
                break;
            case 'business':
                manualList = this.businessManualList;
                break;
        }
        this.manuals = manualList;
    }

    BindManualList(type:any) {
        this.manualList = this.route.snapshot.data['manualList'];

        this.portalManualList = this.route.snapshot.data['portalManualList'];

        this.businessManualList = this.route.snapshot.data[
            'businessManualList'
        ];

        let manualList: any = [];
        switch (type) {
            case 'common':
                manualList = this.manualList;
                break;
            case 'portal':
                manualList = this.portalManualList;
                break;
            case 'business':
                manualList = this.businessManualList;
                break;
        }
        this.manuals = manualList;
    }

    BindUpdatedList() {
        this.manualList = [];
        this.portalManualList = [];
        this.businessManualList = [];
        let levelId = 0;
        let levelKey = 0;
        switch (BaseServices.UserRole) {
            case 'Admin':
                levelId = 1;
                levelKey = BaseServices.ApplicationId;
                break;
            case 'Portal':
                levelId = 2;
                levelKey = +BaseServices.PortalId;
                break;
            case 'Editor':
                levelId = 3;
                levelKey = BaseServices.BusinessId;
                break;
        }

        if (BaseServices.UserRole === 'Admin') {
            this.subscriptions.push(this.manualService
                .getCommonManuallist(BaseServices.ApplicationId)
                .subscribe(result => {
                    this.manualList = result;
                    this.changeManualList(this.manualType);
                }));
                this.subscriptions.push(this.manualService
                .getPortalManuallist(BaseServices.ApplicationId)
                .subscribe(result => {
                    this.portalManualList = result;
                    this.changeManualList(this.manualType);
                }));

                this.subscriptions.push(this.manualService
                .getBusinessManuallist(BaseServices.ApplicationId)
                .subscribe(result => {
                    this.businessManualList = result;
                    this.changeManualList(this.manualType);
                }));
        } else if (BaseServices.UserRole === 'Portal') {
            this.subscriptions.push(this.manualService
                .getCommonManuallistByPortalLogin(
                    +BaseServices.PortalId
                )
                .subscribe(result => {
                    this.manualList = result;
                    this.changeManualList(this.manualType);
                }));
        } else if (BaseServices.UserRole === 'Editor') {
            this.subscriptions.push(this.manualService
                .getCommonManuallistByBusinessLogin(BaseServices.BusinessId)
                .subscribe(result => {
                    this.manualList = result;
                    this.changeManualList(this.manualType);
                }));
        }
    }

    onManualEdit(manual: ManualView) {
        this.showDialog = true;
        this.manual = manual;
        switch (this.manualType) {
            case 'common':
                if (this.manual.Id !== 0) {
                    this.newManualText = 'EDIT_COMMON_MANUAL';
                } else {
                    this.newManualText = 'NEW_COMMON_MANUAL';
                }
                break;
            case 'portal':
                if (this.manual.Id !== 0) {
                    this.newManualText = 'EDIT_PORTAL_MANUAL';
                } else {
                    this.newManualText = 'NEW_PORTAL_MANUAL';
                }
                break;
            case 'business':
                if (this.manual.Id !== 0) {
                    this.newManualText = 'EDIT_BUSINESS_MANUAL';
                } else {
                    this.newManualText = 'NEW_BUSINESS_MANUAL';
                }
                break;
        }
    }

    onManualDelete(id: number) {
        if (this.manualType === 'common') {
            this.subscriptions.push(this.manualService
                .DeleteAppManualLevel(id)
                .subscribe(result => {
                    if (result) {
                        this.BindUpdatedList();
                        this.toasterComponent.callToastDlt();
                    }
                }));
        } else if (this.manualType === 'portal') {
            this.subscriptions.push(this.manualService
                .DeletePortalManualLevel(id)
                .subscribe(result => {
                    if (result) {
                        this.BindUpdatedList();
                        this.toasterComponent.callToastDlt();
                    }
                }));
        } else if (this.manualType === 'business') {
            this.subscriptions.push(this.manualService
                .DeleteBusinessManualLevel(id)
                .subscribe(result => {
                    if (result) {
                        this.BindUpdatedList();
                        this.toasterComponent.callToastDlt();
                    }
                }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
