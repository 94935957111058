import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from 'src/app/kuba/shared';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBaseModule } from 'src/app/kuba/common/common.module';
import { ELComponent } from './el.component';
import { ELListResolver, ELFeaturesResolver, ELEditResolver, ELListByUserResolver } from './services/el.resolver';
import { ELService } from './services/el.service';
import { ELListHeaderComponent } from './components/el-list-header.component';
import { ELListComponent } from './components/el-list.component';
import { ELViewComponent } from './components/el-view.component';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { ELFormComponent } from './components/el-form.component';

@NgModule({
    imports: [
        RouterModule,
        AppSharedModule,
        TranslateModule,
        CommonBaseModule,
        EcplTabNavigationModule,
        KubaExportModule,
        EcplDocumentExplorerModule,
    ],
    exports: [

    ],
    declarations: [
        ELComponent,
        ELListHeaderComponent,
        ELListComponent,
        ELViewComponent,
        ELFormComponent,
    ],
    providers: [
        ELService,
        ELListResolver,
        ELEditResolver,
        ELFeaturesResolver,
        ELListByUserResolver
    ],
})
export class ELModule { }
