import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Trash } from '../models/trash';
import { TrashServices } from './trash.service';

@Injectable()
export class TrashListResolver implements Resolve<Trash[]> {

    constructor(private backend: TrashServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Trash[]> {
        return this.backend
            .list();
    }
}

@Injectable()
export class TrashEditResolver implements Resolve<Trash> {
    constructor(private backend: TrashServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.get(+route.params['aid']);
    }
}