<div class="card-body">
    <div class="page-title">
        <span translate>ASSESSMENT_OF_AVAILABLE_DOCUMENTATION</span>
    </div>
    <div *ngIf="editMode">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="3">
                        <span translate>REVIEW_OF_AVAILABLE_DOCUMENTATION</span>
                    </th>
                </tr>
                <tr>
                    <th><span translate></span></th>
                    <th><span translate>DOCUMENTATION_BY_OWNER/USER</span></th>
                    <th><span translate>COMMENTS</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of DocumentReview">
                    <td class="col-button" style="width:8%">
                        <button type="button" class="btn btn-icon" (click)="addItem(item)" title="Add Row">
                            <span class="icon ic-sm icon-add"></span>
                        </button>
                        <button *ngIf="DocumentReview.length > 1" type="button" class="btn btn-icon" (click)="deleteItem(item)" title="Delete Row">
                            <span class="icon ic-sm icon-trash"></span>
                        </button>
                    </td>
                    <td style="width:46%;padding: 0px;">
                        <input  style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text" class="form-control" [(ngModel)]="item.name" />
                    </td>
                    <td style="width:46%;padding: 0px;">
                        <input  style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text" class="form-control" [(ngModel)]="item.comments" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!editMode">
        <table class="table table-bordered"  style="table-layout: fixed">
            <thead>
                <tr>
                    <th colspan="2">
                        <span translate>REVIEW_OF_AVAILABLE_DOCUMENTATION</span>
                    </th>
                </tr>
                <tr>
                    <th><span translate>DOCUMENTATION_BY_OWNER</span></th>
                    <th><span translate>COMMENTS</span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of DocumentReview">
                    <td><span>{{item.name}}</span></td>
                    <td><span>{{item.comments}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<toaster-component></toaster-component>