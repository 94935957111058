import { Component, OnInit } from '@angular/core';


@Component({
    templateUrl: 'firstKin.component.html'
})
export class FirstKinComponent implements OnInit {
    constructor() {}
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}

