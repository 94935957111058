<div class="main-content">
    <div class="main-heading" translate>
        TIME_LOG
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view">
                <!-- Table starts -->
                <p-table [value]="timeLog" #timeLogTable [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'ORGANISATION_NUMBER' | translate}}</th>
                            <th>{{'NAME' | translate}}</th>
                            <th>{{'DATE_BIRTH' | translate}}</th>
                            <th>{{'HSE_CARD' | translate}}</th>
                            <th>{{'DATE' | translate}}</th>
                            <th>{{'TIME' | translate}}</th>
                            <th>{{'STATUS' | translate}}</th>
                            <th>{{'GPS_LOCATION'|translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>
                                <p-dropdown [options]="statuses" (onChange)="onStatusChanged($event, ed)" #ed
                                  styleClass="p-column-filter" placeholder="Select" [filterBy]="null"
                                  [showClear]="true">
                                    <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                                    </ng-template>
                                </p-dropdown>
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-log>
                        <tr>
                            <td>
                                <span>{{log.OrganizationNumber}}</span>
                            </td>
                            <td>
                                <span>{{log.Name}}</span>
                            </td>
                            <td>
                                <span>{{log.DateOfBirth}}</span>
                            </td>
                            <td>
                                <span>{{log.HSECardNumber}}</span>
                            </td>
                            <td>
                                <span>{{log.Date | date : 'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{log.Time}}</span>
                            </td>
                            <td>
                                <span>{{log.ModeName}}</span>
                            </td>
                            <td>
                                <span>{{log.Address}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="8">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </div>
</div>