import { TimeLogService } from 'src/app/kuba/log/services/log.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class TimeLogResolver implements Resolve<any> {
  constructor(private backend: TimeLogService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAll();
  }
}