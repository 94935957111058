import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroInstallatioForms } from '../../models/electrofeatures';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';

@Component({
    selector: 'installation-form-overview',
    templateUrl: 'installation-form-overview.component.html'
})

export class InstallationFormOverviewComponent implements OnInit {

    enabledForms: any[];
    treeData: any[];
    selectedForm: any;
    activeFolderForms: any[];
    isGuestHideButton = true;
    additionalData: any;
    upConfig: UploaderConfig;
    uploadedFiles: any;
    UploadableFile: any;
    triggerUploader = false;
    editMode = false;
    isShowAllFile: boolean;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute,
        public router: Router,
        private translate: TranslateService) {
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        this.upConfig = {
            title: 'ALL_FILES',
            viewSwitch: false,
            editMode: false,
            windowedHeight: true,
            showUserMeta: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
            showSearchFilter: true,
            documentType: "form",
            addtionalData: null,
        };
    }

    ngOnInit() {
        let enabledForms = this.route.snapshot.data['enabledForms'];
        this.treeData = this.route.snapshot.data['treeData'];
        this.subscriptions.push(this.translate.stream('SELECT_INSTALLATION_FORM').subscribe(val => {
            this.enabledForms = [];
            this.enabledForms.push({ label: val.SELECT_FORM, value: null });
            if (enabledForms) {
                enabledForms.forEach(form => {
                    this.enabledForms.push({ label: form.Title, value: form.Id });
                });
            }
        }));
    }

    /**
     * fill out form event.
     */
    onFillOutClick() {
        switch (this.selectedForm) {
            case ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION:
                this.router.navigate([`./../ins/${0}/document-assessment/${0}`], { relativeTo: this.route });
                break;
            case ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION:
                this.router.navigate([`./../ins/${0}/risk-assesment/${0}`], { relativeTo: this.route });
                break;
            case ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW:
                this.router.navigate([`./../ins/${0}/distribution-overview/${0}`], { relativeTo: this.route });
                break;
            case ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW:
                this.router.navigate([`./../ins/${0}/course-overview/${0}`], { relativeTo: this.route });
                break;
            case ElectroInstallatioForms.FIRE_ALARM_SYSTEM:
                this.router.navigate([`./../ins/${0}/fire-alarm-system/${0}`], { relativeTo: this.route });
                break;
            case ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM:
                this.router.navigate([`./../ins/${0}/lighting/${0}`], { relativeTo: this.route });
                break;
        }
    }

    // Events form Tree
    catchTreeEvent(event): void {
        this.triggerUploader = false;
        switch (event.eventName) {

            case 'activate':
                if (event.node.hasChildren) {
                    this.activeFolderForms = event.node.data;
                    this.triggerUploader = true;
                    this.isShowAllFile = true;
                    this.uploadedFiles = event.node.data.nodes;
                    this.upConfig.title = event.node.data.filename;
                    if (event.node.data.translateKey === 'CHECKLIST_UNDER_COMPLETION') {
                        this.upConfig.showDownloadAll = false;
                    } else {
                        this.upConfig.showDownloadAll = true;
                    }
                    this.upConfig.documentType = event.node.data.documentType;
                    this.upConfig.addtionalData = {
                        ApplicationId: BaseServices.ApplicationId,
                        BusinessId: BaseServices.BusinessId,
                        FolderTypeName: event.node.data.documentType,
                        FeatureId: 1,//to avoid file explorer null data error
                        FeatureKey: 1,//to avoid file explorer null data error
                        FeatureName: "ins",
                        ArchiveFolderId: event.node.data.nodeId,
                        userId: BaseServices.UserId
                    };
                }
                break;
            default:
                if (event.eventName !== 'toggleExpanded' && (event.node ? event.node.data.type !== 'folder' : false)) {
                    switch (event.node.data.documentType) {
                        case "DocumentAssessment":
                            this.router.navigate([`./../ins/${0}/document-assessment/${event.node.data.nodeId}`], { relativeTo: this.route });
                            break;
                        case "RiskAssessment":
                            this.router.navigate([`./../ins/${0}/risk-assesment/${event.node.data.nodeId}`], { relativeTo: this.route });
                            break;
                        case "DistributionOverview":
                            this.router.navigate([`./../ins/${0}/distribution-overview/${event.node.data.nodeId}`], { relativeTo: this.route });
                            break;
                        case "CourseOverview":
                            this.router.navigate([`./../ins/${0}/course-overview/${event.node.data.nodeId}`], { relativeTo: this.route });
                            break;
                        case "FireAlarm":
                            this.router.navigate([`./../ins/${0}/fire-alarm-system/${event.node.data.nodeId}`], { relativeTo: this.route });
                            break;
                        case "EmergencyLighting":
                            this.router.navigate([`./../ins/${0}/lighting/${event.node.data.nodeId}`], { relativeTo: this.route });
                            break;
                    }
                }
                break;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}