
export class BusinessProperty {
    Id: number;
    BusinessId: number;
    BusinessPropertyTypeId: number;
    Name: string;
    Number: number;
    Description: string;
    FloorCount: string;
    ApartmentCount: string;
    Address: string;
    City: string;
    Zip: number;
    Status: number;
    Location: string;
    Latitude: string;
    Longitude: string;
    CreatedBy: string;
    ModifiedBy: string;
    CreatedOn: string;
    ModifiedOn: string;
    PropertyObjects: BusinessPropertyAndObject[];
    constructor() {
        this.Status = 1;
        this.PropertyObjects = [];
    }
}

export class BusinessPropertyAndObject {
    BusinessPropertyId: number;
    BusinessPropertyObjectId: number;
    Quantity: number;
    constructor() {
        this.BusinessPropertyId = 0;
        this.BusinessPropertyObjectId = 0;
        this.Quantity = 0;
    }
}

export class BusinessPropertyObject {
    Id: number;
    Name: string;
    Quantity: number;
}

export interface CommonProperty {
    Id: number;
    Name: string;
}

export class BusinessPropertyType implements CommonProperty {
    Id: number;
    Name: string;
    BusinessId: number;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    constructor() {
        this.Status = 1;
    }
}
