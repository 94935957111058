<div class="px-2 charts-wrapper" *ngIf="shouldShow | async">
  <ngx-charts-pie-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="single"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [labels]="showLabels"
    [doughnut]="isDoughnut"
    (select)="onSelect($event)"
    [legendTitle]="legendTitle"
  >
  </ngx-charts-pie-chart>
</div>
