<div class="panel-tabs">
    <nav>
        <ul class="nav nav-pills">
            <li [class.active]="activeTab === 1">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(1)" translate>CLIENT_INFORMATION
                    <span *ngIf="completedTillTab>=1" class="icon ic-sm icon-checkmark text-success ml-2"></span> </a>
            </li>
            <li [class.active]="activeTab === 2">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(2)"
                  [ngClass]="{disabled: completedTillTab<1}" translate>INSTALLATION_INFORMATION
                    <span *ngIf="completedTillTab>=2" class="icon ic-sm icon-checkmark text-success ml-2"></span></a>
            </li>
            <li [class.active]="activeTab === 3">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(3)"
                  [ngClass]="{disabled: completedTillTab<2}" translate>INSTALLATION_CONTACT_PERSON
                    <span *ngIf="completedTillTab>=3" class="icon ic-sm icon-checkmark text-success ml-2"></span>
                </a>
            </li>
            <li [class.active]="activeTab === 4">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(4)"
                  [ngClass]="{disabled: completedTillTab<3}" translate>ASSIGN_USER_ACCESS
                    <span *ngIf="completedTillTab>=4" class="icon ic-sm icon-checkmark text-success ml-2"></span>
                </a>
            </li>
            <li [class.active]="activeTab === 5">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(5)"
                  [ngClass]="{disabled: completedTillTab<4}" translate>CONTRACT_INFORMATION
                    <span *ngIf="completedTillTab>=5" class="icon ic-sm icon-checkmark text-success ml-2"></span>
                </a>
            </li>
            <li [class.active]="activeTab === 6">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(6)"
                  [ngClass]="{disabled: completedTillTab<5}" translate>CONTRACT_SCOPE
                    <span *ngIf="completedTillTab>=6" class="icon ic-sm icon-checkmark text-success ml-2"></span>
                </a>
            </li>
            <li [class.active]="activeTab === 7">
                <a class="nav-link" href="javascript:void(0);" (click)="setActiveTab(7)"
                  [ngClass]="{disabled: completedTillTab<6}" translate>INSPECTION_SCOPE
                    <span *ngIf="completedTillTab>=7" class="icon ic-sm icon-checkmark text-success ml-2"></span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<div>
    <div *ngIf="activeTab === 1">
        <form [formGroup]="clientInfoForm">
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>FIRM_NAME</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="clients" [filter]="true" required formControlName="clientFirm"
                  [style]="{'width':'100%'}" #clientDropdown (onChange)="onClientChanged($event, clientDropdown)">
                </p-dropdown>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>CLIENT_NUMBER</span>
                </label>
                <input type="text" pInputText disabled formControlName="clientNumber" />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>ADDRESS</span>
                </label>
                <input type="text" pInputText disabled formControlName="Address" />
            </div>
            <div class="form-group-25_75">
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>ZIP_CODE</span>
                    </label>
                    <input type="text" pInputText disabled formControlName="ZipCode" />
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>CITY</span>
                    </label>
                    <input type="text" pInputText disabled formControlName="City" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>PHONE_NO</span>
                </label>
                <input type="text" pInputText disabled formControlName="PhoneNumber" />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>EMAIL</span>
                </label>
                <input type="text" pInputText disabled formControlName="Email" />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>CONTACT_PERSON</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="clientContacts" required formControlName="ContactPerson"
                  [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
            <!-- </div> -->
        </form>
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-primary" (click)="setActiveTab(2,true)"
              [disabled]="clientInfoForm.invalid ">
                <span translate>SAVE_NEXT</span>
            </button>
        </div>
    </div>

    <div *ngIf="activeTab === 2">
        <form [formGroup]="installationForm">
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>FIRM_NAME</span>
                    <span class="required">*</span>
                </label>
                <input type="text" pInputText formControlName="InstallationFirm" required />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>ADDRESS</span>
                </label>
                <input type="text" pInputText formControlName="InstallationAddress" />
            </div>
            <div class="form-group-25_75">
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>ZIP_CODE</span>
                    </label>
                    <input type="text" pInputText formControlName="InstallationZip" />
                </div>
                <div class="form-group">
                    <label class="col-form-label">
                        <span translate>CITY</span>
                    </label>
                    <input type="text" pInputText formControlName="InstallationCity" />
                </div>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>HOUSEFOLDER</span>
                </label>
                <input type="text" pInputText formControlName="Housefolder" />
            </div>
        </form>
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-primary" (click)="SameInstallation()">
                <span translate>SAME_AS_CLIENT</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="setActiveTab(3,true)"
              [disabled]="installationForm.invalid ">
                <span translate>SAVE_NEXT</span>
            </button>
        </div>
    </div>

    <div *ngIf="activeTab === 3">
        <form [formGroup]="installationContactForm">
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>NAME</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="clientContacts" required formControlName="Name" [style]="{'width':'100%'}"
                  (onChange)="onContactChange($event)"></p-dropdown>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>PHONE_NO</span>
                </label>
                <input type="text" pInputText disabled formControlName="PhoneNumber" />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>EMAIL</span>
                </label>
                <input type="text" pInputText disabled formControlName="Email" />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>FUNCTION</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="contactFunction" required formControlName="Function" [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
        </form>
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-primary" (click)="SameContact()">
                <span translate>SAME_AS_CLIENT</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="setActiveTab(4,true)"
              [disabled]="installationContactForm.invalid ">
                <span translate>SAVE_NEXT</span>
            </button>
        </div>
    </div>

    <div *ngIf="activeTab === 4">
        <div class="page-title">
            <span translate>CLIENT_CONTACT_PERSONS</span>
        </div>
        <div class="table-view">
            <!-- Table starts -->
            <p-table #dt [value]="electroClientUsers" dataKey="Id" [(selection)]="selectedClientUser" scrollable="true"
              scrollHeight="200px">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 38px;">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>{{'NAME' | translate}}</th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-client>
                    <tr>
                        <td style="width: 55px;">
                            <p-tableCheckbox id="selectedOrders" [value]="client">
                            </p-tableCheckbox>
                        </td>
                        <td>
                            <span>{{client.Name}}</span>
                        </td>
                    </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="2">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table Ends -->
        </div>
        <div class="page-title">
            <span translate>EMPLOYEES</span>
        </div>
        <div class="table-view">
            <!-- Table starts -->
            <p-table #dt [value]="contractWorkers" dataKey="Id" [(selection)]="selectedWorker" scrollable="true"
              scrollHeight="200px">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 38px;">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>{{'NAME' | translate}}</th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-client>
                    <tr>
                        <td style="width: 38px;">
                            <p-tableCheckbox id="selectedOrders" [value]="client">
                            </p-tableCheckbox>
                        </td>
                        <td>
                            <span>{{client.Name}}</span>
                        </td>
                    </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="2">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table Ends -->
        </div>

        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-primary" (click)="setActiveTab(5,true)">
                <span translate>SAVE_NEXT</span>
            </button>
        </div>
    </div>

    <div *ngIf="activeTab === 5">
        <form [formGroup]="electroForm">
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>CONTRACT_NO</span>
                    <span class="required">*</span>
                </label>
                <div class="phone-field">
                    <input type="text" pInputText disabled value="{{clientInfoForm.value.clientNumber}}" />
                    <input type="text" pInputText (keypress)="onMobileNumber($event,20)"
                      formControlName="ContractNumber" />
                </div>
                <control-messages [control]="electroForm.controls['ContractNumber']"></control-messages>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>CONTRACT_NAME</span>
                    <span class="required">*</span>
                </label>
                <input type="text" pInputText formControlName="ContractName" />
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>CONTRACT_RESPONSIBLE</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="responsiblePerson" required formControlName="ResponsibleUserId"
                  [style]="{'width':'100%'}" #responsible>
                </p-dropdown>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>STATUS</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="status" required formControlName="Status" [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
            <div class="form-group">
                <label class="col-form-label">
                    <span translate>VISIBILIE_CUSTOMER</span>
                    <span class="required">*</span>
                </label>
                <p-dropdown [options]="visibility" formControlName="Visibilty" [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
        </form><br>
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-primary" (click)="SaveElectro(6)" [disabled]="electroForm.invalid ">
                <span translate>SAVE_NEXT</span>
            </button>
        </div>
    </div>

    <div *ngIf="activeTab === 6">
        <!-- Table starts -->
        <p-table [value]="controlscope" [(selection)]="selectedScope">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'CONTROL_AREA' | translate}}</th>
                    <th>{{'TYPE_OF_INSTALLATION' | translate}}</th>
                    <th>{{'GRID_SYSTEM' | translate}}</th>
                    <th>{{'YEAR_BUILT' | translate}}</th>
                    <th>{{'BUILT_AFTER' | translate}}</th>
                    <th>{{'YEAR_UPGREADED' | translate}}</th>
                    <th>{{'OPTION' | translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-el>
                <tr>
                    <td>
                        <span>{{el.BuildingNumber | translate}}</span>
                    </td>
                    <td>
                        <span>{{el.InstallationTypeId | translate}}</span>
                    </td>
                    <td>
                        <span>{{el.GridSystemId | translate}}</span>
                    </td>
                    <td>
                        <span>{{el.YearBuilt}}</span>
                    </td>
                    <td>
                        <span>{{el.InstallationBuildAfterId | translate}}</span>
                    </td>
                    <td>
                        <span>{{el.YearUpgraded}}</span>
                    </td>
                    <td>
                        <button type="button" class="btn btn-icon" (click)="editControlScope(el)"
                          title="{{'EDIT'|translate}}">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button type="button" class="btn btn-icon" (click)="deleteControlScope(el.Id)"
                          title="{{'DELETE'|translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="7">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-primary" (click)="setActiveTab(7,true)">
                <span translate>SAVE_NEXT</span>
            </button>
            <a outerLink="create" (click)="addControlScope()" class="btn btn-success">
                <span class="icon ic-sm icon-add"></span>
                <span translate>ADD</span>
            </a>
        </div>
    </div>

    <div *ngIf="activeTab === 7">
        <!-- Table starts -->
        <p-table [value]="inspectionscope" [tableStyle]="{'overflow': 'visible'}">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'CONTENT_INTERNAL_CONTROL' | translate}}</th>
                    <th>{{'INTERVAL' | translate}}</th>
                    <th>{{'LAST_PERFORMED' | translate}}</th>
                    <th>{{'NEXT_INSPECTION' | translate}}</th>
                    <th>{{'OPTION' | translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-el>
                <tr>
                    <td>
                        <span>{{el.Name}}</span>
                    </td>
                    <td class="overflow-enable">
                        <p-dropdown [(ngModel)]="el.Interval" [options]="interval" [style]="{'width':'100%'}"
                          [ngModelOptions]="{standalone: true}"></p-dropdown>
                        <span class="required">*</span>
                    </td>
                    <td class="overflow-enable">
                        <my-date-picker [style]="{'width':'80px'}" [options]="defaultDateOptions"
                          [(ngModel)]="el.LastPerformed" [ngModelOptions]="{standalone: true}">
                        </my-date-picker>
                    </td>
                    <td class="overflow-enable">
                        <my-date-picker [style]="{'width':'80px'}" [options]="defaultDateOptions"
                          [(ngModel)]="el.NextInspection" [ngModelOptions]="{standalone: true}">
                        </my-date-picker>
                    </td>
                    <td>
                        <button type="button" class="btn btn-icon" (click)="deleteInspection(el.Id)"
                          title="{{'DELETE'|translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="5">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
        <div class="action-btns-wrapper">
            <button [disabled]="onInspectionChange(inspectionscope)" type="button" class="btn btn-primary"
              (click)="SaveInspection('save')">
                <span translate>SAVE_NEXT</span>
            </button>
            <a outerLink="create" (click)="addInspectionScope()" class="btn btn-success">
                <span class="icon ic-sm icon-add"></span>
                <span translate>ADD</span>
            </a>
        </div>
    </div>

</div>
<p-dialog header="{{'ADD_NEW'|translate}}" [(visible)]="addControl" [modal]="true"
  [contentStyle]="{'overflow':'visible'}" [style]="{width: '800px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="controlscoprForm" *ngIf="controlscoprForm">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <div class="col-sm-6">
                        <label for="BuildingId">
                            <span translate>CONTROL_AREA</span>
                            <span class="required">*</span>
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" pInputText formControlName="BuildingId" id="BuildingId"
                          class="ex-full-width" />
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <div class="col-sm-6">
                        <label for="TypeOfInstallation">
                            <span translate>TYPE_OF_INSTALLATION</span>
                            <span class="required">*</span>
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <p-dropdown [options]="installationType" formControlName="TypeOfInstallation"
                          [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <div class="col-sm-6">
                        <label for="GridSystem">
                            <span translate>GRID_SYSTEM</span>
                            <!-- <span class="required">*</span> -->
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <p-dropdown [options]="gridSystem" formControlName="GridSystem" [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <div class="col-sm-6">
                        <label for="YearBuilt">
                            <span translate>YEAR_BUILT</span>
                            <span class="required">*</span>
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" pInputText formControlName="YearBuilt" id="YearBuilt" class="ex-full-width"
                          (keypress)="checkNumber($event)" />
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <div class="col-sm-6">
                        <label for="BuiltAfter">
                            <span translate>INSTALLATION_BUILT_AFTER</span>
                            <span class="required">*</span>
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <p-dropdown [options]="installationBuildAfter" formControlName="BuiltAfter"
                          [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                </div>
                <br>
                <div class="form-group">
                    <div class="col-sm-6">
                        <label for="YearUpgraded">
                            <span translate>YEAR_UPGREADED</span>
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" pInputText formControlName="YearUpgraded" id="YearUpgraded"
                          class="ex-full-width" (keypress)="checkNumber($event)" />
                    </div>
                </div>
                <br>
                <div class="action-btns-wrapper">
                    <div class="row">
                        <div class="col-sm-6">
                            <a class="btn btn-outline-secondary" (click)="closeControlScope()">
                                <span class="icon ic-xs icon-back"></span>
                                <span translate>BACK_LIST</span>
                            </a>
                            <button class="btn btn-primary" type="button" (click)="SaveInstallation('SAVE')"
                              [disabled]="!controlscoprForm.valid">
                                <span translate>SAVE</span>
                            </button>
                            <button class="btn btn-outline-success" type="button" (click)="SaveInstallation('ADD')"
                              [disabled]="!controlscoprForm.valid">
                                <span translate>SAVE_AND_ADD_NEW</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</p-dialog>
<p-dialog header="{{'ADD_NEW'|translate}}" [(visible)]="addInspection" [modal]="true" [style]="{width: '800px'}"
  [resizable]="false" [draggable]="false">
    <!-- Table starts -->
    <p-table #dtIntTask [value]="internalTask" dataKey="Id" [(selection)]="selectedTask" styleClass="no-header">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 38px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>{{'NAME' | translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-client>
            <tr>
                <td style="width: 55px;">
                    <p-tableCheckbox id="selectedOrders" [value]="client">
                    </p-tableCheckbox>
                </td>
                <td>
                    <span>{{client.Name}}</span>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="2">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
    <div class="action-btns-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <a class="btn btn-outline-secondary" (click)="closeInspectionScope()">
                    <span class="icon ic-xs icon-back"></span>
                    <span translate>BACK_LIST</span>
                </a>
                <button class="btn btn-primary" type="button" (click)="SaveInspection('check')">
                    <span translate>SAVE</span>
                </button>
            </div>
        </div>
    </div>
</p-dialog>
<p-dialog header="{{'CONFIRMATION'|translate}}" [(visible)]="displayDeleteDailog" [modal]="true"
  [style]="{width: '800px'}" [resizable]="false" [draggable]="false">
    <span translate>DELETE_BUILDINGS</span>
    <p-footer>
        <button type="button" pButton icon="pi-check" label="{{'SWITCH_YES'|translate}}"
          (click)="deleteBuildingFolder(0)"></button>
        <button type="button" pButton icon="pi-times" label="{{'SWITCH_NO'|translate}}"
          (click)="deleteBuildingFolder(2)"></button>
    </p-footer>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>