import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Business } from './../../accesslog/models/accesslog';
import { InternalControlServices } from './../services/internal-control.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { InternalControl, InternalControlUser, InternalControlUsers } from '../models/internal-control';
import { ConfirmationService } from 'primeng/api';
import { Rights } from 'src/app/_models';
import { HelperService } from 'src/app/_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { InternalControlEditComponent } from './internal-control-edit.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'internal-control-list',
    templateUrl: 'internal-control-list.component.html'
})
export class InternalControlListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    internalControlData: InternalControl;
    internalControlData1: InternalControlUsers;
    isAdministrateIC = true;
    isHideDeleteIcon = true;
    additionalData: any;
    isDisabled:boolean;
    private subscriptions: Subscription[] = [];
    portals: any;
    list: any = [];
    showDialog = false;
    cols: any;
    isGuest = true;
    /**
     * 

     * @param internalControlService {{InternalControlServices}}
     * @param confirmationService {{ConfirmationService}}
     * @param route {{ActivatedRoute}}
     * @param intrnlcntrlsrvc {{InternalControlServices}}
     */
    constructor(
        private internalControlService: InternalControlServices,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        public intrnlcntrlsrvc: InternalControlServices,
        private translate: TranslateService) {
        this.additionalData = {
            fileName: 'Internal Control List',
            header: 'Internal Control List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name of the task', dataKey: 'Title' },
                { title: 'Description', dataKey: 'Description' },
                { title: 'Assigned Access', dataKey: 'UserCount' },
                { title: 'For Editors Only', dataKey: 'IsEditorOnly' },
                { title: 'Deviations In Followup', dataKey: 'ShowDeviation' }
            ]
        };
    }
    /**
     * get internal control list
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.isDisabled = true;
        let internalControl = this.route.snapshot.data['list'];
        if (internalControl != null) {
            this.internalControlData = internalControl;
        }
        this.onAdministrateInternalControl();
        let internalControlUser = this.route.snapshot.data['Namelist'];
        this.internalControlData1 = internalControlUser;
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
        }
    }  


    onHide() {
        this.showDialog = false;
    }

    showPortalInfo(portalInfo: any[]) {
        this.showDialog = true;
        this.list = [];
        this.list = portalInfo;
    }
    /**
     * get internal control list
     */
    getAllControls() {
        this.subscriptions.push(this.intrnlcntrlsrvc.getAll(BaseServices.BusinessId)
            .subscribe(result => this.internalControlData = result));

    }
    /**
     * delete internal control
     * @param control {{any}}
     */
    confirm(control) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.internalControlService.deleteControl(control).then(result => {
                    if (result) {
                        this.toasterComponent.callToastDlt();
                        this.getAllControls();
                    }
                });
            }
        });
    }
    /**
     * Edit & update Ic based on administrateIC rights
     */
    onAdministrateInternalControl() {
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_CONTROL;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.isHideDeleteIcon = false;
            this.isAdministrateIC = BaseServices.checkUserRights(userRightsId, role);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
