export class EmployeeDocumentNode {
    Id: number;
    EmployeeId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: string;
    IsPrivateDocument: boolean;
    Status: string;
    CreatedBy: {
        Id: number;
        Name: string;
    };
    CreatedOn: string;
    ModifiedBy: {
        Id: number;
        Name: string;
    };
    ModifiedOn: string;
}

export class EmployeeDocumentUploadable {
    Id: number;
    EmployeeId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: string;
    IsPrivateDocument: boolean;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
}

