<div class="tab-content">
    <div class="page-title">
        <span translate>ADD_INSTRUMENT</span>
    </div>
    <form [formGroup]="measureProtocolInstrumentForm">
        <div class="form-group">
            <label for="Title">
                <span translate>TYPE</span>
                <span class="required">*</span>
            </label>
            <div class="input-btn-group">
                <p-dropdown [filter]="true" formControlName="InstrumentTypeId" [options]="types"
                    [style]="{'width':'100%'}"></p-dropdown>
                <button class="btn btn-outline-primary" type="button" (click)="addType = !addType">
                    <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
            </div>
            <control-messages [control]="measureProtocolInstrumentForm.controls['InstrumentTypeId']"></control-messages>
        </div>
        <div class="form-group">
            <label for="Title">
                <span translate>MODEL</span>
                <span class="required">*</span>
            </label>
            <div class="input-btn-group">
                <p-dropdown [filter]="true" formControlName="InstrumentModelId" [options]="models"
                    [style]="{'width':'100%'}"></p-dropdown>
                <button class="btn btn-outline-primary" type="button" (click)="addModel = !addModel">
                    <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
            </div>
            <control-messages [control]="measureProtocolInstrumentForm.controls['InstrumentModelId']">
            </control-messages>
        </div>
        <div class="form-group">
            <label>
                <span translate>CALIBRATED_DATE</span>
                <span class="required">*</span>
            </label>
            <div>
                <my-date-picker [style]="{'width':'160px'}" [options]="expiryDateOpitons"
                    formControlName="CalibratedDate" placeholder="{{'Select Calibrated Date'|translate}}">
                </my-date-picker>
            </div>
            <control-messages [control]="measureProtocolInstrumentForm.controls['CalibratedDate']"></control-messages>
        </div>
        <div class="action-btns-wrapper">
            <button class="btn btn-outline-secondary" (click)="gotoBack()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="measureProtocolInstrumentForm.invalid"
                (click)="saveChanges()">
                <span translate>SAVE_CREATE_NEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="measureProtocolInstrumentForm.invalid"
                (click)="saveChanges()">
                <span translate>SAVE</span>
            </button>
        </div>
    </form>
</div>

<p-dialog header="{{'TYPE' | translate}}" (onHide)="oninstrumentTypeFormHide()" [(visible)]="addType" [modal]="true"
    [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="instrumentTypeForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>TYPE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="Name" class="label-input form-control" required>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType"
                (click)="viewInstrumentType()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="instrumentTypeForm.invalid" (click)="saveType()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="viewType">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #et [value]="editTypes" [rows]="5" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'Type' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="et.filter($event.target.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-categoryType>
                        <tr>
                            <td>
                                <span>{{categoryType.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                    (click)="editType(categoryType)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                    (click)="deleteType(categoryType)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'MODEL' | translate}}" (onHide)="oninstrumentModelFormHide()" [(visible)]="addModel" [modal]="true"
    [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="instrumentModelForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>MODEL</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="Name" class="label-input form-control" required>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType"
                (click)="viewModelType()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="instrumentModelForm.invalid"
                (click)="saveModel()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="viewType">
            <div class="table-view">
                <p-table #type [value]="editModels" [rows]="5" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'Type' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="type.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter">
                            </th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-business>
                        <tr>
                            <td>{{business.Name}}</td>
                            <td>
                                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                    (click)="editType(business)">
                                    <i class="icon ic-sm icon-pencil-tip" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn btn-icon" (click)="deleteType(business.Id)"
                                    title="{{'DELETE' | translate}}">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>