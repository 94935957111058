import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { BaseServices } from './../../kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { VehicleConsumptionService } from './../services/vehicle-consumption.services';
import { ConsumptionListDto } from './../models/vehicle-consumption';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicle-consumption-list.component.html'
})
export class VehicleConsumptionListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    consumptionsList: any[];
    selectedConsumption = [];
    isDeleteConsumption = false;
    additionalData: any;
    parentId: number;
    private subscriptions: Subscription[] = [];

    /**
     * constructor
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param vehicleConsumptionService {VehicleConsumptionService}
     */
    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private vehicleConsumptionService: VehicleConsumptionService,
        private location: Location,
        private translate: TranslateService
    ) {
        this.additionalData = {
            fileName: 'Vehicle consumption list',
            header: 'Vehicle consumption list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Measure Date', dataKey: 'Date' },
                { title: 'Brand', dataKey: 'BrandName' },
                { title: 'Model', dataKey: 'ModelName' },
                { title: 'Registration Number', dataKey: 'RegistrationNo' },
                { title: 'Previous KM', dataKey: 'PreviousKm' },
                { title: 'Estimated KM', dataKey: 'Estimatedkm' },
                { title: 'Todays KM', dataKey: 'TodaysKm' },
                { title: 'No of Liter Used', dataKey: 'LitersUsed' },
                { title: 'No of Liter Used per 10 km', dataKey: 'NoOfLiters' },
            ]
        };
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.parentId = this.route.snapshot.parent.parent.params['id'];
        this.consumptionsList = <any[]>this.route.snapshot.data['list'];
    }

    /**
     * consumption multi delete
     */
    deleteConsumption(selectedConsumption) {
        let consumptionId = [];
        this.selectedConsumption.forEach(element => {
            consumptionId.push(element.Id);
        });
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleConsumptionService
                    .deleteVehicleConsumption(consumptionId)
                    .subscribe(result => {
                        // re-bind the consumption table
                        this.toasterComponent.callToastDlt();
                        if (result) {
                            this.refreshList();
                        }
                    }));
            },
            reject: () => {
                this.selectedConsumption = [];
                this.refreshList();
            }
        });
    }

    refreshList() {
        this.subscriptions.push(this.vehicleConsumptionService
            .getAllVehicleConsumption(this.parentId)
            .subscribe(res => {
                this.consumptionsList = res;
            }));
    }
    back() {
        this.location.back();
    }
    /**
     *row select event
     */
    onRowSelect(selectedConsumption) {
        if (this.selectedConsumption.length > 0) {
            this.isDeleteConsumption = true;
        } else {
            this.isDeleteConsumption = false;
        }
    }
    /**
     *row unselect event
     */
    onRowUnSelect(selectedConsumption) {
        if (this.selectedConsumption.length > 0) {
            this.isDeleteConsumption = true;
        } else {
            this.isDeleteConsumption = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
