<div class="tab-content">
    <div class="page-title">
        <span translate>UNWANTED_INCIDENT_REPORT</span>
        <span>(RUH)</span>
        <span class="page-actions">
            <span class="page-actions" *ngIf="isShowNewButton">
                <a routerLink="new" [routerLink]="['./../new']" class="btn btn-success" routerLinkActive="active">
                    <span class="icon ic-sm icon-add"></span>
                    <span translate>NEW</span>
                </a>
            </span>
        </span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table #ruhTable [value]="ruhDetails" [rows]="10" dataKey="Title" [paginator]="true" [pageLinks]="5"
            [rowsPerPageOptions]=" rowsPerPageOptions" (onFilter)="ruhFilterChange($event)"
            (onPage)="ruhPageChange($event)" [responsive]="true" expandableRows="true" [(selection)]="selectedRuh"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="filter-controls-wrapper" *ngIf="isFilter">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>DEPARTMENT</span>
                                    </label>
                                    <p-dropdown [(ngModel)]="selectedDept" [options]="departments"
                                        [style]="{'width':'100%'}" filter="filter" placeholder="{{'SELECT'|translate}}"
                                        (onChange)="onDepartmentChanged(depControl)" #depControl></p-dropdown>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="CreatedFrom" class="col-form-label">
                                        <span translate>CREATED_FROM</span>
                                    </label>
                                    <div>
                                        <my-date-picker [(ngModel)]="createdFromDate" [options]="DateOptions"
                                            placeholder="{{'SELECT_START_DATE'|translate}}"
                                            (dateChanged)="onCreatedFromChanged($event)"></my-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="CreatedTo" class="col-form-label">
                                        <span translate>CREATED_TO</span>
                                    </label>
                                    <div>
                                        <my-date-picker [(ngModel)]="createdToDate" [options]="DateOptions"
                                            placeholder="{{'SELECT_END_DATE'|translate}}" filter="filter"
                                            (dateChanged)="onCreatedToChanged($event)"></my-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                                    </label>
                                    <div>
                                        <p-dropdown [(ngModel)]="selectedResponsibleForExecution" type="text"
                                            [options]="vresperson" (onChange)="onExecutionChanged(executionDropdown)"
                                            placeholder="{{'SELECT'|translate}}" filter="filter" #executionDropdown
                                            [style]="{'width':'100%'}"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>SORTING</span>
                                    </label>
                                    <div>
                                        <p-dropdown type="text" [(ngModel)]="selectedSorting" [options]="Sorting"
                                            (onChange)="onSortingChange($event,SortingDropdown)"
                                            placeholder="{{'SELECT'|translate}}" filter="filter" #SortingDropdown
                                            [style]="{'width':'100%'}"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="OccurFrom" class="col-form-label">
                                        <span translate>DATE_OF_OCCURANCE_FROM</span>
                                    </label>
                                    <div>
                                        <my-date-picker [(ngModel)]="dateOfOccuranceFrom" [options]="DateOptions"
                                            placeholder="{{'SELECT_START_DATE'|translate}}" filter="filter"
                                            (dateChanged)="onOccurFromChanged($event)"></my-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="OccurTo" class="col-form-label">
                                        <span translate>DATE_OF_OCCURANCE_TO</span>
                                    </label>
                                    <div>
                                        <my-date-picker [(ngModel)]="dateOfOccuranceTo" [options]="DateOptions"
                                            placeholder="{{'SELECT_END_DATE'|translate}}"
                                            (dateChanged)="onOccurToChanged($event)"></my-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                                    </label>
                                    <div>
                                        <p-dropdown type="text" [(ngModel)]="selectedResponsibleForFollowUp"
                                            [options]="followup" (onChange)="onFollowupChanged(followUpDropdown)"
                                            placeholder="{{'SELECT'|translate}}" filter="filter" #followUpDropdown
                                            [style]="{'width':'100%'}"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>TYPE_OF_CASE</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <p-dropdown [(ngModel)]="selectedTypeOfCase" [options]="Case"
                                            [style]="{'width':'150px'}" (onChange)="onCaseChanged(caseDropdown)"
                                            placeholder="{{'SELECT'|translate}}" #caseDropdown filter="filter">
                                        </p-dropdown>
                                        <button type="button" class="btn btn-outline-primary"
                                            (click)="addtypeOfCase=!addtypeOfCase">
                                            <i class="pi pi-plus" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>H1/H2/N/FF/UFT</span>
                                    </label>
                                    <p-dropdown [(ngModel)]="selectedH1H2NFFUFT" [options]="Incidentcondition"
                                        [style]="{'width':'100%'}" filter="filter" placeholder="{{'SELECT'|translate}}"
                                        #incidentdropdown (onChange)="onIncidentconditionChanged(incidentdropdown)">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="endDate" class="col-form-label">
                                        <span translate>CREATED_BY</span>
                                    </label>
                                    <div>
                                        <p-dropdown [(ngModel)]="selectedCreatedBy" [options]="createdBy"
                                            [style]="{'width':'100%'}"
                                            (onChange)="onCreatedByChange($event,createdByDropdown)"
                                            placeholder="{{'SELECT'|translate}}" filter="filter" #createdByDropdown
                                            styleClass="ui-column-filter"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <form>
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            <span translate>TOPICS</span>
                                        </label>
                                        <div class="input-btn-group">
                                            <p-dropdown [disabled]="selectedCaseId === null" [options]="Topics"
                                                [style]="{'width':'150px'}" (onChange)="onTopicChanged(topicDropdown)"
                                                placeholder="{{'SELECT'|translate}}" #topicDropdown filter="filter">
                                            </p-dropdown>
                                            <button [disabled]="selectedCaseId === null" type="button"
                                                class="btn btn-outline-primary" (click)="addOfTopic=!addOfTopic">
                                                <i class="pi pi-plus" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 pull-right">
                                <button class="btn btn-outline-primary" type="submit" (click)="clearFilters()">
                                    <span class="icon ic-sm icon-reset"></span>
                                    <span translate>REFRESH</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-options">
                        <div class="pull-right">
                            <div class="action-btns-group">
                                <button class="btn p-3 filter-toggle" (click)="toggleFilter()">
                                    <span class="icon ic-sm icon-filter"></span>
                                    <span translate>FILTER</span>
                                </button>
                            </div>
                            <div class="action-btns-group">
                                <kuba-export [printMode]=1 [reportData]="ruhTable.filteredValue || ruhTable.value"
                                    (loading)='loader($event)' [additionalData]="additionalData"></kuba-export>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 55px;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th></th>
                    <th></th>
                    <th>{{'NUMBER_NO'|translate}}</th>
                    <th pSortableColumn="Title">{{'TITLE'|translate}}</th>
                    <th pSortableColumn="EventDate">{{'EVENT_DATE'|translate}}</th>
                    <th pSortableColumn="EventTime">{{'EVENT_TIME'|translate}}</th>
                    <th pSortableColumn="CreatedOn">{{'CREATED_ON'|translate}}</th>
                    <th><span>H1</span></th>
                    <th><span>H2</span></th>
                    <th>{{'N' |translate}}</th>
                    <th>{{'FF' |translate}}</th>
                    <th>{{'UFT' |translate}}</th>
                    <th>{{'DEADLINE'|translate}}</th>
                    <th>{{'STATUS'|translate}}</th>
                    <th pSortableColumn="DoneOn">{{'DONE_ON'|translate}}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                        <input pInputText type="text"
                            (input)="ruhTable.filter($any($event.target)?.value, 'No', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="ruhTable.filter($any($event.target)?.value, 'Title', 'contains')" placeholder=""
                            class="p-column-filter">
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                        <p-dropdown width='118px' [options]="statuses"
                            (onChange)="onStatusChanged($event, statusfilter)" #statusfilter
                            styleClass="p-column-filter" placeholder="" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-ruh let-expanded="expanded" let-i="rowIndex">
                <tr>
                    <td style="width: 55px;">
                        <p-tableCheckbox id="selectedOrders" [value]="ruh">
                        </p-tableCheckbox>
                    </td>
                    <td>
                        <a (click)="ruhTable.toggleRow(ruh)" *ngIf="ruh.ActionPlanListDto.length>0">
                            <i class="pi pi-chevron-circle-right" aria-hidden="true" *ngIf="setCollapse(i)"
                                (click)="toggleClick(i)"></i>
                            <i class="pi pi-chevron-circle-down" aria-hidden="true" *ngIf="!setCollapse(i)"
                                (click)="toggleClickHide()"></i>
                        </a>
                    </td>
                    <td>
                        <span><i class="{{ruh.StatusIcon}}"></i></span>
                    </td>
                    <td>
                        <span [style.color]="ruh.ImmediateAction ? 'red': 'black'">
                            {{ruh.No}}
                        </span>
                    </td>
                    <td>
                        <div class="truncate-data" pTooltip="{{ruh.Title | translate }}" tooltipPosition="top"
                            *ngIf="(ruh.FollowupUserId===userId || isRuhReadRights || isruhActionExecution(ruh.ActionPlanListDto)); else elseBlock">
                            <a [routerLink]="['./../edit',ruh['Id']]">{{ruh.Title}}</a>
                        </div>
                        <ng-template #elseBlock>
                            <div class="truncate-data" pTooltip="{{ruh.Title | translate }}" tooltipPosition="top">
                                {{ruh.Title}}</div>
                        </ng-template>
                    </td>
                    <td>
                        <span>{{ruh.EventDate |date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{ruh.EventTime | formatHours}}</span>
                    </td>
                    <td>
                        <span>{{ruh.CreatedOn |date : 'dd/MM/yyyy' }}</span>
                    </td>
                    <td>
                        <p-checkbox binary="false" [(ngModel)]="ruh.H1" [disabled]="true"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox binary="true" [(ngModel)]="ruh.H2" [disabled]="true"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox binary="true" [(ngModel)]="ruh.N" [disabled]="true"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox binary="true" [(ngModel)]="ruh.FF" [disabled]="true"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox binary="true" [(ngModel)]="ruh.UFT" [disabled]="true"></p-checkbox>
                    </td>
                    <td>
                        <span>{{ruh.Deadline |date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{ruh.Status | translate}}</span>
                    </td>
                    <td>
                        <span>{{ruh.DoneOn |date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <button class="btn btn-link btn-sm" *ngIf="ruh.RuhAttachment.length>1"
                            (click)="DownloadRUHAttachment(ruh.RuhAttachment, ruh.No)"
                            pTooltip="Download all attachments">
                            <i class="icon ic-sm icon-download"></i>
                        </button>
                        <button class="btn btn-link" *ngIf="ruh.RuhAttachment.length===1"
                            (click)="DownloadRUHAttachment(ruh.RuhAttachment, ruh.No)" pTooltip="Download attachment">
                            <i class="icon ic-sm icon-send-file-o"></i>
                        </button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-icon" (click)="confirm(ruh.Id)"
                            title="{{'DELETE' | translate}}" *ngIf="(ruh.FollowupUserId===userId || isRuhEditRights)">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-task>
                <tr>
                    <td colspan="18">
                        <div>
                            <!-- Table starts -->
                            <p-table [value]="task.ActionPlanListDto" [rows]="5">
                                <!-- Header -->
                                <ng-template pTemplate="header">
                <tr>
                    <th>{{'NUMBER_NO'|translate}}</th>
                    <th>{{'ACTION_NAME'|translate}}</th>
                    <th>{{'DESCRIPTION'|translate}}</th>
                    <th>{{'STATUS'|translate}}</th>
                    <th>{{'DEADLINE'| translate}}</th>
                    <th>{{'RESPONSIBLE_PERSON'|translate}}</th>
                    <th>{{'ATTACHMENTS'|translate}}</th>
                    <th>{{'ACTIONS'|translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-ruh>
                <tr>
                    <td>
                        <span>{{ruh.No}}</span>
                    </td>
                    <td>
                        <div class="truncate-data" pTooltip="{{ruh.Title | translate }}" tooltipPosition="top">
                            {{ruh.Title}}</div>
                    </td>
                    <td>
                        <span>{{ruh.Description}}</span>
                    </td>
                    <td>
                        <span *ngIf="!(ruh.Id === actioneditId)">
                            {{ruh.StatusText | translate}}</span>
                        <span *ngIf="(ruh.Id === actioneditId)">
                            <form [formGroup]="statusform">
                                <p-dropdown [options]="editstatuses" [style]="{'width':'100%','overflow':'visible'}"
                                    formControlName="status" (onChange)="onStatusEdit(ruh,ruh.Id,$event)">
                                </p-dropdown>
                            </form>
                        </span>
                    </td>
                    <td>
                        <span>{{ruh.Deadline |date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{ruh.Execution_Name}}</span>
                    </td>
                    <td class="col-button">
                        <button *ngIf="ruh.objActionPlanAttachment.length>1"
                            (click)="DownloadRUHAttachment(ruh.objActionPlanAttachment, ruh.No)"
                            pTooltip="Download all attachments">
                            <i class="icon ic-sm icon-download"></i>
                        </button>
                        <button *ngIf="ruh.objActionPlanAttachment.length===1"
                            (click)="DownloadRUHAttachment(ruh.objActionPlanAttachment, ruh.No)"
                            pTooltip="Download attachment">
                            <i class="icon ic-sm icon-send-file-o"></i>
                        </button>
                    </td>
                    <td class="col-button">
                        <button *ngIf="(ruh.FollowupUserId===userId || isRuhEditRights || isActionExecution(ruh))"
                            type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                            (click)="Actionedit(ruh.Id,ruh.Status)">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button *ngIf="(ruh.FollowupUserId===userId || isRuhEditRights)" type="button"
                            class="btn btn-icon" title="{{'DELETE' | translate}}" (click)="Actionconfirm(ruh.Id)">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td colspan="19">
                {{'NO_RECORDS_FOUND'|translate}}
            </td>
        </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
        <div class="footer-data">
            <div class="symbol-descriptions text-left">
                <div class="symbol-desc-title">
                    <span translate>DESCRIPTION_SYMBOLS</span>
                </div>
                <div class="symbol-desc-items">
                    <span class="new-deadline">
                        <i class="icon ic-md icon-new-deadline"></i>
                        <span translate>NEW_ISSUE</span>
                    </span>
                    <span class="far-deadline">
                        <i class="icon ic-md icon-far-deadline"></i>
                        <span translate>MORE_DEADLINE</span>
                    </span>
                    <span class="over-deadline">
                        <i class="icon ic-md icon-over-deadline"></i>
                        <span translate>OVERDEADLINE</span>
                    </span>
                    <span class="near-deadline">
                        <i class="icon ic-md icon-near-deadline"></i>
                        <span translate>LESS_DEADLINE</span>
                    </span>
                    <span class="clear-deadline">
                        <i class="icon ic-md icon-clear-deadline"></i>
                        <span translate>DONE_STATUS</span>
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- footerColumnGroup -->
    <ng-template pTemplate="groupheader" let-customer>
        <tr pRowGroupHeader>
            <td colspan="8" [style]="{'text-align':'right','font-weight':'bold'}">Total:</td>
            <td>{{H1}}</td>
            <td>{{H2}}</td>
            <td>{{N}}</td>
            <td>{{FF}}</td>
            <td>{{UFT}}</td>
            <td colspan="4"></td>
        </tr>
    </ng-template>
    </p-table>
</div>
<p-dialog header="{{'ADD_CASE'|translate}}" [(visible)]="addtypeOfCase" [modal]="true" [style]="{width: '300px'}"
    [resizable]="false" [draggable]="false" (onHide)="onBeforeCaseDialogHide()">
    <form [formGroup]="caseForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>NAME</span>
            </label>
            <div>
                <input type="text" #Title class="label-input form-control" formControlName="Case">
                <span style="color:red" *ngIf="isTypeCaseNameExists">{{existsTypeCaseAlerttext}}</span>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewTypeOfCase = !viewTypeOfCase">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="loading" [ngClass]="{'loading' : loading}"
                (click)="saveTypeOfCase()">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
            </button>
        </div>
        <div *ngIf="viewTypeOfCase">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #Types [value]="editTypeOfCases" [rows]="10" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TYPE_NAME' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="Types.filter($any($event.target)?.value, 'Name', 'contains')"
                                    placeholder="" class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-Type>
                        <tr>
                            <td>
                                <span>{{Type.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                    [disabled]="Type.IsDefault" (click)="editTypeOfCase(Type)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                    [disabled]="Type.IsDefault" (click)="deleteTypeOfCase(Type.Id)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>

<p-dialog header="{{'ADD_TOPIC'|translate}}" [(visible)]="addOfTopic" [modal]="true" [style]="{width: '300px'}"
    [resizable]="false" [draggable]="false" (onHide)="onBeforeTopicDialogHide()">
    <form [formGroup]="topicForm">
        <div class="form-group">
            <label for="topic" class="col-form-label">
                <span translate>NAME</span>
            </label>
            <div>
                <input type="text" #topic class="label-input form-control" formControlName="Topic">
                <span style="color:red" *ngIf="isTopicNameExists">{{existsTopicAlerttext}}</span>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewTopic = !viewTopic">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [ngClass]="{'loading' : loading}" [disabled]="loading"
                (click)="saveTopic()">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
            </button>
        </div>
        <div *ngIf="viewTopic">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #et [value]="editTopics" [rows]="10" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TYPE_NAME' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="et.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-Type>
                        <tr>
                            <td>
                                <span>{{Type.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                    (click)="editTopicType(Type)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                    (click)="deleteTopic(Type.Id)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>
</div>