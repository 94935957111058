import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ElectroControlScope, ElectroCourseOverview, ElectroEmergencyLightSystem, ElectroFireSprinklerSystem, ElectroFireAlarmSystem, ElectroDistributionOverview, ElectroRiskAssessment, ElectroDocumentAssessment, ElectroFormTemplates } from '../models/electroinstallation';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TreeData } from 'src/app/kuba/common/models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ElectroInstallationService {


    constructor(private http: HttpClient) {
    }

    getEnabledForms(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getenabledforms/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getContractBuildings(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getcontractbuildings/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInstallationFormsBuildingsTree(contractId: number){
        return this.http.get(environment.BASE_URL + '/electro/installation/getinstallationformsbuildingtree/' + BaseServices.BusinessId + '/' + contractId, BaseServices.headerOption)
        .map((result: any) => result);
    }

    getControlScopeById(id: number){
        return this.http.get(environment.BASE_URL + '/electro/installation/getcontrolscope/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveControlScope(controlScope: ElectroControlScope) {
        return this.http.post(environment.BASE_URL + '/electro/installation/savecontrolscope', controlScope, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateControlScope(controlScope: ElectroControlScope) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updatecontrolscope', controlScope, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getDocumentAssessmsentById(id: number){
        return this.http.get(environment.BASE_URL + '/electro/installation/getdocassessmentbyid/' + id, BaseServices.headerOption)
        .map((result: any) => result);
    }

    saveDocumentAssessmsent(DocumentAssessment: ElectroDocumentAssessment){
        return this.http.post(environment.BASE_URL + '/electro/installation/savedocumentassessment', DocumentAssessment, BaseServices.headerOption)
        .map((result: any) => result);
    }

    updateDocumentAssessment(DocumentAssessment: ElectroDocumentAssessment){
        return this.http.put(environment.BASE_URL + '/electro/installation/updatedocumentassessment', DocumentAssessment, BaseServices.headerOption)
        .map((result: any) => result);
    }

    getFirealarmSystemById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getfirealarmsystem/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveFirealarmSystem(fireSystem: ElectroFireAlarmSystem) {
        return this.http.post(environment.BASE_URL + '/electro/installation/savefirealarmsystem', fireSystem, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateFirealarmSystem(fireSystem: ElectroFireAlarmSystem) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updatefirealarmsystem', fireSystem, BaseServices.headerOption)
            .map((result: any) => result);
    }
    
    getDistributionsById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getdistributionoverviewbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    
    getDistributionOverviewByBuilding(buildingId, inspectionId) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getdistributionoverviewbybuilding/' 
        + buildingId + '/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveDistributions(distribution: ElectroDistributionOverview) {
        return this.http.post(environment.BASE_URL + '/electro/installation/savedistributionoverview/', distribution, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateDistributions(distribution: ElectroDistributionOverview) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updatedistributionoverview/', distribution, BaseServices.headerOption)
            .map((result: any) => result);
    }

    createDistributionFromTemplate(buildingId, inspectionId) {
        return this.http.get(environment.BASE_URL + '/electro/installation/createdistributionfromtemplate/' 
        + buildingId + '/' + inspectionId + '/' + BaseServices.UserId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getCourseById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getcourseoverviewbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveCourse(course: ElectroCourseOverview) {
        return this.http.post(environment.BASE_URL + '/electro/installation/savecourseoverview/', course, BaseServices.headerOption)
            .map((result: any) => result);
    }  

    updateCourse(course: ElectroCourseOverview) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updatecourseoverview/', course, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getDistributionFloorByContract(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getdistributionfloorbycontract/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getDistributionFloorByBuilding(buildingId: number, inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getdistributionfloorbybuilding/' + buildingId
        + '/' + inspectionId, BaseServices.headerOption).map((result: any) => result);
    }

    getEmergencyLightingById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getemergenylightingbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveEmergencyLighting(lighting: ElectroEmergencyLightSystem) {
        return this.http.post(environment.BASE_URL + '/electro/installation/saveemergencylighting/', lighting, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateEmergencyLighting(lighting: ElectroEmergencyLightSystem) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updateemergencylighting/', lighting, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getFireSprinklerById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getfiresprinklerbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveFireSprinkler(fireSprinkler: ElectroFireSprinklerSystem) {
        return this.http.post(environment.BASE_URL + '/electro/installation/savefiresprinkler/', fireSprinkler, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateFireSprinkler(fireSprinkler: ElectroFireSprinklerSystem) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updatefiresprinkler/', fireSprinkler, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getRiskAssessmentById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getriskassessment/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveRiskAssessment(riskAssessment: ElectroRiskAssessment) {
        return this.http.post(environment.BASE_URL + '/electro/installation/saveriskassessment', riskAssessment, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateRiskAssessment(riskAssessment: ElectroRiskAssessment) {
        return this.http.put(environment.BASE_URL + '/electro/installation/updateriskassessment', riskAssessment, BaseServices.headerOption)
            .map((result: any) => result);
    }
    
    getEnabledFormsWithTemplate(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/enabledformwithtemplate/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    SaveTemplate(formTemplate: ElectroFormTemplates) {
        return this.http.post(environment.BASE_URL + '/electro/installation/saveformtemplate', formTemplate, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getContractFormTemplate(formType:number, contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/installation/getcontractformtemplate/' 
        + formType +'/'+ contractId, BaseServices.headerOption).map((result: any) => result);
    }
    
    GetCourseFormTemplateByDistribution(contractId: number, distributionId: number){
        return this.http.get(environment.BASE_URL + `/electro/installation/getcourseformtemplatebydistribution/`
        + contractId + '/' + distributionId, BaseServices.headerOption).map((result: any) => result);
    }

    getCourseByInspectionDistribution(buildingId: number, inspectionId: number, distributionId: number){
        return this.http.get(environment.BASE_URL + `/electro/installation/getcoursebyinspectiondistribution/`
        + buildingId + '/' + inspectionId + '/' + distributionId, BaseServices.headerOption).map((result: any) => result);
    }

    getCoursesbyInspectionDistribution(buildingId: number, inspectionId: number, distributionId: number){
        return this.http.get(environment.BASE_URL + `/electro/installation/getcoursesbyinspectiondistribution/`
        + buildingId + '/' + inspectionId + '/' + distributionId, BaseServices.headerOption).map((result: any) => result);
    }
}