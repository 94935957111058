<div class="main-content">
  <div class="card">
    <div class="card-body">
      <div class="page-title">
        <span translate>MODULEOVERVIEW</span>
      </div>
      <form class="form-horizontal" [formGroup]="overviewForm">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <div class="col-md-4 text-nowrap">
                <label for="title" class="col-form-label">
                  <span translate>FEATURE</span>
                </label>
                <span class="required">*</span>
              </div>
              <div class="col-md-8">
                <p-dropdown [disabled]="IsFeatureName" formControlName="FeatureName" id="FeatureName"
                  [options]="featureName" (onChange)="onFeatureChanged($event)" [style]="{'width':'100%'}"></p-dropdown>
              </div>
              <div class="col-md-8">
                <control-messages [control]="overviewForm.controls['FeatureName']"></control-messages>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 class="first">
            <span translate>DESCRIPTION</span>
          </h3>
          <p-editor formControlName="Description" [style]="{'height':'320px'}"></p-editor>

          <hr style="border-top:0px;border-color:#dde3e6">
        </div>
        <div class="action-btns-wrapper">
          <button *ngIf="isAdmin" type="submit" class="btn btn-primary" (click)="Save()"
            [disabled]="!overviewForm.valid">
            <span translate>SAVE</span>
          </button>
          <a [routerLink]="['../../list']" class="btn btn-outline-secondary">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </a>
        </div>
      </form>
    </div>
  </div>
  <toaster-component></toaster-component>
</div>