<div class="row">
    <div class="col-sm-6">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="2"><span translate>ELECTRICAL_COMPANY</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span translate>FIRM_NAME</span></td>
                        <td><span>{{ElectroInfo.BusinessFirm}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>CONTACT_PERSON</span></td>
                        <td><span>{{ElectroInfo.BusinessContactPerson}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>ADDRESS</span></td>
                        <td><span>{{ElectroInfo.BusinessAddress}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>ZIP_CITY</span></td>
                        <td><span>{{ElectroInfo.BusinessZipcodeCity}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>PHONE_NO</span></td>
                        <td><span>{{ElectroInfo.BusinessPhoneNumber}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>EMAIL</span></td>
                        <td><span>{{ElectroInfo.BusinessEmail}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>CONTRACT_NUMBER</span></td>
                        <td><span>{{ElectroInfo.ContractNumber}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="2"><span translate>CLIENT</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span translate>FIRM_NAME</span></td>
                        <td><span>{{ElectroInfo.ClientFirm}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>CONTACT_PERSON</span></td>
                        <td><span>{{ElectroInfo.ClientContactPerson}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>ADDRESS</span></td>
                        <td><span>{{ElectroInfo.ClientAddress}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>ZIP_CITY</span></td>
                        <td><span>{{ElectroInfo.ClientZipcodeCity}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>PHONE_NO</span></td>
                        <td><span>{{ElectroInfo.ClientPhoneNumber}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>EMAIL</span></td>
                        <td><span>{{ElectroInfo.ClientEmail}}</span></td>
                    </tr>
                    <tr>
                        <td><span translate>CLIENT_NUMBER</span></td>
                        <td><span>{{ElectroInfo.ClientNumber}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="4"><span translate>INSTALLATION_INFORMATION</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span translate>FIRM_NAME</span></td>
                    <td colspan="3"><span>{{ElectroInfo.InstallationFirm}}</span></td>
                </tr>
                <tr>
                    <td><span translate>ADDRESS</span></td>
                    <td colspan="3"><span>{{ElectroInfo.InstallationAddress}}</span></td>
                </tr>
                <tr>
                    <td><span translate>ZIP_CITY</span></td>
                    <td>
                        <span *ngIf="ElectroInfo.InstallationCity">{{ElectroInfo.InstallationCity}}</span>
                        <span *ngIf="ElectroInfo.InstallationZip">/ {{ElectroInfo.InstallationZip}}</span></td>
                    <td><span translate>HOUSEFOLDER</span></td>
                    <td><span>{{ElectroInfo.Housefolder}}</span></td>
                </tr>

            </tbody>
        </table>
    </div>

</div>
<div class="row">
    <div class="col-sm-12">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="4"><span translate>INSTALLATION_CONTACT</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span translate>NAME</span></td>
                    <td><span>{{ElectroInfo.ContactName}}</span></td>
                    <td><span translate>EMAIL</span></td>
                    <td><span>{{ElectroInfo.ContactEmail}}</span></td>
                </tr>
                <tr>
                    <td><span translate>PHONE_NO</span></td>
                    <td><span>{{ElectroInfo.ContactPhoneNumber}}</span></td>
                    <td><span translate>FUNCTION</span></td>
                    <td><span translate>{{ElectroInfo.InstallationContactFunctionText}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>