import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from '../../models/dashboard';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-activities-business-widget',
  templateUrl: './activities-business-widget.component.html',
  styleUrls: ['./activities-business-widget.component.css'],
})
export class ActivitiesWidgetComponent implements OnInit {
  @Input() activities: Activity[] = [];
  single: any[] = [];
  view: any[];

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'right';

  legendTitle: string;
  shouldShow = new BehaviorSubject(false);

  colorScheme = {
    domain: [
      '#F7941E',
      'var(--color-primary-500)',
      '#329934',
      '#FF4500',
      '#006AB2',
      '#00CED1',
      '#8A2BE2',
      '#FF1493',
      '#FFD700',
      '#FF6347',
      '#FFA07A',
      '#FF00FF',
    ],
  };

  monthNames: string[] = [];

  constructor(private translate: TranslateService, private router: Router) {}

  /**
   * Initializes the widget by loading month names and setting the legend title.
   */
  ngOnInit(): void {
    this.loadMonthNames();
    this.setLegendTitle();
    setTimeout(() => {
      this.shouldShow.next(true);
    }, 500);
  }

  /**
   *
   * @param changes - The new and old values of the data-bound properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['activities'] && changes['activities'].currentValue) {
      this.processActivities(changes['activities'].currentValue);
    }
  }

  /**
   * Loads month names from the translation service and updates the activities list.
   */
  async loadMonthNames() {
    const translationKeys = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ];

    const translations = await this.translate.get(translationKeys).toPromise();
    this.monthNames = translationKeys.map((key) => translations[key]);

    if (this.activities) {
      this.processActivities(this.activities);
    }
  }

  /**
   * Sets the legend title from the translation service.
   */
  async setLegendTitle() {
    this.legendTitle = await this.translate.get('MONTH').toPromise();
  }

  /**
   * Processes the activities array to calculate the number of activities per month
   * and sorts them by the end date.
   *
   * @param activities - Array of activity objects to be processed.
   */
  processActivities(activities: Activity[]) {
    activities.sort(
      (a, b) => new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime()
    );

    const activityCounts = activities.reduce((acc, activity) => {
      const endDate = new Date(activity.EndDate);
      const month = endDate.getMonth();
      const monthName = this.monthNames[month];
      if (!acc[monthName]) {
        acc[monthName] = 0;
      }
      acc[monthName]++;
      return acc;
    }, {} as { [key: string]: number });

    const firstMonthIndex = new Date(activities[0].EndDate).getMonth();
    const orderedMonthNames = [
      ...this.monthNames.slice(firstMonthIndex),
      ...this.monthNames.slice(0, firstMonthIndex),
    ];

    this.single = orderedMonthNames
      .filter((month) => activityCounts[month])
      .map((month) => ({
        name: month,
        value: activityCounts[month],
      }));
  }

  /**
   * Handles selection event from the widget and navigates to the follow-up activities page.
   *
   * @param event - The event object containing details about the selected item.
   */
  onSelect(event: any) {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    segments[segments.length - 1] = 'follow-up';
    segments.push('activities');
    const deviationPath = segments.join('/');

    this.router.navigate([deviationPath]);
  }
}
