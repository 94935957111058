<div class="main-content">
    <div class="main-heading">
        <strong translate>NEW_PROJECT
        </strong>
    </div>
    <div class="card">
        <div class="card-body">
            <project-form (isEdit)="false"></project-form>
        </div>
    </div>
</div>