import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  template:'<router-outlet></router-outlet>'
})
export class FaqsComponent implements OnInit {
    constructor(private http: HttpClient) { }

    ngOnInit() { }
}