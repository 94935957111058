import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { datepickerLocale } from 'fullcalendar';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { FoodSafetyServices } from '../../services/foodsafety.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FoodSafetyList, ReasonAfterDealineCompletion } from '../../models';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { EmployeeServices } from 'src/app/kuba/employees/services/employee.services';
import { element } from 'protractor';
import { DatePipe } from '@angular/common';
import { TaskService } from 'src/app/kuba/task-manager/services/task.services';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fs-audit',
    templateUrl: 'fs-audit.component.html',
    styleUrls: ['fs-audit.component.css']
})

export class FSAuditComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    auditTempControlList: any;
    auditCklList: any;
    auditTasks: any;
    windowAuditTempControlList: any[];
    windowAuditCklList: any[];
    widowAuditTasks: any[];
    dataList: any;
    cklListData: any;
    taskData: any
    enableLeftArrow: boolean;
    enableRightArrow: boolean;
    completeAuditList: any[];
    startIndex: number;
    previewTempControl = false;
    previewChecklist = false;
    previewTask = false;
    startDate: string;
    endDate: string;
    featureId: number;
    zoneName: string;
    auditData: any;
    featureKey: number;
    reportDetails: any;
    currentDate: any;
    dateList: any[];
    loading: boolean;
    AfterDeadlineCompletion: FormGroup;
    private subscriptions: Subscription[] = []; 

    /**
   * Date picker configuration option
   */
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };

    public endDateOptions: IMyDpOptions = this.startDateOptions;
    zoneId: any;

    constructor(
        private translate: TranslateService,
        private route: ActivatedRoute,
        private foodSafetyServices: FoodSafetyServices,
        private employeeServices: EmployeeServices,
        private datePipe: DatePipe,
        private taskService: TaskService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private fb: FormBuilder
    ) {
        this.featureKey = this.route.snapshot.params['Id'];
        let children = this.route.snapshot.url[0].path;
        sessionStorage.setItem('WorkingSubModule', children);
        this.dateList = <any[]>this.route.snapshot.data['dateList'];
        this.startIndex = this.dateList.length - 8;
        this.enableLeftArrow = this.startIndex > 0;
        this.enableRightArrow = this.startIndex + 8 < this.dateList.length;

        this.completeAuditList = <any[]>this.route.snapshot.data['list'];
        if (this.completeAuditList) {
            this.auditTempControlList = this.completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
            this.auditCklList = this.completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
            this.auditTasks = this.completeAuditList.filter(x => x.ChecklistType == "TASK");

            this.windowAuditTempControlList = this.auditTempControlList.slice(this.auditTempControlList.length - 8, this.auditTempControlList.length);
            this.windowAuditCklList = this.auditCklList.slice(this.auditCklList.length - 8, this.auditCklList.length);
            this.widowAuditTasks = this.auditTasks.slice(this.auditTasks.length - 8, this.auditTasks.length);
        }
    }

    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.dataList = [];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule.id;
        this.zoneName = sessionStorage.getItem('ZoneName');
        this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
        this.AfterDeadlineCompletion = this.fb.group({
            Reason: ['', [Validators.required]],
        })
    }

    ShowTempControlDialog(TemperatureControlInfo) {
        if (TemperatureControlInfo) {
            this.previewTempControl = true;
            this.dataList = TemperatureControlInfo;
        }
    }

    ShowChecklistDialog(Id) {
        if (Id) {
            this.previewChecklist = true;
            let Type = 'FEATURE_CKL'
            this.subscriptions.push(this.foodSafetyServices.getAuditInformation(Id, Type)
                .subscribe(result => {
                    this.cklListData = result;
                }))
        }
    }

    ShowDailyTaskDialog(Id) {
        if (Id != null) {
            this.previewTask = true;
            let Type = 'TASK'
            this.subscriptions.push(this.foodSafetyServices.getAuditInformation(Id, Type)
                .subscribe(result => {
                    this.taskData = result;
                }))
        }
    }

    shiftLeft() {
        this.loading = true;
        let MinDate = this.windowAuditTempControlList[0].CreatedOn;
        let MaxDate = this.windowAuditTempControlList[6].CreatedOn;
        this.subscriptions.push(this.foodSafetyServices.foodSafetyAuditList(this.featureKey, MinDate, MaxDate, false).subscribe(completeAuditList => {
            if (completeAuditList) {
                this.auditTempControlList = completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                this.auditCklList = completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                this.auditTasks = completeAuditList.filter(x => x.ChecklistType == "TASK");
                if (this.auditTempControlList && this.auditCklList && this.auditTasks) {
                    this.loading = false;
                    --this.startIndex;
                    this.windowAuditTempControlList = this.auditTempControlList.slice(this.auditTempControlList.length - 8, this.auditTempControlList.length);
                    this.windowAuditCklList = this.auditCklList.slice(this.auditCklList.length - 8, this.auditCklList.length);
                    this.widowAuditTasks = this.auditTasks.slice(this.auditTasks.length - 8, this.auditTasks.length);
                    this.enableLeftArrow = this.startIndex > 0;
                    this.enableRightArrow = this.startIndex < this.dateList.length;
                }
            }
        }));
    }

    shiftRight() {
        this.loading = true;
        let MinDate = this.windowAuditTempControlList[1].CreatedOn;
        let MaxDate = this.windowAuditTempControlList[7].CreatedOn;
        this.subscriptions.push(this.foodSafetyServices.foodSafetyAuditList(this.featureKey, MinDate, MaxDate, true).subscribe(completeAuditList => {
            if (completeAuditList) {
                this.auditTempControlList = completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                this.auditCklList = completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                this.auditTasks = completeAuditList.filter(x => x.ChecklistType == "TASK");
                if (this.auditTempControlList && this.auditCklList && this.auditTasks) {
                    this.loading = false;
                    ++this.startIndex;
                    this.windowAuditTempControlList = this.auditTempControlList.slice(this.auditTempControlList.length - 8, this.auditTempControlList.length);
                    this.windowAuditCklList = this.auditCklList.slice(this.auditCklList.length - 8, this.auditCklList.length);
                    this.widowAuditTasks = this.auditTasks.slice(this.auditTasks.length - 8, this.auditTasks.length);
                    this.enableLeftArrow = this.startIndex > 0;
                    this.enableRightArrow = this.startIndex + 8 < this.dateList.length;
                }
            }
        }));
    }

    onStartDateChanged(event: IMyDateModel) {
        if (event) {
            this.startDate = HelperService.formatDateFilter(event.formatted);
            if (this.startDate && this.endDate) {
                let minDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
                let maxDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");
                this.loading = true;
                this.subscriptions.push(this.foodSafetyServices.foodSafetyAuditList(this.featureKey, minDate, maxDate, false).subscribe(completeAuditList => {
                    if (completeAuditList) {
                        this.auditTempControlList = completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                        this.auditCklList = completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                        this.auditTasks = completeAuditList.filter(x => x.ChecklistType == "TASK");
                        this.filterTempControlTable();
                        this.filterTaskTable();
                        this.filterChecklistTable();
                        this.loading = false;
                    }
                }));
            }
            else if (event.jsdate == null) {
                this.completeAuditList = <any[]>this.route.snapshot.data['list'];
                if (this.completeAuditList) {
                    this.auditTempControlList = this.completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                    this.auditCklList = this.completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                    this.auditTasks = this.completeAuditList.filter(x => x.ChecklistType == "TASK");

                    this.windowAuditTempControlList = this.auditTempControlList.slice(this.auditTempControlList.length - 8, this.auditTempControlList.length);
                    this.windowAuditCklList = this.auditCklList.slice(this.auditCklList.length - 8, this.auditCklList.length);
                    this.widowAuditTasks = this.auditTasks.slice(this.auditTasks.length - 8, this.auditTasks.length);
                }
            }
        }
    }

    onEndDateChanged(event: IMyDateModel) {
        if (event) {
            this.endDate = HelperService.formatDateFilter(event.formatted);
            if (this.startDate && this.endDate) {
                let minDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
                let maxDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");
                this.loading = true;
                this.subscriptions.push(this.foodSafetyServices.foodSafetyAuditList(this.featureKey, minDate, maxDate, false).subscribe(completeAuditList => {
                    if (completeAuditList) {
                        this.auditTempControlList = completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                        this.auditCklList = completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                        this.auditTasks = completeAuditList.filter(x => x.ChecklistType == "TASK");
                        this.filterTempControlTable();
                        this.filterTaskTable();
                        this.filterChecklistTable();
                        this.loading = false;
                    }
                }));
            }
            else if (event.jsdate == null) {
                this.completeAuditList = <any[]>this.route.snapshot.data['list'];
                if (this.completeAuditList) {
                    this.auditTempControlList = this.completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                    this.auditCklList = this.completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                    this.auditTasks = this.completeAuditList.filter(x => x.ChecklistType == "TASK");

                    this.windowAuditTempControlList = this.auditTempControlList.slice(this.auditTempControlList.length - 8, this.auditTempControlList.length);
                    this.windowAuditCklList = this.auditCklList.slice(this.auditCklList.length - 8, this.auditCklList.length);
                    this.widowAuditTasks = this.auditTasks.slice(this.auditTasks.length - 8, this.auditTasks.length);
                }
            }
        }
    }

    filterTempControlTable() {
        if (this.startDate && this.endDate) {
            if ((new Date(this.startDate) < new Date(this.endDate)) &&
                new Date(this.auditTempControlList[this.auditTempControlList.length - 1].CreatedOn) > new Date(this.startDate)) {
                this.windowAuditTempControlList = this.auditTempControlList.filter(x => {
                    return new Date(x.CreatedOn) >= new Date(this.startDate) &&
                        new Date(x.CreatedOn) <= new Date(this.endDate);
                });
            }
        }
        else if (this.startDate) {
            if (new Date(this.auditTempControlList[this.auditTempControlList.length - 1].CreatedOn) > new Date(this.startDate)) {
                this.windowAuditTempControlList = this.auditTempControlList.filter(x => {
                    return new Date(x.CreatedOn) >= new Date(this.startDate);
                });
            }
        }
        else if (this.endDate) {
            if (new Date(this.auditTempControlList[0].CreatedOn) < new Date(this.endDate)) {
                this.windowAuditTempControlList = this.auditTempControlList.filter(x => {
                    return new Date(x.CreatedOn) <= new Date(this.endDate);
                });
            }
        }
    }

    filterTaskTable() {
        if (this.startDate && this.endDate) {
            if ((new Date(this.startDate) < new Date(this.endDate)) &&
                new Date(this.auditTasks[this.auditTasks.length - 1].CreatedOn) > new Date(this.startDate)) {
                this.widowAuditTasks = this.auditTasks.filter(x => {
                    return new Date(x.CreatedOn) >= new Date(this.startDate) &&
                        new Date(x.CreatedOn) <= new Date(this.endDate);
                });
            }
        }
        else if (this.startDate) {
            if (new Date(this.auditTasks[this.auditTasks.length - 1].CreatedOn) > new Date(this.startDate)) {
                this.widowAuditTasks = this.auditTasks.filter(x => {
                    return new Date(x.CreatedOn) >= new Date(this.startDate);
                });
            }
        }
        else if (this.endDate) {
            if (new Date(this.auditTasks[0].CreatedOn) < new Date(this.endDate)) {
                this.widowAuditTasks = this.auditTasks.filter(x => {
                    return new Date(x.CreatedOn) <= new Date(this.endDate);
                });
            }
        }
    }

    filterChecklistTable() {
        if (this.startDate && this.endDate) {
            if ((new Date(this.startDate) < new Date(this.endDate)) &&
                new Date(this.auditCklList[this.auditCklList.length - 1].CreatedOn) > new Date(this.startDate)) {
                this.windowAuditCklList = this.auditCklList.filter(x => {
                    return new Date(x.CreatedOn) >= new Date(this.startDate) &&
                        new Date(x.CreatedOn) <= new Date(this.endDate);
                });
            }
        }
        else if (this.startDate) {
            if (new Date(this.auditCklList[this.auditCklList.length - 1].CreatedOn) > new Date(this.startDate)) {
                this.windowAuditCklList = this.auditCklList.filter(x => {
                    return new Date(x.CreatedOn) >= new Date(this.startDate);
                });
            }
        }
        else if (this.endDate) {
            if (new Date(this.auditCklList[0].CreatedOn) < new Date(this.endDate)) {
                this.windowAuditCklList = this.auditCklList.filter(x => {
                    return new Date(x.CreatedOn) <= new Date(this.endDate);
                });
            }
        }
    }

    generateReport() {
        let fileName = this.translate.instant('AUDIT');
        let type = 'pdf';
        let cultureInfo = '';
        let languageMode = sessionStorage.getItem('languageMode');
        if (languageMode != null) {
            cultureInfo = languageMode;
        }
        this.zoneName = sessionStorage.getItem('ZoneName');
        this.zoneId = sessionStorage.getItem('ZoneId');
        let foodSafety = <FoodSafetyList[]>this.route.snapshot.data['fslist'];
        let fs = foodSafety.filter(x => x.ZoneName == this.zoneName);
        this.auditData = { Checklist: this.windowAuditCklList, Task: this.widowAuditTasks, TempratureCkl: this.windowAuditTempControlList };
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule.id;
        this.subscriptions.push(this.foodSafetyServices.exportFSAudit(cultureInfo, this.featureId, BaseServices.BusinessId, BaseServices.UserId, this.auditData, this.zoneId,BaseServices.ApplicationId,fs[0].Department)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${fileName}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }
            ))
    }

    generateReportForTempControl(dataList) {
        let fileName = this.translate.instant('TEMPERATURE_CONTROL');
        let type = 'pdf';
        let cultureInfo = sessionStorage.getItem('languageMode');
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule.id;
        this.subscriptions.push(this.foodSafetyServices.exportFSAuditForTempControl(cultureInfo, this.featureId, BaseServices.BusinessId, BaseServices.UserId, dataList.ChecklistId, dataList.TemperatureControlInfo.CompleteChecklistId, dataList)
            .subscribe(blob => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}.${type.toLowerCase()}`;
                link.click();
            },
                error => {
                    alert('Export not downloaded');
                }
            ))
    }

    humanizeType(mimetype) {
        let type = '';


        switch (mimetype) {
            case 'xlsx':
            case 'xls':
            case 'csv':
            case 'docx':
            case 'doc':
            case 'rtf':
            case 'ppt':
            case 'pptx':
            case 'csv':
                type = 'Document';
                break;
            case 'png':
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'bmp':
            case 'gif':
            case 'tiff':
                type = 'Image';
                break;
            case 'mp3':
            case 'wav':
                type = 'Audio';
                break;
            case 'avi':
            case 'mp4':
            case '3gpp':
            case 'webm':
            case 'flv':
                type = 'Video';
                break;
            case 'txt':
                type = 'Text';
                break;
            case 'PDF':
            case 'pdf':
                type = 'Pdf';
                break;
            case 'zip':
            case 'rar':
            case '7z':
                type = 'Archive file';
                break;
            default:
                type = 'Article';
                break;
        }

        return type;
    }

    getFileExtension(filename: any) {
        let fileExtension = null;
        if (filename != null) {
            fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
        }
        return fileExtension;
    }

    downloadFile(fileToDownload) {
        window.open(fileToDownload);
    }

    generateReportForDailyTask(Id) {
        let fileName = this.translate.instant('DAILY_TASK');
        let type = 'pdf';
        let cultureInfo = '';
        let languageMode = sessionStorage.getItem('languageMode');
        if (languageMode != null) {
            cultureInfo = languageMode;
        }
        this.subscriptions.push(this.foodSafetyServices.exportFSAuditForDailyTask(Id, cultureInfo)
            .subscribe(blob => {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}.${type.toLowerCase()}`;
                link.click();
            },
                error => {
                    alert('Export not downloaded');
                }
            ))
    }

    taskCompleteionafterDeadline(task) {
        if (task.ChecklistId) {
            this.subscriptions.push(this.taskService.getActivityCompleteCheckList(task.Id).subscribe(
                (result: any) => {
                    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
                    this.featureId = workingModule.id;
                    if (result) {
                        this.router.navigate(
                            ['../../../../complete-checklist/incomplete/' + result.Id,
                                'task-manager', this.featureId, this.featureKey],
                            { relativeTo: this.route }
                        );
                    } else {
                        this.router.navigate(
                            ['../../../../complete-checklist/new/' + task.ChecklistId,
                                'task-manager', this.featureId, this.featureKey, task.Id],
                            { relativeTo: this.route }
                        );
                    }
                }
            ));
        }
        else {
            this.confirmationService.confirm({
                message: this.translate.instant('COMPLETE_TASK?'),
                accept: () => {
                    this.loading = true;
                    this.subscriptions.push(this.taskService.CompleteFSTask(task.Id, BaseServices.UserId).subscribe(completed => {
                        if (completed) {
                            this.toasterComponent.callToast();
                            this.loading = false;
                            this.RefreshGrid();
                        }
                    }));
                }
            });
        }
    }

    completeAfterDeadlineTask(task) {
        if (task.LateCompletionDetails != null) {
            this.taskCompleteionafterDeadline(task);
        }
        else {
            if (this.AfterDeadlineCompletion.valid != null) {
                let data = new ReasonAfterDealineCompletion;
                data.Id = 0;
                data.ActivityId = task.Id;
                data.Reason = this.AfterDeadlineCompletion.value.Reason;
                this.subscriptions.push(this.foodSafetyServices.saveReasonForLateTaskCompletion(data)
                    .subscribe(result => {
                        if (result != null) {
                            this.taskCompleteionafterDeadline(task);
                        }
                    }));
            }
        }
    }
    
    RefreshGrid(){
        this.loading = true;
        this.previewTask = false; 
        this.loading = true;
        let MinDate = this.windowAuditTempControlList[0].CreatedOn;
        let MaxDate = this.windowAuditTempControlList[7].CreatedOn; 
        this.subscriptions.push(this.foodSafetyServices.foodSafetyAuditList(this.featureKey, MinDate, MaxDate, false).subscribe(completeAuditList => {
            if (completeAuditList) {
                this.auditTempControlList = completeAuditList.filter(x => x.ChecklistType == "TEMP_CONTROL_CKL");
                this.auditCklList = completeAuditList.filter(x => x.ChecklistType == "FEATURE_CKL");
                this.auditTasks = completeAuditList.filter(x => x.ChecklistType == "TASK");
                if (this.auditTempControlList && this.auditCklList && this.auditTasks) {
                    this.loading = false;
                    this.windowAuditTempControlList = this.auditTempControlList.slice(this.auditTempControlList.length - 8, this.auditTempControlList.length);
                    this.windowAuditCklList = this.auditCklList.slice(this.auditCklList.length - 8, this.auditCklList.length);
                    this.widowAuditTasks = this.auditTasks.slice(this.auditTasks.length - 8, this.auditTasks.length);
                    this.enableLeftArrow = this.startIndex > 0;
                    this.enableRightArrow = this.startIndex < this.dateList.length;
                }
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
