import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { BaseServices } from '../kuba.services';
import { ArchiveDocument } from '../manuals/models/manual-document.model';
import { TreeData } from './../common/models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ArchiveServices {
    constructor(private http: HttpClient) { }

    getArchiveTree(businessId: number): Observable<TreeData[]> {
        return this.http
            .get<any[]>(
                `${environment.BASE_URL}/archive/archivetreedata/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getArchiveTreeFolder(businessId: number): Observable<TreeData[]> {
        return this.http
            .get<any[]>(
                `${
                environment.BASE_URL
                }/archive/archivetreedatafolder/${businessId}`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // Add root folder
    createRootFolder(model:any, isRootFolder:any) {
        let sData = {
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            FolderName: model.newNode.filename,
            ParentFolderId: model.parent.parentFolderId === 0 ? 1
                : model.parent.parentFolderId,
            DocumentTypeId: model.parent.documentTypeId,
            Status: 1,
            IsDefault: model.newNode.IsDefault,
            IsCommon: false,
            Position: 0,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId
        };

        return this.http
            .post(
                `${environment.BASE_URL}/archive/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add manual folder
    createManualFolder(model:any, isRootFolder:any) {
        let sData = {
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            FolderName: model.newNode.filename,
            ParentFolderId: isRootFolder ? 0 : model.parent.nodeId,
            DocumentTypeId: model.parent.documentTypeId,
            Status: 1,
            IsDefault: model.newNode.IsDefault,
            IsCommon: false,
            Position: 0,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId
        };

        return this.http
            .post(
                `${environment.BASE_URL}/archive/new`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Rename Archive Folder
    renameManualFolder(model:any) {
        let sData = {
            Id: +model.item.data.nodeId,
            FolderName: model.item.data.filename,
            CreatedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/archive/renameFolder/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Manual folder
    deleteManualFolder(did:any) {
        return this.http
            .post(
                `${environment.BASE_URL}/archive/deleteFolder/${did}`,
                '',
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    renameDocument(model:any) {
        let sData = {
            Id: +model.item.data.nodeId,
            FileName: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/archive/rename/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete document
    deleteDocument(did:any) {
        return this.http
            .delete(
                `${environment.BASE_URL}/archive/delete/${did}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleDocument(data:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/archive/deleteall`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Archive checklist
    deleteArchiveDocument(id:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/archive/delete/archivechecklist/${id}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteArchiveCheckList(data:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/archive/deleteAll/archivechecklist`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Archive checklist
    deleteFoodSafety(id:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/foodsafety/deletefoodsafety/${id}`,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleFoodSafety(data:any) {
        return this.http
            .put(
                `${environment.BASE_URL}/foodsafety/delete/`,
                data,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    UpdateDocument(fileId:any, userId:any) {
        let sData = {};
        return this.http.put(
            `${environment.BASE_URL}/archive/updateDocument/${fileId}/${userId}`,
            sData
        ).map(result => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Document related services
    // Archive Document
    addArchiveDocument(uploadedFile:any) {
        let archiveDocument: ArchiveDocument = new ArchiveDocument();
        archiveDocument.BusinessId = uploadedFile.BusinessId;
        archiveDocument.FeatureId = 0;
        archiveDocument.FeatureKey = 0;
        archiveDocument.ArchiveChecklistId = 0;
        archiveDocument.ArchiveFolderId = uploadedFile.ArchiveFolderId;
        archiveDocument.FileName = uploadedFile.filename;
        archiveDocument.OriginalFileName = uploadedFile.filename;
        archiveDocument.Size = uploadedFile.size;
        archiveDocument.MimeType = uploadedFile.mimetype;
        archiveDocument.Path = uploadedFile.path;
        archiveDocument.FileType = uploadedFile.fileTypeId;
        archiveDocument.Status = 1;
        archiveDocument.CreatedBy = uploadedFile.userId;
        return this.http
            .post(
                `${environment.BASE_URL}/archive/document/new`,
                archiveDocument,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    CreateEditDocumentVersion(document:any) {
        let varsionDto = {
            Id: 0,
            ArchiveManualDocumentId: document.Id,
            Path: document.Path,
            RevisionNo: 1,
            RevisionComment: 'Saving As',
            Status: '1',
            CreatedBy: BaseServices.UserId,
            ModifiedBy: BaseServices.UserId
        };
        return this.http
            .post(
                `${environment.BASE_URL}/archive/SaveCopyversion`,
                varsionDto,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    moveNode(model:any) {
        let sData;
        switch (model.to.parent.documentType) {
            case 'document':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId
                    }
                };
                let resut: any = this.http
                .put(
                    `${
                    environment.BASE_URL
                    }/archive/DragAndDrop/document`,
                    sData,
                    BaseServices.headerOption
                )
                .map(result => result)
                .catch(error => BaseServices.handleError(error));

                return resut;
            default:
                break;
        }
    }


    updateManualCheckboxstatus(data:any) {
        let sData = {
            Id: +data.nodeId,
            Status: +data.status,
            DocumentType: data.documentType.toLowerCase(),
            FileType: data.type.toLowerCase(),
            IsDefault: data.isDefault,
            BusinessId: BaseServices.BusinessId,
            AppId: BaseServices.ApplicationId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/archive/ArchivePermission/${
                BaseServices.UserId
                }`,
                sData,
                BaseServices.headerOption
            )
            .map(result => result)
            .catch(error => BaseServices.handleError(error));
    }
}
