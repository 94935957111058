import { BaseServices } from 'src/app/kuba/kuba.services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { GoodsType, ReceptionControl } from '../models/reception-control';
import { ReceptionControlService } from '../services/reception-control.service';
import { SupplierList } from './../../Suppliers/Models/supplierList';
import { SupplierServices } from './../../Suppliers/Services/supplier.services';
import { FoodSafetyServices } from 'src/app/kuba/foodsafety/services/foodsafety.services';

@Injectable()
export class ReceptionControlListResolver implements Resolve<ReceptionControl[]> {
    constructor(private backend: ReceptionControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ReceptionControl[]> {
        return this.backend.getAll(+route.params['Id']); // foodSafetyId
    }
}
@Injectable()
export class ReceptionControlSupplierListResolver implements Resolve<SupplierList[]> {
    constructor(private backend: SupplierServices) { }
    resolve(): Observable<SupplierList[]> {
        return this.backend.getAll();
    }
}

@Injectable()
export class ReceptionControlGoodsTypeResolver implements Resolve<GoodsType[]> {
    constructor(private backend: ReceptionControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<GoodsType[]> {
        return this.backend.getAllGoodsType();
    }
}
@Injectable()
export class GoodsTypeByBusinessResolver implements Resolve<GoodsType[]> {
    constructor(private backend: ReceptionControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<GoodsType[]> {
        return this.backend.getGoodsTypeByBusiness(BaseServices.BusinessId);
    }
}
@Injectable()
export class ReceptionControlEditResolver implements Resolve<ReceptionControl[]> {
    constructor(private backend: ReceptionControlService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ReceptionControl[]> {
        if (+route.params['rid'] > 0) {
            return this.backend.getById(+route.parent.parent.params['id'], +route.params['rid'] ? +route.params['rid']
                : +route.queryParams['reception']);
        } else { return null; }
    }

}

@Injectable()
export class ReceptionControlDocumentResolver implements Resolve<any> {
    constructor(private backend: ReceptionControlService) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (+route.params['rid'] > 0) {
            let result:any = this.backend.getReceptionControlDocument(+route.parent.parent.params['id'],+route.params['rid']);
            return result;
        }
    }
}
