export class ManualLAR {
    Id: number;
    AppManualId: number;
    LevelId: number;
    LevelKey: number;
    AppManualFolderId: number;
    Title: string;
    Url: string;
    IsLaws: boolean;
    IsCommon: boolean;
    IsAccess: boolean;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    constructor() {
        this.Id = 0;
        this.Status = 1;
        this.IsAccess = true;
    }
}