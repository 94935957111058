import { BaseServices } from 'src/app/kuba/kuba.services';
import { EmployeeServices } from './../../employees/services/employee.services';
import { Employee, Timetable } from './../../employees/models/employee';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CommonDetailsEditResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(BaseServices.FeatureKey);
  }
}

@Injectable()
export class FunctionDropdownResolver implements Resolve<Employee> {
  constructor(private backend: EmployeeServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllFunctions();
  }
}
@Injectable()
export class MyPageTimeTableResolver implements Resolve<Timetable[]> {
  constructor(private backend: EmployeeServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getTimeTableByEmployeeId(+route.params['tid']);
  }
}

@Injectable()
export class MyPageUserDetailResolver implements Resolve<Timetable[]> {
  constructor(private backend: EmployeeServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUsersInfoById(BaseServices.UserId);
  }
}

