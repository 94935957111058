<div class="main-content">
    <div class="main-heading">
        <span translate>LICENSE_AGREEMENT</span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view" [formGroup]="slider">
                <!-- Table starts -->
                <p-table #dt [value]="businesses" exportFilename="{{'Business List'|translate}}" [rows]="10"
                  [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true"
                  [globalFilterFields]="['CompanyName','Connectedto','Telephone','CategoryName','EmployeeCount']"
                  (onFilter)="onBusinessFilterChange($event)" (onPage)="onBusinessPageChange($event)"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Caption -->
                    <ng-template pTemplate="caption">
                        <div class="ui-helper-clearfix">
                            <div class="table-options">
                                <div class="pull-left">
                                    <div class="input-filters">
                                        <!-- <input type="text" pInputText size="50" placeholder="{{'SEARCH'|translate}}"> -->
                                        <input pInputText size="50" type="text"
                                          (input)="applyFilterGlobal($event, 'contains')"
                                          placeholder="{{'SEARCH'|translate}}" />
                                    </div>
                                </div>
                                <div class="pull-right">
                                    <div class="action-btns-group">
                                        <kuba-export [reportData]="dt.filteredValue || dt.value"
                                          [additionalData]="additionalData" (loading)='loader($event)'></kuba-export>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th width='54px' text-align='center'>{{'R'|translate}}</th>
                            <th pSortableColumn="CompanyName" width='185px'>{{'NAME'|translate}} <p-sortIcon
                                  field="CompanyName"></p-sortIcon></th>
                            <th pSortableColumn="Connectedto" width='120px'>{{'CONNECTED_TO'|translate}} <p-sortIcon
                                  field="Connectedto"></p-sortIcon></th>
                            <th pSortableColumn="PricePerMonth">{{'PRICE_PER_MONTH'|translate}} <p-sortIcon
                                  field="PricePerMonth"></p-sortIcon></th>
                            <th pSortableColumn="LicenseMonth">{{'MONTH'|translate}}</th>
                            <th pSortableColumn="LicenseCategoryId">{{'LICENSE_CATEGORY'|translate}} <p-sortIcon
                                  field="LicenseCategoryId"></p-sortIcon></th>
                            <th pSortableColumn="EmployeeCount">{{'USERS'|translate}} <p-sortIcon
                                  field="EmployeeCount"></p-sortIcon></th>
                            <th>{{'STATUS'|translate}}</th>
                            <th pSortableColumn="CreatedOn" width='95px'>{{'CREATED_ON'|translate}} <p-sortIcon
                                  field="CreatedOn"></p-sortIcon></th>
                            <th pSortableColumn="ModifiedOn" width='95px'>{{'MODIFIED_ON'|translate}} <p-sortIcon
                                  field="ModifiedOn"></p-sortIcon></th>
                            <th pSortableColumn="LoginDate" width='95px'>{{'LOGIN_DATE'|translate}} <p-sortIcon
                                  field="LoginDate"></p-sortIcon></th>
                            <th pSortableColumn="LoginCount" width='60px'>{{'LOGIN_COUNT'|translate}} <p-sortIcon
                                  field="LoginCount"></p-sortIcon></th>
                            <th>{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th width='54px'></th>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'CompanyName', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'Connectedto', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                            <th>
                                <p-dropdown width='118px' [options]="months"
                                  (onChange)="dt.filter($event.value, 'LicenseMonth', 'equals')"
                                  styleClass="p-column-filter" placeholder="" [filterBy]="null" [showClear]="true">
                                    <ng-template let-month pTemplate="item"> {{ month.label | translate }}
                                    </ng-template>
                                </p-dropdown>
                            </th>
                            <th></th>
                            <th></th>
                            <th>
                                <p-dropdown [options]="statuses"
                                  (onChange)="dt.filter($event.value, 'Status', 'equals')" styleClass="p-column-filter"
                                  placeholder="Select" [filterBy]="null" [showClear]="true">
                                    <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                                    </ng-template>
                                </p-dropdown>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-business>
                        <tr>
                            <td>
                                <a href="javascript:void(0)" class="btn btn-outline-primary"
                                  (click)="rModeCheckFeature(business);">
                                    <span translate> R </span>
                                </a>
                            </td>
                            <td>
                                <span>{{business.CompanyName}}</span>
                            </td>
                            <td>
                                <span>{{business.Connectedto}}</span>
                            </td>
                            <td>
                                <span>{{business.PricePerMonth}}</span>
                            </td>
                            <td>
                                <span>{{business.LicenseMonth | month | translate}}</span>
                            </td>
                            <td>
                                <span>{{business.LicenseCategoryId | licenseCategory | translate }}</span>
                            </td>
                            <td>
                                <span>{{business.EmployeeCount}}</span>
                            </td>
                            <td>
                                <span>{{business.Status | rowStatus | translate}}</span>
                            </td>
                            <td>
                                <span>{{business.CreatedOn | date:'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{business.ModifiedOn | date:'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{business.LoginDate | date:'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <span *ngIf="business.LoginCount!=0">{{business.LoginCount}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button"
                                  [routerLink]="['./../business/edit',business.Id, 'details']"
                                  class="btn btn-icon" title="{{'EDIT' | translate}}">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button *ngIf="!isPortal" type="button" (click)="confirm(business.Id,business)"
                                  class="btn btn-icon" title="{{'DELETE' | translate}}">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="13">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                    <!-- footerColumnGroup -->
                    <ng-template pTemplate="groupheader" let-customer>
                        <tr pRowGroupHeader>
                            <td colspan="3" [style]="{ 'text-align': 'right', 'font-weight': 'bold'}">
                                {{'SUMMARY'|translate}}</td>
                            <td colspan="7" [style]="{ 'text-align': 'left', 'font-weight': 'bold'}">{{totalPrice}} {{
                                'KR_PER_Month'|translate}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION' | translate}}" width="425" acceptLabel="{{'SWITCH_YES'|translate}}"
  rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>

<div class="panel-loader" *ngIf="Showloader">
    <span>
        <span class="loader-icon"></span>
        <span translate>PROCESSING</span>
    </span>
</div>