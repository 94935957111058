import { BaseServices } from './../../kuba.services';
import { IMyDpOptions } from 'mydatepicker';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { HelperService } from './../../../_services/helper.service';
import { DeclarationConformityServices } from '../services/declaration-conformity.service';
import { DeclarationOfConformity } from '../models/declaration-conformity';
import { TranslateService } from '@ngx-translate/core';
import { ElectroChecklistCompletion, ElectroCompleteChecklistEdit, ElectroCompleteChecklistItemSection } from 'src/app/kuba/electro/models/electrochecklistcompletion';
let jsPDF = require('jspdf');

@Component({
    selector: 'declaration-conformity',
    templateUrl: 'declaration-conformity.component.html'
})
export class DeclarationConformityComponent implements OnInit {
    @ViewChild('dc',{static: false}) el: ElementRef;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    conformityData = new DeclarationOfConformity();
    conformityForm: FormGroup;
    projectId: number;
    categories = <any>[];
    itemForm: FormGroup;
    yes: any;
    no: any;
    signList1: boolean;
    signList2: boolean;
    signList3: boolean;
    signData1: any[];
    signData2: any[];
    signData3: any[];
    signImage1: string;
    signImage2: string;
    signImage3: string;
    loading = false;
    businessId: number;
    isGuest = true;
    firstInit = true;
    firstInit1 = true;
    firstInit2 = true;
    checklistData: ElectroChecklistCompletion;
    pdfButton : boolean=false;
    private subscriptions: Subscription[] = [];
    //#region my-datepicker option
    /**
     * Date picker configuration option
     */
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    //#endregion
    constructor(

        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        public conformityService: DeclarationConformityServices,
        protected location: Location,
        private translate: TranslateService,
        private router:Router
    ) {
        this.conformityForm = this.formBuilder.group({
            IsInConvenience: [''],
            IsTasksatisfactorily: [''],
            IsAdequatequalifications: [''],
            IsToolsAvailable: [''],
            IsRefprocedure1: [''],
            IsRefprocedure2: [''],
            IsRefprocedure3: [''],
            IsMinutecheckdisconnected: [''],
            IsMinutecheckundisconnected: [''],
            IsMinutecheckclosetoundisconnected: [''],
            RiskAnalysisDate: [''],
            IsCheckFinalInspection: [''],
            IsCheckFinalInspectionBelow: [''],
            IsContactProtection: [''],
            ContactProAcc: [''],
            ContactProComments: [''],
            ISGrounding: [''],
            GroundingAcc: [''],
            GroundingComments: [''],
            IsMarking: [''],
            MarkingAcc: [''],
            MarkingCommnets: [''],
            IsOptionsAndSettings: [''],
            OptionsAndSettingsAcc: [''],
            OptionsAndSettingsComments: [''],
            ISAttachedConductor: [''],
            AttachedConductorAcc: [''],
            AttachedConductorComments: [''],
            IsMeasurementsAndTests: [''],
            MeasurementsAndTestsAcc: [''],
            MeasurementsAndTestsComments: [''],
            IsInsulationMeasurement: [''],
            InsulationMeasurementAcc: [''],
            InsulationMeasurementCommnets: [''],
            IsEarthleakCircuit: [''],
            EarthleakCircuitAcc: [''],
            EarthleakCircuitComments: [''],
            IsSafetyCircuits: [''],
            SafetyCircuitsAcc: [''],
            SafetyCircuitsComments: [''],
            IsCableRoutings: [''],
            CableRoutingsAcc: [''],
            CableRoutingsCommnets: [''],
            IsDocumentation: [''],
            DocumentationAcc: [''],
            DocumentationCommnets: [''],
            InstallationDate: [''],
            IsNewFacilities: [''],
            IsExtension: [''],
            IsChange: [''],
            ISUsednorms: [''],
            IsNEK: [''],
            IsOthers: [''],
            Others: [''],
            ShortDescription: [''],
            ConformityCompletedDate: [''],
            CommentsToFacilities: ['']
        });
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = false;
        }
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            this.projectId = +params['Id'];
        }));
        this.getAllDeclaration();
    }

    getAllDeclaration() {
        this.subscriptions.push(this.conformityService
            .getDeclaration(this.projectId)
            .subscribe((result: any) => {
                if (result) {
                    (this.signImage1 = result.RiskAnalysisSignature ? result.RiskAnalysisSignature : ''),
                        (this.signImage2 = result.InstallationSignature ? result.InstallationSignature : ''),
                        (this.signImage3 = result.ConformityCompletedSignature ? result.ConformityCompletedSignature : ''),
                        (this.conformityData = result);
                    let conformityData = {
                        IsInConvenience: result.IsInConvenience,
                        IsTasksatisfactorily: result.IsTasksatisfactorily,
                        IsAdequatequalifications:
                        result.IsAdequatequalifications,
                        IsToolsAvailable: result.IsToolsAvailable,
                        IsRefprocedure1: result.IsRefprocedure1,
                        IsRefprocedure2: result.IsRefprocedure2,
                        IsRefprocedure3: result.IsRefprocedure3,
                        IsMinutecheckclosetoundisconnected:
                        result.IsMinutecheckclosetoundisconnected,
                        IsMinutecheckundisconnected:
                        result.IsMinutecheckundisconnected,
                        RiskAnalysisDate: result.RiskAnalysisDate ? HelperService.formatInputDate(
                            result.RiskAnalysisDate) : ''
                        ,
                        IsCheckFinalInspection: result.IsCheckFinalInspection,
                        IsCheckFinalInspectionBelow:
                        result.IsCheckFinalInspectionBelow,
                        IsContactProtection: result.IsContactProtection,
                        ContactProAcc: result.ContactProAcc,
                        ContactProComments: result.ContactProComments,
                        ISGrounding: result.ISGrounding,
                        GroundingAcc: result.GroundingAcc,
                        GroundingComments: result.GroundingComments,
                        IsMarking: result.IsMarking,
                        MarkingAcc: result.MarkingAcc,
                        MarkingCommnets: result.MarkingCommnets,
                        IsOptionsAndSettings: result.IsOptionsAndSettings,
                        OptionsAndSettingsAcc: result.OptionsAndSettingsAcc,
                        OptionsAndSettingsComments:
                        result.OptionsAndSettingsComments,
                        ISAttachedConductor: result.ISAttachedConductor,
                        AttachedConductorAcc: result.AttachedConductorAcc,
                        AttachedConductorComments:
                        result.AttachedConductorComments,
                        IsMeasurementsAndTests: result.IsMeasurementsAndTests,
                        MeasurementsAndTestsAcc: result.MeasurementsAndTestsAcc,
                        MeasurementsAndTestsComments:
                        result.MeasurementsAndTestsComments,
                        IsInsulationMeasurement: result.IsInsulationMeasurement,
                        InsulationMeasurementAcc:
                        result.InsulationMeasurementAcc,
                        InsulationMeasurementCommnets:
                        result.InsulationMeasurementCommnets,
                        IsEarthleakCircuit: result.IsEarthleakCircuit,
                        EarthleakCircuitAcc: result.EarthleakCircuitAcc,
                        EarthleakCircuitComments:
                        result.EarthleakCircuitComments,
                        IsSafetyCircuits: result.IsSafetyCircuits,
                        SafetyCircuitsAcc: result.SafetyCircuitsAcc,
                        SafetyCircuitsComments: result.SafetyCircuitsComments,
                        IsCableRoutings: result.IsCableRoutings,
                        CableRoutingsAcc: result.CableRoutingsAcc,
                        CableRoutingsCommnets: result.CableRoutingsCommnets,
                        IsDocumentation: result.IsDocumentation,
                        DocumentationAcc: result.DocumentationAcc,
                        DocumentationCommnets: result.DocumentationCommnets,
                        InstallationDate: result.InstallationDate ? HelperService.formatInputDate(
                            result.InstallationDate) : ''
                        ,
                        IsNewFacilities: result.IsNewFacilities,
                        IsExtension: result.IsExtension,
                        IsMinutecheckdisconnected:
                        result.IsMinutecheckdisconnected,
                        IsChange: result.IsChange,
                        ISUsednorms: result.ISUsednorms,
                        IsNEK: result.IsNEK,
                        IsOthers: result.IsOthers,
                        Others: result.Others,
                        ShortDescription: result.ShortDescription,
                        ConformityCompletedDate: result.ConformityCompletedDate ? HelperService.formatInputDate(
                            result.ConformityCompletedDate) : '',
                        CommentsToFacilities: result.CommentsToFacilities
                    };
                    (<FormGroup>this.conformityForm).setValue(conformityData, {
                        onlySelf: true
                    });
                } else {
                    this.pdfButton=true;
                    this.conformityData.Id = 0;
                }
            }));
    }

    saveChanges() {
        if (this.conformityData.Id === 0 && !this.conformityForm.dirty) {
            alert('No data to generate report');
        } else {
            if (this.conformityData.Id === 0) {
                this.conformityData.ProjectId = this.projectId;
            }
            this.conformityData.IsInConvenience = this.conformityForm.value.IsInConvenience;
            this.conformityData.IsTasksatisfactorily = this.conformityForm.value.IsTasksatisfactorily;
            this.conformityData.IsAdequatequalifications = this.conformityForm.value.IsAdequatequalifications;
            this.conformityData.IsToolsAvailable = this.conformityForm.value.IsToolsAvailable;
            this.conformityData.IsRefprocedure1 = this.conformityForm.value.IsRefprocedure1;
            this.conformityData.IsRefprocedure2 = this.conformityForm.value.IsRefprocedure2;
            this.conformityData.IsRefprocedure3 = this.conformityForm.value.IsRefprocedure3;
            this.conformityData.IsMinutecheckundisconnected = this.conformityForm.value.IsMinutecheckundisconnected;
            this.conformityData.IsMinutecheckclosetoundisconnected = this.conformityForm.value.IsMinutecheckclosetoundisconnected;
            this.conformityData.RiskAnalysisDate = this.conformityForm.value.RiskAnalysisDate
                ? HelperService.formatDateFilter(this.conformityForm.value.RiskAnalysisDate.formatted)
                : null;
            this.conformityData.IsCheckFinalInspection = this.conformityForm.value.IsCheckFinalInspection;
            this.conformityData.IsCheckFinalInspectionBelow = this.conformityForm.value.IsCheckFinalInspectionBelow;
            this.conformityData.IsContactProtection = this.conformityForm.value.IsContactProtection;
            this.conformityData.ContactProAcc = this.conformityForm.value.ContactProAcc;
            this.conformityData.ContactProComments = this.conformityForm.value.ContactProComments;
            this.conformityData.ISGrounding = this.conformityForm.value.ISGrounding;
            this.conformityData.GroundingAcc = this.conformityForm.value.GroundingAcc;
            this.conformityData.GroundingComments = this.conformityForm.value.GroundingComments;
            this.conformityData.IsMarking = this.conformityForm.value.IsMarking;
            this.conformityData.MarkingAcc = this.conformityForm.value.MarkingAcc;
            this.conformityData.MarkingCommnets = this.conformityForm.value.MarkingCommnets;
            this.conformityData.IsOptionsAndSettings = this.conformityForm.value.IsOptionsAndSettings;
            this.conformityData.OptionsAndSettingsAcc = this.conformityForm.value.OptionsAndSettingsAcc;
            this.conformityData.OptionsAndSettingsComments = this.conformityForm.value.OptionsAndSettingsComments;
            this.conformityData.ISAttachedConductor = this.conformityForm.value.ISAttachedConductor;
            this.conformityData.AttachedConductorAcc = this.conformityForm.value.AttachedConductorAcc;
            this.conformityData.AttachedConductorComments = this.conformityForm.value.AttachedConductorComments;
            this.conformityData.IsMeasurementsAndTests = this.conformityForm.value.IsMeasurementsAndTests;
            this.conformityData.MeasurementsAndTestsAcc = this.conformityForm.value.MeasurementsAndTestsAcc;
            this.conformityData.MeasurementsAndTestsComments = this.conformityForm.value.MeasurementsAndTestsComments;
            this.conformityData.IsInsulationMeasurement = this.conformityForm.value.IsInsulationMeasurement;
            this.conformityData.InsulationMeasurementAcc = this.conformityForm.value.InsulationMeasurementAcc;
            this.conformityData.InsulationMeasurementCommnets = this.conformityForm.value.InsulationMeasurementCommnets;
            this.conformityData.IsEarthleakCircuit = this.conformityForm.value.IsEarthleakCircuit;
            this.conformityData.EarthleakCircuitAcc = this.conformityForm.value.EarthleakCircuitAcc;
            this.conformityData.IsMinutecheckdisconnected = this.conformityForm.value.IsMinutecheckdisconnected;
            this.conformityData.EarthleakCircuitComments = this.conformityForm.value.EarthleakCircuitComments;
            this.conformityData.IsSafetyCircuits = this.conformityForm.value.IsSafetyCircuits;
            this.conformityData.SafetyCircuitsAcc = this.conformityForm.value.SafetyCircuitsAcc;
            this.conformityData.SafetyCircuitsComments = this.conformityForm.value.SafetyCircuitsComments;
            this.conformityData.IsCableRoutings = this.conformityForm.value.IsCableRoutings;
            this.conformityData.CableRoutingsAcc = this.conformityForm.value.CableRoutingsAcc;
            this.conformityData.CableRoutingsCommnets = this.conformityForm.value.CableRoutingsCommnets;
            this.conformityData.IsDocumentation = this.conformityForm.value.IsDocumentation;
            this.conformityData.DocumentationAcc = this.conformityForm.value.DocumentationAcc;
            this.conformityData.DocumentationCommnets = this.conformityForm.value.DocumentationCommnets;
            this.conformityData.InstallationDate = this.conformityForm.value.InstallationDate
                ? HelperService.formatDateFilter(this.conformityForm.value.InstallationDate.formatted)
                : null;
            this.conformityData.IsNewFacilities = this.conformityForm.value.IsNewFacilities;
            this.conformityData.IsExtension = this.conformityForm.value.IsExtension;
            this.conformityData.IsChange = this.conformityForm.value.IsChange;
            this.conformityData.ISUsednorms = this.conformityForm.value.ISUsednorms;
            this.conformityData.IsNEK = this.conformityForm.value.IsNEK;
            this.conformityData.IsOthers = this.conformityForm.value.IsOthers;
            this.conformityData.Others = this.conformityForm.value.Others;
            this.conformityData.ShortDescription = this.conformityForm.value.ShortDescription;
            this.conformityData.ConformityCompletedDate = this.conformityForm.value.ConformityCompletedDate
                ? HelperService.formatDateFilter(this.conformityForm.value.ConformityCompletedDate.formatted)
                : null;
            this.conformityData.CommentsToFacilities = this.conformityForm.value.CommentsToFacilities;
            this.conformityData.RiskAnalysisSignature = this.canvas.nativeElement.toDataURL();
            this.conformityData.InstallationSignature = this.canvas1.nativeElement.toDataURL();
            this.conformityData.ConformityCompletedsignature = this.canvas2.nativeElement.toDataURL();
            this.conformityData.BusinessId = BaseServices.BusinessId;
            this.conformityService.add(this.conformityData).then(result => {
                if (result) {
                    this.toasterComponent.callToast();
                    this.router.navigate(['../../../../details/'+this.projectId+'/document/list',{ Id: this.projectId }],
                    { relativeTo: this.route });
                }
            });

        }
    }

    exportPdf() {
        this.loading = true;
        let fileName = 'Declaration of conformity';
        let type = 'pdf';
        let position = 0;
        let cultureInfo = BaseServices.userCultureInfo();
        

        this.subscriptions.push(this.conformityService.getDeclaration(this.projectId).subscribe(result => {
            if(result==null){
                this.pdfButton =true;
            }
            else {
                this.pdfButton =false;
                let ReportJson = result;
                this.subscriptions.push(this.conformityService
                    .reportGenerate(BaseServices.BusinessId, cultureInfo, ReportJson)
                    .subscribe(
                    blob => {
                        this.loading = false;
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${fileName}.${type.toLowerCase()}`;
                        link.click();
                    },
                    error => {
                        this.loading = false;
                        alert('Export not downloaded');
                    }
                    ));
            }
        }))
    }

    public pdfHtml() {
        let pdf = new jsPDF();
        let options = {
            pagesplit: true
        };
        pdf.addHTML(this.el.nativeElement, 0, 0, options, () => {
            pdf.save('test.pdf');
        });
    }
    gotoList() {
        this.location.back();
    }

    setCheckBoxState(control1: any, control2: any, controlName: any) {
        if (control1.checked) {
            control2.checked = false;
        } else {
            control1.checked = false;
        }
        this.conformityForm.get(controlName).patchValue(control1.checked);
    }

    /**
     * sign click event
     */
    signClick1() {
        this.signList1 = true;
        let list = this.route.snapshot.data['sign'];
        if (list) {
            this.signData1 = list.filter(x => x.SignatureImg != null);
        }
    }
    signClick2() {
        this.signList2 = true;
        let list = this.route.snapshot.data['sign'];
        if (list) {
            this.signData2 = list.filter(x => x.SignatureImg != null);
        }
    }
    signClick3() {
        this.signList3 = true;
        let list = this.route.snapshot.data['sign'];
        if (list) {
            this.signData3 = list.filter(x => x.SignatureImg != null);
        }
    }

    onCloseSignEvent1() {
        this.signList1 = false;
    }
    onCloseSignEvent2() {
        this.signList2 = false;
    }
    onCloseSignEvent3() {
        this.signList3 = false;
    }

    selectSign1(sign: any) {
        this.signList1 = false;
        this.signImage1 = sign.SignatureImg;
    }
    selectSign2(sign: any) {
        this.signList2 = false;
        this.signImage2 = sign.SignatureImg;
    }
    selectSign3(sign: any) {
        this.signList3 = false;
        this.signImage3 = sign.SignatureImg;
    }
        
    @ViewChild('canvas',{static: false}) public canvas: ElementRef;
    @ViewChild('canvas1',{static: false}) public canvas1: ElementRef;
    @ViewChild('canvas2',{static:false}) public canvas2: ElementRef;
    
    
        private cx: CanvasRenderingContext2D;
        i = new Image;
        private cx1: CanvasRenderingContext2D;
        i1 = new Image;
        private cx2: CanvasRenderingContext2D;
        i2 = new Image;
    
        public ngAfterViewInit() {//canvas property adjustment after view child init.
    
            this.cx = null;
            const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
            this.cx = canvasEl.getContext('2d');
    
            canvasEl.width = 250;
            canvasEl.height = 150;
    
            this.cx.lineWidth = 3;
            this.cx.lineCap = 'round';
            this.cx.strokeStyle = '#000';
    
            this.captureEvents(canvasEl);

            this.cx1 = null;
            const canvasE2: HTMLCanvasElement = this.canvas1.nativeElement;
            this.cx1 = canvasE2.getContext('2d');
    
            canvasE2.width = 250;
            canvasE2.height = 150;
    
            this.cx1.lineWidth = 3;
            this.cx1.lineCap = 'round';
            this.cx1.strokeStyle = '#000';
    
            this.captureEvents1(canvasE2);

            this.cx2 = null;
            const canvasE3: HTMLCanvasElement = this.canvas2.nativeElement;
            this.cx2 = canvasE3.getContext('2d');
    
            canvasE3.width = 250;
            canvasE3.height = 150;
    
            this.cx2.lineWidth = 3;
            this.cx2.lineCap = 'round';
            this.cx2.strokeStyle = '#000';
    
            this.captureEvents2(canvasE3);
        }
        ngAfterViewChecked() {
           
                setTimeout(() => {
                    if(this.firstInit)
                    {
                        this.bindSign();
                    }
                    if(this.firstInit1)
                    {
                        this.bindSign1();
                    }
                    if(this.firstInit2)
                    {
                        this.bindSign2();
                    }                 
                    
                    
                }, 300);//binding saved sign after CanvasRenderingContext2D(cx) view init completed.
            
        }
    
        /**
         * canvas mouse event capturer.
         * @param canvasEl 
         */
        private captureEvents(canvasEl: HTMLCanvasElement) {
            this.subscriptions.push( Observable
                .fromEvent(canvasEl, 'mousedown')
                .switchMap((e) => {
                    return Observable
                        .fromEvent(canvasEl, 'mousemove')
                        .takeUntil(Observable.fromEvent(canvasEl, 'mouseup'))
                        .pairwise()
                })
                .subscribe((res: [MouseEvent, MouseEvent]) => {
                    const rect = canvasEl.getBoundingClientRect();
    
                    const prevPos = {
                        x: res[0].clientX - rect.left,
                        y: res[0].clientY - rect.top
                    };
    
                    const currentPos = {
                        x: res[1].clientX - rect.left,
                        y: res[1].clientY - rect.top
                    };
    
                    this.drawOnCanvas(prevPos, currentPos);
                }));
        }
        private captureEvents1(canvasE2: HTMLCanvasElement) {
            this.subscriptions.push(Observable
                .fromEvent(canvasE2, 'mousedown')
                .switchMap((e) => {
                    return Observable
                        .fromEvent(canvasE2, 'mousemove')
                        .takeUntil(Observable.fromEvent(canvasE2, 'mouseup'))
                        .pairwise()
                })
                .subscribe((res1: [MouseEvent, MouseEvent]) => {
                    const rect1 = canvasE2.getBoundingClientRect();
    
                    const prevPos1 = {
                        x: res1[0].clientX - rect1.left,
                        y: res1[0].clientY - rect1.top
                    };
    
                    const currentPos1 = {
                        x: res1[1].clientX - rect1.left,
                        y: res1[1].clientY - rect1.top
                    };
    
                    this.drawOnCanvas1(prevPos1, currentPos1);
                }));
        }
        private captureEvents2(canvasE3: HTMLCanvasElement) {
            this.subscriptions.push(Observable
                .fromEvent(canvasE3, 'mousedown')
                .switchMap((e) => {
                    return Observable
                        .fromEvent(canvasE3, 'mousemove')
                        .takeUntil(Observable.fromEvent(canvasE3, 'mouseup'))
                        .pairwise()
                })
                .subscribe((res2: [MouseEvent, MouseEvent]) => {
                    const rect2 = canvasE3.getBoundingClientRect();
    
                    const prevPos2 = {
                        x: res2[0].clientX - rect2.left,
                        y: res2[0].clientY - rect2.top
                    };
    
                    const currentPos2 = {
                        x: res2[1].clientX - rect2.left,
                        y: res2[1].clientY - rect2.top
                    };
    
                    this.drawOnCanvas2(prevPos2, currentPos2);
                }));
        }
    
        /**
         * draw on canvas function.
         * @param prevPos 
         * @param currentPos 
         */
        private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
            if (!this.cx) { return; }
    
            this.cx.beginPath();
    
            if (prevPos) {
                this.cx.moveTo(prevPos.x, prevPos.y); // from
                this.cx.lineTo(currentPos.x, currentPos.y);
                this.cx.stroke();
            }
        }
        private drawOnCanvas1(prevPos1: { x: number, y: number }, currentPos1: { x: number, y: number }) {
            if (!this.cx1) { return; }
    
            this.cx1.beginPath();
    
            if (prevPos1) {
                this.cx1.moveTo(prevPos1.x, prevPos1.y); // from
                this.cx1.lineTo(currentPos1.x, currentPos1.y);
                this.cx1.stroke();
            }
        
        }
        private drawOnCanvas2(prevPos2: { x: number, y: number }, currentPos2: { x: number, y: number }) {
            if (!this.cx2) { return; }
    
            this.cx2.beginPath();
    
            if (prevPos2) {
                this.cx2.moveTo(prevPos2.x, prevPos2.y); // from
                this.cx2.lineTo(currentPos2.x, currentPos2.y);
                this.cx2.stroke();
            }
        
        }
    
        /**
         * clear signature from canvas.
         */
        clearSign() {
            this.firstInit = false;
            this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
        }
        clearSign1() {
            this.firstInit1 = false;
            this.cx1.clearRect(0, 0, this.canvas1.nativeElement.width, this.canvas1.nativeElement.height)
        }
        clearSign2() {
            this.firstInit2 = false;
            this.cx2.clearRect(0, 0, this.canvas2.nativeElement.width, this.canvas2.nativeElement.height)
        }
    
        /**
         * bind signature to canvas.
         */
        bindSign() {
            if (this.signImage1) {
                this.i.src = this.signImage1;
                this.cx.drawImage(this.i, 0, 0);
            }
        }
        bindSign1() {
            if (this.signImage2) {
                this.i1.src = this.signImage2;
                this.cx1.drawImage(this.i1, 0, 0);
            }
        }
        bindSign2() {
            if (this.signImage3) {
                this.i2.src = this.signImage3;
                this.cx2.drawImage(this.i2, 0, 0);
            }
        }

        ngOnDestroy() {
            this.subscriptions.forEach((sub, i) => {
              sub.unsubscribe();
            });
          }
    }

