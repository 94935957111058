<div class="page-title">
    <span class="page-actions">
        <button class="btn btn-primary" (click)="DownloadListPDF()">
            <span class="icon ic-sm icon-print"></span>
            <span translate>PRINT_AS_PDF</span>
        </button>
        <a *ngIf="inspectionId && isGuestHideButton" [routerLink]="['../',inspectionId,0]" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span>
                <span translate>NEW</span>
            </span>
        </a>
    </span>
</div>
<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="deviationList" #deviationTable [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Footer -->
            <ng-template pTemplate="summary">
                <div class="symbol-descriptions">
                    <div class="symbol-desc-title">
                        <span translate>DESCRIPTION_SYMBOLS</span>
                    </div>
                    <div class="symbol-desc-items">
                        <span class="far-deadline">
                            <i class="icon ic-md icon-far-deadline"></i>
                            <span translate>MORE_DEADLINE</span>
                        </span>
                        <span class="over-deadline">
                            <i class="icon ic-md icon-over-deadline"></i>
                            <span translate>OVERDEADLINE</span>
                        </span>
                        <span class="near-deadline">
                            <i class="icon ic-md icon-near-deadline"></i>
                            <span translate>LESS_DEADLINE</span>
                        </span>
                        <span class="clear-deadline">
                            <i class="icon ic-md icon-clear-deadline"></i>
                            <span translate>DONE_STATUS</span>
                        </span>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="InspectionNumber">{{'INSPECTION_NO' | translate}} <p-sortIcon field="InspectionNumber"></p-sortIcon></th>
                    <th>{{'STATUS'| translate}}</th>
                    <th pSortableColumn="DeviationNumber">{{'DEVIATION_NUMBER'| translate}} <p-sortIcon field="DeviationNumber"></p-sortIcon></th>
                    <th pSortableColumn="ClientName">{{'CLIENT' | translate }} <p-sortIcon field="ClientName"></p-sortIcon></th>
                    <th pSortableColumn="Title">{{'TITLE' | translate }} <p-sortIcon field="Title"></p-sortIcon></th>
                    <th pSortableColumn="Description">{{'DESCRIPTION' | translate}} <p-sortIcon field="Description"></p-sortIcon></th>
                    <th>{{'IMPORTANCE'| translate}}</th>
                    <th pSortableColumn="Deadline">{{'DEADLINE' | translate}} <p-sortIcon field="Deadline"></p-sortIcon></th>
                    <th>{{'RESPONSIBLE_PERSON'| translate}}</th>
                    <th pSortableColumn="CompletedDate">{{'DONE_ON' | translate}} <p-sortIcon field="CompletedDate"></p-sortIcon></th>
                    <th>{{'OPTIONS'| translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="deviationTable.filter($event.target.value, 'InspectionNumber', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <p-dropdown [options]="statuses" (onChange)="onStatusChange($event, ad)" #ad
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="deviationTable.filter($event.target.value, 'DeviationNumber', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="deviationTable.filter($event.target.value, 'ClientName', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="deviationTable.filter($event.target.value, 'Title', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <input pInputText type="text"
                            (input)="deviationTable.filter($event.target.value, 'Description', 'contains')"
                            placeholder="" class="p-column-filter">
                    </th>
                    <th>
                        <p-dropdown [options]="importance" (onChange)="onImportanceChange($event, cd)" #cd
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-imp pTemplate="item"> {{ imp.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th>
                        <p-dropdown [options]="execution" (onChange)="onResponsibleChange($event, ed)" #ed
                            styleClass="p-column-filter" placeholder="Select" [filterBy]="null" [showClear]="true">
                            <ng-template let-res pTemplate="item"> {{ res.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-dev>
                <tr>
                    <td>
                        <span>{{dev.InspectionNumber}}</span>
                    </td>
                    <td>
                        <span>{{dev.StatusText.toUpperCase() | translate}}</span>
                    </td>
                    <td>
                        <a pTooltip="{{dev.DeviationNumber}}"
                            [routerLink]="['../',dev.InspectionId,dev.Id]">{{dev.DeviationNumber}}</a>
                    </td>
                    <td>
                        <span>{{dev.ClientName}}</span>
                    </td>
                    <td>
                        <div pTooltip="{{dev.Title}}" tooltipPosition="top">{{dev.Title}}</div>
                    </td>
                    <td>
                        <div pTooltip="{{dev.Description}}" tooltipPosition="bottom">{{dev.Description}}</div>
                    </td>
                    <td>
                        <span class="badge"
                            [style.background-color]="dev.ImportanceText=='Low'?'#4ec55c':(dev.ImportanceText=='Medium'?'#f0ac19':(dev.ImportanceText=='High'?'#e7644f':''))">
                            {{dev.ImportanceText | translate}}</span>
                    </td>
                    <td>
                        <span>{{dev.Deadline | date : 'dd.MM.yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{dev.ResponsiblePersonName}}</span>
                    </td>
                    <td>
                        <span>{{dev.CompletedDate | date : 'dd.MM.yyyy'}}</span>
                    </td>
                    <td class="col-button">
                        <button *ngIf="isGuestHideButton" type="button" class="btn btn-icon"
                            (click)="deleteDeviation(dev['Id'])" title="{{'DELETE' | translate}}">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                        <button class="btn btn-icon" (click)="DeviationReport(dev['Id'])"
                            pTooltip="{{'DOWNLOAD_ATTACHMENT' | translate}}">
                            <i class="icon ic-sm icon-send-file-o"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="11">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->