import { Component, OnInit } from '@angular/core';

@Component({
    template: '<router-outlet></router-outlet>'
})

export class LicenseAgreementComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }
}
