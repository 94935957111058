import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyFeaturesComponent } from './my-features.component';
import { MyFeaturesListComponent } from './components/my-features-list.component';

import { AppSharedModule } from './../shared/appShared.module';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ReactiveFormsModule,
        ContextMenuModule,
        MenuModule,
        ConfirmDialogModule,
        AppSharedModule
    ],
    declarations: [MyFeaturesListComponent, MyFeaturesComponent],
})
export class MyFeaturesModule {}
