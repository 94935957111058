<a href="javascript:void(0)" (click)="showModal()" id="article-file-{{id}}">
  <img class="icon ic-lg" src="/assets/file-icons/kb-checklist.png" alt="Checklist File">
</a>

<div class="checklist-modal" *ngIf="showModalWindow" id="article-file-{{id}}">
  <!-- // New and Edit -->

  <div class="modal-dialog" *ngIf="editMode">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          <span class="ic-md">
            <img src="/assets/file-icons/kb-checklist.png" alt="checklist file">
          </span>
          <span *ngIf="checkListData.checkList.id == 0" translate>
            NEW_CHECKLIST
          </span>
          <span *ngIf="checkListData.checkList.id != 0" translate>
            EDIT_CHECKLIST
          </span>
        </h4>
        <button (click)="hideModal()" class="close">
          <span class="icon ic-md icon-multiply"></span>
        </button>
      </div>
      <div class="modal-body" id="checklist-pop-{{id}}" *ngIf="checkListData">
        <div class="modal-content-left">
          <div class="form">
            <div class="form-group">
              <label for="clName" translate>TITLE</label>
              <input type="text" [readonly]="!checkListData.checkList.isEditable" class="form-control"
                [(ngModel)]="checkListData.checkList.title" id="clName" placeholder="{{'TITLE'|translate}}">
            </div>
            <div class="form-group">
              <label for="clCreated" translate>CREATED_DATE</label>
              <input type="text" class="form-control" id="clCreated"
                value="{{checkListData.checkList.createdOn | date:'dd/MM/yyyy'}}" readonly>
            </div>
            <div class="checkbox">
              <Label translate>
                <input type="checkbox" [readonly]="!checkListData.checkList.isEditable"
                  (change)="updateScoreIndex($event)" name="scoreIndex" id="scoreIndex"
                  [ngModel]="checkListData.checkList.scoreIndex">SCORE_INDEX</Label>
            </div>
          </div>
          <div class="form"
            *ngIf="checkListData.checkList.id == 0 && (featureType ==='Manuals'|| featureType ==='Assign Manuals') ">
            <div class="form-group">
              <label class="sr-only" for="cltemplates" translate>TEMPLATES</label>
              <p-dropdown [options]="checklistTemplateList" [(ngModel)]="copyChecklistId" [filter]="true"
                placeholder="{{'SELECT_CHECKLIST'|translate}}"></p-dropdown>
            </div>
            <button type="button" [disabled]="validateCopyCheckList()" class="btn btn-outline-secondary"
              (click)="copyManualCheckList()" translate>COPY_SELECTED_CHECKLIST</button>
          </div>
        </div>
        <div class="modal-content-right">
          <div *ngIf="checkListData.checkList.sections">
            <ng-template ngFor let-section [ngForOf]="checkListData.checkList.sections" let-seci="index"
              let-secLast="last">
              <div class="cl-list my-3" *ngIf="section.status != 0">
                <div class="cl-list-heading">
                  <table class="table m-0">
                    <tbody>
                      <tr>
                        <td style="width:60px">
                          <input type="text" class="form-control" [(ngModel)]="section.position" name="clSecPoint"
                            id="clSecPoint" placeholder="{{'POINT'|translate}}" readonly>
                        </td>
                        <td>
                          <input type="text" [readonly]="!checkListData.checkList.isEditable" class="form-control"
                            [(ngModel)]="section.title" name="clSecTitle" id="clSecTitle"
                            placeholder="{{'SECTION'|translate}}">
                        </td>
                        <ng-template [ngIf]="checkListData.checkList.isEditable">
                          <td class="text-right col-button" style="width:80px;text-align:right;">
                            <button type="button" class="btn btn-icon" (click)="addItem(section, seci)">
                              <span class="icon ic-sm icon-add"></span>
                            </button>
                            <button type="button" class="btn btn-icon"
                              (click)="removesection(checkListData.checkList, section)">
                              <span class="icon ic-sm icon-trash"></span>
                            </button>
                          </td>
                        </ng-template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="cl-item" *ngIf="section.items">
                  <ng-template ngFor let-item [ngForOf]="section.items" let-itemi="index" let-itemLast="last">
                    <div *ngIf="item.status != 0">
                      <table class="table m-0">
                        <tbody>
                          <tr>
                            <td style="width:60px">
                              <input type="text" class="form-control form-control-sm" id="point-{{item.id}}"
                                name="position-{{item.id}}" placeholder="{{'POINT'|translate}}"
                                value="{{section.position}}.{{item.position}}" readonly>
                            </td>
                            <td>
                              <input type="text" [readonly]="!checkListData.checkList.isEditable"
                                class="form-control form-control-sm" id="point-{{item.id}}" name="title-{{item.id}}"
                                [(ngModel)]="item.title" style="max-width:100%" placeholder="">
                            </td>
                            <td style="width:80px">
                              <Label>
                                <input type="checkbox" [readonly]="!checkListData.checkList.isEditable"
                                  (change)="updateItemStatus($event, item)" name="item" id="item"
                                  [ngModel]="item.status === 1 ? true: false">
                              </Label>
                            </td>
                            <td class="col-button" style="width:80px;text-align:right;">
                              <span *ngIf="checkListData.checkList.isEditable">
                                <button type="button" class="btn btn-icon" (click)="removeItem(section, item)">
                                  <span class="icon ic-sm icon-trash"></span>
                                </button>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-template>
            <div class="action-btns-wrapper text-right" *ngIf="checkListData.checkList.isEditable">
              <button type="button" class="btn btn-outline-success" (click)="addNewSection(checkListData.checkList)"
                translate>
                <span class="icon ic-sm icon-add"></span>NEW_SECTION</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <span *ngIf="checkListData.checkList.isEditable">
          <button type="button" class="btn btn-primary" [disabled]="validation()" (click)="saveChecklist()"
            translate>SAVE</button>
        </span>
      </div>
    </div>
  </div>

  <!-- // View Checklist -->
  <div class="modal-dialog" *ngIf="!editMode && checkListData.checkList.id != 0">
    <div class="modal-content">
      <div class="modal-header">
        <h4>
          <span class="ic-md">
            <img src="/assets/file-icons/kb-checklist.png" alt="Checklist file">
          </span>
          <span>
            {{checkListData.checkList.title}}
          </span>
        </h4>
        <button (click)="hideModal()" class="btn btn-icon close">
          <span class="icon ic-md icon-multiply"></span>
        </button>
      </div>

      <div class="modal-body" id="checklist-pop-{{id}}" *ngIf="checkListData">
        <div *ngIf="checkListData.checkList.sections">
          <ng-template ngFor let-section [ngForOf]="checkListData.checkList.sections" let-parent="index">
            <div class="cl-list my-3">
              <div class="cl-list-heading">
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th style="width:55px;">{{parent+1}}</th>
                      <th>{{section.title}}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <ng-template ngFor let-item [ngForOf]="section.items" let-child="index">
                <div class="cl-item" *ngIf="section.items">
                  <table class="table m-0">
                    <tbody>
                      <tr>
                        <td *ngIf="item.status === 1" style="width:55px;">
                          {{parent+1}} . {{child+1}}
                        </td>
                        <td *ngIf="item.status === 1">{{ item.title}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="modal-footer">
        <span *ngIf="isChecklistRights && featureType!='Manuals'">
          <button type="button" class="btn btn-primary" (click)="completeChecklist()"
            translate>COMPLETE_CHECKLIST</button>
        </span>
        <button *ngIf="editMode" type="button" class="btn btn-primary" (click)="saveChecklist()" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
<p-dialog header="{{'NOTIFICATION'| translate}}" [(visible)]="showCompleteChecklist" [style]="{width: '600px'}"
  [resizable]="false" [draggable]="false">
  <div class="form-group">
    <h5 class="notifi-title">
      <span translate>CHECKLIST_NOTIFICATION</span>
    </h5>
  </div>
</p-dialog>