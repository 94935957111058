
export class Flexi {
    Id: number;
    Name: string;
    Address: string;
    ZipCode: string;
    Mobile: string;
    Email: string;
}

export class OHS_Activty {
    Id: number;
    No: number;
    Activity: string;
    Comment: string;
    OHS_Advise: boolean;
    OHS_Not_Advise: boolean;
    Initiator: number;
    RegisteredActivity: boolean;
    Deadline: any;
    CreatedOn: Date;
    CreatedBy: number;
    ModifiedOn: Date;
    ModifiedBy: number;
    OhsPerformedId: number;
}

export class OHS_PerformedDetails {
    Id: number;
    PerformedBy: string;
    PeriodFrom: any; 
    PeriodTo: any;
    OHS_PlanId: number;
    BusinessId: number
    OHS_Activity: OHS_Activty[]; 
}