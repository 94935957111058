<div class="table-view">
    <!-- Table starts -->
    <p-table [value]="internalControlData" #dt [rows]="10" [paginator]="dt.totalRecords > 0" [pageLinks]="3"
      [rowsPerPageOptions]="[5,10,20]"
      [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData">
                        </kuba-export>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th width="50px">{{'SERIAL_NO' | translate}}</th>
                <th pSortableColumn="Title">{{'NAME_ON_SYSTEM' | translate}} <p-sortIcon field="Title"></p-sortIcon></th>
                <th pSortableColumn="Description">{{'DESCRIPTION' | translate}} <p-sortIcon field="Description"></p-sortIcon></th>
                <th width="100px">{{'ASSIGNED_ACCESS' | translate}}</th>
                <th width="140px">{{'DEVIATIONS_IN_FOLLOWUP' | translate}}</th>
                <th width="90px">{{'OPTIONS'| translate}}</th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'Title', 'contains')"
                      placeholder="" class="p-column-filter">
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-internal let-ic="rowIndex">
            <tr>
                <td>
                    <span>{{ic+1}}</span>
                </td>
                <td>
                    <a [routerLink]="['./../../details',internal.Id]" translate>{{internal.Title}}</a>
                </td>
                <td>
                    <span>{{internal.Description}}</span>
                </td>
                <td>
                    <button type="button" class="btn btn-link" title="{{'View users' | translate}}"
                      (click)="showPortalInfo(internal.QualitySystemUsers)">
                        <span>{{ internal.UserCount !== null ? internal.UserCount : ''}} </span>
                    </button>
                </td>
                <td>
                    <input type="checkbox" [disabled]="isDisabled" [(ngModel)]="internal.ShowDeviation" />
                </td>
                <td class="col-button">
                    <a *ngIf="isAdministrateIC" routerLink="create" [routerLink]="['./../../create',internal.Id]"
                      class="btn btn-icon" title="{{'EDIT'|translate}}" routerLinkActive="active">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </a>
                    <button type="button" *ngIf="isHideDeleteIcon" class="btn btn-icon" title="{{'DELETE'|translate}}"
                      (click)="confirm(internal.Id)">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
</div>

<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<p-dialog header="{{'QUALITY_SYSTEMS_LIST'|translate}}" [(visible)]="showDialog" [modal]="true" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false"
  (onHide)="onHide()">
    <div class="table-view">
        <!-- Table starts -->
        <p-table #et [value]="list" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'NAME'|translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text" (input)="et.filter($any($event.target)?.value, 'NameList', 'contains')"
                          placeholder="" class="p-column-filter" >
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-internal>
                <tr>
                    <td>
                        <span>{{internal.NameList}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="1">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</p-dialog>
<toaster-component></toaster-component>