<div class="top-header"></div>

<div class="card-body">
    <div class="page-title">
        <span>FAQ</span>
        <span class="page-actions pull-right">
            <p-dropdown [filter]="true" class="text-left" [options]="Faq" [(ngModel)]="selectedCategory"
              (onChange)="onFaqChanged($event)">
                <ng-template let-fa pTemplate="item"> {{ fa.label }}
                </ng-template>
            </p-dropdown>
        </span>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h3 class="text-primary">{{selectedUrl ? selectedUrl.name : ''}}</h3>
            <strong *ngIf="selectedUrl" [innerHTML]="selectedUrl.ContentData"></strong>
        </div>
        <div class="col-sm-12" *ngIf="selectedUrl;else othercontent">
            <div>
                <video *ngIf="selectedUrl.VideoPath" width=100% controls>
                    <source src={{selectedUrl.VideoPath}} type="video/mp4">
                </video>
                <div *ngIf="!selectedUrl.VideoPath">
                    <span translate>No Video to display</span>
                </div>
            </div>
        </div>
        <ng-template class="col-sm-12" #othercontent>
            <p>FAQ (Frequently Asked Questions) is a new feature of Kuba IK. We are now working to fill the pages with
                content,
                which will be a mix of questions from users as well as useful user tips. Choose category and we hope you
                get what you're wondering. Please contact us if you can not find what you are looking for.</p>
            <p>You can find contact information for support under "Contacts" in the left menu on the main page. Click on
                the
                tab "Cuba Norway AS" aaa</p>
            <p>You can also check out our contact page at www.kuba.no</p>
        </ng-template>
    </div>
</div>