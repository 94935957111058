import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElectroService } from '../services/electro.services';
import { SelectItem } from 'primeng/api';
import { ChecklistModel } from 'src/app/shared/ecpl-checklist-viewer/ecpl-checklist-viewer.model';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { UploaderOptions } from 'ngx-uploader';
import { PaginationInstance } from 'ngx-pagination';
import { ElectroDocumentService } from '../services/electro-document.services';
import { ElectroChecklistItemUpdate, ParentItem } from 'src/app/kuba/feature-documents/feature-document.models';
import { ChildItem } from 'src/app/kuba/manuals/models/manual-checklist.model';
import { isArray } from 'util';
import { ElectroFeature } from '../models/electrofeatures';
import { KubaBulkReportService } from 'src/app/shared/ecpl-document-explorer/file-window.service';
import { Roles } from 'src/app/_models';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'inspection-report-template',
    templateUrl: 'inspection-report-template.component.html'
})

export class InspectionReportTemplateComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('checklistTable',{static: false}) electroTable: Table;
    @ViewChild('hiddenAction',{static: false}) hiddenAction: ElementRef;
    reportDropdown: SelectItem[];
    checkList: any;
    newchecklist: any;
    file: any;
    options: UploaderOptions;
    query: any;
    pgConfig: PaginationInstance = { 
        itemsPerPage: 5,
        currentPage: 1
    };
    featureId: number;
    featureKey: number;
    isTemplate = false;
    selectedReport : number;
    isAdmin = false;
    public selectedPageSize = this.pgConfig.itemsPerPage;
    private subscriptions: Subscription[] = [];

    constructor(public router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private electroService: ElectroService,
        private electroDocumentServices: ElectroDocumentService,
        private bulkReportService: KubaBulkReportService,
        private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.checkList = this.route.snapshot.data['checklist'];
        this.featureId = ElectroFeature.INSPECTION_REPORT_TEMPLATES;
        this.featureKey = this.route.snapshot.parent.params['Id'];
        this.isAdmin= (+BaseServices.roleId) == Roles.ADMIN ;
        this.reportDropdown = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.reportDropdown = [];
            this.reportDropdown.push(
                { label: val.SELECT, value: null });
        }));
        this.checkList.forEach(cl => {
            this.reportDropdown.push(
                { label: cl.content.checkList.title, value: cl.content.checkList.id });
        });
        this.featureKey = this.featureKey ? this.featureKey : 0;
    }

    /**
     * checklist template change event.
     * @param event 
     */
    onTemplateChanged(event) {
     this.isTemplate = event.value !=null ? true : false ;
    }

    /**
     * copy selected checklist and create new event
     */
    copyCheckList(){
        if(this.selectedReport){
            this.file = {};
        this.file = new ChecklistModel();
        this.file.filename = '';
        this.file.originalFileName = '';
        this.file.path = '';
        this.file.mimetype = 'checklist';
        this.file.size = 0;
        this.file.isFolder = false;
        this.file.type = 'file';
        this.file.isDefault = false;
        this.file.documentType = 'checklist';
        this.file.status = 1;
        this.file.createdBy = BaseServices.UserId;
        this.file.createdOn = new Date();
        this.file.parentFolderId = 0;
        this.file.documentTypeId = 0;
        this.checkList.forEach(cl => {
            if(this.selectedReport === cl.content.checkList.id){
                let sections = cl.content.checkList.sections;
                if(sections){
                    sections.forEach(s => {
                        s.id =0;
                        s.items.forEach(i => {
                            i.id=0
                        });
                    });
                }
                this.file.content = {
                    checkList: {
                        id: 0,
                        title: '',               
                        status: 1,
                        isEditable: true,
                        createdOn: new Date(),
                        sections: sections
                    }
                };
            }
        });
        this.cdr.detectChanges();
        this.triggerFalseClick();
        }
    }

    /**
     * create new checklist event
     */
    createCheckList() {
        // open empty checklist viewer
        this.file = {};
        this.file = new ChecklistModel();
        this.file.filename = '';
        this.file.originalFileName = '';
        this.file.path = '';
        this.file.mimetype = 'checklist';
        this.file.size = 0;
        this.file.isFolder = false;
        this.file.type = 'file';
        this.file.isDefault = false;
        this.file.documentType = 'checklist';
        this.file.status = 1;
        this.file.createdBy = BaseServices.UserId;
        this.file.createdOn = new Date();
        this.file.parentFolderId = 0;
        this.file.documentTypeId = 0;
        this.file.content = {
            checkList: {
                id: 0,
                title: '',               
                status: 1,
                isEditable: true,
                createdOn: new Date(),
                sections: [
                    {
                        id: 0,
                        position: 1,
                        title: '',
                        status: 1,
                        items: [
                            {
                                id: 0,
                                position: 1,
                                title: '',
                                status: 1
                            }
                        ]
                    }
                ]
            }
        };
        this.cdr.detectChanges();
        this.triggerFalseClick();
    }

    /**
     * edit checklist event.
     * @param checklist 
     */
    editCheckList(checklist){
        this.file = checklist;
        this.cdr.detectChanges();
        this.triggerFalseClick();
    }

    /**
     * false trigger for checklist model
     */
    triggerFalseClick() {
        let el: HTMLElement = this.hiddenAction.nativeElement.querySelector(
            'a'
        );
        el.click();
    }

    /**
     * save uploaded changes to checklist event.
     * @param event 
     */
    saveUploaded(event: any) {
        switch (event.eventName) {
            case 'NewChecklist':
                this.catchEvent(event);
                break;
            case 'SaveChecklist':
                this.catchEvent(event);
                break;
            default:
                break;
        }
    }
    
//#region  bind checklist section and items
    private checklistSaveObj(model, modifiedBy) {
        let listModel = new ElectroChecklistItemUpdate();
        listModel.title = model.item.checkList.title;
        listModel.userId = BaseServices.UserId;
        listModel.parentItems = [];
        listModel.ModifiedBy = modifiedBy;
        if (model.item.checkList.sections.length > 0) {
            model.item.checkList.sections.forEach((ParentItem) => {
                let section = this.checklistSecObj(ParentItem);
                listModel.parentItems.push(section);

            });
        }

        return listModel;
    }
    private checklistSecObj(obj) {
        let section = new ParentItem();
        section.parentItemId = obj.id;
        section.parentId = 0;
        section.parentItemPoint = obj.position;
        section.parentItemTitle = obj.title;
        section.parentItemStatus = obj.status;
        section.childItems = this.checklistItemObj(obj.items);
        return section;
    }

    private checklistItemObj(objItem) {
        let items: ChildItem[] = [];
        if (isArray(objItem)) {
            objItem.forEach((item:any) => {
                items.push({
                    childItemId: item.id,
                    childItemTitle: item.title,
                    childItemPoint: item.position,
                    isView: item.status
                });
            });
        }
        return items;
    }
    //#endregion

    /**
     * catch checklist model events.
     * @param event 
     */
    catchEvent(event): void {
        switch (event.eventName) {
            case 'NewChecklist':
                this.subscriptions.push(this.electroDocumentServices
                    .createChecklist(this.featureId, this.featureKey, event).subscribe(
                        response => {
                            let checklistId = response.Id;
                            let modifiedBy = response.modifiedBy;
                            let listModel = this.checklistSaveObj(event, 0);
                            this.subscriptions.push(this.electroDocumentServices.saveChecklist(checklistId, listModel, modifiedBy).subscribe(
                                response => {
                                    this.subscriptions.push(this.electroService.getReportChecklist(this.featureKey).subscribe(result=>{
                                        if(result){
                                            this.toasterComponent.callToast();
                                            this.checkList = result;
                                        }
                                    }));
                                },
                                error => console.log('Save checklistitem error : ', error),
                                () => console.log('save checklistitem Complete')
                            ));
                        },
                        error => console.log('Save checklist error : ', error),
                        () => console.log('save checklist Complete')
                    ));
                break;
            case 'SaveChecklist':
                let tempchecklistId = event.item.checkList.id;
                let templistModel = this.checklistSaveObj(event, BaseServices.UserId);
                let templistModifiedBy = event.item.checkList.modifiedBy;
                this.electroDocumentServices.saveChecklist(tempchecklistId, templistModel, templistModifiedBy).subscribe(
                    response => {
                        this.subscriptions.push(this.electroService.getReportChecklist(this.featureKey).subscribe(result=>{
                            if(result){
                                this.toasterComponent.callToast();
                                this.checkList = result;
                            }
                        }));
                    },
                    error => console.log('Save checklistitem error : ', error),
                    () => console.log('save checklistitem Complete')
                );
                break;
            case 'EditChecklist':
                let checklistId = event.item.checkList.id;
                let listModel = this.checklistSaveObj(event, BaseServices.UserId);
                let modifiedBy = event.item.checkList.modifiedBy;
                this.subscriptions.push(this.electroDocumentServices.saveChecklist(checklistId, listModel, modifiedBy).subscribe(
                    response => {
                        this.subscriptions.push(this.electroService.getReportChecklist(this.featureKey).subscribe(result=>{
                            if(result){
                                this.toasterComponent.callToast();
                                this.checkList = result;
                            }
                        }));
                    },
                    error => console.log('Save checklist error : ', error),
                    () => console.log('save checklist Complete')
                ));

                break;
        }
    }

    /**
     * checklist print event.
     * @param node 
     */
    printChecklist(node) {
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        this.subscriptions.push(this.electroDocumentServices
            .generateChecklistReport(node.content.checkList.id, this.featureKey, BaseServices.UserId
            , CultureInfo)
            .subscribe(
                blob => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${node.filename}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    alert('Export not downloaded');
                }));
    }

    deleteChecklist(checklistId){
        this.subscriptions.push(this.electroDocumentServices.deleteChecklist(checklistId).subscribe(result =>{
            if(result){
                this.toasterComponent.callToastDlt();
                this.subscriptions.push(this.electroService.getReportChecklist(this.featureKey).subscribe(res=>{
                            if(res){
                                this.checkList = res;
                            }
                        }));
            }
        }))
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}