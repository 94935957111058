<div class="card-body">
    <div class="page-title">
        <span translate>ELECTRICAL_DISTRIBUTION_OVERVIEW</span>
    </div>
    <div class="row" *ngIf="editMode">
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="8"><span translate>MAIN_AND_SUBDISTRIBUTIONS_OVERVIEW</span></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th style="text-align:center"><span translate>FLOOR</span></th>
                        <th style="text-align:center"><span translate>ROOMS_NUMBER</span></th>
                        <th style="text-align:center"><span translate>DISTRIBUTION_ID</span></th>
                        <th style="text-align:center"><span translate>MAIN_DISTRIBUTION</span></th>
                        <th style="text-align:center"><span translate>SUB_DISTRIBUTION</span></th>
                        <th style="text-align:center"><span translate>MEASURE_NUMBER</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of distributionItems">
                        <td class="col-button" style="width:120px" *ngIf="item.Status !== 0">
                            <button type="button" (click)="addItem(item)" class="btn btn-icon">
                                <span class="icon ic-sm icon-add"></span>
                            </button>
                            <button *ngIf="hideDelete()" type="button" (click)="deleteItem(item)" class="btn btn-icon">
                                <span class="icon ic-sm icon-trash"></span>
                            </button>
                        </td>
                        <td style="width:100px" *ngIf="item.Status !== 0">
                            <input type="text" class="form-control" [(ngModel)]="item.Floor" />
                        </td>
                        <td style="width:100px" *ngIf="item.Status !== 0">
                            <input type="text" class="form-control" [(ngModel)]="item.RoomNo" />
                        </td>
                        <td style="width:100px" *ngIf="item.Status !== 0">
                            <input type="text" class="form-control" [(ngModel)]="item.DistributionId" />
                        </td>
                        <td style="text-align:center;width:80px" *ngIf="item.Status !== 0">
                            <p-checkbox binary="true" [ngModel]="item.DistributionType === 1"
                                (onChange)="currentTypeChecked(item, 1)"></p-checkbox>
                        </td>
                        <td style="text-align:center;width:80px" *ngIf="item.Status !== 0">
                            <p-checkbox binary="true" [ngModel]="item.DistributionType === 2"
                                (onChange)="currentTypeChecked(item, 2)"></p-checkbox>
                        </td>
                        <td style="width:100px" *ngIf="item.Status !== 0">
                            <input type="text" class="form-control" [(ngModel)]="item.MeasureNumber" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="!editMode">
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="7"><span translate>MAIN_AND_SUBDISTRIBUTIONS_OVERVIEW</span></th>
                    </tr>
                    <tr>
                        <th style="text-align:center"><span translate>FLOOR</span></th>
                        <th style="text-align:center"><span translate>ROOMS_NUMBER</span></th>
                        <th style="text-align:center"><span translate>DISTRIBUTION_ID</span></th>
                        <th style="text-align:center"><span translate>MAIN_DISTRIBUTION</span></th>
                        <th style="text-align:center"><span translate>SUB_DISTRIBUTION</span></th>
                        <th style="text-align:center"><span translate>MEASURE_NUMBER</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of distributionItems">
                        <td style="width:100px"><span>{{item.Floor}}</span></td>
                        <td><span>{{item.RoomNo}}</span></td>
                        <td><span>{{item.DistributionId}}</span></td>
                        <td style="text-align:center" *ngIf="item.Status !== 0">
                            <p-checkbox binary="true" [ngModel]="item.DistributionType === 1" disabled
                                (onChange)="currentTypeChecked(item, 1)"></p-checkbox>
                        </td>
                        <td style="text-align:center" *ngIf="item.Status !== 0">
                            <p-checkbox binary="true" [ngModel]="item.DistributionType === 2" disabled
                                (onChange)="currentTypeChecked(item, 2)"></p-checkbox>
                        </td>
                        <td><span>{{item.MeasureNumber}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<toaster-component></toaster-component>