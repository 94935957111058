import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Topic } from '../../safety-job-analysis/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { RiskanalysisService } from '../services/risk-analysis.service';
import { RuhService } from '../services/ruh.services';
import { TypeOfCase } from '../models/deviation';
import { DeviationServices } from '../services/deviation.services';
import { ruhList } from '../models/ruh';
import { HelperService } from 'src/app/_services/helper.service';
import { FeatureKey, Rights } from 'src/app/_models';
import { IMyDpOptions } from 'mydatepicker';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { KubaBulkReportService } from 'src/app/shared/ecpl-document-explorer/file-window.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({

    templateUrl: 'ruh-list.component.html'
})

export class RuhListComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('ruhTable',{static: false}) ruhTable: Table;
    rowID: number = null!;
    isCollapsed = false;
    public ruhList: any;
    ruhfilterlist: any;
    ruhDetails: any;
    selectedRuh: any = [];
    itemsPerPage = 20;
    filtercount:any;
    H1 = 0;
    H2 = 0;
    N = 0;
    FF = 0;
    UFT = 0;
    CreatedFrom: any;
    CreatedTo: any;
    OccurFrom: any;
    OccurTo: any;
    rowsPerPageOptions:any = [];
    totalRecords: number;
    isStatusDone = false;
    statuses: SelectItem[];
    editstatuses: SelectItem[];
    createdBy: SelectItem[];
    departments: any;
    isBusinessId = false;
    editTypeOfCases: any = [];
    Case: SelectItem[];
    Topics: SelectItem[];
    editTopics: any = [];
    topicId: number;
    caseId: number;
    topicForm: FormGroup;
    caseForm: FormGroup;
    statusform: FormGroup;
    existsTypeCaseAlerttext: string;
    isTypeCaseNameExists: boolean;
    isTopicNameExists: boolean;
    existsTopicAlerttext: string;
    addtypeOfCase = false;
    addOfTopic = false;
    loading = false;
    vresperson: SelectItem[];
    followup: any[];
    Sorting: SelectItem[];
    Incidentcondition: SelectItem[];
    userId: number;
    showLoader = true;
    additionalData: any;
    actioneditId: number;
    selectedCaseId: number = null!;
    createdFromDate: string;
    createdToDate: string;
    dateOfOccuranceTo: string;
    dateOfOccuranceFrom: string;
    selectedDept: any;
    selectedSorting: any;
    selectedTypeOfCase: any;
    selectedTopics: any;
    selectedH1H2NFFUFT: any;
    selectedResponsibleForExecution: any;
    selectedResponsibleForFollowUp: any;
    selectedCreatedBy: any;
    viewTypeOfCase: boolean;
    viewTopic: boolean;
    isShowNewButton: boolean;
    isRuhReadRights: boolean;
    isRuhEditRights: boolean;
    isFilter = false;
    prevIncidentcondition = null; 
    public DateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        selectionTxtFontSize: '14px',
        height: '32px',
        allowDeselectDate: true,
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
    };
    private subscriptions: Subscription[] = [];


    constructor(
        private confirmationService: ConfirmationService,
        private bulkReportService: KubaBulkReportService,
        private riskanalysisService: RiskanalysisService,
        private deviationService: DeviationServices,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private _fb: FormBuilder,
        private ruhServices: RuhService
    ) {
        this.topicForm = this._fb.group({
            Topic: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        });
        this.caseForm = this._fb.group({
            Case: ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        });
        this.statusform = this._fb.group({
            status: ['']
        });
        this.userId = BaseServices.UserId;
        this.additionalData = {
            fileName: this.translate.instant('RUH_LIST'),
            header: this.translate.instant('RUH_LIST'),
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'RUH NO', dataKey: 'No' },
                { title: 'Title', dataKey: 'Title' },
                { title: 'Status', dataKey: 'Status' },
                { title: 'Topics', datakey: 'TopicName' },
                { title: 'Type of Case', datakey: 'CaseName' },
                { title: 'Deadline', dataKey: 'Deadline' },
                { title: 'Event Date', dataKey: 'EventDate' },
                { title: 'Event Time', dataKey: 'EventTime' },
                { title: 'H1', dataKey: 'IsH1' },
                { title: 'H2', dataKey: 'IsH2' },
                { title: 'N', dataKey: 'IsN' },
                { title: 'FF', dataKey: 'IsFF' },
                { title: 'UFT', dataKey: 'IsUFT' },
                //{ title: 'Done On', dataKey: 'CompletedDateForPdf' },
                { title: 'Created On', dataKey: 'CreatedOn' },
                //{ title: 'Created By', dataKey: 'CreatorName' }
            ]
        };
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.isShowNewButton = (BaseServices.roleId === '1') ? false : (BaseServices.roleId === '2') ? false : true;
        this.isRuhReadRights = (BaseServices.roleId === '3') ? true : false;
        this.isRuhEditRights = (BaseServices.roleId === '3') ? true : false;
        this.isBusinessId = true;
        this.onAdministrateRUH();
        this.bindDropdown();
        this.bindRuhList();
        this.resetdates();
    }

    /**
    * get Ruh list
    */
    bindRuhList() {
        this.actioneditId = 0;
        this.ruhDetails = [];
        this.subscriptions.push(this.ruhServices
            .getRuhListByBusiness(BaseServices.BusinessId)
            .subscribe((ruhlist: any) => {
                if (ruhlist) {
                    this.totalRecords = ruhlist.length;
                    if (ruhlist.Status === 'Done') {
                        this.isStatusDone = false;
                    }
                    this.ruhDetails = ruhlist;
                    this.bindStatusIcon(ruhlist);
                    this.bindDropdown();
                    // this.showLoader = false;
                }
            }));

    }
    /**
    *
    * bind status icon by status
    * @param ruh {any}
    */
    bindStatusIcon(ruh: any) {
        this.ruhList = [];
        if (ruh) {
            ruh.forEach((ruhInfo: any) => {
                let daysDiff = 0;
                let statusIcon = '';
                let currentdate = new Date();
                let deadLine = new Date(ruhInfo.Deadline);

                if (ruhInfo.Deadline != null) {
                    daysDiff = HelperService.getDaysDiff(currentdate, deadLine);
                }
                if (ruhInfo.StatusId === 9 || ruhInfo.StatusId === 10) {
                    statusIcon = 'icon ic-sm icon-clear-deadline';
                } else if (ruhInfo.StatusId === 7) {
                    statusIcon = 'icon ic-sm icon-new-deadline';
                } else if (ruhInfo.Deadline) {
                    if (daysDiff > 3) {
                        statusIcon = 'icon ic-sm icon-far-deadline';
                    } else if (daysDiff > 0 && daysDiff < 3) {
                        statusIcon = 'icon ic-sm icon-near-deadline';
                    } else {
                        statusIcon = 'icon ic-sm icon-over-deadline';
                    }
                } else {
                    statusIcon = 'icon ic-sm icon-over-deadline';
                }
                // if (+deviationInfo.ClientId === FeatureKey.FOOD_SAFETY && sessionStorage.getItem('languageId') === '2') {
                //     deviationInfo.Modules = deviationInfo.Modules.replace('FS', 'IK-Mat')
                // }
                ruhInfo.StatusIcon = statusIcon;
                let i = 1;
                ruhInfo.ActionPlanListDto.forEach((action: any) => {
                    action.No = i++;
                });
                this.ruhList.push(ruhInfo);
                this.rowsPerPageOptions = [10, 20, 50, 100];
                if (this.ruhList.length > 100) {
                    this.rowsPerPageOptions.push(this.ruhList.length);
                }

            });
            let list = ruh.slice(0, this.itemsPerPage);
            this.ruhfilterlist = this.ruhList;
            if (list.length > 0) {
                this.getSum(list);
            }
        }

    }

    //bind dropdown for Topics  
    bindTopicDropdown(caseId: number) {
        this.editTopics = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Topics = [];
            this.Topics.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.ruhServices.getAllTopics(caseId).subscribe(result => {
            if (result) {
                result.forEach((topicInfo: any) => {
                    this.Topics.push({
                        label: topicInfo.Name,
                        value: topicInfo.Id
                    });
                });
                this.editTopics = result;
            }
        }));
    }
    /**
     * bind dropdown 
     */
    bindDropdown() {
        //bind dropdown for department
        let department = this.route.snapshot.data['department'];
        this.departments = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
            if (department) {
                department.forEach((department: any) => {
                    this.departments.push({ label: department.Name, value: department.Id });
                });
            }
        }));

        //bind dropdown for Type of Case
        this.Case = [];
        this.editTypeOfCases = [];
        let caseType: any[];
        caseType = this.route.snapshot.data['caseType'];

        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Case = [];
            this.Case.push(
                { label: val.SELECT, value: null }
            );
            if (caseType) {
                caseType.forEach((caseInfo: any) => {
                    this.Case.push({ label: caseInfo.Name, value: caseInfo.Id });
                    this.editTypeOfCases.push({
                        Name: caseInfo.Name,
                        Id: caseInfo.Id,
                        IsDefault: caseInfo.IsDefault
                    });
                });
            }
        }));


        //bind dropdown Responsible for followup 
        this.followup = [];
        let followUpPerson: any[];
        followUpPerson = this.route.snapshot.data['user'];
        //bind dropdown Responsible for followup and excecution and createdby

        let ResponsiblePerson: any[];
        ResponsiblePerson = this.route.snapshot.data['user'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.followup = [];
            this.vresperson = [];
            this.createdBy = [];
            this.followup.push(
                { label: val.SELECT, value: null }
            );
            this.vresperson.push(
                { label: val.SELECT, value: null }
            );
            this.createdBy.push(
                { label: val.SELECT, value: null }
            );
            if (ResponsiblePerson) {
                ResponsiblePerson.forEach((ResponsiblePerson: any) => {
                    this.followup.push({
                        label: ResponsiblePerson.Name,
                        value: ResponsiblePerson.Id
                    });
                    this.vresperson.push({
                        label: ResponsiblePerson.Name,
                        value: ResponsiblePerson.Id
                    });
                    this.createdBy.push({
                        label: ResponsiblePerson.Name,
                        value: ResponsiblePerson.Id
                    });
                });
            }
        }));

        //bind dropdown for Sorting
        this.subscriptions.push(this.translate.stream('SELECT_SORTING').subscribe(val => {
            this.Sorting = [];
            this.Sorting.push({ label: val.SELECT, value: null },
                { label: val.PERSON, value: '1' },
                { label: val.ENVIRONMENTAL, value: '2' },
                { label: val.MATERIAL, value: '3' });
        }));
        //bind Incidentcondition
        this.subscriptions.push(this.translate.stream('SELECT_INCIDENT_DROP').subscribe(val => {
            this.Incidentcondition = [];
            this.Incidentcondition.push({ label: val.SELECT, value: null },
                { label: val.SICKNESS_CERTIFICATE, value: 'H1' },
                { label: val.INCIDENT_CAUSED_INJURY, value: 'H2' },
                { label: val.ACCIDENT_WITHOUT_INJURY, value: 'N' },
                { label: val.DANGEROUS_CONDITION, value: 'FF' },
                { label: val.COMPLETED_CORRECTIVE_ACTIONS, value: 'UFT' }
            );
        }));


        //bind dropdown for status
        this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.NEW, value: 7 },
                { label: val.OPEN, value: 8 },
                { label: val.DONE, value: 9 },
                { label: val.REJECTED, value: 10 }
            );
        }));

        //bind dropdown for status
        this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
            this.editstatuses = [];
            this.editstatuses.push(
                { label: val.OPEN, value: 8 },
                { label: val.DONE, value: 9 },
                { label: val.REJECTED, value: 10 }
            );
        }));
    }



    /**bind Type of Case */
    bindTypeOfCaseDropdown() {
        this.subscriptions.push(this.ruhServices.getAllCaseType().subscribe(typeCaseList => {
            if (typeCaseList) {
                this.Case = [];
                typeCaseList.forEach((typeCase: any) => {
                    this.Case.push({ label: typeCase.Name, value: typeCase.Id });
                });
            }
            this.editTypeOfCases = typeCaseList
        }));
    }


    onBeforeTopicDialogHide() {
        this.topicForm.reset();
    }
    onBeforeCaseDialogHide() {
        this.caseForm.reset();
    }

    /**
     * save topic
     */
    saveTopic() {
        this.loading = true;
        let reskTopic = new Topic();
        reskTopic.Id = this.topicId ? this.topicId : 0;
        reskTopic.Name = this.topicForm.value.Topic;
        reskTopic.Status = '1';
        reskTopic.BusinessId = BaseServices.BusinessId;
        reskTopic.CaseId = this.selectedCaseId;
        if (reskTopic.Id > 0) {
            this.subscriptions.push(this.ruhServices
                .updateTopic(reskTopic)
                .subscribe(isTopicUpdated => {
                    if (isTopicUpdated) {
                        this.addOfTopic = false;
                        this.toasterComponent.callToast();
                        this.loading = false;
                        this.bindTopicDropdown(this.selectedCaseId);
                    } else {
                        this.existsTopicAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.loading = false;
                        this.isTopicNameExists = true;
                        setTimeout(
                             () => {
                                this.isTopicNameExists = false;
                            },
                            3000
                        );
                    }

                }));
        } else {
            this.subscriptions.push(this.ruhServices
                .addTopic(reskTopic)
                .subscribe(isTopicAdded => {
                    if (isTopicAdded) {
                        this.addOfTopic = false;
                        this.toasterComponent.callToast();
                        this.loading = false;
                        this.bindTopicDropdown(this.selectedCaseId);
                        this.riskanalysisService.clearCachedTopic();
                    } else {
                        this.existsTopicAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.loading = false;
                        this.isTopicNameExists = true;
                        setTimeout(
                             () => {
                                this.isTopicNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        }
        this.clear();
    }
    clear() {
        this.topicId = 0;
        this.topicForm = this._fb.group({
            Topic: ''
        });
    }

    /**
     * edit topic
     * @param Topictype {any}
     */
    editTopicType(topic: Topic) {
        this.topicId = topic.Id;
        let result = {
            Topic: topic.Name
        };
        (<FormGroup>this.topicForm).setValue(result, { onlySelf: true });
    }

    /**
     * delete topic
     * @param id {any}
     */
    deleteTopic(id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.ruhServices
                    .deleteTopic(id)
                    .subscribe(isTopicDeleted => {
                        if (isTopicDeleted) {
                            this.riskanalysisService.clearCachedTopic();
                            this.bindTopicDropdown(this.selectedCaseId);
                        }
                    }));
            }
        });
    }
    /**
     * save type of case
     */
    saveTypeOfCase() {
        this.loading = true;
        let typeOfcase = new TypeOfCase();
        typeOfcase.Id = this.caseId ? this.caseId : 0;
        typeOfcase.Name = this.caseForm.value.Case;
        typeOfcase.Status = '1';
        typeOfcase.BusinessId = BaseServices.BusinessId;
        typeOfcase.ApplicationId = BaseServices.ApplicationId;
        // typeofwork.BusinessId = this.loggedUser.BusinessId;
        if (typeOfcase.Id > 0) {
            typeOfcase.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.ruhServices
                .updateCaseType(typeOfcase.Id, typeOfcase)
                .subscribe(isCaseUpdated => {
                    if (isCaseUpdated) {
                        this.addtypeOfCase = false;
                        this.toasterComponent.callToast();
                        this.loading = false;
                        this.bindTypeOfCaseDropdown();
                    } else {
                        this.existsTypeCaseAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isTypeCaseNameExists = true;
                        this.loading = false;
                        setTimeout(
                             () => {
                                this.isTypeCaseNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        } else {
            typeOfcase.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.ruhServices
                .addTypeCase(typeOfcase)
                .subscribe(isCaseAdded => {
                    if (isCaseAdded) {
                        this.addtypeOfCase = false;
                        this.deviationService.clearCachedCaseType();
                        this.toasterComponent.callToast();
                        this.bindTypeOfCaseDropdown();
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.existsTypeCaseAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isTypeCaseNameExists = true;
                        setTimeout(
                             () => {
                                this.isTypeCaseNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        }
        this.clearCaseForm();
    }
    clearCaseForm() {
        this.caseId = 0;
        this.caseForm = this._fb.group({
            Case: ''
        });
    }
    /**
     * edit type of case
     * @param TypeOfCase {any}
     */
    editTypeOfCase(typeOfCase: TypeOfCase) {
        this.caseId = typeOfCase.Id!;
        let result = {
            Case: typeOfCase.Name
        };
        (<FormGroup>this.caseForm).setValue(result, { onlySelf: true });
    }
    /**
     * delete type of case
     * @param deleteType {any}
     */
    deleteTypeOfCase(deleteType: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.ruhServices
                    .deleteTypeCase(deleteType)
                    .subscribe(isCaseDeleted => {
                        if (isCaseDeleted) {
                            this.deviationService.clearCachedCaseType();
                            this.bindTypeOfCaseDropdown();
                        }
                    }));
            }
        });
    }

    onStatusChanged(event: any, statusfilter: any) {
        if (event) {
            this.ruhTable.filter(
                statusfilter.selectedOption.value,
                'StatusId',
                'equals'
            );
        } else {
            this.ruhTable.reset();
        }
    }

    /**
     * sort change
     * @param event {any}
     * @param sortingDropdown {any}
     */
    onSortingChange(event: any, sortingDropdown: any) {
        if (event.value) {
            this.ruhTable.filter(
                sortingDropdown.selectedOption.value,
                'Sorting',
                'equals'
            );
        } else {
            this.ruhTable.reset();
        }
    }
    /**
    * filter list based on followup person
    * @param e {any}
    * @param departmentDropdown {any}
    */
    onDepartmentChanged(depControl: any) {
        // custom filter datatable
        this.ruhTable.filter(
            depControl.value,
            'BusinessDepartmentId',
            'equals'
        );
    }
    /**
     * filter list based on followup person
     * @param e {any}
     * @param followUpDropdown {any}
     */
    onFollowupChanged(followUpDropdown: any) {
        // custom filter datatable
        this.ruhTable.filter(
            followUpDropdown.value,
            'FollowupUserId',
            'equals'
        );
    }
    /**
   * filter list based on execution person
   * @param e {any}
   * @param executionDropdown {any}
   */
    onExecutionChanged(executionDropdown: any) {
        // custom filter datatable
        if (executionDropdown.selectedOption.value) {
            this.ruhTable.filter(
                executionDropdown.selectedOption.value,
                'ExecutionUserId',
                'equals'
            );
        } else {
            this.ruhTable.filter(null, 'ExecutionUserId', 'equals');
        }
    }
    /**
     *  filter list based on Type of case
     * @param e  {any}
     * @param caseDropdown  {any}
     */
    onCaseChanged(caseDropdown: any) {
        this.selectedCaseId = caseDropdown.value;
        this.ruhTable.filter(caseDropdown.value, 'CaseId', 'equals');
        this.bindTopicDropdown(caseDropdown.value);

    }

    /**
     * filter list based on topic
     * @param e {any}
     * @param topicDropdown {any}
     */
    onTopicChanged(topicDropdown: any) {
        this.ruhTable.filter(topicDropdown.value, 'TopicId', 'equals');
    }
    /**
    *  filter list based on Type of case
    * @param e  {any}
    * @param searchValue  {any}
    */
    onCreatedByChange(e: any, searchValue: any) {
        if (e.value) {
            this.ruhTable.filter(searchValue.value, 'CreatedBy', 'equals')
        }
    }

    //filters the records with only checked values in selected column(H1/H2/N/FF/UFT)
    onIncidentconditionChanged(incidentdropdown: any) {
        //remove previous selected filter on ruhTable
        if(this.prevIncidentcondition){
            this.ruhTable.filter(null, this.prevIncidentcondition, 'equals');
        }
        if (incidentdropdown.value) {
            this.ruhTable.filter("true", incidentdropdown.value, 'equals');
            this.prevIncidentcondition = incidentdropdown.value;
        }

    }

    /**
     * startdate calendar onselect event
     * @param event {any}
     */
    onCreatedFromChanged(event: any) {
        // custom filter datatable
        this.CreatedFrom = event.jsdate;
        if (this.CreatedFrom) {
            this.dateRangeFilter();
        } else {
            this.CreatedFrom = new Date('1800-01-01');
            this.dateRangeFilter();
        }
    }

    /**
     * enddate calendar onselect event
     * @param event {any}
     */
    onCreatedToChanged(event: any) {
        // custom filter datatable
        this.CreatedTo = event.jsdate;
        if (this.CreatedTo) {
            this.CreatedTo = this.CreatedTo.setDate(this.CreatedTo.getDate() + 1);
            this.dateRangeFilter();
        } else {
            this.CreatedTo = new Date().setDate(new Date().getDate() + 1);
            this.dateRangeFilter();
        }
    }
    onOccurFromChanged(event: any) {
        // custom filter datatable
        this.OccurFrom = event.jsdate;
        if (this.OccurFrom) {
            this.dateRangeFilter();
        } else {
            this.OccurFrom = new Date('1800-01-01');
            this.dateRangeFilter();
        }
    }
    onOccurToChanged(event: any) {
        // custom filter datatable
        this.OccurTo = event.jsdate;
        if (this.OccurTo) {
            this.dateRangeFilter();
        } else {
            let OccurTo = new Date().getUTCFullYear() + 50;
            this.OccurTo = new Date(OccurTo + '-01-01');
            this.dateRangeFilter();
        }
    }

    /**
   * Edit the page based upon administrate deviation rights
   */
    onAdministrateRUH() {
        let currentUserRole = BaseServices.UserRole;
        let userEditRightsId = Rights.RUH_EDITOR;
        let userReadRightsId = Rights.OBSERVER;
        if (currentUserRole === 'User') {
            this.isRuhEditRights = BaseServices.checkUserRights(userEditRightsId, currentUserRole);
            this.isRuhReadRights = this.isRuhEditRights ? this.isRuhEditRights :
                BaseServices.checkUserRights(userReadRightsId, currentUserRole);
        }
    }
    dateRangeFilter() {
        this.ruhList = [];
        let list: any;
        this.subscriptions.push(this.ruhServices
            .getRuhListByBusiness(BaseServices.BusinessId)
            .subscribe((ruhlist: any) => {
                if (ruhlist) {
                    list = ruhlist.filter((x: any) => {
                        return (
                            new Date(x.CreatedOn) >= this.CreatedFrom &&
                            new Date(x.CreatedOn) <= this.CreatedTo &&
                            new Date(x.EventDate) >= this.OccurFrom &&
                            new Date(x.EventDate) <= this.OccurTo
                        );
                    });
                }
                this.totalRecords = list.length;
                if (list.Status === 'Done') {
                    this.isStatusDone = false;
                }
                this.ruhDetails = list;
                this.bindStatusIcon(list);
                this.bindDropdown();
                //this.showLoader = false;
            }));
    }

    /**
     * fetches data and refreshes grid
     */
    clearFilters() {
        this.ruhTable.reset();
        this.ruhfilterlist = this.ruhList;
        this.resetdates();
        this.bindRuhList();
        this.resetDropdowns();
    }
    resetDropdowns() {
        this.selectedDept = null;
        this.selectedSorting = null;
        this.selectedTypeOfCase = null;
        this.Topics = [];
        this.selectedCaseId = null!;
        this.selectedTopics = null;
        this.selectedH1H2NFFUFT = null;
        this.selectedResponsibleForExecution = null;
        this.selectedResponsibleForFollowUp = null;
        this.selectedCreatedBy = null;

    }

    resetdates() {
        this.createdFromDate = '';
        this.createdToDate = '';
        this.dateOfOccuranceTo = '';
        this.dateOfOccuranceFrom = '';
        this.CreatedFrom = new Date('1800-01-01');
        this.CreatedTo = new Date().setDate(new Date().getDate() + 1);
        //this.CreatedTo = this.CreatedTo ? this.CreatedTo : new Date();
        this.OccurFrom = this.OccurFrom ? this.OccurFrom : new Date('1800-01-01');
        let OccurTo = new Date().getUTCFullYear() + 50;
            this.OccurTo = new Date(OccurTo + '-01-01');
            //this.OccurTo = this.OccurTo ? this.OccurTo : new Date('2100-12-31');
    }
    // filter list based on table columns event
    ruhFilterChange(event: any) {
        let list = event.filteredValue;
        this.ruhfilterlist = list;
        this.filtercount = list.length;
        if (list.length > 0) {
            if (list.length > this.itemsPerPage) {
                list = list.slice(0, this.itemsPerPage);
            }
            this.getSum(list);
        }
        else this.clearSum();
    }
    //event for list page change
    ruhPageChange(event: any) {
        let list = this.ruhfilterlist.slice(event.first, event.first + event.rows);
        if (list.length > 0) {
            this.getSum(list);
        }
        else this.clearSum();
        this.itemsPerPage = event.rows;
    }
    /**
     * page wise cost calculation
     * @param deviations {DeviationList}
     * @param start {number}
     * @param end {number}
     */
    getSum(ruh: ruhList[]): void {
        this.clearSum();
        ruh.forEach(e => {
            this.H1 += (e.H1 ? 1 : 0);
            this.H2 += (e.H2 ? 1 : 0);
            this.N += (e.N ? 1 : 0);
            this.FF += (e.FF ? 1 : 0);
            this.UFT += (e.UFT ? 1 : 0);
        });

        //  this.bindStatusIcon(deviations);
    }

    clearSum() {
        this.H1 = 0;
        this.H2 = 0;
        this.N = 0;
        this.FF = 0;
        this.UFT = 0;
    }




    /**
    *  set collapse value
    * @param i {number}
    */
    public setCollapse(i: number): boolean {
        let isCollapsed = i === this.rowID;
        return !isCollapsed;
    }
    /**
     * toggle expand icon click
     * @param i {number}
     */
    toggleClick(i: number): void {
        this.rowID = i;
        this.isCollapsed = !this.isCollapsed;
    }
    /**
     * toggle collapse icon click
     */
    toggleClickHide(): void {
        this.rowID = null!;
    }

    /**
    * delete deviation
    * @param id {any}
    */
    confirm(id: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.ruhServices.deleteRuh(id).subscribe((result) => {
                    if (result) {
                        //this.ruhDetails = [];
                        this.toasterComponent.callToastDlt();
                        this.clearFilters();
                    }
                }));
                //this.toasterComponent.callToastDlt();
                //this.clearFilters();
            }
        });
    }
    Actionedit(id: any, status: any) {
        this.actioneditId = id;
        this.statusform = this._fb.group({
            status: status
        });
    }
    onStatusEdit(action: any, ruhid: any, status: any) {

        this.confirmationService.confirm({
            message: this.translate.instant('SAVE_ACTION'),
            accept: () => {
                action.Status = status.value;
                action.RUHId = ruhid;
                action.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.ruhServices.updateActionplan(action).subscribe(isActionPlan => {
                    if (isActionPlan) {
                        this.toasterComponent.callToast();
                    }
                }));
                //this.ruhDetails[ruhid].ActionPlanListDto[action.id].StatusText=status.label;
                //this.setCollapse(ruhid);
                this.rowID = null!;
                //this.ruhTable.toggleRow(ruhid);
                this.bindRuhList();

            }
        });
    }

    Actionconfirm(actionid: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.ruhServices.deleteactionplan(actionid).subscribe((result) => {
                    if (result) {
                        this.toasterComponent.callToastDlt();
                        this.clearFilters();
                    }
                }));
            }
        });
    }
    /**
     * Show loading bar when export the data.
     */
    loader(value: any) {
        this.showLoader = value;
    }
    DownloadRUHAttachment(attachments: any, RUHNo: any) {
        let DownloadDoc: any = [];
        if (attachments.length > 1) {
            attachments.forEach((attachment:any) => {
                let requestObject = {
                    'FilePath': attachment.path,
                    'FileName': attachment.originalFileName.replace("business-1203/follow-up/ruh/", ''),
                    'BusinessId': BaseServices.BusinessId,
                    'CultureInfo': BaseServices.userCultureInfo()
                };
                DownloadDoc.push(HelperService.switchKeysCase(requestObject, null, 'U'));
            });
            if (DownloadDoc.length === 0) {
                alert('No file selected');
            } else {
                let reportname = RUHNo + '-Attachments.zip';
                this.subscriptions.push(this.bulkReportService
                    .downloadAllAsZip(DownloadDoc, 1, 0, 0)
                    .subscribe(
                        (blob:any) => {
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = `${reportname}`;
                            link.click();
                        },
                        (error:any) => {
                            alert('Please try again');
                        }));
            }
        }
        else {
            window.open(attachments[0].path);
        }
    }

    toggleFilter(){
        this.isFilter = !this.isFilter;
    }
    isruhActionExecution(actions:any){
        let execution =false;
        actions.forEach((action:any) => {
            if(action.Reponsible_For_Execution == BaseServices.UserId){
                execution = true;
            }
        });
        return execution;
    }

    isActionExecution(action:any){
        return action.Reponsible_For_Execution == BaseServices.UserId;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}