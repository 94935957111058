import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import * as _ from 'lodash';
import { ProjectServices } from 'src/app/kuba/projects/services/project.service';
import { EmployeeServices } from './../../employees/services/employee.services';
import { Rights } from './../../../_models/feature';
import { KundeELUser } from './../../kunde-EL/models/el-edit';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { ELList } from './../../kunde-EL/models/el-list.model';
import { ValidationService } from './../../shared/services/validation.service';
import { HelperService } from './../../../_services/helper.service';
import { Module } from './../../follow-ups/models/deviation';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { EmployeeRights } from './../../employees/models/employee';
import { SaveUsers, UserCredential } from './../../users/models/user';
import { UserService } from './../../users/services/user.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ProjectList } from 'src/app/kuba/projects/models/projectlist';
import {
  ProjectUserDto,
  FireDeviationUserDto,
} from 'src/app/kuba/businesses/models';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edit-users',
  templateUrl: 'userEdit.component.html',
})
export class UserEditComponent implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  businessUserForm: FormGroup;
  updatePasswordForm: FormGroup;
  mobileCodes: any;
  Status: any;
  Roles: SelectItem[];
  Language: SelectItem[];
  showPassword: any;
  Id: number;
  rightsData: any = [];
  userRightsData: any = [];
  guestRightsData: any = [];
  editorRightsData: any = [];
  editedUser = false;
  editedGuest = false;
  editedEditor = false;
  hidden = false;
  roleFilter = [3, 4, 5]; // editor, guest and user
  guest = [4];
  isNewUser = false;
  cachedUserName: string;
  userCredentials = new UserCredential();
  showPasswordResetDialog = false;
  showProjectDialog = false;
  showKundeELDialog = false;
  isRightSelect: boolean;
  hideEmployeeRights = false;
  featureId: number;
  featureKey: number;
  showChooseProject = false;
  showChooseKundeEL = false;
  ProjectList: any;
  FDVList: any;
  selectedProject: any;
  selectedFDV: any;
  selectedContract: any;
  isLoginUser: boolean;
  contractList: any;
  isProjectFeature = false;
  administrativeRights = ['4', '5', '6', '7', '8', '9'];
  private subscriptions: Subscription[] = [];
  //#endregion
  //#region constructor

  /**
   * constructor
   * @param route {ActivatedRoute}
   * @param router {Router}
   * @param userService {UserService}
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    public projectServie: ProjectServices,
    public employeeServices: EmployeeServices,
    public businessServices: BusinessServices,
    public formBuilder: FormBuilder,
    private _location: Location,
    private translate: TranslateService
  ) {
    this.Id = +this.route.snapshot.params['uid'];
    this.isNewUser = this.Id === 0;
    this.onRightsDisableOwnUser();
    this.initForm();
  }
  //#endregion
  //#region page-events
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.updatePasswordForm = this.formBuilder.group({
      passwordValidation: this.formBuilder.group(
        {
          Password: ['', [Validators.required]],
          ConfirmPassword: ['', [Validators.required]],
        },
        { validator: ValidationService.matchPassword }
      ),
    });

    let businessUserData = this.route.snapshot.data['userData'];
    this.cachedUserName = businessUserData.Username;
    this.featureId = businessUserData.FeatureId;
    this.featureKey = businessUserData.FeatureKey;
    if (businessUserData) {
      this.initForm(businessUserData);
    }
    this.Language = [];
    this.Language.push({ label: 'Select a Language', value: null });
    this.subscriptions.push(
      this.userService.getLanguages().subscribe((result: any) => {
        let cLanguage = result;
        cLanguage.forEach((lang: any) => {
          this.Language.push({ label: lang.Title, value: lang.Id });
        });
      })
    );

    this.Roles = [];
    this.Roles.push({ label: 'Select a Role', value: '' });
    let businessUserRole = this.route.snapshot.data['userrole'];
    let RoleId = +this.businessUserForm.get('RoleId')!.value;
    if (RoleId === 0) {
      this.isRightSelect = false;
    } else {
      this.isRightSelect = true;
    }
    this.roleFilter.push(RoleId);
    let roleFiltered = [];
    if (
      businessUserData.FeatureId === Module.CONTRACTOR ||
      businessUserData.FeatureId === Module.CLIENT
    ) {
      roleFiltered = businessUserRole.filter(
        (x: any) => this.guest.indexOf(x.Id) > -1
      );
    } else if (
      businessUserData.FeatureId === Module.EMPLOYEES ||
      businessUserData.FeatureId === Module.CONTACT
    ) {
      roleFiltered = businessUserRole.filter(
        (x: any) => this.roleFilter.indexOf(x.Id) > -1
      );
    } else {
      roleFiltered = businessUserRole.filter(
        (x: any) => this.roleFilter.indexOf(x.Id) > -1
      );
    }
    roleFiltered.forEach((role: any) => {
      this.Roles.push({ label: role.Name, value: role.Id });
    });
    this.mobileCodes = [];
    this.mobileCodes.push({ label: '+47', value: '47' });
    this.mobileCodes.push({ label: '+45', value: '45' });
    this.mobileCodes.push({ label: '+46', value: '46' });
    this.mobileCodes.push({ label: '+34', value: '34' });
    this.mobileCodes.push({ label: '+91', value: '91' });
    this.mobileCodes.push({ label: '+0', value: '0' });
    this.mobileCodes.push({ label: '+1', value: '1' });
    this.mobileCodes.push({ label: '+2', value: '2' });
    this.subscriptions.push(
      this.translate.stream('SELECT_STATUS_DROP').subscribe((val) => {
        this.Status = [];
        this.Status.push(
          { label: val.SELECT, value: '' },
          { label: val.INACTIVE, value: '2' },
          { label: val.ACTIVE, value: '1' }
        );
      })
    );
    this.ProjectList = <ProjectList[]>this.route.snapshot.data['projectList'];
    if (ProjectList != null) {
      this.subscriptions.push(
        this.projectServie
          .getProjectByGuest(+this.route.snapshot.params['uid'])
          .subscribe((res: any) => {
            if (res !== null) {
              this.selectedProject = [];
              res.forEach((id: any) => {
                this.selectedProject.push({ Id: id });
              });
            }
          })
      );
    }
    this.FDVList = this.route.snapshot.data['fdvList'];
    if (this.FDVList != null) {
      this.subscriptions.push(
        this.projectServie
          .getFDVByGuest(+this.route.snapshot.params['uid'])
          .subscribe((res: any) => {
            if (res !== null) {
              this.selectedFDV = [];
              res.forEach((element: any) => {
                this.selectedFDV.push({ Id: element });
              });
            }
          })
      );
    }
    this.contractList = <ELList[]>this.route.snapshot.data['list'];
  }
  //#endregion
  //#region control-events
  /**
   * Role dropdown change event
   * @param e {event}
   */
  onRoleDropDownChanged(e: any) {
    if (e.value === '') {
      this.isRightSelect = false;
    } else {
      this.isRightSelect = true;
      this.setRoleVisiblity(e.value);
    }
  }

  /**
   * Enable/Disable the rights on checkbox
   * @param e {event}
   * @param rightsId {number}
   */
  onRightsCheckboxChange(e: any, rightsId: number, rightCategory: string) {
    if (+rightsId === 1) {
      if (rightCategory === 'USER') {
        this.userRightsCheckBoxEventControl(e, rightsId, 'userCategories');
      } else if (rightCategory === 'GUEST') {
        this.userRightsCheckBoxEventControl(e, rightsId, 'guestCategories');
      } else if (rightCategory === 'EDITOR') {
        this.userRightsCheckBoxEventControl(e, rightsId, 'editorCategories');
      }
    }
    if (rightCategory === 'USER') {
      const userControl = <FormArray>(
        this.businessUserForm.controls['userCategories']
      );
      if (+rightsId === 16) {
        userControl['controls'].forEach((rightControl) => {
          rightControl.enable();
        });
      } else if (+rightsId === 13) {
        userControl['controls'].forEach((rightControl) => {
          if (Object.keys(rightControl.value)[0] === '14') {
            if (e.target.checked) {
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: true,
              });
              rightControl.disable();
            } else {
              rightControl.enable();
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: false,
              });
            }
          }
        });
      } else if (+rightsId === 15) {
        userControl['controls'].forEach((rightControl) => {
          if (
            Object.keys(rightControl.value)[0] === '14' ||
            Object.keys(rightControl.value)[0] === '13'
          ) {
            if (e.target.checked) {
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: false,
              });
              rightControl.disable();
            } else {
              rightControl.enable();
            }
          }
        });
      }
    }
    if (rightCategory === 'USER' || rightCategory === 'GUEST') {
      let userGuestControl: any;
      if (rightCategory === 'USER') {
        userGuestControl = <FormArray>(
          this.businessUserForm.controls['userCategories']
        );
      } else {
        userGuestControl = <FormArray>(
          this.businessUserForm.controls['guestCategories']
        );
      }
      if (+rightsId === 17) {
        userGuestControl['controls'].forEach((rightControl: FormControl) => {
          if (
            Object.keys(rightControl.value)[0] === '19' ||
            Object.keys(rightControl.value)[0] === '20'
          ) {
            if (e.target.checked) {
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: false,
                'attr.disabled': true,
              });
            } else {
              rightControl.enable();
            }
          }
        });
      }
      if (+rightsId === 16) {
        userGuestControl['controls'].forEach((rightControl: any) => {
          if (
            Object.keys(rightControl.value)[0] === '17' ||
            Object.keys(rightControl.value)[0] === '18' ||
            Object.keys(rightControl.value)[0] === '19'
          ) {
            if (e.target.checked) {
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: false,
              });
              rightControl.disable();
            } else {
              rightControl.enable();
            }
          }
        });
      }
      if (+rightsId === 18) {
        userGuestControl['controls'].forEach((rightControl: any) => {
          if (Object.keys(rightControl.value)[0] === '19') {
            if (e.target.checked) {
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: false,
              });
              rightControl.disable();
            } else {
              rightControl.enable();
            }
          }
        });
      } else if (+rightsId === 19) {
        userGuestControl['controls'].forEach((rightControl: any) => {
          if (Object.keys(rightControl.value)[0] === '20') {
            if (e.target.checked) {
              rightControl.patchValue({
                [Object.keys(rightControl.value)[0]]: false,
              });
              rightControl.disable();
            } else {
              rightControl.enable();
            }
          }
        });
      }
      if (+rightsId === Rights.CHOOSE_PROJECTFDV) {
        // CHOOSE_PROJECT_FDV
        userGuestControl['controls'].forEach((rightControl: any) => {
          if (Object.keys(rightControl.value)[0] === '22') {
            this.showChooseProject = e.target.checked;
          }
        });
      }
    }
    if (this.rightsData) {
      _.each(this.rightsData, (x: EmployeeRights) => {
        if (x.id === rightsId) {
          x.isChecked = e.target.checked;
        }
      });
    }
  }
  /**
   * enable or disable checkbox depends upon user roles
   * @param e {any}
   * @param rightsId {number}
   * @param userRole {string}
   */
  userRightsCheckBoxEventControl(e: any, rightsId: number, userRole: string) {
    let isActiveAccountChecked = +rightsId === 1 ? e.target.checked : false;
    const userControl = <FormArray>(
      this.businessUserForm.controls[`${userRole}`]
    );
    userControl['controls'].forEach((rightControl) => {
      rightControl.enable();
      if (Object.keys(rightControl.value)[0] !== '1') {
        if (!isActiveAccountChecked) {
          rightControl.disable();
          rightControl.patchValue({
            [Object.keys(rightControl.value)[0]]: false,
          });
        } else {
          rightControl.enable();
        }
      }
    });
  }

  onMobileNumber(e: any, limitNumber: any) {
    const limit = limitNumber;
    let charCode = e.which ? e.which : e.keyCode;
    if (
      e.target.value.length === limit ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      e.preventDefault();
    }
  }
  /**
   * Generate Random password
   */
  generateRandomPassword() {
    let randomstring = Math.random().toString(36).slice(-8);
    this.businessUserForm.get('Password')!.patchValue(randomstring);
    this.showPassword = randomstring;
    this.hidden = true;
  }

  /**
   * Save the User details
   */
  saveUser() {
    let user = new SaveUsers();
    user.Name = this.businessUserForm.value.Name;
    user.Email = this.businessUserForm.value.Email;
    user.RoleId = this.businessUserForm.value.RoleId;
    user.Status = '1';
    user.Password = this.businessUserForm.value.Password;
    user.Password = this.businessUserForm.value.ConfirmPassword;
    user.Username = this.businessUserForm.value.Username;
    user.ApplicationId = BaseServices.ApplicationId;
    user.BusinessId = BaseServices.BusinessId;
    user.FeatureId = this.featureId;
    user.FeatureKey = this.featureKey;
    user.Mobile = this.businessUserForm.value.Mobile;
    user.CountryCode = this.businessUserForm.value.countryCode;
    user.CreatedBy = this.Id ? this.Id : 0;
    user.SendUserData = this.businessUserForm.value.SendUserData;
    if (this.rightsData && this.rightsData.length > 0) {
      user.Rights = JSON.stringify(this.rightsData);
    } else {
      user.Rights = null!;
    }

    if (this.Id) {
      user.Id = this.Id;
      this.subscriptions.push(
        this.userService.updateUser(user.Id, user).subscribe((isUpdate) => {
          if (isUpdate) {
            this.toasterComponent.callToast();
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.userService.addusers(user).subscribe((isAdded) => {
          if (isAdded) {
            this.toasterComponent.callToast();
          }
        })
      );
    }
  }
  //#endregion
  //#region methods
  /**
   * Bind the UserRights
   * @param data {any}
   */
  bindRightsCheckbox(data?: any) {
    // for user role dropdown
    this.userRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
      },
      {
        id: '2',
        name: 'EDIT_MY_PAGE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '3',
        name: 'EDIT_MANUALS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '4',
        name: 'ADMINISTRATE_ACTIVITIES',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '5',
        name: 'ADMINISTRATE_RISK_ANALYSIS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '6',
        name: 'ADMINISTRATE_PROJECTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '7',
        name: 'ADMINISTRATE_FDV',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '8',
        name: 'ADMINISTRATE_INTERNAL_CONTROL',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '9',
        name: 'ADMINISTRATE_INTERNAL_NEWS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '10',
        name: 'APPROVE_ABSENCE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '11',
        name: 'APPROVE_LEAVE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '12',
        name: 'APPROVE_TIMETABLE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '13',
        name: 'ADD_EMPLOYEE',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '14',
        name: 'DEPARTMENT_EMPLOYEE_RIGHTS',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '15',
        name: 'HIDE_EMPLOYEE_LIST',
        isChecked: false,
        rightsType: 2,
      },
      {
        id: '16',
        name: 'ADMINISTRATE_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '17',
        name: 'VIEW_DEVIATION',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '18',
        name: 'DISPLAY_OWN_DEVIATIONS',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '19',
        name: 'DEVIATIONS_OWN_DEPARTMENT',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '45',
        name: 'DOCUMENTED_TRAINING_RIGHTS',
        isChecked: false,
        rightsType: 3,
      },
      {
        id: '46',
        name: 'APPROVAL_DOCUMENT_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '8',
        name: 'ADMINISTRATE_OTHER_SYSTEMS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '8',
        name: 'ADMINISTRATE_QUALITY_SYSTEM',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '51',
        name: 'ADMINISTRATE_KUBA_CONTROL',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '38',
        name: 'ADMINISTRATE_FOOD_SAFETY',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '52',
        name: 'ADMINISTRATE_FLEXI',
        isChecked: false,
        rightsType: 1,
      },
    ];

    let ahlsellRights = [
      {
        id: '26',
        name: 'FRAMEWORK_AGREEMENT_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '27',
        name: 'SERVICE_AGREEMENT_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '28',
        name: 'EL_RIGHTS',
        isChecked: false,
        rightsType: 1,
      },
    ];
    if (BaseServices.ApplicationId === 4) {
      this.userRightsData.push(...ahlsellRights);
    }
    let isActiveAccount = false;
    const userControl = <FormArray>(
      this.businessUserForm.controls['userCategories']
    );
    if (data && data.Rights) {
      let dataList = JSON.parse(data.Rights || null);
      if (dataList && dataList.length > 0) {
        if (dataList[0].id === '1') {
          isActiveAccount = dataList[0].isChecked;
        }
      }
    }
    for (let i = 0; i < this.userRightsData.length; i++) {
      let fg = new FormGroup({});
      fg.addControl(
        `${this.userRightsData[i].id}`,
        new FormControl({
          value: data
            ? this.arrayCompare(data.Rights, this.userRightsData[i].id)
            : this.userRightsData[i].isChecked,
          disabled: this.isLoginUser
            ? true
            : this.userRightsData[i].id === '1'
            ? false
            : !isActiveAccount,
        })
      );
      userControl.push(fg);
    }
    // for guest role dropdown
    if (this.featureId === Module.CLIENT) {
      this.guestRightsData = HelperService.filterArrayAgainstArray(
        this.GuestRights,
        [0, Module.CLIENT],
        'Module'
      );
      this.hideEmployeeRights = true;
    } else if (this.featureId === Module.CONTRACTOR) {
      this.guestRightsData = HelperService.filterArrayAgainstArray(
        this.GuestRights,
        [0, Module.CONTRACTOR],
        'Module'
      );
      this.hideEmployeeRights = true;
    } else if (
      this.featureId === Module.EMPLOYEES ||
      this.featureId === Module.CONTACT
    ) {
      this.guestRightsData = HelperService.filterArrayAgainstArray(
        this.GuestRights,
        [0, Module.EMPLOYEES, Module.CONTACT],
        'Module'
      );
      if (BaseServices.ApplicationId === 4) {
        this.showChooseKundeEL = true;
        let ahlsellRights = [
          {
            id: '29',
            name: 'Choose Kunde EL',
            isChecked: false,
            rightsType: 1,
            Module: [Module.EMPLOYEES, Module.CONTACT],
          },
        ];
        this.guestRightsData.push(...ahlsellRights);
      }
      this.hideEmployeeRights = false;
    }
    const guestcontrol = <FormArray>(
      this.businessUserForm.controls['guestCategories']
    );
    for (let j = 0; j < this.guestRightsData.length; j++) {
      let ft = new FormGroup({});
      ft.addControl(
        `${this.guestRightsData[j].id}`,
        new FormControl({
          value: data
            ? this.arrayCompare(data.Rights, this.guestRightsData[j].id)
            : this.guestRightsData[j].isChecked,
          disabled: this.isLoginUser
            ? true
            : this.guestRightsData[j].id === '1'
            ? false
            : !isActiveAccount,
        })
      );
      guestcontrol.push(ft);
    }

    // for editor role dropdown
    this.editorRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: [1, 2, 3],
      },
      {
        id: '10',
        name: 'APPROVE_ABSENCE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '11',
        name: 'APPROVE_LEAVE',
        isChecked: false,
        rightsType: 1,
      },
      {
        id: '12',
        name: 'APPROVE_TIMETABLE',
        isChecked: false,
        rightsType: 1,
      },
      // {
      //     'id': '45',
      //     'name': 'DOCUMENTED_TRAINING_RIGHTS',
      //     'isChecked': false,
      //     'rightsType': 3
      // }
    ];

    const editorControl = <FormArray>(
      this.businessUserForm.controls['editorCategories']
    );
    for (let k = 0; k < this.editorRightsData.length; k++) {
      let fk = new FormGroup({});
      fk.addControl(
        `${this.editorRightsData[k].id}`,
        new FormControl({
          value: data
            ? this.arrayCompare(data.Rights, this.editorRightsData[k].id)
            : this.editorRightsData[k].isChecked,
          disabled: this.isLoginUser
            ? true
            : this.editorRightsData[k].id === '1'
            ? false
            : !isActiveAccount,
        })
      );
      editorControl.push(fk);
    }
  }

  arrayCompare(list: any, key: number) {
    let isMatched = false;
    let dataList = JSON.parse(list || null);
    if (dataList && dataList.length > 0) {
      for (let index = 0; index < dataList.length; index++) {
        let element = dataList[index];
        if (element.id === key) {
          return element.isChecked;
        }
      }
    }
    return isMatched;
  }

  /**
   * Initialize the user form
   * @param data {any}
   */
  initForm(data?: any) {
    let Id: number;
    let Name = '';
    let Email = '';
    let Username = '';
    let Password = '';
    let RoleId: number;
    let Status = '';
    let Mobile = '';
    let ownerofAccount: string;
    let allCategories: any | FormArray = new FormArray([]);
    let gCategories: any | FormArray = new FormArray([]);
    let eCategories: any | FormArray = new FormArray([]);
    let CountryPhoneCode = '';
    let sendUserData = '';

    if (data) {
      Id = data.Id ? +data.Id : 0;
      Name = data.Name ? data.Name : '';
      Email = data.Email ? data.Email : '';
      Username = data.Username ? data.Username : '';
      Password = '';
      RoleId = data.RoleId ? +data.RoleId : 0;
      Status = data.Status ? data.Status : '1';
      CountryPhoneCode = data.CountryCode ? data.CountryCode : '';
      Mobile = data.Mobile ? data.Mobile : '';
      ownerofAccount = data.Name ? data.Name : '';
      sendUserData = data.SendUserData ? data.SendUserData : '';
      // assigned projects
      if (data.UserProjects.length > 0) {
        this.selectedProject = [];
        data.UserProjects.forEach((element: any) => {
          this.selectedProject.push({ Id: element.ProjectId });
        });
      }
      // assigned fdvs
      if (data.UserFireDeviations.length > 0) {
        this.selectedFDV = [];
        data.UserFireDeviations.forEach((element: any) => {
          this.selectedFDV.push({ Id: element.FireDeviationId });
        });
      }
      // assigned contracts
      if (data.UserKundeEL.length > 0) {
        this.selectedContract = [];
        data.UserKundeEL.forEach((element: any) => {
          this.selectedContract.push({ Id: element.KundeELId });
        });
      }
      if (RoleId > 0) {
        this.setRoleVisiblity(RoleId);
      }
      if (RoleId === 3) {
        // editor
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.editorRightsData;
      } else if (RoleId === 4) {
        // guest
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.guestRightsData;
      } else if (RoleId === 5) {
        // user
        this.rightsData = data.Rights
          ? JSON.parse(data.Rights)
          : this.userRightsData;
      }
      let access = this.rightsData.find(
        (x: any) => +x.id === Rights.CHOOSE_PROJECTFDV
      );
      this.isProjectFeature = data.IsProjectFeature;
      this.showChooseProject =
        (data.IsProjectFeature || data.IsFDVFeature) &&
        (access ? access.isChecked : false);
    }

    this.businessUserForm = new FormGroup({
      Id: new FormControl(Id!),
      Name: new FormControl(Name),
      Email: new FormControl(Email),
      Username: new FormControl(
        Username,
        [Validators.required],
        this.isUserNameUnique.bind(this)
      ),
      newPasswordValidation: this.formBuilder.group(
        {
          Password: new FormControl(
            Password,
            [Validators.required],
            this.isPasswordMatch.bind(this)
          ),
          ConfirmPassword: new FormControl(Password, [Validators.required]),
        },
        { validator: ValidationService.matchPassword }
      ),
      RoleId: new FormControl(RoleId!),
      Status: new FormControl(Status),
      countryCode: new FormControl(CountryPhoneCode),
      Mobile: new FormControl(Mobile),
      ownerofAccount: new FormControl(ownerofAccount!),
      userCategories: allCategories,
      guestCategories: gCategories,
      editorCategories: eCategories,
      SendUserData: new FormControl(sendUserData),
      OldPassword: new FormControl(),
    });
    this.bindRightsCheckbox(data);
  }

  /**
   * Hide & Show the Rights Based upon the Role
   * @param val{number}
   */
  setRoleVisiblity(val: number) {
    if (val === 3) {
      // editor
      this.rightsData = this.editorRightsData;
      this.editedEditor = true;
      this.editedGuest = false;
      this.editedUser = false;
    }
    if (val === 4) {
      // guest
      this.editedGuest = true;
      this.editedEditor = false;
      this.editedUser = false;
      this.rightsData = this.guestRightsData;
    }
    if (val === 5) {
      // user
      this.editedUser = true;
      this.editedEditor = false;
      this.editedGuest = false;
      this.rightsData = this.userRightsData;
    }
  }
  /**
   * user name validation
   */
  isUserNameUnique(control: FormControl) {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        if (control.value === this.cachedUserName) {
          resolve(null);
        } else {
          this.subscriptions.push(
            this.employeeServices.getUserName(control.value).subscribe(
              (x) => {
                if (!x) {
                  resolve(null);
                } else {
                  resolve({ invalidUserName: true });
                }
              },
              () => {
                resolve({ invalidUserName: true });
              }
            )
          );
        }
      }, 1000);
    });
    return q;
  }
  /**
   * user name validation
   */
  isPasswordMatch() {
    const q = new Promise((resolve) => {
      setTimeout(() => {
        {
          resolve({ invalidPassWord: true });
        }
      }, 1000);
    });
    return q;
  }
  /**
   * Back to list
   */
  gotoList() {
    this._location.back();
  }

  clearUserName() {
    this.businessUserForm.get('Username')!.patchValue('');
  }
  resetPassWord() {
    this.userCredentials.Id = this.Id;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.Password;
    this.userCredentials.Password =
      this.updatePasswordForm.value.passwordValidation.ConfirmPassword;
    this.subscriptions.push(
      this.businessServices
        .updatePassword(this.userCredentials)
        .subscribe((result) => {
          if (result === false) {
            this.isPasswordMatch();
          } else {
            this.toasterComponent.callToast();
          }
        })
    );
    this.clear();
  }
  clear() {
    this.showPasswordResetDialog = false;
    this.updatePasswordForm.reset();
  }
  showResetPassword() {
    this.showPasswordResetDialog = true;
  }

  get GuestRights() {
    return (this.guestRightsData = [
      {
        id: '1',
        name: 'ACTIVE_ACCOUNT',
        isChecked: true,
        rightsType: 1,
        Module: [0],
      },
      {
        id: '15',
        name: 'HIDE_EMPLOYEE_LIST',
        isChecked: false,
        rightsType: 2,
        Module: [Module.EMPLOYEES, Module.CONTACT],
      },
      {
        id: '17',
        name: 'VIEW_DEVIATION',
        isChecked: false,
        rightsType: 3,
        Module: [Module.EMPLOYEES, Module.CONTACT],
      },
      {
        id: '18',
        name: 'DISPLAY_OWN_DEVIATIONS',
        isChecked: false,
        rightsType: 3,
        Module: [Module.EMPLOYEES, Module.CONTACT],
      },
      {
        id: '19',
        name: 'DEVIATIONS_OWN_DEPARTMENT',
        isChecked: false,
        rightsType: 3,
        Module: [Module.EMPLOYEES, Module.CONTACT],
      },
      {
        id: '20',
        name: 'DEVIATION_RIGHTS',
        isChecked: false,
        rightsType: 3,
        Module: [
          Module.EMPLOYEES,
          Module.CONTACT,
          Module.CLIENT,
          Module.CLIENT_CONTACT,
          Module.CONTRACTOR,
          Module.CONTRACTOR_CONTACT,
        ],
      },
      {
        id: '21',
        name: 'VIEW_MANUALS',
        isChecked: false,
        rightsType: 1,
        Module: [Module.EMPLOYEES, Module.CONTACT],
      },
      {
        id: '22',
        name: 'CHOOSE_PROJECT_FDV',
        isChecked: false,
        rightsType: 1,
        Module: [Module.EMPLOYEES, Module.CONTACT],
      },
      {
        id: '24',
        name: 'CHECKLIST_RIGHTS',
        isChecked: false,
        rightsType: 1,
        Module: [Module.CLIENT, Module.CLIENT_CONTACT],
      },
      {
        id: '25',
        name: 'READING_SJA_RIGHTS',
        isChecked: false,
        rightsType: 1,
        Module: [Module.CONTRACTOR, Module.CONTRACTOR_CONTACT],
      },
    ]);
  }

  chooseProject() {
    this.showProjectDialog = true;
  }
  chooseKundeEL() {
    this.showKundeELDialog = true;
  }
  /**
   * save project and fdv
   */
  saveProjectAndFdv() {
    let projectUserListDto: any = [];
    let fdvUserListDto: any = [];
    // assign project to guest user
    if (this.selectedProject.length > 0) {
      this.selectedProject.forEach((element: any) => {
        let projectUserDto = new ProjectUserDto();
        projectUserDto.UserId = this.Id;
        projectUserDto.ProjectId = element.Id;
        projectUserListDto.push(projectUserDto);
      });
    }
    // assign fdv to guest user
    if (this.selectedFDV.length > 0) {
      this.selectedFDV.forEach((element: any) => {
        let fdvUserDto = new FireDeviationUserDto();
        fdvUserDto.UserId = this.Id;
        fdvUserDto.FireDeviationId = element.Id;
        fdvUserListDto.push(fdvUserDto);
      });
    }
    this.subscriptions.push(
      this.businessServices
        .saveProjectFDVforGuest(fdvUserListDto, projectUserListDto, +this.Id)
        .subscribe((responseList) => {
          if (responseList !== null) {
            this.showProjectDialog = false;
          }
        })
    );
  }
  /**
   * save Ik kunde EL
   */
  saveKundeEL() {
    let kundeELDto: any = [];
    if (this.selectedContract.length > 0) {
      this.selectedContract.forEach((element: any) => {
        let elUserDto = new KundeELUser();
        elUserDto.UserId = this.Id;
        elUserDto.KundeELId = element.Id;
        kundeELDto.push(elUserDto);
      });
      this.subscriptions.push(
        this.businessServices
          .assignKundeELUsers(kundeELDto)
          .subscribe((res) => {})
      );
    }
  }
  /**
   * rights disable own user
   */
  onRightsDisableOwnUser() {
    if (BaseServices.UserId === this.Id) {
      this.isLoginUser = true;
    } else {
      this.isLoginUser = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
