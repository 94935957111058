<div class="card-body">
  <form>
    <div class="page-title">
      <div class="page-action text-right">
        <button type="button" class="btn btn-success" title="{{'SAVE'|translate}}" (click)="save()" translate>
          {{'SAVE' | translate}}
        </button>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <label class="font-weight-bold">{{'INSPECTION_REPORT_COMMENT'| translate}}</label>
          </div>
          <textarea class="form-control" rows="7" [(ngModel)]='saveData.InspectionComment'
            name="insertStandard"></textarea>
          <button style="margin-top: 15px;" type="button" class="btn btn-outline-primary" (click)="insertStandards()"
            title="{{'INSERT_STANDARD'|translate}}" translate>
            {{'INSERT_STANDARD' | translate}}
          </button>

        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <label style="margin-top: 15px;">{{'UPLOAD_PIC_INSPECTION_REPORT'| translate}}</label>
          </div>
          <div class="form-group">
            <div class="upload-actions">
              <input type="file" id="uploadimage" class="hidden" (change)="filesSelect($event)" />
              <label for="uploadimage" class="btn btn-outline-primary" translate>UPLOAD_IMAGE</label>
              <!-- <button type="button" *ngIf="employeeDetail?.Avatar!==null && showMyPageSaveButton===true"
                        [style]="{'width':'65px'}" class="btn btn-icon" title="{{'DELETE' | translate}}"
                        (click)="deleteEmpImage(employeeDetail)">
                        <i class="icon ic-sm icon-trash"></i>
                    </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="uploadStart">
      <span translate>IMAGE_IS_UPLOADING</span>
    </div>
    <div *ngIf="saveData.InspectionImage !=null">
      <img src="{{saveData.InspectionImage}}" alt="" width="200" class="thumbnail">
    </div>

  </form>
</div>
<toaster-component></toaster-component>