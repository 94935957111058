import 'rxjs/Rx';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { VehicleControlService } from '../services/vehicle-control.services';

@Component({
    templateUrl: 'vehicle-control-complete.component.html',
})

export class VehicleControlCompleteComponent implements OnInit {
    controlForm: FormGroup;
    constructor(private vehicleSvc: VehicleControlService, private fb: FormBuilder) {
        this.controlForm = this.fb.group({
            CheckpointName: ['', Validators.required],
            Comments: [''],

        });
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
}
