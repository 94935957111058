import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { kubaFeatureDetailsRoutes } from './../shared/kuba-feature-details/Kuba-feature-details.routes';
import { DepartmentDropDownResolver } from './../employees/services/employee.resolver.service';
import { VehicleEditComponent } from './components/vehicle-edit.component';
import { vehicleTrackingRoutes } from './../vehicle-tracking/vehicleTracking.routing';
import { vehicleChecklistRoutes } from './../vehicle-checklist/vehicleChecklist.routing';
import { vehicleControlRoutes } from './../vehicle-control/vehicle-control.routing';
import { vehicleConsumptionRoutes } from './../vehicle-consumption/vehicle-consumption.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import {
    VehicleListResolver,
    VehicleEditResolver,
    VehicleSizeResolver,
    VehicleBrandResolver,
    TypeOfVehicleVehicleResolver,
    VehicleModelResolver,
    VehicleFeaturesResolver,
    VehicleListResolverByUserResolver
} from './services/vehicle.resolver.service';
import { VehicleListComponent } from './components/vehicleList.component';
import { VehicleFormComponent } from './components/vehicleForm.component';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { VehicleComponent } from './vehicle.component';
import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';
import { VehicleViewComponent } from 'src/app/kuba/vehicle/components/vehicle-view.component';
import {
    EmployeeContactUserResolver, 
    EmployeeContactUserSubscriptionResolver } from '../users/services/user.resolver.service';
import { VehicleHeaderListComponent } from './components/vehicle-header.component';
export const vehicleRoutes: Route[] = [
    {
        path: 'vehicle',
        component: VehicleComponent,
        children: [
            {
                path: 'list',
                component: VehicleHeaderListComponent,
                children: [
                    {
                        path: 'own',
                        component: VehicleListComponent,                       
                        resolve: {
                            list: VehicleListResolverByUserResolver
                        }
                    },
                    {
                        path: 'all',
                        component: VehicleListComponent,                    
                        resolve: {
                            list: VehicleListResolver
                        }
                    }
                ]    
            },
            {
                path: 'new',
                component: VehicleFormComponent,
                resolve: {
                    department: DepartmentDropDownResolver,
                    vehiclesize: VehicleSizeResolver,
                    brand: VehicleBrandResolver,
                    typeofvehicle: TypeOfVehicleVehicleResolver,
                    model: VehicleModelResolver,
                    contact: EmployeeContactUserResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver
                }
            },
            {
                path: 'edit/:id',
                component: VehicleEditComponent,
                resolve: {
                    details: VehicleEditResolver,
                    department: DepartmentDropDownResolver,
                    vehiclesize: VehicleSizeResolver,
                    contact: EmployeeContactUserResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver
                }
            },
            {
                path: 'details/:id',
                component: VehicleViewComponent,
                resolve: {
                    vehicledata: VehicleEditResolver,
                    additionalFeatures: VehicleFeaturesResolver
                },
                data: { parent: 'VEHICLE' },
                canActivate: [AuthGuard],
                children: [
                    ...kubaFeatureDetailsRoutes,
                    ...FeatureDocumentRoutes,
                    ...deviationRoutes,
                    ...timetableRoutes,
                    ...taskmanagerRoutes,
                    ...vehicleConsumptionRoutes,
                    ...vehicleControlRoutes,
                    ...vehicleChecklistRoutes,
                    ...vehicleTrackingRoutes
                ]
            }
        ]
    }
];
