import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ModuleOverview } from "../models/moduleoverview";
import { ModuleOverviewServices } from '../services/module-overview.service'

@Injectable()
export class ModuleOverviewListResolver implements Resolve<ModuleOverview[]> {
    constructor(private backend: ModuleOverviewServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (BaseServices.roleId === '1') {
            let res:any = this.backend.adminModuleOverviewlist();
            return res;
        } 
    }
}

@Injectable()
export class ModuleOverviewEditResolver implements Resolve<ModuleOverview> {
    constructor(private backend: ModuleOverviewServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.get(+route.params['aid']);
    }
}

