import { forgetPasswordRoutes } from './forget-password/forget-password.routing';

import { AppComponent } from './app.component';
import { Route } from '@angular/router';
 import { loginRoutes } from './login/login.routing';
import { NotFoundComponent } from './shared/common/not-found/not-found.component';
import { ErrorPageComponent } from './shared/common/error-page/error-page.component';
import { kubaRoutes } from './kuba/kuba.routing';
import { faqRoutes } from './faq/index';
import { RuhRoutes } from './ruhguestlogin/ruh.routing';


export const appRoutes: Route[] = [
    {
        path: '',
        children: [
            {
                path: '',
                component: AppComponent,
                children: [
                     ...kubaRoutes,
                    ...faqRoutes,
                    ...loginRoutes,
                    ...forgetPasswordRoutes,
                    ...RuhRoutes
                ]
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
            {
                path: 'error-page/:code',
                component: ErrorPageComponent,
            }
        ]
    }];

