<div class="tab-content">
  <form [formGroup]="SignForm">
    <div class="form-group">
      <label translate
        >USERS
        <span class="required">*</span>
      </label>
      <div>
        <p-dropdown
          [options]="Users | dropdownSelectTranslate"
          [style]="{ width: '100%' }"
          (onChange)="onUserChanged($event)"
          formControlName="UserName"
        ></p-dropdown>
      </div>
      <control-messages
        [control]="SignForm.controls['UserName']"
      ></control-messages>
    </div>
    <div class="upload-file-container">
      <div class="tab-content">
        <div class="form-group">
          <div class="upload-file-container">
            <label translate>SIGNED</label>
            <div class="upload-actions">
              <input
                type="file"
                id="files"
                class="hidden"
                (change)="filesSelect($event)"
              />
              <label for="files" class="btn btn-outline-primary" translate
                >UPLOAD_IMAGE</label
              >
            </div>
            <div *ngIf="uploadStart">
              <span translate>IMAGE_IS_UPLOADING</span>
            </div>
            <div *ngIf="signName != null">
              {{ signName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <a
        [routerLink]="['../../../../business']"
        class="btn btn-outline-secondary"
        translate
        >BACK</a
      >
      <button
        type="button"
        [ngClass]="{ loading: loading }"
        [disabled]="!SignForm.valid || loading"
        (click)="saveSigned()"
        class="btn btn-primary"
      >
        <span *ngIf="!loading" translate>SAVE</span>
        <span *ngIf="loading" translate>SAVE_PROCESS</span>
        <span *ngIf="loading" class="loader-icon"></span>
      </button>
    </div>
    <div class="table-view">
      <!-- Table starts -->
      <p-table
        #business
        [value]="signedList"
        dataKey="Id"
        [rows]="10"
        [paginator]="true"
        [pageLinks]="3"
        [rowsPerPageOptions]="[5, 10, 20]"
        exportFilename="employeeobj"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
      >
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th>{{ "USERS" | translate }}</th>
            <th>{{ "SIGNATURE" | translate }}</th>
            <th></th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-business>
          <tr>
            <td>
              <span>{{ business.Name }}</span>
            </td>
            <td>
              <img
                src="{{ business.SignatureImg }}"
                alt=""
                width="100px"
                height="100px"
              />
            </td>
            <td class="col-button">
              <a
                (click)="editSignedDetails(business)"
                class="btn btn-icon"
                title="{{ 'EDIT' | translate }}"
                routerLinkActive="active"
              >
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
              <button
                type="button"
                class="btn btn-icon"
                title="{{ 'DELETE' | translate }}"
                (click)="deleteSign(business.Id)"
              >
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </td>
          </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td colspan="3">
              {{ "NO_RECORDS_FOUND" | translate }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table ends -->
    </div>
  </form>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
