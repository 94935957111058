
import { UserEditResolver } from './../users/services/user.resolver.service';
import { UserService } from './../users/services/user.service';
import { UserModule } from '../users/user.module';
import {
    BusinessPortalListResolver, BusinessPortalEditResolver
    , PortalManagerUserlistResolver,
    PortalBusListResolver
} from './services/business-portal.resolver.service';
import { AppSharedModule } from './../shared/appShared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PortalComponent } from './portals.component';
import {
    PortalDetailsComponent,
    PortalListComponent,
    PortalFeaturesComponent,
    PortalWizardComponent,
    PortalUserComponent
} from './components/';
import { BusinessPortalService } from './services/business-portal.services';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        DialogModule,
        RouterModule,
        TableModule,
        UserModule,
        ButtonModule,
        AppSharedModule,
        TranslateModule,
        CheckboxModule,
        ConfirmDialogModule
    ],
    exports:
        [
            PortalComponent,
            PortalDetailsComponent,
            PortalListComponent,
            PortalFeaturesComponent,
            PortalWizardComponent,
            PortalUserComponent
        ],
    declarations: [
        PortalComponent,
        PortalDetailsComponent,
        PortalListComponent,
        PortalFeaturesComponent,
        PortalWizardComponent,
        PortalUserComponent
    ],
    providers: [BusinessPortalService, BusinessPortalListResolver,
        BusinessPortalEditResolver,
         UserService, UserEditResolver,
         PortalManagerUserlistResolver, PortalBusListResolver]
})
export class PortalModule { }
