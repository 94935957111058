export class SupplierContact {
    Id: number;
    SupplierId: number;
    Name: string;
    Mobile: string;
    CountryCode: string;
    Email: string;
    IsSms: string;
    IsEmail: string;
    IsUser: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}