import { ValidationService } from '../../shared/services/validation.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessSocialAspectType } from './../models/businessSocialAspects';
import { BusinessSocialAspectsService } from '../services/businessSocialAspects.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'business-social-aspects.component.html'
})

export class BusinessSocialAspectsComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent, {static:false}) toasterComponent: ToasterComponent;
    socialAspectForm: FormGroup[] = [];
    socialAspectsTypeForm: FormGroup;
    socialAspectsList: any;
    socialTypeData: any;
    showSocialTypeTable = false;
    showSocialtype = false;
    choices: any = [];
    businessId: number;
    selectedSocialTypeId: number;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor
    /**
   * constructor
   * @param route {ActivatedRoute}
   * @param _fb {FormBuilder}
   * @param businessSocialAspects {BusinessSocialAspectsService}
   */
    constructor(
        private route: ActivatedRoute,
        public _fb: FormBuilder,
        private businessSocialAspectService: BusinessSocialAspectsService) {
        this.businessId = +this.route.snapshot.parent?.params['bid'];
        this.socialAspectsList = this.route.snapshot.data['socialAspects']
        this.socialAspectsTypeForm = this._fb.group({
            Name: ['', [ValidationService.noWhitespaceValidator]]
        })
    }

    //#endregion

    //#region page-events
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
    //#endregion

    //#region control-events
    /**
        * edit the type
        * @param socialaspcts
        */
    editType(socialaspcts: any) {
        this.socialAspectsTypeForm = this._fb.group({
            Name: socialaspcts
        });
    }
    /**
     *delete SocialAspects Type
     * @param Id
     */
    deleteType(id: number) {
        this.businessSocialAspectService.deletetype(id);
    }
    /**
     * add new SocialAspects
     */
    addNewChoice() {
        let newItemNo = this.choices.length + 1;
        this.choices.push({ 'id': newItemNo });
    }

    /**
     * toggle aspect type popup
     */
    typeOfSocialAspects() {
        this.showSocialtype = true;
    }

    /**
     * toggle aspect table
     */
    typeOfSocialTable() {
        this.showSocialTypeTable = true;
    }

    /**
     * remove the SocialAspectTable
     */
    removeChoice() {
        let lastItem = this.choices.length - 1;
        this.choices.splice(lastItem);
    }

    /**
     * save the SocialAspectDetails
     * @param this
     */
    onSubmit() {
        let socialType = new BusinessSocialAspectType();
        socialType.Id = (this.selectedSocialTypeId) ? this.selectedSocialTypeId : 0;
        socialType.Name = this.socialAspectsTypeForm.value.Name;
        socialType.Status = '1';
        socialType.BusinessId = this.businessId;
        if (socialType.Id > 0) {
        } else {
            this.subscriptions.push(this.businessSocialAspectService.addSocialAspectsType(socialType).subscribe(isAdded => {
                this.toasterComponent.callToast();
            }))
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
