<div class="main-content">
    <div class="main-heading">
        <span translate>HELPANDSUPPORT</span>
    </div>
    <div class="card">
        <div class="card-body">
            <helpsupport-tab (onTabClick)="onTabClick($event)"></helpsupport-tab>
            <div class="table-view">
                <!-- Table starts -->
                <p-table [value]="helpsupport" #dt [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [responsive]="true"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="Title">{{'TITLE' | translate}}</th>
                            <th>{{'MODULE'|translate}}</th>
                            <th pSortableColumn="PublishedOn">{{'PUBLISHED_DATE' | translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'Title', 'startsWith')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th>
                                <p-dropdown width='118px' [options]="moduleName"
                                  (onChange)="dt.filter($event.value, 'ModuleName', 'equals')" appendTo="body"
                                  styleClass="p-column-filter" placeholder="" [filterBy]="null" [showClear]="true">
                                    <ng-template let-module pTemplate="item"> {{ module.label | translate }}
                                    </ng-template>
                                </p-dropdown>
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-help>
                        <tr>
                            <td>
                                <a href="javascript:void(0);" (click)="showTitlePopup(help.Id)">
                                    <span>{{help.Title}}</span>
                                </a>
                            </td>
                            <td>
                                <span translate>{{help.ModuleName | moduleName }}</span>
                            </td>
                            <td>
                                <span>{{help.PublishedOn |date : 'dd/MM/yyyy'}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="3">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
                <!--Help and Support Modal Popup-->
                <p-dialog header="{{'HELPANDSUPPORT'|translate}}" [(visible)]="showNewNotification" [modal]="true"
                [style]="{width: '600px'}" [resizable]="false" [draggable]="false" (onHide)="cancel()" [contentStyle]="{'min-height':'400px'}">
                    <div class="form-group">
                        <label for="title">
                            <span translate>{{notificationTitle}}</span>
                        </label>
                        <div *ngIf="isVideos" class="wb-content">
                            <iframe width="560" height="315" [src]="videoLink" frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="wb-content">
                            <div [innerHTML]="notificationDesc"></div>
                        </div>
                    </div>
                    <p-footer>
                        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                            <button type="button" class="btn btn-outline-secondary" (click)="closePopUp()">
                                <span translate>CLOSE</span>
                            </button>
                        </div>
                    </p-footer>
                </p-dialog>
            </div>
        </div>
    </div>
</div>