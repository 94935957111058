<div class="page-title">
    <span translate>FOR_YOU</span>
    <span class="page-actions" *ngIf="isHideContractor">
        <a [routerLink]="['./../new']" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
        </a>
    </span>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table #dt [value]="contracts" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
        [responsive]="true">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData">
                        </kuba-export>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="ContractNumber">{{'CONTRACT_NUMBER' | translate}}</th>
                <th pSortableColumn="ContractName">{{'CONTRACT_NAME' | translate}}</th>
                <th pSortableColumn="ClientName">{{'CLIENT' | translate}}</th>
                <th pSortableColumn="Status">{{'STATUS' | translate}}</th>
                <th pSortableColumn="Image">{{'IMAGE' | translate}}</th>
                <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}}</th>
                <th pSortableColumn="EditedBy">{{'EDITED_BY' | translate}}</th>
            </tr>
            <tr>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'ContractNumber', 'contains')"
                        placeholder="" class="p-column-filter" >
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'ContractName', 'contains')"
                        placeholder="" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'ClientName', 'contains')"
                        placeholder="" class="p-column-filter" >
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'Status', 'contains')"
                        placeholder="" class="p-column-filter" >
                </th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-service>
            <tr>
                <td>
                    <span>{{service.ContractNumber}}</span>
                </td>
                <td>
                    <a [routerLink]="['./../details',service.Id]">{{service.ContractName}}</a>
                </td>
                <td>
                    <span>{{service.ClientName}}</span>
                </td>
                <td>
                    <span>{{service.Status | translate}}</span>
                </td>
                <td>
                    <span>{{service.Image}}</span>
                </td>
                <td>
                    <span>{{service.CreatedName}}</span>
                </td>
                <td class="col-button">
                    <a routerLink="create" [routerLink]="['../edit',service.Id]" class="btn btn-icon"
                        title="{{'EDIT'|translate}}" routerLinkActive="active">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </a>
                    <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                        (click)="deleteService(service['Id'])">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
</div>
<div class="action-btns-wrapper text-right" *ngIf="isHideContractor">
    <a [routerLink]="['./../list']" class="btn btn-primary text-left">
        <span translate>ALL</span>
    </a>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>