import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ApplicationFeature } from '../models/feature';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class FeatureService {
    constructor(private http: HttpClient) {}

    getById(id: number) {
        return this.http
            .get(`${environment.BASE_URL}/application/${id}/features`, BaseServices.headerOption)
            .map(result => result);
    }

    add(newApplicationFeature: ApplicationFeature) {
        return this.http
            .post(
                environment.BASE_URL + '/application/features',
                newApplicationFeature,
                BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    delete(id) {
        return this.http
            .delete(`${environment.BASE_URL}/application/${id}/features`, BaseServices.headerOption)
            .map(result => result);
    }
}
