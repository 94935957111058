import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { PdfSetting } from './../../../../_services/helper.service';
import { HelperService } from 'src/app/_services/helper.service';
import { ClientServices } from './../../services/client.services';
import { Client } from './../../models/client';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

require('jspdf-autotable');

@Component({
    templateUrl: 'client-list.component.html'
})
export class ClientListComponent implements OnInit {

    //#region variables

    @ViewChild('dt',{static:false}) clientTable: Table;

    statuses: SelectItem[];
    client: any = [];
    clientTypes: SelectItem[];
    documentUploadUrl: any;
    document: any = {};
    selectedClient: any = [];
    additionalData: any;
    isEditor = false;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     */
    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private clientService: ClientServices,
        private translate: TranslateService
    ) {
        // filter by status
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 2 },
                { label: val.ACTIVE, value: 1 }
            );
        }));
        // filter the type of client
        this.subscriptions.push(this.translate.stream("SUB_CONTRACTOR_TYPES").subscribe(val => {
            this.clientTypes = [];
            this.clientTypes.push({ label: val.SUB_CONTRACTOR_SELECT, value: null });
            this.clientTypes.push({ label: val.SUB_CONTRACTOR_PERSON, value: 1 });
            this.clientTypes.push({ label: val.SUB_CONTRACTOR_COMPANY, value: 2 });

        }));
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            this.additionalData = {
                fileName: val.CLIENT,
                header: 'Client List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Number', dataKey: 'Number' },
                    { title: 'Name', dataKey: 'Name' },
                    { title: 'ClientType', dataKey: 'ClientType' },
                    { title: 'Mobile', dataKey: 'Mobile' },
                    { title: 'Telephone', dataKey: 'Telephone' },
                    { title: 'Email', dataKey: 'Email' },
                    { title: 'Status', dataKey: 'StatusText' }
                ]
            };
        }));
    }

    //#endregion

    //#region page-events

    /**
     * page load event
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.isEditor = BaseServices.roleId === '3' ? true : false;
        this.documentUploadUrl =
            environment.BASE_URL +
            '/file/upload/business/agreement/' +
            BaseServices.ApplicationId +
            '/' +
            BaseServices.BusinessId;
        if (this.route.snapshot.data['list'] !== null) {
            this.client = <Client[]>this.route.snapshot.data['list'];
        }
    }

    
    //#endregion

    //#region control-events

    /**
     * excel upload
     * @param event {any}
     */
    uploadDocument(event: any) {
        let responce = JSON.parse(event.xhr.response);
        for (let file of event.files) {
            this.document.name = file.name;
        }
        switch (responce.Result['mimetype']) {
            case 'pdf':
                this.document.type = 'pdf';
                break;

            case 'doc':
            case 'docx':
                this.document.type = 'word';
                break;

            case 'xls':
            case 'xlsx':
                this.document.type = 'excel';
                break;

            default:
                this.document.type = 'text';
                break;
        }
        this.document.uri = responce.Result['path'];
    }
    /**
    * status dropdown change event
    * @param e {any}
    * @param statusDropdown {any}
    */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.clientTable.filter(statusDropdown.selectedOption.label, 'StatusText', 'equals');
        } else {
            this.clientTable.reset();
        }
    }
    /**
     * client dropdown change event
     * @param e {any}
     * @param clientDropdown {any}
     */
    onClientChanged(e: any, clientDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.clientTable.filter(clientDropdown.selectedOption.label, 'ClientType', 'equals');
        } else {
            this.clientTable.reset();
        }
    }
    //#endregion

    //#region methods
    /**
     * refresh client list
     */
    refreshClientList() {
        this.subscriptions.push(this.clientService
            .getAllClientsByBusiness()
            .subscribe(res => {
                this.client = [];
                if (res) {
                    this.client = res;
                }
            }));
    }
    /**
     * delete client from list
     */
    deleteClient() {
        let clientIds: any = [];
        if (this.selectedClient.length > 0) {
            this.selectedClient.forEach((client: any) => {
                clientIds.push(client.Id);
            });
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_SELECTED'),
                accept: () => {
                    this.subscriptions.push(this.clientService.deleteClient(clientIds).subscribe((isClientDeleted: any) => {
                        if (isClientDeleted) {
                            this.refreshClientList();
                        }
                    }));
                    this.selectedClient = [];
                },
                reject: () => { }
            });
        } else {
            alert(this.translate.instant('PLEASE_SELECT_ATLEAST_ONE_CLIENT_TO_DELETE'));
        }
    }
    /**
     * edit status in grid
     * @param id {any}
     * @param status {any}
     */
    editStatus(id: any, status: any) {
        if (status === 'Active') {
            status = '1';
        } else if (status === 'InActive') {
            status = '2';
        }
        this.confirmationService.confirm({
            message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
            accept: () => {
                this.subscriptions.push(this.clientService.statusUpdate(id, status).subscribe((isStatusUpdated: any) => {
                    if (isStatusUpdated) {
                        this.refreshClientList();
                    }
                }));
            }
        });
    }
    /**
     * export pdf
     */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Client Details';
        let columns: any = [
            { title: 'Name', dataKey: 'Name' },
            { title: 'ClientType', dataKey: 'ClientType' },
            { title: 'Mobile', dataKey: 'Mobile' },
            { title: 'Telephone', dataKey: 'Telephone' },
            { title: 'Email', dataKey: 'Email' },
            { title: 'Status', dataKey: 'Status' }
        ];
        HelperService.generatePdf(this.client, columns, 'Client', pdfSetting, 'l');
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion

}
