import { CompleteChecklistStatus, CompleteAttachment } from "src/app/kuba/shared/models/complete-checklist";
import { DeviationList } from "./electrodeviation";

export class ElectroChecklistCompletion {
    Id: number;
    BusinessId: number;
    ApplicationId: number;
    FeatureId: number;
    FeatureKey: number;
    InspectionId: number;
    ChecklistId: number;
    BuildingId: number;
    Location: string;
    Title: string;
    InitDate: string;
    CheckListData: string;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    Deadline: string;
    ExecutionUserId: number;
    FollowupUserId: number;
    TaskId: number;
    SignatureImageData: string;
}
export class ElectroCompleteChecklistEdit {
    Section: ElectroCompleteChecklistSection[];
}

export class ElectroCompleteChecklistSection {
    Title: string;
    Point: number;
    Items: ElectroCompleteChecklistItemSection[];
}

export class ElectroCompleteChecklistItemSection {
    Title: string;
    Point: number;
    Comments: string;
    Status: CompleteChecklistStatus[];
    Attachments: CompleteAttachment[];
    Score?: number;
    Signature: string;
    ReferenceChecklist: string;
    DeviationIdList: number[];
    DeviationList: DeviationList[];
}