<div class="tab-content">
    <div class="page-title" *ngIf="!isAdmin">
        <span class="page-actions">
            <button type="submit" class="btn btn-success" (click)="showDialogToAdd()" *ngIf="isAdministrateActivity">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </button>
        </span>
    </div>
    <p-dialog header="New Activity" [(visible)]="displayDialog"[style]="{width: '350px'}" [resizable]="false" [draggable]="false" showEffect="fade"
        [modal]="true">
        <form [formGroup]="newActivityForm">
            <div class="form">
                <div class="form-group">
                    <label for="status">
                        <span translate>STATUS</span>
                    </label>
                    <div>
                        <input pInputText readonly formControlName="Status" />
                    </div>
                </div>
                <div class="form-group" *ngIf="newActivityForm.controls['Title']">
                    <label for="title">
                        <span translate>TITLE</span>
                        <span class="required">*</span>
                        <control-messages [control]="newActivityForm.controls['Title']"></control-messages>
                    </label>
                    <div>
                        <input pInputText formControlName="Title" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="description">
                        <span translate>DESCRIPTION</span>
                    </label>
                    <div>
                        <textarea pInputText formControlName="Description"></textarea>
                    </div>
                </div>
            </div>
        </form>
        <div class="action-btns-wrapper">
            <button type="submit" class="btn btn-primary" icon="pi-check" label="Save"
                (click)="onSubmitTemplateBased(newActivityForm.value)" [disabled]="!newActivityForm.valid">
                <span translate>SAVE</span>
            </button>
            <button type="button" class="btn btn-outline-secondary" icon="pi-times" (click)="cancel()">
                <span translate>CANCEL</span>
            </button>
        </div>
    </p-dialog>
</div>
<toaster-component></toaster-component>