import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MeetingService } from '../../services/meeting.services';
import { Meeting } from '../../models/meeting';

// TODO: to be worked
@Component({
    templateUrl: 'meeting.component.html'
})
export class MeetingComponent implements OnInit {
    public data;
    public id;
    showNewMeeting: Boolean = false;
    showMeetingtype: Boolean = false;
    meeting: Meeting[];

    constructor(private meetingService: MeetingService) {}
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.meetingService.getmeetingtype(this.id).then(res => {
            this.data = res;
        });
    }

    typeofmeeting() {
        this.showMeetingtype = true;
    }

    newmeeting() {
        this.showNewMeeting = true;
    }

    savedata() {}

    exportPdf() {}

    exportword() {}

    exportExcel() {}
}
