<div class="main-content">
    <div class="main-heading">
        <span>
            <span translate>QUALITY_SYSTEMS_REGISTRY</span>
        </span>
        <span class="page-actions text-right" *ngIf="isQualitySystemRights">
            <a routerLink="create" [routerLink]="['../create',0]" class="btn btn-success" routerLinkActive="active">
                <span class="icon ic-sm icon-add"></span>
                <span>
                    <span translate>NEW</span>
                </span>
            </a>
        </span>
    </div>
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']" *ngIf="isQualitySystemRights">
                    <a [routerLink]="['./all']" translate>ALL</a>
                </li>
                <li [routerLinkActive]="['active']" *ngIf="hideQualitySystemRights">
                    <a [routerLink]="['./own']" translate>FOR_YOU</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>