import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'panel-tabs',
    templateUrl: 'panel-tabs.html'
})
export class PanelTabComponent implements OnInit {
    @Input() linkItems: any;

    constructor() {
        this.linkItems = [
            {
                id: 36,
                name: 'Project Deviation',
                checked: true,
                access: true
            },
            {
                id: 37,
                name: 'Project Task Manager',
                checked: true,
                access: true
            },
            {
                id: 38,
                name: 'Project Time Table',
                checked: true,
                access: true
            },
            {
                id: 39,
                name: 'Project Safety Job Analysis',
                checked: true,
                access: true
            },
            {
                id: 40,
                name: 'Project Registration',
                checked: true,
                access: true
            },
            {
                id: 41,
                name: 'Sub Contractor',
                checked: true,
                access: true
            },
            {
                id: 42,
                name: 'FDV-dokumenter EL',
                checked: true,
                access: true
            },
            {
                id: 43,
                name: 'Declaration of conformity',
                checked: true,
                access: true
            },
            {
                id: 44,
                name: 'Hantverksdata',
                checked: true,
                access: true
            }
        ];
    }

    ngOnInit() {}
}
