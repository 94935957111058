<div class="page-title">
  <span translate>RECEPTION_CONTROL</span>
  <span class="page-actions">
    <a [routerLink]="['./../edit', 0]" class="btn btn-success">
      <span class="icon ic-sm icon-add"></span>
      <span>
        <span translate>NEW</span>
      </span>
    </a>
  </span>
</div>
<div class="table-view mbtm-30">
  <!-- Table starts -->
  <p-table
    #dt
    [value]="receptionCntrl"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    [(selection)]="selectedReception"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="filter-controls-wrapper" style="display: none">
          <form [formGroup]="ReceptionControlForm" class="form">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="cmpname" class="col-form-label">
                    <span translate>FROM_DATE</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="startdate"
                      [options]="startDateOptions"
                      formControlName="StartDate"
                      placeholder="{{ 'SELECT_START_DATE' | translate }}"
                      (dateChanged)="onStartDateChanged($event)"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="cmpno" class="col-form-label">
                    <span translate>END_DATE</span>
                  </label>
                  <div>
                    <my-date-picker
                      name="enddate"
                      (dateChanged)="onEndDateChanged($event)"
                      [options]="endDateOptions"
                      formControlName="EndDate"
                      placeholder="{{ 'SELECT_END_DATE' | translate }}"
                      required
                    ></my-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="table-options">
          <div class="pull-right">
            <div class="action-btns-group">
              <button class="btn p-3 filter-toggle">
                <span class="icon ic-sm icon-filter"></span>
                <span translate>FILTER</span>
              </button>
            </div>
            <div class="action-btns-group">
              <kuba-export
                [reportData]="dt.filteredValue || dt.value"
                [additionalData]="additionalData"
              >
              </kuba-export>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header" let-col>
      <tr>
        <th style="width: 55px" pSortableColumn="">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="No">
          {{ "NR" | translate }} <p-sortIcon field="No"></p-sortIcon>
        </th>
        <th pSortableColumn="Supplier">{{ "SUPPLIER" | translate }}</th>
        <th pSortableColumn="Date">
          {{ "DATE" | translate }} <p-sortIcon field="Date"></p-sortIcon>
        </th>
        <th pSortableColumn="KindOfGoods">{{ "KIND_OF_GOODS" | translate }}</th>
        <th pSortableColumn="Received">
          {{ "RECEIVED" | translate }}
          <p-sortIcon field="Received"></p-sortIcon>
        </th>
        <th pSortableColumn="QtyLeft" *ngIf="isProductionEnabled">
          {{ "QUANTITY_LEFT" | translate }}
          <p-sortIcon field="QtyLeft"></p-sortIcon>
        </th>
        <th pSortableColumn="StatusText">
          {{ "STATUS" | translate }}
          <p-sortIcon field="StatusText"></p-sortIcon>
        </th>
        <th pSortableColumn="Wastage" *ngIf="isProductionEnabled">
          {{ "WASTAGE" | translate }} <p-sortIcon field="Wastage"></p-sortIcon>
        </th>
        <th>{{ "DEVIATION_STATUS" | translate }}</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          <p-dropdown
            [options]="supplierList"
            (onChange)="onSubChanged($event, cd)"
            #cd
            styleClass="p-column-filter"
            placeholder=""
            [filterBy]="null"
            [showClear]="true"
          >
            <ng-template let-month pTemplate="item">
              {{ month.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th></th>
        <th>
          <p-dropdown
            width="118px"
            [options]="goodsTypeData"
            (onChange)="onEmpChanged($event, ed)"
            #ed
            styleClass="p-column-filter"
            placeholder=""
            [filterBy]="null"
            [showClear]="true"
          >
            <ng-template let-types pTemplate="item">
              {{ types.label | translate }}
            </ng-template>
          </p-dropdown>
        </th>
        <th></th>
        <th *ngIf="isProductionEnabled"></th>
        <th></th>
        <th *ngIf="isProductionEnabled"></th>
        <th></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-reception let-i="rowIndex">
      <tr>
        <td style="width: 55px">
          <p-tableCheckbox id="selectedOrders" [value]="reception">
          </p-tableCheckbox>
        </td>
        <td>
          <a [routerLink]="['./../edit', reception?.Id]">{{ reception.No }}</a>
        </td>
        <td>
          <span>{{ reception.Supplier }}</span>
        </td>
        <td>
          <span>{{ reception.Date | date : "dd/MM/yyyy" }}</span>
        </td>
        <td>
          <span>{{ reception.KindOfGoods }}</span>
        </td>
        <td>
          <span>{{ reception.Received }}</span>
        </td>
        <td *ngIf="isProductionEnabled">
          <span>{{ reception.QtyLeft }}</span>
        </td>
        <td>
          <a
            href="javascript:void(0)"
            (click)="saveReception(receptionCntrl[i])"
            routerLinkActive="active"
          >
            <span>{{ reception.StatusText.toUpperCase() | translate }}</span>
          </a>
        </td>
        <td *ngIf="isProductionEnabled">
          <span>{{ reception.Wastage }}</span>
        </td>
        <td>
          <div *ngIf="reception.OkStatus === false; else elseBlock">
            <label>
              <div *ngFor="let checkListItem of reception.CheckListNames">
                <a
                  [routerLink]="['../../deviation/edit', checkListItem?.id]"
                  *ngIf="
                    checkListItem?.status === 7 || checkListItem?.status === 8
                  "
                  style="color: #e34a31"
                  >{{ checkListItem?.name | translate }}</a
                >
                <a
                  [routerLink]="['../../deviation/edit', checkListItem?.id]"
                  *ngIf="
                    checkListItem?.status === 9 || checkListItem?.status === 10
                  "
                  style="color: #339933"
                  >{{ checkListItem?.name | translate }}</a
                >
              </div>
            </label>
          </div>
          <ng-template #elseBlock>OK</ng-template>
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template
      pTemplate="emptymessage"
      *ngIf="isProductionEnabled"
      let-columns
    >
      <tr>
        <td colspan="10">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="8">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <!-- Footer -->
    <ng-template pTemplate="summary">
      <div class="ui-helper-clearfix">
        <div class="footer-data">
          <div class="text-left">
            <span>
              <button
                class="btn btn-danger"
                type="submit"
                (click)="multipleDelete()"
              >
                <i class="icon ic-sm icon-trash"></i>
                <span translate>DELETE_CHECKED</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
  <!-- Table Ends -->
</div>
<p-confirmDialog
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
>
</p-confirmDialog>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span>Processing...</span>
  </span>
</div>
