import { ActivatedRoute } from '@angular/router';
import { AccessLog } from './../models/accesslog';
import { Component, OnInit } from '@angular/core';


@Component({
    templateUrl: 'accesslog-list.component.html'
})

export class AccessLogListComponent implements OnInit {
    constructor(private route: ActivatedRoute) { }
    accessLogs: any;
    cols ?: any[];
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.accessLogs = <AccessLog[]>this.route.snapshot.data['list'];

        this.cols = [
            { field: 'name', header: 'Name' },
            {field: 'author', header: 'Author' },
            { field: 'price', header: 'Price' }
        ];
    }
}