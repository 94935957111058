import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { UserService } from './../users/services/user.service';
import {
  UserListResolver,
  EmployeeContactUserSubscriptionResolver,
} from './../users/services/user.resolver.service';
import {
  ClientListResolver,
  ClientListByBusinessResolver,
} from './../client/services/client.resolver.service';
import { ConfirmationService } from 'primeng/api';
import {
  ProjectListResolver,
  MyProjectListResolver,
  ProjectEditResolver,
  SubContractorListResolver,
  ClientUserListResolver,
  ResponsibiltyListResolver,
  ProjectListByUserResolver,
  ProjectNameListResolver,
  ProjectFeaturesResolver,
  ProjectFdvStatusResolver,
} from './services/project.resolver';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { ProjectComponent } from './project.component';
import { ProjectServices } from './services/project.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBaseModule } from './../common/common.module';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';
import { NgModule } from '@angular/core';
import {
  ProjectEditComponent,
  ProjectNewComponent,
  ProjectFormComponent,
  ProjectListComponent,
  ProjectListAllComponent,
  ProjectListOwnComponent,
} from './components/index';
import { ProjectViewComponent } from 'src/app/kuba/projects/components/project-view.component';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { ProjectDetailsComponent } from './components/project-details.component';
import { KubaFeatureDetailsModule } from 'src/app/kuba/shared/kuba-feature-details/kuba-feature-details.module';
import { MyDatePickerModule } from 'mydatepicker';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  imports: [
    AppSharedModule,
    RouterModule,
    TranslateModule,
    EcplDocumentExplorerModule,
    EcplTabNavigationModule,
    KubaFeatureDetailsModule,
    MyDatePickerModule,
    TableModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    CommonBaseModule,
    KubaExportModule,
  ],
  exports: [
    ProjectComponent,
    ProjectEditComponent,
    ProjectNewComponent,
    ProjectFormComponent,
    ProjectListComponent,
    ProjectListAllComponent,
    ProjectListOwnComponent,
    ProjectViewComponent,
    ProjectDetailsComponent,
  ],
  declarations: [
    ProjectComponent,
    ProjectEditComponent,
    ProjectNewComponent,
    ProjectFormComponent,
    ProjectListComponent,
    ProjectListAllComponent,
    ProjectListOwnComponent,
    ProjectViewComponent,
    ProjectDetailsComponent,
  ],
  providers: [
    ProjectServices,
    ProjectListResolver,
    MyProjectListResolver,
    ProjectEditResolver,
    ConfirmationService,
    ClientListResolver,
    UserListResolver,
    SubContractorListResolver,
    ClientUserListResolver,
    UserService,
    ResponsibiltyListResolver,
    ProjectListByUserResolver,
    ProjectNameListResolver,
    ClientListByBusinessResolver,
    ProjectFeaturesResolver,
    EmployeeContactUserSubscriptionResolver,
    ProjectFdvStatusResolver,
  ],
})
export class ProjectModule {}
