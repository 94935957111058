//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Options } from './../../../_models/options';
import { BaseServices } from './../../kuba.services';
//#endregion

@Component({
    selector: 'mpc-tab-navigation',
    templateUrl: 'mpc-tab-navigation.component.html'
})

export class MPCTabNavigationComponent implements OnInit {


    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location, ) {
    }

    ngOnInit(){
        document.querySelector("body").classList.remove("opened");
    }
}

