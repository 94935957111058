
import { TaskService } from './../../task-manager/services/task.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from './../../kuba.services';
import { Suggestion, SuggestionDocumentDto } from './../models/suggestion';
import { SuggestionsServices } from '../services/suggestion.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'suggestion-new.component.html'
})
export class NewSuggestionComponent implements OnInit {
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    suggestionId: number;
    suggestion: any;
    suggestionForm: FormGroup;
    fileList: FileList;
    upConfig: UploaderConfig;
    uploadedFiles = [];
    additionalData: any;
    newDocumentAdded: any = [];
    private subscriptions: Subscription[] = [];
    /**
     * edit suggestion
     * @param formBuilder {{FormBuilder}}
     * @param activatedRoute {{ActivatedRoute}}
     * @param suggestionsServices {{SuggestionsServices}}
     */
    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private suggestionsServices: SuggestionsServices,
        private translate: TranslateService
    ) {}
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let sid = params['sid'];
            this.suggestionId = +sid;
        }));
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ParentId: this.suggestionId ? this.suggestionId : 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: 'SUGGESTION_DOCUMENT',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/file/upload/business/followup/suggestion-docs',
            addtionalData: this.additionalData
        };
        this.suggestionForm = this.formBuilder.group({
            Title: ['', Validators.required],
            Description: [''],
            Status: this.translate.instant('NEW_SUGGESTION'),
            upload: []
        });
        if (this.suggestionId) {
            this.subscriptions.push(this.suggestionsServices
                .getById(this.suggestionId)
                .subscribe(result => {
                    if (result) {
                        if (result.SuggestionDocument) {
                            result.SuggestionDocument.forEach((element:any) => {  
                                this.uploadedFiles.push(
                                    HelperService.switchKeysCase(
                                        element,
                                        null,
                                        'L'
                                    )
                                );
                            });
                        }
                        this.uploadedFiles = result.SuggestionDocument.filter(
                            (x:any) => x.status !== 0
                        );
                        this.suggestion = {
                            Status: 'New Suggestion',
                            Title: result.Title,
                            Description: result.Description,
                            upload: []
                        };
                        this.suggestionForm.setValue(this.suggestion, {
                            onlySelf: true
                        });
                    }
                }));
        }
    }
    //#endregion

    /**
     * file upload events
     * @param event {any}
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x:any) => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            (x:any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.suggestionsServices
                            .deleteSuggetionDocument(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        (x:any) => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    /**
     * save and updating suggestion
     * @param data {{any}}
     */
    onSubmitTemplateBased() {
        let suggestion = new Suggestion();
        suggestion.Id = this.suggestionId ? this.suggestionId : 0;
        suggestion.Title = this.suggestionForm.value.Title;
        suggestion.Status = '1';
        suggestion.BusinessId = BaseServices.BusinessId;
        suggestion.CreatorName = BaseServices.userName;
        suggestion.CreatedOn = HelperService.formatDate(new Date());
        suggestion.Description = this.suggestionForm.value.Description;
        suggestion.CreatedBy = BaseServices.UserId;
        suggestion.SuggestionDocument = this.newDocumentAdded;
        if (suggestion.Id > 0) {
            suggestion.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.suggestionsServices.update(suggestion).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        } else {
            this.subscriptions.push(this.suggestionsServices.create(suggestion).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        }
        this.clear();
    }

    clear() {
        this.uploadedFiles = [];
        this.suggestionId = 0;
        this.suggestionForm = this.formBuilder.group({
            Title: '',
            Description: '',
            Status: '',
            upload: ''
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
