export class BusinessEquipmentType{
  Id:number;
  Name:string;
  Status:string;
  ParentId:any;
  BusinessId:number;
  CreatedBy:number;
  ModifiedBy:number;
  CreatedOn:string;
  ModifiedOn:string;
}
export class BusinessEquipment{
  Id:number;
  EquipmentTypeId:number;
  Status:string;
  EquipmentParentId:any;
  BusinessId:number;
  CreatedBy:number;
  ModifiedBy:number;
  CreatedOn:string;
  ModifiedOn:string;
}