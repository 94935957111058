import { Component, OnInit, Input, Renderer2, OnDestroy, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ecpl-tab-navigation',
  templateUrl: './ecpl-tab-navigation.component.html',
  styleUrls: ['./ecpl-tab-navigation.component.css']
})
export class EcplTabNavigationComponent implements OnInit {
  @Input() linkItems: any;
  @Input() parentId: any;
  @Input() linkAccess: any = null;
  @Input() parentKey: any;
  constructor() { }

  //#region page-events
  /**
   * page init event
   */
  ngOnInit() { }

  
  //#endregion

  /**
   * get route for navigation
   * @param linkItem {any}
   */
  getRoute(linkItem: any) {
    if (linkItem.linkText === 'sickness/list' || linkItem.linkText === 'course/list' || linkItem.linkText === 'list') {
      this.parentId = 0;
    }
    if (this.parentId > 0 && this.parentKey) {
      return [linkItem.linkText, this.parentId];
    }
    return [linkItem.linkText, { Id: this.parentId }];
  }

  /**
   * check user rights for tabs
   * @param item {number}
   */
  checkUserRights(item: number): boolean {
    if (this.linkAccess) {
      return this.linkAccess.filter((x:any) => x.id === item).length > 0
        ? this.linkAccess.filter((x:any) => x.id === item)[0].access
        : true;
    }
    return true;
  }

}
