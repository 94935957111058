import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BusinessAbsenceService } from './../services/businessAbsenceService';
import { AbsenceCode } from './../models/businessAbsence';
import { ValidationService } from '../../shared/services/validation.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'business-absenceCodes.component.html'
})
export class BusinessAbsenceCodesComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    businessAbsence: FormGroup;
    absenceCode: any;
    absenceCodeData: any;
    businesId: number;
    progress: boolean | number = false;
    absenceId = 0;
    loading = false;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructors
    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param route {ActivatedRoute}
     * @param businessAbsenceService {BusinessAbsenceService}
     */
    constructor(
        private _fb: FormBuilder,
        private route: ActivatedRoute,
        private businessAbsenceService: BusinessAbsenceService,
    ) {
        this.businesId = +this.route.parent?.snapshot.params['bid'];
        this.businessAbsence = this._fb.group({
            'OwnSickness': ['', [Validators.required, ValidationService.noWhitespaceValidator]],
            'OwnSickMedicalCertificate': ['', [Validators.required,  ValidationService.noWhitespaceValidator]],
            'WorkRelatedIssues': ['', [Validators.required,  ValidationService.noWhitespaceValidator]],
            'ChildrenSickness': ['', [Validators.required,  ValidationService.noWhitespaceValidator]],
            'ChildrenCaretakerSick': ['', [Validators.required,  ValidationService.noWhitespaceValidator]],
            'PaidLeave': ['', [Validators.required,  ValidationService.noWhitespaceValidator]],
            'UnpaidLeave': ['', [Validators.required,  ValidationService.noWhitespaceValidator]],
            'Others': ['', [Validators.required,  ValidationService.noWhitespaceValidator]]
        });
    }
    //#endregion

    //#region page-events

    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let absenceCode = <AbsenceCode>this.route.snapshot.data['absencecode'];
        if (absenceCode) {
            this.absenceCodeData = absenceCode;
            this.absenceId = absenceCode.Id;
            this.absenceCode = {
                OwnSickness: absenceCode.OwnSickness,
                OwnSickMedicalCertificate: absenceCode.OwnSickMedicalCertificate,
                WorkRelatedIssues: absenceCode.WorkRelatedIssues,
                ChildrenSickness: absenceCode.ChildrenSickness,
                ChildrenCaretakerSick: absenceCode.ChildrenCaretakerSick,
                PaidLeave: absenceCode.PaidLeave,
                UnpaidLeave: absenceCode.UnpaidLeave,
                Others: absenceCode.Others
            };
            (<FormGroup>this.businessAbsence)
                .setValue(this.absenceCode, { onlySelf: true });
        }
    }
    //#endregion

    //#region methods

    /**
     * add/update absence code
     */
    saveChanges() {
        if (this.businessAbsence.valid) {
            this.loading = true;
            let absenceCode = (this.absenceCodeData) ? this.absenceCodeData : new AbsenceCode();
            absenceCode.Id = (this.absenceId > 0 ? this.absenceId : 0);
            absenceCode.BusinessId = this.businesId;
            absenceCode.OwnSickness = this.businessAbsence.value.OwnSickness;
            absenceCode.OwnSickMedicalCertificate = this.businessAbsence.value.OwnSickMedicalCertificate;
            absenceCode.WorkRelatedIssues = this.businessAbsence.value.WorkRelatedIssues;
            absenceCode.ChildrenSickness = this.businessAbsence.value.ChildrenSickness;
            absenceCode.ChildrenCaretakerSick = this.businessAbsence.value.ChildrenCaretakerSick;
            absenceCode.PaidLeave = this.businessAbsence.value.PaidLeave;
            absenceCode.UnpaidLeave = this.businessAbsence.value.UnpaidLeave;
            absenceCode.Others = this.businessAbsence.value.Others;
            if (this.absenceId > 0) {
                absenceCode.BusinessId = this.businesId;
                this.subscriptions.push(this.businessAbsenceService.updateAbsenceCode(absenceCode)
                    .subscribe(absenceCodeResponse => {
                        if (absenceCodeResponse) { this.toasterComponent.callToast(); 
                        this.loading = false;}
                    }));
            } else {
                this.subscriptions.push(this.businessAbsenceService.addAbsenceCode(absenceCode)
                    .subscribe(absenceCodeResponse => {
                        if (absenceCodeResponse) { this.toasterComponent.callToast(); }
                    }));
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
