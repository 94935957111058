import { Time } from "@angular/common";

export class Product {
    Id: number;
    BusinessId: number;
    Name: string;
    Active: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}

export class Batch {
    Id: number;
    FoodsafetyId: number;
    BusinessId: number;
    ProductId: number;
    ProductName: string;
    BatchNumber: number;
    Prefix: string;
    Active: boolean;
    Status: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    RegisterdBy: string;
    RegisterdDate: string;
    BatchId: number;
    NewCoolingMeasurement: Measurement;
    CoolingMeasurements: Measurement[];
}

export class Measurement {
    Id: number;
    BatchId: number;
    TemperatureValue: number;
    Time: any;
    Comments: string;
    IsDefault: boolean;
    Active: boolean;
    Status: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    RegisterdBy: string;
    RegisterdDate: string;
}

export class EditBatch{
    BatchId: number;
    Status: number;
    ModifiedBy: number
    NewCoolingMeasurement: Measurement;
}