import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { VehicleCheckListComponent } from './components/vehiclechecklistList.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { VehicleChecklistComponent } from './vehicleChecklist.component';
import { CommonViewComponent } from './../common/components/commonView.component';
export const vehicleChecklistRoutes: Route[] = [
    {
        path: 'vehicle-checklist',
        component: VehicleChecklistComponent,
        children: [
            {
                path: 'list',
                component: VehicleCheckListComponent,
            },
            {
                path: 'details/:id',
                component: CommonViewComponent,
                data: { parent: 'VEHICLE' },
                canActivate: [AuthGuard],
                children: [
                    ...deviationRoutes,
                    ...timetableRoutes
                ]
            }]
    }

];
