
import { EmployeeContactUserResolver } from './../users/services/user.resolver.service';
import { VehicleEditResolver } from './../vehicle/services/vehicle.resolver.service';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { ResponsibleForExecutionResolver } from './../follow-ups/services/deviation.resolver.service';
import { DeviationServices } from './../deviation/services/deviation.service'; 
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskManagerComponent } from './task-manager.component';
import { TaskService } from './services/task.services';
import { NewTaskComponent } from './components/task-new.component';
import { TaskListComponent } from './components/task-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from './../shared/appShared.module';
import {
    TaskManagerEditResolver,
    TaskManagerListResolver,
    FollowUpPersonList,
    ExecutionPersonList,
    RepeatitionList,
    TaskManagerAuditLog
} from './services/task.resolver.service';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        TableModule,
        SharedModule,
        DialogModule,
        CalendarModule,
        TranslateModule,
        ConfirmDialogModule,
        AppSharedModule,
        EcplDocumentExplorerModule,
        MyDatePickerModule,
        KubaExportModule
    ],
    exports: [TaskManagerComponent, NewTaskComponent, TaskListComponent],
    declarations: [TaskManagerComponent, NewTaskComponent, TaskListComponent],
    providers: [
        TaskService,
        TaskManagerListResolver,
        TaskManagerEditResolver,
        FollowUpPersonList,
        RepeatitionList,
        ExecutionPersonList,
        TaskManagerAuditLog,
        ResponsibleForExecutionResolver,
        DeviationServices,
        VehicleEditResolver,
        EmployeeContactUserResolver
    ]
})
export class TaskManagerModule {}
