<div class="tab-content">
  <div class="page-title">
    <strong>
      <span translate>MESSAGE_DETAILS</span>
    </strong>
  </div>
  <div class="form-data">
    <label>
      <span translate>FROM</span>
      <label>{{fromUserName}}</label>
    </label>
  </div>
  <div class="form-data">
    <label>
      <span translate>SUBJECT</span>
      <label>{{subject}}</label>
    </label>
  </div>
  <div class="form-data">
    <label>
      <span translate>MESSAGE</span>
      <label>{{message}}</label>
    </label>
  </div>
  <div class="form-data">
    <label>
      <span translate>ATTACHMENTS</span>
      <a target="_self" href="{{attachmentUrl}}">{{FileName}}</a>
    </label>
  </div>
  <div class="action-btns-wrapper">
    <button class="btn btn-outline-secondary" (click)="gotoBack()">
      <span class="icon ic-xs icon-back"></span>
      <span translate>BACK_LIST</span>
    </button>
  </div>
</div>
