<div class="main-content">
    <div class="main-heading">
        <strong translate>BUSINNESS_PORTAL</strong>
        <div class="page-actions text-right">
            <a routerLink="./../new/details" class="btn btn-success pull-right">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW_PORTAL</span>
            </a>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="portals" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="Title">{{'TITLE'|translate}} <p-sortIcon field="Title"></p-sortIcon>
                            </th>
                            <th>{{'USERS' | translate}}</th>
                            <th>{{'BUSINESS' | translate}}</th>
                            <!-- <th>{{'STATUS'|translate}}</th> -->
                            <th [style]="{'width':'120px'}">{{'IMAGE' | translate}}</th>
                            <th>{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($any($event.target)?.value, 'Title', 'startsWith')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-portal>
                        <tr>
                            <td><span>{{portal.Title}}</span></td>
                            <td><span>{{portal.Users}}</span>
                                <button type="button" class="btn btn-link" title="{{'USERS' | translate}}"
                                  (click)="showPortalInfo(portal.PortalUserList.UserNameList)">
                                    <span>{{ portal.PortalUserList !== null ? portal.PortalUserList.Count : ''}} </span>
                                </button>
                            </td>
                            <td><span>{{portal.Businesses}}</span>
                                <button type="button" class="btn btn-link" title="{{'BUSINESS' | translate}}"
                                  (click)="showPortalInfo(portal.PortalBusinessList.BusinessList)">
                                    <span>{{ portal.PortalBusinessList !== null ? portal.PortalBusinessList.Count : ''}}
                                    </span>
                                </button>
                            </td>
                            <td>
                                <img src="{{portal.PortalLogo}}" alt="" width="100px">
                            </td>
                            <td class="col-button">
                                <a [routerLink]="['./../edit/',portal.Id,'details']" class="btn btn-icon"
                                  title="{{'EDIT' | translate}}" routerLinkActive="active">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </a>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                  (click)="confirm(portal.Id)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="6">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
            <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
              acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
        </div>
    </div>
</div>
<p-dialog header="Portal User List" [(visible)]="showDialog" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false" (onHide)="onHide()">
    <div class="table-view">
        <!-- Table starts -->
        <p-table #pt [value]="list" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'NAME' | translate }}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                          (input)="pt.filter($any($event.target)?.value, 'Value', 'contains')" placeholder=""
                          class="p-column-filter">
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-portal>
                <tr>
                    <td><span>{{portal.Value}}</span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td>
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
</p-dialog>
<toaster-component></toaster-component>