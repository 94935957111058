import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'crew-tab',
    templateUrl: 'tab-navigation.component.html'
})
export class TabComponent implements OnInit {
    projectId: number;
    constructor(private route: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.projectId = this.activatedRoute.snapshot.parent.params['id'];
        this.route.navigate(['list'], { relativeTo: this.activatedRoute });
    }
}