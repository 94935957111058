import { UserRole } from './../../follow-ups/models/deviation';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { ServiceAgreement } from './../models/service-agreement';
import { ServiceAgreementServices } from './service-agreement.service';

@Injectable()
export class ServiceListResolver implements Resolve<any[]> {
    constructor(private backend: ServiceAgreementServices) { }
    resolve(): Observable<any[]> {
        return this.backend.list();
    }
}

@Injectable()
export class ServiceListByUserResolver implements Resolve<any[]> {
    constructor(private backend: ServiceAgreementServices) { }
    resolve(): Observable<any[]> {
        return this.backend.getByUser(BaseServices.UserId);
    }
}

@Injectable()
export class ServiceAgreementListResolver
    implements Resolve<ServiceAgreement[]> {
    constructor(private backend: ServiceAgreementServices) { }
    resolve(): Observable<ServiceAgreement[]> {
        if (+BaseServices.roleId === UserRole.USER) {
            return this.backend.getByUser(BaseServices.UserId);
        } else {
            return this.backend.getServiceAgreementlistByBusiness();
        }
    }

}

@Injectable()
export class ServiceAgreementMyListResolver
    implements Resolve<ServiceAgreement[]> {
    constructor(private backend: ServiceAgreementServices) { }
    resolve(): Observable<ServiceAgreement[]> {
        return this.backend.getServiceMylistByBusiness();
    }
}

@Injectable()
export class ServiceEditResolver implements Resolve<ServiceAgreement[]> {
    constructor(private backend: ServiceAgreementServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ServiceAgreement[]> {
        return this.backend.get(route.params['sId']);
    }
}

@Injectable()
export class ServiceAgreementFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: ServiceAgreementServices) { }
    resolve(): Observable<any> {
        return this.backend.getServiceAgreementFeatures(
            BaseServices.BusinessId
        );
    }
}

