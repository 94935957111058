<div class="row">
  <div class="col-sm-12 text-center pb-4 mb-4">
    <h4><span translate>MANUAL_FILES_FOLDERS</span></h4>
    <p><span translate>DROP_FILES_ON_FOLDER</span></p>
  </div>
  <div class="col-sm-6">
    <h5><span translate>SELECT_MANUAL_TO_DRAG_FILES</span></h5>

    <label class="sr-only" for="cltemplates">Application Manuals</label>
    <p-dropdown
      [options]="appManualList"
      [(ngModel)]="selectedAppManual"
      placeholder="{{ 'SELECT_A_MANUAL' | translate }}"
      optionLabel="name"
      filter="true"
      (onChange)="ShowSelectedAppManualTree()"
    ></p-dropdown>

    <app-ecpl-document-tree
      *ngIf="appManualTree"
      treeId="assignedManulasTree"
      [(ecplTreeData)]="appManualTree"
      allowDrop="false"
      allowDrag="true"
    >
    </app-ecpl-document-tree>
  </div>
  <div class="col-sm-6 pb-4 mb-4">
    <div class="pb-4 mb-3">
      <h5><span translate>DOCUMENTS</span></h5>
    </div>
    <app-ecpl-document-tree
      *ngIf="featureManualTree"
      treeId="featureDocumentsTreeDrop"
      [(ecplTreeData)]="featureManualTree"
      allowDrop="true"
      allowDrag="false"
      (ecplNodeEvent)="addManualEvent($event)"
    >
    </app-ecpl-document-tree>
  </div>
</div>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
