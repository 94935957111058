import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MeetingComponent } from './components/meeting/meeting.component';
import { MeetingService } from './services/meeting.services';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        CommonModule,
        TableModule,
        SharedModule,
        DialogModule,
        CalendarModule,
        TranslateModule
    ],
    exports: [MeetingComponent],
    declarations: [MeetingComponent],
    providers: [MeetingService]
})
export class MeetingModule { }
