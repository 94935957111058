import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Consumption, UnitOfConsumption } from '../models/consumptions';
import { ConsumptionsService } from './consumptions.services';
import { BaseServices } from '../../kuba.services';

@Injectable()
export class ConsumptionListResolver implements Resolve<Consumption[]> {

    constructor(private backend: ConsumptionsService) { }

    resolve(): Observable<Consumption[]> {
        return this.backend.list();
    }
}

@Injectable()
export class ConsumptionsEditResolver implements Resolve<Consumption> {
    constructor(private backend: ConsumptionsService) { }
    resolve(route: ActivatedRouteSnapshot) {
        if ((+route.params['cid']) > 0)
            return this.backend.getById(+route.params['cid']);
        else
            return new Consumption;
    }
}
@Injectable()
export class UnitOfConsumptionResolver implements Resolve<UnitOfConsumption[]> {
    constructor(private backend: ConsumptionsService) { }
    resolve(): Observable<UnitOfConsumption[]> {
        return this.backend.getConsumptionsUnitByBusinessId(BaseServices.BusinessId);

    }
}

@Injectable()
export class ConsumptionTypeResolver implements Resolve<UnitOfConsumption[]> {
    constructor(private backend: ConsumptionsService) { }
    resolve(): Observable<UnitOfConsumption[]> {
        return this.backend.getConsumptionsTypesByBusiness(BaseServices.BusinessId);
    }
}