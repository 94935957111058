import { FeatureKey } from './../../../_models/feature';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { Observable } from 'rxjs';

import { Department } from '../../deviation/models/deviationDepartment';
import { DeviationList } from '../../deviation/models/deviationList';
import { BaseServices } from './../../kuba.services';
import { Business, Client, FollowupPerson, ResponsibleForExe, Topic, TypeOfCase } from './../models/deviation';
import { DeviationServices } from './deviation.services';
import { BusinessDepartmentService } from './../../business-department.services';
import { UserService } from './../../users/services/user.service';
import { ActivityServices } from './activities.services';

@Injectable()
export class FollowupDeviationListResolver implements Resolve<DeviationList[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<DeviationList[]> {
    return this.backend.getDeviationByBusiness(BaseServices.BusinessId);
  }

}
@Injectable()
export class AllDepartmentCachedResolver implements Resolve<Department[]> {
  constructor(private backend: BusinessDepartmentService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Department[]> {
    return this.backend.getCachedBusinessDepartment();
  }
}
@Injectable()
export class AllDepartmentResolver implements Resolve<Department[]> {
  constructor(private backend: DeviationServices, private businessDepartmentService: BusinessDepartmentService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Department[]> {
    if (BaseServices.roleId === '1') {
      return this.backend.getDepartments();
    } else {
      return this.businessDepartmentService.getCachedBusinessDepartment();
    }
  }
}
@Injectable()
export class FollowupCaseTypeResolver implements Resolve<TypeOfCase[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<TypeOfCase[]> {
    return this.backend.getAllCaseType();
  }
}
@Injectable()
export class FollowupTopicResolver implements Resolve<Topic[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Topic[]> {
    return this.backend.getAllTopics(BaseServices.BusinessId);
  }
}
@Injectable()
export class ResponsibleForExecutionResolver implements Resolve<ResponsibleForExe[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<ResponsibleForExe[]> {
    return this.backend.getUser(BaseServices.BusinessId);
  }
}
@Injectable()
export class BusinessResolver implements Resolve<Business[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Business[]> {
    return this.backend.getBusiness();
  }
}
@Injectable()
export class ClientResolver implements Resolve<Client[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Client[]> {
    return this.backend.getClients(BaseServices.BusinessId);
  }
}
@Injectable()
export class DeviationsByUserResolver implements Resolve<FollowupPerson[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<FollowupPerson[]> {
    return this.backend.getDeviationsByUser(BaseServices.UserId);
  }
}
@Injectable()
export class EmployeeContactsListResolver implements Resolve<ResponsibleForExe[]> {
  constructor(private backend: BusinessServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<ResponsibleForExe[]> {
    return this.backend.getHseManager(BaseServices.BusinessId);
  }
}
@Injectable()
export class DeviationUserResolver implements Resolve<any[]> {
  constructor(private backend: DeviationServices, private activityService: ActivityServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<ResponsibleForExe[]> {
    if (BaseServices.UserRole === 'Admin') {
      return this.activityService.responsiblePersons()
    } else {
      return this.backend.getUser(BaseServices.BusinessId);
    }
  }
}

@Injectable()
export class AllDepartmentPortalResolver implements Resolve<Department[]> {
  constructor(private backend: DeviationServices) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Department[]> {
    return this.backend.getPortalDepartment();
  }
}

