<div class="tab-content">
    <div class="page-title">
        <span translate>INSTRUMENT_LIST</span>
        <span class="page-actions">
            <a [routerLink]="['./../new']" class="btn btn-success">
                <span class="icon ic-sm icon-add"></span>
                <span>
                    <span translate>NEW</span>
                </span>
            </a>
        </span>
    </div>
    <div class="table-view mbtm-30">

        <p-table #dt [value]="instruments" scrollable="true" scrollHeight="200px" [rows]="10" [paginator]="true"
            [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="InstrumentTypeName">{{'TYPE' | translate}} <p-sortIcon field="InstrumentTypeName"></p-sortIcon></th>
                    <th pSortableColumn="InstrumentModelName">{{'MODEL' | translate}} <p-sortIcon field="InstrumentModelName"></p-sortIcon></th>
                    <th pSortableColumn="CalibratedDate">{{'CALIBRATED_DATE' | translate}} <p-sortIcon field="CalibratedDate"></p-sortIcon></th>
                    <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
                    <th pSortableColumn="ModifiedName">{{'EDITED_BY' | translate}} <p-sortIcon field="ModifiedName"></p-sortIcon></th>
                    <th>{{'OPTIONS'| translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-inst>
                <tr>
                    <td>
                        <span>{{inst.InstrumentTypeName}}</span>
                    </td>
                    <td>
                        <span>{{inst.InstrumentModelName}}</span>
                    </td>
                    <td>
                        <span>{{inst.CalibratedDate | date : 'dd/MM/yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{inst.CreatedName}}</span>
                    </td>
                    <td>
                        <span>{{inst.ModifiedName}}</span>
                    </td>
                    <td class="col-button">
                        <a class="btn btn-icon" title="{{'EDIT' | translate}}" [routerLink]="['./../edit',inst.Id]"
                            routerLinkActive="active">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </a>
                        <button class="btn btn-icon" title="{{'DELETE' | translate}}"
                            (click)="deleteInstrument(inst['Id'])" routerLinkActive="active">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="5">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends-->
    </div>
    <router-outlet></router-outlet>
    <toaster-component></toaster-component>
    <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
        acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
</div>