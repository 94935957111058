import { FdvDocVVSComponent } from './components/fdv-doc-vvs.component';
import { FdvDocumentVVSComponent } from './fdv-doc-vvs.component';
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
export const fdvDocumentVVSRoutes: Route[] = [
    {
        path: 'fdv-documentvvs',
        component: FdvDocumentVVSComponent,
        children: [
            {
                path: 'list',
                component: FdvDocVVSComponent,
            }
        ]
    }];
