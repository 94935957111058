import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ElectroControlScope, ElectroFireAlarmSystem } from '../../models/electroinstallation';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'fire-alarm',
    templateUrl: 'fire-alarm-system.component.html'
})

export class FilreAlarmComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Input() firealarmSystem: ElectroFireAlarmSystem;
    @Output() saveForm: EventEmitter<any> = new EventEmitter<any>();
    editMode = false;
    systemContent: { 'id': number; 'components': string; 'comments': string; }[];
    otherEquipment: { 'id': number; 'itemRef': any; 'amount': any; 'type': any; 'description': any; 'location': any; 'merking': any }[];
    riskGroup: SelectItem[];
    controlScope: ElectroControlScope;
    editorName: string;
    contractId: number;
    page: string;
    createdOn: string;
    modifiedOn: string;
    isGuestHideButton = true;
    inspectionId: number;
    formData: any;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private location: Location,
        private electroInstallationService: ElectroInstallationService) {
        this.formData = JSON.parse(sessionStorage.getItem('formData'));
        sessionStorage.removeItem('formData');
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
    }

    ngOnInit() {
        this.inspectionId = +this.route.snapshot.parent.params['Iid'] ?
            +this.route.snapshot.parent.params['Iid'] : +this.route.snapshot.params['Iid'];
        this.contractId = this.route.snapshot.parent.params['Id'];
        this.otherEquipment = [
            {
                'id': 1,
                'itemRef': 1,
                'amount': "",
                'type': "",
                'description': "",
                'location': "",
                'merking': "" /* labelling in English */
            }];
        this.systemContent = [];
        this.systemContent.push(
            { id: 1, components: this.translate.instant('SMOKE_DETECTORS'), comments: "" },
            { id: 2, components: this.translate.instant('HEAT_DETECTORS'), comments: "" },
            { id: 3, components: this.translate.instant('MANUAL_ALARM_SWITCHES'), comments: "" },
            { id: 4, components: this.translate.instant('ALARM_BELLS'), comments: "" },
            { id: 5, components: this.translate.instant('KEYSAFE'), comments: "" },
            { id: 6, components: this.translate.instant('ALARM_CENTRAL'), comments: "" },
            { id: 7, components: this.translate.instant('INSTALLATION_DESCRIPTION'), comments: "" },
        );

        this.initForm(this.firealarmSystem);
    }

    /**
     * initialize form group and supply values to control on edit.
     * @param firealarmSystem 
     */
    initForm(firealarmSystem?: ElectroFireAlarmSystem) {
        if (firealarmSystem) {
            this.firealarmSystem = firealarmSystem;
            this.firealarmSystem.Version = firealarmSystem.Version ? firealarmSystem.Version : 1;
            this.editorName = firealarmSystem.EditorName ? firealarmSystem.EditorName : '';
            this.firealarmSystem.Type = firealarmSystem.Type ? firealarmSystem.Type : '';
            this.firealarmSystem.Supplier = firealarmSystem.Supplier ? firealarmSystem.Supplier : '';
            this.firealarmSystem.InstalledYear = firealarmSystem.InstalledYear ? firealarmSystem.InstalledYear : '';
            this.firealarmSystem.LoopAddress = firealarmSystem.LoopAddress ? firealarmSystem.LoopAddress : '';
            this.firealarmSystem.Connection = firealarmSystem.Connection ? firealarmSystem.Connection : '';
            this.firealarmSystem.Controls = firealarmSystem.Controls ? firealarmSystem.Controls : '';
            if (this.firealarmSystem.CreatedOn) {
                this.createdOn = this.firealarmSystem.CreatedOn;
                let cDateObj = new Date(this.firealarmSystem.CreatedOn);
                this.firealarmSystem.CreatedOn = cDateObj.toLocaleDateString('en-GB');
            }
            if (this.firealarmSystem.ModifiedOn) {
                this.modifiedOn = this.firealarmSystem.ModifiedOn;
                let mDateObj = new Date(this.firealarmSystem.ModifiedOn);
                this.firealarmSystem.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
            }
            if (this.firealarmSystem.SystemContent) {
                this.systemContent = <any>JSON.parse(this.firealarmSystem.SystemContent);
            }
            if (this.firealarmSystem.OtherEquipment) {
                this.otherEquipment = <any>JSON.parse(this.firealarmSystem.OtherEquipment);
            }
        }
        else {
            this.firealarmSystem = new ElectroFireAlarmSystem();
            if (this.inspectionId && this.formData) {
                this.firealarmSystem.Version = this.formData.Version;
            }
            else {
                this.firealarmSystem.Version = 1;
            }
        }
        this.firealarmSystem.DocumentNumber = "IKK-040505";
        this.page = "page 5 of 7";
    }

    /**
     * add new system content.
     * @param item 
     */
    addItem(item) {
        let itemsToShift = this.systemContent.splice(this.systemContent.indexOf(item) + 1, this.systemContent.length);
        let newItem = { 'id': item.id + 1, 'components': "", 'comments': "" };
        this.systemContent.push(newItem);
        itemsToShift.forEach(i => {
            ++i.id;
            this.systemContent.push(i);
        });
    }

    /**
     * delete specific syatem content.
     * @param item 
     */
    deleteItem(item) {
        this.systemContent.splice(this.systemContent.indexOf(item), 1);
    }

    /**
     * add new equimpent.
     * @param item 
     */
    addEquipment(item) {
        let refHighVal = this.otherEquipment[0].itemRef;
        this.otherEquipment.forEach(e => {
            if (e.itemRef) {
                refHighVal = refHighVal < e.itemRef ? e.itemRef : refHighVal;
            } else {
                e.itemRef = e.id;
            }
        });
        let itemsToShift = this.otherEquipment.splice(this.otherEquipment.indexOf(item) + 1, this.otherEquipment.length);
        let newItem = {
            'id': item.id + 1, 'itemRef': refHighVal ? refHighVal + 1 : item.id + 1,
            'amount': "", 'type': "", 'description': "", 'location': "", 'merking': ""
        };
        this.otherEquipment.push(newItem);
        itemsToShift.forEach(i => {
            ++i.id;
            this.otherEquipment.push(i);
        });
    }

    /**
     * delete specific equipment.
     * @param item 
     */
    deleteEquipment(item) {
        this.otherEquipment.splice(this.otherEquipment.indexOf(item), 1);
    }

    /**
     * save fire alarm.
     */
    saveFireSystem() {
        this.firealarmSystem.OtherEquipment = JSON.stringify(this.otherEquipment);
        this.firealarmSystem.SystemContent = JSON.stringify(this.systemContent);
        this.firealarmSystem.BusinessId = BaseServices.BusinessId;
        if (this.firealarmSystem.Id > 0) {
            this.firealarmSystem.CreatedOn = this.createdOn;
            this.firealarmSystem.ModifiedOn = this.modifiedOn;
            this.firealarmSystem.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.updateFirealarmSystem(this.firealarmSystem).subscribe(result => {
                if (result) {
                    this.saveForm.emit(result.OtherEquipmentStatus);
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            this.firealarmSystem.InspectionId = this.inspectionId;
            this.firealarmSystem.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.saveFirealarmSystem(this.firealarmSystem).subscribe(result => {
                if (result) {
                    this.saveForm.emit(result.OtherEquipmentStatus);
                    this.firealarmSystem.Id = result.Id;
                    this.createdOn = result.CreatedOn;
                    this.toasterComponent.callToast();
                }
            }));
        }
    }

    /**
     * save fire alarm and back to installation forms tab
     */
    saveBackToList() {
        this.saveFireSystem()
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    disableItemDelete(item) {//return true when deviations are active under checkpoint
        if (this.firealarmSystem ? this.firealarmSystem.OtherEquipmentStatus : false) {
            var itemStatus = JSON.parse(this.firealarmSystem.OtherEquipmentStatus).filter(x => x.itemRef == item.itemRef);
            if (itemStatus ? itemStatus.length : false) {
                return itemStatus[0].DeviationList ? (itemStatus[0].DeviationList.length) : false;
            }
            return false;
        }
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}