<div class="tab-content">
    <div class="page-title">
        <span translate>ADD_EDIT_REGISTRATION</span>
    </div>
    <form [formGroup]="RegistrationForm">
        <div class="form-group">
            <label for="Type">
                <span translate>TYPE_OF_REGISTRATIONS</span>
                <span class="required">*</span>
            </label>
            <div class="input-btn-group">
                <p-dropdown [filter]="true" formControlName="RegistrationTypeId" [options]="types"
                    [style]="{'width':'100%'}">
                    <ng-template let-status pTemplate="item"> {{ status.label | translate }}
                    </ng-template>
                </p-dropdown>
                <button class="btn btn-outline-primary" *ngIf="isHideButton" type="button" (click)="addType = !addType">
                    <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
            </div>
            <control-messages [control]="RegistrationForm.controls['RegistrationTypeId']"></control-messages>
        </div>
        <div class="form-group">
            <label for="Description">
                <span translate>DESCRIPTION</span>
            </label>
            <div>
                <textarea class="ex-full-width" name="Description" pInputTextarea formControlName="Description"
                    autoResize="autoResize"></textarea>
            </div>
            <control-messages [control]="RegistrationForm.controls['Description']"></control-messages>
        </div>
        <div class="form-group">
            <label for="Comments">
                <span translate>COMMENTS</span>
            </label>
            <div>
                <textarea class="ex-full-width" name="Comments" pInputTextarea formControlName="Comments"
                    autoResize="autoResize"></textarea>
            </div>
            <control-messages [control]="RegistrationForm.controls['Comments']"></control-messages>
        </div>
        <div class="form-group">
            <label for="Avatar">
                <span translate></span>
            </label>

            <div class="tab-content">
                <ecpl-document-explorer [uploaderConfig]="upConfig" (saveChanges)="saveUploaded($event)"
                    [uploadedFiles]="uploadedFiles">
                </ecpl-document-explorer>
            </div>
        </div>
        <div *ngIf="isEdit">
            <div class="form-group-50_50">
                <div class="form-group">
                    <label for="Fdate">
                        <span translate>FROM_DATE</span>
                    </label>
                    <div>
                        <my-date-picker name="startdate" [options]="startDateOptions"
                            placeholder="{{'SELECT_START_DATE'|translate}}"
                            (dateChanged)="onRegistrationStartDateChanged($event)" required></my-date-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Edate">
                        <span translate readonlyInput="true">END_DATE</span>
                    </label>
                    <div>
                        <my-date-picker name="enddate" [options]="endDateOptions"
                            placeholder="{{'SELECT_END_DATE'|translate}}"
                            (dateChanged)="onRegistrationEndDateChanged($event)" required></my-date-picker>
                    </div>
                </div>
            </div>
            <div class="table-view">
                <!-- Table starts -->
                <p-table #documentTable [value]="documentUpload" [rows]="10" [paginator]="true"
                    exportFilename="Registration Details">
                    <!-- Caption -->
                    <ng-template pTemplate="caption">
                        <div class="ui-helper-clearfix">
                            <div class="table-options">
                                <div *ngIf="documentUpload.length>0" class="pull-right">
                                    <kuba-export [isPDF]="true"
                                        [reportData]="documentTable.filteredValue || documentTable.value"
                                        [additionalData]="additionalData"></kuba-export>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'DOCUMENT_NAME' | translate}}</th>
                            <th>{{ 'IMG_PREVIEW' | translate}}</th>
                            <th>{{'CREATED_ON' | translate}}</th>
                            <th>{{'CREATED_BY' | translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-document let-i="rowIndex">
                        <tr>
                            <td>
                                <a href="{{document.Path}}">{{document.FileName | SpecifiChar}}</a>
                            </td>
                            <td>
                                <img *ngIf="document.MimeType==='image/jpeg'" (click)="PreviewProjectPhoto(document)"
                                    src="{{document.Path}}" alt=" " width="90px ">
                                <img *ngIf="document.MimeType==='image/png'" (click)="PreviewProjectPhoto(document)"
                                    src="{{document.Path}}" alt=" " width="90px ">
                                <img *ngIf="document.MimeType==='image/gif'" (click)="PreviewProjectPhoto(document)"
                                    src="{{document.Path}}" alt=" " width="90px ">
                            </td>
                            <td>
                                <span>{{document.CreatedOn | date : 'MM/dd/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{document.CreatorName}}</span>
                            </td>
                            <td class="col-button">
                                <a (click)="deleteDoc(document.Id,i)" class="btn btn-icon"
                                    title="{{'DELETE'|translate}}" routerLinkActive="active">
                                    <i class="icon ic-sm icon-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="5">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
        </div>
    </form>
    <div class="action-btns-wrapper text-left">
        <button class="btn btn-outline-secondary" (click)="gotoBack()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="submitForm()" *ngIf="!isGuest"
            [disabled]="!RegistrationForm.valid">
            <span translate>SAVE</span>
        </button>
    </div>
    <router-outlet></router-outlet>
</div>
<p-dialog header="{{'TYPE_OF_REGISTRATIONS' | translate}}" (onHide)="onregistrationTypeFormHide()" [(visible)]="addType"
[style]="{width: '350px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="registrationTypeForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>REGISTRATION</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="Name" class="label-input form-control" required>
            </div>
            <label for="title" color class="col-form-label" *ngIf="isLabelDisabled" [ngStyle]="{'color': 'red'}">
                <span translate>TOPIC_EXIST</span>
            </label>
            <label for="title" color class="col-form-label" *ngIf="isTypeAllocated" [ngStyle]="{'color': 'red'}">
                <span translate>CANNOT_DELETE</span>
            </label>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType"
                (click)="viewRegistrationType()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="registrationTypeForm.invalid"
                (click)="saveType()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="viewType">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="editTypes" [rows]="5" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'REGISTRATION' | translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="dt.filter($event.target.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-categoryType>
                        <tr>
                            <td>
                                <span>{{categoryType.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                    (click)="editType(categoryType)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                    (click)="deleteType(categoryType)">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" width="425" acceptLabel="{{'SWITCH_YES'|translate}}"
    rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<p-dialog id="imgPreviewDialog" (click)="ExitPreviewProjectPhoto() " header="{{displayPhotoTitle}} "
    [(visible)]="displayPreviewPhoto"  [style]="{width: '600px'}" [resizable]="false" [draggable]="false">
    <div class="text-center preview-img-popup">
        <img src="{{displayPhotoURL}}" alt="">
    </div>
</p-dialog>