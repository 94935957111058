import { ContactWorkLocation } from './contactWorkLocation';
export class ContactEdit {
    Id: number;
    RoleName: string;
    ContactTypeId: number;
    ContactWorkLocationId: number;
    Status: number;
    ContactFunctionalAreaId: number;
    ContactFunctionalArea: string;
    Name: string;
    Address: string;
    Zipcode: string;
    Location: string;
    CountryCode: string;
    Mobile: string;
    Telephone: string;
    Fax: string;
    Emailid: string;
    WorkPosition: string;
    Notes: string;
    IsSMS: string;
    IsEmail: string;
    ShowInBusiness: boolean;
    ShowInContact: string;
    ShowInApplication: string;
    ShowInPortal: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    BusinessName: string;
    ShowOnDashBoard: boolean;
    ShowToGuest: boolean;
    IsEmailNewDeviation: boolean;
    BusinessId: number;
    AppId: number;
    SuggestionBoxNotification: boolean;
    ContactWorkLocation: ContactWorkLocation;
    IsPortal: boolean;
    PortalId: number;
}

export class ContactType {
    Name: string;
    Id: number;
}
export class CutomerContactsWithLogin {
    Id: number;
    ContactTypeId: number;
    RoleName: string;
    UserName: string;
    UserId: number;
    ContactWorkLocationId: number;
    Status: string;
    ContactFunctionalAreaId: number;
    ContactFunctionalArea: string;
    Name: string;
    Address: string;
    Zipcode: string;
    Location: string;
    Mobile: number;
    Telephone: number;
    Fax: number;
    Emailid: string;
    WorkPosition: string;
    Notes: string;
    ShowInBusiness: boolean;
    IsSMS: boolean;
    IsEmail: boolean;
    ShowInContact: boolean;
    ShowInApplication: boolean;
    ShowInPortal: boolean;
    CreatedBy: Date;
    ModifiedBy: Date;
    CreatedOn: Date;
    ModifiedOn: Date;
    BusinessName: string;
    BusinessId: number;
    ContactType: number;
    ContactFunctionalAreaDto: null;
    ContactWorkLocation: null;
    CountryCode: null;
}
