
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'topic',
    templateUrl: 'topics.component.html'
})
export class TopicComponent implements OnInit {

    data: any;

    constructor() { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }

}
