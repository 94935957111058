
import { Component, OnInit } from '@angular/core';
import { RiskanalysisService } from "../services/risk-analysis.service";
import { Riskanalysis} from "../models/riskanalysis";
@Component({
    selector: 'staticdata',
    templateUrl: 'staticdata.component.html'
})
export class staticdataComponent implements OnInit {
    constructor(private RiskanalysisService: RiskanalysisService) { }
     Riskanalysis: Riskanalysis[];
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
     
}