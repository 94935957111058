import { Injectable } from '@angular/core';

import { BusinessServices } from './businesses/services/business.services';
import { JobAnalysisService } from './safety-job-analysis/services/jobanalysis.services';
import { DeviationServices } from './follow-ups/services/deviation.services';
import { BusinessDepartmentService } from './business-department.services';
import { RiskanalysisService } from './follow-ups/services/risk-analysis.service';
import { UserService } from './users/services/user.service';  

@Injectable({
    providedIn: 'root'
})
export class CacheHandlerService {
    constructor(
        private businessServices: BusinessServices,
        private deviationServices: DeviationServices,
        private jobAnalysisService: JobAnalysisService,
        private userService: UserService,
        private riskanalysisService: RiskanalysisService,
        private businessDepartment: BusinessDepartmentService 
        ) {

    }
    clearCachedData() {
        this.businessServices.clearCachedBusinessService();
        this.businessDepartment.clearCachedAllDepartment();
        this.jobAnalysisService.clearCachedIssuesType();
        this.deviationServices.clearCachedCaseType();
        this.userService.clearCachedUserList();
       this.riskanalysisService.clearCachedTopic();
    }
}

