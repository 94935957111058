import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UploaderConfig } from './uploader-config';
import { UploadedFile } from './interfaces';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'ecpl-document-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.css']
})
export class ExplorerComponent implements OnInit {
  @Input()
  uploaderConfig: any;
  @Input() folderList: SelectItem[];
  @Input() uploadedFiles: any;
  @Input() triggerUploader: boolean;
  @Input() ecplApiResponse: string;
  @Input() businessMode: string;
  @Input() adminUsers: any[];

  @Output()
  saveChanges: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  activeFolder: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  filterFolderEvents: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  toogleEditDisplayMode: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openChecklistOrFormEmit: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  checkChange(event: any) {
    this.saveChanges.emit(event);
  }

  activatedFolder(event: any) {
    this.activeFolder.emit(event);
  }

  onFilteredFolder(event: any) {
    this.filterFolderEvents.emit(event);
  }

  toogleEditMode(event: any) {
    this.toogleEditDisplayMode.emit(event);
  }

  openChecklistOrForm(event:any){
    this.openChecklistOrFormEmit.emit(event);
  }
  ngOnInit() {
  }
}
