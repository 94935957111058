import { Category } from './../../../businesses/models/category';
import { TypeOfVehicleVehicleResolver } from './../../../vehicle/services/vehicle.resolver.service';
import { BaseServices } from './../../../kuba.services';
import { UploaderConfig } from './../../../../shared/ecpl-document-explorer/uploader-config';
import { HelperService } from './../../../../_services/helper.service';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { Faq } from './../../models/faqs';
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FaqServices } from './../../services/faqs.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Feature } from '../../../features/models/features-base.model';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'custom-faq',
    templateUrl: 'customhelp.component.html'
})
export class CustomHelpComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    CustomfaqForm: FormGroup;
    selectedCategory: string;
    faqVideo: any;
    upConfig: UploaderConfig;
    Id: number;
    cFaq: any;
    additionalData: any;
    videoPath: string;
    Faq: SelectItem[];
    Faqs = new Faq();
    text: string;
    faqTitle: string;
    faqFormData: any;
    uploadedFiles = [];
    newVideoAdded: any = [];
    videoName: string;
    featureId: number;
    additionalFeatureId: number;
    categorySelected: boolean;
    public selectedFiles;
    private subscriptions: Subscription[] = [];
    /* private uploadConfig: Ng4FilesConfig = {
        acceptExtensions: ['mp4', 'avi'],
        maxFilesCount: 1
    }; */
    uploadStart: boolean;
    constructor(
        private faqsvc: FaqServices,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private http: HttpClient,
        private translate: TranslateService
    ) {
        this.CustomfaqForm = this.fb.group({
            Id: ['', Validators.required],
            ContentData: ['']
        });
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.Faq = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Faq = [];
            this.Faq.push(
                { label: val.SELECT, value: 0 }
            );
        }));
        this.subscriptions.push(this.faqsvc.getAllFeatures().subscribe(result => {
            this.cFaq = result;
            for (let i = 0; i < this.cFaq.length; i++) {                
                if (this.cFaq[i].TranslateKey == "HMS-TV" || this.cFaq[i].TranslateKey == "INTERNAL-TV" || this.cFaq[i].TranslateKey == "PORTAL-TV") {
                    this.cFaq.splice(i,1);
                    i--;
                }
            }
            this.cFaq.forEach(element => {
                this.Faq.push({ label: this.translate.instant(element.Title.toUpperCase()), value: { id: element.Id, additionalFeatureId: element.ParentFeatureId } });
            });
        }));
    }

    editFaq(event: any, dropDown: any) {
        this.videoPath = null;
        this.categorySelected = true;
        this.additionalFeatureId = dropDown.selectedOption.value.additionalFeatureId;
        this.featureId = dropDown.selectedOption.value.id;
        this.faqTitle = dropDown.selectedOption.label;
        this.subscriptions.push(this.faqsvc.getFaqById(this.featureId).subscribe((result:any) => {
            this.faqFormData = result;
            if (result) {
                this.Id = result.Id;
                this.videoPath = result.VideoPath;
                this.Faqs.Id = event.value;
                this.Faqs.ContentData = result.ContentData;
                this.additionalData = {
                    ApplicationId: `${BaseServices.ApplicationId}`,
                    BusinessId: `${BaseServices.BusinessId}`,
                    Status: '1',
                    CreatedOn: new Date(),
                    ModifiedBy: `${BaseServices.UserId}`,
                    ModifiedOn: new Date(),
                    ParentId: `${this.Id}`,
                    ChildId: `${this.Id}`,
                    ModuleName: 'Deviation'
                };
                this.upConfig = {
                    title: 'Video URL',
                    titleAsLabel: true,
                    windowedHeight: false,
                    viewSwitch: false,
                    showUserMeta: false,
                    showSearchFilter: false,
                    showUploadButton: true,
                    uploaderUri: environment.BASE_URL + '/faq/file/upload',
                    addtionalData: this.additionalData
                };
                (<FormGroup>this.CustomfaqForm).setValue(this.Faqs, {
                    onlySelf: true
                });
            } else {
                this.Id = 0;
                this.videoPath = null;
                this.Faqs.Id = 0;
                this.Faqs.ContentData = null;
            }
        }));
    }

    saveFaq(data) {
        let faqs = new Faq();
        faqs.Id = this.Id ? this.Id : 0;
        faqs.FeatureId = this.featureId;
        faqs.AdditionalFeatureId = this.additionalFeatureId;
        faqs.ContentData = data.ContentData
            ? data.ContentData.replace('</p>', '')
            : '';
        faqs.ContentData = faqs.ContentData
            ? faqs.ContentData.replace('<p>', '')
            : '';
        faqs.VideoPath = this.videoPath;
        faqs.Title = this.faqTitle;
        faqs.Status = '1';
        faqs.CreatedBy = BaseServices.UserId;
        if (this.Id > 0) {
            faqs.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.faqsvc.update(this.Id, faqs).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        } else {
            this.subscriptions.push(this.faqsvc.add(faqs).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        }
    }

    clear() {
        this.Faqs = new Faq();
        (<FormGroup>this.CustomfaqForm).setValue(this.Faqs, { onlySelf: true });
    }
    /**
     * file upload events
     * @param event {any}
     */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.type;
            switch (eventType) {
                case 'remove':
                    this.uploadedFiles = this.uploadedFiles.filter(
                        x => x.id !== +event.id
                    );
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let deviationDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    deviationDoc.id = 0;
                    deviationDoc.createdBy = BaseServices.UserId;
                    this.newVideoAdded.push(
                        HelperService.switchKeysCase(deviationDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    filesSelect(selectedFiles: any): void {
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/internaltv/file/upload/business/internal-tv/' +
                BaseServices.ApplicationId +
                '/' +
                BaseServices.BusinessId,
                formData,{headers:headers}
            )
            .map((response:any) => {
                this.uploadStart = false;
                let res = response;
                this.videoPath = res.path;
                this.videoName = this.videoPath.replace(/^.*[\\\/]/, '');
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
        ));
    }

    getFeatures() {
        let features = JSON.parse(BaseServices.getUserFeatures().Features);
        if (features) {
            this.Faq = [];
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.Faq = [];
                this.Faq.push(
                    { label: val.SELECT, value: null }
                );
            }));
            features.forEach(mainFeature => {
                if (mainFeature) {
                    this.Faq.push({
                        label: mainFeature.name,
                        value: { id: mainFeature.id, additionalFeatureId: mainFeature.ParentFeatureId }
                    });
                    if (mainFeature.children) {
                        mainFeature.children.forEach(subFeature => {
                            if (subFeature) {
                                this.Faq.push({
                                    label: subFeature.name,
                                    value: { id: subFeature.id, additionalFeatureId: subFeature.ParentFeatureId }
                                });
                            }
                        });
                    }
                }
            });

        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

}
