import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ManualService } from './../services/manual.services';
import { KubaGeneralService } from './../../kuba-general.services';
import { ManualView, ManualEdit } from './../models/index';
import { BaseServices } from './../../kuba.services';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { Toast } from 'angular2-toaster';
import { ManualVersion } from 'src/app/kuba/manuals/models/manual-version';
import { UserRole } from '../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { AdminUserService } from 'src/app/kuba/adminuser/services/adminuser.service';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { Subscription } from 'rxjs';
@Component({
    selector: 'assignmanual',
    templateUrl: 'assignmanual.component.html'
})


export class AssignManualComponent implements OnInit {
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    Portal = 'Portal';
    Business = 'Business';
    selectedType = 'Portal';
    typeList: SelectItem[];
    portalList: SelectItem[];
    businessList: SelectItem[];
    isAllCommonManual = true;
    CommonManuals: ManualView[];
    assignManuals: ManualView[];
    editCommonManual: ManualEdit[];
    GetManualEdit: ManualEdit[];
    objManualEdit: ManualEdit;
    userRole: number;

    levelId: number;
    levelKey: number = null!;
    appId: number;
    mode: number;

    showVersions = false;
    viewType = false;

    BusinessOrPortalList: ManualView[];
    SelectedPortalList: ManualView[];
    selectedCommonList: ManualView[];
    PortalCommonList: ManualView[];
    activeList: ManualView[];
    setbooleanTrue = true;
    setbooleanFalse = false;

    manualVersions: ManualVersion[];
    manualVersionId: any = null;
    manualVersionField: any = null;
    versionDetails: ManualVersion;
    manualVersionDropDown: SelectItem[];

    optionName: string;
    userList: SelectItem[];
    businessId: number;
    portalId: number;
    roleId: any;
    adminUser: any[];
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute,
        private manualService: ManualService, private kubaGeneralService: KubaGeneralService,
        private translate: TranslateService, private confirmationService: ConfirmationService,
        private businessService: BusinessServices) {

        this.ShowHideCommonListColumns();
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        // Bind portal list
        this.portalList = [];
        if (+BaseServices.roleId !== UserRole.PORTAL) {
            this.subscriptions.push(this.kubaGeneralService.getPortalList().subscribe((result: any) => {
                if (result) {
                    result.forEach((element:any) => {
                        this.portalList.push({ label: element.Name, value: element.Id })
                    });
                    this.BindDropDownList();
                }
            }));
            this.adminUser = [];
            this.adminUser = this.route.snapshot.data['adminUsers'];
        }

        // Bind business list
        this.businessList = [];
        if(+BaseServices.roleId == UserRole.PORTAL) {
            this.subscriptions.push(this.businessService.getAllPortalBusiness(BaseServices.PortalId).subscribe((result:any) => {
                if (result) {
                    result.forEach((element:any) => {
                        this.businessList.push({ label: element.CompanyName, value: element.Id })
                    });
                    this.BindDropDownList();
                }
                this.BindListValues();
            })); 
        } else {
            this.subscriptions.push(this.businessService.getAllAdminBusinesses().subscribe((result:any) => {
                if (result) {
                    result.forEach((element:any) => {
                        this.businessList.push({ label: element.CompanyName, value: element.Id })
                    });
                    this.BindDropDownList();
                }
                this.BindListValues();
            }));   
        }
      
        if (BaseServices.roleId === '2') {
            this.selectedType = 'Business';
            this.userRole = 2;
        }
        this.bindManualVersions();
        this.bindManualVersionsdropdown();
    }

    // Bind dropdown based on selected radio options
    onRadioTypeChange(type: string) {
        this.levelKey = null!;
        this.selectedType = type;
        this.BindDropDownList();
        this.BindListValues();
    }

    onChangeType(type: SelectItem, label: string) {
        this.ShowHideCommonListColumns();
        this.BindListValues();
        this.bindBusinessUserList();
    }


    BindListValues() {
        let ddd = BaseServices.roleId;
        if (BaseServices.roleId === '1') {
            if (this.levelKey == null) {
                this.subscriptions.push(this.manualService.getCommonManualListApplication(BaseServices.ApplicationId)
                    .subscribe((result:any) => { this.CommonManuals = result; }));
            } else {
                if (this.selectedType === 'Portal') {
                    this.roleId = {
                        portalId: this.levelKey ? this.levelKey : 0,
                        businessId: 0
                    };
                    this.subscriptions.push(this.manualService.GetUnassignedManualByPortalLevel(BaseServices.ApplicationId, this.levelKey)
                        .subscribe((result:any) => { this.CommonManuals = result; }));
                        this.subscriptions.push(this.manualService.GetAssignedManualforPortal(this.levelKey).subscribe((result:any) => {
                        this.assignManuals = result;
                    }));
                } else {
                    this.roleId = {
                        portalId: 0,
                        businessId: this.levelKey ? this.levelKey : 0
                    };

                    this.subscriptions.push(this.manualService.GetUnassignedManualByBusinessLevel(BaseServices.ApplicationId, this.levelKey)
                        .subscribe((result:any) => { this.CommonManuals = result; }));
                        this.subscriptions.push(this.manualService.GetAssignedManualforBusiness(this.levelKey).subscribe((result:any) => {
                        this.assignManuals = result;
                    }));
                }
            }
        } else {
            if (this.levelKey == null) {
                this.subscriptions.push(this.manualService.GetCommonAssignManualByPortalLevel(+BaseServices.PortalId).subscribe((result:any) => {
                    this.CommonManuals = result;
                }));
            } else {
                this.subscriptions.push(this.manualService.GetUnassignedManualByBusinessLevelByPortalLogin(+BaseServices.PortalId, this.levelKey)
                    .subscribe((result:any) => { this.CommonManuals = result; }));
                    this.subscriptions.push(this.manualService.GetAssignedManualforBusiness(this.levelKey).subscribe((result:any) => {
                    this.assignManuals = result;
                }));
            }
        }
    }

    ShowHideCommonListColumns() {
        if (this.levelKey != null) {
            this.isAllCommonManual = true;
        } else {
            this.isAllCommonManual = false;
        }

    }

    BindDropDownList() {
        // Bind dropdown based on type

        this.typeList = [];

        if (this.selectedType === 'Portal') {
            this.subscriptions.push(this.translate.stream('SELECT_PORTAL_DROP').subscribe(val => {
                this.typeList = [];
                this.typeList.push(
                    { label: val.SELECT_PORTAL, value: null }
                );
                this.portalList.forEach(element => {
                    this.typeList.push(element);
                });
            }));

            this.levelKey = null!;
        } else {
            this.subscriptions.push(this.translate.stream('SELECT_BUSINESS_DROP').subscribe(val => {
                this.typeList = [];
                this.typeList.push(
                    { label: val.SELECT_BUSINESS, value: null }
                );
                this.businessList.forEach(element => {
                    this.typeList.push(element);
                });
            }));
            this.levelKey = null!;
        }
        if (this.levelKey) {
            this.levelKey = null!;
        }

        this.ShowHideCommonListColumns();
    }

    onBusinessOrPortalManualsEdit(list: ManualView[]) {
        this.BusinessOrPortalList = list;
        this.updateBusinessOrPortalManuals();
    }

    onSelectedCommonManuals(list: ManualView[]) {
        this.selectedCommonList = list;
        this.AddCommonManuals();
    }

    onPortalCommonManualsEdit(list: ManualView[]) {
        this.PortalCommonList = list;
        this.updatePortalCommonManuals();
    }

    updateBusinessOrPortalManuals() {
        if (this.BusinessOrPortalList) {
            this.BusinessOrPortalList = this.BusinessOrPortalList.filter(list => list.isUpdate === true);
            if (this.selectedType === 'Portal') {
                this.subscriptions.push(this.manualService.UpdatePortalAssignedManuals(this.BusinessOrPortalList).subscribe(result => {
                    this.BusinessOrPortalList = [];
                    this.BindListValues();
                    this.toasterComponent.callToast();
                }));

            } else {
                this.subscriptions.push(this.manualService.UpdateBusinessAssignedManuals(this.BusinessOrPortalList).subscribe(result => {
                    this.BusinessOrPortalList = [];
                    this.BindListValues();
                    this.toasterComponent.callToast();
                }));
            }
        }
    }

    AddCommonManuals() {
        this.editCommonManual = [];

        if (this.selectedType === 'Portal') {
            this.levelId = 2;
        } else {
            this.levelId = 3;
        }
        this.levelKey = this.levelKey;
        for (let listvalue of this.selectedCommonList) {
            listvalue.CreatedBy = BaseServices.UserId;
            if (BaseServices.roleId === '1') {
                listvalue.AppManualId = listvalue.Id;
            }
            if (this.selectedType === 'Portal') {
                listvalue.BusinessPortalId = this.levelKey;
            } else {
                listvalue.BusinessId = this.levelKey;
                if (listvalue.BusinessPortalId === null) {
                    listvalue.BusinessPortalId = 0;
                }
            }
        }

        if (this.selectedType === 'Portal') {
            this.subscriptions.push(this.manualService.AddAssignManualsToPortal(this.selectedCommonList).subscribe(result => {
                this.selectedCommonList = [];
                this.BindListValues();
                this.toasterComponent.callToast()
            }));
        } else {
            this.subscriptions.push(this.manualService.AddAssignManualToBusiness(this.selectedCommonList).subscribe(result => {
                this.selectedCommonList = [];
                this.BindListValues();
                this.toasterComponent.callToast()
            }));
        }
    }

    updatePortalCommonManuals() {
        if (this.PortalCommonList) {
            this.PortalCommonList = this.PortalCommonList.filter(list => list.isUpdate === true);
            this.subscriptions.push(this.manualService.UpdatePortalCommonManuals(this.PortalCommonList).subscribe(result => {
                this.PortalCommonList = [];
                this.BindListValues();
                this.toasterComponent.callToast();
            }));
        }
    }

    bindManualVersions() {
        this.manualVersions = [];
        this.subscriptions.push(this.manualService.GetAppManualVersions(BaseServices.ApplicationId)
            .subscribe((result:any) => {
                this.manualVersions = result;
            }));
    }

    bindManualVersionsdropdown() {
        this.subscriptions.push(this.manualService.GetAppManualVersions(BaseServices.ApplicationId)
            .subscribe((result:any) => {
                this.subscriptions.push(this.translate.stream("SELECT_DROPDOWN").subscribe(val => {
                    this.manualVersionDropDown = [];
                    this.manualVersionDropDown.push(
                        { label: val.SELECT, value: null }
                    );
                }))
                result.forEach(element => {
                    this.manualVersionDropDown.push({ label: element.VersionNo, value: element.Id })
                });

            }));
    }

    editManualVersion(versionDetails: ManualVersion) {
        this.manualVersionField = versionDetails.VersionNo;
        this.manualVersionId = versionDetails.Id;
        this.versionDetails = versionDetails;
    }


    addEditManualDetails() {
        if (this.manualVersionId) {
            this.versionDetails.VersionNo = this.manualVersionField;
            this.subscriptions.push(this.manualService.addUpdateManualVersion(this.versionDetails).subscribe(result => {
                this.bindManualVersions();
                this.manualVersionField = null;
            }));
            this.manualVersionId = null;
        }
        else {
            this.versionDetails = {}
            this.versionDetails.VersionNo = this.manualVersionField;
            this.versionDetails.Id = null;
            this.subscriptions.push(this.manualService.addUpdateManualVersion(this.versionDetails).subscribe(result => {
                this.bindManualVersions();
                this.manualVersionField = null;
            }));

        }
    }

    deleteManualVersion(versionDetails: ManualVersion) {
        this.subscriptions.push(this.manualService.deleteManualVersion(versionDetails.Id).subscribe(result => {

            this.bindManualVersions();
        }));
    }


    bindBusinessUserList() {
        this.userList = [];
        if (this.levelKey != null) {
            if (this.selectedType === 'Portal') {
                this.userList = [];
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.userList = [];
                    this.userList.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                if (+BaseServices.roleId === 1) {
                    if (this.adminUser && this.adminUser.length > 0) {
                        this.adminUser.forEach(x => {
                            this.userList.push(
                                { label: x.Name, value: x.Id }
                            );
                        });
                    }
                }
                let portal = this.portalList.filter(list => (list.value === this.levelKey || list.value == null));
                if (portal.length > 0) {
                    this.userList.push(
                        { label: portal[0].label, value: portal[0].value }
                    );
                }
            } else {
                this.subscriptions.push(this.manualService.getBusinessUserListForManuals(this.levelKey)
                    .subscribe((result:any) => {
                        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                            this.userList = [];
                            this.userList.push(
                                { label: val.SELECT, value: null }
                            );
                        }));
                        if (+BaseServices.roleId === 1) {
                            this.userList.push(
                                { label: BaseServices.name, value: BaseServices.UserId }
                            );
                        }
                        result.forEach((element:any) => {
                            this.userList.push({ label: element.Name, value: element.Id })
                        });
                        this.BindListValues();
                    }));
            }
        }
    }

    onDeleteAssignedManual(manualDetails: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
            accept: () => {
                if (this.selectedType === 'Portal') {
                    this.subscriptions.push(this.manualService.deletePortalAssignedManual(manualDetails)
                        .subscribe(result => {
                            this.BindListValues();
                            this.toasterComponent.callToastDlt();
                        }));
                } else {
                    this.subscriptions.push(this.manualService.deleteBusinessAssignedManual(manualDetails)
                        .subscribe(result => {
                            this.BindListValues();
                            this.toasterComponent.callToastDlt();
                        }));
                }
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
