<div class="error-page-panel">
    <div class="error-page-panel-inner">
        <div class="col-sm-6 mbtm-30">
            <img src="{{assetPath}}/images/KubaLogo.png" alt="KUBA">
        </div>
        <div class="col-sm-6">
            <h1 class="m-0"> {{viewBag.type}}
                <br>
                <small class="text-muted">
                    Error {{error}}
                </small>
            </h1>
            <p class="mtop-20 mbtm-10">{{viewBag.message}}</p>
            <a [routerLink]="" (click)="onBacktoPage()" class="btn btn-outline-primary">
                <span class="icon ic-sm icon-back"></span>
                <span>Go to Home</span>
            </a>
        </div>
    </div>
</div>
<style>
    .error-page-panel {
        display: table;
        width: 100%;
        height: 100%;
        color: #555;
    }

    h1 {
        font-size: 24px;
    }

    .error-page-panel-inner {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .error-page-panel-inner>div:first-child {
        text-align: right
    }

    .error-page-panel-inner>div:last-child {
        text-align: left
    }

    @media screen and (max-width:992px) {
        .error-page-panel-inner>div:first-child,
        .error-page-panel-inner>div:last-child {
            text-align: center
        }
    }
</style>