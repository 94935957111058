export * from './manual-list.component';
export * from './manual-form.component';
export * from './manual-view.component';
export * from './manual-tabs.component';
export * from './manual-base.component';
export * from './manual-registry.component';
export * from './manual-laws-regulation.component';
export * from './assignmanual.component';


