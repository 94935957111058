import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

import {
    UploadFile,
    UploadInput,
    UploaderOptions,
    UploadOutput
} from 'ngx-uploader';
import { CustomizeHomePageService } from './../../customize-homepage.service';
import { CustomizeHomePage } from './../../models/customize-homepage';
import { BaseServices } from './../../../kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
@Component({
    selector: 'customize-page',
    templateUrl: 'customize-homepage-tab.component.html'
})
export class CustomizeHomePageCreationComponent implements OnInit {
    //#region variable

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    text: string;
    HomepageForm: FormGroup;
    applicationId: number;
    customId: number;
    customizeHomepage = new CustomizeHomePage();
    Id: number;
    isVideoUpload = false;
    isFileChoosed = false;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    option: UploaderOptions;
    hmsVideo: any;
    logo: any;
    fileName: string;
    isAdmin: boolean;
    portalLogo: boolean;
    videoTab: boolean;
    public selectedFiles;
    uploadStart: boolean;
    videoId: number;
    isDefaultVideo: boolean;
    defaultVideoTv: string;
    applicationName: string;
    isShowSubFeatures: boolean;
    logoclass: string

    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param customizeHomePageService {CustomizeHomePageService}
     */
    constructor(
        private formBuilder: FormBuilder,
        private customizeHomePageService: CustomizeHomePageService,
        private http: HttpClient,
        private confirmationService: ConfirmationService,
        public route: ActivatedRoute,
        private location: Location,
        private translate: TranslateService
    ) {
        this.uploadInput = new EventEmitter<UploadInput>();
        this.HomepageForm = this.formBuilder.group({
            ContentData: ['']
        });
    }
    //#endregion

    //#region page-events
    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.applicationName = BaseServices.ApplicationName;
        let defaultVideo = <any>this.route.snapshot.data['defaultVideo'];
        if (defaultVideo != null) {
            this.defaultVideoTv = defaultVideo.ContentData;
            this.isDefaultVideo = true;
        }
        this.applicationId = BaseServices.ApplicationId;
        if (this.applicationId == 5) {
            this.isShowSubFeatures = false;
        }
        else {
            this.isShowSubFeatures = true;
        }
        this.isAdmin = +BaseServices.roleId === 1 ? true : false;
        if (+BaseServices.roleId === 2) {
            this.videoId = 0;
            this.changeNavigation(56);
            this.logoclass = 'active'
        } else {
            this.changeNavigation(45);
        }
    }
    //#endregion

    //#region control-events

    /**
     * save home page
     */
    saveData() {
        this.customizeHomepage.Id = this.Id ? this.Id : 0;
        this.customizeHomepage.AppId = this.applicationId;
        this.customizeHomepage.BusinessId = BaseServices.BusinessId;
        if (this.isVideoUpload) {
            this.customizeHomepage.Title = this.fileName;
            this.customizeHomepage.ContentData = this.hmsVideo;
        } else if (this.portalLogo) {
            this.customizeHomepage.Title = this.fileName;
            this.customizeHomepage.ContentData = this.logo;
        } else {
            this.customizeHomepage.ContentData = this.HomepageForm.value.ContentData;
        }
        this.customizeHomepage.CmsId = this.customId;
        this.customizeHomepage.Status = '1';
        this.customizeHomepage.CreatedBy = BaseServices.UserId;
        if (this.Id > 0) {
            this.subscriptions.push(this.customizeHomePageService
                .update(this.customizeHomepage)
                .subscribe((isCustomzationUpdated: any) => {
                    if (isCustomzationUpdated) {
                        this.toasterComponent.callToast();
                        this.isDefaultVideo = false;
                    }
                }));
        } else {
            this.subscriptions.push(this.customizeHomePageService
                .add(this.customizeHomepage)
                .subscribe((isCustomzationAdded: any) => {
                    if (isCustomzationAdded) {
                        this.toasterComponent.callToast();
                        this.isDefaultVideo = false;
                    }
                }));
        }
    }

    back() {
        this.location.back();
    }
    /**
     * tab change event
     * @param cmsId {number}
     */
    changeNavigation(cmsId: number) {
        if (cmsId === 54 || cmsId === 56 || cmsId === 58) {
            if (cmsId === 54 || cmsId === 58) {
                this.videoTab = false;
                this.isVideoUpload = true;
                this.portalLogo = false;
            } else if (cmsId === 56) {
                this.videoTab = false;
                this.portalLogo = true;
                this.isVideoUpload = false;
            } else {
                this.videoTab = true;
                this.portalLogo = true;
                this.isVideoUpload = false;
            }
        } else {
            this.videoTab = true;
            this.isVideoUpload = false;
            this.portalLogo = false;
            this.logo = null;
            this.hmsVideo = null;
            this.logoclass = 'inactive';
        }
        this.customId = cmsId;
        this.subscriptions.push(this.customizeHomePageService
            .getData(this.applicationId, this.customId, BaseServices.UserId)
            .subscribe((homePageData: any) => {
                this.Id = homePageData.Id;
                if (this.portalLogo) {
                    this.logo = homePageData.ContentData;
                } else if (this.isVideoUpload) {
                    this.hmsVideo = (homePageData.ContentData) ? homePageData.ContentData : this.defaultVideoTv;
                    if (!homePageData.ContentData) {
                        this.isDefaultVideo = true;
                    }
                    this.videoId = homePageData.Id;
                }
                if (homePageData) {
                    this.isDefaultVideo = false;
                    let homePage = {
                        ContentData: homePageData.ContentData
                            ? homePageData.ContentData
                            : ''
                    };
                    (<FormGroup>this.HomepageForm).setValue(homePage, {
                        onlySelf: true
                    });
                } else {
                    this.isDefaultVideo = true;
                    this.hmsVideo = this.defaultVideoTv;
                    let homePage = {
                        ContentData: ''
                    };
                    (<FormGroup>this.HomepageForm).setValue(homePage, {
                        onlySelf: true
                    });
                }
            }));
    }
    /**
     * delete video
     * @param videoId {number}
     */
    deleteVideo(videoId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.customizeHomePageService
                    .deleteVideo(videoId)
                    .subscribe(isVideoDeleted => {
                        if (isVideoDeleted) {
                            this.hmsVideo = '';
                            this.toasterComponent.callToastDlt();
                            this.subscriptions.push(this.customizeHomePageService
                                .getData(this.applicationId, 59, BaseServices.UserId)
                                .subscribe((homePageData: any) => {
                                    this.hmsVideo = homePageData.ContentData;
                                    this.isDefaultVideo = true;
                                }));
                        }
                    }));
            }
        });
    }
    /**
     * on upload event
     * @param output
     */
    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (
            output.type === 'addedToQueue' &&
            typeof output.file !== 'undefined'
        ) {
            this.isFileChoosed = true;
            this.files.push(output.file);
        } else if (
            output.type === 'uploading' &&
            typeof output.file !== 'undefined'
        ) {
            const index = this.files.findIndex(
                file =>
                    typeof output.file !== 'undefined' &&
                    file.id === output.file.id
            );
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter(
                (file: UploadFile) => file !== output.file
            );
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'start') {
            this.hmsVideo = '';
        } else if (output.type === 'done') {
            this.isDefaultVideo = false;
            if (output.file.response) {
                if (this.portalLogo) {
                    this.logo = output.file.response.Result.path;
                    this.fileName = output.file.name;
                } else {
                    this.hmsVideo = output.file.response.path;
                    this.fileName = output.file.response.originalname;
                }
            }
        } else if (
            output.type === 'rejected' &&
            typeof output.file !== 'undefined'
        ) {
        }
    }
    filesSelect(selectedFiles: any): void {
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };
        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/business/logo/' +
                BaseServices.ApplicationId +
                '/' +
                BaseServices.BusinessId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response;
                this.logo = res.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }
    filesSelectForVideo(selectedFiles: any): void {
        this.hmsVideo = '';
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/homepages/file/upload/business/videos/' +
                BaseServices.ApplicationId,
                formData,BaseServices.headerOption
            )
            .map((response: any) => {
                this.uploadStart = false;
                let res = response.json();
                this.hmsVideo = res.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    clear() {
        this.Id = 0;
        if (this.isVideoUpload) {
            this.hmsVideo = '';
        }
        let homePage = {
            ContentData: ''
        };
        this.logo = null;
        (<FormGroup>this.HomepageForm).setValue(homePage, { onlySelf: true });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
