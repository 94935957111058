import { Component, OnInit } from '@angular/core';
import { CacheHandlerService } from 'src/app/kuba/cached.services';
import { Activity } from 'src/app/kuba/follow-ups/models/activities';
import { ActivityServices } from 'src/app/kuba/follow-ups/services/activities.services';
import { BaseServices } from 'src/app/kuba/kuba.services';

@Component({
  selector: 'app-activities-portal-widget',
  templateUrl: './activities-portal-widget.component.html',
  styleUrls: ['./activities-portal-widget.component.css'],
})
export class ActivitiesPortalWidgetComponent implements OnInit {
  activities: Activity[] = [];
  single: any[] = [];
  paginatedData: any[] = [];
  view: any[];
  loading: boolean = false;

  colorScheme = {
    domain: [
      '#F7941E',
      'var(--color-primary-500)',
      '#329934',
      '#FF4500',
      '#006AB2',
      '#00CED1',
      '#8A2BE2',
      '#FF1493',
      '#FFD700',
      '#FF6347',
      '#FFA07A',
      '#FF00FF',
    ],
  };

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = 'Business Name';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Count';

  // pagination variables
  currentPage: number = 1;
  itemsPerPage: number = 5;
  totalPages: number = 0;
  remoteWindow: any;

  constructor(
    private activityService: ActivityServices,
    private cacheHandlerService: CacheHandlerService
  ) {}

  /**
   * Fetches portal activity data, sorts it, transforms it for the chart, and sets up pagination.
   */
  ngOnInit(): void {
    this.loading = true;
    this.activityService.getPortalActivity(BaseServices.UserId).subscribe(
      (data) => {
        this.activities = data;
        this.sortActivitiesByDate(); // Sort activities by CreatedOnForPdf
        this.transformData(); // Transform the data to be used in the chart
        this.setPaginatedData(this.currentPage);
        this.loading = false; // Data fetch completed
      },
      (error) => {
        console.error('Data fetch error:', error);
        this.loading = false; // Ensure to reset loading flag on error
      }
    );
  }

  /**
   * Sorts the activities array by the CreatedOnForPdf date in descending order.
   */
  sortActivitiesByDate(): void {
    this.activities.sort((a, b) => {
      const dateA = this.parseDate(a.CreatedOnForPdf);
      const dateB = this.parseDate(b.CreatedOnForPdf);
      return dateB.getTime() - dateA.getTime(); // Newest first
    });
  }

  /**
   * Parses a date string in the format 'dd/MM/yyyy' and returns a Date object.
   *
   * @param dateString - The date string to be parsed.
   * @returns A Date object representing the parsed date.
   */
  parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  }

  /**
   * Transforms the activities array into a data structure suitable for chart display.
   */
  transformData(): void {
    const dataMap = new Map<string, number>();

    for (const activity of this.activities) {
      // Filter activities with status "Open"
      if (activity.Status === 'OPEN') {
        const businessName = activity.BusinessName;
        if (dataMap.has(businessName)) {
          dataMap.set(businessName, dataMap.get(businessName) + 1);
        } else {
          dataMap.set(businessName, 1);
        }
      }
    }

    this.single = Array.from(dataMap, ([name, value]) => ({ name, value }));
    this.totalPages = Math.ceil(this.single.length / this.itemsPerPage);
  }

  /**
   * Sets paginated data for the current page.
   * @param page - The current page number.
   */
  setPaginatedData(page: number): void {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = this.single.slice(startIndex, endIndex);
  }

  /**
   * Handles page change events and updates the current page and paginated data.
   * @param page - The new page number.
   */
  onPageChange(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return; // Do nothing if page is out of bounds
    }
    this.currentPage = page;
    this.setPaginatedData(page);
  }

  /**
   * Formats the X-axis tick values for display.
   *
   * @param val - The value to be formatted.
   * @returns A formatted string representation of the value.
   */
  formatXaxisTicks(val: number): string {
    return val.toLocaleString(undefined, { maximumFractionDigits: 0 });
  }

  /**
   * Handles selection event and opens a new window for the selected business' follow-up activities.
   *
   * @param business - The selected business object containing name and related details.
   */
  onSelect(business: any) {
    const selectedActivity = this.activities.find(
      (activity) => activity.BusinessName === business.name
    );

    if (!selectedActivity) {
      console.error('Business not found in activities');
      return;
    }

    const businessId = selectedActivity.BusinessId; // Retrieve the BusinessId

    if (this.remoteWindow) {
      this.remoteWindow.close();
    }

    this.cacheHandlerService.clearCachedData();
    let businessSetting = {
      BusinessName: business.BusinessName, // Using business.BusinessName here
      BusinessLogo: business.BusinessLogo,
    };

    sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
    sessionStorage.setItem('RModeBusinessId', businessId.toString()); // Store businessId in session storage

    let windowOrigin = window.location.origin;
    let windowControl =
      'location=yes,width=' +
      screen.availWidth +
      ', height=' +
      screen.availHeight +
      ',scrollbars=yes,status=yes';
    let baseURL = BaseServices.userBase.BaseUrl;

    this.remoteWindow = window.open(
      windowOrigin +
        '/' +
        baseURL +
        '/app/business/' +
        businessId +
        '/follow-up/activities',
      'Remote',
      windowControl
    )!;
  }
}
