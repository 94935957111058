<form [formGroup]="createManualForm">
  <div class="form-group">
    <label for="title">
      <span translate>TITLE</span>
      <span class="required">*</span>
      <span
        *ngIf="manual.isCommon && manualType !== 'common'"
        pTooltip="This manual was originated from common"
        class="icon ic-sm icon-info"
      ></span>
    </label>
    <div>
      <input
        type="text"
        formControlName="title"
        id="title"
        pInputText
        class="form-control"
        [readonly]="
          manualType != 'common' &&
          manualType != 'portal' &&
          this.manual.Id !== 0
        "
      />
    </div>
    <control-messages
      [control]="createManualForm.controls['title']"
    ></control-messages>
  </div>
  <div class="form-group">
    <label for="description">
      <span translate>DESCRIPTION</span>
    </label>
    <div>
      <textarea
        style="width: 378px"
        pInputTextarea
        formControlName="description"
        id="description"
        class="form-control"
        [readonly]="
          manualType != 'common' &&
          manualType != 'portal' &&
          this.manual.Id !== 0
        "
      ></textarea>
    </div>
  </div>
  <div class="form-group">
    <label for="status">
      <span translate>STATUS</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-dropdown
        [options]="status"
        formControlName="status"
        id="status"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>
    <control-messages
      [control]="createManualForm.controls['status']"
    ></control-messages>
  </div>
  <div
    class="form-group"
    *ngIf="manualType == 'business' && this.manual.Id === 0"
  >
    <label for="ChooseBusiness">
      <span translate>CHOOSE_BUSINESS</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-dropdown
        [options]="chooseBusiness"
        formControlName="business"
        [disabled]="this.manual.Id !== 0"
        id="business"
        [filter]="true"
        [style]="{ width: '100%' }"
        placeholder="{{ 'Select Business' | translate }}"
      ></p-dropdown>
    </div>
  </div>
  <div
    class="form-group"
    *ngIf="manualType == 'portal' && this.manual.Id === 0"
  >
    <label for="portal">
      <span translate>CHOOSE_PORTAL</span>
      <span class="required">*</span>
    </label>
    <div>
      <p-dropdown
        [options]="choosePortal"
        formControlName="portal"
        id="portal"
        [style]="{ width: '100%' }"
        [filter]="true"
        [disabled]="this.manual.Id !== 0"
        placeholder="{{ 'SELECT_PORTAL' | translate }}"
      ></p-dropdown>
    </div>
  </div>
  <div class="form-group" *ngIf="showUpload">
    <div class="upload-file-container">
      <label translate>COVER_IMAGE</label>
      <div class="upload-actions">
        <input
          type="file"
          id="files"
          class="hidden"
          (change)="filesSelectForCoverImage($event)"
        />
        <label for="files" class="btn btn-outline-primary" translate
          >UPDATE_COVER_IMAGE</label
        >
      </div>
      <div *ngIf="uploadStartForCoverImage">
        <span translate>COVER_IMAGE_UPLOADING</span>
      </div>
      <div *ngIf="coverImage != null">
        <img
          src="{{ coverImage }}"
          alt=""
          width="100"
          height="100"
          class="thumbnail"
        />
      </div>
    </div>
  </div>
  <br /><br /><br />
  <div class="action-btns-wrapper">
    <button
      type="button"
      (click)="cancelClose()"
      class="btn btn-outline-secondary"
    >
      <span translate>CANCEL</span>
    </button>
    <button
      type="button"
      (click)="saveManual(true)"
      *ngIf="hideSaveandcontinue"
      [disabled]="!createManualForm.valid"
      class="btn btn-primary"
    >
      <span translate>SAVE_CONTINUE</span>
    </button>
    <button
      type="button"
      (click)="saveManual()"
      *ngIf="showSaveAndClose"
      [disabled]="!createManualForm.valid"
      class="btn btn-primary"
    >
      <span translate>SAVE_AND_EXIT</span>
    </button>
  </div>
</form>
