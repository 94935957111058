import { ContactTypeResolver } from './../contact/services/contact.resolver.service';
import {
    ClientListResolver,
    ClientEditResolver,
    ClientContactListResolver,
    ModuleListResolver,
    ClientListByBusinessResolver,
    CountryCodeResolver
} from './services/client.resolver.service';
import { ClientComponent } from './client.component';
import { CreateClientComponent } from './components/new-client/new-client.component';
import { ClientServices } from './services/client.services';
import { ClientListComponent } from './components/client-list/client-list.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TableModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ContextMenuModule,
        MenuModule,
        DialogModule,
        ReactiveFormsModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        ButtonModule,
        CheckboxModule,
        KubaExportModule,
        FileUploadModule,
        ConfirmDialogModule,
        TooltipModule
    ],
    declarations: [ClientComponent, ClientListComponent, CreateClientComponent],
    providers: [
        ClientServices,
        ClientListResolver,
        ClientEditResolver,
        ClientContactListResolver,
        ConfirmationService,
        ModuleListResolver,
        ClientListByBusinessResolver,
        ContactTypeResolver,
        CountryCodeResolver
    ],
    bootstrap: []
})
export class ClientModule { }
