import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';


import { ContactServices } from './../../contact/services/contact.service';
import { BaseServices } from './../../kuba.services';
import { EditorContact } from './../models/editorcontacts';
import { EditorContactsServices } from './editorcontacts.services';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';

@Injectable()
export class EditorContactListResolver implements Resolve<EditorContact[]> {
    constructor(private backend: EditorContactsServices) { }
    resolve(): Observable<EditorContact[]> {
        return this.backend.contactList();
    }
}
@Injectable()
export class EditorContactListByBusinessResolver implements Resolve<EditorContact[]> {
    constructor(private backend: EditorContactsServices) { }
    resolve(): Observable<EditorContact[]> {
        return this.backend.getContactByBusiness(BaseServices.BusinessId);
    }
}
@Injectable()
export class EditorContactsEditResolver implements Resolve<EditorContact[]> {
    constructor(private backend: EditorContactsServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<EditorContact[]> {
        return this.backend.contactById(+route.params['cId']);
    }
}

@Injectable()
export class FdvUsersResolver implements Resolve<EditorContact[]> {
    constructor(private backend: EditorContactsServices) { }
    resolve(): Observable<EditorContact[]> {
        return this.backend.contactById(BaseServices.BusinessId);
    }
}

@Injectable()
export class FunctionResolver implements Resolve<any> {
    constructor(private backend: ContactServices) { }
    resolve() {
        return this.backend.getAllByFunction();
    }
}
@Injectable()
export class KubaNorgeAsResolver implements Resolve<any> {
    constructor(private backend: EditorContactsServices) { }
    resolve() {
        return this.backend.getKubaNorgeAs();
    }
}
@Injectable()
export class ContactBusinessDataResolver implements Resolve<any> {
  constructor(private backend: BusinessServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(BaseServices.BusinessId);
  }
}