import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { BusinessPortalList } from './../models/businessPortalList';
import { BusinessPortalService } from './business-portal.services';

@Injectable()
export class BusinessPortalListResolver implements Resolve<BusinessPortalList[]> {

  constructor(private backend: BusinessPortalService) { }

  resolve(): Observable<BusinessPortalList[]> {
    return this.backend.getAll();
  }
}


@Injectable()
export class BusinessPortalEditResolver implements Resolve<BusinessPortalList> {

  constructor(private backend: BusinessPortalService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['pid']);
  }
}

@Injectable()
export class PortalManagerUserlistResolver implements Resolve<any[]> {
  constructor(private backend: BusinessPortalService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getPortalUsersByPortalManagerId(+route.parent!.params['pid']);
  }
}

@Injectable()
export class PortalBusListResolver implements Resolve<any[]>{
  constructor(private backend: BusinessPortalService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this.backend.getPortalbusList(+route.parent!.params['pid']);
  }
}

