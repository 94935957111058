<div class="main-content">
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./contract_content']" translate>CONTRACT_CONTENT</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./inspect_settings']" translate>INSPECTION_SETTINGS</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./deviation_settings']" translate>DEVIATION_SETTINGS</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./report_templates']" translate>REPORT_TEMPLATES</a>
                </li>
                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['./instruments']" translate>_INSTRUMENTS</a>
                </li>
            </ul>
        </nav>
    </div>
    <router-outlet></router-outlet>
</div>