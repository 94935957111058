
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { FdvDocumentServices } from './services/fdv-doc.services';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBaseModule } from './../common/common.module';
import { EcplDocumentExplorerModule } from '../../shared/ecpl-document-explorer';
import { EcplArticleViewerModule } from './../../shared/ecpl-article-viewer/ecpl-article-viewer.module';
import { EcplArticleViewerComponent } from '../../shared/ecpl-article-viewer/ecpl-article-viewer.component';
import { DialogModule } from 'primeng/dialog';
@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        CommonBaseModule,
        DialogModule,
        EcplDocumentExplorerModule,
        EcplArticleViewerModule
    ],
    exports: [DialogModule,EcplArticleViewerComponent,EcplDocumentExplorerModule,],
    declarations: [],
    providers: [FdvDocumentServices]
})
export class FdvDocumentModule { }
