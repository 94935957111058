import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'vehicle-view',
    templateUrl: 'vehicle-view.component.html'
})
export class VehicleViewComponent implements OnInit {
    pageTitle: string;
    additionalFeatures: any;
    enabledAdditionalFeatures: any;
    vId: number;
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.vId = this.activatedRoute.snapshot.params['id'];
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        let vehicle = this.activatedRoute.snapshot.data['vehicledata'];
        this.pageTitle = vehicle.RegistrationNo || '';
        sessionStorage.setItem('VehicleName',this.pageTitle);
        this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x=>x.id !== 142); // Hide details tab #KW-276
        if (this.enabledAdditionalFeatures.length > 0) {
            this.route.navigate(
                [this.enabledAdditionalFeatures[0].linkText, { Id: this.vId }],
                { relativeTo: this.activatedRoute }
            );
        }
    }

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }
}
