export class ChecklistModel {
    id?: number;
    nodeId?: number;
    filename: string;
    originalFileName: string;
    path: string;
    mimetype: string;
    size: number;
    isFolder: boolean;
    type: string;
    isDefault: boolean;
    documentType: string;
    status: number;
    createdBy: string;
    modifiedBy: number;
    createdOn?: string;
    parentFolderId: number;
    documentTypeId: number;
    content: {
        checkList: {
            id: number;
            title: string;
            scoreIndex: boolean;
            createdBy: string;
            status: string;
            createdOn?: string;
            sections: ChecklistSectionModel[];
        };
    };
}

export class ChecklistSectionModel {
    id: number;
    position: number;
    title: string;
    status: string;
    items: ChecklistSectionItemModel[];
}

export class ChecklistSectionItemModel {
    id: number;
    position: number;
    title: string;
    status: string;
}
