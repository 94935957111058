import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Zone } from './../models/zone';
import { Department } from './../../deviation/models/deviationDepartment';
import { FoodSafety } from './../models/foodsafety';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { BaseServices } from './../../kuba.services';
import { RegistrationType } from './../../Registration/models/registration-type';
import { FoodSafetyManualCompleteChecklist } from '../../shared/models/complete-checklist';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/Product';

@Injectable()
export class FoodSafetyServices {
    constructor(private http: HttpClient) {
    }

    getAllByBusinessId(bid: number) {
        return this.http
            .get(environment.BASE_URL + '/foodsafety/food-safetys/' + bid, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getFoodsafetyById(id: number) {
        return this.http
            .get(environment.BASE_URL + '/foodsafety/food-safety/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getZoneName(businessId: number) {
        return this.http
            .get(environment.BASE_URL + '/foodsafety/zones/' + businessId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // add  the department
    addDepartment(data) {
        let result: Department;
        return this.http
            .post(environment.BASE_URL + '/department', data, BaseServices.headerOption)
            .map(result => result);
    }
    // update  the department
    updateDepartment(department: Department) {
        let result: Department;
        return this.http
            .put(
                environment.BASE_URL + '/department/' + department.Id,
                department,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    // delete  the department
    deleteDepartment(Id: number) {
        let result: boolean;
        return this.http.delete(
            environment.BASE_URL + '/departmentdelete/' + Id, BaseServices.headerOption
        );
    }

    getZone(businessId: number) {
        return this.http
            .get(environment.BASE_URL + '/foodsafety/zones/' + businessId, BaseServices.headerOption)
            .map(result => result);
    }
    addZone(zone) {
        let result: Zone;
        return this.http
            .post(environment.BASE_URL + '/foodsafety/zone', zone, BaseServices.headerOption)
            .map(result => result);
    }
    updateZone(zone) {
        let result: Zone;
        return this.http
            .put(
                environment.BASE_URL + '/foodsafety/Zone/' + zone.Id,
                zone,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    checkAllocatedDepartment(departmentID: number) {
        return this.http
            .get(
                environment.BASE_URL + '/checkdepartmentforfoodsafety/' + departmentID,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    checkAllocatedZone(departmentID: number) {
        return this.http
            .get(
                environment.BASE_URL + '/foodsafety/checkzoneforfoodsafety/' + departmentID,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    deleteZone(Id: number) {
        let result: boolean;
        return this.http.delete(
            environment.BASE_URL + '/foodsafety/Zone/' + Id, BaseServices.headerOption
        );
    }

    createFoodsafety(foodSafety: FoodSafety) {
        return this.http
            .post(
                environment.BASE_URL + '/foodsafety/food-safety',
                foodSafety,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateFoodsafety(foodSafety: FoodSafety) {
        return this.http
            .put(
                environment.BASE_URL + '/foodsafety/food-safety/',
                foodSafety,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteFoodSafety(id: number) {
        let result: boolean;
        return this.http.delete(
            environment.BASE_URL + '/foodsafety/food-safety/' + id, BaseServices.headerOption
        );
    }

    getFoodsafetyFeatures(businessId: number) {
        let featureId: number;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        featureId = workingModule.id;
        return this.http
            .get(
                environment.BASE_URL + '/business/' + businessId,
                BaseServices.headerOption
            )
            .map((result: any) => {
                let feature = result.Features;
                let foodsafetyAdditionalFeature = _.find(JSON.parse(feature), {
                    id: featureId,
                    access: true
                });
                return foodsafetyAdditionalFeature;
            });
    }
    createCompleteChecklist(
        FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist
    ) {
        return this.http
            .post(
                environment.BASE_URL + '/foodsafety/CompleteAllChecklist',
                FoodSafetyManualCompleteChecklist,
                BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }
    // update registration Type
    updateRegType(existingCategory: RegistrationType, key: number) {
        return this.http
            .put(
                `${environment.BASE_URL}/foodsafety/registrationtype`,
                existingCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // adding registration Type
    createRegType(newCategory: RegistrationType, key: number) {
        return this.http
            .post(
                `${environment.BASE_URL}/foodsafety/registrationtype`,
                newCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getFoodByUser(Id: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/getuserFS/' + Id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getZonesbyBusiness() {
        return this.http
            .get(environment.BASE_URL + '/foodsafety/getzonesbybusiness/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getFSDailyTaskList(fsId: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/dailytask/list/' + fsId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    foodSafetyAuditList(featureKey: number, minDate, maxDate, isShiftRight) {
        return this.http.get(environment.BASE_URL + `/foodsafety/foodsafetyauditlist/${featureKey}/${minDate}/${maxDate}/${isShiftRight}`, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveHazardSectionsAndSubSections(hazardAnalysis) {
        return this.http.post(environment.BASE_URL + '/foodsafety/hazardAnalysis/save', hazardAnalysis, BaseServices.headerOption)
            .map(result => result);
    }

    getHazardAnalysis(foodSafetyId: number,featureId :number) {
        return this.http.get(environment.BASE_URL + `/foodsafety/gethazardAnalysis/${foodSafetyId}/${featureId}` ,BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteHazardSection(Id: number) {
        return this.http.delete(environment.BASE_URL + '/foodsafety/deletehazardsection/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    deleteHazardSubSection(Id: number) {
        return this.http.delete(environment.BASE_URL + '/foodsafety/deletehazardsubsection/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    foodSafetyAuditDateList(featureKey: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/getdatelist/' + featureKey, BaseServices.headerOption)
            .map((result: any) => result);
    }

    exportFSHazardAnalysis(appId: number, bId: number, FSId: number, CulInfo, FeatureId: number) {
        let dynamicType = 'application/pdf';
        let url = `${environment.BASE_URL}/foodsafety/exportHazardAnalysis/${appId}/${bId}/${FSId}/${CulInfo}/${FeatureId}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send();
        });

    }

    exportFSAudit(cultureInfo: string, featureId: number, BusinessId, UserId, auditData, zoneId: number,appId : number ,departmentName) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: auditData });
        let url = `${environment.BASE_URL}/export-FSAudit/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${zoneId}/${appId}/${departmentName}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });

    }

    getFSZoneName(fsId: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/getzonename/' + fsId, BaseServices.headerOption)
            .map(result => result);
    }

    getCompleteTempControlPDF(featureId: number, featureKey: number, tempControlChecklistId: number, completeChecklistId: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/getfstempcontrolpdf/' + featureId + '/' + featureKey + '/' + tempControlChecklistId + '/' + completeChecklistId, BaseServices.headerOption)
            .map(result => result);
    }

    exportFSAuditForTempControl(cultureInfo: string, featureId: number, BusinessId, UserId, ChecklistId, CompleteChecklistId, dataList) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: dataList });
        let url = `${environment.BASE_URL}/export-FSAuditTempConrtol/${cultureInfo}/${featureId}/${BusinessId}/${UserId}/${ChecklistId}/${CompleteChecklistId}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            // set authorization header
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });

    }

    exportFSAuditForDailyTask(activityId: number, cultureInfo: string) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: activityId });
        let url = `${environment.BASE_URL}/export-FSAuditDailyTask/${activityId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });

    }

    deleteHazardForm(foodSafetyId: number,featureId:number) {
        return this.http.delete(environment.BASE_URL + `/foodsafety/deletehazardform/${foodSafetyId}/${featureId}` + foodSafetyId,BaseServices.headerOption)
            .map(result => result);
    }

    saveReasonForLateTaskCompletion(data) {
        return this.http.post(environment.BASE_URL + '/foodsafety/saveReason', data, BaseServices.headerOption)
            .map(result => result);
    }

    getAuditInformation(Id: number, Type: string) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/foodsafetyauditDetails/${Id}/${Type}`, BaseServices.headerOption)
            .map(result => result);
    }

    getCoolingBatches(foodsafetyId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/getcoolingbatches/${BaseServices.BusinessId}/${foodsafetyId}`, BaseServices.headerOption)
            .map(result => result);
    }

    getCoolingProducts() {
        return this.http.get(`${environment.BASE_URL}/foodsafety/getcoolingproducts/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(result => result);
    }

    getCoolingProduct(Id: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/getcoolingproduct/${Id}`, BaseServices.headerOption)
            .map(result => result);
    }

    getCoolingTemperatures() {
        return this.http.get(`${environment.BASE_URL}/foodsafety/getcoolingtemperatures`, BaseServices.headerOption)
            .map(result => result);
    }

    getCoolingMeasurements(batchId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/getcoolingmeasurements/${batchId}`, BaseServices.headerOption)
            .map(result => result);
    }

    createCoolingProduct(data) {
        return this.http.post(environment.BASE_URL + '/foodsafety/createcoolingproduct', data, BaseServices.headerOption)
            .map(result => result);
    }

    createCoolingBatch(data) {
        return this.http.post(environment.BASE_URL + '/foodsafety/createcoolingbatch', data, BaseServices.headerOption)
            .map(result => result);
    }

    createCoolingMeasurement(data) {
        return this.http.post(`${environment.BASE_URL}/foodsafety/createcoolingmeasurement`, data, BaseServices.headerOption)
            .map(result => result);
    }

    updateCoolingProduct(data) {
        return this.http.put(environment.BASE_URL + '/foodsafety/updatecoolingproduct', data, BaseServices.headerOption)
            .map(result => result);
    }

    getCoolingBatch(batchId: number) {
        return this.http.get(environment.BASE_URL + '/foodsafety/getcoolingbatch/' + batchId, BaseServices.headerOption)
            .map(result => result);
    }

    updateCoolingBatch(data) {
        return this.http.put(`${environment.BASE_URL}/foodsafety/updatecoolingbatch`, data, BaseServices.headerOption)
            .map(result => result);
    }

    updateCoolingMeasurement(data) {
        return this.http.put(`${environment.BASE_URL}/foodsafety/updatecoolingmeasurement`, data, BaseServices.headerOption)
            .map(result => result);
    }
    deleteCoolingBatch(Id: number) {
        return this.http.delete(environment.BASE_URL + '/foodsafety/deletecoolingbatch/' + Id + '/' + BaseServices.UserId, BaseServices.headerOption)
            .map(result => result);
    }

    deleteCoolingMeasurement(Id: number) {
        return this.http.delete(environment.BASE_URL + '/foodsafety/deletecoolingmeasurement/' + Id + '/' + BaseServices.UserId, BaseServices.headerOption)
            .map(result => result);
    }

    deleteCoolingProduct(Id: number) {
        return this.http.delete(environment.BASE_URL + '/foodsafety/deletecoolingproduct/' + Id + '/' + BaseServices.UserId, BaseServices.headerOption)
            .map(result => result);
    }


    checkProductAllocate(Id: number) {
        return this.http
            .get(
                environment.BASE_URL + '/foodsafety/checkproductcooling/' + Id,
                BaseServices.headerOption
            )
            .map(result => result);

    }

    reportGenerate(batchIds, foodsafetyId: number, cultureInfo: string) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: batchIds });
        let url = `${environment.BASE_URL}/export-FSCoolingBacthlist/${BaseServices.BusinessId}/${foodsafetyId}/${BaseServices.UserId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            // set authorization header
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

}
