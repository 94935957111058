import { HelperService } from './../../../_services/helper.service';
import { ElDetail } from './../../kunde-EL/models/el-edit';
import { FdvDetail } from './../../FDV/models/fdv';
import { FrameworkDetail } from './../../framework-agreement/models/FrameworkEdit';
import { ServiceAgreementDetail } from './../../service-agreement/models/service-agreement';
import { VehicleDetail } from './../../vehicle/models/vehicle';
import { InternalControlDetail } from './../../internal-control/models/internal-control';
import { FoodSafetyDetail } from './../../foodsafety/models/foodsafety';
import { ProjectDetail } from './../../projects/models/projectEdit';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

import { IKubaFeatureDetails } from './kuba-feature-details.interface';
import { FeatureDetailService } from './kuba-feature-details.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from './../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kuba-feature-details',
  templateUrl: './kuba-feature-details.component.html',
  styleUrls: ['./kuba-feature-details.component.css'],
})
export class KubaFeatureDetailsComponent implements OnInit {
  featureKey: any;
  workingModule: any;
  featureId: any;
  viewData: IKubaFeatureDetails[];
  parentKey: string;
  label: any;
  value: any;
  private subscriptions: Subscription[] = [];
  get staticArray() {
    return Array;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureDetailServices: FeatureDetailService
  ) {
    this.featureKey = route.snapshot.parent.params['id'];
    this.parentKey = route.snapshot.parent.data['parent'];
  }

  ngOnInit() {
    this.workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    this.featureId = this.workingModule.id;

    let serviceUrl;
    switch (this.parentKey) {
      case 'PROJECT':
        serviceUrl = '/project/' + this.featureKey;
        break;
      case 'FDV':
        serviceUrl = '/firedeviation/firedeviation/' + this.featureKey;
        break;
      case 'FS':
        serviceUrl = '/foodsafety/food-safety/' + this.featureKey;
        break;
      case 'IC':
        serviceUrl = '/internalcontrol/' + this.featureKey;
        break;
      case 'OS':
        serviceUrl = '/othersystems/' + this.featureKey;
        break;
      case 'QS':
        serviceUrl = '/qualitysystem/' + this.featureKey;
        break;
      case 'VEHICLE':
        serviceUrl = '/vehicle/vehicle/' + this.featureKey;
        break;
      case 'SA':
        serviceUrl = '/serviceAgreement/get/' + this.featureKey;
        break;
      case 'FRAMEWORK':
        serviceUrl = '/framework/' + this.featureKey;
        break;
      case 'KUNDE_EL':
        serviceUrl = '/kundeEL/' + this.featureKey;
        break;
      case 'KUBA_CONTROL':
        serviceUrl = '/kubacontrol/kuba-control/' + this.featureKey;
        break;
      default:
        break;
    }

    let KeyBindings = {
      StatusText: 'Status',
      FloorCount: 'Property no.',
      ApartmentCount: 'Ground no.',
      IsSMS: 'SMS when new deviation',
      IsEmail: 'E-mail when new deviation',
    };

    this.subscriptions.push(
      this.featureDetailServices.getDetails(serviceUrl).subscribe(
        (response) => {
          if (this.parentKey === 'PROJECT') {
            let result = new ProjectDetail();
            let res = HelperService.switchKeysCaseForDetail(
              response,
              KeyBindings
            );
            result = this.limitedAssign(res, result);
            this.viewData = [];

            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                let val = this.parseDate(result[key]);
                if (val === true) {
                  result[key] = HelperService.formatDate(result[key]);
                }
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (this.parentKey === 'FS') {
            let result = new FoodSafetyDetail();
            result = this.limitedAssign(response, result);
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (this.parentKey === 'VEHICLE') {
            let result = new VehicleDetail();
            result = this.limitedAssign(response, result);
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (
            this.parentKey === 'IC' ||
            this.parentKey === 'OS' ||
            this.parentKey === 'QS'
          ) {
            let result = new InternalControlDetail();
            result = this.limitedAssign(response, result);
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (this.parentKey === 'SA') {
            let result = new ServiceAgreementDetail();
            result = this.limitedAssign(response, result);
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (this.parentKey === 'FRAMEWORK') {
            let result = new FrameworkDetail();
            result = this.limitedAssign(response, result);
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (this.parentKey === 'FDV') {
            let result = new FdvDetail();
            let res = HelperService.switchKeysCaseForDetail(
              response,
              KeyBindings
            );
            result = this.limitedAssign(response, result);
            result.PropertyObjects = result.BusinessProperty.PropertyObjects;
            delete result.BusinessProperty.PropertyObjects;
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          } else if (this.parentKey === 'KUNDE_EL') {
            let result = new ElDetail();
            result = this.limitedAssign(response, result);
            this.viewData = [];
            for (let key in result) {
              if (result.hasOwnProperty(key)) {
                this.viewData.push({
                  label: key
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .toUpperCase(),
                  value: result[key],
                });
              }
            }
          }
        },
        (error) => console.log('Error while getting feature details : ', error),
        () => console.log('Got feature details')
      )
    );
  }

  limitedAssign(source: any, destination: any): any {
    for (let prop in destination) {
      if (source[prop] && destination.hasOwnProperty(prop)) {
        if (typeof source[prop] === 'object' && Array.isArray(source[prop])) {
          source[prop].forEach((element) => {
            destination[prop].push(element);
          });
        } else if (typeof source[prop] === 'object') {
          destination[prop] = this.limitedAssign(
            source[prop],
            destination[prop]
          );
        } else {
          destination[prop] = source[prop];
        }
      }
    }
    return destination;
  }

  typeofproperty(value: any) {
    return typeof value;
  }

  matchPath(value: any) {
    if (value) {
      let pattern = '^(http|https)://';
      return value.match(pattern);
    }
  }

  checkBool(value: any) {
    if (typeof value === 'boolean') {
      return true;
    }
    return false;
  }

  parseDate(dateStr) {
    if (isNaN(dateStr)) {
      // Checked for numeric
      let dt = new Date(dateStr);
      if (isNaN(dt.getTime())) {
        // Checked for date
        return false; // Return string if not date.
      } else {
        return true; // Return date **Can do further operations here.
      }
    } else {
      return dateStr; // Return string as it is number
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
