
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights, FeatureKey } from './../../../_models/feature';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import * as _ from 'lodash';


@Component({
    selector: 'project-view',
    templateUrl: 'project-view.component.html'
})

export class ProjectViewComponent implements OnInit {
    pageTitle: string;
    enabledAdditionalFeatures: any;
    projectId: number;
    projectObj: any;
    initialPage: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.projectId = this.route.snapshot.params['id'];
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        this.projectObj = this.route.snapshot.data['project'];

        this.pageTitle = this.projectObj.Title;
        sessionStorage.setItem('ProjectName',this.pageTitle);
        if (+BaseServices.roleId === 4 && +BaseServices.FeatureId === Module.CONTRACTOR) {
            this.onReadSJARights();
        } else {
            this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
            this.enabledAdditionalFeatures = this.enabledAdditionalFeatures.filter(x => x.id !== 164);
            let index = _.findIndex(this.enabledAdditionalFeatures, { translationKey: 'DETAILS' });
            if (index >= 0) {
                this.enabledAdditionalFeatures[index].translationKey = 'PROJECT_DETAILS'; //_.replace(this.enabledAdditionalFeatures[index].translationKey,'test');
            }

            if (this.enabledAdditionalFeatures.length > 0) {
                if (this.projectObj.IsELThreedigit === false) {
                    this.enabledAdditionalFeatures
                        = this.enabledAdditionalFeatures.filter(element => element.translationKey !== 'FDV_DOKUMENTER_EL');
                }
                if (this.projectObj.ISVVSThreedigit === false) {
                    this.enabledAdditionalFeatures
                        = this.enabledAdditionalFeatures.filter(element => element.translationKey !== 'FDV_DOKUMENTER_VVS');
                }
                this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
                    .filter(element =>
                        (element.id !== FeatureKey.SJA_CHECKLIST));
                this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
                    .filter(element =>
                        (element.id !== FeatureKey.TIME_LOG));
                this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.projectId }],
                    { relativeTo: this.route });
            }
        }

    }

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }
    /**
     * show project SJA based on sja rights for subcontractor guest
     */
    onReadSJARights() {
        let isShowSJARights = BaseServices.checkUserRights(Rights.READING_SJA_RIGHTS, BaseServices.UserRole);
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
      
        if (isShowSJARights === false) {
            let filterFeatures = [FeatureKey.SAFETY_JOB_ANALYSIS];
            _.remove(this.enabledAdditionalFeatures, (n: any) => {
                return filterFeatures.indexOf(n.id) > -1;
            });
        }

        if (this.enabledAdditionalFeatures.length > 0) {
            this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.projectId }],
                { relativeTo: this.route });
        }
    }
}
