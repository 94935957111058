export class Vehicle {
    Id: number;
    RegistrationNo: string;
    RegistrationYear: string;
    VehicleTypeId: number;
    VehicleBrandId: number;
    VehicleModelId: number;
    BusinessDepartmentId: number;
    VehicleGeoDeviceId: number;
    EmployeeId: number;
    Status: string;
    BusinessId: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    VehicleSize: number;
    Name: string;
    VehicleTracking: string;
    AutowizVechicleId: string;
}
export class VehicleList {
    Id: number;
    VehicleTypeId: number;
    Name: string;
    AutowizVechicleId: string;
    RegistrationNo: string;
    VehicleGeoDeviceId: string;
    VehicleBrand: string;
    VehicleModel: string;
    BusinessDepartmentId: number;
    DriverName: string;
    BusinessId: number;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    RegistrationYear: string;
    VehicleSizeId: number;
    ShowDeviation?: boolean;
    VehicleUsersDto: VehicleUser[];
    VehicleEmailSubscribers: number[];
}
export class VehicleUser {
    VehicleId: number;
    UserId: number;
    Name: string;
}


export class VehicleBrand {
    Id: number;
    Name: string;
    VehicleTypeId: number;
    CreatedOn: Date;
    CreatedBy: number;
    ModifiedOn: Date;
    ModifiedBy: number;
}

export class VehicleTypeOfvehicle {
    Id: number;
    Name: string;
    BusinessId: number;
}

export class Department {
    Id: number;
    Name: string;
    BusinessId: number;
    Status: string;
    ApplicationId: number;
}

export class VehicleDetail {
    DepartmentName: string;
    RegistrationNo: number;
    RegistrationYear: number;
    BrandName: string;
    ModelName: string;
    VehicleTypeName: string;
    constructor() {
        this.DepartmentName = '';
        this.RegistrationNo = 0;
        this.RegistrationYear = 0;
        this.BrandName = '';
        this.ModelName = '';
        this.VehicleTypeName = '';
    }
}
