import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leave',
  templateUrl: 'leave.component.html',
})
export class LeaveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
  }
}
