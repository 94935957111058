import { Employee } from './../../employees/models/employee';
export class Activity {
  Id?: number;
  BusinessDepartmentId?: number;
  Title?: string;
  Description?: string;
  Status?: any;
  ExecutionUserId?: number;
  FollowupUserId?: number;
  ScheduleId?: number;
  ChecklistId?: number;
  IsRepeatChklistRecurr?: string;
  LinkUrl?: string;
  CompletedDate?: string;
  BusinessId?: number;
  BusinessName?: string;
  CreatedBy?: string;
  CreatedOn?: string;
  CreatedOnForPdf?: string;
  ModifiedBy?: string;
  ModifiedOn?: string;
  FollowUpUser?: Employee[];
}
export class Execution {
  Id?: number;
  Name?: string;
  ActivityId?: number;
  FollowUpId?: number;
}
export class Repetition {
  Id?: number;
  Value?: string;
}
export class ActivityUpdate {
  Id?: number;
  Title?: string;
  Description?: string;
  Status?: string;
  Goal?: string;
  EstimatedCost?: number;
  ExecutionUserId?: number;
  FollowupUserId?: number;
  RepetitionId?: number;
  ActivityScheduleId?: number;
  ChecklistId?: number;
  IsRepeatChklistRecurr?: boolean;
  LinkUrl?: string;
  CompletedDate?: Date;
  BusinessId?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
  FeatureKey?: number;
  ActivityAttachment?: ActivityAttachment[];
  ActivityScheduleDto?: ActivitySchedule;
  IsEmailorSms?: boolean;
  FollowUpUser?: Employee[];
}
export class ActivityAttachment {
  Id?: number;
  ActivityId?: number;
  Path?: string;
  CreatedBy?: number;
  CreatedOn?: Date;
  FileTypeId?: string;
  Status?: string;
  OriginalFileName?: string;
  MimeType?: string;
  Size?: string;
  Filename?: string;
}
export class ActivitySchedule {
  Id?: number;
  StartDate?: string;
  Status?: string;
  EndDate?: string;
  ScheduleRule?: string;
  NotifyBeforeDeadLine?: number;
  NotifyBeforeFollowup?: number;
  CreatedBy?: number;
  CreatedOn?: Date;
  ModifiedBy?: number;
  ModifiedOn?: Date;
  ParentId?: number;
  ActivityId?: number;
}
export class ActivityHeader {
  Id?: number;
  Title?: string;
  Description?: string;
  Status?: string;
  BusinessId?: number;
  CreatedBy?: number;
  IsFirstEdit?: boolean;
}
export class FollowUpActivity extends ActivityHeader {
  BusinessDepartmentId?: number;
  BusinessDepartmentList?: any = [];
  CompletedDate?: Date;
  RepetitionId?: number;
  ExecutionUsers?: Execution[];
  FollowupUsers?: Execution[];
  ChecklistId?: number;
  IsRepeatChklistRecurr?: boolean;
  LinkUrl?: string;
  ActivityAttachmentDto?: ActivityAttachmentDto[];
  ActivityScheduleDto?: ActivitySchedule;
  CreatorName?: string;
  ModifiedBy?: number;
  CreatedOn?: Date;
  ModifiedOn?: Date;
  BusinessIds?: number[];
  ApplicationId?: number;
  ActivityNumber?: number;
  Activity_Number_Prefix?: string;
  IsFutureRecurring?: boolean;
  LanguageId?: number;
  public get someFieldButPublic() {
    return this.IsFirstEdit;
  }
  public set someFieldButPublic(value) {
    this.IsFirstEdit = value;
  }

  // IsFirstEdit ?: boolean;
  ModuleFeatureId?: number;
  IsCreatedFrom?: number;
}
export class ActivityAttachmentDto {
  Id?: number;
  ActivityId?: number;
  Path?: string;
  CreatedBy?: number;
  CreatedOn?: Date;
  FileTypeId?: string;
  Status?: string;
  OriginalFileName?: string;
  MimeType?: string;
  Size?: string;
  Filename?: string;
}

export class Auditlogdata {
  Id?: number;
  AuditLogKeyId?: number;
  TableName?: string;
  ColumnName?: string;
  FieldName?: string;
  OldValue?: string;
  NewValue?: string;
  ModifiedBy?: number;
  Modifier?: string;
  Time?: string;
  CreatedOn?: Date;
}

export class ActivityPortalDto {
  Id?: number;
  Title?: string;
  Description?: string;
  Status?: string;
  RepetitionId?: number;
  ActivityScheduleId?: number;
  ChecklistId?: number;
  IsRepeatChklistRecurr?: boolean;
  LinkUrl?: string;
  CompletedDate?: string;
  CreatedBy?: number;
  BusinessId?: number;
  CreatedOn?: Date;
  BusinessIds?: number[];
  ActivityAttachment?: ActivityAttachment[];
  ActivityScheduleDto?: ActivitySchedule;
  ApplicationId?: number;
}
export class Recurrence {
  Id?: number;
  ActivityId?: string;
  RecurrenceId?: string;
}
