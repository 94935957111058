export class FrameworkAgreement {
    Id: number;
    ContractName: string;
    ContractNumber: number;
    Status: string;
    BusinessPropertyId: number;
    ClientId: number;
    ContractImage: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: string;
    BusinessId: number;
    Comments: string;
    BusinessPropertyDto: BusinessProperty;
    FrameworkUsers: FrameworkUsers[];
    FrameworkClientUsers: FrameworkClientUsers[];
    FrameworkAgreementDocument: FrameworkAgreementDocumentDto[];
    ShowDeviation?: boolean;
}

export class FrameworkClientUsers {
    FrameworkId: number;
    ClientId: number;
    ClientUserId: number;
    Name: string;
    Id: number;
}

export class BusinessProperty {
    Id: number;
    BusinessId: number;
    BusinessPropertyTypeId: number;
    Name: string;
    Number: string;
    FloorCount: number;
    ApartmentCount: number;
    Address: string;
    Status: string;
    City: string;
    Zip: string;
    Location: string;
    Latitude: string;
    Longitude: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: string;
}

export class FrameworkUsers {
    FrameworkId: number;
    UserId: number;
    Name: string;
    IsEmail : boolean;
}

export class FrameworkAgreementDocumentDto {
    Id: number;
    ServiceAgreementId: number;
    CourseId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: number;
    IsPrivateDocument: boolean;
    Status: string;
}
export class FrameworkClientDetail {
    Name: string;
    constructor() {
        this.Name = '';
    }
}
export class FrameworkDetail {
    ContractNumber: string;
    ContractName: string;
    StatusText: string;
    ContractImage: string;
    Comments: string;
    FrameworkClientUsers: FrameworkClientDetail[];
    constructor() {
        this.ContractNumber = '';
        this.ContractName = '';
        this.StatusText = '';
        this.ContractImage = '';
        this.Comments = '';
        this.FrameworkClientUsers = [];
    }
}
