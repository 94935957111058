import { Rights } from './../../../_models/feature';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

@Component({
    selector: 'el-list-header',
    templateUrl: 'el-list-header.component.html'
})

export class ELListHeaderComponent implements OnInit {
    isDisplayOwnContract = true;
    isHideDisplayOwnContract = true;
    constructor
        (
        private router: Router,
        private route: ActivatedRoute
        ) {
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.UserRole === 'Guest') {
            this.isDisplayOwnContract = false;
            this.router.navigate(['own'], { relativeTo: this.route });
        } else if (BaseServices.UserRole === 'User') {
            this.onKundeELRights();
        } else {
           this.OnAdmin()
        }
    }
    onKundeELRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.IK_KUNDE_EL_RIGHTS;
        if (currentUserRole === 'User') {
            this.isDisplayOwnContract = BaseServices.checkUserRights(userRightsId, currentUserRole);
            this.router.navigate(['own'], { relativeTo: this.route });
        } else {
            this.isDisplayOwnContract = true;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
    }

    OnAdmin(){
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
        if(curSession > 0)
        {
            this.isDisplayOwnContract=true;
            this.isHideDisplayOwnContract = false;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
        else{
            this.isDisplayOwnContract = true;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
    }
}
