import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'followups-meeting',
    templateUrl:'meeting.component.html'
})
export class FollowUpMeetingComponent implements OnInit {

    constructor() { }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
   
}

