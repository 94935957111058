import { ToasterComponent } from './../../../_directives/toaster.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, SelectItem
 } from 'primeng/api';
import { Table } from 'primeng/table';
import { BaseServices } from './../../kuba.services';
import { ContactServices } from '../../contact/services/contact.service';
import { EditorContactsServices } from './../services/editorcontacts.services';
import { UserRole } from '../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'contact-list',
    templateUrl: 'editorcontact-list.component.html'
})
export class EditorContactListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent, {static:false}) toasterComponent: ToasterComponent;
    @ViewChild('contactsTable', {static:false}) contactsTable: Table;
    contacts: any;
    selectedContacts: any = [];
    contactform: FormGroup;
    status: SelectItem[];
    typeOfContacts: any;
    isSelectedValue = false;
    additionalData: any;
    newButtonEnable = true;
    contactTypeFilter: any = null;
    statuses: SelectItem[];
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * costructor
     * @param editorContactsServices {EditorContactsServices}
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private editorContactsServices: EditorContactsServices,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private contactservice: ContactServices,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => { 
        this.additionalData = {
            fileName: val.CONTACT_LIST,
            header: 'Contact List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Telephone', dataKey: 'Telephone' },
                { title: 'Email id ', dataKey: 'Emailid' },
                { title: 'Function ', dataKey: 'ContactFunctionalAreaName' },
                { title: 'Status ', dataKey: 'StatusText' },
                { title: 'Company Name ', dataKey: 'BusinessName' }
            ]
        };
    }));
    }
    //#endregion

    //#region page-events

    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (+BaseServices.roleId === UserRole.GUEST || +BaseServices.roleId === UserRole.USER) {
            this.newButtonEnable = false;
        }
        this.typeOfContacts = [];
        let typeOfContacts = this.route.snapshot.data['contactType'];
        this.typeOfContacts.push({ label: 'All', value: null });
        if (this.typeOfContacts) {
            typeOfContacts.forEach((typeOfContact: any) => {
                this.typeOfContacts.push({
                    label: typeOfContact.Name,
                    value: typeOfContact.Id
                });
            });
        }

        let list = this.route.snapshot.data['list'];
        if (list) {
            this.contacts = list;
            this.showBusiness(this.contacts);
        }
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.status = [];
            this.status.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: '2' },
                { label: val.ACTIVE, value: '1' }
            );
            
        }));
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 2 },
                { label: val.ACTIVE, value: 1 }
            );
        }));
    }
    //#endregion

    //#region control-events
    /**
     * type of contact dropdown change event for filter
     * @param e {Event}
     * @param contactDropdown {Dropdown}
     */
    onTypeOfContactChanged(e: any, typeOfContactsDropdown: any) {
        sessionStorage.setItem('search_Contact_TypeOfContact', typeOfContactsDropdown.value);
        this.contactsTable.filter(
            typeOfContactsDropdown.selectedOption.value,
            'ContactTypeId',
            'equals'
        );
        if (e.value == null) {
            this.contactsTable.reset();
        }
    }
    /**
     * filter list by status
     * @param e {Event}
     * @param statusDropdown {Dropdown}
     */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.contactsTable.filter(
                statusDropdown.selectedOption.label,
                'StatusText',
                'equals'
            );
        } else {
            this.contactsTable.reset();
        }
    }
    //#endregion

    //#region methods
    /**
     * edit status
     * @param id {number}
     * @param Status {number}
     */
    editStatus(id: number, Status: string) {
        let statusNumber: number;
        if (Status === 'ACTIVE') {
            statusNumber = 1;
        } else {
            statusNumber = 2;
        }
        this.confirmationService.confirm({
            message: this.translate.instant('ARE_SURE_TO_PERFORM_ACTION'),
            accept: () => {
                this.subscriptions.push(this.editorContactsServices
                    .statusUpdate(id, statusNumber)
                    .subscribe(res => {
                        if (res) {
                            this.getContacts();
                        }
                    }));
            }
        });
    }

    /**
     * get contact list
     */
    getContacts() {
        this.subscriptions.push(this.editorContactsServices
            .getContactByBusiness(BaseServices.BusinessId)
            .subscribe(contacts => {
                if (contacts) {
                    this.contacts = contacts;
                }
            }));
    }
    onRowSelect(selectedValue:any) {
        if (this.selectedContacts) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }
    onRowUnselect(selectedValue:any) {
        if (this.selectedContacts.length > 0) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }
    /**
     * delete contacts
     */
    deleteContacts() {
        let contactIds: any = [];
        if (this.selectedContacts.length > 0) {
            this.selectedContacts.forEach((contact: any) => {
                contactIds.push({ id: contact.Id });
            });
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.editorContactsServices
                        .deleteAll(contactIds)
                        .subscribe(deleteResponse => {
                            if (deleteResponse) {
                                this.getContacts();
                                this.toasterComponent.callToastDlt();
                            }
                        }));
                }
            });
        }
        this.selectedContacts = [];
    }
    showBusiness(data: any) {
        if (data) {
            data.forEach((element: any) => {
                if (element.ShowInBusiness === true && element.RoleId === 2) {
                    let Name = element.Name + ' ' + '(' + 'Portal' + ')';
                    element.Name = Name;
                }
            });
        }
        this.globalContactFilter();
    }

    globalContactFilter() {
        let contactType = sessionStorage.getItem('search_Contact_TypeOfContact');

        if (contactType && contactType !== 'null') {
            this.contactTypeFilter = contactType;
            this.contactsTable.filter(contactType,
                'ContactTypeId',
                'equals'
            )
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
