<div class="tab-content">
    <form [formGroup]="suggestionForm">
        <div class="form-group">
            <label for="title">
                <span translate>STATUS</span>
            </label>
            <div>
                <input type="text" formControlName="Status" name="Status" pInputText readonly="true" />
            </div>
        </div>
        <div class="form-group" *ngIf="suggestionForm.controls['Title']">
            <label for="title">
                <span translate>TITLE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="Title" name="Title" pInputText required />
            </div>
            <control-messages [control]="suggestionForm.controls['Title']"></control-messages>
        </div>
        <div class="form-group">
            <label for="description">
                <span translate>DESCRIPTION</span>
            </label>
            <div>
                <textarea formControlName="Description" name="Description" pInputTextarea></textarea>
            </div>
        </div>
        <div class="form-group">
            <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                (saveChanges)="saveUploaded($event)">
            </ecpl-document-explorer>
        </div>
    </form>
    <div class="action-btns-wrapper">
        <a routerLink="list" [routerLink]="['./../../']" class="btn btn-outline-secondary" routerLinkActive="active">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </a>
        <button class="btn btn-primary" type="submit" [disabled]="!suggestionForm.valid"
            (click)="onSubmitTemplateBased()">
            <span translate>SAVE</span>
        </button>
    </div>
    <toaster-component></toaster-component>
</div>