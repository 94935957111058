<div class="main-content">
    <div class="main-heading">
    </div>
    <div class="upload-file-container">
        <div class="form-group">
            <div class="upload-file-container">
                <div class="upload-actions">
                    <input type="file" accept="video/mp4,video/avi,video/*" id="files" class="hidden" (change)="filesSelect($event)" />
                    <label for="files" class="btn btn-outline-primary" translate>UPLOAD_VIDEO</label>
                </div>
                <div *ngIf="uploadStart">
                    <span translate>VIDEO_UPLOADING</span>
                </div>
            </div>
            <div *ngIf="businessVideo">
                <div>
                    <video width="320" height="240" controls>
                        <source src="{{businessVideo}}" type="video/mp4">
                    </video>
                </div>
                <div>
                    <a *ngIf="!isDefaultVideo" (click)="deleteVideo(videoId)" class="btn btn-icon"
                        title="{{'DELETE' | translate}}" routerLinkActive="active">
                        <i class="icon ic-sm icon-trash"></i>
                        <span translate>DELETE_VIDEO</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button class="btn btn-primary" [ngClass]="{'loading' : loading}" [disabled]="isDefaultVideo || loading"
            (click)="saveChanges()">
            <span *ngIf="!loading" translate>SAVE</span>
            <span *ngIf="loading" translate>SAVE_PROCESS</span>
            <span *ngIf="loading" class="loader-icon"></span>
        </button>
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>