<div class="px-2 charts-container">
  <!-- Loading Image -->
  <div *ngIf="loading" class="loading-wrapper">
    <img src="../../../../assets/icons/blocks.gif" alt="Loading..." />
  </div>

  <!-- Chart -->
  <div *ngIf="!loading" class="charts-wrapper">
    <ngx-charts-bar-horizontal
      [view]="view"
      [scheme]="colorScheme"
      [results]="paginatedData"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="false"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [xAxisTickFormatting]="formatXaxisTicks"
      (select)="onSelect($event)"
      roundEdges="true"
    ></ngx-charts-bar-horizontal>
  </div>

  <!-- Pagination Controls -->
  <div *ngIf="!loading" class="pagination-controls">
    <a
      class="wa-btn"
      (click)="currentPage > 1 && onPageChange(currentPage - 1)"
      [class.disabled]="currentPage === 1"
    >
      <i class="icon ic-md icon-back-to"></i>
    </a>

    <a
      class="wa-btn"
      (click)="currentPage < totalPages && onPageChange(currentPage + 1)"
      [class.disabled]="currentPage === totalPages"
    >
      <i class="icon ic-md icon-next-page"></i>
    </a>
  </div>
</div>
