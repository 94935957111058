import { UserRole } from './../../follow-ups/models/deviation';
import { UploadInput } from 'ngx-uploader';
import { EmployeeServices } from './../services/employee.services';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Location } from '@angular/common';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { EmployeeCourse, BusinessCourse } from './../models/employeeCourse';
import { BaseServices } from './../../kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { EmployeeCourseServices } from './../services/employeecourse.service';
import { DateSetting } from './../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { BusinessPortalService } from 'src/app/kuba/portals/services/business-portal.services';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'course-detail',
  templateUrl: 'employee-course-detail.component.html',
})
export class CourseDetailComponent extends DateSetting implements OnInit {
  //#region variables
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('courseTable', { static: false }) courseTable: Table;
  public employeeCourseArray: EmployeeCourse[];
  addDept: boolean;
  employeeId: number;
  courseId: any;
  courseTypeId: number;
  courseDetailForm: FormGroup;
  dialogDisplay = false;
  responsibleExcecution: SelectItem[];
  resposibleNames: SelectItem[];
  courseNames: SelectItem[];
  courseType: any = [];
  EmployeeCourse: any = new EmployeeCourse();
  businessId: number;
  documentUploadUrl: any;
  document: any = {};
  isNameExists: boolean;
  existsAlerttext: string;
  // FIXME: Why this has children
  hasChildren = false;
  parentKey: string;
  upConfig: UploaderConfig;
  uploadedFiles: any = [];
  additionalData: any;
  newDocumentAdded: any = [];
  uploadInput: EventEmitter<UploadInput>;
  coursePopup: FormGroup;
  courseList: any[];
  viewCourse = false;
  isPortalBusiness: boolean;
  addCourse: boolean;
  portalBusinessId: number;
  isNotUser: boolean;
  IsCommonPortalCourseNames: boolean;
  selDate: string;
  private subscriptions: Subscription[] = [];
  viewDocument = false;
  config: UploaderConfig;

  /**
   * Date picker configuration option
   */
  public startDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  /**
   * Date picker configuration option for renewal date
   */
  public renewalDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
  };

  /**
   * Date picker configuration option
   */
  public endDateOptions: IMyDpOptions = this.startDateOptions;
  hrefcourse: any;
  hrefcontract: any;
  isEmployeecourses: Boolean = true;
  firsttry: any;
  hrefcourseEdit: string;
  hrefcontractEdit: string;
  employeeCourseId: any;

  // #endregion

  //#region constructor

  /**
   * Constructor
   * @param employeeCourseServices {{EmployeeCourseServices}}
   * @param route {{ActivatedRoute}}
   * @param confirmationService {{ConfirmationService}}
   * @param _fb {{FormBuilder}}
   * @param location {{Location}}
   */
  constructor(
    private employeeCourseServices: EmployeeCourseServices,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private _fb: FormBuilder,
    protected location: Location,
    private employeeServices: EmployeeServices,
    private translate: TranslateService,
    private businessServices: BusinessServices,
    private businessPortalService: BusinessPortalService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.coursePopup = this._fb.group({
      Course: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
  }

  // #endregion

  //#region page-events

  /**
   * Binding dropdowns,binding the employee course datatable
   */
  ngOnInit() {
    $('body').removeClass('opened');
    this.uploadInput = new EventEmitter<UploadInput>();
    this.isNotUser = +BaseServices.roleId === UserRole.USER ? false : true;
    this.initForm();

    this.hrefcourse = this.router.url.substring(
      this.router.url.indexOf('editCourse'),
      this.router.url.indexOf('editCourse') + 10
    );
    this.hrefcontract = this.router.url.substring(
      this.router.url.indexOf('editContract'),
      this.router.url.indexOf('editContract') + 12
    );
    this.firsttry = this.router.url.substring(
      this.router.url.indexOf('editContract'),
      this.router.url.indexOf('editContract') + 12
    );

    this.hrefcourseEdit = this.router.url.substring(
      this.router.url.indexOf('edit'),
      this.router.url.indexOf('edit') + 4
    );
    this.hrefcontractEdit = this.router.url.substring(
      this.router.url.indexOf('contractEdit'),
      this.router.url.indexOf('contractEdit') + 12
    );
    this.courseId = parseInt(
      this.router.url.substring(this.router.url.lastIndexOf('/') + 1)
    );
    if (this.hrefcontractEdit == 'contractEdit') {
      this.isEmployeecourses = false;
    } else if (this.hrefcourseEdit == 'edit') {
      this.isEmployeecourses = true;
    }

    if (this.hrefcourse == 'editCourse') {
      this.isEmployeecourses = true;
    } else if (this.hrefcontract == 'editContract') {
      this.isEmployeecourses = false;
    }

    if (BaseServices.roleId === '2') {
      this.isPortalBusiness = true;
      this.bindCourse();
    } else {
      // this.isPortalBusiness = this.route.snapshot.data['businesscreator'];
      this.isPortalBusiness = false;
      let portalId = BaseServices.PortalId;
      this.subscriptions.push(
        this.businessPortalService
          .getById(
            portalId)
          .subscribe((result) => {
            setTimeout(() => {
              if (result) {
                this.isPortalBusiness = this.IsCommonPortalCourseNames =
                  result == null ? true : result.IsCommonPortalCourseNames;
                this.bindCourse();
              }

            }, 1000);
          })
      );
    }
    let eid = this.route.snapshot.params['eid'];
    this.employeeId = +eid;

    this.parentKey = this.route.snapshot.data['parent'];
    if (this.parentKey === 'EMPLOYEE') {
      this.clear();
    }

    let business = this.route.snapshot.data['businesseditcourse'];
    this.businessId = business.BusinessId;

    if (business && this.parentKey !== 'EMPLOYEE') {
      this.initForm(business);
    }
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      EmployeeId: this.employeeId ? +this.employeeId : 0,
      IsCommonDocument: '0',
    };

    if (this.employeeId) {
      // binding course datatable
      this.subscriptions.push(
        this.employeeCourseServices
          .getEmployeeContractByEmployeeid(
            this.employeeId,
            this.isEmployeecourses
          )
          .subscribe((courseDetails: any) => {
            this.employeeCourseArray = courseDetails;
          })
      );
    }

    if (this.isEmployeecourses == true) {
      this.config = {
        title: 'COURSE_DOCUMENT',
        titleAsLabel: true,
        windowedHeight: false,
        viewSwitch: false,
        editMode: true,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: true,
        showDeleteButton: true,
        uploaderUri:
          environment.BASE_URL + '/file/upload/business/employee/private-docs',
        addtionalData: this.additionalData,
      };
      this.upConfig = {
        title: null!,
        titleAsLabel: false,
        windowedHeight: false,
        viewSwitch: false,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: false,
        showDeleteButton: false,
        uploaderUri: null!,
        addtionalData: this.additionalData,
      };
    } else if (this.isEmployeecourses == false) {
      this.config = {
        title: 'CONTRACT_DOCUMENT',
        titleAsLabel: true,
        windowedHeight: false,
        viewSwitch: false,
        editMode: true,
        showUserMeta: false,
        showSearchFilter: false,
        showUploadButton: true,
        showDeleteButton: true,
        uploaderUri:
          environment.BASE_URL + '/file/upload/business/employee/private-docs',
        addtionalData: this.additionalData,
      };
    }

    if (this.isEmployeecourses == true) {
      this.subscriptions.push(
        this.translate.stream('FILENAME').subscribe((val) => {
          this.additionalData = {
            fileName: val.EMPLOYEE_COURSE_DETAILS,
            header: 'Employee Course Details',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Course Name Title', dataKey: 'Course' },
              { title: 'Institute', dataKey: 'Institute' },
              { title: 'Start Date', dataKey: 'StartDate' },
              { title: 'End Date', dataKey: 'EndDate' },
              { title: 'Renewal date', dataKey: 'Renewal_Date' },
              {
                title: 'Responsible for Execution',
                dataKey: 'ResponsiblepersonName',
              },
              { title: 'Description', dataKey: 'Description' },
            ],
          };
        })
      );
    } else if (this.isEmployeecourses == false) {
      this.subscriptions.push(
        this.translate.stream('FILENAME').subscribe((val) => {
          this.additionalData = {
            fileName: val.EMPLOYEE_CONTRACT_DETAILS,
            header: 'Employee Contract Details',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
              { title: 'Contract', dataKey: 'Course' },
              { title: 'Contract Institute', dataKey: 'Institute' },
              { title: 'Contract StartDate', dataKey: 'StartDate' },
              { title: 'Contract EndDate', dataKey: 'EndDate' },
              { title: 'Renewal date', dataKey: 'Renewal_Date' },
              {
                title: 'Contract Responsible for Execution',
                dataKey: 'ResponsiblepersonName',
              },
              { title: 'Description', dataKey: 'Description' },
            ],
          };
        })
      );
    }
    this.bindDetails();

    if (this.employeeId) {
      // Binding course datatable
      this.subscriptions.push(
        this.employeeCourseServices
          .getEmployeeContractByEmployeeid(
            this.employeeId,
            this.isEmployeecourses
          )
          .subscribe((courseDetails: any) => {
            this.employeeCourseArray = courseDetails;
          })
      );
    }
  }

  /**
   * Form initialization
   * @param data  {{any}}
   */
  initForm(data?: any) {
    let BusinessCourseId = '';
    let Institute = '';
    let StartDate = null;
    let EndDate: any;
    let Renewaldate: any;
    let ResponsibleId = '';
    let Description = '';
    let DeadlineNotification = '';
    let courseDocument: any = [];

    if (data) {
      this.employeeId = data.EmployeeId;
      this.uploadedFiles = [];

      this.uploadedFiles = data.EmployeeDocument;
      BusinessCourseId = data.BusinessCourseId ? data.BusinessCourseId : '';
      Institute = data.Institute ? data.Institute : '';
      // Start Date Input and Date Range set
      StartDate = HelperService.formatInputDate(
        data.StartDate ? data.StartDate : null
      );
      let startdate: Date = HelperService.formatDate(data.StartDate)
        ? new Date(HelperService.formatDate(data.StartDate))
        : null!;
      this.endDateOptions = HelperService.setDateRange(
        this.endDateOptions,
        startdate,
        'startDate'
      );
      // End Date Input and Date Range set
      EndDate = HelperService.formatInputDate(
        data.EndDate ? data.EndDate : null
      );
      let enddate: Date = HelperService.formatDate(data.EndDate)
        ? new Date(HelperService.formatDate(data.EndDate))
        : null!;
      Renewaldate = HelperService.formatInputDate(data.Renewaldate)
        ? HelperService.formatInputDate(data.Renewaldate)
        : '';
      let renewaldate: Date = HelperService.formatDate(data.Renewaldate)
        ? new Date(HelperService.formatDate(data.Renewaldate))
        : null!;
      this.renewalDateOptions = HelperService.setDateRange(
        this.renewalDateOptions,
        renewaldate,
        'startDate'
      );
      ResponsibleId = data.ResponsibleId ? data.ResponsibleId : '';
      (Description = data.Description ? data.Description : ''),
        (DeadlineNotification = data.DeadlineNotification
          ? data.DeadlineNotification
          : '');
    } else {
    }
    this.courseDetailForm = this._fb.group({
      BusinessCourseId: new FormControl(BusinessCourseId, Validators.required),
      Institute: new FormControl(Institute),
      StartDate: new FormControl(StartDate),
      EndDate: new FormControl(EndDate),
      Renewaldate: new FormControl(Renewaldate),
      ResponsibleId: new FormControl(ResponsibleId),
      Description: new FormControl(Description),
      DeadlineNotification: new FormControl(DeadlineNotification),
    });
  }
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      let keyBindings = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      let deviationDoc = Object.assign({}, event, this.additionalData);
      switch (eventType) {
        case 'DeleteDefaultFile':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.path !== event.item.data.path
          );
          if (event.item.data.id === 0) {
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.employeeServices
                .deleteEmployeeDocument(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        default:
          deviationDoc.id = 0;
          deviationDoc.createdBy = BaseServices.UserId;
          deviationDoc.status = 1;
          deviationDoc.employeeId = this.employeeId ? this.employeeId : 0;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(deviationDoc, keyBindings)
          );
          break;
      }
    }
  }

  // #endregion

  //#region Control-events

  /**
   * Start Date Changed
   */
  onStartDateChanged(event: IMyDateModel) {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.endDateOptions = this.setDateInput(
      this.endDateOptions,
      event,
      'startDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.endDateOptions
      );
      copyConfig.disableSince = this.getCurrentDate();
      this.startDateOptions = copyConfig;
    }
  }

  /**
   * Event handler for End date
   * @param event {{IMyDateModel}}
   */
  onEndDateChanged(event: IMyDateModel) {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    this.startDateOptions = this.setDateInput(
      this.startDateOptions,
      event,
      'endDate'
    );
    if (!date) {
      let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
        this.startDateOptions
      );
      copyConfig.disableUntil = this.getCurrentDate();
      this.startDateOptions = copyConfig;
    }
  }

  //#region methods

  /**
   * get current date
   */
  getCurrentDate() {
    let dateFormat = new Date();
    return {
      year: dateFormat.getFullYear(),
      month: dateFormat.getMonth() + 1,
      day: dateFormat.getDate() - 1,
    };
  }
  /**
   * Method for setting date range
   * @param dateConfig {{IMyDpOptions}}
   * @param event {{IMyDateModel}}
   * @param type {{string}}
   */
  setDateInput(
    dateConfig: IMyDpOptions,
    event: IMyDateModel,
    type: string
  ): IMyDpOptions {
    let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null!;
    let dateOptions = HelperService.setDateRange(dateConfig, date, type);
    return dateOptions;
  }
  /**
   * checking the retrieved data
   * @param data {{any}}
   */
  checkNullorEmpty(data: any) {
    return data ? data : '';
  }
  /**
   * Binding the course and responsible for execution dropdown
   */
  bindDetails() {
    this.responsibleExcecution = [];
    this.courseNames = [];

    this.resposibleNames = this.route.snapshot.data['responsibleExecution'];
    this.responsibleExcecution.push({ label: 'Select', value: null });
    if (this.resposibleNames) {
      this.resposibleNames.forEach((element: any) => {
        this.responsibleExcecution.push({
          label: element.Name,
          value: element.Id,
        });
      });
    }

    if (this.isPortalBusiness) {
      this.subscriptions.push(
        this.businessServices
          .getBusinessCreator(BaseServices.BusinessId)
          .subscribe((portal) => {
            if (portal) {
              let portalId = portal;
              if (portalId > 0) {
                this.subscriptions.push(
                  this.employeeCourseServices
                    .getUserIdFromPortal(portalId)
                    .subscribe((userId) => {
                      if (userId) {
                        let id = userId;
                        this.subscriptions.push(
                          this.employeeCourseServices
                            .getPortalCourse(id, this.isEmployeecourses)
                            .subscribe((result) => {
                              if (result) {
                                this.courseType = [];
                                this.courseType = result;
                                if (this.courseType) {
                                  this.courseList = [];
                                  this.courseType.forEach((element: any) => {
                                    this.courseNames.push({
                                      label: element.Title,
                                      value: element.Id,
                                    });
                                  });
                                  this.courseList = this.courseType;
                                }
                              }
                            })
                        );
                      }
                    })
                );
              }
            }
          })
      );
    } else {
      // Fetches courseType from resolver, but this data does not update when new records are added.
      // Replaced it with a call to the service: refetch();
      // old code:
      // this.courseType = this.route.snapshot.data['course'];

      this.refetch();

    }
  }
  /**
   * Refetches course data and updates the course type and course names.
   * Fetches all courses by course name, updates `courseType`, initializes
   * `courseNames` with a default select option, populates it with course titles and IDs,
   * and updates `courseList`. Logs errors if fetching fails.
   *
   * @returns {void}
   */
  refetch() {
    this.courseType = this.employeeCourseServices
      .getAllByCourseName(BaseServices.BusinessId, this.isEmployeecourses)
      .subscribe(
        (courses) => {
          this.courseType = courses;

          // Initialize courseNames and push the default select option
          this.courseNames = [];
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.courseNames.push({ label: val.SELECT, value: null });

              // Populate the dropdown options after the SELECT option
              this.courseType.forEach((element: any) => {
                this.courseNames.push({
                  label: element.Title,
                  value: element.Id,
                });
              });
              this.courseList = this.courseType;
            })
          );
        },
        (error) => {
          console.error('Error loading courses', error);
        }
      );
  }
  /** * Show message
   */
  showDialog() {
    this.dialogDisplay = true;
  }
  /**
   * Save and update employee course data
   */
  saveChanges() {
    let employeeData = this.courseDetailForm.value;
    if (this.courseDetailForm.valid) {
      let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
      let languageId = parseInt(sessionStorage.getItem('languageId'));
      // if(this.hrefcourseEdit == "edit" && this.courseId !=0){
      //     this.courseId = this.employeeCourseId
      // }
      this.EmployeeCourse.Id = this.courseId ? this.courseId : 0;
      this.EmployeeCourse.EmployeeId = this.employeeId ? this.employeeId : 0;
      this.EmployeeCourse.BusinessCourseId = employeeData.BusinessCourseId;
      this.EmployeeCourse.Institute = employeeData.Institute;
      this.EmployeeCourse.BusinessId = BaseServices.BusinessId;
      if (employeeData.StartDate) {
        this.EmployeeCourse.StartDate = HelperService.formatDateFilter(
          employeeData.StartDate.formatted
        );
      } else {
        this.EmployeeCourse.StartDate = employeeData.StartDate
          ? employeeData.StartDate.formatted
          : null;
      }
      if (employeeData.EndDate) {
        this.EmployeeCourse.EndDate = HelperService.formatDateFilter(
          employeeData.EndDate.formatted
        );
      } else {
        this.EmployeeCourse.EndDate = employeeData.EndDate
          ? employeeData.EndDate.formatted
          : null;
      }
      if (employeeData.Renewaldate) {
        this.EmployeeCourse.Renewaldate = HelperService.formatDateFilter(
          employeeData.Renewaldate.formatted
        );
      } else {
        this.EmployeeCourse.Renewaldate = employeeData.Renewaldate
          ? employeeData.Renewaldate.formatted
          : null;
      }
      this.EmployeeCourse.ResponsibleId = employeeData.ResponsibleId;
      this.EmployeeCourse.Description = employeeData.Description;
      this.EmployeeCourse.LanguageId = languageId;

      this.EmployeeCourse.EmployeeDocument = [];
      let keyBindings: any = {
        Originalname: 'OriginalFileName',
        FileTypeId: 'FileType',
      };
      this.newDocumentAdded.forEach((file: any) => {
        let employeeDoc: any = Object.assign({}, file, this.additionalData);
        employeeDoc.id = 0;
        employeeDoc.status = 1;
        employeeDoc.createdBy = BaseServices.UserId;
        this.EmployeeCourse.EmployeeDocument.push(
          HelperService.switchKeysCase(employeeDoc, keyBindings, 'L')
        );
      });
      this.EmployeeCourse.Status = '1';
      let path = this.route.snapshot.url[0].path;
      if (path == 'editCourse' || path == 'edit') {
        this.EmployeeCourse.isEmployeecourse = true;
      } else if (path == 'editContract' || path == 'contractEdit') {
        this.EmployeeCourse.isEmployeecourse = false;
      }
      this.EmployeeCourse.DeadlineNotification =
        employeeData.DeadlineNotification;
      if (this.courseId > 0) {
        this.EmployeeCourse.ModifiedBy = BaseServices.UserId;

        this.subscriptions.push(
          this.employeeCourseServices
            .update(this.EmployeeCourse)
            .subscribe((course) => {
              if (course) {
                this.toasterComponent.callToast();
                this.subscriptions.push(
                  this.employeeCourseServices
                    .getEmployeeContractByEmployeeid(
                      this.employeeId,
                      this.isEmployeecourses
                    )
                    .subscribe((employee: any) => {
                      if (employee) {
                        this.newDocumentAdded = [];
                        this.employeeCourseArray = employee;
                      }
                    })
                );
              }
            })
        );
      } else {
        this.EmployeeCourse.CreatedBy = BaseServices.UserId;
        this.subscriptions.push(
          this.employeeCourseServices
            .add(this.EmployeeCourse)
            .subscribe((employeeCourse) => {
              if (employeeCourse) {
                this.toasterComponent.callToast();
                this.subscriptions.push(
                  this.employeeCourseServices
                    .getEmployeeContractByEmployeeid(
                      this.employeeId,
                      this.isEmployeecourses
                    )
                    .subscribe((course: any) => {
                      if (course) {
                        this.newDocumentAdded = [];
                        this.employeeCourseArray = course;
                      }
                    })
                );
              }
            })
        );
      }
    }
    this.clear();
  }
  ViewDocument(documents: any) {
    this.viewDocument = true;
    this.uploadedFiles = documents;
  }

  /**
   * Clearing the entered data
   */
  clear() {
    this.uploadedFiles = [];
    this.courseId = 0;
    this.courseDetailForm.reset();
  }
  /**
   * Getting employee course data for edit
   * @param eid {{any}}
   */
  edit(eid: any) {
    this.subscriptions.push(
      this.employeeCourseServices.getById(eid).subscribe((employeeCourse) => {
        this.courseId = employeeCourse.Id;
        if (employeeCourse) {
          this.uploadedFiles = employeeCourse.EmployeeDocument;
          this.courseDetailForm = this._fb.group({
            BusinessCourseId: new FormControl(
              this.checkNullorEmpty(employeeCourse.BusinessCourseId)
            ),
            Institute: new FormControl(
              this.checkNullorEmpty(employeeCourse.Institute)
            ),
            StartDate: new FormControl(
              HelperService.formatInputDate(employeeCourse.StartDate)
            ),
            EndDate: new FormControl(
              HelperService.formatInputDate(employeeCourse.EndDate)
            ),
            Renewaldate: new FormControl(
              HelperService.formatInputDate(employeeCourse.Renewaldate)
            ),
            ResponsibleId: new FormControl(
              this.checkNullorEmpty(employeeCourse.ResponsibleId)
            ),
            Description: new FormControl(
              this.checkNullorEmpty(employeeCourse.Description)
            ),
            DeadlineNotification: new FormControl(
              this.checkNullorEmpty(employeeCourse.DeadlineNotification)
            ),
          });
        }
      })
    );
  }

  /**
   * Delete employee course
   * @param employeeCourse {{any}}
   */
  delete(employeeCourse: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeCourseServices
            .deleteCourse(employeeCourse)
            .subscribe((isCourseDeleted) => {
              if (isCourseDeleted) {
                this.toasterComponent.callToastDlt();
                this.subscriptions.push(
                  this.employeeCourseServices
                    .getEmployeeContractByEmployeeid(
                      this.employeeId,
                      this.isEmployeecourses
                    )
                    .subscribe((courseId: any) => {
                      if (courseId) {
                        this.employeeCourseArray = courseId;
                      } else {
                        this.employeeCourseArray = [];
                      }
                    })
                );
              }
            })
        );
      },
    });
  }

  /**
   * course Type popup control clear
   */
  onBeforeCourseDialogHide() {
    this.coursePopup.reset();
    this.courseTable.reset();
    this.courseTypeId = 0;
  }
  /**
   * bind course table
   */
  bindCourse(business?: any) {
    this.courseList = [];
    this.courseNames = [];
    if (this.hrefcontract == 'editContract') {
      if (!this.IsCommonPortalCourseNames) {
        this.subscriptions.push(
          this.employeeCourseServices
            .getAllByPortalCourseName(
              BaseServices.BusinessId,
              this.isEmployeecourses
            )
            .subscribe((result) => {
              if (result) {
                this.courseType = [];
                this.courseType = result;
                if (this.courseType) {
                  this.courseNames = [];
                  this.subscriptions.push(
                    this.translate
                      .stream('SELECT_DROPDOWN')
                      .subscribe((val) => {
                        this.courseNames.push({
                          label: val.SELECT,
                          value: null,
                        });
                      })
                  );
                  this.courseList = [];
                  this.courseType.forEach((element: any) => {
                    this.courseNames.push({
                      label: element.Title,
                      value: element.Id,
                    });
                  });
                  this.courseList = this.courseType;

                  if (business) {
                    this.courseDetailForm
                      .get('BusinessCourseId')!
                      .patchValue(business.Id);
                  }
                }
              }
            })
        );
      } else if (this.IsCommonPortalCourseNames) {
        this.subscriptions.push(
          this.employeeCourseServices
            .getallPortalContractCourse(
              BaseServices.PortalId,
              BaseServices.BusinessId
            )
            .subscribe((result) => {
              if (result) {
                this.courseType = [];
                this.courseType = result;
                if (this.courseType) {
                  this.courseNames = [];
                  this.subscriptions.push(
                    this.translate
                      .stream('SELECT_DROPDOWN')
                      .subscribe((val) => {
                        this.courseNames.push({
                          label: val.SELECT,
                          value: null,
                        });
                      })
                  );
                  this.courseList = [];
                  this.courseType.forEach((element: any) => {
                    this.courseNames.push({
                      label: element.Title,
                      value: element.Id,
                    });
                  });
                  this.courseList = this.courseType;

                  if (business) {
                    this.courseDetailForm
                      .get('BusinessCourseId')!
                      .patchValue(business.Id);
                  }
                }
              }
              this.bindDetails();
            })
        );
      } else {
        this.subscriptions.push(
          this.employeeCourseServices
            .getAllByContractName(BaseServices.BusinessId)
            .subscribe((course) => {
              if (course) {
                this.courseNames = [];
                this.subscriptions.push(
                  this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                    this.courseNames.push({ label: val.SELECT, value: null });
                  })
                );
                course.forEach((element: any) => {
                  this.courseNames.push({
                    label: element.Title,
                    value: element.Id,
                  });
                });
                this.courseList = course;

                if (business) {
                  this.courseDetailForm
                    .get('BusinessCourseId')!
                    .patchValue(business.Id);
                }
              }
            })
        );
      }
    } else if (
      this.hrefcourse == 'editCourse' ||
      this.hrefcourseEdit == 'edit'
    ) {
      if (!this.IsCommonPortalCourseNames) {
        this.subscriptions.push(
          this.employeeCourseServices
            .getAllByPortalCourseName(
              BaseServices.BusinessId,
              this.isEmployeecourses
            )
            .subscribe((result) => {
              if (result) {
                this.courseType = [];
                this.courseType = result;
                if (this.courseType) {
                  this.courseNames = [];
                  this.subscriptions.push(
                    this.translate
                      .stream('SELECT_DROPDOWN')
                      .subscribe((val) => {
                        this.courseNames.push({
                          label: val.SELECT,
                          value: null,
                        });
                      })
                  );
                  this.courseList = [];
                  this.courseType.forEach((element: any) => {
                    this.courseNames.push({
                      label: element.Title,
                      value: element.Id,
                    });
                  });
                  this.courseList = this.courseType;

                  if (business) {
                    this.courseDetailForm
                      .get('BusinessCourseId')!
                      .patchValue(business.Id);
                  }
                }
              }
            })
        );
      } else if (this.IsCommonPortalCourseNames) {
        this.subscriptions.push(
          this.employeeCourseServices
            .getallPortalCourse(
              BaseServices.PortalId,
              BaseServices.BusinessId,
              this.isEmployeecourses
            )
            .subscribe((result) => {
              if (result) {
                this.courseType = [];
                this.courseType = result;
                if (this.courseType) {
                  this.courseNames = [];
                  this.subscriptions.push(
                    this.translate
                      .stream('SELECT_DROPDOWN')
                      .subscribe((val) => {
                        this.courseNames.push({
                          label: val.SELECT,
                          value: null,
                        });
                      })
                  );
                  this.courseList = [];
                  this.courseType.forEach((element: any) => {
                    this.courseNames.push({
                      label: element.Title,
                      value: element.Id,
                    });
                  });
                  this.courseList = this.courseType;

                  if (business) {
                    this.courseDetailForm
                      .get('BusinessCourseId')!
                      .patchValue(business.Id);
                  }
                }
              }
            })
        );
      } else {
        this.employeeCourseServices
          .getAllByCourseName(BaseServices.BusinessId, this.isEmployeecourses)
          .subscribe((course) => {
            if (course) {
              this.courseNames = [];
              this.subscriptions.push(
                this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
                  this.courseNames.push({ label: val.SELECT, value: null });
                })
              );
              course.forEach((element: any) => {
                this.courseNames.push({
                  label: element.Title,
                  value: element.Id,
                });
              });
              this.courseList = course;

              if (business) {
                this.courseDetailForm
                  .get('BusinessCourseId')!
                  .patchValue(business.Id);
              }
            }
          });
      }
    }
  }

  /**
   *  edit course
   * @param course {any}
   */
  editCourse(course: any) {
    this.courseTypeId = course.Id;
    this.coursePopup = this._fb.group({
      Course: course.Title,
    });
  }

  /**
   * delete course
   * @param course {any}
   */
  deleteCourse(course: any) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeCourseServices
            .deletecourseData(course.Id)
            .subscribe((isCourseDeleted: any) => {
              if (isCourseDeleted.isSuccess) {
                if (BaseServices.roleId != '3') {
                  this.bindCourse();
                } else {
                  this.refetchDropdown();
                }
                this.toasterComponent.callToastDlt();
              } else {
                this.existsAlerttext = `Course name already in use.`;
                this.isNameExists = true;
                setTimeout(() => {
                  this.isNameExists = false;
                }, 3000);
              }
            })
        );
      },
    });
  }

  /**
   * add and update sub department
   */
  savecourse() {
    let businessCourse = new BusinessCourse();
    businessCourse.Id = this.courseTypeId ? this.courseTypeId : 0;
    businessCourse.Title = this.coursePopup.value.Course;
    businessCourse.Status = '1';
    businessCourse.BusinessId = BaseServices.BusinessId;

    if (this.hrefcourse == 'editCourse') {
      businessCourse.IsEmployeeCourse = true;
    } else if (this.hrefcontract == 'editContract') {
      businessCourse.IsEmployeeCourse = false;
    }

    if (businessCourse.Id > 0) {
      businessCourse.ModifiedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeCourseServices
          .updateCourseData(businessCourse)
          .subscribe((courseDetail: any) => {
            if (courseDetail) {
              this.bindCourseDropdown(courseDetail);
              if (this.courseNames) {
                this.courseDetailForm
                  .get('BusinessCourseId')!
                  .patchValue(courseDetail.Id);
              }
            } else {
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    } else {
      businessCourse.CreatedBy = BaseServices.UserId;
      this.subscriptions.push(
        this.employeeCourseServices
          .addcourseData(businessCourse)
          .subscribe((addedCourse: any) => {
            if (addedCourse) {
              this.toasterComponent.callToast();
              this.bindCourseDropdown(addedCourse);
              this.courseDetailForm
                .get('BusinessCourseId')!
                .patchValue(addedCourse.Id);
            } else {
              this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
              this.isNameExists = true;
              setTimeout(() => {
                this.isNameExists = false;
              }, 3000);
            }
          })
      );
    }
  }

  /**
   * course after event trigger
   * @param department {any}
   */
  bindCourseDropdown(CourseDetail: any) {
    if (CourseDetail) {
      if (BaseServices.roleId != '3') {
        this.bindCourse(CourseDetail);
      } else {
        this.refetchDropdown(CourseDetail);
      }
      this.addCourse = false;
    }
  }
  refetchDropdown(business?: any) {
    this.employeeCourseServices
      .getAllByCourseName(BaseServices.BusinessId, this.isEmployeecourses)
      .subscribe((course) => {
        if (course) {
          this.courseNames = [];
          this.subscriptions.push(
            this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
              this.courseNames.push({ label: val.SELECT, value: null });
            })
          );
          course.forEach((element: any) => {
            this.courseNames.push({
              label: element.Title,
              value: element.Id,
            });
          });
          this.courseList = course;

          if (business) {
            this.courseDetailForm
              .get('BusinessCourseId')!
              .setValue(business.Id);
          }
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
