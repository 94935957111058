<style>
    .logos-block {
        padding: 0;
    }

    .wrapper-container {
        height: calc(100% - 88px);
        display: table;
        width: 100%;
    }

    .article-wrapper,
    .login-wrapper {
        display: table-cell;
    }

    .article-wrapper {
        width: calc(100% - 300px);
        padding: 60px 200px;
    }

    .login-wrapper {
        width: 300px;
        background: #f5f5f5;
        height: auto;
        border-left: 1px solid #ddd;
    }

    .login-panel {
        text-align: left;
        display: block;
        padding: 0 20px;
    }

    .header-container {
        display: table;
        width: 100%;
    }

    .header-container>div {
        display: table-cell;
        vertical-align: middle;
    }

    .business-logo {
        padding: 15px 0;
    }

    .business-logo img {
        max-width: 230px;
        max-height: 80px;
        width: auto;
        height: auto;
    }

    .header-container .info-tabs {
        padding: 0 20px;
        text-align: center;
        vertical-align: bottom;
    }

    .info-tabs .nav-pills>li>a {
        border-radius: 0;
        color: #555;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        border-bottom: 3px solid transparent;
    }

    .info-tabs .nav-pills>li>a:hover,
    .info-tabs .nav-pills>li>a:focus,
    .info-tabs .nav-pills>li>a:active {
        background-color: transparent;
        color: #333;
    }

    .info-tabs .nav-pills>li.active>a,
    .info-tabs .nav-pills>li.active a:hover,
    .info-tabs .nav-pills>li.active a:focus {
        background-color: transparent;
        color: #91268f;
        border-bottom-color: #91268f;
    }

    .info-tabs .btn-group {
        position: absolute;
        right: 0;
        top: -30px;
    }

    .language-list {
        width: 172px;
        border: none;
    }

    .content-block {
        padding: 30px 60px;
    }
</style>
<header>
    <div class="logos-block">
        <div class="container">
            <div class="header-container">
                <div class="business-logo">
                    <img src="assets/images/KubaLogo.png" alt="KUBA">
                </div>
                <div class="info-tabs">
                    <nav>
                        <ul class="nav nav-pills">
                            <li [routerLinkActive]="['active']" class="active">
                                <a href="javascript:void(0)" (click)="changeNavigation(45)" translate>ABOUT_US</a>
                            </li>
                            <li [routerLinkActive]="['active']">
                                <a href="javascript:void(0)" (click)="changeNavigation(46)" translate>CONTACT_US</a>
                            </li>
                            <li [routerLinkActive]="['active']">
                                <a href="javascript:void(0)" (click)="changeNavigation(47)" translate>SERVICES</a>
                            </li>
                            <li [routerLinkActive]="['active']">
                                <a href="javascript:void(0)" (click)="changeNavigation(48)" translate>PRODUCTS</a>
                            </li>
                            <li [routerLinkActive]="['active']">
                                <a href="javascript:void(0)" (click)="changeNavigation(49)" translate>ABOUT_KUBA</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="language-list">
                    <div class="lang-item">
                        <button class="english" value="en"></button>
                    </div>
                    <div class="lang-item">
                        <button class="norsk active" value="no"></button>
                    </div>
                    <div class="lang-item">
                        <button class="swedish" value="sv"></button>
                    </div>
                    <div class="lang-item">
                        <button class="polish" value="pl"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="wrapper-container">
    <div class="article-wrapper">
        <span>{{Content}}</span>
    </div>
    <div class="login-wrapper">
        <div class="login-panel">
            <div class="">
                <div class="text-center">
                    <h4>
                        <strong translate>LOGIN</strong>
                    </h4>
                </div>
                <div class="login-form">
                    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <label for="username" class="col-form-label" translate>USERNAME</label>
                            <input type="text" class="form-control" name="username" [(ngModel)]="model.username"
                                #username="ngModel" required autofocus />
                            <div *ngIf="f.submitted && !username.valid" class="help-block" translate>USERNAME_REQUIRED
                            </div>
                        </div>
                        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                            <label for="password" class="col-form-label" translate>PASSWORD</label>
                            <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                                #password="ngModel" required />
                            <div *ngIf="f.submitted && !password.valid" class="help-block" translate>PASSWORD_REQUIRED
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <a href="javascript:void(0):" class="btn-link" (click)="goToForgetPassword()">
                                <span translate translate>FORGET</span>
                            </a>
                        </div>
                        <button [disabled]="loading" class="btn btn-primary btn-block" translate>LOGIN</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>