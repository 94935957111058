export class ruhList
{
        Id ?: number;
        Title ?: string;
        Description ?: string;
        BusinessId ?: number;
        BusinessDepartmentId ?: number;
        TopicId ?: number;
        CaseId ?: number;
        Deadline ?: Date;       
        EditedBy ?: string;
        ExecutionUserId ?: number;
        FollowupUserId ?: number;
        Status ?: string;
        CreatedBy ?: number;
        CreatedOn ?: Date;
        ModifiedBy ?: number;
        ModifiedOn ?: Date
        StatusIcon ?: string;
        ProjectName ?: string;
        TypeOfObject ?: string;
        StatusId ?:number;
        H1 ?:boolean;
        H2 ?:boolean;
        N ?:boolean;
        FF ?:boolean;
        UFT ?:boolean;
}
export class Ruh {
    Id ?: number;
    Title ?: string;
    Description ?: string;
    SuggestionAction ?:string;
    CorrectiveAction ?:string;
    ProcessingCorrectiveAction ?:string;
    CauseAnalysis ?:string;
    ImmediateAction ?:string;
    BusinessId ?: number;
    FeatureId ?: number;
    EventTime ?:string;
    EventDate ?:string;
    Deadline ?: string;
    MeasuringDeadline ?: string;
    MeasuringEffectofActions ?:string;
    ApplicationId ?: number;
    BusinessDepartmentId ?: number;
    Sorting ?:number;
    TopicId ?: number;
    CaseId ?: number;
    ActivityId ?:number;
    H1 ?: boolean;
    H2 ?:boolean;
    N ?:boolean;
    FF ?: boolean; 
    UFT ?:boolean;
    Location ?:string;
    ExecutionUserId ?: number;
    RuhAttachment ?: RuhAttachment[];
    CultureInfo ?:string;
    FollowupUserId ?: number;
    Status ?: string;
    NewActionTitle ?: string;
    ActionDescription ?:string;
    ActionStatus ?:string;
    ActionDeadline ?:string;
    ActionExecution ?: number;
    DoneOn ?: Date;
    CreatedBy ?:number;
    //ImmediateAction ?: string;
    //CauseAnalysis ?: string;
   // EffectOfAction ?: string;
    //NotifyBeforeDeadLine ?: string;
    //IsShowtoUsers ?: string;
    //IsDeviationViewRights ?: string;
    //IsSendOnlyDepartment ?: string;
    //CompletedDate ?: string;
    //CompletedBy ?: string;
    //ManualId ?: number;
    // ManualChkCommentsId ?: number;
    // DeviationNumber ?: number;
    // DeviationNumberPrefix ?: string;
    CreatedOn ?: string
    ModifiedBy ?: string;
    ModifiedOn ?: string;
    //RuhUrl ?: string;
    //CultureInfo ?: string;
}

export class ActionPlan{
    Id  ?: number;
    RUHId ?:number;
    Title ?:string;
    Description ?:string;
    Deadline ?:string;
    Status ?:number;
    Reponsible_For_Execution ?:number;
    CreatedBy ?:number;
    ModifiedBy ?:number;
    RUHActionPlanAttachment ?: RuhActionPlanAttachment[];
    CultureInfo ?:string;
    BusinessId ?:number;
    ApplicationId ?:number;
}

export class RuhAttachment {
    Id ?: number;
    RuhId ?: number;
    FileName ?: string;
    OriginalFileName ?: string;
    MimeType ?: string;
    Size ?: number;
    Path ?: string;
    FileType ?: string;
    Status ?: string;
    CreatedBy ?: {
        Id ?: number;
        Name ?: string;
    };
    CreatedOn ?: string;
    ModifiedBy ?: {
        Id ?: number;
        Name ?: string;
    };
    ModifiedOn ?: string;
}
export class RuhActionPlanAttachment {
    Id ?: number;
    RuhId ?: number;
    FileName ?: string;
    OriginalFileName ?: string;
    MimeType ?: string;
    Size ?: number;
    Path ?: string;
    FileType ?: string;
    Status ?: string;
    CreatedBy ?: {
        Id ?: number;
        Name ?: string;
    };
    CreatedOn ?: string;
    ModifiedBy ?: {
        Id ?: number;
        Name ?: string;
    };
    ModifiedOn ?: string;
}
export class Department {

    Id ?: number;
    Name ?: string;
    ParentId ?: string;
    Status ?: number;
    BusinessId ?: number;
    ApplicationId ?: number;
    CreatedBy ?: number;
    ModifiedBy ?: number;
    CreatedOn ?: string;
    ModifiedOn ?: string;

}
export class TypeOfCase {

    Id ?: number;
    Name ?: string;
    Status ?: string;
    BusinessId ?: number;
    ApplicationId ?: number;
    CreatedBy ?: number;
    CreatedOn ?: Date;
    ModifiedBy ?: number;
    ModifiedOn ?: Date;
    IsDefault ?: boolean;


}
export class Topic {

    Id ?: number;
    Name ?: string;
    BusinessId ?: number;
    Status ?: string;
    CreatedBy ?: number;
    CreatedOn ?: Date;
    ModifiedBy ?: number;
    ModifiedOn ?: Date;

}
export class ResponsibleForExe {
    Name ?: string;
    ContactPersonId ?: number
}
export class FollowupPerson {
    ProjectId ?: number;
    ContactPersonId ?: number;
    Name ?: string;
}
export class ResponsiblePerson {
    Id ?: number;
    Name ?: string;
}

export enum Module {
    EMPLOYEEPRIVATEDOCUMENT = 132,
    FRAMEWORKAGREEMENT = 22,
    SERVICEAGREEMENT = 23,
    FDV = 18,
    RECEPTIONCONTROL = 154,
    ACTIVITIES = 145,
    OURCOMPANY = 6,
    BUSINESS = 2,
    CONTACT = 10,
    EMPLOYEES = 8,
    FOLLOWUPS = 15,
    PROJECT = 16,
    FIREDEVIATION = 45, //
    INTERNALCONTROL = 19,
    FOODSAFETY = 20,
    CLIENT = 11,
    CLIENT_CONTACT = 165, //
    SUPPLIER = 25,
    CONTRACTOR = 12,
    CONTRACTOR_CONTACT = 166, //
    VEHICLE = 21
}
export enum UserRole {
    ADMIN = 1,
    PORTAL = 2,
    EDITOR = 3,
    GUEST = 4,
    USER = 5,
    DEVELOPER = 6,
    SUPER = 9,
}