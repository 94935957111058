<div class="tab-content">
    <div class="page-title">
        <span translate>{{title}}</span>
    </div>
    <div *ngIf="isVehicle">
        <div class="form-group">
            <label>
                <span translate>BRAND</span> :
                <label>{{brand}}</label>
            </label>
        </div>
        <div class="form-group">
            <label>
                <span translate>MODEL</span> :
                <label>{{model}}</label>
            </label>
        </div>
        <div class="form-group">
            <label>
                <span translate>REGISTRATION_NUMBER</span> :
                <label>{{regNumber}}</label>
            </label>
        </div>
        <div class="form-group">
            <label>
                <span translate>TYPE_OF_VEHICLE</span> :
                <label>{{type}}</label>
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <form [formGroup]="TaskForm">
                <div class="form-group" *ngIf="taskId !== 0">
                    <label translate>STATUS</label>
                    <div>
                        <label class="form-check form-check-inline" *ngFor="let option of options">
                            <input type="radio" formControlName="Status" value="{{option.id}}"
                              [checked]="option.id === TaskForm.controls['Status'].value">{{option.name
                            | translate}}
                        </label>
                    </div>
                </div>

                <div class="form-group">
                    <label for="department" class="col-form-label" translate>DEPARTMENT</label>
                    <div>
                        <p-dropdown [filter]="true" formControlName="Department" [options]="department"
                          [style]="{'width':'160px'}"></p-dropdown>
                    </div>
                </div>
                <div class="form-group" *ngIf="taskId === 0">
                    <label for="Status">
                        <span translate>STATUS</span>
                    </label>
                    <div>
                        <input type="text" value="{{'NEW_ISSUE'|translate}}" pInputText formControlName="Status"
                          class="ex-full-width" readonly />
                    </div>
                    <control-messages [control]="TaskForm.controls['Status']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Title">
                        <span translate>TITLE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="Title" id="Title" class="ex-full-width" />
                    </div>
                    <control-messages [control]="TaskForm.controls['Title']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Deadline">
                        <span translate>DEADLINE</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <my-date-picker name="endDate" [options]="DeadLineDateOptions" id="Deadline"
                          formControlName="EndDate" placeholder="{{'SELECT_END_DATE'|translate}}" required
                          (dateChanged)="onDeadLineChanged($event)"></my-date-picker>
                    </div>
                    <control-messages [control]="TaskForm.controls['EndDate']"></control-messages>
                </div>
                <div *ngIf='!isSJAActivity' class="form-group">
                    <label for="Repetition">
                        <span translate>REPETITION</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="RepetitionId" id="Type" [disabled]="disableRepetation"
                          [options]="repetitions">
                            <ng-template let-repetiton pTemplate="item"> {{ repetiton.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <control-messages [control]="TaskForm.controls['RepetitionId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="exelabel">
                        <span translate>RESPONSIBLE_FOR_EXECUTION</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-multiSelect [options]="executions" formControlName="ExecutionUser" [style]="{'width':'100%'}"
                          [defaultLabel]="'CHOOSE' | translate"></p-multiSelect>
                    </div>
                    <control-messages [control]="TaskForm.controls['ExecutionUserId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="followuplabel">
                        <span translate>RESPONSIBLE_FOR_FOLLOWUP</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-multiSelect [options]="followups" formControlName="FollowupUser" [style]="{'width':'100%'}"
                          [defaultLabel]="'CHOOSE' | translate"></p-multiSelect>
                    </div>
                    <control-messages [control]="TaskForm.controls['FollowupUserId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Description">
                        <span translate>DESCRIPTION</span>
                    </label>
                    <div>
                        <textarea id="Description" class="ex-full-width" name="Description" pInputTextarea
                          formControlName="Description" [autoResize]="true"></textarea>
                    </div>
                    <control-messages [control]="TaskForm.controls['Description']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Number">
                        <span translate>NOTIFICATION_BEFORE_DEADLINE</span>
                    </label>
                    <div>
                        <input type="number" pInputText formControlName="NotifyBeforeDeadLine" class="ex-full-width"
                          (keypress)="onNumberOfDays($event,2)" />
                    </div>
                    <control-messages [control]="TaskForm.controls['NotifyBeforeDeadLine']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="lblNotificationFollowUp" class="col-form-label"
                      translate>NOTIFICATION_BEFORE_THE_TASK_DAYS</label>
                    <div>
                        <div>
                            <p-dropdown [options]="FollowUpDays" [style]="{'width':'100%'}"
                              formControlName="NotificationBeforeFollowUp"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="ishidden">
                    <div class="checkbox">
                        <p-checkbox label="{{'SEND_EMAIL_SMS' | translate}}" formControlName="SendUserData"
                          binary="true"></p-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                        <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                          (saveChanges)="saveUploaded($event)">
                        </ecpl-document-explorer>
                    </div>
                </div>
                <div *ngIf="!isSJAActivity" class="form-group-50_50">
                    <div class="form-group">
                        <label class="col-form-label" translate>CHECKLIST</label>
                        <p-dropdown [options]="ChecklistData" (onChange)="onShowSeeChecklist($event)"
                          [disabled]="disableChecklistDeopDown" formControlName="checklistId"
                          [style]="{'width':'100%'}">
                        </p-dropdown>
                        <div
                          *ngIf="TaskForm['controls']['checklistId'].value && showCompleteCheckListButton && showChecklist">
                            <button class="btn btn-primary" (click)="completeChecklist()"
                              translate>COMPLETE_CHECKLIST</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isSJAActivity" class="form-group">
                    <label for="lblURL" class="col-form-label" translate>URL</label>
                    <div>
                        <input type="text" pInputText formControlName="LinkUrl" />
                    </div>
                </div>
            </form>
        </div>
        <div class="col-sm-6" *ngIf="taskId > 0">
            <div class="wb-title">
                <h4 translate>TASK_LOG</h4>
            </div>
            <div class="wb-content" style="height: auto;">
                <div class="default-list">
                    <div class="list-item">
                        <span translate>CREATED_BY</span> {{CreatorName}}
                    </div>
                    <div class="list-item">
                        <span translate>CREATED_ON</span> {{CreatedOn}}
                    </div>
                    <div class="list-item">
                        <span translate>TIME</span> {{time}}
                    </div>
                </div>
            </div>
            <div class="table-view">
                <!-- Table starts -->
                <p-table [value]="taskLog" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="FieldName">{{'MODIFIED_FIELD' | translate}}</th>
                            <th pSortableColumn="OldValue">{{'OLD_VALUE' | translate}}</th>
                            <th pSortableColumn="Modifier">{{'MODIFIED_BY' | translate}}</th>
                            <th pSortableColumn="CreatedOn">{{'MODIFIED_ON' | translate}}</th>
                            <th pSortableColumn="Time">{{'TIME' | translate}}</th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-task>
                        <tr>
                            <td>
                                <span>{{task.FieldName | translate }}</span>
                            </td>
                            <td>
                                <span>{{task.OldValue | translate }}</span>
                            </td>
                            <td>
                                <span>{{task.Modifier}}</span>
                            </td>
                            <td>
                                <span>{{task.CreatedOn | date:'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{task.Time}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="5">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
        </div>


    </div>
    <div class="action-btns-wrapper">
        <button type="button" class="btn btn-outline-secondary" (click)="gotoList()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
        <button class="btn btn-outline-primary" *ngIf="isChecklistSelected" (click)="showCheckList()"
          translate>SEE_CHECKLIST</button>
        <button class="btn btn-primary" [ngClass]="{'loading' : loading}" type="submit" (click)="saveChanges()"
          *ngIf="approverRights" [disabled]="(!TaskForm.valid || loading )
             || (!isSJAActivity && TaskForm.controls['RepetitionId'].value == null) ">
            <span *ngIf="!loading" translate>SAVE</span>
            <span *ngIf="loading" translate>SAVE_PROCESS</span>
            <span *ngIf="loading" class="loader-icon"></span>
        </button>
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>

<p-dialog header="{{checklistTile}}" [(visible)]="displayDialog"  showEffect="fade"
  [modal]="true" [style]="{width: '700px'}" [resizable]="false" [draggable]="false">
    <div *ngIf="checklistDetails">
        <ng-template ngFor let-section [ngForOf]="checklistDetails.Items" let-parent="index">
            <div class="main-content">
                <div class="main-heading">
                    <table class="table m-0">
                        <tr>
                            <th style="width:55px;">{{parent+1}}</th>
                            <th>{{section.Title}}</th>
                        </tr>
                    </table>
                </div>
                <ng-template ngFor let-item [ngForOf]="section.SubItems" let-child="index">
                    <div class="card-body">
                        <table class="table m-0">
                            <tr>
                                <td *ngIf="item.Status === 1" style="width:55px;">
                                    {{parent+1}} . {{child+1}}
                                </td>
                                <td *ngIf="item.Status === 1">{{ item.Title}}</td>
                            </tr>
                        </table>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </div>
</p-dialog>
<toaster-component></toaster-component>
<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>