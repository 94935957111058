<div class="main-content ">
    <div class="main-heading">
        <!-- <strong translate>KUBA_CONTROL
            <span> - {{ pageTitle | capitalize}}</span>
        </strong> -->
        <label translate>KUBA_CONTROL</label>
        <label> - {{ pageTitle | capitalize}}</label>
        <span class="page-actions text-right">
            <button type="button" class="btn btn-primary" (click)="BackToZone()">
                <span translate>BACK_TO_OBJECTS</span>
            </button>
        </span>
    </div>
    <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="fsId"></ecpl-tab-navigation>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>