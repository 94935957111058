export class Activity {
    Id: number;
    Title: string;
    EndDate: string;
    BusinessName: string;
    Status: boolean;
    DeadLine: Date;
    ExecutionUsers: string;
}


export class ActivityCalendar {
    id: number;
    title: string;
    status: boolean;
    start: Date;
    end: Date;
    color: string;
    executionUser: number;
    type: string;
}
export class DeviationCount {
    Open: number;
    New: number;
    Done: number;
    Rejected: number;
}

export class Riskanalysis {
    Id: number;
    Business: string;
    Title: string;
    CreatedOn: string;
}

export class Message {
    Id: number;
    Message: string;
    Subject: string;
}

export class BusinessInfo {
    BusinessName: string;
    BusinessLogo: string;
    HomePageAddress: string;
    AboutBusiness: string;
    ZipCode: string;
    Function: string;
    Employee: string;
    Telephone: string;
    Id: number;
    HseGoals: string;
    BusinessImg: string;
    AdminAddress: string;
    AdminPostalAddress: string;
    HSEManager: string;
    Environmentallighthouse:string;
}
export class LoggedUser {
    LogOn: Date;
    Business: string;
    BusinessId: number;
    Today: string;
    Yesterday: string;
    DayBeforeYesterday: string;
    DayBeforeYesterdayCount: number;
    YesterdayCount: number;
    TodayCount: number;
    Device: string;
}
export class AboutKuba {
    Id: number;
    ContentData: string;
    BannerImg: string;
    Type: number;
    CmsId: number;
    ApplicationName: string;
}
export class CalendarEvents {
    ActivityId: number;
    Status: string;
    DeadLine: Date;
    Title: string;
}

export class DashboardTV {
    Id: number;
    CmsId: number;
    ContentData: string;
    CreatedOn: Date;
}

export enum CMSPage {
    AboutUs = 45,
    ContactUs = 46,
    Services = 47,
    Products = 48,
    AboutKuba = 49,
    DashboardText = 50,
    AboutKubaIk = 51,
    Help = 52,
    About = 53,
    HmsTv = 54,
    DefaultVideo = 59,
    PortalTV = 58,
    PortalNews = 57
}

