import { CustomizeHomePageResolver, ApplicationDefaultVideoResolver } from './customize-homepage.resolver';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import { CustomizeHomePageCreationComponent } from './components/customize-homepage-creation/customize-homepage-tab.component';
import { CustomizeHomepageComponent } from './customize-homepage.component';
import { DashBoardOwnPageComponent } from '../dashboard/components/dashboard-ownpage.component';

export const customizehomepageRoutes: Route[] = [
    {
        path: 'customize-homepage',
        component: CustomizeHomepageComponent,
        resolve: {
            defaultVideo: ApplicationDefaultVideoResolver
        },
        children: [
            {
                path: 'add',
                component: CustomizeHomePageCreationComponent,
            },
            {
                path: 'customize-news',
                component: DashBoardOwnPageComponent,
            }
        ]
    },
];

