<form [formGroup]="frameworkForm">
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <div class="radio-inline">
                    <label *ngFor="let option of options" class="form-check form-check-inline">
                        <input type="radio" formControlName="Status" class="form-check-input"
                            [checked]="option.id == frameworkForm.controls['Status'].value" name="Status"
                            value="{{option.id}}">{{option.name.toUpperCase() | translate}}
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label for="Title">
                    <span translate>CONTRACT_NUMBER</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="number" pInputText formControlName="ContractNumber" id="ContractNumber" class="ex-full-width"
                        (keypress)="onContractNumber($event,15)" />
                </div>
            </div>
            <div class="form-group">
                <label>
                    <span translate>CONTRACT_NAME</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="ContractName" id="ContactName"
                        class="ex-full-width" />
                </div>
            </div>
            <div class="form-group">
                <label>
                    <span translate>ADDRESS</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="Address" id="Address" class="ex-full-width" />
                </div>
            </div>
            <div class="form-group">
                <label for="Title">
                    <span translate>ZIP</span>
                </label>
                <div>
                    <input type="number" pInputText formControlName="Zip" id="Zip" class="ex-full-width" />
                </div>
            </div>
            <div class="form-group">
                <label for="Title">
                    <span translate>CITY</span>
                </label>
                <div>
                    <input type="text" pInputText formControlName="City" id="City" class="ex-full-width" />
                </div>
            </div>
            <div class="form-group">
                <label for="Client">
                    <span translate>CLIENT</span>
                </label>
                <div>
                    <p-dropdown [options]="clients" formControlName="ClientId" #clientDropdown
                        (onChange)="onClientChanged($event, clientDropdown)" [style]="{'width':'100%'}">
                    </p-dropdown>
                </div>
            </div>
            <div class="form-group">
                <label for="Comments">
                    <span translate>COMMENTS</span>
                </label>
                <div>
                    <textarea pInputTextarea id="Comments" formControlName="Comments" class="ex-description"></textarea>
                </div>
            </div>
            <div class="form-group">
                <label for="EmailDevaition">
                    <span translate>EMAIL_NEWDEVIATION_LABEL</span>
                </label>
                <div *ngIf="bindSelectedUsers">
                    <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [options]="userSubscribers"
                        formControlName="selectedEmailSubscriber" [style]="{'width':'100%'}">
                    </p-multiSelect>
                </div>
                <div *ngIf="bindAllUsers">
                    <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [dataKey]="'Id'"
                        [options]="selectedSubscribers" formControlName="selectAllEmailSubscriber"
                        [style]="{'width':'100%'}">
                    </p-multiSelect>
                </div>
            </div>
            <div class="checkbox">
                <p-checkbox label="{{'DO_NOT_DISPLAY_DEVIATION'|translate}}" formControlName="IsShowDeviationChecked"
                    binary="true"></p-checkbox>
            </div>
            <div class="form-group">
                <ecpl-document-explorer [uploaderConfig]="upConfig" (saveChanges)="saveUploaded($event)"
                    [uploadedFiles]="uploadedFiles">
                </ecpl-document-explorer>
            </div>
        </div>
        <div class="col-sm-6">
            <div *ngIf="isCopyFolder">
                <div class="sub-panel-title">
                    <span translate>COPYFOLDERS_CONTENT</span>
                </div>
                <div class="form-group" [hidden]="IsHidden">
                    <label for="ChooseContract" translate>CHOOSE_CONTRACT</label>
                    <div>
                        <p-dropdown [options]="frameworkList" id="ChooseContract" [style]="{'width':'500px'}">
                        </p-dropdown>
                    </div>
                </div>
                <div [hidden]="IsHidden">
                    <p-checkbox value="CopySja" label="Copy SJA" binary="true"></p-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="upload-file-container">
                    <label>
                        <span translate>CONTRACT_IMAGE</span>
                    </label>
                    <div class="upload-actions">
                        <input type="file" id="files" class="hidden" (change)="filesSelect($event)" />
                        <label for="files" class="btn btn-outline-primary" translate>UPLOAD_IMAGE</label>
                    </div>
                    <div *ngIf="uploadStart">
                        <span translate>IMAGE_IS_UPLOADING</span>
                    </div>
                    <div *ngIf="frameworkAgreementImg != null">
                        <img src="{{frameworkAgreementImg}}" alt="" width="100" height="100" class="thumbnail">
                    </div>
                </div>
            </div>
            <div class="sub-panel-title">
                <span translate>ASSIGN_CONTRACT</span>
            </div>
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="frameWorkUsers" dataKey="Id" scrollable="true" scrollHeight="200px">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                <p-checkbox [ngModel]="checkedAll" (ngModelChange)="checkAll($event)"
                                    [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                            </th>
                            <th>{{'NAME' | translate}}</th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-framework>
                        <tr>
                            <td>
                                <p-checkbox [(ngModel)]="framework.Id === userId || framework.status"
                                    [disabled]="framework.Id === userId" (ngModelChange)="checked(framework)"
                                    [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                            </td>
                            <td>
                                <span>{{framework.Name}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
            <div class="sub-panel-title">
                <span translate>ASSIGN_CLIENT</span>
            </div>
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="frameworkClientUsers" dataKey="Id" [(selection)]="selectedClient"
                    scrollable="true" scrollHeight="200px">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 55px;">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>{{'NAME' | translate}}</th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-client>
                        <tr>
                            <td style="width: 55px;">
                                <p-tableCheckbox id="selectedOrders" [value]="client">
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <span>{{client.Name}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button class="btn btn-outline-secondary" (click)="gotoBack()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
        <button class="btn btn-primary" (click)="saveChanges()" [disabled]="!frameworkForm.valid">
            <span translate>SAVE_AND_BACK</span>
        </button>
        <button class="btn btn-primary" type="submit" (click)="saveAndCreateNew()" [disabled]="!frameworkForm.valid">
            <span translate>SAVE_CREATE_NEW</span>
        </button>
    </div>
</form>
<toaster-component></toaster-component>