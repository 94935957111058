
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  FoodSafetyManualCompleteChecklistData, FoodSafteyManualCompleteStatus, FoodSafetyManualCompleteChecklist
} from '../models/complete-checklist'
import { CompleteCheckListServices } from '../services/complete-checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseServices } from '../../kuba.services';
import { Location } from '@angular/common';
import { FeatureKey } from '../../../_models';
import { ManualControlServices } from 'src/app/kuba/manual-control/services/manual-control-services';
import { FoodSafetyServices } from 'src/app/kuba/foodsafety/services/foodsafety.services';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-kuba-foodsafety-complete-checklist',
  templateUrl: './kuba-foodsafety-complete-checklist.component.html',
  styleUrls: ['./kuba-complete-checklist.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class FoodSafetyCompleteChecklistComponent implements OnInit {

  FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist;
  FoodSafetyManualCompleteChecklistData: FoodSafetyManualCompleteChecklistData[];
  temperaturevalue: any;
  featureKey: number;
  key: any;
  featureId: any;
  showLoader = false;
  zoneTitle: string;
  private subscriptions: Subscription[] = [];
  constructor(private CompleteCheckListServices: CompleteCheckListServices, private route: ActivatedRoute, 
    public foodSafetyService: FoodSafetyServices, 
    private manualControlService: ManualControlServices, private Location: Location
    , private confirmationService: ConfirmationService, private router: Router
    , private translate: TranslateService) {

  }
  ngOnInit() {
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule')).id;
        if(workingModule != null){
            this.featureId = workingModule;
            if(this.featureId == 20)
              this.key = 'foodsafety';
            else if(this.featureId == 43)
              this.key = 'kubacontrol';
        } 
    let path = this.route.snapshot.url[1].path;
    if (path === 'new') {
      if (this.route.snapshot.params['FeatureKey'] !== 'null') {
        this.FoodSafetyManualCompleteChecklistData = [];
        this.subscriptions.push(this.CompleteCheckListServices.GetFoodSafetyChecklistDataByFoodId(this.route.snapshot.params['FeatureKey'],this.key)
          .subscribe((result: any) => {
            if (result) {
              this.FoodSafetyManualCompleteChecklist = new FoodSafetyManualCompleteChecklist();
              this.FoodSafetyManualCompleteChecklist.FeatureId = this.featureId;
              this.FoodSafetyManualCompleteChecklist.FeatureKey = this.route.snapshot.params['FeatureKey'];
              this.FoodSafetyManualCompleteChecklist.BusinessId =
                BaseServices.BusinessId;
              this.FoodSafetyManualCompleteChecklist.Id = 0;
              this.FoodSafetyManualCompleteChecklist.CreatedBy = BaseServices.UserId;
              this.featureKey = this.route.snapshot.params['FeatureKey'];
              this.FoodSafetyManualCompleteChecklistData = JSON.parse(result);
              this.FoodSafetyManualCompleteChecklist.CheckListData = result;
              this.FoodSafetyManualCompleteChecklistData.forEach((element: any) => {
                let totalMinAndMaxTemp = (Number(element.MinTemp) + Number(element.MaxTemp));
                let calculatedTemp = totalMinAndMaxTemp / 2;
                element.Temperature = calculatedTemp;
              });
            }
          }));
      } else {
        this.FoodSafetyManualCompleteChecklistData = [];
        this.subscriptions.push(this.CompleteCheckListServices.GetAllFoodSafetyChecklistData(this.key)
          .subscribe((result: any) => {
            if (result) {
              this.FoodSafetyManualCompleteChecklist = new FoodSafetyManualCompleteChecklist();
              this.FoodSafetyManualCompleteChecklist.FeatureId = this.featureId;
              this.FoodSafetyManualCompleteChecklist.FeatureKey = null;
              this.FoodSafetyManualCompleteChecklist.BusinessId =
                BaseServices.BusinessId;
              this.FoodSafetyManualCompleteChecklist.Id = 0;
              this.FoodSafetyManualCompleteChecklist.CreatedBy = BaseServices.UserId;
              this.featureKey = null;
              this.FoodSafetyManualCompleteChecklistData = JSON.parse(result);
              this.FoodSafetyManualCompleteChecklistData.forEach((element: any) => {
                let totalMinAndMaxTemp = (Number(element.MinTemp) + Number(element.MaxTemp));
                let calculatedTemp = totalMinAndMaxTemp / 2;
                element.Temperature = calculatedTemp;
              });
              this.FoodSafetyManualCompleteChecklist.CheckListData = result;
              this.FoodSafetyManualCompleteChecklist.CheckListData = result;
            }
          }));
      }
    } else {

      this.FoodSafetyManualCompleteChecklistData = [];
      this.CompleteCheckListServices.getFoodSafetyChecklistById(this.route.snapshot.params['clId'],this.key).subscribe((result: any) => {
        if (result) {
          this.featureKey = result.FeatureKey;
          this.FoodSafetyManualCompleteChecklist = result;
          this.FoodSafetyManualCompleteChecklistData = JSON.parse(this.FoodSafetyManualCompleteChecklist.CheckListData);
          this.FoodSafetyManualCompleteChecklistData.forEach((element: any) => {
            element.Temperature = element.Temperature;
          });
        }
      });
    }
    this.zoneTitle = sessionStorage.getItem('ZoneName');
  }

  setStatus(event: FoodSafteyManualCompleteStatus, status: FoodSafteyManualCompleteStatus[]) {
    for (let statusitem of status) {
      if (statusitem.Type == event.Type) {
        statusitem.Value = true;
      } else {
        statusitem.Value = false;
      }
    }

  }
  SaveCompleteCheckList() {
    this.showLoader = true;
    let CultureInfo = sessionStorage.getItem('languageMode');
    this.FoodSafetyManualCompleteChecklist.ModifiedBy = BaseServices.UserId;
    this.FoodSafetyManualCompleteChecklist.CheckListData = JSON.stringify(this.FoodSafetyManualCompleteChecklistData);
    this.CompleteCheckListServices.SaveFoodSafetyChecklistById(this.FoodSafetyManualCompleteChecklist, CultureInfo, this.key).subscribe(result => {
      if (result) {
        this.FoodSafetyManualCompleteChecklist = result;
        this.FoodSafetyManualCompleteChecklistData = JSON.parse(this.FoodSafetyManualCompleteChecklist.CheckListData);
        this.showLoader = false;
        this.Location.back();
      }
    });
  }

  Saveforlater() {
    this.FoodSafetyManualCompleteChecklist.CheckListData = JSON.stringify(this.FoodSafetyManualCompleteChecklistData);
    if (this.featureKey && this.route.snapshot.url[1].path === 'new') {
      this.FoodSafetyManualCompleteChecklist.CreatedBy = BaseServices.UserId;
    } else {
      this.FoodSafetyManualCompleteChecklist.ModifiedBy = BaseServices.UserId;
    }
    if (this.featureKey) {
      this.confirmationService.confirm({
        message: this.translate.instant('MANUALCONTROL_SAVED_LATER'),
        accept: () => {
          this.showLoader = true;
          this.subscriptions.push(this.manualControlService
            .createCompleteChecklist(this.FoodSafetyManualCompleteChecklist)
            .subscribe(result => {
              if (result) {
                this.showLoader = false;
                this.Location.back();
              }
            }));
        }
      });
    } else {
      this.confirmationService.confirm({
        message: this.translate.instant('FS_SAVED_LATER'),
        accept: () => {
          this.showLoader = true;
          this.subscriptions.push(this.foodSafetyService
            .createCompleteChecklist(this.FoodSafetyManualCompleteChecklist)
            .subscribe(result => {
              if (result) {
                this.showLoader = false;
                this.router.navigate(['../../../archive/list'], {
                  relativeTo: this.route
                });
              }
            }));
        }
      });
    }
  }

  checkValid() {
    let val = false;
    if (this.FoodSafetyManualCompleteChecklistData) {

      for (let section of this.FoodSafetyManualCompleteChecklistData) {

        let status = section.Status;
        if (status[0].Value == false && status[1].Value == false && status[2].Value == false) {
          val = true;
        }


      }
    }
    return val;
  }

  goBackPreviousPage() {
    this.Location.back();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
