import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { BaseServices } from './../../kuba.services';
import { TrashServices } from '../../trash/services/trash.service';
import { EmployeeCourseServices } from './../../employees/services/employeecourse.service';
import { EmployeeCourse, EmployeeList, BusinessCourse } from './../../employees/models/employeeCourse';
import { PdfSetting } from './../../../_services/helper.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { HelperService } from './../../../_services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

require('jspdf-autotable');
@Component({

    templateUrl: 'business-courses.component.html'
})

export class BusinessCourseListComponent implements OnInit {
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt', {static: false}) courseDetailsTable: Table;

    coursePopup: FormGroup;
    courseForm: FormGroup;
    departments: SelectItem[];
    courseNames: SelectItem[];
    bdepartment: any = [];
    coursetype: any = [];
    rexcecution: SelectItem[];
    ctype: any = [];
    businessId: number;
    startDate: string;
    endDate: string;
    employeeCourse: SelectItem[];
    Department: SelectItem[];
    courseData: SelectItem[];
    course = new BusinessCourse();
    selectedCourse = new BusinessCourse();
    courseDetails: any = [];
    courseId: number;
    addCourseModal = false;
    notification = false;
    viewCourse = false;
    ListFilter: any;
    hrefcourse: any;
    hrefcontract: any;
    isEmployeecourses: boolean;
    private subscriptions: Subscription[] = [];

    public courseList: EmployeeCourse[];

    /**
     * constructor
     * @param empcrseservice
     * @param router
     * @param confirmationService
     * @param activatedRoute
     * @param fb
     */
    constructor(
         private employeeCourseService: EmployeeCourseServices,
        private confirmationService: ConfirmationService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private translate: TranslateService,
        private router: Router
    ) {
        this.courseForm = this.fb.group({
            'CourseName': [''],
        })
        this.coursePopup = this.fb.group({
            'Title': ['', [Validators.required, ValidationService.noWhitespaceValidator]]
        });
    }

    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.activatedRoute.parent.params.subscribe((params: Params) => {
            let bid = params['bid'];
            this.businessId = +bid;
        }));
        this.hrefcourse = this.router.url.substring(this.router.url.indexOf('editCourse') ,this.router.url.indexOf('editCourse') + 10);
        this.hrefcontract = this.router.url.substring(this.router.url.indexOf('editContract') ,this.router.url.indexOf('editContract') + 12);
        if(this.hrefcourse == "editCourse"){
            this.isEmployeecourses = true;
        }
        else if(this.hrefcontract == "editContract"){
            this.isEmployeecourses = false;
        }
        this.bindDropDown();
        this.courseList = <EmployeeCourse[]>this.activatedRoute.snapshot.data['list'];
    }

    /**
     * start date change event
     * @param e
     */
    onStartDateChanged(e: any) {
        this.courseList = [];
        this.startDate = HelperService.formatDate(e);
        let list = <EmployeeCourse[]>this.activatedRoute.snapshot.data['list'];
        if (this.startDate && this.endDate) {
            list = list.filter(x => {
                return (x.RenewalDateForFilter) >= this.startDate && (x.RenewalDateForFilter) <= this.endDate;
            });
        }
        this.courseList.push(...list); // pushing array inside array

    }

    /**
     * end date change event
     * @param e
     */
    onEndDateChanged(e: any) {
        this.courseList = [];
        this.endDate = HelperService.formatDate(e);
        let list = <EmployeeCourse[]>this.activatedRoute.snapshot.data['list'];
        if (this.startDate && this.endDate) {
            list = list.filter(x => {
                return (x.RenewalDateForFilter) >= this.startDate && (x.RenewalDateForFilter) <= this.endDate;
            });
        }
        this.courseList.push(...list);
    }

    /**
     * refreshing table and date
     */
    refreshData() {
        this.startDate = '';
        this.endDate = '';
        this.courseDetailsTable.reset();
        this.courseList = <EmployeeCourse[]>this.activatedRoute.snapshot.data['list'];
    }

    /**
     * export pdf
     */
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'Course Details';
        let columns: any = [
            { title: 'Employee Name', dataKey: 'Employee' },
            { title: 'Course Name', dataKey: 'Course' },
        ];
        HelperService.generatePdf(this.courseList, columns, 'Course list', pdfSetting, 'l');
    }

    /**
     * bind all dropdowns
     */
    bindDropDown() {
        this.employeeCourse = [];
        this.courseData = [];
        this.courseDetails = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.employeeCourse = [];
            this.employeeCourse.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.courseData = [];
            this.courseData.push(
                { label: val.SELECT, value: null }
            );
        }));

        let employee = this.activatedRoute.snapshot.data['employee'];
        if (employee) {
            employee.forEach((element: any) => {
                this.employeeCourse.push({ label: element.Name, value: element.Id });
            });
        }
        let course = this.activatedRoute.snapshot.data['courselist'];
        if (course && course.length > 0) {
            course.forEach((element: any) => {
                this.courseData.push({ label: element.Title, value: element.Id });
                this.courseDetails.push({ Title: element.Title, Id: element.Id });
            });
        }
    }

    /**
     * course dropdown change eventF
     * @param e
     * @param courseDropdown
     */
    onCourseChanged(e: any, courseDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.courseDetailsTable.filter(courseDropdown.selectedOption.label, 'Course', 'equals');
        } else {
            this.courseDetailsTable.reset();
        }
    }

    /**
     * edit course name
     * @param course
     */
    editCourseName(course: BusinessCourse) {
        this.courseId = course.Id;
        let result = {
            Title: course.Title
        };
        (<FormGroup>this.coursePopup)
            .setValue(result, { onlySelf: true });
    }

    /**
     * delete course
     * @param courseId
     */
    deleteCourse(courseId: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.employeeCourseService.deletecourseData(courseId).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToastDlt();
                        this.resetCourse(this.businessId);
                    }
                }));
            }
        });
    }

    /**
     * clear course
     */
    clear() {
        this.course.Id = 0;
        this.coursePopup = this.fb.group({
            'Title': ''
        });
    }

    /**
     * refresh course table
     * @param businessId
     */
    resetCourse(businessId: any) {
        this.subscriptions.push(this.employeeCourseService.getAllByCourseName(businessId, this.isEmployeecourses).subscribe((course: any) => {
            if (course) {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.courseData = [];
                    this.courseData.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                course.forEach((element: any) => {
                    this.courseData.push({ label: element.Title, value: element.Id });
                });
                this.courseDetails = course;
            }
        }));
    }

    /**
 * add and update category
 */
    saveCourse() {
        this.course.Title = this.coursePopup.value.Title;
        this.course.Status = '1';
        this.course.Id = (this.courseId) ? this.courseId : 0;
        this.course.BusinessId = (this.businessId) ? this.businessId : 0;
        if (this.courseId > 0) {
            this.subscriptions.push(this.employeeCourseService.updateCourseData(this.course).subscribe((courseEdited: any) => {
                if (courseEdited) {
                    this.toasterComponent.callToast();
                    this.resetCourse(this.businessId);
                    this.courseForm.get('CourseName')!.patchValue(courseEdited.Id)
                }
            }));
        } else {
            this.subscriptions.push(this.employeeCourseService.checkCourseName(this.course.Title).subscribe(res => {
                if (res) {
                    this.notification = true;
                } else {
                    this.subscriptions.push(this.employeeCourseService.addcourseData(this.course).subscribe((courseAdded: any) => {
                        if (courseAdded) {
                            this.toasterComponent.callToast();
                            this.resetCourse(this.businessId);
                            this.courseForm.get('CourseName')!.patchValue(courseAdded.Id)
                        }
                    }));
                }
            }))
        }
        this.clear();
    }

    /**
     * close popup
     */
    clearCourse() {
        this.bindDropDown();
        this.selectedCourse = new BusinessCourse();
        this.selectedCourse.Title = '';
    }

    /**
    * close popup
    */
    onBeforeCourseDialogHide() {
        this.coursePopup.reset();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
