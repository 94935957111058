<div class="main-content">
    <div class="main-heading">
        <span>
            <span translate>FDV_REGISTRY</span>
        </span>
        <span class="page-actions text-right">
            <a [routerLink]="['./../createFdv']" class="btn btn-success" *ngIf="isAdministrateFDV">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills">
                <li [routerLinkActive]="['active']" *ngIf="isAdministrateFDV">
                    <a [routerLink]="['./list']" translate>ALL</a>
                </li>
                <li [routerLinkActive]="['active']" *ngIf="isHideMyFDV">
                    <a [routerLink]="['./myList']" translate>FOR_YOU</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>