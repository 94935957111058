<div class="main-content">
    <div class="main-heading">
        <span translate>HELPANDSUPPORT_LIST</span>
        <span class="page-actions text-right" *ngIf="isAdministrateHelp">
            <a *ngIf="!isAdmin" outerLink="create" [routerLink]="['./../create',0]" class="btn btn-success"
              routerLinkActive="active">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW</span>
            </a>
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [value]="helpsupport" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]"
                  [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="Title">{{'TITLE'|translate}} <p-sortIcon field="Title"></p-sortIcon>
                            </th>
                            <th>{{'CATEGORY_NAME'|translate}}</th>
                            <th>{{'STATUS'|translate}}</th>
                            <th pSortableColumn="CreatedOn">{{'CREATED_ON'|translate}} <p-sortIcon
                                  field="CreatedOn"></p-sortIcon></th>
                            <th pSortableColumn="PublishedOn">{{'PUBLISHED_ON'|translate}} <p-sortIcon
                                  field="PublishedOn"></p-sortIcon></th>
                            <th>{{'OPTIONS'| translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'Title', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th>
                                <p-dropdown width='118px' [options]="categoryName"
                                  (onChange)="dt.filter($event.value, 'CategoryId', 'equals')" appendTo="body"
                                  styleClass="p-column-filter" placeholder="" [filterBy]="null" [showClear]="true">
                                    <ng-template let-help pTemplate="item"> {{ help.label | translate }}
                                    </ng-template>
                                </p-dropdown>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-help>
                        <tr>
                            <td>
                                <span translate>{{help.Title}}</span>
                            </td>
                            <td>
                                <span translate> {{help.CategoryId | categoryName | translate}}</span>
                            </td>
                            <td>
                                <span translate>{{(help.PublishedOn !== null ? 1 : 0) | status:['NOT
                                    PUBLISHED','PUBLISHED']}}</span>
                            </td>
                            <td>
                                <span>{{help.CreatedOn |date : 'dd/MM/yyyy'}}</span>
                            </td>
                            <td>
                                <span>{{help.PublishedOn |date : 'dd/MM/yyyy'}}</span>
                            </td>
                            <td *ngIf="isAdministrateHelp">
                                <a routerLink="create" [routerLink]="['./../create',help.Id]" class="btn btn-icon"
                                  title="{{'EDIT'|translate}}" routerLinkActive="active">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </a>
                                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                  (click)="confirm(help.Id)" *ngIf="isHideDeleteIcon">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="6">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
            <p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
              acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
        </div>
    </div>
</div>
<toaster-component></toaster-component>