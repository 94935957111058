export class Issue{
  Id: number;
  Name: string;
  Status: string;
  BusinessId: number;
  ApplicationId: number;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date;

}