<div class="page-title">
    <span class="page-actions">
        <button class="btn btn-primary" (click)="downloadListPDF()">
            <span class="icon ic-sm icon-print"></span>
            <span translate>PRINT_AS_PDF</span>
        </button>
    </span>
</div>
<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="enabledForms" #insFormsTable [rows]="10" [paginator]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="DocumentNumber">{{'DOCUMENT_NR' | translate}} <p-sortIcon field="DocumentNumber"></p-sortIcon></th>
                    <th pSortableColumn="TranslationKey">{{'TITLE'| translate}} <p-sortIcon field="TranslationKey"></p-sortIcon></th>
                    <th>{{'OPTION'| translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-form>
                <tr>
                    <td>
                        <span>{{form.DocumentNumber}}</span>
                    </td>
                    <td>
                        <span>{{form.TranslationKey | translate}}</span>
                    </td>
                    <td class="col-button">
                        <button *ngIf="form.Id != 45" (click)="editTemplate(form)" type="button" class="btn btn-icon">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button (click)="downloadPDF(form.TranslationKey)" type="button" class="btn btn-icon"
                            title="PDF">
                            <i class="icon ic-sm icon-pdf"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>

            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="3">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog header="{{previewHeader | translate}}" *ngIf="editContent" [(visible)]="editContent" [modal]="true"
[style]="{width: '1000px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'scroll'}">
    <table *ngIf="editFormType == 'doc-ass'" class="table table-bordered">
        <thead>
            <tr>
                <th colspan="3">
                    <span translate>REVIEW_OF_AVAILABLE_DOCUMENTATION</span>
                </th>
            </tr>
            <tr>
                <th><span translate></span></th>
                <th><span translate>DOCUMENTATION_BY_OWNER/USER</span></th>
                <th><span translate>COMMENTS</span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of DocumentAssReview">
                <td class="col-button" style="width:8%">
                    <button type="button" class="btn btn-icon" (click)="addDocAssItem(item)" title="Add Row">
                        <span class="icon ic-sm icon-add"></span>
                    </button>
                    <button *ngIf="DocumentAssReview.length > 1" type="button" class="btn btn-icon"
                        (click)="deleteDocAssItem(item)" title="Delete Row">
                        <span class="icon ic-sm icon-trash"></span>
                    </button>
                </td>
                <td style="width:46%;padding: 0px;">
                    <input style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text"
                        class="form-control" [(ngModel)]="item.name" />
                </td>
                <td style="width:46%;padding: 0px;">
                    <input style="min-height: 20px;height: 36px;max-width: 600px;width: 100%;" type="text"
                        class="form-control" [(ngModel)]="item.comments" />
                </td>
            </tr>
        </tbody>
    </table>
    <risk-assessment #riskAssessment *ngIf="editFormType == 'risk-ass'"></risk-assessment>
    <course-overview #course *ngIf="editFormType =='course'" (distributionChange)="onBuidingSelect($event)">
    </course-overview>
    <fire-alarm #filreAlarm *ngIf="editFormType =='fire-alarm'"></fire-alarm>
    <emergency-lighting #emergencyLighting *ngIf="editFormType =='emer-light'"></emergency-lighting>
    <table *ngIf="editFormType == 'distribution'" class="table table-bordered">
        <thead>
            <tr>
                <th colspan="8"><span translate>MAIN_AND_SUBDISTRIBUTIONS_OVERVIEW</span></th>
            </tr>
            <tr>
                <th></th>
                <th style="text-align:center"><span translate>FLOOR</span></th>
                <th style="text-align:center"><span translate>ROOMS_NUMBER</span></th>
                <th style="text-align:center"><span translate>DISTRIBUTION_ID</span></th>
                <th style="text-align:center"><span translate>MAIN_DISTRIBUTION</span></th>
                <th style="text-align:center"><span translate>SUB_DISTRIBUTION</span></th>
                <th style="text-align:center"><span translate>MEASURE_NUMBER</span></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of distributionItems">
                <td style="width:120px" class="col-button"  *ngIf="item.Status !== 0">
                    <button type="button" (click)="addDistributionItem(item)" class="btn btn-icon">
                        <span class="icon ic-sm icon-add"></span>
                    </button>
                    <button *ngIf="hideDelete()" type="button" (click)="deleteDistributionItem(item)"
                        class="btn btn-icon">
                        <span class="icon ic-sm icon-trash"></span>
                    </button>
                </td>
                <td style="width:100px" *ngIf="item.Status !== 0">
                    <input type="text" class="form-control" [(ngModel)]="item.Floor" />
                </td>
                <td style="width:100px" *ngIf="item.Status !== 0">
                    <input type="text" class="form-control" [(ngModel)]="item.RoomNo" />
                </td>
                <td style="width:100px" *ngIf="item.Status !== 0">
                    <input type="text" class="form-control" [(ngModel)]="item.DistributionId" />
                </td>
                <td style="text-align:center;width:80px" *ngIf="item.Status !== 0">
                    <p-checkbox binary="true" [ngModel]="item.DistributionType === 1"
                        (onChange)="currentTypeChecked(item, 1)"></p-checkbox>
                </td>
                <td style="text-align:center;width:80px" *ngIf="item.Status !== 0">
                    <p-checkbox binary="true" [ngModel]="item.DistributionType === 2"
                        (onChange)="currentTypeChecked(item, 2)"></p-checkbox>
                </td>
                <td style="width:100px" *ngIf="item.Status !== 0">
                    <input type="text" class="form-control" [(ngModel)]="item.MeasureNumber" />
                </td>
            </tr>
        </tbody>
    </table>
    <form *ngIf="editFormType == 'measure-protocol'" [formGroup]="measureProtocolForm">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="Title">
                        <span translate>MP_NUMBER</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="number" formControlName="Number" id="Number" class="form-control" />
                    </div>
                    <control-messages [control]="measureProtocolForm.controls['Number']"></control-messages>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>MP_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" formControlName="Name" id="Name" class="form-control" />
                    </div>
                    <control-messages [control]="measureProtocolForm.controls['Name']"></control-messages>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>CLIENT</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown [options]="clients" formControlName="ClientId" #clientDropdown
                            [style]="{'width':'100%'}">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper text-left">
            <button type="button" class="btn btn-primary" [disabled]="measureProtocolForm.invalid || saveprogress"
                (click)="saveMeasureProtocol()">
                <span translate>SAVE</span>
            </button>
        </div>
    </form>
    <div *ngIf="editFormType != 'measure-protocol'" class="action-btns-wrapper">
        <div class="pull-right">
            <button type="submit" class="btn btn-primary" (click)="saveTemplate()">
                <span translate>SAVE</span>
            </button>
        </div>
    </div>
</p-dialog>
<toaster-component></toaster-component>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>