import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { CommonBaseModule } from './../common/common.module';
import { ManualComponent } from './manual.component';
import {
    ManualFormComponent,
    ManualViewComponent,
    ManualTabsComponent,
    ManualBaseComponent,
    ManualListComponent,
    ManualRegistryComponent,
    ManualLARComponent
} from './components/index';
import { ManualService } from './services/manual.services';
import {
    ManualListResolver,
    BusinessManualListResolver,
    PortalManualListResolver,
    ManualTreeDataResolver,
    ManualDataResolver,
    ChooseBusinessResolver,
    ChoosePortalResolver,
    ChecklistTemplateResolver,
    AllManualListResolver,
    BusinessModeReslover,
    AdminUserResolver

} from './services/manual.resolver.service';
import { ManualLARService } from './services/manual-laws-regualtion.services';
import { AssignManualComponent } from './components/assignmanual.component';
import { AssignManualListComponent } from './components/assign-manual-list.component';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { EcplLarViewerModule } from './../../shared/ecpl-lar-viewer/ecpl-lar-viewer.module';
import { EcplArticleViewerModule } from 'src/app/shared/ecpl-article-viewer';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { TabMenuModule } from 'primeng/tabmenu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MyDatePickerModule } from 'mydatepicker';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        BrowserModule,
        RouterModule,
        TableModule,
        SharedModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        InputSwitchModule,
        ContextMenuModule,
        FileUploadModule,
        AppSharedModule,
        // FieldsetModule,
        TabMenuModule,
        SelectButtonModule,
        CommonBaseModule,
        TranslateModule,
        MyDatePickerModule,
        EcplDocumentTreeModule,
        EcplDocumentExplorerModule,
        EcplLarViewerModule,
        EcplArticleViewerModule
    ],
    exports: [
        ManualComponent,
        ManualFormComponent,
        ManualViewComponent,
        ManualTabsComponent,
        ManualBaseComponent,
        ManualListComponent,
        ManualRegistryComponent,
        ManualLARComponent,
        AssignManualComponent,
        AssignManualListComponent
    ],
    declarations: [
        ManualComponent,
        ManualFormComponent,
        ManualViewComponent,
        ManualTabsComponent,
        ManualBaseComponent,
        ManualListComponent,
        ManualRegistryComponent,
        ManualLARComponent,
        AssignManualComponent,
        AssignManualListComponent
    ],
    providers: [
        ManualService,
        ManualLARService,
        ManualListResolver,
        PortalManualListResolver,
        BusinessManualListResolver,
        ManualTreeDataResolver,
        TranslateService,
        ChooseBusinessResolver,
        ChoosePortalResolver,
        ManualDataResolver,
        ChecklistTemplateResolver,
        AllManualListResolver,
        BusinessModeReslover,
        AdminUserResolver
    ]
})
export class ManualModule { }
