export class BusinessSetting {
    Id: number;
    BusinessId: number;
    LanguageId: number;
    ThemeId: number;
    HseGoal: string;
    HseManagerKey: number;
    HseAdviserKey: number;
    EnvironmentallighthouseKey: number;
    HseFeatureId: number;
    BusinessLogo: string;
    BusinessImg: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    InsuranceCompany: string;
    HomePageAddress: string;
    IsSms: Boolean;
    IsEmail: Boolean;
    PortalContact: number;
    SettingsMeta: SettingsMeta[];
}
export class SettingsMeta {
    BusinessSettingId: number;
    BusinessMetadataId: number;
    EmployeeId: number;
    WorkmanInsurance: string;
    OtpStatutoryInsurance:string;
    HealthCare:string;
}
