import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeeContactServices {
    item: Object;
    constructor(private http: HttpClient) {}

    getCustomerEmployee() {
        return this.http
            .get(
                environment.BASE_URL + '/employeeContacts',
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    getCustomerEmployeeWithLogin() {
        return this.http
            .get(
                environment.BASE_URL + '/cutomerEmployeeWithLogin',
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }
}
