export class notificationView {
    Id: number;
    Applicationid: number;
    Businessid: number;
    PortalId: number;
    UserId: number;
    Title: String;
    Description: string;
    Priority: string;
    CreatedBy: string;
    ModifiedBy: string;
}