import { NewsListResolver, AdminNewsListResolver } from './services/news.resolver.service';
import { Route } from '@angular/router';

import { NewsComponent } from './news.component';
import { NewsListComponent } from './components/NewsList.component';
import { CreateNewsComponent } from './components/CreateNews.component';
export const newsRoutes: Route[] = [
    {
        path: 'news',
        component: NewsComponent,
        children: [
            {
                path: 'list',
                component: NewsListComponent,
                resolve: {
                    list: NewsListResolver,
                },
            },
            {
                path: 'create/:id/:previewMode',
                component: CreateNewsComponent
            }

        ]
    },
];
