import { NotificationUserListtResolver } from './../notifications/services/notification.resolver.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from 'src/app/kuba/shared';
import { NotificationUserComponent } from './notificationuser.component';
import { NotificationServices } from '../../_services/notification.services';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AppSharedModule
    ],
    exports: [NotificationUserComponent],
    declarations: [NotificationUserComponent],
    providers: [
        NotificationUserListtResolver,
        NotificationServices
    ],
})
export class NotificationUserModule {

}
