import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
@Component({     
    template: `<router-outlet></router-outlet>`
})
export class FdvComponent implements OnInit {
   constructor(private http: HttpClient) { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}