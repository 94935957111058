export class ModuleOverview {
    Id: number;
    ApplicationId: number;
    FeatureId: number;
    FeatureName: string;
    TranslationKey: string;
    Description: string;
    Key: string;
    Status: string;
    checked: boolean;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
}

export class ModuleOverviewDetails{
    HSE_MODULES: ModuleOverview [];
    ADDITIONAL_MODULES: ModuleOverview [];
    HR_MODULES: ModuleOverview [];
    OTHER: ModuleOverview [];
    ALTERNATIVE_SETTINGS: ModuleOverview [];
}