import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Suggestion } from '../models/suggestion';
import { SuggestionsServices } from './suggestion.services';

@Injectable()
export class SuggestionListResolver implements Resolve<Suggestion[]> {
    constructor(private backend: SuggestionsServices) { }
    resolve(): Observable<Suggestion[]> {
        return this.backend.list();
    }
}

@Injectable()
export class SuggestionEditResolver implements Resolve<Suggestion> {
    constructor(private backend: SuggestionsServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getById(+route.params['bid']);
    }
}
