export class HazardSections{
    Id: number;
    No: string;
    Name: string;
    ReferenceAndDocument: string;
    ControlFrequency: string;
    FoodSafetyId: number;
    FeatureId:number;
    Status: number;
    Position: number;
    HazardSubSections: HazardSubSections[];
}

export class HazardSubSections{
   Id: number;
   HazardParentId: number;
   BCPA: string;
   Risk: string;
   Description: string;
   RiskReduceMeasure: string;
   RiskMatrix1: number;
   RiskMatrix2: number;
   RiskMatrix3: number;
   CCPCBR0: boolean;
   CCPCBR1: boolean;
   CCPCBR2: boolean;
   CCPCBR3: boolean;
   CCPCBR4: boolean;
   YesOrNo: number;
   CCPCBR5: string;
   Position: number;
   Status: number;
}