export class ElectroLAR {
    Id: number;    
    BusinessId: number;
    ApplicationId: number;
    Title: string;
    Url: string;
    IsLaws: boolean;    
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    constructor() {
        this.Id = 0;
        this.Status = 1;       
    }
}