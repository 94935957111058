import { Component } from '@angular/core';

@Component({
    template: `<router-outlet></router-outlet>`,
})

export class ELComponent {
    constructor() { }
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}

