import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class UserService {
    _employeeContactUser: Observable<any> = null!;
    constructor(private http: HttpClient) { }
    clearCachedUserList() {
        this._employeeContactUser = null!;
    }
    getAllUsers() {
        return this.http
            .get(environment.BASE_URL + '/users', BaseServices.headerOption)
            .map(result => result);
    }
    getUser(id: number) {
        return this.http.get(environment.BASE_URL + '/project/ContactLookup/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getDeviationCreatedByUsers() {
        return this.http
            .get<any>(
            environment.BASE_URL + '/createdbyusers/' +
            BaseServices.BusinessId,
            BaseServices.headerOption
            )
            .map(result => result);
    }

    /**
     * get assigned user
     * @param appId {number}
     * @param businessId {number}
     */
    getAssignedUsers(appId: number, businessId: number) {
        return this.http
            .get<any[]>(`${environment.BASE_URL}/users/${appId}/${businessId}`, BaseServices.headerOption)
            .map(result => result);
    }

    updateLanguage(langId: number) {
        return this.http
            .get(
            environment.BASE_URL + '/user/modify/language/' + langId,
            BaseServices.headerOption
            )
            .map(result => {
                return result;
            });
    }

    getUserProfile() {
        return this.http
            .get<HttpHeaderResponse>(
            environment.BASE_URL + '/user/profile',
            BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    getUsersById(id: number) {
        return this.http
            .get<any[]>(
            environment.BASE_URL + '/user/' + id,
            BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }

    updateUser(id: number, data: any) {
        return this.http
            .put<HttpHeaderResponse>(
            environment.BASE_URL + '/user/' + id,
            data,
            BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    /**
     * update signature image for a user
     * @param id {int}
     * @param imageUrl {string}
     */
    updateSignatureImage(data:any) {
        return this.http
            .put<HttpHeaderResponse>(
            environment.BASE_URL + '/update-signature-image/',
            data,
            BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    getLanguages() {
        return this.http
            .get(environment.BASE_URL + '/master/languages', BaseServices.headerOption)
            .map(result => result);
    }

    getRoles() {
        return this.http
            .get<HttpHeaderResponse>(environment.BASE_URL + '/master/roles', BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    addusers(data: any) {
        return this.http
            .post<HttpHeaderResponse>(
            environment.BASE_URL + '/user',
            data,
            BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
    getUsersByBusiness(businessId: number) {
        return this.http
            .get<any>(
            environment.BASE_URL + '/userbybusiness/' + businessId,
            BaseServices.headerOption
            )
            .map(result => result);
    }

    /**
     * get employees/contacts with userid
     * @param businessId {number}
     */
    getEmployeeContactUsers(businessId: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/employeecontactusers/' + businessId,
            BaseServices.headerOption).map(result => result);
    }


    getEmployeeOnly(businessId: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/employeeOnly/' + businessId,
            BaseServices.headerOption).map(result => result);
    }

    /**
     * get employees/contacts with userid
     * @param businessId {number}
     */
    getEmployeeContactConveners(businessId: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/employeecontactconveners/' + businessId,
            BaseServices.headerOption).map(result => result);
    }

    /**
    * get employees/contacts subscription user with userid
    * @param businessId {number}
    */
    getEmployeeContactSubscriptionUsers(businessId: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/employeecontactsubscription/' + businessId, BaseServices.headerOption)
            .map(result => result);
    }

    getUserExits(featureId: number, featureKey: number) {
        return this.http
            .get<HttpHeaderResponse>(`${environment.BASE_URL}/userexits/${BaseServices.ApplicationId}/${featureId}/${featureKey}`, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    ruhmanagerdepartments(UserId:number){
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL +'/ruhmanagerdepartments/' + BaseServices.BusinessId + '/' + BaseServices.ApplicationId + '/' + UserId, BaseServices.headerOption)
        .map((response:HttpHeaderResponse)=>{
            return response;
        })
    }
}
