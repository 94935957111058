<div class="main-content">
    <div class="main-heading">
        <strong><span translate>FOLLOW_UP</span>{{ pageTitle | capitalize}}</strong>
    </div>
    <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="fId"></ecpl-tab-navigation>
    <div class="card">
        <div class="card-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>