import { TreeData } from './index';
export class TreeView {
    id: number;
    type: string;
    name: string;
    data: TreeData[];
    isEditMode: boolean;
    isCommonMode: boolean;
}

export class NodeView {
    typeName: string;
    typeId:number;
    data: any;
}

