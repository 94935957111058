import { AuthGuard } from '../../_guards/index';
import { Route, RouterModule } from '@angular/router';

import { CommonViewComponent } from './../common/components/commonView.component';
import {
    VehicleControlListResolver,
    VehicleControlEditResolver,
    VehicleControlListByVehicleResolver
} from './services/vehicle-control.resolver.service';
import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';

import { VehicleControlListComponent } from './components/vehicle-control-list.component';
import { VehicleControlFormComponent } from './components/vehicle-control-form.component';
import { VehicleControlCompleteComponent } from './components/vehicle-control-complete.component';
import { VehicleControlComponent } from './vehicle-control.component';
import { VehiclesCompleteChecklistComponent } from '../shared/kuba-complete-checklist/kuba-vehicles-complete-checklist.component';
export const vehicleControlRoutes: Route[] = [
    {
        path: 'vehicle-control',
        component: VehicleControlComponent,
        children: [
            {
                path: 'list',
                component: VehicleControlListComponent,
                resolve: {
                    list: VehicleControlListByVehicleResolver
                }
            },
            {
                path: 'edit/:id',
                component: VehicleControlFormComponent,
                resolve: {
                    details: VehicleControlEditResolver
                }
            },
            {
                path: 'complete',
                component: VehicleControlCompleteComponent
            },
            {
                path: 'vehicle-complete-checklist/incomplete/:clId',
                component: VehiclesCompleteChecklistComponent,
            },
            {
                path: 'details/:id',
                component: CommonViewComponent,
                data: { parent: 'VEHICLE' },
                canActivate: [AuthGuard],
                children: [...deviationRoutes, ...timetableRoutes]
            }
        ]
    }
];
