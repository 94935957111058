
import { ProjectEditResolver } from './../projects/services/project.resolver';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import { TimetableComponent } from './timetable.component';
import { TimetableListComponent } from './components/timetable-list/timetable-list.component';
import { CreateTimetableComponent } from './components/new-timetable/new-timetable.component';
import { EditTimetableComponent } from './components/edit-timetable/edit-timetable.component';
import {
    TimeTableEditResolver,
    TimeTableListResolver,
    TypeOfWorkDropdownResolver,
    EmployeeDropdownResolver,
    EmployeeContactUserResolver,
    ProjectDropdownResolver,
    ProjectAndZoneDropdownResolver
} from './services/timetable.resolver.service';
import { ApprovalPersonResolver } from '../employees/services/employeeleave.resolver.service';
export const timetableRoutes: Route[] = [
    {
        path: 'timetable',
        component: TimetableComponent,
        children: [
            {
                path: 'list',
                component: TimetableListComponent,
                resolve: {
                    list: TimeTableListResolver,
                    employee: EmployeeDropdownResolver
                },
            },
            {
                path: 'new',
                component: CreateTimetableComponent,
                resolve: {
                    typeOfWork: TypeOfWorkDropdownResolver,
                    employee: EmployeeDropdownResolver,
                    approvalPerson: ApprovalPersonResolver,
                    project: ProjectAndZoneDropdownResolver
                }
            },
            {
                path: 'edit/:tid',
                component: EditTimetableComponent,
                resolve: {
                    edit: TimeTableEditResolver,
                    typeOfWork: TypeOfWorkDropdownResolver,
                    employee: EmployeeDropdownResolver,
                    approvalPerson: ApprovalPersonResolver,
                    project: ProjectAndZoneDropdownResolver
                },
            },

        ]
    }
];