import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'sickness',
   templateUrl: 'sickness.component.html'
})
export class SicknessComponent implements OnInit {


    constructor() {}

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}
