import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { CopyManualFilesComponent } from './copyManualFiles.component';
import { EcplDocumentTreeModule } from '../../shared/ecpl-document-tree/ecpl-document-tree.module';
import { TooltipModule } from 'primeng/tooltip'
@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        AppSharedModule,
        RouterModule,
        EcplDocumentTreeModule,
        TooltipModule
    ],
    exports: [
        CopyManualFilesComponent
    ],
    declarations: [
        CopyManualFilesComponent
    ],
    providers: []
})
export class CopyManualFilesModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CopyManualFilesModule
        };
    }
}
