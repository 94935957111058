import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from 'src/app/kuba/shared';
import { TaskManagerNoRecordsComponent } from 'src/app/kuba/shared/taskmanagernorecords/taskmanagernorecords.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AppSharedModule
    ],
    exports: [TaskManagerNoRecordsComponent],
    declarations: [TaskManagerNoRecordsComponent],
    providers: [],
})
export class TaskManagerNoRecordsModule {

}
