import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { FeatureDocumentComponent } from './feature-document.component';
import { DocumentListResolver } from 'src/app/kuba/feature-documents/feature-document.service.resolver';
export const FeatureDocumentRoutes: Route[] = [
    {
        path: 'document/list',
        component: FeatureDocumentComponent,
        resolve: {
            list: DocumentListResolver
        }
    }];
