import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Component({

    template: `<router-outlet></router-outlet>`
})
export class VehicleTrackingComponent { 
     constructor() {
    }
}
