export class FoodControl {
    Id: number;
    FoodSafetyId: number;
    ControlId: number;
    ObjectName: string;
    TypeofObject: number;
    Brand: number;
    Model: number;
    Temperature: number;
    Placed: string;
    Description: string;
    Status: string;
    BusinessId: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    OrderNumber: number;
    FoodControlDocument: FoodControlDocumentDto[];
    MinTemp: string;
    MaxTemp: string;
}

export class FoodControlDocumentDto {
    Id: number;
    FoodControlId: number;
    Filename: string;
    OriginalFileName: string;
    Path: string;
    MimeType: string;
    Size: number;
    FileType: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}
export class ObjectDto {
    Id: number;
    Name: string;
    BusinessId: number;
    CreatedBy: Date;
    ModifiedBy: number;
}

abstract class KeyAndValue {
    label: number;
    value: string;
}

export class TypeOfObject extends KeyAndValue { }

export class Model extends KeyAndValue { }

export class Brand extends KeyAndValue { }

export class Temperature extends KeyAndValue { }

export class Repetition
{
    RepetitionId: number;
    Value: string;
}
