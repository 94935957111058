export class EmployeeLeaveAvailability {
  Id: number;
  BusinessId: number;
  Name: string;
  EndEmployeeIdDate: string;
  Year: number;
  LeaveAvailable: number;
  LeaveTaken: number;
  Status: string;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  EmployeeId: number;
}
export class EmployeeHoliday {
  Id: number;
  BusinessId: number;
  HolidayName: string;
  HolidayDate: string;
  Status: string;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
}
