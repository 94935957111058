import { filter } from 'rxjs/operator/filter';
import { ValidationService } from './../../shared/services/validation.service';
import { UserService } from './../../users/services/user.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
    FormArray
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { FeatureKey } from './../../../_models/feature';
import { NotificationSubscription } from './../../../shared/employee-contact';
import {
    UploadOutput,
    UploadFile,
    UploaderOptions,
    UploadInput,
    UploadStatus
} from 'ngx-uploader';
import { environment } from 'src/environments/environment';
import { Responsibilty } from './../models/responsibility';
import { BaseServices } from './../../kuba.services';
import { Options } from './../../../_models/options';
import { HelperService } from './../../../_services/helper.service';
import {
    ContactPerson,
    BusinessProperty,
    ProjectResponsibility
} from './../models/projectEdit';
import { ProjectUsers, SubContractorUser } from './../models/projectEdit';
import { Client } from '../../client/models/client';
import { SelectItem } from 'primeng/api';
import * as _ from 'lodash';
import {
    ProjectEdit,
    ProjectNameListing,
    SubContractor,
    User,
    ClientUser
} from './../models/index';
import { ProjectServices } from './../services/project.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Rights } from 'src/app/_models';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    template: ''
  })
  
export class MyModel {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    leftNav:any;
    subContract:boolean=false;
    client:boolean=false;
    projectName: string;
    cities: SelectItem[];
    ChooseProject: SelectItem[];
    Name: SelectItem[];
    ContactPerson: SelectItem[];
    Client: SelectItem[];
    ClientList: any;
    ProfessionalManager: SelectItem[];
    ResponsibleforApplication: SelectItem[];
    ResponsibleforProjectPlanning: SelectItem[];
    ProjectManager: SelectItem[];
    ResponsibleforExecution: SelectItem[];
    ResponsibleforIndependentControl: SelectItem[];
    Safetyrepresentatives: SelectItem[];
    Constructionmanager: SelectItem[];
    Coordinatorprojecting: SelectItem[];
    Coordinatorexecution: SelectItem[];
    BuilderSelect: SelectItem[];
    projectUser: SelectItem[];
    projectContractor: SelectItem[];
    cproject: any;
    SubContractorData: SelectItem[];
    cperson: any;
    Contractor: string[] = [];
    selectedContactPerson = new User();
    selectedContractor: number[] = [];
    selectedManager = new User();
    selectedProjectManager = new User();
    selectedResponsibleForApp = new User();
    selectedResponsibleForProjectPlanning = new User();
    selectedResponsibleForExecution = new User();
    selectedResponsibleForIndependentControl = new User();
    selectedSafetyRepresentative = new User();
    selectedConstructionManager = new User();
    selectedCordinatorProjecting = new User();
    selectedCordinatorExecution = new User();
    selectedBuilder = new SubContractor();
    selectedEmailSubscriber = [];
    selectedSMSSubscriber = [];
    selectedProject = new ProjectNameListing();
    selectedResponsibility = new Responsibilty();
    projectImageUploadUrl: any;
    projectImage: string;
    isOpen = false;
    createdBy: number;
    isFileChoosed = false;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    option: UploaderOptions;
    isAdministrateProject = true;
    isUser: boolean;
    loading: boolean;
    businessProperty = new BusinessProperty();
    isClientSelected = false;
    isSubContractorSelected = false;
    isAhlsell = false;
    createdOn: any;
    emailSMSUsers: any[];
    bindAllUsers = false;
    bindSelectedUsers = true;
    selectedEmailSMSSubscriber = [];
    smsSubscriber = [];
    public selectedFiles;
    editClient: boolean = false;
    editContact: boolean = false;
    checkedAll:boolean;
}

@Component({
    selector: 'project-form',
    templateUrl: 'project-form.component.html'
})

export class ProjectFormComponent extends MyModel implements OnInit {
    IsHidden = false;
    newProjectForm: FormGroup;
    projects: any;
    projectId: number;
    uploadRequest: any;
    businessPropertyId: any;
    console: Options;
    projectUsers: any[];
    clientUsers: ClientUser[];
    Responsibility: any[];
    projectSubContractor: SubContractor[];
    allCategories: FormArray = new FormArray([]);
    selectedUser: any[];
    selectedClient: any[];
    // selectedContractor: any[];
    selectedContractorOption: any[];
    options = [
        //new Options(7, 'NEW'),
        new Options(8, 'OPEN'),
        new Options(9, 'DONE'),
        new Options(10, 'REJECTED')
    ];
    enableButton = true;
    values: number[];
    userSubscribers: SelectItem[];
    emailSMSSubscribers: SelectItem[];
    selectedSubscribers: SelectItem[];
    // TODO: value to be assigned
    locale: string;
    isHideProject = true;
    userId: number;
    applicationId: number;
    uploadStart: boolean;
    isFDVDocumentation = false;
    isVVSFDVDocumentation = false;
    isELFeatureId: any;
    isVVSFeatureId: any;
    private subscriptions: Subscription[] = [];
    /**
     * Date picker configuration option
     */
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };

    public endDateOptions: IMyDpOptions = this.startDateOptions;
    /**
     * constructor
     * @param formBuilder {{FormBuilder}}
     * @param location {{Location}}
     * @param projectService {{ProjectServices}}
     * @param activatedRoute {{ActivatedRoute}}
     * @param router {{Router}}
     * @param userService {{UserService}}
     * @param clientService {{ClientServices}}
     */
    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private router: Router,
        private projectService: ProjectServices,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private userService: UserService,
        private translate: TranslateService
    ) {
        super(); // this.initForm();
        this.applicationId = BaseServices.ApplicationId;
        this.newProjectForm = this.formBuilder.group({
            Number: ['', Validators.required],
            Title: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ],
            StartDate: ['', Validators.required],
            ExpectedEndDate: ['', Validators.required],
            OrderNumber: [''],
            SSBNumber: [''],
            Address: [''],
            Zip: [''],
            City: [''],
            Status: [8],
            Propertyno: [''],
            Groundno: [''],
            ProjectCost: [''],
            Description: [''],
            ChooseProject: [],
            SelectedContactPerson: [[]],
            SubContractorUsers: [[]],
            Client: [],
            ContractorValues: [[]],
            selectedManager: [[]],
            selectedProjectManager: [[]],
            selectedResponsibleForApp: [[]],
            ProjectPlan: [''],
            selectedResponsibleForExecution: [[]],
            IndependentControl: [''],
            selectedSafetyRepresentative: [[]],
            selectedConstructionManager: [[]],
            CoordinatorProjecting: [''],
            selectedCordinatorExecution: [[]],
            ProjectBuilder: [''],
            selectedEmailSubscriber: [[]],
            selectedEmailSMSSubscriber: [[]],
            selectedSMSSubscriber: [[]],
            smsSubscriber: [[]],
            Responsibility: this.allCategories,
            // CopySJA: [''],
            CopySJA: false,
            CopySJAForm: false,
            FDVDocumentation: [''],
            ELFourdigit: [''],
            ELForvaltning: [''],
            VVSFDVDocumentation: [''],
            VVSFourdigit: [''],
            VVSForvaltning: [''],
            isFDVDocumentationChecked: [false],
            isFDVBuildingChecked: [false],
            isFDVManagementChecked: [false],
            isFDVVVSDocumentationChecked: [false],
            isFDVVVSBuildingChecked: [false],
            isFDVVVSManagementChecked: [false],
            isShowDeviationChecked: [false]
        });
        this.uploadInput = new EventEmitter<UploadInput>();
        this.onAdministrateProject();
    }

    /**
     * bind dropdowns
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.userId = +BaseServices.UserId;
        if (BaseServices.ApplicationId === 4) {
            this.isAhlsell = true;
            let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
            this.isELFeatureId = (allAdditionalFeatures.filter(x => (x.id === 174 && x.checked == true))).length > 0 ? true : false
            this.isVVSFeatureId = (allAdditionalFeatures.filter(x => (x.id === 175 && x.checked == true))).length > 0 ? true : false
        }
        if (BaseServices.UserRole === 'Guest') {
            this.isAdministrateProject = false;
            this.isHideProject = false;
        }

        let url1 = window.location.href
        let urlArray1 = url1.split('/')
        let data1 = urlArray1[urlArray1.length-2]
        let dataValues = data1.split(/([0-9]+)/)
        this.projectId = this.activatedRoute.snapshot.params['id'];
        if(this.projectId != null){
            this.editClient = true;
            this.editContact = true;
        }
        this.projectImageUploadUrl =
            environment.BASE_URL +
            '/file/upload/business/logo/' +
            BaseServices.ApplicationId +
            '/' +
            this.projectId;
        this.Client = [];
        this.console = this.options.filter(item => item.id === 1)[0];
        this.ChooseProject = [];
        this.Name = [];
        this.ContactPerson = [];
        this.SubContractorData = [];
        this.ProfessionalManager = [];
        this.ProjectManager = [];
        this.ResponsibleforApplication = [];
        this.ResponsibleforExecution = [];
        this.Safetyrepresentatives = [];
        this.Constructionmanager = [];
        this.Coordinatorexecution = [];
        this.projectUser = [];
        this.clientUsers = [];
        this.userSubscribers = [];
        this.selectedSubscribers = [];
        let clients = this.activatedRoute.snapshot.data['clients'];
        if (clients) {
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.Client = [];
                this.Client.push(
                    { label: val.SELECT, value: null }
                );
                clients.forEach((element: any) => {
                    this.Client.push({ label: element.Name, value: element.Id });
                });
            }));
        }

        let projectName = this.activatedRoute.snapshot.data['projectName']
        if (projectName) {
            this.subscriptions.push(this.translate.stream('SELECT_PROJECT').subscribe(val => {
                this.ChooseProject = [];
                this.ChooseProject.push(
                    { label: val.SELECT_PROJECT, value: null }
                );
                projectName.forEach((element: any) => {
                    this.ChooseProject.push({
                        label: element.Name,
                        value: element.Id
                    });
                });
            }));
        }

        let users = this.activatedRoute.snapshot.data['users'];
        this.projectUsers = users;

        let subContractor = this.activatedRoute.snapshot.data['subcontractors'];
        if (subContractor) {
            subContractor.forEach((element: any) => {
                this.SubContractorData.push({
                    label: element.Name,
                    value: element.ContractorId
                });
            });
        }

        let contact = <any[]>this.activatedRoute.snapshot.data[
            'users_subscription'
        ];
        if (contact) {
            contact.forEach(element => {
                this.ContactPerson.push({
                    label: element.Name,
                    value: element.Id
                });
                this.ResponsibleforApplication.push({
                    label: element.Name,
                    value: element.Id
                });
                this.Constructionmanager.push({
                    label: element.Name,
                    value: element.Id
                });
                this.Coordinatorexecution.push({
                    label: element.Name,
                    value: element.Id
                });

                this.ResponsibleforExecution.push({
                    label: element.Name,
                    value: element.Id
                });
                this.Safetyrepresentatives.push({
                    label: element.Name,
                    value: element.Id
                });
                this.ProjectManager.push({
                    label: element.Name,
                    value: element.Id
                });
                this.ProfessionalManager.push({
                    label: element.Name,
                    value: element.Id
                });
            });
        }

        let result = this.activatedRoute.snapshot.data['users'];
        this.cperson = result;
        if (this.cperson) {
            this.cperson.forEach((element: any) => {
                this.projectUser.push({
                    label: element.Name,
                    value: element.Id
                });
            });
        }

        let userSubscription = this.activatedRoute.snapshot.data[
            'users_subscription'
        ];
        this.emailSMSUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: {
                            Id: element.Id,
                            FeatureKey: element.FeatureKey,
                            FeatureId: element.FeatureId
                        }
                    });
                    if (element.Id === BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: {
                                Id: element.Id,
                                FeatureKey: element.FeatureKey,
                                FeatureId: element.FeatureId
                            }
                        });
                    }
                });
            }
        }));

        let res = this.activatedRoute.snapshot.data['edit'];
        if (res) {
            this.createdOn = res.CreatedOn;
            this.createdBy = res.CreatedBy;
            this.initForm(res);
            if (res.Status === 9) {
                this.newProjectForm.disable();
                this.newProjectForm.controls['Status'].enable();
            }
        } else {
            this.initForm();
        }

        this.leftNav = JSON.parse(sessionStorage.getItem('leftNav'));
        let filteredTemplate = HelperService.filterTree('Sub Contractor', this.leftNav.filter(x => {
            if ((x.name === "SubContractor"  && x.checked == true) || (x.name="Sub Contractor"   && x.checked == true)) {
                this.subContract = true;
            }
        }), 'IsSubContractor');

        let filteredTemplateClient = HelperService.filterTree('Sub Contractor', this.leftNav.filter(x => {
            if (x.translationKey === "CLIENT"  && x.checked == true) {
                this.client = true;
            }
        }), 'IsClient');
        
    }

    /**
     * Event handler for Start date
     * @param event
     */
    onStartDateChanged(event: IMyDateModel) {
        this.endDateOptions = this.setDateInput(
            this.endDateOptions,
            event,
            'startDate'
        );
    }

    /**
     * Event handler for End date
     * @param event
     */
    onEndDateChanged(event: IMyDateModel) {
        this.startDateOptions = this.setDateInput(
            this.startDateOptions,
            event,
            'endDate'
        );
    }

    /**
     * Method for setting date range
     * @param dateConfig
     * @param event
     * @param type
     */
    setDateInput(
        dateConfig: IMyDpOptions,
        event: IMyDateModel,
        type: string
    ): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }

    initForm(data?: any) {
        let Number = '';
        let Title = '';
        let StartDate: any;
        let ExpectedEndDate: any;
        let OrderNumber = '';
        let SSBNumber = '';
        let Address = '';
        let City = '';
        let Zip = '';
        let Propertyno = '';
        let Groundno = '';
        let ProjectCost = '';
        let Description = '';
        let Contactperson: any[] = [];
        let SubContractorUsers = '';
        let ProjectPlan = '';
        let IndependentControl = '';
        let CoordinatorProjecting = '';
        let ProjectBuilder = '';
        let ChooseProject;
        let client;
        let ContractorValues: any[] = [];
        let selectedResponsibleForApp: any = [];
        let selectedManager: any = [];
        let selectedProjectManager: any[] = [];
        let selectedResponsibleForExecution: any = [];
        let selectedSafetyRepresentative: any = [];
        let selectedConstructionManager: any = [];
        let selectedCordinatorExecution: any = [];
        let selectedEmailSubscriber = this.selectedEmailSubscriber;
        let selectedSMSSubscriber = this.selectedSMSSubscriber;
        let Status = '8';
        let CopySJA = false;
        let CopySJAForm = false;
        let isShowDeviationChecked = false;
        let selectedEmailSMSSubscriber = this.selectedEmailSMSSubscriber;
        let smsSubscriber = this.smsSubscriber;
        this.Responsibility = this.activatedRoute.snapshot.data[
            'reponsibilities'
        ];

        this.checkAll(false);
        if (this.Responsibility) {
            for (let i = 0; i < this.Responsibility.length; i++) {
                let fg = new FormGroup({});
                let isChecked = false;
                if (data) {
                    isChecked =
                        data.ProjectResponsibility.findIndex(
                            (x: any) =>
                                x.ProjectResponsibilityId ===
                                this.Responsibility[i].Id
                        ) > -1;
                }

                fg.addControl(
                    `${this.Responsibility[i].Id}`,
                    new FormControl(isChecked)
                );
                this.allCategories.push(fg);
            }
        }

        if (data) {
            if (data.ProjectTemplateId > 0) {
                this.IsHidden = true;
            }
            this.projectImage = data.ProjectImage ? data.ProjectImage : null;
            this.projectName = data.Title;
            this.businessPropertyId = data.BusinessPropertyId
                ? data.BusinessPropertyId
                : 0;
            Number = data.Number;
            Title = data.Title;
            StartDate = HelperService.formatInputDate(
                data.StartDate ? data.StartDate : null
            );
            let startdate: Date = HelperService.formatDate(data.StartDate)
                ? new Date(HelperService.formatDate(data.StartDate))
                : null;
            ExpectedEndDate = HelperService.formatInputDate(
                data.ExpectedEndDate ? data.ExpectedEndDate : null
            );
            let enddate: Date = HelperService.formatDate(data.ExpectedEndDate)
                ? new Date(HelperService.formatDate(data.ExpectedEndDate))
                : null;
            OrderNumber = data.OrderNumber;
            SSBNumber = data.Ssbnumber;
            Address = data.BusinessPropertyDto
                ? data.BusinessPropertyDto.Address
                : '';
            City = data.BusinessPropertyDto
                ? data.BusinessPropertyDto.City
                : '';
            Zip = data.BusinessPropertyDto ? data.BusinessPropertyDto.Zip : '';
            Propertyno = data.BusinessPropertyDto
                ? data.BusinessPropertyDto.ApartmentCount
                : '';
            Groundno = data.BusinessPropertyDto
                ? data.BusinessPropertyDto.FloorCount
                : '';
            ProjectCost = data.ProjectCost;
            Description = data.Description;
            ProjectPlan = data.ProjectPlan;
            IndependentControl = data.IndependentControl;
            CoordinatorProjecting = data.CoordinatorProjecting;
            ProjectBuilder = data.ProjectBuilder;
            ChooseProject = data.ProjectTemplateId;

            if (data.ContactPerson) {
                Contactperson = [];
                data.ContactPerson.forEach((element: any) => {
                    Contactperson.push(element.ContactPersonId);
                });
            }
            SubContractorUsers = data.SubContractor;
            client = data.Client ? data.Client : '';
            if (data.Client) {
                this.selectedClient = [];
                if (data.ClientUsers.length > 0) {
                    this.isClientSelected = true;
                    if (data.SelectedClientUsers.length > 0) {
                        data.SelectedClientUsers.forEach(element => {
                            this.selectedClient.push(element);
                        });
                    }
                    this.clientUsers = data.ClientUsers;
                } else {
                    if (data.Client) {
                        this.getContactByClientId(data.Client);
                    } else {
                        this.clientUsers = [];
                    }
                }
            }
            if (data.Contractor) {
                // bind subcontractor  dropdown for existing record
                data.Contractor.forEach((element: any) => {
                    ContractorValues.push(element.ContractorId);
                });
                this.selectedContractor = [];
                if (data.SubContractorUsers.length > 0) {
                    this.isSubContractorSelected = true;
                    if (data.SelectedSubContractorUsers.length > 0) {
                        data.SelectedSubContractorUsers.forEach((element: any) => {
                            this.selectedContractor.push(element);
                        });
                    }
                    this.projectSubContractor = data.SubContractorUsers;
                } else {
                    // bind subcontractor user list for selected subcontractor with no user selected
                    let contractorId: any = [];
                    this.isSubContractorSelected = true;
                    data.Contractor.forEach((element: any) => {
                        contractorId.push(element.ContractorId);
                    });
                    this.subscriptions.push(this.projectService
                        .getContractorContactuser(contractorId)
                        .subscribe((result:any) => {
                            if (result) {
                                this.projectSubContractor = result;
                                this.isSubContractorSelected = true;
                            }
                        }));
                }
            }
            if (data.ApplicationResponsible) {
                data.ApplicationResponsible.forEach((element: any) => {
                    selectedResponsibleForApp.push(
                        element.ApplicationResponsibleId
                    );
                });
            }
            if (data.ProfessionalManager) {
                data.ProfessionalManager.forEach((element: any) => {
                    selectedManager.push(element.ProfessionalManagerId);
                });
            }
            if (data.ProjectManager) {
                selectedProjectManager = [];
                data.ProjectManager.forEach((element: any) => {
                    selectedProjectManager.push(element.ProjectManagerId);
                });
            }
            if (data.ProjectExecution) {
                data.ProjectExecution.forEach((element: any) => {
                    selectedResponsibleForExecution.push(
                        element.ProjectExecutionId
                    );
                });
            }
            if (data.ProjectEmailSubscribers) {
                data.ProjectEmailSubscribers.forEach((element: any) => {
                    this.selectedEmailSubscriber.push({
                        Id: element.Id,
                        FeatureKey: element.FeatureKey,
                        FeatureId: element.FeatureId
                    });
                    this.selectedEmailSMSSubscriber.push({
                        Id: element.Id,
                        FeatureKey: element.FeatureKey,
                        FeatureId: element.FeatureId
                    });
                });
            }
            if (data.ProjectSMSSubscribers) {
                data.ProjectSMSSubscribers.forEach((element: any) => {
                    this.selectedSMSSubscriber.push({
                        Id: element.Id,
                        FeatureKey: element.FeatureKey,
                        FeatureId: element.FeatureId
                    });
                    this.smsSubscriber.push({
                        Id: element.Id,
                        FeatureKey: element.FeatureKey,
                        FeatureId: element.FeatureId
                    })
                });
            }
            if (data.SafetyRepresentatives) {
                data.SafetyRepresentatives.forEach((element: any) => {
                    selectedSafetyRepresentative.push(
                        element.SafetyRepresentativesId
                    );
                });
            }
            if (data.ConstructionManager) {
                data.ConstructionManager.forEach((element: any) => {
                    selectedConstructionManager.push(
                        element.ConstructionManagerId
                    );
                });
            }
            if (data.CoordinatorExecution) {
                data.CoordinatorExecution.forEach((element: any) => {
                    selectedCordinatorExecution.push(
                        element.CoordinatorExecutionId
                    );
                });
            }
            Status = (data.Status=='7')?'8':data.Status;
            CopySJA = data.CopySJA;
            CopySJAForm = data.CopySJAForm;
            this.selectedUser = [];
            if (data.ProjectUsers) {
                data.ProjectUsers.forEach((element: any) => {
                    let selectedUsers = this.projectUsers.find(
                        x => x.Id === element.UserId
                    );
                    if (selectedUsers) {
                        selectedUsers.status = true;
                        this.checked(selectedUsers);
                    }
                });
            }
            let isFDVDocumentationChecked = false;
            let isFDVBuildingChecked = false;
            let isFDVManagementChecked = false;
            let isFDVVVSDocumentationChecked = false;
            let isFDVVVSBuildingChecked = false;
            let isFDVVVSManagementChecked = false;
            isShowDeviationChecked = data.ShowDeviation;
            let projectFdvStatus = this.activatedRoute.snapshot.data[
                'projectFdvStatus'
            ];
            if (projectFdvStatus) {
                if (projectFdvStatus.IsELThreedigit) {
                    isFDVDocumentationChecked = projectFdvStatus.IsELThreedigit;
                    isFDVBuildingChecked = projectFdvStatus.IsELFourdigit;
                    isFDVManagementChecked = projectFdvStatus.IsELForvaltning;
                    this.isFDVDocumentation = projectFdvStatus.IsELThreedigit;
                } else {
                    isFDVDocumentationChecked = false;
                    isFDVBuildingChecked = false;
                    isFDVManagementChecked = false;
                    this.isFDVDocumentation = projectFdvStatus.IsELThreedigit;
                }

                if (projectFdvStatus.ISVVSThreedigit) {
                    isFDVVVSDocumentationChecked = projectFdvStatus.ISVVSThreedigit;
                    isFDVVVSBuildingChecked = projectFdvStatus.ISVVSFourdigit;
                    isFDVVVSManagementChecked = projectFdvStatus.IsVVSForvaltning;
                    this.isVVSFDVDocumentation = projectFdvStatus.ISVVSThreedigit;
                } else {
                    isFDVVVSDocumentationChecked = false;
                    isFDVVVSBuildingChecked = false;
                    isFDVVVSManagementChecked = false;
                    this.isVVSFDVDocumentation = projectFdvStatus.ISVVSThreedigit;
                }
            }
            this.newProjectForm = this.formBuilder.group({
                Number: new FormControl(Number),
                Title: new FormControl(Title),
                StartDate: new FormControl(StartDate),
                ExpectedEndDate: new FormControl(ExpectedEndDate),
                OrderNumber: new FormControl(OrderNumber),
                SSBNumber: new FormControl(SSBNumber),
                Address: new FormControl(Address),
                City: new FormControl(City),
                Zip: new FormControl(Zip),
                Propertyno: new FormControl(Propertyno),
                Groundno: new FormControl(Groundno),
                ProjectCost: new FormControl(ProjectCost),
                Description: new FormControl(Description),
                SelectedContactPerson: new FormControl(Contactperson),
                SubContractorUsers: new FormControl(SubContractorUsers),
                Responsibility: this.allCategories,
                ChooseProject: new FormControl(ChooseProject),
                Client: new FormControl(client),
                ContractorValues: new FormControl(ContractorValues),
                ProjectPlan: new FormControl(ProjectPlan),
                IndependentControl: new FormControl(IndependentControl),
                CoordinatorProjecting: new FormControl(CoordinatorProjecting),
                ProjectBuilder: new FormControl(ProjectBuilder),
                selectedResponsibleForApp: new FormControl(
                    selectedResponsibleForApp
                ),
                selectedManager: new FormControl(selectedManager),
                selectedProjectManager: new FormControl(selectedProjectManager),
                selectedResponsibleForExecution: new FormControl(
                    selectedResponsibleForExecution
                ),
                selectedSafetyRepresentative: new FormControl(
                    selectedSafetyRepresentative
                ),
                selectedConstructionManager: new FormControl(
                    selectedConstructionManager
                ),
                selectedCordinatorExecution: new FormControl(
                    selectedCordinatorExecution
                ),
                selectedEmailSubscriber: new FormControl(
                    selectedEmailSubscriber
                ),
                selectedEmailSMSSubscriber: new FormControl(selectedEmailSMSSubscriber),
                selectedSMSSubscriber: new FormControl(selectedSMSSubscriber),
                smsSubscriber: new FormControl(smsSubscriber),
                Status: new FormControl(Status),
                CopySJA: new FormControl(CopySJA),
                CopySJAForm: new FormControl(CopySJAForm),

                isFDVDocumentationChecked: new FormControl(isFDVDocumentationChecked),
                isFDVBuildingChecked: new FormControl(isFDVBuildingChecked),
                isFDVManagementChecked: new FormControl(isFDVManagementChecked),
                isFDVVVSDocumentationChecked: new FormControl(isFDVVVSDocumentationChecked),
                isFDVVVSBuildingChecked: new FormControl(isFDVVVSBuildingChecked),
                isFDVVVSManagementChecked: new FormControl(isFDVVVSManagementChecked),
                isShowDeviationChecked: new FormControl(!isShowDeviationChecked)
            });
        }
    }
    filesSelect(selectedFiles: any): void {
        this.uploadStart = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/project/file/upload/project-image/' +
                BaseServices.ApplicationId +
                '/' +
                this.projectId,
                formData,{headers:headers}
            )
            .map((response:any) => {
                this.uploadStart = false;
                let res = response;
                this.projectImage = res.path;
                this.loading = false;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    /**
     * file upload
     */
    onUpload(event: any) {
        let responce = JSON.parse(event.xhr.response);
        this.projectImage = responce.Result.Uri;
    }

    onClientChanged(event: any) {
        if (event.value) {
            this.getContactByClientId(event.value);
        } else {
            this.clientUsers = [];
        }
    }

    getContactByClientId(clientId: number) {
        this.subscriptions.push(this.projectService.getActiveClientContactUser(clientId).subscribe((result: any) => {
            if (result) {
                this.clientUsers = [];
                result.forEach(element => {
                    this.clientUsers.push(
                        {
                            Name: element.Name,
                            ClientUserId: element.ClientUserId,
                            ClientId: element.ClientId,
                            ProjectId: this.projectId
                        }
                    );
                });
                this.isClientSelected = true;
            }
        }));
    }

    onSubContractorChanged(e: any) {
        let subContractorId: any = [];
        if (e.value.length > 0) {
            e.value.forEach((element: any) => {
                subContractorId.push(element);
            });
            this.subscriptions.push(this.projectService
                .getContractorContactuser(subContractorId)
                .subscribe((result: any) => {
                    if (result) {
                        this.isSubContractorSelected = true;
                        this.projectSubContractor = result;
                    }
                }));
        } else {
            this.projectSubContractor = [];
        }
    }

    /**
     * clear form data
     */
    clear() {
        this.checkAll(false);
        this.newProjectForm.reset();
        this.isClientSelected = false;
        this.isSubContractorSelected = false;
        this.selectedUser = [];
        this.projectId = 0;
        this.projectImage = '';
        this.subscriptions.push(this.userService
            .getEmployeeContactUsers(BaseServices.UserId)
            .subscribe((list: any) => {
                if (list) {
                    this.projectUsers = list;
                }
            }));
    }

    gotoBack() {
        this.location.back();
    }

    /**
     * get selected value in multiple checkbox
     * @param array
     * @param columnName
     */
    getSelectedData(array: any, columnName: string) {
        let selectedList: any = [];
        if (array && array.length > 0) {
            array.forEach((element: any) => {
                let data = {};
                data[columnName] = element;
                selectedList.push(data);
            });
        }
        return selectedList;
    }

    /**
     * ZipCode keypress event restrict number greater than 20 and restrict text
     */
    onZipCodeChanged(e: any, limitNumber: any) {
        const limit = limitNumber;
        if (e.target.value.length === limit) {
            e.preventDefault();
        }
    }

    saveChanges(mode: string) {
        this.loading = true;
        let loggedUserId = BaseServices.UserId;
        let loggedUserName = BaseServices.userName;
        this.selectedUser.push({ Id: loggedUserId, Name: loggedUserName });
        if (this.newProjectForm.valid) {
            this.newProjectForm.enable();
            let projects:any = new ProjectEdit();
            projects.Id = this.projectId ? this.projectId : 0;
            projects.BusinessId = BaseServices.BusinessId;
            projects.Number = this.newProjectForm.value.Number;
            projects.Title = this.newProjectForm.value.Title;
            projects.BusinessPropertyId = this.businessPropertyId
                ? this.businessPropertyId
                : 0;
            projects.StartDate = this.newProjectForm.value.StartDate
                ? HelperService.formatDateFilter(
                    this.newProjectForm.value.StartDate.formatted
                )
                : null;
            projects.Status = this.newProjectForm.value.Status;
            projects.ExpectedEndDate = this.newProjectForm.value.ExpectedEndDate
                ? HelperService.formatDateFilter(
                    this.newProjectForm.value.ExpectedEndDate.formatted
                )
                : null;
            projects.OrderNumber = this.newProjectForm.value.OrderNumber;
            projects.Ssbnumber = this.newProjectForm.value.SSBNumber;
            projects.ProjectCost = this.newProjectForm.value.ProjectCost;
            projects.Description = this.newProjectForm.value.Description;
            projects.CopySJA = this.newProjectForm.value.CopySJA;
            projects.CopySJAForm = this.newProjectForm.value.CopySJAForm;
            projects.ProjectTemplateId = this.newProjectForm.value.ChooseProject;
            
            projects.IsHiddenProject = this.IsHidden;
            projects.ProjectImage = this.projectImage;
            this.businessProperty.Id = this.businessPropertyId
                ? this.businessPropertyId
                : 0;
            this.businessProperty.Address = this.newProjectForm.value.Address
                ? this.newProjectForm.value.Address
                : '';
            this.businessProperty.City = this.newProjectForm.value.City;
            this.businessProperty.Zip = this.newProjectForm.value.Zip;
            this.businessProperty.CreatedOn = new Date();
            this.businessProperty.FloorCount = this.newProjectForm.value.Groundno;
            this.businessProperty.ApartmentCount = this.newProjectForm.value.Propertyno;
            projects.BusinessPropertyDto = this.businessProperty;
            projects.Client = this.newProjectForm.value.Client;
            projects.ProjectImage = this.projectImage;
            projects.ProjectPlan = this.newProjectForm.value.ProjectPlan;
            projects.IndependentControl = this.newProjectForm.value.IndependentControl;
            projects.CoordinatorProjecting = this.newProjectForm.value.CoordinatorProjecting;
            projects.ProjectBuilder = this.newProjectForm.value.ProjectBuilder;
            projects.Contractor = this.getSelectedData(
                this.newProjectForm.value.ContractorValues,
                'ContractorId'
            );
            projects.ContactPerson = this.getSelectedData(
                this.newProjectForm.value.SelectedContactPerson,
                'ContactPersonId'
            );
            projects.ProfessionalManager = this.getSelectedData(
                this.newProjectForm.value.selectedManager,
                'ProfessionalManagerId'
            );
            projects.ProjectManager = this.getSelectedData(
                this.newProjectForm.value.selectedProjectManager,
                'ProjectManagerId'
            );
            projects.ProjectExecution = this.getSelectedData(
                this.newProjectForm.value.selectedResponsibleForExecution,
                'ProjectExecutionId'
            );
            projects.SafetyRepresentatives = this.getSelectedData(
                this.newProjectForm.value.selectedSafetyRepresentative,
                'SafetyRepresentativesId'
            );
            projects.ConstructionManager = this.getSelectedData(
                this.newProjectForm.value.selectedConstructionManager,
                'ConstructionManagerId'
            );
            projects.CoordinatorExecution = this.getSelectedData(
                this.newProjectForm.value.selectedCordinatorExecution,
                'CoordinatorExecutionId'
            );
            projects.ApplicationResponsible = this.getSelectedData(
                this.newProjectForm.value.selectedResponsibleForApp,
                'ApplicationResponsibleId'
            );
            if ((this.bindSelectedUsers) && this.newProjectForm.value.selectedEmailSubscriber) {
                projects.ProjectEmailSubscribers = [];
                let emailSubscriber = this.newProjectForm.value
                    .selectedEmailSubscriber;
                projects.ProjectEmailSubscribers.push(...emailSubscriber);
            }
            if ((this.bindAllUsers) && this.newProjectForm.value.selectedEmailSMSSubscriber) {
                projects.ProjectEmailSubscribers = [];
                let emailSMSSubscriber = this.newProjectForm.value.selectedEmailSMSSubscriber;
                projects.ProjectEmailSubscribers.push(...emailSMSSubscriber);
            }
            if ((this.bindSelectedUsers) && this.newProjectForm.value.selectedSMSSubscriber) {
                projects.ProjectSMSSubscribers = [];
                let smsSubscriber = this.newProjectForm.value
                    .selectedSMSSubscriber;
                projects.ProjectSMSSubscribers.push(...smsSubscriber);
            }
            if ((this.bindAllUsers) && this.newProjectForm.value.smsSubscriber) {
                projects.ProjectSMSSubscribers = [];
                let selectedSMSSubscriber = this.newProjectForm.value.smsSubscriber;
                projects.ProjectSMSSubscribers.push(...selectedSMSSubscriber);
            }
            projects.ProjectUsers = [];
            if (this.selectedUser) {
                this.selectedUser.forEach(element => {
                    let user = new ProjectUsers();
                    user.ProjectId = this.projectId ? this.projectId : 0;
                    user.Name = element.Name ? element.Name : '';
                    user.UserId = element.Id;
                    projects.ProjectUsers.push(user);
                });
            }
            projects.SubContractorUsers = [];
            if (this.selectedContractor) {
                this.selectedContractor.forEach((element:any) => {
                    let user = new SubContractorUser();
                    user.ProjectId = this.projectId ? this.projectId : 0;
                    user.Name = element.Name;
                    user.ContractorId = element.ContractorId;
                    user.ContractorContactId = element.ContractorContactId;
                    projects.SubContractorUsers.push(user);
                });
            }
            projects.ClientUsers = [];
            if (this.selectedClient) {
                this.selectedClient.forEach(element => {
                    let user = new ClientUser();
                    user.ProjectId = this.projectId ? this.projectId : 0;
                    user.Name = element.Name;
                    user.ClientId = element.ClientId ? element.ClientId : 0;
                    user.ClientUserId = element.ClientUserId ? element.ClientUserId : 0;
                    projects.ClientUsers.push(user);
                });
            }
            projects.ProjectResponsibility = [];
            this.newProjectForm.value.Responsibility.forEach(
                (x: any, index: any) => {
                    let id = index + 1;
                    if (x[id]) {
                        let projectResponsible = new ProjectResponsibility();
                        projectResponsible.ProjectId = this.projectId;
                        projectResponsible.ProjectResponsibilityId = id;
                        projects.ProjectResponsibility.push(projectResponsible);
                    }
                }
            );
            if (BaseServices.ApplicationId === 4) {
                projects.IsELThreedigit = this.newProjectForm.value.isFDVDocumentationChecked;
                projects.IsELFourdigit = this.newProjectForm.value.isFDVBuildingChecked;
                projects.IsELForvaltning = this.newProjectForm.value.isFDVManagementChecked;
                projects.ISVVSThreedigit = this.newProjectForm.value.isFDVVVSDocumentationChecked;
                projects.ISVVSFourdigit = this.newProjectForm.value.isFDVVVSBuildingChecked;
                projects.IsVVSForvaltning = this.newProjectForm.value.isFDVVVSManagementChecked;
            }
            projects.ShowDeviation = !this.newProjectForm.value.isShowDeviationChecked;
            if (projects.Id > 0) {
                projects.CreatedOn = this.createdOn;
                projects.ModifiedOn = new Date();
                projects.CreatedBy = this.createdBy;
                projects.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.projectService.update(projects).subscribe(result => {
                    if (result) {
                       // this.loading = false;
                        this.toasterComponent.callToast();
                    }
                }));
            } else {
                let businessProperty = new BusinessProperty();
                businessProperty.Id = this.businessPropertyId
                    ? this.businessPropertyId
                    : 0;
                businessProperty.Address = this.newProjectForm.value.Address
                    ? this.newProjectForm.value.Address
                    : '';
                businessProperty.City = this.newProjectForm.value.City;
                businessProperty.Zip = this.newProjectForm.value.Zip;
                businessProperty.CreatedOn = new Date();
                businessProperty.FloorCount = this.newProjectForm.value.Groundno;
                businessProperty.ApartmentCount = this.newProjectForm.value.Propertyno;
                projects.BusinessPropertyDto = businessProperty;
                projects.CreatedBy = BaseServices.UserId;
                this.subscriptions.push(this.projectService.create(projects).subscribe(result => {
                    if (result) {
                        //this.loading = false;
                        this.toasterComponent.callToast();
                    }
                }));
            }
        }
        if (mode && mode === 'NEWPROJECT') {
            this.clear();
            this.loading = false;
        } else {
            setTimeout(() => {
                this.gotoBack();
            }, 4000);
        }
    }
    getProjectList() {
        this.subscriptions.push(this.projectService
            .getProjectByUser(BaseServices.UserId, BaseServices.BusinessId)
            .subscribe(() => { }));
    }
    /**
    
    /**
     * hide the project button
     */

    onAdministrateProject() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_PROJECTS;
        if (currentUserRole === 'User') {
            this.isHideProject = false;
            this.isAdministrateProject = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }
    }
    /**
     * keypress event restrict number greater than 15 and restrict text
     */
    onNumberPress(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 && (charCode < 48 || charCode > 57))
        ) {
            e.preventDefault();
        }
    }
    onKeyPress(e: any) {
        let charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }

    checked(value) {
        if (value.status) {
            this.selectedUser.push(value);
            if (this.emailSMSUsers) {
                this.emailSMSUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: {
                                Id: element.Id,
                                FeatureKey: element.FeatureKey,
                                FeatureId: element.FeatureId
                            }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedUser, val => {
                if (this.userSubscribers) {
                    _.forEach(this.userSubscribers, (item: any) => {
                        if (item.value.Id == value.Id) {
                            this.userSubscribers.pop();
                        }
                    });
                }
                return val === value;
            });
        }
    }
    checkAll(event) {
        _.forEach(this.projectUsers, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status = this.userId === +item.Id ? true : false; // to remain checked state for current user                
            }
        });

        this.selectedUser = this.projectUsers;
        if (!event) {
            this.selectedUser = [];
            this.bindAllUsers = false;
            this.bindSelectedUsers = true;
        }
        else {
            this.bindAllUsers = true;
            this.bindSelectedUsers = false;
        }
    }

    fdvElektroChanges(event) {
        this.isFDVDocumentation = event;
        this.newProjectForm.get('isFDVBuildingChecked').patchValue(event);
        this.newProjectForm.get('isFDVManagementChecked').patchValue(event);
    }

    fdvvsChanges(event) {
        this.isVVSFDVDocumentation = event;
        this.newProjectForm.get('isFDVVVSBuildingChecked').patchValue(event);
        this.newProjectForm.get('isFDVVVSManagementChecked').patchValue(event);
    }

    /**
    * get current date
    */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1
        };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
