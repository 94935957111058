import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FoodSafetyManualCompleteChecklist } from '../../shared/models/complete-checklist';
import { FoodSafety } from './../../foodsafety/models/foodsafety';
import { BaseServices } from './../../kuba.services';
import { FoodControl, ObjectDto } from './../models/food-control';
import { managetemperaturecontrol } from 'src/app/kuba/foodsafety/models/managetemperaturecontrol';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ManualControlServices{
    key : any;
    constructor(private http: HttpClient,route: ActivatedRoute,
        private router: Router) {     
    }
    AssignFeatureId(): void {
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')).id;
        if(workingModule == 43)
            this.key = "kubacontrol";
        else if(workingModule == 20)
            this.key = "foodsafety";    
    }


    getManualControlByParent(id: number) {
        this.AssignFeatureId();
        return this.http
            .get(environment.BASE_URL + '/'+this.key+'/foodcontrols/' + id, BaseServices.headerOption)
            .map((result:any) => result);
    }
    getManualControlById(id: number) {
        this.AssignFeatureId();
        return this.http
            .get(environment.BASE_URL + '/'+this.key+'/foodcontrol/' + id, BaseServices.headerOption)
            .map((result:any) => result);
    }
    createManualControl(foodSafety: FoodControl) {
        this.AssignFeatureId();
        return this.http
            .post(
                environment.BASE_URL + '/'+this.key+'/foodcontrol',
                foodSafety,
                BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }
    updateManualControl(foodSafety: FoodControl) {
        this.AssignFeatureId();
        return this.http
            .put(
                environment.BASE_URL + '/'+this.key+'/foodcontrol',
                foodSafety,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    updateManualControl1(foodSafety: FoodSafety) {
        this.AssignFeatureId();
        return this.http
            .put(
                environment.BASE_URL + '/'+this.key ,
                foodSafety,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    deleteFoodControl(id: number) {
        this.AssignFeatureId();
        let result: boolean;
        return this.http.delete(
            environment.BASE_URL + '/'+this.key+'/foodcontrol/' + id, BaseServices.headerOption
        );
    }

    getTypeofObject(businessid: number) {
        this.AssignFeatureId();
        return this.http
            .get(
                environment.BASE_URL +
                '/'+this.key+'/gettypeofobject/' +
                businessid,
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    createTypeofObject(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .post(
                environment.BASE_URL + '/'+this.key+'/posttypeofobject',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateTypeofObject(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .put(
                environment.BASE_URL + '/'+this.key+'/posttypeofobject',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getBrand(businessid: number) {
        this.AssignFeatureId();
        return this.http
            .get(environment.BASE_URL + '/'+this.key+'/getbrand/' + businessid, BaseServices.headerOption)
            .map((result: any) => result);
    }

    createBrand(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .post(
                environment.BASE_URL + '/'+this.key+'/postbrand',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateBrand(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .put(
                environment.BASE_URL + '/'+this.key+'/putbrand',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    deleteControlLookUp(id: number) {
        this.AssignFeatureId();
        let result: boolean;
        return this.http.delete(
            environment.BASE_URL + '/'+this.key+'/deletemanualcontrollookup/' + id, BaseServices.headerOption
        );
    }

    getModel(businessid: number) {
        this.AssignFeatureId();
        return this.http
            .get(environment.BASE_URL + '/'+this.key+'/getmodel/' + businessid, BaseServices.headerOption)
            .map((result: any) => result);
    }

    createModel(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .post(
                environment.BASE_URL + '/'+this.key+'/postmodel',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateModel(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .put(
                environment.BASE_URL + '/'+this.key+'/putmodel',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    getTemperature(businessid: number) {
        this.AssignFeatureId();
        return this.http
            .get(
                environment.BASE_URL +
                '/'+this.key+'/getTemperature/' +
                businessid, BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    createTemperature(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .post(
                environment.BASE_URL + '/'+this.key+'/posttemperature',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateTemperature(typeofObject: ObjectDto) {
        this.AssignFeatureId();
        return this.http
            .put(
                environment.BASE_URL + '/'+this.key+'/puttemperature',
                typeofObject,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    createCompleteChecklist(
        FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist
    ) {
        this.AssignFeatureId();
        return this.http
            .post(
                environment.BASE_URL + '/'+this.key+'/CompleteChecklistByFoodId',
                FoodSafetyManualCompleteChecklist,
                BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }



    checkAllocatedObjectAndType(objectAndTypeId: number) {
        this.AssignFeatureId();
        return this.http
            .get(
                environment.BASE_URL +
                '/'+this.key+'/checkobjectandtype/' +
                objectAndTypeId, BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteFoodControlAttachment(Id) {
        this.AssignFeatureId();
        return this.http
            .delete(environment.BASE_URL + '/'+this.key+'/deletefoodcontrolattachment/' + Id, BaseServices.headerOption)
            .map(result => result);
    }
    saveManageTempControl(manageTemperatureControl: managetemperaturecontrol) {
        this.AssignFeatureId();
        return this.http
            .post(
                `${environment.BASE_URL}/`+this.key+`/TempControl/Save`,
                manageTemperatureControl, BaseServices.headerOption
            )
            .map(result => result);
    }

    ManageTemperatureControl(foodSafetyId: number){
        this.AssignFeatureId();
        return this.http.get(environment.BASE_URL + '/'+this.key+'/managetemperaturecontrol/' + foodSafetyId, BaseServices.headerOption)
        .map((result: any) => result);
    }
}
