import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompleteCheckListService } from './../services/complete-checklist.service'
import { ChecklistCompleteEdit } from './../models/index'
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'checklist-complete.component.html'
})

export class CheckListCompleteComponent {

    checkListId: number;
    completeCheckListId: number;
    completeCheckListType: string;
    completeCheckListDetails: ChecklistCompleteEdit;
    responsibleperson: SelectItem[];
    tabIndex: number;
    private subscriptions: Subscription[] = [];
    constructor(private route: ActivatedRoute,
        private completeChecklistService: CompleteCheckListService,
        private translate: TranslateService) { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.checkListId = this.route.snapshot.params['chkId'];
        this.completeCheckListId = this.route.snapshot.params['cmptChkId'];
        this.completeCheckListType = this.route.snapshot.params['cmptType'];

        if (this.completeCheckListId === 0) {
            this.completeChecklistService.create(this.checkListId).
                subscribe(result => {
                    this.completeCheckListDetails = result;
                    this.responsibleperson = [];
                    let respPerson = this.route.snapshot.data['responsibleForExecutionResolver'];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.responsibleperson = [];
                        this.responsibleperson.push(
                            { label: val.SELECT, value: null }
                        );
                    }));
                    if (respPerson) {
                        respPerson.forEach(element => {
                            this.responsibleperson.push({ label: element.Name, value: element.ContactPersonId });
                        });
                    }
                    this.completeCheckListId = this.completeCheckListDetails.Id;
                    this.tabIndex = 0;
                });
        }
    }


    onCompleteGeneralCheckList(tab: number) {
        this.tabIndex = 1;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}
