import { Route } from '@angular/router';
import { LicenseAgreementComponent } from './license-agreement.component';
import { BuinessLicenseAgreementListComponent } from './components/business-license-agreement-list';
import { BusinessFeatureListResolver, BusinessListResolver } from './services/license-agreement.resolver';
import { AdminTabGuard } from 'src/app/_guards';

export const licenseAgreementRoute: Route[] = [
    {
        path: 'license-agrrement',
        component: LicenseAgreementComponent,
        canActivate: [AdminTabGuard],
        children: [
            {
                path: '',
                component: BuinessLicenseAgreementListComponent,
                resolve: {
                    list: BusinessListResolver,
                    featureList: BusinessFeatureListResolver
                }
            }
        ]
    }
]
