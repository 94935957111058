import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';

import { BaseServices } from './../../kuba.services';
import { EmployeeLeaveService } from './../services/employeeleave.service';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { EmployeeLeaveAvailability, EmployeeHoliday } from '../models/index';
import { DateSetting } from './../../../_helpers/date-setting';
import { IMyDpOptions } from 'mydatepicker';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'leave-allocate',
  templateUrl: 'employee-leaveallocate.component.html',
  styleUrls: ['employee-leaveallocate.component.scss'],
})
export class LeaveAllocateComponent implements OnInit {
  @ViewChild('leavesAllocateTable', { static: false })
  leavesAllocateTable: Table;
  @ViewChild('hoildayTable', { static: false }) hoildayTable: Table;
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  isAddType = false;
  viewType: boolean;
  leaveAllocateForm: FormGroup;
  leaveavalible: string;
  holidayId: number;
  businessId: number;
  holidayForm: FormGroup;
  Year: any;
  leaves: any = [];
  holidays: any = [];
  type: any = [];
  location: Location;
  selectedLeaves: any = [];
  CurrentYear: any;
  additionalData: any;
  holidaysList: any = [];
  allocateApply: boolean = false;
  defaultDateOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    firstDayOfWeek: 'su',
    satHighlight: true,
    inline: false,
    height: '26px',
    selectionTxtFontSize: '14px',
    todayBtnTxt: this.translate.instant('TODAY'),
    dayLabels: {
      su: this.translate.instant('SUN'),
      mo: this.translate.instant('MON'),
      tu: this.translate.instant('TUE'),
      we: this.translate.instant('WED'),
      th: this.translate.instant('THU'),
      fr: this.translate.instant('FRI'),
      sa: this.translate.instant('SAT'),
    },
    monthLabels: {
      1: this.translate.instant('JANUARY'),
      2: this.translate.instant('FEBRUARY'),
      3: this.translate.instant('MARCH'),
      4: this.translate.instant('APRIL'),
      5: this.translate.instant('MAY'),
      6: this.translate.instant('JUNE'),
      7: this.translate.instant('JULY'),
      8: this.translate.instant('AUGUST'),
      9: this.translate.instant('SEPTEMBER'),
      10: this.translate.instant('OCTOBER'),
      11: this.translate.instant('NOVEMBER'),
      12: this.translate.instant('DECEMBER'),
    },
    disableUntil: HelperService.formatInputDate(
      new Date().setDate(new Date().getDate() + 1)
    ).date,
    disableDays: this.holidaysList,
  };

  public startDateOptions: IMyDpOptions = this.defaultDateOptions;
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    public employeeLeaveService: EmployeeLeaveService,
    public confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.businessId = BaseServices.BusinessId;
    this.leaveAllocateForm = this.fb.group({
      Year: ['', Validators.required],
      LeaveAvailable: ['0'],
    });

    this.holidayForm = this.fb.group({
      HolidayDate: ['', [Validators.required]],
      HolidayName: [
        '',
        [Validators.required, ValidationService.noWhitespaceValidator],
      ],
    });
    this.Year = [];
    let year = new Date();
    this.Year.push({
      label: year.getFullYear() - 1,
      value: year.getFullYear() - 1,
    });
    this.Year.push({ label: year.getFullYear(), value: year.getFullYear() });
    this.Year.push({
      label: year.getFullYear() + 1,
      value: year.getFullYear() + 1,
    });

    this.subscriptions.push(
      this.translate.stream('FILENAME').subscribe((val) => {
        this.additionalData = {
          fileName: val.EMPLOYEE_LEAVE_DETAILS,
          header: 'Employee Leave Details',
          businessId: BaseServices.BusinessId,
          cultureInfo: BaseServices.userCultureInfo(),
          columnNames: [
            { title: 'Employee Name', dataKey: 'Name' },
            { title: 'Availed Days', dataKey: 'LeaveAvailable' },
            { title: 'Days Allocated', dataKey: 'DaysUsed' },
            { title: 'Days Left', dataKey: 'DaysLeft' },
          ],
        };
      })
    );
  }

  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    this.bindHolidays();
    let year = new Date();
    this.CurrentYear = year.getFullYear();
    this.leaveAllocateForm.controls['Year'].setValue(this.CurrentYear, {
      onlySelf: true,
    });
    this.bindLeavesAvaliable(this.CurrentYear);
  }

  bindLeavesAvaliable(year: number) {
    this.leaves = [];
    if (year == null) {
      this.subscriptions.push(
        this.employeeLeaveService
          .getAllLeaveAllocation(this.businessId, 0)
          .subscribe((res) => {
            this.leaves = res;
            console.log('Leaves data:', this.leaves);
          })
      );
    } else {
      this.subscriptions.push(
        this.employeeLeaveService
          .getAllLeaveAllocation(this.businessId, year)
          .subscribe((res) => {
            this.leaves = res;
            console.log('Leaves data:', this.leaves);
          })
      );
    }
    this.selectedLeaves = [];
  }

  bindHolidays() {
    this.holidays = [];
    let year;
    let month;
    let day;
    this.subscriptions.push(
      this.employeeLeaveService
        .getAllHolidays(BaseServices.BusinessId)
        .subscribe((res) => {
          this.holidays = res;
          for (let i = 0; i < this.holidays.length; i++) {
            if (this.holidays[i].HolidayDate) {
              year = new Date(this.holidays[i].HolidayDate).getUTCFullYear();
              month = new Date(this.holidays[i].HolidayDate).getMonth() + 1;
              day = new Date(this.holidays[i].HolidayDate).getDate();
            }
            this.holidaysList.push({
              year: year,
              month: month,
              day: day,
            });
          }
        })
    );
  }

  editHoliday(employeeType: any) {
    this.holidayId = employeeType.Id;
    this.holidayForm.patchValue({
      HolidayDate: HelperService.formatInputDate(employeeType.HolidayDate),
      HolidayName: employeeType.HolidayName,
    });
  }

  deleteHoliday(id: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('DELETE_THIS_RECORD'),
      accept: () => {
        this.subscriptions.push(
          this.employeeLeaveService
            .deleteHoliday(id)
            .subscribe((isHolidayDeleted) => {
              if (isHolidayDeleted) {
                this.bindHolidays();
                this.toasterComponent.callToastDlt();
              }
            })
        );
      },
    });
  }

  saveHoliday() {
    let employeeType = new EmployeeHoliday();
    employeeType.Id = this.holidayId ? this.holidayId : 0;
    employeeType.HolidayName = this.holidayForm.value.HolidayName;
    employeeType.HolidayDate = this.holidayForm.value.HolidayDate
      ? HelperService.formatDateFilter(
          this.holidayForm.value.HolidayDate.formatted
        )
      : null!;
    employeeType.Status = '1';
    employeeType.BusinessId = BaseServices.BusinessId;
    if (employeeType.Id > 0) {
      this.subscriptions.push(
        this.employeeLeaveService
          .updateHoliday(employeeType)
          .subscribe((updateResponse) => {
            if (updateResponse) {
              this.bindHolidays();
              this.toasterComponent.callToast();
              this.viewType = true;
              this.holidayForm.reset();
            }
          })
      );
    } else {
      this.subscriptions.push(
        this.employeeLeaveService
          .createHoliday(employeeType)
          .subscribe((addResponse) => {
            if (addResponse) {
              this.bindHolidays();
              this.toasterComponent.callToast();
              this.viewType = true;
              this.holidayForm.reset();
            }
          })
      );
    }
  }

  saveMultiple() {
    let leaves: EmployeeLeaveAvailability[] = [];

    if (this.selectedLeaves.length !== 0) {
      this.selectedLeaves.forEach((employeeLeave: any) => {
        if (employeeLeave) {
          let employee = new EmployeeLeaveAvailability();
          employee.Id = employeeLeave.Id;
          employee.BusinessId = BaseServices.BusinessId;
          employee.Name = employeeLeave.Name;
          employee.EmployeeId = employeeLeave.EmployeeId;
          employee.Year = this.leaveAllocateForm.value.Year;
          if (this.leaveAllocateForm.value.LeaveAvailable == null) {
            employee.LeaveAvailable = 0;
          } else {
            employee.LeaveAvailable =
              this.leaveAllocateForm.value.LeaveAvailable;
          }

          leaves.push(employee);
        }
      });
      this.subscriptions.push(
        this.employeeLeaveService.saveLeaveAllocation(leaves).subscribe(() => {
          this.bindLeavesAvaliable(this.leaveAllocateForm.value.Year);
          this.toasterComponent.callToast();
          // Reset the LeaveAvailable field
          this.leaveAllocateForm.controls['LeaveAvailable'].reset();
        })
      );
    } else {
      alert(
        this.translate.instant(
          'PLEASE_SELECT_ATLEAST_ONE_EMPLOYEE_TO_DO_THIS_OPERATION'
        )
      );
    }
  }

  exportPdf() {
    let pdfSetting = new PdfSetting();
    pdfSetting.date = 'Date:' + HelperService.formatDate(new Date());
    pdfSetting.businessName = BaseServices.BusinessName;
    pdfSetting.pageHeader = 'Leave';
    let data = this.leaves;
    this.subscriptions.push(
      this.translate.stream('ALLOCATE_LEAVE_DAYS').subscribe((val) => {
        let columns = [
          { title: val.EMPLOYEE_NAME, dataKey: 'Name' },
          { title: val.AVAILED_DAYS, dataKey: 'LeaveAvailable' },
          { title: val.DAYS_ALLOCATED, dataKey: 'DaysUsed' },
          { title: val.DAYS_LEFT, dataKey: 'DaysLeft' },
        ];
        HelperService.generatePdf(
          data,
          columns,
          'Employee Leave Details',
          pdfSetting,
          'l'
        );
      })
    );
  }

  onBeforeHolidayDialogHide() {
    this.holidayForm.reset();
  }

  onNumberChange(e: any, limitNumber: any) {
    HelperService.numberFieldValidation(e, limitNumber);
    if (e.key == 0 || e.key > 0) {
      this.allocateApply = false;
    } else {
      this.allocateApply = true;
    }
  }

  onLeaveAvailableChange(event: any) {
    const leaveAvailable = parseInt(event.target.value, 10);

    if (!isNaN(leaveAvailable)) {
      this.selectedLeaves.forEach((selectedLeave: any) => {
        const leave = this.leaves.find(
          (l: any) => l.EmployeeId === selectedLeave.EmployeeId
        );
        if (leave) {
          leave.LeaveAvailableDisplay = `${leave.LeaveAvailable} <span>(${leaveAvailable})</span>`;
        }
      });
    } else {
      this.leaves.forEach((leave: any) => {
        leave.LeaveAvailableDisplay = leave.LeaveAvailable;
      });
    }
  }

  onSelectionChange(event: any) {
    const leaveAvailable = parseInt(
      this.leaveAllocateForm.get('LeaveAvailable')?.value,
      10
    );

    this.leaves.forEach((leave: any) => {
      if (
        this.selectedLeaves.some(
          (selectedLeave: any) => selectedLeave.EmployeeId === leave.EmployeeId
        )
      ) {
        if (!isNaN(leaveAvailable)) {
          leave.LeaveAvailableDisplay = `${leave.LeaveAvailable} <span>(${leaveAvailable})</span>`;
        }
      } else {
        leave.LeaveAvailableDisplay = leave.LeaveAvailable;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
