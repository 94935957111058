export class RegistrationList {
    Id: number;
    FeatureKey: number;
    RegistrationTypeId: number;
    RegistrationTypeName: string;
    Description: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    Modifier: number;
    ModifiedOn: string;
    ProjectName: string;
    Nameoftheobject: string;
}
