import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { EmployeeServices } from './../../employees/services/employee.services';
import { UserService } from './../../users/services/user.service';
import { AdminUserService } from '../../adminuser/services/adminuser.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ValidationService } from './../../shared/services/validation.service';
import { BusinessPortalService } from './../services/business-portal.services';
import { SaveUsers, UserCredential, BusinessAccess } from './../../users/models/user';
import { User } from './../../projects/models/user';
import { UserRole } from '../../follow-ups/models/deviation';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Rights } from 'src/app/_models';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
@Component({
    selector: 'portal-user',
    templateUrl: 'portal-user.component.html'
})

export class PortalUserComponent implements OnInit, DoCheck {

    //#region variables
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static:false}) dt: Table;
    portalUserForm: FormGroup;
    user: User;
    Id = 0;
    portalId: number;
    users: any;
    showPassword: any;
    hidePassword = false;
    display = false;
    logoPath: string;
    cachedUserName: string;
    isExistingUser = false;
    showPasswordResetDialog = false;
    updatePasswordForm: FormGroup;
    userCredentials = new UserCredential();
    passwordValidation = false;
    isPortal: boolean;
    public selectedFiles: any;
    businessId: number;
    uploadStartForLogo: boolean;
    userImage: string;
    businessList: any[];
    portalUserList: any[];
    additionalData: any;
    portalRightsData: any = [];
    rightsData: any = [];
    selectedBusiness: any = [];
    hideSave = true;
    roleId: number;
    isPortalManager: boolean;
    assignBusiness = true;
    disabled = false;
    hideRandomPassword = false;
    translateUser = false;
    showUserList = true;
    hideBackButton = false;
    hideUserForm = false;
    hideNewUserButton = false;
    hideNewUserBackButton = false;
    loading = false;
    userCreatedOn: string;
    userCreatedBy: number;
    checkedAll: boolean;
    private subscriptions: Subscription[] = [];
    statuses: SelectItem[];

    //#endregion

    //#region constructor
    /**
     * constructor
     * @param _fb {FormBuilder}
     * @param userService {UserService}
     * @param router {Router}
     * @param route {ActivatedRoute}
     * @param businessPortalService {BusinessPortalService}
     */
    constructor(
        private _fb: FormBuilder,
        private userService: UserService,
        public employeeServices: EmployeeServices,
        private adminUserService: AdminUserService,
        private router: Router,
        private route: ActivatedRoute,
        private businessPortalService: BusinessPortalService,
        public businessServices: BusinessServices,
        protected location: Location,
        private http: HttpClient,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.userRights();
        this.isPortalManager = BaseServices.PortalManager ? BaseServices.PortalManager : false;
        if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
            this.disabled = true;
        }
    }


    //#endregion

    //#region page-events
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
            this.translateUser = true;
            this.hideSave = false;
        } else if (+BaseServices.roleId === 2 && BaseServices.PortalManager === true) {
            this.hideRandomPassword = this.Id > 0 ? false : true;
            this.hideSave = true;
        }

        this.hideNewUserButton = ((+BaseServices.roleId === 2 && BaseServices.PortalManager === true)
            || (+BaseServices.roleId === 1)) ? true : false;
        this.roleId = +BaseServices.roleId;
        this.isPortal = (+BaseServices.roleId === UserRole.PORTAL) ? true : false;
        let pUserList = this.route.snapshot.data['portalManagerUserList'];
        this.businessList = this.route.snapshot.data['PortalBusinessList'];
        if (pUserList !== null && pUserList.length > 0) {
            let IsPortalManager = true;
            pUserList.sort(function (x: any, y: any) {
                return x.IsPortalManager === IsPortalManager
                    ? -1 : y.IsPortalManager === IsPortalManager ? 1 : 0;
            });
            this.portalUserList = pUserList;
        } else {
            this.assignBusiness = false;
        }
        this.updatePasswordForm = this._fb.group({
            passwordValidationGroup: this._fb.group(
                {
                    Password: ['', [Validators.required]],
                    ConfirmPassword: ['', [Validators.required]]
                },
                { validator: ValidationService.matchPassword }
            )
        });
        let pUserRights: any | FormArray = new FormArray([]);
        this.portalUserForm = this._fb.group({
            IsPortalManager: [false],
            'Name': ['', [Validators.required, ValidationService.noWhitespaceValidator]],
            'Email': ['', [Validators.required, ValidationService.emailValidator]],
            'Username': ['', [Validators.required, ValidationService.noWhitespaceValidator],
                this.isUserNameUnique.bind(this)],
            'CountryCode': ['+47'],
            'Mobile': [''],
            'userId': [''],
            'length': [pUserList.length],
            portalUserRights: pUserRights,
            newPasswordValidation: this._fb.group(
                {
                    Password: ['', Validators.required],
                    ConfirmPassword: ['', Validators.required]
                },
                { validator: ValidationService.matchPassword }
            ),
        });

        if (+BaseServices.roleId === 2) {
            this.hideBackButton = true;
            if (BaseServices.PortalManager === false) {
                this.showUserList = false;
                this.getUserDetailsForPortalUser()
            }
        }

        this.additionalData = {
            fileName: 'Portaluserlist',
            header: 'Portal USer List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name', dataKey: 'Name' },
                { title: 'Username', dataKey: 'Username' },
                { title: 'Email', dataKey: 'Email' },
                { title: 'Mobile', dataKey: 'Mobile' },
                { title: 'Status', dataKey: 'Status' }
            ]
        };
        this.portalRightsData = [];
        this.portalRightsData = this.rightsData;

        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 2 },
                { label: val.ACTIVE, value: 1 }
            );
        }));
    }
    //#endregion

    ngDoCheck() {

    }
    highlightParent(rowData: any) {
        return rowData.IsPortalManager ? 'ui-state-highlight' : null;
    }
    hideOrDisplayEdit(userId: any): boolean {
        if (this.roleId === 1 || this.roleId === 2 && BaseServices.PortalManager === true) {
            return true;
        } else {
            if (userId === BaseServices.UserId) {
                return true;
            } else {
                return false;
            }
        }
    }

    pushToBack() {
        this.location.forward();
        this.hideUserForm = false;
        this.hideNewUserButton = true;
        this.hideNewUserBackButton = false;
    }

    /**
        * status dropdown change event
        * @param e {any}
        * @param statusDropdown {any}
        */
    onStatusChanged(e: any, statusDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.dt.filter(statusDropdown.selectedOption.label, 'StatusKey', 'equals');
        } else {
            this.dt.reset();
        }
    }

    /**
     *
     */
    clickNewUser() {
        this.hideUserForm = true;
        this.hideNewUserButton = false;
        this.hideNewUserBackButton = true;
        this.isExistingUser = true;
        let pUserList = this.route.snapshot.data['portalManagerUserList'];
        let pUserRights: any | FormArray = new FormArray([]);
        this.portalUserForm = this._fb.group({
            IsPortalManager: [false],
            'Name': ['', [Validators.required, ValidationService.noWhitespaceValidator]],
            'Email': ['', [Validators.required, ValidationService.emailValidator]],
            'Username': ['', [Validators.required, ValidationService.noWhitespaceValidator],
                this.isUserNameUnique.bind(this)],
            'CountryCode': ['+47'],
            'Mobile': [''],
            'userId': [''],
            'length': [pUserList.length],
            portalUserRights: pUserRights,
            newPasswordValidation: this._fb.group(
                {
                    Password: ['', Validators.required],
                    ConfirmPassword: ['', Validators.required]
                },
                { validator: ValidationService.matchPassword }
            ),
        });
        this.portalRightsData = [];
        this.portalRightsData = this.rightsData;
    }

    /**
     *
     */
    getUserDetailsForPortalUser() {
        this.hideSave = true;
        this.showPassword = '';
        this.hideRandomPassword = false
        this.hidePassword = false;
        this.portalRightsData = [];
        this.selectedBusiness = [];
        this.hideUserForm = true;
        this.subscriptions.push(this.businessPortalService.getPortalUserDetail(+BaseServices.UserId, BaseServices.PortalId).subscribe((user: any) => {
            if (user !== null) {

                if (user.IsPortalManager !== null && user.IsPortalManager === true) {
                    this.assignBusiness = false;
                } else {
                    this.assignBusiness = true;
                    if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
                        this.hideRandomPassword = false;
                    }
                }
                this.portalRightsData = JSON.parse(user.Rights);
                let pUserRights: any | FormArray = new FormArray([]);
                this.Id = null!;
                this.Id = user.Id;
                this.userCreatedBy = user.CreatedBy;
                this.userCreatedOn = user.CreatedOn;
                this.userImage = (user.Avatar) ? user.Avatar : '',
                    this.adminUserService.checkNewUser(true);
                this.cachedUserName = user.Username;
                this.portalUserForm = new FormGroup({
                    IsPortalManager: new FormControl(user.IsPortalManager),
                    Name: new FormControl(user.Name, [Validators.required, ValidationService.noWhitespaceValidator]),
                    Email: new FormControl(user.Email, [Validators.required, ValidationService.emailValidator]),
                    Username: new FormControl(user.Username, [Validators.required],
                        this.isUserNameUnique.bind(this)),
                    Mobile: new FormControl((user.Mobile) ? user.Mobile : ''),
                    UserId: new FormControl(user.Id),
                    length: new FormControl(1),
                    CountryCode: new FormControl((user.CountryCode) ? user.CountryCode : ''),
                    newPasswordValidation: this._fb.group({
                        Password: user.Password,
                        ConfirmPassword: user.Password
                    }),
                    portalUserRights: pUserRights
                });
                if (this.businessList !== null && this.businessList.length > 0) {
                    this.businessList.forEach(x => {
                        x.status = false;
                        let busId = user.BusinessAccess.find(function (element: any) {
                            return element.BusinessId === x.Id;
                        });
                        if (busId !== undefined) {
                            x.status = true;
                            this.selectedBusiness.push(x);
                        }
                    });
                }
            }
        }));

    }

    //#region control-events
    /**
     * Generate random Password
     */
    generateRandomPassword() {
        let randomstring = Math.random().toString(36).slice(-8);
        this.portalUserForm
            .get('newPasswordValidation')!
            .patchValue({
                Password: randomstring,
                ConfirmPassword: randomstring
            });
        this.showPassword = randomstring;
        this.hidePassword = true;
    }

    /**
     * show the reset password feild
     */
    showResetPassword() {
        this.showPasswordResetDialog = true;
    }
    /**
        * user name validation
        */
    isUserNameUnique(control: FormControl) {
        const q = new Promise(resolve => {
            setTimeout(() => {
                if (control.value === this.cachedUserName) {
                    resolve(null);
                } else {
                    this.subscriptions.push(this.employeeServices.getUserName(control.value).subscribe(
                        x => {
                            if (!x) {
                                resolve(null);
                            } else {
                                resolve({ invalidUserName: true });
                            }
                        },
                        () => {
                            resolve({ invalidUserName: true });
                        }
                    ));
                }
            }, 1000);
        });
        return q;
    }

    showDialog() {
        this.display = true;
    }

    /**
     * user default rights data
     */
    userRights() {
        this.rightsData = [
            {
                'id': '1',
                'name': 'ACTIVE_ACCOUNT',
                'isChecked': true
            },
            {

                'id': '37',
                'name': 'PORTAL_MANAGER',
                'isChecked': false
            },
            {
                'id': '10',
                'name': 'APPROVE_ABSENCE',
                'isChecked': false
            },
            {
                'id': '11',
                'name': 'APPROVE_LEAVE',
                'isChecked': false
            },
            {
                'id': '12',
                'name': 'APPROVE_TIMETABLE',
                'isChecked': false
            }, {
                'id': '3',
                'name': 'EDIT_MANUALS',
                'isChecked': false
            }
        ];
    }

    onCheckStatus(data: any) {
        this.portalRightsData.forEach((element: any) => {
            if (+element.id === +data.id) {
                element.isChecked = data.status;
            }
        });
        let pmRights = this.portalRightsData.filter((x: any) => +x.id === Rights.PORTAL_MANAGER);
        if (pmRights.length > 0 && pmRights[0].isChecked === true) {
            this.assignBusiness = false;
        } else {
            this.assignBusiness = true;
        }
    }

    /**
     * Add/Update the Business portal user
     */
    saveChanges() {
        this.loading = true;
        let Users = new SaveUsers();
        Users.Name = this.portalUserForm.value.Name;
        Users.Email = this.portalUserForm.value.Email;
        Users.Status = '1';
        Users.Password =
            this.portalUserForm.value.newPasswordValidation[
            'Password'
            ];
        Users.Username = this.portalUserForm.value.Username;
        Users.ApplicationId = BaseServices.ApplicationId;
        Users.Mobile = this.portalUserForm.value.Mobile;
        Users.CountryCode = this.portalUserForm.value.CountryCode;
        Users.RoleId = 2; // portal role
        Users.BusinessPortalId = this.route.snapshot.parent!.params['pid'];
        Users.SignatureFile = null!;
        Users.AvatarFile = null!;
        Users.Avatar = this.userImage;
        if (window.name === 'Remote') {
            Users.LanguageId = 2; // default language Norsk
        } else {
            Users.LanguageId = BaseServices.userLanguageId;
        }

        if (this.userImage) {
            this.adminUserService.getUserProfileImagePath(this.userImage);
        }
        Users.LanguageId = BaseServices.userLanguageId;
        if (this.portalRightsData !== null) {
            Users.IsPortalManager = this.portalRightsData.filter((x: any) => x.name === 'PORTAL_MANAGER')[0].isChecked;
            Users.Rights = JSON.stringify(this.portalRightsData);
        }
        if (this.Id > 0) {
            Users.Id = this.Id;
            Users.CreatedBy = this.userCreatedBy;
            Users.CreatedOn = this.userCreatedOn;
            Users.ModifiedBy = BaseServices.UserId;
            Users.BusinessAccess = [];
            if (this.selectedBusiness !== null) {
                this.selectedBusiness.forEach((element: any) => {
                    let busAccess = new BusinessAccess();
                    busAccess.BusinessId = element.Id;
                    busAccess.UserId = this.Id;
                    busAccess.PortalManagerId = this.route.snapshot.parent!.params['pid'];
                    Users.BusinessAccess!.push(busAccess);
                });
            }
            this.subscriptions.push(this.userService.updateUser(Users.Id, Users).subscribe(result => {
                if (result) {
                    if (+BaseServices.roleId === 2 && BaseServices.PortalManager === false) {
                        this.toasterComponent.callToast();
                    } else {
                        this.clear();
                        this.getPortalUserList();
                        this.restControls();
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            }));
        } else {
            Users.CreatedBy = BaseServices.UserId;
            Users.BusinessAccess = [];
            if (this.selectedBusiness !== null) {
                this.selectedBusiness.forEach((element: any) => {
                    let busAccess = new BusinessAccess();
                    busAccess.BusinessId = element.Id;
                    busAccess.UserId = this.Id;
                    busAccess.PortalManagerId = this.route.snapshot.parent!.params['pid'];
                    Users.BusinessAccess!.push(busAccess);
                });
            }
            this.subscriptions.push(this.userService.addusers(Users).subscribe((result: any) => {
                if (result) {
                    if (+this.route.snapshot.parent!.params['pid'] != null) {
                        this.portalRightsData = this.rightsData;
                        this.getPortalUserList();
                        this.userRights();
                        if (result.IsPortalManager === true) {
                            this.subscriptions.push(this.businessPortalService.updatePortalUserId(+this.route.snapshot.parent.params['pid']
                                , result.Id).subscribe((isUpdate) => {
                                    if (isUpdate) {
                                        this.toasterComponent.callToast();
                                        let pUserList = this.route.snapshot.data['portalManagerUserList'];
                                        if (pUserList.length === 0) {
                                            this.clear();
                                            this.restControls();
                                            setTimeout(() => {
                                                this.router.navigate(['../features'], { relativeTo: this.route });
                                            }, 1000);
                                        } else {
                                            this.clear();
                                            this.getPortalUserList();
                                            this.restControls();
                                        }
                                    }
                                }));
                        } else {
                            this.clear();
                            this.getPortalUserList();
                            this.restControls();
                        }

                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            }));

        }
    }

    /** */
    restControls() {
        if (+BaseServices.roleId === 1 || (+BaseServices.roleId === 2 && BaseServices.PortalManager === true)) {
            this.hideNewUserBackButton = false;
            this.hideNewUserButton = true;
            this.hideUserForm = false;
        }
        this.portalUserForm.setControl('portalUserRights', new FormArray([]));
        this.portalUserForm.reset();
        this.portalUserForm.get('length')!.patchValue(1);
        this.portalRightsData = [];
        this.portalRightsData = this.rightsData;
        this.portalId = null!;
        this.isExistingUser = false;
        this.selectedBusiness = [];
        this.assignBusiness = true;
        if (BaseServices.roleId === '2') {
            this.hideSave = false;
        }
        this.toasterComponent.callToast();
    }

    resetPassWord() {
        this.userCredentials.Id = this.Id;
        this.userCredentials.Password = this.updatePasswordForm.value.passwordValidationGroup.Password;
        this.userCredentials.Password = this.updatePasswordForm.value.passwordValidationGroup.ConfirmPassword;
        this.subscriptions.push(this.businessServices
            .updatePassword(this.userCredentials)
            .subscribe(result => {
                if (result === false) {
                    this.passwordValidation = true;
                } else {
                    this.clearPasswordResetForm()
                    this.toasterComponent.callToast();
                }
            }));
    }

    checked(value: any) {
        if (value.status) {
            this.selectedBusiness.push(value);
        } else {
            _.remove(this.selectedBusiness, (val) => {
                return val === value;
            })
        }
    }

    checkAll(event: any) {
        _.forEach(this.businessList, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status = false;
            }
        });

        this.selectedBusiness = this.businessList;
        if (!event) {
            this.selectedBusiness = [];
        }
    }

    /**
      * delete the user
      * @param id {number}
      */
    delete(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.adminUserService
                    .deleteAdminUser(id)
                    .subscribe((list: any) => {
                        if (list) {
                            this.getPortalUserList();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
            }
        });
    }

    editUser(editUser: any) {
        this.hideNewUserBackButton = false;
        this.hideUserForm = true;
        this.hideNewUserButton = false;
        if (BaseServices.roleId === '2' && BaseServices.PortalManager === true) {
            this.hideSave = true;
            this.isExistingUser = false;
        } else {
            this.isExistingUser = false;

        }

        this.showPassword = '';
        this.hideRandomPassword = false
        this.hidePassword = false;
        this.portalRightsData = [];
        this.selectedBusiness = [];
        if (editUser.IsPortalManager !== null && editUser.IsPortalManager === true) {
            this.assignBusiness = false;
        } else {
            this.assignBusiness = true;
            if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
                this.hideRandomPassword = false;
            }
        }
        this.portalId = this.route.snapshot.parent!.params['pid'];
        if (editUser !== null) {
            this.subscriptions.push(this.businessPortalService.getPortalUserDetail(editUser.Id, editUser.BusinessPortalId).subscribe((user: any) => {
                if (user !== null) {
                    if (this.businessList !== null && this.businessList.length > 0) {
                        this.businessList.forEach(x => {
                            x.status = false;
                            let busId = user.BusinessAccess.find(function (element: any) {
                                return element.BusinessId === x.Id;
                            });
                            if (busId !== undefined) {
                                x.status = true;
                                this.selectedBusiness.push(x);
                            }
                        });
                    }
                }
            }));
            this.portalRightsData = JSON.parse(editUser.Rights);
            let pUserRights: any | FormArray = new FormArray([]);
            this.Id = 0;
            this.Id = editUser.Id;
            this.userCreatedOn = editUser.CreatedOn;
            this.userCreatedBy = editUser.CreatedBy;
            this.userImage = (editUser.Avatar) ? editUser.Avatar : '',
                this.adminUserService.checkNewUser(true);
            this.cachedUserName = editUser.Username;
            this.portalUserForm = new FormGroup({
                IsPortalManager: new FormControl(editUser.IsChecked),
                Name: new FormControl(editUser.Name, [Validators.required, ValidationService.noWhitespaceValidator]),
                Email: new FormControl(editUser.Email, [Validators.required, ValidationService.emailValidator]),
                Username: new FormControl(editUser.Username, [Validators.required],
                    this.isUserNameUnique.bind(this)),
                Mobile: new FormControl((editUser.Mobile) ? editUser.Mobile : ''),
                UserId: new FormControl(editUser.Id),
                length: new FormControl(1),
                CountryCode: new FormControl((editUser.CountryCode) ? editUser.CountryCode : ''),
                newPasswordValidation: this._fb.group({
                    Password: editUser.Password,
                    ConfirmPassword: editUser.Password
                }),
                portalUserRights: pUserRights
            });
        }
    }

    getPortalUserList() {
        this.subscriptions.push(this.businessPortalService
            .getPortalUsersByPortalManagerId(+this.route.snapshot.parent.params['pid'])
            .subscribe(data => {
                if (data != null) {
                    let IsPortalManager = true;
                    data.sort(function (x: any, y: any) {
                        return x.IsPortalManager === IsPortalManager
                            ? -1 : y.IsPortalManager === IsPortalManager ? 1 : 0;
                    });
                    this.portalUserList = [];
                    this.portalUserList = data;
                }
            }));
    }

    /**
     * clear the password
     */
    clear() {
        this.Id = 0;
        this.showPasswordResetDialog = false;
        this.updatePasswordForm.reset();
        this.userImage = null!;
        if (this.businessList !== null && this.businessList.length > 0) {
            this.businessList.forEach(x => {
                x.status = false;
            });
        }
    }

    /**
     * clear password reset from
     */
    clearPasswordResetForm() {
        this.showPasswordResetDialog = false;
        this.updatePasswordForm.reset();
    }

    /**
     * Back to list
     */
    gotoList() {
        this.router.navigate(['./../../../list'], { relativeTo: this.route });
    }
    /**
    * get back the location
    */
    goBackLocation() {
        this.location.back();
    }

    /**
    * upload for business logo
    * @param selectedFiles
    */
    filesSelectForLogo(selectedFiles: any) {
        this.uploadStartForLogo = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };

        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/business/logo/' +
                BaseServices.ApplicationId +
                '/' +
                this.businessId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStartForLogo = false;
                let res = response;
                this.userImage = res.path;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
