<div class="tab-content">
  <div class="page-title">
    <span translate>ADD_INSTRUMENT</span>
  </div>
  <form [formGroup]="measureProtocolUsedInstrument">
    <div class="form-group" *ngIf="measureProtocolUsedInstrument.controls['InstrumentTypeId']">
      <label for="Title">
        <span translate>TYPE</span>
        <span class="required">*</span>
      </label>
      <div class="input-btn-group">
        <p-dropdown [filter]="true" formControlName="InstrumentTypeId" [options]="types" [style]="{'width':'100%'}">
        </p-dropdown>
        <button class="btn btn-outline-primary" type="button" (click)="addType = !addType">
          <i class="pi pi-plus" aria-hidden="true"></i>
        </button>
      </div>
      <control-messages [control]="measureProtocolUsedInstrument.controls['InstrumentTypeId']"></control-messages>
    </div>
    <div class="form-group" *ngIf="measureProtocolUsedInstrument.controls['InstrumentModelId']">
      <label for="Title">
        <span translate>MODEL</span>
        <span class="required">*</span>
      </label>
      <div class="input-btn-group">
        <p-dropdown [filter]="true" formControlName="InstrumentModelId" [options]="models" [style]="{'width':'100%'}">
        </p-dropdown>
        <button class="btn btn-outline-primary" type="button" (click)="addModel = !addModel">
          <i class="pi pi-plus" aria-hidden="true"></i>
        </button>
      </div>
      <control-messages [control]="measureProtocolUsedInstrument.controls['InstrumentModelId']">
      </control-messages>
    </div>
    <div class="form-group" *ngIf="measureProtocolUsedInstrument.controls['SerialNumber']">
      <label for="Title">
        <span translate>Serial Number</span>
      </label>
      <div class="input-btn-group">
        <input type="text" pInputText formControlName="SerialNumber" />
      </div>
      <control-messages [control]="measureProtocolUsedInstrument.controls['SerialNumber']">
      </control-messages>
    </div>
    <div class="form-group" *ngIf="measureProtocolUsedInstrument.controls['CalibratedDate']">
      <label>
        <span translate>CALIBRATED_DATE</span>
        <span class="required">*</span>
      </label>
      <div>
        <my-date-picker [style]="{'width':'160px'}" [options]="CalibratedDateOptions" formControlName="CalibratedDate"
          placeholder="{{'SELECT_CALIBRATED_DATE'|translate}}">
        </my-date-picker>
      </div>
      <control-messages [control]="measureProtocolUsedInstrument.controls['CalibratedDate']"></control-messages>
    </div>
    <div class="action-btns-wrapper text-left">
      <button class="btn btn-primary" [ngClass]="{'loading' : saveprogress}" (click)="saveChanges()"
        [disabled]="measureProtocolUsedInstrument.invalid || saveprogress">
        <span>
          <span *ngIf="!saveprogress" translate>SAVE</span>
          <span *ngIf="saveprogress" translate>SAVE_PROCESS</span>
          <span *ngIf="saveprogress" class="loader-icon"></span>
        </span>
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="onClear()">
        <span translate>CLEAR</span>
      </button>
    </div>
  </form>

  <div class="mtop-20">
    <div class="page-title" translate>INSTRUMENT_LIST</div>
    <div class="table-view">
      <!-- Table starts -->
      <p-table #dt [value]="CMPinstruments" scrollable="true" scrollHeight="200px" [rows]="10" [paginator]="true"
        [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="InstrumentTypeName">{{'TYPE' | translate}} <p-sortIcon field="InstrumentTypeName"></p-sortIcon></th>
            <th pSortableColumn="InstrumentModelName">{{'MODEL' | translate}} <p-sortIcon field="InstrumentModelName"></p-sortIcon></th>
            <th pSortableColumn="SerialNumber">{{'SERIAL_NUMBER' | translate}} <p-sortIcon field="SerialNumber"></p-sortIcon></th>
            <th pSortableColumn="CalibratedDate">{{'CALIBRATED_DATE' | translate}} <p-sortIcon field="CalibratedDate"></p-sortIcon></th>
            <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
            <th pSortableColumn="ModifiedName">{{'EDITED_BY' | translate}} <p-sortIcon field="ModifiedName"></p-sortIcon></th>
            <th></th>
          </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-inst>
          <tr>
            <td>
              <span>{{inst.InstrumentTypeName}}</span>
            </td>
            <td>
              <span>{{inst.InstrumentModelName}}</span>
            </td>
            <td>
              <span>{{inst.SerialNumber}}</span>
            </td>
            <td>
              <span>{{inst.CalibratedDate | date : 'dd/MM/yyyy'}}</span>
            </td>
            <td>
              <span>{{inst.CreatedName}}</span>
            </td>
            <td>
              <span>{{inst.ModifiedName}}</span>
            </td>
            <td class="col-button">
              <a class="btn btn-icon" title="{{'EDIT' | translate}}" (click)="editInstrument(inst['Id'])"
                routerLinkActive="active">
                <i class="icon ic-sm icon-pencil-tip"></i>
              </a>
              <button class="btn btn-icon" title="{{'DELETE' | translate}}" (click)="deleteInstrument(inst['Id'])"
                routerLinkActive="active">
                <i class="icon ic-sm icon-trash"></i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- Table Ends-->
    </div>
  </div>
</div>

<p-dialog header="{{'TYPE' | translate}}" (onHide)="oninstrumentTypeFormHide()" [(visible)]="addType" [modal]="true"
  [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
  <form [formGroup]="instrumentTypeForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>TYPE</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" formControlName="Name" class="label-input form-control" required>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType"
        (click)="viewInstrumentType()">
        <span translate>VIEW</span>
      </button>
      <button type="button" class="btn btn-primary" [disabled]="instrumentTypeForm.invalid" (click)="saveType()">
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="editTypes" #et [rows]="5" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'TYPE' | translate}}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input pInputText type="text" (input)="et.filter($event.target.value, 'Name', 'contains')"
                  placeholder="" class="p-column-filter" >
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryType>
            <tr>
              <td>
                <span>{{categoryType.Name}}</span>
              </td>
              <td  class="col-button">
                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                  (click)="editType(categoryType)">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                  (click)="deleteType(categoryType)">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="{{'MODEL' | translate}}" (onHide)="oninstrumentModelFormHide()" [(visible)]="addModel" [modal]="true"
  [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
  <form [formGroup]="instrumentModelForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>MODEL</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" formControlName="Name" class="label-input form-control" required>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType" (click)="viewModelType()">
        <span translate>VIEW</span>
      </button>
      <button type="button" class="btn btn-primary" [disabled]="instrumentModelForm.invalid" (click)="saveModel()">
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="editModels" #ct [rows]="5" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'TYPE' | translate}}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input pInputText type="text" (input)="ct.filter($event.target.value, 'Name', 'contains')"
                  placeholder="" class="p-column-filter" >
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryType>
            <tr>
              <td>
                <span>{{categoryType.Name}}</span>
              </td>
              <td class="col-button">
                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                  (click)="editModel(categoryType)">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                  (click)="deleteModel(categoryType)">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
    </div>
  </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
