import { Component, Input } from '@angular/core';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';

import { BusinessServices } from './../../businesses/services/business.services';

@Component({
    selector: 'control-messages',
    template: `<div
                *ngIf="errorMessage !== null && (control && (control.dirty || control.touched))"
                class="error-msg">{{errorMessage | translate}}
              </div>`
})
export class ValidationMessagesComponent {
    @Input() control: any;
    @Input() result: any;
    OrganizationNumber: string;
    constructor(private businessServices: BusinessServices) {}

    get errorMessage() {
        if (this.control) {
            for (let propertyName in this.control.errors) {
                if (
                    (this.control.errors.hasOwnProperty(propertyName) &&
                        (this.control.touched ||
                            propertyName ===
                                'startDateLessThanEqualToEndDate' ||
                            propertyName === 'startDateLessThanCurrentDate' ||
                            propertyName === 'websiteValidator' ||
                            propertyName === 'numberValidator' ||
                            propertyName === 'mobileNumberValidator' ||
                            propertyName === 'startDateEndDateYearCheck')) ||
                    propertyName ===
                        'deadLineDaysLessThanEqualToFollowupDays' ||
                    propertyName === 'multipleCheckboxRequireOne' ||
                    propertyName === 'deadlineValidation' ||
                    propertyName === 'nomatch'
                ) {
                    return ValidationService.getValidatorErrorMessage(
                        propertyName,
                        this.control.errors[propertyName]
                    );
                } else {
                    return null;
                }
            }
        }
    }
}
