import { TranslateModule } from '@ngx-translate/core';
import { NotificationListResolver } from './services/notification.resolver.service';
import { AppSharedModule } from './../shared/appShared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotificationComponent } from './notifications.component';
import { NotificationListComponent } from './components/notification-list/notificationList.component';


import { NotificationServices } from "./services/notifications.service";
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
    imports:
    [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        TableModule,
        SharedModule,
        DialogModule,
        AppSharedModule,
        TranslateModule,
        EditorModule,
        ConfirmDialogModule


    ],

    exports:
    [
        NotificationComponent,
        NotificationListComponent,
        

    ],
    declarations: [
        NotificationComponent,
        NotificationListComponent,
    ],
    providers: [NotificationServices, NotificationListResolver],
})
export class NotificationModule { }
