import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualControlServices } from './../services/manual-control-services';
import { FoodControlList } from './../models/food-control-list';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FoodSafetyManualCompleteChecklist } from '../../shared/models/complete-checklist';
import { BaseServices, KubaServices } from '../../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey, Rights } from '../../../_models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TaskService } from 'src/app/kuba/task-manager/services/task.services';
import { managetemperaturecontrol } from 'src/app/kuba/foodsafety/models/managetemperaturecontrol';
import { element } from 'protractor';
import { Repetition } from '../models/food-control';
import { ConsumptionListResolver } from 'src/app/kuba/follow-ups/services/consumptions.resolver.service';
import { HelperService } from 'src/app/_services/helper.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'manual-control-list',
    templateUrl: 'manual-control-list.component.html'
})
export class ManualControlListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    manualobjects: FoodControlList[];
    parentId: number;
    featureId:any;
    parentKey: number;
    FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist;
    isHideButton = true;
    additionalData: any;
    routeSub: any;
    displayDialog: boolean;
    manageTempControlForm: FormGroup;
    weekDays: SelectItem[];
    executionAndFollowupusers: SelectItem[];
    tempControl = new managetemperaturecontrol();
    manageTempControl: any;
    editManageTempControl = false;
    Id: number;
    private subscriptions: Subscription[] = [];
    userRights: any;
    isUser = true;
    constructor(
        private manualControlService: ManualControlServices,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private router: Router,
        private translate: TranslateService,
        private fb: FormBuilder,
        private taskService: TaskService
    ) {
        let list = <FoodControlList[]>this.route.snapshot.data['list'];
        this.manualobjects = list;
        if (+BaseServices.roleId === 4 || +BaseServices.roleId === 5) {
            this.isHideButton = false;
        }
    }

    ngOnInit() {       
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')).id;
        if(workingModule != null){
            this.featureId = workingModule;
        } 
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.parentId = this.route.snapshot.parent.parent.params['id'];
        this.parentKey = this.route.snapshot.parent.parent.data['parent'];
        this.subscriptions.push(this.manualControlService.getManualControlByParent(this.parentId).subscribe(res => {
            this.manualobjects = res;
        }));

        this.additionalData = {
            fileName: 'List of Objects',
            header: 'List of Objects',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Object Name', dataKey: 'ObjectName' },
                { title: 'Temp_min', dataKey: 'MinTemp' },
                { title: 'Temp_max', dataKey: 'MaxTemp' },
                { title: 'Description', dataKey: 'Description' }
            ]
        }
        this.manualobjects = <FoodControlList[]>this.route.snapshot.data['list'];

        this.manageTempControlForm = this.fb.group({
            Repetition: [''],
            EmailNotificationpastDeadline: ['']
        });

        this.bindDropdown();
        let manageTemp = this.route.snapshot.data['manageTemp'];
        if (manageTemp != null) {
            let repDays = [];
            if (manageTemp.Repetition) {
                manageTemp.Repetition.forEach((element: any) => {
                    repDays.push(element.RepetitionId);
                })
            }
            let manageTempControlForm = {
                Repetition: repDays,
                EmailNotificationpastDeadline: manageTemp ? manageTemp.EmailNotificationPastDeadline : 0
            };
            (<FormGroup>this.manageTempControlForm).setValue(manageTempControlForm, { onlySelf: true })
        }

        this.userRights = BaseServices.getUserRights();
        let data = JSON.parse(this.userRights);
        let userRightsId = Rights.ADMINISTRATE_FOOD_SAFETY;
        if(BaseServices.roleId == '5'){
            if(userRightsId){
                let result = HelperService.checkRightsData(data, userRightsId)
                if(result == true){
                    this.isUser = true;
                }
                else{
                    this.isUser = false;
                }
            }
        }
        else{
            this.isUser = true
        }        
    }


        editFoodControl(id) {
            this.router.navigate(['./../edit', id],
                { relativeTo: this.route });
        }

        deleteFoodControl(manualobjects) {
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_THIS_RECORD'),
                accept: () => {
                    this.subscriptions.push(this.manualControlService
                        .deleteFoodControl(manualobjects)
                        .subscribe(result => {
                            this.subscriptions.push(this.manualControlService
                                .getManualControlByParent(this.parentId)
                                .subscribe(res => {
                                    this.manualobjects = res;
                                    this.toasterComponent.callToastDlt();
                                }));
                        }));
                }
            });
        }

        completeChecklist() {
            this.router.navigate(
                [
                    '../../../../../fs-complete-checklist/new/' +
                    this.parentId
                ],
                { relativeTo: this.route }
            );
            this.FoodSafetyManualCompleteChecklist = new FoodSafetyManualCompleteChecklist();
            this.FoodSafetyManualCompleteChecklist.FeatureId = this.featureId;
            this.FoodSafetyManualCompleteChecklist.FeatureKey = this.parentId;
            this.FoodSafetyManualCompleteChecklist.BusinessId =
                BaseServices.BusinessId;
            this.FoodSafetyManualCompleteChecklist.Id = this.route.snapshot.params[
                'Id'
            ];

        }

        showDialogToAdd() {
            this.displayDialog = true;
        }

        cancel() {
            this.displayDialog = false;
        }


        bindDropdown() {
            this.weekDays = [];
            this.executionAndFollowupusers = [];

            this.subscriptions.push(this.translate.stream('WEEKDAYS_LIST').subscribe(val => {
                this.weekDays = [];
                this.weekDays.push(
                    { label: val.MONDAY, value: 1 },
                    { label: val.TUESDAY, value: 2 },
                    { label: val.WEDNESDAY, value: 3 },
                    { label: val.THURSDAY, value: 4 },
                    { label: val.FRIDAY, value: 5 },
                    { label: val.SATURDAY, value: 6 },
                    { label: val.SUNDAY, value: 7 }
                );
            }))

            this.executionAndFollowupusers = [];
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.executionAndFollowupusers = [];
                this.executionAndFollowupusers.push(
                    { label: val.SELECT, value: null }
                    );
                    this.subscriptions.push(this.taskService.getAssignedUsers(BaseServices.BusinessId,this.featureId , this.parentId, BaseServices.ApplicationId)
                    .subscribe((users: any) => {
                        users.forEach((element: any) => {
                            this.executionAndFollowupusers.push({
                                label: element.Name,
                                value: element.Id
                            })
                        })
                        this.manageTempControlForm.patchValue({ 'EmailNotificationpastDeadline': this.manageTempControlForm.value.EmailNotificationpastDeadline })
                    }))
            }));
        }

        onSubmitForm() {
            if (this.manageTempControlForm.valid) {
                this.displayDialog = false;
                let manageTemp = this.route.snapshot.data['manageTemp'];
                this.tempControl.Id = manageTemp != null ? manageTemp.Id : 0;
                this.tempControl.FoodSafetyId = +this.parentId;
                this.tempControl.ControlId = +this.parentId;
                this.tempControl.Repetition = this.manageTempControlForm.value.Repetition;
                this.tempControl.CreatedBy = BaseServices.UserId;

                let weekdays = []
                if (this.manageTempControlForm.value.Repetition) {
                    this.manageTempControlForm.value.Repetition.forEach(result => {
                        let rep = new Repetition();
                        rep.RepetitionId = result;
                        weekdays.push(rep)
                    });
                }
                this.tempControl.Repetition = weekdays;
                this.tempControl.EmailNotificationPastDeadline = this.manageTempControlForm.value.EmailNotificationpastDeadline == "" || this.manageTempControlForm.value.EmailNotificationpastDeadline == null ? 0 : this.manageTempControlForm.value.EmailNotificationpastDeadline;
                this.subscriptions.push(this.manualControlService.saveManageTempControl(this.tempControl).subscribe(result => {
                    this.displayDialog = false;
                    this.toasterComponent.callToast();
                }));
            }
        }

        ngOnDestroy() {
            this.subscriptions.forEach((sub, i) => {
                sub.unsubscribe();
            });
        }
    }
