import {Component,Input}  from '@angular/core';
import { MenuItem, Message } from 'primeng/api';
@Component({
    templateUrl:'checklist-complete-tabs.component.html',
    selector: 'complete-checklist-tab',
    styles: [`
    .ui-steps .ui-steps-item {
        width: 25%;
    }
    
    .ui-steps.steps-custom {
        margin-bottom: 30px;
    }
     
    .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
        height: 10px;
        padding: 0 1em;
    }
     
    .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
        background-color: #0081c2;
        color: #FFFFFF;
        display: inline-block;
        width: 36px;
        border-radius: 50%;
        margin-top: -14px;
        margin-bottom: 10px;
    }
    
    .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
        color: #555555;
    }
`]
})

export class CompleteCheckListTabs{
    items: MenuItem[];
    msgs: Message[] = [];
    activeIndex: number = 0;
    @Input() tabIndex :number;
    
constructor(){

}

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.items = [{
            label: 'General',
            command: (event: any) => {
                this.activeIndex = 0;
                this.msgs.length = 0;
                this.msgs.push({severity:'info', summary:'First Step', detail: event.item.label});
            }
        },
        {
            label: 'Fill out the checklist',
            command: (event: any) => {
                this.activeIndex = 1;
                this.msgs.length = 0;
                this.msgs.push({severity:'info', summary:'Seat Selection', detail: event.item.label});
            }
        },
        {
            label: 'Finish/Archive',
            command: (event: any) => {
                this.activeIndex = 2;
                this.msgs.length = 0;
                this.msgs.push({severity:'info', summary:'Pay with CC', detail: event.item.label});
            }
        }
       
    ];
    this.activeIndex= this.tabIndex;
    }

    ngOnChanges() 
    { 
        this.activeIndex= this.tabIndex;
    }
}