<div class="widget-container">
  <div
    class="btn-group custom-width sticky-buttons"
    role="group"
    aria-label="Button group"
  >
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-primary': activeButton === 'unapproved',
        'btn-outline-secondary': activeButton === 'approved'
      }"
      (click)="showUnapprovedTable()"
    >
      <span translate>UNAPPROVED</span>
    </button>
    <button
      type="button"
      class="btn"
      [ngClass]="{
        'btn-primary': activeButton === 'approved',
        'btn-outline-secondary': activeButton === 'unapproved'
      }"
      (click)="showApprovedTable()"
    >
      <span translate>APPROVED</span>
    </button>
  </div>
  <!-- Unapproved risks table -->
  <div *ngIf="showTable === 'unapproved'" class="table-container">
    <table class="table fixed-width-table">
      <thead>
        <tr>
          <th><span translate>TITLE</span></th>
          <th class="center-aligned-header">
            <span translate>RISK_FIGURE</span>
          </th>
          <th><span translate>EDITED_DATE</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let risk of unapprovedRiskAnalysis">
          <td class="left-aligned-cell">
            <a [routerLink]="['../follow-up/risk-analysis/edit-risk', risk.Id]">
              {{ risk.Title }}
            </a>
          </td>

          <td class="chip-cell">
            <span
              [ngClass]="getRiskFigureClass(risk.RiskFigureData?.ValueText)"
            >
              {{ formatRiskLevel(risk.RiskFigureData?.ValueText) }}
            </span>
          </td>
          <td>{{ risk.ModifiedOn | date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Approved risks table -->
  <div *ngIf="showTable === 'approved'" class="table-container">
    <table class="table fixed-width-table">
      <thead>
        <tr>
          <th><span translate>TITLE</span></th>
          <th class="center-aligned-header">
            <span translate>RISK_FIGURE</span>
          </th>
          <th><span translate>STATUS</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let risk of approvedRiskAnalysis">
          <td class="left-aligned-cell">
            <a [routerLink]="['../follow-up/risk-analysis/edit-risk', risk.Id]">
              {{ risk.Title }}
            </a>
          </td>
          <td class="chip-cell">
            <span
              [ngClass]="getRiskFigureClass(risk.RiskFigureData?.ValueText)"
            >
              {{ formatRiskLevel(risk.RiskFigureData?.ValueText) }}
            </span>
          </td>
          <td><span translate>APPROVED</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
