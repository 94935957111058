import { UploadFile, UploaderOptions, UploadInput, UploadStatus, UploadOutput } from 'ngx-uploader';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

import { CacheHandlerService } from './../../cached.services';
import { environment } from 'src/environments/environment';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { BaseServices } from './../../kuba.services';
import { BusinessAgreement } from './../models/businessList';
import { BusinessServices } from '../services/business.services';
import { ActivityAttachment } from './../../task-manager/models/activity-list';
import { UploaderConfig } from './../../../shared/ecpl-document-explorer/uploader-config';
import { UserRole } from '../../follow-ups/models/deviation';
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/_models';
import { BusinessEdit } from '../models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'business-license',
    templateUrl: 'business-license.component.html'
})
export class BusinessLicenseComponent implements OnInit {

    //#region variables

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    licenseagrmtForm: FormGroup;
    agreement: BusinessAgreement;
    upConfig: UploaderConfig;
    isLinkHide = true;
    businessId = 0;
    isHide = true;
    fileName: string;
    licenseMonths: any;
    editAgreement: any;
    document = '';
    agreementName: string;
    isFileChoosed = false;
    files: UploadFile[] = [];
    licenseAgreementDocument: any;
    uploadInput: EventEmitter<UploadInput>;
    dragOver: boolean;
    businessInfo: any;
    remoteWindow: Window;
    selDate: any;
    isAdmin: boolean;
    roleId: number;
    licenseCategory: any = [];
    licenseCategoryList: any;
    isDinHMSLogin = false;
    businessEdit: BusinessEdit;
    isDinHMS = false;
    usersCount: number;
    employeesCount: number;
    private subscriptions: Subscription[] = [];
    /**
   * Date picker configuration option
   */
    public PurchaseDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '26px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    public ExpiryDateOptions: IMyDpOptions = this.PurchaseDateOptions;
    isMedi3: boolean=false;
    //#endregion
    //#region constructor
    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param businessagrremnt {BusinessServices}
     * @param route {ActivatedRoute}
     */
    constructor(private formBuilder: FormBuilder,
        private businessagrremnt: BusinessServices,
        private route: ActivatedRoute,
        private businessServices: BusinessServices,
        private cacheHandlerService: CacheHandlerService,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.businessId = this.route.snapshot.parent?.params['bid'];
        this.businessInfo = this.route.snapshot.data['list'];
    }
    //#endregion
    //#region page-events
    /**
        * bind AgreementName based on ApplicationId,bind license agreement dropdown,edit license agreement
        */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.ApplicationId === Application.DIN_HMS
            || BaseServices.ApplicationId === Application.IK || BaseServices.ApplicationId === 4) {  // #KW-176
            this.isDinHMSLogin = true;
            this.isDinHMS = true;
            this.businessEdit = new BusinessEdit();
            this.businessEdit = JSON.parse(localStorage.getItem('detailsInfo')!);
        }
        if(BaseServices.ApplicationId == 5){
            this.isMedi3 = true;
        }
        this.licenseCategory = this.route.snapshot.data['licenseCategoryList'];
        this.isAdmin = (+BaseServices.roleId === UserRole.ADMIN || +BaseServices.roleId === UserRole.PORTAL) ? true : false;
        this.uploadInput = new EventEmitter<UploadInput>();
        switch (BaseServices.UserRole.toUpperCase()) {
            case 'ADMIN': this.isHide = false;
                this.isLinkHide = true;
                this.PurchaseDateOptions.componentDisabled = false;
                break;
            case 'EDITOR': this.isHide = true;
                this.isLinkHide = false;
                this.PurchaseDateOptions.componentDisabled = true;
                break;
            case 'USER': this.isHide = false;
                this.isLinkHide = true;
                this.PurchaseDateOptions.componentDisabled = false;
                break;
            case 'GUEST': this.isHide = false;
                this.isLinkHide = true;
                this.PurchaseDateOptions.componentDisabled = false;
                break;
            case 'PORTAL': this.isHide = false;
                this.isLinkHide = true;
                this.PurchaseDateOptions.componentDisabled = false;
                break;
        }
        switch (BaseServices.ApplicationId) {
            case 1: this.agreementName = 'Kuba';
                break;
            case 2: this.agreementName = 'Kuba';
                break;
            case 3: this.agreementName = 'Kuba';
                break;
            case 4: this.agreementName = 'Kuba';
                break;
        }
        this.roleId = +BaseServices.roleId;
        if (this.route.snapshot.parent?.url[0].path === 'new') {
            this.businessEdit = new BusinessEdit();
            this.businessEdit = JSON.parse(localStorage.getItem('detailsInfo')!);
        } else {
            this.agreement = this.route.snapshot.data['agreement'];
            this.usersCount = this.route.snapshot.data['usersCount'];
            this.employeesCount = this.route.snapshot.data['employeesCount'];
        }
        this.licenseagrmtForm = this.formBuilder.group({
            ExpiryDate: [],
            PurchaseDate: ['', Validators.required],
            Description: [''],
            AgreementName: [''],
            SalesPerson: [],
            LicenseMonth: [],
            LicenseCategory: [, [Validators.required, Validators.nullValidator]],
            PricePerMonth: [0, Validators.required],
            NoOfEmployees: [this.usersCount],
            NoOfUsers: [this.employeesCount]
        });

        if (BaseServices.ApplicationId == 4) {
            this.licenseagrmtForm.controls['LicenseCategory'].setValue(6);
        }

        this.licenseCategoryList = [];

        this.translate.stream('SELECT_LICENSE_CATEGORY').
            subscribe(val => {
                this.licenseCategoryList = [];
                this.licenseCategoryList.push({ label: this.translate.instant('SELECT'), value: null })
                this.licenseCategory.forEach((element:any) => {
                    if (element) {
                        if (BaseServices.ApplicationId == 4 || BaseServices.ApplicationId == 2 ? element.Id != 5 : element.Id) {
                            this.licenseCategoryList.push({ label: this.translate.instant(element.TranslateKey), value: element.Id });
                        }
                    }
                });
            });



        /**
         * bind license months dropdown
         */
        this.subscriptions.push(this.translate.stream('SELECT_MONTH').subscribe(val => {
            this.licenseMonths = [];
            this.licenseMonths.push(
                { label: val.SELECT, value: '00' },
                { label: val.JANUARY, value: '01' },
                { label: val.FEBRUARY, value: '02' },
                { label: val.MARCH, value: '03' },
                { label: val.APRIL, value: '04' },
                { label: val.MAY, value: '05' },
                { label: val.JUNE, value: '06' },
                { label: val.JULY, value: '07' },
                { label: val.AUGUST, value: '08' },
                { label: val.SEPTEMBER, value: '09' },
                { label: val.OCTOBER, value: '10' },
                { label: val.NOVEMBER, value: '11' },
                { label: val.DECEMBER, value: '12' },
            );
        }));
        /**
         * edit agreement
         */
        if (this.agreement) {
            let purchaseDate: any;
            let expiryDate: any;
            purchaseDate = HelperService.formatInputDate(this.agreement.PurchaseDate ? this.agreement.PurchaseDate : null);
            let purchasedate: any = HelperService.formatDate(this.agreement.PurchaseDate)
                ? new Date(HelperService.formatDate((this.agreement.PurchaseDate))) : null;
            this.ExpiryDateOptions = HelperService.setDateRange(this.ExpiryDateOptions, purchasedate, 'startDate');
            expiryDate = HelperService.formatInputDate(this.agreement.ExpiryDate ? this.agreement.ExpiryDate : null);
            let expirydate: any = HelperService.formatDate(this.agreement.ExpiryDate) ?
                new Date(HelperService.formatDate((this.agreement.ExpiryDate))) : null;
            this.PurchaseDateOptions = HelperService.setDateRange(this.PurchaseDateOptions, expirydate, 'endDate');
            this.editAgreement = {
                ExpiryDate: expiryDate ? expiryDate : '',
                PurchaseDate: purchaseDate ? purchaseDate : '',
                Description: this.agreement.Description,
                SalesPerson: this.agreement.SalesPerson,
                LicenseMonth: this.agreement.LicenseMonth,
                AgreementName: this.agreementName,
                LicenseCategory: this.agreement.LicenseCategoryId,
                PricePerMonth: this.agreement.PricePerMonth,
                NoOfEmployees: this.employeesCount,
                NoOfUsers: this.usersCount
            };
            (<FormGroup>this.licenseagrmtForm)
                .setValue(this.editAgreement, { onlySelf: true });

            if (this.agreement.DocumentPath) {
                this.document = this.agreement.DocumentPath;
            }

            if (BaseServices.ApplicationId == 4 && this.agreement.LicenseCategoryId == null) {
                this.licenseagrmtForm.controls['LicenseCategory'].setValue(6);
            }

        }
    }
    //#endregion
    //#region methods
    /**
    * get current date
    */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate()
        }
    }
    /**
     * on upload event
     * @param output
     */
    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
            // uncomment this if you want to auto upload files when added
            const event: UploadInput = {
                type: 'uploadAll',
                url: environment.BASE_URL + '/file/upload/business/licenseagreement',
                method: 'POST',
                data: {
                    'ApplicationId': `${BaseServices.ApplicationId}`,
                    'BusinessId': `${this.businessId ? this.businessId : 0}`,
                    'ParentId': `${this.businessId ? this.businessId : 0}`,
                    'Status': '1',
                    'CreatedOn': `${new Date()}`,
                    'ModifiedBy': `${BaseServices.UserId}`,
                    'ModifiedOn': `${new Date()}`,
                }
            };
            this.uploadInput.emit(event);
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.isFileChoosed = true;
            this.files.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'done') {
            this.licenseAgreementDocument = output.file?.response;
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
        }

        this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
        this.fileName = this.files ? this.files[0].name : '';
    }

    /**
       * Method for setting date range
       * @param dateConfig {IMyDpOptions}
       * @param event{IMyDateModel}
       * @param type  {string}
       */
    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: any = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }

    onSaveBusinessDetails(isNavigate: any) {
        if (this.businessEdit && this.isDinHMS) {
            this.businessEdit.CreatedOn = new Date();
            if (this.businessEdit.Id === 0) {
                this.subscriptions.push(this.businessServices.create(this.businessEdit).subscribe((result: any) => {
                    if (result) {
                        this.businessId = result.Id;
                        this.businessEdit.Id = result.Id;
                        this.businessEdit.ModifiedBy = BaseServices.UserId;
                        localStorage.setItem('detailsInfo', JSON.stringify(this.businessEdit));
                        this.onSubmitTemplateBased(isNavigate);
                    }
                }));
            } else {
                this.businessEdit.ModifiedBy = BaseServices.UserId;
                this.subscriptions.push(this.businessServices.update(this.businessId, this.businessEdit).subscribe(result => {
                    if (result) {
                        this.onSubmitTemplateBased(isNavigate);
                    }
                }));
            }
        } else {
            this.onSubmitTemplateBased(isNavigate);
        }
    }

    onSubmitTemplateBased(isNavigate: any) {


        if (this.businessEdit || +this.businessId !== 0) {
            let bId = this.businessEdit ? this.businessEdit.Id : +this.businessId;
            if (bId !== 0) {
                this.businessServices.getAgreementByBusinessId(bId).
                    subscribe((result: any) => {
                        if (result) {
                            this.agreement = result;
                        }

                        let businessAgreement = (this.agreement) ? this.agreement : new BusinessAgreement();
                        businessAgreement.LicenseCategoryId = this.licenseagrmtForm.value.LicenseCategory;
                        businessAgreement.PricePerMonth = this.licenseagrmtForm.value.LicenseCategory === 1
                            ? null : this.licenseagrmtForm.value.PricePerMonth;
                        businessAgreement.Description = this.licenseagrmtForm.value.Description;
                        businessAgreement.SalesPerson = this.licenseagrmtForm.value.SalesPerson;
                        businessAgreement.PurchaseDate = this.licenseagrmtForm.value.PurchaseDate ?
                            HelperService.formatDateFilter(this.licenseagrmtForm.value.PurchaseDate.formatted) : null!;
                        businessAgreement.ExpiryDate = this.licenseagrmtForm.value.ExpiryDate ?
                            HelperService.formatDateFilter(this.licenseagrmtForm.value.ExpiryDate.formatted) : null!;
                        businessAgreement.LicenseMonth = this.licenseagrmtForm.value.LicenseMonth;
                        businessAgreement.AgreementName = this.agreementName;
                        businessAgreement.NoOfEmployees = this.employeesCount;
                        businessAgreement.NoOfUsers = this.usersCount;
                        if (this.agreement) {
                            businessAgreement.Id = this.agreement.Id;
                            businessAgreement.BusinessId = this.agreement.BusinessId;
                            businessAgreement.DocumentPath = this.licenseAgreementDocument ? (this.licenseAgreementDocument.path ?
                                this.licenseAgreementDocument.path : this.document) : this.document;
                            this.subscriptions.push(this.businessagrremnt.updateAgreement(businessAgreement)
                                .subscribe(updateResponse => {
                                    if (updateResponse) {
                                        this.toasterComponent.callToast();
                                        if (this.isDinHMS && isNavigate) {
                                            if (this.route.snapshot.parent?.url[0].path === 'new') {
                                                this.router.navigate([`./../../edit/${this.businessId}/features`],
                                                    { relativeTo: this.route });
                                            } else {
                                                this.router.navigate([`../features`], { relativeTo: this.route });
                                            }
                                        }
                                    }
                                }));
                        } else {
                            businessAgreement.ApplicationId = BaseServices.ApplicationId;
                            businessAgreement.BusinessId = this.businessId;
                            businessAgreement.Status = '1';
                            businessAgreement.DocumentPath = this.licenseAgreementDocument ? (this.licenseAgreementDocument.path ?
                                this.licenseAgreementDocument.path : this.document) : this.document;
                            this.subscriptions.push(this.businessagrremnt.add(businessAgreement)
                                .subscribe(addResponse => {
                                    if (addResponse) {
                                        this.toasterComponent.callToast();
                                        if (this.isDinHMS && isNavigate) {
                                            if (this.route.snapshot.parent?.url[0].path === 'new') {
                                                this.router.navigate([`./../../edit/${this.businessId}/features`],
                                                    { relativeTo: this.route });
                                            } else {
                                                this.router.navigate([`../features`], { relativeTo: this.route });
                                            }
                                        }
                                    }
                                }));
                        }
                    });
            }
        }

    }
    /***
     * clear fields
     */
    clear() {
        this.licenseagrmtForm = this.formBuilder.group({
            ExpiryDate: [],
            PurchaseDate: [''],
            Description: [''],
            AgreementName: [''],
            SalesPerson: [],
            LicenseMonth: [],
            LicenseCategory: [],
            PricePerMonth: [0],
            NoOfEmployees: [0],
            NoOfUsers: [0]
        });
        this.fileName = '';
        if (this.agreement) {
            this.agreement.DocumentPath = '';
        }

    }

    /**
      * upload event
      */
    startUpload(): void {
        const event: UploadInput = {
            type: 'uploadAll',
            url: environment.BASE_URL + '/file/upload/business/licenseagreement',
            method: 'POST',
            data: {
                'ApplicationId': `${BaseServices.ApplicationId}`,
                'BusinessId': `${this.businessId}`,
                'ParentId': `${this.businessId ? this.businessId : 0}`,
                'Status': '1',
                'CreatedOn': `${new Date()}`,
                'ModifiedBy': `${BaseServices.UserId}`,
                'ModifiedOn': `${new Date()}`,
            }
        };
        this.uploadInput.emit(event);
    }
    /**
     * edit status
     * @param Id {number}
     * @param Status {number}
     * @param endDate {string}
     */
    editBusinessStatus(Id: number, Status: string, endDate: any) {
        let currentDate = new Date();
        let date = currentDate.toString();
        let expiryDate = new Date(endDate.formatted);
        expiryDate.setHours(23);
        expiryDate.setMinutes(59);
        expiryDate.setSeconds(59);
        let eDate = expiryDate.toString();
        if (date === eDate) {
            {
                this.subscriptions.push(this.businessServices.statusUpdate(Id, Status).subscribe(() => {
                }));
            }

        }

    }
    //#endregion

    //#region control-events

    /**
   * Event handler for End date
   * @param event {IMyDateModel}
   */
    onExpiryDateChanged(event: IMyDateModel) {
        let date: any = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.PurchaseDateOptions = this.setDateInput(this.PurchaseDateOptions, event, 'endDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.PurchaseDateOptions);
            this.PurchaseDateOptions = copyConfig;
        }
    }
    /**
    * Event handler for Start date
    * @param event {any}
    */
    onPurchaseDateChanged(event: IMyDateModel) {
        this.ExpiryDateOptions = this.setDateInput(this.ExpiryDateOptions, event, 'startDate');
    }
    /**
    * remote mode event
    * @param event {any}
    * @param business {any}
    */
    rModeLicenceAgreement() {
        let business = this.businessInfo.filter((x: any) => x.Id === +this.businessId);
        if (this.remoteWindow) {
            this.remoteWindow.close();
        }
        this.cacheHandlerService.clearCachedData();
        let businessSetting = { 'BusinessName': business[0].CompanyName, 'BusinessLogo': business[0].BusinessLogo, 'teat': 1 };
        sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
        sessionStorage.setItem('RModeBusinessId', business[0].Id);
        let windowOrigin = window.location.origin;
        let windowControl = 'location=yes,width=' + screen.availWidth + ', height = ' + screen.availHeight
            + ', scrollbars = yes, status = yes';
        this.remoteWindow = window.open(windowOrigin + '/ik/app/business/' + business[0].Id + '/dashboard', 'Remote', windowControl)!;
    }

    onCheckValidation() {
        
        let conTrue: any = true;
        let conFalse: any = false;
        if (this.licenseagrmtForm) {
            if (this.licenseagrmtForm.value.PurchaseDate
                && (this.licenseagrmtForm.value.AgreementName || BaseServices.ApplicationId === Application.DIN_HMS
                    || BaseServices.ApplicationId === Application.IK || BaseServices.ApplicationId === Application.AHLSELL)) {
                if (BaseServices.ApplicationId === Application.DIN_HMS || BaseServices.ApplicationId === Application.IK ||
                    BaseServices.ApplicationId === Application.AHLSELL) {
                    if (this.licenseagrmtForm.value.LicenseCategory) {
                        if (this.licenseagrmtForm.value.LicenseCategory !== 1) {
                            if (this.licenseagrmtForm.value.PricePerMonth === null
                                || this.licenseagrmtForm.value.PricePerMonth === '') {
                                return conTrue;
                            } else {
                                return conFalse
                            }
                        }
                    } else {
                        return conTrue;
                    }
                } else {
                    return conFalse;
                }
            } else {
                return conTrue;
            }
        } else {
            return conTrue;
        }
    }

    onPriceChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
