import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';

import { ContractorContacts, SubContractor } from '../models/contractor';
import { BaseServices } from './../../kuba.services';
import { UserService } from './../../users/services/user.service';
import { User } from './../models/contractor';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SubContractorServices {

    constructor(
        private http: HttpClient,
        private userService: UserService) { }

    // List all SubContractor
    list() {
        return this.http.get(environment.BASE_URL + '/contractor', BaseServices.headerOption)
            .map(result => result);
    }

    getContractorByBusiness(businessId: any) {
        return this.http.get<any>(environment.BASE_URL + '/contractorsbybusiness/' + businessId, BaseServices.headerOption)
            .map(result => result);
    }

    getProjectByUser(Id: number, businessId: number) {
        return this.http.get<any>(environment.BASE_URL + '/project/getuserprojects/' + Id + '/' + businessId + '/' + BaseServices.FeatureId,
            BaseServices.headerOption)
            .map(result => result);
    }
    get(id: number) {
        return this.http.get<any>(environment.BASE_URL + '/contractors/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    add(data: any) {
        return this.http.post(environment.BASE_URL + '/contractors', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    update(id: number, data: SubContractor) {
        return this.http.put(environment.BASE_URL + '/contractors/' + id, data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    listContractorContact() {
        return this.http.get(environment.BASE_URL + '/contractorcontacts', BaseServices.headerOption)
            .map(result => result);
    }

    addContractorContact(data: any) {
        return this.http.post(environment.BASE_URL + '/contractorcontact', data, BaseServices.headerOption)
            .map(result => result);
    }

    updateContractorContact(data: any) {
        return this.http.put(environment.BASE_URL + '/contractorcontact', data, BaseServices.headerOption)
            .map(result => result);
    }

    getContractorContact(id: any) {
        return this.http.get(environment.BASE_URL + '/contractorcontacts/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    deleteContractorContact(id: any) {
        return this.http.delete(environment.BASE_URL + '/contractorcontact/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    statusUpdate(id:any , status:any) {
        return this.http.put(environment.BASE_URL + '/contractorstatusupdate/' + id, status, BaseServices.headerOption)
        .map(result => result);
    }

    deleteMultipleContractor(ids: any) {
        return this.http.put(environment.BASE_URL + '/contractors', ids, BaseServices.headerOption)
            .map(result => result);
    }

    getContractorContactUser(featureKey: number): Observable<User> {
        return this.http.get<any>(`${environment.BASE_URL}/contractorcontactuser/${featureKey}`, BaseServices.headerOption)
            .map(result => result);
    }

    getContractorUser(featureKey: number): Observable<User> {
        return this.http.get<any>(`${environment.BASE_URL}/contractoruser/${featureKey}`, BaseServices.headerOption)
            .map(result => result);
    }
    
    addUser(data: any) {
        return this.userService.addusers(data);
    }

    updateUser(data: any) {
        return this.userService.updateUser(data.Id, data);
    }
    getUserById(Id: any) {
        return this.userService.getUsersById(Id);
    }

}
