<a href="javascript:void(0)" (click)="showModal()" class="img-preview" id="img-file-{{id}}">
    <img [src]="imgSrc" alt="">
</a>

<div class="img-modal" *ngIf="showModalWindow" id="img-pop-{{id}}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4>
                    <span class="ic-sm">
                        <img [src]="imgSrc" alt="Image File">
                    </span>
                    <span>
                        {{imgName}}
                    </span>
                </h4>
                <button (click)="hideModal()" class="close">
                    <span class="icon ic-md icon-multiply"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="file-preview-wrapper">
                    <div class="file-preview-box">
                        <img [src]="imgSrc" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>