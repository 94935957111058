import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Rights } from 'src/app/_models';
import { ToasterComponent } from '../../../_directives/toaster.component';
import { HelpSupport } from '../models/helpsupport';
import { HelpSupportServices } from '../services/help-support.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
    selector: 'helpsupportlist',
    templateUrl: './help-supportList.component.html'
})
export class HelpSupportListComponent implements OnInit {

    //#region variable
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    public helpsupport: HelpSupport[];
    categoryName: SelectItem[];
    isAdministrateHelp = true;
    isHideDeleteIcon = true;
    isUser = true;
    isAdmin: boolean;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor

    constructor(
        public helpsupportServices: HelpSupportServices,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) { 
        this.subscriptions.push(this.translate.stream('CATEGORY_NAMES').subscribe(val => {
            this.categoryName = [];
            this.categoryName.push(
              { label: val.SELECT, value: null },
              { label: val.STORY, value: 1 },
              { label: val.ARTICLES, value: 2 },
              { label: val.VIDEOS, value: 3 },
              { label: val.QUESTIONSANDANSWERS, value: 4 },
              {label: val.EMAILID, value: 5}
            );
          }));
     }

    //#endregion

    //#region page-event

    /**
     * page load event
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        let helpsupport = [];
        helpsupport = this.route.snapshot.data['list'];
        if (this.router.url.indexOf('admin') > 0) {
            if (+BaseServices.roleId === 3) {
                this.isHideDeleteIcon = false;
                this.isAdmin = true;
            } else {
                this.isAdmin = false;
                this.isHideDeleteIcon = true;
            }
        }
        if (helpsupport != null) {
            this.helpsupport = helpsupport.filter((x: any) => x.Status === 1);
        }
        if (BaseServices.roleId === '5') {
            this.isUser = false;
        }
        this.onAdministrateInternalNews();
    }

    //#endregion

    //#region control-event
    /**
     * delete news
     */
    confirm(news: any) {

        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_HELPSUPPORT'),
            accept: () => {
                this.helpsupportServices.delete(news).then(isNewsDeleted => {
                    if (isNewsDeleted) {
                        this.fetchNewsData();
                        this.toasterComponent.callToastDlt();
                    }
                });
            }
        });
    }

    /**
     * refresh list
     */
    fetchNewsData() {
        this.subscriptions.push(this.helpsupportServices.adminHelpSupportlist().subscribe(res => {
            this.helpsupport = res.filter((x: any) => x.Status === 1);
        }));
    }
    /**
     * Edit & update News based on administrate news rights
     */

    onAdministrateInternalNews() {
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_NEWS;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.isAdministrateHelp = BaseServices.checkUserRights(
                userRightsId,
                role
            );
            this.isHideDeleteIcon = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
