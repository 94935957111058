import { NgModule } from '@angular/core';
import { Route } from '@angular/router';

import { NotificationUserListtResolver } from './../notifications/services/notification.resolver.service';
import { NotificationUserComponent } from './notificationuser.component';

export const notificationUserRoutes: Route[] = [
    {
        path: '',
        resolve: {
            list: NotificationUserListtResolver,
        },
        children: [
            {
                path: 'notificationuser',
                component: NotificationUserComponent
            }
        ]
    }
];
