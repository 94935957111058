<div class="tab-content">
    <form [formGroup]="myForm" novalidate (ngSubmit)="save()">
        <div formArrayName="pageProperties" class="properties-wrapper">
            <!-- <div *ngFor="let Properties of userFormGroups.controls; let i=index" -->
            <div *ngFor="let Properties of myForm.controls['pageProperties']['controls']; let i=index"
              class="sub-panel">
                <div class="sub-panel-title">
                    <span> {{title | translate}} {{ i + 1}}</span>
                    <span class="sub-panel-actions pull-right">
                        <!-- <a class="btn-icon" href="javascript:void(0)" *ngIf="userFormGroups.controls.length > 1"
                            (click)="removeProperties(i)">
                            <i class="icon ic-sm icon-trash"></i>
                        </a> -->
                        <a class="btn-icon text-danger" href="javascript:void(0)"
                          *ngIf="myForm.controls['pageProperties']['controls'].length > 1"
                          (click)="removeProperties(i)">
                            <i class="icon ic-sm icon-trash"></i>
                        </a>
                    </span>
                </div>
                <div class="sub-panel-content" [formGroupName]="i">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>
                                    <span translate>TYPE</span>
                                    <span class="required">*</span>
                                </label>
                                <div class="input-btn-group">
                                    <p-dropdown [filter]="true" formControlName="type"
                                      [options]="propertyType | dropdownSelectTranslate" [style]="{'width':'100%'}"
                                      dataKey="value"></p-dropdown>
                                    <button type="button" class="btn btn-outline-primary"
                                      (click)="typeOfBusinessProperty()">
                                        <i class="pi pi-plus"></i>
                                    </button>
                                </div>
                                <control-messages [control]="myForm.controls['type']"></control-messages>
                            </div>
                            <div class="form-group">
                                <label>
                                    <span translate>DESCRIPTION</span>
                                    <span class="required">*</span>
                                </label>
                                <input type="text" class="form-control" formControlName="Description">
                                <control-messages [control]="myForm.controls['Description']"></control-messages>
                            </div>
                            <div class="form-group">
                                <label>
                                    <span translate>NAME_OF_THE_OBJECT</span>
                                </label>

                                <input type="text" class="form-control" formControlName="Name" maxlength="100">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>
                                    <span translate>NUM_FLOOR</span>
                                </label>
                                <input type="number" class="form-control" formControlName="FloorCount"
                                  (keypress)="onMobileNumber($event,20)">
                            </div>
                            <div class="form-group">
                                <label>
                                    <span translate>NUM_APARTMENTS</span>
                                </label>
                                <input type="number" class="form-control" formControlName="ApartmentCount"
                                  (keypress)="onMobileNumber($event,20)">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <div [hidden]="true">
                                    <input type="text" formControlName="Id">
                                    <input type="text" formControlName="Status">
                                    <input type="text" formControlName="BusinessId">
                                </div>
                                <!-- <div class="col-sm-6" formArrayName="checkboxProperties">
                                    <div class="form-check"
                                      *ngFor="let def of Properties.controls['checkboxProperties']['controls']; let j = index">
                                        <label class="form-cehck-label" formGroupName="{{j}}">
                                            <input type="checkbox" [formControlName]="j"
                                              (change)="updateBusinessPropertyModel($event, i, objectArray[j].Id)"
                                              value="{{objectArray[j].BusinessPropertyObjectId}}">
                                            {{objectArray[j].TranslationKey | translate}}
                                        </label>
                                        <input type="text" style="width:35px;margin-left: 4px;"
                                          class="form-check-input py-0 px-1" pInputText *ngIf="j === 6"
                                          (keypress)="onQuantityChange($event,5)" formControlName="Quantity" />
                                    </div>
                                </div> -->
                                <div class="col-sm-6" formArrayName="checkboxProperties">
                                    <div class="form-check" *ngFor="let def of Properties.get('checkboxProperties').controls; let j = index">
                                        <div formGroupName="{{j}}"> <!-- Move formGroupName to an inner div -->
                                            <label class="form-cehck-label">
                                                <input type="checkbox" [formControlName]="j"
                                                    (change)="updateBusinessPropertyModel($event, i, objectArray[j].Id)"
                                                    value="{{objectArray[j].BusinessPropertyObjectId}}">
                                                {{objectArray[j].TranslationKey | translate}}
                                            </label>
                                            <input type="text" style="width:35px;margin-left: 4px;"
                                                class="form-check-input py-0 px-1" pInputText *ngIf="j === 6"
                                                (keypress)="onQuantityChange($event,5)" formControlName="Quantity" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <div class="row">
                <div class="col-sm-6">
                    <button type="submit" class="btn btn-primary" [disabled]="!myForm.valid" translate>SAVE</button>
                </div>
                <div class="col-sm-6 text-right">
                    <a (click)="addbusinessProperties()" class="btn btn-outline-primary" href="javascript:void(0);"
                      translate>
                        <i class="icon ic-sm icon-add"></i>
                        ADD_PROPERTY_OBJECT
                    </a>
                </div>
            </div>
        </div>
    </form>
</div>
<p-dialog header="{{'ADD_EDIT_PROPERTY'|translate}}" [(visible)]="showBusinessProperty" modal="modal"
  [style]="{width: '300px'}" [resizable]="false" [draggable]="false" (onHide)="onBeforePropertyDialogHide()">
    <form [formGroup]="propertyObjectForm">
        <div class="form-group">
            <label for="name" class="col-form-label">
                <span translate>TYPE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input pInputText type="text" formControlName="Name" />
                <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
                <span style="color:red" *ngIf="isNameAllocated">
                    <span translate>CANNOT_DELETE</span>
                </span>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary"
              (click)="showPropertyObjectTable = !showPropertyObjectTable">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="savePropertyType();"
              [disabled]="!propertyObjectForm.controls['Name'].valid">
                <span translate>SAVE</span>
            </button>
        </div>
        <div class="table-view" *ngIf="showPropertyObjectTable">
            <!-- Table starts -->
            <p-table #propertyObjec [value]="propertyObjectData" [rows]="10" [paginator]="true" [pageLinks]="3"
              [rowsPerPageOptions]="[5,10,20]">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'NAME'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>
                            <input pInputText type="text"
                              (input)="propertyObjec.filter($event.target.value, 'Name', 'contains')" placeholder=""
                              class="p-column-filter">
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-propertyObject>
                    <tr>
                        <td>
                            <span>{{propertyObject.Name}}</span>
                        </td>
                        <td>
                            <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                              (click)="editPropertyObject(propertyObject.Name,propertyObject.Id)">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </button>
                            <button type="button" class="btn btn-icon" (click)="deleteTypeOfProperty(propertyObject.Id)"
                              title="{{'DELETE' | translate}}">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="2">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table ends -->
        </div>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>