<nav>
    <ul class="nav nav-pills">
        <li [routerLinkActive]="['active']">
            <a [routerLink]="['usedinstruments']">{{'INSTRUMENTS' | translate}}</a>
        </li>
        <li [routerLinkActive]="['active']">
            <a [routerLink]="['visualcontrol']">{{'VS_STATUS' | translate}}</a>
        </li>
        <li [routerLinkActive]="['active']">
            <a [routerLink]="['earthleakage']">{{'EARTH_LEAKAGE_BREAKER' | translate}}</a>
        </li>
        <li [routerLinkActive]="['active']">
            <a [routerLink]="['powerleakage']">{{'POWER' | translate}}</a>
        </li>
        <li [routerLinkActive]="['active']">
            <a [routerLink]="['temperature']">{{'TEMPERATURE' | translate}}</a>
        </li>
        <li [routerLinkActive]="['active']">
            <a [routerLink]="['comments']">{{'COMMENTS' | translate}}</a>
        </li>
    </ul>
</nav>