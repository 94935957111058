import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import {
    VehicleControlListResolver,
    VehicleControlEditResolver,
    VehicleControlListByVehicleResolver
} from './services/vehicle-control.resolver.service';
import { VehicleControlListComponent } from './components/vehicle-control-list.component';
import { VehicleControlService } from './services/vehicle-control.services';
import { VehicleControlComponent } from './vehicle-control.component';
import { VehicleControlFormComponent } from './components/vehicle-control-form.component';
import { VehicleControlCompleteComponent } from './components/vehicle-control-complete.component';
import { AppSharedModule } from './../shared/appShared.module';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { HttpClientModule } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        MyDatePickerModule,
        KubaExportModule
    ],
    exports: [
        VehicleControlListComponent,
        VehicleControlFormComponent,
        VehicleControlCompleteComponent
    ],

    declarations: [
        VehicleControlListComponent,
        VehicleControlFormComponent,
        VehicleControlCompleteComponent
    ],
    providers: [
        VehicleControlService,
        VehicleControlListResolver,
        VehicleControlEditResolver,
        VehicleControlListByVehicleResolver
    ]
})
export class VehicleControlModule {}
