import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { PdfSetting } from './../../../_services/helper.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from 'src/app/_services/helper.service';
import { SelectItem } from 'primeng/api';
import { SuggestionsServices } from '../services/suggestion.services';
import { Suggestion } from '../models/suggestion';
import { ConfirmationService } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'followups-suggestionbox',
    templateUrl: 'suggestion-list.component.html'
})
export class FollowUpSuggestionListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    suggestionbox: Suggestion[];
    statuses: SelectItem[];
    articles: Array<any>
    deleteid: number;
    isVisible = true;
    additionalData: any;
    userId: number;
    showEditIcon = true;
    private subscriptions: Subscription[] = [];
    //#endregion
    //#region constructor
    /**
     * constructor
     * @param suggestionsServices {SuggestionsServices}
     * @param route {ActivatedRoute}
     * @param confirmationService {confirmationService}
     * @param router {Router}
     */
    constructor(private suggestionsServices: SuggestionsServices,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        public router: Router,
        private translate: TranslateService) {
        this.additionalData = {
            fileName: 'Suggestion List',
            header: 'Suggestion List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Title', dataKey: 'Title' },
                { title: 'Description', dataKey: 'Description' },
                { title: 'Suggested by', dataKey: 'CreatorName' },
                { title: 'Suggested on', dataKey: 'CreatedOn' }
            ]
        };

    }
    //#endregion
    //#region page-events
    /**
         * page load event
         */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.userId = BaseServices.UserId;
        switch (BaseServices.UserRole) {
            case 'Admin': this.isVisible = true;
                break;
            case 'Editor': this.isVisible = true;
                break;
            case 'User': this.isVisible = false;
                break;
            case 'Guest':
                this.isVisible = true;
                this.showEditIcon = false;
                break;
            case 'Portal': this.isVisible = true;
                break;
        }
        this.fetchData();
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.INACTIVE, value: 0 },
                { label: val.ACTIVE, value: 1 }
            );
        }));
    }
    //#endregion
    //#region methods
    /**
     * delete suggestion from list
     * @param suggestiondata {{any}}
     */
    confirm(suggestionData: Suggestion) {
        this.deleteid = suggestionData.Id!;
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.suggestionsServices.deleteById(this.deleteid, suggestionData).subscribe(() => {
                    this.refreshData();
                    this.toasterComponent.callToastDlt();
                }
                ));

            }
        });
    }
    refreshData() {
        this.subscriptions.push(this.suggestionsServices.list().subscribe(result => {
            let list = result;
            this.suggestionbox = [];
            if (list) {
                list.forEach((element: any) => {
                    let date = HelperService.formatDate(element.CreatedOn);
                    return (element.CreatedOn = date)
                });
            }
            this.suggestionbox.push(...list)
        }))

    }

    /**
     * bind suggestion list
     */
    fetchData() {
        this.suggestionbox = [];
        let list = this.route.snapshot.data['list'];
        if (list) {
            this.suggestionbox.push(...this.manipulateData(list));
        }
    }

    manipulateData(list: any) {
        if (list) {
            list.forEach((e: any) => {
                let date = HelperService.formatDate(e.CreatedOn);
                return (e.CreatedOn = date)
            });
        }
        return list;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
