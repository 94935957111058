<div class="main-content ">
    <div class="main-heading">
        <strong> <span translate>FRAMEWORK_AGREEMENT</span> - {{ pageTitle | capitalize}}</strong>
    </div>
    <div class="card">
        <div class="card-body">
            <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="faId"></ecpl-tab-navigation>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>