import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { FrameworkAgreement } from 'src/app/kuba/framework-agreement/models';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FrameworkAgreementServices {

    constructor(private http: HttpClient) {
    }

    getClientContactUser(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/clientcontactsuser/' + clientId, BaseServices.headerOption)
            .map(result => result);
    }

    getFrameworkList(businessId: number) {
        return this.http.get(environment.BASE_URL + `/framework/name/${businessId}`, BaseServices.headerOption)
            .map((result: any) => result);
    }

    create(newFramework: FrameworkAgreement) {
        return this.http.post(environment.BASE_URL + '/framework/save', newFramework, BaseServices.headerOption)
            .map(result => result);
    }

    getFrameworklistByBusiness() {
        return this.http.get(environment.BASE_URL + '/framework/getall/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getFrameworkByUser(Id: number) {
        return this.http.get(environment.BASE_URL + '/framework/getuserFA/' + Id + '/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getFrameworkById(id: number) {
        let result: any;
        return this.http.get(environment.BASE_URL + '/framework/' + id, BaseServices.headerOption)
            .map((res: any) => res);
    }

    update(ExistingFramework: FrameworkAgreement) {
        return this.http.put(environment.BASE_URL + '/framework/update', ExistingFramework, BaseServices.headerOption)
            .map(result => result);
    }

    deleteFramework(id: number) {
        return this.http.delete(environment.BASE_URL + '/framework/delete/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    getFrameworkFeatures(businessId: number) {
        let featureId: number;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        featureId = workingModule.id;
        return this.http.get(environment.BASE_URL + '/business/' + businessId, BaseServices.headerOption)
            .map((result: any) => {
                let feature = result.Features;
                let frameworkAdditionalFeature = _.find(JSON.parse(feature), { 'id': featureId, 'access': true });
                return frameworkAdditionalFeature;
            });
    }

    getActiveClientContactUser(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/activeclientcontactsuser/' + clientId, BaseServices.headerOption)
            .map(result => result);
    }
}
