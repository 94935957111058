import { APP_BASE_HREF } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  HostListener,
  VERSION,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InitialServices } from './_services/initial.services';
import { environment } from '../environments/environment'; /* Not used */

@Component({
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    public translate: TranslateService /* Not used */,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private initialServices: InitialServices,
    private router: Router
  ) {}

  ngOnInit() {
    if (
      window.location.pathname != '/ik/login' &&
      window.location.pathname != '/youtube.com' &&
      !(window.location.pathname.indexOf('reset-password') > -1) &&
      !(window.location.pathname.indexOf('image-uploader') > -1)
    ) {
      if (!(window.name === 'Remote' || window.name === 'FAQ')) {
        this.router.navigate(['/login']);
      }
    }
    this.initialServices.appInitializing();
  }
}
