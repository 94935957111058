<div class="panel panel-default">
    <div class="panel-body">
        <div class="row">
            <div *ngIf="userData == null">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                    <label class="control-label" style="padding: 30px 30px;">
                        <span translate style="color: red; font-size: 16px;">{{'NO_ADVISOR'| translate}}</span>
                    </label>
                </div>
            </div>
            <div class="col-md-6" *ngIf="userData != null">
                <div *ngIf="userData.Image != null">
                    <img src="{{userData.Image}}" alt="" width="200" class="thumbnail">
                </div>
                <div>
                    <label class="control-label">
                        <span translate>{{'NAME'| translate}} : {{userData.Name}}</span>
                    </label>
                </div>
                <div>
                    <label class="control-label">
                        <span translate>{{'ADDRESS' | translate}} : {{userData.Address}}</span>
                    </label>
                </div>
                <div>
                    <label class="control-label">
                        <span translate>{{'ZIP_CODE' | translate}} : {{userData.ZipCode}}</span>
                    </label>
                </div>
                <div></div>
                <div>
                    <label class="control-label">
                        <span translate>{{'EMAILID' | translate}} : {{userData.Email}}</span>
                    </label>
                </div>
                <div>
                    <label class="control-label">
                        <span translate>{{'MOBILE' | translate}} : {{userData.Mobile}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>