<div class="col-md-12">
    <div class="form-group">
        <label for="title" class="col-form-label">
            <span translate>DEPARTMENT</span>
        </label>
        <div>
            <input type="text" #Name class="label-input form-control" [(ngModel)]="selectedDept.Name">
        </div>
    </div>
</div>
<p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">

        <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType">
            <span translate>VIEW</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="saveDept()">
            <span translate>SAVE</span>
        </button>
    </div>
    <div *ngIf="viewType">
        <div class="table-view">
            <!-- Table starts -->
            <p-table [value]="editDepartments" [rows]="10" [paginator]="true" [pageLinks]="3"
                [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'TYPE_NAME' | translate}}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-DeptType>
                    <tr>
                        <td>
                            <span>{{DeptType.Name}}</span>
                        </td>
                        <td class="col-button">
                            <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                (click)="editDeptType(DeptType)">
                                <i class="icon ic-sm icon-pencil-tip"></i>
                            </button>
                            <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}">
                                <i class="icon ic-sm icon-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td colspan="2">
                            {{'NO_RECORDS_FOUND'|translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- Table Ends -->
        </div>
    </div>
</p-footer>