import { Data } from '@angular/router';

export class ArticleModel {
    id?: number;
    nodeId?: number;
    filename: string;
    originalFileName: string;
    path: string;
    mimetype: string;
    size: number;
    isFolder: boolean;
    type: string;
    isDefault: boolean;
    documentType: string;
    status: number;
    createdBy: string;
    modifiedBy: number;
    parentFolderId: number;
    documentTypeId: number;
    content: ArticleContent;
    featureId?: number;
}

export interface ArticleContent {
    id?: number;
    article: Article;
    subArticles: SubArticle[];
    revisionComments: any;
}

export interface Article {
    id?: number;
    articleName: string;
    documentNumber: number;
    chapter: string;
    version?: number;
    articleDate?: any;
    articleEditDate?: any;
    signaturePath?: string;
    signatureFile?: File;
    modifiedOn: any;
    createdOn: any;
    articleFormatedDate?: any;
    roleId?: number;
    roleStatus: RoleStatus;
    formattedDate: string;
    portalId?: number;
    businessId?: number;
    folderName: string;
    createrId: number;
    factAboutCompanyData: string;
    orgChartData: string;
    modifiedBy: string;
    editedBy?: number;
    approvedDate?: any;
    approvedBy: string;
}
export interface RoleStatus {
    admin: ArtilceText[];
    portal: ArtilceText[];
    editor: ArtilceText[];
}
export interface ArtilceText {
    name: string,
    status: string,
    disable: string
}

export interface ArticleDetails {
    ArticleId: number,
    CreaterRoleId: number,
    CreaterId: number,
    UserRoleId: number,
    BusinessId?: number,
    PortalId?: number,
    Version: number,
    BusinessMode: string
}

export interface SubArticle {
    id?: number;
    name: string;
    text: string;
    version?: number;
    portalVersion?: number;
    businessVersion?: number;
    status: string;
    disable: string;
    applicationId?: number;
    portalId?: number;
    businessId?: number;
    portalEditedBy?: number;
    businessEditedBy?: number;
    portalEditedDate?: any;
    businessEditedDate?: any;
    articleDate?: any,
    modifiedName?: string,
    modifiedDate?: any;
}

export interface ArticleEvent {
    eventName: string;
    item: {
        articleId?: number;
        articleContent: ArticleContent;
    };
}

export interface MergeField {
    id: number,
    name: string,
    html: string,
    data: string
}

export class ArticleEntityModel {
    Id?: number;
    AppManualId?: number;
    AppManualFolderId?: number;
    ApplicationId?: number;
    BusinessId: number;
    PortalId?: number;
    Title: string;
    DocumentNo: string;
    Chapter: string;
    ArticleDate?: string;
    SignaturePath?: string;
    Version: number;
    Status: number;
    CreatedBy?: number;
    ModifiedBy?: number;
    FeatureId?: number;
    FeatureKey?: number;
    FeatureFolderId?: number;
    ReferenceNumber?: string;
    RoleStatus?: string;
    ArticleFormattedDate?: string;
    EditedBy?: number;
}

export class ArticleApproveDetails {
    ApplicationId: number;
    ManualId: number;
    BusinessId: number;
    UserId: number;
    ApprovedDate?: any;
    ArticleIdList?: any;
}

export class ArticleDataEntityModel {
    Id?: number;
    AppManualArticleId?: number;
    FeatureManualArticleId?: number;
    ApplicationId?: number;
    BusinessId?: number;
    PortalId?: number;
    TextType?: string;
    TextContent?: string;
    Version?: number;
    PortalVersion?: number;
    BusinessVersion?: number;
    Status: string;
    CreatedBy?: number;
    ModifiedBy: number;
    SignaturePath?: string;
    ArticleFormattedDate?: string;
    ArticleEditDate?: string;
    ArticleStatus: string;
    PortalEditedBy?: number;
    BusinessEditedBy?: number;
    ArticleDate?: Date;
}
export class LarDataEntityModel {
    Id?: number;
    AppManualId?: number;
    AppManualFolderId?: number;
    ApplicationId?: number;
    PortalId?: number;
    BusinessId?: number;
    Title: string;
    Url: string;
    IsLaws?: boolean;
    IsCommon?: boolean;
    IsAccess?: boolean;
    Status?: number;
    CreatedBy?: number;
    ModifiedBy?: number;
    ModifiedOn?: any;
    CreatedOn?: any;
    FeatureFolderId?: number;
    FeatureId?: number;
    FeatureKey?: number;
}

export interface TextTypeAndStatus {
    id?:number,
    textType: string,
    status: string
}




