
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { ManualControlListResolver,
     TypeOfObjectResolver,
      BrandResolver, 
      ModelResolver,
       ManualControlEditResolver, 
       TemperatureResolver, ManualTempControlResolver } from './services/manual-control-resolver';
import { ManualControlServices } from './services/manual-control-services';
import { ManualControlFormComponent } from './components/manual-control-form.component';
import { ManualControlListComponent } from './components/manual-control-list.component';
import { CommonBaseModule } from './../common/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { ManualControlComponent } from './manual-control.component';
import { NgModule } from '@angular/core';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';
import { SpinnerModule } from 'primeng/spinner';



@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        CommonBaseModule,
        EcplDocumentExplorerModule,
        SpinnerModule,
        KubaExportModule
    ],
    exports: [
        ManualControlComponent,
        ManualControlListComponent,
        ManualControlFormComponent
    ],
    declarations: [
        ManualControlComponent,
        ManualControlListComponent,
        ManualControlFormComponent
    ],
    providers: [
        ManualControlServices,
        ManualControlListResolver,
        TypeOfObjectResolver,
        BrandResolver,
        ModelResolver,
        ManualControlEditResolver,
        TemperatureResolver,
        ManualTempControlResolver
    ],
})
export class ManualControlModule { }
