import { AuthGuard } from './../_guards/auth.guard';
import { KubaComponent } from './kuba.component';
import { Route } from '@angular/router';
import { KubaSubComponent } from './kuba.sub.component';
import { kubaSubRoutes } from './kuba.sub.routing';
import { applicationRoutes } from './application/application.routing';
import { developerRoutes } from './developer/developer.routing';
import { SearchResultComponent } from './SearchResults/searchresults.component';
import { FeaturesResolver } from './kuba.services.resolver';

export const kubaRoutes: Route[] = [
    {
        path: 'app',
        component: KubaComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'admin/:id',
                component: KubaSubComponent,
                children: [
                    ...kubaSubRoutes
                ]
            },
            {
                path: 'portal/:id',
                component: KubaSubComponent,
                children: [
                    ...kubaSubRoutes
                ]
            },
            {
                path: 'business/:id',
                component: KubaSubComponent,
                children: [
                    ...kubaSubRoutes
                ]
            },
            {
                path: 'developer',
                children: [
                    ...developerRoutes
                ]
            },
            {
                path: 'super',
                children: [
                    ...applicationRoutes
                ]
            },
            {
                path: 'searchPage/:bId/:searchKey/:searchMode',
                component: SearchResultComponent
            } 
        ]
    }

];
