export class ProjectAdmin {
    Id: number;
    Name: string;
    Status: string;
    ApplicationId: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    PortalId: number;
    BusinessId: number;
    IsCommon: any;
}
