import { Component, OnInit, ViewChild } from '@angular/core';
import { Tasklist, ActivityList, ActivityDelete } from 'src/app/kuba/task-manager/models';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TaskService } from 'src/app/kuba/task-manager/services/task.services';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights, FeatureKey } from 'src/app/_models';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-kctask-manager-list',
  templateUrl: './kctask-manager-list.component.html'
})
export class KCTaskManagerListComponent implements OnInit {

  tasks: Tasklist[];
  @ViewChild('taskTable',{static: false}) taskTable: Table;
  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  pdf: any;
  selectedTasklist: Tasklist;
  tasklist: Tasklist[];
  parentId: number;
  parentKey: any;
  statuses: SelectItem[];
  loading = false;
  displayDialog = false;
  roleId: string;
  displayDialogForRecurring = false;
  recurringActivityId = 0;
  IsFutureRecurring: boolean;
  isAdministrateActivity: boolean;
  isDisableDeleteIcon = true;
  isNewButtonEnable: boolean;
  isWeekRecuurTask: boolean;
  userrights: any = [];
  projectcheck: boolean = false;
  additionalData = {
      fileName: this.translate.instant('CONTROL_TASK_MANAGER_LIST'),
      header: this.translate.instant('CONTROL_TASK_MANAGER_LIST'),
      businessId: BaseServices.BusinessId,
      cultureInfo: BaseServices.userCultureInfo(),
      columnNames: [
          { title: 'Title', dataKey: 'Title' },
          { title: 'Status', dataKey: 'Status' },
          { title: 'Deadline', dataKey: 'EndDate' },
          { title: 'Responsible for Execution', dataKey: 'ExecutionUserName' },
          { title: 'Created On', dataKey: 'CreatedOn' },
          { title: 'Done On', dataKey: 'CompletedDate' },
          { title: 'Edited By', dataKey: 'ModifierName' },
          { title: 'Created By', dataKey: 'CreatorName' }
      ]
  };
  private subscriptions: Subscription[] = [];
  constructor(public taskService: TaskService,
      private confirmationService: ConfirmationService,
      private route: ActivatedRoute,
      private translate: TranslateService) {
      this.parentId = this.route.snapshot.params['Id']
      this.parentKey = this.route.parent.parent.snapshot.data['parent'];
      this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
          this.statuses = [];
          this.statuses.push(
              { label: val.SELECT, value: null },
              { label: val.NEW, value: 7 },
              { label: val.OPEN, value: 8 },
              { label: val.DONE, value: 9 },
              { label: val.REJECTED, value: 10 }
          );
      }));
  }

  ngOnInit() {
      // To hide side menu bar
      document.querySelector("body").classList.remove("opened");
      this.roleId = BaseServices.roleId;
      if (BaseServices.roleId === '5') {
          this.isDisableDeleteIcon = false;
      }
      this.isNewButtonEnable = BaseServices.roleId === '3' ? true : false;
      let tasks = <Tasklist[]>this.route.snapshot.data['list'];
      this.tasklist = [];
      if (tasks ? tasks.length > 0 : false) {
          this.bindTasks(tasks);
      }
      this.userrights = JSON.parse(BaseServices.getUserRights());
        if(this.userrights != null){
            for (let i = 0; i < this.userrights.length; i++) {
                if (this.userrights[i].name == 'ADMINISTRATE_PROJECTS') {
                    this.projectcheck = this.userrights[i].isChecked;
                }
            }
        }
      this.onAdministrateActivity();   
  }

  bindTasks(taskList?: any): any {
      this.tasklist = [];
      taskList.forEach((element: any) => {
          element.Responsible = false;
          if (element.FollowupUserIdList) {
              for (let i = 0; i < element.FollowupUserIdList.length; i++) {
                  if (BaseServices.UserId === element.FollowupUserIdList[i]) {
                      element.Responsible = true;
                  }
              }
          }
          if (!element.Responsible) {
              if (element.ExecutionUserIdList) {
                  for (let i = 0; i < element.ExecutionUserIdList.length; i++) {
                      if (BaseServices.UserId === element.ExecutionUserIdList[i]) {
                          element.Responsible = true;
                      }
                  }
              }
          }
          let daysDiff = 0;
          let statusIcon = '';
          let currentdate = new Date();
          let deadLine = new Date(element.EndDate);
          if (element.EndDate != null) {
              daysDiff = this.getDaysDiff(currentdate, deadLine);
          }
          if (element.Status === 'NEW') {
              statusIcon = 'icon ic-sm icon-star';
          } else if (
              element.Status === 'DONE' ||
              element.Status === 'Rejected'
          ) {
              statusIcon = 'icon ic-sm icon-clear-deadline';
          } else {
              statusIcon = 'icon ic-sm icon-star';
          }
          element.StatusIcon = statusIcon;
          this.tasklist.push(element);
      });
  }
  /**
   * get Task manager list
   */
  getTaskList() {
    this.subscriptions.push(this.taskService.getAllByEach(this.parentId, this.parentKey).subscribe(res => {
          if (res) {
              this.bindTasks(res)
          }
      }));
  }

  /**
   * filter task mananger list based on status
   * @param e {{any}}
   * @param statusDropdown {{any}}
   */
  onStatusChanged(e: any, statusDropdown: any) {
      if (e.value) {
          this.taskTable.filter(statusDropdown.selectedOption.value, 'StatusId', 'equals');
      } else {
          this.taskTable.reset();
      }
  }

  /**
   * delete task manager based on Id
   * @param value {{any}}
   */
  confirm(value: any) {
      let activity = new ActivityDelete();
      activity.AdditionalFeatureId = FeatureKey.TASK_MANAGER;
      activity.AdditionalFeatureKey = value;
      activity.FeatureKey = this.parentId;
      activity.FeatureId = BaseServices.getFeatureId(this.parentKey);
      this.subscriptions.push(this.taskService.checkParentActivity(value)
          .subscribe((count: any) => {
              // check for child and normal actvitiy
              if (count === 0 || count === 2) {
                  this.confirmationService.confirm({
                      message: this.translate.instant('DELETE_THIS_RECORD'),
                      accept: () => {
                          this.loading = true;
                          this.subscriptions.push(this.taskService.delete(value).subscribe(isRecurringActivityDeleted => {
                              if (isRecurringActivityDeleted) {
                                  this.toasterComponent.callToastDlt();
                                  this.getTaskList();
                                  this.loading = false;
                              }
                          }));
                      }
                  });
              } else {
                  // parent activity delete
                  this.confirmationService.confirm({
                      message: this.translate.instant('DELETE_PARENT_ACTIVITY'),
                      accept: () => {
                          this.loading = true;
                          this.subscriptions.push(this.taskService.deleteParentwithRecurring(value).subscribe(isActivityDeleted => {
                              if (isActivityDeleted) {
                                  this.toasterComponent.callToastDlt();
                                  this.getTaskList();
                                  this.loading = false;
                              }
                          }));
                      }
                  });
              }
          }));
  }

  /**
   * get days between start date and end date
   * @param StartDate {{date}}
   * @param EndDate {{date}}
   */
  getDaysDiff(StartDate: Date, EndDate: Date) {
      if (StartDate && EndDate) {
          let _MS_PER_DAY = 1000 * 60 * 60 * 24;
          let date1 = Date.UTC(
              StartDate.getFullYear(),
              StartDate.getMonth(),
              StartDate.getDate()
          );
          let date2 = Date.UTC(
              EndDate.getFullYear(),
              EndDate.getMonth(),
              EndDate.getDate()
          );
          let res:any = Math.floor((date2 - date1) / _MS_PER_DAY);
          return res;
      }
  }
  /**
   * show dialog for stop future recurring activity
   */
  showDialogForStopFutureRecurring(data: any) {
      this.displayDialogForRecurring = true;
      this.recurringActivityId = data.Id;
      this.IsFutureRecurring = data.IsFutureRecurring ? data.IsFutureRecurring : false;
      this.isWeekRecuurTask = data.RecurrDays ? data.RecurrDays.length != 0 : true
  }

  onClickStopFutureRecurring() {
      if (this.recurringActivityId > 0) {
          this.confirmationService.confirm({
              message: this.translate.instant('STOP_FUTURE_RECURRING_ACTIVTY'),
              accept: () => {
                  this.displayDialogForRecurring = false;
                  this.loading = true;
                  if(this.isWeekRecuurTask){
                    this.subscriptions.push(this.taskService.StopWeekFutureRecurrTask(this.recurringActivityId).subscribe(result => {
                          if (result) {
                              this.getTaskList();
                              this.loading = false;
  
                          }
                      }));
                  }else{
                    this.subscriptions.push(this.taskService.stopFutureRecurring(this.recurringActivityId).subscribe(result => {
                          if (result) {
                              this.getTaskList();
                              this.loading = false;
  
                          }
                      }));
                  }
              },
              reject: () => {
                  this.loading = false;
              }
          });
      }
  }

  onClickDeleteRecurringActivity(task) {
      this.recurringActivityId = task.Id;
      if (this.recurringActivityId > 0) {
                      // parent activity delete
                      this.confirmationService.confirm({
                          message: this.translate.instant('DELETE_PARENT_ACTIVITY'),
                          accept: () => {
                              this.loading = true;
                              this.subscriptions.push(this.taskService.deleteParentwithRecurringFS(this.recurringActivityId).subscribe(isActivityDeleted => {
                                  if (isActivityDeleted) {
                                      this.toasterComponent.callToastDlt();
                                      this.getTaskList();
                                      this.loading = false;
                                  }
                              }));
                          }
                      });
              //     }
              // });
          this.displayDialogForRecurring = false;
      }
  }

  onAdministrateActivity() {
      let currentUserRole = BaseServices.UserRole;
      let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
      if (currentUserRole === 'User') {
          this.isAdministrateActivity = BaseServices.checkUserRights(userRightsId, currentUserRole);
      } else if (currentUserRole === 'Guest') {
          this.isAdministrateActivity = false;
      } else {
          this.isAdministrateActivity = true;
      }
  }

  onCancel() {
      this.displayDialog = false;
  }

  recurTranslate(recur) {
      let concatRecur = "";
      if (recur) {
          for (var i = 0; i < recur.length; i++) {
              if(recur.length == i+1){
                  concatRecur += this.translate.instant(recur[i]);
              }else{
                  concatRecur += this.translate.instant(recur[i]) + ", ";
              }
          }
      }
      return concatRecur;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
