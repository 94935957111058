<header>
    <!--header (language & User Panel)-->
    <div class="top-header">
        <header-top></header-top>
    </div>
    <!--Logo Header (Header)-->
    <div class="logos-block">
        <branding></branding>
    </div>
</header>
<div class="main-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-8 offset-md-3 offset-sm-2">
                <!--Application navigation-->
                <div class="app-content ruh-module">
                    <div class="content-wrapper">
                        <div class="tab-content">
                            <form [formGroup]="ruhForm">
                                <div class="page-title">
                                    <span translate>NEW_INCIDENT </span>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>DEPARTMENT</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <p-dropdown [options]="departments" [style]="{'width':'100%'}"
                                          formControlName="DepartmentId" (onChange)="onDepartmentChanged(depControl)"
                                          #depControl placeholder="{{'SELECT'|translate}}"></p-dropdown>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                        <span class="text-danger"
                                          *ngIf="!isdepartmentFollowupExist">{{noFollowupExistAlert}}</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>TOPICS</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <p-dropdown [options]="Topics" [style]="{'width':'150px'}"
                                          formControlName="TopicID" placeholder="{{'SELECT'|translate}}"
                                          #topicDropdown></p-dropdown>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                        <span class="text-danger" *ngIf="!isCaseTopicExist">{{noTopicExistAlert}}</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">
                                        <span translate>TYPE_OF_CASE</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <p-dropdown [options]="case" [style]="{'width':'150px'}"
                                          formControlName="CaseId" placeholder="{{'SELECT'|translate}}" #caseDropdown
                                          (onChange)="bindTopicDropdown(caseDropdown)"></p-dropdown>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="lblWhathappend" class="col-form-label">
                                        <span translate>TITLE</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <input type="text" id="txtRelatedIncident" formControlName="Title" pInputText>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="lblLocation" class="col-form-label">
                                        <span translate>LOCATION</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <input type="text" id="txtLocation" pInputText name="Location"
                                          style="width: 340px" formControlName="Location">
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="endDate" class="col-form-label">
                                        <span translate>EVENT_DATE</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <my-date-picker name="enddate" [options]="startDateOptions"
                                          [style]="{'width':'100%'}" placeholder="{{'SELECT_END_DATE'|translate}}"
                                          formControlName="EventDate"></my-date-picker>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="lblStartTime" class="col-form-label">
                                        <span translate>EVENT_TIME</span>
                                        <span class="required">*</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <p-calendar [timeOnly]="true" readonlyInput="true" [style]="{'width':'300px'}"
                                          styleClass="dateTimeField" formControlName="EventTime"></p-calendar>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="lblDescription" class="col-form-label">
                                        <span translate>DESCRIPTION</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <textarea style="width: 350px" pInputTextarea
                                          formControlName="Description">  </textarea>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="lblDescription" class="col-form-label">
                                        <span translate>CORRECTIVE_ACTIONS</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <textarea style="width: 350px" pInputTextarea
                                          formControlName="CorrectiveAction"> </textarea>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="lblDescription" class="col-form-label">
                                        <span translate>SUGGESTIONS_ACTIONS</span>
                                    </label>
                                    <div class="input-btn-group">
                                        <textarea style="width: 350px" pInputTextarea
                                          formControlName="SuggestionAction"> </textarea>
                                        <button class="btn btn-outline-success">
                                            <i class="pi pi-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="page-title">
                                        <span translate>ATTACHMENTS</span>
                                    </div>
                                    <div>
                                        <ecpl-document-explorer [uploaderConfig]="upConfig"
                                          [uploadedFiles]="uploadedRuhFiles" (saveChanges)="saveUploaded($event)">
                                        </ecpl-document-explorer>
                                    </div>
                                </div>
                                <div class="action-btns-wrapper">
                                    <button type="submit" class="btn btn-primary" [ngClass]="{'loading' : loading}"
                                      [disabled]="loading || !(ruhForm.valid && isdepartmentFollowupExist) "
                                      (click)="saveRuh()">
                                        <span *ngIf="!loading" translate>SAVE</span>
                                        <span *ngIf="loading" translate>SAVE_PROCESS</span>
                                        <span *ngIf="loading" class="loader-icon"></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>