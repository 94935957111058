import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { SjaFormComponent } from './sja-form.component';
import { SjaListComponent } from './components/sja-list.component';
import { SjaUserformComponent } from './components/sja-userform.component';
import { ConfirmationService } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';
import { SJAFormServices } from './sjaform.routing';
import { SJAFormListResolver, SJAFormResolver, SJATitleListResolver } from './sjaform.resolver.service';
// import { AppSharedModule } from '../shared';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PaginatorModule } from 'primeng/paginator';
import { AppSharedModule } from '../shared/appShared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ConfirmDialogModule,
    TranslateModule,
    TableModule,
    PaginatorModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    AppSharedModule
  ],
  declarations: [
    SjaListComponent,
    SjaFormComponent,
    SjaUserformComponent
  ],
  providers: [
      ConfirmationService,
      SJAFormServices,
      SJAFormListResolver,
      SJAFormResolver,
      SJATitleListResolver
  ]
})
export class SjaFormModule { }
