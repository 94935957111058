
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MoveBusinessComponent } from './components/move-business.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from './../shared/appShared.module';
import { AdminAndPortalListResolver } from './services/move-business.resolver';
import { MoveBusinessServices } from './services/move-business.service';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from 'primeng/editor';


@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        EditorModule,
        FormsModule,
        AppSharedModule,
        TranslateModule,
    ],
    exports: [],
    declarations: [MoveBusinessComponent],
    providers: [
        MoveBusinessServices,
        AdminAndPortalListResolver
    ],
})
export class MoveBusinessModule { }
