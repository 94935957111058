<div class="page-title">
    <span translate>CREW_LIST</span>
</div>
<div class="main-content">
    <div class="card">
        <div class="card-body">
            <div class="panel-tabs" style="margin-bottom:40px;">
                <nav>
                    <ul class="nav nav-pills">
                        <li [routerLinkActive]="['active']"><a [routerLink]="['./list']" translate>CREW_LIST</a></li>
                        <li [routerLinkActive]="['active']"><a [routerLink]="['./history']" translate>HISTORY</a></li>
                    </ul>
                </nav>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>