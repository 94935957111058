import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'electro-form',
    templateUrl: 'electro-form.component.html'
})

export class ElectroFormComponent implements OnInit {
    
    isEditMode: boolean;
    contractName: string;
    title: string;

   constructor(
    private route: ActivatedRoute
   ) {
       let electroId = this.route.snapshot.params['cid'];
       if(electroId > 0){
           this.isEditMode = true;
           this.title="EDIT_CONTRACT";
           this.contractName = " - " + localStorage.getItem('contractName');
        }
       else{
        this.title="NEW_CONTRACT";
        this.contractName ="";
       }
     }
    ngOnInit() {     }
    }