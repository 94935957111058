import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';

import { CommandServiceObject } from './editorConfig';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class EcplOnlyOfficeViwerService {
    constructor(private http: HttpClient) {}

    sendCommandService(cso: CommandServiceObject) {
        return this.http
            .post('http://13.94.114.163//coauthoring/CommandService.ashx', cso)
            .map((response: any) => response);
    }
}
