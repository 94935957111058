<div class="main-content">
    <div class="main-heading">
        <span translate>MANUALS</span>
        <span class="page-actions text-right" *ngIf="isAdminMode">
            <button type="button" class="btn btn-success" (click)="newManual()">
                <span class="icon ic-sm icon-add"></span>
                <span translate>NEW_MANUAL</span>
            </button>
        </span>
    </div>
    <div class="card">
        <div class="card-body">
            <manual-tab *ngIf="isAdminMode" (onTabClick)="onTabClick($event)"></manual-tab>
            <manual-list [manuals]="manuals" [manualType]="manualType" (onManualDelete)="onManualDelete($event)"
              (onManualEdit)="onManualEdit($event)"></manual-list>
        </div>
    </div>
</div>

<p-dialog header="{{newManualText|translate}}" *ngIf="showDialog" [(visible)]="showDialog" 
[style]="{width: '500px', 'height': '500px' }" [resizable]="false" [draggable]="false">
    <manual-form [manual]="manual" [manualType]="manualType" (onClose)="onClose($event)"></manual-form>
</p-dialog>
<toaster-component [toast]="toast"></toaster-component>