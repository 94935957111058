import { Execution } from './../models/activities';
import { FeatureKey } from './../../../_models/feature';
import { ActivityServices } from './../services/activities.services';
import { FeatureBase } from './../../features/models/features-base.model';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter
} from '@angular/core';
import { BusinessDepartmentService } from './../../business-department.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

import { IMyDpOptions } from 'mydatepicker';
import { JobAnalysisService } from './../../safety-job-analysis/services/jobanalysis.services';
import { UploadOutput, UploadFile, UploadStatus } from 'ngx-uploader';
import { UploadInput } from 'ngx-uploader';
import {
    TaskPlanList,
    TaskPlan,
} from './../../safety-job-analysis/models/taskplan';
import { environment } from 'src/environments/environment';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { BaseServices, KubaServices } from './../../kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Topic } from './../models/riskanalysis';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Options } from 'src/app/_models/options';
import { Rights } from 'src/app/_models';
import * as _ from 'lodash';
import { UserDropdownSelectTranslate } from './../../../_helpers/pipes';
import { RuhService } from '../services/ruh.services';
import { Ruh, ActionPlan } from '../models/ruh';
import { RiskanalysisService } from '../services/risk-analysis.service';
import { Table } from 'primeng/table';
import { SafetyBaseComponent } from '../../safety-job-analysis/components/safety-base.component';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: 'ruh-edit.component.html'
})
export class RuhEditComponent extends SafetyBaseComponent implements OnInit {
    //#region variable

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('ruhTable',{static: false}) ruhTable: Table;
    ruhEditForm: FormGroup;
    isSorting = true;
    featureId: number;
    caseId: number;
    uploadedRuhFiles = [];
    uploadedActionPlanFiles = [];
    riskUploadedFiles: UploaderConfig;
    upConfig: UploaderConfig;
    upRiskConfig: UploaderConfig;
    upConfigActionPlan: UploaderConfig;
    taskId: number;
    additionalData: any;
    ruhAdditionalData: any;
    title: string;
    newActionTitle: any;
    actionDescription: any;
    actionDeadline: number;
    actionStatus: string;
    actionExecution: number;
    dragOver: boolean;
    actionplanForm: FormGroup;
    ruhAddNewActionPopup: FormGroup;
    isFileChoosed = false;
    FollowUpDays: any[];
    showNotification = false;
    isActionExist = false;
    activityScheduleId: number;
    ruhNumberPrefix: string;
    ruhNumber: number;
    ruh: any;
    ruhstatus: any[];
    editTypeOfCases: any = [];
    Case: SelectItem[];
    followupId: any;
    followupName: string;
    vresperson: SelectItem[];
    ruhId: number;
    actionId: number;
    RiskEditTopicPopUp: FormGroup;
    files: UploadFile[] = [];
    RiskEditDeptPopUp: FormGroup;
    uploadInput: EventEmitter<UploadInput>;
    figureValue: any;
    Topics: SelectItem[];
    departments: SelectItem[];
    isVisibleSave = true;
    executions: SelectItem[];
    followUps: SelectItem[];
    locale: string = this.translator.currentLang
        ? this.translator.currentLang
        : 'en';
    notifyBeforeDeadLineMaxValue = '7';
    selectedTopic = new Topic();
    editDepartments: any = [];
    editTopics: any = [];
    status: string;
    RuhStatus: any[];
    showViewLog = false;
    actvityFilePath: string;
    documentPath: string;
    actionPlanList: any = [];
    actionPlan: TaskPlan;
    riskanalysis: any;
    activityRowIndex: number;
    activitylist: any = [];
    addTopicType: boolean;
    addDept: boolean;
    viewType: boolean;
    featureBase: FeatureBase;
    viewTopicType: boolean;
    IsActive: boolean;
    topicId: number;
    departmentId: number;
    sorting: SelectItem[];
    activityDocument: any;
    Sorting: string;
    showArchiveDocumentDailog = false;
    treeNode: any;
    editMode = false;
    treeActiveNode: any;
    selectFolderId?: number;
    selectFolderName?: string;
    addType = false;
    public selectedFiles:any;
    loading: boolean;
    actionloading: boolean;
    documentName: string;
    createdby: number;
    createdon: string;
    createdOnForSave: Date;
    time: string;
    activityLogData: any = [];
    fileName: string;
    attachmentUrl: string;
    attachment: any[];
    currentBusinessId: number;
    roleId: any;
    isTaskAdded = false;
    activityDocumentObject: any;
    newActivityDocumentObject: any;
    existingDocument: any;
    departmentExisting: string;
    departmentAlerttext: string;
    isdepartmentExists = false;
    isdepartmentAlert = false;
    topicExisting: string;
    topicAlerttext: string;
    istopicNameExists = false;
    istopicExists = false;
    showNotificationForTask = false;
    newDocumentAdded: any = [];
    newActionPlanDocumentAdded: any = [];
    actionCreatedBy: number;
    actionModifiedBy: number;
    actionCreatedOn: Date;
    riskArchieveDocument: any;
    uploadedArchieveFiles: any = [];
    loadingForNewRisk: boolean;
    loadingForSaveAndActivity: boolean;
    loadingForAttachment: boolean;
    parentKey: any;
    parentId: any;
    projectId: number;
    BusinessId = 1;
    addActionPlan: boolean;
    isdepartmentFollowupExist = true;
    isCaseTopicExist = true;
    noFollowupExistAlert: string;
    noTopicExistAlert: string;
    userId: number;
    /**
     * Date picker configuration option
     */
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        disableUntil: this.getCurrentDate(),
        width: '300px',
        inline: false
    };
    public actiondateOptions: IMyDpOptions;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param route {ActivatedRoute}
     * @param translator {TranslateService}
     * @param kubaServices {KubaServices}
     * @param _fb {FormBuilder}
     */
    constructor(
        private route: ActivatedRoute,
        private translator: TranslateService,
        private riskanalysisService: RiskanalysisService,
        private location: Location,
        private _fb: FormBuilder,
        private confirmService: ConfirmationService,
        private ruhServices: RuhService,
        private businessDepartmentService: BusinessDepartmentService,
        private translate: TranslateService
    ) {
        super();
        this.userId = BaseServices.UserId;
        this.parentId = route.snapshot.parent!.parent!.params['id'];
        this.parentKey = this.route.snapshot.parent!.parent!.data['parent'];
        this.ruhAddNewActionPopup = this._fb.group({
            ActionTitle: ['', [Validators.required]],
            actionDescription: ['', [Validators.required]],
            actionStatus: ['', [Validators.required]],
            actionDeadline: ['', [Validators.required]],
            actionExecution: ['', [Validators.required]]
        });
    }

    //#endregion

    //#region page-event

    /**
     * editing risk analysis and binding dropdowns
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: 1203,
            ActivityId: 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date()
        };
        this.upConfig = {
            title: 'ATTACHMENTS',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/ruh/file/upload/business/followup/ruh-docs',
            addtionalData: this.additionalData
        };
        this.upConfigActionPlan = {
            title: 'ATTACHMENTS',
            titleAsLabel: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/ruh/file/upload/business/followup/ruh/actionPlan-docs',
            addtionalData: this.additionalData
        };

        this.roleId = BaseServices.roleId;
        this.additionalData = {
            ParentId: this.parentId ? this.parentId : 0,
        };
        this.subscriptions.push(this.route.parent.params.subscribe((params: Params) => {
            let pId = +params['id'];
            this.projectId = +pId;
        }));

        // this.BindTreeNode();
        this.featureBase = BaseServices.getUserFeatures();
        let features = [];
        features = JSON.parse(this.featureBase.Features);
        if (features) {
            features.forEach((feature:any) => {
                if (feature.id === FeatureKey.FOLLOW_UP) {
                    let childrens = feature.children;
                    childrens.forEach((children:any) => {
                        if (children.id === FeatureKey.RISK_ANALYSIS_EXTRA_SORTINGFIELD && children.checked) {
                            this.isSorting = false;
                        }
                    });
                }
            });
        }
        let documentList = this.route.snapshot.data['attachment']



        if (documentList) {
            this.attachment = documentList;
        }
        this.uploadInput = new EventEmitter<UploadInput>();
        switch (BaseServices.UserRole) {
            case 'Admin':
                this.isVisibleSave = true;
                break;
            case 'Editor':
                this.isVisibleSave = true;
                break;
            case 'User':
                this.isVisibleSave = BaseServices.checkUserRights(Rights.RUH_EDITOR, BaseServices.UserRole);
                break;
            case 'Guest':
                this.isVisibleSave = false;
                break;
            case 'Portal':
                this.isVisibleSave = true;
                break;
        }
        this.initializeRuhEditForm();
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            let rid = params['ruhid'];
            this.ruhId = +rid;
            this.subscriptions.push(this.ruhServices.getAllActionPlan(this.ruhId).subscribe(
                result => {
                    if (result) {
                        this.actionPlanList = result;
                    }
                }
            ))
            if (this.ruhId) {
                this.subscriptions.push(this.ruhServices
                    .getById(this.ruhId)
                    .subscribe((ruhForm: any) => {
                        if (ruhForm) {
                            this.createdby = ruhForm.CreatedBy;
                            this.currentBusinessId = ruhForm.BusinessId;
                            this.createdon = ruhForm.CreatedOn;

                            this.initializeRuhEditForm(ruhForm);
                        }
                    }));
            }
        }));
        this.actionplanForm = this._fb.group({
            Title: ['', [Validators.required]],
            Descrition: [''],
            CorrectiveAction: [''],
            CauseAnalysis: [''],
            ImmediateAction: [''],
            Goal: [''],
            EstimatedCost: [''],
            Deadline: ['', [Validators.required]],
            RuhDeadline: ['', [Validators.required]],
            Execution: [[], Validators.required],
            Followup: [[], Validators.required],
            NotifyBeforeDeadLine: [''],
            NotifyBeforeFollowUp: [7],
            Status: [8]
        });



        this.dropdownBinding();

    }

    gotoList() {
        this.location.back();
    }
    getActionById(id: number) {
        this.uploadedActionPlanFiles = [];
        this.bindstatus("edit");
        this.addActionPlan = true;
        this.actionId = id;
        this.subscriptions.push(this.ruhServices.getactionplan(id, this.ruhId).subscribe((actionplanresult: any) => {
            if (actionplanresult) {
                let ActionTitle = '';
                let ActionDescription = '';
                let ActionStatus = 8;
                let ActionDeadline = '';
                let ActionExecution = '';
                this.uploadedActionPlanFiles = [];
                if (id) {
                    ActionTitle = actionplanresult.Title;
                    ActionDescription = actionplanresult.Description;
                    ActionDeadline = actionplanresult.Deadline;
                    ActionStatus = (actionplanresult.Status == 7) ? 8 : actionplanresult.Status;
                    ActionExecution = actionplanresult.Reponsible_For_Execution;
                }
                this.ruhAddNewActionPopup = this._fb.group({
                    ActionTitle: ActionTitle,
                    actionDescription: ActionDescription,
                    actionStatus: ActionStatus,
                    actionDeadline: HelperService.formatInputDate(ActionDeadline),
                    actionExecution: ActionExecution
                });
                if (actionplanresult.objActionPlanAttachment) {
                    actionplanresult.objActionPlanAttachment.forEach((element:any) => {
                        this.uploadedActionPlanFiles.push(
                            HelperService.switchKeysCase(element, null, 'L')
                        );
                    });
                }
            }
        }));
    }
    /**
    * save uploaded file
    */
    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x:any) => x.Path !== event.item.data.path
                        );
                        this.uploadedRuhFiles = this.uploadedRuhFiles.filter(
                            (x:any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.ruhServices
                            .deleteRuhAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedRuhFiles = this.uploadedRuhFiles.filter(
                                        (x:any) => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }

    /**
     * risk form initialize
     * @param riskanalysis {any}
     */
    initializeRuhEditForm(ruh?: any) {
        let Status = '';
        let DepartmentId;
        let Sorting;
        let CaseId;
        let TopicId;
        let ruhTitle = '';
        let EventDate = '';
        let EventTime = '';
        let Description = '';
        let CorrectiveAction = '';
        let ProcessingCorrectiveAction = '';
        let SuggestionAction = '';
        let CauseAnalysis = '';
        let ImmediateAction = '';
        let FollowupUserId;
        let Deadline;
        let H1 = '';
        let H2 = '';
        let N = '';
        let FF = '';
        let UFT = '';
        let MeasuringDeadline = '';
        let MeasuringEffectofActions = '';

        let Alreadyactiontaken = '';
        let location = '';
        let RuhDeadline;
        let Probability = 1;
        let Consequence = 1;
        let NewActionTitle = '';
        let ActionDescription = '';
        let ActionStatus = '';
        let ActionDeadline = '';
        let ActionExecution = '';

        if (ruh) {
            let topics: any[];
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.Topics = [];
                this.Topics.push(
                    { label: val.SELECT, value: null }
                );
            }));

            this.bindstatus("edit");
            this.title = this.translate.instant('CASE_NUMBER') + ':' + ruh.RUHNumberPrefix + '-' + ruh.Id;

            this.uploadedRuhFiles = [];
            if (ruh.RuhAttachment) {
                ruh.RuhAttachment.forEach((element:any) => {
                    if (element) {
                        this.uploadedRuhFiles.push(
                            HelperService.switchKeysCase(element, null, 'L')
                        );
                    }
                });
            }
            Status = (ruh.Status == 7) ? 8 : ruh.Status;
            DepartmentId = ruh.BusinessDepartmentId;
            Sorting = ruh.Sorting === null ? 1 : ruh.Sorting;
            CaseId = ruh.CaseId;
            TopicId = ruh.TopicId;
            ruhTitle = ruh.Title;
            EventDate = ruh.EventDate;
            EventTime = ruh.EventTime;
            Description = ruh.Description;
            CorrectiveAction = ruh.CorrectiveAction;
            SuggestionAction = ruh.SuggestionAction;
            CauseAnalysis = ruh.CauseAnalysis;
            ImmediateAction = ruh.ImmediateAction;
            ProcessingCorrectiveAction = ruh.ProcessingCorrectiveAction;
            FollowupUserId = ruh.FollowupUserId;
            Deadline = ruh.Deadline;
            H1 = ruh.H1;
            H2 = ruh.H2;
            N = ruh.N;
            FF = ruh.FF;
            UFT = ruh.UFT;
            MeasuringDeadline = ruh.MeasuringDeadline;
            MeasuringEffectofActions = ruh.MeasuringEffectofActions;
            this.actionPlan = ruh.Activity;
            this.subscriptions.push(this.ruhServices.getAllTopics(ruh.CaseId).subscribe(result => {
                if (result) {
                    topics = result;
                    if (topics) {
                        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                            this.Topics = [];
                            this.Topics.push(
                                { label: val.SELECT, value: null }
                            );
                            topics.forEach((topicInfo: any) => {
                                this.Topics.push({
                                    label: topicInfo.Name,
                                    value: topicInfo.Id
                                });
                            });
                        }));
                        if (this.Topics.length > 1) {
                            TopicId = ruh.TopicId;
                            this.ruhEditForm.get('TopicId')!.patchValue(ruh.TopicId);
                        }
                    }
                }
            }));
            this.subscriptions.push(this.ruhServices.getFollowupofDepartment(DepartmentId).subscribe((result:any) => {
                if (result) {
                    this.isdepartmentFollowupExist = true;
                    this.followupId = result.Id;
                    this.followupName = result.Name

                }
                else {
                    this.isdepartmentFollowupExist = false;
                    this.noFollowupExistAlert = this.translate.instant(`NO_FOLLOWUP`);
                    this.followupId = 0;
                    this.followupName = "Nofollowup";
                }
            }));

        }

        this.ruhAddNewActionPopup = this._fb.group({
            ActionTitle: [NewActionTitle, Validators.required],
            actionDescription: [ActionDescription, Validators.required],
            actionStatus: [ActionStatus, Validators.required],
            actionDeadline: [ActionDeadline, Validators.required],
            actionExecution: [ActionExecution, Validators.required],
        });

        if(Deadline ? HelperService.formatInputDate(Deadline) ?
        HelperService.formatInputDate(Deadline).date : false : false){
        let ruhDeadline = HelperService.formatInputDate(Deadline);
        let date = {
            year: ruhDeadline.date.year,
            month: ruhDeadline.date.month,
            day: ruhDeadline.date.day + 1,
        };
        this.actiondateOptions  = {
            dateFormat: 'dd/mm/yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            firstDayOfWeek: 'su',
            satHighlight: true,
            height: '32px',
            selectionTxtFontSize: '14px',
            disableUntil: this.getCurrentDate(),
            disableSince: date, //disables days after ruh deadline days
            width: '300px',
            inline: false
        };
    }

        this.ruhEditForm = this._fb.group({
            Status: [Status],
            DepartmentId: [DepartmentId, Validators.required],
            Sorting: [Sorting, Validators.required],
            CaseId: [CaseId, Validators.required],
            TopicId: [TopicId, Validators.required],
            Title: [ruhTitle, Validators.required],
            EventDate: [HelperService.formatInputDate(EventDate), Validators.required],
            EventTime: [HelperService.formatTime(EventTime), Validators.required],
            Description: [Description],
            CorrectiveAction: [CorrectiveAction],
            SuggestionAction: [SuggestionAction],
            CauseAnalysis: [CauseAnalysis, Validators.required],
            ImmediateAction: [ImmediateAction, Validators.required],
            ProcessingCorrectiveAction: [ProcessingCorrectiveAction, Validators.required],
            Deadline: [HelperService.formatInputDate(Deadline), Validators.required],
            H1: [H1],
            H2: [H2],
            N: [N],
            FF: [FF],
            UFT: [UFT],
            MeasuringDeadline: [HelperService.formatInputDate(MeasuringDeadline)],
            MeasuringEffectofActions: [MeasuringEffectofActions],

            Alreadyactiontaken: [Alreadyactiontaken],

        });
    }

    /**
     * show log dailog
     */
    showLog() {
        this.showViewLog = true;
        this.activityLogData = this.route.snapshot.data['log'];
    }
    showActionPlan() {
        this.uploadedActionPlanFiles = [];
        this.bindstatus("new");
        this.addActionPlan = true;
        this.actionId = 0;
        this.ruhAddNewActionPopup.reset();
        this.ruhAddNewActionPopup.get('actionStatus')!.patchValue(8);
    }
    saveUploadedActionPlan(event: any) {
        if (event) {
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newActionPlanDocumentAdded = this.newActionPlanDocumentAdded.filter(
                            (x:any) => x.Path !== event.item.data.path
                        );
                        this.uploadedActionPlanFiles = this.uploadedActionPlanFiles.filter(
                            (x:any) => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.ruhServices
                            .deleteActionAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedActionPlanFiles = this.uploadedActionPlanFiles.filter(
                                        (x:any) => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let keyBindings = {
                        Originalname: 'OriginalFileName',
                        FileTypeId: 'FileType'
                    };
                    let suggestionDoc = Object.assign(
                        {},
                        event,
                        this.additionalData
                    );
                    suggestionDoc.id = 0;
                    suggestionDoc.createdBy = BaseServices.UserId;
                    suggestionDoc.status = 1;
                    this.newActionPlanDocumentAdded.push(
                        HelperService.switchKeysCase(suggestionDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    saveNewAction() {
        this.actionloading = true;
        this.actionPlanList = [];
        let windowOrigin = window.location.origin;
        let applicationBase = '';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
        if (appSettings) {
            applicationBase = appSettings.BaseUrl;
        }
        let url = windowOrigin + '/' + applicationBase + '/login?returnUrl='
            + '/app/business/' + BaseServices.BusinessId + '/follow-up/ruh/edit/';
        let actionPlanDetails = new ActionPlan();
        if (this.actionId)
            actionPlanDetails.Id = this.actionId;
        actionPlanDetails.RUHId = this.ruhId;
        actionPlanDetails.CreatedBy = +BaseServices.UserId;
        actionPlanDetails.Title = this.ruhAddNewActionPopup.value.ActionTitle;
        actionPlanDetails.Description = this.ruhAddNewActionPopup.value.actionDescription;
        actionPlanDetails.Status = this.ruhAddNewActionPopup.value.actionStatus;
        actionPlanDetails.Deadline = HelperService.formatDateFilter(this.ruhAddNewActionPopup.value.actionDeadline.formatted);
        actionPlanDetails.Reponsible_For_Execution = this.ruhAddNewActionPopup.value.actionExecution;
        actionPlanDetails.RUHActionPlanAttachment = this.newActionPlanDocumentAdded;
        actionPlanDetails.CultureInfo = BaseServices.userCultureInfo();
        actionPlanDetails.BusinessId = BaseServices.BusinessId;
        actionPlanDetails.ApplicationId = BaseServices.ApplicationId;
        if (this.actionId) {
            actionPlanDetails.Id = this.actionId;
            this.subscriptions.push(this.ruhServices.updateActionplan(actionPlanDetails).subscribe(isActionPlan => {
                if (isActionPlan) {
                    this.toasterComponent.callToast();
                    this.actionloading = false;
                    this.addActionPlan = false;
                    this.subscriptions.push(this.ruhServices.getAllActionPlan(this.ruhId).subscribe(
                        result => {
                            if (result) {
                                this.actionPlanList = result;
                            }
                        }));
                }
            }));
        }
        else {
            this.subscriptions.push(this.ruhServices.addRuhActionPlan(actionPlanDetails).subscribe(isActionPlan => {
                if (isActionPlan) {
                    this.toasterComponent.callToast();
                    this.actionloading = false;
                    this.addActionPlan = false;
                    this.subscriptions.push(this.ruhServices.getAllActionPlan(this.ruhId).subscribe(
                        result => {
                            if (result) {
                                this.actionPlanList = result;
                            }
                        }));
                }
            }));
        }
        this.actionId = 0;
        this.ruhAddNewActionPopup.reset();
        this.ruhAddNewActionPopup.get('actionStatus')!.patchValue(8);
    }
    /**
     * saving ruh 
     */
    UpdateRuh() {
        this.loading = true;
        let windowOrigin = window.location.origin;
        let applicationBase = '';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
        if (appSettings) {
            applicationBase = appSettings.BaseUrl;
        }
        let url = windowOrigin + '/' + applicationBase + '/login?returnUrl='
            + '/app/business/' + BaseServices.BusinessId + '/follow-up/ruh/edit/';
        let ruhUpdate = new Ruh();

        ruhUpdate.Status = this.ruhEditForm.value.Status;
        ruhUpdate.BusinessId = BaseServices.BusinessId;
        ruhUpdate.ApplicationId = BaseServices.ApplicationId;
        ruhUpdate.Id = this.ruhId;
        ruhUpdate.Title = this.ruhEditForm.value.Title;
        ruhUpdate.BusinessDepartmentId = this.ruhEditForm.value.DepartmentId;
        ruhUpdate.Sorting = this.ruhEditForm.value.Sorting;
        ruhUpdate.CaseId = this.ruhEditForm.value.CaseId;
        ruhUpdate.TopicId = this.ruhEditForm.value.TopicId;
        ruhUpdate.EventDate = HelperService.formatDateFilter(this.ruhEditForm.value.EventDate.formatted);
        ruhUpdate.EventTime = HelperService.formatTime(this.ruhEditForm.value.EventTime);
        ruhUpdate.Description = this.ruhEditForm.value.Description;
        ruhUpdate.CorrectiveAction = this.ruhEditForm.value.CorrectiveAction;
        ruhUpdate.SuggestionAction = this.ruhEditForm.value.SuggestionAction;
        ruhUpdate.CauseAnalysis = this.ruhEditForm.value.CauseAnalysis;
        ruhUpdate.ImmediateAction = this.ruhEditForm.value.ImmediateAction;
        ruhUpdate.ProcessingCorrectiveAction = this.ruhEditForm.value.ProcessingCorrectiveAction;
        ruhUpdate.FollowupUserId = this.followupId;
        ruhUpdate.Deadline = HelperService.formatDateFilter(this.ruhEditForm.value.Deadline.formatted);
        ruhUpdate.H1 = this.ruhEditForm.value.H1;
        ruhUpdate.H2 = this.ruhEditForm.value.H2;
        ruhUpdate.N = this.ruhEditForm.value.N;
        ruhUpdate.FF = this.ruhEditForm.value.FF;
        ruhUpdate.UFT = this.ruhEditForm.value.UFT;
        ruhUpdate.MeasuringDeadline = this.ruhEditForm.value.MeasuringDeadline ? HelperService.formatDateFilter(this.ruhEditForm.value.MeasuringDeadline.formatted) : '';
        ruhUpdate.MeasuringEffectofActions = this.ruhEditForm.value.MeasuringEffectofActions;
        ruhUpdate.RuhAttachment = this.newDocumentAdded;
        ruhUpdate.CultureInfo = BaseServices.userCultureInfo();
        ruhUpdate.CreatedBy = this.createdby;
        ruhUpdate.CreatedOn = this.createdon;
        this.subscriptions.push(this.ruhServices.updateRuh(ruhUpdate, this.parentKey).subscribe(isRuhUpdated => {
            if (isRuhUpdated) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.backToList();
            }
        }));

    }
    /**
     * to back to risk list
     * @param mode {string}
     */
    backToList() {

        //  this.clearFormControl();
        this.ruhId = 0;
        setTimeout(() => {
            this.location.back();
        }, 2000);

    }

    goToList() {
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    /**
     * filter ruh list by Status
     * @param status {any}
     */
    onStatusChanged(status: any) {
        this.status = status;
    }
    /**
     * getting files to upload
     * @param event {any}
     */
    onUpload(event: any) {
        for (let file of event.files) {
            this.documentPath = file.name;
        }
    }
    /**
     * getting files to upload
     * @param event {any}
     */
    onfilesUpload(event: any) {
        for (let file of event.taskFiles) {
            this.actvityFilePath = file.name;
        }
    }


    //#endregion

    //#region methods
    bindstatus(statushint: string) {
        this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
                this.RuhStatus = [];
                if (statushint == "new") {
                }
                this.RuhStatus.push(
                    { label: val.OPEN, value: 8 },
                    { label: val.DONE, value: 9 },
                    { label: val.REJECTED, value: 10 }
                );
        }));
    }
    /**
     * bind dropdowns
     */
    dropdownBinding() {
        this.sorting = [];
        this.subscriptions.push(this.translate.stream('SELECT_SORTING').subscribe(val => {
            this.sorting = [];
            this.sorting.push(
                { label: val.PERSON, value: 1 },
                { label: val.ENVIRONMENTAL, value: 2 },
                { label: val.MATERIAL, value: 3 }
            )
        }));
        let department: any[];
        let topics: any[];

        let ResponsiblePerson: any[];
        ResponsiblePerson = this.route.snapshot.data['user'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.vresperson = [];
            this.vresperson.push(
                { label: val.SELECT, value: null }
            );
            if (ResponsiblePerson) {
                ResponsiblePerson.forEach((ResponsiblePerson: any) => {
                    this.vresperson.push({
                        label: ResponsiblePerson.Name,
                        value: ResponsiblePerson.Id
                    });
                });
            }
        }));
        // Department
        this.departments = [];
        this.editDepartments = [];
        if (this.roleId === 1) {
            department = this.route.snapshot.data['adminDepartment'];
        } else {
            department = this.route.snapshot.data['department'];
        }
        if (department) {
            this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.departments = [];
                this.departments.push(
                    { label: val.SELECT, value: null }
                );
                department.forEach((departmentList: any) => {
                    this.departments.push({
                        label: departmentList.Name,
                        value: departmentList.Id
                    });
                });
            }));
        }
        //bind dropdown for Type of Case
        this.Case = [];
        let caseType: any[];
        caseType = this.route.snapshot.data['caseType'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Case = [];
            this.Case.push(
                { label: val.SELECT, value: null }
            );
            if (caseType) {
                caseType.forEach((caseInfo: any) => {
                    this.Case.push({ label: caseInfo.Name, value: caseInfo.Id });

                });
            }
        }));
        // Topics
        this.Topics = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.Topics = [];
            this.Topics.push(
                { label: val.SELECT, value: null }
            );
            if (this.roleId === 1) {
                topics = this.route.snapshot.data['adminTopic'];
            } else {
                topics = this.route.snapshot.data['topic'];
            }
            if (topics) {
                topics.forEach((topicsList: any) => {
                    this.Topics.push({
                        label: topicsList.Name,
                        value: topicsList.Id
                    });

                });
            }
        }));
        this.executions = [];
    }
    /**
     * text box event
     * @param e {any}
     * @param limitNumber {any}
     */
    onNumberChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }
    onCostChange(e: any) {
        let charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }

    //bind dropdown for Topics  
    bindTopicDropdown(caseId: any) {
        let topics: any[];
        this.isCaseTopicExist = true;
        this.subscriptions.push(this.ruhServices.getAllTopics(caseId.value).subscribe(result => {
            if (result) {
                topics = result;
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.Topics = [];
                    this.Topics.push(
                        { label: val.SELECT, value: null }
                    );
                    if (topics) {
                        topics.forEach((topicInfo: any) => {
                            this.Topics.push({
                                label: topicInfo.Name,
                                value: topicInfo.Id
                            });
                        });
                    }
                }));
            }
            else {
                this.isCaseTopicExist = false;
                this.noTopicExistAlert = 'No topic under this case go to list page to add topic';
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.Topics = [];
                    this.Topics.push(
                        { label: val.SELECT, value: null }
                    );
                }));
            }
        }));
    }
    /**
   * filter list based on followup person
   * @param depControl {any}
   */
    onDepartmentChanged(depControl: any) {
        if (depControl) {
            this.subscriptions.push(this.ruhServices.getFollowupofDepartment(depControl.value)
                .subscribe((result: any) => {
                    if (result) {
                        this.isdepartmentFollowupExist = true;
                        this.followupId = result.Id;
                        this.followupName = result.Name;
                    }
                    else {
                        this.isdepartmentFollowupExist = false;
                        this.noFollowupExistAlert = this.translate.instant(`NO_FOLLOWUP`);
                        this.followupId = 0;
                        this.followupName = "Nofollowup";
                    }
                }));
        }
    }
    //#endregion


    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1,
        };
    }

    catchEvent($event:any) {
        if ($event.eventName === 'focus') {
            this.selectFolderName = $event.node.data.filename;
            this.selectFolderId = $event.node.data.nodeId;
        }
    }

    // private
    private flattenArrObj(arr:any) {
        let newArr:any = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach((element:any) => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    gotoBack() {
        this.location.back();
    }

    editActionPlan(actionplanresult: any) {
        this.ruhAddNewActionPopup.reset();
        this.ruhAddNewActionPopup.get('actionStatus')!.patchValue(8);
        if (actionplanresult) {
            this.addActionPlan = true;
            let ActionTitle = '';
            let ActionDescription = '';
            let ActionStatus = '';
            let ActionDeadline = '';
            let ActionExecution = '';

            this.actionId = actionplanresult.Id;
            ActionTitle = actionplanresult.Title;
            ActionDescription = actionplanresult.Description;
            ActionDeadline = actionplanresult.Deadline;
            ActionStatus = actionplanresult.Status;
            ActionExecution = actionplanresult.Reponsible_For_Execution;

            this.ruhAddNewActionPopup = this._fb.group({
                ActionTitle: ActionTitle,
                actionDescription: ActionDescription,
                actionStatus: ActionStatus,
                actionDeadline: ActionDeadline,
                actionExecution: ActionExecution
            });


        }
    }
    Actionconfirm(actionid: any) {
        this.confirmService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.ruhServices.deleteactionplan(actionid).subscribe((result) => {
                    if (result) {
                        this.subscriptions.push(this.ruhServices.getAllActionPlan(this.ruhId)
                            .subscribe(result => {
                                if (result != null) {
                                    this.actionPlanList = result;
                                }
                            }));
                    }
                }))
            }

        });
    }
    setActionDeadlineOptions(event:any){
        event.date = {
            year: event.date.year,
            month: event.date.month,
            day: event.date.day + 1,
        };
        this.actiondateOptions  = {
            dateFormat: 'dd/mm/yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            firstDayOfWeek: 'su',
            satHighlight: true,
            height: '32px',
            selectionTxtFontSize: '14px',
            disableUntil: this.getCurrentDate(),
            disableSince: event.date, //disables days after ruh deadline days
            width: '300px',
            inline: false
        };
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
