import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { KubaServices } from './../kuba/kuba.services';

@Component({
    selector: 'toaster-component',
    // providers: [ToasterService], 
    template:
    // ''
    `<p-toast></p-toast>`
        // '<toaster-container [toasterconfig]="toastConfig"></toaster-container>'
        // `<toaster-container></toaster-container>
        // <button (click)="popToast()">pop toast</button>`
})
export class ToasterComponent {
    @Input() toast: any;
    successTitle: any;
    successBody: any;
    errorTitle: any;
    errorBody: any;
    approvalTitle: any;
    approvalBody: any;
    approvedTitle: any;
    approvedBody: any;
    private subscription: Subscription;
    private language: string = this.translator.currentLang
        ? this.translator.currentLang
        : 'en';
    errorTitleRecurring: string;
    errorBodyRecurring: string;
    constructor(
        private toasterService: MessageService,
        private translator: TranslateService,
        private kubaServices: KubaServices
    ) {
        this.subscription = this.kubaServices.getLanguage$.subscribe(lang => {
            this.language = lang;
        });
    }

setLanguage() {
this.language = sessionStorage.getItem('languageMode')!;
if (this.language === 'no-NO') {
(this.successTitle = 'Legg til / oppdater'),
(this.successBody = 'Data lagret vellykket'),
(this.errorTitle = 'slettet'),
(this.errorBody = 'Data slettet vellykket'),
(this.approvalTitle = 'Godkjenningsmelding sendt'),
(this.approvalBody = 'Godkjenningsmelding sendt vellykket'),
(this.approvedTitle = ''),
(this.errorTitleRecurring = 'stoppet gjentagelser'),
(this.errorBodyRecurring = 'Fremtidige gjentagelser er stoppet'),
(this.approvedBody = 'Dokumenter er godkjent');
} else if (this.language === 'sv-SE') {
(this.successTitle = 'Lägg till / Uppdatera'),
(this.successBody = 'Uppgifter har sparats'),
(this.errorTitle = 'Borttaget'),
(this.errorBody = 'Uppgifter har raderats'),
(this.approvalTitle = 'Godkännandemeddelande skickat'),
(this.approvalBody = 'Godkännandemeddelande skickades framgångsrikt'),
(this.approvedTitle = ''),
(this.errorTitleRecurring = 'stoppet gjentagelser'),
(this.errorBodyRecurring = 'Fremtidige gjentagelser er stoppet'),
(this.approvedBody = 'Dokument har godkänts');
}
else {
(this.successTitle = 'Add/Update'),
(this.successBody = 'Data saved successfully'),
(this.errorTitle = 'Deleted'),
(this.errorBody = 'Data deleted successfully'),
(this.approvalTitle = 'Notice Sent'),
(this.approvalBody = 'Approval notice sent successfully'),
(this.approvedTitle = ''),
(this.errorTitleRecurring = 'Recurring Stopped'),
(this.errorBodyRecurring = 'Future recurrences stopped successfully'),
(this.approvedBody = 'Documents have been approved');
}
}

// Toast callback
callToast(): void {
this.setLanguage();
let toast = this.toasterService.add({
    severity: 'success',
    summary: this.successTitle,
    detail: this.successBody
});
}

callToastDlt(): void {
this.setLanguage();
this.toasterService.add({
    severity: 'error',
    summary: this.errorTitle,
    detail: this.errorBody
});
}

callToaststopRecurring(): void {
    this.setLanguage();
    this.toasterService.add({
        severity: 'error',
        summary: this.errorTitleRecurring,
        detail: this.errorBodyRecurring
    });
    }

callToastApprovalNotice() : void  {
    this.setLanguage();
    this.toasterService.add({
        severity: 'success',
        summary: this.approvalTitle,
        detail: this.approvalBody
    });
    }

    callToastApprovedNotice() : void  {
        this.setLanguage();
        this.toasterService.add({
            severity: 'success',
            summary: this.approvedTitle,
            detail: this.approvalBody
        });
        }

    callDistributionToast(): void {
        // this.toast = {
        this.toasterService.add({
            severity: 'success',
            summary: this.language === 'no' ? 'Legg til' : 'Add',
            detail: this.language === 'no' ? 'Distribution added successfully' : 'Distribution added successfully'
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}