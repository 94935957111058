<div class="main-content">
    <div class="main-heading">
        <span translate>ADD_DEVICE</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="deviceForm" (ngSubmit)="SaveRiskanalysis(deviceForm.value)" #f="ngForm" novalidate
              name="form" class="form">
                <div class="form-group">
                    <label for="ZoneName" class="col-form-label">
                        <span translate>DEVICE_ID</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="DeviceID" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['DeviceID']"></control-messages>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['DeviceName']">
                    <label for="Department" class="col-form-label">
                        <span translate>DEVICE_NAME </span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="DeviceName" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['DeviceName']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR </span>1
                    </label>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorID1']">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR_ID</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorID1" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorID1']"></control-messages>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorName1']">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>SENSOR_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorName1" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorName1']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>STATUS</span>
                    </label>
                    <div>
                        <p-checkbox formControlName="Status1"></p-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR </span>2
                    </label>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorID2']">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR_ID</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorID2" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorID2']"></control-messages>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorName2']">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>SENSOR_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorName2" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorName2']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>STATUS</span>
                    </label>
                    <div>
                        <p-checkbox formControlName="Status2"></p-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR </span>3
                    </label>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorID3']">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR_ID</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorID3" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorID3']"></control-messages>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorName3']">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>SENSOR_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorName3" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorName3']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>STATUS</span>
                    </label>
                    <div>
                        <p-checkbox formControlName="Status3"></p-checkbox>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR </span>4
                    </label>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorID4']">
                    <label for="Description" class="col-form-label">
                        <span translate>SENSOR_ID</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorID4" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorID4']"></control-messages>
                </div>
                <div class="form-group" *ngIf="deviceForm.controls['SensorName4']">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>SENSOR_NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="SensorName4" required />
                    </div>
                    <control-messages [control]="deviceForm.controls['SensorName4']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="NoOfDays" class="col-form-label">
                        <span translate>STATUS</span>
                    </label>
                    <div>
                        <p-checkbox formControlName="Status4"></p-checkbox>
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <a [routerLink]="['./../devices']" class="btn btn-outline-secondary">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </a>
                    <button class="btn btn-primary" type="submit" (click)="saveProceed(f.value)"
                      [disabled]="!deviceForm.valid">
                        <span translate>SAVE_AND_BACK</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>