export class FollowUpMessage {
    Id ?: number;
    SenderList ?: ToUserData[];
    FromUser ?: number;
    Subject ?: string;
    Message ?: string;
    Status ?: boolean;
    IsDeleted ?: boolean;
    IsSent ?: boolean;
    AttachmentUrl ?: string;
    OutboxStatus ?: boolean;
    BusinessId ?: number;
    AdminDelete ?: boolean;
    ApplicationId ?: number;
    SendDate ?: Date;
    CreatedBy ?: number;
    CreatedOn ?: Date;
    ModifiedBy ?: number;
    ModifiedOn ?: Date;
}
export class ToUserData {
    UserId ?: number;
    Name ?: string;
    mail ?: string;
}
