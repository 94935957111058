import { ActivatedRoute, Params } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    moduleId: module.id,
    selector: 'contact-edit',
    templateUrl: 'contactEdit.component.html'
})

export class ContactEditComponent implements OnInit {
    myPage = null;

    constructor(
        private location: Location,
        private route: ActivatedRoute
    ) {
        this.myPage = this.route.snapshot.queryParams['myPage']
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }

    gotoBack() {
        this.location.back();
    }
}
