<div class="mbtm-20 table-responsive" *ngIf='foodsafety'>
    <table class="table table-striped">
        <tbody>
            <tr>
                <th translate>{{featureKeyName}}</th>
                <td translate> : {{projectName}}</td>
            </tr>
            <tr>
                <th translate>ADDRESS</th>
                <td translate> : {{ProjectAddress}}</td>
            </tr>
            <tr>
                <th translate>{{buildername}}</th>
                <td translate> : {{ProjectBuilder}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table [value]="crewList" #crewListTable [rows]="10" [paginator]="true" [pageLinks]="3"
        [rowsPerPageOptions]="[5,10,20]"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="crewListTable.value" [additionalData]="additionalData"></kuba-export>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="OrganizationNumber" *ngIf="foodsafety">{{'ORGANISATION_NUMBER' | translate}} <p-sortIcon field="OrganizationNumber"></p-sortIcon></th>
                <th pSortableColumn="CompanyName" *ngIf="foodsafety">{{'COMPANY_NAME' | translate}} <p-sortIcon field="CompanyName"></p-sortIcon></th>
                <th pSortableColumn="Name">{{'NAME' | translate}} <p-sortIcon field="Name"></p-sortIcon></th>
                <th pSortableColumn="Dob" *ngIf="foodsafety">{{'DATE_BIRTH' | translate}} <p-sortIcon field="Dob"></p-sortIcon></th>
                <th pSortableColumn="HSECardNumber" *ngIf="foodsafety">{{'HSE_CARD' | translate}} <p-sortIcon field="HSECardNumber"></p-sortIcon></th>
                <th pSortableColumn="Date">{{'DATE' | translate}} <p-sortIcon field="Date"></p-sortIcon></th>
                <th pSortableColumn="TimeZone">{{'TIME' | translate}} <p-sortIcon field="TimeZone"></p-sortIcon></th>
                <th>{{'STATUS' | translate}}</th>
                <th pSortableColumn="Address">{{'GPS_LOCATION'|translate}} <p-sortIcon field="Address"></p-sortIcon></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-timelog>
            <tr>
                <td *ngIf="foodsafety">
                    <span>{{timelog.OrganizationNumber}}</span>
                </td>
                <td *ngIf="foodsafety">
                    <div pTooltip="{{timelog.CompanyName}}" tooltipPosition="top">{{timelog.CompanyName}}</div>
                </td>
                <td>
                    <span>{{timelog.Name}}</span>
                </td>
                <td *ngIf="foodsafety">
                    <span>{{timelog.Dob}}</span>
                </td>
                <td *ngIf="foodsafety">
                    <span>{{timelog.HSECardNumber}}</span>
                </td>
                <td>
                    <span>{{timelog.Date | date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span>{{timelog.TimeZone}}</span>
                </td>
                <td>
                    <p-inputSwitch onLabel="'IN_'|translate" [disabled]="true" offLabel="'OUT'|translate"
                        [(ngModel)]="timelog.IsMode">
                    </p-inputSwitch>
                </td>
                <td>
                    <div pTooltip="{{timelog.Address}}" tooltipPosition="top">{{timelog.Address}}</div>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="5">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
</div>