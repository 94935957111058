export class Links {
  Id: number;
  ApplicationId: number;
  PortalId: number;
  BusinessId: number;
  Title: string;
  Url: string;
  Status: string;
  ShowInBusiness: true;
  ShowInApplication: true;
  ShowInPortal: true;
  CreatedBy: number;
  CreatedOn: Date;
  ModifiedBy: number;
  ModifiedOn: Date
}
