import { FollowUpMessageService } from './../../follow-ups/services/message.services';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { MoveBusinessServices } from './move-business.service';

@Injectable()
export class AdminAndPortalListResolver implements Resolve<any[]> {
    constructor(private backend: MoveBusinessServices) { }
    resolve(): Observable<any[]> {
        return this.backend.getAdminUsersAndPortalTitles();
    }
}
