import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'timetable',
   templateUrl: 'timetable.component.html'
})
export class TimetableComponent implements OnInit {
   constructor() { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}
