
import { NotificationSubscription } from "src/app/shared/employee-contact";

export class OtherSystem {
    Id: number;
    Title: string;
    Description: string;
    IsEditorOnly: string;
    Status: string;
    BusinessId: number;
    ApplicationId: number;
    CreatedBy: number;
    CreatorName: string;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    ShowDeviation?: boolean;
    OSEmailSubscribers: NotificationSubscription[];
    OSUserDto: OtherSystemUser[];
    OtherSystemUsers : OtherSystemUsers;
    UserCount: number;
}

export class OtherSystemDetail {
    Title: string;
    Description: string;
    constructor() {
        this.Title = '';
        this.Description = '';
    }
}
export class OtherSystemUser {
    OtherSystemsId: number;
    UserId: number;
    Name: string;
}

export class OtherSystemUsers {
    OtherSystemsId: number;
    UserId: number;
    Count:String;
    NameList: String;
}

export class KeyPairValue {
    Key?: number;
    Value: string;
}
