 
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { TypeOfRegistrationListResolver } from './services/registration.resolver.service';
import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { AppSharedModule } from './../shared/appShared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RegistrationServices } from './services/registration.service';
import { RegistrationComponent } from './registration.component';
import {
  RegistrationListComponent,
  RegistrationNewComponent
} from './components/index'
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { RegistrationListResolver, RegistrationEditResolver } from 'src/app/kuba/Registration/services/registration.resolver.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MyDatePickerModule } from 'mydatepicker';


@NgModule({
  imports: [
    AppSharedModule,
    RouterModule,
    TranslateModule,
    ConfirmDialogModule,
    EcplDocumentExplorerModule,
    MyDatePickerModule,
    KubaExportModule
  ],
  exports:
    [
      RegistrationComponent,
      RegistrationListComponent,
      RegistrationNewComponent
    ],
  declarations:
    [
      RegistrationComponent,
      RegistrationListComponent,
      RegistrationNewComponent
    ],
  providers: [RegistrationServices,
    RegistrationListResolver,
    RegistrationEditResolver,
    TypeOfRegistrationListResolver
  ],
})
export class RegistrationModule { }
