import { ManualService } from './../../manuals/services/manual.services';
import { EmployeeSicknessServices } from './employeesickness.service';
import { User } from './../../users/models/user';
import { BaseServices } from './../../kuba.services';
import { EmployeeLeaveService } from './employeeleave.service';
import { EmployeeLeave } from './../models/employeeLeave';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ResponsibleExecution } from 'src/app/kuba/employees/models';


@Injectable()
export class EmpLeaveListResolver implements Resolve<EmployeeLeave[]> {
  constructor(private backend: EmployeeLeaveService) { }
  resolve(): Observable<EmployeeLeave[]> {
    return this.backend.getAllByBusinessId(BaseServices.BusinessId, new Date().getFullYear());
  }
}

@Injectable()
export class EmpLeaveEditResolver implements Resolve<EmployeeLeave> {
  constructor(private backend: EmployeeLeaveService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getById(+route.params['eid']);
  }
}
@Injectable()
export class ApprovalPersonResolver implements Resolve<User> {
  constructor(private backend: EmployeeLeaveService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllApprovedPerson();
  }
}
@Injectable()
export class EmpLeaveByLeaveResolver implements Resolve<EmployeeLeave> {
  constructor(private backend: EmployeeLeaveService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getEmployeeLeaveByLeaveId(+route.params['lid'] ? +route.params['lid'] : 0);
  }
}

@Injectable()
export class SicknessAbsenceCodeResolver implements Resolve<any> {
  constructor(private backend: EmployeeSicknessServices) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getSicknessAbsenceCode(BaseServices.BusinessId);
  }
}
@Injectable()
export class ResposibleExecutionResolver implements Resolve<ResponsibleExecution> {
  constructor(private backend: ManualService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getBusinessUserListForManuals(BaseServices.BusinessId);
  }
}
@Injectable()
export class BusinessHolidaysResolver implements Resolve<any[]> {
  constructor(private backend: EmployeeLeaveService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllHolidays(BaseServices.BusinessId);
  }
}

