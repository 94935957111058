
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-project-details',
    templateUrl: 'project-details.component.html'
})

export class ProjectDetailsComponent implements OnInit {
    constructor() { }

    ngOnInit() {document.querySelector("body").classList.remove("opened"); }
}
