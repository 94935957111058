import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroDocumentService } from '../../services/electro-document.services';
import { ConfirmationService } from 'primeng/api';
import { KubaExportService } from 'src/app/kuba/shared/kuba-exports/kuba-export.service';
import { ElectroInstallatioForms } from '../../models/electrofeatures';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { ElectroFormTemplates, ElectroRiskAssessment, ElectroCourseOverview, ElectroFireAlarmSystem, ElectroEmergencyLightSystem } from '../../models/electroinstallation';
import { Status, FeatureKey } from 'src/app/_models';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { RiskAssessmentComponent } from './risk-assessment.component';
import { CourseOverviewComponent } from './course-overview.component';
import { FilreAlarmComponent } from './fire-alarm-system.component';
import { EmergencyLightingComponent } from './emergency-lighting.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';
import { MeasureProtocolList, MP } from 'src/app/kuba/measure-protocol/models/measure-protocol';
import { ElectroService } from '../../services/electro.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'installation-forms',
    templateUrl: 'installation-forms.component.html'
})

export class InstallationFormsComponent implements OnInit {
    enabledForms: any[];
    contractId: number;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('insFormsTable',{static: false}) insFormsTable: Table;
    @ViewChild('riskAssessment',{static: false}) riskAssessment: RiskAssessmentComponent;
    @ViewChild('course',{static: false}) course: CourseOverviewComponent;
    @ViewChild('filreAlarm',{static: false}) filreAlarm: FilreAlarmComponent;
    @ViewChild('emergencyLighting',{static: false}) emergencyLighting: EmergencyLightingComponent;
    additionalData = {
        fileName: 'INSTALLATION_FORMS_LIST',
        header: 'INSTALLATION_FORMS_LIST',
        businessId: BaseServices.BusinessId,
        cultureInfo: BaseServices.userCultureInfo(),
        columnNames: [
            { title: 'Document No', dataKey: 'DocumentNumber' },
            { title: 'Title ', dataKey: 'TranslationKey' }
        ]
    };
    showLoader = false;
    editContent: boolean;
    previewHeader: string;
    EmerOtherEquipments: any[];
    formTemplate: ElectroFormTemplates;
    editFormType: string;
    DocumentAssReview: { 'id': number; 'name': string; 'comments': string; }[];
    distributionItems: { 'OrderId': number; 'Id': number; 'Status': number; 'DistributionId': string; 'RoomNo': string; 'Floor': string; 'MeasureNumber': string; 'DistributionType': string; }[];
    measureProtocolForm: FormGroup;
    measureProtocols: MeasureProtocolList[];
    clients: any;
    private subscriptions: Subscription[] = [];

    measureProtocol: MP;
    saveprogress: boolean = false;
    constructor(private route: ActivatedRoute,
        private electroDocService: ElectroDocumentService,
        public measureprotocolservice: MeasureProtocolService,
        private installationsFormsService: ElectroInstallationService,
        private electroService: ElectroService,
        private fb: FormBuilder,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private kubaExportService: KubaExportService) { }

    ngOnInit() {
        this.contractId = this.route.snapshot.parent.params['Id'];
        this.enabledForms = this.route.snapshot.data['enabledForms'];
        this.measureProtocolForm = this.fb.group({
            'Number': ['', Validators.required],
            'Name': ['', [Validators.required, ValidationService.noWhitespaceValidator]],
            'ClientId': ['', Validators.required]
        });
    }

    /**
     * download form as pdf event
     * @param formKey 
     */
    downloadPDF(formKey) {
        this.showLoader = true;
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let languageId = appSettings.Language.LanguageId;
        let CultureInfo = BaseServices.userCultureInfo();
        this.subscriptions.push(this.electroDocService
            .generateElectroInstallationForms(BaseServices.BusinessId, CultureInfo, this.contractId, formKey)
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.translate.instant(formKey)}.${type.toLowerCase()}`;
                    link.click();
                },
                error => {
                    this.showLoader = false;
                    alert('Export not downloaded');
                }));
    }
    downloadListPDF() {
        this.showLoader = true;
        let CultureInfo = sessionStorage.getItem('languageMode');
        let ContractNumber = localStorage.getItem('contractNumber');
        let printList = [];
        this.enabledForms.forEach(f => {
            let element = {
                'DocumentNumber': f.DocumentNumber,
                'TranslationKey': this.translate.instant(f.TranslationKey)
            }
            printList.push(element);
        });
        this.subscriptions.push(this.kubaExportService
            .generateExportFile(printList, this.additionalData.columnNames,
                'PDF', this.translate.instant(this.additionalData.header), 0, BaseServices.BusinessId, CultureInfo)
            .subscribe(
                blob => {
                    this.showLoader = false;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${ContractNumber + '-' + this.translate.instant(this.additionalData.fileName)}.${('PDF').toLowerCase()}`;
                    link.click();
                },
                error => {
                    this.showLoader = false;
                    alert(this.translate.instant('EXPORT_NOT_DOWNLOAD'));
                }));
    }

    editTemplate(form) {
        this.editContent = true;
        this.previewHeader = this.translate.instant(form.TranslationKey);

        this.formTemplate = new ElectroFormTemplates();
        this.formTemplate.FormType = form.Id;
        switch (form.Id) {
            case ElectroInstallatioForms.ASSESSMENT_OF_AVAILABLE_DOCUMENTATION:
                this.editFormType = 'doc-ass';
                this.DocumentAssReview = [
                    {
                        'id': 1,
                        'name': "HMS-målsetting",
                        'comments': ""
                    },
                    {
                        'id': 2,
                        'name': "Instruks for instruert personell",
                        'comments': ""
                    },
                    {
                        'id': 3,
                        'name': "Tilsynsrapporter",
                        'comments': ""
                    },
                    {
                        'id': 4,
                        'name': "Bygningstegninger",
                        'comments': ""
                    },
                    {
                        'id': 5,
                        'name': "Kursoversikt",
                        'comments': ""
                    },
                    {
                        'id': 6,
                        'name': "Samsvarserklæring datert før januar 1999",
                        'comments': ""
                    },
                    {
                        'id': 7,
                        'name': "Dokumentasjon på elektrisk utstyr",
                        'comments': ""
                    },
                    {
                        'id': 8,
                        'name': "Dokumentasjon på elektromedisinsk utstyr",
                        'comments': ""
                    },
                    {
                        'id': 9,
                        'name': "Brukerveiledninger for elektrisk utstyr",
                        'comments': ""
                    },
                    {
                        'id': 10,
                        'name': "Monteringsveiledninger for utstyr med høy brannrisiko",
                        'comments': ""
                    },
                    {
                        'id': 11,
                        'name': "Dokumentasjon på varmekabler i tak og gulv",
                        'comments': ""
                    },
                    {
                        'id': 12,
                        'name': "Tegninger over det elektriske anlegget",
                        'comments': ""
                    },
                    {
                        'id': 13,
                        'name': "Beregningsunderlag for vern og ledertverrsnitt",
                        'comments': ""
                    },
                    {
                        'id': 14,
                        'name': "Dokumentasjon på om anlegget er bygd for sakkyndig eller usakkyndig betjening",
                        'comments': ""
                    },
                    {
                        'id': 15,
                        'name': "Stoffkartotek",
                        'comments': ""
                    }];
                if (form.ElectroFormTemplates) {
                    this.formTemplate = form.ElectroFormTemplates;
                    if (this.formTemplate.FormTemplate) {
                        this.DocumentAssReview = JSON.parse(this.formTemplate.FormTemplate);
                    }
                }
                break;
            case ElectroInstallatioForms.RISK_ASSESSMENT_OF_THE_INSTALLATION:
                this.editFormType = 'risk-ass';
                setTimeout(() => {
                    this.bindRisk(form.ElectroFormTemplates);
                }, 500);
                break;
            case ElectroInstallatioForms.ELECTRICAL_DISTRIBUTION_OVERVIEW:
                this.editFormType = 'distribution';
                this.distributionItems = [{
                    'OrderId': 1,
                    'Id': 0,
                    'Status': 1,
                    'DistributionId': "",
                    'RoomNo': "",
                    'Floor': "",
                    'MeasureNumber': "",
                    'DistributionType': ""
                }];
                if (form.ElectroFormTemplates) {
                    this.formTemplate = form.ElectroFormTemplates;
                    if (this.formTemplate.FormTemplate) {
                        this.distributionItems = JSON.parse(this.formTemplate.FormTemplate);
                    }
                }
                break;
            case ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW:
                this.editFormType = 'course';
                setTimeout(() => {
                    this.bindCourse(form.ElectroFormTemplates);
                }, 500);
                break;
            case ElectroInstallatioForms.FIRE_ALARM_SYSTEM:
                this.editFormType = 'fire-alarm';
                setTimeout(() => {
                    this.bindFireAlarm(form.ElectroFormTemplates);
                }, 500);
                break;
            case ElectroInstallatioForms.EMERGENCY_LIGHTING_SYSTEM:
                this.editFormType = 'emer-light';
                setTimeout(() => {
                    this.bindEmerLight(form.ElectroFormTemplates);
                }, 500);
                break;
            case ElectroInstallatioForms.MEASURE_PROTOCOL:
                this.editFormType = 'measure-protocol';
                setTimeout(() => {
                    this.initMeasureProtocol();
                }, 500);
                break;
        }

    }
    //#region Functions - Add/Delete items in forms
    //Emergency lighting
    addEmerItem(item) {
        //splice items after the current item in equipment table.
        let refHighVal = this.EmerOtherEquipments[0].itemRef;
        this.EmerOtherEquipments.forEach(e => {
            if (!e.itemRef) {
                e.itemRef = e.id;
            } else {
                refHighVal = refHighVal < e.itemRef ? e.itemRef : refHighVal;
            }
        });
        let itemsToShift = this.EmerOtherEquipments.splice(this.EmerOtherEquipments.indexOf(item) + 1, this.EmerOtherEquipments.length);
        let newItem = {
            'id': item.id + 1,
            'itemRef': refHighVal ? refHighVal + 1 : item.id + 1,
            'no': "",
            'floor': "",
            'location': "",
            'type': "",
            'armature': "",
            'voltage': "",
            'battery': ""
        };
        //push new item to equipment table.
        this.EmerOtherEquipments.push(newItem);
        //push the spliced items after new item.
        itemsToShift.forEach(i => {
            ++i.id;
            this.EmerOtherEquipments.push(i);
        });
    }
    deleteEmerItem(item) {
        this.EmerOtherEquipments.splice(this.EmerOtherEquipments.indexOf(item), 1);
    }

    //Document assessment
    addDocAssItem(item) {
        let itemsToShift = this.DocumentAssReview.splice(this.DocumentAssReview.indexOf(item) + 1, this.DocumentAssReview.length);
        let newItem = { 'id': item.id + 1, 'name': "", 'comments': "" };
        this.DocumentAssReview.push(newItem);
        itemsToShift.forEach(i => {
            ++i.id;
            this.DocumentAssReview.push(i);
        });
    }
    deleteDocAssItem(item) {
        this.DocumentAssReview.splice(this.DocumentAssReview.indexOf(item), 1);
    }

    //Distribution overview
    addDistributionItem(item) {
        let itemsToShift = this.distributionItems.splice(this.distributionItems.indexOf(item) + 1, this.distributionItems.length);
        let newItem = {
            'OrderId': item.OrderId + 1,
            'Id': 0,
            'Status': 1,
            'DistributionId': "",
            'RoomNo': "",
            'Floor': "",
            'MeasureNumber': "",
            'DistributionType': ""
        };
        this.distributionItems.push(newItem);
        itemsToShift.forEach(i => {
            ++i.OrderId;
            this.distributionItems.push(i);
        });
    }
    deleteDistributionItem(item) {
        this.distributionItems.forEach(i => {
            if (i.Id === item.Id && i.OrderId === item.OrderId) {
                i.Status = 0;
            }
        });
    }
    currentTypeChecked(item, type) {
        this.distributionItems.forEach(i => {
            if (i.OrderId === item.OrderId) {
                i.DistributionType = type;
            }
        });
    }
    //#endregion

    bindRisk(risk) {
        this.riskAssessment.riskAssessment = new ElectroRiskAssessment();
        this.riskAssessment.elementsConsidered = [];
        this.riskAssessment.elementsConsidered.push(
            { id: 1, elements: this.translate.instant('COMPETENCE_INSTRUCTED_PERSONNEL'), comments: "" },
            { id: 2, elements: this.translate.instant('LOCKING_DISTRIBUTION_BOARDS'), comments: "" },
            { id: 3, elements: this.translate.instant('STATE_LIGHTING_EQUIPMENT'), comments: "" },
            { id: 4, elements: this.translate.instant('STATE_MOVING_WIRES'), comments: "" },
            { id: 5, elements: this.translate.instant('PCB_CONTAINING_CAPACITORS'), comments: "" },
            { id: 6, elements: this.translate.instant('DANGER_SHORT_CIRCUIT'), comments: "" },
            { id: 7, elements: this.translate.instant('DANGER_INJURY_ACCIDENT'), comments: "" });
        this.riskAssessment.editMode = true;
        if (risk ? risk.FormTemplate : false) {
            this.formTemplate = risk;
            this.riskAssessment.riskAssessment = JSON.parse(risk.FormTemplate);
            if (this.riskAssessment.riskAssessment.OtherMatters) {
                this.riskAssessment.elementsConsidered = JSON.parse(this.riskAssessment.riskAssessment.OtherMatters);
            }
        }
    }

    bindCourse(course) {
        this.course.contractId = this.contractId;
        this.course.courseOverview = new ElectroCourseOverview();
        this.course.isTemplate = true;
        this.course.courseDetails = [{
            'id': 1,
            'course': "",
            'loadDiscription': "",
            'protectionType': "",
            'protectionTin_a': "",
            'protectionTkar': "",
            'cableS': "",
            'cableL': "",
            'cableR': "",
            'rekkekl': "",/* order in English*/
            'JFB': ""
        }];
        this.course.editMode = true;
        if (course ? course.FormTemplate : false) {
            this.formTemplate = course;
            this.course.courseOverview = JSON.parse(course.FormTemplate);
            if (this.course.courseOverview.CourseOverviewDetails) {
                this.course.courseDetails = JSON.parse(this.course.courseOverview.CourseOverviewDetails);
            }
        }
        this.course.courseOverview.ElectroContractId = this.contractId;
        this.course.initForm(this.course.courseOverview);
    }

    bindFireAlarm(fireAlarm) {
        this.filreAlarm.firealarmSystem = new ElectroFireAlarmSystem();
        this.filreAlarm.otherEquipment = [{
            'id': 1,
            'itemRef': 1,
            'amount': "",
            'type': "",
            'description': "",
            'location': "",
            'merking': "" /* labelling in English */
        }];
        this.filreAlarm.editMode = true;
        if (fireAlarm ? fireAlarm.FormTemplate : false) {
            this.formTemplate = fireAlarm;
            this.filreAlarm.firealarmSystem = JSON.parse(fireAlarm.FormTemplate);
            if (this.filreAlarm.firealarmSystem.OtherEquipment) {
                this.filreAlarm.otherEquipment = JSON.parse(this.filreAlarm.firealarmSystem.OtherEquipment);
            }
            if (this.filreAlarm.firealarmSystem.SystemContent) {
                this.filreAlarm.systemContent = JSON.parse(this.filreAlarm.firealarmSystem.SystemContent);
            }
        }
    }

    bindEmerLight(emerLight) {
        this.emergencyLighting.lighting = new ElectroEmergencyLightSystem();
        this.emergencyLighting.otherEquipments = [{
            'id': 1,
            'itemRef': 1,
            'no': "",
            'floor': "",
            'location': "",
            'type': "",
            'armature': "",
            'voltage': "",
            'battery': ""
        }];
        this.emergencyLighting.editMode = true;
        if (emerLight ? emerLight.FormTemplate : false) {
            this.formTemplate = emerLight;
            this.emergencyLighting.lighting = JSON.parse(emerLight.FormTemplate);
            if (this.emergencyLighting.lighting.OtherEquipment) {
                this.emergencyLighting.otherEquipments = JSON.parse(this.emergencyLighting.lighting.OtherEquipment);
            }
        }
    }
    saveTemplate() {
        let duplicateExists = false;
        if (this.formTemplate.Id) {
            this.formTemplate.ModifiedBy = BaseServices.UserId;
        }
        else {
            this.formTemplate.CreatedBy = BaseServices.UserId;
        }
        this.formTemplate.ContractId = this.contractId
        this.formTemplate.Status = Status.Active;
        switch (this.editFormType) {
            case 'doc-ass':
                this.formTemplate.FormTemplate = JSON.stringify(this.DocumentAssReview);
                break;
            case 'risk-ass':
                if (this.riskAssessment.elementsConsidered) {
                    this.riskAssessment.riskAssessment.OtherMatters = JSON.stringify(this.riskAssessment.elementsConsidered);
                }
                this.formTemplate.FormTemplate = JSON.stringify(this.riskAssessment.riskAssessment);
                break;
            case 'distribution':
                this.formTemplate.FormTemplate = JSON.stringify(this.distributionItems);
                break;
            case 'course':
                this.course.courseDetails.forEach(c => {
                    let crs = this.course.courseDetails.filter(x => x.course == c.course);
                    if (crs.length > 1) {
                        duplicateExists = true;
                    }
                });
                if (duplicateExists) {
                    alert(this.translate.instant('COURSES_SHOULD_UNIQUE'));
                }
                else {
                    if (this.course.courseDetails) {
                        this.course.courseOverview.CourseOverviewDetails = JSON.stringify(this.course.courseDetails);
                    }
                    this.formTemplate.FormTemplate = JSON.stringify(this.course.courseOverview);
                }
                break;
            case 'fire-alarm':
                if (this.filreAlarm.otherEquipment) {
                    this.filreAlarm.firealarmSystem.OtherEquipment = JSON.stringify(this.filreAlarm.otherEquipment);
                }
                if (this.filreAlarm.systemContent) {
                    this.filreAlarm.firealarmSystem.SystemContent = JSON.stringify(this.filreAlarm.systemContent);
                }
                this.formTemplate.FormTemplate = JSON.stringify(this.filreAlarm.firealarmSystem);
                break;
            case 'emer-light':
                if (this.emergencyLighting.otherEquipments) {
                    this.emergencyLighting.lighting.OtherEquipment = JSON.stringify(this.emergencyLighting.otherEquipments);
                }
                this.formTemplate.FormTemplate = JSON.stringify(this.emergencyLighting.lighting);
                break;
        }
        if(!duplicateExists){
            this.subscriptions.push(this.installationsFormsService.SaveTemplate(this.formTemplate).subscribe(res => {
                if (res) {
                    this.editContent = false;
                    this.toasterComponent.callToast();
                    this.subscriptions.push(this.installationsFormsService.getEnabledFormsWithTemplate(this.contractId).subscribe(res => {
                        if (res) {
                            this.enabledForms = res;
                        }
                    }))
                }
            }))
        }
        
    }

    initMeasureProtocol() {
        this.subscriptions.push(this.measureprotocolservice.getAllMeasureProtocol(FeatureKey.ELECTRO, this.contractId, 'KUNDE_EL')
            .subscribe(result => {
                if (result) {
                    this.measureProtocols = result;
                    if (result.length) {
                        this.editMP(result[0]);
                    }
                }
            }));
            this.subscriptions.push(this.electroService.GetClientUsersBySelfInspection(this.contractId).subscribe(Clients => {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                this.clients = [];
                this.clients.push(
                    { label: val.SELECT, value: null }
                );
            }));
            if (Clients) {
                Clients.forEach(element => {
                    this.clients.push({ label: element.Name, value: element.Id })
                });
            }
        }))
    }

    saveMeasureProtocol() {
        this.saveprogress = true;
        let mp = new MP();
        mp.Id = (this.measureProtocol) ? this.measureProtocol.Id : 0;
        mp.Number = this.measureProtocolForm.value.Number;
        mp.Name = this.measureProtocolForm.value.Name;
        mp.ClientId = this.measureProtocolForm.value.ClientId;
        mp.KundeELId = this.contractId;
        mp.Status = 1;
        mp.FeatureId = FeatureKey.ELECTRO;
        if (mp.Id > 0) {
            mp.CreatedOn = this.measureProtocol.CreatedOn;
            mp.CreatedBy = this.measureProtocol.CreatedBy;
            mp.ModifiedOn = new Date();
            mp.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice.update(this.contractId, mp).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
                this.saveprogress = false;
            }));
        } else {
            mp.CreatedOn = new Date();
            mp.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice.create(this.contractId, mp).subscribe((result: any) => {
                if (result) {
                    this.measureProtocol = result;
                    this.toasterComponent.callToast();
                }
                this.saveprogress = false;
            }));
        }
    }

    editMP(mp) {
        this.measureProtocol = new MP();
        this.measureProtocol.Id = mp.Id;
        this.measureProtocol.CreatedBy = mp.CreatedBy;
        this.measureProtocol.CreatedOn = mp.CreatedOn;
        this.measureProtocolForm = this.fb.group({
            'Number': [mp.Number, Validators.required],
            'Name': [mp.Name, [Validators.required, ValidationService.noWhitespaceValidator]],
            'ClientId': [mp.ClientId, Validators.required]
        });
    }

    /**
     * delete mp based on id
     * @param  mp
    */
    deleteMP(mpId) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.measureprotocolservice.deleteMP(mpId, this.contractId).subscribe(result => {
                    this.subscriptions.push(this.measureprotocolservice.getAllMeasureProtocol(FeatureKey.ELECTRO, this.contractId, 'KUNDE_EL')
                        .subscribe(result => this.measureProtocols = result));
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    onBuidingSelect(distributionId) {
        if (distributionId) {
            this.course.showLoader = true;
            this.subscriptions.push(this.installationsFormsService.GetCourseFormTemplateByDistribution(this.contractId,
                distributionId).subscribe(courseTemp => {
                    this.course.showLoader = false;
                    if (courseTemp) {
                        this.bindCourse(courseTemp);
                    } else {
                        this.formTemplate = new ElectroFormTemplates();
                        this.formTemplate.FormType = ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW;
                        this.course.initForm();
                        this.course.courseOverview.DistributionId = distributionId;
                    }
                }));
        } else {
            this.formTemplate = new ElectroFormTemplates();
            this.formTemplate.FormType = ElectroInstallatioForms.ELECTRICAL_COURSE_OVERVIEW;
            this.course.initForm();
            this.course.courseOverview.DistributionId = distributionId;
        }
    }

    hideDelete(){
        let activeDist = this.distributionItems.map(x=>x.Status != 0);
        return activeDist.filter(x=> x == true).length > 1;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}