import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroSettings } from 'src/app/kuba/electro/models/electrocontract';
import { ElectroService } from 'src/app/kuba/electro/services/electro.services';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-deviation-settings',
  templateUrl: 'deviation-settings.component.html',
})
export class DeviationSettingsComponent implements OnInit {
  saveData = new ElectroSettings;
  insertStandardtext = "Static text";
  private subscriptions: Subscription[] = [];
  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  constructor(private electroServices: ElectroService, private route: ActivatedRoute,
    private translate: TranslateService) { }

  ngOnInit() {
    let set = new ElectroSettings();
    set = this.route.snapshot.data['setting'];
    if (set) {
      this.saveData = set;
    }
  }

  /**
   * insert standard event.
   */
  insertStandards() {
    this.saveData.DeviationComment = this.translate.instant('DEVIATION_REPORT_STANDARD_TEXT');
  }

  /**
   * save settings data.
   */
  save() {
    this.saveData.BusinessId = BaseServices.BusinessId;
    this.saveData.ApplicationId = BaseServices.ApplicationId;
    this.saveData.DeviationComment;
    this.saveData.CreatedBy = BaseServices.UserId;
    this.subscriptions.push(this.electroServices.saveInspectionReportSettings(this.saveData).subscribe(result =>{
      if(result){
        this.toasterComponent.callToast();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
