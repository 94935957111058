
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { HelpSupportCategory } from '../../../_models/feature';
import { IMyDpOptions } from 'mydatepicker';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from '../../../_services/helper.service';
import { HelpSupport } from '../models/helpsupport';
import { HelpSupportServices } from '../services/help-support.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { DateSetting } from '../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core'

import { Feature } from 'src/app/_models';
import { Subscription } from 'rxjs';
@Component({
    selector: 'CreateHelpSupport',
    templateUrl: 'Createhelp-support.component.html'
})
export class CreateHelpSupportComponent extends DateSetting implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    helpsupportForm: FormGroup;
    IsHidden = true;
    IsEmail = false;
    IsVideo = false;
    IsStory = false;
    newsobj: any;
    helpsupportId: number;
    categoryId: number;
    helpsupportData = new HelpSupport();
    createdOn: string;
    locale: string;
    isAdmin: boolean;
    IsCategoryName = false;
    categoryName: any;
    moduleName: any;
    /**
     * Date picker configuration option
     */
    public override defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '26px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
    };
    /**
     * Date picker configuration option
     */
    public publishedDateOpitons: IMyDpOptions = this.defaultDateOptions;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region constructor

    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param newsServices {NewsServices}
     * @param activatedRoute {ActivatedRoute}
     */
    constructor(
        private formBuilder: FormBuilder,
        public helpsupportServices: HelpSupportServices,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        protected location: Location,
        private translate: TranslateService
    ) {
        super();
    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.helpsupportForm = this.formBuilder.group({
            CategoryName: ['', Validators.required],
            PublishedOn: [''],
            Title: [''],
            Content: [''],
            ContentLink: [''],
            EmailId: [''],
            ModuleName: [''],
        });
        this.subscriptions.push(this.translate.stream('CATEGORY_NAMES').subscribe(val => {
            this.categoryName = [];
            this.categoryName.push(
                { label: val.SELECT, value: null },
                { label: val.STORY, value: 1 },
                { label: val.ARTICLES, value: 2 },
                { label: val.VIDEOS, value: 3 },
                { label: val.QUESTIONSANDANSWERS, value: 4 },
                { label: val.EMAILID, value: 5 }
            );
        }));
        this.subscriptions.push(this.translate.stream('MODULE_NAMES').subscribe(val => {
            this.moduleName = [];
            this.moduleName.push(
                { label: val.SELECT, value: null },
                { label: val.ABSENCE, value: 1 },
                { label: val.LEAVE, value: 2 },
                { label: val.CLIENT, value: 3 },
                { label: val.CONTACT, value: 4 },
                { label: val.SUBCONTRACTOR, value: 5 },
                { label: val.FOODSAFETY, value: 6 },
                { label: val.FDV, value: 7 },
                { label: val.PROJECT, value: 8 },
                { label: val.FOLLOWUP, value: 9 },
                { label: val.IKCLIENT, value: 10 },
                { label: val.FRAMEWORKAGREEMENT, value: 11 },
                { label: val.SERVICEAGREEMENT, value: 12 },
                { label: val.KUNDEEL, value: 13 }
            );
        }));
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let nid = params['id'];
            if (this.router.url.indexOf('admin') > 0) {
                if (+BaseServices.roleId === 3) {
                    this.isAdmin = false;
                } else {
                    this.isAdmin = true;
                }
            } else {
                this.isAdmin = true;
            }
            this.helpsupportId = +nid;
            if (this.helpsupportId !== 0) {
                this.subscriptions.push(this.helpsupportServices.get(this.helpsupportId).subscribe(newsInfo => {
                    if (newsInfo) {
                        this.IsEmail = newsInfo.CategoryId == HelpSupportCategory.Email ? true : false;
                        this.IsVideo = newsInfo.CategoryId == HelpSupportCategory.VIDEOS ? true : false;

                        if (newsInfo.CategoryId === HelpSupportCategory.Email ||
                            newsInfo.CategoryId === HelpSupportCategory.STORY) {
                            this.IsStory = false;
                        }
                        else {
                            this.IsStory = true;
                        }
                        this.IsCategoryName = true;
                        this.createdOn = newsInfo.CreatedOn;
                        this.IsHidden = newsInfo.PublishedOn ? false : true;
                        this.helpsupportData = newsInfo;
                        this.newsobj = {
                            CategoryName: newsInfo.CategoryId,
                            Title: newsInfo.Title,
                            Content: newsInfo.Content,
                            EmailId: newsInfo.Content,
                            ContentLink: newsInfo.ContentLink,
                            PublishedOn: (newsInfo.PublishedOn) ? HelperService.formatInputDate(newsInfo.PublishedOn) : null,
                            ModuleName: newsInfo.ModuleName
                        };
                        (<FormGroup>this.helpsupportForm).setValue(this.newsobj, {
                            onlySelf: true
                        });

                    }
                }));
            }
        }));
    }

    //#endregion

    //#region control-event

    /**
    * back to the list
    */
    gotoList() {
        this.location.back();
    }
    /**
     * save news
     */
    Save() {
        let edithelpsupportId = this.helpsupportId;
        if (this.helpsupportForm.value.CategoryName == HelpSupportCategory.Email || this.helpsupportForm.value.CategoryName == HelpSupportCategory.STORY) {
            if (this.helpsupportForm.value.CategoryName == HelpSupportCategory.Email) {
                this.helpsupportData.Title = "EMAIL";
                this.helpsupportData.Content = this.helpsupportForm.value.EmailId;
                this.helpsupportData.PublishedOn = HelperService.formatDate(new Date());
                this.helpsupportData.ModuleName = null!;
            }
            else {
                this.helpsupportData.Title = "STORY";
                this.helpsupportData.Content = this.helpsupportForm.value.Content;
                this.helpsupportData.PublishedOn = HelperService.formatDate(new Date());
                this.helpsupportData.ModuleName = null!;
            }
        }
        else {
            this.helpsupportData.Title = this.helpsupportForm.value.Title;
            this.helpsupportData.Content = this.helpsupportForm.value.Content;
            this.helpsupportData.PublishedOn = (this.helpsupportForm.value.PublishedOn) ?
                HelperService.formatDateFilter(this.helpsupportForm.value.PublishedOn.formatted) :
                null!;
            this.helpsupportData.ModuleName = this.helpsupportForm.value.ModuleName;
        }
        this.helpsupportData.CategoryId = this.helpsupportForm.value.CategoryName;
        this.helpsupportData.ContentLink = this.helpsupportForm.value.CategoryName == HelpSupportCategory.VIDEOS
            ? this.helpsupportForm.value.ContentLink.replace("watch?v=", "embed/") : "";
        this.helpsupportData.BusinessId = BaseServices.BusinessId;
        this.helpsupportData.ApplicationId = BaseServices.ApplicationId;
        this.helpsupportData.CreatedOn = this.createdOn;
        if (edithelpsupportId > 0) {
            this.subscriptions.push(this.helpsupportServices
                .update(edithelpsupportId, this.helpsupportData)
                .subscribe(isNewsUpdated => {
                    if (isNewsUpdated) {
                        this.toasterComponent.callToast();
                    }
                }));
        } else {
            this.helpsupportData.CreatedOn = HelperService.formatDate(new Date());
            this.helpsupportData.Id = 0;
            this.helpsupportData.Status = '1';
            this.subscriptions.push(this.helpsupportServices.add(this.helpsupportData).subscribe(isNewsAdded => {
                if (isNewsAdded) {
                    this.toasterComponent.callToast();
                }
            }));
        }
        this.gotoList();
    }

    reset() {
        this.helpsupportId = 0;
        this.helpsupportForm.reset();
    }

    resetform() {
        this.helpsupportId = 0;
        this.helpsupportForm.controls['PublishedOn'].setValue(null);
        this.helpsupportForm.controls['Title'].setValue(null);
        this.helpsupportForm.controls['Content'].setValue(null);
        this.helpsupportForm.controls['ContentLink'].setValue("");
        this.helpsupportForm.controls['EmailId'].setValue(null);
        this.helpsupportForm.controls['ModuleName'].setValue(null);
    }
    /**
     * save and publish
     */
    SaveAndPublish() {
        let edithelpsupportId = this.helpsupportId;
        this.helpsupportData.CategoryId = this.helpsupportForm.value.CategoryName;
        if (this.helpsupportForm.value.CategoryName == HelpSupportCategory.Email) {
            this.helpsupportData.Title = "Email Address";
            this.helpsupportData.Content = this.helpsupportForm.value.EmailId;
            this.helpsupportData.ModuleName = null!;
        }
        else {
            this.helpsupportData.Title = this.helpsupportForm.value.Title;
            this.helpsupportData.Content = this.helpsupportForm.value.Content;
            this.helpsupportData.ModuleName = this.helpsupportForm.value.ModuleName;
        }
        this.helpsupportData.Content = this.helpsupportForm.value.Content;
        this.helpsupportData.ContentLink = this.helpsupportForm.value.ContentLink.replace("watch?v=", "embed/");
        this.helpsupportData.CreatedOn = this.createdOn;
        this.helpsupportData.PublishedOn = this.helpsupportForm.value.PublishedOn
            ? HelperService.formatDateFilter(this.helpsupportForm.value.PublishedOn.formatted)
            : HelperService.formatDate(new Date());
        this.helpsupportData.ApplicationId = BaseServices.ApplicationId;
        this.helpsupportData.BusinessId = BaseServices.BusinessId;
        if (edithelpsupportId > 0) {
            this.subscriptions.push(this.helpsupportServices
                .update(edithelpsupportId, this.helpsupportData)
                .subscribe(isNewsUpdated => {
                    if (isNewsUpdated) {
                        this.toasterComponent.callToast();
                    }
                }));
        } else {
            this.helpsupportData.Id = 0;
            this.helpsupportData.Status = '1';
            this.helpsupportData.CreatedOn = HelperService.formatDate(new Date());
            this.subscriptions.push(this.helpsupportServices.add(this.helpsupportData).subscribe(isNewsAdded => {
                if (isNewsAdded) {
                    this.toasterComponent.callToast();
                }
            }));
        }
        this.gotoList();
    }

    //#endregion

    //#region method

    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() + 1
        };
    }

    onCatrgoryChanged(event:any) {
        if (event.value == HelpSupportCategory.Email || event.value == HelpSupportCategory.STORY) {
            this.categoryId = event.value;
            this.resetform();
            this.IsEmail = event.value == HelpSupportCategory.Email ? true : false;
            this.IsVideo = false;
            this.IsStory = false;
            this.subscriptions.push(this.helpsupportServices.getByCategoryId(this.categoryId).subscribe((newsInfo:any) => {
                if (newsInfo) {
                    this.createdOn = newsInfo.CreatedOn;
                    this.IsHidden = newsInfo.PublishedOn ? false : true;
                    this.helpsupportId = newsInfo.Id;
                    this.helpsupportData = newsInfo;
                    this.newsobj = {
                        CategoryName: newsInfo.CategoryId,
                        Title: newsInfo.Title,
                        Content: newsInfo.Content,
                        EmailId: newsInfo.Content,
                        ContentLink: newsInfo.ContentLink,
                        PublishedOn: (newsInfo.PublishedOn) ? HelperService.formatInputDate(newsInfo.PublishedOn) : null,
                        ModuleName:newsInfo.ModuleName
                    };
                    (<FormGroup>this.helpsupportForm).setValue(this.newsobj, {
                        onlySelf: true
                    });

                }
            }));
        }
        else if (event.value == HelpSupportCategory.VIDEOS) {
            this.IsVideo = true;
            this.IsStory = true;
            this.IsEmail = false;
            this.resetform();
        }
        else {
            this.IsStory = true;
            this.IsVideo = false;
            this.IsEmail = false;
            this.resetform();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
