import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { ToasterComponent } from 'src/app/_directives/toaster.component';

import { HelperService, PdfSetting } from './../../../../_services/helper.service';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Options } from './../../../../_models/options';
import { BaseServices } from './../../../kuba.services';
import { MeasureProtocolService } from '../../services/measure-protocol.services';
import { InstrumentType, ModelType, Instrument } from '../../models/instrument';
import { DateSetting } from './../../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'measure-protocol-instrument-form',
    templateUrl: 'measure-protocol-instrument-form.component.html'
})

export class MeasureProtocolInstrumentFormComponent extends DateSetting implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolInstrumentForm: FormGroup;
    instrumentTypeForm: FormGroup;
    instrumentModelForm: FormGroup;
    isEditMode = false;
    viewType = false;
    types: SelectItem[];
    editTypes: any = [];
    models: SelectItem[];
    editModels: any = [];
    kundeELId: number;
    parentKey: any;
    addType = false;
    addModel = false;
    instrumentTypeId: number;
    instrumentModelId: number;
    mpId: number;
    instneweditId: number;

    InstrumentType: InstrumentType[];
    locale: string;
    createdOn: Date;
    createdBy: number;
    createdName: number;
    private subscriptions: Subscription[] = [];

    /**
   * Date picker configuration option
   */
    public expiryDateOpitons: IMyDpOptions = this.defaultDateOptions;
    //#endregion

    //#region constructors
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        private confirmationService: ConfirmationService,
        public measureprotocolservice: MeasureProtocolService,
        private translate: TranslateService) {
        super();
        this.measureProtocolInstrumentForm = this.fb.group({
            'InstrumentTypeId': [[], Validators.required],
            'InstrumentModelId': [[], Validators.required],
            'CalibratedDate': ['', Validators.required]
        });

        this.instrumentTypeForm = this.fb.group({
            'Name': ['', Validators.required],
        });

        this.instrumentModelForm = this.fb.group({
            'Name': ['', Validators.required],
        });
    }
    //#endregion

    //#region page-events
    /**
     * page load event
    */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.kundeELId = this.route.snapshot.parent.parent.parent.params['id'];
        this.parentKey = this.route.snapshot.parent.parent.parent.data['parent'];
        this.mpId = this.route.snapshot.parent.params['mid'];
        this.instneweditId = this.route.snapshot.params['id'];
        this.bindDetails();
        let res = this.route.snapshot.data['edit'];
        if (res) {
            this.isEditMode = true;
            this.initForm(res);
        } else {
            this.initForm();
        }

        this.subscriptions.push(this.measureprotocolservice.getInstrumentById(this.mpId, this.instneweditId).subscribe((mpInfo: any) => {
            if (mpInfo) {
                this.createdOn = mpInfo.CreatedOn;
                this.createdBy = mpInfo.CreatedBy;
                this.createdName = mpInfo.CreatedName;
            }
        }));
    }
    //#endregion

    //#region methods
    /**
     * bind category dropdown
     */
    bindDetails() {
        this.types = [];
        this.editTypes = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.types = [];
            this.types.push(
                { label: val.SELECT, value: null }
            );
        }));
        let result = this.route.snapshot.data['instrumentType'];
        if (result) {
            result.forEach((instType: any) => {
                this.types.push({ label: instType.Name, value: instType.Id });
                this.editTypes.push({ Name: instType.Name, Id: instType.Id });
            });
        }

        this.models = [];
        this.editModels = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.models = [];
            this.models.push(
                { label: val.SELECT, value: null }
            );
        }));
        let Modelresult = this.route.snapshot.data['modelType'];
        if (Modelresult) {
            Modelresult.forEach((modelType: any) => {
                this.models.push({ label: modelType.Name, value: modelType.Id });
                this.editModels.push({ Name: modelType.Name, Id: modelType.Id });
            });
        }

    }

    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate()
        }
    }

    clearType() {
        this.instrumentTypeId = 0;
        this.instrumentTypeForm = this.fb.group({
            Name: ['']
        })
    }

    clearModel() {
        this.instrumentModelId = 0;
        this.instrumentModelForm = this.fb.group({
            Name: ['']
        })
    }

    oninstrumentTypeFormHide() {
        this.instrumentTypeForm.reset();
    }
    oninstrumentModelFormHide() {
        this.instrumentModelForm.reset();
    }

    /**
     * view type list
     */
    viewInstrumentType() {
        this.subscriptions.push(this.measureprotocolservice.getAllByType(this.kundeELId, this.parentKey).subscribe(instType => {
            if (instType) {
                this.types = [];
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.types = [];
                    this.types.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                instType.forEach((instType: any) => {
                    this.types.push({ label: instType.Name, value: instType.Id });
                });
            }
            this.editTypes = instType;
        }));
    }

    /**
     * view model list
     */
    viewModelType() {
        this.subscriptions.push(this.measureprotocolservice.getAllByModel(this.kundeELId, this.parentKey).subscribe(modelType => {
            if (modelType) {
                this.models = [];
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.models = [];
                    this.models.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                modelType.forEach((modelType: any) => {
                    this.models.push({ label: modelType.Name, value: modelType.Id });
                });
            }
            this.editModels = modelType;
        }));
    }


    /**
     * save type of instrument
     */
    saveType() {
        let instrumentType = new InstrumentType();
        instrumentType.Id = this.instrumentTypeId ? this.instrumentTypeId : 0;
        instrumentType.Name = this.instrumentTypeForm.value.Name;
        instrumentType.KundeELId = this.kundeELId;
        instrumentType.Status = '1';
        instrumentType.CreatedOn = new Date();
        instrumentType.CreatedBy = BaseServices.UserId;

        if (instrumentType.Id > 0) {
            instrumentType.ModifiedOn = new Date();
            instrumentType.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice.updateInsType(instrumentType, this.parentKey, this.kundeELId).subscribe((instrumentEdited: any) => {
                if (instrumentEdited) {
                    this.viewInstrumentType();
                    this.toasterComponent.callToast();
                    this.instrumentTypeForm.get('InstrumentTypeId').patchValue(instrumentEdited.Id)
                }
            }));
        } else {
            this.subscriptions.push(this.measureprotocolservice.createInsType(instrumentType, this.parentKey, this.kundeELId).subscribe((instrumentAdded: any) => {
                if (instrumentAdded) {
                    this.viewInstrumentType();
                    this.toasterComponent.callToast();
                    this.instrumentTypeForm.get('InstrumentTypeId').patchValue(instrumentAdded.Id)
                }
            }));
        }
        this.clearType();
    }

    saveModel() {
        let modelType = new ModelType();
        modelType.Id = this.instrumentModelId ? this.instrumentModelId : 0;
        modelType.Name = this.instrumentModelForm.value.Name;
        modelType.KundeELId = this.kundeELId;
        modelType.Status = '1';
        modelType.CreatedBy = BaseServices.UserId;
        modelType.CreatedOn = new Date();

        if (modelType.Id > 0) {
            modelType.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice.updateModel(modelType, this.parentKey, this.kundeELId).subscribe((modelEdited: any) => {
                if (modelEdited) {
                    this.viewModelType();
                    this.toasterComponent.callToast();
                    this.instrumentModelForm.get('InstrumentModelId').patchValue(modelEdited.Id)
                }
            }));
        } else {
            this.subscriptions.push(this.measureprotocolservice.createModel(modelType, this.parentKey, this.kundeELId).subscribe((modelAdded: any) => {
                if (modelAdded) {
                    this.viewModelType();
                    this.toasterComponent.callToast();
                    this.instrumentModelForm.get('InstrumentModelId').patchValue(modelAdded.Id)
                }
            }));
        }
        this.clearModel();
    }

    /**
    * edit type of registration
    */
    editType(instrumentType: InstrumentType) {
        this.instrumentTypeId = instrumentType.Id;
        let result = {
            Name: instrumentType.Name
        };
        (<FormGroup>this.instrumentTypeForm)
            .setValue(result, { onlySelf: true });
    }

    /**
    * edit model of registration
    */
    editModel(modelType: ModelType) {
        this.instrumentModelId = modelType.Id;
        let result = {
            Name: modelType.Name
        };
        (<FormGroup>this.instrumentModelForm)
            .setValue(result, { onlySelf: true });
    }

    /**
     * delete instrument
     * @param instrumentType
     */
    deleteType(instrumentType: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                let deleteTypeid = instrumentType.Id;
                this.subscriptions.push(this.measureprotocolservice.deleteInsType(deleteTypeid, this.kundeELId, this.parentKey).subscribe(() => {
                    this.viewInstrumentType();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    /**
     * delete instrument
     * @param instrumentModel
     */
    deleteModel(instrumentModel: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                let deleteModelid = instrumentModel.Id;
                this.subscriptions.push(this.measureprotocolservice.deleteModelType(deleteModelid, this.kundeELId, this.parentKey).subscribe(() => {
                    this.viewModelType();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }


    onClientChanged(event, typeDropdown) {
        this.getTypeById(event.value);
    }

    getTypeById(TypeId: number) {
        if (!TypeId) {
            TypeId = 0;
        }
        this.subscriptions.push(this.measureprotocolservice.getAllByType(this.kundeELId, this.parentKey)
            .subscribe(result => {
                this.InstrumentType = result;
            }))
    }

    saveChanges() {
        let instrument = new Instrument();
        instrument.Id = (this.instneweditId) ? this.instneweditId : 0;
        instrument.InstrumentTypeId = this.measureProtocolInstrumentForm.value.InstrumentTypeId;
        instrument.InstrumentModelId = this.measureProtocolInstrumentForm.value.InstrumentModelId;
        instrument.CalibratedDate = HelperService.formatDate(this.measureProtocolInstrumentForm.value.CalibratedDate.formatted);
        instrument.MeasureProtocolId = this.mpId;
        instrument.MeasureProtocolCompleteId = 0;
        instrument.Status = "1";

        if (instrument.Id > 0) {
            instrument.CreatedBy = this.createdBy;
            instrument.CreatedOn = this.createdOn;
            instrument.CreatedName = this.createdName;
            instrument.ModifiedBy = BaseServices.UserId;
            instrument.ModifiedName = BaseServices.UserId;
            instrument.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .updateInstrument(instrument)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }));
        } else {
            instrument.CreatedBy = BaseServices.UserId;
            instrument.CreatedOn = new Date();
            instrument.CreatedName = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice
                .createMPInstrument(instrument)
                .subscribe((result) => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                    this.gotoBack();
                }));
        }
    }

    initForm(data?: any) {
        let InstrumentTypeId = '';
        let InstrumentModelId = '';
        let CalibratedDate = '';

        if (data) {
            InstrumentTypeId = data.InstrumentTypeId;
            InstrumentModelId = data.InstrumentModelId;
            CalibratedDate = data.CalibratedDate;

            this.measureProtocolInstrumentForm = this.fb.group({
                InstrumentTypeId: new FormControl(InstrumentTypeId, Validators.required),
                InstrumentModelId: new FormControl(InstrumentModelId, Validators.required),
                CalibratedDate: new FormControl(HelperService.formatInputDate(CalibratedDate)),
            });
        }
    }

    gotoBack() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}

