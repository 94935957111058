import { AutoWizUser } from './autowizuser';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VehicleServices } from './../../vehicle/services/vehicle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: 'autowiz-login.component.html'
})

export class AutoWizLoginComponent implements OnInit {
    AutoWizLoginform: FormGroup;
    private subscription: Subscription;
    constructor(private route: ActivatedRoute,
        private vehicleServices: VehicleServices,
        private formBuilder: FormBuilder,
        public router: Router) {
        this.AutoWizLoginform = this.formBuilder.group({
            UserName: ['', Validators.required],
            Password: ['', Validators.required],
        });
    }
    ngOnInit() {

    }
    checkAutoWizUser() {
        let autoWizUser = new AutoWizUser();
        autoWizUser.UserName = this.AutoWizLoginform.value.UserName;
        autoWizUser.Password = this.AutoWizLoginform.value.Password;
        autoWizUser.UserId = BaseServices.UserId.toString();
        this.subscription = this.vehicleServices.checkAutowizuser(autoWizUser).subscribe(result => {
            if (result) {
                setTimeout(() => {
                    this.router.navigate(['./../vehicle/list'], { relativeTo: this.route });
                }, 1000);
            } else {
               alert('Invalid Email Id Or Password');
            }
        })
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}