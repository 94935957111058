import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ElectroControlScope, ElectroDistributionOverview, ElectroDistributionFloor, ElectroInstallationBuliding } from '../../models/electroinstallation';
import { Electro } from '../../models/electrocontract';
import { SelectItem } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'distribution-overview',
    templateUrl: 'distribution-overview.component.html'
})

export class DistributionOverviewComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Input()distributionOverview: ElectroDistributionOverview;
    editMode = false;
    electro: Electro;
    distributionItems: any[];
    editorName: string;
    page: string;
    createdOn: string;
    modifiedOn: string;
    riskGroup: SelectItem[];
    contractId: number;
    isGuestHideButton = true;
    buildingDropdown: SelectItem[];
    inspectionId: number;
    formData: any;
    private subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute,
        public router: Router,
        private translate: TranslateService,
        private location: Location,
        private electroInstallationService: ElectroInstallationService) {
        this.formData = JSON.parse(sessionStorage.getItem('formData'));
        sessionStorage.removeItem('formData');
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
    }
    ngOnInit() {
        this.inspectionId = +this.route.snapshot.parent.params['Iid'] ?
            +this.route.snapshot.parent.params['Iid'] : +this.route.snapshot.params['Iid'];
        this.contractId = this.route.snapshot.parent.params['Id'];
        this.electro = this.route.snapshot.data['contractInfo'];
        this.distributionItems = [
            {
                'OrderId': 1,
                'Id': 0,
                'Status': 1,
                'DistributionId': "",
                'RoomNo': "",
                'Floor': "",
                'MeasureNumber': "",
                'DistributionType': ""
            }];
        this.initForm(this.distributionOverview);
    }

    /**
     * initialize form group and supply values to control on edit.
     * @param distributionOverview 
     */
    initForm(distributionOverview?: ElectroDistributionOverview) {
        if (distributionOverview) {
            this.distributionOverview = distributionOverview;
            this.distributionOverview.Version = distributionOverview.Version ? distributionOverview.Version : 1;
            this.editorName = distributionOverview.EditorName ? distributionOverview.EditorName : '';
            if (this.distributionOverview.CreatedOn) {
                this.createdOn = this.distributionOverview.CreatedOn;
                let cDateObj = new Date(this.distributionOverview.CreatedOn);
                this.distributionOverview.CreatedOn = cDateObj.toLocaleDateString('en-GB');
            }
            if (this.distributionOverview.ModifiedOn) {
                this.modifiedOn = this.distributionOverview.ModifiedOn;
                let mDateObj = new Date(this.distributionOverview.ModifiedOn);
                this.distributionOverview.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
            }
            if (this.distributionOverview.ElectroDistributionFloor) {
                this.distributionItems = [];
                this.distributionOverview.ElectroDistributionFloor.forEach(i => {
                    let distributions: any = [];
                    distributions.OrderId = i.OrderId;
                    distributions.Id = i.Id;
                    distributions.Status = i.Status;
                    distributions.DistributionId = i.DistributionId;
                    distributions.RoomNo = i.RoomNo;
                    distributions.Floor = i.Floor;
                    distributions.MeasureNumber = i.MeasureNumber;
                    distributions.DistributionType = i.DistributionType;
                    this.distributionItems.push(distributions);
                });
            }
        }
        else {
            this.distributionOverview = new ElectroDistributionOverview();
            if (this.inspectionId && this.formData) {
                this.distributionOverview.Version = this.formData.Version;
            }
            else {
                this.distributionOverview.Version = 1;
            }
        }
        this.distributionOverview.DocumentNumber = "IKK-040503";
        this.page = "page 3 of 7";
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    /** add new distribution item to the distribution overview table.
     * @param item 
     */
    addItem(item) {
        //splice items after the current item in distribution overview table.
        let itemsToShift = this.distributionItems.splice(this.distributionItems.indexOf(item) + 1, this.distributionItems.length);
        let newItem = {
            'OrderId': item.OrderId + 1,
            'Id': 0,
            'Status': 1,
            'DistributionId': "",
            'RoomNo': "",
            'Floor': "",
            'MeasureNumber': "",
            'DistributionType': ""
        };
        //push new item to distribution overview table.
        this.distributionItems.push(newItem);
        //push the spliced items after new item.
        itemsToShift.forEach(i => {
            ++i.OrderId;
            this.distributionItems.push(i);
        });
    }

    /** delete the item in distribution overview table.
     * @param item 
     */
    deleteItem(item) {
        this.distributionItems.forEach(i => {
            if (i.Id === item.Id && i.OrderId === item.OrderId) {
                i.Status = 0;
            }
        });
    }

    /**
    * set type for the specified distribution type.
    * @param type 
    */
    currentTypeChecked(item, type) {
        this.distributionItems.forEach(i => {
            if (i.OrderId === item.OrderId) {
                i.DistributionType = type;
            }
        });
    }

    /**
     * save distribution.
     */
    saveDistributions() {
        this.distributionOverview.BusinessId = BaseServices.BusinessId;
        this.distributionOverview.ElectroDistributionFloor = [];
        if (this.distributionItems) {
            this.distributionItems.forEach(i => {
                let item = new ElectroDistributionFloor();
                item.Id = i.Id;
                item.OrderId = i.OrderId;
                item.DistributionId = i.DistributionId;
                item.RoomNo = i.RoomNo;
                item.Floor = i.Floor;
                item.MeasureNumber = i.MeasureNumber;
                item.DistributionType = i.DistributionType;
                item.ElectroContractId = this.contractId;
                item.Status = i.Status;
                this.distributionOverview.ElectroDistributionFloor.push(item);
            });
        }
        if (this.distributionOverview.Id > 0) {
            this.distributionOverview.CreatedOn = this.createdOn;
            this.distributionOverview.ModifiedOn = this.modifiedOn;
            this.distributionOverview.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.updateDistributions(this.distributionOverview).subscribe(result => {
                if (result) {
                    if (result.ElectroDistributionFloor) {
                        this.distributionItems = [];
                        result.ElectroDistributionFloor.forEach(i => {
                            let distributions: any = [];
                            distributions.OrderId = i.OrderId;
                            distributions.Id = i.Id;
                            distributions.Status = i.Status;
                            distributions.DistributionId = i.DistributionId;
                            distributions.RoomNo = i.RoomNo;
                            distributions.Floor = i.Floor;
                            distributions.MeasureNumber = i.MeasureNumber;
                            distributions.DistributionType = i.DistributionType;
                            this.distributionItems.push(distributions);
                        });
                    }
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            this.distributionOverview.InspectionId = this.inspectionId;
            this.distributionOverview.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.saveDistributions(this.distributionOverview).subscribe(result => {
                if (result) {
                    this.distributionOverview.Id = result.Id;
                    if (result.ElectroDistributionFloor) {
                        this.distributionItems = [];
                        result.ElectroDistributionFloor.forEach(i => {
                            let distributions: any = [];
                            distributions.OrderId = i.OrderId;
                            distributions.Id = i.Id;
                            distributions.Status = i.Status;
                            distributions.DistributionId = i.DistributionId;
                            distributions.RoomNo = i.RoomNo;
                            distributions.Floor = i.Floor;
                            distributions.MeasureNumber = i.MeasureNumber;
                            distributions.DistributionType = i.DistributionType;
                            this.distributionItems.push(distributions);
                        });
                    }
                    this.createdOn = result.CreatedOn;
                    this.toasterComponent.callToast();
                }
            }));
        }
    }
    /**
     * save distribution and back to installation forms tab
     */
    saveBackToList() {
        this.saveDistributions()
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    hideDelete(){
        let activeDist = this.distributionItems.map(x=>x.Status != 0);
        return activeDist.filter(x=> x == true).length > 1;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}