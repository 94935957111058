import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ProjectServices } from '../services/project.service';
import { ProjectList } from '../models/projectlist';
import { Rights } from 'src/app/_models';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

require('jspdf-autotable');
@Component({
  moduleId: module.id,
  selector: 'project-list-all',
  templateUrl: 'project-list-all.component.html',
})
export class ProjectListAllComponent implements OnInit {
  //#region letiable

  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  @ViewChild('projectsTable', { static: false }) projectsTable: Table;
  projects: ProjectList[];
  ProjectResponsibility: SelectItem[];
  isAdminProject: boolean;
  showProject = false;
  guestLogin = true;
  isHideDeleteIcon = true;
  additionalData: any;
  projectResFilter: any = null;
  statuses: SelectItem[];
  filterdata = [];
  private subscriptions: Subscription[] = [];
  // appsettings = JSON.parse(sessionStorage.getItem('appSettings'));
  // languageId = this.appsettings.Language.languageId;
  // CultureInfo ="en-GB";

  //#endregion

  //#region constructor

  /**
   * constructor
   * @param projectServices {ProjectServices}
   * @param router {Router}
   * @param route {ActivatedRoute}
   * git
   * @param confirmationService {ConfirmationService}
   */
  constructor(
    private projectServices: ProjectServices,
    public router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.additionalData = {
      fileName: 'Project List',
      header: 'Project List',
      businessId: BaseServices.BusinessId,
      cultureInfo: BaseServices.userCultureInfo(),
      columnNames: [
        { title: 'Project Number', datakey: 'Number' },
        { title: 'Project Name', dataKey: 'Title' },
        { title: 'Start Date', datakey: 'StartDate' },
        { title: 'End Date', datakey: 'ExpectedEndDate' },
        { title: 'Project Manager', datakey: 'ProjectManagers' },
        { title: 'Status', datakey: 'Status' },
        { title: 'Created By', datakey: 'CreatorName' },
        { title: 'Edited By', datakey: 'ModifierName' },
      ],
    };

    this.subscriptions.push(
      this.translate.stream('SELECT_STATUSES').subscribe((val) => {
        this.statuses = [];
        this.statuses.push(
          { label: val.OPEN, value: 'OPEN' },
          { label: val.DONE, value: 'DONE' },
          { label: val.REJECTED, value: 'REJECTED' }
        );
      })
    );
  }

  //#endregion

  //#region page-event

  /**
   * init
   */
  // project manager name is not coming exactly
  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.ProjectResponsibility = [];
    let responsible = this.route.snapshot.data['reponsibilities'];
    this.subscriptions.push(
      this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
        this.ProjectResponsibility = [];
        this.ProjectResponsibility.push({ label: val.SELECT, value: null });

        if (responsible) {
          responsible.forEach((responsible: any) => {
            let label = this.translate.instant(responsible.Name);
            this.ProjectResponsibility.push({
              label: label,
              value: responsible.Id,
            });
          });
        }
      })
    );
    if (BaseServices.UserRole === 'Guest') {
      this.isHideDeleteIcon = false;
      this.guestLogin = false;
      this.onChooseProjectRights();
    } else if (BaseServices.UserRole === 'User') {
      this.isHideDeleteIcon = false;
      this.bindProjectList();
    } else {
      this.bindProjectList();
    }
    this.globalAllProjectFilter();
    this.projects = this.filterdata.filter(
      (x) => x.Status == 'OPEN' || x.Status == 'NEW'
    );
  }

  bindProjectList() {
    this.projects = [];
    this.filterdata = [];
    let list = <ProjectList[]>this.route.snapshot.data['List'];
    if (list) {
      list.forEach((project: any) => {
        let startDate = HelperService.formatDate(project.StartDate);
        let endDate = HelperService.formatDate(project.ExpectedEndDate);
        let status = project.Status == 'NEW' ? 'OPEN' : project.Status;
        return (
          (project.StartDate = startDate),
          (project.ExpectedEndDate = endDate),
          (project.Status = status)
        );
      });
    }
    this.projects.push(...list);
    this.filterdata.push(...list);
  }

  //#endregion

  //#region cotrol-event

  /**
   * project deletion
   * @param projects {any}
   */
  deleteProject(projects: any) {
    if (projects.CreatorName === 'Admin') {
      this.isAdminProject = true;
    } else {
      this.confirmationService.confirm({
        message: this.translate.instant('DELETE_THIS_RECORD'),
        accept: () => {
          this.subscriptions.push(
            this.projectServices
              .deleteproject(projects.Id)
              .subscribe((isProjectDeleted) => {
                if (isProjectDeleted) {
                  this.getProjectList();
                  this.toasterComponent.callToastDlt();
                }
              })
          );
        },
      });
    }
  }
  /**
   * client dropdown change event
   * @param e {any}
   * @param responsibleDropdown {any}
   */
  onResponsibleChanged(e: any, responsibleDropdown: any) {
    sessionStorage.setItem(
      'search_AllProject_ProjectRes',
      responsibleDropdown.value
    );
    if (e.value) {
      // custom filter datatable
      this.projectsTable.filter(
        responsibleDropdown.value,
        'ProjectResponsibilityId',
        'equals'
      );
    } else {
      this.projectsTable.reset();
    }
  }

  //#endregion

  //#region method

  /**
   * get project list
   */
  getProjectList() {
    this.subscriptions.push(
      this.projectServices
        .getlistByBusiness(BaseServices.BusinessId)
        .subscribe((projectList) => {
          projectList.forEach((project: any) => {
            let startDate = HelperService.formatDate(project.StartDate);
            let endDate = HelperService.formatDate(project.ExpectedEndDate);
            let status = project.Status == 'NEW' ? 'OPEN' : project.Status;
            return (
              (project.StartDate = startDate),
              (project.ExpectedEndDate = endDate),
              (project.Status = status)
            );
          });
          this.projects = [];
          this.filterdata = [];
          this.projects.push(...projectList);
          this.filterdata.push(...projectList);
          this.projects = this.filterdata.filter(
            (x) => x.Status == 'OPEN' || x.Status == 'NEW'
          );
        })
    );
  }
  /**
   * popup hide
   */
  informationHide() {
    this.isAdminProject = false;
  }

  //#endregion

  // TODO: to be implemented
  exportword() {}

  // TODO: to be implemented
  exportCSV() {}
  /**
   * Bind selected Project based on rights
   */
  onChooseProjectRights() {
    let userRightsId = Rights.CHOOSE_PROJECTFDV;
    let role = BaseServices.UserRole;
    this.showProject = BaseServices.checkUserRights(userRightsId, role);
    if (this.showProject) {
      this.subscriptions.push(
        this.projectServices
          .getProjectByUser(BaseServices.UserId, BaseServices.BusinessId)
          .subscribe((project: any) => {
            this.projects = project;
            this.filterdata = project;
          })
      );
    }
  }

  globalAllProjectFilter() {
    let projectRes = sessionStorage.getItem('search_AllProject_ProjectRes');

    if (projectRes && projectRes !== 'null') {
      this.projectResFilter = projectRes;
      this.projectsTable.filter(
        projectRes,
        'ProjectResponsibilityId',
        'equals'
      );
    }
  }

  onStatusChanged(e: any, statusDropdown: any) {
    if (e.value) {
      // custom filter datatable
      if (e.value == 'OPEN') {
        this.projects = this.filterdata.filter(
          (x) => x.Status == 'OPEN' || x.Status == 'NEW'
        );
      } else {
        this.projects = this.filterdata.filter((x) => x.Status == e.value);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
