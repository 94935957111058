import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Module } from 'src/app/kuba/follow-ups/models/deviation';
import { BaseServices } from '../kuba.services';
import { Rights } from 'src/app/_models';
@Component({
    templateUrl: 'electro.component.html'
})

export class ElectroComponent implements OnInit {
    pageTitle: string;
    enabledAdditionalFeatures: any;
    fId: number;
    isAdmin : boolean;
    isGuestHideButton = true;
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) { 
        this.fId = Module.ELECTRO;
        if (sessionStorage.getItem('workingModule') && (JSON.parse(sessionStorage.getItem('workingModule')).name === 'Electro')) {
            let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;

            if(BaseServices.UserRole == 'Admin'){
                this.isAdmin = true;
                this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => (element.checked || element.IsDefault)
                && element.navigationRoles.indexOf('A') > -1);
            }
            else this.enabledAdditionalFeatures = [];
            if (this.enabledAdditionalFeatures.length > 0) {
                this.router.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.fId }],
                    { relativeTo: this.route });
            }
        } else {
            // need to do something to add feature id on URL
        }
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }
    }

    ngOnInit() { 
        document.querySelector("body").classList.remove("opened");
    }
}