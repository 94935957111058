export class CommonChecklistList {
    Id: number;
    AppManualId: number;
    LevelId: number;
    LevelKey: number;
    AppManualFolderId: number;
    RefChecklistId: number;
    Title: string;
    IsScoreIndex: boolean;
    IsCommon: boolean;
    IsAccess: boolean;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    BusinessId: number;
    constructor() {
        this.Id = 0;
        this.IsAccess = true;
        this.Status = 1;
        this.IsCommon = false;
    }
}
export class CommonChecklistEdit extends CommonChecklistList {
    Items: CommonChecklistItem[];
}

export class CommonChecklistItem {
    Id: number;
    AppManualChecklistId: number;
    ParentItemId: number;
    Title: string;
    Position: number;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    SubItems : CommonChecklistItem[];
}

export class CommonChecklistEdit1 extends CommonChecklistList {
    Section: ParentSectionView[];
}

export class SectionProperty {
    Id: number;
    Point: number;
    SectionName: string;
    Theme: string;
}

export class ParentSectionView extends SectionProperty {
    ChildSection: ChildSectionView[];
}

export class ChildSectionView extends SectionProperty {
    IsView: boolean;
}

export class CheckListSection{
    point :string;
    text:string;
    ok:string;
    comments:string;
}