import { NgModule } from '@angular/core';
import { FoodSafetyCleaningListComponent } from './components/cleaning-list.component';
import { FoodSafetyCleaningComponent } from './foodsafety-cleaning.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { EcplChecklistViewerComponent } from '../../shared/ecpl-checklist-viewer/ecpl-checklist-viewer.component';
import { EcplChecklistViewerModule } from '../../shared/ecpl-checklist-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppSharedModule } from '../shared/appShared.module';

@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
         AppSharedModule,
        NgxPaginationModule,
        EcplChecklistViewerModule,
    ],
    declarations: [
        FoodSafetyCleaningListComponent,
        FoodSafetyCleaningComponent
    ],
    providers: [
    ],
    bootstrap: [
        FoodSafetyCleaningListComponent
    ]
})
export class FoodSafetyCleaningModule { }
