 import { CountryCodeResolver } from './../employees/services/employeeWorkExperience.resolver.service';
 import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { ContractorListByBusinessResolver, ContractorEditByResolver, ModuleListResolver} from './services/contractor.resolver';
import { AppSharedModule } from './../shared/appShared.module';
import { SubContractorServices } from './services/contractor.service';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SubContractorComponent } from './contractor.component';
import { SubContractorListComponent } from './components/sub-contractor-list.component';
import { SubContractorNewComponent } from './components/sub-contractor-new.component';
import { HttpClientModule } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        HttpClientModule,
        AppSharedModule,
        RouterModule,
        TranslateModule,
        MyDatePickerModule,
        KubaExportModule
    ],
    exports: [
        SubContractorComponent,
        SubContractorListComponent,
        SubContractorNewComponent
    ],
    declarations: [
        SubContractorComponent,
        SubContractorListComponent,
        SubContractorNewComponent
    ],
    providers: [
        SubContractorServices,
        ContractorListByBusinessResolver,
        CountryCodeResolver,
        ContractorEditByResolver,
        ModuleListResolver
    ]
})
export class SubContractorModule { }
