import { TreeView } from './../models/index';
import { Router, ActivatedRoute } from '@angular/router';

import { Component, OnInit, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'common-document',
    templateUrl: 'commonDocument.component.html'
})

export class CommonDocumentComponent implements OnInit {
    @Input('nodeData') nodeData: any;
    @Input('treeData') treeData: TreeView;
    document: any;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.document = [
            { "Id": 1, "Title": "Tulips", "DateRelease": '25/09/2014', "EditBy": 'adminik', "EditDate": '25/09/2014', "Link": 'Copy link' },
            { "Id": 2, "Title": "Document 1", "DateRelease": '25/09/2015', "EditBy": 'adminik', "EditDate": '25/09/2015', "Link": 'Copy link' },

        ];
    }

    addDocument() {
        this.router.navigate([0], { relativeTo: this.activatedRoute });
    }
}