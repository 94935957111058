export class SjaForm {
  Id: number;
  SjaTitleId: number;
  Location: string;
  IsCheckCompletedChecklist: boolean;
  Date: any;
  Participants: string;
  SjaResponsible: string;
  JobDescription: string;
  RiskAssociatedWithWork: string;
  ProtectionOrSecurity: string;
  ConclusionOrComments: string;
  ResponsibleSign: string;
  ResponsibleForExecutionSign: string;
  AreaManagerSign: string;
  OthersSign: string;
  CreatedBy: string;
  CreatedOn: Date;
  ModifiedBy: string;
  ModifiedOn: Date;
  FeatureId: number;
  FeatureKey: number;
  CheckListData: string;
  Status: boolean;
  ApplicationId: number;
  BusinessId: number;
  SjaResponsibleSignedDate: any;
  AreaManagerSignedDate: any;
  ResponsibleExecutionSignedDate: any;
  OthersSignedDate: any;
  ZoneName: string;
  LanguageId: string;
  DigitalResponsibleSign: string;
  DigitalAreaManagerSign: string;
  DigitalExecutionSign: string;
  DigitalOtherSign: string;
}

export class SjaCheckListData {
  Id: number;
  Title: string;
  Yes: boolean;
  No: boolean;
  NotApplicable: boolean;
  Comments: string;
  Respo: string;
}

export class SjaTitleForm {
  Id: number;
  value: number;
  label: string;
  SjaTitle: string;
  Status: boolean;
  ApplicationId: number;
  BusinessId: number;
  CreatedBy: number;
  CreatedOn: Date;
}
