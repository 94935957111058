import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Timetable, TypeOfWork } from '../models/timetable';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TimetableServices {
    private setparent = new Subject<any>();
    getParent$ = this.setparent.asObservable();
    private setReadOnly = new Subject<any>();
    getReadOnly$ = this.setReadOnly.asObservable();
    constructor(private http: HttpClient) { }

    getReadOnly(path: boolean) {
        this.setReadOnly.next(path);
    }
    getParent(path: boolean) {
        this.setparent.next(path);
    }
    getAllByEach(id: number, key: string) {
        if (key) {
            return this.http.get(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/timetable/list/${id}`, BaseServices.headerOption)
                .map((result:any) => result);
        } else {
            return this.getAllTimetableByBusinessId();
        }
    }
    getAllTimetableByBusinessId() {
        return this.http.get(environment.BASE_URL + '/timetables/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map(result => result);
    }

    addTimetable(timetable: Timetable, key: string) {
        return this.http.post(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/timetable`, timetable, BaseServices.headerOption)
            .map(result => result);
    }

    // Update Timetable
    updateTimetable(id: number, timetable: Timetable) {
        return this.http.put(environment.BASE_URL + '/timetable/' + id, timetable, BaseServices.headerOption)
            .map(result => result);
    }
    getAllTimetableByProjectId(projectId) {
        return this.http.get(environment.BASE_URL + '/timetables/' + projectId, BaseServices.headerOption)
            .map(result => result);
    }

    getEmpByBusinessId(id) {
        return this.http.get(environment.BASE_URL + '/employees/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }
    getProByBusinessId(id) {
        return this.http.get(environment.BASE_URL + '/project/getall/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }

    getTypeofworkbyBusinessId(id) {
        return this.http.get(environment.BASE_URL + '/typeofworks/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getTypeofworkbyProjectId(id) {
        return this.http.get(environment.BASE_URL + '/timetables/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }
    getById(id) {
        return this.http.get(environment.BASE_URL + '/timetable/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    // bind type
    getAllByType(id: number, key: string) {
        return this.http.get(environment.BASE_URL + `/typeofworks/` + id, BaseServices.headerOption)
            .map(result =>
                <TypeOfWork[]>result
            );
    }

    // update  Type
    updateType(data: TypeOfWork, key: number) {
        return this.http.put(environment.BASE_URL + `/typeofwork/` + data.Id, data, BaseServices.headerOption)
            .map(result => result);
    }

    // adding Type
    createType(newCategory: TypeOfWork, key: number) {
        return this.http.post(environment.BASE_URL + `/typeofwork`, newCategory, BaseServices.headerOption)
            .map(result => result);
    }

    // delete  Type
    deleteType(Id) {
        return this.http.delete(environment.BASE_URL + '/typeofwork/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    getType(id: number) {
        return this.http.get(environment.BASE_URL + '/typeofworks/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    getallTypeofworks() {
        return this.http.get(environment.BASE_URL + '/typeofworks/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map(result => result);
    }

    getallApprovalPerson() {
        return this.http.get(environment.BASE_URL + '/users', BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }
    getEmployeeContactUser() {
        return this.http.get(environment.BASE_URL + '/employeecontactusers/' + BaseServices.BusinessId, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }
    getTypeofworksById(id) {
        return this.http.get(environment.BASE_URL + '/typeofwork/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }
    DeleteTimetable(id) {
        return this.http.delete(environment.BASE_URL + '/timetable/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    DeleteTypeofwork(id) {
        return this.http.delete(environment.BASE_URL + '/typeofwork/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: any) => { return response });
    }

    getProjectsAndZone(businessId: number) {
        return this.http.get(environment.BASE_URL + '/projectandfoodsafetylist/' + businessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getTimeTableByTimeTableId(timeTableId: number, featureId: number, featureKey: number) {
        return this.http.get(
            environment.BASE_URL + '/timetablebytimetableid/' + timeTableId + '/' + featureId + '/' + featureKey,
            BaseServices.headerOption);
    }
    getMyPageOrigin(businessId: number,Language: string) {
        return this.http.get(environment.BASE_URL + '/myPageOrigin/' + businessId + '/' + Language, BaseServices.headerOption)
            .map(result => result);
    }
}
