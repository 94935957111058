import { BusinessPortalService } from './../services/business-portal.services';
import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';
import * as _ from 'lodash';

@Component({
    selector: 'portal-wizard',
    templateUrl: 'portal-wizard.component.html',
    styleUrls: ['portal-wizard.component.css']
})

export class PortalWizardComponent implements OnInit {

    wizard: any;
    enabled: boolean;
    isNewPortal: boolean;
    portalId: number;
    removeTab = false;

    constructor(private route: ActivatedRoute, private portalServices: BusinessPortalService, private router: Router) { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.portalId = + this.route.snapshot.params['pid'];
        this.isNewPortal = (this.portalId > 0) ? false : true;
        if (+BaseServices.roleId === 2 && BaseServices.PortalManager !== true) {
            this.removeTab = true;
        }
    }

}
