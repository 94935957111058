import {
    Component,
    OnInit,
    AfterViewChecked,
    ChangeDetectorRef,
    TemplateRef
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { UploaderConfig } from '../../shared/ecpl-document-explorer/uploader-config';
import { ArchiveServices } from './archive.services';
import { BaseServices, KubaServices } from '../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { ManualService } from '../manuals/services/manual.services';
import { Subscription } from 'rxjs';

class ArchiveDocumentModel {
    Id: number;
    Title: string;
    OriginalFileName: string;
    Path: string;
    MimeType: string;
    Size: number;
    DocumentTypeId: number;
    FeatureFolderId: number;
    FeatureId: number;
    FeatureKey: number;
    Status: string;
    CreatedBy: number;
    ModifiedBy: number;
}

@Component({
    templateUrl: 'archive.component.html'
})
export class ArchiveComponent implements OnInit {
    modalRef: BsModalRef;
    bsConfig = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };
    applicationId: number;
    featureId: number;
    featureName = '';
    featureKeyName: string;
    featureKey: number;
    triggerUploader = false;
    showSavingLoader = false;
    treeNode: any;
    editMode = false;
    treeActiveNode: any;

    // Document Explorer Configs
    additionalData: any;
    upConfig: UploaderConfig;
    uploadedFiles: any;
    UploadableFile: any;

    featuredocumentId: number;
    additionalFeatures: any;
    pageTitle: string;
    locale: string;
    downloadAll = false;
    allFiles: any[];
    isShowAllFile = true;
    isHideShowAllButton = false;
    archiveUserRights: any;
    isShowButton: boolean;
    Userrights: any;
    administrateUserRights: any;
    administrateachive2: any;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private modalService: BsModalService,
        private archiveServices: ArchiveServices,
        private translationService: TranslateService,
        private kubaServices: KubaServices,
        private manualService: ManualService,
    ) {

        this.Userrights = BaseServices.UserRole;
        this.featureKey = route.snapshot.parent!.params['id'];
        this.featureKeyName = this.route.snapshot.params['manualname'];
        this.applicationId = BaseServices.ApplicationId;
        this.upConfig = {
            title: 'ALL_FILES',
            viewSwitch: false,
            editMode: this.editMode,
            windowedHeight: true,
            //  showUserMeta: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
            //  showSearchFilter: true,
            showDownloadAll: this.downloadAll,
            addtionalData: null,
            showDeleteAllButton: true
        };
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
        this.featureName = workingModule.Title;
        this.featureId = workingModule.id;
    }

    // Emitter for selected folder from Document Explorer
    setActiveNodeIntree(event: any) {
        this.treeActiveNode = event;
    }

    // Toggle Edit Mode
    toggleEditMode() {
        this.editMode = !this.editMode;
        this.upConfig.editMode = !this.upConfig.editMode;
    }

    // Events form Tree
    catchTreeEvent(event:any): void {
        this.triggerUploader = false;
        switch (event.eventName) {
            case 'CheckBoxStatus':
                this.subscriptions.push(this.archiveServices
                    .updateManualCheckboxstatus(event.node.data)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log(
                                'Checkbox status not update : ',
                                error
                            ),
                        () => console.log('Checkbox status updated')
                    ));
                // console.log('CheckBoxStatus :', event);
                break;
            case 'AddNewRootFolder':
                // console.log('add Folder @ Manual :', event);
                this.subscriptions.push(this.archiveServices.createRootFolder(event, true).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error =>
                        console.log('Manual Create folder Error : ', error),
                    () =>
                        console.log(
                            'Manual Create folder Complete',
                            // this.treeActiveNode.data
                        )
                ));
                break;
            case 'AddNewFolder':
                // console.log('add Folder @ Manual :', event);
                this.subscriptions.push(this.archiveServices.createManualFolder(event, false).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error =>
                        console.log('Manual Create folder Error : ', error),
                    () =>
                        console.log(
                            'Manual Create folder Complete',
                            // this.treeActiveNode.data
                        )
                ));
                break;

            case 'RenameFolder':
                // console.log('RenameFolder @ Manual :', event);
                this.subscriptions.push(this.archiveServices.renameManualFolder(event).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error =>
                        console.log('Manual Create folder Error : ', error),
                    () => console.log('Manual Create folder Complete')
                ));
                break;
            case 'DeleteSelectedDocuments':
                this.subscriptions.push(this.archiveServices.deleteMultipleDocument(event.item.data)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log(
                                'Manual delete folder Error : ',
                                error
                            ),
                        () => console.log('Manual delete folder Complete')
                    ))
                break;
            case 'DeleteArchiveSelectedDocuments':
                this.subscriptions.push(this.archiveServices.deleteArchiveCheckList(event.item.data)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log(
                                'Manual delete folder Error : ',
                                error
                            ),
                        () => console.log('Manual delete folder Complete')
                    ))
                break;
            case 'DeleteDefaultFile':
                if (event.item.data.documentType === 'archiveundercompletion') {
                    this.subscriptions.push(this.archiveServices
                        .deleteArchiveDocument(event.item.data.nodeId)
                        .subscribe(
                            response => {
                                this.refreshTreeNode();
                            },
                            error =>
                                console.log(
                                    'Manual delete folder Error : ',
                                    error
                                ),
                            () => console.log('Manual delete folder Complete')
                        ));
                } else if (
                    event.item.data.documentType === 'fsundercompletion'
                ) {
                    this.subscriptions.push(this.archiveServices
                        .deleteFoodSafety(event.item.data.nodeId)
                        .subscribe(
                            response => {
                                this.refreshTreeNode();
                            },
                            error =>
                                console.log(
                                    'Manual delete folder Error : ',
                                    error
                                ),
                            () => console.log('Manual delete folder Complete')
                        ));
                }

                break;
            case 'DeleteFolder':
                // console.log('DeleteFolder @ Manual :', event);

                this.subscriptions.push(this.archiveServices
                    .deleteManualFolder(event.item.data.nodeId)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log('Manual delete folder Error : ', error),
                        () => console.log('Manual delete folder Complete')
                    ));

                break;

            case 'AddFiles':
                this.triggerUploader = true;
                // console.log(
                //     'AddFiles @ Manual :',
                //     event,
                //     'triggerUploader:',
                //     this.triggerUploader
                // );
                break;
            case 'EditFile':
                // TODO:
                // console.log('RenameFile @ Manual :', event);
                break;
            case 'RenameFile':
                // console.log('RenameFile @ Manual :', event);
                this.subscriptions.push(this.archiveServices.renameDocument(event).subscribe(
                    response => {
                        this.refreshTreeNode();
                    },
                    error => console.log('Rename article error : ', error),
                    () => console.log('Rename article Complete')
                ));
                break;
            case 'DeleteFile':
                // console.log('DeleteFile @ Manual :', event);
                this.subscriptions.push(this.archiveServices
                    .deleteDocument(event.item.data.nodeId)
                    .subscribe(
                        response => {
                            this.refreshTreeNode();
                        },
                        error =>
                            console.log(
                                'Manual checklist delete Error : ',
                                error
                            ),
                        () => console.log('Manual checklist delete Complete')
                    ));
                break;
            case 'NewArticle':
                // TODO:
                // console.log('AddArticle @ Manual :', event);
                break;
            case 'SaveArticle':
                // TODO:
                // console.log('EditArticle @ Manual :', event);
                break;
            case 'RenameArticle':
                // console.log('RenameArticle @ Manual :', event);
                break;
            case 'DeleteArticle':
                // console.log('DeleteArticle @ Manual :', event);
                break;

            case 'AddChecklist':
                // TODO:
                // console.log('AddChecklist @ Manual :', event);
                break;
            case 'EditChecklist':
                // TODO:
                // console.log('EditChecklist @ Manual :', event);
                break;
            case 'RenameChecklist':
                // console.log('RenameChecklist @ Manual :', event);
                break;
            case 'DeleteChecklist':
                // console.log('DeleteChecklist @ Manual :', event);
                break;
            case 'CopyEditVersionDocument':
                // Service that copy existing file to respective place
                this.subscriptions.push(this.manualService.createEditCopyofDocument(event).subscribe(
                    (response:any) => {
                        let docPathDto = {
                            Id: event.item.id,
                            Path: response.path,
                            ModifiedBy: BaseServices.UserId
                        };
                        this.subscriptions.push(this.archiveServices
                            .CreateEditDocumentVersion(docPathDto)
                            .subscribe(
                                response => console.log('Document version created'),
                                error =>
                                    console.error('Document version  Error : ', error),
                                () => console.log('Document version  Complete')
                            ));
                    },
                    error => console.error('Document version  Error : ', error),
                    () => console.log('Document version  Complete')
                ));
                break;
            case 'moveNode':
                this.subscriptions.push(this.archiveServices.moveNode(event).subscribe(
                    response => {
                        if (response) {
                            this.refreshTreeNode();
                        }
                    },
                    (error:any) => console.log("Manual movenoxde  Error : ", error),
                    () => console.log("Manual movenode  Complete")
                ));
                break;
            case 'activate':
                if (event.node.hasChildren) {
                    this.isShowAllFile = true;
                    this.uploadedFiles = event.node.data.nodes;
                    this.upConfig.title = event.node.data.filename;
                    if (event.node.data.translateKey === 'CHECKLIST_UNDER_COMPLETION') {
                        this.downloadAll = false;
                        this.upConfig.showDownloadAll = false;
                    } else {
                        this.upConfig.showDownloadAll = true;
                        this.downloadAll = true;
                    }
                    this.upConfig.documentType = event.node.data.documentType;
                    this.upConfig.addtionalData = {
                        ApplicationId: BaseServices.ApplicationId,
                        BusinessId: BaseServices.BusinessId,
                        FolderPath:
                            'fid-' +
                            this.featureKey +
                            '-' +
                            '', //this.featureName.replace(' ', '-').toLowerCase(),
                        FolderTypeName: event.node.data.documentType,
                        FeatureId: this.featureId,
                        FeatureKey: +this.featureKey,
                        FeatureName: this.featureName,
                        ArchiveFolderId: event.node.data.nodeId,
                        userId: BaseServices.UserId
                    };

                    this.treeActiveNode = event.node;
                    // console.log(
                    //     'Additinal:',
                        // this.upConfig.addtionalData,
                        // this.treeActiveNode
                    // );
                }
                this.treeActiveNode = event.node;
                this.cdr.detectChanges();
                break;
            case 'initialized':
                console.log('initialized ');
                break;
            case 'updateData':
                // console.log('updateData', event);

                break;
            case 'loadNodeChildren':
                // console.log('loadNodeChildren', event);
                break;
            default:
                if (event.eventName !== 'toggleExpanded') {
                    if (event.node.data.documentType === 'archiveundercompletion' && event.node.data.type !== 'folder') {
                        this.router.navigate(
                            [
                                '../../complete-checklist/incomplete/' +
                                event.node.data.nodeId,
                                'activity',
                                0,
                                0
                            ],
                            { relativeTo: this.route }
                        );
                    } else if (
                        event.node.data.documentType === 'fsundercompletion' && event.node.data.type !== 'folder'
                    ) {
                        this.router.navigate(
                            [
                                '../../fs-complete-checklist/incomplete/' +
                                event.node.data.nodeId
                            ],
                            { relativeTo: this.route }
                        );
                    }
                }
                break;
        }
    }

    // emitter for save from Document Explorer
    catchExplorerEvent(event: any) {
        switch (event.eventName) {
            case 'AddNewFolder':
                // console.log(
                //     'Create folder in Manual : ',
                //     event,
                //     this.treeActiveNode
                // );
                if (!event.parent) {
                    event.parent = this.treeActiveNode.data;
                }
                this.catchTreeEvent(event);
                break;
            case 'DeleteFolder':
            case 'DeleteArticle':
            case 'DeleteChecklist':
            case 'DeleteFile':
            case 'DeleteDefaultFile':
            case 'DeleteSelectedDocuments':
            case 'DeleteArchiveSelectedDocuments':
                this.catchTreeEvent(event);
                break;
            case 'SaveArticle':
                event.activeNodeId = this.treeActiveNode.data.nodeId;
                this.catchTreeEvent(event);
                break;
            case 'NewArticle':
                event.activeNodeId = this.treeActiveNode.data.nodeId;
                this.catchTreeEvent(event);
                break;
            case 'CopyEditVersionDocument':
                event.activeNodeId = this.treeActiveNode.data.nodeId;
                this.catchTreeEvent(event);
                break;
            case 'ShowOfficeFileSaveloader':
                if (
                    parseInt(sessionStorage.getItem('isDocumentChanged')!, null!)
                ) {
                    this.showSavingLoader = true;
                    this.archiveServices.UpdateDocument(event.item.id, BaseServices.UserId).
                        subscribe(
                            response => {
                                if (response) {
                                }
                            }
                        );
                    setTimeout(() => {
                        this.refreshTreeNode();
                        this.showSavingLoader = false;
                    }, 15000);
                    sessionStorage.setItem('isDocumentChanged', '0');
                }
                break;
            default:
                let uploadedFile = Object.assign(
                    {},
                    event,
                    this.upConfig.addtionalData
                );
                this.subscriptions.push(this.archiveServices.addArchiveDocument(uploadedFile).subscribe(
                    (result: any) => {
                        this.refreshTreeNode();
                    },
                    (error: any) => {
                        console.error('Archive document service error:', error);
                    },
                    () => {
                        console.log(
                            'Adding archive document service complete.'
                        );
                    }
                ));
                break;
        }
    }

    refreshTreeNode() {
        this.subscriptions.push(this.archiveServices.getArchiveTree(BaseServices.BusinessId).subscribe(
            (result: any) => {
                this.treeNode = result; // .nodes[0];
                let flattenned = this.flattenArrObj(this.treeNode);
                const parent = _.filter(flattenned, [
                    'id',
                    this.treeActiveNode.data.id
                ]);
                this.uploadedFiles = parent[0].nodes;
                this.upConfig.title = parent[0].filename;
            },
            (error: any) => {
                console.error('Api Error while refreshing tree:', error);
            },
            () => {
                console.log('Refreshing tree service complete.');
            }
        ));
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.bsConfig);
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.treeNode = this.route.snapshot.data['treeData'];

            this.treeNode[0].nodes.forEach(x => {
                if(x.originalFileName == 'Name of business') {
                    x.originalFileName = BaseServices.BusinessName;
                    x.filename = BaseServices.BusinessName;
                    x.translateKey = null;
                    if(BaseServices.BusinessName == null){
                        let BusinessDetails = JSON.parse(localStorage.getItem("BusinessDetails"));
                        x.originalFileName = BusinessDetails.CompanyName;
                        x.translateKey = null;
                        x.filename = BusinessDetails.CompanyName;
                    }
                }
            });
        
        if (this.Userrights == "User") {
            this.archiveUserRights = BaseServices.userSettings.userFeature.UserRights;
            this.administrateUserRights = JSON.parse(this.archiveUserRights);
            this.administrateUserRights.forEach((element:any) => {
                if (element.name == 'ADMINISTRATE_ARCHIVE' && element.isChecked == true) {
                    this.isShowButton = true;
                }
            });
        }
        else{
            this.isShowButton = true;
        }
    }

    // private
    private flattenArrObj(arr:any) {
        let newArr:any = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    showAllFiles() {
        this.isShowAllFile = false;
        if (this.treeActiveNode == undefined) {
            this.allFiles = this.flattenArrObj(this.treeNode[0].nodes);
        }
        else {
            this.allFiles = this.flattenArrObj(this.treeActiveNode.data.nodes);
        }
        this.uploadedFiles = this.allFiles.filter(x => x.isFolder === false);
    }

    hideAllFiles() {

        this.isShowAllFile = true;
        this.uploadedFiles = '';
    }
}
