import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'framework-new',
    templateUrl: 'framework-new.component.html'
})

export class FrameworkNewComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
}
