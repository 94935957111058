<div class="tab-content">
    <div class="main-content">
        <div class="main-heading">
            <strong>
                <span translate>MOVE_BUSINESS</span>
            </strong>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>
                                <span translate>FROM</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-dropdown #frm [required]="true" [options]="Users" [filter]="true"
                                  [style]="{'width':'100%'}" (onChange)="onFromUserChange(frm)"
                                  placeholder="{{'Select' | translate}}">
                                </p-dropdown>
                            </div>
                        </div>
                        <div *ngIf="isFromUserSelected">
                            <label>
                                <span translate>CHOOSE_BUSINESS</span>
                                <span class="required">*</span>
                            </label>
                            <div class="table-view">
                                <!-- Table starts -->
                                <p-table #dt [value]="businesslist" dataKey="Id" [(selection)]="selectedBusiness"
                                  [scrollable]="true" scrollHeight="200px">
                                    <!-- Header -->
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 35px;">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>{{'COMPANY' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <input pInputText type="text"
                                                  (input)="dt.filter($any($event.target)?.value, 'CompanyName', 'contains')"
                                                  placeholder="" class="p-column-filter">
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <!-- Body Content -->
                                    <ng-template pTemplate="body" let-business>
                                        <tr>
                                            <td style="width: 35px;">
                                                <p-tableCheckbox id="selectedOrders" [value]="business">
                                                </p-tableCheckbox>
                                            </td>
                                            <td><span>{{business.CompanyName}}</span></td>
                                        </tr>
                                    </ng-template>
                                    <!-- Empty message -->
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td colspan="2">
                                                {{'NO_RECORDS_FOUND'|translate}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <!-- Table ends -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>
                                <span translate>TO</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <p-dropdown #to [required]="true" [options]="SelectedUser" [filter]="true"
                                  [style]="{'width':'100%'}" (onChange)="onToUserChange(to)"
                                  placeholder="{{'Select' | translate}}"></p-dropdown>
                            </div>
                        </div>
                        <div *ngIf="tblUser && tblUser.length > 0 ">
                            <label>
                                <span translate>Assign access</span>
                                <span class="required">*</span>
                            </label>
                            <div class="table-view">
                                <!-- Table starts -->
                                <p-table #dt [value]="tblUser" dataKey="Id" [(selection)]="selectedUserList"
                                  [scrollable]="true" scrollHeight="200px">
                                    <!-- Header -->
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 35px;">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th>{{'Name' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <input pInputText type="text"
                                                  (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')"
                                                  placeholder="" class="p-column-filter">
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <!-- Body Content -->
                                    <ng-template pTemplate="body" let-business>
                                        <tr>
                                            <td style="width: 35px;">
                                                <p-checkbox (ngModelChange)="userChecked(business)"
                                                  [(ngModel)]="business.IsChecked" [disabled]="business.IsPortalManager"
                                                  [binary]="true"></p-checkbox>
                                            </td>
                                            <td><span>{{business.Name}}</span></td>
                                        </tr>
                                    </ng-template>
                                    <!-- Empty message -->
                                    <ng-template pTemplate="emptymessage" let-columns>
                                        <tr>
                                            <td colspan="2">
                                                {{'NO_RECORDS_FOUND'|translate}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <!-- Table ends -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <button type="button" class="btn btn-primary" (click)="moveBusiness()">
                        <span translate>MOVE</span>
                        <span class="icon ic-xs icon-forward"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>
<div class="panel-loader" *ngIf="loading">
</div>