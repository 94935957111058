import { Rights } from './../../../_models/feature';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ELService } from './../services/el.service';
import { ELList } from '../models/el-list.model';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { FrameworkAgreementServices } from '../../framework-agreement/services/framework.service';
import { FrameworkList } from '../../framework-agreement/models/frameworkList';
import { Subscription } from 'rxjs';

@Component({
    selector: 'el-list',
    templateUrl: 'el-list.component.html'
})

export class ELListComponent implements OnInit {

    //#region variables
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @ViewChild('elTable', { static: false }) elTable: Table;
    pdf: any;
    isHideDeleteIcon = true;
    hideEditIcon = true;
    isAccess = true;
    additionalData: any;
    ellist: ELList[];
    statuses: SelectItem[];
    frameworklist: FrameworkList[];
    private subscriptions: Subscription[] = [];
    //#endregion

    constructor(
        private elService: ELService,
        public router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
        private frameServices: FrameworkAgreementServices,
    ) {
        this.additionalData = {
            fileName: 'IK-Kunde EL contract list',
            header: 'IK-Kunde EL contract list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Contract Number', dataKey: 'ContractNumber' },
                { title: 'Contract Name', dataKey: 'ContractName' },
                { title: 'Client', dataKey: 'Client' },
                { title: 'Status', dataKey: 'Status' },
                { title: 'Created By', dataKey: 'CreatedName' },
                { title: 'Edited By', dataKey: 'ModifierName' },
            ]
        };

    }

    //#region page-events
    /**
     * page load event
     */
    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.ellist = [];
        this.bindStatusDropdown();
        let list = <ELList[]>this.route.snapshot.data['list'];
        if (BaseServices.UserRole === 'Guest' && list.length <= 0) {
            this.onKundeELRights();
        } else {
            this.ellist = list;
            if (BaseServices.UserRole === 'User') {
                this.isHideDeleteIcon = false;
                let currentUserRole = BaseServices.UserRole;
                let userRightsId = Rights.IK_KUNDE_EL_RIGHTS;
                this.isAccess = BaseServices.checkUserRights(userRightsId, currentUserRole);
                if (this.isAccess === false) {
                    this.hideEditIcon = false;
                }
            } else if (BaseServices.UserRole === 'Guest') {
                this.isHideDeleteIcon = false;
            }
            else {
                this.isHideDeleteIcon = true;
            }
        }
    }
    //#endregion


    getELList() {
        this.subscriptions.push(this.elService.getELList().subscribe(result => {
            let list = result;
            list.forEach(element => {
                let startDate = HelperService.formatDate(element.StartDate);
                let endDate = HelperService.formatDate(element.ExpectedEndDate);
                return (element.StartDate = startDate), (element.ExpectedEndDate = endDate);
            });
            this.ellist = [];
            this.ellist.push(...list);
        }));
    }

    /**
     * delete el based on id
     * @param  el
     */
    deleteEL(ellist) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.elService.deleteEL(ellist).subscribe(result => {
                    this.getELList();
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    getListByUserId() {
        this.subscriptions.push(this.elService.getELByUserId(BaseServices.UserId).subscribe((res: any) => {
            this.ellist = res;
        }));
    }

    onKundeELRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.CHOOSE_KUNDE_EL;
        this.isAccess = BaseServices.checkUserRights(userRightsId, currentUserRole);
        if (this.isAccess === true) {
            this.getListByUserId();
        }
    }

    /**
   * custom filter datatable of status
   */
    onStatusChange(e: any, statusDropdown: any) {
        if (e.value) {
            this.elTable.filter(
                +statusDropdown.value,
                'Status',
                'equals'
            );
        } else {
            this.elTable.reset();
        }
    }

    //#endregion

    //#region methods

    /**
     * bind status dropdown
     */
    bindStatusDropdown() {
        this.subscriptions.push(this.translate.stream('SELECT_STATUSES').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.NEW, value: '7' },
                { label: val.OPEN, value: '8' },
                { label: val.DONE, value: '9' },
                { label: val.REJECTED, value: '10' }
            );
        }));
    }

    /**
 * get frameworklist
 */
    getProjectList() {
        this.subscriptions.push(this.frameServices
            .getFrameworklistByBusiness()
            .subscribe(result => {
                let list = result;
                list.forEach(t => {
                    if (t.Status === '2') {
                        t.Status = 'New';
                    } else if (t.Status === '3') {
                        t.Status = 'Open';
                    } else if (t.Status === '4') {
                        t.Status = 'Done';
                    } else if (t.Status === '5') {
                        t.Status = 'Rejected';
                    } else { t.Status = 'New'; }
                });
                this.frameworklist = [];
                this.frameworklist.push(...list);
            }));
    }
    /**
     * delete framework based on id
     * @param  framework
     */
    deleteFramework(framework) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.frameServices
                    .deleteFramework(framework)
                    .subscribe(result => {
                        this.getProjectList();
                        this.toasterComponent.callToastDlt();
                    }));
            }})}
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion

}
