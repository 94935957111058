import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ElectroControlScope, ElectroInstallationBuliding, ElectroCourseOverview, ElectroFireAlarmSystem, ElectroEmergencyLightSystem, ElectroFireSprinklerSystem, ElectroDistributionOverview, ElectroRiskAssessment, ElectroDocumentAssessment, ElectroFormTemplates } from '../models/electroinstallation';
import { ElectroInstallationService } from './electro-installation.services';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';


@Injectable()
export class InstallationBuildingsResolver implements Resolve<ElectroInstallationBuliding[]>{
    constructor(private backend: ElectroInstallationService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroInstallationBuliding[]> {
        return this.backend.getContractBuildings(+route.parent.parent.params['cid']);
    }
}
@Injectable()
export class ControlScopeResolver implements Resolve<ElectroControlScope> {
    constructor(private backend: ElectroInstallationService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroControlScope> {
        return route.params['id'] > 0 ?
            this.backend.getControlScopeById(+route.params['id']) : null;
    }
}
@Injectable()
export class DocumentAssessmentResolver implements Resolve<ElectroDocumentAssessment> {
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroDocumentAssessment> {
        return route.params['id'] > 0 ? 
          this.backend.getDocumentAssessmsentById(+route.params['id']) : null;
    }
}

@Injectable()
export class CourseOverviewResolver implements Resolve<ElectroCourseOverview> {
    constructor(private backend: ElectroInstallationService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroCourseOverview> {
        return route.params['id'] > 0 ?
         this.backend.getCourseById(+route.params['id']) : null;
    }
}

@Injectable()
export class DistributionsOverviewResolver implements Resolve<ElectroDistributionOverview> {
    constructor(private backend: ElectroInstallationService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroDistributionOverview> {
        return route.params['id'] > 0 ?
         this.backend.getDistributionsById(+route.params['id']) : null;
    }
}

@Injectable()
export class EmergencyLightingResolver implements Resolve<ElectroEmergencyLightSystem> {
    constructor(private backend: ElectroInstallationService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroEmergencyLightSystem> {
        return route.params['id'] > 0 ?
         this.backend.getEmergencyLightingById(+route.params['id']) : null;
    }
}

@Injectable()
export class FireSprinklerResolver implements Resolve<ElectroFireSprinklerSystem> {
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroFireSprinklerSystem> {
        return route.params['id'] > 0 ? 
           this.backend.getFireSprinklerById(+route.params['id']) : null;
    }
}

@Injectable()
export class FireAlarmSystemResolver implements Resolve<ElectroFireAlarmSystem> {
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroFireAlarmSystem> {
         return route.params['id'] > 0 ? 
         this.backend.getFirealarmSystemById(+route.params['id']) : null;
    }
}

@Injectable()
export class RiskAssessmentResolver implements Resolve<ElectroRiskAssessment> {
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot): Observable<ElectroRiskAssessment> {
         return route.params['id'] > 0 ? 
         this.backend.getRiskAssessmentById(+route.params['id']) : null; 
    }
}

@Injectable()
export class FormsWithTemplateResolver implements Resolve<any> {
    constructor(private backend: ElectroInstallationService){}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
         return +route.parent.params['Id'] > 0 ? 
         this.backend.getEnabledFormsWithTemplate(+route.parent.params['Id']) : null; 
    }
}

@Injectable()
export class ElectroBusinessInstTypeResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService){}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
         return this.backend.getElectroBusinessInstrumentTypes(); 
    }
}

@Injectable()
export class ElectroBusinessInstModelResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService){}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
         return this.backend.getElectroBusinessInstrumentModels(); 
    }
}
@Injectable()
export class ElectroBusinessInstrumentsResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService){}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
         return this.backend.getElectroBusinessInstruments(); 
    }
}