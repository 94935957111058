export class BusinessList {
  Id: number;
  AgreementName: string;
  OrganizationNumber: string;
  CompanyName: string;
  CompanyNumber: string;
  AboutBusiness: string;
  Address: string;
  Zipcode: string;
  Telephone: string;
  Email: string;
  Categoty: string;
  Application: string;
  Status: boolean;
  Portal: string;
  CreatedBy: string;
  ModifiedBy: string;
  CreatedOn: string;
  ModifiedOn: string;
  PricePerMonth?: number;
  LicenseCategoryName: string;
  MonthName: string;
  StatusName: string;
  IsProject:string;
  IsFdv:string;
  IsInternalControl:string;
  IsFoodSafety:string;
  IsVehicle:string;
  IsActivity:string;
  IsDeviation:string;
  IsRiskAnalysis:string;
  OtherSystems:string;
  QualitySystem:string;
  Features:string;
}
export class BusinessAgreement {
  Id: number;
  SalesPerson: string;
  PurchaseDate: string;
  ExpiryDate: string;
  LicenseAggreementId: number;
  LicenseMonth: string;
  Description: string;
  DocumentPath: string;
  Status: string;
  BusinessId: number;
  ApplicationId: number;
  CreatedBy: string;
  CreatedOn: string;
  ModifiedBy: string;
  ModifiedOn: string;
  AgreementName: string;
  LicenseCategoryId?: number;
  PricePerMonth?: number;
  NoOfEmployees: number;
  NoOfUsers: number
}

export class User {
  Id: number;
  LanguageId: number;
  RoleId: number;
  ApplicationId: number;
  BusinessId: number;
  Rights: string;
  FeatureId: number;
  FeatureKey: String;
  Username: string;
  Password: string;
  Email: string;
  Name: string;
  Avatar: string;
  Sso: string;
  SsoUsername: string;
  SsoDomain: string;
  SsoLoggedIn: string;
  NotificationUpdateOn: string;
  Status: string;
}


