import { 
    EmployeeContactUserResolver, 
    EmployeeContactUserSubscriptionResolver } from './../users/services/user.resolver.service';
import { PropertyObjectListResolver } from './../businesses/services/business.resolver.service';
import { kubaFeatureDetailsRoutes } from './../shared/kuba-feature-details/Kuba-feature-details.routes';
import { FdvMyListComponent } from './component/fdvMyList.component';
import { Route } from '@angular/router';
import { AuthGuard } from './../../_guards/auth.guard';
import { CommonViewComponent } from './../common/components/commonView.component';
import {
    FdvListResolver,
    FdvEditResolver,
    FdvMyListResolver,
    ContactDropDownResolver,
    ChooseObjectDropDownResolver,
    TypeOfObjectDropDownResolver,
    BusinessPropertyListResolver,
    FdvFeaturesResolver,
    UserListResolver
} from './services/fdv.resolver.service';
import { FdvComponent } from './fdv.component';
import { FdvListComponent } from './component/fdvList.component';
import { FdvNewComponent } from './component/fdvNew.component';
import { FdvViewComponent } from './component/fdvView.component';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing'; 
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { FeatureDocumentRoutes } from './../feature-documents/feature-document.routing';
import { FdvListAllComponent } from './component/fdvListAll.component';

export const fdvRoutes: Route[] = [
    {
        path: 'fire-deviation',
        component: FdvComponent,
        children: [{
            path: 'list',
            component: FdvListAllComponent,
            canActivate: [AuthGuard],
            children: [
                { path: '', redirectTo: 'list', pathMatch: 'full' },
                {
                    path: 'list',
                    component: FdvListComponent,
                    resolve: {
                        list: FdvListResolver
                    }
                },
                {
                    path: 'myList',
                    component: FdvMyListComponent,
                    resolve: {
                        myList: FdvMyListResolver
                    }
                }
            ]
        },
        {
            path: 'edit/:id',
            component: FdvNewComponent,
            resolve: {
                edit: FdvEditResolver, 
                contact: EmployeeContactUserResolver,
                chooseObject: ChooseObjectDropDownResolver,
                typeOfObject: BusinessPropertyListResolver,
                propertyObject: PropertyObjectListResolver,
                users_subscription: EmployeeContactUserSubscriptionResolver,
            }
        },
        {
            path: 'createFdv',
            component: FdvNewComponent,
            resolve: { 
                contact: EmployeeContactUserResolver,
                chooseObject: ChooseObjectDropDownResolver,
                propertyObject: PropertyObjectListResolver,
                typeOfObject: BusinessPropertyListResolver,
                users_subscription: EmployeeContactUserSubscriptionResolver,
            }
        },
        {
            path: 'details/:id',
            component: FdvViewComponent,
            resolve: {
                additionalFeatures: FdvFeaturesResolver,
                edit: FdvEditResolver,
            },
            data: { parent: 'FDV' },
            canActivate: [AuthGuard],
            children: [
                ...kubaFeatureDetailsRoutes,
                ...FeatureDocumentRoutes,
                ...deviationRoutes,
                ...taskmanagerRoutes,
                ...timetableRoutes,
                ...safetyjobRoutes,
                ...registrationRoutes 
            ]
        }
        ]
    }
];
