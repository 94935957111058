import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { LoggedUser } from './../../../_models/';

@Component({
    templateUrl: 'vehicletaskForm.component.html'
})
export class VehicleTaskFormComponent implements OnInit {
    loggedUser: LoggedUser;
    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {document.querySelector("body").classList.remove("opened");}
}
