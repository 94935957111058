import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from '../shared';
import { TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../users/user.module';
import { NgxUploaderModule } from 'ngx-uploader';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';
import { BuinessLicenseAgreementListComponent } from './components/business-license-agreement-list';
import { LicenseAgreementComponent } from './license-agreement.component';
import { BusinessFeatureListResolver, BusinessListResolver } from './services/license-agreement.resolver';
import { LicenseAgreementServices } from './services/license-agreement.service';
import { RowStatusPipe, MonthPipe, LicenseCategoryPipe } from 'src/app/_helpers/pipes';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule(
    {
        imports: [
            CommonModule,
            ReactiveFormsModule,
            HttpClientModule,
            RouterModule,
            AppSharedModule,
            TranslateModule,
            UserModule,
            ConfirmDialogModule,
            CalendarModule,
            EditorModule,
            ContextMenuModule,
            MenuModule,
            DropdownModule,
            InputTextareaModule,
            InputTextModule,
            TranslateModule,
            RouterModule,
            ButtonModule,
            TabMenuModule,
            NgxUploaderModule,
            InputSwitchModule,
            EcplDocumentExplorerModule,
            EcplTabNavigationModule,
            KubaExportModule
        ],
        exports: [
            BuinessLicenseAgreementListComponent,
            LicenseAgreementComponent
        ],
        declarations: [
            BuinessLicenseAgreementListComponent,
            LicenseAgreementComponent
        ],
        providers: [
            LicenseAgreementServices,
            BusinessListResolver,
            RowStatusPipe,
            MonthPipe,
            LicenseCategoryPipe,
            BusinessFeatureListResolver
        ]

    }
)

export class LicenseAgreementModule { }
