export * from './employee-tabs.component';
export * from './course.component';
export * from './employee-course-list.component';
export * from './employee-course-detail.component';
export * from './employee-form.component';
export * from './employee-list.component';
export * from './firstKin.component';
export * from './employee-firstofkin-list.component';
export * from './employee-firstofkin-edit.component';
export * from './leave.component';
export * from './employee-leaveList.component';
export * from './employee-leave-edit.component';
export * from './employee-leaveallocate.component';
export * from './sickness.component';
export * from './employee-sickness-list.component';
export * from './employee-sickness-edit.component';
export * from './timetable.component';
export * from './employee-timetable-list.component';
export * from './employee-workexp-edit.component';
export * from './employee-privatedocument.component';
export * from './employee-documented-training.component';

