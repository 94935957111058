import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
@Component({
    selector: 'error-page',
    templateUrl: 'error-page.component.html'
})

export class ErrorPageComponent implements OnInit, OnDestroy {
    errorCodes: any;
    error: any;
    subcription: any;
    viewBag: any;
    assetPath: string = environment.BASE_ASSET_PATH;
    private subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute, private location: Location) {
        this.errorCodes = {
            '400': {
                'type': 'Bad Request',
                'message': 'Bad Request',
                'alert': 'warning'
            },
            '401': {
                'type': 'Unauthorized',
                'message': 'Unauthorized',
                'alert': 'warning'
            },
            '402': {
                'type': 'Payment Required',
                'message': 'Payment Required',
                'alert': 'warning'
            },
            '403': {
                'type': 'Forbidden',
                'message': 'Forbidden',
                'alert': 'warning'
            },
            '404': {
                'type': 'Page Not Found',
                'message': 'Sorry, the page were looking could not found!',
                'alert': 'warning'
            },
            '405': {
                'type': 'Method Not Allowed',
                'message': 'Method Not Allowed',
                'alert': 'warning'
            },
            '406': {
                'type': 'Not Acceptable',
                'message': 'Not Acceptable',
                'alert': 'warning'
            },
            '407': {
                'type': 'Proxy Authentication Required',
                'message': 'Proxy Authentication Required',
                'alert': 'warning'
            },
            '408': {
                'type': 'Request Timeout',
                'message': 'Request Timeout',
                'alert': 'warning'
            },
            '409': {
                'type': 'Conflict',
                'message': 'Conflict',
                'alert': 'warning'
            },
            '410': {
                'type': 'Gone',
                'message': 'Gone',
                'alert': 'warning'
            },
            '411': {
                'type': 'Length Required',
                'message': 'Length Required',
                'alert': 'warning'
            },
            '412': {
                'type': 'Precondition Failed',
                'message': 'Precondition Failed',
                'alert': 'warning'
            },
            '413': {
                'type': 'Request Entity Too Large',
                'message': 'Request Entity Too Large',
                'alert': 'warning'
            },
            '414': {
                'type': 'Request-URI Too Long',
                'message': 'Request-URI Too Long',
                'alert': 'warning'
            },
            '415': {
                'type': 'Unsupported Media Type',
                'message': 'Unsupported Media Type',
                'alert': 'warning'
            },
            '416': {
                'type': 'Requested Range Not Satisfiable',
                'message': 'Requested Range Not Satisfiable',
                'alert': 'warning'
            },
            '417': {
                'type': 'Expectation Failed',
                'message': 'Expectation Failed',
                'alert': 'warning'
            },
            '500': {
                'type': 'Internal Server Error',
                'message': 'Internal Server Error',
                'alert': 'warning'
            },
            '501': {
                'type': 'Not Implemented',
                'message': 'Not Implemented',
                'alert': 'warning'
            },
            '502': {
                'type': 'Bad Gateway',
                'message': 'Bad Gateway',
                'alert': 'warning'
            },
            '503': {
                'type': 'Service Unavailable',
                'message': 'Service Unavailable',
                'alert': 'warning'
            },
            '504': {
                'type': 'Gateway Timeout',
                'message': 'Gateway Timeout',
                'alert': 'warning'
            },
            '505': {
                'type': 'HTTP Version Not Supported',
                'message': 'HTTP Version Not Supported',
                'alert': 'warning'
            },
            '511': {
                'type': 'Network Authentication Required',
                'message': 'Network Authentication Required',
                'alert': 'warning'
            },
            '800': {
                'type': 'Application Suspended',
                'message': 'Application Suspended',
                'alert': 'warning'
            },
            '801': {
                'type': 'User Suspended',
                'message': 'User Suspended',
                'alert': 'warning'
            }
        }
    }

    ngOnInit() {
        this.subscriptions.push(this.subcription = this.route.params.subscribe(params => this.error = params['code']));
        this.viewBag = this.errorCodes[this.error];
        this.getErrorInfo();
    }

    ngOnDestroy() {
        this.subcription.unsubscribe();
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }

    onBacktoPage(): void {
        this.location.back();
    }
    getErrorInfo() {
        document.querySelector("body").classList.add("error-" + this.error);
    }
}
