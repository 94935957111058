import { TimeLogResolver } from './services/log.resolver';
import { LogListComponent } from 'src/app/kuba/log/components/log-list.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { Route } from '@angular/router';
import { LogComponent } from 'src/app/kuba/log/log.component';

export const LogListRoutes: Route[] = [{
    path: 'timelog/list',
    component: LogListComponent,
    resolve: {
        list: TimeLogResolver
    }
}];
