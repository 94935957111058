import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({    
    template: '<router-outlet></router-outlet>'
})
export class UserComponent implements OnInit {

    constructor(translate:TranslateService) { }

    ngOnInit(): void {
        
    }
}