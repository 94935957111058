<div class="main-content">
  <div class="main-heading">
    <span>
      <span translate>INTERNAL_CONTROL_REGISTRY</span>
    </span>
    <span class="page-actions text-right" *ngIf="isInternalControlRights">
      <a
        routerLink="create"
        [routerLink]="['../create', 0]"
        class="btn btn-success"
        routerLinkActive="active"
      >
        <span class="icon ic-sm icon-add"></span>
        <span>
          <span translate>NEW</span>
        </span>
      </a>
    </span>
  </div>
  <div class="panel-tabs">
    <nav>
      <ul class="nav nav-pills">
        <li [routerLinkActive]="['active']" *ngIf="isInternalControlRights">
          <a [routerLink]="['./all']" translate>ALL</a>
        </li>
        <li [routerLinkActive]="['active']" *ngIf="hideInternalControlRights">
          <a [routerLink]="['./own']" translate>FOR_YOU</a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="card">
    <router-outlet></router-outlet>
  </div>
</div>
