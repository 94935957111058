<div class="main-content">
    <div class="card">
        <div class="card-body">
            <div class="page-title">
                <span translate>NEWS</span>
            </div>
            <form [formGroup]="newsForm">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="title" class="col-form-label">
                            <span translate>TITLE</span>
                        </label>
                        <span class="required">*</span>
                    </div>
                    <div class="col-md-8">
                        <input type="text" class="label-input form-control" formControlName="Title"
                          [readonly]="!isEditor && !isAdmin">
                        <control-messages [control]="newsForm.controls['Title']"></control-messages>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="title" class="col-form-label">
                            <span translate>EXPIRY_DATE</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <my-date-picker [style]="{'width':'160px'}" [options]="expiryDateOpitons"
                          formControlName="ExpiredOn" placeholder="{{'SELECT_EXPIRY_DATE'|translate}}"
                          [disabled]="!isEditor && !isAdmin"></my-date-picker>
                    </div>
                </div>

                <div class="mb-3">
                    <h3 class="first">
                        <span translate>CONTENT</span>
                    </h3>
                    <p-editor [readonly]="!isEditor && !isAdmin && !isUser" formControlName="ContentData" [style]="{'height':'320px'}"></p-editor>

                    <hr style="border-top:0px;border-color:#dde3e6">
                </div>
                <!-- <div class="mb-3" *ngIf="!isEditor">
                    <p [innerHTML]="newsForm.value.ContentData"></p>
                </div> -->
                <div class="action-btns-wrapper">
                    <a [routerLink]="['../../../list']" class="btn btn-outline-secondary">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </a>
                    <button *ngIf="isAdmin || isEditor" type="submit" class="btn btn-primary" (click)="clear()">
                        <span translate>CLEAR</span>
                    </button>
                    <button *ngIf="isAdmin || isEditor" type="submit" class="btn btn-primary" (click)="Save()"
                      [disabled]="!newsForm.valid">
                        <span translate>SAVE</span>
                    </button>
                    <button *ngIf="isEditor && published" type="submit" class="btn btn-primary"
                      (click)="SaveAndSendEmailToUsers()" [disabled]="!newsForm.valid || sendSMSToAllUser">
                        <span translate>PUBLISH_AND_SEND_EMAIL</span>
                    </button>
                    <button type="button" class="btn btn-primary" *ngIf="isAdmin && showPublishButton"
                    [disabled]="!newsForm.valid || loading" (click)="SaveAndPublish()">
                    <span translate>PUBLISH_SEND_EMAIL_TO_THE_USERS</span>
                </button>
                </div>
            </form>
        </div>
    </div>
    <toaster-component></toaster-component>
</div>
<div class="panel-loader" *ngIf="loading">
    <span>
        <span class="loader-icon"></span>
        <span translate>PROCESSING</span>
    </span>
</div>