<div class="main-content">
  <div class="main-heading">
    <span translate>ADD_FDV_REGISTRY</span>
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="fdvForm">
        <div class="row">
          <div class="col-md-6">
            <div class="sub-panel-title">
              <span translate>ADD_PROPERTY</span>
            </div>
            <div class="form">
              <div class="form-group" *ngIf="fdvForm.controls['objectType']">
                <label class="col-form-label">
                  <span translate>TYPE_OF_OBJECT</span>
                  <span class="required">*</span>
                </label>
                <div>
                  <p-dropdown
                    [options]="typeOfObject"
                    [style]="{ width: '160px' }"
                    formControlName="objectType"
                  ></p-dropdown>
                </div>
                <control-messages
                  [control]="fdvForm.controls['objectType']"
                ></control-messages>
              </div>
              <div class="form-group" *ngIf="fdvForm.controls['objectType']">
                <label class="col-form-label">
                  <span translate>NAME_OF_THE_OBJECT</span>
                  <span class="required">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    maxlength="100"
                    formControlName="objectName"
                  />
                </div>
                <control-messages
                  [control]="fdvForm.controls['objectName']"
                ></control-messages>
              </div>
              <div class="form-group" *ngIf="fdvForm.controls['apartments']">
                <label class="col-form-label">
                  <span translate>NUMBER_APARTMENTS</span>
                </label>
                <div>
                  <input
                    type="number"
                    class="form-control"
                    (keypress)="onNumberChange($event, 3)"
                    formControlName="apartments"
                  />
                </div>
                <control-messages
                  [control]="fdvForm.controls['apartments']"
                ></control-messages>
              </div>
              <div class="form-group" *ngIf="fdvForm.controls['floor']">
                <label class="col-form-label">
                  <span translate>NUMBER_OF_FLOOR</span>
                </label>
                <div>
                  <input
                    type="number"
                    class="form-control"
                    (keypress)="onNumberChange($event, 3)"
                    formControlName="floor"
                  />
                </div>
                <control-messages
                  [control]="fdvForm.controls['floor']"
                ></control-messages>
              </div>
              <div class="sub-panel-title">
                <span translate>ADDRESS</span>
              </div>
              <div class="form-group">
                <label class="col-form-label">
                  <span translate>ADDRESS</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  maxlength="100"
                  formControlName="address"
                />
              </div>
              <div class="form-group-25_75">
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>ZIP</span>
                  </label>
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      (keypress)="onNumberChange($event, 20)"
                      formControlName="zip"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>LOCATION</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      maxlength="100"
                      formControlName="location"
                    />
                  </div>
                </div>
              </div>
              <div class="sub-panel-title">
                <span translate>ORGANIZED</span>
              </div>
              <div class="form-group" *ngIf="fdvForm.controls['contact']">
                <label class="col-form-label">
                  <span translate>CONTACT_PERSON</span>
                  <!-- <span class="required">*</span> -->
                </label>
                <div>
                  <p-dropdown
                    [options]="contact"
                    [style]="{ width: '160px' }"
                    formControlName="contact"
                  >
                  </p-dropdown>
                </div>
                <control-messages
                  [control]="fdvForm.controls['contact']"
                ></control-messages>
              </div>
              <!--<div class="form-group" *ngIf="isVisible && Mobile">
                            <label class="col-form-label">
                                <span translate>MOBILE</span>
                            </label>
                            <div>
                                <label class="col-form-label">{{Mobile}}</label>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isVisible && Email">
                            <label class="col-form-label">
                                <span translate>EMAIL</span>
                            </label>
                            <div>
                                <label class="col-form-label">{{Email}}</label>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isVisible">
                            <div>
                                <p-checkbox [value]="email" binary="false" formControlName="isEmail"></p-checkbox>
                                <span>
                                    <label>
                                        <span translate>EMAIL_NEW_DEVIATION</span>
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isVisible">
                            <div>
                                <p-checkbox [value]="sms" binary="false" formControlName="isSms"></p-checkbox>
                                <span>
                                    <label for="sms">
                                        <span translate>SMS_NEW_DEVIATION</span>
                                    </label>
                                </span>
                            </div>
                        </div> -->
              <!-- <div class="form-group">
                <label class="col-form-label">
                  <span translate>DESCRIPTION</span>
                </label>
                <textarea
                  class="form-control"
                  maxlength="250"
                  formControlName="description"
                  rows="4"
                ></textarea>
              </div> -->
              <div class="form-group">
                <label for="EmailDevaition">
                  <span translate>EMAIL_NEWDEVIATION_LABEL</span>
                </label>
                <div *ngIf="bindSelectedUsers">
                  <p-multiSelect
                    defaultLabel="{{ 'SELECT' | translate }}"
                    [dataKey]="'Id'"
                    [options]="userSubscribers"
                    formControlName="selectedEmailSubscriber"
                    [style]="{ width: '100%' }"
                  >
                    <ng-template
                      let-emailDeviation
                      let-i="index"
                      pTemplate="item"
                    >
                      {{ i }}
                      <span>{{ emailDeviation.label }}</span>
                    </ng-template>
                  </p-multiSelect>
                </div>
                <div *ngIf="bindAllUsers">
                  <p-multiSelect
                    defaultLabel="{{ 'SELECT' | translate }}"
                    [dataKey]="'Id'"
                    [options]="selectedSubscribers"
                    formControlName="selectAllEmailSubscriber"
                    [style]="{ width: '100%' }"
                  >
                    <ng-template
                      let-emailDeviations
                      let-i="index"
                      pTemplate="item"
                    >
                      {{ i }}
                      <span>{{ emailDeviations.label }}</span>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <p-checkbox
                    label="{{ 'DO_NOT_DISPLAY_DEVIATION' | translate }}"
                    formControlName="IsShowDeviationChecked"
                    binary="true"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <div class="form" *ngIf="isChooseProject">
                <div class="sub-panel-title">
                  <span translate>COPYFOLDERS_CONTENT</span>
                </div>
                <div class="form-group">
                  <label class="col-form-label">
                    <span translate>CHOOSE_OBJECT</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="chooseObject"
                      formControlName="chooseObject"
                      [style]="{ width: '160px' }"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="form-group">
                  <div class="checkbox">
                    <p-checkbox
                      value="copy SJA"
                      label="{{ 'COPY_SJA' | translate }}"
                      formControlName="copySpj"
                      binary="true"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">
                  <span translate>DESCRIPTION</span>
                </label>
                <div>
                  <textarea
                    class="form-control"
                    maxlength="250"
                    formControlName="businessDescription"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <label class="col-form-label">
                <span translate>ADDIMAGES</span>
              </label>
              <div class="upload-file-container">
                <div class="form-group">
                  <div class="upload-file-container">
                    <div class="upload-actions">
                      <input
                        type="file"
                        id="files"
                        class="hidden"
                        (change)="filesSelect($event)"
                      />
                      <label
                        for="files"
                        class="btn btn-outline-primary"
                        translate
                        >UPLOAD_IMAGE</label
                      >
                    </div>
                    <div *ngIf="uploadStart">
                      <span translate>IMAGE_IS_UPLOADING</span>
                    </div>
                    <div *ngIf="fdvImage != null">
                      <img
                        src="{{ fdvImage }}"
                        alt=""
                        width="200"
                        class="thumbnail"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="sub-panel-title">
                <span translate>ASSIGN_ACCESS</span>
              </div>
              <p-table
                #dt
                [value]="propertyObjectUsers"
                dataKey="Id"
                scrollable="true"
                scrollHeight="400px"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <p-checkbox
                        [ngModel]="checkedAll"
                        (ngModelChange)="checkAll($event)"
                        [ngModelOptions]="{ standalone: true }"
                        binary="true"
                      ></p-checkbox>
                    </th>
                    <th>{{ "NAME" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-vechile>
                  <tr>
                    <td>
                      <p-checkbox
                        [(ngModel)]="vechile.Id === userId || vechile.status"
                        [disabled]="vechile.Id === userId"
                        (ngModelChange)="checked(vechile)"
                        [ngModelOptions]="{ standalone: true }"
                        binary="true"
                      ></p-checkbox>
                    </td>
                    <td>
                      <span>{{ vechile.Name }}</span>
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="2">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
        <!-- <div class="row">
                    <div class="col-md-12" *ngIf="categories">
                        <div class="sub-panel-title">
                            <span translate>CONTENT</span>
                        </div>
                        <div class="row" formArrayName="checkboxProperties">
                            <div class="col-sm-4" *ngFor="let category of categories; let i = index">
                                <div class="checkbox">
                                    <label formGroupName="{{i}}">
                                        <p-checkbox value="{{category.TranslationKey | translate}}"
                                          label="{{category.TranslationKey | translate}}"
                                          formControlName="{{category.Id}}" binary="true"></p-checkbox>
                                        &nbsp;
                                        <input type="text" *ngIf="category.Id === 7" style="width: 35px;height: 17px;"
                                          formControlName="Quantity" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <div class="action-btns-wrapper">
          <a class="btn btn-outline-secondary" (click)="gotoBack()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
          </a>
          <button
            type="button"
            class="btn btn-primary addbuton"
            (click)="saveFdv('REDIRECT')"
            [disabled]="!fdvForm.valid"
            *ngIf="isAdministrateFDV"
          >
            <span translate>SAVE</span>
          </button>
          <button
            type="button"
            class="btn btn-primary addbuton"
            (click)="saveFdv('RESET')"
            [disabled]="!fdvForm.valid"
            *ngIf="isHideSaveAndCreateButton"
          >
            <span translate>SAVE_CREATE_NEW</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<toaster-component></toaster-component>
