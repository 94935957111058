import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TreeModule } from '@circlon/angular-tree-component';
import { MyDatePickerModule } from 'mydatepicker';
// import { TreeModule } from 'angular-tree-component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';

import { AppSharedModule } from './../shared/appShared.module';
import {
  CommmonImageComponent,
  CommonComponent,
  CommonDocumentComponent,
  CommonTreeComponent,
  CommonViewComponent,
} from './components';
import { CommonArticleService, CommonService } from './services';
import { CommonChecklistService } from './services/common-checklist.services';

// module:
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    RouterModule,
    TableModule,
    SharedModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    MyDatePickerModule,
    InputSwitchModule,
    ContextMenuModule,
    FileUploadModule,
    FieldsetModule,
    TabMenuModule,
    AppSharedModule,
    TreeModule,
    CKEditorModule,
  ],
  exports: [
    CommonComponent,
    CommonTreeComponent,
    CommonViewComponent,
    CommmonImageComponent,
    CommonDocumentComponent,
  ],
  declarations: [
    CommonComponent,
    CommonTreeComponent,
    CommonViewComponent,
    CommmonImageComponent,
    CommonDocumentComponent,
  ],
  providers: [CommonService, CommonArticleService, CommonChecklistService],
})
export class CommonBaseModule {}
