import 'rxjs/Rx';

import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ConfirmationService, SelectItem } from 'primeng/api';

import {
    Department,
    Vehicle,
    VehicleBrand,
    VehicleTypeOfvehicle
} from '../models/vehicle';
import { BusinessDepartmentService } from './../../business-department.services';
import { VehicleServices } from '../services/vehicle.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { VehicleList } from './../models/vehicle';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'vehicleForm.component.html'
})
export class VehicleFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    public Id;
    Year: any;
    Vehicles: Vehicle[];
    Vehicleformdata: any = [];
    vehicle: any;
    vehicleForm: FormGroup;
    VehicleBrands: SelectItem[];
    vehicleType: SelectItem[];
    departments: SelectItem[];
    typeOfVehicleForm: FormGroup;
    brandForm: FormGroup;
    deptForm: FormGroup;
    departmentId: number;
    brandId: number;
    typeId: number;
    editBrandType = [];
    editVehicleType = [];
    editDepartments = [];
    cBrand: any;
    cDepartment: any;
    cTypeofVehicle: any;
    cModel: any;
    selectedBrand = new VehicleBrand();
    vehiclesize: SelectItem[];
    selectedTypeofVehicle = new VehicleTypeOfvehicle();
    selectedDepartment = new Department();
    addDept = false;
    showDept = false;
    addBrand = false;
    showBrand = false;
    addVehicle = false;
    viewVehicle = false;
    isNameExists = false;
    existsAlerttext: string;
    isDeparmentExists = false;
    departmentExists: string;
    isBrandExists = false;
    brandExists: string;
    isTypeExists = false;
    typeExists: string;
    vehicleUsers: any;
    selectedUsers: any = [];
    userId: any;
    bindAllUsers = false;
    bindSelectedUsers = true;
    checkedAll:boolean;
    userSubscribers: SelectItem[];
    emailUsers: any[];
    emailSubscriber = [];
    selectedSubscribers: SelectItem[];
    private subscriptions: Subscription[] = [];
    constructor(
        private vehicleService: VehicleServices,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        protected location: Location,
        private confirmationService: ConfirmationService,
        private businessDepartmentService: BusinessDepartmentService,
        private translate: TranslateService
    ) {
        let date = new Date();
        let currentDate = date.getFullYear();
        this.vehicleForm = this.fb.group({
            BusinessDepartmentId: [],
            VehicleBrandId: [, Validators.required],
            VehicleModelId: [, Validators.required],
            RegistrationNo: [, Validators.required],
            RegistrationYear: [currentDate.toString()],
            VehicleGeoDeviceId: [],
            DriverName: [''],
            VehicleTypeId: [],
            VehicleSize: [],
            VehicleName: [''],
            IsShowDeviationChecked: [false],
            selectedEmailSubscriber: [''],
            selectAllEmailSubscriber: ['']
        });
        this.typeOfVehicleForm = this.fb.group({
            Name: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ]
        });
        this.deptForm = this.fb.group({
            Name: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ]
        });
        this.brandForm = this.fb.group({
            Name: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ]
        });
        this.userId = +BaseServices.UserId;
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let date = new Date();
        let currentDate = date.getFullYear();
        this.Year = [];
        for (let n = 1905; n <= currentDate; n++) {
            this.Year.push({ label: n, value: n });
        }

        this.editBrandType = [];
        this.VehicleBrands = [];
        this.VehicleBrands.push({ label: 'Select a Brand', value: null });
        let brand = this.activatedRoute.snapshot.data['brand'];
        if (brand) {
            brand.forEach(element => {
                this.VehicleBrands.push({ label: element.Name, value: element.Id });
                this.editBrandType.push({ Name: element.Name, Id: element.Id });
            });
        }

        this.selectedSubscribers = [];
        this.userSubscribers = [];
        let userSubscription = this.activatedRoute.snapshot.data[
            'users_subscription'
        ];
        this.emailUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: { Id: element.Id }
                    });
                    if (element.Id === BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        }));

        let users = this.activatedRoute.snapshot.data['contact'];
        if (users) {
            this.vehicleUsers = users;
        }
        this.vehiclesize = [];
        this.vehiclesize.push({ label: 'Select', value: null });
        let vehiclesize = this.activatedRoute.snapshot.data['vehiclesize'];
        if (vehiclesize) {
            vehiclesize.forEach(element => {
                this.vehiclesize.push({ label: element.Name, value: element.Id });
            });
        }

        this.vehicleType = [];
        this.editVehicleType = [];
        this.vehicleType.push({
            label: 'Select',
            value: null
        });
        this.vehicleType.push({
            label: 'Petrol',
            value: 1
        });
        this.vehicleType.push({
            label: 'Diesol',
            value: 2
        });
        this.editDepartments = [];
        this.departments = [];
        this.departments.push({ label: 'Select', value: null });
        let department = this.activatedRoute.snapshot.data['department'];
        if (department) {
            department.forEach(element => {
                this.departments.push({ label: element.Name, value: element.Id });
                this.editDepartments.push({ Name: element.Name, Id: element.Id });
            });
        }
        this.bindDropdowns();
    }

    bindDropdowns() {
        this.subscriptions.push(this.translate.stream('SELECT_TYPE_OF_VEHICLE').subscribe(val => {
            this.vehicleType = [];
            this.vehicleType.push(
                { label: val.SELECT, value: null },
                { label: val.PETROL,value: 1 },
                { label: val.DIESEL, value: 2 },
                { label: val.HYBRID, value: 3 },
                { label: val.ELECTRIC,value: 4 }
            );
        }));
    }
    editBrand(brand: VehicleBrand) {
        this.brandId = brand.Id;
        let result = {
            Name: brand.Name
        };
        (<FormGroup>this.brandForm).setValue(result, { onlySelf: true });
    }
    deleteBrand(deleteBrand) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleService
                    .deleteBrandById(deleteBrand.Id)
                    .subscribe(result => {
                        this.toasterComponent.callToastDlt();
                        this.bindBrand();
                        this.viewBrand();
                    }));
            }
        });
    }
    viewBrand() {
        this.subscriptions.push(this.vehicleService.getAllBrands().subscribe(result => {
            this.editBrandType = result;
        }));
    }
    viewTypeOfVehicle() {
        this.subscriptions.push(this.vehicleService.getAllVehicleType().subscribe(result => {
            this.editVehicleType = result;
        }));
    }
    viewDept() {
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(result => {
            if (result) {
                this.editDepartments = result;
            }
        }));
    }
    bindTypeOfVehicle() {
        this.subscriptions.push(this.vehicleService.getAllVehicleType().subscribe((type: any) => {
            this.vehicleType = [];
            this.vehicleType.push({
                label: 'Select a Type Of vehicle',
                value: null
            });
            this.cTypeofVehicle = type;
            this.cTypeofVehicle.forEach(element => {
                this.vehicleType.push({
                    label: element.Name,
                    value: element.Id
                });
            });
            this.editVehicleType = type;
        }));
    }
    bindDepartment() {
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe((department: any) => {
            if (department) {
                this.departments = [];
                this.departments.push({
                    label: 'Select a Department',
                    value: null
                });
                this.cDepartment = department;
                this.cDepartment.forEach(element => {
                    this.departments.push({
                        label: element.Name,
                        value: element.Id
                    });
                });
                this.editDepartments = department;
            }
        }));
    }
    bindBrand() {
        this.subscriptions.push(this.vehicleService.getAllBrands().subscribe((brand: any) => {
            this.VehicleBrands = [];
            this.VehicleBrands.push({ label: 'Select a Brand', value: null });
            this.cBrand = brand;
            this.cBrand.forEach(element => {
                this.VehicleBrands.push({
                    label: element.Name,
                    value: element.Id
                });
            });
            this.editBrandType = brand;
        }));
    }
    saveBrand() {
        let brand = new VehicleBrand();
        brand.Id = this.brandId ? this.brandId : 0;
        brand.Name = this.brandForm.value.Name;
        if (brand.Id > 0) {
            this.subscriptions.push(this.vehicleService
                .updateBrand(brand)
                .subscribe((brandEdited: any) => {
                    if (brandEdited) {
                        this.toasterComponent.callToast();
                        this.viewBrand();
                        this.bindBrand();
                        this.selectedBrand = new VehicleBrand();
                        this.vehicleForm
                            .get('VehicleBrandId')
                            .patchValue(brandEdited.Id);
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            function () {
                                this.isNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        } else {
            this.subscriptions.push(this.vehicleService
                .createBrand(brand)
                .subscribe((brandAdded: any) => {
                    if (brandAdded) {
                        this.toasterComponent.callToast();
                        this.viewBrand();
                        this.bindBrand();
                        this.vehicleForm
                            .get('VehicleBrandId')
                            .patchValue(brandAdded.Id);
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            function () {
                                this.isNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        }
    }
    /**
     * reset brand from
     */
    resetBrand() {
        this.brandId = 0;
        this.brandForm.reset();
    }

    editTypeOfVehicle(typeOfVehicle: VehicleTypeOfvehicle) {
        this.typeId = typeOfVehicle.Id;
        let result = {
            Name: typeOfVehicle.Name
        };
        (<FormGroup>this.typeOfVehicleForm).setValue(result, {
            onlySelf: true
        });
    }
    deleteTypeOfVehicle(deleteTypeOfVehicle) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleService
                    .deleteVehicleType(deleteTypeOfVehicle.Id)
                    .subscribe(result => {
                        this.toasterComponent.callToastDlt();
                        this.bindTypeOfVehicle();
                        this.viewTypeOfVehicle();
                    }));
            }
        });
    }

    saveTypeOfVehicle() {
        let typeofvehicle = new VehicleTypeOfvehicle();
        typeofvehicle.Id = this.typeId ? this.typeId : 0;
        typeofvehicle.Name = this.typeOfVehicleForm.value.Name;
        if (typeofvehicle.Id > 0) {
            this.subscriptions.push(this.vehicleService
                .updateVehicleType(typeofvehicle)
                .subscribe((typeEdited: any) => {
                    if (typeEdited) {
                        this.toasterComponent.callToast();
                        this.bindTypeOfVehicle();
                        this.viewTypeOfVehicle();
                        this.vehicleForm
                            .get('VehicleTypeId')
                            .patchValue(typeEdited.Id);
                        this.selectedTypeofVehicle = new VehicleTypeOfvehicle();
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            function () {
                                this.isNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        } else {
            this.subscriptions.push(this.vehicleService
                .createVehicleType(typeofvehicle)
                .subscribe((typeAdded: any) => {
                    if (typeAdded) {
                        this.toasterComponent.callToast();
                        this.bindTypeOfVehicle();
                        this.viewTypeOfVehicle();
                        this.vehicleForm
                            .get('VehicleTypeId')
                            .patchValue(typeAdded.Id);
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            function () {
                                this.isNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        }
        this.resetTypeOfVehicle();
    }
    /**
     * reset vehicle type from
     */
    resetTypeOfVehicle() {
        this.typeId = 0;
        this.typeOfVehicleForm.reset();
    }
    saveDept() {
        let dept = new Department();
        dept.Id = this.departmentId ? this.departmentId : 0;
        dept.Name = this.deptForm.value.Name;
        dept.Status = '1';
        dept.BusinessId = BaseServices.BusinessId;
        dept.ApplicationId = BaseServices.ApplicationId;
        if (dept.Id > 0) {
            this.subscriptions.push(this.vehicleService
                .updateDept(dept)
                .subscribe((dapartmentEdited: any) => {
                    if (dapartmentEdited) {
                        this.toasterComponent.callToast();
                        this.bindDepartment();
                        this.viewDept();
                        this.selectedDepartment = new Department();
                        this.vehicleForm
                            .get('BusinessDepartmentId')
                            .patchValue(dapartmentEdited.Id);
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            function () {
                                this.isNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        } else {
            this.subscriptions.push(this.vehicleService
                .createDept(dept)
                .subscribe((dapartmentAdded: any) => {
                    if (dapartmentAdded) {
                        this.toasterComponent.callToast();
                        this.bindDepartment();
                        this.viewDept();
                        this.vehicleForm
                            .get('BusinessDepartmentId')
                            .patchValue(dapartmentAdded.Id);
                        this.onHideDeptForm();
                        this.addDept=false;
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            function () {
                                this.isNameExists = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        }
        this.resetDepartment();
    }
    /**
     * reset department from
     */
    resetDepartment() {
        this.departmentId = 0;
        this.deptForm.reset();
    }
    editDept(DeptType: Department) {
        this.departmentId = DeptType.Id;
        let result = {
            Name: DeptType.Name
        };
        (<FormGroup>this.deptForm).setValue(result, { onlySelf: true });
    }
    saveVehicle() {
        let vehicle = new VehicleList();
        vehicle.Id = this.Id ? this.Id : 0;
        vehicle.BusinessId = BaseServices.BusinessId;
        vehicle.BusinessDepartmentId = this.vehicleForm.value.BusinessDepartmentId;
        vehicle.VehicleSizeId = this.vehicleForm.value.VehicleSize;
        vehicle.Status = '1';
        vehicle.RegistrationNo = this.vehicleForm.value.RegistrationNo;
        vehicle.RegistrationYear = this.vehicleForm.value.RegistrationYear.toString();
        vehicle.DriverName = this.vehicleForm.value.DriverName;
        vehicle.Name = this.vehicleForm.value.VehicleName;
        vehicle.VehicleTypeId = this.vehicleForm.value.VehicleTypeId;
        vehicle.VehicleBrand = this.vehicleForm.value.VehicleBrandId;
        vehicle.VehicleModel = this.vehicleForm.value.VehicleModelId;
        vehicle.CreatedBy = BaseServices.UserId;
        vehicle.CreatedOn = new Date();
        vehicle.ShowDeviation = !this.vehicleForm.value.IsShowDeviationChecked;
        let userId = BaseServices.UserId;
        let name = BaseServices.userName;
        let checkUserId = this.selectedUsers.filter(x => x.Id === userId);
        if (checkUserId.length === 0) {
            this.selectedUsers.push({ Id: userId, Name: name });
        }
        if ((this.bindSelectedUsers) && this.vehicleForm.value.selectedEmailSubscriber) {
            vehicle.VehicleEmailSubscribers = [];
            this.emailSubscriber = this.vehicleForm.value
                .selectedEmailSubscriber;
        }
        if ((this.bindAllUsers) && this.vehicleForm.value.selectAllEmailSubscriber) {
            vehicle.VehicleEmailSubscribers = [];
            this.emailSubscriber = this.vehicleForm.value.selectAllEmailSubscriber;
        }

        let Ids: any = ([] = []);

        this.selectedUsers.forEach((element: any) => {
            Ids.push({
                UserId: element.Id,
                VehicleId: vehicle.Id,
                Name: element.Name,
                IsEmail: this.emailSubscriber.find(x => x.Id === element.Id) ? true : false
            });
        });
        vehicle.VehicleUsersDto = Ids;
        this.subscriptions.push(this.vehicleService.createVehicle(vehicle).subscribe((result: any) => {
            if (result) {
                this.toasterComponent.callToast();
                setTimeout(() => {
                    this.router.navigate(['./../edit', result.Id], {
                        relativeTo: this.activatedRoute
                    });
                }, 1000);
            }
        }));
    }
    gotoList() {
        this.location.back();
    }
    deleteDept(deletDept) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleService
                    .deleteDepartment(deletDept.Id)
                    .subscribe(result => {
                        this.toasterComponent.callToastDlt();
                        this.bindDepartment();
                        this.viewDept();
                    }));
            }
        });
    }
    onHideDeptForm() {
        this.deptForm.reset();
    }
    onHideBrandForm() {
        this.brandForm.reset();
    }
    onHideTypeForm() {
        this.typeOfVehicleForm.reset();
    }

    checkAll(event) {
        _.forEach(this.vehicleUsers, (item: any) => {
            if (event) {
                item.status = true;
            } else {
                item.status =
                    // to remain checked state for current user
                    (this.userId === +item.Id) ? true : false;
            }
        });

        this.selectedUsers = this.vehicleUsers;
        if (!event) {
            this.selectedUsers = [];
            this.bindAllUsers = false;
            this.bindSelectedUsers = true;
        } else {
            this.bindAllUsers = true;
            this.bindSelectedUsers = false;
        }
    }
    checked(value) {
        if (value.status) {
            this.selectedUsers.push(value);
            if (this.emailUsers) {
                let selectedUsers = this.emailUsers.find(x => x.Id === value.Id);
                this.emailUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.isEmail)) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: { Id: element.Id }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedUsers, (val) => {
                if (this.userSubscribers) {
                    _.forEach(this.userSubscribers, (item: any) => {
                        if (item.value.Id == value.Id) {
                            this.userSubscribers.pop();
                        }
                    });
                }
                return val === value;
            })

        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
