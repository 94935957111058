<div
  class="kb-dropdown pull-right"
  [ngClass]="{ open: showDropDown }"
  *ngIf="!isAdmin && helpsupport"
>
  <a
    href="javscript:void(0)"
    class="dd-icon-btn"
    id="helpBtn"
    (click)="dropdownToggle()"
    pTooltip="{{ 'HELPANDSUPPORT' | translate }}"
    tooltipPosition="bottom"
  >
    <span class="icon ic-sm icon-help" aria-hidden="true"></span>
  </a>
  <div
    class="kb-dropdown-menu help-list"
    aria-labelledby="helpBtn"
    *ngIf="showDropDown"
  >
    <div class="kb-dd-header">
      <h5 class="pull-left" translate>HELPANDSUPPORT</h5>
      <a href="{{ EmailTo }}" class="btn-link pull-right"
        ><span translate>SEND_MAIL</span></a
      >
    </div>
    <form>
      <div
        class="pleft-10 pright-10"
        *ngFor="let item of helpsupportList.Story"
      >
        <h4 translate>STORY</h4>
        <div [innerHTML]="GeneralContent"></div>
        <a href="javascript:void(0)" (click)="showTitlePopup(item)">
          <span translate>VIEW_MORE</span>
        </a>
      </div>
      <div class="list-item">
        <div
          class="accordion-group"
          id="quickHelp"
          role="tablist"
          aria-multiselectable="true"
        >
          <div class="accordion-item">
            <h5
              class="accordion-header pleft-10 pright-10"
              id="articlesQuickHelp"
              role="tab"
            >
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#quickHelp"
                href="#articlesQuickHelpContent"
                aria-expanded="true"
                aria-controls="articlesQuickHelpContent"
              >
                <span translate> ARTICLES </span>
                <span class="icon ic-xs icon-expand-arrow pull-right"></span>
              </a>
            </h5>
            <div
              id="articlesQuickHelpContent"
              class="accordion-content collapse in pleft-20 pright-20"
              role="tabpanel"
              aria-labelledby="articlesQuickHelp"
            >
              <ul class="list-unstyled">
                <li
                  class="list-item"
                  *ngFor="let item of helpsupportList.Articles"
                >
                  <a href="javascript:void(0)" (click)="showTitlePopup(item)">
                    <span class="list-item-title">{{ item.Title }}</span>
                  </a>
                </li>
              </ul>
              <div class="text-left">
                <a
                  [routerLink]="[
                    './../app/' +
                      userRole +
                      '/' +
                      businessId +
                      '/help-support/userlist',
                    2
                  ]"
                >
                  <span translate>VIEW_MORE</span>
                </a>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5
              class="accordion-header pleft-10 pright-10"
              id="VideosQuickHelp"
              role="tab"
            >
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#quickHelp"
                href="#VideosQuickHelpContent"
                aria-expanded="true"
                aria-controls="VideosQuickHelpContent"
              >
                <span translate> VIDEOS </span>
                <span class="icon ic-xs icon-expand-arrow pull-right"></span>
              </a>
            </h5>
            <div
              id="VideosQuickHelpContent"
              class="accordion-content collapse in pleft-20 pright-20"
              role="tabpanel"
              aria-labelledby="VideosQuickHelp"
            >
              <ul class="list-unstyled">
                <li
                  class="list-item"
                  *ngFor="let item of helpsupportList.Videos"
                >
                  <a href="javascript:void(0)" (click)="showTitlePopup(item)">
                    <span class="icon ic-xs icon-circled-play mright-10"></span>
                    <span>{{ item.Title }}</span>
                  </a>
                </li>
              </ul>
              <div class="text-left">
                <a
                  [routerLink]="[
                    './../app/' +
                      userRole +
                      '/' +
                      businessId +
                      '/help-support/userlist',
                    3
                  ]"
                >
                  <span translate>VIEW_MORE</span>
                </a>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5
              class="accordion-header pleft-10 pright-10"
              id="QAQuickHelp"
              role="tab"
            >
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#quickHelp"
                href="#QAQuickHelpContent"
                aria-expanded="true"
                aria-controls="QAQuickHelpContent"
              >
                <span translate> QUESTIONSANDANSWERS </span>
                <span class="icon ic-xs icon-expand-arrow pull-right"></span>
              </a>
            </h5>
            <div
              id="QAQuickHelpContent"
              class="accordion-content collapse in pleft-20 pright-20"
              role="tabpanel"
              aria-labelledby="QAQuickHelp"
            >
              <ul class="list-unstyled">
                <li
                  class="list-item"
                  *ngFor="let item of helpsupportList.QuestionsAnswers"
                >
                  <a href="javascript:void(0)" (click)="showTitlePopup(item)">
                    <span>{{ item.Title }}</span>
                  </a>
                </li>
              </ul>
              <div class="text-left">
                <a
                  [routerLink]="[
                    './../app/' +
                      userRole +
                      '/' +
                      businessId +
                      '/help-support/userlist',
                    4
                  ]"
                >
                  <span translate>VIEW_MORE</span>
                </a>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h5 class="accordion-header pleft-10 pright-10" role="tab">
              <a
                href="javascript:void(0);"
                [routerLink]="[
                  './../app/' + userRole + '/' + businessId + '/about'
                ]"
                ><span translate>ABOUT</span
                ><span class="icon ic-xs icon-forward pull-right"></span
              ></a>
            </h5>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a
          class="btn btn-link"
          href="javascript:void(0)"
          [routerLink]="[
            './../app/' +
              userRole +
              '/' +
              businessId +
              '/help-support/userlist',
            2
          ]"
        >
          <span translate>VIEW_ALL</span
          ><span class="icon ic-xs icon-forward" aria-hidden="true"></span>
        </a>
      </div>
    </form>
  </div>
</div>
<p-dialog
  header="{{ 'HELPANDSUPPORT' | translate }}"
  [(visible)]="showNewNotification"
  [modal]="true"
  [style]="{ width: '600px' }"
  [resizable]="false"
  [draggable]="false"
  (onHide)="cancel()"
  [contentStyle]="{ 'min-height': '400px' }"
>
  <div class="form-group">
    <label *ngIf="isStory" for="title">
      <span translate>{{ notificationTitle }}</span>
    </label>
    <div *ngIf="isVideos" class="wb-content">
      <iframe
        width="560"
        height="315"
        [src]="videoLink"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <div class="wb-content">
      <div [innerHTML]="notificationDesc"></div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="closePopUp()"
      >
        <span translate>CLOSE</span>
      </button>
    </div>
  </p-footer>
</p-dialog>
