export class LoggedUser {
    Id ?: number;
    ApplicationId ?: number;
    BusinessId ?: number;
    BusinessPortalId ?: number;
    Username ?: string;
    Name ?: string;
    Email ?: string;
    Role ?: string;
    Avatar ?: string;
    Theme ?: string;
    BasePath ?: string;
    Language ?: string;
    Branding ?: string;
}
