export class SubContractor {
    Id: number;
    ContractorTypeId: string;
    ContractorTypeName: string;
    Name: string;
    Address: string;
    Zip: string;
    Mobile: string;
    Telephone: string;
    Fax: string;
    Email: string;
    Location: string;
    CountryCode: string;
    Comment: string;
    BusinessId: number;
    BusinessName: string;
    OrganizationNumber: string;
    AppId: number;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    Avatar: string;
    IsApprovalNotice: boolean;
    ApprovalDateTime: any;
}
export class ContractorContacts {
    Id: number;
    ContractorId: string;
    Name: string;
    Mobile: string;
    CountryCode: string;
    Email: string;
    DateofBirth: string;
    HSECardNumber: number;
    IsSms: string;
    IsEmail: string;
    IsUser: boolean;
    AssignProjectUser: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    Avatar: string;
    IsApprovalNotice: boolean;
    ApprovalDateTime: any;
}
export class User {
    Id: number;
    LanguageId: number;
    RoleId: number;
    ApplicationId: number;
    BusinessId: number;
    Rights: string;
    FeatureId: number;
    FeatureKey: String;
    Username: string;
    Password: string;
    Email: string;
    Name: string;
    UserInfoSms: string;
    Avatar: string;
    Sso: string;
    SsoUsername: string;
    SsoDomain: string;
    SsoLoggedIn: string;
    NotificationUpdateOn: string;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    Mobile: string;
    Phonecode: string;
    CountryCode:string;

}

export class ModuleList {
    Number: number;
    Name: String;
    Status: String;
    Id: number;
    Modules: String;
}
