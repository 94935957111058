export class AbsenceCode {
    Id: number;
    OwnSickness: string;
    OwnSickMedicalCertificate: string;
    WorkRelatedIssues: string;
    ChildrenSickness: string;
    ChildrenCaretakerSick: string;
    PaidLeave: string;
    UnpaidLeave: string;
    Others: string;
    BusinessId: number;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
}
