import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Links } from '../models/';
import { BaseServices } from './../../kuba.services';
import { BusinessLinksService } from './businessLinks.service';

@Injectable()
export class BusinessLinksListResolver implements Resolve<Links[]> {
  constructor(private backend: BusinessLinksService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<Links[]> {
    if (BaseServices.roleId === '2') {
      return this.backend.getLinksByBusinessId(0);
    } else {
      return this.backend.getLinksByBusinessId(+route.parent?.params['bid']);
    }
  }
}

@Injectable()
export class BusinessLinksEditResolver implements Resolve<Links> {
  constructor(private backend: BusinessLinksService) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getLinksById(+route.params['lid']);
  }
}
