<div class="main-content">
    <div class="main-heading">
        <span translate>CUSTOMIZE_FAQ</span>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="CustomfaqForm" #f="ngForm" novalidate name="form">
                <div class="form-group">
                    <label for="category" class="col-form-label" translate>SELECT_CATEGORY</label>
                    <span class="required">*</span>
                    <div>
                        <p-dropdown [options]="Faq" formControlName="Id" (onChange)="editFaq($event,dropDown)"
                          #dropDown>
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group" *ngIf="categorySelected">
                    <div class="upload-file-container">
                        <div class="upload-actions">
                            <input type="file" accept="video/mp4,video/avi,video/*" id="files" class="hidden"
                              (change)="filesSelect($event)" />
                            <label for="files" class="btn btn-outline-primary" translate>UPLOAD_VIDEO</label>
                        </div>
                        <div *ngIf="uploadStart">
                            <span translate>VIDEO_UPLOADING</span>
                        </div>
                    </div>
                    <div class="wb-content">
                        <video width=100% *ngIf="videoPath != null" controls>
                            <source src={{videoPath}} type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="mtop-10">
                    <div class="page-title" *ngIf="faqTitle">{{faqTitle | translate }}</div>
                    <p-editor [style]="{'height':'320px'}" formControlName="ContentData"></p-editor>
                </div>
            </form>
            <div class="action-btns-wrapper">
                <button type="button" class="btn btn-primary" (click)="saveFaq(CustomfaqForm.value)"
                  [disabled]="!CustomfaqForm.valid">
                    <span translate>SAVE</span>
                </button>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>