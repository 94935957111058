export class HelpSupport {
    Id: number;
    ApplicationId: number;
    BusinessId: number;
    CategoryId: number;
    CategoryName: string;
    Title: string;
    Content: string;
    ContentLink: string;
    Status: string;
    PublishedOn: string;
    ExpiredOn: string;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
    ModuleName:number;
    ModuleNameOriginal:string;
}

export class HelpSupportDetails{
    Story: HelpSupport [];
    Articles: HelpSupport [];
    Videos: HelpSupport [];
    QuestionsAnswers: HelpSupport [];
    Email: HelpSupport [];
}