<div class="page-title">
    <span>{{formTitle}}</span>
    <span *ngIf="mode" class="page-actions">
        <button type="button" class="btn btn-success" (click)="newForm()">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
        </button>
    </span>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table #dt [value]="larList" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
        [responsive]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="title">{{'TITLE' | translate}}</th>
                <th pSortableColumn="url">{{'Url' | translate}}</th>
                <th *ngIf="mode">{{'Url' | translate}}</th>
            </tr>
        </ng-template>
        <tr>
            <th>
                <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'title', 'contains')"
                    placeholder="" class="p-column-filter">
            </th>
            <th>
                <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'url', 'contains')" placeholder=""
                    class="p-column-filter">
            </th>
            <th *ngIf="mode"></th>
        </tr>
        <ng-template pTemplate="body" let-lar>
            <tr>
                <td>
                    <span translate>{{lar.title}}</span>
                </td>
                <td>
                    <span><a [attr.href]="lar.url" target="_blank">{{lar.url}}</a></span>
                </td>
                <td *ngIf="mode">
                    <div *ngIf="lar['isEditable']">
                        <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}" (click)="editLar(lar)">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>

                        <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                            (click)="deleteLar(lar['id'])">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="3">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
</div>
<p-dialog [header]='title' [(visible)]="larDailog"
[style]="{width: '500px', height:'350px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="createLarForm">
        <div class="form-group">
            <label for="title">
                <span translate>TITLE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="title" id="title" pInputText class="form-control" />
            </div>
            <control-messages [control]="createLarForm.controls['title']"></control-messages>
        </div>
        <div class="form-group">
            <label for="title">
                <span translate>URL</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="url" id="url" pInputText class="form-control" />
            </div>
            <control-messages [control]="createLarForm.controls['url']"></control-messages>
        </div>
        <div class="form-group">
            <div class="radio-inline" *ngFor="let option of options">
                <span>
                    <input type="radio" formControlName="status"
                        [checked]="createLarForm.controls['status'].value == option.id" name="status"
                        value="{{option.id}}">{{option.name | translate}}
                </span>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <button type="button" (click)="cancelClose()" class="btn btn-outline-secondary">
                <span translate>CANCEL</span>
            </button>
            <button type="button" (click)="saveLar()"
                [disabled]="!createLarForm.valid || ValidateWhiteSapce(createLarForm.controls['title'].value) || ValidateWhiteSapce(createLarForm.controls['url'].value)"
                class="btn btn-primary">
                <span translate>SAVE_CLOSE</span>
            </button>
        </div>
    </form>
</p-dialog>

<p-confirmDialog width="425"></p-confirmDialog>