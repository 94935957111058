import { Route } from '@angular/router';

import { MyFeaturesComponent } from './my-features.component';
import { MyFeaturesListComponent } from './components/my-features-list.component';
export const MyFeaturesRoutes: Route[] = [
    {
        path: 'my-features',
        component: MyFeaturesComponent,
        children: [
            {
                path: 'list',
                component: MyFeaturesListComponent,
            }
        ]
    },
];
