import { Rights } from './../../../_models/feature';
import { BaseServices } from './../../kuba.services';
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
    selector: 'internalcontrol-list',
    templateUrl: 'internalcontrol-list.component.html'
})

export class InternalListComponent implements OnInit {
    isInternalControlRights = true;
    hideInternalControlRights= true;

    constructor(private router: Router, private route: ActivatedRoute) { 
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        /*
         * get InternalControl list
         */
        
        if (+BaseServices.roleId === 4) {
            this.isInternalControlRights = false;
            this.router.navigate(['own'], { relativeTo: this.route })
        } else if (+BaseServices.roleId === 5) {
            this.onInternalRights();
        } else {
        }
    }

    onInternalRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_CONTROL;

        if (currentUserRole === 'User') {
            this.isInternalControlRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
            this.router.navigate(['own'], { relativeTo: this.route });
        } else {
            this.isInternalControlRights = true;
            this.router.navigate(['all'], { relativeTo: this.route });
        }
    }

    OnAdmin(){
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
        if(curSession > 0)
        {
            this.isInternalControlRights=true;
            this.hideInternalControlRights = false;
            this.router.navigate(['all'], { relativeTo: this.route  });
        }
        else{
            this.isInternalControlRights = true;
            this.router.navigate(['all'], { relativeTo: this.route  });
        }
    }
}
