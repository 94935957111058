import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseServices } from './../kuba/kuba.services';

@Component({
    selector: 'helplinks',
    templateUrl: 'helplinks.component.html'
})
export class HelpLinksComponent implements OnInit {
    //#region variable

    businessId: number;
    userRole: string;
    isAdmin: boolean;
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param location {Location}
     * @param route {ActivatedRoute}
     */
    constructor(private location: Location, private route: ActivatedRoute, private router: Router) {}

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        let roleId = BaseServices.roleId;
        this.isAdmin = roleId === '1' ? true : false;
        if (roleId === '1') {
            this.userRole = 'admin';
        } else if (roleId === '2') {
            this.userRole = 'portal';
        } else {
            this.userRole = 'business';
        }
        this.businessId = BaseServices.BusinessId;
    }
    //#endregion

    //#region control-event

    /**
     * open window
     */
    openWindow() {
        let origin = window.location.origin;
        window.open(
            origin + '/faq',
            'FAQ',
            'location=yes,height=570,width=520,scrollbars=yes,status=yes'
        );
    }

    //#endregion
}
