import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { Injectable } from '@angular/core';
import { Instrument, InstrumentType, ModelType } from 'src/app/kuba/measure-protocol/models/instrument';
import { CMP, MP, CMPInstrument } from 'src/app/kuba/measure-protocol/models/measure-protocol';
import {
    CMPComment,
    EarthLeakage,
    PowerLeakage,
    Temperature,
    TemperatureModel,
    TemperatureType,
} from 'src/app/kuba/measure-protocol/models/mpc-details';
import { environment } from 'src/environments/environment';

import { VisualControl } from '../models/visual-control.model';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MeasureProtocolService {

    constructor(private http: HttpClient) {
    }

    getClientContactUser(clientId: number) {
        return this.http
            .get(
                environment.BASE_URL +
                    '/clientcontact/clientcontactsuser/' +
                    clientId,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getAllMeasureProtocol(featureId:number, featureKey: number, key: string) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${featureKey}/measureProtocol/byfeature/${featureId}`,
                // this.options
                BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    getAllCompleteMeasureProtocol(id: number, key: string) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${id}/measureProtocol/all/measureProtocolComplete`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    create(kundeELId: number, newMP: MP) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/kundeEL/' +
                    kundeELId +
                    '/measureProtocol',
                newMP,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    update(kundeELId: number, ExistingMP: MP) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/kundeEL/' +
                    kundeELId +
                    '/measureProtocol',
                ExistingMP,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteMP(id: number, kundeELId: number) {
        return this.http
            .delete(
                environment.BASE_URL +
                    '/kundeEL/' +
                    kundeELId +
                    '/measureProtocol/delete/' +
                    id, BaseServices.headerOption
            )
            .map(result => result);
    }

    createCMP(MeasureProtocolId: number, newMP: CMP) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/measureProtocol/' +
                    MeasureProtocolId +
                    '/measureProtocolComplete',
                newMP,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateCMP(kundeELId: number, ExistingCMP: CMP) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/measureProtocol/' +
                    kundeELId +
                    '/measureProtocolComplete',
                ExistingCMP,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteCMP(id: number, kundeELId: number) {
        return this.http
            .delete(
                environment.BASE_URL +
                    '/measureProtocol/' +
                    kundeELId +
                    '/measureProtocolComplete/delete/' +
                    id, BaseServices.headerOption
            )
            .map(result => result);
    }

    getMeasureProtocolById(id: number, kundeELId: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/kundeEL/' +
                    kundeELId +
                    '/measureProtocol/' +
                    id, BaseServices.headerOption
            )
            .map(res => res);
    }

    getAllInstruments(id: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/instrument/measureProtocol/` + id,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getAllCMPInstruments(id: number) {
        return this.http
            .get(
                `${environment.BASE_URL}/instrument/measureProtocolComplete/` +
                    id,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getInstrumentById(cmpId: number, id: number) {
        let result: any;
        return this.http
            .get(environment.BASE_URL + '/instrument/' + id, BaseServices.headerOption)
            .map(res => res);
    }

    getMeasureProtocolCompleteById(id: number, kundeELId: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/measureProtocol/' +
                    kundeELId +
                    '/measureProtocolComplete/' +
                    id, BaseServices.headerOption
            )
            .map(res => res);
    }

    //  bind instrument type
    getAllByType(kundeELId: number, key: string) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/instrument/type`, BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    //update Instrument Type
    updateInsType(
        existingCategory: InstrumentType,
        key: string,
        kundeELId: number
    ) {
        return this.http
            .put(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/instrument/type`,
                existingCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //adding Instrument Type
    createInsType(newCategory: InstrumentType, key: string, kundeELId: number) {
        return this.http
            .post(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/instrument/type`,
                newCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //delete Instrument Type
    deleteInsType(Id: number, kundeELId: number, key: string) {
        let result: boolean;
        return this.http.delete(
            `${environment.BASE_URL}/${BaseServices.apiRoute(
                key
            )}/${kundeELId}/instrument/type/` + Id, BaseServices.headerOption
        );
    }

    //  bind model type
    getAllByModel(kundeELId: number, key: string) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/instrument/model`, BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    //  update Model Type
    updateModel(existingCategory: ModelType, key: string, kundeELId: number) {
        return this.http
            .put(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/instrument/model`,
                existingCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  adding Model Type
    createModel(newCategory: ModelType, key: string, kundeELId: number) {
        return this.http
            .post(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/instrument/model`,
                newCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  delete Model Type
    deleteModelType(Id: number, kundeELId: number, key: string) {
        let result: boolean;
        return this.http.delete(
            `${environment.BASE_URL}/${BaseServices.apiRoute(
                key
            )}/${kundeELId}/instrument/model/` + Id, BaseServices.headerOption
        );
    }

    //  create mp instrument
    createMPInstrument(newInst: Instrument) {
        return this.http
            .post(
                environment.BASE_URL + '/instrument/measureprotocol',
                newInst,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  create cmp instrument
    createCMPInstrument(newInst: Instrument) {
        return this.http
            .post(
                environment.BASE_URL + '/instrument/measureProtocolComplete/',
                newInst,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  update instrument
    updateInstrument(ExistingInst: Instrument) {
        return this.http
            .put(
                environment.BASE_URL + '/instrument',
                ExistingInst,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // delete Instrument
    deleteInstrument(id: number) {
        return this.http
            .delete(environment.BASE_URL + '/instrument/' + id, BaseServices.headerOption)
            .map(result => result);
    }

    //  get all power leakage
    getAllPowerLeakage(id: number) {
        return this.http
            .get(
                `${
                    environment.BASE_URL
                }/measureProtocolComplete/${id}/powerLeakage`,
                // this.options
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getPowerLeakageById(cmpId: number, id: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/powerLeakage/' +
                    id, BaseServices.headerOption
            )
            .map(res => res);
    }

    createPowerLeakage(cmpId: number, newPL: PowerLeakage) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/powerLeakage',
                newPL,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // update power leakage
    updatePowerLeakage(cmpId: number, ExistingPL: PowerLeakage) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/powerLeakage',
                ExistingPL,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // delete power leakage
    deletePowerLeakage(id: number, cmpId: number) {
        return this.http
            .delete(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/powerLeakage/' +
                    id, BaseServices.headerOption
            )
            .map(result => result);
    }

    //  get all power leakage
    getAllEarthLeakage(id: number) {
        return this.http
            .get(
                `${
                    environment.BASE_URL
                }/measureProtocolComplete/${id}/earthLeakageBreaker`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getEarthLeakageById(cmpId: number, id: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/earthLeakageBreaker/' +
                    id, BaseServices.headerOption
            )
            .map(res => res);
    }

    createEarthLeakage(cmpId: number, newEL: EarthLeakage) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/earthLeakageBreaker',
                newEL,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // update power leakage
    updateEarthLeakage(cmpId: number, ExistingEL: EarthLeakage) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/earthLeakageBreaker',
                ExistingEL,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // delete power leakage
    deleteEarthLeakage(id: number, cmpId: number) {
        return this.http
            .delete(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/earthLeakageBreaker/' +
                    id, BaseServices.headerOption
            )
            .map(result => result);
    }

    //  bind Temperature type
    getAllByTemperatureType(kundeELId: number, key: string, featureId:number) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/MPCTemperature/type/byfeature/${featureId}`, BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    //  update Temperature Type
    updateTemperatureType(
        existingCategory: TemperatureType,
        key: number,
        kundeELId: number
    ) {
        return this.http
            .put(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/MPCTemperature/type`,
                existingCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  adding Temperature Type
    createTemperatureType(
        newCategory: TemperatureType,
        key: number,
        kundeELId: number
    ) {
        return this.http
            .post(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/MPCTemperature/type`,
                newCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  delete Temperature Type
    deleteTemperatureType(Id: number, kundeELId: number, key: string, featureId: number) {
        let result: boolean;
        return this.http.delete(
            `${environment.BASE_URL}/${BaseServices.apiRoute(
                key
            )}/${kundeELId}/MPCTemperature/type/` + Id + '/'+ featureId, BaseServices.headerOption
        );
    }

    //  bind Temperature type
    getAllByTemperatureModel(kundeELId: number, key: string, featureId:number) {
        return this.http
            .get(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/MPCTemperature/model/byfeature/${featureId}`, BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    //  update Temperature Type
    updateTemperatureModel(
        existingCategory: TemperatureModel,
        key: number,
        kundeELId: number
    ) {
        return this.http
            .put(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/MPCTemperature/model`,
                existingCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  adding Temperature Type
    createTemperatureModel(
        newCategory: TemperatureModel,
        key: number,
        kundeELId: number
    ) {
        return this.http
            .post(
                `${environment.BASE_URL}/${BaseServices.apiRoute(
                    key
                )}/${kundeELId}/MPCTemperature/model`,
                newCategory,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  delete Temperature Type
    deleteTemperatureModel(Id: number, kundeELId: number, key: string, featureId: number) {
        let result: boolean;
        return this.http.delete(
            `${environment.BASE_URL}/${BaseServices.apiRoute(
                key
            )}/${kundeELId}/MPCTemperature/model/` + Id + '/'+ featureId, BaseServices.headerOption
        );
    }

    //  get all cmp temperature
    getAllCMPTemperature(cmpId: number) {
        return this.http
            .get(
                `${
                    environment.BASE_URL
                }/measureProtocolComplete/${cmpId}/MPCTemperature`,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getCMPTemperatureById(cmpId: number, id: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCTemperature/' +
                    id, BaseServices.headerOption
            )
            .map(res => res);
    }

    createCMPTemperature(cmpId: number, newTemp: Temperature) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCTemperature',
                newTemp,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // update power leakage
    updateCMPTemperature(cmpId: number, ExistingTemp: Temperature) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCTemperature',
                ExistingTemp,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // delete comment
    deleteCMPTemperature(id: number, cmpId: number) {
        return this.http
            .delete(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCTemperature/' +
                    id, BaseServices.headerOption
            )
            .map(result => result);
    }

    getCMPCommentById(cmpId: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCNote',
                BaseServices.headerOption
            )
            .map(res => res);
    }

    // create comment
    createCMPComment(cmpId: number, newCMPComment: CMPComment) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCNote',
                newCMPComment,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    // update comment
    updateCMPComment(cmpId: number, ExistingCMPComment: CMPComment) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    cmpId +
                    '/MPCNote',
                ExistingCMPComment,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getVisualControl(mpCompleteId: number) {
        let result: any;
        return this.http
            .get(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    mpCompleteId +
                    '/visualControl', BaseServices.headerOption
            )
            .map((res: any) => res);
    }

    //  create instrument
    createVisualControl(newVC: VisualControl) {
        return this.http
            .post(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    newVC.MeasureProtocolCompleteId +
                    '/visualControl',
                newVC,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    //  update instrument
    updateVisualControl(ExistingVC: VisualControl) {
        return this.http
            .put(
                environment.BASE_URL +
                    '/measureProtocolComplete/' +
                    ExistingVC.MeasureProtocolCompleteId +
                    '/visualControl',
                ExistingVC,
                BaseServices.headerOption
            )
            .map(result => result);
    }
    
    reportGenerate(businessId: number, cultureInfo: string, ReportJson) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: ReportJson });
        let url = `${environment.BASE_URL}/export-MeasureProtocolCompleteChecklist/${businessId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    getElectroBusinessInstrumentTypes() {
        return this.http.get(environment.BASE_URL +'/instrument/gettype/electrobusiness/' 
        + BaseServices.BusinessId, BaseServices.headerOption).map(result => result);
    }

    getElectroBusinessInstrumentModels() {
        return this.http.get(environment.BASE_URL +'/instrument/getmodel/electrobusiness/' 
        + BaseServices.BusinessId, BaseServices.headerOption).map(result => result);
    }

    createElectroInstrument(newInst: Instrument) {
        return this.http.post(environment.BASE_URL + '/instrument/electro/create',newInst, BaseServices.headerOption)
            .map(result => result);
    }

    updateElectroInstrument(ExistingInst: Instrument) {
        return this.http.put(environment.BASE_URL + '/instrument/electro/update',ExistingInst, BaseServices.headerOption)
            .map(result => result);
    }

    getElectroBusinessInstruments() {
        return this.http.get(environment.BASE_URL +'/instrument/electro/getbybusiness/' 
        + BaseServices.BusinessId, BaseServices.headerOption).map(result => result);
    }

    saveInstrumentsforMPCompleteElectro(CMPInstruments: CMPInstrument[]){
        return this.http.post(environment.BASE_URL +'/instrument/electro/measureprotocolComplete',
        CMPInstruments, BaseServices.headerOption).map(result => result);
    }

    getElectroCompleteMP(id:number) {
        return this.http.get(environment.BASE_URL +'/measureProtocol/'+0+'/measureProtocolComplete/electro/' 
        + id, BaseServices.headerOption).map(result => result);
    }

    getElectroCompleteMPPreview(id:number) {
        return this.http.get(environment.BASE_URL +'/measureProtocol/'+0+'/measureProtocolComplete/electropreview/' 
        + id, BaseServices.headerOption).map(result => result);
    }
}
