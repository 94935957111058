import { Consumption } from './../../follow-ups/models/consumptions';
export class ConsumptionListDto {
    Id: number;
    Date: Date;
    BrandName: string;
    ModelName: string;
    TypeName: string;
    RegistrationNo: string;
    PreviousKm: string;
    Estimatedkm: string;
    TodaysKm: string;
    NoOfLiters: string;
    LitersUsed: string;
    Comments: string;
}
export class VehicleEditDto {
    Id: number;
    VehicleTypeName: string;
    VehicleBrandName: string;
    VehicleModelName: string
    RegistrationNo: string;
    Status: number;
}
export class ConsumptionEditDto {
    Id: number;
    ConsumptionTypeId: string;
    Date: string;
    CreatedOn: Date;
    ModifiedOn: string;
    PreviousNumber: string;
    TodaysNumber: string;
    EstimatedNumber: string;
    ConsumptionUnitId: string;
    LitersUsed: number;
    NoOfLiters: number;
    Comments: string;
    Status: string;
    BusinessId: string;
    CreatedBy: string;
    ModifiedBy: string;
    VehicleId: number;
}
