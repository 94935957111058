import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBaseModule } from './../common/common.module';
import { AppSharedModule } from './../shared/appShared.module';
import {
    ReceptionControlListResolver,
    ReceptionControlSupplierListResolver,
    ReceptionControlGoodsTypeResolver,
    ReceptionControlEditResolver,
    GoodsTypeByBusinessResolver,
    ReceptionControlDocumentResolver
} from './services/reception-control.resolver.service';
import { ReceptionControlService } from './services/reception-control.service';
import { ReceptionControlListComponent } from './components/reception-control-list.component';
import { ReceptionControlFormComponent } from './components/reception-control-form.component';
import { ReceptionControlComponent } from './reception-control.component';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { HttpClientModule } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';


@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        CommonBaseModule,
        KubaExportModule,
        MyDatePickerModule,
        EcplDocumentExplorerModule
    ],
    exports: [
        ReceptionControlComponent,
        ReceptionControlFormComponent,
        ReceptionControlListComponent
    ],
    declarations: [
        ReceptionControlComponent,
        ReceptionControlFormComponent,
        ReceptionControlListComponent
    ],
    providers: [
        ReceptionControlService,
        ReceptionControlListResolver,
        ReceptionControlSupplierListResolver,
        ReceptionControlGoodsTypeResolver,
        ReceptionControlEditResolver,
        GoodsTypeByBusinessResolver,
        ReceptionControlDocumentResolver
    ]
})
export class ReceptionControlModule { }
