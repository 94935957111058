export class ReceptionControl {
    Id: number;
    BusinessId: number;
    SupplierId: number;
    Quantity: number;
    RemainingQuantity: number;
    Measurement: string
    Description: string;
    GoodsTypeId: number;
    Temperature: string;
    FeatureKey: string;
    AdditionalInformation: string;
    Status: number;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
    CultureInfo:string;
    ReceptionControlCheckListItemDto: ReceptionControlCheckListItemDto[];
    ReceptionDocument: ReceptionDocument[];
}
export class ReceptionControlListDto {
    No: string;
    BusinessId: number;
    Supplier: string;
    Date: Date;
    KindOfGoods: string;
    Received: number;
    QtyLeft: number;
    ReceptionNo: number;
    Status: string;
    Measurement: string;
    OkStatus: boolean;
    DeviationStatus: string;
    CheckListNames: object;
}
export class GoodsType {
    Id: number;
    Name: string;
    Status: string;
    BusinessId: number;
    AppId: number;
    ModifiedBy: number;
    CreatedBy: number;
}
export class ReceptionControlCheckListItemDto {
    Id: number;
    ReceptionControlId: number;
    ReceptionControlCheckListId: number;
    IsOK: number;
    DeviationId: number;

}

export class ReceptionDocument {
    Id: number;
    ReceptionControlId: number;
    FeatureId: number;
    FeatureKey: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: string;
    Status: string;
    CreatedBy: number
    CreatedOn: string;
    ModifiedBy: number
    ModifiedOn: string;
}

export class DeviationFile {
    Id: number;
    Comment: string;
    DeviationId: number;
    OkStatus: boolean;
    Title: string;
    checkListName: string;
    DeviationAttachment : DeviationAttachment[];
}

export class DeviationAttachment {
createdBy: number;
createdOn: Date;
deviationId: number;
fileType: number;
filename: string;
id: number;
mimeType: string;
modifiedBy: number;
modifiedOn: Date;
originalFileName: string;
path: string;
size: number;
status: number;
}


