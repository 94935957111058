import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import 'rxjs/add/operator/map'
import { Observable } from 'rxjs/Observable';
import { Ruh } from '../models/ruh';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class RuhService {

    constructor(private http: HttpClient) { }

    addRuh(ruh: Ruh) {
        return this.http.post(environment.BASE_URL + '/ruh/savenewruh', ruh, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // get the particular risk analysis
    getById(id:any) {
        if (id > 0) {
            let result: any =this.http
            .get(environment.BASE_URL + '/ruh/edit/' + id, BaseServices.headerOption)
            .map(result => result);
            return result;
        }
    }
    updateRuh(ruhupdate: Ruh, key: string) {
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
        let languageId = appSettings.Language.Id;
        let CultureInfo = '';
        switch (languageId) {
            case 1:
                CultureInfo = 'en-GB';
                break;
            case 2:
                CultureInfo = 'no-NO';
                break;
            case 3:
                CultureInfo = 'sv-SE';
                break;
            case 4:
                CultureInfo = 'pl-PL';
                break;

        }
        return this.http
            .put(
                `${environment.BASE_URL}/ruh/update/${CultureInfo}`,
                ruhupdate,
                BaseServices.headerOption
            )
            .map(result => result);

    }
    deleteRuh(id:any) {
        return this.http.delete(environment.BASE_URL + '/ruh/delete/' + id, BaseServices.headerOption)
        .map((response: any) => { return response });
    }
    deleteRuhAttachment(id:any) {
        return this.http.delete(environment.BASE_URL + '/ruh/deleteruhattachment/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    getRuhListByBusiness(id: number) {
        return this.http.get(environment.BASE_URL + '/ruh/ruhbybusiness/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    // get the type cases
    getAllCaseType() {
        return this.http.get(environment.BASE_URL + '/ruh/casetypes/' + BaseServices.BusinessId
            + '/' + BaseServices.roleId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // add the type case
    addTypeCase(data:any) {
        return this.http.post(environment.BASE_URL + '/ruh/casetype', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // update the type case
    updateCaseType(Id: number, data:any) {
        return this.http.put(environment.BASE_URL + '/ruh/casetype/' + Id, data, BaseServices.headerOption)
            .map((response: any) => { return response });

    }
    // delete the typecase
    deleteTypeCase(Id: number) {
        return this.http.delete(environment.BASE_URL + '/ruh/casetype/' + Id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // get the topics
    getAllTopics(caseId: number) {
        return this.http.get(environment.BASE_URL + '/ruh/topic/' + BaseServices.BusinessId + '/' + caseId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // add the topic
    addTopic(data:any) {
        return this.http.post(environment.BASE_URL + '/ruh/topic/save', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    // update the topic
    updateTopic(data: any) {
        return this.http
            .put(environment.BASE_URL + '/ruh/topic/update', data, BaseServices.headerOption)
            .map((response: any) => { return response; });
    }
    // delete the topic
    deleteTopic(Id: number) {
        return this.http
            .delete(
                environment.BASE_URL + '/ruh/topic/delete/' + Id,
                BaseServices.headerOption
            )
            .map((response: any) => {
                return response;
            });
    }

    getcase() {
        return this.http.get(environment.BASE_URL + '/ruh/casetypes', BaseServices.headerOption)
            .map(result => result);
    }

    getFollowupofDepartment(DepId:number)
    {
        return this.http.get(environment.BASE_URL+'/ruh/followupuser/'+DepId, BaseServices.headerOption)
        .map(result=>result);
    }
    getactionplan(id: number, ruhid: number) {
        return this.http.get(environment.BASE_URL + '/ruh/action-plan/get/' + id + '/' + ruhid, BaseServices.headerOption)
            .map(result => result);
    }

    // Get all Action Plan list based on RUH Id
    getAllActionPlan(ruhId: number) {
        return this.http.get(environment.BASE_URL + '/ruh/action-plan/' + BaseServices.BusinessId
            + '/' + ruhId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    addRuhActionPlan(actionplan: any) {
        return this.http
            .post(environment.BASE_URL + '/ruh/action-plan/save', actionplan, BaseServices.headerOption)
            .map((response: any) => { return response; });
    }
    editActionplan(actionid:number,status:any){
        return this.http.put(environment.BASE_URL + '/ruh/action-plan/edit',actionid,status)
        .map((response:any) =>{return response;});
    }
    updateActionplan(actionplan:any){
        return this.http
        .put(environment.BASE_URL + '/ruh/action-plan/update', actionplan, BaseServices.headerOption)
        .map((response: any) => { return response; });
    }
    deleteactionplan(actionid:number){
      return this.http.delete(environment.BASE_URL + '/ruh/action-plan/delete/' + actionid, BaseServices.headerOption)
       .map((response: any) => { return response });
      
    }
    deleteActionAttachment(id:any) {
        return this.http.delete(environment.BASE_URL + '/ruh/delete/actionplanattachment/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
}