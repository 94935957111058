<div class="tab-content">
    <div class="page-title">
        <span translate>
            NEW_MESSAGE
        </span>
    </div>
    <form class="form" [formGroup]="messageForm">
        <div class="form-group">
            <label for="To" class="col-form-label">
                <span translate>To</span>
            </label>
            <div>
                <!-- Table starts -->
                <p-table #dt [value]="toList" dataKey="Id" [(selection)]="selectedUser" scrollable="true"
                    scrollHeight="200px">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 38px;">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>{{'NAME' | translate}}</th>
                        </tr>
                        <tr>
                            <th style="width: 38px;"></th>
                            <th>
                                <input pInputText type="text"
                                    (input)="dt.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                    class="p-column-filter" >
                            </th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-message>
                        <tr>
                            <td style="width: 38px;">
                                <p-tableCheckbox id="selectedOrders" [value]="message">
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <span>{{message.Name}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends -->
            </div>
        </div>
        <div class="form-group">
            <label for="To" class="col-form-label">
                <span translate>FROM</span>
            </label>
            <div>
                <span translate>{{userName}}</span>
            </div>
        </div>
        <div class="form-group">
            <label for="To" class="col-form-label">
                <span translate>SUBJECT</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" pInputText formControlName="Subject" />
            </div>
            <control-messages [control]="messageForm.controls['Subject']"></control-messages>
        </div>
        <div class="form-group">
            <label for="To" class="col-form-label">
                <span translate>MESSAGE</span>
                <span class="required">*</span>
            </label>
            <div>
                <textarea pInputTextarea [rows]="3" [cols]="30" formControlName="Message"></textarea>
            </div>
            <control-messages [control]="messageForm.controls['Message']"></control-messages>
        </div>
        <div class="form-group-50_50">
            <div class="form-group">
                <label for="To" class="col-form-label">
                    <span translate>CHOOSE_SENDING</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <p-calendar formControlName="selectedDate" [minDate]="minDate" [disabledDates]="invalidDates"
                        required [showTime]="true" readonlyInput="true"></p-calendar>
                </div>
                <control-messages [control]="messageForm.controls['selectedDate']"></control-messages>
            </div>
            <div class="form-group">
                <label for="To" class="col-form-label">
                    <span translate>ATTACHMENTS</span>
                </label>
                <div class="mbtm-10">
                    <input type="file" id="files" class="hidden" (change)="filesSelectForLogo($event)" />
                    <label for="files" class="btn btn-outline-primary" translate>UPLOAD</label>
                </div>
                <div *ngIf="uploadStart" class="mbtm-10">
                    <span translate>ATTACHMENT_UPLOADING</span>
                </div>
                <div *ngIf="attachmentName" class="mbtm-10">
                    <span translate>{{attachmentName}}</span>
                </div>
                <div *ngIf="attachement !=null" class="mbtm-10">
                    <img src="{{attachement}}" alt="" style="max-width:100%;">
                </div>
            </div>
        </div>
        <div class="action-btns-wrapper">
            <button class="btn btn-outline-secondary" (click)="gotoBack()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button class="btn btn-outline-primary" (click)="clear()">
                <span translate>CLEAR</span>
            </button>

            <button class="btn btn-primary" (click)="sendMail()" [disabled]="!messageForm.valid">
                <span translate>SEND</span>
            </button>
        </div>
    </form>
</div>
<toaster-component></toaster-component>
<p-dialog header="{{'NOTIFICATION' | translate}}" [(visible)]="showNotification" [modal]="true" width="600"
    [responsive]="true" translate>
    SELECT_USER
</p-dialog>