import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { FSProduction } from './../models/production';
import { ProductionType } from './../models/productionType';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductionService {
    constructor(private http: HttpClient) { }

    // bind production type
    getAllProdType(key: string, id: number) {
        return this.http.get(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/productiontype/${id}`, BaseServices.headerOption)
            .map((result: any) => result);
    }

    // update production Type
    updateProdType(existingCategory: ProductionType, key: number) {
        return this.http.put(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/productiontype`,
            existingCategory, BaseServices.headerOption)
            .map(result => result);
    }

    // adding production Type
    createProdType(newCategory: ProductionType, key: number) {
        return this.http.post(`${environment.BASE_URL}/${BaseServices.apiRoute(key)}/productiontype`,
            newCategory, BaseServices.headerOption)
            .map(result => result);
    }

    // delete production Type
    deleteProdType(Id: number) {
        return this.http.delete(`${environment.BASE_URL}/foodsafety/deleteproductiontype/${Id}`, BaseServices.headerOption)
            .map(result => result);
    }
    // add production
    addProduction(production: FSProduction) {
        return this.http.post(`${environment.BASE_URL}/foodsafety/production`, production,
            BaseServices.headerOption, )
            .map(result => result);
    }

    // updating production list
    updateProduction(ExistingMember: FSProduction) {
        return this.http.put(`${environment.BASE_URL}/foodsafety/production`,
            ExistingMember, BaseServices.headerOption)
            .map(result => result);
    }
    deleteProduction(ids: number[]) {
        return this.http.put(environment.BASE_URL + '/foodsafety/productions', ids, BaseServices.headerOption)
            .map(result => result)
    }
    getProduction(foodsafetyId: number, productionId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/production/${foodsafetyId}/${productionId}`, BaseServices.headerOption)
            .map((result: any) => result ? result : null);
    }
    getAllProduction(foodsafetyId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/production/${foodsafetyId}`, BaseServices.headerOption)
            .map((result: any) => result ? result : null);
    }
    getAllReception(foodsafetyId: number) {
        return this.http.get(`${environment.BASE_URL}/foodsafety/receptioncontrol/${foodsafetyId}`, BaseServices.headerOption)
            .map((result: any) => result);
    }

}
