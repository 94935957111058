import { Component, OnInit } from '@angular/core';

@Component({
   template: `<router-outlet></router-outlet>`,
   
})
export class RiskAnalysisComponent implements OnInit {
    constructor() { }

    ngOnInit() { 
        document.querySelector("body").classList.remove("opened");
    }
}