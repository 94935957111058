import { ToasterComponent } from './../../../_directives/toaster.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseServices } from 'src/app/kuba/kuba.services';

import { ProductionService } from './../services/production.services'; 
import { Production } from './../models/production';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { HelperService } from 'src/app/_services/helper.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'production-list',
    templateUrl: 'production-list.component.html'
})

export class ProductionListComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static:false}) dt: Table;
    //#region variable
    productions: any;
    selectedProduction: any;
    parentId: number;
    additionalData: any;
    ProductionControlForm: FormGroup;
    ClientNumberList: any[];
    ClientNameList: any[];
    startDate: Date;
    endDate: Date;
    ProductionTypeList:any[]

    public startDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    ClientNumber: any;
    ClientName: any;
    ProductionType: any;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * Constructor
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     * @param productionService {ProductionService}
     */
    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private productionService: ProductionService,
        private translate: TranslateService,
        private _fb: FormBuilder,
    ) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.additionalData = {
            fileName: 'Production list',
            header: 'Production list',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Client Number', dataKey: 'ClientNumber' },
                { title: 'Client Name', dataKey: 'ClientName' },
                { title: 'Quantity', dataKey: 'Quantity' },
                { title: 'Production Type', dataKey: 'ProductionType' },
                { title: 'Lot Number', dataKey: 'LotNumber' },
                { title: 'Date of delivery', dataKey: 'DeliveryOn' }
            ]
        };
    }
    //#endregion

    //#region page-event
    /**
     * initialization
     */
    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        this.ProductionControlForm = this._fb.group({
            StartDate: [''],
            EndDate: ['']
        });
        this.productions = <Production[]>this.route.snapshot.data['list'];
        
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.ClientNumberList = [];
            this.ClientNumberList.push(
                { label: val.SELECT, value: null }
            );
            if (this.productions) {
                this.productions.forEach(element => {
                    if(element.ClientNumber != null && element.ClientNumber != '' && this.ClientNumberList.findIndex(x => x.label == element.ClientNumber) <= 0)
                             this.ClientNumberList.push({ label: element.ClientNumber, value: `${element.Id}` });
                });
            }
        }));
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.ClientNameList = [];
            this.ClientNameList.push(
                { label: val.SELECT, value: null }
            );
            if (this.productions) {
                this.productions.forEach(element => {
                    if(element.ClientName != null && element.ClientName != '' && this.ClientNameList.findIndex(x => x.label == element.ClientName) <= 0)
                                this.ClientNameList.push({ label: element.ClientName, value: `${element.Id}` });
                });
            }
        }));
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.ProductionTypeList = [];
            this.ProductionTypeList.push(
                { label: val.SELECT, value: null }
            );
            if (this.productions) {
                this.productions.forEach(element => {
                    if(element.ProductionType != null && element.ProductionType != '' && this.ProductionTypeList.findIndex(x => x.label == element.ProductionType) <= 0)
                                this.ProductionTypeList.push({ label: element.ProductionType, value: `${element.Id}` });
                });
            }
        }));
    }
    //#endregion

/**
     * to filter employee in list
     * @param e
     * @param clDropdown
     */
 onSubChanged(e: any, clDropdown: any) {
    if (e.value) {
        // custom filter datatable
        this.dt.filter(
            clDropdown.selectedOption.label,
            'ClientNumber',
            ''
        );
        this.ClientNumber = e.value;
    } else {
        this.dt.reset();
    }
}
/**
     * to filter employee in list
     * @param e
     * @param nameDropdown
     */
 onnameChanged(e: any, nameDropdown: any) {
    if (e.value) {
        // custom filter datatable
        this.dt.filter(
            nameDropdown.selectedOption.label,
            'ClientName',
            ''
        );
        this.ClientName = e.value;
    } else {
        this.dt.reset();
    }
}
/**
     * to filter employee in list
     * @param e
     * @param typeDropdown
     */
 ontypeChanged(e: any, typeDropdown: any) {
    if (e.value) {
        // custom filter datatable
        this.dt.filter(
            typeDropdown.selectedOption.label,
            'ProductionType',
            ''
        );
        this.ProductionType = e.value;
    } else {
        this.dt.reset();
    }
}
     /**
    * start date change event
    * @param event {IMyDateModel}
    */
   onStartDateChanged(event: IMyDateModel) {
    this.endDateOptions = this.setDateInput(this.endDateOptions, event, 'startDate');
    this.startDate = event.jsdate;
    this.productionDateRangeFilter(this.startDate, this.endDate);
    }

    onEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.startDateOptions = this.setDateInput(this.startDateOptions, event, 'endDate');
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.startDateOptions);
            this.startDateOptions = copyConfig;
        }
        this.endDate = event.jsdate;
        this.productionDateRangeFilter(this.startDate, this.endDate);
    }

    setDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }

      /**
    * TimeTable DateRange Filter
    * @param StartDate {any}
    * @param EndDate {any}
    */
   productionDateRangeFilter(StartDate: any, EndDate: any) {
    let startDate = StartDate ? StartDate : new Date('1800-01-01');
    let endDate = EndDate ? EndDate : new Date();
    this.productions = [];
    let list = this.route.snapshot.data['list'];
    if (list) {
        list = list.filter(x => {
            return (
                new Date(x.DeliveryOn) >= startDate &&
                new Date(x.DeliveryOn) <= endDate
                );
            });
            this.productions.push(...list);
        }
    }
    /**
    //#region methods
    /**
     * Bind the Production details
     */
    bindProductionTable() {
        this.subscriptions.push(this.productionService.getAllProduction(this.parentId).subscribe((result: any) => {
            this.productions = result;
        }))
    }
    /**
     * Delete the Production Details
     */
    deleteProduction() {
        let productionIds: any = [];
        if (this.selectedProduction.length > 0) {
            this.selectedProduction.forEach((production: any) => {
                productionIds.push(production.Id);
            });
            this.confirmationService.confirm({
                message: this.translate.instant('DELETE_SELECTED'),
                accept: () => {
                    this.subscriptions.push(this.productionService.deleteProduction(productionIds).subscribe((isProductionDeleted: any) => {
                        if (isProductionDeleted) {
                            this.bindProductionTable();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
                    this.selectedProduction = [];
                },
                reject: () => {
                    this.selectedProduction = [];
                }
            });
        } else {
            alert('Please select atleast one production to delete.');
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}