import { User } from './../../businesses/models/businessList';
import { SelectItem } from 'primeng/api';
import { PdfSetting, HelperService } from './../../../_services/helper.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FollowUpMessageService } from './../services/message.services';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'message-inbox',
    templateUrl: 'message-inbox.component.html'
})
export class MessageInboxComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('dt',{static: false}) dt: Table;
    FromUser: SelectItem[];
    inboxData: any[];
    selectedMessages = [];
    isSelectedValue = false;
    Status: SelectItem[];
    userName: string;
    additionalData: any;
    roleId: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private followUpMessageService: FollowUpMessageService,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
        this.additionalData = {
            fileName: val.MESSAGE_INBOX,
            header: 'Message-Inbox',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'From', dataKey: 'FromuserName' },
                { title: 'Subject', dataKey: 'Subject' },
                { title: 'Message', dataKey: 'Message' },
                { title: 'FileName', dataKey: 'FileName' },
                { title: 'Received Date', dataKey: 'SendDate' }
            ]
        };
    }));
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.roleId = BaseServices.roleId;

        this.subscriptions.push(this.translate.stream('MESSAGE_STATUS_DROP').subscribe(val => {
            this.Status = [];
            this.Status.push(
                { label: val.ALL, value: null },
                { label: val.READ, value: false },
                { label: val.UNREAD, value: true }
            );
        }));
        this.inboxData = this.route.snapshot.data['message'];
    }

    deleteMessages() {
        let contactIds: any = [];
        if (this.selectedMessages.length > 0) {
            this.selectedMessages.forEach((e: any) => {
                contactIds.push(e.Id);
            });
            this.confirmationService.confirm({
                message:
                    'Are you sure that you want to delete the selected records?',
                accept: () => {
                    this.subscriptions.push(this.followUpMessageService
                        .deleteMessages(contactIds)
                        .subscribe(() => {
                            this.subscriptions.push(this.followUpMessageService
                                .getMessages(BaseServices.UserId)
                                .subscribe(res => (this.inboxData = res)));
                            this.toasterComponent.callToastDlt();
                        }));
                }
            });
            this.selectedMessages = [];
        } else {
            alert('Please select one record');
        }
    }

    /**
     * row select event
     * @param selectedValue
     */
    onRowSelect(selectedValue:any) {
        if (this.selectedMessages) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }
    /**
     * row unselect event
     */
    onRowUnselect(selectedValue:any) {
        if (this.selectedMessages.length > 0) {
            this.isSelectedValue = true;
        } else {
            this.isSelectedValue = false;
        }
    }

    updateStatus(id: number) {
        this.subscriptions.push(this.followUpMessageService
            .updateStatus(id)
            .subscribe((data: any) => { }));
    }

    /**
     * status filter
     */
    onStatusChanged(event: any, statusDropdown: any) {
        this.dt.filter(
            statusDropdown.selectedOption.value,
            'Status',
            'equals'
        );
    }
    /**
     * from dropdown change event
     */
    onFromUserChange(event: any, fromUserDropdown: any) {
        if (event.value) {
            this.dt.filter(
                fromUserDropdown.selectedOption.label,
                'FromuserName',
                'equals'
            );
        } else {
            this.dt.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
