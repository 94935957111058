import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureKey } from 'src/app/_models';

@Component({
    selector: 'app-quality-system-view',
    templateUrl: './quality-system-view.component.html'
})
export class QualitySystemViewComponent implements OnInit {


    pageTitle: string;
    additionalFeatures: any;
    enabledAdditionalFeatures: any;
    icId: number;
    initialPage: any;
    icObject: any;
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.icId = this.activatedRoute.snapshot.params['id'];
        let allAdditionalFeatures = JSON.parse(sessionStorage.getItem('workingModule')).children;
        allAdditionalFeatures.forEach(element => {
            if (element.id === FeatureKey.SAFETY_JOB_ANALYSIS) {
                element.translationKey = 'IC_SJA';
            }
        });
        this.icObject = this.activatedRoute.snapshot.data['edit'];
        this.pageTitle = this.icObject.Title;
        sessionStorage.setItem('InternalCntrlName', this.pageTitle);
        this.enabledAdditionalFeatures = allAdditionalFeatures.filter(element => element.checked || element.IsDefault);
        this.enabledAdditionalFeatures = this.enabledAdditionalFeatures
            .filter(x => x.id !== 142); // Hide details tab #KW-276
        if (this.enabledAdditionalFeatures.length > 0) {
            this.route.navigate([this.enabledAdditionalFeatures[0].linkText, { Id: this.icId }],
                { relativeTo: this.activatedRoute });
        }
    }

    ngOnInit() { document.querySelector("body").classList.remove("opened"); }

    BackToZone() {
        // let foodSafety = this.activatedRoute.snapshot.data['list'];
        // if (foodSafety.length == 1) {
            sessionStorage.setItem('checkParent', this.activatedRoute.snapshot.data['parent']);
            this.route.navigate(['../../list'], { relativeTo: this.activatedRoute });
        // }
        // this.route.navigate(['../../list'], { relativeTo: this.activatedRoute });
    }

}

