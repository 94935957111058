import { TimeLogResolver } from './services/log.resolver';
import { LogComponent } from './log.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { NgModule } from '@angular/core';
import { LogListComponent } from 'src/app/kuba/log/components/log-list.component';
import { TimeLogService } from 'src/app/kuba/log/services/log.service';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { SharedModule } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        TableModule,
        CalendarModule,
        FileUploadModule,
        SharedModule,
        PaginatorModule,
        DropdownModule
    ],
    exports: [LogListComponent, LogComponent],
    declarations: [LogListComponent, LogComponent],
    providers: [TimeLogService, TimeLogResolver]
})
export class LogListModule { }
