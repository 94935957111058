<div class="tab-content">
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #contactsTable
      [value]="employeeWithlogin"
      [rows]="10"
      dataKey="Id"
      [(selection)]="selectedEmployees"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="CustomerContactsLogin"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="table-options">
            <div class="pull-right">
              <div class="action-btns-group">
                <kuba-export
                  [reportData]="
                    contactsTable.filteredValue || contactsTable.value
                  "
                  [additionalData]="additionalData"
                ></kuba-export>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="Name">{{ "EMPLOYEE_NAME" | translate }}</th>
          <th pSortableColumn="Category">{{ "CATEGORY" | translate }}</th>
          <th>{{ "BUSINESS" | translate }}</th>
          <th>{{ "USERNAME" | translate }}</th>
          <th>{{ "MOBILE" | translate }}</th>
          <th>{{ "ADDITIONAL_ROLE" | translate }}</th>
        </tr>
        <tr>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter($event.target.value, 'Name', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              [options]="category"
              (onChange)="onCategoryChanged($event, cd)"
              #cd
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              [options]="businesses"
              (onChange)="onBusinessChanged($event, ed)"
              #ed
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter(
                  $event.target.value,
                  'UserName',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter($event.target.value, 'Mobile', 'contains')
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th>
            <p-dropdown
              [options]="roles"
              (onChange)="onRoleChanged($event, ad)"
              #ad
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-employee>
        <tr>
          <td>
            <span>
              <a
                href="javascript:void(0);"
                (click)="openEmployeeInNewWindow(employee)"
                >{{ employee.Name }}</a
              >
            </span>
          </td>

          <td>
            <span>{{ employee.Category }}</span>
          </td>
          <td>
            <span>
              <a
                href="javascript:void(0);"
                (click)="openBusinessInNewWindow(employee)"
                >{{ employee.BusinessName }}</a
              >
            </span>
          </td>
          <td>
            <span>
              <a
                [routerLink]="[
                  '../../../business/edit/' +
                    employee.BusinessId +
                    '/user/edit/',
                  employee.UserId
                ]"
                [queryParams]="{ Title: 'Employee' }"
                >{{ employee.UserName }}</a
              >
            </span>
          </td>
          <td>
            <span>{{ employee.Mobile }}</span>
          </td>
          <td>
            <span>{{ employee.RoleName }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="6">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>

<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
