import { NgModule, ModuleWithProviders } from '@angular/core';
import { ElectroComponent } from './electro.component';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { 
    ElectroOrganizationComponent,
    ElectroLARComponent,
    ElectroListComponent,
    ElectroViewComponent,
    EditPrintManualComponent,
    ElectroFormComponent,
    InspectionChartOverview,
    InspectionFormComponent,
    InspectionComponent,
    ElectroContractInfoComponent,
    InspectionFollowupViewComponent,
    InspectionOverviewComponent,
    InspectionFollowupTabComponent,
    DeviationFormComponent,
    DeviationOverviewComponent,
    InspectionReportTemplateComponent,
    ContractTabComponent,
    ContractDetailComponent,
    ElectroInfoViewerComponent,
    SettingsFormComponent,
    DeviationSettingsComponent,
    InspectionReportSettingsComponent,
    InstallationFormOverviewComponent,
    DocumentAssessmentComponent,
    RiskAssessmentComponent,
    DistributionOverviewComponent,
    CourseOverviewComponent,
    FilreAlarmComponent,
    EmergencyLightingComponent,
    ElectroChecklistCompletionComponent
} from './components';
import {
    ElectroListByBusinessResolver,
    ElectroEditResolver,
    InspectionListByBusinessResolver,
    ContractWorkersResolver,
    ContractContentResolver,
    OrganizationListByBusinessResolver,
    InternalControlListByBusinessResolver,
    LawsListByBusinessResolver,
    AssignedWorkersResolver,
    AssignedWorkersByInspectionResolver,
    AssignedTasksResolver,
    InspectionEditResolver,
    RegulationsListByBusinessResolver,
    DeviationEditResolver,
    DeviationListByBusinessResolver,
    ReportCheckListResolver,
    DevInspectionResolver,
    ElectroInfoResolver,
    ElectroContractDetailById,
    ElectroManualListResolver,
    ElectroEnabledManualsResolver,
    InspecitonChartResolver,
    EnabledInstallationFormsResolver,
    InstallationFormsBuildingsTreeResolver,
    EnabledChecklistResolver,
    ElectroSettingsResolver,
    ElectroArchiveTreeDataResolver,
    ElectroChecklistTemplateResolver,
    ContractBuildingsResolver,
    ChecklistBuildingtreeResolver,
    ElectroCompletionChecklistDataResolver,
    InspectionByContractResolver,
    DeviationsbyInspectionResolver,
    DeviationListByContractResolver,
    ElectroEnabledFeaturesResolver,
    InspectionEnabledTasksResolver,
    EnabledReportsResolver,
    ControlScopeBuildingsResolver,
    InspectionScopeResolver,
    InstallerInspectionByContractResolver,
    ClientInspectionByContractResolver,
    BuildingsByInspectionResolver,
    EnabledFormsResolver,
    ContractClientContactInfoResolver,
    ViolatedParaResolver,
    ClientNumListResolver,
    ClientUsersBySelfInspectionResolver,
    ElectroMeasureProtocolResolver,
    DeviationClientInfoResolver
} from './services/electro.resolver';
import { ElectroService } from './services/electro.services';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '../shared';
import { EcplArticleViewerModule } from 'src/app/shared/ecpl-article-viewer';
import { EcplArticleViewerComponent } from 'src/app/shared/ecpl-article-viewer/ecpl-article-viewer.component';
import { ElectroDocumentService } from './services/electro-document.services';
import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { EcplChecklistViewerComponent } from 'src/app/shared/ecpl-checklist-viewer/ecpl-checklist-viewer.component';
import { EcplChecklistViewerModule } from 'src/app/shared/ecpl-checklist-viewer';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';

import { InstallationFormsComponent } from './components/installation/installation-forms.component';
import { ElectroInstallationService } from './services/electro-installation.services';
import { ControlScopeResolver, InstallationBuildingsResolver, CourseOverviewResolver, FireAlarmSystemResolver, EmergencyLightingResolver, FireSprinklerResolver, DistributionsOverviewResolver, RiskAssessmentResolver,DocumentAssessmentResolver, FormsWithTemplateResolver, ElectroBusinessInstTypeResolver, ElectroBusinessInstModelResolver, ElectroBusinessInstrumentsResolver } from './services/electro-installation.resolver';
import { EcplDocumentTreeModule } from 'src/app/shared/ecpl-document-tree/ecpl-document-tree.module';
import { FormsModule } from '@angular/forms';
import { ElectroArchiveComponent } from './components/electro-archive.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ElectroFactsAbtCompanyComponent } from './components/shared/electro-facts-company/electro-facts-abt-company.component';
import { DeviationOverviewListComponent } from './components/deviation/deviation-overview-list.component';
import { ContractContentComponent } from './components/Settings/contract-content/contract-content.component';
import { MeasureProtocolModule } from '../measure-protocol/measure-protocol.module';
import { MeasureProtocolPreviewComponent } from './components/shared/measure-protocol-preview/measure-protocol-preview.component';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        RouterModule,
        EcplTabNavigationModule,
        EcplDocumentExplorerModule,
        CommonModule,
        HttpClientModule,
        AppSharedModule,
        TranslateModule,
        EcplArticleViewerModule,
        EcplChecklistViewerModule,
        EcplDocumentTreeModule,
        ButtonModule,
        SelectButtonModule,
        KubaExportModule,
        MyDatePickerModule,
        FormsModule,
        TabsModule.forRoot(),
        MeasureProtocolModule
    ],
    exports: [EcplArticleViewerComponent,
        EcplChecklistViewerComponent
    ],
    declarations: [
        ElectroComponent,
        ElectroOrganizationComponent,
        ElectroLARComponent,
        EditPrintManualComponent,
        ElectroListComponent,
        ElectroViewComponent,
        ElectroFormComponent,
        ElectroContractInfoComponent,
        InspectionFormComponent,
        InspectionComponent,
        InspectionChartOverview,
        InspectionFormComponent,
        InspectionFollowupViewComponent,
        InspectionFollowupTabComponent,
        InspectionOverviewComponent,
        DeviationFormComponent,
        DeviationOverviewComponent,
        InspectionReportTemplateComponent,
        ContractTabComponent,
        ContractDetailComponent,
        ElectroInfoViewerComponent,
        InstallationFormOverviewComponent,
        InstallationFormsComponent,
        SettingsFormComponent,
        DeviationSettingsComponent,
        InspectionReportSettingsComponent,
        DocumentAssessmentComponent,
        FilreAlarmComponent,
        RiskAssessmentComponent,
        ElectroArchiveComponent,
        DocumentAssessmentComponent,
        CourseOverviewComponent,
        DistributionOverviewComponent,
        EmergencyLightingComponent,
        ElectroChecklistCompletionComponent,
        ElectroFactsAbtCompanyComponent,
        DeviationOverviewListComponent,
        ContractContentComponent,
        MeasureProtocolPreviewComponent
    ],
    providers: [
        ElectroService,
        ElectroDocumentService,
        ElectroListByBusinessResolver,
        ElectroEditResolver,
        ContractWorkersResolver,
        ContractContentResolver,
        InspecitonChartResolver,
        InspectionListByBusinessResolver,
        AssignedWorkersResolver,
        AssignedWorkersByInspectionResolver,
        AssignedTasksResolver,
        InspectionEditResolver,
        DeviationListByBusinessResolver,
        DeviationEditResolver,
        DevInspectionResolver,
        OrganizationListByBusinessResolver,
        ElectroInfoResolver,
        ElectroContractDetailById,
        InternalControlListByBusinessResolver,
        LawsListByBusinessResolver,
        RegulationsListByBusinessResolver,
        ReportCheckListResolver,
        ElectroManualListResolver,
        ElectroEnabledManualsResolver,
        EnabledInstallationFormsResolver,
        InstallationBuildingsResolver,
        ElectroInstallationService,
        ControlScopeResolver,
        FireAlarmSystemResolver,
        RiskAssessmentResolver,
        ElectroArchiveTreeDataResolver,
        CourseOverviewResolver,
        EmergencyLightingResolver,
        FireSprinklerResolver,
        InstallationFormsBuildingsTreeResolver,
        DistributionsOverviewResolver,
        EnabledChecklistResolver,
        DocumentAssessmentResolver,
        ElectroSettingsResolver,
        ElectroChecklistTemplateResolver,
        ContractBuildingsResolver,
        ChecklistBuildingtreeResolver,
        ElectroCompletionChecklistDataResolver,
        InspectionByContractResolver,
        DeviationsbyInspectionResolver,
        DeviationListByContractResolver,
        ElectroEnabledFeaturesResolver,
        ControlScopeBuildingsResolver,
        InspectionScopeResolver,
        InspectionEnabledTasksResolver,
        EnabledReportsResolver,
        ControlScopeBuildingsResolver,
        InstallerInspectionByContractResolver,
        ClientInspectionByContractResolver,
        BuildingsByInspectionResolver,
        EnabledFormsResolver,
        ContractClientContactInfoResolver,
        ViolatedParaResolver,
        ClientNumListResolver,
        ClientUsersBySelfInspectionResolver,
        FormsWithTemplateResolver,
        ElectroBusinessInstTypeResolver,
        ElectroBusinessInstModelResolver,
        ElectroBusinessInstrumentsResolver,
        ElectroMeasureProtocolResolver,
        DeviationClientInfoResolver
    ],
})
export class ElectroModule { 
    static forRoot(): ModuleWithProviders<any> {
        return {
          ngModule: ElectroModule
        };
      }
}
