import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ReceptionControl } from './../../reception-control/models/reception-control';
import { Production } from './../models/production';
import { ProductionType } from './../models/productionType';
import { ProductionService } from './production.services';

@Injectable()
export class ProductionListResolver implements Resolve<Production[]> {
    constructor(private backend: ProductionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Production[]> {
        return this.backend.getAllProduction(+route.params['Id']);
    }
}
@Injectable()
export class ProductionEditResolver implements Resolve<Production> {
    constructor(private backend: ProductionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Production> {
        if (+route.params['prodId'] > 0) {
            return this.backend.getProduction(+route.parent.parent.params['id'], +route.params['prodId']);
        } else { return null; }
    }
}
@Injectable()
export class ProductionTypeListResolver implements Resolve<ProductionType[]> {
    constructor(private backend: ProductionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ProductionType[]> {
        return this.backend.getAllProdType(route.parent.parent.data['parent'], +route.parent.parent.params['id']);
    }

}
@Injectable()
export class ReceptionDropdownResolver implements Resolve<ReceptionControl[]> {
    constructor(private backend: ProductionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ReceptionControl[]> {
        return this.backend.getAllReception(+route.parent.parent.params['id']);
    }
}
