import { NotesEditResolver } from './services/notes.resolver.service';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';

import { NotesComponent } from './notes.component';
import { DetailsComponent } from './components/user-signin/details.component';
import { BusinessListResolver } from "src/app/kuba/businesses/services/business.resolver.service";
export const notesRoutes: Route[] = [
    {
        path: 'notes',
        component: NotesComponent,

    },
    {
        path: 'notedetails',
        component: DetailsComponent,
        resolve: {
            business: BusinessListResolver
        }
    },
];