import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KubaServices } from '../../kuba.services';
import { ActivatedRoute , Params } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    selector: 'helpsupport-tab',
    template: `<p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>`
})

export class HelpSupportTabsComponent implements OnInit {
    @Output() onTabClick = new EventEmitter<string>();
    items: MenuItem[];
    activeItem: MenuItem;
    categoryId: Number;
    private subscriptions: Subscription[] = [];
    private language: string = this.translator.currentLang ? this.translator.currentLang : 'en';
    constructor(private translator: TranslateService, private kubaServices: KubaServices, private activatedRoute: ActivatedRoute) {
        this.subscriptions.push(this.kubaServices.getLanguage$.subscribe(
            lang => {
                this.language = lang;
            }));
    }


    ngOnInit() {
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let nid = params['id'];
            this.categoryId = +nid;
        }))
        this.subscriptions.push(this.translator.stream('CATEGORY_NAMES').subscribe(val => {
            this.BindList();
        }))
        switch (this.categoryId) {
            case 2:
             this.activeItem = this.items[0];
             break;
            case 3:
             this.activeItem = this.items[1];
             break;
             case 4:
             this.activeItem = this.items[2];
             break;
            default:
            this.activeItem = this.items[0];
                break;
        }
    }

    menuChange(type: string, item: any) {
        this.activeItem = item;
        this.onTabClick.emit(type);
    }

    BindList() {

        this.items = [];
            this.items = [
                { label: this.translator.instant('ARTICLES'), icon: 'pi pi-book', command: (event) => this.menuChange('articles', event.item) },
                { label: this.translator.instant('VIDEOS'), icon: 'pi pi-video', command: (event) => this.menuChange('videos', event.item) },
                { label: this.translator.instant('QUESTIONSANDANSWERS'), icon: 'pi pi-question-circle', command: (event) => this.menuChange('questions', event.item) }
            ];
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
