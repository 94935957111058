
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { FdvDocumentVVSServices } from './services/fdv-doc-vvs.services';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBaseModule } from './../common/common.module';
import { FdvDocumentVVSComponent } from './../fdv-document-vvs/fdv-doc-vvs.component';
import { FdvDocVVSComponent } from './../fdv-document-vvs/components/fdv-doc-vvs.component';
import { EcplDocumentExplorerModule } from '../../shared/ecpl-document-explorer';
import { EcplArticleViewerModule } from './../../shared/ecpl-article-viewer/ecpl-article-viewer.module';
import { EcplArticleViewerComponent } from '../../shared/ecpl-article-viewer/ecpl-article-viewer.component';
import { DialogModule } from 'primeng/dialog';
@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule, 
        CommonBaseModule,
        DialogModule,
        EcplDocumentExplorerModule,
        EcplArticleViewerModule
    ],
    exports: [DialogModule,EcplArticleViewerComponent],
    declarations: [FdvDocumentVVSComponent, FdvDocVVSComponent],
    providers: [FdvDocumentVVSServices]
})
export class FdvDocumentVVSModule { }
