import { FeatureKey } from './../../../_models/feature';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { VehicleServices } from 'src/app/kuba/vehicle/services/vehicle.service';
import { BaseServices } from './../../kuba.services';
import { Options } from './../../../_models/options';
import { HelperService } from './../../../_services/helper.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ActivityList, ActivitySchedule } from '../models/index';
import { TaskService } from '../services/task.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { CommonProperty } from './../../businesses/models/businessProperty';
import { Execution } from './../../follow-ups/models/activities';
import { TranslateService } from '@ngx-translate/core';
import { KubaComponent } from 'src/app/kuba/kuba.component';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'new-task',
    templateUrl: 'task-new.component.html',
    styleUrls: ['task-new.component.css']
})
export class NewTaskComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    //#region variable

    options = [
        new Options(8, 'OPEN'),
        new Options(9, 'DONE'),
        new Options(10, 'REJECTED')
    ];
    additionalData: any;
    upConfig: UploaderConfig;
    TaskForm: FormGroup;
    repetitions: SelectItem[];
    executions: SelectItem[];
    followups: SelectItem[];
    taskId: number;
    task: any;
    taskData = new ActivityList();
    newUploadedFiles: any = [];
    uploadedFiles: any = [];
    parentId: number;
    parentKey: any;
    activityScheduleId: number;
    editUrl: string;
    CreatorName: string;
    CreaterId: number;
    CreatedOn: any;
    time: string;
    taskLog: any;
    isRepetitionSelect = true;
    brand: string;
    model: string;
    regNumber: string;
    type: string;
    isVehicle = false;
    loading = false;
    ishidden = false;
    isGuest = false;
    isParentId: number;
    isFutureRecurringDisabled = false;
    RepetitionId = 0;
    FeatureId: number;
    ChecklistData: SelectItem[];
    disableRepetation = false;
    disableChecklistDeopDown = false;
    completeChecklistDetails: any;
    FollowUpDays: any[];
    currentDate: Date;
    currentDeadLine: Date;
    dateDiff: number;
    department: SelectItem[];
    displayDialog = false;
    checklistDetails: any;
    checklistTile: any;
    isChecklistSelected = false;
    showCompleteCheckListButton = true;
    showChecklist = true;
    isSJAActivity = false;
    title = '';
    private subscriptions: Subscription[] = [];
    /**
     * Date picker configuration option
     */
    public DeadLineDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableUntil: this.getCurrentDate()
    };
    approverRights: boolean;
    //#endregion
    //#region methods
    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1
        };
    }
    //#endregion
    //#region constructor

    /**
     * constructor
     * @param taskService {TaskService}
     * @param fb {FormBuilder}
     * @param location {Location}
     * @param route {ActivatedRoute}
     * @param router {Router}
     */
    constructor(
        private taskService: TaskService,
        private fb: FormBuilder,
        protected location: Location,
        private route: ActivatedRoute,
        private vehicleService: VehicleServices,
        private translate: TranslateService,
        private confirmationService: ConfirmationService,
        private router: Router,
    ) {
        this.taskId = route.snapshot.params['tid']
            ? route.snapshot.params['tid']
            : 0;
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            ParentId: this.parentId,
            ChildId: this.taskId ? this.taskId : 0,
            ModuleName: 'TaskManager'
        };

        this.upConfig = {
            title: 'MORE_ATTACHMENTS',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: (+BaseServices.roleId === 4) ? false : true,
            showDeleteButton: (+BaseServices.roleId === 4) ? false : true,
            uploaderUri: `${environment.BASE_URL}/${BaseServices.apiRoute(
                this.parentKey
            )}/file-upload`,
            addtionalData: this.additionalData
        };
        this.taskLog = route.snapshot.data['taskLog'];
        this.TaskForm = this.fb.group({
            Status: ['New Issue'],
            Title: ['', [Validators.required, ValidationService.noWhitespaceValidator]],
            EndDate: ['', Validators.required],
            RepetitionId: [0],
            Description: [''],
            NotifyBeforeDeadLine: ['0', [], this.notificationdays.bind(this)],
            NotificationBeforeFollowUp: [7],
            SendUserData: [''],
            futureRecurring: [false],
            ExecutionUser: ['', Validators.required],
            FollowupUser: ['', Validators.required],
            checklistId: [],
            Department: [],
            LinkUrl: [''],
        });
        this.taskService.getByTitle();
    }
    //#endregion

    //#region page-event

    /**
     * edit task manager
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = true;
        }
        if (this.parentKey === 'FDV' && this.parentKey === 'FS') {
            this.ishidden = true;
        }
        this.bindDropdown();
        this.bindChecklistDropDown();

        if (this.parentKey === 'VEHICLE') {
            this.isVehicle = true;
            this.subscriptions.push(this.vehicleService
                .getVehiclesById(+this.parentId)
                .subscribe((result: any) => {
                    if (result) {
                        this.brand = result.BrandName;
                        this.model = result.ModelName;
                        this.regNumber = result.RegistrationNo;
                        this.type = result.VehicleTypeName;
                    }
                }));
        } else {
            this.isVehicle = false;
        }

        if (
            this.parentKey === 'PROJECT' ||
            this.parentKey === 'FDV' ||
            this.parentKey === 'IC' ||
            this.parentKey === 'FS' ||
            this.parentKey === 'VEHICLE'|| 
            this.parentKey === 'OS' || 
            this.parentKey === 'QS'
        ) {
            this.isRepetitionSelect = false;
        }
        let deadLine: any;
        let task = this.route.snapshot.data['edit'];
        task.Status =
        task.Status && task.Status === 7 ? 8 : task.Status;
        task.Status = task.Status.toString();
        if (task) {
            if(task.ExecutionUsers.length != 0 || task.FollowupUsers.length == 0){
                this.approverRights = true;
            }
            for (let i = 0; i < task.ExecutionUsers.length; i++) {
                if(task.ExecutionUsers[i].Id == BaseServices.UserId){
                    this.approverRights = true;
                }
            }
            for (let i = 0; i < task.FollowupUsers.length; i++) {
                if(task.FollowupUsers[i].Id == BaseServices.UserId){
                    this.approverRights = true;
                }
            }
            
            if (window.name === 'Remote') {
                if(task.ExecutionUsers.length == 0 || task.FollowupUsers.length == 0){
                    this.approverRights = true;
                }
                for (let i = 0; i < task.ExecutionUsers.length; i++) {
                    if(task.ExecutionUsers[i].Id != BaseServices.UserId){
                        this.approverRights = true;
                    }
                }
                for (let i = 0; i < task.FollowupUsers.length; i++) {
                    if(task.FollowupUsers[i].Id != BaseServices.UserId){
                        this.approverRights = true;
                    }
                }

            }

            this.subscriptions.push(this.translate.stream('TASKMANAGER_NUM').subscribe(val => {

                this.title = task.Title + ' (' + val.TASKMANAGER_NO + ': ' + task.No + ') ';
            }));
            this.isSJAActivity = task.IsSJAActivity;
            if (task.RepetitionId) {
                this.disableRepetation = true;
            }
            if (task.ActivityScheduleDto != null) {
                this.isParentId = task.ActivityScheduleDto.ParentId;
                this.currentDeadLine = task.ActivityScheduleDto.EndDate ?
                    new Date(task.ActivityScheduleDto.EndDate) : null;
            }
            this.RepetitionId = task.RepetitionId;
            if (task.IsFutureRecurring === true) {
                this.isFutureRecurringDisabled = true;
            }
            deadLine = task.ActivityScheduleDto
                ? HelperService.formatInputDate(
                    task.ActivityScheduleDto.EndDate
                )
                : null;
            let deadLineDate: Date = task.ActivityScheduleDto
                ? HelperService.formatDate(task.ActivityScheduleDto.EndDate)
                    ? new Date(
                        HelperService.formatDate(
                            task.ActivityScheduleDto.EndDate
                        )
                    )
                    : null
                : null;
            this.taskId = task.Id;
            if (task.ChecklistId == null) {
                this.showCompleteCheckListButton = false;
            }
            this.getCompleteChecklistDetails(this.taskId, task);
            this.activityScheduleId = task.ActivityScheduleId;
            this.CreaterId = task.CreatedBy;
            this.CreatorName = task.CreatorName;
            this.CreatedOn = HelperService.formatDate(task.CreatedOn);
            this.time = new Date(task.CreatedOn).toLocaleTimeString();
            this.isRepetitionSelect = task.RepetitionId > 0 ? false : true;
            if (task.ActivityAttachment) {
                task.ActivityAttachment.forEach((element: any) => {
                    this.uploadedFiles.push(
                        HelperService.switchKeysCase(element, null, 'L')
                    );
                });
                this.uploadedFiles = task.ActivityAttachment.filter(
                    x => x.status !== 0
                );
            }
            task.ExecutionUser = [];
            if (task.ExecutionUsers) {
                task.ExecutionUsers.forEach((element: any) => {
                    task.ExecutionUser.push(element.Id);
                });
            }
            task.FollowupUser = [];
            if (task.FollowupUsers) {
                task.FollowupUsers.forEach((element: any) => {
                    task.FollowupUser.push(element.Id);
                });
            }
            if (task.Status === 7) {
                this.showChecklist = false;
            }



            this.task = {
                Status: task.Status === 7 ? task.Status = '8' : task.Status.toString(),
                EndDate: deadLine,
                Title: task.Title,
                NotifyBeforeDeadLine: task.ActivityScheduleDto
                    ? task.ActivityScheduleDto.NotifyBeforeDeadLine == null ? 0 :
                        task.ActivityScheduleDto.NotifyBeforeDeadLine
                    : 0,
                NotificationBeforeFollowUp: task.ActivityScheduleDto
                    ? task.ActivityScheduleDto.NotifyBeforeFollowup : 7,
                RepetitionId: task.RepetitionId,
                Description: task.Description,
                SendUserData: task.SendUserData,
                futureRecurring: task.IsFutureRecurring,
                ExecutionUser: task.ExecutionUser,
                FollowupUser: task.FollowupUser,
                checklistId: task.ChecklistId,
                Department: task.BusinessDepartmentId,
                LinkUrl: task.LinkUrl
            };
            (<FormGroup>this.TaskForm).setValue(this.task, { onlySelf: true });


            this.subscriptions.push(this.taskService.getActivityLog(this.taskId).subscribe((taskLog: any) => {
                if (taskLog) {
                    taskLog.forEach(function (entry: any) {
                        if (entry.ColumnName === 'Status') {
                            switch (entry.OldValue) {
                                case '7':
                                    entry.OldValue = 'NEW_ISSUE';
                                    break;
                                case '8':
                                    entry.OldValue = 'OPEN';
                                    break;
                                case '9':
                                    entry.OldValue = 'DONE';
                                    break;
                                case '10':
                                    entry.OldValue = 'REJECTED';
                                    break;
                                default:
                                    entry.OldValue = entry.OldValue;
                            }
                        }
                    });

                    if (taskLog) {
                        this.taskLog = taskLog.filter(x => (x.OldValue !== ''
                            || x.FieldName !== 'CHECKLIST'));
                    }

                }
            }));
        }
        this.FeatureId = this.parentKey === 'PROJECT' ? FeatureKey.PROJECT : 
                         this.parentKey === 'FDV' ? FeatureKey.FDV : 
                         this.parentKey === 'IC' ? FeatureKey.INTERNAL_CONTROL : 
                         this.parentKey === 'FS' ? FeatureKey.FOOD_SAFETY : 
                         this.parentKey === 'VEHICLE' ? FeatureKey.VEHICLES :
                         this.parentKey === 'FRAMEWORK' ? FeatureKey.FRAMEWORK_AGREEMENT : 
                         this.parentKey === 'SA' ? FeatureKey.SERVICE_AGREEMENT : 
                         this.parentKey == 'KUNDE_EL' ? FeatureKey.KUNDE_EL : 
                         this.parentKey === 'OS' ? FeatureKey.OTHER_SYSTEMS : 
                         this.parentKey == 'QS' ? FeatureKey.QUALITY_SYSTEMS : 
                         0;
    }

    //#endregion

    /**
     * file upload events
     * @param event {any}
     */

    saveUploaded(event: any) {
        if (event) {
            let eventType = event.eventName;
            let keyBindings = {
                Originalname: 'OriginalFileName',
                FileTypeId: 'FileType'
            };
            let deviationDoc = Object.assign({}, event, this.additionalData);
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newUploadedFiles = this.newUploadedFiles.filter(
                            x => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            x => x.path !== event.item.data.path
                        );
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.taskService
                            .deleteActivityAttachment(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        x => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    deviationDoc.id = 0;
                    deviationDoc.createdBy = BaseServices.UserId;
                    deviationDoc.status = 1;
                    this.newUploadedFiles.push(
                        HelperService.switchKeysCase(deviationDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    //#region control-event

    /**
     * save and update task manager
     */
    saveChanges() {
        let languageId = sessionStorage.getItem('languageId');
        if (this.TaskForm.valid) {
            this.loading = true;
            this.taskData.IsFutureRecurring = this.TaskForm.value.futureRecurring ? this.TaskForm.value.futureRecurring : false;
            this.taskData.Id = this.taskId ? this.taskId : 0;
            this.taskData.BusinessId = BaseServices.BusinessId;
            this.taskData.Status = this.TaskForm.value.Status;
            this.taskData.FeatureKey = this.parentId;
            this.taskData.FeatureId = this.parentKey;
            this.taskData.BusinessDepartmentId = this.TaskForm.value.Department;
            this.taskData.LinkUrl = this.TaskForm.value.LinkUrl;
            this.taskData.LanguageId = +languageId;
            this.taskData.ApplicationId = BaseServices.ApplicationId;
            this.taskData.CreatedBy = this.CreaterId
                ? this.CreaterId
                : BaseServices.UserId;
            this.taskData.CreatedOn = this.CreatedOn
                ? this.CreatedOn
                : new Date();
            this.taskData.Title = this.TaskForm.value.Title;
            if (this.taskData.Status === '9') {
                this.taskData.CompletedDate = HelperService.formatDate(
                    new Date()
                );
            }
            this.taskData.ChecklistId = this.TaskForm.value.checklistId;
            this.taskData.SendUserData = this.TaskForm.value.SendUserData;
            this.taskData.RepetitionId = this.TaskForm.value.RepetitionId;
            let commonProperty = []; // = new Execution();

            this.TaskForm.value.ExecutionUser.forEach((element: any) => {
                let exec = new Execution();
                exec.Id = element;
                commonProperty.push(exec);
            });
            this.taskData.ExecutionUsers = commonProperty;
            let followUpUserProperty = [];
            this.TaskForm.value.FollowupUser.forEach(element => {
                let exec = new Execution();
                exec.Id = element;
                followUpUserProperty.push(exec);
            });
            this.taskData.FollowupUsers = followUpUserProperty;
            this.taskData.Description = this.TaskForm.value.Description;
            this.taskData.ActivityScheduleId = this.activityScheduleId
                ? this.activityScheduleId
                : 0;
            this.taskData.ActivityAttachment = this.newUploadedFiles;
            let activitySchedule = new ActivitySchedule();
            activitySchedule.Id = this.activityScheduleId ? this.activityScheduleId : 0;
            activitySchedule.ActivityId = this.taskId;
            activitySchedule.NotifyBeforeDeadLine = this.TaskForm.value.NotifyBeforeDeadLine;
            activitySchedule.NotifyBeforeFollowup = this.TaskForm.value.NotificationBeforeFollowUp;
            activitySchedule.EndDate = this.TaskForm.value.EndDate
                ? HelperService.formatDateFilter(this.TaskForm.value.EndDate.formatted)
                : null;
            activitySchedule.StartDate = HelperService.formatDate(
                this.taskData.CreatedOn
            );
            activitySchedule.CreatedBy = BaseServices.UserId;
            activitySchedule.CreatedOn = HelperService.formatDate(
                this.taskData.CreatedOn
            );
            activitySchedule.ParentId = this.isParentId ? this.isParentId : 0;
            this.taskData.ActivityScheduleDto = activitySchedule;
            this.taskData.ModuleFeatureId = this.FeatureId;
            this.taskData.CultureInfo = BaseServices.userCultureInfo();
            // update new task details
            if (this.taskId > 0) {
                this.taskData.ModifiedBy = BaseServices.UserId;
                this.taskData.Status = this.TaskForm.value.Status
                    ? this.TaskForm.value.Status
                    : '7';
                    this.subscriptions.push(this.taskService
                    .updateTask(this.taskData, this.parentKey)
                    .subscribe(isTaskUpdated => {
                        if (isTaskUpdated) {
                            this.toasterComponent.callToast();
                            this.loading = false;
                            this.gotoList();
                        }
                    }));
            } else {
                this.taskData.Status = '7';
                // add new task details
                this.subscriptions.push(this.taskService
                    .addActivity(this.taskData, this.parentKey)
                    .subscribe(isActivityAdded => {
                        if (isActivityAdded) {
                            this.toasterComponent.callToast();
                            this.loading = false;
                            this.gotoList();
                        }
                    }));
            }
        }
        // }
    }

    /**
     * go to list
     */
    gotoList() {
        this.location.back();
    }

    //#endregion
    //#region methos

    /**
     * bind dropdowns
     * @param busId {any}
     */
    bindDropdown() {
        this.repetitions = [];
        this.executions = [];
        this.followups = [];
        this.subscriptions.push(this.translate.stream('REPETITION_LIST').subscribe(val => {
            this.repetitions = [];
            this.repetitions.push(
                { label: val.REPETITION_LIST_SELECT, value: null },
                { label: val.REPETITION_LIST_NO_REPETITION, value: 1 },
                { label: val.REPETITION_LIST_DAILY, value: 2 },
                { label: val.REPETITION_LIST_WEEKDAYS, value: 3 },
                { label: val.REPETITION_LIST_EVERY_WEEK, value: 4 },
                { label: val.REPETITION_LIST_EVERY_2_WEEKS, value: 5 },
                { label: val.REPETITION_LIST_EVERY_3_WEEKS, value: 6 },
                { label: val.REPETITION_LIST_EVERY_MONTH, value: 7 },
                { label: val.REPETITION_LIST_EVERY_2_MONTH, value: 8 },
                { label: val.REPETITION_LIST_EVERY_3_MONTH, value: 9 },
                { label: val.REPETITION_LIST_EVERY_4_MONTH, value: 10 },
                { label: val.REPETITION_LIST_EVERY_6_MONTH, value: 11 },
                { label: val.REPETITION_LIST_EVERY_YEAR, value: 12 },
                { label: val.REPETITION_LIST_EVERY_2_YEARS, value: 13 },
                { label: val.REPETITION_LIST_EVERY_3_YEARS, value: 14 },
                { label: val.REPETITION_LIST_EVERY_4_YEARS, value: 15 },
                { label: val.REPETITION_LIST_EVERY_5_YEARS, value: 16 },
                { label: val.REPETITION_LIST_EVERY_6_YEARS, value: 17 },
                { label: val.REPETITION_LIST_EVERY_7_YEARS, value: 18 },
                { label: val.REPETITION_LIST_EVERY_8_YEARS, value: 19 },
                { label: val.REPETITION_LIST_EVERY_9_YEARS, value: 20 },
                { label: val.REPETITION_LIST_EVERY_10_YEARS, value: 21 }
            );
        }))
        this.subscriptions.push(this.taskService
            .getAssignedUsers(BaseServices.BusinessId, this.getFeatureKey(this.parentKey), this.parentId, BaseServices.ApplicationId)
            .subscribe((result: any) => {
                Array.from(result).forEach((exePersons: any) => {
                    this.executions.push({
                        label: exePersons.Name,
                        value: exePersons.Id
                    });
                    this.followups.push({
                        label: exePersons.Name,
                        value: exePersons.Id
                    })
                });
                this.TaskForm.patchValue({ ExecutionUser: this.TaskForm.value.ExecutionUser, FollowupUser: this.TaskForm.value.FollowupUser });
            }));

        this.FollowUpDays = [];
        for (let n = 0; n <= 14; n++) {
            if (n === 0) {
              this.FollowUpDays.push({
                label: this.translate.instant('ON_DEADLINE_DATE'),
                value: n,
              });
            } else {
              this.FollowUpDays.push({ label: n.toString(), value: n });
            }
        }
        this.FollowUpDays.push({ label: this.translate.instant('TREE_WEEKS'), value: 21 }, { label: this.translate.instant('ONE_MONTH'), value: 30 });
        let departments = this.route.snapshot.data['departments'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.department = [];
            this.department.push(
                { label: val.SELECT, value: null }
            );
            if (departments) {
                departments.forEach((department: any) => {
                    this.department.push({
                        label: department.Name,
                        value: department.Id
                    });
                });
            }
        }));
    }

    onNumberOfDays(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 && (charCode < 48 || charCode > 57))
        ) {
            e.preventDefault();
        }
    }

    onChangeFutureRecurring(event) {
        if (event === true) {
            this.confirmationService.confirm({
                message: this.translate.instant('STOP_FUTURE_RECURRING_ACTIVTY'),
                accept: () => {
                    this.TaskForm.get('futureRecurring').patchValue(event);
                },
                reject: () => {
                    this.TaskForm.get('futureRecurring').patchValue(false);
                }
            });
        } else {
            this.TaskForm.get('futureRecurring').patchValue(event);
        }

    }


    getFeatureKey(fKey) {
        let fValue = 0;
        switch (fKey) {
            case 'PROJECT':
                fValue = +FeatureKey.PROJECT;
                break;
            case 'FDV':
                fValue = +FeatureKey.FDV;
                break;
            case 'IC':
                fValue = +FeatureKey.INTERNAL_CONTROL;
                break;                
            case 'OS':
                fValue = +FeatureKey.OTHER_SYSTEMS;
                break;                
            case 'QS':
                fValue = +FeatureKey.QUALITY_SYSTEMS;
                break;
            case 'FS':
                fValue = +FeatureKey.FOOD_SAFETY;
                break;
            case 'VEHICLE':
                fValue = +FeatureKey.VEHICLES;
                break;
            case 'FRAMEWORK':
                fValue = +FeatureKey.FRAMEWORK_AGREEMENT;
                break;
            case 'SA':
                fValue = +FeatureKey.SERVICE_AGREEMENT;
                break;
            case 'KUNDE_EL':
                fValue = +FeatureKey.KUNDE_EL;
                break;                
            case 'KUBA_CONTROL':                
                fValue = +FeatureKey.KUBA_CONTROL;
                break;   
            default:
                fValue = 0;
        }
        return fValue;
    }

    bindChecklistDropDown() {
        this.ChecklistData = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.ChecklistData.push(
                { label: val.SELECT, value: null }
            );
        }));

        this.subscriptions.push(this.taskService
            .getCheckListData(BaseServices.BusinessId, this.getFeatureKey(this.parentKey), this.parentId)
            .subscribe((result:any) => {
                result.forEach((checklist: any) => {
                    this.ChecklistData.push({
                        label: checklist.Title,
                        value: checklist.Id
                    });
                });
                this.TaskForm.patchValue({
                    checklistId: this.TaskForm.value.checklistId
                });
            }));
    }

    completeChecklist() {
        let activityId = this.taskId;
        let featureKey = this.getFeatureKey(this.parentKey);
        let featureId = this.parentId;
        if (this.completeChecklistDetails) {
            this.router.navigate(
                [
                    '../../../../../../complete-checklist/incomplete/' +
                    this.completeChecklistDetails.Id,
                    'task-manager',
                    0,
                    0
                ],
                { relativeTo: this.route }
            );
        } else {
            this.router.navigate(
                [
                    '../../../../../../complete-checklist/new/' +
                    this.TaskForm.value.checklistId,
                    'task-manager',
                    featureKey,
                    featureId,
                    activityId
                ],
                { relativeTo: this.route }
            );
        }


    }

    getCompleteChecklistDetails(activityId: number, task: any) {
        this.subscriptions.push(this.taskService.getActivityCompleteCheckList(activityId).subscribe(
            (result: any) => {
                if (result) {

                    this.disableChecklistDeopDown = true;
                    if (result.Status !== 1) {
                        this.showCompleteCheckListButton = false
                    }
                    this.completeChecklistDetails = result;
                }
            }
        ));
    }

    notificationdays(control: FormControl) {
        this.currentDate = new Date();
        if (this.currentDate && this.currentDeadLine) {
            this.currentDate.setHours(0, 0, 0, 0);
            this.currentDeadLine.setHours(0, 0, 0, 0);
            let ONE_DAY = 1000 * 60 * 60 * 24;
            // Convert both dates to milliseconds
            let date1 = this.currentDate.getTime();
            let date2 = this.currentDeadLine.getTime();
            // Calculate the difference in milliseconds
            let difference_ms = Math.abs(date1 - date2);
            this.dateDiff = Math.round(difference_ms / ONE_DAY) + 1;
        }
        const q = new Promise((resolve) => {
            if (control.value >= this.dateDiff && this.TaskForm.value.Status !== '9'
                && this.TaskForm.value.Status !== '10') {
                resolve({ 'notificationdays': true });
            } else {
                resolve(null)
            }
        });
        return q;
    }

    onDeadLineChanged(event: IMyDateModel) {
        this.currentDeadLine = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.currentDate = new Date();

    }

    showCheckList() {
        if (this.TaskForm.value.checklistId > 0) {
            this.displayDialog = true;
            this.checklistDetails = [];
            this.subscriptions.push(this.taskService.getFeatureChecklistItems(this.TaskForm.value.checklistId)
                .subscribe((val: any) => {
                    if (val) {
                        this.checklistTile = val.Title;
                        this.checklistDetails = val;
                    }
                }));
        }
    }

    onShowSeeChecklist(e: any) {
        if (e.value > 0) {
            this.isChecklistSelected = true;
        } else {
            this.isChecklistSelected = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
