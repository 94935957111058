export class Electro {
    Id: number;
    ContractNumber: number;
    ContractName: string;
    ClientId: number;
    ClientContactId: number;
    InstallationFirm: string;
    InstallationAddress: string;
    InstallationZip: string;
    InstallationCity: string;
    Housefolder: string;
    InstallationContactId: number;
    InstallationContactFunction: string;
    InstallationContactFunctionText: string;
    BusinessId: number;
    ApplicationId: number;
    BusinessPropertyId: number;
    ResponsibleUserId: number;
    ContractImage: string;
    Status: number;
    Visibilty: number;
    Comments: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    ClientUsers: ElectroClientUsers[];
    ContractWorkers: ElectroContractWorkers[];
    ContractContentsData: ElectroContractContentData[];
    BusinessFirm: string;
    BusinessContactPerson: string;
    BusinessAddress: string;
    BusinessZipcodeCity: string;
    BusinessPhoneNumber: string;
    BusinessEmail: string;
    ClientFirm: string;
    ClientContactPerson: string;
    ClientAddress: string;
    ClientZipcodeCity: string;
    ClientPhoneNumber: string;
    ClientEmail: string;
    ClientNumber: string;
    ContactName: string;
    ContactEmail: string;
    ContactPhoneNumber: string;
}

export class ElectroList {
    Id: number;
    ContractNumber: number;
    ContractName: string;
    ClientId: number;
    ClientName: string;
    ResponsibleUserId: number;
    ResponsibleUserName: string;
    Status: number;
    StatusText: string;
    Visibilty: boolean;
}

export class ElectroClientUsers {
    Id: number;
    ElectroContractId: number;
    ClientContactId: number;
    ClientUserId: number;
}

export class ElectroContractWorkers {
    Id: number;
    ElectroContractId: number;
    UserId: number;

}

export class ElectroContractContentData {
    Id: number;
    ElectroContractId: number;
    ContentId: number;
    Interval: number;
    Unit: number;
    Cost: number;
    Status: number;
    PerformedForm: number;
    Name: string;
    TranslationKey: string;
    LastPerformed: string;
    NextInspection: string;
    InspectionId: number;
    UnitText:string;
}

export class ElectroInternalControl {
    Id: number;
    ContractId: number;
    DocumentNumber: string;
    Version: number;
    Description: string;
    TermsDuration: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
}
export class ElectroDeviationClosing {
    Id: number;
    ContractId: number;
    DocumentNumber: string;
    Version: number;
    Description: string;
    TermsDuration: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
}

export class ContractDetail {
    ElectroInternalControl: ElectroInternalControl;
    ElectroDeviationClosing: ElectroDeviationClosing;
    InternalControlModifiedByName: string;
    DeviationClosingModifiedByName: string;
    ContractContentsData: ElectroContractContentData[];
    ElectricianSign: string;
    ClientSign: string;
    ElectricianSignInfo: string;
    ClientSignInfo: string;
}

export class ContractDetailEdit {
    Id: number;
    ContractId: number;
    DocumentNumber: string;
    Version: number;
    Description: string;
    TermsDuration: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    ContractContentsData: ElectroContractContentData[];
    ElectricianSign: string;
    ClientSign: string;
    ElectricianSignInfo: string;
    ClientSignInfo: string;
}
export class LarList {
    Id: number;
    Title: string;
    Url: string;
    IsLaws: boolean;
    Status: number;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
}

export class ElectroEnabledManuals {
    Id: number;
    ElectroContractId: number;
    Status: number;
    EnabledManuals: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
}

export class ElectroSettings {
    Id: number;
    ApplicationId: number; 
    BusinessId: number;
    InspectionComment: string;
    DeviationComment: string;
    InspectionImage: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
}