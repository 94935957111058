import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ohs_planData } from '../ohs-plan';
import { BaseServices } from '../../kuba.services';
import { FlexiServices } from '../services/flexi.services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { ArticleContent } from 'src/app/shared/ecpl-article-viewer/interfaces';
import * as _ from 'lodash';
import { HelperService } from 'src/app/_services/helper.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common'
import { publicDecrypt } from 'crypto';
import { publish } from 'rxjs-compat/operator/publish';
import { OHS_Activty, OHS_PerformedDetails } from '../models/flexi';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { log } from 'console';
import { ConfirmationService } from 'primeng/api';
import { FeaturesBaseServices } from '../../features/services/features-base.services';
import { features } from 'process';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
    templateUrl: './ohs-plan.component.html'
})

export class OHSPlanComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    @Input() articleSrc: ArticleContent;
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    addnewrow = 0;
    Data: any;
    Content: any;
    portalId: number;
    businessId: number;
    applicationId: number;
    loading: boolean = false;
    ohsId: number;
    userId: number;
    currentDateTime: any;
    OHSName: string = "Plan for BHT";
    featureId: number;
    featureKey: number;
    Userrights: any;
    isRegisteredActivityDisabled: any;
    administrateFeatures: any;
    FollowUpActivityFeatures: any;
    OHS_PerfomedDetails: OHS_PerformedDetails;
    initiator: SelectItem[];
    OhsActivity: FormGroup;
    isShowOHSPlanTable: boolean;
    isRMode: boolean;
    isRegisteredActivityColumnVisible: boolean = false;
    public dateOptions: IMyDpOptions;
    public fromDateOptions: IMyDpOptions;
    public toDateOptions: IMyDpOptions;
    OptionDate: IMyDateModel;
    Optionstring: any = '';
    // ediConfig: any = {
    //     height: 380,
    //     resize_dir: 'vertical',
    //     resize_maxHeight: 900,
    //     toolbar: [
    //         {
    //             name: 'clipboard',
    //             groups: ['clipboard', 'undo'],
    //             items: [
    //                 'Cut',
    //                 'Copy',
    //                 'Paste',
    //                 'PasteText',
    //                 'PasteFromWord',
    //                 '-',
    //                 'Undo',
    //                 'Redo'
    //             ]
    //         },
    //         {
    //             name: 'editing',
    //             groups: ['find', 'selection', 'spellchecker'],
    //             items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt']
    //         },

    //         '/',
    //         {
    //             name: 'basicstyles',
    //             groups: ['basicstyles', 'cleanup'],
    //             items: [
    //                 'Bold',
    //                 'Italic',
    //                 'Underline',
    //                 'Strike',
    //                 'Subscript',
    //                 'Superscript',
    //                 '-',
    //                 'CopyFormatting',
    //                 'RemoveFormat'
    //             ]
    //         },
    //         {
    //             name: 'paragraph',
    //             groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
    //             items: [
    //                 'NumberedList',
    //                 'BulletedList',
    //                 '-',
    //                 'Outdent',
    //                 'Indent',
    //                 '-',
    //                 'Blockquote',
    //                 'CreateDiv',
    //                 '-',
    //                 'JustifyLeft',
    //                 'JustifyCenter',
    //                 'JustifyRight',
    //                 'JustifyBlock',
    //                 '-',
    //                 'BidiLtr',
    //                 'BidiRtl',
    //                 'Language'
    //             ]
    //         },
    //         { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    //         {
    //             name: 'insert',
    //             items: [
    //                 'Image',
    //                 'Table',
    //                 'HorizontalRule',
    //                 'Smiley',
    //                 'SpecialChar',
    //                 'PageBreak',
    //                 'Iframe',
    //             ]
    //         },
    //         '/',
    //         { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    //         { name: 'colors', items: ['TextColor', 'BGColor'] },
    //         { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
    //         { name: 'others', items: ['-'] },
    //         { name: 'about', items: ['About'] }
    //     ]


    // }
    public readOnly = false;

  public Editor = ClassicEditor; // Declare the Editor property
  public editorConfig = {
    skin: 'moono-lisa',
    language: 'en',
    // Other configuration options
  };
  public tabz = {
    text: '',
  };
    constructor(
        private _flexiService: FlexiServices,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private router: Router,
        public datapipe: DatePipe,
        private translate: TranslateService,
        public featuresBaseServices: FeaturesBaseServices

    ) {
        this.dateOptions = {
            dateFormat: 'dd/mm/yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            firstDayOfWeek: 'su',
            satHighlight: true,
            height: '29px',
            selectionTxtFontSize: '13px',
            disableSince: {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate() + 1,
            },
            disableUntil: {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate() + 1,
            },
            todayBtnTxt: this.translate.instant('TODAY'),
            dayLabels: {
                su: this.translate.instant('SUN'),
                mo: this.translate.instant('MON'),
                tu: this.translate.instant('TUE'),
                we: this.translate.instant('WED'),
                th: this.translate.instant('THU'),
                fr: this.translate.instant('FRI'),
                sa: this.translate.instant('SAT')
            },
            monthLabels: {
                1: this.translate.instant('JANUARY'),
                2: this.translate.instant('FEBRUARY'),
                3: this.translate.instant('MARCH'),
                4: this.translate.instant('APRIL'),
                5: this.translate.instant('MAY'),
                6: this.translate.instant('JUNE'),
                7: this.translate.instant('JULY'),
                8: this.translate.instant('AUGUST'),
                9: this.translate.instant('SEPTEMBER'),
                10: this.translate.instant('OCTOBER'),
                11: this.translate.instant('NOVEMBER'),
                12: this.translate.instant('DECEMBER')
            },
            inline: false
        };

        this.fromDateOptions = {
            dateFormat: 'dd/mm/yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            firstDayOfWeek: 'su',
            satHighlight: true,
            height: '29px',
            selectionTxtFontSize: '13px',
            todayBtnTxt: this.translate.instant('TODAY'),
            dayLabels: {
                su: this.translate.instant('SUN'),
                mo: this.translate.instant('MON'),
                tu: this.translate.instant('TUE'),
                we: this.translate.instant('WED'),
                th: this.translate.instant('THU'),
                fr: this.translate.instant('FRI'),
                sa: this.translate.instant('SAT')
            },
            monthLabels: {
                1: this.translate.instant('JANUARY'),
                2: this.translate.instant('FEBRUARY'),
                3: this.translate.instant('MARCH'),
                4: this.translate.instant('APRIL'),
                5: this.translate.instant('MAY'),
                6: this.translate.instant('JUNE'),
                7: this.translate.instant('JULY'),
                8: this.translate.instant('AUGUST'),
                9: this.translate.instant('SEPTEMBER'),
                10: this.translate.instant('OCTOBER'),
                11: this.translate.instant('NOVEMBER'),
                12: this.translate.instant('DECEMBER')
            },
            inline: false
        };

        this.toDateOptions = {
            dateFormat: 'dd/mm/yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            firstDayOfWeek: 'su',
            satHighlight: true,
            height: '29px',
            selectionTxtFontSize: '13px',
            todayBtnTxt: this.translate.instant('TODAY'),
            dayLabels: {
                su: this.translate.instant('SUN'),
                mo: this.translate.instant('MON'),
                tu: this.translate.instant('TUE'),
                we: this.translate.instant('WED'),
                th: this.translate.instant('THU'),
                fr: this.translate.instant('FRI'),
                sa: this.translate.instant('SAT')
            },
            monthLabels: {
                1: this.translate.instant('JANUARY'),
                2: this.translate.instant('FEBRUARY'),
                3: this.translate.instant('MARCH'),
                4: this.translate.instant('APRIL'),
                5: this.translate.instant('MAY'),
                6: this.translate.instant('JUNE'),
                7: this.translate.instant('JULY'),
                8: this.translate.instant('AUGUST'),
                9: this.translate.instant('SEPTEMBER'),
                10: this.translate.instant('OCTOBER'),
                11: this.translate.instant('NOVEMBER'),
                12: this.translate.instant('DECEMBER')
            },
            inline: false
        };
    }

    ngOnInit() {

        if (window.name === 'Remote') {
            let businessId = +sessionStorage.getItem('RModeBusinessId');
            this.portalId = +BaseServices.PortalId
            if (businessId > 0 && this.portalId == 0) {
                this.isRMode = true;
            }
        } else {
            this.isRMode = false;

        }

        this.businessId = BaseServices.BusinessId;
        this.portalId = +BaseServices.PortalId
        this.userId = BaseServices.UserId;
        this.applicationId = BaseServices.ApplicationId;
        let OHSData = this.route.snapshot.data['OhsData'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule.id;
        if (OHSData != null) {
            this.ohsId = OHSData.id;
        }
        else {
            this.ohsId = 0;
        }
        if (this.ohsId == undefined || this.ohsId == null || this.ohsId == 0) {
            this.ohsId = 0;
        }
        else {
            this.ohsId = OHSData.id;
        }
        if (OHSData != null) {
            this.Content = OHSData.Data;
        }
    
        this.translate.stream('SELECT_FOR_INITIATOR').subscribe(val => {
            this.initiator = [];
            this.initiator.push(
                { label: val.OHS, value: 1 },
                { label: val.BUSINESS, value: 2 }
            )
        });

        this._flexiService.getOhsActivityDetailsById(this.ohsId).subscribe(result => {
          
            if (this.ohsId >= 0 && result != null) {
                result.PeriodFrom = HelperService.formatInputDate(result.PeriodFrom);
                result.PeriodTo = HelperService.formatInputDate(result.PeriodTo);
                this.ToDateChanged(result.PeriodTo);
                this.FromDateChanged(result.PeriodFrom);

            } else {
                result = {
                    PeriodFrom: HelperService.formatInputDate(Date),
                    PeriodTo: HelperService.formatInputDate(Date),
                    Id: 0
                };
                this.ToDateChanged(result.PeriodTo);
                this.FromDateChanged(result.PeriodFrom);
            }

            if (result.OHS_Activity) {
                result.OHS_Activity.forEach(x => {
                    x.Deadline = HelperService.formatInputDate(x.Deadline);
                    this.dateOptions = this.SetDateInput(this.dateOptions, this.OptionDate, this.Optionstring);
                })
            }

            this.OHS_PerfomedDetails = result;
            // if (this.OHS_PerfomedDetails.Id == 0) {
            //     this.addNewRow();
            // }
            this.isShowOHSPlanTable = true;
        })



        let FollowUpActivityFeatures;
        let FollowUpActivity;
        let FollowUpActivityFeaturesJson;


        let curSession = JSON.parse(sessionStorage.getItem('RModeBusiness'));

        if (curSession != null) {
            FollowUpActivity = localStorage.getItem("BusinessDetails");
            FollowUpActivityFeaturesJson = JSON.parse(FollowUpActivity).Features;
            FollowUpActivityFeatures = JSON.parse(FollowUpActivityFeaturesJson);
          
        } else {

            FollowUpActivityFeatures = JSON.parse(BaseServices.userSettings.userFeature.Features);
        }

        const parentElement = FollowUpActivityFeatures.find(element => element.id === 15);

        if (parentElement && parentElement.children) {
            const childElement = parentElement.children.find(child => child.id === 135);

            if (childElement && childElement.checked) {
                this.isRegisteredActivityColumnVisible = true; // Enable the checkbox
            }
            else {
                this.isRegisteredActivityColumnVisible = false; // Disable the checkbox
            }
        }
    }

    saveContent() {
        this.addnewrow = 0;
        this.loading = true;
        this.OHS_PerfomedDetails.OHS_PlanId = this.ohsId;
        this.OHS_PerfomedDetails.BusinessId = BaseServices.BusinessId;
        let PeriodFrom = this.OHS_PerfomedDetails.PeriodFrom.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodFrom : this.OHS_PerfomedDetails.PeriodFrom.jsdate;
        if (this.OHS_PerfomedDetails.PeriodFrom.jsdate != undefined) {
            this.OHS_PerfomedDetails.PeriodFrom = this.datapipe.transform(PeriodFrom);
        }
        let PeriodTo = this.OHS_PerfomedDetails.PeriodTo.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodTo : this.OHS_PerfomedDetails.PeriodTo.jsdate;
        if (this.OHS_PerfomedDetails.PeriodTo.jsdate != undefined) {
            this.OHS_PerfomedDetails.PeriodTo = this.datapipe.transform(PeriodTo);
        }
        this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
            x.CreatedBy = BaseServices.UserId;
            if (x.Deadline != null) {
                x.Deadline = x.Deadline.jsdate != undefined ? this.datapipe.transform(x.Deadline.jsdate) : this.datapipe.transform(x.Deadline);
            }
            else {
                x.Deadline = null;
            }
        });
    
        this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
            if (x.OhsPerformedId !== 0) {
                x.OhsPerformedId = 0;
            }
        });
        this._flexiService.saveOHSActivity(this.OHS_PerfomedDetails).subscribe(result => {
            result.OHS_Activity.forEach(x => {
                x.Deadline = HelperService.formatInputDate(x.Deadline);
            })
            this.OHS_PerfomedDetails = result;



            if (this.ohsId != null && this.ohsId != 0) {
                this.Data = {
                    CreatedBy: BaseServices.UserId,
                    Content: this.Content,
                    BusinessId: BaseServices.BusinessId
                };
                this.loading = true;
                this._flexiService.updateOHSData(this.Data, this.ohsId).subscribe(isOhsupdate => {
                    if (isOhsupdate) {
                        this.toasterComponent.callToast();
                        this.loading = false;
                    }
                });

            }
            else {
                this.Data = {
                    id: null,
                    CreatedBy: BaseServices.UserId,
                    Content: this.Content,
                    BusinessId: BaseServices.BusinessId
                };
              
                this.loading = true;
                this._flexiService.PostOHSData(this.Data).subscribe(isOhsupdate => {
                    if (isOhsupdate) {
                        this.toasterComponent.callToast();
                        this.loading = false;
                    }
                });

            }

        })
    }

    PrintOhs() {
        let cloneArticleSrc = _.clone(this.articleSrc);
        if (this.articleSrc !== undefined) {
            this.articleSrc.article.articleDate = null;
        }
        let type = 'pdf';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
        let Business = JSON.parse(sessionStorage.getItem('RModeBusiness'));
        let businessName =Business.BusinessName
        let languageId = parseInt(sessionStorage.getItem('languageId'));

        let CultureInfo = 'en-GB';
        switch (languageId) {
            case 1:
                CultureInfo = 'en-GB';
                break;
            case 2:
                CultureInfo = 'no-NO';
                break;
            case 3:
                CultureInfo = 'sv-SE';
                break;
            case 4:
                CultureInfo = 'pl-PL';
                break;
        }
        this.addnewrow = 0;
        this.loading = true;
        this.OHS_PerfomedDetails.OHS_PlanId = this.ohsId;
        this.OHS_PerfomedDetails.BusinessId = BaseServices.BusinessId;
        let PeriodFrom = this.OHS_PerfomedDetails.PeriodFrom.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodFrom : this.OHS_PerfomedDetails.PeriodFrom.jsdate;
        if (this.OHS_PerfomedDetails.PeriodFrom.jsdate != undefined) {
            this.OHS_PerfomedDetails.PeriodFrom = this.datapipe.transform(PeriodFrom);
        }
        let PeriodTo = this.OHS_PerfomedDetails.PeriodTo.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodTo : this.OHS_PerfomedDetails.PeriodTo.jsdate;
        if (this.OHS_PerfomedDetails.PeriodTo.jsdate != undefined) {
            this.OHS_PerfomedDetails.PeriodTo = this.datapipe.transform(PeriodTo);
        }
        this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
            x.CreatedBy = BaseServices.UserId;
            if (x.Deadline != null) {
                x.Deadline = x.Deadline.jsdate != undefined ? this.datapipe.transform(x.Deadline.jsdate) : this.datapipe.transform(x.Deadline);
            }
            else {
                x.Deadline = null;
            }
        });
       
        this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
            if (x.OhsPerformedId !== 0) {
                x.OhsPerformedId = 0;
            }
        });
        this._flexiService.saveOHSActivity(this.OHS_PerfomedDetails).subscribe(result => {
            result.OHS_Activity.forEach(x => {
                x.Deadline = HelperService.formatInputDate(x.Deadline);
            })
            this.OHS_PerfomedDetails = result;
            this.loading = false;

        })

        if (this.ohsId != null && this.ohsId != 0) {
            this.Data = {
                CreatedBy: BaseServices.UserId,
                Content: this.Content,
                BusinessId: BaseServices.BusinessId
            };
            this.loading = true;
            this._flexiService.updateOHSData(this.Data, this.ohsId).subscribe(isOhsupdate => {
                if (isOhsupdate) {
                    this.toasterComponent.callToast();
                    this.loading = false;
                }
            });

        }
        else {
            this.Data = {
                id: null,
                CreatedBy: BaseServices.UserId,
                Content: this.Content,
                BusinessId: BaseServices.BusinessId
            };
          
            this.loading = true;
            this._flexiService.PostOHSData(this.Data).subscribe(isOhsupdate => {
                if (isOhsupdate) {
                    this.toasterComponent.callToast();
                    this.loading = false;
                }
            });
        }

        this.loading = true;
        this.subscriptions.push(this._flexiService
            .generateOHSFile(this.Content, this.businessId, CultureInfo
                , this.ohsId, this.userId)
            .subscribe(
                blob => {
                    this.currentDateTime = this.datapipe.transform((new Date), 'dd/MM/yyyy h:mm:ss');
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.OHSName}_${businessName}_${this.currentDateTime}.${type.toLowerCase()}`;
                    link.click();
                    this.loading = false;
                },
                error => {
                    alert('Export not downloaded');
                    this.loading = false;
                }));
    }

    SavePDFDocument() {
        this.confirmationService.confirm({
            message: this.translate.instant('WARNING_MESSAGE'),
            accept: () => {


                let cloneArticleSrc = _.clone(this.articleSrc);
                if (this.articleSrc !== undefined) {
                    this.articleSrc.article.articleDate = null;
                }
                let type = 'pdf';
                let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
                let languageId = parseInt(sessionStorage.getItem('languageId'));

                let CultureInfo = 'en-GB';
                switch (languageId) {
                    case 1:
                        CultureInfo = 'en-GB';
                        break;
                    case 2:
                        CultureInfo = 'no-NO';
                        break;
                    case 3:
                        CultureInfo = 'sv-SE';
                        break;
                    case 4:
                        CultureInfo = 'pl-PL';
                        break;
                }

                if (this.ohsId != null && this.ohsId != 0) {
                    this.Data = {
                        CreatedBy: BaseServices.UserId,
                        Content: this.Content,
                        BusinessId: BaseServices.BusinessId
                    };
                    this.loading = true;
                    this._flexiService.updateOHSData(this.Data, this.ohsId).subscribe(isOhsupdate => {
                        if (isOhsupdate) {

                            // this.loading = false;
                        }
                    });

                }
                else {
                    this.Data = {
                        id: null,
                        CreatedBy: BaseServices.UserId,
                        Content: this.Content,
                        BusinessId: BaseServices.BusinessId
                    };
                    this.loading = true;
                    this._flexiService.PostOHSData(this.Data).subscribe(isOhsupdate => {
                        if (isOhsupdate) {

                            // this.loading = false;
                        }
                    });
                }

                this.addnewrow = 0;
                this.loading = true;
                this.OHS_PerfomedDetails.OHS_PlanId = this.ohsId;
                this.OHS_PerfomedDetails.BusinessId = BaseServices.BusinessId;
                let PeriodFrom = this.OHS_PerfomedDetails.PeriodFrom.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodFrom : this.OHS_PerfomedDetails.PeriodFrom.jsdate;
                if (this.OHS_PerfomedDetails.PeriodFrom.jsdate != undefined) {
                    this.OHS_PerfomedDetails.PeriodFrom = this.datapipe.transform(PeriodFrom);
                }
                let PeriodTo = this.OHS_PerfomedDetails.PeriodTo.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodTo : this.OHS_PerfomedDetails.PeriodTo.jsdate;
                if (this.OHS_PerfomedDetails.PeriodTo.jsdate != undefined) {
                    this.OHS_PerfomedDetails.PeriodTo = this.datapipe.transform(PeriodTo);
                }
                this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
                    x.CreatedBy = BaseServices.UserId;
                    if (x.Deadline != null) {
                        x.Deadline = x.Deadline.jsdate != undefined ? this.datapipe.transform(x.Deadline.jsdate) : this.datapipe.transform(x.Deadline);
                    }
                    else {
                        x.Deadline = null;
                    }
                });
              
                this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
                    if (x.OhsPerformedId !== 0) {
                        x.OhsPerformedId = 0;
                    }
                });
                this._flexiService.saveOHSActivity(this.OHS_PerfomedDetails).subscribe(result => {
                    result.OHS_Activity.forEach(x => {
                        x.Deadline = HelperService.formatInputDate(x.Deadline);
                    })
                    this.OHS_PerfomedDetails = result;


                    if (result != null) {
                        this._flexiService.getOhsActivityDetailsById(this.ohsId).subscribe(result => {
                           

                            const ohsActivities = result.OHS_Activity;
                            const registeredActivities = ohsActivities.filter(activity => activity.RegisteredActivity);
                            var selectedData = registeredActivities.map(activity => ({
                                Title: activity.Activity,
                                Description: activity.Comment,
                                Deadline: activity.Deadline
                            }));


                            


                            this.loading = true;
                            this.applicationId = BaseServices.ApplicationId;
                            this.subscriptions.push(this._flexiService
                                .generateOHSPDFInDocumnet(this.Content, this.businessId, CultureInfo
                                    , this.portalId, this.ohsId, this.userId, this.featureId, this.featureKey = 0)
                                .subscribe(result => {
                                    if (result) {
                                        const selectedDataLength = selectedData.length;
                                        // if (selectedData.length != 0) {
                                        //     selectedData.forEach(dataItem => {
                                        //         this._flexiService.SaveOHSToDetailsActivity(dataItem, this.businessId, this.applicationId, this.userId).subscribe(result1 => {
                                        //             if (result1) {

                                        //                 this.loading = false;
                                        //             }
                                        //         });
                                        //     })
                                        //     this.toasterComponent.callToast();
                                        // }
                                        if (selectedDataLength > 0) {
                                           
                                                this._flexiService.SaveOHSToDetailsActivity(selectedData, this.businessId, this.applicationId, this.userId).subscribe(result1 => {
                                                    if (result1) {
                                                        this.loading = false;
                                                    }
                                                });
                                           
                        
                                            this.toasterComponent.callToast();
                                        }
                                        else {
                                            this.toasterComponent.callToast();
                                            this.loading = false;

                                        }
                                    }
                                },
                                    error => {
                                        alert('Export not downloaded');
                                        this.loading = false;
                                    }));

                        });
                    }

                })

            }
        });
    }


    addNewRow(index: number) {
        let activity = new OHS_Activty();
        activity.Activity = "";
        activity.Comment = "";
        activity.OHS_Advise = false;
        activity.OHS_Not_Advise = false;
        activity.Initiator = 1;
        activity.RegisteredActivity = false;
        activity.Deadline = null;
    
        // Insert the new activity at the correct position
        this.OHS_PerfomedDetails.OHS_Activity.splice(index + 1, 0, activity);
    
        // Update the No property for all rows to maintain the correct sequence
        for (let i = 0; i < this.OHS_PerfomedDetails.OHS_Activity.length; i++) {
            this.OHS_PerfomedDetails.OHS_Activity[i].No = i + 1;
        }
    
        // Additional logic if Id is 0
        if (this.OHS_PerfomedDetails.Id == 0) {
            this.isShowOHSPlanTable = true;
            let performedActivity = new OHS_PerformedDetails();
            performedActivity.PerformedBy = '';
            performedActivity.PeriodFrom = null;
            performedActivity.PeriodTo = null;
            performedActivity.OHS_PlanId = this.ohsId;
            performedActivity.BusinessId = BaseServices.BusinessId;
            this.OHS_PerfomedDetails = performedActivity;
            this.OHS_PerfomedDetails.OHS_Activity = [];
            this.OHS_PerfomedDetails.OHS_Activity.push(activity);
        }
    
        this.addnewrow = 1;
        this.dateOptions = this.SetDateInput(this.dateOptions, this.OptionDate, this.Optionstring);
    }
    
    

    checkActivityDeadline(): boolean {
        for (const activity of this.OHS_PerfomedDetails.OHS_Activity) {
            if (!activity.Activity || !activity.Deadline) {
                return false; // Return false if any activity or deadline is empty
            }
        }
        return true; // Return true if all activities have non-empty activity and deadline
    }

    SaveOHSActivity() {
        this.addnewrow = 0;
        this.loading = true;
        this.OHS_PerfomedDetails.OHS_PlanId = this.ohsId;
        this.OHS_PerfomedDetails.BusinessId = BaseServices.BusinessId;
        let PeriodFrom = this.OHS_PerfomedDetails.PeriodFrom.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodFrom : this.OHS_PerfomedDetails.PeriodFrom.jsdate;
        if (this.OHS_PerfomedDetails.PeriodFrom.jsdate != undefined) {
            this.OHS_PerfomedDetails.PeriodFrom = this.datapipe.transform(PeriodFrom);
        }
        let PeriodTo = this.OHS_PerfomedDetails.PeriodTo.jsdate == undefined ? this.OHS_PerfomedDetails.PeriodTo : this.OHS_PerfomedDetails.PeriodTo.jsdate;
        if (this.OHS_PerfomedDetails.PeriodTo.jsdate != undefined) {
            this.OHS_PerfomedDetails.PeriodTo = this.datapipe.transform(PeriodTo);
        }
        this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
            x.CreatedBy = BaseServices.UserId;
            if (x.Deadline != null) {
                x.Deadline = x.Deadline.jsdate != undefined ? this.datapipe.transform(x.Deadline.jsdate) : this.datapipe.transform(x.Deadline);
            }
            else {
                x.Deadline = null;
            }
        });
      
        this.OHS_PerfomedDetails.OHS_Activity.forEach(x => {
            if (x.OhsPerformedId !== 0) {
                x.OhsPerformedId = 0;
            }
        });
        this._flexiService.saveOHSActivity(this.OHS_PerfomedDetails).subscribe(result => {
            result.OHS_Activity.forEach(x => {
                x.Deadline = HelperService.formatInputDate(x.Deadline);
            })
            this.OHS_PerfomedDetails = result;
            this.toasterComponent.callToast();
            this.loading = false;

        })
    }

    FromDateChanged(event: IMyDateModel) {
        this.OptionDate = event;
        this.Optionstring = 'startDate';
        this.toDateOptions = this.SetDateInput(this.toDateOptions, event, 'startDate');
        this.dateOptions = this.SetDateInput(this.dateOptions, this.OptionDate, this.Optionstring);
        if (!event.jsdate) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.toDateOptions);
            copyConfig.disableUntil = event.date;
            this.toDateOptions = copyConfig;
        }
    }

    ToDateChanged(event: IMyDateModel) {
        this.OptionDate = event;
        this.Optionstring = 'endDate';
        this.fromDateOptions = this.SetDateInput(this.fromDateOptions, event, 'endDate');
        this.dateOptions = this.SetDateInput(this.dateOptions, this.OptionDate, this.Optionstring);
        if (!event.jsdate) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(this.fromDateOptions);
            copyConfig.disableUntil = event.date;
            this.fromDateOptions = copyConfig;
        }
    }
    dateChanged(event: IMyDateModel, index: number) {
        this.OHS_PerfomedDetails.OHS_Activity[index].Deadline = event.jsdate;

    }

    SetDateInput(dateConfig: IMyDpOptions, event: IMyDateModel, type: string): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }



    AddDefaultTemplate() {
        this.confirmationService.confirm({
            message: this.translate.instant('CONFIRM_THIS_RECORD'),
            accept: () => {

                this._flexiService.getDefaultOhsActivity().subscribe(result => {
                  
                    let performedActivity = new OHS_PerformedDetails();
                    performedActivity.OHS_Activity = result;
                    performedActivity.PerformedBy = this.OHS_PerfomedDetails.PerformedBy; 
                    this.OHS_PerfomedDetails = performedActivity;
                    this.isShowOHSPlanTable = true;
                });
            }
        });
    }

    removeOhsActivity(index: number) {
        this.loading = true;
        if (this.addnewrow == 0) {
            this._flexiService.getOhsActivityDetailsById(this.ohsId).subscribe(result => {
                const activity = result.OHS_Activity[index];

                const activityIdToDelete = activity.Id;
                this._flexiService.DeleteOhsActivity(activityIdToDelete).subscribe(result => {
                    if (result) {
                        this.OHS_PerfomedDetails.OHS_Activity.splice(index, 1);
                        this.updateActivityNumbers(index);
                        this.loading = false;
                        // this.toasterComponent.callToastDlt();
                    }
                });
            });
            this._flexiService.DeleteOhsActivity(this.OHS_PerfomedDetails.OHS_Activity[index].Id).subscribe(result => {
                this.OHS_PerfomedDetails.OHS_Activity.splice(index, 1);
                this.updateActivityNumbers(index);
                if (result) {
                    this.loading = false;
                    // this.toasterComponent.callToastDlt();
                }
            });
            this.toasterComponent.callToastDlt();
        }
        else {

            this.OHS_PerfomedDetails.OHS_Activity.splice(index, 1);
            this.updateActivityNumbers(index);
            this.loading = false;
            this.toasterComponent.callToastDlt();

        }

    }

    updateActivityNumbers(startIndex: number) {

        for (let i = startIndex; i < this.OHS_PerfomedDetails.OHS_Activity.length; i++) {
            this.OHS_PerfomedDetails.OHS_Activity[i].No = i + 1;
        }
    }

    selectOption(option: string, index: number) {

        if (option == 'OHS_Advise') {
            this.OHS_PerfomedDetails.OHS_Activity[index].OHS_Advise = true;
            this.OHS_PerfomedDetails.OHS_Activity[index].OHS_Not_Advise = false;
        }

        else {
            this.OHS_PerfomedDetails.OHS_Activity[index].OHS_Advise = false;
            this.OHS_PerfomedDetails.OHS_Activity[index].OHS_Not_Advise = true;
        }
    }

}