import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { EcplPdfViewerComponent } from './ecpl-pdf-viewer.component';
import { PdfViewerModule   } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [EcplPdfViewerComponent
],
  imports: [BrowserModule, CommonModule, PdfViewerModule],
  exports: [EcplPdfViewerComponent]
})
export class EcplPdfViewerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplPdfViewerModule
    };
  }
}
