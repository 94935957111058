// region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';

import { HelperService, PdfSetting } from './../../../../_services/helper.service';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Options } from './../../../../_models/options';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { BaseServices } from './../../../kuba.services';
import { MeasureProtocolService } from '../../services/measure-protocol.services';
import { TemperatureType, TemperatureModel, Temperature } from 'src/app/kuba/measure-protocol/models/mpc-details';
import { DateSetting } from './../../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-temperature',
    templateUrl: 'measure-protocol-temperature.component.html'
})

export class MeasureProtocolTemperatureComponent extends DateSetting implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolTemperature: FormGroup;
    temperatureTypeForm: FormGroup;
    temperatureModelForm: FormGroup;
    temperature: Temperature[];

    types: SelectItem[];
    editTypes: any = [];
    models: SelectItem[];
    editModels: any = [];
    viewType = false;

    kundeELId: number;
    parentKey: any;
    temperatureTypeId: number;
    temperatureModelId: number;
    cmpId: number;
    addModel = false;

    addType = false;
    cmpTemperature: any;
    cmpTemperatureId: any;
    locale: string;
    createdOn: any;
    createdBy: any;
    featureId: number;
    isElectro: boolean;
    private subscriptions: Subscription[] = [];
    /**
     * Date picker configuration option
    */
    public DateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '32px',
        selectionTxtFontSize: '14px',
        disableSince: HelperService.formatInputDate((new Date()).setDate((new Date()).getDate() + 1)).date,
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        }
    };
    saveprogress: boolean = false;
    ShowConfirm = false;
    deleteId = 0;
    deleteItem: string;
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        private confirmationService: ConfirmationService,
        public measureprotocolservice: MeasureProtocolService,
        private translate: TranslateService) {
        super();
        this.measureProtocolTemperature = this.fb.group({
            'MPCTemperatureTypeId': ['', Validators.required],
            'MPCTemperatureModelId': ['', Validators.required],
            'Celsius': ['', Validators.required],
            'TempDate': ['', Validators.required]
        });

        this.temperatureTypeForm = this.fb.group({
            'Name': ['', ValidationService.noWhitespaceValidator],
        });

        this.temperatureModelForm = this.fb.group({
            'Name': ['', ValidationService.noWhitespaceValidator],
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.kundeELId = this.route.snapshot.parent.parent.parent.params['id'];
        this.parentKey = this.route.snapshot.parent.parent.parent.data['parent'];
        this.bindDetails();
        this.temperature = [];
        this.temperature = this.route.snapshot.data['CMPTemperature'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule ? workingModule.id : 0;
        if (this.featureId == FeatureKey.ELECTRO) {
            this.isElectro = true;
            this.parentKey = 'KUNDE_EL';
            if (+this.route.snapshot.params['cClId']) {
                this.cmpId = +this.route.snapshot.params['cClId'];
            }
            this.kundeELId = +this.route.snapshot.params['cid'];
            this.viewTemperatureModel();
            this.viewTemperatureType();
            if (this.cmpId) {
                this.subscriptions.push(this.measureprotocolservice.getAllCMPTemperature(this.cmpId).subscribe((earthData: any) => {
                    if (earthData) {
                        this.temperature = earthData;

                    }
                }))
            }
        } else {
            this.cmpId = this.route.snapshot.parent.params['mpcid'];
        }
    }

    bindDetails() {
        this.types = [];
        this.editTypes = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.types = [];
            this.types.push(
                { label: val.SELECT, value: null }
            );
        }));
        let result = this.route.snapshot.data['temperatureType'];
        if (result) {
            result.forEach((instType: any) => {
                this.types.push({ label: instType.Name, value: instType.Id });
                this.editTypes.push({ Name: instType.Name, Id: instType.Id });
            });
        }

        this.models = [];
        this.editModels = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.models = [];
            this.models.push(
                { label: val.SELECT, value: null }
            );
        }));
        let Modelresult = this.route.snapshot.data['temperatureModel'];
        if (Modelresult) {
            Modelresult.forEach((modelType: any) => {
                this.models.push({ label: modelType.Name, value: modelType.Id });
                this.editModels.push({ Name: modelType.Name, Id: modelType.Id });
            });
        }

    }

    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate()
        }
    }

    clearType() {
        this.temperatureTypeId = 0;
        this.temperatureTypeForm = this.fb.group({
            Name: ['']
        })
    }

    clearModel() {
        this.temperatureModelId = 0;
        this.temperatureModelForm = this.fb.group({
            Name: ['']
        })
    }

    ontemperatureTypeFormHide() {
        this.temperatureTypeForm.reset();
    }
    ontemperatureModelFormHide() {
        this.temperatureModelForm.reset();
    }

    /**
    * view type list
    */
    viewTemperatureType() {
        this.subscriptions.push(this.measureprotocolservice.getAllByTemperatureType(this.kundeELId, this.parentKey, this.featureId).subscribe(instType => {
            if (instType) {
                this.types = [];
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.types = [];
                    this.types.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                instType.forEach((instType: any) => {
                    this.types.push({ label: instType.Name, value: instType.Id });
                });
            }
            this.editTypes = instType;
        }));
    }

    /**
     * view model list
     */
    viewTemperatureModel() {
        this.subscriptions.push(this.measureprotocolservice.getAllByTemperatureModel(this.kundeELId, this.parentKey, this.featureId).subscribe(modelType => {
            if (modelType) {
                this.models = [];
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.models = [];
                    this.models.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                modelType.forEach((modelType: any) => {
                    this.models.push({ label: modelType.Name, value: modelType.Id });
                });
            }
            this.editModels = modelType;
        }));
    }

    /**
    * save type of instrument
    */
    saveType() {
        let temperatureType = new TemperatureType();
        temperatureType.Id = this.temperatureTypeId ? this.temperatureTypeId : 0;
        temperatureType.Name = this.temperatureTypeForm.value.Name;
        temperatureType.KundeELId = this.kundeELId;
        temperatureType.FeatureId = this.featureId;
        temperatureType.Status = '1';

        if (temperatureType.Id > 0) {
            temperatureType.CreatedOn = new Date();
            temperatureType.CreatedBy = BaseServices.UserId;
            temperatureType.ModifiedOn = new Date();
            temperatureType.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice.updateTemperatureType(temperatureType, this.parentKey, this.kundeELId).subscribe((instrumentEdited: any) => {
                if (instrumentEdited) {
                    this.viewTemperatureType();
                    this.toasterComponent.callToast();
                    this.temperatureTypeForm.get('MPCTemperatureTypeId').patchValue(instrumentEdited.Id)
                }
            }));
        } else {
            temperatureType.CreatedOn = new Date();
            temperatureType.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.measureprotocolservice.createTemperatureType(temperatureType, this.parentKey, this.kundeELId).subscribe((instrumentAdded: any) => {
                if (instrumentAdded) {
                    this.viewTemperatureType();
                    this.toasterComponent.callToast();
                    this.temperatureTypeForm.get('MPCTemperatureTypeId').patchValue(instrumentAdded.Id)
                }
            }));
        }
        this.clearType();
    }

    saveModel() {
        let temperatureModel = new TemperatureModel();
        temperatureModel.Id = this.temperatureModelId ? this.temperatureModelId : 0;
        temperatureModel.Name = this.temperatureModelForm.value.Name;
        temperatureModel.KundeELId = this.kundeELId;
        temperatureModel.FeatureId = this.featureId;
        temperatureModel.Status = '1';
        temperatureModel.CreatedBy = BaseServices.UserId;
        temperatureModel.CreatedOn = new Date();

        if (temperatureModel.Id > 0) {
            temperatureModel.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice.updateTemperatureModel(temperatureModel, this.parentKey, this.kundeELId).subscribe((modelEdited: any) => {
                if (modelEdited) {
                    this.viewTemperatureModel();
                    this.toasterComponent.callToast();
                    this.temperatureModelForm.get('MPCTemperatureModelId').patchValue(modelEdited.Id)
                }
            }));
        } else {
            this.subscriptions.push(this.measureprotocolservice.createTemperatureModel(temperatureModel, this.parentKey, this.kundeELId).subscribe((modelAdded: any) => {
                if (modelAdded) {
                    this.viewTemperatureModel();
                    this.toasterComponent.callToast();
                    this.temperatureModelForm.get('MPCTemperatureModelId').patchValue(modelAdded.Id)
                }
            }));
        }
        this.clearModel();
    }

    /**
* edit type of temperature
*/
    editType(temperatureType: TemperatureType) {
        this.temperatureTypeId = temperatureType.Id;
        let result = {
            Name: temperatureType.Name
        };
        (<FormGroup>this.temperatureTypeForm)
            .setValue(result, { onlySelf: true });
    }

    /**
    * edit model of temperature
    */
    editModel(temperatureModel: TemperatureModel) {
        this.temperatureModelId = temperatureModel.Id;
        let result = {
            Name: temperatureModel.Name
        };
        (<FormGroup>this.temperatureModelForm)
            .setValue(result, { onlySelf: true });
    }

    /**
     * delete instrument
     * @param instrumentType
     */
    deleteType(instrumentType: any) {
        this.ShowConfirm = true;
        this.deleteId = instrumentType.Id;
        this.deleteItem = 'TYPE';
    }

    /**
     * delete instrument
     * @param instrumentModel
     */
    deleteModel(instrumentModel: any) {
        this.ShowConfirm = true;
        this.deleteId = instrumentModel.Id;
        this.deleteItem = 'MODEL';
    }

    /**
     * get all temperature list
    */
    getAllCMPTemperature() {
        this.subscriptions.push(this.measureprotocolservice
            .getAllCMPTemperature(this.cmpId)
            .subscribe((result: any) => this.temperature = result));
    }

    /**
     * clearing the data in the form
     */
    onClear() {
        this.saveprogress = false;
        this.cmpTemperatureId = 0;
        this.measureProtocolTemperature = this.fb.group({
            MPCTemperatureTypeId: ['', Validators.required],
            MPCTemperatureModelId: ['', Validators.required],
            Celsius: ['', Validators.required],
            TempDate: ['', Validators.required]
        });
    }

    saveChanges() {
        this.saveprogress = true;
        let temperature = new Temperature();
        temperature.Id = (this.cmpTemperatureId) ? this.cmpTemperatureId : 0;
        temperature.MeasureProtocolCompleteId = this.cmpId;
        temperature.MPCTemperatureTypeId = this.measureProtocolTemperature.value.MPCTemperatureTypeId;
        temperature.MPCTemperatureModelId = this.measureProtocolTemperature.value.MPCTemperatureModelId;
        temperature.Celsius = this.measureProtocolTemperature.value.Celsius;
        temperature.TempDate = HelperService.formatDate(this.measureProtocolTemperature.value.TempDate.formatted);
        temperature.Status = 1;

        if (temperature.Id > 0) {
            temperature.CreatedBy = this.createdBy;
            temperature.CreatedOn = this.createdOn;
            temperature.ModifiedBy = BaseServices.UserId;
            temperature.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .updateCMPTemperature(this.cmpId, temperature)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.getAllCMPTemperature();
                    this.onClear();
                }));
        } else {
            temperature.CreatedBy = BaseServices.UserId;
            temperature.CreatedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .createCMPTemperature(this.cmpId, temperature)
                .subscribe((result) => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.getAllCMPTemperature();
                        this.onClear();
                    }
                }));
        }
    }

    /**
    * editing power leakage
     * @param Id {number}
     */

    editCMPTemperature(id: number) {
        this.subscriptions.push(this.measureprotocolservice.getCMPTemperatureById(this.cmpId, id).subscribe((cmpTemperature: any) => {
            if (cmpTemperature) {
                this.cmpTemperatureId = id;
                this.createdOn = cmpTemperature.CreatedOn;
                this.createdBy = cmpTemperature.CreatedBy;
                this.cmpTemperature = {
                    MPCTemperatureTypeId: cmpTemperature.MPCTemperatureTypeId,
                    MPCTemperatureModelId: cmpTemperature.MPCTemperatureModelId,
                    Celsius: cmpTemperature.Celsius,
                    TempDate: HelperService.formatInputDate(cmpTemperature.TempDate),
                };
                (<FormGroup>this.measureProtocolTemperature).setValue(this.cmpTemperature, { onlySelf: true });
            }
        }));
    }

    /**
     * delete temperature
     * @param temp
     */

    deleteTemperature(temp) {
        this.ShowConfirm = true;
        this.deleteId = temp;
        this.deleteItem = 'TEMPERATURE';
    }

    Confirmation(accept) {
        if (accept) {
            this.ShowConfirm = false;
            if (this.deleteItem == 'TEMPERATURE') {
                this.subscriptions.push(this.measureprotocolservice.deleteCMPTemperature(this.deleteId, this.cmpId).subscribe(result => {
                    this.getAllCMPTemperature();
                    this.toasterComponent.callToastDlt();
                }));
            }
            else if (this.deleteItem == 'TYPE') {
                this.subscriptions.push(this.measureprotocolservice.deleteTemperatureType(this.deleteId,
                    this.kundeELId, this.parentKey, this.featureId).subscribe(() => {
                        this.viewTemperatureType();
                        this.toasterComponent.callToastDlt();
                    }));
            }
            else if (this.deleteItem == 'MODEL') {
                this.subscriptions.push(this.measureprotocolservice.deleteTemperatureModel(this.deleteId,
                    this.kundeELId, this.parentKey, this.featureId).subscribe(() => {
                        this.viewTemperatureModel();
                        this.toasterComponent.callToastDlt();
                    }));
            }
        } else {
            this.ShowConfirm = false;
            this.deleteId = 0;
            this.deleteItem = '';
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

