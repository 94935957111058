<div class="table-options">
    <div class="pull-right" *ngIf="!isAdmin">
        <span class="page-actions text-right">
            <button type="button" class="btn btn-success">
                <span (click)="exportReport('PDF','LAWS AND REGULATIONS')" translate>PRINT_TO_PDF</span>
            </button>
        </span>
        <span class="page-actions text-right">
            <button type="button" class="btn btn-success">
                <a [href]="mailText"></a>
                <span (click)="mailMe()" translate>SEND_REPORT</span>
            </button>
        </span>
    </div>
</div>
<div class="page-title">
    <span translate>LAWS</span>
    <span class="page-actions text-right">
        <button type="button" *ngIf="isAdmin" (click)="initLARForm('LAWS')" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
        </button>
    </span>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" #LARTable
        [value]="electroLARList">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="Title">{{'TITLE'|translate}} <p-sortIcon field="Title"></p-sortIcon></th>
                <th>{{'OPTIONS'| translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-electroLARS>
            <tr>
                <td>
                    <div pTooltip="{{electroLARS.Title}}" tooltipPosition="right">
                        <a (click)="openLink(electroLARS.Url)">{{electroLARS.Title}}</a>
                    </div>
                </td>
                <td class="col-button">
                    <a (click)="initLARForm(electroLARS)" class="btn btn-icon">
                        <i class="icon ic-sm icon-pencil-tip" pTooltip="{{'EDIT' | translate}}"
                            tooltipPosition="top"></i>
                    </a>
                    <a (click)="deleteLAR(electroLARS['Id'])" class="btn btn-icon">
                        <i class="icon ic-sm icon-trash" pTooltip="{{'DELETE' | translate}}" tooltipPosition="top"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
</div>
<p-dialog header="{{(isLaws ? 'SAVE_LAWS' : 'SAVE_REGULATIONS') | translate}}" *ngIf="showLawsDialog"
    [(visible)]="showLawsDialog" [modal]="true" [style]="{width: '500px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'visible'}">
    <form [formGroup]="createLarForm">
        <div class="form-group">
            <label for="title" translate>TITLE
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="title" pInputText />
            </div>
            <control-messages [control]="createLarForm.controls['title']"></control-messages>
        </div>
        <div class="form-group">
            <label for="url" translate>URL
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" formControlName="url" pInputText />
            </div>
            <control-messages [control]="createLarForm.controls['url']"></control-messages>
        </div>
        <div class="action-btns-wrapper">
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-secondary" (click)="clearLaw()" translate>CLEAR</button>
                <button type="button" class="btn btn-outline-secondary" (click)="showLawsDialog = false"
                    translate>CLOSE</button>
                <button type="Submit" class="btn btn-primary" [disabled]="!createLarForm.valid"
                (click)="saveLAR(createLarForm.value)" translate>SAVE</button>
            </div>
        </div>
    </form>
</p-dialog>
<div class="page-title">
    <span translate>REGULATIONS</span>
    <span class="page-actions text-right">
        <button type="button" (click)="initLARForm('REGULATIONS')" class="btn btn-success">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW</span>
        </button>
    </span>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" #electroRegTable
        [value]="electroREGList">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="Title">{{'TITLE'|translate}} <p-sortIcon field="Title"></p-sortIcon></th>
                <th>{{'OPTIONS'| translate}}</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-electroLARS>
            <tr>
                <td>
                    <div pTooltip="{{electroLARS.Title}}" tooltipPosition="right">
                        <a (click)="openLink(electroLARS.Url)">{{electroLARS.Title}}</a>
                    </div>
                </td>
                <td class="col-button">
                    <a (click)="initLARForm(electroLARS)" class="btn btn-icon">
                        <i class="icon ic-sm icon-pencil-tip" pTooltip="{{'EDIT' | translate}}"
                            tooltipPosition="top"></i>
                    </a>
                    <a (click)="deleteLAR(electroLARS['Id'])" class="btn btn-icon">
                        <i class="icon ic-sm icon-trash" pTooltip="{{'DELETE' | translate}}" tooltipPosition="top"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends -->
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<p-dialog header="{{'SEND_MAIL' | translate}}" *ngIf="showMailDialog" [(visible)]="showMailDialog" [modal]="true"
[style]="{width: '500px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'visible'}">
    <form [formGroup]="clientContactForm">
        <div class="form-group">
            <label for="clientname" translate>CONTACT_NAME
            </label>
            <span class="required">*</span>
            <div>
                <p-dropdown [options]="clientContacts" formControlName="contactName" [style]="{'width':'100%'}"
                    #clientDropdown (onChange)="onContactChange($event)"></p-dropdown>
            </div>
            <control-messages [control]="clientContactForm.controls['contactName']"></control-messages>
        </div>
        <div class="form-group">
            <label for="clientname" translate>EMAIL
            </label>
            <div>
                <input type="text" disabled pInputText formControlName="Email" />
            </div>
        </div>
        <div class="action-btns-wrapper">
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-outline-secondary" (click)="showMailDialog = false"
                    translate>CLOSE</button>
                <button type="Submit" class="btn btn-primary" [disabled]="!clientContactForm.valid"
                    (click)="sendReportMail(clientContactForm.value)" translate>SEND</button>
            </div>
        </div>
    </form>
</p-dialog>
<toaster-component></toaster-component>