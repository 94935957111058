import { ReturnStatement } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RequestOptions } from "http";
import { Observable } from "rxjs";
import { ApprovalNotices } from "../follow-ups/models/riskanalysis";
import { BaseServices } from "../kuba.services";
import { SjaForm, SjaTitleForm } from "./components/sja-form";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SJAFormServices {
    headers: Headers;
    options: RequestOptions;
    constructor(private http: HttpClient) { }

    createSjaForm(sjaFormData: SjaForm) {
        return this.http.post(environment.BASE_URL + '/project/sjaform/save', sjaFormData, BaseServices.headerOption)
            .map(result => result);
    }

    getSJAFormList(featureId: number, featureKey: number, BusinessId: number) {
        let applicationId = BaseServices.ApplicationId;
        return this.http.get(`${environment.BASE_URL}/project/get/sjadata/${featureId}/${featureKey}/${BusinessId}/${applicationId}`, BaseServices.headerOption)
            .map(result => result);
    }

    getSJAForm(Id: number) {
        return this.http.get(environment.BASE_URL + '/project/get/sjadata/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    deleteSJAForm(Id: number) {
        return this.http.put(environment.BASE_URL + '/project/delete/sjaform/' + Id, BaseServices.headerOption)
    }

    exportSJAForm(sjaFormId: number, cultureInfo: string, appId:number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: sjaFormId });
        let url = `${environment.BASE_URL}/export-sjaform/${sjaFormId}/${BaseServices.BusinessId}/${BaseServices.ApplicationId}/${cultureInfo}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            const tokenParts = token.split('.');
            tokenParts[1] = tokenParts[1].substring(0, 5) + "W" + tokenParts[1].substring(5);
            // token = tokenParts.join('.'); commented for token issue fix
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }

    addApprovalNotices(approvalNotice: ApprovalNotices[]) {
        return this.http
            .post(
                environment.BASE_URL + '/project/sjaform/approved',
                approvalNotice,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    getAllApproved(featureKey: number) {
        return this.http.get(
            `${environment.BASE_URL}/project/sjaform/approval/${BaseServices.BusinessId}/${featureKey}`,
            BaseServices.headerOption)
            .map(result => result);
    }

    getAllApprovedForKubaControl(featureKey: number) {
        return this.http.get(
            `${environment.BASE_URL}/kubacontrol/sjaform/approval/${BaseServices.BusinessId}/${featureKey}`,
            BaseServices.headerOption)
            .map(result => result);
    }

    SaveSjaTitle(sjaTitleForm: SjaTitleForm) {
        return this.http.post(environment.BASE_URL + '/project/sjaTitle/save', sjaTitleForm, BaseServices.headerOption)
            .map(result => result);
    }

    GetSJATitleList() {
        return this.http.get(`${environment.BASE_URL}/project/sjaformtitle/${BaseServices.ApplicationId}/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(result => result);
    }

    DeleteSJATitle(Id: number) {
        return this.http.delete(`${environment.BASE_URL}/project/sjaTitle/Delete/${Id}`, BaseServices.headerOption)
    }
}