import {
    Component,
    OnInit,
    Input,
    Renderer2,
    OnDestroy,
    ChangeDetectorRef,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CommonChecklistList, CommonChecklistEdit, NodeView, TreeView, TreeData } from 'src/app/kuba/common/models';

import { Rights } from 'src/app/_models';
import { CommonChecklistService } from 'src/app/kuba/common/services/common-checklist.services';
import { ElCheckListServices } from '../../kuba/el-checklist/services/elChecklist.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { FeatureKey } from 'src/app/_models';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

interface ChecklistTemlate {
    name: string;
    id: number;
}

@Component({
    selector: 'ecpl-checklist-viewer',
    templateUrl: './ecpl-checklist-viewer.component.html',
    styleUrls: ['./ecpl-checklist-viewer.component.css']
})
export class EcplChecklistViewerComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @Input() id: number;
    @Input() checklistSrc: any;
    @Input() checklistName: string;
    @Input() editMode: boolean;


    @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();
    showModalWindow: boolean;
    checkListData: any;
    checkListEvent: any;
    checklistTemlates: ChecklistTemlate[];
    selectedChecklistTemplate: ChecklistTemlate;
    createdOn: any;
    featureType: string;
    isChecklistRights = true;
    checklistTemplateList: SelectItem[];
    copyChecklistId: number;
    userRole: string;
    pathValue: string;
    showCompleteChecklist: boolean;
    title: string;
    private subscriptions: Subscription[] = [];

    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private commonChecklistService: CommonChecklistService,
        private elCheckListServices: ElCheckListServices,
        location: LocationStrategy
    ) {
        this.pathValue = route.snapshot.url[0].path;
        location.onPopState(() => {
            this.showModalWindow = false;
            this.renderer.removeClass(document.body, 'modal-open');
        });
    }

    showModal() {
        this.checkListData = null;
        if (this.checklistSrc.content) {
            this.checkListData = this.checklistSrc.content;
        } else {
            this.checkListData = this.checklistSrc;
            if (this.checkListData.checkList.sections === null) {
                this.checkListData.checkList.sections = [];
                this.checkListData.checkList.sections.push({
                    id: 0,
                    position: 1,
                    title: '',
                    status: 1,
                    items: [
                        {
                            id: 0,
                            position: 1,
                            title: '',
                            status: 1
                        }
                    ]
                });
            }


        }
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
        if (workingModule.id == 20 || workingModule.id == 43) {
            if (this.checklistSrc.latestCompletionStatus == 'UNDERCOMPLETION') {
                this.completeChecklist();
            }
            else {
                this.showModalWindow = true;
                this.renderer.addClass(document.body, 'modal-open');
                this.initialBind();
                this.copyChecklistId = null!;
            }
        }
        else {
            this.showModalWindow = true;
            this.renderer.addClass(document.body, 'modal-open');
            this.initialBind();
            this.copyChecklistId = null!;
        }
    }


    hideModal() {
        this.showModalWindow = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    updateScoreIndex(event:any) {
        this.checkListData.checkList.scoreIndex = event.currentTarget.checked;
    }

    updateItemStatus(event:any, item:any) {
        if (event.currentTarget.checked) {
            item.status = 1;
        } else {
            item.status = 2;
        }
    }

    addNewSection(checklist:any) {
        checklist.sections.push({
            id: 0,
            position: 0,
            title: '',
            status: 1,
            items: [
                {
                    id: 0,
                    position: 0,
                    title: '',
                    status: 1
                }
            ]
        });

        this.positioning();
    }

    removesection(checklist:any, section:any) {
        section.status = 0;
        this.positioning();
    }

    addItem(sec:any, index:any) {
        sec.items.push({
            id: 0,
            position: 0,
            title: '',
            status: 1
        });
        this.positioning();
    }

    removeItem(sec:any, item:any) {
        item.status = 0;
        this.positioning();
    }

    completeChecklist() {
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
        let featureName = workingModule.name;
        if (featureName !== 'Manuals') {
            let id = this.route.snapshot.params['Id'];
            this.hideModal();
            if (this.pathValue == "checklist") {
                this.router.navigate(
                    [
                        '../../../../complete-checklist/new/' +
                        this.checkListData.checkList.id,
                        'feature',
                        workingModule.id,
                        this.route.snapshot.params['Id'], 'null'
                    ],
                    { relativeTo: this.route }
                );
            }
            else if (this.pathValue == 'dailytask') {
                if (this.checklistSrc.isUnderCompleteChecklist) {
                    this.router.navigate([
                        '../../../../complete-checklist/incomplete/' +
                        this.checklistSrc.underCompleteChecklistId, 'feature',
                        workingModule.id, this.route.snapshot.params['Id']
                    ],
                        { relativeTo: this.route }
                    );
                }
                else {
                    this.router.navigate([
                        '../../../../complete-checklist/new/' +
                        this.checkListData.checkList.id, 'feature',
                        workingModule.id, this.route.snapshot.params['Id'], 0
                    ],
                        { relativeTo: this.route }
                    );
                }
            }
            else {
                this.router.navigate(
                    [
                        '../../../../../complete-checklist/new/' +
                        this.checkListData.checkList.id,
                        'feature',
                        workingModule.id,
                        this.route.snapshot.params['Id'], 0
                    ],
                    { relativeTo: this.route }
                );
            }

        }
    }

    informationHide() {
        this.showCompleteChecklist = false;
    }

    saveChecklist() {
        let validSection = true;
        this.positioning();

        this.checkListData.checkList.sections =
            this.checkListData.checkList.sections.filter((x:any) => x.status !== 0 || x.id !== 0);
        if (this.checkListData.checkList.title === '') {
            let res:any=false;
            return res;
        }
        let secName = '';
        let secName1 = '';
        let Tottal = 0;
        let secArray = this.checkListData.checkList.sections;

        secArray.some((element:any) => {
            secName = '';
            secName = element.title;
            let result = secArray.filter((element:any) => element.title === secName);
            if (result.length > 1) {
                let result:any=true;
                validSection = false;
                this.alertMsg();
                return result;
            }
        });
        if (validSection) {

            if (this.checkListData.checkList.id === 0) {
                this.checkListEvent = {
                    eventName: 'NewChecklist',
                    item: this.checkListData
                };
            } else {
                this.checkListEvent = {
                    eventName: 'SaveChecklist',
                    item: this.checkListData
                };
            }
            this.saveChanges.emit(this.checkListEvent);
            this.hideModal();
        }
    }

    ngOnInit() {
        this.userRole = BaseServices.UserRole;
        this.checkListData = null;
        this.checkListData = this.checklistSrc.content;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
        this.featureType = workingModule.name;
        this.onChecklistRights();
    }

    // private funs
    positioning(): void {
        let secPos = 1;
        this.checkListData.checkList.sections.forEach((section:any) => {
            if (section.status !== 0) {
                let itemPos = 1;
                section.position = secPos;
                section.items.forEach((item:any) => {
                    if (item.status !== 0) {
                        item.position = itemPos;
                        itemPos++;
                    }
                });
                secPos++;
            }
        });
    }

    alertMsg() {
        alert(
            `Section does not allow duplicate`
        );
    }

    validation() {
        let status = false;
        if (this.checkListData.checkList.title.trim() === '') {
            status = true;
        }
        if (this.checkListData.checkList.sections) {
            this.checkListData.checkList.sections.forEach((section:any) => {
                if (section.title.trim() === '' && section.status !== 0) {
                    status = true;
                }
                if (section.status !== 0) {
                    section.items.forEach((item:any) => {
                        if (item.title.trim() === '' && item.status !== 0) {
                            status = true;
                        }
                    });
                }
            });
        }
        return status;
    }

    copyManualCheckList() {
        this.positioning();
        if (this.copyChecklistId != null) {
            this.checkListData.isCopy = true;
            this.checkListData.appManualId = this.route.snapshot.params['mid'];
            this.checkListData.BusinessId = BaseServices.BusinessId;
            this.checkListData.ApplicationId = BaseServices.ApplicationId;
            this.checkListData.portalId = BaseServices.PortalId;
            this.checkListData.CreatedBy = BaseServices.UserId;
            this.checkListData.roleId = BaseServices.roleId;
            this.checkListData.Id = this.copyChecklistId;
            this.checkListEvent = {
                eventName: 'CopyChecklist',
                item: this.checkListData
            };
            this.saveChanges.emit(this.checkListEvent);
            this.hideModal();
        }
    }

    initialBind() {
        this.getCheckListTemplateList(
            BaseServices.ApplicationId,
            +BaseServices.PortalId,
            BaseServices.BusinessId,
            +BaseServices.roleId
        );
    }

    getCheckListTemplateList(appId: number, portalId: number, BusinessId: number, roleId: number) {
        this.checklistTemplateList = [];
        let appManualId = this.route.snapshot.params['mid'];
        if (roleId === 1) {
            portalId = 0;
            BusinessId = 0
        } else if (roleId == 2) {
            BusinessId = 0;
        } else {
            portalId = 0;
        }
        this.subscriptions.push(this.commonChecklistService.getCheckListforTemplate(appManualId, appId, portalId, BusinessId, roleId)
            .subscribe((result: any) => {
                if (result) {
                    this.checklistTemplateList = [];
                    result.forEach((element:any) => {
                        this.checklistTemplateList.push({ label: element.Title + " (" + element.manualTitle + ")", value: element.Id })
                    });
                }
            }));
    }

    onChecklistRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.CHECKLIST_RIGHTS;
        if (currentUserRole === 'Guest') {
            this.isChecklistRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
        }
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule')!);
        if (workingModule.id === FeatureKey.ELECTRO) {
            this.isChecklistRights = false;
        }
    }

    validateCopyCheckList() {
        if (this.copyChecklistId === undefined || this.copyChecklistId === null) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
