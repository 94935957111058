import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { SupplierContact } from './../Models/supplierContact';
import { SupplierList } from './../Models/supplierList';
import { SupplierServices } from './supplier.services';

@Injectable()
export class SupplierListResolver implements Resolve<SupplierList[]> {
    constructor(private backend: SupplierServices) { }
    resolve(): Observable<SupplierList[]> {
        return this.backend.getAll();
    }
}
@Injectable()
export class ActiveSupplierResolver implements Resolve<SupplierList[]> {
    constructor(private backend: SupplierServices) { }
    resolve(): Observable<SupplierList[]> {
        return this.backend.getActiveSuppliers();
    }
}

@Injectable()
export class SupplierEditResolver implements Resolve<SupplierList> {
    constructor(private backend: SupplierServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getById(+route.params['supplierId']);
    }
}

@Injectable()
export class SupplierContactResolver implements Resolve<any[]> {
    constructor(private backend: SupplierServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getContactType();
    }
}

@Injectable()
export class SupplierContactListResolver implements Resolve<SupplierContact[]> {
    constructor(private backend: SupplierServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<SupplierContact[]> {
        return this.backend.getSupplierContactListById(
            isNaN(+route.params['supplierId']) ? 0 : +route.params['supplierId']
        );
    }
}
