import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

import { BaseServices } from '../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessLogService {
    constructor(private http: HttpClient) {
    }

    getAllLogs(id: number) {
        return this.http.get<any>(environment.BASE_URL + '/accessloghistory/' + id, BaseServices.headerOption)
            .map((response: any) => {
                return response;
            });
    }

    getAllLogsUserName(businessId: number, loginDate: any) {
        return this.http.get<HttpHeaderResponse>(environment.BASE_URL + '/accessloghistoryusername/' + businessId + '/' + loginDate, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
}