import { BusinessSignedListResolver } from './services/declaration-of-conformity.resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppSharedModule } from './../shared/appShared.module';
import { DeclarationConformityServices } from './services/declaration-conformity.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeclarationOfConformityComponent } from './declaration-of-conformity.component';
import { DeclarationConformityComponent } from './components/declaration-conformity.component';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { MyDatePickerModule } from 'mydatepicker';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';

@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        TranslateModule,
        CommonModule,
        ConfirmDialogModule,
        InputSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CalendarModule,
        MyDatePickerModule,
        TableModule,
        DialogModule,
        InputSwitchModule
    ],
    exports: [DeclarationOfConformityComponent, DeclarationConformityComponent],
    declarations: [
        DeclarationOfConformityComponent,
        DeclarationConformityComponent
    ],
    providers: [DeclarationConformityServices, BusinessSignedListResolver]
})
export class DeclarationOfConformityModule {}
