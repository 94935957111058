import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Rights } from 'src/app/_models';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { QualitySystem, QualitySystemUser } from '../models/quality-system';
import { QualitySystemService } from '../services/quality-system.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quality-system-edit',
  templateUrl: './quality-system-edit.component.html'
})
export class QualitySystemEditComponent implements OnInit {



  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  InternalControlForm: FormGroup;
  icId: number;
  isAdministrateIC = true;
  icObj: any = [];
  selectedUsers: any = [];
  interanlUsers: any[];
  userId: number;
  userSubscribers: SelectItem[];
  selectedSubscribers: SelectItem[];
  emailUsers: any[];
  bindAllUsers = false;
  bindSelectedUsers = true;
  selectedEmailSubscriber = [];
  selectAllEmailSubscriber = [];
  ICUsers = new QualitySystemUser();
  ICdetails = new QualitySystem();
  GetICdetails: any;
  checkedAll:boolean;
  private subscriptions: Subscription[] = [];
  /**
   *
   * @param formBuilder {{FormBuilder}}
   * @param qualitySystemService {{qualitySystemService}}
   * @param activatedRoute {{ActivatedRoute}}
   * @param location {{Location}}
   */
  constructor(
      private formBuilder: FormBuilder,
      private qualitySystemService: QualitySystemService,
      private activatedRoute: ActivatedRoute,
      private location: Location,
      private translate: TranslateService, ) {
      this.InternalControlForm = this.formBuilder.group({
          'Title': ['', Validators.required],
          'Description': [''],
          'EditorOnly': [''],
          'IsShowDeviationChecked': [false],
          selectedEmailSubscriber: new FormControl(this.selectedEmailSubscriber),
          selectAllEmailSubscriber: new FormControl(this.selectAllEmailSubscriber)
      });
      this.userId = +BaseServices.UserId;
  }
  /**
   * edit internal control
   */
  ngOnInit() {
      document.querySelector("body").classList.remove("opened");
      this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
          let nid = params['id'];
          this.icId = +nid;
          if (this.icId !== 0) {
            this.subscriptions.push(this.qualitySystemService.getInternalControlById(this.icId).subscribe(result => {
                  if (result) {
                      this.userId = result.CreatedBy;
                      this.icObj = {
                          Title: result.Title,
                          Description: result.Description,
                          EditorOnly: result.IsEditorOnly,
                          IsShowDeviationChecked: result.ShowDeviation,
                          selectedEmailSubscriber: result.QSUserDto.filter(x => x.IsEmail === true),
                          selectAllEmailSubscriber: result.QSUserDto.filter(x => x.IsEmail === true)
                      };
                      this.InternalControlForm.setValue(this.icObj, { onlySelf: true });
                      this.GetICdetails = result;
                      if (this.GetICdetails) {
                          if (this.GetICdetails.QSUserDto) {
                              this.GetICdetails.QSUserDto.forEach((element: any) => {
                                  let selectedUsers = this.interanlUsers.find(x => x.Id === element.UserId);
                                  if (selectedUsers) {
                                      selectedUsers.status = true;
                                      selectedUsers.isEmail = false;
                                      this.checked(selectedUsers)
                                      if (element.IsEmail) {
                                          this.selectedEmailSubscriber.push({
                                              Id: element.UserId,
                                              FeatureKey: element.FeatureKey,
                                              FeatureId: element.FeatureId
                                          });
                                          this.selectAllEmailSubscriber.push({
                                              Id: element.UserId,
                                              FeatureKey: element.FeatureKey,
                                              FeatureId: element.FeatureId
                                          });
                                      }
                                  }
                              });
                          }
                          if (result.QSUserDto) {
                              this.InternalControlForm.patchValue({
                                  selectedEmailSubscriber: this.selectedEmailSubscriber
                              });
                          }
                      }
                  }
              }));
          }
      }));
      this.onAdministrateInternalControl();
      let users = this.activatedRoute.snapshot.data['contact'];
      if (users) {
          this.interanlUsers = users;
      }
      this.selectedSubscribers = [];
      this.userSubscribers = [];
      let userSubscription = this.activatedRoute.snapshot.data[
          'users_subscription'
      ];
      this.emailUsers = userSubscription;
      this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
          this.userSubscribers = [];
          if (userSubscription) {
              userSubscription.forEach((element: any) => {
                  this.selectedSubscribers.push({
                      label: element.Name,
                      value: {
                          Id: element.Id,
                          FeatureKey: element.FeatureKey,
                          FeatureId: element.FeatureId
                      }
                  });
                  if (element.Id == BaseServices.UserId) {
                      this.userSubscribers.push({
                          label: element.Name,
                          value: {
                              Id: element.Id,
                              FeatureKey: element.FeatureKey,
                              FeatureId: element.FeatureId
                          }
                      });
                  }
              });
          }
      }));
  }
  /**
   * save and update internal control
   * @param Icdata {{any}}
   */
  onSubmitTemplateBased(Icdata) {
      let ICdetails = this.InternalControlForm.value;
      let userId = BaseServices.UserId;
      let name = BaseServices.userName;
      let checkUserId = this.selectedUsers.filter(x => x.Id == userId);
      if (checkUserId.length === 0 && window.name !== 'Remote') {
          this.selectedUsers.push({ Id: userId, Name: name });
      }
      if (this.icId) {
          ICdetails.Id = this.icId;
          ICdetails.Status = '1';
          ICdetails.BusinessId = BaseServices.BusinessId;
          ICdetails.ApplicationId = BaseServices.ApplicationId;
          ICdetails.CreatedBy = this.userId;
          ICdetails.ModifiedBy = BaseServices.UserId;
          ICdetails.IsEditorOnly = (ICdetails.EditorOnly ? ICdetails.EditorOnly : false);
          ICdetails.CreatedOn = new Date();
          ICdetails.Title = ICdetails.Title;
          ICdetails.Description = ICdetails.Description;
          ICdetails.ShowDeviation = (ICdetails.IsShowDeviationChecked) ? ICdetails.IsShowDeviationChecked: false;
          if ((this.bindAllUsers) && this.InternalControlForm.value.selectAllEmailSubscriber) {
              ICdetails.QSEmailSubscribers = [];
              let emailAllSubscriber = this.InternalControlForm.value.selectAllEmailSubscriber;
              emailAllSubscriber.map(function (x) { x.IsChecked = true; return x });
              ICdetails.QSEmailSubscribers.push(...emailAllSubscriber);
          }
          if ((this.bindSelectedUsers) && this.InternalControlForm.value.selectedEmailSubscriber) {
              ICdetails.QSEmailSubscribers = [];
              let emailSubscriber = this.InternalControlForm.value
                  .selectedEmailSubscriber;
              emailSubscriber.map(function (x) {
                  x.IsChecked = true;
                  return x
              });
              ICdetails.QSEmailSubscribers.push(...emailSubscriber);
          }
          let ICUsers: QualitySystemUser = new QualitySystemUser();
          this.ICUsers.QualitySystemId = this.icId ? this.icId : 0;
          let Ids: any = ([] = []);
          this.selectedUsers.forEach((element: any) => {
              Ids.push({
                  UserId: element.Id,
                  QualitySystemId: this.ICUsers.QualitySystemId,
                  Name: element.Name
              });
          });
          ICdetails.QSUserDto = Ids;
          this.subscriptions.push(this.qualitySystemService.update(ICdetails)
              .subscribe(result => {
                  this.toasterComponent.callToast();
                  this.gotoBack();
              }))
      } else {
          let ICdetails = new QualitySystem();
          ICdetails.Id = 0;
          ICdetails.Status = '1';
          ICdetails.BusinessId = BaseServices.BusinessId;
          ICdetails.ApplicationId = BaseServices.ApplicationId;
          ICdetails.CreatedBy = BaseServices.UserId;
          ICdetails.Title = this.InternalControlForm.value.Title;
          ICdetails.IsEditorOnly = (this.InternalControlForm.value.EditorOnly) ? this.InternalControlForm.value.EditorOnly : false;
          ICdetails.Description = this.InternalControlForm.value.Description;
          ICdetails.ShowDeviation = (this.InternalControlForm.value.IsShowDeviationChecked) ? this.InternalControlForm.value.IsShowDeviationChecked: false;
          if ((this.bindAllUsers) && this.InternalControlForm.value.selectAllEmailSubscriber) {
              ICdetails.QSEmailSubscribers = [];
              let emailAllSubscriber = this.InternalControlForm.value.selectAllEmailSubscriber;
              emailAllSubscriber.map(function (x) { x.IsChecked = true; return x });
              ICdetails.QSEmailSubscribers.push(...emailAllSubscriber);
          }
          if ((this.bindSelectedUsers) && this.InternalControlForm.value.selectedEmailSubscriber) {
              ICdetails.QSEmailSubscribers = [];
              let emailSubscriber = this.InternalControlForm.value
                  .selectedEmailSubscriber;
              emailSubscriber.map(function (x) {
                  x.IsChecked = true;
                  return x
              });
              ICdetails.QSEmailSubscribers.push(...emailSubscriber);
          }
          let ICUsers: QualitySystemUser = new QualitySystemUser();
          this.ICUsers.QualitySystemId = this.icId ? this.icId : 0;
          let Ids: any = ([] = []);
          this.selectedUsers.forEach((element: any) => {
              Ids.push({
                  UserId: element.Id,
                  QualitySystemId: this.ICUsers.QualitySystemId,
                  Name: element.Name
              });
          });
          ICdetails.QSUserDto = Ids;
          this.subscriptions.push(this.qualitySystemService.add(ICdetails)
              .subscribe(result => {
                  this.toasterComponent.callToast();
                  this.gotoBack();
              }));
      }
      this.selectedUsers = [];
  }
  /**
   * go to internal control list
   */
  gotoBack() {
      this.location.back();
  }
  // hide and show the save button based on the rights
  onAdministrateInternalControl() {
      let userRightsId = Rights.ADMINISTRATE_QUALITY_SYSTEM;
      let role = BaseServices.UserRole;
      if (role === 'User') {
          this.isAdministrateIC = BaseServices.checkUserRights(userRightsId, role);
      }
  }

  checked(value) {
      if (value.status) {
          this.selectedUsers.push(value);
          if (this.emailUsers) {
              this.emailUsers.forEach((element: any) => {
                  if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.isEmail)) {
                      this.userSubscribers.push({
                          label: element.Name,
                          value: {
                              Id: element.Id,
                              FeatureKey: element.FeatureKey,
                              FeatureId: element.FeatureId
                          }
                      });
                  }
              });
          }
      } else {
          _.remove(this.selectedUsers, (val) => {
              if (this.userSubscribers) {
                  this.userSubscribers = this.userSubscribers.filter(x => x.value.Id !== value.Id);
                  if (this.selectedEmailSubscriber) {
                      this.selectedEmailSubscriber = this.selectedEmailSubscriber.filter(x => x.Id !== value.Id);
                      this.InternalControlForm.get('selectedEmailSubscriber').reset();
                      this.InternalControlForm.patchValue({
                          selectedEmailSubscriber: this.selectedEmailSubscriber
                      });
                  }
              }
              return val === value;
          })
      }
  }

  checkAll(event) {
      _.forEach(this.interanlUsers, (item: any) => {
          if (event) {
              item.status = true;

          } else {
              item.status =
                  // to remain checked state for current user
                  (this.userId === +item.Id) ? true : false;
          }
      });

      this.selectedUsers = _.create(this.interanlUsers);
      if (event === true) {
          this.userSubscribers = [];
          if (this.selectedUsers) {
              this.selectedUsers.forEach(element => {
                  let checkUserId = this.emailUsers.filter(x => x.Id === element.Id);
                  if (checkUserId.length > 0) {
                      this.userSubscribers.push({
                          label: element.Name,
                          value: {
                              Id: element.Id,
                              FeatureKey: checkUserId[0].FeatureKey,
                              FeatureId: checkUserId[0].FeatureId
                          }
                      });
                  }                   
              })
          }
      } else {
          if (this.userSubscribers) {
              this.userSubscribers = this.userSubscribers.filter(x => x.value.Id === this.userId);
          }
          this.selectedEmailSubscriber = [];
          this.InternalControlForm.get('selectedEmailSubscriber').reset();
          this.InternalControlForm.controls['selectedEmailSubscriber'].setValue(this.selectedEmailSubscriber);
      }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
