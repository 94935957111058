<div class="main-content ">
    <div class="main-heading">
        <strong> Contract Details of - {{ serviceAgreementTitle }}</strong>
    </div>
    <div class="card">
        <div class="card-body">

            <div class="panel-tabs" *ngIf="!isEditMode">
                <nav>
                    <ul class="nav nav-pills">
                        <li [class.active]="item === 'document/list'" [routerLinkActive]="['active']">
                            <a (click)="changeMainType('document/list');" href="javascript:void(0);">
                                <span translate>DOCUMENTS</span>
                            </a>
                        </li>

                        <li [class.active]="item === 'deviation/list'" [routerLinkActive]="['active']"
                          *ngIf="isDefault">
                            <a (click)="changeMainType('deviation/list');" href="javascript:void(0);">
                                <span translate>DEVIATION</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'taskmanager/list'" *ngIf="isBoth">
                            <a (click)="changeMainType('taskmanager/list');" href="javascript:void(0);">
                                <span translate>TASK_MANAGER</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'timetable/list'" *ngIf="isDefault">
                            <a (click)="changeMainType('timetable/list');" href="javascript:void(0);">
                                <span translate>TIME_TABLE</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'safetyjobs/list'" *ngIf="isBoth">
                            <a (click)="changeMainType('safetyjobs/list');" href="javascript:void(0);">
                                <span translate>SAFETY_JOB_ANALYSIS</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'registration/list'" *ngIf="isBoth">
                            <a (click)="changeMainType('registration/list');" href="javascript:void(0);">
                                <span translate>REGISTRATION</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'fdv-document/list'" *ngIf="isProject">
                            <a (click)="changeMainType('fdv-document/list');" href="javascript:void(0);">
                                <span translate>FDV_DOCUMENTS</span>
                            </a>
                        </li>
                        <li *ngIf="isFoodSafety" [class.active]="item === 'production/list'">
                            <a (click)="changeMainType('production/list');" href="javascript:void(0);">
                                <span translate>PRODUCTION</span>
                            </a>
                        </li>
                        <li *ngIf="isFoodSafety" [class.active]="item === 'reception-control/list'">
                            <a (click)="changeMainType('reception-control/list');" href="javascript:void(0);">
                                <span class="link-item-txt" translate>RECEPTION_CONTROL</span>
                            </a>
                        </li>
                        <li *ngIf="isFoodSafety" [class.active]="item === 'manual-control/list'">
                            <a (click)="changeMainType('manual-control/list');" href="javascript:void(0);">
                                <span translate>MANUAL_CONTROL</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'declaration-of-conformity/page'" *ngIf="isProject">
                            <a (click)="changeMainType('declaration-of-conformity/page');" href="javascript:void(0);">
                                <span translate>DECLARATION_CONFORMITY</span>
                            </a>
                        </li>
                        <li [class.active]="item === 'crew/list'" *ngIf="isProject">
                            <a (click)="changeMainType('crew/list');" href="javascript:void(0);">
                                <span translate>CREW_LIST</span>
                            </a>
                        </li>
                        <li *ngIf="isVehicle">
                            <a (click)="changeMainType('taskmanager/list');" href="javascript:void(0);">
                                <span translate>VEHICLE_TASK</span>
                            </a>
                        </li>
                        <li *ngIf="isVehicle">
                            <a (click)="changeMainType('vehicle-consumption/list');" href="javascript:void(0);">
                                <span translate>VEHICLE_CONSUMPTION</span>
                            </a>
                        </li>
                        <li *ngIf="isVehicle">
                            <a (click)="changeMainType('vehicle-checklist/list');" href="javascript:void(0);">
                                <span translate>VEHICLE_CHECKLIST</span>
                            </a>
                        </li>
                        <li *ngIf="isVehicle">
                            <a (click)="changeMainType('vehicle-control/list');" href="javascript:void(0);">
                                <span translate>VEHICLE_CONTROL</span>
                            </a>
                        </li>
                        <li *ngIf="isVehicle">
                            <a (click)="changeMainType('vehicle-tracking/list');" href="javascript:void(0);">
                                <span translate>VEHICLE_TRACKING</span>
                            </a>
                        </li>
                        <li *ngIf="isAssignProject">
                            <a (click)="changeMainType('assign/projects');" href="javascript:void(0);">
                                <span translate>VEHICLE_TRACKING</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="row">
                <div class="col-sm-2" *ngIf="isEditMode">
                    <div>
                        <p-dropdown [options]="manualList" [(ngModel)]="selectedManual" [style]="{'width':'100%'}"
                          (onChange)="changeManual();"></p-dropdown>
                        <common-tree *ngIf="manualData.id" [treeData]="manualData"
                          (onTreeNodeClick)="onTreeNodeClick($event)" (onNodeDrag)="onNodeDrag($event)"></common-tree>
                    </div>
                </div>
                <div class="col-sm-8 detail-view" [ngSwitch]="nodeType" *ngIf="!isMainTab">
                </div>
                <div class="col-sm-2" *ngIf="!isMainTab">
                    <common-tree [treeData]="treeData" (onTreeNodeClick)="onTreeNodeClick($event)"
                      (onNodeDrag)="onNodeDrag($event)"></common-tree>
                </div>
                <div class="col-sm-12" [hidden]="!isMainTab">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>