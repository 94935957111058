<div class="panel panel-default">
    <div class="panel-heading">
        <strong *ngIf="!isMedi3"><span translate>FLEXI</span></strong>
        <strong *ngIf="isMedi3"><span translate>BHT</span></strong>
    </div>
    <ecpl-tab-navigation [linkItems]="enabledAdditionalFeatures" [parentId]="fId"></ecpl-tab-navigation>
    <div class="card">
    <div class="card-body">
    <div class="panel-body" style="background: #fff; border: 7px solid #fff;">
        <router-outlet></router-outlet>
    </div>
    </div>
    </div>
</div>