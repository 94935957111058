export class SubContractor {
    Id: number;
    Name: string;
}

export class ContractorData {
    ProjectId: number;
    ContractorId: number;
    Name: string;
}



