import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ElectroService } from '../services/electro.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Rights, FeatureKey } from 'src/app/_models';
import { BaseServices } from '../../kuba.services';
import { HelperService } from 'src/app/_services/helper.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    selector: 'electro-list',
    templateUrl: 'electro-list.component.html'
})

export class ElectroListComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('electroTable',{static: false}) electroTable: Table;
    electroList: any;
    visibility: any;
    statuses: any;
    responsible: any;
    private subscriptions: Subscription[] = [];
    isGuestHideButton = true;
    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private confirmationService: ConfirmationService,
        private electroService: ElectroService,
    ) {
        if (+BaseServices.roleId === 4) {
            this.isGuestHideButton = false;
        }
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.SERVICE_LEADER_ELECTRO;
        if (+BaseServices.roleId === 5) {
            this.isGuestHideButton = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        }
    }

    ngOnInit() {
        this.electroList = this.route.snapshot.data['list'];
        this.bindStatusDropdown();
    }

    /**
     * bind status dropdown
     */
    bindStatusDropdown() {
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statuses = [];
            this.statuses.push(
                { label: val.SELECT, value: null },
                { label: val.ACTIVE, value: '1' },
                { label: val.INACTIVE, value: '2' }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_VISIBILITY').subscribe(val => {
            this.visibility = [];
            this.visibility.push(
                { label: val.SELECT, value: '' },
                { label: val.VISIBLE, value: 1 },
                { label: val.HIDDEN, value: 2 }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.responsible = [];
            this.responsible.push({ label: val.SELECT, value: null });
            let Workers = this.route.snapshot.data['contractworkers'];
            if (Workers) {
                Workers.forEach((user: any) => {
                    let rights = (user.Rights) ? JSON.parse(user.Rights) : null;
                    if (HelperService.ApprovalPersonRightsCheck(rights, Rights.SERVICE_LEADER_ELECTRO)) {
                        this.responsible.push({ label: user.Name, value: user.Id });
                    }
                    else if (user.RoleId == 3) {
                        this.responsible.push({ label: user.Name, value: user.Id });
                    }
                });
            }
        }));
    }

    /**
   * custom filter datatable of status
   */
    onStatusChange(e: any, statusDropdown: any) {
        if (e.value) {
            this.electroTable.filter(+statusDropdown.value,'Status','equals');
        } else {
            this.electroTable.filter(null,'Status','equals')
        }

    }

    onResponsibleChange(e: any, respDropdown: any) {
        if (e.value) {
            this.electroTable.filter(+respDropdown.value,'ResponsibleUserId','equals');
        } else {
            this.electroTable.filter(null,'ResponsibleUserId','equals');
        }

    }

    onVisibilityChange(e: any, visiblityDropdown: any) {
        if (e.value) {
            this.electroTable.filter(+visiblityDropdown.value,'Visibilty','equals');
        } else {
            this.electroTable.filter(null,'Visibilty','equals');
        }

    }
    /**
     * 
     * @param contractNumber 
     * @param contractName 
     */
    saveContractNumber(el) {
        localStorage.setItem('contractNumber', el.ContractNumber);
        localStorage.setItem('contractName', el.ContractName);
        localStorage.setItem('clientName', el.ClientName);
        localStorage.setItem('clientLogo', el.ClientLogo);
    }
    /**
     * delete specific electro contract.
     * @param electroId 
     */
    deleteElectro(electroId) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.electroService.deleteElectro(electroId).subscribe(result => {
                    this.subscriptions.push(this.electroService.getContractsbybusiness().subscribe(result => {
                        this.electroList = result;
                    }));
                    this.toasterComponent.callToastDlt();
                }));
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}