import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { EL } from '../models/el-edit';
import { ELList } from '../models/el-list.model';
import { BaseServices } from './../../kuba.services';
import { ELService } from './el.service';
import { HelperService } from 'src/app/_services/helper.service';
import { Rights, Roles } from 'src/app/_models';


@Injectable()
export class ELListResolver implements Resolve<ELList[]> {
    constructor(private backend: ELService) { }
    resolve(): Observable<ELList[]> {
        if (BaseServices.ApplicationId === 4 || BaseServices.ApplicationId === 2 ) {
            let userRights = BaseServices.getUserRights();
            let isExits = HelperService.checkRightsData(JSON.parse(userRights), Rights.IK_KUNDE_EL_RIGHTS)
            if (BaseServices.roleId === Roles.EDITOR.toString() ||
            ((BaseServices.roleId === Roles.USER.toString() || BaseServices.roleId === Roles.GUEST.toString())
             && isExits === true)) {
                return this.backend.getELList();
            } else {
                return this.backend.getELByUser(BaseServices.UserId);
            }
        }
        return null;
    }
}

@Injectable()
export class ELListByUserResolver implements Resolve<ELList[]> {
    constructor(private backend: ELService) { }
    resolve(): Observable<ELList[]> {
        return this.backend.getELByUser(BaseServices.UserId);
    }
}

@Injectable()
export class ELEditResolver implements Resolve<EL[]> {
    constructor(private backend: ELService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<EL[]> {
        return this.backend.getELById(+route.params['id']);
    }
}

@Injectable()
export class ELFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: ELService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getELFeatures(BaseServices.BusinessId);
    }
}

