import { kubaFeatureDetailsRoutes } from 'src/app/kuba/shared/kuba-feature-details/Kuba-feature-details.routes';
import { registrationRoutes } from './../Registration/registration.routing';
import { safetyjobRoutes } from './../safety-job-analysis/jobanalysis.routing';
import { timetableRoutes } from './../time-table/timetable.routing';
import { taskmanagerRoutes } from './../task-manager/task-manager.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
import { AuthGuard } from './../../_guards/auth.guard';
import { Route } from '@angular/router';
import { InternalControlListComponent } from './components/internal-control-list.component';
import { InternalControlEditComponent } from './components/internal-control-edit.component';
import { InternalControlViewComponent } from './components/internal-control-view.component';
import { InternalControlComponent } from './internal-control.component';
import {
    InternalControlResolver,
    InternalControlFeaturesResolver,
    InternalControlEditResolver,
    InternalControlByUserResolver,
    InternalControlNameResolver
} from './services/internal-control.resolver.service';
import { FeatureDocumentRoutes } from 'src/app/kuba/feature-documents/feature-document.routing';
import { 
    EmployeeContactUserResolver, 
    EmployeeContactUserSubscriptionResolver 
} from '../users/services/user.resolver.service';
import { InternalListComponent } from './components/internalcontrol-list.component';
import { HazardAnalysisComponent } from '../foodsafety/components/hazard-analysis/hazard-analysis.component';
import { HazardAnalysisListResolver } from '../foodsafety/services/foodsafety.resolver';
export const internalcontrolRoutes: Route[] = [
    {
        path: 'internal-control',
        component: InternalControlComponent,
        children: [
            {
                path: 'list',
                component: InternalListComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'all', pathMatch: 'full' },
                    {
                        path: 'own',
                        component: InternalControlListComponent,                       
                        resolve: {
                            list: InternalControlByUserResolver
                        }
                    },
                    {
                        path: 'all',
                        component: InternalControlListComponent,                    
                        resolve: {
                            list: InternalControlResolver,
                        }
                    }
                ]                
            },
            {
                path: 'create/:id',
                component: InternalControlEditComponent,
                resolve:{
                    contact: EmployeeContactUserResolver,
                    users_subscription: EmployeeContactUserSubscriptionResolver, 
                    internalControlName: InternalControlNameResolver
                }
            },
            {
                path: 'details/:id',
                data: { parent: 'IC' },
                component: InternalControlViewComponent,
                resolve: {
                    additionalFeatures: InternalControlFeaturesResolver,
                    edit: InternalControlEditResolver
                },
                canActivate: [AuthGuard],
                children: [
                    ...kubaFeatureDetailsRoutes,
                    ...FeatureDocumentRoutes,
                    ...deviationRoutes,
                    ...taskmanagerRoutes,
                    ...timetableRoutes,
                    ...safetyjobRoutes,
                    ...registrationRoutes,
                    {
                        path: 'hazardanalysis', 
                        component: HazardAnalysisComponent ,
                        resolve:{
                            list: HazardAnalysisListResolver
                        }
                    }
                ]
            }
        ]
    }
];
