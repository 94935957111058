import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Meeting } from '../models/meeting';
import { BaseServices } from './../../kuba.services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MeetingService {
    constructor(private http: HttpClient) { }

    list() {
        return this.http.get(`${environment.BASE_URL}/meetings/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(res => res);
    }
    getById(id: number) {
        return this.http.get(`${environment.BASE_URL}/meetings/${id}`, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getAllMeetingType() {
        return this.http.get(`${environment.BASE_URL}/meetingtypes/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map((res: any) => res);

    }
    getMeetingByBusiness(id: number) {
        return this.http.get(`${environment.BASE_URL}/meeting/${id}`, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getUser(id: number) {
        return this.http.get(environment.BASE_URL + `/project/ContactLookup/${BaseServices.BusinessId}`, BaseServices.headerOption)
            .map(result => result);
    }
    add(newMeeting: Meeting) {
        return this.http.post(environment.BASE_URL + '/meeting', newMeeting, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    addMeetingType(data:any) {
        return this.http.post(environment.BASE_URL + '/meetingtype/', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    updateMeetingType(data:any) {
        return this.http.put(environment.BASE_URL + '/meetingtype/', data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    deleteMeetingType(id:any) {
        return this.http.delete(environment.BASE_URL + '/meetingtypedelete/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    update(id:any, data:any) {
        return this.http.put(environment.BASE_URL + '/meeting/' + id, data, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    delete(id:any) {
        return this.http.delete(environment.BASE_URL + '/meeting/' + id, BaseServices.headerOption)
            .map(result => result);

    }
    attachment(id:any){
        return this.http.get(environment.BASE_URL + `/meetingAttachment/`+id, BaseServices.headerOption)
            .map(result => result);
    }
    getMeetingType() {
        return this.http.get(environment.BASE_URL + `/meetingtypes/${BaseServices.BusinessId}`)
            .map((response: any) => { return response });
    }
    // bind business dropdown
    getBusiness() {
        return this.http.get(environment.BASE_URL + '/businesses', BaseServices.headerOption)
            .map(result => result);
    }

      /**
     * delete documents
     * @param Id {any}
     */
       deleteMeetingDocument(Id: any) {
        return this.http
            .delete(environment.BASE_URL + '/delete-meeting-document/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    meetingReportGenerate( businessId: number, meetingId: number,cultureInfo: string, userId : number) {
        let dynamicType = 'application/pdf';
        let myData = JSON.stringify({ data: meetingId });
        let url = `${environment.BASE_URL}/export-MeetingPrintDetails/${businessId}/${meetingId}/${cultureInfo}/${userId}`;
        return Observable.create(observer => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            let session = JSON.parse(sessionStorage.getItem('session'));
            let type = 'Bearer';
            let token = session.AccessToken;
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', type + ' ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let contentType = dynamicType;
                        let blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            }
            xhr.send(myData);
        });
    }
}
