import { BusinessServices } from './../../businesses/services/business.services';
import { BusinessPortalService } from './../../portals/services/business-portal.services';
import { FeatureKey } from 'src/app/_models';
import { HelperService } from './../../../_services/helper.service';
import { ValidationService } from './../../shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { ProjectAdminList } from './../models/project-admin-list';
import { ActivatedRoute } from '@angular/router';
import { ProjectAdmin } from './../models/project-admin';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ProjectAdminServices } from './../service/project-admin.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, Toast, ToasterConfig } from 'angular2-toaster';
import { Subscription } from 'rxjs';
@Component({

    selector: 'project-admin',
    templateUrl: 'project-admin.component.html'
})
export class ProjectAdminComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    IsHidden = false;
    IsHide = false;
    projectForm: FormGroup;
    ChooseBusiness: SelectItem[];
    ChoosePortal: SelectItem[];
    data: any;
    projectList: ProjectAdminList[];
    private subscriptions: Subscription[] = [];


    constructor(
        private location: Location,
        private projectAdminService: ProjectAdminServices,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private toasterService: ToasterService,
        private businessPortalService: BusinessPortalService,
        private businessService: BusinessServices,


    ) {
        this.projectForm = this.formBuilder.group({
            'ProjectName': ['', [Validators.required, ValidationService.noWhitespaceValidator], this.isProjectNameUnique.bind(this)],
            'Business': [''],
            'Portal': ['']
        })
    }
    gotoBack() {
        this.location.back();
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.ChooseBusiness = [];
        this.subscriptions.push(this.businessService.list().subscribe(portalList => {
            this.ChooseBusiness.push({ label: 'Select a Portal', value: null });
            if (portalList) {
                portalList.forEach((list: any) => {
                    let rights = (list.Features) ? JSON.parse(list.Features) : null;
                    let hasAccess = HelperService.featureBasedCheck(rights, FeatureKey.PROJECT);
                    if (hasAccess) {
                        this.ChooseBusiness.push({ label: list.CompanyName, value: list.Id });
                    }
                });
            }
        }));

        this.ChoosePortal = [];
        this.subscriptions.push(this.businessPortalService.getAll().subscribe(portalList => {
            this.ChoosePortal.push({ label: 'Select a Portal', value: null });
            if (portalList) {
                portalList.forEach((list: any) => {
                    let rights = (list.Features) ? JSON.parse(list.Features) : null;
                    let hasAccess = HelperService.featureBasedCheck(rights, FeatureKey.PROJECT);
                    if (hasAccess) {
                        this.ChoosePortal.push({ label: list.Title, value: list.Id });
                    }
                });
            }
        }));
    }
    onChange() {
        this.IsHide = true;
    }
    onChangeevent() {
        this.IsHidden = true;
    }

    saveproject() {
        if (this.projectForm.valid) {
            let project = new ProjectAdmin();
            project.Name = this.projectForm.value.ProjectName;
            project.ApplicationId = BaseServices.ApplicationId
            project.CreatedOn = new Date();
            project.PortalId = (this.projectForm.value.Portal) ? this.projectForm.value.Portal : 0;
            project.BusinessId = (this.projectForm.value.Business) ? this.projectForm.value.Business : 0;
            if (project.PortalId > 0 || project.BusinessId > 0) {
                project.IsCommon = 0;
            } else {
                project.IsCommon = 1;
            }
            project.Status = '1';
            project.CreatedBy = BaseServices.UserId;
            project.ModifiedOn = new Date();
            this.subscriptions.push(this.projectAdminService.create(project)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }));
        }
    }
    /**
         * project name validation
         */
    isProjectNameUnique(control: FormControl) {
        const q = new Promise(resolve => {
            setTimeout(() => {
                this.subscriptions.push(this.projectAdminService.checkProjectName(control.value).subscribe(
                    x => {
                        if (!x) {
                            resolve(null);
                        } else {
                            resolve({ invalidProjectName: true });
                        }
                    },
                    () => {
                        resolve({ invalidProjectName: true });
                    }
                ));
            }, 1000);
        });
        return q;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
