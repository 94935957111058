import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'course.component.html'
})
export class CourseComponent implements OnInit {
    public data: any;
    href: any;
    constructor(private router : Router) {}

    ngOnInit(): void {
       this.href = this.router.url;
        document.querySelector("body").classList.remove("opened");
    }
}
