<div class="card-body">
    <div class="tab-content">
        <form [formGroup]="deviationForm">
            <div class="row">
                <div class="col-sm-5">
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>INSPECTION_NO</span>
                        </label>
                        <div>
                            <input type="text" pInputText readonly formControlName="InspectionNumber" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>TITLE</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="Title" />
                            <control-messages [control]="deviationForm.controls['Title']"></control-messages>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>TIME_OF_EVENT</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <div style="display:flex;justify-content:space-between;max-width:380px">
                                <span style="width: 140px;">
                                    <my-date-picker name="EventDate" [options]="dateOptions" formControlName="EventDate"
                                      placeholder="{{'SELECT_EVENT_DATE'|translate}}">
                                    </my-date-picker>
                                </span>
                                <span style="width: 100px;">
                                    <p-calendar [timeOnly]="true" readonlyInput="true" [style]="{'width':'100%'}"
                                      formControlName="EventTime" styleClass="dateTimeField">
                                    </p-calendar>
                                </span>
                                <span>
                                    <button type="button" class="btn btn-primary" (click)="bindEventDate()">
                                        <span translate>NOW</span>
                                    </button>
                                    <button type="button" class="btn btn-outline-secondary" [disabled]="!IsClear"
                                      (click)="clearEventDate()">
                                        <span translate>CLEAR</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>CONTROL_AREA</span>
                        </label>
                        <div>
                            <p-dropdown formControlName="Building" [options]="buildingList"
                              placeholder="{{'SELECT' | translate}}" [style]="{'width':'100%'}"></p-dropdown>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>LOCATION</span>
                            <span class="required">*</span>
                        </label>
                        <input type="text" pInputText formControlName="Location" />
                        <control-messages [control]="deviationForm.controls['Location']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>DESCRIPTION</span>
                        </label>
                        <div>
                            <textarea pInputTextarea autoResize="autoResize" formControlName="Description"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>STATUS</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-dropdown formControlName="Status" [options]="status"
                              placeholder="{{'SELECT' | translate}}" [style]="{'width':'100%'}"></p-dropdown>
                        </div>
                        <control-messages [control]="deviationForm.controls['Status']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>IMPORTANCE</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <p-dropdown formControlName="Importance" [options]="importance"
                              placeholder="{{'SELECT' | translate}}" [style]="{'width':'100%'}"></p-dropdown>
                        </div>
                        <control-messages [control]="deviationForm.controls['Importance']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>DEADLINE</span>
                            <span class="required">*</span>
                        </label>
                        <my-date-picker [options]="dateOptions" placeholder="{{'SELECT_DEADLINE_DATE' | translate}}"
                          [style]="{'width':'100%'}" formControlName="Deadline"></my-date-picker>
                        <control-messages [control]="deviationForm.controls['Deadline']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>RESPONSIBLE_EXECUTION</span>
                            <span class="required">*</span>
                        </label>
                        <p-dropdown formControlName="ExecutionUserId" [options]="execution" [style]="{'width':'100%'}">
                        </p-dropdown>
                        <control-messages [control]="deviationForm.controls['ExecutionUserId']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>RESPONSIBLE_FOR_FOLLOW_UP</span>
                            <span class="required">*</span>
                        </label>
                        <p-dropdown formControlName="FollowupUserId" [options]="followup" [style]="{'width':'100%'}">
                        </p-dropdown>
                        <control-messages [control]="deviationForm.controls['FollowupUserId']"></control-messages>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>CORRECTIVE_ACTION</span>
                        </label>
                        <div>
                            <textarea pInputTextarea autoResize="autoResize"
                              formControlName="CorrectiveAction"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            <span translate>REFER_VIOLATED_PARA</span>
                        </label>
                        <div>
                            <p-multiSelect formControlName="ParaViolated" [options]="violatedParagraphs" [filter]="true"
                              [style]="{'width':'100%'}" defaultLabel="{{'CHOOSE' | translate}}">
                            </p-multiSelect>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isOffer">
                        <label class="col-form-label">
                            <span translate>OFFER_INCLUDE</span>
                        </label>
                        <div>
                            <textarea pInputTextarea autoResize="autoResize" formControlName="OfferInclude"></textarea>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isOffer">
                        <label class="col-form-label">
                            <span translate>OFFER_PRICE</span>
                        </label>
                        <div>
                            <input type="number" formControlName="OfferPrice" pInputText
                              (keypress)="onNumber($event,15)">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="tab-content">
                            <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                              (saveChanges)="saveUploaded($event)">
                            </ecpl-document-explorer>
                        </div>
                    </div>
                    <div *ngIf="isGuestHideButton" class="action-btns-wrapper">
                        <div class="pull-right">
                            <button *ngIf="isOffer" type="button" class="btn btn-outline-secondary"
                              (click)="addOffer()">
                                <span translate>REMOVE_OFFER</span>
                            </button>
                            <button *ngIf="!isOffer" type="button" class="btn btn-outline-secondary"
                              (click)="addOffer()">
                                <span translate>ADD_OFFER</span>
                            </button>
                            <button type="submit" class="btn btn-primary" (click)="SaveDeviationClick('back')"
                              [disabled]="!deviationForm.valid">
                                <span translate>SAVE_BACK_LIST</span>
                            </button>
                            <button type="submit" class="btn btn-primary" (click)="SaveDeviationClick('stay')"
                              [disabled]="!deviationForm.valid">
                                <span translate>SAVE_EDIT</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7">
                    <div *ngIf="clientContactInfo">
                        <div class="page-title"><span translate>CLIENT_INFORMATION</span></div>
                        <table class="table no-border">
                            <tr>
                                <th style="width: 140px;">
                                    <span translate>FIRM_NAME</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientFirm}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>CLIENT_NUMBER</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientNumber}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>ADDRESS</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientAddress}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>ZIP_CITY</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientZipcodeCity}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>PHONE_NO</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientPhoneNumber}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>EMAIL</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientEmail}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>CONTACT_PERSON</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ClientContactPerson}}</span>
                                </td>
                            </tr>
                        </table>
                        <div class="page-title"><span translate>INSTALLATION_CONTACT</span></div>
                        <table class="table no-border">
                            <tr>
                                <th style="width: 140px;">
                                    <span translate>NAME</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ContactName}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>PHONE_NO</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ContactPhoneNumber}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>EMAIL</span>
                                </th>
                                <td>
                                    <span>{{clientContactInfo.ContactEmail}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span translate>FUNCTION</span>
                                </th>
                                <td>
                                    <span translate>{{clientContactInfo.InstallationContactFunctionText}}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<toaster-component></toaster-component>
<p-dialog header="{{('SEND_CHANGE_NOTIFICATION') | translate}}" *ngIf="isShowNotifyPopup"
  [(visible)]="isShowNotifyPopup" [modal]="true" [style]="{width: '500px'}" [resizable]="false" [draggable]="false"
  [contentStyle]="{'overflow':'visible'}">
    <div class="form-group">
        <div class="checkbox">
            <p-checkbox label="{{'SEND_CHANGE_NOTIFICATION' | translate}}" binary="true" [(ngModel)]="IsChangeNotify">
            </p-checkbox>
        </div>
    </div>
    <div class="form-group" *ngIf="IsChangeNotify">
        <div class="checkbox">
            <p-checkbox label="{{'RESPONSIBLE_EXECUTION' | translate}}" binary="true"
              [(ngModel)]="IsExecutionChangeNotify"></p-checkbox>
            <p-checkbox label="{{'RESPONSIBLE_FOR_FOLLOW_UP' | translate}}" binary="true"
              [(ngModel)]="IsFollowupChangeNotify"></p-checkbox>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button type="submit" class="btn btn-primary" (click)="saveDeviation(navOnSaveClick)">
            <span translate>SAVE</span>
        </button>
    </div>
</p-dialog>