import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import 'rxjs/Rx';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { ConfirmationService } from 'primeng/api';
import { FDV } from './../models/fdvlist';
import { Rights } from './../../../_models/feature';
import { FDVServices } from '../services/fdv.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fdv',
  templateUrl: 'fdvList.component.html'
})
export class FdvListComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
  fireDeviation: FDV[];
  isHidden = false;
  isMyFdvHidden = false;
  showFDV = true;
  isHideMyFDV = true;
  isAdministrateFDV = true;
  additionalData: any;
  private subscriptions: Subscription[] = [];

  /**
   * Constructor
   * @param route {{ActivatedRoute}}
   * @param fdvService {{FDVServices}}
   * @param confirmationService {{ConfirmationService}}
   */
  constructor(
    private route: ActivatedRoute,
    private fdvService: FDVServices,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
  ) {
    this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
      this.additionalData = {
        fileName: val.FDV_LIST,
        header: 'FDV  List',
        businessId: BaseServices.BusinessId,
        cultureInfo: BaseServices.userCultureInfo(),
        columnNames: [
          { title: 'Name of the Object', dataKey: 'Name' },
          { title: 'Type Of Object', dataKey: 'ObjectType' },
          { title: 'Address', dataKey: 'Address' },
          { title: 'Zip', dataKey: 'Zip' },
          { title: 'Location', dataKey: 'Location' },
          { title: 'Contact', dataKey: 'Contact' },
          { title: 'Email', dataKey: 'Email' },
          { title: 'Mobile', dataKey: 'Mobile' }
        ]
      };
    }));
  }
  /**
   * get FDV lsit
   */
  ngOnInit(): void {
    document.querySelector("body").classList.remove("opened");
    if (BaseServices.UserRole === 'Guest') {
      this.isHideMyFDV = false;
      this.isAdministrateFDV = false;
      this.onChooseFDVRights();
    } else if (BaseServices.UserRole === 'User') {
      this.isHideMyFDV = false;
      this.onAdministrateFDV();
    } else {
      let fdv = this.route.snapshot.data['list'];
      if (fdv.length > 0) {
        this.fireDeviation = fdv;
      }
    }
    switch (BaseServices.UserRole.toUpperCase()) {
      case 'ADMIN': this.isHidden = false;
        this.isMyFdvHidden = false;
        break;
      case 'EDITOR': this.isHidden = false;
        this.isMyFdvHidden = false;
        break;
      case 'USER':
        this.isMyFdvHidden = true;
        break;
      case 'GUEST': this.isHidden = true;
        this.isMyFdvHidden = true;
        break;
      case 'PORTAL': this.isHidden = true;
        this.isMyFdvHidden = true;
        break;
    }
  }
  /**
   * delete FV by id
   * @param fireDeviation {{any}}
   */
  deleteFdv(fireDeviation) {
    this.confirmationService.confirm({
      message: this.translate.instant('ARE_SURE_DELETE_FDV'),
      accept: () => {
        this.subscriptions.push(this.fdvService
          .delete(fireDeviation)
          .subscribe(result => {
            this.subscriptions.push(this.fdvService
              .list(BaseServices.BusinessId)
              .subscribe(result => { this.fireDeviation = result }));
            this.toasterComponent.callToastDlt();
          }));
      }
    });
  }
  /**
   * choose fdv based on rights
   */
  onChooseFDVRights() {
    let userRightsId = Rights.CHOOSE_PROJECTFDV;
    let role = BaseServices.UserRole;
    this.showFDV = BaseServices.checkUserRights(userRightsId, role);
    if (this.showFDV) {
      this.subscriptions.push(this.fdvService.getFdvByUserId(BaseServices.UserId).subscribe((fdv: any) => {
        this.fireDeviation = fdv;
      }));
    }
  }
  /**
   * hide the new button based upon the rights
   */
  onAdministrateFDV() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_FDV;
    if (currentUserRole === 'User') {
      this.isAdministrateFDV = BaseServices.checkUserRights(userRightsId, currentUserRole);
      this.subscriptions.push(this.fdvService.getFdvByUserId(BaseServices.UserId).subscribe((fdv: any) => {
        this.fireDeviation = fdv;
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
