<div class="page-title">
  <span>
    <span translate>SJA_FORM</span>
  </span>
  <span class="page-actions">
    <a *ngIf="isShowToUser" [routerLink]="['./../new']" class="btn btn-success">
      <span class="icon ic-sm icon-add"></span>
      <span>
        <span translate>NEW</span>
      </span>
    </a>
  </span>
</div>
<div class="row">
  <div class="col-md-3">
    <div class="form-group" *ngIf="!isShowToUser">
      <label class="col-form-label">
        <span translate>CHOOSE_BUSINESS</span>
      </label>
      <div>
        <p-dropdown
          [options]="Business"
          [filter]="true"
          [style]="{ width: '100%' }"
          (onChange)="onBusinessChanged($event)"
        ></p-dropdown>
      </div>
    </div>
  </div>
</div>
<div class="table-view mbtm-30">
  <!-- Table starts -->
  <p-table
    #taskTable
    [value]="sjaDataList"
    selectionMode="multiple"
    [rows]="10"
    [paginator]="true"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5, 10, 20]"
    [(selection)]="selectedSJA"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
  >
    <!-- Caption -->
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="table-options">
          <div class="pull-right">
            <!-- <kuba-export [reportData]="taskTable.filteredValue || taskTable.value"
                            [additionalData]="additionalData"></kuba-export> -->
          </div>
        </div>
      </div>
    </ng-template>
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 38px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="Title">
          {{ "TITLE" | translate }} <p-sortIcon field="Title"></p-sortIcon>
        </th>
        <th pSortableColumn="Date">
          {{ "DATE" | translate }} <p-sortIcon field="Date"></p-sortIcon>
        </th>
        <th pSortableColumn="Location">
          {{ "LOCATION" | translate }}
          <p-sortIcon field="Location"></p-sortIcon>
        </th>
        <th pSortableColumn="Participants">
          {{ "PARTICIPANTS" | translate }}
          <p-sortIcon field="Participants"></p-sortIcon>
        </th>
        <th pSortableColumn="SjaResponsible">
          {{ "SJA_RESPONSIBLE" | translate }}
          <p-sortIcon field="SjaResponsible"></p-sortIcon>
        </th>
        <th pSortableColumn="ConclusionOrComments" style="width: 200px">
          {{ "CONCLUSION_OR_COMMENTS" | translate }}
          <p-sortIcon field="ConclusionOrComments"></p-sortIcon>
        </th>
        <th pSortableColumn="ApprovalStatus" *ngIf="hasRights">
          {{ "APPROVAL_NOTICE" | translate }}
          <p-sortIcon field="ApprovalStatus"></p-sortIcon>
        </th>
        <th width="75px">{{ "OPTIONS" | translate }}</th>
        <th width="45px"></th>
      </tr>
      <tr>
        <th style="width: 38px"></th>
        <th>
          <input
            pInputText
            type="text"
            (input)="
              taskTable.filter($any($event.target)?.value, 'Title', 'contains')
            "
            placeholder=""
            class="p-column-filter"
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th *ngIf="hasRights"></th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <!-- Body Content -->
    <ng-template pTemplate="body" let-sja>
      <tr>
        <td style="width: 38px">
          <p-tableCheckbox id="selectedOrders" [value]="sja"> </p-tableCheckbox>
        </td>
        <td>
          <span
            pTooltip="{{ sja.Title | translate }}"
            tooltipPosition="bottom"
            >{{ sja.Title | translate }}</span
          >
        </td>
        <td>
          <span>{{ sja.Date | date : "dd/MM/yyyy" }}</span>
        </td>
        <td>
          <span>{{ sja.Location }}</span>
        </td>
        <td>
          <span>{{ sja.Participants }}</span>
        </td>
        <td>
          <span>{{ sja.SjaResponsible }}</span>
        </td>
        <td>
          <span>{{ sja.ConclusionOrComments }}</span>
        </td>
        <td>
          <span
            [style.color]="sja.ApprovalStatus == 'Approved' ? 'Green' : 'black'"
            >{{ sja.ApprovalStatus | translate }}</span
          >
        </td>
        <td *ngIf="hasRights">
          <a
            *ngIf="userRole == 'User' && sja.CreatedBy != loginUserName"
            type="button"
            [routerLink]="['./../edit', sja.Id]"
            class="btn btn-icon"
            title="{{ 'EDIT' | translate }}"
          >
            <i class="icon ic-sm icon-pencil-tip"></i>
          </a>
          <a
            *ngIf="userRole == 'User' && sja.CreatedBy == loginUserName"
            type="button"
            [routerLink]="['./../edit', sja.Id]"
            class="btn btn-icon"
            title="{{ 'EDIT' | translate }}"
          >
            <i class="icon ic-sm icon-pencil-tip"></i>
          </a>
          <a
            *ngIf="
              userRole == 'Editor' ||
              userRole == 'Guest' ||
              userRole == 'Admin' ||
              userRole == 'Portal'
            "
            type="button"
            [routerLink]="['./../edit', sja.Id]"
            class="btn btn-icon"
            title="{{ 'EDIT' | translate }}"
          >
            <i class="icon ic-sm icon-pencil-tip"></i>
          </a>
          <a
            *ngIf="
              userRole == 'User' &&
              isShowToUser &&
              sja.CreatedBy == loginUserName
            "
            class="btn btn-icon"
            (click)="DeleteSJAForm(sja.Id)"
            title="{{ 'DELETE' | translate }}"
          >
            <i class="icon ic-sm icon-trash"></i>
          </a>
          <a
            *ngIf="userRole != 'User' && userRole != 'Guest' && isShowToUser"
            class="btn btn-icon"
            (click)="DeleteSJAForm(sja.Id)"
            title="{{ 'DELETE' | translate }}"
          >
            <i class="icon ic-sm icon-trash"></i>
          </a>
        </td>
        <td>
          <button
            type="submit"
            class="btn btn-icon text-primary"
            (click)="exportPdf(sja.Id)"
          >
            <i class="icon ic-sm icon-print"></i>
          </button>
        </td>
      </tr>
    </ng-template>

    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" *ngIf="hasRights" let-columns>
      <tr>
        <td colspan="10">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
    <!-- Empty message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td colspan="10">
          {{ "NO_RECORDS_FOUND" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- Table ends -->

  <div class="action-btns-wrapper mbtm-30">
    <button
      class="btn btn-primary"
      (click)="saveApprovalNotice()"
      *ngIf="!isApproved"
    >
      <span translate>APPROVAL_NOTICE</span>
    </button>
  </div>
</div>
<div class="mbtm-30" *ngIf="showApprovalTable">
  <div class="sub-panel-title">
    <span translate>APPROVED_NOTICES</span>
  </div>
  <!--Approved all notices  datatable-->
  <div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table
      [value]="approvalNotices"
      #approvalTable
      dataKey="Name"
      expandableRows="true"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th>{{ "EMPLOYEE_NAME" | translate }}</th>
          <th>{{ "STATUS" | translate }}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template
        pTemplate="body"
        let-risk
        let-expanded="expanded"
        let-i="rowIndex"
      >
        <tr>
          <td>
            <a (click)="approvalTable.toggleRow(risk)" class="btn btn-icon">
              <span
                class="icon ic-sm icon-expand-arrow"
                aria-hidden="true"
                *ngIf="setCollapse(i)"
                (click)="toggleClick(i)"
              ></span>
              <span
                class="icon ic-sm icon-collapse-arrow"
                aria-hidden="true"
                *ngIf="!setCollapse(i)"
                (click)="toggleClickHide(i)"
              ></span>
            </a>
          </td>
          <td>
            <span>{{ risk.Name }}</span>
          </td>
          <td>
            <span>{{ risk.Count }}/{{ risk.RiskAnalysis.length }}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-risk>
        <tr>
          <td colspan="3">
            <div>
              <!-- Table starts -->
              <p-table
                [value]="risk.RiskAnalysis"
                [rows]="5"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{
                  'SUMMARY_TOTALRECORDS' | translate
                }}"
              >
                <!-- Header -->
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{ "TOPICS" | translate }}</th>
                    <th>{{ "LOCATION" | translate }}</th>
                    <th>{{ "APPROVED_DATE" | translate }}</th>
                    <th>{{ "EDITED_ON" | translate }}</th>
                  </tr>
                </ng-template>
                <!-- Body Content -->
                <ng-template pTemplate="body" let-risks>
                  <tr>
                    <td>
                      <span>{{ risks.Title | translate }}</span>
                    </td>
                    <td>
                      <span>{{ risks.GPSLocation }}</span>
                    </td>
                    <td>
                      <span
                        [style.color]="risks.ApprovedOn ? 'Green' : 'black'"
                        >{{
                          risks.ApprovedOn
                            ? (risks.ApprovedOn | date : "dd/MM/yyyy")
                            : ("Unapproved" | translate)
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        [style.color]="
                          risks.ApprovedOn != null
                            ? risks.ModifiedOn
                              ? 'black'
                              : 'Green'
                            : 'red'
                        "
                        >{{ risks.ModifiedOn | date : "dd/MM/yyyy" }}</span
                      >
                    </td>
                  </tr>
                </ng-template>
                <!-- Empty message -->
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td colspan="3">
                      {{ "NO_RECORDS_FOUND" | translate }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
  <toaster-component></toaster-component>
  <div class="panel-loader" *ngIf="loading">
    <span>
      <span class="loader-icon"></span>
      <span translate>PROCESSING</span>
    </span>
  </div>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
  <p-dialog
    header="{{ 'NOTIFICATION' | translate }}"
    [(visible)]="showInfo"
    [style]="{ width: '600px' }"
    [resizable]="false"
    [draggable]="false"
    translate
  >
    <span translate>SELECT_ONERECORD</span>
  </p-dialog>
</div>
