<div class="page-title">
    <div class="row">
        <div class="col-md-6">
            <span translate>DOCUMENTS</span>
        </div>
        <div class="col-md-6">
            <span class="page-actions text-right">
                <button (click)="addDocument()" class="btn btn-success">
                    <span class="icon ic-sm icon-add"></span>
                    <span translate>NEW</span>
                </button>
            </span>
        </div>
    </div>
</div>
<div class="table-view">
    <!-- Table starts -->
    <p-table [value]="document" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
        [responsive]="true">
        <!-- Footer -->
        <ng-template pTemplate="summary">Todo:Users Info and Export buttons </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>Title</th>
                <th>Date release</th>
                <th>Last edited by</th>
                <th>Date last edited</th>
                <th>Document</th>
                <th>Document</th>
                <th>Download link</th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-document>
            <tr>
                <td>
                    <span>{{document.Title}}</span>
                </td>
                <td>
                    <span>{{document.DateRelease}}</span>
                </td>
                <td>
                    <span>{{document.EditBy}}</span>
                </td>
                <td>
                    <span>{{document.EditDate}}</span>
                </td>
                <td>
                    <span>{{document.Title}}</span>
                </td>
                <td>
                    <a [routerLink]="['./../view', document['Id']]">{{document.Title}}</a>
                </td>
                <td>
                    <a [routerLink]="['./../view', document['Id']]">{{document.Link}}</a>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
</div>