import { Component, OnInit, Input } from '@angular/core';
import { Electro } from '../../../models/electrocontract';
import { BaseServices } from 'src/app/kuba/kuba.services';

@Component({
    selector: 'electro-info-viewer',
    templateUrl: 'electro-info-viewer.component.html'
})

export class ElectroInfoViewerComponent implements OnInit {

    @Input() ElectroInfo: Electro;
    businessName: string;

    constructor() { 
        this.businessName = BaseServices.BusinessName;
        this.ElectroInfo;
    }

    ngOnInit() { }
}