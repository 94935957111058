import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Flexi } from "../models/flexi";
import { FlexiServices } from "./flexi.services";
import { BaseServices } from "../../kuba.services";



@Injectable()
export class FlexiResolver implements Resolve<Flexi> {
    constructor(private backend: FlexiServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getAdvisor(BaseServices.BusinessId);
    }
}
@Injectable()
export class OHSDataResolver implements Resolve<Flexi> {
    constructor(private backend: FlexiServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getOHSdata(BaseServices.BusinessId);
    }
}