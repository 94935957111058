import { UserService } from './../../users/services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { HelperService } from './../../../_services/helper.service';
import { Meeting, MeetingConvernerDto, MeetingParticipationsDto, MeetingType, MeetingUserBackupDto, MeetingUserDto } from './../models/meeting';
import { MeetingService } from './../services/meeting.services';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'new-meeting',
    templateUrl: 'meeting-new.component.html'
})
export class NewMeetingComponent implements OnInit {
    //#region variables

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    upConfig: UploaderConfig;
    uploadedFiles: any = [];
    newDocumentAdded: any = [];
    startDate: any;
    startTime: Date;
    endTime: Date;
    meetingId: number;
    meetingTypes: SelectItem[];
    userNames: SelectItem[];
    convernerNames: SelectItem[];
    meetingForm: FormGroup;
    isMeetingTypeExsist = false;
    MeetingTypeForm: FormGroup;
    businessType: SelectItem[];
    typesData: any;
    meetingTypeId: number;
    edited: Boolean = false;
    addTopicType: boolean;
    viewTopicType: boolean;
    isUsedMeetingtype: boolean;
    selecteduserslist:any=[];
    documentdetails: any=[];
    /**
     * Date picker configuration option
     */
    public DateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableUntil: this.getCurrentDate()
    };
    additionalData: any;
    private subscriptions: Subscription[] = [];
    loadingReport: boolean;
    print: boolean = false;

    //#endregion

    //#region constructor
    /**
     * constructor
     * @param route {ActivatedRoute}
     * @param _fb {FormBuilder}
     * @param meetingService {MeetingService}
     * @param location {location}
     * @param confirmationService {confirmationService}
     */
    constructor(
        private route: ActivatedRoute,
        private _fb: FormBuilder,
        private meetingService: MeetingService,
        private location: Location,
        private confirmationService: ConfirmationService,
        private userService: UserService,
        private convernerService: UserService,
        private translate: TranslateService
    ) { }
    //#endregion

    //#region page-events

    /**
     * edit meeting and bind dropdowns
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        let userRole = BaseServices.UserRole;
        if (userRole === 'Admin' || userRole === 'Portal') {
            this.edited = true;
        }
        this.meetingTypes = [];
        this.userNames = [];
        this.convernerNames = [];
        this.businessType = [];
        let userList:any = [];
        this.meetingId = +this.route.snapshot.params['mid'];
        this.MeetingTypeForm = this._fb.group({
            MeetingTitle: ['', Validators.required]
        });
        this.meetingForm = this._fb.group({
            meetingType: ['', Validators.required],
            selectedMeeting: [''],
            date: ['', Validators.required],
            startTime: ['', Validators.required],
            endTime: ['', Validators.required],
            spendTime: [],
            comment: [],
            userNameList: new FormControl(userList),
            convernerName: ['', Validators.required]
        });
        this.typesData = this.route.snapshot.data['type'];
        this.meetingTypes = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.meetingTypes = [];
            this.meetingTypes.push(
                { label: val.SELECT, value: null }
            );
            if (this.typesData) {
                this.typesData.forEach((type: any) => {
                    this.meetingTypes.push({ label: type.Title, value: type.Id });
                });
            }
        }));
        this.userNames = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userNames = [];
            this.userNames.push(
                { label: val.SELECT, value: null }
            );
            let userNames = this.route.snapshot.data['meetinguser'];
            if (userNames) {
                userNames.forEach((user:any) => {
                    this.userNames.push({
                        label: user.Name,
                        value: user.Id
                    });
                });
            }
        }));
        this.convernerNames = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.convernerNames = [];
            this.convernerNames.push(
                { label: val.SELECT, value: null }
            );
            let convernerNames = this.route.snapshot.data['meetingconvener']
            if (convernerNames) {
                convernerNames.forEach((user:any) => {
                    this.convernerNames.push({
                        label: user.Name,
                        value: user.Id
                    });
                });
            }
        }));
        let meetings = this.route.snapshot.data['business'];
        this.businessType = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.businessType = [];
            this.businessType.push(
                { label: val.SELECT, value: null }
            );
        }));
        if (meetings) {
            meetings.forEach((bType: any) => {
                this.businessType.push({
                    label: bType.Name,
                    value: +bType.Id
                });
            });
        }

        if (this.meetingId) {
            let meetingInfo = this.route.snapshot.data['detail'];
            meetingInfo.MeetingParticipationsList.forEach((user:any) => {
                this.selecteduserslist.push( user.UserId );
            });

            if (meetingInfo) {
                let meetingtestForm = {
                    meetingType: meetingInfo.MeetingType,
                    date: HelperService.formatInputDate(meetingInfo.Date),
                    startTime: HelperService.formatTime(meetingInfo.StartTime),
                    endTime: HelperService.formatTime(meetingInfo.EndTime),
                    spendTime: HelperService.formatTime(meetingInfo.Duration),
                    comment: meetingInfo.Description,
                    userNameList:this.selecteduserslist,
                    convernerName: meetingInfo.MeetingConverner ? meetingInfo.MeetingConverner.UserId : null,
                    selectedMeeting: meetingInfo.BusinessId
                };
                this.meetingForm.setValue(meetingtestForm, { onlySelf: true });
                this.startDate = new Date(meetingInfo.Date);
                this.startTime = new Date(
                    `${this.startDate.toDateString()} ${meetingInfo.StartTime}`
                );
                this.endTime = new Date(
                    `${this.startDate.toDateString()} ${meetingInfo.EndTime}`
                );
            }
            this.uploadedFiles = meetingInfo.MeetingDocument;
        }
        this.additionalData = {
            'ApplicationId': BaseServices.ApplicationId,
            'BusinessId': BaseServices.BusinessId,
            'Status': '1',
            'CreatedOn': new Date(),
            'ModifiedBy': BaseServices.UserId,
            'ModifiedOn': new Date(),
            'MeetingId': this.meetingId ? +this.meetingId : 0,
            //'IsCommonDocument': '0'
        };
        this.upConfig = {
            title: 'UPLOAD_ATTACHMENT',
            titleAsLabel: true,
            editMode:true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri: environment.BASE_URL + '/file/upload/business/manual',
            addtionalData: this.additionalData
        };
    }
    //#endregion

    //#region control-events
    /**
     * save and update meeting
     * @param meetings {any}
     */
    onSubmit() {
        let meeting:any = new Meeting();
        meeting.Date = this.meetingForm.value.date ? HelperService.formatDateFilter(this.meetingForm.value.date.formatted) : null;
        meeting.StartTime = HelperService.formatTime(
            this.meetingForm.value.startTime
        );
        meeting.EndTime = HelperService.formatTime(
            this.meetingForm.value.endTime
        );
        meeting.Duration = HelperService.formatTime(
            this.meetingForm.value.spendTime
        );
        meeting.Status = '1';
        meeting.Description = this.meetingForm.value.comment;
        meeting.MeetingParticipations = new MeetingParticipationsDto();
        meeting.MeetingParticipations.UserNameList = this.meetingForm.value.userNameList;
        meeting.MeetingParticipations.IsOrganizar = true;
        meeting.MeetingType = this.meetingForm.value.meetingType;
        if (this.meetingForm.value.convernerName != null || this.meetingForm.value.convernerName != "") {
            if (this.convernerNames.length > 0) {
                for (var i = 0; i < this.convernerNames.length; i++) {
                    if (this.convernerNames[i].value == this.meetingForm.value.convernerName) {
                        meeting.Title = this.convernerNames[i].label
                    }
                }
            }
        }
        meeting.MeetingConverner = new MeetingConvernerDto();
        meeting.MeetingConverner.UserId = this.meetingForm.value.convernerName;
        meeting.MeetingConverner.IsOrganizar = true;
        // Documents inserting
        meeting.MeetingDocument = [];
        let keyBindings = { 'Originalname': 'OriginalFileName', 'FileTypeId': 'FileType' };
        this.newDocumentAdded.forEach((file: any) => {
            let meetingDoc = Object.assign({}, file, this.additionalData)
            meetingDoc.id = 0;
            meetingDoc.status = 1;
            meetingDoc.createdBy = BaseServices.UserId;
            meeting.MeetingDocument.push(HelperService.switchKeysCase(meetingDoc, keyBindings, 'L'))
        })
        if (BaseServices.UserRole === 'Admin') {
            meeting.BusinessId = this.meetingForm.value.selectedMeeting;
        } else {
            meeting.BusinessId = BaseServices.BusinessId;
        }
        if (this.meetingId > 0) {
            let updatemeetingId = this.meetingId;
            meeting.Id = this.meetingId;
            this.subscriptions.push(this.meetingService
                .update(updatemeetingId, meeting)
                .subscribe(isMeetingUpdate => {
                    if (isMeetingUpdate) {
                        this.toasterComponent.callToast();
                        this.goToBack();
                    }
                }));
            // if(this.print == false){

            //   }else{
            //     this.print = false;
            //     this.exportPdf();
            //   }
        } else {
            meeting.Id = 0;
            meeting.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.meetingService.add(meeting).subscribe(isMeetingSaved => {
                if (isMeetingSaved) {
                    this.toasterComponent.callToast();
                    this.goToBack();
                }
            }));
        }
    }

    onPrint() {
        this.print = true;
    }
    /**
     * calculating hour spent when startDate changed
     * @param date  {IMyDateModel}
     */
    startDateChanged(event: IMyDateModel) {
        this.startDate = event.jsdate;
        this.setCalculatedHours();
    }
    /**
     *calculating HourSpent when StartTime changed
     * @param date{any}
     */
    startTimeChanged(date: any) {
        this.startTime = date;
        this.setCalculatedHours();
    }
    /**
     * calculating hour spent when endTime changed
     * @param date {any}
     */
    endTimeChanged(date: any) {
        this.endTime = date;
        this.setCalculatedHours();
    }
    /**
     * close popup
     */
    onBeforeMeetingTypeDialogHide() {
        this.MeetingTypeForm.reset();
    }
    /**
     * edit meeting type
     * @param meetingTypes {any}
     */
    editMeetingType(meetingTypes: any) {
        this.meetingTypeId = meetingTypes.Id;
        this.MeetingTypeForm = this._fb.group({
            MeetingTitle: meetingTypes.Title
        });
    }
    /**
     * delete the meeting type
     * @param Id {any}
     */
    deleteMeetingType(Id: any) {
        let typeList = [];
        this.subscriptions.push(this.meetingService
            .getMeetingByBusiness(BaseServices.BusinessId)
            .subscribe(meeting => {
                let meetings = meeting ? meeting.filter((x:any) => x.MeetingType === Id) : [];
                if (meetings.length > 0) {
                    this.isUsedMeetingtype = true;
                } else {
                    this.confirmationService.confirm({
                        message:
                            this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.meetingService
                                .deleteMeetingType(Id)
                                .subscribe(isTypeDeleted => {
                                    if (isTypeDeleted) {
                                        this.fetchMeetingType();
                                        this.toasterComponent.callToastDlt();
                                    }
                                }));
                        }
                    });
                }
            }));
    }
    /**
     * popup hide
     */
    informationHide() {
        this.isUsedMeetingtype = false;
    }
    /**
     * save metting type
     */
    saveMeetingType() {
        let meetingTypeData = new MeetingType();
        meetingTypeData.Title = this.MeetingTypeForm.value.MeetingTitle;
        meetingTypeData.Status = 1;
        meetingTypeData.BusinessId = BaseServices.BusinessId;
        if (this.meetingTypeId > 0) {
            meetingTypeData.Id = this.meetingTypeId;
            this.subscriptions.push(this.meetingService
                .updateMeetingType(meetingTypeData)
                .subscribe(isTypeUpdated => {
                    if (isTypeUpdated) {
                        this.toasterComponent.callToast();
                        this.isMeetingTypeExsist = false;
                        this.fetchMeetingType();
                        this.meetingForm
                            .get('meetingType')!
                            .patchValue(isTypeUpdated.Id);
                        this.meetingTypeId = 0;
                    } else {
                        this.isMeetingTypeExsist = true;
                    }
                }));
        } else {
            this.subscriptions.push(this.meetingService
                .addMeetingType(meetingTypeData)
                .subscribe(isTypeSaved => {
                    if (isTypeSaved) {
                        this.toasterComponent.callToast();
                        this.isMeetingTypeExsist = false;
                        this.fetchMeetingType();
                        this.meetingForm
                            .get('meetingType')!
                            .patchValue(isTypeSaved.Id);
                    } else {
                        this.isMeetingTypeExsist = true;
                    }
                }));
        }
    }
    /**
     * go back location
     */
    goToBack() {
        this.location.back();
    }
    //#endregion

    //#region methods
    /**
     * calculate hours spent and set to control
     */
    private setCalculatedHours() {
        if (this.startDate && this.endTime && this.startTime) {
            let hoursSpent = HelperService.calculateHourSpent(
                this.startDate,
                this.startDate,
                this.startTime,
                this.endTime
            );
            this.meetingForm.get('spendTime')!.patchValue(hoursSpent);
        }
    }
    /**
     * refresh meeting type list
     */
    fetchMeetingType() {
        this.subscriptions.push(this.meetingService.getAllMeetingType().subscribe(typeInfo => {
            if (typeInfo) {
                this.typesData = typeInfo;
                this.meetingTypes = [];
                typeInfo.forEach((type: any) => {
                    this.meetingTypes.push({
                        label: type.Title,
                        value: type.Id
                    });
                });
            }
        }));
    }
    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1
        };
    }

    //#endregion
    validateTimeAndSave() {
        let date1 = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth(),
            this.startDate.getDate(),
            this.startTime.getHours(),
            this.startTime.getMinutes(),
            this.startTime.getSeconds(),
            this.startTime.getMilliseconds()
        );
        let date2 = new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth(),
            this.startDate.getDate(),
            this.endTime.getHours(),
            this.endTime.getMinutes(),
            this.endTime.getSeconds(),
            this.endTime.getMilliseconds()
        );
        let timeStart = new Date(date1).getTime();
        let timeEnd = new Date(date2).getTime();
        if (timeEnd >= timeStart) {
            this.onSubmit();
        } else {
            alert('End time should be greater than start time');
        }
    }

    onBusinessChanges(e: any) {
        this.userNames = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userNames = [];
            this.userNames.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.userService
            .getEmployeeContactUsers(e.value)
            .subscribe((user: any) => {
                if (user) {
                    user.forEach((user:any) => {
                        this.userNames.push({
                            label: user.Name,
                            value: user.Id
                        });
                    });
                }
            }));
        this.subscriptions.push(this.userService
            .getEmployeeContactConveners(e.value)
            .subscribe((user: any) => {
                if (user) {
                    user.forEach((user:any) => {
                        this.convernerNames.push({
                            label: user.Name,
                            value: user.Id
                        });
                    });
                }
            }));
    }

    exportPdf(){
        this.loadingReport = true;
        let fileName = this.translate.instant('MEETING_DETAILS');
        let filetype = 'pdf';
        let cultureInfo = sessionStorage.getItem('languageMode');
        this.confirmationService.confirm({
            message: this.translate.instant('ARE_SURE_TO_PRINT_THIS_PAGE'),
            accept: () => {
                this.onSubmit();
                this.subscriptions.push(this.meetingService
                    .meetingReportGenerate(BaseServices.BusinessId, this.meetingId, cultureInfo, BaseServices.UserId)
                    .subscribe(
                        blob => {
                            this.loadingReport = false;
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = `${fileName}.${filetype.toLowerCase()}`;
                            link.click();
                        },
                        error => {
                            this.loadingReport = false;
                            alert('Export not downloaded');
                        }
                    ));
            }
        });
    }

    saveUploaded(event: any) {

        if (event) {
            let eventType = event.eventName;
            let keyBindings = {
                Originalname: 'OriginalFileName',
                FileTypeId: 'FileType'
            };
            let deviationDoc = Object.assign({}, event, this.additionalData);
            switch (eventType) {
                case 'DeleteDefaultFile':
                    this.uploadedFiles = this.uploadedFiles.filter(
                        (x: any) => x.path !== event.item.data.path
                    );
                    if (event.item.data.id === 0) {
                        this.newDocumentAdded = this.newDocumentAdded.filter(
                            (x: any) => x.Path !== event.item.data.path
                        );
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.meetingService
                            .deleteMeetingDocument(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter((x: any) => x.path !== event.item.data.path);
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    deviationDoc.id = 0;
                    deviationDoc.createdBy = BaseServices.UserId;
                    deviationDoc.status = 1;
                    deviationDoc.employeeId = this.meetingId ? this.meetingId : 0;
                    this.newDocumentAdded.push(
                        HelperService.switchKeysCase(deviationDoc, keyBindings)
                    );
                    break;
            }
        }
    }
    getFileExtension(filename: any) {
        let fileExtension = null;
        if (filename != null) {
            fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
        }
        return fileExtension.toLowerCase();;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
