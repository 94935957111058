<div class="tab-content">
    <div class="page-title">
        <span translate>NEW_TIMETABLE</span>
    </div>
    <form [formGroup]="timetableAddForm" class="form" #f="ngForm" novalidate name="form">
        <timetable-form [TimetableForm]="timetableAddForm"></timetable-form>
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-outline-secondary" (click)="gotoBack()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK_LIST</span>
            </button>
            <button class="btn btn-primary" [ngClass]="{'loading' : loading}" type="submit" (click)="saveProceed()"
                [disabled]="!timetableAddForm.valid || loading">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
            </button>
        </div>
    </form>
</div>
<toaster-component></toaster-component>