import { EcplDocumentExplorerModule } from 'src/app/shared/ecpl-document-explorer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from './../shared/appShared.module';
import { MeasureProtocolComponent } from './measure-protocol.component';
import { MeasureProtocolListComponent } from './components/measure-protocol-list.component';
import { MeasureProtocolFormComponent } from './components/measure-protocol-form.component';
import { MeasureProtocolInstrumentListComponent } from './components/Instrument/measure-protocol-instrument-list.component';
import { MeasureProtocolInstrumentFormComponent } from './components/Instrument/measure-protocol-instrument-form.component';
import { MeasureProtocolUsedInstrumentComponent } from './components/MPComplete/measure-protocol-used-instruments.component';
import { MeasureProtocolVisualControlComponent } from './components/MPComplete/measure-protocol-visual-control.component';
import { MeasureProtocolEarthLeakageComponent } from './components/MPComplete/measure-protocol-earth-leakage.component';
import { MeasureProtocolPowerLeakageComponent } from './components/MPComplete/measure-protocol-power-leakage.component';
import { MeasureProtocolTemperatureComponent } from './components/MPComplete/measure-protocol-temperature.component';
import { MeasureProtocolCommentsComponent } from './components/MPComplete/measure-protocol-comments.component';
import { MeasureProtocolCompleteFormComponent } from './components/measure-protocol-complete-form.component';
import { MPCTabNavigationComponent } from './components/mpc-tab-navigation.component';
import { MeasureProtocolService } from './services/measure-protocol.services';
import { RouterModule } from '@angular/router';
import {
    MeasureProtocolListResolver,
    CompleteMeasureProtocolListResolver,
    MeasureProtocolEditResolver,
    InstrumentListResolver,
    InstrumentEditResolver,
    MeasureProtocolCompleteEditResolver,
    TypeListResolver,
    ModelListResolver,
    CMPInstrumentListResolver,
    PowerLeakageListResolver,
    EarthLeakageListResolver,
    TemperatureTypeListResolver,
    TemperatureModelListResolver,
    CMPTemperatureListResolver,
    VisualControlResolver
} from './services/measure-protocol.resolver.service';
import { ClientListResolver } from 'src/app/kuba/projects/services/project.resolver';
import { MPCDetailsComponent } from 'src/app/kuba/measure-protocol/components/MPComplete/mpc-details.component';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MyDatePickerModule } from 'mydatepicker';
@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        TableModule,
        SharedModule,
        DialogModule,
        CalendarModule,
        TranslateModule,
        ConfirmDialogModule,
        AppSharedModule,
        EcplDocumentExplorerModule,
        KubaExportModule,
        MyDatePickerModule
    ],
    exports: [
        MeasureProtocolComponent,
        MeasureProtocolListComponent,
        MeasureProtocolFormComponent,
        MeasureProtocolInstrumentListComponent,
        MeasureProtocolInstrumentFormComponent,
        MeasureProtocolUsedInstrumentComponent,
        MeasureProtocolVisualControlComponent,
        MeasureProtocolEarthLeakageComponent,
        MeasureProtocolPowerLeakageComponent,
        MeasureProtocolTemperatureComponent,
        MeasureProtocolCommentsComponent,
        MPCTabNavigationComponent,
        MeasureProtocolCompleteFormComponent,
        MPCDetailsComponent
    ],
    declarations: [
        MeasureProtocolComponent,
        MeasureProtocolListComponent,
        MeasureProtocolFormComponent,
        MeasureProtocolInstrumentListComponent,
        MeasureProtocolInstrumentFormComponent,
        MeasureProtocolUsedInstrumentComponent,
        MeasureProtocolVisualControlComponent,
        MeasureProtocolEarthLeakageComponent,
        MeasureProtocolPowerLeakageComponent,
        MeasureProtocolTemperatureComponent,
        MeasureProtocolCommentsComponent,
        MPCTabNavigationComponent,
        MeasureProtocolCompleteFormComponent,
        MPCDetailsComponent
    ],
    providers: [
        MeasureProtocolService,
        MeasureProtocolListResolver,
        CompleteMeasureProtocolListResolver,
        ClientListResolver,
        MeasureProtocolEditResolver,
        InstrumentListResolver,
        InstrumentEditResolver,
        MeasureProtocolCompleteEditResolver,
        TypeListResolver,
        ModelListResolver,
        CMPInstrumentListResolver,
        PowerLeakageListResolver,
        EarthLeakageListResolver,
        TemperatureTypeListResolver,
        TemperatureModelListResolver,
        CMPTemperatureListResolver,
        VisualControlResolver
    ]
})
export class MeasureProtocolModule {}
