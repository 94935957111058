import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class MeetingService {
    constructor(private http: HttpClient) {}

    getAll() {
        return this.http
            .get(environment.BASE_URL + '/meetings', BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;;
            });
    }

    getById(id) {
        return this.http
            .get(environment.BASE_URL + '/meeting/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }

    add(data) {
        return this.http
            .post(
                environment.BASE_URL + '/meetings/',
                data,
                BaseServices.headerOption
            )
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }

    update(id, data) {
        return this.http
            .put(
                environment.BASE_URL + '/meetings/' + id,
                data,
                BaseServices.headerOption
            )
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }
    getmeetingtype(id) {
        return this.http
            .get(environment.BASE_URL + '/meetingtypes/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }
}
