import { AuthGuard } from './../../_guards/auth.guard';
import { HistoryComponent } from './components/history.component';
import { CrewListComponent } from './components/crew-list.component';
import { CrewComponent } from './crew.component';
import { Route } from '@angular/router';
import {
    CrewListResolver
    , ProjectUsersResolver
    , PeojectInfoResolver
    , CrewListHistroyResolver
} from 'src/app/kuba/crew-list/services/crewlist.resolver';
export const crewroutes: Route[] = [{
    path: 'crew',
    component: CrewComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: CrewListComponent,
            resolve: {
                list: CrewListResolver,
                proInfo: PeojectInfoResolver
            }

        },
        {
            path: 'history',
            component: HistoryComponent,
            resolve: {
                list: CrewListHistroyResolver,
                proInfo: PeojectInfoResolver,
                projectUsers: ProjectUsersResolver
            }
        }]
}];
