<a href="javascript:void(0)" (click)="showModal()" id="article-file-{{facts.FactsData.Id}}">
    <img src="/assets/file-icons/kb-article.png" alt="article File">
</a>
<div class="article-modal" *ngIf="showModalWindow">
    <div class="modal-dialog">
        <div class="modal-header">
            <h4>
                <span class="ic-md">
                    <img src="/assets/file-icons/kb-article.png" alt="Article file">
                </span>
                <span translate>FACTS_ABOUT_THE_COMPANY</span>
            </h4>
            <button (click)="hideModal()" class="btn btn-icon close">
                <span class="icon ic-md icon-multiply"></span>
            </button>
        </div>
        <div class="modal-body">
            <div [ngClass]="{'container' : !editMode}">
                <div [ngClass]="{'row' : !editMode}">
                    <div [ngClass]="{'col-sm-8 offset-sm-2' : !editMode}">
                        <div [ngClass]="{'article-print-preview' : !editMode}">
                            <div class="mb-3" *ngIf="!editMode">
                                <button class="btn btn-icon text-primary" (click)="printFactPdf()">
                                    <span class="icon ic-sm icon-print"></span>
                                    <span translate>PRINT_AS_PDF</span>
                                </button>
                            </div>
                            <div class="modal-content-left" *ngIf="editMode">
                                <div class="form-group">
                                    <label translate>DOCUMENT_NUMBER</label>
                                    <span class="required">*</span>
                                    <input type="text" class="form-control" [(ngModel)]="facts.FactsData.DocumentNumber"
                                      placeholder="{{'DOCUMENT_NUMBER'| translate}}" disabled>
                                </div>
                                <div class="form-group">
                                    <label translate>VERSION</label>
                                    <input type="text" class="form-control" [(ngModel)]="facts.FactsData.Version"
                                      (change)="updateTextVersion()">
                                </div>
                                <div class="form-group">
                                    <label translate>DATE</label>
                                    <div>
                                        <my-date-picker name="articledate" [options]="articleDateOptions"
                                          [(ngModel)]="facts.FactsData.ModifiedOn"
                                          placeholder="{{'CHOOSE_DATE'|translate}}"></my-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="article-pp-info" *ngIf="!editMode">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label translate>ARTICLE_NAME</label>:
                                                <label class="text-primary">{{'FACTS_ABOUT_THE_COMPANY' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-4">
                                                <label translate>DOCUMENT_NUMBER</label>:
                                                <label class="text-primary">{{facts.FactsData.DocumentNumber}} </label>
                                            </div>
                                            <div class="col-md-4">
                                                <label translate>VERSION</label>:
                                                <label class="text-primary">{{facts.FactsData.Version}}</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label translate>EDITED_BY</label>:
                                                <label class="text-primary">{{facts.EditorName}}</label>
                                            </div>
                                            <div class="col-md-4">
                                                <label translate>RELEASE_DATE</label>:
                                                <label class="text-primary">{{facts.CreatedOn | date :
                                                    'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-md-4">
                                                <label translate>EDITED_DATE</label>:
                                                <label class="text-primary"> {{displayDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="editMode ? 'modal-content-right':'article-pp-content'">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colspan="2">
                                                <span translate>FACTS_ABOUT_THE_COMPANY</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span translate>FIRM_NAME</span></td>
                                            <td><span>{{facts.ClientFirm}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ORGANISATION_NUMBER</span></td>
                                            <td><span>{{facts.ClientOrganizationNumber}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ADDRESS</span></td>
                                            <td><span>{{facts.ClientAddress}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ZIP_CODE</span></td>
                                            <td><span>{{facts.ClientZipcode}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>CITY</span></td>
                                            <td><span>{{facts.ClientCity}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>PHONE_NO</span></td>
                                            <td><span>{{facts.ClientPhoneNumber}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>FAX</span></td>
                                            <td><span>{{facts.ClientFax}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>EMAIL</span></td>
                                            <td><span>{{facts.ClientEmail}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>NUM_EMPLOYEES</span></td>
                                            <td><span>{{facts.ClientNoOfEmployees}}</span></td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th colspan="2">
                                                <span translate>COMPANY_ORGANIZATION</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!editMode">
                                        <tr>
                                            <td><span translate>OWNER</span></td>
                                            <td><span>{{facts.ClientOwner}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>CEO</span></td>
                                            <td><span>{{facts.ClientCEO}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ACADEMIC_DIRECTOR</span></td>
                                            <td><span>{{facts.FactsData.AcademicDirector}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>HSE_RESPONSIBLE</span></td>
                                            <td><span>{{facts.FactsData.HSEResponsible}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ELECTRO_RESPONSIBLE</span></td>
                                            <td><span>{{facts.FactsData.ElectroResponsible}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>INDUSTRIAL_LEADER</span></td>
                                            <td><span>{{facts.FactsData.IndustrialLeader}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>PUBLIC_HEALTH_DIRECTOR</span></td>
                                            <td><span>{{facts.FactsData.PublicHealthDirector}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>FIRE_CHIEF</span></td>
                                            <td><span>{{facts.FactsData.FireCheif}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>OCCUPATIONAL_HEALTH</span></td>
                                            <td><span>{{facts.FactsData.OccupationalHealth}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>OTHER</span></td>
                                            <td>
                                                <textarea [(ngModel)]="facts.FactsData.Other" pInputTextarea disabled
                                                  autoResize="autoResize"></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="editMode">
                                        <tr>
                                            <td><span translate>OWNER</span></td>
                                            <td><span>{{facts.ClientOwner}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>CEO</span></td>
                                            <td><span>{{facts.ClientCEO}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ACADEMIC_DIRECTOR</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.AcademicDirector" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>HSE_RESPONSIBLE</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.HSEResponsible" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>ELECTRO_RESPONSIBLE</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.ElectroResponsible" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>INDUSTRIAL_LEADER</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.IndustrialLeader" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>PUBLIC_HEALTH_DIRECTOR</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.PublicHealthDirector" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>FIRE_CHIEF</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.FireCheif" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>OCCUPATIONAL_HEALTH</span></td>
                                            <td>
                                                <input type="text" class="form-control"
                                                  [(ngModel)]="facts.FactsData.OccupationalHealth" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><span translate>OTHER</span></td>
                                            <td>
                                                <textarea [(ngModel)]="facts.FactsData.Other" pInputTextarea
                                                  autoResize="autoResize"></textarea>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="editMode" class="modal-footer">
            <button
              [disabled]="!(facts.FactsData.AcademicDirector || facts.FactsData.HSEResponsible || facts.FactsData.ElectroResponsible)"
              type="button" class="btn btn-primary" (click)="saveFacts()">
                <span translate>SAVE_CHANGES</span>
            </button>
        </div>
    </div>
</div>
<toaster-component></toaster-component>