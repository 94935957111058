import { IMyDpOptions } from 'mydatepicker';
import { BaseServices } from './../../kuba.services';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';

import { HelperService } from './../../../_services/helper.service';
import { ValidationService } from './../../shared/services/validation.service';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import {
    VehicleEditDto,
    ConsumptionEditDto,
    ConsumptionListDto
} from './../models/vehicle-consumption';
import { VehicleConsumptionService } from './../services/vehicle-consumption.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicle-consumption-form.component.html'
})
export class VehicleConsumptionFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    consumptionForm: FormGroup;
    vehicle: any;
    consumptionDetail: any;
    parentId: number;
    consumptionId: number;
    private subscriptions: Subscription[] = [];
    /**
          * Date picker configuration option
          */
    public defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableSince: this.getCurrentDate()
    };
    constructor(
        private _fb: FormBuilder,
        private route: ActivatedRoute,
        private vehicleConsumptionService: VehicleConsumptionService,
        private translate:TranslateService
    ) {
        this.consumptionForm = this._fb.group({
            ChooseDate: [null],
            Kilometers: this._fb.group(
                {
                    PreviousKM: ['0'],
                    TodaysKM: ['', Validators.required]
                },
                { validator: ValidationService.kilometerValidator }
            ),

            NoofLiterUsed: [''],
            EstimatedKm: ['', Validators.required],
            NoOfliterusedper10km: [''],
            Comments: ['']
        });
        this.parentId = route.snapshot.parent.parent.params['id'];
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.vehicle = <VehicleEditDto>this.route.snapshot.data['vehicle'];
        let result = <ConsumptionListDto>this.route.snapshot.data[
            'editconsumption'
        ];
        if (result) {
            this.consumptionId = result.Id;
            this.consumptionForm = this._fb.group({
                ChooseDate: new FormControl(result.Date)
                    ? HelperService.formatInputDate(result.Date)
                    : null,
                Kilometers: new FormGroup({
                    PreviousKM: new FormControl(result.PreviousKm),
                    TodaysKM: new FormControl(result.TodaysKm)
                }),
                NoofLiterUsed: new FormControl(result.NoOfLiters),
                EstimatedKm: new FormControl(result.Estimatedkm),
                NoOfliterusedper10km: new FormControl(result.LitersUsed),
                Comments: new FormControl(result.Comments)
            });
        }
        let previousNo = this.route.snapshot.data['previousnumber'];
        if (previousNo) {
            this.consumptionForm
                .get('Kilometers')
                .patchValue({ PreviousKM: previousNo.TodaysKm });
        }
    }
    saveVehicleConsumption() {
        let vehicleConsumption = this.consumptionDetail
            ? this.consumptionDetail
            : new ConsumptionEditDto();
        vehicleConsumption.Id = this.consumptionId ? this.consumptionId : 0;
        vehicleConsumption.VehicleId = this.parentId;
        vehicleConsumption.Date =
            this.consumptionForm.value.ChooseDate ?
                HelperService.formatDateFilter(this.consumptionForm.value.ChooseDate.formatted) : null
        vehicleConsumption.PreviousNumber = this.consumptionForm.value.Kilometers.PreviousKM;
        vehicleConsumption.TodaysNumber = this.consumptionForm.value.Kilometers.TodaysKM;
        vehicleConsumption.EstimatedNumber = this.consumptionForm.value.EstimatedKm;
        vehicleConsumption.Comments = this.consumptionForm.value.Comments;
        vehicleConsumption.LitersUsed = this.consumptionForm.value.NoofLiterUsed;
        vehicleConsumption.NoOfLiters = this.consumptionForm.value.NoOfliterusedper10km;
        vehicleConsumption.BusinessId = BaseServices.BusinessId;
        vehicleConsumption.Status = '1';
        if (vehicleConsumption) {
            this.subscriptions.push(this.vehicleConsumptionService
                .saveVehicleConsumption(vehicleConsumption)
                .subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                }));
        }
        this.resetForm();
    }
    /**
     * form reset
     */
    resetForm() {
        let previousNo = this.route.snapshot.data['previousnumber'];
        this.consumptionId = 0;
        this.consumptionForm.reset();
        this.consumptionForm = this._fb.group({
            ChooseDate: new FormControl(),
            Kilometers: new FormGroup({
                PreviousKM: new FormControl(previousNo.TodaysKm),
                TodaysKM: new FormControl()
            }),
            NoofLiterUsed: new FormControl(),
            EstimatedKm: new FormControl(),
            NoOfliterusedper10km: new FormControl(),
            Comments: new FormControl()
        });
    }
    /**
     * Calculation for Estimated km
     * @param event {Event}
     */
    calculateEstimatedKm() {
        let previousKilometer = this.consumptionForm.value.Kilometers
            .PreviousKM;
        let todaysKilometer = this.consumptionForm.value.Kilometers.TodaysKM;
        if (previousKilometer && todaysKilometer) {
            let estimatedKilometer = todaysKilometer - previousKilometer;
            this.consumptionForm.controls['EstimatedKm'].patchValue(
                estimatedKilometer
            );
        }
    }
    /**
           * get current date
           */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() + 1
        };
    }
    /**
     * Calculation for Liters used
     * @param event {Event}
     */
    calculateLiterused() {
        let noOfLiters = this.consumptionForm.value.NoofLiterUsed;
        if (noOfLiters) {
            let litersPerKm = noOfLiters * 10;
            this.consumptionForm.controls['NoOfliterusedper10km'].patchValue(
                litersPerKm
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
