import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'followups-note',
    templateUrl: 'note.component.html'
})
export class FollowUpNoteComponent implements OnInit {
    constructor() { }

    ngOnInit() { document.querySelector("body").classList.remove("opened");}
}