import { BaseServices } from './../../kuba.services';
import { ProjectBuilder } from './../../projects/models/projectEdit';
import { ManualService } from './../../manuals/services/manual.services'; 
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TreeView, NodeView } from './../models/index';
import { SelectItem, TreeNode } from 'primeng/api';
import { CommonService } from './../services/common.services';

import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ServiceAgreementServices } from 'src/app/kuba/service-agreement/services/service-agreement.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'common-view',
    templateUrl: 'commonView.component.html'
})
export class CommonViewComponent implements OnInit, OnDestroy {
    item: string;
    isVehicle: boolean;
    isBoth: boolean;
    treeData: TreeView;
    manualData: TreeView;
    moduleType = 'project';
    nodeType = 'articleList';
    nodeData: NodeView;
    isEditMode: boolean;
    isMainTab = false;
    newProject: boolean;
    isProject: boolean;
    isFoodSafety: boolean;
    isAssignProject: boolean;
    manualList: SelectItem[];
    selectedManual: any;
    mainType: string;
    isDefault = false;
    title: string;
    prefix: string;
    manualOriginalCopy = [];
    projectId: number;
    levelId = 0;
    levelKey = 0;
    serviceAgreementTitle = '';
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private manualServices: ManualService,
        public commonService: CommonService,
        public serviceAgreementServices: ServiceAgreementServices
    ) { }

    ngOnInit() {
        this.isEditMode = false;
        this.treeData = new TreeView();
        this.manualData = new TreeView();
        this.nodeData = new NodeView();
        this.projectId = +this.route.snapshot.params['id'];
        let featureDetail = this.route.snapshot.data['parent'];
        if (featureDetail === 'SA') { // Title for service agreement
            this.subscriptions.push(this.serviceAgreementServices.get(this.projectId).subscribe(result => {
                if (result) {
                    this.serviceAgreementTitle = result.ContractName;
                }
            }));
        }
        this.treeData.name = 'Project Documents';
        this.treeData.id = this.projectId;
        this.treeData.type = 'project';
        this.treeData.isCommonMode = false;
        if (this.projectId > 0) {
            this.setPageTitle(this.projectId);
        }

        document.querySelector("body").classList.remove("opened"); // To hide side menu bar

        this.bindManual();

        switch (BaseServices.UserRole) {
            case 'Admin':
                this.levelId = 1;
                this.levelKey = BaseServices.ApplicationId;
                break;
            case 'Portal':
                this.levelId = 2;
                this.levelKey = BaseServices.UserId;
                break;
            case 'Editor':
                this.levelId = 3;
                this.levelKey = BaseServices.BusinessId;
                break;
        }
        this.changeMainType('document/list');
    }

    ngOnDestroy() {
        document.querySelector("body").classList.add("opened");
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
          });
    }

    gotoEdit(): void {
        this.isMainTab = false;
        if (!this.isEditMode) {
            this.isEditMode = this.treeData.isEditMode = true;
            this.onTreeNodeClick(this.nodeData.data);
        } else {
            this.isEditMode = this.treeData.isEditMode = false;
            this.onTreeNodeClick(this.nodeData.data);
        }
    }

    onTreeNodeClick(node: any) {
        if (node) {
            this.nodeData = new NodeView();
            this.nodeData.data = node;
            let fileType = node.fileType;
            let documentType = node.documentType;
            let folderId = node.folderId;
            switch (documentType) {
                case 1: // Article
                    if (fileType === 1) {
                        this.nodeType = 'articleList';
                    } else if (fileType === 2) {
                        this.nodeType = this.isEditMode
                            ? 'articleForm'
                            : 'articleView';
                    }
                    break;
                case 3: // Checklist
                    if (fileType === 1) {
                        this.nodeType = 'checklistList';
                    } else if (fileType === 3) {
                        this.nodeType = this.isEditMode
                            ? 'checklistForm'
                            : 'checklistForm';
                    }
                    break;
                default:
                    this.nodeType = 'document';
                    break;
            }
        }
    }

    onNavigate(node: NodeView) {
        this.nodeData = node;
        if (node) {
            switch (node.typeName) {
                case 'Article':
                    this.nodeType = 'articleForm';

                    this.nodeType = this.isEditMode
                        ? 'articleForm'
                        : 'articleView';

                    break;
                case 'Checklist':
                    this.nodeType = 'checklistForm';

                    this.nodeType = this.isEditMode
                        ? 'checklistForm'
                        : 'checklistForm';

                    break;

                default:
                    break;
            }
        }
    }

    // binding manual List
    bindManual() {
        this.manualList = [];
        this.manualList.push({ label: 'Select Manual', value: null });
        this.subscriptions.push(this.manualServices.getManuallist(3, 1).subscribe((result:any) => {
            let manualList = result;
            manualList.forEach(element => {
                this.manualList.push({
                    label: element.Title,
                    value: { id: element.Id, name: element.Title }
                });
            });
        }));
    }

    changeManual() {
        this.manualData = new TreeView();
        this.manualData.data = [];
        if (this.selectedManual) {
            this.manualData.id = this.selectedManual.id;
            this.manualData.name = this.selectedManual.name;
            this.manualData.type = 'manual';
            this.manualData.isCommonMode = true;
            this.manualOriginalCopy = this.manualData.data.slice(
                0,
                this.manualData.data.length
            );
        }
    }

    changeMainType(type: any) {
        this.item = type;
        this.subscriptions.push(this.route.data.subscribe(data => { }));
        this.isMainTab = true;
        this.mainType = type;
        this.router.navigate([`./${this.mainType}`, { Id: this.projectId }], {
            relativeTo: this.route
        });
    }

    onNodeDrag(event: any) {
        this.manualData.data = this.manualOriginalCopy.slice(
            0,
            this.manualOriginalCopy.length
        );
    }

    setPageTitle(id: number) {
        this.title = '';
        this.subscriptions.push(this.route.data.subscribe((data:any) => {
            switch (data.parent) {
                case 'PROJECT':
                    this.isProject = true;
                    this.isBoth = true;
                    this.isVehicle = false;
                    this.isDefault = true;
                    this.prefix = 'Project Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService
                                .getByProjectId(id)
                                .subscribe((res:any) => {
                                    this.title = res.Title;
                                }));
                        } else {
                            this.title = 'New Project';
                        }
                    }));
                    break;
                case 'IC':
                    this.isVehicle = false;
                    this.isBoth = true;
                    this.isDefault = true;
                    this.prefix = 'Internal control Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService.getByICId(id).subscribe(res => {
                                this.title = res.Title;
                            }));
                        } else {
                            this.title = 'New Control';
                        }
                    }));
                    break;
                case 'OS':
                    this.isVehicle = false;
                    this.isBoth = true;
                    this.isDefault = true;
                    this.prefix = 'Other Systems Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService.getByICId(id).subscribe(res => {
                                this.title = res.Title;
                            }));
                        } else {
                            this.title = 'New Control';
                        }
                    }));
                    break;
                case 'QS':
                    this.isVehicle = false;
                    this.isBoth = true;
                    this.isDefault = true;
                    this.prefix = 'Quality System Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService.getByICId(id).subscribe(res => {
                                this.title = res.Title;
                            }));
                        } else {
                            this.title = 'New Control';
                        }
                    }));
                    break;
                case 'FS': case 'KUBA_CONTROL':
                    this.isFoodSafety = true;
                    this.isBoth = true;
                    this.isVehicle = false;
                    this.isDefault = true;
                    this.prefix = 'Food Safety Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService.getByFSId(id).subscribe(res => {
                                this.title = res.Title;
                            }));
                        } else {
                            this.title = 'New FoodSafety';
                        }
                    }));
                    break;
                case 'VEHICLE':
                    this.isProject = false;
                    this.isVehicle = true;
                    this.isDefault = true;
                    this.prefix = 'Vehicles Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService
                                .getByVehicleId(id).subscribe(res => {
                                    this.title = res.RegistrationNo;
                                }));
                        } else {
                            this.title = 'New Vehicle';
                        }
                    }));
                    break;
                case 'FDV':
                    this.isProject = false;
                    this.isVehicle = false;
                    this.isDefault = true;
                    this.isBoth = true;
                    this.prefix = 'Fire Deviation Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService.getByFDVId(id).subscribe(res => {
                                this.title = res.Name;
                            }));
                        } else {
                            this.title = 'New FDV';
                        }
                    }));
                    break;
                case 'AP':
                    this.isAssignProject = true;
                    this.isDefault = true;
                    this.prefix = 'Project Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService
                                .getProjectById(id)
                                .subscribe((res: any) => {
                                    this.title = res.Name;
                                }));
                        } else {
                            this.title = 'New Project';
                        }
                    }));

                    break;
                case 'FRAMEWORK':
                    this.isVehicle = false;
                    this.isDefault = true;
                    this.isBoth = true;
                    this.prefix = 'Contract Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService
                                .getFrameworkById(id)
                                .subscribe((res: any) => {
                                    this.title = res.ContractName;
                                }));
                        } else {
                            this.title = 'New Framework';
                        }
                    }));

                    break;

                case 'SA':
                    this.isAssignProject = false;
                    this.isDefault = true;
                    this.isProject = false;
                    this.isVehicle = false;
                    this.isBoth = true;
                    this.prefix = 'Services Agreement Details of';
                    this.subscriptions.push(this.route.params.subscribe((params: Params) => {
                        if (id) {
                            this.subscriptions.push(this.commonService.getBySAId(id).subscribe((res:any) => {
                                this.title = res.ContractName;
                            }));
                        } else {
                            this.title = 'New Project';
                        }
                    }));
            }
        }));
    }
}
