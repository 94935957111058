export class CustomizeHomePage {
    Id: number;
    CmsId: number;
    AppId: number;
    BusinessId: number;
    Type: number;
    Title: string;
    ContentData: string;
    BannerImg: any;
    Status: string;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
}

export enum HomePageType {
    AboutUs = 45,
    ContactUs = 46,
    Services = 47,
    Products = 48,
    AboutKuba = 49,
    DashboardText = 50,
    AboutKubaIk = 51,
    Help = 52,
    About = 53,
    HmsTv = 54,
}
