import { NotificationSubscription } from "src/app/shared/employee-contact";

export class InternalControl {
    Id: number;
    Title: string;
    Description: string;
    IsEditorOnly: string;
    Status: string;
    BusinessId: number;
    ApplicationId: number;
    CreatedBy: number;
    CreatorName: string;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    ShowDeviation?: boolean;
    ICEmailSubscribers: NotificationSubscription[];
    ICUserDto: InternalControlUser[];
    InternalControlUsers : InternalControlUsers;
    UserCount: number;
    InternalControlTemplateId : number;
    CopyRA: boolean;
}

export class InternalControlDetail {
    Title: string;
    Description: string;
    constructor() {
        this.Title = '';
        this.Description = '';
    }
}
export class InternalControlUser {
    InternalControlId: number;
    UserId: number;
    Name: string;
}

export class InternalControlUsers {
    InternalControlId: number;
    UserId: number;
    Count:String;
    NameList: String;
}

export class KeyPairValue {
    Key?: number;
    Value: string;
}
