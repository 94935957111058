<div class="tab-content">
  <div class="page-title">
    <div>
      <strong translate>CONTROL_REPORT</strong>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-sm-3">
        <button
          (click)="shiftLeft()"
          class="btn btn-outline-primary"
          [disabled]="!enableLeftArrow"
        >
          <span class="icon ic-sm icon-back"></span>
        </button>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label class="form-label">
            <span translate>FROM_DATE</span>
          </label>
          <div>
            <my-date-picker
              name="startdate"
              [options]="startDateOptions"
              placeholder="{{ 'SELECT_START_DATE' | translate }}"
              (dateChanged)="onStartDateChanged($event)"
            >
            </my-date-picker>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label>
            <span translate>END_DATE</span>
          </label>
          <div>
            <my-date-picker
              name="enddate"
              [options]="endDateOptions"
              placeholder="{{ 'SELECT_END_DATE' | translate }}"
              (dateChanged)="onEndDateChanged($event)"
            >
            </my-date-picker>
          </div>
        </div>
      </div>
      <div class="col-sm-3 text-right">
        <button
          class="btn btn-outline-primary"
          (click)="shiftRight()"
          [disabled]="!enableRightArrow"
        >
          <span class="icon ic-sm icon-forward"></span>
        </button>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-primary" (click)="generateReport()">
        <span translate>GENERATE_REPORT</span>
      </button>
    </div>
    <div>
      <h6 translate class="text-center">DAILY_TASKS</h6>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 100px"></th>
              <th
                style="width: 100px; text-align: center"
                *ngFor="let item of widowAuditTasks"
              >
                {{ item.DayOfWeek | translate }}
                <p style="white-space: pre-wrap">
                  {{ item.CreatedOnFormated }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-dataRow
              let-dRi="index"
              [ngForOf]="widowAuditTasks[0]?.checklists"
            >
              <tr>
                <th style="text-align: left; width: 150px">
                  {{ dataRow.Name }}
                </th>
                <ng-template ngFor let-dCi="index" [ngForOf]="widowAuditTasks">
                  <td>
                    <div
                      *ngIf="
                        widowAuditTasks[dCi].checklists[dRi].NeedPerformance
                      "
                    >
                      <span
                        *ngIf="
                          !widowAuditTasks[dCi].checklists[dRi]
                            .IsCompletionAvailable
                        "
                      >
                        <span
                          *ngIf="
                            widowAuditTasks[dCi].checklists[dRi].Status ==
                              'NEW' &&
                            widowAuditTasks[dCi].checklists[dRi].IsNewTask
                          "
                          class="icon ic-sm icon-star-circle text-primary"
                        >
                        </span>
                        <span
                          *ngIf="
                            widowAuditTasks[dCi].checklists[dRi].Status ==
                              'NEW' &&
                            !widowAuditTasks[dCi].checklists[dRi].IsNewTask
                          "
                          class="text-muted icon ic-sm icon-cancel"
                          (click)="
                            ShowDailyTaskDialog(
                              widowAuditTasks[dCi].checklists[dRi].Id
                            )
                          "
                        >
                        </span>
                        <span
                          *ngIf="
                            widowAuditTasks[dCi].checklists[dRi].Status ==
                            'OPEN'
                          "
                          class="near-deadline"
                          (click)="
                            ShowDailyTaskDialog(
                              widowAuditTasks[dCi].checklists[dRi].Id
                            )
                          "
                        >
                          <i
                            class="icon ic-sm icon-star-circle text-secondary"
                          ></i>
                        </span>
                      </span>
                      <span
                        *ngIf="
                          widowAuditTasks[dCi].checklists[dRi]
                            .IsCompletionAvailable &&
                          widowAuditTasks[dCi].checklists[dRi].IsTodayCompleted
                        "
                      >
                        <span
                          *ngIf="
                            !widowAuditTasks[dCi].checklists[dRi].IsDeviation
                          "
                          class="clear-deadline"
                          (click)="
                            ShowDailyTaskDialog(
                              widowAuditTasks[dCi].checklists[dRi].Id
                            )
                          "
                        >
                          <i class="icon ic-sm icon-clear-deadline"></i
                        ></span>
                        <span
                          *ngIf="
                            widowAuditTasks[dCi].checklists[dRi].IsDeviation
                          "
                          style="color: #0ab7e2"
                          class="icon ic-sm icon-clear-deadline"
                          (click)="
                            ShowDailyTaskDialog(
                              widowAuditTasks[dCi].checklists[dRi].Id
                            )
                          "
                        ></span>
                      </span>
                      <span
                        *ngIf="
                          widowAuditTasks[dCi].checklists[dRi]
                            .IsCompletionAvailable &&
                          !widowAuditTasks[dCi].checklists[dRi].IsTodayCompleted
                        "
                        (click)="
                          ShowDailyTaskDialog(
                            widowAuditTasks[dCi].checklists[dRi].Id
                          )
                        "
                      >
                        <i class="icon ic-sm icon-clock text-flame"></i
                      ></span>
                    </div>
                  </td>
                </ng-template>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="temperatureControlCheck">
      <h6 translate class="text-center">TEMPERATURE_CONTROL</h6>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 100px"></th>
              <th
                style="width: 100px; text-align: center"
                *ngFor="let item of windowAuditTempControlList"
              >
                {{ item.DayOfWeek | translate }}
                <p style="white-space: pre-wrap">
                  {{ item.CreatedOnFormated }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-dataRow
              let-dRi="index"
              [ngForOf]="windowAuditTempControlList[0]?.checklists"
            >
              <tr>
                <th style="text-align: left; width: 150px">
                  {{ dataRow.Name }}
                </th>
                <ng-template
                  ngFor
                  let-dCi="index"
                  [ngForOf]="windowAuditTempControlList"
                >
                  <td
                    [style.color]="
                      windowAuditTempControlList[dCi].checklists[dRi]
                        .IsDeviation == true
                        ? 'red'
                        : 'green'
                    "
                  >
                    <div
                      *ngIf="
                        windowAuditTempControlList[dCi].checklists[dRi]
                          .NeedPerformance
                      "
                    >
                      <span
                        *ngIf="
                          windowAuditTempControlList[dCi].checklists[dRi]
                            .IsCompletionAvailable == true
                        "
                      >
                        <span
                          *ngIf="
                            windowAuditTempControlList[dCi].checklists[dRi]
                              .Status == 'DONE'
                          "
                          (click)="
                            ShowTempControlDialog(
                              windowAuditTempControlList[dCi].checklists[dRi]
                            )
                          "
                        >
                          {{
                            windowAuditTempControlList[dCi].checklists[dRi]
                              .LatesttempControl + "°C"
                          }}
                        </span>
                        <span
                          *ngIf="
                            windowAuditTempControlList[dCi].checklists[dRi]
                              .Status == 'UNDERCOMPLETION'
                          "
                          class="near-deadline"
                        >
                          <span class="icon ic-sm icon-star-circle"></span>
                        </span>
                      </span>
                      <span
                        *ngIf="
                          !windowAuditTempControlList[dCi].checklists[dRi]
                            .IsCompletionAvailable
                        "
                      >
                        <span
                          *ngIf="
                            windowAuditTempControlList[dCi].checklists[dRi]
                              .IsTaskCreated
                          "
                        >
                          <span
                            class="text-muted icon ic-sm icon-cancel"
                          ></span>
                        </span>
                        <span
                          *ngIf="
                            !windowAuditTempControlList[dCi].checklists[dRi]
                              .IsTaskCreated
                          "
                        >
                          <span></span>
                        </span>
                      </span>
                    </div>
                  </td>
                </ng-template>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <h6 translate class="text-center">CLEANING</h6>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 100px"></th>
              <th
                style="width: 100px; text-align: center"
                *ngFor="let item of windowAuditCklList"
              >
                {{ item.DayOfWeek | translate }}
                <p style="white-space: pre-wrap">
                  {{ item.CreatedOnFormated }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-dataRow
              let-dRi="index"
              [ngForOf]="windowAuditCklList[0]?.checklists"
            >
              <tr>
                <th style="text-align: left; width: 150px">
                  {{ dataRow.Name }}
                </th>
                <ng-template
                  ngFor
                  let-dCi="index"
                  [ngForOf]="windowAuditCklList"
                >
                  <td>
                    <div>
                      <span
                        *ngIf="
                          !windowAuditCklList[dCi].checklists[dRi]
                            .IsCompletionAvailable &&
                          !windowAuditCklList[dCi].checklists[dRi].IsDeviation
                        "
                      >
                      </span>
                      <span
                        *ngIf="
                          windowAuditCklList[dCi].checklists[dRi]
                            .IsCompletionAvailable
                        "
                      >
                        <span
                          *ngIf="
                            windowAuditCklList[dCi].checklists[dRi].IsDeviation
                          "
                        >
                          <span
                            style="color: #0ab7e2"
                            class="icon ic-sm icon-clear-deadline"
                            (click)="
                              ShowChecklistDialog(
                                windowAuditCklList[dCi].checklists[dRi].Id
                              )
                            "
                          ></span>
                        </span>
                        <span
                          *ngIf="
                            !windowAuditCklList[dCi].checklists[dRi]
                              .IsDeviation &&
                            windowAuditCklList[dCi].checklists[dRi].Status ==
                              'DONE'
                          "
                        >
                          <span
                            style="color: green"
                            class="icon ic-sm icon-clear-deadline"
                            (click)="
                              ShowChecklistDialog(
                                windowAuditCklList[dCi].checklists[dRi].Id
                              )
                            "
                          ></span>
                        </span>
                        <span
                          class="text-secondary"
                          *ngIf="
                            windowAuditCklList[dCi].checklists[dRi].Status ==
                            'UNDERCOMPLETION'
                          "
                        >
                          <span class="icon ic-sm icon-star-circle"></span>
                        </span>
                      </span>
                    </div>
                  </td>
                </ng-template>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="text-center">
      <span>
        <i class="icon ic-md icon-clear-deadline"></i>
        <strong translate style="color: green">DONE_STATUS</strong>
      </span>
      <span style="color: grey">
        <span class="icon ic-md icon-cancel"></span>
        <strong translate>CHECKLIST_NOT_COMPLETED</strong>
      </span>
      <span class="clear-deadline" style="color: #0ab7e2">
        <i style="color: #0ab7e2" class="icon ic-md icon-clear-deadline"></i>
        <strong translate>CHECKLIST_DEVIATIONS</strong>
      </span>
      <span class="text-flame">
        <i class="icon ic-md icon-clock"></i>
        <strong translate>COMPLETED_PAST_DEADLINE</strong>
      </span>
      <span class="text-secondary">
        <i class="icon ic-md icon-star-circle"></i>
        <strong translate>TASK_STARTED</strong>
      </span>
      <span class="text-primary">
        <i class="icon ic-md icon-star-circle"></i>
        <strong translate>NEW_TASK</strong>
      </span>
    </div>
  </div>
  <p-dialog
    header="{{ 'TEMPERATURE_CONTROL' | translate }}"
    [(visible)]="previewTempControl"
    [style]="{ width: '600px', height: '300px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <div class="list-item">
      <span style="width: 500px" *ngIf="dataList.TemperatureControlInfo">
        <h4>{{ dataList.TemperatureControlInfo.Title }}</h4>
      </span>
      <span>
        <button
          type="submit"
          class="btn btn-outline-primary"
          (click)="generateReportForTempControl(dataList)"
        >
          <span translate>PRINT_AS_PDF</span>
        </button>
      </span>
      <div>
        <strong>&nbsp;&nbsp;<span translate>OBJECT_NAME</span>:</strong>
        {{ dataList.Name }}
      </div>
      <div *ngIf="dataList.TemperatureControlInfo">
        <strong
          >&nbsp;&nbsp;<span translate>RECOMMENDED_TEMPERTURE</span>:</strong
        >
        {{ dataList.TemperatureControlInfo.RecommendedTemp }}
      </div>
      <div *ngIf="dataList.TemperatureControlInfo">
        <strong>&nbsp;&nbsp;<span translate>DESCRIPTION</span>:</strong>
        <div>&nbsp;&nbsp;{{ dataList.TemperatureControlInfo.Description }}</div>
      </div>
      <div class="action-btns-wrapper">
        <div *ngIf="dataList.TemperatureControlInfo">
          <strong
            >&nbsp;&nbsp;<span translate>ACTUAL_TEMPERATURE</span>:</strong
          >
          {{ dataList.TemperatureControlInfo.ActualTemp }}
        </div>
      </div>
      <div *ngIf="dataList.TemperatureControlInfo">
        <strong>&nbsp;&nbsp;<span translate>DEVIATION</span>:</strong>
        {{ dataList.TemperatureControlInfo.DeviationNum }}
      </div>
    </div>
  </p-dialog>

  <p-dialog
    header="{{ cklListData.Name | translate }}"
    *ngIf="cklListData"
    [(visible)]="previewChecklist"
    [style]="{ width: '800px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <div>
      <ng-template
        ngFor
        let-section
        let-headPoint="index"
        [ngForOf]="cklListData.CheckListInfo"
      >
        <table class="table">
          <thead>
            <tr>
              <th>{{ headPoint + 1 }} {{ section.SectionTitle }}</th>
              <th>
                <span *ngIf="headPoint === 0" translate>STATUS</span>
              </th>
              <th>
                <span *ngIf="headPoint === 0" translate>COMMENTS</span>
              </th>
              <th>
                <span *ngIf="headPoint === 0"></span>
              </th>
            </tr>
          </thead>
          <ng-template
            ngFor
            let-sectionItem
            let-i="index"
            [ngForOf]="section.SectionItemData"
          >
            <tbody>
              <tr>
                <td class="col-sm-2">
                  <label
                    >{{ headPoint + 1 }}. {{ i + 1 }}
                    {{ sectionItem.SectionItemTitle }}</label
                  >
                </td>
                <td class="col-sm-2">
                  <label translate>{{ sectionItem.Status }}</label>
                </td>
                <td class="col-sm-2">
                  <textarea
                    disabled
                    [(ngModel)]="sectionItem.Comments"
                    class="form-control single-line"
                    autoResize="autoResize"
                  ></textarea>
                </td>
                <td>
                  <table class="table">
                    <tr
                      class="file-item"
                      *ngFor="
                        let f of sectionItem.checklistAttachment;
                        let i = index
                      "
                    >
                      <td class="file-name col-sm-4">
                        <span class="thumb-sm">
                          <ecpl-image-viewer
                            *ngIf="
                              f.path != null &&
                              humanizeType(getFileExtension(f.path)) == 'Image'
                            "
                            [imgSrc]="f.path"
                            [imgType]="f.path"
                            [imgName]="f.filename"
                            [id]="f.id"
                            thumbNail="true"
                          >
                          </ecpl-image-viewer>

                          <ecpl-onlyoffice-viewer
                            *ngIf="
                              f.path != null &&
                              humanizeType(getFileExtension(f.path)) ==
                                'Document'
                            "
                            [onlyofficeSrc]="f.path"
                            [onlyofficeKey]="f.nodeId + 'key'"
                            [onlyofficeType]="getFileExtension(f.path)"
                            [onlyofficeName]="f.filename"
                            [onlyofficeMode]="false"
                            [id]="f.nodeId"
                          >
                          </ecpl-onlyoffice-viewer>

                          <ecpl-pdf-viewer
                            *ngIf="
                              f.path != null &&
                              humanizeType(getFileExtension(f.path)) == 'Pdf'
                            "
                            [id]="f.nodeId"
                            [pdfSrc]="f.path"
                            [pdfName]="f.filename"
                          ></ecpl-pdf-viewer>

                          <a
                            href="javascript:void(0)"
                            *ngIf="f.type == 'folder'"
                            [id]="f.nodeId"
                          >
                            <img
                              src="/assets/file-icons/kb-folder.png"
                              alt="Folder"
                            />
                          </a>
                        </span>
                        <span class="caption">
                          <small>{{ f.filename }}</small>
                        </span>
                        <span>
                          <a
                            *ngIf="
                              f.path != null &&
                              (humanizeType(getFileExtension(f.path)) ==
                                'Document' ||
                                humanizeType(getFileExtension(f.path)) ==
                                  'Text' ||
                                humanizeType(getFileExtension(f.path)) ==
                                  'Image')
                            "
                            href="javascript:void(0);"
                            class="btn btn-icon"
                            (click)="downloadFile(f.path)"
                            pTooltip="{{ 'CLICK_DOWNLOAD' | translate }}"
                            tooltipPosition="top"
                          >
                            <span class="icon ic-sm icon-send-file-o"></span>
                          </a>
                        </span>
                        <span>
                          <a
                            *ngIf="
                              f.path != null &&
                              humanizeType(getFileExtension(f.path)) == 'Pdf'
                            "
                            href="javascript:void(0);"
                            class="btn btn-icon"
                            (click)="downloadFile(f.path)"
                            pTooltip="{{ 'CLICK_DOWNLOAD' | translate }}"
                            tooltipPosition="top"
                          >
                            <span class="icon ic-sm icon-send-file-o"></span>
                          </a>
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="text-right" style="border: 0">
                  <strong>{{ sectionItem.Signature }}</strong>
                </td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </ng-template>
    </div>
  </p-dialog>
  <p-dialog
    header="{{ 'DAILY_TASKS' | translate }}"
    [(visible)]="previewTask"
    [style]="{ width: '450px' }"
    [resizable]="false"
    [draggable]="false"
  >
    <div class="list-item" *ngIf="taskData">
      <div>
        <span>
          <h4>{{ taskData.Name }}</h4>
        </span>
        <span style="float: right">
          <button
            *ngIf="
              taskData.DailyTaskInfo.CompletedDate != 'NOT_COMPLETED' &&
              taskData.ChecklistId != 0
            "
            type="submit"
            class="btn btn-outline-primary"
            (click)="generateReportForDailyTask(taskData.Id)"
          >
            <span translate>PRINT_AS_PDF</span>
          </button>
        </span>
      </div>
      <div>
        <strong><span translate>OBJECT_NAME</span>:</strong> {{ zoneName }}
      </div>
      <div>
        <strong><span translate>COMPLETED_BY</span>:</strong>
        {{ taskData.DailyTaskInfo.CompletedBy | translate }}
      </div>
      <div>
        <strong><span translate>COMPLETED_DATE</span>:</strong>
        {{ taskData.DailyTaskInfo.CompletedDate | translate }}
      </div>
      <div
        class="audit-panel-title"
        *ngIf="
          taskData.Status == 'NEW' ||
          (taskData.Status == 'OPEN' && taskData.CreatedOn < currentDate) ||
          (taskData.Status == 'DONE' && taskData.LateCompletionDetails != null)
        "
      ></div>
      <form
        [formGroup]="AfterDeadlineCompletion"
        class="form"
        *ngIf="
          (taskData.Id && taskData.Status == 'NEW') ||
          (taskData.Status == 'OPEN' && taskData.CreatedOn < currentDate) ||
          (taskData.Status == 'DONE' && taskData.LateCompletionDetails != null)
        "
      >
        <h4
          translate
          *ngIf="taskData.Status == 'NEW' || taskData.Status == 'OPEN'"
        >
          COMPLETE_TASK_PAST_DEADLINE<span>:</span>
        </h4>
        <h4
          translate
          *ngIf="
            taskData.Status == 'DONE' && taskData.LateCompletionDetails != null
          "
        >
          COMPLETED_TASK_PAST_DEADLINE<span>:</span>
        </h4>
        <div class="form-group">
          <label for="Reason" class="col-form-label">
            <strong translate>REASON</strong>
            <span
              *ngIf="taskData.Status == 'NEW' || taskData.Status == 'OPEN'"
              class="required"
              >*</span
            >
          </label>
          <div>
            <input
              *ngIf="taskData.Status == 'OPEN'"
              [attr.disabled]="true"
              [value]="taskData.LateCompletionDetails.Reason"
              type="text"
              pInputText
              formControlName="Reason"
            />
            <input
              *ngIf="taskData.Status == 'NEW'"
              type="text"
              pInputText
              formControlName="Reason"
            />
            <span
              *ngIf="
                taskData.Status == 'DONE' &&
                taskData.LateCompletionDetails != null
              "
            >
              {{ taskData.LateCompletionDetails.Reason }}</span
            >
          </div>
        </div>
        <div>
          <button
            *ngIf="taskData.Status == 'OPEN'"
            class="btn btn-primary"
            (click)="completeAfterDeadlineTask(taskData)"
          >
            <span translate>COMPLETE_TASK</span>
          </button>
          <button
            *ngIf="taskData.Status == 'NEW'"
            class="btn btn-primary"
            [disabled]="AfterDeadlineCompletion.invalid"
            (click)="completeAfterDeadlineTask(taskData)"
          >
            <span translate>COMPLETE_TASK</span>
          </button>
        </div>
      </form>
    </div>
  </p-dialog>
</div>
<div class="panel-loader" *ngIf="loading">
  <span>
    <span class="loader-icon"></span>
    <span translate>PROCESSING</span>
  </span>
</div>
<toaster-component></toaster-component>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
