import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InitialServices } from '../_services/initial.services';
import { AccessLog } from '../kuba/accesslog/models/accesslog';
import { BaseServices, KubaServices } from '../kuba/kuba.services';

import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service'
import { Observable } from 'rxjs';
import { AccessLogServices } from './../kuba/accesslog/services/accesslog.services';
import { CustomizeHomePageService } from './../kuba/customize-homepage/customize-homepage.service';
import { CacheHandlerService } from './../kuba/cached.services';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';



@Component({
    selector: 'login-original',
    templateUrl: 'login-original.component.html'
})

export class LoginOriginalComponent implements OnInit {
    accesslog: any;
    model: any = {};
    loading = false;
    returnUrl: string;
    baseUrl: string;
    userSettings: any;
    deviceInfo = null;
    acessLog: AccessLog[];
    customId: number;
    businessId: number;
    Content: string;
    header: boolean;
    private subscriptions: Subscription[] = [];
    ipAddress: any;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private accessLogServices: AccessLogServices,
        private alertService: AlertService,
        private initialServices: InitialServices,
        private kubaServices: KubaServices,
        private cacheHandlerService: CacheHandlerService,
        @Inject(APP_BASE_HREF) private baseHref: string,
        @Inject(DOCUMENT) private document: any,
        private customizeHomePageService: CustomizeHomePageService,
        private http: HttpClient
    ) { 
        this.getIPAddress();
    }

    ngOnInit() {
        this.header = true;
        this.businessId = +this.route.snapshot.params['businessId'];
        this.subscriptions.push(this.customizeHomePageService.getData(this.businessId, 45, BaseServices.UserId).subscribe((homePageData: any) => {
            if (homePageData) {
                this.Content = (homePageData.ContentData).replace('<p>', '').replace('</p>', '');
            }
        }))
        this.authenticationService.logout();
        this.initialServices.appInitializing();
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app';
    }

    changeNavigation(cmsId: number) {
        this.header = true;
        this.customId = cmsId;
        this.subscriptions.push(this.customizeHomePageService.getData(this.businessId, this.customId, BaseServices.UserId).subscribe((homePageData: any) => {
            if (homePageData) {
                this.Content = (homePageData.ContentData).replace('<p>', '').replace('</p>', '');
            }
        }))
    }
    getIPAddress()
    {
      this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
        this.ipAddress = res.ip;
      });
    }
    login() {
        this.loading = true;
        this.subscriptions.push(this.authenticationService.login(this.model.username, this.model.password,this.ipAddress)
            .subscribe(
                res => {
                    if (sessionStorage.getItem('session')) {
                        // cache clear
                        this.cacheHandlerService.clearCachedData();
                        // set userSetting for apploication when is not set
                        if (sessionStorage.getItem('userSettings') == null) {
                            let observables = [];

                            // Retrives all available languages
                            observables.push(this.kubaServices.getAllLanguages());

                            // Retrive current user language , UserProfile & UserTheme
                            observables.push(this.kubaServices.getUserCustomSetting());

                            // get and set UserBase
                            observables.push(this.kubaServices.getUserBase());

                            // get and set UserFeatures
                            observables.push(this.kubaServices.getUserFeatures());

                            // Observe above call for completion
                            /* Observable.call(observables).subscribe(
                                (result:any) => {
                                    result.forEach((data:any) => {});
                                },
                                (error:any) => console.error('Error at App Init services : ', error),
                                () => {
                                    // Setting up base user data (Experimental)
                                    // BaseServices.setUserSettings(this.userSettings);
                                    // setting Up Session Storage and listen to it
                                    sessionStorage.setItem('userSettings', JSON.stringify(this.userSettings));
                                    // listening to the local storage for anonymous of client users
                                    window.addEventListener('storage', function (e) {
                                        if (e.oldValue !== e.newValue) {
                                            this.location.href = '/login-original';
                                        }
                                    });
                                    if (sessionStorage.getItem('appSettings') == null) {
                                        window.location.href = window.location.href.replace(window.location.pathname, '')
                                            + '/' + this.userSettings.userBase.BaseUrl;
                                        this.router.navigate([this.returnUrl]);
                                    } else {
                                        this.router.navigate([this.returnUrl]);
                                       this.addAccessLog();
                                    }
                                }
                            ); */
                        }
                    } else {
                        this.alertService.error('Invalid User or Password');
                        // this.loading = false;
                    }
                },
                error => {
                    if (error.status === 0) {
                        this.alertService.error('User may not available.');
                    }
                    if (error.status !== 0) {
                        this.alertService.error(error._body);
                    }
                    // this.loading = false;
                }));
    }
    /**
     * access log for hit counts
     */
    addAccessLog() {
        let user = new AccessLog();
        user.UserId = BaseServices.UserId;
        user.BusinessId = BaseServices.BusinessId;
        user.IP = '';
        user.Browser = '';
        user.OS = '';
        user.ApplicationId = BaseServices.ApplicationId;
        user.Type = 'Web';
        user.LogIn = new Date();
        this.subscriptions.push(this.accessLogServices.addAccessLog
            (user)
            .subscribe((accsess: any) => { }));
    }
    goToForgetPassword() {
        this.router.navigate(['./../forget-password'], { relativeTo: this.route });
    }  
    
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
