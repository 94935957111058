<div class="tab-content">
    <div class="table-view">
        <div class="page-actions text-right">
            <button *ngIf="isGuestHideButton" type="button" class="btn btn-link" (click)="toggleEditMode()">
                <span [ngClass]="editMode ? 'text-danger':'text-success'">
                    <span *ngIf="editMode" translate>GO_DISPLAY</span>
                    <span *ngIf="!editMode" translate>GO_EDIT</span>
                    <span class="icon ic-md" [ngClass]="editMode ? 'icon-view-content':'icon-edit-content'"></span>
                </span>
            </button>
        </div>
        <!-- Table starts -->
        <p-table [value]="orgList" #orgTable [rows]="10" [paginator]="true" [pageLinks]="3"
          [rowsPerPageOptions]="[5,10,20]"
          [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="table-options">
                    <div class="pull-right">
                        <!-- <kuba-export [reportData]="electroTable.filteredValue || electroTable.value" [additionalData]="additionalData"></kuba-export> -->
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="content.article.documentNumber">{{'DOCUMENT_NR' | translate}} <p-sortIcon
                          field="content.article.documentNumber"></p-sortIcon></th>
                    <th pSortableColumn="filename">{{'TITLE'| translate}} <p-sortIcon field="filename"></p-sortIcon>
                    </th>
                    <th pSortableColumn="content.article.version">{{'VERSION' | translate }} <p-sortIcon
                          field="content.article.version"></p-sortIcon></th>
                    <th pSortableColumn="createdBy">{{'CREATED_BY' | translate}} <p-sortIcon
                          field="createdBy"></p-sortIcon></th>
                    <th pSortableColumn="createdOn">{{'CREATED_DATE' | translate}} <p-sortIcon
                          field="createdOn"></p-sortIcon></th>
                    <th pSortableColumn="content.article.modifiedBy">{{'EDITED_BY' | translate}} <p-sortIcon
                          field="content.article.modifiedBy"></p-sortIcon></th>
                    <th pSortableColumn="content.article.modifiedOn">{{'EDITED_DATE' | translate}} <p-sortIcon
                          field="content.article.modifiedOn"></p-sortIcon></th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-articleOrg>
                <tr>
                    <td>
                        <span>{{articleOrg.content.article.documentNumber}}</span>
                    </td>
                    <td>
                        <span class="thumb-sm">
                            <ecpl-article-viewer *ngIf="articleOrg.mimetype === 'article'"
                              [articleName]="articleOrg.filename" [(articleSrc)]="articleOrg.content"
                              [editMode]="editMode" [businessMode]="busMode" [id]="articleOrg.content.article.id"
                              (saveChanges)="saveUploaded($event)">
                            </ecpl-article-viewer>
                            <electro-facts-abt-company *ngIf="articleOrg.mimetype === 'factform'" [editMode]="editMode"
                              [facts]="articleOrg.content.article.factAboutCompanyData" (save)="saveFactsReload()">
                            </electro-facts-abt-company>
                            <a href="javascript:void(0)"
                              (click)="openiframe(articleOrg.content.article.orgChartData,articleOrg.content.article.modifiedBy)"
                              *ngIf="articleOrg.mimetype === 'orgchart'">
                                <img style="width: 40px;" src="/assets/file-icons/kb-article.png" alt="Article">
                            </a>
                        </span>
                        <span translate>{{articleOrg.filename}}</span>
                    </td>
                    <td>
                        <span>{{articleOrg.content.article.version}}</span>
                    </td>
                    <td>
                        <span>{{articleOrg.createdBy}}</span>
                    </td>
                    <td>
                        <span>{{articleOrg.createdOn | date : 'dd.MM.yyyy'}}</span>
                    </td>
                    <td>
                        <span>{{articleOrg.content.article.modifiedBy}}</span>
                    </td>
                    <td>
                        <span>{{articleOrg.content.article.modifiedOn | date : 'dd.MM.yyyy'}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table Ends -->
    </div>
</div>
<div [ngClass]="isiFrameEnabled ? 'e-org-modal' : 'hidden'">
    <div class="modal-dialog" [ngClass]="{'edit-mode' : editMode}">
        <div class="modal-header">
            <h4 *ngIf="!editMode">
                <span class="ic-md">
                    <img src="/assets/file-icons/kb-article.png" alt="Article file">
                </span>
                <span translate>ORGANIZATIONAL_CHART</span>
            </h4>
            <button (click)="hideModal()" class="btn btn-icon close">
                <span class="icon ic-md icon-multiply"></span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 offset-sm-2">
                        <div class="article-print-preview">
                            <div *ngIf="isiFrameEnabled">
                                <button *ngIf="!editMode" class="btn text-primary"
                                  (click)="printChartPdf()">
                                    <span class="icon ic-sm icon-print"></span>
                                    <span translate>PRINT_AS_PDF</span>
                                </button>
                                <div class="py-3" *ngIf="!editMode">
                                    <h2 translate>ORGANIZATIONAL_CHART</h2>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label translate>DOCUMENT_NUMBER</label>:
                                                    <label class="text-primary">{{orgchartData.DocumentNumber}}</label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label translate>VERSION</label>:
                                                    <label class="text-primary">{{orgchartData.Version}}</label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label translate>CHAPTER</label>:
                                                    <label class="text-primary">{{orgchartData.Chapter}}</label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label translate>EDITED_BY</label>:
                                                    <label class="text-primary">{{orgchartData.EditorName}}</label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label translate>RELEASE_DATE</label>:
                                                    <label class="text-primary">{{orgchartData.CreatedOn | date :
                                                        'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label translate>EDITED_DATE</label>:
                                                    <label class="text-primary">{{EditedOn | date :
                                                        'dd/MM/yyyy'}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <iframe id="graphEditor" *ngIf="editMode" #iframe frameborder="0" width="100%" height="100%"></iframe>
            <div class="text-center"><img style="margin-top: 25px" *ngIf="!editMode && isSvgchart" [src]="svgchart">
            </div>
        </div>
    </div>
</div>
<div #hiddenAction style="width:1px; height:1px; overflow:hidden" *ngIf="file != null">
    <ecpl-article-viewer [articleName]="file.filename" [(articleSrc)]="file.content"
      (saveChanges)="saveUploaded($event)" [id]="file.nodeId" [editMode]="editMode">
    </ecpl-article-viewer>
</div>
<toaster-component></toaster-component>
<!--Panel Loader-->
<div class="panel-loader" *ngIf="showLoader">
    <span>
        <span class="loader-icon"></span>
        <span>Processing...</span>
    </span>
</div>
<!--Panel Loader-->