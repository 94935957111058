import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { News } from '../models/news';
import { NewsServices } from './news.service';

@Injectable()
export class NewsListResolver implements Resolve<News[]> {
    constructor(private backend: NewsServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<News[]> {
        if (BaseServices.roleId === '1') {
            return this.backend.adminNewslist();
        } else {
            return this.backend.list();
        }
    }
}

@Injectable()
export class NewsEditResolver implements Resolve<News> {
    constructor(private backend: NewsServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.get(+route.params['aid']);
    }
}
@Injectable()
export class AdminNewsListResolver implements Resolve<News[]> {
    constructor(private backend: NewsServices) { }
    resolve(): Observable<News[]> {
        return this.backend.adminNewslist();
    }
}
