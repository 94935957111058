import { ProjectAdminListResolver } from './../project-admin/service/project-admin.resolver';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import {
    ProjectFeaturesResolver,
    ProjectEditResolver
} from './../projects/services/project.resolver';
import { AssignProjectViewComponent } from './component/assign-project-view.component';
import { AssignprojectListResolver } from './services/assign-projects.resolver';
import { AssignProjectService } from './services/assign-projects.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { AssignProjectMainComponent } from './assign-projects.component';
import { AssignProjectComponent } from './component/assign-project.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
    imports: [
        AppSharedModule,
        RouterModule,
        ConfirmDialogModule,
        TranslateModule,
        EcplTabNavigationModule
    ],
    exports: [
        AssignProjectComponent,
        AssignProjectMainComponent,
        AssignProjectViewComponent
    ],
    declarations: [
        AssignProjectComponent,
        AssignProjectMainComponent,
        AssignProjectViewComponent
    ],
    providers: [
        AssignProjectService,
        AssignprojectListResolver,
        ProjectFeaturesResolver,
        ProjectEditResolver,
        ProjectAdminListResolver
    ]
})
export class AssignProjectModule { }
