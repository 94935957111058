//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService, PdfSetting } from './../../../_services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem, ConfirmationService, } from 'primeng/api';
import { Options } from './../../../_models/options';
import { BaseServices } from './../../kuba.services';
import { MeasureProtocolService } from './../services/measure-protocol.services';
import { MPClientUsers } from './../models/measure-protocol';
import { MP, CMP } from './../models/measure-protocol';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-complete-form',
    templateUrl: 'measure-protocol-complete-form.component.html'
})

export class MeasureProtocolCompleteFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    completemeasureProtocolForm: FormGroup;

    contracterId: number;
    kundeELId: number;
    isEditMode = false;
    createdOn: any;
    createdBy: any;
    MeasureProtocolId: any;
    private subscriptions: Subscription[] = [];

    options = [new Options(1, 'Open'), new Options(2, 'Done'), new Options(3, 'Rejected')];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private MeasureProtocolService: MeasureProtocolService,
        private confirmationService: ConfirmationService,
        private location: Location, ) {
        this.completemeasureProtocolForm = this.fb.group({
            'Number': ['', Validators.required],
            'Name': ['', Validators.required],
            'Status': [1],
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.contracterId = this.route.snapshot.params['id'];
        this.kundeELId = this.route.snapshot.parent.parent.params['id'];
        let res = this.route.snapshot.data['edit'];
        if (res) {
            this.isEditMode = true;
            this.initForm(res);
        } else {
            this.initForm();
        }

        this.subscriptions.push(this.MeasureProtocolService.getMeasureProtocolCompleteById(this.contracterId, this.kundeELId).subscribe((mpcInfo: any) => {
            if (mpcInfo) {
                this.createdOn = mpcInfo.CreatedOn;
                this.createdBy = mpcInfo.CreatedBy;
                this.MeasureProtocolId = mpcInfo.MeasureProtocolId;
            }
        }));
    }

    /**
     * save and create new button click event
     */
    /*     saveAndCreateNew() {
            this.saveChanges();
            this.clear();
        } */

    /**
     * clear form data
     */
    /*     clear() {
            this.initForm();
            this.contracterId = 0;
        } */

    /**Save form**/
    saveChanges() {
        let cmp = new CMP();
        cmp.Id = (this.contracterId) ? this.contracterId : 0;
        cmp.Number = this.completemeasureProtocolForm.value.Number;
        cmp.Name = this.completemeasureProtocolForm.value.Name;
        cmp.KundeELId = this.kundeELId;
        cmp.Status = this.completemeasureProtocolForm.value.Status;
        if (cmp.Status === 2) {
            cmp.DoneOn = new Date();
        }
        cmp.CreatedOn = this.createdOn
        cmp.CreatedBy = this.createdBy
        cmp.MeasureProtocolId = this.MeasureProtocolId;
        cmp.ModifiedOn = new Date();
        cmp.ModifiedBy = BaseServices.UserId;
        this.subscriptions.push(this.MeasureProtocolService
            .updateCMP(this.kundeELId, cmp)
            .subscribe(result => {
                this.toasterComponent.callToast();
                this.gotoBack();
            }));
    }

    initForm(data?: any) {
        let Number = '';
        let Name = '';
        let Status = '';

        if (data) {
            Name = data.Name;
            Number = data.Number;
            Status = data.Status;

            this.completemeasureProtocolForm = this.fb.group({
                Number: new FormControl(Number, Validators.required),
                Name: new FormControl(Name, Validators.required),
                Status: new FormControl(Status),
            });
        }
    }

    gotoBack() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

