import { EcplLarViewerComponent } from './ecpl-lar-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from './../../kuba/shared/appShared.module';
import {  SelectItem } from 'primeng/api';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppSharedModule,
    RouterModule,
    TranslateModule,
    // Ng4FilesModule,
    DropdownModule,
    DialogModule,
    TableModule,
    ConfirmDialogModule
  ],
  exports: [
    EcplLarViewerComponent
  ],
  declarations: [
    EcplLarViewerComponent
  ],
  providers: []
})
export class EcplLarViewerModule {
}

