import { ResetPasswordComponent } from './reset-password.component';
import { ForgetPasswordComponent } from './forget-password.component';
import { Route, RouterModule } from '@angular/router';


export const forgetPasswordRoutes: Route[] = [
    {
        path: 'forget-password',
        component: ForgetPasswordComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
];
