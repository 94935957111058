
import { DeviationAttachment } from './deviationAttachment'
export class DeviationShared {
    Id: number;
    Title: string;
    Description: string;
    BusinessId: number;
    BusinessDepartmentId: number;
    BusinessDepartmentIds: number[];
    ProjectId: number;
    FeatureKey: number;
    TopicId: number;
    TopicName: string;
    CaseId: number;
    Importance: number;
    Cost: string;
    Deadline: string;
    EventDate: string;
    EventTime: string;
    EventLocation: string;
    ExecutionUserId: number;
    FollowupUserId: number;
    Status: string;
    Comment: string;
    ImmediateAction: string;
    CauseAnalysis: string;
    CorrectiveAction: string;
    EffectOfAction: string;
    NotifyBeforeDeadLine: string;
    NotificationBeforeFollowup: string;
    IsShowtoUsers: string;
    IsDeviationViewRights: string;
    IsSendOnlyDepartment: string;
    CompletedDate: string;
    CompletedBy: string;
    ManualId: number;
    ManualChkCommentsId: number;
    DeviationNumber: number;
    DeviationNumberPrefix: string;
    DeviationAttachment: DeviationAttachment[];
    CreatedBy: number;
    CreatedOn: string
    ModifiedBy: number;
    ModifiedOn: string;
    TemperatureValue: string;
    ObjectName: string;
    DeviationUrl: string;
    ApplicationId: number;
    AppManualChecklistId: number;
    FeatureManualChecklistId: number;
    ActivityId: number;
    FeatureId: number;
    ModuleName: string;
    LanguageId: number;
    CultureInfo: string;
    TopicLabel: string;
    CaseLabel: string;
    DepartmentLabel: string;
    ImportanceLabel: string;
    ExecLabel: string;
    FollowLabel: string;
    FeatureCompleteChecklistId ?: number;
    SendonlytoEditors: boolean;
    Reference: string;
    NotifyMyDeviationsChange : boolean;
    BIds : number[];
}
