import { Component, OnInit, Input } from '@angular/core';
import { MeasureProtocolService } from 'src/app/kuba/measure-protocol/services/measure-protocol.services';
import { VisualControlData, Resistance, Continuity, Insulation, Voltage, Power, Effect, Reactive, Short } from 'src/app/kuba/measure-protocol/models/visual-control.model';
import { MPCompletePreview } from 'src/app/kuba/measure-protocol/models/measure-protocol';
import { Subscription } from 'rxjs';

@Component({
    selector: 'measure-protocol-preview',
    templateUrl: 'measure-protocol-preview.component.html'
})

export class MeasureProtocolPreviewComponent implements OnInit {
    @Input() mpCompleteId: number;
    previewMPData: any;
    visualControl: any;
    private subscriptions: Subscription[] = [];
    constructor(private measureProtocolService: MeasureProtocolService) {
        this.previewMPData = new MPCompletePreview();
        this.visualControl = {
            Resistance: new Resistance(),
            Continuity: new Continuity(),
            Insulation: new Insulation(),
            Voltage: new Voltage(),
            Power: new Power(),
            Effect: new Effect(),
            Reactive: new Reactive(),
            Short: new Short()
        };
    }

    ngOnInit() {
        this.subscriptions.push(this.measureProtocolService.getElectroCompleteMPPreview(this.mpCompleteId).subscribe(data => {
            if (data) {
                this.previewMPData = data;
                if (this.previewMPData.VisualControl ? this.previewMPData.VisualControl.Data : false) {
                    this.visualControl = JSON.parse(this.previewMPData.VisualControl.Data);
                }
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}