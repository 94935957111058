import { NgModule } from '@angular/core';
import { FlexiComponent } from './components/flexi.component';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation';
import { RouterModule } from '@angular/router';
import { OHSPlanComponent } from './components/ohs-plan.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MyHSEAdvisor } from './components/my-hse-advisor.component';
import {
  FlexiResolver,
  OHSDataResolver,
} from './services/flexi.resolver.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppSharedModule } from '../shared';
import { FollowUpsModule } from '../follow-ups/followups.module';
import {
  NewRiskAnalysisComponent,
  RiskAnalysisComponent,
} from '../follow-ups/components';
import {
  ApprovalNoticesResolver,
  FollowupDepartmentForAdminResolver,
  RiskAnalysisAdminResolver,
  RiskAnalysisCategoryResolver,
  RiskAnalysisEditResolver,
  RiskAnalysisListResolver,
  RiskAnalysisLogResolver,
  RiskAnalysisTopicCachedResolver,
  RiskAnalysisTopicForAdminResolver,
  UserForAdminResolver,
} from '../follow-ups/services/risk-analysis.resolver.service';
import { AllDepartmentResolver } from '../follow-ups/services/deviation.resolver.service';
import {
  CompanyNameResolver,
  EmployeeContactUserResolver,
  RiskListResolver,
} from '../businesses/services/business.resolver.service';
import { DepartmentCachedListResolver } from '../follow-ups/services/activities.resolver.service';
import { ArchiveTreeDataResolver } from '../archive/archive.resolver.service';
@NgModule({
  imports: [
    EcplTabNavigationModule,
    RouterModule,
    BrowserModule,
    CKEditorModule,
    TranslateModule,
    CommonModule,
    AppSharedModule,
    FollowUpsModule,
  ],
  declarations: [FlexiComponent, OHSPlanComponent, MyHSEAdvisor],
  providers: [
    FlexiResolver,
    OHSDataResolver,
    RiskAnalysisEditResolver,
    ApprovalNoticesResolver,
    RiskAnalysisListResolver,
    AllDepartmentResolver,
    RiskAnalysisAdminResolver,
    RiskAnalysisTopicCachedResolver,
    CompanyNameResolver,
    RiskListResolver,
    RiskAnalysisCategoryResolver,
    DepartmentCachedListResolver,
    EmployeeContactUserResolver,
    RiskAnalysisLogResolver,
    RiskAnalysisTopicForAdminResolver,
    FollowupDepartmentForAdminResolver,
    UserForAdminResolver,
    ArchiveTreeDataResolver,
  ],
  bootstrap: [],
})
export class FlexiModule {}
