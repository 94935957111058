<div class="main-content">
  <div class="main-heading">
    <span>{{ "OUR_SUPPLIER" | translate }}</span>
    <span *ngIf="!isUser" class="page-actions text-right">
      <button type="button" class="btn btn-success" [routerLink]="['../new']">
        <span class="icon ic-sm icon-add"></span>
        <span>{{ "NEW_SUPPLIER" | translate }}</span>
      </button>
    </span>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="table-view">
        <!-- Table starts -->
        <p-table
          #dt
          [value]="suppliers"
          (onRowSelect)="onRowSelect($event)"
          (onRowUnselect)="onRowUnSelect($event)"
          dataKey="Id"
          [(selection)]="selectedSupplier"
          [rows]="10"
          [paginator]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[5, 10, 20]"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
        >
          <!-- Caption -->
          <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
              <div class="table-options">
                <div class="pull-right">
                  <kuba-export
                    [reportData]="dt.filteredValue || dt.value"
                    [additionalData]="additionalData"
                  ></kuba-export>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th *ngIf="!isUser" style="width: 38px">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="Name">{{ "NAME" | translate }}</th>
              <th pSortableColumn="SupplierTypeName" *ngIf="!isUser">
                {{ "TYPE_SUPPLIER" | translate }}
              </th>
              <th pSortableColumn="MobileCode">
                {{ "CELLULAR_PHONE" | translate }}
              </th>
              <th pSortableColumn="Telephone">{{ "TELEPHONE" | translate }}</th>
              <th>{{ "EMAIL" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
            </tr>
            <tr>
              <th *ngIf="!isUser" style="width: 38px"></th>
              <th>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt.filter($any($event.target)?.value, 'Name', 'contains')
                  "
                  placeholder=""
                  class="p-column-filter"
                />
              </th>
              <th *ngIf="!isUser">
                <p-dropdown
                  width="118px"
                  [options]="supplierTypes"
                  #typesDropdown
                  (onChange)="ontypeChanged($event, typesDropdown)"
                  styleClass="p-column-filter"
                  placeholder=""
                  [showClear]="true"
                >
                  <ng-template let-type pTemplate="item">
                    {{ type.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <p-dropdown
                  [options]="statuses"
                  #statusDropdown
                  (onChange)="onStatusChanged($event, statusDropdown)"
                  styleClass="p-column-filter"
                  placeholder="Select"
                  [showClear]="true"
                >
                  <ng-template let-status pTemplate="item">
                    {{ status.label | translate }}
                  </ng-template>
                </p-dropdown>
              </th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-supplier>
            <tr>
              <td *ngIf="!isUser" style="width: 38px">
                <p-tableCheckbox id="selectedOrders" [value]="supplier">
                </p-tableCheckbox>
              </td>
              <td>
                <a href="#" [routerLink]="['../edit/', supplier['Id']]">{{
                  supplier.Name
                }}</a>
              </td>
              <td *ngIf="!isUser">
                <span>{{ supplier.SupplierTypeName }}</span>
              </td>
              <td>
                <span>{{ supplier.MobileCode }}</span>
              </td>
              <td>
                <span>{{ supplier.Telephone }}</span>
              </td>
              <td>
                <span>{{ supplier.Email }}</span>
              </td>
              <td>
                <a
                  (click)="editStatus(supplier['Id'], supplier['Status'])"
                  href="javascript:void(0);"
                  routerLinkActive="active"
                >
                  {{ supplier.StatusText.toUpperCase() | translate }}
                </a>
              </td>
            </tr>
          </ng-template>

          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="6">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" *ngIf="!isUser" let-columns>
            <tr>
              <td colspan="7">
                {{ "NO_RECORDS_FOUND" | translate }}
              </td>
            </tr>
          </ng-template>
          <!-- Footer -->
          <ng-template pTemplate="summary">
            <div class="ui-helper-clearfix">
              <div class="table-options">
                <div *ngIf="!isUser" class="pull-left">
                  <span pTooltip="Select atleast one record to delete supplier">
                    <button
                      class="btn btn-danger"
                      (click)="deleteSupplier(selectedSupplier)"
                      [disabled]="!isDeleteSupplier"
                    >
                      <i class="icon ic-sm icon-trash"></i>
                      <span translate>DELETE_CHECKED</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
        </p-table>
        <!-- Table Ends -->
      </div>
    </div>
  </div>
  <p-confirmDialog
    header="{{ 'CONFIRMATION' | translate }}"
    icon="pi pi-question-circle"
    width="425"
    acceptLabel="{{ 'SWITCH_YES' | translate }}"
    rejectLabel="{{ 'SWITCH_NO' | translate }}"
  ></p-confirmDialog>
</div>
<toaster-component></toaster-component>
