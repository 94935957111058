import { SubContractorServices } from './../kuba/contractor/services/contractor.service';
import { ClientServices } from './../kuba/client/services/client.services'; 
import { BaseServices } from './../kuba/kuba.services';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { FeatureKey } from '../_models/feature';
import { AuthenticationService } from '../_services/authentication.service';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
        private tokenService: AuthenticationService
        ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (
            sessionStorage.getItem('appSettings') &&
            sessionStorage.getItem('session')
        ) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url }
        });
        return false;
    }
}
@Injectable()
export class MyPageGuard implements CanActivate {
    
    private subscriptions: Subscription[] = [];
    constructor(
        private router: Router,
         private subContractorServices: SubContractorServices,
         private clientService: ClientServices,
        ) { }

    canActivate(
        routeSnap: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {
        let featureId = BaseServices.FeatureId;
        let parentUrl = state.url
            .slice(0, state.url.indexOf(routeSnap.url[routeSnap.url.length - 1].path));
        if (featureId === FeatureKey.SUB_CONTRACTOR) {
            this.router.navigate([parentUrl + '/contractor/create/' + BaseServices.FeatureKey], { queryParams: { myPage: true } });
        } else if (featureId === FeatureKey.CONTRACTOR_CONTACT) {
            
            this.subscriptions.push(this.subContractorServices
                .getContractorContact(+BaseServices.FeatureKey)
                .subscribe((x:any) => {
                    if (x) {
                        this.router.navigate([parentUrl + '/contractor/create/' + x.ContractorId], { queryParams: { myPage: true } });
                    }
                }));
        } else if (featureId === FeatureKey.CONTACTS) {
            this.router.navigate([parentUrl + 'contact/edit/' + BaseServices.FeatureKey], { queryParams: { myPage: true } });
        } else if (featureId === FeatureKey.CLIENT) {
            this.router.navigate([parentUrl + 'client/edit/' + BaseServices.FeatureKey], { queryParams: { myPage: true } });
        } else if (featureId === FeatureKey.CLIENT_CONTACT) {
            
            this.subscriptions.push(this.clientService
                .getClientContactById(+BaseServices.FeatureKey)
                .subscribe((x:any) => {
                    if (x) {
                        this.router.navigate([parentUrl + 'client/edit/' + x.ClientId], { queryParams: { myPage: true } });
                    }
                }));
        } else if (featureId === FeatureKey.EMPLOYEES) {
            this.router.navigate([parentUrl + '/employee/edit/' + BaseServices.FeatureKey]);
        }
        return false;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}

@Injectable()
export class AdminTabGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (
            sessionStorage.getItem('appSettings') &&
            sessionStorage.getItem('session') &&
            BaseServices.roleId === '1'
        ) {
            return true;
        }
        return false;
    }
}

@Injectable()
export class AdminPortalTabGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (
            sessionStorage.getItem('appSettings') &&
            sessionStorage.getItem('session') &&
            (BaseServices.roleId === '1' || BaseServices.roleId === '2')
        ) {
            return true;
        }
        return false;
    }
}