import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    templateUrl: 'device-list.component.html'
})
export class FoodSafetyDeviceListComponent implements OnInit {

    constructor( private fb: FormBuilder) {

    }

    ngOnInit(): void {

    }

}
