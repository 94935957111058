import { FeaturesBaseServices } from './../features/services/features-base.services';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseServices } from './../kuba.services';
import { HelperService } from './../../_services/helper.service';
import { UserService } from './../users/services/user.service';
import { EmployeeServices } from './../employees/services/employee.services';
import { Rights } from './../../_models/feature';

@Component({
    templateUrl: 'my-page.component.html'
})
export class MyPageComponent implements OnInit {

    //#region variable

    employeeId: any;
    user: any;

    //#endregion

    //#region constructor
    /**
     * constructor
     * @param route {any}
     * @param activatedRoute {any}
     */
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private featuresBaseServices: FeaturesBaseServices) {
        this.featuresBaseServices.setEmployeeEditModeNavigation(true);
        this.user = this.activatedRoute.snapshot.data['user'];
    }

    //#endregion

    //#region page-event
    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (this.user) {
            this.employeeId = this.user.FeatureKey;
            this.route.navigate(
                [`./employee/edit/${this.employeeId}`],
                { relativeTo: this.activatedRoute }
            );
        }
    }
    //#endregion
}
