import { Route } from "@angular/router";
import { FlexiComponent } from "./components/flexi.component";
import { AuthGuard } from "src/app/_guards";
import { FeatureDocumentComponent } from "../feature-documents/feature-document.component";
import { DocumentListResolver } from "../feature-documents/feature-document.service.resolver";
import { OHSPlanComponent } from "./components/ohs-plan.component";
import { MyHSEAdvisor } from "./components/my-hse-advisor.component";
import { FlexiResolver, OHSDataResolver } from "./services/flexi.resolver.service";
import {
    RiskAnalysisTopicCachedResolver,
    RiskAnalysisDepartmentResolver,
    RiskAnalysisEditResolver,
    RiskAnalysisListResolver,
    BusinessesListResolver,
    ApprovalNoticesResolver,
    RiskAnalysisLogResolver,
    RiskAnalysisAdminResolver,
    RiskAttachmentResolver,
    RiskAnalysisTopicForAdminResolver,
    FollowupDepartmentForAdminResolver,
    UserForAdminResolver,
    RiskAnalysisCategoryResolver
} from '../follow-ups/services/risk-analysis.resolver.service';
import { RiskAnalysisListComponent } from "../follow-ups/components/riskanalysis-list.component";
import { RiskAnalysisComponent } from "../follow-ups/components/riskanalysis.component";
import { AllDepartmentResolver, ResponsibleForExecutionResolver } from "../follow-ups/services/deviation.resolver.service";
import { BusinessCacheResolver, CompanyNameResolver, EmployeeContactUserResolver, RiskListResolver } from "../businesses/services/business.resolver.service";
import { NewRiskAnalysisComponent } from "../follow-ups/components/riskanalysis-new.component";
import { DepartmentCachedListResolver } from "../follow-ups/services/activities.resolver.service";
import { NewRiskPortalComponent } from "../follow-ups/components/risk-new-portal.component";
import { ArchiveTreeDataResolver } from "../archive/archive.resolver.service";

export const flexiRoutes: Route[] = [{
    path: 'Flexi',
    component: FlexiComponent,
    canActivate: [AuthGuard],
    children: [{
        path: 'document/list',
        component: FeatureDocumentComponent,
        resolve: {
            list: DocumentListResolver
        }
    },
    {
        path: 'OHS-plan',
        component: OHSPlanComponent,
        canActivate: [AuthGuard],
        resolve: {
            OhsData: OHSDataResolver
        }
    },
    {
        path: 'HSE-advisor',
        component: MyHSEAdvisor,
        canActivate: [AuthGuard],
        resolve: {
            list: FlexiResolver
        }
    },
    {
        path: 'risk-analysis',
        component: RiskAnalysisComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: RiskAnalysisListComponent,
                resolve: {
                    detail: RiskAnalysisEditResolver,
                    approvalNotices: ApprovalNoticesResolver,
                    list: RiskAnalysisListResolver,
                    departments: AllDepartmentResolver,
                    adminlist: RiskAnalysisAdminResolver,
                    topic: RiskAnalysisTopicCachedResolver,
                    business: CompanyNameResolver,
                    Risklist: RiskListResolver,
                    category: RiskAnalysisCategoryResolver

                }
            },
            // {

            //     path: 'newrisk',
            //     component: NewRiskAnalysisComponent,
            //     resolve: {
            //         department: DepartmentCachedListResolver,
            //         topic: RiskAnalysisTopicCachedResolver,
            //         user: EmployeeContactUserResolver,
            //         category: RiskAnalysisCategoryResolver,
            //         business: CompanyNameResolver
            //     }
            // },
            // {
            //     path: 'edit-risk/:rid',
            //     component: NewRiskAnalysisComponent,
            //     resolve: {
            //         detail: RiskAnalysisEditResolver,
            //         department: DepartmentCachedListResolver,
            //         topic: RiskAnalysisTopicCachedResolver,
            //         user: EmployeeContactUserResolver,
            //         log: RiskAnalysisLogResolver,
            //         adminTopic: RiskAnalysisTopicForAdminResolver,
            //         adminDepartment: FollowupDepartmentForAdminResolver,
            //         usersAdmin: UserForAdminResolver,
            //         category: RiskAnalysisCategoryResolver,
            //         business: CompanyNameResolver
            //     }
            // },
            {
                path: 'riskportal',
                component: NewRiskPortalComponent,
                resolve: {
                    business: BusinessCacheResolver,
                }
            }
        ]
    },
    {
        path: 'risk-analysis',
        component: RiskAnalysisComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: RiskAnalysisListComponent,
                resolve: {
                    detail: RiskAnalysisEditResolver,
                    list: RiskAnalysisListResolver,
                    departments: AllDepartmentResolver,
                    approvalNotices: ApprovalNoticesResolver,
                    business: CompanyNameResolver,
                    Risklist: RiskListResolver,
                    category: RiskAnalysisCategoryResolver,

                }
            },
            {
                path: 'newrisk',
                component: NewRiskAnalysisComponent,
                resolve: {
                    department: DepartmentCachedListResolver,
                    topic: RiskAnalysisTopicCachedResolver,
                    user: ResponsibleForExecutionResolver,
                    category: RiskAnalysisCategoryResolver,
                    business: CompanyNameResolver
                }
            },
            {
                path: 'edit-risk/:rid',
                component: NewRiskAnalysisComponent,
                resolve: {
                    detail: RiskAnalysisEditResolver,
                    department: DepartmentCachedListResolver,
                    topic: RiskAnalysisTopicCachedResolver,
                    user: ResponsibleForExecutionResolver,
                    treeData: ArchiveTreeDataResolver,
                    business: CompanyNameResolver,
                    category: RiskAnalysisCategoryResolver,
                }
            },
        ]
    }
    ]
}]