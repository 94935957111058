<div class="panel-tabs">
    <nav>
        <ul class="nav nav-pills">
            <ng-template ngFor let-linkItem [ngForOf]="linkItems">
                <li [routerLinkActive]="['active']" *ngIf="checkUserRights(linkItem.id)">
                    <a [routerLink]="getRoute(linkItem)">
                        {{linkItem.translationKey | translate}}
                    </a>
                </li>
            </ng-template>
        </ul>
    </nav>
</div>