import { Route, Resolve } from '@angular/router';
import { AutoWizLoginComponent } from 'src/app/kuba/autowiz-login/components/autowiz-login.component';


export const AutoWizLoginRoutes: Route[] = [
    {
        path: 'autowiz-login',
        component: AutoWizLoginComponent,
    }
];
