<div class="page-title">
    <span translate>VEHICLE_CHECKLIST</span>
</div>
<div class="list">
    <table class="table table-striped list">
        <thead>
            <tr>
                <th translate>FILE_NAME</th>
                <th translate>CREATED_BY</th>
                <th translate>CREATED_ON</th>
                <th translate>EDITED_BY</th>
                <th translate>EDITED_ON</th>
            </tr>
        </thead>
        <tbody class="uploads">
            <tr class="file-item" *ngFor="let f of uploadedFiles | paginate: pgConfig ; index as i ;">
                <td>
                    <span class="thumb-sm">
                        <ecpl-checklist-viewer *ngIf="f.type == 'file' && f.documentType == 'checklist'" [checklistName]="f.filename" [(checklistSrc)]="uploadedFiles[i]"
                            [id]="f.nodeId" (saveChanges)="saveEmitter($event)" [editMode]="isEditMode"></ecpl-checklist-viewer>
                    </span>
                    {{ f.filename}}
                </td>

                <td>{{f.createdBy}}</td>
                <td>{{f.createdOn | date:'dd-MM-yyyy'}}</td>
                <td>{{f.modifiedBy}}</td>
                <td>{{f.modifiedOn | date:'dd-MM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>
    <pagination-controls *ngIf="(uploadedFiles)?.length > 0 && selectedPageSize !=0" (pageChange)="pgConfig.currentPage = $event"
        previousLabel="Previous" nextLabel="Next">
    </pagination-controls>
    <div class="action-btns-wrapper" style="text-align: center">
        <p-dropdown *ngIf="(uploadedFiles)?.length > 0" [options]="pageSizeList" [(ngModel)]="selectedPageSize" (onChange)="onPageChanged($event)"
            [style]="{'width':'50px','float':'right'}"></p-dropdown>
    </div>
</div>
<router-outlet></router-outlet>