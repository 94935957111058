export class AutoWizUser {
    Id: number;
    UserName: string;
    UserId: string;
    Password: string;
    CreatedBy: number;
    CreatedOn: Date;
    Status: string;
    ModifiedBy: number;
    ModifiedOn: Date;
    BusinessId: number;
}