import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'employee-list',
  templateUrl: 'employee-list.component.html',
})
export class EmployeeListComponent implements OnInit {
  constructor(private route: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    document.querySelector('body').classList.remove('opened');
    this.route.navigate(['customer-employee-login'], {
      relativeTo: this.activatedRoute,
    });
  }

  saveChange() {}
}
