import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppSharedModule } from './../shared/appShared.module';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { VehicleConsumptionListComponent } from './components/vehicle-consumption-list.component';
import { VehicleConsumptionFormComponent } from './components/vehicle-consumption-form.component';
import {
  VehicleConsumptionListResolver,
  VehicleListResolver,
  VehiclePreviousNoResolver,
  VehicleConsumptionEditResolver
} from './services/vehicle-consumption-resolver.service';
import { VehicleConsumptionService } from './services/vehicle-consumption.services';
import { VehicleConsumptionComponent } from './vehicle-consumption.component';
import { HttpClientModule } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    AppSharedModule,
    MyDatePickerModule,
    KubaExportModule
  ],
  exports: [
    VehicleConsumptionListComponent,
    VehicleConsumptionFormComponent
  ],
  declarations: [
    VehicleConsumptionListComponent,
    VehicleConsumptionFormComponent
  ],
  providers: [
    VehicleConsumptionService,
    VehicleConsumptionListResolver,
    VehicleListResolver,
    VehiclePreviousNoResolver,
    VehicleConsumptionEditResolver

  ]
})
export class VehicleConsumptionModule { }
