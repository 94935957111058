import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { crewroutes } from '../crew-list/crew-list.routing';
import { deviationRoutes } from '../deviation/deviation.routing';
import { FeatureDocumentRoutes } from '../feature-documents/feature-document.routing';
import { foodsafetycleaningRoutes } from '../foodsafety-cleaning/foodsafety-cleaning.routing';
import { ManualControlRoutes } from '../manual-control/manual-control.routing';
import { productionRoutes } from '../production/production.routing';
import { ReceptionControlRoutes } from '../reception-control/reception-control.routing';
import { registrationRoutes } from '../Registration/registration.routing';
import { safetyjobRoutes } from '../safety-job-analysis/jobanalysis.routing';
import { kubaFeatureDetailsRoutes } from '../shared/kuba-feature-details/Kuba-feature-details.routes';
import { TaskManagerComponent } from '../task-manager/task-manager.component';
import { taskmanagerRoutes } from '../task-manager/task-manager.routing';
import { timetableRoutes } from '../time-table/timetable.routing';
import { CreateKubaControlComponent } from './components/create-kuba-control/create-kuba-control.component';
import { DailyTaskComponent } from './components/daily-task/daily-task.component';
import { KubaControlListComponent } from './components/kuba-control-list/kuba-control-list.component';
import { KubaControlViewComponent } from './components/kuba-control-view/kuba-control-view.component';
import { KubaControlComponent } from './kuba-control.component';
import { FoodSafetyEditResolver, FoodsafetyFeaturesResolver, FoodSafetyListByUserResolver,
    FoodSafetyListResolver, FoodSafetyZonesResolver, FSAuditDateListResolver, FSAuditListResolver, 
    FSDailyTaskListResolver, KubaControlListResolver, ObjectTypeListResolver, ZoneListResolver,
    ToDoListResolver,ToDoDocumentResolver } from './services/kubacontrol.resolver';
import { AuthGuard } from '../../_guards/index';
import { KCTaskManagerListComponent } from './components/task-manager/kctask-manager-list.component';
import { KCTaskManagerFormComponent } from './components/task-manager/kc-task-manager-form.component';
import { KCAuditComponentComponent } from './components/audit/kc-audit-component.component';
import { DepartmentCachedListResolver } from '../follow-ups/services/activities.resolver.service';
import { 
    EmployeeContactUserResolver, 
    EmployeeContactUserSubscriptionResolver 
} from '../users/services/user.resolver.service';
import { KubaControlHeaderComponent } from './components/kuba-control-list/kuba-control-header.component';
import { ExecutionPersonList, FollowUpPersonList, RepeatitionList, TaskManagerEditResolver, TaskManagerListResolver } from '../task-manager/services/task.resolver.service';
import { AllDepartmentResolver } from '../follow-ups/services/deviation.resolver.service';
import { SjaFormRoutingModule } from '../sja-form/sja-form-routing.module';
import { ToDoListComponent } from './components/to-do-list/to-do-list.component';

export const kubaControlRoutes: Routes = [{
    path: 'kubacontrol',
    component: KubaControlComponent,
    children: [
        {
            path: 'list',
            component: KubaControlHeaderComponent,
            children: [
                { path: '', redirectTo: 'all', pathMatch: 'full' },
                {
                    path: '',
                    component: KubaControlListComponent,
                    resolve: {
                        list: FoodSafetyListByUserResolver
                    }
                },
                {
                    path: 'own',
                    component: KubaControlListComponent,
                    resolve: {
                        list: FoodSafetyListByUserResolver
                    }
                },
                {
                    path: 'all',
                    component: KubaControlListComponent,
                    resolve: {
                        list: FoodSafetyListResolver
                    }
                }
            ]
        },
        {
            path: 'edit/:id',
            component: CreateKubaControlComponent,
            resolve: {
                edit: FoodSafetyEditResolver,
                zonedetails: ZoneListResolver,
                deptdetails: DepartmentCachedListResolver,
                users_subscription: EmployeeContactUserSubscriptionResolver,
                contact: EmployeeContactUserResolver,
                zones: FoodSafetyZonesResolver,
                objects: ObjectTypeListResolver
            }
        },
        {
            path: 'details/:id',
            component: KubaControlViewComponent,
            resolve: {
                additionalFeatures: FoodsafetyFeaturesResolver,
                list: FoodSafetyListResolver
            },
            data: { parent: 'KUBA_CONTROL' },
            canActivate: [AuthGuard],
            children: [
                {
                    path: 'dailytask',
                    component: DailyTaskComponent,
                    resolve: {
                        list: FSDailyTaskListResolver
                    }
                },
                {
                    path: 'audit',
                    component: KCAuditComponentComponent,
                    resolve: {
                        list: FSAuditListResolver,
                        kclist: KubaControlListResolver,
                        dateList: FSAuditDateListResolver
                    }
                },
                {
                    path: 'todolist',
                    component: ToDoListComponent,
                    canActivate: [AuthGuard],
                    resolve:{
                        toDoApplist: ToDoListResolver,
                        ToDoDocument: ToDoDocumentResolver,
                    }
                  },    
                {
                    path: 'taskmanager',
                    component: TaskManagerComponent,
                    canActivate: [AuthGuard],
                    children: [
                        {
                            path: 'list',
                            component: KCTaskManagerListComponent,
                            resolve: {
                                list: TaskManagerListResolver,
                            }
                        },
                        {
                            path: 'edit/:tid',
                            component: KCTaskManagerFormComponent,
                            resolve: {
                                user: EmployeeContactUserResolver,
                                edit: TaskManagerEditResolver,
                                repetition: RepeatitionList,
                                exePerson: ExecutionPersonList,
                                followup: FollowUpPersonList,
                                departments: AllDepartmentResolver
                            }
                        }
                    ]
                },
                ...kubaFeatureDetailsRoutes,
                ...FeatureDocumentRoutes,
                ...deviationRoutes,
                ...taskmanagerRoutes,
                ...timetableRoutes,
                ...safetyjobRoutes,
                ...registrationRoutes,
                ...productionRoutes,
                ...ManualControlRoutes,
                ...ReceptionControlRoutes,
                ...crewroutes,
                ...foodsafetycleaningRoutes,
                ...SjaFormRoutingModule
            ]
        }
    ]
}];
