<div class="tab-content">
    <div class="page-title">
        <strong translate>MEASURE_UNDER_COMPLETION</strong>
        <span class="page-actions">
            <button class="btn btn-primary" (click)="generateReport()">
                <span translate>GEN_REPORT</span>
            </button>
        </span>
    </div>
    <div class="panel-tabs">
        <mpc-tab-navigation></mpc-tab-navigation>
    </div>
    <router-outlet></router-outlet>
</div>