//#region import
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService, PdfSetting } from './../../../../_services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Options } from './../../../../_models/options';
import { BaseServices } from './../../../kuba.services';
import { MeasureProtocolService } from '../../services/measure-protocol.services';
import { PowerLeakage } from '../../models/mpc-details';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';
//#endregion

@Component({
    selector: 'measure-protocol-power-leakage',
    templateUrl: 'measure-protocol-power-leakage.component.html'
})

export class MeasureProtocolPowerLeakageComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    measureProtocolPowerLeakage: FormGroup;
    powerLeakage: PowerLeakage[];
    cmpId: number;
    powerLeak: any;
    powerLeakId: any;
    createdOn: any;
    createdBy: any;
    featureId: number;
    isElectro: boolean;
    saveprogress: boolean = false;
    ShowConfirm = false;
    deletePower = 0;
    private subscriptions: Subscription[] = [];
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private location: Location,
        private confirmationService: ConfirmationService,
        public measureprotocolservice: MeasureProtocolService,
        private translate: TranslateService
    ) {
        this.measureProtocolPowerLeakage = this.fb.group({
            'PowerReading': ['', Validators.required],
            'Comments': ['']
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.powerLeakage = [];
        this.powerLeakage = this.route.snapshot.data['powerLeakage'];
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        this.featureId = workingModule ? workingModule.id : 0;
        if (this.featureId == FeatureKey.ELECTRO) {
            this.isElectro = true;
            if(+this.route.snapshot.params['cClId']){
                this.cmpId = +this.route.snapshot.params['cClId'];
            }
            if (this.cmpId) {
                this.subscriptions.push(this.measureprotocolservice.getAllPowerLeakage(this.cmpId).subscribe((earthData: any) => {
                    if (earthData) {
                        this.powerLeakage = earthData;
                    }
                }))
            }
        }else{
            this.cmpId = this.route.snapshot.parent.params['mpcid'];
        }
    }

    /**
     * get all power leakage list
    */
    getAllPowerLeakage() {
        this.subscriptions.push(this.measureprotocolservice
            .getAllPowerLeakage(this.cmpId)
            .subscribe((result: any) => this.powerLeakage = result));
    }

    /**
     * clearing the data in the form
     */
    onClear() {
        this.saveprogress = false;
        this.powerLeakId = 0;
        this.measureProtocolPowerLeakage = this.fb.group({
            PowerReading: ['', Validators.required],
            Comments: ['']
        });
    }
    
    saveChanges() {
        this.saveprogress = true;
        let powerLeakage = new PowerLeakage();
        powerLeakage.Id = (this.powerLeakId) ? this.powerLeakId : 0;
        powerLeakage.PowerReading = this.measureProtocolPowerLeakage.value.PowerReading;
        powerLeakage.Comments = this.measureProtocolPowerLeakage.value.Comments;
        powerLeakage.MeasureProtocolCompleteId = this.cmpId;
        powerLeakage.Status = 1;

        if (powerLeakage.Id > 0) {
            powerLeakage.CreatedBy = this.createdBy;
            powerLeakage.CreatedOn = this.createdOn;
            powerLeakage.ModifiedBy = BaseServices.UserId;
            powerLeakage.ModifiedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .updatePowerLeakage(this.cmpId, powerLeakage)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.getAllPowerLeakage();
                    this.onClear();
                }));
        } else {
            powerLeakage.CreatedBy = BaseServices.UserId;
            powerLeakage.CreatedOn = new Date();
            this.subscriptions.push(this.measureprotocolservice
                .createPowerLeakage(this.cmpId, powerLeakage)
                .subscribe((result) => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                    this.getAllPowerLeakage();
                    this.onClear();
                }));
        }
    }

    /**
    * editing power leakage
     * @param Id {number}
     */

    editPowerLeakage(id: number) {
        this.subscriptions.push(this.measureprotocolservice.getPowerLeakageById(this.cmpId, id).subscribe((powerLeak: any) => {
            if (powerLeak) {
                this.powerLeakId = id;
                this.createdOn = powerLeak.CreatedOn;
                this.createdBy = powerLeak.CreatedBy;
                this.powerLeak = {
                    PowerReading: powerLeak.PowerReading,
                    Comments: powerLeak.Comments,
                };
                (<FormGroup>this.measureProtocolPowerLeakage).setValue(this.powerLeak, { onlySelf: true });
            }
        }));
    }


    /**
     * delete power leakage
     * @param pl
     */

    deletePowerLeakage(pl) {
        this.ShowConfirm = true;
        this.deletePower = pl;
    }

    Confirmation(accept){
        if(accept){
            this.ShowConfirm = false;
            this.subscriptions.push(this.measureprotocolservice.deletePowerLeakage(this.deletePower, this.cmpId).subscribe(result => {
                this.getAllPowerLeakage();
                this.toasterComponent.callToastDlt();
            }));
        }else{
            this.ShowConfirm = false;
            this.deletePower = 0;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}

