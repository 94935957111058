import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
/* Commented for KUBA 3.0 initial version check */
// import { CalendarComponent } from 'ng-fullcalendar';
// import { Options } from 'fullcalendar_ng';
import { TranslateService } from '@ngx-translate/core';

import { BaseServices } from './../../../kuba.services';
import { ActivityCalendar } from './../../../dashboard/models/dashboard';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
@Component({
    templateUrl: 'mypage-calender.component.html'
})
export class MyPageCalenderComponent implements OnInit {
    //#region variable

    // calendarOptions: Options;
    calendarOptions: CalendarOptions;
    //#endregion

    //#region constructor

    constructor(private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService) { }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.bindCalendarEvents();
    }

    //#endregion

    //#region control-event

    /**
     * calendar click event
     * @param model {any}
     */
    eventClick(model: any) {
        if (model.event.type === 'ACTIVITY') {
            this.router.navigate(
                [`./../../follow-up/activities/edit/${model.event.id}`],
                { relativeTo: this.route }
            );
        }
    }

    //#endregion

    //#region methods
    /**
     * bind mypage calendar activity
     */
    bindCalendarEvents() {
        let user = <ActivityCalendar[]>this.route.snapshot.data['mypageCalendarActivity'];
        if (user != null) {
            user = user.filter(x => x.executionUser === BaseServices.UserId && x.type === 'ACTIVITY');
        }
        /* Commented for KUBA 3.0 initial version check */
        /*  this.calendarOptions = {
             // editable: true,
             locale:this.translateService.currentLang,
             buttonText: {
                 today: this.translateService.instant('TODAY')
             },
             eventLimit: 2,
             firstDay: 1,
             header: {
                 left: 'title',
                 center: '',
                 right: 'today prev,next'
             },
             titleFormat: 'MMM-YYYY',
             events: user
         }; */
        // Convert the user data to FullCalendar event format
        const events: any[] = user.map(item => ({
            title: item.title,
            start: new Date(item.start), // Make sure this is a valid date string or Date object
            end: new Date(item.end) // Make sure this is a valid date string or Date object
        }));
        this.calendarOptions = {
            initialView: 'dayGridMonth',
            plugins: [dayGridPlugin],
            locale: this.translateService.currentLang,
            buttonText: {
                today: this.translateService.instant('TODAY')
            },
            dayMaxEvents: 1,
            firstDay: 1,
            headerToolbar: {
                left: 'title',
                center: '',
                right: 'today prev,next'
            },
            titleFormat: { month: 'short', year: 'numeric' },
            events: events
            // moreLinkText: this.translateService.instant('MORE')
        };

    }

    //#endregion
}
