
import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { ArchiveComponent } from './archive.component';
import { ArchiveTreeDataResolver } from './archive.resolver.service';

export const archiveRoutes: Route[] = [
    {
        path: 'archive/list',
        component: ArchiveComponent,
        canActivate: [AuthGuard],
        resolve: {
            treeData: ArchiveTreeDataResolver
        }
    }
]
