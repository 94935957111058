export class ContactView {
    Id: number;
    RoleName: string;    
    BusinessId: number;
    ContactTypeId: number;
    ContactWorkLocationId: number;
    Status: number;
    ContactFunctionalAreaId: number;
    ContactFunctionalArea: string;
    Name: string;
    Address: string;
    Zipcode: string;
    Location: string;
    Mobile: string;
    Telephone: string;
    Fax: string;
    Emailid: string;
    WorkPosition: string;
    Notes: string;
    IsSMS: string;
    IsEmail: string;
    ShowInBusiness: string;
    ShowInContact: string;
    ShowInApplication: string;
    ShowInPortal: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: string;
    ModifiedOn: string;
    BusinessName: string;
    CountryCode: string;
    ShowOnDashBoard: boolean;
    ShowToGuest: boolean;
    IsEmailNewDeviation: boolean;
    SuggestionBoxNotification: boolean;
    WorkLocationCompany: string;
    WorkLocationName: string;
    WorkLocationWebSite: string;
    WorkLocationAddress: string;
    WorkLocationZip: string;
    UserId: number;
    RoleId: number;
}

export class ContactWorkLocation {
    Id: number;
    ContactId: number;
    Company: string;
    Address: string;
    Zipcode: string;
    Location: string;
    Website: string;
}
export class ContactType {
    Id: number;
    Name: string;
}


