export class News {
    Id: number;
    ApplicationId: number;
    PortalId: number;
    BusinessId: number;
    Title: string;
    CoverImg: string;
    ContentData: string;
    Status: string;
    PublishedOn: string;
    ExpiredOn: string;
    ShowInBusiness: boolean;
    ShowInApplication: string;
    ShowInPortal: string;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
}