export class Production {
    Id: number;
    ProductDeliveryId: number;
    LotNumber: number;
    ProductionTypeId: number;
    ReceptionNumber: number;
    Quantity: number;
    Measurement: number;
    QuantityRest: number;
    Status: string;
    CreatedBy: string;
    CreatedOn: string;
}
export class ProductionEditDto {
    Id: number;
    ProductDeliveryId: number;
    LotNumber: number;
    ProductionTypeId: number;
    ReceptionNumber: number;
    Measurement: number;
    Quantity: number;
    QuantityRest: number;
    Status: number;
    CreatedBy: string;
    CreatedOn: string;
    ProductionDeliveryDto: ProductionDelivery;
}
export class ProductionDelivery {
    Id: number
    ClientId: number;
    DeliveryOn: string;
    BusinessId: number;
    CreatedBy: string;
    CreatedOn: string;
}
export class FSProduction {
    Id: number;
    ProductDeliveryId: number;
    ProductionTypeId: number;
    ClientId: number;
    BusinessId: number;
    DeliveryOn: string;
    FeatureKeyId: number;
    Status: string;
    CreatedBy: number;
    ModifiedBy: number;
    ProductionArray: ProductionArrayDto;
    Quantity: number;
}
export class ProductionArrayDto {
    ReceptionNo: string;
    Measurement: string;
    Quantities: any;
}




