import { NgModule } from '@angular/core';
import { AppSharedModule} from './../shared/appShared.module';
import {DialogModule} from 'primeng/dialog';
import { SuggestionComponent } from './suggestions.component';
import { BoxComponent } from './components/box.component';
import { SuggestionsServices } from './services/suggestion.service';

@NgModule({
    imports: [AppSharedModule,
              DialogModule],
    exports:
    [
        BoxComponent,
        SuggestionComponent
    ],
    declarations:
    [
        BoxComponent,
        SuggestionComponent

    ],
    providers: [SuggestionsServices],
})
export class SuggestionModule { }
