<div class="tab-content">
  <div class="page-title">
    <span translate>OBJECT</span>
  </div>
  <div class="sub-panel-title">
    <h4 translate>TEMPERATURE_CONTROL</h4>
  </div>
  <form class="form" [formGroup]="manualControlForm">
    <div class="form-group">
      <label for="ObjectName" class="col-form-label">
        <span translate>OBJECT_NAME</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" pInputText formControlName="objectName" />
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="MinTemp" class="col-form-label">
              <span translate>REC_MIN_TEMPERATURE</span>
              <span class="required">*</span>
            </label>
            <div>
            </div>
            <p-dropdown [options]="TemperatureMin" formControlName="minTemp" [style]="{'width':'150px'}" (onClick)="changeminTemp()">
            </p-dropdown>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="MaxTemp" class="col-form-label">
              <span translate>REC_MAX_TEMPERATURE</span>
              <span class="required">*</span>
            </label>
            <div>
              <p-dropdown [options]="TemperatureMax" formControlName="maxTemp" [style]="{'width':'150px'}" (onClick)="changemaxTemp()">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="Description" class="col-form-label">
        <span translate>DESCRIPTION</span>
      </label>
      <div>
        <textarea pInputTextarea formControlName="Description"></textarea>
      </div>
    </div>
    <div class="form-group">
      <div class="tab-content">
        <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
          (saveChanges)="saveUploaded($event)">
        </ecpl-document-explorer>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <button type="button" (click)="gotoBack()" class="btn btn-outline-secondary">
        <span class="icon ic-xs icon-back"></span>
        <span translate>BACK_LIST</span>
      </button>
      <button class="btn btn-primary" type="submit" [disabled]="!manualControlForm.valid || loading"
        (click)="Save(); this.gotoBack(); " [ngClass]="{'loading' : loading}">
        <span *ngIf="!loading" translate>SAVE</span>
        <span *ngIf="loading" translate>SAVE_PROCESS</span>
        <span *ngIf="loading" class="loader-icon"></span>
      </button>
      <button class="btn btn-primary" type="submit" [disabled]="!manualControlForm.valid"
        (click)="Save(); this.clearform();">
        <span translate>SAVE_CREATE_NEW</span>
      </button>
    </div>
  </form>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>
<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>
