export class ProjectList {
  Id: number;
  Number: string;
  Title: string;
  StartDate: String;
  ExpectedEndDate: String;
  Manager: string;
  Status: string;
  CreatorName: string;
  ModifierName: string;
  ProjectImage: string;
}
