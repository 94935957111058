import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';
import {
    FormGroup,
    FormControl,
    FormArray,
    FormBuilder,
    Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService, SelectItem } from 'primeng/api';
import { ToasterComponent } from './../../../_directives/toaster.component';
import {
    BusinessSocialAspect,
    BusinessSocialAspectType
} from './../models/businessSocialAspects';
import { BusinessSocialAspectsService } from './../services/businessSocialAspects.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'nested-form',
    templateUrl: 'nested-form.component.html'
})
export class NestedFormComponent implements OnInit {
    @ViewChild(ToasterComponent, {static: false}) toasterComponent: ToasterComponent;
    @ViewChild('socialAspects', {static: false}) socialAspects: Table;
    //#region variables
    myForm: FormGroup;
    _socialAspects: BusinessSocialAspect[];
    showSocialType: Boolean = false;
    showSocialTypeTable: Boolean = false;
    socialTypeData: any;
    selectedSocialTypeId: number;
    socialAspectsTypeForm: FormGroup;
    businessId: number;
    departments: SelectItem[];
    existsAlerttext: string;
    currentAddButtonIndex = 0;
    isNameExists: boolean;
    listSocialAspect = false;
    private subscriptions: Subscription[] = [];

    //#endregion

    //#region input/output
    @Input() title: string;
    @Input('data')
    set data(businessSocialAspects: BusinessSocialAspect[]) {
        this._socialAspects = businessSocialAspects;
    }
    @Output() change: EventEmitter<number> = new EventEmitter<number>();
    //#endregion

    //#region constructor
    /**
     * constructor
     * @param _fb {FormBuilder}
     * @param businessSocialAspectsService {BusinessSocialAspectsService}
     * @param route {ActivatedRoute}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private _fb: FormBuilder,
        private businessSocialAspectsService: BusinessSocialAspectsService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.socialAspectsTypeForm = this._fb.group({
            Name: []
        });
    }

    //#endregion

    //#region page-events
    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.businessId = +this.route.snapshot.parent?.params['bid'];
        this.initForm();
        // socialAspectsType list in popup
        this.bindData();
        let types = this.route.snapshot.data['socialAspectType'];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
            if (types && types.length > 0) {
                types.forEach((type: any) => {
                    this.departments.push({ label: type.Name, value: `${type.Id}` });
                });
            }
        }));
      
    }
    //#endregion

    //#region methods

    /**
     * bind type dropdown
     * @param businessId
     */
    getType(businessId: number) {
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.departments = [];
            this.departments.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.businessSocialAspectsService
            .gettype(businessId)
            .subscribe((socialAspects: any) => {
                if (socialAspects) {
                    socialAspects.forEach((socialAspect: any) => {
                        this.departments.push({
                            label: socialAspect.Name,
                            value: socialAspect.Id
                        });
                    });
                    const props = (<FormArray>this.myForm.controls['pageProperties']);
                    props['controls'].forEach((propControl, index) => {
                        if (this.currentAddButtonIndex === index && this.showSocialType) {
                            propControl.get('SocialAspectsTypeId')!.patchValue(propControl.get('SocialAspectsTypeId')!.value);
                        }
                    });
                }
            }));
    }

    /**
     * socialAspectsType list in popup
     */
    bindData() {
        this.subscriptions.push(this.businessSocialAspectsService
            .listSocialAspectsType(this.businessId)
            .subscribe(result => {
                this.socialTypeData = result;
            }));
    }

    /**
     * set form values
     */
    initForm() {
        let socialAspects:any | FormArray = new FormArray([]);
        this.myForm = this._fb.group({
            pageProperties: socialAspects
        });
        if (this._socialAspects) {
            // edit social aspects
            this._socialAspects.forEach(element => {
                this.addSocialAspects(element);
            });
        } else {
            this.addSocialAspects();
        }
    }

    /**
     * Adds a properties FormGroup to the socialAspects
     * @method addSocialAspects
     * @param void
     * @return void
     */
    addSocialAspects(socialAspect?: any): void {
        let type = '';
        let description = '';
        let applicable = 0;
        let id = 0;
        let status = 1;

        if (socialAspect) {
            type = socialAspect.SocialAspectsTypeId
                ? socialAspect.SocialAspectsTypeId
                : null;
            description = socialAspect.Description
                ? socialAspect.Description
                : '';
            applicable = socialAspect.IsApplicable
                ? socialAspect.IsApplicable
                : 0;
            id = socialAspect.Id ? socialAspect.Id : 0;
            status = socialAspect.Status ? socialAspect.Status : '1';
        }
        (<FormArray>this.myForm.controls['pageProperties']).push(
            new FormGroup({
                SocialAspectsTypeId: new FormControl(type, Validators.required),
                IsApplicable: new FormControl(
                    applicable > 0,
                    Validators.required
                ),
                Description: new FormControl(description, Validators.required),
                // hidden fields
                Id: new FormControl(id),
                Status: new FormControl(status),
                BusinessId: new FormControl(this.businessId)
            })
        );
    }

    /**
     * delete social aspect form array
     * @param i
     */
    removeProperties(i: number) {
        const control = <FormArray>this.myForm.controls['pageProperties'];
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                if (control.value[i].Id) {
                    let propId = control.value[i].Id;

                    this.subscriptions.push(this.businessSocialAspectsService
                        .deleteSocialAspects(propId)
                        .subscribe((res:any) => {
                            if (res.isSuccess) {
                                this.toasterComponent.callToastDlt();
                                control.removeAt(i);
                            }
                        }));
                } else {
                    // remove uncommited property
                    control.removeAt(i);
                }
            }
        });
    }

    /**
     * add and update social aspect
     */
    save() {
        let result = this.myForm.value.pageProperties.map((a: any) => {
            a.IsApplicable = a.IsApplicable ? 1 : 0;
            return a;
        });

        this.subscriptions.push(this.businessSocialAspectsService
            .addSocialAspect(result)
            .subscribe(isAdded => {
                this.toasterComponent.callToast();
            }));
    }

    /**
     * view type popup
     */
    typeOfSocialAspects(index?: number) {
        this.showSocialType = true;
        this.currentAddButtonIndex = index!;
    }

    /**
     * show type table
     */
    typeOfSocialTable() {
        this.showSocialTypeTable = true;
    }

    /**
     * edit type
     */
    editType(socialAspects: BusinessSocialAspectType) {
        this.socialAspectsTypeForm = this._fb.group({
            Name: socialAspects.Name
        });
        this.selectedSocialTypeId = socialAspects.Id;
    }

    /**
     * delete social aspect type
     * @param id
     */
    deleteType(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.businessSocialAspectsService
                    .deleteSocialAspectsType(id)
                    .subscribe(isRemoved => {
                        if (isRemoved) {
                            this.bindData();
                            this.toasterComponent.callToastDlt();
                        }
                    }));
            }
        });
    }

    /**
     * add and update socialaspect
     */
    saveSocialAspectsType() {
        let socialType = new BusinessSocialAspectType();
        socialType.Id = this.selectedSocialTypeId
            ? this.selectedSocialTypeId
            : 0;
        socialType.Name = this.socialAspectsTypeForm.value.Name;
        socialType.Status = '1';
        socialType.BusinessId = this.businessId;
        if (socialType.Id > 0) {
            this.subscriptions.push(this.businessSocialAspectsService
                .updateSocialAspectsType(socialType)
                .subscribe(isUpdated => {
                    this.bindSocialAspectsType(isUpdated);
                }));
        } else {
            this.subscriptions.push(this.businessSocialAspectsService
                .addSocialAspectsType(socialType)
                .subscribe(isAdded => {
                    this.bindSocialAspectsType(isAdded);
                }));
        }
    }
    /**
     * bind social aspects type
     * @param aspectsType {any}
     */
    bindSocialAspectsType(aspectsType: any) {
        if (aspectsType) {
            this.bindData();
            this.getType(this.businessId);
            this.toasterComponent.callToast();
            this.selectedSocialTypeId = 0;
            this.showSocialType = false;
        } else {
            this.existsAlerttext = this.translate.instant('DATA_EXIST');
            this.isNameExists = true;
            setTimeout(function () {
                this.isNameExists = false;
            }.bind(this), 3000);
        }
    }
    /**
     * social aspects popup reset
    */
    onBeforeAspectDialogHide() {
        this.myForm.reset(); // need to check its not reset form control
        this.socialAspects.reset();
        this.selectedSocialTypeId = 0;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
