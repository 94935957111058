import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { BaseServices } from "../kuba.services";
import { SJAFormServices } from "./sjaform.routing";

@Injectable()
export class SJAFormListResolver implements Resolve<any>{
    constructor(private backend: SJAFormServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        let featureKey = workingModule.id == 15 ? 0 : route.params['Id'];
        return this.backend.getSJAFormList(workingModule.id, featureKey, BaseServices.BusinessId);
    }
}

@Injectable()
export class SJAFormResolver implements Resolve<any>{
    constructor(private backend: SJAFormServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.getSJAForm(+route.params['Id']);
    }
}

@Injectable()
export class SJATitleListResolver implements Resolve<any>{
    constructor(private backend: SJAFormServices) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.backend.GetSJATitleList();
    }
}