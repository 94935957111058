import { Feature, FeatureKey } from './../../../../_models/feature';
import { ToasterComponent } from './../../../../_directives/toaster.component';
import { BaseServices } from './../../../kuba.services';
import { ConfirmationService } from 'primeng/api';
import { FoodSafetyServices } from './../../services/foodsafety.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FoodSafetyList } from './../../models/foodsafety-list';
import { FoodSafetyManualCompleteChecklist } from '../../../shared/models/complete-checklist';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'foodsafety-list.component.html'
})
export class FoodSafetyListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    FoodSafety: FoodSafetyList[];
    FoodSafetyManualCompleteChecklist: FoodSafetyManualCompleteChecklist;
    additionalData: any;
    isShowUser = true;
    deleteDialog = false;
    delete: any;
    deleteButton = false;
    deleteZoneID: number;
    showLoader=false;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * constructor
     */
    constructor(
        public foodSafetyService: FoodSafetyServices,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private router: Router,
        private translate: TranslateService
    ) {
        this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
            this.additionalData = {
                fileName: val.FOOD_SAFETY_LIST,
                header: 'Food Safety List',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: [
                    { title: 'Zone Name', dataKey: 'ZoneName' },
                    { title: 'Department Name', dataKey: 'Department' },
                    { title: 'Created On', dataKey: 'CreatedOn' },
                    { title: 'Created By', dataKey: 'CreatorName' }
                ]
            };
        }));
    }
    //#endregion

    //#region page-event
    /**
     * page init event
     */
    ngOnInit(): void {
        let foodSafety = <FoodSafetyList[]>this.route.snapshot.data['list'];
        if (foodSafety.length > 0) {
            this.FoodSafety = foodSafety;
        }
        var fdParam = null;
        fdParam = this.route.snapshot.params['fs'];
        let fs = sessionStorage.getItem('checkParent');
        if (foodSafety.length == 1 && fs == 'FS') {
            this.router.navigate(['../../list'], { relativeTo: this.route });
        }
        else if (foodSafety.length == 1) {

            this.router.navigate(['../../details/' + foodSafety[0].Id], { relativeTo: this.route })
        }
        sessionStorage.setItem('checkParent', null);
        if (+BaseServices.roleId === 5 || +BaseServices.roleId === 4) {
            this.isShowUser = false;
        }
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
    }
    //#endregion

    //#region method
    /**
     * delete food safety
     * @param FoodSafety {{number}}
     */
    deleteFoodSafety(FoodSafety: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.deleteDialog = false;
                this.delete = "";
                this.deleteButton = false;
                this.showLoader=true
                this.subscriptions.push(this.foodSafetyService
                    .deleteFoodSafety(FoodSafety)
                    .subscribe(result => {
                        this.subscriptions.push(this.foodSafetyService
                            .getAllByBusinessId(BaseServices.BusinessId)
                            .subscribe(res => {
                                this.FoodSafety = res;
                                this.toasterComponent.callToastDlt();
                                this.showLoader=false;    
                            }));
                    }));
            }
        });
    }
    //#endregion

    completeChecklist() {
        this.router.navigate(
            ['./../../../fs-complete-checklist/new/' + null],
            { relativeTo: this.route }
        );
    }

    deleteZoneDialog(zone: any) {
        this.deleteZoneID = zone;
        this.deleteDialog = true;
        this.delete = '';
    }

    onDelete(del: any) {
        this.deleteButton = false;
        if (del == "DELETE") {
            this.deleteButton = true;
        }
    }

    closeZoneDialog() {
        this.deleteDialog = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
