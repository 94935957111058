<div class="main-content">
    <div class="main-heading">
        <strong>Access Log</strong>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #dt [(value)]="activityLogs" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="UserId">{{'User Name' | translate}}</th>
                            <th pSortableColumn="BusinessId">{{'Business'|translate}}</th>
                            <th pSortableColumn="FeatureId">{{'Feature'|translate}}</th>
                            <th pSortableColumn="ApplicationId">{{'Application'|translate}}</th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'UserId', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'BusinessId', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'FeatureId', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                            <th>
                                <input pInputText type="text"
                                  (input)="dt.filter($event.target.value, 'ApplicationId', 'contains')" placeholder=""
                                  class="p-column-filter">
                            </th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-acticitylog>
                        <tr>
                            <td>
                                <span>{{acticitylog.UserId}}</span>
                            </td>
                            <td>
                                <span>{{acticitylog.BusinessId}}</span>
                            </td>
                            <td>
                                <span>{{acticitylog.FeatureId}}</span>
                            </td>
                            <td>
                                <span>{{acticitylog.ApplicationId}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="4">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="form-group">
                <a [routerLink]="['../activitylog/new']" class="btn btn-success">
                    <span translate>New</span>
                </a>
            </div>
        </div>
    </div>
</div>