<div class="panel-tabs">
            <nav>
                <ul class="nav nav-pills">
                    <li [routerLinkActive]="['active']">
                        <a [routerLink]="['./info']" translate>INSPECTION_INFORMATION</a>
                    </li>
                    <li [routerLinkActive]="['active']">
                        <a [routerLink]="['./dev']" class="disabled" *ngIf="!isEditMode" translate>INSPECTION_DEVIATION</a>
                        <a [routerLink]="['./dev']" *ngIf="isEditMode" translate>INSPECTION_DEVIATION</a>
                    </li>
                </ul>
            </nav>
        </div>
        <router-outlet></router-outlet>
    