import { ProjectUsersResolver } from './services/crewlist.resolver';
import { CrewListResolver, PeojectInfoResolver,CrewListHistroyResolver } from 'src/app/kuba/crew-list/services/crewlist.resolver';
import { TabComponent } from './components/tab-navigation.component';
import { HistoryComponent } from './components/history.component';
import { CrewListComponent } from './components/crew-list.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { CrewComponent } from './crew.component';
import { NgModule } from '@angular/core';
import { CrewListServices } from 'src/app/kuba/crew-list/services/crewlist.service';
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        SharedModule,
        DialogModule,
        FileUploadModule,
        AppSharedModule,
        RouterModule,
        TranslateModule,
        DropdownModule,
        KubaExportModule,
        InputSwitchModule,
        MyDatePickerModule
    ],
    exports: [CrewComponent,
        CrewListComponent,
        HistoryComponent,
        TabComponent],
    declarations: [CrewComponent,
        CrewListComponent,
        HistoryComponent,
        TabComponent],
    providers: [CrewListServices, CrewListResolver,
        ProjectUsersResolver, PeojectInfoResolver,CrewListHistroyResolver],
})
export class CrewListModule { }
