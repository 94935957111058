
export class FoodSafetyList {
    Id: number;
    ZoneName: string;
    Department: string;
    BusinessId: string
    CreatedOn: string;
    CreatedBy: number;
    Status: string;
    Title: string;
}

export class ReasonAfterDealineCompletion{
    Id: number;
    ActivityId: number;
    Reason: string;
}

