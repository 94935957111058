import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { HomePage } from './../models/businessHomepage';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessHomepageService {
    constructor(private http: HttpClient) {}
    getHomepageByBusinessId(bid: number) {
        return this.http
            .get(environment.BASE_URL + '/homepages/homepagelist/' + bid, BaseServices.headerOption)
            .map(result => result);
    }
    getHomepageById(id: number) {
        return this.http
            .get(environment.BASE_URL + '/homepages/homepage/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    createHomepage(homepage: HomePage) {
        return this.http
            .post(
                environment.BASE_URL + '/homepages/homepage/',
                homepage,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateHomepage(id: number, homepage: HomePage) {
        return this.http
            .put(
                environment.BASE_URL + '/homepages/homepage/',
                homepage,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteById(id: number) {
        return this.http
            .delete<HttpHeaderResponse>(environment.BASE_URL + '/homepages/homepagedelete/' + id, BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => {
                return response;
            });
    }
    checkExistingLink(link: string) {
        return this.http
            .get(environment.BASE_URL + '/homepages/check-link/' + link, BaseServices.headerOption)
            .map(result => result);
    }
}
