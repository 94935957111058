import { NgModule } from '@angular/core';
import {ScrollingModule,} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EcplDocumentTreeComponent } from './ecpl-document-tree.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { EcplPdfViewerModule } from '../ecpl-pdf-viewer/ecpl-pdf-viewer.module';
import { EcplOnlyofficeViewerModule } from '../ecpl-onlyoffice-viewer/ecpl-onlyoffice-viewer.module';
import { EcplImageViewerModule } from '../ecpl-image-viewer/ecpl-image-viewer.module';
import { EcplArticleViewerModule } from '../ecpl-article-viewer/ecpl-article-viewer.module';
import { EcplChecklistViewerModule } from '../ecpl-checklist-viewer/ecpl-checklist-viewer.module';
import { EcplLarViewerModule } from '../ecpl-lar-viewer/ecpl-lar-viewer.module';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { ActiveFileCount, ActiveFolderCount } from '../../_helpers/pipes';
import { DefaultFolderTranslate } from '../../_helpers/pipes';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TreeModule,
    ModalModule.forRoot(),
    EcplPdfViewerModule,
    EcplOnlyofficeViewerModule,
    EcplImageViewerModule,
    EcplArticleViewerModule,
    EcplChecklistViewerModule,
    EcplLarViewerModule,
    ContextMenuModule.forRoot({ autoFocus: true }),
    ScrollingModule,
    TranslateModule
  ],
  declarations: [ActiveFileCount, EcplDocumentTreeComponent, DefaultFolderTranslate,ActiveFolderCount],
  exports: [EcplDocumentTreeComponent]
})
export class EcplDocumentTreeModule { }
