import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

@Component({
    selector: 'contact-list',
    templateUrl: 'contactList.component.html'
})


export class ContactListComponent implements OnInit {
    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute
    ) { }


    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.route.navigate(['our'], { relativeTo: this.activatedRoute });
    }

    saveChange() {
    }
}

