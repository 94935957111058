import {
    ModuleOverviewListResolver,
    ModuleOverviewEditResolver
} from './services/module-overview.resolver.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModuleOverviewListComponent } from './components/module-overviewList.component';
import { CreateModuleOverviewComponent } from './components/Createmodule-overview.component';
import { ModuleOverviewComponent } from './module-overview.component';
import { ModuleOverviewServices } from './services/module-overview.service';
import { AppSharedModule } from './../shared/appShared.module';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        TableModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ReactiveFormsModule,
        ContextMenuModule,
        MenuModule,
        ConfirmDialogModule,
        AppSharedModule
    ],
    declarations: [ModuleOverviewComponent, ModuleOverviewListComponent, CreateModuleOverviewComponent],
    providers: [
        ModuleOverviewServices,
        ConfirmationService,
        ModuleOverviewListResolver,
        ModuleOverviewEditResolver
    ],
})
export class ModuleOverviewModule {}
