export class BusinessView {
  Id: number;
  OrganizationNumber: string;
  CompanyName: string;
  CompanyNumber: string;
  AboutBusiness: string;
  Address: string;
  Zipcode: string;
  Telephone: string;
  Email: string;
  BusinessCategoryId: number;
  ApplicationId: number;
  Status: boolean;
  BusinessPortalId: number;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedOn: string;
  ModifiedOn: string;
  PortalUsers: UserProfileDto[];
}
export class FireDeviationUserDto {
  FireDeviationId: number;
  UserId: number;
}
export class ProjectUserDto {
  ProjectId: number;
  UserId: number;
}

export class UserProfileDto {
  Id: number;
}
