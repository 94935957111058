import { FeatureKey } from './../../../_models/feature';
import { EmployeeServices } from './../../employees/services/employee.services';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators
} from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SelectItem } from 'primeng/api';

import { ValidationService } from '../../shared/services/validation.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { AdminUser, UserAddressPropertyDto } from './../models/adminuser';
import { AdminUserService } from './../services/adminuser.service';
import { UserService } from './../../users/services/user.service';
import {
    AdminSaveUsers,
    AdminUserAvatarFile,
    AdminUserSignatureFile,
} from './../models/adminuser';
import { AlertService } from '../../../_services';
import { BaseServices } from '../../kuba.services';
import { Subscription } from 'rxjs';
@Component({
    selector: 'adminuser-add',
    templateUrl: 'new-adminuser.component.html'
})
export class AdminUserAddComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    adminUserAddForm: FormGroup;
    userId: number;
    user: AdminUser;
    public Id?: number;
    fileName: string;
    uploadRequest: any;
    userImage: string;
    isNewUser = true;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    constructor(
        private fb: FormBuilder,
        private userService: AdminUserService,
        private alertService: AlertService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private employeeServices: EmployeeServices
    ) { }
    //#endregion

    //#region page-event
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.adminUserAddForm = this.fb.group({
            Name: ['', Validators.required],
            Email: [
                '',
                [Validators.required, ValidationService.emailValidator]
            ],
            Avatar: [''],
            newPasswordValidation: this.fb.group(
                {
                    Password: ['', Validators.required],
                    ConfirmPassword: ['', Validators.required]
                },
                { validator: ValidationService.matchPassword }
            ),
            Username: [
                '',
                [Validators.required],
                this.isUserNameUnique.bind(this)
            ],
            LanguageId: [''],
            Status: [1, Validators.required],
            Address: [''],
            Mobile: ['', Validators.required],
            PhoneCode: [2],
            PostalAddress: [''],
            ZipCode: [''],
            NewsLetter: [''],
        });
        this.subscriptions.push(this.userService.getImage$.subscribe(imagePath => {
            this.userImage = imagePath ? imagePath : '';
        }));
        this.userService.checkNewUser(true);
    }
    //#endregion

    //#region methods
    saveChanges() {
        let userBusinessPropertyDto = new UserAddressPropertyDto();
        userBusinessPropertyDto.UserId = 0;
        userBusinessPropertyDto.User.CreatedBy = BaseServices.UserId;
        userBusinessPropertyDto.User.Name = this.adminUserAddForm.value.Name;
        userBusinessPropertyDto.User.Email = this.adminUserAddForm.value.Email;
        userBusinessPropertyDto.User.Username = this.adminUserAddForm.value.Username;
        userBusinessPropertyDto.User.LanguageId = this.adminUserAddForm.value.LanguageId || 1; // english default
        userBusinessPropertyDto.User.RoleId = 1;
        userBusinessPropertyDto.User.ApplicationId = BaseServices.ApplicationId;
        userBusinessPropertyDto.User.PostalAddress = this.adminUserAddForm.value.PostalAddress;
        userBusinessPropertyDto.User.IsNewsLetter = this.adminUserAddForm.value.NewsLetter;
        userBusinessPropertyDto.User.BusinessId = BaseServices.BusinessId;
        userBusinessPropertyDto.User.Password = this.adminUserAddForm.value.newPasswordValidation[
            'Password'
        ];
        userBusinessPropertyDto.User.Status = '1';
        userBusinessPropertyDto.User.FeatureId = FeatureKey.EMPLOYEES;
        userBusinessPropertyDto.User.Mobile = this.adminUserAddForm.value.Mobile;
        userBusinessPropertyDto.User.CountryCode = this.adminUserAddForm.value.PhoneCode;
        userBusinessPropertyDto.User.Id = 0;
        userBusinessPropertyDto.AddressProperty.Address = this.adminUserAddForm.value.Address;
        userBusinessPropertyDto.AddressProperty.Zip = this.adminUserAddForm.value.ZipCode;
        userBusinessPropertyDto.User.Avatar = this.userImage;
        this.subscriptions.push(this.userService.addusers(userBusinessPropertyDto).subscribe(result => {
            this.toasterComponent.callToast();
        }));
        this.resetForm();
    }
    resetForm() {
        this.adminUserAddForm = this.fb.group({
            Name: '',
            Email: '',
            Avatar: '',
            newPasswordValidation: this.fb.group({
                Password: '',
                ConfirmPassword: ''
            }),
            Username: '',
            LanguageId: '',
            Status: '',
            Address: '',
            Mobile: '',
            PhoneCode: '',
            PostalAddress: '',
            ZipCode: '',
            NewsLetter: ''
        });
    }

    // TODO: Upload profile image need to be implemented

    onUpload(event:any) {
        for (let file of event.files) {
            this.fileName = file.name;
        }
    }
    /**
   * user name validation
   */
    isUserNameUnique(control: FormControl) {
        const q = new Promise(resolve => {
            setTimeout(() => {
                this.subscriptions.push(this.employeeServices.getUserName(control.value).subscribe(
                    x => {
                        if (!x) {
                            resolve(null);
                        } else {
                            resolve({ invalidUserName: true });
                        }
                    },
                    () => {
                        resolve({ invalidUserName: true });
                    }
                ));

            }, 1000);
        });
        return q;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
    //#endregion
}
