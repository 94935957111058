<div class="page-title">
    <a [routerLink]="['./../../../../list/own']" class="btn btn-icon" title="{{'BACK_CONTRACTS'|translate}}">
        <span class="icon ic-xs icon-back"></span>
        <span translate>BACK_CONTRACTS</span>
    </a>
    <span translate>MEASURE_PROTOCOL</span>
    <span class="page-actions">
        <a [routerLink]="['./../new']" class="btn btn-success" *ngIf="isGuest">
            <span class="icon ic-sm icon-add"></span>
            <span translate>NEW_MEASURE_PROTOCOL</span>
        </a>
    </span>
</div>

<div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table #dt [value]="measureProtocols" scrollable="true" scrollHeight="200px" [rows]="10" [paginator]="true"
        [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="dt.filteredValue || dt.value" [additionalData]="additionalData">
                        </kuba-export>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>{{'COMPLETE_PROTOCOL' | translate}}</th>
                <th pSortableColumn="Number">{{'MP_NUMBER' | translate}} <p-sortIcon field="Number"></p-sortIcon></th>
                <th pSortableColumn="Name">{{'MP_NAME' | translate}} <p-sortIcon field="Name"></p-sortIcon></th>
                <th pSortableColumn="CreatedOn">{{'CREATED_ON' | translate}} <p-sortIcon field="CreatedOn"></p-sortIcon></th>
                <th pSortableColumn="ModifiedOn">{{'EDITED_ON' | translate}} <p-sortIcon field="ModifiedOn"></p-sortIcon></th>
                <th pSortableColumn="Status">{{'STATUS' | translate}} <p-sortIcon field="Status"></p-sortIcon></th>
                <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
                <th pSortableColumn="ModifiedName">{{'EDITED_BY' | translate}} <p-sortIcon field="ModifiedName"></p-sortIcon></th>
                <th>{{'OPTIONS'| translate}}</th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'Number', 'contains')"
                        placeholder="" class="p-column-filter" >
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'Name', 'contains')"
                        placeholder="" class="p-column-filter">
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-mp>
            <tr>
                <td>
                    <a>
                        <button class="btn btn-icon" [disabled]="!isGuest" (click)="complete(mp['Id'])"
                            title="{{'COMPLETE_PROTOCOL' | translate}}" routerLinkActive="active">
                            <span class="icon ic-sm icon-checkmark"></span>
                        </button>
                    </a>
                </td>
                <td>
                    <span>{{mp.Number}}</span>
                </td>
                <td>
                    <div *ngIf="isGuest else elseBlock">
                        <a [routerLink]="['./../instrument/' + mp.Id + '/list']">{{mp.Name}}</a>
                    </div>
                    <ng-template #elseBlock>
                        <a>{{mp.Name}}</a>
                    </ng-template>
                </td>
                <td>
                    <span>{{mp.CreatedOn | date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span>{{mp.ModifiedOn | date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span translate>{{mp.Status | status: [' ', 'OPEN','DONE','REJECTED']}}</span>
                </td>
                <td>
                    <span>{{mp.CreatedName}}</span>
                </td>
                <td>
                    <span>{{mp.ModifiedName}}</span>
                </td>
                <td class="col-button">
                    <button class="btn btn-icon" title="{{'EDIT' | translate}}" [routerLink]="['./../edit',mp.Id]"
                        routerLinkActive="active">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button class="btn btn-icon" title="{{'DELETE' | translate}}" (click)="deleteMP(mp['Id'])"
                        routerLinkActive="active">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="9">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends-->
</div>
<div class="page-title">
    <span translate>MPCOMPLETED_UNDERCOMPLETE</span>
</div>
<div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table #ct [value]="completeMeasureProtocols" scrollable="true" scrollHeight="200px" [rows]="10"
        [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="Number">{{'MP_NUMBER' | translate}} <p-sortIcon field="Number"></p-sortIcon></th>
                <th pSortableColumn="Name">{{'MP_NAME' | translate}} <p-sortIcon field="Name"></p-sortIcon></th>
                <th pSortableColumn="CreatedOn">{{'START_DATE' | translate}} <p-sortIcon field="CreatedOn"></p-sortIcon></th>
                <th pSortableColumn="DoneOn">{{'COMPLETED_DATE' | translate}} <p-sortIcon field="DoneOn"></p-sortIcon></th>
                <th pSortableColumn="Status">{{'STATUS_MP' | translate}} <p-sortIcon field="Status"></p-sortIcon></th>
                <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatedName"></p-sortIcon></th>
                <th>{{'OPTIONS'| translate}}</th>
            </tr>
            <tr>
                <th>
                    <input pInputText type="text" (input)="ct.filter($event.target.value, 'Number', 'contains')"
                        placeholder="" class="p-column-filter" >
                </th>
                <th>
                    <input pInputText type="text" (input)="ct.filter($event.target.value, 'Name', 'contains')"
                        placeholder="" class="p-column-filter">
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-cmp>
            <tr>
                <td>
                    <span>{{cmp.Number}}</span>
                </td>
                <td>
                    <div *ngIf="isGuest else elseBlock">
                        <a *ngIf="cmp.Status == 1 || cmp.Status == 3"
                            [routerLink]="['./../completeprotocol/' + cmp.Id + '/details/usedinstruments']">{{cmp.Name}}</a>
                        <p *ngIf="cmp.Status == 2">{{cmp.Name}}</p>
                    </div>
                    <ng-template #elseBlock>
                        <a>{{cmp.Name}}</a>
                    </ng-template>
                </td>
                <td>
                    <span>{{cmp.CreatedOn | date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span>{{cmp.DoneOn | date : 'dd/MM/yyyy'}}</span>
                </td>
                <td>
                    <span translate>{{cmp.Status | status: [' ', 'OPEN','DONE','REJECTED']}}</span>
                </td>
                <td>
                    <span>{{cmp.CreatedName}}</span>
                </td>
                <td class="col-button">
                    <a *ngIf="cmp.Status == 2" class="btn btn-icon" title="{{'FILE' | translate}}"
                        routerLinkActive="active">
                        <i class="icon ic-sm icon-file"></i>
                    </a>
                    <div *ngIf="cmp.Status == 1 || cmp.Status == 3">
                        <button class="btn btn-icon" title="{{'EDIT' | translate}}"
                            [routerLink]="['./../completeprotocol/edit',cmp.Id]" routerLinkActive="active">
                            <i class="icon ic-sm icon-pencil-tip"></i>
                        </button>
                        <button class="btn btn-icon" title="{{'DELETE' | translate}}" (click)="deleteCMP(cmp['Id'])"
                            routerLinkActive="active">
                            <i class="icon ic-sm icon-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends-->
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>