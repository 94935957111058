import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { VehicleServices } from 'src/app/kuba/vehicle/services/vehicle.service';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { SelectItem } from 'primeng/api';

import { HelperService } from './../../../_services/helper.service';
import { AutoWizUser } from './../../autowiz-login/components/autowizuser';
import { VehicleTrackingServices } from './../services/vehicleTracking.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicletrackList.component.html'
})
export class VehicleTrackListComponent implements OnInit {
    @ViewChild('tripDetailsTable',{static: false}) tripDetailsTable: Table;
    tripDetails: any = [];
    trackingForm: FormGroup;
    tripCategories: SelectItem[];
    parentKey: any;
    totalTollPay: any;
    isHide = true;
    isTripDetail = false;
    additionalData: any;
    private subscriptions: Subscription[] = [];
    /**
     * Date picker configuration option
     */
    public StartDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableSince: this.getCurrentDate()
    };
    public EndDateOptions: IMyDpOptions = this.StartDateOptions;
    constructor(
        private fb: FormBuilder,
        private vehicleTrackingService: VehicleTrackingServices,
        private vehicleService: VehicleServices,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        this.additionalData = {
            fileName: 'Vehicle Track List',
            header: 'Vehicle Track List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Trip ID', datakey: 'id' },
                { title: 'Start Date', dataKey: 'StartDate' },
                { title: 'End Date', datakey: 'EndDate' },
                { title: 'Start Time', datakey: 'StartTime' },
                { title: 'End Time', datakey: 'EndTime' },
                { title: 'End Place', datakey: 'endLocationAddress' },
                { title: 'Total Km', datakey: 'tripDistance' },
                { title: 'Toll cost', datakey: 'totalTollPay' },
            ]
        };

        this.tripCategories = [];
        this.tripCategories.push({ label: 'All', value: null });
        this.tripCategories.push({ label: 'Personal', value: 1 });
        this.tripCategories.push({ label: 'Business', value: 0 });

        this.trackingForm = this.fb.group({
            StartDate: ['', Validators.required],
            EndDate: ['', Validators.required],
            TripCategory: ['']
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.parentKey = this.route.snapshot.params['Id'];
    }
    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() + 1
        };
    }
    getTripDetails() {
        this.subscriptions.push(this.vehicleService
            .getVehiclesById(this.parentKey)
            .subscribe((vehicles:any) => {
                if (vehicles) {
                    let autoWizuser = new AutoWizUser();
                    autoWizuser.UserId = BaseServices.UserId.toString();
                    autoWizuser.Status = '1';
                    autoWizuser.BusinessId = BaseServices.BusinessId;
                    this.isTripDetail = true;
                    this.subscriptions.push(this.vehicleTrackingService
                        .getTripDetails(
                            autoWizuser,
                            this.formatDate(
                                this.trackingForm.value.StartDate.formatted
                            ),
                            vehicles.AutowizVechicleId,
                            this.formatDate(
                                this.trackingForm.value.EndDate.formatted
                            ),
                            vehicles.VehicleGeoDeviceId,
                            'All'
                        )
                        .subscribe(result => {
                            if (result) {
                                this.tripDetails = [];
                                if (JSON.parse(result[0])) {
                                    let data = JSON.parse(result[0]);
                                    data.forEach((tripDetail: any) => {
                                        if (tripDetail) {
                                            tripDetail.StartDate = this.getDate(
                                                tripDetail.tripStartTime
                                            );
                                            tripDetail.EndDate = this.getDate(
                                                tripDetail.tripEndTime
                                            );
                                            tripDetail.StartTime = this.getTime(
                                                tripDetail.tripStartTime
                                            );
                                            tripDetail.EndTime = this.getTime(
                                                tripDetail.tripEndTime
                                            );
                                            if (tripDetail.extraData !== null) {
                                                tripDetail.tripCategory =
                                                    tripDetail.tripCategory;
                                                if (
                                                    tripDetail.extraData
                                                        .tripCategory !== null
                                                ) {
                                                    if (
                                                        tripDetail.extraData
                                                            .tripCategory ===
                                                        'Business'
                                                    ) {
                                                        tripDetail.Category = false;
                                                    } else {
                                                        tripDetail.Category = true;
                                                    }
                                                }
                                            }
                                            this.tripDetails.push(tripDetail);
                                            this.isHide = false;
                                            this.clear();
                                        }
                                    });
                                    this.calculateTotalCost();
                                } else {
                                    this.isHide = true;
                                }
                            }
                        }));
                }
            }));
    }
    getDate(str: any) {
        let d = new Date();
        d.setTime(str);
        let date = new Date(d),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join('/');
    }

    getTime(str: any) {
        let d = new Date();
        d.setTime(str);
        let date = new Date(d),
            hours = ('0' + date.getHours()).slice(-2),
            minutes = ('0' + date.getMinutes()).slice(-2);
        return hours + ':' + minutes;
    }
    /**
     * Method for setting date range
     * @param dateConfig {IMyDpOptions}
     * @param event{IMyDateModel}
     * @param type  {string}
     */
    setDateInput(
        dateConfig: IMyDpOptions,
        event: IMyDateModel,
        type: string
    ): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }
    /**
     * Event handler for End date
     * @param event {IMyDateModel}
     */
    onEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.StartDateOptions = this.setDateInput(
            this.StartDateOptions,
            event,
            'endDate'
        );
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
                this.StartDateOptions
            );
            copyConfig.disableSince = this.getCurrentDate();
            this.StartDateOptions = copyConfig;
        }
    }
    /**
     * event handler for Start date
     * @param event {IMyDateModel}
     */
    onStartDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.EndDateOptions = this.setDateInput(
            this.EndDateOptions,
            event,
            'startDate'
        );
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
                this.EndDateOptions
            );
            copyConfig.disableSince = this.getCurrentDate();
            this.EndDateOptions = copyConfig;
        }
    }
    formatDate(dateTime: any, pickerDate: any = null, delimiter = '-') {
        let time = '';
        if (pickerDate) {
        }
        if (dateTime) {
            let date = new Date(dateTime);
            if (date) {
                let formattedMonth =
                    date.getMonth() < 9
                        ? `0${date.getMonth() + 1}`
                        : date.getMonth() + 1;
                let formattedDate =
                    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
                time = `${date.getFullYear()}${delimiter}${formattedMonth}${delimiter}${formattedDate}`;
            }
        }
        return time ? time : '';
    }
    calculateTotalCost() {
        let totalCost = 0;
        if (this.tripDetails) {
            this.tripDetails.forEach(element => {
                totalCost = totalCost + element.totalTollPay;
                this.totalTollPay = totalCost;
            });
        }
        return this.totalTollPay;
    }
    clear() {
        this.trackingForm = this.fb.group({
            StartDate: [''],
            EndDate: [''],
            TripCategory: ['']
        });
    }
    onTripCategoryChanged(e: any, tripCategoryDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.tripDetailsTable.filter(
                tripCategoryDropdown.selectedOption.value,
                'tripCategory',
                'equals'
            );
        } else {
            this.tripDetailsTable.reset();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
