import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Category } from '../models/';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessCategoryService {
    constructor(private http: HttpClient) { }

    getAllCategories() {
        return this.http
            .get(
                environment.BASE_URL + '/business/categories',
                BaseServices.headerOption
            )
            .map(result => result);
    }
    getAllBusiness() {
        return this.http
            .get(
                environment.BASE_URL + '/businesses',
                BaseServices.headerOption
            )
            .map(result => result);
    }

    create(newCategory: Category) {
        return this.http
            .post<HttpHeaderResponse>(
                environment.BASE_URL + '/business/category',
                newCategory,
                BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    update(id: number, ExistingCategory: Category) {
        return this.http
            .put<HttpHeaderResponse>(
                environment.BASE_URL + '/business/category/' + id,
                ExistingCategory,
                BaseServices.headerOption
            )
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }

    deleteCategory(id: number) {
        return this.http
            .delete<HttpHeaderResponse>(environment.BASE_URL + '/business/category/' + id, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => {
                return response;
            });
    }
}
