<div class="tab-content">
  <div class="page-title">
    <span translate>TEMPERATURE</span>
  </div>
  <form [formGroup]="measureProtocolTemperature">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group" *ngIf="measureProtocolTemperature.controls['MPCTemperatureTypeId']">
          <label for="Title">
            <span translate>TYPE</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown [filter]="true" formControlName="MPCTemperatureTypeId" [options]="types"
              [style]="{'width':'100%'}"></p-dropdown>
            <button class="btn btn-outline-primary" type="button" (click)="addType = !addType">
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages [control]="measureProtocolTemperature.controls['MPCTemperatureTypeId']">
          </control-messages>
        </div>
        <div class="form-group" *ngIf="measureProtocolTemperature.controls['MPCTemperatureModelId']">
          <label for="Title">
            <span translate>MODEL</span>
            <span class="required">*</span>
          </label>
          <div class="input-btn-group">
            <p-dropdown [filter]="true" formControlName="MPCTemperatureModelId" [options]="models"
              [style]="{'width':'100%'}"></p-dropdown>
            <button class="btn btn-outline-primary" type="button" (click)="addModel = !addModel">
              <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
          </div>
          <control-messages [control]="measureProtocolTemperature.controls['MPCTemperatureModelId']">
          </control-messages>
        </div>
        <div class="form-group" *ngIf="measureProtocolTemperature.controls['MPCTemperatureModelId']">
          <label>
            <span translate>CELSIUS</span>
            <span class="required">*</span>
          </label>
          <div>
            <input type="number" formControlName="Celsius" id="Celsius" class="form-control" />
          </div>
          <control-messages [control]="measureProtocolTemperature.controls['Celsius']"></control-messages>
        </div>
        <div class="form-group" *ngIf="measureProtocolTemperature.controls['TempDate']">
          <label>
            <span translate>DATE</span>
            <span class="required">*</span>
          </label>
          <div>
            <my-date-picker [style]="{'width':'160px'}" [options]="DateOptions" formControlName="TempDate"
              placeholder="{{'SELECT_DATE'|translate}}"></my-date-picker>
          </div>
        </div>
        <control-messages [control]="measureProtocolTemperature.controls['TempDate']"></control-messages>
      </div>
    </div>
    <div class="action-btns-wrapper">
      <button class="btn btn-primary" [disabled]="measureProtocolTemperature.invalid || saveprogress"
        (click)="saveChanges()">
        <span translate>SAVE</span>
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="onClear()">
        <span translate>CLEAR</span>
      </button>
    </div>
  </form>

  <div class="mtop-20">
    <div class="page-title" translate>TEMPERATURE</div>
    <!-- Table starts -->
    <p-table #dt [value]="temperature" scrollable="true" scrollHeight="200px" [rows]="10" [paginator]="true"
      [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="MPCTemperatureTypeId">{{'TYPE' | translate}} <p-sortIcon
              field="MPCTemperatureTypeId"></p-sortIcon></th>
          <th pSortableColumn="MPCTemperatureModelId">{{'MODEL' | translate}} <p-sortIcon
              field="MPCTemperatureModelId"></p-sortIcon></th>
          <th pSortableColumn="TempDate">{{'DATE' | translate}} <p-sortIcon field="TempDate"></p-sortIcon></th>
          <th pSortableColumn="CreatedName">{{'CREATED_BY' | translate}} <p-sortIcon field="CreatedName"></p-sortIcon>
          </th>
          <th pSortableColumn="ModifiedName">{{'EDITED_BY' | translate}} <p-sortIcon field="ModifiedName"></p-sortIcon>
          </th>
          <th>{{'OPTIONS'| translate}}</th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-temp>
        <tr>
          <td>
            <span>{{temp.MPCTemperatureTypeId}}</span>
          </td>
          <td>
            <span>{{temp.MPCTemperatureModelId}}</span>
          </td>
          <td>
            <span>{{temp.TempDate | date : 'dd/MM/yyyy' }}</span>
          </td>
          <td>
            <span>{{temp.CreatedName}}</span>
          </td>
          <td>
            <span>{{temp.ModifiedName}}</span>
          </td>
          <td class="col-button">
            <button class="btn btn-icon" title="{{'EDIT' | translate}}" (click)="editCMPTemperature(temp['Id'])"
              routerLinkActive="active">
              <i class="icon ic-sm icon-pencil-tip"></i>
            </button>
            <button class="btn btn-icon" title="{{'DELETE' | translate}}" (click)="deleteTemperature(temp['Id'])"
              routerLinkActive="active">
              <i class="icon ic-sm icon-trash"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="!isElectro" class="action-btns-wrapper text-right">
    <button type="button" class="btn btn-outline-primary" [routerLink]="['./../comments']">
      <span translate>NEXT</span>
      <span class="icon ic-xs icon-forward"></span>
    </button>
  </div>
</div>

<p-dialog header="{{'TYPE' | translate}}" (onHide)="ontemperatureTypeFormHide()" [(visible)]="addType" [modal]="true"
  [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
  <form [formGroup]="temperatureTypeForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>TYPE</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" formControlName="Name" class="label-input form-control" required>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType"
        (click)="viewTemperatureType()">
        <span translate>VIEW</span>
      </button>
      <button type="button" class="btn btn-primary" [disabled]="temperatureTypeForm.invalid" (click)="saveType()">
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table #et [value]="editTypes" [rows]="5" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'TYPE' | translate}}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input pInputText type="text" (input)="et.filter($event.target.value, 'Name', 'contains')"
                  placeholder="" class="p-column-filter">
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryType>
            <tr>
              <td>
                <span>{{categoryType.Name}}</span>
              </td>
              <td class="col-button">
                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                  (click)="editType(categoryType)">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </button>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                  (click)="deleteType(categoryType)">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="{{'MODEL' | translate}}" (onHide)="ontemperatureModelFormHide()" [(visible)]="addModel" [modal]="true"
  [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
  <form [formGroup]="temperatureModelForm">
    <div class="form-group">
      <label for="title" class="col-form-label">
        <span translate>MODEL</span>
        <span class="required">*</span>
      </label>
      <div>
        <input type="text" formControlName="Name" class="label-input form-control" required>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType"
        (click)="viewTemperatureModel()">
        <span translate>VIEW</span>
      </button>
      <button type="button" class="btn btn-primary" [disabled]="temperatureModelForm.invalid" (click)="saveModel()">
        <span translate>SAVE</span>
      </button>
    </div>
    <div *ngIf="viewType">
      <div class="table-view">
        <!-- Table starts -->
        <p-table #ft [value]="editModels" [rows]="5" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'TYPE' | translate}}</th>
              <th></th>
            </tr>
            <tr>
              <th>
                <input pInputText type="text" (input)="ft.filter($event.target.value, 'Name', 'contains')"
                  placeholder="" class="p-column-filter">
              </th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-categoryType>
            <tr>
              <td>
                <span>{{categoryType.Name}}</span>
              </td>
              <td class="col-button">
                <a type="button" class="btn btn-icon" title="{{'EDIT'|translate}}" (click)="editModel(categoryType)">
                  <i class="icon ic-sm icon-pencil-tip"></i>
                </a>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                  (click)="deleteModel(categoryType)">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="2">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
    </div>
  </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-dialog header="{{('CONFIRMATION') | translate}}" *ngIf="ShowConfirm" [(visible)]="ShowConfirm" [modal]="true"
  [style]="{width: '500px'}" [resizable]="false" [draggable]="false" [contentStyle]="{'overflow':'visible'}">
  <span translate>DELETE_THIS_RECORD</span>
  <div class="action-btns-wrapper">
    <button type="submit" class="btn btn-primary" (click)="Confirmation(true)">
      <span class="pi pi-check"></span><span translate>SWITCH_YES</span>
    </button>
    <button type="submit" class="btn btn-primary" (click)="Confirmation(false)">
      <span class="pi pi-times"></span><span translate>SWITCH_NO</span>
    </button>
  </div>
</p-dialog>