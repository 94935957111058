import { NgModule } from '@angular/core';
import { LinksComponent } from './links.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BusinessLinksListResolver } from 'src/app/kuba/businesses/services/businessLinks.resolver.service';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
    ],
    exports: [],
    declarations: [
        LinksComponent
    ],
    providers: [
        BusinessLinksListResolver
    ],
})
export class LinksModule {

}
