import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { EcplDocumentExplorerModule } from './../../shared/ecpl-document-explorer/ecpl-document-explorer.module';
import {
    ServiceListResolver,
    ServiceEditResolver,
    ServiceAgreementMyListResolver,
    ServiceAgreementListResolver,
    ServiceAgreementFeaturesResolver,
    ServiceListByUserResolver
} from './services/service-agreement.resolver.service';
import { ServiceAgreementComponent } from './service-agreement.component';
import { ServiceAgreementMyListComponent } from './components/service-agreement-mylist.component';
import { ServiceAgreementList } from './components/service-agreement-list.component';
import { ServiceAgreementViewComponent } from './components/service-agreement-view.component';
import { ServiceAgreementFormComponent } from './components/service-agreement-form.component';
import { ClientListResolver } from './../client/services/client.resolver.service';
import { AssignUserResolver } from './../users/services/user.resolver.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { ServiceAgreementServices } from './services/service-agreement.service';
import { EcplTabNavigationModule } from 'src/app/shared/ecpl-tab-navigation/ecpl-tab-navigation.module';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        AppSharedModule,
        RouterModule,
        TranslateModule,
        EcplTabNavigationModule,
        EcplDocumentExplorerModule,
        KubaExportModule
    ],
    exports: [
        ServiceAgreementComponent,
        ServiceAgreementFormComponent,
        ServiceAgreementList,
        ServiceAgreementMyListComponent,
        ServiceAgreementViewComponent
    ],
    declarations: [
        ServiceAgreementComponent,
        ServiceAgreementFormComponent,
        ServiceAgreementList,
        ServiceAgreementMyListComponent,
        ServiceAgreementViewComponent
    ],
    providers: [
        ServiceAgreementServices,
        ServiceListResolver,
        ServiceEditResolver,
        AssignUserResolver,
        ClientListResolver,
        ServiceAgreementListResolver,
        ServiceAgreementFeaturesResolver,
        ServiceAgreementMyListResolver,
        ServiceListByUserResolver
    ]
})
export class ServiceAgreementModule { }
