import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { MeasureProtocolService } from '../services/measure-protocol.services';
import { FeatureKey } from 'src/app/_models';

@Injectable()
export class MeasureProtocolListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        let moduleId = workingModule ? workingModule.id : 0;
        return this.backend.getAllMeasureProtocol(moduleId,
            +route.params['Id'],
            route.parent.parent.data['parent']
        );
    }
}

@Injectable()
export class CompleteMeasureProtocolListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getAllCompleteMeasureProtocol(
            +route.params['Id'],
            route.parent.parent.data['parent']
        );
    }
}

@Injectable()
export class MeasureProtocolEditResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getMeasureProtocolById(
            +route.params['id'],
            route.parent.parent.params['id']
        );
    }
}

@Injectable()
export class InstrumentListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (+route.parent.params['mid'] > 0) {
            return this.backend.getAllInstruments(+route.parent.params['mid']);
        } else {
            return null;
        }
    }
}

@Injectable()
export class CMPInstrumentListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (+route.parent.params['mpcid'] > 0) {
            return this.backend.getAllCMPInstruments(
                +route.parent.params['mpcid']
            );
        } else {
            return null;
        }
    }
}

@Injectable()
export class InstrumentEditResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getInstrumentById(
            +route.parent.params['mpcid'],
            route.params['id']
        );
    }
}

@Injectable()
export class MeasureProtocolCompleteEditResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getMeasureProtocolCompleteById(
            +route.params['id'],
            route.parent.parent.params['id']
        );
    }
}

@Injectable()
export class TypeListResolver implements Resolve<any[]> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllByType(
            +route.parent.parent.parent.params['id'],
            route.parent.parent.parent.data['parent']
        );
    }
}

@Injectable()
export class ModelListResolver implements Resolve<any[]> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllByModel(
            +route.parent.parent.parent.params['id'],
            route.parent.parent.parent.data['parent']
        );
    }
}

@Injectable()
export class PowerLeakageListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (+route.parent.params['mpcid'] > 0) {
            return this.backend.getAllPowerLeakage(
                +route.parent.params['mpcid']
            );
        } else {
            return null;
        }
    }
}

@Injectable()
export class EarthLeakageListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (+route.parent.params['mpcid'] > 0) {
            return this.backend.getAllEarthLeakage(
                +route.parent.params['mpcid']
            );
        } else {
            return null;
        }
    }
}

@Injectable()
export class CMPTemperatureListResolver implements Resolve<any> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (+route.parent.params['mpcid'] > 0) {
            return this.backend.getAllCMPTemperature(
                +route.parent.params['mpcid']
            );
        } else {
            return null;
        }
    }
}

@Injectable()
export class TemperatureTypeListResolver implements Resolve<any[]> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllByTemperatureType(
            +route.parent.parent.parent.params['id'],
            route.parent.parent.parent.data['parent'],FeatureKey.KUNDE_EL
        );
    }
}

@Injectable()
export class TemperatureModelListResolver implements Resolve<any[]> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllByTemperatureModel(
            +route.parent.parent.parent.params['id'],
            route.parent.parent.parent.data['parent'],FeatureKey.KUNDE_EL
        );
    }
}

@Injectable()
export class VisualControlResolver implements Resolve<any[]> {
    constructor(private backend: MeasureProtocolService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getVisualControl(+route.parent.params['mpcid']);
    }
}
