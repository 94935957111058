import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from './../shared/appShared.module';

import { ProductionComponent } from './production.component';
import { ProductionFormComponent } from './components/production-form.component';
import { ProductionListComponent } from './components/production-list.component';
import {
    ProductionListResolver, ProductionEditResolver,
    ProductionTypeListResolver, ReceptionDropdownResolver
} from './services/production.resolver.service';
import { ProductionService } from './services/production.services';
import { ClientListResolver } from './../client/services/client.resolver.service';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { TabMenuModule } from 'primeng/tabmenu';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        BrowserModule,
        RouterModule,
        TableModule,
        SharedModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        InputSwitchModule,
        ContextMenuModule,
        FileUploadModule,
        TabMenuModule,
        MyDatePickerModule,
        AppSharedModule,
        TranslateModule,
        ReactiveFormsModule,
        KubaExportModule
    ],
    exports: [
        ProductionComponent,
        ProductionFormComponent,
        ProductionListComponent,
    ],
    declarations: [
        ProductionComponent,
        ProductionFormComponent,
        ProductionListComponent,
    ],
    providers: [
        ProductionService,
        ProductionListResolver,
        ProductionEditResolver,
        ProductionTypeListResolver,
        ClientListResolver,
        ReceptionDropdownResolver]
})
export class ProductionModule { }
