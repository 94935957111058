<div class="main-content">
    <div class="main-heading">
        <span translate>NOTIFICATION</span>
    </div>
    <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="notificationUser" #dt [rows]="10" [paginator]="true" [pageLinks]="3"
            [rowsPerPageOptions]="[5,10,20]" [responsive]="true"
            [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'TITLE' | translate}}</th>
                    <th>{{'PUBLISHED_DATE' | translate}}</th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-notification>
                <tr>
                    <td>
                        <a href="javascript:void(0);" (click)="showTitlePopup(notificationList)">
                            <span>{{notificationList.Title}}</span>
                        </a>
                    </td>
                    <td><span>{{notification.PublishedOn |date : 'MM/dd/yyyy'}}</span></td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->

        <!--New Notification Modal Popup-->
        <p-dialog header="Notification" [(visible)]="showNewNotification" [modal]="true" 
        [style]="{width: '600px'}" [resizable]="false" [draggable]="false">
            <div class="form-group">
                <h5 class="notifi-title">
                    <span translate>{{notificationTitle}}</span>
                </h5>
                <div class="wb-content">
                    <div class="notif-content-wrapper" style="padding:10px;word-break:break-all;"
                        [innerHTML]="notificationDesc"></div>
                </div>
            </div>
            <p-footer>
                <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                    <button type="button" class="btn btn-outline-secondary" (click)="closePopUp()">
                        <span translate>CLOSE</span>
                    </button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
</div>