import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { EcplChecklistViewerComponent } from './ecpl-checklist-viewer.component';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from 'src/app/kuba/shared/appShared.module';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';



@NgModule({
  declarations: [EcplChecklistViewerComponent],
  imports: [

    CommonModule,
    FormsModule,
    AppSharedModule,
    RouterModule,
    AccordionModule.forRoot(),
    TranslateModule,
    DropdownModule,
    DialogModule
  ],
  exports: [EcplChecklistViewerComponent]
})
export class EcplChecklistViewerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: EcplChecklistViewerModule
    };
  }
}
