import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { ConsumptionListDto, VehicleEditDto } from './../models/vehicle-consumption';
import { VehicleConsumptionService } from './vehicle-consumption.services';

@Injectable()
export class VehicleConsumptionListResolver implements Resolve<ConsumptionListDto[]> {
    constructor(private backend: VehicleConsumptionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ConsumptionListDto[]> {
        return this.backend.getAllVehicleConsumption(+route.parent.parent.params['id']);
    }
}
@Injectable()
export class VehicleListResolver implements Resolve<any[]> {
    constructor(private backend: VehicleConsumptionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllVehicle(+route.parent.parent.params['id']);
    }
}
@Injectable()
export class VehiclePreviousNoResolver implements Resolve<ConsumptionListDto[]> {
    constructor(private backend: VehicleConsumptionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ConsumptionListDto[]> {
        if (+route.params['id'] === 0) { // for new consumption only
            return this.backend.getPreviousNo(+route.parent.parent.params['id']);
        } else { return null; }
    }
}
@Injectable()
export class VehicleConsumptionEditResolver implements Resolve<ConsumptionListDto[]> {
    constructor(private backend: VehicleConsumptionService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<ConsumptionListDto[]> {
        if (+route.params['id'] > 0) {
            return this.backend.getVehicleConsumptionById(+route.params['id']);
        } else { return null; }
    }
}



