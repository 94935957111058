import { BusinessPortalListResolver } from './../portals/services/business-portal.resolver.service';
import { BusinessListResolver } from 'src/app/kuba/businesses/services/business.resolver.service';
import {
    ProjectAdminListResolver,
    PortalNameListResolver,
    BusinessNameListResolver,
    AdminProjectListResolver
} from './service/project-admin.resolver';
import { ProjectAdminServices } from './service/project-admin.service';
import { CommonBaseModule } from './../common/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { ProjectAdminMainComponent } from './project-admin-main.component';
import { ProjectAdminListComponent } from './components/project-admin-list.component';
import { ProjectAdminComponent } from './components/project-admin.component';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [AppSharedModule, RouterModule, TranslateModule, 
        CommonBaseModule
    ],
    exports: [
        ProjectAdminComponent,
        ProjectAdminListComponent,
        ProjectAdminMainComponent
    ],
    declarations: [
        ProjectAdminComponent,
        ProjectAdminListComponent,
        ProjectAdminMainComponent
    ],
    providers: [
        ProjectAdminServices,
        ProjectAdminListResolver,
        PortalNameListResolver,
        BusinessNameListResolver,
        AdminProjectListResolver,
        BusinessListResolver,
        BusinessPortalListResolver
    ]
})
export class ProjectAdminModule { }
