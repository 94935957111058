import { AdminProjectListResolver, ProjectAdminListResolver } from './../project-admin/service/project-admin.resolver';
import { FeatureDocumentRoutes } from './../feature-documents/feature-document.routing';
import { AssignProjectViewComponent } from './component/assign-project-view.component';
import {
    ProjectFeaturesResolver,
    ProjectEditResolver
} from './../projects/services/project.resolver';
import { CommonViewComponent } from './../common/components/commonView.component';
import { AssignprojectListResolver } from './services/assign-projects.resolver';
import { AuthGuard, AdminPortalTabGuard } from './../../_guards/auth.guard';
import { AssignProjectMainComponent } from './assign-projects.component';
import { Route } from '@angular/router';
import { AssignProjectComponent } from './component/assign-project.component';

export const AssignProjectRoutes: Route[] = [
    {
        path: 'assign',
        component: AssignProjectMainComponent,
        canActivate: [AdminPortalTabGuard],
        children: [
            {
                path: 'projects',
                component: AssignProjectComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: ProjectAdminListResolver
                }
            }
        ]
    }
];
