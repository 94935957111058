import { BaseServices } from 'src/app/kuba/kuba.services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Rights } from 'src/app/_models';
import { HelperService } from 'src/app/_services/helper.service';

@Component({
  selector: 'project-list',
  templateUrl: 'project-list.component.html',
})
export class ProjectListComponent implements OnInit {
  isUser: boolean;
  isHideMyProject = true;
  isAdministrateProject = true;
  /**
   * constructor
   * @param route {{Router}}
   * @param activatedRoute {{ActivatedRoute}}
   */
  constructor(private route: Router, private activatedRoute: ActivatedRoute) {}

  /**
   * get project list
   */
  ngOnInit(): void {
    // To hide side menu bar
    document.querySelector('body').classList.remove('opened');
    if (BaseServices.UserRole === 'Guest') {
      this.isAdministrateProject = false;
      this.route.navigate(['own'], { relativeTo: this.activatedRoute });
    } else if (BaseServices.UserRole === 'User') {
      this.onAdministrateProject();
    } else {
      this.OnAdmin();
    }
  }

  /**
   * hide the AllProjectList based upon the rights
   */
  onAdministrateProject() {
    let currentUserRole = BaseServices.UserRole;
    let userRightsId = Rights.ADMINISTRATE_PROJECTS;
    if (currentUserRole === 'User') {
      this.isAdministrateProject = BaseServices.checkUserRights(
        userRightsId,
        currentUserRole
      );
      this.route.navigate(['own'], { relativeTo: this.activatedRoute });
    } else {
      this.isAdministrateProject = true;
      this.route.navigate(['all'], { relativeTo: this.activatedRoute });
    }
  }

  OnAdmin() {
    let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
    if (curSession > 0) {
      this.isAdministrateProject = true;
      this.isHideMyProject = false;
      this.route.navigate(['all'], { relativeTo: this.activatedRoute });
    } else {
      this.isAdministrateProject = true;
      this.isHideMyProject = false;
      this.route.navigate(['all'], { relativeTo: this.activatedRoute });
    }
  }
}
