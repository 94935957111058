import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Rights } from 'src/app/_models';
import { ConfirmationService } from 'primeng/api';
import { QualitySystem, QualitySystemUsers } from '../models/quality-system';
import { QualitySystemService } from '../services/quality-system.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quality-system-list',
  templateUrl: './quality-system-list.component.html'
})
export class QualitySystemListComponent implements OnInit {



  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  internalControlData: QualitySystem;
  internalControlData1: QualitySystemUsers;
  isAdministrateIC = true;
  isHideDeleteIcon = true;
  additionalData: any;
  isDisabled:boolean;

  portals: any;
  list: any = [];
  showDialog = false;
  cols: any;
  isDeviation:any[];

  private subscriptions: Subscription[] = [];
  /**
   * 

   * @param qualitySystemService {{qualitySystemServices}}
   * @param confirmationService {{ConfirmationService}}
   * @param route {{ActivatedRoute}}
   * @param intrnlcntrlsrvc {{InternalControlServices}}
   */
  constructor(
      private qualitySystemService: QualitySystemService,
      private confirmationService: ConfirmationService,
      private route: ActivatedRoute,
      private translate: TranslateService,
      private router: Router) {
        
  }
  /**
   * get internal control list
   */
  ngOnInit() {
      document.querySelector("body").classList.remove("opened");
      this.isDisabled = true;
      let internalControl = this.route.snapshot.data['list'];
      if (internalControl != null) {
          this.internalControlData = internalControl;          
      }
      this.onAdministrateInternalControl();
      let internalControlUser = this.route.snapshot.data['Namelist'];
      this.internalControlData1 = internalControlUser;      
      let qs = sessionStorage.getItem('checkParent');

      if(internalControl.length == 1 && qs == 'QS'){
        this.router.navigate(['./../../list'], {relativeTo: this.route})
    }      
    else if(internalControl.length == 1){
        this.router.navigate(['./../../details/' + internalControl[0].Id], {relativeTo: this.route})
    }
    sessionStorage.setItem('checkParent',null);

    this.subscriptions.push(this.translate.stream('FILENAME').subscribe(val => {
        this.additionalData = {
            fileName: val.QUALITY_SYSTEMS_LIST,
            header: 'Quality System List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Name of the task', dataKey: 'Title' },
                { title: 'Description', dataKey: 'Description' },
                { title: 'Assigned Access', dataKey: 'UserCount' },
                { title: 'Deviations In Followup', dataKey: 'ShowDeviationreport' }
            ]
        }}));

  }

  onHide() {
      this.showDialog = false;
  }

  showPortalInfo(portalInfo: any[]) {
      this.showDialog = true;
      this.list = [];
      this.list = portalInfo;
  }
  /**
   * get internal control list
   */
  getAllControls() {
    this.subscriptions.push(this.qualitySystemService.getAll(BaseServices.BusinessId)
          .subscribe(result => this.internalControlData = result));

  }
  /**
   * delete internal control
   * @param control {{any}}
   */
  confirm(control) {
      this.confirmationService.confirm({
          message: this.translate.instant('DELETE_THIS_RECORD'),
          accept: () => {
              this.qualitySystemService.deleteControl(control).then(result => {
                  if (result) {
                      this.toasterComponent.callToastDlt();
                      this.getAllControls();
                  }
              });
          }
      });
  }
  /**
   * Edit & update Ic based on administrateIC rights
   */
  onAdministrateInternalControl() {
      let userRightsId = Rights.ADMINISTRATE_QUALITY_SYSTEM;
      let role = BaseServices.UserRole;
      if (role === 'User') {
          this.isHideDeleteIcon = false;
          this.isAdministrateIC = BaseServices.checkUserRights(userRightsId, role);
      }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}

