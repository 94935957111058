import { UserService } from './../../users/services/user.service';
import { BaseServices } from './../../kuba.services';
import { AccessLog, User, Business } from './../models/accesslog';
import { ToasterConfig, ToasterService, Toast } from 'angular2-toaster';
import { ActivatedRoute, Params } from '@angular/router';
import { AccessLogServices } from './../services/accesslog.services';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { BusinessServices } from '../../businesses/services/business.services';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: 'new-accesslog.component.html',
})

export class CreateAccessLogComponent implements OnInit {
    accessLogs ?: AccessLog[];
    accesslogForm: FormGroup;
    Id ?: number;
    accessLog: any;
    cBusiness: any;
    cUsers: any;
    business: SelectItem[];
    users: SelectItem[];
    private subscriptions: Subscription[] = [];
    constructor(private fb: FormBuilder,
        private accessloSrvc: AccessLogServices,
        private activatedRoute: ActivatedRoute,
        private toasterService: ToasterService,
        private route: ActivatedRoute,
        private location: Location,
        private userService: UserService,
        private businessServices: BusinessServices
          ) {
        this.accesslogForm = this.fb.group({
            username: [''],
            business: [''],
            ip: [''],
            browser: [''],
            os: ['']
        });
    }
    popToast() {
        var toast: Toast = {
            type: 'success',
            title: 'Add/Update',
            body: 'Data saved successfully'
        };

        this.toasterService.pop(toast);
    }
    public config1: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-right',
        showCloseButton: false,
        tapToDismiss: false,
        timeout: 2000
    });
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.bindDropdowns();
    }
    saveChanges() {
        let accessLog = new AccessLog();
        accessLog.UserId = this.accesslogForm.value.username;
        accessLog.BusinessId = this.accesslogForm.value.business;
        accessLog.IP = this.accesslogForm.value.ip;
        accessLog.Browser = this.accesslogForm.value.browser;
        accessLog.OS = this.accesslogForm.value.os;
        accessLog.ApplicationId = BaseServices.ApplicationId;
        let users = new User();
    
        this.subscriptions.push(this.userService.getUsersById(this.accesslogForm.value.username).subscribe((result:any) => {
            users.Name = result.Name;
            users.Username = result.Username;
            users.Email = result.Email;
            users.Status = result.Status;
            users.Language = result.LanguageId;
            users.Role = result.RoleId;
            users.CreatedOn = result.CreatedOn;
            users.CreatedBy = result.CreatedBy;
            users.ModifiedBy = result.ModifiedBy;
            users.ModifiedOn = result.ModifiedOn;
            accessLog.User = users;
        }));

        let business = new Business();
    
        this.subscriptions.push(this.businessServices.getById(this.accesslogForm.value.business).subscribe(result => {
            business.Id = result.Id;
            business.CompanyName = result.CompanyName;
            business.OrganizationNumber = result.OrganizationNumber;
            business.CompanyNumber = result.CompanyNumber;
            business.AboutBusiness = result.AboutBusiness;
            business.Zipcode = result.Zipcode;
            business.Telephone = result.Telephone;
            business.Email = result.Email;
            business.BusinessCategoryId = result.BusinessCategoryId;
            business.ApplicationId = result.ApplicationId;
            business.Status = result.Status;
            business.BusinessPortalId = result.BusinessPortalId;
            business.CreatedBy = result.CreatedBy;
            business.ModifiedBy = result.ModifiedBy;
            accessLog.Business = business;
        }));

        this.subscriptions.push(this.accessloSrvc.addAccessLog(accessLog).subscribe(result => {
            if (result) {
                this.popToast();
            }
        }));
    }
    bindDropdowns() {
        this.business = [];
        this.business.push({ label: "Select", value: null });
        this.subscriptions.push(this.accessloSrvc.getAllBusiness().subscribe(result => {
            this.cBusiness = result;
            this.cBusiness.forEach((element:any) => {
                this.business.push({ label: element.CompanyName, value: element.Id });
            });

        }));

        this.users = [];
        this.users.push({ label: "Select", value: null });
        this.subscriptions.push(this.accessloSrvc.getAllUsers().subscribe(result => {
            this.cUsers = result;
            this.cUsers.forEach((element: any) => {
                this.users.push({ label: element.Username, value: element.Id });
            });

        }));
    }
    gotoList() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}