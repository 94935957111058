<div class="table-container">
  <table class="table fixed-width-table">
    <thead class="sticky-header">
      <tr>
        <th class="col-business"><span translate>BUSINESS</span></th>
        <th><span translate>TOTAL</span></th>
        <th><span translate>MODIFIED_ON</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let risk of riskSummaries">
        <td>
          <a
            href="javascript:void(0)"
            (click)="openRiskInNewWindow(risk.BusinessId)"
          >
            {{ getBusinessName(risk.BusinessId) }}
          </a>
        </td>
        <td>
          <span>{{ risk.Count }}</span>
        </td>
        <td>
          {{ risk.LastEdited ? (risk.LastEdited | date) : "" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
