import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomizeHomePageCreationComponent } from './components/customize-homepage-creation/customize-homepage-tab.component';
import { CustomizeHomepageComponent } from './customize-homepage.component';
import {
    CustomizeHomePageResolver,
    ApplicationDefaultVideoResolver
} from './customize-homepage.resolver';
import { CustomizeHomePage } from './models/customize-homepage';
import { CustomizeHomePageService } from './customize-homepage.service';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from './../shared/appShared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        ConfirmDialogModule,
        TranslateModule,
        EditorModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        AppSharedModule,
        RouterModule
    ],
    declarations: [
        CustomizeHomePageCreationComponent,
        CustomizeHomepageComponent
    ],
    bootstrap: [CustomizeHomePageCreationComponent, CustomizeHomepageComponent],
    providers: [
        CustomizeHomePageService,
        CustomizeHomePageResolver,
        ApplicationDefaultVideoResolver
    ]
})
export class CustomizeHomepageModule {}
