import { Component, OnInit } from '@angular/core';

@Component({

    templateUrl: 'supplierNewForm.component.html'
})
export class SupplierNewComponent implements OnInit {
    constructor() { }

    ngOnInit() { document.querySelector("body").classList.remove("opened");}
}