<form [formGroup]="contactForm">
    <div class="row">
        <div class="col-md-4">
            <div class="page-title" translate>CONTACTS</div>
            <div class="form">
                <div class="form-group">
                    <label for="Type">
                        <span translate>TYPE_OF_CONTACT</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="ContactTypeId" id="Type" [options]="types"
                          [style]="{'width':'100%'}">
                            <ng-template let-contact pTemplate="item">
                                <span translate>{{contact.label | translate}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <control-messages [control]="contactForm.controls['ContactTypeId']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Name">
                        <span translate>NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" maxlength="100" pInputText formControlName="Name" id="Name" />
                    </div>
                    <control-messages [control]="contactForm.controls['Name']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Address">
                        <span translate>ADDRESS</span>
                    </label>
                    <div>
                        <textarea id="Address" name="Address" maxlength="250" pInputTextarea formControlName="Address"
                          [autoResize]="false"></textarea>
                    </div>
                    <control-messages [control]="contactForm.controls['Address']"></control-messages>
                </div>
                <div class="form-group-25_75">
                    <div class="form-group">
                        <label for="Zipcode">
                            <span translate>ZIP_CODE</span>
                        </label>
                        <div>
                            <input type="text" onpaste="return false;" pInputText formControlName="Zipcode" id="Zipcode"
                              (keypress)="onMobileNumber($event,20)" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Location">
                            <span translate>LOCATION</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="Location" maxlength="50" id="Location" />
                        </div>
                    </div>
                    <control-messages [control]="contactForm.controls['Zipcode']"></control-messages>
                    <control-messages [control]="contactForm.controls['Location']"></control-messages>
                </div>
                <div class="form-group">
                    <label>
                        <span translate>MOBILE</span>
                    </label>
                    <div class="phone-field">
                        <p-dropdown [options]="countryCodes" formControlName="MobileCode" [filter]="true"
                          [style]="{'width':'110px'}"></p-dropdown>
                        <input type="number" pInputText onpaste="return false;" formControlName="Mobile"
                          (keypress)="onMobileNumber($event,20)">
                    </div>
                </div>
                <div class="form-group-50_50">
                    <div class="form-group">
                        <label for="Telephone">
                            <span translate>TELEPHONE</span>
                        </label>
                        <div>
                            <input type="text" onpaste="return false;" pInputText formControlName="Telephone"
                              id="Telephone" (keypress)="onMobileNumber($event,20)" />
                        </div>
                    </div>
                    <control-messages [control]="contactForm.controls['Telephone']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Emailid">
                        <span translate>EMAIL</span>
                        <span class="required">*</span>
                        <control-messages [control]="contactForm.controls['Emailid']"></control-messages>
                    </label>
                    <div>
                        <input type="text" maxlength="100" pInputText formControlName="Emailid" id="Emailid" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="checkbox" for="IsSMS" *ngIf="isPortal">
                        <p-checkbox label="{{'RECEIVE_NEW_DEVIATION' | translate}}"
                          formControlName="IsEmailNewDeviation" [binary]="false"></p-checkbox>
                        <control-messages [control]="contactForm.controls['IsSMS']"></control-messages>
                    </label>
                    <label class="checkbox" for="IsEmail" *ngIf="isPortal">
                        <p-checkbox label="{{'SHOW_RESPONSIBLE_ACTIVITIES' | translate}}" [binary]="false"></p-checkbox>
                        <control-messages [control]="contactForm.controls['IsEmail']"></control-messages>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="page-title" translate>WORK_PLACE</div>
            <div class="form">
                <div class="form-group">
                    <label for="WorkPosition">
                        <span translate>POSITION</span>
                    </label>
                    <div>
                        <input type="text" pInputText maxlength="100" formControlName="WorkPosition"
                          id="WorkPosition" />
                    </div>
                    <control-messages [control]="contactForm.controls['WorkPosition']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Company">
                        <span translate>COMPANY</span>
                    </label>
                    <div>
                        <input type="text" pInputText maxlength="100" formControlName="Company" id="Company" />
                    </div>
                    <control-messages [control]="contactForm.controls['Company']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Address">
                        <span translate>ADDRESS</span>
                    </label>
                    <div>
                        <textarea id="Address" maxlength="250" name="WorkAddress" pInputTextarea
                          formControlName="WorkAddress" [autoResize]="false"></textarea>
                    </div>
                    <control-messages [control]="contactForm.controls['WorkAddress']"></control-messages>
                </div>
                <div class="form-group-25_75">
                    <div class="form-group">
                        <label for="Zip">
                            <span translate>ZIP_CODE</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="WorkZipcode" id="WorkZipcode"
                              (keypress)="onMobileNumber($event,20)" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="wLocation">
                            <span translate>LOCATION</span>
                        </label>
                        <div>
                            <input type="text" pInputText formControlName="WorkLocation" id="WorkLocation" />
                        </div>
                    </div>
                    <control-messages [control]="contactForm.controls['WorkZipcode']"></control-messages>
                    <control-messages [control]="contactForm.controls['WorkLocation']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Website">
                        <span translate>WEBSITE</span>
                    </label>
                    <div>
                        <input type="text" maxlength="100" pInputText formControlName="Website" id="Website" />
                    </div>
                    <control-messages [control]="contactForm.controls['Website']"></control-messages>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="page-title" translate>COMMENT</div>
            <div class="form">
                <div class="form-group">
                    <label for="Notes">
                        <span translate>NOTE</span>
                    </label>
                    <div>
                        <textarea id="Notes" maxlength="250" name="Notes" pInputTextarea formControlName="Notes"
                          [autoResize]="false"></textarea>
                    </div>
                    <control-messages [control]="contactForm.controls['Notes']"></control-messages>
                </div>
                <div class="form-group">
                    <label for="Type">
                        <span translate>FUNCTION</span>
                        <span class="required">*</span>
                    </label>
                    <div class="input-btn-group">
                        <p-dropdown [filter]="true" formControlName="ContactFunctionalAreaId" id="ContactFunctionalArea"
                          [options]="functions">
                            <ng-template let-contact pTemplate="item">
                                <span translate>{{contact.label}}</span>
                            </ng-template>
                        </p-dropdown>
                        <button class="btn btn-outline-primary" type="button" (click)="addFunction = !addFunction">
                            <i class="pi pi-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                    <control-messages [control]="contactForm.controls['ContactFunctionalArea']"></control-messages>
                </div>
                <div class="form-group">
                    <label class="checkbox" for="ShowInBusiness">
                        <p-checkbox label="{{'SHOW_BUSINESS' | translate}}" formControlName="ShowInBusiness"
                          [binary]="false"></p-checkbox>
                        <control-messages [control]="contactForm.controls['ShowInBusiness']"></control-messages>
                    </label>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="action-btns-wrapper" *ngIf="isEdit">
    <button class="btn btn-outline-secondary" (click)="gotoList()">
        <span class="icon ic-xs icon-back"></span>
        <span translate>BACK_LIST</span>
    </button>
    <button type="button" class="btn btn-primary" [disabled]="!contactForm.valid" (click)="submitForm()">
        <span translate>SAVE</span>
    </button>
</div>
<p-dialog header="{{'FUNCTION' | translate}}" (onHide)="onFunctionkDialogHide()" [(visible)]="addFunction" [style]="{width: '300px'}"
 [resizable]="false" [draggable]="false">
    <form [formGroup]="functionForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>NAME</span>
            </label>
            <div>
                <input type="text" #title class="label-input form-control" [(ngModel)]="selectedFunction.Name"
                  formControlName="Name">
                <span style="color:red" *ngIf="isfunctionExists">{{functionExisting}}</span>
                <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
            </div>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="viewFunction = !viewFunction">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="!functionForm.valid" (click)="saveFunction()">
                <span translate>SAVE</span>
            </button>
        </div>
        <div *ngIf="viewFunction">
            <div class="table-view">
                <!-- Table starts -->
                <p-table [value]="editFunction" [rows]="10" [paginator]="true" [pageLinks]="3"
                  [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'FUNCTION_NAME' | translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-categoryFunction>
                        <tr>
                            <td>
                                <span>{{categoryFunction.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT' | translate}}"
                                  (click)="editFunctions(categoryFunction)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE' | translate}}"
                                  (click)="deleteFunction(categoryFunction.Id)" *ngIf="!isPortal">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table ends -->
            </div>
        </div>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>