
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import {
    ContactTypeResolver,
    AdminContactsResolver
} from './../contact/services/contact.resolver.service';
import { ContactServices } from './../contact/services/contact.service';
import { KubaNorgeAsComponent } from './components/kuba-norge-as.component';
import { NewEditorContactComponent } from './components/new-editorcontacts.component';
import {
    EditorContactListResolver,
    EditorContactsEditResolver,
    FunctionResolver,
    EditorContactListByBusinessResolver,
    KubaNorgeAsResolver,
    ContactBusinessDataResolver
} from './services/editorcontacts.services.resolver';
import { EditorContactsServices } from './services/editorcontacts.services';
import { EditorContactListComponent } from './components/editorcontact-list.component';
import { EditorContactsComponent } from './editorcontacts.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppSharedModule,
        RouterModule,
        TranslateModule,
        KubaExportModule
    ],
    exports: [
        EditorContactsComponent,
        EditorContactListComponent,
        KubaNorgeAsComponent,
        NewEditorContactComponent
    ],
    declarations: [
        EditorContactsComponent,
        EditorContactListComponent,
        KubaNorgeAsComponent,
        NewEditorContactComponent
    ],
    providers: [
        EditorContactsServices,
        EditorContactListResolver,
        EditorContactsEditResolver,
        FunctionResolver,
        ContactTypeResolver,
        ContactServices,
        EditorContactListByBusinessResolver,
        AdminContactsResolver,
        KubaNorgeAsResolver,
        ContactBusinessDataResolver
    ]
})
export class EditorContactsModule { }
