
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Rights } from 'src/app/_models/feature';

@Component({
    selector: 'foodsafety-header',
    templateUrl: 'foodsafety-header.component.html'
})

export class FoodHeaderListComponent implements OnInit {
    isFSRights = true;
    hideFSRights = true;

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        /*
         * get InternalControl list
         */
        this.router.navigate(['all'], { relativeTo: this.route })
        if (+BaseServices.roleId === 4) {
            this.isFSRights = false;
            this.router.navigate(['own'], { relativeTo: this.route })
        } else if (+BaseServices.roleId === 5) {
            this.onFoodSafetyRights();
        }else {
            this.OnAdmin()
            
        }
    }
    onFoodSafetyRights() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_FOOD_SAFETY;
        this.isFSRights = BaseServices.checkUserRights(userRightsId, currentUserRole);
        if (this.isFSRights) {
            this.router.navigate(['all'], { relativeTo: this.route })
        } else {
            this.router.navigate(['own'], { relativeTo: this.route })
        }
    }

    OnAdmin(){
        let curSession = JSON.parse(sessionStorage.getItem('RModeBusinessId'));
        if(curSession > 0)
        {
            this.isFSRights=true;
            this.hideFSRights = false;
            this.router.navigate(['all'], { relativeTo: this.route  });
        }
        else{
            this.isFSRights = true;
            this.router.navigate(['all'], { relativeTo: this.route  });
        }
    }
}
