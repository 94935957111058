<features [featureBase]="featureBase"></features>
<div class="action-btns-wrapper">
    <button *ngIf="!isPortal" class="btn btn-outline-secondary" (click)="gotoList()">
        <span class="icon ic-sm icon-back"></span>
        <span translate>BACK_LIST</span>
    </button>
    <button *ngIf="isPortal" class="btn btn-outline-secondary" (click)="goBackLocation()">
        <span class="icon ic-sm icon-back"></span>
        <span translate>BACK</span>
    </button>
    <button *ngIf="hideNewButton" type="button" class="btn btn-primary" (click)="saveFeature()">
        <span translate>SAVE</span>
    </button>
</div>
<toaster-component></toaster-component>