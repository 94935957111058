import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BusinessServices } from './businesses/services/business.services';

import { KubaServices } from './kuba.services';
import { BaseServices } from './kuba.services';
import { BusinessPortalService } from './portals/services/business-portal.services';

@Injectable()
export class AllLanguagesResolver implements Resolve<any> {

  constructor(private backend: KubaServices) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getAllLanguages();
  }
}

@Injectable()
export class UserLanguageResolver implements Resolve<any> {

  constructor(private backend: KubaServices) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUserCustomSetting();
  }
}


@Injectable()
export class UserBaseResolver implements Resolve<any> {

  constructor(private backend: KubaServices) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUserBase();
  }
}

@Injectable()
export class UserFeaturesResolver implements Resolve<any> {

  constructor(private backend: KubaServices) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUserFeatures();
  }
}


@Injectable()
export class UserNotificationResolver implements Resolve<any> {

  constructor(private backend: KubaServices) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.backend.getUserNotifications();
  }
}

@Injectable()
export class FeaturesResolver implements Resolve<any[]> {
  constructor(
    public businessServices: BusinessServices,
    public businessPortalService: BusinessPortalService
    ) { }
  resolve(route: ActivatedRouteSnapshot) {
    if (BaseServices.roleId === '1') {
      return BaseServices.userFeatures;
    } else if (BaseServices.roleId === '4') {
      return this.businessPortalService.getPortalFeatures(BaseServices.PortalId);
    } else {
      
      return this.businessServices.getBusinessForFeature(BaseServices.BusinessId);
    }
  }
}
