export class ServiceAgreement {
    Id: number;
    ContractName: string;
    ContractNumber: number;
    Status: string;
    BusinessPropertyId: number;
    CreatedOn: Date;
    ClientId: number;
    ContractImage: string;
    CreatedBy: number;
    ModifiedBy: number;
    BusinessId: number;
    Comments: string;
    CreatedName: string;
    ModifierName: string;
    Client: String;
    ShowDeviation?: boolean;
    BusinessPropertyDto: BusinessProperty;
    ServiceUsers: ServiceUsers[];
    ServiceClientUsers: ServiceClientUsers[];
    ServiceAgreementDocument: ServiceAgreementDocumentDto[];
    SAEmailSubscribers: number[];
}

export class ServiceAgreementDocumentDto {
    Id: number;
    ServiceAgreementId: number;
    CourseId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Path: string;
    FileType: number;
    Status: string;
}

export class ServiceClientUsers {
    ServiceId: number;
    ClientId: number;
    ClientUserId: number;
    Name: string;
    Id: number;
}

export class ServiceUsers {
    ServiceId: number;
    UserId: number;
    Name: string;
    IsEmail:boolean;
}
export class User {
    ContactPersonId: number;
    Name: string;
    RoleId: Number;
    BusinessId: Number;
}

export class BusinessProperty {
    Id: number;
    BusinessId: number;
    BusinessPropertyTypeId: number;
    Name: string;
    Number: string;
    FloorCount: number;
    ApartmentCount: number;
    Address: string;
    Status: string;
    City: string;
    Zip: string;
    Location: string;
    Latitude: string;
    Longitude: string;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: string;
}

export class ServiceClientUsersDetail {
    Name: string;
    constructor() {
        this.Name = '';
    }
}
export class ServiceAgreementDetail {
    ContractNumber: string;
    ContractName: string;
    StatusText: string;
    ContractImage: string;
    Comments: string;
    ServiceClientUsers: ServiceClientUsersDetail[];
    constructor() {
        this.ContractNumber = '';
        this.ContractName = '';
        this.StatusText = '';
        this.ContractImage = '';
        this.Comments = '';
        this.ServiceClientUsers = [];
    }
}
