import { Component, OnInit } from '@angular/core';
@Component({ 
    template: `<router-outlet></router-outlet>`
})
export class ProjectComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
    }
}