import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
@Component({ 
    template: `<notes></notes>`
})
export class NotesComponent implements OnInit {
    constructor(private http: HttpClient) { }

    ngOnInit(): void {
        
    }
}