import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LicenseAgreementServices } from './license-agreement.service';


@Injectable()
export class BusinessListResolver implements Resolve<any[]> {
  constructor(private backend: LicenseAgreementServices) { }
  resolve(): Observable<any[]> {
    return this.backend.getBusinessLicenseAgreementList();
  }
}

@Injectable()
export class BusinessFeatureListResolver implements Resolve<any[]> {
  constructor(private backend: LicenseAgreementServices) { }
  resolve(): Observable<any[]> {
    return this.backend.getBusinessFeatureLicenseAgreementList();
  }
}

