import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroSettings } from 'src/app/kuba/electro/models/electrocontract';
import { ElectroService } from 'src/app/kuba/electro/services/electro.services';
import { ActivatedRoute } from '@angular/router';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inspection-report-settings',
  templateUrl: './inspection-report-settings.component.html',
})
export class InspectionReportSettingsComponent implements OnInit {
  uploadStart: boolean;
  selectedFiles: any;
  avatarName: string;
  insertStandardtext = "";
  saveData = new ElectroSettings;
  data: any;
  private subscriptions: Subscription[] = [];
  @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
  constructor(private http: HttpClient, private electroServices: ElectroService,
    private route: ActivatedRoute,
    private translate: TranslateService) { }

  ngOnInit() {
    let set = new ElectroSettings();
    set = this.route.snapshot.data['setting'];
    
    if (set) {
      this.saveData = set;
    }

  }
  /**
      * upload for employee image
      * @param selectedFiles
      */
  save() {
    this.saveData.BusinessId = BaseServices.BusinessId;
    this.saveData.ApplicationId = BaseServices.ApplicationId;
    this.saveData.InspectionComment;
    this.saveData.CreatedBy = BaseServices.UserId;
    this.subscriptions.push(this.electroServices.saveInspectionReportSettings(this.saveData).subscribe(savedSettings => {
      if(savedSettings){
        this.toasterComponent.callToast();
      }
    }));

  }


  /**
   * insert standard event.
   */
  insertStandards() {
    this.saveData.InspectionComment = this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P1') + '\n\n'
    + this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P2') + '\n\n'
    + this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P3') + '\n\n'
    + this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P4') + '\n\n'
    + this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P5') + '\n\n'
    + this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P6') + '\n\n'
    + this.translate.instant('INSPECTION_REPORT_STANDARD_TEXT_P7');
  }

  /**
   * file upload save event.
   * @param selectedFiles 
   */
  filesSelect(selectedFiles: any): void {
    this.uploadStart = true;
    this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
    let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);

    this.subscriptions.push(this.http
      .post(
        environment.BASE_URL +
        '/electro/file/upload/inspection-settings-image/' +
        BaseServices.ApplicationId +
        '/' +
        BaseServices.BusinessId,
        formData
      )
      .map((response: any) => {
        this.uploadStart = false;
        let res = response;
        this.saveData.InspectionImage = this.avatarName = res.Result.path;
      })
      .subscribe(
        result => {
          result;
        },

        error => console.log('Upload article Sign Error : ', error),
        () => console.log('Upload article  Sign Complete')
      ));
  }


  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }


}
