import { UserRole } from './../../follow-ups/models/deviation';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { EmployeeServices } from './../services/employee.services';
import { HelperService } from './../../../_services/helper.service';

import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { EmployeePrivateDocumentServices } from './../services/employeeprivatedocument.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { FileUploadService } from 'src/app/_services/fileUpload.service';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import {
  EmployeeDocumentNode,
  EmployeeDocumentUploadable,
} from 'src/app/kuba/employees/models/employeeDocument';
import { FeatureBase } from 'src/app/kuba/features/models/features-base.model';
import { FeatureKey } from 'src/app/_models';
import { elementAt } from 'rxjs/operator/elementAt';
import { SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'private-doc',
  templateUrl: 'employee-privatedocument.component.html',
})
export class PrivateDocumentComponent implements OnInit {
  @ViewChild(ToasterComponent, { static: false })
  toasterComponent: ToasterComponent;
  //#region variables
  additionalData: any;
  loading = false;
  upConfig: UploaderConfig;
  upConfigApp: UploaderConfig;
  UploadableFile: EmployeeDocumentUploadable;
  newDocumentAdded: any = [];
  uploadedFiles: any = [];
  employeeId: number;
  docId: number;
  isShowDelete: boolean;
  editMode = false;
  showSavingLoader = false;
  isEditor = false;
  isUser = false;
  featureId: any;
  selectedArticles: any = [];
  selectedFiles: any;
  isMultiDocument = false;
  employees: any = [];
  emp: any = [];
  rowID: number = null!;
  isCollapsed = false;
  employeeForm: FormGroup;
  selectedEmployees: SelectItem[];
  selectedEmployeesForApproval: any = [];
  files: any = [];
  selectedDocumentsForApprovalNotice: any = [];
  type: any;
  all = false;
  title: any = 'APPROVAL_DOCUMENTS';
  typeList: any = [];
  originalEmp: any = [];
  private subscriptions: Subscription[] = [];
  safeURL: any;
  isVideo = false;
  //#endregion

  //#region constructor
  /**
   *  constructor
   * @param route {ActivatedRoute}
   * @param fileUploadService {FileUploadService}
   * @param location {Location}
   * @param privateDocSvc {EmployeePrivateDocumentServices}
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private location: Location,
    private employeePrivateDocumentServices: EmployeePrivateDocumentServices,
    private employeeServices: EmployeeServices,
    private translate: TranslateService,
    private _sanitizer: DomSanitizer
  ) {
    //#endregion
    this.employeeId = route.snapshot.params['eid'];
    this.type = route.snapshot.params['mId'];
    if (this.type == 1) {
      this.title = 'APPROVAL_DOCUMENTS';
      this.isMultiDocument = true;
      this.subscriptions.push(
        this.employeeServices
          .list(BaseServices.BusinessId)
          .subscribe((result) => {
            if (result) {
              result.forEach((r: any) => {
                this.employees.push({
                  label: r.Name,
                  value: r.Id,
                });
              });
            }
          })
      );
      this.emp = this.route.snapshot.data['applist'];
      this.originalEmp = this.route.snapshot.data['applist'];
      this.subscriptions.push(
        this.translate.stream('SELECT_DROPDOWN').subscribe((val) => {
          this.typeList = [];
          this.typeList.push({ label: val.SELECT, value: null });
          var types = this.emp
            .map((item: any) => item.TypeOfEmployee)
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            );
          types.forEach((element: any) => {
            this.typeList.push({ label: element, value: element });
          });
        })
      );
    }

    if (BaseServices.roleId == '3') {
      if (BaseServices.userBase.UserFeatureKey == this.employeeId)
        this.isUser = true;
      else this.isEditor = true;
    }
    if (
      (BaseServices.roleId == '4' || BaseServices.roleId == '5') &&
      BaseServices.userBase.UserFeatureKey == this.employeeId
    ) {
      this.isUser = true;
    }
    // TODO: need to send dynamic values
    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      EmployeeId: this.employeeId,
      IsPrivateDocument: true,
      Status: '1',
      CreatedBy: BaseServices.UserId,
      CreatedOn: '',
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: '',
      IsDocumentedTraining: false,
    };
    this.upConfig = {
      title: 'PRIVATE_DOCUMENTS',
      viewSwitch: true,
      IsPrivateUploadButton: true,
      showDeleteButton: false,
      editMode: this.editMode,
      windowedHeight: false,
      showUserMeta: false,
      showSearchFilter: false,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/employee/private-docs',
      addtionalData: this.additionalData,
      isEditablePrivateDocument: true,
      pdEmpId: this.employeeId,
    };
    this.upConfigApp = {
      title: null!,
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      IsPrivateUploadButton: true,
      showDeleteButton: true,
      uploaderUri:
        environment.BASE_URL + '/file/upload/business/employee/private-docs',
      addtionalData: this.additionalData,
      pdEmpId: this.employeeId,
    };
    
  }
  /**
   * file upload events
   * @param event {any}
   */
  isFileSeclected() {
    var selected = this.uploadedFiles.filter((x: any) => x.selected == true);
    if (selected.length > 0) return false;
    else return true;
  }

  setCollapse(i: number): boolean {
    let isCollapsed = i === this.rowID;
    return !isCollapsed;
  }

  toggleClick(i: number): void {
    this.rowID = i;
    this.isCollapsed = !this.isCollapsed;
  }

  toggleClickHide(i: number): void {
    this.rowID = null!;
  }
  saveUploaded(event: any) {
    if (event) {
      let eventType = event.eventName;
      switch (eventType) {
        case 'DeleteDefaultFile':
          if (event.item.data.id === 0) {
            // remove unsaved attachment
            this.newDocumentAdded = this.newDocumentAdded.filter(
              (x: any) => x.Path !== event.item.data.path
            );
            this.uploadedFiles = this.uploadedFiles.filter(
              (x: any) => x.path !== event.item.data.path
            );
            this.toasterComponent.callToastDlt();
          } else {
            // remove existing attachement
            this.subscriptions.push(
              this.employeeServices
                .deleteEmployeeDocument(event.item.data.id)
                .subscribe((result: any) => {
                  if (result) {
                    this.newDocumentAdded = this.newDocumentAdded.filter(
                      (x: any) => x.Path !== event.item.data.path
                    );
                    // remove from display list
                    this.uploadedFiles = this.uploadedFiles.filter(
                      (x: any) => x.path !== event.item.data.path
                    );
                    this.toasterComponent.callToastDlt();
                  }
                })
            );
          }
          break;
        case 'remove':
          this.uploadedFiles = this.uploadedFiles.filter(
            (x: any) => x.id !== +event.id
          );
          break;
        case 'ShowOfficeFileSaveloader':
          this.subscriptions.push(
            this.employeePrivateDocumentServices
              .updateDoc(event.item.id, this.employeeId, false)
              .subscribe((res) => {
                this.docId = event.item.id;
                if (res) {
                  this.subscriptions.push(
                    this.employeePrivateDocumentServices
                      .list(this.employeeId, 1)
                      .subscribe((result) => {
                        if (result.length > 0) {
                          this.uploadedFiles = result;
                          this.Approve(1);
                        }
                      })
                  );
                }
              })
          );
          if (parseInt(sessionStorage.getItem('isDocumentChanged')!, null!)) {
            this.showSavingLoader = true;
            setTimeout(() => {
              this.showSavingLoader = false;
              this.newDocumentAdded = [];
            }, 15000);
            sessionStorage.setItem('isDocumentChanged', '0');
          }
          break;
        default:
          let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType',
          };
          let privateDoc = Object.assign({}, event, this.additionalData);
          privateDoc.id = 0;
          privateDoc.status = '1';
          privateDoc.createdBy = BaseServices.UserId;
          this.newDocumentAdded.push(
            HelperService.switchKeysCase(privateDoc, keyBindings)
          );

          break;
      }
    }
  }

  saveDoc() {
    if (this.isMultiDocument == true) {
      if (
        this.newDocumentAdded.length > 0 &&
        this.selectedEmployeesForApproval.length > 0
      ) {
        this.loading = true;
        this.newDocumentAdded.forEach((element: any) => {
          this.selectedEmployeesForApproval.forEach((x: any) => {
            var tempDoc: any = {};
            tempDoc.BusinessId = element.BusinessId;
            tempDoc.CreatedBy = element.CreatedBy;
            tempDoc.CreatedOn = element.CreatedOn;
            tempDoc.EmployeeId = x.EmployeeId;
            tempDoc.Extension = element.Extension;
            tempDoc.FileType = element.FileType;
            tempDoc.Filename = element.Filename;
            tempDoc.Id = element.Id;
            tempDoc.IsPrivateDocument = element.IsPrivateDocument;
            tempDoc.Mimetype = element.Mimetype;
            tempDoc.ModifiedBy = element.ModifiedBy;
            tempDoc.ModifiedOn = element.ModifiedOn;
            tempDoc.OriginalFileName = element.OriginalFileName;
            tempDoc.Path = element.Path;
            tempDoc.Size = element.Size;
            tempDoc.Status = element.Status;
            tempDoc.IsDocumentedTraining = false;
            this.files.push(tempDoc);
          });
        });
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .saveDocs(this.files, this.employeeId)
            .subscribe((result) => {
              if (result) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.newDocumentAdded = [];
                this.getApprovalDoc();
                this.selectedEmployeesForApproval = [];
                this.uploadedFiles = [];
                this.files = [];
              }
            })
        );
      }
    } else {
      if (this.newDocumentAdded.length > 0) {
        this.loading = true;
        this.newDocumentAdded.forEach((X: any) => {
          X.IsDocumentedTraining = false;
        });
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .save(this.newDocumentAdded, this.employeeId)
            .subscribe((result) => {
              if (result) {
                this.toasterComponent.callToast();
                this.loading = false;
                this.newDocumentAdded = [];
                this.getDoc();
              }
            })
        );
      }
    }
  }

  getApprovalDoc() {
    this.employeePrivateDocumentServices
      .getApprovalDocuments(
        this.employeeId,
        BaseServices.BusinessId,
        BaseServices.PortalId,
        1
      )
      .subscribe((result) => {
        this.emp = result;
        this.originalEmp = result;
        this.subscriptions.push(
          this.translate.stream('SELECT').subscribe((val) => {
            this.typeList = [];
            this.typeList.push({ label: val.SELECT, value: null });
            var types = this.emp
              .map((item: any) => item.TypeOfEmployee)
              .filter(
                (value: any, index: any, self: any) =>
                  self.indexOf(value) === index
              );
            types.forEach((element: any) => {
              this.typeList.push({ label: element, value: element });
            });
          })
        );
      });
  }
  //#endregion

  //#region page-events
  /**
   * page initialization
   */
  ngOnInit() {
    document.querySelector('body').classList.remove('opened');
    let uploadfiles = this.route.snapshot.data['list'];
    if (uploadfiles && uploadfiles.length > 0) {
      this.uploadedFiles = uploadfiles;
    }
  }

  ngOnChanges() {
    this.selectedFiles = this.uploadedFiles.filter(
      (x: any) => x.selected == true
    );
  }

  getDoc() {
    this.subscriptions.push(
      this.employeePrivateDocumentServices
        .list(this.employeeId, 1)
        .subscribe((result) => {
          this.uploadedFiles = result;
        })
    );
  }
  getApprovalDocuments() {
    this.loading = true;
    this.employeePrivateDocumentServices
      .getApprovalDocuments(
        this.employeeId,
        BaseServices.BusinessId,
        BaseServices.PortalId,
        1
      )
      .subscribe((result) => {
        this.emp = result;
        this.originalEmp = result;
        this.subscriptions.push(
          this.translate.stream('SELECT').subscribe((val) => {
            this.typeList = [];
            this.typeList.push({ label: val.SELECT, value: null });
            var types = this.emp
              .map((item: any) => item.TypeOfEmployee)
              .filter(
                (value: any, index: any, self: any) =>
                  self.indexOf(value) === index
              );
            types.forEach((element: any) => {
              this.typeList.push({ label: element, value: element });
            });
          })
        );
        this.loading = false;
      });
  }

  toggleEditMode(mode: any) {
    this.editMode = mode;
    this.upConfig.editMode = mode;
    this.upConfig.showDeleteButton = mode;
  }

  onTableHeaderCheckboxToggle() {
    this.selectedEmployeesForApproval = this.emp;
  }

  Approve(isSendApproval: number) {
    this.saveDoc();
    if (this.type == 1) {
      this.loading = true;
      this.selectedDocumentsForApprovalNotice =
        this.selectedEmployeesForApproval
          .filter((x: any) => x.TotalDocumentsForApproval > 0)
          .map((ele: any) => ele.EmployeeDocuments);
      var merged = [].concat.apply([], this.selectedDocumentsForApprovalNotice);
      if (this.selectedDocumentsForApprovalNotice.length > 0) {
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .update(
              merged,
              3,
              BaseServices.UserFeatureKey,
              FeatureKey.APPROVAL_DOCUMENTS,
              1
            )
            .subscribe((result) => {
              if (result) {
                if (isSendApproval == 1) {
                  this.toasterComponent.callToastApprovalNotice();
                } else {
                  this.toasterComponent.callToastApprovedNotice();
                }
                this.getApprovalDoc();
                this.loading = false;
                this.selectedEmployeesForApproval = [];
              }
            })
        );
      } else {
        this.loading = false;
        this.getApprovalDoc();
        this.selectedEmployeesForApproval = [];
      }
    } else {
      this.loading = true;
      if (this.docId != null) {
        this.selectedFiles = this.uploadedFiles.filter(
          (x: any) => x.id == this.docId
        );
      } else {
        this.selectedFiles = this.uploadedFiles.filter(
          (x: any) => x.selected == true
        );
      }
      if (this.selectedFiles.length > 0) {
        this.subscriptions.push(
          this.employeePrivateDocumentServices
            .update(
              this.selectedFiles,
              isSendApproval,
              this.employeeId,
              FeatureKey.APPROVAL_DOCUMENTS,
              1
            )
            .subscribe((result) => {
              if (result) {
                if (isSendApproval == 1) {
                  this.toasterComponent.callToastApprovalNotice();
                } else {
                  this.toasterComponent.callToastApprovedNotice();
                }
                this.loading = false;
                this.getDoc();
              }
            })
        );
      } else {
        this.loading = false;
      }
    }
  }

  choose(event: any) {}

  getFileExtension(filename: any) {
    let fileExtension = null;
    if (filename != null) {
      fileExtension = filename.substr(filename.lastIndexOf('.') + 1);
    }
    return fileExtension.toLowerCase();
  }
  checkIfAllSelected() {
    this.all = !this.all;
  }
  filterResults(data: any) {
    if (data == null || data == '') {
      this.emp = this.originalEmp;
    } else {
      var arr: any = [];
      this.originalEmp.forEach((x: any) => {
        var filter = [];
        if (x.EmployeeDocuments != null && x.EmployeeDocuments.length > 0) {
          x.EmployeeDocuments.forEach((ele: any) => {
            if (
              ele.Filename != null &&
              ele.Filename.toLowerCase().includes(data.toLowerCase())
            )
              filter.push(ele);
          });
          if (filter.length > 0) arr.push(x);
        }
      });
      this.emp = arr;
    }
  }

  deleteDoc(Id: any) {
    this.loading = true;
    this.employeePrivateDocumentServices
      .delete(this.employeeId, Number.parseInt(Id))
      .subscribe((res) => {
        this.loading = false;
        this.toasterComponent.callToastDlt();
        this.selectedArticles = [];
        this.getApprovalDocuments();
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
  //#endregion
}
