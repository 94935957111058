import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BusinessSignedService } from 'src/app/kuba/businesses/services/businessSigned.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class BusinessSignedListResolver implements Resolve<any[]> {
    constructor(private backend: BusinessSignedService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getAllUsers(BaseServices.BusinessId);
    }
}
