<div class="main-content">
    <div class="main-heading">
        <strong>
            <span translate>NEW_VEHICLE</span>
        </strong>
    </div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="vehicleForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>
                                <span translate>DEPARTMENT</span>
                            </label>
                            <div class="input-btn-group">
                                <p-dropdown [options]="departments" type="text"
                                  formControlName="BusinessDepartmentId"></p-dropdown>
                                <button class="btn btn-outline-primary" type="button" (click)="addDept = !addDept">
                                    <i class="pi pi-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                <span translate>VEHICLE_NAME</span>
                            </label>
                            <div>
                                <input type="text" maxlength="100" formControlName="VehicleName" class="form-control">
                            </div>
                        </div>
                        <div class="form-group" *ngIf="vehicleForm.controls['VehicleBrandId']">
                            <label for="Brand">
                                <span translate>BRAND</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="text" maxlength="100" required formControlName="VehicleBrandId"
                                  class="form-control" />
                            </div>
                            <control-messages [control]="vehicleForm.controls['VehicleBrandId']"></control-messages>
                        </div>
                        <div class="form-group" *ngIf="vehicleForm.controls['VehicleModelId']">
                            <label for="Model">
                                <span translate>MODEL</span>
                                <span class="required">*</span>
                            </label>
                            <div>
                                <input type="text" maxlength="100" required formControlName="VehicleModelId"
                                  class="form-control" />
                            </div>
                            <control-messages [control]="vehicleForm.controls['VehicleModelId']"></control-messages>
                        </div>
                        <div class="form-group-50_50">
                            <div class="form-group" *ngIf="vehicleForm.controls['RegistrationNo']">
                                <label for="RegistrationNumber">
                                    <span translate>REGISTRATION_NUMBER</span>
                                    <span class="required">*</span>
                                </label>
                                <div>
                                    <input type="text" maxlength="100" formControlName="RegistrationNo" required
                                      class="form-control" />
                                </div>
                                <control-messages [control]="vehicleForm.controls['RegistrationNo']"></control-messages>
                            </div>
                            <div class="form-group">
                                <label for="RegistrationYear">
                                    <span translate>REGISTRATION_YEAR</span>
                                </label>
                                <div>
                                    <p-dropdown [options]="Year" formControlName="RegistrationYear"
                                      [style]="{'width':'100%'}">
                                    </p-dropdown>
                                </div>
                                <control-messages
                                  [control]="vehicleForm.controls['RegistrationYear']"></control-messages>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="vehicleForm.controls['VehicleTypeId']">
                            <label for="lblEndTime">
                                <span translate>TYPE_OF_VEHICLE</span>
                            </label>
                            <div>
                                <p-dropdown [options]="vehicleType | dropdownSelectTranslate" type="text"
                                  formControlName="VehicleTypeId" [style]="{'width':'100%'}"></p-dropdown>
                            </div>
                        </div>
                        <div class="form-group-50_50">
                            <div class="checkbox">
                                <p-checkbox label="{{'DO_NOT_DISPLAY_DEVIATION'|translate}}"
                                  formControlName="IsShowDeviationChecked" binary="true"></p-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="sub-panel-title">
                                <span translate>ASSIGN_ACCESS</span>
                            </div>
                            <!-- Table starts -->
                            <p-table #dt [value]="vehicleUsers" dataKey="Id" scrollable="true" scrollHeight="200px">
                                <!-- Header -->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>
                                            <p-checkbox [ngModel]="checkedAll" (ngModelChange)="checkAll($event)"
                                              [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                                        </th>
                                        <th>{{'Name' | translate}}</th>
                                    </tr>
                                </ng-template>
                                <!-- Body Content -->
                                <ng-template pTemplate="body" let-object>
                                    <tr>
                                        <td>
                                            <p-checkbox [(ngModel)]="object.Id === userId || object.status"
                                              [disabled]="object.Id === userId" (ngModelChange)="checked(object)"
                                              [ngModelOptions]="{standalone: true}" binary="true"></p-checkbox>
                                        </td>
                                        <td>
                                            <span>{{object.Name}}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <!-- Table Ends -->
                        </div>
                        <div class="form-group">
                            <label for="EmailDevaition">
                                <span translate>EMAIL_NEWDEVIATION_LABEL</span>
                            </label>
                            <div *ngIf="bindSelectedUsers">
                                <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [options]="userSubscribers"
                                  formControlName="selectedEmailSubscriber" [style]="{'width':'100%'}">
                                </p-multiSelect>
                            </div>
                            <div *ngIf="bindAllUsers">
                                <p-multiSelect defaultLabel="{{'SELECT'|translate}}" [dataKey]="'Id'"
                                  [options]="selectedSubscribers" formControlName="selectAllEmailSubscriber"
                                  [style]="{'width':'100%'}">
                                </p-multiSelect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action-btns-wrapper">
                    <button class="btn btn-outline-secondary" type="button" (click)="gotoList()">
                        <span class="icon ic-xs icon-back"></span>
                        <span translate>BACK_LIST</span>
                    </button>
                    <button class="btn btn-primary" type="submit" (click)="saveVehicle()"
                      [disabled]="!vehicleForm.valid">
                        <span translate>SAVE</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<p-dialog header="Department" (onHide)="onHideDeptForm()" [(visible)]="addDept" [modal]="true" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="deptForm">
        <div class="form-group">
            <label class="col-form-label">
                <span translate>NAME</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" class="label-input form-control" formControlName="Name">
            </div>
            <div class="error-msg" *ngIf="isNameExists">{{existsAlerttext}}</div>
            <div class="error-msg" *ngIf="isDeparmentExists">{{departmentExists}}</div>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-primary" (click)="saveDept()" [disabled]="!deptForm.valid">
                    <span translate>SAVE</span>
                </button>
                <button type="button" class="btn btn-outline-primary" (click)="showDept = !showDept"
                  (click)="viewDept()">
                    <span translate>VIEW</span>
                </button>
            </div>
            <div *ngIf="showDept">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #et [value]="editDepartments" [rows]="5" [paginator]="true" [pageLinks]="3"
                      [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'DEPARTMENT_NAME'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>
                                    <input pInputText type="text"
                                      (input)="et.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                      class="p-column-filter">
                                </th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-dept>
                            <tr>
                                <td>
                                    <span>{{dept.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" (click)="editDept(dept)" class="btn btn-icon"
                                      title="{{'EDIT'|translate}}">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                      (click)="deleteDept(dept)">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table Ends -->
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>
<p-dialog header="{{'BRAND'|translate}}" [(visible)]="addBrand" (onHide)="onHideBrandForm()" [modal]="true" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="brandForm">
        <div class="col-md-12">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="title" class="col-form-label">
                        <span translate>NAME</span>
                        <span class="required">*</span>
                    </label>
                </div>
            </div>
            <div class="col-md-8">
                <input type="text" #Title class="label-input form-control" formControlName="Name">
                <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
                <span style="color:red" *ngIf="isBrandExists">{{brandExists}}</span>
            </div>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-primary btn-md" (click)="saveBrand()"
                  [disabled]="!brandForm.valid">
                    <span translate>SAVE</span>
                </button>
                <button type="button" class="btn btn-outline-primary" (click)="showBrand = !showBrand"
                  (click)="viewBrand()">
                    <span translate>VIEW</span>
                </button>
            </div>
            <div *ngIf="showBrand">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #ft [value]="editBrandType" [rows]="5" [paginator]="true" [pageLinks]="3"
                      [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'BRAND_NAME'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>
                                    <input pInputText type="text"
                                      (input)="ft.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                      class="p-column-filter">
                                </th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-brand>
                            <tr>
                                <td>
                                    <span>{{brand.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                      (click)="editBrand(brand)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                      (click)="deleteBrand(brand)">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table Ends -->
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>
<p-dialog header="{{'TYPE_OF_VEHICLE'|translate}}" (onHide)="onHideTypeForm()" [(visible)]="addVehicle" [modal]="true"
  width="300" [responsive]="true">
    <form [formGroup]="typeOfVehicleForm">
        <div class="col-md-12">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="title" class="col-form-label">
                        <span translate>TYPE_OF_VEHICLE</span>
                        <span class="required">*</span>
                    </label>
                </div>
            </div>
            <div class="col-md-8">
                <input type="text" formControlName="Name" class="label-input form-control"
                  [(ngModel)]="selectedTypeofVehicle.Name">
                <span style="color:red" *ngIf="isNameExists">{{existsAlerttext}}</span>
                <span style="color:red" *ngIf="isTypeExists">{{typeExists}}</span>
            </div>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                <button type="button" class="btn btn-primary" (click)="saveTypeOfVehicle()"
                  [disabled]="!typeOfVehicleForm.valid">
                    <span translate>SAVE</span>
                </button>
                <button type="button" class="btn btn-outline-primary" (click)="viewVehicle = !viewVehicle"
                  (click)="viewTypeOfVehicle()">
                    <span translate>VIEW</span>
                </button>
            </div>
            <div *ngIf="viewVehicle">
                <div class="table-view">
                    <!-- Table starts -->
                    <p-table #ct [value]="editVehicleType" [rows]="5" [paginator]="true" [pageLinks]="3"
                      [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                        <!-- Header -->
                        <ng-template pTemplate="header">
                            <tr>
                                <th>{{'TYPE_NAME'|translate}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>
                                    <input pInputText type="text"
                                      (input)="ct.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                                      class="p-column-filter">
                                </th>
                            </tr>
                        </ng-template>
                        <!-- Body Content -->
                        <ng-template pTemplate="body" let-Type>
                            <tr>
                                <td>
                                    <span>{{Type.Name}}</span>
                                </td>
                                <td class="col-button">
                                    <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                      (click)="editTypeOfVehicle(Type)">
                                        <i class="icon ic-sm icon-pencil-tip"></i>
                                    </button>
                                    <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                      (click)="deleteTypeOfVehicle(Type)">
                                        <i class="icon ic-sm icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Empty message -->
                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr>
                                <td colspan="2">
                                    {{'NO_RECORDS_FOUND'|translate}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!-- Table Ends -->
                </div>
            </div>
        </p-footer>
    </form>
</p-dialog>

<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>