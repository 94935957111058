<div class="tab-content">
  <div class="table-view">
    <!-- Table starts -->
    <p-table
      #contactsTable
      [value]="contacts"
      dataKey="Id"
      [(selection)]="selectedContacts"
      [rows]="10"
      [paginator]="true"
      [pageLinks]="3"
      (onRowUnselect)="onRowUnselect($event)"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{ 'SUMMARY_TOTALRECORDS' | translate }}"
      (onRowSelect)="onRowSelect($event)"
      [rowsPerPageOptions]="[5, 10, 20]"
      exportFilename="Customer Contact List"
      styleClass="p-datatable-striped"
    >
      <!-- Caption -->
      <ng-template pTemplate="caption">
        <div class="ui-helper-clearfix">
          <div class="filter-controls-wrapper" *ngIf="viewFilter">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>
                    <span translate>TYPE_OF_CONTACT</span>
                  </label>
                  <div>
                    <p-dropdown
                      [options]="typeOfcontacts"
                      [style]="{ width: '150px' }"
                      #typeOfContactsDropdown
                      (onChange)="onTypeOfContactChanged($event)"
                    >
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-options">
            <div class="pull-right">
              <div class="action-btns-group" *ngIf="viewFilter">
                <button
                  class="btn p-3 filter-toggle"
                  (click)="viewFilter = !viewFilter"
                >
                  <span class="icon ic-sm icon-filter"></span>
                  <span translate>FILTER</span>
                </button>
              </div>
              <kuba-export
                [reportData]="
                  contactsTable.filteredValue || contactsTable.value
                "
                [additionalData]="additionalData"
              ></kuba-export>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 55px">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="Name">{{ "NAME" | translate }}</th>
          <th>{{ "CELLULAR_PHONE" | translate }}</th>
          <th>{{ "EMAIL" | translate }}</th>
          <th pSortableColumn="ContactFunctionalAreaName">
            {{ "FUNCTION" | translate }}
          </th>
          <th pSortableColumn="BusinessName">{{ "BUSINESS" | translate }}</th>
        </tr>
        <tr>
          <th></th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                contactsTable.filter(
                  $any($event.target)?.value,
                  'Name',
                  'contains'
                )
              "
              placeholder=""
              class="p-column-filter"
            />
          </th>
          <th></th>
          <th></th>
          <th>
            <p-dropdown
              [options]="functions"
              (onChange)="onFunctionChanged($event, functionDropdown)"
              #functionDropdown
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
          <th>
            <p-dropdown
              [options]="business"
              (onChange)="onBusinessChanged($event, businessDropdown)"
              #businessDropdown
              styleClass="p-column-filter"
              placeholder="Select"
              [showClear]="true"
            >
              <ng-template let-status pTemplate="item">
                {{ status.label | translate }}
              </ng-template>
            </p-dropdown>
          </th>
        </tr>
      </ng-template>
      <!-- Body Content -->
      <ng-template pTemplate="body" let-contact>
        <tr>
          <td style="width: 55px">
            <p-tableCheckbox id="selectedOrders" [value]="contact">
            </p-tableCheckbox>
          </td>
          <td>
            <span
              ><a [routerLink]="['./../../edit', contact.Id]">{{
                contact.Name
              }}</a></span
            >
          </td>
          <td>
            <span>{{ contact.MobileCode }}</span>
          </td>
          <td>
            <span>{{ contact.Emailid }}</span>
          </td>
          <td>
            <span>{{ contact.ContactFunctionalAreaName }}</span>
          </td>
          <td>
            <span>{{ contact.BusinessName }}</span>
          </td>
        </tr>
      </ng-template>
      <!-- Empty message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td colspan="6">
            {{ "NO_RECORDS_FOUND" | translate }}
          </td>
        </tr>
      </ng-template>
      <!-- Footer -->
      <ng-template pTemplate="summary">
        <div class="ui-helper-clearfix">
          <div class="footer-data">
            <div *ngIf="!isPortal">
              <span
                pTooltip="{{
                  'SELECT_ATLEAST_ONE_RECORD_DELETE_CONTACT' | translate
                }}"
              >
                <button
                  class="btn btn-danger"
                  [disabled]="!isSelectedValue"
                  (click)="deleteContacts()"
                >
                  <i class="icon ic-sm icon-trash"></i>
                  <span translate>DELETE_CHECKED</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-table>
    <!-- Table ends -->
  </div>
</div>
<p-confirmDialog
  header="{{ 'CONFIRMATION' | translate }}"
  icon="pi pi-question-circle"
  width="425"
  acceptLabel="{{ 'SWITCH_YES' | translate }}"
  rejectLabel="{{ 'SWITCH_NO' | translate }}"
></p-confirmDialog>
