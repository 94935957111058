
import { KubaExportModule } from './../shared/kuba-exports/kuba-export.module';
import { CountryCodeListResolver } from './../contact/services/contact.resolver.service';
import { SupplierServices } from './Services/supplier.services';
import {
    SupplierListResolver,
    SupplierEditResolver,
    SupplierContactListResolver,
    SupplierContactResolver,
    ActiveSupplierResolver
} from './Services/supplier.resolver.service';
import { SupplierNewComponent } from './Components/supplierNewForm.component';
import { SupplierEditComponent } from './Components/supplierEditForm.component';
import { SupplierFormComponent } from './Components/supplierForm.component';
import { SupplierComponent } from './supplier.component';
import { SupplierListComponent } from './Components/supplierList.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
    imports: [
        AppSharedModule, 
        RouterModule, 
        ConfirmDialogModule,
        KubaExportModule
    ],
    exports: [
        SupplierComponent,
        SupplierListComponent,
        SupplierFormComponent,
        SupplierEditComponent,
        SupplierNewComponent
    ],

    declarations: [
        SupplierComponent,
        SupplierListComponent,
        SupplierFormComponent,
        SupplierEditComponent,
        SupplierNewComponent
    ],
    providers: [
        SupplierListResolver,
        ActiveSupplierResolver,
        SupplierServices,
        SupplierEditResolver,
        SupplierContactListResolver,
        CountryCodeListResolver,
        SupplierContactResolver
    ]
})
export class SupplierModule {}
