import { BusinessFeatureService } from './../../businesses/services/businessFeatures.service';
import { BaseServices } from './../../kuba.services';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'user-rights',
    templateUrl: 'userRights.component.html'
})

// TODO: to be implemented
export class UserRightsComponent implements OnInit {
    businessFeature: any;
    value: boolean;

    private subscriptions: Subscription[] = [];
    constructor(private businessSvc: BusinessFeatureService) {
        this.subscriptions.push(this.businessSvc.getBusinessFeature(1).subscribe((res) => {
            this.businessFeature = res;
        }))
    }
    ngOnInit() { document.querySelector("body").classList.remove("opened");}

    cancel() {}

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
