import { FeaturesBaseServices } from './../services/features-base.services';
import { FeatureBase, Feature } from './../models/features-base.model';
import { IActionMapping, TreeModel, TreeNode, TREE_ACTIONS  } from '@circlon/angular-tree-component';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { BaseServices } from '../../kuba.services';
import { HelperService } from '../../../_services/helper.service';
import { FeatureKey } from 'src/app/_models/feature';
@Component({
    selector: 'features',
    templateUrl: 'features.component.html'
})
export class FeaturesComponent implements OnInit, OnChanges {
    _featuresBase: FeatureBase;
    portalFeatures: any;
    filterFeaturesKey: any;
    filterEmployeeKey: any = [];
    @Input()
    set featureBase(value) {
        this._featuresBase = value;
    }
    @Input() isPortalExists: any;
    get featureBase(): FeatureBase {
        let filterEmployeeFeaturesKey = [
            FeatureKey.EMPLOYEES,
            FeatureKey.ARCHIVE
        ];
        let employee = JSON.parse(this._featuresBase.DefaultFeatures);
        employee = employee.filter((x:any) => {
            return (x.id) == (FeatureKey.EMPLOYEES) || (x.id) == (FeatureKey.ARCHIVE);
        });
        this.filterEmployeeKey = HelperService.filterTree('N', employee, 'IsFeature');
        // }
        let filteredTemplate = HelperService.filterTree('F', JSON.parse(this._featuresBase.FeaturesTemplate), 'IsFeature');
        this._featuresBase.FeaturesTemplate = JSON.stringify(filteredTemplate);
        return this._featuresBase;
    }
 
    // KW-653 - Start
    _supplierChecked: boolean;  
    @Input()
    get supplierChecked(): boolean {
        return this._supplierChecked;
    }
    set supplierChecked(value) {
        this._supplierChecked = value;
    }
    entryCount: number = 0;
    // KW-653 - End

    features: Feature[];
    employeeFeatures: Feature[];
    roleId: number;
    
    // Tree Configuration
    actionMapping: IActionMapping = {
        mouse: {
            dblClick: (tree:TreeModel, node:TreeNode, $event:any) => {
                if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event) };
            }
        }
    };

    optionsTreeNode:any = {
        actionMapping: this.actionMapping,
        animateExpand: true,
        animateSpeed: 30,
        animateAcceleration: 1.2,
        allowDrag: false,
        allowDrop: false
    }
    constructor() {
        this.portalFeatures = BaseServices.userFeatures;
    }
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.roleId = +BaseServices.roleId;
        this.features = FeaturesBaseServices.getFeatureDetails(this.featureBase);
        this.features.forEach(element => {
            if (element.children != null) {
                for (let i = 0; i < element.children.length; i++) {
                    if (element.children[i].translationKey === "HMS_TV" || element.children[i].translationKey === "INTERNAL_TV" || element.children[i].translationKey === "PORTAL_TV") {
                        element.children.splice(i, 1);
                        i--;
                    }
                }
            }
            if(element.id == 6){
                element.children= element.children.filter(e => e.id != 116);
               }   
        });

        //KW-653 - Start
        this.features.forEach(element => {
            if(element.id === FeatureKey.SUPPLIERS){
                if(element.checked === true){
                    this._supplierChecked = true;
                }else{
                    this._supplierChecked = false;
                }
            }
        });
        //KW-653 - End
        this.features.forEach(element => {
            if (element.children != null) {
                for (let i = 0; i < element.children.length; i++) {
                    if (element.children[i].translationKey === "HMS_TV" || element.children[i].translationKey === "INTERNAL_TV" || element.children[i].translationKey === "PORTAL_TV") {
                        element.children.splice(i, 1);
                        i--;
                    }
                }
            }
        });
    }

    /**
     * refresh the features on update
     */
    ngOnChanges(changes: SimpleChanges): void {
        if((+BaseServices.roleId === 1) || (+BaseServices.roleId === 2) || (+BaseServices.roleId === 6) || (+BaseServices.roleId === 9)){
            this.entryCount++;
        }
        if((this.entryCount === 1) || (+BaseServices.roleId === 3) || (+BaseServices.roleId === 4) || (+BaseServices.roleId === 5)){
        // Disable non-working features kw-101
        this.filterFeaturesKey = [
            FeatureKey.MESSAGES,
            FeatureKey.SUGGESTION_BOX,
            FeatureKey.HOME_PAGE,
        ];
        let isProjectTimeTable = true;
        let key = this.contains(this.filterFeaturesKey, FeatureKey.TIME_LOG);
        if (+BaseServices.roleId > 3) {
            let filteredTemplate = HelperService.filterTree('N', JSON.parse(this._featuresBase.FeaturesTemplate), 'IsFeature');
            this._featuresBase.FeaturesTemplate = JSON.stringify(filteredTemplate);
            this.employeeFeatures = <Feature[]>JSON.parse(this._featuresBase.FeaturesTemplate);
        }
        if (+BaseServices.BusinessId == 5) {
            //Enable reception control feature for Kuba Norge AS business only
            this.filterFeaturesKey = [
                FeatureKey.MESSAGES,
                FeatureKey.SUGGESTION_BOX,
                FeatureKey.HOME_PAGE
            ];
        }
        else {
            // Disable non-working features kw-101         
            this.filterFeaturesKey = [
                FeatureKey.MESSAGES,
                FeatureKey.SUGGESTION_BOX,
                FeatureKey.HOME_PAGE
            ];
        }
        this.roleId = +BaseServices.roleId;
        this.features = FeaturesBaseServices.getFeatureDetails(this.featureBase);
        if (BaseServices.roleId === '2') {
            this.features.forEach(element => {
                if (element.id === 40) {
                    element.access = false;
                }
            });
        }
        this.features.forEach(element => {
            let val = [];
            if (element.id === FeatureKey.PROJECT || element.id === FeatureKey.FOOD_SAFETY|| element.id === FeatureKey.KUBA_CONTROL) {
                val = element.children;
                val.forEach((e:any) => {
                    if (e.translationKey === 'TIME_TABLE') {
                        if (e.access === true) {
                            if (e.checked === false) {
                                isProjectTimeTable = false;
                            }
                        } else if (e.access === false) {
                            isProjectTimeTable = false;
                        }
                    }
                    if (e.id === 'TIMELOG') {
                        if (isProjectTimeTable === false) {
                            e.access = false;
                            e.checked = false;
                        } else {
                            e.checked = true;
                        }
                    }
                })
            }

        });

        }

    }

    /**
     * usded to find array has element
     * @param arr
     * @param element
     */
    contains(arr:any, element:any) {
        let isKey = false;
        arr.forEach((e:any) => {
            if (e === element) {
                isKey = true;
            }
        });
        return isKey;
    }
    public check(node:any, $event:any) {
        let checkedValue = ($event.value == null) ? true : $event.value;
        this.updateChildNodesCheckBox(node, checkedValue);
        this.updateParentNodesCheckBox(node.parent);

        //KW-653 - Start
        if(!this._supplierChecked){
            if(node.id === FeatureKey.RECEPTION_CONTROL || node.id === FeatureKey.FOOD_SAFETY || node.id === FeatureKey.KUBA_CONTROL){
                if(node.data.checked === true){
                    this.features.forEach(element => {
                        if(element.id === FeatureKey.SUPPLIERS && element.checked === false){
                            element.checked = true;
                        }
                    });
                }else{
                    this.features.forEach(element => {
                        if(element.id === FeatureKey.SUPPLIERS && element.checked === true){
                            element.checked = false;
                        }
                    });
                }
            }
        }
        //KW-653 - End
    }
    public updateChildNodesCheckBox(node:any, checked:any) {
        if (node.id === 117) {
            node.data.checked = checked;
            return;
        }
        node.data.checked = checked;
       
        let key = this.contains(this.filterFeaturesKey, FeatureKey.TIME_LOG);
        if (node.id === FeatureKey.TIME_TABLE) {
            this.features.forEach(element => {
                let val = [];
                if (element.id === FeatureKey.PROJECT || element.id === FeatureKey.FOOD_SAFETY || element.id === FeatureKey.KUBA_CONTROL) {
                    val = element.children;
                    val.forEach(e => {
                        if (e.translationKey === 'TIMELOG') {
                            if (checked === false) {
                                e.access = false;
                                e.checked = false;
                            } else {
                                e.access = true;
                            }
                        }
                    })
                }
            });
        }
        if (node.children) {
            node.children.forEach((child:any) => {
                if (child.id != FeatureKey.MESSAGES && child.id != FeatureKey.SUGGESTION_BOX && child.id != FeatureKey.HOME_PAGE) {   // Disable non-working features kw-101
                    this.updateChildNodesCheckBox(child, checked)
                }
            });
        }
    }
    public updateParentNodesCheckBox(node:any) {
        if (node && node.level > 0 && node.children) {
            const isChild117 = node.children.some(child => child.data.id === 117);
            if (!isChild117) {
            let allChildChecked = true;
            let noChildChecked = true;

            for (let child of node.children) {
                if (!child.data.checked) {
                    allChildChecked = false;
                } else if (child.data.checked) {
                    noChildChecked = false;
                }
            }

            if (allChildChecked) {
                node.data.checked = true;
                node.data.indeterminate = false;
            } else if (noChildChecked) {
                node.data.checked = false;
                node.data.indeterminate = false;
            } else {
                node.data.indeterminate = true;
                node.data.checked = null;
            }
        }
            this.updateParentNodesCheckBox(node.parent);
        }
    }
}
