import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators
} from '@angular/forms';
import { Route, ActivatedRoute } from '@angular/router';
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter
} from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import {
    UploadInput,
    UploaderOptions,
    UploadFile,
    UploadStatus,
    UploadOutput
} from 'ngx-uploader';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { ValidationService } from '../../shared/services/validation.service';
import { SupplierServices } from './../../Suppliers/Services/supplier.services';
import { AdminUser, CountryCodes } from './../models/adminuser';
import { AdminUserService } from '../services/adminuser.service';
import { ToasterComponent } from '../../../_directives/toaster.component';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'adminuser-form',
    templateUrl: 'adminuser-form.component.html'
})
export class AdminUserFormComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    @Input() adminUserForm: FormGroup;
    countryCodeForm: FormGroup;
    PhoneCode: SelectItem[];
    country: SelectItem[];
    countryCodeId: number;
    user: AdminUser;
    uploadRequest: any;
    isUserNameExist = false;
    countryCodeList: any = [];
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput>;
    options: UploaderOptions;
    userImage: string;
    addType = false;
    viewCountryCode = false;
    newsletter = false;
    isFileChoosed = false;
    isNewUser: boolean;
    uploadStartForLogo: boolean;
    public selectedFiles: any;
    businessId: number;
    businessLogo: string;
    loading: boolean;
    countryExisting: string;
    existsAlerttext: string;
    isCountryExists = false;
    isNameExists = false;
    isfunctionExists: boolean;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * Constructor
     * @param _fb {FormBuilder}
     * @param translate {TranslateService}
     * @param supplierService {SupplierServices}
     * @param adminUserService {AdminUserService}
     * @param route {ActivatedRoute}
     * @param http {Http}
     * @param confirmationService {ConfirmationService}
     */
    constructor(
        private _fb: FormBuilder,
        private translate: TranslateService,
        private supplierService: SupplierServices,
        private adminUserService: AdminUserService,
        public route: ActivatedRoute,
        private http: HttpClient,
        private confirmationService: ConfirmationService
    ) {
        this.subscriptions.push(this.adminUserService.checkNewUser$.subscribe(condition => {
            this.isNewUser = condition;
        }));
        this.subscriptions.push(this.adminUserService.getExistingImage$.subscribe(imagePath => {
            this.userImage = imagePath ? imagePath : '';
        }));
        this.adminUserForm = this._fb.group({
            Name: [''],
            Address: [''],
            Email: [''],
            Mobile: [''],
            NewsLetter: [''],
            PhoneCode: [],
            PostalAddress: [''],
            UserType: [''],
            Username: [''],
            ZipCode: [],
        })
    }
    //#endregion

    //#region page-event
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.uploadInput = new EventEmitter<UploadInput>();
        this.countryCodeForm = this._fb.group({
            CountryCode: ['', Validators.required],
            CountryName: ['', Validators.required]
        })


        this.bindCountries();
        let adminUserForm = {
            Name: this.adminUserForm.value.Name,
            Address: this.adminUserForm.value.Address,
            Email: this.adminUserForm.value.Email,
            Mobile: this.adminUserForm.value.Mobile,
            NewsLetter: this.adminUserForm.value.NewsLetter,
            PhoneCode: Number(this.adminUserForm.value.PhoneCode),
            PostalAddress: this.adminUserForm.value.PostalAddress,
            UserType: this.adminUserForm.value.UserType,
            Username: this.adminUserForm.value.Username,
            ZipCode: this.adminUserForm.value.ZipCode,
        };
        (<FormGroup>this.adminUserForm).setValue(adminUserForm, {
            onlySelf: true
        });
    }
    //#endregion

    //#region methods
    /**
     * Bind the country code details
     */
    bindCountries() {
        this.PhoneCode = [];
        let code = this.route.snapshot.data['countryCode'];
        if (code) {
            code.forEach((countrycode: any) => {
                this.PhoneCode.push({
                    label: '+' + countrycode.Phonecode,
                    value: countrycode.Id,
                });
                this.countryCodeList.push({
                    Phonecode: '+' + countrycode.Phonecode,
                    Name: countrycode.Name,
                    Id: countrycode.Id
                });
            });
        }
    }

    /**
     * edit the country code
     * @param countryType {any}
     */
    editCountryCode(countryType: any) {
        this.countryCodeForm = this._fb.group({
            CountryCode: countryType.Phonecode,
            CountryName: countryType.Name
        })
        this.countryCodeId = countryType.Id;
    }

    /**
     * mobilenumber keypress event restrict number greater than 20 and restrict text
     */
    onKeyChange(e: any, limitNumber: any) {
        const limit = limitNumber;
        let charCode = (e.which) ? e.which : e.keyCode
        if (e.target.value.length === limit || charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    }
    /**
     * delete the country code
     * @param id {number}
     */
    deleteCode(countryCode) {
        this.subscriptions.push(this.adminUserService
            .checkAllocatedCountryCode(countryCode.Phonecode)
            .subscribe((result: any) => {
                if (result) {
                    this.isCountryExists = true;
                    this.countryExisting =
                        'You cannot delete this value as it is being used by another User';
                    setTimeout(
                        () => {
                            this.isfunctionExists = false;
                        },
                        3000
                    );
                } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.adminUserService.deleteCountryCode(countryCode.Id).subscribe(result => {
                                if (result) {
                                    this.fetchData();
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                        }
                    })
                }
            }));



    }

    /**
     * save the country code details
     */
    saveCountryCode() {
        let countryCode = new CountryCodes();
        countryCode.Id = this.countryCodeId
            ? this.countryCodeId
            : 0;
        countryCode.Name = this.countryCodeForm.value.CountryName;
        countryCode.Status = '1';
        countryCode.BusinessId = BaseServices.BusinessId;
        countryCode.Phonecode = this.countryCodeForm.value.CountryCode;
        if (countryCode.Id > 0) {
            this.subscriptions.push(this.adminUserService
                .updateCountryCode(countryCode)
                .subscribe((result: any) => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.fetchData();
                        this.adminUserForm.get('CountryCode')!.patchValue(result.Id);
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            () => {
                                this.isNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        } else {

            this.subscriptions.push(this.adminUserService
                .addCountryCode(countryCode)
                .subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.fetchData();
                    } else {
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        this.isNameExists = true;
                        setTimeout(
                            () => {
                                this.isNameExists = false;
                            },
                            3000
                        );
                    }
                }));
        }
        this.clearCountryCode();
    }
    clearCountryCode() {
        this.countryCodeForm = this._fb.group({
            CountryName: [''],
            CountryCode: ['']
        });
        this.countryCodeId = 0;
    }
    /**
     * Refreshing the table data
     */
    fetchData() {
        this.PhoneCode = [];
        this.countryCodeList = [];
        this.subscriptions.push(this.supplierService.getCountryCode().subscribe((res: any) => {
            this.countryCodeList = res;
            res.forEach((countrycode: any) => {
                this.PhoneCode.push({
                    label: '+' + countrycode.Phonecode,
                    value: countrycode.Phonecode
                });
            });
        }))
    }

    /**
     * upload for business logo
     * @param selectedFiles
     */
    filesSelectForLogo(selectedFiles: any) {
        this.uploadStartForLogo = true;
        this.selectedFiles = Array.from(selectedFiles.currentTarget.files).map(file => file);
        let formData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        let session = JSON.parse(sessionStorage.getItem('session'));
        let token = session.AccessToken;
        var headers = {
            'Authorization': 'Bearer ' + token, // Add JWT token to headers
            // Add any other headers if needed
        };
        this.subscriptions.push(this.http
            .post(
                environment.BASE_URL +
                '/file/upload/business/logo/' +
                BaseServices.ApplicationId +
                '/' +
                this.businessId,
                formData,{headers:headers}
            )
            .map((response: any) => {
                this.uploadStartForLogo = false;
                let res = response;
                this.userImage = res.path;
                this.adminUserService.getImagePath(this.userImage);
                this.loading = false;
            })
            .subscribe(
                result => { },
                error => console.log('Upload article Sign Error : ', error),
                () => console.log('Upload article  Sign Complete')
            ));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
