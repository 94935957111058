import { Route } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { FoodSafetyCleaningListComponent } from './components/cleaning-list.component';
import { FoodSafetyCleaningComponent } from './foodsafety-cleaning.component';

export const foodsafetycleaningRoutes: Route[] = [{
    path: 'cleaning',
    component: FoodSafetyCleaningComponent,
    canActivate: [AuthGuard],
    children: [
        {
            path: 'list',
            component: FoodSafetyCleaningListComponent,
            canActivate: [AuthGuard],
            resolve: {
            }
        },
    ]
}];


