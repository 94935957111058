import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { News } from '../models/news';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { AnyTxtRecord } from 'dns';
import { Rights } from 'src/app/_models';

@Injectable()
export class NewsServices {

    constructor(private http: HttpClient) { }
    isAdministrateNews: boolean = false;
    createAuthorizationHeader(headers: Headers) {
        headers.append('content-type', 'application/json');
    }
    // List all News
    list() {
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_NEWS;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.isAdministrateNews = BaseServices.checkUserRights(
                userRightsId,
                role
            );
        }
        return this.http.get<any>(environment.BASE_URL + '/newsbybusiness/' + BaseServices.BusinessId + '/' + this.isAdministrateNews, BaseServices.headerOption)
            .map(result => result);
    }

    adminNewslist() {
        return this.http.get<any>(environment.BASE_URL
            + '/newsbyadmin/'
            + BaseServices.ApplicationId
            + '/' + 0, BaseServices.headerOption)
            .map(result => result);
    }

    get(Id: number) {
        return this.http.get<any>(environment.BASE_URL + '/news/' + Id, BaseServices.headerOption)
            .map(result => result);
    }

    add(data,cultureInfo:any) {
        return this.http.post(environment.BASE_URL + '/news'+'/'+ cultureInfo, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    update(Id: number, data: News) {
        return this.http.put(environment.BASE_URL + '/news/' + Id, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    publish(Id: number, data: News,cultureInfo: any) {
        return this.http.put(environment.BASE_URL + '/news/' + Id + '/'+ cultureInfo, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    delete(Id: number) {
        return this.http.delete<HttpHeaderResponse>(environment.BASE_URL + '/newsdelete/' + Id, BaseServices.headerOption)
            .toPromise()
            .then((response: HttpHeaderResponse) => { return response });
    }

    EditSendEmailToAllUsers(cultureInfo: any,Id: number, data: News){
        return this.http.put(environment.BASE_URL + '/SendEmailToAllUsers/' + BaseServices.ApplicationId + '/' + BaseServices.BusinessId + '/'+ cultureInfo + '/' +Id, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }

    addSendEmailToAllUsers(data, cultureInfo: any){
        return this.http.post(environment.BASE_URL + '/AddSendEmailToAllUsers/' + BaseServices.ApplicationId + '/' + BaseServices.BusinessId + '/'+ cultureInfo, data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
}
