import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BaseServices } from './../../kuba.services';
import { Links } from './../models/businessLink';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BusinessLinksService {
    constructor(private http: HttpClient) { }

    getLinksByBusinessId(bid: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/links/linklist/' + bid + '/' +BaseServices.UserRole , BaseServices.headerOption)
            .map(result => result);
    }
    getLinksById(id: number) {
        return this.http
            .get<any>(environment.BASE_URL + '/links/link/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    createLink(links: Links) {
        return this.http
            .post(
                environment.BASE_URL + '/links/link',
                links,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    updateLink(id: number, links: Links) {
        return this.http
            .put(
                environment.BASE_URL + '/links/link/',
                links,
                BaseServices.headerOption
            )
            .map(result => result);
    }

    deleteById(id: number) {
        return this.http
            .delete(
                environment.BASE_URL + '/links/linksdelete/' + id,
                BaseServices.headerOption
            )
            .map(result => result);
    }
}
