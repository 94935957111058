import { Component, OnInit } from '@angular/core';

@Component({
  template: `<router-outlet></router-outlet>`
})
export class LogComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
