import { BaseServices } from './../../kuba.services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { RiskanalysisService } from '../services/risk-analysis.service';
import { Department, Topic } from './../models/riskanalysis';
import { SelectItem } from 'primeng/api';
import { BusinessDepartmentService } from './../../business-department.services';
import { Subscription } from 'rxjs';
@Component({
    selector: 'department',
    templateUrl: 'department.component.html'
})
export class DepartmentComponent implements OnInit {
    editDepartments = [];
    cDepartment: any;
    departments: SelectItem[];
    selectedDept = new Department();
    viewType = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private riskanalysisService: RiskanalysisService,
        private businessDepartmentService: BusinessDepartmentService) { }

    saveDept() {
        let riskDept = new Department();
        riskDept.Id = (this.selectedDept.Id) ? this.selectedDept.Id : 0;
        riskDept.Name = this.selectedDept.Name;
        riskDept.Status = '1';
        riskDept.BusinessId = BaseServices.BusinessId;
        riskDept.ApplicationId = BaseServices.ApplicationId;
        riskDept.CreatedBy = BaseServices.UserId;
        if (riskDept.Id > 0) {
            this.subscriptions.push(this.riskanalysisService.updateDept(riskDept).subscribe(result => {
                if (result) {

                }
            }));
        } else {
            this.subscriptions.push(this.riskanalysisService.createDept(riskDept)
                .subscribe(result => {

                    if (result) {

                    }
                }));
        }
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.departments = [];
        this.departments.push({ label: 'select a Department', value: null });
        this.subscriptions.push(this.riskanalysisService.getAllByProj(1).subscribe(Riskanalysis => this.riskanalysisService = Riskanalysis));
        this.subscriptions.push(this.businessDepartmentService.getCachedBusinessDepartment().subscribe(result => {
            if (result) {
                this.cDepartment = result;
                this.cDepartment.forEach((element:any) => {
                    this.departments.push({ label: element.Name, value: element.Id });
                    this.editDepartments.push({ Name: element.Name, Id: element.Id });
                });
            }
        }));
    }

    editDeptType(DeptType: any) {
        this.selectedDept = DeptType;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
