import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BusinessProperty } from '../models/';
import { BaseServices } from './../../kuba.services';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Injectable()
export class BusinessPropertyService {
    constructor(private http: HttpClient) {
    }


    // list the businessProperty
    list(businessId: number) {
        return this.http.get(environment.BASE_URL + '/business/properties/' + businessId, BaseServices.headerOption)
            .map(result => result);
    }
    // add the businessProperty
    add(data: BusinessProperty[]) {
        return this.http.post(environment.BASE_URL + '/business/property', data, BaseServices.headerOption)
            .map(result => result);
    }
    // delete the businessProperty
    deleteProperty(id: number) {
        return this.http.put(environment.BASE_URL + '/business/property/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    // get the particular businessProperty
    getProperty(id: number) {
        return this.http.get(environment.BASE_URL + '/business/property/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    // update the particular businessProperty
    updateProperty(id: number) {
        return this.http.put<HttpHeaderResponse>(environment.BASE_URL + ' /business/property/', +id, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
    // list the business property type
    listPropertyType(businessId: number) {
        return this.http.get(environment.BASE_URL + '/businessPropertyTypes/' + businessId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    // get the particular businessPropertyObject
    getPropertyObjectById(id: number) {
        return this.http.get(environment.BASE_URL + '/businessPropertyObject/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    // add the businessPropertyType
    addPropertyType(data:any) {
        return this.http.post<HttpHeaderResponse>(environment.BASE_URL + '/businessPropertyType', data, BaseServices.headerOption)
            .map((response: HttpHeaderResponse) => { return response });
    }
    // update the businessPropertyType
    updatePropertyType(data: any) {
        return this.http.put<HttpHeaderResponse>(
            environment.BASE_URL + '/businessPropertyType/', data, BaseServices.headerOption
        ).map((response: HttpHeaderResponse) => response = response);
    }
    // delete the businessPropertyType
    deletePropertyType(id: number) {
        return this.http.delete(environment.BASE_URL + '/businessPropertyType/' + id, BaseServices.headerOption)
            .map(result => result);
    }
    // list the businessPropertyObject listPropertyObject
    listPropertyObject() {
        return this.http.get(environment.BASE_URL + '/business/businessPropertyObjects', BaseServices.headerOption)
            .map(result => result);
    }
    checkAllocatedBusinessProperty(id: number) {
        return this.http.get(environment.BASE_URL + '/business/checkallocatedbusinessproperty/' + id, BaseServices.headerOption)
            .map(result => result);
    }
}
