import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { InternalControlServices } from './../services/internal-control.service';
import { InternalControl, InternalControlUser } from '../models/internal-control';
import { Rights } from 'src/app/_models';
import { HelperService } from 'src/app/_services/helper.service';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import { Subscription } from 'rxjs';

@Component({
    selector: 'internal-control-edit',
    templateUrl: 'internal-control-edit.component.html'
})
export class InternalControlEditComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    InternalControlForm: FormGroup;
    icId: number;
    isAdministrateIC = true;
    icObj: any = [];
    selectedUsers: any = [];
    interanlUsers: any[];
    userId: number;
    userSubscribers: SelectItem[];
    selectedSubscribers: SelectItem[];
    emailUsers: any[];
    bindAllUsers = false;
    bindSelectedUsers = true;
    selectedEmailSubscriber = [];
    selectAllEmailSubscriber = [];
    ICUsers = new InternalControlUser();
    ICdetails = new InternalControl();
    GetICdetails: any;
    IsHidden = false;
    chooseObject: SelectItem[];
    CopyRA = false;
    checkedAll:boolean;
    private subscriptions: Subscription[] = [];

    /**
     *
     * @param formBuilder {{FormBuilder}}
     * @param internalControlServices {{InternalControlServices}}
     * @param activatedRoute {{ActivatedRoute}}
     * @param location {{Location}}
     */
    constructor(
        private formBuilder: FormBuilder,
        private internalControlServices: InternalControlServices,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private translate: TranslateService, ) {
        this.InternalControlForm = this.formBuilder.group({
            'Title': ['', Validators.required],
            'Description': [''],
            'EditorOnly': [''],
            'IsShowDeviationChecked': [false],
            chooseObject: new FormControl(this.chooseObject),
            InternalControlTemplateId : new FormControl(this.chooseObject),
            CopyRA: new FormControl(this.CopyRA),
            selectedEmailSubscriber : new FormControl(this.selectedEmailSubscriber),
        });
        this.userId = +BaseServices.UserId;
    }
    /**
     * edit internal control
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let nid = params['id'];
            this.icId = +nid;
            if (this.icId !== 0) {
                this.subscriptions.push(this.internalControlServices.getInternalControlById(this.icId).subscribe(result => {
                    if (result) {
                        if (result.InternalControlTemplateId > 0) {
                            this.IsHidden = true;
                        }
                        this.icObj = {
                            Title: result.Title,
                            Description: result.Description,
                            EditorOnly: result.IsEditorOnly,
                            InternalControlTemplateId : result.InternalControlTemplateId,
                            CopyRA: result.CopyRA,
                            IsShowDeviationChecked: result.ShowDeviation == true ? false : true,
                            selectedEmailSubscriber: result.ICUserDto.filter(x => x.IsEmail === true),
                            selectAllEmailSubscriber: result.ICUserDto.filter(x => x.IsEmail === true)
                        };
                        this.InternalControlForm.patchValue(this.icObj, { onlySelf: true });    
                        
                        this.GetICdetails = result;
                        if (this.GetICdetails) {
                            if (this.GetICdetails.ICUserDto) {
                                this.GetICdetails.ICUserDto.forEach((element: any) => {
                                    let selectedUsers = this.interanlUsers.find(x => x.Id === element.UserId);
                                    if (selectedUsers) {
                                        selectedUsers.status = true;
                                        selectedUsers.isEmail = false;
                                        this.checked(selectedUsers)
                                        if (element.IsEmail) {
                                            this.selectedEmailSubscriber.push({
                                                Id: element.UserId,
                                                FeatureKey: element.FeatureKey,
                                                FeatureId: element.FeatureId
                                            });
                                            this.selectAllEmailSubscriber.push({
                                                Id: element.UserId,
                                                FeatureKey: element.FeatureKey,
                                                FeatureId: element.FeatureId
                                            });
                                        }
                                    }
                                });
                            }
                            if (result.ICUserDto) {
                                this.InternalControlForm.patchValue({
                                    selectedEmailSubscriber: this.selectedEmailSubscriber
                                });
                            }
                        }
                    }
                }));
            }
        }));
        this.onAdministrateInternalControl();
        let users = this.activatedRoute.snapshot.data['contact'];
        if (users) {
            this.interanlUsers = users;
        }
        this.selectedSubscribers = [];
        this.userSubscribers = [];
        let userSubscription = this.activatedRoute.snapshot.data[
            'users_subscription'
        ];
        this.emailUsers = userSubscription;
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.userSubscribers = [];
            if (userSubscription) {
                userSubscription.forEach((element: any) => {
                    this.selectedSubscribers.push({
                        label: element.Name,
                        value: {
                            Id: element.Id,
                            FeatureKey: element.FeatureKey,
                            FeatureId: element.FeatureId
                        }
                    });
                    if (element.Id == BaseServices.UserId) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: {
                                Id: element.Id,
                                FeatureKey: element.FeatureKey,
                                FeatureId: element.FeatureId
                            }
                        });
                    }
                });
            }
        }));
        this.chooseObject = [];
        let internalControlName = this.activatedRoute.snapshot.data['internalControlName']
        if (internalControlName) {
            this.subscriptions.push(this.translate.stream('SELECT_OBJECT').subscribe(val => {
                this.chooseObject = [];
                this.chooseObject.push(
                    { label: val.SELECT_OBJECT, value: null }
                );
                internalControlName.forEach((element: any) => {
                    this.chooseObject.push({
                        label: element.Name,
                        value: element.Id
                    });
                });
            }));
        }
    }
    /**
     * save and update internal control
     * @param Icdata {{any}}
     */
    onSubmitTemplateBased(Icdata) {
        let ICdetails = this.InternalControlForm.value;
        let userId = BaseServices.UserId;
        let name = BaseServices.userName;
        let checkUserId = this.selectedUsers.filter(x => x.Id == userId);
        if (checkUserId.length === 0 && window.name !== 'Remote') {
            this.selectedUsers.push({ Id: userId, Name: name });
        }
        if (this.icId) {
            ICdetails.Id = this.icId;
            ICdetails.Status = '1';
            ICdetails.BusinessId = BaseServices.BusinessId;
            ICdetails.ApplicationId = BaseServices.ApplicationId;
            ICdetails.CreatedBy = this.userId;
            ICdetails.ModifiedBy = BaseServices.UserId;
            ICdetails.IsEditorOnly = (ICdetails.EditorOnly ? ICdetails.EditorOnly : false);
            ICdetails.CreatedOn = new Date();
            ICdetails.Title = ICdetails.Title;
            ICdetails.Description = ICdetails.Description;
            ICdetails.InternalControlTemplateId = ICdetails.InternalControlTemplateId;
            ICdetails.CopyRA = ICdetails.CopyRA;
            ICdetails.ShowDeviation = (ICdetails.IsShowDeviationChecked) ? false: true;
            if ((this.bindAllUsers) && this.InternalControlForm.value.selectAllEmailSubscriber) {
                ICdetails.ICEmailSubscribers = [];
                let emailAllSubscriber = this.InternalControlForm.value.selectAllEmailSubscriber;
                emailAllSubscriber.map(function (x) { x.IsChecked = true; return x });
                ICdetails.ICEmailSubscribers.push(...emailAllSubscriber);
            }
            if ((this.bindSelectedUsers) && this.InternalControlForm.value.selectedEmailSubscriber) {
                ICdetails.ICEmailSubscribers = [];
                let emailSubscriber = this.InternalControlForm.value
                    .selectedEmailSubscriber;
                emailSubscriber.map(function (x) {
                    x.IsChecked = true;
                    return x
                });
                ICdetails.ICEmailSubscribers.push(...emailSubscriber);
            }
            let ICUsers: InternalControlUser = new InternalControlUser();
            this.ICUsers.InternalControlId = this.icId ? this.icId : 0;
            let Ids: any = ([] = []);
            this.selectedUsers.forEach((element: any) => {
                Ids.push({
                    UserId: element.Id,
                    InternalControlId: this.ICUsers.InternalControlId,
                    Name: element.Name
                });
            });
            ICdetails.ICUserDto = Ids;
            this.subscriptions.push(this.internalControlServices.update(ICdetails)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }))
        } else {
            let ICdetails = new InternalControl();
            ICdetails.Id = 0;
            ICdetails.Status = '1';
            ICdetails.BusinessId = BaseServices.BusinessId;
            ICdetails.ApplicationId = BaseServices.ApplicationId;
            ICdetails.CreatedBy = BaseServices.UserId;
            ICdetails.Title = this.InternalControlForm.value.Title;
            ICdetails.InternalControlTemplateId = this.InternalControlForm.value.InternalControlTemplateId;
            ICdetails.CopyRA = this.InternalControlForm.value.CopyRA;
            ICdetails.IsEditorOnly = (this.InternalControlForm.value.EditorOnly) ? this.InternalControlForm.value.EditorOnly : false;
            ICdetails.Description = this.InternalControlForm.value.Description;
            ICdetails.ShowDeviation = (this.InternalControlForm.value.IsShowDeviationChecked) ? false: true;
            if ((this.bindAllUsers) && this.InternalControlForm.value.selectAllEmailSubscriber) {
                ICdetails.ICEmailSubscribers = [];
                let emailAllSubscriber = this.InternalControlForm.value.selectAllEmailSubscriber;
                emailAllSubscriber.map(function (x) { x.IsChecked = true; return x });
                ICdetails.ICEmailSubscribers.push(...emailAllSubscriber);
            }
            if ((this.bindSelectedUsers) && this.InternalControlForm.value.selectedEmailSubscriber) {
                ICdetails.ICEmailSubscribers = [];
                let emailSubscriber = this.InternalControlForm.value
                    .selectedEmailSubscriber;
                emailSubscriber.map(function (x) {
                    x.IsChecked = true;
                    return x
                });
                ICdetails.ICEmailSubscribers.push(...emailSubscriber);
            }
            let ICUsers: InternalControlUser = new InternalControlUser();
            this.ICUsers.InternalControlId = this.icId ? this.icId : 0;
            let Ids: any = ([] = []);
            this.selectedUsers.forEach((element: any) => {
                Ids.push({
                    UserId: element.Id,
                    InternalControlId: this.ICUsers.InternalControlId,
                    Name: element.Name
                });
            });
            ICdetails.ICUserDto = Ids;
            this.subscriptions.push(this.internalControlServices.add(ICdetails)
                .subscribe(result => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }));
        }
        this.selectedUsers = [];
    }
    /**
     * go to internal control list
     */
    gotoBack() {
        this.location.back();
    }
    // hide and show the save button based on the rights
    onAdministrateInternalControl() {
        let userRightsId = Rights.ADMINISTRATE_INTERNAL_CONTROL;
        let role = BaseServices.UserRole;
        if (role === 'User') {
            this.isAdministrateIC = BaseServices.checkUserRights(userRightsId, role);
        }
    }

    checked(value) {
        if (value.status) {
            this.selectedUsers.push(value);
            if (this.emailUsers) {
                this.emailUsers.forEach((element: any) => {
                    if (element.Id === value.Id && value.Id !== BaseServices.UserId && (!value.isEmail)) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: {
                                Id: element.Id,
                                FeatureKey: element.FeatureKey,
                                FeatureId: element.FeatureId
                            }
                        });
                    }
                });
            }
        } else {
            _.remove(this.selectedUsers, (val) => {
                if (this.userSubscribers) {
                    this.userSubscribers = this.userSubscribers.filter(x => x.value.Id !== value.Id);
                    if (this.selectedEmailSubscriber) {
                        this.selectedEmailSubscriber = this.selectedEmailSubscriber.filter(x => x.Id !== value.Id);
                        this.InternalControlForm.get('selectedEmailSubscriber').reset();
                        this.InternalControlForm.patchValue({
                            selectedEmailSubscriber: this.selectedEmailSubscriber
                        });
                    }
                }
                return val === value;
            })
        }
    }

    checkAll(event) {
        _.forEach(this.interanlUsers, (item: any) => {
            if (event) {
                item.status = true;

            } else {
                item.status =
                    // to remain checked state for current user
                    (this.userId === +item.Id) ? true : false;
            }
        });

        this.selectedUsers = _.create(this.interanlUsers);
        if (event === true) {
            this.userSubscribers = [];
            if (this.selectedUsers) {
                this.selectedUsers.forEach(element => {
                    let checkUserId = this.emailUsers.filter(x => x.Id === element.Id);
                    if (checkUserId.length > 0) {
                        this.userSubscribers.push({
                            label: element.Name,
                            value: {
                                Id: element.Id,
                                FeatureKey: checkUserId[0].FeatureKey,
                                FeatureId: checkUserId[0].FeatureId
                            }
                        });
                    }                   
                })
            }
        } else {
            if (this.userSubscribers) {
                this.userSubscribers = this.userSubscribers.filter(x => x.value.Id === this.userId);
            }
            this.selectedEmailSubscriber = [];
            this.InternalControlForm.get('selectedEmailSubscriber').reset();
            this.InternalControlForm.controls['selectedEmailSubscriber'].setValue(this.selectedEmailSubscriber);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
