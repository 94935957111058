import { CountryCodeListResolver } from './../contact/services/contact.resolver.service';
import {
    SupplierListResolver,
    SupplierEditResolver,
    SupplierContactListResolver,
    SupplierContactResolver
} from './Services/supplier.resolver.service';
import { SupplierEditComponent } from './Components/supplierEditForm.component';
import { SupplierNewComponent } from './Components/supplierNewForm.component';
import { SupplierFormComponent } from './Components/supplierForm.component';
import { SupplierComponent } from './supplier.component';
import { SupplierListComponent } from './Components/supplierList.component';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from './../../_guards/index';

export const SupplierRoutes: Route[] = [
    {
        path: 'supplier',
        component: SupplierComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: SupplierListComponent,
                canActivate: [AuthGuard],
                resolve: {
                    list: SupplierListResolver
                }
            },
            {
                path: 'new',
                component: SupplierFormComponent,
                canActivate: [AuthGuard],
                resolve: {
                    contactlist: SupplierContactListResolver,
                    countryCode: CountryCodeListResolver,
                    type: SupplierContactResolver
                }
            },
            {
                path: 'edit/:supplierId',
                component: SupplierFormComponent,
                canActivate: [AuthGuard],
                resolve: {
                    detail: SupplierEditResolver,
                    contactlist: SupplierContactListResolver,
                    type: SupplierContactResolver
                }
            }
        ]
    }
];
