import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpSupportCategory } from '../_models/feature';
import { BaseServices } from '../kuba/kuba.services';
import { HelpSupportServices } from '../kuba/help-support/services/help-support.service';
import { HelpSupportDetails } from '../kuba/help-support/models/helpsupport'; 
import { Subscription } from 'rxjs';

@Component({
    selector: 'quick-help',
    templateUrl: 'quick-help.component.html',
    providers: [HelpSupportServices]
})
export class QuickHelpComponent implements OnInit {
    //#region variable
    userRole: string;
    businessId: number;
    isAdmin: boolean;
    helpsupportList = new HelpSupportDetails();
    helpsupport = false;
    isVideos = false;
    isStory = true;
    showDropDown = false;
    showNewNotification: boolean;
    notificationTitle: string;
    notificationDesc: string;
    videoLink: any;
    EmailTo: string;
    GeneralContent: string;
    private subscription: Subscription;
    constructor(
        private helpsupportServices: HelpSupportServices,
        private _sanitizer: DomSanitizer) { }
    //#endregion

    //#region constructor

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit(): void {
        let roleId = BaseServices.roleId;
        this.isAdmin = roleId === '1' ? true : false;
        if (roleId === '1') {
            this.userRole = 'admin';
        } else if (roleId === '2') {
            this.userRole = 'portal';
        } else {
            this.userRole = 'business';
        }
        if (roleId != '1') {
            this.businessId = BaseServices.BusinessId;
            this.subscription = this.helpsupportServices.getAllHelpSupportDetails().subscribe(result => {
                this.helpsupportList.Articles = [];
                this.helpsupportList.Story = [];
                this.helpsupportList.Videos = [];
                this.helpsupportList.QuestionsAnswers = [];
                this.helpsupportList.Articles = (result && result.Articles.length > 0) ? result.Articles.slice(0, 5) : [];
                this.helpsupportList.Story = (result && result.Story.length > 0) ? result.Story.slice(0, 1) : [];
                this.helpsupportList.Videos = (result && result.Videos.length > 0) ? result.Videos.slice(0, 5) : [];
                this.helpsupportList.QuestionsAnswers = (result && result.QuestionsAnswers.length > 0) ? result.QuestionsAnswers.slice(0, 5) : [];
                this.EmailTo = (result && result.Email.length > 0) ? "mailto:" + result.Email[0].Content : "mailto:";
                if (result && result.Story.length > 0) {
                    this.GeneralContent = result.Story[0].Content.length >= 270 ?
                        result.Story[0].Content.substring(0, 270) + "&hellip;" : result.Story[0].Content;
                }
                else {
                    this.GeneralContent = "";
                }
                if (result && this.helpsupportList !== null) {
                    this.helpsupport = true;
                    sessionStorage.setItem('helpsupportdetails', JSON.stringify(result));
                }
            });
        }

    }

    /**
 * show popup
 * @param notification {any}
 */
    showTitlePopup(notification: any) {
        this.showNewNotification = true;
        if (notification.CategoryId === HelpSupportCategory.VIDEOS) {
            this.isStory = true;
            this.isVideos = true;
            this.videoLink = this._sanitizer.bypassSecurityTrustResourceUrl(
                notification.ContentLink
            );
        }
        else if (notification.CategoryId === HelpSupportCategory.STORY) {
            this.isStory = false;
            this.isVideos = false;
        }
        else {
            this.isStory = true;
            this.isVideos = false;
        }
        this.notificationTitle = notification.Title;
        this.notificationDesc = notification.Content;
    }

    /**
  * reset popup
  */
    closePopUp() {

        this.showNewNotification = false;
    }
    dropdownToggle() {
        if (this.showDropDown) {
            this.showDropDown = false;
        } else {
            this.showDropDown = true;
        }
    }

    cancel() {
        this.notificationTitle = '';
        this.notificationDesc = '';
        this.isVideos = false;
    }

}