import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
    private static _admin = 1;

    constructor() {}

    static get ADMIN() {
        return this._admin;
    }
}
