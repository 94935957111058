<div class="main-content">
  <div class="main-heading">
    <h3 class="panel-title" translate>MEETING</h3>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="filter-controls">
          <div class="row">
            <div class="col-sm-12">
              <form class="form-horizontal">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cmpname" class="col-sm-4 col-form-label" translate>HOURS_AVAILABLE</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="hrsavlble">
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="cmpno" class="col-sm-4 col-form-label" translate>HOURS_SPENT</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="hrspd">
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="nofemp" class="col-sm-4 col-form-label" translate>Hours_left</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" id="hrslft">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="table-view">
        <!-- Table starts -->
        <p-table [value]="data" [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]"
          [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="typeofmeeting">{{'TYPE_OF_MEETING'|translate}}</th>
              <th pSortableColumn="name">{{'NAME'|translate}}</th>
              <th pSortableColumn="meetingdate">{{'MEETING_DATE'|translate}}</th>
              <th pSortableColumn="starttime">{{'START_TIME'|translate}}</th>
              <th pSortableColumn="endtime">{{'END_TIME'|translate}}</th>
              <th pSortableColumn="hoursspent">{{'HOURS_SPENT'|translate}}</th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-meeting>
            <tr>
              <td>
                <span>{{meeting.typeofmeeting}}</span>
              </td>
              <td>
                <span>{{meeting.name}}</span>
              </td>
              <td>
                <span>{{meeting.meetingdate}}</span>
              </td>
              <td>
                <span>{{meeting.starttime}}</span>
              </td>
              <td>
                <span>{{meeting.endtime}}</span>
              </td>
              <td>
                <span>{{meeting.hoursspent}}</span>
              </td>
              <td class="col-button">
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}">
                  <i class="icon ic-sm icon-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>

          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="7">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- Table Ends-->
      </div>
      <div class="action-btns-wrapper">
        <div class="text-left">
          <button type="button" class="btn btn-primary" (click)="newmeeting()" translate>NEW</button>
        </div>
        <div class="text-right">
          <span class="action-label" translate>EXPORT_TO</span>
          <div class="btn-group ">
            <button type="button" class="btn btn-link " (click)="exportPdf()"><i class="pi pi-file-pdf"></i>
              PDF</button>
            <button type="button" class="btn btn-link " (click)="exportword()"><i class="pi pi-file"></i>Word</button>
            <button type="button" class="btn btn-link" (click)="exportExcel()"><i class="pi pi-file-excel"></i>
              Excel</button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <p-dialog header="{{'NEW_MEETING'|translate}}" [(visible)]="showNewMeeting" [modal]="true"
    [style]="{width: '700px', height:'500px'}" [resizable]="false" [draggable]="false">
    <form>
      <div class="main-content">
        <div class="main-heading">
          <strong translate>MEETING</strong>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="newmeeting" class="col-sm-4 col-form-label" style="margin-left: 10px"
              translate>TYPE_OF_MEETING<span class="required">*</span></label>
            <div class="col-sm-6">
              <select type="text" class="col-sm-6 label-input form-control" style="width: 275px" id="newmeeting">
                <option value="Select" translate>SELECT</option>
                <option *ngFor="let c of data" [ngValue]="c">{{c.Title}}</option>
              </select>
              <button type="button" class="btn btn-link" (click)="typeofmeeting()"><i class="pi pi-plus"></i></button>
            </div>
          </div>
          <div class="form-group">
            <label for="name" class="col-sm-4 col-form-label" style="margin-left: 10px" translate>NAME</label>
            <div class="col-sm-6">
              <select type="text" class="col-sm-6 label-input form-control" style="width: 275px" id="name">
                <option value="Select" translate>SELECT</option>
              </select> <span class="required">*</span>
            </div>
          </div>
          <div class="form-group">
            <label for="date" class="col-sm-4 col-form-label" style="margin-left: 10px" translate>DATE</label>
            <div class="col-sm-6">
              <p-calendar name="date"></p-calendar>

              <span class="required">*</span>
            </div>
          </div>
          <div class="form-group">
            <label for="starttime" class="col-sm-4 col-form-label" style="margin-left: 10px"
              translate>START_TIME</label>
            <div class="col-sm-6">
              <input type="text" class="col-sm-6 label-input form-control" id="starttime" style="width: 275px">

              <span class="required">*</span>
            </div>
          </div>
          <div class="form-group">
            <label for="endtime" class="col-sm-4 col-form-label" style="margin-left: 10px" translate>END_TIME</label>
            <div class="col-sm-6">
              <input type="text" class="col-sm-6 label-input form-control" id="endtime" style="width: 275px">

              <span class="required">*</span>
            </div>
          </div>

          <div class="form-group">
            <label for="hours" class="col-sm-4 col-form-label" style="margin-left: 10px" translate>HOURS_SPENT</label>
            <div class="col-sm-6">
              <input type="text" class="col-sm-6 label-input form-control" id="hours" style="width: 275px">
            </div>
          </div>
          <div class="form-group">
            <label for="comment" class="col-sm-4 col-form-label" style="margin-left: 10px" translate>COMMENTS</label>
            <div class="col-sm-6">
              <textarea type="text" rows="5" cols="10" class="col-sm-6 label-input form-control" id="comment"
                style="width: 275px"></textarea>
            </div>
          </div>
          <div class="action-btns-wrapper">
            <button type="button" class="btn btn-outline-secondary">
              <span class="icon ic-xs icon-back"></span>
              <span translate>BACK_LIST</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="savedata()" translate>SAVE</button>
          </div>

        </div>
      </div>
    </form>
  </p-dialog>
  <p-dialog header="{{'MEETING_TYPE'|translate}}" [(visible)]="showMeetingtype" [modal]="true"
    [style]="{width: '300px', height:'400px'}" [resizable]="false" [draggable]="false">
    <form>
      <div class="row">
        <div class="form-group">
          <label for="meetingtype" class="col-sm-4 col-form-label" translate>TYPE<span class="required">*</span></label>
          <div class="col-sm-6">
            <input type="text" class="col-sm-6 label-input form-control" id="meetingtype" style="width:100px">
          </div>
        </div>
        <div>
          <button type="submit" class="btn btn-outline-primary" translate>VIEW</button>
          <button type="submit" class="btn btn-primary" translate>SAVE</button>
        </div>
      </div>
      <div class="table-view">
        <p-table [value]="data" [rows]="10" [responsive]="true">
          <!-- Header -->
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="type">{{'TYPE'|translate}}</th>
              <th></th>
            </tr>
          </ng-template>
          <!-- Body Content -->
          <ng-template pTemplate="body" let-meeting>
            <tr>
              <td>
                <span>{{meeting.type}}</span>
              </td>
              <td class="col-button">
                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"><i
                    class="icon ic-sm icon-pencil-tip"></i></button>
                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"><i
                    class="icon ic-sm icon-trash"></i></button>
              </td>
            </tr>
          </ng-template>
          <!-- Empty message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td colspan="7">
                {{'NO_RECORDS_FOUND'|translate}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </form>
  </p-dialog>
</div>