
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'upload',
    templateUrl: 'upload-attachment.component.html'
})
export class uploadComponent implements OnInit {
    constructor() { }
    
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
    }
     
}