<div class="page-title">
    <span translate>HAZARD_ANALYSIS</span>
</div>
<p translate>CLEAR_FORM1
</p>
<p translate>CLEAR_FORM2<span style="color:red" translate> CLEAR_FORM6 </span>CLEAR_FORM7</p>
<p translate> CLEAR_FORM3</p>
<p translate> CLEAR_FORM4</p>
<p translate> CLEAR_FORM5 </p>
<div class="text-right">
    <button *ngIf="(hazardAnalysisdata.length == 1 || hazardAnalysisdata.length == 0) && editMode && parentId== 20 " type="button"
      class="btn btn-icon btn-sm btn-border" (click)="AddDefault()">
        <i class="icon ic-lg icon-load-template text-primary"></i>
        <strong style="color:#000" translate>LOAD_TEMPLATE</strong>
    </button>
    <button *ngIf="hazardAnalysisdata.length > 1 && editMode" type="button" class="btn btn-icon btn-sm btn-border"
      (click)="ClearForm(featureId)">
        <i class="icon ic-lg icon-load-template text-primary"></i>
        <strong style="color:#000" translate>CLEAR_FORM</strong>
    </button>
    <button *ngIf="!editMode" type="button" class="btn btn-icon btn-sm btn-border" (click)="exportHazardAnalysis()"
      [ngClass]="'text-success'">
        <i class="icon ic-sm icon-send-file-o"></i>
        <span translate>EXPORT</span>
    </button>
    <button type="button" class="btn btn-link" (click)="toggleEditMode()">
        <span [ngClass]="editMode ? 'text-danger':'text-success'">
            <span *ngIf="editMode" translate>GO_DISPLAY</span>
            <span *ngIf="!editMode" translate>GO_EDIT</span>
            <span class="icon ic-md" [ngClass]="editMode ? 'icon-view-content':'icon-edit-content'"></span>
        </span>
    </button>
</div>
<div class="table-view mbtm-30" *ngIf="hazardAnalysisdata">
    <table class="table table-striped table-bordered form-table">
        <thead>
            <tr>
                <th colspan="2" translate>PROCESS</th>
                <th colspan="3" translate *ngIf="parentId== 20">DESCRIPTION_OF_RISK</th>
                <th colspan="2" translate *ngIf="parentId!= 20">DESCRIPTION_OF_RISK</th>
                <th rowspan="2" translate>RISK_REDUCING_MEASURE</th>
                <th rowspan="2" colspan="3" translate>RISK_MATRIX</th>
                <th rowspan="2" translate>REFERENCE_DOCUMENT</th>
                <th rowspan="2" translate>CONTROL_FREQUENCY</th>
                <th *ngIf="editMode" rowspan="2" colspan="3"></th>
            </tr>
            <tr>
                <th translate>NO.</th>
                <th style="column-width: 120px;" translate>NAME</th>
                <th *ngIf="parentId== 20" style="width: 5px;" translate>BCPA</th>
                <th translate>RISK</th>
                <th style="width: 130px;" translate>DESCRIPTION</th>
            </tr>
        </thead>
        <ng-template ngFor let-hazard let-secIndex="index" [ngForOf]="hazardAnalysisdata">
            <tbody *ngIf="editMode && secIndex== 0">
                <tr  *ngIf="editMode && secIndex== 0" >
                    <td  *ngIf="editMode && secIndex== 0" colspan="18" class="text-right col-btn-right">
                        <button type="button" class="btn btn-outline-success" (click)="addAboveSection(secIndex)" translate>
                            <span class="icon ic-sm icon-add"></span>NEW_SECTION</button>                        
                        <span *ngIf="hazardAnalysisdata.length != 1" style="padding-left: 20px;"></span>                       
                    </td>
                </tr>                
            </tbody>
            <tbody>
                <tr>
                    <td style="display:none;">
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="hazard.Id">
                    </td>
                    <td rowspan="100" class="position-relative">
                        <div class="cell-wrapper">
                            <textarea *ngIf="editMode" class="form-control full-wh form-control-sm" type="text"
                              [(ngModel)]="hazard.No"></textarea>
                            <span *ngIf="!editMode" [innerText]="hazard.No"></span>
                        </div>
                    </td>
                    <td rowspan="100" class="position-relative" style="width: 135px; overflow: overlay;">
                        <div class="cell-wrapper">
                            <textarea *ngIf="editMode" type="text" class="form-control full-wh form-control-sm"
                              [(ngModel)]="hazard.Name"></textarea>
                            <span *ngIf="!editMode" [innerText]="hazard.Name"></span>
                        </div>
                    </td>
                </tr>
                <ng-template ngFor let-hParams let-subSecIndex="index" [ngForOf]="hazard.HazardSubSections">
                    <tr>
                        <td style="display:none;">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="hParams.Id">
                        </td>
                        <td *ngIf="parentId== 20">
                            <input *ngIf="editMode" type="text" class="form-control input-sm"
                              [(ngModel)]="hParams.BCPA">
                            <span *ngIf="!editMode" [innerText]="hParams.BCPA"></span>
                        </td>
                        <td>
                            <input *ngIf="editMode" type="text" class="form-control form-control-sm"
                              [(ngModel)]="hParams.Risk">
                            <span *ngIf="!editMode" [innerText]="hParams.Risk"></span>
                        </td>
                        <td>
                            <input *ngIf="editMode" type="text" class="form-control form-control-sm"
                              [(ngModel)]="hParams.Description">
                            <span *ngIf="!editMode" [innerText]="hParams.Description"></span>
                           </td>
                        <td>
                            <input *ngIf="editMode" type="text" class="form-control form-control-sm"
                              [(ngModel)]="hParams.RiskReduceMeasure">
                            <span *ngIf="!editMode" [innerText]="hParams.RiskReduceMeasure"></span>
                            </td>
                        <td>
                            <p-dropdown [options]="riskMatrix" [(ngModel)]="hParams.RiskMatrix1"
                              (onChange)="CalculateRiskMatrix3(secIndex,subSecIndex)" [disabled]="!editMode">
                            </p-dropdown>
                        </td>
                        <td>
                            <p-dropdown [options]="riskMatrix" [(ngModel)]="hParams.RiskMatrix2"
                              (onChange)="CalculateRiskMatrix3(secIndex,subSecIndex)" [disabled]="!editMode">
                            </p-dropdown>
                        </td>
                        <td
                          [ngClass]="hParams.RiskMatrix3 == 1 ? 'risk-green-indicator' : hParams.RiskMatrix3 == 2 ? 'risk-yellow-indicator' : hParams.RiskMatrix3 == 3 ? 'risk-red-indicator' : 'risk-yellow-indicator'">
                            <div readonly
                              [innerText]="hParams.RiskMatrix3 == 1 ? 'L' : hParams.RiskMatrix3 == 2 ? 'M' : 'H'">
                            </div>
                        </td>
                        <td rowspan="100" class="position-relative" *ngIf="subSecIndex == 0">
                            <div class="cell-wrapper">
                                <textarea *ngIf="editMode" class="form-control full-wh form-control-sm" type="text"
                                  [(ngModel)]="hazard.ReferenceAndDocument"></textarea>
                                <span *ngIf="!editMode" [innerText]="hazard.ReferenceAndDocument"></span>
                            </div>
                        </td>
                        <td rowspan="100" class="position-relative" *ngIf="subSecIndex == 0">
                            <div class="cell-wrapper">
                                <textarea *ngIf="editMode" class="form-control full-wh form-control-sm" type="text"
                                  [(ngModel)]="hazard.ControlFrequency"></textarea>
                                <span *ngIf="!editMode" [innerText]="hazard.ControlFrequency"></span>
                            </div>
                        </td>
                        <td *ngIf="editMode" class="text-right col-button" style="width:60px;text-align:right;">
                            <button type="button" class="btn btn-icon" (click)="addSubSection(secIndex,subSecIndex)">
                                <span class="icon ic-sm icon-add"></span>
                            </button>
                            <button *ngIf="hazard.HazardSubSections.length != 1 && editMode" type="button"
                              class="btn btn-icon" (click)="removeSubSection(secIndex,subSecIndex, hParams.Id)">
                                <span class="icon ic-sm icon-trash"></span>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
            <br>
            <tbody>
                <tr>
                    <td *ngIf="editMode" colspan="18" class="text-right col-btn-right">
                        <button type="button" class="btn btn-outline-success" (click)="addNewSection(secIndex)" translate>
                            <span class="icon ic-sm icon-add"></span>NEW_SECTION</button>
                        <button *ngIf="hazardAnalysisdata.length != 1 && editMode" type="button" class="btn btn-icon"
                          (click)="removeSection(secIndex, hazard.Id)">
                            <span class="icon ic-sm icon-trash"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
    <div class="action-btns-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <div class="symbol-desc-items" *ngIf="parentId== 20" >
                    <span translate>BIOLOGICAL</span>
                    <span translate>CHECMICAL</span>
                    <span translate>PHYSICAL</span>
                    <span translate>ALLERGENS</span>
                </div>
            </div>
            <div class="text-right">
                <button *ngIf="editMode" type="submit" class="btn btn-primary" (click)="SaveHazard()">
                    <span translate>SAVE</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="panel-loader" *ngIf="loading">
    <span>
        <span class="loader-icon"></span>
        <span translate>PROCESSING</span>
    </span>
</div>
<p-confirmDialog header="{{ 'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{ 'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>