import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseServices } from '../kuba/kuba.services';
import { UserRole } from '../kuba/follow-ups/models/deviation';
import { FeatureComponent } from '../kuba/application/components/feature.component';
import { FeaturesBaseServices } from './../kuba/features/services/features-base.services';
import { BusinessServices } from '../kuba/businesses/services/business.services';

@Component({
  moduleId: module.id,
  selector: 'branding',
  templateUrl: 'branding.component.html',

  styleUrls: ['branding.component.css'],
})
export class BrandingComponent implements OnInit {
  @ViewChild(FeatureComponent, { static: false })
  featuresComponent: FeatureComponent;
  assetPath: string = environment.BASE_ASSET_PATH;
  currentUserData: any;
  applicationBranding: any;
  businessBranding: any;
  applicationBaseUrl: any;
  businessId: number;
  licenseCategoryId: number;
  roleId: number;
  constructor(
    public featuresBaseServices: FeaturesBaseServices,
    public businessServices: BusinessServices
  ) {}

  ngOnInit() {
    this.currentUserData = BaseServices.userBase;
    this.roleId = +BaseServices.roleId;
    if (+BaseServices.roleId !== UserRole.ADMIN) {
      if (
        this.currentUserData.BusinessLogo == null ||
        this.currentUserData.BusinessLogo === ''
      ) {
        this.currentUserData.BusinessLogo = null;
        if (window.name === 'Remote') {
          let business = JSON.parse(sessionStorage.getItem('RModeBusiness')!);
          this.businessId = +sessionStorage.getItem('RModeBusinessId')!;
          this.businessBranding = this.currentUserData.BusinessName =
            business.BusinessName;
          this.currentUserData.BusinessLogo = business.BusinessLogo
            ? business.BusinessLogo
            : null;
        }
      } else {
        this.businessBranding = this.currentUserData.BusinessLogo;
        this.businessId = BaseServices.BusinessId;
      }
    }
    if (BaseServices.roleId === '2') {
      this.currentUserData.PortalLogo =
        this.currentUserData.PortalLogo !== null &&
        this.currentUserData.PortalLogo !== ''
          ? this.currentUserData.PortalLogo
          : '';
    }
    this.applicationBaseUrl = this.currentUserData.BaseUrl;
  }
}
