export class Tasklist {

    Id: number;
    BusinessDepartmentId: number;
    Title: string;
    Description: string;
    EndDate: Date;
    Status: string;
    ExecutionUserId: number;
    FollowupUserId: number;
    RepetitionId: number
    ActivityScheduleId: number;
    ChecklistId: number;
    LinkUrl: string;
    CompletedDate: string;
    BusinessId: number;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    ImageUrl: string;
    Deadline: string;
    StatusIcon: string;

}
export class Repetition {
    Id: number;
    TypeName: string;
    Value: string;
}
export class ActivityDelete {
    AdditionalFeatureId: number;
    AdditionalFeatureKey: number;
    FeatureId: number;
    FeatureKey: number;
    isMappedActivity: boolean;
}

