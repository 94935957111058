import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Riskanalysis } from 'src/app/kuba/follow-ups/models/riskanalysis';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { CacheHandlerService } from 'src/app/kuba/cached.services';

@Component({
  selector: 'app-risk-analysis-portal-widget',
  templateUrl: './risk-analysis-portal-widget.component.html',
  styleUrls: ['./risk-analysis-portal-widget.component.css'],
})
export class RiskAnalysisAdminAndPortalWidgetComponent implements OnInit {
  @Input() riskAnalysis: Riskanalysis[] = [];
  approvalStatus: any[] = [];
  riskSummaries: { BusinessId: number; Count: number; LastEdited: Date }[] = [];
  businessList: any[] = [];
  businessMap: { [key: number]: string } = {};
  remoteWindow: any;

  constructor(
    private businessService: BusinessServices,
    private cacheHandlerService: CacheHandlerService
  ) {}

  /**
   * Gets the businessList so we can compare business IDs with the risk analysis data.
   */
  ngOnInit(): void {
    this.fetchBusinessList();
  }

  /**
   * Angular lifecycle hook that is called when any data-bound property of a directive changes.
   *
   * @param changes - An object of SimpleChanges that holds current and previous property values
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['riskAnalysis']) {
      this.processRiskAnalysis();
    }
  }

  /**
   * Fetches the list of businesses from the business service and updates the local business list and map.
   */
  fetchBusinessList(): void {
    this.businessService.list().subscribe(
      (businessList) => {
        this.businessList = businessList;
        this.businessMap = this.createBusinessMap(businessList);
      },
      (error) => {
        console.error('Error fetching business list:', error);
      }
    );
  }

  /**
   * Creates a map of business IDs to business names.
   *
   * @param businessList - Array of businesses
   * @returns A map where keys are business IDs and values are business names
   */
  createBusinessMap(businessList: any[]): { [key: number]: string } {
    const map: { [key: number]: string } = {};
    businessList.forEach((business) => {
      map[business.Id] = business.CompanyName;
    });
    return map;
  }

  /**
   * Processes the risk analysis data to generate summaries.
   */

  processRiskAnalysis(): void {
    const riskMap: {
      [key: number]: {
        BusinessId: number;
        Count: number;
        LastEdited: Date | null;
      };
    } = {};

    this.riskAnalysis.forEach((risk) => {
      if (!riskMap[risk.BusinessId]) {
        riskMap[risk.BusinessId] = {
          BusinessId: risk.BusinessId,
          Count: 0,
          LastEdited: null, // Initially set to null
        };
      }

      riskMap[risk.BusinessId].Count++;

      if (risk.ModifiedOn) {
        const riskModifiedDate = new Date(risk.ModifiedOn);
        if (
          !riskMap[risk.BusinessId].LastEdited ||
          riskMap[risk.BusinessId].LastEdited < riskModifiedDate
        ) {
          riskMap[risk.BusinessId].LastEdited = riskModifiedDate;
        }
      }
    });

    this.riskSummaries = Object.values(riskMap).sort(
      (a, b) =>
        (b.LastEdited ? b.LastEdited.getTime() : 0) -
        (a.LastEdited ? a.LastEdited.getTime() : 0)
    );
  }

  /**
   * Returns the name of a business given its ID.
   *
   * @param businessId - The ID of the business
   * @returns The name of the business
   */
  getBusinessName(businessId: number): string {
    return this.businessMap[businessId] || 'Unknown Business';
  }

  /**
   * Opens the risk analysis for a specific business in a new window and clears cached data.
   *
   * @param businessId - The ID of the business
   */
  openRiskInNewWindow(businessId: number): void {
    if (this.remoteWindow) {
      this.remoteWindow.close();
    }
    this.cacheHandlerService.clearCachedData();

    const businessName = this.getBusinessName(businessId);
    let businessSetting = {
      BusinessName: businessName,
      BusinessLogo: '', // Assuming you need to fetch or have a logo available somewhere
    };

    sessionStorage.setItem('RModeBusiness', JSON.stringify(businessSetting));
    sessionStorage.setItem('RModeBusinessId', businessId.toString());

    let windowOrigin = window.location.origin;
    let windowControl =
      'location=yes,width=' +
      screen.availWidth +
      ',height=' +
      screen.availHeight +
      ',scrollbars=yes,status=yes';

    let baseURL = BaseServices.userBase.BaseUrl;
    this.remoteWindow = window.open(
      `${windowOrigin}/${baseURL}/app/business/${businessId}/follow-up/risk-analysis/`,
      'Remote',
      windowControl
    )!;
  }
}
