import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

import { BaseServices } from 'src/app/kuba/kuba.services';
import { PdfSetting, HelperService } from './../../../_services/helper.service';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { FDVServices } from './../../FDV/services/fdv.service';
import { ProjectServices } from './../../projects/services/project.service';
import { ConfirmationService } from 'primeng/api';
import { RegistrationServices } from '../services/registration.service';
import { RegistrationList } from 'src/app/kuba/Registration/models/registration-list';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

let jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
    selector: 'registration-list',
    templateUrl: 'registration-list.component.html'
})

export class RegistrationListComponent implements OnInit {
    //#region variables
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    parentKey: any;
    parentId: any;
    RegistrationTypeName: any;
    @ViewChild('registration',{static: false}) registration: Table;
    types: any[];
    projectId: number;
    pdf: any;
    isHidden = false;
    isFdv = false;
    isVisible = true;
    selectedEmployee: any;
    registrations: RegistrationList[];
    additionalData: any;
    isGuest = false;
    isUser = false;
    isExport = false;
    isFs = false;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor
    /**
     * constructor
     * @param route {{ActivatedRoute}}
     * @param location {{Location}}
     * @param registrationServices {{RegistrationServices}}
     * @param confirmationService {{ConfirmationService}}
     */
    constructor(private route: ActivatedRoute,
        public registrationServices: RegistrationServices,
        public projectServices: ProjectServices,
        public fdvServices: FDVServices,
        public registerservice: RegistrationServices,
        private confirmationService: ConfirmationService,
        private translate: TranslateService) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        if(this.parentKey === "FS"){
            this.isFs = true;
        }
    }
    //#endregion
    //#region page-event
    /**
     * get registration list
     */
    ngOnInit() {
        // To hide side menu bar
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '4') {
            this.isGuest = true;
            this.isUser = true;
        }
        if (BaseServices.roleId === '5') {
            this.isUser = true;
        }
        if (this.parentKey === 'FDV') {
            this.isFdv = true;
        }
        this.registrations = this.route.snapshot.data['list'];
        if (this.registrations.length > 0) {
            this.isExport = true;
        }
        this.subscriptions.push(this.route.params.subscribe((params: Params) => {
            this.projectId = + params['Id'];
        }));

        if (this.parentKey === 'IC' || this.parentKey === 'FS' || this.parentKey === 'FDV' || this.parentKey === 'OS' || this.parentKey === 'QS') {
            this.isHidden = true;
            this.isVisible = false;
        } else {
            this.isHidden = false;
            this.isVisible = true;
        }
        this.bindDetails();
        this.exportPdf();
    }
    //#endregion
    //#region control-event
    /**
    * filter registration list based ristration type value
    * @param e {{any}}
    * @param typeDropdown{{any}}
    */
    onTypeChanged(e: any, typeDropdown: any) {
        if (e.value) {
            // custom filter datatable
            this.registration.filter(typeDropdown.selectedOption.label, 'RegistrationTypeName', 'equals');
        } else {
            this.registration.reset();
        }
    }
    //#endregion
    //#region method
    /**
     * bind dropdown
     */
    bindDetails() {
        this.types = [];
        this.subscriptions.push(this.translate.stream('SELECT_TYPE').subscribe(val => {
            this.types = [];
            this.types.push(
                { label: val.SELECT_A_TYPE, value: null }
            );
            this.subscriptions.push(this.registerservice.getAllByRegType(this.parentId, this.parentKey).subscribe(result => {
                result.forEach((element: any) => {
                    this.types.push({ label: element.Name, value: element.Name });
                });
            }));
        }));

    }
    /**
     * get registration list
     */
    getAllRegistrations() {
        this.subscriptions.push(this.registrationServices
            .getAllByEach(this.parentId, this.parentKey)
            .subscribe(result => this.registrations = result));
    }

    /**
     * delete registration by id
     * @param value {{any}}
     */
    confirm(value: any) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            header: 'Delete Confirmation',
            icon: 'pi-trash',
            accept: () => {
                this.subscriptions.push(this.registrationServices.deleteRegistration(value)
                    .subscribe(() => {
                        this.getAllRegistrations()
                        this.toasterComponent.callToastDlt();
                    }));
            }
        });
    }

    /**
    * generate pdf for registration list
    */
    exportPdf() {
        this.additionalData = {};
        this.parentKey = this.route.snapshot.parent.parent.data['parent'];

        let columns = [
            { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
            { title: 'Description', dataKey: 'Description' },
            { title: 'Date Registered', dataKey: 'CreatedOn' },
            { title: 'Registered By', dataKey: 'RegisteredBY' },
            { title: 'Edited By', dataKey: 'Modifier' },
        ];
        if (this.parentKey === 'PROJECT') {
            this.additionalData = {
                fileName: 'PROJ_REG_LIST',
                header: 'Project registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                columnNames: columns,
                featureId: this.parentId,
                featureKey: this.parentKey
            }
        } else if (this.parentKey === 'FDV') {
            this.additionalData = {
                fileName: 'FDV_REG_LIST',
                header: 'FDV registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        } else if (this.parentKey === 'FS') {
            this.additionalData = {
                fileName: 'FOOD_SAFETY_REG_LIST',
                header: 'FS registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        } else if (this.parentKey === 'IC' || this.parentKey === 'OS' || this.parentKey === 'QS') {
            this.additionalData = {
                fileName: 'INTERNAL_CONTROL_REG_LIST',
                header: 'Internal control registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Comments', dataKey: 'Comments' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        } else if (this.parentKey === 'FRAMEWORK') {
            this.additionalData = {
                fileName: 'FRAMEWORK_AGREEMENT_REG_LIST',
                header: 'Framework Agreement registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        } else if (this.parentKey === 'SERVICE') {
            this.additionalData = {
                fileName: 'SERVICE_AGREEMENT_REG_LIST',
                header: 'Service Agreement registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        } else if (this.parentKey === 'KUNDE_EL') {
            this.additionalData = {
                fileName: 'EL_REG_LIST',
                header: 'IK-Kunde EL registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        } else {
            this.additionalData = {
                fileName: 'REG_LIST',
                header: 'Registration list',
                businessId: BaseServices.BusinessId,
                cultureInfo: BaseServices.userCultureInfo(),
                featureId: this.parentId,
                featureKey: this.parentKey,
                columnNames: [
                    { title: 'Type of Registrations', dataKey: 'RegistrationTypeName' },
                    { title: 'Description', dataKey: 'Description' },
                    { title: 'Date Registered', dataKey: 'CreatedOn' },
                    { title: 'Registered By', dataKey: 'RegisteredBY' },
                    { title: 'Edited By', dataKey: 'Modifier' },
                ]
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion
}
