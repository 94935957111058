import { BusinessCacheResolver } from './../businesses/services/business.resolver.service';
import { AllDepartmentResolver } from './../follow-ups/services/deviation.resolver.service';
import { UserEditResolver } from './../users/services/user.resolver.service';
import { EmployeeContactServices } from './services/employee-admin.service';
import { EmployeeListResolver, EmployeeWithLoginListResolver } from './services/employee-admin.resolver.service';
import { ContactServices } from './../contact/services/contact.service';
import { EmployeeListComponent } from './components/employee-list.component';
import { CustomerEmployeeLoginComponent } from './components/customer-employee-login.component';
import { CustomerEmployeeComponent } from './components/customer-employees.component';
import { EmployeeAdminMainComponent } from './employee-admin.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../shared/appShared.module';
import { NgModule } from '@angular/core';
import { BusinessListResolver } from '../businesses/services/business.resolver.service';
import { KubaExportModule } from '../shared/kuba-exports/kuba-export.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        HttpClientModule,
        AppSharedModule,
        RouterModule,
        TranslateModule,
        KubaExportModule
    ],
    exports: [
        EmployeeListComponent,
        CustomerEmployeeComponent,
        CustomerEmployeeLoginComponent,
    ],
    declarations: [
        EmployeeListComponent,
        CustomerEmployeeComponent,
        CustomerEmployeeLoginComponent
    ],
    providers: [
        EmployeeContactServices,
        EmployeeListResolver,
        EmployeeWithLoginListResolver,
        UserEditResolver,
        BusinessListResolver,
        BusinessCacheResolver,
        AllDepartmentResolver
    ],
})
export class EmployeeAdminModule { }
