import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import {
    VehiclesCompleteChecklist,
    VehiclesCompleteChecklisttData,
    VehiclesCompleteStatus
} from '../models/complete-checklist';

import { CompleteCheckListServices } from '../services/complete-checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BaseServices } from '../../kuba.services';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-kuba-vehicles-complete-checklist',
    templateUrl: './kuba-vehicles-complete-checklist.component.html',
    styleUrls: ['./kuba-complete-checklist.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class VehiclesCompleteChecklistComponent implements OnInit {
    VehiclesCompleteChecklist: VehiclesCompleteChecklist;
    VehiclesCompleteChecklisttData: VehiclesCompleteChecklisttData[];
    temperaturevalue: SelectItem[];
    featureKey: number;
    showLoader = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private CompleteCheckListServices: CompleteCheckListServices,
        private route: ActivatedRoute,
        private Location: Location,
        private router: Router
    ) { }

    ngOnInit() {
        this.VehiclesCompleteChecklisttData = [];
        this.subscriptions.push(this.CompleteCheckListServices.getVehiclesChecklistById(
            this.route.snapshot.params['clId'], this.route.snapshot.params['fkey']
        ).subscribe(result => {
            if (result) {
                this.VehiclesCompleteChecklist = result;
                this.VehiclesCompleteChecklisttData = JSON.parse(
                    this.VehiclesCompleteChecklist.CheckListData
                );
            }
        }));
    }

    setStatus(event: VehiclesCompleteStatus, status: VehiclesCompleteStatus[]) {
        for (let statusitem of status) {
            if (statusitem.Type == event.Type) {
                statusitem.Value = true;
            } else {
                statusitem.Value = false;
            }
        }
    }


    SaveCompleteCheckList() {
        this.showLoader = true;
        let CultureInfo = sessionStorage.getItem('languageMode');
        this.VehiclesCompleteChecklist.CheckListData = JSON.stringify(
            this.VehiclesCompleteChecklisttData
        );
        this.subscriptions.push(this.CompleteCheckListServices.VehiclesChecklistById(
            this.VehiclesCompleteChecklist
            , CultureInfo
        ).subscribe((result: any) => {
            if (result) {
                this.featureKey = result.FeatureKey;
                this.VehiclesCompleteChecklist = result;
                this.VehiclesCompleteChecklisttData = JSON.parse(
                    this.VehiclesCompleteChecklist.CheckListData
                );
                this.showLoader = false;
                this.Location.back();
            }
        }));
    }

    checkValid() {
        let val = false;
        if (this.VehiclesCompleteChecklisttData) {
            for (let section of this.VehiclesCompleteChecklisttData) {
                let status = section.Status;
                if (
                    status[0].Value === false &&
                    status[1].Value === false &&
                    status[2].Value === false
                ) {
                    val = true;
                }
            }
        }
        return val;
    }
    goBack() {
        this.Location.back();
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
