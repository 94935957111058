import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { AutoWizUser } from 'src/app/kuba/autowiz-login/components/autowizuser';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ConfirmationService } from 'primeng/api';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { PdfSetting } from './../../../_services/helper.service';
import { VehicleServices } from './../services/vehicle.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicleList.component.html'
})
export class VehicleListComponent implements OnInit {
    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    Vehicles = [];
    additionalData: any;
    isHideDeleteIcon = true;
    guestLogin = true;
    isUser = true;
    private subscriptions: Subscription[] = [];
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private vehicleSvc: VehicleServices,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.additionalData = {
            fileName: 'Vehicle List',
            header: 'Vehicle List',
            businessId: BaseServices.BusinessId,
            cultureInfo: BaseServices.userCultureInfo(),
            columnNames: [
                { title: 'Vehicle Name', dataKey: 'VehicleName' },
                { title: 'Brand', dataKey: 'BrandName' },
                { title: 'Model', dataKey: 'ModelName' },
                { title: 'Registration No', dataKey: 'RegistrationNo' },
                { title: 'Registration Year', dataKey: 'RegistrationYear' },
                { title: 'Type of Vehicles', dataKey: 'VehicleTypeName' },
                { title: 'Department', dataKey: 'DepartmentName' }
            ]
        };
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        this.isUser = (BaseServices.roleId === '5');
        document.getElementById('fleetControl').style.display = 'none';
        this.Vehicles = this.route.snapshot.data['list'];
        if (this.Vehicles) {
            this.imageLoad(this.Vehicles);
        }

        if (BaseServices.UserRole === 'Guest') {
            this.isHideDeleteIcon = false;
        } else if (BaseServices.UserRole === 'User') {
            this.isHideDeleteIcon = false;
        } else {
            this.isHideDeleteIcon = true;
        }
    }
    deleteVehicle(id: number) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.subscriptions.push(this.vehicleSvc.deleteVehicle(id).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.bindGrid();
                    }
                }));
            }
        });
    }

    bindGrid() {
        this.subscriptions.push(this.vehicleSvc.getAllVehicles().subscribe(result => {
            if (result) {
                this.imageLoad(result);
            }
        }));
    }
    exportPdf() {
        let pdfSetting = new PdfSetting();
        pdfSetting.date = HelperService.formatDate(new Date());
        pdfSetting.businessName = BaseServices.BusinessName;
        pdfSetting.pageHeader = 'VehiclesDetails';
        let columns = [
            { title: 'Vehicle Name', dataKey: 'Name' },
            { title: 'Brand', dataKey: 'BrandName' },
            { title: 'Model', dataKey: 'ModelName' },
            { title: 'Registration Number', dataKey: 'RegistrationNo' },
            { title: 'Registration Year', dataKey: 'RegistrationYear' },
            { title: 'Type Of Vehicles', dataKey: 'VehicleTypeName' },
            { title: 'Department', dataKey: 'DepartmentName' }
        ];
        HelperService.generatePdf(
            this.Vehicles,
            columns,
            'Vehicles',
            pdfSetting,
            'l'
        );
    }
    syncup() {
        this.subscriptions.push(this.vehicleSvc
            .getAutowizbyUserId(BaseServices.UserId.toString())
            .subscribe(result => {
                if (result != null) {
                    let autoWizUser = new AutoWizUser();
                    autoWizUser.UserName = result.UserName;
                    autoWizUser.Password = result.Password;
                    autoWizUser.BusinessId = BaseServices.BusinessId;
                    autoWizUser.UserId = BaseServices.UserId.toString();
                    this.subscriptions.push(this.vehicleSvc
                        .getAutowizVehicleList(autoWizUser)
                        .subscribe(list => {
                            if (list) {
                                alert('Data synced');
                                this.bindGrid();
                            }
                        }));
                } else {
                    setTimeout(() => {
                        this.router.navigate(['./../../autowiz-login'],
                            {
                                relativeTo: this.route
                            }
                        );
                    }, 6000);
                }
            }));
    }
    fleetControl() {
        this.subscriptions.push(this.vehicleSvc
            .getAutowizbyUserId(BaseServices.UserId.toString())
            .subscribe(result => {
                if (result != null) {
                    this.subscriptions.push(this.vehicleSvc
                        .getFleetControlUrl(BaseServices.UserId.toString())
                        .subscribe(result => {
                            if (result.Result[1] && result.Result[1] !== 'Incorrect Password') {
                                var fleetControl = document.getElementById('fleetControl');
                                fleetControl.setAttribute('src', result.Result[1]);
                                fleetControl.style.display = 'block';
                            } else if (result.Result[1] === 'Incorrect Password') {
                                alert('Username and Password is incorrect');
                                this.router.navigate(['./../../autowiz-login'],
                                    {
                                        relativeTo: this.route
                                    }
                                );
                            }
                        }));
                } else {
                    setTimeout(() => {
                        this.router.navigate(['./../../autowiz-login'],
                            {
                                relativeTo: this.route
                            }
                        );
                    }, 6000);
                }
            }));
    }
    imageLoad(data: any) {
        this.Vehicles = [];
        data.forEach(element => {
            if (element.AutowizVechicleId) {
                element.VehicleTracking = 'icon ic-sm icon-truck';
            } else {
                element.VehicleTracking =
                    'icon ic-sm icon-shopping-cart';
            }
            data = element;
            this.Vehicles.push(data);
        });
    }
    checkAutoWizUser(Username: string, Pasword: string) {
        let autoWizUser = new AutoWizUser();
        autoWizUser.UserName = Username;
        autoWizUser.Password = Pasword;
        autoWizUser.UserId = BaseServices.UserId.toString();
        this.subscriptions.push(this.vehicleSvc.checkAutowizuser(autoWizUser).subscribe(result => { }));
    }
    vehicleTracking(AutowizVechicleId: any, Id: number) {
        if (AutowizVechicleId) {
            setTimeout(() => {
                this.router.navigate(
                    [
                        '../../vehicle/details/' +
                        Id +
                        '/vehicle-tracking/list',
                        { Id: Id }
                    ],
                    { relativeTo: this.route }
                );
            }, 1000);
        } else {
            window.open('https://kuba.no/produkter/kuba-kjorebok/');
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
interface MyWindow extends Window {
    vehicleTracking(): void;
}
declare var window: MyWindow;
