export class PowerLeakage {
    Id: number;
    MeasureProtocolCompleteId: number;
    PowerReading: string;
    Comments: string;
    Status: number;
    CreatedBy: number;
    ModifiedBy:number;
    CreatedOn: Date;
    ModifiedOn: Date;
    ModifiedName: string;
    CreatedName: string; 
}

export class EarthLeakage {
    Id: number;
    MeasureProtocolCompleteId: number;
    CircuitNumber: string;
    ELBreaker: string;
    IsTested: boolean;
    ReleasePower: string;
    DisconnectTime: string;
    Comments: string;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    ModifiedName: string;
    CreatedName: string; 
}

export class Temperature {
    Id: number;
    MeasureProtocolCompleteId: number;
    MPCTemperatureTypeId: string;
    MPCTemperatureModelId: string;
    Celsius: string;
    TempDate: string;
    Status: number;
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date; 
}

export class TemperatureType {
    Id: number;
    Name: string;
    KundeELId: number;
    Status: string;  
    CreatedOn: Date;
    ModifiedOn: Date;
    CreatedBy: number;
    ModifiedBy: number;
    FeatureId:number;
}

export class TemperatureModel {
    Id: number;
    Name: string;
    KundeELId: number;
    Status: string;  
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    FeatureId:number;
}

export class CMPComment {
    Id: number;
    MeasureProtocolCompleteId: number;
    Comments: string;
    Status: string; 
    CreatedBy: number;
    ModifiedBy: number;
    CreatedOn: Date;
    ModifiedOn: Date;
    CreatedName: number;
    ModifiedName: number;
}
