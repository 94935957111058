import { RegistrationType } from './../models/registration-type';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';

import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { environment } from 'src/environments/environment';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { HelperService } from './../../../_services/helper.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { BaseServices } from './../../kuba.services';
import { Location } from '@angular/common';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { RegistrationServices } from '../services/registration.service';
import { RegistrationDocument } from './../models/registration';
import { EmployeeDocumentUploadable } from './../../employees/models/employeeDocument';
import { EmployeeDocumentNode } from 'src/app/kuba/employees/models/employeeDocument';
import { RegistrationList } from 'src/app/kuba/Registration/models/registration-list';
import { Registration } from 'src/app/kuba/Registration/models';
import { TranslateService } from '@ngx-translate/core';
import { Rights } from 'src/app/_models';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
@Component({
    selector: 'registration-new',
    templateUrl: 'registration-new.component.html'
})
export class RegistrationNewComponent implements OnInit {
    //#region variables
    @ViewChild('documentTable',{static: false}) documentTable: Table;
    @ViewChild('dt',{static: false}) dt: Table;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    documntIDArray: number[] = [];
    projectId: number;
    documentUpload: Registration[] = [];
    isEdit: boolean;
    registerType: any;
    RegistrationForm: FormGroup;
    startDate: Date;
    endDate: Date;
    isLabelDisabled = false;
    registrationTypeForm: FormGroup;
    types: SelectItem[];
    newDocumentAdded: any = [];
    additionalData: any;
    additionalUploadData: any;
    editTypes: any = [];
    btype: any = [];
    addType = false;
    registerId: number;
    register: any;
    REGISTER: any;
    selectedType = new RegistrationType();
    parentId: number;
    parentKey: any;
    createdDate: string;
    viewType = false;
    locale = 'en';
    regiatrationTypeId: number;
    upConfig: UploaderConfig;
    uploadedFiles = [];
    isUser = false;
    isGuest = false;
    documentExport = false;
    addNewFile: boolean;
    UploadableFile: EmployeeDocumentUploadable;
    newUploadedFiles: RegistrationDocument[] = [];
    displayPreviewPhoto = false;
    displayPhotoTitle: string;
    displayPhotoURL: string;
    isHideButton = true;
    public startDateOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        height: '32px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableSince: HelperService.formatInputDate(
            new Date().setDate(new Date().getDate() + 1)
        ).date
    };
    isTypeAllocated = false;
    public endDateOptions: IMyDpOptions = this.startDateOptions;
    private subscriptions: Subscription[] = [];
    //#endregion
    //#region constructor
    /**
     * constructor
     * @param route {route}
     * @param location {location}
     * @param formBuilder {formBuilder}
     * @param registerservice {registerservice}
     * @param confirmationService {confirmationService}
     */
    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private formBuilder: FormBuilder,
        public registerservice: RegistrationServices,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.parentId = route.snapshot.parent.parent.params['id'];
        this.parentKey = route.snapshot.parent.parent.data['parent'];
        this.RegistrationForm = this.formBuilder.group({
            RegistrationTypeId: ['', Validators.required],
            Description: [''],
            Comments: ['']
        });
        this.registrationTypeForm = this.formBuilder.group({
            Name: ['', [ValidationService.noWhitespaceValidator]]
        });

        this.additionalData = {
            fileName: 'REGISTRATION_DOCUMENT',
            header: 'Registration  Document',
            businessId: BaseServices.BusinessId,
            featureId: this.parentId,
            featureKey: this.parentKey,
            cultureInfo: BaseServices.userCultureInfo(),
            subFeatureId: this.route.snapshot.params['rid'],
            columnNames: [
                { title: 'Document Name', dataKey: 'FileName' },
                { title: 'Document', dataKey: 'Path' },
                { title: 'Created On', dataKey: 'CreatedOn' },
                { title: 'Created By', dataKey: 'CreatorName' },
            ]
        };
    }
    //#endregion

    //#region page-event
    /**
     * page load event
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.UserRole === 'User') {
            this.isUser = true;
        } else if (BaseServices.UserRole !== 'Editor') {
            this.isHideButton = false;
            if (BaseServices.roleId === '4') {
                this.isGuest = true;
                this.isUser = true;
            }
        }
        let rid = this.route.snapshot.params['rid'];
        this.registerId = +rid;
        this.additionalUploadData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            ParentId: this.parentId,
            ChildId: +rid ? +rid : 0,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            ModuleName: 'Registration'
        };
        let name = 'Registration';
        this.upConfig = {
            title: 'REGISTRATION_DOCUMENT',
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            showRegistrationItem: true,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            showDeleteButton: true,
            uploaderUri: `${environment.BASE_URL}/${BaseServices.apiRoute(
                this.parentKey
            )}/file-upload`,
            addtionalData: this.additionalUploadData
        };
        let result = this.route.snapshot.data['edit'];
        if (rid > 0) {
            this.isEdit = true;
            this.createdDate = result.CreatedOn;
            this.register = {
                RegistrationTypeId: result.RegistrationTypeId,
                Description: result.Description,
                Comments: result.Comments
            };
            (<FormGroup>this.RegistrationForm).setValue(this.register, {
                onlySelf: true
            });
        } else {
            this.isEdit = false;
        }
        this.bindDetails();
        this.docList();
    }
    //#endregion
    //#region methods
    /**
     * binding document
     */
    docList() {
        this.subscriptions.push(this.registerservice
            .getAllDocById(this.registerId)
            .subscribe((result:any) => {
                if (
                    result &&
                    result.RegistrationDocumentDto &&
                    result.RegistrationDocumentDto.length > 0
                ) {
                    this.bindDocuments(result.RegistrationDocumentDto);
                }
            }));
    }

    bindDocuments(documents) {
        let documentName: any;
        this.documentUpload = [];
        if (documents && documents.length > 0) {
            this.addNewFile = false;
            let list = documents.filter(x => x.Status !== 0);
            list.forEach(e => {
                if (e.OriginalFileName != null) {
                    documentName = e.OriginalFileName.replace(/^.*[\\\/]/, '');
                    return (e.OriginalFileName = documentName)
                }
                else {
                    documentName = e.FileName.replace(/^.*[\\\/]/, '');
                    return (e.OriginalFileName = documentName)
                }
            })
            this.documentUpload.push(...list)
            if (this.documentUpload.length > 0) {
                this.documentExport = true;
            }
        }
    }

    /**
     * startdate calendar onselect event
     * @param event {IMyDateModel}
     */
    onRegistrationStartDateChanged(event: IMyDateModel) {
        this.endDateOptions = this.setDateInput(
            this.endDateOptions,
            event,
            'startDate'
        );
        this.startDate = event.jsdate;
        this.docsListDateRangeFilter(this.startDate, this.endDate);
    }
    /**
     * enddate calendar onselect event
     * @param event {IMyDateModel}
     */
    onRegistrationEndDateChanged(event: IMyDateModel) {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        this.startDateOptions = this.setDateInput(
            this.startDateOptions,
            event,
            'endDate'
        );
        if (!date) {
            let copyConfig: IMyDpOptions = HelperService.getCopyOfDatePickerOptions(
                this.startDateOptions
            );
            this.startDateOptions = copyConfig;
        }
        this.endDate = event.jsdate;
        this.docsListDateRangeFilter(this.startDate, this.endDate);
    }

    /**
     * binding type dropdown
     */
    bindDetails() {
        this.types = [];
        this.editTypes = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.types = [];
            this.types.push(
                { label: val.SELECT, value: null }
            );
            let result = this.route.snapshot.data['registrationType'];
            if (result) {
                result.forEach((typeOfRegistration: any) => {
                    this.types.push({
                        label: typeOfRegistration.Name,
                        value: typeOfRegistration.Id
                    });
                    this.editTypes.push({
                        Name: typeOfRegistration.Name,
                        Id: typeOfRegistration.Id
                    });
                });
            }
        }));
    }

    /**
     * view type list
     */
    viewRegistrationType() {
        this.subscriptions.push(this.registerservice
            .getAllByRegType(this.parentId, this.parentKey)
            .subscribe(typeOfRegistration => {
                if (typeOfRegistration) {
                    this.types = [];
                    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                        this.types = [];
                        this.types.push(
                            { label: val.SELECT, value: null }
                        );
                    }));
                    typeOfRegistration.forEach((registration: any) => {
                        this.types.push({
                            label: registration.Name,
                            value: registration.Id
                        });
                    });
                }
                this.editTypes = typeOfRegistration;
            }));
    }
    /**
     * edit type of registration
     */
    editType(registerType: RegistrationType) {
        this.regiatrationTypeId = registerType.Id;
        let result = {
            Name: registerType.Name
        };
        (<FormGroup>this.registrationTypeForm).setValue(result, {
            onlySelf: true
        });
    }

    /**
     * save type of registration
     */
    saveType() {
        let registerType = new RegistrationType();
        registerType.Id = this.regiatrationTypeId ? this.regiatrationTypeId : 0;
        registerType.Name = this.registrationTypeForm.value.Name;
        registerType.FeatureKeyId = this.parentId;
        registerType.Status = '1';
        registerType.BusinessId = BaseServices.BusinessId;
        registerType.CreatedOn = new Date();
        if (registerType.Id > 0) {
            this.subscriptions.push(this.registerservice
                .updateRegType(registerType, this.parentKey)
                .subscribe((registrationEdited:any) => {
                    if (registrationEdited) {
                        this.viewRegistrationType();
                        this.toasterComponent.callToast();
                        this.RegistrationForm.get(
                            'RegistrationTypeId'
                        ).patchValue(registrationEdited.Id);

                    } else {
                        this.isLabelDisabled = true;
                        setTimeout(
                            function () {
                                this.isLabelDisabled = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        } else {
            registerType.FeatureKeyId = this.parentId;
            this.subscriptions.push(this.registerservice
                .createRegType(registerType, this.parentKey)
                .subscribe((registrationAdded:any) => {
                    if (registrationAdded) {
                        this.viewRegistrationType();
                        this.toasterComponent.callToast();
                        this.RegistrationForm.get(
                            'RegistrationTypeId'
                        ).patchValue(registrationAdded.Id);
                    } else {
                        this.isLabelDisabled = true;
                        setTimeout(
                            function () {
                                this.isLabelDisabled = false;
                            }.bind(this),
                            3000
                        );
                    }
                }));
        }
        this.clear();
    }

    clear() {
        this.regiatrationTypeId = 0;
        this.registrationTypeForm = this.formBuilder.group({
            Name: ['']
        });
    }
    onregistrationTypeFormHide() {
        this.registrationTypeForm.reset();
    }
    /**
     * delete type of registration
     * @param registerType
     */
    deleteType(registerType: any) {
        this.subscriptions.push(this.registerservice.getAllByEach(this.parentId, this.parentKey).subscribe(result => {
            if (result) {
                let registrations = result ? result.filter(x => x.RegistrationTypeId === registerType.Id) : [];
                if (registrations.length > 0) {
                    this.isTypeAllocated = true;
                    setTimeout(() => {
                        this.isTypeAllocated = false;
                    }, 3000);
                } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.registerservice
                                .deleteRegType(registerType.Id)
                                .subscribe(() => {
                                    this.viewRegistrationType();
                                    this.toasterComponent.callToastDlt();
                                }));
                        }
                    });
                }
            }
        }))

    }

    onDateChanged(documentCalender: any) {
        // custom date filter in datatable
        this.documentTable.filter(
            documentCalender.selectedOption.label,
            'CreatedOn',
            ''
        );
    }

    /**
     * save registration
     */
    submitForm() {
        if (this.documntIDArray.length > 0) {
            this.subscriptions.push(this.registerservice.deleteDoc(this.documntIDArray).subscribe(result => {
                if (result) {
                    this.docList();                    
                }
            }));
        }
        let register = this.RegistrationForm.value;
        register = new RegistrationList();
        register.Id = this.registerId ? this.registerId : 0;
        register.Status = '1';
        register.FeatureKey = this.parentId;
        register.CreatedBy = BaseServices.UserId;
        register.RegistrationTypeId = this.RegistrationForm.value.RegistrationTypeId;
        register.Description = this.RegistrationForm.value.Description;
        register.Comments = this.RegistrationForm.value.Comments;
        register.CreatedOn = this.createdDate
            ? this.createdDate
            : HelperService.formatDate(new Date());
        register.RegistrationDocumentDto = [];
        let keyBindings = {
            Originalname: 'OriginalFileName',
            FileTypeId: 'FileType'
        };
        this.newUploadedFiles.forEach((file: any) => {
            let registrationDoc = Object.assign({}, file, this.additionalUploadData);
            registrationDoc.id = 0;
            registrationDoc.status = 1;
            registrationDoc.createdBy = BaseServices.UserId;
            registrationDoc.filename = registrationDoc.documentName ? registrationDoc.documentName : registrationDoc.filename;
            register.RegistrationDocumentDto.push(
                HelperService.switchKeysCase(registrationDoc, keyBindings)
            );
        });
        if (this.registerId) {
            register.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.registerservice
                .updateRegistration(register, this.parentKey)
                .subscribe(() => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }));
        } else {
            this.subscriptions.push(this.registerservice
                .addRegistration(register, this.parentKey)
                .subscribe(() => {
                    this.toasterComponent.callToast();
                    this.gotoBack();
                }));
        }
    }

    gotoBack() {
        this.location.back();
    }
    deleteDoc(value: any, index) {
        this.confirmationService.confirm({
            message: this.translate.instant('DELETE_THIS_RECORD'),
            accept: () => {
                this.documentUpload.splice(index, 1)
                let notDeletedData = this.documentUpload;
                this.documentUpload = [];
                notDeletedData.forEach(element => {
                    this.documentUpload.push(element);
                });
                this.documntIDArray.push(value)
                this.toasterComponent.callToastDlt();
            }
        });
    }
    /**
     * Method for setting date range
     * @param dateConfig
     * @param event
     * @param type
     */
    setDateInput(
        dateConfig: IMyDpOptions,
        event: IMyDateModel,
        type: string
    ): IMyDpOptions {
        let date: Date = event.jsdate ? new Date(event.jsdate.getTime()) : null;
        let dateOptions = HelperService.setDateRange(dateConfig, date, type);
        return dateOptions;
    }

    /**
     * file upload events
     * @param event {any}
     */
    saveUploaded(event: any) {
        if (event) {
            this.addNewFile = event.id === 0 ? true : false;
            let eventType = event.eventName;
            switch (eventType) {
                case 'DeleteDefaultFile':
                    if (event.item.data.id === 0) {
                        // remove unsaved attachment
                        this.newUploadedFiles = this.newUploadedFiles.filter(
                            x => x.Path !== event.item.data.path
                        );
                        this.uploadedFiles = this.uploadedFiles.filter(
                            x => x.path !== event.item.data.path
                        );
                        this.newUploadedFiles.pop();
                        this.toasterComponent.callToastDlt();
                    } else {
                        // remove existing attachement
                        this.subscriptions.push(this.registerservice
                            .deleteRegistrationDocument(event.item.data.id)
                            .subscribe((result: any) => {
                                if (result) {
                                    // remove from display list
                                    this.uploadedFiles = this.uploadedFiles.filter(
                                        x => x.path !== event.item.data.path
                                    );
                                    this.toasterComponent.callToastDlt();
                                }
                            }));
                    }
                    break;
                default:
                    let obj = Object.assign({}, event, this.additionalData);
                    this.newUploadedFiles.push(event);
                    break;
            }
        }
    }

    /**
     * TimeTable DateRange Filter
     * @param StartDate {any}
     * @param EndDate {any}
     */
    docsListDateRangeFilter(StartDate: any, EndDate: any) {
        let startDate = StartDate ? StartDate : new Date('1800-01-01');
        let endDate = EndDate ? EndDate : new Date();
        this.subscriptions.push(this.registerservice.getAllDocById(this.registerId).subscribe((list:any) => {
            this.documentUpload = [];
            if (list) {
                let data = list.RegistrationDocumentDto;
                data = data.filter(x => {
                    return (
                        new Date(x.CreatedOn) >= startDate &&
                        new Date(x.CreatedOn) <= endDate
                    );
                });
                this.documentUpload.push(...data);
                this.bindDocuments(this.documentUpload);
            }
        }));
    }
    //#endregion
    /**
        * 
        * @param featureName
        */
    hideTypeOfcase(featureName: string): boolean {
        if (featureName === 'PROJECT') {
            return this.isUserRights(Rights.ADMINISTRATE_PROJECTS);
        } else if (featureName === 'FDV') {
            return this.isUserRights(Rights.ADMINISTRATE_FDV);
        } else if (featureName === 'IC') {
            return this.isUserRights(Rights.ADMINISTRATE_INTERNAL_CONTROL);
        } else if (featureName === 'OS') {
            return this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
        } else if (featureName === 'QS') {
            return this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
        } else if (featureName === 'VEHICLE') {
            return false;
        } else if (featureName === 'FS') {
            return false;
        } else if (featureName === 'KUNDE_EL') {
            return this.isUserRights(Rights.IK_KUNDE_EL_RIGHTS);
        } else if (featureName === 'FRAMEWORK') {
            return this.isUserRights(Rights.FRAMEWORK_AGREEMENT_RIGHTS);
        } else if (featureName === 'SA') {
            return this.isUserRights(Rights.SERVICE_AGREEMENT_RIGHTS);
        }else if (featureName === 'OS') {
            return this.isUserRights(Rights.ADMINISTRATE_OTHER_SYSTEMS);
        }else if (featureName === 'QS') {
            return this.isUserRights(Rights.ADMINISTRATE_QUALITY_SYSTEM);
        }
        return true;
    }

    isUserRights(rightsId: number): boolean {
        return BaseServices.checkUserRights(
            +rightsId,
            BaseServices.UserRole
        );
    }
    //#endregion

    PreviewProjectPhoto(imgPath: any) {
        this.displayPreviewPhoto = true;
        this.displayPhotoURL = imgPath.Path;
        this.displayPhotoTitle = imgPath.FileName;
    }
    ExitPreviewProjectPhoto() {
        this.displayPreviewPhoto = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
