export class RegistrationType {
    Id: number;
    Name: string;
    FeatureId: number;
    BusinessId: number;
    FeatureKeyId: number;
    Status: string;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: string;
}
