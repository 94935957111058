import { HelperService } from '../../../_services/helper.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseServices } from '../../../kuba/kuba.services';
import { ConfirmationService } from 'primeng/api';
import { ContactEdit } from './../models/contactEdit';
import { ToasterComponent } from './../../../_directives/toaster.component';
import { ContactView, ContactWorkLocation } from './../models/contactView';
import { ContactFunctionalArea } from './../models/contactFunctionalArea';
import { SelectItem } from 'primeng/api';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { ContactServices } from '../services/contact.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'contact-form',
    templateUrl: 'contactForm.component.html'
})
export class ContactFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static:false}) toasterComponent: ToasterComponent;
    @Input() isEdit: boolean;
    functions: SelectItem[];
    types: SelectItem[];
    editFunction: any = [];
    countryCodes: SelectItem[];
    selectedFunction = new ContactFunctionalArea();
    addFunction = false;
    contactId: number;
    contactForm: FormGroup;
    workLocationId: number;
    functionForm: FormGroup;
    contactData = new ContactView();
    contactEdit = new ContactEdit();
    isfunctionExists = false;
    functionExisting: string;
    isNameExists: boolean;
    existsAlerttext: string;
    isPortal = false;
    loading = false;
    viewFunction = false;
    isLabelDisabled: boolean;
    functionId: number;
    private subscriptions: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        public contactService: ContactServices,
        private formBuilder: FormBuilder,
        protected location: Location,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {
        this.contactForm = this.formBuilder.group({
            ContactTypeId: ['', Validators.required],
            Name: ['', Validators.required],
            Address: [''],
            Zipcode: [''],
            Location: [''],
            Mobile: [''],
            Telephone: [''],
            Fax: [''],
            Emailid: [
                '',
                [Validators.required, ValidationService.emailValidator]
            ],
            IsSMS: [''],
            IsEmail: [''],
            WorkPosition: [''],
            WorkAddress: [''],
            WorkZipcode: [''],
            WorkLocation: [''],
            ContactFunctionalAreaId: ['', Validators.required],
            Notes: [''],
            ShowInBusiness: [''],
            Website: ['http://', [ValidationService.websiteValidator]],
            Company: [''],
            MobileCode: [47],
            IsEmailNewDeviation: ['']
        });
        this.functionForm = this.formBuilder.group({
            Name: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '2') {
            this.isPortal = true;
        }
        this.isEdit = BaseServices.roleId === '1' ? true : BaseServices.roleId === '2' ? true : false;
        this.types = [];
        this.functions = [];
        this.editFunction = [];
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.types = [];
            this.types.push(
                { label: val.SELECT, value: null }
            );
        }));
        this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.functions = [];
            this.functions.push(
                { label: val.SELECT, value: null }
            );
        }));

        let typeOfContacts = this.activatedRoute.snapshot.data['contactType'];
        if (typeOfContacts) {
            typeOfContacts.forEach((element: any) => {
                this.types.push({ label: element.Name, value: element.Id });
            });
        }
        let functions = this.activatedRoute.snapshot.data['function'];
        if (functions) {
            functions.forEach((element: any) => {
                this.functions.push({ label: element.Name, value: element.Id });
                this.editFunction.push({ Name: element.Name, Id: element.Id });
            });
        }

        this.countryCodes = [];
        let coutryCodes = this.activatedRoute.snapshot.data['countryCode'];
        if (coutryCodes) {
            coutryCodes.forEach((element: any) => {
                this.countryCodes.push({
                    label: '+' + element.Phonecode,
                    value: '+' + element.Phonecode
                });
            });
        }
        this.contactData = this.activatedRoute.snapshot.data['edit'];
        if (this.contactData) {
            this.contactId = this.contactData.Id;
            this.workLocationId = this.contactData.ContactWorkLocationId;

            let contact = {
                ContactTypeId: this.contactData.ContactTypeId,
                Name: this.contactData.Name,
                Address: this.contactData.Address,
                Zipcode: this.contactData.Zipcode,
                Location: this.contactData.Location,
                Mobile: this.contactData.Mobile,
                Telephone: this.contactData.Telephone,
                Emailid: this.contactData.Emailid,
                Fax: this.contactData.Fax,
                IsSMS: this.contactData.IsSMS ? this.contactData.IsSMS : false,
                IsEmail: this.contactData.IsEmail
                    ? this.contactData.IsEmail
                    : false,
                Notes: this.contactData.Notes,
                ContactFunctionalAreaId: this.contactData
                    .ContactFunctionalAreaId
                    ? this.contactData.ContactFunctionalAreaId
                    : '',
                ShowInBusiness: this.contactData.ShowInBusiness
                    ? this.contactData.ShowInBusiness
                    : false,
                WorkPosition: this.contactData.WorkPosition
                    ? this.contactData.WorkPosition
                    : '',
                Company: this.contactData.WorkLocationCompany
                    ? this.contactData.WorkLocationCompany
                    : '',
                WorkAddress: this.contactData.WorkLocationAddress
                    ? this.contactData.WorkLocationAddress
                    : '',
                WorkZipcode: this.contactData.WorkLocationZip
                    ? this.contactData.WorkLocationZip
                    : '',
                WorkLocation: this.contactData.WorkLocationName
                    ? this.contactData.WorkLocationName
                    : '',
                Website: this.contactData.WorkLocationWebSite
                    ? this.contactData.WorkLocationWebSite
                    : '',
                MobileCode: this.contactData.CountryCode
                    ? this.contactData.CountryCode
                    : '',
                IsEmailNewDeviation: this.contactData.IsEmailNewDeviation
                    ? this.contactData.IsEmailNewDeviation
                    : '',
            };
            (<FormGroup>this.contactForm).setValue(contact, { onlySelf: true });
        }
    }

    bindFunctionDropdown() {
        this.subscriptions.push(this.contactService.getAllByFunction().subscribe(result => {
            if (result) {
                this.functions = [];
                this.functions.push({
                    label: 'Select a Function',
                    value: null
                });
                result.forEach((element: any) => {
                    this.functions.push({
                        label: element.Name,
                        value: element.Id
                    });
                });
                this.editFunction = result;
            }
        }));
    }
    editFunctions(contactFunction: any) {
        this.functionId = contactFunction.Id;
        let result = {
            Name: contactFunction.Name
        };
        (<FormGroup>this.functionForm).setValue(result, { onlySelf: true });
    }
    saveFunction() { 
        let contactFunction = new ContactFunctionalArea();
        contactFunction.Id = this.functionId ? this.functionId : 0;
        contactFunction.Name = this.functionForm.value.Name;
        contactFunction.ApplicationId = BaseServices.ApplicationId;
        contactFunction.Status = 1;
        contactFunction.CreatedBy = BaseServices.UserId;
        if (contactFunction.Id > 0) {
            this.subscriptions.push(this.contactService
                .updateFunction(contactFunction)
                .subscribe((result:any) => {
                    if (result) {
                        this.bindFunctionDropdown();
                        this.toasterComponent.callToast();
                        this.contactForm.get('ContactFunctionalAreaId')!.patchValue(result.Id);
                        this.addFunction = false;
                        this.functionId = 0;
                    } else {
                        this.isNameExists = true;
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        setTimeout(
                             () => {
                                this.isNameExists = false;
                            },
                            3000
                        );
                    }
                }));
                this.selectedFunction = new ContactFunctionalArea();
            } else {
            this.isNameExists = false;
            this.subscriptions.push(this.contactService
                .createFunction(contactFunction)
                .subscribe((result:any) => {
                    if (result) {
                        this.bindFunctionDropdown();
                        this.toasterComponent.callToast();
                        this.contactForm
                        .get('ContactFunctionalAreaId')!
                        .patchValue(result.Id);
                        this.addFunction = false;
                    } else {
                        this.isNameExists = true;
                        this.existsAlerttext = this.translate.instant(`TOPIC_EXIST`);
                        setTimeout(
                             () => {
                                this.isNameExists = false;
                            },
                            3000
                        );
            }
        }));
                
                this.functionForm.reset();
                this.clearfunction();
        }
    }

    clearfunction() {
        this.selectedFunction.Id = 0;
        this.selectedFunction.Name = '';
    }

    deleteFunction(value: any) {
        this.subscriptions.push(this.contactService
            .checkExistingFunction(value)
            .subscribe((result: any) => {
                if (result) {
                    this.isfunctionExists = true;
                    this.functionExisting =
                        'You cannot delete this Function / Role as it is being used by another Contact';
                    setTimeout(
                         () => {
                            this.isfunctionExists = false;
                        },
                        3000
                    );
                } else {
                    this.confirmationService.confirm({
                        message: this.translate.instant('DELETE_THIS_RECORD'),
                        accept: () => {
                            this.subscriptions.push(this.contactService
                                .deleteType(value)
                                .subscribe(() => {
                                    this.bindFunctionDropdown();
                                    this.toasterComponent.callToastDlt();
                                }));
                        }
                    });
                }
            }));
    }
    submitForm() {
        if (this.contactForm.valid) {
            if (BaseServices.roleId === '2') {
                this.contactEdit.IsPortal = true;
                this.contactEdit.PortalId = BaseServices.UserId;
            }
            this.contactEdit.Id = this.contactId ? this.contactId : 0;
            this.contactEdit.BusinessId = BaseServices.BusinessId;
            this.contactEdit.ContactTypeId = this.contactForm.value.ContactTypeId;
            this.contactEdit.Name = this.contactForm.value.Name;
            this.contactEdit.Address = this.contactForm.value.Address;
            this.contactEdit.Zipcode = this.contactForm.value.Zipcode;
            this.contactEdit.Location = this.contactForm.value.Location;
            this.contactEdit.CountryCode = this.contactForm.value.MobileCode;
            this.contactEdit.Mobile = this.contactForm.value.Mobile;
            this.contactEdit.Telephone = this.contactForm.value.Telephone;
            this.contactEdit.Emailid = this.contactForm.value.Emailid;
            this.contactEdit.IsSMS = this.contactForm.value.IsSMS;
            this.contactEdit.CreatedBy = BaseServices.UserId;
            this.contactEdit.IsEmail = this.contactForm.value.IsEmail;
            this.contactEdit.AppId = BaseServices.ApplicationId;
            this.contactEdit.CreatedOn = HelperService.formatDate(new Date());
            this.contactEdit.IsEmailNewDeviation = this.contactForm.value.IsEmailNewDeviation;
            this.contactEdit.ShowInBusiness = this.contactForm.value
                .ShowInBusiness
                ? this.contactForm.value.ShowInBusiness
                : false;
            this.contactEdit.Fax = this.contactForm.value.Fax;
            this.contactEdit.WorkPosition = this.contactForm.value.WorkPosition;
            this.contactEdit.Notes = this.contactForm.value.Notes;
            this.contactEdit.CountryCode = this.contactForm.value.MobileCode;
            this.contactEdit.ContactFunctionalAreaId = this.contactForm.value.ContactFunctionalAreaId;
            this.contactEdit.Status = 1;
            let workplace = new ContactWorkLocation();
            workplace.Id = this.workLocationId ? this.workLocationId : 0; // new work location
            workplace.ContactId = 0; // new contact
            workplace.Address = this.contactForm.value.WorkAddress;
            workplace.Company = this.contactForm.value.Company;
            workplace.Location = this.contactForm.value.WorkLocation;
            workplace.Zipcode = this.contactForm.value.WorkZipcode;
            workplace.Website = this.contactForm.value.Website;
            this.contactEdit.ContactWorkLocation = workplace;
            if (this.contactEdit.Id > 0) {
                // update work location details
                this.subscriptions.push(this.contactService.update(this.contactEdit).subscribe(() => {
                    this.toasterComponent.callToast();
                    this.clear();
                }));
            } else {
                // add new work location details
                this.subscriptions.push(this.contactService.add(this.contactEdit).subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                        this.clear();
                    }
                }));
            }
        }
    }
    gotoList() {
        this.location.back();
    }
    clear() {
        this.contactForm = this.formBuilder.group({
            ContactTypeId: ['', Validators.required],
            Name: ['', Validators.required],
            Address: [''],
            Zipcode: [''],
            Location: [''],
            Mobile: [''],
            Telephone: [''],
            Fax: [''],
            Emailid: [''],
            IsSMS: [''],
            IsEmail: [''],
            WorkPosition: [''],
            WorkAddress: [''],
            WorkZipcode: [''],
            WorkLocation: [''],
            ContactFunctionalAreaId: [''],
            Notes: [''],
            ShowInBusiness: [''],
            Website: [''],
            Company: [''],
            MobileCode: [47]
        });
    }
    /**
     * mobilenumber keypress event restrict number greater than 20 and restrict text
     */
    onMobileNumber(e: any, limitNumber: number) {
        const limit = limitNumber;
        let charCode = e.which ? e.which : e.keyCode;
        if (
            e.target.value.length === limit ||
            (charCode > 31 && (charCode < 48 || charCode > 57))
        ) {
            e.preventDefault();
        }
    }

onFunctionkDialogHide() {
    this.functionForm.reset();
}
ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }
}
