import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Electro } from '../../models/electrocontract';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { ActivatedRoute } from '@angular/router';
import { Options } from 'selenium-webdriver/ie';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ElectroDocumentAssessment, ElectroInstallationBuliding } from '../../models/electroinstallation';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ElectroInstallationService } from '../../services/electro-installation.services';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';


@Component({
    selector: 'document-assessment',
    templateUrl: 'document-assessment.component.html'
})

export class DocumentAssessmentComponent implements OnInit {

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @Input()DocumentAssessment: ElectroDocumentAssessment;
    contractId: number;
    editMode = false;
    DocumentReview: { 'id': number; 'name': string; 'comments': string; }[];
    riskGroup: SelectItem[];
    editorName: string;
    page: string;
    createdOn: string;
    modifiedOn: string;
    inspectionId: number;
    formData: any;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private location: Location,
        private electroInstallationService: ElectroInstallationService
    ) {
        this.formData = JSON.parse(sessionStorage.getItem('formData'));
        sessionStorage.removeItem('formData');
    }

    ngOnInit() {
        this.inspectionId = +this.route.snapshot.parent.params['Iid'] ?
            +this.route.snapshot.parent.params['Iid'] : +this.route.snapshot.params['Iid'];
        this.contractId = this.route.snapshot.parent.parent.params['cid'];
        let DocumentAssessmentComponent = this.route.snapshot.data['DocumentAssessment']

        this.DocumentReview = [
            {
                'id': 1,
                'name': "HMS-målsetting",
                'comments': ""
            },
            {
                'id': 2,
                'name': "Instruks for instruert personell",
                'comments': ""
            },
            {
                'id': 3,
                'name': "Tilsynsrapporter",
                'comments': ""
            },
            {
                'id': 4,
                'name': "Bygningstegninger",
                'comments': ""
            },
            {
                'id': 5,
                'name': "Kursoversikt",
                'comments': ""
            },
            {
                'id': 6,
                'name': "Samsvarserklæring datert før januar 1999",
                'comments': ""
            },
            {
                'id': 7,
                'name': "Dokumentasjon på elektrisk utstyr",
                'comments': ""
            },
            {
                'id': 8,
                'name': "Dokumentasjon på elektromedisinsk utstyr",
                'comments': ""
            },
            {
                'id': 9,
                'name': "Brukerveiledninger for elektrisk utstyr",
                'comments': ""
            },
            {
                'id': 10,
                'name': "Monteringsveiledninger for utstyr med høy brannrisiko",
                'comments': ""
            },
            {
                'id': 11,
                'name': "Dokumentasjon på varmekabler i tak og gulv",
                'comments': ""
            },
            {
                'id': 12,
                'name': "Tegninger over det elektriske anlegget",
                'comments': ""
            },
            {
                'id': 13,
                'name': "Beregningsunderlag for vern og ledertverrsnitt",
                'comments': ""
            },
            {
                'id': 14,
                'name': "Dokumentasjon på om anlegget er bygd for sakkyndig eller usakkyndig betjening",
                'comments': ""
            },
            {
                'id': 15,
                'name': "Stoffkartotek",
                'comments': ""
            }];

        this.initForm(this.DocumentAssessment);
    }

    /**
     * initialize form group and supply values to control on edit.
     * @param DocumentAssessment 
     */
    initForm(DocumentAssessment?: ElectroDocumentAssessment) {
        if (DocumentAssessment) {
            this.DocumentAssessment = DocumentAssessment;
            this.DocumentAssessment.Version = DocumentAssessment.Version ? DocumentAssessment.Version : 1;
            this.editorName = DocumentAssessment.EditorName ? DocumentAssessment.EditorName : '';
            if (this.DocumentAssessment.CreatedOn) {
                this.createdOn = this.DocumentAssessment.CreatedOn;
                let cDateObj = new Date(this.DocumentAssessment.CreatedOn);
                this.DocumentAssessment.CreatedOn = cDateObj.toLocaleDateString('en-GB');
            }
            if (this.DocumentAssessment.ModifiedOn) {
                this.modifiedOn = this.DocumentAssessment.ModifiedOn;
                let mDateObj = new Date(this.DocumentAssessment.ModifiedOn);
                this.DocumentAssessment.ModifiedOn = mDateObj.toLocaleDateString('en-GB');
            }

            this.DocumentReview = <any>JSON.parse(this.DocumentAssessment.DocumentReview);
        }
        else {
            this.DocumentAssessment = new ElectroDocumentAssessment();
            if (this.inspectionId && this.formData) {
                this.DocumentAssessment.Version = this.formData.Version;
            }
            else {
                this.DocumentAssessment.Version = 1;
            }
        }
        this.DocumentAssessment.DocumentNumber = "IKK-040501";
        this.page = "page 1 of 7";
    }
    toggleEditMode() {
        this.editMode = !this.editMode;
    }
    addItem(item) {
        let itemsToShift = this.DocumentReview.splice(this.DocumentReview.indexOf(item) + 1, this.DocumentReview.length);
        let newItem = { 'id': item.id + 1, 'name': "", 'comments': "" };
        this.DocumentReview.push(newItem);
        itemsToShift.forEach(i => {
            ++i.id;
            this.DocumentReview.push(i);
        });
    }
    deleteItem(item) {
        this.DocumentReview.splice(this.DocumentReview.indexOf(item), 1);
    }

    /**
     * save document assessment.
     */
    saveDocumentAssessment() {
        this.DocumentAssessment.DocumentReview = JSON.stringify(this.DocumentReview);
        this.DocumentAssessment.BusinessId = BaseServices.BusinessId;
        if (this.DocumentAssessment.Id > 0) {
            this.DocumentAssessment.CreatedOn = this.createdOn;
            this.DocumentAssessment.ModifiedOn = this.modifiedOn;
            this.DocumentAssessment.ModifiedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.updateDocumentAssessment(this.DocumentAssessment).subscribe(result => {
                if (result) {
                    this.toasterComponent.callToast();
                }
            }));
        }
        else {
            this.DocumentAssessment.InspectionId = this.inspectionId;
            this.DocumentAssessment.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.electroInstallationService.saveDocumentAssessmsent(this.DocumentAssessment).subscribe(result => {
                if (result) {
                    this.DocumentAssessment.Id = result.Id;
                    this.createdOn = result.CreatedOn;
                    this.toasterComponent.callToast();
                }
            }));
        }
    }
    /**
     * save document assessment and back to installation forms tab
     */
    saveBackToList() {
        this.saveDocumentAssessment()
        setTimeout(() => {
            this.location.back();
        }, 2000);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
          sub.unsubscribe();
        });
      }
}