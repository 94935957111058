import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

import { BaseServices } from './../kuba/kuba.services';

@Component({
  moduleId: module.id,
  selector: 'header-top',
  templateUrl: 'headerTop.component.html',
  styleUrls: ['headerTop.component.css'],
})
export class HeaderTopComponent implements OnInit {
  searchText: string;
  searchForm: FormGroup;
  searchMode: SelectItem[];
  selectedMode: string;
  isSearchEmpty: boolean;
  userrole = false;

  private subscription: Subscription;
  isMedi3: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {
    this.searchForm = this.fb.group({
      txtSearch: [''],
      searchMode: [''],
      selectedMode: [''],
    });
    let role = BaseServices.roleId;
    if (role == '4') {
      this.userrole = true;
    }
  }

  ngOnInit() {
    this.searchText = '';
    this.selectedMode = 'Act';
    this.subscription = this.translate
      .stream('GLOBAL_SEARCH_DROPDOWN')
      .subscribe((val) => {
        this.searchMode = [];
        this.searchMode.push(
          { label: val.ACTIVITIES, value: 'Act' },
          { label: val.ARTICLE, value: 'Art' },
          { label: val.DEVIATION, value: 'Dev' }
        );
      });
    if (BaseServices.ApplicationId == 5) {
      this.isMedi3 = true;
    }
  }

  // Search option
  searchApp(): void {
    if (this.searchText.length > 0) {
      this.router.navigate(
        [
          'searchPage/' +
            BaseServices.BusinessId +
            '/' +
            this.searchText +
            '/' +
            this.selectedMode,
        ],
        { relativeTo: this.route }
      );
      this.searchText = '';
    } else {
      this.isSearchEmpty = true;
    }
  }
  /**
   * popup hide
   */
  informationHide() {
    this.isSearchEmpty = false;
  }
  openUrlInNewTab() {
    const url = 'https://kuba.no/kurs-og-radgivning/hmskurs/';
    window.open(url, '_blank'); // Opens the URL in a new tab
  }
  openUrlInNewTabMed() {
    const url = 'https://www.medi3.no/for-bedrift/vi-tilbyr/kurs/';
    window.open(url, '_blank'); // Opens the URL in a new tab
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
