<div class="main-content">
    <div class="main-heading">
        <strong *ngIf="applicationId != 5 && isAdmin" translate>CUSTOMIZE_HOMEPAGE</strong>
        <strong *ngIf="applicationId == 5 && isAdmin" translate>SYSTEM_INFORMATION</strong>
        <strong *ngIf="!isAdmin" translate>CUSTOMIZE_DASHBOARD</strong>
    </div>
    <div class="panel-tabs">
        <nav>
            <ul class="nav nav-pills" *ngIf="isAdmin">
                <li role="presentation" *ngIf="isShowSubFeatures">
                    <a (click)="changeNavigation(45)" href="javascript:void(0)" translate>ABOUT_US</a>
                </li>
                <li role="presentation" *ngIf="isShowSubFeatures">
                    <a (click)="changeNavigation(46)" href="javascript:void(0)" translate>CONTACTS_US</a>
                </li>
                <li role="presentation" *ngIf="isShowSubFeatures">
                    <a (click)="changeNavigation(47)" href="javascript:void(0)" translate>SERVICES</a>
                </li>
                <li role="presentation" *ngIf="isShowSubFeatures">
                    <a (click)="changeNavigation(48)" href="javascript:void(0)" translate>PRODUCTS</a>
                </li>
                <li role="presentation" *ngIf="isShowSubFeatures">
                    <a (click)="changeNavigation(49)" href="javascript:void(0)" translate>ABOUT_KUBA</a>
                </li>
                <li role="presentation">
                    <a (click)="changeNavigation(50)" href="javascript:void(0)" translate>DASHBOARD_TEXT</a>
                </li>
                <li role="presentation">
                    <a (click)="changeNavigation(51)" href="javascript:void(0)">{{'ABOUT' | translate}}
                        {{applicationName}}</a>
                </li>
                <li role="presentation" *ngIf="isShowSubFeatures">
                    <a (click)="changeNavigation(52)" href="javascript:void(0)" translate>HELP</a>
                </li>
                <li role="presentation">
                    <a (click)="changeNavigation(53)" href="javascript:void(0)" translate>ABOUT</a>
                </li>
            </ul>
            <ul class="nav nav-pills" *ngIf="!isAdmin">
                <li role="presentation" class="{{logoclass}}" routerLinkActive="active">
                    <a (click)="changeNavigation(56)" routerLink="./add" href="javascript:void(0)" translate>LOGO</a>
                </li>
                <li role="presentation" routerLinkActive="active">
                    <a (click)="changeNavigation(57)" routerLink="./customize-news" href="javascript:void(0)" translate>NEWS</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="HomepageForm">
                <div class="upload-file-container" *ngIf="isVideoUpload">

                    <div class="upload-actions">
                        <input type="file" accept="video/mp4,video/avi,video/*" id="files" class="hidden"
                          (change)="filesSelectForVideo($event)" />
                        <label for="files" class="btn btn-outline-primary" translate>UPLOAD_VIDEO</label>
                    </div>
                    <div *ngIf="uploadStart">
                        <span translate>VIDEO_UPLOADING</span>
                    </div>
                    <div *ngIf="hmsVideo != null">
                        <video *ngIf="hmsVideo" controls>
                            <source src={{hmsVideo}} type="video/mp4" width="10px" height="10px">
                        </video>
                    </div>
                    <a (click)="deleteVideo(videoId)" *ngIf="!isDefaultVideo" class="btn btn-icon"
                      title="{{'DELETE' | translate}}" routerLinkActive="active">
                        <i class="icon ic-sm icon-trash"></i>
                        <span translate>DELETE_VIDEO</span>
                    </a>
                </div>

                <div class="upload-file-container" *ngIf="portalLogo">
                    <div class="form-group">
                        <div class="upload-actions">
                            <input type="file" id="uploadimage" class="hidden" (change)="filesSelect($event)" />
                            <label for="uploadimage" class="btn btn-outline-primary" translate>UPLOAD_IMAGE</label>
                        </div>
                        <div *ngIf="uploadStart">
                            <span translate>IMAGE_IS_UPLOADING</span>
                        </div>
                        <div *ngIf="logo != null">
                            <img src="{{logo}}" alt="" width="100" height="100">
                        </div>
                    </div>
                </div>
                <div *ngIf="videoTab">
                    <p-editor formControlName="ContentData" [style]="{'height':'320px'}"></p-editor>
                </div>
            </form>
            <div class="action-btns-wrapper">
                <button class="btn btn-outline-secondary" (click)="clear()" translate>CLEAR</button>
                <button class="btn btn-primary" (click)="saveData()" translate>SAVE</button>
                <button class="btn btn-secondary" (click)="back()" translate>BACK</button>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>