import { EL } from 'src/app/kuba/kunde-EL/models/el-edit';
import { FDV } from './../../FDV/models/fdvlist';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import {
  CompleteCheckList,
  CompleteChecklistEdit,
  CompleteChecklistSection,
  CompleteChecklistItemSection,
  CompleteChecklistStatus,
  CompleteAttachment,
  DeviationIds
} from '../models/complete-checklist';
import { SelectItem } from 'primeng/api';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DeviationServices } from '../../deviation/services/deviation.service';
import { BaseServices } from '../../kuba.services';
import { environment } from '../../../../environments/environment';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { CompleteCheckListServices } from '../services/complete-checklist.service';
import { ArchiveServices } from '../../archive/archive.services';
import * as _ from 'lodash';
import { UploadedFile } from 'src/app/shared/ecpl-document-explorer/interfaces';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { FeatureKey, Roles } from '../../../_models';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/kuba/users/services/user.service';
import { BusinessServices } from 'src/app/kuba/businesses/services/business.services';
import { JobAnalysisService } from 'src/app/kuba/safety-job-analysis/services/jobanalysis.services';
import { Subscription } from 'rxjs';
import { Feature } from '../../features/models/features-base.model';
import { FeaturesBaseServices } from '../../features/services/features-base.services';
@Component({
  selector: 'app-kuba-complete-checklist',
  templateUrl: './kuba-complete-checklist.component.html',
  styleUrls: ['./kuba-complete-checklist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class KubaCompleteChecklistComponent implements OnInit {
  CompleteCheckList: CompleteCheckList = {};
  responsibleperson: SelectItem[];
  scoreList: SelectItem[];
  CompleteChecklistEdit: CompleteChecklistEdit;
  jsonString: string;
  upConfig: UploaderConfig;
  treeNode: any;
  treeActiveNode: any;
  uploadedFiles: any;
  additionalData: any;
  type: string;
  featureId: any;
  feaureKey: number;
  routeType: string;

  checklistTitle: string;
  isScoreIndex = false;
  signature: string;
  CompleteChecklistItemSection: CompleteChecklistItemSection;

  selectFolderId?: number;
  selectFolderName?: string;
  aproval: boolean;
  checkListReference: SelectItem[];
  referencechecklist: string;
  showLoader = true;
  featureTreeNode: any;
  selectFeatureFolderName?: string;
  featureTreeActiveNode: any;
  selectFeatureFolderId?: number;
  elFeatureKey: number;
  parentKey: any;
  treeType = 'checklist';
  projectTitle: any;
  pathName: string;
  businessFeature: Feature[];
  SendonlytoDepartment: any;
  @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  private subscriptions: Subscription[] = [];
  followService: any;
  //#region variables

  constructor(
    private route: ActivatedRoute,
    private deviationServices: DeviationServices,
    private CompleteCheckListServices: CompleteCheckListServices,
    private archiveServices: ArchiveServices,
    private datePipe: DatePipe,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    protected location: Location,
    protected userServices: UserService,
    private businessServices: BusinessServices,
    private safetyjobservice: JobAnalysisService
  ) {

    this.responsibleperson = [];
    this.parentKey = this.route.snapshot.params['FeatureKey'];
    this.featureId = this.route.snapshot.params['FeatureId'];

    if (this.featureId == 15 || this.featureId == 26 || this.featureId == 0) {
      this.subscriptions.push(this.businessServices
        .getUsersByBusiness(BaseServices.BusinessId)
        .subscribe(result => {
          this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
            this.responsibleperson = [];
            this.responsibleperson.push(
              { label: val.SELECT, value: null }
            );
          }));
          result.forEach(element => {
            this.responsibleperson.push({
              label: element.Name,
              value: element.Id
            });
          });
        }));
    }
    else if (this.featureId == 16 || this.featureId == 18 || this.featureId == 19 || this.featureId == 20 || this.featureId == 21 || this.featureId == 22 || this.featureId == 23 || this.featureId == 24 || this.featureId == 43 || this.featureId == 41 || this.featureId == 42) {
      this.subscriptions.push(this.businessServices.getAllUsersForResponsible(BaseServices.BusinessId, this.parentKey, this.featureId)
        .subscribe((result: any) => {
          this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe((val: any) => {
            this.responsibleperson = [];
            this.responsibleperson.push(
              { label: val.SELECT, value: null }
            );
          }));
          result.forEach(element => {
            this.responsibleperson.push({
              label: element.Name,
              value: element.Id
            });
          });
        }));
    }



    this.additionalData = {
      ApplicationId: BaseServices.ApplicationId,
      BusinessId: BaseServices.BusinessId,
      Status: '1',
      CreatedOn: new Date(),
      ModifiedBy: BaseServices.UserId,
      ModifiedOn: new Date(),
      ModuleName: 'completechecklist'
    };

    this.uploadedFiles = [];
    this.elFeatureKey = FeatureKey.KUNDE_EL;
  }

  selectTab(tab_id: number) {
    if (this.staticTabs != undefined) {
      this.staticTabs.tabs[tab_id].active = true;
    }
  }

  disableEnable() {
    if (this.staticTabs != undefined) {
      this.staticTabs.tabs[2].disabled = true;
    }
  }

  setStatus(
    event: CompleteChecklistStatus,
    status: CompleteChecklistStatus[],
    CompleteChecklistItemSection: CompleteChecklistItemSection
  ) {
    for (let statusitem of status) {
      if (statusitem.Label === event.Label) {
        statusitem.value = true;
      } else {
        statusitem.value = false;
      }
    }

    this.subscriptions.push(this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
      if (result) {
        CompleteChecklistItemSection.Signature =
          this.translate.instant('SIGNED') + " : " +
          BaseServices.Name +
          ' ' +
          this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
      }
    }));

  }

  uploaderEvents(
    event: any,
    CompleteChecklistItemSection: CompleteChecklistItemSection
  ) {
    switch (event.eventName) {
      case 'DeleteDefaultFile':
        CompleteChecklistItemSection.Attachments = CompleteChecklistItemSection.Attachments.filter(
          x => x.path !== event.item.data.path
        );

        break;
      default:
        let uploadedFile = event;
        // TODO: add  uploaded files to attachment objects
        break;
    }
  }

  ngOnInit() {
    this.elFeatureKey = FeatureKey.KUNDE_EL;
    this.signature =
      'Signed: ' +
      BaseServices.Name +
      ' ' +
      this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:MM');
    if (this.staticTabs != undefined) {
      this.staticTabs.tabs[0].disabled = true;
      this.staticTabs.tabs[1].disabled = true;
      this.staticTabs.tabs[2].disabled = true;
    }
    this.CompleteChecklistEdit = new CompleteChecklistEdit();
    this.CompleteCheckList = new CompleteCheckList();
    this.CompleteCheckList.ResponsibleId = null;
    this.CompleteCheckList.ChecklistId = this.route.snapshot.params['clId'];
    this.type = this.route.snapshot.params['Type'];
    this.featureId = this.route.snapshot.params['FeatureId'];
    this.feaureKey = this.route.snapshot.params['FeatureKey'];

    if (this.featureId == 16) {
      this.projectTitle = sessionStorage.getItem('ProjectName');
    }
    else if (this.featureId == 18) {
      this.projectTitle = sessionStorage.getItem('FdvName');
    }
    else if (this.featureId == 19) {
      this.projectTitle = sessionStorage.getItem('InternalCntrlName');
    }
    else if (this.featureId == 20 || this.featureId == 43) {
      this.projectTitle = sessionStorage.getItem('ZoneName');
    }
    else if (this.featureId == 21) {
      this.projectTitle = sessionStorage.getItem('VehicleName');
    }
    else if (this.featureId == 22) {
      this.projectTitle = sessionStorage.getItem('FrameWorkAgreementName');
    }
    else if (this.featureId == 23) {
      this.projectTitle = sessionStorage.getItem('ServiceAgreement');
    }
    else if (this.featureId == 24) {
      this.projectTitle = sessionStorage.getItem('KundeELName');
    }


    switch (this.route.snapshot.url[1].path) {
      case 'new':

        if (this.type === 'feature' || this.type === 'task-manager') {
          this.CompleteCheckList.ResponsibleId = BaseServices.UserId;
          this.GetFeatureMaualChecklistdetails(
            this.route.snapshot.params['clId']
          );
          this.bindFeatureTree();
        } else {
          this.CompleteCheckList.ResponsibleId = BaseServices.UserId;
          this.GetAppmaualChecklistdetails(
            this.route.snapshot.params['clId']
          );
        }
        if (this.staticTabs != undefined) {
          this.staticTabs.tabs[0].disabled = false;
          this.staticTabs.tabs[1].disabled = true;
          this.staticTabs.tabs[2].disabled = true;
        }
        break;
      case 'incomplete':
        this.showLoader = true;
        if (this.type === 'feature' || this.type === 'task-manager') {
          this.CompleteChecklistEdit = new CompleteChecklistEdit();
          this.subscriptions.push(this.CompleteCheckListServices.getFeatureCompleteCheckListData(
            this.route.snapshot.params['clId']
          ).subscribe(result => {
            if (result) {
              this.showLoader = false;
              this.CompleteCheckList = new CompleteCheckList();
              this.CompleteCheckList = result;
              this.featureId = this.CompleteCheckList.FeatureId;
              this.feaureKey = this.CompleteCheckList.FeatureKey;
              this.jsonString = this.CompleteCheckList.CheckListData;
              this.CompleteChecklistEdit = new CompleteChecklistEdit();
              this.CompleteChecklistEdit = JSON.parse(
                this.jsonString
              );
              this.GetFeatureMaualChecklistdetails(
                this.CompleteCheckList.ChecklistId
              );
              this.bindFeatureTree();
            }
          }));
        } else {
          this.CompleteChecklistEdit = new CompleteChecklistEdit();
          this.subscriptions.push(this.CompleteCheckListServices.getManualCompleteCheckListData(
            this.route.snapshot.params['clId']
          ).subscribe(result => {
            if (result) {
              this.showLoader = false;
              this.CompleteCheckList = new CompleteCheckList();
              this.CompleteCheckList = result;
              this.jsonString = this.CompleteCheckList.CheckListData;
              this.CompleteChecklistEdit = new CompleteChecklistEdit();
              this.CompleteChecklistEdit = JSON.parse(
                this.jsonString
              );
              this.GetAppmaualChecklistdetails(
                this.CompleteCheckList.ChecklistId
              );
            }
          }));
        }
        if (this.staticTabs != undefined) {
          this.staticTabs.tabs[0].disabled = false;
          this.staticTabs.tabs[1].disabled = false;
          this.staticTabs.tabs[2].disabled = true;
          this.staticTabs.tabs[1].active = true;
        }
        break;
    }

    this.BindScore();
    this.BindTreeNode();
    this.BindChecklistReference();
    this.upConfig = {
      title: this.translate.instant('DOCUMENTS'),
      titleAsLabel: true,
      editMode: true,
      windowedHeight: false,
      viewSwitch: false,
      showUserMeta: false,
      showSearchFilter: false,
      showUploadButton: true,
      uploaderUri:
        environment.BASE_URL +
        '/file/upload/business/completechecklist',
      addtionalData: this.additionalData,
      showDeleteButton: true
    };

  }

  SaveFirstStepCompleteCheckList() {
    this.showLoader = true;
    if (this.route.snapshot.url[1].path === 'new') {

      this.CompleteCheckList.BusinessId = BaseServices.BusinessId;
      this.CompleteCheckList.FeatureId = this.featureId;
      this.CompleteCheckList.FeatureKey = this.feaureKey;
      this.CompleteCheckList.Status = 1;
      switch (this.type) {
        case 'feature':
        case 'task-manager':
          if (!this.CompleteCheckList.CheckListData) {
            this.CompleteChecklistEdit = new CompleteChecklistEdit();
            this.subscriptions.push(this.CompleteCheckListServices.getFeatureCheckListData(
              this.CompleteCheckList.ChecklistId
            ).subscribe((result: any) => {
              if (result) {
                this.showLoader = false;
                this.CompleteCheckList.CheckListData =
                  result.CheckListData;
                this.jsonString = this.CompleteCheckList.CheckListData;
                this.CompleteChecklistEdit = new CompleteChecklistEdit();
                this.CompleteChecklistEdit = JSON.parse(
                  this.jsonString
                );
              }
              if (this.featureId == 20 || this.featureId == 43) {
                this.CompleteCheckList.CreatedBy = BaseServices.UserId;
                // this.CompleteCheckList.ActivityId = this.route.snapshot.params.Department;
                this.CompleteCheckList.ActivityId = this.route.snapshot.params['Department'];
                this.subscriptions.push(this.CompleteCheckListServices.SaveFirstStepCompleteCheckListForFS(this.CompleteCheckList)
                  .subscribe(result => {
                    this.CompleteCheckList = result;
                  }));
              }
              this.toasterComponent.callToast();
            }));
          } else {
            this.showLoader = false;
          }
          if (this.staticTabs != undefined) {
            this.staticTabs.tabs[1].disabled = false;
            this.staticTabs.tabs[1].active = true;
          }
          break;
        case 'activity':
          if (!this.CompleteCheckList.CheckListData) {
            this.checklistTitle = this.CompleteCheckList.Title;
            this.CompleteChecklistEdit = new CompleteChecklistEdit();
            this.subscriptions.push(this.CompleteCheckListServices.getManualCheckListData(
              this.CompleteCheckList.ChecklistId
            ).subscribe((result: any) => {
              if (result) {
                this.showLoader = false;
                this.CompleteCheckList.CheckListData =
                  result.CheckListData;
                this.jsonString = this.CompleteCheckList.CheckListData;
                this.CompleteChecklistEdit = new CompleteChecklistEdit();
                this.CompleteChecklistEdit = JSON.parse(
                  this.jsonString
                );
              }
              this.toasterComponent.callToast();
            }));
          } else {
            this.showLoader = false;
          }
          if (this.staticTabs != undefined) {
            this.staticTabs.tabs[1].disabled = false;
            this.staticTabs.tabs[1].active = true;
          }
          break;
      }
    } else {
      if (this.staticTabs != undefined) {
        this.staticTabs.tabs[1].disabled = false;
        this.staticTabs.tabs[1].active = true;
      }
      this.showLoader = false;
    }
  }

  SaveSecondStepCompleteCheckList() {
    this.CompleteCheckList.ApplicationId = BaseServices.ApplicationId;
    this.CompleteCheckList.CultureInfo = sessionStorage.getItem('languageMode');
    this.showLoader = true;
    if (!this.CompleteCheckList.ActivityId) {
      if (this.type === 'task-manager' || this.type === 'activity') {
        this.CompleteCheckList.ActivityId = this.route.snapshot.params['Department'];
      } else if (this.type === 'feature') {
        this.CompleteCheckList.ActivityId = 0;
      } else {
        this.CompleteCheckList.ActivityId = this.CompleteCheckList.FeatureKey;
        this.CompleteCheckList.DepartmentId = this.route.snapshot.params['Department'];
      }
    }
    if (
      this.CompleteCheckList.Id === 0 ||
      this.CompleteCheckList.Id === null
    ) {
      this.CompleteCheckList.CreatedBy = BaseServices.UserId;
    } else {
      this.CompleteCheckList.ModifiedBy = BaseServices.UserId;
    }
    this.CompleteCheckList.CheckListData = JSON.stringify(
      this.CompleteChecklistEdit
    );
    switch (this.type) {
      case 'feature':
      case 'task-manager':
        this.subscriptions.push(this.CompleteCheckListServices.addFeatureCompleteCheckList(
          this.CompleteCheckList
        ).subscribe(result => {
          if (result) {
            this.showLoader = false;
            this.CompleteCheckList = result;
            this.jsonString = this.CompleteCheckList.CheckListData;
            this.CompleteChecklistEdit = new CompleteChecklistEdit();
            this.CompleteChecklistEdit = JSON.parse(
              this.jsonString
              );
              this.toasterComponent.callToast();
              const deviationIds = this.CompleteCheckList.DeviationIds;
              let cultureInfo = sessionStorage.getItem('languageMode');
              const dIds: DeviationIds = {
                DeviationIds: deviationIds,
                FeatureId : this.featureId,
                FeatureKey : this.parentKey,
                CultureInfo : cultureInfo
              };
              this.subscriptions.push(this.CompleteCheckListServices.SendMailforDeviation(
                dIds
              ).subscribe(res => {
                    res == true
              }));
            if (this.staticTabs != undefined) {
              this.staticTabs.tabs[2].disabled = false;
              this.staticTabs.tabs[2].active = true;
            }
          }
        }));
        break;
      case 'activity':
        let applicationBase = '';
        let appSettings = JSON.parse(sessionStorage.getItem('appSettings')!);
        if (appSettings) {
            applicationBase = appSettings.BaseUrl;
        }
       
        if (window.name === 'Remote') {
          this.subscriptions.push(this.CompleteCheckListServices.getRModeFeature().subscribe((feature: any) => {
              if (feature) {
                  this.businessFeature = <Feature[]>JSON.parse(feature.Features);
                  feature = this.businessFeature.find(record => record.id === FeatureKey.FOLLOW_UP);
            if (feature.children) {
                let Features = feature.children.find((data:any) => data.id === FeatureKey.SEND_ONLY_TO_DEPARTMENT);
                this.SendonlytoDepartment = Features ? Features.checked : false;
                this.CompleteCheckList.SendonlytoDepartment=this.SendonlytoDepartment;
                this.subscriptions.push(this.CompleteCheckListServices.addAppCompleteCheckList(
                  this.CompleteCheckList
                ).subscribe(result => {
                  if (result) {
                    this.showLoader = false;
                    this.CompleteCheckList = result;
                    this.jsonString = this.CompleteCheckList.CheckListData;
                    this.CompleteChecklistEdit = new CompleteChecklistEdit();
                    this.CompleteChecklistEdit = JSON.parse(
                      this.jsonString
                    );
                    this.toasterComponent.callToast();
                    const deviationIds = this.CompleteCheckList.DeviationIds;
              let cultureInfo = sessionStorage.getItem('languageMode');
              const dIds: DeviationIds = {
                DeviationIds: deviationIds,
                FeatureId : this.featureId,
                FeatureKey : this.parentKey,
                CultureInfo : cultureInfo
              };
              this.subscriptions.push(this.CompleteCheckListServices.SendMailforDeviation(
                dIds
              ).subscribe(res => {
                    res == true
              }));
                    if (this.staticTabs != undefined) {
                      this.staticTabs.tabs[2].disabled = false;
                      this.staticTabs.tabs[2].active = true;
                    }
                  }
                }));
               
            }      
          }
         }
       ));
      }else{
        let usersettings = JSON.parse(sessionStorage.getItem('userSettings')!);
        if (usersettings) {
            this.businessFeature = <Feature[]>JSON.parse(usersettings.userFeature.Features);
            usersettings = this.businessFeature.find(record => record.id === FeatureKey.FOLLOW_UP);
            if (usersettings.children) {
                let feature = usersettings.children.find((data:any) => data.id === FeatureKey.SEND_ONLY_TO_DEPARTMENT);
                this.SendonlytoDepartment = feature ? feature.checked : false;
                this.CompleteCheckList.SendonlytoDepartment=this.SendonlytoDepartment;
                this.subscriptions.push(this.CompleteCheckListServices.addAppCompleteCheckList(
                  this.CompleteCheckList
                ).subscribe(result => {
                  if (result) {
                    this.showLoader = false;
                    this.CompleteCheckList = result;
                    this.jsonString = this.CompleteCheckList.CheckListData;
                    this.CompleteChecklistEdit = new CompleteChecklistEdit();
                    this.CompleteChecklistEdit = JSON.parse(
                      this.jsonString
                    );
                    this.toasterComponent.callToast();
                    const deviationIds = this.CompleteCheckList.DeviationIds;
              let cultureInfo = sessionStorage.getItem('languageMode');
              const dIds: DeviationIds = {
                DeviationIds: deviationIds,
                FeatureId : this.featureId,
                FeatureKey : this.parentKey,
                CultureInfo : cultureInfo
              };
              this.subscriptions.push(this.CompleteCheckListServices.SendMailforDeviation(
                dIds
              ).subscribe(res => {
                    res == true
              }));
                    if (this.staticTabs != undefined) {
                      this.staticTabs.tabs[2].disabled = false;
                      this.staticTabs.tabs[2].active = true;
                    }
                  }
                }));
              
            }
        }
      }

      break;
   
    }
  }

  GetSaveForLaterMessageKey(featureId: number) {
    let key = '';
    switch (featureId) {
      case FeatureKey.PROJECT:
        key = 'CHECKLIST_PROJECT';
        break;
      case FeatureKey.FDV:
        key = 'CHECKLIST_FDV';
        break;
      case FeatureKey.INTERNAL_CONTROL:
        key = 'CHECKLIST_INTERNAL_CONTROL';
        break;
      case FeatureKey.OTHER_SYSTEMS:
        key = 'CHECKLIST_OTHER_SYSTEMS';
        break;
      case FeatureKey.QUALITY_SYSTEMS:
        key = 'CHECKLIST_QUALITY_SYSTEMS';
        break;
      case FeatureKey.VEHICLES:
        key = 'CHECKLIST_VEHICLE';
        break;
      case FeatureKey.FOOD_SAFETY:
        key = 'CHECKLIST_FOOD_SAFETY';
        break;
      case FeatureKey.KUBA_CONTROL:
        key = 'CHECKLIST_KUBA_CONTROL';
        break;
      case FeatureKey.FRAMEWORK_AGREEMENT:
        key = 'CHECKLIST_FRAMEWORK';
        break;
      case FeatureKey.FRAMEWORK_AGREEMENT:
        key = 'CHECKLIST_FRAMEWORK';
        break;
      case FeatureKey.SERVICE_AGREEMENT:
        key = 'CHECKLIST_SERVICE_AGREEMENT';
        break;
      case FeatureKey.KUNDE_EL:
        key = 'CHECKLIST_EL';
        break;
    }
    return key;
  }

  Saveforlater(print?: boolean) {
    this.CompleteCheckList.ApplicationId = BaseServices.ApplicationId;
    if (!this.CompleteCheckList.ActivityId) {
      if (this.type === 'task-manager' || this.type === 'activity') {
        this.CompleteCheckList.ActivityId = this.route.snapshot.params['Department'];
      } else if (this.type === 'feature') {
        this.CompleteCheckList.ActivityId = 0;
      } else {
        this.CompleteCheckList.ActivityId = this.CompleteCheckList.FeatureKey;
        this.CompleteCheckList.DepartmentId = this.route.snapshot.params['Department'];
      }
    }

    if (
      this.CompleteCheckList.Id === 0 ||
      this.CompleteCheckList.Id === null
    ) {
      this.CompleteCheckList.CreatedBy = BaseServices.UserId;
    } else {
      this.CompleteCheckList.ModifiedBy = BaseServices.UserId;
    }
    this.CompleteCheckList.CheckListData = JSON.stringify(
      this.CompleteChecklistEdit
    );
    switch (this.type) {
      case 'feature':
      case 'task-manager':
        if (!print) {//save for later button click
          this.confirmationService.confirm({
            message: this.translate.instant(this.GetSaveForLaterMessageKey(+this.CompleteCheckList.FeatureId)),
            accept: () => {
              this.subscriptions.push(this.CompleteCheckListServices.addFeatureCompleteCheckList(
                this.CompleteCheckList
              ).subscribe(result => {
                if (result) {
                  this.CompleteCheckList = result;
                  if (this.type === 'task-manager') {
                    this.location.back();
                  } else {
                    if (this.route.snapshot.url[1].path === 'incomplete') {
                      switch (this.CompleteCheckList.FeatureId) {
                        case FeatureKey.PROJECT: // Project
                          this.router.navigate(['../../../../../../project/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.FDV: // FDV
                          this.router.navigate(['../../../../../../fire-deviation/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.INTERNAL_CONTROL: // Internal-control
                          this.router.navigate(['../../../../../../internal-control/details', this.CompleteCheckList.FeatureKey, 'document',
                            'list', { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.VEHICLES: // vehicle
                          this.router.navigate(['../../../../../../vehicle/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.FOOD_SAFETY: // food safety  
                          this.router.navigate(['../../../../../../foodsafety/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.KUBA_CONTROL: // Kuba Control
                          this.router.navigate(['../../../../../../kubacontrol/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.FRAMEWORK_AGREEMENT: // Framework - agreement
                          this.router.navigate(['../../../../../../framework-agreement/details',
                            this.CompleteCheckList.FeatureKey, 'document', 'list', { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.SERVICE_AGREEMENT: // Service-agreement
                          this.router.navigate(['../../../../../../service-agreement/details',
                            this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.KUNDE_EL: // IK-Kunde-EL
                          sessionStorage.setItem('isUnderCompletion', 'true');
                          this.router.navigate(['../../../../../../kunde_EL/details',
                            this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break
                      }
                    }
                    else if (this.route.snapshot.url[1].path === 'new') {
                      switch (this.CompleteCheckList.FeatureId) {
                        case FeatureKey.PROJECT: // Project
                          this.router.navigate(['../../../../../../../project/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.FDV: // FDV
                          this.router.navigate(['../../../../../../../fire-deviation/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.INTERNAL_CONTROL: // Internal-control
                          this.router.navigate(['../../../../../../../internal-control/details', this.CompleteCheckList.FeatureKey, 'document',
                            'list', { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.VEHICLES: // vehicle
                          this.router.navigate(['../../../../../../../vehicle/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.FOOD_SAFETY: // food safety  
                          this.router.navigate(['../../../../../../../foodsafety/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.KUBA_CONTROL: // Kuba Control
                          this.router.navigate(['../../../../../../../kubacontrol/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.FRAMEWORK_AGREEMENT: // Framework - agreement
                          this.router.navigate(['../../../../../../../framework-agreement/details',
                            this.CompleteCheckList.FeatureKey, 'document', 'list', { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.SERVICE_AGREEMENT: // Service-agreement
                          this.router.navigate(['../../../../../../../service-agreement/details',
                            this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break;
                        case FeatureKey.KUNDE_EL: // IK-Kunde-EL
                          sessionStorage.setItem('isUnderCompletion', 'true');
                          this.router.navigate(['../../../../../../../kunde_EL/details',
                            this.CompleteCheckList.FeatureKey, 'document', 'list',
                            { Id: this.CompleteCheckList.FeatureKey }], {
                            relativeTo: this.route
                          });
                          break
                      }
                    }
                  }
                }
              }));
            },
            reject: () => {
              // User clicked "Cancel" or dismissed the confirmation dialog
              this.location.back();
            }
          })
        } else {//print button click
          this.printAsPdf();
          this.subscriptions.push(this.CompleteCheckListServices.addFeatureCompleteCheckList(
            this.CompleteCheckList
          ).subscribe(result => {
            if (result) {
              this.toasterComponent.callToast();
              this.CompleteCheckList = result;
            }
          }));
        }
        break;
      case 'activity':
        if (!print) {//save for later button click
          this.confirmationService.confirm({
            message: this.translate.instant('CHECKLIST_SAVED_LATER'),
            accept: () => {
              this.subscriptions.push(this.CompleteCheckListServices.addAppCompleteCheckList(
                this.CompleteCheckList
              ).subscribe(result => {
                if (result) {
                  this.router.navigate(['../../../../../../archive/list'], {
                    relativeTo: this.route
                  });
                  this.router.navigate(['../../archive/list'], {
                    relativeTo: this.route
                  });
                  this.location.back();
                }
              }));
            }
          });
        } else {//print button click
          this.printAsPdf();
          this.subscriptions.push(this.CompleteCheckListServices.addAppCompleteCheckList(
            this.CompleteCheckList
          ).subscribe(result => {
            if (result) {
              this.toasterComponent.callToast();
            }
          }));
        }


        break;
    }
  }

  BindScore() {
    this.scoreList = [];
    for (let i = 1; i <= 6; i++) {
      this.scoreList.push({ label: i.toString(), value: i });
    }
  }

  bindFeatureTree() {
    if (this.type !== 'activity') {
      this.subscriptions.push(this.CompleteCheckListServices
        .getFeatureFolders(this.featureId, this.feaureKey)
        .subscribe(
          (result: any) => {
            this.featureTreeNode = result; // [0].nodes;
            let flattenned = this.flattenArrObj(this.featureTreeNode);
          },
          (error: any) => {
            console.error('Api Error while refreshing tree:', error);
          },
          () => {
            console.log('Refreshing tree service complete.');
          }
        ));
    }
  }

  BindTreeNode() {
    this.type = this.route.snapshot.params['Type'];
    if (this.type === 'activity') {
      this.subscriptions.push(this.archiveServices
        .getArchiveTreeFolder(BaseServices.BusinessId)
        .subscribe(
          (result: any) => {
            this.treeNode = result[0].nodes;
            let flattenned = this.flattenArrObj(this.treeNode);
            if (this.treeActiveNode != undefined) {
              const parent = _.filter(flattenned, [
                'id',
                this.treeActiveNode.data.id
              ]);
              this.uploadedFiles = parent[0].nodes;
            }
          },
          (error: any) => {
            console.error('Api Error while refreshing tree:', error);
          },
          () => {
            console.log('Refreshing tree service complete.');
          }
        ));
    }
  }

  private flattenArrObj(arr) {
    let newArr = [];
    if (Array.isArray(arr) && arr.length > 0) {
      arr.forEach(element => {
        newArr.push(element);
        if (Array.isArray(element.nodes) && element.nodes.length > 0) {
          newArr = newArr.concat(this.flattenArrObj(element.nodes));
        }
      });
    }
    return newArr;
  }

  GetAppmaualChecklistdetails(id: number) {
    this.showLoader = true;
    this.subscriptions.push(this.CompleteCheckListServices.getManualChecklistDetails(id).subscribe(
      (result: any) => {
        if (result) {
          if (this.route.snapshot.url[1].path === 'new') {
            this.CompleteCheckList.Title = result.Title;
          }
          this.checklistTitle = result.Title;
          this.isScoreIndex = result.IsScoreIndex;
          this.showLoader = false;
        }
      }
    ));
  }

  GetFeatureMaualChecklistdetails(id: number) {
    this.showLoader = true;
    this.subscriptions.push(this.CompleteCheckListServices.getFeatureChecklistDetails(id).subscribe(
      (result: any) => {
        if (result) {
          if (this.route.snapshot.url[1].path == 'new') {
            this.CompleteCheckList.Title = result.Title;
          }
          this.checklistTitle = result.Title;
          this.isScoreIndex = result.IsScoreIndex;
        }
        this.showLoader = false;
      }
    ));
  }

  EnableFirstTab() {
    if (this.staticTabs != undefined) {
      this.staticTabs.tabs[0].active = true;
    }
  }

  catchEvent($event) {
    if ($event.eventName === 'focus') {
      this.selectFolderName = $event.node.data.filename;
      this.selectFolderId = $event.node.data.nodeId;
    }
  }

  onFeatureCatchEvent($event) {
    if ($event.eventName === 'focus') {
      this.selectFeatureFolderName = $event.node.data.filename;
      this.selectFeatureFolderId = $event.node.data.nodeId;
    }
  }

  checkValid() {
    let val = false;
    if (this.CompleteChecklistEdit != undefined) {
      if (this.CompleteChecklistEdit.Section) {
        let sections = this.CompleteChecklistEdit.Section;
        for (let section of sections) {
          let items = section.Items;
          for (let item of items) {
            let status = item.Status;
            if (
              status[0].value === false &&
              status[1].value === false &&
              status[2].value === false
            ) {
              val = true;
            }
          }
        }
      }
    }

    return val;
  }

  BindChecklistReference() {
    this.checkListReference = [];
    this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
      this.checkListReference = [];
      this.checkListReference.push(
        { label: val.SELECT, value: null }
      );
    }));
    this.subscriptions.push(this.CompleteCheckListServices.getCheckListReferenceData()
      .subscribe((result: any) => {
        result.forEach((checklist: any) => {
          this.checkListReference.push({ label: checklist.Value, value: checklist.Value });
        });
      }))
  }


  // Check for approval checkbox
  isCheckBoxTicked() {
    let val = true;
    if (this.aproval) {
      if (this.type === 'activity') {
        val = this.selectFolderId ? false : true;
      } else {
        val = this.selectFeatureFolderId ? false : true;
        // val = false;
      }
    }
    return val;
  }

  // Complete Checklist
  SaveAndCompleteChecklist() {
    this.showLoader = true;
    this.CompleteCheckList.CompletedBy = BaseServices.UserId;
    if (this.type === 'activity') {
      this.CompleteCheckList.MovedFolderId = this.selectFolderId;
    } else {
      this.CompleteCheckList.MovedFolderId = this.selectFeatureFolderId;
    }
    if (!this.CompleteCheckList.ActivityId) {
      if (this.type === 'task-manager' || this.type === 'activity') {
        this.CompleteCheckList.ActivityId = this.route.snapshot.params['Department'];
      } else if (this.type === 'feature') {
        this.CompleteCheckList.ActivityId = 0;
      } else {
        this.CompleteCheckList.ActivityId = this.CompleteCheckList.FeatureKey;
        this.CompleteCheckList.DepartmentId = this.route.snapshot.params['Department'];
      }
    }
    let languageId = parseInt(sessionStorage.getItem('languageId'));
    let CultureInfo = 'en-GB';
    switch (languageId) {
      case 1:
        CultureInfo = 'en-GB';
        break;
      case 2:
        CultureInfo = 'no-NO';
        break;
      case 3:
        CultureInfo = 'sv-SE';
        break;
      case 4:
        CultureInfo = 'pl-PL';
        break;
    }
    this.subscriptions.push(this.CompleteCheckListServices
      .SaveAndCompleteCheckList(this.CompleteCheckList, this.isScoreIndex, CultureInfo)
      .subscribe(
        result => {
          if (result === true) {
            this.showLoader = false;
            this.toasterComponent.callToast();
            if (this.type === 'activity') {
              // this.router.navigate(['../../../../../../archive/list'], {
              //   relativeTo: this.route
              // });
              this.location.back();
            } else if (this.type === 'task-manager') {
              this.location.back();
            } else {
              if (this.route.snapshot.url[1].path === 'incomplete') {
                switch (this.CompleteCheckList.FeatureId) {
                  case FeatureKey.PROJECT: // Project
                    this.router.navigate(['../../../../../../project/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.FDV: // FDV
                    this.router.navigate(['../../../../../../fire-deviation/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.INTERNAL_CONTROL: // Internal-control
                    this.router.navigate(['../../../../../../internal-control/details', this.CompleteCheckList.FeatureKey, 'document',
                      'list', { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.VEHICLES: // vehicle
                    this.router.navigate(['../../../../../../vehicle/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.FOOD_SAFETY: // food safety  
                    this.router.navigate(['../../../../../../foodsafety/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.KUBA_CONTROL: // Kuba Control  
                    this.router.navigate(['../../../../../../kubacontrol/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.FRAMEWORK_AGREEMENT: // Framework - agreement
                    this.router.navigate(['../../../../../../framework-agreement/details',
                      this.CompleteCheckList.FeatureKey, 'document', 'list', { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.SERVICE_AGREEMENT: // Service-agreement
                    this.router.navigate(['../../../../../../service-agreement/details',
                      this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.KUNDE_EL: // IK-Kunde-EL
                    sessionStorage.setItem('isUnderCompletion', 'true');
                    this.router.navigate(['../../../../../../kunde_EL/details',
                      this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break
                }
              }
              else if (this.route.snapshot.url[1].path === 'new') {
                switch (this.CompleteCheckList.FeatureId) {
                  case FeatureKey.PROJECT: // Project
                    this.router.navigate(['../../../../../../../project/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.FDV: // FDV
                    this.router.navigate(['../../../../../../../fire-deviation/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.INTERNAL_CONTROL: // Internal-control
                    this.router.navigate(['../../../../../../../internal-control/details', this.CompleteCheckList.FeatureKey, 'document',
                      'list', { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.VEHICLES: // vehicle
                    this.router.navigate(['../../../../../../../vehicle/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.FOOD_SAFETY: // food safety  
                    this.router.navigate(['../../../../../../../foodsafety/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.KUBA_CONTROL: // Kuba Control  
                    this.router.navigate(['../../../../../../../kubacontrol/details', this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.FRAMEWORK_AGREEMENT: // Framework - agreement
                    this.router.navigate(['../../../../../../../framework-agreement/details',
                      this.CompleteCheckList.FeatureKey, 'document', 'list', { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.SERVICE_AGREEMENT: // Service-agreement
                    this.router.navigate(['../../../../../../../service-agreement/details',
                      this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break;
                  case FeatureKey.KUNDE_EL: // IK-Kunde-EL
                    sessionStorage.setItem('isUnderCompletion', 'true');
                    this.router.navigate(['../../../../../../../kunde_EL/details',
                      this.CompleteCheckList.FeatureKey, 'document', 'list',
                      { Id: this.CompleteCheckList.FeatureKey }], {
                      relativeTo: this.route
                    });
                    break
                }
              }
            }
          } else {
            alert('checklist not completed');
          }
        },
        error => {
          console.log('checklist not completed');
        }));
  }

  //print checklist undercompletion
  printAsPdf() {
    let filename = this.CompleteCheckList.Title + '(' +
      this.datePipe.transform(this.CompleteCheckList.CreatedOn, "dd-MM-yyyy-hh-mm-ss") + ') (' +
      this.translate.instant('UNDER_COMPLETION') + ')';
    let type = 'pdf';
    let appSettings = JSON.parse(sessionStorage.getItem('appSettings'));
    let CultureInfo = sessionStorage.getItem('languageMode');
    this.CompleteCheckList.IsUnderCompletion = true;
    this.subscriptions.push(this.CompleteCheckListServices
      .generateUnderCompletionChecklistReport(this.CompleteCheckList, this.isScoreIndex, CultureInfo)
      .subscribe(
        blob => {
          this.CompleteCheckList.IsUnderCompletion = false;
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}.${type.toLowerCase()}`;
          link.click();
        },
        error => {
          this.CompleteCheckList.IsUnderCompletion = false;
          alert('Export not downloaded');
        }));
  }

  Back() {
    let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
    if (workingModule.id == 20) {
      this.pathName = sessionStorage.getItem('childrenModule');
      this.confirmationService.confirm({
        message: this.translate.instant('CHECKLIST_FOOD_SAFETY'),
        accept: () => {
          this.SaveFirstStepCompleteCheckList();
          this.location.back();
        },
        reject: () => {
          this.subscriptions.push(this.CompleteCheckListServices.Deletefeaturecompletechecklist(this.CompleteCheckList.Id)
            .subscribe(res => {
              this.location.back();
            }));
        }
      });
    }
    else if (workingModule.id == 43) {
      this.pathName = sessionStorage.getItem('childrenModule');
      this.confirmationService.confirm({
        message: this.translate.instant('CHECKLIST_KUBA_CONTROL'),
        accept: () => {
          this.SaveFirstStepCompleteCheckList();
          this.location.back();
        },
        reject: () => {
          this.subscriptions.push(this.CompleteCheckListServices.Deletefeaturecompletechecklist(this.CompleteCheckList.Id)
            .subscribe(res => {
              this.location.back();
            }));
        }
      });
    }
    else {
      this.location.back();
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
        sub.unsubscribe();
    });
}
}
