import { TaskPlanList } from './../../follow-ups/models/riskanalysis';

export class SafetyJobListedit {
    Id: number;
    BusinessId: number;
    ApplicationId: number;
    FeatureId: number;
    Title: string;
    Location: string;
    TopicId: number;
    ProjectId: number;
    FeatureKey: number;
    BusinessDepartmentId: number;
    RelatedIncident: string;
    Alreadyactiontaken: string;
    Measures: string;
    Partcipants: string;
    IssueTypeId: number;
    Probability: number;
    Consequence: number;
    CreatedOn: Date;
    ModifiedBy: number;
    RiskFigure: number;
    ExpectedBehaviour: string;
    Status: string;
    RiskAnalysisAttachment: RiskAnalysisAttachment[];
    Activity: TaskPlanList[];
    DocumentPath: string;
    CreatorName: string;
    CreatedBy: number;
    ModifierName: string;
    ModifiedOn: Date;
    // Save or SaveAndAddTask
    SaveType:any;
    ModuleFeatureId : number;
    AdditionalFeatureId : number;
    ObjectName:string;
}
export class RiskAnalysisAttachment {
    Id: number;
    RiskAnalysisId: number;
    FileName: string;
    OriginalFileName: string;
    MimeType: string;
    Size: number;
    Mapping: string;
    Path: string;
    FileType: string;
    Status: string;
    CreatedBy: {
        Id: number;
        Name: string;
    };
    CreatedOn: string;
    ModifiedBy: {
        Id: number;
        Name: string;
    };
    ModifiedOn: string;
}
export class FileUpload {
    ApplicationId: number;
    BusinessId: number;
    ParentId: number;
}
