
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/kuba/shared/services/validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { IMyDpOptions } from 'mydatepicker';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { HelperService } from './../../../_services/helper.service';
import { News } from '../models/news';
import { NewsServices } from '../services/news.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { DateSetting } from './../../../_helpers/date-setting';
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs';
import { Consumption } from '../../follow-ups/models/consumptions';
@Component({
    selector: 'CreateNews',
    templateUrl: 'CreateNews.component.html'
})
export class CreateNewsComponent extends DateSetting implements OnInit {
    //#region variables

    @ViewChild(ToasterComponent, { static: false }) toasterComponent: ToasterComponent;
    newsForm: FormGroup;
    IsHidden = true;
    newsobj: any;
    newsId: number;
    newsData = new News();
    createdOn: string;
    locale: string;
    isAdmin: boolean;
    isEditor = false;
    previewMode = false;
    showPublishButton = false;
    sendSMSToAllUser: boolean = false;
    loading: boolean = false;
    published: boolean = true;
    publishedOn: any;
    isUser: boolean;
    /**
     * Date picker configuration option
     */
    public override defaultDateOptions: IMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        editableDateField: false,
        openSelectorOnInputClick: true,
        firstDayOfWeek: 'su',
        satHighlight: true,
        inline: false,
        height: '26px',
        selectionTxtFontSize: '14px',
        todayBtnTxt: this.translate.instant('TODAY'),
        dayLabels: {
            su: this.translate.instant('SUN'),
            mo: this.translate.instant('MON'),
            tu: this.translate.instant('TUE'),
            we: this.translate.instant('WED'),
            th: this.translate.instant('THU'),
            fr: this.translate.instant('FRI'),
            sa: this.translate.instant('SAT')
        },
        monthLabels: {
            1: this.translate.instant('JANUARY'),
            2: this.translate.instant('FEBRUARY'),
            3: this.translate.instant('MARCH'),
            4: this.translate.instant('APRIL'),
            5: this.translate.instant('MAY'),
            6: this.translate.instant('JUNE'),
            7: this.translate.instant('JULY'),
            8: this.translate.instant('AUGUST'),
            9: this.translate.instant('SEPTEMBER'),
            10: this.translate.instant('OCTOBER'),
            11: this.translate.instant('NOVEMBER'),
            12: this.translate.instant('DECEMBER')
        },
        disableUntil: HelperService.formatInputDate((new Date()).setDate((new Date()).getDate() -1)).date
    };
    /**
     * Date picker configuration option
     */
    public expiryDateOpitons: IMyDpOptions = this.defaultDateOptions;

    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param formBuilder {FormBuilder}
     * @param newsServices {NewsServices}
     * @param activatedRoute {ActivatedRoute}
     */
    constructor(
        private formBuilder: FormBuilder,
        public newsServices: NewsServices,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        protected location: Location,
        private translate: TranslateService
    ) {
        super();
    }

    //#endregion

    //#region page-event

    /**
     * init
     */
    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.newsForm = this.formBuilder.group({
            Title: [
                '',
                [Validators.required, ValidationService.noWhitespaceValidator]
            ],
            ExpiredOn: [''],
            ContentData: ['']
        });
        this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params) => {
            let nid = params['id'];
            this.previewMode = params['previewMode'];
            if (this.router.url.indexOf('admin') > 0) {
                if (+BaseServices.roleId === 3) {
                    this.isAdmin = false;
                } else {
                    this.isAdmin = true;
                }
            } else {
                this.isAdmin = true;
            }
            this.newsId = +nid;
            if (this.newsId !== 0) {
                this.subscriptions.push(this.newsServices.get(this.newsId).subscribe((newsInfo: any) => {
                    if (newsInfo) {
                        this.createdOn = newsInfo.CreatedOn;
                        this.IsHidden = newsInfo.PublishedOn ? false : true;
                        this.newsData = newsInfo;
                        this.newsobj = {
                            Title: newsInfo.Title,
                            ContentData: newsInfo.ContentData,
                            ExpiredOn: (newsInfo.ExpiredOn) ? HelperService.formatInputDate(newsInfo.ExpiredOn) : null
                        };
                        (<FormGroup>this.newsForm).setValue(this.newsobj, {
                            onlySelf: true
                        });
                    }
                    if (newsInfo.PublishedOn != null) {
                        this.publishedOn = newsInfo.PublishedOn;
                        this.published = false;
                    }
                }));
            }
            if (BaseServices.UserRole == 'Editor' && this.previewMode == false) {
                this.isEditor = true;
                this.isAdmin = false;
            }
            else if (BaseServices.UserRole == 'Admin') {
                this.isAdmin = true;
                this.isEditor = false;
            }
            else {
                this.isEditor = false;
                this.isAdmin = false;
                this.isUser= false;

            }
            if (BaseServices.UserRole == 'Editor' || BaseServices.UserRole == 'Admin') {
                this.showPublishButton = true;
            }
        }));
        this.getInternalNews();
    }

    //#endregion

    //#region control-event

    /**
    * back to the list
    */
    gotoList() {
        this.location.back();
    }

    getInternalNews(){
        this.newsServices.list().subscribe((res: any) => {
           let dat = res;
        });
    }
    /**
     * save news
     */
    Save() {
        let cultureInfo = sessionStorage.getItem('languageId');
        let editnewsId = this.newsId;
        this.newsData.Title = this.newsForm.value.Title;
        this.newsData.ContentData = this.newsForm.value.ContentData;
        this.newsData.BusinessId = BaseServices.BusinessId;
        this.newsData.ApplicationId = BaseServices.ApplicationId;
        this.newsData.CreatedOn = this.createdOn;
        this.newsData.ShowInBusiness = true;
        this.newsData.ExpiredOn = new Date(this.newsForm.value.ExpiredOn.jsdate).toISOString();
        if (editnewsId > 0) {
            this.subscriptions.push(this.newsServices
                .update(editnewsId, this.newsData)
                .subscribe(isNewsUpdated => {
                    if (isNewsUpdated) {
                        this.toasterComponent.callToast();
                    }
                    this.gotoList();
                })
            );
        } else {
            this.newsData.CreatedOn = HelperService.formatDate(new Date());
            this.newsData.Id = 0;
            this.newsData.Status = '1';
            this.subscriptions.push(this.newsServices.add(this.newsData,cultureInfo).subscribe(isNewsAdded => {
                if (isNewsAdded) {
                    this.toasterComponent.callToast();
                }
                this.gotoList();
            }));
        }
    }

    reset() {
        this.newsId = 0;
        this.newsForm.reset();
    }
    /**
     * save and publish
     */
    SaveAndPublish() {
        this.loading = true;
        let cultureInfo = sessionStorage.getItem('languageId');
        let editnewsId = this.newsId;
        this.newsData.Title = this.newsForm.value.Title;
        this.newsData.ContentData = this.newsForm.value.ContentData;
        this.newsData.CreatedOn = this.createdOn;
        this.newsData.ShowInBusiness = true;
        this.newsData.PublishedOn = HelperService.formatDate(new Date());
        this.newsData.ExpiredOn = this.newsForm.value.ExpiredOn
            ? HelperService.formatDateFilter(this.newsForm.value.ExpiredOn.formatted)
            : null!;
        this.newsData.ApplicationId = BaseServices.ApplicationId;
        this.newsData.BusinessId = BaseServices.BusinessId;
        if (editnewsId > 0) {
            this.subscriptions.push(this.newsServices
                .publish(editnewsId, this.newsData, cultureInfo)
                .subscribe(isNewsUpdated => {
                    if (isNewsUpdated) {
                        this.toasterComponent.callToast();
                    }
                    this.gotoList();
                }));
        } else {
            this.newsData.Id = 0;
            this.newsData.Status = '1';
            this.newsData.CreatedOn = HelperService.formatDate(new Date());
            this.subscriptions.push(this.newsServices.add(this.newsData,cultureInfo).subscribe(isNewsAdded => {
                if (isNewsAdded) {
                    this.toasterComponent.callToast();
                }
                this.gotoList();
            }));
        }
    }

    //#endregion

    //#region method

    /**
     * get current date
     */
    getCurrentDate() {
        let dateFormat = new Date();
        return {
            year: dateFormat.getFullYear(),
            month: dateFormat.getMonth() + 1,
            day: dateFormat.getDate() - 1
        };
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion

    //Sending Email to all the users
    SaveAndSendEmailToUsers() {
        this.loading = true;
        let editnewsId = this.newsId;
        this.newsData.Title = this.newsForm.value.Title;
        this.newsData.ContentData = this.newsForm.value.ContentData;
        this.newsData.BusinessId = BaseServices.BusinessId;
        this.newsData.ApplicationId = BaseServices.ApplicationId;
        this.newsData.CreatedOn = this.createdOn;
        this.newsData.ShowInBusiness = true;
        this.newsData.ExpiredOn = (this.newsForm.value.ExpiredOn) ?
            HelperService.formatDateFilter(this.newsForm.value.ExpiredOn.formatted) :
            null;
        let cultureInfo = sessionStorage.getItem('languageId');
        if (editnewsId > 0) {
            this.newsServices.EditSendEmailToAllUsers(cultureInfo, editnewsId, this.newsData).subscribe((res: any) => {
                if (res == true) {
                    this.sendSMSToAllUser = true;
                    this.toasterComponent.callToast();
                }
                this.gotoList();
            });
        }
        else {
            this.newsData.Id = 0;
            this.newsData.Status = '1';
            this.newsData.CreatedOn = HelperService.formatDate(new Date());
            this.newsServices.addSendEmailToAllUsers(this.newsData, cultureInfo).subscribe(isNewsAdded => {
                if (isNewsAdded) {
                    this.toasterComponent.callToast();
                }
                this.gotoList();
            });
        }

    }

    clear() {
        this.newsForm.reset();
    }

}
