<div class="page-title">
    <span *ngIf="isICSJA" translate>IC_SJA</span>
    <span *ngIf="!isICSJA" translate>SAFETY_JOB_ANALYSIS</span>
</div>
<form [formGroup]="newSafetyJobAnalysis">
    <div class="form-group">
        <label for="Issue" class="col-form-label">
            <span translate>TYPE_OF_ISSUE</span>
            <span class="required">*</span>
        </label>
        <div class="input-btn-group">
            <p-dropdown formControlName="TypeofIssue" filter="true" placeholder="{{'SELECT'|translate}}"
                [options]="Issue" [style]="{'width':'100%'}"></p-dropdown>
            <button type="button" *ngIf="isHideButton" class="btn btn-outline-primary" (click)="addType = !addType">
                <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
        </div>
        <control-messages [control]="newSafetyJobAnalysis.controls['TypeofIssue']"></control-messages>
    </div>
    <div class="form-group">
        <label for="topic" class="col-form-label">
            <span translate>TOPICS</span>
            <span class="required">*</span>
        </label>
        <div class="input-btn-group">
            <p-dropdown [options]="Topic" filter="true" formControlName="Topics" [style]="{'width':'100%'}"
                placeholder="{{'SELECT'|translate}}">
            </p-dropdown>
            <button *ngIf="isHideButton" class="btn btn-outline-primary" type="button" (click)="addTopic = !addTopic">
                <i class="pi pi-plus" aria-hidden="true"></i>
            </button>
        </div>
        <control-messages [control]="newSafetyJobAnalysis.controls['Topics']"></control-messages>
    </div>
    <div class="form-group" [hidden]="isLocation">
        <label for="title" class="col-form-label">
            <span translate>LOCATION</span>
        </label>
        <div>
            <input type="text" class="label-input form-control" formControlName="Location">
        </div>
    </div>
    <div class="form-group" [hidden]="isWhatCanHappen">
        <label for="title" class="col-form-label">
            <span translate>WHAT_CAN_HAPPEN</span>
        </label>
        <div>
            <textarea class="form-control" formControlName="Description"></textarea>
        </div>

    </div>
    <div class="form-group" [hidden]="isLocation">
        <label for="title" class="col-form-label">
            <span translate>DESCRIPTION</span>
        </label>
        <div>
            <textarea class="form-control" formControlName="Description"></textarea>
        </div>
    </div>
    <div class="form-group">
        <div class="tab-content">
            <ecpl-document-explorer [uploaderConfig]="upConfig" [uploadedFiles]="uploadedFiles"
                (saveChanges)="saveUploaded($event)">
            </ecpl-document-explorer>
        </div>
    </div>
    <div class="action-btns-wrapper">
        <button class="btn btn-outline-secondary" (click)="gotoBack()">
            <span class="icon ic-xs icon-back"></span>
            <span translate>BACK_LIST</span>
        </button>
        <button class="btn btn-primary" [ngClass]="{'loading' : loading}" (click)="saveChanges()"
            [disabled]="!newSafetyJobAnalysis.valid || loading">
            <span *ngIf="!loading" translate>SAVE</span>
            <span *ngIf="loading" translate>SAVE_PROCESS</span>
            <span *ngIf="loading" class="loader-icon"></span>
        </button>
        <button class="btn btn-primary" (click)="saveAndOpen()" [disabled]="!newSafetyJobAnalysis.valid">
            <span translate>SAVE_OPEN</span>
        </button>
    </div>
</form>
<p-dialog header="{{'TYPE_OF_ISSUE' | translate}}" (onHide)="onIssuekDialogHide()" [(visible)]="addType" [modal]="true"
[style]="{width: '350px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="safetyIssueForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>SAFETY_ISSUE</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" id="title" class="label-input form-control" formControlName="Name">
            </div>
            <label for="title" color class="col-form-label" *ngIf="isTopicExists" [ngStyle]="{'color': 'red'}">
                <span translate>TOPIC_EXIST</span>
            </label>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="hidingElement = !hidingElement"
                (click)="viewIssue()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [ngClass]="{'loading' : loading}"
                [disabled]="!safetyIssueForm.valid || loading" (click)="saveIssue()">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
            </button>
        </div>
        <div *ngIf="hidingElement">
            <div class="table-view">
                <!-- Table starts -->
                <p-table [value]="editTypes" [rows]="5" [paginator]="true" 
                [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
                  <!-- Header -->
                  <ng-template pTemplate="header">
                      <tr>
                          <th>{{'SAFETY_ISSUE'|translate}}</th>
                          <th></th>
                      </tr>
                  </ng-template>
                  <!-- Body Content -->
                  <ng-template pTemplate="body" let-categoryType>
                      <tr>
                          <td>
                              <span>{{categoryType.Name}}</span>
                          </td>
                          <td class="col-button">
                              <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                (click)="editIssue(categoryType)">
                                  <i class="icon ic-sm icon-pencil-tip"></i>
                              </button>
                              <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                (click)="deleteIssue(categoryType['Id'])">
                                  <i class="icon ic-sm icon-trash"></i>
                              </button>
                          </td>
                      </tr>
                  </ng-template>
                  <!-- Empty message -->
                  <ng-template pTemplate="emptymessage" let-columns>
                      <tr>
                          <td colspan="2">
                              {{'NO_RECORDS_FOUND'|translate}}
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
              <!-- Table ends -->
            </div>
        </div>
    </p-footer>
</p-dialog>
<p-dialog header="{{'NEW_TOPIC' | translate}}" [(visible)]="addTopic" (onHide)="onTopicFormHide()" [modal]="true"
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="topicForm">
        <div class="form-group">
            <label for="title" class="col-form-label">
                <span translate>TOPIC_NAME</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="text" id="title" class="label-input form-control" formControlName="Name">
            </div>
            <label for="title" color class="col-form-label" *ngIf="isCaseExists" [ngStyle]="{'color': 'red'}">
                <span translate>TOPIC_EXIST</span>
            </label>
        </div>
    </form>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <button type="button" class="btn btn-outline-primary" (click)="hidingElement = !hidingElement"
                (click)="viewTopic()">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" [disabled]="!topicForm.valid || loading"
                [ngClass]="{'loading' : loading}" (click)="saveTopic()">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span>
            </button>
        </div>
        <div *ngIf="hidingElement">
            <div class="table-view">
                <!-- Table starts -->
                <p-table #Types [value]="editTopics" [rows]="5" [paginator]="true" [pageLinks]="3"
                    [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TOPIC_NAME'|translate}}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>
                                <input pInputText type="text"
                                    (input)="Types.filter($any($event.target)?.value, 'Name', 'contains')"
                                    placeholder="" class="p-column-filter">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <!-- Body Content -->
                    <ng-template pTemplate="body" let-safety>
                        <tr>
                            <td>
                                <span>{{safety.Name}}</span>
                            </td>
                            <td class="col-button">
                                <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                                    (click)="editTopic(safety)">
                                    <i class="icon ic-sm icon-pencil-tip"></i>
                                </button>
                                <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                                    (click)="deleteTopic(safety['Id'])">
                                    <i class="icon ic-sm icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <!-- Empty message -->
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td colspan="2">
                                {{'NO_RECORDS_FOUND'|translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- Table Ends-->
            </div>
        </div>
    </p-footer>
</p-dialog>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>