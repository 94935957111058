import { AccessLogListResolver, AccessLogEditResolver } from './services/accesslog.services.resolver';
import { CreateAccessLogComponent } from './components/new-accesslog.component';
import { AccessLogComponent } from './accesslog.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { AccessLogListComponent } from './components/accesslog-list.component';
import { Route } from '@angular/router';
export const accessLogRoutes: Route[] = [
    {
        path: 'accesslog',
        component: AccessLogListComponent,
        resolve: {
            list: AccessLogListResolver
        }
    },

    {
        path: 'accesslog/new',
        component: CreateAccessLogComponent,

    }
]