import { VehicleTaskFormComponent } from './components/vechicletaskForm.component';
import { VehicleTaskListComponent } from './components/vehicletaskList.component';
import { VehicleTaskServices } from './services/vehicleTask.services';
import { VehicleTaskComponent } from './vehicleTask.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharedModule } from './../shared/appShared.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { MyDatePickerModule } from 'mydatepicker';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TableModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ContextMenuModule,
        MenuModule,
        DialogModule,
        ReactiveFormsModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        TranslateModule,
        AppSharedModule,
        RouterModule,
        ButtonModule,
        MyDatePickerModule
    ],
    exports: [VehicleTaskFormComponent, VehicleTaskListComponent],
    declarations: [
        VehicleTaskFormComponent,
        VehicleTaskListComponent
    ],
    providers: [
        VehicleTaskServices
    ]
})
export class VehicleTaskModule {}
