import { Component, Input, Output, EventEmitter, SimpleChange, DoCheck, OnChanges, AfterContentChecked } from '@angular/core'
import { ManualView } from '../models/index'
import { OnInit, AfterContentInit, AfterViewInit, AfterViewChecked, } from '@angular/core';
import { ManualVersion } from 'src/app/kuba/manuals/models/manual-version';
import { SelectItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseServices } from '../../kuba.services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'assign-manual-list',
    templateUrl: 'assign-manual-list.component.html'
})


export class AssignManualListComponent implements OnInit, OnChanges, DoCheck, AfterContentChecked, AfterViewChecked {



    selectModule: any;
    checkCommon: boolean;
    CommonManuals: ManualView[];
    assignManualVersion: SelectItem[];
    Editoronly = 'Editor only';
    manualVersionslist: SelectItem[];
    bindUserList: SelectItem[];
    statusList: SelectItem[];
    roleIdList: SelectItem[];
    portalId: number;
    businessId: number;
    userRole: number;
    manual = {
        Status: '1' // Replace with the actual value from your data
    };
    private subscriptions: Subscription[] = [];
    @Input('manualVersions')
    set setmanualversions(value: SelectItem[]) {
        if (value) {
            this.manualVersionslist = value;
        }
    }
    @Input('userList')
    set setuserList(value: SelectItem[]) {
        if (value) {
            this.bindUserList = value;
        }
    }


    @Input('isBindCommonManual')
    set setisBindCommonManual(check: boolean) {
        this.checkCommon = check;
        if (check === true) {
            this.selectedManuals = [];
        }

    }

    @Input('roleId')
    set setRoled(role: any) {
        if (role) {
            this.portalId = role.portalId;
            this.businessId = role.businessId;
        }
    }


    @Input('CommonManuals')
    set setvalue(value: ManualView[]) {

        if (this.checkCommon == false) {
            this.selectedManuals = [];
            if (value) {
                for (let listvalue of value) {
                    if (listvalue.Status == 1) {
                        this.selectedManuals.push(listvalue);
                    }
                }
                this.CommonManuals = [];
                for (let listvalue of value) {
                    listvalue.isUpdate = false;
                    this.CommonManuals.push(listvalue);
                }
            }
        } else {
            this.selectedManuals = [];
            this.CommonManuals = value;
        }

        value = [];
    }


    @Input() isAllCommonManual: boolean;
    @Input() ActiveList: ManualView[];
    @Output() onAssignManualEdit = new EventEmitter<ManualView[]>();
    @Output() onPortalCommonManualEdit = new EventEmitter<ManualView[]>();
    @Output() onDeleteAssignedManual = new EventEmitter<ManualView[]>();
    selectedManuals: ManualView[];



    constructor(private route: ActivatedRoute, public router: Router,
        private translate: TranslateService) {
        this.selectModule = this.translate.instant('SELECT_MODULE');
    }

    ngOnInit() {
        $('body').removeClass('opened');
        this.assignManualVersion = [];
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.assignManualVersion.push(
                { label: val.SELECT, value: 0 }
            );
        }));
        this.assignManualVersion.push(
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 },
            { label: '10', value: 10 },
        );
        this.statusList = [];
        this.subscriptions.push(this.translate.stream('SELECT_STATUS_DROP').subscribe(val => {
            this.statusList = [];
            this.statusList.push(
                { label: val.SELECT, value: 0 },
                { label: val.ACTIVE, value: 1 },
                { label: val.INACTIVE, value: 2 }
            );
        }));
        if (BaseServices.roleId === '2') {
            this.userRole = 2;
        } else if (BaseServices.roleId === '1') {
            this.userRole = 1;
        }
    }
    // Mapping function
    mapStatus(status: string): string {
        return status == '1' ? 'ACTIVE' : status == '2' ? 'INACTIVE' : status;
    }
    getStatusTranslation(status: string): string {
        const translatedStatus = this.mapStatus(status);
        const translationKey = `${translatedStatus}`;
        return this.translate.instant(translationKey);
    }


    onRowSelectOrUnselect() {
        this.onAssignManualEdit.emit(this.selectedManuals);
    }

    onRowUnselect(list: ManualView) {

        if (this.checkCommon === false) {

            list.isUpdate = true;
            list.Status = 2; // Make manual Inactive 
        } else {
        }
    }

    onRowSelect(list: ManualView) {
        if (this.checkCommon === false) {
            list.isUpdate = true;
            list.Status = 1; // Make manual Active
        } else if (this.checkCommon === true) {
            if (list.Status === 2) {
                this.selectedManuals = this.selectedManuals.filter(x => x.AppManualId !== list.AppManualId);
                return alert(this.translate.instant('INACTIVE_MANUAL_MSG'));
            }
        }
    }

    onIsEditorChange(list: ManualView) {
        if (this.checkCommon === false) {
            list.isUpdate = true;
        }
    }

    onIsShowChecklistChange(list: ManualView) {
        if (this.checkCommon === true) {
            list.isUpdate = true;
        }
    }

    onValueChange(list: ManualView) {
        if (this.checkCommon === false) {
            list.isUpdate = true;
        }
    }

    ngAfterContentChecked() {
    }

    ngAfterViewChecked() {
    }
    onRowSelectclick() {

    }

    value1Changed() {
    }

    assignManual() {
        if (this.selectedManuals.length > 0) {
            this.onAssignManualEdit.emit(this.selectedManuals);
        } else {
            alert(this.selectModule);
        }
    }

    updateManual() {
        if (this.CommonManuals.length === this.selectedManuals.length) {
            for (let manuals of this.CommonManuals) {
                manuals.isUpdate = true;
            }
            this.onAssignManualEdit.emit(this.CommonManuals);
        } else {
            this.onAssignManualEdit.emit(this.CommonManuals);
        }
    }

    updatePortalCommonManual() {
        this.onPortalCommonManualEdit.emit(this.CommonManuals);
    }

    onDeleteAssignManual(manual: any) {
        this.onDeleteAssignedManual.emit(manual);
    }

    ngOnChanges() {

    }
    ngDoCheck() {

    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
