import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { Business } from './../../accesslog/models/accesslog';
import { BusinessSignedService } from './businessSigned.service';


@Injectable()
export class BusinessSignedUsersListResolver implements Resolve<Business[]> {
    constructor(private backend: BusinessSignedService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Business[]> {
        return this.backend.getAllUsers(+route.parent?.params['bid']);
    }
}

