import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToasterComponent } from './../../../_directives/toaster.component';
import { VehicleControlService } from '../services/vehicle-control.services';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { ControlDto } from './../models/vehicle-control';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'vehicle-control-form.component.html'
})
export class VehicleControlFormComponent implements OnInit {
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    vehicleControl: ControlDto;
    controlForm: FormGroup;
    controlDetails: any;
    vehicleControlId: number;
    parentId: number;
    controlId: number;
    private subscriptions: Subscription[] = [];
    /**
     *
     * @param route {ActivatedRoute}
     * @param vehicleControlservice {VehicleControlService}
     * @param fb {FormBuilder}
     */
    constructor(
        private route: ActivatedRoute,
        private vehicleControlservice: VehicleControlService,
        private fb: FormBuilder,
        private router: Router,
        private location: Location
    ) {
        this.controlForm = this.fb.group({
            CheckpointName: ['', Validators.required],
            Comments: ['']
        });
        this.vehicleControlId = route.snapshot.params['id'];
        this.parentId = route.snapshot.parent.parent.params['id'];
    }

    ngOnInit() {
        document.querySelector("body").classList.remove("opened");
        this.vehicleControl = <ControlDto>this.route.snapshot.data['details'];
        if (this.vehicleControl) {
            this.controlId = this.vehicleControl.Id;
            this.controlDetails = {
                CheckpointName: this.vehicleControl.Title,
                Comments: this.vehicleControl.Description
            };
            this.controlForm.setValue(this.controlDetails, { onlySelf: true });
        }
    }
    /**
     * Save vehicle control
     */
    saveVehicleControl() {
        let vehicleControl = this.vehicleControl
            ? this.vehicleControl
            : new ControlDto();
        vehicleControl.Id = this.controlId ? this.controlId : 0;
        vehicleControl.VehicleId = this.parentId;
        vehicleControl.Title = this.controlForm.value.CheckpointName;
        vehicleControl.Description = this.controlForm.value.Comments;
        vehicleControl.Status = '1';
        if (this.vehicleControlId > 0) {
            vehicleControl.ModifiedBy = BaseServices.UserId;
            vehicleControl.Id = this.vehicleControlId;
            this.subscriptions.push(this.vehicleControlservice
                .updateVehicleControl(vehicleControl, vehicleControl.Id)
                .subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                }));
        } else {
            vehicleControl.CreatedBy = BaseServices.UserId;
            this.subscriptions.push(this.vehicleControlservice
                .addVehicleControl(vehicleControl)
                .subscribe(result => {
                    if (result) {
                        this.toasterComponent.callToast();
                    }
                }));
        }
        this.resetForm();
    }
    resetForm() {
        this.controlId = 0;
        this.controlForm.reset();
    }
    /**
     * Save And New Form
     */
    SaveAndNew() {
        this.controlId = 0;
        this.saveVehicleControl();
    }
    gotoList() {
        this.router.navigate(['./../list'], { relativeTo: this.route });
    }
    back() {
        this.location.back();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
