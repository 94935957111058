<form [formGroup]="businessUserForm">
    <div class="form">
        <div class="row">
            <div class="col-sm-5">
                <div class="form-group" *ngIf="businessUserForm.controls['Name']">
                    <label for="Name">
                        <span translate>NAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="Name" id="Name" required />
                    </div>
                    <control-messages [control]="businessUserForm.controls['Name']"></control-messages>
                </div>
                <div class="form-group" *ngIf="businessUserForm.controls['Username']">
                    <label for="Username">
                        <span translate>USERNAME</span>
                        <span class="required">*</span>
                    </label>
                    <div>
                        <input type="text" pInputText formControlName="Username" id="Username" required />
                    </div>
                    <div>
                        <a routerLinkActive="active" class="btn btn-link" (click)="clearUserName()"
                            translate>OTHER_NAME</a>
                    </div>
                    <control-messages [control]="businessUserForm.controls['Username']"></control-messages>
                </div>
                <div class="form-group" *ngIf="businessUserForm.controls['RoleId']">
                    <label for="RoleId">
                        <span translate>ADDITIONAL_ROLE</span>
                    </label>
                    <div>
                        <p-dropdown formControlName="RoleId" id="RoleId" [options]="Roles" [style]="{'width':'100%'}"
                            (onChange)="onRoleDropDownChanged($event)"></p-dropdown>
                    </div>
                </div>
                <div class="form-group" *ngIf="businessUserForm.controls['ownerofAccount']">
                    <label for="Name">
                        <span translate>OWNER_ACCOUNT</span>
                    </label>
                    <div>
                        <input type="text" formControlName="ownerofAccount" readonly pInputText id="Name" />
                    </div>

                </div>

                <div class="form-group">
                    <label for="Mobile">
                        <span translate>MOBILE</span>
                    </label>
                    <div class="phone-field">
                        <p-dropdown [options]="mobileCodes" formControlName="countryCode" [filter]="true"
                            [style]="{'width':'110px'}"></p-dropdown>
                        <input  type="text" pInputText onpaste="return false;"
                            formControlName="Mobile" (keypress)="onMobileNumber($event,20)">
                    </div>
                </div>
                <div class="form-group" *ngIf="businessUserForm.controls['Email']">
                    <label for="Email">
                        <span translate>EMAIL</span>
                        <span class="required">*</span>

                    </label>
                    <div>
                        <input type="text" pInputText formControlName="Email" id="Email" required />
                    </div>
                    <control-messages [control]="businessUserForm.controls['Email']"></control-messages>
                </div>
                <div formGroupName="newPasswordValidation">
                    <div class="form-group" *ngIf="isNewUser">
                        <label for="Password">
                            <span translate>PASSWORD</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <input type="password" pInputText formControlName="Password" id="Password" required />
                        </div>
                        <control-messages [control]="businessUserForm.controls['Password']"></control-messages>
                    </div>
                    <div class="form-group" *ngIf="isNewUser">
                        <label for="RepeatPassword">
                            <span translate>REPEAT_PASSWORD</span>
                            <span class="required">*</span>
                        </label>
                        <div>
                            <input type="password" pInputText id="ConfirmPassword" formControlName="ConfirmPassword" />
                        </div>
                    </div>
                    <control-messages [control]="businessUserForm.controls['newPasswordValidation']"></control-messages>
                </div>
                <div *ngIf="hidden">
                    <label>
                        <span translate>YOUR_RANDOM_PASSWORD</span>: {{showPassword}}</label>
                </div>
                <div class="form-group">
                    <div class="checkbox">
                        <p-checkbox formControlName="SendUserData" [binary]="false"
                            label="{{'USERINFO_SMS' | translate}}"></p-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="isRightSelect">
                <div formArrayName="userCategories" *ngIf="editedUser">
                    <div class="row">
                        <div class="col-sm-4">
                            <span class="page-title" translate>USER_RIGHTS</span>
                            <div
                                *ngFor="let def of businessUserForm.controls['userCategories']['controls']; let i = index">
                                <div *ngIf="userRightsData[i].rightsType === 1">
                                    <div
                                        *ngIf="(administrativeRights.indexOf(userRightsData[i].id) < 0);else testBlock">
                                        <label>
                                            <span formGroupName="{{i}}">
                                                <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                    (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                {{userRightsData[i].name | translate}}
                                            </span>
                                        </label>
                                    </div>
                                    <ng-template #testBlock>
                                        <div *ngIf="(userRightsData[i].id === '4' && true)">
                                            <label>
                                                <span formGroupName="{{i}}">
                                                    <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                        (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                    {{userRightsData[i].name | translate}}
                                                </span>
                                            </label>
                                        </div>
                                        <div *ngIf="(userRightsData[i].id === '5' && true)">
                                            <label>
                                                <span formGroupName="{{i}}">
                                                    <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                        (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                    {{userRightsData[i].name | translate}}
                                                </span>
                                            </label>
                                        </div>
                                        <div *ngIf="(userRightsData[i].id === '6' && true)">
                                            <label>
                                                <span formGroupName="{{i}}">
                                                    <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                        (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                    {{userRightsData[i].name | translate}}
                                                </span>
                                            </label>
                                        </div>
                                        <div *ngIf="(userRightsData[i].id === '7' && true)">
                                            <label>
                                                <span formGroupName="{{i}}">
                                                    <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                        (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                    {{userRightsData[i].name | translate}}
                                                </span>
                                            </label>
                                        </div>
                                        <div *ngIf="(userRightsData[i].id === '8' && true)">
                                            <label>
                                                <span formGroupName="{{i}}">
                                                    <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                        (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                    {{userRightsData[i].name | translate}}
                                                </span>
                                            </label>
                                        </div>
                                        <div *ngIf="(userRightsData[i].id === '9' && true)">
                                            <label>
                                                <span formGroupName="{{i}}">
                                                    <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                        (change)="onRightsCheckboxChange($event, userRightsData[i].id , 'USER')">{{userRightsData[i].id}}
                                                    {{userRightsData[i].name | translate}}
                                                </span>
                                            </label>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <span class="page-title" translate>EMPLOYEE_RIGHTS</span>
                            <div
                                *ngFor="let def of businessUserForm.controls['userCategories']['controls']; let i = index">
                                <div *ngIf="userRightsData[i].rightsType === 2">
                                    <label>
                                        <span formGroupName="{{i}}">
                                            <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                (change)="onRightsCheckboxChange($event, userRightsData[i].id,'USER')">
                                            {{userRightsData[i].name | translate}}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <span class="page-title" translate>DEVIATION_RIGHTS</span>
                            <div
                                *ngFor="let def of businessUserForm.controls['userCategories']['controls']; let i = index">
                                <div *ngIf="userRightsData[i].rightsType === 3">
                                    <label>
                                        <span formGroupName="{{i}}">
                                            <input type="checkbox" formControlName="{{userRightsData[i].id}}"
                                                (change)="onRightsCheckboxChange($event, userRightsData[i].id,'USER')">
                                            {{userRightsData[i].name | translate}}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="checkbox" *ngFor="let def of businessUserForm.controls['userCategories'].controls; let i = index">
                            <div>
                                <label>
                                    <span formGroupName="{{i}}">
                                        <input type="checkbox" formControlName="{{userRightsData[i].id}}" (change)="onRightsCheckboxChange($event, userRightsData[i].id)"> {{userRightsData[i].name | translate}}
                                    </span>
                                </label>
                            </div>
                        </div> -->
                </div>
                <div formArrayName="guestCategories" *ngIf="editedGuest">
                    <div class="row">
                        <div class="col-sm-4">
                            <span class="page-title" translate>USER_RIGHTS</span>
                            <div class="checkbox"
                                *ngFor="let def of businessUserForm.controls['guestCategories']['controls']; let j = index">
                                <div *ngIf="guestRightsData[j].rightsType === 1">
                                    <label>
                                        <span formGroupName="{{j}}">
                                            <input type="checkbox" formControlName="{{guestRightsData[j].id}}"
                                                (change)="onRightsCheckboxChange($event, guestRightsData[j].id,'GUEST')">
                                            {{guestRightsData[j].name | translate}}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <button class="btn btn-primary" *ngIf="showChooseProject" (click)="chooseProject()"
                                translate>CHOOSE_PROJECT_FDV</button>
                            <button class="btn btn-primary" *ngIf="showChooseKundeEL" style="margin:5px 0"
                                (click)="chooseKundeEL()" translate>CHOOSE_KUNDE_EL</button>
                        </div>
                        <div class="col-sm-4" *ngIf="!hideEmployeeRights">
                            <span class="page-title" translate>EMPLOYEE_RIGHTS</span>
                            <div class="checkbox"
                                *ngFor="let def of businessUserForm.controls['guestCategories']['controls']; let j = index">
                                <div *ngIf="guestRightsData[j].rightsType === 2">
                                    <label>
                                        <span formGroupName="{{j}}">
                                            <input type="checkbox" formControlName="{{guestRightsData[j].id}}"
                                                (change)="onRightsCheckboxChange($event, guestRightsData[j].id,'GUEST')">
                                            {{guestRightsData[j].name | translate}}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <span class="page-title" translate>DEVIATION_RIGHTS</span>
                            <div class="checkbox"
                                *ngFor="let def of businessUserForm.controls['guestCategories']['controls']; let j = index">
                                <div *ngIf="guestRightsData[j].rightsType === 3">
                                    <label>
                                        <span formGroupName="{{j}}">
                                            <input type="checkbox" formControlName="{{guestRightsData[j].id}}"
                                                (change)="onRightsCheckboxChange($event, guestRightsData[j].id,'GUEST')">
                                            {{guestRightsData[j].name | translate}}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div formArrayName="editorCategories" class="form-group" *ngIf="editedEditor">
                    <span class="page-title" translate>USER_RIGHTS</span>
                    <div class="checkbox"
                        *ngFor="let def of businessUserForm.controls['editorCategories']['controls']; let k = index">
                        <label>
                            <span formGroupName="{{k}}">
                                <input type="checkbox" formControlName="{{editorRightsData[k].id}}"
                                    (change)="onRightsCheckboxChange($event, editorRightsData[k].id,'EDITOR')">
                                {{editorRightsData[k].name| translate}}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

    </div>
</form>
<div class="action-btns-wrapper">
    <button class="btn btn-outline-secondary" (click)="gotoList()">
        <span class="icon ic-xs icon-back"></span>
        <span translate>BACK_LIST</span>
    </button>
    <button class="btn btn-outline-primary" *ngIf="isNewUser" (click)="generateRandomPassword()">
        <span translate>RANDOM_PASSWORD</span>
    </button>
    <button class="btn btn-primary" (click)="saveUser()">
        <span translate>SAVE</span>
    </button>
    <button class="btn btn-primary" *ngIf="!isNewUser" (click)="showResetPassword()">
        <span translate>RESET_PASSWORD</span>
    </button>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>
<p-dialog header="{{'RESET_PASSWORD' | translate}}" (onHide)="clear()" [(visible)]="showPasswordResetDialog" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <form [formGroup]="updatePasswordForm">
        <!-- <div class="form-group" *ngIf="!isNewUser">
            <label for="RepeatPassword">
                <span translate>OLD_PASSWORD</span>
                <span class="required">*</span>
            </label>
            <div>
                <input type="password" pInputText id="OldPassword" formControlName="OldPassword" />
            </div>
            <control-messages [control]="businessUserForm.controls['Password']"></control-messages>
        </div> -->
        <div formGroupName="passwordValidation">
            <div class="form-group" *ngIf="!isNewUser">
                <label for="RepeatPassword">
                    <span translate>NEW_PASSWORD</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="password" pInputText id="NewPassword" formControlName="Password" />
                </div>
            </div>
            <div class="form-group" *ngIf="!isNewUser">
                <label for="RepeatPassword">
                    <span translate>CONFIRM_PASSWORD</span>
                    <span class="required">*</span>
                </label>
                <div>
                    <input type="password" pInputText id="ConfirmPassword" formControlName="ConfirmPassword" required />
                </div>
            </div>
            <control-messages [control]="updatePasswordForm.controls['passwordValidation']"></control-messages>
        </div>
        <button class="btn btn-outline-primary" (click)="resetPassWord()" [disabled]="!updatePasswordForm.valid">
            <span translate>SAVE</span>
        </button>
    </form>
</p-dialog>

<p-dialog header="{{'PROJECT_FDV' | translate}}" (onHide)="clear()" [(visible)]="showProjectDialog" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <div class="table-view">
        <!-- Table starts -->
        <p-table #at [value]="ProjectList" [rows]="5" dataKey="Id" [(selection)]="selectedProject" [paginator]="true"
            [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 38px;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="Title">{{'NAME_PROJECT'| translate}}</th>
                </tr>
                <tr>
                    <th style="width: 38px;"></th>
                    <th>
                        <input pInputText type="text"
                            (input)="at.filter($any($event.target)?.value, 'Title', 'contains')"
                            class="p-column-filter">
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td style="width: 38px;">
                        <p-tableCheckbox id="selectedOrders" [value]="user">
                        </p-tableCheckbox>
                    </td>
                    <td>
                        <span>{{user.Title}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
        <!-- Table starts -->
        <p-table #bt [value]="FDVList" [rows]="5" dataKey="Id" [(selection)]="selectedFDV" [paginator]="true"
            [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 38px;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="Name">{{'NAME_FDV'| translate}}</th>
                </tr>
                <tr>
                    <th style="width: 38px;"></th>
                    <th>
                        <input pInputText type="text"
                            (input)="bt.filter($any($event.target)?.value, 'Name', 'contains')" class="p-column-filter">
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td style="width: 38px;">
                        <p-tableCheckbox id="selectedOrders" [value]="user">
                        </p-tableCheckbox>
                    </td>
                    <td>
                        <span>{{user.Name}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
    <button class="btn btn-primary" (click)="saveProjectAndFdv()" translate>SAVE_CLOSE</button>
</p-dialog>
<p-dialog header="{{'KUNDE_EL' | translate}}" (onHide)="clear()" [(visible)]="showKundeELDialog" 
[style]="{width: '300px'}" [resizable]="false" [draggable]="false">
    <div class="table-view">
        <!-- Table starts -->
        <p-table #ct [value]="contractList" [rows]="5" dataKey="Id" [(selection)]="selectedContract" [paginator]="true"
            [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [responsive]="true">
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 38px;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="ContractName">{{'CONTRACT_NAME'| translate}}</th>
                </tr>
                <tr>
                    <th style="width: 38px;"></th>
                    <th>
                        <input pInputText type="text"
                            (input)="ct.filter($any($event.target)?.value, 'ContractName', 'contains')"
                            class="p-column-filter">
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td style="width: 38px;">
                        <p-tableCheckbox id="selectedOrders" [value]="user">
                        </p-tableCheckbox>
                    </td>
                    <td>
                        <span>{{user.ContractName}}</span>
                    </td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="2">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->

    </div>
    <button class="btn btn-primary" (click)="saveKundeEL()" translate>SAVE_CLOSE</button>
</p-dialog>