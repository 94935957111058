import { ResetPasswordComponent } from './reset-password.component';
import { AppSharedModule } from './../kuba/shared/appShared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ForgetPasswordComponent } from './forget-password.component';
import { NgModule } from '@angular/core';
import { ToasterModule } from 'angular2-toaster';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AppSharedModule,
        ToasterModule
    ],
    exports: [
        ForgetPasswordComponent,
        ResetPasswordComponent
    ],
    declarations: [
        ForgetPasswordComponent,
        ResetPasswordComponent
    ],
    providers: [],
})
export class ForgetPasswordModule { }
