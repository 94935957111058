import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { FrameworkAgreement } from 'src/app/kuba/framework-agreement/models/FrameworkEdit';
import { FrameworkList } from 'src/app/kuba/framework-agreement/models/frameworkList';
import { Observable } from 'rxjs';

import { BaseServices } from './../../kuba.services';
import { FrameworkAgreementServices } from './framework.service';

@Injectable()
export class FrameworkListResolver implements Resolve<any[]> {
    constructor(private backend: FrameworkAgreementServices) { }
    resolve(): Observable<any[]> {
        return this.backend.getFrameworkList(BaseServices.BusinessId);
    }
}

@Injectable()
export class FrameworkListByUserResolver implements Resolve<any[]> {
    constructor(private backend: FrameworkAgreementServices) { }
    resolve(): Observable<any[]> {
        return this.backend.getFrameworkByUser(BaseServices.UserId);
    }
}

@Injectable()
export class FrameworkAgreementListResolver implements Resolve<FrameworkList[]> {
    constructor(private backend: FrameworkAgreementServices) { }
    resolve(): Observable<FrameworkList[]> {
        return this.backend.getFrameworklistByBusiness();
    }
}
@Injectable()
export class FrameworkEditResolver implements Resolve<FrameworkAgreement[]> {
    constructor(private backend: FrameworkAgreementServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<FrameworkAgreement[]> {
        return this.backend.getFrameworkById(+route.params['fId']);
    }
}

@Injectable()
export class FrameworkFeaturesResolver implements Resolve<any[]> {
    constructor(private backend: FrameworkAgreementServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.backend.getFrameworkFeatures(BaseServices.BusinessId);
    }
}


