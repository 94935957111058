import { Router, ActivatedRoute } from '@angular/router';
import { ActivityServices } from './../services/activities.services';
import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Rights } from './../../../_models/feature';
import { BaseServices } from './../../kuba.services';
import { Subscription } from 'rxjs';
@Component({
    selector: 'activity-new',
    templateUrl: 'activity-new.component.html'
})
export class ActivityNewComponent implements OnInit {


    //#region variable

    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    displayDialog = false;
    newActivityForm: FormGroup;
    isAdministrateActivity: boolean;
    isAdmin: boolean;
    private subscriptions: Subscription[] = [];
    //#endregion

    //#region constructor

    /**
     * constructor
     * @param _fb {FormBuilder}
     * @param activityService {ActivityServices}
     * @param route {ActivatedRoute}
     * @param router {Router}
     */
    constructor(
        private _fb: FormBuilder,
        private activityService: ActivityServices,
        private route: ActivatedRoute,
        public router: Router
    ) {
        this.newActivityForm = this._fb.group({
            Status: 'New Activity',
            Title: ['', Validators.required],
            Description: ['']
        });
    }

    //#endregion

    //#region page-event

    ngOnInit(): void {
        document.querySelector("body").classList.remove("opened");
        if (BaseServices.roleId === '1') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        this.onAdministrateActivity();
    }
    //#endregion

    //#region control-event

    /**
    * show new activity pop-up
    */
    showDialogToAdd() {
        if (BaseServices.roleId === '2') {
            this.router.navigate(['portalactivity'], { relativeTo: this.route });
        } else { this.displayDialog = true; }
    }

    /**
    * close new activity pop-up
    */
    cancel() {
        this.newActivityForm.reset();
        this.displayDialog = false;
    }
    /**
    * edit the activity page based upon the rights
    */
    onAdministrateActivity() {
        let currentUserRole = BaseServices.UserRole;
        let userRightsId = Rights.ADMINISTRATE_ACTIVITIES;
        if (currentUserRole === 'User') {
            this.isAdministrateActivity = BaseServices.checkUserRights(
                userRightsId,
                currentUserRole
            );
        } else {
            this.isAdministrateActivity = true;
        }
    }
    /**
    * saving new activity
    * @param activity {any}
    */
    onSubmitTemplateBased(activity: any) {
        let role = BaseServices.roleId;
        let businessId = BaseServices.BusinessId;
        activity.Status = 7;
        activity.BusinessId = businessId ? businessId : 0;
        activity.ApplicationId = BaseServices.ApplicationId;
        activity.CreatedBy = BaseServices.UserId;
        if (role === '1') {
            activity.IsAdmin = true;
        } else {
            activity.IsAdmin = false;
        }
        this.subscriptions.push(this.activityService
            .add(activity, BaseServices.ApplicationId)
            .subscribe((res: any) => {
                if (res) {
                    this.toasterComponent.callToast();
                    this.cancel();
                }
                setTimeout(() => {
                    this.router.navigate(['../edit', res.Id], {
                        queryParams: {},
                        relativeTo: this.route
                    });
                }, 1000);
            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
    //#endregion


}
