import { fromEvent, Observable } from 'rxjs';
import 'rxjs/add/observable/fromEvent';

export enum ConnectionStatusEnum {
  Online,
  Offline
}

export class NetworkConnection {

  public static status: ConnectionStatusEnum = ConnectionStatusEnum.Online;
  private static online$: Observable<any>;
  private static offline$: Observable<any>;

  public static init() {
    NetworkConnection.online$ = fromEvent(window, 'online');
    NetworkConnection.offline$ = fromEvent(window, 'offline');

    NetworkConnection.online$.subscribe(e => {
      console.log('Online');
      NetworkConnection.status = ConnectionStatusEnum.Online;
    });

    NetworkConnection.offline$.subscribe(e => {
      console.log('Offline');
      NetworkConnection.status = ConnectionStatusEnum.Offline;
    });
  }

  constructor() {
    NetworkConnection.init();
  }

}

new NetworkConnection();