import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'contract-tab.component',
    templateUrl: 'contract-tab.component.html'
})

export class ContractTabComponent implements OnInit {
    enabledManuals: any;
    tabsEnabled:string[];
    constructor( private route: ActivatedRoute) { }

    ngOnInit() { 
        this.tabsEnabled = [];
        this.enabledManuals = this.route.snapshot.parent.data['enabledFeatures'];
        if (this.enabledManuals ? this.enabledManuals.EnabledManuals : false) {
            (JSON.parse(this.enabledManuals.EnabledManuals)).forEach(m => {
                if (m.name == 'CONTRACT_FOR_INTERNAL_INSPECTION_OF_ELECTRICAL_INSTALLATIONS') {
                    this.tabsEnabled.push('control');
                }
            });
        }
        
    }
}