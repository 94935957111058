<div class="dropdown account">
    <a href="#" class="dropdown-toggle" id="profileSetting" role="button" data-toggle="dropdown" aria-expanded="false">
        <span class="profile-avatar">
            <img *ngIf="profileImg" class="user-img" [src]="profileImg" alt="">
        </span>
    </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="profileSetting">
        <h6 class="dropdown-header">
            <span class="profile-avatar">
                <img *ngIf="profileImg" class="user-img rounded-circle" [src]="profileImg" alt="">
            </span>
            <span>
                {{currentUser.Name}}<br />
                <small>{{currentUser.Email}}</small>
            </span>
        </h6>
        <div class="dropdown-divider"></div>
        <ng-template [ngIf]="!isExits">
            <a *ngIf="!isRMode" href="javascript:void(0);" class="dropdown-item" (click)="userProfileEdit()">
                <span *ngIf="rollId===2;else other" translate>PORTAL_PROFILE_SETTING</span>
                <ng-template #other>
                    <span translate>PROFILE_SETTINGS</span>
                </ng-template>
            </a>
        </ng-template>
        <a *ngIf="!isRMode" [routerLink]="['/login']" class="dropdown-item">
            <span translate>LOGOUT</span>
        </a>
    </div>
</div>