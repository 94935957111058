import { Component, OnInit } from '@angular/core';
import { FaqServices } from '../kuba/faqs/services/faqs.service';
import { BaseServices } from 'src/app/kuba/kuba.services';
import { environment } from 'src/environments/environment';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ThemeService } from '../theme.service';

@Component({
  templateUrl: 'faq.component.html',
  styles: [
    `
      vg-player {
        font-family: 'videogular';
        position: relative;
        display: flex;
        width: 100%;
        height: 50%;
        overflow: hidden;
        background-color: black;
      }
    `,
  ],
})
export class FaqComponent implements OnInit {
  //#region variable

  selectedCategory: any;
  selectedUrl: any;
  Faq: SelectItem[];
  isHide = true;
  cFaq: any;
  videoPath: string;
  isNoContent = false;
  assetloc: string = environment.BASE_STYLES_PATH;
  private subscriptions: Subscription[] = [];
  //#endregion

  //#region constructor

  /**
   * constructor
   * @param faqsvc {FaqServices}
   */
  constructor(
    private faqsvc: FaqServices,
    private themeService: ThemeService
  ) {}

  //#endregion

  //#region page-event

  /**
   * init
   */
  ngOnInit() {
    this.resetTheme();
    this.Faq = [];
    this.Faq.push({ label: 'Select a Category', value: null });
    this.subscriptions.push(
      this.faqsvc.getAllFeatures().subscribe((result) => {
        this.cFaq = result;
        this.cFaq = this.cFaq.filter(
          (x: any) =>
            x.TranslateKey != 'HMS-TV' && x.TranslateKey != 'INTERNAL-TV'
        );
        this.cFaq.forEach((feature: any) => {
          if (feature) {
            this.Faq.push({
              label: feature.Title,
              value: {
                id: feature.Id,
              },
            });
          }
        });
      })
    );
  }

  resetTheme(): void {
    // if (BaseServices.userTheme != null) {
    //     const timeNow = Date.now() / 1000 | 1;
    //     let styleLink = document.getElementById('appStyle')!;
    //     styleLink.setAttribute('href', this.assetloc + '/styles' + BaseServices.userTheme.Stylepath + '?ver=' + timeNow);
    // }
    if (BaseServices.userTheme && BaseServices.userTheme.Stylepath) {
      const themeId = BaseServices.userTheme.Id;
      const themePath = this.assetloc + BaseServices.userTheme.Stylepath;
      this.themeService.setTheme(themeId);
      this.themeService.applyTheme();
    }
  }
  //#endregion

  //#region control-event

  /**
   * dropdown change event
   * @param e {any}
   */
  onFaqChanged(e: any) {
    this.selectedUrl = '';
    this.subscriptions.push(
      this.faqsvc.getFaqById(e.value.id).subscribe((feature) => {
        this.selectedUrl = '';
        if (feature != null) {
          this.selectedUrl = feature;
        } else {
          this.selectedUrl = '';
          this.isNoContent = true;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub, i) => {
      sub.unsubscribe();
    });
  }

  //#endregion
}
