<div class="main-content">
    <div class="main-heading">
        <span translate>ASSIGN_MANUALS</span>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="mbtm-30">
                <div class="form-group" *ngIf="userRole!=2">
                    <p-radioButton label="{{'PORTAL'|translate}}" class="radio-marg"
                      (onClick)="onRadioTypeChange('Portal')" name="Portal" value="Portal" [(ngModel)]="selectedType">
                    </p-radioButton>
                    <p-radioButton label="{{'BUSINESS'|translate}}" class="radio-marg"
                      (onClick)="onRadioTypeChange('Business')" name="Business" value="Business"
                      [(ngModel)]="selectedType">
                    </p-radioButton>
                </div>
                <div class="input-btn-group">
                    <p-dropdown [options]="typeList" [(ngModel)]="levelKey"
                      (onChange)="onChangeType($event,optionName)" id="typeList" [filter]="true"
                      [style]="{'width':'100%'}"></p-dropdown>
                </div>
            </div>
            <div *ngIf="levelKey!=null" class="mbtm-30">
                <div class="page-title" translate>{{selectedType}} {{'MANUALS' |translate }}</div>
                <div class="table-view">
                    <assign-manual-list [CommonManuals]="assignManuals" [isAllCommonManual]="isAllCommonManual"
                      (onAssignManualEdit)="onBusinessOrPortalManualsEdit($event)" [ActiveList]="activeList"
                      [isBindCommonManual]="setbooleanFalse" [manualVersions]="manualVersionDropDown"
                      [userList]="userList" (onDeleteAssignedManual)="onDeleteAssignedManual($event)">
                    </assign-manual-list>
                </div>
            </div>
            <div>
                <span class="page-title" translate>COMMON_MANUAL</span>
                <assign-manual-list [CommonManuals]="CommonManuals" [roleId]="roleId"
                  [isAllCommonManual]="isAllCommonManual" [isBindCommonManual]="setbooleanTrue"
                  (onAssignManualEdit)="onSelectedCommonManuals($event)"
                  (onPortalCommonManualEdit)="onPortalCommonManualsEdit($event)"
                  [manualVersions]="manualVersionDropDown">
                </assign-manual-list>
            </div>
        </div>
    </div>
</div>
<toaster-component></toaster-component>

<p-dialog header="{{'ADD_VERSION'|translate}}" [(visible)]="showVersions" [style]="{width: '350px'}" [resizable]="false" [draggable]="false">
    <div class="form-group">
        <label for="title" class="col-form-label">
            <span translate>VERSION_NUMBER</span>
            <span class="required">*</span>
        </label>
        <div>
            <input type="text" [(ngModel)]="manualVersionField" #txtVerNo class="label-input form-control">
        </div>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="action-btns-wrapper">
            <button type="button" class="btn btn-outline-primary" (click)="viewType = !viewType">
                <span translate>VIEW</span>
            </button>
            <button type="button" class="btn btn-primary" (click)="addEditManualDetails()">
                <span translate>SAVE</span>
            </button>
        </div>
    </div>
    <!-- Table starts -->
    <p-table *ngIf="viewType" [value]="manualVersions" [rows]="5" [paginator]="true">
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th>{{'VERSION'|translate}}</th>
                <th></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-manual>
            <tr>
                <td>{{manual.VersionNo}}</td>
                <td class="col-button">
                    <button type="button" class="btn btn-icon" title="{{'EDIT'|translate}}"
                      (click)="editManualVersion(manual)">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </button>
                    <button type="button" class="btn btn-icon" title="{{'DELETE'|translate}}"
                      (click)="deleteManualVersion(manual)">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table ends -->
</p-dialog>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>