import { TrashListResolver } from './services/trash.resolver.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrashListComponent } from './components/TrashList.component';
import { TrashComponent } from './trash.component';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { TrashServices } from './services/trash.service';
import { AppSharedModule } from './../shared/appShared.module';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        TableModule,
        SharedModule,
        FormsModule,
        CalendarModule,
        EditorModule,
        ReactiveFormsModule,
        ContextMenuModule,
        MenuModule,
        ConfirmDialogModule,
        AppSharedModule
    ],
    declarations: [TrashComponent,
        TrashListComponent
    ],
    providers: [
        TrashServices,
        ConfirmationService,
        TrashListResolver
    ],
    bootstrap: [
        TrashListComponent,
        TrashComponent],
})
export class TrashModule { }
