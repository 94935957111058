import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/index';
import { CommonViewComponent } from './../common/components/commonView.component';


import { VehicleConsumptionComponent } from './vehicle-consumption.component';
import { VehicleConsumptionListComponent } from './components/vehicle-consumption-list.component';
import { VehicleConsumptionFormComponent } from './components/vehicle-consumption-form.component';
import {
    VehicleConsumptionListResolver,
    VehicleListResolver,
    VehiclePreviousNoResolver,
    VehicleConsumptionEditResolver
} from './services/vehicle-consumption-resolver.service';
import { timetableRoutes } from './../time-table/timetable.routing';
import { deviationRoutes } from './../deviation/deviation.routing';
export const vehicleConsumptionRoutes: Route[] = [
    {
        path: 'vehicle-consumption',
        component: VehicleConsumptionComponent,
        children: [
            {
                path: 'list',
                component: VehicleConsumptionListComponent,
                resolve: {
                    list: VehicleConsumptionListResolver
                }
            },
            {
                path: 'edit/:id',
                component: VehicleConsumptionFormComponent,
                resolve: {
                    vehicle: VehicleListResolver,
                    editconsumption: VehicleConsumptionEditResolver,
                    previousnumber: VehiclePreviousNoResolver
                }

            },
            {
                path: 'details/:id',
                component: CommonViewComponent,
                data: { parent: 'VEHICLE' },
                canActivate: [AuthGuard],
                children: [
                    ...deviationRoutes,
                    ...timetableRoutes
                ]
            }]
    }

];
