import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import 'rxjs/add/operator/map'
import { BaseServices } from './../../kuba.services';
import { Electro, ContractDetail, ContractDetailEdit, ElectroEnabledManuals, ElectroSettings } from '../models/electrocontract';
import { ElectroLAR } from '../models/electro-laws-regulations.model';
import { ElectroInspection, ElectroInspectionTasks } from '../models/electroinspection';
import { ElectroDeviation } from '../models/electrodeviation';
import { Observable } from 'rxjs';
import { TreeData } from 'src/app/kuba/common/models';
import { ArchiveDocument } from 'src/app/kuba/manuals/models/manual-document.model';
import { ElectroFeature } from '../models/electrofeatures';
import { ElectroInstallationBuliding } from '../models/electroinstallation';
import { ElectroContractContent } from '../models/electro-contract-content.models';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ElectroService {

    featureId: number;
    workingModule: any;

    constructor(private http: HttpClient) {
    }

    getClientById(id: number) {
        return this.http.get(environment.BASE_URL + '/client/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getClientContact(clientId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/contactsofclient/' + clientId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    //#region Get contract and dropdowns
    getContractsbybusiness() {
        return this.http.get(environment.BASE_URL + '/electro/getbusinesscontract/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getContractById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/getcontract/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getClientInfoById(contactId: number) {
        return this.http.get(environment.BASE_URL + '/clientcontact/clientcontacts/' + contactId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getContractContent() {
        return this.http.get(environment.BASE_URL + '/electro/getcontracttasks/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    checkcontractNum(contractNum) {
        return this.http.get(environment.BASE_URL + '/electro/check-contractnum/' + contractNum, BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteContractContent(id: number) {
        return this.http.delete(environment.BASE_URL + '/electro/deletecontractcontent/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getContractWorkers() {
        return this.http.get(environment.BASE_URL + '/electro/getcontractworkers/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion

    saveElectro(electro: Electro) {
        return this.http.post(environment.BASE_URL + '/electro/save', electro, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateElectro(electro: Electro) {
        return this.http.put(environment.BASE_URL + '/electro/update', electro, BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteElectro(id: number) {
        return this.http.delete(environment.BASE_URL + '/electro/delete/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    //#region Inspection scope 
    saveInspectionScope(electro: Electro, type: string) {
        return this.http.post(environment.BASE_URL + '/electro/saveinspectionscope/' + type, electro, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updaInspectionScope(electro: Electro) {
        return this.http.put(environment.BASE_URL + '/electro/updaInspectionScope', electro, BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteInspectionScope(id: number) {
        return this.http.delete(environment.BASE_URL + '/electro/deleteinspectionscope/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInspectionScope(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinspectionscope/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion

    //#region Control scope installation building

    getInstallationBuilding(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinstallationbuilding/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveInstallationBuilding(building: ElectroInstallationBuliding) {
        return this.http.post(environment.BASE_URL + '/electro/saveinstallationbuilding', building, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateInstallationBuilding(building: ElectroInstallationBuliding) {
        return this.http.put(environment.BASE_URL + '/electro/updateinstallationbuilding', building, BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteInstallationBuilding(id: number, status: number) {
        return this.http.delete(environment.BASE_URL + '/electro/deleteinstallationbuilding/' + id + '/' + status, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion

    //#region get electro followup(inspection) and dropdowns

    getInspectionChartData() {
        return this.http.get(environment.BASE_URL + '/electro/getinspectionchartdata/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInspectionsbybusiness() {
        return this.http.get(environment.BASE_URL + '/electro/getinspectionsbybusiness/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInspectionById(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinspection/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getAssignedWorkers(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getassignedworkers/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getAssignedWorkersByInspection(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getassignedworkersbyinspection/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getBuildingsByInspection(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getbuildingsbyinspection/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getAssignedTasks(contractId: number, inspectionId) {
        return this.http.get(environment.BASE_URL + '/electro/getassignedtasks/' + contractId + '/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getClientContactInfo(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getclientcontactinfo/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion

    saveInspection(inspection: ElectroInspection, cultureInfo: string) {
        return this.http.post(environment.BASE_URL + '/electro/saveInspection/' + cultureInfo, inspection, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateInspection(inspection: ElectroInspection, cultureInfo: string) {
        return this.http.put(environment.BASE_URL + '/electro/updateInspection/' + cultureInfo, inspection, BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteInspection(id: number) {
        return this.http.delete(environment.BASE_URL + '/electro/deleteinspection/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getArticlesByBusinessFeature(featureId: number, featureKey: number) {
        return this.http.get(environment.BASE_URL + '/electro/article/getarticlelist/'
            + BaseServices.ApplicationId + '/' + BaseServices.BusinessId + '/'
            + featureId + '/' + featureKey + '/' + +BaseServices.roleId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInternalControlbybusiness(featureKey: number) {
        return this.http.get(environment.BASE_URL + '/electro/article/getorganizationarticlelist/'
            + BaseServices.ApplicationId + '/' + BaseServices.BusinessId + '/'
            + ElectroFeature.INTERNAL_CONTROL_SYSTEM + '/' + featureKey, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInspectionReportbybusiness() {
        return this.http.get(environment.BASE_URL + '/electro/article/getorganizationarticlelist/' +
            BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getLawsList() {
        return this.http.get(environment.BASE_URL + '/electro/Lar/getallLawsbybusiness/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    addLARS(electroLARS: ElectroLAR) {
        return this.http
            .post(
                environment.BASE_URL + '/electro/Lar/addLar',
                electroLARS, BaseServices.headerOption
            )
            .map((result: any) => {
                return result;
            });
    }

    updateLARS(electroLARS: ElectroLAR) {
        return this.http
            .put(
                environment.BASE_URL + '/electro/Lar/update',
                electroLARS,
                BaseServices.headerOption
            )
            .map((result: any) => {
                return result;
            });
    }
    deleteLARS(id: number) {
        return this.http.delete(environment.BASE_URL + '/electro/Lar/delete/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getRegulationsList() {
        return this.http.get(environment.BASE_URL + '/electro/Lar/getallRegulationsbybusiness/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    //#region get deviation and dropdowns
    getDeviationByBusiness() {
        return this.http.get(environment.BASE_URL + '/electro/getdeviationbybusiness/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getDeviationById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/getdeviationbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion

    saveDeviation(deviation: ElectroDeviation, cultureInfo: string) {
        return this.http.post(environment.BASE_URL + '/electro/savedeviation/' + cultureInfo, deviation, BaseServices.headerOption)
            .map((result: any) => result);
    }

    updateDeviation(deviation: ElectroDeviation, cultureInfo: string) {
        return this.http.put(environment.BASE_URL + '/electro/updatedeviation/' + cultureInfo, deviation, BaseServices.headerOption)
            .map((result: any) => result);
    }

    deleteDeviation(id: number) {
        return this.http.delete(environment.BASE_URL + '/electro/deletedeviation/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getReportChecklist(featureKey) {
        return this.http.get(environment.BASE_URL + '/electro/getElectroChecklists/' + BaseServices.ApplicationId + '/' + BaseServices.BusinessId + '/' + featureKey, BaseServices.headerOption)
            .map((result: any) => result);
    }

    //#region Attachments
    deleteDeviationAttachment(id) {
        return this.http.delete(environment.BASE_URL + '/electro/deletedeviationattachment/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }

    deleteInspectionAttachment(id) {
        return this.http.delete(environment.BASE_URL + '/electro/deleteinspectionattachment/' + id, BaseServices.headerOption)
            .map((response: any) => { return response });
    }
    //#endregion

    getContractInfoById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/getcontractinfobyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    // get electro contract internal control and deviation closing details.
    getContractDetailById(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/getcontractdetailbyid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveUpdateInternalControl(electroContract: ContractDetailEdit) {
        return this.http.put(environment.BASE_URL + '/electro/saveupdateinternalcontrol', electroContract, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveUpdateDeviationClosing(electroContract: ContractDetailEdit) {
        return this.http.put(environment.BASE_URL + '/electro/saveupdatedeviationclosing', electroContract, BaseServices.headerOption)
            .map((result: any) => result);
    }

    //#region edit or print manual services
    getElectroManualByContractId(id: number, pagetype: number) {
        return this.http.get(environment.BASE_URL + '/electro/getmanualsbycontractid/'
            + id + '/' + BaseServices.BusinessId + '/' + BaseServices.ApplicationId + '/' + pagetype, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getEnabledElectroManualByContractId(id: number) {
        return this.http.get(environment.BASE_URL + '/electro/getenabledmanualsbycontractid/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveEnabledManuals(enabledManual: ElectroEnabledManuals) {
        return this.http.put(environment.BASE_URL + '/electro/saveenabledmanuals', enabledManual, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion

    sendAttachmentMail(reportData: any, reportData1: any, lawColumns: any, regulationColumns: any, headerName: any, headerName1: any, businessId: number, cultureInfo: string, ToMail: string) {
        let myData = JSON.stringify({ Laws: reportData, Regulations: reportData1, lawColumns: lawColumns, regulationColumns: regulationColumns });
        return this.http.post(environment.BASE_URL + '/electro/Lar/emailattachment/' + headerName + '/' + headerName1 + '/' + businessId + '/' + ToMail + '/' + BaseServices.UserId + '/' + cultureInfo, myData, BaseServices.headerOption);
        //.map((response: any) => { return response });
    }
    //#region settings services
    saveInspectionReportSettings(saveSettings: ElectroSettings) {
        return this.http.post(environment.BASE_URL + '/electro/savesettings', saveSettings, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getSettingsById() {
        return this.http.get(environment.BASE_URL + '/electro/getsettingsbybusiness'
            + '/' + BaseServices.ApplicationId + '/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    saveContractContent(content: ElectroContractContent) {
        return this.http.post(environment.BASE_URL + '/electro/savecontractcontent', content, BaseServices.headerOption)
            .map((result: any) => result);
    }
    //#endregion
    getInspectionsbyContract(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinspectionsbycontractid/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInstallerInspectionsbyContract(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinstallerinspectionbycontract/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getClientInspectionsbyContract(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getclientinspectionbycontract/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getDeviationsbyInspection(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getdeviationbyinspection/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getDeviationsbyContract(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getdeviationbycontract/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getElectroArchiveTree(businessId: number, contractId: number): Observable<TreeData[]> {
        return this.http
            .get(
                `${environment.BASE_URL}/electro/archive/electroarchivetreedata/${businessId}/${contractId}`,
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    // Add root folder
    createRootFolder(model, isRootFolder, contractId: number) {
        let sData = {
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            ContractId: contractId,
            FolderName: model.newNode.filename,
            ParentFolderId: model.parent.parentFolderId,
            DocumentTypeId: model.parent.documentTypeId,
            Status: 1,
            IsDefault: model.newNode.IsDefault,
            IsCommon: false,
            Position: 0,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId
        };

        return this.http
            .post(
                `${environment.BASE_URL}/electro/archive/new`,
                sData,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Add manual folder
    createManualFolder(model, isRootFolder, contractId: number) {
        let sData = {
            BusinessId: +BaseServices.BusinessId ? +BaseServices.BusinessId : 0,
            ContractId: contractId,
            FolderName: model.newNode.filename,
            ParentFolderId: isRootFolder ? 0 : model.parent.nodeId,
            DocumentTypeId: model.parent.documentTypeId,
            Status: 1,
            IsDefault: model.newNode.IsDefault,
            IsCommon: false,
            Position: 0,
            CreatedBy: +BaseServices.UserId,
            ModifiedBy: +BaseServices.UserId,
            IsParentBuilding: model.parent.isBuilding ? true : false
        };

        return this.http
            .post(
                `${environment.BASE_URL}/electro/archive/new`,
                sData,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Rename Archive Folder
    renameManualFolder(model) {
        let sData = {
            Id: +model.item.data.nodeId,
            FolderName: model.item.data.filename,
            CreatedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/electro/archive/renameFolder/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Manual folder
    deleteManualFolder(did) {
        return this.http
            .post(
                `${environment.BASE_URL}/electro/archive/deleteFolder/${did}`,
                '',
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    renameDocument(model) {
        let sData = {
            Id: +model.item.data.nodeId,
            FileName: model.item.data.filename,
            ModifiedBy: +BaseServices.UserId
        };
        return this.http
            .put(
                `${environment.BASE_URL}/electro/archive/rename/${sData.Id}`,
                sData,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete document
    deleteDocument(did) {
        return this.http
            .delete(
                `${environment.BASE_URL}/electro/archive/delete/${did}`,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleDocument(data) {
        return this.http
            .put(
                `${environment.BASE_URL}/electro/archive/deleteall`,
                data,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Archive checklist
    deleteArchiveDocument(id) {
        return this.http
            .put(
                `${environment.BASE_URL}/archive/delete/archivechecklist/${id}`,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Delete Archive checklist
    deleteFoodSafety(id) {
        return this.http
            .put(
                `${environment.BASE_URL}/foodsafety/deletefoodsafety/${id}`,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    deleteMultipleFoodSafety(data) {
        return this.http
            .put(
                `${environment.BASE_URL}/foodsafety/delete/`,
                data,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    // Document related services
    // Archive Document
    addArchiveDocument(uploadedFile, contractId: number) {
        let archiveDocument: ArchiveDocument = new ArchiveDocument();
        archiveDocument.BusinessId = uploadedFile.BusinessId;
        archiveDocument.ContractId = contractId;
        archiveDocument.FeatureId = 0;
        archiveDocument.FeatureKey = 0;
        archiveDocument.ArchiveChecklistId = 0;
        archiveDocument.ArchiveFolderId = uploadedFile.ArchiveFolderId;
        archiveDocument.FileName = uploadedFile.filename;
        archiveDocument.OriginalFileName = uploadedFile.filename;
        archiveDocument.Size = uploadedFile.size;
        archiveDocument.MimeType = uploadedFile.mimetype;
        archiveDocument.Path = uploadedFile.path;
        archiveDocument.FileType = uploadedFile.fileTypeId;
        archiveDocument.Status = 1;
        archiveDocument.CreatedBy = uploadedFile.userId;
        archiveDocument.IsParentBuilding = uploadedFile.IsParentBuilding;
        return this.http
            .post(
                `${environment.BASE_URL}/electro/archive/document/new`,
                archiveDocument,
                BaseServices.headerOption
            )
            .map((result: any) => result);
    }

    moveNode(model) {
        let sData;
        switch (model.to.parent.documentType) {
            case 'document':
                sData = {
                    Source: [
                        {
                            Type: model.node.type,
                            FolderId: model.node.nodeId
                        }
                    ],
                    Destination: {
                        FolderId: model.to.parent.nodeId,
                        ModifiedBy: BaseServices.UserId,
                        IsBuilding: model.to.parent.isBuilding
                    }
                };
                let res: any = this.http
                    .put(
                        `${environment.BASE_URL
                        }/electro/archive/DragAndDrop/document`,
                        sData,
                        BaseServices.headerOption
                    )
                    .map((result: any) => result)
                    .catch(error => BaseServices.handleError(error));
                return res;
            default:
                break;
        }
    }

    updateManualCheckboxstatus(data) {
        let sData = {
            Id: +data.nodeId,
            Status: +data.status,
            DocumentType: data.documentType.toLowerCase(),
            FileType: data.type.toLowerCase(),
            IsDefault: data.isDefault,
            BusinessId: BaseServices.BusinessId,
            AppId: BaseServices.ApplicationId,
            IsParentBuilding: data.IsParentBuilding
        };
        return this.http
            .put(
                `${environment.BASE_URL}/electro/archive/ArchivePermission/${BaseServices.UserId
                }`,
                sData,
                BaseServices.headerOption
            )
            .map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    saveInspectionTasks(inspectionTasks: ElectroInspectionTasks[], inspectionId: number) {
        return this.http.post(environment.BASE_URL + '/electro/saveinspectiontasks/' + inspectionId, inspectionTasks, BaseServices.headerOption)
            .map((result: any) => result);

    }

    getInspectionTasks(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinspectiontasks/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getInspectionNumberById(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getinspectionnumberbyid/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    checkclientNumAndContratcNum(clientNum, contractNum) {
        return this.http.get(environment.BASE_URL + '/electro/check-clientnum-contractnum/' + BaseServices.BusinessId + '/' + clientNum + '/' + contractNum, BaseServices.headerOption)
            .map((result: any) => result);
    }
    getClientWithClientNum() {
        return this.http.get(environment.BASE_URL + '/client/list-clients/' + BaseServices.BusinessId, BaseServices.headerOption)
            .map((result: any) => result);
    }
    GetClientUsersBySelfInspection(contractId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getclientusersbyselfinspection/' + contractId, BaseServices.headerOption)
            .map((result: any) => result);
    }

    UpdateDocument(fileId, userId) {
        let sData = {};
        // this.setWorkingModule();
        return this.http.put(
            `${environment.BASE_URL}/electro/archive/updateDocument/${fileId}/${userId}`,
            sData, BaseServices.headerOption
        ).map((result: any) => result)
            .catch(error => BaseServices.handleError(error));
    }

    GetViolatedParaReference() {
        return this.http.get(environment.BASE_URL + '/electro/getviolatedparareference', BaseServices.headerOption)
            .map((result: any) => result);
    }

    GetHighestContractNumOnClient(clinetId) {
        return this.http.get(environment.BASE_URL + `/electro/gethighestcontractnumonclient/${clinetId}`, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getContractByUserAssociated() {
        return this.http.get(environment.BASE_URL + '/electro/getcontractbyuserassociated/' + BaseServices.BusinessId
            + '/' + BaseServices.UserId, BaseServices.headerOption).map((result: any) => result);
    }

    checkInspectionNum(contractId, inspectionNum) {
        return this.http.get(environment.BASE_URL + '/electro/check-inspectionnum/' + contractId + '/' + inspectionNum, BaseServices.headerOption)
            .map((result: any) => result);
    }

    GetHighestDeviationNumber() {
        return this.http.get(environment.BASE_URL + `/electro/gethighestdeviationnumber`, BaseServices.headerOption)
            .map((result: any) => result);
    }

    getClientContactInfoByInspection(inspectionId: number) {
        return this.http.get(environment.BASE_URL + '/electro/getclientinfobyinspection/' + inspectionId, BaseServices.headerOption)
            .map((result: any) => result);
    }
}