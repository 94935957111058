export class SafetyBaseComponent {

    probabilityValue = 1;
    consequenceValue = 1;
    riskData: any = [];


    constructor() {

    }

    /**
     * color the risk figure based on probability value
     * @param probability {{any}}
     */
    probabilityChanged(probability:any) {
        this.probabilityValue = probability.value;
        this.setRiskFigure(this.probabilityValue, this.consequenceValue)
    }
    /**
     * color the risk figure based on consequence value
     * @param consequence {{any}}
     */
    consequenceChanged(consequence:any) {
        this.consequenceValue = consequence.value;
        this.setRiskFigure(this.probabilityValue, this.consequenceValue)
    }
    /**
     * based on consiquence and probability figure change the color
     * @param probability {{any}}
     * @param consequence {{any}}
     */
    setRiskFigure(probability:any, consequence:any) {
        if (probability && consequence) {
            probability = parseInt(probability);
            consequence = parseInt(consequence);
            let totalFigure = probability * consequence;
            if (totalFigure <= 6) { // Low Figure
                this.riskData.Id = 1;
                this.riskData.value = 'LOW';
                this.riskData.backColor = '#4ec55c';
            } else if (totalFigure > 14) { // High Figure
                this.riskData.Id = 3;
                this.riskData.value = 'HIGH';
                this.riskData.backColor = '#e7644f';
            } else { // Medium Figure 
                this.riskData.Id = 2;
                this.riskData.value = 'MEDIUM';
                this.riskData.backColor = '#f0ac19';
            }
        }
    }
}