<div class="table-view mbtm-30">
    <!-- Table starts -->
    <p-table #dt [value]="FoodSafety" [rows]="10" [paginator]="true" alwaysShow="false" [pageLinks]="3"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      [rowsPerPageOptions]="[5,10,20]"
      [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}">
        <!-- Caption -->
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <div class="table-options">
                    <div class="pull-right">
                        <kuba-export [reportData]="dt.filteredValue || dt.value"
                          [additionalData]="additionalData"></kuba-export>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- Header -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="Title">{{'OBJECT_NAME'|translate}} <p-sortIcon field="Title"></p-sortIcon></th>
                <th pSortableColumn="ObjectType" width="110px">{{'TYPE_OF_OBJECT'|translate}}</th>
                <th pSortableColumn="CreatorName" width="110px">{{'CREATED_BY'|translate}}</th>
                <th pSortableColumn="CreatedOn" width="100px">{{'CREATED_ON'|translate}}</th>
                <th pSortableColumn="Status" width="100px">{{'STATUS'|translate}} <p-sortIcon field="Status"></p-sortIcon></th>
                <th width="110px">{{'DEVIATIONS_IN_FOLLOWUP' | translate}} </th>
                <th *ngIf="isShowUser" width="90px">{{'OPTIONS'| translate}}</th>
            </tr>
            <tr>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'Title', 'contains')"
                      placeholder="" class="p-column-filter">
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'Status', 'contains')"
                      placeholder="" class="p-column-filter">
                </th>
                <th></th>
                <th *ngIf="isShowUser"></th>
            </tr>
        </ng-template>
        <!-- Body Content -->
        <ng-template pTemplate="body" let-fs>
            <tr>
                <td>
                    <a [routerLink]="['./../../details',fs.Id]" pTooltip="{{fs.Title}}"
                    tooltipPosition="top" [queryParams]="{ Title : fs.Title }">{{fs.Title}}</a>
                </td>
                <td>
                    <span>{{fs.ObjectType}}</span>
                </td>
                <td>
                    <span>{{fs.CreatorName}}</span>
                </td>
                <td>
                    <span>{{fs.CreatedOn |date : 'MM/dd/yyyy'}}</span>
                </td>
                <td>
                    <span translate>{{fs.Status ==1 ? 'ACTIVE' : 'INACTIVE'}}</span>
                </td>
                <td>
                    <input type="checkbox" [disabled]="isDisabled" [(ngModel)]="fs.ShowDeviation" />
                </td>
                <td class="col-button" *ngIf="isShowUser">
                    <a [routerLink]="['./../../edit',fs.Id]" class="btn btn-icon" title="{{'EDIT' | translate}}">
                        <i class="icon ic-sm icon-pencil-tip"></i>
                    </a>
                    <button type="button" class="btn btn-icon" (click)="deleteFoodSafety(fs['Id'])"
                      title="{{'DELETE' | translate}}">
                        <i class="icon ic-sm icon-trash"></i>
                    </button>
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" *ngIf="isShowUser" let-columns>
            <tr>
                <td colspan="7">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
        <!-- Empty message -->
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td colspan="6">
                    {{'NO_RECORDS_FOUND'|translate}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Table Ends-->
</div>
<router-outlet></router-outlet>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
  acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<toaster-component></toaster-component>