import { BaseServices } from './../../kuba.services';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FDVServices {
    constructor(private http: HttpClient) {
    }

    // list all fdv
    list(businessId: number) {
        return this.http
            .get(
            environment.BASE_URL +
            '/firedeviation/firedeviation/list/' +
            businessId,BaseServices.headerOption
            )
            .map((result:any) => result);
    }

    myList(bId: number, userId: number) {
        return this.http
            .get(
            environment.BASE_URL +
            '/firedeviation/firedeviation/my-list/' +
            bId +
            '/' +
            userId,BaseServices.headerOption
            )
            .map((result:any) => result);
    }
    getFdvByUserId(UserId: number) {
        return this.http
            .get(
            environment.BASE_URL +
            '/firedeviation/firedeviation-user/' +
            UserId,BaseServices.headerOption
            )
            .map(result => result);
    }
    getByFDVId(Id: number) {
        return this.http
            .get(environment.BASE_URL + '/firedeviation/firedeviation/' + Id,BaseServices.headerOption)
            .map((result:any) => result);
    }

    getTypeOfObject() {
        return this.http
            .get(environment.BASE_URL + '/businessPropertyTypes', BaseServices.headerOption)
            .map((result: any) => result);
    }

    getUser(id: number) {
        return this.http
            .get(environment.BASE_URL + '/employeecontactusers/' + id, BaseServices.headerOption)
            .map((result: any) => result);
    }

    addFdv(data: any) {
        return this.http
            .post(
            environment.BASE_URL + '/firedeviation/firedeviation',
            data, BaseServices.headerOption

            )
            .map(result => result);
    }
    update(data: any) {
        return this.http
            .put(
            environment.BASE_URL + '/firedeviation/deviation',
            data,
            BaseServices.headerOption
            )
            .map(result => result);
    }
    delete(id: number) {
        return this.http
            .delete(
            environment.BASE_URL +
            '/firedeviation/firedeviation/delete/' +
            id,BaseServices.headerOption
            )
            .map(result => result)
            .catch(this.handleError);
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    getFdvFeatures(businessId: number) {
        let featureId: number;
        let workingModule = JSON.parse(sessionStorage.getItem('workingModule'));
        featureId = workingModule.id;
        return this.http
            .get(
            environment.BASE_URL + '/business/' + businessId,
            BaseServices.headerOption
            )
            .map((result:any) => {
                let feature = result.Features;
                let fdvAdditionalFeature = _.find(JSON.parse(feature), {
                    id: featureId,
                    access: true
                });
                return fdvAdditionalFeature;
            });
    }
}
