export class deviationCaseType {
    Id: number;
    Name: string;
    Status: string;
    BusinessId: number;
    CreatedBy: string;
    CreatedOn: string;
    ModifiedBy: string;
    ModifiedOn: string;
    ApplicationId : number;
}