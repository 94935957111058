import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CrewListServices } from 'src/app/kuba/crew-list/services/crewlist.service';
import { Observable } from 'rxjs/Observable';



@Injectable()
export class CrewListResolver implements Resolve<any> {

    Id: number;
    constructor(private backend: CrewListServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getCrewList(+route.parent.parent.params['id'], route.parent.parent.data['parent']);
    }
}

@Injectable()
export class CrewListHistroyResolver implements Resolve<any> {
    Id: number;
    constructor(private backend: CrewListServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getCrewListHistroy(+route.parent.parent.params['id'], route.parent.parent.data['parent']);
    }
}

@Injectable()
export class PeojectInfoResolver implements Resolve<any> {
    constructor(private backend: CrewListServices) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getProjectInfo(+route.parent.parent.params['id'],  route.parent.parent.data['parent']);
    }
}

@Injectable()
export class ProjectUsersResolver implements Resolve<any> {

    Id: number;
    constructor(private backend: CrewListServices) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        return this.backend.getProjectUsers(+route.parent.parent.params['id']);
    }
}