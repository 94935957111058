<div class="tab-content">
    <form #f="ngForm" name="form" [formGroup]="homepageForm">
        <div class="form-group">
            <label class="form-check form-check-inline" *ngFor="let option of options">
                <input type="radio" formControlName="TypeOfContent" value="{{option.id}}"
                  [checked]="option.id === homepageForm['controls']?.['TypeOfContent']?.value" class="form-check-input">
                {{option.name | translate}}
            </label>
        </div>
        <div class="form-group">
            <label>
                <span>{{'LINK' | translate }} : </span>
            </label>
            <div>
                <input class="form-control" formControlName="Link" type="text">
                <div *ngIf="valid" class="text-success mtop-10">
                    <span translate>AVAILABLE</span>
                </div>
            </div>
            <control-messages [control]="homepageForm.controls['Link']"></control-messages>
        </div>
        <div class="panel-tabs mtop-20">
            <ul class="nav nav-pills">
                <li role="presentation" class="active" routerLinkActive="active">
                    <a (click)="changeNavigation(45)" href="javascript:void(0)" translate>ABOUT_US</a>
                </li>
                <li role="presentation" routerLinkActive="active">
                    <a (click)="changeNavigation(46)" href="javascript:void(0)" translate>CONTACTS_US</a>
                </li>
                <li role="presentation" routerLinkActive="active">
                    <a (click)="changeNavigation(47)" href="javascript:void(0)" translate>SERVICES</a>
                </li>
                <li role="presentation" routerLinkActive="active">
                    <a (click)="changeNavigation(48)" href="javascript:void(0)" translate>PRODUCTS</a>
                </li>
            </ul>
        </div>
        <div class="mtop-10">
            <p-editor [style]="{'height':'320px'}" formControlName="Description"></p-editor>
        </div>
        <div class="action-btns-wrapper">
            <button class="btn btn-outline-secondary" (click)="back()">
                <span class="icon ic-xs icon-back"></span>
                <span translate>BACK</span>
            </button>
            <a class="btn btn-outline-secondary" [routerLink]="['/login/',linkText,businessId]" target="_blank">
                <span translate>PREVIEW</span>
            </a>
            <button *ngIf="roleId < 3" type="submit" class="btn btn-outline-primary" (click)="rModeHomePage();">
                <span translate>R</span>
            </button>
            <button type="button" (click)="saveLinks()" class="btn btn-primary" translate=""
              [disabled]="!homepageForm.valid || loading" [ngClass]="{'loading' : loading}">
                <span *ngIf="!loading" translate>SAVE</span>
                <span *ngIf="loading" translate>SAVE_PROCESS</span>
                <span *ngIf="loading" class="loader-icon"></span></button>
        </div>
    </form>
</div>
<toaster-component></toaster-component>