<div class="tab-content">
    <div class="table-view">
        <!-- Table starts -->
        <p-table #contactsTable [value]="custContacts" [rows]="10" [paginator]="true" [pageLinks]="3"
        [showCurrentPageReport]="true" currentPageReportTemplate="{{'SUMMARY_TOTALRECORDS'|translate}}"
            [rowsPerPageOptions]="[5,10,20]" exportFilename="Customer Contacts Login" styleClass="p-datatable-striped">
            <!-- Caption -->
            <ng-template pTemplate="caption">
                <div class="ui-helper-clearfix">
                    <div class="table-options">
                        <div class="pull-right">
                            <kuba-export [reportData]="contactsTable.filteredValue || contactsTable.value"
                                [additionalData]="additionalData"></kuba-export>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="Name">{{'NAME' | translate}}</th>
                    <th pSortableColumn="ContactFunctionalAreaName">{{'FUNCTION' | translate}}</th>
                    <th>{{'BUSINESS'|translate}}</th>
                    <th>{{'USERNAME'|translate}}</th>
                    <th pSortableColumn="RoleName">{{'ADDITIONAL_ROLE' | translate}}</th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text"
                            (input)="contactsTable.filter($any($event.target)?.value, 'Name', 'contains')" placeholder=""
                            class="p-column-filter" >
                    </th>
                    <th>
                        <p-dropdown [options]="functions" (onChange)="onFunctionChanged($event, functionDropdown)"
                            #functionDropdown styleClass="p-column-filter" placeholder="Select" appendTo="body"
                            [showClear]="true">
                            <ng-template let-functions pTemplate="item"> {{ functions.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                        <p-dropdown [options]="roles" (onChange)="onRoleChanged($event, roleDropdown)" #roleDropdown
                            styleClass="p-column-filter" placeholder="Select" appendTo="body" [showClear]="true">
                            <ng-template let-roles pTemplate="item"> {{ roles.label | translate }}
                            </ng-template>
                        </p-dropdown>
                    </th>
                </tr>
            </ng-template>
            <!-- Body Content -->
            <ng-template pTemplate="body" let-customerContact>
                <tr>
                    <td><span>{{customerContact.Name}}</span></td>
                    <td><span>{{customerContact.ContactFunctionalAreaName}}</span></td>
                    <td><span>{{customerContact.BusinessName}}</span></td>
                    <td><span>{{customerContact.UserName}}</span></td>
                    <td><span>{{customerContact.RoleName}}</span></td>
                </tr>
            </ng-template>
            <!-- Empty message -->
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td colspan="5">
                        {{'NO_RECORDS_FOUND'|translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Table ends -->
    </div>
</div>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
    acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>