import { ToasterComponent } from 'src/app/_directives/toaster.component';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import {
    CompleteCheckList,
    CompleteChecklistEdit,
    CompleteChecklistSection,
    CompleteChecklistItemSection,
    CompleteChecklistStatus,
    CompleteAttachment
} from '../models/complete-checklist';
import { SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviationServices } from '../../deviation/services/deviation.service';
import { BaseServices } from '../../kuba.services';
import { environment } from '../../../../environments/environment';
import { UploaderConfig } from 'src/app/shared/ecpl-document-explorer/uploader-config';
import { CompleteCheckListServices } from '../services/complete-checklist.service';
import { ArchiveServices } from '../../archive/archive.services';
import * as _ from 'lodash';
import { UploadedFile } from 'src/app/shared/ecpl-document-explorer/interfaces';
import { DatePipe, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FeatureKey } from 'src/app/_models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-kuba-complete-checklist',
    templateUrl: './kuba-safety-job-analysis-complete-checklist.component.html',
    styleUrls: ['./kuba-complete-checklist.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class KubaCompleteSafteyJobChecklistComponent implements OnInit {
    CompleteCheckList: CompleteCheckList = {};
    responsibleperson: SelectItem[];
    scoreList: SelectItem[];
    CompleteChecklistEdit: CompleteChecklistEdit;
    jsonString: string;
    upConfig: UploaderConfig;
    treeNode: any;
    treeActiveNode: any;
    uploadedFiles: any;
    additionalData: any;
    type: string;
    featureId: number;
    feaureKey: number;
    routeType: string;

    checklistTitle: string;
    isScoreIndex: boolean;
    signature: string;
    parentId?: number;
    parentKey?: number;
    aproval = false;
    showLoader = true;
    @ViewChild(ToasterComponent,{static: false}) toasterComponent: ToasterComponent;
    @ViewChild('staticTabs',{static: false}) staticTabs: TabsetComponent;
    private subscriptions: Subscription[] = [];
    //#region variables

    constructor(
        private route: ActivatedRoute,
        private deviationServices: DeviationServices,
        private CompleteCheckListServices: CompleteCheckListServices,
        private archiveServices: ArchiveServices,
        private datePipe: DatePipe,
        private router: Router,
        private translate: TranslateService,
        private location: Location,
    ) {
        this.additionalData = {
            ApplicationId: BaseServices.ApplicationId,
            BusinessId: BaseServices.BusinessId,
            Status: '1',
            CreatedOn: new Date(),
            ModifiedBy: BaseServices.UserId,
            ModifiedOn: new Date(),
            ModuleName: 'completechecklist'
        };
        this.upConfig = {
            title: this.translate.instant('DOCUMENTS'),
            titleAsLabel: true,
            editMode: true,
            windowedHeight: false,
            viewSwitch: false,
            showUserMeta: false,
            showSearchFilter: false,
            showUploadButton: true,
            uploaderUri:
                environment.BASE_URL +
                '/file/upload/business/completechecklist',
            addtionalData: this.additionalData
        };

        this.uploadedFiles = [];
    }

    selectTab(tab_id: number) {
        this.staticTabs.tabs[tab_id].active = true;
    }

    disableEnable() {
        this.staticTabs.tabs[2].disabled = true;
    }

    setStatus(
        event: CompleteChecklistStatus,
        status: CompleteChecklistStatus[],
        CompleteChecklistItemSection: CompleteChecklistItemSection
    ) {
        for (let statusitem of status) {
            if (statusitem.Label === event.Label) {
                statusitem.value = true;
            } else {
                statusitem.value = false;
            }
        }

        this.subscriptions.push(this.CompleteCheckListServices.getCurrentDate().subscribe((result:any) => {
            if (result) {
                CompleteChecklistItemSection.Signature =
                    this.translate.instant('SIGNED') +
                    BaseServices.userName +
                    ' ' +
                    this.datePipe.transform(result, 'dd/MM/yyyy HH:mm');
            }
        }));
    }

    uploaderEvents(
        event: any,
        CompleteChecklistItemSection: CompleteChecklistItemSection
    ) {
        switch (event.eventName) {
            case 'DeleteDefaultFile':
                CompleteChecklistItemSection.Attachments = CompleteChecklistItemSection.Attachments.filter(
                    x => x.path !== event.item.data.path
                );

                break;
            default:
                let uploadedFile = event;
                // TODO: add  uploaded files to attachment objects
                break;
        }
    }

    ngOnInit() {
        this.signature =
            BaseServices.userName +
            ' ' +
            this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:MM');
            if (this.staticTabs != undefined) {
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
            }
        this.BindScore();
        this.BindTreeNode();

        this.CompleteChecklistEdit = new CompleteChecklistEdit();
        this.CompleteCheckList = new CompleteCheckList();

        this.CompleteCheckList.ChecklistId = this.route.snapshot.params['clId'];
        this.type = this.route.snapshot.params['Type'];
        this.featureId = this.route.snapshot.params['FeatureId'];
        this.feaureKey = this.route.snapshot.params['FeatureKey'];
        this.parentId = this.route.snapshot.params['ParentId'];
        this.parentKey = this.route.snapshot.params['ParentKey'];



        switch (this.route.snapshot.url[1].path) {
            case 'new':
                if (this.type === 'project') {
                    this.GetFeatureMaualChecklistdetails(
                        this.route.snapshot.params['clId']
                    );
                }
                this.SaveFirstStepCompleteCheckList();
                if (this.staticTabs != undefined) {
                this.staticTabs.tabs[0].disabled = false;
                this.staticTabs.tabs[1].disabled = true;
                }
                break;
            case 'incomplete':
                if (this.type === 'project') {
                    this.showLoader = true;
                    this.CompleteChecklistEdit = new CompleteChecklistEdit();
                    this.subscriptions.push(this.CompleteCheckListServices.getFeatureCompleteCheckListData(
                        this.route.snapshot.params['clId']
                    ).subscribe(result => {
                        if (result) {
                            this.showLoader = false;
                            this.CompleteCheckList = new CompleteCheckList();
                            this.CompleteCheckList = result;
                            this.jsonString = this.CompleteCheckList.CheckListData;
                            this.CompleteChecklistEdit = new CompleteChecklistEdit();
                            this.CompleteChecklistEdit = JSON.parse(
                                this.jsonString
                            );
                            this.CompleteCheckList.ParentId = this.parentId;
                            this.CompleteCheckList.ParentKey = this.parentKey;
                            this.GetFeatureMaualChecklistdetails(
                                this.CompleteCheckList.ChecklistId
                            );
                        }
                    }));
                }
                if (this.staticTabs != undefined) {
                this.staticTabs.tabs[0].disabled = false;
                this.staticTabs.tabs[1].disabled = true;
                }
                break;
        }
        this.responsibleperson = [];
        this.subscriptions.push(this.deviationServices
            .getUser(BaseServices.BusinessId)
            .subscribe(result => {
                this.subscriptions.push(this.translate.stream('SELECT_DROPDOWN').subscribe(val => {
                    this.responsibleperson = [];
                    this.responsibleperson.push(
                        { label: val.SELECT, value: null }
                    );
                }));
                result.forEach(element => {
                    this.responsibleperson.push({
                        label: element.Name,
                        value: element.ContactPersonId
                    });
                });
            }));
    }

    SaveFirstStepCompleteCheckList() {
        this.showLoader = true;
        this.CompleteCheckList.BusinessId = BaseServices.BusinessId;
        this.CompleteCheckList.FeatureId = this.featureId;
        this.CompleteCheckList.FeatureKey = this.feaureKey;
        this.CompleteCheckList.Status = 1;
        this.CompleteCheckList.ParentId = this.parentId;
        this.CompleteCheckList.ParentKey = this.parentKey;
        this.CompleteCheckList.Title = this.checklistTitle;
        this.CompleteCheckList.ApplicationId = BaseServices.ApplicationId;
        switch (this.type) {
            case 'project':
                if (!this.CompleteCheckList.CheckListData) {
                    this.CompleteChecklistEdit = new CompleteChecklistEdit();
                    this.subscriptions.push(this.CompleteCheckListServices.getFeatureCheckListData(
                        this.CompleteCheckList.ChecklistId
                    ).subscribe((result: any) => {
                        if (result) {
                            this.showLoader = false;
                            this.CompleteCheckList.CheckListData =
                                result.CheckListData;
                            this.jsonString = this.CompleteCheckList.CheckListData;
                            this.CompleteChecklistEdit = new CompleteChecklistEdit();
                            this.CompleteChecklistEdit = JSON.parse(
                                this.jsonString
                            );
                        }
                    }));
                }
                break;
        }
    }

    SaveSecondStepCompleteCheckList() {
        this.CompleteCheckList.CultureInfo = sessionStorage.getItem('languageMode');
        this.showLoader = true;
        this.CompleteCheckList.ResponsibleId = 0;
        this.parentId = this.route.snapshot.params['ParentId'];
        this.parentKey = this.route.snapshot.params['ParentKey'];
        this.CompleteCheckList.ParentId = this.parentId;
        this.CompleteCheckList.ParentKey = this.parentKey;
        if (
            this.CompleteCheckList.Id === 0 ||
            this.CompleteCheckList.Id == null
        ) {
            this.CompleteCheckList.CreatedBy = BaseServices.UserId;
        } else {
            this.CompleteCheckList.ModifiedBy = BaseServices.UserId;
        }
        this.CompleteCheckList.CheckListData = JSON.stringify(
            this.CompleteChecklistEdit
        );
        this.CompleteCheckList.Title = this.checklistTitle;
        switch (this.type) {
            case 'project':
                this.subscriptions.push(this.CompleteCheckListServices.addFeatureCompleteCheckList(
                    this.CompleteCheckList
                ).subscribe(result => {
                    if (result) {
                        this.showLoader = false;
                        this.CompleteCheckList = result;
                        this.jsonString = this.CompleteCheckList.CheckListData;
                        this.CompleteChecklistEdit = new CompleteChecklistEdit();
                        this.CompleteChecklistEdit = JSON.parse(
                            this.jsonString
                        );
                        if (this.staticTabs != undefined) {
                        this.staticTabs.tabs[1].disabled = false;
                        this.staticTabs.tabs[1].active = true;
                        }
                    }
                }));
                break;
        }
    }

    SaveForLater() {
        let pathRedirection = null;
        switch (+this.parentId) {
            case FeatureKey.PROJECT:
                pathRedirection = '../../../../../../../../project/details/';
                break;
            case FeatureKey.FDV:
                pathRedirection = '../../../../../../../../fire-deviation/details/';
                break;
            case FeatureKey.FOOD_SAFETY:
                pathRedirection = '../../../../../../../../foodsafety/details/';
                break;
            case FeatureKey.FRAMEWORK_AGREEMENT:
                pathRedirection = '../../../../../../../../framework-agreement/details/';
                break;
            case FeatureKey.SERVICE_AGREEMENT:
                pathRedirection = '../../../../../../../../service-agreement/details/';
                break;
            case FeatureKey.KUNDE_EL:
                pathRedirection = '../../../../../../../../kunde_EL/details/';
                break;
        }
        this.showLoader = true;
        this.CompleteCheckList.ResponsibleId = 0;
        if (
            this.CompleteCheckList.Id === 0 ||
            this.CompleteCheckList.Id == null
        ) {
            this.CompleteCheckList.CreatedBy = BaseServices.UserId;
        } else {
            this.CompleteCheckList.ModifiedBy = BaseServices.UserId;
        }
        this.CompleteCheckList.CheckListData = JSON.stringify(
            this.CompleteChecklistEdit
        );
        this.CompleteCheckList.Title = this.checklistTitle;

        switch (this.type) {
            case 'project':
                this.subscriptions.push(this.CompleteCheckListServices.addFeatureCompleteCheckList(
                    this.CompleteCheckList
                ).subscribe(result => {
                    if (result) {
                        this.showLoader = false;
                        if (pathRedirection) {
                            this.router.navigate(
                                [
                                    pathRedirection,
                                    this.parentKey,
                                    'safetyjobs',
                                    'edit',
                                    121
                                ],
                                { relativeTo: this.route }
                            );
                        }
                    }
                }));
                break;
        }
    }

    BindScore() {
        this.scoreList = [];
        for (let i = 1; i <= 6; i++) {
            this.scoreList.push({ label: i.toString(), value: i });
        }
    }

    BindTreeNode() {
        this.subscriptions.push(this.archiveServices.getArchiveTree(BaseServices.BusinessId).subscribe(
            (result: any) => {
                this.treeNode = result[0].nodes;
                let flattenned = this.flattenArrObj(this.treeNode);
                const parent = _.filter(flattenned, [
                    'id',
                    this.treeActiveNode.data.id
                ]);
                this.uploadedFiles = parent[0].nodes;
                // this.upConfig.title = parent[0].filename;
            },
            (error: any) => {
                console.error('Api Error while refreshing tree:', error);
            },
            () => {
                console.log('Refreshing tree service complete.');
            }
        ));
    }

    private flattenArrObj(arr) {
        let newArr = [];
        if (Array.isArray(arr) && arr.length > 0) {
            arr.forEach(element => {
                newArr.push(element);
                if (Array.isArray(element.nodes) && element.nodes.length > 0) {
                    newArr = newArr.concat(this.flattenArrObj(element.nodes));
                }
            });
        }
        return newArr;
    }

    GetAppmaualChecklistdetails(id: number) {
        this.subscriptions.push(this.CompleteCheckListServices.getManualChecklistDetails(id).subscribe(
            (result: any) => {
                if (result) {
                    this.checklistTitle = result.Title;
                    this.isScoreIndex = result.IsScoreIndex;
                }
            }
        ));
    }

    GetFeatureMaualChecklistdetails(id: number) {
        this.subscriptions.push(this.CompleteCheckListServices.getFeatureChecklistDetails(id).subscribe(
            (result: any) => {
                if (result) {
                    this.checklistTitle = result.Title;
                    this.isScoreIndex = result.IsScoreIndex;
                }
            }
        ));
    }

    EnableFirstTab() {
        this.staticTabs.tabs[0].active = true;
    }

    checkValid() {
        let val = false;
        if (this.CompleteChecklistEdit.Section) {
            let sections = this.CompleteChecklistEdit.Section;
            for (let section of sections) {
                let items = section.Items;
                for (let item of items) {
                    let status = item.Status;
                    if (
                        status[0].value === false &&
                        status[1].value === false &&
                        status[2].value === false
                    ) {
                        val = true;
                    }
                }
            }
        }

        return val;
    }

    SaveAndCompleteChecklist() {
        this.showLoader = true;
        this.subscriptions.push(this.CompleteCheckListServices
            .SaveAndCompleteCheckList(this.CompleteCheckList, this.isScoreIndex, BaseServices.userCultureInfo())
            .subscribe(
                result => {
                    if (result === true) {
                        this.location.back();
                        this.showLoader = false;
                    } else {
                        this.showLoader = false;
                        alert('checklist not completed');
                    }
                },
                error => {
                    console.log('checklist not completed');
                }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub, i) => {
            sub.unsubscribe();
        });
    }
}
