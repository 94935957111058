<h1 style="text-align: center; font-weight: bold;">
    <span>{{'OHS_PLAN' | translate}} </span>
</h1>
<div class="table-view mbtm-30">
    <table class="table table-striped table-bordered form-table" *ngIf="isShowOHSPlanTable">
        <thead>
            <tr>
                <th colspan="1" style="width: 10%;">
                    <span translate>PERFORMED_BY</span>
                    <span class="required">*</span>
                    <input type="text"  class="form-control input-sm" [readonly]="!isRMode" [(ngModel)]="OHS_PerfomedDetails.PerformedBy">
                </th>
                <th colspan="4">
                    <span colspan="1" translate>PERIOD_FROM</span>
                    <span class="required">*</span>
                    <my-date-picker name="PeriodFrom" [disabled]="!isRMode"  [options]="fromDateOptions"
                        [(ngModel)]="OHS_PerfomedDetails.PeriodFrom" (dateChanged)="FromDateChanged($event)">
                    </my-date-picker>
                </th>
                <th colspan="4">
                    <span colspan="1" translate>PERIOD_TO</span>
                    <span class="required">*</span>
                    <my-date-picker name="PeriodTo" [disabled]="!isRMode" [options]="toDateOptions" [(ngModel)]="OHS_PerfomedDetails.PeriodTo"
                        (dateChanged)="ToDateChanged($event)">
                    </my-date-picker>
                </th>
            </tr>
            <tr>
                <th translate>OHS-NO</th>
                <th translate>ACTIVITIES
                <span class="required">*</span>
                </th>
                <th translate>COMMENT</th>
                <th translate>OHS-ADVISE</th>
                <th translate>OHS-NOT-ADVISE</th>
                <th translate>INITIATOR</th>
                <th translate *ngIf="isRegisteredActivityColumnVisible">REGISTERED-ACTIVITY</th>
                <th translate style="width: 10.5%;">DEADLINE
                <span class="required">*</span>
                </th>
                <th translate *ngIf="isRMode">ACTIONS</th>
                <!-- <th translate></th> -->
            </tr>
        </thead>
        <!-- <tbody>
            <ng-template let-ohsDetails >

            </ng-template>
        </tbody> -->
        <ng-template ngFor let-activity let-index="index" [ngForOf]="OHS_PerfomedDetails.OHS_Activity">
            <tbody>
                <tr>
                    <td>
                        <input type="text" readonly class="form-control input-sm" [(ngModel)]="activity.No">
                    </td>
                    <!-- <td>
                        <div style="overflow: scroll;">
                        <input  pTooltip="{{activity.Activity}}" tooltipPosition="top" type="text"
                            class="form-control input-sm" [(ngModel)]="activity.Activity" >
                        </div>
                    </td> -->
                    <td>
                          <textarea pInputTextarea [readonly]="!isRMode" autoResize="autoResize" pTooltip="{{ activity.Activity }}" tooltipPosition="top"
                                     [(ngModel)]="activity.Activity"></textarea>
                    </td>
                    <td>
                        <textarea pInputTextarea [readonly]="!isRMode" autoResize="autoResize" pTooltip="{{activity.Comment}}" tooltipPosition="top" 
                             [(ngModel)]="activity.Comment"></textarea>
                    </td>
                    <td>
                        <div style="display: flex; justify-content: center;">
                        <input type="checkbox" [disabled]="!isRMode" [(ngModel)]="activity.OHS_Advise"  value="true" (change)="selectOption('OHS_Advise',index)">
                        </div>
                    </td>
                    <td>
                        <div style="display: flex; justify-content: center;">
                        <input type="checkbox" [disabled]="!isRMode" [(ngModel)]="activity.OHS_Not_Advise"  value="false" (change)="selectOption('OHS_Not_Advise',index)">
                        </div>
                    </td>
                    <td>
                        <p-dropdown [options]="initiator" [readonly]="!isRMode" [(ngModel)]="activity.Initiator">
                        </p-dropdown>
                    </td>
                    <td *ngIf="isRegisteredActivityColumnVisible" >
                        <div style="display: flex; justify-content: center;"  >
                        <input type="checkbox" [disabled]="!isRMode" [(ngModel)]="activity.RegisteredActivity"  >
                    </div>
                    </td>
                    <td>
                        <my-date-picker [disabled]="!isRMode" name="EventDate" [options]="dateOptions" [(ngModel)]="activity.Deadline"
                            (dateChanged)="dateChanged($event, index)">
                        </my-date-picker>
                    </td>
                    <td> 
                        <button type="button" *ngIf="isRMode" class="btn btn-icon" (click)="removeOhsActivity(index)">
                            <span class="icon ic-sm icon-trash"></span>
                        </button>
                        <button *ngIf="isRMode" type="button" class="btn btn-icon" (click)="addNewRow(index)">
                            <span class="icon ic-sm icon-add"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
    <div style="padding-top: 10px; text-align: right;">
        <button *ngIf="isShowOHSPlanTable && isRMode"
            [disabled]="OHS_PerfomedDetails.PeriodFrom == null || OHS_PerfomedDetails.PeriodTo == null || OHS_PerfomedDetails.PerformedBy == null || !checkActivityDeadline()"
            translate class="btn btn-primary" (click)="SaveOHSActivity()">SAVE_CHECKLIST</button>
        <button type="button" *ngIf="isRMode" title="{{'Confirm'| translate}}"  class="btn btn-primary-outline" (click)="AddDefaultTemplate()">
            <span translate>IMPORT_FROM_TEMPLATE</span>
        </button>
        <!-- <button *ngIf="isRMode" type="button" class="btn btn-icon" (click)="addNewRow()">
            <span class="icon ic-sm icon-add"></span>
        </button> -->
    </div>
</div>
<ckeditor
  [editor]="Editor"
  [config]="editorConfig"
  [(ngModel)]="tabz.text"
  [disabled]="readOnly"
  data=""
></ckeditor>
<div style="padding-top: 10px; float: right;">
<button translate class="btn btn-primary" *ngIf="isRMode" [disabled]="OHS_PerfomedDetails.PeriodFrom == null || OHS_PerfomedDetails.PeriodTo == null || OHS_PerfomedDetails.PerformedBy == null || !checkActivityDeadline()" (click)="saveContent()">SAVE_LATER</button>
<button translate class="btn btn-primary"  *ngIf="isRMode" [disabled]="OHS_PerfomedDetails.PeriodFrom == null || OHS_PerfomedDetails.PeriodTo == null || OHS_PerfomedDetails.PerformedBy == null || !checkActivityDeadline()" (click)="PrintOhs()">PDF</button>
<button translate class="btn btn-primary" *ngIf="isRMode" [disabled]="OHS_PerfomedDetails.PeriodFrom == null || OHS_PerfomedDetails.PeriodTo == null || OHS_PerfomedDetails.PerformedBy == null || !checkActivityDeadline()"
 (click)="SavePDFDocument()">SAVE_PDF_IN_DOCUMENTS</button>
</div>
<toaster-component></toaster-component>
<p-confirmDialog header="{{'CONFIRMATION'|translate}}" icon="pi pi-question-circle" width="425"
acceptLabel="{{'SWITCH_YES'|translate}}" rejectLabel="{{'SWITCH_NO'|translate}}"></p-confirmDialog>
<div class="panel-loader" *ngIf="loading">
    <span>
        <span class="loader-icon"></span>
        <span translate>PROCESSING</span>
    </span>
</div>